import useFormStyles from "assets/jss/components/auth/formStyle";
import React, { useEffect, useRef, useState } from "react";
import Items from "./TableBody";

//Style
import "./style.css";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Rating } from "@material-ui/lab";
import CustomTextInput from "components/Common/CustomTextInput";
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import TimesheetFooter from "./ItemsTwo";
import { useDispatch, useSelector } from "react-redux";
import CustomSnackBar from "components/Common/CustomSnackBar";
import {
  changeTimesheetInput,
  updateTimesheet,
} from "redux/actions/timesheetAction";

const Details = () => {
  const classes = useFormStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = Cookies.get("LINtoken");
  const [details, setDetails] = useState(null);
  const [value, setValue] = useState(0);
  const { data } = useSelector((state) => state.timesheetReducer);
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const uploadRef = useRef();
  const [submit, setSubmit] = useState({
    toast: false,
    loader: false,
  });
  const [dispute, setDispute] = useState({
    toast: false,
    loader: false,
  });
  const handleDispute = async () => {
    try {
      setDispute({ ...dispute, loader: true });
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/dispute-timesheet/timesheetID/${id}`
      );
      setDispute({ loader: false, toast: true });

      window.location = `/linguist/open-new-ticket`;
    } catch (error) {
      console.log(error);
    }
  };
  const {
    start_date,
    end_date,
    feedback,
    note,
    duration,
    start_time,
    end_time,
    signature,
    declaration,
  } = data;

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getTimeSheet = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/view-details-timesheet/timesheetID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );

        setDetails(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    getTimeSheet();

    return () => {
      source.cancel();
    };
  }, []);

  const handleSubmit = async (e) => {
    setSubmit({ ...submit, loader: true });
    const file = new File([signature], "signature", { type: "image/png" });
    const fd = new FormData();
    fd.append("signature", file);
    fd.append("feedback", feedback);
    fd.append("note", note);
    if (uploadTimesheet?.size) {
      fd.append("document", uploadTimesheet);
    }
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/accept-timesheet/timesheetID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubmit({ ...submit, loader: false, toast: true });
      window.location = "/linguist/current-assignment/close-and-timesheet";
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleRating = (e, value) => {
    setValue(value);
  };

  return details ? (
    <div>
      <div style={{ width: "100%", marginTop: "24px" }}>
        <CustomSnackBar
          message="Timesheet accepted"
          handleClose={() => setSubmit({ ...submit, toast: false })}
          openSnackBar={submit.toast}
          undo={true}
        />
        <CustomSnackBar
          message="Timesheet disputed"
          handleClose={() => setDispute({ ...dispute, toast: false })}
          openSnackBar={dispute.toast}
        />
        <CustomTextInput
          label="Job Reference Number"
          value={details?.cacID?.id ? details?.cacID?.id : details?.bacID?.id}
        />

        <Items details={details} />

        <CustomTextInput
          label="Instructions for linguist professional"
          value={details?.country}
        />

        {/* Address details */}
        {details?.remote_address && (
          <CustomTextInput
            label="Remote Address"
            value={
              details?.cacID?.remote_address
                ? details?.cacID?.remote_address
                : details?.bacID?.remote_address
            }
          />
        )}
        {!details?.remote_address ? (
          <>
            <CustomTextInput
              label="House Number / Street Name"
              value={
                details?.cacID?.house_number_street_name
                  ? details?.cacID?.house_number_street_name
                  : details?.bacID?.house_number_street_name
              }
            />
            <CustomTextInput
              label="Address Line 2"
              value={
                details?.cacID?.address_line_two
                  ? details?.cacID?.address_line_two
                  : details?.bacID?.address_line_two
              }
            />
            <CustomTextInput
              label="County / Region"
              value={
                details?.cacID?.region
                  ? details?.cacID?.region
                  : details?.bacID?.region
              }
            />
            <CustomTextInput
              label="Post Code"
              value={
                details?.cacID?.post_code
                  ? details?.cacID?.post_code
                  : details?.bacID?.post_code
              }
            />
            <CustomTextInput
              label="Country"
              value={
                details?.cacID?.country
                  ? details?.cacID?.country
                  : details?.bacID?.country
              }
            />
          </>
        ) : null}
        <CustomTextInput
          label="Court case, police, NHS ref"
          value={
            details?.cacID?.security_clearance
              ? details?.cacID?.security_clearance
              : details?.bacID?.security_clearance
          }
        />

        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
        >
          <Header style={{ margin: "0" }} as="h4">
            Feedback from client
          </Header>
          <div>
            <Rating
              name="simple-controlled"
              value={details?.feedbackByClient}
              readOnly
            />
          </div>
        </div>

        <CustomTextInput
          label="Customer / Client Name"
          value={` ${
            details?.cacID?.postedBy?.FirstName
              ? details?.cacID?.postedBy?.FirstName
              : details?.bacID?.postedBy?.FirstName
          }  ${
            details?.cacID?.postedBy?.LastName
              ? details?.cacID?.postedBy?.LastName
              : details?.bacID?.postedBy?.LastName
          }`}
        />

        <CustomTextInput
          label="Customer / Client ref no."
          value={
            details?.cacID?.postedBy?.id
              ? details?.cacID?.postedBy?.id
              : details?.bacID?.postedBy?.id
          }
        />
        <CustomTextInput
          label="Emergency Contact no."
          value={
            details?.cacID?.postedBy?.mobile_no
              ? details?.cacID?.postedBy?.mobile_no
              : details?.bacID?.postedBy?.mobile_no
          }
        />
        <CustomTextInput label="Name of limited speaker conference:" value="" />
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
        >
          <Header style={{ margin: "0" }} as="h4">
            Feedback to client
          </Header>
          <div>
            <Rating
              name="simple-controlled"
              value={feedback}
              onChange={(event, newValue) => {
                dispatch(
                  changeTimesheetInput({
                    name: "feedback",
                    value: newValue,
                  })
                );
              }}
            />
          </div>
        </div>

        <TimesheetFooter details={details} />
      </div>

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button
        onClick={() => uploadRef.current.click()}
        basic
        style={{ backgroundColor: "purple", color: "white" }}
      >
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <div style={{ padding: "10px" }}>
          <Button style={{ backgroundColor: "purple", color: "white" }}>
            View PDF
          </Button>
        </div>
        <div style={{ padding: "10px" }}>
          <Button
            disabled={submit.loader || !feedback || !signature || !declaration}
            onClick={handleSubmit}
            style={{ backgroundColor: "purple", color: "white" }}
          >
            Confirm
          </Button>
          {submit.loader && <Loader active inline />}
        </div>
        <div style={{ padding: "10px" }}>
          <Button
            onClick={handleDispute}
            style={{ backgroundColor: "purple", color: "white" }}
            disabled={details?.dispute}
          >
            {details.dispute ? "Disputed" : "Dispute"}
          </Button>
          {dispute.loader && <Loader active inline />}
        </div>
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
