import React, { useState, useEffect } from "react";
import { Card, Icon, Image } from 'semantic-ui-react'
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Axios from "utils/axios";
import moment from "moment";

const CardExampleCard = () => {

    const [values, setValues] = useState({ reload: false });


    const loadList = async () => {
        try {
            const response = await Axios.get(`/api/manager-admin/get-events-list`)

            setValues(response?.data)

            console.log(response)
        } catch (error) {
            console.log(error.response?.data)
        }
    };

    useEffect(() => {

        loadList()

    }, [])




    return (
        <GridContainer xs={12} sm={12} md={12} lg={12} justify="center">
            {values.length > 0 ?
                <>
                    {values?.map((list) => (
                        <GridItem xs={12} sm={6} md={3} lg={3}>
                            <Card>
                                {/* image size 290 x 217 => width="290" height="217" */}
                                {/* {list?.banner ? */}
                                    <Image src={`${process.env.REACT_APP_BACKEND_API}/get-events-banner/eventID/${list?._id}`}
                                        size='medium' centered
                                    /> 
                                    {/* : null} */}
                                <Card.Content>
                                    <Card.Header>{list?.subject}</Card.Header>

                                    <Card.Description>
                                        <span className='date'>
                                            {list?.CAC_check == "yes" ? "CAC " : null}
                                            {list?.BAC_check == "yes" ? "BAC " : null}
                                            {list?.LIN_check == "yes" ? "LIN " : null}
                                            {list?.EMP_check == "yes" ? "EMP " : null}
                                            {list?.SPPO_check == "yes" ? "SPPO " : null}
                                            {list?.INDIVIDUAL_check == "yes" ? "INDIVIDUAL " : null}
                                            {list?.PUBLIC_check == "yes" ? "PUBLIC" : null}
                                        </span>

                                    </Card.Description>

                                </Card.Content>
                                <Card.Content extra>

                                    <Icon name='calendar alternate outline' /> {moment(list?.events_date).format("DD-MMM-YYYY")} <Icon name='time' /> {moment(list?.events_date).format("HH:mm")}

                                </Card.Content>
                                <Card.Content extra>

                                    <Icon name='map marker alternate' />
                                    Time Zone {list?.timezone}

                                </Card.Content>

                                <Card.Content extra>
                                    <Icon name='signup' />
                                    <a target="_blank" href={`/event-member-registration/${list?._id}`}> Registration/RSVP Respond please</a>
                                </Card.Content>
                                <Card.Content extra>
                                    <Icon name='eye' />
                                    <a target="_blank" href={`/event-member-registration/${list?._id}`}> View Details</a>
                                </Card.Content>
                                <Card.Content extra>
                                    <Icon name='location arrow' /> <span className='date'>{list?.venue_address}</span>
                                </Card.Content>
                                <Card.Content extra>
                                    <Icon name='video' /> <a target="_blank" href={list?.remote_link}>Remote Joining Link (if available) </a>
                                </Card.Content>
                            </Card><br /></GridItem>
                    ))} </>
                : "No data found"}
        </GridContainer>
    )
}

export default CardExampleCard