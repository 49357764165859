import React, { useState, useEffect } from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import AccountClientLayout from "layout/AccountClientLayout";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Cookies from "js-cookie";
import Axios from "axios";
import { Header } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Report from "components/AccountClient/Report/Report";

const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  const [report_access, setAssignmentAccess] = useState(null);
  const [bacUser, setbacUser] = useState(null);

  const getDetails = async (e) => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/accountclient/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAssignmentAccess(response?.data.report_access);
    setbacUser(response?.data.bacUser);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Accountclient>
      <AccountClientLayout>
        <GridContainer>
          {report_access ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className={classes.cardTitleWhite}>Management Report</p>
                  <IconButton
                    color="inherit"
                    style={{ marginLeft: 5 }}
                    onClick={() => history.goBack()}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </CardHeader>
                <CardBody>
                  <Report />
                </CardBody>
              </Card>
            </GridItem>
          ) : bacUser === false ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className={classes.cardTitleWhite}>Management Report</p>
                  <IconButton
                    color="inherit"
                    style={{ marginLeft: 5 }}
                    onClick={() => history.goBack()}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </CardHeader>
                <CardBody>
                  <Report />
                </CardBody>
              </Card>
            </GridItem>
          ) : report_access === false ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default UnconfirmedAssignmentsPage;
