import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomDate from "components/Common/CustomDate";

import { IconButton } from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { autoSaveER, changeErInput, updateErStep } from "redux/actions/erAction";
import CustomLabel from "components/Common/CustomLabel";

const FormSix = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.erReducer);
  const [timeCount, setTimeCount] = useState([1]);

  const {
    organization_name,
    organization_address,
    start_date,
    leaving_date,
    experience_gained,
    reason_for_leaving,
    supervisor_contact_details,
  }= data

  const handleChange = e => {
    const { name, value }= e.target
    dispatch(changeErInput({
        name,
        value
    }))
  }

  const handleDateChange = (e,value) => {
    dispatch(changeErInput({
        name: 'start_date',
        value
    }))
  }

  const handleDateChangeL = (e,value) => {
    dispatch(changeErInput({
        name: 'leaving_date',
        value
    }))
  }

  useEffect(() => {
    const temp= JSON.parse(localStorage.getItem('userER'))
    dispatch(autoSaveER({
      temp
    }))
  }, [dispatch])

  useEffect(() => {
    localStorage.setItem('userER', JSON.stringify(data))
  }, [data])

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateErStep(7));
  };

  const handleBack = () => {
    dispatch(updateErStep(5));
  };

  const handleAdd = () => {
    setTimeCount([...timeCount, timeCount[0] + 1]);
  };

  const handleRemove = () => {
    const copiedTC = [...timeCount];
    copiedTC.pop();
    setTimeCount(copiedTC);
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        {/* Developer allow "add more" option here */}
        <CustomLabel label= 'Work experience' moreInfo= 'List maximum three previous employments' />

        {timeCount.map((tc) => (
        <Fragment>
        <CustomInput
          onChange= {handleChange}
          width="73.3%"
          label="Name of the organisation / event"
          name= 'organization_name'
          value= {organization_name}
        />
        <CustomInput
          onChange= {handleChange}
          width="73.3%"
          label="Address / contact details"
          name= 'organization_address'
          value= {organization_address}
        />
        <CustomDate
          onChange= {handleDateChange}
          label= 'Start date'
          width= '73.3%'
          name= 'start_date'
          value= {start_date}
        />
        <CustomDate
          onChange= {handleDateChangeL}
          label= 'Leaving date'
          width= '73.3%'
          name= 'leaving_date'
          value= {leaving_date}
        />
        <CustomInput
          onChange= {handleChange}
          width="73.3%"
          label="List of duties / experience gained"
          name= 'experience_gained'
          value= {experience_gained}
        />
        <CustomInput 
          onChange= {handleChange}
          label="Reasons for leaving" 
          width="73.3%" 
           name= 'reason_for_leaving'
         value= {reason_for_leaving}
        />
        <CustomBigInput
          onChange= {handleChange}
          width="73.3%"
          label="Reference Name"
          moreInfo= 'Provide name of your supervisor and contact details below'
          name= 'supervisor_contact_details'
          value= {supervisor_contact_details}
        />
        </Fragment>
      ))}
      <div className={classes.timeDiv}>
        {timeCount.length > 1 ? (
          <IconButton
            color="secondary"
            className="i-btn-two"
            component="button"
            onClick={handleRemove}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        ) : null}
        
        <IconButton
          color="primary"
          className="i-btn"
          component="button"
          onClick={handleAdd}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </div>
        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton text="Next" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default FormSix;
