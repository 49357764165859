import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import Action from "../Action";
import Cookies from "js-cookie";
import { Checkbox, IconButton } from "@material-ui/core";
import swal from "sweetalert";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  button: {
    background: "#e0e1e2 none",
    border: 0,
    color: "rgba(0,0,0,.6)",
    padding: 12,
    marginRight: 10,
    borderRadius: "3pt",
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
    fontSize: 13,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);

  const [ids, setIds] = useState([]);
  const handleChange = (value, id) => {
    if (value === true) {
      setIds(ids.concat(id));
    } else {
      setIds(ids.filter((item) => item !== id));
    }
  };
  const handleDeleteMult = () => {
    const token = Cookies.get("token");

    try {
      swal({
        buttons: {
          confirm: "Delete",
          cancel: "Cancel",
        },
        icon: "warning",
        title: "Are you sure?",
      }).then(async (val) => {
        if (val !== null) {
          await axios.delete(
            `${process.env.REACT_APP_BACKEND_API}/delete-multiple-translation-PRC/${ids}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          loadList();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState([]);

  useEffect(() => {
    loadList();
    setFiltered([]);
  }, [filter]);

  const loadList = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-private-translation`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setValues({ ...values, details: response?.data });
      filtered.length > 0 ? setValues(filtered) : setValues(response?.data);
      setFiltered([]);
      setIds([]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper className={classes.root}>
        <div>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" id="export">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {ids.length > 1 && (
                      <IconButton onClick={handleDeleteMult}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>Assignment ID</TableCell>
                  <TableCell>Source Language</TableCell>
                  <TableCell>Target Language</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {values
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((list) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={list._id}
                    >
                      <TableCell>
                        <Checkbox
                          onChange={(e, value) => handleChange(value, list._id)}
                          color="secondary"
                        />
                      </TableCell>
                      <TableCell>
                        {list?.id}
                      </TableCell>
                      <TableCell>{list.source_language}</TableCell>
                      <TableCell>{list.target_language}</TableCell>

                      <TableCell>
                        <Action id={list?._id} load={loadList} prcTranslation={"prcTranslation"} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={values.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
     </Paper>
  );
}
