import React, { useState, useEffect } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import { Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import ReactHtmlParser from "react-html-parser";
import Axios from "axios";

const PrivacyPolicy = () => {
  const classes = useLandingpageStyles();

  const [privacypolicy, setPrivacyPolicy] = useState(null);

  const getPrivacyPolicy = async (e) => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/get-latest-privacy-policy`
    );

    setPrivacyPolicy(response?.data);
  };
  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 0 4rem 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card style={{ padding: "5rem" }} className={classes.clientPortal}>
            {privacypolicy ? (
              <CardBody>
                <Typography
                  style={{ fontWeight: "700", borderBottom: "1px solid black" }}
                  paragraph
                  variant="h5"
                >
                  {privacypolicy?.title}
                </Typography>
                {/* <Typography style= {{fontWeight: '600', color: 'darkgray'}} paragraph variant= 'h6'>How to Find Us:</Typography> */}
                <div style={{ paddingTop: "10px", paddingLeft: "15px" }}>
                  <Typography
                    paragraph
                    variant="body1"
                    style={{ textAlign: "justify" }}
                  >
                    {ReactHtmlParser(privacypolicy?.content)}
                  </Typography>
                </div>
              </CardBody>
            ) : (
              <CardBody>
                <Typography
                  style={{ fontWeight: "700", textAlign: "center" }}
                  paragraph
                  variant="h5"
                >
                  Privacy Policy temporarily unavailable, please contact Admin
                  Office.
                </Typography>
              </CardBody>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default PrivacyPolicy;
