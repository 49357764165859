import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import history from "utils/history";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import RateCard from "components/Admin/Card/RateCardForm"

const AssignmentPage = () => {
    const classes = useDashboardStyles();

    const [control_panel, setAdminAccess] = useState(null);

    const getLinguistDetails = async (e) => {
        const token = Cookies.get("token");
        const response = await Axios.get(
            "/api/manager-admin/dashboard",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        setAdminAccess(response?.data.control_panel);
    };
    useEffect(() => {
        getLinguistDetails();
    }, []);

    return (
        <MasterLayout>
            <Manager>
                <GridContainer>
                    {control_panel ? (
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader style={{ display: "flex", justifyContent: "space-between" }} color="primary">
                                    <p className={classes.cardTitleWhite}>
                                        Rate Card: Interpreting and translation price guide for UK,
                                        Europe, Canada, Australia, New Zealand and USA.
                                    </p>
                                    <IconButton color='inherit' style={{ marginLeft: 5 }} onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
                                </CardHeader>
                                <CardBody>
                                    <RateCard />
                                </CardBody>
                            </Card>
                        </GridItem>
                    ) : control_panel === 0 ? (
                        <Header as="h3">Access denied</Header>
                    ) : (
                        <Dimmer active inverted>
                            <Loader size="large">Loading</Loader>
                        </Dimmer>
                    )}
                </GridContainer>
            </Manager>
        </MasterLayout>
    );
};

export default AssignmentPage;
