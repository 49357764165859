import OTTPageadmin from "pages/admin/OTT";
import OTTPageemp from "pages/employee/OTT";

const OTT = [
  {
    id: '700c',
    type: "form",
    text: "Order translation (telephonic)",
    path: "admin/order-translation-telephonic-form",
    component: OTTPageadmin,
  },
  {
    id: '700424',
    type: "form",
    text: "Order translation (telephonic)",
    path: "employee/order-translation-telephonic-form",
    component: OTTPageemp,
  },
];

export default OTT;
