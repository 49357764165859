import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomSelect from "components/Common/CustomSelect";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomCaptcha from "components/Common/CustomCaptcha";

import defaultAxios from "utils/axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
import { EMAIL_REGEX } from "data";

const FormOne = () => {
  const classes = useFormStyles();

  const [data, setData] = useState({
    del_first_name: "",
    del_last_name: "",
    del_email: "",
    del_mobile_no: "",
    reasons: "",
  });

  const { del_first_name, del_last_name, del_email, del_mobile_no, reasons } =
    data;
  const [notValid, setNotValid] = useState(false);
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleEmailChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const isAllow =
    !data.del_first_name ||
    !data.del_email ||
    !data.del_last_name ||
    !data.del_mobile_no ||
    !data.reasons ||
    notValid;

  const handleSubmit = async (e) => {
    const token = Cookies.get("token");

    const sendData = {
      del_first_name: data.del_first_name || "-",
      del_last_name: data.del_last_name || "-",
      del_email: data.del_email || "-",
      del_mobile_no: data.del_mobile_no || "-",
      reasons: data.reasons || "-",
    };
    try {
      const response = await defaultAxios.put(
        `${process.env.REACT_APP_BACKEND_API}/lead-removal-request`,
        sendData
      );
      swal({
        title: "Success",
        text: "Removal Request Sent",
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        label="First Name(s)"
        name="del_first_name"
        value={data.del_first_name}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Last Name"
        name="del_last_name"
        value={data.del_last_name}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleEmailChange}
        label="Email"
        name="del_email"
        value={data.del_email}
        width="100%"
        required
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomInputSingle
        onChange={handleChange}
        label="Phone Number"
        name="del_mobile_no"
        value={data.del_mobile_no}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Reason/s for request"
        name="reasons"
        value={data.reasons}
        width="100%"
        required
      />

      <div className={classes.buttonFlex}>
        <CustomButton disabled={isAllow} text="Send" onClick={handleSubmit} />
      </div>
    </form>
  );
};

export default FormOne;
