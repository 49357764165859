import CustomTextInput from "components/Common/CustomTextInput";
import React, { useRef, useState, useEffect } from "react";
import { Button, Table } from "semantic-ui-react";
import { Header, Checkbox } from "semantic-ui-react";
import { IconButton, Input, TextField } from "@material-ui/core";

import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import history from "utils/history";
import RichTextEditor from "react-rte";
import Cookies from "js-cookie";
import Axios from "axios";
import moment from "moment";

const Details = ({ id }) => {
  let sigPad = useRef({});
  const [signature, setSignature] = useState(false);
  const [sign, setSign] = useState("");
  const [text, setText] = useState(RichTextEditor.createEmptyValue());
  const [projectReport, setProjectReport] = useState();
  const token = Cookies.get("LINtoken");
  const [details, setDetails] = useState({
    project_name: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    request_details: "",
    target_language_level: "",
    security_clearence: "",
    word_count: "",
    visual_formatting: "",
    certification: "",
    post_certification: "",
    project_report: RichTextEditor.createEmptyValue(),
    signature: "",
    supervisor_fullname: "",
    date: "",
    feedbackByLin: "",
    remarksByLin: "",
  });

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getProjectReport = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/preview-quote/cashclient/linQuoteID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );
        setProjectReport(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancel");
        } else {
          console.log(error.response);
        }
      }
    };

    getProjectReport();

    return () => {
      source.cancel();
    };
  }, []);

  const handleClose = (e) => {
    e.preventDefault();
    setSignature(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = () => {
    const temp = sigPad.current.toDataURL();
    setDetails({ ...details, signature: temp });
    setSignature(false);
  };

  const handleText = (value) => {
    setDetails({ ...details, project_report: value });
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleNext = async () => {
    const fd = new FormData();

    const file = new File([details.signature], "signature", {
      type: "image/png",
    });

    fd.append("project_name", details.project_name);
    fd.append("start_date", projectReport.date_of_quote);
    fd.append("end_date", details.endDate);
    fd.append("start_time", details.start_time);
    fd.append("end_time", details.end_time);
    fd.append("request_details", details.request_details);
    fd.append(
      "target_language_level",
      projectReport.quoteRequstByCashclient.target_skills_required
    );
    fd.append(
      "security_clearance",
      projectReport.quoteRequstByCashclient.security_clearance
    );
    fd.append("word_count", details.word_count);
    fd.append("visual_formatting", details.visual_formatting);
    fd.append("certification", details.certification);
    fd.append("post_certification", details.post_certification);
    fd.append("project_report", "Pratik");
    fd.append("signatureByLin", file);
    fd.append("supervisor_fullname", "");
    fd.append("date", moment().format("DD/MM/YYYY"));
    fd.append("remarksByLin", details.remarksByLin);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/create-project-report/cashclient/linQuoteID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      history.push(
        `/linguist/accepted-quote/project-report/invoice/${response?.data._id}`
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  return projectReport ? (
    <div>
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>
      <CustomTextInput
        label="Assignment reference number"
        value={projectReport.id}
      />
      <CustomTextInput
        onChange={handleChange}
        name="project_name"
        label="Translation / Training /Other Project name"
        value={details.project_name}
      />

      {/* Table */}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Actual</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Project / assignment confirmation date: </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project start date: </Table.Cell>
            <Table.Cell>{projectReport.date_of_quote}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project end date: </Table.Cell>
            <Table.Cell>
              <TextField
                id="date"
                type="date"
                value={details.end_date}
                name="end_date"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project background and request details</Table.Cell>
            <Table.Cell>
              <Input
                onChange={handleChange}
                name="request_details"
                value={details.request_details}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell>
              {projectReport.quoteRequstByCashclient.target_skills_required}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Security clearance/s</Table.Cell>
            <Table.Cell>
              {projectReport.quoteRequstByCashclient.security_clearance}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Wordcount</Table.Cell>
            <Table.Cell>
              <Input
                onChange={handleChange}
                name="word_count"
                value={details.word_count}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Visual formatting</Table.Cell>
            <Table.Cell>
              <Input
                onChange={handleChange}
                name="visual_formatting"
                value={details.visual_formatting}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Certification</Table.Cell>
            <Table.Cell>
              <Input
                onChange={handleChange}
                name="certification"
                value={details.certification}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Post certification </Table.Cell>
            <Table.Cell>
              <Input
                onChange={handleChange}
                name="post_certification"
                value={details.post_certification}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">Project Report</Header>
      <RichTextEditor
        value={details.project_report}
        onChange={handleText}
        name="project_report"
      />
      {/* <Button style= {{backgroundColor: 'purple', color: 'white', marginTop: '10px'}}>Save Report </Button> */}

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
            <Table.HeaderCell>
              To be verified by project supervisor / quality control (if
              applicable){" "}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Checkbox label="I declare the information given by me is true/accurate and complete." />
            </Table.Cell>
            <Table.Cell>
              <Checkbox label="I certify that the project was supervised / quality control by me" />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Signature{" "}
              {history.location.pathname.includes("linguist") ? (
                <IconButton onClick={() => setSignature(true)}>
                  <EditIcon />
                </IconButton>
              ) : null}
              {details.signature && (
                <img
                  style={{ width: "100px", height: "50px" }}
                  src={details.signature}
                  alt=""
                />
              )}
            </Table.Cell>
            <Table.Cell>
              Signature{" "}
              {history.location.pathname.includes("admin") ? (
                <IconButton onClick={() => setSignature(true)}>
                  <EditIcon />
                </IconButton>
              ) : null}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Full name: </Table.Cell>
            <Table.Cell>Full name: </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {moment().format("DD/MM/YYYY")}</Table.Cell>
            <Table.Cell>Date: {moment().format("DD/MM/YYYY")}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Feedback</Table.Cell>
            <Table.Cell>Remarks: </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button style={{ backgroundColor: "purple", color: "white" }}>
        Upload
      </Button>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button onClick={handleNext} color="purple">
          Next
        </Button>
      </div>
    </div>
  ) : null;
};

export default Details;
