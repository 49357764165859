import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Button } from "@material-ui/core";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import EmailNotification from "./EmailNotification";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

// Others
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import Axios from "utils/axios";
import fetch from "isomorphic-fetch";
import Notes from "pages/admin/Private/TransNotes";
import DocumentRequest from "pages/admin/Private/TransDocumentRequest";
import swal from "sweetalert";
import { AccordionSummary } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import TranslationDetails from "pages/Component/TranslationDetails";
import TranslationDelivery from "pages/Component/Translation_Delivery/EMP/Translation_Delivery";

const ViewContactRequest = () => {
  const token = Cookies.get("EMPtoken");
  const history = useHistory();
  const classes = useDashboardStyles();
  const { id } = useParams();
  const [values, setValues] = useState("");
  const [reload, setReload] = useState(0);
  const [edit, setEdit] = React.useState({
    loader: false,
  });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-view-translation/translationID/${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setValues(result);
      });
  }, [reload]);

  const sendLink = async (e) => {
    setEdit({ ...edit, loader: true });
    try {
      await Axios.post(
        `/api/PRC/sendlink-translation-document/assignmentID/${id}`,
        " ",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `Send Link For Document/s Upload`,
        icon: "success",
        buttons: false,
      });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `Failed`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleSend = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          sendLink(value);
          break;
        default:
          break;
      }
    });
  };

  return values ? (
    <EmployeeLayout>
      <Employee>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>
              Translation Assignment - {values.id}
            </p>
            <i
              className="icon list arrow left"
              onClick={() => {
                history.goBack();
              }}
            ></i>
          </CardHeader>
        </GridItem>
        <br />
        <React.Fragment>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
              <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
                <CardHeader
                  color="primary"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 10,
                    top: 35,
                  }}
                >
                  <p className={classes.cardTitleWhite}>
                    Translation Assignment
                  </p>
                </CardHeader>
              </GridItem>
              <Card
                raised
                style={{ marginTop: "24px", padding: "30px", zIndex: 0 }}
              >
                <CardBody>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <CardBody>
                        <TranslationDetails
                          values={values}
                          type={"prctrans"}
                          setReload={setReload}
                        />
                      </CardBody>
                    </Card>
                    <br />
                    <EmailNotification
                      type={"trans"}
                      values={values}
                      id={values?._id}
                    />
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ zIndex: 1 }}
                    >
                      <Button
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          marginTop: 10,
                          top: 35,
                          background:
                            "linear-gradient( 60deg , #ab47bc, #8e24aa)",
                          width: 100 + "%",
                          padding: (10, 15),
                        }}
                        onClick={() =>
                          history.push(
                            `/employee/prc-view-translation-document/${values._id}`
                          )
                        }
                      >
                        <p className={classes.cardTitleWhite}>View Document</p>
                      </Button>
                    </GridItem>
                    <br />
                    <br />
                    <br />
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ zIndex: 1 }}
                    >
                      {edit.loader ? (
                        <h3
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {edit.loader && (
                            <div class="ui active inline loader"></div>
                          )}
                        </h3>
                      ) : (
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <AppBar
                            position="static"
                            className={`${classes.button}`}
                            style={{ width: "100%", height: "100%" }}
                          >
                            <Toolbar onClick={handleSend}>
                              <h3
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Send Link For Document/s Upload
                                {edit.loader && (
                                  <div class="ui active inline loader"></div>
                                )}
                              </h3>
                            </Toolbar>
                          </AppBar>
                        </AccordionSummary>
                      )}
                    </GridItem>

                    <br />
                    <br />
                  </GridItem>
                </CardBody>
                <DocumentRequest />
                <br />
                <Notes />
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <TranslationDelivery />
            </GridItem>
          </GridContainer>
        </React.Fragment>
      </Employee>
    </EmployeeLayout>
  ) : (
    "Loading..."
  );
};

export default ViewContactRequest;
