
import Bill from "./Bill";

const TableBody = () => {
  return (

      <Bill />
   
  );
};

export default TableBody;
