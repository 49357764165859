import React, { Fragment } from "react";

// Custom Components
import ViewQRClients from "components/Auth/ViewQRClients/QuoteRqst";

const ViewQRClientsPage= () => {
  return (
    <Fragment>
      <ViewQRClients />
    </Fragment>
  );
};

export default ViewQRClientsPage
