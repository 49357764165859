import React from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "components/Common/CustomButton";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import { updateTransStep } from "redux/actions/transAction";
import { Button, Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";

const FormTwo = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.transReducer);

  const {} = data;

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateTransStep(1));
  };

  return (
    <div>
      <Card raised style={{ marginTop: "24px" }}>
        <CardBody>
          <h4 style={{ textAlign: "center" }}>Order Translation</h4>
        </CardBody>
      </Card>
      <Card raised style={{ marginTop: "24px", padding: "30px" }}>
        <CardBody>
          <h4 style={{ textAlign: "center" }}>Client Information </h4>
          <p>Name: </p>
          <p>House number / Street number: </p>
          <p>Address: </p>
          <p>Town / City: </p>
          <p>Zip code / Postal code: </p>
          <p>Country / Region: </p>
          <p>Country: </p>
        </CardBody>
      </Card>
      <Card raised style={{ marginTop: "24px", padding: "30px" }}>
        <CardBody>
          <h4 style={{ textAlign: "center" }}>Translation Details</h4>
          <p>Booking reference number or purchase order number: </p>
          <p>Timezone: </p>
          <p>Date & Time: </p>
          <p>Interpreting Service: </p>
          <p>Remote / Telephone Details: </p>
          <p>House number / Street number: </p>
          <p>Address: </p>
          <p>Town / City: </p>
          <p>Zip code / Postal code: </p>
          <p>Country / Region: </p>
          <p>Country: </p>
        </CardBody>
      </Card>
      <Card raised style={{ marginTop: "24px", padding: "30px" }}>
        <CardBody>
          <p>Source Language: </p>
          <p>Target Language: </p>
          <p>Linguists skill level: </p>
          <p>Interpreting Service: </p>
          <p>Types of Interpreting: </p>
          <p>Job Description: </p>
          <p>Preferred Linguist / Service Provider ID: </p>
          <p>Gender: </p>
          <p>Security clearence level: </p>
          <p>Car parking for linguist: </p>
          <p>Additional Information or direction for linguist: </p>
        </CardBody>
      </Card>
      <Card raised style={{ marginTop: "24px" }}>
        <CardBody>View Document</CardBody>
      </Card>
      <div className={classes.buttonFlex}>
        <Button
          style={{ marginTop: "2rem", marginRight: "15px" }}
          variant="contained"
          color="primary"
        >
          View Linguists
        </Button>
        <CustomButton text="Send Note" onClick={handleNext} />
        <CustomButton text="Cancel" onClick={handleNext} />
      </div>
    </div>
  );
};

export default FormTwo;
