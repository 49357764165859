import { useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
import swal from "sweetalert";
import Axios from 'axios'


const LinguistAccountActivate = () => {
  let { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    checkToken(token);
  }, [token]);

  const checkToken = async (token) => {
    try {
      const response = await Axios.post(`${process.env.REACT_APP_BACKEND_API}/linguists/signup`, {
        token,
      });
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });

      setTimeout(() => {
        history.push("/linguists-login");
      }, 2000);
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return <div></div>;
};

export default LinguistAccountActivate;
