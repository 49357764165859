import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import FinanceLedger from "components/Admin/AccountFinance/FinanceLedger/FinanceLedger";
import InvLINToPRC from "components/Admin/AccountFinance/FinanceLedger/DirectINVLINToPRC";
import InvToLIN from "components/Admin/AccountFinance/FinanceLedger/DirectINVtoLIN";
import InvtoSPPO from "components/Admin/AccountFinance/FinanceLedger/DirectINVtoSPPO";
import ReactToExcel from "react-html-table-to-excel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
  button: {
    background: "#3f51b5 none",
    border: 0,
    color: "#ffff",
    padding: 10,
    margin: 0,
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
    borderRadius: "3pt",
    fontSize: "14px",
  },
});
const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const [finance_panel, setAdminAccess] = useState(null);
  const bclasses = useStyles();

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get("/api/manager-admin/dashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setAdminAccess(response?.data.finance_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {finance_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>Finance Ledger</p>
                </CardHeader>
                <CardBody>
                  <Header as="h3">Finance Ledger</Header>
                  <ReactToExcel
                    className={bclasses.button}
                    table="finance-ledger"
                    filename="finance-ledger"
                    sheet="sheet 1"
                    buttonText="Export in Excel"
                  />
                  <FinanceLedger />
                </CardBody>
                <CardBody>
                  <Header as="h3">LIN Invoice to PRC</Header>
                  <ReactToExcel
                    className={bclasses.button}
                    table="linguist-invoice-to-prc"
                    filename="linguist-invoice-to-prc"
                    sheet="sheet 1"
                    buttonText="Export in Excel"
                  />
                  <InvLINToPRC />
                </CardBody>
                <CardBody>
                  <Header as="h3">Invoice to LIN</Header>
                  <ReactToExcel
                    className={bclasses.button}
                    table="invoice-to-linguist"
                    filename="invoice-to-linguist"
                    sheet="sheet 1"
                    buttonText="Export in Excel"
                  />
                  <InvToLIN />
                </CardBody>
                <CardBody>
                  <Header as="h3">Invoice to SPPO</Header>
                  <ReactToExcel
                    className={bclasses.button}
                    table="invoice-to-sppo"
                    filename="invoice-to-sppo"
                    sheet="sheet 1"
                    buttonText="Export in Excel"
                  />
                  <InvtoSPPO />
                </CardBody>
              </Card>
            </GridItem>
          ) : finance_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
