import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";

import SearchIcon from "@material-ui/icons/Search";
import { Button } from "semantic-ui-react";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "50%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media (max-width:768px)"]: {
      width: "100%",
      display: "block",
      padding: 0,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearchInput = ({ searchChat, handleSearch }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <div>
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Enter invoice number"
          onChange={(e) => {
            handleSearch(e);
          }}
          inputProps={{ "aria-label": "search google maps" }}
          value={searchChat}
          // onChange={(e) => setText(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        {matches ? (
          <Button style={{marginBottom:'5px',width:'100%'}} color="purple">Find saved invoice</Button>
        ) : (
          <Button color="purple">Find saved invoice</Button>
        )}
      </Paper>
    </div>
  );
};

export default SearchInput;
