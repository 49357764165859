import countryData from "./country.json";
import languageData from "./language.json";
import serviceData from "./services.json";
import securityclearence from "./securityclearence.json";
import adminsecurityclearence from "./adminsequrityclarence";
import moment from "moment-timezone";
//import timezoneData from './timezone.json'
// import mastercard from 'assets/images/mastercard.png'
// import paylib from 'assets/images/paylib.png'
// import maestro from 'assets/images/maestro.png'
// import diners from 'assets/images/diners.png'
// import Amex from 'assets/images/Amex.png'
// import ideal from 'assets/images/ideal.png'
// import przlewy24 from 'assets/images/przlewy24.png'

export const TIMEZONE = moment.tz.names();

export const TITLE_DATA = [
  "Miss",
  "Mr",
  "Mrs",
  "Sir",
  "Ms",
  "Dr",
  "Lady",
  "Lord",
  "Begum",
  "Mz",
  "Mx",
];

export const GD = ["Female", "Male", "Any"];

export const GENDER_DATA = ["Female", "Male", "Prefer not to disclose / Any"];
export const SERVICE_TYPE_INTERPRETING = [
  "Daily booking (up to 31 day)",
  "Monthly, Bulk booking (up to 24 month)",
];
export const WEEK_WORK_INTERPRETING_DURATION = [
  "Weekdays (5day/week)",
  "Weekends (2day/week)",
  "Both (7day/week)",
];
export const CLIENT_DATA = [
  "CAC",
  "BAC",
  "LIN",
  "EMP",
  "SPPO",
  "INDIVIDUAL",
  "PUBLIC",
];

export const COUNTRY_DATA = countryData;

export const BIRTH_NATIONALITY = countryData;

export const CURRENT_NATIONALITY = countryData;

export const CURRENT_COUNTRY = countryData;

export const SIMPLE_QS = ["Yes", "No"];

export const BASIC_QS = [
  "Yes(Please upload copy of your certificate and provide expiry date)",
  "No",
];

export const MOTHER_LANG = languageData;

export const PRIMARY_TARGET_LANG = languageData;

export const PRIMARY_SOURCE_LANG = languageData;

export const SERVICE = serviceData;

export const INTERPRETING_QUALIFICATION = [
  "Level 1 Award in Understanding Community Interpreting",
  "Level 2 Certificate in Languages for Business",
  "Level 2 Introduction to Legal Translation Diploma (ILTD)",
  "Level 3 Award in Understanding Community Interpreting",
  "Level 3 Certificate in Bilingual Skills - Police",
  "Level 4 Certificate in Community Interpreting",
  "Level 4 Interpreting for Victims of Crime",
  "Level 6 / Degree in interpreting",
  "Level 6 Diploma in public service interpreting (law) (DPSI)",
  "Level 6 Diploma in public service interpreting (health) (DPSI)",
  "Level 6 Diploma in public service interpreting (local government) (DPSI)",
  "Level 6 Diploma in police interpreting (DPI)",
  "Level 6 Diploma in Community Interpreting (DCI)",
  "Level 6 Diploma in Public Service Interpreting (DPSI)",
  "Level 6 Diploma in Post Edit Machine Translation (DipMat)",
  "Level 6 / Degree in conference interpreting",
  "London met police test",
  "UK Home Office interpreter test",
  "Level 7 Diploma in Translation (DipTrans)",
  "Level 7 / Masters in (conference) interpreting",
  "Level 7 Diploma in Translation",
  "Level 7/8/ MA in Translation",
  "Level 7/8/ Interpreting MA",
  "Foreign language degree (please provide details below)",
  "BSL Qualifications (please indicate your level below)",
  "Foundation Certificate in Interpreting",
  "Foundation Certificate in Translation",
  "Rare language (qualified by experience)",
  "Qualified by experience (please give details below)",
  "Student",
  "Other (please list below and provide details)",
  "None (No formal interpreting or translating qualification)",
];

export const TRANSLATION_QUALIFICATION = [
  "Level 1 Award in Understanding Community Interpreting",
  "Level 2 Certificate in Languages for Business",
  "Level 2 Introduction to Legal Translation Diploma (ILTD)",
  "Level 3 Award in Understanding Community Interpreting",
  "Level 3 Certificate in Bilingual Skills - Police",
  "Level 4 Certificate in Community Interpreting",
  "Level 4 Interpreting for Victims of Crime",
  "Level 6 / Degree in interpreting",
  "Level 6 Diploma in public service interpreting (law) (DPSI)",
  "Level 6 Diploma in public service interpreting (health) (DPSI)",
  "Level 6 Diploma in public service interpreting (local government) (DPSI)",
  "Level 6 Diploma in police interpreting (DPI)",
  "Level 6 Diploma in Community Interpreting (DCI)",
  "Level 6 Diploma in Public Service Interpreting (DPSI)",
  "Level 6 Diploma in Post Edit Machine Translation (DipMat)",
  "Level 6 / Degree in conference interpreting",
  "London met police test",
  "UK Home Office interpreter test",
  "Level 7 Diploma in Translation (DipTrans)",
  "Level 7 / Masters in (conference) interpreting",
  "Level 7 Diploma in Translation",
  "Level 7/8/ MA in Translation",
  "Level 7/8/ Interpreting MA",
  "Foreign language degree (please provide details below)",
  "BSL Qualifications (please indicate your level below)",
  "Foundation Certificate in Interpreting",
  "Foundation Certificate in Translation",
  "Rare language (qualified by experience)",
  "Qualified by experience (please give details below)",
  "Student",
  "Other (please list below and provide details)",
  "None (No formal interpreting or translating qualification)",
];

export const OTHER_LANG_TEST = [
  "International English Language Testing System IELTS academic",
  "International English Language Testing System IELTS General Training",
  "Test of English as a Foreign Language (TOEFL)",
  "Test of English for International Communication (TOEIC)",
  "Test de Connaissance du Français (TCF) (CIEP)",
  "Diplomas de Español como Lengua Extranjera (DELE)",
  "The European Language Certificates (TELC) Deutsch",
  "HSK1 to HSK6 (please indicate your level)",
  "Japanese Language Proficiency Test (JLPT)",
  "Other (please list below)",
  "Please list your CPD below",
  "Student",
];

export const LANG_COMB = languageData;
export const SECURITY_CLEARANCE_LEVEL = securityclearence;
export const ADMIN_SECURITY_CLEARANCE_LEVEL = adminsecurityclearence;
export const CR_RECORD = ["Yes (please provide details below)", "No"];

export const YES_NO = ["Yes", "No"];
export const RARE_REGEX = /(rare|Rare|Level 5)/g;
export const LEVEL_REGEX =
  /(Level 1|Level 2|Level 5|Level X |Level 3|Level 4:)/g;
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const PASS_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
export const LIST_SEC_CLEAR = [
  "DBS Enhanced Certificate (UK)",
  "MoD Security Clearance (UK)",
  "Home Office Counter Terrorist Check CTC (UK)",
  "Counter Terrorist Check CTC (UK)",
  "Police Clearance SC Level (UK)",
  "NPPV L2 (UK)",
  "NPPV L3 (UK)",
  "Other / Police check (UK)",
  "Other security clearance or police clearance by (EU Countries)",
  "Police clearance certificate for non UK (proof that no record of criminal record and not wanted by local police)",
  "Other security clearance by police (Commonwealth countries)",
  "Police clearance certificate from native country",
  "Criminal record check USA",
  "Baseline Personnel Security Standard (‘BPSS’) (British) (additional charge)",
  "Other (please provide details below)",
];

export const ORDER_PLACED_BY = [
  "Customer / Client",
  "Partner / Expert / Associate / Co-operator",
  "LC booking assistant / supervisor / manager",
];

export const DOCUMENT_TYPE = [
  "Birth Certificate",
  "Marriage Certificate",
  "Divorce Certificate",
  "Death Certificate",
  "Academic / degree Certificate",
  "Academic / degree transcript or mark sheet",
  "Government announcement / guide",
  "Court order",
  "Legal document",
  "Health document",
  "Scientific document",
  "Business document",
  "Local government / council document",
  "Engineering document",
  "Personal document",
  "Land property registration",
  "Other (please provide details below)",
];

export const EXPERTISE_LEVEL = [
  "Partner / Expert Level 5: Qualified, experienced and expert linguist",
  "Associate Level 4: Qualified and experienced linguist",
  "Associate Level 4R: Experienced Rare language linguist",
  "Co-operator Level 3: Qualified and or Rare language linguist",
  "Junior Level 2: Community interpreting qualification, apprentice or qualified by experience",
  "Junior Level 2: Community interpreting qualification, apprentice or qualified by experience",
];

export const VIRTUAL_FORMATTING = ["Yes", "No"];

export const TRANSLATION_CERTIFICATE = ["Yes", "No"];

export const PROOFREADING = ["Yes", "No"];

export const TRANSLATION_PRINTED = [
  "Yes (Linguist will bill you for packaging and postage cost)",
  "No (electronic / pdf copy only)",
];

export const PRINT_QUALITY = ["Yes", "No"];

//(*Developer - Estimated payment must be paid here unless option 2 selected and verified)
export const PAYMENT_TYPE = [
  "Pay now - using debit card / paypal / stripe etc / BACS",
  "Add to account for regular or contract billing customer",
];

export const BOOKING_BY = [
  "Customer",
  "Client",
  "Partner / Expert / Associate / Co-operator etc",
  "LC booking secretary / admin / supervisor / manager etc",
];

// (*Developer - if selected, only show FTF related section)(*Developer - eneble remote related section only)
export const ITP_SERVICE_REQUIRED = [
  "Remote Interpreting (RI) / Video Remote Interpreting (VRI)",
  "Face to Face FTF - On Site",
];

export const TYPE_INTERPRETING = [
  "Simultaneous interpreting",
  "Consecutive interpreting",
  "Conference interpreting",
  "Court interpreting",
  "UK Court interpreting",
  "Whispered or Chuchotage",
  "Police interpreting (interview /Oral)",
  "Police interpreting (statement/written)",
  "Community interpreting",
  "Hospital / Health / NHS interpreting",
  "Local government / council interpreting",
  "Contact centre interpreter",
  "Escort/Assistance/Travel Interpreting",
  "Scheduled Telephone Interpreting",
  "On-Demand Phone Interpreting",
  "BSL (British Sign Language)",
  "ASL (American Sign Language)",
  "Other (Please provide details below)",
];

export const SOURCE_LANG_EXPERTISE_LEVEL = [
  "Partner / Expert Level 5: Qualified, experienced and expert linguist",
  "Associate Level 4: Qualified and experienced linguist",
  "Associate Level 4R: Experienced Rare linguist",
  "Co-operator Level 3: Rare language linguist",
  "Junior Level 2: Apprentice or qualified by experienced linguist",
  "Assistant Level 1:  Trainee, student or qualified by experienced linguist",
];

export const TARGET_LANG_EXPERTISE_LEVEL = [
  "Partner / Expert Level 5: Qualified, experienced and expert linguist",
  "Associate Level 4: Qualified and experienced linguist",
  "Associate Level 4R: Experienced Rare linguist",
  "Co-operator Level 3: Rare language linguist",
  "Junior Level 2: Apprentice or qualified by experienced linguist",
  "Assistant Level 1:  Trainee, student or qualified by experienced linguist",
];

export const SKILL_LEVEL_REQUIRED = [
  "Partner / Expert Level 5: Qualified, experienced and expert linguist",
  "Associate Level 4: Qualified and experienced linguist",
  "Associate Level 4R: Experienced Rare linguist",
  "Co-operator Level 3: Qualified and or Rare language linguist",
  "Junior Level 2: Community interpreting qualification, apprentice or qualified by experience",
  "Assistant Level 1:  Trainee or qualified by experience",
];

export const SKILL_LEVEL_REQUIRED_X = [
  "Associate Professional Level X",
  "Assistant Level 1:  Trainee or qualified by experience",
  "Junior Level 2: Community interpreting qualification, apprentice or qualified by experience",
  "Co-operator Level 3: Qualified and or Rare language linguist",
  "Associate Level 4: Qualified and experienced linguist",
  "Associate Level 4R: Experienced Rare linguist",
  "Partner / Expert Level 5: Qualified, experienced and expert linguist",
];

// export const SECURITY_CLEARANCE_LEVEL = [
//   "DBS Enhanced Certificate (UK)",
//   "MoD Security Clearance (UK)",
//   "Home Office Counter Terrorist Check CTC (UK)",
//   "Counter Terrorist Check CTC (UK)",
//   "Police Clearance SC Level (UK)",
//   "NPPV L2 (UK)",
//   "NPPV L3 (UK)",
//   "Other / Police check (UK)",
//   "Other security clearance or police clearance by (EU Countries)",
//   "Police clearance certificate for non UK (proof that no record of criminal record and not wanted by local police)",
//   "Other security clearance by police (Commonwealth countries)",
//   "Police clearance certificate from native country",
//   "Criminal record check USA",
//   "Baseline Personnel Security Standard (‘BPSS’) (British) (additional charge)",
//   "Other (please ask linguist)",
//   "Any",
//   "Not required",
// ];

export const CAR_PARKING = [
  "Yes (Please give details)",
  "No (Linguist may add parking fees to invoice)",
];

//(Developer* - if selected - add section additional filed 1. Account no. 2. Account code4 3. Person booking this job and 4. Direct contact number)
export const PAYMENT = [
  "Pay now deposit (Remote)",
  "Pay now deposit (FTF - on site)",
  "Add to account for regular or contract billing customer",
];

export const TEST_LISIS = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export const QUOTE_REQUEST_FOR = [
  "Face to Face interpreting FTF",
  "Remote interpreting (VRI video/RI telephone)",
  "Video Remote Interpreting (VRI)",
  "Audio Remote Interpreting (ARI)",
  "British Sign Language BSL (must be qualified)",
  "American Sign Language ASL (must be qualified)",
  "International Sign Language ISL (must be qualified)",
  "Translation",
  "Simultaneous interpreting",
  "Consecutive interpreting",
  "Conference interpreting",
  "Court interpreting",
  "Diplomatic Mission Interpreter",
  "Bilingual Customer Service Rep",
  "Bilingual Care Work",
  "Bilingual Nurse",
  "Bilingual Doula",
  "Multilingual",
  "UK Court interpreting",
  "Escort/Assistance/Travel Interpreting",
  "Whispered or Chuchotage",
  "Forensic / investigative interpreter (Expert Evidence - Court Experts)",
  "Police interpreting (interview/Oral)",
  "Police interpreting (statement/written)",
  "Community interpreting (charity and support organisation)",
  "Doctor/Hospital/Health/NHS interpreting",
  "Local government / council interpreting",
  "Contact centre interpreter",
  "Business delegation / meeting interpreting",
  "Foreign delegation interpreting",
  "Security service interpreting",
  "Prison, probation and rehabilitation interpreting",
  "Military field interpreting",
  "Cultural consultancy",
  "Cultural mediation",
  "Science and Technology Translator",
  "Legal Language Translation",
  "Literary translation",
  "History Translation",
  "Translation (law, court and police)",
  "Translation (medical)",
  "Translation (science and engineering)",
  "Translation (business)",
  "Translation (literature)",
  "Translation (general, other)",
  "Content writing / editor",
  "Transcription",
  "Subtitling",
  "Audiovisual translation",
  "Linguistic",
  "Language teaching (please specify which language/s)",
  "Teaching / training linguists",
  "Training professionals",
  "Tour guide  (Speak your language)",
  "Advocacy",
  "Bilingual Accountant/tax adviser/bookkeeper",
  "Immigration / Legal advice",
  "General/bridal assistance to non native speakers",
  "Care worker (Speak your language)",
  "Doctor/Consultant/GP (Speak your language)",
  "Childminder/child care worker( (Speak your language))",
  "Proofreading",
  "Language Specialist",
  "Examiner",
  "Cultural Consultancy",
  "Language Consultancy",
  "Consultancy",
  "Translation Certification",
  "Notary Certified Translation",
  "Other (please give details below)",
];

export const PAYMENT_QUOTEREQUEST = [
  "Card / Bank Transfer",
  "Account / contract / billing customer",
  "Online using LC Platform / Card",
  "Monthly bill, I have an account with you",
  "Monthly bill, I intend to open an account with you",
];

export const NHS_TRUST = ["Yes (Please provide registration number)", "No"];

export const ACCOUNT_TYPE = [
  "Local government / council",
  "Police force / security / law enforcement",
  "Government department /agency",
  "Court and tribunal",
  "NHS / hospital / doctor",
  "Law firm / solicitor",
  "Humanitarian organisation / charity",
  "Private interpreting agency",
  "Finance / banking / accountant",
  "Corporate",
  "UN",
  "EU",
  "National government",
  "Other (Please provide details below)",
];

export const DEBIT_CARD = ["Yes (Please provide details belows", "No"];

export const POSSIBLE_TO_PAY_IN_ACCOUNT = ["Yes", "No", "Maybe"];

export const PROFESSIONAL_LIABILITY_INSURANCE = [
  "Yes (Please provide expiry date for liability insurance)",
  "No",
];

export const NATIONALITY = [
  "The Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunel",
  "Bulgaria",
  "Burlina Faso",
  "Burundi",
  "Other",
];

export const PROFESSIONAL_MEMBERSHIP = [
  "CIOL (Chartered Institute of Linguists, UK)",
  "NRPSI (National Register of Public Service Interpreters, UK)",
  "ITI (Institute of Translation and interpreting, UK)",
  "ITIA (Irish Translators' and Interpreters' Association, Ireland)",
  "AIIC (The International Association of Conference Interpreters)",
  "TAC (Translators Association of China)",
  "EFSLI (European Forum of Sign Language Interpreters)",
  "IAPTI (International Association of Professional Translators and Interpreters)",
  "FIT (International Federation of Translators)",
  "JAT (The Japan Association of Translators)",
  "SRA (Solicitors Regulation Authority)",
  "SET (The Society for Education and Training)",
  "Other (please list below name, address and your membership status)",
  "None",
];

export const CONTACT_ADDR = [
  "Same as business address",
  "Different from business address",
];
export const CLIENT_TYPE = [
  "Yes, Cash Account Client (CAC)",
  "Yes, Billing Account Client (BAC)",
  "Yes, In-house Client (INC)",
  "Yes, Private Client (PRC)",
  "No, Will Register",
];
export const SERVICE_TYPE = ["Interpreting", "Translation", "Quotation"];
export const PRC_SERVICE_TYPE = [
  "Request booking",
  "Request translation order",
];
export const PERSONAL_ADDR = [
  "Same as 'Legal representative / personal address'",
  "Different from personal address",
];

export const CURRENCY = [
  "GBP (UK)",
  "EUR (EU)",
  "AUD (Australia)",
  "USD (USA)",
  "CAD (Canada)",
  "TRY (Turkish lira)",
  "SGD (Singapore dollar)",
  "HUF (Hungarian forint)",
  "NZD (New Zealand dollar)",
  "RON (Romanian leu)",
];

export const CURRENCY_x = [
  "GBP (UK)",
  "EUR (Euro countries)",
  "AUD (Australia)",
  "USD (USA, paying from USA only)",
  "CAD (Canada)",
  "TRY (Turkish lira, payment from Turkey only)",
  "SGD (Singapore dollar)",
  "HUF (Hungarian forint)",
  "NZD (New Zealand dollar, paying from New Zealand only)",
  "RON (Romanian leu, paying from Romania only)",
];

export const CURRENCY_STRIPE = [
  "GBP (UK)",
  "EUR (Euro countries)",
  "AUD (Australia)",
  "USD (USA, paying from USA only)",
  "CAD (Canada)",
  "TRY (Turkish lira, payment from Turkey only)",
  "SGD (Singapore dollar)",
  "HUF (Hungarian forint)",
  "NZD (New Zealand dollar, paying from New Zealand only)",
  "RON (Romanian leu, paying from Romania only)",
];

export const CARD_TYPE = [
  {
    title: "MASTERCARD",
    img: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/206_Mastercard_Credit_Card_logo_logos-512.png",
  },
  // {
  //   title: 'CB', img: ''
  // },
  {
    title: "VISA",
    img: "https://icons.iconarchive.com/icons/designbolts/credit-card-payment/256/Visa-icon.png",
  },
  {
    title: "Maestro",
    img: "https://cdn2.iconfinder.com/data/icons/circle-payment/128/maestro-512.png",
  },
  {
    title: "AMEX",
    img: "https://icons.iconarchive.com/icons/designbolts/credit-card-payment/256/American-Express-icon.png",
  },
  {
    title: "Diners",
    img: "https://cdn4.iconfinder.com/data/icons/simple-peyment-methods/512/diners_club-512.png",
  },
  {
    title: "Przlewy24",
    img: "https://cdn0.iconfinder.com/data/icons/50-payment-system-icons-2/480/Przelewy24.png",
  },
  {
    title: "iDeal",
    img: "https://cdn4.iconfinder.com/data/icons/circle-payment/32/payment_003-ideal-512.png",
  },
  {
    title: "Paylib",
    img: "https://www.myfrenchstartup.com/logo/paylib.jpg",
  },
];

export const CARD_ADDRESS = ["Same as account profile", "Add new address"];

export const JOB_ADVERT_ADDRESS = [
  "This position is Remote",
  "This position is office based",
];

export const SSL_OPTIONS = ["No TLS", "TLS", "SSL"];

export const MAIL_SERVER_TYPE = ["Gmail", "Hotmail", "SMTP"];

export const DURATION = [
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
];
export const MONTHLY_DURATION = [
  "1 month",
  "2 months",
  "3 months",
  "4 months",
  "5 months",
  "6 months",
  "7 months",
  "8 months",
  "9 months",
  "10 months",
  "11 months",
  "12 months",
  "13 months",
  "14 months",
  "15 months",
  "16 months",
  "17 months",
  "18 months",
  "19 months",
  "20 months",
  "21 months",
  "22 months",
  "23 months",
  "24 months",
];
export const DURATION_TYPE = ["Daily booking", "Monthly booking"];
export const PROFESSIONAL_MEMBERSHIP_SEM_UI = [
  {
    key: "angular",
    text: "CIOL (Chartered Institute of Linguists, UK)",
    value: "angular",
  },
  {
    key: "NRPSI (National Register of Public Service Interpreters, UK)",
    text: "NRPSI (National Register of Public Service Interpreters, UK)",
    value: "NRPSI (National Register of Public Service Interpreters, UK)",
  },
  {
    key: "ITI (Institute of Translation and interpreting, UK)",
    text: "ITI (Institute of Translation and interpreting, UK)",
    value: "ITI (Institute of Translation and interpreting, UK)",
  },
  {
    key: "ITIA (Irish Translators' and Interpreters' Association, Ireland)",
    text: "ITIA (Irish Translators' and Interpreters' Association, Ireland)",
    value: "ITIA (Irish Translators' and Interpreters' Association, Ireland)",
  },
  {
    key: "AIIC (The International Association of Conference Interpreters)",
    text: "AIIC (The International Association of Conference Interpreters)",
    value: "AIIC (The International Association of Conference Interpreters)",
  },
  {
    key: "TAC (Translators Association of China)",
    text: "TAC (Translators Association of China)",
    value: "TAC (Translators Association of China)",
  },
  {
    key: "EFSLI (European Forum of Sign Language Interpreters)",
    text: "EFSLI (European Forum of Sign Language Interpreters)",
    value: "EFSLI (European Forum of Sign Language Interpreters)",
  },
  {
    key: "IAPTI (International Association of Professional Translators and Interpreters)",
    text: "IAPTI (International Association of Professional Translators and Interpreters)",
    value:
      "IAPTI (International Association of Professional Translators and Interpreters)",
  },
  {
    key: "FIT (International Federation of Translators)",
    text: "FIT (International Federation of Translators)",
    value: "FIT (International Federation of Translators)",
  },
  {
    key: "JAT (The Japan Association of Translators)",
    text: "JAT (The Japan Association of Translators)",
    value: "JAT (The Japan Association of Translators)",
  },
  {
    key: "SRA (Solicitors Regulation Authority)",
    text: "SRA (Solicitors Regulation Authority)",
    value: "SRA (Solicitors Regulation Authority)",
  },
  {
    key: "SET (The Society for Education and Training)",
    text: "SET (The Society for Education and Training)",
    value: "SET (The Society for Education and Training)",
  },
  {
    key: "Other (please list below name, address and your membership status)",
    text: "Other (please list below name, address and your membership status)",
    value: "Other (please list below name, address and your membership status)",
  },
  { key: "None", text: "None", value: "None" },
];

export const QUOTE_REQUEST_FOR_SEM_UI = QUOTE_REQUEST_FOR.map((QUOTE) => ({
  key: QUOTE,
  text: QUOTE,
  value: QUOTE,
}));

export const LANGUAGE_DATA = languageData.map((lang) => ({
  key: lang,
  text: lang,
  value: lang,
}));

export const SERVICE_DATA = serviceData.map((service) => ({
  key: service,
  text: service,
  value: service,
}));

export const SECURITY_CLEARANCE_LEVEL_SEM_UI = SECURITY_CLEARANCE_LEVEL.map(
  (sec) => ({ key: sec, text: sec, value: sec })
);

export const COUNTRY_DATA_SEM_UI = countryData.map((country) => ({
  key: country,
  text: country,
  value: country,
}));

export const CURRENCY_SEM_UI = CURRENCY.map((currency, i) => ({
  key: currency,
  text: currency,
  value: currency,
}));

export const TAXIFARE = [
  "Price per hour (minimum 4 hours including ½ hour paid break)",
  "Mileage charge for using own vehicle",
  "Travelling time",
  "Air tickets cost",
  "Airport transfers",
  "Overnight charge (hotel booking)",
  "Food (only for jobs booked for two days or longer)",
  "Bus fare",
  "Train fare",
  "Taxi fare ",
  "Telephone and internet bill",
  "Additional charge as agreed ",
  "Credit note",
  "Other",
];

export const TAXIFARE_SEM_UI = TAXIFARE.map((taxi, i) => ({
  key: taxi,
  text: taxi,
  value: taxi,
}));

export const TYPES_INTERPRETING_SEM_UI = TYPE_INTERPRETING.map((type) => ({
  key: type,
  text: type,
  value: type,
}));

export const DISCOUNT = ["0", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

export const PAY_OPTIONS = [
  "Marketplace speedy payment",
  "Marketplace bank transfer",
];

export const PAY_OPTIONS_BAC = [
  "Add to monthly bill",
  "Marketplace speedy payment",
  "Marketplace bank transfer",
];

export const BANK_PAY_OPTIONS = [
  "Marketplace bank transfer",
];

export const CANCEL_DETAILS = [
  "Cancel by admin",
  "Cancel by linguist",
  "Cancel by client",
];

export const PROFILE_UPDATE_OPTIONS = [
  { key: "m", text: "Interpreter", value: "Interpreter" },
  { key: "t", text: "Translator", value: "Translator" },
  {
    key: "t",
    text: "Interpreter and Translator",
    value: "Interpreter and Translator",
  },
  { key: "t", text: "Linguist", value: "Linguist" },
  { key: "t", text: "Linguist Professional", value: "Linguist Professional" },
  {
    key: "t",
    text: "Multilingual Professional",
    value: "Multilingual Professional",
  },
  { key: "t", text: "Bilingual Professional", value: "Bilingual Professional" },
  {
    key: "t",
    text: "Bilingual Medical Doctor",
    value: "Bilingual Medical Doctor",
  },
  {
    key: "t",
    text: "Bilingual Qualified Nurse",
    value: "Bilingual Qualified Nurse",
  },
  { key: "t", text: "Bilingual Care Worker", value: "Bilingual Care Worker" },
  { key: "t", text: "Bilingual Qualified", value: "Bilingual Qualified" },
  { key: "t", text: "Childminder", value: "Childminder" },
  { key: "t", text: "Bilingual Tour Guide", value: "Bilingual Tour Guide" },
  { key: "t", text: "Accountant", value: "Accountant" },
  {
    key: "t",
    text: "Bilingual Qualified Barrister",
    value: "Bilingual Qualified Barrister",
  },
  {
    key: "t",
    text: "Bilingual Qualified Solicitor",
    value: "Bilingual Qualified Solicitor",
  },
  {
    key: "t",
    text: "Bilingual Qualified Content",
    value: "Bilingual Qualified Content",
  },
  { key: "t", text: "Writer", value: "Writer" },
  { key: "t", text: "Other", value: "Other" },
];
