import React, { useState, Fragment, useEffect } from "react";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Table } from "semantic-ui-react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import Action from "./LinGroupAction";
import SearchView from "./SearchLINGroup/SearchView";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const token = Cookies.get("token");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const [show, setShow] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    LeadContactList: [],
    contact_group_name: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const { LeadContactList, contact_group_name, reload } = values;

  useEffect(() => {
    loadLeadContactList();
    setFiltered([]);
  }, [reload, filter]);

  const loadLeadContactList = async () => {
    try {
      const response = await Axios.get(
        `/api/list/LIN-contact-group`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      filtered.length > 0
        ? setValues({ ...values, LeadContactList: filtered })
        : setValues({ ...values, LeadContactList: response?.data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      const response = Axios.post(
        "/api/create/LIN-contact-group",
        { contact_group_name: contact_group_name }
      );
      swal({
        text: `Success`,
        icon: "success",
        buttons: false,
      });
      setValues({ ...values, contact_group_name: "" });
      loadLeadContactList();
      loadLeadContactList();
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response ? error?.response?.data?.error : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <>
      <SearchView
        values={LeadContactList}
        SearchText={searchText}
        setSearchText={setSearchText}
        setFiltered={setFiltered}
        load={loadLeadContactList}
        setShow={setShow}
        setFilter={setFilter}
        show={show}
      />
      {show && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table
              color="purple"
              stickyHeader
              aria-label="sticky table"
              id="export"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Lead Contact Group</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              {LeadContactList.length > 0 ? (
                <TableBody>
                  {LeadContactList?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((list) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={list._id}
                    >
                      <TableCell>{list?.contact_group_name}</TableCell>

                      <TableCell>
                        <Action
                          load={loadLeadContactList}
                          id={list?._id}
                          contact_group_name={list?.contact_group_name}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                "No data found"
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 40, 100]}
            component="div"
            count={LeadContactList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      <span>
        <br />
        <h5>Linguist Contact Group</h5>
        <br />
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            value={contact_group_name}
            onChange={handleChange}
            id="outlined-basic"
            label="Other Contact"
            variant="outlined"
            name="contact_group_name"
          />

          {contact_group_name ? (
            <CheckIcon
              color="secondary"
              onClick={handleSubmit}
              style={{
                cursor: "pointer",
                fontSize: "2rem",
                color: "green",
                marginLeft: "5px",
              }}
            />
          ) : null}
        </div>
      </span>
    </>
  );
}
