import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";

import SearchIcon from "@material-ui/icons/Search";
import { Button } from "semantic-ui-react";
import Axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { linguistPRC } from "redux/actions/LinguistClientAction";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "40%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearhInput = () => {
  const classes = useStyles();
  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const { id } = useParams();

  const handlePrivateClient = async (e) => {
    e.preventDefault();
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-linguist-lead/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(linguistPRC(response?.data));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search private client"
          inputProps={{ "aria-label": "search google maps" }}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Button color="purple">Find a lead</Button>
        <Button onClick={handlePrivateClient} color="purple">
          View all leads
        </Button>
      </Paper>
    </div>
  );
};

export default SearhInput;
