import { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";

// Material
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";

// Others
import { updateCurrencyDetails } from "redux/actions/admin/controller";

const EditableText = ({ text, readOnly, name }) => {
  const dispatch = useDispatch();

  const [isClicked, setIsClicked] = useState(false);
  const [value, setValue] = useState(text);
  const [inputName, setInputName] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const { currencyDetails } = useSelector((state) => state.adminControlData);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const handleCheck = async () => {
    if (!inputName) {
      setIsClicked(!isClicked);
      return false;
    }
    setLoading(true);
    const destruct = inputName.split(".");

    const coopiedDetails = { ...currencyDetails };

    if (destruct.length > 1) {
      coopiedDetails[destruct[0]][destruct[1]] = value;
    } else {
      coopiedDetails[destruct[0]] = value;
    }
    await dispatch(updateCurrencyDetails(coopiedDetails));
    setLoading(false);
    setIsClicked(!isClicked);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setInputName(e.target.name);
  };

  return (
    <span>
      {isClicked ? (
        <TextField
          // style={{
          //   border: "0.5px solid #333",
          //   fontSize: "1.1rem",
          //   width: `${customWidth || "4%"}`,
          // }}
          value={value}
          onChange={handleChange}
          name={name}
          id="outlined-basic"
          label="Currency Conversion"
          variant="outlined"
        />
      ) : (
        <span>{text}</span>
      )}{" "}
      {!readOnly ? (
        <Fragment>
          {!isClicked ? (
            <EditIcon
              color="primary"
              onClick={handleClick}
              style={{
                cursor: "pointer",
                fontSize: "1.2rem",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <Fragment>
              {loading ? (
                <CircularProgress
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              ) : (
                <CheckIcon
                  color="secondary"
                  onClick={handleCheck}
                  style={{
                    cursor: "pointer",
                    fontSize: "2rem",
                    verticalAlign: "middle",
                    color: "green",
                  }}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      ) : null}{" "}
    </span>
  );
};

export default EditableText;
