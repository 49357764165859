import { useState } from "react";

import Visibility from "@material-ui/icons/Visibility";
import RefreshIcon from "@material-ui/icons/Refresh";

import MenuItem from "@material-ui/core/MenuItem";

// Custom Styles
import { NotificationDiv } from "./style";

const NotificationAction = ({ values, handleReadAll,setRefresh }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const tempIds = [];
  const tempTypes = [];
  let ids = [];
  values?.map((item) => {
    tempIds.push(item._id);
  });
  ids = tempIds;
  let types = [];
  values?.map((item) => tempTypes.push(item.id));
  types = tempTypes;
  return (
    <NotificationDiv>
      <div style={{ display: "flex" }}>
        <MenuItem onClick={() => setRefresh(Math.random())}>
          {" "}
          <RefreshIcon />
        </MenuItem>
        <MenuItem onClick={() => handleReadAll(ids, types, values)}>
          {" "}
          <Visibility />
          <span
            style={{
              paddingLeft: "10px",
            }}
          >
            Mark all as read
          </span>
        </MenuItem>
      </div>
    </NotificationDiv>
  );
};

export default NotificationAction;
