
import React, { useState } from 'react'
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from 'react-redux';
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Search from './Search/SearchClient'
import Client from './List'
import FindClient from './SearchClient'

const PersonalDetails = ({id}) => {
    const classes = useDashboardStyles();
    const {linguistINC} = useSelector(state => state.linguistclient)	
    return (
     
          <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>In-house Clients</p>
               
              </CardHeader>
              </Card>
              <Card>
              <CardBody>
                {/* <Search/> */}
                <Client id = {id}/>
                {/* {linguistINC.length > 0 && <Client linguistINC= {linguistINC} id = {id} />}	 */}
                    
              </CardBody>
              
            </Card>
            
            
          </GridItem>
      
    )
}

export default PersonalDetails
