import React from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import { Card, Typography } from "@material-ui/core";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import ContactForm from "./ContactForm";
import ThreeCards from "./ThreeCards";

// Custom Styles
import { ContactDiv } from "./style";

// Others
import ContactUsImg from "assets/icons/contact-us.jpg";

const EmployeesPortal = () => {
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 0 4rem 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <img
            src={ContactUsImg}
            alt="logo"
            style={{
              width: "100%",
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <ContactDiv>
            <Card style={{ padding: "1rem" }}>
              <CardBody className="card-body">
                <GridContainer>
                  {/* Left */}
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography
                      style={{
                        fontWeight: "700",
                      }}
                      paragraph
                      variant="h5"
                      className="heading"
                    >
                      We'd love to hear from you
                    </Typography>
                    <ContactForm />
                  </GridItem>

                  {/* Right */}
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="bottom">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2448.8470386829135!2d-0.46577458420678464!3d52.137103879743606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877b6c3537d0107%3A0xe2009b43f0da1a64!2s38%20Mill%20St%2C%20Bedford%20MK40%203HD%2C%20UK!5e0!3m2!1sen!2sin!4v1608561171953!5m2!1sen!2sin"
                        width="100%"
                        height="500"
                        frameborder="0"
                        style={{ border: "0px" }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                        title="contact map"
                      ></iframe>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </ContactDiv>
          <ThreeCards />
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default EmployeesPortal;
