// import { useHistory } from "react-router-dom";

// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import history from "utils/history";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const ProjectOptions = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        // style={{ width: "30%", marginLeft: "15px", marginRight: "15px" }}
      >
        Current Projects
      </Button>
      <Button
        variant="contained"
        color="primary"
        // style={{ width: "30%", marginRight: "15px" }}
      >
        Projects History
      </Button>
      <Button
        onClick= {() => history.push('projects/submit-project-report')}
        variant="contained"
        color="primary"
        // style={{ width: "35%" }}
      >
        Submit Project Report
      </Button>
    </div>
  );
};

export default ProjectOptions;
