import { Button, IconButton, Input } from "@material-ui/core";
import React, { Fragment } from "react";
import SaveIcon from "@material-ui/icons/Save";
import Axios from "utils/axios";
import swal from "sweetalert";
import { changePreRegInput } from "redux/actions/preregAction";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import jsPDF from "jspdf";
import "jspdf-autotable";

const useStyles = makeStyles({
  noPrint: {
    "@media print": {
      display: "none !important",
    },
  },
});

const Actions = ({ billID, clientID, total, listContent }) => {
  const dispatch = useDispatch();
  const printClasses = useStyles();
  const { data } = useSelector((state) => state.preregReducer);
  const { note } = data;

  const onChange = (e) => {
    const value = e.target.value;
    dispatch(
      changePreRegInput({
        name: "note",
        value,
      })
    );
  };

  const handleSubmit = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/resend-Monthly-Bill/billID/${billID}`,
        {
          // bill_no: "3",
          total_bill_amount: total,
          note: note,
        }
      );
      swal({
        title: "Success",
        text: `Bill successfully resend!!!`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = `/admin/resend-bill/${billID}/${clientID}`;
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const sendBill = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleSubmit(value);

        default:
          break;
      }
    });
  };

  //export in pdf
  const exportPDF = (e) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 10;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    var totalPages = doc.internal.getNumberOfPages();
    var i;

    for (i = 1; i <= totalPages; i++) {
      doc.setPage(i);

      doc.text(
        50,
        doc.internal.pageSize.height - 30,
        "Strictly Company Confidential © linguistscollective.com"
      );
    }

    let pdfContent = listContent;
    doc.html(pdfContent, {
      callback: () => {
        var string = doc.output("datauristring");
        var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
        var x = window.open();
        x.document.open();
        x.document.write(embed);
        x.document.close();
      },
      margin: [30, 30, 30, 30],
    });
  };

  return (
    <Fragment>
      <div
        className={printClasses.noPrint}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        <Input
          style={{ width: "40%" }}
          placeholder="Note..."
          name="note"
          value={note}
          onChange={onChange}
        />
        <IconButton>
          <SaveIcon color="primary" />
        </IconButton>
      </div>
      <div
        className={printClasses.noPrint}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: " 60px",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: "15px" }}
          onClick={() => window.print()}
        >
          Print
        </Button>

        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: "15px" }}
          onClick={exportPDF}
        >
          View PDF
        </Button>

        <Button variant="contained" color="primary" onClick={sendBill}>
          Resend
        </Button>
      </div>
    </Fragment>
  );
};

export default Actions;
