import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SendRegForm from "components/AccountClient/AddAnUser/SendRegForm/SendRegForm";
import { useHistory } from 'react-router-dom';

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const AddAnUserPage = () => {
  const classes = useDashboardStyles();
  const history = useHistory()


  return (
    <AccountClientLayout>
      <Accountclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>Send Reg Form</p>
                <i className="icon list arrow left"
                  onClick={() => {
                    history.goBack()
                  }}></i>
              </CardHeader>
              <CardBody>
                <SendRegForm />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </Accountclient>

    </AccountClientLayout>
  );
};

export default AddAnUserPage;
