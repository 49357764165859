import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
// Material Core Components
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Material Icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormTwo from "./FormTwo";
import logo from "assets/images/logo.png";
import swal from "sweetalert";
import history from "utils/history";
// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Axios from "axios";
// import CustomLabel from "components/Common/CustomLabel";
// import CustomButton from "components/Common/CustomButton";
import { Button, Input } from "@material-ui/core";
// import history from "utils/history";
import Cookies from "js-cookie";
import { updateBookingStep } from "redux/actions/bookingAction";
import { CARD_ADDRESS, CARD_TYPE, CURRENT_COUNTRY } from "data";
import CustomSelect from "components/Common/CustomSelect";
import CustomRadio from "components/Common/CustomRadio";
import CustomInput from "components/Common/CustomInput";

const BIPayment = () => {
  const classes = useEmployeeStyles();
  const { userData } = useSelector((state) => state.mangopayReducer);
  const { data } = useSelector((state) => state.accountbookingReducer);
  const estimatedTotal = userData.total_London_cost
    ? userData.total_London_cost +
      userData.total_TravelTotal_Wages +
      userData.tatal_Food_Overnight +
      userData.total_PriceForInterpriting +
      userData.total_Overnight_charges
    : null +
      userData.total_TravelTotal_Wages +
      userData.tatal_Food_Overnight +
      userData.total_PriceForInterpriting +
      userData.total_Overnight_charges;

  const [payInDetails, setPayInDetails] = useState("");
  const [address, setAddress] = useState("");
  // const [cardDetails, setCardDetails] = useState('')
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    cardType: "",
    cardNumber: "",
    cardExpirationDate: "",
    cardCvx: "",
  });
  // console.log(userData.token)
  // console.log(data)

  const [rates, setRates] = useState("");
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
    ).then((res) => setRates(res?.data?.rates));
  }, []);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleAmount = async (e) => {
    const Id = Cookies.get("userId");
    if (details.cardType === "VISA" || details.cardType === "MASTERCARD") {
      details.cardType = "CB_VISA_MASTERCARD";
    }
    const response = await Axios.post(
      `${process.env.REACT_APP_BACKEND_API}/create/token/cardRegistration`,
      { details, Currency: "EUR", UserId: Id }
    ).then((res) => {
      if (
        res.data.ResultMessage === "Card number: invalid format" ||
        res.data.ResultMessage === "Expiry date: missing or invalid format"
      ) {
        alert(res.data.ResultMessage);
      }
      // console.log(res)
      Cookies.set("cardId", res.data.CardId);
      if (res.data.Status === "VALIDATED") {
        //history.push('payment/amount')
      }
    });

    const DebitedFunds = {
      Currency: "EUR",
      Amount: 35 * 100,
    };

    const Fees = {
      Currency: "EUR",
      Amount: (15 / 100) * (35 * 100),
    };
    const cardId = Cookies.get("cardId");
    const walletId = Cookies.get("walletId");
    const SecureModeReturnURL = `${process.env.REACT_APP_BACKEND_API}/accountclient/accountClientinterpreting/sendMail/${userData.token}`;
    swal({
      title: "Success!",
      text: `Payment Success !!`,
      icon: "success",
      buttons: false,
    });
    history.push("dashboard");
    const SecureMode = "FORCE";
    const res = await Axios.post(
      `${process.env.REACT_APP_BACKEND_API}/create/payin/card/direct`,
      {
        AuthorId: Id,
        DebitedFunds,
        CreditedWalletId: walletId,
        CardID: cardId,
        Fees,
        SecureModeReturnURL,
        SecureMode,
      }
    )
      .then((res) => {
        setPayInDetails(res.data);
        // swal({
        //   title: "Your order is confirmed",
        //   text: 'Success',
        //   icon: "success",
        // })
        window.location.href = res.data.SecureModeRedirectURL;
        // console.log(res)
      })
      .catch((err) => console.log(err));
  };

  const handleRadioChange = (e) => {
    setAddress(e.target.value);
  };

  // console.log(userData.level_no)

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                {/* <Avatar className={classes.avatar}> */}
                <img
                  style={{ width: "70px", height: "65px" }}
                  src={logo}
                  alt=""
                />
                {/* </Avatar> */}
                <Typography component="h1" variant="h5">
                  Payment
                </Typography>
              </div>
              <div
                style={{
                  padding: "4rem 10rem 4rem 10rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  <div style={{ textAlign: "center", fontSize: "16px" }}>
                    <p>Add card details</p>
                  </div>
                  <CustomSelect
                    onChange={handleChange}
                    width="100%"
                    label="Card type"
                    data={CARD_TYPE}
                    icon
                    name="cardType"
                    value={details.cardType}
                  />
                  <Input
                    style={{ marginTop: "20px" }}
                    placeholder="Name on card"
                  />
                  <Input
                    name="cardNumber"
                    value={details.cardNumber}
                    onChange={(e) => handleChange(e)}
                    style={{ marginTop: "10px" }}
                    placeholder="Card number"
                  />
                  <div style={{ display: "flex", margin: "10px 0 40px 0" }}>
                    <Input
                      name="cardExpirationDate"
                      value={details.cardExpirationDate}
                      onChange={(e) => handleChange(e)}
                      style={{ marginRight: "10px" }}
                      placeholder="Expiry date"
                    />
                    <Input
                      name="cardCvx"
                      value={details.cardCvx}
                      onChange={(e) => handleChange(e)}
                      placeholder="CVV"
                    />
                  </div>
                  <div class="ui checkbox">
                    <input type="checkbox" name="example" />
                    <label>Save card for future payment</label>
                  </div>
                  <CustomRadio
                    onChange={handleRadioChange}
                    width="100%"
                    label="Card holders address"
                    name="address"
                    value={address}
                    data={CARD_ADDRESS}
                  />
                  {address === "Add new address" ? (
                    <Fragment>
                      <CustomInput
                        required
                        //onChange= {handleChange}
                        width="100%"
                        label="House Number / Name, Street Name"
                        name="house_number_street_name"
                        //value= {house_number_street_name}
                      />
                      <CustomInput
                        //onChange= {handleChange}
                        width="100%"
                        label="Address Line 2"
                        name="address_line_two"
                        //value= {address_line_two}
                      />
                      <CustomInput
                        required
                        //onChange= {handleChange}
                        width="100%"
                        label="County / Region"
                        name="region"
                        //value= {region}
                      />
                      <CustomInput
                        required
                        //onChange= {handleChange}
                        width="100%"
                        label="Post / Zip code"
                        name="post_code"
                        //value= {post_code}
                      />
                      <CustomSelect
                        //onChange= {handleChange}
                        label="Country"
                        moreInfo="Current country of residence"
                        width="100%"
                        name="country"
                        //value= {country}
                        data={CURRENT_COUNTRY}
                        required
                      />
                    </Fragment>
                  ) : null}
                  {/* <Button onClick= {handleCardDetails} style= {{width: '9rem', marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem'}} variant= 'contained' color= 'primary'>Next</Button> */}
                  {/* <Input name= 'amount' value= {amount} onChange= {(e) => handleChange(e)} style= {{marginTop: '10px'}} placeholder= 'Amount to pay'/> */}
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      marginTop: "3rem",
                    }}
                  >
                    <p></p>
                  </div>
                  {estimatedTotal ? (
                    <div style={{ display: "flex" }}>
                      <p>Total Amount</p>
                      {data.currency.includes("Euro") && (
                        <span style={{ marginLeft: "auto" }}>
                          € {(estimatedTotal * rates.EUR).toFixed(2)}
                        </span>
                      )}
                      {data.currency.includes("GBP") && (
                        <span style={{ marginLeft: "auto" }}>
                          £ {estimatedTotal}
                        </span>
                      )}
                      {data.currency.includes("(USA)") && (
                        <span style={{ marginLeft: "auto" }}>
                          $ {(estimatedTotal * rates.USD).toFixed(2)}
                        </span>
                      )}
                      {data.currency.includes("AUS") && (
                        <span style={{ marginLeft: "auto" }}>
                          $ {(estimatedTotal * rates.AUD).toFixed(2)}
                        </span>
                      )}
                      {data.currency.includes("CHF") && (
                        <span style={{ marginLeft: "auto" }}>
                          CHF {(estimatedTotal * rates.CHF).toFixed(2)}
                        </span>
                      )}
                      {data.currency.includes("CAN") && (
                        <span style={{ marginLeft: "auto" }}>
                          $ {(estimatedTotal * rates.CAD).toFixed(2)}
                        </span>
                      )}
                    </div>
                  ) : (
                    <Skeleton variant="text" animation="wave" />
                  )}
                  <Button
                    onClick={handleAmount}
                    style={{
                      width: "9rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "1rem",
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Pay
                  </Button>
                </div>
              </div>

              {/* {selectedForm === 2 && <FormThree />} */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default BIPayment;
