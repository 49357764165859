import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router";
import LeadRegistration from "components/Auth/LeadRegistration/LeadRegistration";

const ContactRegistrationPage = () => {
  const { id } = useParams();

  return (
    <Fragment>
      <LeadRegistration id={id} />
    </Fragment>
  );
};

export default ContactRegistrationPage;
