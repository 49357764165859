import { v4 as uuidv4 } from "uuid";

import AccountFinance from "pages/admin/AccountFinance";
import AdminDashboard from "pages/admin/AdminDashboard";
import BookingPanel from "pages/admin/BookingPanel";
import ControlPanel from "pages/admin/ControlPanel";
import CrmPanel from "pages/admin/CrmPanel";
import HrPanel from "pages/admin/HrPanel";
import ManagementPanel from "pages/admin/ManagementPanel";
import ResetPasswordPage from "pages/admin/ResetPasswordPage";

// Sub Menu Pages
import InterpretingAssignments from "pages/admin/BookingSecretary/Interpreting/InterpretingList";
import TranslationAssignments from "pages/admin/BookingSecretary/Translation/TranslationList";

import Employees from "pages/admin/Employee/Employees";
import ActiveEmployees from "pages/admin/Employee/ActiveEmployees";
import InactiveEmployees from "pages/admin/Employee/InactiveEmployees";

import Linguists from "pages/admin/Linguists";
import QuotationFromClients from "pages/admin/BookingSecretary/Quotation/QuotationFromClients";
import AccountClient from "pages/admin/AccountClient/AccountClient";
import Archive from "pages/admin/Archive";
import Backup from "pages/admin/ControlPanel/Backup/Backup";
import Bills from "pages/admin/AccountAndFinance/Bills/Bills";
import Refund from "pages/admin/AccountAndFinance/Refund/Refund";
import BI_edit_page from "pages/admin/BI_editpage";

import Cashbook from "pages/admin/Cashbook";
import CashClient from "pages/admin/CashClient/CashClient";
import Communication from "pages/admin/Communication/Communication";
import OpenCommunication from "pages/admin/Communication/CreateTicket";
import ViewCommunication from "pages/admin/Communication/TicketList";
import SendInvitation from "pages/admin/Communication/SendInvitation";
import EmailSettings from "pages/admin/ControlPanel/EmailSettings/EmailSettings";
import ViewEmailSettings from "pages/admin/ControlPanel/EmailSettings/ViewEmailSettings";
import EditEmailSettings from "pages/admin/ControlPanel/EmailSettings/EditEmailSettings";

import RateCard from "pages/admin/RateCard";
import RateCardView from "pages/admin/Card/RateCardView";
import RateCardViewBookingSecretary from "pages/admin/RateCardView";

import PercentageCard from "pages/admin/PercentageCard";
import PercentageCardView from "pages/admin/Card/PercentageCardView";
import PercentageCardViewBookingSecretary from "pages/admin/PercentageCardView";

import Cms from "pages/admin/ControlPanel/CMS/Cms";
import TaskLists from "pages/admin/TaskLists";
import Finance from "pages/admin/AccountAndFinance/FinanceLedger/Finance";
import InvoiceByLinguist from "pages/admin/InvoiceByLinguist";

// booking secretary inhouse
import InhouseInterpretingAssignments from "pages/admin/BookingSecretary/Inhouse/Interpreting/ConfirmAssignments";
import ViewInhouseAssignment from "pages/admin/BookingSecretary/Inhouse/Interpreting/ViewInhouseAssignment";
import ViewTranslationAssignment from "pages/admin/BookingSecretary/Inhouse/Translation/ViewTranslationAssignment";
import InhouseTranslatingAssignments from "pages/admin/BookingSecretary/Inhouse/Translation/TranslationAssignment";

import CreateQuotation from "pages/admin/AccountAndFinance/QuoteToClient/CreateQuotation";
import CreateInvoiceToClient from "pages/admin/CreateInvoice";
import CreateInvoiceToSPPO from "pages/admin/AccountAndFinance/SPPOQuoteInvoice/CreateInvoice";
import Meetings from "pages/admin/ManagementPanel/Meetings";
import Notice from "pages/admin/ManagementPanel/Notice";
import HelpContact from "pages/admin/BookingSecretary/HelpContact/Others";
import HelpContactCommunication from "pages/admin/Communication/Help";
import CACReports from "pages/admin/ManagementPanel/cacReports";
import BACReports from "pages/admin/ManagementPanel/bacReports";
import INCReports from "pages/admin/ManagementPanel/incReports";
import PRCReports from "pages/admin/ManagementPanel/prcReports";
import MessageToLin from "pages/admin/MessageToLin";

import PanelSettings from "pages/admin/PanelSettings";

import CarpCardPage from "pages/admin/CarpCardPage";
import CarpCardView from "pages/admin/Card/CarpCardView";
import CarpCardViewBookingSecretary from "pages/admin/CarpCardView";

import FindLinguist from "pages/admin/FindLinguist";
import FindQuote from "pages/admin/FindQuote";
import AdminEmployeeAccess from "pages/admin/ControlPanel/AdminEmployee";

import EmployeeList from "pages/admin/ControlPanel/EmployeeList";
import ContactForm from "pages/admin/ContactForm";
import SmtpNotification from "pages/admin/SmtpNotification";
import PrivateInterpretingJobView from "pages/admin/BookingSecretary/Private/PrivateInterpreting/InterpretingAssignment";
import PrivateTranslationJobView from "pages/admin/BookingSecretary/Private/PrivateTranslation/TranslationAssignment";
// job advert
import PaidJobAdvert from "pages/admin/BookingSecretary/jobadvert/PaidJobAdvert";
import UnpaidJobAdvert from "pages/admin/BookingSecretary/jobadvert/UnpaidJobAdvert";
import ViewJObAdvert from "pages/admin/BookingSecretary/jobadvert/ViewJObAdvert";
import ViewJObAdvertDocument from "pages/admin/BookingSecretary/jobadvert/ViewJObAdvertDocument";
import ViewAcceptedjob from "pages/admin/BookingSecretary/jobadvert/ViewAcceptedJob";
import ViewDeclinedJob from "pages/admin/BookingSecretary/jobadvert/ViewDeclinedJob";

import AddBankDetails from "pages/admin/BankDetails/AddBankDetails";
import ViewAdminBankDetails from "pages/admin/BankDetails/ViewAdminBankDetails";
import ViewBankDetails from "pages/admin/BankDetails/ViewBankDetails";
import ViewBankDetailsBookingSecretary from "pages/admin/BankDetails/ViewBankDetailsBooking";

// stripe details
import StripeDetails from "pages/admin/AccountAndFinance/Stripe/StripeDetails";
import StripeClients from "pages/admin/AccountAndFinance/Stripe/StripeClients";

import ProfilePage from "pages/admin/ProfilePage";
import UnregisteredLinguist from "pages/admin/UnregisteredLinguist";
import LinguistRemovalRequest from "pages/admin/HRPanel/LinguistRemovalRequest";
import PriceCalculator from "pages/admin/PriceCalculator";
// cash client route
import AddCashClient from "pages/admin/CashClient/AddCashClient";
import ViewCashClient from "pages/admin/CashClient/ViewCashClient";
import ViewPartCashClientPage from "pages/admin/CashClient/VIewPartCashClientPage";
// account client route
import AddAccountClient from "pages/admin/AccountClient/AddAccountClient";
import ViewAccountClient from "pages/admin/AccountClient/ViewAccountClient";
import ViewInactiveAccountClient from "pages/admin/AccountClient/ViewInactiveAccountClient";
import EditAccountClient from "pages/admin/AccountClient/EditAccountClient";
import ViewPartAccountClientPage from "pages/admin/AccountClient/VIewPartAccountClientPage";
// admin
import AdminList from "pages/admin/ControlPanel/AdminList";
import InactiveManagerList from "pages/admin/ControlPanel/InactiveManagerAdmin";
import ManagerList from "pages/admin/ControlPanel/ManagerList";
import ViewAdminDetails from "pages/admin/ControlPanel/ViewAdminDetails";
import UpdateAdminDetails from "pages/admin/ControlPanel/UpdateAdminDetails";
// employee route
import ViewEmployeeDetails from "pages/admin/Employee/ViewEmployeeDetails";
// sppo route
import SppOrganisations from "pages/admin/Sppo/SppOrganisations";
import ViewSppoDetails from "pages/admin/Sppo/ViewSppoDetails";
// inhouse route
import InhouseClient from "pages/admin/Inhouse/InhouseClient";
import ViewInhouseDetails from "pages/admin/Inhouse/ViewInhouseDetails";
// private route
import PrivateClient from "pages/admin/Private/PrivateClient";
import ViewPrivateDetails from "pages/admin/Private/ViewPrivateDetails";
// subscription
import Subscription from "pages/admin/CrmPanel/Subscription";
// Leads
import Leads from "pages/admin/CrmPanel/Leads";
import AddLead from "pages/admin/CrmPanel/AddLead";
import LeadsBooking from "pages/admin/BookingSecretary/Leads/Leads";
import ViewCLADetails from "pages/admin/CrmPanel/ViewCLADetails";
import SendCLARegistrationForm from "pages/admin/CrmPanel/SendCLARegistrationForm";
import SendCACBACRegistrationForm from "pages/admin/CrmPanel/SendCACBACForm";
import LeadRemovalForm from "pages/admin/CrmPanel/LeadRemovalForm";
import LeadContactGroup from "pages/admin/CrmPanel/LeadContactGroup";
import InvitationRemovalForm from "pages/admin/HRPanel/InvitationRemovalForm";
import LinGroup from "pages/admin/HRPanel/LinGroup";

//Account Removal
import CACAccountRemoval from "pages/admin/CrmPanel/AccountRemoval/CACAccountRemoval";
import BACAccountRemoval from "pages/admin/CrmPanel/AccountRemoval/BACAccountRemoval";
import INCAccountRemoval from "pages/admin/CrmPanel/AccountRemoval/INCAccountRemoval";
import PRCAccountRemoval from "pages/admin/CrmPanel/AccountRemoval/PRCAccountRemoval";
import SPPOAccountRemoval from "pages/admin/CrmPanel/AccountRemoval/SPPOAccountRemoval";

// booking, translation and quote route
import ViewCashClientBooking from "pages/admin/CashClient/ViewCashClientBooking";
import ViewAccountClientBooking from "pages/admin/AccountClient/ViewAccountClientBooking";
import ViewInhouseClientBooking from "pages/admin/Inhouse/ViewInhouseClientBooking";
import ViewPrivateClientBooking from "pages/admin/Private/ViewPrivateClientBooking";

// view translation details
import ViewInhouseClientTranslation from "pages/admin/Inhouse/ViewInhouseTranslation";
import ViewPrivateClientTranslation from "pages/admin/Private/ViewPrivateTranslation";

// view documents
import ViewCACInterpretingDocument from "pages/admin/CashClient/ViewCACInterpretingDocument";
import ViewCACTranslationDocument from "pages/admin/CashClient/ViewCACTranslationDocument";
import ViewBACInterpretingDocument from "pages/admin/AccountClient/ViewBACInterpretingDocument";
import ViewBACTranslationDocument from "pages/admin/AccountClient/ViewBACTranslationDocument";
import viewINCInterpretingDocument from "pages/admin/Inhouse/ViewINCInterpretingDocument";
import viewINCTranslationDocument from "pages/admin/Inhouse/ViewINCTranslationDocument";
import viewPRCInterpretingDocument from "pages/admin/Private/ViewPRCInterpretingDocument";
import viewPRCTranslationDocument from "pages/admin/Private/ViewPRCTranslationDocument";
import viewCACQuoteDocument from "pages/admin/CashClient/ViewCACQuoteDocument";
import viewBACQuoteDocument from "pages/admin/AccountClient/ViewBACQuoteDocument";

import ViewCashClientTranslation from "pages/admin/CashClient/ViewCashClientTranslation";
import ViewCashClientQuotation from "pages/admin/CashClient/ViewCashClientQuotation";
import ViewAccountClientQuotation from "pages/admin/AccountClient/ViewAccountClientQuotation";

import ViewAccountClientTranslation from "pages/admin/AccountClient/ViewAccountclientTranslation";

// booking secretary cac&bac interpreting panel
import BACAssignments from "pages/admin/BookingSecretary/Interpreting/BAC/BACBulkInterpreting";
//Confirm
import ConfirmCACAssignments from "pages/admin/BookingSecretary/Interpreting/CAC/ConfirmCACAssignments";
import ConfirmBACAssignments from "pages/admin/BookingSecretary/Interpreting/BAC/ConfirmBACAssignments";
//Assigned
import AssignedCACAssignments from "pages/admin/BookingSecretary/Interpreting/CAC/AssignedCACAssignments";
import AssignedBACAssignments from "pages/admin/BookingSecretary/Interpreting/BAC/AssignedBACAssignments";
//Closed
import ClosedCACAssignments from "pages/admin/BookingSecretary/Interpreting/CAC/ClosedCACAssignments";
import ClosedBACAssignments from "pages/admin/BookingSecretary/Interpreting/BAC/ClosedBACAssignments";
//invoiced (CAC & BAC)
import InvoicedCACAssignments from "pages/admin/BookingSecretary/Interpreting/CAC/InvoicedAssignments";
import InvoicedBACAssignments from "pages/admin/BookingSecretary/Interpreting/BAC/InvoicedAssignments";
// Completed(CAC & BAC)
import CompletedCACAssignments from "pages/admin/BookingSecretary/Interpreting/CAC/CompletedAssignments";
import CompletedBACAssignments from "pages/admin/BookingSecretary/Interpreting/BAC/CompletedAssignments";

//Canceled
import CanceledCACAssignments from "pages/admin/BookingSecretary/Interpreting/CAC/CanceledCACAssignments";
import CanceledBACAssignments from "pages/admin/BookingSecretary/Interpreting/BAC/CanceledBACAssignments";
// disputed

// booking secretary cac&bac translation panel
//Confirm CAC & BAC
import ConfirmCACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/CAC/ConfirmCACAssignments";
import ConfirmBACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/BAC/ConfirmBACAssignments";
//Assigned CAC & BAC
import AssignedCACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/CAC/AssignedCACAssignments";
import AssignedBACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/BAC/AssignedBACAssignments";
//Closed CAC & BAC
import ClosedCACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/CAC/ClosedCACAssignments";
import ClosedBACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/BAC/ClosedBACAssignments";
//Invoiced CAC & BAC
import InvoicedCACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/CAC/InvoicedAssignments";
import InvoicedBACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/BAC/InvoicedAssignments";
//Completed CAC & BAC
import CompletedCACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/CAC/CompletedAssignments";
import CompletedBACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/BAC/CompletedAssignments";
// Canceled CAC & BAC
import CanceledCACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/CAC/CanceledCACAssignments";
import CanceledBACAssignmentsTranslation from "pages/admin/BookingSecretary/Translation/BAC/CanceledBACAssignments";
//Disputed CAC & BAC
// booking secretary cac&bac quotation from clients routes

//requested quote cac + bac
import RequestedCACQuote from "pages/admin/BookingSecretary/Quotation/CAC/RequestedQuote";
import RequestedBACQuote from "pages/admin/BookingSecretary/Quotation/BAC/RequestedQuote";

//provided quote cac + bac
import RespondedCACQuoteProvided from "pages/admin/BookingSecretary/Quotation/CAC/RespondedQuoteProvided";
import RespondedBACQuoteProvided from "pages/admin/BookingSecretary/Quotation/BAC/RespondedQuoteProvided";

//confirmed quote cac + bac
import ConfirmedByCACCliendQuote from "pages/admin/BookingSecretary/Quotation/CAC/ConfirmedByCliendQuote";
import ConfirmedByBACCliendQuote from "pages/admin/BookingSecretary/Quotation/BAC/ConfirmedByCliendQuote";

//closed quote cac + bac
import ClosedCACJobs from "pages/admin/BookingSecretary/Quotation/CAC/ClosedJobs";
import ClosedBACJobs from "pages/admin/BookingSecretary/Quotation/BAC/ClosedJobs";

//invoiced quote cac + bac
import InvoicedCACJobs from "pages/admin/BookingSecretary/Quotation/CAC/InvoicedJobs";
import InvoicedBACJobs from "pages/admin/BookingSecretary/Quotation/BAC/InvoicedJobs";

//completed quote cac + bac
import CompletedCACJobs from "pages/admin/BookingSecretary/Quotation/CAC/CompletedJobs";
import CompletedBACJobs from "pages/admin/BookingSecretary/Quotation/BAC/CompletedJobs";

//canceled quote cac + bac
import CanceledCACJobs from "pages/admin/BookingSecretary/Quotation/CAC/CanceledJobs";
import CanceledBACJobs from "pages/admin/BookingSecretary/Quotation/BAC/CanceledJobs";

// booking secretary inc interpreting panel
import ConfirmAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Interpreting/ConfirmAssignments";
import AssignedAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Interpreting/AssignedAssignments";
import ClosedAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Interpreting/ClosedAssignments";
import InvoicedAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Interpreting/InvoicedAssignments";
import CompletedAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Interpreting/CompletedAssignments";
import CanceledAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Interpreting/CanceledAssignments";

// booking secretary prc interpreting panel
import ConfirmAssignmentsPRC from "pages/admin/BookingSecretary/Private/PrivateInterpreting/ConfirmAssignments";
import ClosedAssignmentsPRC from "pages/admin/BookingSecretary/Private/PrivateInterpreting/ClosedAssignments";
import InvoicedAssignmentsPRC from "pages/admin/BookingSecretary/Private/PrivateInterpreting/InvoicedAssignments";
import CompletedAssignmentsPRC from "pages/admin/BookingSecretary/Private/PrivateInterpreting/CompletedAssignments";
import CanceledAssignmentsPRC from "pages/admin/BookingSecretary/Private/PrivateInterpreting/CanceledAssignments";

// booking secretary inc translation panel
import ConfirmTAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Translation/ConfirmAssignments";
import AssignedTAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Translation/AssignedAssignments";
import ClosedTAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Translation/ClosedAssignments";
import InvoicedTAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Translation/InvoicedAssignments";
import CompletedTAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Translation/CompletedAssignments";
import CanceledTAssignmentsINC from "pages/admin/BookingSecretary/Inhouse/Translation/CanceledAssignments";

// booking secretary prc translation panel
import ConfirmTAssignmentsPRC from "pages/admin/BookingSecretary/Private/PrivateTranslation/ConfirmAssignments";
import ClosedTAssignmentsPRC from "pages/admin/BookingSecretary/Private/PrivateTranslation/ClosedAssignments";
import InvoicedTAssignmentsPRC from "pages/admin/BookingSecretary/Private/PrivateTranslation/InvoicedAssignments";
import CompletedTAssignmentsPRC from "pages/admin/BookingSecretary/Private/PrivateTranslation/CompletedAssignments";
import CanceledTAssignmentsPRC from "pages/admin/BookingSecretary/Private/PrivateTranslation/CanceledAssignments";
//help contact
import ViewLinQuestion from "pages/admin/BookingSecretary/HelpContact/ViewLinQuestion";
import ViewCACQuestion from "pages/admin/BookingSecretary/HelpContact/ViewCACQuestion";
import ViewBACQuestion from "pages/admin/BookingSecretary/HelpContact/ViewBACQuestion";
import ViewSPPOQuestion from "pages/admin/BookingSecretary/HelpContact/ViewSPPOQuestion";

import PreRegistrationPage from "pages/admin/PreRegistrationPage";
import SendSelectedRegistrationForm from "pages/admin/SendRegistrationForm";
import SendRegistrationForm from "pages/admin/SendRegistrationForm2";
import UnverifiedLinguists from "pages/admin/UnverifiedLinguist";

import UnverifiedSPPO from "pages/admin/Sppo/UnverifiedSPPO";
import verifiedSPPO from "pages/admin/Sppo/verifiedSPPO";

import VerifiedLinguist from "pages/admin/VerifiedLinguist";
import SuspendLinguist from "pages/admin/SuspendLinguist";
import ListUnregisteredLinguist from "pages/admin/ListUnregisteredLinguist";
import UnregisteredLinguistList from "pages/admin/BookingSecretary/FindLinguist/UnregisteredLinguists";
import RegisteredLinguistList from "pages/admin/BookingSecretary/FindLinguist/RegisteredLinguists";
import SearchAllLin from "pages/admin/BookingSecretary/FindLinguist/SearchAllLin";
import SearchMultiAllLin from "pages/admin/BookingSecretary/FindLinguist/SearchAllMultiLin";
import SendLINInvitation from "pages/admin/LINInvitation/SendLINRegForm";

import ViewContactCard from "pages/admin/ViewContactCard";
import SetNewPassword from "pages/admin/SetNewPassword";
import ViewUnregisteredLinguists from "pages/admin/ViewUnregisteredLinguists";
import ViewUnregisteredLinguistsCV from "pages/admin/ViewUnregisteredLinguistsCV";
import ViewUnverifiedLinguist from "pages/admin/ViewUnverifiedLinguist";
import AdminAccountActivate from "pages/admin/AdminAccountActivate";

//help videos assignment
import HelpTutorials from "pages/admin/HelpTutorials/HelpTutorial";
import PostTutorials from "pages/admin/HelpTutorials/PostTutorials";
import ViewTutorials from "pages/admin/HelpTutorials/ViewTutorials";

// service agreement
import PostAgreement from "pages/admin/ControlPanel/CMS/ServiceAgreement/PostAgreement";
import ViewAgreement from "pages/admin/ControlPanel/CMS/ServiceAgreement/ViewAgreement";
import EditAgreement from "pages/admin/ControlPanel/CMS/ServiceAgreement/EditAgreement";

// public profile sliders
import PostSliders from "pages/admin/ControlPanel/CMS/PublicProfileSliders/PostSliders";
import ViewSliders from "pages/admin/ControlPanel/CMS/PublicProfileSliders/ViewSliders";
import ViewSlider from "pages/admin/ControlPanel/CMS/PublicProfileSliders/ViewSlider";
import PostJobAdvertPrice from "pages/admin/ControlPanel/CMS/JobAdvert/PostJobAdvertPrice";

// T&C
import TandC from "pages/admin/ControlPanel/CMS/T&C/PostT&C";
import ViewTandC from "pages/admin/ControlPanel/CMS/T&C/ViewTandC";
import EditTandC from "pages/admin/ControlPanel/CMS/T&C/EditT&C";

// Privacy and policy
import PostPrivacyPolicy from "pages/admin/ControlPanel/CMS/PrivacyPolicy/PostPrivacyPolicy";
import ViewPrivacyPolicy from "pages/admin/ControlPanel/CMS/PrivacyPolicy/ViewPrivacyPolicy";
import EditPrivacyPolicy from "pages/admin/ControlPanel/CMS/PrivacyPolicy/EditPrivacyPolicy";

//Activity logs
import ActivityLogs from "pages/admin/ActivityLogs/ActivityLogs";
import MyActivityLogs from "pages/admin/ActivityLogs/MyActivityLogs";

// Iconspages/admin/CrmPanel/ViewCLADetails
import ActivityLogIcon from "assets/icons/activitylog.png";
import Dicon from "assets/icons/dashboardAC.png";
import Ticon from "assets/icons/tasklist.png";
import NTMicon from "assets/icons/my noteto do list.png";
import RCicon from "assets/icons/rate-card.png";
import VRicon from "assets/icons/view-rate-card.png";
import MFMicon from "assets/icons/message from managment-02.png";
import OVRicon from "assets/icons/overpayment and refund-03.png";
import VCicon from "assets/icons/view-carp-card.png";
import VPicon from "assets/icons/view-percentage-card.png";
import BSicon from "assets/icons/booking-secretary.png";
import PTCicon from "assets/icons/percentage-card.png";
import Bicon from "assets/icons/bills-01.png";
import CABicon from "assets/icons/cashbook-01.png";
import VBDicon from "assets/icons/view bank details-01.png";
import CCNicon from "assets/icons/currencies-01.png";
import FLicon from "assets/icons/find-linguist.png";
import FDQicon from "assets/icons/find quote-01.png";
import HTicon from "assets/icons/help tuturial-03.png";
import Licon from "assets/icons/linguists.png";
import COicon from "assets/icons/communcation-01.png";
import PCNicon from "assets/icons/price calculator-01.png";
import BDicon from "assets/icons/bank details-01.png";
import HPicon from "assets/icons/help contact-01.png";
import SDicon from "assets/icons/STRIPE DETAIL-01.png";
import Ricon from "assets/icons/report.png";
import Eicon from "assets/icons/employees.png";
import IAicon from "assets/icons/interpreting-assignments.png";
import IIAicon from "assets/icons/inhouse-interpreting-assignment.png";
import AFicon from "assets/icons/account-and-finance.png";
import CPicon from "assets/icons/control-panel.png";
import CRMicon from "assets/icons/crm-panel.png";
import CCicon from "assets/icons/cash-client.png";
import PAYicon from "assets/icons/payroll-01.png";
import Iicon from "assets/icons/invoice.png";
import IBLicon from "assets/icons/invoice by linguist-01.png";
import ITLicon from "assets/icons/invoice to linguist-01.png";
import ITCicon from "assets/icons/invoice to client-01.png";
import SITCicon from "assets/icons/SPPO request and invoices-03.png";
import Ficon from "assets/icons/finance-01.png";
import TAicon from "assets/icons/translation-assignments.png";
import ITAicon from "assets/icons/inhouse-translation-assignments.png";
import QFCicon from "assets/icons/quotation-from-clients.png";
import HRicon from "assets/icons/hr-panel.png";
import PSicon from "assets/icons/panel-settings.png";
import PIJicon from "assets/icons/private interpreting-01.png";
import PTJicon from "assets/icons/private translation-01.png";
import PQJicon from "assets/icons/private quotes-01.png";
import UCAicon from "assets/icons/unconfirmed assignments-01.png";
import JAicon from "assets/icons/job-advert.png";
import SPOicon from "assets/icons/service-providing-organisation.png";
import Nicon from "assets/icons/notice.png";
import Micon from "assets/icons/meeting.png";
import CRicon from "assets/icons/contact-request.png";
import NCCRicon from "assets/icons/notes-02.png";
import NMCRicon from "assets/icons/my notes-02.png";
import CMSicon from "assets/icons/cms.png";
import BACKicon from "assets/icons/backup.png";
import AEicon from "assets/icons/admin-employee.png";
import Aicon from "assets/icons/archive.png";
import ESicon from "assets/icons/email-settings.png";
import SMTPicon from "assets/icons/smtp-notification-settings.png";
import Sicon from "assets/icons/subscription.png";
import LEicon from "assets/icons/leads-01.png";
import ARRicon from "assets/icons/account removal-01.png";
import ULicon from "assets/icons/unregistered-linguist.png";
import LRQicon from "assets/icons/remove linguis-01.png";
import CMicon from "assets/icons/communication-menuAC.png";
import PCSicon from "assets/icons/private-clients.png";
import ICicon from "assets/icons/inhouse-client.png";
import CICicon from "assets/icons/company-info-cardD.png";
import ACicon from "assets/icons/account-client.png";
import MPicon from "assets/icons/management-panel.png";
import CARPicon from "assets/icons/carp-card.png";
import DiplicateLINicon from "assets/icons/dublicate-linguist-id.png";

// contact request
import ContactRequest from "pages/admin/Contact/ContactRequest";
import ViewContactRequest from "pages/admin/Contact/ViewContactRequest";
import SendAdminForm from "pages/admin/SendForm/SendAdminForm";
import SendEmpForm from "pages/admin/SendForm/SendEmpForm";
import SendCACForm from "pages/admin/SendForm/SendCACForm";
import SendBACForm from "pages/admin/SendForm/SendBACForm";
import ManagerPrivileges from "pages/admin/ManagerPrivileges";
import ManagePrivilege from "pages/admin/ManagePrivilege";
import ManagerPosition from "pages/admin/ManagerPosition";
import Help from "pages/admin/Help";

import UnconfirmedAssignmentsPage from "pages/admin/BookingSecretary/UnconfirmedAssignmentsPage/UnconfirmedAssignmentsPage";
import CACUnconfirmedAssignments from "pages/admin/BookingSecretary/UnconfirmedAssignmentsPage/CACUnconfirmedAssignments";
import BACUnconfirmedAssignments from "pages/admin/BookingSecretary/UnconfirmedAssignmentsPage/BACUnconfirmedAssignmentsPage";
import INCUnconfirmedAssignments from "pages/admin/BookingSecretary/UnconfirmedAssignmentsPage/INCUnconfirmedAssignments";
import PRCUnconfirmedAssignments from "pages/admin/BookingSecretary/UnconfirmedAssignmentsPage/PRCUnconfirmedAssignments";
import AllInactiveAssignments from "pages/admin/BookingSecretary/UnconfirmedAssignmentsPage/AllInactiveAssignments";

import InvoiceLinguist from "pages/admin/InvoiceLinguist";

import cacconfirmbooking from "pages/admin/JobList/CaC/Booking";
import bacconfirmbooking from "pages/admin/JobList/BAC/Booking";
import inhouseconfirmbooking from "pages/admin/JobList/Inhouse/Booking";

import cacconfirmTranslation from "pages/admin/JobList/CaC/Translation";
import bacconfirmTranslation from "pages/admin/JobList/BAC/Translation";
import inhouseconfirmTranslation from "pages/admin/JobList/Inhouse/Translation";

import bacconfirmQuotations from "pages/admin/JobList/BAC/Quotations";
import cacconfirmQuotations from "pages/admin/JobList/CaC/Quotations";

import ArchiveADM from "pages/admin/ControlPanel/Archive/ADM";
import ArchivePRC from "pages/admin/ControlPanel/Archive/PRC";
import ArchiveBAC from "pages/admin/ControlPanel/Archive/BAC";
import ArchiveCAC from "pages/admin/ControlPanel/Archive/CAC";
import ArchiveEMP from "pages/admin/ControlPanel/Archive/EMP";
import ArchiveLIN from "pages/admin/ControlPanel/Archive/LIN";
import ArchiveSPPO from "pages/admin/ControlPanel/Archive/SPPO";
import ArchiveJOB from "pages/admin/ControlPanel/Archive/JOB";
import ArchiveINC from "pages/admin/ControlPanel/Archive/INC";
import ArchiveINV from "pages/admin/ControlPanel/Archive/INV";
import ArchiveTimesheetReport from "pages/admin/ControlPanel/Archive/TimeReport";
import ArchiveAssignments from "pages/admin/ControlPanel/Archive/Assignments";

import CreateInvoiceLinguist from "pages/admin/CreateInvoiceLinguist";
import InvoiceClient from "pages/admin/InvoiceClient";
import SPPOQuoteInvoice from "pages/admin/AccountAndFinance/SPPOQuoteInvoice/QuoteInvoice";

import HelpCAC from "pages/admin/BookingSecretary/HelpContact/CAC";
import HelpBAC from "pages/admin/BookingSecretary/HelpContact/BAC";
import HelpLIN from "pages/admin/BookingSecretary/HelpContact/LIN";
import HelpSPPO from "pages/admin/BookingSecretary/HelpContact/SPPO";

import PrivateSentQuote from "pages/admin/BookingSecretary/PrivateQuote/PrivateSentQuote";
import PrivateSavedQuote from "pages/admin/BookingSecretary/PrivateQuote/PrivateSavedQuote";
import PrivateAcceptedQuote from "pages/admin/BookingSecretary/PrivateQuote/PrivateAcceptedQuote";

import UploadLINDoc from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/UploadLINDoc";

import LINProfilePhoto from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ProfilePhoto";
import LINAddress from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ProofAddressPhoto";
import LINPassport from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/Passport";
import LINReleventDocument from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ReleventDocument";
import LINIsuranceDocument from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/InsuranceDocument";
import LINReferenceLetter from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ReferenceLetter";
import LINInterpretingDocument from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/InterpretingDocument";
import LINTranslationDocument from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/TranslationDocument";
import LINPSIPSTDocument from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/PSIPSTDocument";
import LINAnyOtherDocument from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/AnyOtherDocument";
import LINCV from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/CV";

import LINPassportDocument from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/PassportDocument";
import LINAddressDocument from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/AddressDocument";
import LINReleventCertificate from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ReleventCertificate";
import LINSecurityClearence1 from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/SecurityClearence1";
import LINSecurityClearence2 from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/SecurityClearence2";
import LINSecurityClearence3 from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/SecurityClearence3";
import LINSecurityClearence4 from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/SecurityClearence4";
import ViewOtherExperienceDoc from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/OtherExperienceDocument";
import ViewCVDoc from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/CV";
import ViewInsuranceDoc from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/InsuranceDocument";
import ViewInterpretingDoc from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/InterpretingDocument";
import ViewTranslationDoc from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/TranslationDocument";
import ViewPSIPSTDoc from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/PSIPSTDocument";
import ViewWorkReferenceLetter from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/WorkReferenceDocument";
import ViewReleventCertificate from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/ReleventCertificate";
import ViewReleventDoc from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/ReleventDocument";
import ViewSecurityDoc1 from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/SecurityClearence1";
import ViewSecurityDoc2 from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/SecurityClearence2";
import ViewSecurityDoc3 from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/SecurityClearence3";
import ViewSecurityDoc4 from "components/Admin/HrPanel/ViewLinguist/DocumentsNew/ViewDocuments/SecurityClearence4";

import LINOldPassport from "components/Admin/HrPanel/ViewLinguist/Documents/Passport";
import LINOldAddress from "components/Admin/HrPanel/ViewLinguist/Documents/ProofAddressPhoto";
import LINOldReleventDocument from "components/Admin/HrPanel/ViewLinguist/Documents/ReleventDocument";
import LINOldReleventCertificate from "components/Admin/HrPanel/ViewLinguist/Documents/ReleventCertificate";
import LINOldSecurityClearence from "components/Admin/HrPanel/ViewLinguist/Documents/SecurityClearence";
import LINOldCV from "components/Admin/HrPanel/ViewLinguist/Documents/CV";
import LINOldIsuranceDocument from "components/Admin/HrPanel/ViewLinguist/Documents/InsuranceDocument";
import LINOldReferenceLetter from "components/Admin/HrPanel/ViewLinguist/Documents/ReferenceLetter";
import LINOldInterpretingDocument from "components/Admin/HrPanel/ViewLinguist/Documents/InterpretingDocument";
import LINOldTranslationDocument from "components/Admin/HrPanel/ViewLinguist/Documents/TranslationDocument";
import LINOldPSIPSTDocument from "components/Admin/HrPanel/ViewLinguist/Documents/PSIPSTDocument";
import LINOldAnyOtherDocument from "components/Admin/HrPanel/ViewLinguist/Documents/AnyOtherDocument";

import INVcac from "pages/admin/AccountAndFinance/InvoiceByLinguist/InterpretingInvoice/invoice";
import INVbac from "pages/admin/AccountAndFinance/InvoiceByLinguist/TranslationInvoice/invoice";
import INVinc from "pages/admin/AccountAndFinance/InvoiceByLinguist/QuoteInvoice/invoice";
import PRCDirectInvoiceList from "pages/admin/AccountAndFinance/InvoiceByLinguist/PRCDirectInvoice/invoice";

// timesheet
import ViewTimesheet from "pages/admin/TimesheetProjectReport/Timesheet/ViewTimesheet";

import ViewProjectReport from "pages/admin/TimesheetProjectReport/ProjectReport/ViewProjectReport";
import AmendProjectReport from "pages/admin/TimesheetProjectReport/ProjectReport/AmendProjectReport";

import ViewInvoice from "pages/admin/TimesheetProjectReport/Invoice/ViewInvoice";
import ViewPRCDirectInvoice from "pages/admin/TimesheetProjectReport/Invoice/ViewPRCDirectInvoice";
import ViewDirectInvoice from "pages/admin/TimesheetProjectReport/Invoice/ViewDirectInvoice";
import ViewLINDirectInvoice from "pages/admin/TimesheetProjectReport/Invoice/ViewLINDirectInvoice";
import ViewDirectQuote from "pages/admin/AccountAndFinance/QuoteToClient/ViewDirectQuote";

import CreateQuoteProjectReport from "pages/admin/TimesheetProjectReport/ProjectReport/CreateQuoteProjectReport";
import RemarkQuoteProjectReport from "pages/admin/TimesheetProjectReport/ProjectReport/RemarkQuoteProjectReport";
import ViewCACLINProvidedQuote from "pages/admin/TimesheetProjectReport/Quote/ViewCACLINProvidedQuote";

import ViewMonthlyBill from "pages/admin/AccountAndFinance/Bills/ViewMonthlyBill";
import ViewAllBills from "pages/admin/AccountAndFinance/Bills/ViewAllBills";
import ResendBill from "pages/admin/AccountAndFinance/Bills/ResendBill";
import CurrencyConversion from "pages/admin/AccountAndFinance/Currency/Currency";

import AssignmentDocument from "pages/Component/ViewAssignmentDocuments";
import ClientDirectInvoice from "pages/admin/AccountAndFinance/InvoiceToClient/invoice";
import SPPODirectInvoice from "pages/admin/AccountAndFinance/SPPOQuoteInvoice/InvoiceList";
import AdminToSPPODirectInvoice from "pages/admin/AccountAndFinance/SPPOQuoteInvoice/DirectInvoiceList";
import LinDirectInvoice from "pages/admin/AccountAndFinance/InvoiceToLinguist/invoice";
import ClientDirectQuote from "pages/admin/AccountAndFinance/QuoteToClient/quote";
import SPPODirectQuote from "pages/admin/AccountAndFinance/SPPOQuoteInvoice/QuoteList";

import CreateDirectQuoteProjectReport from "pages/admin/TimesheetProjectReport/ProjectReport/CreateDirectQuoteProjectReport";
import CreateDirectQuoteInvoice from "pages/admin/TimesheetProjectReport/Invoice/CreateDirectQuoteInvoice";

import EventMembers from "pages/admin/ManagementPanel/EventMembers";
import ColleagueNotes from "pages/admin/Task/ColleagueNotes";
import MyNotes from "pages/admin/Task/MyNotes";
import Notifications from "pages/admin/Notifications/Notifications";

import ViewAttachConversation from "pages/admin/Communication/ViewAttachConversation";
import ViewAttachTicket from "pages/admin/Communication/ViewAttachTicket";

import ViewAttachMessage from "pages/admin/WelcomeMessage/ViewAttachMessage";
import ApprovalPublicProfile from "pages/admin/Sppo/ApprovalPublicProfile";

import ViewPRCQuote from "pages/admin/BookingSecretary/PrivateQuote/ViewPRCQuote";

import ViewDocument from "components/Admin/CrmPanel/CAC/Documents/ViewDocument";

import ScanDuplicateLinguists from "pages/admin/HRPanel/ScanLinguist";

import LinguistInvitation from "pages/admin/HRPanel/LinguistInvitation";
import ViewInvitedLINDetails from "pages/admin/HRPanel/ViewInvitedLINDetails";

import ViewList from "pages/admin/ControlPanel/Backup/ViewList";
import ActivityLogList from "pages/admin/ActivityLogs/ActivityLogs";

import ViewProjectReportDocument from "pages/admin/TimesheetProjectReport/ProjectReport/ViewProjectReportDocument";
import ViewTimesheetDocument from "pages/admin/TimesheetProjectReport/Timesheet/ViewTimesheetDocument";

import ViewLeadPassport from "components/Admin/CrmPanel/Leads/Documents/ViewPassport";
import ViewLeadQuoteDocument from "components/Admin/CrmPanel/Leads/Documents/ViewEnquiryFile";
import ViewSppoPassport from "components/Admin/Sppo/SPPOProfile/ViewPassport";
import TranslationDeliveryDoc from "pages/Component/Translation_Delivery/ADM/ViewDocument";

import BulkTimesheetList from "pages/admin/BookingSecretary/Interpreting/BAC/BulkTimeSheetListPage";
import PostRateCard from "pages/admin/Card/PostRateCard";
import PostPercentCard from "pages/admin/Card/PostPercentCard";
import EditEvents from "pages/admin/ManagementPanel/EditMeetings";

const ADMIN_ROUTES = [
  {
    id: uuidv4(),
    text: "Dashboard",
    subText: "Manager Dashboard",
    path: "admin/dashboard",
    type: "dashboard",
    component: AdminDashboard,
    icon: Dicon,
  },
  {
    id: uuidv4(),
    path: "admin/add-position",
    component: ManagerPosition,
  },
  {
    id: uuidv4(),
    path: "admin/help",
    component: Help,
  },
  {
    id: uuidv4(),
    path: "admin/manager-privileges",
    component: ManagerPrivileges,
  },
  {
    id: uuidv4(),
    path: "admin/manage-privilege",
    component: ManagePrivilege,
  },
  {
    id: uuidv4(),
    path: "admin/send-form",
    component: SendAdminForm,
  },
  {
    id: uuidv4(),
    path: "admin/send-emp-reg-form",
    component: SendEmpForm,
  },
  {
    id: uuidv4(),
    path: "admin/send-cac-reg-form",
    component: SendCACForm,
  },
  {
    id: uuidv4(),
    path: "admin/send-bac-reg-form",
    component: SendBACForm,
  },
  {
    id: uuidv4(),
    path: "admin/reset-password",
    component: ResetPasswordPage,
  },
  {
    id: uuidv4(),
    path: "admin/auth/password/reset/:token",
    component: SetNewPassword,
  },
  {
    id: uuidv4(),
    path: "admin/auth/account/activate/:token",
    component: AdminAccountActivate,
  },
  {
    id: uuidv4(),
    path: "admin/inactive-manager-admin-list",
    component: InactiveManagerList,
  },
  {
    id: uuidv4(),
    path: "admin/executive-list",
    component: ManagerList,
  },
  {
    id: uuidv4(),
    path: "admin/admin-list",
    component: AdminList,
  },
  {
    id: uuidv4(),
    component: EmployeeList,
    path: "admin/employee-list",
    icon: Eicon,
  },
  {
    id: uuidv4(),
    path: "admin/add-account-client",
    component: AddAccountClient,
  },

  // booking secretary cac&bac interpreting route
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/confirm-assignments",
    component: ConfirmCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/confirm-assignments/:data/:type",
    component: ConfirmCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-bulk-interpreting",
    component: BACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/confirm-assignments",
    component: ConfirmBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/confirm-assignments/:data/:type",
    component: ConfirmBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/assigned-assignments",
    component: AssignedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/assigned-assignments/:data/:type",
    component: AssignedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/assigned-assignments",
    component: AssignedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/assigned-assignments/:data/:type",
    component: AssignedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/closed-assignments",
    component: ClosedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/closed-assignments",
    component: ClosedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/closed-assignments/:data/:type",
    component: ClosedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/closed-assignments/:data/:type",
    component: ClosedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/invoiced-assignments",
    component: InvoicedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/invoiced-assignments",
    component: InvoicedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/invoiced-assignments/:data/:type",
    component: InvoicedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/invoiced-assignments/:data/:type",
    component: InvoicedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/completed-assignments",
    component: CompletedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/completed-assignments",
    component: CompletedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/completed-assignments/:data/:type",
    component: CompletedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/completed-assignments/:data/:type",
    component: CompletedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/canceled-assignments",
    component: CanceledCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/cac-interpreting/canceled-assignments/:data/:type",
    component: CanceledCACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/canceled-assignments",
    component: CanceledBACAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/bac-interpreting/canceled-assignments/:data/:type",
    component: CanceledBACAssignments,
  },

  // booking secretary cac&bac translation route
  {
    id: uuidv4(),
    path: "admin/cac-translation/confirm-assignments",
    component: ConfirmCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/confirm-assignments/:data/:type",
    component: ConfirmCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/confirm-assignments",
    component: ConfirmBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/confirm-assignments/:data/:type",
    component: ConfirmBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/assigned-assignments",
    component: AssignedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/assigned-assignments/:data/:type",
    component: AssignedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/assigned-assignments",
    component: AssignedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/assigned-assignments/:data/:type",
    component: AssignedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/closed-assignments",
    component: ClosedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/closed-assignments",
    component: ClosedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/closed-assignments/:data/:type",
    component: ClosedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/closed-assignments/:data/:type",
    component: ClosedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/invoiced-assignments",
    component: InvoicedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/invoiced-assignments",
    component: InvoicedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/invoiced-assignments/:data/:type",
    component: InvoicedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/invoiced-assignments/:data/:type",
    component: InvoicedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/completed-assignments",
    component: CompletedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/completed-assignments",
    component: CompletedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/completed-assignments/:data/:type",
    component: CompletedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/completed-assignments/:data/:type",
    component: CompletedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/canceled-assignments",
    component: CanceledCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/canceled-assignments",
    component: CanceledBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/cac-translation/canceled-assignments/:data/:type",
    component: CanceledCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-translation/canceled-assignments/:data/:type",
    component: CanceledBACAssignmentsTranslation,
  },

  // booking secretary panel cac&bac quotation from clients routes
  {
    id: uuidv4(),
    path: "admin/quotation/cac-requestedQuote",
    component: RequestedCACQuote,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-requestedQuote/:data/:type",
    component: RequestedCACQuote,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-requestedQuote",
    component: RequestedBACQuote,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-requestedQuote/:data/:type",
    component: RequestedBACQuote,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-respondedQuotedProvided",
    component: RespondedCACQuoteProvided,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-respondedQuotedProvided",
    component: RespondedBACQuoteProvided,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-byClientQuote",
    component: ConfirmedByCACCliendQuote,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-byClientQuote/:data/:type",
    component: ConfirmedByCACCliendQuote,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-byClientQuote",
    component: ConfirmedByBACCliendQuote,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-byClientQuote/:data/:type",
    component: ConfirmedByBACCliendQuote,
  },

  {
    id: uuidv4(),
    path: "admin/quotation/cac-closed-jobs",
    component: ClosedCACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-closed-jobs",
    component: ClosedBACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-closed-jobs/:data/:type",
    component: ClosedCACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-closed-jobs/:data/:type",
    component: ClosedBACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-invoiced-jobs",
    component: InvoicedCACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-invoiced-jobs",
    component: InvoicedBACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-invoiced-jobs/:data/:type",
    component: InvoicedCACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-invoiced-jobs/:data/:type",
    component: InvoicedBACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-completed-jobs",
    component: CompletedCACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-completed-jobs",
    component: CompletedBACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-completed-jobs/:data/:type",
    component: CompletedCACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-completed-jobs/:data/;type",
    component: CompletedBACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-canceled-jobs",
    component: CanceledCACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-canceled-jobs",
    component: CanceledBACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/cac-canceled-jobs/:data/:type",
    component: CanceledCACJobs,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/bac-canceled-jobs/:data/:type",
    component: CanceledBACJobs,
  },

  // booking secretary inc interpreting route
  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/confirm-assignments",
    component: ConfirmAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/confirm-assignments/:data/:type",
    component: ConfirmAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/assigned-assignments",
    component: AssignedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/assigned-assignments/:data/:type",
    component: AssignedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/closed-assignments",
    component: ClosedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/closed-assignments/:data/:type",
    component: ClosedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/invoiced-assignments",
    component: InvoicedAssignmentsINC,
  },

  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/completed-assignments",
    component: CompletedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/invoiced-assignments/:data/:type",
    component: InvoicedAssignmentsINC,
  },

  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/completed-assignments/:data/:type",
    component: CompletedAssignmentsINC,
  },

  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/canceled-assignments",
    component: CanceledAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-interpreting/canceled-assignments/:data/:type",
    component: CanceledAssignmentsINC,
  },

  // booking secretary prc interpreting route
  {
    id: uuidv4(),
    path: "admin/private-interpreting/confirm-assignments",
    component: ConfirmAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "admin/private-interpreting/confirm-assignments/:data/:type",
    component: ConfirmAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "admin/private-interpreting/closed-assignments",
    component: ClosedAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "admin/private-interpreting/closed-assignments/:data/:type",
    component: ClosedAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "admin/private-interpreting/invoiced-assignments",
    component: InvoicedAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "admin/private-interpreting/completed-assignments",
    component: CompletedAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "admin/private-interpreting/invoiced-assignments/:data/:type",
    component: InvoicedAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "admin/private-interpreting/completed-assignments/:data/:type",
    component: CompletedAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "admin/private-interpreting/canceled-assignments",
    component: CanceledAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "admin/private-interpreting/canceled-assignments/:data/:type",
    component: CanceledAssignmentsPRC,
  },

  // booking secretary inc translation route
  {
    id: uuidv4(),
    path: "admin/inhouse-translation/confirm-assignments",
    component: ConfirmTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-translation/confirm-assignments/:data/:type",
    component: ConfirmTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-translation/assigned-assignments",
    component: AssignedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-translation/assigned-assignments/:data/:type",
    component: AssignedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-translation/closed-assignments",
    component: ClosedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-translation/invoiced-assignments",
    component: InvoicedTAssignmentsINC,
  },

  {
    id: uuidv4(),
    path: "admin/inhouse-translation/completed-assignments",
    component: CompletedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-translation/invoiced-assignments/:data/:type",
    component: InvoicedTAssignmentsINC,
  },

  {
    id: uuidv4(),
    path: "admin/inhouse-translation/completed-assignments/:data/:type",
    component: CompletedTAssignmentsINC,
  },

  {
    id: uuidv4(),
    path: "admin/inhouse-translation/canceled-assignments",
    component: CanceledTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-translation/canceled-assignments/:data/:type",
    component: CanceledTAssignmentsINC,
  },

  // booking secretary prc translation route
  {
    id: uuidv4(),
    path: "admin/private-translation/confirm-assignments",
    component: ConfirmTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "admin/private-translation/confirm-assignments/:data/:type",
    component: ConfirmTAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "admin/private-translation/closed-assignments",
    component: ClosedTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "admin/private-translation/invoiced-assignments",
    component: InvoicedTAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "admin/private-translation/completed-assignments",
    component: CompletedTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "admin/private-translation/invoiced-assignments/:data/:type",
    component: InvoicedTAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "admin/private-translation/completed-assignments/:data/:type",
    component: CompletedTAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "admin/private-translation/canceled-assignments",
    component: CanceledTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "admin/private-translation/canceled-assignments/:data/:type",
    component: CanceledTAssignmentsPRC,
  },

  // end

  //Booking secretary unconfirmed assignment routes
  {
    id: uuidv4(),
    path: "admin/unconfirmed-assignments/cash-client",
    component: CACUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/unconfirmed-assignments/cash-client/:data/:type",
    component: CACUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/unconfirmed-assignments/billing-account-client",
    component: BACUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/unconfirmed-assignments/billing-account-client/:data/:type",
    component: BACUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/unconfirmed-assignments/inhouse-client",
    component: INCUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/unconfirmed-assignments/inhouse-client/:data/:type",
    component: INCUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/unconfirmed-assignments/private-client",
    component: PRCUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/unconfirmed-assignments/private-client/:data/:type",
    component: PRCUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/all-inactive-assignments",
    component: AllInactiveAssignments,
  },

  // account client route
  {
    id: uuidv4(),
    path: "admin/view-account-client",
    component: ViewAccountClient,
  },
  {
    id: uuidv4(),
    path: "admin/view-inactive-account-client",
    component: ViewInactiveAccountClient,
  },
  {
    id: uuidv4(),
    path: "admin/view-account-client/:id",
    component: ViewPartAccountClientPage,
  },
  {
    id: uuidv4(),
    path: "admin/edit-account-client/:id",
    component: EditAccountClient,
  },
  {
    id: uuidv4(),
    path: "admin/add-cash-client",
    component: AddCashClient,
  },
  {
    id: uuidv4(),
    path: "admin/view-cash-client",
    component: ViewCashClient,
  },
  {
    id: uuidv4(),
    path: "admin/view-cash-client/:id",
    component: ViewPartCashClientPage,
  },
  {
    id: uuidv4(),
    path: "admin/view-admin/:id",
    component: ViewAdminDetails,
  },
  {
    id: uuidv4(),
    path: "admin/view-admin-details/:id",
    component: UpdateAdminDetails,
  },
  {
    id: uuidv4(),
    path: "admin/view-employee/:id",
    component: ViewEmployeeDetails,
  },

  {
    id: uuidv4(),
    path: "admin/view-sppo/:id",
    component: ViewSppoDetails,
  },
  {
    id: uuidv4(),
    path: "admin/view-inhouse/:id",
    component: ViewInhouseDetails,
  },
  {
    id: uuidv4(),
    path: "admin/view-private/:id",
    component: ViewPrivateDetails,
  },
  {
    id: uuidv4(),
    path: "admin/view-cla/:id",
    component: ViewCLADetails,
  },
  {
    id: uuidv4(),
    path: "admin/send-cla-registration-form",
    component: SendCLARegistrationForm,
  },
  {
    id: uuidv4(),
    path: "admin/send-CAC-BAC-registration-form",
    component: SendCACBACRegistrationForm,
  },
  {
    id: uuidv4(),
    path: "contact-removal-request",
    component: LeadRemovalForm,
  },
  {
    id: uuidv4(),
    path: "invitation-removal-request",
    component: InvitationRemovalForm,
  },
  {
    id: uuidv4(),
    path: "admin/lead-contact-group",
    component: LeadContactGroup,
  },
  {
    id: uuidv4(),
    path: "admin/LIN-group",
    component: LinGroup,
  },
  {
    id: uuidv4(),
    path: "admin/view-linguist-question/:id",
    component: ViewLinQuestion,
  },
  {
    id: uuidv4(),
    path: "admin/view-cash-client-question/:id",
    component: ViewCACQuestion,
  },
  {
    id: uuidv4(),
    path: "admin/view-account-client-question/:id",
    component: ViewBACQuestion,
  },
  {
    id: uuidv4(),
    path: "admin/view-sppo-question/:id",
    component: ViewSPPOQuestion,
  },
  {
    id: uuidv4(),
    path: "admin/view-cash-client-booking-interpreting/:id",
    component: ViewCashClientBooking,
  },
  {
    id: uuidv4(),
    path: "admin/view-account-client-booking-interpreting/:id",
    component: ViewAccountClientBooking,
  },
  {
    id: uuidv4(),
    path: "admin/view-inhouse-client-booking-interpreting/:id",
    component: ViewInhouseClientBooking,
  },
  {
    id: uuidv4(),
    path: "admin/view-private-client-booking-interpreting/:id",
    component: ViewPrivateClientBooking,
  },

  // translation view details
  {
    id: uuidv4(),
    path: "admin/view-inhouse-client-translation/:id",
    component: ViewInhouseClientTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/view-private-client-translation/:id",
    component: ViewPrivateClientTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/view-cash-client-order-translation/:id",
    component: ViewCashClientTranslation,
  },

  {
    id: uuidv4(),
    path: "admin/view-account-client-order-translation/:id",
    component: ViewAccountClientTranslation,
  },

  // interpreting and translation document
  {
    id: uuidv4(),
    path: "admin/view-cac-interpreting-document/:id",
    component: ViewCACInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "admin/view-cac-translation-document/:id",
    component: ViewCACTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "admin/view-bac-interpreting-document/:id",
    component: ViewBACInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "admin/view-bac-translation-document/:id",
    component: ViewBACTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "admin/inc-view-interpreting-document/:id",
    component: viewINCInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "admin/inc-view-translation-document/:id",
    component: viewINCTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "admin/prc-view-interpreting-document/:id",
    component: viewPRCInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "admin/prc-view-translation-document/:id",
    component: viewPRCTranslationDocument,
  },

  // view quote document
  {
    id: uuidv4(),
    path: "admin/cac-view-quotation-document/:id",
    component: viewCACQuoteDocument,
  },
  {
    id: uuidv4(),
    path: "admin/bac-view-quotation-document/:id",
    component: viewBACQuoteDocument,
  },

  // end

  {
    id: uuidv4(),
    path: "admin/view-cash-client-quotation/:id",
    component: ViewCashClientQuotation,
  },
  {
    id: uuidv4(),
    path: "admin/view-account-client-quotation/:id",
    component: ViewAccountClientQuotation,
  },

  {
    id: uuidv4(),
    path: "admin/add-preregistration-linguists",
    component: PreRegistrationPage,
  },
  {
    id: uuidv4(),
    path: "admin/list-unregistered-linguists",
    component: ListUnregisteredLinguist,
  },
  {
    id: uuidv4(),
    path: "admin/unregistered-linguists-list",
    component: UnregisteredLinguistList,
  },
  {
    id: uuidv4(),
    path: "admin/registered-linguists-list",
    component: RegisteredLinguistList,
  },
  {
    id: uuidv4(),
    path: "admin/registered-unregistered-linguists-list",
    component: SearchAllLin,
  },
  {
    id: uuidv4(),
    path: "admin/registered-unregistered-multi-linguists-list",
    component: SearchMultiAllLin,
  },
  {
    id: uuidv4(),
    path: "admin/view-unregistered-linguist-client/:id",
    component: ViewUnregisteredLinguists,
  },
  {
    id: uuidv4(),
    path: "admin/view-unregistered-linguist-cv/:id",
    component: ViewUnregisteredLinguistsCV,
  },
  {
    id: uuidv4(),
    path: "admin/send-form-registration",
    component: SendRegistrationForm,
  },
  {
    id: uuidv4(),
    path: "admin/send-form-registration-select",
    component: SendSelectedRegistrationForm,
  },
  {
    id: uuidv4(),
    path: "admin/unverified-linguists",
    component: UnverifiedLinguists,
  },
  {
    id: uuidv4(),
    path: "admin/view-linguist-profile/:id",
    component: ViewUnverifiedLinguist,
  },
  {
    id: uuidv4(),
    path: "admin/verified-linguists",
    component: VerifiedLinguist,
  },

  {
    id: uuidv4(),
    path: "admin/suspend-linguists",
    component: SuspendLinguist,
  },

  // sppo
  {
    id: uuidv4(),
    path: "admin/unverified-sppo",
    component: UnverifiedSPPO,
  },
  {
    id: uuidv4(),
    path: "admin/verified-sppo",
    component: verifiedSPPO,
  },
  // end sppo

  {
    id: uuidv4(),
    component: ViewContactCard,
    path: "admin/view-contact-card",
  },
  {
    id: uuidv4(),
    path: "admin/accepted-jobadvert",
    component: ViewAcceptedjob,
  },
  {
    id: uuidv4(),
    path: "admin/accepted-jobadvert/:data/:type",
    component: ViewAcceptedjob,
  },
  {
    id: uuidv4(),
    path: "admin/declined-jobadvert",
    component: ViewDeclinedJob,
  },
  {
    id: uuidv4(),
    path: "admin/declined-jobadvert/:data/:type",
    component: ViewDeclinedJob,
  },
  {
    id: uuidv4(),
    path: "admin/view-inhouse-assignment/:id",
    component: ViewInhouseAssignment,
  },
  {
    id: uuidv4(),
    path: "admin/view-translation-assignment/:id",
    component: ViewTranslationAssignment,
  },
  {
    id: uuidv4(),
    path: "admin/amend_job/:id",
    component: BI_edit_page,
  },

  //saved private quotes
  {
    id: uuidv4(),
    component: PrivateSavedQuote,
    path: "admin/private-quote/saved-quote",
  },

  {
    id: uuidv4(),
    component: PrivateAcceptedQuote,
    path: "admin/private-quote/accepted-quote",
  },

  {
    id: uuidv4(),
    text: "Booking Secretary",
    path: "booking-secretary",
    icon: BSicon,
    component: BookingPanel,
    subMenu: [
      {
        id: uuidv4(),
        text: "CAC & BAC Interpreting assignments",
        path: "admin/interpreting-assignments",
        component: InterpretingAssignments,
        icon: IAicon,
      },
      {
        id: uuidv4(),
        text: "CAC & BAC Translation assignments",
        path: "admin/translation-assignments",
        component: TranslationAssignments,
        icon: TAicon,
      },
      {
        id: uuidv4(),
        text: "CAC & BAC Quotation",
        component: QuotationFromClients,
        path: "admin/quotation-from-clients",
        icon: QFCicon,
      },
      {
        id: uuidv4(),
        text: "Inhouse interpreting assignments",
        component: InhouseInterpretingAssignments,
        path: "admin/inhouse-interpreting-assignments",
        icon: IIAicon,
      },
      {
        id: uuidv4(),
        text: "Inhouse translating assignments",
        component: InhouseTranslatingAssignments,
        path: "admin/inhouse-translating-assignments",
        icon: ITAicon,
      },
      {
        id: uuidv4(),
        text: "Private Interpreting Assignments",
        component: PrivateInterpretingJobView,
        path: "admin/private-interpreting-job-view",
        icon: PIJicon,
      },
      {
        id: uuidv4(),
        text: "Private Translation Assignments",
        component: PrivateTranslationJobView,
        path: "admin/private-translation-job-view",
        icon: PTJicon,
      },
      {
        id: uuidv4(),
        text: "Private Quotes",
        component: PrivateSentQuote,
        path: "admin/private-quote/sent-quote",
        icon: PQJicon,
      },
      {
        id: uuidv4(),
        text: "Unconfirmed Assignments",
        path: "admin/unconfirmed-assignments",
        component: UnconfirmedAssignmentsPage,
        icon: UCAicon,
      },

      {
        id: uuidv4(),
        text: "Job Advert",
        component: PaidJobAdvert,
        path: "admin/paid-job-advert",
        icon: JAicon,
      },

      {
        id: uuidv4(),
        text: "Direct Invoice",
        component: CreateInvoiceToClient,
        path: "admin/direct-invoice",
        icon: Iicon,
      },

      // {
      //   id: uuidv4(),
      //   text: "Projects",
      //   component: Projects,
      //   path: "admin/project",
      //   icon: PTicon,
      // },
      {
        id: uuidv4(),
        text: "Find Linguist",
        component: FindLinguist,
        path: "admin/find-linguist",
        icon: FLicon,
      },
      {
        id: uuidv4(),
        text: "Direct Quote by LC",
        component: FindQuote,
        path: "admin/find-quote",
        icon: FDQicon,
      },
      {
        id: uuidv4(),
        text: "Leads",
        component: LeadsBooking,
        path: "admin/bookingsecretary/lead",
        icon: LEicon,
      },
      {
        id: uuidv4(),
        text: "Price Calculator",
        component: PriceCalculator,
        path: "admin/price-calculator",
        icon: PCNicon,
      },
      {
        id: uuidv4(),
        text: "Rate Card View",
        component: RateCardViewBookingSecretary,
        path: "admin/view-rate-card-BookingSecretary",
        icon: VRicon,
      },
      {
        id: uuidv4(),
        text: "CARP Card View",
        component: CarpCardViewBookingSecretary,
        path: "admin/view-carp-card-BookingSecretary",
        icon: VCicon,
      },
      {
        id: uuidv4(),
        text: "Percentage Card View",
        component: PercentageCardViewBookingSecretary,
        path: "admin/view-percentage-card-BookingSecretary",
        icon: VPicon,
      },
      {
        id: uuidv4(),
        text: "Bank Details",
        path: "admin/view-bank-details-booking-secretary",
        component: ViewBankDetailsBookingSecretary,
        icon: VBDicon,
      },
      {
        id: uuidv4(),
        text: "Help Contacts",
        component: HelpContact,
        path: "admin/help-contact",
        icon: HPicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "Task, Note And Do List",
    path: "admin/task-lists",
    component: TaskLists,
    icon: NTMicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Task List",
        path: "admin/task-lists",
        component: TaskLists,
        icon: Ticon,
      },
      {
        id: uuidv4(),
        text: "Notes And Do List - Colleagues",
        path: "admin/colleague-notes",
        component: ColleagueNotes,
        icon: NCCRicon,
      },
      {
        id: uuidv4(),
        text: "My Notes And Do List",
        path: "admin/my-notes",
        component: MyNotes,
        icon: NMCRicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "Communication",
    component: Communication,
    path: "admin/communication",
    icon: CMicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Communication",
        component: Communication,
        path: "admin/communication",
        icon: COicon,
      },

      {
        id: uuidv4(),
        text: "Contact Request",
        path: "admin/contact-request",
        component: ContactRequest,
        icon: CRicon,
      },
      {
        id: uuidv4(),
        text: "Help Contacts",
        component: HelpContactCommunication,
        path: "admin/communication-help",
        icon: HPicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "HR Panel",
    path: "hr-panel",
    component: HrPanel,
    icon: HRicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Employees",
        path: "admin/employees",
        component: Employees,
        icon: Eicon,
      },
      {
        id: uuidv4(),
        text: "Linguists",
        path: "admin/linguists",
        component: Linguists,
        icon: Licon,
      },
      {
        id: uuidv4(),
        text: "Duplicate LIN ID Scan",
        path: "admin/scan-duplicate-linguist",
        component: ScanDuplicateLinguists,
        icon: DiplicateLINicon,
      },

      {
        id: uuidv4(),
        text: "Unregistered Linguist",
        component: UnregisteredLinguist,
        path: "admin/unregistered-linguist",
        icon: ULicon,
      },
      {
        id: uuidv4(),
        text: "Linguists Invitation",
        component: LinguistInvitation,
        path: "admin/linguist-invitation",
        icon: ULicon,
      },
      {
        id: uuidv4(),
        text: "Linguist Removal Request",
        component: LinguistRemovalRequest,
        path: "admin/linguist-removal-request",
        icon: LRQicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "CRM Panel",
    path: "crm-panel",
    component: CrmPanel,
    icon: CRMicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Cash client",
        component: CashClient,
        path: "admin/cash-client",
        icon: CCicon,
      },
      {
        id: uuidv4(),
        text: "Account client",
        component: AccountClient,
        path: "admin/account-client",
        icon: ACicon,
      },
      {
        id: uuidv4(),
        text: "SPP Organisations",
        component: SppOrganisations,
        path: "admin/spp-organisations",
        icon: SPOicon,
      },
      {
        id: uuidv4(),
        text: "Inhouse client",
        component: InhouseClient,
        path: "admin/inhouse-client",
        icon: ICicon,
      },
      {
        id: uuidv4(),
        text: "Private client",
        component: PrivateClient,
        path: "admin/private-client",
        icon: PCSicon,
      },
      {
        id: uuidv4(),
        text: "Leads",
        component: Leads,
        path: "admin/lead",
        icon: LEicon,
      },
      {
        id: uuidv4(),
        text: "Subscription",
        component: Subscription,
        path: "admin/subscription",
        icon: Sicon,
      },
      {
        id: uuidv4(),
        text: "Account Removal Requests",
        component: CACAccountRemoval,
        path: "admin/account-removal-requests/cash-client",
        icon: ARRicon,
      },
    ],
  },
  {
    id: uuidv4(),
    component: BACAccountRemoval,
    path: "admin/account-removal-requests/account-client",
  },
  {
    id: uuidv4(),
    component: INCAccountRemoval,
    path: "admin/account-removal-requests/inhouse-client",
  },
  {
    id: uuidv4(),
    component: PRCAccountRemoval,
    path: "admin/account-removal-requests/private-client",
  },
  {
    id: uuidv4(),
    component: SPPOAccountRemoval,
    path: "admin/account-removal-requests/sppo",
  },
  {
    id: uuidv4(),
    component: CreateInvoiceLinguist,
    path: "admin/invoice-to-linguist/create-invoice",
  },

  {
    id: uuidv4(),
    path: "admin/invoice-to-client/create-invoice",
    component: CreateInvoiceToClient,
  },

  {
    id: uuidv4(),
    path: "admin/invoice-to-sppo/create-invoice",
    component: CreateInvoiceToSPPO,
  },

  {
    id: uuidv4(),
    text: "Account / Finance",
    path: "account-finance",
    component: AccountFinance,
    icon: AFicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Invoice By Linguist",
        component: InvoiceByLinguist,
        path: "admin/invoice-by-linguist",
        icon: IBLicon,
      },
      {
        id: uuidv4(),
        text: "Invoice To Linguist",
        component: InvoiceLinguist,
        path: "admin/invoice-to-linguist",
        icon: ITLicon,
      },
      {
        id: uuidv4(),
        text: "Invoice To Client",
        path: "admin/invoice-to-client",
        component: InvoiceClient,
        icon: ITCicon,
      },
      {
        id: uuidv4(),
        text: "SPPO Quotes and Invoices ",
        path: "admin/sppo-quotes-invoices",
        component: SPPOQuoteInvoice,
        icon: SITCicon,
      },
      {
        id: uuidv4(),
        text: "Bills",
        component: Bills,
        path: "admin/bills",
        icon: Bicon,
      },
      {
        id: uuidv4(),
        text: "Overpayment Refund Request",
        component: Refund,
        path: "admin/refund",
        icon: OVRicon,
      },
      {
        id: uuidv4(),
        text: "Stripe Details",
        path: "admin/stripe-clients",
        component: StripeClients,
        icon: SDicon,
      },

      {
        id: uuidv4(),
        text: "Finance Ledger",
        component: Finance,
        path: "admin/finance",
        icon: Ficon,
      },
      {
        id: uuidv4(),
        text: "Cashbook",
        component: Cashbook,
        path: "admin/cashbook",
        icon: CABicon,
      },

      {
        id: uuidv4(),
        text: "Bank Details",
        path: "admin/view-bank-details",
        component: ViewBankDetails,
        icon: VBDicon,
      },

      {
        id: uuidv4(),
        text: "Currency",
        path: "admin/currency-conversion",
        component: CurrencyConversion,
        icon: CCNicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "Control Panel",
    path: "control-panel",
    component: ControlPanel,
    icon: CPicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Queuing Panel",
        component: PanelSettings,
        path: "admin/panel-Queuing",
        icon: PSicon,
      },

      // {
      //   id: uuidv4(),
      //   text: "Web Settings",
      //   component: WebSettings,
      //   path: "admin/web-settings",
      //   icon: WSicon,
      // },
      {
        id: uuidv4(),
        text: "SMTP-Notification Settings",
        component: SmtpNotification,
        path: "admin/smtp-notification-setting",
        icon: SMTPicon,
      },
      {
        id: uuidv4(),
        text: "Email Settings",
        component: EmailSettings,
        path: "admin/email-settings",
        icon: ESicon,
      },
      {
        id: uuidv4(),
        text: "Rate Card",
        component: RateCard,
        path: "admin/rate-card",
        icon: RCicon,
      },
      {
        id: uuidv4(),
        text: "View Rate Card",
        component: RateCardView,
        path: "admin/view-rate-card",
        icon: VRicon,
      },
      {
        id: uuidv4(),
        text: "Percentage Card",
        component: PercentageCard,
        path: "admin/percentage-card",
        icon: PTCicon,
      },
      {
        id: uuidv4(),
        text: "View Percentage Card ",
        component: PercentageCardView,
        path: "admin/view-percentage-card",
        icon: VPicon,
      },
      {
        id: uuidv4(),
        text: "CARP Card",
        component: CarpCardPage,
        path: "admin/carp-card",
        icon: CARPicon,
      },
      {
        id: uuidv4(),
        text: "View CARP Card ",
        component: CarpCardView,
        path: "admin/view-carp-card",
        icon: VCicon,
      },

      {
        id: uuidv4(),
        text: "Bank Details",
        path: "admin/add-bank-details",
        component: AddBankDetails,
        icon: BDicon,
      },

      {
        id: uuidv4(),
        text: "View Bank Details",
        path: "admin/view-admin-bank-details",
        component: ViewAdminBankDetails,
        icon: VBDicon,
      },

      {
        id: uuidv4(),
        text: "CMS",
        component: Cms,
        path: "admin/cms",
        icon: CMSicon,
      },
      {
        id: uuidv4(),
        text: "Backup",
        component: Backup,
        path: "admin/backup",
        icon: BACKicon,
      },

      {
        id: uuidv4(),
        text: "Manager & Employee",
        component: AdminEmployeeAccess,
        path: "admin/admin&employee",
        icon: AEicon,
      },

      {
        id: uuidv4(),
        text: "Company Info Card",
        component: ContactForm,
        path: "admin/contact-form",
        icon: CICicon,
      },

      {
        id: uuidv4(),
        text: "Archive",
        component: Archive,
        path: "admin/archive",
        icon: Aicon,
      },
      {
        id: uuidv4(),
        text: "All Activity Logs",
        component: ActivityLogs,
        path: "admin/activity-logs",
        icon: ActivityLogIcon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "Management Panel",
    path: "management-panel",
    component: ManagementPanel,
    icon: MPicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "News and Notice",
        component: Notice,
        path: "admin/news-and-notice",
        icon: Nicon,
      },
      {
        id: uuidv4(),
        text: "Meetings and Events",
        component: Meetings,
        path: "admin/meetings",
        icon: Micon,
      },

      {
        id: uuidv4(),
        text: "Reports",
        component: CACReports,
        path: "admin/reports/cac",
        icon: Ricon,
      },
      {
        id: uuidv4(),
        text: "Message from management",
        component: MessageToLin,
        path: "admin/welcome-message",
        icon: MFMicon,
      },
      {
        id: uuidv4(),
        text: "Help Tutorials",
        path: "admin/help-tutorials",
        component: HelpTutorials,
        icon: HTicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "Activity Logs",
    path: "admin/my-activity-logs",
    component: MyActivityLogs,
    icon: ActivityLogIcon,
  },

  {
    id: uuidv4(),
    path: "admin/view-contact-Request/:id",
    component: ViewContactRequest,
    icon: CRicon,
  },

  {
    id: uuidv4(),
    path: "admin/profile",
    component: ProfilePage,
  },

  {
    id: uuidv4(),
    path: "admin/cac-confirmed-interpreters",
    component: cacconfirmbooking,
  },
  {
    id: uuidv4(),
    path: "admin/bac-confirmed-interpreters",
    component: bacconfirmbooking,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-confirmed-interpreters",
    component: inhouseconfirmbooking,
  },

  {
    id: uuidv4(),
    path: "admin/cac-confirmed-translations",
    component: cacconfirmTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/bac-confirmed-translations",
    component: bacconfirmTranslation,
  },
  {
    id: uuidv4(),
    path: "admin/inhouse-confirmed-translations",
    component: inhouseconfirmTranslation,
  },

  {
    id: uuidv4(),
    path: "admin/cac-confirmed-quotations",
    component: cacconfirmQuotations,
  },
  {
    id: uuidv4(),
    path: "admin/bac-confirmed-quotations",
    component: bacconfirmQuotations,
  },

  {
    id: uuidv4(),
    path: "admin/archive-private-client",
    component: ArchivePRC,
  },

  {
    id: uuidv4(),
    path: "admin/archive-inhouse-client",
    component: ArchiveINC,
  },

  {
    id: uuidv4(),
    path: "admin/archive-account-client",
    component: ArchiveBAC,
  },
  {
    id: uuidv4(),

    component: OpenCommunication,
    path: "admin/open-new-ticket",
  },
  {
    id: uuidv4(),

    component: ViewCommunication,
    path: "admin/view-tickets",
  },

  {
    id: uuidv4(),
    component: SendInvitation,
    path: "admin/send-invitation/:id/:ticketno",
  },

  {
    id: uuidv4(),
    path: "admin/archive-admins",
    component: ArchiveADM,
  },

  {
    id: uuidv4(),
    path: "admin/archive-cash-client",
    component: ArchiveCAC,
  },

  {
    id: uuidv4(),
    path: "admin/archive-employee",
    component: ArchiveEMP,
  },

  {
    id: uuidv4(),
    path: "admin/archive-linguists",
    component: ArchiveLIN,
  },

  {
    id: uuidv4(),
    path: "admin/archive-SPPO",
    component: ArchiveSPPO,
  },

  {
    id: uuidv4(),
    path: "admin/archive-jobadvert",
    component: ArchiveJOB,
  },

  {
    id: uuidv4(),
    path: "admin/archived-assignments",
    component: ArchiveAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/archived-cac-interpreting",
    component: ArchiveAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/archived-bac-interpreting",
    component: ArchiveAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/archived-inc-interpreting",
    component: ArchiveAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/archived-prc-interpreting",
    component: ArchiveAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/archived-cac-translation",
    component: ArchiveAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/archived-bac-translation",
    component: ArchiveAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/archived-inc-translation",
    component: ArchiveAssignments,
  },
  {
    id: uuidv4(),
    path: "admin/archived-prc-translation",
    component: ArchiveAssignments,
  },

  {
    id: uuidv4(),
    path: "admin/archived-timesheet-and-project-report",
    component: ArchiveTimesheetReport,
  },
  {
    id: uuidv4(),
    path: "admin/archived-close-and-timesheet",
    component: ArchiveTimesheetReport,
  },
  {
    id: uuidv4(),
    path: "admin/archived-project-report",
    component: ArchiveTimesheetReport,
  },

  {
    id: uuidv4(),
    path: "admin/archived-invoice-and-quote",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/invoiced-by-linguist-for-all-client",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/invoiced-by-linguist-for-PRC",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/invoiced-by-admin-for-all-client",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/invoiced-by-admin-for-sppo",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/invoiced-by-admin-for-linguist",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/quote-by-linguist-for-PRC",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/quote-by-admin-for-client",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/archived-requested-quote-by-cac",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/archived-requested-quote-by-bac",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/archived-provided-quote-by-linguist",
    component: ArchiveINV,
  },

  {
    id: uuidv4(),
    path: "admin/help-cac",
    component: HelpCAC,
  },
  {
    id: uuidv4(),
    path: "admin/help-bac",
    component: HelpBAC,
  },
  {
    id: uuidv4(),
    path: "admin/help-lin",
    component: HelpLIN,
  },
  {
    id: uuidv4(),
    path: "admin/help-sppo",
    component: HelpSPPO,
  },

  // linguist documents
  {
    id: uuidv4(),
    path: "admin/linguist-profile-photo-preview/:id",
    component: LINProfilePhoto,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-address-preview/:id",
    component: LINAddress,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-address-preview/:id",
    component: LINOldAddress,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-relevent-document/:id",
    component: LINReleventDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-relevent-document/:id",
    component: LINOldReleventDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-insurance-document/:id",
    component: LINIsuranceDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-insurance-document/:id",
    component: LINOldIsuranceDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-reference-letter/:id",
    component: LINReferenceLetter,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-reference-letter/:id",
    component: LINOldReferenceLetter,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-relevent-certificate/:id",
    component: LINReleventCertificate,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-relevent-certificate/:id",
    component: LINOldReleventCertificate,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-security-clearence/:id",
    component: LINOldSecurityClearence,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-security-clearence-1/:id",
    component: LINSecurityClearence1,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-security-clearence-2/:id",
    component: LINSecurityClearence2,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-security-clearence-3/:id",
    component: LINSecurityClearence3,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-security-clearence-4/:id",
    component: LINSecurityClearence4,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-other-experience-document/:id",
    component: ViewOtherExperienceDoc,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-cv-document/:id",
    component: ViewCVDoc,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-insurance-document/:id",
    component: ViewInsuranceDoc,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-interpreting-document/:id",
    component: ViewInterpretingDoc,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-translation-document/:id",
    component: ViewTranslationDoc,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-psi-pst-document/:id",
    component: ViewPSIPSTDoc,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-work-reference-letter/:id",
    component: ViewWorkReferenceLetter,
  },
  {
    id: uuidv4(),
    path: "admin/send-invitation",
    component: SendLINInvitation,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-relevent-certificate-document/:id",
    component: ViewReleventCertificate,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-relevent-document/:id",
    component: ViewReleventDoc,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-security-clearence-document1/:id",
    component: ViewSecurityDoc1,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-security-clearence-document2/:id",
    component: ViewSecurityDoc2,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-security-clearence-document3/:id",
    component: ViewSecurityDoc3,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-security-clearence-document4/:id",
    component: ViewSecurityDoc4,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-passport/:id",
    component: LINPassport,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-passport/:id",
    component: LINOldPassport,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-passport-document/:id",
    component: LINPassportDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-view-address-document/:id",
    component: LINAddressDocument,
  },
  {
    id: uuidv4(),
    path: "admin/upload-linguist-documents/:id",
    component: UploadLINDoc,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-cv/:id",
    component: LINCV,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-cv/:id",
    component: LINOldCV,
  },

  // invoice by linguist
  {
    id: uuidv4(),
    path: "admin/interpreting-invoice",
    component: INVcac,
  },
  {
    id: uuidv4(),
    path: "admin/translation-invoice",
    component: INVbac,
  },
  {
    id: uuidv4(),
    path: "admin/quote-invoice",
    component: INVinc,
  },
  {
    id: uuidv4(),
    path: "admin/prc-direct-invoices",
    component: PRCDirectInvoiceList,
  },

  {
    id: uuidv4(),
    path: "admin/active-employees",
    component: ActiveEmployees,
  },
  {
    id: uuidv4(),
    path: "admin/inactive-employees",
    component: InactiveEmployees,
  },
  // {
  //   id: uuidv4(),
  //   path: "admin/stripe-clients",
  //   component: StripeClients,
  // },
  {
    id: uuidv4(),
    path: "admin/stripe-connected-linguists",
    component: StripeDetails,
  },
  {
    id: uuidv4(),
    path: "admin/view-timesheet/:id",
    component: ViewTimesheet,
  },
  {
    id: uuidv4(),
    path: "admin/view-project-report/:id",
    component: ViewProjectReport,
  },
  {
    id: uuidv4(),
    path: "admin/amend-project-report/:id",
    component: AmendProjectReport,
  },

  {
    id: uuidv4(),
    path: "admin/view-invoice/:id",
    component: ViewInvoice,
  },

  {
    id: uuidv4(),
    path: "admin/view-direct-invoice/:id",
    component: ViewDirectInvoice,
  },

  {
    id: uuidv4(),
    path: "admin/view-prc-direct-invoice/:id",
    component: ViewPRCDirectInvoice,
  },

  {
    id: uuidv4(),
    path: "admin/view-linguist-direct-invoice/:id",
    component: ViewLINDirectInvoice,
  },

  {
    id: uuidv4(),
    path: "admin/view-direct-quote/:id",
    component: ViewDirectQuote,
  },

  // cac quotation
  {
    id: uuidv4(),
    path: "admin/quotation/byClientQuote/create-project-report/:id",
    component: CreateQuoteProjectReport,
  },
  {
    id: uuidv4(),
    path: "admin/quotation/byClientQuote/remark-project-report/:id",
    component: RemarkQuoteProjectReport,
  },

  {
    id: uuidv4(),
    path: "admin/view-provided-quote/:id",
    component: ViewCACLINProvidedQuote,
  },

  // bac monthly bill
  {
    id: uuidv4(),
    path: "admin/view-monthly-bill/:id",
    component: ViewMonthlyBill,
  },

  {
    id: uuidv4(),
    path: "admin/view-all-bills/:id",
    component: ViewAllBills,
  },

  {
    id: uuidv4(),
    path: "admin/resend-bill/:billID/:clientID",
    component: ResendBill,
  },

  {
    id: uuidv4(),
    path: "admin/view-assignment-document/:id",
    component: AssignmentDocument,
  },

  {
    id: uuidv4(),
    path: "admin/client-direct-invoices/:id/:name/:clientref",
    component: ClientDirectInvoice,
  },
  {
    id: uuidv4(),
    path: "admin/sppo-direct-invoices/:id/:name/:clientref",
    component: SPPODirectInvoice,
  },
  {
    id: uuidv4(),
    path: "admin/admin-to-sppo-direct-invoices",
    component: AdminToSPPODirectInvoice,
  },

  {
    id: uuidv4(),
    path: "admin/client-direct-quotes/:id/:name/:clientref",
    component: ClientDirectQuote,
  },
  {
    id: uuidv4(),
    path: "admin/sppo-direct-quotes/:id/:name/:clientref",
    component: SPPODirectQuote,
  },

  {
    id: uuidv4(),
    path: "admin/linguist-direct-invoices/:id/:name",
    component: LinDirectInvoice,
  },

  {
    id: uuidv4(),
    component: CreateQuotation,
    path: "admin/create-quotation",
  },

  {
    id: uuidv4(),
    path: "admin/create-direct-quote-project-report/:id",
    component: CreateDirectQuoteProjectReport,
  },

  {
    id: uuidv4(),
    path: "admin/create-direct-quote-invoice/:id",
    component: CreateDirectQuoteInvoice,
  },

  {
    id: uuidv4(),
    path: "admin/add-new-lead",
    component: AddLead,
  },

  {
    id: uuidv4(),
    path: "admin/event-registered-members",
    component: EventMembers,
  },

  {
    id: uuidv4(),
    path: "admin/view-attachment-ticket-conversation/:id",
    component: ViewAttachConversation,
  },

  {
    id: uuidv4(),
    path: "admin/view-ticket-attachment/:id",
    component: ViewAttachTicket,
  },

  {
    id: uuidv4(),
    path: "admin/view-welcome-message-attachment/:id",
    component: ViewAttachMessage,
  },

  // sppo public profile routes

  {
    id: uuidv4(),
    path: "admin/sppo-public-profile/:id",
    component: ApprovalPublicProfile,
  },
  //help tutorials
  {
    id: uuidv4(),
    path: "admin/help-tutorials/post-tutorial",
    component: PostTutorials,
  },
  {
    id: uuidv4(),
    path: "admin/help-tutorials/view-tutorials-list",
    component: ViewTutorials,
  },
  {
    id: uuidv4(),
    path: "admin/view-all-notifications",
    component: Notifications,
  },
  {
    id: uuidv4(),
    path: "admin/view-prc-quote-bylinguist/:id",
    component: ViewPRCQuote,
  },
  {
    id: uuidv4(),
    path: "admin/view-client-document/:id",
    component: ViewDocument,
  },
  {
    id: uuidv4(),
    path: "admin/cashclient-service-agreement/post-agreement",
    component: PostAgreement,
  },
  {
    id: uuidv4(),
    path: "admin/accountclient-service-agreement/post-agreement",
    component: PostAgreement,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-service-agreement/post-agreement",
    component: PostAgreement,
  },
  {
    id: uuidv4(),
    path: "admin/sppo-service-agreement/post-agreement",
    component: PostAgreement,
  },
  {
    id: uuidv4(),
    path: "admin/cashclient-service-agreement/view-agreements-list",
    component: ViewAgreement,
  },
  {
    id: uuidv4(),
    path: "admin/accountclient-service-agreement/view-agreements-list",
    component: ViewAgreement,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-service-agreement/view-agreements-list",
    component: ViewAgreement,
  },
  {
    id: uuidv4(),
    path: "admin/sppo-service-agreement/view-agreements-list",
    component: ViewAgreement,
  },
  {
    id: uuidv4(),
    path: "admin/service-agreement/edit-agreement/:id",
    component: EditAgreement,
  },

  {
    id: uuidv4(),
    path: "admin/linguist-public-profile-sliders/view-sliders/:id",
    component: ViewSlider,
  },
  {
    id: uuidv4(),
    path: "admin/sppo-public-profile-sliders/view-sliders/:id",
    component: ViewSlider,
  },
  {
    id: uuidv4(),
    path: "admin/job-advert-sliders/view-sliders/:id",
    component: ViewSlider,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-public-profile-sliders/view-sliders",
    component: ViewSliders,
  },
  {
    id: uuidv4(),
    path: "admin/sppo-public-profile-sliders/view-sliders",
    component: ViewSliders,
  },
  {
    id: uuidv4(),
    path: "admin/job-advert-sliders/view-sliders",
    component: ViewSliders,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-public-profile-sliders/post-slider",
    component: PostSliders,
  },
  {
    id: uuidv4(),
    path: "admin/sppo-public-profile-sliders/post-slider",
    component: PostSliders,
  },
  {
    id: uuidv4(),
    path: "admin/job-advert-sliders/post-slider",
    component: PostSliders,
  },
  {
    id: uuidv4(),
    path: "admin/job-advert-price/post-price",
    component: PostJobAdvertPrice,
  },
  {
    id: uuidv4(),
    path: "admin/post-percentage-card",
    component: PostPercentCard,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-interpreting-document/:id",
    component: LINInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-interpreting-document/:id",
    component: LINOldInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-translation-document/:id",
    component: LINTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-translation-document/:id",
    component: LINOldTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-psi-pst-document/:id",
    component: LINPSIPSTDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-psi-pst-document/:id",
    component: LINOldPSIPSTDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-any-other-document/:id",
    component: LINAnyOtherDocument,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-old-any-other-document/:id",
    component: LINOldAnyOtherDocument,
  },

  // terms and conditions
  {
    id: uuidv4(),
    path: "admin/terms-and-conditions/post-T&C",
    component: TandC,
    icon: HTicon,
  },

  {
    id: uuidv4(),
    path: "admin/terms-and-conditions/edit-T&C/:id",
    component: EditTandC,
    icon: HTicon,
  },

  {
    id: uuidv4(),
    path: "admin/terms-and-conditions/view-T&C-list",
    component: ViewTandC,
    icon: HTicon,
  },

  // privacy and policy
  {
    id: uuidv4(),
    path: "admin/privacy-policy/post-privacy-policy",
    component: PostPrivacyPolicy,
    icon: HTicon,
  },

  {
    id: uuidv4(),
    path: "admin/privacy-policy/edit-privacy-policy/:id",
    component: EditPrivacyPolicy,
    icon: HTicon,
  },

  {
    id: uuidv4(),
    path: "admin/privacy-policy/view-privacy-policy-list",
    component: ViewPrivacyPolicy,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/view-invited-linguist-details/:id",
    component: ViewInvitedLINDetails,
    icon: HTicon,
  },

  //report
  {
    id: uuidv4(),
    component: BACReports,
    path: "admin/reports/bac",
  },
  {
    id: uuidv4(),
    component: INCReports,
    path: "admin/reports/inc",
  },
  {
    id: uuidv4(),
    component: PRCReports,
    path: "admin/reports/prc",
  },

  // backup routes
  {
    id: uuidv4(),
    path: "admin/view-linguist-all-passport-document",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/view-linguist-all-public-professional-membership",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/view-linguist-all-public-security-clearance",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/view-linguist-all-public-educational-qualification",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/view-sppo-all-public-corporate-membership",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/view-sppo-all-public-certifications",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/sent-sppo-user-requests",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/sent-bac-user-requests",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/all-documents-of-assignments",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/assignment-document-requests",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/all-inactive-jobadvert",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/list-all-close-and-timesheet",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/list-all-project-report",
    component: ViewList,
    icon: HTicon,
  },

  {
    id: uuidv4(),
    path: "admin/registration-requests-CAC",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/registration-requests-BAC",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/registration-requests-ADM",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/registration-requests-EMP",
    component: ViewList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/registration-requests-Lead",
    component: ViewList,
    icon: HTicon,
  },

  // activity log routes
  {
    id: uuidv4(),
    path: "admin/my-activity-log",
    component: ActivityLogList,
    icon: HTicon,
  },

  {
    id: uuidv4(),
    path: "admin/visitor-activity-log",
    component: ActivityLogList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/cac-activity-log",
    component: ActivityLogList,
    icon: HTicon,
  },

  {
    id: uuidv4(),
    path: "admin/bac-activity-log",
    component: ActivityLogList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/linguist-activity-log",
    component: ActivityLogList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/sppo-activity-log",
    component: ActivityLogList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/employee-activity-log",
    component: ActivityLogList,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/manager-activity-log",
    component: ActivityLogList,
    icon: HTicon,
  },

  // timesheet and project report documents view routes
  {
    id: uuidv4(),
    path: "admin/view-timesheet-document-by-linguist/:id",
    component: ViewTimesheetDocument,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/view-timesheet-document-by-client/:id",
    component: ViewTimesheetDocument,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/view-project-report-document-by-linguist/:id",
    component: ViewProjectReportDocument,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/view-project-report-document-by-client/:id",
    component: ViewProjectReportDocument,
    icon: HTicon,
  },
  {
    id: uuidv4(),
    path: "admin/view-project-report-document-by-admin/:id",
    component: ViewProjectReportDocument,
    icon: HTicon,
  },

  {
    id: uuidv4(),
    component: UnpaidJobAdvert,
    path: "admin/unpaid-job-advert",
    icon: JAicon,
  },

  {
    id: uuidv4(),
    component: UnpaidJobAdvert,
    path: "admin/unpaid-job-advert/:data/:type",
    icon: JAicon,
  },

  {
    id: uuidv4(),
    component: PaidJobAdvert,
    path: "admin/paid-job-advert/:data/:type",
    icon: JAicon,
  },

  {
    id: uuidv4(),
    component: ViewJObAdvert,
    path: "admin/view-job-advert/:id",
    icon: JAicon,
  },

  {
    id: uuidv4(),
    component: ViewJObAdvertDocument,
    path: "admin/view-job-advert-document/:id",
    icon: JAicon,
  },

  // lead documents
  {
    id: uuidv4(),
    component: ViewLeadPassport,
    path: "admin/lead-passport-document-view/:id",
    icon: JAicon,
  },

  {
    id: uuidv4(),
    component: ViewLeadQuoteDocument,
    path: "admin/lead-quote-enquiry-documents/:id",
    icon: JAicon,
  },

  {
    id: uuidv4(),
    component: ViewSppoPassport,
    path: "admin/sppo-view-passport/:id",
    icon: JAicon,
  },

  {
    id: uuidv4(),
    component: PostRateCard,
    path: "admin/post-rate-card",
    icon: JAicon,
  },

  // view translation delivery document
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "admin/view-translation-delivery-document1/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "admin/view-translation-delivery-document2/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "admin/view-translation-delivery-document3/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "admin/view-translation-delivery-document4/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "admin/view-translation-delivery-document5/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: ViewEmailSettings,
    path: "admin/email-settings/list-of-email-template",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: EditEmailSettings,
    path: "admin/edit-email-settings/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: BulkTimesheetList,
    path: "admin/listview-bulk-interpreting-timesheet/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: EditEvents,
    path: "admin/edit-events-and-meetings/:id",
    icon: JAicon,
  },
];

export default ADMIN_ROUTES;
