import { Table } from "semantic-ui-react";
import { useState } from "react";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CURRENCIES from "data/currencies";
import { Button } from "semantic-ui-react";
import CustomDate from "components/Common/CustomDate";
import TextField from "@material-ui/core/TextField";
import Cookies from "js-cookie";
import history from "utils/history";
import defaultAxios from "utils/axios";
import Axios from "axios";
import { addCashbookData } from "redux/actions/linguists/cashBookAction";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Box } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";

const AddData = ({ setReload }) => {
  const dispatch = useDispatch();
  const classes = useDashboardStyles();

  const [totalIncomeResult, setTotalIncomeResult] = useState(0);
  const [totalExpensesResult, setTotalExpensesResult] = useState(0);
  const [loading, setLoading] = useState(false);

  const clearAll = () => {
    setValue({
      date: "",
      jobReference: "",
      paymentValue: "",
      fuelCost: "",
      parking: "",
      toolCc: "",
      busFare: "",
      trainFare: "",
      taxiFare: "",
      airFare: "",
      airportTransfer: "",
      hotelAcm: "",
      chargeFee: "",
      food: "",
      cashPaid: "",
      otherExpenses: "",
      currency: "",
    });
  };

  const currencies = CURRENCIES;

  const [value, setValue] = useState({
    date: Date.now(),
    jobReference: "",
    paymentValue: "",
    otherIncome: "",
    fuelCost: "",
    parking: "",
    toolCc: "",
    busFare: "",
    // trainFare: "",
    taxiFare: "",
    airFare: "",
    airportTransfer: "",
    hotelAcm: "",
    chargeFee: "",
    food: "",
    cashPaid: "",
    otherExpenses: "",
    currency: currencies[0].code,
  });

  const handleChange = (e) => {
    const { name } = e.target;

    if (!isNaN(Number(e.target.value))) {
      setValue({
        ...value,
        [name]: e.target.value,
      });
    }
  };

  const handleDateChange = (date) => {
    setValue({
      ...value,
      date,
    });
  };

  const totalExpenses = () => {
    let total = 0;

    if (!isNaN(Number(value.cashPaid))) {
      total += +value.cashPaid;
    }
    if (!isNaN(Number(value.fuelCost))) {
      total += +value.fuelCost;
    }
    if (!isNaN(Number(value.parking))) {
      total += +value.parking;
    }
    if (!isNaN(Number(value.toolCc))) {
      total += +value.toolCc;
    }
    if (!isNaN(Number(value.busFare))) {
      total += +value.busFare;
    }
    if (!isNaN(Number(value.trainFare))) {
      total += +value.trainFare;
    }
    if (!isNaN(Number(value.taxiFare))) {
      total += +value.taxiFare;
    }
    if (!isNaN(Number(value.airFare))) {
      total += +value.airFare;
    }
    if (!isNaN(Number(value.airportTransfer))) {
      total += +value.airportTransfer;
    }
    if (!isNaN(Number(value.hotelAcm))) {
      total += +value.hotelAcm;
    }
    if (!isNaN(Number(value.chargeFee))) {
      total += +value.chargeFee;
    }
    if (!isNaN(Number(value.food))) {
      total += +value.food;
    }
    if (!isNaN(Number(value.otherExpenses))) {
      total += +value.otherExpenses;
    }

    const result = isNaN(total) || !total ? "" : total.toFixed(2);
    setTotalExpensesResult(result);
  };

  const grossProfile = () => {
    const total = totalExpenses();
    const pValue = +value.paymentValue || 0;
    const grossProfile = pValue - +total;
    return isNaN(grossProfile) ? "" : grossProfile.toFixed(2);
  };

  const handleSubmit = async () => {
    if (history.location.pathname.includes("linguist")) {
      const token = Cookies.get("LINtoken");
      const sendValue = {
        date: value.date,
        jobReference: value.jobReference || "0.00",
        paymentValue: value.paymentValue || "0.00",
        fuelCost: value.fuelCost || "0.00",
        parking: value.parking || "0.00",
        toolCc: value.toolCc || "0.00",
        busFare: value.busFare || "0.00",
        trainFare: value.trainFare || "0.00",
        taxiFare: value.taxiFare || "0.00",
        airFare: value.airFare || "0.00",
        airportTransfer: value.airportTransfer || "0.00",
        hotelAcm: value.hotelAcm || "0.00",
        chargeFee: value.chargeFee || "0.00",
        food: value.food || "0.00",
        cashPaid: value.cashPaid || "0.00",
        otherExpenses: value.otherExpenses || "0.00",
        currency: value.currency,
        linId: Cookies.get("linID"),
      };
      setLoading(true);
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_BACKEND_API}/linguists/cash-book`,
          sendValue,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.data.success) {
          swal({
            title: "Success",
            text: `${response?.data.message}`,
            icon: "success",
            buttons: false,
          }).then(function () {
            setReload(Math.random());
          });
          const dateArray = sendValue.date.split("/");
          let date = `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
          date = new Date(date);
          sendValue.date = date;
          dispatch(addCashbookData(sendValue));
          clearAll();
        }
        setLoading(false);
      } catch (error) {
        console.log("hanlde submit error -->", error);
        setLoading(false);
      }
    } else if (history.location.pathname.includes("sppo")) {
      const token = Cookies.get("SPPOtoken");
      const sendValue = {
        date: value.date,
        jobReference: value.jobReference || "0.00",
        paymentValue: value.paymentValue || "0.00",
        fuelCost: value.fuelCost || "0.00",
        parking: value.parking || "0.00",
        toolCc: value.toolCc || "0.00",
        busFare: value.busFare || "0.00",
        trainFare: value.trainFare || "0.00",
        taxiFare: value.taxiFare || "0.00",
        airFare: value.airFare || "0.00",
        airportTransfer: value.airportTransfer || "0.00",
        hotelAcm: value.hotelAcm || "0.00",
        chargeFee: value.chargeFee || "0.00",
        food: value.food || "0.00",
        cashPaid: value.cashPaid || "0.00",
        otherExpenses: value.otherExpenses || "0.00",
        currency: value.currency,
        sppoId: Cookies.get("SPPOID"),
      };
      setLoading(true);
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_BACKEND_API}/sppo/cash-book`,
          sendValue,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.data.success) {
          swal({
            title: "Success",
            text: `${response?.data.message}`,
            icon: "success",
            buttons: false,
          }).then(function () {
            setReload(Math.random());
          });
          const dateArray = sendValue.date.split("/");
          let date = `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
          date = new Date(date);
          sendValue.date = date;
          dispatch(addCashbookData(sendValue));
          clearAll();
        }
        setLoading(false);
      } catch (error) {
        console.log("hanlde submit error -->", error);
        setLoading(false);
      }
    } else if (history.location.pathname.includes("admin")) {
      const token = Cookies.get("token");
      const sendValue = {
        date: value.date,
        jobReference: value.jobReference || "0.00",
        paymentValue: value.paymentValue || "0.00",
        fuelCost: value.fuelCost || "0.00",
        parking: value.parking || "0.00",
        toolCc: value.toolCc || "0.00",
        busFare: value.busFare || "0.00",
        trainFare: value.trainFare || "0.00",
        taxiFare: value.taxiFare || "0.00",
        airFare: value.airFare || "0.00",
        airportTransfer: value.airportTransfer || "0.00",
        hotelAcm: value.hotelAcm || "0.00",
        chargeFee: value.chargeFee || "0.00",
        food: value.food || "0.00",
        cashPaid: value.cashPaid || "0.00",
        otherExpenses: value.otherExpenses || "0.00",
        currency: value.currency,
        adminId: Cookies.get("ADMID"),
      };
      setLoading(true);
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_BACKEND_API}/admin/cash-book`,
          sendValue,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.data.success) {
          swal({
            title: "Success",
            text: `${response?.data.message}`,
            icon: "success",
            buttons: false,
          }).then(function () {
            setReload(Math.random());
          });
          const dateArray = sendValue.date.split("/");
          let date = `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
          date = new Date(date);
          sendValue.date = date;
          dispatch(addCashbookData(sendValue));
          clearAll();
        }
        setLoading(false);
      } catch (error) {
        console.log("hanlde submit error -->", error);
        setLoading(false);
      }
    }
  };

  const {
    date,
    otherIncome,
    jobReference,
    paymentValue,
    fuelCost,
    parking,
    toolCc,
    busFare,
    trainFare,
    taxiFare,
    airFare,
    airportTransfer,
    hotelAcm,
    chargeFee,
    food,
    cashPaid,
    otherExpenses,
    currency,
  } = value;

  const totalOne = () => {
    const calculate = +otherIncome + +paymentValue;

    const result = calculate ? calculate.toFixed(2) : "";

    setTotalIncomeResult(result);
  };

  const handleGrossClick = () => {
    totalOne();
    totalExpenses();
  };

  const grossValue = +totalIncomeResult - +totalExpensesResult;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        {/* Date and Revenue */}
        <Box p={1}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Date And Revenue</p>
            </CardHeader>
            <CardBody>
              {/* <Box m={1} style={{display:'flex',alignItems:'start'}}> */}
              {/* <CustomDate
                      width="20%"
                      onChange={handleDateChange}
                      value={date}
                      label="Date"
                    />
                  <TextField
                    width="20%"
                    required
                    name="jobReference"
                    label="Job / Your Reference"
                    defaultValue={jobReference}
                    variant="outlined"
                    onChange={(e) => {
                      setValue({
                        ...value,
                    jobReference: e.target.value,
                          });
                        }}
                      />
                     <TextField
                      required
                      onChange={handleChange}
                      width="20%"
                      value={paymentValue}
                      name="paymentValue"
                      label="Payment Received / Contract Value"
                      variant="outlined"
                    />
                     <TextField
                      onChange={handleChange}
                      width="20%"
                      value={otherIncome}
                      name="otherIncome"
                      label="Other Income"
                      variant="outlined"
                    />
               <TextField
                      value={totalIncomeResult}
                      name="Total Income"
                      label="Total Income"
                      variant="outlined"
                      disabled
                      width="20%"
                    />
                    <span>{totalIncomeResult}</span> */}
              {/* </Box> */}
              {/* <h4>Date And Revenue</h4> */}
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell> Date </Table.HeaderCell>
                    <Table.HeaderCell> Job / Your Reference </Table.HeaderCell>
                    <Table.HeaderCell> Currency </Table.HeaderCell>
                    <Table.HeaderCell>
                      Payment Received / Contract Value
                    </Table.HeaderCell>
                    <Table.HeaderCell>Other Income</Table.HeaderCell>
                    <Table.HeaderCell>Total Income</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          width="100%"
                          onChange={handleDateChange}
                          value={date}
                          label="Date"
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                        />
                      </MuiPickersUtilsProvider>
                      {/* <CustomDate
                      width="100%"
                      onChange={handleDateChange}
                      value={date}
                      label="Date"
                      inputVariant="outlined"
                    /> */}
                    </Table.Cell>

                    <Table.Cell>
                      <TextField
                        required
                        name="jobReference"
                        label="Job / Your Reference"
                        defaultValue={jobReference}
                        variant="outlined"
                        onChange={(e) => {
                          setValue({
                            ...value,
                            jobReference: e.target.value,
                          });
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        select
                        variant="outlined"
                        onChange={(e) => {
                          setValue({
                            ...value,
                            currency: e.target.value,
                          });
                        }}
                        width="100%"
                        defaultValue={currencies[0].code}
                        name="currency"
                      >
                        {currencies.map((option) => (
                          <MenuItem key={option.symbol} value={option.code}>
                            {option.code + " " + option.symbol}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        required
                        onChange={handleChange}
                        value={paymentValue}
                        name="paymentValue"
                        label=" Received payment"
                        variant="outlined"
                      />
                    </Table.Cell>
                    {/* <Table.Cell>
                    <TextField
                      onChange={handleChange}
                      variant="outlined"
                      value={paymentValue}
                      name="paymentValue"
                      label="Payment Value"
                    />
                  </Table.Cell> */}
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        value={otherIncome}
                        name="otherIncome"
                        label="Other Income"
                        variant="outlined"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        value={totalIncomeResult + " " + value.currency}
                        name="totalIncome"
                        variant="outlined"
                        multiline
                        inputProps={{ readOnly: true }}
                      />
                      {/* <span>{totalIncomeResult}</span> */}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </CardBody>
          </Card>
        </Box>
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={12}>
        <box pb={1}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Expenses</p>
            </CardHeader>
            <CardBody>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Fuel Cost</Table.HeaderCell>
                    <Table.HeaderCell>Parking</Table.HeaderCell>
                    <Table.HeaderCell>Toll / CC</Table.HeaderCell>
                    <Table.HeaderCell>Bus Fare / Train Fare</Table.HeaderCell>
                    <Table.HeaderCell>Taxi Fare</Table.HeaderCell>
                    <Table.HeaderCell>Air Fare</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        width="100%"
                        value={fuelCost}
                        name="fuelCost"
                        variant="outlined"
                        label="Fuel cost"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        width="100%"
                        value={parking}
                        name="parking"
                        variant="outlined"
                        label="Parking"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        width="100%"
                        value={toolCc}
                        name="toolCc"
                        label="Tool cc"
                        variant="outlined"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        width="100%"
                        value={busFare}
                        name="busFare"
                        label="Bus fare"
                        variant="outlined"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        width="100%"
                        value={taxiFare}
                        name="taxiFare"
                        label="Taxi fare"
                        variant="outlined"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        width="100%"
                        value={airFare}
                        name="airFare"
                        label="Air fare"
                        variant="outlined"
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Airport Transport</Table.HeaderCell>
                    <Table.HeaderCell>Hotel / Accomodation</Table.HeaderCell>
                    <Table.HeaderCell>Charge / Fee</Table.HeaderCell>
                    <Table.HeaderCell>Food</Table.HeaderCell>
                    <Table.HeaderCell>Cash Paid</Table.HeaderCell>
                    <Table.HeaderCell>Other Expenses</Table.HeaderCell>
                    <Table.HeaderCell>Total Expenses</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        width="100%"
                        value={airportTransfer}
                        name="airportTransfer"
                        label="Airport transfer"
                        variant="outlined"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        width="100%"
                        value={hotelAcm}
                        name="hotelAcm"
                        label="Hotel acm"
                        variant="outlined"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        onChange={handleChange}
                        width="100%"
                        value={chargeFee}
                        name="chargeFee"
                        label="Charge fee"
                        variant="outlined"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        variant="outlined"
                        onChange={handleChange}
                        width="100%"
                        value={food}
                        name="food"
                        label="Food"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        variant="outlined"
                        onChange={handleChange}
                        width="100%"
                        value={cashPaid}
                        name="cashPaid"
                        label="Paid cash"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        variant="outlined"
                        onChange={handleChange}
                        width="100%"
                        value={otherExpenses}
                        name="otherExpenses"
                        label="Other expenses"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        variant="outlined"
                        width="100%"
                        value={totalExpensesResult + " " + value.currency}
                        name="totalExpenses"
                        multiline
                        onChange={handleChange}
                        inputProps={{ readOnly: true }}
                      />
                      {/* {totalExpensesResult} */}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </CardBody>
          </Card>
        </box>
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={12}>
        <box pb={1}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Calculation And Save</p>
            </CardHeader>
            <CardBody>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      Total Revenue / Total Income
                    </Table.HeaderCell>
                    <Table.HeaderCell>Less Total Expenses</Table.HeaderCell>
                    <Table.HeaderCell>
                      Gross Profit (Total Revenue / Total Income - Total
                      Expenses)
                    </Table.HeaderCell>

                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <TextField
                        variant="outlined"
                        width="100%"
                        onChange={handleChange}
                        value={totalIncomeResult + " " + value.currency}
                        name="totalIncomeresult"
                        inputProps={{ readOnly: true }}
                      />
                      {/* <span>{totalIncomeResult}</span> */}
                    </Table.Cell>
                    <Table.Cell>
                      <TextField
                        variant="outlined"
                        width="100%"
                        onChange={handleChange}
                        value={totalExpensesResult + " " + value.currency}
                        name="totalExpensesresult"
                        inputProps={{ readOnly: true }}
                      />
                      {/* <span>{totalExpensesResult}</span> */}
                    </Table.Cell>
                    <Table.Cell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button color="purple" onClick={handleGrossClick}>
                          Calculate gross profit
                        </Button>
                        <TextField
                          variant="outlined"
                          value={
                            grossValue
                              ? grossValue.toFixed(2) + " " + value.currency
                              : ""
                          }
                          name="gross profit"
                          inputProps={{ readOnly: true }}
                          style={{
                            color: `${
                              Math.sign(grossValue) === -1 ? "red" : "green"
                            }`,
                            marginLeft: 10,
                          }}
                        />
                        {/* <span
                        style={{
                          color: `${
                            Math.sign(grossValue) === -1 ? "red" : "green"
                          }`,
                          fontSize: "2rem",
                          marginLeft: "1rem",
                        }}
                      >
                       
                      </span> */}
                      </div>
                    </Table.Cell>

                    <Table.Cell>
                      <Button
                        style={{ background: "#3f51b5 none", color: "white" }}
                        variant="outlined"
                        onClick={handleSubmit}
                        disabled={!jobReference || !currency || loading}
                      >
                        {loading ? "Saving..." : "Save"}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </CardBody>
          </Card>
        </box>
      </GridItem>
    </GridContainer>
  );
};

export default AddData;
