import { useState } from "react";
import { useDispatch } from "react-redux";

import Divider from "@material-ui/core/Divider";

import CustomInput from "components/Common/CustomInput";
import ExpertiseRequired from "./ExpertiseRequired";
import ServiceDropdown from "./ServiceDropdown";

import { storeValues } from "redux/actions/admin/invoice";

// Custom Styles
import { SubDetailsDiv } from "./style";

const SubDetails = () => {
  const dispatch = useDispatch();

  const [inputSelect, setInputSelect] = useState();

  const onChange = (value) => {
    setInputSelect(value);
    dispatch(
      storeValues({
        name: "invoiceService",
        value,
      })
    );
  };

  const handleCustomInput = (e) => {
    const { name, value } = e.target;

    dispatch(
      storeValues({
        name,
        value,
      })
    );
  };

  return (
    <SubDetailsDiv>
      <div className="service">
        <p className="left">Service:</p>
        <p style={{ width: "100%" }}>Interpreting</p>{" "}
      </div>
      <div className="service">
        <p className="left">Add another: </p>
        <ServiceDropdown value={inputSelect} onChange={onChange} />
      </div>{" "}
      {inputSelect && inputSelect.toLowerCase().includes("other") ? (
        <div className="service">
          <p className="left"></p>
          <CustomInput
            label="Write here.."
            onChange={handleCustomInput}
            name="invoiceService"
          />
        </div>
      ) : null}
      <Divider
        style={{
          margin: "1rem 0",
        }}
      />
      <div className="er">
        <p className="left">Expertise Required: </p>
        <ExpertiseRequired />
      </div>
    </SubDetailsDiv>
  );
};

export default SubDetails;
