import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import Action from "./Actions/JobActions";
import Axios from "axios";
import Searchlinguist from "./SearchView/SearchView";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ jobadvert }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({ reload: false });

  useEffect(() => {
    loadList();
    setFiltered([]);
  }, [filter]);

  const loadList = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-declined-frontpage-jobadvert`
    )
      .then((res) => {
        filtered.length > 0
          ? setValues(filtered)
          : jobadvert
          ? setValues(res.data.filter((item) => item.id === jobadvert))
          : setValues(res.data);
        jobadvert && setShow(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Paper className={classes.root}>
        {!jobadvert && (
          <Searchlinguist
            values={values}
            SearchText={searchText}
            setSearchText={setSearchText}
            setFiltered={setFiltered}
            load={loadList}
            setShow={setShow}
            setFilter={setFilter}
            show={show}
          />
        )}
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" id="export">
                <TableHead>
                  <TableRow>
                    <TableCell>Job ID</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Job Title</TableCell>
                    <TableCell>Salary</TableCell>
                    <TableCell>Hours Per Week</TableCell>
                    <TableCell>Source language</TableCell>
                    <TableCell>Target language</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Charge</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Closing Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {values.length > 0 ? (
                  <TableBody>
                    {values
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={list._id}
                        >
                          <TableCell key={list._id} align={list.align}>
                            <Link to={`/employee/view-job-advert/${list._id}`}>
                              {list.id}
                            </Link>
                          </TableCell>
                          <TableCell>{list?.userID}</TableCell>
                          <TableCell>{list?.jobtitle}</TableCell>
                          <TableCell>{list?.salary}</TableCell>
                          <TableCell>{list?.hoursperweek}</TableCell>
                          <TableCell>{list?.sourcelanguage}</TableCell>
                          <TableCell>{list?.targetlanguage}</TableCell>
                          <TableCell>
                            {list?.remote_details
                              ? list?.remote_details
                              : list?.business_addressline_one}
                          </TableCell>
                          <TableCell>
                            Charge - GBP {list?.charge}
                            <br />
                            Estimate - {list?.currency?.substring(0, 3)}{" "}
                            {list?.estimate}
                            <br />
                            Bank pay - {list?.currency?.substring(0, 3)}{" "}
                            {list?.estimate_paid_amount
                              ? list?.estimate_paid_amount
                              : "00.00"}
                          </TableCell>
                          <TableCell>
                            {Moment(list?.createdAt).format(
                              "DD MMM YYYY - kk:mm:ss"
                            )}
                          </TableCell>
                          <TableCell>{list?.applicationclosingdate}</TableCell>
                          <TableCell>
                            {list?.access_permission ? "Approved" : "Rejected"}
                          </TableCell>
                          <TableCell>
                            <Action
                              load={loadList}
                              id={list._id}
                              ID={list?.id}
                              name={
                                list?.first_name
                                  ? list?.first_name
                                  : "N/A" + " " + list?.last_name
                                  ? list?.last_name
                                  : ""
                              }
                              bank_pay={list?.bank_pay}
                              payment={list?.payment_status}
                              approve={list?.access_permission}
                              reject={list?.reject}
                              charge_id={list?.charge_id}
                              currency={list?.currency?.substring(0, 3)}
                              estimate={
                                list?.estimate_paid_amount
                                  ? list?.estimate_paid_amount
                                  : list?.estimate
                              }
                              applicationclosingdate={
                                list?.applicationclosingdate <
                                Moment().format("DD/MM/YYYY")
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  "No data found"
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={values.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </>
  );
}
