import React, { useState, useEffect } from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import { Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Options from "components/Linguist/MyClient/INCOptions";

const AssignmentPage = () => {
  const classes = useDashboardStyles();

  const [inhouseAccess, setinhouseAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("LINtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setinhouseAccess(response?.data.inhouseAccess);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          {inhouseAccess ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>
                    Manage In-house Clients
                  </p>

                  <IconButton onClick={() => history.goBack()} color="inherit">
                    <ArrowBackIcon />
                  </IconButton>
                </CardHeader>
              </Card>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Options />
                </CardHeader>
              </Card>
            </GridItem>
          ) : inhouseAccess === false ? (
            <Header as="h3">Access denied for INC, contact LC HR</Header>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default AssignmentPage;
