import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import { CURRENCY, COUNTRY_DATA_SEM_UI, GENDER_DATA } from "data";
import moment from "moment";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("token");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "different_comapny",
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("guarantee_of_payment", data.guarantee_of_payment);
    fd.append("pay_in_account", data.pay_in_account);
    fd.append(
      "existing_account_with_localprovider",
      data.existing_account_with_localprovider
    );
    fd.append("comapny", data.comapny);
    fd.append("bank_reference_letter", data.bank_reference_letter);
    fd.append("pay_in_direct_debit", data.pay_in_direct_debit);
    fd.append("company_bank_details", data.company_bank_details);
    fd.append("currency", data.currency);

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/update-account-client/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="guarantee_of_payment"
            onChange={handleChange}
            label="Can you provide us any guarantee of payment?"
            edit={edit.value}
            value={data.guarantee_of_payment}
          />
          <CustomTextInput
            name="pay_in_account"
            onChange={handleChange}
            label="If you are a samll or micro business, would it be possible to pay in account?"
            edit={edit.value}
            value={data.pay_in_account}
          />
          <CustomTextInput
            name="existing_account_with_localprovider"
            onChange={handleChange}
            label="Do you or your company currently have an existing account with a local provider? "
            edit={edit.value}
            value={data.existing_account_with_localprovider}
          />
          <CustomTextInput
            name="comapny"
            onChange={handleChange}
            label="If yes, which company do you hold an account with?"
            edit={edit.value}
            value={data.comapny}
          />
          <CustomTextInput
            name="bank_reference_letter"
            onChange={handleChange}
            label="Can you provide us with a bank reference letter?"
            edit={edit.value}
            value={data.bank_reference_letter}
          />
          <CustomTextInput
            name="pay_in_direct_debit"
            onChange={handleChange}
            label="Can you pay by direct debit?"
            edit={edit.value}
            value={data.pay_in_direct_debit}
          />
          <CustomTextInput
            name="company_bank_details"
            onChange={handleChange}
            label="Company bank details"
            edit={edit.value}
            value={data.company_bank_details}
          />
          {edit.value ? (
            <CustomTextInput
              onChange={handleChange}
              name="currency"
              label="Currency"
              select={CURRENCY}
              svalue={data.currency}
            />
          ) : (
            <CustomTextInput label="Currency" value={data.currency} />
          )}
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
