import React, { useState, useEffect } from 'react'
import { Card, Header } from "semantic-ui-react";
import Axios from "axios";

function ProfMemberShip({ id, checkdata }) {

  const [values, setValues] = useState({ reload: false });


  const loadList = async () => {
    try {
      const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/linguists/educational-qualification-list/linID/${id}`)

      setValues(response?.data)

    } catch (error) {
      console.log(error.response?.data)
    }
  };

  useEffect(() => {

    loadList()

  }, [])




  return (
    <div
      raised
      style={{
        width: "100%",
        padding: "1rem",
        backgroundImage:
          "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
      }}
    >
      <Card.Content>
        <Header color="purple" as="h2">
          Educational / Professional Qualification
        </Header>
        {values.length > 0 ?
          <>
            {values?.map((list) => (
              <div style={{ marginRight: '10px' }}>
                <Header as='h5'>
                  {list?.date_of_graduation}
                  <br /> {list?.name_of_degree}
                  <br /> {list?.country}
                  <br /> {list?.subject_studied}
                  <br /> {list?.grade}
                  <br />{checkdata ? checkdata : ""}
                </Header>
                <br />
              </div>
            ))} </>
          : null}
      </Card.Content>
    </div>
  )
}


export default ProfMemberShip;
