import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";
import { useHistory } from "react-router-dom";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import PostForm from "components/Admin/ControlPanel/CMS/JobAdvert/PostJobAdvert";
import PostViewOptions from "components/Admin/ControlPanel/CMS/T&C/PostViewOptions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";


const AssignmentPage = () => {
    const classes = useDashboardStyles();
    const history = useHistory();
    const [control_panel, setAdminAccess] = useState(null);

    const getLinguistDetails = async (e) => {
        const token = Cookies.get("token");
        const response = await Axios.get(
            "/api/manager-admin/dashboard",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        setAdminAccess(response?.data.control_panel);
    };
    useEffect(() => {
        getLinguistDetails();
    },[]);

    return (
        <MasterLayout>
            <Manager>
                <GridContainer>
                    {control_panel ? (
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader
                                    color="primary"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <p className={classes.cardTitleWhite}>
                                        Job Advert (Update Price)
                                    </p>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <PostViewOptions />
                                        <IconButton
                                            color="inherit"
                                            onClick={() => {
                                                history.goBack();
                                            }}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </div>
                                </CardHeader>
                            </Card>
                            <Card>
                                <CardBody>
                                    <PostForm />
                                </CardBody>
                            </Card>
                        </GridItem>
                    ) : control_panel === 0 ? (
                        <Header as="h3">Access denied</Header>
                    ) : (
                        <Dimmer active inverted>
                            <Loader size="large">Loading</Loader>
                        </Dimmer>
                    )}
                </GridContainer>
            </Manager>
        </MasterLayout>
    );
};

export default AssignmentPage;
