
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Cookies from 'js-cookie'
import Axios from "utils/axios";
import { useParams } from 'react-router-dom'
import moment from 'moment'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const { id } = useParams()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get('token')


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { bookingList } = values;


  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    try {
      const response = await Axios.get(`/api/BAC/sent-request-list-translation/translationID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setValues({ ...values, bookingList: response?.data });
    } catch (error) {
      console.log(error)
    }
  };



  return (
    <div>

      <Paper className={classes.root}>
        <div>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">

              <TableHead>
                <TableRow>
                  <TableCell>Admin ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date and time (London)</TableCell>

                </TableRow>
              </TableHead>

              {bookingList.length > 0 ?
                <TableBody>
                  {bookingList

                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((list, i) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                        <TableCell >{list.adminID}</TableCell>
                        <TableCell >{list.status}</TableCell>
                        <TableCell >{moment(list.createdAt).tz("Europe/London").format('DD-MMM-YYYY : hh:mm:ss')}</TableCell>

                      </TableRow>
                    ))}
                </TableBody>
                :

                null}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={bookingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          /></div>

      </Paper>
    </div>
  );
}