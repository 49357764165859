import { CHANGE_LINGUIST_PROFILE, UPDATE_LINGUIST_PROFILE } from "redux/actions/actionTypes"

const INITIAL_STATE = {
    data: {
        profile_photo: null,
    title: "",
    FirstName: "",
    LastName: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    PostalCode: "",
    Region: "",
    Country: "",
    Nationality: "",
    other_nationality: [],
    Birthday: "",
    email: "",
    sex: "",
    mobile_no: "",
    other_email: "",
    business_phone_no: "",
    online_calling_details: "",
    proof_address_photo: null,
    reference_work: "",
    reference_education: "",
    work_in_the_uk: "",
    eu_all_other_countries: "",
    ni_number: "",
    utr_number: "",
    vat_number: "",
    liability_insurance: "",
    liability_insurance_expiry_date: null,
    passport_ni_photo: "",
    relevant_document_photo: "",
    bank_account_fullname: "",
    bank_sort_code: "",
    bank_account_number: "",
    bank_branch_name: "",
    bank_iban: "",
    bank_swift_code: "",
    bank_name: "",
    bank_branch_address: "",
    bank_additional_info: "",
    bic_no: "",
    bank_ifsc_code: "",
    institution_number: "",
    branch_code: "",
    aba: "",
    currency: "",
    mother_tongue: "",
    source_language: "",
    target_language: "",
    language_combinations: [],
    ticking_relevant: [],
    interpreting_qualification: "",
    translation_qualification: "",
    other_language_test: "",
    total_hours_of_proven: "",
    total_days_words_of_proven: "",
    professional_qualification: "",
    professional_membership: [],
    professional_membership_number_level: "",
    institute_name: "",
    date_of_graduation: "",
    name_of_degree: "",
    subject_studied: "",
    relevant_certificate_photo: "",
    organization_name: "",
    organization_address: "",
    start_date: "",
    leaving_date: "",
    experience_gained: "",
    reason_for_leaving: "",
    reference_name: "",
    criminal_record: "",
    criminal_record_details: "",
    security_clearance: "",
    title_security_clearance: [],
    security_clearance_document: null,
    security_certificate_expiry_date: null,
    note: "",
    upload_cv: "",
    relevant_documents: "",
    special_needs: "",
    }
}

const updateProfileReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case CHANGE_LINGUIST_PROFILE:
        return {
            ...state,
            data: {
              ...state.data,
              [payload.name]: payload.value
            }
        }
    
      case UPDATE_LINGUIST_PROFILE: 
        return {
            ...state,
            data: payload
        }
  
      default:
        return state;
    }
  };
  
  export default updateProfileReducer;