import React, { useState } from "react";
import { useSelector } from "react-redux";

// Material Core Components
import Typography from "@material-ui/core/Typography";

// Material Icons
import { Icon, Step } from "semantic-ui-react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import CustomButton from "components/Common/CustomButton";
// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import { changeSPPORInput, updateSPPORStep } from "redux/actions/spporAction";
import { useDispatch } from "react-redux";

const SPPORegistration = () => {
  const { count } = useSelector((state) => state.spporReducer);
  const classes = useEmployeeStyles();
  const dispatch = useDispatch();
  const selectedForm = count;
  const [completed1, setCompleted1] = useState(false);
  const [completed2, setCompleted2] = useState(false);

  const handleNext1 = () => {
    dispatch(updateSPPORStep(1));
  };

  const handleNext2 = () => {
    dispatch(updateSPPORStep(2));
  };

  const handleNext3 = () => {
    dispatch(updateSPPORStep(3));
  };

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <CardHeader>
              <Step.Group widths={3} fluid stackable>
                <Step
                  completed={completed1}
                  active={selectedForm === 1}
                  onClick={handleNext1}
                >
                  <Icon name="building " />
                  <Step.Content>
                    <Step.Title>Organisation Details</Step.Title>
                    <Step.Description>
                      Enter organisational details
                    </Step.Description>
                  </Step.Content>
                </Step>

                <Step
                  completed={completed2}
                  active={selectedForm === 2}
                  onClick={handleNext2}
                >
                  <Icon name="address card" />
                  <Step.Content>
                    <Step.Title>Personal Details</Step.Title>
                    <Step.Description>
                      Enter your personal details
                    </Step.Description>
                  </Step.Content>
                </Step>

                <Step active={selectedForm === 3} onClick={handleNext3}>
                  <Icon name="chart line" />
                  <Step.Content>
                    <Step.Title>Financial Details</Step.Title>
                    <Step.Description>
                      Enter your financial details
                    </Step.Description>
                  </Step.Content>
                </Step>
              </Step.Group>
            </CardHeader>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  Service Providing Partner Organisation registration
                </Typography>
                <Typography variant="subtitle2">Page {count} of 3</Typography>
              </div>
              {selectedForm === 1 && <PageOne setCompleted={setCompleted1} />}
              {selectedForm === 2 && <PageTwo setCompleted={setCompleted2} />}
              {selectedForm === 3 && <PageThree />}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default SPPORegistration;
