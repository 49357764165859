import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Table } from 'semantic-ui-react';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from 'moment'
import { Rating } from '@material-ui/lab';


const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const Items = ({ values }) => {
  const classes = useStyles();

  return (

    <>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width='5'>Linguist accepted this assignment</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{values?.linID?.id ? values?.linID?.id : values?.linguistID?.id ? values?.linguistID?.id : "No linguist accepted this assignment yet."}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </GridItem>
      </GridContainer>
      <br />

      {values?.timesheetID ?
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width='5'>Linguist ratings</Table.HeaderCell>
                  <Table.HeaderCell width='5'>Client ratings</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Rating
                      name="simple-controlled"
                      value={values?.timesheetID?.feedback}
                      readOnly
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Rating
                      name="simple-controlled"
                      value={values?.timesheetID?.feedbackByClient}
                      readOnly
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </GridItem>
        </GridContainer>
        : null}
      <br />
    </>
  )
}

export default Items
