import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
// Custom Components
import Action from "./Action";
import Cookies from "js-cookie";
import Axios from "axios";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import { useToasts } from "react-toast-notifications";
import SearchView from "./Search/SearchAssignment";

const TableOne = ({ interpretingData }) => {
  const classes = useDashboardStyles();
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();
  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const { bookingList, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-confirm-booking/bookingID/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });

        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/private-confirm-booking-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({
        ...values,
        bookingList: interpretingData
          ? response?.data.filter((item) => item.id === interpretingData)
          : response?.data,
        reload: false,
      });
      interpretingData && setShow(true);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  return (
    <div>
      {!interpretingData && (
        <SearchView
          load={loadbookingList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          SearchText={SearchText}
          setShow={setShow}
          show={show}
        />
      )}
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table color="pink" stickyHeader aria-label="sticky table">
                <Table.Header>
                  <Table.Row>
                    <Table.Cell>Assignment ID</Table.Cell>
                    <Table.Cell>Client ID</Table.Cell>
                    <Table.Cell>Timezone</Table.Cell>
                    <Table.Cell>Start date & time</Table.Cell>
                    <Table.Cell>Duration</Table.Cell>
                    <Table.Cell>Source language</Table.Cell>
                    <Table.Cell>Target language</Table.Cell>
                    <Table.Cell>Target language skill level</Table.Cell>
                    <Table.Cell>Total estimate pay</Table.Cell>
                    <Table.Cell>Payment status</Table.Cell>
                    <Table.Cell>Action</Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {bookingList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((list, i) => (
                      <Table.Row hover role="checkbox" tabIndex={-1} key={i}>
                        <Table.Cell>
                          <Link
                            to={`/linguist/prc-view-interpreting-assignment/${list._id}`}
                          >
                            {list.id}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>
                          {list.clientID?.id ? list.clientID?.id : "N/A"}
                        </Table.Cell>
                        <Table.Cell>{list?.timezone}</Table.Cell>
                        <Table.Cell>
                          {Moment(list.startdateArray[0]).format("DD-MMM-YYYY")}{" "}
                          - {list?.starttimeArray[0]}
                        </Table.Cell>
                        <Table.Cell>{list.total_time_period}</Table.Cell>
                        <Table.Cell>{list.source_language}</Table.Cell>
                        <Table.Cell>{list.target_language}</Table.Cell>
                        <Table.Cell>
                          {list.target_expertise_level_required}
                        </Table.Cell>
                        <Table.Cell>
                          {list.currency.substring(0, 3)} {list.estimate}
                        </Table.Cell>
                        <Table.Cell>{list.status}</Table.Cell>

                        <Table.Cell>
                          <Action
                            load={loadbookingList}
                            id={list._id}
                            ID={list?.id}
                            clientID={list?.clientID?.id}
                            name={
                              list?.clientID?.FirstName +
                              " " +
                              list?.clientID?.LastName
                            }
                            currency={list?.currency.substring(0, 3)}
                            estimate={list.estimate}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>{" "}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
};

export default TableOne;
