import React, { useState, useEffect } from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import Welcome from "components/Users/Accountclient";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AccountClientDashboard from "components/AccountClient/AccountClientDasboard";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import Axios from "utils/axios";
import Cookies from "js-cookie";
import history from "utils/history";
import CardIcon from "components/Card/CardIcon";
import CardFooter from "components/Card/CardFooter";
//import { useSelector } from "react-redux";

//Icons
import BIicon from "assets/icons/book-interpreterD.png";
import OTicon from "assets/icons/order-translationD.png";
import RQicon from "assets/icons/request-quoteD.png";

const Dashboard = () => {
  const classes = useDashboardStyles();
  const [bacUser, setbacUser] = useState(null);

  const getDetails = async (e) => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      "/api/accountclient/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setbacUser(response?.data.bacUser);
  };

  useEffect(() => {
    getDetails()
  }, []);

  return (
    <AccountClientLayout>
      <Accountclient>
        <GridContainer>
          <GridItem xs={24} sm={24} md={24} lg={24} style={{ width: "100%" }}>
            <Welcome />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card
              style={{
                cursor: "pointer",
              }}
              onClick={() => history.push("/account-client/book-interpreter")}
            >
              <CardHeader color="primary" stats icon>
                <CardIcon
                  color="primary"
                  style={{
                    textAlign: "center",
                    width: "90px",
                    padding: "10px",
                  }}
                >
                  <img src={BIicon} alt="" style={{ width: "100%" }} />
                </CardIcon>
                <h3 className={classes.cardCategory}>Book Interpreter</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card
              style={{
                cursor: "pointer",
              }}
              onClick={() => history.push("/account-client/order-translation")}
            >
              <CardHeader color="primary" stats icon>
                <CardIcon
                  color="primary"
                  style={{
                    textAlign: "center",
                    width: "90px",
                    padding: "10px",
                  }}
                >
                  <img src={OTicon} alt="" style={{ width: "100%" }} />
                </CardIcon>
                <h3 className={classes.cardCategory}>Order Translation</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <div className={classes.cardSettingsDiv}>
              <Card
                style={{
                  cursor: "pointer",
                }}
                onClick={() => history.push("/account-client/request-quote")}
              >
                <CardHeader color="primary" stats icon>
                  <CardIcon
                    color="primary"
                    style={{
                      textAlign: "center",
                      width: "90px",
                      padding: "10px",
                    }}
                  >
                    <img src={RQicon} alt="" style={{ width: "100%" }} />
                  </CardIcon>
                  <h3 className={classes.cardCategory}>Request Quote</h3>
                </CardHeader>
                <CardFooter stats></CardFooter>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>
                  Account Client Dashboard ({bacUser ? "Additional User" : "Main Admin"})
                </p>
              </CardHeader>
              <CardBody>
                {" "}
                <AccountClientDashboard />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Accountclient>
    </AccountClientLayout>
  );
};
export default Dashboard;
