import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import history from "utils/history";

const Action = ({
  id,
  cancel,
  report,
  reportID,
  invoice,
  invoicePay,
  invoiceID,
  load,
  handlePaid,
  linID,
  ID,
  clientID,
  name,
  currency,
  estimate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("token");
  const [showallocate, setShow] = React.useState(false);
  const [showConfirmation, setConfirmation] = React.useState(false);
  const [text, setText] = React.useState("");
  const [data, setData] = React.useState([]);

  const [showNote, setShowNote] = React.useState(false);
  const [viewNote, setViewNote] = React.useState(false);

  const [showMessage, setShowMessage] = React.useState(false);
  const [viewMessage, setViewMessage] = React.useState(false);

  const [totalnote, setTotalNote] = React.useState(false);
  const [totalmessage, setTotalMessage] = React.useState(false);

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const [Linguist, setLinguist] = React.useState({
    id: "",
    data: "",
  });

  const [pay, setPay] = React.useState({
    admin_booking_notes: "",
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowNote(false);
    setShowMessage(false);
  };

  const handleBack = () => {
    setViewNote(false);
    setViewMessage(false);
  };

  const handleShow = () => {
    setShowNote(!showNote);
  };
  const handleShowMessage = () => {
    setShowMessage(!showMessage);
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  const handleNote = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/writeNote-translation-PRC/translationID/${id}`,
        {
          admin_booking_notes: pay.admin_booking_notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: "Note successfully added",
      });

      setPay({ ...pay, admin_booking_notes: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleMessage = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/write-message-translation-prc/translationID/${id}`,
        {
          admin_booking_notes: pay.admin_booking_notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: "Message Sent!!!",
      });

      setPay({ ...pay, admin_booking_notes: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  useEffect(() => {
    const getTotalNote = async () => {
      try {
        const response = await Axios.get(
          `/api/manager-admin/count-Note-translation-prc/translationID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setTotalNote(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    const getTotalMessage = async () => {
      try {
        const response = await Axios.get(
          `/api/manager-admin/count-message-translation-prc/translationID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setTotalMessage(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    getTotalNote();
    getTotalMessage();
  }, []);

  const handleProjectReport = async () => {
    history.push(`/admin/view-project-report/${reportID}`);
  };

  const Archive = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-translation-private/translationID/${id}`
      );
      swal({
        icon: "success",
        text: `Assignment moved to archive folder!!!`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleArchive = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          Archive(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!cancel && !report ? (
          <MenuItem onClick={handlePaid}>Cancel and Refund</MenuItem>
        ) : null}
        {report && !invoice && !cancel ? (
          <MenuItem onClick={(e) => handleProjectReport(e)}>
            View Project Report
          </MenuItem>
        ) : null}
        {invoice ? (
          <MenuItem>
            <Link to={`/admin/view-invoice/${invoiceID}`}>View Invoice</Link>
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleShow}>Add note ({totalnote})</MenuItem>
        <MenuItem onClick={handleShowMessage}>
          Send Message ({totalmessage}){" "}
        </MenuItem>
        <MenuItem onClick={handleArchive}>Archive (Delete)</MenuItem>
      </Menu>

      {/* ADD NOTE */}
      {showNote ? (
        <Dialog open={showNote}>
          {!viewNote ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Add Note - {ID} {name} {clientID ? clientID : "N/A"}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.admin_booking_notes}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="admin_booking_notes"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.admin_booking_notes}
                    onClick={handleNote}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Save Note
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Saved Notes
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}

      {/* Send Message */}
      {showMessage ? (
        <Dialog open={showMessage}>
          {!viewMessage ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Send Message {ID} {currency} {estimate} {name}{" "}
                  {clientID ? clientID : "N/A"}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.admin_booking_notes}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="admin_booking_notes"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  {clientID ? (
                    <Button
                      disabled={!pay.admin_booking_notes}
                      onClick={handleMessage}
                      color="purple"
                      variant="outlined"
                      startIcon={<SaveIcon />}
                    >
                      Send Message
                      {edit.loader && (
                        <div class="ui active inline loader"></div>
                      )}
                    </Button>
                  ) : null}
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Send Message
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}
    </div>
  );
};

export default Action;
