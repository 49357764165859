import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { updateCrStep, changeCrInput } from "redux/actions/crAction";
import CustomSelect from "components/Common/CustomSelect";
import { CURRENCY, CURRENT_COUNTRY, EMAIL_REGEX } from "data";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPhone from "components/Common/CustomPhone";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";

const FormTwo = () => {
  const { data } = useSelector((state) => state.crReducer);
  const token = Cookies.get("token");
  const [notValid, setNotValid] = useState(false);
  const {
    title,
    FirstName,
    LastName,
    email,
    password,
    Birthday,
    sex,
    direct_line,
    mobile_no,
    AddressLine1,
    AddressLine2,
    City,
    PostalCode,
    Country,
    Region,
    Nationality,
    other_nationality,
    business_name,
    position,
    business_email,
    business_phone_no,
    business_address,
    business_address_2,
    business_town,
    business_zipcode,
    business_region,
    business_country,
    charity_registration_no,
    company_registration_no,
    business_website,
    currency,
    note,
    photo,
    passport,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: value || checked,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handlePhone = (value) => {
    // inputData({ ...data, mobile_no: value });
    dispatch(
      changeCrInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const handleChangeCO = (e, value) => {
    dispatch(
      changeCrInput({
        name: "business_country",
        value,
      })
    );
  };

  const handleNext = async (e) => {
    let formData = new FormData();
    formData.append("title", title || "-");
    formData.append("FirstName", FirstName || "-");
    formData.append("LastName", LastName || "-");
    formData.append("Birthday", Birthday || "-");
    formData.append("sex", sex || "-");
    formData.append("email", email || "-");
    formData.append("password", password || "-");
    formData.append("mobile_no", mobile_no || "-");
    formData.append("direct_line", direct_line || "-");
    formData.append("AddressLine1", AddressLine1 || "-");
    formData.append("AddressLine2", AddressLine2 || "-");
    formData.append("City", City || "-");
    formData.append("PostalCode", PostalCode || "-");
    formData.append("Region", Region || "-");
    formData.append("Country", Country || "-");
    formData.append("Nationality", Nationality || "-");
    formData.append("other_nationality", other_nationality || "-");
    formData.append("business_name", business_name || "-");
    formData.append("business_email", business_email || "-");
    formData.append("business_phone_no", business_phone_no || "-");
    formData.append("business_address", business_address || "-");
    formData.append("business_address_2", business_address_2 || "-");
    formData.append("business_town", business_town || "-");
    formData.append("business_zipcode", business_zipcode || "-");
    formData.append("business_region", business_region || "-");
    formData.append("business_country", business_country || "-");
    formData.append("company_registration_no", company_registration_no || "-");
    formData.append("charity_registration_no", charity_registration_no || "-");
    formData.append("business_website", business_website || "-");
    formData.append("note", note || "-");
    formData.append("position", position || "-");

    if (!photo?.contentType) {
      formData.append("photo", photo);
    }
    if (!passport?.contentType) {
      formData.append("passport", passport);
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/add-new-cashclient`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `Client successfully added`,
        icon: "success",
        buttons: "Ok",
      }).then(() => {
        window.location = "/admin/cash-client";
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        // text: "Email is associated with an existing account. Please use a different email for completing registration.",
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleBack = () => {
    dispatch(updateCrStep(1));
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        value={business_name}
        name="business_name"
        label="Business or organisation name"
        width="100%"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={position}
        name="position"
        label="Your position"
        width="100%"
      />
      <CustomInputSingle
        onChange={handleEmailChange}
        value={business_email}
        name="business_email"
        label="Business email address"
        moreInfo="If different"
        width="100%"
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <br />
      <CustomPhone
        label="Business phone number"
        onChange={handlePhone}
        placeholder="Business phone number"
        value={business_phone_no}
        name="business_phone_no"
        required
      />

      {/* *Developer allow select same as before or add new with 5 fields including post code and country */}
      <CustomBigInputSingle
        onChange={handleChange}
        label="House number/name, Street name"
        moreInfo="main contact address (if different)"
        width="100%"
        name="business_address"
        value={business_address}
      />
      <CustomInputSingle
        onChange={handleChange}
        label="Address line 2"
        width="100%"
        name="business_address_2"
        value={business_address_2}
      />
      <CustomInputSingle
        onChange={handleChange}
        label="Town / City"
        width="100%"
        name="business_town"
        value={business_town}
      />
      <CustomInputSingle
        onChange={handleChange}
        label="Post / Zip Code"
        width="100%"
        name="business_zipcode"
        value={business_zipcode}
      />
      <CustomInputSingle
        onChange={handleChange}
        label="County / Region"
        width="100%"
        name="business_region"
        value={business_region}
      />
      <CustomAutoSelect
        onChange={handleChangeCO}
        label="Country"
        width="100%"
        name="business_country"
        value={business_country}
        data={CURRENT_COUNTRY}
      />
      <CustomBigInputSingle
        onChange={handleChange}
        label="Company registration number"
        moreInfo="If registered"
        width="100%"
        name="charity_registration_no"
        value={charity_registration_no}
      />
      <CustomBigInputSingle
        onChange={handleChange}
        label="Charity registration number"
        moreInfo="If registered"
        width="100%"
        name="company_registration_no"
        value={company_registration_no}
      />
      <CustomInputSingle
        onChange={handleChange}
        value={business_website}
        name="business_website"
        label="Business website"
        width="100%"
      />
      <CustomSelect
        onChange={handleChange}
        data={CURRENCY}
        width="100%"
        label="Preferred currency"
        name="currency"
        value={currency}
      />
      <CustomInputSingle
        onChange={handleChange}
        value={note}
        name="note"
        label="Additional information or note"
        width="100%"
      />

      <div
        className={classes.tc}
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>

      <div className={classes.buttonFlex}>
        <CustomButton
          text="Back"
          variant="outlined"
          color="primary"
          onClick={handleBack}
        />
        <CustomButton
          disabled={business_email && notValid}
          text="Submit"
          onClick={handleNext}
        />
      </div>
    </form>
  );
};

export default FormTwo;
