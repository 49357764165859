import { v4 as uuidv4 } from "uuid";

import CustomerLoginPage from "pages/CustomerLoginPage";
import EmployeeLoginPage from "pages/employee/Login";
import ForgotPasswrodPage from "pages/ForgotPasswrodPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import LinguistsLoginPage from "pages/linguist/Login";
import SetPasswordPage from "pages/SetPasswordPage";
import AccountClientLoginPage from "pages/AccountClientLogin";

const AUTH = [
  {
    id: uuidv4(),
    text: "Cash Client Login",
    path: "cash-client-login",
    type: "login",
    component: CustomerLoginPage,
  },
  {
    id: uuidv4(),
    text: "Account Client Login",
    path: "account-client-login",
    type: "login",
    component: AccountClientLoginPage,
  },
  {
    id: uuidv4(),
    text: "Employee Login",
    path: "employee-login",
    type: "login",
    component: EmployeeLoginPage,
  },
  {
    id: uuidv4(),
    text: "Forgot Password",
    path: "forgot-password",
    type: "login",
    component: ForgotPasswrodPage,
  },
  {
    id: uuidv4(),
    text: "Reset Password",
    path: "reset-password",
    type: "login",
    component: ResetPasswordPage,
  },
  {
    id: uuidv4(),
    text: "Set Your New Password",
    path: "auth/password/reset/:token",
    type: "login",
    component: SetPasswordPage,
  },
  {
    id: uuidv4(),
    text: "Linguists Login",
    path: "linguists-login",
    type: "login",
    component: LinguistsLoginPage,
  },
];

export default AUTH;
