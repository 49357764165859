import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import { COUNTRY_DATA, COUNTRY_DATA_SEM_UI, GENDER_DATA } from "data";
import moment from "moment";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("token");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("business_name", data.business_name || "-");
    fd.append("legal_business_name", data.legal_business_name || "-");
    fd.append("email", data.email || "-");
    fd.append("business_phone_no", data.business_phone_no || "-");
    fd.append("business_address_line1", data.business_address_line1 || "-");
    fd.append("business_address_line2", data.business_address_line2 || "-");
    fd.append("business_city", data.business_city || "-");
    fd.append("business_postal_code", data.business_postal_code || "-");
    fd.append("business_region", data.business_region || "-");
    fd.append("business_country", data.business_country || "-");
    fd.append("business_website", data.business_website || "-");
    fd.append("company_registration_no", data.company_registration_no || "-");
    fd.append("charity_registration_no", data.charity_registration_no || "-");
    fd.append("vat_number", data.vat_number || "-");
    fd.append("vat_country", data.vat_country || "-");

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/update-Sppo/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="business_name"
            onChange={handleChange}
            label="Business or organization name"
            edit={edit.value}
            value={data.business_name}
          />
          <CustomTextInput
            name="legal_business_name"
            onChange={handleChange}
            label="Legal business name"
            edit={edit.value}
            value={data.legal_business_name}
          />
          <CustomTextInput
            name="email"
            onChange={handleChange}
            label="Primary / Business Email"
            edit={edit.value}
            value={data.email}
          />
          {/* <CustomTextInput name= 'business_phone_no' onChange= {handleChange} label= 'Business phone number' edit= {edit.value} value= {data.business_phone_no}/> */}
          {edit.value ? (
            <CustomTextInput
              name="business_phone_no"
              onChange={handleMobile}
              label="Business phone number"
              pvalue={data.business_phone_no}
              mobile={true}
            />
          ) : (
            <CustomTextInput
              name="business_phone_no"
              onChange={handleChange}
              label="Business phone number"
              value={data.business_phone_no}
            />
          )}
          <CustomTextInput
            name="business_address_line1"
            onChange={handleChange}
            label="House number / Street number"
            edit={edit.value}
            value={data.business_address_line1}
          />
          <CustomTextInput
            name="business_address_line2"
            onChange={handleChange}
            label="Address"
            edit={edit.value}
            value={data.business_address_line2}
          />
          <CustomTextInput
            name="business_city"
            onChange={handleChange}
            label="Town / City"
            edit={edit.value}
            value={data.business_city}
          />
          <CustomTextInput
            name="business_postal_code"
            onChange={handleChange}
            label="Zip code / Postal code"
            edit={edit.value}
            value={data.business_postal_code}
          />
          <CustomTextInput
            name="business_region"
            onChange={handleChange}
            label="County / Region"
            edit={edit.value}
            value={data.business_region}
          />

          {edit.value ? (
            <CustomTextInput
              name="business_country"
              onChange={handleChange}
              select={COUNTRY_DATA}
              svalue={data.business_country}
              label="Country"
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              name="business_country"
              onChange={handleChange}
              label="Country"
              value={data.business_country}
            />
          )}
          <CustomTextInput
            name="business_website"
            onChange={handleChange}
            label="Business Website"
            edit={edit.value}
            value={data.business_website}
          />
          <CustomTextInput
            name="company_registration_no"
            onChange={handleChange}
            label="Company Registration Number"
            edit={edit.value}
            value={data.company_registration_no}
          />
          <CustomTextInput
            name="charity_registration_no"
            onChange={handleChange}
            label="Charity Registration Number"
            edit={edit.value}
            value={data.charity_registration_no}
          />
          <CustomTextInput
            name="vat_number"
            onChange={handleChange}
            label="VAT / GST Number"
            edit={edit.value}
            value={data.vat_number}
          />
          <CustomTextInput
            name="vat_country"
            onChange={handleChange}
            label="VAT Country"
            edit={edit.value}
            value={data.vat_country}
          />
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
