import axios from "axios";
import Cookie from "js-cookie";

export const URL = "https://linguistscollective.herokuapp.com/";

const defaultAxios = axios.create({
  baseURL: URL,
});

const token = Cookie.get("token");
defaultAxios.defaults.headers.common["Authorization"] = "Bearer " + token;

export default defaultAxios;
