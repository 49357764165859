import React, { useState, useEffect } from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import { Header, Button } from "semantic-ui-react";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton, Divider } from "@material-ui/core";
import history from "utils/history";
import Avatar from "@material-ui/core/Avatar";
import ITLimage from "../../assets/icons/graphics-01.png";

const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  const avatar_classes = useEmployeeStyles();
  const [jobadvert_access, setAssignmentAccess] = useState(null);
  const [bacUser, setbacUser] = useState(null);
  const [clientID, setClientID] = useState(null);

  const getDetails = async (e) => {
    const token = Cookies.get("BACtoken");

    const response = await Axios.get("/api/accountclient/dashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setAssignmentAccess(response?.data.jobadvert_access);
    setbacUser(response?.data.bacUser);
    setClientID(response?.data._id);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Accountclient>
      <AccountClientLayout>
        <Card>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            color="primary"
          >
            <p className={classes.cardTitleWhite}>Billing Account Job Advert</p>
            <IconButton color="inherit" onClick={() => history.goBack()}>
              <ArrowBackIcon />
            </IconButton>
          </CardHeader>
          <GridContainer
            style={{
              justifyContent: "center",
            }}
          >
            {jobadvert_access ? (
              <GridItem xs={12} sm={12} md={12} lg={4}>
                <Card>
                  <CardHeader>
                    <Avatar
                      onContextMenu={(e) => e.preventDefault()}
                      className={avatar_classes.large_avatar}
                      src={ITLimage}
                      alt="job advert Photo"
                    />
                  </CardHeader>
                  <Divider orientation="horizontal" />

                  <CardBody>
                    <h5
                      style={{
                        // textAlign: "center",
                        width: "100%",
                        // color: "purple",
                      }}
                    >
                      <ul>
                        <li>
                          Both CAC and BAC are welcome to post industry related
                          or language related job adverts with us, using the
                          form here. You must meet the following conditions:
                        </li>
                        <li>
                          The advert must be about a language or industry
                          related job.
                        </li>
                        <li>
                          The advert should be from the employer directly.
                        </li>
                        <li>
                          Advert must be employment related not a freelancing
                          opportunity.
                        </li>
                        <li>
                          The advert should be posted in writing and paid for,
                          using the appropriate form here.
                        </li>
                        <li>
                          LC will charge £̶1̶0̶0̶ (90% discount) per advert, but
                          will allow a 50% discount for NHS and registered
                          charities with a certified charity number.
                        </li>
                        <li>
                          The job advert will be published on our website.
                        </li>
                      </ul>
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        color="purple"
                        onClick={() =>
                          window.open(
                            `/interpreter-translator-linguists-job-advert/${clientID}`
                          )
                        }
                      >
                        Click here to post job advert
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            ) : bacUser === false ? (
              <GridItem xs={12} sm={12} md={12} lg={4}>
                <Card>
                  {/* <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <li className={classes.cardTitleWhite}>
                      Billing Account Job Advert
                    </li>
                    <IconButton
                      color="inherit"
                      onClick={() => history.goBack()}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </CardHeader> */}
                  <CardHeader>
                    <Avatar
                      onContextMenu={(e) => e.preventDefault()}
                      className={avatar_classes.large_avatar}
                      src={ITLimage}
                      alt="job advert Photo"
                    />
                  </CardHeader>
                  <Divider orientation="horizontal" />
                  <CardBody>
                    {/* <ACJobAdvert /> */}

                    <h5
                      style={{
                        // textAlign: "center",
                        width: "100%",
                        // color: "purple",
                      }}
                    >
                      <li>
                        Both clients and customers are welcome to post industry
                        related or language related job adverts with us, using
                        the form here. You must meet the following conditions:
                      </li>
                      <li>
                        The advert must be about a language or industry related
                        job.
                      </li>
                      <li>The advert should be from the employer directly.</li>
                      <li>
                        Advert must be employment related not a freelancing
                        opportunity.
                      </li>
                      <li>
                        The advert should be posted in writing and paid for,
                        using the appropriate form here.
                      </li>
                      <li>
                        LC will charge £̶1̶0̶0̶ (90% discount) per advert, but will
                        allow a 50% discount for NHS and registered charities
                        with a certified charity number.
                      </li>
                      <li>
                        The job advert will be sent by email to all linguists
                        registered with us and show a notification on their
                        profile when they login.
                      </li>
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        color="purple"
                        onClick={() =>
                          window.open(
                            `/interpreter-translator-linguists-job-advert/${clientID}`
                          )
                        }
                      >
                        Click here to post job advert
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            ) : jobadvert_access === false ? (
              <Header as="h3">Access denied</Header>
            ) : (
              <Header as="h3">Loading...</Header>
            )}
          </GridContainer>
        </Card>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default UnconfirmedAssignmentsPage;
