import React from "react";

const IconImg = ({ icon, width, ...props }) => {
  return (
    <img
      {...props}
      src={icon}
      alt="icon"
      style={{
        width: `${width || "90%"}`,
      }}
    />
  );
};

export default IconImg;
