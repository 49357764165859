import ViewQRLinguistsPage from "pages/ViewQR";
import ViewQRClientsPage from "pages/ViewQRClients";
import ViewQRManagerPage from "pages/ViewQRManager";

const VIEW_QR = [
  {
    id: '90b',
    type: "form",
    text: "View Quote Request (Linguists)",
    path: "view-qr-linguists",
    component: ViewQRLinguistsPage,
  },
  {
    id: '90c',
    type: "form",
    text: "View Quote Request (Clients)",
    path: "view-qr-clients",
    component: ViewQRClientsPage,
  },
  {
    id: '90d',
    type: "form",
    text: "View Quote Request (Manager)",
    path: "view-qr-manager",
    component: ViewQRManagerPage,
  },
];

export default VIEW_QR;
