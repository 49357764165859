import CustomTextInput from "components/Common/CustomTextInput";
import React, { useRef, useState, useEffect } from "react";
import { Button, Dimmer, Loader, Rating, Table } from "semantic-ui-react";
import { Header, Checkbox } from "semantic-ui-react";
import { IconButton, TextField } from "@material-ui/core";

import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import history from "utils/history";
import RichTextEditor from "react-rte";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "axios";
import moment from "moment";
import CustomTime from "components/Common/CustomTime";
import swal from "sweetalert";

const Details = () => {
  let sigPad = useRef({});
  const [signature, setSignature] = useState(false);
  const [sign, setSign] = useState("");
  const [text, setText] = useState();
  const [projectReport, setProjectReport] = useState(null);
  const [linguist, setLinguist] = useState(null);
  const { id } = useParams();
  const token = Cookies.get("LINtoken");
  const [declaration, setDeclaration] = useState(false);
  const [details, setDetails] = useState({
    project_name: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    request_details: "",
    target_language_level: "",
    security_clearence: "",
    word_count: "",
    visual_formatting: "",
    certification: "",
    post_certification: "",
    project_report: RichTextEditor.createEmptyValue(),
    signature: "",
    supervisor_fullname: "",
    date: "",
    feedbackByLin: "",
    remarksByLin: "",
    loader: false,
  });
  const uploadRef = useRef();
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const todayDate = moment().format("DD/MM/YYYY");

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getProjectReport = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/view-lin-quote/linQuoteID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );

        setProjectReport(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancel");
        } else {
          console.log(error.response);
        }
      }
    };

    getProjectReport();

    return () => {
      source.cancel();
    };
  }, []);

  const handleClose = (e) => {
    e.preventDefault();
    setSignature(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = () => {
    const temp = sigPad.current.toDataURL();
    setDetails({ ...details, signature: temp });
    setSignature(false);
  };

  const handleText = (value) => {
    setText(value.toString("markdown"));
    setDetails({ ...details, project_report: value });
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmitCAC = async (e) => {
    setDetails({ ...details, loader: true });
    const fd = new FormData();

    const file = new File([details.signature], "signature", {
      type: "image/png",
    });

    fd.append("project_name", details.project_name);
    fd.append("start_date", details.start_date || moment(projectReport.createdAt).format("DD/MMM/YYYY"));
    fd.append("end_date", details.end_date || moment(projectReport.expiry_date).format("DD/MMM/YYYY"));
    fd.append("start_time", details.start_time || moment(projectReport.createdAt).format("HH:mm"));
    fd.append("end_time", details.end_time || moment(projectReport.expiry_date).format("HH:mm"));
    fd.append("requested_start_date", moment(projectReport.createdAt).format("DD/MMM/YYYY") || "");
    fd.append("requested_end_date", moment(projectReport.expiry_date).format("DD/MMM/YYYY") || "");
    fd.append("requested_start_time", moment(projectReport.createdAt).format("HH:mm") || "");
    fd.append("requested_end_time", moment(projectReport.expiry_date).format("HH:mm") || "");
    fd.append("requested_wordCount", projectReport?.wordCount || "");
    fd.append("word_count", details.word_count || "");
    fd.append("request_details", details.request_details);
    fd.append(
      "target_language_level",
      projectReport.target_expertise_level_required
    );
    fd.append("security_clearence", projectReport.security_clearance);
    fd.append("visual_formatting", projectReport.require_visual_formating);
    fd.append("certification", projectReport.certificate);
    fd.append("post_certification", projectReport.certified_translation);
    fd.append("project_report", text);
    fd.append("signatureByLin", file);
    fd.append("supervisor_fullname", "");
    fd.append("date", moment().format("YYYY/MM/DD"));
    fd.append("feedbackByLin", details.feedbackByLin);

    if (uploadTimesheet?.size) {
      fd.append("document", uploadTimesheet);
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/create-project-report/cashclient/linQuoteID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDetails({ ...details, loader: false });
      swal({
        text: "Project Report Created",
        buttons: "Ok",
        icon: "success",
      });
      history.push(`/linguist/accepted-quote`);
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  const handleSubmitBAC = async (e) => {
    setDetails({ ...details, loader: true });
    const fd = new FormData();

    const file = new File([details.signature], "signature", {
      type: "image/png",
    });

    fd.append("project_name", details.project_name);
    fd.append("start_date", details.start_date || moment(projectReport.createdAt).format("DD/MMM/YYYY"));
    fd.append("end_date", details.end_date || moment(projectReport.expiry_date).format("DD/MMM/YYYY"));
    fd.append("start_time", details.start_time || moment(projectReport.createdAt).format("HH:mm"));
    fd.append("end_time", details.end_time || moment(projectReport.expiry_date).format("HH:mm"));
    fd.append("requested_start_date", moment(projectReport.createdAt).format("DD/MMM/YYYY") || "");
    fd.append("requested_end_date", moment(projectReport.expiry_date).format("DD/MMM/YYYY") || "");
    fd.append("requested_start_time", moment(projectReport.createdAt).format("HH:mm") || "");
    fd.append("requested_end_time", moment(projectReport.expiry_date).format("HH:mm") || "");
    fd.append("requested_wordCount", projectReport?.count || "");
    fd.append("word_count", details.word_count || "");
    fd.append("request_details", details.request_details);
    fd.append(
      "target_language_level",
      projectReport.target_expertise_level_required
    );
    fd.append("security_clearence", projectReport.security_clearance);
    fd.append("visual_formatting", projectReport.require_visual_formating);
    fd.append("certification", projectReport.certificate);
    fd.append("post_certification", projectReport.certified_translation);
    fd.append("project_report", text);
    fd.append("signatureByLin", file);
    fd.append("supervisor_fullname", "");
    fd.append("date", moment().format("DD/MMM/YYYY"));
    fd.append("feedbackByLin", details.feedbackByLin);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/create-project-report/accountclient/linQuoteID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDetails({ ...details, loader: false });
      swal({
        text: "Project Report Created",
        buttons: "Ok",
        icon: "success",
      });
      history.push(`/linguist/accepted-quote`);
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  const handleRate = (e, { rating, maxRating }) => {
    setDetails({ ...details, feedbackByLin: rating });
  };

  return projectReport ? (
    <div>
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          // onEnd = {handleSave}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            basic
            color="purple"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="purple">
            Save
          </Button>
        </div>
      </CustomModal>
      <CustomTextInput
        label="Assignment reference number"
        value={projectReport?.id}
      />
      {/* <CustomTextInput label= 'Translation / Training /Other Project name' value= 'abc' /> */}

      {/* Table */}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center">Requested</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actual</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Project start date<b style={{ color: "red" }}>*</b>: </Table.Cell>
            <Table.Cell textAlign="center">
              {moment(projectReport.createdAt).format("DD/MMM/YYYY")}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <TextField
                type="date"
                value={details.start_date}
                onChange={(e) =>
                  setDetails({ ...details, start_date: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project start time<b style={{ color: "red" }}>*</b>: </Table.Cell>
            <Table.Cell textAlign="center">
              {moment(projectReport.createdAt).format("HH:mm")}</Table.Cell>
            <Table.Cell>
              <CustomTime
                value={details.start_time}
                onChange={(e) =>
                  setDetails({ ...details, start_time: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project end date<b style={{ color: "red" }}>*</b>: </Table.Cell>

            <Table.Cell textAlign="center">
              {projectReport?.expiry_date &&
                moment(projectReport.expiry_date).format("DD/MMM/YYYY")}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <TextField
                type="date"
                value={details.end_date}
                onChange={(e) =>
                  setDetails({ ...details, end_date: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project end time<b style={{ color: "red" }}>*</b>: </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.expiry_date &&
                moment(projectReport.expiry_date).format("HH:mm")}
            </Table.Cell>
            <Table.Cell>
              <CustomTime
                value={details.end_time}
                onChange={(e) =>
                  setDetails({ ...details, end_time: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.quoteRequstByCashclient?.target_skills_required
                ? projectReport?.quoteRequstByCashclient?.target_skills_required
                : projectReport?.quoteRequstByAccountclient
                  ?.target_skills_required
                  ? projectReport?.quoteRequstByAccountclient
                    ?.target_skills_required
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport.postedBy.target_language_level
                ? projectReport.postedBy.target_language_level
                : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Security clearance/s</Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.quoteRequstByCashclient?.security_clearance
                ? projectReport?.quoteRequstByCashclient?.security_clearance
                : projectReport?.quoteRequstByAccountclient?.security_clearance
                  ? projectReport?.quoteRequstByAccountclient?.security_clearance
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.postedBy?.security_clearance
                ? projectReport?.postedBy?.security_clearance
                : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Wordcount</Table.Cell>
            <Table.Cell textAlign="center">{projectReport?.wordCount ? projectReport?.wordCount : "N/A"}</Table.Cell>
            <Table.Cell>
              <CustomTextInput
                number={true}
                name="word_count"
                onChange={(e) =>
                  setDetails({ ...details, word_count: e.target.value })
                }
                value={details.word_count}
                edit={true}
                placeholder="100"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Visual formatting</Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.quoteRequstByCashclient?.require_visual_formating
                ? projectReport?.quoteRequstByCashclient
                  ?.require_visual_formating
                : projectReport?.quoteRequstByAccountclient
                  ?.require_visual_formating
                  ? projectReport?.quoteRequstByAccountclient
                    ?.require_visual_formating
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.quoteRequstByCashclient?.require_visual_formating
                ? projectReport?.quoteRequstByCashclient
                  ?.require_visual_formating
                : projectReport?.quoteRequstByAccountclient
                  ?.require_visual_formating
                  ? projectReport?.quoteRequstByAccountclient
                    ?.require_visual_formating
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Certification</Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.quoteRequstByCashclient?.certificate
                ? projectReport?.quoteRequstByCashclient?.certificate
                : projectReport?.quoteRequstByAccountclient?.certificate
                  ? projectReport?.quoteRequstByAccountclient?.certificate
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.quoteRequstByCashclient?.certificate
                ? projectReport?.quoteRequstByCashclient?.certificate
                : projectReport?.quoteRequstByAccountclient?.certificate
                  ? projectReport?.quoteRequstByAccountclient?.certificate
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Post certification </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.quoteRequstByCashclient?.certified_translation
                ? projectReport?.quoteRequstByCashclient?.certified_translation
                : projectReport?.quoteRequstByAccountclient
                  ?.certified_translation
                  ? projectReport?.quoteRequstByAccountclient
                    ?.certified_translation
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.quoteRequstByCashclient?.certified_translation
                ? projectReport?.quoteRequstByCashclient?.certified_translation
                : projectReport?.quoteRequstByAccountclient
                  ?.certified_translation
                  ? projectReport?.quoteRequstByAccountclient
                    ?.certified_translation
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">Project Report<b style={{color: "red"}}>*</b></Header>
      <RichTextEditor value={details.project_report} onChange={handleText} />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
            <Table.HeaderCell>To be completed by admin</Table.HeaderCell>
            <Table.HeaderCell>To be completed by client</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Checkbox
                checked={declaration}
                onChange={(e, value) => setDeclaration(value.checked)}
                label="I declare the information given by me is true/accurate and complete."
              />
            </Table.Cell>
            <Table.Cell>
              <Checkbox
                label="I declare that I have checked the information on this form and certify that it is true and complete"
                disabled
              />
            </Table.Cell>
            <Table.Cell>
              <Checkbox
                label="I declare that I have checked the information on this form and certify that it is true and complete"
                disabled
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Signature{" "}
              <IconButton onClick={() => setSignature(true)}>
                <EditIcon />
              </IconButton>
              {details.signature && (
                <img
                  style={{ width: "100px", height: "50px" }}
                  src={details.signature}
                  alt=""
                />
              )}
            </Table.Cell>
            <Table.Cell>Signature</Table.Cell>
            <Table.Cell>Signature</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p>
                {" "}
                Full name:
                <b>
                  {projectReport?.postedBy?.FirstName
                    ? projectReport?.postedBy?.FirstName
                    : " "}
                </b>
                <b>
                  {projectReport?.postedBy?.LastName
                    ? projectReport?.postedBy?.LastName
                    : " "}
                </b>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>
                {" "}
                Full name:
                <b>
                  {projectReport?.postedBySupervisor?.FirstName
                    ? projectReport?.postedBySupervisor?.FirstName
                    : projectReport?.postedBySupervisor?.FirstName
                      ? projectReport?.postedBySupervisor?.FirstName
                      : " "}
                </b>
                <b>
                  {projectReport?.postedBySupervisor?.LastName
                    ? projectReport?.postedBySupervisor?.LastName
                    : projectReport?.postedBySupervisor?.LastName
                      ? projectReport?.postedBySupervisor?.LastName
                      : " "}
                </b>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>
                {" "}
                Full name:
                <b>
                  {projectReport?.quoteRequstByCashclient?.postedBy?.FirstName
                    ? projectReport?.quoteRequstByCashclient?.postedBy
                      ?.FirstName
                    : projectReport?.quoteRequstByAccountclient?.postedBy
                      ?.FirstName
                      ? projectReport?.quoteRequstByAccountclient?.postedBy
                        ?.FirstName
                      : " "}
                </b>
                <b>
                  {projectReport?.quoteRequstByCashclient?.postedBy?.LastName
                    ? projectReport?.quoteRequstByCashclient?.postedBy?.LastName
                    : projectReport?.quoteRequstByAccountclient?.postedBy
                      ?.LastName
                      ? projectReport?.quoteRequstByAccountclient?.postedBy
                        ?.LastName
                      : " "}
                </b>
              </p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {todayDate}</Table.Cell>
            <Table.Cell>Date: {todayDate}</Table.Cell>
            <Table.Cell>Date: {todayDate}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Feedback<b style={{ color: "red" }}>*</b>:
              <Rating
                maxRating={5}
                onRate={handleRate}
                icon="star"
                size="massive"
              />
            </Table.Cell>
            <Table.Cell>Remark</Table.Cell>
            <Table.Cell>Feedback</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {/* <Signature projectReport={projectReport} /> */}

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button
        onClick={() => uploadRef.current.click()}
        basic
        style={{ backgroundColor: "purple", color: "white" }}
      >
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>

      {projectReport?.quoteRequstByCashclient ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            loading={details.loader}
            onClick={handleSubmitCAC}
            disabled={
              !declaration ||
              !details.feedbackByLin ||
              !details.signature ||
              !details.start_date ||
              !details.start_time ||
              !details.end_date ||
              !details.end_time 
            }
            color="purple"
          >
            Submit
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            loading={details.loader}
            onClick={handleSubmitBAC}
            disabled={
              !details.project_report ||
              !details.start_date ||
              !details.start_time ||
              !details.end_date ||
              !details.end_time ||
              !declaration ||
              !details.feedbackByLin ||
              !details.signature
            }
            color="purple"
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="large">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
