import React, { useState } from "react";
import useDropdownStyles from "assets/jss/components/dropDownStyles";
import Axios from "utils/axios";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
const AssignmentPage = () => {
  const classesTwo = useDropdownStyles();
  const [searchData, setSearchData] = React.useState({
    assignmentID: "",
  });
  const [values, setValues] = useState({ reload: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleChange = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
    FindAssignment(e.target.value);
  };

  const FindAssignment = async (id) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/search-assignment/assignmentID/${id}`
      );
      setValues(response?.data);
      handleClick();
    } catch (error) { }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TextField
        label="Search jobs"
        onChange={handleChange}
        variant="outlined"
        name="assignmentID"
        style={{ margin: 5, borderRadius: "8pt" }}
        onClick={handleClick}
        placeholder="Job / Assignment ID.."
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                color="purple"
                disabled={!searchData.assignmentID}
                onClick={() => FindAssignment(searchData.assignmentID)}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popper
        open={Boolean(open)}
        anchorEl={anchorEl}
        placement="auto-start"
        className={classesTwo.search}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList role="menu">
                  <MenuItem
                    className={classesTwo.dropdownItem}
                    onClick={handleChange}
                  >
                    {searchData.assignmentID == "" ? (
                      "No results found"
                    ) : !values ? null : values?.active && // search unconfirm assignment cac, bac and inc interpreting and translation
                      !values?.payment_status &&
                      !values?.id?.includes("PUBJA") ? (
                      <>
                        {values?.id?.includes("CACIA") ? ( //cac interpreting assignment
                          <a
                            href={`/employee/unconfirmed-assignments/cash-client/${values?.id
                              }/${"interpreting"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("CACTA") ? ( //cac translation assignment
                          <a
                            href={`/employee/unconfirmed-assignments/cash-client/${values?.id
                              }/${"translation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("CACQR") ? ( //cac translation assignment
                          <a
                            href={`/employee/quotation/cac-requestedQuote/${values?.id
                              }/${"quotation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("BACIA") || //bac interpreting assignment
                          values?.id?.includes("BACTIA") ? ( //bac telephonic interpreting assignment
                          <a
                            href={`/employee/unconfirmed-assignments/billing-account-client/${values?.id
                              }/${"interpreting"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("BACTA") || //bac translation assignment
                          values?.id?.includes("BACTTA") ? ( //bac telephonic translation assignment
                          <a
                            href={`/employee/unconfirmed-assignments/billing-account-client/${values?.id
                              }/${"translation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("BACQR") || //bac quotation
                          values?.id?.includes("BACQRT") ? ( //bac telephonic quotation
                          <a
                            href={`/employee/quotation/bac-requestedQuote/${values?.id
                              }/${"quotation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("IIA") || //inhouse interpreting assignment
                          values?.id?.includes("PIIA") || //sppo inhouse interpreting assignment
                          values?.id?.includes("ITIA") || //inhouse telephonic interpreting assignment
                          values?.id?.includes("PITIA") ? ( //sppo inhouse telephonic interpreting assignment
                          <a
                            href={`/employee/unconfirmed-assignments/inhouse-client/${values?.id
                              }/${"interpreting"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("ITA") || //inhouse translation assignment
                          values?.id?.includes("PITA") || //sppo inhouse translation assignment
                          values?.id?.includes("ITTA") || //inhouse telephonic translation assignment
                          values?.id?.includes("PITTA") ? ( //sppo inhouse telephonic translation assignment
                          <a
                            href={`/employee/unconfirmed-assignments/inhouse-client/${values?.id
                              }/${"translation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("PIA") || //private interpreting assignment
                          values?.id?.includes("PTIA") ? ( //private telephonic interpreting assignment
                          <a
                            href={`/employee/unconfirmed-assignments/private-client/${values?.id
                              }/${"interpreting"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("PTA") || //private translation assignment
                          values?.id?.includes("PTTA") ? ( //private telephonic translation assignment
                          <a
                            href={`/employee/unconfirmed-assignments/private-client/${values?.id
                              }/${"translation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("LINCQ") ||
                          values?.quoteID?.includes("CACQR") ? (
                          <a
                            href={`/employee/unconfirmed-assignments/cash-client/${values?.id
                              }/${"quotation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("LINBQ") ||
                          values?.quoteID?.includes("BACQR") ? (
                          <a
                            href={`/employee/unconfirmed-assignments/billing-account-client/${values?.id
                              }/${"quotation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : (
                          "No results found"
                        )}{" "}
                      </>
                    ) : // search confirm assignment cac, bac and inc interpreting and translation
                      values?.payment_status &&
                        values?.active &&
                        !values?.accept &&
                        !values?.timesheet &&
                        !values?.cancel &&
                        !values?.id?.includes("PUBJA") ? (
                        <>
                          {values?.id?.includes("CACIA") ? ( //cac interpreting assignment
                            <a
                              href={`/employee/cac-interpreting/confirm-assignments/${values?.id
                                }/${"interpreting"}`}
                            >
                              {" "}
                              {values?.id}
                            </a>
                          ) : values?.id?.includes("CACTA") ? ( //cac translation assignment
                            <a
                              href={`/employee/cac-translation/confirm-assignments/${values?.id
                                }/${"translation"}`}
                            >
                              {" "}
                              {values?.id}
                            </a>
                          ) : values?.id?.includes("BACIA") || //bac interpreting assignment
                            values?.id?.includes("BACTIA") ? ( //bac telephonic interpreting assignment
                            <a
                              href={`/employee/bac-interpreting/confirm-assignments/${values?.id
                                }/${"interpreting"}`}
                            >
                              {" "}
                              {values?.id}
                            </a>
                          ) : values?.id?.includes("BACTA") ||
                            values?.id?.includes("BACTTA") ? ( //bac translation assignment
                            <a
                              href={`/employee/bac-translation/confirm-assignments/${values?.id
                                }/${"translation"}`}
                            >
                              {" "}
                              {values?.id}
                            </a>
                          ) : values?.id?.includes("IIA") || //inhouse interpreting assignment
                            values?.id?.includes("PIIA") || //sppo inhouse interpreting assignment
                            values?.id?.includes("ITIA") || //inhouse telephonic interpreting assignment
                            values?.id?.includes("PITIA") ? ( //sppo inhouse telephonic interpreting assignment
                            <a
                              href={`/employee/inhouse-interpreting/confirm-assignments/${values?.id
                                }/${"interpreting"}`}
                            >
                              {" "}
                              {values?.id}
                            </a>
                          ) : values?.id?.includes("ITA") || //inhouse translation assignment
                            values?.id?.includes("PITA") || //sppo inhouse translation assignment
                            values?.id?.includes("ITTA") || //inhouse telephonic translation assignment
                            values?.id?.includes("PITTA") ? ( //sppo inhouse telephonic translation assignment
                            <a
                              href={`/employee/inhouse-translation/confirm-assignments/${values?.id
                                }/${"translation"}`}
                            >
                              {" "}
                              {values?.id}
                            </a>
                          ) : (
                            "No results found"
                          )}{" "}
                        </>
                      ) : // search assigned assignment cac, bac and inc interpreting and translation or quote confirmed by client
                        values?.payment_status &&
                          values?.active &&
                          values?.accept &&
                          !values.timesheet &&
                          !values.cancel ? (
                          <>
                            {values?.id?.includes("CACIA") ? ( //cac interpreting assignment
                              <a
                                href={`/employee/cac-interpreting/assigned-assignments/${values?.id
                                  }/${"interpreting"}`}
                              >
                                {" "}
                                {values?.id}
                              </a>
                            ) : values?.id?.includes("CACTA") ? ( //cac translation assignment
                              <a
                                href={`/employee/cac-translation/assigned-assignments/${values?.id
                                  }/${"translation"}`}
                              >
                                {" "}
                                {values?.id}
                              </a>
                            ) : values?.id?.includes("BACIA") || //bac interpreting assignment
                              values?.id?.includes("BACTIA") ? ( //bac telephonic interpreting assignment
                              <a
                                href={`/employee/bac-interpreting/assigned-assignments/${values?.id
                                  }/${"interpreting"}`}
                              >
                                {" "}
                                {values?.id}
                              </a>
                            ) : values?.id?.includes("BACTA") || //bac translation assignment
                              values?.id?.includes("BACTTA") ? ( //bac telephonic translation assignment
                              <a
                                href={`/employee/bac-translation/assigned-assignments/${values?.id
                                  }/${"translation"}`}
                              >
                                {" "}
                                {values?.id}
                              </a>
                            ) : values?.id?.includes("IIA") || //inhouse interpreting assignment
                              values?.id?.includes("PIIA") || //sppo inhouse interpreting assignment
                              values?.id?.includes("ITIA") || //inhouse telephonic interpreting assignment
                              values?.id?.includes("PITIA") ? ( //sppo inhouse telephonic interpreting assignment
                              <a
                                href={`/employee/inhouse-interpreting/assigned-assignments/${values?.id
                                  }/${"interpreting"}`}
                              >
                                {" "}
                                {values?.id}
                              </a>
                            ) : values?.id?.includes("ITA") || //inhouse translation assignment
                              values?.id?.includes("PITA") || //sppo inhouse translation assignment
                              values?.id?.includes("ITTA") || //inhouse telephonic translation assignment
                              values?.id?.includes("PITTA") ? ( //sppo inhouse telephonic translation assignment
                              <a
                                href={`/employee/inhouse-translation/assigned-assignments/${values?.id
                                  }/${"translation"}`}
                              >
                                {" "}
                                {values?.id}
                              </a>
                            ) : values?.id?.includes("PIA") || //private interpreting assignment
                              values?.id?.includes("PTIA") ? ( //private telephonic interpreting assignment
                              <a
                                href={`/employee/private-interpreting/confirm-assignments/${values?.id
                                  }/${"interpreting"}`}
                              >
                                {" "}
                                {values?.id}
                              </a>
                            ) : values?.id?.includes("PTA") || //private translation assignment
                              values?.id?.includes("PTTA") ? ( //private telephonic translation assignment
                              <a
                                href={`/employee/private-translation/confirm-assignments/${values?.id
                                  }/${"translation"}`}
                              >
                                {" "}
                                {values?.id}
                              </a>
                            ) : values?.id?.includes("LINCQ") ||
                              values?.quoteID?.includes("CACQR") ? (
                              <a
                                href={`/employee/quotation/cac-byClientQuote/${values?.id
                                  }/${"quotation"}`}
                              >
                                {" "}
                                {values?.id}
                              </a>
                            ) : values?.id?.includes("LINBQ") ||
                              values?.quoteID?.includes("BACQR") ? (
                              <a
                                href={`/employee/quotation/bac-byClientQuote/${values?.id
                                  }/${"quotation"}`}
                              >
                                {" "}
                                {values?.id}
                              </a>
                            ) : (
                              "No results found"
                            )}{" "}
                          </>
                        ) : // search closed assignment cac, bac and inc interpreting and translation
                          values?.timesheet &&
                            !values?.invoice &&
                            !values?.invoice_payment_status &&
                            values?.payment_status &&
                            values?.active &&
                            values?.accept ? (
                            <>
                              {values?.id?.includes("CACIA") ? ( //cac interpreting assignment
                                <a
                                  href={`/employee/cac-interpreting/closed-assignments/${values?.id
                                    }/${"interpreting"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.id?.includes("CACTA") ? ( //cac translation assignment
                                <a
                                  href={`/employee/cac-translation/closed-assignments/${values?.id
                                    }/${"translation"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.id?.includes("BACIA") || //bac interpreting assignment
                                values?.id?.includes("BACTIA") ? ( //bac telephonic interpreting assignment
                                <a
                                  href={`/employee/bac-interpreting/closed-assignments/${values?.id
                                    }/${"interpreting"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.id?.includes("BACTA") ||
                                values?.id?.includes("BACTTA") ? ( //bac translation assignment
                                <a
                                  href={`/employee/bac-translation/closed-assignments/${values?.id
                                    }/${"translation"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.id?.includes("IIA") || //inhouse interpreting assignment
                                values?.id?.includes("PIIA") || //sppo inhouse interpreting assignment
                                values?.id?.includes("ITIA") || //inhouse telephonic interpreting assignment
                                values?.id?.includes("PITIA") ? ( //sppo inhouse telephonic interpreting assignment
                                <a
                                  href={`/employee/inhouse-interpreting/closed-assignments/${values?.id
                                    }/${"interpreting"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.id?.includes("ITA") || //inhouse translation assignment
                                values?.id?.includes("PITA") || //sppo inhouse translation assignment
                                values?.id?.includes("ITTA") || //inhouse telephonic translation assignment
                                values?.id?.includes("PITTA") ? ( //sppo inhouse telephonic translation assignment
                                <a
                                  href={`/employee/inhouse-translation/closed-assignments/${values?.id
                                    }/${"translation"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.id?.includes("PIA") || //private interpreting assignment
                                values?.id?.includes("PTIA") ? ( //private telephonic interpreting assignment
                                <a
                                  href={`/employee/private-interpreting/closed-assignments/${values?.id
                                    }/${"interpreting"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.id?.includes("PTA") || //private translation assignment
                                values?.id?.includes("PTTA") ? ( //private telephonic translation assignment
                                <a
                                  href={`/employee/private-translation/closed-assignments/${values?.id
                                    }/${"translation"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.id?.includes("LINCQ") ? (
                                <a
                                  href={`/employee/quotation/cac-closed-jobs/${values?.id
                                    }/${"quotation"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.quoteID?.includes("CACQR") ? (
                                <a
                                  href={`/employee/quotation/cac-closed-jobs/${values?.id
                                    }/${"quotation"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.quoteID?.includes("BACQR") ? (
                                <a
                                  href={`/employee/quotation/bac-closed-jobs/${values?.id
                                    }/${"quotation"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : values?.id?.includes("LINBQ") ? (
                                <a
                                  href={`/employee/quotation/bac-closed-jobs/${values?.id
                                    }/${"quotation"}`}
                                >
                                  {" "}
                                  {values?.id}
                                </a>
                              ) : (
                                "No results found"
                              )}{" "}
                            </>
                          ) : // search cancelled assignment cac, bac and inc interpreting and translation
                            (values?.cancel || values?.cancel_request) &&
                              !values?.active &&
                              !values?.accept ? (
                              <>
                                {values?.id?.includes("CACIA") ? ( //cac interpreting assignment
                                  <a
                                    href={`/employee/cac-interpreting/canceled-assignments/${values?.id
                                      }/${"interpreting"}`}
                                  >
                                    {" "}
                                    {values?.id}
                                  </a>
                                ) : values?.id?.includes("CACTA") ? ( //cac translation assignment
                                  <a
                                    href={`/employee/cac-translation/canceled-assignments/${values?.id
                                      }/${"translation"}`}
                                  >
                                    {" "}
                                    {values?.id}
                                  </a>
                                ) : values?.id?.includes("BACIA") || //bac interpreting assignment
                                  values?.id?.includes("BACTIA") ? ( //bac telephonic interpreting assignment
                                  <a
                                    href={`/employee/bac-interpreting/canceled-assignments/${values?.id
                                      }/${"interpreting"}`}
                                  >
                                    {" "}
                                    {values?.id}
                                  </a>
                                ) : values?.id?.includes("BACTA") ||
                                  values?.id?.includes("BACTTA") ? ( //bac translation assignment
                                  <a
                                    href={`/employee/bac-translation/canceled-assignments/${values?.id
                                      }/${"translation"}`}
                                  >
                                    {" "}
                                    {values?.id}
                                  </a>
                                ) : values?.id?.includes("IIA") || //inhouse interpreting assignment
                                  values?.id?.includes("PIIA") || //sppo inhouse interpreting assignment
                                  values?.id?.includes("ITIA") || //inhouse telephonic interpreting assignment
                                  values?.id?.includes("PITIA") ? ( //sppo inhouse telephonic interpreting assignment
                                  <a
                                    href={`/employee/inhouse-interpreting/canceled-assignments/${values?.id
                                      }/${"interpreting"}`}
                                  >
                                    {" "}
                                    {values?.id}
                                  </a>
                                ) : values?.id?.includes("ITA") || //inhouse translation assignment
                                  values?.id?.includes("PITA") || //sppo inhouse translation assignment
                                  values?.id?.includes("ITTA") || //inhouse telephonic translation assignment
                                  values?.id?.includes("PITTA") ? ( //sppo inhouse telephonic translation assignment
                                  <a
                                    href={`/employee/inhouse-translation/canceled-assignments/${values?.id
                                      }/${"translation"}`}
                                  >
                                    {" "}
                                    {values?.id}
                                  </a>
                                ) : values?.id?.includes("PIA") || //private interpreting assignment
                                  values?.id?.includes("PTIA") ? ( //private telephonic interpreting assignment
                                  <a
                                    href={`/employee/private-interpreting/canceled-assignments/${values?.id
                                      }/${"interpreting"}`}
                                  >
                                    {" "}
                                    {values?.id}
                                  </a>
                                ) : values?.id?.includes("PTA") || //private translation assignment
                                  values?.id?.includes("PTTA") ? ( //private telephonic translation assignment
                                  <a
                                    href={`/employee/private-translation/canceled-assignments/${values?.id
                                      }/${"translation"}`}
                                  >
                                    {" "}
                                    {values?.id}
                                  </a>
                                ) : values?.id?.includes("LINCQ") ||
                                  values?.quoteID?.includes("CACQR") ? (
                                  <a
                                    href={`/employee/quotation/cac-canceled-jobs/${values?.id
                                      }/${"quotation"}`}
                                  >
                                    {" "}
                                    {values?.id}
                                  </a>
                                ) : values?.id?.includes("LINBQ") ||
                                  values?.quoteID?.includes("BACQR") ? (
                                  <a
                                    href={`/employee/quotation/bac-canceled-jobs/${values?.id
                                      }/${"quotation"}`}
                                  >
                                    {" "}
                                    {values?.id}
                                  </a>
                                ) : (
                                  "No results found"
                                )}{" "}
                              </>
                            ) : // search invoiced assignment cac, bac and inc interpreting and translation
                              !values?.invoice_payment_status &&
                                values?.invoice &&
                                !values?.id?.includes("PUBJA") ? (
                                <>
                                  {values?.id?.includes("CACIA") ? ( //cac interpreting assignment
                                    <a
                                      href={`/employee/cac-interpreting/invoiced-assignments/${values?.id
                                        }/${"interpreting"}`}
                                    >
                                      {" "}
                                      {values?.id}
                                    </a>
                                  ) : values?.id?.includes("CACTA") ? ( //cac translation assignment
                                    <a
                                      href={`/employee/cac-translation/invoiced-assignments/${values?.id
                                        }/${"translation"}`}
                                    >
                                      {" "}
                                      {values?.id}
                                    </a>
                                  ) : values?.id?.includes("BACIA") || //bac interpreting assignment
                                    values?.id?.includes("BACTIA") ? ( //bac telephonic interpreting assignment
                                    <a
                                      href={`/employee/bac-interpreting/invoiced-assignments/${values?.id
                                        }/${"interpreting"}`}
                                    >
                                      {" "}
                                      {values?.id}
                                    </a>
                                  ) : values?.id?.includes("BACTA") ||
                                    values?.id?.includes("BACTTA") ? ( //bac translation assignment
                                    <a
                                      href={`/employee/bac-translation/invoiced-assignments/${values?.id
                                        }/${"translation"}`}
                                    >
                                      {" "}
                                      {values?.id}
                                    </a>
                                  ) : values?.id?.includes("IIA") || //inhouse interpreting assignment
                                    values?.id?.includes("PIIA") || //sppo inhouse interpreting assignment
                                    values?.id?.includes("ITIA") || //inhouse telephonic interpreting assignment
                                    values?.id?.includes("PITIA") ? ( //sppo inhouse telephonic interpreting assignment
                                    <a
                                      href={`/employee/inhouse-interpreting/invoiced-assignments/${values?.id
                                        }/${"interpreting"}`}
                                    >
                                      {" "}
                                      {values?.id}
                                    </a>
                                  ) : values?.id?.includes("ITA") || //inhouse translation assignment
                                    values?.id?.includes("PITA") || //sppo inhouse translation assignment
                                    values?.id?.includes("ITTA") || //inhouse telephonic translation assignment
                                    values?.id?.includes("PITTA") ? ( //sppo inhouse telephonic translation assignment
                                    <a
                                      href={`/employee/inhouse-translation/invoiced-assignments/${values?.id
                                        }/${"translation"}`}
                                    >
                                      {" "}
                                      {values?.id}
                                    </a>
                                  ) : values?.id?.includes("PIA") || //private interpreting assignment
                                    values?.id?.includes("PTIA") ? ( //private telephonic interpreting assignment
                                    <a
                                      href={`/employee/private-interpreting/invoiced-assignments/${values?.id
                                        }/${"interpreting"}`}
                                    >
                                      {" "}
                                      {values?.id}
                                    </a>
                                  ) : values?.id?.includes("PTA") || //private translation assignment
                                    values?.id?.includes("PTTA") ? ( //private telephonic translation assignment
                                    <a
                                      href={`/employee/private-translation/invoiced-assignments/${values?.id
                                        }/${"translation"}`}
                                    >
                                      {" "}
                                      {values?.id}
                                    </a>
                                  ) : values?.id?.includes("LINCQ") ||
                                    values?.quoteID?.includes("CACQR") ? (
                                    <a
                                      href={`/employee/quotation/cac-invoiced-jobs/${values?.id
                                        }/${"quotation"}`}
                                    >
                                      {" "}
                                      {values?.id}
                                    </a>
                                  ) : values?.id?.includes("LINBQ") ||
                                    values?.quoteID?.includes("BACQR") ? (
                                    <a
                                      href={`/employee/quotation/bac-invoiced-jobs/${values?.id
                                        }/${"quotation"}`}
                                    >
                                      {" "}
                                      {values?.id}
                                    </a>
                                  ) : (
                                    "No results found"
                                  )}{" "}
                                </>
                              ) : // search completed assignment cac, bac and inc interpreting and translation
                                values?.invoice_payment_status ? (
                                  <>
                                    {values?.id?.includes("CACIA") ? ( //cac interpreting assignment
                                      <a
                                        href={`/employee/cac-interpreting/completed-assignments/${values?.id
                                          }/${"interpreting"}`}
                                      >
                                        {" "}
                                        {values?.id}
                                      </a>
                                    ) : values?.id?.includes("CACTA") ? ( //cac translation assignment
                                      <a
                                        href={`/employee/cac-translation/completed-assignments/${values?.id
                                          }/${"translation"}`}
                                      >
                                        {" "}
                                        {values?.id}
                                      </a>
                                    ) : values?.id?.includes("BACIA") || //bac interpreting assignment
                                      values?.id?.includes("BACTIA") ? ( //bac telephonic interpreting assignment
                                      <a
                                        href={`/employee/bac-interpreting/completed-assignments/${values?.id
                                          }/${"interpreting"}`}
                                      >
                                        {" "}
                                        {values?.id}
                                      </a>
                                    ) : values?.id?.includes("BACTA") || //bac translation assignment
                                      values?.id?.includes("BACTTA") ? ( //bac telephonic translation assignment
                                      <a
                                        href={`/employee/bac-translation/completed-assignments/${values?.id
                                          }/${"translation"}`}
                                      >
                                        {" "}
                                        {values?.id}
                                      </a>
                                    ) : values?.id?.includes("IIA") || //inhouse interpreting assignment
                                      values?.id?.includes("PIIA") || //sppo inhouse interpreting assignment
                                      values?.id?.includes("ITIA") || //inhouse telephonic interpreting assignment
                                      values?.id?.includes("PITIA") ? ( //sppo inhouse telephonic interpreting assignment
                                      <a
                                        href={`/employee/inhouse-interpreting/completed-assignments/${values?.id
                                          }/${"interpreting"}`}
                                      >
                                        {" "}
                                        {values?.id}
                                      </a>
                                    ) : values?.id?.includes("ITA") || //inhouse translation assignment
                                      values?.id?.includes("PITA") || //sppo inhouse translation assignment
                                      values?.id?.includes("ITTA") || //inhouse telephonic translation assignment
                                      values?.id?.includes("PITTA") ? ( //sppo inhouse telephonic translation assignment
                                      <a
                                        href={`/employee/inhouse-translation/completed-assignments/${values?.id
                                          }/${"translation"}`}
                                      >
                                        {" "}
                                        {values?.id}
                                      </a>
                                    ) : values?.id?.includes("PIA") || //private interpreting assignment
                                      values?.id?.includes("PTIA") ? ( //private telephonic interpreting assignment
                                      <a
                                        href={`/employee/private-interpreting/completed-assignments/${values?.id
                                          }/${"interpreting"}`}
                                      >
                                        {" "}
                                        {values?.id}
                                      </a>
                                    ) : values?.id?.includes("PTA") || //private translation assignment
                                      values?.id?.includes("PTTA") ? ( //private telephonic translation assignment
                                      <a
                                        href={`/employee/private-translation/completed-assignments/${values?.id
                                          }/${"translation"}`}
                                      >
                                        {" "}
                                        {values?.id}
                                      </a>
                                    ) : values?.id?.includes("LINCQ") ||
                                      values?.quoteID?.includes("CACQR") ? (
                                      <a
                                        href={`/employee/quotation/cac-completed-jobs/${values?.id
                                          }/${"quotation"}`}
                                      >
                                        {" "}
                                        {values?.id}
                                      </a>
                                    ) : values?.id?.includes("LINBQ") ||
                                      values?.quoteID?.includes("BACQR") ? (
                                      <a
                                        href={`/employee/quotation/bac-completed-jobs/${values?.id
                                          }/${"quotation"}`}
                                      >
                                        {" "}
                                        {values?.id}
                                      </a>
                                    ) : (
                                      "No results found "
                                    )}{" "}
                                  </>
                                ) : // search unpaid job advert
                                  !values?.payment_status &&
                                    values?.bank_pay &&
                                    values?.active ? (
                                    <>
                                      {values?.id?.includes("PUBJA") ? (
                                        <a
                                          href={`/employee/unpaid-job-advert/${values?.id
                                            }/${"jobadvert"}`}
                                        >
                                          {" "}
                                          {values?.id}
                                        </a>
                                      ) : (
                                        "No results found 7"
                                      )}{" "}
                                    </>
                                  ) : // search paid job advert
                                    values?.payment_status &&
                                      !values?.access_permission &&
                                      !values?.reject ? (
                                      <>
                                        {values?.id?.includes("PUBJA") ? (
                                          <a
                                            href={`/employee/paid-job-advert/${values?.id
                                              }/${"jobadvert"}`}
                                          >
                                            {" "}
                                            {values?.id}
                                          </a>
                                        ) : (
                                          "No results found"
                                        )}{" "}
                                      </>
                                    ) : // search accepted job advert
                                      values?.payment_status &&
                                        values?.access_permission &&
                                        !values?.reject ? (
                                        <>
                                          {values?.id?.includes("PUBJA") ? (
                                            <a
                                              href={`/employee/accepted-jobadvert/${values?.id
                                                }/${"jobadvert"}`}
                                            >
                                              {" "}
                                              {values?.id}
                                            </a>
                                          ) : (
                                            "No results found"
                                          )}{" "}
                                        </>
                                      ) : // search rejected job advert
                                        values?.payment_status &&
                                          !values?.access_permission &&
                                          values?.reject ? (
                                          <>
                                            {values?.id?.includes("PUBJA") ? (
                                              <a
                                                href={`/employee/declined-jobadvert/${values?.id
                                                  }/${"jobadvert"}`}
                                              >
                                                {" "}
                                                {values?.id}
                                              </a>
                                            ) : (
                                              "No results found"
                                            )}{" "}
                                          </>
                                        ) : (
                                          "No results found"
                                        )}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default AssignmentPage;
