import React, { useState, useEffect, useRef } from "react";

// Custom Components
// Custom Styles
import { Button, Header } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { changePreRegInput } from "redux/actions/preregAction";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "70vh",
    overflowY: "auto",
  },
});

const CommunicationMenu = ({ id, token, tickets }) => {
  const classes = useStyles();
  // const [tickets, setTickets] = useState({ reload: false });
  const [conversationDetails, setConversation] = useState({ reload: false });
  const fileInput = useRef(null);
  const [attachment, setAttachment] = useState();
  const { data } = useSelector((state) => state.preregReducer);
  const dispatch = useDispatch();

  // const { conversation } = data;

  const [converse, setConverse] = useState({
    conversation: "",
  });

  const { conversation } = converse;

  const handleChange = (e) => {
    // const { name, value, checked } = e.target;
    setConverse({ ...converse, [e.target.name]: e.target.value });
    const inputData = {
      name: e.target.name,
      value: e.target.value,
    };
    dispatch(changePreRegInput(inputData));
  };

  const ConversationDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/conversation-list/ticketID/${id}`
      );

      setConversation(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    ConversationDetails();
  }, []);

  const handleAttachment = (e) => {
    setAttachment(e.target.files[0]);
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleSubmit = async (e) => {
    let fd = new FormData();

    fd.append("attachment", attachment);
    fd.append("conversation", conversation);

    if (attachment) {
      fd.append("attachTrue", true);
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/start-conversation-CAC/ticketID/${id}`,
        fd,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `Sent!!!`,
        icon: "success",
        buttons: false,
      });
      ConversationDetails();
      setAttachment();
      setConverse({ ...converse, conversation: "" });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `Something wrong!`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const closeTicket = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/close-ticket/ticketID/${id}`
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/cash-client/view-tickets";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error.response?.data}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleClose = async (value) => {
    swal("Are you sure to close this ticket?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          closeTicket(value);

        default:
          break;
      }
    });
  };

  return (
    <>
      <List className={classes.messageArea}>
        <ListItem key="3">
          {tickets?.status ? (
            <Grid item xs={12}>
              <Button
                color="purple"
                icon="close"
                floated="right"
                content="Close this support ticket"
                onClick={handleClose}
              />
            </Grid>
          ) : null}
          <Grid container>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                primary={tickets?.ticket_no}
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                primary={
                  tickets?.additional_ccEmail == "undefined"
                    ? ""
                    : tickets?.additional_ccEmail == "-"
                    ? ""
                    : tickets?.additional_ccEmail
                }
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                primary={
                  tickets?.additional_contact_number == "undefined"
                    ? ""
                    : tickets?.additional_contact_number == "-"
                    ? ""
                    : tickets?.additional_contact_number
                }
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                primary={tickets?.subject}
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                primary={tickets?.details}
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                secondary={moment(tickets?.createdAt).format(
                  "DD/MMM/YYYY : HH:mm"
                )}
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText style={{ color: "purple" }} align="right">
                <a href={`/cash-client/view-ticket-attachment/${tickets?._id}`}>
                  View Attach
                </a>
              </ListItemText>
            </Grid>
          </Grid>
        </ListItem>
        <Divider />

        {conversationDetails.length > 0 ? (
          <>
            {conversationDetails?.map((list, i) => (
              <>
                <ListItem key="3">
                  <Grid container>
                    <Grid item xs={12}>
                      {list?.postedByIDName.includes("ADM") ? (
                        <>
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText
                              style={{ color: "purple" }}
                              align="right"
                            >
                              <a
                                href={`/cash-client/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}

                      {list?.postedByIDName.includes("EMP") ? (
                        <>
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText
                              style={{ color: "purple" }}
                              align="right"
                            >
                              <a
                                href={`/cash-client/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}

                      {list?.postedByIDName.includes("SPPO") ? (
                        <>
                          <ListItemText
                            align="center"
                            style={{ color: "green" }}
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            align="center"
                            style={{ color: "green" }}
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText
                              style={{ color: "green" }}
                              align="center"
                            >
                              <a
                                href={`/cash-client/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            align="center"
                            style={{ color: "green" }}
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}

                      {list?.postedByIDName.includes("CAC") ? (
                        <>
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText style={{ color: "red" }} align="left">
                              <a
                                href={`/cash-client/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}

                      {list?.postedByIDName.includes("BAC") ? (
                        <>
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText style={{ color: "red" }} align="left">
                              <a
                                href={`/cash-client/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}

                      {list?.postedByIDName.includes("LIN") ? (
                        <>
                          <ListItemText
                            style={{ color: "blue" }}
                            align="center"
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            style={{ color: "blue" }}
                            align="center"
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText
                              style={{ color: "blue" }}
                              align="center"
                            >
                              <a
                                href={`/cash-client/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            style={{ color: "blue" }}
                            align="center"
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </>
            ))}
          </>
        ) : null}
      </List>
      <Divider />

      {tickets?.status ? (
        <Grid container style={{ padding: "20px" }}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                display: "flex",
              }}
            >
              <TextField
                onChange={handleChange}
                name="conversation"
                value={converse.conversation}
                id="outlined-basic-email"
                label="Reply to this ticket"
                style={{ marginRight: "5px", width: "85%" }}
              />

              <input
                name="identification_doc"
                onChange={handleAttachment}
                type="file"
                style={{ display: "none" }}
                accept=".doc,.pdf,.jpg,.png,.gif,.zip,.rar,.txt,.log,.xml"
                ref={fileInput}
              ></input>
              <div>
                <Fab
                  style={{ marginRight: "10px" }}
                  color="secondary"
                  onClick={handleClick}
                >
                  {attachment ? (
                    <p style={{ fontSize: "7pt" }}>Uploaded</p>
                  ) : (
                    <NoteAddIcon />
                  )}{" "}
                </Fab>{" "}
              </div>
              <Fab
                color="primary"
                aria-label="add"
                onClick={handleSubmit}
                disabled={!converse.conversation}
              >
                <SendIcon />
              </Fab>
            </div>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default CommunicationMenu;
