import React, { Fragment } from "react";

import AccountClientLogin from "components/Auth/Login/AccountClientLogin";

const BACLogin = () => {
  return (
    <Fragment>
      <AccountClientLogin />
    </Fragment>
  );
};

export default BACLogin;

