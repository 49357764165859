import React, {  } from "react";
import Sppo from "components/RouteProtection/Sppo/Sppo";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SppoLayout from "layout/SppoLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import QuoteOptions from "components/Sppo/Quotation/QuoteOptions";


const DirectQuoteInvoice = () => {
  const classes = useDashboardStyles();

  return (
    <SppoLayout>
      <Sppo>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <p className={classes.cardTitleWhite}>Direct Quote & Invoice</p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
            </Card>

            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <QuoteOptions />
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      </Sppo>
    </SppoLayout>
  );
};

export default DirectQuoteInvoice;
