// NPM packages
import { combineReducers } from "redux";

// Custom modules
import cashBookReducer from "./linguist/cashBookReducer";
import userReducer from "./userReducer";
import erReducer from "./erReducer";
import crReducer from "./crReducer";
import bookingReducer from "./bookingReducer";
import accountbookingReducer from "./accountClientBookingreducer";
import transReducer from "./transReducer";
import lcReducer from "./lcReducer";
import quoterqstReducer from "./quoterqstReducer";
import caoReducer from "./caoReducer";
import irReducer from "./irReducer";
import advertReducer from "./advertReducer";
import controllerReducer from "./admin/controller";
import authReducer from "./authReducer";
import spporReducer from "./spporReducer";
import ibtReducer from "./ibtReducer";
import ottReducer from "./ottReducer";
import qrtReducer from "./qrtReducer";
import qutationReducer from "./admin/qutation";
import invoiceReducer from "./admin/invoiceReducer";
import linguistInvoice from "./admin/linguistInvoice";
import adminregReducer from "./adminregReducer";
import invoiceReducers from "./admin/invoice";
import getDataReducer from "./getData";
import preregReducer from "./preregReducer";
import profileReducer from "./profileReducer";
import contactCardReducer from "./contactCardReducer";
import icrReducer from "./icrReducer";
import ACquoterqstReducer from "./ACquotsreducer";
import transReducerAC from "./transReducerAC";
import unverifiedLinguistReducer from "./unverifiedLinguistReducer";
import notificationReducer from "redux/reducers/notifications/notificationReducer";
import datetimeReducer from "./datetimeReducer";
import ammendreducer from "./aammendreducer";
import updateProfileReducer from "./linguist/updateProfileReducer";
import timesheetReducer from './timesheetReducer'
import billsReducer from './billsReducer'
import assignmentReducer from './assignmentReducer'
import expenseReducer from './expenseReducer'
import unconfirmQuotesReducer from './unconfirmQuotesReducer'
import linguistclient from './linguistclient'
import updatePPLReducer from './updatePPLReducer'


export default combineReducers({
  linguistInvoice,
  cashBookReducer,
  userReducer,
  erReducer,
  crReducer,
  bookingReducer,
  accountbookingReducer,
  transReducer,
  lcReducer,
  advertReducer,
  quoterqstReducer,
  caoReducer,
  irReducer,
  authReducer,
  adminControlData: controllerReducer,
  spporReducer,
  ibtReducer,
  ottReducer,
  qrtReducer,
  qutationReducer,
  invoiceReducer,
  invoiceReducers,
  adminregReducer,
  getDataReducer,
  preregReducer,
  profileReducer,
  contactCardReducer,
  icrReducer,
  ACquoterqstReducer,
  transReducerAC,
  unverifiedLinguistReducer,
  notificationReducer,
  datetimeReducer,
  ammendreducer,
  updateProfileReducer,
  timesheetReducer,
  billsReducer,
  assignmentReducer,
  expenseReducer,
  unconfirmQuotesReducer,
  linguistclient,
  updatePPLReducer
});
