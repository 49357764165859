import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Moment from "moment";
import Action from './Action'
import Axios from "utils/axios";
import Cookies from 'js-cookie'
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const PersonalDetails = ({ id }) => {

  const token = Cookies.get('BACtoken')
  const classes = useDashboardStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({ reload: false });


  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    try {
      const response = await Axios.get(`/api/accountclient/list-colleague-note`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

      setValues(response?.data)
    } catch (error) {
      console.log(error.response?.data)
    }
  };

  return (

    <GridItem xs={12} sm={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" id="export">
                <TableHead>
                  <TableRow>
                    <TableCell>Created At (London Time)</TableCell>
                    <TableCell>Created by</TableCell>
                    <TableCell>Note / Do List</TableCell>
                    <TableCell>Deadline (As selected)</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {values.length > 0 ?
                  <TableBody>
                    {values
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((list) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={list._id}
                          style={
                            list?.priority == "Done"
                              ? { backgroundColor: "lightgreen" }
                              : list?.mainPriority == "Note"
                                ? { backgroundColor: "white" }
                                : list?.mainPriority == "Low"
                                  ? { backgroundColor: "lightyellow" }
                                  : list?.mainPriority == "Medium"
                                    ? { backgroundColor: "#FFBF00" }
                                    : list?.mainPriority == "High"
                                      ? { backgroundColor: "red" }
                                      : null

                          }
                        >
                          <TableCell>
                            {Moment(list.createdAt).tz("Europe/London").format(
                              "DD MMM YYYY - HH:mm:ss"
                            )}</TableCell>
                          <TableCell>{list?.clientName}, {list?.clientID}</TableCell>
                          <TableCell>{list?.admin_note}<br />
                            <TableCell>
                              {list?.comment?.filter((dt) => dt != "")
                                .map((st) => (
                                  <TableRow> {st} <br /><br /></TableRow>
                                ))}
                            </TableCell>
                          </TableCell>
                          <TableCell>
                            {" "}
                            {list.deadline === "nullTnull" ? "N/A"
                              : Moment(list.deadline).format(
                                "DD MMM YYYY - HH:mm:ss"
                              )
                            }
                          </TableCell>
                          <TableCell>
                            {list?.mainPriority}<br />
                            <>
                              <TableCell>
                                {list?.priorityMarkAs?.filter((dt) => dt != "")
                                  .map((st) => (
                                    <TableRow
                                      style={st == "Note"
                                        ? { backgroundColor: "white" }
                                        : st == "Low"
                                          ? { backgroundColor: "lightyellow" }
                                          : st == "Medium"
                                            ? { backgroundColor: "#FFBF00" }
                                            : st == "High"
                                              ? { backgroundColor: "red" }
                                              : { backgroundColor: "lightgreen" }}>
                                      {st} <br />
                                    </TableRow>
                                  ))}
                              </TableCell>
                              <TableCell>
                                {list?.doneByBAC?.filter((dt) => dt != "")
                                  .map((st) => (
                                    <TableRow> {st?.FirstName}, {st?.id} <br /></TableRow>
                                  ))}
                              </TableCell>
                              <TableCell>
                                {list?.doneDate?.filter((dt) => dt != "")
                                  .map((st) => (
                                    <TableRow> {st} <br /></TableRow>
                                  ))}
                              </TableCell>
                            </>
                          </TableCell>
                          <TableCell>
                            <Action
                              agent={list?.agent_client}
                              postedClient={list?.postedByClient}
                              colleague_note={list?.bac_colleague_note}
                              id={list?._id}
                              load={loadList}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  : "No data found"}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={values.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </CardBody>
      </Card>
    </GridItem >
  )
}

export default PersonalDetails
