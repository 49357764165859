import SearchInput from "./SearchInput";
import { useState } from "react";
import { Button } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  div: {
    marginTop: "24px",
    width: "50%",
    ["@media (max-width:768px)"]: {
      width: "100%",
    },
  },
  root: {
    ["@media (max-width:768px)"]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  button: {
    ["@media (max-width:768px)"]: {
      width: "100%",
      marginTop: 5,
    },
  },
}));
const SearchClient = ({
  setShow,
  setSearchText,
  SearchText,
  handleSearch,
  load,
}) => {
  const classes = useStyles();

  const showClients = (e) => {
    setShow(true);
  };
  return (
    <div className={classes.div}>
      <SearchInput
        load={load}
        setSearchText={setSearchText}
        SearchText={SearchText}
        handleSearch={handleSearch}
      />
      <div className={classes.root}>
        <Button className={classes.button} color="purple" onClick={showClients}>
          View all active Employee
        </Button>
      </div>
    </div>
  );
};

export default SearchClient;
