import { IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";

const WorkExperience = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("LINtoken");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updateProfileReducer);

  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleFiles = async (e) => {
    dispatch(
      changeLinguistProfileInput({
        name: e.target.name,
        value: e.target.files[0],
      })
    );
  }

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("leaving_date", data.leaving_date);
    fd.append("organization_name", data.organization_name || "-");
    fd.append("organization_address", data.organization_address || "-");
    fd.append("start_date", data.start_date);
    fd.append("experience_gained", data.experience_gained || "-");
    fd.append("reference_name", data.reference_name || "-");
    fd.append("reseasons_for_leaving", data.reseasons_for_leaving || "-");

    if (data.reference_letter?.size) {
      fd.append('reference_letter', data.reference_letter)
    }

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/profile/update`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="organization_name"
            onChange={handleChange}
            label="Name of organization / event"
            edit={edit.value}
            value={data.organization_name}
          />
          <CustomTextInput
            name="organization_address"
            onChange={handleChange}
            label=" Address contact details"
            edit={edit.value}
            value={data.organization_address}
          />

          {edit.value ? (
            <CustomTextInput
              name="start_date"
              onChange={handleChange}
              label=" Start date"
              dlabel="Start date"
              dvalue={data.start_date}
              date={true}
            />
          ) : (
            <CustomTextInput
              name="start_date"
              label="Start date"
              value={moment(data.start_date).format("DD/MMM/YYYY")}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              name="leaving_date"
              onChange={handleChange}
              label="Leaving date"
              dlabel="Leaving date"
              dvalue={data.leaving_date}
              date={true}
              min={data.start_date}
            />
          ) : (
            <CustomTextInput
              name="leaving_date"
              label="Leaving date"
              value={moment(data.leaving_date).format("DD/MMM/YYYY")}
            />
          )}
          <CustomTextInput
            name="experience_gained"
            onChange={handleChange}
            label="List of duties experience gained"
            edit={edit.value}
            value={data.experience_gained}
          />
          <CustomTextInput
            name="reseasons_for_leaving"
            onChange={handleChange}
            label="Reasons for leaving"
            edit={edit.value}
            value={data.reseasons_for_leaving}
          />
          <CustomTextInput
            name="reference_name"
            onChange={handleChange}
            label="Provide name of your supervisor and contact details below"
            edit={edit.value}
            value={data.reference_name}
          />

          <a href={`/linguist/update-linguist-documents`}>
            Upload Documents
          </a>

        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default WorkExperience;
