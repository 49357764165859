import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media(max-width:768px)"]: {
      width: "100%",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearchInput = ({ values, SearchText, setSearchText, setFiltered }) => {
  const classes = useStyles();
  const handleInput = (value) => {
    let filterText = [];
    setSearchText(value);
    if (value === "" || value === undefined) {
      filterText = [];
      setFiltered([]);
    } else {
      values?.map((item) => {
        var regexp = new RegExp(value, "i");

        if (item.match(regexp)) {
          filterText.push(item);
        }
      });
      setFiltered(filterText);
    }
  };

  return (
    <div>
      <Paper
        component="form"
        className={classes.root}
        onClick={(e) => e.preventDefault()}
      >
        <InputBase
          className={classes.input}
          placeholder="Filter FAQs..."
          onChange={(e) => {
            handleInput(e.target.value);
          }}
          inputProps={{ "aria-label": "search google maps" }}
          value={SearchText}
          // onChange={(e) => setText(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </div>
  );
};

export default SearchInput;
