const { INHOUSE_CLIENT, PRIVATE_CLIENT } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    linguistINC: [],
    linguistPRC: false
};


const linguistclient = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case INHOUSE_CLIENT:
            return {
                ...state,
                linguistINC: payload,
            };
        
        case PRIVATE_CLIENT: 
            return {
                ...state,
                linguistPRC: payload
            }
        
        default:
            return state
    }
}

export default linguistclient
