import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody"; 
import LinguistLayout from "layout/LinguistLayout";
import { useParams } from 'react-router-dom'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import InhouseClient from 'components/Linguist/ManageInhouse/Profile/ViewProfile'

const ViewInhouseClient = () => {
  const { id } = useParams()
  const classes = useDashboardStyles();
  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}> In-house Client Profile</p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>

              <CardBody>
                <InhouseClient id={id} />
              </CardBody>

            </Card>


          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default ViewInhouseClient;
