import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { providedQuotes } from "redux/actions/unconfirmQuotesAction";
import Axios from "axios";
import swal from "sweetalert";

const Action = ({ id, ticketDetails }) => {
  console.log(id);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(providedQuotes(id));
    ticketDetails(id);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Details
      </Button>
      {/* <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => dispatch(providedQuotes(id))}>View Details</MenuItem>
      </Menu> */}
    </div>
  );
};

export default Action;
