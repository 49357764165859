import React from "react";

// Material Components
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Divider from "@material-ui/core/Divider";

// Custom Components
import CustomDropdown from "components/Common/CustomDropdown";

// Others
import useDropdownStyles from "assets/jss/components/dropDownStyles";

const Settings = () => {
  const classes = useDropdownStyles();
  return (
    <CustomDropdown label="management-settings">
      <MenuList role="menu">
        <MenuItem className={classes.dropdownItem}>Panel Settings</MenuItem>
        <MenuItem className={classes.dropdownItem}>Set Privilege</MenuItem>
        <MenuItem className={classes.dropdownItem}>Web Settings</MenuItem>
        <MenuItem className={classes.dropdownItem}>Others</MenuItem>
        <Divider />
        <MenuItem className={classes.dropdownItem}>Email Settings</MenuItem>
      </MenuList>
    </CustomDropdown>
  );
};

export default Settings;
