import { v4 as uuidv4 } from "uuid";
import InhouseClientFormWeb from "components/Auth/InhouseClientFormWeb/InhouseClientFormWeb";

import InhouseInterpretingWeb from "components/Auth/InhouseInterpretingWeb/InhouseInterpretingWeb";
import SPPOInhouseInterpretingWeb from "components/Sppo/ManageInhouse/Form/InhouseInterpretingWeb/InhouseInterpretingWeb";

import InhouseTransWeb from "components/Auth/InhouseOrderTransWeb/InhouseOrderTransWeb";
import SPPOInhouseTransWeb from "components/Sppo/ManageInhouse/Form/InhouseOrderTransWeb/InhouseOrderTransWeb";

import InhouseClientEmailActivation from "pages/linguist/InhouseClientEmailActivation";
import SPPOInhouseClientEmailActivation from "pages/sppo-page/MyClients/INCEmailActivation";
import EmailActivationPrivate from "pages/linguist/PrivateClient/EmailActivationPrivate";
import PrivateInterpretingWeb from "components/Linguist/ManagePrivateClient/Form/PrivateInterpretingWeb/PrivateInterpretingWeb";
import PrivateOrderTransWeb from "components/Linguist/ManagePrivateClient/Form/PrivateOrderTransWeb/PrivateOrderTransWeb";
import InhouseSummary from "components/Auth/InhouseInterpretingWeb/InhouseSummary";
import InhousePayment from "components/Auth/InhouseInterpretingWeb/InhousePayment";
import PrivateInterpretingSummary from "pages/landing-page/SummaryPage/PRCBookingSummary";
import InhouseTransWebsummary from "components/Auth/InhouseOrderTransWeb/InhouseOrderTransummary";
import InhouseClientOTpayment from "components/Auth/InhouseOrderTransWeb/inhousepayment";
import PrivateInterpretingPayment from "components/Linguist/ManagePrivateClient/Form/PrivateInterpretingWeb/success.jsx";
import privatetranslationsummary from "pages/landing-page/SummaryPage/PRCTransSummary";
import privatetranslationpayment from "components/Linguist/ManagePrivateClient/Form/PrivateOrderTransWeb/payment";
import InhouseIndipendentSummary from "components/inhouseindependent";
import PrivateClientRegSend from "components/Linguist/ManagePrivateClient/Form/PrivateClientRegSend/PrivateClientRegSend";

const INHOUSE_FORMS = [
  {
    id: uuidv4(),
    path: "inhouse-client-form/:linID",
    component: InhouseClientFormWeb,
  },

  {
    id: uuidv4(),
    path: "inhouse-client-form/summary/payment/:linID/:clientID/:id",
    component: InhouseIndipendentSummary,
  },

  {
    id: uuidv4(),
    path: "inhouse/auth/account/activate/:token",
    component: InhouseClientEmailActivation,
  },

  {
    id: uuidv4(),
    path: "sppo/inhouse/auth/account/activate/:token",
    component: SPPOInhouseClientEmailActivation,
  },

  {
    id: uuidv4(),
    type: "form",
    text: "Inhouse Interpreter booking Form",
    path: "inhouse-interpreter-booking/:linID/:clientID/:clientPC/:clientLN/:clientobj",
    component: InhouseInterpretingWeb,
  },
  {
    id: uuidv4(),
    path: "inhouse-translation-order/:linID/:clientID/:clientPC/:clientLN/:clientobj",
    component: InhouseTransWeb,
  },

  {
    id: uuidv4(),
    type: "form",
    text: "Inhouse Interpreter booking Form",
    path: "inhouse-interpreter-booking/:sppoobj/:sppoID/:clientID/:clientPC/:clientLN/:clientobj",
    component: SPPOInhouseInterpretingWeb,
  },
  {
    id: uuidv4(),
    path: "inhouse-translation-order/:sppoobj/:sppoID/:clientID/:clientPC/:clientLN/:clientobj",
    component: SPPOInhouseTransWeb,
  },

  {
    id: uuidv4(),
    path: "inhouse-translation-order/inhouse/summary",
    component: InhouseTransWebsummary,
  },
  {
    id: uuidv4(),
    path: "inhouse-interpreter-booking/Ordertranslation/:paymentIntent/payment",
    component: InhouseClientOTpayment,
  },
  {
    id: uuidv4(),
    path: "private-client-form/:linID",
    component: PrivateClientRegSend,
  },
  {
    id: uuidv4(),
    path: "private/auth/account/activate/:token",
    component: EmailActivationPrivate,
  },
  {
    id: uuidv4(),
    path: "private-interpreter-booking/:linID/:clientID/:clientFname/:clientLname",
    component: PrivateInterpretingWeb,
  },
  {
    id: uuidv4(),
    path: "private-interpreter-booking/:linID/:clientID/:clientFname/:clientLname/:frontPage",
    component: PrivateInterpretingWeb,
  },
  {
    id: uuidv4(),
    path: "private-interpreter-booking/client/summary",
    component: PrivateInterpretingSummary,
  },
  {
    id: uuidv4(),
    path: "private-interpreter-booking/:paymentIntent/payment",
    component: PrivateInterpretingPayment,
  },
  {
    id: uuidv4(),
    path: "private-translation-order/:linID/:clientID/:clientpin/:clientLname",
    component: PrivateOrderTransWeb,
  },
  {
    id: uuidv4(),
    path: "private-translation-order/:linID/:clientID/:clientpin/:clientLname/:frontPage",
    component: PrivateOrderTransWeb,
  },
  {
    id: uuidv4(),
    path: "private-translation-order/cli_ent/summary",
    component: privatetranslationsummary,
  },
  {
    id: uuidv4(),
    path: "private-translation-order/client/summary/payment/:paymentIntent/succeass",
    component: privatetranslationpayment,
  },
  {
    id: uuidv4(),
    path: "inhouse-interpreter-booking/client/summary",
    component: InhouseSummary,
  },
  {
    id: uuidv4(),
    path: "inhouse-interpreter-booking/:status/payment",
    component: InhousePayment,
  },
];

export default INHOUSE_FORMS;
