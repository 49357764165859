import React, { useState, useEffect } from "react";
import { Table, Header, Button, Label } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useDispatch, useSelector } from "react-redux";
import EditableText from "./EditableText";
import useRateCardStyles from "./styles";
import { getCurrencyDetails } from "redux/actions/admin/controller";
import { useMediaQuery } from "@material-ui/core";

const CurrencyList = ({ readOnly }) => {
  const matches = useMediaQuery("(max-width:425px)");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { currencyDetails } = useSelector((state) => state.adminControlData);

  useEffect(() => {
    dispatch(getCurrencyDetails());
  }, []);

  const classes = useRateCardStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        {matches ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div>
              <Header style={{ marginRight: "5px" }} as="h3">
                Base currency: United Kingdom –{" "}
              </Header>

              <Header style={{ marginRight: "10px" }} color="pink" as="h3">
                {""}
                GPB £000000001.0000
              </Header>
            </div>
            <Button color="purple" onClick={() => setShow(!show)}>
              View all currencies
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "start",
              }}
            >
              <Header style={{ marginRight: "5px" }} as="h3">
                Base currency: United Kingdom –{" "}
              </Header>

              <Header style={{ marginRight: "10px" }} color="pink" as="h3">
                {""}
                GPB £000000001.0000
              </Header>
            </div>
            <Button color="purple" onClick={() => setShow(!show)}>
              View all currencies
            </Button>
          </div>
        )}

        {show && (
          <>
            {" "}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={classes.root}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Table color="purple">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Country</Table.HeaderCell>
                          <Table.HeaderCell>Currency</Table.HeaderCell>
                          <Table.HeaderCell>Code</Table.HeaderCell>
                          <Table.HeaderCell>Rate/s</Table.HeaderCell>
                          {/* <Table.HeaderCell>Active/In-active</Table.HeaderCell> */}
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.GBP ? (
                              <Label>{currencyDetails.country.GBP}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.GBP}
                                readOnly={readOnly}
                                name="country.GBP"
                                id="outlined-basic"
                                label="Country"
                                variant="outlined"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.GBP ? (
                              <Label>{currencyDetails.currency.GBP}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.GBP}
                                readOnly={readOnly}
                                name="currency.GBP"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>GBP</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.GBP}
                              readOnly={readOnly}
                              name="rates.GBP"
                            />{" "}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.EUR ? (
                              <Label>{currencyDetails.country.EUR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.EUR}
                                readOnly={readOnly}
                                name="country.EUR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.EUR ? (
                              <Label>{currencyDetails.currency.EUR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.EUR}
                                readOnly={readOnly}
                                name="currency.EUR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>EUR</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.EUR}
                              readOnly={readOnly}
                              name="rates.EUR"
                            />{" "}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.AUD ? (
                              <Label>{currencyDetails.country.AUD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.AUD}
                                readOnly={readOnly}
                                name="country.AUD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.AUD ? (
                              <Label>{currencyDetails.currency.AUD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.AUD}
                                readOnly={readOnly}
                                name="currency.AUD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>AUD</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.AUD}
                              readOnly={readOnly}
                              name="rates.AUD"
                            />{" "}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.USD ? (
                              <Label>{currencyDetails.country.USD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.USD}
                                readOnly={readOnly}
                                name="country.USD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.USD ? (
                              <Label>{currencyDetails.currency.USD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.USD}
                                readOnly={readOnly}
                                name="currency.USD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>USD</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.USD}
                              readOnly={readOnly}
                              name="rates.USD"
                            />{" "}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.CAD ? (
                              <Label>{currencyDetails.country.CAD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.CAD}
                                readOnly={readOnly}
                                name="country.CAD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.CAD ? (
                              <Label>{currencyDetails.currency.CAD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.CAD}
                                readOnly={readOnly}
                                name="currency.CAD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>CAD</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.CAD}
                              readOnly={readOnly}
                              name="rates.CAD"
                            />{" "}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.TRY ? (
                              <Label>{currencyDetails.country.TRY}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.TRY}
                                readOnly={readOnly}
                                name="country.TRY"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.TRY ? (
                              <Label>{currencyDetails.currency.TRY}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.TRY}
                                readOnly={readOnly}
                                name="currency.TRY"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>TRY</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.TRY}
                              readOnly={readOnly}
                              name="rates.TRY"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.SGD ? (
                              <Label>{currencyDetails.country.SGD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.SGD}
                                readOnly={readOnly}
                                name="country.SGD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.SGD ? (
                              <Label>{currencyDetails.currency.SGD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.SGD}
                                readOnly={readOnly}
                                name="currency.SGD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>SGD</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.SGD}
                              readOnly={readOnly}
                              name="rates.SGD"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.HUF ? (
                              <Label>{currencyDetails.country.HUF}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.HUF}
                                readOnly={readOnly}
                                name="country.HUF"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.HUF ? (
                              <Label>{currencyDetails.currency.HUF}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.HUF}
                                readOnly={readOnly}
                                name="currency.HUF"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>HUF</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.HUF}
                              readOnly={readOnly}
                              name="rates.HUF"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.NZD ? (
                              <Label>{currencyDetails.country.NZD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.NZD}
                                readOnly={readOnly}
                                name="country.NZD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.NZD ? (
                              <Label>{currencyDetails.currency.NZD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.NZD}
                                readOnly={readOnly}
                                name="currency.NZD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>NZD</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.NZD}
                              readOnly={readOnly}
                              name="rates.NZD"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.RON ? (
                              <Label>{currencyDetails.country.RON}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.RON}
                                readOnly={readOnly}
                                name="country.RON"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.RON ? (
                              <Label>{currencyDetails.currency.RON}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.RON}
                                readOnly={readOnly}
                                name="currency.RON"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>RON</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.RON}
                              readOnly={readOnly}
                              name="rates.RON"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.BRL ? (
                              <Label>{currencyDetails.country.BRL}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.BRL}
                                readOnly={readOnly}
                                name="country.BRL"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.BRL ? (
                              <Label>{currencyDetails.currency.BRL}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.BRL}
                                readOnly={readOnly}
                                name="currency.BRL"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>BRL</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.BRL}
                              readOnly={readOnly}
                              name="rates.BRL"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.ARS ? (
                              <Label>{currencyDetails.country.ARS}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.ARS}
                                readOnly={readOnly}
                                name="country.ARS"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.ARS ? (
                              <Label>{currencyDetails.currency.ARS}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.ARS}
                                readOnly={readOnly}
                                name="currency.ARS"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>ARS</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.ARS}
                              readOnly={readOnly}
                              name="rates.ARS"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.CNY ? (
                              <Label>{currencyDetails.country.CNY}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.CNY}
                                readOnly={readOnly}
                                name="country.CNY"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.CNY ? (
                              <Label>{currencyDetails.currency.CNY}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.CNY}
                                readOnly={readOnly}
                                name="currency.CNY"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>CNY</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.CNY}
                              readOnly={readOnly}
                              name="rates.CNY"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.RUB ? (
                              <Label>{currencyDetails.country.RUB}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.RUB}
                                readOnly={readOnly}
                                name="country.RUB"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.RUB ? (
                              <Label>{currencyDetails.currency.RUB}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.RUB}
                                readOnly={readOnly}
                                name="currency.RUB"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>RUB</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.RUB}
                              readOnly={readOnly}
                              name="rates.RUB"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.MYR ? (
                              <Label>{currencyDetails.country.MYR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.MYR}
                                readOnly={readOnly}
                                name="country.MYR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.MYR ? (
                              <Label>{currencyDetails.currency.MYR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.MYR}
                                readOnly={readOnly}
                                name="currency.MYR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>MYR</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.MYR}
                              readOnly={readOnly}
                              name="rates.MYR"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.JPY ? (
                              <Label>{currencyDetails.country.JPY}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.JPY}
                                readOnly={readOnly}
                                name="country.JPY"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.JPY ? (
                              <Label>{currencyDetails.currency.JPY}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.JPY}
                                readOnly={readOnly}
                                name="currency.JPY"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>JPY</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.JPY}
                              readOnly={readOnly}
                              name="rates.JPY"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.HKD ? (
                              <Label>{currencyDetails.country.HKD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.HKD}
                                readOnly={readOnly}
                                name="country.HKD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.HKD ? (
                              <Label>{currencyDetails.currency.HKD}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.HKD}
                                readOnly={readOnly}
                                name="currency.HKD"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>HKD</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.HKD}
                              readOnly={readOnly}
                              name="rates.HKD"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.INR ? (
                              <Label>{currencyDetails.country.INR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.INR}
                                readOnly={readOnly}
                                name="country.INR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.INR ? (
                              <Label>{currencyDetails.currency.INR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.INR}
                                readOnly={readOnly}
                                name="currency.INR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>INR</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.INR}
                              readOnly={readOnly}
                              name="rates.INR"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.IDR ? (
                              <Label>{currencyDetails.country.IDR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.IDR}
                                readOnly={readOnly}
                                name="country.IDR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.IDR ? (
                              <Label>{currencyDetails.currency.IDR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.IDR}
                                readOnly={readOnly}
                                name="currency.IDR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>IDR</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.IDR}
                              readOnly={readOnly}
                              name="rates.IDR"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.BDT ? (
                              <Label>{currencyDetails.country.BDT}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.BDT}
                                readOnly={readOnly}
                                name="country.BDT"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.BDT ? (
                              <Label>{currencyDetails.currency.BDT}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.BDT}
                                readOnly={readOnly}
                                name="currency.BDT"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>BDT</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.BDT}
                              readOnly={readOnly}
                              name="rates.BDT"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.SAR ? (
                              <Label>{currencyDetails.country.SAR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.SAR}
                                readOnly={readOnly}
                                name="country.SAR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.SAR ? (
                              <Label>{currencyDetails.currency.SAR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.SAR}
                                readOnly={readOnly}
                                name="currency.SAR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>SAR</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.SAR}
                              readOnly={readOnly}
                              name="rates.SAR"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.EGP ? (
                              <Label>{currencyDetails.country.EGP}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.EGP}
                                readOnly={readOnly}
                                name="country.EGP"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.EGP ? (
                              <Label>{currencyDetails.currency.EGP}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.EGP}
                                readOnly={readOnly}
                                name="currency.EGP"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>EGP</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.EGP}
                              readOnly={readOnly}
                              name="rates.EGP"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.ZAR ? (
                              <Label>{currencyDetails.country.ZAR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.ZAR}
                                readOnly={readOnly}
                                name="country.ZAR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.ZAR ? (
                              <Label>{currencyDetails.currency.ZAR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.ZAR}
                                readOnly={readOnly}
                                name="currency.ZAR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>ZAR</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.ZAR}
                              readOnly={readOnly}
                              name="rates.ZAR"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.UAE ? (
                              <Label>{currencyDetails.country.UAE}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.UAE}
                                readOnly={readOnly}
                                name="country.UAE"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.UAE ? (
                              <Label>{currencyDetails.currency.UAE}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.UAE}
                                readOnly={readOnly}
                                name="currency.UAE"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>UAE</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.UAE}
                              readOnly={readOnly}
                              name="rates.UAE"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.QAR ? (
                              <Label>{currencyDetails.country.QAR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.QAR}
                                readOnly={readOnly}
                                name="country.QAR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.QAR ? (
                              <Label>{currencyDetails.currency.QAR}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.QAR}
                                readOnly={readOnly}
                                name="currency.QAR"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>QAR</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.QAR}
                              readOnly={readOnly}
                              name="rates.QAR"
                            />
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>
                            {currencyDetails?.country?.NGN ? (
                              <Label>{currencyDetails.country.NGN}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.country.NGN}
                                readOnly={readOnly}
                                name="country.NGN"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {currencyDetails?.currency?.NGN ? (
                              <Label>{currencyDetails.currency.NGN}</Label>
                            ) : (
                              <EditableText
                                text={currencyDetails.currency.NGN}
                                readOnly={readOnly}
                                name="currency.NGN"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Label>NGN</Label>
                          </Table.Cell>
                          <Table.Cell>
                            <EditableText
                              text={currencyDetails.rates.NGN}
                              readOnly={readOnly}
                              name="rates.NGN"
                            />
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default CurrencyList;
