import React, { Fragment } from "react";

// Custom Components
import LinguistInitialRegistration from "components/Linguist/InitialRegistration/InitialRegistration";

const InitialRegistrationPage = () => {
  return (
    <Fragment>
      <LinguistInitialRegistration />
    </Fragment>
  );
};

export default InitialRegistrationPage;
