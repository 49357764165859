import React, { Fragment } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import { Card, Typography, Divider } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import ThreeCards from "./ThreeCards";
import FEEimage from "assets/images/forensic-expert.jpg";
import SDimage from "assets/icons/statement-drafting.png";
import FEimage from "assets/icons/forensic-expert.png";
import { Link } from "react-router-dom";

const FEE = () => {
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 4rem 0 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div>
            <img src={FEEimage} alt="" style={{ width: "100%" }} />
          </div>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={SDimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Statement Drafting Service
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Our linguists can help many individuals who need to provide a
                  personal statement to lawyers, draft one up in a well written
                  manner. This is in particular for those who are not very adept
                  in a specific language. We understand that our clients will be
                  well-versed in their native tongue. Our linguists will be able
                  to convey what the client needs, take notes and draft up a
                  statement tailored to the language a client can read and speak
                  in. Linguists will ensure to check every detail is accurate
                  with the client, and if there is a need for amendment, our
                  Linguists will do so. After this process, a full draft will be
                  written, translated into the preferred language of the client.
                  Many of our linguists are efficient in aiding the police and
                  other professionals in the translation and write ups of legal
                  statements. We recommend you book Level 3 or above for this
                  service. You can trust our personnel. Many of our linguists
                  are trained, highly qualified professionals and experienced in
                  this profession. Please note that some linguists hold a DPI
                  (Diploma in Police Interpreting), formerly known as an MPT
                  (Metropolitan Police Test). Other linguists have a DPSI
                  (Diploma in Public Service Interpreting) in law discipline.
                  Our linguists are required and committed to keep all your
                  information confidential. They are obliged to practice
                  according to our code of conduct. Request a{" "}
                  <Link to="/add-new-customer">Quote</Link>
                </Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "4px",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={FEimage} alt="" style={{ width: "90%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700" }}
                  paragraph
                  variant="h5"
                >
                  Forensic and Expert Evidence Service
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Forensic linguists are language specialists that investigate
                  or work together with professional investigators. They provide
                  an expert opinion in reports or court witness forms. Some work
                  may relate to researching, investigating and producing expert
                  reportsfor court. This is a special service our company offers
                  and we believe, the first of its kind. We are introducing this
                  mainly for British courts, in order to provide support
                  verifying the quality of work and any impact it may have on a
                  case. This special service is also designed to provide
                  assistance to security personnel and the police in
                  investigating any serious matters. For a court needing any
                  expert evidence, a team of three linguists, an assistant
                  linguist and a legal expert, will do the necessary work and
                  produce the report to the court. However, for security and
                  police, it entirely depends on the service needed, in order to
                  work out a charge. It may be that a sole expert linguist is
                  hired to assist them. We only use fully qualified staff, with
                  an extensive experience in security, police, court
                  interpreting and translating. Should you wish to hire a team
                  or request an expert evidence report, then please contact our
                  Admin Team or the send your enquiry using a quote request.
                  Request a <Link to="/add-new-customer">Quote</Link> or apply
                  for a{" "}
                  <Link to="/clientacc-opening-form">Billing Account</Link> to
                  buy services.
                </Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <ThreeCards />
      </div>
      {/* </div> */}
    </LandingPageLayout>
  );
};

export default FEE;
