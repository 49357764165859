import { CHANGE_CLIENTACCOPENING_INPUT, CHANGE_MB_CURRENCY, UPDATE_CLIENTACCOPENING_STEP } from "./actionTypes";

export const updateCAOStep = (count) => ({
  type: UPDATE_CLIENTACCOPENING_STEP,
  payload: count,
});

export const changeCAOInput = data => ({
  type: CHANGE_CLIENTACCOPENING_INPUT,
  payload: data
})

export const changeMBCurrency = data => ({
  type: CHANGE_MB_CURRENCY,
  payload: data
})
