import React, { useState } from "react";

// Material Core Components
import Typography from "@material-ui/core/Typography";

import { Button } from "semantic-ui-react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import CustomInputSingle from "components/Common/CustomInputSingle";
import { Icon } from "@material-ui/core";
import Axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";

const SendPrivateClientReg = () => {
  const classes = useEmployeeStyles();

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const isAllow = !state.first_name || !state.last_name || !state.email;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (e) => {
    const token = Cookies.get("LINtoken");
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/request-private-Client`,
        state,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `Email Sent!`,
        icon: "success",
        buttons: false,
      });
      setState({ first_name: "", last_name: "", email: "" });
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  Send Private Client Registration Form
                </Typography>
              </div>

              <form
                onSubmit={(e) => e.preventDefault()}
                className={classes.form}
                noValidate
                autoComplete="off"
              >
                <CustomInputSingle
                  onChange={handleChange}
                  name="first_name"
                  value={state.first_name}
                  label="First Name"
                  required
                />

                <CustomInputSingle
                  onChange={handleChange}
                  name="last_name"
                  value={state.last_name}
                  label="Last Name"
                  required
                />

                <CustomInputSingle
                  onChange={handleChange}
                  name="email"
                  value={state.email}
                  label="Email"
                  required
                />

                <Button
                  color="purple"
                  disabled={isAllow}
                  className={classes.button}
                  endIcon={<Icon>send</Icon>}
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default SendPrivateClientReg;
