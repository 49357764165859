import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { IconButton, useMediaQuery } from "@material-ui/core";
import history from "utils/history";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useParams } from "react-router-dom";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ViewList from "components/Admin/BookingPanel/Quotation/Requested/CAC/List";
import QuotationOptions from "components/Admin/BookingPanel/Quotation/QuotationOptions";
import Options from "components/Admin/BookingPanel/Quotation/Requested/Options";

const InvoiceJobs = () => {
  const classes = useDashboardStyles();
  const matches = useMediaQuery('(max-width:425px)')
  const { data, type } = useParams();

  return (
    <Manager>
      <MasterLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              {matches ? <CardHeader
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Requested Quote (CAC){" "}
                </p>
                <div style={{ display: "flex" }}>
                  <Options />
                  <IconButton color="inherit">
                    <ArrowBackIcon onClick={() => history.goBack()} />
                  </IconButton>
                </div>
              </CardHeader> : <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Requested Quote (CAC){" "}
                </p>
                <div style={{ display: "flex" }}>
                  <Options />
                  <IconButton color="inherit">
                    <ArrowBackIcon onClick={() => history.goBack()} />
                  </IconButton>
                </div>
              </CardHeader>}
            </Card>

            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <QuotationOptions />
              </CardHeader>
            </Card>
            <Card>
              <CardBody>
                <ViewList quotationData={type === "quotation" && data} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </MasterLayout>
    </Manager>
  );
};

export default InvoiceJobs;
