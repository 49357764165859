import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import swal from "sweetalert";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import CustomDate from "components/Common/CustomDate";
import CustomInput from "components/Common/CustomInput";
import CustomSelect from "components/Common/CustomSelect";
import axios from "axios";
import Cookies from "js-cookie";
import TextField from "@material-ui/core/TextField";

const AddTask = ({ setOpen }) => {
  const [PERSONNELLIST, setPERSONNELLIST] = useState([]);
  const PRIORITYLIST = ["High", "Medium", "Low"];
  const [employeeList, setEmployeeList] = useState();
  const token = Cookies.get("EMPtoken");

  const [data, setData] = useState({
    subject: "",
    task: "",
    personnelName: [],
    status: "to do",
    priority: "",
    note: "",
    personnelID: [],
    deadline: "",
    workEmail: [],
  });

  const isAllow =
    !data.subject ||
    !data.task ||
    !data.personnelName ||
    !data.priority ||
    !data.deadline;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleDateChange = (date) => {
    setData({ ...data, deadline: date });
  };

  const handleAutoComplete = (e, value) => {
    let id = [];

    let workEmails = [];
    id = value.map((id) => id?.split("- ")[1]);
    workEmails = id.map((entry, i) => {
      let email;
      employeeList.forEach((val) => {
        if (entry === val.id) {
          email = val.email;
        }
      });
      return email;
    });
    setData({
      ...data,
      personnelName: value,
      personnelID: id,
      workEmail: workEmails,
    });
  };

  const LoadPersonnelList = async () => {
    let list = [];
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/all-managers-admins-employee`
      );
      response?.data.forEach(
        (element) =>
        (list = [
          ...list,
          `${element.FirstName} ${element.LastName} - ${element.id}`,
        ])
      );
      setPERSONNELLIST(list);
      setEmployeeList(response?.data);
    } catch (error) {
      console.log(error);
      swal({
        title: "Connection error",
        text: "please check connection then refresh",
        icon: "error",
      });
    }
  };
  const AssignTask = async () => {
    try {
      const name = data.personnelName.map((val) => val?.split(" -")[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/employee/post-task`,
        {
          subject: data.subject,
          personnelName: name,
          task: data.task,
          status: data.status,
          priority: data.priority,
          note: data.note,
          personnelID: data.personnelID,
          deadline: data.deadline,
          email: data.workEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(
        setData({
          subject: "",
          task: "",
          personnelName: [],
          status: "to do",
          priority: "",
          note: "",
          personnelID: [],
          deadline: "",
          workEmail: [],
        })
      );
    } catch (error) {
      swal({
        title: "Connection error",
        text: "please check connection then refresh",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    LoadPersonnelList();
  }, []);

  return (
    <div>
      <TextField
        label="Subject"
        name='subject'
        onChange={handleChange}
        value={data.subject}
        multiline
        rows={3}
        inputProps={{ maxLength: 100 }}
        placeholder="Task subject"
        style={{ width: '100%' }}
        required
      />

      <CustomInput
        onChange={handleChange}
        value={data.task}
        name="task"
        label="Task details"
        width="100%"
        placeholder="enter task details (e.g. take out garbage)"
        required
      />

      <CustomDate
        onChange={handleDateChange}
        value={data.deadline}
        name="deadline"
        width="100%"
        type="date"
        required
        label="Deadline"
        autoFocus
      />

      <CustomAutoComplete
        onChange={handleAutoComplete}
        label="Personnel list"
        width="100%"
        data={PERSONNELLIST}
        value={data.personnelName}
        placeholder="Choose personnel(s)"
        required
      />

      <CustomSelect
        onChange={handleChange}
        label="Priority"
        width="100%"
        name="priority"
        data={PRIORITYLIST}
        value={data.priority}
        required
      />
      <CustomInput
        onChange={handleChange}
        value={data.note}
        name="note"
        label="Note"
        width="100%"
        placeholder="enter additonal task information"
      />
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button disabled={isAllow} onClick={AssignTask} color="purple">
          Assign Task
        </Button>
      </div>
    </div>
  );
};
export default AddTask;