import SearchInput from "./QuoteInput";
const SearchCACQuotes = ({handleSearch,search,load,setSearch,setShow,show}) => {
  return (
    <div>
      <SearchInput setShow={setShow} setSearch={setSearch} load={load} handleSearch={handleSearch} search={search} show={show}/>
    
    </div>
  );
};

export default SearchCACQuotes;
