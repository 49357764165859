import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const Options = ({ currentPage }) => {
  const classes = useStyles();
  const { id } = useParams();
  

  return (
    <div className={classes.root}>
      <Button variant="outlined">
        <Link to={`/employee/inhouse-interpreting/confirm-assignments`}>
          <b style={{ color: "white" }}>Confirmed Assignments</b>
        </Link>
      </Button>
      <Button variant="outlined">
        <Link to={`/employee/inhouse-interpreting/assigned-assignments`}>
          <b style={{ color: "white" }}>Assigned Assignments</b>
        </Link>
      </Button>

      <Button variant="outlined">
        <Link to={`/employee/inhouse-interpreting/closed-assignments`}>
          <b style={{ color: "white" }}>Closed Assignments (Timesheet)</b>
        </Link>
      </Button>

      <Button variant="outlined">
        <Link to={`/employee/inhouse-interpreting/invoiced-assignments`}>
          <b style={{ color: "white" }}>Invoiced Assignments</b>
        </Link>
      </Button>

      <Button variant="outlined">
        <Link to={`/employee/inhouse-interpreting/completed-assignments`}>
          <b style={{ color: "white" }}>Completed Assignments (Fully Paid)</b>
        </Link>
      </Button>

      <Button variant="outlined">
        <Link to={`/employee/inhouse-interpreting/canceled-assignments`}>
          <b style={{ color: "white" }}>Cancelled Assignments</b>
        </Link>
      </Button>

     
    </div>
  );
};

export default Options;
