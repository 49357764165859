import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

import ViewAttach from "components/Admin/Communication/ViewAttachConversation";

const ViewContactCard = () => {
  return (
    <Employee>
      <EmployeeLayout>
        <ViewAttach />
      </EmployeeLayout>
    </Employee>
  );
};

export default ViewContactCard;
