import useFormStyles from "assets/jss/components/auth/formStyle";
import React, { useEffect, useRef, useState } from "react";
import Items from "./TableBody";

//Style
import "./style.css";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Rating } from "@material-ui/lab";
import CustomTextInput from "components/Common/CustomTextInput";
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import TimesheetFooter from "./ItemsTwo";
import { useSelector } from "react-redux";
import CustomSnackBar from "components/Common/CustomSnackBar";
const Details = () => {
  const { id } = useParams();
  const token = Cookies.get("CACtoken");
  const [details, setDetails] = useState(null);
  const [value, setValue] = useState(0);
  const { data } = useSelector((state) => state.timesheetReducer);
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const uploadRef = useRef();
  const [submit, setSubmit] = useState({
    toast: false,
    loader: false,
  });
  const [dispute, setDispute] = useState({
    toast: false,
    loader: false,
  });
  const handleDispute = async () => {
    try {
      setDispute({ ...dispute, loader: true });
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/dispute-timesheet/timesheetID/${details[0]._id}`
      );
      setDispute({ loader: false, toast: true });

      window.location = `/cash-client/open-new-ticket`;
    } catch (error) {
      console.log(error);
    }
  };
  const { FirstName, LastName, mobile_no } = JSON.parse(
    localStorage.getItem("CACuser")
  );

  const {
    note,
    signature,
    declaration,
  } = data;

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getTimeSheet = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/cashclient/view-closed-booking/bookingID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );
        setDetails(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    getTimeSheet();

    return () => {
      source.cancel();
    };
  }, []);

  const handleSubmit = async (e) => {
    setSubmit({ ...submit, loader: true });
    const file = new File([signature], "signature", { type: "image/png" });
    const fd = new FormData();
    fd.append("signatureByCAC", file);
    fd.append("feedbackByClient", value);
    fd.append("client_note", note);
    if (uploadTimesheet?.size) {
      fd.append("documentByClient", uploadTimesheet);
    }
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/accept-timesheet/timesheetID/${details[0]._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubmit({ ...submit, loader: false, toast: true });
      window.location = "/cashclient/confirmed-interpreters";
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleRating = (e, value) => {
    setValue(value);
  };

  return details ? (
    <div>
      <div style={{ width: "100%", marginTop: "24px" }}>
        <CustomSnackBar
          message="Timesheet accepted"
          handleClose={() => setSubmit({ ...submit, toast: false })}
          openSnackBar={submit.toast}
          undo={true}
        />
        <CustomSnackBar
          message="Timesheet disputed"
          handleClose={() => setDispute({ ...dispute, toast: false })}
          openSnackBar={dispute.toast}
        />
        <CustomTextInput
          label="Job Reference Number"
          value={details[0].cacID.id}
        />

        <Items details={details} />

        <CustomTextInput
          label="Instructions for linguist professional"
          value={details[0].country}
        />

        {/* Address details */}
        {details[0].remote_address && (
          <CustomTextInput
            label="Remote Address"
            value={details[0].cacID.remote_address}
          />
        )}
        {!details[0].remote_address ? (
          <>
            <CustomTextInput
              label="House Number / Street Name"
              value={details[0].cacID.house_number_street_name}
            />
            <CustomTextInput
              label="Address Line 2"
              value={details[0].cacID.address_line_two}
            />
            <CustomTextInput
              label="County / Region"
              value={details[0].cacID.region}
            />
            <CustomTextInput
              label="Post Code"
              value={details[0].cacID.post_code}
            />
            <CustomTextInput label="Country" value={details[0].cacID.country} />
          </>
        ) : null}
        <CustomTextInput
          label="Court case, police, NHS ref"
          value={details[0].cacID.security_clearance}
        />

        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
        >
          <Header style={{ margin: "0" }} as="h4">
            Feedback from linguist
          </Header>
          <div>
            <Rating
              name="simple-controlled"
              value={details[0].feedback}
              readOnly
            />
          </div>
        </div>

        <CustomTextInput
          label="Customer / Client Name"
          value={FirstName + " " + LastName}
        />
        <CustomTextInput
          label="Customer / Client ref no."
          value={details[0].clientRef}
        />
        <CustomTextInput label="Emergency Contact no." value={mobile_no} />
        <CustomTextInput label="Name of limited speaker conference:" value="" />
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
        >
          <Header style={{ margin: "0" }} as="h4">
            Feedback to linguist
          </Header>
          <div>
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
          </div>
        </div>

        <TimesheetFooter details={details} />
      </div>

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button
        onClick={() => uploadRef.current.click()}
        basic
        style={{ backgroundColor: "purple", color: "white" }}
      >
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <div style={{ padding: "10px" }}>
          <Button style={{ backgroundColor: "purple", color: "white" }}>
            View PDF
          </Button>
        </div>
        <div style={{ padding: "10px" }}>
          <Button
            disabled={submit.loader || !signature || !declaration}
            onClick={handleSubmit}
            style={{ backgroundColor: "purple", color: "white" }}
          >
            Confirm
          </Button>
          {submit.loader && <Loader active inline />}
        </div>
        <div style={{ padding: "10px" }}>
          <Button
            onClick={handleDispute}
            style={{ backgroundColor: "purple", color: "white" }}
            disabled={details?.dispute}
          >
            {details.dispute ? "Disputed" : "Dispute"}
          </Button>
          {dispute.loader && <Loader active inline />}
        </div>
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
