import { ACCOUNT_CHANGE_BA_INPUT, ACCOUNT_UPDATE_BOOKING_STEP, CHANGE_BA_INPUT, UPDATE_BOOKING_STEP } from "./actionTypes";

export const updateBookingStep = (count) => ({
  type: UPDATE_BOOKING_STEP,
  payload: count,
});

export const changeBaInput = (data) => ({
  type: CHANGE_BA_INPUT,
  payload: data,
});


export const updateBookingStepAC = (count) => ({
  type: ACCOUNT_UPDATE_BOOKING_STEP,
  payload: count,
});

export const changeBaInputAC = (data) => ({
  type: ACCOUNT_CHANGE_BA_INPUT,
  payload: data,
});
