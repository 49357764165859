import React, {useState, useEffect} from 'react'
import history from "utils/history";
import StripeLayout from 'layout/StripeLayout';
import CheckoutForm from 'layout/CheckoutFormLayout/CheckoutFormCAC';
import Cookies from 'js-cookie'
import { Dimmer, Loader } from 'semantic-ui-react'


const StripePayment = () => {
    const grandTotal = Cookies.get('grandTotal')
    const currency = Cookies.get('stripeCurrency')
    const [details, setDetails] = useState(null)
    const [loader, setLoader] = useState(true)
    // const id = Cookies.get('cacClientID')

    return (
        <StripeLayout>
          <CheckoutForm
            price = {grandTotal}
            currency = {currency}
            onSuccessfulCheckout={(id) => history.push(`/cash-client/interpreter/success/estimate/payment/${id}`)}
            // cacClientID = {id}
          />
        </StripeLayout>
    )
}

export default StripePayment
