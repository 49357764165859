import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistsCollectiveBooking from "components/Auth/LinguistsCollectiveBooking/LinguistsCollectiveBooking";
import SppoLayout from "layout/SppoLayout";

const BookingInterpreter = () => {
  return (
    <SppoLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <LinguistsCollectiveBooking />
        </GridItem>
      </GridContainer>
    </SppoLayout>
  );
};

export default BookingInterpreter;
