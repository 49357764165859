import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import swal from "sweetalert";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import { Button, Dimmer, Loader } from "semantic-ui-react";

const LevelAction = ({
  id,
  level,
  level1,
  level2,
  level3,
  level4,
  setValues,
  values,
  hide,
  lang,
  index,
  load,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("token");
  const [state, setState] = React.useState({
    level: "Level 0",
  });
  const [loader, setLoader] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = (e) => {
    history.push("/interpreter/hindi/000");
  };

  if (!level) {
    Axios.put(
      `/api/manager-admin/upgrade-other-language-level/linID/${id}`,
      {
        other_language_level: "level 0",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  const handleLevel = async (levelSelected) => {
    setLoader(true);

    try {
      const response = await Axios.put(
        `/api/manager-admin/upgrade-other-language-level/linID/${id}`,
        {
          other_language_level: levelSelected,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAnchorEl(null);
      setLoader(false);

      setValues({ ...values, reload: !values.reload });
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleLevel1 = async (levelSelected) => {
    setLoader(true);

    try {
      const response = await Axios.put(
        `/api/manager-admin/upgrade-other-language-level1/linID/${id}`,
        {
          other_language_level_1: levelSelected,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAnchorEl(null);
      setLoader(false);

      setValues({ ...values, reload: !values.reload });
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleLevel2 = async (levelSelected) => {
    setLoader(true);

    try {
      const response = await Axios.put(
        `/api/manager-admin/upgrade-other-language-level2/linID/${id}`,
        {
          other_language_level_2: levelSelected,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAnchorEl(null);
      setLoader(false);

      setValues({ ...values, reload: !values.reload });
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleLevel3 = async (levelSelected) => {
    setLoader(true);

    try {
      const response = await Axios.put(
        `/api/manager-admin/upgrade-other-language-level3/linID/${id}`,
        {
          other_language_level_3: levelSelected,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAnchorEl(null);
      setLoader(false);

      setValues({ ...values, reload: !values.reload });
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleLevel4 = async (levelSelected) => {
    setLoader(true);

    try {
      const response = await Axios.put(
        `/api/manager-admin/upgrade-other-language-level4/linID/${id}`,
        {
          other_language_level_4: levelSelected,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAnchorEl(null);
      setLoader(false);

      setValues({ ...values, reload: !values.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleChange = async (levelSelected) => {
    swal("Are you sure you want to do this ?", {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      // .then(function() {
      //   window.location = "/admin/unverified-linguists";
      // })
      .then((value) => {
        switch (value) {
          case "cancel":
            break;

          case "catch":
            if (index === 0) {
              handleLevel(levelSelected);
            } else if (index === 1) {
              handleLevel1(levelSelected);
            } else if (index === 2) {
              handleLevel2(levelSelected);
            } else if (index === 3) {
              handleLevel3(levelSelected);
            } else if (index === 4) {
              handleLevel4(levelSelected);
            }
            load();

          default:
            break;
        }
      });
  };

  if (loader) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Assigning level</Loader>
      </Dimmer>
    );
  }
  return (
    <div>
      {index === 0 ? (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          // style={{
          //   minWidth: "10px",
          //   paddingLeft: "0px",
          // }}
        >
          {/* {state.level} */}
          {level || "level 0"}
        </Button>
      ) : index === 1 ? (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          // style={{
          //   minWidth: "10px",
          //   paddingLeft: "0px",
          // }}
        >
          {/* {state.level} */}
          {level1 || "level 0"}
        </Button>
      ) : index === 2 ? (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          // style={{
          //   minWidth: "10px",
          //   paddingLeft: "0px",
          // }}
        >
          {/* {state.level} */}
          {level2 || "level 0"}
        </Button>
      ) : index === 3 ? (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          // style={{
          //   minWidth: "10px",
          //   paddingLeft: "0px",
          // }}
        >
          {/* {state.level} */}
          {level3 || "level 0"}
        </Button>
      ) : (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          // style={{
          //   minWidth: "10px",
          //   paddingLeft: "0px",
          // }}
        >
          {/* {state.level} */}
          {level4 || "level 0"}
        </Button>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!hide ? (
          <MenuItem onClick={() => handleChange("Level 0")}>Level 0</MenuItem>
        ) : null}
        {lang?.includes("(R)") ? (
          <>
            <MenuItem onClick={() => handleChange("N/A")}>N/A</MenuItem>
            <MenuItem onClick={() => handleChange("Level 3")}>Level 3</MenuItem>
            <MenuItem onClick={() => handleChange("Level 4R")}>
              Level 4r
            </MenuItem>
            <MenuItem onClick={() => handleChange("Level 5")}>Level 5</MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={() => handleChange("N/A")}>N/A</MenuItem>
            <MenuItem onClick={() => handleChange("Level 1")}>Level 1</MenuItem>
            <MenuItem onClick={() => handleChange("Level 2")}>Level 2</MenuItem>
            <MenuItem onClick={() => handleChange("Level 3")}>Level 3</MenuItem>
            <MenuItem onClick={() => handleChange("Level 4")}>Level 4</MenuItem>
            <MenuItem onClick={() => handleChange("Level 5")}>Level 5</MenuItem>

            <MenuItem onClick={() => handleChange("Level X")}>Level X</MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};

export default LevelAction;
