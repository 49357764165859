import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "semantic-ui-react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

//Material icons

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

// import Details from './Details'
import { Dimmer, Header, Loader, Statistic, Table } from "semantic-ui-react";
import { CURRENCY, PAY_OPTIONS, BANK_PAY_OPTIONS } from "data";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import CustomSemanticDD from "components/Common/CustomSemanticDD";

//Stripe
import StripeLayout from "layout/StripeLayout";
import CheckoutCACInvoice from "layout/CheckoutLayout/CheckoutCACInvoice";
import history from "utils/history";

import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import swal from "sweetalert";

const ViewINCInvoice = () => {
  const classes = useEmployeeStyles();
  const { id } = useParams();
  const token = Cookies.get("CACtoken");
  const [invoice, setInvoice] = useState(null);
  const [text, setText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showNote, setShowNote] = React.useState(false);
  const [viewNote, setViewNote] = React.useState(false);

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const [pay, setPay] = React.useState({
    reason: "",
  });

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };
  const handleBack = () => {
    setViewNote(false);
  };
  const handleShowNote = () => {
    setShowNote(!showNote);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowNote(false);
  };

  const dispatch = useDispatch();
  const [payOptions, setPayOptions] = useState(null);
  const [currency, setCurrency] = useState(null);

  const [bankDetails, setBankDetails] = useState({
    account_holder_name: "",
    bsb: "",
    account_number: "",
    bic: "",
    iban: "",
    sort_code: "",
    routing_number: "",
    institution_number: "",
    transit_number: "",
    bank_code: "",
  });
  const [convertedCurrency, setConvertedCurrency] = useState(null);

  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
  });

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const loadInvoice = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-invoice/invID/${id}`
        );

        setInvoice(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          console.log(error.response?.data);
        }
      }
    };

    loadInvoice();

    return () => {
      source.cancel();
    };
  }, []);

  // Handling payments

  const handlePayOptions = async (e) => {
    setPayOptions(e.target.value);
    if (e.target.value === "Marketplace speedy payment") {
      setBankDetails({
        ...bankDetails,
        bank_code: "",
        account_number: "",
        account_holder_name: "",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      setCurrency(null);
    }
  };

  const handleCurrency = async (e) => {
    setCurrency(e.target.value);
    const value = e.target.value;
    if (value === "AUD (Australia)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        bsb: "802-985",
        account_number: "918630266",
        bic: "",
        sort_code: "",
        iban: "",
        routing_number: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
        );

        setConvertedCurrency(
          `AUD $ ${(
            (response?.data?.rates?.AUD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "EUR (EU)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        bic: "TRWIBEB1XXX",
        iban: "BE13 9671 5755 4739",
        bsb: "",
        account_number: "",
        sort_code: "",
        routing_number: "",
        bank_code: "",
        institution_number: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
        );

        setConvertedCurrency(
          `EUR € ${(
            (response?.data?.rates?.EUR * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "GBP (UK)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        sort_code: "23-14-70",
        iban: "GB51 TRWI 2314 7058 5979 53",
        account_number: "",
        bsb: "",
        bic: "",
        routing_number: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
        );

        setConvertedCurrency(
          `GBP £ ${(
            (response?.data?.rates?.GBP * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "TRY (Turkish lira)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        iban: "TR69 0010 3000 0000 0033 8634 34",
        sort_code: "",
        bsb: "",
        account_number: "",
        bic: "",
        routing_number: "",
        bank_code: "",
        institution_number: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
        );

        setConvertedCurrency(
          `TRY ₺ ${(
            (response?.data?.rates?.TRY * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "USD (USA)") {
      setBankDetails({
        ...bankDetails,
        account_number: "9600000000264428",
        account_holder_name: "Linguists Collective Ltd",
        routing_number: "084009519",
        sort_code: "",
        bsb: "",
        bic: "",
        iban: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
        );

        setConvertedCurrency(
          `USD $ ${(
            (response?.data?.rates?.USD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "CAD (Canada)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        institution_number: "621",
        account_number: "200110001176",
        transit_number: "16001",
        routing_number: "",
        bic: "",
        bsb: "",
        sort_code: "",
        iban: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
        );

        setConvertedCurrency(
          `CAD $ ${(
            (response?.data?.rates?.CAD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "HUF (Hungarian forint)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        account_number: "12600016-17943734-02621164",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
        );

        setConvertedCurrency(
          `HUF $ ${(
            (response?.data?.rates?.HUF * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "NZD (New Zealand dollar)") {
      setBankDetails({
        ...bankDetails,
        account_number: "02-1290-0578525-000",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
        );

        setConvertedCurrency(
          `NZD $ ${(
            (response?.data?.rates?.NZD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "RON (Romanian leu)") {
      setBankDetails({
        ...bankDetails,
        bank_code: "BRELROBUXXX",
        account_number: "RO73 BREL 0005 4000 8848 0100",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
        );

        setConvertedCurrency(
          `lei ${(
            (response?.data?.rates?.RON * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "SGD (Singapore dollar)") {
      setBankDetails({
        ...bankDetails,
        bank_code: "7171",
        account_number: "885-074-585-39",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
        );

        setConvertedCurrency(
          `SGD $ ${(
            (response?.data?.rates?.SGD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const refund = async (e) => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sent-request-refund/invID/${id}`,
        {
          reason: pay.reason,
          client_ref: invoice?.client_id,
          client_name: invoice?.client_firstname,
          client_email: invoice?.client_email,
          client_mobile_no: invoice?.client_mobile_no,
          assignment_ref: invoice?.assignment_id,
          assignmentID: invoice?.projectID?.cacTranslationID?._id
            ? invoice?.projectID?.cacTranslationID?._id
            : invoice?.projectID?.linQuoteID?.quoteRequstByCashclient?._id
              ? invoice?.projectID?.linQuoteID?.quoteRequstByCashclient?._id
              : "N/A",
          lin_ref: invoice?.postedBy?.id,
          payment_intent: invoice?.projectID?.cacTranslationID?.payment_intent
            ? invoice?.projectID?.cacTranslationID?.payment_intent
            : invoice?.projectID?.linQuoteID?.payment_intent
              ? invoice?.projectID?.linQuoteID?.payment_intent
              : "",
          charge_id: invoice?.projectID?.cacTranslationID?.charge_id
            ? invoice?.projectID?.cacTranslationID?.charge_id
            : invoice?.projectID?.linQuoteID?.charge_id
              ? invoice?.projectID?.linQuoteID?.charge_id
              : "",
          payment_date: invoice?.projectID?.cacTranslationID?.payment_date
            ? invoice?.projectID?.cacTranslationID?.payment_date
            : invoice?.projectID?.linQuoteID?.payment_date
              ? invoice?.projectID?.linQuoteID?.payment_date
              : "No payment date",
          paidby: invoice?.projectID?.cacTranslationID?.bank_pay
            ? "Marketplace bank transfer"
            : "Paid by card"
              ? "Paid by card"
              : invoice?.projectID?.linQuoteID?.bank_pay
                ? "Marketplace bank transfer"
                : "Paid by card",
          paid_currency:
            invoice?.projectID?.cacTranslationID?.currency_new?.substring(0, 3)
              ? invoice?.projectID?.cacTranslationID?.currency_new?.substring(
                0,
                3
              )
              : invoice?.projectID?.linQuoteID?.currency?.substring(0, 3)
                ? invoice?.projectID?.linQuoteID?.currency?.substring(0, 3)
                : "N/A",
          paid_amount: invoice?.projectID?.cacTranslationID
            ?.estimate_paid_amount
            ? invoice?.projectID?.cacTranslationID?.estimate_paid_amount
            : invoice?.projectID?.cacTranslationID?.estimate
              ? invoice?.projectID?.cacTranslationID?.estimate
              : invoice?.projectID?.linQuoteID?.estimate_paid_amount
                ? invoice?.projectID?.linQuoteID?.estimate_paid_amount
                : invoice?.projectID?.linQuoteID?.grand_total
                  ? invoice?.projectID?.linQuoteID?.grand_total
                  : "N/A",
        }
      );
      swal({
        icon: "success",
        text: response?.data.message,
        buttons: "Ok",
      }).then(function () {
        window.location = "/cash-client/dashboard";
      });
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: "Failed",
        buttons: "Ok",
      });
    }
    setEdit({ ...edit, loader: true });
  };

  const refundRequest = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          refund(value);

        default:
          break;
      }
    });
  };

  return invoice ? (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem id="printArea" xs={12} sm={12} md={12} lg={8} xl={8}>
          <Card>
            <CardBody>
              {/* Avatar and company name */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "24px",
                }}
              >
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  {Number(invoice?.grand_total) === 0 ? (
                    <Statistic color="green">
                      <Statistic.Value text>
                        PAID
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : Number(invoice?.grand_total) < 0 ? (
                    <Statistic color="purple">
                      <Statistic.Value text>
                        OVERPAID
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : Number(invoice?.grand_total) < 1 ? (
                    <Statistic color="purple">
                      <Statistic.Value text>
                        Pay Manually
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : invoice.status == "PAID" ? (
                    <Statistic color="green">
                      <Statistic.Value text>
                        {invoice.status}
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : (
                    <Statistic color="red">
                      <Statistic.Value text>
                        {invoice.status}
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  )}
                  <CustomAvatar style={{ margin: "0", marginLeft: "10px" }} />
                </div>
              </div>

              {/* First div  */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice.client_firstname + " " + invoice.client_lastname}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_addressline_one}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_addressline_two}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_city}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_region}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_postalcode}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_country}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_email}
                    </Header>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ID number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.lin_id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist Residence:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.lin_country}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      VAT/GST no:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.lin_vat_no}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of quote:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;N/A
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of invoice:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.date_of_invoice}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice to be paid by:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {moment(invoice.invoice_tobe_paid_by).format(
                        "DD/MMM/YYYY HH:mm"
                      )}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Job reference:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.assignment_id ? invoice?.assignment_id : "N/A"}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Service completion date:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.service_completion_date}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ref:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.linguist_ref ? invoice?.linguist_ref : "N/A"}
                    </Header>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Service:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;{invoice?.service}
                  </Header>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Expertise required:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;{invoice?.expertise_level}
                  </Header>
                </div>
              </div>

              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="5">
                      Description of service
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Price</Table.HeaderCell>
                    <Table.HeaderCell width="1">Quantity</Table.HeaderCell>
                    <Table.HeaderCell width="1">Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {invoice?.productOne?.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.text}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.amount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.quantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ))}

                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header textAlign="right" as="h3">
                        Sub Total
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.subtotal1}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Expenses and travel</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  {invoice?.productTwo?.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.otherText}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherAmount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherQuantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ))}

                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h3">Sub Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.subtotal2}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {Number(invoice?.subtotal1) +
                            Number(invoice?.subtotal2)}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Discount ({invoice?.discount_percentage})%
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.discount_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Billing Account Service Charge %</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>00.00</Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Platform and booking service charge{" "}
                        {invoice?.service_charge} %
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.service_charge_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">VAT ({invoice?.vat_percentage})%</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.vat_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)} {invoice?.total}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Estimate paid in advance / Pay later amount</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.estimate_paid_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Total to be paid including VAT/GST
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {Number(invoice?.grand_total).toFixed(2)}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Header style={{ marginTop: "16px" }} as="h4">
                  Thank You For Your Business
                </Header>
                <Header
                  color="grey"
                  style={{ marginBottom: "16px", marginTop: "0" }}
                  as="h4"
                >
                  {invoice?.company_name}, {invoice?.company_address}
                </Header>

                {Number(invoice?.grand_total) < 0 ? (
                  <>
                    <p style={{ color: "red" }}>
                      Negative value!! Please request refund of the amount shown
                      (-) in 'Total to be paid including VAT/GST', unless you
                      have been already issued with the refund.
                    </p>
                    <Button
                      onClick={handleShowNote}
                      style={{ marginBottom: "10px" }}
                      color="purple"
                    >
                      Refund request
                    </Button>
                  </>
                ) : Number(invoice?.grand_total) === 0 ? (
                  <h3 style={{ color: "green" }}>
                    {" "}
                    This invoice has been paid.
                  </h3>
                ) : Number(invoice?.grand_total) < 1 &&
                  !invoice?.invoice_payment_status &&
                  !invoice?.bank_pay ? (
                  <>
                    <div>
                      <h3 style={{ color: "purple" }}>
                        {" "}
                        Please pay by bank transfer, less than 1.00 not accepted by card payment.
                      </h3>
                      <CustomSemanticDD
                        onChange={(e) => handlePayOptions(e)}
                        label="Pay"
                        data={BANK_PAY_OPTIONS}
                      />
                      {payOptions === "Marketplace bank transfer" && (
                        <CustomSemanticDD
                          onChange={(e) => handleCurrency(e)}
                          label="Currency"
                          data={CURRENCY}
                          value={currency}
                        />
                      )}
                      {payOptions === "Marketplace bank transfer" &&
                        convertedCurrency && (
                          <Header as="h4">
                            Total to be paid including VAT/GST:{" "}
                            {convertedCurrency}
                          </Header>
                        )}
                      <Header as="h3">
                        {bankDetails?.account_holder_name}
                      </Header>

                      {bankDetails?.account_number && (
                        <Statistic color="purple" size="mini">
                          <Statistic.Label>Account number</Statistic.Label>
                          <Statistic.Value>
                            {bankDetails?.account_number}
                          </Statistic.Value>
                        </Statistic>
                      )}

                      {bankDetails?.sort_code && (
                        <Statistic color="purple" size="mini">
                          <Statistic.Label>Sort code</Statistic.Label>
                          <Statistic.Value>
                            {bankDetails?.sort_code}
                          </Statistic.Value>
                        </Statistic>
                      )}

                      {bankDetails?.bic && (
                        <Statistic color="purple" size="mini">
                          <Statistic.Label>BIC</Statistic.Label>
                          <Statistic.Value>
                            {bankDetails?.bic}
                          </Statistic.Value>
                        </Statistic>
                      )}

                      {bankDetails?.iban && (
                        <Statistic color="purple" size="mini">
                          <Statistic.Label>IBAN</Statistic.Label>
                          <Statistic.Value>
                            {bankDetails?.iban}
                          </Statistic.Value>
                        </Statistic>
                      )}

                      {bankDetails?.bsb && (
                        <Statistic color="purple" size="mini">
                          <Statistic.Label>BSB</Statistic.Label>
                          <Statistic.Value>
                            {bankDetails?.bsb}
                          </Statistic.Value>
                        </Statistic>
                      )}

                      {bankDetails?.institution_number && (
                        <Statistic color="purple" size="mini">
                          <Statistic.Label>
                            Institution number
                          </Statistic.Label>
                          <Statistic.Value>
                            {bankDetails?.institution_number}
                          </Statistic.Value>
                        </Statistic>
                      )}

                      {bankDetails?.bank_code && (
                        <Statistic color="purple" size="mini">
                          <Statistic.Label>Bank code</Statistic.Label>
                          <Statistic.Value>
                            {bankDetails?.bank_code}
                          </Statistic.Value>
                        </Statistic>
                      )}

                      {bankDetails?.routing_number && (
                        <Statistic color="purple" size="mini">
                          <Statistic.Label>Routing number</Statistic.Label>
                          <Statistic.Value>
                            {bankDetails?.routing_number}
                          </Statistic.Value>
                        </Statistic>
                      )}

                      {bankDetails?.transit_number && (
                        <Statistic color="purple" size="mini">
                          <Statistic.Label>Transit number</Statistic.Label>
                          <Statistic.Value>
                            {bankDetails?.transit_number}
                          </Statistic.Value>
                        </Statistic>
                      )}

                      {currency && (
                        <Header as="h5">
                          Please inform our Admin Team after processing the
                          payment. Bank transfer payment can take a few days
                          to complete. Please use either your client reference
                          number {invoice?.cashclientID.id}, invoice or billing
                          number {invoice?.projectID.id} - as the payment
                          reference.
                        </Header>
                      )}
                    </div>
                    {invoice?.invoice_payment_status ?
                      <p style={{ color: "green" }}>Invoice has been paid.</p>
                      :
                      <p style={{ color: "green" }}>
                        Invoice has been paid by bank transfer. Please wait for payment confirmation.
                      </p>
                    }
                  </>
                ) : (
                  <>
                    {!invoice?.invoice_payment_status &&
                      !invoice?.bank_pay ? (
                      <div>
                        <CustomSemanticDD
                          onChange={(e) => handlePayOptions(e)}
                          label="Pay"
                          data={PAY_OPTIONS}
                        />
                        {payOptions === "Marketplace bank transfer" && (
                          <CustomSemanticDD
                            onChange={(e) => handleCurrency(e)}
                            label="Currency"
                            data={CURRENCY}
                            value={currency}
                          />
                        )}
                        {payOptions === "Marketplace bank transfer" &&
                          convertedCurrency && (
                            <Header as="h4">
                              Total to be paid including VAT/GST:{" "}
                              {convertedCurrency}
                            </Header>
                          )}
                        <Header as="h3">
                          {bankDetails?.account_holder_name}
                        </Header>

                        {bankDetails?.account_number && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>Account number</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails?.account_number}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails?.sort_code && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>Sort code</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails?.sort_code}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails?.bic && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>BIC</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails?.bic}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails?.iban && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>IBAN</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails?.iban}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails?.bsb && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>BSB</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails?.bsb}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails?.institution_number && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>
                              Institution number
                            </Statistic.Label>
                            <Statistic.Value>
                              {bankDetails?.institution_number}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails?.bank_code && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>Bank code</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails?.bank_code}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails?.routing_number && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>Routing number</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails?.routing_number}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails?.transit_number && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>Transit number</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails?.transit_number}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {currency && (
                          <Header as="h5">
                            Please inform our Admin Team after processing the
                            payment. Bank transfer payment can take a few days
                            to complete. Please use either your client reference
                            number {invoice?.cashclientID.id}, invoice or billing
                            number {invoice?.projectID.id} - as the payment
                            reference.
                          </Header>
                        )}
                      </div>
                    ) : invoice?.invoice_payment_status ? (
                      <p style={{ color: "green" }}>Invoice has been paid.</p>
                    ) : (
                      <p style={{ color: "green" }}>
                        Invoice has been paid by bank transfer. Please wait for payment confirmation.
                      </p>
                    )}{" "}
                  </>
                )}
                {/* {payOptions === 'Marketplace speedy payment' && <Button loading= {loading} onClick= {() => setCardPay(true)} color= 'purple'>Pay</Button>} */}
                {payOptions === "Marketplace speedy payment" && (
                  <StripeLayout avatar={true}>
                    <CheckoutCACInvoice
                      price={Number(invoice?.grand_total)}
                      currency={invoice?.currency}
                      onSuccessfulCheckout={(id, invoiceId) =>
                        history.push(
                          `/cash-client/translation/success/invoice/payment/${invoiceId}/${id}`
                        )
                      }
                      cacClientID={id}
                      clientDetails={invoice?.cashclientID}
                      invoice={invoice}
                    />
                  </StripeLayout>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <>
        {/* ADD NOTE */}
        {showNote ? (
          <Dialog open={showNote}>
            {!viewNote ? (
              <div style={{ width: 600 }}>
                <DialogTitle
                  style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "space-between",
                      width: 100 + "%",
                    }}
                  >
                    Request Refund
                  </div>
                </DialogTitle>
                <DialogContent>
                  <TextField
                    onChange={handleChange}
                    value={pay.note}
                    id="standard-textarea"
                    label="Type here... (max 432 ch.)"
                    placeholder="Write your reason here..."
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 432 }}
                    name="reason"
                    required
                    style={{ marginBottom: 15, width: 100 + "%" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 10,
                      marginBottom: 20,
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      color="purple"
                      variant="outlined"
                      startIcon={<CloseIcon />}
                    >
                      Close
                    </Button>

                    <Button
                      disabled={!pay.reason || edit.loader}
                      onClick={refundRequest}
                      color="purple"
                      variant="outlined"
                      startIcon={<SaveIcon />}
                    >
                      Send Refund Request
                      {/* {edit.loader && <div class="ui active inline loader"></div>} */}
                    </Button>
                  </div>
                </DialogContent>
              </div>
            ) : (
              <div style={{ width: 600 }}>
                <DialogTitle
                  style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}
                >
                  Send Refund Request
                </DialogTitle>
                <DialogContent style={{ overflowY: "scroll" }}>
                  <Box pb={1}>
                    <Card>
                      <CardBody>
                        <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                          some text
                        </h5>
                      </CardBody>
                    </Card>
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 10,
                      marginBottom: 20,
                    }}
                  >
                    <Button
                      onClick={handleBack}
                      color="purple"
                      variant="outlined"
                      startIcon={<ArrowLeftIcon />}
                    >
                      Back
                    </Button>
                    <Button
                      onClick={handleClose}
                      color="purple"
                      variant="outlined"
                      startIcon={<CloseIcon />}
                    >
                      Close
                    </Button>
                  </div>
                </DialogContent>
              </div>
            )}
          </Dialog>
        ) : null}
      </>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default ViewINCInvoice;
