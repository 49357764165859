import React from "react";

// Material Components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

// Custom Components
import CustomFormControl from "./CustomFormControl";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  root: {
    width: "100%",

    "& .MuiInputBase-root": {
      width: "100%",
    },
  },
}));

const MultiDropdown = ({ label, data, selectedData, setSelectedData }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setSelectedData(event.target.value);
  };

  return (
    <div className={classes.root}>
      <CustomFormControl width="100%">
        <InputLabel id="multi-dropdown">{label}</InputLabel>
        <Select
          labelId="multi-dropdown"
          id="demo-mutiple-chip"
          multiple
          value={selectedData}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  className={classes.chip}
                  onDelete={() => ""}
                />
              ))}
            </div>
          )}
        >
          {data.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox
                checked={selectedData.indexOf(item) > -1}
                color="primary"
              />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
    </div>
  );
};

export default MultiDropdown;
