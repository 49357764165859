import React, { useState, useEffect } from "react";
//custom components
import CustomTime from "components/Common/CustomTime";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
import { TextField, Typography } from "@material-ui/core";
import CustomFormControl from "components/Common/CustomFormControl";
import { changedatetime } from "redux/actions/datetimeAction";
import { Button } from "semantic-ui-react";
//styles
import useFormStyles from "assets/jss/components/auth/formStyle";
//data
import { MONTHLY_DURATION, WEEK_WORK_INTERPRETING_DURATION } from "data";

const MonthlyDuration = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const [notValid, setNotValid] = useState(false);
  const { calender } = useSelector((state) => state.datetimeReducer);
  const {
    startdate,
    starttime,
    total_duration,
    enddate,
    endtime,
    monthly_duration,
    total_days,
    flag,
    weekly_work_duration,
  } = calender;
  const handleClick = (e, titleProps) => {
    e.preventDefault();
    let startHours = starttime.split(":")[0];
    let startMinutes = starttime.split(":")[1];
    let endHours = endtime.split(":")[0];
    let endMinutes = endtime.split(":")[1];
    if (startHours === "00") {
      startHours = "24";
    }
    if (endHours === "00") {
      endHours = "24";
    }

    let totalHours = Math.abs(parseFloat(endHours - startHours)).toFixed(2);
    let totalMinutes = (
      Math.abs(parseFloat(endMinutes - startMinutes)) / 60
    ).toFixed(2);
    let totalCombined = parseFloat(totalHours) + parseFloat(totalMinutes);
    let result = parseInt(total_days) * totalCombined;
    dispatch(
      changedatetime({
        name: "total_duration",
        value: result.toFixed(2),
      })
    );

    dispatch(
      changedatetime({
        name: "flag",
        value: false,
      })
    );
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    dispatch(
      changedatetime({
        name,
        value,
      })
    );

    //setDateOne({...dateOne, [name]: value})
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    dispatch(
      changedatetime({
        name,
        value,
      })
    );
    if (name === "endtime") {
      dispatch(
        changedatetime({
          name: "flag",
          value: true,
        })
      );
      let startHours = starttime.split(":")[0];
      let endHours = value.split(":")[0];
      if (startHours === "00") {
        startHours = "24";
      }
      if (endHours === "00") {
        endHours = "24";
      }

      let startMinutes = starttime.split(":")[1];
      let endMinutes = value.split(":")[1];
      let totalHours = parseFloat(endHours - startHours);
      let totalMinutes = parseFloat(endMinutes - startMinutes) / 60;
      let totalCombined = parseFloat(totalHours) + parseFloat(totalMinutes);
      if (totalCombined <= 0) {
        setNotValid(true);
      } else {
        setNotValid(false);
      }
    }
  };

  const handleWeekDuration = (e) => {
    const { name, value } = e.target;

    dispatch(
      changedatetime({
        name,
        value,
      })
    );

    let momentStart = moment(startdate);
    let momentEnd = moment(enddate);
    let total_weeks = Math.round(
      moment.duration(momentEnd.diff(momentStart)).asWeeks()
    );
    let total_days = moment.duration(momentEnd.diff(momentStart)).asDays();

    if (value.includes("Weekends")) {
      let i = 0;
      while (i !== total_weeks) {
        total_days = total_days - 5;
        i++;
      }
      dispatch(
        changedatetime({
          name: "total_days",
          value: total_days,
        })
      );
    } else if (value.includes("Weekdays")) {
      let i = 0;
      while (i !== total_weeks) {
        total_days = total_days - 2;
        i++;
      }
      dispatch(
        changedatetime({
          name: "total_days",
          value: total_days,
        })
      );
    } else {
      dispatch(
        changedatetime({
          name: "total_days",
          value: total_days,
        })
      );
    }
    dispatch(
      changedatetime({
        name: "flag",
        value: true,
      })
    );
  };

  const handleMonthlyDuration = (e) => {
    const { name, value } = e.target;

    dispatch(
      changedatetime({
        name,
        value,
      })
    );

    const days = value.split(" ")[0].toString();
    const id = value.split(" ")[1].toString();
    let startTime, endTime, startDate, endDate, total_days;
    startTime = starttime;
    endTime = endtime;
    startDate = startdate;
    endDate = enddate;

    let end_date;
    if (id === "month" || id === "months") {
      end_date = moment(startdate).add(days, "months").format("YYYY-MM-DD");
    } else {
      end_date = moment(startdate).add(days, "days").format("YYYY-MM-DD");
    }

    dispatch(
      changedatetime({
        name: "enddate",
        value: end_date,
      })
    );
    let momentStart = moment(startdate);
    let momentEnd = moment(end_date);
    let total_weeks = Math.round(
      moment.duration(momentEnd.diff(momentStart)).asWeeks()
    );
    total_days = moment.duration(momentEnd.diff(momentStart)).asDays();

    if (weekly_work_duration.includes("Weekends")) {
      let i = 0;
      while (i !== total_weeks) {
        total_days = total_days - 5;
        i++;
      }
      dispatch(
        changedatetime({
          name: "total_days",
          value: total_days,
        })
      );
    } else if (weekly_work_duration.includes("Weekdays")) {
      let i = 0;
      while (i !== total_weeks) {
        total_days = total_days - 2;
        i++;
      }
      dispatch(
        changedatetime({
          name: "total_days",
          value: total_days,
        })
      );
    } else {
      dispatch(
        changedatetime({
          name: "total_days",
          value: total_days,
        })
      );
    }

    dispatch(
      changedatetime({
        name: "flag",
        value: true,
      })
    );
  };

  return (
    <div className={classes.timeDiv}>
      {/* <p style={{ margin: "0", marginTop: "16px" }}>
        Please make sure that <b style={{ color: "red" }}>Start Time</b> is not
        coming after <b style={{ color: "red" }}>End Time</b> i.e:not( Start=2PM & End=1PM .
      </p> */}
      <p style={{ margin: "0", marginTop: "16px" }}>
        Please select start date, start, end time and monthly duration for each
        working day for your booking, you can book maximum 24 month (20+
        day/month). Total duration must be calculated using the 'Calculate total
        duration' before submitting this form.
      </p>
      <div
        style={{ padding: "0", alignItems: "flex-end", marginBottom: 10 }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            name="startdate"
            value={startdate}
            type="date"
            inputProps={{
              min: moment().format("YYYY-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          value={starttime}
          name="starttime"
          onChange={handleTimeChange}
          label="Start time each day"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 5px 0 5px",
          }}
        >
          <CustomSemanticDD
            disabled={!starttime || !startdate}
            name="monthly_duration"
            value={monthly_duration}
            onChange={handleMonthlyDuration}
            label="Monthly Duration"
            data={MONTHLY_DURATION}
          />
          <CustomSemanticDD
            disabled={!monthly_duration}
            name="weekly_work_duration"
            value={weekly_work_duration}
            onChange={handleWeekDuration}
            label="Weekly work days"
            data={WEEK_WORK_INTERPRETING_DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            name="enddate"
            value={moment(enddate).format("MM/DD/YYYY")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>

        <CustomTime
          name="endtime"
          value={endtime}
          label="End time each day"
          disabled={!monthly_duration}
          onChange={handleTimeChange}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {notValid && (
          <small style={{ color: "red" }}>
            <b> End time interval must be after start time interval***</b>
          </small>
        )}
      </div>
      <div>
        <Typography align="left" variant="h6" paragraph>
          Estimated Total day: {total_days}
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{ backgroundColor: "#7C277D" }}
            disabled={!endtime || !flag || notValid}
            onClick={handleClick}
            primary
          >
            Calculate total duration (hrs)
          </Button>
          <Typography variant="h4">&nbsp;{total_duration}</Typography>
        </div>
      </div>
    </div>
  );
};

export default MonthlyDuration;
