import React, { } from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useParams } from "react-router-dom";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import PrivateAssignment from "components/Employee/BookingPanel/Private/Translation/Canceled/List";
import InterpretingtOptions from "components/Employee/BookingPanel/Private/Translation/TranslationOptions";

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const { data, type } = useParams();

  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Canceled Assignments (Private)
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon onClick={() => history.goBack()} />
                </IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <InterpretingtOptions />
              </CardHeader>
            </Card>
            <Card>
              <CardBody>
                <PrivateAssignment translationData={type === "translation" && data}/>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default AssignmentPage;
