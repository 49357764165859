import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import AccountClientLayout from "layout/AccountClientLayout";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";

const ViewContactCard = () => {
  const { id } = useParams();
  const classes = useDashboardStyles();

  return (
    <Accountclient>
      <AccountClientLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Translation Delivery Document</p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center" className={classes.container}>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      {history.location.pathname.includes("account-client/view-translation-delivery-document1") ?
                        <object
                          data={`${process.env.REACT_APP_BACKEND_API}/for-linguists-admin/view_Translation_Delivery_Doc1/docID/${id}`}
                          type="application/pdf"
                          width="100%"
                          height="1000px"
                        ></object> :
                        history.location.pathname.includes("account-client/view-translation-delivery-document2") ?
                          <object
                            data={`${process.env.REACT_APP_BACKEND_API}/for-linguists-admin/view_Translation_Delivery_Doc2/docID/${id}`}
                            type="application/pdf"
                            width="100%"
                            height="1000px"
                          ></object> :
                          history.location.pathname.includes("account-client/view-translation-delivery-document3") ?
                            <object
                              data={`${process.env.REACT_APP_BACKEND_API}/for-linguists-admin/view_Translation_Delivery_Doc3/docID/${id}`}
                              type="application/pdf"
                              width="100%"
                              height="1000px"
                            ></object> :
                            history.location.pathname.includes("account-client/view-translation-delivery-document4") ?
                              <object
                                data={`${process.env.REACT_APP_BACKEND_API}/for-linguists-admin/view_Translation_Delivery_Doc4/docID/${id}`}
                                type="application/pdf"
                                width="100%"
                                height="1000px"
                              ></object> :
                              history.location.pathname.includes("account-client/view-translation-delivery-document5") ?
                                <object
                                  data={`${process.env.REACT_APP_BACKEND_API}/for-linguists-admin/view_Translation_Delivery_Doc5/docID/${id}`}
                                  type="application/pdf"
                                  width="100%"
                                  height="1000px"
                                ></object>
                                : null}
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default ViewContactCard;
