import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import {isAuth} from '../../../action/employeeaction';

const Private = ({children}) => {
    const history = useHistory();

    useEffect(()=>{
        if(!isAuth()){
            history.push("/employee-login");
        }else if(isAuth().role !== 3){
            history.push("/employee-login");
        }
    }, []);

return <React.Fragment>{children}</React.Fragment>
}

export default Private;