import React, { useState, useEffect } from "react";

import axios from "axios";
import Cookies from "js-cookie";

// Custom Styles
import moment from "moment";
import { Beenhere } from "@material-ui/icons";
import { Brightness1 } from "@material-ui/icons";

// Icons
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Button, Header } from "semantic-ui-react";
import Visibility from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

const NotificationList = () => {
  const [readloading, setreadLoading] = useState(false);
  const [deleteloading, setdeleteLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [count, setCount] = useState(0);
  const [values, setValues] = useState();
  const [read, setRead] = useState([]);

  const handleRead = async (id, type) => {
    setreadLoading(true);
    const token = Cookies.get("LINtoken");
    await axios.put(
      `${process.env.REACT_APP_BACKEND_API}/linguist/read-mark-single/notificationID/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    setreadLoading(false);
    handleCount();
  };

  const handleReadAll = async (id, type) => {
    setreadLoading(true);
    const token = Cookies.get("LINtoken");
    let i = 0;
    while (i !== count) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguist/read-mark/notificationID/${values[i]?._id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );
      i++;
      setreadLoading(false);
    }

    handleCount();
  };
  const handleDelete = async (id, type) => {
    setdeleteLoading(true);
    const token = Cookies.get("LINtoken");
    await axios.put(
      `${process.env.REACT_APP_BACKEND_API}/linguist/delete-notification/notificationID/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    setreadLoading(false);
    handleCount();
  };

  const handleDeleteAll = async (id, type) => {
    setreadLoading(true);
    const token = Cookies.get("LINtoken");
    let i = 0;
    while (i !== count) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguist/delete-notification/notificationID/${values[i]?._id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );
      i++;
      setreadLoading(false);
    }

    handleCount();
  };

  const handleCount = async () => {
    setFetching(true);
    const token = Cookies.get("LINtoken");
    const assignmentNotification = axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguist/get-all-offer-notification`,
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    const otherNotification = axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguist/get-all-other-notification`,
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    await axios.all([assignmentNotification, otherNotification]).then(
      axios.spread(function (res1, res2) {
        setCount(res1?.data.length + res2.data.length);
        setValues(res1?.data.concat(res2?.data));
        const temp = res1?.data.concat(res2?.data);
        let tempArr = [];
        temp.forEach((item) => {
          if (item?.isRead !== []) {
            item?.isRead.forEach((entry) => {
              entry === Cookies.get("linID") && tempArr.push(item.id);
            });
          }
        });
        setRead(tempArr);
      })
    );

    setFetching(false);
  };

  useEffect(() => {
    handleCount();
  }, []);

  return (
    <GridContainer style={{ justifyContent: "center" }}>
      <GridItem lg={6} md={6} sm={12}>
        <Card>
          <CardBody>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => handleReadAll()}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Visibility />
                Mark all as read
              </Button>
              <Button
                color="red"
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => handleDeleteAll()}
              >
                <DeleteIcon />
                Delete all
              </Button>
            </div>
          </CardBody>
        </Card>
        {values?.length > 0 ? (
          values?.map((list, i) => (
            <Card>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "block" }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      {!list?.lin_read &&
                        moment().diff(moment(list?.createdAt), "days") <= 0 && (
                          <Brightness1
                            color="primary"
                            fontSize="sm"
                            style={{ marginRight: 5 }}
                          />
                        )}
                      <Header as="h3">{list?.notification}</Header>
                    </div>
                    <Header as="h5">
                      {" "}
                      {moment(list?.createdAt).fromNow()}
                    </Header>
                  </div>
                  <div style={{ display: "flex" }}>
                    {!list?.lin_read ? (
                      <>
                        <Button
                          onClick={() => handleRead(list._id, list.id)}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Visibility />
                        </Button>
                        <Button
                          color="red"
                          onClick={() => handleDelete(list._id, list.id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          color="green"
                          disabled
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Beenhere style={{ color: "white" }} />
                        </Button>
                        <Button
                          color="red"
                          onClick={() => handleDelete(list._id, list.id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          ))
        ) : (
          <Header as="h3" textAlign="center">
            nothing to show
          </Header>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default NotificationList;
