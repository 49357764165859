import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { changeCrInput } from "redux/actions/crAction";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPhone from "components/Common/CustomPhone";
import Axios from "utils/axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
import { EMAIL_REGEX } from "data";

const FormTwo = () => {
  const token = Cookies.get("BACtoken");
  const { data } = useSelector((state) => state.crReducer);
  const [notValid, setNotValid] = useState(false);

  const {
    title,
    FirstName,
    LastName,
    business_name,
    business_phone_no,
    contact_group,
    email,
    mobile_no,
    City,
    note,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();
  const [addContact, setAddContact] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeCrInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handlePhone = (value) => {
    dispatch(
      changeCrInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeCrInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handleContactGroup = (e) => {
    const inputData = {
      name: "contact_group",
      value: e.target.innerText,
    };
    dispatch(changeCrInput(inputData));
    if (inputData.value === "Other") setAddContact(true);
  };

  const handleNext = async (e) => {
    let formData = new FormData();
    formData.append("title", title || "-");
    formData.append("FirstName", FirstName || "-");
    formData.append("LastName", LastName || "-");
    formData.append("City", City || "-");
    formData.append("business_name", business_name || "-");
    formData.append("business_phone_no", business_phone_no || "-");
    formData.append("contact_group", contact_group || "-");
    formData.append("email", email || "-");
    formData.append("mobile_no", mobile_no || "-");
    formData.append("note", note || "-");

    try {
      const response = await Axios.post(
        "/api/accountclient/create/CLA",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `Sent!`,
        icon: "success",
        buttons: "Ok",
      }).then(() => {
        window.location = "/account-client/recommend-lc-bac";
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const [values, setValues] = useState({
    list: [],
    reload: false,
  });

  const { list, reload } = values;
  const [contact, setContact] = useState([]);

  useEffect(() => {
    loadList();
  }, [reload]);

  const loadList = async () => {
    try {
      const response = await Axios.get(`/api/list/cla-contact-group`);
      setContact(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const CONTACT_GROUP = contact?.map(
    (CONTACT) => `${CONTACT.contact_group_name}`
  );

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        value={FirstName}
        name="FirstName"
        label="First Name(s)"
        width="100%"
        required
        placeholder="First Name(s)/Forename/Given Names of your friend/colleague"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={LastName}
        name="LastName"
        label="Last Name"
        width="100%"
        required
        placeholder="Last Name/Surname/Family Name of your friend/colleague"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={business_name}
        name="business_name"
        label="Business or Organisation Name"
        width="100%"
        placeholder="Trading name of your friend/colleague business / organisation. Otherwise please write N/A"
      />
      <CustomInputSingle
        onChange={handleEmailChange}
        value={email}
        name="email"
        label="Primary / Business email"
        width="100%"
        required
        placeholder="Friend/colleague email address e.g. example@example.com"
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomAutoSelect
        onChange={(e) => handleContactGroup(e)}
        value={contact_group}
        name="contact_group"
        label="Industry"
        width="100%"
        data={CONTACT_GROUP}
      />{" "}
      <br />
      <br />
      <CustomPhone
        label="Mobile Number / Direct Contact Number"
        onChange={handleMobile}
        placeholder="Mobile number / Direct contact number *"
        value={mobile_no}
        name="mobile_no"
        required
        placeholder="Mobile number of your friend/colleague (e.g. +44 7307 000000)"
      />
      <br />
      <CustomPhone
        label="Business Phone Number"
        onChange={handlePhone}
        placeholder="Business phone number"
        value={business_phone_no}
        name="business_phone_no"
        placeholder="Business Phone Number (Please select country then type number e.g. +44 7307 000000)"
      />
      <CustomInputSingle
        onChange={handleChange}
        label="Town / City"
        width="100%"
        name="City"
        value={City}
        placeholder="Your friend/colleague Village, Town or City Name"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={note}
        name="note"
        label="Your Message"
        width="100%"
        placeholder="Write your invitation message here..."
      />
      <div className={classes.buttonFlex}>
        <CustomButton
          disabled={!FirstName || !LastName || !email || !mobile_no}
          text="Submit"
          onClick={handleNext}
        />
      </div>
    </form>
  );
};

export default FormTwo;
