import React, { useState, useEffect } from "react";
import Sppo from "components/RouteProtection/Sppo/Sppo";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SppoLayout from "layout/SppoLayout";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import QuoteOptions from "components/Sppo/Quotation/QuoteOptions";
import CreateInvoice from "components/Sppo/DirectInvoice/CreateInvoice";
import DirectInvoiceOptions from "components/Sppo/DirectInvoice/DirectInvoiceOptions";
import Cookies from 'js-cookie'
import Axios from 'axios'
import { Header } from "semantic-ui-react";




const ProvideDirectQuote = () => {
    const classes = useDashboardStyles();

    const [sppoID, setSPPOID] = useState(null)

    const getDetails = async () => {
        const token = Cookies.get("SPPOtoken");
        const response = await Axios.get(
            `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        setSPPOID(response?.data);
    };
    useEffect(() => {
        getDetails();
    }, []);



    return (
        <SppoLayout>
            <Sppo>
                <GridContainer>
                    {sppoID?.agent_sppo && sppoID?.direct_quote_invoice_access ?
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader
                                    color="primary"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <p className={classes.cardTitleWhite}>Direct Invoice</p>
                                    <IconButton color="inherit">
                                        <ArrowBackIcon
                                            onClick={() => history.goBack()}
                                        ></ArrowBackIcon>
                                    </IconButton>
                                </CardHeader>
                            </Card>
                            <Card>
                                <CardHeader
                                    color="primary"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <QuoteOptions />
                                </CardHeader>
                                <CardBody>
                                    <DirectInvoiceOptions />
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CreateInvoice />
                                </CardBody>
                            </Card>
                        </GridItem>
                        : sppoID?.profile_update ?
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardHeader
                                        color="primary"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p className={classes.cardTitleWhite}>Direct Invoice</p>
                                        <IconButton color="inherit">
                                            <ArrowBackIcon
                                                onClick={() => history.goBack()}
                                            ></ArrowBackIcon>
                                        </IconButton>
                                    </CardHeader>
                                </Card>
                                <Card>
                                    <CardHeader
                                        color="primary"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <QuoteOptions />
                                    </CardHeader>
                                    <CardBody>
                                        <DirectInvoiceOptions />
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <CreateInvoice />
                                    </CardBody>
                                </Card>
                            </GridItem>
                            :
                            sppoID?.agent_sppo && sppoID?.profile_update === false ?
                                <Header as='h3'>Access denied.</Header>
                                :
                                sppoID?.profile_update === false ?
                                    <Header as='h3'>Access denied. <a href={`/sppo/full-registration`}>Please complete full registration.</a></Header>
                                    :
                                    <Header as='h3'>Loading...</Header>
                    }
                </GridContainer>
            </Sppo>
        </SppoLayout>
    );
};

export default ProvideDirectQuote;
