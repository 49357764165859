// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";

//Custom styles
//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import LinguistLayout from "layout/LinguistLayout";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CreateQuotationBAC from "components/Linguist/Quotation/CreateQuotationBAC/CreateQuotationBAC";

const CreateQuoteBACPage = () => {
  const classes = useDashboardStyles();
  const { id } = useParams();
  const history = useHistory();

  return (
    <LinguistLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader
              style={{ display: "flex", justifyContent: "space-between" }}
              color="primary"
            >
              <p className={classes.cardTitleWhite}>Create Quote</p>
              <IconButton color="inherit">
                <ArrowBackIcon
                  style={{ marginLeft: 5 }}
                  onClick={() => history.goBack()}
                ></ArrowBackIcon>
              </IconButton>
            </CardHeader>
            <CardBody>
              <CreateQuotationBAC id={id} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LinguistLayout>
  );
};

export default CreateQuoteBACPage;
