import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import React, { useEffect, useState } from "react";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import defaultAxios from "utils/axios";
import swal from "sweetalert";
import Cookies from "js-cookie";

const JASummary = () => {
  const classes = useEmployeeStyles();

  const [data, setData] = useState({
    account_holder: "",
    institution_number: "",
    account_number: "",
    transit_number: "",
  });

  const { account_holder, institution_number, account_number, transit_number } =
    data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const isAllow =
    !data.account_holder ||
    !data.account_number ||
    !data.institution_number ||
    !data.transit_number;

  const handleSubmit = async (e) => {
    const token = Cookies.get("token");

    const sendData = {
      account_holder: data.account_holder || "-",
      institution_number: data.institution_number || "-",
      account_number: data.account_number || "-",
      transit_number: data.transit_number || "-",
    };
    try {
      const response = await defaultAxios.post(
        "/api/manager-admin/add-CADAccount",
        sendData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: "Canadian Doller Account Successfully Updated",
        icon: "success",
        buttons: false,
      });
      getBankDetails();
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const getBankDetails = async (e) => {
    const response = await defaultAxios.get(
      "/api/manager-admin/list-CADAccount"
    );
    setData(response?.data[0]);
  };

  useEffect(() => {
    getBankDetails();
  }, []);

  return (
    <form
      className={classes.form}
      noValidate
      autoComplete="off"
      style={{ width: "100%" }}
    >
      <CustomInput
        onChange={handleChange}
        label="Account Hoder"
        name="account_holder"
        value={data.account_holder}
        width="100%"
      />

      <CustomInput
        onChange={handleChange}
        label="Institution Number"
        name="institution_number"
        value={data.institution_number}
        width="100%"
      />

      <CustomInput
        onChange={handleChange}
        label="Account Number"
        name="account_number"
        value={data.account_number}
        width="100%"
      />

      <CustomInput
        onChange={handleChange}
        label="Transit Number"
        name="transit_number"
        value={data.transit_number}
        width="100%"
      />

      <div className={classes.buttonFlex}>
        <CustomButton disabled={isAllow} text="Update" onClick={handleSubmit} />
      </div>
    </form>
  );
};

export default JASummary;
