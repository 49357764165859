import React from 'react'


// Material Core Components

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Details from './Details'
import { Header } from 'semantic-ui-react';

const ProjectReportPRC = () => {
    const classes = useEmployeeStyles();
    return (
        <div className={classes.root}>
            <GridContainer justify="center" className={classes.container}>
                <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card>
                        <CardBody>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomAvatar />
                            </div>
                            <div className={`${classes.textCenter} ${classes.title}`}>
                                <Header style={{ margin: '0' }} as='h3'>Linguists Collective Ltd</Header>
                                <Header style={{ margin: '0' }} as='h3'>Interpreting Assignment Project Report (Linguists / Professionals)</Header>
                            </div>
                            <Details />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default ProjectReportPRC
