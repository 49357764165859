import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import SearchView from "./Search/AcceptedSearch/SearchQuote";
import Cookies from "js-cookie";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import Action from "./Action";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const ViewClientInhouse = ({ searchChat }) => {
  const id = Cookies.get("linID");
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    inhouseClients: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, inhouseClients, removed, reload } = values;

  const loadVerifiedLinguistList = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/private-accepted-quote-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues({ ...values, inhouseClients: response?.data });
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    loadVerifiedLinguistList();
  }, [reload]);

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/private-accepted-quote-Find/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct quote id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({
          ...values,
          inhouseClients: [response?.data],
          reload: false,
        });
        // inhouseClients=[response?.data]
        addToast("Quote found", { appearance: "success" });
      } else {
        addToast("Quote not found", { appearance: "error" });
      }
      if (values.inhouseClients) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct quote id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <div>
      <SearchView
        load={loadVerifiedLinguistList}
        handleSearch={handleSearch}
        setSearchText={setSearchText}
        SearchText={SearchText}
        setShow={setShow}
        show={show}
      />
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Quote ID</TableCell>
                    <TableCell>Client ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell>Quote Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inhouseClients
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((list) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={list._id}
                      >
                        <TableCell key={list._id} align={list.align}>
                          <Link
                            to={`/linguist/view-prc-direct-quote/${list._id}`}
                          >
                            {list.id}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {/* <Link to={`/linguist/view-details-quote/${list._id}`}> */}
                          {list?.privateClientID?.id}
                          {/* </Link> */}
                        </TableCell>
                        <TableCell>{list.privateClientID.title}</TableCell>
                        <TableCell>{list.privateClientID.FirstName}</TableCell>
                        <TableCell>{list.privateClientID.LastName}</TableCell>
                        <TableCell>{list.privateClientID.email}</TableCell>
                        <TableCell>
                          {Moment(list.createdAt).format(
                            "DD MMM YYYY - kk:mm:ss"
                          )}
                        </TableCell>
                        <TableCell>{list.status}</TableCell>
                        <TableCell>
                          <Action id={list._id} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={inhouseClients.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
};

export default ViewClientInhouse;
