import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";
import { useHistory } from "react-router-dom";
// Materials Core
import Button from "@material-ui/core/Button";
import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import LINPassportDoc from "components/Admin/ControlPanel/Backup/LIN/LINPassportDoc";
import ProfessionalMembership from "components/Admin/ControlPanel/Backup/LIN/ProfessionaMembership";
import SecurityClearance from "components/Admin/ControlPanel/Backup/LIN/SecurityClearance";
import EducationalQualification from "components/Admin/ControlPanel/Backup/LIN/EducationalQualification";

import CorporateMembership from "components/Admin/ControlPanel/Backup/SPPO/CorporateMembership";
import Certifications from "components/Admin/ControlPanel/Backup/SPPO/Certifications";
import SPPOUserList from "components/Admin/ControlPanel/Backup/SPPO/SPPOUserList";

import BACUserList from "components/Admin/ControlPanel/Backup/BAC/BACUserList";

import AssignmentDocRequestList from "components/Admin/ControlPanel/Backup/ADM/AssignmentDocRequestList";
import AssignmentDocList from "components/Admin/ControlPanel/Backup/ADM/AssignmentDocList";

import CACRequestList from "components/Admin/ControlPanel/Backup/ADM/CACRequestList";
import BACRequestList from "components/Admin/ControlPanel/Backup/ADM/BACRequestList";
import ADMRequestList from "components/Admin/ControlPanel/Backup/ADM/ADMRequestList";
import EMPRequestList from "components/Admin/ControlPanel/Backup/ADM/EMPRequestList";
import LeadRequestList from "components/Admin/ControlPanel/Backup/ADM/LeadRequestList";
import JobList from "components/Admin/ControlPanel/Backup/ADM/JobList";
import Timesheet from "components/Admin/ControlPanel/Backup/ADM/Timesheet";
import ProjectReport from "components/Admin/ControlPanel/Backup/ADM/ProjectReport";


const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const history = useHistory();
  const [control_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAdminAccess(response?.data.control_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  },[]);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {control_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className={classes.cardTitleWhite}>
                    {history.location.pathname.includes("admin/view-linguist-all-passport-document") ?
                      "Linguist Passport Document" :
                      history.location.pathname.includes("admin/view-linguist-all-public-professional-membership") ?
                        "Linguist Professional Membership For Public Profile" :
                        history.location.pathname.includes("admin/view-linguist-all-public-security-clearance") ?
                          "Linguist Security Clearance For Public Profile" :
                          history.location.pathname.includes("admin/view-linguist-all-public-educational-qualification") ?
                            "Linguist Educational Qualification For Public Profile" :
                            history.location.pathname.includes("admin/view-sppo-all-public-corporate-membership") ?
                              "SPPO Corporate Membership For Public Profile" :
                              history.location.pathname.includes("admin/view-sppo-all-public-certifications") ?
                                "SPPO Certifications For Public Profile" :
                                history.location.pathname.includes("admin/sent-sppo-user-requests") ?
                                  "SPPO User Request List" :
                                  history.location.pathname.includes("admin/sent-bac-user-requests") ?
                                    "BAC User Request List" :
                                    history.location.pathname.includes("admin/all-documents-of-assignments") ?
                                      "List Of All Assignment Documents" :
                                      history.location.pathname.includes("admin/assignment-document-requests") ?
                                        "List Of All Document Requests Of Assignment" :
                                        history.location.pathname.includes("admin/registration-requests-CAC") ?
                                          "Registration Requests Of CAC" :
                                          history.location.pathname.includes("admin/registration-requests-BAC") ?
                                            "Registration Requests Of BAC" :
                                            history.location.pathname.includes("admin/registration-requests-ADM") ?
                                              "Registration Requests Of ADM" :
                                              history.location.pathname.includes("admin/registration-requests-EMP") ?
                                                "Registration Requests Of EMP" :
                                                history.location.pathname.includes("admin/registration-requests-Lead") ?
                                                  "Registration Requests Of Lead" :
                                                  history.location.pathname.includes("admin/all-inactive-jobadvert") ?
                                                    "List Of All Inactive Job Advert" :
                                                    history.location.pathname.includes("admin/list-all-close-and-timesheet") ?
                                                      "List Of All Close and Timesheet" :
                                                      history.location.pathname.includes("admin/list-all-project-report") ?
                                                        "List Of All Project Report" : null}
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>

                    <Button style={{ color: "white" }} variant="outlined" onClick={() => history.push("/admin/backup")}>
                      Backup
                    </Button>

                    <IconButton
                      color="inherit"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                </CardHeader>
              </Card>
              <Card>
                <CardBody>
                  {history.location.pathname.includes("admin/view-linguist-all-passport-document") ?
                    <LINPassportDoc /> :
                    history.location.pathname.includes("admin/view-linguist-all-public-professional-membership") ?
                      <ProfessionalMembership /> :
                      history.location.pathname.includes("admin/view-linguist-all-public-security-clearance") ?
                        <SecurityClearance /> :
                        history.location.pathname.includes("admin/view-linguist-all-public-educational-qualification") ?
                          <EducationalQualification /> :
                          history.location.pathname.includes("admin/view-sppo-all-public-corporate-membership") ?
                            <CorporateMembership /> :
                            history.location.pathname.includes("admin/view-sppo-all-public-certifications") ?
                              <Certifications /> :
                              history.location.pathname.includes("admin/sent-sppo-user-requests") ?
                                <SPPOUserList /> :
                                history.location.pathname.includes("admin/sent-bac-user-requests") ?
                                  <BACUserList /> :
                                  history.location.pathname.includes("admin/all-documents-of-assignments") ?
                                    <AssignmentDocList /> :
                                    history.location.pathname.includes("admin/assignment-document-requests") ?
                                      <AssignmentDocRequestList /> :
                                      history.location.pathname.includes("admin/registration-requests-CAC") ?
                                        <CACRequestList /> :
                                        history.location.pathname.includes("admin/registration-requests-BAC") ?
                                          <BACRequestList /> :
                                          history.location.pathname.includes("admin/registration-requests-ADM") ?
                                            <ADMRequestList /> :
                                            history.location.pathname.includes("admin/registration-requests-EMP") ?
                                              <EMPRequestList /> :
                                              history.location.pathname.includes("admin/registration-requests-Lead") ?
                                                <LeadRequestList /> :
                                                history.location.pathname.includes("admin/all-inactive-jobadvert") ?
                                                  <JobList /> :
                                                  history.location.pathname.includes("admin/list-all-close-and-timesheet") ?
                                                    <Timesheet /> :
                                                    history.location.pathname.includes("admin/list-all-project-report") ?
                                                      <ProjectReport /> : null}
                </CardBody>
              </Card>
            </GridItem>
          ) : control_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
