import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

//Material icons
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

// import Details from './Details'
import { Button, Dimmer, Header, Loader, Statistic, Table } from "semantic-ui-react";
import { CURRENCY, TAXIFARE, TYPE_INTERPRETING } from "data";
import { IconButton, Input } from "@material-ui/core";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import Axios from "axios";
import Cookies from 'js-cookie'
import moment from 'moment'
import swal from "sweetalert";
import { addOtherTransaction, addTransaction, deleteOtherTransaction, deleteTransaction } from "redux/actions/expenseAction";

// import jsPDF from "jspdf";
// import Stripe from 'stripe';

const QuoteInvoice = () => {
    const { id } = useParams()
    const classes = useEmployeeStyles();
    const [] = useState([0])
    const history = useHistory();
    const token = Cookies.get('LINtoken')
    const [invoice, setInvoice] = useState(null)
    const [linguist, setLinguist] = useState(null)
    const [totalToPaid, setTotalToPaid] = useState(null)

    const [privateClient, setPrivateClient] = useState({
        id: '',
        data: ''
    })
    const [] = useState(null)
    const [] = useState('')
    const [service] = useState('')
    const [] = useState(null)
    const [calculate, setCalculate] = useState({
        sendButton: false,
        loading: false,
        quoteId: ''
    })

    //   1st subtotal
    const [text, setText] = useState('');
    const [amount, setAmount] = useState(0);
    const [quantity, setQuantity] = useState(0)

    //   2nd subtotal
    const [otherAmount, setOtherAmount] = useState(0);
    const [otherText, setOtherText] = useState('');
    const [otherQuantity, setOtherQuantity] = useState(0)

    const dispatch = useDispatch()

    const { transactions } = useSelector(state => state.expenseReducer)
    const amountsOne = transactions.map(transaction => transaction.total)
    const subTotalOne = (Number(amountsOne.reduce((acc, item) => (acc += item), 0)) + Number(invoice?.linQuoteID.subtotal1)).toFixed(2)

    const { otherTransactions } = useSelector(state => state.expenseReducer)
    const amountsTwo = otherTransactions.map(transaction => transaction.total);
    const subTotalTwo = (Number(amountsTwo.reduce((acc, item) => (acc += item), 0)) + Number(invoice?.linQuoteID.subtotal2)).toFixed(2)

    const [] = useState(0)
    const [] = useState(0)

    const [] = useState({
        price: 0,
        quantity: 0
    })

    const [companyCard, setCompanyCard] = useState({
        basc: '',
        pbsc: '',
        cname: '',
        caddress: ''
    })
    //  const totalIntr = totalFTF.price * totalFTF.quantity

    const total = (Number(subTotalOne) + Number(subTotalTwo)).toFixed(2)


    //Total after discount
    const totalDiscount = (Number(total) * invoice?.linQuoteID.discount_percentage / 100).toFixed(2)

    //Including platform and booking service charge
    const totalPBSC = ((Number(total) - Number(totalDiscount)) * Number(invoice?.linQuoteID.service_charge) / 100).toFixed(2);

    //Total VAT
    const totalVAT = (Number(Number(total) + Number(totalPBSC) - Number(totalDiscount)) * (invoice?.linQuoteID.vat_percentage / 100)).toFixed(2)

    //Estimate paid

    //Total after including vat and discount
    const totalTwo = (Number(total) + Number(totalPBSC) - Number(totalDiscount) + Number(totalVAT)).toFixed(2)

    //To be paid including vat

    //   const totalFTF = invoice ? invoice?.labelFive * invoice?.duration : null

    const handleAdd = e => {
        e.preventDefault();

        const newTransaction = {
            id: Math.floor(Math.random() * 100000000),
            text,
            amount: +amount,
            quantity,
            total: amount * quantity
        }

        dispatch(addTransaction(newTransaction));
        setText('')
        setAmount('')
        setQuantity('')
    }

    const handleAddOther = e => {
        e.preventDefault();
        const newTransaction = {
            id: Math.floor(Math.random() * 100000000),
            otherText,
            otherAmount: +otherAmount,
            otherQuantity,
            total: otherAmount * otherQuantity
        }

        dispatch(addOtherTransaction(newTransaction))
        setOtherText('')
        setOtherAmount('')
        setOtherQuantity('')
    }

    useEffect(() => {
        let source = Axios.CancelToken.source()

        const getLinguistProfile = async () => {
            try {
                const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setLinguist(response?.data)
            } catch (error) {
                console.log(error)
            }
        }

        const getCompanyCard = async () => {
            try {
                const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/manager-admin/view/company-information-card`)
                setCompanyCard({ ...companyCard, basc: response?.data[0].getCompanyCard, pbsc: response?.data[0].platform_booking_service_charge, basc: response?.data[0].billing_account_service_charge, cname: response?.data[0].company_name, caddress: response?.data[0].company_address })
            } catch (error) {
                console.log(error)
            }
        }

        const getInvoiceDetails = async () => {
            try {
                const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/linguists/view-timesheet/cashclient/timesheetID/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                
                setInvoice(response?.data)
            } catch (error) {
                console.log(error)
            }
        }

        getLinguistProfile();
        getCompanyCard();
        getInvoiceDetails();

        return () => {
            source.cancel();
        }
    }, [])

    const handleSend = async () => {
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/linguists/send-quote-invoice/cashclient/invoiceID/${calculate.quoteId}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                text: 'Invoice successfully send to client',
                icon: 'success',
                button: 'Ok'
            })
            history.push('/linguist/dashboard')
            
        } catch (error) {
            console.log(error)
        }
    }


    const handleCalculate = async () => {
        setCalculate({ ...calculate, loading: true })
        try {
            const response = await Axios.post(`${process.env.REACT_APP_BACKEND_API}/linguists/create-quote-invoice/cashclient/timesheetID/${id}`, {
                date_of_quote: invoice?.linQuoteID.date_of_quote,
                date_of_invoice: '',
                service_completion_date: invoice.linQuoteID.service_completion_date,
                linguist_ref: invoice.linQuoteID.linguist_ref,
                currency: invoice.linQuoteID.currency,
                transactions: invoice.linQuoteID.productOne.concat(transactions),
                otherTransactions: invoice.linQuoteID.productTwo.concat(otherTransactions),
                subtotal1: subTotalOne,
                subtotal2: subTotalTwo,
                service_charge: invoice.linQuoteID.service_charge,
                discount_percentage: invoice.linQuoteID.discount_percentage,
                discount_amount: totalDiscount,
                vat_percentage: invoice.linQuoteID.vat_percentage,
                vat_amount: totalVAT,
                grand_total: totalToPaid,
                deposit: '',
                due: '',
                service,
                linguist_note: '',
                admin_note: '',
                client_note: ''
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                text: 'Invoice successfully saved',
                icon: 'success',
                button: 'Ok'
            })
            const calc = (Number(totalTwo) - Number(invoice.linQuoteID.grand_total)).toFixed(2)
            setTotalToPaid(calc)
            setCalculate({ ...calculate, loading: false, sendButton: true, quoteId: response?.data._id })
            
        } catch (error) {
            console.log(error)
        }
    }

    return (
        linguist && companyCard && invoice ?
            <div className={classes.root}>
                <div id="pdf"></div>
                <GridContainer justify="center" className={classes.container}>
                    <GridItem id='printArea' xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card >
                            <CardBody>
                                {/* Avatar and company name */}
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '24px' }}>

                                    <div style={{ display: 'flex' }}>
                                        <CustomAvatar style={{ margin: '0' }} />
                                    </div>
                                </div>

                                {/* First div  */}
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0 5px 0 0' }} as='h4'>Invoice to:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.postedBy.id}</Header>
                                        </div>
                                        {invoice.linQuoteID.quoteRequstByCashclient.postedBy.client === 'privateclient' &&
                                            <>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>Name of the company:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;N/A</Header>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>Name of the person:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.postedBy.FirstName}</Header>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>House number/ name, Street name:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.postedBy.AddressLine1}</Header>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>Address Line Two:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.postedBy.AddressLine2}</Header>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>Region:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.postedBy.Region}</Header>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>City:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.postedBy.City}</Header>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>Postal Code:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.postedBy.PostalCode}</Header>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>Country:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.postedBy.Country}</Header>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>Email:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.postedBy.email}</Header>
                                                </div>
                                            </>
                                        }

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Invoice number:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.id}</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Linguist/SP ID number:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{linguist.id}</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Client quote request ref no:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.id}</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Linguist quote request ref no:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.id}</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Linguist Residence:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{linguist.Country}</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>VAT/GST no:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{linguist.vat_number}</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Date of quote:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{moment(invoice.linQuoteID.date_of_quote).format('DD/MM/YYYY')}</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Date of invoice:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{moment().format('DD/MM/YYYY')}</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Invoice to be paid by:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.expiry_date ? moment(invoice.linQuoteID.expiry_date).format('DD/MM/YYYY HH:mm') : null}</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Client reference:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;N/A</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Service completion date:&nbsp;</Header><Header style={{ margin: '0' }} as='h4' color='grey'>{invoice.linQuoteID.service_completion_date}</Header>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                                            <Header style={{ margin: '0' }} as='h4'>Linguist/SP ref:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.linguist_ref}</Header>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Header style={{ margin: '0' }} as='h4'>Service:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.quote_rqst_for}</Header>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Header style={{ margin: '0' }} as='h4'>Source language:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.source_language}</Header>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Header style={{ margin: '0' }} as='h4'>Target language:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linQuoteID.quoteRequstByCashclient.target_language}</Header>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Header style={{ margin: '0' }} as='h4'>Linguist's expertise level:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{linguist.target_language_level}</Header>
                                    </div>
                                </div>

                                <Table color='pink'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width='5'></Table.HeaderCell>
                                            <Table.HeaderCell width='1'></Table.HeaderCell>
                                            <Table.HeaderCell width='1'></Table.HeaderCell>
                                            <Table.HeaderCell width='1'><CustomAutoSelect label='Currency' data={CURRENCY} value='GBP (UK)' /></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell width='5'>Description of service</Table.HeaderCell>
                                            <Table.HeaderCell width='1'>Price £00.00</Table.HeaderCell>
                                            <Table.HeaderCell width='1'>Quantity 00.00</Table.HeaderCell>
                                            <Table.HeaderCell width='1'>Total</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {invoice.linQuoteID.productOne.map(tr => (
                                            <Table.Row>
                                                <Table.Cell><Header as='h4'>{tr.text}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.amount}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.quantity}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.total}</Header></Table.Cell>
                                            </Table.Row>
                                        ))}
                                        {transactions.map(tr => (
                                            <Table.Row>
                                                <Table.Cell><Header as='h4'>{tr.text}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.amount}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.quantity}</Header></Table.Cell>
                                                <Table.Cell>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Header style={{ margin: '0' }} as='h4'>{tr.total}</Header>
                                                        <IconButton onClick={() => dispatch(deleteTransaction(tr.id))}>
                                                            <RemoveCircleOutlineIcon />
                                                        </IconButton>
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                        <Table.Row>
                                            <Table.Cell><CustomAutoSelect onChange={(e, value) => setText(value)} value={text} label='Choose' data={TYPE_INTERPRETING} /></Table.Cell>
                                            <Table.Cell><Input onChange={(e) => setAmount(e.target.value)} value={amount} placeholder='£ 00.00' style={{ margin: '24px auto 0' }} type='number' /></Table.Cell>
                                            <Table.Cell><Input onChange={(e) => setQuantity(e.target.value)} value={quantity} style={{ margin: '24px auto 0' }} type='number' /></Table.Cell>
                                            <Table.Cell>
                                                <IconButton onClick={handleAdd}>
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign><Header textAlign='right' as='h3'>Sub Total</Header></Table.Cell>
                                            <Table.Cell>
                                                <Statistic size='mini'>
                                                    <Statistic.Value>£ {Number(subTotalOne)}</Statistic.Value>
                                                </Statistic>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row style={{ backgroundColor: '#F9FAFB' }}>
                                            <Table.Cell>Expenses and travel</Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                        </Table.Row>
                                        {invoice.linQuoteID.productTwo.map(tr => (
                                            <Table.Row>
                                                <Table.Cell><Header as='h4'>{tr.otherText}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.otherAmount}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.otherQuantity}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.total}</Header></Table.Cell>
                                            </Table.Row>
                                        ))}
                                        {otherTransactions.map(tr => (
                                            <Table.Row>
                                                <Table.Cell><Header as='h4'>{tr.otherText}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.otherAmount}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.otherQuantity}</Header></Table.Cell>
                                                <Table.Cell >
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Header style={{ margin: '0' }} as='h4'>{tr.total}</Header>
                                                        <IconButton onClick={() => dispatch(deleteOtherTransaction(tr.id))}>
                                                            <RemoveCircleOutlineIcon />
                                                        </IconButton>
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                        <Table.Row>
                                            <Table.Cell><CustomAutoSelect onChange={(e, value) => setOtherText(value)} value={otherText} label='Taxi fare' data={TAXIFARE} /></Table.Cell>
                                            <Table.Cell><Input onChange={(e) => setOtherAmount(e.target.value)} value={otherAmount} placeholder='£ 00.00' style={{ margin: '24px auto 0' }} type='number' /></Table.Cell>
                                            <Table.Cell><Input onChange={(e) => setOtherQuantity(e.target.value)} value={otherQuantity} style={{ margin: '24px auto 0' }} type='number' /></Table.Cell>
                                            <Table.Cell><IconButton onClick={handleAddOther}><AddCircleOutlineIcon /></IconButton></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign><Header as='h3'>Sub Total</Header></Table.Cell>
                                            <Table.Cell>
                                                <Statistic size='mini'>
                                                    <Statistic.Value>£ {Number(subTotalTwo)}</Statistic.Value>
                                                </Statistic>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign><Header as='h4'>Total</Header></Table.Cell>
                                            <Table.Cell>
                                                <Statistic size='mini'>
                                                    <Statistic.Value>£ {total}</Statistic.Value>
                                                </Statistic>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign><Header as='h4'>Discount {invoice.linQuoteID.discount_percentage}%</Header></Table.Cell>
                                            <Table.Cell>
                                                <Statistic size='mini'>
                                                    <Statistic.Value>{totalDiscount}</Statistic.Value>
                                                </Statistic>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign><Header as='h4'>Billing Account Service Charge {companyCard?.basc} %</Header></Table.Cell>
                                            <Table.Cell>
                                                <Statistic size='mini'>
                                                    <Statistic.Value>00.00</Statistic.Value>
                                                </Statistic>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign>
                                                <Header as='h4'>Platform and booking service charge  {invoice.linQuoteID.service_charge}%</Header>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Statistic size='mini'>
                                                    <Statistic.Value>{totalPBSC}</Statistic.Value>
                                                </Statistic>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign><Header as='h4'>VAT {invoice.linQuoteID.vat_percentage}%</Header></Table.Cell>
                                            <Table.Cell>
                                                <Statistic size='mini'>
                                                    <Statistic.Value>{totalVAT}</Statistic.Value>
                                                </Statistic>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign><Header as='h4'>Total</Header></Table.Cell>
                                            <Table.Cell>
                                                <Statistic size='mini'>
                                                    <Statistic.Value>{totalTwo}</Statistic.Value>
                                                </Statistic>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign><Header as='h4'>Estimate paid in advance</Header></Table.Cell>
                                            <Table.Cell>
                                                <Statistic size='mini'>
                                                    <Statistic.Value>{invoice.linQuoteID.grand_total}</Statistic.Value>
                                                </Statistic>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell textAlign><Header as='h4'>Total to be paid including VAT/GST</Header></Table.Cell>
                                            <Table.Cell>
                                                <Statistic size='mini'>
                                                    <Statistic.Value>£ {totalToPaid}</Statistic.Value>
                                                </Statistic>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>

                                <div style={{ marginBottom: '24px' }}>
                                    <Header as='h4'>{invoice.linQuoteID.quoteRequstByCashclient.timezone}</Header>
                                    <Header as='h4'>{moment(invoice.linQuoteID.expiry_date).format('DD/MM/YYYY HH:mm')}</Header>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Header style={{ marginTop: '16px' }} as='h4'>Thank You For Your Business</Header>
                                    <Header color='grey' style={{ marginBottom: '16px', marginTop: '0' }} as='h4'>{companyCard.cname + ", " + companyCard.caddress}</Header>
                                    {!calculate.sendButton && <Button onClick={handleCalculate} loading={calculate.loading} color='purple'>Calculate and Save</Button>}
                                    {calculate.sendButton && <Button onClick={handleSend} color='purple'>Send</Button>}
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
            :
            <Dimmer active inverted>
                <Loader size='medium'>Loading</Loader>
            </Dimmer>
    );
};

export default QuoteInvoice;
