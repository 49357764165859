import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { getSupportSystem } from "../../../../../action/linguistAction";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = () => {
    getSupportSystem().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setValues({ ...values, bookingList: data });
      }
    });
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Manager / personnel name</TableCell>
                <TableCell>{list.complience_name}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Direct Dial</TableCell>
                <TableCell>{list.complience_directdial}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Compliance Officer</TableCell>
                <TableCell>{list.complience_officer}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Documentation And Verification Officer</TableCell>
                <TableCell>
                  {list.documentation_and_verification_officer}
                </TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Data Controller</TableCell>
                <TableCell>{list.data_controller}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>
                  Anty-Money Laundering Reporting Officer (AMLRO)
                </TableCell>
                <TableCell>{list.amlro}</TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </TableContainer>
    </Paper>
  );
}
