// Custom Components
import CustomSelect from "components/Common/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { changeMBCurrency } from "redux/actions/caoAction";
import { CURRENCY_STRIPE } from "data";
import { PriceDropdownDiv } from "./style";

const PriceDropdown = () => {
  //const CURRRENCY_DATA = ["Pound £", "EUR €", "CA $", "US $", "AU $"];
  const dispatch = useDispatch()
  const { currency } = useSelector(state => state.caoReducer)

  const handleChange = e => {
    dispatch(changeMBCurrency(e.target.value))
  }

  return (
    <PriceDropdownDiv>
      <CustomSelect
        onChange={handleChange}
        label="Currency"
        width="10%"
        name="Currency"
        data={CURRENCY_STRIPE}
        value={currency}
      />
    </PriceDropdownDiv>
  );
};

export default PriceDropdown;
