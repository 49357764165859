import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { ActionsDiv } from "./style";

const Actions = () => {
  const history = useHistory();

  return (
    <ActionsDiv>
      {history.location.pathname.includes("quote") ? (
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "10px" }}
        >
          Provide Quote
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "10px" }}
        >
          Accept
        </Button>
      )}

      <Button variant="outlined" color="secondary">
        Decline
      </Button>
    </ActionsDiv>
  );
};

export default Actions;
