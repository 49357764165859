import React from "react";

// Material Components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const SecondSegment = () => {
  const classes = useDashboardStyles();

  return (
    <Card>
      <CardHeader color="primary">
        <p className={`${classes.cardTitleWhite} ${classes.textCenter}`}>
          Notice
        </p>
      </CardHeader>
      <CardBody>
        <div className={classes.boxDiv}>
          <TextField
            id="standard-textarea"
            label="Type here..."
            placeholder="Write your message..."
            multiline
          />
          <div className={classes.attachDiv}>
            <input
              accept="*"
              className={classes.input}
              id="icon-button-file"
              type="file"
              style={{
                display: "none",
              }}
            />
            <label htmlFor="icon-button-file">
              <IconButton
                color="primary"
                aria-label="upload files"
                component="span"
              >
                <AttachFileIcon />
              </IconButton>
            </label>
          </div>
          <Button color="primary">
            <Icon>send</Icon>
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default SecondSegment;
