import SearchInput from "./SearchInput";
// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//   div: {
//     marginTop: "24px",
//     width: "50%",
//     ["@media (max-width:768px)"]: {
//       width: "100%",
//     },
//   },
//   root: {
//     ["@media (max-width:768px)"]: {
//       width: "100%",
//       display: "flex",
//       justifyContent: "center",
//     },
//   },
//   button: {
//     ["@media (max-width:768px)"]: {
//       width: "96%",
//       marginTop: 5,
//     },
//   },
// }));
const SearchClient = ({
  setShow,
  setSearchText,
  SearchText,
  handleSearch,
  load,
  show,
  values,
  setFiltered,
  setFilter,
}) => {
  // const classes = useStyles();

  return (
    <div>
      <SearchInput
        show={show}
        load={load}
        setSearchText={setSearchText}
        SearchText={SearchText}
        handleSearch={handleSearch}
        setFiltered={setFiltered}
        setFilter={setFilter}
        values={values}
        setShow={setShow}
      />
    </div>
  );
};

export default SearchClient;
