import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Form, Grid, Segment, Modal } from "semantic-ui-react";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import { changeTransInput } from "redux/actions/transAction";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import { Header } from "semantic-ui-react";
import CustomDateTime from "components/Common/CustomDateTime";
import Axios from "utils/axios";
import swal from "sweetalert";
import Cookie from "js-cookie";
import moment from "moment";
import history from "utils/history";
import TextField from "@material-ui/core/TextField";
import { TIMEZONE } from "data";

const DividerExampleVerticalForm = () => {
  const token = Cookie.get("token");
  const [open, setOpen] = React.useState(false);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const { data } = useSelector((state) => state.transReducer);
  const dispatch = useDispatch();
  const {
    photo,
    banner,
    CAC_check,
    BAC_check,
    LIN_check,
    EMP_check,
    SPPO_check,
    INDIVIDUAL_check,
    PUBLIC_check,
    events_date,
    events_time,
    timezone,
    venue_address,
    remote_link,
    joining_details,
    subject,
    details,
  } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeTransInput({
        name,
        value,
      })
    );
  };

  const handleChangeTI = (e, value) => {
    dispatch(
      changeTransInput({
        name: "timezone",
        value,
      })
    );
  };



  const handleDateChange = (e) => {
    dispatch(
      changeTransInput({
        name: "events_date",
        value: e.target.value,
      })
    );
  };


  const handleProfileFiles = (e) => {
    const { name } = e.target;
    dispatch(
      changeTransInput({
        name,
        value: e.target.files[0],
      })
    );
  };

  const handleBannerFiles = (e) => {
    const { name } = e.target;
    dispatch(
      changeTransInput({
        name,
        value: e.target.files[0],
      })
    );
    // }
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleClickBanner = (e) => {
    e.preventDefault();
    fileInput2.current.click();
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeTransInput({
        name,
        value,
      })
    );
  };

  const isAllow =
    !events_date ||
    !timezone ||
    !venue_address ||
    !remote_link ||
    !joining_details ||
    !subject ||
    !details;

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });
  const handleSubmit = async (e) => {
    setEdit({ ...edit, loader: true });
    let formData = new FormData();
    formData.append("events_date", events_date || "-");
    formData.append("events_time", events_time || "-");
    formData.append("timezone", timezone || "-");
    formData.append("venue_address", venue_address || "-");
    formData.append("remote_link", remote_link || "-");
    formData.append("joining_details", joining_details || "-");
    formData.append("subject", subject || "-");
    formData.append("details", details || "-");
    formData.append("CAC_check", CAC_check || "-");
    formData.append("BAC_check", BAC_check || "-");
    formData.append("LIN_check", LIN_check || "-");
    formData.append("EMP_check", EMP_check || "-");
    formData.append("SPPO_check", SPPO_check || "-");
    formData.append("INDIVIDUAL_check", INDIVIDUAL_check || "-");
    formData.append("PUBLIC_check", PUBLIC_check || "-");

    if (!photo?.contentType) {
      formData.append("photo", photo);
    }
    if (!banner?.contentType) {
      formData.append("banner", banner);
    }

    try {
      const response = await Axios.post(
        "/api/manager-admin/create-events",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: "Ok",
      }).then(function () {
        window.location = "/admin/meetings";
      });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  function EventMembers() {
    history.push("/admin/event-registered-members");
  }

  return (
    <>
      <Button
        color="purple"
        icon="list"
        floated="right"
        content="Event Listings"
        onClick={EventMembers}
      />
      
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={
          <Button
            color="purple"
            icon="add"
            floated="right"
            content="Add New Event"
          />
        }
      >
        <Modal.Header>Events and Meetings</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Segment placeholder>
              <Grid columns={2} relaxed="very" stackable>
                <Grid.Column>
                  <Form>
                    <input
                      onChange={handleChange}
                      type="checkbox"
                      name="CAC_check"
                      value="yes"
                    />{" "}
                    CAC <br />
                    <input
                      onChange={handleChange}
                      type="checkbox"
                      name="BAC_check"
                      value="yes"
                    />{" "}
                    BAC <br />
                    <input
                      onChange={handleChange}
                      type="checkbox"
                      name="LIN_check"
                      value="yes"
                    />{" "}
                    LIN <br />
                    <input
                      onChange={handleChange}
                      type="checkbox"
                      name="SPPO_check"
                      value="yes"
                    />{" "}
                    SPPO <br />
                    <input
                      onChange={handleChange}
                      type="checkbox"
                      name="EMP_check"
                      value="yes"
                    />{" "}
                    EMP <br />
                    <input
                      onChange={handleChange}
                      type="checkbox"
                      name="INDIVIDUAL_check"
                      value="yes"
                    />{" "}
                    INDIVIDUAL <br />
                    <input
                      onChange={handleChange}
                      type="checkbox"
                      name="PUBLIC_check"
                      value="yes"
                    />{" "}
                    PUBLIC
                    <br /> <br />
                    <CustomDateTime
                      onChange={handleDateChange}
                      width="100%"
                      label="Events Date and Time*"
                      name="events_date"
                      value={events_date}
                      required
                      inputProps={{
                        min: moment().format("YYYY-MM-DD"),
                      }}
                    />
                    <CustomAutoSelect
                      onChange={handleChangeTI}
                      label="Select timezone"
                      width="100%"
                      data={TIMEZONE}
                      name="timezone"
                      value={timezone}
                      required
                    />
                    <p>Venue / Address*</p>
                    <input
                      onChange={handleChange}
                      width="100%"
                      label="Venue / Address*"
                      name="venue_address"
                      value={venue_address}
                      placeholder="Venue / Address"
                      required
                    />
                    <br />
                    <p>Remote Link*</p>
                    <input
                      onChange={handleChange}
                      width="100%"
                      label="Remote Link*"
                      name="remote_link"
                      value={remote_link}
                      placeholder="Remote Link"
                      required
                    />
                    <br />
                    <p>Joining Details*</p>
                    <input
                      onChange={handleChange}
                      width="100%"
                      label="Joining Details*"
                      name="joining_details"
                      value={joining_details}
                      placeholder="Joining Details"
                      required
                    />
                    <br />
                    <p>Subject*</p>
                    <input
                      onChange={handleChange}
                      width="100%"
                      label="Subject*"
                      name="subject"
                      value={subject}
                      placeholder="Subject"
                      required
                    />
                    <br />
                    <p>Details*</p>
                    {/* <textarea
                                            onChange={handleChange}
                                            width="100%"
                                            label="Details*"
                                            name="details"
                                            value={details}
                                            placeholder="Details"
                                            required
                                        /> */}
                    <TextField
                      onChange={handleChange}
                      value={details}
                      id="standard-textarea"
                      // label="Type here..."
                      placeholder="Write details here..."
                      multiline
                      rows={6}
                      name="details"
                      required
                      width="100%"
                      style={{ marginBottom: 15, width: 100 + "%" }}
                    />
                  </Form>
                </Grid.Column>

                <Grid.Column verticalAlign="middle">
                  {/* <div>
                                        <input
                                            accept="image/png, image/jpeg"
                                            ref={fileInput}
                                            onChange={handleProfileFiles}
                                            type="file"
                                            width="100%"
                                            name="photo"
                                            style={{ display: "none" }}
                                        />
                                        <div style={{ display: "flex", flexDirection: "column" }}>

                                            <div>
                                                <Button color="purple" icon='add' content="Upload Photo" onClick={handleClick} />
                                            </div>
                                            {photo && (
                                                <div style={{ textAlign: "center" }}>
                                                    <Header style={{ margin: "0" }} as="h1">
                                                        <InsertPhotoIcon fontSize="large" />
                                                    </Header>{" "}
                                                    <Header style={{ margin: "0" }} as="h5">
                                                        {photo.name}{" "}
                                                    </Header>
                                                    <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                                                        photo.size / 1024
                                                    ).toFixed(2)}kb`}</Header>
                                                    <Header
                                                        style={{ margin: "0" }}
                                                        as="h5"
                                                    >{`Last Modified: ${photo?.lastModifiedDate}`}</Header>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <br /> */}
                  <div>
                    <input
                      accept="image/png, image/jpeg"
                      ref={fileInput2}
                      onChange={handleBannerFiles}
                      type="file"
                      width="100%"
                      name="banner"
                      style={{ display: "none" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>
                        <Button
                          color="purple"
                          icon="add"
                          content="Upload banner"
                          onClick={handleClickBanner}
                        />
                        <h4 style={{ textAlign: "center" }}>
                          ( height: 217px, width: 290px )
                        </h4>
                      </div>
                      {banner && (
                        <div style={{ textAlign: "center" }}>
                          <Header style={{ margin: "0" }} as="h1">
                            <InsertPhotoIcon fontSize="large" />
                          </Header>{" "}
                          <Header style={{ margin: "0" }} as="h5">
                            {banner.name}{" "}
                          </Header>
                          <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                            banner.size / 1024
                          ).toFixed(2)}kb`}</Header>
                          <Header
                            style={{ margin: "0" }}
                            as="h5"
                          >{`Last Modified: ${banner?.lastModifiedDate}`}</Header>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  <div>
                    <Button
                      color="purple"
                      disabled={isAllow}
                      content="Submit"
                      onClick={handleSubmit}
                    />
                  </div>
                </Grid.Column>
              </Grid>

              <Divider vertical>-</Divider>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
};
export default DividerExampleVerticalForm;
