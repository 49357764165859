import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import swal from "sweetalert";
import { Button } from "semantic-ui-react";
import Cookies from "js-cookie";
import history from "utils/history"


const Action = ({ id, load }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get('token')


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const deletePost = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-privacy-policy/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deletePost(value);

          default:
            break;
        }
      });
  }



  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}

      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push(`/admin/privacy-policy/edit-privacy-policy/${id}`)}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

    </div>
  );
};

export default Action;
