import React from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import { Button, Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomAvatar from "components/Common/CustomAvatar";
import Axios from "utils/axios";
import CustomPhone from "components/Common/CustomPhone";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import history from "utils/history"
import { useParams } from "react-router";
import swal from "sweetalert";


const SubscribeHere = () => {
  const classes = useLandingpageStyles();
  const { id } = useParams();
  const { sppoID } = useParams();
  const [incDetails, setINCDetails] = React.useState()
  const [data, setData] = React.useState({
    clientID: "",
    mobile_no: "",
    passNumber: ""
  })

  const {
    clientID,
    mobile_no,
    passNumber
  } = data

  const handleMobile = (value) => {
    setData({ ...data, mobile_no: value });
  };


  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  }


  const FindINCClient = async () => {
    try {
      const response = await Axios.put(`/api/sppo/find-inhouse-client`, {
        clientID: clientID,
        mobile_no: mobile_no,
        passNumber: passNumber
      })
      setINCDetails(response?.data)
      setData({ ...data, clientID: "", mobile_no: "", passNumber: "" });
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error.response?.data}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  // useEffect(() => {
  //   FindINCClient()
  // }, [])



  return (
    <LandingPageLayout>
      <GridContainer style={{ justifyContent: "center", margin: "2rem 0" }}>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5" style={{ textAlign: "center" }}>
                  Booking Interpreter With SPPO: {sppoID}<br />
                  Find INC Information
                </Typography>
              </div>
              {incDetails ? null : <>
                <CustomInputSingle
                  label="Client Ref ID"
                  width="100%"
                  placeholder="e.g. INC100..."
                  name="clientID"
                  onChange={handleChange}
                  value={data.clientID}
                  required
                />

                <div style={{ marginTop: "24px" }}>
                  <CustomPhone
                    onChange={handleMobile}
                    placeholder="Select country code than type number e.g. +44 7307 000000"
                    value={data.mobile_no}
                    name="mobile_no"
                    required
                  />
                </div>

                <CustomInputSingle
                  label="Pass Number / Code"
                  width="100%"
                  placeholder="Please enter your pass number or code here"
                  name="passNumber"
                  onChange={handleChange}
                  value={data.passNumber}
                  required
                />

              </>}

              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                {!incDetails ?
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={FindINCClient}
                    disabled={!data.clientID || !data.mobile_no || !data.passNumber}
                  >
                    Find My Details
                  </Button>
                  :
                  <>
                    <p>ID: {incDetails?.id}</p>
                    <p>Name: {incDetails?.LastName}</p>
                    <p>Mobile no.: {incDetails?.mobile_no}</p>
                    <p>Town / City: {incDetails?.City}</p>
                    <p>Country: {incDetails?.Country}</p>

                    <Button
                      className={`${classes.button}`}
                      color="primary"
                      variant="contained"
                      onClick={() => history.push(`/`)}
                      style={{ marginRight: "5px" }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className={`${classes.button}`}
                      color="primary"
                      variant="contained"
                      onClick={() => history.push(`/inhouse-interpreter-booking/${id}/${sppoID}/${incDetails?.id}/${incDetails?.FirstName}/${incDetails?.LastName}/${incDetails?._id}`)}
                    >
                      Confirm And Book An Interpreter
                    </Button>

                  </>
                }

              </div>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default SubscribeHere;
