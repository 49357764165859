import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Button,Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from 'js-cookie'
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { IconButton, Link ,useMediaQuery} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ViewList from "components/Admin/CrmPanel/Private/List/List";


const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const matches = useMediaQuery('max-width:425px')
  const [crm_panel, setAdminAccess] = useState(null)

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAdminAccess(response?.data.crm_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  },[]);


  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {crm_panel ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
              <CardHeader style={{display:'flex',alignItems:'center',justifyContent:'space-between'}} color="primary">
                  {matches? <> 
                  <div style={{display:'inline'}}>
                   <p className={classes.cardTitleWhite}>Private Clients</p>
                   </div>
                  <div style={{display:'flex'}}>
                  <Link href='/delete-my-PRC-account-from-linguist' target='_blank'>  <Button  color='purple'>PRC Removal Form</Button></Link>
                  <IconButton color='inherit' onClick={()=>history.goBack()}><ArrowBackIcon /></IconButton>

                  </div></>: <>  <p className={classes.cardTitleWhite}>Private Clients</p>
                  <div style={{display:'flex'}}>
                  <Link href='/delete-my-PRC-account-from-linguist' target='_blank'>  <Button  color='purple'>PRC Removal Form</Button></Link>
                    <IconButton color='inherit' onClick={()=>history.goBack()}><ArrowBackIcon /></IconButton>
                  </div></>}
               
                </CardHeader>
      
                <CardBody>
                  {/* <Search /> */}
                  <ViewList />
                </CardBody>
              </Card>
            </GridItem>

            :
            crm_panel === 0 ?
              <Header as='h3'>Access denied</Header>
              :
              <Dimmer active inverted>
                <Loader size='large'>Loading</Loader>
              </Dimmer>
          }

        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
