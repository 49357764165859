import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";
import { Button, Form, Segment, TextArea } from "semantic-ui-react";
import swal from "sweetalert";
import { Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import Moment from "moment";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Action from "components/Admin/ManagementPanel/ActionNews";

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const token = Cookies.get("token");
  const [values, setValues] = useState({ reload: false });
  const [management_panel, setAdminAccess] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getLinguistDetails = async (e) => {
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminAccess(response?.data.management_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  const [pay, setPay] = React.useState({
    news_notice: "",
  });
  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };
  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const handleCAC = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/write-cac-news-notice`,
        {
          news_notice: pay.news_notice,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "News or notice successfully added",
      });
      setPay({ ...pay, news_notice: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleBAC = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/write-bac-news-notice`,
        {
          news_notice: pay.news_notice,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "News or notice successfully added",
      });
      setPay({ ...pay, news_notice: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleLIN = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/write-lin-news-notice`,
        {
          news_notice: pay.news_notice,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "News or notice successfully added",
      });
      setPay({ ...pay, news_notice: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleEMP = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/write-emp-news-notice`,
        {
          news_notice: pay.news_notice,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "News or notice successfully added",
      });
      setPay({ ...pay, news_notice: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleSPPO = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/write-sppo-news-notice`,
        {
          news_notice: pay.news_notice,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "News or notice successfully added",
      });
      setPay({ ...pay, news_notice: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const postCAC = async (e, id) => {
    swal(`Are you sure you want to post this News / Notice to CAC?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleCAC(value);
          break;
        default:
          break;
      }
    });
  };

  const postBAC = async (e, id) => {
    swal(`Are you sure you want to post this News / Notice to BAC?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleBAC(value);
          break;
        default:
          break;
      }
    });
  };

  const postLIN = async (e, id) => {
    swal(`Are you sure you want to post this News / Notice to LIN?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleLIN(value);
          break;
        default:
          break;
      }
    });
  };

  const postEMP = async (e, id) => {
    swal(`Are you sure you want to post this News / Notice to EMP?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleEMP(value);
          break;

        default:
          break;
      }
    });
  };

  const postSPPO = async (e, id) => {
    swal(`Are you sure you want to post this News / Notice to SPPO?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleSPPO(value);
          break;
        default:
          break;
      }
    });
  };

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/list-all-news-notice`
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {management_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>News and Notice</p>
                </CardHeader>
                <CardBody>
                  <Segment color="purple">
                    <Form color="purple">
                      <Form.Group widths="equal">
                        <Form.Field
                          onChange={handleChange}
                          value={pay.news_notice}
                          control={TextArea}
                          label="News and Notice"
                          name="news_notice"
                          placeholder="Write news or notice here..."
                        />
                        <Button.Group>
                          <Button
                            color="purple"
                            disabled={!pay.news_notice}
                            onClick={postCAC}
                          >
                            CAC
                          </Button>
                          <Button.Or />
                          <Button
                            color="purple"
                            disabled={!pay.news_notice}
                            onClick={postBAC}
                          >
                            BAC
                          </Button>
                          <Button.Or />
                          <Button
                            color="purple"
                            disabled={!pay.news_notice}
                            onClick={postLIN}
                          >
                            LIN
                          </Button>
                          <Button.Or />
                          <Button
                            color="purple"
                            disabled={!pay.news_notice}
                            onClick={postSPPO}
                          >
                            SPPO
                          </Button>
                          <Button.Or />
                          <Button
                            color="purple"
                            disabled={!pay.news_notice}
                            onClick={postEMP}
                          >
                            EMP/
                            <br />
                            MAN
                          </Button>
                        </Button.Group>
                      </Form.Group>
                    </Form>
                  </Segment>
                </CardBody>
              </Card>

              <Card>
                <Paper className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" id="export">
                      <TableHead>
                        <TableRow>
                          <TableCell>News and Notice</TableCell>
                          <TableCell>Admin Name</TableCell>
                          <TableCell>Admin ID</TableCell>
                          <TableCell>Created Date</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>

                      {values.length > 0 ? (
                        <TableBody>
                          {values
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((list) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={list._id}
                                style={
                                  list?.cac_news_notice
                                    ? { backgroundColor: "#B048B5" }
                                    : list?.bac_news_notice
                                    ? { backgroundColor: "#A74AC7" }
                                    : list?.lin_news_notice
                                    ? { backgroundColor: "#D462FF" }
                                    : list?.emp_news_notice
                                    ? { backgroundColor: "#FF00FF" }
                                    : list?.sppo_news_notice
                                    ? { backgroundColor: "#EE82EE" }
                                    : null
                                }
                              >
                                <TableCell style={{ textAlign: "justify" }}>
                                  {list?.cac_news_notice
                                    ? "CAC - "
                                    : list?.bac_news_notice
                                    ? "BAC - "
                                    : list?.lin_news_notice
                                    ? "LIN - "
                                    : list?.emp_news_notice
                                    ? "EMP - "
                                    : "SPPO - "}
                                  {list?.news_notice}
                                </TableCell>
                                <TableCell>
                                  {list?.postedBy?.LastName}
                                </TableCell>
                                <TableCell> {list?.postedBy?.id} </TableCell>

                                <TableCell>
                                  {Moment(list.createdAt)
                                    .tz("Europe/London")
                                    .format("DD MMM YYYY - kk:mm:ss")}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <Action id={list?._id} load={loadList} />{" "}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      ) : (
                        "No data found"
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={values.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </Card>
            </GridItem>
          ) : management_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
