import Axios from "axios";
import swal from "sweetalert";
import defaultAxios from "utils/axios";

const {
  GET_RATES,
  SET_LOADING,
  UPDATE_RATE,
  GET_RATE_DETAILS,
  UPDATE_RATE_DETAILS,
  GET_CARP_DETAILS,
  UPDATE_CARP_DETAILS,
  GET_PERCENTAGE_RATES,
  UPDATE_PERCENTAGE_RATE,
  SET_PERCENT,
  GET_CURRENCY,
  UPDATE_CURRENCY,
  POST_CURRENCY,
  GET_LEAD_DETAILS,
  UPDATE_LEAD_CONTACT_GROUP
} = require("../actionTypes");

export const getRates = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/admin/rate_card`
    );
    // "proxy": "https://api.linguistscollective.com",

    dispatch({
      type: GET_RATES,
      payload: response?.data,
    });
  } catch (error) {
    if (error.response) {
      swal({
        title: "Oops!",
        text: `${
          error.response ? error?.response?.data?.error : "Something Went Wrong"
        }`,
        icon: "error",
        buttons: false,
      });
    }
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const updateRate = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const copiedData = { ...data };
    delete copiedData._id;
    delete copiedData.active;
    delete copiedData.createdAt;
    delete copiedData.updatedAt;

    const response = await Axios.put(
      `${process.env.REACT_APP_BACKEND_API}/admin/rate_card/${data._id}`,
      copiedData
    );

    dispatch({
      type: UPDATE_RATE,
      payload: response?.data.data,
    });
  } catch (error) {
    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const getRatesDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/admin/rate_card/details`
    );

    dispatch({
      type: GET_RATE_DETAILS,
      payload: response?.data.data[0],
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};


export const updateRateDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const copiedData = { ...data };
    delete copiedData._id;
    delete copiedData.active;
    delete copiedData.createdAt;
    delete copiedData.updatedAt;

    const response = await Axios.put(
      `${process.env.REACT_APP_BACKEND_API}/admin/rate_card/details/${data._id}`,
      copiedData
    );

    dispatch({
      type: UPDATE_RATE_DETAILS,
      payload: response?.data.data[0],
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};







export const getCurrencyDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/currency-list`
    );

    dispatch({
      type: GET_CURRENCY,
      payload: response?.data.data[0],
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};


export const postCurrencyDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const copiedData = { ...data };
   
    const response = await Axios.post(
      `${process.env.REACT_APP_BACKEND_API}/create-currency-rate`,
      copiedData
    );

    dispatch({
      type: POST_CURRENCY,
      payload: response?.data.data[0],
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};




export const updateCurrencyDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const copiedData = { ...data };
    delete copiedData._id;
    delete copiedData.active;
    delete copiedData.createdAt;
    delete copiedData.updatedAt;

    const response = await Axios.put(
      `${process.env.REACT_APP_BACKEND_API}/update-currency-rate/${data._id}`,
      copiedData
    );

    dispatch({
      type: UPDATE_CURRENCY,
      payload: response?.data.data[0],
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};





export const getLeadDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/list/cla-contact-group`
    );

    dispatch({
      type: GET_LEAD_DETAILS,
      payload: response?.data.data[0],
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};




export const updateLeadDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const copiedData = { ...data };
   
    const response = await Axios.put(
      `${process.env.REACT_APP_BACKEND_API}/edit/cla-contact-group/${data._id}`,
      copiedData
    );

    dispatch({
      type: UPDATE_LEAD_CONTACT_GROUP,
      payload: response?.data.data[0],
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};




export const getCarpDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    // const response = await Axios.get(
    //   `${process.env.REACT_APP_BACKEND_API}/admin/rate_card/details/`
    // );

    const response = await defaultAxios.get(`${process.env.REACT_APP_BACKEND_API}/admin/carp_card/details`);

    dispatch({
      type: GET_CARP_DETAILS,
      payload: response?.data.data[0],
    });
  } catch (error) {
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const updateCarpDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const copiedData = { ...data };
    delete copiedData._id;
    delete copiedData.active;
    delete copiedData.createdAt;
    delete copiedData.updatedAt;

    const response = await defaultAxios.put(
      `${process.env.REACT_APP_BACKEND_API}/admin/carp_card/details/${data._id}`,
      copiedData
    );

    dispatch({
      type: UPDATE_CARP_DETAILS,
      payload: response?.data.data[0],
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const getPercentRates = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const response = await defaultAxios.get(`${process.env.REACT_APP_BACKEND_API}/admin/percent_card`);
    // "proxy": "https://api.linguistscollective.com",

    dispatch({
      type: GET_PERCENTAGE_RATES,
      payload: response?.data.data,
    });
  } catch (error) {
    if (error.response) {
      swal({
        title: "Oops!",
        text: `${
          error.response ? error?.response?.data?.error : "Something Went Wrong"
        }`,
        icon: "error",
        buttons: false,
      });
    }
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const updatePercentRates = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const copiedData = { ...data };
    delete copiedData._id;
    delete copiedData.active;
    delete copiedData.createdAt;
    delete copiedData.updatedAt;

    console.log(copiedData);

    const response = await defaultAxios.put(
      `${process.env.REACT_APP_BACKEND_API}/admin/percent_card/${data._id}`,
      copiedData
    );

    dispatch({
      type: UPDATE_PERCENTAGE_RATE,
      payload: response?.data.data,
    });
  } catch (error) {
    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const setSelectedPercent = (data) => ({
  type: SET_PERCENT,
  payload: data,
});
