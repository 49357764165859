import CustomTextInput from "components/Common/CustomTextInput";
import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "semantic-ui-react";
import { Card, Icon, Image, Header, Label, Checkbox } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";

import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import history from "utils/history";
import RichTextEditor from "react-rte";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeTimesheetInput } from "redux/actions/timesheetAction";

const TimesheetFooter = ({ details, linguist, checked, setChecked }) => {
  let sigPad = useRef({});
  const [signatureModal, setSignatureModal] = useState(false);
  const { data } = useSelector((state) => state.timesheetReducer);
  const dispatch = useDispatch();
  const [text, setText] = useState(RichTextEditor.createEmptyValue());

  const { signature } = data;

  const handleClose = (e) => {
    e.preventDefault();
    setSignatureModal(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    dispatch(
      changeTimesheetInput({
        name: "signature",
        value: temp,
      })
    );
    setSignatureModal(false);
  };

  const handleText = (value) => {
    setText(value);
  };

  return (
    <div>
      <CustomModal
        open={signatureModal}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          //onEnd = {handleSave}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            basic
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            color="purple"
          >
            Clear
          </Button>
          <Button onClick={handleSave} color="purple">
            Save
          </Button>
        </div>
      </CustomModal>

      {/* Table */}

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="2">
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
            {/* <Table.HeaderCell width="2">
              To be verified by project supervisor / quality control (if
              applicable){" "}
            </Table.HeaderCell> */}
            <Table.HeaderCell width="2">
              To be completed by client
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Checkbox onChange={() => setChecked(!checked)} label="I declare that I have checked the information on this form and certify that it is true and complete" />
            </Table.Cell>
            {/* <Table.Cell>
              <Checkbox
                label="I certify that the project was supervised / quality control by me."
                disabled
              />
            </Table.Cell> */}
            <Table.Cell>
              <Checkbox
                label="I declare the information given by me is true/accurate and complete."
                disabled
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ display: "flex", flexDirection: "column" }}>
              <div>
                Signature{" "}
                <IconButton onClick={() => setSignatureModal(true)}>
                  <EditIcon />
                </IconButton>
              </div>
              {signature && (
                <img style={{ width: "130px" }} src={signature} alt="" />
              )}
            </Table.Cell>
            {/* <Table.Cell>Signature</Table.Cell> */}
            <Table.Cell>Signature</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <CustomTextInput
                label="Full name"
                value={linguist?.FirstName + " " + linguist?.LastName}
              />
            </Table.Cell>
            {/* <Table.Cell>
              <CustomTextInput
                label="Full name"
                value="admin first + last name to be implemented"
              />
            </Table.Cell> */}
            <Table.Cell>
              <CustomTextInput
                label="Full name"
                value={
                  details?.postedBy?.FirstName + " " + details?.postedBy?.LastName
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {moment().format("DD-MM-YYYY")}</Table.Cell>
            {/* <Table.Cell>Date: {moment().format("DD-MM-YYYY")}</Table.Cell> */}
            <Table.Cell>Date: {moment().format("DD-MM-YYYY")}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default TimesheetFooter;
