import React from "react";
import { SERVICE_TYPE } from "data";
import CustomRadio from "components/Common/CustomRadio";
import history from "utils/history";
import LandingPageLayout from "layout/LandingPageLayout";
import CustomAvatar from "components/Common/CustomAvatar";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Header } from "semantic-ui-react";
const FormSelection = () => {
  const classes = useEmployeeStyles();
  const handleRadioChange = (e) => {
    const { value } = e.target;
    if (value === "Interpreting") {
      history.push("/interpreting-or-translation/interpreting-form");
    } else if (value === "Translation") {
      history.push("/interpreting-or-translation/translation-form");
    } else if (value === "Quotation") {
      history.push("/interpreting-or-translation/quotation-form");
    }
  };
  return (
    <LandingPageLayout>
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
          <Card>
              <CardBody>
              <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomAvatar />
                </div>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <Header style={{ margin: "0" }} as="h3">
                    Linguists Collective (LC)
                  </Header>
                  <Header style={{ margin: "0" }} as="h3">
                   Interpreting booking, translation ordering and quotation request form

                  </Header>
                </div>
            <form className={classes.form} noValidate autoComplete="off">
      <CustomRadio
        onChange={handleRadioChange}
        label="Choose service"
        width="100%"
        data={SERVICE_TYPE}
        required
      />
    </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  </LandingPageLayout>

  );
};
export default FormSelection;
