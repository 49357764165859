import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

//Material icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

// import Details from './Details'
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Segment,
  Statistic,
  Table,
} from "semantic-ui-react";
import {
  DISCOUNT,
  TAXIFARE,
  TIMEZONE,
  QUOTE_REQUEST_FOR,
  CURRENCY_STRIPE,
} from "data";
import { IconButton, Input, TextField, useMediaQuery } from "@material-ui/core";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import swal from "sweetalert";
import {
  addOtherTransaction,
  addTransaction,
  deleteOtherTransaction,
  deleteTransaction,
} from "redux/actions/expenseAction";
import CustomDateTime from "components/Common/CustomDateTime";
import momentTimezone from "moment-timezone";

import { Fragment } from "react";

const ProvideQuotePRC = ({ title, id }) => {
  const matches = useMediaQuery("(max-width:768px)");
  const classes = useEmployeeStyles();
  const [count, setCount] = useState([0]);
  const history = useHistory();
  const token = Cookies.get("LINtoken");
  const [invoice, setInvoice] = useState(null);
  const [linguist, setLinguist] = useState(null);
  const [lingRef, setLingRef] = useState(null);
  const [timezone, setTimezone] = useState(momentTimezone.tz.guess());
  const [sourceLang, setSourceLang] = useState("");
  const [targetLang, setTargetLang] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [privateClient, setPrivateClient] = useState({
    id: "",
    data: "",
  });
  const [serviceDate, setServiceDate] = useState(null);
  const [clientRef, setClientRef] = useState("");
  const [service, setService] = useState("");
  const [completionDate, setCompletionDate] = useState(null);
  const [calculate, setCalculate] = useState({
    sendButton: false,
    loading: false,
    quoteId: "",
  });

  //   1st subtotal
  const [text, setText] = useState("");
  const [amount, setAmount] = useState(null);
  const [quantity, setQuantity] = useState(null);

  //   2nd subtotal
  const [otherAmount, setOtherAmount] = useState(null);
  const [otherText, setOtherText] = useState("");
  const [otherQuantity, setOtherQuantity] = useState(null);

  const dispatch = useDispatch();

  const { transactions } = useSelector((state) => state.expenseReducer);
  const amountsOne = transactions.map((transaction) => transaction.total);
  const subTotalOne = amountsOne
    .reduce((acc, item) => (acc += item), 0)
    .toFixed(2);
  const [currency, setCurrency] = useState(CURRENCY_STRIPE[0]);
  const { otherTransactions } = useSelector((state) => state.expenseReducer);
  const amountsTwo = otherTransactions.map((transaction) => transaction.total);
  const subTotalTwo = amountsTwo
    .reduce((acc, item) => (acc += item), 0)
    .toFixed(2);

  const [discount, setDiscount] = useState(0);
  const [vat, setVAT] = useState(0);

  const [totalFTF, setTotalFTF] = useState({
    price: 0,
    quantity: 0,
  });

  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
  });
  //  const totalIntr = totalFTF.price * totalFTF.quantity

  const total = (Number(subTotalOne) + Number(subTotalTwo)).toFixed(2);

  //Total after discount
  const totalDiscount = ((Number(total) * discount) / 100).toFixed(2);

  //Including platform and booking service charge
  const totalPBSC = (
    ((Number(total) - Number(totalDiscount)) * companyCard?.pbsc) /
    100
  ).toFixed(2);

  //Total VAT
  const totalVAT = (
    Number(Number(total) + Number(totalPBSC) - Number(totalDiscount)) *
    (vat / 100)
  ).toFixed(2);

  //Total after including vat and discount
  const totalTwo = (
    Number(total) +
    Number(totalPBSC) -
    Number(totalDiscount) +
    Number(totalVAT)
  ).toFixed(2);

  const handleAdd = (e) => {
    e.preventDefault();

    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      text,
      amount: +amount,
      quantity,
      total: amount * quantity,
    };

    dispatch(addTransaction(newTransaction));
    setText("");
    setAmount("");
    setQuantity("");
  };

  const handleAddOther = (e) => {
    e.preventDefault();
    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      otherText,
      otherAmount: +otherAmount,
      otherQuantity,
      total: otherAmount * otherQuantity,
    };

    dispatch(addOtherTransaction(newTransaction));
    setOtherText("");
    setOtherAmount("");
    setOtherQuantity("");
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getLinguistProfile = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLinguist(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    const getCompanyCard = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/view/company-information-card`
        );

        setCompanyCard({
          ...companyCard,
          basc: response?.data[0].getCompanyCard,
          pbsc: response?.data[0].platform_booking_service_charge,
          basc: response?.data[0].billing_account_service_charge,
          cname: response?.data[0].company_name,
          caddress: response?.data[0].company_address,
        });
      } catch (error) {
        console.log(error);
      }
    };

    getLinguistProfile();
    getCompanyCard();

    return () => {
      source.cancel();
    };
  }, []);

  const handleSend = async (e) => {
    setLoading(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/send/private-client-quote/quoteID/${calculate.quoteId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      swal({
        text: "Quotation successfully send to private client",
        icon: "success",
        button: "Ok",
      }).then(function () {
        window.location = "/linguist/dashboard";
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutoFill = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-PRC/clientID/${privateClient.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPrivateClient({ ...privateClient, data: response?.data });
      if (!response?.data) {
        swal({
          text: "Wrong client Id",
          buttons: "Ok",
          dangerMode: true,
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
      swal({
        text: "Wrong client Id",
        buttons: false,
        dangerMode: true,
        icon: "error",
      });
    }
  };

  const handleCalculate = async (e) => {
    setCalculate({ ...calculate, loading: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/create/private-client-quote/clientID/${privateClient.data._id}`,
        {
          date_of_quote: moment().format("DD/MMM/YYYY"),
          service_completion_date: serviceDate,
          linguist_ref: lingRef,
          subtotal1: subTotalOne,
          subtotal2: subTotalTwo,
          total: totalTwo,
          currency: currency,
          service_charge: companyCard.pbsc,
          service_charge_amount: totalPBSC,
          discount_percentage: discount,
          discount_amount: totalDiscount,
          vat_percentage: vat,
          vat_amount: totalVAT,
          grand_total: totalTwo,
          transactions: transactions,
          otherTransactions: otherTransactions,
          timezone: timezone,
          expiry_date: expiryDate,
          service: service,
          client_ref: clientRef,
          company_name: companyCard.cname,
          company_address: companyCard.caddress,
          account_service_charge: companyCard.basc,

          source_language: sourceLang,
          target_language: targetLang,
          source_language_level: sourceLevel,
          target_language_level: targetLevel,

          client_title: privateClient.data?.title,
          client_firstname: privateClient.data?.FirstName,
          client_lastname: privateClient.data?.LastName,
          client_email: privateClient.data?.email,
          client_mobile_no: privateClient.data?.mobile_number,
          client_city: privateClient.data?.City,
          client_postalcode: privateClient.data?.Region,
          client_region: privateClient.data?.Region,
          client_country: privateClient.data?.Country,
          client_addressline_one: privateClient.data?.AddressLine1,
          client_addressline_two: privateClient.data?.AddressLine2,
          client_id: privateClient.data?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Quote saved not send to client yet. Please send the quote when you ready.",
        icon: "success",
        button: "Noted",
        dangerMode: true,
      });
      setCalculate({
        ...calculate,
        loading: false,
        sendButton: true,
        quoteId: response?.data._id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [sourceLevel, setSourceLevel] = useState([]);
  const [targetLevel, setTargetLevel] = useState([]);

  const LIN_SOURCE_LANG =
    linguist &&
    linguist.source_language
      .concat(linguist.source_language_1)
      .concat(linguist.source_language_2);
  const LIN_TARGET_LANG =
    linguist &&
    linguist.target_language
      .concat(linguist.target_language_1)
      .concat(linguist.target_language_2);
  const LIN_SOURCE_LEVEL =
    linguist &&
    linguist.source_language_level
      .concat(linguist.source_language_level_1)
      .concat(linguist.source_language_level_2);
  const LIN_TARGET_LEVEL =
    linguist &&
    linguist.target_language_level
      .concat(linguist.target_language_level_1)
      .concat(linguist.target_language_level_2);

  const handleChangeS = (e, value) => {
    const index = LIN_SOURCE_LANG.indexOf(value);
    setSourceLevel(LIN_SOURCE_LEVEL[index]);
    // setv_alu(index)

    setSourceLang(value);
  };

  const handleChangeT = (e, value) => {
    const index = LIN_TARGET_LANG.indexOf(value);
    setTargetLevel(LIN_TARGET_LEVEL[index]);
    // setv_alu(index)

    setTargetLang(value);
  };

  const handleCurrency = async (e, value) => {
    setCurrency(value);
  };

  return linguist && companyCard ? (
    <div className={classes.root}>
      <div id="pdf"></div>
      <GridContainer justify="center" className={classes.container}>
        <GridItem id="printArea" xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              {/* Avatar and company name */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "24px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <CustomAvatar style={{ margin: "0" }} />
                </div>
              </div>

              {/* First div  */}
              {!matches ? (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "24px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0 5px 0 0" }} as="h4">
                          Quotation to:
                        </Header>
                        <Input
                          style={{ marginRight: "5px" }}
                          onChange={(e) =>
                            setPrivateClient({
                              ...privateClient,
                              id: [e.target.value],
                            })
                          }
                          placeholder="Type Private Client Id.."
                        />
                        <Button
                          inverted
                          color="purple"
                          onClick={handleAutoFill}
                        >
                          Auto fill
                        </Button>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Name of the company:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Name of the person:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.FirstName}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          House number/ name, Street name:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.AddressLine1}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Address Line Two:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.AddressLine2}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Region:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.Region}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          City:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.City}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Postal Code:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.PostalCode}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Country:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.Country}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Email:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.email}
                        </Header>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Quotation number:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Linguist/SP ID number:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{linguist.id}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Linguist Residence:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{linguist.Country}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          VAT/GST no:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{linguist.vat_number}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Date of quote:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{moment().format("DD-MMM-YYYY")}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Date of invoice:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;N/A
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Quote estimate to be paid by:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                          {expiryDate
                            ? moment(expiryDate).format("DD/MMM/YYYY HH:mm")
                            : null}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Client reference:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                          <Input
                            onChange={(e) => setClientRef(e.target.value)}
                            value={clientRef}
                          />
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Service completion date*:&nbsp;
                        </Header>
                        <TextField
                          id="date"
                          label="Date"
                          onChange={(e) => setServiceDate(e.target.value)}
                          value={serviceDate}
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: moment().format("YYYY-MM-DD"),
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Linguist/SP ref:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                          <Input
                            onChange={(e) => setLingRef(e.target.value)}
                            value={lingRef}
                            type="text"
                          />
                        </Header>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CustomAutoSelect
                        label="Source language"
                        width="40%"
                        onChange={handleChangeS}
                        data={LIN_SOURCE_LANG}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Source language level:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{sourceLevel}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CustomAutoSelect
                        label="Target language"
                        width="40%"
                        onChange={handleChangeT}
                        data={LIN_TARGET_LANG}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Target language level:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{targetLevel}
                      </Header>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div
                    style={{ marginBottom: "24px", width: "100%", padding: 5 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0 5px 0 0" }} as="h4">
                          Quotation to:
                        </Header>
                        <Input
                          style={{ marginRight: "5px" }}
                          onChange={(e) =>
                            setPrivateClient({
                              ...privateClient,
                              id: [e.target.value],
                            })
                          }
                          placeholder="Type Private Client Id.."
                        />
                        <Button
                          inverted
                          color="purple"
                          onClick={handleAutoFill}
                        >
                          Auto fill
                        </Button>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Name of the company:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Name of the person:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.FirstName}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          House number/ name, Street name:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.AddressLine1}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Address Line Two:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.AddressLine2}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Region:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.Region}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          City:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.City}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Postal Code:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.PostalCode}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Country:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.Country}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Email:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{privateClient.data?.email}
                        </Header>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Quotation number:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Linguist/SP ID number:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{linguist.id}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Linguist Residence:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          VAT/GST no:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{linguist.vat_number}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Date of quote:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;{moment().format("DD/MM/YYYY")}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Date of invoice:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;N/A
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Quote estimate to be paid by:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                          {expiryDate
                            ? moment(expiryDate).format("DD/MM/YYYY HH:mm")
                            : null}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Client reference:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                          <Input
                            onChange={(e) => setClientRef(e.target.value)}
                            value={clientRef}
                          />
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Service completion date*:&nbsp;
                        </Header>
                        <TextField
                          id="date"
                          label="Date"
                          onChange={(e) => setServiceDate(e.target.value)}
                          value={serviceDate}
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          Linguist/SP ref:
                        </Header>
                        <Header color="grey" style={{ margin: "0" }} as="h4">
                          &nbsp;
                          <Input
                            onChange={(e) => setLingRef(e.target.value)}
                            value={lingRef}
                            type="number"
                          />
                        </Header>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CustomAutoSelect
                        label="Service"
                        width="40%"
                        onChange={(e, value) => setService(value)}
                        data={QUOTE_REQUEST_FOR}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Source language:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{linguist.source_language}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Target language level:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{linguist.target_language}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Expertise required:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{linguist.target_language_level}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Linguist's expertise level:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{linguist.target_language_level}
                      </Header>
                    </div>
                  </div>
                </Fragment>
              )}

              <Segment color="purple" textAlign="center">
                <CustomAutoSelect
                  width="50%"
                  data={CURRENCY_STRIPE}
                  onChange={(e, value) => handleCurrency(e, value)}
                  value={currency}
                />
              </Segment>
              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="5">
                      Description of service
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">{`Price ${
                      currency?.split("(")[0]
                    } 00.00`}</Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Quantity 00.00
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {transactions.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.text}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.amount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.quantity}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        {tr.total}
                        <IconButton
                          onClick={() => dispatch(deleteTransaction(tr.id))}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell>
                      <CustomAutoSelect
                        onChange={(e, value) => setText(value)}
                        value={text}
                        label="Choose"
                        data={QUOTE_REQUEST_FOR}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                        placeholder={`${currency?.split("(")[0]}00.00`}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setQuantity(e.target.value)}
                        value={quantity}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <IconButton
                        disabled={
                          !amount ||
                          !text ||
                          !quantity ||
                          quantity <= 0 ||
                          amount <= 0
                        }
                        onClick={handleAdd}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header textAlign="right" as="h3">
                        Sub Total
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0] + Number(subTotalOne)}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row style={{ backgroundColor: "#F9FAFB" }}>
                    <Table.Cell>Expenses and travel</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  {otherTransactions.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.otherText}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherAmount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherQuantity}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        {tr.total}
                        <IconButton
                          onClick={() =>
                            dispatch(deleteOtherTransaction(tr.id))
                          }
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell>
                      <CustomAutoSelect
                        onChange={(e, value) => setOtherText(value)}
                        value={otherText}
                        label="Taxi fare"
                        data={TAXIFARE}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setOtherAmount(e.target.value)}
                        value={otherAmount}
                        placeholder={`${currency?.split("(")[0]}00.00`}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setOtherQuantity(e.target.value)}
                        value={otherQuantity}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <IconButton
                        disabled={
                          !otherQuantity ||
                          !otherAmount ||
                          !otherText ||
                          otherQuantity <= 0 ||
                          otherAmount <= 0
                        }
                        onClick={handleAddOther}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h3">Sub Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0] + subTotalTwo}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0] + total}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Discount %</Header>
                      <CustomAutoSelect
                        data={DISCOUNT}
                        onChange={(e, value) => setDiscount(value)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>{totalDiscount}</Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Billing Account Service Charge {companyCard?.basc} %
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>00.00</Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Platform and booking service charge {companyCard?.pbsc}{" "}
                        %
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>{totalPBSC}</Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">VAT / GST %</Header>
                      <TextField
                        onChange={(e) => setVAT(e.target.value)}
                        type="number"
                        inputProps={{ min: "0", max: "10", step: "1" }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>{totalVAT}</Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>{totalTwo}</Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Estimated total to be paid including VAT/GST
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0] + totalTwo}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <div style={{ marginBottom: "24px" }}>
                <CustomAutoSelect
                  onChange={(e, value) => setTimezone(value)}
                  label="Timezone"
                  width="30%"
                  data={TIMEZONE}
                  value={timezone}
                />
                <CustomDateTime
                  onChange={(e) => setExpiryDate(e.target.value)}
                  label="Expiry date"
                />
                {/* <TextField
                  id="date"
                  label="Expiry date"
                  onChange={(e) => setExpiryDate(e.target.value)}
                  value={expiryDate}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Header style={{ marginTop: "16px" }} as="h4">
                  Thank You For Your Business
                </Header>
                <Header
                  color="grey"
                  style={{ marginBottom: "16px", marginTop: "0" }}
                  as="h4"
                >
                  {companyCard.cname + ", " + companyCard.caddress}
                </Header>
                {!calculate.sendButton && (
                  <Button
                    disabled={
                      timezone === null ||
                      timezone === "" ||
                      expiryDate === null ||
                      !expiryDate ||
                      !serviceDate ||
                      privateClient.data === "" ||
                      !privateClient.data
                    }
                    onClick={handleCalculate}
                    loading={calculate.loading}
                    color="purple"
                  >
                    Calculate and Save
                  </Button>
                )}
                {calculate.sendButton && (
                  <Button
                    onClick={handleSend}
                    disabled={subTotalOne + subTotalTwo === 0}
                    color="purple"
                    loading={loading}
                  >
                    Send
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default ProvideQuotePRC;
