import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import swal from "sweetalert";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import CustomPassword from "components/Common/CustomPassword";
import CustomSelect from "components/Common/CustomSelect";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { CURRENT_COUNTRY, GENDER_DATA, EMAIL_REGEX } from "data";
import CustomDate from "components/Common/CustomDate";
import CustomRadio from "components/Common/CustomRadio";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { changeAdminRegInput } from "redux/actions/adminregAction";
import CustomCaptcha from "components/Common/CustomCaptcha";
import defaultAxios from "utils/axios";
import CustomPhone from "components/Common/CustomPhone";

const FormOne = () => {
  const classes = useFormStyles();
  const { data } = useSelector((state) => state.adminregReducer);
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState("");
  const [notValid, setNotValid] = useState(false);
  const [personalnotValid, setPersonalNotValid] = useState(false);
  const [notRegex, setNotRegex] = useState(false);
  const {
    profile_photo,
    title,
    first_name,
    last_name,
    Birthday,
    Sex,
    primary_email,
    personal_email,
    telephone_no,
    mobile_no,
    AddressLine1,
    AddressLine2,
    City,
    PostalCode,
    Country,
    Region,
    Nationality,
    other_nationality,
    password,
    confirm_password,
    additional_info,
    agree,
  } = data;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: value || checked,
    };
    dispatch(changeAdminRegInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeAdminRegInput({
        name,
        value,
      })
    );
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handlePersonalEmailChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeAdminRegInput({
        name,
        value,
      })
    );
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setPersonalNotValid(false);
    } else {
      setPersonalNotValid(true);
    }
  };

  const handleDateChange = (date) => {
    dispatch(
      changeAdminRegInput({
        name: "Birthday",
        value: date,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeAdminRegInput({
        name: "Country",
        value,
      })
    );
  };

  const handlePhone = (value) => {
    dispatch(
      changeAdminRegInput({
        name: "telephone_no",
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeAdminRegInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handleSubmit = async (e) => {
    const sendData = {
      title: data.title || "-",
      FirstName: data.first_name || "-",
      LastName: data.last_name || "-",
      email: data.primary_email || "-",
      personal_email: data.personal_email || "-",
      password: data.password || "-",
      Birthday: data.Birthday || "-",
      Sex: data.Sex || "-",
      telephone_no: data.telephone_no || "-",
      mobile_no: data.mobile_no || "-",
      AddressLine1: data.AddressLine1 || "-",
      AddressLine2: data.AddressLine2 || "-",
      Nationality: data.Nationality || "-",
      other_nationality: data.other_nationalities || "-",
      City: data.City || "-",
      PostalCode: data.PostalCode || "-",
      Country: data.Country || "-",
      Region: data.Region || "-",
      additional_info: data.additional_info || "-",
      gdpr: true,
    };
    try {
      const response = await defaultAxios.post(
        "api/manager-admin/pre-signup",
        sendData
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      {/* <CustomUpload label="Upload / take profile photo" onChange={fileChange} /> */}

      <CustomInput
        onChange={handleChange}
        value={title}
        name="title"
        label="Title"
        width="100%"
        required
      />
      <CustomInput
        onChange={handleChange}
        value={first_name}
        name="first_name"
        label="First Name(s)"
        width="100%"
        required
      />
      <CustomInput
        onChange={handleChange}
        value={last_name}
        name="last_name"
        label="Last Name"
        width="100%"
        required
      />
      <CustomDate
        onChange={handleDateChange}
        value={Birthday}
        name="Birthday"
        label="Date of Birth"
        width="100%"
        required
      />
      <CustomRadio
        onChange={handleChange}
        value={Sex}
        name="Sex"
        data={GENDER_DATA}
        label="Gender"
        width="100%"
      />
      <CustomInput
        onChange={handleEmailChange}
        value={primary_email}
        name="primary_email"
        label="Primary / Work email"
        width="100%"
        required
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomInput
        onChange={handlePersonalEmailChange}
        value={personal_email}
        name="personal_email"
        label="Personal email"
        width="100%"
        required
      />
      {personalnotValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <br />
      <CustomPhone
        onChange={handlePhone}
        value={telephone_no}
        name="telephone_no"
        label="Telephone number*"
        width="100%"
        required
      />
      <br />
      <CustomPhone
        onChange={handleMobile}
        value={mobile_no}
        name="mobile_no"
        label="Mobile number*"
        width="100%"
        required
      />
      <CustomInput
        onChange={handleChange}
        value={AddressLine1}
        name="AddressLine1"
        label="House number/name, Street name"
        width="100%"
        required
      />
      <CustomInput
        onChange={handleChange}
        value={AddressLine2}
        name="AddressLine2"
        label="Address line 2"
        width="100%"
      />
      <CustomInput
        onChange={handleChange}
        value={City}
        name="City"
        label="Town / City"
        width="100%"
        required
      />
      <CustomInput
        onChange={handleChange}
        label="County / Region"
        required
        width="100%"
        name="Region"
        value={Region}
      />
      <CustomInput
        onChange={handleChange}
        value={PostalCode}
        name="PostalCode"
        label="Post Code / Zip Code / Post office"
        width="100%"
        required
      />
      <CustomAutoSelect
        onChange={handleAutoCompleteCO}
        label="Country"
        moreInfo="Current country of residence"
        width="100%"
        value={Country}
        name="Country "
        data={CURRENT_COUNTRY}
        required
      />
      <CustomSelect
        onChange={handleChange}
        label="Current nationality"
        width="100%"
        name="Nationality"
        value={Nationality}
        data={CURRENT_COUNTRY}
      />

      <CustomPassword
        onChange={handleChange}
        label="Password"
        width="100%"
        name="password"
        value={password}
        required
      />
      <CustomPassword
        onChange={handleChange}
        label="Confirm password"
        width="100%"
        name="confirm_password"
        value={confirm_password}
        validation={password !== confirm_password}
        required
      />
      <CustomInput
        onChange={handleChange}
        label="Additional Information"
        name="additional_info"
        value={additional_info}
        width="100%"
      />

      <div
        className={classes.tc}
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              checked={agree}
              name="agree"
              color="primary"
            />
          }
          label="By submitting this form I agree with LC Privacy Policy, T&C, and GDPR"
        />
      </div>

      <CustomCaptcha onChange={handleCaptcha} />

      <div className={classes.buttonFlex}>
        <CustomButton
          disabled={
            !agree ||
            !title ||
            !first_name ||
            !last_name ||
            !primary_email ||
            notValid ||
            !Birthday ||
            !personal_email ||
            personalnotValid ||
            !telephone_no ||
            !mobile_no ||
            !AddressLine1 ||
            !City ||
            !PostalCode ||
            !Country ||
            password !== confirm_password ||
            !captcha
          }
          text="Submit"
          onClick={handleSubmit}
        />
      </div>
    </form>
  );
};

export default FormOne;
