import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { Checkbox } from "semantic-ui-react";
import Axios from "axios";
import moment from "moment";
import { Rating } from "@material-ui/lab";
import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";

const TimesheetFooter = ({ projectReport }) => {
  const todayDate = moment().format("DD/MM/YYYY");

  const [signature, setSignature] = useState({
    linSignature: "",
  });

  const [cacSignature, setcACSignature] = useState({
    cacSignature: "",
  });

  const [adminSignature, setAdminSignature] = useState({
    adminSignature: "",
  });

  var arrayBufferView = new Uint8Array(projectReport?.signature?.data?.data);
  var blob = new Blob([arrayBufferView], { type: "image/jpeg" });

  useEffect(() => {
    const getCACSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-client-signature/reportID/${projectReport?._id}`
        );

        setcACSignature({ ...cacSignature, cacSignature: response?.data });
      } catch (error) {
        console.log(error);
      }
    };

    const getLINSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-LIN-signature/reportID/${projectReport?._id}`
        );

        setSignature({ ...signature, linSignature: response?.data });
      } catch (error) {
        console.log(error);
      }
    };

    const getAdminSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-admin-signature/reportID/${projectReport?._id}`
        );

        setAdminSignature({ ...adminSignature, adminSignature: response?.data });
      } catch (error) {
        console.log(error);
      }
    };

    getCACSignature();
    getLINSignature();
    getAdminSignature();
  }, []);

  return (
    <>
      <Table.Cell>
        Signature
        <div>
          <img
            style={{ width: "130px" }}
            src={adminSignature.adminSignature}
            alt=""
          />
        </div>
      </Table.Cell>

      <Table.Cell>
        Signature
        <div>
          <img
            style={{ width: "130px" }}
            src={cacSignature.cacSignature}
            alt=""
          />
        </div>
      </Table.Cell>
    </>
  );
};

export default TimesheetFooter;
