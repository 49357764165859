import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "react-loading-screen";

// Custom Components
import CustomSelect from "components/Common/CustomSelect";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";
import CustomUpload from "components/Common/CustomUpload";
import CustomRadio from "components/Common/CustomRadio";
import Time from "components/Auth/LinguistsCollectiveBooking/Time";
import Cookies from "js-cookie";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import {
  LANG_COMB,
  ITP_SERVICE_REQUIRED,
  TYPE_INTERPRETING,
  SKILL_LEVEL_REQUIRED,
  SECURITY_CLEARANCE_LEVEL,
  CAR_PARKING,
  CURRENT_COUNTRY,
  GENDER_DATA,
  TIMEZONE,
  RARE_REGEX,
  LEVEL_REGEX
} from "data";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import {
  AppBar,
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  Toolbar,
} from "@material-ui/core";
import { changeIBTInput } from "redux/actions/ibtAction";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import { useParams } from "react-router-dom";
import history from "utils/history";
import swal from "sweetalert";
import Axios from "axios";

const FormTwo = () => {
  const { data } = useSelector((state) => state.ibtReducer);
  const { calender } = useSelector((state) => state.datetimeReducer);
  const dispatch = useDispatch();
  const { sppoobj } = useParams();
  const { sppoID } = useParams();
  const { clientID } = useParams();
  const { clientPC } = useParams();
  const { clientLN } = useParams();
  const { clientobj } = useParams();
  Cookies.set("sppoobj", sppoobj);
  Cookies.set("clientID", clientID);
  Cookies.set("incClientID", clientobj); // For stripe checkout
  const [loading, setLoading] = useState(false);

  const {
    purchase_order_number,
    timezone,
    interpreting_service_required,
    house_number_street_name,
    address_line_two,
    region,
    post_code,
    country,
    town,
    remote_address,
    source_language,
    source_language_level,
    target_language,
    target_language_level,
    typesof_interpreting_required,
    job_description,
    preferred_interpreter,
    gender,
    security_clearance,
    car_parking,
    additional_info,
    upload_file,
    agree,
  } = data;

  const classes = useFormStyles();
  const classesb = useLandingpageStyles();
  const handleNext = async () => {
    let total_Duration = calender.total_duration;
    // history.push('/inhouse-interpreter-booking/summary')

    swal(
      "Have you checked all details? Please note that after submission you will not be able to return to this page, on submission you will be directed to summary page. Are you ready to submit?",
      {
        icon: "warning",
        buttons: ["Cancel", "Yes"],
      }
    ).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        if (!total_Duration) {
          swal("kindly calculate the total duration! ");
        }

        let totalCount = calender.total_days;
        //
        let startdateArray = [];
        let enddateArray = [];
        let starttimeArray = [];
        let endtimeArray = [];

        //count 1
        if (totalCount.length === 1) {
          startdateArray.push(calender.startdateOne);
          enddateArray.push(calender.enddateOne);
          starttimeArray.push(calender.starttimeOne);
          endtimeArray.push(calender.endtimeOne);
        }
        //count 1
        if (totalCount.length === 2) {
          startdateArray.push(calender.startdateOne, calender.startdateTwo);

          enddateArray.push(calender.enddateOne, calender.enddateTwo);

          starttimeArray.push(calender.starttimeOne, calender.starttimeTwo);

          endtimeArray.push(calender.endtimeOne, calender.endtimeTwo);
        }

        //count 1
        if (totalCount.length === 3) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree
          );
        }

        //count 1
        if (totalCount.length === 4) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour
          );
        }
        //count 1
        if (totalCount.length === 5) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive
          );
        }
        //count 1
        if (totalCount.length === 6) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix
          );
        }

        ///DONE TILL HERE ////////////////////////////////////////////////////////
        //count 1
        if (totalCount.length === 7) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven
          );
        }
        //count 1
        if (totalCount.length === 8) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight
          );
        }
        //count 1
        if (totalCount.length === 9) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine
          );
        }
        //count 1
        if (totalCount.length === 10) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen
          );
        }
        //count 1
        if (totalCount.length === 11) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven
          );
        }
        //count 1
        if (totalCount.length === 12) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve
          );
        }
        //count 1
        ////////////////////////////
        if (totalCount.length === 13) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen
          );
        }
        //count 1
        if (totalCount.length === 14) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen
          );
        }

        /////////////////////////////////////////////////////////////////////////////////////////////
        //count 1
        if (totalCount.length === 15) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen
          );
        }
        //count 1
        if (totalCount.length === 16) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen
          );
        }
        //count 1
        if (totalCount.length === 17) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen
          );
        }
        //count 1
        if (totalCount.length === 18) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen
          );
        }
        //count 1
        if (totalCount.length === 19) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen
          );
        }
        //count 1
        if (totalCount.length === 20) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty
          );
        }
        //count 1
        if (totalCount.length === 21) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne
          );
        }
        //count 1
        if (totalCount.length === 22) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo
          );
        }
        //count 1
        if (totalCount.length === 23) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23
          );
        }
        //count 1
        if (totalCount.length === 24) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24
          );
        }
        //count 1
        if (totalCount.length === 25) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25
          );
        }
        //count 1
        if (totalCount.length === 26) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26
          );
        }
        //count 1
        if (totalCount.length === 27) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26,
            calender.startdate27
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26,
            calender.enddate27
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26,
            calender.starttime27
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26,
            calender.endtime27
          );
        }

        //count 1
        if (totalCount.length === 28) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26,
            calender.startdate27,
            calender.startdate28
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26,
            calender.enddate27,
            calender.enddate28
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26,
            calender.starttime27,
            calender.starttime28
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26,
            calender.endtime27,
            calender.endtime28
          );
        }

        //count 1
        if (totalCount.length === 29) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26,
            calender.startdate27,
            calender.startdate28,
            calender.startdate29
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26,
            calender.enddate27,
            calender.enddate28,
            calender.enddate29
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26,
            calender.starttime27,
            calender.starttime28,
            calender.starttime29
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26,
            calender.endtime27,
            calender.endtime28,
            calender.endtime29
          );
        }

        //count 1
        if (totalCount.length === 30) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26,
            calender.startdate27,
            calender.startdate28,
            calender.startdate29,
            calender.startdate30
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26,
            calender.enddate27,
            calender.enddate28,
            calender.enddate29,
            calender.enddate30
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26,
            calender.starttime27,
            calender.starttime28,
            calender.starttime29,
            calender.starttime30
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26,
            calender.endtime27,
            calender.endtime28,
            calender.endtime29,
            calender.endtime30
          );
        }

        //count 1
        if (totalCount.length === 31) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26,
            calender.startdate27,
            calender.startdate28,
            calender.startdate29,
            calender.startdate30,
            calender.startdate31
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26,
            calender.enddate27,
            calender.enddate28,
            calender.enddate29,
            calender.enddate30,
            calender.enddate31
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26,
            calender.starttime27,
            calender.starttime28,
            calender.starttime29,
            calender.starttime30,
            calender.starttime31
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26,
            calender.endtime27,
            calender.endtime28,
            calender.endtime29,
            calender.endtime30,
            calender.endtime31
          );
        }

        Cookies.set("total_Duration", total_Duration);
        //noe append to the backend
        let fd = new FormData();

        fd.append("purchase_order_number", purchase_order_number);
        fd.append("timezone", timezone);
        fd.append("upload_file", upload_file);
        fd.append("source_language", source_language);
        fd.append("target_language", target_language);
        fd.append("source_language_level", source_language_level);
        fd.append("target_language_level", target_language_level);
        fd.append(
          "interpreting_service_required",
          interpreting_service_required
        );
        fd.append("house_number_street_name", house_number_street_name);
        fd.append("address_line_two", address_line_two);
        fd.append("region", region);
        fd.append("post_code", post_code);
        fd.append("country", country);
        fd.append("remote_address", remote_address);
        fd.append(
          "typesof_interpreting_required",
          typesof_interpreting_required
        );
        fd.append("job_description", job_description);
        fd.append("preferred_interpreter", preferred_interpreter);
        fd.append("gender", gender);
        fd.append("security_clearance", security_clearance);
        fd.append("car_parking", car_parking);
        fd.append("car_parking", security_clearance);
        fd.append("additional_info", additional_info);
        fd.append("agree", agree);
        fd.append("SPPOID", sppoobj);
        fd.append("userID", sppoID);
        fd.append("clientobj", clientobj);
        fd.append("startdateArray", startdateArray);
        fd.append("enddateArray", enddateArray);
        fd.append("starttimeArray", starttimeArray);
        fd.append("endtimeArray", endtimeArray);
        fd.append("total_Duration", total_Duration);

        try {
          const response = Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/sppo/inhouse-interpreting-byEmail-assignment`,
            fd
          )
            .then((response) => {
              //for currency,
              Cookies.set("currency", response?.data.message.new_currency);

              // //id
              Cookies.set("bookingid", response?.data.message.token);

              // //totalPriceForTranslation
              Cookies.set(
                "total_Overnight_charges",
                response?.data.message.total_Overnight_charges
              );

              // //totalPriceForCertificate
              Cookies.set(
                "total_PriceForInterpriting",
                response?.data.message.total_PriceForInterpriting
              );

              // //totalPriceForQualityCheck
              Cookies.set(
                "total_TravelTotal_Wages",
                response?.data.message.total_TravelTotal_Wages
              );
              Cookies.set(
                "inhouseClientID",
                response?.data.message.inhouseClientID
              );
              Cookies.set(
                "tatal_Food_Overnight",
                response?.data.message.tatal_Food_Overnight
              );
            })
            .then(function () {
              setLoading(false);
              history.push("/inhouse-interpreter-booking/client/summary");
              //  window.location = "/account-client/book-interpreter/summary";
            });
        } catch (error) {
          // setLoading(true)
          if (error.response) {
            swal({
              title: "Oops!",
              text: `${error?.response?.data?.error}`,
              icon: "error",
              buttons: false,
            });
          }
        }
      }
    });
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    dispatch(
      changeIBTInput({
        name,
        value: !agree,
      })
    );
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeIBTInput({
        name,
        value: value || checked,
      })
    );
  };

  const handleExpertise = (value) => {
    if (target_language?.includes("R") || source_language?.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(
        LEVEL_REGEX
       );
    }
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "country",
        value,
      })
    );
  };

  const handleAutoCompleteTZ = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "timezone",
        value,
      })
    );
  };

  //file upload
  const handleFiles = (e) => {
    const value = e.target.files[0];

    if (value.size >= 5000000) {
      swal({
        title: "Kindly select less than 5 mb file",
      });
    } else {
      dispatch(
        changeIBTInput({
          name: "upload_file",
          value,
        })
      );
    }
  };

  const isAllow =
    !calender.startdateOne ||
    !calender.starttimeOne ||
    !calender.durationOne ||
    calender.flag ||
    !interpreting_service_required ||
    (interpreting_service_required.includes("FTF") &&
      !house_number_street_name) ||
    (interpreting_service_required.includes("FTF") && !post_code) ||
    (interpreting_service_required.includes("FTF") && !country) ||
    (interpreting_service_required.includes("Remote") && !remote_address) ||
    !source_language ||
    !target_language ||
    !typesof_interpreting_required ||
    !job_description ||
    !agree;

  const handleChangeSO = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "source_language",
        value,
      })
    );
  };

  const handleChangeTA = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "target_language",
        value,
      })
    );
  };

  const handleChangeTZ = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "timezone",
        value,
      })
    );
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        {/* "Developer- add more option to book a multi day job. One Day job or Multi day job, allow easy pick without making mistake"  */}
        <div
          style={{
            margin: "0",
            marginTop: "15px",
            fontSize: "0.9rem",
            fontWeight: "bold",
          }}
        >
          <AppBar position="static">
            <Toolbar>
              <h3>
                {clientID} - {clientLN} - {clientPC}
              </h3>
            </Toolbar>
          </AppBar>
        </div>

        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Your booking ref/ Purchase order no"
          name="purchase_order_number"
          value={purchase_order_number}
          placeholder="Enter your reference number or purchase order number"
        />

        <CustomAutoSelect
          required
          onChange={handleChangeTZ}
          label="Select timezone"
          width="100%"
          data={TIMEZONE}
          name="timezone"
          value={timezone}
          //helperText= {moment.tz.guess()}
        />

        <Time />

        <CustomSelect
          required
          onChange={handleChange}
          label="Interpreting service required"
          data={ITP_SERVICE_REQUIRED}
          width="100%"
          name="interpreting_service_required"
          value={interpreting_service_required}
        />

        {/* Developer - make this conditional if remote selected only be able to add remote platform details and phone number. If FTF selected show address field, if Remote address optional for invoicing purpose only. FTF onsite must provide venue details clearly including any department or building block */}

        {/* moreInfo="Developer - customer must select two, allow customer to book easily another combination which should become two jobs. System must be able to check match of languages 'not one both ie English <> Bengali '. Apply condition both selection cannot be same"  */}
        {interpreting_service_required !==
        "Remote Interpreting (RI) / Video Remote Interpreting (VRI)" ? (
          <Fragment>
            <CustomInputSingle
              required
              onChange={handleChange}
              width="100%"
              label="House Number / Name, Street Name"
              name="house_number_street_name"
              value={house_number_street_name}
              placeholder="e.g. Flat/Building Name, 101 Example Street"
            />
            <CustomInputSingle
              onChange={handleChange}
              width="100%"
              label="Address Line 2"
              name="address_line_two"
              value={address_line_two}
              placeholder="Additional Address Information"
            />
            <CustomInputSingle
              onChange={handleChange}
              width="100%"
              label="County / Region"
              name="region"
              value={region}
              placeholder="Your County, Division or State Name"
            />
            <CustomInputSingle
              required
              onChange={handleChange}
              width="100%"
              label="Post / Zip code"
              name="post_code"
              value={post_code}
              placeholder="Your Post Code, Zip Code or Post Office Name"
            />
            <CustomAutoSelect
              onChange={handleAutoCompleteCO}
              label="Country"
              moreInfo="Current country of residence"
              width="100%"
              name="country"
              value={country}
              data={CURRENT_COUNTRY}
              required
            />
          </Fragment>
        ) : (
          <CustomBigInput
            required
            onChange={handleChange}
            width="100%"
            name="remote_address"
            value={remote_address}
            label="Remote / Telephone details"
            moreInfo="Please provide details Zoom link, Skype, phone number etc"
            placeholder="How linguist will join you? e.g. Zoom, Microsoft Team link, conference phone number etc."
          />
        )}
        <CustomAutoSelect
          required
          onChange={handleChangeSO}
          label="Source language"
          data={LANG_COMB}
          width="100%"
          name="source_language"
          value={source_language}
        />
        <CustomSelect
          onChange={handleChange}
          label="Please indicate source language skill level "
          moreInfo="Please indicate source language skills level required for this assignment (1 - 5)"
          data={SKILL_LEVEL_REQUIRED.filter((value) => handleExpertise(value))}
          width="100%"
          name="source_language_level"
          value={source_language_level}
        />
        <CustomAutoSelect
          required
          onChange={handleChangeTA}
          label="Target language"
          data={LANG_COMB.filter((value) => value !== source_language)}
          width="100%"
          name="target_language"
          value={target_language}
        />
        <CustomSelect
          required
          onChange={handleChange}
          label="Please indicate target language skill level"
          moreInfo="Please indicate source language skills level required for this assignment (1 - 5)"
          data={SKILL_LEVEL_REQUIRED.filter((value) => handleExpertise(value))}
          width="100%"
          name="target_language_level"
          value={target_language_level}
        />
        {/* (Developer* - this is a mandatory */}
        <CustomSelect
          required
          onChange={handleChange}
          label="Type/s of Interpreting required"
          moreInfo="Please pick the most relevant one/s)"
          data={TYPE_INTERPRETING}
          width="100%"
          name="typesof_interpreting_required"
          value={typesof_interpreting_required}
        />
        <CustomBigInput
          required
          onChange={handleChange}
          width="100%"
          label="Job description"
          moreInfo="Please provide brief information for the benefit of linguist's preparation"
          name="job_description"
          value={job_description}
          placeholder=" Provide details for interpreter e.g. solicitor conference, police interview, visiting GP etc."
        />
        <CustomBigInputSingle
          onChange={handleChange}
          width="100%"
          label="Preferred Linguist / Service provider ID number"
          moreInfo="LC will try its best, but cannot guarantee"
          name="preferred_interpreter"
          value={preferred_interpreter}
          placeholder="Provide details of the preferred linguist e.g. LIN/PRO12345 - Jane Miller"
        />
        <CustomRadio
          onChange={handleChange}
          label="Gender"
          moreInfo="Please note that we can not guarantee gender"
          data={GENDER_DATA}
          width="100%"
          value={gender}
        />
        <CustomSelect
          onChange={handleChange}
          label="Please indicate security clearance level required"
          moreInfo="Please indicate security clearance level required for this assignment"
          data={SECURITY_CLEARANCE_LEVEL}
          width="100%"
          name="security_clearance"
          value={security_clearance}
        />
        {interpreting_service_required.includes("Face") ? (
          <CustomSelect
            onChange={handleChange}
            label="Do you have car parking for linguist?"
            data={CAR_PARKING}
            width="100%"
            name="car_parking"
            value={car_parking}
          />
        ) : null}
        <CustomInput
          onChange={handleChange}
          label="Additional information and direction for linguist"
          width="100%"
          name="additional_info"
          value={additional_info}
          placeholder="Provide any other information including direction that you would like linguist to have"
        />
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <CustomUpload
            color="primary"
            onChange={handleFiles}
            accept=".pdf,.docx,.JPEG"
            name="upload_file"
            width="100%"
            label="Upload any script or instruction here, pdf or photos/JPEG only)"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button color="secondary">
            {upload_file ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Icon name="file pdf" size="big" />
                <p
                  style={{
                    margin: "0",
                    marginTop: "7px",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  {upload_file.name}
                </p>
                <p>{upload_file?.lastModifiedDate?.toDateString()} </p>
                <p style={{ margin: "0" }}>
                  {(upload_file.size / 1024).toFixed(2)}Kb{" "}
                </p>
              </div>
            ) : null}
          </Button>
        </div>

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <h4>INC referral Linguist/SPPO ID: {sppoID}</h4>
        </div>
        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <p>
            <Checkbox
              onChange={handleCheckbox}
              checked={agree}
              name="agree"
              color="primary"
            />
            By submitting this form I agree with Linguists Collective
            <a
              target="_blank"
              href="http://linguistscollective.com/landing-page/terms-and-conditions"
            >
              {" "}
              Service Agreement{" "}
            </a>
            ,
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/privacy-policy"
            >
              Privacy Policy{" "}
            </a>
            and{" "}
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/terms-and-conditions"
            >
              {" "}
              T&C
            </a>
          </p>
        </div>

        <div className={classes.buttonFlex}>
          <CustomButton
            text="Submit"
            disabled={isAllow}
            value={agree}
            className={`${classesb.button}`}
            onClick={handleNext}
          />
        </div>
      </form>
    </div>
  );
};

export default FormTwo;
