import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import history from "utils/history";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import {
  changeInput,
  updateProfileLinguistPageThree,
} from "redux/actions/lcAction";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import Cookies from "js-cookie";
import Axios from "axios";
import { Button } from "semantic-ui-react";

const PageThree = ({ setReload }) => {
  const { data } = useSelector((state) => state.lcReducer);
  const dispatch = useDispatch();
  const token = Cookies.get("LINtoken");
  const [loader, setLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [next, setNext] = useState(false);

  const getLinguistDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/get-details-page3`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        updateProfileLinguistPageThree({
          bank_account_fullname: response?.data.bank_account_fullname,
          bank_sort_code: response?.data.bank_sort_code,
          bank_account_number: response?.data.bank_account_number,
          bank_branch_name: response?.data.bank_branch_name,
          bank_iban: response?.data.bank_iban,
          bank_swift_code: response?.data.bank_swift_code,
          bank_name: response?.data.bank_name,
          bank_branch_address: response?.data.bank_branch_address,
          bank_additional_info: response?.data.bank_additional_info,
          bic_no: response?.data.bic_no,
          bank_ifsc_code: response?.data.bank_ifsc_code,
          institution_number: response?.data.institution_number,
          branch_code: response?.data.branch_code,
          aba: response?.data.aba,
          currency: response?.data.currency,
        })
      );
      setLoader(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  const {
    bank_account_fullname,
    bank_sort_code,
    bank_account_number,
    bank_branch_name,
    bank_iban,
    bank_swift_code,
    bank_ifsc_code,
    bank_name,
    bank_branch_address,
    currency,
    bank_additional_info,
    Country,
    aba,
    branch_code,
    institution_number,
  } = data;

  const classes = useFormStyles();

  const handleSave = async (e) => {
    e.preventDefault();
    let fd = new FormData();
    setSaveLoader(true);

    fd.append("bank_account_fullname", data.bank_account_fullname || "");
    fd.append("bank_sort_code", data.bank_sort_code || "");
    fd.append("bank_account_number", data.bank_account_number || "");
    fd.append("bank_branch_name", data.bank_branch_name || "");
    fd.append("bank_iban", data.bank_iban || "");
    fd.append("bank_swift_code", data.bank_swift_code || "");
    fd.append("bank_name", data.bank_name || "");
    fd.append("bank_branch_address", data.bank_branch_address || "");
    fd.append("bank_additional_info", data.bank_additional_info || "");
    fd.append("bic_no", data.bic_no || "");
    fd.append("bank_ifsc_code", data.bank_ifsc_code || "");
    fd.append("institution_number", data.institution_number || "");
    fd.append("branch_code", data.branch_code || "");
    fd.append("aba", data.aba || "");
    fd.append("currency", data.currency || "");

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-full-registration`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((val) => {
        setReload(Math.random());
      });
      setSaveLoader(false);
      setNext(true);
    } catch (error) {
      console.log(error.response);
      setSaveLoader(false);
    }
  };

  const handleBack = () => {
    history.push("/linguist/full-registration-p2");
  };

  const handleNext = () => {
    history.push("/linguist/full-registration-p4");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInput({
        name,
        value,
      })
    );
  };

  // if (loader) {
  //   return (
  //     <Dimmer active inverted>
  //       <Loader size="large">Loading</Loader>
  //     </Dimmer>
  //   );
  // }

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomBigInputSingle
          onChange={handleChange}
          width="100%"
          label=" Your bank account full name"
          moreInfo="Personal and bank details will be shared with payment processor"
          name="bank_account_fullname"
          value={bank_account_fullname}
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="Account Number"
          moreInfo="Please provide relevant details as used in your Country of residence and for international payment number"
          width="100%"
          name="bank_account_number"
          value={bank_account_number}
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Branch name"
          width="100%"
          name="bank_branch_name"
          value={bank_branch_name}
        />
        {Country.includes("United States") ? (
          <CustomInputSingle
            onChange={handleChange}
            label="ABA"
            width="100%"
            name="aba"
            value={aba}
          />
        ) : null}
        {Country.includes("United Kingdom") ? (
          <CustomInputSingle
            onChange={handleChange}
            label="Sort code"
            width="1003%"
            name="bank_sort_code"
            value={bank_sort_code}
          />
        ) : null}
        {Country.includes("Canada") ? (
          <React.Fragment>
            <CustomInputSingle
              onChange={handleChange}
              label="Branch Code"
              width="100%"
              name="branch_code"
              value={branch_code}
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Institution Number"
              width="100%"
              name="institution_number"
              value={institution_number}
            />
          </React.Fragment>
        ) : null}
        <CustomInputSingle
          onChange={handleChange}
          label="IBAN"
          width="100%"
          name="bank_iban"
          value={bank_iban}
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Swift / BIC code"
          name="bank_swift_code"
          value={bank_swift_code}
        />
        {Country !== "United States" &&
        Country !== "United Kingdom" &&
        Country !== "Canada" ? (
          <CustomInputSingle
            onChange={handleChange}
            label="Sort Code / IFSC Code "
            width="100%"
            name="bank_ifsc_code"
            value={bank_ifsc_code}
          />
        ) : null}
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Name of the bank"
          name="bank_name"
          value={bank_name}
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Address of bank branch"
          name="bank_branch_address"
          value={bank_branch_address}
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Additional information"
          name="bank_additional_info"
          value={bank_additional_info}
        />
        <CustomBigInputSingle
          onChange={handleChange}
          width="100%"
          label="Preferred currency"
          moreInfo="e.g. GBP, EURO, Chinese Yuan / Renminbi"
          name="currency"
          value={currency}
        />

        <div className={classes.buttonFlex} style={{ marginTop: "24px" }}>
          <Button basic color="purple" onClick={handleBack}>
            Back
          </Button>
          {!next ? (
            <Button loading={saveLoader} color="purple" onClick={handleSave}>
              Save
            </Button>
          ) : (
            <Button color="green" onClick={handleNext}>
              Next
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default PageThree;
