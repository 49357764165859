import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { getInactiveEmployeeList } from "../../../action/manager";
import Moment from "moment";
import "jspdf-autotable";
import SearchView from "./Search/SearchView";
import Action from "./Action"



const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadList();
  }, [filter]);

  const loadList = () => {
    getInactiveEmployeeList().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        if (filtered.length > 0) {
          setValues({
            ...values,
            bookingList: filtered,
          });
          setFiltered([]);
        } else {
          setValues({ ...values, bookingList: data });
        }
      }
    });
  };



  return (
    <>
      <SearchView
        load={loadList}
        show={show}
        setShow={setShow}
        SearchText={SearchText}
        setSearchText={setSearchText}
        setFiltered={setFiltered}
        setFilter={setFilter}
        values={bookingList}
      // handleSearch={handleSearch}
      />
      <Paper className={classes.root}>
        {show && (
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" id="export">
                <TableHead>
                  <TableRow>
                    <TableCell>Unique ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell>Registration Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookingList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((list) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={list._id}
                      >
                        <TableCell key={list._id} align={list.align}>
                          <Link to={`/admin/view-employee/${list._id}`}>
                            {list.id}
                          </Link>
                        </TableCell>
                        <TableCell>{list.title}</TableCell>
                        <TableCell>{list.FirstName}</TableCell>
                        <TableCell>{list.LastName}</TableCell>
                        <TableCell>{list.email}</TableCell>
                        <TableCell>
                          {Moment(list.createdAt).format(
                            "DD MMM YYYY - kk:mm:ss"
                          )}
                        </TableCell>

                        <TableCell>
                          <Action id={list?._id} load={loadList} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </>
  );
}
