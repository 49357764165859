import React, { useState } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import SearchData from "./SearchData";

const cRef = React.createRef();

const CashbookPage = () => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  return (
    
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={12}
          style={{
            margin: "auto",
          }}
        >
          <SearchData />
        </GridItem>

      </GridContainer>
    
  );
};

export default CashbookPage;
