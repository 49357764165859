import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import { SECURITY_CLEARANCE_LEVEL } from "data";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import moment from "moment";

const SecurityClearance = () => {
  const [edit, setEdit] = useState(false);
  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updateProfileReducer);
  const handleEdit = (e) => {
    setEdit(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleFiles = async (e) => {
    dispatch(
      changeLinguistProfileInput({
        name: e.target.name,
        value: e.target.files[0],
      })
    );
  };

  const handleUpdate = async (e) => {
    let fd = new FormData();

    fd.append("criminal_record", data.criminal_record || "");
    fd.append("security_clearance", data.security_clearance || "");
    fd.append("title_security_clearance_1", data.title_security_clearance_1 || "");
    fd.append("title_security_clearance_2", data.title_security_clearance_2 || "");
    fd.append("title_security_clearance_3", data.title_security_clearance_3 || "");
    fd.append("title_security_clearance_4", data.title_security_clearance_4 || "");

    if (data.security_clearance_document_1?.size) {
      fd.append(
        "security_clearance_document_1",
        data.security_clearance_document_1
      );
    }

    if (data.security_clearance_document_2?.size) {
      fd.append(
        "security_clearance_document_2",
        data.security_clearance_document_2
      );
    }

    if (data.security_clearance_document_3?.size) {
      fd.append(
        "security_clearance_document_3",
        data.security_clearance_document_3
      );
    }

    if (data.security_clearance_document_4?.size) {
      fd.append(
        "security_clearance_document_4",
        data.security_clearance_document_4
      );
    }

    if (data.security_certificate_expiry_date_1) {
      fd.append(
        "security_certificate_expiry_date_1",
        data.security_certificate_expiry_date_1
      );
    }
    if (data.security_certificate_expiry_date_2) {
      fd.append(
        "security_certificate_expiry_date_2",
        data.security_certificate_expiry_date_2
      );
    }
    if (data.security_certificate_expiry_date_3) {
      fd.append(
        "security_certificate_expiry_date_3",
        data.security_certificate_expiry_date_3
      );
    }
    if (data.security_certificate_expiry_date_4) {
      fd.append(
        "security_certificate_expiry_date_4",
        data.security_certificate_expiry_date_4
      );
    }

    try {
      const response = await Axios.put(
        `/api/manager-admin/update-linguist/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit(false);
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            label="Criminal record"
            value={data.criminal_record}
          />
          <CustomTextInput
            label="Criminal record details"
            value={data.criminal_record_details == "undefined" ? "" : data.criminal_record_details}
          />
          <hr />
          <CustomTextInput
            label="Security / police clearance"
            value={data.security_clearance}
          />

          {edit ? (
            <CustomTextInput
              onChange={handleChange}
              name="title_security_clearance_1"
              label="Security clearance"
              select={SECURITY_CLEARANCE_LEVEL}
              svalue={data.title_security_clearance_1}
            />
          ) : (
            <CustomTextInput
              label="Security clearance"
              value={data.title_security_clearance_1}
            />
          )}
          {edit ? (
            <CustomTextInput
              name="security_certificate_expiry_date_1"
              onChange={handleChange}
              label=" Security clearance expiry date"
              dlabel="Expiry date"
              dvalue={data.security_certificate_expiry_date_1}
              date={true}
            />
          ) : (
            <CustomTextInput
              label="Security clearance expiry date"
              value={data.security_certificate_expiry_date_1 ? moment(data.security_certificate_expiry_date_1).format(
                "DD/MM/yyyy"
              ) : ""}
            />
          )}
          {edit ? (
            <input
              type="file"
              name="security_clearance_document_1"
              onChange={handleFiles}
              accept="application/pdf"
            />
          ) : null}
          <a href={`/admin/linguist-security-clearence-1/${data._id}`}>
            {" "}
            View Security Clearence Document
          </a>

          <hr />

          {edit ? (
            <CustomTextInput
              onChange={handleChange}
              name="title_security_clearance_2"
              label="Security clearance"
              select={SECURITY_CLEARANCE_LEVEL}
              svalue={data.title_security_clearance_2}
            />
          ) : (
            <CustomTextInput
              label="Security clearance"
              value={data.title_security_clearance_2}
            />
          )}
          {edit ? (
            <CustomTextInput
              name="security_certificate_expiry_date_2"
              onChange={handleChange}
              label=" Security clearance expiry date"
              dlabel="Expiry date"
              dvalue={data.security_certificate_expiry_date_2}
              date={true}
            />
          ) : (
            <CustomTextInput
              label="Security clearance expiry date"
              value={data.security_certificate_expiry_date_2 ? moment(data.security_certificate_expiry_date_2).format(
                "DD/MM/yyyy"
              ) : ""}
            />
          )}

          {edit ? (
            <input
              type="file"
              name="security_clearance_document_2"
              onChange={handleFiles}
              accept="application/pdf"
            />
          ) : null}
          <a href={`/admin/linguist-security-clearence-2/${data._id}`}>
            {" "}
            View Security Clearence Document
          </a>

          <hr />

          {edit ? (
            <CustomTextInput
              onChange={handleChange}
              name="title_security_clearance_3"
              label="Security clearance"
              select={SECURITY_CLEARANCE_LEVEL}
              svalue={data.title_security_clearance_3}
            />
          ) : (
            <CustomTextInput
              label="Security clearance"
              value={data.title_security_clearance_3}
            />
          )}
          {edit ? (
            <CustomTextInput
              name="security_certificate_expiry_date_3"
              onChange={handleChange}
              label=" Security clearance expiry date"
              dlabel="Expiry date"
              dvalue={data.security_certificate_expiry_date_3}
              date={true}
            />
          ) : (
            <CustomTextInput
              label="Security clearance expiry date"
              value={data.security_certificate_expiry_date_3 ? moment(data.security_certificate_expiry_date_3).format(
                "DD/MM/yyyy"
              ) : ""}
            />
          )}
          {edit ? (
            <input
              type="file"
              name="security_clearance_document_3"
              onChange={handleFiles}
              accept="application/pdf"
            />
          ) : null}
          <a href={`/admin/linguist-security-clearence-3/${data._id}`}>
            {" "}
            View Security Clearence Document
          </a>

          <hr />

          {edit ? (
            <CustomTextInput
              onChange={handleChange}
              name="title_security_clearance_4"
              label="Security clearance"
              select={SECURITY_CLEARANCE_LEVEL}
              svalue={data.title_security_clearance_4}
            />
          ) : (
            <CustomTextInput
              label="Security clearance"
              value={data.title_security_clearance_4}
            />
          )}
          {edit ? (
            <CustomTextInput
              name="security_certificate_expiry_date_4"
              onChange={handleChange}
              label=" Security clearance expiry date"
              dlabel="Expiry date"
              dvalue={data.security_certificate_expiry_date_4}
              date={true}
            />
          ) : (
            <CustomTextInput
              label="Security clearance expiry date"
              value={data.security_certificate_expiry_date_4 ? moment(data.security_certificate_expiry_date_4).format(
                "DD/MM/yyyy"
              ) : ""}
            />
          )}
          {edit ? (
            <input
              type="file"
              name="security_clearance_document_4"
              onChange={handleFiles}
              accept="application/pdf"
            />
          ) : null}
          <a href={`/admin/linguist-security-clearence-4/${data._id}`}>
            {" "}
            View Security Clearence Document
          </a>

          <hr />

          <a href={`/admin/upload-linguist-documents/${data._id}`}>
            {" "}
            Upload Documents
          </a>

        </div>
        {/* <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div> */}
      </div>

      {edit ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default SecurityClearance;
