import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Action from "./Action";
import Axios from "utils/axios";
import { useToasts } from "react-toast-notifications";

import SearchView from "./Search/SearchView";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable() {
  const { addToast } = useToasts();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState([]);

  useEffect(() => {
    loadList();
  }, [filter]);

  const loadList = async () => {
    const response = await Axios.get(
      "/api/manager-admin/delete-account-request-list-lin"
    )
      .then((res) => {
        if (filtered.length > 0) {
          setValues(filtered);
          setFiltered([]);
        } else {
          setValues(res.data);
        }
        // setValues(res.data || [])
      })
      .catch((err) => console.log(err));
  };

  return (
    <Paper className={classes.root}>
      <SearchView
        load={loadList}
        show={show}
        setShow={setShow}
        SearchText={SearchText}
        setSearchText={setSearchText}
        setFiltered={setFiltered}
        setFilter={setFilter}
        values={values}
        // handleSearch={handleSearch}
      />
      {show ? (
        <div>
          {" "}
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" id="export">
              <TableHead>
                <TableRow>
                  <TableCell>Linguist ID</TableCell>
                  <TableCell>Firstname</TableCell>
                  <TableCell>Lastname</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Delete Request Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((list) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={list._id}
                    >
                      <TableCell key={list._id} align={list.align}>
                        <Link to={`/admin/view-linguist-profile/${list._id}`}>
                          {list.id}
                        </Link>
                      </TableCell>
                      <TableCell>{list?.FirstName}</TableCell>
                      <TableCell>{list?.LastName}</TableCell>
                      <TableCell>{list?.reason}</TableCell>
                      <TableCell>{list?.deleteRequestDate}</TableCell>
                      <TableCell>
                        <Action load={loadList} id={list?._id} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={values.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      ) : null}
    </Paper>
  );
}
