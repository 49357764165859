import React, { useState, useEffect } from "react";
import TableOne from "./Interpreting/TableOne";
import TransTableOne from "./Translation/TableOne";
import TransTableTwo from "./Translation/TableTwo";
import TransTableThree from "./Translation/TableThree";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Header } from "semantic-ui-react";
import BAC from "./Interpreting/BAC";
import BAC_Bulk_Interpreting from "./Interpreting/BulkInterpreting/BAC_Bulk_Interpreting";
import INC from "./Interpreting/INC";
import Cookies from "js-cookie";
import Axios from "axios";

const AssignmentView = ({ data, type }) => {
  const classes = useDashboardStyles();
  const [linData, setLinData] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("LINtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setLinData(response?.data);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);
  return (
    <div className={classes.listDiv}>
      <Header as="h4">CAC Interpreting Assignments</Header>
      <TableOne
        details={linData}
        interpretingData={
          type === "interpreting" && data.includes("CAC") && data
        }
      />
      <Header as="h4">BAC Interpreting Assignments</Header>
      <BAC
        details={linData}
        interpretingData={
          type === "interpreting" && data.includes("BAC") && data
        }
      />
      <Header as="h4">BAC Bulk Interpreting Assignments</Header>
      <BAC_Bulk_Interpreting
        details={linData}
        interpretingData={
          type === "bulk-interpreting" && data.includes("BAC") && data
        }
      />
      <Header as="h4">Accepted Marketplace INC Interpreting Assignments</Header>
      <INC
        details={linData}
        interpretingData={
          type === "interpreting" &&
          (data.includes("TI") || data.includes("IT")) &&
          data
        }
      />
      <Header as="h4">CAC Translation Assignments</Header>
      <TransTableOne
        translationData={type === "translation" && data.includes("CAC") && data}
      />
      <Header as="h4">BAC Translation Assignments</Header>
      <TransTableTwo
        translationData={type === "translation" && data.includes("BAC") && data}
      />
      <Header as="h4">Accepted Marketplace INC Translation Assignments</Header>
      <TransTableThree
        translationData={
          type === "translation" &&
          (data.includes("TI") || data.includes("IT")) &&
          data
        }
      />
    </div>
  );
};

export default AssignmentView;
