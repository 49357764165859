import CustomInput from "components/Common/CustomInput";

import { BottomDetailsDiv } from "./style";

const BottomDetails = () => {
  return (
    <BottomDetailsDiv>
      <h3>Thank You For Your Business</h3>
      <h4>
        Linguists Collective Ltd, 38 Mill Street, Bedford, Bedfordshire, MK40
        3HD
      </h4>
      <div className="wrapper">
        <div className="details-div">
          <div className="details">
            <div className="left">
              <p>Input status:</p>{" "}
            </div>
            <div className="right">
              <p>Paid/Unpaid/Over due/Disputed</p>
            </div>
          </div>
          <div className="details">
            <div className="left">
              <p>Linguist Note:</p>
            </div>
            <div className="right">
              <CustomInput width="100%" />
            </div>
          </div>
          <div className="details">
            <div className="left">
              <p>LC Admin Note:</p>
            </div>
            <div className="right">
              <CustomInput width="100%" />
            </div>
          </div>
          <div className="details">
            <div className="left">
              <p>Client Note:</p>
            </div>
            <div className="right">
              <CustomInput width="100%" />
            </div>
          </div>
        </div>
      </div>
    </BottomDetailsDiv>
  );
};

export default BottomDetails;
