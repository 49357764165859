import React from "react";
import { useSelector } from "react-redux";

import { Card, Header } from "semantic-ui-react";

const About = () => {
  const { data } = useSelector((state) => state.updateProfileReducer);

  return (
    <div
      style={{
        width: "100%",
        padding: "1rem",
        background: "rgb(255,240,245)",
      }}
    >
      <Card.Content>
        <Header color="purple" as="h2">
          About Us
        </Header>
        <Card.Description>{data.about}</Card.Description>
      </Card.Content>
    </div>
  );
};

export default About;
