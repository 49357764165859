import React, { useState, Fragment, useEffect } from "react";
import history from "utils/history";
import swal from "sweetalert";
// Material imports
import { Avatar, IconButton, Tooltip, useMediaQuery } from "@material-ui/core";
import { Button, Input, Checkbox } from "semantic-ui-react";
// Material Icons
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PersonIcon from "@material-ui/icons/Person";
import PublicIcon from "@material-ui/icons/Public";

import publicProfileOne from "assets/images/publicProfileOne.jpg";
import publicProfileTwo from "assets/images/public profile-06.jpg";
import publicProfileThree from "assets/images/publicProfileThree.jpg";
import publicProfileFour from "assets/images/new sppo and pp photo-03.jpg";

import Slider from "../../Sliders/Slider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// Custom imports
import { useStylesAvatar } from "assets/jss/layouts/styleP";
import ProfileModal from "assets/jss/Modals/ProfileModal/ProfileModal";

import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

import CustomModal from "components/Common/CustomModal";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Axios from "axios";
import { updateLinguistProfile } from "redux/actions/updateProfileAction";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
//HeaderPage
import { Header, Dropdown } from "semantic-ui-react";
import { PROFILE_UPDATE_OPTIONS } from "data";
import { changePPLInput } from "redux/actions/updatePPLAction";

function HeaderPage() {
  const [openEdit, setOpenEdit] = useState(false);
  const [username, setUsername] = useState("Pratik Bandal");
  const [details, setDetails] = useState("Interpreter");
  const dispatch = useDispatch();
  const [openProfileUpdate, setOpenProfileUpdate] = useState(false);
  const [upload, setUpload] = useState(false);
  const [loader, setLoader] = useState(false);
  const [rating, setRating] = useState(2);
  const [formats, setFormats] = React.useState(() => ["phone"]);
  const matches = useMediaQuery("(max-width:768px)");
  const token = Cookies.get("LINtoken");
  const { data } = useSelector((state) => state.updatePPLReducer);
  const sliderImg = [
    publicProfileOne,
    publicProfileTwo,
    publicProfileThree,
    publicProfileFour,
  ];
  const handleFormat = (event, newFormats) => {
    if (newFormats?.length) {
      setFormats(newFormats);
    }
  };

  const classes_avatar = useStylesAvatar();

  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data.profile_update === false) {
        history.push("/linguist/full-registration-p1");
      }
      dispatch(updateLinguistProfile(response?.data));
      //setData(response?.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  const handleClose = (e) => {
    setUpload(false);
  };

  const handleChange = (e, { name, value }) => {
    dispatch(
      changePPLInput({
        name: "public_profile_position",
        value: value,
      })
    );
  };

  const handleChangeOther = (e) => {
    const { name, value } = e.target;
    dispatch(
      changePPLInput({
        name,
        value,
      })
    );
  };

  const EnableadminAllowPublicProfile = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/allow-public-profile-full-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "You have successfully set your profile to be public. You can also choose section by section.",
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "full_public_profile_section",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableadminAllowPublicProfile = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/hide-public-profile-full-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "You have successfully disabled public access to your profile, you can reset this at any time. Your profile will be only visible to LC and clients at all time.",
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "full_public_profile_section",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePublicProfile = (value) => {
    if (data.verified) {
      if (!data.full_public_profile_section) {
        swal(
          "Set profile privacy. Making your profile public. *Please note that you cannot hide your profile from LC clients, but you can choose not to make your profile public. By setting permission active here will make your profile public after LC approval. Are you sure you want to make your profile public? You can reset this at any time.",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              EnableadminAllowPublicProfile(value);

            default:
              break;
          }
        });
      } else {
        swal(
          "Are you sure you want to disable public profile access? Keeping your profile public help Linguist advertise their services and help finding a linguist by clients. LC encourage you to keep your profile public.",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              DisableadminAllowPublicProfile(value);

            default:
              break;
          }
        });
      }
    }
  };

  return (
    <Fragment>
      <ProfileModal
        openProfileUpdate={openProfileUpdate}
        setOpenProfileUpdate={setOpenProfileUpdate}
      />

      <div className="app__header__card">
        <CustomModal
          open={upload}
          handleClose={handleClose}
          style={{ height: "50%", width: "50%" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>Upload</p>
            <p style={{ marginTop: "10px" }}>Upload cover photo</p>
            <input type="file" name="logo" accept=".jpeg, .png" />
          </div>
        </CustomModal>
        <div
          style={{ position: "relative", marginBottom: "60px" }}
          className="relative mb-8"
        >
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: "1%",
              right: "0",
            }}
          >
            <ToggleButtonGroup
              style={{ marginRight: "10px" }}
              value={formats}
              onChange={handleFormat}
              aria-label="device"
            >
              <Tooltip title="Linguist Collective and Clients" placement="top">
                <ToggleButton value="tv" aria-label="tv">
                  <PersonIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Public" placement="top">
                <ToggleButton value="phone" aria-label="phone">
                  <PublicIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </div>

          <Slider data={sliderImg} />

          <div className="profile_avatar">
            <Avatar
              onContextMenu={(e) => e.preventDefault()}
              onClick={() => setOpenProfileUpdate(true)}
              className={classes_avatar.root}
              src={`${process.env.REACT_APP_BACKEND_API}/linguists/public-profile-photo/${data._id}`}
              alt="P"
              style={{ left: "10px" }}
            />
          </div>
        </div>

        <div
          style={{
            padding: "32px",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "100px",
          }}
          className="p-8"
        >
          <div>
            <Header as="h1">
              {data.title} {data.FirstName} {data.LastName}
              <IconButton>
                <VerifiedUserIcon />
              </IconButton>
            </Header>
            <Dropdown
              value={data.public_profile_position}
              name="public_profile_position"
              onChange={handleChange}
              placeholder="Select Your Position"
              search
              selection
              options={PROFILE_UPDATE_OPTIONS}
            />
            {data.public_profile_position == "Other" ? (
              <>
                <br /> <br />
                <Input
                  name="Other_position"
                  value={data.Other_position}
                  onChange={handleChangeOther}
                  type="text"
                  focus
                  placeholder="Type other position..."
                />
              </>
            ) : null}
            <br /> <br />
            <p
              style={{ fontSize: "24px", margin: "0" }}
              className="text-xl font-light"
            >
              LC Linguist ID: {data?.id}
            </p>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <LocationOnIcon />
              <Header
                style={{ margin: "0", marginLeft: "5px" }}
                color="grey"
                size="medium"
              >
                {data.Country}
              </Header>
            </div>
            <br />
            <Header as="h3">Source and target languages</Header>
            {data?.target_language?.length > 0 &&
              data?.target_language_level?.length > 0 && (
                <Header as="h3">
                  {data.target_language} - {data.target_language_level}
                </Header>
              )}
            {data?.target_language_1?.length > 0 &&
              data?.target_language_level_1?.length > 0 && (
                <Header as="h3">
                  {data?.target_language_1} - {data?.target_language_level_1}
                </Header>
              )}
            {data?.target_language_2?.length > 0 &&
              data?.target_language_level_2?.length > 0 && (
                <Header as="h3">
                  {data?.target_language_2} - {data?.target_language_level_2}
                </Header>
              )}
            {data?.source_language?.length > 0 && (
              <Header as="h3">
                {data?.source_language} - {data?.source_language_level}
              </Header>
            )}
            {data?.source_language_1?.length > 0 && (
              <Header as="h3">
                {data?.source_language_1} - {data?.source_language_level_1}
              </Header>
            )}
            {data?.source_language_2?.length > 0 && (
              <Header as="h3">
                {data.source_language_2} - {data.source_language_level_2}
              </Header>
            )}
          </div>

          {data?.verified ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3 style={{ margin: "0" }}>Profile privacy*, public?</h3>
                <Checkbox
                  toggle
                  checked={data?.full_public_profile_section}
                  onChange={() =>
                    handlePublicProfile("full_public_profile_section")
                  }
                />
                <div style={{ marginTop: 10 }}>
                  <Button
                    color="purple"
                    onClick={() =>
                      history.push(
                        `/linguist/protected/public/profile/${
                          data?.source_language.toString().split(" ")[0]
                        }/${data?.target_language.toString().split(" ")[0]}/${
                          data?._id
                        }`
                      )
                    }
                  >
                    Preview Public Profile
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <p>Account unverified, preview denied.</p>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default HeaderPage;
