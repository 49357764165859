import React, { useState } from "react";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomPassword from "components/Common/CustomPassword";
import Axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
import CustomCaptcha from "components/Common/CustomCaptcha";
import Button from "@material-ui/core/Button";
import ForgotPassword from "./BACForgotPassword";
import { Header } from 'semantic-ui-react'
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import {Divider} from '@material-ui/core'
const VerifyBAC = ({ setVerified }) => {
  const classes = useEmployeeStyles();
  const [clientPassword, setClientPassword] = useState();
  const [clientEmail, setClientEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setClientPassword(value);
  };
  const handleClientEmailChange = (e) => {
    const { value } = e.target;
    setClientEmail(value);
  };

  const handleBACLogin = async (e) => {
    e.preventDefault();
    const sendData = { email: clientEmail, password: clientPassword };
    setLoading(true);
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/accountclient/signin`,
        sendData
      );

      const { token, accountclient } = response?.data;
      Cookies.set("BACtoken", token);
      setVerified(true);
      sessionStorage.setItem("frontUser", JSON.stringify(accountclient));
      localStorage.setItem("BACuser", JSON.stringify(accountclient));
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.error) {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      } else {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `Please check your internet connection.`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const isAllow = !clientEmail || !clientPassword;
  return (
    <Card>
      <CardBody>
      <Header style={{ margin: "0" }} as="h3" textAlign="center">
                   
                  Billing Account Client (BAC) Login
                 
      </Header>
      <Divider orientation="horizontal" style={{margin:10}}/>
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleClientEmailChange}
        value={clientEmail}
        name="email"
        placeholder="Your registered primary email "
        width="100%"
        required
        label="Email"

      />

      <CustomPassword
        onChange={handlePasswordChange}
        value={clientPassword}
        name="clientPassword"
        label="Password"
        placeholder="Enter your password"
        width="100%"
        required
      />
      <CustomCaptcha onChange={(value) => setValid(value)} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 10,
        }}
      >
         <div className={classes.buttonDiv}>
            <Button
              color="primary"
              onClick={handleBACLogin}
              disabled={isAllow || loading || !valid}
              variant="contained"
            >
              {loading ? "Logging..." : "Login"}
            </Button>
           
          </div>
        {/* <Button
          disabled={isAllow || loading || !valid}
          loading={loading}
          color="purple"
          onClick={handleBACLogin}
        >
          Verify Account
        </Button> */}
      </div>
    </form>
        <div className={classes.forgotDiv}>
        <span />
        <ForgotPassword />
      </div>
      </CardBody>
    </Card>

  );
};
export default VerifyBAC;
