// Custom Components
import CustomSelect from "components/Common/CustomSelect";

//Others
import { TYPE_INTERPRETING } from "data";

const DescriptionDropdown = ({ onChange, name }) => {
  return (
    <CustomSelect
      label="Description of the service"
      data={TYPE_INTERPRETING}
      width="100%"
      name={name}
      onChange={onChange}
    />
  );
};

export default DescriptionDropdown;
