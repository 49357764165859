import { useHistory } from "react-router-dom";
import React from "react";
import Button from "@material-ui/core/Button";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SendIcon from "@material-ui/icons/Send";
import PageviewIcon from "@material-ui/icons/Pageview";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import DescriptionIcon from "@material-ui/icons/Description";
import { useMediaQuery } from "@material-ui/core";

const Actions = ({ sppoID }) => {
  const history = useHistory();
  const matches = useMediaQuery("(max-width:1201px)");
  return (
    <>
      {matches ? (
        <div>
          <Button
            startIcon={<SendIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            onClick={() => history.push("/sppo/send-client-reg-form")}
          >
            Send Client Reg Form
          </Button>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            onClick={() => window.open(`/sppo/inhouse-client-form/${sppoID}`)}
          >
            Add a new client
          </Button>
          <Button
            startIcon={<PageviewIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            onClick={() => history.push("/sppo/view-clients")}
          >
            View Clients
          </Button>

          <Button
            startIcon={<RemoveCircleIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            onClick={() =>
              history.push("/sppo/add-inhouse-client/removal-request")
            }
          >
            INC Removal Requests
          </Button>

          <Button
            startIcon={<DescriptionIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            onClick={() => window.open(`/delete-my-inc-account-from-sppo`)}
          >
            INC Removal Form
          </Button>

          <Button
            startIcon={<DescriptionIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            onClick={() => history.push(`/sppo/manage-inhouse`)}
          >
            Manage INC Assignments
          </Button>
          <Button
            startIcon={<DescriptionIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            onClick={() => history.push(`/sppo/my-leads`)}
          >
            Leads
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <Button
            startIcon={<SendIcon />}
            color="primary"
            variant="contained"
            style={{
              // width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() => history.push("/sppo/send-client-reg-form")}
          >
            Send Client Reg Form
          </Button>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{
              //  width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() => window.open(`/sppo/inhouse-client-form/${sppoID}`)}
          >
            Add a new client
          </Button>
          <Button
            startIcon={<PageviewIcon />}
            color="primary"
            variant="contained"
            style={{
              // width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() => history.push("/sppo/view-clients")}
          >
            View Clients
          </Button>

          <Button
            startIcon={<RemoveCircleIcon />}
            color="primary"
            variant="contained"
            style={{
              // width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() =>
              history.push("/sppo/add-inhouse-client/removal-request")
            }
          >
            INC Removal Requests
          </Button>

          <Button
            startIcon={<DescriptionIcon />}
            color="primary"
            variant="contained"
            style={{
              // width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() => window.open(`/delete-my-inc-account-from-sppo`)}
          >
            INC Removal Form
          </Button>

          <Button
            startIcon={<DescriptionIcon />}
            color="primary"
            variant="contained"
            style={{
              // width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() => history.push(`/sppo/manage-inhouse`)}
          >
            Manage INC Assignments
          </Button>

          <Button
            startIcon={<DescriptionIcon />}
            color="primary"
            variant="contained"
            style={{
              // width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() => history.push(`/sppo/my-leads`)}
          >
            Leads
          </Button>
        </div>
      )}
    </>
  );
};

export default Actions;
