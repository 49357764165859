import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Table } from "semantic-ui-react";

// Material Components
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

// Custom Components
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomInput from "components/Common/CustomInput";

const dropdownData = [
  "Revenue / job value / predict income",
  "Other revenue items / incomings",
];

const RevenueItems = ({
  revDatas,
  otherRevDatas,
  handleRevAdd,
  handleRevRemove,
  handleOtherRevRemove,
  handleRevDropdownChange,
  handleRevInputChange,
  handleRevOtherNameInputChange,
  handleRevOtherValueInputChange,
  totalRevenue,
  handleOtherRevAdd,
  currency
}) => {
  const dLength = revDatas.length;
  const otherLength = otherRevDatas.length;
  return (
    <div>
      <Table>
        <Table.Header
          style={{
            display: "none",
          }}
        >
          <Table.Row>
            <Table.HeaderCell> </Table.HeaderCell>
            <Table.HeaderCell> </Table.HeaderCell>
            <Table.HeaderCell> </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {revDatas.map((item, index) => (
            <Table.Row key={item.id}>
              <Table.Cell
                style={{
                  width: "300px",
                }}
              >
                <CustomAutoSelect
                  label="Select Revenue"
                  data={dropdownData}
                  onChange={(e, value) =>
                    handleRevDropdownChange(item.id, value)
                  }
                />
              </Table.Cell>
              <Table.Cell
                style={{
                  paddingBottom: "0px",
                }}
              >
                <CustomInput
                  onChange={(e) =>
                    handleRevInputChange(item.id, e.target.value)
                  }
                  placeholder="£00.00  "
                />
              </Table.Cell>
              <Table.Cell style={{ display: "flex", alignItems: "center" }}>
                {dLength > 1 ? (
                  <IconButton
                    aria-label="remove"
                    color="primary"
                    onClick={() => handleRevRemove(item.id)}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                ) : null}

                {dLength - 1 === index ? (
                  <IconButton
                    aria-label="add"
                    color="primary"
                    onClick={handleRevAdd}
                  >
                    <AddCircleIcon />
                  </IconButton>
                ) : null}
              </Table.Cell>
            </Table.Row>
          ))}
          {otherRevDatas.map((item, index) => (
            <Table.Row key={item.id}>
              <Table.Cell
                style={{
                  width: "300px",
                }}
              >
                <CustomInput
                  onChange={(e) =>
                    handleRevOtherNameInputChange(item.id, e.target.value)
                  }
                  placeholder="Revenue item"
                />
              </Table.Cell>
              <Table.Cell
                style={{
                  paddingBottom: "8px",
                }}
              >
                <CustomInput
                  onChange={(e) =>
                    handleRevOtherValueInputChange(item.id, e.target.value)
                  }
                  placeholder="£00.00"
                />
              </Table.Cell>
              <Table.Cell
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="remove"
                  color="primary"
                  onClick={() => handleOtherRevRemove(item.id)}
                >
                  <RemoveCircleIcon />
                </IconButton>
                {otherLength - 1 === index ? (
                  <IconButton
                    aria-label="add"
                    color="primary"
                    onClick={handleOtherRevAdd}
                  >
                    <AddCircleIcon />
                  </IconButton>
                ) : null}
              </Table.Cell>
            </Table.Row>
          ))}
          <Table.Row>
            <Table.Cell
              style={{
                width: "300px",
              }}
            >
              Total Revenue
            </Table.Cell>
            <Table.Cell>
              <span>{totalRevenue ? totalRevenue.toFixed(2)+ ' ' + currency : ""}</span>
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default RevenueItems;
