import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material Core Components
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Material Icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Cookies from "js-cookie";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Axios from "axios";

const BIPayment = () => {
  const classes = useEmployeeStyles();

  const currency_new = Cookies.get("currency_new_card");
  const id = Cookies.get("cacTransID");
  const new_total = Cookies.get("new_total");
  const token = Cookies.get("CACtoken");

  const { username } = JSON.parse(localStorage.getItem("CACuser"));

  Axios.post(
    `${process.env.REACT_APP_BACKEND_API}/cashclient/cashclientinterpreting/sendMailSTRIPE/translation`,
    { currency_new, new_total, username, id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.title}`}>
                <Typography component="h1" variant="h5">
                  Order Successful!
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "20px" }}
                  src="https://www.cntraveller.in/wp-content/themes/cntraveller/images/check-circle.gif"
                  alt=""
                />
                <Typography component="h1" variant="h5">
                  Your order is confirmed
                </Typography>
              </div>
              <div>
                <Typography variant="h5">
                  Check your dashboard and email for more details
                </Typography>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default BIPayment;
