import styled from "styled-components";

export const DetailsDiv = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .left {
    flex: 1;
  }
  .right {
    display: flex;
    justify-content: center;
    flex: 1;
  }
  ul {
    li {
      text-decoration: none;
      list-style: none;
      font-size: 16px;
      font-weight: bold;
      span {
        font-weight: normal;
      }

      .MuiFormControl-root {
        margin-top: 0 !important;
      }
    }

    li.address {
      min-height: 120px;
    }
  }
`;

export const SubDetailsDiv = styled.div`
  padding: 2rem 0;
  width: 50%;
  margin: auto;
  p {
    margin: 0;
    font-size: 16px;
  }
  div {
    display: flex;
    p {
      min-width: 100px;
    }
    .left {
      text-align: right;
      margin-right: 1rem;
    }
  }

  .er {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    div.MuiFormControl-root {
      margin-top: 0px;
    }
  }

  .service {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiFormControl-root {
      margin-top: 0 !important;
    }
  }
`;

export const PriceDropdownDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  .MuiFormControl-root {
    margin: 0;
  }
`;

export const SubTotalDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .left {
    flex: 1;
    text-align: center;

    p {
      margin-left: -3.5rem;
    }
  }

  .right {
    margin-right: 4rem;
  }
`;

export const TotalDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  font-weight: bold;
  .left {
    flex: 1;
    text-align: center;
  }

  .right {
    margin-right: 4rem;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .percent {
        margin-right: 1rem;
      }
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .percent {
    /* padding-right: 20px; */
  }
`;

export const ActionsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

export const TableDiv = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  thead {
    background: #952daf;
    color: #fff;
  }
  td,
  th {
    text-align: left;
    padding: 8px;
  }

  tr td:last-child {
    text-align: center;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  tr th:last-child {
    text-align: center;
  }

  thead tr th {
    font-size: 18px;
    width: 200px;
  }

  td .MuiFormControl-root {
    margin-top: 0;
  }

  .pt-25 {
    padding-top: 25px !important;
  }

  .fw-bold {
    font-weight: bold;
  }

  .pr-10 {
    padding-right: 10px;
  }
  .pr-20 {
    padding-right: 20px;
  }

  .pr-40 {
    padding-right: 40px;
  }

  .other-input {
    padding-bottom: 0px;
    .MuiFormControl-root {
      margin-bottom: -5px;
    }
  }
`;

export const BottomDetailsDiv = styled.div`
  margin: 2rem 0;
  text-align: center;
  h3,
  h4 {
    margin: 0;
  }

  h3 {
    padding-bottom: 0.7rem;
  }

  p {
    margin: 0;
    font-size: 18px;
  }
  .details {
    display: flex;
    align-items: center;
    margin-bottom: 1.1rem;
    .right {
      flex: 1;
      margin-left: 1rem;
      .MuiFormControl-root {
        margin-top: 0;
      }
    }
  }
  .wrapper {
    margin-top: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
  }
`;
