import React from "react";

// Custom Component
import MasterLayout from "layout/MasterLayout";

const SppoLayout = ({ children }) => {
  return <MasterLayout layoutType="SPPO">{children}</MasterLayout>;
};

export default SppoLayout;
