import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import CustomSelect from "components/Common/CustomSelect";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomCaptcha from "components/Common/CustomCaptcha";

import defaultAxios from "utils/axios";
import swal from "sweetalert";
import Cookies from 'js-cookie'

const FormOne = () => {
    const classes = useFormStyles();

    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        note: ""
    })

    const {first_name, last_name, email, note} = data

    const handleChange = e => {
        setData({...data, [e.target.name] : e.target.value})
    }

    const isAllow = 
        !data.first_name ||
        !data.email ||
        !data.last_name

        const handleSubmit = async (e) => {
            // console.log(data);
            const token = Cookies.get('token')
        
            const sendData = {
              first_name: data.first_name || "-",
              last_name: data.last_name || "-",
              email: data.email || "-",
              note: data.note || "-",
            };
            try {
              const response = await defaultAxios.post(
                `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-admin-form/registration`,
                sendData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                      }
                }
              );
              // console.log(response);
              swal({
                title: "Success",
                text: "Admin registration form successfully send",
                icon: "success",
                buttons: false,
              }).then(function() {
                window.location = "/admin/send-form";
            });
            } catch (error) {
              if (error.response) {
                swal({
                  title: "Oops!",
                  text: `${error?.response?.data?.error}`,
                  icon: "error",
                  buttons: false,
                });
              }
            }
          };

    return (
        <form className={classes.form} noValidate autoComplete="off">
            <CustomInput
                onChange= {handleChange}
                label = 'First Name(s)'
                name = 'first_name'
                value= {data.first_name}
                width = '100%'
                required
            />

            <CustomInput
                onChange= {handleChange}
                label = 'Last Name'
                name = 'last_name'
                value = {data.last_name}
                width = '100%'
                required
            />

            <CustomInput
                onChange= {handleChange}
                label = 'Email'
                name = 'email'
                value = {data.email}
                width = '100%'
                required
            />

            <CustomInput
                onChange= {handleChange}
                label = 'Note'
                name = 'note'
                value = {data.note}
                width = '100%'
            />


            <div className={classes.buttonFlex}>
                <CustomButton disabled= {isAllow}  text="Send" onClick= {handleSubmit} />
            </div>
        </form>
    )
}

export default FormOne
