import Axios from "axios";
import Cookies from "js-cookie";
import Moment from "moment";
import SearchClient from "./Search/SearchView";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useToasts } from "react-toast-notifications";
import Action from "./Action";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const ViewClientInhouse = ({ searchChat }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const id = Cookies.get("linID");
  const token = Cookies.get("LINtoken");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const classes = useStyles();
  const { addToast } = useToasts();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchText, setSearchText] = useState("");

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-PRC/${searchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (searchText == undefined || searchText == "") {
        addToast("Empty field, please write correct client id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({
          ...values,
          privateClients: [response?.data],
          reload: false,
        });
        // =[response?.data]
        addToast("Client found", { appearance: "success" });
      } else {
        addToast("Client not found", { appearance: "error" });
      }
      if (values.privateClients) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct client id.", {
          appearance: "error",
        });
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    privateClients: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, privateClients, removed, reload } = values;

  const loadPrivateClient = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/list-private-client`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues({ ...values, privateClients: response?.data });
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    loadPrivateClient();
  }, [reload]);

  return (
    <div>
      <SearchClient
        load={loadPrivateClient}
        setSearchText={setSearchText}
        searchtext={searchText}
        handleSearch={handleSearch}
        setShow={setShow}
      />
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Client ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell>Pass Number</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {privateClients
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((list) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={list._id}
                      >
                        <TableCell key={list._id} align={list.align}>
                          <Link to={`/linguist/prc-profile/${list._id}`}>
                            {list.id}
                          </Link>
                        </TableCell>
                        <TableCell>{list.title}</TableCell>
                        <TableCell>{list.FirstName}</TableCell>
                        <TableCell>{list.LastName}</TableCell>
                        <TableCell>{list.email}</TableCell>
                        <TableCell>{list.passNumber}</TableCell>
                        <TableCell>
                          <Action
                            id={list?._id}
                            ID={list?.id}
                            load={loadPrivateClient}
                            name={list?.FirstName + " " + list?.LastName}
                          />
                          {/* <Button onClick={() => handleDelete(list._id)}>Archive</Button> */}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={privateClients.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
};

export default ViewClientInhouse;
