import useFormStyles from "assets/jss/components/auth/formStyle";
import React, { useEffect, useState } from "react";
import Items from "./TableBody";

//Style
import "./style.css";
import TableFooter from "./TableFooter";
import Axios from "axios";
import Cookies from "js-cookie";
import { Rating } from "@material-ui/lab";
import CustomTextInput from "components/Common/CustomTextInput";
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import CustomSnackBar from "components/Common/CustomSnackBar";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import history from "utils/history";

const Details = ({ id }) => {
  const classes = useFormStyles();
  const token = Cookies.get("LINtoken");
  const [details, setDetails] = useState(null);
  const [value, setValue] = useState(0);
  const { data } = useSelector((state) => state.timesheetReducer);
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState({
    toast: false,
    loader: false,
  });

  const {
    start_date,
    end_date,
    feedback,
    note,
    duration,
    start_time,
    end_time,
    signature,
  } = data;

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getTimeSheet = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/preview-quote/cashclient/linQuoteID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );
        setDetails(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    getTimeSheet();

    return () => {
      source.cancel();
    };
  }, []);

  const handleNext = async (e) => {
    setSubmit({ ...submit, loader: true });
    const file = new File([signature], "signature", { type: "image/png" });
    const fd = new FormData();
    fd.append("signature", file);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("duration", duration);
    fd.append("feedback", feedback);
    fd.append("note", note);
    fd.append("start_time", start_time);
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/create-timesheet/cashclient/linQuoteID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      history.push(
        `/linguist/accepted-quote/timesheet/invoice/${response?.data._id}`
      );
      setSubmit({ ...submit, loader: false, toast: true });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleViewPdf = (e) => {
    window.html2canvas = html2canvas;
    var doc = new jsPDF({
      orientation: "potrait",
      unit: "pt",
      size: "A4",
      // format: [4, 2]
    });
    var content = document.getElementById("printArea");

    doc.html(content, {
      callback: function (doc) {
        // doc.save('myDocument.pdf');
        window.open(doc.output("bloburl"));
      },
    });
  };

  return (
    details && (
      <div>
        <div style={{ width: "100%", marginTop: "24px" }}>
          <CustomSnackBar
            message="Timesheet submitted to client"
            handleClose={() => setSubmit({ ...submit, toast: false })}
            openSnackBar={submit.toast}
          />
          <CustomTextInput
            label="Job Reference Number"
            value={details.quoteRequstByCashclient.id}
          />

          <Items details={details} />

          <CustomTextInput
            label="Instructions for linguist professional"
            value=""
          />

          {/* Address details */}
          <CustomTextInput
            label="Remote Address"
            value={
              details.quoteRequstByCashclient.remote_address
                ? details.quoteRequstByCashclient.remote_address
                : "N/A"
            }
          />

          <>
            <CustomTextInput
              label="House Number / Street Name"
              value={details.quoteRequstByCashclient.house_street_no_name}
            />
            <CustomTextInput
              label="Address Line 2"
              value={details.quoteRequstByCashclient.address_line_two}
            />
            <CustomTextInput
              label="County / Region"
              value={details.quoteRequstByCashclient.county_region}
            />
            <CustomTextInput
              label="Post Code"
              value={details.quoteRequstByCashclient.post_code}
            />
            <CustomTextInput
              label="Country"
              value={details.quoteRequstByCashclient.country}
            />
          </>

          <CustomTextInput
            label="Court case, police, NHS ref"
            value={
              details.quoteRequstByCashclient.security_clearance
                ? details.quoteRequstByCashclient.security_clearance
                : "N/A"
            }
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
            }}
          >
            <Header style={{ margin: "0" }} as="h4">
              Feedback to client
            </Header>
            <div>
              <Rating
                name="simple-controlled"
                // onChange={(event, newValue) => {
                //     dispatch(changeTimesheetInput({
                //         name: 'feedback',
                //         value: newValue
                //     }))
                // }}
              />
            </div>
          </div>

          <CustomTextInput
            label="Customer / Client Name"
            value={details.quoteRequstByCashclient.postedBy.FirstName}
          />
          <CustomTextInput label="Customer / Client ref no." value="N/A" />
          <CustomTextInput
            label="Emergency Contact no."
            value={details.quoteRequstByCashclient.postedBy.mobile_no}
          />
          <CustomTextInput
            label="Name of limited speaker conference"
            value=""
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
            }}
          >
            <Header style={{ margin: "0" }} as="h4">
              Feedback to linguist
            </Header>
          </div>

          <TableFooter details={details} />
        </div>

        <Header as="h4">
          Upload timesheet or proof of service delivery, max1MB
        </Header>
        <Button style={{ backgroundColor: "purple", color: "white" }}>
          Upload
        </Button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div style={{ padding: "10px" }}></div>
          <div style={{ padding: "10px" }}>
            <Button
              loading={submit.loader}
              disabled={!signature}
              onClick={handleNext}
              style={{ backgroundColor: "purple", color: "white" }}
            >
              Next
            </Button>
            {submit.loader && (
              <Dimmer>
                <Loader active inline />
              </Dimmer>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Details;
