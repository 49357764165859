import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import {
  LANGUAGE_DATA,
  QUOTE_REQUEST_FOR_SEM_UI,
  TRANSLATION_QUALIFICATION,
  INTERPRETING_QUALIFICATION,
  OTHER_LANG_TEST,
  PROFESSIONAL_MEMBERSHIP_SEM_UI,
} from "data";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import Axios from "axios";
import Cookies from "js-cookie";

const ProfessionalDetails = () => {
  const token = Cookies.get("LINtoken");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updateProfileReducer);

  const {
    source_language_1,
    source_language_2,
    target_language_1,
    target_language_2,
  } = data;

  const [edit, setEdit] = useState(false);

  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };


  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("language_combinations", data.language_combinations || "-");
    fd.append("target_language_1", data.target_language_1 || "");
    fd.append("target_language_2", data.target_language_2 || "");
    fd.append("source_language_1", data.source_language_1 || "");
    fd.append("source_language_2", data.source_language_2 || "");
    fd.append("ticking_relevant", data.ticking_relevant || "");
    fd.append("interpreting_qualification", data.interpreting_qualification || "");
    fd.append("translation_qualification", data.translation_qualification || "");
    fd.append("total_hours_of_proven", data.total_hours_of_proven || "");
    fd.append("total_days_words_of_proven", data.total_days_words_of_proven || "");
    fd.append("professional_qualification", data.professional_qualification || "");
    fd.append(
      "professional_membership_number_level",
      data.professional_membership_number_level || ""
    );
    fd.append("professional_membership", data.professional_membership || "");

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/profile/update`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <label>
            Mother Tongue / Source / Native Language: {data.source_language} -{" "}
            {data.source_language_level}
            <br />
            <br />
          </label>

          <label>
            Mother Tongue / Source / Native Language: {data.source_language_1} -{" "}
            {data.source_language_level_1} <br />
            <br />
          </label>

          <label>
            Mother Tongue / Source / Native Language: {data.source_language_2} -{" "}
            {data.source_language_level_2} <br />
            <br />
          </label>

          <label>
            Target Language: {data.target_language} -{" "}
            {data.target_language_level}
            <br /> <br />
          </label>

          <label>
            Target Language: {data.target_language_1} -{" "}
            {data.target_language_level_1} <br />
          </label>

          <label>
            Target Language: {data.target_language_2} -{" "}
            {data.target_language_level_2} <br />
          </label>

          {edit.value ? (
            <CustomTextInput
              onChange={handleDropDown}
              name="language_combinations"
              label="List all other languages combinations (max 5)"
              data={LANGUAGE_DATA}
              dropdown={true}
            />
          ) : (
            <CustomTextInput
              label="List all other languages combinations (max 5)"
              value={data.language_combinations}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              onChange={handleDropDown}
              name="ticking_relevant"
              label="Please subscribe by ticking relevant boxes"
              data={QUOTE_REQUEST_FOR_SEM_UI}
              dropdown={true}
            />
          ) : (
            <CustomTextInput
              label="Please subscribe by ticking relevant boxes"
              value={data.ticking_relevant}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              name="interpreting_qualification"
              onChange={handleChange}
              label="Interpreting Qualification (s)"
              select={INTERPRETING_QUALIFICATION}
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              label="Interpreting Qualification (s)"
              value={data.interpreting_qualification}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              name="translation_qualification"
              onChange={handleChange}
              label="Translation Qualification (s)"
              select={TRANSLATION_QUALIFICATION}
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              label="Translation Qualification (s)"
              value={data.translation_qualification}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              name="other_language_test"
              onChange={handleChange}
              label="Other language test, qualification and or CPD (s)"
              select={OTHER_LANG_TEST}
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              label="Other language test, qualification and or CPD (s)"
              value={data.other_language_test}
            />
          )}

          {edit.value ?
            <CustomTextInput
              name="total_days_words_of_proven"
              onChange={handleChange}
              label="Total hours of proven PSI experience"
              edit={edit.value}
              value={data.total_days_words_of_proven}
            />
            :
            <CustomTextInput
              label="Total hours of proven PSI experience"
              value={data.total_days_words_of_proven}
            />
          }

          {edit.value ?
            <CustomTextInput
              name="total_hours_of_proven"
              onChange={handleChange}
              label="Total Job days of proven PST work experience"
              edit={edit.value}
              value={data.total_hours_of_proven}
            />
            :
            <CustomTextInput
              label="Total Job days of proven PST work experience"
              value={data.total_hours_of_proven}
            />
          }

          {edit.value ?
            <CustomTextInput
              name="professional_qualification"
              onChange={handleChange}
              label="Please list any other professional qualifications and experiences"
              // edit={edit.value}
              value={data.professional_qualification}
            />
            :
            <CustomTextInput
              label="Please list any other professional qualifications and experiences"
              value={data.professional_qualification}
            />
          }

          {edit.value ? (
            <CustomTextInput
              onChange={handleDropDown}
              name="professional_membership"
              label="Professional Membership"
              data={PROFESSIONAL_MEMBERSHIP_SEM_UI}
              dropdown={true}
            />
          ) : (
            <CustomTextInput
              label="Professional Membership"
              value={data.professional_membership}
            />
          )}

          {edit.value ?
            <CustomTextInput
              name="professional_membership_number_level"
              onChange={handleChange}
              label="Professional membership and level"
              // edit={edit.value}
              value={data.professional_membership_number_level}
            />
            :
            <CustomTextInput
              label="Professional membership and level"
              value={data.professional_membership_number_level}
            />
          }

          <a href={`/linguist/update-linguist-documents`}>
            Upload Documents
          </a>

        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default ProfessionalDetails;
