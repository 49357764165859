import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert";
import Action from "../Action";
import { Link } from "react-router-dom";
import { Checkbox, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  button: {
    background: "#e0e1e2 none",
    border: 0,
    color: "rgba(0,0,0,.6)",
    padding: 12,
    marginRight: 10,
    borderRadius: "3pt",
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
    fontSize: 13,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [ids, setIds] = useState([]);
  const handleChange = (value, id) => {
    if (value === true) {
      setIds(ids.concat(id));
    } else {
      setIds(ids.filter((item) => item !== id));
    }
  };

  const handleDeleteMult = () => {
    try {
      swal({
        buttons: {
          confirm: "Delete",
          cancel: "Cancel",
        },
        icon: "warning",
        title: "Are you sure?",
      }).then(async (val) => {
        if (val !== null) {
          await axios.delete(
            `${process.env.REACT_APP_BACKEND_API}/delete-multiple-lin-quote/linQuoteID/${ids}`);
          loadList();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    details: [],
    removed: false,
    reload: false,
  });

  const { details, reload } = values;

  useEffect(() => {
    loadList();
  }, [reload]);

  const loadList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-lin-quote`
      );
      setValues({ ...values, details: response?.data });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="export">
          <TableHead>
            <TableRow>
              <TableCell>
                {ids.length > 1 && (
                  <IconButton onClick={handleDeleteMult}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>Assignment ID</TableCell>
              <TableCell>Provided Quote ID</TableCell>
              <TableCell>Quote Request For</TableCell>
              <TableCell>Source Language</TableCell>
              <TableCell>Target Language</TableCell>
              <TableCell>Remote / Venue</TableCell>
              <TableCell>Request Details</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>Skills Level</TableCell>
              <TableCell>Quote Needed By</TableCell>
              <TableCell>Expiry Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {details
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((list, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  <TableCell>
                    <Checkbox
                      onChange={(e, value) => handleChange(value, list._id)}
                      color="secondary"
                    />
                  </TableCell>

                  <TableCell key={list._id} align={list.align} >
                    {list?.quoteRequstByCashclient?._id ?
                      <Link 
                        to={`/admin/view-cash-client-quotation/${list?.quoteRequstByCashclient?._id}`}
                      >
                        {list?.quoteRequstByCashclient?.id}
                      </Link>
                      : list?.quoteRequstByAccountclient?._id ?
                        <Link 
                          to={`/admin/view-account-client-quotation/${list?.quoteRequstByAccountclient?._id}`}
                        >
                          {list?.quoteRequstByAccountclient?.id}
                        </Link>
                        : <b style={{ color: "red" }}>Requested quote deleted</b>}
                  </TableCell>
                  <TableCell key={list._id} align={list.align}>
                    <Link 
                      to={`/admin/view-provided-quote/${list?._id}`}
                    >
                      {list?.id}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {list?.quoteRequstByCashclient?.quote_rqst_for
                      ? list?.quoteRequstByCashclient?.quote_rqst_for
                      : list?.quoteRequstByAccountclient?.quote_rqst_for
                        ? list?.quoteRequstByAccountclient?.quote_rqst_for
                        : <b style={{ color: "red" }}>Deleted</b>}
                  </TableCell>
                  <TableCell>
                    {list?.quoteRequstByCashclient?.source_language
                      ? list?.quoteRequstByCashclient?.source_language
                      : list?.quoteRequstByAccountclient?.source_language
                        ? list?.quoteRequstByAccountclient?.source_language
                        : <b style={{ color: "red" }}>Deleted</b>}
                  </TableCell>
                  <TableCell>
                    {list?.quoteRequstByCashclient?.target_language
                      ? list?.quoteRequstByCashclient?.target_language
                      : list?.quoteRequstByAccountclient?.target_language
                        ? list?.quoteRequstByAccountclient?.target_language
                        : <b style={{ color: "red" }}>Deleted</b>}
                  </TableCell>
                  <TableCell>
                    {list?.quoteRequstByCashclient?.country
                      ? list?.quoteRequstByCashclient?.country
                      : list?.quoteRequstByAccountclient?.country
                        ? list?.quoteRequstByAccountclient?.country
                        : <b style={{ color: "red" }}>Deleted</b>}
                  </TableCell>
                  <TableCell>
                    {list?.quoteRequstByCashclient?.request_details
                      ? list?.quoteRequstByCashclient?.request_details
                      : list?.quoteRequstByAccountclient?.request_details
                        ? list?.quoteRequstByAccountclient?.request_details
                        : <b style={{ color: "red" }}>Deleted</b>}
                  </TableCell>
                  <TableCell>
                    {list?.quoteRequstByCashclient?.project_fix_budget
                      ? list?.quoteRequstByCashclient?.project_fix_budget
                      : list?.quoteRequstByAccountclient?.project_fix_budget
                        ? list?.quoteRequstByAccountclient?.project_fix_budget
                        : <b style={{ color: "red" }}>Deleted</b>}
                  </TableCell>
                  <TableCell>
                    {list?.quoteRequstByCashclient?.target_skills_required
                      ? list?.quoteRequstByCashclient?.target_skills_required
                      : list?.quoteRequstByAccountclient?.target_skills_required
                        ? list?.quoteRequstByAccountclient?.target_skills_required
                        : <b style={{ color: "red" }}>Deleted</b>}
                  </TableCell>
                  <TableCell>
                    {list?.quoteRequstByCashclient?.quote_by
                      ? moment(list?.quoteRequstByCashclient?.quote_by
                      ).format("DD-MMM-YYYY")
                      : list?.quoteRequstByAccountclient?.quote_by
                        ? moment(list?.quoteRequstByAccountclient?.quote_by
                        ).format("DD-MMM-YYYY")
                        : <b style={{ color: "red" }}>Deleted</b>}
                  </TableCell>
                  <TableCell>
                    {moment(list?.expiry_date).format("DD-MMM-YYYY")}
                  </TableCell>
                  <TableCell>
                    <Action
                      load={loadList}
                      LinQuoteID={list?._id}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={details.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
