import React from "react";
import { useSelector } from "react-redux";


import Typography from "@material-ui/core/Typography";

// Material Icons

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import BookingOption from "components/Linguist/ManagePrivateClient/BookingOption";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import CustomFormControl from "components/Common/CustomFormControl";
import { Progress } from "semantic-ui-react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import LinguistLayout from "layout/LinguistLayout";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import { IconButton, Link, Button, useMediaQuery } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import Options from "components/Linguist/MyClient/PRCOptions";



const PrivateClientRegistrationPrivateForm = () => {
  const { count } = useSelector((state) => state.icrReducer);
  const matches = useMediaQuery('(max-width:768px)')
  const classes = useDashboardStyles();

  const selectedForm = count;

  const { linID } = useParams()

  Cookies.set('linID', linID)

  return (
    <Linguist>
      <LinguistLayout>
        <div className={classes.root}>

          <Card>
            <CardHeader
              color="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className={classes.cardTitleWhite}>Private Client Registration </p>
              <IconButton color='inherit'><ArrowBackIcon

                onClick={() => history.goBack()}
              ></ArrowBackIcon></IconButton>
            </CardHeader>
          </Card>
          <Card>
            <CardHeader
              color="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >

              <Options />
            </CardHeader>

            <CardBody>
              {!matches ? <div> <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/registration')} variant='contained' color='primary'>Filling up the form</Button>
                <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/send-client-registration-form')} variant='contained' color='primary'>Sending form</Button>
                <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/removal-request')} variant='contained' color='primary'>Removal Requests</Button>
                <Link href='/delete-my-prc-account-from-linguist' target='_blank'>  <Button color='primary' variant='contained'> Removal Form</Button></Link>
              </div> :
                <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
                  <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/registration')} variant='contained' color='primary'>Filling up the form</Button>
                  <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/send-client-registration-form')} variant='contained' color='primary'>Sending form</Button>
                  <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/removal-request')} variant='contained' color='primary'>Removal Requests</Button>
                  <Link href='/delete-my-prc-account-from-linguist' target='_blank'>  <Button color='primary' variant='contained'> Removal Form</Button></Link>
                </div>
              }

            </CardBody>

          </Card>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className={`${classes.textCenter} ${classes.title}`}>
                    <CustomAvatar />
                    <Typography component="h1" variant="h5">
                      Private Client Registration
                    </Typography>
                    <Typography variant="subtitle2">Page {count} of 2</Typography>
                  </div>
                  {selectedForm === 1 && <FormOne />}
                  {selectedForm === 2 && <FormTwo />}
                  <CustomFormControl width="73.33%">
                    <Progress
                      color="purple"
                      percent={(selectedForm / 2) * 100}
                      active
                      size="small"
                    />
                  </CustomFormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </LinguistLayout>
    </Linguist>
  );
};

export default PrivateClientRegistrationPrivateForm;
