/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// Custom Components
import CustomSelect from "components/Common/CustomSelect";

import { setSelectedCurrency, setCurrency } from "redux/actions/getData";

import { PriceDropdownDiv } from "./style";

const data = [
  "GBP (UK)",
  "EUR (EU)",
  "AUD (Australia)",
  "USD (USA)",
  "CAD (Canada)",
  "TRY (Turkish lira)",
  "SGD (Singapore dollar)",
  "HUF (Hungarian forint)",
  "NZD (New Zealand dollar)",
  "RON (Romanian leu)",
];

const CurrencyDropdown = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState(() => data[0]);

  useEffect(() => {
    const type = state.substring(0, 3);
    dispatch(setSelectedCurrency(type));
    dispatch(setCurrency(type || type));
  }, [state]);

  const onChange = (e) => {
    setState(e.target.value);
  };
  return (
    <PriceDropdownDiv>
      <CustomSelect
        label="Currency"
        width="10%"
        name="Currency"
        data={data}
        value={state}
        onChange={onChange}
      />
    </PriceDropdownDiv>
  );
};

export default CurrencyDropdown;
