import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Axios from "utils/axios";
import { Button } from "semantic-ui-react";
import Cookies from "js-cookie";
import Moment from "moment";
import swal from "sweetalert";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactToExcel from "react-html-table-to-excel";
import moment from "moment";
import SearchView from "./SearchView/SearchView/SearchView";
import { useToasts } from "react-toast-notifications";
import Action from "./Action";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
  button: {
    background: "#e0e1e2 none",
    border: 0,
    color: "#ffff",
    padding: (20, 11),
    marginRight: 10,
    marginLeft: 10,
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
    borderRadius: "3pt",
    fontSize: 13,
    color: "rgba(0,0,0,.6)",
  },
});

export default function StickyHeadTable() {
  const token = Cookies.get("token");
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [linEmail, setLinEmail] = useState("");
  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    List: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, List, removed, reload } = values;

  useEffect(() => {
    loadList();
    // loadLinData()
  }, []);

  const loadList = async () => {
    try {
      const response = await Axios.get(
        "/api/manager-admin/list/pregistration",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, List: response?.data });
    } catch (error) {
      console.log(error);
    }
  };

  const a = List?.map((uc) => uc);
  const exportPDF = (e) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    var totalPages = doc.internal.getNumberOfPages();
    var i;

    for (i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
      // doc.setTextColor(150);
      doc.text(
        50,
        doc.internal.pageSize.height - 30,
        "Strictly Company Confidential © linguistscollective.com."
      );
    }

    const title = "Unregistered Linguists List";
    const headers = [
      [
        "Id",
        "First\nName",
        "Last\nName",
        "Email",
        "Mobile\nNumber",
        "Registration\nDate",
        "Country",
        "City",
        "Source\nLanguage",
        "Target\nLanguage",
      ],
    ];

    const data = List.map((list) => [
      list.id,
      list.first_name,
      list.last_name,
      list.primary_email,
      list.mobile_no,
      moment(list.createdAt).format("DD/MM/YYYY") +
        "\n" +
        moment(list.createdAt).format("HH:mm"),
      list.country,
      list.city,
      list.source_language,
      list.target_language,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        valign: "middle",
        // halign: 'justify'
      },
      columnStyles: { 0: { fillColor: [138, 43, 226], textColor: "white" } },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    var string = doc.output("datauristring");
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    var x = window.open();
    x.document.open();
    x.document.write(embed);
    x.document.close();
  };


const handleSearch = async () => {
  try {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/search/unregistered-linguists-list/${SearchText}`
    );
    if (SearchText == undefined || SearchText == "") {
      addToast("Empty field, please write correct linguits id.", {
        appearance: "error",
      });
    } else if (response?.data?.length > 0) {
       setValues({ ...values, List: response?.data, reload: false });
      addToast("Linguits found", { appearance: "success" });
    } else {
      addToast("Linguits not found", { appearance: "error" });
      setShow(false);
    }
    if (values.List) {
      setShow(true);
    }
  } catch (error) {
    if (error.response) {
      addToast("Empty field, please write correct linguits id.", {
        appearance: "error",
      });
      setShow(false);
    }
  }
};

  // const handleSearch = async () => {
  //   let entry = List.filter(
  //     (entry) =>
  //       entry.id == `LIN/CV${SearchText}` ||
  //       entry.id.toLowerCase() == SearchText ||
  //       entry.id.toUpperCase() == SearchText ||
  //       entry.primary_email.toLowerCase() == SearchText ||
  //       entry.primary_email.toUpperCase() == SearchText ||
  //       entry.city.toLowerCase() == SearchText ||
  //       entry.city.toUpperCase() == SearchText ||
  //       entry.country.toLowerCase() == SearchText ||
  //       entry.country.toUpperCase() == SearchText ||
  //       entry.source_language.toLowerCase() == SearchText ||
  //       entry.source_language.toUpperCase() == SearchText ||
  //       entry.target_language.toLowerCase() == SearchText ||
  //       entry.target_language.toUpperCase() == SearchText ||
  //       entry.first_name.toLowerCase() == SearchText ||
  //       entry.first_name.toUpperCase() == SearchText ||
  //       entry.last_name.toLowerCase() == SearchText ||
  //       entry.last_name.toUpperCase() == SearchText ||
  //       entry.mobile_no == SearchText
  //   );
  //   try {
  //     if (SearchText == undefined || SearchText == "") {
  //       addToast("Empty field, please write correct linguits id.", {
  //         appearance: "error",
  //       });
  //     } else if (entry.length != 0) {
  //       setValues({ ...values, List: entry, reload: false });
  //       addToast("Linguits found", { appearance: "success" });
  //       entry = [];
  //     } else {
  //       addToast("Linguits not found", { appearance: "error" });
  //     }
  //     if (values.List) {
  //       setShow(true);
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       addToast("Empty field, please write correct linguits id.", {
  //         appearance: "error",
  //       });
  //       setShow(false);
  //     }
  //   }
  // };

  return (
    <div>
      <SearchView
        load={loadList}
        SearchText={SearchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
        setShow={setShow}
        show={show}
      />

      {show ? (
        <div>
          {" "}
          <Paper className={classes.root}>
            <ReactToExcel
              // className = "btn"
              table="export"
              filename="excelFile"
              sheet="sheet 1"
              buttonText="Export CSV"
              className={classes.button}
            />

            <Button onClick={exportPDF}>Export PDF</Button>

            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" id="export">
                <TableHead>
                  <TableRow>
                    <TableCell>Unique Id No.</TableCell>
                    <TableCell>Enrolling</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Source Language</TableCell>
                    <TableCell>Target Language</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {List.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((list) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={list._id}
                      style={
                        list?.initial_reg
                          ? { backgroundColor: "lightgreen" }
                          : null
                      }
                    >
                      <TableCell key={list._id} align={list.align}>
                        <Link
                          to={`/admin/view-unregistered-linguist-client/${list._id}`}
                        >
                          {list.id}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {Moment(list.createdAt).format(
                          "DD MMM YYYY - kk:mm:ss"
                        )}
                      </TableCell>
                      <TableCell>{list.first_name} </TableCell>
                      <TableCell>{list.last_name}</TableCell>
                      <TableCell>{list.primary_email}</TableCell>
                      <TableCell>{list.mobile_no}</TableCell>
                      <TableCell>{list.city}</TableCell>
                      <TableCell>{list.country}</TableCell>
                      <TableCell>{list.source_language}</TableCell>
                      <TableCell>{list.target_language}</TableCell>

                      <TableCell>
                        <Action
                          id={list?._id}
                          ID={list?.id}
                          name={list?.first_name + " " + list?.last_name}
                          initial_reg={list?.initial_reg}
                          load={loadList}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={List.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      ) : null}
    </div>
  );
}
