import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";
import { Button } from "semantic-ui-react";
import { Typography, Divider } from "@material-ui/core";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
const EmailNotification = ({ id, values, type }) => {
  const hidden = values?.job_status?.includes("Closed");
  const [data, setData] = useState({
    bcc_email: "",
    admin_booking_notes: "",
  });
  const { bcc_email, admin_booking_notes } = data;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  // const handleCACSend = async (e) => {
  //   e.preventDefault();
  //   const token = Cookies.get("token");
  //   try {
  //     const res = await Axios.put(
  //       `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-confirmation-email-interpreting-CAC/bookingID/${id}`,
  //       {
  //         bcc_email,
  //         admin_booking_notes,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     swal({
  //       buttons: false,
  //       icon: "success",
  //       title: res.data?.message,
  //       text: " ",
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleBACSend = async (e) => {
    e.preventDefault();

    const token = Cookies.get("token");
    try {
      const res = await Axios.put(
        type === "trans"
          ? `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-confirmation-email-translation-BAC/translationID/${id}`
          : `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-confirmation-email-interpreting-BAC/bookingID/${id}`,
        {
          bcc_email,
          admin_booking_notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        buttons: false,
        icon: "success",
        title: res.data?.message,
        text: " ",
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {hidden ? null : (
        <form autoComplete="off" noValidate>
          <Paper style={{ padding: 20 }}>
            <Typography style={{ textAlign: "center" }} variant="h5">
              Send Amendment Notification to Client
            </Typography>
            <Divider orientation="horizontal" style={{ margin: 5 }} />
            <CustomInputSingle
              onChange={handleChange}
              value={bcc_email}
              name="bcc_email"
              label="Bcc Email"
              width="100%"
              // required
              type="email"
            />
            <CustomInput
              onChange={handleChange}
              value={admin_booking_notes}
              name="admin_booking_notes"
              label="Email body or note"
              width="100%"
              required
            />
            <div
              style={{ margin: 10, display: "flex", justifyContent: "center" }}
            >
              {/* <Button
                onClick={handleCACSend}
                disabled={!bcc_email || !admin_booking_notes}
                color="purple"
              >
                Send to CAC
              </Button> */}
              <Button
                onClick={handleBACSend}
                disabled={ !admin_booking_notes}
                color="purple"
              >
                Send to BAC
              </Button>
            </div>
          </Paper>
        </form>
      )}
    </>
  );
};

export default EmailNotification;
