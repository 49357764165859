import React, { } from "react";
import LinguistLayout from "layout/LinguistLayout";
import Linguist from "components/RouteProtection/Linguist/Linguist";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import InhouseList from 'components/Linguist/ManageInhouse/DeleteRequest'
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Button } from "@material-ui/core";
import Link from '@material-ui/core/Link'
import Options from "components/Linguist/MyClient/INCOptions";



const INCAccountRemoval = () => {
  const classes = useDashboardStyles();
  return (
    <Linguist>
      <LinguistLayout>

        <GridContainer>

          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                <p className={classes.cardTitleWhite}> Add / Remove In-house Client (Account Removal Requests)</p>

                <IconButton color='inherit'><ArrowBackIcon onClick={() => history.goBack()} /></IconButton>

              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >

                <Options />
              </CardHeader>
              <CardBody>
                <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-inhouse-client/registration')} variant='contained' color='primary'>Filling up the form</Button>
                <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-inhouse-client/send-form')} variant='contained' color='primary'>Sending form</Button>
                <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-inhouse-client/removal-request')} variant='contained' color='primary'>Removal Requests</Button>
                <Link href='/delete-my-inc-account-from-linguist' target='_blank'>  <Button color='primary' variant='contained'> Removal Form</Button></Link>
              </CardBody>
              <CardBody>
                <InhouseList />
              </CardBody>
            </Card>
          </GridItem>


        </GridContainer>
      </LinguistLayout>
    </Linguist>

  );
};

export default INCAccountRemoval;
