import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LinguistOptions from "components/Employee/BookingPanel/FindLinguist/FindLinguistsOptions";
import SearchAllLin from "components/Employee/BookingPanel/FindLinguist/SearchAllLin/SearchAllLin";

const AssignmentPage = () => {
  const classes = useDashboardStyles();

  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>
                  Registered and Unregistered Linguists
                </p>
              </CardHeader>
            </Card>
            <Card>
              <CardBody>
                <LinguistOptions />
              </CardBody>
              <CardBody>
                <SearchAllLin />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default AssignmentPage;
