import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import ResetPassword from "components/AccountClient/ResetPassword/ResetPassword";

// Custom Styles

const ResetPasswordPage = () => {

  return (
    <AccountClientLayout>
      <Accountclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <ResetPassword />
          </GridItem>
        </GridContainer>
      </Accountclient>
    </AccountClientLayout>
  );
};

export default ResetPasswordPage;
