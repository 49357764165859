import { useDispatch, useSelector } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import CustomInput from "components/Common/CustomInput";
import ExpensesAndTravelDropdown from "./ExpensesAndTravelDropdown";
import {
  expensesQutation,
  addExpenses,
  removeExpenses,
} from "redux/actions/admin/invoice";

const OtherExpenseTable = () => {
  const dispatch = useDispatch();

  const { expenses } = useSelector((state) => state.invoiceReducers);
  const { rates, selectedCurrency, setCurrencyValue } = useSelector(
    (state) => state.getDataReducer
  );

  const convertedRates = rates ? rates[selectedCurrency] : 1;

  console.clear();

  const numLength = expenses.length;
  const lastExp = expenses[numLength - 1];

  const handleChange = (value, type, data) => {
    data[type] = value;

    dispatch(expensesQutation(data));
  };

  console.clear();

  const subTotal = expenses.reduce(
    (acc, item) => acc + Number(item.quantity) * Number(item.rate),
    0
  );

  return (
    <>
      {expenses
        .filter((data) => data.isReadOnly)
        .map((data) => (
          <tr key={data.id}>
            <td colSpan="2">
              <p>{data.expense}</p>
            </td>
            <td className="pt-25">
              <CustomInput
                placeholder="Quantity"
                width="100%"
                value={data.quantity}
                onChange={(e) => handleChange(e.target.value, "quantity", data)}
              />
            </td>
            <td className="pt-25">
              <CustomInput
                placeholder="Rate"
                width="100%"
                value={data.rate}
                onChange={(e) => handleChange(e.target.value, "rate", data)}
              />
            </td>
            <td className="pt-25">
              <p>
                {setCurrencyValue}
                {isNaN(Number(data.quantity) * Number(data.rate))
                  ? "0.00"
                  : (
                      Number(data.quantity) *
                      Number(data.rate) *
                      convertedRates
                    ).toFixed(2)}
              </p>
            </td>
          </tr>
        ))}
      {expenses
        .filter((data) => !data.isReadOnly)
        .map((data) => (
          <>
            <tr key={data.id}>
              <td colSpan="2">
                <ExpensesAndTravelDropdown
                  onChange={(e) =>
                    handleChange(e.target.value, "expense", data)
                  }
                  value={data.expense}
                />
              </td>
              <td className="pt-25">
                <CustomInput
                  placeholder="Quantity"
                  width="100%"
                  value={data.quantity}
                  onChange={(e) =>
                    handleChange(e.target.value, "quantity", data)
                  }
                />
              </td>
              <td className="pt-25">
                <CustomInput
                  placeholder="Rate"
                  width="100%"
                  value={data.rate}
                  onChange={(e) => handleChange(e.target.value, "rate", data)}
                />
              </td>
              <td className="pt-25">
                <p>
                  {setCurrencyValue}
                  {isNaN(Number(data.quantity) * Number(data.rate))
                    ? "0.00"
                    : (Number(data.quantity) * Number(data.rate)).toFixed(2)}
                </p>
                <IconButton
                  aria-label="add"
                  color="primary"
                  onClick={() => dispatch(removeExpenses(data))}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </td>
            </tr>
            {data.expense.toLowerCase().includes("other") ? (
              <tr>
                <td colSpan="2" className="other-input">
                  <CustomInput
                    placeholder="Write here..."
                    width="100%"
                    onChange={(e) =>
                      handleChange(e.target.value, "expense", data)
                    }
                    value={data.expense}
                  />
                </td>
              </tr>
            ) : null}
          </>
        ))}
      {/* Sub Total */}
      <tr>
        <td colSpan="2"></td>
        <td className=""></td>
        <td className=""></td>
        <td
          className=""
          style={{
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            aria-label="add"
            color="primary"
            onClick={() => dispatch(addExpenses())}
            style={{
              marginRight: "2rem",
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </td>
      </tr>
      <tr>
        <td colSpan="2"></td>
        <td className="pt-25"></td>
        <td className="pt-25">
          <p>
            <strong>Sub Total</strong>
          </p>
        </td>
        <td className="pt-25">
          <p>
            <strong>
              {setCurrencyValue}{" "}
              {isNaN(subTotal)
                ? "0.00"
                : (subTotal * convertedRates).toFixed(2)}
            </strong>
          </p>
        </td>
      </tr>
    </>
  );
};

export default OtherExpenseTable;
