import React, { useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import { Button } from "semantic-ui-react";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import swal from "sweetalert";

const Action = ({
  id,
  load,
  accept,
  cancel,
  timesheet,
  source,
  target,
  linID,
  month_duration
}) => {
  const token = Cookies.get("BACtoken");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cancelRequest = async (e) => {
    try {
      const response = await Axios.put(
        `/api/accountclient/cancel-booking/bookingID/${id}`,"",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Cancel request sent to admin!!!`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCancel = async (e, id) => {
    swal(
      `This will send cancellation request to the Admin Team. You will hear from them if this job has been cancelled. Are you sure you want to send cancellation request for this assignment?`,
      {
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }
    ).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          cancelRequest(value);

        default:
          break;
      }
    });
  };

  const [totalTimesheet, setTotalTimesheet] = React.useState(false);
  const getTotalTimesheet = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/count-bulk-interpreting-timesheet/bookingID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalTimesheet(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalTimesheet();
  }, []);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {accept ? (
          <MenuItem
            onClick={() =>
              history.push(
                `/account-client/protected/public/profile/${source}/${target}/${linID}`
              )
            }
          >
            Request Linguist Details
          </MenuItem>
        ) : null}

        {totalTimesheet > 0 ? (
          <MenuItem
            onClick={() =>
              history.push(
                `/account-client/listview-bulk-interpreting-timesheet/${id}`
              )
            }
          >
            View Timesheet ({totalTimesheet})
          </MenuItem>
        ) : null}

        {totalTimesheet < month_duration ? (
          <MenuItem
            onClick={() =>
              history.push(
                `/account-client/create-bac-bulk-interpreting-timesheet/${id}`
              )
            }
          >
            Close & Timesheet ({totalTimesheet})
          </MenuItem>
        ) : null}

        {!timesheet && !cancel ? (
          <MenuItem onClick={handleCancel}>Cancel</MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default Action;
