import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Options from "components/Admin/CrmPanel/BAC/List/Options";
import BAC from "components/Admin/CrmPanel/BAC/List/List";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const ViewCashClient = () => {
  const classes = useDashboardStyles();

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>
                  View Active Billing Account Clients
                </p>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Options />
                  <IconButton color="inherit">
                    <ArrowBackIcon onClick={() => history.goBack()} />
                  </IconButton>
                </div>
              </CardHeader>
              <CardBody>
                <BAC />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default ViewCashClient;
