import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ListAltIcon from '@material-ui/icons/ListAlt';
import SendIcon from '@material-ui/icons/Send';
import { useMediaQuery } from "@material-ui/core";
import { Fragment } from "react";

const ULOptions = () => {
  const history = useHistory();
  return (
    <div style={{display:'flex',justifyContent:'center'}}>
      <Button
        startIcon={<PersonAddIcon />}
        color="primary"
        variant="contained"
        style={{ width: "45%", marginLeft: "15px", marginRight: "15px" }}
        onClick={() => history.push("/admin/stripe-clients")}
      >
        Stripe Clients
      </Button>
      <Button
        startIcon={<PersonAddIcon />}
        color="primary"
        variant="contained"
        style={{ width: "45%", marginLeft: "15px", marginRight: "15px" }}
        onClick={() => history.push("/admin/stripe-connected-linguists")}
      >
        Stripe Connected Linguists
      </Button>
     
    </div>
  );
};

export default ULOptions;

