import styled from "styled-components";

export const ContactDiv = styled.div`
  height: auto;
  background: lightgray;
  border-radius: 20px;
  display: flex;
  justify-content: center;

  .MuiCard-root {
    width: 100%;
    /* background-color: #b794f4; */

    background-color: #ffe7fa;
    box-shadow: 0px 4px 16px 8px rgba(0, 0, 0, 0.1);
  }

  .bottom {
    margin-top: 2rem;
  }

  a {
    color: blue;
  }

  textarea {
    width: 100%;
    background: inherit;
    margin-top: 1rem;
    padding: 1rem;
    outline: none;
    border-color: #333;
    &::placeholder {
      color: #000;
      font-size: 15px;
    }
  }

  @media (max-width: 960px) {
    .MuiGrid-item {
      margin-bottom: 2rem;
    }

    .heading {
      text-align: center;
    }

    .top {
      p {
        text-align: center;
      }
    }
  }

  @media (max-width: 420px) {
    .card-body {
      padding-left: 0;
      padding-right: 0;
    }

    iframe {
      width: 100%;
    }
  }
`;

export const EpLangSolutionSideBarDiv = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  .menu.transition {
    z-index: 2000;
  }

  .menu {
    min-width: 260px !important;
    background: #aa43cc !important;

    .item,
    .header,
    .divider {
      color: #fff !important;
    }
  }

  .ui.button {
    background: #aa43cc !important;
    color: #fff !important;
    &:hover {
      background: #aa43cc !important;
    }
  }
`;

export const ForensicExEvSideBarDiv = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  .menu.transition {
    z-index: 2000;
  }

  .menu {
    min-width: 260px !important;
    background: #aa43cc !important;

    .item,
    .header,
    .divider {
      color: #fff !important;
    }
  }

  .ui.button {
    background: #aa43cc !important;
    color: #fff !important;
    &:hover {
      background: #aa43cc !important;
    }
  }
`;

export const BilingualProfSideBarDiv = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  .menu.transition {
    z-index: 2000;
  }

  .menu {
    min-width: 260px !important;
    background: #aa43cc !important;

    .item,
    .header,
    .divider {
      color: #fff !important;
    }
  }

  .ui.button {
    background: #aa43cc !important;
    color: #fff !important;
    &:hover {
      background: #aa43cc !important;
    }
  }
`;

export const TransSidebarDiv = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  .menu.transition {
    z-index: 2000;
  }

  .menu {
    min-width: 260px !important;
    background: #aa43cc !important;

    .item,
    .header,
    .divider {
      color: #fff !important;
    }
  }

  .ui.button {
    background: #aa43cc !important;
    color: #fff !important;
    &:hover {
      background: #aa43cc !important;
    }
  }
`;

export const MainDiv = styled.div`
  img {
    width: 100%;
  }

  .MuiGrid-item:nth-child(1) {
    padding-left: 0px !important;
  }

  /* display: flex;
  align-items: center;

  .left {
    height: 330px;
    img {
      height: 100%;
    }
  }
  .right {
    padding-left: 15px;
  }

  @media (max-width: 1540px) {
    flex-wrap: wrap;
  } */
`;

export const ImageDiv = styled.div`
  .MuiGrid-item {
    padding: 0px !important;
  }
  .MuiGrid-item:nth-child(2) {
    padding: 0px 15px !important;

    @media (max-width: 960px) {
      padding: 0px !important;
    }
  }

  img {
    border-radius: 5px;
    height: 100%;
    width: 100%;
  }
`;

export const SearchDiv = styled.div`
  .ui.right.icon.input {
    width: 50%;
    @media (max-width: 580px) {
      width: 100%;
    }
  }

  .results {
    height: 130px;
    overflow-y: auto;
  }
`;
