import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Button, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import HelpIcon from "@material-ui/icons/Help";
import helpicon from "assets/icons/help icon-06.png";
import history from "utils/history";
import Cookies from "js-cookie";
import Axios from "axios";
// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";

const NavLinks = () => {
  const classes = useNavbarStyles();
  const handleSignout = async () => {
    if (Cookies.get("CACtoken")) {
      await Axios.get("/api/cashclient/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("CACtoken")}`,
        },
      });
      Cookies.remove("CACtoken");
      Cookies.remove("CACuser");
      localStorage.removeItem("CACuser");
      sessionStorage.clear();
      Cookies.set("isAuth", false);
      history.push("/cash-client-login");
    } else if (Cookies.get("BACtoken")) {
      await Axios.get("/api/accountclient/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("BACtoken")}`,
        },
      });
      Cookies.remove("BACtoken");
      Cookies.remove("BACuser");
      localStorage.removeItem("BACuser");
      sessionStorage.clear();
      history.push("/account-client-login");
    }
    if (Cookies.get("incClientID")) {
      Cookies.remove("clientID");
      Cookies.remove("incClientID");
      sessionStorage.clear();
      history.push("/");
    }
  };
  return (
    <Fragment>
      <Link to="/">
        <Button className={classes.menuButton} color="primary">
          <Typography
            variant="subtitle1"
            noWrap
            style={{
              color: "#6E3CBC",
              fontWeight: "600",
            }}
          >
            Home
          </Typography>
        </Button>
      </Link>
      <Link to="/landing-page/clients-portal">
        <Button className={classes.menuButton} color="primary">
          <Typography
            variant="subtitle1"
            noWrap
            style={{
              color: "#6E3CBC",
              fontWeight: "600",
            }}
          >
            {/* Book Interpreter, Order Translation or Request a Quote */}
            Customer
          </Typography>
        </Button>
      </Link>
      {/* <Link to="/interpreting-or-translation"> */}
      <Link to="/landing-page/about">
        <Button className={classes.menuButton} color="primary">
          <Typography
            style={{
              color: "#6E3CBC",
              fontWeight: "600",
            }}
            variant="subtitle1"
            noWrap
          >
            About Us
          </Typography>
        </Button>
      </Link>
      <div className={classes.menuButton}>
        {/* {Cookies.get("incClientID")&&(!Cookies.get("BACtoken") ||
          !Cookies.get("CACtoken")) && (
            <Button
              style={{ padding: 7, backgroundColor: "red", color: "white" }}
              variant="contained"
              // color="secondary"
              onClick={() => handleSignout()}
            >
              logout
            </Button>
          )} */}
        {(Cookies.get("BACtoken") || Cookies.get("CACtoken")) && (
          <>
            {" "}
            <Button
              style={{ padding: 7 }}
              variant="contained"
              color="primary"
              onClick={() =>
                Cookies.get("BACtoken")
                  ? history.push("/account-client/dashboard")
                  : history.push("/cash-client/dashboard")
              }
            >
              dashboard
            </Button>{" "}
            <Button
              style={{ padding: 7, backgroundColor: "red", color: "white" }}
              variant="contained"
              // color="secondary"
              onClick={() => handleSignout()}
            >
              logout
            </Button>
          </>
        )}
        {!Cookies.get("CACtoken") && !Cookies.get("BACtoken") && (
          <Button
            style={{ padding: 7, backgroundColor: "purple", color: "white" }}
            variant="contained"
            // color="secondary"
            onClick={() => history.push("/interpreting-or-translation")}
          >
            Book Interpreter / Order Translation
          </Button>
        )}
        <Link to="/landing-page/faq">
          <IconButton>
            <img
              style={{
                width: "105px",
                height: "40px",
                marginRight: "5px",
                marginBottom: "4px",
              }}
              src={helpicon}
              alt=""
            />
            {/* <HelpIcon /> */}
          </IconButton>
        </Link>
      </div>
      {/* </Link> */}

      {/* <Link to="/landing-page/contact">
        <Button className={classes.menuButton} color="primary">
          <Typography
            style={{
              color: "#6E3CBC",
              fontWeight6'700'
            }}
            variant="subtitle1"
            noWrap
          >
            Contact Us
          </Typography>
        </Button>
      </Link> */}
    </Fragment>
  );
};

export default NavLinks;
