import React from "react";

// Material Components
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// Custom Components
import CustomFormControl from "./CustomFormControl";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomLabel from "./CustomLabel";

const CustomDate = ({
  label,
  width,
  moreInfo,
  onChange,
  value,
  required,
  maxDate,
  minDate,
  format,
  ...rest
}) => {
  const classes = useFormStyles();

  const [selectedDate, setSelectedDate] = React.useState(value || null);

  const handleDateChange = (date, value) => {
    setSelectedDate(date);
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <CustomFormControl width={width} {...rest}>
      <MuiPickersUtilsProvider
        className={classes.pickerClass}
        utils={DateFnsUtils}
      >
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label={
            <CustomLabel
              label={label}
              moreInfo={"DD/MM/YYYY"}
              required={required}
            />
          }
          format={format || "dd/MM/yyyy"}
          value={selectedDate}
          onChange={handleDateChange}
          // maxDate={maxDate}
          minDate={minDate}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </CustomFormControl>
  );
};

export default CustomDate;
