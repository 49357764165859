import React, { useState, useEffect } from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton, Divider } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import ITLimage from "../../assets/icons/graphics-01.png";
import { Button } from "semantic-ui-react";
const JobAdvertPage = () => {
  const classes = useDashboardStyles();
  const avatar_classes = useEmployeeStyles();
  const [clientID, setClientID] = useState(null);

  const getDetails = async (e) => {
    const token = Cookies.get("CACtoken");
    const response = await Axios.get("/api/cashclient/dashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setClientID(response?.data._id);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Cashclient>
      <CashClientLayout>
        <Card>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            color="primary"
          >
            <p className={classes.cardTitleWhite}>Job Advert</p>
            <IconButton color="inherit">
              <ArrowBackIcon onClick={() => history.goBack()}></ArrowBackIcon>
            </IconButton>
          </CardHeader>

          <GridContainer
            style={{
              justifyContent: "center",
            }}
          >
            <GridItem xs={12} sm={12} md={12} lg={4}>
              <Card>
                <CardHeader>
                  <Avatar
                    onContextMenu={(e) => e.preventDefault()}
                    className={avatar_classes.large_avatar}
                    src={ITLimage}
                    alt="job advert Photo"
                  />
                </CardHeader>
                <Divider orientation="horizontal" />
                <CardBody>
                  <h5
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      // color: "purple",
                    }}
                  >
                    <ul>
                      <li>
                        Both CAC and BAC are welcome to post industry related or
                        language related job adverts with us, using the form
                        here. You must meet the following conditions:
                      </li>
                      <li>
                        The advert must be about a language or industry related
                        job.
                      </li>
                      <li>The advert should be from the employer directly.</li>
                      <li>
                        Advert must be employment related not a freelancing
                        opportunity.
                      </li>
                      <li>
                        The advert should be posted in writing and paid for,
                        using the appropriate form here.
                      </li>
                      <li>
                        LC will charge £̶1̶0̶0̶ (90% discount) per advert, but will
                        allow a 50% discount for NHS and registered charities
                        with a certified charity number.
                      </li>
                      <li>The job advert will be published on our website.</li>
                    </ul>
                  </h5>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      color="purple"
                      onClick={() =>
                        window.open(
                          `/interpreter-translator-linguists-job-advert/${clientID}`
                        )
                      }
                    >
                      Click here to post job advert
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Card>
      </CashClientLayout>
    </Cashclient>
  );
};

export default JobAdvertPage;
