import React from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import cash_client_portal from "assets/icons/cash_client_portal.png";
import account_client from "assets/icons/account_client.png";
import client from "assets/icons/client.png";
import {
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { Link } from "react-router-dom";
import QuickQuote from "components/TranslationQuickQuote/QuickQuote";

const CP = [
  "We are committed to offering our customers an outstanding service and our dedicated team are on hand to assist you with any support you may require 24 hrs a day, 7 days a week, 365 days a year.",
  "Priority in urgent booking (less than 24 hours) within the UK & EU",
  "A 14-day credit facility",
  "Priority services",
  "Additional users",
  "Fully itemised invoices and bills to easily track your spending and usage",
  "No need to worry about paying during booking interpreting or ordering translation services",
  "Account security to stop unauthorised use",
  "A dedicated accounts team",
  "Booking through our easy online system",
];

const BI = [
  "Please note, client registered with our Agent Linguist or SPPO can contact them directly for booking an interpreter or ordering human translation.",
];

const AV = [
  "Please note that the list of languages is subject to change, as per linguist availability. Some languages may not be available at the time of your booking or in your region. Rare languages may require a longer notice period. To check linguist availability, please use a Quotation to send your enquiry at least 24 hours before the appointment start time. We realise this may not always be possible. Please be aware that if you provide less than 24 hours’ notice, we will do our best to accommodate your request, but may not be able to secure a linguist in time. However, if you have paid a deposit and we are unable to secure a linguist, you can either change the date and time of the appointment, or request a full refund.",
  "Please also note that LC or LC’s payment processor cannot issue refunds for bank transfers or direct debit payments.  Clients may claim a credit note in the event of successfully disputing a service or invoice.  LC encourages CAC, INC and PRC to pay using bank or credit cards to enable ease of refunds in the event that one is required.",
];

const ClientPortal = () => {
  const classes = useLandingpageStyles();
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 0 4rem 0" }}>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card
            style={{ marginBottom: "1rem" }}
            className={classes.clientPortal}
          >
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid black",
                }}
              >
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    marginRight: "10px",
                    marginBottom: "1rem",
                  }}
                  src={cash_client_portal}
                  alt=""
                />
              </div>
              <Typography
                style={{ fontWeight: "700", borderBottom: "1px solid black" }}
                paragraph
                variant="h5"
              >
                Cash Account Client (CAC)
              </Typography>
              <Typography variant="body1" style={{ textAlign: "justify" }}>
                Please login to access your dashboard. You can access our
                services, make bookings for interpreters, place an order for
                translation and request a quotation using your CAC portal.
              </Typography>
              <List dense>
                {/* {ES.map(value => <ListItem>
                      <ListItemText
                          primary= {value}
                      />
                  </ListItem>)} */}
              </List>
              <div style={{ marginBottom: "2rem" }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/cash-client-login"
                >
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                  >
                    Cash Account (CAC) Login
                  </Button>
                </Link>
              </div>
              <div>
                <Typography variant="body1">
                  If you do not have login details, click below to create an
                  account.
                </Typography>
                <List dense>
                  {/* {ES.map(value => <ListItem>
                      <ListItemText
                          primary= {value}
                      />
                  </ListItem>)} */}
                </List>
                <div style={{ marginBottom: "2rem" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/customer-registration"
                  >
                    <Button className={`${classes.button}`} variant="contained">
                      Cash Account (CAC) Registration
                    </Button>
                  </Link>
                </div>

                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  CAC refers to a Cash Account Client. We call them Cash Clients
                  as they have to pay the estimated sum in advance in order to
                  book an interpreter, order translation online or confirm a
                  free quotation. However, CAC can request 'Pay Later' option to
                  be activated for Booking Interpreter and Ordering Translation work,
                  particularly for UK Legal Aid Jobs.
                </Typography>
              </div>
            </CardBody>
          </Card>
          <Card className={classes.clientPortal}>
            <CardBody
              style={{
                paddingBottom: "3rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid black",
                }}
              >
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    marginRight: "10px",
                    marginBottom: "1rem",
                  }}
                  src={account_client}
                  alt=""
                />
              </div>
              <Typography
                style={{ fontWeight: "700", borderBottom: "1px solid black" }}
                paragraph
                variant="h5"
              >
                Billing Account Client (BAC)
              </Typography>
              <Typography variant="body1" style={{ textAlign: "justify" }}>
                Please login to access your dashboard. You can access our
                services, make bookings for interpreters, place an order for
                translation and request a quotation using your BAC portal.
              </Typography>
              <List dense>
                {/* {ES.map(value => <ListItem>
                      <ListItemText
                          primary= {value}
                      />
                  </ListItem>)} */}
              </List>
              <div style={{ marginBottom: "2rem" }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/account-client-login"
                >
                  <Button className={`${classes.button}`} variant="contained">
                    Billing Account (BAC) Login
                  </Button>
                </Link>
              </div>
              <Typography
                style={{ marginTop: "1rem" }}
                //align="center"
                variant="body1"
              >
                If you do not have Billing Account login details, click below to
                create an account.
              </Typography>

              <List dense></List>

              <div style={{ marginBottom: "2rem" }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/clientacc-opening-form"
                >
                  <Button className={`${classes.button}`} variant="contained">
                    Open A Billing Account (BAC)
                  </Button>
                </Link>
              </div>

              <Typography variant="body1" style={{ textAlign: "justify" }}>
                Billing Account Client is referred as BAC by LC. BAC are mainly
                large organisations or SMEs that have a contract in place
                between LC and BAC and pays monthly bill. BAC not required to
                pay in advance for their booking interpreter, ordering
                translation or confirming a quote.
              </Typography>
              <br />
              <Typography style={{ fontWeight: "600" }} paragraph variant="h6">
                Why open a Billing Account?
              </Typography>

              <Typography variant="body1">
                Our customers can enjoy the following benefits by creating an
                account today:
              </Typography>
              <List dense>
                {CP.map((value) => (
                  <ListItem>
                    <ListItemText primary={value} />
                  </ListItem>
                ))}
              </List>
              <Typography paragraph variant="body1">
                By creating an account, our customers can receive premier
                services for an additional 10% administration charge.
              </Typography>

              <br />
              <Typography variant="body1" style={{ textAlign: "justify" }}>
                *Please be aware that all applications will require additional
                information and a credit check before approval can be given.
                Once your account is approved and set up, we assure you that
                there are various levels of security protection to stop
                unauthorised usage. This will include a password for authorised
                users, etc. At the end of each month, you will receive a fully
                itemised bill of all bookings and orders taken, each individual
                cost, who booked the assignment, details of the job, location
                and any other additional time charges, if applicable. Please
                follow the "Open A Billing Account (BAC)" link above and a
                member of the team will get in contact with you.
              </Typography>
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={6} lg={6}>
          
        </GridItem> */}
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card
            style={{ marginBottom: "1rem" }}
            className={classes.clientPortal}
          >
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid black",
                }}
              >
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    marginRight: "10px",
                    marginBottom: "1rem",
                  }}
                  src={client}
                  alt=""
                />
              </div>
              <Typography
                style={{ fontWeight: "700", borderBottom: "1px solid black" }}
                paragraph
                variant="h5"
              >
                Book, Order and Quote
              </Typography>
              <Typography style={{ fontWeight: "600" }} variant="h6">
                Booking an Interpreter
              </Typography>
              <List dense>
                <ListItem style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ textAlign: "justify" }}>
                    Should you wish to book an interpreter or order translation
                    online, login to your{" "}
                    <Link style={{ margin: "2px" }} to="/cash-client-login">
                      {" "}
                      CAC{" "}
                    </Link>{" "}
                    {"  "}or{"  "}
                    <Link style={{ margin: "2px" }} to="/account-client-login">
                      {" "}
                      BAC{" "}
                    </Link>{" "}
                    dashboard and fill up the necessary information using
                    booking form and submit. One of our linguist or a member of
                    our admin team will confirm you ASAP. The end of the booking
                    form system will ask you to pay an estimate as a deposit or
                    add to billing account. After delivering the service
                    required, our linguist or admin team will issue a final
                    invoice, making necessary adjustments. An Account customer
                    (BAC) can simply add the booking to their billing account.,{" "}
                  </div>
                </ListItem>
                {BI.map((value) => (
                  <ListItem>
                    <ListItemText
                      primary={value}
                      style={{ textAlign: "justify" }}
                    />
                  </ListItem>
                ))}
              </List>
              <Typography style={{ fontWeight: "600" }} variant="h6">
                Ordering Translation Services
              </Typography>
              <List dense>
                <ListItem style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ textAlign: "justify" }}>
                    You may order a translation service of up to 10,000 words or
                    20 A4 pages directly from your{" "}
                    <Link style={{ margin: "2px" }} to="/cash-client-login">
                      {" "}
                      CAC{" "}
                    </Link>{" "}
                    or{" "}
                    <Link style={{ margin: "2px" }} to="/account-client-login">
                      {" "}
                      BAC{" "}
                    </Link>{" "}
                    dashboard. However, if your translation project is larger
                    than 10,000 words or more than 20 A4 pages, please contact
                    us by using the{" "}
                    <Link to="/add-new-customer"> Quotation form </Link> for an
                    assessment and estimate. A Linguist or member of our LC team
                    will get in touch as soon as possible.
                  </div>
                </ListItem>
              </List>

              <Typography style={{ fontWeight: "600" }} variant="h6">
                Requesting a free Quote
              </Typography>
              <List dense>
                <ListItem>
                  <div style={{ textAlign: "justify" }}>
                    You can request a free quote for interpreting, translation,
                    expert language solutions, expert evidence, and for hiring a
                    multilingual, plurilingual or bilingual professional. Please
                    provide as much detail as possible and include any file that
                    you wish us to consider by filling up the quotation form.
                    One of our linguists or a member of our Admin Team will be
                    in touch with you. For interpreting and translation services
                    customer can request quote from
                    <Link style={{ margin: "2px" }} to="/cash-client-login">
                      {" "}
                      CAC{" "}
                    </Link>{" "}
                    or
                    <Link style={{ margin: "2px" }} to="/account-client-login">
                      {" "}
                      BAC{" "}
                    </Link>{" "}
                    dashboard, customer may receive multiple quotes from our
                    Linguists, CACs and BACs can accept any, but have no
                    obligation. For special services or extensive project
                    contact LC directly using the{" "}
                    <Link style={{ margin: "2px" }} to="/add-new-customer">
                      {" "}
                      enquiry form
                    </Link>
                    {"."}
                  </div>
                </ListItem>

                <br />
                <Link style={{ textDecoration: "none" }} to="/add-new-customer">
                  <Button className={`${classes.button}`} variant="contained">
                    Request free quote for an extensive project
                  </Button>
                </Link>
                <br />
                <br />
              </List>

              <Typography style={{ fontWeight: "600" }} variant="h6">
                Availability
              </Typography>
              <List dense>
                {AV.map((value) => (
                  <ListItem>
                    <ListItemText
                      primary={value}
                      style={{ textAlign: "justify" }}
                    />
                  </ListItem>
                ))}
              </List>
              <div>
                View <a href="/landing-page/view-rate-card"> Rate Card</a>
              </div>
              <div>
                *Recommended web browser:
                <a href="JavaScript:newPopup('https://www.google.co.uk/chrome/?brand=FHFK&gclsrc=aw.ds&gclid=EAIaIQobChMImueQy6yL8QIVCt1RCh0lIQ-7EAAYASAAEgJdS_D_BwE');">
                  {" "}
                  Chrome{" "}
                </a>
                or{" "}
                <a href="JavaScript:newPopup('https://www.microsoft.com/en-us/edge');">
                  {" "}
                  Edge{" "}
                </a>
              </div>
            </CardBody>
          </Card>

          <Card
            style={{ marginBottom: "1rem" }}
            className={classes.clientPortal}
          >
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid black",
                }}
              ></div>
              <Typography
                style={{ fontWeight: "700", borderBottom: "1px solid black" }}
                paragraph
                variant="h5"
              >
                Translation Quick Quote
              </Typography>

              <QuickQuote />

              <br />
              <Typography
                style={{
                  fontWeight: "700",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                paragraph
                variant="h5"
              >
                <a href="/add-new-lead">Leave details </a>to be contacted by LC
                Sales and Marketing Team
              </Typography>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default ClientPortal;
