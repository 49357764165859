import React, { useState } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import DateFilter from "./DateFilter";
import TableData from "./TableData";
import AddData from "./AddData";
import { CashbookDiv } from "./style";

const cRef = React.createRef();

const CashbookPage = () => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [reload, setReload] = useState();

  return (
    <CashbookDiv>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            margin: "auto",
          }}
        >
          <AddData setReload={setReload} />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <TableData
            reload={reload}
            setReload={setReload}
            fromDate={fromDate}
            toDate={toDate}
            cRef={cRef}
          />
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={12} lg={12}>
          <DateFilter
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            cRef={cRef}
          />
        </GridItem> */}
      </GridContainer>
    </CashbookDiv>
  );
};

export default CashbookPage;
