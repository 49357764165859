
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AUSDAccount } from "../../../../action/manager";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable() {

  const classes = useStyles();

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bankList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bankList, removed, reload } = values;


  useEffect(() => {
    loadList();
  }, [reload]);

  const loadList = () => {
    AUSDAccount().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setValues({ ...values, bankList: data });
      }
    });
  };


  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
         
          <TableHead>
          <TableRow>
            <TableCell>Account Holder</TableCell>
            <TableCell>BSB Code</TableCell>
            <TableCell>Account Number</TableCell>
            
          </TableRow>
        </TableHead>
          <TableBody>
           
            {bankList.map((list) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={list?._id}>
              
              <TableCell>{list?.account_holder}</TableCell>
              <TableCell>{list?.bsb_code}</TableCell>
              <TableCell>{list?.account_number}</TableCell>
              
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Paper>
  );
}