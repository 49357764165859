import React from "react";
import Sppo from "components/RouteProtection/Sppo/Sppo";
import SppoLayout from "layout/SppoLayout";
// Custom Components
// Custom Styles
import ViewAttach from "components/Admin/ManagementPanel/ViewAttachMessage";

const ViewContactCard = () => {
  return (
    <SppoLayout>
      <Sppo>
        <ViewAttach />
      </Sppo>
    </SppoLayout>
  );
};

export default ViewContactCard;
