import React, { useState, Fragment, useEffect } from "react";
// Material imports
import { Avatar, IconButton, useMediaQuery } from "@material-ui/core";
// Material Icons
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import publicProfileOne from "assets/images/new sppo and pp photo-02.jpg";
import publicProfileTwo from "assets/images/SPPO banner-05.jpg";
import publicProfileThree from "assets/images/public profile-06.jpg";
import publicProfileFour from "assets/images/public profile-01.jpg";
import Slider from "../../Sliders/Slider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// Custom imports
import { useStylesAvatar } from "assets/jss/layouts/styleP";

import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

import Axios from "axios";
import swal from "sweetalert";
import { updateLinguistProfile } from "redux/actions/updateProfileAction";
//HeaderPage
import { Header } from "semantic-ui-react";

function HeaderPage() {
  const [openEdit, setOpenEdit] = useState(false);
  const [username, setUsername] = useState("Pratik Bandal");
  const [companyDetails, setCompanyDetails] = useState("");
  const dispatch = useDispatch();
  const [openProfileUpdate, setOpenProfileUpdate] = useState(false);
  const [upload, setUpload] = useState(false);
  const [loader, setLoader] = useState(false);
  const [rating, setRating] = useState(2);
  const [formats, setFormats] = React.useState(() => ["phone"]);
  const matches = useMediaQuery("(max-width:768px)");
  const token = Cookies.get("SPPOtoken");
  const { data } = useSelector((state) => state.updatePPLReducer);
  const sliderImg = [
    publicProfileOne,
    publicProfileTwo,
    publicProfileThree,
    publicProfileFour,
  ];
  const handleFormat = (event, newFormats) => {
    if (newFormats.length) {
      setFormats(newFormats);
    }
  };

  const classes_avatar = useStylesAvatar();
  const API_KEY = process.env.REACT_APP_MAPS_API_KEY;

  const getSppoDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(updateLinguistProfile(response?.data));
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/get-company-details/${
          data?.agent_sppo ? data?.agent_sppo : data?._id
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCompanyDetails(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSppoDetails();
    getCompanyDetails();
  }, []);

  const handleClose = (e) => {
    setUpload(false);
  };

  const handleUpload = async (e) => {
    document.querySelector("#file").click();
  };

  const handleFiles = async (e) => {
    if (e.target.files.length) {
      let fd = new FormData();

      fd.append("public_profile_photo", e.target.files[0]);

      try {
        const response = await Axios.put(
          `${process.env.REACT_APP_BACKEND_API}/sppo/update-company-profile/${
            data?.agent_sppo ? data?.agent_sppo : data?._id
          }`,
          fd,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        swal({
          icon: "success",
          text: "Thank you for updating profile",
          buttons: false,
        }).then(function () {
          window.location = `/sppo/manage-public-profile`;
        });
      } catch (error) {
        console.log(error.response);
        if (error.response?.data) {
          swal({
            icon: "error",
            text: error?.response?.data?.error,
            buttons: false,
            dangerMode: true,
          });
        }
      }
    }
  };

  return (
    <Fragment>
      <div className="app__header__card">
        <div
          style={{ position: "relative", marginBottom: "60px" }}
          className="relative mb-8"
        >
          <Slider data={sliderImg} />
          <div
            style={{ cursor: "pointer" }}
            onClick={handleUpload}
            className="image"
            className="profile_avatar"
          >
            <Avatar
              onContextMenu={(e) => e.preventDefault()}
              onClick={() => setOpenProfileUpdate(true)}
              className={classes_avatar.root}
              src={`${
                process.env.REACT_APP_BACKEND_API
              }/sppo/public-profile-photo/${
                data?.agent_sppo ? data?.agent_sppo : data?._id
              }`}
              alt="P"
              style={{ left: "10px" }}
            />
          </div>
          <input
            onChange={handleFiles}
            type="file"
            accept="image/png, image/jpeg"
            id="file"
            style={{ display: "none" }}
          />
        </div>

        <div
          style={{
            padding: "32px",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "100px",
          }}
          className="p-8"
        >
          <div style={{ width: "100%" }}>
            <Header as="h1">
              {companyDetails?.title ? companyDetails?.title : data?.title}{" "}
              {companyDetails?.first_name
                ? companyDetails?.first_name
                : data?.first_name}{" "}
              {companyDetails?.last_name
                ? companyDetails?.last_name
                : data?.last_name}
              <IconButton>
                <VerifiedUserIcon />
              </IconButton>
            </Header>
            <br />

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
              }}
            >
              <div>
                <p
                  style={{ fontSize: "24px", margin: "0" }}
                  className="text-xl font-light"
                >
                  LC SPPO ID:{" "}
                  {companyDetails?.id ? companyDetails?.id : data?.id}
                </p>
                <br />
                <Header as="h3">Business details</Header>
                <Header as="h3">
                  {" "}
                  Name:{" "}
                  {companyDetails?.business_name
                    ? companyDetails?.business_name
                    : data?.business_name}
                </Header>
                <Header as="h3">
                  Legal name:{" "}
                  {companyDetails?.legal_business_name
                    ? companyDetails?.legal_business_name
                    : data.legal_business_name}
                </Header>
                <Header as="h3">
                  {" "}
                  Website:{" "}
                  {companyDetails?.business_website
                    ? companyDetails?.business_website
                    : data.business_website}
                </Header>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <LocationOnIcon />
                  <Header
                    style={{ margin: "0", marginLeft: "5px" }}
                    color="grey"
                    size="medium"
                  >
                    {companyDetails?.business_city
                      ? companyDetails?.business_city
                      : data.business_city}{" "}
                    {companyDetails?.business_postal_code
                      ? companyDetails?.business_postal_code
                      : data.business_postal_code}{" "}
                    {companyDetails?.business_country
                      ? companyDetails?.business_country
                      : data.business_country}
                  </Header>
                </div>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="100%"
                  style={{ border: "0px" }}
                  loading="lazy"
                  src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q= ${data.business_address_line1}, ${data.business_city}, ${data.business_region}, ${data.business_postal_code}, ${data.business_country}`}
                ></iframe>
              </div>
            </div>
          </div>

          {/* {data.verified ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3 style={{ margin: "0" }}>Profile privacy*, public?</h3>
                <Checkbox
                  toggle
                  checked={data?.full_public_profile_section}
                  onChange={() =>
                    handlePublicProfile("full_public_profile_section")
                  }
                />
                <div style={{ marginTop: 10 }}>
                  <Button
                    color="purple"
                    onClick={() =>
                      window.open(`/sppo/protected/public/profile/${data?._id}`)
                    }
                  >
                    Preview Public Profile
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <p>Account unverified, preview denied.</p>
          )} */}
        </div>
      </div>
    </Fragment>
  );
}

export default HeaderPage;
