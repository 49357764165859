const { CHANGE_TIMESHEET_INPUT, UPDATE_TIMESHEET } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    data: {
        signature: '',
        feedback: '',
        note: '',
        start_date: '',
        end_date: '',
        end_time: '',
        start_time: '',
        duration: '',
        declaration: '',
        feedbackToLinguist: '',
    }
};


const timesheetReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case CHANGE_TIMESHEET_INPUT:
            return {
                ...state,
                data: {
                  ...state.data,
                  [payload.name]: payload.value
                }
            }
        
        case UPDATE_TIMESHEET: 
            return {
                ...state,
                data: {
                    ...state.data,
                    start_date: payload.start_date,
                    end_date: payload.end_date,
                    end_time: payload.end_time,
                    start_time: payload.start_time,
                    duration: payload.duration
                }
            }
        
        default:
            return state
    }
}

export default timesheetReducer
