/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Switch, Route, Router } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import swal from "sweetalert";
import { ToastProvider } from "react-toast-notifications";
import { currencyValue } from "redux/actions/getData";
import Axios from "utils/axios";
import Cookies from "js-cookie";

// Custom Components
import Home from "pages/Home";
import ReverseMap from "components/Common/Map";
import { useMediaQuery } from "@material-ui/core";

import CookieConsent from "react-cookie-consent";

// Others

import LANDING_PAGE from "routes/landingPage";
import REGISTRATION from "routes/registration";
import AUTH from "routes/auth";
import CA_OPENING from "routes/clientAccOpening";
import INITIAL_REGISTRATION from "routes/initialRegistration";
import SPPOR from "routes/SPPORegistration";
import ADMIN_REG from "routes/adminRegistration";

import ADMIN_ROUTES from "routes/admin";
import MANAGEMENT_PANEL_ROUTES from "routes/managementPanel";
import CASH_CLIENT from "routes/cashClient";
import EMPLOYEE from "routes/employee";
import LC_JOINING from "routes/linguistCollectiveJoining";

// import JOB_ADVERT from "routes/jobAdvert";
import QUOTE_REQUEST from "routes/quoteRequest";
import LINGUIST from "routes/linguist";
import ACCOUNT_CLIENT from "routes/accountClient";

import IBT from "routes/IBT";
import OTT from "routes/OTT";
import QRT from "routes/QRT";
import SPPO from "routes/sppo";
import history from "utils/history";
import VIEW_BI from "routes/viewBI";
import VIEW_QR from "routes/viewQR";
import BI_CASHCLIENT from "routes/biCashClient";
import PROFILE_VIEW from "routes/profileView";
import TIMESHEET from "routes/timeSheet";
import INHOUSE_FORMS from "routes/inhouseForms";
import BI_ACCPUNTCLIENT from "routes/biaccountclient";
import BI_ADMIN from "routes/biadmin";
import ScrollTop from "./ScrollTop";

import PageNotFound from "pages/PageNotFound";

const routes = [
  ...SPPO,
  ...ADMIN_ROUTES,
  ...REGISTRATION,
  ...MANAGEMENT_PANEL_ROUTES,
  ...CASH_CLIENT,
  ...AUTH,
  ...EMPLOYEE,
  ...LC_JOINING,
  ...CA_OPENING,
  ...QUOTE_REQUEST,
  ...LANDING_PAGE,
  ...LINGUIST,
  ...ACCOUNT_CLIENT,
  ...INITIAL_REGISTRATION,
  ...SPPOR,
  ...IBT,
  ...OTT,
  ...QRT,
  ...VIEW_BI,
  ...VIEW_QR,
  ...BI_CASHCLIENT,
  ...ADMIN_REG,
  ...PROFILE_VIEW,
  ...TIMESHEET,
  ...INHOUSE_FORMS,
  ...BI_ACCPUNTCLIENT,
  ...BI_ADMIN,
];

function App() {
  const dispatch = useDispatch();
  const dashboardConditions =
    !window.location.href.includes(`sppo/login`) &&
    !window.location.href.includes(`admin/login`) &&
    !window.location.href.includes(`cash-client-login`) &&
    !window.location.href.includes(`account-client-login`) &&
    !window.location.href.includes(`employee-login`) &&
    !window.location.href.includes(`linguists-login`) &&
    !window.location.href.includes(`linguist/registration`) &&
    (window.location.pathname?.split("/")[1]?.includes(`admin`) ||
      window.location.pathname?.split("/")[1]?.includes(`linguist`) ||
      window.location.pathname?.split("/")[1]?.includes(`cash-client`) ||
      window.location.pathname?.split("/")[1]?.includes(`cashclient`) ||
      window.location.pathname?.split("/")[1]?.includes(`account-client`) ||
      window.location.pathname?.split("/")[1]?.includes(`employee`) ||
      window.location.pathname?.split("/")[1]?.includes(`sppo`));
  const timeout = 7200000; //30min in milliseconds
  // const timeout = 300000; //5min in milliseconds

  const handleLogOut = async (e) => {
    if (
      history.location.pathname.includes("cash-client") ||
      history.location.pathname.includes("cashclient")
    ) {
      await Axios.get("/api/cashclient/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("CACtoken")}`,
        },
      });
      Cookies.remove("CACtoken");
      Cookies.set("isAuth", false);
      Cookies.remove("CACuser");
      localStorage.removeItem("CACuser");

      window.location = "/cash-client-login";
    } else if (history.location.pathname.includes("account-client")) {
      await Axios.get("/api/accountclient/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("BACtoken")}`,
        },
      });
      Cookies.remove("BACtoken");
      Cookies.remove("BACuser");
      localStorage.removeItem("BACuser");
      window.location = "/account-client-login";
    } else if (history.location.pathname.includes("admin")) {
      await Axios.get("/api/manager-admin/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      Cookies.remove("token");
      window.location = "/admin/login";
    } else if (history.location.pathname.includes("linguist")) {
      await Axios.get("/api/linguists/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("LINtoken")}`,
        },
      });
      Cookies.remove("LINtoken");
      window.location = "/linguists-login";
    } else if (history.location.pathname.includes("employee")) {
      await Axios.get("/api/employee/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("EMPtoken")}`,
        },
      });
      Cookies.remove("EMPtoken");
      window.location = "/employee-login";
    } else if (history.location.pathname.includes("sppo")) {
      await Axios.get("/api/sppo/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("SPPOtoken")}`,
        },
      });
      Cookies.remove("SPPOtoken");
      window.location = "/sppo/login";
    }
    if (history.location.pathname.includes("/interpreting-or-translation")) {
      if (Cookies.get("BACtoken")) {
        await Axios.get("/api/accountclient/signout", {
          headers: {
            Authorization: `Bearer ${Cookies.get("BACtoken")}`,
          },
        });
        Cookies.remove("BACtoken");
        Cookies.remove("BACuser");
        localStorage.removeItem("BACuser");
        sessionStorage.clear();
        window.location = "/account-client-login";
      } else if (Cookies.get("CACtoken")) {
        await Axios.get("/api/cashclient/signout", {
          headers: {
            Authorization: `Bearer ${Cookies.get("CACtoken")}`,
          },
        });
        Cookies.remove("CACtoken");
        Cookies.set("isAuth", false);
        Cookies.remove("CACuser");
        localStorage.removeItem("CACuser");
        sessionStorage.clear();
        window.location = "/cash-client-login";
      }
      if (Cookies.get("incClientID")) {
        Cookies.remove("clientID");
        Cookies.remove("incClientID");
        sessionStorage.clear();
        history.push("/");
      }
    }
  };
  const handleOnIdle = () => {
    if (
      !window.location.href.includes(`sppo/login`) &&
      !window.location.href.includes(`admin/login`) &&
      !window.location.href.includes(`cash-client-login`) &&
      !window.location.href.includes(`account-client-login`) &&
      !window.location.href.includes(`employee-login`) &&
      !window.location.href.includes(`linguists-login`) &&
      !window.location.href.includes(`linguist/registration`) &&
      (window.location.pathname
        ?.split("/")[1]
        ?.includes(`interpreting-or-translation`) ||
        window.location.pathname?.split("/")[1]?.includes(`admin`) ||
        window.location.pathname?.split("/")[1]?.includes(`linguist`) ||
        window.location.pathname?.split("/")[1]?.includes(`cash-client`) ||
        window.location.pathname?.split("/")[1]?.includes(`cash-client`) ||
        window.location.pathname?.split("/")[1]?.includes(`account-client`) ||
        window.location.pathname?.split("/")[1]?.includes(`employee`) ||
        window.location.pathname?.split("/")[1]?.includes(`sppo`)) &&
      getRemainingTime() === 0
    ) {
      swal.getState().isOpen === true && swal.close();
      handleLogOut();
    }
  };

  const { getRemainingTime, reset } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    crossTab: false,
    startOnMount: false,
  });

  const [state, setState] = useState({
    lat: "",
    lng: "",
  });
  const [address, setAddress] = useState("");
  const getAddress = (address) => {
    setAddress(address);
    Cookies.set("visitor_address", address);
    Cookies.set("visitor_device", getUA());
    Cookies.set("visitor_browser", getBrowser());
    getUserIP(function (ip) {
      Cookies.set("visitor_ip", ip);
    });
    Cookies.set("visitor_visit_time", new Date());
    postActivity();
  };
  const getUA = () => {
    let device = "Unknown";
    const ua = {
      "Generic Linux": /Linux/i,
      Android: /Android/i,
      BlackBerry: /BlackBerry/i,
      Bluebird: /EF500/i,
      "Chrome OS": /CrOS/i,
      Datalogic: /DL-AXIS/i,
      Honeywell: /CT50/i,
      iPad: /iPad/i,
      iPhone: /iPhone/i,
      iPod: /iPod/i,
      macOS: /Macintosh/i,
      Windows: /IEMobile|Windows/i,
      Zebra: /TC70|TC55/i,
    };
    Object.keys(ua).map(
      (v) => navigator.userAgent.match(ua[v]) && (device = v)
    );
    return device;
  };
  const getBrowser = () => {
    const userAgent = navigator.userAgent;
    let browser = "unkown";
    // Detect browser name
    browser = /ucbrowser/i.test(userAgent) ? "UCBrowser" : browser;
    browser = /edg/i.test(userAgent) ? "Edge" : browser;
    browser = /googlebot/i.test(userAgent) ? "GoogleBot" : browser;
    browser = /chromium/i.test(userAgent) ? "Chromium" : browser;
    browser =
      /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent)
        ? "Firefox"
        : browser;
    browser =
      /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent)
        ? "IE"
        : browser;
    browser =
      /chrome|crios/i.test(userAgent) &&
      !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
        ? "Chrome"
        : browser;
    browser =
      /safari/i.test(userAgent) &&
      !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(
        userAgent
      )
        ? "Safari"
        : browser;
    browser = /opr|opera/i.test(userAgent) ? "Opera" : browser;

    // detect browser version
    switch (browser) {
      case "UCBrowser":
        return `${browser}/${browserVersion(
          userAgent,
          /(ucbrowser)\/([\d\.]+)/i
        )}`;
      case "Edge":
        return `${browser}/${browserVersion(
          userAgent,
          /(edge|edga|edgios|edg)\/([\d\.]+)/i
        )}`;
      case "GoogleBot":
        return `${browser}/${browserVersion(
          userAgent,
          /(googlebot)\/([\d\.]+)/i
        )}`;
      case "Chromium":
        return `${browser}/${browserVersion(
          userAgent,
          /(chromium)\/([\d\.]+)/i
        )}`;
      case "Firefox":
        return `${browser}/${browserVersion(
          userAgent,
          /(firefox|fxios)\/([\d\.]+)/i
        )}`;
      case "Chrome":
        return `${browser}/${browserVersion(
          userAgent,
          /(chrome|crios)\/([\d\.]+)/i
        )}`;
      case "Safari":
        return `${browser}/${browserVersion(
          userAgent,
          /(safari)\/([\d\.]+)/i
        )}`;
      case "Opera":
        return `${browser}/${browserVersion(
          userAgent,
          /(opera|opr)\/([\d\.]+)/i
        )}`;
      case "IE":
        const version = browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
        // IE version is mapped using trident version
        // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
        return version
          ? `${browser}/${parseFloat(version) + 4.0}`
          : `${browser}/7.0`;
      default:
        return `unknown/0.0.0.0`;
    }
  };

  const browserVersion = (userAgent, regex) => {
    return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
  };

  const matches = useMediaQuery("(max-width:1024px)");
  const message = matches
    ? `Please enable location from browser taskbar or settings \n(finding your location helps us to improve app security & privacy)\n
steps to turn on/off location on Chrome: 
1 - On your Android phone or tablet, open the Chrome app Chrome.
2 - To the right of the address bar, tap More More and then Settings.
3 - Tap Site settings and then Location.
4 - Turn Location on .

steps to turn on/off location on Safari:
1 - Open your iOS's Settings app.
2 - Tap [Privacy] > [Location Services]
3 - Make sure that Location Services is ON
4 - Tap [Privacy] > [Location Services] > [Safari Websites] 
5 - Tap [Allow Location Access] > Set to [While Using the App]`
    : `Please enable location from browser taskbar or settings \n(finding your location helps us to improve app security & privacy)\n
steps to turn on/off location on Chrome:            
1 - On your computer, open Chrome.
2 - At the top right, click More and then Settings.
3 - Under "Privacy and security," click Site settings.
4 - Click Location.
5 - Turn Ask before accessing on or off.

steps to turn on/off location on MacOS Safari:            
1 - Click on Safari written on the top left corner.
2 - Go to Preferences.
3 - Under the Privacy tab, select Website Tracking.
4 - Uncheck the options that say “Prevent Cross-site tracking” and “Ask websites not to track me”.

steps to turn on/off location on Microsoft Edge:   
1 - Open the Action Center on your Windows 10 computer.
2 - Select All Settings.
3 - Go to Privacy and select Location
4 - You’ll see a slider that says Location Service. Turn it on.

`;
  const locationPrompt = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setState({
            ...state,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        function (error) {
          if (error.code == error.PERMISSION_DENIED) {
            swal({
              icon: "warning",
              title: "Some features will stop working",
              text: message,
            }).then((val) => {
              Cookies.remove("visitor_address");
              Cookies.set("visitor_address", "permission denied");
              Cookies.set("visitor_device", getUA());
              Cookies.set("visitor_browser", getBrowser());
              getUserIP(function (ip) {
                Cookies.set("visitor_ip", ip);
              });
              Cookies.set("visitor_visit_time", new Date());
            });
          }
        },
        {
          enableHighAccuracy: true,
        }
      );
    }
  };

  function getUserIP(onNewIP) {
    //  onNewIp - your listener function for new IPs
    //compatibility for firefox and chrome
    var myPeerConnection =
      window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
    var pc = new myPeerConnection({
        iceServers: [],
      }),
      noop = function () {},
      localIPs = {},
      ipRegex =
        /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
      key;

    function iterateIP(ip) {
      if (!localIPs[ip]) onNewIP(ip);
      localIPs[ip] = true;
    }

    //create a bogus data channel
    pc.createDataChannel("");

    // create offer and set local description
    pc.createOffer()
      .then(function (sdp) {
        sdp.sdp.split("\n").forEach(function (line) {
          if (line.indexOf("candidate") < 0) return;
          line.match(ipRegex).forEach(iterateIP);
        });

        pc.setLocalDescription(sdp, noop, noop);
      })
      .catch(function (reason) {
        console.log(reason);
      });

    //listen for candidate events
    pc.onicecandidate = function (ice) {
      if (
        !ice ||
        !ice.candidate ||
        !ice.candidate.candidate ||
        !ice.candidate.candidate.match(ipRegex)
      )
        return;
      ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
  }
  const postActivity = async () => {
    await Axios.post(`/api/visitor/post-activity-log`, {
      Device: Cookies.get("visitor_device") || getUA(),
      Location:
        Cookies.get("visitor_address") || address || "permission denied",
      Browser: Cookies.get("visitor_browser") || getBrowser(),
      IP:
        Cookies.get("visitor_ip") ||
        getUserIP(function (ip) {
          return ip;
        }),
      Time: Cookies.get("visitor_visit_time") || new Date(),
    });
  };
  useEffect(() => {
    if (
      !dashboardConditions &&
      Cookies.get("CookieConsent") === "true" &&
      !Cookies.get("visitor_address")
    ) {
      locationPrompt();
    }
    dispatch(currencyValue());
    setInterval(() => {
      if (
        !window.location.href.includes(`sppo/login`) &&
        !window.location.href.includes(`admin/login`) &&
        !window.location.href.includes(`cash-client-login`) &&
        !window.location.href.includes(`account-client-login`) &&
        !window.location.href.includes(`employee-login`) &&
        !window.location.href.includes(`linguists-login`) &&
        !window.location.href.includes(`linguist/registration`) &&
        (window.location.pathname
          ?.split("/")[1]
          ?.includes(`interpreting-or-translation`) ||
          window.location.pathname?.split("/")[1]?.includes(`admin`) ||
          window.location.pathname?.split("/")[1]?.includes(`linguist`) ||
          window.location.pathname?.split("/")[1]?.includes(`cash-client`) ||
          window.location.pathname?.split("/")[1]?.includes("cashclient") ||
          window.location.pathname?.split("/")[1]?.includes(`account-client`) ||
          window.location.pathname?.split("/")[1]?.includes(`employee`) ||
          window.location.pathname?.split("/")[1]?.includes(`sppo`))
      ) {
        if (getRemainingTime() > 0 && getRemainingTime() <= 10000) {
          swal({
            buttons: {
              cancel: "Extend Session",
              confirm: "Logout",
            },
            icon: "warning",
            title:
              getRemainingTime() <= 60000
                ? `Your session will expire in ${parseInt(
                    getRemainingTime() / 1000
                  )} seconds`
                : `Your session will expire in ${parseInt(
                    getRemainingTime() / 1000 / 60
                  )} minutes`,
            text: "Please click extend session to continue using your dashboard",
          }).then((val) => {
            if (val !== null) {
              handleLogOut();
            } else {
              reset();
              swal.getState().isOpen === true && swal.close();
            }
          });
        }
      }
    }, 1000);
  }, []);

  const allRoutes = (
    <Fragment>
      {routes.map((route) => (
        <Fragment>
          {route.subMenu ? (
            <Fragment>
              {route.subMenu.map((rt) => (
                <Router history={history}>
                  <Switch>
                    <Route
                      key={rt.id}
                      path={`/${rt.path}`}
                      exact
                      component={rt.component}
                    />
                  </Switch>
                </Router>
              ))}
            </Fragment>
          ) : (
            <Router history={history}>
              <Switch>
                <Route
                  key={route.id}
                  path={`/${route.path}`}
                  exact
                  component={route.component}
                />
              </Switch>
            </Router>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
  return (
    <>
      {state.lng !== "" && state.lat !== "" && (
        <ReverseMap
          reverse={true}
          lng={state.lng}
          lat={state.lat}
          setAddress={getAddress}
        />
      )}
      <ToastProvider
        placement="bottom-right"
        autoDismiss
        autoDismissTimeout={6000}
      >
        <Router history={history}>
          <ScrollTop />
          <Switch>
            <Route path="/" exact component={Home} />
            {allRoutes}
            <Route component={PageNotFound} />
          </Switch>
        </Router>
        <CookieConsent
          onAccept={locationPrompt}
          style={{ background: "#2c4b29" }}
        >
          This website uses cookies that help the website function and help us
          respond to your request. We use these cookies and share with our
          partners (e.g. payment processor) to provide you service and related
          support. We may use cookies to provide you with improved and
          customised user-experience. If you continue to use the website, we
          assume that you are fine with it.{" "}
          <a
            pathname="https://linguistscollective.com/landing-page/privacy-policy"
            target="_blank"
          >
            Read more..
          </a>
        </CookieConsent>
      </ToastProvider>
    </>
  );
}

export default App;
