import { useState } from "react";

import { Table } from "semantic-ui-react";

// Material Components
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

// Custom Components
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomInput from "components/Common/CustomInput";

const dropdownData = [
  "Fuel cost",
  "Parking",
  "Toll /  Congestion Charge (CC)",
  "Bus fare",
  "Train fare",
  "Taxi fare",
  "Air fare / tickets",
  "Airport transfer cost",
  "Hotel /  accommodation cost",
  "Charge / fee",
  "Food",
  "Cash paid",
  "Other expense",
];

const BoxTwo = ({
  expenseDaas,
  otherExpenseDt,
  handleAdd,
  handleRemove,
  handleOtherRemove,
  handleDropdownChange,
  handleExpInputChange,
  handleExpOtherNameInputChange,
  handleExpOtherValueInputChange,
  totalExp,
  handleOtherAdd,
  currency
}) => {
  const dLength = expenseDaas.length;
  const otherLength = otherExpenseDt.length;

  return (
    <div>
      <Table>
        <Table.Header
          style={{
            display: "none",
          }}
        >
          <Table.Row>
            <Table.HeaderCell> </Table.HeaderCell>
            <Table.HeaderCell> </Table.HeaderCell>
            <Table.HeaderCell> </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {expenseDaas.map((item, index) => (
            <Table.Row key={item.id}>
              <Table.Cell
                style={{
                  width: "300px",
                }}
              >
                <CustomAutoSelect
                  label="Select Expenses"
                  data={dropdownData}
                  onChange={(e, value) => handleDropdownChange(item.id, value)}
                />
              </Table.Cell>
              <Table.Cell
                style={{
                  paddingBottom: "0px",
                }}
              >
                <CustomInput
                  onChange={(e) =>
                    handleExpInputChange(item.id, e.target.value)
                  }
                  placeholder="£00.00"
                />
              </Table.Cell>
              <Table.Cell style={{ display: "flex", alignItems: "center" }}>
                {dLength > 1 ? (
                  <IconButton
                    aria-label="remove"
                    color="primary"
                    onClick={() => handleRemove(item.id)}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                ) : null}
                {dLength - 1 == index ? (
                  <IconButton
                    aria-label="add"
                    color="primary"
                    onClick={handleAdd}
                  >
                    <AddCircleIcon />
                  </IconButton>
                ) : null}
              </Table.Cell>
            </Table.Row>
          ))}
          {otherExpenseDt.map((item, index) => (
            <Table.Row key={item.id}>
              <Table.Cell
                style={{
                  width: "300px",
                }}
              >
                <CustomInput
                  onChange={(e) =>
                    handleExpOtherNameInputChange(item.id, e.target.value)
                  }
                  placeholder="Expenses /  Outgoings"
                />
              </Table.Cell>
              <Table.Cell
                style={{
                  paddingBottom: "8px",
                }}
              >
                <CustomInput
                  onChange={(e) =>
                    handleExpOtherValueInputChange(item.id, e.target.value)
                  }
                  placeholder="Value"
                />
              </Table.Cell>
              <Table.Cell
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="remove"
                  color="primary"
                  onClick={() => handleOtherRemove(item.id)}
                >
                  <RemoveCircleIcon />
                </IconButton>
                {otherLength - 1 === index ? (
                  <IconButton
                    aria-label="add"
                    color="primary"
                    onClick={handleOtherAdd}
                  >
                    <AddCircleIcon />
                  </IconButton>
                ) : null}
              </Table.Cell>
            </Table.Row>
          ))}
          <Table.Row>
            <Table.Cell
              style={{
                width: "300px",
              }}
            >
              Total Expenses:
            </Table.Cell>
            <Table.Cell>
              <span>{totalExp ? totalExp.toFixed(2) + ' '+currency : ""}</span>
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default BoxTwo;
