import SearchInput from "./SearchInput";
import { useState } from "react";
import { Button } from "semantic-ui-react";

const SearchClient = ({load,setSearchText,setShow,handleSearch,searchText}) => {
 

  const showClients = e => {
   setShow(true)
  }
  return (
    <div style= {{marginTop: '24px'}}>
      <SearchInput load={load} setSearchText={setSearchText} searchText= {searchText} handleSearch= {handleSearch}/>
      <Button style= {{marginTop: '24px',marginBottom:10}} color= 'purple' onClick= {showClients}>View all private clients</Button>
    </div>
  );
};

export default SearchClient;
