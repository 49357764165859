import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import { Button } from "semantic-ui-react";
import Axios from "utils/axios";
import swal from "sweetalert";

const Action = ({
  handlePaid,
  AdminID,
  load,
  billID,
  clientID,
  bank_pay,
  pay,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResendBill = (e) => {
    history.push(`/admin/resend-bill/${billID}/${clientID}`);
  };

  const handlePaidClick = (e) => {
    setAnchorEl(false);
    handlePaid(billID, AdminID);
  };

  const handleSubmit = async (e) => {
    try {
      const response = await Axios.delete(
        `/api/manager-admin/delete-bill/billID/${billID}`
      );
      swal({
        title: "Success",
        text: `Bill successfully deleted!!!`,
        icon: "success",
        buttons: false,
      });
      load();
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleDeleteBill = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleSubmit(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!pay ? (
          <MenuItem onClick={handleResendBill}>Resend Bill</MenuItem>
        ) : null}
        {bank_pay ? (
          <MenuItem onClick={handlePaidClick}>Change payment status</MenuItem>
        ) : null}
        <MenuItem onClick={handleDeleteBill}>Delete Bill</MenuItem>
      </Menu>
    </div>
  );
};

export default Action;
