import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import { COUNTRY_DATA, COUNTRY_DATA_SEM_UI, GENDER_DATA } from "data";
import moment from "moment";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("token");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("work_in_uk", data.work_in_uk || "");
    fd.append(
      "national_insurance_number",
      data.national_insurance_number || ""
    );
    fd.append("ptr_utr_number", data.ptr_utr_number || "");
    fd.append("vat_number", data.vat_number || "");

    try {
      const response = await Axios.put(
        `/api/manager-admin/update-employee/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="work_in_uk"
            onChange={handleChange}
            label="Do you have the right to work in UK?"
            edit={edit.value}
            value={data.work_in_uk}
          />
          <CustomTextInput
            name="national_insurance_number"
            onChange={handleChange}
            label="National Insurance(NI) Number, Social Security Number or equivalent (if available)"
            edit={edit.value}
            value={data.national_insurance_number}
          />
          <CustomTextInput
            name="ptr_utr_number"
            onChange={handleChange}
            label="Personal Tax reference number / (UTR) unique tax reference number(if available)"
            edit={edit.value}
            value={data.ptr_utr_number}
          />
          <CustomTextInput
            name="vat_number"
            onChange={handleChange}
            label="Vat Number"
            edit={edit.value}
            value={data.vat_number}
          />
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
