import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import {isAuth} from '../../../action/manager';

const Private = ({children}) => {
    const history = useHistory();

    useEffect(()=>{
        if(!isAuth()){
            history.push("/admin/login");
        }
        // else if(isAuth().role !== 1){
        //     history.push("/admin/login");
        // }
    }, []);

return <React.Fragment>{children}</React.Fragment>
}

export default Private;