import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Header } from "semantic-ui-react";
import history from "utils/history";
import Axios from "utils/axios";
import Cookies from "js-cookie";

const InvoicePaySuccess = () => {
  const { id } = useParams();
  const [session, setSession] = useState(null);
  const token = Cookies.get("CACtoken");

  const changePaymentStatus = async (session) => {
    try {
      const response = await Axios.put(
        `/api/cashclient/payment/invoiceID/${session.metadata.id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const retreiveSession = async (e) => {
      try {
        await fetch(
          `${process.env.REACT_APP_BACKEND_API}/retreive/cash-client/checkout/session?id=` +
            id,
          {
            method: "GET",
            //   body: JSON.stringify(y),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((response) => response.json())
          .then((session) => {
            setSession(session);
            changePaymentStatus(session);
          });
      } catch (error) {
        console.log(error);
      }
    };

    retreiveSession();
  }, []);
  return session ? (
    <div style={{ backgroundColor: "purple", height: "100%", padding: "30px" }}>
      <Container
        style={{
          backgroundColor: "white",
          color: "purple",
          height: "100%",
          padding: "10px",
          borderRadius: "10px",
        }}
        textAlign="center"
      >
        {/* <Card> */}
        <Header as="h3">
          Your order has been successfully booked. Thank you,{" "}
          {session.line_items.data[0].description}
        </Header>
        <Header as="h3">Payment ID - {session.payment_intent}</Header>
        <Button
          onClick={() => history.push("/cash-client/dashboard")}
          basic
          color="purple"
        >
          Dashboard
        </Button>
        {/* </Card> */}
      </Container>
    </div>
  ) : null;
};

export default InvoicePaySuccess;
