import { FormControlLabel, Input, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { Header, Dropdown } from 'semantic-ui-react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const CustomTextInput = ({label, edit, value, number, select, selectLabel, data, dropdown, ddvalue, svalue, date, dvalue, dlabel, min, radio, rvalue, mobile, pvalue, ...rest}) => {
    return (
        <div style= {{display: 'flex', alignItems: 'center', margin: '24px 0 24px 0'}}>
            <Header style= {{margin: '0'}} size='small'>{label}:&nbsp;</Header>
            {edit ? <Input style= {{width: '40%'}} type = {number ? 'number' : 'text'} {...rest} value= {value}/> 
            : 
            <Header style= {{margin: '0'}} size='small' color= 'grey'>{value}</Header>}
            {select ? (
            <select {...rest} defaultValue= {svalue} className="ui dropdown">
                <option value="">{selectLabel}</option>
                {select.map(dt => (
                    <option value= {dt}>{dt}</option>
                ))}
            </select>
            ) : null}
            {dropdown ? 
            <Dropdown {...rest} placeholder= {label} defaultValue= {ddvalue} search fluid multiple selection options={data} />
            : null}

            {date ? 
            <TextField
            id="date"
            value= {dvalue}
            label= {dlabel}
            {...rest}
            type="date"
            inputProps={{
                min: min
            }}
            InputLabelProps={{
              shrink: true,
            }}
            />
            : null}

            {radio ? 
            <RadioGroup
            aria-label={label.toLowerCase()}
            name={label.toLowerCase()}
            {...rest}
            value= {rvalue}
            style= {{display: 'flex', flexDirection: 'row'}}
            >
            {data.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item}
                control={<Radio />}
                label={item}
                color="primary"
              />
            ))}
            </RadioGroup>
            :
            null}

            {mobile ?
            <PhoneInput
              value= {pvalue}
              placeholder= {label}
              {...rest}
            /> 
            :
            null}
        </div>
    )
}

export default CustomTextInput
