import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CIC from "components/Admin/ControlPanel/CompanyCard/CIC/CIC";
import Booking from "components/Admin/ControlPanel/CompanyCard/CIC/Booking";
import CRM from "components/Admin/ControlPanel/CompanyCard/CIC/CRM";
import Sales from "components/Admin/ControlPanel/CompanyCard/CIC/Sales";
import HR from "components/Admin/ControlPanel/CompanyCard/CIC/HR";
import Account from "components/Admin/ControlPanel/CompanyCard/CIC/Account";
import Compliance from "components/Admin/ControlPanel/CompanyCard/CIC/Compliance";
import Report from "components/Admin/ControlPanel/CompanyCard/CIC/Report";
import Charge from "components/Admin/ControlPanel/CompanyCard/CIC/Charge";






const ViewContactCard = () => {
  const classes = useDashboardStyles();
  return (
    <Manager>
    <MasterLayout>
    <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            margin: "auto",
          }}
        >
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Company Information</p>
            </CardHeader>
            <CardBody>
             <CIC/>
            
            </CardBody>
          </Card>
        </GridItem>
        </GridContainer>
        <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            margin: "auto",
          }}
        >
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Booking Secretary</p>
            </CardHeader>
            <CardBody>
             
             <Booking/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            margin: "auto",
          }}
        >
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>CRM / Admin / Customer service team</p>
            </CardHeader>
            <CardBody>
             
             <CRM/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            margin: "auto",
          }}
        >
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Sales And Marketing Team</p>
            </CardHeader>
            <CardBody>
             
             <Sales/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            margin: "auto",
          }}
        >
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>HR Team</p>
            </CardHeader>
            <CardBody>
             
             <HR/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            margin: "auto",
          }}
        >
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Account, Finance And Invoicing Team</p>
            </CardHeader>
            <CardBody>
             
             <Account/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            margin: "auto",
          }}
        >
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Compliance Team</p>
            </CardHeader>
            <CardBody>
             
             <Compliance/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            margin: "auto",
          }}
        >
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}> System Development</p>
            </CardHeader>
            <CardBody>
             
             <Report/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            margin: "auto",
          }}
        >
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}> Service Charge</p>
            </CardHeader>
            <CardBody>
             
             <Charge/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      
    </MasterLayout>
    </Manager>
  );
};

export default ViewContactCard;
