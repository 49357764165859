import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Material Components
import Typography from "@material-ui/core/Typography";

// Custom Components
import CustomButton from "components/Common/CustomButton";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { updateCrStep, crSubmit } from "redux/actions/crAction";

const FormThree = () => {
  const { data, loading } = useSelector((state) => state.crReducer);
  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleBack = () => {
    dispatch(updateCrStep(2));
  };

  const handleSubmit = () => {
    dispatch(crSubmit(data));
  };
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.tc}>
        <Typography variant="body1">
          By submitting this form I am accepting the privacy and confidentiality
          policy of Linguists Collective{" "}
        </Typography>
      </div>

      <div className={classes.buttonFlex}>
        <CustomButton
          text="Back"
          variant="outlined"
          color="primary"
          onClick={handleBack}
        />
        <CustomButton
          text={`${loading ? "Submitting..." : "Submit"}`}
          onClick={handleSubmit}
          disabled={loading}
        />
      </div>
    </form>
  );
};

export default FormThree;
