import React from "react";
import { Link } from "react-router-dom";

// Material Components
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Divider from "@material-ui/core/Divider";

// Custom Components
import CustomDropdown from "components/Common/CustomDropdown";

// Others
import useDropdownStyles from "assets/jss/components/dropDownStyles";

const Settings = () => {
  const classes = useDropdownStyles();
  return (
    <CustomDropdown label="management-settings">
      <MenuList role="menu">
        <Link to="/notice" className={classes.linkText}>
          <MenuItem className={classes.dropdownItem}>Notice</MenuItem>
        </Link>
        <Link to="/meetings" className={classes.linkText}>
          <MenuItem className={classes.dropdownItem}>Meetings</MenuItem>
        </Link>
        <MenuItem className={classes.dropdownItem}>Project Task</MenuItem>
        <MenuItem className={classes.dropdownItem}>Reports</MenuItem>
        <Divider />
        <MenuItem className={classes.dropdownItem}>Backup</MenuItem>
      </MenuList>
    </CustomDropdown>
  );
};

export default Settings;
