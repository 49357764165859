import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
// Custom Components
import ActionINC from "./ActionINC";
import Cookies from "js-cookie";
import Axios from "axios";
import history from "utils/history";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Button, Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import CustomModal from "components/Common/CustomModal";
import ViewAll from "../../AssignmentHistory/ViewAll";
import SearchView from "./InterpretingSearch/SearchView";
import { useToasts } from "react-toast-notifications";

const TableThree = ({ interpretingData }) => {
  const classes = useDashboardStyles();
  const comparisonDate = moment(new Date()).format("MM/DD/YYYY");
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { addToast } = useToasts();
  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });
  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-inhouse-closed-booking/${searchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (searchText == undefined || searchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });

        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  const { bookingList, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-closed-booking-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues({
        ...values,
        bookingList: interpretingData
          ? response?.data.filter((item) => item?.id === interpretingData)
          : response?.data,
        reload: false,
      });
      interpretingData && setShow(true);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  const handleViewAll = async (e, list) => {
    e.preventDefault();
    setViewAll({ ...viewAll, open: true, list: list });
  };

  return (
    <div>
      {!interpretingData && (
        <SearchView
          show={show}
          load={loadbookingList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          searchText={searchText}
          setShow={setShow}
        />
      )}
      <Paper className={classes.root}>
        <CustomModal
          open={viewAll.open}
          handleClose={() => setViewAll({ ...viewAll, open: false })}
        >
          <ViewAll data={viewAll.list} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              color="purple"
              onClick={() => setViewAll({ ...viewAll, open: false })}
            >
              Close
            </Button>
          </div>
        </CustomModal>
        {/* <SearchView setShow={setShow}/> */}
        {show ? (
          <div>
            {" "}
            <TableContainer className={classes.container}>
              <Table color="pink" stickyHeader aria-label="sticky table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="1">Assignment ID</Table.HeaderCell>
                    <Table.HeaderCell width="1">Timezone</Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Start date & time
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Duration</Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Source language
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Target language
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Target language skill
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Service Required
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Status</Table.HeaderCell>
                    <Table.HeaderCell width="1">Feedback</Table.HeaderCell>
                    <Table.HeaderCell width="1">Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {bookingList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .filter((list) => list)
                    .map((list) => (
                      <Table.Row
                        style={
                          list?.timesheetID?.signatureByINCTrue ||
                            moment() >
                            moment(list?.timesheetID?.autoAcceptDate) ||
                            moment() ===
                            moment(list?.timesheetID?.autoAcceptDate)
                            ? { backgroundColor: "lightgreen" }
                            : { backgroundColor: "yellow" }
                        }
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={list._id}
                      >
                        <Table.Cell key={list._id} align={list.align}>
                          {list.id}
                        </Table.Cell>
                        <Table.Cell>{list?.timezone}</Table.Cell>
                        <Table.Cell>
                          {moment(list?.startdateArray[0]).format(
                            "DD-MMM-YYYY"
                          )}{" "}
                          - {list?.starttimeArray[0]}
                        </Table.Cell>
                        <Table.Cell>{list.duration}</Table.Cell>
                        <Table.Cell>{list?.source_language}</Table.Cell>
                        <Table.Cell>{list?.target_language}</Table.Cell>
                        <Table.Cell>{list?.target_language_level}</Table.Cell>
                        <Table.Cell>
                          {list?.typesof_interpreting_required}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.timesheetID?.dispute
                            ? "Timesheet disputed"
                            : list?.timesheetID?.signatureByINCTrue &&
                              list?.timesheetID?.signatureByLINTrue
                              ? "Timesheet accepted by both"
                              : list?.timesheetID?.signatureByINCTrue
                                ? "Timesheet created by client"
                                : list?.timesheetID?.signatureByLINTrue
                                  ? "Timesheet created by linguist"
                                  : "Timesheet not confirmed"}
                        </Table.Cell>
                        <Table.Cell>{list?.timesheetID?.feedback}</Table.Cell>
                        <Table.Cell>
                          <ActionINC
                            cacTimesheet={list?.timesheetID?.signatureByINCTrue}
                            linSignature={list?.timesheetID?.signatureByLINTrue}
                            date={
                              moment() >
                              moment(list?.timesheetID?.autoAcceptDate) ||
                              moment() ===
                              moment(list?.timesheetID?.autoAcceptDate)
                            }
                            accepted={list?.timesheetID?.signatureByINCTrue}
                            id={list?.timesheetID?._id}
                            dispute={list?.timesheetID?.dispute}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
};

export default TableThree;
