import Axios from 'axios'
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import history from "utils/history"
import swal from "sweetalert";

const EmailActivationSPPO = () => {

    const { token } = useParams()

    const preSignup = async (e) => {
        try {
            const response = await Axios.post(`${process.env.REACT_APP_BACKEND_API}/sppo/signup`, {
                token: token
            })
            swal({
                title: "Success",
                text: `${response?.data.message}`,
                icon: "success",
                buttons: false,
            });

            setTimeout(() => {
                history.push("/sppo/login");
            }, 2000);
         } catch (error) {
            if (error.response) {
                swal({
                    title: "Oops!",
                    text: `${error?.response?.data?.error}`,
                    icon: "error",
                    buttons: false,
                })
                history.push("/sppo/login");
            }
        }
    }
    useEffect(() => {
        preSignup();
    }, [])
    return (
        <div>
            
        </div>
    )
}

export default EmailActivationSPPO