import React, {  } from "react";
import Button from "@material-ui/core/Button";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import BusinessIcon from "@material-ui/icons/Business";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import history from "utils/history"

const ULOptions = ({ id }) => {
  const classes = useLandingpageStyles();

  return (
    <div style={{ display: "grid", gridTemplateColumns: "auto auto " }}>
    
      <Button
        startIcon={<PermIdentityIcon />}
        className={`${classes.button}`}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/admin/lead-passport-document-view/${id}`)
        }
      >
        Preview Passport Document
      </Button>
      <Button
        startIcon={<BusinessIcon />}
        className={`${classes.button}`}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/admin/lead-quote-enquiry-documents/${id}`)}
      >
        Quote Enquiry Documents
      </Button>
    
    </div>
  );
};

export default ULOptions;
