import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import SendForm from "components/Admin/SendCACBACForm/SendCACBACForm";

const CashClient = () => {

  const classes = useDashboardStyles();
  
  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>

                <p className={classes.cardTitleWhite}>Send CAC or BAC registration form</p>
                <IconButton
                  onClick={() => history.goBack()}
                  color="inherit"
                >
                  <ArrowBackIcon />
                </IconButton>
              </CardHeader>
              <CardBody>
                <SendForm />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default CashClient;
