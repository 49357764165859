import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import CustomSelect from "components/Common/CustomSelect";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomCaptcha from "components/Common/CustomCaptcha";

import defaultAxios from "utils/axios";
import swal from "sweetalert";
import Cookies from "js-cookie";

const FormOne = () => {
  const classes = useFormStyles();

  const [data, setData] = useState({
    position: "",
  });

  const { position } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const isAllow = !data.position;

  const handleSubmit = async (e) => {
    const token = Cookies.get("token");

    const sendData = {
      position: data.position || "-",
    };
    try {
      const response = await defaultAxios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/add-position`,
        sendData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: "Position successfully added",
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/admin/add-position";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInput
        onChange={handleChange}
        label="Position Title"
        name="position"
        value={data.position}
        width="100%"
      />

      <div className={classes.buttonFlex}>
        <CustomButton disabled={isAllow} text="Send" onClick={handleSubmit} />
      </div>
    </form>
  );
};

export default FormOne;
