import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import Action from "./Action";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ListCACAssignments({ text }) {
  const token = Cookies.get("token");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    UnconfirmBookingList: [],
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });

  const [quoteValue, setQuotesValue] = useState({
    UnconfirmQuoteList: [],
  });

  const {
    name,
    error,
    success,
    UnconfirmBookingList,
    removed,
    reload,
    loader,
  } = values;

  useEffect(() => {
    loadEventMembers();
  }, [reload]);

  const loadEventMembers = async (e) => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/get-events-list-without-photo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((unconfirmedJobs) => {
        setValues({ ...values, UnconfirmBookingList: unconfirmedJobs.data });
      })
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Event name</TableCell>
                <TableCell>Event date and time</TableCell>
                <TableCell>Timezone</TableCell>
                <TableCell>venue_address</TableCell>
                <TableCell>Event for</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {UnconfirmBookingList.length > 0 ? (
              <TableBody>
                {UnconfirmBookingList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((list, i) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    <TableCell>{list.subject}</TableCell>
                    <TableCell>
                      {moment(list.events_date).format("DD-MMM-YYYY : HH:mm")}
                    </TableCell>
                    <TableCell>{list.timezone}</TableCell>
                    <TableCell>{list.venue_address}</TableCell>
                    <TableCell>
                      {list?.CAC_check == "yes" ? "CAC " : null}
                      {list?.BAC_check == "yes" ? "BAC " : null}
                      {list?.LIN_check == "yes" ? "LIN " : null}
                      {list?.EMP_check == "yes" ? "EMP " : null}
                      {list?.SPPO_check == "yes" ? "SPPO " : null}
                      {list?.INDIVIDUAL_check == "yes" ? "INDIVIDUAL " : null}
                      {list?.PUBLIC_check == "yes" ? "PUBLIC" : null}
                    </TableCell>
                    <TableCell>
                      <Action id={list._id} load={loadEventMembers} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={UnconfirmBookingList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
