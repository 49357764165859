import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const useCarpCardStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 0",

    "& h3": {
      fontSize: "24px",
      margin: "0",
    },

    "& li": {
      fontSize: "16px",
    },

    "& input": {
      width: "6% !important",
    },
  },
}));

export const SubDetailsDiv = styled.div`
  .icon-div {
    text-align: right;
  }
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
  textarea {
    width: 100%;
    font-size: 1.4rem;
  }
`;

export default useCarpCardStyles;
