import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomCaptcha from "components/Common/CustomCaptcha";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import Cookies from "js-cookie";
// Other
import { Checkbox } from "@material-ui/core";
import { CURRENT_COUNTRY, EMAIL_REGEX } from "data";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import {
  changeInhouseClientInput,
  updateInhouseClientStep,
} from "redux/actions/icrAction";
import Axios from "axios";
import swal from "sweetalert";
import { Button } from "semantic-ui-react";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import CustomPhone from "components/Common/CustomPhone";

const FormTwo = ({ id, userID }) => {
  const { data } = useSelector((state) => state.icrReducer);
  const [loader, setLoader] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [notValid, setNotValid] = useState(false);

  const {
    business_name,
    position,
    business_email,
    business_phone_no,
    business_address,
    business_address_2,
    business_town,
    business_zipcode,
    business_region,
    business_country,
    charity_registration_no,
    company_registration_no,
    business_website,
    note,
    gdpr,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: value || checked,
    };
    dispatch(changeInhouseClientInput(inputData));
  };

  const handleChangeCO = (e, value) => {
    dispatch(
      changeInhouseClientInput({
        name: "business_country",
        value,
      })
    );
  };

  const handleNext = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      Cookies.set("data", data);
      localStorage.setItem("data", JSON.stringify(data));
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/inhouse/pre-signup`,
        { ...data, postedBySPPO: id, userID: userID }
      );
      setLoader(false);
      swal({
        title: "Success!",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(() => {
        window.location = "/";
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
      setLoader(false);
    }
  };

  const handleBack = () => {
    dispatch(updateInhouseClientStep(1));
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    dispatch(
      changeInhouseClientInput({
        name,
        value: !gdpr,
      })
    );
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeInhouseClientInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleMobile = (value) => {
    dispatch(
      changeInhouseClientInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInput
        onChange={handleChange}
        value={business_name}
        name="business_name"
        label="Business or organisation name"
        width="100%"
        placeholder="Trading name of your business / organisation. Otherwise please write N/A"
      />
      <CustomInput
        onChange={handleChange}
        value={position}
        name="position"
        label="Your position"
        width="100%"
        placeholder="e.g. Manager, Director etc"
      />
      <CustomBigInputSingle
        onChange={handleEmailChange}
        value={business_email}
        name="business_email"
        label="Business email address"
        moreInfo="If different"
        width="100%"
        placeholder="e.g. example@example.com"
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <br />
      <CustomPhone
        label="Business phone number"
        onChange={handleMobile}
        placeholder="Business Phone Number (Please select country then type number e.g. +44 7307 000000)"
        value={business_phone_no}
        name="business_phone_no"
      />
      {/* *Developer allow select same as before or add new with 5 fields including post code and country */}
      <CustomBigInput
        onChange={handleChange}
        label="House number/name, Street name"
        moreInfo="main contact address (if different)"
        width="100%"
        name="business_address"
        value={business_address}
        placeholder="e.g. Flat/Building Name, 101 Example Street"
      />
      <CustomInput
        onChange={handleChange}
        label="Address line 2"
        width="100%"
        name="business_address_2"
        value={business_address_2}
        placeholder="Additional Address Information"
      />
      <CustomInput
        onChange={handleChange}
        label="Town / City"
        width="100%"
        name="business_town"
        value={business_town}
        placeholder="Your Village, Town or City Name"
      />

      <CustomInput
        onChange={handleChange}
        label="County / Region"
        width="100%"
        name="business_region"
        value={business_region}
        placeholder="Your County, Division or State Name"
      />
      <CustomInput
        onChange={handleChange}
        label="Post / Zip Code"
        width="100%"
        name="business_zipcode"
        value={business_zipcode}
        placeholder="Your Post Code, Zip Code or Post Office Name"
      />
      <CustomAutoSelect
        onChange={handleChangeCO}
        label="Country"
        width="100%"
        name="business_country"
        value={business_country}
        data={CURRENT_COUNTRY}
      />
      <CustomBigInput
        onChange={handleChange}
        label="Company registration number"
        moreInfo="If registered"
        width="100%"
        name="company_registration_no"
        value={company_registration_no}
        placeholder="if registered please provide CRN"
      />
      <CustomBigInput
        onChange={handleChange}
        label="Charity registration number"
        moreInfo="If registered"
        width="100%"
        name="charity_registration_no"
        value={charity_registration_no}
        placeholder="if registered please provide Charity Number and Registered Country"
      />
      <CustomInput
        onChange={handleChange}
        value={business_website}
        name="business_website"
        label="Business website"
        width="100%"
        placeholder="e.g. www.YourCompanyWebsite.com"
      />
      <CustomInput
        onChange={handleChange}
        value={note}
        name="note"
        label="Additional information or note"
        width="100%"
        placeholder="Enquery Details..."
      />

      <div
        className={classes.tc}
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <p>
          <Checkbox
            onChange={handleCheckbox}
            checked={gdpr}
            name="gdpr"
            color="primary"
          />
          By submitting this form I agree with Linguists Collective
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/privacy-policy"
          >
            Privacy Policy{" "}
          </a>
          and{" "}
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/terms-and-conditions"
          >
            {" "}
            T&C
          </a>
        </p>

        <CustomCaptcha onChange={handleCaptcha} />
      </div>

      <div className={classes.buttonFlex} style={{ marginTop: "24px" }}>
        <Button color="purple" basic onClick={handleBack}>
          Back
        </Button>
        <Button
          loading={loader}
          color="purple"
          disabled={!gdpr || !captcha}
          onClick={handleNext}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default FormTwo;
