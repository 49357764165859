import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import CashClientLayout from "layout/CashClientLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Report from "components/CashClient/Report/Report";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const ReportPage = () => {
  const classes = useDashboardStyles();

  return (
    <CashClientLayout>
      <Cashclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className={classes.cardTitleWhite}>Managment Report</p>
                <IconButton color='inherit' style={{ marginLeft: 5 }} onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>

              </CardHeader>
              <CardBody>
                <Report />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default ReportPage;
