import { List } from "semantic-ui-react";
import { useState } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import { CommonDiv } from "./style";
import ViewOneJob from "../ViewOneJob";

const PostJobShortList = ({ data }) => {
  const [jobId, setJobId] = useState();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4} lg={4}>
        <Card>
          <CardBody>
            <CommonDiv>
              <List>
                {data.map((item) => (
                  <List.Item
                    className={item._id === jobId ? "active" : ""}
                    key={item._id}
                    onClick={() => setJobId(item._id)}
                  >
                    {item.jobtitle}, {item.salary}, {item.targetlanguage},{" "}
                    {item.sourcelanguage}
                  </List.Item>
                ))}
              </List>
            </CommonDiv>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} lg={8}>
        {jobId ? (
          <Card>
            <CardBody>
              <CommonDiv>
                <ViewOneJob id={jobId} />
              </CommonDiv>
            </CardBody>
          </Card>
        ) : null}
      </GridItem>
    </GridContainer>
  );
};

export default PostJobShortList;
