import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInput from "components/Common/CustomInput";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import { changeCAOInput, updateCAOStep } from "redux/actions/caoAction";
import { LANG_COMB, QUOTE_REQUEST_FOR } from "data";
import CustomAutoComplete from "components/Common/CustomAutoComplete";

const PageThree = () => {
  const { data } = useSelector((state) => state.caoReducer);
  const dispatch = useDispatch();

  const {
    anticipated_spending_per_month,
    estimated_booking_volume_per_month,
    anticipated_languages_required,
    anticipated_services_required,
    provide_additional_info,
  } = data;
  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateCAOStep(4));
  };

  const handleBack = () => {
    dispatch(updateCAOStep(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value,
      })
    );
  };

  const handleAutoCompleteAL = (e, value) => {
    dispatch(
      changeCAOInput({
        name: "anticipated_languages_required",
        value,
      })
    );
  };

  const handleAutoCompleteAS = (e, value) => {
    dispatch(
      changeCAOInput({
        name: "anticipated_services_required",
        value,
      })
    );
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInput
          onChange={handleChange}
          width="100%"
          label="Anticipated spending per month (GBP)"
          name="anticipated_spending_per_month"
          value={anticipated_spending_per_month}
        />

        <CustomInput
          onChange={handleChange}
          label="Estimated booking volume per month"
          width="100%"
          name="estimated_booking_volume_per_month"
          value={estimated_booking_volume_per_month}
        />
        <CustomAutoComplete
          onChange={handleAutoCompleteAL}
          label="Anticipated languages required"
          width="100%"
          name="anticipated_languages_required"
          value={anticipated_languages_required}
          data={LANG_COMB}
        />
        <CustomAutoComplete
          onChange={handleAutoCompleteAS}
          label="Anticipated services will be required"
          width="100%"
          name="anticipated_services_required"
          value={anticipated_services_required}
          data={QUOTE_REQUEST_FOR}
        />
        <CustomInput
          onChange={handleChange}
          label="Additional information"
          width="100%"
          name="provide_additional_info"
          value={provide_additional_info}
        />

        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton text="Next" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default PageThree;
