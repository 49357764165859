import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const styles = {
  customDiv: {
    "& .MuiTypography-root": {
      color: "#000 !important",
    },
  },
};

const useStyles = makeStyles(styles);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    "& .MuiTypography-root": {
      color: "#000 !important",
    },
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#952DAF",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#952DAF",
      border: "6px solid #000",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const ToggleButton = () => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    toggle: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleLogOut = async (e) => {
    if (
      history.location.pathname.includes("cash-client") ||
      history.location.pathname.includes("cashclient")
    ) {
      const response = await Axios.get("/api/cashclient/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("CACtoken")}`,
        },
      });
      Cookies.remove("CACtoken");
      Cookies.set("isAuth", false);
      history.push("/cash-client-login");
    } else if (history.location.pathname.includes("account-client")) {
      const response = await Axios.get("/api/accountclient/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("BACtoken")}`,
        },
      });
      Cookies.remove("BACtoken");
      history.push("/account-client-login");
    } else if (history.location.pathname.includes("admin")) {
      const response = await Axios.get("/api/manager-admin/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      Cookies.remove("token");
      history.push("/admin/login");
    } else if (history.location.pathname.includes("linguist")) {
      const response = await Axios.get("/api/linguists/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("LINtoken")}`,
        },
      });
      Cookies.remove("LINtoken");
      history.push("/linguists-login");
    } else if (history.location.pathname.includes("employee")) {
      const response = await Axios.get("/api/employee/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("EMPtoken")}`,
        },
      });
      Cookies.remove("EMPtoken");
      history.push("/employee-login");
    } else if (history.location.pathname.includes("sppo")) {
      const response = await Axios.get("/api/sppo/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("SPPOtoken")}`,
        },
      });
      Cookies.remove("SPPOtoken");
      history.push("/sppo/login");
    }
  };

  return (
    <div className={classes.customDiv}>
      {/* <label>Short Break</label> */}
      <FormControlLabel
        control={
          <IOSSwitch
            checked={state.toggle}
            onChange={handleChange}
            name="toggle"
            onClick={handleLogOut}
          />
        }
        label="Short Break"
        className={classes.customDiv}
      />
    </div>
  );
};

export default ToggleButton;
