import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import swal from "sweetalert";
//Axios
import Axios from "axios";

//Cookies
import Cookies from "js-cookie";

const Action = ({ id, load, date, cancel }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteQuote = async (value) => {
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/linguists/delete-lin-quote/linQuoteID/${id}`
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure you want to delete this?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          deleteQuote(value);

        default:
          break;
      }
    });
  };

  const handleSee = (e) => {
    history.push(`/linguist/provided-quote/view/${id}`);
  };


  const cancelQuote = async (value) => {
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/linguists/cancel-lin-quote/linQuoteID/${id}`
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };


  const handleCancel = async (value) => {
    swal("Are you sure you want to cancel this quote?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          cancelQuote(value);

        default:
          break;
      }
    });
  };


  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          minWidth: "10px",
          paddingLeft: "0px",
        }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSee}>View Quotation</MenuItem>
        {!cancel ?
          <MenuItem onClick={handleCancel}>Cancel Quotation</MenuItem>
          : null}
        {date || cancel? <MenuItem onClick={handleDelete}>Delete</MenuItem> : null}
      </Menu>
    </div>
  );
};

export default Action;
