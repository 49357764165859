import React, { Fragment } from "react";

import CustomerRegistration from "components/Auth/CustomerRegistration/CustomerRegistration";

const CustomerRegistrationPage = () => {
  return (
    <Fragment>
      <CustomerRegistration />
    </Fragment>
  );
};

export default CustomerRegistrationPage;
