import React from "react";

// Material Components
import Button from "@material-ui/core/Button";

// Custom Components
import CustomFormControl from "./CustomFormControl";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

const CustomButton = ({
  text,
  variant,
  color,
  onClick,
  disabled,
  width,
  ...rest
}) => {
  const classes = useFormStyles();

  return (
    <CustomFormControl
      width={width || "15%"}
      style={{
        marginRight: "15px",
      }}
    >
      <Button
        
        variant={`${variant || "contained"}`}
        color={`${color || "primary"}`}
        className={classes.button}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {text}
      </Button>
    </CustomFormControl>
  );
};

export default CustomButton;
