import { useHistory } from "react-router-dom";
import React from "react";
// Materials Core
import { Button } from 'semantic-ui-react'
import { makeStyles } from "@material-ui/core/styles";



const styles = {
  root: {
    display: "flex",
    alignItems: "center",

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const InvoiceOptions = () => {

  const [open, setOpen] = React.useState(false)
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Button color="purple"
        variant="outlined"
        onClick={() => history.push("/sppo/invoiced-list")}
      >
        Invoiced
      </Button>
      <Button color="purple"
        variant="outlined"
        onClick={() => history.push("/sppo/received-invoice-list")}
      >
        Received Invoice
      </Button>
     
    </div>
  );
};

export default InvoiceOptions;
