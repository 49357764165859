import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "utils/history";

import LoadingScreen from "react-loading-screen";
// Custom Components
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";
import CustomInput from "components/Common/CustomInput";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomUpload from "components/Common/CustomUpload";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import Cookies from "js-cookie";
import moment from "moment-timezone";

// Others
import {
  CURRENT_COUNTRY,
  GENDER_DATA,
  SECURITY_CLEARANCE_LEVEL,
  PAYMENT_QUOTEREQUEST,
  PRIMARY_SOURCE_LANG,
  PRIMARY_TARGET_LANG,
  QUOTE_REQUEST_FOR,
  SKILL_LEVEL_REQUIRED_X,
  TIMEZONE,
  RARE_REGEX,
  LEVEL_REGEX
} from "data";

// import { changeQuoteInput } from "redux/actions/qrtAction";
import { changeQuoteInput } from "redux/actions/quoterqstAction";
import CustomSelect from "components/Common/CustomSelect";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Icon,
} from "@material-ui/core";
import CustomRadio from "components/Common/CustomRadio";
import CustomDateTime from "components/Common/CustomDateTime";
import CustomAutoSelect from "components/Common/CustomAutoSelect";

import swal from "sweetalert";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import Axios from "axios";

const PageOne = ({ adminID, adminName }) => {
  const { data } = useSelector((state) => state.ACquoterqstReducer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    select_client,
    purchase_order_number,
    quote_rqst_for,
    house_street_no_name,
    address_line_two,
    town_city,
    post_code,
    county_region,
    country,
    other_details,
    request_details,
    project_fix_budget,
    upload_cv,
    source_language,
    target_language,
    source_skills_required,
    target_skills_required,
    provider_id_number,
    security_clearance,
    gender,
    timezone,
    quote_by,
    pay_type,
    agree,
  } = data;

  // const { username } = JSON.parse(localStorage.getItem("user"));
  // console.log(username);

  const classes = useFormStyles();

  const handleNext = () => {
    swal(
      `
      Have you reconfirmed all details with client? Read the T&C? Note that you will not be able to return to this page, after submission you will be directed to summary page. Are you ready to submit?`,
      {
        icon: "warning",
        buttons: ["Cancel", "Yes"],
      }
    ).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        // let fd = new FormData()
        // fd.append("upload_cv", upload_cv)

        let fd = new FormData();

        fd.append("select_client", select_client);
        fd.append("purchase_order_number", purchase_order_number);
        fd.append("quote_rqst_for", quote_rqst_for);
        fd.append("house_street_no_name", house_street_no_name);
        fd.append("address_line_two", address_line_two);
        fd.append("town_city", town_city);
        fd.append("post_code", post_code);
        fd.append("county_region", county_region);
        fd.append("country", country);
        fd.append("other_details", other_details);
        fd.append("request_details", request_details);
        fd.append("project_fix_budget", project_fix_budget);
        fd.append("upload_cv", upload_cv);
        fd.append("source_language", source_language);
        fd.append("target_language", target_language);
        fd.append("source_skills_required", source_skills_required);
        fd.append("target_skills_required", target_skills_required);
        fd.append("provider_id_number", provider_id_number);
        fd.append("security_clearance", security_clearance);
        fd.append("gender", gender);
        fd.append("timezone", timezone);
        fd.append("quote_by", quote_by);
        fd.append("pay_type", pay_type);
        fd.append("agree", agree);
        // fd.append("username", username);
        try {
          const response = Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/admin/request/quote`,
            fd
          );
          setLoading(false);
          swal("Thank you for telephonic quote request ", {
            icon: "success",
            buttons: "OK!!",
          }).then((response) => {
            history.push("telephonic-quote-summary");
          });
        } catch (error) {
          if (error.response) {
            swal({
              title: "Oops!",
              text: `${error?.response?.data?.error}`,
              icon: "error",
              buttons: false,
            });
          }
        }
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeQuoteInput({
        name,
        value: value,
      })
    );
  };
  const handleChecked = (e) => {
    const { name, checked } = e.target;
    dispatch(
      changeQuoteInput({
        name,
        value: checked,
      })
    );
  };

  const handleExpertise = (value) => {
    if (target_language?.includes("R") || source_language?.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(
        LEVEL_REGEX
       );
    }
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "quote_rqst_for",
        value,
      })
    );
  };

  const handleFiles = (e) => {
    const value = e.target.files[0];
    if (value.size >= 5000000) {
      swal({
        title: "Kindly select less than 5 mb file",
      });
    } else {
      dispatch(
        changeQuoteInput({
          name: "upload_cv",
          value,
        })
      );
    }
  };

  const handleDateChange = (date) => {
    dispatch(
      changeQuoteInput({
        name: "quote_by",
        value: date.target.value,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "country",
        value,
      })
    );
  };
  const handleChangePT = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "pay_type",
        value,
      })
    );
  };
  const handleSecurityChange = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "security_clearance",
        value,
      })
    );
  };
  const handleChangeS = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "source_language",
        value,
      })
    );
  };
  const handleChangeCL = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "select_client",
        value,
      })
    );
  };
  const handleChangeT = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "target_language",
        value,
      })
    );
  };

  const classesb = useLandingpageStyles();
  const [clientID, setClientID] = useState("");

  const handleChangeCC = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeQuoteInput({
        name,
        value: value,
      })
    );
  };

  const token = Cookies.get("token");

  const handleNextX = async () => {
    let fd = new FormData();
    fd.append("select_client", select_client);

    const response = await Axios.post(
      `${process.env.REACT_APP_BACKEND_API}/maneger/clientSearch`,
      fd
    );
    try {
      let nclientID =
        response?.data.id +
        " - " +
        response?.data.LastName +
        " - " +
        response?.data.PostalCode +
        " - " +
        response?.data.mobile_no;
      setClientID(nclientID);
      // Cookies.set("clientID", clientID)
    } catch (error) {
      swal({
        title: "OPPS !!!!",
        text: `Kindly provide correct ID number`,
        icon: "error",
        buttons: false,
      });
    }
  };
  const isAlloW = !select_client;

  const isAllow =
    !quote_rqst_for.length ||
    !request_details ||
    !project_fix_budget ||
    !source_language ||
    !target_language ||
    !target_skills_required ||
    !source_skills_required ||
    !quote_by ||
    !agree;

  return (
    <div>
      {loading === false ? (
        <div>
          {/* {clientID.length > 0 ? ( */}
          <form className={classes.form} noValidate autoComplete="off">
            <div>
              <form className={classes.form} noValidate autoComplete="off">
                <CustomInputSingle
                  onChange={handleChangeCC}
                  width="100%"
                  label="Enter client ID"
                  name="select_client"
                  value={select_client}
                  placeholder="Enter BAC ID (e.g. - BAC0000)"
                />
                <div
                  className={classes.buttonFlex}
                  style={{
                    display: "flex",
                    marginTop: "24px",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    className={`${classesb.button}`}
                    disabled={isAlloW}
                    style={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={handleNextX}
                  >
                    Find client
                  </Button>
                </div>
              </form>
              {clientID.length > 0 ? (
                <div
                  style={{
                    margin: "0",
                    marginTop: "15px",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  <AppBar position="static">
                    <Toolbar>
                      <h3>{clientID}</h3>
                    </Toolbar>
                  </AppBar>
                </div>
              ) : (
                <div
                  style={{
                    margin: "0",
                    marginTop: "15px",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  <AppBar position="static">
                    <Toolbar>
                      <h3>
                        Find client with ID and ask client to confirm BAC
                        Surname, Post/Zip Code and Phone number for security.
                      </h3>
                    </Toolbar>
                  </AppBar>
                </div>
              )}
            </div>

            <CustomInputSingle
              onChange={handleChange}
              width="100%"
              label="Your booking ref/ Purchase order no"
              name="purchase_order_number"
              value={purchase_order_number}
            />

            <CustomAutoComplete
              onChange={handleAutoComplete}
              label="Quote Request for"
              name="quote_rqst_for"
              data={QUOTE_REQUEST_FOR}
              value={quote_rqst_for}
              placeholder="Choose"
              required
            />
            <CustomBigInputSingle
              onChange={handleChange}
              label="House Number/Name, Street Name"
              moreInfo="Venue address"
              width="100%"
              name="house_street_no_name"
              value={house_street_no_name}
              placeholder="e.g. Flat/Building Name, 101 Example Street"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Address line 2"
              width="100%"
              name="address_line_two"
              value={address_line_two}
              placeholder="Additional Address Information"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Town / City"
              width="100%"
              name="town_city"
              value={town_city}
              placeholder="Your Village, Town or City Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Post / Zip Code"
              width="100%"
              name="post_code"
              value={post_code}
              placeholder="Your Post Code, Zip Code or Post Office Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="County / Region"
              width="100%"
              name="county_region"
              value={county_region}
              placeholder="Your County, Division or State Name"
            />
            <CustomAutoSelect
              onChange={handleAutoCompleteCO}
              label="Country"
              moreInfo="Venue country"
              width="100%"
              name="country"
              value={country}
              data={CURRENT_COUNTRY}
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Other / Remote or Telephone interpreting details"
              name="other_details"
              value={other_details}
              placeholder="How linguist will join you? e.g. Zoom, Microsoft Team link, conference phone number etc."
            />

            <CustomInput
              onChange={handleChange}
              label="Details of your request"
              name="request_details"
              value={request_details}
              required
              placeholder=" Provide details for interpreter e.g. solicitor conference, police interview, visiting GP etc."
            />
            <CustomBigInputSingle
              onChange={handleChange}
              label="Do you have a fixed budget for this assignment / project?"
              moreInfo="If so, please provide details here including currency"
              name="project_fix_budget"
              value={project_fix_budget}
              required
              placeholder="Provide details here including currency"
            />

            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <CustomUpload
                onChange={handleFiles}
                accept=".pdf,.docx"
                name="upload_cv"
                width="100%"
                label="Upload any script or instruction here, pdf or photos/JPEG only)"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button color="secondary">
                {upload_cv ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Icon name="file pdf" size="big" />
                    <p
                      style={{
                        margin: "0",
                        marginTop: "7px",
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                      }}
                    >
                      {upload_cv.name}
                    </p>
                    <p>{upload_cv?.lastModifiedDate?.toDateString()} </p>
                    <p style={{ margin: "0" }}>
                      {(upload_cv.size / 1024).toFixed(2)}Kb{" "}
                    </p>
                  </div>
                ) : null}
              </Button>
            </div>

            <CustomAutoSelect
              onChange={handleChangeS}
              label="Source/Native language"
              data={PRIMARY_SOURCE_LANG}
              width="100%"
              name="source_language"
              value={source_language}
              required
            />
            <CustomSelect
              onChange={handleChange}
              label="Please indicate linguist's source language skills level required"
              moreInfo="Please indicate linguist's source language skills level required for this assignment (1 - 5)"
              data={SKILL_LEVEL_REQUIRED_X.filter((value) =>
                handleExpertise(value)
              )}
              width="100%"
              name="source_skills_required"
              value={source_skills_required}
              required
            />

            <CustomAutoSelect
              onChange={handleChangeT}
              label="Target language"
              data={PRIMARY_TARGET_LANG.filter(
                (value) => value !== source_language
              )}
              width="100%"
              name="target_language"
              value={target_language}
              required
            />
            <CustomSelect
              onChange={handleChange}
              label="Please indicate linguist's target language skills level required"
              moreInfo="Please indicate linguist's target language skills level required for this assignment (1 - 5)"
              data={SKILL_LEVEL_REQUIRED_X.filter((value) =>
                handleExpertise(value)
              )}
              width="100%"
              name="target_skills_required"
              value={target_skills_required}
              required
            />

            <CustomBigInputSingle
              onChange={handleChange}
              label="Preferred Linguist / Service provider ID number"
              width="100%"
              name="provider_id_number"
              moreInfo="LC will try its best, but cannot guarantee"
              value={provider_id_number}
              placeholder="Provide details of the preferred linguist e.g. LIN/PRO12345 - Jane Miller"
            />

            <CustomRadio
              onChange={handleChange}
              label="Preferred gender"
              moreInfo="LC will try, but cannot guarantee"
              data={GENDER_DATA}
              width="100%"
              name="gender"
              value={gender}
            />

            <CustomAutoSelect
              onChange={handleSecurityChange}
              label="Please indicate security clearance level required*"
              moreInfo="Please indicate security clearance level required for this assignment"
              data={SECURITY_CLEARANCE_LEVEL}
              width="100%"
              name="security_clearance"
              value={security_clearance}
            />

            <CustomSelect
              onChange={handleChange}
              label="Select timezone"
              width="100%"
              data={TIMEZONE}
              name="timezone"
              value={timezone}
              required
            />

            <CustomDateTime
              onChange={handleDateChange}
              width="100%"
              label="When do you need this quote by?*"
              name="quote_by"
              value={quote_by}
              required
            />
            <CustomAutoSelect
              onChange={handleChangePT}
              width="100%"
              label="How do you intend to pay for this service?"
              name="pay_type"
              value={pay_type}
              data={PAYMENT_QUOTEREQUEST}
            />

            <div
              className={classes.tc}
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChecked}
                    checked={agree}
                    name="agree"
                    color="primary"
                  />
                }
                label="I have confirmed the details with the client and informed client must agree with LC T&C and Privacy policy for completing this booking, order or quote request
            "
              />
            </div>

            <div className={classes.buttonFlex}>
              <CustomButton
                disabled={isAllow}
                text="Submit"
                onClick={handleNext}
              />
            </div>
          </form>
        </div>
      ) : (
        <LoadingScreen
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          logoSrc="https://linguistscollective.com/static/media/Interpreting-Translation-Linguists-Collective-Language-Services.0f1108ca.png"
          text="Please inform the client to check their associated email"
        >
          <div>Loadable content</div>
        </LoadingScreen>
      )}
    </div>
  );
};

export default PageOne;
