import React, { useState, useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import SppoLayout from "layout/SppoLayout";
import Sppo from "components/RouteProtection/Sppo/Sppo";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ColleagueNotes from "components/Sppo/MyNote/ColleagueNoteList";
import ColleagueForm from "components/Sppo/MyNote/ColleagueForm";
import Cookies from 'js-cookie'
import Axios from 'axios'
import { Header } from "semantic-ui-react";


const Archive = () => {
  const classes = useDashboardStyles();
  const [open, setOpen] = React.useState(false);

  const [sppoID, setSPPOID] = useState(null)

  const getDetails = async (e) => {
    const token = Cookies.get("SPPOtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setSPPOID(response?.data);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <SppoLayout>
      <Sppo>
        <GridContainer>
          {sppoID?.agent_sppo && sppoID?.colleague_note_access ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className={classes.cardTitleWhite}>
                    Note / To Do List For Colleagues
                  </p>
                </CardHeader>
              </Card>

              {/* start colleague note form modal */}
              <Card>
                <Modal
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  trigger={
                    <Button color="purple" icon="add" content="Note / Do List" />
                  }
                >
                  <Modal.Content scrolling>
                    <Modal.Description>
                      <ColleagueForm />
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </Card>

              <Card>
                <ColleagueNotes />
              </Card>
            </GridItem>
            : sppoID?.profile_update ?
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    color="primary"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className={classes.cardTitleWhite}>
                      Note / To Do List For Colleagues
                    </p>
                  </CardHeader>
                </Card>

                {/* start colleague note form modal */}
                <Card>
                  <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    trigger={
                      <Button color="purple" icon="add" content="Note / Do List" />
                    }
                  >
                    <Modal.Content scrolling>
                      <Modal.Description>
                        <ColleagueForm />
                      </Modal.Description>
                    </Modal.Content>
                  </Modal>
                </Card>

                <Card>
                  <ColleagueNotes />
                </Card>
              </GridItem>
              :
              sppoID?.agent_sppo && sppoID?.profile_update === false ?
                <Header as='h3'>Access denied.</Header>
                :
                sppoID?.profile_update === false ?
                  <Header as='h3'>Access denied. <a href={`/sppo/full-registration`}>Please complete full registration.</a></Header>
                  :
                  <Header as='h3'>Loading...</Header>
          }
        </GridContainer>
      </Sppo>
    </SppoLayout>
  );
};

export default Archive;
