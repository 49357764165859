import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { useMediaQuery } from "@material-ui/core";
import { Fragment } from "react";

const CashBook = () => {
  const matches=useMediaQuery('(max-width:768px)')
  const history = useHistory();
  return (
    <div>
      {!matches?<Fragment><Button
        color="primary"
        variant="contained"
        style={{ width: "48%", marginLeft: "5px", marginRight: "15px" }}
        onClick={() => history.push("/linguist/price-calculator")}
      >
        Price Calculator
      </Button>
      <Button
        color="primary"
        variant="contained"
        style={{ width: "48%"}}
        onClick={() => history.push("/linguist/cashbook")}
      >
        Cashbook
      </Button></Fragment> :<Fragment> <Button
        color="primary"
        variant="contained"
        style={{ width: "100%",marginBottom:10}}
        onClick={() => history.push("/linguist/price-calculator")}
      >
        Price Calculator
      </Button>
      <Button
        color="primary"
        variant="contained"
        style={{ width: "100%" }}
        onClick={() => history.push("/linguist/cashbook")}
      >
        Cashbook
      </Button></Fragment> }
     
    </div>
  );
};

export default CashBook;
