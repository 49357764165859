import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import { useParams } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import UnconfirmedOptions from "components/Admin/BookingPanel/UnconfirmedAssignments/UnconfirmedOptions";
import ListCACAssignments from "components/Admin/BookingPanel/UnconfirmedAssignments/CAC/ListCACAssignments/ListCACAssignments";
import { Header } from "semantic-ui-react";
import ListCACTransAssignments from "components/Admin/BookingPanel/UnconfirmedAssignments/CAC/ListCACTransAssignments/ListCACTransAssignments";
import ListCACQuotations from "components/Admin/BookingPanel/UnconfirmedAssignments/CAC/ListCACLinQuote/ListCACAssignments";

import { useState } from "react";

const CACUnconfirmedAssignments = () => {
  const classes = useDashboardStyles();
  const { data, type } = useParams();

  return (
    <Manager>
      <MasterLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Unconfirmed Assignments (Cash Account Client)
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon onClick={() => history.goBack()} />
                </IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <UnconfirmedOptions />
              </CardHeader>
              <CardBody>
                <Header as="h4">Interpreting Assignments</Header>
                <ListCACAssignments
                  interpretingData={type === "interpreting" && data}
                />
                <Header as="h4">Translation Assignments</Header>
                <ListCACTransAssignments
                  translationData={type === "translation" && data}
                />
                <Header as="h4">Quotation</Header>
                <ListCACQuotations
                  quotationData={type === "quotation" && data}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </MasterLayout>
    </Manager>
  );
};

export default CACUnconfirmedAssignments;
