
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Axios from "utils/axios";
import swal from "sweetalert";
import { useParams } from 'react-router-dom'
import moment from "moment"
import Action from "../ActionDelivery"
import { DropzoneArea } from "material-ui-dropzone";
import Cookies from "js-cookie";
import { AccordionSummary } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import { useDispatch, useSelector } from "react-redux";
import CardBody from "components/Card/CardBody";
import { changeCrInput } from "redux/actions/crAction";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const { id } = useParams();
  const token = Cookies.get("LINtoken");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.crReducer);
  const [edit, setEdit] = React.useState({
    loader: false,
  });
  const { uploaded_document } = data
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { bookingList } = values;


  useEffect(() => {
    loadbookingList();
  }, []);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(`/api/for-linguists-admin/get_Translation_Delivery_Doc_list/assignmentID/${id}`)
      setValues({ ...values, bookingList: response?.data });
    } catch (error) {
      console.log(error)
    }
  };

  const handleFiles = (file) => {
    const inputData = {
      name: "uploaded_document",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const uploadDocument = async (e) => {
    let formData = new FormData();

    if (!uploaded_document?.contentType) {
      formData.append("document1", uploaded_document[0] || "");
      formData.append("document2", uploaded_document[1] || "");
      formData.append("document3", uploaded_document[2] || "");
      formData.append("document4", uploaded_document[3] || "");
      formData.append("document5", uploaded_document[4] || "");
    }

    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/Upload_Translation_Delivery/assignmentID/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `${response?.data?.message}`,
        icon: "success",
        buttons: false,
      })
      setEdit({ ...edit, loader: false });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error ? error?.response?.data?.error : "Please check your internet connection or if server error then contact Admin Team."}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleSubmit = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          uploadDocument(value);

        default:
          break;
      }
    });
  };

  let sl = 1

  return (
    <div>

      <CardBody>
        <DropzoneArea
          acceptedFiles={[
            ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ]}
          onChange={handleFiles}
          showFileNames
          dropzoneText="Drag & Drop Translated or Transcript Document here or Click (Max 5 files, 10MB, contact us for help)"
          showAlerts={true}
          clearOnUnmount
          maxFileSize={10000000}
          showPreviewsInDropzone
          showFileNamesInPreview
          filesLimit={5}
        />
      </CardBody>

      {edit.loader ? (
        <h3
          style={{ display: "flex", justifyContent: "center" }}
        >
          {edit.loader && (
            <div class="ui active inline loader"></div>
          )}
        </h3>
      ) : (
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AppBar
            position="static"
            className={`${classes.button}`}
            style={{ width: "100%", height: "100%" }}
          >
            <Toolbar onClick={handleSubmit}>
              <h3
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Upload Translation Delivery
                {edit.loader && (
                  <div class="ui active inline loader"></div>
                )}
              </h3>
            </Toolbar>
          </AppBar>
        </AccordionSummary>
      )}

      <Paper className={classes.root}>
        <div>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">

              <TableHead>
                <TableRow>
                  <TableCell>Document</TableCell>
                  <TableCell>Date and time (London)</TableCell>
                  <TableCell>Action</TableCell>

                </TableRow>
              </TableHead>

              {bookingList.length > 0 ?
                <TableBody>
                  {bookingList

                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((list, i) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                        <TableCell >{sl++}</TableCell>
                        <TableCell >{moment(list.createdAt).tz('Europe/London').format("DD MMM YYYY - HH:mm:ss")}</TableCell>
                        <TableCell >
                          <Action
                            id={list?._id}
                            load={loadbookingList}
                            doc1True={list?.doc1True}
                            doc2True={list?.doc2True}
                            doc3True={list?.doc3True}
                            doc4True={list?.doc4True}
                            doc5True={list?.doc5True}
                            lin={"lin"}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                :

                null}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={bookingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          /></div>

      </Paper>
    </div>
  );
}