import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomAvatar from "components/Common/CustomAvatar";
import Axios from "axios";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { changeInitRegInput } from "redux/actions/irAction";
import {
  PRIMARY_SOURCE_LANG,
  PRIMARY_TARGET_LANG,
  CURRENT_COUNTRY,
  SKILL_LEVEL_REQUIRED,
  RARE_REGEX,
  LEVEL_REGEX,
} from "data";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

const SubscribeHere = () => {
  const classes = useLandingpageStyles();
  const tableClasses = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.irReducer);
  const [values, setValues] = useState({ reload: false });
  const [results, setResults] = useState({ reload: false });
  const [allValues, setAllValues] = useState({ reload: false });

  const {
    source_language,
    source_language_level,
    target_language,
    target_language_level,
    Country,
  } = data;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeInitRegInput(inputData));
  };

  const handleChangeS = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "source_language",
        value,
      })
    );
  };
  const handleChangeSL = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "source_language_level",
        value,
      })
    );
  };

  const handleChangeT = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "target_language",
        value,
      })
    );
  };
  const handleChangeTL = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "target_language_level",
        value,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "Country",
        value,
      })
    );
  };

  const handleSExpertise = (value) => {
    if (source_language?.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(LEVEL_REGEX);
    }
  };
  const handleTExpertise = (value) => {
    if (target_language?.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(LEVEL_REGEX);
    }
  };

  //   const FindRegisteredLinguist = async () => {
  //     try {
  //       const response = await Axios.put(
  //         `${process.env.REACT_APP_BACKEND_API}/manager-admin/find/registered-linguists`,
  //         {
  //           source_language: source_language,
  //           target_language: target_language,
  //           Country: Country,
  //         }
  //       );
  //       setValues(response?.data);
  //       console.log(values);
  //     } catch (error) {
  //       swal({
  //         title: "Oops!",
  //         text: `${error.response?.data}`,
  //         icon: "error",
  //         buttons: false,
  //       });
  //     }
  //   };

  //   const FindUnregisteredLinguist = async () => {
  //     try {
  //       const response = await Axios.put(
  //         `${process.env.REACT_APP_BACKEND_API}/manager-admin/find/unregistered-linguists`,
  //         {
  //           source_language: source_language,
  //           target_language: target_language,
  //           Country: Country,
  //         }
  //       );
  //       setResults(response?.data);
  //       console.log(results);
  //     } catch (error) {
  //       swal({
  //         title: "Oops!",
  //         text: `${error.response?.data}`,
  //         icon: "error",
  //         buttons: false,
  //       });
  //     }
  //   };

  const FindAllLinguist = async () => {
    Axios.all([
      Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find/registered-linguists`,
        {
          source_language: source_language,
          target_language: target_language,
          source_language_level: source_language_level,
          target_language_level: target_language_level,
          Country: Country,
        }
      ),
      Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find/unregistered-linguists`,
        {
          source_language: source_language,
          target_language: target_language,
          source_language_level: source_language_level,
          target_language_level: target_language_level,
          Country: Country,
        }
      ),
    ]).then(
      Axios.spread((registeredlin, unregisteredlin) => {
        const history = registeredlin.data.concat(unregisteredlin.data);
        setAllValues(history);
      })
    );
  };

  return (
    <GridContainer
      justify="center"
      className={classes.container}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
        <Card>
          <CardBody>
            <div className={`${classes.textCenter} ${classes.title}`}>
              <CustomAvatar />
              <Typography
                component="h1"
                variant="h5"
                style={{ textAlign: "center" }}
              >
                Search Registered and Unregistered Linguist
              </Typography>
            </div>

            <form noValidate autoComplete="off">
              <CustomAutoSelect
                required
                onChange={handleChangeS}
                label="Source/Native language"
                data={PRIMARY_SOURCE_LANG.filter(
                  (value) => value !== target_language
                )}
                width="100%"
                name="source_language"
                value={source_language}
              />
              <CustomAutoSelect
                onChange={handleChangeSL}
                label="Please indicate linguist's source language skills level required"
                moreInfo="Please indicate linguist's source language skills level required for this assignment (1 - 5)"
                data={SKILL_LEVEL_REQUIRED.filter((value) =>
                  handleSExpertise(value)
                )}
                width="100%"
                name="source_language_level"
                value={source_language_level}
              />
              <CustomAutoSelect
                required
                onChange={handleChangeT}
                label="Target language"
                data={PRIMARY_TARGET_LANG.filter(
                  (value) => value !== source_language
                )}
                width="100%"
                name="target_language"
                value={target_language}
              />
              <CustomAutoSelect
                onChange={handleChangeTL}
                label="Please indicate linguist's target language skills level required"
                moreInfo="Please indicate linguist's target language skills level required for this assignment (1 - 5). Target language skill level will be used for price calculation"
                data={SKILL_LEVEL_REQUIRED.filter((value) =>
                  handleTExpertise(value)
                )}
                width="100%"
                name="target_language_level"
                value={target_language_level}
              />
              {/* <CustomInput
                                onChange={handleChange}
                                type="search"
                                name="Country"
                                label="Country"
                                placeholder="Search Country"
                                width="100%"
                            /> */}

              <CustomAutoSelect
                onChange={handleAutoCompleteCO}
                label="Country"
                moreInfo="Current country of residence"
                width="100%"
                name="Country"
                value={Country}
                data={CURRENT_COUNTRY}
              />

              {/* <CustomRadio
                onChange={handleChange}
                name="search_option"
                value={search_option}
                data={["Registered Linguist", "Unregistered Linguist", "All"]}
                label="Select Search Option"
                width="100%"
                required
              />

              {search_option === "Registered Linguist" ? (
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={FindRegisteredLinguist}
                    disabled={
                      !source_language || !target_language || !search_option
                    }
                  >
                    Search Registered Linguist
                  </Button>
                </div>
              ) : search_option === "Unregistered Linguist" ? (
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={FindUnregisteredLinguist}
                    disabled={
                      !source_language || !target_language || !search_option
                    }
                  >
                    Search Unregistered Linguist
                  </Button>
                </div>
              ) : ( */}
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  className={`${classes.button}`}
                  color="primary"
                  variant="contained"
                  onClick={FindAllLinguist}
                  disabled={!source_language || !target_language}
                >
                  Search All Linguist
                </Button>
              </div>
              {/* )} */}
            </form>
          </CardBody>
        </Card>
      </GridItem>

      {/* {search_option === "Registered Linguist" ? (
        <>
          {values?.length > 0 ? (
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <br />
              <br />
              <b style={{ color: "green" }}>* Verified Linguist,</b>{" "}
              <b style={{ color: "blue" }}>Unverified Linguist,</b>{" "}
              <b style={{ color: "red" }}> Suspend Linguist</b>
              <br />
              <br />
              <b style={{ color: "purple" }}>
                {" "}
                {values?.length}{" "}
                {values?.length > 1 ? "Linguists Found" : "Linguist Found"}{" "}
              </b>
              <br />
              <br />
              <Paper className={tableClasses.root}>
                <TableContainer className={tableClasses.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Linguist ID</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Mobile Number</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Source / Native language</TableCell>
                        <TableCell>Target language</TableCell>
                        <TableCell>Target language Level</TableCell>
                        <TableCell>Town / City</TableCell>
                        <TableCell>Post / Zip Code</TableCell>
                        <TableCell>Country</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values?.length > 0 ? (
                        <>
                          {values
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            ?.map((list, i) => {
                              if (list?.verified == true) {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={i}
                                    style={{ background: "lightgreen" }}
                                  >
                                    <TableCell>
                                      <Link
                                        to={`/admin/view-linguist-profile/${list._id}`}
                                      >
                                        {list.id}
                                      </Link>
                                    </TableCell>

                                    <TableCell>{list.LastName}</TableCell>
                                    <TableCell>{list.email}</TableCell>
                                    <TableCell>{list.mobile_no}</TableCell>
                                    <TableCell>{list.phone_no}</TableCell>
                                    <TableCell>
                                      {list.source_language
                                        ? list.source_language
                                        : "N/A"}{" "}
                                      <br />
                                      {list.source_language_1
                                        ? list.source_language_1
                                        : "N/A"}{" "}
                                      <br />
                                      {list.source_language_2
                                        ? list.source_language_2
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      {list.target_language
                                        ? list.target_language
                                        : "N/A"}
                                      <br />
                                      {list.target_language_1
                                        ? list.target_language_1
                                        : "N/A"}
                                      <br />
                                      {list.target_language_2
                                        ? list.target_language_2
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      {list?.target_language_level
                                        ? list?.target_language_level
                                        : "N/A"}
                                      <br />
                                      {list?.target_language_level_1
                                        ? list?.target_language_level_1
                                        : "N/A"}
                                      <br />
                                      {list?.target_language_level_2
                                        ? list?.target_language_level_2
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>{list.City}</TableCell>
                                    <TableCell>{list.PostalCode}</TableCell>
                                    <TableCell>{list.Country}</TableCell>
                                  </TableRow>
                                );
                              } else if (list?.verified == false) {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={i}
                                    style={{ background: "#4FAEF4" }}
                                  >
                                    <TableCell>
                                      {" "}
                                      <Link
                                        to={`/admin/view-linguist-profile/${list._id}`}
                                      >
                                        {list.id}
                                      </Link>
                                    </TableCell>

                                    <TableCell>{list.LastName}</TableCell>
                                    <TableCell>{list.email}</TableCell>
                                    <TableCell>{list.mobile_no}</TableCell>
                                    <TableCell>{list.phone_no}</TableCell>
                                    <TableCell>
                                      {list.source_language}
                                    </TableCell>
                                    <TableCell>
                                      {list.target_language}
                                    </TableCell>
                                    <TableCell>
                                      {list?.target_language_level}
                                    </TableCell>
                                    <TableCell>{list.City}</TableCell>
                                    <TableCell>{list.PostalCode}</TableCell>
                                    <TableCell>{list.Country}</TableCell>
                                  </TableRow>
                                );
                              } else if (list?.suspend == true) {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={i}
                                    style={{ background: "#F7624A" }}
                                  >
                                    <TableCell>
                                      <p style={{ color: "red" }}>
                                        {" "}
                                        <Link
                                          to={`/admin/view-linguist-profile/${list._id}`}
                                        >
                                          {list.id}
                                        </Link>
                                      </p>
                                    </TableCell>

                                    <TableCell>{list.LastName}</TableCell>
                                    <TableCell>{list.email}</TableCell>
                                    <TableCell>{list.mobile_no}</TableCell>
                                    <TableCell>{list.phone_no}</TableCell>
                                    <TableCell>
                                      {list.source_language}
                                    </TableCell>
                                    <TableCell>
                                      {list.target_language}
                                    </TableCell>
                                    <TableCell>
                                      {list?.target_language_level}
                                    </TableCell>
                                    <TableCell>{list.City}</TableCell>
                                    <TableCell>{list.PostalCode}</TableCell>
                                    <TableCell>{list.Country}</TableCell>
                                  </TableRow>
                                );
                              }
                            })}
                        </>
                      ) : (
                        "No Linguist Found"
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={values?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            </GridItem>
          ) : null}
        </>
      ) : null} */}

      {/* {search_option === "Unregistered Linguist" ? (
        <>
          {results?.length > 0 ? (
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <br />
              <br />
              <b style={{ color: "purple" }}>
                {" "}
                {results?.length}{" "}
                {results?.length > 1 ? "Linguists Found" : "Linguist Found"}
              </b>
              <br />
              <br />

              <Paper className={tableClasses.root}>
                <TableContainer className={tableClasses.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Linguist ID</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Mobile Number</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Source / Native language</TableCell>
                        <TableCell>Target language</TableCell>
                        <TableCell>Town / City</TableCell>
                        <TableCell>Post / Zip Code</TableCell>
                        <TableCell>Country</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {results?.length > 0 ? (
                        <>
                          {results
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            ?.map((list, i) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={i}
                                >
                                  <TableCell>
                                    <Link
                                      to={`/admin/view-unregistered-linguist-client/${list._id}`}
                                    >
                                      {list?.id}
                                    </Link>
                                  </TableCell>

                                  <TableCell>{list.last_name}</TableCell>
                                  <TableCell>{list.primary_email}</TableCell>
                                  <TableCell>{list.mobile_no}</TableCell>
                                  <TableCell>{list.phone_no}</TableCell>
                                  <TableCell>{list.source_language}</TableCell>
                                  <TableCell>{list.target_language}</TableCell>
                                  <TableCell>{list.city}</TableCell>
                                  <TableCell>{list.postal_code}</TableCell>
                                  <TableCell>{list.country}</TableCell>
                                </TableRow>
                              );
                            })}
                        </>
                      ) : (
                        "No Linguist Found"
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={results?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            </GridItem>
          ) : null}
        </>
      ) : null} */}

      <>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <br />
          <br />
          <b style={{ color: "green" }}>* Verified Linguist,</b>{" "}
          <b style={{ color: "blue" }}>Unverified Linguist,</b>{" "}
          <b style={{ color: "red" }}> Suspend Linguist,</b>
          <b style={{ color: "lightsalmon" }}> Unregistered Linguist</b>
          <br />
          <br />
          <b style={{ color: "purple" }}>
            {" "}
            {allValues?.length}{" "}
            {allValues?.length > 1 ? "Linguists Found" : "Linguist Found"}
          </b>
          <br />
          <br />
          <Paper className={tableClasses.root}>
            <TableContainer className={tableClasses.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Linguist ID</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Source / Native language</TableCell>
                    <TableCell>Target language</TableCell>
                    <TableCell>Target language Level</TableCell>
                    <TableCell>Town / City</TableCell>
                    <TableCell>Post / Zip Code</TableCell>
                    <TableCell>Country</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allValues?.length > 0 ? (
                    <>
                      {allValues
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((list, i) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={i}
                              style={
                                list?.verified == true
                                  ? {
                                      backgroundColor: "lightgreen",
                                      color: "white",
                                    }
                                  : list?.verified == false
                                  ? { backgroundColor: "#4FAEF4" }
                                  : list?.suspend == true
                                  ? { backgroundColor: "#F7624A" }
                                  : list?.initial_reg == true
                                  ? { backgroundColor: "lightsalmon" }
                                  : null
                              }
                            >
                              <TableCell>
                                {list?.LastName ? (
                                  <Link
                                    to={`/employee/view-linguist-profile/${list._id}`}
                                  >
                                    {list.id}
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/employee/view-unregistered-linguist-client/${list._id}`}
                                  >
                                    {list?.id}
                                  </Link>
                                )}
                              </TableCell>

                              <TableCell>
                                {list?.LastName
                                  ? list?.LastName
                                  : list?.last_name}
                              </TableCell>
                              <TableCell>
                                {list?.email
                                  ? list?.email
                                  : list?.primary_email}
                              </TableCell>
                              <TableCell>{list?.mobile_no}</TableCell>
                              <TableCell>{list?.phone_no}</TableCell>
                              <TableCell>
                                {list?.source_language}
                                <br />
                                {list?.source_language_1
                                  ? list?.source_language_1
                                  : "N/A"}{" "}
                                <br />
                                {list?.source_language_2
                                  ? list?.source_language_2
                                  : "N/A"}
                              </TableCell>
                              <TableCell>
                                {list?.target_language}
                                <br />
                                {list.target_language_1
                                  ? list.target_language_1
                                  : "N/A"}
                                <br />
                                {list.target_language_2
                                  ? list.target_language_2
                                  : "N/A"}
                              </TableCell>
                              <TableCell>
                                {list?.target_language_level
                                  ? list?.target_language_level
                                  : "N/A"}
                                <br />
                                {list?.target_language_level_1
                                  ? list?.target_language_level_1
                                  : "N/A"}
                                <br />
                                {list?.target_language_level_2
                                  ? list?.target_language_level_2
                                  : "N/A"}
                              </TableCell>
                              <TableCell>
                                {list?.City ? list?.City : list?.city}
                              </TableCell>
                              <TableCell>
                                {list?.PostalCode
                                  ? list?.PostalCode
                                  : list?.postal_code}
                              </TableCell>
                              <TableCell>
                                {list?.Country ? list?.Country : list?.country}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </>
                  ) : (
                    "No Linguist Found"
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={allValues?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </GridItem>
      </>
    </GridContainer>
  );
};

// EBBlxUJe_
export default SubscribeHere;
