import React from "react";

// Custom Components
import CommonCard from "components/Common/CommonCard";
import TableOne from "./TableOne";
import TableTwo from "./TableTwo";
import TableThree from "./TableThree";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const PrivateAssignmentView = () => {
  const classes = useDashboardStyles();

  return (
    <CommonCard title="Private Assignment View">
      <div className={classes.listDiv}>
        <TableOne />
        <TableTwo />
        <TableThree />
      </div>
    </CommonCard>
  );
};

export default PrivateAssignmentView;
