import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import history from 'utils/history'

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import PreRegistration from "components/Sppo/SendLINRegistration/LINRegistration";
import SppoLayout from "layout/SppoLayout";
import Sppo from "components/RouteProtection/Sppo/Sppo";

const PreRegistrationPage = () => {
    const classes = useDashboardStyles();

    return (
        <Sppo>
            <SppoLayout>

                <GridContainer>

                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                                <p className={classes.cardTitleWhite}>Send Linguist Registration Form (+ unregistered LIN)</p>
                                <IconButton color='inherit' onClick={() => history.goBack()}><ArrowBackIcon></ArrowBackIcon></IconButton>
                            </CardHeader>
                            <CardBody>
                                <PreRegistration />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

            </SppoLayout>
        </Sppo>
    );
};

export default PreRegistrationPage;
