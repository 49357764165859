import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import Cookies from "js-cookie";
import { PRIMARY_SOURCE_LANG, PRIMARY_TARGET_LANG, EMAIL_REGEX } from "data";
import { changeInitRegInput } from "redux/actions/irAction";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import axios from "axios";
import swal from "sweetalert";
import CustomPhone from "components/Common/CustomPhone";
import CustomEmail from "components/Common/CustomEmail";

const PageOne = () => {
  const { data } = useSelector((state) => state.irReducer);
  const dispatch = useDispatch();
  const [notValid, setNotValid] = useState(false);
  const [notRegex, setNotRegex] = useState(false);
  const token = Cookies.get("LINtoken");

  const {
    first_name,
    last_name,
    mobile_no,
    email,
    target_language,
    source_language,
    note,
  } = data;

  const classes = useFormStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInitRegInput({
        name,
        value: value,
      })
    );
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInitRegInput({
        name,
        value: value,
      })
    );
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleChangeSO = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "source_language",
        value,
      })
    );
  };

  const handleChangeTA = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "target_language",
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeInitRegInput({
        name: "mobile_no",
        value: value,
      })
    );
  };

  const handleSubmit = async (e) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/send-invitation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `Invitation Sent!`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/linguist/send-invitation";
      });
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const isAllow =
    !first_name ||
    !last_name ||
    !email ||
    !target_language ||
    !source_language ||
    notValid;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          label="First Name (s)"
          width="100%"
          name="first_name"
          value={first_name}
          required
          placeholder="First Name(s)/Forename/Given Names of your friend/colleague"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Last Name"
          width="100%"
          name="last_name"
          value={last_name}
          placeholder="Last Name/Surname/Family Name of your friend/colleague"
          required
        />
        <br />
        <CustomPhone
          label="Mobile Number"
          onChange={handleMobile}
          placeholder="Mobile number of your friend/colleague (e.g. +44 7307 000000)"
          value={mobile_no}
          name="mobile_no"
        />

        <CustomEmail
          onChange={handleEmailChange}
          label="Primary Email"
          width="100%"
          name="email"
          value={email}
          type="email"
          placeholder="Email address of your friend/colleague (e.g. example@example.com)"
          required
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <CustomAutoSelect
          onChange={handleChangeSO}
          value={source_language}
          name="source_language"
          label="Friend or colleague's Source Language / mother tongue "
          data={PRIMARY_SOURCE_LANG.filter(
            (value) => value !== target_language
          )}
          width="100%"
          required
        />
        <CustomAutoSelect
          onChange={handleChangeTA}
          value={target_language}
          name="target_language"
          label="Friend or colleague's Target Language"
          data={PRIMARY_TARGET_LANG.filter(
            (value) => value !== source_language
          )}
          width="100%"
          required
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Your message"
          name="note"
          value={note}
          width="100%"
          placeholder="Write your invitation message here..."
        />

        <div className={classes.buttonFlex}>
          <CustomButton
            onClick={handleSubmit}
            disabled={isAllow}
            text="Submit"
          />
        </div>
      </form>
    </div>
  );
};

export default PageOne;
