export const ACCOUNT_CLIENT_QUOTS_REQUEST = "ACCOUNT_CLIENT_QUOTS_REQUEST"
export const ACCOUNT_CLIENT_QUOTS_SUCCESS = "ACCOUNT_CLIENT_QUOTS_SUCCESS"
export const ACCOUNT_CLIENT_QUOTS_FAIL = "ACCOUNT_CLIENT_QUOTS_FAIL"

export const ACCOUNT_CLIENT_TRANSLATION_REQUEST = "ACCOUNT_CLIENT_TRANSLATION_REQUEST"
export const ACCOUNT_CLIENT_TRANSLATION_SUCCESS = "ACCOUNT_CLIENT_TRANSLATION_SUCCESS"
export const ACCOUNT_CLIENT_TRANSLATION_FAIL = "ACCOUNT_CLIENT_TRANSLATION_FAIL"

export const ACCOUNT_CLIENT_JOBADVART_REQUEST = "ACCOUNT_CLIENT_JOBADVART_REQUEST"
export const ACCOUNT_CLIENT_JOBADVART_SUCCESS = "ACCOUNT_CLIENT_JOBADVART_SUCCESS"
export const ACCOUNT_CLIENT_JOBADVART_FAIL = "ACCOUNT_CLIENT_JOBADVART_FAIL"