import React, { useState } from "react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import MultiDropdown from "components/Common/MultiDropdown";

// Others
import { TEST_LISIS } from "data";

const Freelancers = () => {
  const [selectedData, setSelectedData] = useState([]);
  return (
    <Card>
      <CardBody>
        <MultiDropdown
          label="Freelancers"
          data={TEST_LISIS}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      </CardBody>
    </Card>
  );
};

export default Freelancers;
