import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { getPrivateAssignments } from "../../../../action/linguistAction";
import { useToasts } from "react-toast-notifications";
import SearchView from "./Search/SearchAssignment";
import Cookies from "js-cookie";
import Axios from "axios";
import Moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ interpretingData }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = () => {
    getPrivateAssignments().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setValues({
          ...values,
          bookingList: interpretingData
            ? data.filter((item) => item.id === interpretingData)
            : data,
        });
        interpretingData && setShow(true);
      }
    });
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-unconfirm-booking/bookingID/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });

        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <div>
      {!interpretingData && (
        <SearchView
          load={loadbookingList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          SearchText={SearchText}
          setShow={setShow}
          show={show}
        />
      )}
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Assignment ID</TableCell>
                    <TableCell>Client ID</TableCell>
                    <TableCell>Timezone</TableCell>
                    <TableCell>Start date & time</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Source language</TableCell>
                    <TableCell>Target language</TableCell>
                    <TableCell>Expertise Level</TableCell>
                    <TableCell>Security Level</TableCell>
                    <TableCell>Estimate Amount</TableCell>
                    <TableCell>Payment Status</TableCell>
                  </TableRow>
                </TableHead>

                {bookingList.length > 0 ? (
                  <TableBody>
                    {bookingList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={list._id}
                        >
                          <TableCell key={list._id} align={list.align}>
                            <Link
                              to={`/linguist/prc-view-interpreting-assignment/${list._id}`}
                            >
                              {list.id}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {list.clientID?.id ? list.clientID?.id : "N/A"}
                          </TableCell>
                          <TableCell>{list?.timezone}</TableCell>
                          <TableCell>
                            {Moment(list.startdateArray[0]).format(
                              "DD-MMM-YYYY"
                            )}{" "}
                            - {list?.starttimeArray[0]}
                          </TableCell>
                          <TableCell>{list.total_time_period}</TableCell>
                          <TableCell>{list.source_language}</TableCell>
                          <TableCell>{list.target_language}</TableCell>
                          <TableCell>
                            {list.target_expertise_level_required}
                          </TableCell>
                          <TableCell>{list.security_clearance}</TableCell>
                          <TableCell>
                            {list?.currency?.substring(0, 3)} {list.estimate}
                          </TableCell>
                          <TableCell>{list.status}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
