import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import Axios from "utils/axios";
import EditableText from "./EditTextInvToSppo.jsx";
import Cookies from "js-cookie";
import { TableSortLabel } from "@material-ui/core";
import DateView from "./DateFilter/DateView";
import SearchView from "./Search/SearchView";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 1000,
  },
});

export default function StickyHeadTable({ setReload }) {
  const classes = useStyles();
  const token = Cookies.get("token");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [from, setFrom] = useState(false);
  const [to, setTo] = useState("");
  const [SearchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const [asc, setAsc] = useState(false);
  const [dec, setDec] = useState(true);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [details, setDetails] = useState({ paid_to_sppo: "" });
  const [values, setValues] = useState({ reload: false });
  const handleASCSort = (dates) => {
    let temp = [];
    setDec(false);
    setAsc(true);
    if (dates?.length > 0) {
      dates?.forEach((record, i) => {
        const d1 = record?.createdAt;
        const d2 = dates[i + 1]?.createdAt;
        temp = dates?.sort(() => Date.parse(d1) - Date.parse(d2));
      });
      setValues(temp);
    } else {
      values?.forEach((record, i) => {
        const d1 = record?.createdAt;
        const d2 = values[i + 1]?.createdAt;
        temp = values?.sort(() => Date.parse(d1) - Date.parse(d2));
      });
      setValues(temp);
    }
  };

  const handleDECSort = () => {
    let temp = [];
    setAsc(false);
    setDec(true);

    values?.forEach((record, i) => {
      const d1 = record?.createdAt;
      const d2 = values[i + 1]?.createdAt;
      temp = values?.sort(() => Date.parse(d2) - Date.parse(d1));
    });
    setValues(temp);
  };
  useEffect(() => {
    loadList();
    setFiltered([]);
  }, [filter]);

  const loadList = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-of-all-invoice-by-LC-to-client-sppo`
    )
      .then((res) => {
        if (filtered.length > 0) {
          setValues(filtered);
        } else {
          setValues(res.data);
          // handleDECSort(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  // let totalProfit = 0;

  // for (let i = 0; i <= values?.length - 1; i++) {
  //   {
  //     totalProfit += values[i].grand_total - values[i].paid_to_sppo;
  //   }
  // }

  return (
    <>
      <Paper className={classes.root}>
        <div>
          <SearchView
            SearchText={SearchText}
            setSearchText={setSearchText}
            setFiltered={setFiltered}
            setFilter={setFilter}
            values={values}
            load={loadList}
            id={"invtosppo"}
          />
          <DateView
            to={to}
            from={from}
            setTo={setTo}
            setFrom={setFrom}
            setFiltered={setFiltered}
            setFilter={setFilter}
            values={values}
            load={loadList}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" id="invoice-to-sppo">
              <TableHead>
                <TableRow>
                  <TableCell>Direct to Client or SPPO </TableCell>
                  <TableCell>Invoice to Client or SPPO ID </TableCell>
                  <TableCell>Invoice Number </TableCell>
                  <TableCell>
                    Invoice Date
                    {dec ? (
                      <TableSortLabel
                        active
                        onClick={handleASCSort}
                        direction="asc"
                      />
                    ) : (
                      <TableSortLabel
                        active
                        onClick={handleDECSort}
                        direction="desc"
                      />
                    )}{" "}
                  </TableCell>{" "}
                  <TableCell>Invoice Total </TableCell>
                  <TableCell>Invoiced by SPPO ID or LC User ID </TableCell>
                  <TableCell>Payment Received </TableCell>
                  <TableCell>Paid to % SPPO</TableCell>
                  <TableCell>LC % Balance on hand £00.00 </TableCell>
                  {/* <TableCell>Notes </TableCell> */}
                </TableRow>
              </TableHead>

              {values.length > 0 ? (
                <TableBody>
                  {values
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((list) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={list._id}
                        style={
                          list?.invoice_payment_status
                            ? { backgroundColor: "lightgreen" }
                            : null
                        }
                      >
                        <TableCell>{"Direct Invoice"}</TableCell>
                        <TableCell>{list?.client_id}</TableCell>
                        <TableCell>
                          <Link to={`/admin/view-direct-invoice/${list._id}`}>
                            {list.id}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {Moment(list.createdAt).format(
                            "DD MMM YYYY - kk:mm:ss"
                          )}
                        </TableCell>
                        <TableCell>
                          {list?.currency?.substring(0, 3)} {list.grand_total}
                        </TableCell>
                        <TableCell>
                          {list?.postedByID ? list?.postedByID : list.userID}
                        </TableCell>
                        <TableCell>
                          {list?.currency?.substring(0, 3)}{" "}
                          {list?.invoice_payment_status
                            ? list.grand_total
                            : "0.00"}
                        </TableCell>

                        <TableCell>
                          {list?.adminTrue && list?.sppoTrue ? (
                            "No Need"
                          ) : (
                            <>
                              {list?.invoice_payment_status ? (
                                <EditableText
                                  setReload={setReload}
                                  text={list?.paid_to_sppo}
                                  readOnly={false}
                                  id={list?._id}
                                  variant="outlined"
                                  name={"paid_to_sppo"}
                                  load={loadList}
                                />
                              ) : (
                                "N/A"
                              )}
                            </>
                          )}
                        </TableCell>
                        {list?.adminTrue && list?.sppoTrue ? (
                          <TableCell>
                            {list?.invoice_payment_status
                              ? list?.grand_total
                              : 0}
                          </TableCell>
                        ) : (
                          <TableCell>
                            {list?.invoice_payment_status
                              ? list?.grand_total - list?.paid_to_sppo
                              : 0 - list?.paid_to_sppo}
                          </TableCell>
                        )}
                        {/* <TableCell></TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                "No data found"
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[30, 55, 100]}
            component="div"
            count={values.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </>
  );
}
