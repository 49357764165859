import React, { useEffect } from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from "@material-ui/core";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LCJoining from "components/Auth/LCJoining/JoinPageWithForm/LCJoining7";
import history from "utils/history";
import Axios from "axios";
import Cookies from 'js-cookie'

const CompleteRegistration = () => {
  const classes = useDashboardStyles();
  const token = Cookies.get('LINtoken')
 
  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/linguists/access-control`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (response?.data.profile_update) {
        history.push('/linguist/profile/update')
      }
   } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getLinguistDetails();
  }, [])

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                <p className={classes.cardTitleWhite}>Complete Registration</p>
                <IconButton color='inherit'><ArrowBackIcon

                  onClick={() => history.goBack()}
                ></ArrowBackIcon></IconButton>
              </CardHeader>
              <CardBody>
                <LCJoining />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
    );
};

export default CompleteRegistration;
