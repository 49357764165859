import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import { Button, Item } from "semantic-ui-react";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "40%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media(max-width:768px)"]: {
      width: "100%",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearchInput = ({
  values,
  SearchText,
  setSearchText,
  setShow,
  load,
  setFiltered,
  setFilter,
  show,
}) => {
  const classes = useStyles();
  const handleInput = (value) => {
    setSearchText(value);
    if (value === "" || value === undefined) {
      load();
    } else {
      setFiltered(
        values?.filter((item) => {
          if (item.id?.toLowerCase() === value?.toLowerCase()) {
            return item.id?.toLowerCase() === value?.toLowerCase();
          } else if (item.country?.toLowerCase() === value?.toLowerCase()) {
            return item.country?.toLowerCase() === value?.toLowerCase();
          } else if (
            moment(item?.quote_by).format("DD-MMM-YYYY : HH:mm") ===
            value?.toLowerCase()
          ) {
            return (
              moment(Item.quote_by).format("DD-MMM-YYYY : HH:mm") ===
              value?.toLowerCase()
            );
          } else if (
            item.request_details.toLowerCase() === value?.toLowerCase()
          ) {
            return item.request_details.toLowerCase() === value?.toLowerCase();
          } else if (
            item.project_fix_budget.toLowerCase() === value?.toLowerCase()
          ) {
            return (
              item.project_fix_budget.toLowerCase() === value?.toLowerCase()
            );
          } else if (
            item.source_language.toLowerCase() === value?.toLowerCase()
          ) {
            return item.source_language.toLowerCase() === value?.toLowerCase();
          } else if (
            item.target_language.toLowerCase() === value?.toLowerCase()
          ) {
            return item.target_language.toLowerCase() === value?.toLowerCase();
          } else if (
            item.target_skills_required.toLowerCase() === value?.toLowerCase()
          ) {
            return (
              item.target_skills_required.toLowerCase() === value?.toLowerCase()
            );
          } else if (item.timezone.toLowerCase() === value?.toLowerCase()) {
            return item.timezone.toLowerCase() === value?.toLowerCase();
          }
        })
      );
      setFilter(Math.random());
    }
  };
  return (
    <div>
      <Paper
        component="form"
        className={classes.root}
        onClick={(e) => e.preventDefault()}
      >
        <InputBase
          className={classes.input}
          placeholder="Filter asignment/job..."
          onChange={(e) => {
            handleInput(e.target.value);
          }}
          inputProps={{ "aria-label": "search google maps" }}
          value={SearchText}
          // onChange={(e) => setText(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Button onClick={() => setShow(!show)} color="purple">
          View all
        </Button>
      </Paper>
    </div>
  );
};

export default SearchInput;
