import React, { Fragment, useState } from "react";

// materil core components
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import IconButton from "@material-ui/core/IconButton";

import ViewComfyIcon from "@material-ui/icons/ViewComfy";
// Custom Components
import CustomTable from "components/Common/CustomTable";
import CustomModal from "components/Common/CustomModal";
import NoteInput from "./NoteInput";
import Action from "./Action";
import ViewNote from "./ViewNote";

const TableThree = () => {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("input");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: "assignment", headerName: "Assignment ID", width: 130 },
    { field: "type", headerName: "Quote Request For", width: 120 },
    {
      field: "language",
      headerName: "Remote/Venue",
      width: 120,
      renderCell: (params) => {
        const values = params.value.split(", ");
        return (
          <>
            {values.map((value, index) => (
              <span key={index}>{value}</span>
            ))}
          </>
        );
      },
    },
    { field: "deadline", headerName: "Request Details", width: 120 },
    { field: "words", headerName: "Budget", width: 120 },
    { field: "value", headerName: "Languages", width: 120 },
    { field: "value", headerName: "Skills Level Required", width: 120 },
    { field: "value", headerName: "Quote Needed By", width: 120 },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return <Action />;
      },
    },
    {
      field: "note",
      headerName: "Note",
      width: 120,
      renderCell: (params) => (
        <Fragment>
          <IconButton
            aria-label="Person"
            color="primary"
            onClick={() => {
              handleOpen();
              setModalType("input");
            }}
          >
            <ControlPointIcon />
          </IconButton>
          <IconButton
            aria-label="Person"
            color="primary"
            onClick={() => {
              handleOpen();
              setModalType("view");
            }}
          >
            <ViewComfyIcon />
          </IconButton>
        </Fragment>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      assignment: "Translation",
      type: "Certificate",
      language: "Italian, French",
      deadline: "18 June 20",
      words: "1700",
      value: "€200",
      action: "Urgent",
      status: "Not Allocated",
      note: "note",
    },
  ];

  return (
    <Fragment>
      <CustomTable rows={rows} columns={columns} />
      <CustomModal open={open} handleClose={handleClose}>
        {modalType === "input" ? <NoteInput /> : null}
        {modalType === "view" ? <ViewNote /> : null}
      </CustomModal>
    </Fragment>
  );
};

export default TableThree;
