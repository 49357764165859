import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import history from "utils/history"
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Options from "components/Admin/ControlPanel/Archive/Options"
import AssignmentOptions from "components/Admin/ControlPanel/Archive/Assignments/AssignmentOptions"

import CACInterpreting from "components/Admin/ControlPanel/Archive/Assignments/CACInterpreting"
import BACInterpreting from "components/Admin/ControlPanel/Archive/Assignments/BACInterpreting"
import INCInterpreting from "components/Admin/ControlPanel/Archive/Assignments/INCInterpreting"
import PRCInterpreting from "components/Admin/ControlPanel/Archive/Assignments/PRCInterpreting"
import CACTranslation from "components/Admin/ControlPanel/Archive/Assignments/CACTranslation"
import BACTranslation from "components/Admin/ControlPanel/Archive/Assignments/BACTranslation"
import INCTranslation from "components/Admin/ControlPanel/Archive/Assignments/INCTranslation"
import PRCTranslation from "components/Admin/ControlPanel/Archive/Assignments/PRCTranslation"



const Archive = () => {
  const classes = useDashboardStyles();
  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>
                  Assignments {" "}
                  {history.location.pathname.includes("admin/archived-cac-interpreting") ?
                    "(CAC Interpreting Assignments)" :
                    history.location.pathname.includes("admin/archived-bac-interpreting") ?
                      "(BAC Interpreting Assignments)" :
                      history.location.pathname.includes("admin/archived-inc-interpreting") ?
                        "(INC Interpreting Assignments)" :
                        history.location.pathname.includes("admin/archived-prc-interpreting") ?
                          "(PRC Interpreting Assignments)" :
                          history.location.pathname.includes("admin/archived-cac-translation") ?
                            "(CAC Translation Assignments)" :
                            history.location.pathname.includes("admin/archived-bac-translation") ?
                              "(BAC Translation Assignments)" :
                              history.location.pathname.includes("admin/archived-inc-translation") ?
                                "(INC Translation Assignments)" :
                                history.location.pathname.includes("admin/archived-prc-translation") ?
                                  "(PRC Translation Assignments)" : null}
                </p>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Options />
              </CardHeader>
            </Card>
            <AssignmentOptions />
            <Card>
              {history.location.pathname.includes("admin/archived-cac-interpreting") ?
                <CACInterpreting /> :
                history.location.pathname.includes("admin/archived-bac-interpreting") ?
                  <BACInterpreting /> :
                  history.location.pathname.includes("admin/archived-inc-interpreting") ?
                    <INCInterpreting /> :
                    history.location.pathname.includes("admin/archived-prc-interpreting") ?
                      <PRCInterpreting /> :
                      history.location.pathname.includes("admin/archived-cac-translation") ?
                        <CACTranslation /> :
                        history.location.pathname.includes("admin/archived-bac-translation") ?
                          <BACTranslation /> :
                          history.location.pathname.includes("admin/archived-inc-translation") ?
                            <INCTranslation /> :
                            history.location.pathname.includes("admin/archived-prc-translation") ?
                              <PRCTranslation /> : null}
            </Card>
          </GridItem>
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default Archive;
