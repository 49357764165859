import React, { Fragment } from "react";

// Material Core Components
import CssBaseline from "@material-ui/core/CssBaseline";

// Material Icons
import LPNavbar from "components/LPNavbar/LPNavbar";

// Custom Styles
import useStyles from "assets/jss/layouts/style";
import LPFooter from "components/LPFooter";

function LandingPageLayout(props) {
  const classes = useStyles();

  return (
    <Fragment>
     
      <div
        style={{
          background: "#D6BCFA",
          padding: "15px 0 0 0",
          userSelect: "none",
        }}
        className={classes.root}
      >
        
        <CssBaseline />
        <LPNavbar />

        <main
          style={{ padding: "8rem 0 0", marginTop: "4rem" }}
          className={classes.content}
        >
           
          {props.children}
         
        </main>

        <LPFooter />
      </div>
    </Fragment>
  );
}

export default LandingPageLayout;
