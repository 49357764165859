import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import MyLogs from "components/Admin/ActivityLogs/MyLogs";
import VisitorLogs from "components/Admin/ActivityLogs/VisitorLogs";
import CACLogs from "components/Admin/ActivityLogs/CACLogs";
import BACLogs from "components/Admin/ActivityLogs/BACLogs";
import LINLogs from "components/Admin/ActivityLogs/LINLogs";
import SPPOLogs from "components/Admin/ActivityLogs/SPPOLogs";
import EMPLogs from "components/Admin/ActivityLogs/EMPLogs";
import ADMLogs from "components/Admin/ActivityLogs/ADMLogs";
import Options from "components/Admin/ActivityLogs/Options";

const Archive = () => {
  const classes = useDashboardStyles();

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>All Activity Logs</p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
            </Card>
            <Options />
            <Card>
              {history.location.pathname.includes("admin/my-activity-log") ?
                <MyLogs /> :
                history.location.pathname.includes("admin/visitor-activity-log") ?
                  <VisitorLogs /> :
                  history.location.pathname.includes("admin/cac-activity-log") ?
                    <CACLogs /> :
                    history.location.pathname.includes("admin/bac-activity-log") ?
                      <BACLogs /> :
                      history.location.pathname.includes("admin/linguist-activity-log") ?
                        <LINLogs /> :
                        history.location.pathname.includes("admin/sppo-activity-log") ?
                          <SPPOLogs /> :
                          history.location.pathname.includes("admin/employee-activity-log") ?
                            <EMPLogs /> :
                            history.location.pathname.includes("admin/manager-activity-log") ?
                              <ADMLogs /> : null}
            </Card>
          </GridItem>
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default Archive;
