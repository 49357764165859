import { useState } from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Invoices from "./Invoices/Invoices";
import TotalJobs from "./TotalJobs/TotalJobs";
import ReportFilter from "./ReportFilter";
import ReportCards from "./ReportCards";

const Report = () => {
  const [selectedReport, setSelectedReport] = useState();
  return (
    <GridContainer>
      {/* <GridItem xs={12} sm={12} md={12} lg={12}>
        <ReportFilter
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
        />
         <p style={{ color: 'red' }}>This page is not fully functional yet. Your feedback welcome</p> 
      </GridItem> */}
      <GridItem xs={12} sm={12} md={12} lg={12}>
        {/* {selectedReport === "Invoices" && <Invoices />}
        {selectedReport === "Total Jobs" && <TotalJobs />} */}
        <ReportCards />
      </GridItem>
    </GridContainer>
  );
};

export default Report;
