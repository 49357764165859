import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";
import Axios from "utils/axios";
import Cookie from "js-cookie";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import QueuingList from "components/Admin/ControlPanel/QueuingPanel/QueuingList";
import { Dimmer, Header, Loader, Button } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    minWidth: "fit-content",
  },
  chatSection: {
    width: "100%",
    height: "max-content",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "max-content",
    overflowY: "auto",
  },
});

const CommunicationMenu = () => {
  const classes = useDashboardStyles();
  const classess = useStyles();
  const token = Cookie.get("token");

  const [control_panel, setAdminAccess] = useState(null);
  const getAdminDetails = async (e) => {
    const response = await Axios.get("/api/manager-admin/dashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setAdminAccess(response?.data.control_panel);
  };


  useEffect(() => {
    getAdminDetails()
  }, []);

  return (
    <Manager>
      <MasterLayout>
        <GridContainer>
          {control_panel ? (
            <>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    color="primary"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className={classes.cardTitleWhite}>
                      Queuing Panel
                    </p>
                    <IconButton color="inherit">
                      <ArrowBackIcon
                        onClick={() => history.goBack()}
                      ></ArrowBackIcon>
                    </IconButton>
                  </CardHeader>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardBody>
                    <Grid
                      container
                      component={Paper}
                      className={classess.chatSection}
                    >

                      <Grid item xs={12}>
                        <QueuingList />
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </GridItem>
            </>
          ) : control_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </MasterLayout>
    </Manager>
  );
};

export default CommunicationMenu;
