import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CardHeader from "components/Card/CardHeader";
import ViewInvoice from 'components/Admin/TimesheetProjectReport/Invoice/ViewDirectQuote'

import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";


const ViewpaidInvoicePage = () => {
  const classes = useDashboardStyles();

  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                <p className={classes.cardTitleWhite}>Direct Quote By LC</p>
                <IconButton color='inherit'><ArrowBackIcon
                  style={{ marginLeft: 5 }}
                  onClick={() => history.goBack()}
                ></ArrowBackIcon></IconButton>
              </CardHeader>
              <CardBody>
                <ViewInvoice />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default ViewpaidInvoicePage;
