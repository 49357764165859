import { v4 as uuidv4 } from "uuid";

import Dashboard from "pages/cash-client/Dashboard";
import BillingAccount from "pages/cash-client/BillingAccount";
import CommunicationMenu from "pages/cash-client/Communication/Communication";
import CreateTicket from "pages/cash-client/Communication/CreateTicket";
import OpenTicket from "pages/cash-client/Communication/TicketList";
import ViewTicket from "pages/cash-client/Communication/TicketList";
import RateCardPage from "pages/cash-client/RateCardPage";
import BusinessProfilePage from "pages/cash-client/BusinessProfilePage";
import BookingHistory from "pages/cash-client/BookingHistory";
import FeedbackPage from "pages/cash-client/FeedbackPage";
import JobAdvertPage from "pages/cash-client/JobAdvert";

import BookInterpreter from "pages/cash-client/BookInterpreter";
import ViewCashclientInterpreting from "pages/cash-client/Assignment/ViewInterpretingDetails";
import ViewCashclientTranslation from "pages/cash-client/Assignment/ViewTranslationDetails";
import ViewCashclientQuotation from "pages/cash-client/Assignment/ViewQuotationDetails";

// view document
import ViewInterpretingDocument from "pages/cash-client/Assignment/ViewInterpretingDocument";
import ViewTranslationDocument from "pages/cash-client/Assignment/ViewTranslationDocument";
import ViewQuotationDocument from "pages/cash-client/Assignment/ViewQuotationDocument";

import OrderTranslation from "pages/cash-client/OrderTranslation";
import QuoteRequest from "pages/cash-client/QuoteRequest";
import ProfilePage from "pages/cash-client/ProfilePage";
import Report from "pages/cash-client/Report";
import ResetPassword from "pages/cash-client/ResetPassword";
import BillingAccountOpeningForm from "pages/cash-client/BAOForm";
import CarpCard from "pages/cash-client/CarpCardView";
import InvoicePage from "pages/cash-client/Invoices";
import SetNewPassword from "pages/cash-client/SetNewPassword";
import ServiceAgreement from "pages/cash-client/ServiceAgreement";
import BookingSecratery from "../pages/cash-client/CACBookingSecretary.jsx";
//Icons
import BPicon from "assets/icons/business-profile.png";
import CMicon from "assets/icons/communication-menuAC.png";
import Dicon from "assets/icons/dashboardAC.png";
import BAOicon from "assets/icons/billing-account-opening.png";
import JAicon from "assets/icons/job-advert.png";
import BHicon from "assets/icons/booking-history.png";
import Bicon from "assets/icons/monthly-billAC.png";
import Ficon from "assets/icons/feedback.png";
import Ricon from "assets/icons/report.png";
import VRicon from "assets/icons/view-rate-card.png";
import VCicon from "assets/icons/view-carp-card.png";
import Iicon from "assets/icons/invoice.png";
import Help from "pages/cash-client/Help";
import NTMicon from "assets/icons/my noteto do list.png";
import ActivityLogIcon from "assets/icons/activitylog.png";
//booking secretary
import UAicon from "assets/icons/unconfirmed assignments-01.png";
import UQicon from "assets/icons/unconfirmed-quote.png";
import UCAicon from "assets/icons/confirmed asssignments.png";
import UCQicon from "assets/icons/confirmed quotation.png";

import ConfirmAssignments from "pages/cash-client/Assignment/confirmAssignments/ConfirmAssignments";
import ConfirmedInterpreters from "pages/cash-client/Assignment/confirmAssignments/ConfirmedInterpreters";
import ConfirmedTranslations from "pages/cash-client/Assignment/confirmAssignments/ConfirmedTranslations";

import UnconfirmedAssignments from "pages/cash-client/Assignment/unconfirmAssignments/unconfirmAssignments";
import UnconfirmedInterpreters from "pages/cash-client/Assignment/unconfirmAssignments/UnconfirmedInterpreters";
import UnconfirmedTranslations from "pages/cash-client/Assignment/unconfirmAssignments/UnconfirmedTranslations";
import UnconfirmedQuotations from "pages/cash-client/Assignment/unconfirmAssignments/UnconfirmedQuotations";

import CloseTimesheet from "pages/cash-client/CloseTimesheet";
import PaidInvoice from "pages/cash-client/PaidInvoice";
import UnpaidInvoice from "pages/cash-client/UnpaidInvoice";
import ConfirmQuotationPage from "pages/cash-client/Assignment/confirmAssignments/confirmQuotations";
import ViewUnconfirmQuotationPage from "pages/cash-client/Assignment/ViewUnconfirmQuotation";
import ViewUnconfirmDirectQuote from "pages/cash-client/Assignment/ViewUnconfirmDirectQuote";
import UnconfirmQuotationPay from "pages/cash-client/Assignment/unconfirmAssignments/UnconfirmQuotationPay";

import InvoicePaySuccess from "pages/cash-client/InvoicePaySuccess.jsx";
import BISuccessPage from "components/Auth/LinguistsCollectiveBooking/BISuccessPage.jsx";
import CloseTransJobPage from "pages/cash-client/CloseTransJob.jsx";
import ViewPaidInvoicePage from "pages/cash-client/Invoices/ViewPaidInvoicePage";
import ViewUnpaidInvoiceTransPage from "pages/cash-client/Invoices/ViewUnpaidInvoiceTransPage";
import BISuccessTransPage from "components/Auth/LinguistsCollective/BISuccessPage.jsx";

// amend job
import amendInterpreting from "pages/cash-client/Assignment/AmendInterpreting";

// payment interpreting invoice
import ViewUnpaidInvoicePage from "pages/cash-client/Invoices/ViewUnpaidInvoicePage";
import PayDirectClientInv from "pages/cash-client/Invoices/PayDirectClientInv";
import SuccessCACBookingINV from "components/CashClient/Invoices/ViewUnpaidInvoice/SucessCACBookingINV";
import SuccessCACTransINV from "components/CashClient/Invoices/ViewUnpaidInvoice/SucessCACTransINV";
import SuccessCACQuote from "components/CashClient/Invoices/ViewUnpaidInvoice/SuccessCACQuote";
import SuccessDirectInv from "components/CashClient/Invoices/ViewUnpaidInvoice/SuccessDirectInv";
import SuccessDirectQuote from "components/CashClient/Invoices/ViewUnpaidInvoice/SuccessDirectQuote";

// create or confirmed timesheet for linguist
import TimeSheetPage from "pages/cash-client/TimeSheetPage";
// create or confirmed project report for linguist
import ProjectReportPage from "pages/cash-client/ProjectReportPage";
import ProjectReportCreate from "pages/cash-client/ProjectReportCreate";
import CreateCACQuoteReport from "pages/cash-client/CreateCACQuoteReport";
import ConfirmCACQuoteReport from "pages/cash-client/ConfirmCACQuoteReport";
import ViewCACQuoteReport from "pages/cash-client/Invoices/ViewCACQuoteReport";
import ViewCACQuoteINV from "pages/cash-client/Invoices/ViewCACQuoteINV";
import ViewCACBookingINV from "pages/cash-client/Invoices/ViewCACBookingINV";
import ViewCACTransINV from "pages/cash-client/Invoices/ViewCACTransINV";
import ViewCACDirectINV from "pages/cash-client/Invoices/ViewCACDirectINV";
import ViewCACBookingTimesheet from "pages/cash-client/Invoices/ViewCACBookingTimesheet";
import AmendTimesheet from "pages/cash-client/Invoices/AmendTimesheet";
import ViewTransProjectReport from "pages/cash-client/Invoices/ViewTransProjectReport";
import AmendProjectReport from "pages/cash-client/Invoices/AmendProjectReport";

// payment routes
import CACStripePayment from "components/Auth/LinguistsCollectiveBooking/StripePayment";
import SuccessCACStripe from "components/Auth/LinguistsCollectiveBooking/SuccessCACStripe";

import CACPayTrans from "components/Auth/LinguistsCollective/StripePayment";
import SuccessCACTransStripe from "components/Auth/LinguistsCollective/SuccessCACStripe";

import MyNotes from "pages/cash-client/MyNote/MyNotes";
import Notifications from "pages/cash-client/Notifications/Notifications";

import ViewAttachConversation from "pages/cash-client/Communication/ViewAttachConversation";
import ViewAttachTicket from "pages/cash-client/Communication/ViewAttachTicket";

import ViewDocument from "components/CashClient/profile/ProfileUpdate/Documents/ViewDocument";

import RecommendLC from "pages/cash-client/RecommendLC/RecommendLCForm";
import ActivityLogs from "pages/cash-client/ActivityLogs/ActivityLogs";

import ViewProjectReportDocument from "pages/cash-client/Invoices/ViewProjectReportDocument";
import ViewTimesheetDocument from "pages/cash-client/Invoices/ViewTimesheetDocument";

import TranslationDeliveryDoc from "pages/Component/Translation_Delivery/CAC/ViewDocument";
import ViewAttachMessage from "pages/cash-client/WelcomeMessage/ViewAttachMessage";


const CASH_CLIENT = [
  {
    id: uuidv4(),
    text: "Dashboard",
    subText: "Cash Client Dashboard",
    path: "cash-client/dashboard",
    type: "dashboard",
    component: Dashboard,
    icon: Dicon,
  },

  {
    id: uuidv4(),
    path: "cashclient/view-and-pay-unconfirmed-quotations/quotation/:id",
    component: ViewUnconfirmQuotationPage,
  },

  {
    id: uuidv4(),
    path: "direct-quote-from-lc-accept/:id",
    component: ViewUnconfirmDirectQuote,
  },

  {
    id: uuidv4(),
    text: "Assignments",
    path: "cash-client/assignments",
    icon: Bicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Confirmed Assignments",
        component: ConfirmAssignments,
        path: "cash-client/confirmed-assignments",
        icon: UCAicon,
      },
      {
        id: uuidv4(),
        text: "Confirmed Quotations",
        path: "cash-client/confirmed-quotations",
        component: ConfirmQuotationPage,
        icon: UCQicon,
      },

      {
        id: uuidv4(),
        text: "Unconfirmed Assignments",
        path: "cash-client/unconfirm-assignments",
        component: UnconfirmedAssignments,
        icon: UAicon,
      },
      {
        id: uuidv4(),
        text: "Unconfirmed Quotations",
        path: "cashclient/unconfirmed-quotations",
        component: UnconfirmedQuotations,
        icon: UQicon,
      },
    ],
  },
  {
    id: uuidv4(),
    path: "cashclient/unconfirmed-quotations/:data/:type",
    component: UnconfirmedQuotations,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-welcome-message-attachment/:id/:message",
    component: ViewAttachMessage,
  },
  {
    id: uuidv4(),
    path: "cash-client/help",
    component: Help,
  },
  {
    id: uuidv4(),
    path: "cash-client/book-interpreter",
    type: "form",
    component: BookInterpreter,
  },
  {
    id: uuidv4(),
    path: "cash-client/reset-password",
    component: ResetPassword,
  },
  {
    id: uuidv4(),
    path: "cash-client/service-agreement-of-cash-client",
    component: ServiceAgreement,
  },
  {
    id: uuidv4(),
    path: "cashclient/auth/password/reset/:token",
    component: SetNewPassword,
  },
  {
    id: uuidv4(),
    path: "cash-client/order-translation",
    type: "form",
    component: OrderTranslation,
  },
  {
    id: uuidv4(),
    type: "form",
    path: "cash-client/quote-request",
    component: QuoteRequest,
  },
  {
    id: uuidv4(),
    text: "Communications",
    path: "cash-client/communication",
    component: CommunicationMenu,
    icon: CMicon,
  },
  {
    id: uuidv4(),
    text: "Business Profile",
    path: "cash-client/business-profile",
    component: BusinessProfilePage,
    icon: BPicon,
  },
  {
    id: uuidv4(),
    text: "Assignment History",
    path: "cash-client/assignment-history",
    component: BookingHistory,
    icon: BHicon,
  },
  {
    id: uuidv4(),
    text: "Feedback",
    path: "cash-client/feedback",
    component: FeedbackPage,
    icon: Ficon,
  },
  {
    id: uuidv4(),
    type: "form",
    text: "Job Advert",
    subText: "Job Advert",
    path: `cash-client/jobAdvert-form`,
    component: JobAdvertPage,
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: PaidInvoice,
    path: "cash-client/invoices-list/paid-invoices",
  },
  {
    id: uuidv4(),
    component: UnpaidInvoice,
    path: "cash-client/invoices-list/unpaid-invoices",
  },
  {
    id: uuidv4(),
    component: ViewUnpaidInvoicePage,
    path: "cash-client/invoices-list/unpaid-invoices/view-invoice/:id",
  },
  {
    id: uuidv4(),
    component: PayDirectClientInv,
    path: "pay-direct-invoice/:id",
  },
  // paid invoice success page
  {
    id: uuidv4(),
    path: "cash-client/interpreter/success/invoice/payment/:invoiceId/:id", //CAC interpreting Invoice Pay Success Page
    component: SuccessCACBookingINV,
  },
  {
    id: uuidv4(),
    path: "cash-client/translation/success/invoice/payment/:invoiceId/:id", //CAC translation Invoice Pay Success Page
    component: SuccessCACTransINV,
  },
  {
    id: uuidv4(),
    path: "cash-client/quotation/success/quote/payment/:invoiceId/:id", //CAC interpreting Invoice Pay Success Page
    component: SuccessCACQuote,
  },
  {
    id: uuidv4(),
    path: "pay-direct-invoice/success/invoice/payment/:invoiceId/:id", //CAC interpreting Invoice Pay Success Page
    component: SuccessDirectInv,
  },

  {
    id: uuidv4(),
    path: "pay-direct-quotation/success/invoice/payment/:invoiceId/:id", //CAC interpreting Invoice Pay Success Page
    component: SuccessDirectQuote,
  },

  {
    id: uuidv4(),
    component: ViewUnpaidInvoiceTransPage,
    path: "cash-client/invoices-list/unpaid-invoices/view-unpaid-invoice/translation/:id",
  },
  {
    id: uuidv4(),
    component: ViewPaidInvoicePage,
    path: "cash-client/invoices-list/paid-invoices/view-paid-invoice/:id",
  },
  {
    id: uuidv4(),
    component: BookingSecratery,
    path: "cash-client/booking-secratery",
  },
  {
    id: uuidv4(),
    text: "Notes And Do List",
    path: "cash-client/my-notes",
    component: MyNotes,
    icon: NTMicon,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-all-notifications",
    component: Notifications,
  },
  {
    id: uuidv4(),
    text: "Accounts & payments",
    path: "cash-client/accounts-and-payments",
    component: BillingAccount,
    icon: Bicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Invoices",
        component: InvoicePage,
        path: "cash-client/invoices-list",
        icon: Iicon,
      },

      {
        id: uuidv4(),
        text: "View Rate Card",
        path: "cash-client/view-rate-card",
        component: RateCardPage,
        icon: VRicon,
      },
      {
        id: uuidv4(),
        text: "View Carp Card",
        path: "cash-client/view-carp-card",
        component: CarpCard,
        icon: VCicon,
      },

      {
        id: uuidv4(),
        text: "Managment Report",
        component: Report,
        path: "cash-client/report",
        icon: Ricon,
      },
      {
        id: uuidv4(),
        text: "Billing Account Opening Form",
        path: "cashclient/billing-account-opening-form",
        component: BillingAccountOpeningForm,
        icon: BAOicon,
      },
    ],
  },
  {
    id: uuidv4(),
    text: "Recommend LC",
    path: "cash-client/recommend-lc-cac",
    component: RecommendLC,
    icon: NTMicon,
  },
  {
    id: uuidv4(),
    text: "Activity Logs",
    path: "cash-client/activity-logs",
    component: ActivityLogs,
    icon: ActivityLogIcon,
  },

  {
    id: uuidv4(),
    path: "cash-client/view-cash-client-booking-interpreting/:id",
    component: ViewCashclientInterpreting,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-cash-client-order-translation/:id",
    component: ViewCashclientTranslation,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-cash-client-quotation/:id",
    component: ViewCashclientQuotation,
  },

  // view document
  {
    id: uuidv4(),
    path: "cash-client/view-interpreting-document/:id",
    component: ViewInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-translation-document/:id",
    component: ViewTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-quotation-document/:id",
    component: ViewQuotationDocument,
  },

  {
    id: uuidv4(),
    path: "cash-client/profile",
    component: ProfilePage,
  },

  {
    id: uuidv4(),
    path: "cashclient/confirmed-interpreters",
    component: ConfirmedInterpreters,
  },

  {
    id: uuidv4(),
    path: "cash-client/confirm-close-and-timesheet/:id",
    component: CloseTimesheet,
  },
  {
    id: uuidv4(),
    path: "cashclient/confirmed-translations",
    component: ConfirmedTranslations,
  },

  {
    id: uuidv4(),
    path: "cashclient/unconfirmed-interpreters",
    component: UnconfirmedInterpreters,
  },

  {
    id: uuidv4(),
    path: "cashclient/unconfirmed-translations",
    component: UnconfirmedTranslations,
  },
  {
    id: uuidv4(),
    path: "cashclient/confirmed-translations/:data/:type",
    component: ConfirmedTranslations,
  },
  {
    id: uuidv4(),
    path: "cashclient/confirmed-interpreters/:data/:type",
    component: ConfirmedInterpreters,
  },
  {
    id: uuidv4(),
    path: "cashclient/unconfirmed-interpreters/:data/:type",
    component: UnconfirmedInterpreters,
  },

  {
    id: uuidv4(),
    path: "cashclient/unconfirmed-translations/:data/:type",
    component: UnconfirmedTranslations,
  },

  {
    id: uuidv4(),
    path: "cashclient/unconfirmed-quotations/quotation/payment/successful/:id/success",
    component: UnconfirmQuotationPay,
  },
  {
    id: uuidv4(),
    path: "cashclient/unconfirmed-quotations/quotation/payment/successful/:id/success",
    component: UnconfirmQuotationPay,
  },
  {
    id: uuidv4(),
    path: "cashclient/invoice/payment/success/:id",
    component: InvoicePaySuccess,
  },
  {
    id: uuidv4(),
    path: "cashclient/estimatepay/success/:id",
    component: BISuccessPage,
  },
  {
    id: uuidv4(),
    path: "cashclient/translation/estimatepay/success/:id",
    component: BISuccessTransPage,
  },
  {
    id: uuidv4(),
    path: "cashclient/closed-assignments/translation-booking/close-and-timesheet/:id",
    component: CloseTransJobPage,
  },

  {
    id: uuidv4(),
    path: "cash-client/amend-job/:id",
    component: amendInterpreting,
  },

  // create or confirmed timesheet for linguist
  {
    id: uuidv4(),
    component: TimeSheetPage,
    path: "cash-client/create-close-and-timesheet/:id",
  },
  // create or confirmed project report for linguist
  {
    id: uuidv4(),
    component: ProjectReportCreate,
    path: "cash-client/create-project-report/:id",
  },
  {
    id: uuidv4(),
    component: ProjectReportPage,
    path: "cash-client/accept-project-report/:id",
  },
  {
    id: uuidv4(),
    component: ViewTransProjectReport,
    path: "cash-client/view-project-report/:id",
  },
  {
    id: uuidv4(),
    component: AmendProjectReport,
    path: "cash-client/amend-project-report/:id",
  },

  {
    id: uuidv4(),
    path: "cashclient/confirmed-quotations/cac/quotation/create-project-report/:id",
    component: CreateCACQuoteReport,
  },

  {
    id: uuidv4(),
    path: "cash-client/confirmed-quotations/cac/quotation/view-project-report/:id",
    component: ViewCACQuoteReport,
  },
  {
    id: uuidv4(),
    path: "cashclient/confirmed-quotations/cac/quotation/confirm-project-report/:id",
    component: ConfirmCACQuoteReport,
  },
  {
    id: uuidv4(),
    path: "cash-client/confirmed-quotations/cac/quotation/view-invoice/:id",
    component: ViewCACQuoteINV,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-invoice/:id",
    component: ViewCACBookingINV,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-timesheet/:id",
    component: ViewCACBookingTimesheet,
  },
  {
    id: uuidv4(),
    path: "cash-client/amend-timesheet/:id",
    component: AmendTimesheet,
  },

  {
    id: uuidv4(),
    path: "cashclient/confirmed-translation/view-invoice/:id",
    component: ViewCACTransINV,
  },

  {
    id: uuidv4(),
    path: "cash-client/view-direct-invoice/:id",
    component: ViewCACDirectINV,
  },

  {
    id: uuidv4(),
    path: "cash-client/open-new-ticket",
    component: CreateTicket,
  },

  {
    id: uuidv4(),
    path: "cash-client/view-tickets",
    component: OpenTicket,
  },

  {
    id: uuidv4(),
    path: "cash-client/view-ticket-details/:id",
    component: ViewTicket,
  },

  // payment routes
  {
    id: uuidv4(),
    path: "cac-interpreter-booking/client/summary/estimate/payment", //Cashclient estimate booking interpreter
    component: CACStripePayment,
  },
  {
    id: uuidv4(),
    path: "cash-client/interpreter/success/estimate/payment/:id", //CAC Booking Interpreter Send Mail
    component: SuccessCACStripe,
  },
  {
    id: uuidv4(),
    path: "cac-translation-order/client/summary/estimate/payment", //Cashclient estimate order translation
    component: CACPayTrans,
  },
  {
    id: uuidv4(),
    path: "cash-client/translation/success/estimate/payment/:id", //CAC translation order Send Mail
    component: SuccessCACTransStripe,
  },

  {
    id: uuidv4(),
    path: "cash-client/view-attachment-ticket-conversation/:id",
    component: ViewAttachConversation,
  },

  {
    id: uuidv4(),
    path: "cash-client/view-ticket-attachment/:id",
    component: ViewAttachTicket,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-document/:id",
    component: ViewDocument,
  },

  // timesheet and project report documents view routes
  {
    id: uuidv4(),
    path: "cash-client/view-timesheet-document-by-linguist/:id",
    component: ViewTimesheetDocument,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-timesheet-document-by-client/:id",
    component: ViewTimesheetDocument,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-project-report-document-by-linguist/:id",
    component: ViewProjectReportDocument,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-project-report-document-by-client/:id",
    component: ViewProjectReportDocument,
  },
  {
    id: uuidv4(),
    path: "cash-client/view-project-report-document-by-admin/:id",
    component: ViewProjectReportDocument,
  },

  // view translation delivery document
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "cash-client/view-translation-delivery-document1/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "cash-client/view-translation-delivery-document2/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "cash-client/view-translation-delivery-document3/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "cash-client/view-translation-delivery-document4/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "cash-client/view-translation-delivery-document5/:id",
  },
];

export default CASH_CLIENT;
