import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import history from "utils/history";
import { Button, Dimmer, Header, Loader, Progress } from "semantic-ui-react";
import swal from "sweetalert";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import {
  changeInput,
  updateProfileLinguistPageFour,
} from "redux/actions/lcAction";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import { useToasts } from "react-toast-notifications";

import {
  INTERPRETING_QUALIFICATION,
  LANG_COMB,
  QUOTE_REQUEST_FOR,
  TRANSLATION_QUALIFICATION,
  PRIMARY_TARGET_LANG,
  PROFESSIONAL_MEMBERSHIP,
  OTHER_LANG_TEST,
} from "data";

import CustomAutoComplete from "components/Common/CustomAutoComplete";
import CustomAutoSelect from "components/Common/CustomAutoSelect";

import Axios from "axios";
import Cookies from "js-cookie";

const PageFour = ({ setReload }) => {
  const { data } = useSelector((state) => state.lcReducer);
  const dispatch = useDispatch();
  const token = Cookies.get("LINtoken");
  const [timeCount, setTimeCount] = useState([1]);
  const [loader, setLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [linDetails, setLinDetails] = useState(false);
  const { addToast } = useToasts();
  const [interpreting_document, setInterpretingDocument] = useState(null);
  const [translation_document, setTranslationDocument] = useState(null);
  const [psi_pst_document, setPstPsiDocument] = useState(null);
  const [other_document, setOtherDocument] = useState(null);
  const [interpretingProgress, setInterpretingProgress] = useState(0);
  const [translationProgress, setTranslationProgress] = useState(0);
  const [psiProgress, setPsiProgress] = useState(0);
  const [otherProgress, setOtherProgress] = useState(0);
  const [next, setNext] = useState(false);

  const getLinguistDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/get-details-page4`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        updateProfileLinguistPageFour({
          mother_tongue: response?.data.mother_tongue,
          source_language: response?.data.source_language.toString(),
          source_language_1: response?.data.source_language_1.toString(),
          source_language_2: response?.data.source_language_2.toString(),
          target_language: response?.data.target_language.toString(),
          target_language_1: response?.data.target_language_1.toString(),
          target_language_2: response?.data.target_language_2.toString(),
          language_combinations: response?.data.language_combinations,
          ticking_relevant: response?.data.ticking_relevant,
          interpreting_qualification: response?.data.interpreting_qualification,
          translation_qualification: response?.data.translation_qualification,
          other_language_test: response?.data.other_language_test,
          total_hours_of_proven: response?.data.total_hours_of_proven,
          total_days_words_of_proven: response?.data.total_days_words_of_proven,
          professional_qualification: response?.data.professional_qualification,
          professional_membership: response?.data.professional_membership,
          professional_membership_number_level:
            response?.data.professional_membership_number_level,
        })
      );
      setLoader(false);
      setLinDetails(response?.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
      setLoader(false);
    }
  };

  const [viewInterpreting, setViewInterpreting] = useState(null);
  const InterpretingDoc = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-interpreting-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewInterpreting(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  const [viewTranslation, setViewTranslation] = useState(null);
  const TranslationDoc = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-translation-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewTranslation(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  const [viewPsiPst, setViewPsiPst] = useState(null);
  const PsiPstDoc = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-psipst-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewPsiPst(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  const [viewOtherExp, setViewOtherExp] = useState(null);
  const OtherExpDoc = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-other-experience-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewOtherExp(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  useEffect(() => {
    getLinguistDetails();
    InterpretingDoc();
    TranslationDoc();
    PsiPstDoc();
    OtherExpDoc();
  }, []);

  const {
    target_language,
    target_language_1,
    target_language_2,
    source_language,
    source_language_1,
    source_language_2,
    language_combinations,
    ticking_relevant,
    interpreting_qualification,
    translation_qualification,
    other_language_test,
    total_hours_of_proven,
    total_days_words_of_proven,
    professional_qualification,
    professional_membership,
    professional_membership_number_level,
  } = data;

  const classes = useFormStyles();

  const handleDoc1 = async (e) => {
    if (e.target?.files[0]?.size > 2000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 2mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      setInterpretingDocument(e.target.files[0]);
      uploadInterpreting(e.target.files[0]);
    }
  };

  const uploadInterpreting = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("interpreting_document", file);
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-interpreting-file`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setInterpretingProgress(
              Math.round((100 * data.loaded) / data.total)
            );
          },
        }
      );
      InterpretingDoc();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleDoc2 = async (e) => {
    if (e.target?.files[0]?.size > 2000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 2mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      setTranslationDocument(e.target.files[0]);
      uploadTranslation(e.target.files[0]);
    }
  };

  const uploadTranslation = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("translation_document", file);
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-translation-file`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setTranslationProgress(
              Math.round((100 * data.loaded) / data.total)
            );
          },
        }
      );
      TranslationDoc();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleDoc3 = async (e) => {
    if (e.target?.files[0]?.size > 1000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 1mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      setPstPsiDocument(e.target.files[0]);
      uploadPstPsi(e.target.files[0]);
    }
  };

  const uploadPstPsi = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("psi_pst_document", file);
    }

    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-psi-pst-file`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setPsiProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      PsiPstDoc();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleDoc4 = async (e) => {
    if (e.target?.files[0]?.size > 1000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 1mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      setOtherDocument(e.target.files[0]);
      uploadOther(e.target.files[0]);
    }
  };

  const uploadOther = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("other_document", file);
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-other-file`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setOtherProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      OtherExpDoc();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaveLoader(true);
    let fd = new FormData();

    fd.append("mother_tongue", data.mother_tongue);
    fd.append("source_language", data.source_language);
    fd.append("source_language_1", data.source_language_1 || []);
    fd.append("source_language_2", data.source_language_2 || []);
    fd.append("target_language", data.target_language);
    fd.append("target_language_1", data.target_language_1 || []);
    fd.append("target_language_2", data.target_language_2 || []);
    fd.append("language_combinations", data.language_combinations || []);
    fd.append("ticking_relevant", data.ticking_relevant || []);
    fd.append(
      "interpreting_qualification",
      data.interpreting_qualification || []
    );
    fd.append(
      "translation_qualification",
      data.translation_qualification || []
    );
    fd.append("other_language_test", data.other_language_test || []);
    fd.append("total_hours_of_proven", data.total_hours_of_proven || "");
    fd.append(
      "total_days_words_of_proven",
      data.total_days_words_of_proven || ""
    );
    fd.append(
      "professional_qualification",
      data.professional_qualification || ""
    );
    fd.append("professional_membership", data.professional_membership || []);
    fd.append(
      "professional_membership_number_level",
      data.professional_membership_number_level || ""
    );

    // if (interpreting_document?.size) {
    //   fd.append("interpreting_document", interpreting_document);
    // }
    // if (translation_document?.size) {
    //   fd.append("translation_document", translation_document);
    // }
    // if (psi_pst_document?.size) {
    //   fd.append("psi_pst_document", psi_pst_document);
    // }
    // if (other_document?.size) {
    //   fd.append("other_document", other_document);
    // }

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-full-registration`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((val) => {
        setReload(Math.random());
      });
      setSaveLoader(false);
      setNext(true);
    } catch (error) {
      console.log(error.response);
      setSaveLoader(false);
    }
  };

  const handleBack = () => {
    history.push("/linguist/full-registration-p3");
  };
  const handleNext = () => {
    history.push("/linguist/full-registration-p5");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInput({
        name,
        value,
      })
    );
  };

  const handleAutoCompleteLC = (e, value) => {
    dispatch(
      changeInput({
        name: "language_combinations",
        value,
      })
    );
  };

  const handleAutoCompleteTR = (e, value) => {
    dispatch(
      changeInput({
        name: "ticking_relevant",
        value,
      })
    );
  };

  const handleAutoCompletePM = (e, value) => {
    dispatch(
      changeInput({
        name: "professional_membership",
        value,
      })
    );
  };

  const handleChangeSO = (e, value) => {
    dispatch(
      changeInput({
        name: "source_language",
        value,
      })
    );
  };
  const handleChangeSO1 = (e, value) => {
    dispatch(
      changeInput({
        name: "source_language_1",
        value,
      })
    );
  };
  const handleChangeSO2 = (e, value) => {
    dispatch(
      changeInput({
        name: "source_language_2",
        value,
      })
    );
  };

  const handleChangeTA = (e, value) => {
    dispatch(
      changeInput({
        name: "target_language",
        value,
      })
    );
  };

  const handleChangeTA1 = (e, value) => {
    dispatch(
      changeInput({
        name: "target_language_1",
        value,
      })
    );
  };

  const handleChangeTA2 = (e, value) => {
    dispatch(
      changeInput({
        name: "target_language_2",
        value,
      })
    );
  };

  const handleChangeIQ = (e, value) => {
    dispatch(
      changeInput({
        name: "interpreting_qualification",
        value,
      })
    );
  };

  const handleChangeTQ = (e, value) => {
    dispatch(
      changeInput({
        name: "translation_qualification",
        value,
      })
    );
  };

  const handleChangeOLT = (e, value) => {
    dispatch(
      changeInput({
        name: "other_language_test",
        value,
      })
    );
  };

  const isAllow = !source_language || !target_language;

  if (loader) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomAutoSelect
          onChange={handleChangeSO}
          width="100%"
          label="Mother tongue / Source / Native languages"
          name="source_language"
          value={source_language}
          data={PRIMARY_TARGET_LANG.filter(
            (value) =>
              value !== target_language &&
              value !== target_language_1 &&
              value !== target_language_2 &&
              value !== source_language_1 &&
              value !== source_language_2
          )}
          required
        />

        <CustomAutoSelect
          onChange={handleChangeSO1}
          width="100%"
          label="Mother tongue / Source / Native languages"
          name="source_language_1"
          value={source_language_1}
          data={PRIMARY_TARGET_LANG.filter(
            (value) =>
              value !== target_language &&
              value !== target_language_1 &&
              value !== target_language_2 &&
              value !== source_language &&
              value !== source_language_2
          )}
        />

        <CustomAutoSelect
          onChange={handleChangeSO2}
          width="100%"
          label="Mother tongue / Source / Native languages"
          name="source_language_2"
          value={source_language_2}
          data={PRIMARY_TARGET_LANG.filter(
            (value) =>
              value !== target_language &&
              value !== target_language_1 &&
              value !== target_language_2 &&
              value !== source_language_1 &&
              value !== source_language
          )}
        />

        <CustomAutoSelect
          onChange={handleChangeTA}
          width="100%"
          label="Target languages"
          name="target_language"
          value={target_language}
          data={PRIMARY_TARGET_LANG.filter(
            (value) =>
              value !== source_language &&
              value !== target_language_1 &&
              value !== target_language_2 &&
              value !== source_language_1 &&
              value !== source_language_2
          )}
          required
        />

        <CustomAutoSelect
          onChange={handleChangeTA1}
          width="100%"
          label="Target languages"
          name="target_language_1"
          value={target_language_1}
          data={PRIMARY_TARGET_LANG.filter(
            (value) =>
              value !== target_language &&
              value !== source_language &&
              value !== target_language_2 &&
              value !== source_language_1 &&
              value !== source_language_2
          )}
        />

        <CustomAutoSelect
          onChange={handleChangeTA2}
          width="100%"
          label="Target languages"
          name="target_language_2"
          value={target_language_2}
          data={PRIMARY_TARGET_LANG.filter(
            (value) =>
              value !== target_language &&
              value !== target_language_1 &&
              value !== source_language &&
              value !== source_language_1 &&
              value !== source_language_2
          )}
        />

        <CustomAutoComplete
          onChange={handleAutoCompleteLC}
          width="100%"
          label="List all other language combinations (max 5)"
          name="language_combinations"
          value={language_combinations}
          data={LANG_COMB}
        />
        <CustomAutoComplete
          onChange={handleAutoCompleteTR}
          width="100%"
          label="Please subscribe by ticking relevant boxes"
          moreInfo="For the service/s that you wish to provide (subject to approval)"
          name="ticking_relevant"
          value={ticking_relevant}
          data={QUOTE_REQUEST_FOR}
        />
        <CustomAutoSelect
          onChange={handleChangeIQ}
          width="100%"
          label="Interpreting qualification (s)"
          name="interpreting_qualification"
          value={interpreting_qualification}
          data={INTERPRETING_QUALIFICATION}
        />
        <CustomAutoSelect
          onChange={handleChangeTQ}
          width="100%"
          label="Translation qualification (s)"
          name="translation_qualification"
          value={translation_qualification}
          data={TRANSLATION_QUALIFICATION}
        />
        <CustomAutoSelect
          onChange={handleChangeOLT}
          width="100%"
          label="Other language test, qualification and or CPD(s)"
          name="other_language_test"
          value={other_language_test}
          data={OTHER_LANG_TEST}
        />
        <CustomBigInputSingle
          onChange={handleChange}
          width="100%"
          label="Total hours of proven PSI experience"
          moreInfo="Public service interpreting (Please attach your evidence)"
          name="total_hours_of_proven"
          value={total_hours_of_proven}
        />
        <CustomBigInputSingle
          onChange={handleChange}
          width="100%"
          label="Job Total days of proven PST work experience"
          moreInfo="Public service translation (Please attach your evidence)"
          name="total_days_words_of_proven"
          value={total_days_words_of_proven}
        />
        <CustomBigInputSingle
          onChange={handleChange}
          width="100%"
          label="Please list any other professional qualification/s
           and experience/s"
          moreInfo="content writing / editing / teaching / training / general practice, doctor / lawyer / business consultancy"
          name="professional_qualification"
          value={professional_qualification}
        />
        <CustomAutoComplete
          onChange={handleAutoCompletePM}
          width="100%"
          label="Professional membership"
          name="professional_membership"
          value={professional_membership}
          data={PROFESSIONAL_MEMBERSHIP}
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Professional membership number and level"
          name="professional_membership_number_level"
          value={professional_membership_number_level}
        />

        <GridContainer
          justify="center"
          className={classes.container}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card>
              <CardBody>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "24px",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Header as="h4">
                      Upload interpreting Qualifications (max 2mb)
                    </Header>
                    <Header as="h4">
                      <input
                        type="file"
                        name="interpreting_document"
                        onChange={handleDoc1}
                        accept="application/pdf"
                      />
                    </Header>
                    {interpreting_document?.name && (
                      <div>
                        <Header
                          style={{ margin: "0" }}
                          as="h5"
                        >{`${interpreting_document?.lastModifiedDate?.toDateString()}`}</Header>
                        <Header style={{ margin: "0" }} as="h5">{`${(
                          interpreting_document?.size / 1024
                        ).toFixed(2)}kb`}</Header>
                      </div>
                    )}

                    <Header as="h4">
                      {viewInterpreting?.interpreting_document_true && (
                        <a
                          href={`/linguist/linguist-view-interpreting-document/${viewInterpreting?._id}`}
                        >
                          View document
                        </a>
                      )}
                    </Header>
                  </div>
                </div>
                {interpretingProgress > 0 && (
                  <Progress
                    percent={interpretingProgress}
                    indicating
                    autoSuccess
                    progress
                  >
                    Document uploaded
                  </Progress>
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card>
              <CardBody>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "24px",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Header as="h4">
                      Upload Translation Qualifications (max 2mb)
                    </Header>
                    <Header as="h4">
                      <input
                        type="file"
                        name="translation_document"
                        onChange={handleDoc2}
                        accept="application/pdf"
                      />
                    </Header>
                    {translation_document?.name && (
                      <div>
                        <Header
                          style={{ margin: "0" }}
                          as="h5"
                        >{`${translation_document?.lastModifiedDate?.toDateString()}`}</Header>
                        <Header style={{ margin: "0" }} as="h5">{`${(
                          translation_document?.size / 1024
                        ).toFixed(2)}kb`}</Header>
                      </div>
                    )}

                    <Header as="h4">
                      {viewTranslation?.translation_document_true && (
                        <a
                          href={`/linguist/linguist-view-translation-document/${viewTranslation?._id}`}
                        >
                          View document
                        </a>
                      )}
                    </Header>
                  </div>
                </div>
                {translationProgress > 0 && (
                  <Progress
                    percent={translationProgress}
                    indicating
                    autoSuccess
                    progress
                  >
                    {" "}
                    Document uploaded
                  </Progress>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer
          justify="center"
          className={classes.container}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card>
              <CardBody>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "24px",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Header as="h4">
                      Upload PSI / PST experience reference letter (max 1mb)
                    </Header>
                    <Header as="h4">
                      <input
                        type="file"
                        name="psi_pst_document"
                        onChange={handleDoc3}
                        accept="application/pdf"
                      />
                    </Header>
                    {psi_pst_document?.name && (
                      <div>
                        <Header
                          style={{ margin: "0" }}
                          as="h5"
                        >{`${psi_pst_document?.lastModifiedDate?.toDateString()}`}</Header>
                        <Header style={{ margin: "0" }} as="h5">{`${(
                          psi_pst_document?.size / 1024
                        ).toFixed(2)}kb`}</Header>
                      </div>
                    )}

                    <Header as="h4">
                      {viewPsiPst?.psi_pst_document_true && (
                        <a
                          href={`/linguist/linguist-view-psi-pst-document/${viewPsiPst?._id}`}
                        >
                          View document
                        </a>
                      )}
                    </Header>
                  </div>
                </div>
                {psiProgress > 0 && (
                  <Progress
                    percent={psiProgress}
                    indicating
                    autoSuccess
                    progress
                  >
                    {" "}
                    Document uploaded
                  </Progress>
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card>
              <CardBody>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "24px",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Header as="h4">
                      Upload interpreting or translation experience reference
                      letter (max 1mb)
                    </Header>
                    <Header as="h4">
                      <input
                        type="file"
                        name="other_document"
                        onChange={handleDoc4}
                        accept="application/pdf"
                      />
                    </Header>
                    {other_document?.name && (
                      <div>
                        <Header
                          style={{ margin: "0" }}
                          as="h5"
                        >{`${other_document?.lastModifiedDate?.toDateString()}`}</Header>
                        <Header style={{ margin: "0" }} as="h5">{`${(
                          other_document?.size / 1024
                        ).toFixed(2)}kb`}</Header>
                      </div>
                    )}

                    <Header as="h4">
                      {viewOtherExp?.other_document_true && (
                        <a
                          href={`/linguist/linguist-view-any-other-document/${viewOtherExp?._id}`}
                        >
                          View document
                        </a>
                      )}
                    </Header>
                  </div>
                </div>
                {otherProgress > 0 && (
                  <Progress
                    percent={otherProgress}
                    indicating
                    autoSuccess
                    progress
                  >
                    Document Uploaded
                  </Progress>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <div className={classes.buttonFlex} style={{ marginTop: "24px" }}>
          <Button basic color="purple" onClick={handleBack}>
            Back
          </Button>
          {!next ? (
            <Button
              loading={saveLoader}
              color="purple"
              disabled={isAllow}
              onClick={handleSave}
            >
              Save
            </Button>
          ) : (
            <Button color="green" onClick={handleNext}>
              Next
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default PageFour;
