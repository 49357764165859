
import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import Axios from "utils/axios";
// Material Core Components
import Typography from "@material-ui/core/Typography";

// Material Icons

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormOne from "./FormOne";
import LandingPageLayout from "layout/LandingPageLayout";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import CustomFormControl from "components/Common/CustomFormControl";
import { Progress } from "semantic-ui-react";

const ContactRegistration = ({ id }) => {
  const { count } = useSelector((state) => state.crReducer);

  const classes = useEmployeeStyles();

  const selectedForm = count;

  const [linguistsppo, LinSppoDetails] = useState([]);

  const LinguistSppoDetails = async () => {
    try {
      const response = await Axios.get(
        `/api/only-view-LinID/SppoID/AdminID/${id}`
      );
      LinSppoDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };


  useEffect(() => {
    LinguistSppoDetails();
  }, []);

  return (
    // linguistsppo?.id ?
      <LandingPageLayout>
        <div className={classes.root}>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className={`${classes.textCenter} ${classes.title}`}>
                    <CustomAvatar />
                    <Typography component="h1" variant="h5">
                      <Fragment>Customer Enquiry and Quotation Request Form</Fragment>
                    </Typography>
                    <Typography variant="subtitle2">Page {count} of 2</Typography>
                    <Typography variant="subtitle2">Reference ID: {linguistsppo?.id ? linguistsppo?.id : "LC Web"}</Typography>
                  </div>
                  {selectedForm === 1 && <FormOne
                    userID={linguistsppo?.id}
                    mainobjID={linguistsppo?.agent_sppo ? linguistsppo?.agent_sppo : id} />} 
                    <CustomFormControl width="73.33%">
                    <Progress
                      color="purple"
                      percent={(selectedForm / 2) * 100}
                      active
                      size="small"
                    />
                  </CustomFormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </LandingPageLayout>
      // :
      // <Card>
      //   <div
      //     style={{
      //       backgroundImage:
      //         "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
      //       padding: "20px",
      //       boxShadow:
      //         "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
      //     }}
      //   >
      //     This page is not available
      //   </div>
      // </Card>
  );
};

export default ContactRegistration;
