import { UPDATE_UNCONFIRM_QUOTES, PROVIDED_QUOTES_LIST } from "./actionTypes";

export const unconfirmQuotes = data => ({
    type: UPDATE_UNCONFIRM_QUOTES,
    payload: data
})

export const providedQuotes = data => ({
    type: PROVIDED_QUOTES_LIST,
    payload: data
})