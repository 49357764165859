import { CHANGE_IBT_INPUT } from "../actions/actionTypes";
import moment from 'moment-timezone'

const INITIAL_STATE = {
    data: {
        client_found: false,
        select_client: '',
        purchase_order_number: '',
        timezone: moment.tz.guess(),
        date: '',
        starttime: '',
        endtime: '',
        interpreting_service_required: '',
        house_number_street_name: '',
        address_line_two: '',
        region: '',
        current_nationality: '',
        other_nationalities: [],
        post_code: '',
        country: '',
        remote_address: '',
        source_language: '',
        source_language_level: '',
        target_language: '',
        target_language_level: '',
        linguist_skill_level: '',
        typesof_interpreting_required: '',
        job_description: '',
        preferred_interpreter: '',
        gender: '',
        security_clearance: '',
        car_parking: '',
        additional_info: '',
        upload_file: '',
        agree: false
    }
};

const ibtReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case CHANGE_IBT_INPUT:
            return {
                ...state,
                data: {
                  ...state.data,
                  [payload.name]: payload.value
                }
            }
        
        default:
            return state
    }
}

export default ibtReducer