import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import SearchView from "./Search/SearchView";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import { useToasts } from "react-toast-notifications";
import Action from "components/Admin/Dashboard/JobLists/Interpreting/BAC/BACActions";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ interpretingData, text }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("token");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/list-fullpaid-invoiced-interpreting-BAC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({
        ...values,
        bookingList: interpretingData
          ? response?.data.filter((item) => item.id === interpretingData)
          : response?.data,
      });
      interpretingData && setShow(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/find-fullpaid-invoiced-interpreting-BAC/bookingID/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });

        addToast("assignment found", { appearance: "success" });
      } else {
        addToast("assignment not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <div>
      {!interpretingData && (
        <SearchView
          show={show}
          load={loadbookingList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          SearchText={SearchText}
          setShow={setShow}
        />
      )}
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice ID</TableCell>
                    <TableCell>Assignment ID</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Source language</TableCell>
                    <TableCell>Target language</TableCell>
                    <TableCell>Target language skill level</TableCell>
                    <TableCell>Total estimate pay</TableCell>
                    <TableCell>Payment status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {bookingList.length > 0 ? (
                  <TableBody>
                    {bookingList

                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list, i) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                          <TableCell>{list?.invoiceID?.id}</TableCell>
                          <TableCell>
                            <Link
                              to={`/admin/view-account-client-booking-interpreting/${list?._id}`}
                            >
                              {list?.id}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {list?.gethour}
                          </TableCell>
                          <TableCell>
                            {list?.source_language}
                          </TableCell>
                          <TableCell>
                            {list?.target_language}
                          </TableCell>
                          <TableCell>
                            {
                              list
                                ?.target_linguist_skill_level
                            }
                          </TableCell>
                          <TableCell>
                            {list?.currency_new?.substring(
                              0,
                              3
                            )}{" "}
                            {list.estimate_paid_amount ? list.estimate_paid_amount : list.estimate}
                          </TableCell>
                          <TableCell>{list?.status}</TableCell>
                          <TableCell>
                            <Action
                              load={loadbookingList}
                              allocated={list?.accept}
                              cancel={list?.cancel}
                              list={list}
                              invoice={list.invoice}
                              invoicePay={list.invoice_payment_status}
                              invoiceID={list?.invoiceID?._id}
                              id={list?._id}
                              linID={list?.postedBy}
                              ID={list?.id}
                              clientID={list?.postedBy?.id}
                              name={
                                list?.postedBy?.FirstName
                                  ? list?.postedBy?.FirstName
                                  : "N/A" +
                                    " " +
                                    list?.postedBy?.LastName
                                    ? list?.postedBy?.LastName
                                    : ""
                              }
                              currency={list?.currency_new?.substring(
                                0,
                                3
                              )}
                              estimate={list.estimate_paid_amount ? list.estimate_paid_amount : list.estimate}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
