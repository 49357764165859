import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import BookingList from "components/Employee/Dashboard/JobLists/BookingList";
import Translation from "components/Employee/Dashboard/JobLists/Translation";
import Quotation from "components/Employee/Dashboard/JobLists/Quotation";

// Fourth Segment
import News from "./NewsAndNotice";
import WelcomeMessage from "./WelcomeMessage";

import MyNotes from "components/Employee/Dashboard/Notes/MyNoteForm";
import ColleagueNoteForm from "components/Employee/Dashboard/Notes/ColleagueNoteForm";
import EventsCard from "components/Admin/ManagementPanel/EventsForm/EMPEventsCard"

const Dashboard = () => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <BookingList />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <Translation />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <Quotation />
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <ColleagueNoteForm />
          </GridItem>
          <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <EventsCard />
          </GridItem>
          <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <MyNotes />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
        <News />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
        <WelcomeMessage />
      </GridItem>
    </GridContainer>
  );
};

export default Dashboard;
