import React, { Fragment } from "react";

// Custom Components
import ViewOTManager from "components/Auth/ViewOTManager/LinguistsCollective";

const ViewOTManagerPage= () => {
  return (
    <Fragment>
      <ViewOTManager />
    </Fragment>
  );
};

export default ViewOTManagerPage
