import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Material Icons
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CachedSharpIcon from "@material-ui/icons/CachedSharp";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";

import Settings from "components/Admin/ManagementPanel/Settings";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const ManagementPanel = () => {
  const classes = useDashboardStyles();

  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <BookmarkIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Book Interpreter</p>
                <h3 className={classes.cardTitle}>49/50</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <ListAltSharpIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Order Translation</p>
                <h3 className={classes.cardTitle}>49/50</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <div className={classes.cardSettingsDiv}>
              <Card>
                <CardHeader color="primary" stats icon>
                  <CardIcon color="primary">
                    <CachedSharpIcon />
                  </CardIcon>
                  <p className={classes.cardCategory}>Request Quote</p>
                  <h3 className={classes.cardTitle}>49/50</h3>
                </CardHeader>
                <CardFooter stats></CardFooter>
              </Card>
              <Settings />
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Management Panel</p>
              </CardHeader>
              <CardBody></CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default ManagementPanel;
