import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Button } from "@material-ui/core";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

// Others
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import Axios from "utils/axios";
import swal from "sweetalert";
import { AccordionSummary } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";

import fetch from "isomorphic-fetch";
import Items from "pages/Component/TableBodyTranslation";
import Notes from "./NotesTrans";
import DocumentRequest from "./TransDocumentRequest";
import TranslationDetails from "pages/Component/TranslationDetails";
import TranslationDelivery from "pages/Component/Translation_Delivery/ADM/Translation_Delivery";
import EmailNotification from "./EmailNotification";
const ViewContactRequest = () => {
  const token = Cookies.get("token");
  const history = useHistory();
  const classes = useDashboardStyles();
  const { id } = useParams();
  const [values, setValues] = useState("");
  const [reload, setReload] = useState(0);

  const [edit, setEdit] = React.useState({
    loader: false,
  });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/read-translation-accountclient/${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setValues(result);
      });
  }, [reload]);

  const sendLink = async (e) => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/BAC/sendlink-translation-document/assignmentID/${values.id}`,
        " ",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `Send Link For Document/s Upload`,
        icon: "success",
        buttons: false,
      });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `Failed`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleSend = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          sendLink(value);

        default:
          break;
      }
    });
  };

  return values ? (
    <Manager>
      <MasterLayout>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>
              Translation Assignment - {values.id}
            </p>
            <i
              className="icon list arrow left"
              onClick={() => {
                history.goBack();
              }}
            ></i>
          </CardHeader>
        </GridItem>
        <br />
        <React.Fragment>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
              <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
                <CardHeader
                  color="primary"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 10,
                    top: 35,
                  }}
                >
                  <p className={classes.cardTitleWhite}>
                    Translation Assignment
                  </p>
                </CardHeader>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card
                  raised
                  style={{ marginTop: "24px", padding: "30px", zIndex: 0 }}
                >
                  <CardBody>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Card>
                        <CardBody>
                          <TranslationDetails
                            values={values}
                            type={"bactrans"}
                            setReload={setReload}
                          />
                        </CardBody>
                      </Card>
                      <br />
                      <EmailNotification
                        type={"trans"}
                        id={values?._id}
                        values={values}
                      />
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ zIndex: 1 }}
                      >
                        <Button
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            marginTop: 10,
                            top: 35,
                            background:
                              "linear-gradient( 60deg , #ab47bc, #8e24aa)",
                            width: 100 + "%",
                            padding: (10, 15),
                          }}
                          onClick={() =>
                            history.push(
                              `/admin/view-bac-translation-document/${values._id}`
                            )
                          }
                        >
                          <p className={classes.cardTitleWhite}>
                            View Document
                          </p>
                        </Button>
                      </GridItem>

                      <br />
                      <br />
                      <br />
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ zIndex: 1 }}
                      >
                        {edit.loader ? (
                          <h3
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {edit.loader && (
                              <div class="ui active inline loader"></div>
                            )}
                          </h3>
                        ) : (
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <AppBar
                              position="static"
                              className={`${classes.button}`}
                              style={{ width: "100%", height: "100%" }}
                            >
                              <Toolbar onClick={handleSend}>
                                <h3
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  Send Link For Document/s Upload
                                  {edit.loader && (
                                    <div class="ui active inline loader"></div>
                                  )}
                                </h3>
                              </Toolbar>
                            </AppBar>
                          </AccordionSummary>
                        )}
                      </GridItem>
                      <br />
                    </GridItem>
                  </CardBody>
                  <Items values={values} />

                  <DocumentRequest />
                  <br />
                  <Notes />
                </Card>
              </GridItem>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <TranslationDelivery />
            </GridItem>
          </GridContainer>
          <br />
        </React.Fragment>
      </MasterLayout>
    </Manager>
  ) : (
    "Loading..."
  );
};

export default ViewContactRequest;
