import React from "react";
import { Button, Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import UpdateDuplicateID from "./UpdateDuplicateID"
import MoveLinDocument from "./MoveLinDocument"

const SubscribeHere = () => {

  return (
    <>
      <Card>
        <CardBody>
          <UpdateDuplicateID />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <MoveLinDocument />
        </CardBody>
      </Card>
    </>

  );
};

// EBBlxUJe_
export default SubscribeHere;
