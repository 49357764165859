import { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";

import { updateCarpDetails } from "redux/actions/admin/controller";

const EditableText = ({ text, readOnly, name }) => {
  const dispatch = useDispatch();

  const [isClicked, setIsClicked] = useState(false);
  const [value, setValue] = useState(text);
  const [inputName, setInputName] = useState();
  const [loading, setLoading] = useState(false);

  const { carpDetails } = useSelector((state) => state.adminControlData);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const handleCheck = async () => {
    if (!inputName) {
      setIsClicked(!isClicked);
      return false;
    }

    const coopiedDetails = { ...carpDetails };

    coopiedDetails[inputName] = value;

    setLoading(true);
    await dispatch(updateCarpDetails(coopiedDetails));
    setLoading(false);
    setIsClicked(!isClicked);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setInputName(e.target.name);
  };

  return (
    <span>
      {isClicked ? (
        <input
          style={{
            border: "0.5px solid #333",
            fontSize: "1.1rem",
            width: "4%",
          }}
          value={value}
          onChange={handleChange}
          name={name}
        />
      ) : (
        <span>{text}</span>
      )}{" "}
      {!readOnly ? (
        <Fragment>
          {!isClicked ? (
            <EditIcon
              color="primary"
              onClick={handleClick}
              style={{
                cursor: "pointer",
                fontSize: "1.2rem",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <Fragment>
              {loading ? (
                <CircularProgress
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              ) : (
                <CheckIcon
                  color="secondary"
                  onClick={handleCheck}
                  style={{
                    cursor: "pointer",
                    fontSize: "1.3rem",
                    verticalAlign: "middle",
                  }}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      ) : null}{" "}
    </span>
  );
};

export default EditableText;
