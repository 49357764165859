import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomSelect from "components/Common/CustomSelect";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";
import CustomUpload from "components/Common/CustomUpload";
import CustomRadio from "components/Common/CustomRadio";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import moment from "moment-timezone";

// Others
import {
  PRIMARY_TARGET_LANG,
  PRIMARY_SOURCE_LANG,
  VIRTUAL_FORMATTING,
  DOCUMENT_TYPE,
  TRANSLATION_CERTIFICATE,
  TRANSLATION_PRINTED,
  PRINT_QUALITY,
  SECURITY_CLEARANCE_LEVEL,
  SKILL_LEVEL_REQUIRED,
  TIMEZONE,
  RARE_REGEX,
  LEVEL_REGEX
} from "data";
import { changeOTTInput } from "redux/actions/ottAction";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CustomDateTime from "components/Common/CustomDateTime";

import CustomCaptcha from "components/Common/CustomCaptcha";
import { useParams } from "react-router-dom";
import history from "utils/history";
import { AppBar, Button, Icon, Toolbar } from "@material-ui/core";
import Axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomNumberInput from "components/Common/CustomNumberInput";
import CustomDeadline from "components/Common/CustomDeadline";

const FormTwo = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.ottReducer);
  const { linID } = useParams();
  const { clientID } = useParams();
  const { clientPC } = useParams();
  const { clientLN } = useParams();
  const { clientobj } = useParams();
  Cookies.set("incClientID", clientobj);
  const [captcha, setcaptcha] = useState("");

  const [linguistid, setlinguistId] = useState("");
  const {
    client_found,
    selected_client,
    purchase_order_number,
    document_type,
    other_details,
    source_language,
    source_language_skill_level,
    target_language,
    target_language_skill_level,
    require_visual_formating,
    certified_translation,
    certificate,
    additional_quality_check,
    provider_id_number,
    count,
    additional_note,
    timezone,
    deadline,
    upload_file,
    agree,
    security_clearance,
  } = data;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeOTTInput({
        name,
        value: value,
      })
    );
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    dispatch(
      changeOTTInput({
        name,
        value: !agree,
      })
    );
  };

  const handleExpertise = (value) => {
    if (target_language?.includes("R") || source_language?.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(
        LEVEL_REGEX
       );
    }
  };

  const classes = useFormStyles();

  const handleNext = () => {
    swal(
      `
      Have you checked all details? Please note that after submission you will not be able to return to this page, on submission you will be directed to summary page. Are you ready to submit?`,
      {
        icon: "warning",
        buttons: ["Cancel", "Yes"],
      }
    ).then((willDelete) => {
      if (willDelete) {
        // setLoading(true)
        let fd = new FormData();

        fd.append("purchase_order_number", purchase_order_number);
        fd.append("document_type", document_type);
        fd.append("other_details", other_details);
        fd.append("source_language", source_language);
        fd.append("target_language", target_language);
        fd.append("source_language_skill_level", source_language_skill_level);
        fd.append("target_language_skill_level", target_language_skill_level);
        fd.append("require_visual_formating", require_visual_formating);
        fd.append("certified_translation", certified_translation);
        fd.append("certificate", certificate);
        fd.append("additional_quality_check", additional_quality_check);
        fd.append("provider_id_number", provider_id_number);
        fd.append("count", count);
        fd.append("additional_note", additional_note);
        fd.append("timezone", timezone);
        fd.append("deadline", deadline);
        fd.append("agree", agree);
        fd.append("security_clearance", security_clearance);
        fd.append("upload_file", upload_file);
        fd.append("linID", linID);
        fd.append("clientID", clientID);
        fd.append("clientobj", clientobj);

        try {
          const response = Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation`,
            fd
          )
            .then((response) => {
              //for currency
              Cookies.set("currency", response?.data.message.new_currency);

              //id
              Cookies.set("id", response?.data.message.ID);

              Cookies.set("client_ID", response?.data.message.client_ID);

              //wordcount
              Cookies.set("wordCount", response?.data.message.wordCount);

              //totalPriceForTranslation
              Cookies.set(
                "totalPriceForTranslation",
                response?.data.message.totalPriceForTranslation
              );

              //totalPriceForCertificate
              Cookies.set(
                "totalPriceForCertificate",
                response?.data.message.totalPriceForCertificate
              );

              //totalPriceForQualityCheck
              Cookies.set(
                "totalPriceForQualityCheck",
                response?.data.message.totalPriceForQualityCheck
              );

              //translationVisualFormatingPrice
              Cookies.set(
                "translationVisualFormatingPrice",
                response?.data.message.translationVisualFormatingPrice
              );
            })
            .then(function () {
              // setLoading(false)
              history.push("/inhouse-translation-order/inhouse/summary");
              // window.location = "/account-client/order-translation/summary";
            });
        } catch (error) {
          if (error.response) {
            swal({
              title: "Oops!",
              text: `${error?.response?.data?.error}`,
              icon: "error",
              buttons: false,
            });
          }
        }
      }
    });
  };

  const isAllow =
    !document_type ||
    (document_type.includes("Other") && !other_details) ||
    !source_language ||
    !target_language ||
    !require_visual_formating ||
    !certified_translation ||
    !count ||
    (certified_translation === "Yes" && !certificate) ||
    !additional_quality_check ||
    !upload_file ||
    !deadline ||
    !security_clearance ||
    !captcha ||
    !agree;

  const handleDateChange = (date) => {
    dispatch(
      changeOTTInput({
        name: "deadline",
        value: date.target.value,
      })
    );
  };

  const handleFiles = (e) => {
    const value = e.target.files[0];
    if (value.size >= 35840000) {
      swal({
        title: "Kindly select less than 35 mb file",
      });
    } else {
      dispatch(
        changeOTTInput({
          name: "upload_file",
          value,
        })
      );
    }
  };

  const handleChangeS = (e, value) => {
    dispatch(
      changeOTTInput({
        name: "source_language",
        value,
      })
    );
  };

  const handleChangeDT = (e, value) => {
    dispatch(
      changeOTTInput({
        name: "document_type",
        value,
      })
    );
  };

  const handleChangeT = (e, value) => {
    dispatch(
      changeOTTInput({
        name: "target_language",
        value,
      })
    );
  };
  const handleSecurityChange = (e, value) => {
    dispatch(
      changeOTTInput({
        name: "security_clearance",
        value,
      })
    );
  };

  const handleCaptcha = async (value) => {
    const isHuman = await fetch(
      `https://www.google.com/recaptcha/api/siteverify`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
        mode: "no-cors",
        credentials: "omit",
        body: `secret=6LdPUwMaAAAAAK7JyeaYyRPz4NeNyC2XoQthvK8h&response=6LdPUwMaAAAAALqroyySr9kV-TXA7sxKx2qzvSnC`,
      }
    );
    setcaptcha(isHuman);
  };

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/getlinguists/${linID}`
    ).then(
      (res) => setlinguistId(res.data.data.id)
      // setRates(res.data.rates)
    );
  }, []);

  return (
    <div>
      {/*'Developer allow "add more" option at the end of first order'*/}
      <form className={classes.form} noValidate autoComplete="off">
        <div
          style={{
            margin: "0",
            marginTop: "15px",
            fontSize: "0.9rem",
            fontWeight: "bold",
          }}
        >
          <AppBar position="static">
            <Toolbar>
              <h3>
                {clientID} - {clientLN} - {clientPC}
              </h3>
            </Toolbar>
          </AppBar>
        </div>
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Your booking ref/ Purchase order no"
          name="purchase_order_number"
          value={purchase_order_number}
          placeholder="Enter your reference number or purchase order number"
        />
        <CustomAutoSelect
          required
          onChange={handleChangeDT}
          name="document_type"
          value={document_type}
          label="Document type"
          data={DOCUMENT_TYPE}
          width="100%"
        />
        {document_type?.includes("Other") ? (
          <CustomInputSingle
            onChange={handleChange}
            label="Other details"
            name="other_details"
            value={other_details}
            width="100%"
            required
          />
        ) : null}
        <CustomAutoSelect
          onChange={handleChangeS}
          label="Source language"
          data={PRIMARY_SOURCE_LANG}
          width="100%"
          name="source_language"
          value={source_language}
          required
        />
        <CustomSelect
          onChange={handleChange}
          label="Please indicate source language skill level"
          moreInfo="for this assignment / project"
          data={SKILL_LEVEL_REQUIRED.filter((value) => handleExpertise(value))}
          width="100%"
          name="source_language_skill_level"
          value={source_language_skill_level}
        />
        <CustomAutoSelect
          onChange={handleChangeT}
          label="Target language"
          data={PRIMARY_TARGET_LANG.filter(
            (value) => value !== source_language
          )}
          width="100%"
          name="target_language"
          value={target_language}
          required
        />
        <CustomSelect
          onChange={handleChange}
          label="Please indicate target language skill level"
          moreInfo="for this assignment / project"
          data={SKILL_LEVEL_REQUIRED.filter((value) => handleExpertise(value))}
          width="100%"
          name="target_language_skill_level"
          value={target_language_skill_level}
          required
        />

        <CustomAutoSelect
          onChange={handleSecurityChange}
          label="Please indicate security clearance level required"
          moreInfo="Please indicate security clearance level required for this assignment"
          data={SECURITY_CLEARANCE_LEVEL}
          width="100%"
          required
          name="security_clearance"
          value={security_clearance}
        />

        {/*  (*Developer make sure this charge can be changed on settings with other rates */}
        <CustomSelect
          onChange={handleChange}
          label="Do this job require visual formatting?"
          moreInfo="document to look alike same as source document formatting boxes, graphs etc, set charge of £x* each A4 page"
          data={VIRTUAL_FORMATTING}
          width="100%"
          name="require_visual_formating"
          value={require_visual_formating}
          required
        />
        {/* (*Developer make sure charge can be modified easily on settings) */}
        <CustomSelect
          onChange={handleChange}
          label="Do you require this translation to be certified"
          moreInfo="By a qualified translator? If yes, please indicate which page(s) / document(s)? "
          data={TRANSLATION_CERTIFICATE}
          width="100%"
          name="certified_translation"
          value={certified_translation}
          required
        />
        {/* (*Developer make sure charge can be modified easily on settings) */}
        {certified_translation === "Yes" && (
          <CustomRadio
            onChange={handleChange}
            label="Would you like certificate to be posted or electronically?"
            moreInfo="If you have requested your translation to be certified"
            data={TRANSLATION_PRINTED}
            flexDir="column"
            width="100%"
            name="certificate"
            value={certificate}
            required
          />
        )}
        {/* (*Developer make sure charge can be modified easily on settings)  */}
        <CustomSelect
          onChange={handleChange}
          label="Would you require additional quality check?"
          moreInfo="Would you require additional quality assurance QA for this assignment / project? (a qualified second linguist will check the work of main translator and assure quality of translation, recommended for large volume of translation, not necessary for certified translation)"
          data={PRINT_QUALITY}
          width="100%"
          name="additional_quality_check"
          value={additional_quality_check}
          required
        />

        {/* (*Developer try to make this automated) */}
        <div
          style={{ display: "flex", alignItems: "flex-end", marginTop: "7px" }}
        >
          <CustomUpload
            onChange={handleFiles}
            accept=".pdf,.docx,.JPEG"
            name="upload_file"
            width="100%"
            label="Please upload your document(s) here (clear and readable scan, pdf or photos/JPEG only)"
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button color="secondary">
            {upload_file ? (
              <div>
                <Icon name="file pdf" size="big" />
                <p
                  style={{
                    margin: "0",
                    marginTop: "7px",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  {upload_file.name}
                </p>
                <p>{upload_file?.lastModifiedDate?.toDateString()} </p>
                <p style={{ margin: "0" }}>
                  {(upload_file.size / 1024).toFixed(2)}Kb{" "}
                </p>
              </div>
            ) : null}
          </Button>
        </div>

        <CustomNumberInput
          onChange={handleChange}
          width="100%"
          name="count"
          value={count}
          label="Estimated number words / word counts"
          moreInfo="(10k words or 20 A4 pages only. More than 10k or 20 pages please use quote)"
          required
          type={"number"}
          placeholder="Enter your reference number or purchase order number"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="Preferred Linguist / Service provider ID number"
          width="100%"
          name="provider_id_number"
          moreInfo="LC will try its best, but cannot guarantee"
          value={provider_id_number}
          placeholder="Provide details of the preferred linguist e.g. LIN/PRO12345 - Jane Miller"
        />
        <CustomInput
          onChange={handleChange}
          name="additional_note"
          value={additional_note}
          label="Additional note for translator"
          width="100%"
          placeholder="Provide any other information that you would like translator to have"
        />

        <CustomSelect
          required
          onChange={handleChange}
          label="Select timezone"
          width="100%"
          data={TIMEZONE}
          name="timezone"
          value={timezone}
          helperText={moment.tz.guess()}
        />

        <CustomDeadline
          required
          onChange={handleDateChange}
          width="100%"
          label="Translation needed by"
          moreInfo="Please allow sufficient time for translation, LC linguist will try their best to complete on time"
          name="deadline"
          value={deadline}
        />

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <h4>INC referral agent Linguist/SPPO ID: {linguistid}</h4>
        </div>
        {/* <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={<Checkbox onChange={handleChange} name="agree" checked={agree} color="primary" />}
            label="By submitting this form I agree with Linguists Collective - T&C, Privacy policy"
          />
        </div> */}

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <p>
            <Checkbox
              onChange={handleCheckbox}
              checked={agree}
              name="agree"
              color="primary"
            />
            By submitting this form I agree with Linguists Collective
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/privacy-policy"
            >
              {" "}
              Privacy Policy{" "}
            </a>
            and{" "}
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/terms-and-conditions"
            >
              {" "}
              T&C
            </a>
          </p>
        </div>

        <CustomCaptcha onChange={handleCaptcha} />

        <div className={classes.buttonFlex}>
          <CustomButton disabled={isAllow} text="Submit" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default FormTwo;
