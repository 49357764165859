import styled from "styled-components";
import { Button } from "@material-ui/core";

export const CustomButton = styled(Button)`
  .btn {
    color: #ab47bc;
    border: none;
    background: transparent;
    padding: 4px 15px;
  }
`;

export const DateFilterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  h3 {
    margin: 0;
    padding-right: 15px;
  }
`;

export const PricecalculatorDiv = styled.div`
  margin-top: 2rem;
`;

export const AddDataDiv = styled.div`
  padding-top: 2rem;
  overflow-x: scroll;
  table {
    thead {
      tr {
        th {
          min-width: 160px;
        }
      }
    }
    tbody {
      td {
        .d-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .MuiFormControl-root {
            margin-right: 0px;
          }
        }
      }
    }
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .a-p {
    font-size: 14px;
  }
  .p {
    margin: 0;
    font-weight: 500;
    min-width: 200px;
  }
  .MuiFormControl-root {
    margin-left: 0px;
    margin-top: 0px;
    padding-left: 1.4rem;
  }
`;

export const DivFlex = styled.div``;

export const QuoteDiv = styled.div`
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    margin-left: 10px;
    margin-top: 1px;
    margin-bottom: 10px;
  }
`;
