import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import history from "utils/history";
import swal from "sweetalert";
import { Button } from "semantic-ui-react";



const Action = ({
  load, id,
  doc1True, doc2True, doc3True, doc4True, doc5True,
  admin, lin, agentlin, sppo, emp, cac, bac
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const DeleteDelivery = async (e) => {
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/for-linguists-admin/delete_Translation_Delivery_Doc/docID/${id}`);
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      })
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          DeleteDelivery(value);

        default:
          break;
      }
    });
  };





  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {doc1True && admin ?
          <MenuItem onClick={() => history.push(`/admin/view-translation-delivery-document1/${id}`)}>View Document</MenuItem> : null}
        {doc2True && admin ?
          <MenuItem onClick={() => history.push(`/admin/view-translation-delivery-document2/${id}`)}>View Document</MenuItem> : null}
        {doc3True && admin ?
          <MenuItem onClick={() => history.push(`/admin/view-translation-delivery-document3/${id}`)}>View Document</MenuItem> : null}
        {doc4True && admin ?
          <MenuItem onClick={() => history.push(`/admin/view-translation-delivery-document4/${id}`)}>View Document</MenuItem> : null}
        {doc5True && admin ?
          <MenuItem onClick={() => history.push(`/admin/view-translation-delivery-document5/${id}`)}>View Document</MenuItem> : null}

        {doc1True && (lin || agentlin) ?
          <MenuItem onClick={() => history.push(`/linguist/view-translation-delivery-document1/${id}`)}>View Document</MenuItem> : null}
        {doc2True && (lin || agentlin) ?
          <MenuItem onClick={() => history.push(`/linguist/view-translation-delivery-document2/${id}`)}>View Document</MenuItem> : null}
        {doc3True && (lin || agentlin) ?
          <MenuItem onClick={() => history.push(`/linguist/view-translation-delivery-document3/${id}`)}>View Document</MenuItem> : null}
        {doc4True && (lin || agentlin) ?
          <MenuItem onClick={() => history.push(`/linguist/view-translation-delivery-document4/${id}`)}>View Document</MenuItem> : null}
        {doc5True && (lin || agentlin) ?
          <MenuItem onClick={() => history.push(`/linguist/view-translation-delivery-document5/${id}`)}>View Document</MenuItem> : null}

        {doc1True && emp ?
          <MenuItem onClick={() => history.push(`/employee/view-translation-delivery-document1/${id}`)}>View Document</MenuItem> : null}
        {doc2True && emp ?
          <MenuItem onClick={() => history.push(`/employee/view-translation-delivery-document2/${id}`)}>View Document</MenuItem> : null}
        {doc3True && emp ?
          <MenuItem onClick={() => history.push(`/employee/view-translation-delivery-document3/${id}`)}>View Document</MenuItem> : null}
        {doc4True && emp ?
          <MenuItem onClick={() => history.push(`/employee/view-translation-delivery-document4/${id}`)}>View Document</MenuItem> : null}
        {doc5True && emp ?
          <MenuItem onClick={() => history.push(`/employee/view-translation-delivery-document5/${id}`)}>View Document</MenuItem> : null}

        {doc1True && bac ?
          <MenuItem onClick={() => history.push(`/account-client/view-translation-delivery-document1/${id}`)}>View Document</MenuItem> : null}
        {doc2True && bac ?
          <MenuItem onClick={() => history.push(`/account-client/view-translation-delivery-document2/${id}`)}>View Document</MenuItem> : null}
        {doc3True && bac ?
          <MenuItem onClick={() => history.push(`/account-client/view-translation-delivery-document3/${id}`)}>View Document</MenuItem> : null}
        {doc4True && bac ?
          <MenuItem onClick={() => history.push(`/account-client/view-translation-delivery-document4/${id}`)}>View Document</MenuItem> : null}
        {doc5True && bac ?
          <MenuItem onClick={() => history.push(`/account-client/view-translation-delivery-document5/${id}`)}>View Document</MenuItem> : null}

        {doc1True && cac ?
          <MenuItem onClick={() => history.push(`/cash-client/view-translation-delivery-document1/${id}`)}>View Document</MenuItem> : null}
        {doc2True && cac ?
          <MenuItem onClick={() => history.push(`/cash-client/view-translation-delivery-document2/${id}`)}>View Document</MenuItem> : null}
        {doc3True && cac ?
          <MenuItem onClick={() => history.push(`/cash-client/view-translation-delivery-document3/${id}`)}>View Document</MenuItem> : null}
        {doc4True && cac ?
          <MenuItem onClick={() => history.push(`/cash-client/view-translation-delivery-document4/${id}`)}>View Document</MenuItem> : null}
        {doc5True && cac ?
          <MenuItem onClick={() => history.push(`/cash-client/view-translation-delivery-document5/${id}`)}>View Document</MenuItem> : null}

        {doc1True && sppo ?
          <MenuItem onClick={() => history.push(`/sppo/view-translation-delivery-document1/${id}`)}>View Document</MenuItem> : null}
        {doc2True && sppo ?
          <MenuItem onClick={() => history.push(`/sppo/view-translation-delivery-document2/${id}`)}>View Document</MenuItem> : null}
        {doc3True && sppo ?
          <MenuItem onClick={() => history.push(`/sppo/view-translation-delivery-document3/${id}`)}>View Document</MenuItem> : null}
        {doc4True && sppo ?
          <MenuItem onClick={() => history.push(`/sppo/view-translation-delivery-document4/${id}`)}>View Document</MenuItem> : null}
        {doc5True && sppo ?
          <MenuItem onClick={() => history.push(`/sppo/view-translation-delivery-document5/${id}`)}>View Document</MenuItem> : null}

        {cac || bac || sppo || agentlin ? null :
          <MenuItem onClick={handleDelete}>Delete</MenuItem>}

      </Menu>
    </div>
  );
};

export default Action;
