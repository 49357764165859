import { AMEND_TRANSLATION_STEP, AMEND_TRANSLATION_INPUT } from "../actions/actionTypes";

const AMMEND_STATE = {
  count: 1,
  data: {
    upload_file: ''
  }
};

const ammendreducer = (state = AMMEND_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AMEND_TRANSLATION_STEP:
      return {
        ...state,
        count: payload,
      };

    case AMEND_TRANSLATION_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value
        }
      }

    default:
      return state;
  }
};

export default ammendreducer;
