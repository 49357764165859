import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { Checkbox } from "semantic-ui-react";
import Axios from "axios";
import moment from "moment";
import { Rating } from "@material-ui/lab";
import "./sigCanvas.css";

const TimesheetFooter = ({ details }) => {
  const todayDate = moment().format("DD/MMM/YYYY");

  const [signature, setSignature] = useState({
    linSignature: "",
  });

  const [cacSignature, setcACSignature] = useState({
    cacSignature: "",
  });

  const [adminSignature, setAdminSignature] = useState({
    adminSignature: "",
  });

  var arrayBufferView = new Uint8Array(details.signature?.data?.data);
  var blob = new Blob([arrayBufferView], { type: "image/jpeg" });

  useEffect(() => {
    const getCACSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-client-signature/reportID/${details._id}`
        );
        setcACSignature({ ...cacSignature, cacSignature: response?.data });
      } catch (error) {
        console.log(error);
      }
    };

    const getLINSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-LIN-signature/reportID/${details._id}`
        );
        setSignature({ ...signature, linSignature: response?.data });
      } catch (error) {
        console.log(error);
      }
    };

    const getAdminSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-admin-signature/reportID/${details._id}`
        );
        setAdminSignature({
          ...adminSignature,
          adminSignature: response?.data,
        });
      } catch (error) {
        console.log(error);
      }
    };

    getCACSignature();
    getLINSignature();
    getAdminSignature();
  }, []);

  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
            {/* <Table.HeaderCell>
              To be verified by project supervisor / quality control (if
              applicable){" "}
            </Table.HeaderCell> */}
            <Table.HeaderCell>To be completed by client</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            {details?.signatureByLinTrue ? (
              <Table.Cell>
                <Checkbox
                  checked
                  label="I declare the information given by me is true/accurate and complete."
                  disabled
                />
              </Table.Cell>
            ) : (
              <Table.Cell>
                <Checkbox
                  label="I declare the information given by me is true/accurate and complete."
                  disabled
                />
              </Table.Cell>
            )}
            {/* {details?.signatureByadminTrue ? (
              <Table.Cell>
                <Checkbox
                  checked
                  label="I certify that the project was supervised / quality controlled by me"
                  disabled
                />
              </Table.Cell>
            ) : (
              <Table.Cell>
                <Checkbox
                  label="I certify that the project was supervised / quality controlled by me"
                  disabled
                />
              </Table.Cell>
            )} */}
            {details?.signatureByClientTrue ? (
              <Table.Cell>
                <Checkbox
                  checked
                  label="I declare that I have checked the information on this form and certify that it is true and complete"
                  disabled
                />
              </Table.Cell>
            ) : (
              <Table.Cell>
                <Checkbox
                  label="I declare that I have checked the information on this form and certify that it is true and complete"
                  disabled
                />
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ display: "flex", flexDirection: "column" }}>
              <div>Signature</div>
              <img
                style={{ width: "130px" }}
                src={signature.linSignature}
                alt=""
              />
            </Table.Cell>
            {/* <Table.Cell>
              <div>Signature</div>
              <img
                style={{ width: "130px" }}
                src={adminSignature.adminSignature}
                alt=""
              />
            </Table.Cell> */}
            <Table.Cell>
              <div>Signature</div>
              <img
                style={{ width: "130px" }}
                src={cacSignature.cacSignature}
                alt=""
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p>
                Full Name:
                <b>
                  {details.postedByLin?.FirstName
                    ? details.postedByLin?.FirstName
                    : " "}
                </b>
                <b>
                  {details.postedByLin?.LastName
                    ? details.postedByLin?.LastName
                    : " "}
                </b>
              </p>
            </Table.Cell>

            {/* <Table.Cell>
              <p>
                Full Name:
                <b>
                  {details.supervisor_fullname
                    ? details.supervisor_fullname
                    : details.SPPOID?.first_name
                    ? details.SPPOID?.first_name
                    : " "}
                </b>
                <b>
                  {details?.postedBySupervisor?.LastName
                    ? details?.postedBySupervisor?.LastName
                    : details.SPPOID?.last_name
                    ? details.SPPOID?.last_name +
                      " " +
                      "(" +
                      details?.userID +
                      ")"
                    : " "}
                </b>
              </p>
            </Table.Cell> */}
            <Table.Cell>
              <p>
                Full Name:
                <b>
                  {details.cacTranslationID?.createdBy?.FirstName
                    ? details.cacTranslationID?.createdBy?.FirstName
                    : details.bacTranslationID?.createdBy?.FirstName
                    ? details.bacTranslationID?.createdBy?.FirstName
                    : details.incTranslationID?.client_ID?.FirstName
                    ? details.incTranslationID?.client_ID?.FirstName
                    : details.prcTranslationID?.PrivateClientID?.FirstName
                    ? details.prcTranslationID?.PrivateClientID?.FirstName
                    : details?.linQuoteID?.quoteRequstByCashclient?.postedBy
                        ?.FirstName
                    ? details?.linQuoteID?.quoteRequstByCashclient?.postedBy
                        ?.FirstName
                    : details?.linQuoteID?.quoteRequstByAccountclient?.postedBy
                        ?.FirstName
                    ? details?.linQuoteID?.quoteRequstByAccountclient?.postedBy
                        ?.FirstName
                    : details?.prcDirectQuoteID?.privateClientID?.FirstName
                    ? details?.prcDirectQuoteID?.privateClientID?.FirstName
                    : "N/A"}{" "}
                </b>
                <b>
                  {details.cacTranslationID?.createdBy?.LastName
                    ? details.cacTranslationID?.createdBy?.LastName
                    : details.bacTranslationID?.createdBy?.LastName
                    ? details.bacTranslationID?.createdBy?.LastName
                    : details.incTranslationID?.client_ID?.LastName
                    ? details.incTranslationID?.client_ID?.LastName
                    : details.prcTranslationID?.PrivateClientID?.LastName
                    ? details.prcTranslationID?.PrivateClientID?.LastName
                    : details?.linQuoteID?.quoteRequstByCashclient?.postedBy
                        ?.LastName
                    ? details?.linQuoteID?.quoteRequstByCashclient?.postedBy
                        ?.LastName
                    : details?.linQuoteID?.quoteRequstByAccountclient?.postedBy
                        ?.LastName
                    ? details?.linQuoteID?.quoteRequstByAccountclient?.postedBy
                        ?.LastName
                    : details?.prcDirectQuoteID?.privateClientID?.LastName
                    ? details?.prcDirectQuoteID?.privateClientID?.LastName
                    : "N/A"}{" "}
                </b>
              </p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {todayDate}</Table.Cell>
            {/* <Table.Cell>Date: {todayDate}</Table.Cell> */}
            <Table.Cell>Date: {todayDate}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Feedback:
              <div>
                <Rating
                  name="simple-controlled"
                  value={details.feedbackByLin}
                  readOnly
                />
              </div>
              {/* <Input onChange= {(e) => setDetails({...details, feedbackByLin: e.target.value})} value= {details.feedbackByLin}/> */}
            </Table.Cell>
            {/* <Table.Cell>
              Remarks:
              <p>{details.remarksBySupervisor}</p>
            </Table.Cell> */}
            <Table.Cell>
              Feedback
              <div>
                <Rating
                  name="simple-controlled"
                  value={details.feedbackByClient}
                  readOnly
                />
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default TimesheetFooter;
