import {ADD_TRANSACTION, DELETE_TRANSACTION, ADD_OTHER_TRANSACTION, DELETE_OTHER_TRANSACTION} from './actionTypes'

export const addTransaction = data => ({
    type: ADD_TRANSACTION,
    payload: data
})

export const deleteTransaction = data => ({
    type: DELETE_TRANSACTION,
    payload: data
})

export const addOtherTransaction = data => ({
    type: ADD_OTHER_TRANSACTION,
    payload: data
})

export const deleteOtherTransaction = data => ({
    type: DELETE_OTHER_TRANSACTION,
    payload: data
})