import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import RateCard from "components/RateCard/RateCard";
import CurrencyDropdown from "components/Common/CurrencyDropdown";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const RateCardPage = () => {
  const classes = useDashboardStyles();
  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>
                  Rate Card: Interpreting and translation price guide for UK,
                  Europe, Canada, Australia, New Zealand and USA.
                </p>
              </CardHeader>
              <CardBody>
                <CurrencyDropdown />
                <RateCard readOnly />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default RateCardPage;
