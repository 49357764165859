import { useHistory } from "react-router-dom";
import React from "react";
import Button from "@material-ui/core/Button";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PeopleIcon from "@material-ui/icons/People";
import { useMediaQuery } from "@material-ui/core";

const Actions = ({ sppoID }) => {
  const history = useHistory();
  const matches = useMediaQuery("(max-width:425px)");
  return (
    <>
      {matches ? (
        <div>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px"
            }}
            onClick={() => history.push("/sppo/send-reg-form")}
          >
            Send User Reg Form
          </Button>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px"
            }}
            onClick={() =>
              window.open(`/sppo/additional-user-registration/${sppoID}`)
            }
          >
            Add a new user
          </Button>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px"
            }}
            onClick={() => history.push("/sppo/view-inactive-users")}
          >
            View Inactive Users
          </Button>

          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{
              width: "100%",
              marginBottom: "10px"
            }}
            onClick={() => history.push("/sppo/view-active-users")}
          >
            View Active Users
          </Button>

        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{
              // width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() => history.push("/sppo/send-reg-form")}
          >
            Send User Reg Form
          </Button>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{
              //  width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() =>
              window.open(`/sppo/additional-user-registration/${sppoID}`)
            }
          >
            Add a new user
          </Button>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{
              // width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() => history.push("/sppo/view-inactive-users")}
          >
            View Inactive Users
          </Button>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{
              // width: "23%",
              marginLeft: "5px",
              marginRight: "15px",
            }}
            onClick={() => history.push("/sppo/view-active-users")}
          >
            View Active Users
          </Button>

        </div>
      )}
    </>
  );
};

export default Actions;
