import { CHANGE_BA_INPUT, UPDATE_BOOKING_STEP } from "../actions/actionTypes";
import moment from 'moment-timezone'

const INITIAL_STATE = {
  count: 1,
  data: {
    purchase_order_number: '',
    timezone: moment.tz.guess(),
    startdate: '',
    starttime: '',
    duration: '',
    enddate: '',
    endtime: '',
    interpreting_service_required: '',
    house_number_street_name: '',
    address_line_two: '',
    region: '',
    current_nationality: '',
    other_nationalities: [],
    post_code: '',
    country: '',
    remote_address: '',
    source_language: '',
    target_language: '',
    linguist_skill_level: '',
    typesof_interpreting_required: '',
    job_description: '',
    preferred_interpreter: '',
    gender: '',
    security_clearance: '',
    car_parking: '',
    additional_info: '',
    scripts: '',
    currency: '',
    currency_new: '',
    tc: '',
    currency_new_card: '',
    timeCount: 1,

    startdateOne: '',
    enddateOne: '',
    starttimeOne: '',
    endtimeOne: '',
    durationOne: '',
    startdateTwo: '',
    enddateTwo: '',
    starttimeTwo: '',
    endtimeTwo: '',
    durationTwo: '',

    startdateThree: '',
    enddateThree: '',
    starttimeThree: '',
    endtimeThree: '',
    durationThree: '',
    startdateFour: '',
    enddateFour: '',
    starttimeFour: '',
    endtimeFour: '',
    durationFour: '',

    startdateFive: '',
    enddateFive: '',
    starttimeFive: '',
    endtimeFive: '',
    durationFive: '',
    startdateSix: '',
    enddateSix: '',
    starttimeSix: '',
    endtimeSix: '',
    durationSix: '',

    startdateSeven: '',
    enddateSeven: '',
    starttimeSeven: '',
    endtimeSeven: '',
    durationSeven: '',
    startdateEight: '',
    enddateEight: '',
    starttimeEight: '',
    endtimeEight: '',
    durationEight: '',

    startdateNine: '',
    enddateNine: '',
    starttimeNine: '',
    endtimeNine: '',
    durationNine: '',
    startdateTen: '',
    enddateTen: '',
    starttimeTen: '',
    endtimeTen: '',
    durationTen: '',

    startdateEleven: '',
    enddateEleven: '',
    starttimeEleven: '',
    endtimeEleven: '',
    durationEleven: '',
    startdateTwelve: '',
    enddateTwelve: '',
    starttimeTwelve: '',
    endtimeTwelve: '',
    durationTwelve: '',

    startdateThirteen: '',
    enddateThirteen: '',
    starttimeThirteen: '',
    endtimeThirteen: '',
    durationThirteen: '',
    startdateFourteen: '',
    enddateFourteen: '',
    starttimeFourteen: '',
    endtimeFourteen: '',
    durationFourteen: '',

    startdateFifteen: '',
    enddateFifteen: '',
    starttimeFifteen: '',
    endtimeFifteen: '',
    durationFifteen: '',
    startdateSixteen: '',
    enddateSixteen: '',
    starttimeSixteen: '',
    endtimeSixteen: '',
    durationSixteen: '',

    startdateSeventeen: '',
    enddateSeventeen: '',
    starttimeSeventeen: '',
    endtimeSeventeen: '',
    durationSeventeen: '',
    startdateEighteen: '',
    enddateEighteen: '',
    starttimeEighteen: '',
    endtimeEighteen: '',
    durationEighteen: '',

    startdateNineteen: '',
    enddateNineteen: '',
    starttimeNineteen: '',
    endtimeNineteen: '',
    durationNineteen: '',
    startdateTwenty: '',
    enddateTwenty: '',
    starttimeTwenty: '',
    endtimeTwenty: '',
    durationTwenty: '',

    startdateTwentyOne: '',
    enddateTwentyOne: '',
    starttimeTwentyOne: '',
    endtimeTwentyOne: '',
    durationTwentyOne: '',
    startdateTwentyTwo: '',
    enddateTwentyTwo: '',
    starttimeTwentyTwo: '',
    endtimeTwentyTwo: '',
    durationTwentyTwo: '',

    startdate23: '',
    enddate23: '',
    starttime23: '',
    endtime23: '',
    duration23: '',
    startdate24: '',
    enddate24: '',
    starttime24: '',
    endtime24: '',
    duration24: '',
    
    startdate25: '',
    enddate25: '',
    starttime25: '',
    endtime25: '',
    duration25: '',
    startdate26: '',
    enddate26: '',
    starttime26: '',
    endtime26: '',
    duration26: '',

    startdate27: '',
    enddate27: '',
    starttime27: '',
    endtime27: '',
    duration27: '',
    startdate28: '',
    enddate28: '',
    starttime28: '',
    endtime28: '',
    duration28: '',

    startdate29: '',
    enddate29: '',
    starttime29: '',
    endtime29: '',
    duration29: '',
    startdate30: '',
    enddate30: '',
    starttime30: '',
    endtime30: '',
    duration30: '',

    startdate31: '',
    enddate31: '',
    starttime31: '',
    endtime31: '',
    duration31: '',
  }
};

const bookingReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_BOOKING_STEP:
      return {
        ...state,
        count: payload,
      };
    
    case CHANGE_BA_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value
        }
      }

    default:
      return state;
  }
};

export default bookingReducer;
