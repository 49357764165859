import LShop from "assets/images/linguists-shop.jpg";
import React from "react";

// Custom Components
import CommonCard from "components/Common/CommonCard";

const LinguistShop = () => {
  return (
    <div style={{ margin: "5px" }}>
      <CommonCard title="Linguists Shop">
        <a href="https://languageshop.uk" target="_blank">
          <img style={{ width: "100%" }} src={LShop} alt="" />
        </a>
      </CommonCard>
    </div>
  );
};

export default LinguistShop;
