const { FIND_TELEPHONIC_BOOKING, VIEW_TELEPHONIC_BOOKING } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    findAssignment : false,
    viewAssignment : false
};


const assignmentReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case FIND_TELEPHONIC_BOOKING:
            return {
                findAssignment: payload,
            };

        case VIEW_TELEPHONIC_BOOKING:
            return {
                viewAssignment: payload,
            };
        
        default:
            return state
    }
}

export default assignmentReducer
