import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { Document, Page } from "react-pdf";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const ViewContactCard = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useDashboardStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <GridContainer justify="center" className={classes.container}>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <object
                  data={`${process.env.REACT_APP_BACKEND_API}/linguists/passport-INC/${id}`}
                  type="application/pdf"
                  width="100%"
                  height="1000px"
                ></object>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewContactCard;
