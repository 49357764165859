import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";
// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import history from "utils/history";
import { useMediaQuery } from "@material-ui/core";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    ["@media(max-width:1024px)"]: {
      width: "-webkit-fill-available",
    },

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

const useStyles = makeStyles(styles);
const Options = ({ currentPage }) => {
  const { id } = useParams();
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:1024px)");

  return (
    <div className={classes.root}>
      <Menu
        vertical={matches}
        widths={matches && 0}
        fluid
        stackable
        style={{ backgroundColor: "transparent" }}
      >
        <Menu.Item style={{ border: "1px white solid", marginRight: "10px" }}>
          <Link
            style={{ color: "white" }}
            to={`/admin/inactive-manager-admin-list`}
          >
            Inactive Manager/Admin
          </Link>
        </Menu.Item>
        <Menu.Item style={{ border: "1px white solid", marginRight: "10px" }}>
          <Link style={{ color: "white" }} to={`/admin/executive-list`}>
            Executive List
          </Link>
        </Menu.Item>
        <Menu.Item style={{ border: "1px white solid", marginRight: "10px" }}>
          <Link
            style={{ color: "white", marginRight: "10px" }}
            to={`/admin/admin-list`}
          >
            Manager List
          </Link>
        </Menu.Item>
        <Dropdown
          style={{
            border: "1px white solid",
            marginRight: "10px",
            color: "white",
          }}
          text="+ Manager"
          pointing
          className="link item"
          floating
        >
          <Dropdown.Menu>
            <Dropdown.Item>
              <Link style={{ color: "black" }} to={`/admin/admin&employee`}>
                Add Manager
              </Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link style={{ color: "black" }} to={`/admin/send-form`}>
                Send Manager Reg Form
              </Link>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>
              <Link target="_blank" to={`/admin-reg-form`}>
                Manager Registration Form
              </Link>
            </Dropdown.Header>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item style={{ border: "1px white solid", marginRight: "10px" }}>
          <Link style={{ color: "white" }} to={`/admin/employee-list`}>
            Employee List
          </Link>
        </Menu.Item>
        <Dropdown
          style={{ border: "1px white solid", color: "white" }}
          text="+ Employee"
          pointing
          className="link item"
          floating
        >
          <Dropdown.Menu>
            <Dropdown.Item>
              <Link style={{ color: "black" }} to={`/admin/send-emp-reg-form`}>
                Send Employee Reg Form
              </Link>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>
              <Link target="_blank" to={`/employee/initial-registration`}>
                Employee Reg Form
              </Link>
            </Dropdown.Header>
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    </div>
  );
};

export default Options;
