import React from "react";

// Material Components
import TextField from "@material-ui/core/TextField";

// Material icons
import InfoIcon from '@material-ui/icons/Info'

// Custom Components
import CustomFormControl from "./CustomFormControl";
import { InputAdornment, Tooltip } from "@material-ui/core";
import moment from 'moment'
import { Header } from "semantic-ui-react";

const CustomDeadline = ({ label, width, moreInfo, ...rest }) => {
  
  return (
    <CustomFormControl width={width}>
      <Header as= 'h5'>Translation needed by? *</Header>
      <input
        style={{
          width: "100%",
          height: '2.8rem',
          fontSize: '1rem'
        }}
        id="datetime-local"
        placeholder={label}
        type="datetime-local"
     
        {...rest}
        
        min = {moment().format('YYYY-MM-DDTHH:mm')}
      />
    </CustomFormControl>
  );
};

export default CustomDeadline;
