import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import { Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    width: "100%",
  },
  numberRow: {},
  button: { '&:focus': {
    background: " #3f51b5 !important",
    color:"white !important"
  }},
   root:{
   
  }
}));

const Numpad = (props) => {
  const { onChange, onKeyPress } = props;

  const classes = useStyles();

  const [inputValue, setInputValue] = useState("");

  const handleOnChange = (_value) => {
    // const newValue = inputValue.concat(_value);
    setInputValue(_value);
    onChange(_value);
  };

  const onButtonPress = (_value) => {
    if (onChange) handleOnChange(_value);
    if (onKeyPress) onKeyPress(_value);
  };

  //   const handleClear = () => {
  //     setInputValue("");
  //     onChange("");
  //   };

  //   const handleDelete = () => {
  //     const newString = inputValue.substring(0, inputValue.length - 1);
  //     setInputValue(newString);
  //     onChange(newString);
  //   };

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          {[1, 2, 3, 4, 5].map((value) => (
            <Grid key={value} item>
              <Button
                onClick={() => onButtonPress(value)}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                {value}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          {[6, 7, 8, 9, 10].map((value) => (
            <Grid key={value} item>
              <Button
                onClick={() => onButtonPress(value)}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                {value}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          {[11, 12, 13, 14, 15].map((value) => (
            <Grid key={value} item>
              <Button
                onClick={() => onButtonPress(value)}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                {value}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Grid container justify="center" spacing={2}>
          {[16, 17, 18, 19, 20].map((value) => (
            <Grid key={value} item>
              <Button
                onClick={() => onButtonPress(value)}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                {value}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Grid container justify="center" spacing={2}>
          {[21, 22, 23, 24, 25].map((value) => (
            <Grid key={value} item>
              <Button
                onClick={() => onButtonPress(value)}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                {value}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Grid container justify="center" spacing={2}>
          {[26, 27, 28].map((value) => (
            <Grid key={value} item>
              <Button
                onClick={() => onButtonPress(value)}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                {value}
              </Button>
            </Grid>
          ))}
        </Grid>
        {/* <Grid container justify="center" spacing={2}>
          {[19, 20, 21].map((value) => (
            <Grid key={value} item>
              <Button onClick={() => onButtonPress(value)} color="primary" variant="outlined" className={classes.button}>{value}</Button>
            </Grid>
          ))}
        </Grid>
        <Grid container justify="center" spacing={2}>
          {[22, 23, 24].map((value) => (
            <Grid key={value} item>
              <Button onClick={() => onButtonPress(value)} color="primary" variant="outlined" className={classes.button}>{value}</Button>
            </Grid>
          ))}
        </Grid>
        <Grid container justify="center" spacing={2}>
          {[25, 26, 27].map((value) => (
            <Grid key={value} item>
              <Button onClick={() => onButtonPress(value)} color="primary" variant="outlined" className={classes.button}>{value}</Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.numberRow} >
        <Grid container justify="center" className={classes.numberRow} spacing={2}>
          {/* <Grid key="dot" item>
            <Butto disabled={!inputValue.length} onClick={handleDelete} color="primary" variant="outlined" className={classes.button}>&larr;</Button>
          </Grid> */}
        {/* <Grid key={28} item>
            <Butto onClick={() => onButtonPress(28)} color="primary" variant="outlined" className={classes.button}>28</Button>
          </Grid>  */}
        {/* <Grid key="clear" item>
            <Butto disabled={!inputValue.length} onClick={handleClear} color="primary" variant="outlined" className={classes.button}>C</Button>
          </Grid> */}
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
};

Numpad.propTypes = {
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
};

Numpad.defaultProps = {
  onChange: undefined,
  onKeyPress: undefined,
};

export default Numpad;
