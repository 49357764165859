import styled from "styled-components";

export const QuoteDiv = styled.div`
  .row {
    display: flex;
    align-items: flex-end;

    .left {
      flex: 1;
    }

    .right {
      flex: 1;

      .MuiFormControl-root {
        margin-top: 0px;
        margin-left: 0px;
      }
    }
  }

  .sm {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;
