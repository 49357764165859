import TimeSheetPage from "pages/linguist/Timesheet/TimeSheetPage";

const TIMESHEET = [
  {
    id: 534,
    type: "form",
    text: "TimeSheet",
    path: "timesheet",
    component: TimeSheetPage,
  },
];

export default TIMESHEET;
