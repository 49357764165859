import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Material Core Components\
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import AdminMyNotesForm from "components/Admin/Dashboard/FourthSegment/MyNotes";
import EMPMyNotesForm from "components/Employee/Dashboard/Notes/MyNoteForm";
import EMPColleagueNotesForm from "components/Employee/Dashboard/Notes/ColleagueNoteForm";
import CACMyNotesForm from "components/CashClient/Dashboard/MyNote/Form";
import LINMyNotesForm from "components/Linguist/MyNote/MyNote";
import BACMyNotesForm from "components/AccountClient/AccountClientDasboard/MyNote/MyNoteForm";
import SppoMyNotesForm from "components/Sppo/MyNote/MyNoteForm";
import BACColleagueNotesForm from "components/AccountClient/AccountClientDasboard/MyNote/ColleagueForm";
import AdminColleagueNotesForm from "components/Admin/Dashboard/Bookings/Bookings";
import SppoColleagueNotesForm from "components/Sppo/MyNote/ColleagueForm";

import { Modal } from "semantic-ui-react";

//Icons
import PUicon from "assets/icons/profile-update.png";
import ProfileACIcon from "assets/icons/profileAC.png";

// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";
import useDropdownStyles from "assets/jss/components/dropDownStyles";
import IconImg from "components/Sidebar/IconImg";

const NavPerson = ({ layoutType }) => {
  const history = useHistory();

  const state = useSelector((state) => state);

  const classes = useNavbarStyles();
  const classesTwo = useDropdownStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [colleaguenoteOpen, setColleagueNoteOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
    setOpen(false);
    // setNoteOpen(false);
    // setColleagueNoteOpen(false);
  };

  // TODO set logic based on logged in user

  const handleNote = () => {
    setNoteOpen(true);
    setColleagueNoteOpen(false);
  };
  const handleColleagueNote = () => {
    setColleagueNoteOpen(true);
    setNoteOpen(false);
  };
  const handleChange = () => {
    switch (layoutType) {
      case "ACCOUNT_CLIENT":
        history.push("/account-client/profile");
        break;

      case "CASH_CLIENT":
        history.push("/cash-client/profile");
        break;

      case "EMPLOYEE_CLIENT":
        history.push("/employee/profile");
        break;

      case "LINGUIST":
        history.push("/linguist/profile/update");
        break;

      case "SPPO":
        history.push("/sppo/profile");
        break;

      default:
        history.push("/admin/profile");
        break;
    }
  };

  return (
    <Fragment>
      <IconButton aria-label="Person" color="primary" onClick={handleClick}>
        <div className={classes.imgDiv}>
          {["ACCOUNT_CLIENT", "CASH_CLIENT"].includes(layoutType) ? (
            <IconImg
              className={classes.icons}
              icon={ProfileACIcon}
              width="100%"
            />
          ) : (
            <IconImg className={classes.icons} icon={PUicon} />
          )}
        </div>
      </IconButton>

      <div>
        <Popper
          open={Boolean(open)}
          anchorEl={anchorEl}
          placement="bottom-end"
          className={classesTwo.toolTip}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      className={classesTwo.dropdownItem}
                      onClick={handleChange}
                    >
                      Profile Update
                    </MenuItem>

                    <MenuItem
                      className={classesTwo.myNote}
                      onClick={handleNote}
                    >
                      My Note / Do List
                    </MenuItem>
                    {!["LINGUIST", "CASH_CLIENT"].includes(layoutType) && (
                      <MenuItem
                        className={classesTwo.CoNote}
                        onClick={handleColleagueNote}
                      >
                        Note / To Do List For Colleagues
                      </MenuItem>
                    )}
                    {layoutType === "LINGUIST" && (
                      <MenuItem
                        className={classesTwo.dropdownItem}
                        onClick={() =>
                          history.push("/linguist/manage-public-profile")
                        }
                      >
                        Manage Public Profile
                      </MenuItem>
                    )}
                    {layoutType === "SPPO" && (
                      <MenuItem
                        className={classesTwo.dropdownItem}
                        onClick={() =>
                          history.push("/sppo/manage-public-profile")
                        }
                      >
                        Manage Public Profile
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
        {/* cash client */}
        {layoutType === "CASH_CLIENT" && (
          <Modal
            open={noteOpen}
            onClose={() => setNoteOpen(false)}
            onOpen={() => setNoteOpen(true)}
          >
            <Modal.Content scrolling>
              <Modal.Description>
                <CACMyNotesForm />
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
        {/* admin */}
        {![
          "ACCOUNT_CLIENT",
          "CASH_CLIENT",
          "EMPLOYEE_CLIENT",
          "SPPO",
          "LINGUIST",
        ].includes(layoutType) && (
          <>
            <Modal
              open={noteOpen}
              onClose={() => setNoteOpen(false)}
              onOpen={() => setNoteOpen(true)}
              // trigger={
              //   <MenuItem
              //     className={classesTwo.myNote}
              //     onClick={handleNote}
              //   >
              //     My Note / Do List
              //   </MenuItem>
              // }
            >
              <Modal.Content scrolling>
                <Modal.Description>
                  <AdminMyNotesForm />
                </Modal.Description>
              </Modal.Content>
            </Modal>
            <Modal
              open={colleaguenoteOpen}
              onClose={() => setColleagueNoteOpen(false)}
              onOpen={() => setColleagueNoteOpen(true)}
              // trigger={
              //   <MenuItem
              //     className={classesTwo.CoNote}
              //     onClick={handleColleagueNote}
              //   >
              //     Note / To Do List For Colleagues
              //   </MenuItem>
              // }
            >
              <Modal.Content scrolling>
                <Modal.Description>
                  <AdminColleagueNotesForm />
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </>
        )}
        {/* employee */}
        {layoutType === "EMPLOYEE_CLIENT" && (
          <>
            <Modal
              open={noteOpen}
              onClose={() => setNoteOpen(false)}
              onOpen={() => setNoteOpen(true)}
              // trigger={
              //   <MenuItem
              //     className={classesTwo.myNote}
              //     onClick={handleNote}
              //   >
              //     My Note / Do List
              //   </MenuItem>
              // }
            >
              <Modal.Content scrolling>
                <Modal.Description>
                  <EMPMyNotesForm />
                </Modal.Description>
              </Modal.Content>
            </Modal>
            <Modal
              open={colleaguenoteOpen}
              onClose={() => setColleagueNoteOpen(false)}
              onOpen={() => setColleagueNoteOpen(true)}
              // trigger={
              //   <MenuItem
              //     className={classesTwo.CoNote}
              //     onClick={handleColleagueNote}
              //   >
              //     Note / To Do List For Colleagues
              //   </MenuItem>
              // }
            >
              <Modal.Content scrolling>
                <Modal.Description>
                  <EMPColleagueNotesForm />
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </>
        )}

        {/* account client */}
        {layoutType === "ACCOUNT_CLIENT" && (
          <>
            <Modal
              open={noteOpen}
              onClose={() => setNoteOpen(false)}
              onOpen={() => setNoteOpen(true)}
              // trigger={
              //   <MenuItem
              //     className={classesTwo.myNote}
              //     onClick={handleNote}
              //   >
              //     My Note / Do List
              //   </MenuItem>
              // }
            >
              <Modal.Content scrolling>
                <Modal.Description>
                  <BACMyNotesForm />
                </Modal.Description>
              </Modal.Content>
            </Modal>
            <Modal
              open={colleaguenoteOpen}
              onClose={() => setColleagueNoteOpen(false)}
              onOpen={() => setColleagueNoteOpen(true)}
              // trigger={
              //   <MenuItem
              //     className={classesTwo.CoNote}
              //     onClick={handleColleagueNote}
              //   >
              //     Note / To Do List For Colleagues
              //   </MenuItem>
              // }
            >
              <Modal.Content scrolling>
                <Modal.Description>
                  <BACColleagueNotesForm />
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </>
        )}
        {/* linguist */}
        {layoutType === "LINGUIST" && (
          <>
            <Modal
              open={noteOpen}
              onClose={() => setNoteOpen(false)}
              onOpen={() => setNoteOpen(true)}
              // trigger={
              //   <MenuItem
              //     className={classesTwo.myNote}
              //     onClick={handleNote}
              //   >
              //     My Note / Do List
              //   </MenuItem>
              // }
            >
              <Modal.Content scrolling>
                <Modal.Description>
                  <LINMyNotesForm />
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </>
        )}
        {/* sppo */}
        {layoutType === "SPPO" && (
          <>
            <Modal
              open={noteOpen}
              onClose={() => setNoteOpen(false)}
              onOpen={() => setNoteOpen(true)}
              // trigger={
              //   <MenuItem
              //     className={classesTwo.myNote}
              //     onClick={handleNote}
              //   >
              //     My Note / Do List
              //   </MenuItem>
              // }
            >
              <Modal.Content scrolling>
                <Modal.Description>
                  <SppoMyNotesForm />
                </Modal.Description>
              </Modal.Content>
            </Modal>
            <Modal
              open={colleaguenoteOpen}
              onClose={() => setColleagueNoteOpen(false)}
              onOpen={() => setColleagueNoteOpen(true)}
              // trigger={
              //   <MenuItem
              //     className={classesTwo.CoNote}
              //     onClick={handleColleagueNote}
              //   >
              //     Note / To Do List For Colleagues
              //   </MenuItem>
              // }
            >
              <Modal.Content scrolling>
                <Modal.Description>
                  <SppoColleagueNotesForm />
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default NavPerson;
