import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Material Core Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// Custom Styles
import swal from "sweetalert";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import { changeIBTInput } from "redux/actions/ibtAction";
import Cookies from "js-cookie";

import Axios from "axios";

import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { Button } from "@material-ui/core";

const Searchclient = () => {
  const classes = useEmployeeStyles();
  const { data } = useSelector((state) => state.ibtReducer);
  const dispatch = useDispatch();
  const token = Cookies.get("LINtoken");

  const classesb = useLandingpageStyles();
  const [clientID, setClientID] = useState("");
  const { select_client } = data;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeIBTInput({
        name,
        value: value,
      })
    );
  };
  const handleNext = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/get/client/private`,
        { select_client },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response !== null) {
        let nclientID =
          response?.data?.id +
          " - " +
          response?.data?.LastName +
          " - " +
          response?.data?.PostalCode +
          " - " +
          response?.data?.mobile_no +
          " - " +
          response?.data?.passNumber;
        dispatch(
          changeIBTInput({
            name: "client_found",
            value: true,
          })
        );
        setClientID(nclientID);
      } else {
        swal({
          title: "OPPS !!!!",
          text: `Kindly provide correct ID number`,
          icon: "error",
          buttons: false,
        });
        dispatch(
          changeIBTInput({
            name: "client_found",
            value: false,
          })
        );
      }
    } catch (error) {
      swal({
        title: "OPPS !!!!",
        text: `Kindly provide correct ID number`,
        icon: "error",
        buttons: false,
      });
      dispatch(
        changeIBTInput({
          name: "client_found",
          value: false,
        })
      );
    }
  };

  const isAllow = !select_client;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Enter client ID"
          name="select_client"
          value={select_client}
          placeholder="Enter PRC ID (e.g. - PRC0000)"
        />
        <div
          className={classes.buttonFlex}
          style={{
            display: "flex",
            marginTop: "24px",
            justifyContent: "center",
          }}
        >
          <Button
            className={`${classesb.button}`}
            disabled={isAllow}
            style={{ width: "30rem" }}
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            Find client
          </Button>
        </div>
      </form>
      {clientID.length > 0 ? (
        <div
          style={{
            margin: "0",
            marginTop: "15px",
            fontSize: "0.9rem",
            fontWeight: "bold",
          }}
        >
          <AppBar position="static">
            <Toolbar>
              <h3>{clientID}</h3>
            </Toolbar>
          </AppBar>
        </div>
      ) : (
        <div
          style={{
            margin: "0",
            marginTop: "15px",
            fontSize: "0.9rem",
            fontWeight: "bold",
          }}
        >
          <AppBar position="static">
            <Toolbar>
              <h3>
                Find client with ID and ask client to confirm BAC Surname,
                Post/Zip Code and Phone number for security.
              </h3>
            </Toolbar>
          </AppBar>
        </div>
      )}
    </div>
  );
};

export default Searchclient;
