import React, { Fragment } from "react";

// Custom Components
import QuoteRqst from "components/Auth/QuoteRqst/QuoteRqst";

const QuoteRequestPage = () => {
  return (
    <Fragment>
      <QuoteRqst />
    </Fragment>
  );
};

export default QuoteRequestPage;
