
import fetch from "isomorphic-fetch";
import cookie from 'js-cookie';

// get cookie
export const getCookie = (key) => {
  if(process.browser){
      return cookie.get(key)
  }
};

export const isAuth = () => {
  if(process.browser){
      const cookieChecked = getCookie('LINtoken');
      if(cookieChecked){
          if(localStorage.getItem('linguistLoggedIn')){
              return JSON.parse(localStorage.getItem('linguistLoggedIn'));
          }
          else{
              return false;
          }
      }
  }
}


export const getCashtclientQuotationList = () => {
    const token = cookie.get('LINtoken')
    return fetch(
      `${process.env.REACT_APP_BACKEND_API}/linguists/list/quote-request-cashclient`,
      {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
  };  

  export const getAccountclientQuotationList = () => {
    const token = cookie.get('LINtoken')
    return fetch(
      `${process.env.REACT_APP_BACKEND_API}/linguists/list/quote-request-accountclient`,
      {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
  };


  export const getCashbooklist = () => {
    const token = cookie.get('LINtoken')
    return fetch(
      `${process.env.REACT_APP_BACKEND_API}/linguists/cash-book`,
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
  };

  
export const getSupportSystem = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/linguists/support-system`,
    {
      method: "GET",
    }
  ).then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

  export const removeInhouseClient= (_id, token) => {

    return fetch(`${process.env.REACT_APP_BACKEND_API}/linguists/archive-inhouse-client/${_id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
    })
  };

  export const removePrivateClient= (_id, token) => {

    return fetch(`${process.env.REACT_APP_BACKEND_API}/linguists/archive-private-client/${_id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
    })
  };

  export const removePrivateClientRequest= (_id, token) => {

    return fetch(`${process.env.REACT_APP_BACKEND_API}/linguists/delete-prc-account-request/${_id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
    })
  };

  
  export const removeInhouseClientRequest= (_id, token) => {

    return fetch(`${process.env.REACT_APP_BACKEND_API}/linguists/delete-inc-account-request/${_id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
    })
  };

  export const getPrivateAssignments = () => {
    const token = cookie.get('LINtoken')
    return fetch(
      `${process.env.REACT_APP_BACKEND_API}/linguists/private-unconfirm-booking-list`,
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
  };

  export const getInhouseConfirmAssignments = () => {
    const token = cookie.get('LINtoken')
    return fetch(
      `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-booking-list`,
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
  };

  export const getCurrentAssignments = () => {
    const token = cookie.get('LINtoken')
    return fetch(
      `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-current-booking-list`,
      {
        method: "GET",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      },
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
  };
