import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { InputBase } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import Moment from "moment";

import SearchIcon from "@material-ui/icons/Search";
import { Button } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "50%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media (max-width:768px)"]: {
      width: "100%",
      padding: 0,
      display: "block",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearchInput = ({
  values,
  SearchText,
  setSearchText,
  setShow,
  load,
  setFiltered,
  setFilter,
  show,
}) => {
  const classes = useStyles();

  const handleInput = (value) => {
    setSearchText(value);

    if (value === "" || value === undefined) {
      load();
    } else {
      setFiltered(
        values?.filter((item) => {
          if (item.bill_no?.toString().toLowerCase() === value?.toLowerCase()) {
            return (
              item.bill_no?.toString().toLowerCase() === value?.toLowerCase()
            );
          } else if (
            Moment(item?.billing_month)
              .tz("Europe/London")
              .format("MMM YYYY")
              .toLowerCase() === value?.toLowerCase()
          ) {
            return (
              Moment(item?.billing_month)
                .tz("Europe/London")
                .format("MMM YYYY")
                .toLowerCase() === value?.toLowerCase()
            );
          } else if (
            item.total_invoice_no?.toString().toLowerCase() ===
            value?.toLowerCase()
          ) {
            return (
              item.total_invoice_no?.toString().toLowerCase() ===
              value?.toLowerCase()
            );
          } else if (
            item.total_bill_amount?.toString().toLowerCase() ===
            value?.toLowerCase()
          ) {
            return (
              item.total_bill_amount?.toString().toLowerCase() ===
              value?.toLowerCase()
            );
          } else if (
            Moment(item?.payment_date)
              .tz("Europe/London")
              .format("DD MMM YYYY")
              .toLowerCase() === value?.toLowerCase()
          ) {
            return (
              Moment(item?.payment_date)
                .tz("Europe/London")
                .format("DD MMM YYYY")
                .toLowerCase() === value?.toLowerCase()
            );
          } else if (item.payment_ref?.toLowerCase() === value?.toLowerCase()) {
            return item.payment_ref?.toLowerCase() === value?.toLowerCase();
          } else if (item.status?.toLowerCase() === value?.toLowerCase()) {
            return item.status?.toLowerCase() === value?.toLowerCase();
          }
        })
      );
      setFilter(Math.random());
    }
  };

  return (
    <div>
      <Paper
        onClick={(e) => e.preventDefault()}
        component="form"
        className={classes.root}
      >
        <InputBase
          className={classes.input}
          placeholder="Filter bill.."
          onChange={(e) => {
            handleInput(e.target.value);
          }}
          inputProps={{ "aria-label": "search google maps" }}
          value={SearchText}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Button onClick={() => setShow(!show)} color="purple">
          View all bills
        </Button>
      </Paper>
    </div>
  );
};

export default SearchInput;
