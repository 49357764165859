import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import Axios from "axios";
import Cookies from "js-cookie";

const Action = ({ id, month_duration }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("LINtoken");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [totalTimesheet, setTotalTimesheet] = React.useState(false);
  const getTotalTimesheet = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/count-bulk-interpreting-timesheet/bookingID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalTimesheet(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalTimesheet();
  }, []);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          minWidth: "10px",
          paddingLeft: "0px",
        }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {totalTimesheet > 0 ? (
          <MenuItem
            onClick={() =>
              history.push(
                `/linguist/listview-bulk-interpreting-timesheet/${id}`
              )
            }
          >
            View Timesheet ({totalTimesheet})
          </MenuItem>
        ) : null}

        {totalTimesheet < month_duration ? (
          <MenuItem
            onClick={() =>
              history.push(
                `/linguist/create-bac-bulk-interpreting-timesheet/${id}`
              )
            }
          >
            Close & Timesheet ({totalTimesheet})
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default Action;
