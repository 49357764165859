import { TableDiv } from "./style";

const Table = ({ children }) => {
  return (
    <TableDiv>
      <table>
        <thead>
          <tr>
            <th colSpan="2">Item</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </TableDiv>
  );
};

export default Table;
