import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { Link } from "react-router-dom";
import Action from "./Actions/Action";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ListCACAssignments({ quotationData, text }) {
  const token = Cookies.get("BACtoken");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    UnconfirmBookingList: [],
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });


  const {
    UnconfirmBookingList,
    reload,
  } = values;

  useEffect(() => {
    loadUnconfirmQuote();
  }, [reload]);

  const loadUnconfirmQuote = async (e) => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/accountclient/list/quotations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((unconfirmedJobs) => {
        setValues({
          ...values,
          UnconfirmBookingList: quotationData
            ? unconfirmedJobs.data.filter((item) => item.id === quotationData)
            : unconfirmedJobs.data,
        });
      })
    );
  };


  const a = UnconfirmBookingList?.map((uc) => uc);

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Assignment ID</TableCell>
                {/* <TableCell>Quote Request For</TableCell> */}
                <TableCell>Remote / Venue</TableCell>
                <TableCell>Request Details</TableCell>
                <TableCell>Budget</TableCell>
                <TableCell>Source language</TableCell>
                <TableCell>Target language</TableCell>

                <TableCell>Skill Level</TableCell>
                <TableCell>Timezone</TableCell>
                <TableCell>Quote Needed By</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {UnconfirmBookingList.length > 0 ? (
              <TableBody>
                {UnconfirmBookingList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((list, i) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    <TableCell align={list.align}>
                      <Link
                        to={`/account-client/view-account-client-quotation/${list?._id}`}
                      >
                        {list.id}
                      </Link>
                    </TableCell>

                    <TableCell>{list.country}</TableCell>
                    <TableCell>{list.request_details}</TableCell>
                    <TableCell>{list.project_fix_budget}</TableCell>
                    <TableCell>{list.source_language}</TableCell>
                    <TableCell>{list.target_language}</TableCell>
                    <TableCell>{list.target_skills_required}</TableCell>
                    <TableCell>{list.timezone}</TableCell>
                    <TableCell>
                      {moment(list.quote_by).format("DD-MMM-YYYY")}
                    </TableCell>
                    <TableCell>
                      <Action
                        id={list._id}
                        date={moment(list?.quote_by) < moment()}
                        load={loadUnconfirmQuote}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={UnconfirmBookingList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
