import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import Axios from "axios";
import Cookies from "js-cookie";

const Action = ({ id, linSignature, bacSignature, date, dispute, invoice, invoiceID }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("LINtoken");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          minWidth: "10px",
          paddingLeft: "0px",
        }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => history.push(`/linguist/view-timesheet/${id}`)}
        >
          View Timesheet
        </MenuItem>

        {!linSignature && bacSignature ? (
          <MenuItem
            onClick={() =>
              history.push(`/linguist/confirm-bac-timesheet/${id}`)
            }
          >
            Confirm Timesheet
          </MenuItem>
        ) : null}

        {invoice ? (
          <MenuItem
            onClick={() => history.push(`/linguist/view-invoice/${invoiceID}`)}
          >
            View Invoice
          </MenuItem>
        ) : null}

        {
          (bacSignature || date) && linSignature && !dispute && !invoice && (
            <MenuItem
              onClick={() =>
                history.push(
                  `/linguist/current-assignment/bac-bulk/invoice/${id}`
                )
              }
            >
              Create Invoice
            </MenuItem>
          ) // if accepted (signatureByCAC) is true then invoice action is comming here
        }
      </Menu>
    </div>
  );
};

export default Action;
