import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import Cookies from 'js-cookie'
import Axios from "utils/axios";
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import InvitedLinList from 'components/Admin/HrPanel/InvitedLinguist/InvitationList'

const useStyles = makeStyles({
  Button: {
    width: "100%",
  },
});


const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const [hr_panel, setAdminAccess] = useState(null)
  const buttonClass = useStyles();

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminAccess(response?.data.hr_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);


  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {hr_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>Invited Linguist</p>
                  <div style={{ display: "flex" }}>
                    <Button
                      className={buttonClass.Button}
                      onClick={() =>
                        history.push("/admin/send-invitation")
                      }
                      color="purple"
                    >
                      Send Invitation
                    </Button>
                    <Button
                      className={buttonClass.Button}
                      onClick={() =>
                        window.open("/invitation-removal-request", "_blank")
                      }
                      color="purple"
                    >
                      Removal Form
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <InvitedLinList />
                </CardBody>
              </Card>
            </GridItem>
          ) : hr_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
