import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import LinguistLayout from "layout/LinguistLayout";
import history from "utils/history";
import { Button, useMediaQuery } from "@material-ui/core";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import BookingOptions from "components/Linguist/ManagePrivateClient/BookingOption";
import DirectInvoicePRC from "components/Linguist/ManagePrivateClient/DirectInvoice/DirectInvoice";
import CardBody from "components/Card/CardBody";

const SendPrivateClientRegPage = () => {
    const classes = useDashboardStyles();
    const matches = useMediaQuery('(max-width:768px)')
    return (
        <LinguistLayout>
            <Linguist>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <p className={classes.cardTitleWhite}>Direct invoice to PRCs</p>
                                <IconButton color='inherit'><ArrowBackIcon

                                    onClick={() => history.goBack()}
                                ></ArrowBackIcon></IconButton>
                            </CardHeader>

                        </Card>
                        <Card>
                            <CardHeader
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <BookingOptions />
                            </CardHeader>
                            <CardBody>
                                {!matches ? <div>
                                    <Button onClick={() => history.push('/linguist/create-prc-invoice')} style={{ marginRight: '10px' }} variant='contained' color='primary'>Raise Invoice </Button>
                                    <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/private-client/direct-invoice-list')} variant='contained' color='primary'> Direct invoice to PRCs</Button>
                                </div> : <div>
                                    <Button onClick={() => history.push('/linguist/create-prc-invoice')} style={{ marginRight: '10px' }} variant='contained' color='primary'>Raise Invoice </Button>
                                    <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/private-client/direct-invoice-list')} variant='contained' color='primary'> Direct invoice to PRCs</Button>
                                </div>}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <DirectInvoicePRC />
                            </CardBody>
                        </Card>
                        <br />
                    </GridItem>
                </GridContainer>
            </Linguist>
        </LinguistLayout>
    );
};

export default SendPrivateClientRegPage;
