import React, { useState, useEffect, Fragment } from "react";
import "./Verified.css";
import { Header, Rating } from "semantic-ui-react";
import Axios from "axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

function Verified({ id }) {
  const [timesheetRating, setTimesheetRating] = useState(0);
  const [reportRating, setReportRating] = useState(0);

  const getRating = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/timesheet-feedback-list/linID/${id}`
      );
      setTimesheetRating(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectReportRating = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/report-feedback-list/linID/${id}`
      );
      setReportRating(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRating();
    getProjectReportRating();
  }, []);

  let totalFeedbackByClient = 0,
    avgFeedbackByClient = 0;
  let totalTransFeedbackByClient = 0,
    avgTransFeedbackByClient = 0;

  // calculate interpreting feedback by client
  for (let i = 0; i <= timesheetRating?.length - 1; i++) {
    {
      totalFeedbackByClient += timesheetRating[i].feedbackByClient;
      avgFeedbackByClient = totalFeedbackByClient / timesheetRating?.length;
    }
  }

  // calculate translation feedback by client
  for (let i = 0; i <= reportRating?.length - 1; i++) {
    {
      totalTransFeedbackByClient += reportRating[i].feedbackByClient;
      avgTransFeedbackByClient =
        totalTransFeedbackByClient / reportRating?.length;
    }
  }

  return (
    <Fragment>
      <div
        style={{
          padding: "20px",
          backgroundImage:
            "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
          position: "relative",
        }}
        className="p-8 bg-white relative"
      >
        <Header color="purple" as="h2">
          Client Ratings
        </Header>
        <div style={{ marginBottom: "32px" }} className="mb-4">
          <h3 style={{ whiteSpace: "nowrap" }} className="text-2xl">
            Interpreting
          </h3>
          <Rating
            maxRating={5}
            rating={avgFeedbackByClient}
            icon="star"
            size="massive"
          />
        </div>

        <div style={{ marginBottom: "32px" }} className="mb-4">
          <h3 style={{ whiteSpace: "nowrap" }} className="text-2xl">
            Translation and other services
          </h3>
          <Rating
            maxRating={5}
            rating={avgTransFeedbackByClient}
            icon="star"
            size="massive"
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Verified;
