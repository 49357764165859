import React, { Fragment } from "react";

// Custom Components
import ViewBIManager from "components/Auth/ViewBIManager/LinguistsCollectiveBooking";

const ViewBIManagerPage= () => {
  return (
    <Fragment>
      <ViewBIManager />
    </Fragment>
  );
};

export default ViewBIManagerPage
