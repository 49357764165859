import React from "react";
import { useParams } from "react-router-dom";
import { PRC_SERVICE_TYPE } from "data";
import CustomRadio from "components/Common/CustomRadio";
import history from "utils/history";
import LandingPageLayout from "layout/LandingPageLayout";
import CustomAvatar from "components/Common/CustomAvatar";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Header } from "semantic-ui-react";
const PRCFormSelection = () => {
  const { clientID, linID, clientPC, clientLN, clientobj, sppoobj, sppoID } =
    useParams();

  const classes = useEmployeeStyles();
  const handleRadioChange = (e) => {
    const { value } = e.target;
    if (sppoobj) {
      if (value.includes("booking")) {
        history.push(
          `/inhouse-interpreter-booking/${sppoobj}/${sppoID}/${clientID}/${clientPC}/${clientLN}/${clientobj}`
        );
      } else if (value.includes("translation")) {
        history.push(
          `/inhouse-translation-order/${sppoobj}/${sppoID}/${clientID}/${clientPC}/${clientLN}/${clientobj}`
        );
      }
    } else {
      if (value.includes("booking")) {
        history.push(
          `/inhouse-interpreter-booking/${linID}/${clientID}/${clientPC}/${clientLN}/${clientobj}`
        );
      } else if (value.includes("translation")) {
        history.push(
          `/inhouse-translation-order/${linID}/${clientID}/${clientPC}/${clientLN}/${clientobj}`
        );
      }
    }
  };

  return (
    <LandingPageLayout>
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
            <Card>
              <CardBody>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomAvatar />
                </div>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <Header style={{ margin: "0" }} as="h3">
                    Linguists Collective (LC)
                  </Header>
                  <Header style={{ margin: "0" }} as="h3">
                    Request booking,Request translation ordering form
                  </Header>
                </div>
                <form className={classes.form} noValidate autoComplete="off">
                  <CustomRadio
                    onChange={handleRadioChange}
                    label="Choose service"
                    width="100%"
                    data={PRC_SERVICE_TYPE}
                    required
                  />
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </LandingPageLayout>
  );
};
export default PRCFormSelection;
