import { CHANGE_LINGUIST_PROFILE, UPDATE_LINGUIST_PROFILE } from "redux/actions/actionTypes"


export const changeLinguistProfileInput = data => ({
    type: CHANGE_LINGUIST_PROFILE,
    payload: data
})

export const updateLinguistProfile = data => ({
    type: UPDATE_LINGUIST_PROFILE,
    payload: data
})