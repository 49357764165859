import React, { useState, useEffect } from "react";
import { Table, Header } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomSelect from "components/Common/CustomSelect";
import CustomInputSingle from "components/Common/CustomInputSingle";
import { getCurrencyDetails } from "redux/actions/admin/controller";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
  PinkColor: {
    border: " 1px solid #a333c8 !important",
    borderRadius: "3pt !important",
  },
});
const CurrencyCalculator = () => {
  const classes = styles();
  const [toChange, setToChange] = useState("");
  const [changed, setChanged] = useState("");
  const [toChangeRate, setToChangeRate] = useState(0);
  const [changedRate, setChangedRate] = useState([0, "GBP"]);
  const [base, setBase] = useState(0);
  const dispatch = useDispatch();
  const { currencyDetails } = useSelector((state) => state.adminControlData);

  useEffect(() => {
    dispatch(getCurrencyDetails());
  }, []);

  const handletoChange = (e) => {
    setToChange(e.target.value);
    updateBaseRate(Object.values(currencyDetails?.country), e.target.value);
  };
  const handleChanged = (e) => {
    setChanged(e.target.value);

    updateChangedRate(Object.values(currencyDetails?.country), e.target.value);
  };

  const updateChangedRate = (country, rate) => {
    country.forEach((item, i) => {
      if (rate === item) {
        setChangedRate([
          Object.entries(currencyDetails?.rates)[i][1],
          Object.entries(currencyDetails?.rates)[i][0],
        ]);
      }
    });
  };

  const updateBaseRate = (country, rate) => {
    country.forEach((item, i) => {
      if (rate === item) {
        setBase([
          Object.entries(currencyDetails?.rates)[i][1],
          Object.entries(currencyDetails?.rates)[i][0],
        ]);
      }
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <Header style={{ marginRight: "5px" }} as="h3">
          Currency Conversion Calculator
        </Header>{" "}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Table color="purple">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign={"center"}>
                  Country/Currency (from)
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>Amount</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>
                  Base Currency/GBP
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>
                  Country/Currency (to)
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>
                  Currency Rate
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>
                  Total Converted
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell width={2}>
                  <CustomSelect
                    defaultValue={currencyDetails?.country.GBP}
                    onChange={handletoChange}
                    width="100%"
                    value={toChange}
                    variant={"outlined"}
                    data={[
                      currencyDetails?.country.GBP,
                      currencyDetails?.country.EUR,
                      currencyDetails?.country.AUD,
                      currencyDetails?.country.USD,
                      currencyDetails?.country.CAD,
                      currencyDetails?.country.TRY,
                      currencyDetails?.country.SGD,
                      currencyDetails?.country.HUF,
                      currencyDetails?.country.NZD,
                      currencyDetails?.country.RON,
                      currencyDetails?.country.BRL,
                      currencyDetails?.country.ARS,
                      currencyDetails?.country.CNY,
                      currencyDetails?.country.RUB,
                      currencyDetails?.country.MYR,
                      currencyDetails?.country.JPY,
                      currencyDetails?.country.HKD,
                      currencyDetails?.country.INR,
                      currencyDetails?.country.IDR,
                      currencyDetails?.country.BDT,
                      currencyDetails?.country.SAR,
                      currencyDetails?.country.EGP,
                      currencyDetails?.country.ZAR,
                      currencyDetails?.country.UAE,
                      currencyDetails?.country.QAR,
                      currencyDetails?.country.NGN,
                    ]}
                    required
                  />
                </Table.Cell>

                <Table.Cell width={2}>
                  {" "}
                  <CustomInputSingle
                    placeholder="0000000000.0000"
                    width={"100%"}
                    type={"number"}
                    onChange={(e) =>
                      setToChangeRate(
                        (e.target.value = Math.max(
                          0,
                          parseFloat(e.target.value)
                        )
                          .toString()
                          .slice(0, 16))
                      )
                    }
                    variant={"outlined"}
                    inputProps={{ className: classes.PinkColor }}
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  <CustomInputSingle
                    width={"100%"}
                    variant={"outlined"}
                    defaultValue={`${currencyDetails?.rates.GBP} ${currencyDetails?.base}`}
                    value={`${(toChangeRate / base[0]).toFixed(4)} ${
                      currencyDetails?.base
                    }`}
                    inputProps={{ readOnly: true }}
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  <CustomSelect
                    // label={"select country"}
                    defaultValue={currencyDetails?.country.GBP}
                    onChange={handleChanged}
                    width="100%"
                    value={changed}
                    variant={"outlined"}
                    data={[
                      currencyDetails?.country.GBP,
                      currencyDetails?.country.EUR,
                      currencyDetails?.country.AUD,
                      currencyDetails?.country.USD,
                      currencyDetails?.country.CAD,
                      currencyDetails?.country.TRY,
                      currencyDetails?.country.SGD,
                      currencyDetails?.country.HUF,
                      currencyDetails?.country.NZD,
                      currencyDetails?.country.RON,
                      currencyDetails?.country.BRL,
                      currencyDetails?.country.ARS,
                      currencyDetails?.country.CNY,
                      currencyDetails?.country.RUB,
                      currencyDetails?.country.MYR,
                      currencyDetails?.country.JPY,
                      currencyDetails?.country.HKD,
                      currencyDetails?.country.INR,
                      currencyDetails?.country.IDR,
                      currencyDetails?.country.BDT,
                      currencyDetails?.country.SAR,
                      currencyDetails?.country.EGP,
                      currencyDetails?.country.ZAR,
                      currencyDetails?.country.UAE,
                      currencyDetails?.country.QAR,
                      currencyDetails?.country.NGN,
                    ]}
                    required
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  {" "}
                  <CustomInputSingle
                    placeholder="0000000000.0000"
                    width={"100%"}
                    value={`  ${changedRate[0]} ${changedRate[1]} = 1 GBP `}
                    variant={"outlined"}
                    inputProps={{ readOnly: true }}
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  <CustomInputSingle
                    width={"100%"}
                    value={`${(
                      (toChangeRate * changedRate[0]) /
                      base[0]
                    ).toFixed(4)} ${changedRate[1]}`}
                    defaultValue={0}
                    variant={"outlined"}
                    inputProps={{ readOnly: true }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default CurrencyCalculator;
