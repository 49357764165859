import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import SearchView from "./Search/SearchView";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import { useToasts } from "react-toast-notifications";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ text }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("BACtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `/api/accountclient/list-own-active-bacuser`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, bookingList: response?.data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `/api/accountclient/find-own-active-bacuser/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct client id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });
        addToast("client found", { appearance: "success" });
      } else {
        addToast("client not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct client id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <div>
      <SearchView
        load={loadbookingList}
        handleSearch={handleSearch}
        setSearchText={setSearchText}
        SearchText={SearchText}
        setShow={setShow}
        show={show}
      />
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Unique ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>First name</TableCell>
                    <TableCell>Last name</TableCell>
                    <TableCell>Email address</TableCell>
                    <TableCell>Mobile number</TableCell>
                    <TableCell>Registration Date</TableCell>
                  </TableRow>
                </TableHead>

                {bookingList.length > 0 ? (
                  <TableBody>
                    {bookingList

                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list, i) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                          <TableCell>
                            <Link
                              to={`/account-client/additional-user-profile/${list._id}`}
                            >
                              {list.id}
                            </Link>
                          </TableCell>
                          <TableCell>{list.title}</TableCell>
                          <TableCell>{list.FirstName}</TableCell>
                          <TableCell>{list.LastName}</TableCell>
                          <TableCell>{list.email}</TableCell>
                          <TableCell>{list.mobile_no}</TableCell>
                          <TableCell>
                            {Moment(list?.createdAt).format(
                              "DD MMM YYYY - kk:mm:ss"
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
