import { CHANGE_INITIALREG_INPUT } from "redux/actions/actionTypes";

const INITIAL_STATE = {
    data: {
        title: '',
        FirstName: '',
        LastName: '',
        AddressLine1: '',
        AddressLine2: '',
        City: '',
        PostalCode: '',
        Region: '',
        Country: '',
        Nationality: '',
        other_nationality: [],
        Birthday: '',
        email: '',
        password: '',
        confirm_password: '',
        gdpr: false
    }
}

const irReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case CHANGE_INITIALREG_INPUT:
        return {
          ...state,
          data: {
            ...state.data,
            [payload.name]: payload.value
          }
        }
  
      default:
        return state;
    }
  };
  
  export default irReducer;