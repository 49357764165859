import React, { useState, useEffect } from "react";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Link } from "react-router-dom";
//Semantic UI imorts
import { Button, Table } from "semantic-ui-react";

import Axios from "axios";

//Sweet alert
import swal from "sweetalert";

//Cookies import
import Cookies from "js-cookie";

//Action
import ActionINC from "./ActionINC";
//search
import SearchView from "../TranslationSearch/SearchView";
import { useToasts } from "react-toast-notifications";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";

const TransTableThree = ({ translationData }) => {
  const classes = useDashboardStyles();
  const token = Cookies.get("LINtoken");
  const comparisonDate = moment(new Date()).format("MM/DD/YYYY");
  const { addToast } = useToasts();
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });
  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: null,
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const {
    name,
    error,
    success,
    bookingList,
    cacAndBacAccess,
    removed,
    reload,
  } = values;
  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-inhouse-PR-Translation/${searchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (searchText == undefined || searchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });

        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  let source = Axios.CancelToken.source();

  const loadbookingList = async () => {
    const linID = Cookies.get("linID");
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-closed-Translation-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({
        ...values,
        bookingList: translationData
          ? response?.data.filter(
            (item) => item?.id === translationData
          )
          : response?.data,
        reload: false,
      });
      translationData && setShow(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadbookingList();

    return () => {
      source.cancel();
    };
  }, [reload]);

  const handleAccept = async (id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-Translation-accept/TranslationID/${id}`,
        { email: email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setValues({ ...values, reload: true });
    } catch (error) {
      console.log(error.response);
    }
  };

  const decline = async (decline, id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-Translation-reject/TranslationID/${id}`,
        { email: email, declines: decline },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setValues({ ...values, reload: true });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDecline = async (e, id, email) => {
    const declines = e.target.value;
    swal(`Are you sure you want to decline this job?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          decline(declines, id, email);

        default:
          break;
      }
    });
  };

  const handleViewAll = async (e, list) => {
    e.preventDefault();
    setViewAll({ ...viewAll, open: true, list: list });
  };

  return (
    <div>
      {!translationData && (
        <SearchView
          show={show}
          load={loadbookingList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          searchText={searchText}
          setShow={setShow}
        />
      )}
      {show ? (
        <div style={{ overflowX: "scroll" }}>
          {" "}
          <Table color={"pink"}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                <Table.HeaderCell>Document type</Table.HeaderCell>
                <Table.HeaderCell>Timezone</Table.HeaderCell>
                <Table.HeaderCell>Deadline</Table.HeaderCell>
                <Table.HeaderCell>Source language</Table.HeaderCell>
                <Table.HeaderCell>Target language</Table.HeaderCell>
                <Table.HeaderCell>Target language skill level</Table.HeaderCell>
                <Table.HeaderCell>Estimated word count</Table.HeaderCell>
                <Table.HeaderCell>Estimate paid</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {bookingList ? (
              <Table.Body>
                {bookingList.map((list) => (
                  <Table.Row
                    style={
                      list?.reportID?.signatureByClientTrue ||
                        moment(comparisonDate).isSame(
                          moment(list?.reportID?.autoAcceptDate).format("MM/DD/YYYY"),
                          "days"
                        )
                        ? { backgroundColor: "lightgreen" }
                        : { backgroundColor: "yellow" }
                    }
                  >
                    <Table.Cell>
                      <Link
                        to={`/linguist/inc-view-translation-assignment/${list._id}`}
                      >
                        {list?.id}
                      </Link></Table.Cell>

                    <Table.Cell>
                      {list.document_type}
                    </Table.Cell>
                    <Table.Cell>{list.timezone}</Table.Cell>
                    <Table.Cell>
                      {list.deadline
                        ? moment(list.deadline).format(
                          "DD-MMM-YYYY : HH:mm:ss"
                        )
                        : "N/A"}
                    </Table.Cell>
                    <Table.Cell>
                      {list.source_language}
                    </Table.Cell>
                    <Table.Cell>
                      {list.target_language}
                    </Table.Cell>
                    <Table.Cell>
                      {list.target_language_skill_level}
                    </Table.Cell>
                    <Table.Cell>{list.count}</Table.Cell>
                    <Table.Cell>
                      {list?.currency_new?.substring(0, 3)}{" "}
                      {list.estimate}
                    </Table.Cell>
                    <Table.Cell>
                      {list?.reportID?.dispute
                        ? "Project report disputed"
                        : list?.reportID?.signatureByClientTrue && list?.reportID?.signatureByLinTrue
                          ? "Project report accepted by both"
                          : list?.reportID?.signatureByClientTrue
                            ? "Project report created by client"
                            : list?.reportID?.signatureByLinTrue
                              ? "Project report accepted by linguist"
                              : "Project report not confirmed"}
                    </Table.Cell>
                    <Table.Cell>
                      {list.startdateArray?.length > 1 ? (
                        <Button
                          color="purple"
                          onClick={(e) => handleViewAll(e, list)}
                        >
                          View All
                        </Button>
                      ) : (
                        <ActionINC
                          date={moment() > moment(list?.reportID?.autoAcceptDate) || moment() === moment(list?.reportID?.autoAcceptDate)}
                          accepted={list?.reportID?.signatureByClientTrue}
                          id={list?.reportID?._id}
                          dispute={list?.reportID?.dispute}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            ) : null}
          </Table>{" "}
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={bookingList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TransTableThree;
