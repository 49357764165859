import React, { Fragment, useState } from "react";

// Material Components
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

// Custom Components
import CustomInput from "components/Common/CustomInput";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Axios from "axios";
import swal from "sweetalert";
import CustomDate from "components/Common/CustomDate";
import CustomCaptcha from "components/Common/CustomCaptcha";
import { EMAIL_REGEX } from "data";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    minWidth: "300px",
    padding: theme.spacing(2, 4, 2),
    textAlign: "center",

    "& .MuiButton-root": {
      marginTop: "15px",
    },
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState();
  const [dob, setDob] = useState();
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [notValid, setNotValid] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/forgot-password`,
        { email: email, Birthday: dob }
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (date) => {
    setDob(date);
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };

  return (
    <div>
      <Button color="primary" onClick={handleOpen}>
        Forgot Password?{" "}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Forgot Password</p>
              </CardHeader>
              <CardBody>
                <Fragment>
                  <CustomDate
                    label="Enter your birth date..."
                    width="100%"
                    onChange={handleDateChange}
                    value={dob}
                  />
                  <CustomInput
                    onChange={handleChange}
                    value={email}
                    label="Enter your email..."
                  />
                  {notValid && (
                    <>
                      <small style={{ color: "red", float: "left" }}>
                        enter a valid email address
                      </small>
                    </>
                  )}
                  <CustomCaptcha onChange={handleCaptcha} />

                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    disabled={!email || !dob || loading || !captcha || notValid}
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    {loading ? "Sending..." : "Send Reset Link"}
                  </Button>
                </Fragment>
              </CardBody>
            </Card>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
