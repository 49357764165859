import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Header } from "semantic-ui-react";
import Cookies from 'js-cookie'
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useSelector } from "react-redux";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import EventsForm from "components/Admin/ManagementPanel/EventsForm/EventsForm"
import EventMembers from "components/Admin/ManagementPanel/EventsForm/EventMembers"
import EventMemberList from "./EventMemberList";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from "@material-ui/core";
import history from 'utils/history'


const AssignmentPage = () => {
  const classes = useDashboardStyles();

  const [management_panel, setAdminAccess] = useState(null)
  const { providedQuotes } = useSelector(state => state.unconfirmQuotesReducer)


  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAdminAccess(response?.data.management_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);


  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {management_panel ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }} color="primary">
                  <p className={classes.cardTitleWhite}>Meetings and Events</p>
                  <IconButton color='inherit' onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
                </CardHeader>
                <CardBody>
                  <EventsForm />
                </CardBody>
                <CardBody>
                  <EventMembers />
                </CardBody>

                <CardBody>
                  {providedQuotes &&
                    <EventMemberList id={providedQuotes} />}
                </CardBody>

              </Card>
            </GridItem>

            :
            management_panel === 0 ?
              <Header as='h3'>Access denied</Header>
              :
              <Header as='h3'>Loading...</Header>
          }

        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
