import CustomDate from "components/Common/CustomDate";
import html2canvas from "html2canvas";
import { Button } from "semantic-ui-react";
import jsPDF from "jspdf";
import ReactToExcel from "react-html-table-to-excel";
import { DateFilterDiv } from "./style";
//custom styles
import { makeStyles } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
const styles = makeStyles({
  btn: {
    background: "#a333c8",
    color: "white",
    border: 0,
    borderRadius: "3pt",
    padding: "10px",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    textTransform: "capitalize",
    margin: 10,
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "-15px",
    cursor: "pointer",
  },
});
const DateFilter = () => {
  const classes = styles();
  const matches = useMediaQuery("(max-width:768px)");
  const handlePdf = () => {
    const input = document.getElementById("report-excel");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("managment-report.pdf");
    });
  };
  return (
    <>
      {matches ? (
        <div style={{ marginBottom: 30 }}>
          <h3 style={{ marginBottom: "-20px" }}>Select Dates: </h3>
          <CustomDate
            name="from_date"
            label="From Date"
            width="50%"
            style={{
              marginLeft: "0px",
              marginRight: "15px",
            }}
          />
          <CustomDate
            name="to_date"
            label="To Date"
            width="50%"
            style={{
              marginLeft: "0px",
              marginRight: "5px",
            }}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <ReactToExcel
              className={classes.btn}
              table="report-excel"
              filename="report"
              sheet="sheet 1"
              buttonText="Export in Excel"
            />
            <Button
              variant="outlined"
              color="purple"
              style={{
                // margin: "10px",
                marginBottom: "-25px",
              }}
              onClick={handlePdf}
            >
              Export in PDF
            </Button>
          </div>
        </div>
      ) : (
        <DateFilterDiv>
          <h3 style={{ marginBottom: "-20px" }}>Select Dates: </h3>
          <CustomDate
            name="from_date"
            label="From Date"
            width="15%"
            style={{
              marginLeft: "0px",
              marginRight: "15px",
            }}
          />
          <CustomDate
            name="to_date"
            label="To Date"
            width="15%"
            style={{
              marginLeft: "0px",
              marginRight: "5px",
            }}
          />

          <ReactToExcel
            className={classes.btn}
            table="report-excel"
            filename="report"
            sheet="sheet 1"
            buttonText="Export in Excel"
          />
          <Button
            variant="outlined"
            color="purple"
            style={{
              marginBottom: "-25px",
            }}
            onClick={handlePdf}
          >
            Export in PDF
          </Button>
        </DateFilterDiv>
      )}
    </>
  );
};

export default DateFilter;
