import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "semantic-ui-react";
import TextTruncate from "react-text-truncate";
import moment from "moment";
import ReactToExcel from "react-html-table-to-excel";

import {
  getContactRequest,
  removeContact,
  getCookie,
} from "../../../action/manager";
import Moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import swal from "sweetalert";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  button: {
    background: "#e0e1e2 none",
    border: 0,
    color: "rgba(0,0,0,.6)",
    padding: 12,
    marginRight: 10,
    borderRadius: "3pt",
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
    fontSize: 13,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [message, setMessage] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = getCookie("EMPtoken");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = () => {
    getContactRequest().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setValues({ ...values, bookingList: data });
      }
    });
  };

  const deleteContact = (_id) => {
    removeContact(_id, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        swal({
          icon: "success",
          text: `${data.message}`,
          buttons: false,
        });
        loadbookingList();
      }
    });
  };

  const handleDelete = async (value) => {
    swal("Are you sure you want to permanently delete this?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          deleteContact(value);

        default:
          break;
      }
    });
  };

  const a = bookingList?.map((uc) => uc);
  const exportPDF = (e) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    var totalPages = doc.internal.getNumberOfPages();
    var i;

    for (i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
      // doc.setTextColor(150);
      doc.text(
        50,
        doc.internal.pageSize.height - 30,
        "Strictly Company Confidential © linguistscollective.com."
      );
    }

    const title = "Contact List";
    const headers = [
      [
        "First\nName",
        "Last\nName",
        "Email",
        "Phone\nNumber",
        "Mobile\nNumber",
        "Message",
        "Contact\nDate",
      ],
    ];

    const data = bookingList.map((list) => [
      list.first_name,
      list.last_name,
      list.email,
      list.phone_no,
      list.mobile_no,
      list.message,
      moment(list.createdAt).format("DD/MM/YYYY") +
        "\n" +
        moment(list.createdAt).format("HH:mm"),
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        valign: "middle",
        // halign: 'justify'
      },
      columnStyles: { 0: { fillColor: [138, 43, 226], textColor: "white" } },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    var string = doc.output("datauristring");
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    var x = window.open();
    x.document.open();
    x.document.write(embed);
    x.document.close();
  };

  return (
    <Paper className={classes.root}>
      <ReactToExcel
        // className = "btn"
        table="export"
        filename="excelFile"
        sheet="sheet 1"
        buttonText="Export CSV"
        className={classes.button}
      />

      <Button style={{ padding: "12px" }} onClick={exportPDF}>
        Export PDF
      </Button>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="export">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookingList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((list) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={list._id}>
                  <TableCell>{list.first_name}</TableCell>
                  <TableCell>{list.last_name}</TableCell>
                  <TableCell>{list.email}</TableCell>
                  <TableCell>{list.phone_no}</TableCell>
                  <TableCell>{list.mobile_no}</TableCell>
                  <TableCell>
                    {/* <Link to={`/admin/view-contact-Request/${list._id}`}>
                  {list.message}
                </Link> */}
                    <TextTruncate
                      line={1}
                      element="span"
                      truncateText="…"
                      text={list.message}
                      textTruncateChild={
                        <a href={`/employee/view-contact-Request/${list._id}`}>
                          Read More
                        </a>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {Moment(list?.createdAt).format("DD MMM YYYY - kk:mm:ss")}
                  </TableCell>

                  <TableCell>
                    <Button onClick={() => handleDelete(list._id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={bookingList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
