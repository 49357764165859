import React from "react";
import { useSelector } from "react-redux";

import { Card, Header } from "semantic-ui-react";

const About = () => {
  const { data } = useSelector((state) => state.updateProfileReducer);

  return (
    <div
      
      style={{
        width: "100%",
        padding: "1rem",
        backgroundImage:
          "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
      }}
    >
      <Card.Content>
        <Header color="purple" as="h2">
          About Me
        </Header>
        <Card.Description>{data.about}</Card.Description>
      </Card.Content>
    </div>
  );
};

export default About;
