import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Action from "./Action";
import { Table } from "semantic-ui-react";
import SearchView from "./Search/SearchAssignment";
import Cookies from "js-cookie";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ translationData, text }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    translationList: [],
    removed: false,
    reload: false,
  });

  const { translationList, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/list-PRC-translation-Report`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues({
        ...values,
        translationList: translationData
          ? response?.data.filter((item) => item.id === translationData)
          : response?.data,
      });
      translationData && setShow(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-prc-translation-Report`,
        { id: SearchText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({
          ...values,
          translationList: [response?.data],
          reload: false,
        });
        // translationList=[response?.data]
        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.translationList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <div>
      {!translationData && (
        <SearchView
          load={loadbookingList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          SearchText={SearchText}
          setShow={setShow}
          show={show}
        />
      )}
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table color={"pink"}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                    <Table.HeaderCell>Document type</Table.HeaderCell>
                    <Table.HeaderCell>Timezone</Table.HeaderCell>
                    <Table.HeaderCell>Deadline</Table.HeaderCell>
                    <Table.HeaderCell>Source language</Table.HeaderCell>
                    <Table.HeaderCell>Target language</Table.HeaderCell>
                    <Table.HeaderCell>
                      Target language skill level
                    </Table.HeaderCell>
                    <Table.HeaderCell>Estimated word count</Table.HeaderCell>
                    <Table.HeaderCell>Estimate paid</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Action
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {translationList.length > 0 ? (
                  <Table.Body>
                    {translationList.map((list) => (
                      <Table.Row>
                        <Table.Cell>
                          {/* <Link to={`/admin/view-linguist-client/${list._id}`}> */}
                          {list?.prcTranslationID?.id}
                          {/* </Link> */}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.prcTranslationID?.document_type}
                        </Table.Cell>
                        <Table.Cell>{list?.timezone}</Table.Cell>
                        <Table.Cell>
                          {moment(list?.deadline).format("DD-MMM-YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.prcTranslationID?.source_language}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.prcTranslationID?.target_language}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.prcTranslationID?.target_linguist_skill_level}
                        </Table.Cell>
                        <Table.Cell>{list?.prcTranslationID?.count}</Table.Cell>
                        <Table.Cell>
                          {list?.prcTranslationID?.estimate}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.prcTranslationID?.status}
                        </Table.Cell>
                        <Table.Cell>
                          <Action
                            accepted={list.signatureByLin}
                            client={list.id}
                            id={list._id}
                            trans={true}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                ) : null}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={translationList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
