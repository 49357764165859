import React, { useState, useRef, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "utils/axios";
import swal from "sweetalert";
import Cookies from "js-cookie";

import CustomModal from "components/Common/CustomModal";
import { Button, Header, Icon, Loader, Modal } from "semantic-ui-react";
import CustomTextInput from "components/Common/CustomTextInput";
import SignatureCanvas from "react-signature-canvas";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";



const Action = ({ id, ID, name, charge_id, currency, estimate, load, bank_pay, payment, approve, reject, applicationclosingdate }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get('token')

  const [showMarkPaid, setShowMarkPaid] = React.useState(false);
  const [showRefund, setShowRefund] = React.useState(false);

  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  let sigPad = useRef({});

  const [showNote, setShowNote] = React.useState(false);
  const [viewNote, setViewNote] = React.useState(false);

  const [showMessage, setShowMessage] = React.useState(false);
  const [viewMessage, setViewMessage] = React.useState(false);

  const [totalnote, setTotalNote] = React.useState(false);
  const [totalmessage, setTotalMessage] = React.useState(false);

  const [adminId, setAdminID] = useState("");
  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const [pay, setPay] = React.useState({
    note: "",
    payment_ref: "",
    estimate_paid_amount: "",
    payment_date: "",
    signature: "",
    cancel_by_admin_client: "",
    refund_date: "",
    refund_reason: "",
    refund_amount: "",
    email: "",
  });

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowNote(false);
    setShowMessage(false);
  };

  const handleShowNote = () => {
    setShowNote(!showNote);
  };

  const handleShowMessage = () => {
    setShowMessage(!showMessage);
  };

  const handleMarkPaid = (e) => {
    setAnchorEl(false);
    setShowMarkPaid(!showMarkPaid);
    setSignature(false);
  };

  const handleRefund = (e) => {
    setAnchorEl(false);
    setShowRefund(!showRefund);
    setSignature(false);
  };


  const handleBack = () => {
    setViewNote(false);
    setViewMessage(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const handleConfirm = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/update-payment-status-jobadvert/${id}`,
        {
          payment_date: pay.payment_date,
          payment_ref: pay.payment_ref,
          estimate_paid_amount: parseInt(pay.estimate_paid_amount),
          note: pay.note,
          signature: sign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: response?.data.message,
        buttons: "Ok",
      });

      setPay({
        ...pay,
        estimate_paid_amount: "",
        payment_ref: "",
        payment_date: "",
      });
      setSign(null);
      load();
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: error?.response?.data?.error,
        buttons: "Ok",
      });
    }
  };


  const deleteJob = async (e) => {
    try {
      const response = await Axios.delete(`/api/manager-admin/delete-jobadvert/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDelete = async (e) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deleteJob(value);

          default:
            break;
        }
      });
  };

  const archiveJob = async (e) => {
    try {
      const response = await Axios.put(`/api/manager-admin/archive-jobadvert/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleArchive = async (e) => {
    swal(`Are you sure you want to move this job to archive folder?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            archiveJob(value);

          default:
            break;
        }
      });
  };

  const acceptJob = async (e) => {
    try {
      const response = await Axios.put(`/api/manager-admin/accept-frontpage-jobadvert/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleAccept = async (e) => {
    swal(`Are you sure you want to approve this job?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            acceptJob(value);

          default:
            break;
        }
      });
  };

  // refund function

  const handleRefundJobAdvert = async (e) => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/v1/refund/admin`,
        {
          charge_id: charge_id,
          reason: pay.refund_reason,
          amount: parseInt(pay.refund_amount * 100),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: response?.data.status,
        buttons: "Ok",
      });
      RefundJobAdvert();
    } catch (error) {
      swal({
        icon: "error",
        text: error.response?.data.raw.message,
        buttons: "Ok",
      });
      setEdit({ ...edit, loader: false });
    }
  };

  const RefundJobAdvert = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/reject-and-refund-frontpage-jobadvert/${id}`,
        {
          refund_date: pay.refund_date,
          refund_reason: pay.refund_reason,
          refund_amount: parseInt(pay.refund_amount * 100),
          note: pay.note,
          signature: sign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: response?.data.message,
        buttons: "Ok",
      });

      setPay({ ...pay, refund_amount: "", refund_date: "", refund_reason: "" });
      setSign(null);
      load();
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: error.response?.data.raw.message,
        buttons: "Ok",
      });
    }
  };




  const handleNote = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/write-note/job-advert/${id}`,
        {
          note: pay.note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: response?.data.message,
      });
      getTotalNote();
      setPay({ ...pay, note: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleMessage = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/write-message-for-job-advert/${id}`,
        {
          note: pay.note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: response?.data.message,
      });
      getTotalMessage();
      setPay({ ...pay, note: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const getTotalNote = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/count-note/job-advert/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalMessage = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/count-message/job-advert/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalMessage(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getAdminDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAdminID(response?.data.id);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalNote();
    getTotalMessage();
    getAdminDetails()
  }, []);










  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {bank_pay && !payment ?
          <MenuItem onClick={handleMarkPaid}>Estimated Paid Status</MenuItem> : null}
        {payment && !approve && !reject ?
          <MenuItem onClick={handleAccept}>Approve Job Add</MenuItem> : null}
        {payment && !reject ?
          <MenuItem onClick={handleRefund}>Reject and Refund</MenuItem> : null}
        <MenuItem onClick={handleShowNote}>Add note ({totalnote})</MenuItem>
        <MenuItem onClick={handleShowMessage}> Send Message ({totalmessage}) </MenuItem>
        {applicationclosingdate ?
          <MenuItem onClick={handleArchive}>Archive</MenuItem> : null}
        {!payment ?
          <MenuItem onClick={handleDelete}>Delete</MenuItem> : null}
        {reject ?
          <MenuItem onClick={handleDelete}>Delete</MenuItem> : null}

      </Menu>



      {/* Mark as paid modal */}
      <Modal open={showMarkPaid} onClose={() => setShowMarkPaid(false)}>
        <Modal.Header>
          Payment Details - {ID} - {name} - {currency} - {estimate}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomTextInput
              name="payment_date"
              onChange={handleChange}
              dvalue={pay.payment_date}
              label="Payment date"
              dlabel="Payment date "
              date={true}
            />
            <CustomTextInput
              name="payment_ref"
              onChange={handleChange}
              value={pay.payment_ref}
              label="Payment reference "
              placeholder="reference no. or transaction no."
              edit={true}
            />
            <CustomTextInput
              number={true}
              name="estimate_paid_amount"
              onChange={handleChange}
              value={pay.estimate_paid_amount}
              label="Payment amount"
              edit={true}
              placeholder="00.00"
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
              placeholder="Additional note"
            />
            <CustomTextInput label="Admin Id" value={adminId}/>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              !pay.payment_ref ||
              !sign ||
              !pay.payment_date ||
              !pay.estimate_paid_amount ||
              edit.loader
            }
            onClick={handleConfirm}
            color="purple"
          >
            Confirm <Icon name="chevron right" />
          </Button>
          <Loader active inline />
        </Modal.Actions>
      </Modal>


      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>





      {/* Refund Payment modal */}
      <Modal open={showRefund} onClose={() => setShowRefund(false)}>
        <Modal.Header>
          Refund Details - {ID} - {name} - {currency} - {estimate}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomTextInput
              name="refund_date"
              onChange={handleChange}
              dvalue={pay.refund_date}
              label="Refund date"
              dlabel="Refund date "
              date={true}
            />
            <CustomTextInput
              name="refund_reason"
              onChange={handleChange}
              value={pay.refund_reason}
              label="Refund reference "
              edit={true}
              placeholder="Refund reference no. or transaction no."
            />
            <CustomTextInput
              number={true}
              name="refund_amount"
              onChange={handleChange}
              value={pay.refund_amount}
              label="Refund amount"
              edit={true}
              placeholder="00.00"
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
              placeholder="Additional note"
            />
            <CustomTextInput label="Admin Id" />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {bank_pay ? (
            <Button
              disabled={
                !pay.refund_reason ||
                !sign ||
                !pay.refund_date ||
                !pay.refund_amount ||
                edit.loader
              }
              onClick={RefundJobAdvert}
              color="purple"
            >
              Confirm <Icon name="chevron right" />
            </Button>
          ) : (
            <Button
              disabled={
                !pay.refund_reason ||
                !sign ||
                !pay.refund_date ||
                !pay.refund_amount ||
                edit.loader
              }
              onClick={handleRefundJobAdvert}
              color="purple"
            >
              Confirm <Icon name="chevron right" />
            </Button>
          )}
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>


      {/* ADD NOTE */}
      {showNote ? (
        <Dialog open={showNote}>
          {!viewNote ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Add Note - {name} - {ID}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.note}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="note"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.note}
                    onClick={handleNote}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Save Note
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Save Note
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}

      {/* Send Message */}
      {showMessage ? (
        <Dialog open={showMessage}>
          {!viewMessage ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Send Message - {name} - {ID}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.note}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="note"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.note}
                    onClick={handleMessage}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Send Message
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Send Message
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}

    </div>
  );
};

export default Action;
