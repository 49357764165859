import React from "react";
import { Table } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import moment from "moment";
import EditableText from "./EditableText";

const Items = ({ values, setReload }) => {
  return (
    <GridContainer>
      <Table color={"pink"}>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Job Advert Reference ID</Table.Cell>
            <Table.Cell>
              {values?.id == "undefined"
                ? "N/A"
                : values?.id
                ? values?.id
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Order reference number or purchase order number
            </Table.Cell>
            <Table.Cell>
              {values?.purchase_order_no == "undefined" ? (
                " "
              ) : values?.purchase_order_no ? (
                <EditableText
                  setReload={setReload}
                  text={values?.purchase_order_no}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"purchase_order_no"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Job Title</Table.Cell>
            <Table.Cell>
              {values?.jobtitle == "undefined" ? (
                " "
              ) : values?.jobtitle ? (
                <EditableText
                  setReload={setReload}
                  text={values?.jobtitle}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"jobtitle"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Salary</Table.Cell>
            <Table.Cell>
              {values?.salary == "undefined" ? (
                " "
              ) : values?.salary ? (
                <EditableText
                  setReload={setReload}
                  text={values?.salary}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"salary"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Contract Type</Table.Cell>
            <Table.Cell>
              {values?.contracttype ? (
                <EditableText
                  setReload={setReload}
                  text={values?.contracttype}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"contracttype"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Hours Per Week</Table.Cell>
            <Table.Cell>
              {values?.hoursperweek ? (
                <EditableText
                  setReload={setReload}
                  text={values?.hoursperweek}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"hoursperweek"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Position for this location</Table.Cell>
            <Table.Cell>
              {values?.location ? (
                <EditableText
                  setReload={setReload}
                  text={values?.location}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"location"}
                />
              ) : (
                "N/A"
              )}
              <br />
              {values?.remote_details ? (
                <EditableText
                  setReload={setReload}
                  text={values?.remote_details}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"remote_details"}
                />
              ) : (
                values?.business_country
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table color="pink">
        <Table.Body>
          <Table.Row>
            <Table.Cell>Job Reference</Table.Cell>
            <Table.Cell>
              {values?.jobref ? (
                <EditableText
                  setReload={setReload}
                  text={values?.jobref}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"jobref"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target Language</Table.Cell>
            <Table.Cell>
              {values?.targetlanguage == "undefined" ? (
                " "
              ) : values?.targetlanguage ? (
                <EditableText
                  setReload={setReload}
                  text={values?.targetlanguage}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"targetlanguage"}
                  lang={true}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Source Language</Table.Cell>
            <Table.Cell>
              {values?.sourcelanguage == "undefined" ? (
                " "
              ) : values?.sourcelanguage ? (
                <EditableText
                  setReload={setReload}
                  text={values?.sourcelanguage}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"sourcelanguage"}
                  lang={true}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Expertise Level</Table.Cell>
            <Table.Cell>
              {values?.expertiselevel == "undefined" ? (
                " "
              ) : (
                <EditableText
                  setReload={setReload}
                  text={values?.expertiselevel}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"expertiselevel"}
                  exp={true}
                />
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Additional Languages</Table.Cell>
            <Table.Cell>
              {values?.additionallanguage == "undefined" ? (
                " "
              ) : values?.additionallanguage ? (
                <EditableText
                  setReload={setReload}
                  text={values?.additionallanguage}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"additionallanguage"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Job Description</Table.Cell>
            <Table.Cell>
              {values?.jobdescription ? (
                <EditableText
                  setReload={setReload}
                  text={values?.jobdescription}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"jobdescription"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Application Closing Date</Table.Cell>
            <Table.Cell>
              {values?.applicationclosingdate == "undefined" ? (
                " "
              ) : values?.applicationclosingdate ? (
                <EditableText
                  setReload={setReload}
                  text={values?.applicationclosingdate}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"applicationclosingdate"}
                  date={true}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>How To Apply?</Table.Cell>
            <Table.Cell>
              {values?.howtoapply == "undefined" ? (
                " "
              ) : values?.howtoapply ? (
                <EditableText
                  setReload={setReload}
                  text={values?.howtoapply}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"howtoapply"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table color="pink">
        <Table.Body>
          <Table.Row>
            <Table.Cell>Business Name</Table.Cell>
            <Table.Cell>
              {values?.business_name == "undefined" ? (
                " "
              ) : values?.business_name ? (
                <EditableText
                  setReload={setReload}
                  text={values?.business_name}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"business_name"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>About Company</Table.Cell>
            <Table.Cell>
              {values?.about_company ? (
                <EditableText
                  setReload={setReload}
                  text={values?.about_company}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"about_company"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Company Number</Table.Cell>
            <Table.Cell>
              {values?.company_number ? (
                <EditableText
                  setReload={setReload}
                  text={values?.company_number}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"company_number"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Company Website</Table.Cell>
            <Table.Cell>
              {values?.company_website ? (
                <EditableText
                  setReload={setReload}
                  text={values?.company_website}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"company_website"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>VAT / GST Number</Table.Cell>
            <Table.Cell>
              {values?.vat_gst_number ? (
                <EditableText
                  setReload={setReload}
                  text={values?.vat_gst_number}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"vat_gst_number"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Company Phone Number</Table.Cell>
            <Table.Cell>
              {values?.phone_no ? (
                <EditableText
                  setReload={setReload}
                  text={values?.phone_no}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"phone_no"}
                  phone={true}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>NHS Trust</Table.Cell>
            <Table.Cell>
              {values?.nhs_trust ? values?.nhs_trust : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Company Registration No.</Table.Cell>
            <Table.Cell>
              {values?.registration_number ? (
                <EditableText
                  setReload={setReload}
                  text={values?.registration_number}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"registration_number"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Business Address</Table.Cell>
            <Table.Cell>
              {values?.business_addressline_one ? (
                <EditableText
                  setReload={setReload}
                  text={values?.business_addressline_one}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"business_addressline_one"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Business Address</Table.Cell>
            <Table.Cell>
              {values?.business_addressline_two ? (
                <EditableText
                  setReload={setReload}
                  text={values?.business_addressline_two}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"business_addressline_two"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Business City</Table.Cell>
            <Table.Cell>
              {values?.business_city ? (
                <EditableText
                  setReload={setReload}
                  text={values?.business_city}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"business_city"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Business Post Code / Zip Code</Table.Cell>
            <Table.Cell>
              {values?.business_postcode ? (
                <EditableText
                  setReload={setReload}
                  text={values?.business_postcode}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"business_postcode"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Business Region</Table.Cell>
            <Table.Cell>
              {values?.business_region ? (
                <EditableText
                  setReload={setReload}
                  text={values?.business_region}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"business_region"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Business Country</Table.Cell>
            <Table.Cell>
              {values?.business_country ? (
                <EditableText
                  setReload={setReload}
                  text={values?.business_country}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"business_country"}
                  country={true}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table color="pink">
        <Table.Body>
          <Table.Row>
            <Table.Cell>Full Name</Table.Cell>
            <Table.Cell>
              {values?.title} {values?.first_name} {values?.last_name}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell>{values?.email ? values?.email : "N/A"}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Mobile Number</Table.Cell>
            <Table.Cell>
              {values?.mobile_no ? values?.mobile_no : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Personal Address </Table.Cell>
            <Table.Cell>
              {values?.address_line_one ? (
                <EditableText
                  setReload={setReload}
                  text={values?.address_line_one}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"address_line_one"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Personal Address (line two)</Table.Cell>
            <Table.Cell>
              {values?.address_line_two ? (
                <EditableText
                  setReload={setReload}
                  text={values?.address_line_two}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"address_line_two"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>City / Town</Table.Cell>
            <Table.Cell>
              {values?.city ? (
                <EditableText
                  setReload={setReload}
                  text={values?.city}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"city"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Post Code / Zip Code</Table.Cell>
            <Table.Cell>
              {values?.postcode ? (
                <EditableText
                  setReload={setReload}
                  text={values?.postcode}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"postcode"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>County / Region</Table.Cell>
            <Table.Cell>
              {values?.region ? (
                <EditableText
                  setReload={setReload}
                  text={values?.region}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"region"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Country</Table.Cell>
            <Table.Cell>
              {values?.country ? (
                <EditableText
                  setReload={setReload}
                  text={values?.country}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"country"}
                  country={true}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Position</Table.Cell>
            <Table.Cell>
              {values?.position ? (
                <EditableText
                  setReload={setReload}
                  text={values?.position}
                  readOnly={false}
                  id={values?._id}
                  variant="outlined"
                  name={"position"}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table color="pink">
        <Table.Body>
          <Table.Row>
            <Table.Cell>Estimated value / estimate paid</Table.Cell>
            <Table.Cell>
              {`${
                values?.estimate_paid_amount
                  ? values?.estimate_paid_amount
                  : values?.estimate
              } ${
                values?.currency
                  ? values?.currency?.substring(0, 3)
                  : values?.currency?.substring(0, 3)
              }`}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Date and Time of Placing Order (London Time)
            </Table.Cell>
            <Table.Cell>
              {moment(values.createdAt)
                .tz("Europe/London")
                .format("DD-MMM-YYYY, HH:mm")}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </GridContainer>
  );
};

export default Items;
