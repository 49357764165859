import React, { useState, Fragment } from "react";

// Material imports
import { Avatar, IconButton } from "@material-ui/core";
import { Button, Header, Segment } from "semantic-ui-react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// Material Icons
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

// Custom imports
import { useStylesAvatar } from "assets/jss/layouts/styleP";
import ProfileModal from "assets/jss/Modals/ProfileModal/ProfileModal";

import { useParams } from "react-router-dom";
import Slider from "../../Sliders/Slider";
import publicProfileOne from "assets/images/new sppo and pp photo-02.jpg";
import publicProfileTwo from "assets/images/SPPO banner-05.jpg";
import publicProfileThree from "assets/images/public profile-06.jpg";
import publicProfileFour from "assets/images/public profile-01.jpg";
import DefaultPhoto from "assets/icons/DefaultSppo.png";
//Redux imports
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
// Share buttons
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";

function HeaderPage() {
  const { id } = useParams();
  const matches = useMediaQuery("(max-width:425px)");
  const [details, setDetails] = useState("Interpreter");
  const [openProfileUpdate, setOpenProfileUpdate] = useState(false);
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const sliderImg = [
    publicProfileOne,
    publicProfileTwo,
    publicProfileThree,
    publicProfileFour,
  ];
  const [combination, setcombination] = useState(data?.language_combinations);
  const API_KEY = process.env.REACT_APP_MAPS_API_KEY;
  const classes_avatar = useStylesAvatar();

  return (
    <Fragment>
      <Header as="h2" textAlign="center">
        SPPO Profile
      </Header>
      <ProfileModal
        openProfileUpdate={openProfileUpdate}
        setOpenProfileUpdate={setOpenProfileUpdate}
      />

      <div className="app__header__card">
        <div style={{ position: "relative", marginBottom: "60px" }}>
          <Slider data={sliderImg} />

          <div className="profile_avatar">
            <Avatar
              variant="square"
              onContextMenu={(e) => e.preventDefault()}
              className={classes_avatar.root}
              src={`${process.env.REACT_APP_BACKEND_API}/sppo/public-profile-photo/${data._id}`}
              alt="Profile Photo"
            >
              <img
                style={{ width: "96px", height: "96px" }}
                src={DefaultPhoto}
              ></img>
            </Avatar>
          </div>
        </div>

        <div
          style={{
            padding: " 0 32px 32px 32px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p style={{ fontSize: "28px", margin: "0" }}>
              {data?.title} {data?.first_name} {data?.last_name}{" "}
              {data.access ? (
                <IconButton>
                  <VerifiedUserIcon />
                </IconButton>
              ) : null}
            </p>

            <br />
            <br />
            <p
              style={{ fontSize: "24px", margin: "0" }}
              className="text-xl font-light"
            >
              LC SPPO ID: {data?.id}
            </p>
          </div>

          {matches ? (
            <div>
              <div>
                <FacebookShareButton
                  quote="Interpreting and Translation Services"
                  hashtag="Interpreting"
                  url={window.location.href}
                >
                  <Button circular color="facebook" icon="facebook" />
                </FacebookShareButton>
                <TwitterShareButton
                  quote="Interpreting and Translation Services"
                  hashtag="Interpreting"
                  url={window.location.href}
                >
                  <Button circular color="twitter" icon="twitter" />
                </TwitterShareButton>
                <LinkedinShareButton
                  quote="Interpreting and Translation Services"
                  hashtag="Interpreting"
                  url={window.location.href}
                >
                  <Button circular color="linkedin" icon="linkedin" />
                </LinkedinShareButton>
                <EmailShareButton
                  quote="Interpreting and Translation Services"
                  hashtag="Interpreting"
                  url={window.location.href}
                >
                  <Button circular color="google plus" icon="google plus" />
                </EmailShareButton>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <Button
                  style={{ marginBottom: 10 }}
                  color="purple"
                  onClick={() =>
                    window.open("https://www.linguistscollective.com")
                  }
                >
                  Linguists Collective (LC)
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <FacebookShareButton url={window.location.href}>
                  <Button circular color="facebook" icon="facebook" />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <Button circular color="twitter" icon="twitter" />
                </TwitterShareButton>
                <LinkedinShareButton url={window.location.href}>
                  <Button circular color="linkedin" icon="linkedin" />
                </LinkedinShareButton>
                <EmailShareButton url={window.location.href}>
                  <Button circular color="google plus" icon="google plus" />
                </EmailShareButton>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <Button
                  style={{ marginBottom: 10 }}
                  color="purple"
                  onClick={() =>
                    window.open("https://www.linguistscollective.com")
                  }
                >
                  Linguists Collective (LC)
                </Button>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            padding: " 0 32px 32px 32px",
          }}
        >
          <div>
            <Header as="h3">Business details</Header>
            <Header as="h3">
              {" "}
              Name:{" "}
              {data?.business_name ? data?.business_name : data?.business_name}
            </Header>
            <Header as="h3">
              Legal name:{" "}
              {data?.legal_business_name
                ? data?.legal_business_name
                : data.legal_business_name}
            </Header>
            <Header as="h3">
              {" "}
              Website:{" "}
              {data?.business_website
                ? data?.business_website
                : data.business_website}
            </Header>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <LocationOnIcon />
              <Header
                style={{ margin: "0", marginLeft: "5px" }}
                color="grey"
                size="medium"
              >
                {data?.business_city ? data?.business_city : data.business_city}{" "}
                {data?.business_postal_code
                  ? data?.business_postal_code
                  : data.business_postal_code}{" "}
                {data?.business_country
                  ? data?.business_country
                  : data.business_country}
              </Header>
            </div>
          </div>
          <div
            style={{
              marginLeft: "15px",
            }}
          >
            <iframe
              width="100%"
              height="100%"
              style={{ border: "0px" }}
              loading="lazy"
              src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q= ${data.business_address_line1}, ${data.business_city}, ${data.business_region}, ${data.business_postal_code}, ${data.business_country}`}
            ></iframe>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default HeaderPage;
