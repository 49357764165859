// Custom Components
import CustomSelect from "components/Common/CustomSelect";

const ExpensesAndTravelDropdown = ({ ...props }) => {
  const data = [
    "Price per hour (minimum 4 hours including ½ hour paid break)",
    "Mileage charge for using own vehicle (££ per mile, figure changeable)",
    "Travelling time (££)",
    "Air tickets cost",
    "Airport transfers",
    "Overnight charge (hotel booking)",
    "Food (only for jobs booked for two days or longer)",
    "Bus fare",
    "Train fare",
    "Taxi fare",
    "Telephone and internet bill",
    "Additional charge as agreed (details on note)",
    "Other (details on note)",
  ];

  return (
    <CustomSelect
      label="Expenses and Travel"
      width="100%"
      data={data}
      {...props}
    />
  );
};

export default ExpensesAndTravelDropdown;
