import React from "react";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const FirstSegment = () => {
  const classes = useDashboardStyles();

  return (
    <Card>
      <CardHeader color="primary">
        <p className={`${classes.cardTitleWhite} ${classes.textCenter}`}>
          Arrange Meetings
        </p>
      </CardHeader>
      <CardBody>
        <div className={classes.boxDiv}></div>
      </CardBody>
    </Card>
  );
};

export default FirstSegment;
