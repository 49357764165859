import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import { Button, Modal } from "semantic-ui-react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import MyNotes from "components/CashClient/Dashboard/MyNote/List";
import MyNotesForm from "components/CashClient/Dashboard/MyNote/Form";

const Archive = () => {
  const classes = useDashboardStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <Cashclient>
      <CashClientLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>My Notes / To Do List</p>
              </CardHeader>
            </Card>

            {/* start colleague note form modal */}
            <Card>
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                trigger={
                  <Button
                    color="purple"
                    icon="add"
                    content="My Note / Do List"
                  />
                }
              >
                <Modal.Content scrolling>
                  <Modal.Description>
                    <MyNotesForm />
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </Card>

            <Card>
              <MyNotes open={open} />
            </Card>
          </GridItem>
        </GridContainer>
      </CashClientLayout>
    </Cashclient>
  );
};

export default Archive;
