import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import Axios from "axios";
import swal from "sweetalert";

const Action = ({
  id,
  load,
  pay,
  cancel,
  report,
  reportID,
  invoice,
  invoiceID,
  clientReport,
  linReport,
  adminReport,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cancelRequest = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/cancel-linQuote/linQuoteID/${id}`
      );
      swal({
        icon: "success",
        text: `Cancel request sent to admin!!!`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCancel = async (e, id) => {
    swal(`Are you sure you want to cancel this job?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          cancelRequest(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!report && pay ? (
          <MenuItem
            onClick={() =>
              history.push(
                `confirmed-quotations/cac/quotation/create-project-report/${id}`
              )
            }
          >
            Close & Project Report
          </MenuItem>
        ) : null}

        {report && !clientReport ? (
          <MenuItem
            onClick={() =>
              history.push(
                `confirmed-quotations/cac/quotation/confirm-project-report/${reportID}`
              )
            }
          >
            Confirm Project Report
          </MenuItem>
        ) : null}

        {report ? (
          <MenuItem
            onClick={() =>
              history.push(
                `confirmed-quotations/cac/quotation/view-project-report/${reportID}`
              )
            }
          >
            View Project Report
          </MenuItem>
        ) : null}

        {invoice ? (
          <MenuItem
            onClick={() =>
              history.push(
                `confirmed-quotations/cac/quotation/view-invoice/${invoiceID}`
              )
            }
          >
            View Invoice
          </MenuItem>
        ) : null}

        {!report && !cancel ? (
          <MenuItem onClick={handleCancel}>Cancel</MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default Action;
