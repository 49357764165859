import React, { useState } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import {
  Card,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import CardBody from "components/Card/CardBody";
import ThreeCards from "./landing-page/ThreeCards";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { Menu } from "semantic-ui-react";
import CardFooter from "components/Card/CardFooter";
import Slider from "components/JobAdvert/Slider";
import JobSearchImg from "assets/images/job-search.jpg";
import JobAdvertSearchImg from "assets/images/job-advert-search.jpg";
import JobAdvertImg from "assets/images/job-advert.jpg";
import JPimage from "assets/icons/job-post.png";
import JSimage from "assets/icons/search-job.png";
import PublicJobAdvert from "components/Auth/PublicJobAdvert/PublicJobAdvert";
import history from "utils/history";
import { useParams } from 'react-router-dom'

const PostJobAdvert = () => {
  const { id } = useParams()
  const classes = useLandingpageStyles();
  const [activeItem, setActiveItem] = useState("Post a Job Advert");

  const handleItemClick = (e, { name }) => {
    history.push("/post-job-advert")
  };
  const sliderImg = [JobSearchImg, JobAdvertSearchImg, JobAdvertImg];

  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "0 0 0 0" }}>
        <Card className={classes.frontImage}>
          <CardBody style={{ paddingTop: "0" }}>
            <Slider data={sliderImg} />
          </CardBody>
          <CardFooter>
            <Menu color="purple" inverted tabular widths="two">
              <Menu.Item
                name="Post a Job Advert"
                active={activeItem === "Post a Job Advert"}
                onClick={handleItemClick}
              >
                <img src={JPimage} alt="" style={{ width: "10%" }} />
                <span style={{ marginLeft: "10px" }}>Post a Job Advert</span>
              </Menu.Item>

              <Menu.Item
                name="Search Job"
                active={activeItem === "Search Job"}
                onClick={handleItemClick}
              >
                <img src={JSimage} alt="" style={{ width: "10%" }} />
                <span style={{ marginLeft: "10px" }}>Search Job</span>
              </Menu.Item>
            </Menu>
          </CardFooter>

          <PublicJobAdvert id={id} />

        </Card>

        <ThreeCards />

      </GridContainer>
    </LandingPageLayout>
  );
};

export default PostJobAdvert;
