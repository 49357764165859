import swal from "sweetalert";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, FormControlLabel } from "@material-ui/core";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInput from "components/Common/CustomInput";
import CustomDate from "components/Common/CustomDate";
import CustomPassword from "components/Common/CustomPassword";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomCaptcha from "components/Common/CustomCaptcha";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import { CURRENT_COUNTRY } from "data";
import { changeInitRegInput } from "redux/actions/irAction";
import defaultAxios from "utils/axios";

const PageOne = () => {
  const { data } = useSelector((state) => state.irReducer);
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState("");

  const {
    title,
    FirstName,
    LastName,
    AddressLine1,
    AddressLine2,
    City,
    PostalCode,
    Region,
    Country,
    Nationality,
    other_nationality,
    Birthday,
    email,
    password,
    confirm_password,
    gdpr,
  } = data;

  const classes = useFormStyles();

  const handleChange = (e, date) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeInitRegInput({
        name,
        value: value || checked,
      })
    );
  };

  const handleDateChange = (date) => {
    dispatch(
      changeInitRegInput({
        name: "Birthday",
        value: date,
      })
    );
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "other_nationality",
        value,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "Country",
        value,
      })
    );
  };

  const handleChangeNA = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "Nationality",
        value: value,
      })
    );
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };

  const handleSubmit = async (e) => {
    try {
      const response = await defaultAxios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/pre-signup`,
        data
      );

      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const isAllow =
    !title ||
    !FirstName ||
    !LastName ||
    !AddressLine1 ||
    !City ||
    !PostalCode ||
    !Region ||
    !Country ||
    !Birthday ||
    !email ||
    !password ||
    !confirm_password ||
    !gdpr ||
    !Nationality ||
    !captcha;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInput
          onChange={handleChange}
          width="100%"
          label="Title"
          name="title"
          value={title}
          required
        />

        <CustomInput
          onChange={handleChange}
          label="First Name (s)"
          width="100%"
          name="FirstName"
          value={FirstName}
          required
        />
        <CustomInput
          onChange={handleChange}
          label="Last Name"
          width="100%"
          name="LastName"
          value={LastName}
          required
        />
        <CustomInput
          onChange={handleChange}
          label="House Number/Name, Street Name"
          width="100%"
          name="AddressLine1"
          value={AddressLine1}
          required
        />
        <CustomInput
          onChange={handleChange}
          label="Address line 2"
          width="100%"
          name="AddressLine2"
          value={AddressLine2}
        />
        <CustomInput
          onChange={handleChange}
          label="Town / City"
          width="100%"
          name="City"
          value={City}
          required
        />
        <CustomInput
          onChange={handleChange}
          label="Post / Zip Code"
          width="100%"
          name="PostalCode"
          value={PostalCode}
          required
        />
        <CustomInput
          onChange={handleChange}
          label="County / Region"
          width="100%"
          name="Region"
          value={Region}
          required
        />
        <CustomAutoSelect
          onChange={handleAutoCompleteCO}
          label="Country"
          moreInfo="Current country of residence"
          width="100%"
          name="Country"
          value={Country}
          data={CURRENT_COUNTRY}
          required
        />
        <CustomAutoSelect
          onChange={handleChangeNA}
          label="Current nationality"
          width="100%"
          name="Nationality"
          value={Nationality}
          data={CURRENT_COUNTRY}
          required
        />
        <CustomAutoComplete
          onChange={handleAutoComplete}
          label="Other nationalities"
          width="100%"
          data={CURRENT_COUNTRY}
          value={other_nationality}
          placeholder="Choose"
        />
        <CustomDate
          onChange={handleDateChange}
          width="100%"
          label="Date of birth"
          name="Birthday"
          value={Birthday}
          required
        />
        <CustomBigInput
          onChange={handleChange}
          label="Primary Email"
          moreInfo="This email address will be shared with payment processor"
          width="100%"
          name="email"
          value={email}
          required
        />
        <CustomPassword
          onChange={handleChange}
          label="Password"
          width="100%"
          name="password"
          value={password}
          required
        />
        <CustomPassword
          onChange={handleChange}
          label="Confirm password"
          width="100%"
          name="confirm_password"
          value={confirm_password}
          validation={password !== confirm_password}
          required
        />

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                checked={gdpr}
                name="gdpr"
                color="primary"
              />
            }
            label="By submitting this form I gdpr with LC Privacy Policy, T&C, and GDPR"
          />
        </div>

        <CustomCaptcha onChange={handleCaptcha} />

        <div className={classes.buttonFlex}>
          <CustomButton
            onClick={handleSubmit}
            disabled={isAllow}
            text="Submit"
          />
        </div>
      </form>
    </div>
  );
};

export default PageOne;
