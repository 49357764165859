import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import history from "utils/history";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const UnconfirmedOptions = ({ currentPage }) => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <div className={classes.root}>
      <Button onClick= {() => history.push('/admin/archive-admins')} variant="outlined">
        <b style={{ color: "white" }}>Executive / Manager</b>
      </Button>

      <Button onClick= {() => history.push('/admin/archive-cash-client')} variant="outlined">
        <b style={{ color: "white" }}>Cash Client</b>
      </Button>

      <Button onClick= {() => history.push('/admin/archive-account-client')} variant="outlined">
          <b style={{ color: "white" }}>Account Client</b>
      </Button>

      <Button onClick= {() => history.push('/admin/archive-inhouse-client')} variant="outlined">
        <b style={{ color: "white" }}>Inhouse Client</b>
    </Button>

    <Button onClick= {() => history.push('/admin/archive-private-client')} variant="outlined">
        <b style={{ color: "white" }}>Private Client</b>
    </Button>

    <Button onClick= {() => history.push('/admin/archive-linguists')} variant="outlined">
        <b style={{ color: "white" }}>Linguists</b>
    </Button>

    <Button onClick= {() => history.push('/admin/archive-sppo')} variant="outlined">
        <b style={{ color: "white" }}>SPPO</b>
    </Button>

    <Button onClick= {() => history.push('/admin/archive-employee')} variant="outlined">
        <b style={{ color: "white" }}>Employee</b>
    </Button>

    <Button onClick= {() => history.push('/admin/archive-jobadvert')} variant="outlined">
        <b style={{ color: "white" }}>Job Advert</b>
    </Button>

    <Button onClick= {() => history.push('/admin/archived-assignments')} variant="outlined">
        <b style={{ color: "white" }}>Assignments</b>
    </Button>

    <Button onClick= {() => history.push('/admin/archived-invoice-and-quote')} variant="outlined">
        <b style={{ color: "white" }}>Invoice / Quote</b>
    </Button>

    <Button onClick= {() => history.push('/admin/archived-timesheet-and-project-report')} variant="outlined">
        <b style={{ color: "white" }}>Timesheet / Project Report</b>
    </Button>

    </div>
  );
};

export default UnconfirmedOptions;
