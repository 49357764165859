import React from "react";
import Sppo from "components/RouteProtection/Sppo/Sppo";
import SppoLayout from "layout/SppoLayout";
// Custom Components

// Custom Styles

import ViewAttach from "components/Admin/Communication/ViewAttachConversation";


const ViewContactCard = () => {
  return (
    <Sppo>
      <SppoLayout>
        <ViewAttach />
      </SppoLayout>
    </Sppo>
  );
};

export default ViewContactCard;
