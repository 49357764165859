import Avatar from "@material-ui/core/Avatar";
// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import ITLimage from "assets/icons/Interpreting-Translation-Linguists-Collective-Language-Services.png";

const CustomAvatar = ({ ...rest }) => {
  const classes = useEmployeeStyles();

  return (
    <Avatar {...rest} className={classes.avatar}>
      <img style={{ width: "100%" }} src={ITLimage} alt="lc-logo" />
    </Avatar>
  );
};

export default CustomAvatar;
