import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomCaptcha from "components/Common/CustomCaptcha";
// Others

import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import { changeSPPORInput, updateSPPORStep } from "redux/actions/spporAction";
import CustomPhone from "components/Common/CustomPhone";
import CustomDate from "components/Common/CustomDate";
import CustomPassword from "components/Common/CustomPassword";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Axios from "axios";
import swal from "sweetalert";
import history from "utils/history";
import { EMAIL_REGEX, PASS_REGEX } from "data";

const PageOne = ({ id, genID }) => {
  const { data } = useSelector((state) => state.spporReducer);
  const dispatch = useDispatch();
  const [notValid, setNotValid] = useState(false);
  const [notRegex, setNotRegex] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    title,
    first_name,
    last_name,
    email,
    mobile_no,
    password,
    confirm_password,
    birthday,
    gdpr,
  } = data;

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateSPPORStep(2));
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeSPPORInput({
        name,
        value: value,
      })
    );
  };

  const handlePhone = (value) => {
    dispatch(
      changeSPPORInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeSPPORInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeSPPORInput(inputData));
    if (value !== "" && PASS_REGEX.test(value)) {
      setNotRegex(false);
    } else {
      setNotRegex(true);
    }
  };

  const handleDateChange = (date) => {
    dispatch(
      changeSPPORInput({
        name: "birthday",
        value: date,
      })
    );
  };

  const handleCheckChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeSPPORInput({
        name,
        value: value || checked,
      })
    );
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
    const isHuman = await fetch(
      `https://www.google.com/recaptcha/api/siteverify`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
        mode: "no-cors",
        credentials: "omit",
        body: `secret=6LdPUwMaAAAAAK7JyeaYyRPz4NeNyC2XoQthvK8h&response=6LdPUwMaAAAAALqroyySr9kV-TXA7sxKx2qzvSnC`,
      }
    );
    // console.log(isHuman)
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/pre-signup`,
        { ...data, agent_sppo: id, userID: genID }
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });
      history.push("/");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const isAllow =
    !title ||
    !first_name ||
    !last_name ||
    !birthday ||
    !email ||
    !password ||
    !confirm_password ||
    !mobile_no ||
    !gdpr ||
    !captcha ||
    notValid;

  return (
    <div>
      <h3 style={{ textAlign: "center", paddingTop: "5px" }}>
        <p>SPPO Ref: {genID ? genID : "LC Web"}</p>
      </h3>

      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          label="Title"
          width="100%"
          name="title"
          value={title}
          required
          placeholder="e.g. Mr, Mrs, Miss, Ms etc"
        />

        <CustomBigInputSingle
          onChange={handleChange}
          label="First Name (s)"
          moreInfo="First Name(s) of the legal representative. e.g. director"
          width="100%"
          name="first_name"
          value={first_name}
          required
          placeholder="Your First Names"
        />

        <CustomBigInputSingle
          onChange={handleChange}
          label="Last Name"
          moreInfo="Last Name of the legal representative. e.g. director"
          width="100%"
          name="last_name"
          value={last_name}
          required
          placeholder="Your Last Name / Surname"
        />

        <CustomInputSingle
          onChange={handleEmailChange}
          label="New User / Primary / Business Email Address"
          width="100%"
          name="email"
          value={email}
          required
          placeholder="e.g. example@example.com"
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <br />

        <CustomDate
          onChange={handleDateChange}
          width="100%"
          label="Date of Birth"
          name="birthday"
          value={birthday}
          required
          maxDate={new Date("1-1-2000")}
        />

        <br />
        <CustomPhone
          onChange={handlePhone}
          label="Mobile Number"
          placeholder="Mobile Number* (Please select country then type number e.g. +44 7307 000000)"
          width="100%"
          name="mobile_no"
          value={mobile_no}
          required
        />

        <CustomPassword
          onChange={handlePasswordChange}
          label="Password"
          width="100%"
          name="password"
          value={password}
          notRegex={notRegex}
          required
          placeholder="e.g. Ex@mPle012345"
        />
        <CustomPassword
          onChange={handleChange}
          label="Confirm password"
          width="100%"
          name="confirm_password"
          value={confirm_password}
          validation={password !== confirm_password}
          required
          placeholder="e.g. Ex@mPle012345"
        />

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleCheckChange}
                checked={gdpr}
                name="gdpr"
                color="primary"
              />
            }
            // label="By submitting this form I agree with LC Partnership Agreement, T&C and Privacy Policy"
          />
          By submitting this form I agree with LC Partnership Agreement
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/terms-and-conditions"
          >
            {" "}
            T&C
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/privacy-policy"
          >
            {" "}
            Privacy Policy{" "}
          </a>
        </div>

        <CustomCaptcha onChange={handleCaptcha} />

        <div className={classes.buttonFlex}>
          <CustomButton
            disabled={isAllow || loading}
            text="Submit"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default PageOne;
