import React from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import { Button, Card, List, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { Link } from "react-router-dom";

const EmployeesPortal = () => {
  const classes = useLandingpageStyles();
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 0 4rem 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card style={{ padding: "5rem" }} className={classes.clientPortal}>
            <CardBody>
              <Typography
                style={{ fontWeight: "700", borderBottom: "1px solid black" }}
                paragraph
                variant="h5"
              >
                Employees
              </Typography>
              <Typography variant="body1">
                Please login to access your dedicated platform
              </Typography>
              <List dense>
                {/* {ES.map(value => <ListItem>
                                    <ListItemText
                                        primary= {value}
                                    />
                                </ListItem>)} */}
              </List>
              <div style={{ marginBottom: "2rem" }}>
                <Link style={{ textDecoration: "none" }} to="/employee-login">
                  <Button className= {`${classes.button}`} variant="contained">
                  Employee Login
                  </Button>
                </Link>
              </div>
              
              <Typography paragraph variant="body1">
              If you are a new employee, please contact manager for your portal credentials.
              </Typography>
             
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default EmployeesPortal;
