import React, { useState, useEffect } from "react";
import Sppo from "components/RouteProtection/Sppo/Sppo";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SppoLayout from "layout/SppoLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import MyNoteForm from "components/Sppo/MyNote/MyNoteForm";
import MyNoteList from "components/Sppo/MyNote/MyNoteList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { makeStyles } from "@material-ui/core/styles";
import Cookies from "js-cookie";
import Axios from "axios";
import { Header } from "semantic-ui-react";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",

      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};
const useStyles = makeStyles(styles);

const InterpretingJobs = () => {
  const classes = useDashboardStyles();
  const ButtonClass = useStyles();
  const [show, setShow] = useState(false);

  const [sppoID, setSPPOID] = useState(null);

  const getDetails = async (e) => {
    const token = Cookies.get("SPPOtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setSPPOID(response?.data);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <SppoLayout>
      <Sppo>
        <GridContainer>
          {sppoID?.profile_update ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className={classes.cardTitleWhite}>Saved Notes</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={ButtonClass.root}>
                      <Button variant="outlined" onClick={() => setShow(!show)}>
                        {show ? "Saved Notes" : "+ Add Note"}
                      </Button>
                    </div>
                    <IconButton color="inherit">
                      <ArrowBackIcon
                        onClick={() => history.goBack()}
                      ></ArrowBackIcon>
                    </IconButton>
                  </div>
                </CardHeader>
                <CardBody>{show ? <MyNoteForm /> : <MyNoteList />}</CardBody>
              </Card>
            </GridItem>
          ) : sppoID?.profile_update === false ? (
            <Header as="h3">
              Access denied.{" "}
              <a href={`/sppo/full-registration`}>
                Please complete full registration.
              </a>
            </Header>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </Sppo>
    </SppoLayout>
  );
};

export default InterpretingJobs;
