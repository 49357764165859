import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import LinguistLayout from "layout/LinguistLayout";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import { IconButton } from "@material-ui/core";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import InhouseClientRegistration from "components/Auth/InhouseClientRegistration/InhouseClientRegistration";
import { Button } from "@material-ui/core";
import Link from '@material-ui/core/Link'
import Options from "components/Linguist/MyClient/INCOptions";

const InhouseClientReg = () => {
  const classes = useDashboardStyles();
  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>In-house Client Registration</p>
                <IconButton color='inherit'><ArrowBackIcon

                  onClick={() => history.goBack()}
                ></ArrowBackIcon></IconButton>
              </CardHeader>

            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >

                <Options />
              </CardHeader>
              <CardBody>
                <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-inhouse-client/registration')} variant='contained' color='primary'>INC Registration Form</Button>
                <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-inhouse-client/send-form')} variant='contained' color='primary'>Send INC Registration Form</Button>
                <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-inhouse-client/removal-request')} variant='contained' color='primary'>Removal Requests</Button>
                <Link href='/delete-my-inc-account-from-linguist' target='_blank'>  <Button color='primary' variant='contained'> Removal Form</Button></Link>
              </CardBody>
            </Card>
            <InhouseClientRegistration />
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default InhouseClientReg;
