import React from "react";
import LandingPageLayout from "layout/LandingPageLayout";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import EventsCard from "components/Admin/ManagementPanel/EventsForm/PublicEventsCard";

const AssignmentPage = () => {
  const classes = useDashboardStyles();

  return (
    <LandingPageLayout>
      <Card>
        <GridContainer
          // justify="center"
          // className={classes.container}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Events and Meetings</p>
              </CardHeader>
              <CardBody>
                <EventsCard />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Card>
    </LandingPageLayout>
  );
};

export default AssignmentPage;
