import React, { useState, useEffect } from "react";
import SppoLayout from "layout/SppoLayout";
import Sppo from "components/RouteProtection/Sppo/Sppo";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Cookies from "js-cookie";
import Axios from "axios";
import { Header } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CardHeader from "components/Card/CardHeader";
import LeadOptions from 'components/Sppo/Leads/LeadOptions'
import LeadsList from 'components/Sppo/Leads/Leads'

const InvoicePage = () => {
  const classes = useDashboardStyles();

  const [sppoID, setSPPOID] = useState(null)

  const getDetails = async (e) => {
    const token = Cookies.get("SPPOtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setSPPOID(response?.data);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <SppoLayout>
      <Sppo>
        <GridContainer>
          {sppoID?.agent_sppo && sppoID?.my_lead_access ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // alignItems: "center",
                    // flexWrap: "wrap",
                  }}
                >
                  <p className={classes.cardTitleWhite}>My Leads</p>
                  <LeadOptions sppoID={sppoID?._id} />
                  <IconButton
                    onClick={() => history.goBack()}
                    color="inherit"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </CardHeader>
                <CardBody>
                  <LeadsList />
                </CardBody>
              </Card>
            </GridItem>
            : sppoID?.profile_update ?
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    color="primary"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // alignItems: "center",
                      // flexWrap: "wrap",
                    }}
                  >
                    <p className={classes.cardTitleWhite}>My Leads</p>
                    <LeadOptions sppoID={sppoID?._id} />
                    <IconButton
                      onClick={() => history.goBack()}
                      color="inherit"
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </CardHeader>
                  <CardBody>
                    <LeadsList />
                  </CardBody>
                </Card>
              </GridItem>
              : sppoID?.agent_sppo && sppoID?.profile_update === false ?
                <Header as='h3'>Access denied.</Header>
                :
                sppoID?.profile_update === false ?
                  <Header as='h3'>Access denied. <a href={`/sppo/full-registration`}>Please complete full registration.</a></Header>
                  :
                  <Header as='h3'>Loading...</Header>
          }
        </GridContainer>
      </Sppo>
    </SppoLayout>
  );
};

export default InvoicePage;
