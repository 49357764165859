import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from 'utils/history'
//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import ProjectReport from "components/Admin/TimesheetProjectReport/ViewProjectReport/ProjectReport";

const ProjectReportINCPage = () => {
  const classes = useDashboardStyles();
  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{
                display: "flex",
                justifyContent: "space-between",
              }} color="primary">
                <p className={classes.cardTitleWhite}>Project report</p>
                <IconButton color='inherit'><ArrowBackIcon onClick={() => history.goBack()} /></IconButton>
              </CardHeader>
              <CardBody>
                <ProjectReport />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default ProjectReportINCPage;
