import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Moment from "moment";
// Custom Components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import SearchView from "./Search/SearchView";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useToasts } from "react-toast-notifications";
//Axios
import Axios from "utils/axios";
import Action from "components/Admin/BookingPanel/PrivateQuote/Action";
//Cookies
import Cookies from "js-cookie";

const AcceptedQuote = () => {
  const classes = useDashboardStyles();
  const [viewAll, setViewAll] = useState(null);
  const token = Cookies.get("token");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [SearchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const { addToast } = useToasts();
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleViewAll = async (e) => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/list-accepted-private-client-Quote`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      filtered.length > 0 ? setViewAll(filtered) : setViewAll(response?.data);
      setFiltered([]);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  const handleSearch = async (e) => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/find-private-client-Quote/quoteID/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data) {
        addToast("Quote found", { appearance: "success" });
        setViewAll([response?.data]);
      } else {
        addToast("Quote not found", { appearance: "error" });
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct Quote id.", {
          appearance: "error",
        });
      }
    }
  };

  useEffect(() => {
    handleViewAll();
  }, [filter]);

  return (
    <>
      <SearchView
        load={handleViewAll}
        handleSearch={handleSearch}
        setSearchText={setSearchText}
        SearchText={SearchText}
        setShow={setShow}
        show={show}
        values={viewAll}
        setFilter={setFilter}
        setFiltered={setFiltered}
      />

      <Paper className={classes.root}>
        {show && (
          <>
            <TableContainer className={classes.container}>
              <Table>
                <TableHead>
                  <TableRow style={{ textAlign: "left" }}>
                    <TableCell>Quote ID</TableCell>
                    <TableCell>Client ID</TableCell>
                    <TableCell>Linguist Ref ID</TableCell>
                    <TableCell>Estimate Amount</TableCell>
                    <TableCell>Quote Created Date</TableCell>
                    <TableCell>Expiry Date</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {viewAll?.map((list, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Link
                          to={`/admin/view-prc-quote-bylinguist/${list._id}`}
                        >
                          {list.id}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {list.privateClientID?.id
                          ? list.privateClientID.id
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {list.postedBy?.id ? list.postedBy?.id : "N/A"}
                      </TableCell>
                      <TableCell>{list.grand_total}</TableCell>
                      <TableCell>
                        {Moment(list?.createdAt).format(
                          "DD MMM YYYY - kk:mm:ss"
                        )}
                      </TableCell>
                      <TableCell>
                        {Moment(list?.expiry_date).format(
                          "DD MMM YYYY - kk:mm:ss"
                        )}
                      </TableCell>
                      <TableCell>{list.status}</TableCell>
                      <TableCell>
                        {" "}
                        <Action id={list?._id} load={handleViewAll} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={viewAll && viewAll.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </>
  );
};

export default AcceptedQuote;
