import React from 'react'

import Snackbar from '@material-ui/core/Snackbar';

import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

const CustomSnackBar = ({message, handleClose, openSnackBar, undo, severity, ...rest}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={100000} onClose={handleClose}>
            <Alert onClose={handleClose} severity= {severity || 'success'}>
                {message}
            </Alert>
            </Snackbar>
        </div>
    )
}

export default CustomSnackBar
