import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";
import CustomInput from "components/Common/CustomInput";
//import CustomUpload from "components/Common/CustomUpload";
import CustomAutoComplete from "components/Common/CustomAutoComplete";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import { CURRENT_COUNTRY, GENDER_DATA, PAYMENT_QUOTEREQUEST, PRIMARY_SOURCE_LANG, PRIMARY_TARGET_LANG, QUOTE_REQUEST_FOR, SKILL_LEVEL_REQUIRED } from "data";

import { changeQuoteInput } from "redux/actions/quoterqstAction";
import CustomSelect from "components/Common/CustomSelect";
import { Button, Card, Checkbox, FormControlLabel } from "@material-ui/core";
import CustomDate from "components/Common/CustomDate";
import CustomRadio from "components/Common/CustomRadio";
import CardBody from "components/Card/CardBody";


const PageOne = () => {
  const { data } = useSelector(state => state.quoterqstReducer)
  const dispatch = useDispatch();

  const {
    purchase_order_number,
    quote_rqst_for,
    house_street_no_name,
    address_line_two,
    town_city,
    post_code,
    county_region,
    country,
    other_details,
    request_details,
    project_fix_budget,
    documents,
    source_language,
    target_language,
    skills_required,
    provider_id_number,
    gender,
    quote_by,
    pay_type,
    agree
  }= data

  const classes = useFormStyles();

  const handleNext = () => {
    console.log(data)
  };

  const handleChange = e => {
    const { name, value, checked }= e.target
    dispatch(changeQuoteInput({
        name,
        value: value || checked
    }))
  }

  const handleExpertise = value => {
    if (target_language.includes('R') || source_language.includes('R')) {
        return value.match(/(rare|Rare|Level 5|Level X |Level 1|Level 2)/g)
    } else {
        return value.match(/(Level 1|Level 2|Level 5|Level X |Level 3|Level 4:)/g)
    }
  }

  const handleAutoComplete = (e, value) => {
    dispatch(changeQuoteInput({
      name: 'quote_rqst_for',
      value
    }))
  }

  const handleDateChange = (date) => {
    dispatch(changeQuoteInput({
      name: 'quote_by',
      value: date
    }))
  }

  const isAllow= 
    !quote_rqst_for.length ||
    !request_details ||
    !project_fix_budget ||
    !source_language ||
    !target_language ||
    !skills_required ||
    !quote_by ||
    !agree

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">

        <CustomInput
          onChange= {handleChange}
          label="Your booking ref/ Purchase order no"
          name= 'purchase_order_number'
          value= {purchase_order_number}
        />
        <CustomAutoComplete
          onChange= {handleAutoComplete}         
          label="Quote Request for"
          name= 'quote_rqst_for'
          data= {QUOTE_REQUEST_FOR}
          value= {quote_rqst_for}
          placeholder= 'Choose'
          required
        />
        <CustomBigInput
          onChange= {handleChange}
          label="House Number/Name, Street Name"
          moreInfo= 'Venue address'
          width="100%"
          name= 'house_street_no_name'
          value= {house_street_no_name}
        />
        <CustomInput
          onChange= {handleChange}
          label= 'Address line 2'
          width="100%"
          name= 'address_line_two'
          value= {address_line_two}
        />
        <CustomInput
          onChange= {handleChange}
          label="Town / City"
          width="100%"
          name= 'town_city'
          value= {town_city}
        />
        <CustomInput
          onChange= {handleChange}
          label= 'Post / Zip Code'
          width="100%"
          name= 'post_code'
          value= {post_code}
        />
        <CustomInput
          onChange= {handleChange}
          label= 'County / Region'
          width="100%"
          name= 'county_region'
          value= {county_region}
        />
        <CustomSelect
          onChange= {handleChange}
          label= 'Country'
          moreInfo= 'Venue country'
          width="100%"
          name= 'country'
          value= {country}
          data= {CURRENT_COUNTRY}
        />
        <CustomInput
          onChange= {handleChange}
          label="Other / Remote or Telephone interpreting details"
          name= 'other_details'
          value= {other_details}
        />

        <CustomInput
          onChange= {handleChange}
          label="Details of your request"
          name= 'request_details'
          value= {request_details}
          required
        />
        <CustomBigInput
          onChange= {handleChange}
          label="Do you have a fixed budget for this assignment / project?"
          moreInfo= 'If so, please provide details here including currency'       
          name= 'project_fix_budget'
          value= {project_fix_budget}
          required
        />
        {/* <CustomUpload
          onChange= {handleChange}
          label="Upload documents"
          name= 'documents'
          value= {documents}
        /> */}
        <Card raised style= {{marginTop: '24px'}} >
          <CardBody>
            View Documents
          </CardBody>
        </Card>
        <CustomSelect
          onChange= {handleChange}
          label="Source language"
          data={PRIMARY_SOURCE_LANG}
          width="100%"
          name= 'source_language'
          value= {source_language}
          required
        />
        <CustomSelect
          onChange= {handleChange}
          label="Target language"
          data={PRIMARY_TARGET_LANG.filter(value => value!==source_language)}
          width="100%"
          name= 'target_language'
          value= {target_language}
          required
        />
        <CustomSelect
          onChange= {handleChange}
          label="Please indicate linguist's skills level required"
          moreInfo="Please indicate linguist's skills level required for this assignment (1 - 5)"
          data={SKILL_LEVEL_REQUIRED.filter((value) => handleExpertise(value))}
          width="100%"
          name= 'skills_required'
          value= {skills_required}
          required
        />

        <CustomBigInput
          onChange= {handleChange}
          label="Preferred Linguist / Service provider ID number"
          width="100%"
          name= 'provider_id_number'
          moreInfo="LC will try its best, but cannot guarantee"
          value= {provider_id_number}
        />

        <CustomRadio
          onChange= {handleChange}
          label="Preferred gender"
          moreInfo="LC will try, but cannot guarantee"
          data={GENDER_DATA}
          width="100%"
          name= 'gender'
          value= {gender}
        />
        <CustomDate 
          onChange= {handleDateChange}
          width= '100%' 
          label= 'When do you need this quote by?'
          name= 'quote_by'
          value= {quote_by}
          required
        />
        <CustomSelect
          onChange= {handleChange}
          width="100%"
          label="How do you intend to pay for this service?"
          name= 'pay_type'
          value= {pay_type}
          data= {PAYMENT_QUOTEREQUEST}
        />

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={<Checkbox onChange= {handleChange} checked={agree} name="agree" color="primary" />}
            label="By submitting this form I agree with LC Privacy Policy, T&C"
          />
        </div>


        <div className={classes.buttonFlex}>
          <CustomButton text="Amend" onClick={handleNext} />
          <CustomButton text="Cancel" onClick={handleNext} />
          <Button style= {{marginTop: '2rem', marginRight: '15px'}} variant= 'contained' color= 'primary'>Provide Quote</Button>
          <Button style= {{marginTop: '2rem', marginRight: '15px'}} variant= 'contained' color= 'primary'>View Note</Button>
          <CustomButton text="Archive" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default PageOne;
