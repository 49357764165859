import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

//Material icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

// import Details from './Details'
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Segment,
  Statistic,
  Table,
} from "semantic-ui-react";
import {
  CURRENCY,
  CURRENCY_STRIPE,
  DISCOUNT,
  TAXIFARE,
  TYPE_INTERPRETING,
} from "data";
import { IconButton, Input, TextField } from "@material-ui/core";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import swal from "sweetalert";
import {
  addOtherTransaction,
  addTransaction,
  deleteOtherTransaction,
  deleteTransaction,
} from "redux/actions/expenseAction";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
import CustomBigInput from "components/Common/CustomBigInput";

const InvoiceINC = () => {
  const { id } = useParams();
  const classes = useEmployeeStyles();
  const [count] = useState([0]);
  const history = useHistory();
  const token = Cookies.get("token");
  const [invoice, setInvoice] = useState(null);
  const [linguist, setLinguist] = useState(null);
  const [text, setText] = useState("");
  const [amount, setAmount] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(null);
  const [rate, setRate] = useState(null);
  const [interpretingRate, setInterpretingRate] = useState(1);
  const [estimateRate, setEstimateRate] = useState(null);
  const [calculate, setCalculate] = useState({
    sendButton: false,
    loading: false,
    quoteId: "",
  });

  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
  });

  //   2nd subtotal
  const [otherAmount, setOtherAmount] = useState(null);
  const [otherText, setOtherText] = useState("");
  const [otherQuantity, setOtherQuantity] = useState(null);

  const { transactions } = useSelector((state) => state.expenseReducer);
  const amountsOne = transactions.map((transaction) => transaction.total);
  const subTotalOne = Number(
    amountsOne.reduce((acc, item) => (acc += item), 0)
  ).toFixed(2); //+ Number(totalFTF)

  const { otherTransactions } = useSelector((state) => state.expenseReducer);
  const amountsTwo = otherTransactions.map((transaction) => transaction.total);
  const subTotalTwo = amountsTwo
    .reduce((acc, item) => (acc += item), 0)
    .toFixed(2);

  const total = (Number(subTotalOne) + Number(subTotalTwo)).toFixed(2);

  const [discount, setDiscount] = useState(0);
  const [vat, setVAT] = useState(0);

  //Total after discount
  const totalDiscount = ((Number(total) * discount) / 100).toFixed(2);

  const estimatePaidAdvance =
    invoice &&
    (invoice.estimate_paid_amount
      ? invoice.estimate_paid_amount
      : invoice.grand_total);

  //Total VAT
  const totalVAT = (
    Number(
      Number(total) +
        invoice?.service_charge_amount +
        invoice?.bac_service_charge_amount -
        Number(totalDiscount)
    ) *
    (vat / 100)
  ).toFixed(2);

  //Total after including vat and discount
  const totalTwo = (
    Number(total) +
    invoice?.service_charge_amount +
    invoice?.bac_service_charge_amount -
    Number(totalDiscount) +
    Number(totalVAT)
  ).toFixed(2);

  //Total after subtracting estimate paid
  const grandTotal = (totalTwo - rate).toFixed(2);

  const [lingRef, setLingRef] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleAdd = (e) => {
    e.preventDefault();

    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      text,
      amount: +amount,
      quantity,
      total: amount * quantity,
    };

    dispatch(addTransaction(newTransaction));
    setText("");
    setAmount("");
    setQuantity("");
  };

  const handleAddOther = (e) => {
    e.preventDefault();
    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      otherText,
      otherAmount: +otherAmount,
      otherQuantity,
      total: otherAmount * otherQuantity,
    };

    dispatch(addOtherTransaction(newTransaction));
    setOtherText("");
    setOtherAmount("");
    setOtherQuantity("");
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const loadInvoice = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/view-client-Quote/QuoteID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setInvoice(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          throw error;
        }
      }
    };

    const getAdminProfile = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLinguist(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getCompanyCard = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/view/company-information-card`
        );
        setCompanyCard({
          ...companyCard,
          basc: response?.data[0].getCompanyCard,
          pbsc: response?.data[0].platform_booking_service_charge,
          basc: response?.data[0].billing_account_service_charge,
          cname: response?.data[0].company_name,
          caddress: response?.data[0].company_address,
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadInvoice();
    getAdminProfile();
    getCompanyCard();

    return () => {
      source.cancel();
    };
  }, []);

  const handleCalculate = async () => {
    setCalculate({ ...calculate, loading: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/create-invoice-directQuote/reportID/${invoice?.reportID}`,
        {
          date_of_quote: "",
          date_of_invoice: moment().format("DD/MMM/YYYY"),
          service_completion_date: moment(invoice.end_date).format(
            "DD/MMM/YYYY"
          ),
          linguist_ref: lingRef,
          currency: currency ? currency : "GBP (UK)",
          transactions: transactions,
          otherTransactions: otherTransactions,
          subtotal1: subTotalOne,
          subtotal2: subTotalTwo,
          total: totalTwo,
          service_charge: invoice.service_charge,
          bac_service_charge: invoice.bac_service_charge,
          service_charge_amount: invoice.service_charge_amount,
          bac_service_charge_amount: invoice.bac_service_charge_amount,
          discount_percentage: discount,
          discount_amount: totalDiscount,
          vat_percentage: vat,
          vat_amount: totalVAT,
          grand_total: grandTotal,
          linguist_note: "",
          admin_note: "",
          client_note: "",
          rate: invoice[0],
          company_name: companyCard.cname,
          company_address: companyCard.caddress,
          service: invoice.service,
          source_language: invoice.source_language,
          target_language: invoice.target_language,
          skill_level: invoice.skill_level,
          clientID: invoice?.clientID,
          client_note: "",
          client_title: invoice?.client_title,
          client_firstname: invoice?.client_firstname,
          client_lastname: invoice?.client_lastname,
          client_email: invoice?.client_email,
          client_mobile_no: invoice?.client_mobile_no,
          client_city: invoice?.client_city,
          client_postalcode: invoice?.client_postalcode,
          client_region: invoice?.client_region,
          client_country: invoice?.client_country,
          client_addressline_one: invoice?.client_addressline_one,
          client_addressline_two: invoice?.client_addressline_two,
          client_id: invoice?.client_id,
          estimate_paid_in_advance: rate,

          directQuoteID: invoice?._id,
          QuoteID: invoice?.id,
          clientTrue: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Invoice successfully saved",
        icon: "success",
        button: "Ok",
      });
      setLoading(false);
      setCalculate({
        ...calculate,
        loading: false,
        sendButton: true,
        quoteId: response?.data._id,
      });
    } catch (error) {
      console.log(error);
      setCalculate({ ...calculate, loading: false, sendButton: false });
    }
  };

  const handleSendInvoice = async () => {
    setLoading(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-invoice-directQuote/invID/${calculate.quoteId}`,
        { _id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Invoice successfully send to client",
        icon: "success",
        button: "Ok",
      }).then(function () {
        window.location = "/admin/find-quote";
      });
      setLoading(false);
      // history.push('/admin/find-quote')
    } catch (error) {
      console.log(error);
    }
  };

  const handleCurrency = async (e) => {
    const { value } = e.target;
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
      );
      if (value === "GBP (UK)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.GBP) /
            response?.data?.rates[invoice.currency?.substring(0, 3)]
          ).toFixed(2)
        );
      } else if (value === "EUR (Euro countries)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.EUR) /
            response?.data?.rates[invoice.currency?.substring(0, 3)]
          ).toFixed(2)
        );
      } else if (value === "AUD (Australia)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.AUD) /
            response?.data?.rates[invoice.currency?.substring(0, 3)]
          ).toFixed(2)
        );
      } else if (value === "USD (USA, paying from USA only)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.USD) /
            response?.data?.rates[invoice.currency?.substring(0, 3)]
          ).toFixed(2)
        );
      } else if (value === "CAD (Canada)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.CAD) /
            response?.data?.rates[invoice.currency?.substring(0, 3)]
          ).toFixed(2)
        );
      } else if (value === "TRY (Turkish lira, payment from Turkey only)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.TRY) /
            response?.data?.rates[invoice.currency?.substring(0, 3)]
          ).toFixed(2)
        );
      } else if (value === "SGD (Singapore dollar)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.SGD) /
            response?.data?.rates[invoice.currency?.substring(0, 3)]
          ).toFixed(2)
        );
      } else if (value === "HUF (Hungarian forint)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.HUF) /
            response?.data?.rates[invoice.currency?.substring(0, 3)]
          ).toFixed(2)
        );
      } else if (
        value === "NZD (New Zealand dollar, paying from New Zealand only)"
      ) {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.NZD) /
            response?.data?.rates[invoice.currency?.substring(0, 3)]
          ).toFixed(2)
        );
      } else if (value === "RON (Romanian leu, paying from Romania only)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.RON) /
            response?.data?.rates[invoice.currency?.substring(0, 3)]
          ).toFixed(2)
        );
      }
      // setRate(response?.data?.rates[invoice?.linQuoteID?.currency?.substring(0, 3)])
      setCurrency(value);
    } catch (error) {
      console.log(error);
    }

    try {
      const rate = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
      );
      const temp = value.split(" ")[0];
      setInterpretingRate(rate?.data?.rates[temp].toFixed(2));
    } catch (error) {
      console.log(error);
    }

    try {
      const Erate = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
      );
      const temp = value.split(" ")[0];
      setEstimateRate(Erate?.data?.rates[temp].toFixed(2));
    } catch (error) {
      console.log(error);
    }
  };

  return invoice && linguist ? (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem id="printArea" xs={12} sm={12} md={12} lg={8} xl={8}>
          <Card>
            <CardBody>
              {/* Avatar and company name */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "24px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <CustomAvatar style={{ margin: "0" }} />
                </div>
              </div>

              {/* First div  */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice to:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_firstname}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_addressline_one}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_addressline_two}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_city}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_postalcode}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_region}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_country}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_email}
                    </Header>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      User ID number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{linguist?.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Company Residence:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.company_address}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      VAT/GST no:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.lc_vat}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of quote:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {moment(invoice?.createdAt)
                        .tz("Europe/London")
                        .format("DD/MMM/YYYY")}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of invoice:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{moment().tz("Europe/London").format("DD/MMM/YYYY")}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice to be paid by:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                    </Header>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Service completion date:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{moment(invoice.expiry_date).format("DD/MM/YYYY")}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ref:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      <Input
                        onChange={(e) => setLingRef(e.target.value)}
                        value={lingRef}
                        type="text"
                      />
                    </Header>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Service:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.service}
                  </Header>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Source language:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.source_language}
                  </Header>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Target language:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.target_language}
                  </Header>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Expertise required:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.skill_level}
                  </Header>
                </div>
              </div>

              <Segment size="small" textAlign="center" color="purple">
                <CustomSemanticDD
                  onChange={(e) => handleCurrency(e)}
                  value={currency}
                  label="Currency"
                  data={CURRENCY_STRIPE}
                />
              </Segment>
              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="5">
                      Description of service
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Price{" "}
                      {currency?.split("(")[0]
                        ? currency?.split("(")[0]
                        : "GBP"}{" "}
                      00.00
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Quantity</Table.HeaderCell>
                    <Table.HeaderCell width="1">Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {/* {invoice.productOne.map(tr => (
                                            <Table.Row>
                                                <Table.Cell><Header as='h4'>{tr.text}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.amount}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.quantity}</Header></Table.Cell>
                                                <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>{tr.total}</Header>

                                                </Table.Cell>
                                            </Table.Row>
                                        ))} */}

                  {transactions.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.text}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.amount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.quantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                        <IconButton
                          onClick={() => dispatch(deleteTransaction(tr.id))}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell>
                      <CustomAutoSelect
                        onChange={(e, value) => setText(value)}
                        value={text}
                        label="Choose"
                        data={TYPE_INTERPRETING}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                        placeholder={`${
                          currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"
                        }00.00`}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setQuantity(e.target.value)}
                        value={quantity}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <IconButton
                        disabled={
                          !amount ||
                          !text ||
                          !quantity ||
                          quantity <= 0 ||
                          amount <= 0
                        }
                        onClick={handleAdd}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header textAlign="right" as="h3">
                        Sub Total
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {Number(subTotalOne)}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Expenses and travel</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>

                  {/* {invoice.productTwo.map(tr => (
                                            <Table.Row>
                                                <Table.Cell><Header as='h4'>{tr.otherText}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.otherAmount}</Header></Table.Cell>
                                                <Table.Cell><Header as='h4'>{tr.otherQuantity}</Header></Table.Cell>
                                                <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Header style={{ margin: '0' }} as='h4'>{tr.total}</Header>

                                                </Table.Cell>
                                            </Table.Row>
                                        ))} */}

                  {otherTransactions.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.otherText}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherAmount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherQuantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                        <IconButton
                          onClick={() =>
                            dispatch(deleteOtherTransaction(tr.id))
                          }
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell>
                      <CustomBigInput
                        onChange={(e) => setOtherText(e.target.value)}
                        value={otherText}
                        label="Write service/s details here…"
                        data={TAXIFARE}
                      />
                      {/* <CustomAutoSelect onChange={(e, value) => setOtherText(value)} value={otherText} label='Taxi fare' data={TAXIFARE} /> */}
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setOtherAmount(e.target.value)}
                        value={otherAmount}
                        placeholder={`${
                          currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"
                        }00.00`}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setOtherQuantity(e.target.value)}
                        value={otherQuantity}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <IconButton
                        disabled={
                          !otherQuantity ||
                          !otherAmount ||
                          !otherText ||
                          otherQuantity <= 0 ||
                          otherAmount <= 0
                        }
                        onClick={handleAddOther}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h3">Sub Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {subTotalTwo}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {" "}
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {total}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Discount {invoice?.discount_percentage}%
                      </Header>
                      <CustomAutoSelect
                        data={DISCOUNT}
                        onChange={(e, value) => setDiscount(value)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalDiscount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Billing Account Service Charge{" "}
                        {invoice?.bac_service_charge}%
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {invoice?.bac_service_charge_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Platform and booking service charge{" "}
                        {invoice?.service_charge}%
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {invoice?.service_charge_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        VAT / GST {invoice?.vat_percentage}%
                      </Header>
                      <TextField
                        onChange={(e) => setVAT(e.target.value)}
                        type="number"
                        inputProps={{ min: "0", max: "10", step: "1" }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalVAT}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalTwo}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Estimate paid in advance</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.estimate_paid_amount
                            ? invoice?.estimate_paid_amount
                            : invoice?.grand_total}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Estimate paid in advance (converted)
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic color="purple" size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {rate}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Total to be paid including VAT/GST
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {grandTotal}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Header style={{ marginTop: "16px" }} as="h4">
                  Thank You For Your Business
                </Header>
                <Header
                  color="grey"
                  style={{ marginBottom: "16px", marginTop: "0" }}
                  as="h4"
                >
                  Linguists Collective Ltd, 38 Mill Street, Bedford,
                  Bedfordshire, MK40 3HD
                </Header>

                {!calculate.sendButton && (
                  <Button
                    onClick={handleCalculate}
                    loading={calculate.loading}
                    color="purple"
                  >
                    Calculate and Save
                  </Button>
                )}
                {calculate.sendButton && (
                  <Button
                    onClick={handleSendInvoice}
                    loading={loading}
                    color="purple"
                  >
                    Send
                  </Button>
                )}
                {grandTotal < 0 ? (
                  <p style={{ color: "red" }}>
                    Negative value!! Please make necessary arrangement for
                    refunding the amount shown (-) in 'Total to be paid
                    including VAT/GST'.
                  </p>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default InvoiceINC;
