import styled from "styled-components";

export const CompanyDetailsDiv = styled.div`
  text-align: center;

  p {
    margin: 0;
    padding: 1px 0;
  }

  .details {
    margin: 10px 0;
    .text:first-child {
      font-size: 13px;
      font-weight: bold;
    }
  }
`;

export const AddNewUserDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiButton-root {
    width: 100%;
  }
`;

export const UserItemDiv = styled.div`
  text-align: center;
  p {
    margin: 0;
    padding: 1px 0;
  }
`;

export const MoveDiv = styled.div`
  text-align: center;
`;

export const CenterDiv = styled.div`
  text-align: center;
  margin-top: 1rem;
`;
