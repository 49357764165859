import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import Cookies from "js-cookie";
import SearchDeleteRequest from "./Search/SearchInhouseDeleteRequest";
import swal from "sweetalert";
import Axios from "axios";
import Action from "./Action";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Button } from "semantic-ui-react";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable() {
  const { addToast } = useToasts();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState([]);

  //   const { name, error, success, List, removed, reload } = values;

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-account-request-list-inc`
    )
      .then((res) => setValues(res.data || []))
      .catch((err) => console.log(err));
  };

  return (
    <>
      <SearchDeleteRequest show={show} setShow={setShow} />
      <Paper className={classes.root}>
        {show ? (
          <div>
            {" "}
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" id="export">
                <TableHead>
                  <TableRow>
                    <TableCell>Client ID</TableCell>
                    <TableCell>Firstname</TableCell>
                    <TableCell>Lastname</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile no</TableCell>
                    <TableCell>Pass number</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell>Delete Request Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((list) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={list._id}
                      >
                        <TableCell>
                          <Link to={`/admin/view-inhouse/${list._id}`}>
                            {list.id}
                          </Link>
                        </TableCell>
                        <TableCell>{list.FirstName}</TableCell>
                        <TableCell>{list.LastName}</TableCell>
                        <TableCell>{list.email}</TableCell>
                        <TableCell>{list.mobile_no}</TableCell>
                        <TableCell>{list.passNumber}</TableCell>
                        <TableCell>{list.reason}</TableCell>
                        <TableCell>
                          {Moment(list.createdAt).format(
                            "DD MMM YYYY - kk:mm:ss"
                          )}
                        </TableCell>
                        <TableCell>
                          <Action load={loadList} id={list?._id} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={values.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </>
  );
}
