import React, { useState, useEffect } from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import { Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AssignmentOptions from "components/Linguist/Assignment/AssignmentOptions";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const AssignmentPage = () => {
  const classes = useDashboardStyles();

  const [cacAndBacAccess, setCacAndBacAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("LINtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCacAndBacAccess(response?.data.cacAndBacAccess);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          {cacAndBacAccess ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>
                    CAC, BAC & INC Assignments
                  </p>
                </CardHeader>
                <CardBody>
                  <AssignmentOptions />
                </CardBody>
              </Card>
            </GridItem>
          ) : cacAndBacAccess === false ? (
            <Header as="h3">
              Access Denied! <br /> Complete Full Registration or Contact HR /
              Admin Team.
            </Header>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default AssignmentPage;
