import React, { useEffect, useRef, useState } from "react";
import Items from "./TableBody";
import history from "utils/history";
import "./style.css";
import Axios from "axios";
import { useParams } from "react-router-dom";
import CustomTextInput from "components/Common/CustomTextInput";
import { Button, Dimmer, Header, Loader, Rating } from "semantic-ui-react";
import TimesheetFooter from "./ItemsTwo";
import { useSelector } from "react-redux";
import CustomSnackBar from "components/Common/CustomSnackBar";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Modal } from "semantic-ui-react";
import DisputeForm from "./DisputeForm"

const Details = () => {
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const { data } = useSelector((state) => state.timesheetReducer);
  const uploadRef = useRef();
  const [linguistRating, setLinguistRating] = useState(null);
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const [submit, setSubmit] = useState({
    toast: false,
    loader: false,
  });

  const [noteOpen, setNoteOpen] = useState(false);

  const handleDispute = () => {
    setNoteOpen(true);
  };

  const {
    start_date,
    end_date,
    feedback,
    note,
    duration,
    start_time,
    signature,
  } = data;

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getTimeSheet = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/inhouse/view-closed-booking/bookingID/${id}`
        );
        setDetails(response?.data[0]);
      } catch (error) {
        console.log(error);
      }
    };

    getTimeSheet();

    return () => {
      source.cancel();
    };
  }, []);

  const handleSubmit = async () => {
    setSubmit({ ...submit, loader: true });
    const file = new File([signature], "signature", { type: "image/png" });
    const fd = new FormData();
    fd.append("signatureByINC", file);
    fd.append("feedbackByClient", linguistRating);
    fd.append("client_note", note);
    if (uploadTimesheet?.size) {
      fd.append("documentByClient", uploadTimesheet);
    }
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse/accept-timesheet/timesheetID/${details._id}`,
        fd
      );
      setSubmit({ ...submit, loader: false, toast: true });
      window.location = "/";
    } catch (error) {
      console.log(error);
      setSubmit({ ...submit, loader: false, toast: true });
    }
  };

  const handleViewPdf = () => {
    window.html2canvas = html2canvas;
    var doc = new jsPDF({
      orientation: "potrait",
      unit: "pt",
      size: "A4",
      // format: [4, 2]
    });
    var content = document.getElementById("printArea");
    doc.html(content, {
      callback: function (doc) {
        // doc.save('myDocument.pdf');
        window.open(doc.output("bloburl"));
      },
    });
  };

  const handleRate = (e, { rating, maxRating }) => {
    setLinguistRating(rating);
  };

  return details ? (
    <div>
      {details?.signatureByINCTrue ? "Already confirmed close and timesheet." :
        <>
          <div style={{ width: "100%", marginTop: "24px" }}>
            <CustomSnackBar
              message="Timesheet confirmed"
              handleClose={() => setSubmit({ ...submit, toast: false })}
              openSnackBar={submit.toast}
            />
            <CustomTextInput label="Job Reference Number" value={details.id} />

            <Items details={details} />

            <CustomTextInput
              label="Instructions for linguist professional"
              value=""
            />

            {/* Address details */}
            {details?.inhouseID?.remote_address && (
              <CustomTextInput
                label="Remote Address"
                value={details?.inhouseID?.remote_address}
              />
            )}
            {!details?.inhouseID?.remote_address ? (
              <>
                <CustomTextInput
                  label="House Number / Street Name"
                  value={details?.inhouseID?.house_number_street_name}
                />
                <CustomTextInput
                  label="Address Line 2"
                  value={details?.inhouseID?.address_line_two}
                />
                <CustomTextInput
                  label="County / Region"
                  value={details?.inhouseID?.region}
                />
                <CustomTextInput
                  label="Post Code"
                  value={details?.inhouseID?.post_code}
                />
                <CustomTextInput
                  label="Country"
                  value={details?.inhouseID?.country}
                />
              </>
            ) : null}
            <CustomTextInput
              label="Court case, police, NHS ref"
              value={details?.inhouseID?.security_clearance}
            />

            <div
              style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Feedback from linguist
              </Header>
              <div>

                <Rating
                  maxRating={5}
                  rating={details?.feedback}
                  icon="star"
                  size="massive"
                />
              </div>

              <Header style={{ margin: "0" }} as="h4">
                Feedback to linguist
              </Header>
              <div>
                <Rating
                  maxRating={5}
                  onRate={handleRate}
                  rating={linguistRating}
                  icon="star"
                  size="massive"
                />
              </div>
            </div>

            <CustomTextInput
              label="Customer / Client Name"
              value={
                details?.inhouseID?.ClientID?.FirstName +
                " " +
                details?.inhouseID?.ClientID?.LastName
              }
            />
            <CustomTextInput
              label="Customer / Client ref no."
              value={details?.clientRef}
            />
            <CustomTextInput
              label="Emergency Contact no."
              value={details?.inhouseID?.ClientID?.mobile_no}
            />
            <CustomTextInput label="Name of limited speaker conference" value="" />
            <TimesheetFooter details={details} />
          </div>

          <input
            onChange={(e) => setUploadTimesheet(e.target.files[0])}
            ref={uploadRef}
            type="file"
            style={{ display: "none" }}
          />
          <Header as="h4">
            Upload timesheet or proof of service delivery, max1MB
          </Header>
          <Button onClick={() => uploadRef.current.click()} basic color="purple">
            Upload
          </Button>
          <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
            {uploadTimesheet?.name}
          </Header>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
          >
            <div style={{ padding: "10px" }}>
              <Button
                onClick={() => history.push(`/view-timesheet-document-bylinguist/${details?._id}`)}
                style={{ backgroundColor: "purple", color: "white" }}
              >
                View
              </Button>
            </div>
            <div style={{ padding: "10px" }}>
              <Button
                disabled={submit.loader || !signature || !linguistRating}
                onClick={handleSubmit}
                style={{ backgroundColor: "purple", color: "white" }}
              >
                Confirm
              </Button>

              {details?.dispute ?
                <Button
                  color="purple"
                  disabled
                >
                  Disputed
                </Button>
                :
                <Modal
                  open={noteOpen}
                  onClose={() => setNoteOpen(false)}
                  onOpen={() => setNoteOpen(true)}
                  trigger={
                    <Button
                      color="purple"
                      onClick={handleDispute}
                    >
                      Dispute
                    </Button>
                  }
                >
                  <Modal.Content>
                    <Modal.Description>
                      <DisputeForm bookingID={id} timesheetID={details._id} />
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              }

              {submit.loader && (
                <Dimmer>
                  <Loader active inline />
                </Dimmer>
              )}
            </div>
          </div>
        </>
      }
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
