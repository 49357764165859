import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { getArchiveSPPO } from "../../../../../action/manager";
import Moment from "moment";
import Cookies from "js-cookie";
import Action from "./Action";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const token = Cookies.get("token");
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    List: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, List, removed, reload } = values;

  useEffect(() => {
    loadList();
  }, [reload]);

  const loadList = () => {
    getArchiveSPPO().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setValues({ ...values, List: data });
      }
    });
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="export">
          <TableHead>
            <TableRow>
              <TableCell>Unique ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Registration Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {List?.length > 0 ? (
            <TableBody>
              {List?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((list) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={list._id}>
                  <TableCell key={list._id} align={list.align}>
                    {list.id}
                  </TableCell>

                  <TableCell>{list?.title}</TableCell>
                  <TableCell>{list?.first_name}</TableCell>
                  <TableCell>{list?.last_name}</TableCell>
                  <TableCell>{list?.email}</TableCell>
                  <TableCell>
                    {Moment(list?.createdAt).format("DD MMM YYYY - kk:mm:ss")}
                  </TableCell>

                  <TableCell>
                    <Action id={list?._id} load={loadList} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={List?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
