import { CHANGE_CONTACT_INPUT, UPDATE_CONTACT_DATA } from "./actionTypes";

export const changeContactCardInput = data => ({
    type: CHANGE_CONTACT_INPUT,
    payload: data
})

export const updateContactData = data => ({
    type: UPDATE_CONTACT_DATA,
    payload: data
})