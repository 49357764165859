import ReactToExcel from "react-html-table-to-excel";
// import Pdf from "react-to-pdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DateFnsUtils from "@date-io/date-fns";
import { Box, useMediaQuery } from "@material-ui/core";
import { Button } from "semantic-ui-react";
import CustomDate from "./CustomDate";
import { DateFilterDiv, CustomButton } from "./style";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles({
  button: {
    background: "#3f51b5 none",
    border: 0,
    color: "#ffff",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
  },
});
const DateFilter = ({ fromDate, setFromDate, toDate, setToDate, cRef }) => {
  const classes = useStyles();
  const handleClear = () => {
    setFromDate();
    setToDate();
  };
  const matches = useMediaQuery("(max-width:768px)");
  const handlePdf = () => {
    const input = document.getElementById("cashbook-excel");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("./download.pdf");
    });
  };

  return (
    <DateFilterDiv>
      {!matches ? (
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <h3>Select Date Range: </h3>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              width="100%"
              onChange={(date) => setFromDate(date)}
              value={fromDate}
              name="from_date"
              label="From Date"
              width="15%"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              variant="inline"
              style={{ marginRight: 10, marginLeft: 5 }}
            />
          </MuiPickersUtilsProvider>
          {/* <CustomDate
        name="from_date"
        label="From Date"
        width="15%"
        style={{
          marginLeft: "0px",
          marginRight: "15px",
        }}
        onChange={(date) => setFromDate(date)}
        value={fromDate}
      /> */}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              width="100%"
              onChange={(date) => setToDate(date)}
              value={toDate}
              name="to_date"
              label="To Date"
              width="15%"
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
            />
          </MuiPickersUtilsProvider>
          {/* <CustomDate
        name="to_date"
        label="To Date"
        width="15%"
        style={{
          marginLeft: "0px",
          marginRight: "0px",
        }}
        onChange={(date) => setToDate(date)}
        value={toDate}
      /> */}
          {/* <Button
        
        color="primary"
        style={{
          marginLeft: "5px",
          marginRight: "5px",
          background:'#3f51b5 none'
        }}
        onClick={handleClear}
      >
        <ReactToExcel
          className={classes.button}
          table="cashbook-excel"
          filename="cashbook-table-xls"
          sheet="sheet 1"
          buttonText="Export in Excel"
       
        />
      </Button> */}
        </Box>
      ) : (
        <Box
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "auto auto",
            marginTop: 20,
          }}
        >
          <h3>Select Date Range: </h3>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              width="100%"
              onChange={(date) => setFromDate(date)}
              value={fromDate}
              name="from_date"
              label="From Date"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              variant="inline"
              style={{ marginRight: 10, marginLeft: 5 }}
            />
          </MuiPickersUtilsProvider>
          {/* <CustomDate
        name="from_date"
        label="From Date"
        width="15%"
        style={{
          marginLeft: "0px",
          marginRight: "15px",
        }}
        onChange={(date) => setFromDate(date)}
        value={fromDate}
      /> */}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              width="100%"
              onChange={(date) => setToDate(date)}
              value={toDate}
              name="to_date"
              label="To Date"
              style={{ margin: 5 }}
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
            />
          </MuiPickersUtilsProvider>
          {/* <CustomDate
        name="to_date"
        label="To Date"
        width="15%"
        style={{
          marginLeft: "0px",
          marginRight: "0px",
        }}
        onChange={(date) => setToDate(date)}
        value={toDate}
      /> */}

          {/* <Button
        
        color="primary"
        style={{
          marginLeft: "5px",
          marginRight: "5px",
          background:'#3f51b5 none',
          marginTop:5
        }}
        onClick={handleClear}
      >
        <ReactToExcel
          className={classes.button}
          table="cashbook-excel"
          filename="cashbook"
          sheet="sheet 1"
          buttonText="Export in Excel"
       
        />
      </Button>
    */}
        </Box>
      )}
    </DateFilterDiv>
  );
};

export default DateFilter;
