import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import Moment from "moment";
import { removeInhouseClientRequest } from "../../../action/sppoAction";
// import SearchClient from './Search/SearchView'
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const ViewClientInhouse = ({ searchChat }) => {
  const token = Cookies.get("SPPOtoken");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    privateClients: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, privateClients, removed, reload } = values;

  const loadPrivateClient = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/inc-account-delete-request-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, privateClients: response?.data });
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    loadPrivateClient();
  }, [reload]);

  const deleteClient = async (id) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/archive-inhouse-client/clientID/${id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      loadPrivateClient();
    } catch (error) {
      console.log(error.response);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure you want to move this client to archive folder?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          deleteClient(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Client ID</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email Address</TableCell>
                <TableCell>Pass Number</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Request Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {privateClients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((list) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={list._id}>
                    <TableCell key={list._id} align={list.align}>
                      <Link>{list.id}</Link>
                    </TableCell>
                    <TableCell>{list.FirstName}</TableCell>
                    <TableCell>{list.LastName}</TableCell>
                    <TableCell>{list.email}</TableCell>
                    <TableCell>{list.passNumber}</TableCell>
                    <TableCell>{list.reason}</TableCell>
                    <TableCell>
                      {Moment(list.createdAt).format("DD MMM YYYY - kk:mm:ss")}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleDelete(list._id)}>
                        Archive
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={privateClients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default ViewClientInhouse;
