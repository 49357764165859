import AcepDec from "./AcepDec";

export const langRow = (text) => (
  <div
    style={{
      maxWidth: "120px",
      margin: "auto",
    }}
  >
    {text}
  </div>
);

export const dateRow = (text) => (
  <div
    style={{
      maxWidth: "130px",
      margin: "auto",
    }}
  >
    {text}
  </div>
);

export const addressRow = (text) => (
  <div
    style={{
      maxWidth: "200px",
      margin: "auto",
    }}
  >
    {text}
  </div>
);

export const acceptDecline = (type, fn, handleAccept, handleDecline, id, email, declineOp) => <AcepDec type={type} fn={fn} handleAccept= {handleAccept} handleDecline= {handleDecline} id= {id} email= {email} declineOp= {declineOp}/>;
