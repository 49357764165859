import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CIC from "components/Admin/ControlPanel/CompanyCard/CIC/CIC";
import Booking from "components/Admin/ControlPanel/CompanyCard/CIC/Booking";

import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const BookingSecratery = () => {
  const classes = useDashboardStyles();

  return (
    <AccountClientLayout>
      <Accountclient>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{
              margin: "auto",
            }}
          >
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Company Information</p>
              </CardHeader>
              <CardBody>
                <CIC />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{
              margin: "auto",
            }}
          >
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Booking Secretary</p>
              </CardHeader>
              <CardBody>
                <Booking />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{
              margin: "auto",
            }}
          >
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}> Report system issues</p>
              </CardHeader>
              <CardBody></CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Accountclient>
    </AccountClientLayout>
  );
};

export default BookingSecratery;
