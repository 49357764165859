import React, { Fragment } from "react";

import Login from "components/Auth/LCJoining/Login/Login";

const LinguistLoginPage = () => {
  return (
    <Fragment>
      <Login />
    </Fragment>
  );
};

export default LinguistLoginPage;
