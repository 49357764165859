import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import CustomSelect from "components/Common/CustomSelect";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";
import CustomUpload from "components/Common/CustomUpload";
import CustomRadio from "components/Common/CustomRadio";
import history from "utils/history";
import LoadingScreen from "react-loading-screen";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
// Others
import {
  PRIMARY_TARGET_LANG,
  PRIMARY_SOURCE_LANG,
  VIRTUAL_FORMATTING,
  DOCUMENT_TYPE,
  TRANSLATION_CERTIFICATE,
  TRANSLATION_PRINTED,
  SECURITY_CLEARANCE_LEVEL,
  PRINT_QUALITY,
  SKILL_LEVEL_REQUIRED,
  TIMEZONE,
  RARE_REGEX,
  LEVEL_REGEX
} from "data";

import { changeOTTInput } from "redux/actions/ottAction";
import { Checkbox, FormControlLabel, Icon } from "@material-ui/core";
import CustomDateTime from "components/Common/CustomDateTime";
import swal from "sweetalert";
import Axios from "axios";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import { useMediaQuery } from "@material-ui/core";

const FormTwo = ({ adminID, adminName }) => {
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:768px)");
  const { data } = useSelector((state) => state.ottReducer);
  const classesb = useLandingpageStyles();

  const [loading, setLoading] = useState(false);

  const token = Cookies.get("token");
  const {
    select_client,
    purchase_order_number,
    document_type,
    other_details,
    source_language,
    target_language,
    source_expertise_level_required,
    target_expertise_level_required,
    require_visual_formating,
    certified_translation,
    certificate,
    additional_quality_check,
    provider_id_number,
    wordCount,
    additional_note,
    timezone,
    deadline,
    agree,
    security_clearance,
    upload_file,
  } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeOTTInput({
        name,
        value: value,
      })
    );
  };
  const handleChecked = (e) => {
    const { name, checked } = e.target;
    dispatch(
      changeOTTInput({
        name,
        value: checked,
      })
    );
  };

  const handleChangeS = (e, value) => {
    dispatch(
      changeOTTInput({
        name: "source_language",
        value,
      })
    );
  };

  const handleChangeT = (e, value) => {
    dispatch(
      changeOTTInput({
        name: "target_language",
        value,
      })
    );
  };
  const handleExpertise = (value) => {
    if (target_language?.includes("R") || source_language?.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(
        LEVEL_REGEX
       );
    }
  };

  const classes = useFormStyles();

  //submitting the file
  const handleFiles = (e) => {
    const value = e.target.files[0];
    if (value.size >= 5000000) {
      swal({
        title: "Kindly select less than 5 mb file",
      });
    } else {
      dispatch(
        changeOTTInput({
          name: "upload_file",
          value,
        })
      );
    }
  };

  const handleNext = async (e) => {
    //upload cv
    swal(
      `
      Have you reconfirmed all details with client? Read the T&C? Note that you will not be able to return to this page, after submission you will be directed to summary page. Are you ready to submit?`,
      {
        icon: "warning",
        buttons: ["Cancel", "Yes"],
      }
    ).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        // let fd = new FormData()
        // fd.append("upload_file", upload_file)
        Cookies.set("wordCount", wordCount);
        let fd = new FormData();

        fd.append("select_client", select_client);
        fd.append("purchase_order_number", purchase_order_number);
        fd.append("document_type", document_type);
        fd.append("other_details", other_details);
        fd.append("source_language", source_language);
        fd.append("target_language", target_language);
        fd.append(
          "source_expertise_level_required",
          source_expertise_level_required
        );
        fd.append(
          "target_expertise_level_required",
          target_expertise_level_required
        );
        fd.append("require_visual_formating", require_visual_formating);
        fd.append("certified_translation", certified_translation);
        fd.append("certificate", certificate);
        fd.append("additional_quality_check", additional_quality_check);
        fd.append("provider_id_number", provider_id_number);
        fd.append("wordCount", wordCount);
        fd.append("additional_note", additional_note);
        fd.append("timezone", timezone);
        fd.append("deadline", deadline);
        fd.append("agree", agree);
        fd.append("security_clearance", security_clearance);
        fd.append("upload_file", upload_file);
        fd.append("adminID", adminID);
        fd.append("adminName", adminName);

        try {
          const response = Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form`,
            fd
          )
            .then((response) => {
              Cookies.set("currency", response?.data.message.new_currency);

              //id

              Cookies.set("id", response?.data.message.id);
              Cookies.set("BACusername", response?.data.message.BACusername);
              //totalPriceForTranslation
              Cookies.set(
                "totalPriceForTranslation",
                response?.data.message.totalPriceForTranslation
              );

              //totalPriceForCertificate
              Cookies.set(
                "totalPriceForCertificate",
                response?.data.message.totalPriceForCertificate
              );

              //totalPriceForQualityCheck
              Cookies.set(
                "totalPriceForQualityCheck",
                response?.data.message.totalPriceForQualityCheck
              );

              //translationVisualFormatingPrice
              Cookies.set(
                "translationVisualFormatingPrice",
                response?.data.message.translationVisualFormatingPrice
              );
            })
            .then(function () {
              setLoading(false);
              history.push("order-translation/summary");
            });
          //for currency

          // Have you reconfirmed all details with client? Read the T&C? Note that you will not be able to return to this page after submission. Are you ready to submit?
          // Have you reconfirmed all details with client? Read the T&C? Note that you will not be able to return to this page, after submission you will be directed to summary page. Are you ready to submit?
          // Cancel> <Yes>
        } catch (error) {
          if (error.response) {
            swal({
              title: "Oops!",
              text: `${error?.response?.data?.error}`,
              icon: "error",
              buttons: false,
            });
          }
        }

        // window.location = "/admint/order-translation-telephonic-form/summary";
      }
    });
  };

  const isAllow =
    !select_client ||
    !document_type ||
    (document_type.includes("Other") && !other_details) ||
    !source_language ||
    !target_language ||
    !target_expertise_level_required ||
    !source_expertise_level_required ||
    !require_visual_formating ||
    !certified_translation ||
    (certified_translation === "Yes" && !certificate) ||
    !additional_quality_check ||
    !deadline ||
    !agree;

  const handleDateChange = (date) => {
    dispatch(
      changeOTTInput({
        name: "deadline",
        value: date.target.value,
      })
    );
  };

  const handleChangeCL = (e, value) => {
    dispatch(
      changeOTTInput({
        name: "select_client",
        value,
      })
    );
  };
  const handleSecurityChange = (e, value) => {
    dispatch(
      changeOTTInput({
        name: "security_clearance",
        value,
      })
    );
  };

  return (
    <div>
      {loading === false ? (
        <div>
          {/*'Developer allow "add more" option at the end of first order'*/}
          <form className={classes.form} noValidate autoComplete="off">
            <CustomInputSingle
              onChange={handleChange}
              width="100%"
              label="Your booking ref/ Purchase order no"
              name="purchase_order_number"
              value={purchase_order_number}
              placeholder="Enter your reference number or purchase order number"
            />

            <CustomSelect
              required
              onChange={handleChange}
              name="document_type"
              value={document_type}
              label="Document type"
              data={DOCUMENT_TYPE}
              width="100%"
            />
            {document_type?.includes("Other") ? (
              <CustomInputSingle
                onChange={handleChange}
                label="Other details"
                name="other_details"
                value={other_details}
                width="100%"
                required
              />
            ) : null}
            <CustomAutoSelect
              onChange={handleChangeS}
              label="Source/Native language"
              data={PRIMARY_SOURCE_LANG}
              width="100%"
              name="source_language"
              value={source_language}
              required
            />

            <CustomSelect
              onChange={handleChange}
              label="Please indicate linguist's source language skills level required"
              moreInfo="Please indicate linguist's source language skills level required for this assignment (1 - 5)"
              data={SKILL_LEVEL_REQUIRED.filter((value) =>
                handleExpertise(value)
              )}
              required
              width="100%"
              name="source_expertise_level_required"
              value={source_expertise_level_required}
            />

            <CustomAutoSelect
              onChange={handleChangeT}
              label="Target language"
              data={PRIMARY_TARGET_LANG.filter(
                (value) => value !== source_language
              )}
              width="100%"
              name="target_language"
              value={target_language}
              required
            />
            <CustomSelect
              required
              onChange={handleChange}
              label="Please indicate linguist's target language skills level required"
              moreInfo="Please indicate linguist's target language skills level required for this assignment (1 - 5). Target language skills level is an element for price calculation."
              data={SKILL_LEVEL_REQUIRED.filter((value) =>
                handleExpertise(value)
              )}
              width="100%"
              name="target_expertise_level_required"
              value={target_expertise_level_required}
            />

            <CustomAutoSelect
              onChange={handleSecurityChange}
              label="Please indicate security clearance level required*"
              moreInfo="Please indicate security clearance level required for this assignment"
              data={SECURITY_CLEARANCE_LEVEL}
              width="100%"
              name="security_clearance"
              value={security_clearance}
            />

            {/*  (*Developer make sure this charge can be changed on settings with other rates */}
            <CustomSelect
              onChange={handleChange}
              label="Do this job require visual formatting?"
              moreInfo="document to look alike same as source document formatting boxes, graphs etc, set charge of £x* each A4 page"
              data={VIRTUAL_FORMATTING}
              width="100%"
              name="require_visual_formating"
              value={require_visual_formating}
              required
            />
            {/* (*Developer make sure charge can be modified easily on settings) */}
            <CustomSelect
              onChange={handleChange}
              label="Do you require this translation to be certified"
              moreInfo="By a qualified translator? If yes, please indicate which page(s) / document(s)? "
              data={TRANSLATION_CERTIFICATE}
              width="100%"
              name="certified_translation"
              value={certified_translation}
              required
            />
            {/* (*Developer make sure charge can be modified easily on settings) */}

            {/* (*Developer make sure charge can be modified easily on settings) */}
            {certified_translation === "Yes" && (
              <CustomRadio
                onChange={handleChange}
                label="Would you like certificate to be posted or electronically?"
                moreInfo="If you have requested your translation to be certified"
                data={TRANSLATION_PRINTED}
                flexDir="column"
                width="100%"
                name="certificate"
                value={certificate}
              />
            )}
            {/* (*Developer make sure charge can be modified easily on settings)  */}

            {!matches ? (
              <CustomSelect
                onChange={handleChange}
                label="Would you require additional quality check?"
                moreInfo="Would you require additional quality assurance QA for this assignment / project? (a qualified second linguist will check the work of main translator and assure quality of translation, recommended for large volume of translation, not necessary for certified translation)"
                data={PRINT_QUALITY}
                width="100%"
                name="additional_quality_check"
                value={additional_quality_check}
                required
              />
            ) : (
              <CustomSelect
                onChange={handleChange}
                label="Would you require additional quality check?"
                moreInfo="Would you require additional quality assurance QA for this assignment / project? (a qualified second linguist will check the work of main translator and assure quality of translation, recommended for large volume of translation, not necessary for certified translation)"
                data={PRINT_QUALITY}
                width="100%"
                name="additional_quality_check"
                value={additional_quality_check}
                required
                style={{ marginTop: 50 }}
              />
            )}

            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <CustomUpload
                onChange={handleFiles}
                accept=".pdf,.docx, .jpeg"
                name="upload_file"
                width="100%"
                label="Please upload your document(s) here (clear and readable scan, pdf or photos/JPEG only)"
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button color="secondary">
                {upload_file ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Icon name="file pdf" size="big" />
                    <p
                      style={{
                        margin: "0",
                        marginTop: "7px",
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                      }}
                    >
                      {upload_file.name}
                    </p>
                    <p>{upload_file?.lastModifiedDate?.toDateString()} </p>
                    <p style={{ margin: "0" }}>
                      {(upload_file.size / 1024).toFixed(2)}Kb{" "}
                    </p>
                  </div>
                ) : null}
              </Button>
            </div>

            {/* (*Developer try to make this automated) */}
            <CustomBigInputSingle
              onChange={handleChange}
              name="wordCount"
              value={wordCount}
              width="100%"
              label="Estimated number words / word counts"
              moreInfo="(10k words or 20 A4 pages only. More than 10k or 20 pages please use quote)"
              required
              type={"number"}
              placeholder="Provide number of words in source language e.g. 10000"
            />
            <CustomBigInputSingle
              onChange={handleChange}
              width="100%"
              label="Preferred Linguist / Service provider ID number"
              name="provider_id_number"
              moreInfo="LC will try its best, but cannot guarantee"
              value={provider_id_number}
              placeholder="Provide details of the preferred linguist e.g. LIN/PRO12345 - Jane Miller"
            />
            <CustomInput
              onChange={handleChange}
              name="additional_note"
              value={additional_note}
              label="Additional note for translator"
              width="100%"
              placeholder="Provide any other information that you would like translator to have"
            />

            <CustomSelect
              onChange={handleChange}
              label="Select time zone"
              width="100%"
              data={TIMEZONE}
              name="timezone"
              //defaultValue= {moment.tz.guess()}
              value={timezone}
              helperText={moment.tz.guess()}
            />

            <CustomDateTime
              onChange={handleDateChange}
              width="100%"
              label="Translation needed by*"
              moreInfo="Please allow sufficient time for translation, LC linguist will try their best to complete on time"
              name="deadline"
              value={deadline}
              required
            />

            <div
              className={classes.tc}
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChecked}
                    name="agree"
                    checked={agree}
                    color="primary"
                  />
                }
                label="I have confirmed the details with the client and informed client must agree with LC T&C and Privacy policy for completing this booking / order / quote request
            "
              />
            </div>

            <div className={classes.buttonFlex}>
              <CustomButton
                disabled={isAllow}
                className={`${classesb.button}`}
                text="Submit"
                onClick={handleNext}
              />
            </div>
          </form>
        </div>
      ) : (
        <LoadingScreen
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          logoSrc="https://linguistscollective.com/static/media/Interpreting-Translation-Linguists-Collective-Language-Services.0f1108ca.png"
          text="Please wait! The system is calculating translation summary."
        >
          <div>Loadable content</div>
        </LoadingScreen>
      )}
    </div>
  );
};

export default FormTwo;
