import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "components/Common/CustomSelect";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import CustomRadio from "components/Common/CustomRadio";
import { DropzoneArea } from "material-ui-dropzone";
import { Header } from "semantic-ui-react";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import Cookies from "js-cookie";
// Others
import {
  PRIMARY_TARGET_LANG,
  PRIMARY_SOURCE_LANG,
  VIRTUAL_FORMATTING,
  DOCUMENT_TYPE,
  TRANSLATION_CERTIFICATE,
  SECURITY_CLEARANCE_LEVEL,
  TRANSLATION_PRINTED,
  PRINT_QUALITY,
  SKILL_LEVEL_REQUIRED,
  TIMEZONE,
  RARE_REGEX,
  LEVEL_REGEX
} from "data";
import { changeTransInput } from "redux/actions/transAction";
import { Checkbox, useMediaQuery } from "@material-ui/core";

import CustomAutoSelect from "components/Common/CustomAutoSelect";

import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import Axios from "axios";
import swal from "sweetalert";
import moment from "moment";

// import { Button } from "semantic-ui-react";

const FormTwo = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.transReducer);
  const matches = useMediaQuery("(max-width:768px)");
  const classesb = useLandingpageStyles();
  // const [count, setCount] = useState('')
  const [submitLoader, setSubmitLoader] = useState(false);

  const {
    purchase_order_number,
    document_type,
    other_details,
    source_language,
    target_language,
    target_expertise_level_required,
    source_expertise_level_required,
    require_visual_formating,
    certified_translation,
    certificate,
    additional_quality_check,
    provider_id_number,
    additional_note,
    timezone,
    deadline,
    security_clearance,
    agree,
    count,
    currency,
    upload_file,
  } = data;
  const [deadlineDate, setDeadlineDate] = useState();
  const [deadlineTime, setDeadlineTime] = useState();
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeTransInput({
        name,
        value: value,
      })
    );
  };

  const handleChecked = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeTransInput({
        name,
        value: checked,
      })
    );
  };
  const token = Cookies.get("BACtoken");

  const handleExpertise = (value) => {
    if (target_language?.includes("R") || source_language?.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(
        LEVEL_REGEX
       );
    }
  };

  const { username } = JSON.parse(localStorage.getItem("BACuser"));
  const classes = useFormStyles();

  //submitting the file
  const handleFiles = (file) => {
    dispatch(
      changeTransInput({
        name: "upload_file",
        value: file[0],
      })
    );
  };

  const handleNext = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    let fd = new FormData();

    fd.append("purchase_order_number", purchase_order_number);
    fd.append("document_type", document_type);
    fd.append("other_details", other_details);
    fd.append("source_language", source_language);
    fd.append("target_language", target_language);
    fd.append(
      "source_expertise_level_required",
      source_expertise_level_required
    );
    fd.append(
      "target_expertise_level_required",
      target_expertise_level_required
    );
    fd.append("require_visual_formating", require_visual_formating);
    fd.append("certified_translation", certified_translation);
    fd.append("certificate", certificate);
    fd.append("additional_quality_check", additional_quality_check);
    fd.append("provider_id_number", provider_id_number);
    fd.append("count", count);
    fd.append("additional_note", additional_note);
    fd.append("timezone", timezone);
    fd.append("deadline", deadline);
    fd.append("agree", agree);
    fd.append("security_clearance", security_clearance);
    fd.append("upload_file", upload_file);
    fd.append("username", username);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/accountclient_translation`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubmitLoader(false);
      //for currency
      Cookies.set("currency", response?.data.message.new_currency);

      //id
      Cookies.set("bacTransID", response?.data.message.id);

      //wordcount
      Cookies.set("wordCount", response?.data.message.wordCount);

      //totalPriceForTranslation
      Cookies.set(
        "totalPriceForTranslation",
        response?.data.message.totalPriceForTranslation
      );

      //totalPriceForCertificate
      Cookies.set(
        "totalPriceForCertificate",
        response?.data.message.totalPriceForCertificate
      );

      //totalPriceForQualityCheck
      Cookies.set(
        "totalPriceForQualityCheck",
        response?.data.message.totalPriceForQualityCheck
      );

      //translationVisualFormatingPrice
      Cookies.set(
        "translationVisualFormatingPrice",
        response?.data.message.translationVisualFormatingPrice
      );
      swal(
        `Have you completed all details? Please note that once this form submitted, you will not be able to amend any details here. Are you sure you want to submit this form now? `,
        {
          icon: "warning",
          buttons: ["Cancel", "Yes"],
        }
      ).then((willDelete) => {
        if (willDelete) {
          // history.push("order-translation/summary");
          window.location = "/account-client/order-translation/summary";
        }
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
      setSubmitLoader(false);
    }
  };

  const isAllow =
    !document_type ||
    (document_type.includes("Other") && !other_details) ||
    !source_language ||
    !target_language ||
    !target_expertise_level_required ||
    !source_expertise_level_required ||
    !require_visual_formating ||
    !certified_translation ||
    !additional_quality_check ||
    !agree ||
    !deadlineDate ||
    !deadlineTime ||
    !security_clearance ||
    !upload_file;

  const handleDateChange = (date, time) => {
    setDeadlineDate(date);
    setDeadlineTime(time);

    dispatch(
      changeTransInput({
        name: "deadline",
        value: `${date}T${time}`,
      })
    );
  };

  const handleChangeS = (e, value) => {
    dispatch(
      changeTransInput({
        name: "source_language",
        value,
      })
    );
  };

  const handleChangeT = (e, value) => {
    dispatch(
      changeTransInput({
        name: "target_language",
        value,
      })
    );
  };

  const handleChangeDT = (e, value) => {
    dispatch(
      changeTransInput({
        name: "document_type",
        value,
      })
    );
  };

  const handleChangeTI = (e, value) => {
    dispatch(
      changeTransInput({
        name: "timezone",
        value,
      })
    );
  };
  const handleSecurityChange = (e, value) => {
    dispatch(
      changeTransInput({
        name: "security_clearance",
        value,
      })
    );
  };

  return (
    <div>
      {/*'Developer allow "add more" option at the end of first order'*/}
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Your booking ref/ Purchase order no"
          name="purchase_order_number"
          value={purchase_order_number}
          placeholder="Enter your reference number or purchase order number"
        />
        <CustomAutoSelect
          required
          onChange={handleChangeDT}
          name="document_type"
          value={document_type}
          label="Document type"
          data={DOCUMENT_TYPE}
          width="100%"
        />
        {document_type?.includes("Other") ? (
          <CustomInputSingle
            onChange={handleChange}
            label="Other details"
            name="other_details"
            value={other_details}
            width="100%"
            required
          />
        ) : null}
        <CustomAutoSelect
          onChange={handleChangeS}
          label="Source/Native language"
          data={PRIMARY_SOURCE_LANG.filter(
            (value) => value !== target_language
          )}
          width="100%"
          name="source_language"
          value={source_language}
          required
        />

        {!matches ? (
          <CustomSelect
            onChange={handleChange}
            label="Please indicate linguist's source language skills level required"
            moreInfo="Please indicate linguist's source language skills level required for this assignment (1 - 5)"
            data={SKILL_LEVEL_REQUIRED.filter((value) =>
              handleExpertise(value)
            )}
            required
            width="100%"
            name="source_expertise_level_required"
            value={source_expertise_level_required}
          />
        ) : (
          <CustomSelect
            onChange={handleChange}
            label="Please indicate linguist's source language skills level required"
            moreInfo="Please indicate linguist's source language skills level required for this assignment (1 - 5)"
            data={SKILL_LEVEL_REQUIRED.filter((value) =>
              handleExpertise(value)
            )}
            required
            width="100%"
            name="source_expertise_level_required"
            value={source_expertise_level_required}
            style={{ marginTop: 50 }}
          />
        )}

        <CustomAutoSelect
          onChange={handleChangeT}
          label="Target language"
          data={PRIMARY_TARGET_LANG.filter(
            (value) => value !== source_language
          )}
          width="100%"
          name="target_language"
          value={target_language}
          required
        />
        {!matches ? (
          <CustomSelect
            required
            onChange={handleChange}
            label="Please indicate linguist's target language skills level required"
            moreInfo="Please indicate linguist's target language skills level required for this assignment (1 - 5). Target language skills level is an element for price calculation."
            data={SKILL_LEVEL_REQUIRED.filter((value) =>
              handleExpertise(value)
            )}
            width="100%"
            name="target_expertise_level_required"
            value={target_expertise_level_required}
          />
        ) : (
          <CustomSelect
            required
            onChange={handleChange}
            label="Please indicate linguist's target language skills level required"
            moreInfo="Please indicate linguist's target language skills level required for this assignment (1 - 5). Target language skills level is an element for price calculation."
            data={SKILL_LEVEL_REQUIRED.filter((value) =>
              handleExpertise(value)
            )}
            width="100%"
            name="target_expertise_level_required"
            value={target_expertise_level_required}
            style={{ marginTop: 50 }}
          />
        )}

        {/*  (*Developer make sure this charge can be changed on settings with other rates */}
        {!matches ? (
          <CustomSelect
            onChange={handleChange}
            label="Do this job require visual formatting?"
            moreInfo="document to look alike same as source document formatting boxes, graphs etc, set charge of £x* each A4 page"
            data={VIRTUAL_FORMATTING}
            width="100%"
            name="require_visual_formating"
            value={require_visual_formating}
            required
          />
        ) : (
          <CustomSelect
            onChange={handleChange}
            label="Do this job require visual formatting?"
            moreInfo="document to look alike same as source document formatting boxes, graphs etc, set charge of £x* each A4 page"
            data={VIRTUAL_FORMATTING}
            width="100%"
            name="require_visual_formating"
            value={require_visual_formating}
            required
            style={{ marginTop: 35 }}
          />
        )}

        {/* (*Developer make sure charge can be modified easily on settings) */}

        {!matches ? (
          <CustomSelect
            onChange={handleChange}
            label="Do you require this translation to be certified"
            moreInfo="By a qualified translator? If yes, please indicate which page(s) / document(s)? "
            data={TRANSLATION_CERTIFICATE}
            width="100%"
            name="certified_translation"
            value={certified_translation}
            required
          />
        ) : (
          <CustomSelect
            onChange={handleChange}
            label="Do you require this translation to be certified"
            moreInfo="By a qualified translator? If yes, please indicate which page(s) / document(s)? "
            data={TRANSLATION_CERTIFICATE}
            width="100%"
            name="certified_translation"
            value={certified_translation}
            required
            style={{ marginTop: 50 }}
          />
        )}

        {/* (*Developer make sure charge can be modified easily on settings) */}
        {certified_translation === "Yes" && (
          <CustomRadio
            onChange={handleChange}
            label="Would you like certificate to be posted or electronically?"
            moreInfo="If you have requested your translation to be certified"
            data={TRANSLATION_PRINTED}
            flexDir="column"
            width="100%"
            name="certificate"
            value={certificate}
          />
        )}
        {/* (*Developer make sure charge can be modified easily on settings)  */}

        {!matches ? (
          <CustomSelect
            onChange={handleChange}
            label="Would you require additional quality check?"
            moreInfo="Would you require additional quality assurance QA for this assignment / project? (a qualified second linguist will check the work of main translator and assure quality of translation, recommended for large volume of translation, not necessary for certified translation)"
            data={PRINT_QUALITY}
            width="100%"
            name="additional_quality_check"
            value={additional_quality_check}
            required
          />
        ) : (
          <CustomSelect
            onChange={handleChange}
            label="Would you require additional quality check?"
            moreInfo="Would you require additional quality assurance QA for this assignment / project? (a qualified second linguist will check the work of main translator and assure quality of translation, recommended for large volume of translation, not necessary for certified translation)"
            data={PRINT_QUALITY}
            width="100%"
            name="additional_quality_check"
            value={additional_quality_check}
            required
            style={{ marginTop: 50 }}
          />
        )}
        <br />
        <DropzoneArea
          acceptedFiles={[".jpg, .jpeg, .png, .pdf"]}
          onChange={handleFiles}
          showFileNames
          dropzoneText="Drag & Drop here or Click to add any script or instruction to this form - (PDF or photo / JPEG file only, Max 1 file, 35MB, contact us for help *"
          showAlerts={true}
          clearOnUnmount
          maxFileSize={35840000}
          showPreviewsInDropzone
          showFileNamesInPreview
          filesLimit={1}
        />
        {/* <div style={{ display: "flex", alignItems: "flex-end" }}>
          <CustomUpload
            onChange={handleFiles}
            accept=".pdf,.docx,.JPEG"
            name="upload_file"
            width="100%"
            label="Please upload your document(s) here (clear and readable scan, pdf or photos/JPEG only)"
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button color="secondary">
            {upload_file ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Icon name="file pdf" size="big" />
                <p
                  style={{
                    margin: "0",
                    marginTop: "7px",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  {upload_file.name}
                </p>
                <p>{upload_file?.lastModifiedDate?.toDateString()} </p>
                <p style={{ margin: "0" }}>
                  {(upload_file.size / 1024).toFixed(2)}Kb{" "}
                </p>
              </div>
            ) : null}
          </Button>
        </div> */}

        {/* (*Developer try to make this automated) */}
        <CustomBigInputSingle
          onChange={handleChange}
          name="count"
          width="100%"
          label="Estimated number words / word counts"
          moreInfo="(10k words or 20 A4 pages only. More than 10k or 20 pages please use quote)"
          required
          value={count}
          type={"number"}
          placeholder="Provide number of words in source language e.g. 10000"
        />
        {!matches ? (
          <CustomBigInputSingle
            onChange={handleChange}
            label="Preferred Linguist / Service provider ID number"
            width="100%"
            name="provider_id_number"
            moreInfo="LC will try its best, but cannot guarantee"
            value={provider_id_number}
            placeholder="Provide details of the preferred linguist e.g. LIN/PRO12345 - Jane Miller"
          />
        ) : (
          <CustomBigInputSingle
            onChange={handleChange}
            label="Preferred Linguist / Service provider ID number"
            width="100%"
            name="provider_id_number"
            moreInfo="LC will try its best, but cannot guarantee"
            value={provider_id_number}
            style={{ paddingTop: 20 }}
            placeholder="Provide details of the preferred linguist e.g. LIN/PRO12345 - Jane Miller"
          />
        )}

        <CustomInput
          onChange={handleChange}
          name="additional_note"
          value={additional_note}
          label="Additional note for translator"
          width="100%"
          placeholder="Provide any other information that you would like translator to have"
        />

        {!matches ? (
          <CustomAutoSelect
            onChange={handleSecurityChange}
            label="Please indicate security clearance level requirement"
            moreInfo="Please indicate security clearance level required for this assignment"
            data={SECURITY_CLEARANCE_LEVEL}
            width="100%"
            name="security_clearance"
            value={security_clearance}
            required
          />
        ) : (
          <CustomAutoSelect
            onChange={handleSecurityChange}
            label="Please indicate security clearance level requirement*"
            moreInfo="Please indicate security clearance level required for this assignment"
            data={SECURITY_CLEARANCE_LEVEL}
            width="100%"
            name="security_clearance"
            value={security_clearance}
            style={{ marginTop: 50 }}
            required
          />
        )}

        <CustomAutoSelect
          onChange={handleChangeTI}
          label="Select timezone"
          width="100%"
          data={TIMEZONE}
          name="timezone"
          value={timezone}
          //helperText= {moment.tz.guess()}
        />
        <div style={{ margin: 10 }}>
          <Header as="h5">Translation needed by *</Header>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <input
              style={{
                width: "90%",
                height: "2.8rem",
                fontSize: "1rem",
              }}
              min={moment().format("YYYY-MM-DD")}
              type="date"
              onChange={(e) => handleDateChange(e.target.value, deadlineTime)}
            />

            <input
              style={{
                width: "90%",
                height: "2.8rem",
                fontSize: "1rem",
              }}
              type="time"
              min={moment().format("YYYY-MM-DD")}
              onChange={(e) => handleDateChange(deadlineDate, e.target.value)}
            />
          </div>
        </div>
        {/* <CustomDateTime
          onChange={handleDateChange}
          width="100%"
          label="Translation needed by*"
          moreInfo="Please allow sufficient time for translation, LC linguist will try their best to complete on time"
          name="deadline"
          value={deadline}
          required
        /> */}
        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <p>
            <Checkbox
              onChange={handleChecked}
              checked={agree}
              name="agree"
              color="primary"
            />
            By submitting this form I agree with Linguists Collective{" "}
            <a href="https://linguistscollective.com/account-client/service-agreement-of-account-client">
              {" "}
              Service Agreement{" "}
            </a>
            ,
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/privacy-policy"
            >
              Privacy Policy{" "}
            </a>
            and{" "}
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/terms-and-conditions"
            >
              {" "}
              T&C
            </a>
          </p>
        </div>

        <div className={classes.buttonFlex}>
          <CustomButton
            text={submitLoader ? "Submitting" : "Submit"}
            className={`${classesb.button}`}
            disabled={isAllow || submitLoader}
            onClick={handleNext}
          >
            Submit
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default FormTwo;
