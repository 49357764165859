import React from "react";

import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    height: 200,
    width: "100%",
    marginBottom: "10px",

    "& .MuiDataGrid-root": {
      marginBottom: "10px",

      "& .MuiDataGrid-cell:nth-child(3)": {
        lineHeight: "normal !important",
        maxHeight: "auto !important",
        flexDirection: "column !important",
        paddingTop: "7px",
      },
    },
    "& .MuiDataGrid-colCellWrapper": {
      background: "rgba(0,0,0,0.1)",
    },
  },
});

const useCustomTableStyles = makeStyles(styles);

const CustomTable = ({ rows, columns }) => {
  const classes = useCustomTableStyles();

  return (
    <div className={classes.root}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        hideFooterRowCount
        autoPageSize
        hideFooterPagination
        hideFooter
      />
    </div>
  );
};

export default CustomTable;
