import React, { useState } from "react";
import Axios from "axios";
import { Checkbox, Dimmer, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { useParams } from "react-router-dom";

const PayLater = ({access}) => {
  const { id } = useParams();
  const token = Cookies.get("token");
  const [loader, setLoader] = useState(false);

  const accessPayLater = async (e) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/pay-later-access/cacID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data?.message}`,
        buttons: false,
      }).then(function () {
        window.location = `/admin/view-cash-client/${id}`;
      });
      setLoader(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  const disablePayLater = async (e) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/pay-later-disable/cacID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data?.message}`,
        buttons: false,
      }).then(function () {
        window.location = `/admin/view-cash-client/${id}`;
      });
      setLoader(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePayLater = async (value) => {
    if (!access) {
      swal("Are you sure you want to do this?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            accessPayLater(value);

          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to do this?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            disablePayLater(value);

          default:
            break;
        }
      });
    }
  };

  return (
    <>
      {loader && (
        <Dimmer active inverted>
          <Loader size="large">Changing Access</Loader>
        </Dimmer>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "100%",
        }}
      >
        {access ? (
          <Checkbox
            toggle
            checked={access}
            label="ON"
            onChange={() => handlePayLater("access")}
          />
        ) : (
          <Checkbox
            toggle
            checked={access}
            label="OFF"
            onChange={() => handlePayLater("access")}
          />
        )}
      </div>
    </>
  );
}
export default PayLater;
