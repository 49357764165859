import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import QuoteToClientList from "components/Employee/BookingPanel/DirectQuoteByLC/QuoteToClient";
import QuoteList from "components/Employee/BookingPanel/DirectQuoteByLC/AllQuoteList/QuoteList";
import { Button, Header } from "semantic-ui-react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";

const AssignmentPage = () => {
  const classes = useDashboardStyles();

  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>Direct Quote By LC</p>
                <div style={{ display: "flex" }}>
                  <Button
                    color="purple"
                    content="Create Direct Quotation"
                    onClick={() => history.push("/employee/create-quotation")}
                  />
                  <IconButton
                    color="inherit"
                    style={{ marginLeft: 5 }}
                    onClick={() => history.goBack()}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </div>
              </CardHeader>

              <CardBody>
                <Header as="h3">Client List</Header>
                <QuoteToClientList />
                <br />
                <Header as="h3">Quote List</Header>
                <QuoteList />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default AssignmentPage;
