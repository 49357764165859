import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import SearchSavedBill from "components/Admin/AccountFinance/InvoiceToClient/SaveBill/Search/SearchView";
import ClientList from "components/Admin/AccountFinance/InvoiceToClient/InvoiceToClient";
import FindClientBill from "components/Admin/AccountFinance/InvoiceToClient/FindClientBill/Search/SearchView";
import ClientBills from "components/Admin/AccountFinance/InvoiceToClient/FindClientBill/ClientBill/ClientBills";
import BillList from "components/Admin/AccountFinance/InvoiceToClient/FindBill/FindBill";
import { useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import history from "utils/history";
import { IconButton, useMediaQuery } from "@material-ui/core";

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const matchesSmall = useMediaQuery("(max-width:425px)");
  const [finance_panel, setAdminAccess] = useState(null);

  const { findBill, showBills } = useSelector((state) => state.billsReducer);
  const [savedInvoice, setSavedInvoice] = useState(false);

  const getLinguistDetails = async () => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminAccess(response?.data.finance_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {finance_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                {matchesSmall ? (
                  <CardHeader style={{ display: "block" }} color="primary">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className={classes.cardTitleWhite}>
                        Invoice to Client
                      </p>
                      <IconButton
                        color="inherit"
                        style={{ marginLeft: 5 }}
                        onClick={() => history.goBack()}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        onClick={() =>
                          history.push(
                            "/admin/invoice-to-client/create-invoice"
                          )
                        }
                        color="purple"
                      >
                        Raise invoice
                      </Button>
                    </div>
                  </CardHeader>
                ) : (
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Invoice to Client</p>
                    <div>
                      <Button
                        onClick={() =>
                          history.push(
                            "/admin/invoice-to-client/create-invoice"
                          )
                        }
                        color="purple"
                      >
                        Raise invoice
                      </Button>
                      <IconButton
                        color="inherit"
                        style={{ marginLeft: 5 }}
                        onClick={() => history.goBack()}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </div>
                  </CardHeader>
                )}

                <CardBody>
                  {!savedInvoice ? (
                    <div>
                      <ClientList />
                      <br />
                      <br />
                      {findBill && <FindClientBill />}
                      {showBills && <ClientBills />}
                      <BillList />
                    </div>
                  ) : (
                    <div>
                      <SearchSavedBill />
                    </div>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          ) : finance_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
