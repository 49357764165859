import { CHANGE_CLIENTACCOPENING_INPUT, CHANGE_MB_CURRENCY, UPDATE_CLIENTACCOPENING_STEP } from "../actions/actionTypes";

const INITIAL_STATE = {
  currency: 'GBP',
  count: 1,
  data: {
    account_type: '',
    other_details: '',
    business_name: '',
    house_street_no_name: "",
    address_line_two: "",
    town_city: "",
    post_code: "",
    county_region: "",
    country: "",
    business_website: '',
    primary_email: '',
    business_phone_no: '',
    company_registration_number: '',
    charity_registration_number: '',
    title: '',
    first_name: '',
    last_name: '',
    gender: '',
    position: '',
    nationality: '',
    other_nationalities: [],
    dob: "",
    contact_address: '',
    contact_house_street_no_name: '',
    contact_address_line_two: '',
    contact_town_city: '',
    contact_post_code: '',
    contact_county_region: '',
    contact_country: '',
    contact_phone: '',
    email_address: '',
    password: '',
    confirmation_password: '',
    preferred_date: '',
    anticipated_spending_per_month: '',
    estimated_booking_volume_per_month: '',
    anticipated_languages_required: [],
    anticipated_services_required: [],
    additional_info: '',
    guarantee_payment: '',
    pay_in_account: '',
    existing_account_with_localprovider: '',
    company: '',
    bank_reference_letter: '',
    pay_in_direct_debit: '',
    company_bank_details: '',
    currency: '',
    gdpr: false
  }
};

const caoReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_CLIENTACCOPENING_STEP:
      return {
        ...state,
        count: payload,
      };
    
    case CHANGE_CLIENTACCOPENING_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value
        }
      }

    case CHANGE_MB_CURRENCY: 
      return {
        ...state,
        currency: payload
      }

    default:
      return state;
  }
};

export default caoReducer;
