import React, { useState, useEffect } from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

import { Button } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LeadsList from "components/Employee/Leads/Leads";

const useStyles = makeStyles({
  Button: {
    width: "100%",
  },
});
const Leads = () => {
  const classes = useDashboardStyles();
  const buttonClass = useStyles();
  const matches = useMediaQuery("(max-width:768px)");
  const [empID, setEmployeeDetails] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("EMPtoken");
    const response = await Axios.get(
      "/api/employee/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setEmployeeDetails(response?.data);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              {!matches ? (
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>Leads</p>
                  <div>
                    <Button
                      onClick={() =>
                        window.open(`/add-new-lead/${empID?._id}`)
                      }
                      color="purple"
                    >
                      +Add New (Short Info) {" "}
                    </Button>
                    <Button
                      onClick={() =>
                        window.open(`/add-new-customer/${empID?._id}`)
                      }
                      color="purple"
                    >
                      +Add New (Full Info) {" "}
                    </Button>
                    <Button
                      onClick={() =>
                        history.push(`/employee/send-cla-registration-form`)
                      }
                      color="purple"
                    >
                      Send Registration Form{" "}
                    </Button>

                    <Button
                      onClick={() =>
                        window.open("/contact-removal-request", "_blank")
                      }
                      color="purple"
                    >
                      Removal Form
                    </Button>

                    <IconButton
                      onClick={() => history.goBack()}
                      color="inherit"
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                </CardHeader>
              ) : (
                <>
                  {" "}
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Leads</p>
                    <IconButton
                      onClick={() => history.goBack()}
                      color="inherit"
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </CardHeader>{" "}
                  <CardHeader color="primary">
                    {" "}
                    <div style={{ marginBottom: '15px' }}>
                      <Button
                        className={buttonClass.Button}
                        onClick={() =>
                          window.open(`/add-new-lead/${empID?._id}`)
                        }
                        color="purple"
                      >
                        +Add New (Short Info) {" "}
                      </Button>
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                      <Button
                        className={buttonClass.Button}
                        onClick={() =>
                          window.open(`/add-new-customer/${empID?._id}`)
                        }
                        color="purple"
                      >
                        +Add New (Full Info) {" "}
                      </Button>
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                      <Button
                        className={buttonClass.Button}
                        onClick={() =>
                          history.push(`/employee/send-cla-registration-form`)
                        }
                        color="purple"
                      >
                        Send Registration Form{" "}
                      </Button>
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                      <Button
                        className={buttonClass.Button}
                        onClick={() =>
                          window.open("/contact-removal-request", "_blank")
                        }
                        color="purple"
                      >
                        Removal Form
                      </Button>
                    </div>

                  </CardHeader>
                </>
              )}
              <CardBody>
                <LeadsList />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default Leads;
