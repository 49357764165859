import TikBox from "@material-ui/core/Checkbox";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Material imports
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "semantic-ui-react";

import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
// Icons
import { changeCrInput } from "redux/actions/crAction";

import { Header, Button, Modal, Form } from "semantic-ui-react";
import Action from "./Action";

function ProfMemberShip({ checkData }) {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.crReducer);
  const [values, setValues] = useState({ reload: false });
  const token = Cookies.get("LINtoken");
  const fileInput = useRef(null);
  const {
    institute_name,
    date_of_graduation,
    name_of_degree,
    subject_studied,
    country,
    grade,
  } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeCrInput(inputData));
  };

  const [state, setState] = useState({
    checkedA: false,
    checkedB: false,
    checkedF: false,
    checkedG: false,
    checkedH: false,
    other: false,
    added: false,
  });

  const handleSave = async (e) => {
    let formData = new FormData();
    formData.append("institute_name", institute_name || "-");
    formData.append("date_of_graduation", date_of_graduation || "-");
    formData.append("name_of_degree", name_of_degree || "-");
    formData.append("subject_studied", subject_studied || "-");
    formData.append("country", country || "-");
    formData.append("grade", grade || "-");

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-public-profile-educational-qualification`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `Successfully added`,
        icon: "success",
        buttons: "Ok",
      }).then(function () {
        window.location = "manage-public-profile";
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/educational-qualification-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  const [otherModal, setOtherModal] = useState(false);

  const handleOther = (e) => {
    setOtherModal(true);
  };

  const handleClose = (e) => {
    setOtherModal(false);
  };

  const updateQualification = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/allow-public-profile-qualification-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const hideQualification = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/hide-public-profile-qualification-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePublicProfile = (value) => {
    if (checkData.verified) {
      if (!checkData.qualification_section) {
        swal(
          "Qualification section of your profile will be public. Are you sure you want to make this section of your profile public?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              updateQualification(value);

            default:
              break;
          }
        });
      } else {
        swal(
          "Are you sure you want to revoke public view permission for this section?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              hideQualification(value);

            default:
              break;
          }
        });
      }
    }
  };

  return (
    <div>
      <Modal size="mini" onClose={handleClose} open={otherModal}>
        <Modal.Header>
          Adding Education / Professional Qualification Details
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Field>
                <label>Date of Graduation / Training</label>
                <input
                  name="date_of_graduation"
                  onChange={handleChange}
                  value={date_of_graduation}
                  placeholder="e.g. 01/Jan/2021"
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Degree / Certificate / CPD Title</label>
                <input
                  name="name_of_degree"
                  onChange={handleChange}
                  value={name_of_degree}
                  placeholder="Degree/Certificate/Diploma/CPD Name"
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Institution Name</label>
                <input
                  name="institute_name"
                  onChange={handleChange}
                  value={institute_name}
                  placeholder="Institution/University Name"
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Town, Country</label>
                <input
                  name="country"
                  onChange={handleChange}
                  value={country}
                  placeholder="Town/City, Country"
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Subjects / Modules</label>
                <input
                  name="subject_studied"
                  onChange={handleChange}
                  value={subject_studied}
                  placeholder="Subject/Module Names"
                />
              </Form.Field>

              <Form.Field>
                <label>Achievements / Grade</label>
                <input
                  name="grade"
                  onChange={handleChange}
                  value={grade}
                  placeholder="Achievement/Grade/Result"
                />
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            content="Add"
            labelPosition="right"
            icon="checkmark"
            onClick={handleSave}
            disabled={
              !institute_name ||
              !date_of_graduation ||
              !name_of_degree ||
              !country
            }
            positive
          />
        </Modal.Actions>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header color="purple" as="h2">
          Education / Professional Qualifications
        </Header>
        <Checkbox
          toggle
          checked={checkData?.qualification_section}
          onChange={() => handlePublicProfile("qualification_section")}
        />
      </div>{" "}
      <br />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          marginBottom: "20px",
        }}
      >
        {values?.length > 0 ? (
          <>
            {values?.map((list) => (
              <>
                {" "}
                {list.institute_name ? (
                  <div
                    style={{
                      display: "block",
                      margin: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Header as="h5">
                      {list?.date_of_graduation}
                      <br /> {list?.name_of_degree}
                      <br /> {list?.country}
                      <br /> {list?.subject_studied}
                      <br /> {list?.grade}
                      <br />
                      {checkData?.professional_qualification}
                    </Header>
                    <div>
                      <Action id={list?._id} load={loadList} />
                    </div>
                  </div>
                ) : null}
              </>
            ))}{" "}
          </>
        ) : null}
      </div>
      <FormControlLabel
        control={
          <TikBox
            onChange={handleOther}
            name="other"
            checked={state.other}
            color="primary"
          />
        }
        label="Add Education / Professional Qualification"
      />
    </div>
  );
}

export default ProfMemberShip;
