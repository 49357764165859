import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LeadsList from "components/Admin/CrmPanel/Leads/Leads";

const useStyles = makeStyles({
  Button: {
    width: "100%",
  },
});
const Leads = () => {
  const classes = useDashboardStyles();
  const buttonClass = useStyles();
  const matches = useMediaQuery("(max-width:768px)");
  const [booking_panel, setAdminAccess] = useState(null);
  const [adminID, setAdminID] = useState(null);
  const getLinguistDetails = async () => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAdminAccess(response?.data.booking_panel);
    setAdminID(response?.data._id);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {booking_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                {!matches ? (
                  <CardHeader
                    style={{ display: "flex", justifyContent: "space-between" }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Leads</p>
                    <div>
                      <Button
                        onClick={() =>
                          window.open(`/add-new-lead/${adminID}`, "_blank")
                        }
                        color="purple"
                      >
                        Lead by admin (Short Info)
                      </Button>
                      <Button
                        onClick={() => history.push("/admin/add-new-lead")}
                        color="purple"
                      >
                        Lead by admin (Full Info)
                      </Button>
                      <Button
                        onClick={() =>
                          history.push(`/admin/send-cla-registration-form`)
                        }
                        color="purple"
                      >
                        Send Lead Registration Form{" "}
                      </Button>

                      <Button
                        onClick={() =>
                          window.open("/contact-removal-request", "_blank")
                        }
                        color="purple"
                      >
                        Removal Form
                      </Button>

                      <IconButton
                        onClick={() => history.goBack()}
                        color="inherit"
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </div>
                  </CardHeader>
                ) : (
                  <>
                    {" "}
                    <CardHeader
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      color="primary"
                    >
                      <p className={classes.cardTitleWhite}>Leads</p>
                      <IconButton
                        onClick={() => history.goBack()}
                        color="inherit"
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </CardHeader>{" "}
                    <CardHeader color="primary">
                      {" "}
                      <div style={{ marginBottom: '15px' }}>
                        <Button
                          className={buttonClass.Button}
                          onClick={() =>
                            window.open(`/add-new-lead/${adminID}`, "_blank")
                          }
                          color="purple"
                        >
                          Lead by admin (Short Info)
                        </Button>
                      </div>
                      <div style={{ marginBottom: "15px" }}>
                        <Button
                          className={buttonClass.Button}
                          onClick={() => window.open("/admin/add-new-lead")}
                          color="purple"
                        >
                          Lead by admin (Full Info)
                        </Button>
                      </div>
                      <div style={{ marginBottom: "15px" }}>
                        <Button
                          className={buttonClass.Button}
                          onClick={() =>
                            history.push(`/admin/send-cla-registration-form`)
                          }
                          color="purple"
                        >
                          Send Lead Registration Form{" "}
                        </Button>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Button
                          className={buttonClass.Button}
                          onClick={() =>
                            window.open("/contact-removal-request", "_blank")
                          }
                          color="purple"
                        >
                          Removal Form
                        </Button>
                      </div>
                    </CardHeader>
                  </>
                )}

                <CardBody>
                  <LeadsList />
                </CardBody>
              </Card>
            </GridItem>
          ) : booking_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default Leads;
