import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

//Semantic UI imorts
import { Table } from "semantic-ui-react";
import moment from "moment";
import Axios from "axios";

//Sweet alert
import swal from "sweetalert";

//Cookies import
import Cookies from "js-cookie";

//ActionINCTrans
import ActionINCTrans from "./ActionINCTrans";
//search
import SearchView from "../TranslationSearch/SearchView";
import { useToasts } from "react-toast-notifications";
import { Paper, TableContainer } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";

const TransTableThree = ({ translationData }) => {
  const classes = useDashboardStyles();
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    translationList: null,
    removed: false,
    reload: false,
    cacAndincAccess: null,
  });

  const {
    name,
    error,
    success,
    translationList,
    cacAndincAccess,
    removed,
    reload,
  } = values;

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-inhouse-current-Translation/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({
          ...values,
          translationList: [response?.data],
          reload: false,
        });
        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.translationList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  const loadtranslationList = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-current-Translation-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({
        ...values,
        translationList: translationData
          ? response?.data.filter((item) => item.id === translationData)
          : response?.data,
        reload: false,
      });
      translationData && setShow(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadtranslationList();
  }, [reload]);

  const handleAccept = async (id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-Translation-accept/TranslationID/${id}`,
        { email: email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setValues({ ...values, reload: true });
    } catch (error) {
      console.log(error.response);
    }
  };

  const decline = async (decline, id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-Translation-reject/TranslationID/${id}`,
        { email: email, declines: decline },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setValues({ ...values, reload: true });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDecline = async (e, id, email) => {
    const declines = e.target.value;
    swal(`Are you sure you want to decline this job?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      // .then(function() {
      //   window.location = "/admin/unverified-linguists";
      // })
      .then((value) => {
        switch (value) {
          case "cancel":
            break;

          case "catch":
            decline(declines, id, email);

          default:
            break;
        }
      });
  };

  return (
    <div>
      {!translationData && (
        <SearchView
          show={show}
          load={loadtranslationList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          SearchText={SearchText}
          setShow={setShow}
        />
      )}
      <Paper>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table color="pink" aria-label="sticky table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="1">Assignment ID</Table.HeaderCell>
                    <Table.HeaderCell width="1">Document Type</Table.HeaderCell>
                    <Table.HeaderCell width="1">Timezone</Table.HeaderCell>
                    <Table.HeaderCell width="1">Deadline</Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Source language
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Target language
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Service Required
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Security Level
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Estimate Amount
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Payment Status
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {translationList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((list) => (
                      <Table.Row role="checkbox" tabIndex={-1} key={list._id}>
                        <Table.Cell key={list._id} align={list.align}>
                          <Link
                            to={`/linguist/inc-view-translation-assignment/${list._id}`}
                          >
                            {list?.id}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{list.document_type}</Table.Cell>
                        <Table.Cell>{list.timezone}</Table.Cell>
                        <Table.Cell>
                          {moment(list.deadline).format(
                            "DD-MMM-YYYY : hh:mm:ss"
                          )}
                        </Table.Cell>
                        <Table.Cell>{list.source_language}</Table.Cell>
                        <Table.Cell>{list.target_language}</Table.Cell>
                        <Table.Cell>
                          {list.target_language_skill_level}
                        </Table.Cell>
                        <Table.Cell>{list.security_clearance}</Table.Cell>
                        <Table.Cell>
                          {list?.currency_new?.substring(0, 3)} {list.estimate}
                        </Table.Cell>
                        <Table.Cell>{list.status}</Table.Cell>
                        <Table.Cell>
                          <ActionINCTrans client={list.id} id={list._id} />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>{" "}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={translationList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
};

export default TransTableThree;
