import React, { Fragment, useState, useEffect } from "react";

// Material Core Components
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Badge from "@material-ui/core/Badge";
import axios from "axios";
import Cookies from "js-cookie";
import { Button, Header } from "semantic-ui-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { Beenhere } from "@material-ui/icons";
import { Brightness1 } from "@material-ui/icons";

// Custom Components
import NotificationAction from "../NotificationAction";
import history from "utils/history";
// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";
import useDropdownStyles from "assets/jss/components/dropDownStyles";
import { NotificationActionDiv } from "../style";

// Icons
import IconImg from "components/Sidebar/IconImg";
import NDicon from "assets/icons/notifD.png";

const NavNotification = () => {
  const classes = useNavbarStyles();
  const token = Cookies.get("LINtoken");
  const classesTwo = useDropdownStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [readloading, setreadLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [count, setCount] = useState(0);
  const [values, setValues] = useState();
  const [countUnread, setCountUnread] = useState(0);
  const [markopen, setmarkOpen] = useState(false);
  const [read, setRead] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const handleRead = async (id, type) => {
    setreadLoading(true);
    const token = Cookies.get("LINtoken");
    await axios.put(
      `${process.env.REACT_APP_BACKEND_API}/linguist/read-mark-single/notificationID/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    setreadLoading(false);
    handleCount();
  };

  const handleReadAll = async (id, type) => {
    setreadLoading(true);
    const token = Cookies.get("LINtoken");
    let i = 0;
    while (i !== count) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguist/read-mark/notificationID/${values[i]?._id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );
      i++;
      setreadLoading(false);
    }

    handleCount();
  };

  const handleCount = async () => {
    setFetching(true);
    const token = Cookies.get("LINtoken");
    const assignmentNotification = axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguist/get-all-offer-notification`,
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    const otherNotification = axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguist/get-all-other-notification`,
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    await axios.all([assignmentNotification, otherNotification]).then(
      axios.spread(function (res1, res2) {
        setCount(res1?.data.length + res2.data.length);
        setValues(res1?.data.concat(res2?.data));
        const temp = res1?.data.concat(res2?.data);
        let tempArr = [];
        temp.forEach((item) => {
          if (item?.isRead !== []) {
            item?.isRead.forEach((entry) => {
              entry === Cookies.get("linID") && tempArr.push(item.id);
            });
          }
        });
        setRead(tempArr);
      })
    );

    setFetching(false);
  };

  useEffect(() => {
    handleCount();
    loadUnreadList();
    // const timer = setInterval(handleCount, 30000);
    // return () => clearInterval(timer);
  }, [open, refresh]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const loadUnreadList = async () => {
    const token = Cookies.get("LINtoken");
    setFetching(true);
    const assignmentNotification = axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguist/get-all-unread-offer-notification`,
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    const otherNotification = axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguist/get-all-other-unread-notification`,
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    await axios.all([assignmentNotification, otherNotification]).then(
      axios.spread(function (res1, res2) {
        setCountUnread(res1?.data.length + res2.data.length);
      })
    );

    setFetching(false);
  };

  return (
    <Fragment>
      <IconButton
        aria-label="show 0 new notifications"
        color="primary"
        onClick={handleClick}
      >
        <div className={classes.imgDiv}>
          {countUnread > 0 ? (
            <Badge badgeContent={countUnread} color="error">
              <IconImg className={classes.icons} icon={NDicon} />
            </Badge>
          ) : (
            <IconImg className={classes.icons} icon={NDicon} />
          )}
        </div>
      </IconButton>

      <div>
        <Popper
          open={Boolean(open)}
          anchorEl={anchorEl}
          placement="bottom-end"
          className={classesTwo.toolTip}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <div>
                    <NotificationActionDiv>
                      <h3>Notifications</h3>
                      <NotificationAction
                        values={values}
                        handleReadAll={handleReadAll}
                        setRefresh={setRefresh}
                      />
                    </NotificationActionDiv>

                    <MenuList role="menu">
                      {values?.length > 0 ? (
                        values?.map(
                          (list, index) =>
                            index < 10 && (
                              <MenuItem
                                className={classesTwo.dropdownItem}
                                key={index}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{ display: "block" }}
                                    onClick={() =>
                                      history.push(
                                        "/linguist/view-all-notifications"
                                      )
                                    }
                                  >
                                    <div style={{ display: "flex" }}>
                                      {!list?.lin_read &&
                                        moment().diff(
                                          moment(list?.createdAt),
                                          "days"
                                        ) <= 0 && (
                                          <Brightness1
                                            color="primary"
                                            fontSize="sm"
                                            style={{ marginRight: 5 }}
                                          />
                                        )}
                                      {list?.notification}
                                    </div>
                                    <Typography variant="subtitle2">
                                      {moment(list?.createdAt).fromNow()}
                                    </Typography>
                                  </div>
                                  {!list?.lin_read ? (
                                    <MenuItem
                                      style={{
                                        backgroundColor: "#e0e1e2",
                                        color: "black",
                                        borderRadius: "50pt",
                                        marginLeft: 20,
                                      }}
                                      onClick={() =>
                                        handleRead(list._id, list.id)
                                      }
                                    >
                                      {" "}
                                      {readloading ? (
                                        <CircularProgress size={20} />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      style={{
                                        backgroundColor: "green",
                                        borderRadius: "50pt",
                                        marginLeft: 20,
                                      }}
                                    >
                                      {" "}
                                      <Beenhere style={{ color: "white" }} />
                                    </MenuItem>
                                  )}
                                </div>
                              </MenuItem>
                            )
                        )
                      ) : (
                        <Header as="h3" textAlign="center">
                          nothing to show
                        </Header>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          color="purple"
                          loading={fetching}
                          disabled={fetching}
                          onClick={() =>
                            history.push("/linguist/view-all-notifications")
                          }
                          style={{ width: "90%", margin: 10 }}
                        >
                          View all notifications
                        </Button>
                      </div>
                    </MenuList>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
      {/* ) : null} */}
    </Fragment>
  );
};

export default NavNotification;
