/*global google*/
import React, { useEffect } from "react";

const GoogleMap = ({
  origin,
  destination,
  setDistance,
  setDuration,
  reverse,
  lat,
  lng,
  setAddress,
}) => {
  const onScriptLoad = () => {
    const map = new window.google.maps.Map(document.getElementById("idmap"), {
      disableDefaultUI: true,
      draggable: false,
      zoomControl: true,
      draggable: true,
    });
    //create a DirectionsService object to use the route method and get a result for our request
    const directionsService = new window.google.maps.DirectionsService();

    //create a DirectionsRenderer object which we will use to display the route
    const directionsDisplay = new window.google.maps.DirectionsRenderer();
    //bind the DirectionsRenderer to the map
    directionsDisplay.setMap(map);
    const geocoder = new google.maps.Geocoder();
    // setMap(map);
    if (reverse === true) {
      geocodeLatLng(geocoder);
    } else {
      calcRoute(directionsService, directionsDisplay);
    }
  };

  const mapLoad = () => {
    const API_KEY = process.env.REACT_APP_MAPS_API_KEY;
    if (!window.google) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        `https://maps.googleapis.com/maps/api/js?key=` +
        API_KEY +
        `&libraries=places,geometry`;
      script.id = "googleMaps";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.addEventListener("load", (e) => {
        onScriptLoad();
      });
    } else {
      onScriptLoad();
    }
  };

  useEffect(() => {
    mapLoad();
  }, [reverse]);

  const calcRoute = (directionsService, directionsDisplay) => {
    //create request
    let request = {
      origin: origin,
      destination: destination,
      travelMode: window.google.maps.TravelMode.DRIVING, //WALKING, BYCYCLING, TRANSIT
      unitSystem: window.google.maps.UnitSystem.IMPERIAL,
    };

    //pass the request to the route method
    directionsService.route(request, function (result, status) {
      if (status == window.google.maps.DirectionsStatus.OK) {
        //Get distance and time
        setDuration(result.routes[0].legs[0].duration.text);
        setDistance(result.routes[0].legs[0].distance.text);
        //display route
        directionsDisplay.setDirections(result);
      } else {
        //delete route from map
        directionsDisplay.setDirections({ routes: [] });
      }
    });
  };

  function geocodeLatLng(geocoder) {
    const latlng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };
    geocoder
      .geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          setAddress(response.results[0].formatted_address);
        } else {
          console.log("No results found");
        }
      })
      .catch((e) => console.log("Geocoder failed due to: " + e));
  }

  return (
    <>
      {reverse ? (
        <div id="idmap"></div>
      ) : (
        <div id="idmap" style={{ width: "100%", height: "300px" }}></div>
      )}
    </>
  );
};
export default GoogleMap;
