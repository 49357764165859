import React, { useState, useEffect } from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Button } from "semantic-ui-react";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Accordion, Icon, Header } from "semantic-ui-react";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { Fragment } from "react";

const Help = () => {
  const classes = useDashboardStyles();
  const token = Cookies.get("CACtoken");
  const [activeIndex, setActiveIndex] = useState("0");
  const matches = useMediaQuery("(max-width:768px)");
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const [values, setValues] = useState([]);

  const [data, setData] = useState({
    question: "",
  });

  const { question } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const [toturialList, setToturialList] = useState([]);
  const loadToturialsList = async () => {
    try {
      const response = await Axios.get("/api/CAC/get-tutorial");
      setToturialList(response?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadToturialsList();
    getAnswer();
  }, []);

  const getAnswer = async () => {
    try {
      const response = await Axios.get("/api/manager-admin/cac-question");
      console.log(response);
      setValues(response?.data);
    } catch (error) {
      // Copy from other catch's code
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    try {
      const response = Axios.post("/api/cashclient/ask-question", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);

      swal({
        // title: "Success",
        text: `Question sent to the Booking Secretary / Admin Team.`,
        icon: "success",
        buttons: false,
      });
      setData({ ...data, question: "" });
    } catch (error) {
      // Copy from other catch's code
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <CashClientLayout>
      <Cashclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Box pb={2}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>CAC Tutorials</p>
                </CardHeader>
                <CardBody>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: matches ? "auto" : "30% 30% 30%",
                      justifyContent: matches ? null : "space-evenly",
                      alignItems: "end",
                    }}
                  >
                    {toturialList.map((list) => (
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <Header as="h4" textAlign="center">
                          {list.tutorial_name}
                        </Header>
                        <Button
                          onClick={() => window.open(list.tutorial_link)}
                          fluid
                          color="youtube"
                        >
                          <Icon name="youtube" /> Watch on youtube
                        </Button>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Box>

            <Box pb={2}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>FAQs</p>
                </CardHeader>
                <CardBody>
                  <Accordion fluid styled>
                    <Accordion.Title
                      active={activeIndex === 0}
                      index={0}
                      onClick={handleClick}
                    >
                      <Icon name="dropdown" />
                      What is CAC?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                      <p>
                        CAC refers to a Cash Account Client. We call them Cash
                        Clients as they have to pay the estimated sum in advance
                        in order to book an interpreter, order translation
                        online or confirm a free quotation. However, CAC can
                        request for 'Pay Later' option to be activated,
                        particularly for UK Legal Aid jobs.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 1}
                      index={1}
                      onClick={handleClick}
                    >
                      <Icon name="dropdown" />
                      How do i determine what language to use for a
                      certain/specific interpreting?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                      <p>
                        You will need to speak to your non-native speaker and
                        identify which languages are needed using our list of
                        languages with the relevant country name and origin.
                        Here we have produced a list of languages and origin or
                        the country they are found, you can show this to the
                        non-native speaker for identifying correct mother
                        tongue.
                        <object
                          data="/list-of-languages-web.pdf"
                          type="application/pdf"
                          width="100%"
                          height="1000px"
                          aria-label="document"
                        ></object>
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 2}
                      index={2}
                      onClick={handleClick}
                    >
                      <Icon name="dropdown" />
                      What type of computer systems and software does LC
                      support?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                      <p>
                        All, but we recommend using larger than 8” screen for
                        the best quality of experience of using LC platform.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 3}
                      index={3}
                      onClick={handleClick}
                    >
                      <Icon name="dropdown" />
                      What payments methods are available?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 3}>
                      <p>
                        Card (with refund option, if needed) for all, bank
                        transfer and direct debit for Billing Account Clients
                        only (no refund available).
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 4}
                      index={4}
                      onClick={handleClick}
                    >
                      <Icon name="dropdown" />
                      How do I book an interpreter/order translation online?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 4}>
                      <p>
                        On our website, at the top we have an option to click
                        ‘Book, online and order’. That will take you to the
                        relevant page to help you book an interpreter or order a
                        translation online.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 5}
                      index={5}
                      onClick={handleClick}
                    >
                      <Icon name="dropdown" />
                      Who is who and what expertise do they have?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 5}>
                      <p>
                        Know your linguist’s title, qualification, experience
                        and their relevant skills.
                        <br />
                        You can select the best suitable linguist needed for
                        your assignment.
                      </p>
                      <object
                        data="/WhoIsWho.pdf"
                        type="application/pdf"
                        width="100%"
                        height="1000px"
                        aria-label="document"
                      ></object>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 6}
                      index={6}
                      onClick={handleClick}
                    >
                      <Icon name="dropdown" />
                      Manual Timesheet
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 6}>
                      <object
                        data="/Linguists Collective time sheet.pdf"
                        type="application/pdf"
                        width="100%"
                        height="1000px"
                        aria-label="document"
                      ></object>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 7}
                      index={7}
                      onClick={handleClick}
                    >
                      <Icon name="dropdown" />
                      Manual Project Report
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 7}>
                      <object
                        data="/Linguists Collective project report.pdf"
                        type="application/pdf"
                        width="100%"
                        height="1000px"
                        aria-label="document"
                      ></object>
                    </Accordion.Content>
                  </Accordion>
                </CardBody>
              </Card>
            </Box>

            <Box pb={2}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>Have a question?</p>
                </CardHeader>
                <CardBody>
                  <div>
                    <TextareaAutosize
                      onChange={handleChange}
                      name="question"
                      style={{
                        width: 100 + "%",
                        margin: 10,
                        marginLeft: 0,
                        padding: 10,
                      }}
                      value={data.question}
                      rowsMin={5}
                      placeholder="Type your question here... Please note that your question and answer from Admin Team will be visible to all LC Linguists. Please do not share any confidential, personal, client or assignment details here."
                    />
                    <Button
                      color="purple"
                      disabled={!question}
                      onClick={handleSubmit}
                    >
                      Send
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Box>

            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>
                  Answers from Booking Secretary / Admin Team
                </p>
              </CardHeader>
              <CardBody>
                <Accordion>
                  {values.map((list) => (
                    <Fragment>
                      {list.question && list.answer ? (
                        <Fragment>
                          <Accordion.Title
                            active={activeIndex === list._id}
                            index={list._id}
                            onClick={handleClick}
                          >
                            <Icon name="dropdown" />
                            {list.question}
                          </Accordion.Title>
                          <Accordion.Content active={activeIndex === list._id}>
                            {list.answer}
                          </Accordion.Content>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ))}
                </Accordion>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default Help;
