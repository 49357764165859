import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import { Dimmer, Header, Loader } from "semantic-ui-react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Booking from "components/AccountClient/AccountClientDasboard/JobLists/Interpreting/TableOne";
import Translation from "components/AccountClient/AccountClientDasboard/JobLists/Translation/TableTwo";
import Quotation from "components/AccountClient/AccountClientDasboard/JobLists/Quotation/TableThree";

// Fourth Segment
import NewsAndNotice from "./NewsAndNotice";
import WelcomeMessage from "./WelcomeMessage";

import EventsCard from "components/Admin/ManagementPanel/EventsForm/BACEventsCard";
import MyNoteForm from "components/AccountClient/AccountClientDasboard/MyNote/MyNoteForm";
import ColleagueForm from "components/AccountClient/AccountClientDasboard/MyNote/ColleagueForm";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const AccountClientDashboard = () => {
  const matches = useMediaQuery("(max-width:768px)");
  const [interpreting_access, setInterpretingAccess] = useState(null);
  const [translation_access, setTranslationccess] = useState(null);
  const [quotation_access, setQuotationAccess] = useState(null);
  const [bacUser, setbacUser] = useState(null);

  const getDetails = async (e) => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      "/api/accountclient/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setInterpretingAccess(response?.data.interpreting_access);
    setTranslationccess(response?.data.translation_access);
    setQuotationAccess(response?.data.quotation_access);
    setbacUser(response?.data.bacUser);
  };

  useEffect(() => {
    getDetails();
  });

  return (
    <GridContainer>
      {interpreting_access ? (
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header as="h3">Interpreting Assignments</Header>

          <Booking />
          <br />
        </GridItem>
      ) : bacUser === false ? (
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header as="h3">Interpreting Assignments</Header>

          <Booking />
          <br />
        </GridItem>
      ) : interpreting_access === false ? (
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header as="h3">
            {" "}
            Interpreting Assignments View Denied. No permission. Contact Admin
            User.
          </Header>
          <br />
        </GridItem>
      ) : (
        <Dimmer active inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
      )}

      {translation_access ? (
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header as="h3">Translation Assignments</Header>
          <Translation />
          <br />
        </GridItem>
      ) : bacUser === false ? (
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header as="h3">Translation Assignments</Header>
          <Translation />
          <br />
        </GridItem>
      ) : translation_access === false ? (
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header as="h3">
            {" "}
            Translation Order View Denied. No permission. Contact Admin User.
          </Header>
          <br />
        </GridItem>
      ) : (
        <Dimmer active inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
      )}

      {quotation_access ? (
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header as="h3">Quotations</Header>

          <Quotation />
          <br />
          <br />
          <br />
        </GridItem>
      ) : bacUser === false ? (
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header as="h3">Quotations</Header>

          <Quotation />
          <br />
          <br />
          <br />
        </GridItem>
      ) : quotation_access === false ? (
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header as="h3">
            {" "}
            Requested Quotes View Denied. No permission. Contact Admin User.
          </Header>
        </GridItem>
      ) : (
        <Dimmer active inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
      )}


      {matches ? (
        <>
          {" "}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto",
              width: "100%",
            }}
          >
            <EventsCard />

            <ColleagueForm />
            <MyNoteForm />
            <NewsAndNotice />
          </div>
        </>
      ) : (
        <>
          <GridItem xs={12} sm={12} md={12} lg={4} xl={4}>
            <EventsCard />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4} xl={4}>
            <ColleagueForm />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4} xl={4}>
            <MyNoteForm />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
            <NewsAndNotice />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
            <WelcomeMessage />
          </GridItem>
        </>
      )}
    </GridContainer>
  );
};

export default AccountClientDashboard;
