const {
  GET_CURRENCY_RATES,
  SELECTED_CURRENCY,
  SET_CURRENCY,
} = require("redux/actions/actionTypes");

const INITIAL_STATE = {
  rates: null,
  selectedCurrency: "GBP",
  setCurrencyValue: "£",
};

const getDataReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CURRENCY_RATES:
      return {
        ...state,
        rates: payload,
      };
    case SET_CURRENCY:
      return {
        ...state,
        setCurrencyValue: payload,
      };
    case SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default getDataReducer;
