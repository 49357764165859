import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";

const Action = ({ id, cacProject_report, linSignature, accepted, date, dispute }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          minWidth: "10px",
          paddingLeft: "0px",
        }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            history.push(`/linguist/view-close-and-project-report/${id}`)
          }
        >
          View Project Report
        </MenuItem>
        {!cacProject_report && linSignature && (
          <MenuItem
            onClick={() =>
              history.push(`/linguist/amend-close-and-project-report/${id}`)
            }
          >
            Amend Project Report
          </MenuItem>
        )}
        {cacProject_report && !linSignature ? (
          <MenuItem
            onClick={() =>
              history.push(`/linguist/confirm-cac-project-report/${id}`)
            }
          >
            Confirm Project Report
          </MenuItem>
        ) : null}
        {
          (accepted || date) && linSignature && !dispute &&(
            <MenuItem
              onClick={() => history.push(`cac/translation/invoice/${id}`)}
            >
              Create Invoice
            </MenuItem>
          ) // if accepted (signatureByCAC) is true then invoice action is comming here
        }
      </Menu>
    </div>
  );
};

export default Action;
