import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomDate from "components/Common/CustomDate";
import CustomUpload from "components/Common/CustomUpload";
import CustomSelect from "components/Common/CustomSelect";
import CustomRadio from "components/Common/CustomRadio";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import { CR_RECORD, YES_NO, LIST_SEC_CLEAR } from "data";
import { autoSaveER, changeErInput, updateErStep } from "redux/actions/erAction";
import CustomInput from "components/Common/CustomInput";
import CustomAutoSelect from "components/Common/CustomAutoSelect";

const FormSeven = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.erReducer);
  const classes = useFormStyles();

  const {
    criminal_record,
    criminal_record_details,
    security_clearance,
    title_security_clearance,
    security_clearance_document,
    security_certificate_expiry_date,
  }= data

  const isAllow= 
    !criminal_record ||
    (criminal_record.includes('Yes') && !criminal_record_details) ||
    !security_clearance

  const handleNext = () => {
    dispatch(updateErStep(8));
  };

  const handleBack = () => {
    dispatch(updateErStep(6));
  };

  useEffect(() => {
    const temp= JSON.parse(localStorage.getItem('userER'))
    dispatch(autoSaveER({
      temp
    }))
  }, [dispatch])

  useEffect(() => {
    localStorage.setItem('userER', JSON.stringify(data))
  }, [data])

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(changeErInput({ name, value }));
  };

  const handleDateChange = date => {
    dispatch(changeErInput({
        name: 'security_certificate_expiry_date',
        value: date
    }))
  }

  const handleChangeSC = (e, value) => {
    dispatch(changeErInput({
      name: 'title_security_clearance',
      value
    }))
  }

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomSelect
          onChange= {handleChange}
          label="Do you have any criminal record? "
          data={CR_RECORD}
          width="100%"
          value= {criminal_record}
          name= 'criminal_record'
          required
        />
        {criminal_record?.includes('Yes') ? (
        <CustomInput
          onChange= {handleChange}
          label= 'Other details'
          name= 'criminal_record_details'
          value= {criminal_record_details}
          width= '100%'
          required
        />)
        :
        null}
        <CustomRadio
          onChange= {handleChange}
          required
          label="Do you have security / police clearance? "
          data={YES_NO}
          width="100%"
          name= 'security_clearance'
          value= {security_clearance}
        />
        <CustomAutoSelect
          onChange= {handleChangeSC}
          width="100%"
          label="List of your security clearance(s)"
          moreInfo= 'LC will verify these according to the information provided here'
          name= 'title_security_clearance'
          data= {LIST_SEC_CLEAR}
          value={title_security_clearance}
        />
        {title_security_clearance?.includes('Other') ? (
        <CustomInput
          label= 'Other details'
          width= '100%'
        />)
        :
        null}
        <CustomUpload
          required
          label="Upload copy of security clearance / give details"
          width="100%"
          value= {security_clearance_document}
          name= 'security_clearance_document'
        />

        {/* "Developer - add option for sending notification 12 week before the expiry date" */}
        <CustomDate
         onChange= {handleDateChange}
         label="Security certificate expiry date"
         width="100%" value= {security_certificate_expiry_date} 
         name= 'security_certificate_expiry_date' 
         value= {security_certificate_expiry_date}
        />
        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton text="Next" disabled= {isAllow} onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default FormSeven;
