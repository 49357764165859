import { v4 as uuidv4 } from "uuid";

import Dashboard from "pages/linguist/Dashboard";
import InhouseBooking from "pages/linguist/InhouseBooking";
import InterpretingJobs from "pages/linguist/Jobs/InterpretingJobs";
import ViewCashclientBooking from "pages/linguist/Assignment/ViewCashclientBooking";
import TranslationJobs from "pages/linguist/Jobs/TranslationJobs";
import QuoteAndOthers from "pages/linguist/Jobs/Quote&Others";
import RateCardPage from "pages/linguist/RateCardPage";
import CarpCardPage from "pages/linguist/CarpCardPage";

import CreateQuotation from "pages/linguist/CreateQuotation";
import QuoteProvided from "pages/linguist/Quotation/QuoteProvided";
import QuoteAccepted from "pages/linguist/Quotation/QuoteAccepted";

import CreateInvoice from "pages/linguist/CreateInvoice";
import PrivateClient from "pages/linguist/PrivateClient/PrivateClient";
import AddInhouseClient from "pages/linguist/AddInhouseClient";
import SendBookingForm from "pages/linguist/SendBookingForm";
import OrderTranslation from "pages/linguist/OrderTranslation";
import OrderTranslationSummary from "pages/linguist/ordertranslationtelephonicsummary";
import AssignmentPage from "pages/linguist/AssingnmentPage";
import Communication from "pages/linguist/Communication/Communication";
import ManagePublicProfile from "pages/linguist/ManagePublicProfile";
import CashBook from "pages/linguist/Cashbook";
import ResetPassword from "pages/linguist/ResetPassword";
import CurrentAssignmentPage from "pages/linguist/CurrentAssignmentPage";
import ProfileUpdate from "pages/linguist/ProfileUpdate";
import InhouseClientReg from "pages/linguist/InhouseClientReg";
import PercentageCardView from "pages/linguist/PercentageCardView";
import SendInhouseFormPage from "pages/linguist/SendInhouseForm";

import ViewInhouseClient from "pages/linguist/ViewInhouseClient";
import ProfilepageINC from "pages/linguist/inhouse/ProfilepageINC";

import ViewPrivateClient from "pages/linguist/ViewPrivateClient";
import ProfilepagePRC from "pages/linguist/PrivateClient/ProfilepagePRC";

import InvoicePage from "pages/linguist/Invoice";
import AddPrivateClient from "pages/linguist/PrivateClient/AddPrivateClient";
import SendPrivateClientRegPage from "pages/linguist/PrivateClient/SendPrivateClientRegPage";
import SetNewPassword from "pages/linguist/SetNewPassword";
import SendBookingFormPrivatePage from "pages/linguist/PrivateClient/SendBookingFormPrivatePage";
import PrivateInterpreterBooking from "pages/linguist/PrivateClient/PrivateInterpreterBooking";
import DirectPRCInvoice from "pages/linguist/DirectPRCInvoice";
import PrivateInterpreterBookingSummary from "pages/linguist/PrivateClient/privateinterpreterbookingsummary";
import PrivateOrderTrans from "pages/linguist/PrivateClient/PrivateOrderTrans";
import PrivateOrderTransOTT from "pages/linguist/PrivateClient/privateclientOTTtelephonic";
import PrivateQuote from "pages/linguist/PrivateClient/PrivateQuote";
import ProvideQuote from "pages/linguist/PrivateClient/ProvideQuote";
import QuoteList from "pages/linguist/PrivateClient/QuoteList";
import SavedQuoteList from "pages/linguist/PrivateClient/SavedQuoteList";
import AcceptedQuoteList from "pages/linguist/PrivateClient/AcceptedQuote";
import InitialRegistrationPage from "pages/linguist/InitialRegistrationPage";
import LinguistAccountActivate from "pages/linguist/LinguistAccountActivate";
import ServiceAgreement from "pages/linguist/ServiceAgreement";
import AssignmentPRCPage from "pages/linguist/Invoice/InvoicePRC";
import ProjectReportINCPage from "pages/linguist/ProjectReportINCPage";
import ProjectReportBACPage from "pages/linguist/ProjectReport/CreateProjectReportBAC";

// direct invoice
import DirectInvoiceList from "pages/linguist/PrivateClient/InvoiceList";

//Leads
import Leads from "pages/linguist/Leads/Leads";
import SendCLARegistrationForm from "pages/linguist/Leads/SendCLARegistrationForm";
import SendClientRegistrationForm from "pages/linguist/Leads/SendClientRegistrationForm";
import ViewCLADetails from "pages/linguist/Leads/ViewCLADetails";
//Activity logs
import ActivityLogs from "pages/linguist/ActivityLogs/ActivityLogs";

//Icons
import ActivityLogIcon from "assets/icons/activitylog.png";
import InviteIcon from "assets/icons/INVITE-FRIENDS-COLLEAGUES-01.png";
import PCCicon from "assets/icons/price-calculator-cashbook.png";
import MYNicon from "assets/icons/my noteto do list.png";
import Iicon from "assets/icons/invoice.png";
import PCSicon from "assets/icons/private-clients.png";
import Dicon from "assets/icons/dashboard.png";
import MIicon from "assets/icons/inhouse-client.png";
import VPCicon from "assets/icons/view-percentage-card.png";
import LEicon from "assets/icons/leads-01.png";
import MCSicon from "assets/icons/my client-03.png";
import VCCicon from "assets/icons/view-carp-card.png";
import VRicon from "assets/icons/view-rate-card.png";
import CMicon from "assets/icons/communication-menu.png";
import Aicon from "assets/icons/assignment.png";
import AcceptedQuote from "assets/icons/accepted-quote.png";
import CRIcon from "assets/icons/complete-registration.png";
import PQIcon from "assets/icons/provided-quote.png";
import InvoiceIcon from "assets/icons/invoicing.png";
import PPIcon from "assets/icons/public-profile.png";
import QIcon from "assets/icons/quotation.png";

import TimeSheetPage from "pages/linguist/Timesheet/TimeSheetPage";
import TimeSheetPageBAC from "pages/linguist/Timesheet/TimeSheetPageBAC";
import BulkTimeSheetPage from "pages/linguist/Timesheet/BulkTimeSheetPage";
import BulkTimeSheetListPage from "pages/linguist/Timesheet/BulkTimeSheetListPage";
import SupportSystem from "pages/linguist/SupportSystem";

import Help from "pages/linguist/Help";
import INCInterpreterBooking from "pages/linguist/INC/INCInterpreterBooking";
import INCInterpreterBookingSummary from "pages/linguist/INC/insummary";

import PriceCalculator from "pages/linguist/PriceCalculator";
import ProjectReportPage from "pages/linguist/ProjectReportPage";
import SubmitProjectReport from "pages/linguist/SubmitProjectReport";
import ListInvoice from "pages/linguist/ListInvoice";
import CloseTimesheetPage from "pages/linguist/CloseTimesheetPage";
import InvoiceJob from "pages/linguist/Invoice/InvoiceCAC";
import ViewTimesheetPage from "pages/linguist/Timesheet/ViewCACTimesheet";
import ViewTimesheetDetails from "pages/linguist/Timesheet/ViewBACTimesheet";
import AmendTimesheetDetails from "pages/linguist/Timesheet/AmendTimesheet";
import ViewCACProjectReport from "pages/linguist/ProjectReport/ViewCACProjectReport";
import AmendProjectReport from "pages/linguist/ProjectReport/AmendProjectReport";
import ConfirmCACTimesheetPage from "pages/linguist/Timesheet/ConfirmCACTimesheet";
import ConfirmBACTimesheetPage from "pages/linguist/Timesheet/ConfirmBACTimesheet";
import ConfirmCACProjectReportPage from "pages/linguist/ProjectReport/ConfirmCACProjectReport";
import ConfirmBACProjectReportPage from "pages/linguist/ProjectReport/ConfirmBACProjectReport";
import ReceivedInvoicesList from "pages/linguist/ReceivedInvoicesList";
import ReceivedInvoicesView from "pages/linguist/ReceivedInvoicesView";
import PaidInvoicesList from "pages/linguist/Invoice/PaidInvoice";
import CreateQuotePage from "pages/linguist/CreateQuote";
import CreateQuoteBACPage from "pages/linguist/CreateQuoteBAC";

import PRCUnconfirmAssignment from "pages/linguist/PrivateClient/Assignments/UnconfirmAssignment";
import PRCConfirmAssignment from "pages/linguist/PrivateClient/Assignments/ConfirmAssignment";
import INCUnconfirmAssignment from "pages/linguist/inhouse/Assignments/UnconfirmAssignment";
import INCConfirmAssignment from "pages/linguist/inhouse/Assignments/ConfirmAssignment";
import INCCancelAssignment from "pages/linguist/inhouse/Assignments/CancelAssignment";
import QuoteTimesheetPage from "pages/linguist/Quotation/QuoteTimesheetPage";
import QuoteInvoicePage from "pages/linguist/Quotation/QuoteInvoicePage";
import QuoteReportPage from "pages/linguist/Quotation/QuoteReportPage";
import PublicPRCQuoteView from "pages/admin/Private/PublicPRCQuoteView";
import ReportInvoicePage from "pages/linguist/Quotation/ReportInvoicePage";
import InvoiceBAC from "pages/linguist/Invoice/InvoiceBAC";
import InvoiceBACBulk from "pages/linguist/Invoice/InvoiceBACBulk";
import InvoiceTransCACPage from "pages/linguist/Invoice/InvoiceTransCAC";
import InvoiceTransBACPage from "pages/linguist/Invoice/InvoiceTransBAC";

import viewAssignment from "pages/linguist/Assignment/ViewCashclientBooking";
import viewBACAssignment from "pages/linguist/Assignment/ViewAccountClientBooking";
import viewINCAssignment from "pages/linguist/Assignment/ViewInhouseClientBooking";
import viewPRCAssignment from "pages/linguist/Assignment/ViewPrivateBooking";

import viewCACAssignmentDocument from "pages/linguist/Assignment/CACInterpretingDocument";
import viewBACAssignmentDocument from "pages/linguist/Assignment/BACInterpretingDocument";
import viewINCAssignmentDocument from "pages/linguist/Assignment/INCInterpretingDocument";
import viewPRCAssignmentDocument from "pages/linguist/Assignment/PRCInterpretingDocument";

import viewCACTranslationDocument from "pages/linguist/Translation/CACTranslationDocument";
import viewBACTranslationDocument from "pages/linguist/Translation/BACTranslationDocument";
import viewINCTranslationDocument from "pages/linguist/Translation/INCTranslationDocument";
import viewPRCTranslationDocument from "pages/linguist/Translation/PRCTranslationDocument";

import viewCACQuotationDocument from "pages/linguist/Quotation/CACQuotationDocument";
import viewBACQuotationDocument from "pages/linguist/Quotation/BACQuotationDocument";

import viewCACTranslation from "pages/linguist/Translation/ViewCashclientTranslation";
import viewBACTranslation from "pages/linguist/Translation/ViewAccountclientTranslation";
import viewINCTranslation from "pages/linguist/Translation/ViewInhouseTranslation";
import AgentLINviewINCTranslation from "pages/linguist/Translation/ViewAgentLinINCTranslation";
import viewPRCTranslation from "pages/linguist/Translation/ViewPrivateTranslation";
import ViewSavedPRCQuote from "pages/linguist/PrivateClient/ViewSavedPRCQuote";

import ViewCashClientQuotation from "pages/linguist/Quotation/ViewCashClientQuotation";
import ViewAccountClientQuotation from "pages/linguist/Quotation/ViewAccountClientQuotation";

import ViewCACTranslationJob from "pages/linguist/ViewCACTranslationJob";
import AssignmentHistoryPage from "pages/linguist/AssignmentHistoryPage";

import LINAddress from "components/Linguist/ProfileUpdateLinguist/Documents/ProofAddressPhoto";
import LINPassport from "components/Linguist/ProfileUpdateLinguist/Documents/Passport";
import LINReleventDocument from "components/Linguist/ProfileUpdateLinguist/Documents/ReleventDocument";
import LINInsuranceDocument from "components/Linguist/ProfileUpdateLinguist/Documents/InsuranceDocument";
import LINReferenceLetter from "components/Linguist/ProfileUpdateLinguist/Documents/ReferenceLetter";
import LINReleventCertificate from "components/Linguist/ProfileUpdateLinguist/Documents/ReleventCertificate";
import LINInterpretingDocument from "components/Linguist/ProfileUpdateLinguist/Documents/InterpretingDocument";
import LINTranslationDocument from "components/Linguist/ProfileUpdateLinguist/Documents/TranslationDocument";
import LINPSIPSTDocument from "components/Linguist/ProfileUpdateLinguist/Documents/PSIPSTDocument";
import LINAnyOtherDocument from "components/Linguist/ProfileUpdateLinguist/Documents/AnyOtherDocument";
import LINSecurityClearence from "components/Linguist/ProfileUpdateLinguist/Documents/SecurityClearence";
import LINSecurityClearence1 from "components/Linguist/ProfileUpdateLinguist/Documents/SecurityClearence1";
import LINSecurityClearence2 from "components/Linguist/ProfileUpdateLinguist/Documents/SecurityClearence2";
import LINSecurityClearence3 from "components/Linguist/ProfileUpdateLinguist/Documents/SecurityClearence3";
import LINSecurityClearence4 from "components/Linguist/ProfileUpdateLinguist/Documents/SecurityClearence4";
import LINCV from "components/Linguist/ProfileUpdateLinguist/Documents/CV";

import UploadLinDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/UploadLINDoc";
import LINListAddress from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ProofAddressPhoto";
import LINListPassport from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/Passport";
import LINListReleventDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ReleventDocument";
import LINListInsuranceDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/InsuranceDocument";
import LINListReferenceLetter from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ReferenceLetter";
import LINListReleventCertificate from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ReleventCertificate";
import LINListInterpretingDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/InterpretingDocument";
import LINListTranslationDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/TranslationDocument";
import LINListPSIPSTDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/PSIPSTDocument";
import LINListAnyOtherDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/AnyOtherDocument";
import LINListSecurityClearence1 from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/SecurityClearence1";
import LINListSecurityClearence2 from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/SecurityClearence2";
import LINListSecurityClearence3 from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/SecurityClearence3";
import LINListSecurityClearence4 from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/SecurityClearence4";
import LINListCV from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/CV";

import LINProfilePhoto from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/ProfilePhoto";
import LINNewAddress from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/ProofAddressPhoto";
import LINNewPassport from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/Passport";
import LINNewReleventDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/ReleventDocument";
import LINNewInsuranceDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/InsuranceDocument";
import LINNewReferenceLetter from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/ReferenceLetter";
import LINNewReleventCertificate from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/ReleventCertificate";
import LINNewInterpretingDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/InterpretingDocument";
import LINNewTranslationDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/TranslationDocument";
import LINNewPSIPSTDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/PSIPSTDocument";
import LINNewAnyOtherDocument from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/AnyOtherDocument";
import LINNewSecurityClearence1 from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/SecurityClearence1";
import LINNewSecurityClearence2 from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/SecurityClearence2";
import LINNewSecurityClearence3 from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/SecurityClearence3";
import LINNewSecurityClearence4 from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/SecurityClearence4";
import LINNewCV from "components/Linguist/ProfileUpdateLinguist/DocumentsNew/ViewDocuments/CV";

import TimeSheetINCPage from "pages/linguist/Timesheet/TimesheetINC";
import TimesheetINCPublicPage from "pages/TimesheetINCPublicPage";
import InvoiceINCPage from "pages/linguist/Invoice/InvoiceINC";
import TimesheetPRCPage from "pages/linguist/PrivateClient/Assignments/TimesheetPRCPage";
import PRCClosedAssignmentsPage from "pages/linguist/PrivateClient/PRCClosedAssignmentsPage";
import InvoicePrivateClientPage from "pages/linguist/InvoicePrivateClientPage";
import InvoicePrivateClientTransPage from "pages/linguist/InvoicePrivateClientTransPage";
import PrivateClientRegistrationPrivateForm from "components/Linguist/ManagePrivateClient/Form/PrivateClientRegistration/PrivateClientRegistrationPrivateForm";
import ProjectReportPP from "pages/linguist/ProjectReportPP";
//Account removal
import PRCAccountRemoval from "pages/linguist/PrivateClient/AccountRemoval";
import INCAccountRemoval from "pages/linguist/inhouse/AccountRemoval";
import PRTransPage from "pages/linguist/PrivateClient/Assignments/PRTransPage";
import InvoiceINCTransPage from "pages/linguist/Invoice/InvoiceINCTransPage";
import ViewINCInvoicePage from "pages/linguist/inhouse/ViewINCInvoicePage";
import ViewINCTransInvPage from "pages/linguist/inhouse/ViewINCTransInvPage";

import StripePayment from "components/Auth/InhouseInterpretingWeb/StripePayment";
import SuccessINCStripe from "components/Auth/InhouseInterpretingWeb/SuccessINCStripe";
import SuccessINCINV from "components/Linguist/ManageInhouse/Invoices/SucessINCINV";
import PRCPayIntrWeb from "components/Linguist/ManagePrivateClient/Form/PrivateInterpretingWeb/PRCPayIntrWeb";
import PRCPayTransWeb from "components/Linguist/ManagePrivateClient/Form/PrivateOrderTransWeb/PRCPayTransWeb";
import SuccessPRCOTWeb from "components/Linguist/ManagePrivateClient/Form/PrivateOrderTransWeb/SuccessPRCOTWeb";
import SuccessStripePRCEst from "components/Linguist/ManagePrivateClient/Form/PrivateInterpretingWeb/SuccessStripePRCEst";
import INCTransPay from "components/Auth/InhouseOrderTransWeb/INCTransPay";
import SuccessTransINCWeb from "components/Auth/InhouseOrderTransWeb/SuccessTransINCWeb";
import PayTelINCBI from "components/Linguist/ManageInhouse/Form/INCInterpreterTel/PayTelINCBI";
import PayTelINCOT from "components/Linguist/ManageInhouse/Form/OTT/PayTelINCOT";
import PayTelPRCOT from "components/Linguist/ManagePrivateClient/Form/OTTprivate/PayTelPRCOT";
import PRCQuoteSuccess from "pages/admin/Private/PRCQuoteSuccess";
import PRCInvoiceSuccess from "pages/linguist/PrivateClient/PRCInvoiceSuccess";

//Inhouse translation Invoice
import SuccessINCTransInv from "components/Linguist/ManageInhouse/Invoices/SuccessINCTransInv";

// private telephonic interpeting
import PayTelPRCTI from "components/Linguist/ManagePrivateClient/Form/PrivateInterpretingTel/PRCPayTelephonic";
import SuccessStripePRCTelEst from "components/Linguist/ManagePrivateClient/Form/PrivateInterpretingTel/SuccessStripePRCTElEst";

import PublicPRCInvoiceView from "pages/linguist/PrivateClient/PublicPRCInvoiceView";
import ViewQuotePRC from "components/Linguist/ManagePrivateClient/Quote/ViewQuotePRC";
import PRCDirectQInvoice from "components/Linguist/ManagePrivateClient/Quote/PRCDirectQInvoice";
import PRCDirectQInvoicePay from "components/Linguist/ManagePrivateClient/Quote/PRCDirectQInvoicePay";
import SuccessDirectQInv from "components/Linguist/ManagePrivateClient/Quote/SuccessDirectQInv";
import ViewProvidedQuote from "pages/linguist/Quotation/ViewProvidedQuote";
import ViewPrivateDirectQuote from "pages/linguist/PrivateClient/ViewPrivateDirectQuote";

// private client invoice payment
import PublicPRCBookingView from "pages/linguist/private/ViewPRCBookingInvoicePage";
import SuccessPRCINV from "components/Linguist/ManagePrivateClient/Invoices/SucessPRCBookingINV";

import PublicPRCTransView from "pages/linguist/private/ViewPRCTransInvoicePage";
import SuccessPRCTransINV from "components/Linguist/ManagePrivateClient/Invoices/SucessPRCTransINV";

// quote project report
import ViewCACQuoteReport from "pages/linguist/ProjectReport/ViewCACQuoteReport";
import ProjectReportCACQuote from "pages/linguist/ProjectReport/CACQuoteProjectReport";
import ConfirmCACQuoteReport from "pages/linguist/ProjectReport/ConfirmCACQuoteReport";
import CreateCACQuoteINV from "pages/linguist/Invoice/CreateCACQuoteINV";
import ViewCACQuoteINV from "pages/linguist/Invoice/ViewCACQuoteINV";
import ViewInvoice from "pages/linguist/Invoice/ViewInvoice";
import ViewPRCDirectInvoice from "pages/linguist/Invoice/ViewPRCDirectInvoice";
import ViewPRCDirectQuote from "pages/linguist/PrivateClient/ViewPRCDirectQuote";

// linguist full registration
import CompleteRegistration from "pages/linguist/FullRegistration/CompleteRegistration";
import linRegistration2 from "pages/linguist/FullRegistration/linRegistration2";
import linRegistration3 from "pages/linguist/FullRegistration/linRegistration3";
import linRegistration4 from "pages/linguist/FullRegistration/linRegistration4";
import linRegistration5 from "pages/linguist/FullRegistration/linRegistration5";
import linRegistration6 from "pages/linguist/FullRegistration/linRegistration6";
import linRegistration7 from "pages/linguist/FullRegistration/linRegistration7";
import linRegistration8 from "pages/linguist/FullRegistration/linRegistration8";

import cacInterpreting from "pages/linguist/Jobs/cacInterpreting";
import bacInterpreting from "pages/linguist/Jobs/bacInterpreting";
import incInterpreting from "pages/linguist/Jobs/incInterpreting";

import cacTranslation from "pages/linguist/Jobs/cacTranslation";
import bacTranslation from "pages/linguist/Jobs/bacTranslation";
import incTranslation from "pages/linguist/Jobs/incTranslation";

import cacQuotation from "pages/linguist/Jobs/cacQuotation";
import bacQuotation from "pages/linguist/Jobs/bacQuotation";

// my note or to do list route
import MyNote from "pages/linguist/MyNote/MyNote";
import Notifications from "pages/linguist/Notifications/Notifications";
import CreateTicket from "pages/linguist/Communication/CreateTicket";
import OpenTicket from "pages/linguist/Communication/TicketList";

import ViewAttachConversation from "pages/linguist/Communication/ViewAttachConversation";
import ViewAttachTicket from "pages/linguist/Communication/ViewAttachTicket";
import ViewAttachMessage from "pages/linguist/WelcomeMessage/ViewAttachMessage";

import MyClients from "pages/linguist/MyClient/MyClient";
import ManageMyINC from "pages/linguist/MyClient/ManageMyINC";
import ManageMyPRC from "pages/linguist/MyClient/ManageMyPRC";

import SendInvitation from "pages/linguist/LINInvitation/SendLINRegForm";

import ViewProjectReportDocument from "pages/linguist/ProjectReport/ViewProjectReportDocument";
import ViewTimesheetDocument from "pages/linguist/Timesheet/ViewTimesheetDocument";

import SuccessDirectInv from "components/Linguist/Invoicing/ReceivedInvoice/SuccessDirectInv";

import TranslationDeliveryDoc from "pages/Component/Translation_Delivery/LIN/ViewDocument";

const LINGUIST = [
  {
    id: uuidv4(),
    text: "Dashboard",
    subText: "Linguist Dashboard",
    path: "linguist/dashboard",
    type: "dashboard",
    component: Dashboard,
    icon: Dicon,
  },
  {
    id: uuidv4(),
    path: "linguist/help",
    component: Help,
  },
  {
    id: uuidv4(),
    path: "linguist/current-assignment/assignment-history",
    component: AssignmentHistoryPage,
  },
  {
    id: uuidv4(),
    text: "Manage In-house ⇧ Clients",
    path: "linguist/manage-inhouse-client",
    component: InhouseBooking,
    icon: MIicon,
  },
  {
    id: uuidv4(),
    path: "linguist/create-invoice/view-invoice",
    component: InvoicePage,
    icon: Iicon,
  },
  {
    id: uuidv4(),
    path: "linguist/initial-registration",
    component: InitialRegistrationPage,
    subText: "Linguist Initial Registration",
    type: "form",
  },
  {
    id: uuidv4(),
    path: "linguist/auth/password/reset/:token",
    component: SetNewPassword,
  },
  {
    id: uuidv4(),
    path: "linguist/auth/account/activate/:token",
    component: LinguistAccountActivate,
  },
  {
    id: uuidv4(),
    path: "linguist/reset-password",
    component: ResetPassword,
  },
  {
    id: uuidv4(),
    path: "linguist/current-assignment",
    component: CurrentAssignmentPage,
  },
  {
    id: uuidv4(),
    path: "linguist/current-assignment/:data/:type",
    component: CurrentAssignmentPage,
  },
  {
    id: uuidv4(),
    path: "linguist/current-assignment/close-and-timesheet",
    component: CloseTimesheetPage,
  },
  {
    id: uuidv4(),
    path: "linguist/current-assignment/close-and-timesheet/:data/:type",
    component: CloseTimesheetPage,
  },
  {
    id: uuidv4(),
    path: "linguist/inhouse-booking/book-interpreter",
    component: INCInterpreterBooking,
  },
  {
    id: uuidv4(),
    path: "linguist/inhouse-booking/book-interpreter/summary",
    component: INCInterpreterBookingSummary,
  },
  {
    id: uuidv4(),
    path: "linguist/add-inhouse-client",
    component: AddInhouseClient,
  },
  {
    id: uuidv4(),
    path: "linguist/add-inhouse-client/removal-request",
    component: INCAccountRemoval,
  },
  {
    id: uuidv4(),
    path: "linguist/add-inhouse-client/registration",
    component: InhouseClientReg,
  },
  {
    id: uuidv4(),
    path: "linguist/send-booking-form",
    component: SendBookingForm,
  },
  {
    id: uuidv4(),
    path: "linguist/inhouse-booking/order-translation",
    component: OrderTranslation,
  },
  {
    id: uuidv4(),
    path: "linguist/inhouse-booking/order-translation/telephonic/summary",
    component: OrderTranslationSummary,
  },
  {
    id: uuidv4(),
    path: "linguist/add-inhouse-client/send-form",
    component: SendInhouseFormPage,
  },

  {
    id: uuidv4(),
    path: "linguist/view-inhouse-client",
    component: ViewInhouseClient,
  },
  {
    id: uuidv4(),
    path: "linguist/view-private-client",
    component: ViewPrivateClient,
  },
  {
    id: uuidv4(),
    path: "linguist/prc-profile/:id",
    component: ProfilepagePRC,
  },
  {
    id: uuidv4(),
    path: "linguist/inc-profile/:id",
    component: ProfilepageINC,
  },
  {
    id: uuidv4(),
    text: "Manage Private Clients",
    path: "linguist/manage-private-client",
    component: PrivateClient,
    icon: PCSicon,
  },
  {
    id: uuidv4(),
    path: "linguist/add-private-client",
    component: AddPrivateClient,
  },
  {
    id: uuidv4(),
    path: "linguist/add-private-client/removal-request",
    component: PRCAccountRemoval,
  },
  {
    id: uuidv4(),
    path: "linguist/add-private-client/send-client-registration-form",
    component: SendPrivateClientRegPage,
  },
  {
    id: uuidv4(),
    path: "linguist/send-booking-form-private",
    component: SendBookingFormPrivatePage,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/book-interpreter",
    component: PrivateInterpreterBooking,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/book-interpreter/summary/private",
    component: PrivateInterpreterBookingSummary,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/order-translation",
    component: PrivateOrderTrans,
  },
  {
    id: uuidv4(),
    path: "linguist/private/telephonicbooking/order-translation/summary",
    component: PrivateOrderTransOTT,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/quote",
    component: PrivateQuote,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/provide-quote",
    component: ProvideQuote,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/quote-list",
    component: QuoteList,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/saved-quote-list",
    component: SavedQuoteList,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/accepted-quote-list",
    component: AcceptedQuoteList,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/direct-invoice-list",
    component: DirectInvoiceList,
  },

  // CreateQuotation
  {
    id: uuidv4(),
    text: "Quotation",
    component: CreateQuotation,
    path: "linguist/quotation",
    icon: QIcon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Provided Quotes",
        component: QuoteProvided,
        path: "linguist/provided-quote",
        icon: PQIcon,
      },
      {
        id: uuidv4(),
        text: "Accepted Quotes",
        component: QuoteAccepted,
        path: "linguist/accepted-quote",
        icon: AcceptedQuote,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "CAC, BAC & INC ⇩ Assignments",
    path: "linguist/assignments",
    component: AssignmentPage,
    icon: Aicon,
  },
  // {
  //   id: uuidv4(),
  //   text: "Projects",
  //   component: Projects,
  //   path: "linguist/projects",
  //   icon: Picon,
  // },

  {
    id: uuidv4(),
    text: "My Clients",
    component: MyClients,
    path: "linguist/my-clients",
    icon: MCSicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Manage My INC",
        component: ManageMyINC,
        path: "linguist/manage-my-in-house-clients",
        icon: MIicon,
      },
      {
        id: uuidv4(),
        text: "Manage My PRC",
        component: ManageMyPRC,
        path: "linguist/manage-my-private-clients",
        icon: PCSicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "Leads",
    component: Leads,
    path: "linguist/lead",
    icon: LEicon,
  },

  {
    id: uuidv4(),
    path: "linguist/interpreting-jobs",
    component: InterpretingJobs,
  },
  {
    id: uuidv4(),
    path: "linguist/translation-jobs",
    component: TranslationJobs,
  },
  {
    id: uuidv4(),
    path: "linguist/translation-jobs/view/:id",
    component: ViewCACTranslationJob,
  },
  {
    id: uuidv4(),
    path: "linguist/quote-others",
    component: QuoteAndOthers,
  },
  {
    id: uuidv4(),
    path: "linguist/quote-others/create-quote/:id",
    component: CreateQuotePage,
  },
  {
    id: uuidv4(),
    path: "linguist/quote-others/bac/create-quote/:id",
    component: CreateQuoteBACPage,
  },

  {
    id: uuidv4(),
    path: "linguist/profile/update",
    component: ProfileUpdate,
  },
  {
    id: uuidv4(),
    path: "linguist/send-cla-registration-form",
    component: SendCLARegistrationForm,
  },

  {
    id: uuidv4(),
    path: "linguist/send-client-registration-form",
    component: SendClientRegistrationForm,
  },

  {
    id: uuidv4(),
    path: "linguist/view-cla/:id",
    component: ViewCLADetails,
  },
  {
    id: uuidv4(),
    text: "Invoicing",
    component: CreateInvoice,
    path: "linguist/create-invoice",
    icon: InvoiceIcon,
  },
  {
    id: uuidv4(),
    component: ListInvoice,
    path: "linguist/invoice-list",
  },
  {
    id: uuidv4(),
    component: PaidInvoicesList,
    path: "linguist/paid-invoices-list",
  },
  {
    id: uuidv4(),
    component: ReceivedInvoicesList,
    path: "linguist/received-invoices-list",
  },
  {
    id: uuidv4(),
    component: ReceivedInvoicesView,
    path: "linguist/view-received-invoice/:id",
  },
  {
    id: uuidv4(),
    component: DirectPRCInvoice,
    path: "linguist/direct-prc-invoice",
  },
  {
    id: uuidv4(),
    component: AssignmentPRCPage,
    path: "linguist/create-prc-invoice",
  },

  {
    id: uuidv4(),
    text: "Communication",
    path: "linguist/communication",
    component: Communication,
    icon: CMicon,
  },

  {
    id: uuidv4(),
    text: "Manage Public Profile",
    component: ManagePublicProfile,
    path: "linguist/manage-public-profile",
    icon: PPIcon,
  },

  {
    id: uuidv4(),
    text: "Price Calculator And Cashbook",
    component: PriceCalculator,
    path: "linguist/price-calculator-cashbook",
    icon: PCCicon,
  },
  {
    id: uuidv4(),
    text: "My Note / To Do List",
    component: MyNote,
    path: "linguist/my-note-or-to-do-list",
    icon: MYNicon,
  },
  {
    id: uuidv4(),
    component: Notifications,
    path: "linguist/view-all-notifications",
  },

  {
    id: uuidv4(),
    path: "linguist/price-calculator",
    component: PriceCalculator,
  },
  {
    id: uuidv4(),
    path: "linguist/cashbook",
    component: CashBook,
  },
  {
    id: uuidv4(),
    text: "View Rate Card",
    component: RateCardPage,
    path: "linguist/view-rate-card",
    icon: VRicon,
  },
  {
    id: uuidv4(),
    text: "View CARP Card",
    component: CarpCardPage,
    path: "linguist/view-carp-card",
    icon: VCCicon,
  },
  {
    id: uuidv4(),
    text: "View Percentage Card",
    component: PercentageCardView,
    path: "linguist/view-percentage-card",
    icon: VPCicon,
  },
  {
    id: uuidv4(),
    component: ServiceAgreement,
    path: "linguist/service-agreement-of-working-with-linguists-collective",
  },

  {
    id: uuidv4(),
    component: ViewCashclientBooking,
    path: "linguists/view-cash-client-booking-interpreting/:id",
  },
  {
    id: uuidv4(),
    component: TimeSheetPage,
    path: "linguist/current-assignment/cac/timesheet/:id",
  },
  {
    id: uuidv4(),
    component: TimeSheetPageBAC,
    path: "linguist/current-assignment/bac/timesheet/:id",
  },
  {
    id: uuidv4(),
    component: BulkTimeSheetPage,
    path: "linguist/create-bac-bulk-interpreting-timesheet/:id",
  },
  {
    id: uuidv4(),
    component: BulkTimeSheetListPage,
    path: "linguist/listview-bulk-interpreting-timesheet/:id",
  },
  {
    id: uuidv4(),
    component: SupportSystem,
    path: "linguist/support-system",
  },
  {
    id: uuidv4(),
    text: "Invite Friend / Colleague",
    path: "linguist/send-invitation",
    component: SendInvitation,
    icon: InviteIcon,
  },
  {
    id: uuidv4(),
    text: "Activity Logs",
    path: "linguist/activity-logs",
    component: ActivityLogs,
    icon: ActivityLogIcon,
  },
  {
    id: uuidv4(),
    component: CompleteRegistration,
    text: "Complete Full Registration",
    path: "linguist/full-registration-p1",
    icon: CRIcon,
  },
  {
    id: uuidv4(),
    component: ProjectReportPage,
    path: "linguist/create-close-and-project-report/:id",
  },
  {
    id: uuidv4(),
    component: InvoiceJob,
    path: "linguist/create-invoice/:id",
  },
  {
    id: uuidv4(),
    component: InvoiceINCPage,
    path: "linguist/current-assignment/inhouse/interpreter/invoice/:id",
  },
  {
    id: uuidv4(),
    component: InvoiceINCTransPage,
    path: "linguist/current-assignment/inhouse/translation/invoice/:id",
  },
  {
    id: uuidv4(),
    component: InvoicePrivateClientPage,
    path: "linguist/private-client/interpreter/invoice/:id",
  },
  {
    id: uuidv4(),
    component: InvoicePrivateClientTransPage,
    path: "linguist/private-client/translation/invoice/:id",
  },
  {
    id: uuidv4(),
    component: InvoiceBAC,
    path: "linguist/current-assignment/bac/invoice/:id",
  },
  {
    id: uuidv4(),
    component: InvoiceBACBulk,
    path: "linguist/current-assignment/bac-bulk/invoice/:id",
  },
  {
    id: uuidv4(),
    component: InvoiceTransCACPage,
    path: "linguist/current-assignment/cac/translation/invoice/:id",
  },
  {
    id: uuidv4(),
    component: InvoiceTransBACPage,
    path: "linguist/current-assignment/bac/translation/invoice/:id",
  },
  {
    id: uuidv4(),
    component: ViewTimesheetPage,
    path: "linguist/current-assignment/view-timesheet/:id",
  },
  {
    id: uuidv4(),
    component: ViewTimesheetDetails,
    path: "linguist/view-timesheet/:id",
  },
  {
    id: uuidv4(),
    component: AmendTimesheetDetails,
    path: "linguist/amend-timesheet/:id",
  },

  {
    id: uuidv4(),
    component: ViewCACProjectReport,
    path: "linguist/view-close-and-project-report/:id",
  },
  {
    id: uuidv4(),
    component: AmendProjectReport,
    path: "linguist/amend-close-and-project-report/:id",
  },

  {
    id: uuidv4(),
    component: ConfirmCACTimesheetPage,
    path: "linguist/confirm-cac-timesheet/:id",
  },
  {
    id: uuidv4(),
    component: ConfirmBACTimesheetPage,
    path: "linguist/confirm-bac-timesheet/:id",
  },
  {
    id: uuidv4(),
    component: ConfirmCACProjectReportPage,
    path: "linguist/confirm-cac-project-report/:id",
  },
  {
    id: uuidv4(),
    component: ConfirmBACProjectReportPage,
    path: "linguist/confirm-bac-project-report/:id",
  },
  {
    id: uuidv4(),
    component: SubmitProjectReport,
    path: "linguist/projects/submit-project-report",
  },
  {
    id: uuidv4(),
    component: INCUnconfirmAssignment,
    path: "linguist/inc-unconfirm-assignment",
  },
  {
    id: uuidv4(),
    component: INCConfirmAssignment,
    path: "linguist/inc-confirm-assignment",
  },
  {
    id: uuidv4(),
    component: INCCancelAssignment,
    path: "linguist/inc-cancel-assignment",
  },
  {
    id: uuidv4(),
    component: INCUnconfirmAssignment,
    path: "linguist/inc-unconfirm-assignment/:data/:type",
  },
  {
    id: uuidv4(),
    component: INCConfirmAssignment,
    path: "linguist/inc-confirm-assignment/:data/:type",
  },
  {
    id: uuidv4(),
    component: INCCancelAssignment,
    path: "linguist/inc-cancel-assignment/:data/:type",
  },
  {
    id: uuidv4(),
    component: PRCUnconfirmAssignment,
    path: "linguist/prc-unconfirm-assignment",
  },
  {
    id: uuidv4(),
    component: PRCConfirmAssignment,
    path: "linguist/prc-confirm-assignment",
  },
  {
    id: uuidv4(),
    component: PRCUnconfirmAssignment,
    path: "linguist/prc-unconfirm-assignment/:data/:type",
  },
  {
    id: uuidv4(),
    component: PRCConfirmAssignment,
    path: "linguist/prc-confirm-assignment/:data/:type",
  },
  {
    id: uuidv4(),
    component: QuoteTimesheetPage,
    path: "linguist/accepted-quote/timesheet/:id",
  },
  {
    id: uuidv4(),
    component: QuoteInvoicePage,
    path: "linguist/accepted-quote/timesheet/invoice/:id",
  },
  {
    id: uuidv4(),
    component: QuoteReportPage,
    path: "linguist/accepted-quote/project-report/:id",
  },
  {
    id: uuidv4(),
    component: ReportInvoicePage,
    path: "linguist/accepted-quote/project-report/invoice/:id",
  },

  // Public route view quote
  {
    id: uuidv4(),
    component: PublicPRCQuoteView,
    path: "linguist/private-client/payment/:id",
  },

  {
    id: uuidv4(),
    component: PublicPRCBookingView,
    path: "linguist/private-client-booking-interpreting/payment/:id",
  },

  {
    id: uuidv4(),
    component: PublicPRCTransView,
    path: "linguist/private-client-translation-order/payment/:id",
  },

  // Public route view PRC invoice
  {
    id: uuidv4(),
    component: PublicPRCInvoiceView,
    path: "linguist/private-client-invoice/payment/:id",
  },

  // view interpreting assignment
  {
    id: uuidv4(),
    component: viewAssignment,
    path: "linguist/cac-view-interpreting-assignment/:id/:linAddress/:isMapped",
  },
  {
    id: uuidv4(),
    component: viewAssignment,
    path: "linguist/cac-view-interpreting-assignment/:id/:isMapped",
  },
  {
    id: uuidv4(),
    component: viewBACAssignment,
    path: "linguist/bac-view-interpreting-assignment/:id/:linAddress/:isMapped",
  },
  {
    id: uuidv4(),
    component: viewBACAssignment,
    path: "linguist/bac-view-interpreting-assignment/:id/:isMapped",
  },
  {
    id: uuidv4(),
    component: viewINCAssignment,
    path: "linguist/inc-view-interpreting-assignment/:id/:linAddress/:isMapped",
  },
  {
    id: uuidv4(),
    component: viewINCAssignment,
    path: "linguist/inc-view-interpreting-assignment/:id/:isMappped",
  },
  {
    id: uuidv4(),
    component: viewPRCAssignment,
    path: "linguist/prc-view-interpreting-assignment/:id",
  },

  // interpreting assignment document
  {
    id: uuidv4(),
    component: viewCACAssignmentDocument,
    path: "linguist/cac-view-interpreting-document/:id/:refID",
  },
  {
    id: uuidv4(),
    component: viewBACAssignmentDocument,
    path: "linguist/bac-view-interpreting-document/:id/:refID",
  },
  {
    id: uuidv4(),
    component: viewINCAssignmentDocument,
    path: "linguist/inc-view-interpreting-document/:id/:refID",
  },
  {
    id: uuidv4(),
    component: viewPRCAssignmentDocument,
    path: "linguist/prc-view-interpreting-document/:id/:refID",
  },

  // translation assignment document
  {
    id: uuidv4(),
    component: viewCACTranslationDocument,
    path: "linguist/cac-view-translation-document/:id/:refID",
  },
  {
    id: uuidv4(),
    component: viewBACTranslationDocument,
    path: "linguist/bac-view-translation-document/:id/:refID",
  },
  {
    id: uuidv4(),
    component: viewINCTranslationDocument,
    path: "linguist/inc-view-translation-document/:id/:refID",
  },
  {
    id: uuidv4(),
    component: viewPRCTranslationDocument,
    path: "linguist/prc-view-translation-document/:id/:refID",
  },

  // quote document
  {
    id: uuidv4(),
    component: viewCACQuotationDocument,
    path: "linguist/cac-view-quotation-document/:id",
  },
  {
    id: uuidv4(),
    component: viewBACQuotationDocument,
    path: "linguist/bac-view-quotation-document/:id",
  },

  // view translation assignment
  {
    id: uuidv4(),
    component: viewCACTranslation,
    path: "linguist/cac-view-translation-assignment/:id",
  },
  {
    id: uuidv4(),
    component: viewBACTranslation,
    path: "linguist/bac-view-translation-assignment/:id",
  },
  {
    id: uuidv4(),
    component: viewINCTranslation,
    path: "linguist/inc-view-translation-assignment/:id",
  },
  {
    id: uuidv4(),
    component: AgentLINviewINCTranslation,
    path: "linguist/agent-linguist-view-inc-translation-assignment/:id",
  },
  {
    id: uuidv4(),
    component: viewPRCTranslation,
    path: "linguist/prc-view-translation-assignment/:id",
  },

  //quote & others
  {
    id: uuidv4(),
    component: ViewCashClientQuotation,
    path: "linguist/cac-view-quote/:id",
  },
  {
    id: uuidv4(),
    component: ViewAccountClientQuotation,
    path: "linguist/bac-view-quote/:id",
  },
  //linguist documents
  {
    id: uuidv4(),
    path: "linguist/update-linguist-documents",
    component: UploadLinDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-profile-photo-preview/:id",
    component: LINProfilePhoto,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-address-preview/:id",
    component: LINAddress,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-address/:id",
    component: LINNewAddress,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-relevent-document/:id",
    component: LINReleventDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-relevent-document/:id",
    component: LINNewReleventDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-insurance-document/:id",
    component: LINInsuranceDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-insurance-document/:id",
    component: LINNewInsuranceDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-reference-letter/:id",
    component: LINReferenceLetter,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-reference-letter/:id",
    component: LINNewReferenceLetter,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-interpreting-document/:id",
    component: LINInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-interpreting-document/:id",
    component: LINNewInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-translation-document/:id",
    component: LINTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-translation-document/:id",
    component: LINNewTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-psi-pst-document/:id",
    component: LINPSIPSTDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-psi-pst-document/:id",
    component: LINNewPSIPSTDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-any-other-document/:id",
    component: LINAnyOtherDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-any-other-document/:id",
    component: LINNewAnyOtherDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-relevent-certificate/:id",
    component: LINReleventCertificate,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-relevent-certificate/:id",
    component: LINNewReleventCertificate,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-security-clearence/:id",
    component: LINSecurityClearence,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-security-clearence-1",
    component: LINListSecurityClearence1,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-security-clearence-2",
    component: LINListSecurityClearence2,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-security-clearence-3",
    component: LINListSecurityClearence3,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-security-clearence-4",
    component: LINListSecurityClearence4,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-cv",
    component: LINListCV,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-address",
    component: LINListAddress,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-passport",
    component: LINListPassport,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-relevent-document",
    component: LINListReleventDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-relevent-certificate",
    component: LINListReleventCertificate,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-insurance-document",
    component: LINListInsuranceDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-reference-letter",
    component: LINListReferenceLetter,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-interpreting-document",
    component: LINListInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-translation-document",
    component: LINListTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-psi-pst-document",
    component: LINListPSIPSTDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-any-other-document",
    component: LINListAnyOtherDocument,
  },

  {
    id: uuidv4(),
    path: "linguist/linguist-security-clearence-1/:id",
    component: LINSecurityClearence1,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-security-clearence-1/:id",
    component: LINNewSecurityClearence1,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-security-clearence-2/:id",
    component: LINSecurityClearence2,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-security-clearence-2/:id",
    component: LINNewSecurityClearence2,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-security-clearence-3/:id",
    component: LINSecurityClearence3,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-security-clearence-3/:id",
    component: LINNewSecurityClearence3,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-security-clearence-4/:id",
    component: LINSecurityClearence4,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-security-clearence-4/:id",
    component: LINNewSecurityClearence4,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-passport/:id",
    component: LINPassport,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-passport/:id",
    component: LINNewPassport,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-cv/:id",
    component: LINCV,
  },
  {
    id: uuidv4(),
    path: "linguist/linguist-view-cv/:id",
    component: LINNewCV,
  },
  {
    id: uuidv4(),
    path: "linguist/current-assignment/inhouse/timesheet/:id",
    component: TimeSheetINCPage,
  },

  {
    id: uuidv4(),
    path: "linguist/private-client/closed-assignments",
    component: PRCClosedAssignmentsPage,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/closed-assignments/:data/:type",
    component: PRCClosedAssignmentsPage,
  },

  {
    id: uuidv4(),
    path: "linguist/add-private-client/registration",
    component: PrivateClientRegistrationPrivateForm,
  },

  {
    id: uuidv4(),
    path: "linguist/current-assignment/inc/translation/project-report/:id",
    component: ProjectReportINCPage,
  },

  {
    id: uuidv4(),
    path: "linguist/current-assignment/bac/translation/project-report/:id",
    component: ProjectReportBACPage,
  },

  //Public route closed and timesheet inhouse
  {
    id: uuidv4(),
    path: "accept-inhouse-assignment-close-and-timesheet/:id",
    component: TimesheetINCPublicPage,
  },
  {
    id: uuidv4(),
    path: "linguist/current-assignment/prc/close-and-project-report/:id",
    component: TimesheetPRCPage,
  },
  {
    id: uuidv4(),
    path: "linguist/current-assignment/prc/translation/close-and-project-report/:id",
    component: PRTransPage,
  },
  {
    id: uuidv4(),
    path: "accept-inhouse-translation-close-and-report/:id",
    component: ProjectReportPP,
  },

  {
    id: uuidv4(),
    path: "inhouse-interpreter-booking/client/summary/estimate/payment", //Inhouse estimate booking interpreter
    component: StripePayment,
  },
  {
    id: uuidv4(),
    path: "inhouse-translation-order/client/summary/estimate/payment", //Inhouse estimate translation
    component: INCTransPay,
  },
  {
    id: uuidv4(),
    path: "private-client-interpreter-booking/summary/estimate/payment", //Private estimate booking interpreter
    component: PRCPayIntrWeb,
  },
  {
    id: uuidv4(),
    path: "private-client-translation-order/summary/estimate/payment", //Private estimate order translation
    component: PRCPayTransWeb,
  },

  // Public route for invoice inc Booking and Trans
  {
    id: uuidv4(),
    path: "inhouse-client/invoice/payment/:id",
    component: ViewINCInvoicePage,
  },
  {
    id: uuidv4(),
    path: "inhouse-client/translation/invoice/payment/:id",
    component: ViewINCTransInvPage,
  },

  // Stripe Success Routes
  {
    id: uuidv4(),
    path: "inhouse-client/interpreter/success/estimate/payment/:id", //INC Booking Interpreter Send Mail
    component: SuccessINCStripe,
  },
  {
    id: uuidv4(),
    path: "inhouse-client/interpreter/success/invoice/payment/:invoiceId/:id", //INC Booking Interpreter Invoice Pay Success Page
    component: SuccessINCINV,
  },
  {
    id: uuidv4(),
    path: "inhouse-client/translation/success/invoice/payment/:invoiceId/:id", //INC Booking Interpreter Invoice Pay Success Page
    component: SuccessINCTransInv,
  },
  {
    id: uuidv4(),
    path: "inhouse-client/translation/success/estimate/payment/:id", //INC Booking Interpreter Send Mail
    component: SuccessTransINCWeb,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client/view-quote/timesheet/:id", //PRC View Accepted Quote
    component: ViewQuotePRC,
  },

  // Private client estimate
  {
    id: uuidv4(),
    path: "private-client/interpreter/success/estimate/payment/:id", //Private Booking Interpreter Send Mail
    component: SuccessStripePRCEst,
  },

  // private client invoice payment
  {
    id: uuidv4(),
    path: "private-client/interpreter/success/invoice/payment/:invoiceId/:id", //PRC Booking Interpreter Invoice Pay Success Page
    component: SuccessPRCINV,
  },
  {
    id: uuidv4(),
    path: "private-client/translation/success/invoice/payment/:invoiceId/:id", //PRC translation Invoice Pay Success Page
    component: SuccessPRCTransINV,
  },
  // private client telephonic interpreting

  {
    id: "200n",
    path: "private-client/booking-interpreter/telephonic/payment/:token",
    component: PayTelPRCTI,
  },
  {
    id: uuidv4(),
    path: "private-client/telephonic-interpreter/success/estimate/payment/:id", //Private Booking Interpreter Send Mail
    component: SuccessStripePRCTelEst,
  },

  {
    id: uuidv4(),
    path: "private-client/translation/success/estimate/payment/:id", //Private Translation Send Mail
    component: SuccessPRCOTWeb,
  },

  //Telephonic booking interpreter INC

  {
    id: uuidv4(),
    path: "inhouse-client/booking-interpreter/telephonic/payment/:token", //Telephonic Booking interpreter INC
    component: PayTelINCBI,
  },
  {
    id: uuidv4(),
    path: "inhouse-client/order-translation/telephonic/payment/:token", //Telephonic Order Translation INC
    component: PayTelINCOT,
  },

  // Telephonic order translation private
  {
    id: uuidv4(),
    path: "private-client/order-translation/telephonic/payment/:token", //Telephonic Order Translation PRC
    component: PayTelPRCOT,
  },

  // PRC Quote Success
  {
    id: uuidv4(),
    path: "private-client/quote/estimate/success/payment/:quoteID/:paymentIntent", //Telephonic Order Translation PRC
    component: PRCQuoteSuccess,
  },

  // PRC Invoice Success
  {
    id: uuidv4(),
    path: "private-client/direct-invoice/success/payment/:invoiceID/:paymentIntent", //Telephonic Order Translation PRC
    component: PRCInvoiceSuccess,
  },

  // View Direct Quote Invoice
  {
    id: uuidv4(),
    path: "linguist/private-client/direct-quote/invoice/:id",
    component: PRCDirectQInvoice,
  },
  {
    id: uuidv4(),
    path: "linguist/private-client-direct-quote-invoice/payment/:id", // View direct quote invoice for payment
    component: PRCDirectQInvoicePay,
  },
  {
    id: uuidv4(),
    path: "private-client/direct-quote/invoice/success/payment/:invoiceID/:paymentIntent", // Direct quote invoice success page
    component: SuccessDirectQInv,
  },

  // Linguist Dashboard Provided Quote View
  {
    id: uuidv4(),
    path: "linguist/provided-quote/view/:id",
    component: ViewProvidedQuote,
  },

  {
    id: uuidv4(),
    path: "linguist/private-direct-quote/view/:id",
    component: ViewPrivateDirectQuote,
  },

  // View saved quote
  {
    id: uuidv4(),
    path: "linguist/private-direct-quote/save-view/:id",
    component: ViewSavedPRCQuote,
  },

  {
    id: uuidv4(),
    path: "linguist/view-project-report/:id",
    component: ViewCACQuoteReport,
  },

  // view lin quote project report for cac
  {
    id: uuidv4(),
    path: "linguist/accepted-quote/quotation/view-project-report/:id",
    component: ViewCACQuoteReport,
  },
  {
    id: uuidv4(),
    path: "linguist/accepted-quote/quotation/create-project-report/:id",
    component: ProjectReportCACQuote,
  },
  {
    id: uuidv4(),
    path: "linguist/accepted-quote/quotation/confirm-project-report/:id",
    component: ConfirmCACQuoteReport,
  },
  {
    id: uuidv4(),
    path: "linguist/accepted-quote/quotation/create-invoice/:id",
    component: CreateCACQuoteINV,
  },
  {
    id: uuidv4(),
    path: "linguist/accepted-quote/quotation/view-invoice/:id",
    component: ViewCACQuoteINV,
  },
  {
    id: uuidv4(),
    path: "linguist/view-invoice/:id",
    component: ViewInvoice,
  },
  {
    id: uuidv4(),
    path: "linguist/view-prc-direct-invoice/:id",
    component: ViewPRCDirectInvoice,
  },
  {
    id: uuidv4(),
    path: "linguist/view-prc-direct-quote/:id",
    component: ViewPRCDirectQuote,
  },

  // linguist full registration
  {
    id: uuidv4(),
    path: "linguist/full-registration-p2",
    component: linRegistration2,
  },
  {
    id: uuidv4(),
    path: "linguist/full-registration-p3",
    component: linRegistration3,
  },
  {
    id: uuidv4(),
    path: "linguist/full-registration-p4",
    component: linRegistration4,
  },
  {
    id: uuidv4(),
    path: "linguist/full-registration-p5",
    component: linRegistration5,
  },
  {
    id: uuidv4(),
    path: "linguist/full-registration-p6",
    component: linRegistration6,
  },
  {
    id: uuidv4(),
    path: "linguist/full-registration-p7",
    component: linRegistration7,
  },
  {
    id: uuidv4(),
    path: "linguist/full-registration-p8",
    component: linRegistration8,
  },

  // interpreting job routes
  {
    id: uuidv4(),
    path: "linguist/cac-interpreting-jobs",
    component: cacInterpreting,
  },
  {
    id: uuidv4(),
    path: "linguist/bac-interpreting-jobs",
    component: bacInterpreting,
  },
  {
    id: uuidv4(),
    path: "linguist/inc-interpreting-jobs",
    component: incInterpreting,
  },
  // translation routes
  {
    id: uuidv4(),
    path: "linguist/cac-translation-jobs",
    component: cacTranslation,
  },
  {
    id: uuidv4(),
    path: "linguist/bac-translation-jobs",
    component: bacTranslation,
  },
  {
    id: uuidv4(),
    path: "linguist/inc-translation-jobs",
    component: incTranslation,
  },
  // quote routes
  {
    id: uuidv4(),
    path: "linguist/cac-quotation",
    component: cacQuotation,
  },
  {
    id: uuidv4(),
    path: "linguist/bac-quotation",
    component: bacQuotation,
  },

  {
    id: uuidv4(),
    path: "linguist/open-new-ticket",
    component: CreateTicket,
  },

  {
    id: uuidv4(),
    path: "linguist/view-tickets",
    component: OpenTicket,
  },

  {
    id: uuidv4(),
    path: "linguist/view-attachment-ticket-conversation/:id",
    component: ViewAttachConversation,
  },

  {
    id: uuidv4(),
    path: "linguist/view-ticket-attachment/:id",
    component: ViewAttachTicket,
  },

  {
    id: uuidv4(),
    path: "linguist/view-welcome-message-attachment/:id/:message",
    component: ViewAttachMessage,
  },

  {
    id: uuidv4(),
    path: "linguist/view-cla/:id",
    component: ViewCLADetails,
  },

  // timesheet and project report documents view routes
  {
    id: uuidv4(),
    path: "linguist/view-timesheet-document-by-linguist/:id",
    component: ViewTimesheetDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/view-timesheet-document-by-client/:id",
    component: ViewTimesheetDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/view-project-report-document-by-linguist/:id",
    component: ViewProjectReportDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/view-project-report-document-by-client/:id",
    component: ViewProjectReportDocument,
  },
  {
    id: uuidv4(),
    path: "linguist/view-project-report-document-by-admin/:id",
    component: ViewProjectReportDocument,
  },

  {
    id: uuidv4(),
    path: "linguist/pay-direct-invoice/success/invoice/payment/:invoiceId/:id", // linguist direct Invoice Pay Success Page
    component: SuccessDirectInv,
  },

  // view translation delivery document
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "linguist/view-translation-delivery-document1/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "linguist/view-translation-delivery-document2/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "linguist/view-translation-delivery-document3/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "linguist/view-translation-delivery-document4/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "linguist/view-translation-delivery-document5/:id",
  },
];

export default LINGUIST;
