// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import LinguistLayout from "layout/LinguistLayout";
import QuoteTimesheet from "components/CashClient/Quotation/AcceptedQuote/QuoteTimeSheet/QuoteTimeSheet";
import { useParams } from "react-router-dom";

const QuoteTimesheetPage = () => {
  const classes = useDashboardStyles();
  const { id } = useParams()
  return (
    <LinguistLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Timesheet</p>
            </CardHeader>
            <CardBody>
              <QuoteTimesheet id={id} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LinguistLayout>
  );
};

export default QuoteTimesheetPage;
