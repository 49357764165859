import React, { useEffect, useState } from "react";

import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import CustomAutoComplete from "components/Common/CustomAutoCompleteCG";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CheckIcon from "@material-ui/icons/Check";
//Custom style
import Axios from "utils/axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { updateLinguistProfile } from "redux/actions/updateProfileAction";
import swal from "sweetalert";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import AllocateAccess from "./AllocateAccess";
import { Button, Header, Tab } from "semantic-ui-react";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import BankDetails from "./BankDetails";
import PersonalDetails from "./PersonalDetails";
import InsuranceDetails from "./InsuranceDetails";
import ProfessionalDetails from "./ProfessionalDetails";
import Qualification from "./Qualification";
import WorkExperience from "./WorkExperience";
import SecurityClearance from "./SecurityClearance";
import OtherDetails from "./OtherDetails";
import INC from "./INC/INC";
import PRC from "./PRC/PRC";
import Lead from "./Lead/Lead";
import Documents from "./Documents/Documents";
import DocumentsNew from "./DocumentsNew/Documents";
import InvitationList from "./InvitationList";
import Action from "./Action";
const ViewLinguist = ({ id }) => {
  const token = Cookies.get("token");
  const [admID, setADMID] = "";
  const dispatch = useDispatch();
  const classes = useEmployeeStyles();
  const { data } = useSelector((state) => state.updateProfileReducer);
  const [show, setShow] = useState(false);
  const [linDetails, setLinDetails] = useState("");
  const [state, setState] = React.useState({
    reload: false,
  });
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });

  const panes = [
    {
      menuItem: "Access",
      render: () => (
        <Tab.Pane attached={false}>
          <AllocateAccess id={id} load={getUnverifiedLinguistProfile} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Personal Details",
      render: () => (
        <Tab.Pane attached={false}>
          <PersonalDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Bank Details",
      render: () => (
        <Tab.Pane attached={false}>
          <BankDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Due diligence",
      render: () => (
        <Tab.Pane attached={false}>
          <InsuranceDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Professional Details",
      render: () => (
        <Tab.Pane attached={false}>
          <ProfessionalDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "General Qualification",
      render: () => (
        <Tab.Pane attached={false}>
          <Qualification />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Work Experience",
      render: () => (
        <Tab.Pane attached={false}>
          <WorkExperience />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Security Clearance",
      render: () => (
        <Tab.Pane attached={false}>
          <SecurityClearance />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Old Documents",
      render: () => (
        <Tab.Pane attached={false}>
          <Documents id={id} linRefID={linDetails?.id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Documents",
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentsNew id={id} linRefID={linDetails?.id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Other details / Notes",
      render: () => (
        <Tab.Pane attached={false}>
          <OtherDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "INCs",
      render: () => (
        <Tab.Pane attached={false}>
          <INC id={id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "PRCs",
      render: () => (
        <Tab.Pane attached={false}>
          <PRC id={id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Leads",
      render: () => (
        <Tab.Pane attached={false}>
          <Lead id={id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Invited Linguist",
      render: () => (
        <Tab.Pane attached={false}>
          <InvitationList id={id} />
        </Tab.Pane>
      ),
    },
  ];

  const getUnverifiedLinguistProfile = async (e) => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/view-linguist/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(updateLinguistProfile(response?.data));
      setLinDetails(response?.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getUnverifiedLinguistProfile();
    loadList();
  }, [state.reload]);

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "contact_group",
        value,
      })
    );
  };

  const handleUpload = async (e) => {
    document.querySelector("#file").click();
  };

  const handleFiles = async (e) => {
    if (e.target.files.length) {
      let fd = new FormData();

      fd.append("photo", e.target.files[0]);

      try {
        const response = await Axios.put(
          `/api/manager-admin/update-linguist/${data._id}`,
          fd,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        swal({
          icon: "success",
          text: "Thank you for updating profile",
          buttons: false,
        }).then(function () {
          window.location = `/admin/view-linguist-profile/${id}`;
        });
      } catch (error) {
        console.log(error.response);
        if (error.response?.data) {
          swal({
            icon: "error",
            text: error?.response?.data?.error,
            buttons: false,
            dangerMode: true,
          });
        }
      }
    }
  };
  const completeReg = async () => {
    setEdit({ ...edit, loader: true });

    try {
      const response = await Axios.put(
        `/api/manager-admin/complete-linguist-registration`,
        {
          Country: linDetails?.Country,
          City: linDetails?.City,
          source_language: linDetails?.source_language[0],
          target_language: linDetails?.target_language[0],
          email: linDetails?.email,
          Lastname: linDetails?.LastName,
          Firstname: linDetails?.FirstName,
          LinID: linDetails?.id,
          _id: linDetails?._id,
          //  id:,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEdit({ ...edit, value: false, loader: false });
      setShow(false);
      setState({ ...state, reload: Math.random() });

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };
  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    setShow(false);
    setState({ ...state, reload: Math.random() });
    let fd = new FormData();

    fd.append("contact_group", data.contact_group);

    try {
      const response = await Axios.put(
        `/api/manager-admin/update-linguist/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  const [contact, setContact] = useState([]);
  const loadList = async () => {
    try {
      const response = await Axios.get(`/api/list/LIN-contact-group`);
      setContact(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const CONTACT_GROUP = contact?.map(
    (CONTACT) => `${CONTACT?.contact_group_name}`
  );

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
    >
      <div className={classes.header}>
        <GridItem xs={6} sm={6} md={12} lg={2} xl={2}>
          <Card className={classes.profile_photo_card}>
            <CardBody>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={handleUpload}
                className="image"
              >
                <img
                  src={`${process.env.REACT_APP_BACKEND_API}/manager-admin/photo-linguist/${data._id}`}
                  style={{ maxHeight: "auto", maxWidth: "100%" }}
                  alt="profile photo"
                />
              </div>
              <input
                onChange={handleFiles}
                type="file"
                accept="image/png, image/jpeg"
                id="file"
                style={{ display: "none" }}
              />
            </CardBody>
          </Card>
        </GridItem>

        <div className={classes.about}>
          <br />
          <Header size="large">
            {data.title} {data.FirstName} {data.LastName}
          </Header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Profile ID:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.id}
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              LC ID Card Code:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data?.username}
            </Header>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Position:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.position}
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Registered as:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data?.other_service ? data?.other_service : data?.service}
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Email:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.email}
            </Header>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Mobile number:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.mobile_no}
            </Header>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Source language(s):{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.source_language} {data.source_language_1}{" "}
              {data.source_language_2}
            </Header>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Target language(s):{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.target_language} {data.target_language_1}{" "}
              {data.target_language_2}
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.approvalRequest}
              <a
                target="_blank"
                href={`/linguist/public/profile/${
                  data?.source_language?.toString().split(" ")[0]
                }/${data?.target_language?.toString().split(" ")[0]}/${
                  data?._id
                }`}
              >
                {" "}
                View Public Profile
              </a>{" "}
              |
              <a
                href={`/admin/protected/public/profile/${
                  data?.source_language?.toString().split(" ")[0]
                }/${data?.target_language?.toString().split(" ")[0]}/${
                  data?._id
                }`}
              >
                {" "}
                Preview (Restricted)
              </a>{" "}
              |<a href={`/admin/view-public-profile/${data?._id}`}> Approval</a>
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0", color: "#949f8c" }} size="medium">
              Old Profile ID:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="#949f8c"
              size="medium"
            >
              <strike style={{ color: "#949f8c" }}>{data?.old_id}</strike>
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <LocationOnIcon />
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.Country}
            </Header>
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            {show ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                {" "}
                <CustomAutoComplete
                  onChange={handleAutoComplete}
                  name="contact_group"
                  label="Contact group"
                  width="100%"
                  data={CONTACT_GROUP}
                />
                {CONTACT_GROUP?.length > 0 && (
                  <CheckIcon
                    color="secondary"
                    onClick={handleUpdate}
                    style={{
                      cursor: "pointer",
                      fontSize: "2rem",
                      color: "green",
                    }}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "baseline",
                  flexWrap: "wrap",
                }}
              >
                <>
                  <Button
                    disabled={!linDetails}
                    color="purple"
                    onClick={() => setShow(true)}
                  >
                    Edit Contact Group
                  </Button>
                  {linDetails?.profile_update ? (
                    <Button disabled>Full Registration Completed By {linDetails?.profile_updated_by} </Button>
                  ) : (
                    <Button
                      disabled={!linDetails}
                      color="green"
                      onClick={() => completeReg()}
                    >
                      Complete Full Registration
                    </Button>
                  )}
                </>
                <Action
                  name={linDetails?.FirstName + " - " + linDetails?.LastName}
                  id={linDetails?._id}
                  ID={linDetails?.id}
                  linDetails={linDetails}
                />
              </div>
            )}
          </div>
          <Header
            style={{ margin: "0", marginLeft: "5px", marginRight: "5px" }}
            color="grey"
            size="medium"
          >
            {linDetails?.contact_group || ""}
          </Header>
        </div>
      </div>

      <div>
        <div>
          <Tab
            className={classes.menu}
            menu={{ secondary: true, pointing: true }}
            panes={panes}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewLinguist;
