import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Material Core Components
import Typography from "@material-ui/core/Typography";

// Material Icons

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import PageFour from "./PageFour";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomFormControl from "components/Common/CustomFormControl";
import { Progress } from "semantic-ui-react";

import LandingPageLayout from "layout/LandingPageLayout";
import CustomAvatar from "components/Common/CustomAvatar";

const ClientAcc = () => {
  const { count } = useSelector((state) => state.caoReducer);
  const classes = useEmployeeStyles();

  const selectedForm = count;

  return (
    <LandingPageLayout>
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    Billing Account Application Form
                  </Typography>
                  <Typography style={{ marginTop: "5px" }} variant="subtitle1">
                    Already Registered?{" "}
                    <Link color="primary" to="/account-client-login">
                      Login{" "}
                    </Link>
                  </Typography>
                  <Typography style={{ marginTop: "5px" }} variant="subtitle1">
                    I  wish to register as a{" "}
                    <Link color="primary" to="/customer-registration">
                      Cash Client{" "}
                    </Link>
                  </Typography>

                  <Typography variant="subtitle2">Page {count} of 4</Typography>
                  {selectedForm === 1 && (
                    <Typography variant="subtitle2">
                      <b>Business Organisation Details</b>
                    </Typography>
                  )}
                  {selectedForm === 2 && (
                    <Typography variant="subtitle2">
                      <b> Business Organisation Legal Repetitive / Director</b>
                    </Typography>
                  )}
                  {selectedForm === 3 && (
                    <Typography variant="subtitle2">
                      <b> Booking and Order Estimation</b>
                    </Typography>
                  )}
                  {selectedForm === 4 && (
                    <Typography variant="subtitle2">
                      <b>Additional Information</b>
                    </Typography>
                  )}
                </div>
                {selectedForm === 1 && <PageOne />}
                {selectedForm === 2 && <PageTwo />}
                {selectedForm === 3 && <PageThree />}
                {selectedForm === 4 && <PageFour />}
                <CustomFormControl width="73.33%">
                  <Progress
                    color="purple"
                    percent={(selectedForm / 4) * 100}
                    active
                    size="small"
                  />
                </CustomFormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </LandingPageLayout>
  );
};

export default ClientAcc;
