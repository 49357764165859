import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import { Button, Modal } from 'semantic-ui-react'
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import MyNotes from "components/Admin/Dashboard/Profile/MyNotes"
import MyNotesForm from "components/Admin/Dashboard/FourthSegment/MyNotes"


const Archive = () => {
  const classes = useDashboardStyles();
  const [open, setOpen] = React.useState(false)

  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>My Notes / To Do List</p>
              </CardHeader>
            </Card>

            {/* start colleague note form modal */}
            <Card>
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                trigger={<Button color="purple" icon='add' content='My Note / Do List' />}

              >
                <Modal.Content scrolling>
                  <Modal.Description>
                    <MyNotesForm />
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </Card>

            <Card>
              <MyNotes />
            </Card>
          </GridItem>
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default Archive;
