// Material Components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    height: "200px",
    justifyContent: "flex-end",

    "& .MuiButton-root": {
      marginTop: "20px",
    },
  },
};

const useStyles = makeStyles(styles);

const NoteInput = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        id="standard-textarea"
        label="Type here..."
        placeholder="Write your note here..."
        multiline
      />
      <Button color="primary" variant="outlined" startIcon={<SaveIcon />}>
        Save Note
      </Button>
    </div>
  );
};

export default NoteInput;
