import React, { Fragment } from "react";

import SppoLogin from "components/Sppo/Login/SppoLogin";

const SPPOLoginPage = () => {
  return (
    <Fragment>
      <SppoLogin />
    </Fragment>
  );
};

export default SPPOLoginPage;
