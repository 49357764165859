// Custom Components
import CommonCard from "components/Common/CommonCard";
import Axios from "utils/axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid } from "semantic-ui-react";

const NewsAndNotice = () => {
  const [values, setValues] = useState({ reload: false });

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `/api/account-client/view-bac-news-notice`
      );
      setValues(response?.data);
    } catch (error) { }
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <div style={{ margin: "5px" }}>
      <CommonCard title="News & Notice">
        {values.length > 0 ? (
          <>
            {values?.map((list) => (
              <CommonCard>
                <marquee scrollamount="2" direction="left">
                  <h5 style={{ textAlign: "center" }}>{list.news_notice} </h5>{" "}
                </marquee>
                <p style={{ textAlign: "center" }}>
                  {moment(list.createdAt).format("DD-MMM-YYYY : HH:mm")}{" "}
                </p>
              </CommonCard>
            ))}{" "}
          </>
        ) : null}
      </CommonCard>
    </div>
  );
};

export default NewsAndNotice;
