import { useState } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import { Button, Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import CustomInput from "components/Common/CustomInput";
import CustomCaptcha from "components/Common/CustomCaptcha";
import TermsAndConditions from "components/Auth/EmployeeRegistration/TermsAndConditions";
import swal from "sweetalert";
import CustomAvatar from "components/Common/CustomAvatar";
import { GENDER_DATA, EMAIL_REGEX, PASS_REGEX } from "data";
import defaultAxios from "utils/axios";
import CustomDate from "components/Common/CustomDate";
import CustomRadio from "components/Common/CustomRadio";
import CustomPassword from "components/Common/CustomPassword";
import { useParams } from "react-router";
import Cookies from "js-cookie";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomPhone from "components/Common/CustomPhone";

const SubscribeHere = () => {
  const { id } = useParams();
  const classes = useLandingpageStyles();
  const [captcha, setCaptcha] = useState();
  const [checked, setChecked] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const [notRegex, setNotRegex] = useState(false);
  const [notOtherValid, setOtherNotValid] = useState(false);
  const [data, setData] = useState({
    title: "",
    FirstName: "",
    LastName: "",
    mobile_no: "",
    phone_no: "",
    email: "",
    other_email: "",
    Birthday: "",
    Sex: "",
    password: "",
    confirm_password: "",
  });

  const {
    title,
    FirstName,
    LastName,
    email,
    other_email,
    mobile_no,
    phone_no,
    Birthday,
    Sex,
    password,
    confirm_password,
  } = data;

  const handleEmailChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleOtherEmailChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setOtherNotValid(false);
    } else {
      setOtherNotValid(true);
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;

    setData({ ...data, password: value });
    if (value !== "" && PASS_REGEX.test(value)) {
      setNotRegex(false);
    } else {
      setNotRegex(true);
    }
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };
  const handleCheck = (value) => {
    setChecked(value);
  };

  const handlePhone = (value) => {
    setData({ ...data, phone_no: value });
  };
  const handleMobile = (value) => {
    setData({ ...data, mobile_no: value });
  };

  const handleDateChange = (date) => {
    setData({ ...data, Birthday: date });
  };

  const handleSubmit = async () => {
    const sendData = {
      title: data.title || "-",
      FirstName: data.FirstName || "-",
      LastName: data.LastName || "-",
      email: data.email || "-",
      mobile_no: data.mobile_no || "-",
      phone_no: data.phone_no || "-",
      other_email: data.other_email || "-",
      Birthday: data.Birthday || "-",
      Sex: data.Sex || "-",
      password: data.password,
    };
    try {
      Cookies.set("bacID", id);
      localStorage.setItem("bacID", JSON.stringify(id));
      const response = await defaultAxios.post(
        "/api/accountclient/user/pre-signup",
        sendData
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/";
      });
      setData({
        ...data,
        title: "",
        FirstName: "",
        LastName: "",
        email: "",
        mobile_no: "",
        phone_no: "",
        other_email: "",
        password: "",
        confirm_password: "",
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <LandingPageLayout>
      <GridContainer style={{ justifyContent: "center", margin: "2rem 0" }}>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardBody>
              <div style={{ textAlign: "center" }}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  Billing Account Client Additional User
                </Typography>
              </div>
            </CardBody>
            <CardBody>
              <CustomInput
                label="Title"
                width="100%"
                placeholder="Title"
                name="title"
                onChange={handleChange}
                value={title}
                required
              />
              <CustomInput
                label="First Name(s)"
                width="100%"
                placeholder="First Name"
                name="FirstName"
                onChange={handleChange}
                value={FirstName}
                required
              />
              <CustomInput
                label="Last Name"
                width="100%"
                placeholder="Last Name"
                name="LastName"
                value={LastName}
                onChange={handleChange}
                required
              />
              <CustomDate
                onChange={handleDateChange}
                value={Birthday}
                name="Birthday"
                label="Date of Birth"
                width="100%"
                required
              />
              <CustomRadio
                onChange={handleChange}
                value={Sex}
                name="Sex"
                data={GENDER_DATA}
                label="Gender"
                width="100%"
              />

              <CustomPhone
                label="Mobile Number"
                onChange={handleMobile}
                placeholder="Mobile number"
                value={mobile_no}
                name="mobile_no"
              />

              <br />
              <CustomPhone
                label="Phone Number"
                onChange={handlePhone}
                placeholder="Phone number"
                value={phone_no}
                name="phone_no"
              />
              <br />

              <CustomInputSingle
                label="Email"
                width="100%"
                placeholder="Email"
                name="email"
                onChange={handleEmailChange}
                value={email}
                required
              />
              {notValid && (
                <small style={{ color: "red" }}>
                  enter valid email address
                </small>
              )}
              <br />

              <CustomInputSingle
                label="Other Email"
                width="100%"
                placeholder="Other Email"
                name="other_email"
                onChange={handleOtherEmailChange}
                value={other_email}
              />
              {other_email && notOtherValid && (
                <small style={{ color: "red" }}>
                  enter valid email address
                </small>
              )}
              <br />

              <CustomPassword
                onChange={handlePasswordChange}
                label="Password"
                width="100%"
                name="password"
                value={password}
                notRegex={notRegex}
                required
              />
              <CustomPassword
                onChange={handleChange}
                label="Confirm password"
                width="100%"
                name="confirm_password"
                value={confirm_password}
                validation={password !== confirm_password}
                required
              />
              <TermsAndConditions
                handleCheck={handleCheck}
                text="By submitting this form I agree to accept the invitation to be an additional user of my employer / company, I also agree with LC T&C and Privacy Policy.
                "
              />
              <br />
              <p style={{ color: "red" }}>
                (Please note that newly registered user must verify their email
                address from the link sent to their email. Parent account holder
                must also give login access once email verified by the new
                user.)
              </p>
              <CustomCaptcha onChange={handleCaptcha} />

              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  className={`${classes.button}`}
                  color="primary"
                  variant="contained"
                  disabled={
                    !checked ||
                    !captcha ||
                    !FirstName ||
                    !LastName ||
                    !Birthday ||
                    !email ||
                    !password ||
                    !confirm_password ||
                    password !== confirm_password ||
                    (other_email && notOtherValid) ||
                    notRegex ||
                    notValid
                  }
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default SubscribeHere;
