import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
// Custom Components
import SPPORegistration from "components/Auth/SPPORegistration/SPPORegistration";
import LandinPageLayout from "layout/LandingPageLayout";
import Axios from "axios";



const SPPORegistrationPage = () => {
  const { id } = useParams();

  const [sppoID, setSPPOID] = useState(null)

  const getDetails = async (e) => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/sppo/only-read-ID/${id}`);

    setSPPOID(response?.data);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <LandinPageLayout>
      <SPPORegistration id={sppoID?.agent_sppo ? sppoID?.agent_sppo : id} genID={sppoID?.id}/>
    </LandinPageLayout>
  );
};

export default SPPORegistrationPage;
