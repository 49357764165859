import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomAvatar from "components/Common/CustomAvatar";
import Axios from "axios";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { changeInitRegInput } from "redux/actions/irAction";
import {
  PRIMARY_SOURCE_LANG,
  PRIMARY_TARGET_LANG,
  RARE_REGEX,
  LEVEL_REGEX,
} from "data";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

const SearchMultiAllLin = () => {
  const classes = useLandingpageStyles();
  const tableClasses = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.irReducer);

  const [allValues, setAllValues] = useState({ reload: false });

  const {
    source_language,
    source_language_1,
    source_language_2,
    target_language,
    target_language_1,
    target_language_2,
  } = data;

  // const handleChange = (e) => {
  //   const { name, value, checked } = e.target;
  //   const inputData = {
  //     name,
  //     value: value,
  //   };
  //   dispatch(changeInitRegInput(inputData));
  // };

  const handleChangeS = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "source_language",
        value,
      })
    );
  };
  const handleChangeS1 = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "source_language_1",
        value,
      })
    );
  };
  const handleChangeS2 = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "source_language_2",
        value,
      })
    );
  };

  const handleChangeT = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "target_language",
        value,
      })
    );
  };
  const handleChangeT1 = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "target_language_1",
        value,
      })
    );
  };
  const handleChangeT2 = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "target_language_2",
        value,
      })
    );
  };

  // const handleAutoCompleteCO = (e, value) => {
  //   dispatch(
  //     changeInitRegInput({
  //       name: "Country",
  //       value,
  //     })
  //   );
  // };

  // const handleSExpertise = (value) => {
  //   if (source_language?.includes("R")) {
  //     return value.match(RARE_REGEX);
  //   } else {
  //     return value.match(LEVEL_REGEX);
  //   }
  // };
  // const handleTExpertise = (value) => {
  //   if (target_language?.includes("R")) {
  //     return value.match(RARE_REGEX);
  //   } else {
  //     return value.match(LEVEL_REGEX);
  //   }
  // };

  const FindMultiAllLinguist = async () => {
    Axios.all([
      Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find/multi-registered-linguists`,
        {
          source_language: source_language,
          source_language_1: source_language_1,
          source_language_2: source_language_2,
          target_language: target_language,
          target_language_1: target_language_1,
          target_language_2: target_language_2,
        }
      ),
      Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find/multi-unregistered-linguists`,
        {
          source_language: source_language,
          source_language_1: source_language_1,
          source_language_2: source_language_2,
          target_language: target_language,
          target_language_1: target_language_1,
          target_language_2: target_language_2,
        }
      ),
    ]).then(
      Axios.spread((registeredlin, unregisteredlin) => {
        const history = registeredlin.data.concat(unregisteredlin.data);
        setAllValues(history);
      })
    );
  };

  return (
    <GridContainer
      justify="center"
      className={classes.container}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
        <Card>
          <CardBody>
            <div className={`${classes.textCenter} ${classes.title}`}>
              <CustomAvatar />
              <Typography
                component="h1"
                variant="h5"
                style={{ textAlign: "center" }}
              >
                Search Registered and Unregistered Linguist
              </Typography>
            </div>

            <form noValidate autoComplete="off">
              <CustomAutoSelect
                required
                onChange={handleChangeS}
                label="Source/Native language(1)"
                data={PRIMARY_SOURCE_LANG.filter(
                  (value) => value !== target_language
                )}
                width="100%"
                name="source_language"
                value={source_language}
              />
              <CustomAutoSelect
                onChange={handleChangeS1}
                label="Source/Native language(2)"
                data={PRIMARY_SOURCE_LANG.filter(
                  (value) => value !== target_language_1
                )}
                width="100%"
                name="source_language_1"
                value={source_language_1}
              />
              <CustomAutoSelect
                onChange={handleChangeS2}
                label="Source/Native language(3)"
                data={PRIMARY_SOURCE_LANG.filter(
                  (value) => value !== target_language_2
                )}
                width="100%"
                name="source_language_2"
                value={source_language_2}
              />

              <CustomAutoSelect
                required
                onChange={handleChangeT}
                label="Target language(1)"
                data={PRIMARY_TARGET_LANG.filter(
                  (value) => value !== source_language
                )}
                width="100%"
                name="target_language"
                value={target_language}
              />
              <CustomAutoSelect
                onChange={handleChangeT1}
                label="Target language(2)"
                data={PRIMARY_TARGET_LANG.filter(
                  (value) => value !== source_language_1
                )}
                width="100%"
                name="target_language(2)"
                value={target_language_1}
              />
              <CustomAutoSelect
                onChange={handleChangeT2}
                label="Target language(3)"
                data={PRIMARY_TARGET_LANG.filter(
                  (value) => value !== source_language_2
                )}
                width="100%"
                name="target_language_2"
                value={target_language_2}
              />

              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  className={`${classes.button}`}
                  color="primary"
                  variant="contained"
                  onClick={FindMultiAllLinguist}
                  disabled={!source_language || !target_language}
                >
                  Search All Linguist
                </Button>
              </div>
              {/* )} */}
            </form>
          </CardBody>
        </Card>
      </GridItem>

      <>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <br />
          <br />
          <b style={{ color: "green" }}>* Verified Linguist,</b>{" "}
          <b style={{ color: "blue" }}>Unverified Linguist,</b>{" "}
          <b style={{ color: "red" }}> Suspend Linguist,</b>
          <b style={{ color: "lightsalmon" }}> Unregistered Linguist</b>
          <br />
          <br />
          <b style={{ color: "purple" }}>
            {" "}
            {allValues?.length}{" "}
            {allValues?.length > 1 ? "Linguists Found" : "Linguist Found"}
          </b>
          <br />
          <br />
          <Paper className={tableClasses.root}>
            <TableContainer className={tableClasses.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Linguist ID</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Source / Native language</TableCell>
                    <TableCell>Target language</TableCell>
                    <TableCell>Target language Level</TableCell>
                    <TableCell>Town / City</TableCell>
                    <TableCell>Post / Zip Code</TableCell>
                    <TableCell>Country</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allValues?.length > 0 ? (
                    <>
                      {allValues
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((list, i) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={i}
                              style={
                                list?.verified == true
                                  ? {
                                      backgroundColor: "lightgreen",
                                      color: "white",
                                    }
                                  : list?.verified == false
                                  ? { backgroundColor: "#4FAEF4" }
                                  : list?.suspend == true
                                  ? { backgroundColor: "#F7624A" }
                                  : list?.initial_reg == true
                                  ? { backgroundColor: "lightsalmon" }
                                  : null
                              }
                            >
                              <TableCell>
                                {list?.LastName ? (
                                  <Link
                                    to={`/admin/view-linguist-profile/${list._id}`}
                                  >
                                    {list.id}
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/admin/view-unregistered-linguist-client/${list._id}`}
                                  >
                                    {list?.id}
                                  </Link>
                                )}
                              </TableCell>

                              <TableCell>
                                {list?.LastName
                                  ? list?.LastName
                                  : list?.last_name}
                              </TableCell>
                              <TableCell>
                                {list?.email
                                  ? list?.email
                                  : list?.primary_email}
                              </TableCell>
                              <TableCell>{list?.mobile_no}</TableCell>
                              <TableCell>{list?.phone_no}</TableCell>
                              <TableCell>
                                {list?.source_language}
                                <br />
                                {list?.source_language_1
                                  ? list?.source_language_1
                                  : "N/A"}{" "}
                                <br />
                                {list?.source_language_2
                                  ? list?.source_language_2
                                  : "N/A"}
                              </TableCell>
                              <TableCell>
                                {list?.target_language}
                                <br />
                                {list.target_language_1
                                  ? list.target_language_1
                                  : "N/A"}
                                <br />
                                {list.target_language_2
                                  ? list.target_language_2
                                  : "N/A"}
                              </TableCell>
                              <TableCell>
                                {list?.target_language_level
                                  ? list?.target_language_level
                                  : "N/A"}
                                <br />
                                {list?.target_language_level_1
                                  ? list?.target_language_level_1
                                  : "N/A"}
                                <br />
                                {list?.target_language_level_2
                                  ? list?.target_language_level_2
                                  : "N/A"}
                              </TableCell>
                              <TableCell>
                                {list?.City ? list?.City : list?.city}
                              </TableCell>
                              <TableCell>
                                {list?.PostalCode
                                  ? list?.PostalCode
                                  : list?.postal_code}
                              </TableCell>
                              <TableCell>
                                {list?.Country ? list?.Country : list?.country}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </>
                  ) : (
                    "No Linguist Found"
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={allValues?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </GridItem>
      </>
    </GridContainer>
  );
};

// EBBlxUJe_
export default SearchMultiAllLin;
