import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Material Core Components
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Material Icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";

import CustomAvatar from "components/Common/CustomAvatar";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import { Link } from "react-router-dom";

const ViewOTClients = () => {
  const { count } = useSelector((state) => state.transReducer);
  const classes = useEmployeeStyles();
  const history = useHistory();

  const selectedForm = count;

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <Avatar className={classes.avatar}>
                  <CustomAvatar />
                </Avatar>
                {/* <Typography component="h1" variant="h5">
                  {history.location.pathname.includes("linguist")
                    ? "Order Inhouse Translation"
                    : "Order Translation"}
                </Typography>
                <Typography variant="subtitle2"></Typography>
              </div>
              <div className={classes.notes}>
                <Typography variant="h6" gutterBottom>
                  Note to the customer
                </Typography>
                <Typography variant="body2" gutterBottom>
                  You order most types of translation via this order form
                  however there are some limitation for large volume of work and
                  if you are looking for bargain you can use our quote to
                  compare prices. If you have more than document please order
                  them separately and indicate any requirement of formatting and
                  certification. For transcription and subtitling work please
                  use quotation form - <Link to="/quoterequest-form">here</Link>
                </Typography> */}
              </div>
              {selectedForm === 1 && <FormTwo />}
              {selectedForm === 2 && <FormThree />}
              {/* {selectedForm === 3 && <FormThree />} */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ViewOTClients;
