import React from "react";
import { useSelector } from "react-redux";

// Material Core Components
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Material Icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import PageOne from "./PageOne";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import LandingPageLayout from "layout/LandingPageLayout";
import CustomAvatar from "components/Common/CustomAvatar";

const ACQuoteRqst = () => {
  const { count } = useSelector((state) => state.ACquoterqstReducer);
  const classes = useEmployeeStyles();

  const selectedForm = count;

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  BAC Quotation Request Form
                </Typography>
                <Typography variant="subtitle2"></Typography>
              </div>
              <div className={classes.notes}>
                <Typography variant="h6" gutterBottom>
                  Note to the customer
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Matching linguist profile Your quote request will be sent to
                  all LC linguists. Please check your dashboard for their
                  responses and accepting a quote before expiry.
                </Typography>
              </div>
              {selectedForm === 1 && <PageOne />}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ACQuoteRqst;
