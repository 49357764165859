import React, { useState, useEffect } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import Axios from "axios";
import ReactHtmlParser from "react-html-parser";

import { Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";

const ServiceAgreement = () => {
  const classes = useLandingpageStyles();

  const [TandC, setTandC] = useState(null);

  const getTandC = async (e) => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/get-latest-terms-and-conditions`
    );

    setTandC(response?.data);
  };
  useEffect(() => {
    getTandC();
  }, []);

  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 0 4rem 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card style={{ padding: "5rem" }} className={classes.clientPortal}>
            {TandC ? (
              <CardBody>
                <Typography
                  style={{ fontWeight: "700", textAlign: "center" }}
                  paragraph
                  variant="h5"
                >
                  {TandC?.title}
                </Typography>
                <div style={{ paddingTop: "10px", paddingLeft: "15px" }}>
                  <Typography
                    style={{ fontWeight: "700", textAlign: "justify" }}
                    paragraph
                    variant="h6"
                  >
                    {ReactHtmlParser(TandC?.content)}
                  </Typography>
                </div>
              </CardBody>
            ) : (
              <CardBody>
                <Typography
                  style={{ fontWeight: "700", textAlign: "center" }}
                  paragraph
                  variant="h5"
                >
                  Terms and Conditions temporarily unavailable, please contact
                  Admin Office.
                </Typography>
              </CardBody>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default ServiceAgreement;
