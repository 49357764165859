import { v4 as uuidv4 } from "uuid";

import {
  DESCRIPTION_QUOTATION,
  ADD_DESCRIPTION,
  REMOVE_DESCRIPTION,
  EXPENSES_QUOTATION,
  ADD_EXPENSES,
  REMOVE_EXPENSES,
  PERCENT_CHANGE,
} from "redux/actions/actionTypes";

const INITIAL_STATE = {
  description: [
    {
      id: uuidv4(),
      service: "",
      quantity: "",
      rate: "",
      amount: "",
    },
  ],
  expenses: [
    {
      id: uuidv4(),
      expense: "",
      quantity: "",
      rate: "",
      amount: "",
    },
  ],
  percents: {
    service: "10",
    discount: "0",
    vat: "20",
  },
};

const qutationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PERCENT_CHANGE:
      return {
        ...state,
        percents: {
          ...state.percents,
          [payload.name]: payload.value,
        },
      };
    case ADD_DESCRIPTION:
      const newDesc = {
        id: uuidv4(),
        service: "",
        quantity: "",
        rate: "",
        amount: "",
      };
      return {
        ...state,
        description: [...state.description, newDesc],
      };
    case REMOVE_DESCRIPTION:
      return {
        ...state,
        description: state.description.filter((desc) => desc.id !== payload.id),
      };
    case DESCRIPTION_QUOTATION:
      return {
        ...state,
        description: state.description.map((desc) => {
          if (desc.id === payload.id) {
            return payload;
          }
          return desc;
        }),
      };

    case ADD_EXPENSES:
      const newExp = {
        id: uuidv4(),
        expense: "",
        quantity: "",
        rate: "",
        amount: "",
      };
      return {
        ...state,
        expenses: [...state.expenses, newExp],
      };
    case REMOVE_EXPENSES:
      return {
        ...state,
        expenses: state.expenses.filter((desc) => desc.id !== payload.id),
      };
    case EXPENSES_QUOTATION:
      return {
        ...state,
        expenses: state.expenses.map((desc) => {
          if (desc.id === payload.id) {
            return payload;
          }
          return desc;
        }),
      };

    default:
      return state;
  }
};

export default qutationReducer;
