import React, { } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Axios from "axios";
import swal from "sweetalert";
import { changeCrInput } from "redux/actions/crAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AccordionSummary } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CustomInputSingle from "components/Common/CustomInputSingle";
import TextField from "@material-ui/core/TextField";
import Cookies from "js-cookie";
import DocumentList from "./Document_list"
import DriveLinkList from "../DriveLink_list"

const Items = ({ values }) => {
    const { id } = useParams();
    const token = Cookies.get("EMPtoken");
    const classes = useDashboardStyles();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.crReducer);
    const [edit, setEdit] = React.useState({
        loader: false,
    });

    const { link, password, instructions } = data

    const handleChange = (e) => {
        const { name, value } = e.target;
        const inputData = {
            name,
            value: value,
        };
        dispatch(changeCrInput(inputData));
    };



    const uploadDriveLink = async (e) => {
        let formData = new FormData();

        formData.append("link", link || "");
        formData.append("password", password || "");
        formData.append("instructions", instructions || "");


        setEdit({ ...edit, loader: true });
        try {
            const response = await Axios.post(
                `${process.env.REACT_APP_BACKEND_API}/employee/Upload_Translation_Delivery_DriveLink/assignmentID/${id}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            swal({
                title: "Success",
                text: `${response?.data?.message}`,
                icon: "success",
                buttons: false,
            })
            setEdit({ ...edit, loader: false });
        } catch (error) {
            if (error.response) {
                swal({
                    title: "Oops!",
                    text: `${error?.response?.data?.error ? error?.response?.data?.error : "Please check your internet connection or if server error then contact Admin Team."}`,
                    icon: "error",
                    buttons: false,
                });
            }
        }
    };


    return (

        <>
            <GridContainer justify="center" className={classes.container} xs={12} sm={12} md={12} lg={12} xl={12}>
                {values?.outgoing ? null :
                    <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Card>
                            <CardHeader
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <p className={classes.cardTitleWhite}>
                                    Translation or Transcript Document Delivery
                                    (please provide drive link, postal record or upload file)

                                </p>
                            </CardHeader>

                            <CardBody>
                                <CustomInputSingle
                                    onChange={handleChange}
                                    value={link}
                                    name="link"
                                    label="Drive Link"
                                    width="100%"
                                    placeholder="Drive Link"
                                />
                                <CustomInputSingle
                                    onChange={handleChange}
                                    value={password}
                                    name="password"
                                    label="Drive Password"
                                    width="100%"
                                    placeholder="Drive Password"
                                />
                                <br />
                                <TextField
                                    onChange={handleChange}
                                    value={instructions}
                                    id="standard-textarea"
                                    label="Instruction or delivery note (max 432ch.)"
                                    placeholder="Access instructions or postal delivery tracking details (e.g. by Royal Mail - GB 1223456 XYZ)"
                                    multiline
                                    rows={4}
                                    inputProps={{ maxLength: 432 }}
                                    name="instructions"
                                    style={{ marginBottom: 15, width: 100 + "%" }}
                                />
                            </CardBody>

                            {edit.loader ? (
                                <h3
                                    style={{ display: "flex", justifyContent: "center" }}
                                >
                                    {edit.loader && (
                                        <div class="ui active inline loader"></div>
                                    )}
                                </h3>
                            ) : (
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <AppBar
                                        position="static"
                                        className={`${classes.button}`}
                                        style={{ width: "100%", height: "100%" }}
                                    >
                                        <Toolbar onClick={uploadDriveLink}>
                                            <h3
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                Upload Translation Delivery
                                                {edit.loader && (
                                                    <div class="ui active inline loader"></div>
                                                )}
                                            </h3>
                                        </Toolbar>
                                    </AppBar>
                                </AccordionSummary>
                            )}

                            <CardBody>
                                <DriveLinkList />
                            </CardBody>

                        </Card>
                    </GridItem>
                }
                <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Card>
                        <CardHeader
                            color="primary"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <p className={classes.cardTitleWhite}>
                                Translation or Transcript Document Delivery
                                (please provide drive link, postal record or upload file)
                            </p>
                        </CardHeader>

                        <CardBody>
                            <DocumentList />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}

export default Items
