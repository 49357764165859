import React, { useState } from "react";
import SppoLayout from "layout/SppoLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import BookingOption from "components/Sppo/ManageInhouse/BookingOption";
import Options from "components/Sppo/ManageInhouse/Options";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import SendInhouseForm from "components/Auth/SendInhouseForm";
import { Button } from "@material-ui/core";
import Link from "@material-ui/core/Link";

const SendInhouseFormPage = () => {
  const classes = useDashboardStyles();
  const [type, setType] = useState("");

  return (
    <SppoLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader
              color="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className={classes.cardTitleWhite}>Send Booking Form</p>
              <IconButton color="inherit">
                <ArrowBackIcon onClick={() => history.goBack()}></ArrowBackIcon>
              </IconButton>
            </CardHeader>
          </Card>
          <Card>
            <CardHeader
              color="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <BookingOption setType={setType} />
            </CardHeader>
          </Card>
          <Card>
            {type !== "" && (
              <CardHeader color="primary">
                <Options type={type} />
              </CardHeader>
            )}
            <CardBody>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() =>
                  history.push("/sppo/add-inhouse-client/registration")
                }
                variant="contained"
                color="primary"
              >
                Filling up the form
              </Button>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() =>
                  history.push("/sppo/send-client-reg-form")
                }
                variant="contained"
                color="primary"
              >
                Send Client Reg Form
              </Button>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() =>
                  history.push("/sppo/add-inhouse-client/removal-request")
                }
                variant="contained"
                color="primary"
              >
                Removal Requests
              </Button>
              <Link href="/delete-my-inc-account-from-sppo" target="_blank">
                {" "}
                <Button color="primary" variant="contained">
                  {" "}
                  Removal Form
                </Button>
              </Link>
            </CardBody>
          </Card>
          <SendInhouseForm />
        </GridItem>
      </GridContainer>
    </SppoLayout>
  );
};

export default SendInhouseFormPage;
