import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import SearchView from "./Search/SearchAssignment";
import Cookies from "js-cookie";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import Moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ translationData }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/private-unconfirm-Translation-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({
        ...values,
        bookingList: translationData
          ? response?.data.filter((item) => item.id === translationData)
          : response?.data,
      });
      translationData && setShow(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-unconfirm-Translation/TranslationID/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });
        // bookingList=[response?.data]
        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <div>
      {!translationData && (
        <SearchView
          load={loadbookingList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          SearchText={SearchText}
          setShow={setShow}
          show={show}
        />
      )}
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                    <Table.HeaderCell>Client ID</Table.HeaderCell>
                    <Table.HeaderCell>Document type</Table.HeaderCell>
                    <Table.HeaderCell>Timezone</Table.HeaderCell>
                    <Table.HeaderCell>Deadline</Table.HeaderCell>
                    <Table.HeaderCell>Source language</Table.HeaderCell>
                    <Table.HeaderCell>Target language</Table.HeaderCell>
                    <Table.HeaderCell>
                      Target language skill level
                    </Table.HeaderCell>
                    <Table.HeaderCell>Estimated word count</Table.HeaderCell>
                    <Table.HeaderCell>Estimate paid</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {bookingList.length > 0 ? (
                  <Table.Body>
                    {bookingList
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list) => (
                        <Table.Row
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={list._id}
                        >
                          <Table.Cell key={list._id} align={list.align}>
                            <Link
                              to={`/linguist/prc-view-translation-assignment/${list._id}`}
                            >
                              {list.id}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>
                            {list.PrivateClientID?.id
                              ? list.PrivateClientID?.id
                              : "N/A"}
                          </Table.Cell>
                          <Table.Cell>{list.document_type}</Table.Cell>
                          <Table.Cell>{list.timezone}</Table.Cell>
                          <Table.Cell>
                            {Moment(list.deadline).format(
                              "DD-MMM-YYYY : HH:mm"
                            )}
                          </Table.Cell>
                          <Table.Cell>{list.source_language}</Table.Cell>
                          <Table.Cell>{list.target_language}</Table.Cell>
                          <Table.Cell>
                            {list.target_linguist_skill_level}
                          </Table.Cell>
                          <Table.Cell>{list.count}</Table.Cell>
                          <Table.Cell>
                            {list?.currency_new?.substring(0, 3)}{" "}
                            {list.estimate}
                          </Table.Cell>
                          <Table.Cell>{list.status}</Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                ) : (
                  "No data found"
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
