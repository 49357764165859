import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";

import SearchIcon from "@material-ui/icons/Search";
import { Button } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "40%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media(max-width:1900px)"]: {
      width: "100%",
      display: "block",
    },
  },
  button: {
    ["@media(max-width:1024px)"]: {
      width: "100%",
      marginBottom: "5px !important",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    ["@media (max-width:768px)"]: {
      display: "none",
    },
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearchInput = ({
  SearchText,
  handleSearch,
  setSearchText,
  load,
  show,
  setShow,
}) => {
  const classes = useStyles();
  const handleLoad = (value) => {
    setSearchText(value);
    if (value == "" || value == undefined) {
      load();
    }
  };
  return (
    <div>
      <Paper
        onClick={(e) => e.preventDefault()}
        component="form"
        className={classes.root}
      >
        <InputBase
          className={classes.input}
          placeholder="Enter Invoice ID"
          onChange={(e) => {
            handleLoad(e.target.value);
          }}
          inputProps={{ "aria-label": "search google maps" }}
          value={SearchText}
          // onChange={(e) => setText(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Button
          className={classes.button}
          onClick={handleSearch}
          color="purple"
        >
          Find an assignment
        </Button>
        <Button
          className={classes.button}
          color="purple"
          onClick={() => setShow(!show)}
        >
          View all assignments
        </Button>
      </Paper>
    </div>
  );
};

export default SearchInput;
