import fetch from "isomorphic-fetch";
import cookie from "js-cookie";

// get cookie
export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};

export const isAuth = () => {
  if (process.browser) {
    const cookieChecked = getCookie("SPPOtoken");
    if (cookieChecked) {
      if (localStorage.getItem("SPPOuser")) {
        return JSON.parse(localStorage.getItem("SPPOuser"));
      } else {
        return false;
      }
    }
  }
};

export const removeInhouseClientRequest = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/sppo/delete-inc-account-request/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

