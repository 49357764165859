import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import ViewSlider from "components/Admin/ControlPanel/CMS/PublicProfileSliders/ViewSlider";

const AssignmentPage = () => {
  const [control_panel, setAdminAccess] = useState(null);
  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAdminAccess(response?.data.control_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  },[]);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {control_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardBody>
                  <ViewSlider />
                </CardBody>
              </Card>
            </GridItem>
          ) : control_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
