import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import { changeCAOInput, updateCAOStep } from "redux/actions/caoAction";
import { CONTACT_ADDR, CURRENT_COUNTRY, GENDER_DATA, EMAIL_REGEX } from "data";
import CustomSelect from "components/Common/CustomSelect";
import CustomRadio from "components/Common/CustomRadio";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomDate from "components/Common/CustomDate";
import CustomPhone from "components/Common/CustomPhone";

const PageTwo = () => {
  const { data } = useSelector((state) => state.caoReducer);
  const dispatch = useDispatch();
  const [notValid, setNotValid] = useState(false);
  const {
    title,
    FirstName,
    LastName,
    Sex,
    position,
    Nationality,
    other_nationalities,
    Birthday,
    contact_address,
    different_AddressLine1,
    different_AddressLine2,
    different_Town,
    different_PostalCode,
    different_CountryRegion,
    different_Country,
    mobile_no,
    other_email,
    date_and_time,
  } = data;

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateCAOStep(3));
  };

  const handleBack = () => {
    dispatch(updateCAOStep(1));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value,
      })
    );
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value,
      })
    );
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleChangeN = (e, value) => {
    dispatch(
      changeCAOInput({
        name: "Nationality",
        value,
      })
    );
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeCAOInput({
        name: "other_nationalities",
        value,
      })
    );
  };

  const handleDateChange = (date) => {
    dispatch(
      changeCAOInput({
        name: "Birthday",
        value: date,
      })
    );
  };

  const handleChangeCO = (e, value) => {
    dispatch(
      changeCAOInput({
        name: "different_Country",
        value,
      })
    );
  };

  const handlePhone = (value) => {
    // inputData({ ...data, mobile_no: value });
    dispatch(
      changeCAOInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const isAllow =
    !title ||
    !FirstName ||
    !LastName ||
    !Birthday ||
    !contact_address ||
    (contact_address.includes("Different") && !different_AddressLine1) ||
    (contact_address.includes("Different") && !different_Town) ||
    (contact_address.includes("Different") && !different_PostalCode) ||
    (contact_address.includes("Different") && !different_CountryRegion) ||
    (contact_address.includes("Different") && !different_Country) ||
    !mobile_no ||
    (notValid && other_email !== "");

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Title"
          name="title"
          value={title}
          required
          placeholder="e.g. Mr, Mrs, Miss, Ms etc"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          width="100%"
          label="First Name (s)"
          //moreInfo= 'Name of the person in charge of account'
          name="FirstName"
          value={FirstName}
          required
          placeholder="First Name(s)"
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Last Name"
          name="LastName"
          value={LastName}
          required
          placeholder="Last Name"
        />
        {/* <CustomRadio
          onChange={handleChange}
          width="100%"
          label="Gender"
          name="Sex"
          value={Sex}
          data={GENDER_DATA}
        /> */}
        <CustomInputSingle
          onChange={handleChange}
          label="Position"
          width="100%"
          name="position"
          value={position}
          placeholder="your position at your Corporation/Company. (if any)"
        />
        {/* <CustomAutoSelect
          onChange={handleChangeN}
          label="Current Nationality"
          width="100%"
          name="Nationality"
          value={Nationality}
          data={CURRENT_COUNTRY}
          
        />
        <CustomAutoComplete
          onChange={handleAutoComplete}
          label="Other nationalities"
          width="100%"
          data={CURRENT_COUNTRY}
          value={other_nationalities}
          placeholder="Choose"
        /> */}
        <CustomDate
          onChange={handleDateChange}
          width="100%"
          label="Date of birth"
          name="Birthday"
          value={Birthday}
          required
        />
        <CustomRadio
          onChange={handleChange}
          label="Contact address"
          moreInfo="If different from business address"
          width="100%"
          data={CONTACT_ADDR}
          name="contact_address"
          value={contact_address}
          required
        />
        {contact_address.includes("Different") ? (
          <React.Fragment>
            <CustomBigInputSingle
              onChange={handleChange}
              label="House number/name, Street name"
              width="100%"
              name="different_AddressLine1"
              value={different_AddressLine1}
              required
              placeholder="e.g. Flat/Building Name, 101 Example Street"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Address line 2"
              width="100%"
              name=" different_AddressLine2"
              value={different_AddressLine2}
              placeholder="Additional Address Information"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Town / City"
              width="100%"
              name="different_Town"
              value={different_Town}
              required
              placeholder="Your Village, Town or City Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Post / Zip Code"
              width="100%"
              name="different_PostalCode"
              value={different_PostalCode}
              required
              placeholder="Your Post Code, Zip Code or Post Office Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="County / Region"
              width="100%"
              name="different_CountryRegion"
              value={different_CountryRegion}
              required
              placeholder="Your County, Division or State Name"
            />
            <CustomAutoSelect
              onChange={handleChangeCO}
              label="Country"
              width="100%"
              name="different_Country"
              value={different_Country}
              data={CURRENT_COUNTRY}
              required
            />
          </React.Fragment>
        ) : null}

        <br />
        <CustomPhone
          label="Contact phone and mobile number *"
          onChange={handlePhone}
          placeholder="Contact phone and mobile number (e.g. +44 1234 567890) *"
          value={mobile_no}
          width="100%"
          name="mobile_no"
          required
        />
        <CustomInputSingle
          onChange={handleEmailChange}
          label="Other email address"
          width="100%"
          name="other_email"
          value={other_email}
          placeholder="e.g. example@example.com"
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <CustomInputSingle
          onChange={handleChange}
          label="Preferred date and time for contacting"
          width="100%"
          name="date_and_time"
          value={date_and_time}
          placeholder="e.g. 02/11/2020 : 14:57"
        />

        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton disabled={isAllow} text="Next" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default PageTwo;
