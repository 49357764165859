import React, { useState } from "react";
import { Button, Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomAvatar from "components/Common/CustomAvatar";
import Axios from "utils/axios";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import swal from "sweetalert";

const SubscribeHere = () => {
  const classes = useLandingpageStyles();
  const [values, setValues] = useState({ reload: false });

  const [data, setData] = React.useState({
    linID: "",
  });

  const { linID } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const FindLinguist = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-linguist-documents/linID`,
        {
          linID: linID,
        }
      );
      setValues(response?.data);
      setData({ ...data, linID: "" });
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error.response?.data}`,
        icon: "error",
        buttons: false,
      });
    }
  };


  const movePassportDocument = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-passport-to-new-table/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMovePassportDocument = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          movePassportDocument(value);
          break;
        default:
          break;
      }
    });
  };


  const moveAddressDocument = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-proof-of-address-to-new-table/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveAddress = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          moveAddressDocument(value);
          break;
        default:
          break;
      }
    });
  };


  const MoveRelevantDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-relevent-document/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveRelevantDoc = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveRelevantDoc(value);
          break;
        default:
          break;
      }
    });
  };

  const MoveInsuranceDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-insurance-document/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveInsuranceDoc = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveInsuranceDoc(value);
          break;
        default:
          break;
      }
    });
  };

  const MoveInterpretingDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-interpreting-document/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveInterpretingDoc = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveInterpretingDoc(value);
          break;
        default:
          break;
      }
    });
  };

  const MoveTranslationDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-translation-document/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveTranslationDoc = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveTranslationDoc(value);
          break;
        default:
          break;
      }
    });
  };

  const MovePsiDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-psipst-document/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMovePsiDoc = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MovePsiDoc(value);
          break;
        default:
          break;
      }
    });
  };

  const MoveOtherDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-other-experience-document/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveOtherDoc = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveOtherDoc(value);
          break;
        default:
          break;
      }
    });
  };


  const MoveWorkDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-work-reference-document/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveWorkDoc = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveWorkDoc(value);
          break;
        default:
          break;
      }
    });
  };

  const MoveRelevantCertificate = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-relevent-certificate-document/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveRelevantCertificate = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveRelevantCertificate(value);
          break;
        default:
          break;
      }
    });
  };

  const MoveSecurityDoc1 = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-security-clearence-document1/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveSecurityDoc1 = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveSecurityDoc1(value);
          break;
        default:
          break;
      }
    });
  };

  const MoveSecurityDoc2 = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-security-clearence-document2/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveSecurityDoc2 = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveSecurityDoc2(value);
          break;
        default:
          break;
      }
    });
  };


  const MoveSecurityDoc3 = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-security-clearence-document3/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveSecurityDoc3 = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveSecurityDoc3(value);
          break;
        default:
          break;
      }
    });
  };


  const MoveSecurityDoc4 = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-security-clearence-document4/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveSecurityDoc4 = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveSecurityDoc4(value);
          break;
        default:
          break;
      }
    });
  };


  const MoveCV = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/move-cv-upload-document/linID/${values?._id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: `${error?.response?.data?.error}`,
        text: `Confirmation above`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleMoveCV = async (value) => {
    swal("Do you want to check document exist or not? And if exist then move this document to another new schema?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          MoveCV(value);
          break;
        default:
          break;
      }
    });
  };



  return (
    <>
      <GridContainer
        justify="center"
        className={classes.container}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ textAlign: "center" }}
                >
                  Move Linguist Document
                </Typography>
              </div>

              <CustomInputSingle
                label="LC Lingusit's Profile ID"
                width="100%"
                placeholder="Enter Linguist Profile ID Here (e.g. LIN/PRO100...)"
                name="linID"
                onChange={handleChange}
                value={data.linID}
                required
              />

              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  className={`${classes.button}`}
                  color="primary"
                  variant="contained"
                  onClick={FindLinguist}
                  disabled={!data.linID}
                >
                  Find Linguist
                </Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
          {values?.id ? (
            <>
              <Card>
                <CardBody>
                  <h4 style={{ textAlign: "left" }}>ID: {values?.id}</h4>
                  <h4 style={{ textAlign: "left" }}>Old ID: {values?.old_id}</h4>
                  <h4 style={{ textAlign: "left" }}>
                    Profile ID Card Security Code: <a href={`/admin/view-linguist-profile/${values?._id}`}> {values?.username} </a>
                  </h4>
                  <h4 style={{ textAlign: "left" }}>
                    Firstname: {values?.FirstName}
                  </h4>
                  <h4 style={{ textAlign: "left" }}>
                    Lastname: {values?.LastName}
                  </h4>
                  <h4 style={{ textAlign: "left" }}>
                    Mobile Number: {values?.mobile_no}
                  </h4>
                  <h4 style={{ textAlign: "left" }}>Email: {values?.email}</h4>
                </CardBody>
              </Card>
            </>
          ) : (
            "No Linguist Found"
          )}
        </GridItem>
      </GridContainer>
      <br /><br />
      {values?.id ?
        <GridContainer
          justify="center"
          className={classes.container}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <div style={{ display: "grid", gridTemplateColumns: "auto auto " }}>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMovePassportDocument}
                    style={{ margin: "5px" }}
                  >
                    Passport
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveAddress}
                    style={{ margin: "5px" }}
                  >
                    Proof of Address
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveRelevantDoc}
                    style={{ margin: "5px" }}
                  >
                    Relevant Document
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveInsuranceDoc}
                    style={{ margin: "5px" }}
                  >
                    Insurance Document
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveInterpretingDoc}
                    style={{ margin: "5px" }}
                  >
                    Interpreting Document
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveTranslationDoc}
                    style={{ margin: "5px" }}
                  >
                    Translation Document
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMovePsiDoc}
                    style={{ margin: "5px" }}
                  >
                    PSI / PST Document
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveOtherDoc}
                    style={{ margin: "5px" }}
                  >
                    Other Experience Document
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveWorkDoc}
                    style={{ margin: "5px" }}
                  >
                    Work Reference Letter
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveRelevantCertificate}
                    style={{ margin: "5px" }}
                  >
                    Relevant Certificate
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveSecurityDoc1}
                    style={{ margin: "5px" }}
                  >
                    1 - Security Clearance
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveSecurityDoc2}
                    style={{ margin: "5px" }}
                  >
                    2 - Security Clearance 
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveSecurityDoc3}
                    style={{ margin: "5px" }}
                  >
                    3 - Security Clearance 
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveSecurityDoc4}
                    style={{ margin: "5px" }}
                  >
                    4 - Security Clearance 
                  </Button>
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={handleMoveCV}
                    style={{ margin: "5px" }}
                  >
                    CV
                  </Button>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        : null}
    </>
  );
};

// EBBlxUJe_
export default SubscribeHere;
