import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";

import Cookies from "js-cookie";
// Materials Core

import { Button } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const AssignmentOptions = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Button
        color="purple"
        content="Cash Client"
        icon="calendar check outline"
        label={{
          basic: true,
          color: "purple",
          pointing: "left",
          content: "CAC",
        }}
        onClick={() => history.push("/linguist/cac-quotation")}
      />
      <Button
        color="purple"
        content="Account Client"
        icon="calendar check outline"
        label={{
          as: "a",
          basic: true,
          color: "purple",
          pointing: "left",
          content: "BAC",
        }}
        onClick={() => history.push("/linguist/bac-quotation")}
      />
    </div>
  );
};

export default AssignmentOptions;
