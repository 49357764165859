import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from 'react-loading-screen';
import Typography from "@material-ui/core/Typography";
import LandingPageLayout from "layout/LandingPageLayout";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import logo from 'assets/images/logo.png'

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Axios from "axios";
import StripeCheckout from "react-stripe-checkout";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@material-ui/core";
import history from "utils/history";
import Cookies from 'js-cookie'
import CustomSelect from "components/Common/CustomSelect";
import { changeBaInput } from "redux/actions/bookingAction";
import swal from "sweetalert";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { Divider } from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import {
  CURRENCY_x
} from "data";
import {loadStripe} from '@stripe/stripe-js';
import { useParams } from "react-router-dom";

const BISummary = () => {
  const classes = useEmployeeStyles();
//   const stripePromise = loadStripe('pk_test_51I3OlCEdJeNZngVd9ROYTlsOSf8HVo8Q1MvlJeoy7Fryh5KfPtuO3LdLoggzv8QwTQgOvSrA0e4uNxbb0uDjbu1d00yj7pZaDs');
  const token = Cookies.get("token");
  const dispatch = useDispatch();

  const classesb = useLandingpageStyles();
  const [rates, setRates] = useState('')


  const [loading, setLoading] = useState(false)
const {id} = useParams()
console.log(id)
  const currency_u = "GBP (UK)"


 const { data } = useSelector(state => state.bookingReducer)
const { currency_new, currency_new_card } = data 


  const handleChange = (e) => {
    dispatch(changeBaInput({
      name: 'currency_new',
      value: e.target.value
  }))

  }
const [Interpreting_service, setInterpreting_service] = useState("")
const [Source_language, setSource_language] = useState("")
const [Target_language, setTarget_language] = useState("")
const [total_PriceForInterpriting, settotal_PriceForInterpriting] = useState(0)
const [Target_language_level, setTarget_language_level] = useState(0)
// const [total_PriceForInterpriting, settotal_PriceForInterpriting] = useState(0)


const response =  Axios.get(`${process.env.REACT_APP_BACKEND_API}/send_email/get_data/${id}`).then (res => {
console.log(res)
if(res.data === "no_data") {
    swal({
        title: "No data found!!!!",
        text: `Kindly contact to your linguist. `,
        icon: "error",
        buttons: "ok!!",
       
      }).then(function(){
        window.location = "/"
      })
     
}
    else{console.log(res)
    setInterpreting_service(res.data.message.Interpreting_service)
    setSource_language(res.data.message.Source_language)
    setTarget_language(res.data.message.Target_language)
    settotal_PriceForInterpriting(res.data.message.total_PriceForInterpriting)
    setTarget_language_level(res.data.message.Target_language_level)}
})


  useEffect(() => {

    Axios.get('https://api.exchangeratesapi.io/latest?base=GBP')
        .then(res => setRates(res.data.rates))
  }, [])

const [price, setPrice] = useState('')
console.log(rates.EUR)

 
  let c = parseFloat(total_PriceForInterpriting) || 0
 
  let d = 0
 
   const estimatedTotal = 0 + c + d

   console.log(estimatedTotal)
   


  const isAllow= 
  !estimatedTotal ||
!currency_new || 0
  
  return (
    <div className={classes.root}>
         <LandingPageLayout>

{loading === false ? (
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={8}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                {/* <Avatar className={classes.avatar}> */}
                  <img style= {{width: '70px', height: '65px'}} src= {logo} alt= ''/>
                {/* </Avatar> */}
                <Typography component="h1" variant="h5">
                    Summary
                </Typography>
              </div>
              <div style= {{padding: '4rem 12rem 4rem 12rem', display: 'flex', flexDirection: 'column'}}>
                <div style= {{display: 'flex', flexDirection: 'column', width: '100%', fontWeight: "600", fontSize: '15px'}}>
                   
              {id ? (
                  <div style= {{display: 'flex'}}>
                       <p>Booking Order / Assignment ID</p>
                       <span style= {{ marginLeft: 'auto'}}>{id}</span>
                  </div>
                ): null}

                        
              {Interpreting_service ? (
                  <div style= {{display: 'flex'}}>
                       <p>Interpreting service:</p>
                       <span style= {{ marginLeft: 'auto'}}>{Interpreting_service}</span>
                  </div>
                ): null}
                  
                  {Source_language ? (
                  <div style= {{display: 'flex'}}>
                       <p>Source language:</p>
                       <span style= {{ marginLeft: 'auto'}}>{Source_language}</span>
                  </div>
                ): null}
             {Target_language ? (
                  <div style= {{display: 'flex'}}>
                       <p>Target language:</p>
                       <span style= {{ marginLeft: 'auto'}}>{Target_language}</span>
                  </div>
                ): null}

            {Target_language_level ? (
                  <div style= {{display: 'flex'}}>
                       <p>Experties level:</p>
                       <span style= {{ marginLeft: 'auto'}}>{Target_language_level}</span>
                  </div>
                ): null}
                  <Divider />
                  <div style= {{display: 'flex', margin: '1em 0 1em 0'}}>

                </div>
              {total_PriceForInterpriting ? (
                    <div style= {{display: 'flex'}}>
                      <p>Estimate interpreting price</p>
                      {/* <span style= {{ marginLeft: 'auto'}}> {currency_u.includes('euro (eu)') ? '€': '£'} {currency_u.includes('euro (eu)') ? (total_PriceForInterpriting * rates.EUR).toFixed(2) : total_PriceForInterpriting}</span> */}
                      {currency_u.includes('GBP (UK)') && <span style= {{ marginLeft: 'auto'}}>£ {(c)}</span>}
                      {currency_u.includes('EUR (EU)') && <span style= {{ marginLeft: 'auto'}}>€ {(c * rates.EUR + (c * 2.5 / 100 * rates.EUR)).toFixed(2)}</span>}
                      {currency_u.includes('AUD (Australia)') && <span style= {{ marginLeft: 'auto'}}>AUD $ {(c * rates.AUD + (c * 2.5 / 100 * rates.AUD)).toFixed(2)}</span>}
                      {currency_u.includes('USD (USA)') && <span style= {{ marginLeft: 'auto'}}>USD $ {(c * rates.USD + (c * 2.5 / 100 * rates.USD)).toFixed(2)}</span>}
                      {currency_u.includes('CAD (Canada)') && <span style= {{ marginLeft: 'auto'}}>CAD $ {(c * rates.CAD + (c * 2.5 / 100 * rates.CAD)).toFixed(2)}</span>}

                      {currency_u.includes('TRY (Turkish lira)') && <span style= {{ marginLeft: 'auto'}}> TRY ₺ {(c * rates.TRY + (c * 2.5 / 100 * rates.TRY)).toFixed(2)}</span>}

                      {currency_u.includes('SGD (Singapore dollar)') && <span style= {{ marginLeft: 'auto'}}> SGD S$ {(c * rates.SGD + (c * 2.5 / 100 * rates.SGD)).toFixed(2)}</span>}
                      {currency_u.includes('HUF (Hungarian forint)') && <span style= {{ marginLeft: 'auto'}}>HUF Ft {(c * rates.HUF + (c * 2.5 / 100 * rates.HUF)).toFixed(2)}</span>}
                      {currency_u.includes('NZD (New Zealand dollar)') && <span style= {{ marginLeft: 'auto'}}> NZD $ {(c * rates.NZD + (c * 2.5 / 100 * rates.NZD)).toFixed(2)}</span>}
                      {currency_u.includes('RON (Romanian leu)') && <span style= {{ marginLeft: 'auto'}}> RON lei {(c * rates.RON + (c * 2.5 / 100 * rates.RON)).toFixed(2)}</span>}
                      {/* <span style= {{ marginLeft: 'auto'}}>{total_PriceForInterpriting }</span> */}
                      
                    </div>
                  ): null}

                
                    <div style= {{display: 'flex'}}>
                      <p>Travelling cost (to be confirmed)</p>
                      {currency_u.includes('GBP (UK)') && <span style= {{ marginLeft: 'auto'}}>£ {(0).toFixed(2)}</span>}
                      {currency_u.includes('EUR (EU)') && <span style= {{ marginLeft: 'auto'}}>€ {(0 * rates.EUR + (d * 2.5 / 100 * rates.EUR)).toFixed(2)}</span>}
                      {currency_u.includes('AUD (Australia)') && <span style= {{ marginLeft: 'auto'}}>AUD $ {(0 * rates.AUD + (d * 2.5 / 100 * rates.AUD)).toFixed(2)}</span>}
                      {currency_u.includes('USD (USA)') && <span style= {{ marginLeft: 'auto'}}>USD $ {(0 * rates.USD + (0 * 2.5 / 100 * rates.USD)).toFixed(2)}</span>}
                      {currency_u.includes('CAD (Canada)') && <span style= {{ marginLeft: 'auto'}}>CAD $ {(0 * rates.CAD + (d * 2.5 / 100 * rates.CAD)).toFixed(2)}</span>}

                      {currency_u.includes('TRY (Turkish lira)') && <span style= {{ marginLeft: 'auto'}}> TRY ₺ {(0 * rates.TRY + (0 * 2.5 / 100 * rates.TRY)).toFixed(2)}</span>}

                      {currency_u.includes('SGD (Singapore dollar)') && <span style= {{ marginLeft: 'auto'}}> SGD S$ {(0 * rates.SGD + (d * 2.5 / 100 * rates.SGD)).toFixed(2)}</span>}
                      {currency_u.includes('HUF (Hungarian forint)') && <span style= {{ marginLeft: 'auto'}}>HUF Ft {(0 * rates.HUF + (d * 2.5 / 100 * rates.HUF)).toFixed(2)}</span>}
                      {currency_u.includes('NZD (New Zealand dollar)') && <span style= {{ marginLeft: 'auto'}}> NZD $ {(0 * rates.NZD + (d * 2.5 / 100 * rates.NZD)).toFixed(2)}</span>}
                      {currency_u.includes('RON (Romanian leu)') && <span style= {{ marginLeft: 'auto'}}> RON lei {(0 * rates.RON + (d * 2.5 / 100 * rates.RON)).toFixed(2)}</span>}
                    {/* <span style= {{ marginLeft: 'auto'}}>{total_TravelTotal_Wages}</span> */}
                    </div>
                

               
                    <div style= {{display: 'flex'}}>
                      <p>Overnight food allowance (to be confirmed)</p>
                      {/* <span style= {{ marginLeft: 'auto'}}> {currency_u.includes('euro (eu)') ? '€': '£'} {currency_u.includes('euro (eu)') ? (tatal_Food_Overnight * rates.EUR).toFixed(2) : tatal_Food_Overnight}</span> */}

                      {currency_u.includes('GBP (UK)') && <span style= {{ marginLeft: 'auto'}}>£ 0.00</span>}
                


                    {/* <span style= {{ marginLeft: 'auto'}}>{tatal_Food_Overnight}</span> */}
                    </div>
               

                 
                    <div style= {{display: 'flex'}}>
                      <p>Overnight accommodation allowance (to be confirmed)</p>

                      {currency_u.includes('GBP (UK)') && <span style= {{ marginLeft: 'auto'}}>£ 0.00</span>}
                   
                    {/* <span style= {{ marginLeft: 'auto'}}>{total_Overnight_charges}</span> */}
                    </div>
                 

                  <Divider />
                
                  {estimatedTotal ? (
                    <div style= {{display: 'flex', margin: '1em 0 1em 0'}}>
                      <p>Estimated total</p>
             

                      {currency_u.includes('GBP (UK)') && <span style= {{ marginLeft: 'auto'}}>£ {(estimatedTotal).toFixed(2)}</span>}
                      {currency_u.includes('EUR (EU)') && <span style= {{ marginLeft: 'auto'}}>€ {(estimatedTotal * rates.EUR + (estimatedTotal * 2.5 / 100 * rates.EUR)).toFixed(2)}</span>}
                      {currency_u.includes('AUD (Australia)') && <span style= {{ marginLeft: 'auto'}}>AUD $ {(estimatedTotal * rates.AUD + (estimatedTotal * 2.5 / 100 * rates.AUD)).toFixed(2)}</span>}
                      {currency_u.includes('USD (USA)') && <span style= {{ marginLeft: 'auto'}}>USD $ {(estimatedTotal * rates.USD + (estimatedTotal * 2.5 / 100 * rates.USD)).toFixed(2)}</span>}
                      {currency_u.includes('CAD (Canada)') && <span style= {{ marginLeft: 'auto'}}>CAD $ {(estimatedTotal * rates.CAD + (estimatedTotal * 2.5 / 100 * rates.CAD)).toFixed(2)}</span>}

                      {currency_u.includes('TRY (Turkish lira)') && <span style= {{ marginLeft: 'auto'}}> TRY ₺ {(estimatedTotal * rates.TRY + (estimatedTotal * 2.5 / 100 * rates.TRY)).toFixed(2)}</span>}

                      {currency_u.includes('SGD (Singapore dollar)') && <span style= {{ marginLeft: 'auto'}}> SGD S$ {(estimatedTotal * rates.SGD + (estimatedTotal * 2.5 / 100 * rates.SGD)).toFixed(2)}</span>}
                      {currency_u.includes('HUF (Hungarian forint)') && <span style= {{ marginLeft: 'auto'}}>HUF Ft {(estimatedTotal * rates.HUF + (estimatedTotal * 2.5 / 100 * rates.HUF)).toFixed(2)}</span>}
                      {currency_u.includes('NZD (New Zealand dollar)') && <span style= {{ marginLeft: 'auto'}}> NZD $ {(estimatedTotal * rates.NZD + (estimatedTotal * 2.5 / 100 * rates.NZD)).toFixed(2)}</span>}
                      {currency_u.includes('RON (Romanian leu)') && <span style= {{ marginLeft: 'auto'}}> RON lei {(estimatedTotal * rates.RON + (estimatedTotal * 2.5 / 100 * rates.RON)).toFixed(2)}</span>}


                    {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                    </div>
                  ): null}
                </div>
              </div>
              <div style= {{display: 'flex', margin: '1em 0 1em 0'}}>
              <span style= {{ marginLeft: 'auto'}}>* 2 Days + 70 miles = overnight</span>
              </div>
            
                <Accordion>

                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Marketplace speedy payment</Typography>
                  </AccordionSummary>
                 
                  <AccordionDetails>
                        <div style= {{display: 'flex', flexDirection: 'column', width: '100%', fontWeight: "600", fontSize: '15px'}}>
                  {estimatedTotal ? (
                    <div style= {{display: 'flex', margin: '1em 0 1em 0'}}>
                      <p>Estimated total</p>
                      {currency_u.includes('GBP (UK)') && <span style= {{ marginLeft: 'auto'}}>£ {(estimatedTotal).toFixed(2)}</span>}
                      {currency_u.includes('EUR (Euro countries)') && <span style= {{ marginLeft: 'auto'}}>€ {(estimatedTotal * rates.EUR + (estimatedTotal * 2.5 / 100 * rates.EUR)).toFixed(2)}</span>}
                      {currency_u.includes('AUD (Australia)') && <span style= {{ marginLeft: 'auto'}}>AUD $ {(estimatedTotal * rates.AUD + (estimatedTotal * 2.5 / 100 * rates.AUD)).toFixed(2)}</span>}
                      {currency_u.includes('USD (USA, paying from USA only)') && <span style= {{ marginLeft: 'auto'}}>USD $ {(estimatedTotal * rates.USD + (estimatedTotal * 2.5 / 100 * rates.USD)).toFixed(2)}</span>}
                      {currency_u.includes('CAD (Canada)') && <span style= {{ marginLeft: 'auto'}}>CAD $ {(estimatedTotal * rates.CAD + (estimatedTotal * 2.5 / 100 * rates.CAD)).toFixed(2)}</span>}

                      {currency_u.includes('TRY (Turkish lira, payment from Turkey only)') && <span style= {{ marginLeft: 'auto'}}> TRY ₺ {(estimatedTotal * rates.TRY + (estimatedTotal * 2.5 / 100 * rates.TRY)).toFixed(2)}</span>}

                      {currency_u.includes('SGD (Singapore dollar)') && <span style= {{ marginLeft: 'auto'}}> SGD S$ {(estimatedTotal * rates.SGD + (estimatedTotal * 2.5 / 100 * rates.SGD)).toFixed(2)}</span>}
                      {currency_u.includes('HUF (Hungarian forint)') && <span style= {{ marginLeft: 'auto'}}>HUF Ft {(estimatedTotal * rates.HUF + (estimatedTotal * 2.5 / 100 * rates.HUF)).toFixed(2)}</span>}
                      {currency_u.includes('NZD (New Zealand dollar, paying from New Zealand only)') && <span style= {{ marginLeft: 'auto'}}> NZD $ {(estimatedTotal * rates.NZD + (estimatedTotal * 2.5 / 100 * rates.NZD)).toFixed(2)}</span>}
                      {currency_u.includes('RON (Romanian leu, paying from Romania only)') && <span style= {{ marginLeft: 'auto'}}> RON lei {(estimatedTotal * rates.RON + (estimatedTotal * 2.5 / 100 * rates.RON)).toFixed(2)}</span>}
                  
                    {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                    </div>
                  ): null}
                  </div>
                      </AccordionDetails>
                  <AccordionDetails>
                    <Button  className={`${classesb.button}`} style= {{width: '30rem', marginLeft: 'auto', marginRight: 'auto'}} variant= 'contained' color= 'primary'>Pay and confirm</Button>
                    
{/* onClick={handleNextSP} */}
                    {/* //stripe */}
                  
               {/* <Button className={`${classesb.button}`} style= {{width: '30rem', marginLeft: 'auto', marginRight: 'auto'}} variant= 'contained' color= 'primary'>Pay and confirm</Button> */}


                 {/* </StripeCheckout> */}

                 {/* //stripe */}
                  </AccordionDetails>
                </Accordion>

              
              
              {/* {selectedForm === 2 && <FormThree />} */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
         ) : 
      
         <LoadingScreen
         loading={true}
         bgColor='#f1f1f1'
         spinnerColor='#9ee5f8'
         textColor='#676767'
         logoSrc='https://linguistscollective.com/static/media/Interpreting-Translation-Linguists-Collective-Language-Services.0f1108ca.png'
         text='Please wait! The system is generating email and sending it to the client.'
       > 
  
  
        <div>Loadable content</div>
       </LoadingScreen>
         }
         
    </LandingPageLayout>
    </div>
  );
};

export default BISummary;
