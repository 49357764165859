import React, { Fragment } from "react";

import AdminLogin from "components/Auth/Supreme/Login/Login";

const SupremeLoginPage = () => {
  return (
    <Fragment>
      <AdminLogin />
    </Fragment>
  );
};

export default SupremeLoginPage;
