import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import Searchlinguist from "./SearchView/SearchView";
import Cookies from "js-cookie";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ id }) {
  const { addToast } = useToasts();
  const classes = useStyles();
  const token = Cookies.get("token");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const [pay, setPay] = useState({
    message: "",
    claID: "",
    admin_note: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({ reload: false });
  const [notevalues, setNoteValues] = useState({ reload: false });

  useEffect(() => {
    loadList();
    setFiltered([]);
  }, [filter]);

  const loadList = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-sppo-inc-clients/${id}`
    )
      .then((res) =>
        filtered.length > 0 ? setValues(filtered) : setValues(res.data)
      )
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Paper className={classes.root}>
        <Searchlinguist
          values={values}
          SearchText={searchText}
          setSearchText={setSearchText}
          setFiltered={setFiltered}
          load={loadList}
          setShow={setShow}
          setFilter={setFilter}
          show={show}
        />
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" id="export">
                <TableHead>
                  <TableRow>
                    <TableCell>Unique ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>First name</TableCell>
                    <TableCell>Last name</TableCell>
                    <TableCell>Email address</TableCell>
                    <TableCell>Mobile number</TableCell>
                    <TableCell>Registration Date</TableCell>
                  </TableRow>
                </TableHead>

                {values.length > 0 ? (
                  <TableBody>
                    {values
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={list._id}
                        >
                          <TableCell>
                            <Link to={`/admin/view-inhouse/${list._id}`}>
                              {list.id}
                            </Link>
                          </TableCell>
                          <TableCell>{list.title}</TableCell>
                          <TableCell>{list.FirstName}</TableCell>
                          <TableCell>{list.LastName}</TableCell>
                          <TableCell>{list.email}</TableCell>
                          <TableCell>{list.mobile_no}</TableCell>
                          <TableCell>
                            {Moment(list?.createdAt)
                              .tz("Europe/London")
                              .format("DD MMM YYYY - kk:mm:ss")}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  "No data found"
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={values.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </>
  );
}
