// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import history from "utils/history";
const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const BookingOptions = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        onClick={() => history.push("/admin/cms")}
      >
        CMS
      </Button>


      {history.location.pathname.includes("admin/cashclient") ?
        <Button
          variant="outlined"
          onClick={() => history.push("/admin/cashclient-service-agreement/post-agreement")}
        >
          Post Service Agreement
        </Button>
        : history.location.pathname.includes("admin/accountclient") ?
          <Button
            variant="outlined"
            onClick={() => history.push("/admin/accountclient-service-agreement/post-agreement")}
          >
            Post Service Agreement
          </Button>
          : history.location.pathname.includes("admin/linguist") ?
            <Button
              variant="outlined"
              onClick={() => history.push("/admin/linguist-service-agreement/post-agreement")}
            >
              Post Service Agreement
            </Button>
            : history.location.pathname.includes("admin/sppo") ?
              <Button
                variant="outlined"
                onClick={() => history.push("/admin/sppo-service-agreement/post-agreement")}
              >
                Post Service Agreement
              </Button>
              : null}


      {history.location.pathname.includes("admin/cashclient") ?
        <Button
          variant="outlined"
          onClick={() =>
            history.push("/admin/cashclient-service-agreement/view-agreements-list")
          }
        >
          View Service Agreements
        </Button>
        : history.location.pathname.includes("admin/accountclient") ?
          <Button
            variant="outlined"
            onClick={() =>
              history.push("/admin/accountclient-service-agreement/view-agreements-list")
            }
          >
            View Service Agreements
          </Button>
          : history.location.pathname.includes("admin/linguist") ?
            <Button
              variant="outlined"
              onClick={() =>
                history.push("/admin/linguist-service-agreement/view-agreements-list")
              }
            >
              View Service Agreements
            </Button>
            : history.location.pathname.includes("admin/sppo") ?
              <Button
                variant="outlined"
                onClick={() =>
                  history.push("/admin/sppo-service-agreement/view-agreements-list")
                }
              >
                View Service Agreements
              </Button>
              : null}
    </div>
  );
};

export default BookingOptions;
