import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomPhone from "components/Common/CustomPhone";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomPassword from "components/Common/CustomPassword";
import Button from "@material-ui/core/Button";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import { makeStyles } from "@material-ui/styles";
// Other
import { CURRENT_COUNTRY, EMAIL_REGEX } from "data";
import CustomDate from "components/Common/CustomDate";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import {
  changeInhouseClientInput,
  updateInhouseClientStep,
} from "redux/actions/icrAction";
import { Header } from "semantic-ui-react";

const useStyles = makeStyles({
  button: {
    display: "block !important",
  },
});
const FormOne = () => {
  const styledClasses = useStyles();
  const { data } = useSelector((state) => state.icrReducer);
  const [notValid, setNotValid] = useState(false);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const {
    profile_photo,
    passport_ni_photo,
    title,
    FirstName,
    LastName,
    Birthday,
    email,
    mobile_no,
    direct_line,
    AddressLine1,
    AddressLine2,
    City,
    PostalCode,
    Region,
    Country,
    Nationality,
    passNumber,
    confirm_passNumber,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateInhouseClientStep(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeInhouseClientInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeInhouseClientInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeInhouseClientInput(inputData));
  };

  const handleProfileFiles = (value) => {
    dispatch(
      changeInhouseClientInput({
        name: "profile_photo",
        value: value,
      })
    );
  };

  const handlepassport_ni_photoFiles = (value) => {
    dispatch(
      changeInhouseClientInput({
        name: "passport_ni_photo",
        value: value,
      })
    );
  };

  const handleClick = (e, value) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handlepassport_ni_photoClick = (e, value) => {
    e.preventDefault();
    fileInput2.current.click();
  };

  const handleAutoCompleteON = (e, value) => {
    dispatch(
      changeInhouseClientInput({
        name: "other_nationality",
        value,
      })
    );
  };

  const handleDateChange = (date) => {
    dispatch(
      changeInhouseClientInput({
        name: "Birthday",
        value: date,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeInhouseClientInput({
        name: "Country",
        value,
      })
    );
  };

  const handleChangeN = (e, value) => {
    dispatch(
      changeInhouseClientInput({
        name: "Nationality",
        value,
      })
    );
  };

  const handleChangePH = (e) => {
    dispatch(
      changeInhouseClientInput({
        name: "mobile_no",
        value: e,
      })
    );
  };

  const isAllow =
    !title ||
    !FirstName ||
    !LastName ||
    !email ||
    !mobile_no ||
    !AddressLine1 ||
    !City ||
    !PostalCode ||
    !Country ||
    !passNumber ||
    !confirm_passNumber ||
    notValid ||
    passNumber !== confirm_passNumber;

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        value={title}
        name="title"
        label="Title"
        width="100%"
        required
        placeholder="e.g. Mr, Mrs, Miss, Ms etc"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={FirstName}
        name="FirstName"
        label="First Name(s)"
        width="100%"
        required
        placeholder="First Name(s)"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={LastName}
        name="LastName"
        label="Last Name"
        width="100%"
        required
        placeholder="Last Name"
      />
      <CustomDate
        onChange={handleDateChange}
        value={Birthday}
        name="Birthday"
        label="Date of Birth"
        width="100%"
        maxDate={new Date("1-1-2000")}
      />
      <CustomInputSingle
        onChange={handleChange}
        value={AddressLine1}
        name="AddressLine1"
        label="House number/name, Street name"
        width="100%"
        required
        placeholder="e.g. Flat/Building Name, 101 Example Street"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={AddressLine2}
        name="AddressLine2"
        label="Address line 2"
        width="100%"
        placeholder="Additional Address Information"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={City}
        name="City"
        label="Town / City"
        width="100%"
        required
        placeholder="Your Village, Town or City Name"
      />
      <CustomInputSingle
        onChange={handleChange}
        label="County / Region"
        width="100%"
        name="Region"
        value={Region}
        placeholder="Your County, Division or State Name"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={PostalCode}
        name="PostalCode"
        label="Post Code / Zip Code / Post office"
        width="100%"
        required
        placeholder="Your Post Code, Zip Code or Post Office Name"
      />
      <CustomAutoSelect
        onChange={handleAutoCompleteCO}
        label="Country"
        moreInfo="Current Country of residence"
        width="100%"
        value={Country}
        data={CURRENT_COUNTRY}
        required
      />

      <CustomInputSingle
        onChange={handleEmailChange}
        value={email}
        name="email"
        label="Primary email address"
        width="100%"
        required
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomPassword
        onChange={handlePasswordChange}
        label="Passcode / Memorable word"
        width="100%"
        name="passNumber"
        value={passNumber}
        required
      />
      <CustomPassword
        onChange={handleChange}
        label="Confirm Passcode / Memorable word"
        width="100%"
        name="confirm_passNumber"
        value={confirm_passNumber}
        validation={passNumber !== confirm_passNumber}
        required
      />
      <CustomPhone
        placeholder="Mobile Number* (Please select country then type number e.g. +44 7307 000000)"
        value={mobile_no}
        width="100%"
        name="mobile_no"
        label="Mobile number"
        style={{ marginTop: "2rem" }}
        onChange={handleChangePH}
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        value={direct_line}
        name="direct_line"
        label="Direct line with extension no"
        width="100%"
        placeholder="Country Code, Phone Number - Ext."
      />

      <div className={classes.buttonFlex}>
        <CustomButton text="Next" onClick={handleNext} disabled={isAllow} />
      </div>
    </form>
  );
};

export default FormOne;
