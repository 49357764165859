import React, { useState, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { Button } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CustomInputSingle from "components/Common/CustomInputSingle";
import { EMAIL_REGEX } from "data";
import history from "utils/history";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useDispatch } from "react-redux";
import { providedQuotes } from "redux/actions/unconfirmQuotesAction";

const Action = ({ id, load }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const [notValid, setNotValid] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [pay, setPay] = React.useState({
    admin_note: "",
    toEmail: "",
    bccEmail: "",
  });

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const handleBack = () => {
    setViewMessage(false);
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  const handleEmailChange = (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const DeleteNote = async (e) => {
    try {
      const response = await Axios.delete(
        `/api/manager-admin/delete-event/eventID/${id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          DeleteNote(value);

        default:
          break;
      }
    });
  };

  const [showMessage, setMessage] = React.useState(false);
  const [viewMessage, setViewMessage] = React.useState(false);

  const handleInvitation = () => {
    setMessage(!showMessage);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMessage(false);
  };

  const sendInvitation = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/send-invitation/eventID/${id}`,
        {
          admin_note: pay.admin_note,
          toEmail: pay.toEmail,
          bccEmail: pay.bccEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "Invitation sent",
      });
      load();
      setPay({
        ...pay,
        admin_note: "",
        ccEmail: "",
        bccEmail: "",
        loader: false,
      });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => dispatch(providedQuotes(id))}>
          Registered Members
        </MenuItem>
        <MenuItem onClick={handleInvitation}>Send Invitation</MenuItem>
        <MenuItem onClick={() => history.push(`/admin/edit-events-and-meetings/${id}`)}>Edit Event</MenuItem>
        <MenuItem onClick={handleDelete}>Delete Event</MenuItem>
      </Menu>

      {/* ADD Doc Reminder NOTE */}
      {showMessage ? (
        <Dialog open={showMessage}>
          {!viewMessage ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Send Invitation
                </div>
              </DialogTitle>
              <DialogContent>
                <CustomInputSingle
                  label="To Email"
                  width="100%"
                  placeholder="To Email"
                  name="toEmail"
                  onChange={handleEmailChange}
                  value={pay.toEmail}
                />
                {notValid && (
                  <small style={{ color: "red" }}>
                    enter valid email address
                  </small>
                )}
                <br />

                <CustomInputSingle
                  label="BCC Email"
                  width="100%"
                  placeholder="BCC Email"
                  name="bccEmail"
                  onChange={handleEmailChange}
                  value={pay.bccEmail}
                />
                {notValid && (
                  <small style={{ color: "red" }}>
                    enter valid email address
                  </small>
                )}
                <br />

                <TextField
                  onChange={handleChange}
                  value={pay.admin_note}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="admin_note"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.toEmail || !pay.admin_note}
                    onClick={sendInvitation}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Send Invitation
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Send Invitation
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}
    </div>
  );
};

export default Action;
