import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

//Material icons

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

// import Details from './Details'
import { Dimmer, Header, Loader, Statistic, Table } from "semantic-ui-react";
import Axios from "axios";
import moment from "moment";
import {
  addOtherTransaction,
  addTransaction,
} from "redux/actions/expenseAction";

//Stripe

const ViewPaidInvoice = () => {
  const classes = useEmployeeStyles();
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [text, setText] = useState("");
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const dispatch = useDispatch();
  const [payOptions, setPayOptions] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [bankDetails, setBankDetails] = useState({
    account_holder_name: "",
    bsb: "",
    account_number: "",
    bic: "",
    iban: "",
    sort_code: "",
    routing_number: "",
    institution_number: "",
    transit_number: "",
    bank_code: "",
  });
  const [convertedCurrency, setConvertedCurrency] = useState(null);

  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
  });

  //   2nd subtotal
  const [otherAmount, setOtherAmount] = useState(0);
  const [otherText, setOtherText] = useState("");
  const [otherQuantity, setOtherQuantity] = useState(0);

  const { transactions } = useSelector((state) => state.expenseReducer);
  const amountsOne = transactions.map((transaction) => transaction.total);
  const subTotalOne = amountsOne
    .reduce((acc, item) => (acc += item), 0)
    .toFixed(2);

  const { otherTransactions } = useSelector((state) => state.expenseReducer);
  const amountsTwo = otherTransactions.map((transaction) => transaction.total);
  const subTotalTwo = amountsTwo
    .reduce((acc, item) => (acc += item), 0)
    .toFixed(2);

  //   const totalFTF = invoice ? Number(invoice[0]) * Number(invoice?.duration) : null

  const total = (Number(subTotalOne) + Number(subTotalTwo)).toFixed(2);

  const [discount, setDiscount] = useState(0);
  const [vat, setVAT] = useState(0);

  //Total after discount
  const totalDiscount = ((Number(total) * discount) / 100).toFixed(2);

  //Including platform and booking service charge
  const totalPBSC = (
    ((Number(Number(invoice?.subtotal1) + Number(invoice?.subtotal2)) -
      Number(invoice?.discount_amount)) *
      invoice?.service_charge) /
    100
  ).toFixed(2);

  //Total VAT
  const totalVAT = (
    Number(Number(total) + Number(totalPBSC) - Number(totalDiscount)) *
    (vat / 100)
  ).toFixed(2);

  //Estimate paid
  const estimate_paid = "00.00";

  //Total after including vat and discount
  const totalTwo = (
    Number(total) +
    Number(totalPBSC) -
    Number(totalDiscount) +
    Number(totalVAT)
  ).toFixed(2);

  //To be paid including vat

  const paidBy = moment().add(14, "days").format("DD/MM/YYYY");

  const [lingRef, setLingRef] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleAdd = (e) => {
    e.preventDefault();

    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      text,
      amount: +amount,
      quantity,
      total: amount * quantity,
    };

    dispatch(addTransaction(newTransaction));
    setText("");
    setAmount("");
    setQuantity("");
  };

  const handleAddOther = (e) => {
    e.preventDefault();
    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      otherText,
      otherAmount: +otherAmount,
      otherQuantity,
      total: otherAmount * otherQuantity,
    };

    dispatch(addOtherTransaction(newTransaction));
    setOtherText("");
    setOtherAmount("");
    setOtherQuantity("");
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const loadInvoice = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-invoice/invID/${id}`
        );

        setInvoice(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          console.log(error.response?.data);
        }
      }
    };

    const getCompanyCard = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/view/company-information-card`
        );

        setCompanyCard({
          ...companyCard,
          basc: response?.data[0].getCompanyCard,
          pbsc: response?.data[0].platform_booking_service_charge,
          basc: response?.data[0].billing_account_service_charge,
          cname: response?.data[0].company_name,
          caddress: response?.data[0].company_address,
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadInvoice();
    getCompanyCard();

    return () => {
      source.cancel();
    };
  }, []);

  return invoice ? (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem id="printArea" xs={12} sm={12} md={12} lg={8} xl={8}>
          <Card>
            <CardBody>
              {/* Avatar and company name */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "24px",
                }}
              >
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  {Number(invoice?.grand_total).toFixed(2) == 0 ? (
                    <Statistic color="green">
                      <Statistic.Value text>
                        PAID
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : Number(invoice?.grand_total).toFixed(2) < 0 ? (
                    <Statistic color="purple">
                      <Statistic.Value text>
                        OVERPAID
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : invoice.status == "PAID" ? (
                    <Statistic color="green">
                      <Statistic.Value text>
                        {invoice.status}
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : invoice.status == "Paid in full" ? (
                    <Statistic color="green">
                      <Statistic.Value text>
                        {invoice.status}
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : (
                    <Statistic color="red">
                      <Statistic.Value text>
                        {invoice.status}
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  )}
                  <CustomAvatar style={{ margin: "0", marginLeft: "10px" }} />
                </div>
              </div>

              {/* First div  */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_id ? invoice?.client_id : "N/A"}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      <b>
                        {invoice?.client_firstname
                          ? invoice?.client_firstname
                          : "N/A"}
                      </b>
                      <b>
                        {invoice?.client_lastname
                          ? invoice?.client_lastname
                          : "N/A"}
                      </b>
                    </Header>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_addressline_one
                        ? invoice?.client_addressline_one
                        : "N/A"}
                    </Header>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_addressline_two
                        ? invoice?.client_addressline_two
                        : "N/A"}
                    </Header>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_city ? invoice?.client_city : "N/A"}
                    </Header>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_region ? invoice?.client_region : "N/A"}
                    </Header>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_postalcode
                        ? invoice?.client_postalcode
                        : "N/A"}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_country
                        ? invoice?.client_country
                        : "N/A"}
                    </Header>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.client_email ? invoice?.client_email : "N/A"}
                    </Header>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.id ? invoice?.id : "N/A"}
                    </Header>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ID number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.lin_id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist Residence:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.lin_country}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      VAT/GST no:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.lin_vat_no ? invoice?.lin_vat_no : "N/A"}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of quote:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;N/A
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of invoice:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {moment(invoice?.date_of_invoice).format("DD/MMM/YYYY")}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice to be paid by:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{moment(invoice?.invoice_tobe_paid_by).format("DD/MMM/YYYY")}
                      {/* {invoice?.invoice_tobe_paid_by} */}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Job reference:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.assignment_id ? invoice?.assignment_id : "N/A"}
                    </Header>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Service completion date:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.service_completion_date ? invoice?.service_completion_date : ""}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ref:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.linguist_ref ? invoice?.linguist_ref : "N/A"}
                    </Header>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Service:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.service ? invoice?.service : "N/A"}
                  </Header>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Expertise required:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.expertise_level
                      ? invoice?.expertise_level
                      : "N/A"}
                  </Header>
                </div>
              </div>

              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="5">
                      Description of service
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Price</Table.HeaderCell>
                    <Table.HeaderCell width="1">Quantity</Table.HeaderCell>
                    <Table.HeaderCell width="1">Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>

                  {invoice.productOne.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.text}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.amount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.quantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ))}

                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header textAlign="right" as="h3">
                        Sub Total
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.subtotal1}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Expenses and travel</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  {invoice.productTwo.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.otherText}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherAmount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherQuantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ))}

                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h3">Sub Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.subtotal2}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {Number(invoice?.subtotal1) +
                            Number(invoice?.subtotal2)}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Discount ({invoice?.discount_percentage})%
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.discount_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Billing Account Service Charge (
                        {invoice?.bacInvoice
                          ? invoice?.bac_service_charge
                          : "0"}
                        )%
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.bacInvoice
                            ? invoice?.bac_service_charge_amount
                            : "00.00"}{" "}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Platform and booking service charge (
                        {invoice?.service_charge})%
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.service_charge_amount
                            ? invoice?.service_charge_amount
                            : "00.00"}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">VAT ({invoice?.vat_percentage})%</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.vat_amount ? invoice?.vat_amount : "00.00"}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)} {invoice.total}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Estimate paid in advance</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {invoice?.estimate_paid_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Total to be paid including VAT/GST
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice?.currency?.substring(0, 3)}{" "}
                          {Number(invoice?.grand_total).toFixed(2)}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Header style={{ marginTop: "16px" }} as="h4">
                  Thank You For Your Business
                </Header>
                <Header
                  color="grey"
                  style={{ marginBottom: "16px", marginTop: "0" }}
                  as="h4"
                >
                  {invoice?.company_name} {invoice?.company_address}
                </Header>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default ViewPaidInvoice;
