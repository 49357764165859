import { Button, IconButton, Input } from "@material-ui/core";
import React, { Fragment } from "react";
import { ActionsDiv } from "./style";
import SaveIcon from "@material-ui/icons/Save";
import Axios from "axios";
import swal from "sweetalert";
import { changePreRegInput } from "redux/actions/preregAction";
import { useDispatch, useSelector } from "react-redux";

const Actions = ({
  id,
  total,
  discount,
  vat,
  deposit,
  paid,
  due,
  pbsc,
  basc,
  currency,
  bill_no,
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.preregReducer);
  const { note } = data;

  const onChange = (e) => {
    const value = e.target.value;
    dispatch(
      changePreRegInput({
        name: "note",
        value,
      })
    );
  };

  const handleSubmit = async (e) => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-Monthly-Bill/bacID/${id}`,
        {
          bill_no: bill_no,
          total: total,
          discount: discount,
          vat: vat,
          basc: basc,
          pbsc: pbsc,
          deposit: deposit,
          paid: paid,
          currency: currency,
          due: due,
          total_bill_amount: due,
          note: note,
        }
      );
      swal({
        title: "Success",
        text: `Bill successfully send to client!!!`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = `/admin/view-monthly-bill/${id}`;
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const sendBill = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleSubmit(value);

        default:
          break;
      }
    });
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        <Input
          style={{ width: "40%" }}
          placeholder="Note..."
          name="note"
          value={note}
          onChange={onChange}
        />
        <IconButton>
          <SaveIcon color="primary" />
        </IconButton>
      </div>
      <ActionsDiv>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: "15px" }}
        >
          Print
        </Button>

        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: "15px" }}
        >
          View PDF
        </Button>

        <Button variant="contained" color="primary" onClick={sendBill}>
          Send
        </Button>
      </ActionsDiv>
    </Fragment>
  );
};

export default Actions;
