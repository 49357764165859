import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import swal from "sweetalert";
import { Button } from "semantic-ui-react";
import Cookies from "js-cookie";


const Action = ({ id, linID, load, JOB,
  passport,
  ProfessionalMembership,
  SecurityClearance,
  Qualification,
  SPPOMemCer,
  SPPOUser,
  BACUser,
  AssignmentDocRequest,
  AssignmentDocument,
  CAC, BAC, ADM, EMP, Lead, Timesheet
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get('token')


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const deletePassport = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-linguist-document/docID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handlePassportDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this document?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deletePassport(value);

          default:
            break;
        }
      });
  }


  const DeleteProfessionalMembership = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/linguists/professional-membership/membershipListID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteProfessionalMembership = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteProfessionalMembership(value);

          default:
            break;
        }
      });
  }

  const DeleteSecurityClearance = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/linguists/security_clearance/securityID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteSecurityClearance = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteSecurityClearance(value);

          default:
            break;
        }
      });
  }

  const DeleteQualification = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/linguists/educational-qualification/educationID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteQualification = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteQualification(value);

          default:
            break;
        }
      });
  }


  const DeleteSPPOMemCer = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/sppo/delete-cooperate-certification/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteSPPOMemCer = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteSPPOMemCer(value);

          default:
            break;
        }
      });
  }


  const DeleteSPPOUser = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-sppo-user-request/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteSPPOUser = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteSPPOUser(value);

          default:
            break;
        }
      });
  }

  const DeleteBACUser = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-bac-user-request/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteBACUser = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteBACUser(value);

          default:
            break;
        }
      });
  }

  const DeleteAssignmentDocument = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/delete-assignment-document/docID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteAssignmentDocument = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteAssignmentDocument(value);

          default:
            break;
        }
      });
  }

  const DeleteAssignmentDocRequest = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-assignment-document-request/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteAssignmentDocRequest = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteAssignmentDocRequest(value);

          default:
            break;
        }
      });
  }

  const DeleteCAC = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-CAC-registration-request/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteCAC = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteCAC(value);

          default:
            break;
        }
      });
  }

  const DeleteBAC = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-BAC-registration-request/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteBAC = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteBAC(value);

          default:
            break;
        }
      });
  }

  const DeleteADM = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-ADM-registration-request/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteADM = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteADM(value);

          default:
            break;
        }
      });
  }

  const DeleteEMP = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-EMP-registration-request/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteEMP = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteEMP(value);

          default:
            break;
        }
      });
  }

  const DeleteLead = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-Lead-registration-request/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteLead = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteLead(value);

          default:
            break;
        }
      });
  }




  const DeleteJOB = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-jobadvert/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteJOB = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteJOB(value);

          default:
            break;
        }
      });
  }


  const DeleteTimesheet = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-timesheet/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleDeleteTimesheet = async (e, id) => {
    swal(`Are you sure you want to permanently delete this?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            DeleteTimesheet(value);

          default:
            break;
        }
      });
  }





  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}

      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {passport ?
          <MenuItem onClick={handlePassportDelete}>Delete</MenuItem> : null}
        {ProfessionalMembership ?
          <MenuItem onClick={handleDeleteProfessionalMembership}>Delete</MenuItem> : null}
        {SecurityClearance ?
          <MenuItem onClick={handleDeleteSecurityClearance}>Delete</MenuItem> : null}
        {Qualification ?
          <MenuItem onClick={handleDeleteQualification}>Delete</MenuItem> : null}
        {SPPOMemCer ?
          <MenuItem onClick={handleDeleteSPPOMemCer}>Delete</MenuItem> : null}
        {SPPOUser ?
          <MenuItem onClick={handleDeleteSPPOUser}>Delete</MenuItem> : null}
        {BACUser ?
          <MenuItem onClick={handleDeleteBACUser}>Delete</MenuItem> : null}
        {AssignmentDocument ?
          <MenuItem onClick={handleDeleteAssignmentDocument}>Delete</MenuItem> : null}
        {AssignmentDocRequest ?
          <MenuItem onClick={handleDeleteAssignmentDocRequest}>Delete</MenuItem> : null}
        {CAC ?
          <MenuItem onClick={handleDeleteCAC}>Delete</MenuItem> : null}
        {BAC ?
          <MenuItem onClick={handleDeleteBAC}>Delete</MenuItem> : null}
        {ADM ?
          <MenuItem onClick={handleDeleteADM}>Delete</MenuItem> : null}
        {EMP ?
          <MenuItem onClick={handleDeleteEMP}>Delete</MenuItem> : null}
        {Lead ?
          <MenuItem onClick={handleDeleteLead}>Delete</MenuItem> : null}
        {JOB ?
          <MenuItem onClick={handleDeleteJOB}>Delete</MenuItem> : null}
        {Timesheet ?
          <MenuItem onClick={handleDeleteTimesheet}>Delete</MenuItem> : null}

      </Menu>

    </div>
  );
};

export default Action;
