import React, { } from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Options from "components/Linguist/Jobs/Options";
import ClientOptions from "components/Linguist/Jobs/Quotation/Options";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CAC from 'components/Linguist/Quotation/CacQuoteRequest'

const InterpretingJobs = () => {
  const classes = useDashboardStyles();


  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Quote Requests (CAC)</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Options />
                </div>
              </CardHeader>



              <CardBody>
                <ClientOptions />
              </CardBody>

              <CardBody>
                <CAC />
              </CardBody>

            </Card>
          </GridItem>

        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default InterpretingJobs;
