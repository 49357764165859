import React from 'react'
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import ViewEmployee from 'components/Admin/HrPanel/Employee/ViewEmployee'
import { useParams } from 'react-router-dom'
import MasterLayout from 'layout/MasterLayout'
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";

import useDashboardStyles from "assets/jss/pages/dashboardStyle";


const ViewCACDetails = () => {
  const { id } = useParams()
  const classes = useDashboardStyles();

  return (

    <MasterLayout>
      <Manager>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Employee Profile</p>
                <IconButton color="inherit" onClick={history.goBack}>
                  <ArrowBackIcon />
                </IconButton>
              </CardHeader>
              <CardBody>
                <ViewEmployee id={id} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Manager>
    </MasterLayout>
  )
}

export default ViewCACDetails
