import React, { useEffect, useState } from "react";
import SPPO from "components/RouteProtection/Sppo/Sppo";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SppoLayout from "layout/SppoLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
// Custom Styles
import { Header } from "semantic-ui-react";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import SPPOJoining from "components/Sppo/SPPOFullRegistration/SPPORegistration";
import history from "utils/history";
import Axios from "axios";
import Cookies from "js-cookie";

const CompleteRegistration = () => {
  const classes = useDashboardStyles();
  const token = Cookies.get("SPPOtoken");
  const [profileUpdate, setProfileUpdate] = useState("");
  const [sppoID, setSPPOID] = useState(null);

  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProfileUpdate(response?.data.profile_update);
      setSPPOID(response?.data);
      if (response?.data.profile_update) {
        history.push("/sppo/profile");
      }
      //setData(response?.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <SppoLayout>
      <SPPO>
        <GridContainer>
          {sppoID?.agent_sppo ? ( //if sppo ref id is present or if its sppo user then this sppo can not access this page
            <Header as="h3">Access denied.</Header>
          ) : profileUpdate === false ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>
                    Complete Registration
                  </p>
                  <IconButton color="inherit">
                    <ArrowBackIcon
                      onClick={() => history.goBack()}
                    ></ArrowBackIcon>
                  </IconButton>
                </CardHeader>
                <CardBody>
                  <SPPOJoining />
                </CardBody>
              </Card>
            </GridItem>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </SPPO>
    </SppoLayout>
  );
};

export default CompleteRegistration;
