import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";

// Custom Components
import CashClientLayout from "layout/CashClientLayout";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistsCollective from "components/Auth/LinguistsCollective/LinguistsCollective";

const OrderTranslation = () => {
  return (
    <CashClientLayout>
      <Cashclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <LinguistsCollective />
          </GridItem>
        </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default OrderTranslation;
