import React, {} from 'react'
import history from "utils/history";
import StripeLayout from 'layout/StripeLayout';
import CheckoutFormPRC from 'layout/CheckoutFormLayout/CheckoutFormPRC';
import Cookies from 'js-cookie'


const PRCPayIntrWeb = () => {
    const grandTotal = Cookies.get('grandTotal')
    const currency = Cookies.get('stripeCurrency')
    const id = Cookies.get('privateclientID')

    return (
        <StripeLayout>
          <CheckoutFormPRC
            price = {grandTotal}
            currency = {currency}
            onSuccessfulCheckout={(id) => history.push(`/private-client/interpreter/success/estimate/payment/${id}`)}
            prcClientID = {id}
          />
        </StripeLayout>
    )
}

export default PRCPayIntrWeb
