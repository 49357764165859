import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import {
  bank_swift_code_DATA,
  bank_swift_code_DATA_SEM_UI,
  GENDER_DATA,
} from "data";
import moment from "moment";
import CustomUpload from "components/Common/CustomUpload";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("token");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("institute_name", data.institute_name || "");
    fd.append("graduation_date", data.graduation_date || "");
    fd.append("degree_name", data.degree_name || "");
    fd.append("subject_name", data.subject_name || "");

    try {
      const response = await Axios.put(
        `/api/manager-admin/update-employee/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="institute_name"
            onChange={handleChange}
            label="Name of the institute / school / college / university"
            edit={edit.value}
            value={data.institute_name}
          />
          <CustomTextInput
            name="graduation_date"
            onChange={handleChange}
            label="Year / date of graduation or course completion"
            edit={edit.value}
            value={data.graduation_date}
          />
          <CustomTextInput
            name="degree_name"
            onChange={handleChange}
            label="Name of the degree / course / diploma / qualification / CPD obtained"
            edit={edit.value}
            value={data.degree_name}
          />
          <CustomTextInput
            name="subject_name"
            onChange={handleChange}
            label="Subjects studied"
            edit={edit.value}
            value={data.subject_name}
          />
          {/* <CustomUpload name= 'relevant_certificate_photo' onChange= {handleChange} label= 'Upload relevant certificate and any reference letter (for your highest qualification)'/> */}
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
