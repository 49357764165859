import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from 'js-cookie'
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import Options from "components/Admin/ControlPanel/Backup/Options";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";





const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const [control_panel, setAdminAccess] = useState(null)

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminAccess(response?.data.control_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);


  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {control_panel ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className={classes.cardTitleWhite}>Backup</p>
                  <IconButton color="inherit">
                    <ArrowBackIcon
                      onClick={() => history.goBack()}
                    ></ArrowBackIcon>
                  </IconButton>
                </CardHeader>
              </Card>
              <Options />
            </GridItem>

            :
            control_panel === 0 ?
              <Header as='h3'>Access denied</Header>
              :
              <Dimmer active inverted>
                <Loader size='large'>Loading</Loader>
              </Dimmer>
          }

        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
