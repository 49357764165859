import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const useRateCardStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 0",

    "& h4": {
      fontSize: "24px",
      margin: "0",
    },

    "& li": {
      fontSize: "18px",
    },

    "& .footer-highlight": {
      fontSize: "16px",
      fontWeight: "bold",
    },

    "& b": {
      fontSize: "15px",
    },
  },

  details: {
    marginTop: "1rem",
    paddingLeft: "1rem",
    "& p": {
      fontSize: "1.2rem",

      "&:before": {
        display: "inline-block",
        content: "-",
      },
    },
  },
}));

export const SubDetailsDiv = styled.div`
  .icon-div {
    text-align: right;
  }

  textarea {
    width: 100%;
    font-size: 1.4rem;
  }
`;

export default useRateCardStyles;
