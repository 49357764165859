
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetch from 'isomorphic-fetch';
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";

const ViewContactRequest = () => {

  const classes = useDashboardStyles();
  const { id } = useParams();

  const [values, setValues] = useState({
    // title: "mst",
    // FirstName: "kona", 
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_API}/manager-admin/read-jobadvert-cashclient/${id}`)
      .then(res => res.json())
      .then((result) => {
        setValues(result)
      })
  }, [])

  return (
    <Manager>
      <MasterLayout>

        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>Inhouse Translation Assignment - {values.id}</p>

          </CardHeader>
        </GridItem>

        <div raised style={{ marginLeft: '15%', marginRight: '15%' }}>

          <Card raised style={{ marginTop: '24px' }} >
            <CardBody>
              <h4 style={{ textAlign: "center" }}>Inhouse Translation Assignment Details</h4>
            </CardBody>
          </Card>
          <Card raised style={{ marginTop: '24px', padding: '30px' }} >
            <CardBody>
              {/* <h4 style={{textAlign: "center"}}>Detail Information </h4> */}
              <p>Purchase Order Number: {values.purchase_order_number} </p>
              <p>Job Title: {values.job_title} </p>
              <p>Salary: {values.salary} </p>
              <p>Contract Type: {values.contract_type}  </p>
              <p>Hours Per Week: {values.hours_per_week}  </p>
              <p>Position: {values.position}  </p>
              <p>Remote Address: {values.remote_address}  </p>

            </CardBody>
          </Card>

          <Card raised style={{ marginTop: '24px', padding: '30px' }} >
            <CardBody>
              {/* <h4 style={{textAlign: "center"}}>Detail Information </h4> */}

              <p>Address: {values.address_line_two}  </p>
              <p>City: {values.town_city}  </p>
              <p>Post Code: {values.post_code}  </p>
              <p>Country: {values.country}  </p>
              <p>Region: {values.county_region}  </p>
              <p>Nationality: {values.current_nationality}  </p>
              <p>Other Nationality: {values.other_nationalities}  </p>

            </CardBody>
          </Card>
          <Card raised style={{ marginTop: '24px', padding: '30px' }} >
            <CardBody>
              {/* <h4 style={{textAlign: "center"}}>Detail Information </h4> */}

              <p>Address: {values.job_ref}  </p>
              <p>Target Language: {values.target_language}  </p>
              <p>Source Language: {values.source_language}  </p>
              <p>Additional Language: {values.additional_language}  </p>
              <p>Linguist Skill Level: {values.linguist_skills_level}  </p>
              <p>About Company: {values.about_company}  </p>
              <p>Job Description: {values.job_description}  </p>
              <p>Application Closing Date: {values.application_closing_date}  </p>
              <p>NHS Trust: {values.nhs_trust}  </p>
              <p>Registration Number: {values.registration_number}  </p>

            </CardBody>
          </Card>
          <br /><br /><br />
        </div>

      </MasterLayout>
    </Manager>
  );
};

export default ViewContactRequest;
