import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { providedQuotes } from "redux/actions/unconfirmQuotesAction";
import Axios from "axios";
import swal from "sweetalert";

const Action = ({ id, ticketDetails, status, load }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const handleDetailsClick = () => {
    dispatch(providedQuotes(id));
    ticketDetails(id);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteTicket = async (e) => {
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/delete-ticket/ticketID/${id}`
      );

      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/admin/view-tickets";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error.response?.data}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure to delete this ticket?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          deleteTicket(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDetailsClick}>Details</MenuItem>
        {!status ? <MenuItem onClick={handleDelete}>Delete</MenuItem> : null}
      </Menu>
    </div>
  );
};

export default Action;
