import {
  GET_LBOOKING_NOTIFICATION,
  MARK_ALL_READ,
  ADMIN_NOTIFICATIONS,
  SET_SOCKET,
} from "redux/actions/notifications/actionType";

const INITIAL_STATE = {
  socket: null,
  notifications: [],
  adminNotifications: [],
};

const noficationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SOCKET:
      return {
        ...state,
        socket: payload,
      };
    case GET_LBOOKING_NOTIFICATION:
      console.clear();

      const upatedData = state.notifications.map((item) => {
        if (item.type === payload.type) {
          return payload;
        }
        return item;
      });

      return {
        ...state,
        notifications: state.notifications.length ? upatedData : [payload],
      };

    case ADMIN_NOTIFICATIONS:
      return {
        ...state,
        adminNotifications: payload,
      };

    case MARK_ALL_READ:
      return {
        ...state,
        notifications: [],
        adminNotifications: [],
      };

    default:
      return state;
  }
};

export default noficationReducer;
