import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import history from "utils/history";
// Material Core Components
import Button from "@material-ui/core/Button";

const Action = ({ type, fn, handleAccept, handleDecline, id, email, declineOp }) => {
  const history = useHistory();
const handleNext = () => {
  history.push("/linguist/create-quotation")
}
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {type === "all" ? (
        <Button color="primary" variant="contained" onClick={fn}>
          View All
        </Button>
      ) : (
        <Fragment>
          {history.location.pathname.includes("quote") ? (
            <Button
              variant="contained"
              color="primary"
              style={{
                marginBottom: "10px",
              }}
              onClick={() => history.push(`/linguist/quote-others/create-quote/${id}`)}
            >
              Provide Quote
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick = {(e) => handleAccept(e, id, email)}
              style={{
                marginBottom: "10px",
              }}
             
            >
              Accept
            </Button>
          )}

          {declineOp ? 
            <select onChange= {(e) => handleDecline(e, id, email)} className="ui dropdown">
              <option>Decline</option>
              <option value="Not profitable">Not profitable</option>
              <option value="Venue is too far">Venue is too far</option>
              <option value="Unavailable / booked">Unavailable / booked</option>
              <option value="On holiday">On holiday</option>
              <option value="Not interested in job from this client">Not interested in job from this client</option>
              <option value="Don't feel confident">Don't feel confident</option>
              <option value="Too large project">Too large project</option>
              <option value="Too large project">Project too small</option>
              <option value="Too large project">Complicated project</option>
              <option value="Too large project">Not interested working in a team</option>
              <option value="Not interested providing quote">Not interested providing quote</option>
              <option value="Need more information">Need more information</option>
              <option value="Other">Other</option>
            </select>
          :
          <Button onClick = {() => handleDecline(id, email)} color="secondary" variant="outlined">
            Decline
          </Button>
          }
        </Fragment>
      )}
    </div>
  );
};

export default Action;
