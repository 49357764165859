import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

// Custom Components

// Custom Styles
import ViewAttach from "components/Admin/Communication/ViewAttachTicket";

const ViewContactCard = () => {

  return (
    <Manager>
      <MasterLayout>
        <ViewAttach />
      </MasterLayout>
    </Manager>
  );
};

export default ViewContactCard;
