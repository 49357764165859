import React from "react";
import MasterLayout from "layout/MasterLayout";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
// Custom Components

import BISummaryadmin from "components/Auth/OTT/BISummary";

const BIPaymentPage = () => {
  return (


    <MasterLayout>
      <Manager>
        <BISummaryadmin />
      </Manager>
    </MasterLayout>
  );
};

export default BIPaymentPage;
