import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Header } from "semantic-ui-react";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles({
  noPrint: {
    "@media print": {
      display: "none !important",
    },
  },
});
const SuccessINCINV = () => {
  const { id } = useParams();
  const { invoiceId } = useParams();
  const orderId = Cookies.get("orderID");
  const [session, setSession] = useState(null);
  const token = Cookies.get("BACtoken");
  const [sendMail, setSendMail] = useState(null);
  const bookingid = Cookies.get("bookingid");
  const printClasses = useStyles();

  const changePaymentStatus = async (session) => {
    try {
      const response = await Axios.put(
        `/api/accountclient/stripe-payment-quote/linQuoteID/${invoiceId}`,
        {
          currency_new: session.currency,
          new_total: session.amount_received / 100,
          bookingid: bookingid,
          payment_intent: session.id,
          charge_id: session.charges.data[0].id,
          payment_date: moment
            .unix(session.created)
            .format("DD/MMM/YYYY HH:mm"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSendMail(response?.data);
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    const retreiveSession = async (e) => {
      try {
        const response = await Axios.get(`/api/retrieve/payment/intent/${id}`);
        setSession(response?.data);
        changePaymentStatus(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    retreiveSession();
  }, []);

  return session ? (
    <div style={{ backgroundColor: "purple", height: "100%", padding: "30px" }}>
      <Container
        style={{
          backgroundColor: "white",
          color: "purple",
          height: "100%",
          padding: "10px",
          borderRadius: "10px",
        }}
        textAlign="center"
      >
        {/* <Card> */}
        <Header as="h3">
          Payment successful. Thank you,{" "}
          {session.charges.data[0].billing_details.name}.
        </Header>
        <Header as="h4">Please check your email / dashboard.</Header>
        <Header as="h3">
          Charge / Transaction ID - {session.charges.data[0].id}
        </Header>
        <Header as="h3">
          Amount Paid -{" "}
          {session.currency.toUpperCase() + " " + session.amount_received / 100}
        </Header>
        <Header as="h3">
          {moment.unix(session.created).format("DD/MMM/YYYY HH:mm")}
        </Header>
        {!sendMail ? (
          <div>
            <img
              style={{ width: "300px", height: "200px", marginBottom: "10px" }}
              src="https://i.pinimg.com/originals/98/64/9a/98649add72e05e3cc1b8ae0e6f553c8e.gif"
              alt=""
            />
            <Header as="h3">Email sending...</Header>
            <Header as="h3">Please wait</Header>
          </div>
        ) : (
          <div className={printClasses.noPrint}>
            <Header as="h3">Email sent!</Header>
            <Button
              onClick={() => (window.location = "/account-client/dashboard")}
              basic
              color="purple"
            >
              Back to dashboard
            </Button>
            <Button onClick={() => window.print()} color="purple">
              Print
            </Button>
          </div>
        )}
      </Container>
    </div>
  ) : null;
};

export default SuccessINCINV;
