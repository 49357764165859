import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import CustomTextInput from "components/Common/CustomTextInput";
import { Button, Dimmer, Header, Loader, Table } from "semantic-ui-react";
import "./sigCanvas.css";
import moment from "moment";
import Signature from "./signature";
import { TextField } from "@material-ui/core";
import CustomTime from "components/Common/CustomTime";
import history from "utils/history";
import swal from "sweetalert";
import Cookies from "js-cookie";

const Details = () => {
  const { id } = useParams();
  const [details, setDetails] = useState();
  const [data, setData] = useState({
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    loader: false,
  });
  const token = window.location.pathname.includes("linguist")
    ? Cookies.get("LINtoken")
    : window.location.pathname.includes("cash-client")
    ? Cookies.get("CACtoken")
    : window.location.pathname.includes("account-client")
    ? Cookies.get("BACtoken")
    : window.location.pathname.includes("admin") && Cookies.get("token");

  const uploadRef = useRef();
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });
    const fd = new FormData();

    fd.append("start_date", data.start_date);
    fd.append("end_date", details.end_date);
    fd.append("start_time", data.start_time);
    fd.append("end_time", details.end_time);

    if (uploadTimesheet?.size) {
      fd.append("document", uploadTimesheet);
    }

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/edit-project-report/ReportID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDetails({ ...details, loader: false });
      swal({
        text: "Project Report Updated",
        buttons: "Ok",
        icon: "success",
      });
      history.push(`/linguist/current-assignment`);
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getTimeSheet = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-project-report/reportID/${id}`
        );
        setDetails(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    getTimeSheet();

    return () => {
      source.cancel();
    };
  }, []);

  return details ? (
    <div>
      <CustomTextInput
        label="Assignment reference number"
        value={
          details?.cacTranslationID?.id
            ? details?.cacTranslationID?.id
            : details?.bacTranslationID?.id
            ? details?.bacTranslationID?.id
            : details?.incTranslationID?.id
            ? details?.incTranslationID?.id
            : details?.prcTranslationID?.id
            ? details?.prcTranslationID?.id
            : details?.prcBookingID?.id
            ? details?.prcBookingID?.id
            : details?.prcDirectQuoteID?.id
            ? details?.prcDirectQuoteID?.id
            : details.linQuoteID?.quoteRequstByCashclient?.id
            ? details.linQuoteID?.quoteRequstByCashclient?.id
            : details.linQuoteID?.quoteRequstByAccountclient?.id
            ? details.linQuoteID?.quoteRequstByAccountclient?.id
            : details?.QuoteID
            ? details?.QuoteID
            : "N/A"
        }
      />

      {/* Table */}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center">Requested</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actual</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Project start date: </Table.Cell>

            <Table.Cell textAlign="center">
              {details.requested_start_date}
            </Table.Cell>

            <Table.Cell textAlign="center">
              <TextField
                type="date"
                onChange={(e) =>
                  setData({ ...data, start_date: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project start time: </Table.Cell>
            <Table.Cell textAlign="center">
              {details.requested_start_time}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {" "}
              <CustomTime
                onChange={(e) =>
                  setData({ ...data, start_time: e.target.value })
                }
                width="100%"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project completion date: </Table.Cell>
            <Table.Cell textAlign="center">
              {details.requested_end_date}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details.end_date &&
                moment(details.end_date).format("DD/MMM/YYYY")}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project end time: </Table.Cell>
            <Table.Cell textAlign="center">
              {details.requested_end_time}
            </Table.Cell>
            <Table.Cell textAlign="center">{details.end_time}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell textAlign="center">
              {details?.cacTranslationID?.target_expertise_level_required
                ? details?.cacTranslationID?.target_expertise_level_required
                : details?.bacTranslationID?.target_expertise_level_required
                ? details?.bacTranslationID?.target_expertise_level_required
                : details?.incTranslationID?.target_language_skill_level
                ? details?.incTranslationID?.target_language_skill_level
                : details?.prcTranslationID?.target_linguist_skill_level
                ? details?.prcTranslationID?.target_linguist_skill_level
                : details?.prcBookingID?.target_expertise_level_required
                ? details?.prcBookingID?.target_expertise_level_required
                : details.linQuoteID?.quoteRequstByCashclient
                    ?.target_skills_required
                ? details.linQuoteID?.quoteRequstByCashclient
                    ?.target_skills_required
                : details.linQuoteID?.quoteRequstByAccountclient
                    ?.target_skills_required
                ? details.linQuoteID?.quoteRequstByAccountclient
                    ?.target_skills_required
                : details.directQuoteID?.skill_level
                ? details.directQuoteID?.skill_level
                : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.postedByLin?.target_language_level
                ? details?.postedByLin?.target_language_level
                : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Security clearance/s</Table.Cell>
            <Table.Cell textAlign="center">
              {details?.linQuoteID?.quoteRequstByCashclient?.security_clearance
                ? details?.linQuoteID?.quoteRequstByCashclient
                    ?.security_clearance
                : details?.linQuoteID?.quoteRequstByAccountclient
                    ?.security_clearance
                ? details?.linQuoteID?.quoteRequstByAccountclient
                    ?.security_clearance
                : details?.cacTranslationID?.security_clearance
                ? details?.cacTranslationID?.security_clearance
                : details?.bacTranslationID?.security_clearance
                ? details?.bacTranslationID?.security_clearance
                : details?.incTranslationID?.security_clearance
                ? details?.incTranslationID?.security_clearance
                : details?.prcTranslationID?.security_clearance
                ? details?.prcTranslationID?.security_clearance
                : details?.prcBookingID?.security_clearance
                ? details?.prcBookingID?.security_clearance
                : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.postedByLin?.title_security_clearance_1
                ? details?.postedByLin?.title_security_clearance_1
                : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Wordcount</Table.Cell>
            <Table.Cell textAlign="center">
              {details?.requested_wordCount == "undefined"
                ? "N/A"
                : details?.requested_wordCount
                ? details?.requested_wordCount
                : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.word_count == "undefined"
                ? "N/A"
                : details?.word_count
                ? details?.word_count
                : details?.cacTranslationID?.wordCount
                ? details?.cacTranslationID?.wordCount
                : details?.bacTranslationID?.wordCount
                ? details?.bacTranslationID?.wordCount
                : details?.incTranslationID?.count
                ? details?.incTranslationID?.count
                : details?.prcTranslationID?.count
                ? details?.prcTranslationID?.count
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Visual formatting</Table.Cell>
            <Table.Cell textAlign="center">
              {details?.visual_formatting == "undefined"
                ? "N/A"
                : details?.visual_formatting
                ? details?.visual_formatting
                : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.visual_formatting == "undefined"
                ? "N/A"
                : details?.visual_formatting
                ? details?.visual_formatting
                : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Certification</Table.Cell>
            <Table.Cell textAlign="center">
              {details?.certification == "undefined"
                ? "N/A"
                : details?.certification
                ? details?.certification
                : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.certification == "undefined"
                ? "N/A"
                : details?.certification
                ? details?.certification
                : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Post certification </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.post_certification == "undefined"
                ? "N/A"
                : details?.post_certification
                ? details?.post_certification
                : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.post_certification == "undefined"
                ? "N/A"
                : details?.post_certification
                ? details?.post_certification
                : "N/A"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">Project Report</Header>

      <p>
        {details.project_report == "undefined" ? "N/A" : details.project_report}
      </p>

      <Signature details={details} />

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button
        onClick={() => uploadRef.current.click()}
        basic
        style={{ backgroundColor: "purple", color: "white" }}
      >
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          loading={data.loader}
          onClick={handleSubmit}
          disabled={!data.start_date || !data.start_time || data.loader}
          color="purple"
        >
          Amend Project Report
        </Button>
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="large">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
