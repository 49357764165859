import React, { useState, useRef } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import swal from "sweetalert";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import history from "utils/history";
import CustomModal from "components/Common/CustomModal";
import { Button, Header, Icon, Loader, Modal } from "semantic-ui-react";
import CustomTextInput from "components/Common/CustomTextInput";
import SignatureCanvas from "react-signature-canvas";

const Action = ({
  id,
  invID,
  load,
  status,
  report,
  name,
  ID,
  reportID,
  bank_pay,
  payment,
  currency,
  invoice,
  total,
  estimate,
  refunded,
  markPaid,
  AdminID,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("token");

  const [showMarkPaid, setShowMarkPaid] = React.useState(false);
  const [showRefund, setShowRefund] = React.useState(false);

  const [showNote, setShowNote] = React.useState(false);

  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  let sigPad = useRef({});

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const [pay, setPay] = React.useState({
    note: "",
    payment_ref: "",
    estimate_paid_amount: "",
    payment_date: "",
    signature: "",
    cancel_by_admin_client: "",
    refund_date: "",
    refund_reason: "",
    refund_amount: "",
    ccEmail: "",
    bccEmail: "",
  });

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowNote(false);
  };

  const handleMarkPaid = (e) => {
    setAnchorEl(false);
    setShowMarkPaid(!showMarkPaid);
    setSignature(false);
  };

  const handleRefund = (e) => {
    setAnchorEl(false);
    setShowRefund(!showRefund);
    setSignature(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const sendQuote = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/send-client-Quote/QuoteID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Quote sent!!!`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleSend = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          sendQuote(value);

        default:
          break;
      }
    });
  };

  const sendArchive = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/archive-client-Quote/QuoteID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Moved to archive folder`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleArchive = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          sendArchive(value);

        default:
          break;
      }
    });
  };

  const handleConfirm = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/mark-paid-direct-client-quote/quoteID/${id}`,
        {
          payment_date: pay.payment_date,
          payment_ref: pay.payment_ref,
          estimate_paid_amount: parseInt(pay.estimate_paid_amount),
          note: pay.note,
          signature: sign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: response?.data.message,
        buttons: "Ok",
      });

      setPay({
        ...pay,
        estimate_paid_amount: "",
        payment_ref: "",
        payment_date: "",
      });
      setSign(null);
      load();
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: error.response?.data.raw.message,
        buttons: "Ok",
      });
    }
  };

  // refund function

  const RefundInv = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/refund-direct-client-quote/quoteID/${id}`,
        {
          refund_date: pay.refund_date,
          refund_reason: pay.refund_reason,
          refund_amount: parseInt(pay.refund_amount * 100),
          note: pay.note,
          signature: sign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: response?.data.message,
        buttons: "Ok",
      });

      setPay({ ...pay, refund_amount: "", refund_date: "", refund_reason: "" });
      setSign(null);
      load();
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: error.response?.data.raw.message,
        buttons: "Ok",
      });
    }
  };

  const handleProject = (e) => {
    history.push(`/admin/create-direct-quote-project-report/${id}`);
  };

  const handleViewProject = (e) => {
    history.push(`/admin/view-project-report/${reportID}`);
  };

  const handleInvoice = (e) => {
    history.push(`/admin/create-direct-quote-invoice/${id}`);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {status == "Saved" ? (
          <MenuItem onClick={handleSend}>Send Quote</MenuItem>
        ) : null}

        {status == "Sent/Unpaid" && !payment && bank_pay ? (
          <MenuItem onClick={handleMarkPaid}>Mark as paid</MenuItem>
        ) : null}

        {payment && !report ? (
          <MenuItem onClick={handleProject}>Create project report</MenuItem>
        ) : null}

        {report && !invoice ? (
          <>
            <MenuItem onClick={handleViewProject}>View project report</MenuItem>
            <MenuItem onClick={handleInvoice}>Create Invoice</MenuItem>{" "}
          </>
        ) : null}

        {payment && !refunded ? (
          <MenuItem onClick={handleRefund}>Refund</MenuItem>
        ) : null}

        <MenuItem onClick={handleArchive}>Archive (delete)</MenuItem>
      </Menu>

      {/* Mark as paid modal */}
      <Modal open={showMarkPaid} onClose={() => setShowMarkPaid(false)}>
        <Modal.Header>
          Payment Details - {invID}, {currency} Total - {total} Paid -{" "}
          {estimate}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomTextInput
              name="payment_date"
              onChange={handleChange}
              dvalue={pay.payment_date}
              label="Payment date"
              dlabel="Payment date "
              date={true}
            />
            <CustomTextInput
              name="payment_ref"
              onChange={handleChange}
              value={pay.payment_ref}
              label="Payment reference "
              edit={true}
            />
            <CustomTextInput
              number={true}
              name="estimate_paid_amount"
              onChange={handleChange}
              value={pay.estimate_paid_amount}
              label="Payment amount"
              edit={true}
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
            <CustomTextInput label="Admin Id" value={AdminID} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {/* {paidby === "Paid by card" ? */}
          <Button
            disabled={
              !pay.payment_ref ||
              !sign ||
              !pay.payment_date ||
              !pay.estimate_paid_amount ||
              edit.loader
            }
            onClick={handleConfirm}
            color="purple"
          >
            Confirm <Icon name="chevron right" />
          </Button>
          {/* :
            <Button disabled={!pay.payment_ref || !sign || !pay.payment_date || !pay.estimate_paid_amount || edit.loader}
              onClick={RefundJob} color='purple'>
              Confirm <Icon name='chevron right' />
            </Button>} */}
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>

      {/* Refund Payment modal */}
      <Modal open={showRefund} onClose={() => setShowRefund(false)}>
        <Modal.Header>
          Refund Details - {invID}, {currency} {total}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomTextInput
              name="refund_date"
              onChange={handleChange}
              dvalue={pay.refund_date}
              label="Refund date"
              dlabel="Refund date "
              date={true}
            />
            <CustomTextInput
              name="refund_reason"
              onChange={handleChange}
              value={pay.refund_reason}
              label="Refund reference "
              edit={true}
            />
            <CustomTextInput
              number={true}
              name="refund_amount"
              onChange={handleChange}
              value={pay.refund_amount}
              label="Refund amount"
              placeholder={total}
              edit={true}
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
            <CustomTextInput label="Admin Id" value={AdminID} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              !pay.refund_reason ||
              !sign ||
              !pay.refund_date ||
              !pay.refund_amount ||
              edit.loader
            }
            onClick={RefundInv}
            color="purple"
          >
            Confirm <Icon name="chevron right" />
          </Button>
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};

export default Action;
