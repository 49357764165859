import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";


// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Invoice from "components/Linguist/CreateInvoice/Invoice";

// Custom Styles

const InvoicePage = () => {

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardBody>
                <Invoice />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default InvoicePage;
