import CustomTextInput from 'components/Common/CustomTextInput'
import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux';

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false
  })
  const token = Cookies.get('token')
  const { data } = useSelector(state => state.updateProfileReducer)
  const dispatch = useDispatch()


  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <div style={{ flex: '1' }}>
        <CustomTextInput label="First name" value={data.del_first_name} />
        <CustomTextInput label="Last name" value={data.del_last_name} />
        <CustomTextInput label='Email' value={data.del_email} />
        <CustomTextInput label='Mobile number' value={data.del_mobile_no} />
        <CustomTextInput label='Reason' value={data.reasons} />
      </div>
    </div>
  )
}

export default PersonalDetails
