import React, { useState, useEffect } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import CustomAvatar from "components/Common/CustomAvatar";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Header } from "semantic-ui-react";
import CustomRadio from "components/Common/CustomRadio";
import Cookies from "js-cookie";
import history from "utils/history";
import { Accordion, Icon } from "semantic-ui-react";
import { Divider, Button, Typography } from "@material-ui/core";
//components
import VerifyBAC from "./VerifyBACpage";
import VerifyCAC from "./VerifyCACpage";
import VerifyINC from "./VerifyINCpage";
import VerifyPRC from "./VerifyPRCpage";
import { CLIENT_TYPE } from "data";
const InterpretingOrTranslation = () => {
  const classes = useEmployeeStyles();
  const [clientType, setClientType] = useState("");
  const [verified, setVerified] = useState(false);
  const [activeIndex, setActiveIndex] = useState("0");
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setClientType(value);
  };
  useEffect(() => {
    if (Cookies.get("CACtoken") || Cookies.get("BACtoken")) {
      setVerified(true);
    }

    if (verified) {
      history.push("interpreting-or-translation/select-form");
    }
  }, [clientType, verified]);

  return (
    <LandingPageLayout>
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
            <Card>
              <CardBody>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomAvatar />
                </div>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <Header style={{ margin: "0" }} as="h3">
                    Linguists Collective (LC)
                  </Header>
                  <Header style={{ margin: "0" }} as="h3">
                    Interpreting booking, translation ordering and quotation
                    request form
                  </Header>
                </div>

                <>
                  {" "}
                  <CustomRadio
                    onChange={handleRadioChange}
                    label="Are you registered with LC?"
                    moreInfo="If not press No, Will Register"
                    width="100%"
                    data={CLIENT_TYPE}
                    name="client_type"
                    value={clientType}
                    required
                  />
                  {clientType.includes("Yes, Cash Account Client") && (
                    <VerifyCAC setVerified={setVerified} />
                  )}
                  {clientType.includes("Yes, Billing Account Client") && (
                    <VerifyBAC setVerified={setVerified} />
                  )}
                  {clientType.includes("Yes, In-house Client") && <VerifyINC />}
                  {clientType.includes("Yes, Private Client") && <VerifyPRC />}
                  {clientType.includes("No, Will Register") && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Card>
                          <CardBody>
                            <Header as="h3" textAlign="center">
                              I want to register as a Cash Account Client (CAC)
                            </Header>
                            <Divider orientation="horizontal" />
                            <Header size="small">
                              CAC refers to a Cash Account Client. We call them
                              Cash Clients as they have to pay the estimated sum
                              in advance in order to book an interpreter, order
                              translation online or confirm a free quotation.
                            </Header>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: 10,
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: "purple",
                                  color: "white",
                                }}
                                onClick={() =>
                                  history.push("/customer-registration")
                                }
                                variant="contained"
                              >
                                Register
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Card>
                          <CardBody>
                            <Header as="h3" textAlign="center">
                              I want to register as a Billing Account Client
                              (BAC)
                            </Header>
                            <Divider orientation="horizontal" />
                            <Header size="small">
                              Billing Account Client is referred as BAC by LC.
                              BAC are mainly large organisations or SMEs that
                              have a contract in place between LC and BAC and
                              pays monthly bill. BAC not required to pay in
                              advance for their booking interpreter, ordering
                              translation or confirming a quote.
                            </Header>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: 10,
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: "purple",
                                  color: "white",
                                }}
                                onClick={() =>
                                  history.push("/clientacc-opening-form")
                                }
                                variant="contained"
                              >
                                Register
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </div>
                  )}
                  {clientType === "" && (
                    <>
                      <Header as="h3">Need Help?</Header>
                      <Accordion>
                        <Accordion.Title
                          active={activeIndex === 0}
                          index={0}
                          onClick={handleClick}
                        >
                          <Icon name="dropdown" />
                          What is a CAC?
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                          <p>
                            CAC refers to a Cash Account Client. We call them
                            Cash Clients as they pay the estimated sum for their
                            service in advance in order to book an interpreter,
                            order translation online or confirm a free quote.
                            However, CAC can request for 'Pay Later' option to
                            be activated, particularly for UK Legal Aid jobs.
                          </p>
                        </Accordion.Content>

                        <Accordion.Title
                          active={activeIndex === 1}
                          index={1}
                          onClick={handleClick}
                        >
                          <Icon name="dropdown" />
                          What is a BAC?
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 1}>
                          <p>
                            Billing Account Client is referred as BAC by LC. BAC
                            are mainly large organisations or SMEs that have a
                            contract in place between LC and BAC and pays
                            monthly bill. BAC not required to pay in advance for
                            their booking interpreter, ordering translation or
                            confirming a quote.
                          </p>
                        </Accordion.Content>

                        <Accordion.Title
                          active={activeIndex === 2}
                          index={2}
                          onClick={handleClick}
                        >
                          <Icon name="dropdown" />
                          What is a INC?
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 2}>
                          <p>
                            An in-house client is a client registered with a LC
                            Linguist or SPPO who can place orders for services
                            in translation or interpreting via that Agent
                            Linguist or SPPO public profile.
                          </p>
                        </Accordion.Content>

                        <Accordion.Title
                          active={activeIndex === 3}
                          index={3}
                          onClick={handleClick}
                        >
                          <Icon name="dropdown" />
                          What is a PRC?
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 3}>
                          <p>
                            PRC stands for Private Clients of Linguists. Private
                            clients are our Linguists' own clients to whom they
                            provide services in their specialist language while
                            being qualified and registered to provide services
                            for the Linguists Collective.
                          </p>
                        </Accordion.Content>
                      </Accordion>
                    </>
                  )}
                </>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </LandingPageLayout>
  );
};
export default InterpretingOrTranslation;
