import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import Typography from "@material-ui/core/Typography";
import LoadingScreen from "react-loading-screen";
import { CURRENCY_x } from "data";
// Material Icons
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { changeBaInput } from "redux/actions/bookingAction";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import logo from "assets/images/logo.png";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Axios from "axios";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
} from "@material-ui/core";
import history from "utils/history";
import Cookies from "js-cookie";

import swal from "sweetalert";
import CustomSelect from "components/Common/CustomSelect";

const BISummary = () => {
  const classes = useEmployeeStyles();
  const classesb = useLandingpageStyles();
  const { data } = useSelector((state) => state.bookingReducer);
  const [loading, setLoading] = useState(false);
  const { currency_new, upload_file } = data;

  const [edit, setEdit] = React.useState({
    loader: false,
  });

  const [rates, setRates] = useState("");

  const dispatch = useDispatch();

  const { username } = JSON.parse(localStorage.getItem("EMPuser"));

  //for currency
  const New_currency = Cookies.get("currency");

  const BACusername = Cookies.get("BACusername");
  //id
  const summary = Cookies.get("id");

  const Word_count = Cookies.get("wordCount");

  //totalPriceForTranslation
  const TotalPriceForTranslation = Cookies.get("totalPriceForTranslation") || 0;

  //totalPriceForCertificate
  const TotalPriceForCertificate = Cookies.get("totalPriceForCertificate") || 0;

  const word_count = Cookies.get("wordCount");
  //totalPriceForQualityCheck
  const TotalPriceForQualityCheck =
    Cookies.get("totalPriceForQualityCheck") || 0;

  //translationVisualFormatingPrice
  const TranslationVisualFormatingPrice =
    Cookies.get("translationVisualFormatingPrice") || 0;
  const token = Cookies.get("EMPtoken");

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
    ).then((res) => setRates(res?.data?.rates));
  }, []);

  let a = parseFloat(TranslationVisualFormatingPrice) || 0;

  let b = parseFloat(TotalPriceForCertificate) || 0;

  let c = parseFloat(TotalPriceForTranslation) || 0;

  let d = parseFloat(TotalPriceForQualityCheck) || 0;

  const estimatedTotal = a + b + c + d;

  const handleChange = (e) => {
    // alert( e.target.value)
    dispatch(
      changeBaInput({
        name: "currency_new",
        value: e.target.value,
      })
    );
  };

  const sendLink = async (e) => {
    setEdit({ ...edit, loader: true });
    let id = summary;

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/employee/BAC/sendlink-translation-document/assignmentID/${id}`,
        " ",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `Send Link For Document/s Upload`,
        icon: "success",
        buttons: "Ok",
      });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Failed!!!`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleSend = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          sendLink(value);

        default:
          break;
      }
    });
  };

  const handleNext = async (e) => {
    setLoading(true);
    let id = summary;
    // alert("doing")
    if (currency_new === "EUR (Euro countries)") {
      let x =
        estimatedTotal * rates.EUR + ((estimatedTotal * 2.5) / 100) * rates.EUR;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail`,
        { currency_new, new_total, username, BACusername, rates, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
    } else if (currency_new === "AUD (Australia)") {
      let x =
        estimatedTotal * rates.AUD + ((estimatedTotal * 2.5) / 100) * rates.AUD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail`,
        { currency_new, new_total, username, BACusername, rates, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
    } else if (currency_new === "USD (USA, paying from USA only)") {
      let x =
        estimatedTotal * rates.USD + ((estimatedTotal * 2.5) / 100) * rates.USD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail`,
        { currency_new, new_total, username, BACusername, rates, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
    } else if (currency_new === "CAD (Canada)") {
      let x =
        estimatedTotal * rates.CAD + ((estimatedTotal * 2.5) / 100) * rates.CAD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail`,
        { currency_new, new_total, username, BACusername, rates, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
    } else if (
      currency_new === "TRY (Turkish lira, payment from Turkey only)"
    ) {
      let x =
        estimatedTotal * rates.TRY + ((estimatedTotal * 2.5) / 100) * rates.TRY;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail`,
        { currency_new, new_total, username, BACusername, rates, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
    } else if (currency_new === "SGD (Singapore dollar)") {
      let x =
        estimatedTotal * rates.SGD + ((estimatedTotal * 2.5) / 100) * rates.SGD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail`,
        { currency_new, new_total, username, BACusername, rates, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
    } else if (currency_new === "HUF (Hungarian forint)") {
      let x =
        estimatedTotal * rates.HUF + ((estimatedTotal * 2.5) / 100) * rates.HUF;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail`,
        { currency_new, new_total, username, BACusername, rates, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
    } else if (
      currency_new === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      let x =
        estimatedTotal * rates.NZD + ((estimatedTotal * 2.5) / 100) * rates.NZD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail`,
        { currency_new, new_total, username, BACusername, rates, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
    } else if (
      currency_new === "RON (Romanian leu, paying from Romania only)"
    ) {
      let x =
        estimatedTotal * rates.RON + ((estimatedTotal * 2.5) / 100) * rates.RON;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail`,
        { currency_new, new_total, username, BACusername, rates, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
    } else {
      let new_total = estimatedTotal.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail`,
        { currency_new, new_total, username, BACusername, rates, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
    }

    try {
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/admin/sendEmail-AUSDAccount`,
        { currency_new, estimatedTotal, username, rates, BACusername, id }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `and pay to confirm your booking. You recive selected bank details via email kindly check it `,
              icon: "success",
              buttons: false,
            }),
          setLoading(false)
        )
        .then(
          Cookies.set("id", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/employee/dashboard";
        });

      // const newresponst = Axios.post('')
      //  alert(response)
      //code
      console.clear();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleNextBA = async (e) => {
    swal("Are you sure you want to add to billing account?", {
      icon: "warning",
      buttons: ["Cancel", "Yes"],
    }).then((willDelete) => {
      if (willDelete) {
        // history.push("book-interpreter/summary")
        setLoading(true);

        if (currency_new === "EUR (EU)") {
          let x =
            estimatedTotal * rates.EUR +
            ((estimatedTotal * 2.5) / 100) * rates.EUR;
          let new_total = x.toFixed(2);
          Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail/add_account`,
            { currency_new, new_total, summary, BACusername }
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          );
        } else if (currency_new === "AUD (Australia)") {
          let x =
            estimatedTotal * rates.AUD +
            ((estimatedTotal * 2.5) / 100) * rates.AUD;
          let new_total = x.toFixed(2);
          Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail/add_account`,
            { currency_new, new_total, summary, BACusername }
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          );
        } else if (currency_new === "USD (USA)") {
          let x =
            estimatedTotal * rates.USD +
            ((estimatedTotal * 2.5) / 100) * rates.USD;
          let new_total = x.toFixed(2);
          Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail/add_account`,
            { currency_new, new_total, summary, BACusername }
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          );
        } else if (currency_new === "CAD (Canada)") {
          let x =
            estimatedTotal * rates.CAD +
            ((estimatedTotal * 2.5) / 100) * rates.CAD;
          let new_total = x.toFixed(2);
          Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail/add_account`,
            { currency_new, new_total, summary, BACusername }
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          );
        } else if (currency_new === "TRY (Turkish lira)") {
          let x =
            estimatedTotal * rates.TRY +
            ((estimatedTotal * 2.5) / 100) * rates.TRY;
          let new_total = x.toFixed(2);
          Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail/add_account`,
            { currency_new, new_total, summary, BACusername }
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          );
        } else if (currency_new === "SGD (Singapore dollar)") {
          let x =
            estimatedTotal * rates.SGD +
            ((estimatedTotal * 2.5) / 100) * rates.SGD;
          let new_total = x.toFixed(2);
          Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail/add_account`,
            { currency_new, new_total, summary, BACusername }
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          );
        } else if (currency_new === "HUF (Hungarian forint)") {
          let x =
            estimatedTotal * rates.HUF +
            ((estimatedTotal * 2.5) / 100) * rates.HUF;
          let new_total = x.toFixed(2);
          Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail/add_account`,
            { currency_new, new_total, summary, BACusername }
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          );
        } else if (currency_new === "NZD (New Zealand dollar)") {
          let x =
            estimatedTotal * rates.NZD +
            ((estimatedTotal * 2.5) / 100) * rates.NZD;
          let new_total = x.toFixed(2);
          Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail/add_account`,
            { currency_new, new_total, summary, BACusername }
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          );
        } else if (currency_new === "RON (Romanian leu)") {
          let x =
            estimatedTotal * rates.RON +
            ((estimatedTotal * 2.5) / 100) * rates.RON;
          let new_total = x.toFixed(2);
          Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail/add_account`,
            { currency_new, new_total, summary, BACusername }
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          );
        } else {
          let new_total = estimatedTotal.toFixed(2);
          Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/order-translation-telephonic-form/sendmail/add_account`,
            { currency_new, new_total, summary, BACusername }
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          );
        }
        setLoading(false);
        swal(
          "Please inform the client to check their associated email for more details.",
          {
            icon: "success",
            buttons: "OK!!",
          }
        ).then((res) => {
          Cookies.set("total_Overnight_charges", 0);
          Cookies.set("total_PriceForInterpriting", 0);
          Cookies.set("total_TravelTotal_Wages", 0);
          Cookies.set("tatal_Food_Overnight", 0);

          window.location = "/employee/dashboard";
        });
      }
    });
  };

  const isAllow = !currency_new || 0;

  // if(New_currency === )

  return (
    <div className={classes.root}>
      {/* {lodingArr.length < 1 ?  */}
      <GridContainer justify="center" className={classes.container}>
        {loading === false ? (
          <GridItem xs={12} sm={12} md={12} lg={9} xl={8}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  {/* <Avatar className={classes.avatar}> */}
                  <img
                    style={{ width: "70px", height: "65px" }}
                    src={logo}
                    alt=""
                  />
                  {/* </Avatar> */}
                  <Typography component="h1" variant="h5">
                    Summary
                  </Typography>
                </div>
                <div
                  style={{
                    padding: "4rem 12rem 4rem 12rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {summary ? (
                      <div style={{ display: "flex" }}>
                        <p>Translation Order / Assignment ID</p>
                        <span style={{ marginLeft: "auto" }}>{summary}</span>
                      </div>
                    ) : null}

                    {Word_count ? (
                      <div style={{ display: "flex" }}>
                        <p>Estimated word count</p>
                        <span style={{ marginLeft: "auto" }}>{Word_count}</span>
                      </div>
                    ) : null}
                    <Divider />
                    <div
                      style={{ display: "flex", margin: "1em 0 1em 0" }}
                    ></div>

                    {c ? (
                      <div style={{ display: "flex" }}>
                        <p>Price for translation (TBC)</p>

                        {New_currency.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {c.toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("EUR (EU)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              c * rates.EUR +
                              ((c * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              c * rates.AUD +
                              ((c * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("USD (USA)") && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              c * rates.USD +
                              ((c * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              c * rates.CAD +
                              ((c * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {New_currency.includes("TRY (Turkish lira)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              c * rates.TRY +
                              ((c * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {New_currency.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              c * rates.SGD +
                              ((c * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              c * rates.HUF +
                              ((c * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("NZD (New Zealand dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              c * rates.NZD +
                              ((c * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("RON (Romanian leu)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              c * rates.RON +
                              ((c * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}

                        {/* <span style= {{ marginLeft: 'auto'}}> {summary.new_currency.includes('euro (eu)') ? '€': '£'} {summary.new_currency.includes('euro (eu)') ? (c * 1.11).toFixed(2) : c}</span> */}
                      </div>
                    ) : null}

                    {b ? (
                      <div style={{ display: "flex" }}>
                        <p>Price for certification (TBC)</p>

                        {New_currency.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {b.toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("EUR (EU)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              b * rates.EUR +
                              ((b * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              b * rates.AUD +
                              ((b * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("USD (USA)") && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              b * rates.USD +
                              ((b * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              b * rates.CAD +
                              ((b * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {New_currency.includes("TRY (Turkish lira)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              b * rates.TRY +
                              ((b * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {New_currency.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              b * rates.SGD +
                              ((b * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              b * rates.HUF +
                              ((b * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("NZD (New Zealand dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              b * rates.NZD +
                              ((b * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("RON (Romanian leu)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              b * rates.RON +
                              ((b * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}
                      </div>
                    ) : null}

                    {d ? (
                      <div style={{ display: "flex" }}>
                        <p>Price for quality check (TBC)</p>

                        {New_currency.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {d.toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("EUR (EU)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              d * rates.EUR +
                              ((d * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              d * rates.AUD +
                              ((d * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("USD (USA)") && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              d * rates.USD +
                              ((d * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              d * rates.CAD +
                              ((d * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {New_currency.includes("TRY (Turkish lira)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              d * rates.TRY +
                              ((d * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {New_currency.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              d * rates.SGD +
                              ((d * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              d * rates.HUF +
                              ((d * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("NZD (New Zealand dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              d * rates.NZD +
                              ((d * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("RON (Romanian leu)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              d * rates.RON +
                              ((d * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}
                      </div>
                    ) : null}

                    {a ? (
                      <div style={{ display: "flex" }}>
                        <p>Translation visual formatting (TBC)</p>

                        {New_currency.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {a.toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("EUR (EU)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              a * rates.EUR +
                              ((c * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              a * rates.AUD +
                              ((a * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("USD (USA)") && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              a * rates.USD +
                              ((a * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              a * rates.CAD +
                              ((a * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {New_currency.includes("TRY (Turkish lira)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              a * rates.TRY +
                              ((a * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {New_currency.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              a * rates.SGD +
                              ((a * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              a * rates.HUF +
                              ((a * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("NZD (New Zealand dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              a * rates.NZD +
                              ((a * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("RON (Romanian leu)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              a * rates.RON +
                              ((a * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}
                      </div>
                    ) : null}

                    {estimatedTotal ? (
                      <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                        <p>Estimated total</p>

                        {New_currency.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {estimatedTotal.toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("EUR (EU)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              estimatedTotal * rates.EUR +
                              ((estimatedTotal * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              estimatedTotal * rates.AUD +
                              ((estimatedTotal * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("USD (USA)") && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              estimatedTotal * rates.USD +
                              ((estimatedTotal * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              estimatedTotal * rates.CAD +
                              ((estimatedTotal * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {New_currency.includes("TRY (Turkish lira)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              estimatedTotal * rates.TRY +
                              ((estimatedTotal * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {New_currency.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              estimatedTotal * rates.SGD +
                              ((estimatedTotal * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              estimatedTotal * rates.HUF +
                              ((estimatedTotal * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("NZD (New Zealand dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              estimatedTotal * rates.NZD +
                              ((estimatedTotal * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {New_currency.includes("RON (Romanian leu)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              estimatedTotal * rates.RON +
                              ((estimatedTotal * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}

                        {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                  <span style={{ marginLeft: "auto" }}>* To be confirmed</span>
                </div>

                {edit.loader ? (
                  <h3 style={{ display: "flex", justifyContent: "center" }}>
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </h3>
                ) : (
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <AppBar
                      position="static"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Toolbar onClick={handleSend}>
                        <h3
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          Send Link For Document/s Upload To Client
                        </h3>
                      </Toolbar>
                    </AppBar>
                  </AccordionSummary>
                )}

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      Add to billing account
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomSelect
                      onChange={handleChange}
                      data={CURRENCY_x}
                      width="73.3%"
                      label="Preferred currency"
                      name="currency_new"
                      value={currency_new}
                    />
                  </AccordionDetails>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      {estimatedTotal ? (
                        <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                          <p>Estimated total</p>
                          {currency_new.includes("GBP (UK)") && (
                            <span style={{ marginLeft: "auto" }}>
                              £ {estimatedTotal.toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("EUR (Euro countries)") && (
                            <span style={{ marginLeft: "auto" }}>
                              €{" "}
                              {(
                                estimatedTotal * rates.EUR +
                                ((estimatedTotal * 2.5) / 100) * rates.EUR
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("AUD (Australia)") && (
                            <span style={{ marginLeft: "auto" }}>
                              AUD ${" "}
                              {(
                                estimatedTotal * rates.AUD +
                                ((estimatedTotal * 2.5) / 100) * rates.AUD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "USD (USA, paying from USA only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              USD ${" "}
                              {(
                                estimatedTotal * rates.USD +
                                ((estimatedTotal * 2.5) / 100) * rates.USD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("CAD (Canada)") && (
                            <span style={{ marginLeft: "auto" }}>
                              CAD ${" "}
                              {(
                                estimatedTotal * rates.CAD +
                                ((estimatedTotal * 2.5) / 100) * rates.CAD
                              ).toFixed(2)}
                            </span>
                          )}

                          {currency_new.includes(
                            "TRY (Turkish lira, payment from Turkey only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              TRY ₺{" "}
                              {(
                                estimatedTotal * rates.TRY +
                                ((estimatedTotal * 2.5) / 100) * rates.TRY
                              ).toFixed(2)}
                            </span>
                          )}

                          {currency_new.includes("SGD (Singapore dollar)") && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              SGD S${" "}
                              {(
                                estimatedTotal * rates.SGD +
                                ((estimatedTotal * 2.5) / 100) * rates.SGD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("HUF (Hungarian forint)") && (
                            <span style={{ marginLeft: "auto" }}>
                              HUF Ft{" "}
                              {(
                                estimatedTotal * rates.HUF +
                                ((estimatedTotal * 2.5) / 100) * rates.HUF
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "NZD (New Zealand dollar, paying from New Zealand only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              NZD ${" "}
                              {(
                                estimatedTotal * rates.NZD +
                                ((estimatedTotal * 2.5) / 100) * rates.NZD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "RON (Romanian leu, paying from Romania only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              RON lei{" "}
                              {(
                                estimatedTotal * rates.RON +
                                ((estimatedTotal * 2.5) / 100) * rates.RON
                              ).toFixed(2)}
                            </span>
                          )}

                          {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                        </div>
                      ) : null}
                    </div>
                  </AccordionDetails>

                  <AccordionDetails>
                    <Button
                      className={`${classesb.button}`}
                      onClick={handleNextBA}
                      disabled={isAllow}
                      style={{
                        width: "30rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {" "}
                      Add to billing account
                    </Button>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Marketplace bank transfer
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomSelect
                      onChange={handleChange}
                      data={CURRENCY_x}
                      width="73.3%"
                      label="Preferred currency"
                      name="currency_new"
                      value={currency_new}
                    />
                  </AccordionDetails>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      {estimatedTotal ? (
                        <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                          <p>Estimated total</p>
                          {currency_new.includes("GBP (UK)") && (
                            <span style={{ marginLeft: "auto" }}>
                              £ {estimatedTotal.toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("EUR (Euro countries)") && (
                            <span style={{ marginLeft: "auto" }}>
                              €{" "}
                              {(
                                estimatedTotal * rates.EUR +
                                ((estimatedTotal * 2.5) / 100) * rates.EUR
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("AUD (Australia)") && (
                            <span style={{ marginLeft: "auto" }}>
                              AUD ${" "}
                              {(
                                estimatedTotal * rates.AUD +
                                ((estimatedTotal * 2.5) / 100) * rates.AUD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "USD (USA, paying from USA only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              USD ${" "}
                              {(
                                estimatedTotal * rates.USD +
                                ((estimatedTotal * 2.5) / 100) * rates.USD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("CAD (Canada)") && (
                            <span style={{ marginLeft: "auto" }}>
                              CAD ${" "}
                              {(
                                estimatedTotal * rates.CAD +
                                ((estimatedTotal * 2.5) / 100) * rates.CAD
                              ).toFixed(2)}
                            </span>
                          )}

                          {currency_new.includes(
                            "TRY (Turkish lira, payment from Turkey only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              TRY ₺{" "}
                              {(
                                estimatedTotal * rates.TRY +
                                ((estimatedTotal * 2.5) / 100) * rates.TRY
                              ).toFixed(2)}
                            </span>
                          )}

                          {currency_new.includes("SGD (Singapore dollar)") && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              SGD S${" "}
                              {(
                                estimatedTotal * rates.SGD +
                                ((estimatedTotal * 2.5) / 100) * rates.SGD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("HUF (Hungarian forint)") && (
                            <span style={{ marginLeft: "auto" }}>
                              HUF Ft{" "}
                              {(
                                estimatedTotal * rates.HUF +
                                ((estimatedTotal * 2.5) / 100) * rates.HUF
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "NZD (New Zealand dollar, paying from New Zealand only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              NZD ${" "}
                              {(
                                estimatedTotal * rates.NZD +
                                ((estimatedTotal * 2.5) / 100) * rates.NZD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "RON (Romanian leu, paying from Romania only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              RON lei{" "}
                              {(
                                estimatedTotal * rates.RON +
                                ((estimatedTotal * 2.5) / 100) * rates.RON
                              ).toFixed(2)}
                            </span>
                          )}

                          {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                        </div>
                      ) : null}
                    </div>
                  </AccordionDetails>

                  <AccordionDetails>
                    <Button
                      className={`${classesb.button}`}
                      onClick={handleNext}
                      disabled={isAllow}
                      style={{
                        width: "30rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Get bank details
                    </Button>
                  </AccordionDetails>
                </Accordion>

                {/* {selectedForm === 2 && <FormThree />} */}
              </CardBody>
            </Card>
          </GridItem>
        ) : (
          <LoadingScreen
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc="https://linguistscollective.com/static/media/Interpreting-Translation-Linguists-Collective-Language-Services.0f1108ca.png"
            text="Please wait! The system is generating email and sending it to the client."
          >
            <div>Loadable content</div>
          </LoadingScreen>
        )}
      </GridContainer>
      {/* :
<Dimmer active inverted>
           <Loader size='medium'>Loading..</Loader>
         </Dimmer>
} */}
    </div>
  );
};

export default BISummary;
