import React, {  } from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import UnconfirmedOptions from "components/Employee/BookingPanel/UnconfirmedAssignments/UnconfirmedOptions";




const AssignmentPage = () => {
  const classes = useDashboardStyles();


  return (
    <Employee>
      <EmployeeLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Unconfirmed Assignments</p>

              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <UnconfirmedOptions />
              </CardHeader>
            </Card>
          </GridItem>


        </GridContainer>
      </EmployeeLayout>
    </Employee>
  );
};

export default AssignmentPage;
