import { useHistory } from "react-router-dom";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Button from "@material-ui/core/Button";

import ListAltIcon from "@material-ui/icons/ListAlt";
import GridContainer from "components/Grid/GridContainer";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { useMediaQuery } from "@material-ui/core";

const LinguistOptions = () => {
  const history = useHistory();
  const classes = useEmployeeStyles();
  const matches = useMediaQuery("(max-width:425px)");

  return (
    <GridContainer justify="center" className={classes.container}>
      {matches ? (
        <>
          {" "}
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/sppo/unregistered-linguists-list")}
          >
            Unregistered Linguist
          </Button>
          <Button
            startIcon={<VerifiedUserIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%" }}
            onClick={() => history.push("/sppo/registered-linguists-list")}
          >
            Registered Linguist
          </Button>
        </>
      ) : (
        <>
          {" "}
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "30%", marginLeft: "15px", marginRight: "15px" }}
            onClick={() => history.push("/sppo/unregistered-linguists-list")}
          >
            Unregistered Linguist
          </Button>
          <Button
            startIcon={<VerifiedUserIcon />}
            color="primary"
            variant="contained"
            style={{ width: "30%", marginRight: "15px" }}
            onClick={() => history.push("/sppo/registered-linguists-list")}
          >
            Registered Linguist
          </Button>
        </>
      )}
    </GridContainer>
  );
};

export default LinguistOptions;
