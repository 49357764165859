import React from "react";

// Material Components

// Custom Components
import CustomFormControl from "./CustomFormControl";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import { TextField } from "@material-ui/core";

const CustomDateTwo = ({
  label,
  width,
  moreInfo,
  required,
  ...rest
}) => {

  return (
    <CustomFormControl width={width} {...rest}>
      <TextField
        id="date"
        label= {label}
        {...rest}
        type="date"
        InputLabelProps={{
        shrink: true,
        }}
    />
    </CustomFormControl>
  );
};

export default CustomDateTwo;
