const { CHANGE_SPPOR_INPUT, UPDATE_SPPOR_STEP } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    count: 1,
    data: {
        business_name: '',
        legal_business_name: '',
        primary_email: '',
        phone_number: '',
        house_street_number_name: '',
        address_line_two: '',
        town_city: '',
        post_zip_code: '',
        county_region: '',
        country: '',
        title: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
        birthday: '',
        address_line1: '',
        address_line2: '',
        city: '',
        postal_code: '',
        region: '',
        
        nationality: '',
        other_nationality: [],
        business_website: '',
        company_registration_number: '',
        charity_registration_number: '',
        vat_number: '',
        vat_country: '',
        bank_account_fullname: "",
        bank_sort_code: "",
        bank_account_number: "",
        bank_branch_name: "",
        bank_iban: "",
        bank_swift_code: "",
        bank_name: "",
        bank_branch_address: "",
        bank_additional_info: "",
        bic_no: '',
        ifsc_code: '',
        institution_number: '',
        branch_code: '',
        aba: '',
        currency: "",
        language_combinations: [],
        additional_info: '',
        gdpr: false
    }
};


const spporReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_SPPOR_STEP:
            return {
                ...state,
                count: payload,
            };

        case CHANGE_SPPOR_INPUT:
            return {
                ...state,
                data: {
                  ...state.data,
                  [payload.name]: payload.value
                }
            }
        
        default:
            return state
    }
}

export default spporReducer
