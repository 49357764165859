import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import Axios from "axios";
import swal from "sweetalert";

const StripeDetails = () => {
  const { data } = useSelector((state) => state.updateProfileReducer);
  const [loading, setLoading] = useState(false);

  const handleOnboard = async (e) => {
    setLoading(true);
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguist/createAccount/v1/createAccountLinguist`,
        {
          country: data.Country,
          email: data.email,
          business_type: "individual",
          addressLine1: data.AddressLine1,
          addressLine2: data.AddressLine2,
          city: data.City,
          postal_code: data.PostalCode,
          region: data.Region,
          dob: data.Birthday,
          first_name: data.FirstName,
          last_name: data.LastName,
          gender: data.sex,
          phone: data.mobile_no,
          role: "Linguist",
          bank_country: data.Country,
          bank_currency: data.currency,
          routing_number: data.bank_swift_code,
          account_number: data.bank_account_number,
          account_holder_name: data.bank_account_fullname,
          account_holder_type: "individual",
          refresh_url: "https://linguistscollective.com",
          return_url: "https://linguistscollective.com",
        }
      );
      setLoading(false);

      window.open(response?.data.accountLink.url, "_blank");
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          text: error.response?.data,
          buttons: false,
          icon: "error",
        });
      }
      setLoading(false);
    }
  };
  return (
    <div>
      <Button loading={loading} onClick={handleOnboard} color="purple">
        Stripe Connect
      </Button>
    </div>
  );
};

export default StripeDetails;
