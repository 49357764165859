import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// Material Core Components
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";

// Custom Components
import NavLinks from "./NavLinks";

// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";
import useSidebarStyles from "assets/jss/components/sidebar";
import { NavDiv, RootDiv } from "./style";
import NavDropdown from "./NavDropdown";

import ITLimage from "assets/icons/Interpreting-Translation-Linguists-Collective-Language-Services.png";

const LPNavbar = ({ handleDrawerToggle, layoutType }) => {
  const isLower = useMediaQuery({
    query: "(max-device-width: 920px)",
  });

  const classesImage = useSidebarStyles();
  const classes = useNavbarStyles();

  const changeBackground = (e) => {
    if (window.scrollY > 50) {
      if (document.querySelector("#nav")) {
        document.querySelector("#nav").style.backgroundColor = "#B794F4";
      }
    } else {
      if (document.querySelector("#nav")) {
        document.querySelector("#nav").style.backgroundColor = "#D6BCFA";
      }
    }
  };

  window.addEventListener("scroll", () => changeBackground());
  return (
    <AppBar
      id="nav"
      position="fixed"
      style={{ background: "#D6BCFA", boxShadow: "none" }}
    >
       

      <RootDiv className={classes.sideDiv}>
        <div className="logo-div">
          <Link to="/">
            <div className="img-div">
              <img className={classesImage.img} src={ITLimage} alt="" />
            </div>
          </Link>
          <Typography
            color="textPrimary"
            variant="h6"
            className={classes.title}
            noWrap
          >
            Linguists Collective <br /> Interpreting & Translation
          </Typography>
        </div>
        {isLower ? (
          <div>
            <NavDropdown />
          </div>
        ) : (
          <NavDiv>
            <NavLinks />
          </NavDiv>
        )}
      </RootDiv>
    </AppBar>
  );
};

export default LPNavbar;
