import { useHistory } from "react-router-dom";

// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const Options = ({ type }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      {type === "clients" && (
        <>
          {" "}
          {/* <Button
            variant="outlined"
            onClick={() =>
              history.push("/sppo/add-inhouse-client/registration")
            }
          >
            Add / Remove in-house client
          </Button> */}
          <Button
            variant="outlined"
            onClick={() => history.push("/sppo/send-client-reg-form")}
          >
            Add / Remove in-house client
          </Button>
          <Button
            variant="outlined"
            onClick={() => history.push("/sppo/view-inhouse-client")}
          >
            search / view in-house client
          </Button>
        </>
      )}
      {type === "bookings" && (
        <>
          {" "}
          <Button
            variant="outlined"
            onClick={() => history.push("/sppo/send-booking-form")}
          >
            send booking form
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              history.push("/sppo/inhouse-booking/book-interpreter")
            }
          >
            in-house interpreting booking
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              history.push("/sppo/inhouse-booking/order-translation")
            }
          >
            in-house order translation
          </Button>
        </>
      )}
      {type === "assignments" && (
        <>
          {" "}
          <Button
            variant="outlined"
            onClick={() => history.push("/sppo/inc-unconfirm-assignment")}
          >
            unconfirm outgoing assignments
          </Button>
          <Button
            variant="outlined"
            onClick={() => history.push("/sppo/inc-confirm-assignment")}
          >
            confirm outgoing assignments
          </Button>
          <Button
            variant="outlined"
            onClick={() => history.push("/sppo/inc-cancel-assignment")}
          >
            cancelled outgoing assignments
          </Button>
        </>
      )}
    </div>
  );
};

export default Options;
