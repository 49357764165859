import { CHANGE_JOBADVERT_INPUT, UPDATE_ADVERT_STEP } from './actionTypes'

export const updateAdvertStep= (count) => ({
    type: UPDATE_ADVERT_STEP,
    payload: count
})

export const changeJaInput= data => ({
    type: CHANGE_JOBADVERT_INPUT,
    payload: data
})