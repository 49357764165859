import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import QuoteToClientList from "components/Admin/AccountFinance/DirectQuoteByLC/QuoteToClient";
import QuoteList from "components/Admin/AccountFinance/DirectQuoteByLC/FindBill/FindBill";
import { Button } from "semantic-ui-react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const [booking_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminAccess(response?.data.booking_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {booking_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>Direct Quote By LC</p>
                  <div style={{ display: "flex" }}>
                    <Button
                      color="purple"
                      content="Create Direct Quotation"
                      onClick={() => history.push("/admin/create-quotation")}
                    />
                    <IconButton
                      color="inherit"
                      style={{ marginLeft: 5 }}
                      onClick={() => history.goBack()}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                </CardHeader>

                <CardBody>
                  <QuoteToClientList />
                  <br />
                  <br />
                  <QuoteList />
                </CardBody>
              </Card>
            </GridItem>
          ) : booking_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
