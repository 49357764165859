import CardIcon from "components/Card/CardIcon";
import { OneCardDiv } from "./style";

const OneCard = ({ icon, value, text }) => {
  return (
    <OneCardDiv>
      <div className="icon">
        <CardIcon
          // color="primary"
          style={{
            textAlign: "center",
            width: "50px",
            height: "50px",
            padding: "00px",
          }}
        >
          <img src={icon} alt="" style={{ width: "100%" }} />
        </CardIcon>
      </div>
      <div className="details">
        <span>
          {value} {"  "} {text}
        </span>
        {/* <br /> */}
        {/* <span></span> */}
      </div>
    </OneCardDiv>
  );
};

export default OneCard;
