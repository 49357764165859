import React, { useEffect, useState } from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";

// Custom Components
import CashClientLayout from "layout/CashClientLayout";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import BIPayment from "components/Auth/LinguistsCollective/BIsummary";
import Cookies from "js-cookie";
import Axios from "axios";

const BIPaymentPage = () => {
  const token = Cookies.get("CACtoken");
  const [values, setValues] = useState({ reload: false });

  const clientDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    clientDetails();
  }, []);

  return (
    <CashClientLayout>
      <Cashclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <BIPayment payLaterAccess={values?.payLaterAccess} />
          </GridItem>
        </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default BIPaymentPage;
