import React from "react";
import MasterLayout from "layout/MasterLayout";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

import BISummary from "components/Auth/IBT/BISummary";

const BISummaryPage = () => {
  return (

    <MasterLayout>
      <Manager>
        <BISummary />
      </Manager>
    </MasterLayout>

  );
};

export default BISummaryPage;
