import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import history from "utils/history"
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Options from "components/Admin/ControlPanel/Archive/Options"
import TimeReportOptions from "components/Admin/ControlPanel/Archive/TimeReport/TimeReportOptions"

import Timesheet from "components/Admin/ControlPanel/Archive/TimeReport/Timesheet"
import ProjectReport from "components/Admin/ControlPanel/Archive/TimeReport/ProjectReport"



const Archive = () => {
  const classes = useDashboardStyles();
  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>
                  Close & Timesheet and Project Report {" "}
                  {history.location.pathname.includes("admin/archived-close-and-timesheet") ?
                    "(Close and Timesheet)" : 
                    history.location.pathname.includes("admin/archived-project-report") ?
                    "(Project Report)" : null}
                </p>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Options />
              </CardHeader>
            </Card>
            <TimeReportOptions />
            <Card>
                {history.location.pathname.includes("admin/archived-close-and-timesheet") ?
                  <Timesheet /> : 
                  history.location.pathname.includes("admin/archived-project-report") ?
                  <ProjectReport /> : null}
            </Card>
          </GridItem>
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default Archive;
