import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

// Material Core Components
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/Common/CustomInput";
import CustomPassword from "components/Common/CustomPassword";
import AdminForgot from "./ForgotPassword";
import CustomAvatar from "components/Common/CustomAvatar";
import CustomCaptcha from "components/Common/CustomCaptcha";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import LandingPageLayout from "layout/LandingPageLayout";

// Others

import defaultAxios from "utils/axios";

const AdminLogin = () => {
  const history = useHistory();

  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState();

  const classes = useEmployeeStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const response = await defaultAxios.post(
        `${process.env.REACT_APP_BACKEND_API}/supreme/manager/signin`,
        state
      );

      const { token, admin } = response?.data;

      Cookies.set("token", token);
      localStorage.setItem("user", JSON.stringify(admin));

      history.push("/admin/dashboard");
      swal({
        title: "Welcome",
        text: `${admin.FirstName} ${admin.LastName}`,
        icon: "success",
        buttons: false,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };
  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };

  const { email, password } = state;

  return (
    <LandingPageLayout>
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    Supreme Manager Login
                  </Typography>
                </div>
                <form method="POST">
                  <CustomInput
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    value={email}
                  />
                  <CustomPassword
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    value={password}
                  />

                  <CustomCaptcha onChange={handleCaptcha} />

                  <div className={classes.buttonDiv}>
                    <Button
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!email || !password || loading || !captcha}
                      variant="contained"
                    >
                      {loading ? "Logging..." : "Login"}
                    </Button>
                   
                  </div>
                </form>
                <div className={classes.forgotDiv}>
                  <span />
                  <AdminForgot />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </LandingPageLayout>
  );
};

export default AdminLogin;
