import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import Typography from "@material-ui/core/Typography";

import CustomSelect from "components/Common/CustomSelect";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { changeBaInput } from "redux/actions/bookingAction";
import logo from "assets/images/logo.png";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Axios from "axios";
import { Button } from "semantic-ui-react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@material-ui/core";
import history from "utils/history";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { CURRENCY_x } from "data";
import swal from "sweetalert";

const BIsummary = () => {
  const classes = useEmployeeStyles();
  const classesb = useLandingpageStyles();
  const [grandTotal, setGrandTotal] = useState("");
  const { data } = useSelector((state) => state.bookingReducer);
  const { currency_new, currency_new_card } = data;

  const [submitLoader, setSubmitLoader] = useState(false);

  const [rates, setRates] = useState("");

  const dispatch = useDispatch();

  // currency
  const New_currency = "GBP (UK)"
  //id
  const id = Cookies.get("id");
  //wordcount
  const charge = Cookies.get("charge");

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
    ).then((res) => setRates(res?.data?.rates));
  }, []);

  const estimatedTotal = charge;

  const handleChange = (e) => {
    dispatch(
      changeBaInput({
        name: "currency_new",
        value: e.target.value,
      })
    );
  };


  const handleChangecard = (e) => {

    const { value } = e.target;

    dispatch(
      changeBaInput({
        name: "currency_new_card",
        value: e.target.value,
      })
    );

    if (value === "EUR (Euro countries)") {
      setGrandTotal(
        estimatedTotal * rates.EUR +
        (((estimatedTotal * 2.5) / 100) * rates.EUR).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.EUR +
          ((estimatedTotal * 2.5) / 100) * rates.EUR
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "EUR €");
    } else if (value === "AUD (Australia)") {
      setGrandTotal(
        estimatedTotal * rates.AUD +
        (((estimatedTotal * 2.5) / 100) * rates.AUD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.AUD +
          ((estimatedTotal * 2.5) / 100) * rates.AUD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "AUD $");
    } else if (value === "USD (USA, paying from USA only)") {
      setGrandTotal(
        estimatedTotal * rates.USD +
        (((estimatedTotal * 2.5) / 100) * rates.USD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.USD +
          ((estimatedTotal * 2.5) / 100) * rates.USD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "USD $");
    } else if (value === "CAD (Canada)") {
      setGrandTotal(
        estimatedTotal * rates.CAD +
        (((estimatedTotal * 2.5) / 100) * rates.CAD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.CAD +
          ((estimatedTotal * 2.5) / 100) * rates.CAD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "CAD $");
    } else if (value === "TRY (Turkish lira, payment from Turkey only)") {
      setGrandTotal(
        estimatedTotal * rates.TRY +
        (((estimatedTotal * 2.5) / 100) * rates.TRY).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.TRY +
          ((estimatedTotal * 2.5) / 100) * rates.TRY
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "TRY  ₺");
    } else if (value === "SGD (Singapore dollar)") {
      setGrandTotal(
        estimatedTotal * rates.SGD +
        (((estimatedTotal * 2.5) / 100) * rates.SGD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.SGD +
          ((estimatedTotal * 2.5) / 100) * rates.SGD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "SGD $");
    } else if (value === "HUF (Hungarian forint)") {
      setGrandTotal(
        estimatedTotal * rates.HUF +
        (((estimatedTotal * 2.5) / 100) * rates.HUF).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.HUF +
          ((estimatedTotal * 2.5) / 100) * rates.HUF
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "HUF Ft");
    } else if (
      value === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      setGrandTotal(
        estimatedTotal * rates.NZD +
        (((estimatedTotal * 2.5) / 100) * rates.NZD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.NZD +
          ((estimatedTotal * 2.5) / 100) * rates.NZD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "NZD $");
    } else if (value === "RON (Romanian leu, paying from Romania only)") {
      setGrandTotal(
        estimatedTotal * rates.RON +
        (((estimatedTotal * 2.5) / 100) * rates.RON).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.RON +
          ((estimatedTotal * 2.5) / 100) * rates.RON
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "RON lei");
    } else {
      setGrandTotal(estimatedTotal);
      Cookies.set("grandTotal", estimatedTotal);
      Cookies.set("stripeCurrency", "GBP £");
    }
  };

  const handleNext = async (e) => {
    setSubmitLoader(true);

    if (currency_new === "EUR (Euro countries)") {
      let x =
        estimatedTotal * rates.EUR + ((estimatedTotal * 2.5) / 100) * rates.EUR;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert-bank-payment/${id}`,
        { currency_new, new_total, rates, id }
      );
    } else if (currency_new === "AUD (Australia)") {
      let x =
        estimatedTotal * rates.AUD + ((estimatedTotal * 2.5) / 100) * rates.AUD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert-bank-payment/${id}`,
        { currency_new, new_total, rates, id }
      );
    } else if (currency_new === "USD (USA, paying from USA only)") {
      let x =
        estimatedTotal * rates.USD + ((estimatedTotal * 2.5) / 100) * rates.USD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert-bank-payment/${id}`,
        { currency_new, new_total, rates, id }
      );
    } else if (currency_new === "CAD (Canada)") {
      let x =
        estimatedTotal * rates.CAD + ((estimatedTotal * 2.5) / 100) * rates.CAD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert-bank-payment/${id}`,
        { currency_new, new_total, rates, id }
      );
    } else if (
      currency_new === "TRY (Turkish lira, payment from Turkey only)"
    ) {
      let x =
        estimatedTotal * rates.TRY + ((estimatedTotal * 2.5) / 100) * rates.TRY;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert-bank-payment/${id}`,
        { currency_new, new_total, rates, id }
      );
    } else if (currency_new === "SGD (Singapore dollar)") {
      let x =
        estimatedTotal * rates.SGD + ((estimatedTotal * 2.5) / 100) * rates.SGD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert-bank-payment/${id}`,
        { currency_new, new_total, rates, id }
      );
    } else if (currency_new === "HUF (Hungarian forint)") {
      let x =
        estimatedTotal * rates.HUF + ((estimatedTotal * 2.5) / 100) * rates.HUF;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert-bank-payment/${id}`,
        { currency_new, new_total, rates, id }
      );
    } else if (
      currency_new === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      let x =
        estimatedTotal * rates.NZD + ((estimatedTotal * 2.5) / 100) * rates.NZD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert-bank-payment/${id}`,
        { currency_new, new_total, rates, id }
      );
    } else if (
      currency_new === "RON (Romanian leu, paying from Romania only)"
    ) {
      let x =
        estimatedTotal * rates.RON + ((estimatedTotal * 2.5) / 100) * rates.RON;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert-bank-payment/${id}`,
        { currency_new, new_total, rates, id }
      );
    } else {
      let new_total = estimatedTotal;
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert-bank-payment/${id}`,
        { currency_new, new_total, rates, id }
      );
    }

    try {
      let EUR = (
        estimatedTotal * rates.EUR +
        ((estimatedTotal * 2.5) / 100) * rates.EUR
      ).toFixed(2);
      let AUD = (
        estimatedTotal * rates.AUD +
        ((estimatedTotal * 2.5) / 100) * rates.AUD
      ).toFixed(2);
      let USD = (
        estimatedTotal * rates.USD +
        ((estimatedTotal * 2.5) / 100) * rates.USD
      ).toFixed(2);
      let CAD = (
        estimatedTotal * rates.CAD +
        ((estimatedTotal * 2.5) / 100) * rates.CAD
      ).toFixed(2);
      let TRY = (
        estimatedTotal * rates.TRY +
        ((estimatedTotal * 2.5) / 100) * rates.TRY
      ).toFixed(2);
      let SGD = (
        estimatedTotal * rates.SGD +
        ((estimatedTotal * 2.5) / 100) * rates.SGD
      ).toFixed(2);
      let HUF = (
        estimatedTotal * rates.HUF +
        ((estimatedTotal * 2.5) / 100) * rates.HUF
      ).toFixed(2);
      let NZD = (
        estimatedTotal * rates.NZD +
        ((estimatedTotal * 2.5) / 100) * rates.NZD
      ).toFixed(2);
      let RON = (
        estimatedTotal * rates.RON +
        ((estimatedTotal * 2.5) / 100) * rates.RON
      ).toFixed(2);

      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/JobAdvert/sendEmail`,
        {
          currency_new,
          estimatedTotal,
          rates,
          id,
          EUR,
          AUD,
          USD,
          CAD,
          TRY,
          SGD,
          HUF,
          NZD,
          RON,
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `and pay to confirm your job advert. You recive selected bank details via email kindly check it `,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(function () {
          window.location = "/";
        });

      console.clear();
    } catch (error) {
      console.log(error.response);
    }
  };

  const isAllow2 = !currency_new_card || 0;

  const isAllow = !currency_new || 0;

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={9} xl={6}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                {/* <Avatar className={classes.avatar}> */}
                <img
                  style={{ width: "70px", height: "65px" }}
                  src={logo}
                  alt=""
                />
                {/* </Avatar> */}
                <Typography component="h1" variant="h5">
                  Summary
                </Typography>
              </div>
              <div
                style={{
                  padding: "4rem 12rem 4rem 12rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  {id ? (
                    <div style={{ display: "flex" }}>
                      <p>Job Advert / Assignment ID</p>
                      <span style={{ marginLeft: "auto" }}>{id}</span>
                    </div>
                  ) : null}

                  {charge ? (
                    <div style={{ display: "flex" }}>
                      <p>Estimated charge amount</p>
                      <span style={{ marginLeft: "auto" }}>£ {charge}</span>
                    </div>
                  ) : null}
                  <Divider />
                  <div style={{ display: "flex", margin: "1em 0 1em 0" }}></div>

                  {charge ? (
                    <div style={{ display: "flex" }}>
                      <p>Total Charge</p>

                      {New_currency.includes("GBP (UK)") && (
                        <span style={{ marginLeft: "auto" }}>£ {charge}</span>
                      )}
                      {New_currency.includes("EUR (EU)") && (
                        <span style={{ marginLeft: "auto" }}>
                          €{" "}
                          {(
                            charge * rates.EUR +
                            ((charge * 2.5) / 100) * rates.EUR
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("AUD (Australia)") && (
                        <span style={{ marginLeft: "auto" }}>
                          AUD ${" "}
                          {(
                            charge * rates.AUD +
                            ((charge * 2.5) / 100) * rates.AUD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("USD (USA)") && (
                        <span style={{ marginLeft: "auto" }}>
                          USD ${" "}
                          {(
                            charge * rates.USD +
                            ((charge * 2.5) / 100) * rates.USD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("CAD (Canada)") && (
                        <span style={{ marginLeft: "auto" }}>
                          CAD ${" "}
                          {(
                            charge * rates.CAD +
                            ((charge * 2.5) / 100) * rates.CAD
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("TRY (Turkish lira)") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          TRY ₺{" "}
                          {(
                            charge * rates.TRY +
                            ((charge * 2.5) / 100) * rates.TRY
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("SGD (Singapore dollar)") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          SGD S${" "}
                          {(
                            charge * rates.SGD +
                            ((charge * 2.5) / 100) * rates.SGD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("HUF (Hungarian forint)") && (
                        <span style={{ marginLeft: "auto" }}>
                          HUF Ft{" "}
                          {(
                            charge * rates.HUF +
                            ((charge * 2.5) / 100) * rates.HUF
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("NZD (New Zealand dollar)") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          NZD ${" "}
                          {(
                            charge * rates.NZD +
                            ((charge * 2.5) / 100) * rates.NZD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("RON (Romanian leu)") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          RON lei{" "}
                          {(
                            charge * rates.RON +
                            ((charge * 2.5) / 100) * rates.RON
                          ).toFixed(2)}
                        </span>
                      )}

                    </div>
                  ) : null}

                </div>
              </div>
              <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                <span style={{ marginLeft: "auto" }}>** To be confirmed</span>
              </div>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Marketplace speedy payment
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomSelect
                    onChange={handleChangecard}
                    data={CURRENCY_x}
                    width="73.3%"
                    label="Preferred currency"
                    name="currency_new_card"
                    value={currency_new_card}
                  />
                </AccordionDetails>
                <AccordionDetails>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {estimatedTotal ? (
                      <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                        <p>Estimated total</p>
                        {currency_new_card.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {estimatedTotal}
                          </span>
                        )}
                        {currency_new_card.includes("EUR (Euro countries)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              estimatedTotal * rates.EUR +
                              ((estimatedTotal * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new_card.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              estimatedTotal * rates.AUD +
                              ((estimatedTotal * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new_card.includes(
                          "USD (USA, paying from USA only)"
                        ) && (
                            <span style={{ marginLeft: "auto" }}>
                              USD ${" "}
                              {(
                                estimatedTotal * rates.USD +
                                ((estimatedTotal * 2.5) / 100) * rates.USD
                              ).toFixed(2)}
                            </span>
                          )}
                        {currency_new_card.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              estimatedTotal * rates.CAD +
                              ((estimatedTotal * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_new_card.includes(
                          "TRY (Turkish lira, payment from Turkey only)"
                        ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              TRY ₺{" "}
                              {(
                                estimatedTotal * rates.TRY +
                                ((estimatedTotal * 2.5) / 100) * rates.TRY
                              ).toFixed(2)}
                            </span>
                          )}

                        {currency_new_card.includes(
                          "SGD (Singapore dollar)"
                        ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              SGD S${" "}
                              {(
                                estimatedTotal * rates.SGD +
                                ((estimatedTotal * 2.5) / 100) * rates.SGD
                              ).toFixed(2)}
                            </span>
                          )}
                        {currency_new_card.includes(
                          "HUF (Hungarian forint)"
                        ) && (
                            <span style={{ marginLeft: "auto" }}>
                              HUF Ft{" "}
                              {(
                                estimatedTotal * rates.HUF +
                                ((estimatedTotal * 2.5) / 100) * rates.HUF
                              ).toFixed(2)}
                            </span>
                          )}
                        {currency_new_card.includes(
                          "NZD (New Zealand dollar, paying from New Zealand only)"
                        ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              NZD ${" "}
                              {(
                                estimatedTotal * rates.NZD +
                                ((estimatedTotal * 2.5) / 100) * rates.NZD
                              ).toFixed(2)}
                            </span>
                          )}
                        {currency_new_card.includes(
                          "RON (Romanian leu, paying from Romania only)"
                        ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              RON lei{" "}
                              {(
                                estimatedTotal * rates.RON +
                                ((estimatedTotal * 2.5) / 100) * rates.RON
                              ).toFixed(2)}
                            </span>
                          )}
                      </div>
                    ) : null}
                  </div>
                </AccordionDetails>
                <AccordionDetails>
                  <Button
                    onClick={() =>
                      history.push(
                        `/job-advert/summary/estimate/payment`
                      )
                    }
                    disabled={isAllow2}
                    className={`${classesb.button}`}
                    style={{
                      width: "30rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Pay and confirm
                  </Button>

                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Marketplace bank transfer
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomSelect
                    onChange={handleChange}
                    data={CURRENCY_x}
                    width="73.3%"
                    label="Preferred currency"
                    name="currency_new"
                    value={currency_new}
                  />
                </AccordionDetails>
                <AccordionDetails>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {estimatedTotal ? (
                      <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                        <p>Estimated total</p>
                        {currency_new.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {estimatedTotal}
                          </span>
                        )}
                        {currency_new.includes("EUR (Euro countries)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              estimatedTotal * rates.EUR +
                              ((estimatedTotal * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              estimatedTotal * rates.AUD +
                              ((estimatedTotal * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes(
                          "USD (USA, paying from USA only)"
                        ) && (
                            <span style={{ marginLeft: "auto" }}>
                              USD ${" "}
                              {(
                                estimatedTotal * rates.USD +
                                ((estimatedTotal * 2.5) / 100) * rates.USD
                              ).toFixed(2)}
                            </span>
                          )}
                        {currency_new.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              estimatedTotal * rates.CAD +
                              ((estimatedTotal * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_new.includes(
                          "TRY (Turkish lira, payment from Turkey only)"
                        ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              TRY ₺{" "}
                              {(
                                estimatedTotal * rates.TRY +
                                ((estimatedTotal * 2.5) / 100) * rates.TRY
                              ).toFixed(2)}
                            </span>
                          )}

                        {currency_new.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              estimatedTotal * rates.SGD +
                              ((estimatedTotal * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              estimatedTotal * rates.HUF +
                              ((estimatedTotal * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes(
                          "NZD (New Zealand dollar, paying from New Zealand only)"
                        ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              NZD ${" "}
                              {(
                                estimatedTotal * rates.NZD +
                                ((estimatedTotal * 2.5) / 100) * rates.NZD
                              ).toFixed(2)}
                            </span>
                          )}
                        {currency_new.includes(
                          "RON (Romanian leu, paying from Romania only)"
                        ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              RON lei{" "}
                              {(
                                estimatedTotal * rates.RON +
                                ((estimatedTotal * 2.5) / 100) * rates.RON
                              ).toFixed(2)}
                            </span>
                          )}

                      </div>
                    ) : null}
                  </div>
                </AccordionDetails>

                <AccordionDetails>
                  <Button
                    loading={submitLoader}
                    className={`${classesb.button}`}
                    onClick={handleNext}
                    disabled={isAllow}
                    style={{
                      width: "30rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    variant="contained"
                    color="purple"
                  >
                    Get bank details
                  </Button>
                </AccordionDetails>
              </Accordion>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default BIsummary;
