import React from "react";
import { Table } from "semantic-ui-react";
import moment from "moment"

const Items = ({ details }) => {
  const startTime = details[0].start_time[0].split(",");
  const startDate = details[0].start_date[0].split(",");
  const actualDuration = details[0].actual_duration[0].split(",");


  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="5">Job Details</Table.HeaderCell>
            <Table.HeaderCell width="3">Requested</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row hover="true" role="checkbox" tabIndex={-1}>
            <Table.Cell>
              Level of expertise required source / native language (
              {details[0].cacID?.source_language})
            </Table.Cell>
            <Table.Cell>
              {details[0].cacID?.source_linguist_skill_level}
            </Table.Cell>
            <Table.Cell>{details[0].postedBy?.source_language_level}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Level of expertise required target language (
              {details[0].cacID?.target_language})
            </Table.Cell>
            <Table.Cell>
              {details[0].cacID?.target_linguist_skill_level}
            </Table.Cell>
            <Table.Cell>{details[0].postedBy?.target_language_level}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Timezone</Table.Cell>
            <Table.Cell>{details[0].cacID?.timezone}</Table.Cell>
            <Table.Cell>{details[0].cacID?.timezone}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="3">
              Appointment start date
            </Table.HeaderCell>
            <Table.HeaderCell width="3">
              Actual appointment start date
            </Table.HeaderCell>
            <Table.HeaderCell width="3">Start Time</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual start Time</Table.HeaderCell>
            <Table.HeaderCell width="3">Appointment Duration</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual Duration</Table.HeaderCell>

          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.HeaderCell width="3">
            {details[0].cacID?.startdateArray
              ? details[0].cacID?.startdateArray
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>
                      {moment(st).tz("Europe/London").format("DD-MMM-YYYY")}
                    </Table.Cell>
                  </Table.Row>
                ))
              : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details[0].start_date[0] !== ""
              ? startDate
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>
                      {moment(st).format("DD-MMM-YYYY")}
                    </Table.Cell>
                  </Table.Row>
                ))
              : details[0].cacID?.startdateArray
                ? details[0].cacID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
                : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details[0].cacID?.starttimeArray
              ? details[0].cacID?.starttimeArray
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>{st}</Table.Cell>
                  </Table.Row>
                ))
              : null}

          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details[0].start_time[0] !== ""
              ? startTime
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>{st}</Table.Cell>
                  </Table.Row>
                ))
              : details[0].cacID?.starttimeArray
                ? details[0].cacID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
                : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details[0].cacID?.durationArray
              ? details[0].cacID?.durationArray
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>
                      {st}
                    </Table.Cell>
                  </Table.Row>
                ))
              : null}

          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details[0].actual_duration[0] !== ""
              ? actualDuration
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>{st}</Table.Cell>
                  </Table.Row>
                ))
              : null}

          </Table.HeaderCell>

          <Table.Row>
            <Table.Cell>Total Duration</Table.Cell>

            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              {details[0].cacID?.gethour}
            </Table.Cell>
            <Table.Cell>{details[0].duration}</Table.Cell>
          </Table.Row>

        </Table.Body>
      </Table>
    </>
  );
};

export default Items;
