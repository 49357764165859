import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
// Custom Components
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import { Button } from "semantic-ui-react";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Moment from "moment";
import Axios from "utils/axios";
import swal from "sweetalert";
import { getCookie } from "../../../../action/manager";

const ViewLinQuestion = () => {
  const history = useHistory();
  const classes = useDashboardStyles();
  const { id } = useParams();
  const [answer, setAnswer] = useState("");
  const [values, setValues] = useState([]);

  useEffect(() => {
    Axios.get(
      `/api/manager-admin/sppo-question`
    ).then((result) => {
      setValues(result.data);
    });
  }, []);

  const handleSend = async () => {
    const token = getCookie("EMPtoken");
    try {
      await Axios.put(
        `/api/employee/all-question/answer/quesID/${id}`,
        { answer },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then(() => {
        swal({
          icon: "success",
          title: "Success",
          text: "Answer sent to the SPPO successfully",
          buttons: false,
        });
        history.push("/employee/help-sppo");
      });
    } catch (err) {
      swal({
        icon: "error",
        title: "OOPS!",
        text: "Something went wrong",
        buttons: false,
      });
    }
  };
  return (
    <EmployeeLayout>
      <Employee>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>
              Question from -{" "}
              {values
                .filter((list) => list?._id === id)
                .map((list) => list?.postedByLIN?.id)}
            </p>
            <i
              className="icon list arrow left"
              onClick={() => {
                history.goBack();
              }}
            ></i>
          </CardHeader>
        </GridItem>

        <div raised style={{ marginLeft: "15%", marginRight: "15%" }}>
          <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
            <CardHeader
              color="primary"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginTop: 10,
                top: 35,
              }}
            >
              <p className={classes.cardTitleWhite}>Question</p>
            </CardHeader>
          </GridItem>
          <Card
            raised
            style={{ marginTop: "24px", padding: "30px", zIndex: 0 }}
          >
            <CardBody>
              {/* <h4 style={{textAlign: "center"}}></h4> */}

              {values
                .filter((list) => list?._id === id)
                .map((list) => (
                  <div>
                    <h2>
                      Date:{" "}
                      {Moment(list?.createdAt).format("DD MMM YYYY - kk:mm:ss")}
                    </h2>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        fontSize: 15,
                        width: 100 + "%",
                      }}
                    >
                      Last Name: {list?.postedBySPPO?.last_name}
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        fontSize: 15,
                        width: 100 + "%",
                      }}
                    >
                      Question:
                      <br /> {list?.question}
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        fontSize: 15,
                        width: 100 + "%",
                      }}
                    >
                      Answer:
                      <br /> {list?.answer}
                    </p>
                  </div>
                ))}
            </CardBody>
          </Card>
          <br />
          <Card>
            <CardHeader
              color="primary"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: 20,
                top: 35,
              }}
            >
              <p className={classes.cardTitleWhite}>Answer a question</p>
            </CardHeader>
            <CardBody>
              <TextField
                id="standard-textarea"
                placeholder="type answer here..."
                multiline
                rows={4}
                style={{ margin: 15, width: 100 + "%" }}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
              <div style={{ margin: 15, marginTop: 5 }}>
                <Button
                  disabled={answer === ""}
                  onClick={handleSend}
                  color="purple"
                >
                  Post
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </Employee>
    </EmployeeLayout>
  );
};

export default ViewLinQuestion;
