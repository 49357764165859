import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

// Material Components
import Button from "@material-ui/core/Button";
import CustomPassword from "components/Common/CustomPassword";
import { resetPassword } from "redux/actions/auth";
import Axios from "axios";
import history from "utils/history";

const SetPassword = ({id}) => {
  const dispatch = useDispatch();

  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async(e) => {
    // const { password, confirmPassword } = passwords;
    // if (password !== confirmPassword) {
    //   swal({
    //     title: "Oops!",
    //     text: `Password and Confirm Password must be same`,
    //     icon: "error",
    //     buttons: false,
    //   });
    //   return;
    // }
    // dispatch(resetPassword(passwords));

    try {
      const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/initial/linguists/reset-password`, {resetPasswordLink: id, newPassword: passwords.newPassword, confirmPassword: passwords.confirmPassword})
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });
      setTimeout(() => {
        history.push("/linguists-login");
      }, 2000);
    }
    catch(error) {
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      })
    }
  };

  return (
    <Fragment>
      <CustomPassword
        label="New Password"
        name="newPassword"
        value={passwords.newPassword}
        onChange={handleChange}
      />
      <CustomPassword
        label="Confirm Password"
        name="confirmPassword"
        value={passwords.confirmPassword}
        onChange={handleChange}
      />
      <Button
        color="primary"
        variant="contained"
        style={{
          marginTop: "15px",
        }}
        onClick={handleSubmit}
        disabled={
          !passwords.newPassword ||
          !passwords.confirmPassword
        }
      >
        Submit
      </Button>
    </Fragment>
  );
};

export default SetPassword;
