import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";
import CustomSemanticDD from "components/Common/CustomSemanticDD";

//Material icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

// import Details from './Details'
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Statistic,
  Table,
  Segment,
} from "semantic-ui-react";
import { DISCOUNT, TAXIFARE, TYPE_INTERPRETING, CURRENCY_STRIPE } from "data";
import { IconButton, Input, TextField } from "@material-ui/core";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import swal from "sweetalert";
import {
  addOtherTransaction,
  addTransaction,
  deleteOtherTransaction,
  deleteTransaction,
} from "redux/actions/expenseAction";

const Invoice = ({ id }) => {
  const classes = useEmployeeStyles();
  const token = Cookies.get("LINtoken");
  const [invoice, setInvoice] = useState(null);
  const [linguist, setLinguist] = useState(null);
  const [text, setText] = useState("");
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(null);
  const [rate, setRate] = useState(null);
  const [interpretingRate, setInterpretingRate] = useState(1);
  const [estimateRate, setEstimateRate] = useState(null);
  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    company_name: "",
    company_address: "",
  });

  const totalFTF = invoice
    ? Number(invoice[0]) * Number(invoice[1][0]?.duration)
    : null;

  //   2nd subtotal
  const [otherAmount, setOtherAmount] = useState(0);
  const [otherText, setOtherText] = useState("");
  const [otherQuantity, setOtherQuantity] = useState(0);

  const { transactions } = useSelector((state) => state.expenseReducer);
  const amountsOne = transactions.map((transaction) => transaction.total);
  const subTotalOne = Number(
    amountsOne.reduce((acc, item) => (acc += item), 0) + Number(totalFTF)
  ).toFixed(2);

  const { otherTransactions } = useSelector((state) => state.expenseReducer);
  const amountsTwo = otherTransactions.map((transaction) => transaction.total);
  const subTotalTwo = amountsTwo
    .reduce((acc, item) => (acc += item), 0)
    .toFixed(2);

  const total = (Number(subTotalOne) + Number(subTotalTwo)).toFixed(2);

  const [discount, setDiscount] = useState(0);
  const [vat, setVAT] = useState(0);

  //Total after discount
  const totalDiscount = ((Number(total) * discount) / 100).toFixed(2);

  //Including platform and booking service charge
  const totalPBSC = ((Number(total) * companyCard?.pbsc) / 100).toFixed(2);

  //Total VAT
  const totalVAT = (
    Number(Number(total) + Number(totalPBSC) - Number(totalDiscount)) *
    (vat / 100)
  ).toFixed(2);

  //Estimate paid
  const estimatePaidAdvance =
    invoice &&
    (invoice[1][0].cacID.estimate_paid_amount
      ? invoice[1][0].cacID.estimate_paid_amount
      : invoice[1][0].cacID.estimate);

  //Total after including vat and discount
  const totalTwo = (
    Number(total) +
    Number(totalPBSC) -
    Number(totalDiscount) +
    Number(totalVAT)
  ).toFixed(2);

  //Total after subtracting estimate paid
  const grandTotal = invoice && (Number(totalTwo) - rate).toFixed(2);

  //To be paid including vat

  const paidBy = moment().add(14, "days").format("DD/MMM/YYYY");

  const [lingRef, setLingRef] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleAdd = (e) => {
    e.preventDefault();

    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      text,
      amount: +amount,
      quantity,
      total: amount * quantity,
    };

    dispatch(addTransaction(newTransaction));
    setText("");
    setAmount("");
    setQuantity("");
  };

  const handleAddOther = (e) => {
    e.preventDefault();
    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      otherText,
      otherAmount: +otherAmount,
      otherQuantity,
      total: otherAmount * otherQuantity,
    };

    dispatch(addOtherTransaction(newTransaction));
    setOtherText("");
    setOtherAmount("");
    setOtherQuantity("");
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const loadInvoice = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/view-details-timesheet-cac/timesheetID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setInvoice(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          throw error;
        }
      }
    };

    const getLinguistProfile = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLinguist(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getCompanyCard = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/view/company-information-card`
        );

        setCompanyCard({
          ...companyCard,
          basc: response?.data[0].getCompanyCard,
          pbsc: response?.data[0].platform_booking_service_charge,
          basc: response?.data[0].billing_account_service_charge,
          company_name: response?.data[0].company_name,
          company_address: response?.data[0].company_address,
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadInvoice();
    getLinguistProfile();
    getCompanyCard();

    return () => {
      source.cancel();
    };
  }, []);

  const handleCurrency = async (e) => {
    const { value } = e.target;
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
      );

      if (value === "GBP (UK)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.GBP) /
            response?.data?.rates[
              invoice[1][0].cacID?.currency_new?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "EUR (Euro countries)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.EUR) /
            response?.data?.rates[
              invoice[1][0].cacID?.currency_new?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "AUD (Australia)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.AUD) /
            response?.data?.rates[
              invoice[1][0].cacID?.currency_new?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "USD (USA, paying from USA only)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.USD) /
            response?.data?.rates[
              invoice[1][0].cacID?.currency_new?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "CAD (Canada)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.CAD) /
            response?.data?.rates[
              invoice[1][0].cacID?.currency_new?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "TRY (Turkish lira, payment from Turkey only)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.TRY) /
            response?.data?.rates[
              invoice[1][0].cacID?.currency_new?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "SGD (Singapore dollar)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.SGD) /
            response?.data?.rates[
              invoice[1][0].cacID?.currency_new?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "HUF (Hungarian forint)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.HUF) /
            response?.data?.rates[
              invoice[1][0].cacID?.currency_new?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (
        value === "NZD (New Zealand dollar, paying from New Zealand only)"
      ) {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.NZD) /
            response?.data?.rates[
              invoice[1][0].cacID?.currency_new?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "RON (Romanian leu, paying from Romania only)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.RON) /
            response?.data?.rates[
              invoice[1][0].cacID?.currency_new?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      }

      setCurrency(value);
    } catch (error) {
      console.log(error);
    }

    try {
      const rate = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
      );
      const temp = value.split(" ")[0];
      setInterpretingRate(rate?.data?.rates[temp].toFixed(2));
    } catch (error) {
      console.log(error);
    }

    try {
      const Erate = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
      );
      const temp = value.split(" ")[0];
      setEstimateRate(Erate?.data?.rates[temp].toFixed(2));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/make-cac-invoice/timesheetID/${id}`,
        {
          date_of_quote: "",
          date_of_invoice: moment().format("DD/MMM/YYYY"),
          service_completion_date: invoice[1][0].end_date
            ? moment(invoice[1][0].end_date).format("DD/MMM/YYYY")
            : "",
          linguist_ref: lingRef,
          currency: currency ? currency : "GBP (UK)",
          transactions: transactions.concat([
            {
              text: invoice[1][0].cacID.interpreting_service_required,
              amount: invoice[0],
              quantity: invoice[1][0].duration,
              total: totalFTF,
            },
          ]),
          otherTransactions: otherTransactions,
          subtotal1: subTotalOne,
          subtotal2: subTotalTwo,
          service_charge: companyCard?.pbsc,
          service_charge_amount: totalPBSC,
          discount_percentage: discount,
          discount_amount: totalDiscount,
          vat_percentage: vat,
          vat_amount: totalVAT,
          grand_total: invoice[1][0].cacID?.pay_later ? totalTwo : grandTotal,
          // invoice_payment_status: grandTotal <= 0 ? true : false,
          deposit: "",
          due: "",
          linguist_note: "",
          admin_note: "",
          client_note: "",
          rate: invoice[0],
          total: totalTwo,
          estimate_paid_amount: invoice[1][0].cacID?.pay_later ? "0.00" : rate,
          company_name: companyCard?.company_name,
          company_address: companyCard?.company_address,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Invoice successfully send",
        icon: "success",
        button: "Ok",
      }).then(function () {
        window.location = "/linguist/dashboard";
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return invoice && linguist ? (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem id="printArea" xs={12} sm={12} md={12} lg={8} xl={8}>
          <Card>
            <CardBody>
              {/* Avatar and company name */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "24px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <CustomAvatar style={{ margin: "0" }} />
                </div>
              </div>

              {/* First div  */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice to:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice[1][0].cacID?.postedBy?.id}
                    </Header>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice[1][0].id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ID number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{linguist.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist Residence:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{linguist.Country}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      VAT/GST no:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {linguist?.vat_number ? linguist?.vat_number : "N/A"}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of quote:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;N/A
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of invoice:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{moment().format("DD/MMM/YYYY")}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice to be paid by:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{paidBy}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Job reference:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice[1][0].cacID.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Service completion date:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {moment(invoice[1][0].end_date).format("DD/MMM/YYYY")}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ref:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      <Input
                        onChange={(e) => setLingRef(e.target.value)}
                        value={lingRef}
                        type="number"
                      />
                    </Header>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Service:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;{invoice[1][0].cacID.typesof_interpreting_required}
                  </Header>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Expertise required:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;{invoice[1][0].cacID.target_linguist_skill_level}
                  </Header>
                </div>
              </div>

              {/* Table  */}
              <Segment size="small" textAlign="center" color="purple">
                <CustomSemanticDD
                  onChange={(e) => handleCurrency(e)}
                  value={currency}
                  label="Currency*"
                  data={CURRENCY_STRIPE}
                />
              </Segment>
              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="5"></Table.HeaderCell>
                    <Table.HeaderCell width="1"></Table.HeaderCell>
                    <Table.HeaderCell width="1"></Table.HeaderCell>
                    {/* <Table.HeaderCell width= '1'><CustomAutoSelect label='Currency' data={CURRENCY_STRIPE} value= 'GBP (UK)'/></Table.HeaderCell> */}
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell width="5">
                      Description of service
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Price</Table.HeaderCell>
                    <Table.HeaderCell width="1">Quantity</Table.HeaderCell>
                    <Table.HeaderCell width="1">Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {invoice[1][0].cacID.interpreting_service_required ===
                  "Face to Face FTF - On Site" ? (
                    <Table.Row>
                      <Table.Cell>Face to Face interpreting FTF</Table.Cell>
                      <Table.Cell>{invoice[0]}</Table.Cell>
                      <Table.Cell>{invoice[1][0].duration}</Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{totalFTF}</Header>
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row>
                      <Table.Cell>Remote interpreting</Table.Cell>
                      <Table.Cell>{invoice[0]}</Table.Cell>
                      <Table.Cell>{invoice[1][0].duration}</Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{totalFTF}</Header>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {transactions.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.text}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.amount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.quantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                        <IconButton
                          onClick={() => dispatch(deleteTransaction(tr.id))}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell>
                      <CustomAutoSelect
                        onChange={(e, value) => setText(value)}
                        value={text}
                        label="Choose"
                        data={TYPE_INTERPRETING}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                        placeholder="£ 00.00"
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setQuantity(e.target.value)}
                        value={quantity}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <IconButton
                        disabled={
                          !amount ||
                          !text ||
                          !quantity ||
                          quantity <= 0 ||
                          amount <= 0
                        }
                        onClick={handleAdd}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header textAlign="right" as="h3">
                        Sub Total
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {Number(subTotalOne)}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Expenses and travel</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  {otherTransactions.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.otherText}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherAmount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherQuantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                        <IconButton
                          onClick={() =>
                            dispatch(deleteOtherTransaction(tr.id))
                          }
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell>
                      <CustomAutoSelect
                        onChange={(e, value) => setOtherText(value)}
                        value={otherText}
                        label="Choose"
                        data={TAXIFARE}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setOtherAmount(e.target.value)}
                        value={otherAmount}
                        placeholder="£ 00.00"
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setOtherQuantity(e.target.value)}
                        value={otherQuantity}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <IconButton
                        disabled={
                          !otherQuantity ||
                          !otherAmount ||
                          !otherText ||
                          otherQuantity <= 0 ||
                          otherAmount <= 0
                        }
                        onClick={handleAddOther}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h3">Sub Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {subTotalTwo}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {total}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Discount %</Header>
                      <CustomAutoSelect
                        data={DISCOUNT}
                        onChange={(e, value) => setDiscount(value)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalDiscount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Billing Account Service Charge {companyCard?.basc} %
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>0.00</Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Platform and booking service charge {companyCard?.pbsc}{" "}
                        %
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalPBSC}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">VAT %</Header>
                      <TextField
                        onChange={(e) => setVAT(e.target.value)}
                        type="number"
                        inputProps={{ min: "0", max: "10", step: "1" }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalVAT}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalTwo}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Estimate paid in advance</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        {invoice[1][0].cacID?.pay_later ? (
                          <Statistic.Value>0.00</Statistic.Value>
                        ) : (
                          <Statistic.Value>
                            {invoice[1][0].cacID.currency_new?.substring(0, 3)}
                            {invoice[1][0].cacID.estimate_paid_amount
                              ? invoice[1][0].cacID.estimate_paid_amount
                              : invoice[1][0].cacID.estimate}
                          </Statistic.Value>
                        )}
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Estimate paid in advance (converted)
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic color="purple" size="mini">
                        {invoice[1][0].cacID?.pay_later ? (
                          <Statistic.Value>0.00</Statistic.Value>
                        ) : (
                          <Statistic.Value>
                            {currency?.split("(")[0]
                              ? currency?.split("(")[0]
                              : "GBP"}{" "}
                            {rate}
                          </Statistic.Value>
                        )}
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Total to be paid including VAT/GST
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        {invoice[1][0].cacID?.pay_later ? (
                          <Statistic.Value>
                            {currency?.split("(")[0]
                              ? currency?.split("(")[0]
                              : "GBP"}{" "}
                            {totalTwo}
                          </Statistic.Value>
                        ) : (
                          <Statistic.Value>
                            {currency?.split("(")[0]
                              ? currency?.split("(")[0]
                              : "GBP"}{" "}
                            {grandTotal}
                          </Statistic.Value>
                        )}
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Header style={{ marginTop: "16px" }} as="h4">
                  Thank You For Your Business
                </Header>
                <Header
                  color="grey"
                  style={{ marginBottom: "16px", marginTop: "0" }}
                  as="h4"
                >
                  {/* Linguists Collective Ltd, 38 Mill Street, Bedford,
                  Bedfordshire, MK40 3HD */}
                  {companyCard?.company_name}, {companyCard?.company_address}
                </Header>
                {grandTotal < 0 ? (
                  <p style={{ color: "red" }}>
                    Negative value!! Please make necessary arrangement for
                    refunding the amount shown (-) in 'Total to be paid
                    including VAT/GST'.
                  </p>
                ) : null}
                <Button
                  disabled={!currency}
                  loading={loading}
                  onClick={handleSend}
                  color="purple"
                >
                  Send
                </Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default Invoice;
