// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";


//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import AccountClientLayout from "layout/AccountClientLayout";
import TimesheetBAC from 'components/AccountClient/Assignments/ClosedAssignments/TimesheetBAC/TimesheetBAC'

const CloseTimesheetPage = () => {
  const classes = useDashboardStyles();
  return (
    <AccountClientLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
              <p className={classes.cardTitleWhite}>Timesheet</p>
              <IconButton color='inherit'><ArrowBackIcon
                style={{ marginLeft: 5 }}
                onClick={() => history.goBack()}
              ></ArrowBackIcon></IconButton>
            </CardHeader>
            <CardBody>
              <TimesheetBAC />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AccountClientLayout>
  );
};

export default CloseTimesheetPage;
