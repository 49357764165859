import React from "react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import CommonCard from "components/Common/CommonCard";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from "react-router-dom";

const ViewContactCard = () => {
  const { id, message } = useParams();
  const classes = useDashboardStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>Slider View</p>
            <IconButton color="inherit">
              <ArrowBackIcon onClick={() => history.goBack()}></ArrowBackIcon>
            </IconButton>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center" className={classes.container}>
              <GridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                <div style={{ margin: "5px" }}>
                  <CommonCard
                    style={{ textAlign: "center" }}
                  >
                    <CardHeader color="primary">
                      <p style={{ textAlign: "center" }}>
                        {history.location.pathname.includes("admin/linguist") ?
                          "Linguist public profile slider" :
                          history.location.pathname.includes("admin/sppo") ?
                            "SPPO public profile slider" :
                            history.location.pathname.includes("admin/job-advert-sliders") ?
                              "Job Advert Slider" : "Slider View"}
                      </p>
                    </CardHeader>
                    <img
                      style={{ width: "100%", padding: "5%" }}
                      src={`${process.env.REACT_APP_BACKEND_API}/manager-admin/view-slider/sliderID/${id}`}
                      alt=""
                    />
                  </CommonCard>
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewContactCard;
