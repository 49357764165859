import React from 'react'
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import AccountClientLayout from "layout/AccountClientLayout";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from "@material-ui/core";
import history from 'utils/history'

// Custom Components
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";

//Custom styles
import useDashboardStyles from 'assets/jss/pages/dashboardStyle';
import CardBody from 'components/Card/CardBody';
import ViewUnconfirmQuotation from 'components/AccountClient/Assignments/UnconfirmQuotations/ViewUnconfirmQuotation';
import { useParams } from 'react-router-dom';

const ViewUnconfirmQuotationPage = () => {
  const classes = useDashboardStyles();
  const { id } = useParams()
  return (
    <Accountclient>
      <AccountClientLayout>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }} color="primary">
            <p className={classes.cardTitleWhite}>Quotation</p>
            <IconButton color='inherit' onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
          </CardHeader>
          <CardBody>
            <ViewUnconfirmQuotation id={id} />
          </CardBody>
        </GridItem>
      </AccountClientLayout>
    </Accountclient>
  )
}

export default ViewUnconfirmQuotationPage
