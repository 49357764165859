import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LandingPageLayout from "layout/LandingPageLayout";
// Material Core Components
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import history from "utils/history";
// Material Icons
import LoadingScreen from "react-loading-screen";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import Cookies from "js-cookie";
import Axios from "axios";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

const InhousePayment = () => {
  const classes = useEmployeeStyles();

  const id = Cookies.get("prcTransID");
  const currency_new_card = Cookies.get("currency_new_card");
  const new_total = Cookies.get("new_total");
  const [payment, setPayment] = useState(false);
  const { paymentIntent } = useParams();

  useEffect(() => {
    Axios.post(
      `${process.env.REACT_APP_BACKEND_API}/private_translation/order_translation/confirmmail/stripe`,
      { currency_new_card, new_total, id, paymentIntent }
    ).then((response) => {
      let x = response?.data;
      if (x === "error") {
        swal("Sorry!! something wrong. payment not recived.", {
          icon: "error",
          buttons: "ok",
        });
        history.push("/private-translation-order/cli_ent/summary");
      } else {
        setPayment(true);
      }
    });
  }, []);

  return (
    <LandingPageLayout>
      {payment === true ? (
        <div className={classes.root}>
          <div className={classes.root}>
            <GridContainer justify="center" className={classes.container}>
              <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
                <Card>
                  <CardBody>
                    <div className={`${classes.title}`}>
                      <Typography component="h1" variant="h5">
                        Order {id} Successful!
                      </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{ marginRight: "20px" }}
                        src="https://www.cntraveller.in/wp-content/themes/cntraveller/images/check-circle.gif"
                        alt=""
                      />
                      <Typography component="h1" variant="h5">
                        Your order is confirmed
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h5">
                        Check your email for more details
                      </Typography>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      ) : (
        <LoadingScreen
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          logoSrc="https://linguistscollective.com/static/media/Interpreting-Translation-Linguists-Collective-Language-Services.0f1108ca.png"
          text="Please wait! The system is generating email and waiting for payment authorization."
        >
          <div>Loadable content</div>
        </LoadingScreen>
      )}
    </LandingPageLayout>
  );
};

export default InhousePayment;
