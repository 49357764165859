import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LinguistProfile from "components/PublicProfileLIN/ProtectedPublicProfile/PPL";

const ManagePublicProfile = () => {
  const classes = useDashboardStyles();

  return (
    <EmployeeLayout>
      <Employee>

        <LinguistProfile />

      </Employee>
    </EmployeeLayout>
  );
};

export default ManagePublicProfile;
