import styled from "styled-components";
import { Button } from "@material-ui/core";

export const CustomButton = styled(Button)`
  .btn {
    color: #ab47bc;
    border: none;
    background: transparent;
    padding: 4px 15px;
  }
`;

export const DateFilterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  h3 {
    margin: 0;
    padding-right: 15px;
  }
`;

export const ReportCardsDiv = styled.div``;

export const OneCardDiv = styled.div`
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  span {
    font-size: 1.1rem;
    color:white
  }

  .icon {
    margin-top: 13px;
  }
`;

export const ReportFilterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%
`;

export const BillsDiv = styled.div`
  margin-top: 2rem;
`;

export const TableDataDiv = styled.div`
  padding-top: 2rem;
`;
