import { v4 as uuidv4 } from "uuid";
import Dashboard from "pages/sppo-page/Dashboard";
import InhouseBooking from "pages/sppo-page/ManageInhouse/InhouseBooking";
import RateCardPage from "pages/sppo-page/RateCardPage";
import CarpCardPage from "pages/sppo-page/CarpCardPage";
import CreateInvoice from "pages/sppo-page/CreateInvoice";
import MyClient from "pages/sppo-page/MyClients/MyClient";
import MyLeads from "pages/sppo-page/MyLeads/MyLeads";
import BookInterpreter from "pages/sppo-page/BookInterpreter";
import MyNote from "pages/sppo-page/MyNote/MyNote";
import Notifications from "pages/sppo-page/Notifications/Notifications";
import ColleagueNotes from "pages/sppo-page/MyNote/ColleagueNotes";
import ViewAttachMessage from "pages/sppo-page/WelcomeMessage/ViewAttachMessage";

import ManagePublicProfile from "pages/sppo-page/ManagePublicProfile";
import CashBook from "pages/sppo-page/Cashbook";
import PriceCalculator from "pages/sppo-page/PriceCalculator";
//manage inhouse
import InhouseClientReg from "pages/sppo-page/MyClients/InhouseClientReg";
import SendInhouseFormPage from "pages/sppo-page/MyClients/Options";
import INCAccountRemoval from "pages/sppo-page/MyClients/AccountRemoval";
import ViewInhouseClient from "pages/sppo-page/MyClients/ClientList";
import INCInterpreterBooking from "pages/sppo-page/InterpretingForm/INCInterpreterBooking";
import INCInterpreterBookingSummary from "pages/sppo-page/InterpretingForm/insummary";
import SendBookingForm from "pages/sppo-page/ManageInhouse/SendBookingForm";
import OrderTranslation from "pages/sppo-page/TranslationForm/OrderTranslation";
import OrderTranslationSummary from "pages/sppo-page/TranslationForm/ordertranslationtelephonicsummary";
import INCUnconfirmAssignment from "pages/sppo-page/UnconfirmAssignment";
import INCConfirmAssignment from "pages/sppo-page/ConfirmAssignment";
import INCCancelAssignment from "pages/sppo-page/CancelAssignment";
//my clients
import DirectQuote from "pages/sppo-page/Quotation/DirectQuote";
import DirectInvoice from "pages/sppo-page/DirectInvoice/DirectInvoice";
import DirectQuoteInvoice from "pages/sppo-page/DirectQuoteInvoice";
import ProvideQuote from "pages/sppo-page/Quotation/ProvideQuote";
//find linguist
import UnregisteredLinguistList from "pages/sppo-page/FindLinguist/UnregisteredLinguists";
import RegisteredLinguistList from "pages/sppo-page/FindLinguist/RegisteredLinguists";
import FindLinguist from "pages/sppo-page/FindLinguist/FindLinguist";
import LINRegistration from "pages/sppo-page/LinguistRegistration/SendLINRegistration";

//communication
import Communication from "pages/sppo-page/Communication/Communication";
import CreateTicket from "pages/sppo-page/Communication/CreateTicket";
import OpenTicket from "pages/sppo-page/Communication/TicketList";
import ViewAttachConversation from "pages/sppo-page/Communication/ViewAttachConversation";
import ViewAttachTicket from "pages/sppo-page/Communication/ViewAttachTicket";

import Projects from "pages/sppo-page/Project/Projects";
import UsersPage from "pages/sppo-page/AddAnUser/UsersPage";
import ResetPassword from "pages/sppo-page/ResetPassword";
import ProfilePage from "pages/sppo-page/ProfilePage";
import CurrentAssignmentPage from "pages/sppo-page/CurrentAssignmentPage";
import LoginPage from "pages/sppo-page/Login";
import PercentageCardView from "pages/sppo-page/PercentageCardView";
import SetNewPassword from "pages/sppo-page/SetNewPassword";
import Help from "pages/sppo-page/Help";

//partnership agreement
import PartnershipAgreement from "pages/sppo-page/PartnershipAgreement";

//Activity logs
import ActivityLogs from "pages/sppo-page/ActivityLogs//ActivityLogs";

//Icons

import PCCicon from "assets/icons/price-calculator-cashbook.png";
import Iicon from "assets/icons/invoice.png";
import FLicon from "assets/icons/find-linguist.png";
import ActivityLogIcon from "assets/icons/activitylog.png";
import Dicon from "assets/icons/dashboard.png";
import MIicon from "assets/icons/inhouse-client.png";

import VPCicon from "assets/icons/view-percentage-card.png";
import MPPicon from "assets/icons/manage-public-profile.png";

import VCCicon from "assets/icons/view-carp-card.png";
import RCicon from "assets/icons/rate-card.png";
import CMicon from "assets/icons/communication-menuAC.png";
import NTMicon from "assets/icons/my noteto do list.png";
import NCCRicon from "assets/icons/notes-02.png";
import NMCRicon from "assets/icons/my notes-02.png";
import LEicon from "assets/icons/leads-01.png";
import PTicon from "assets/icons/project-tasks.png";
import DQicon from "assets/icons/direct qoutes-03.png";
import CRIcon from "assets/icons/complete-registration.png";
import MTicon from "assets/icons/manage team-03.png";
import RLIcon from "assets/icons/Register linguist-03.png";
import MCSicon from "assets/icons/my client-03.png";

import FullRegistration from "pages/sppo-page/profile/fullregistration";
import SendRegForm from "pages/sppo-page/AddAnUser/SendRegForm";
import SendClientRegForm from "pages/sppo-page/MyClients/SendRegForm";
import INCRegFormPublic from "pages/sppo-page/MyClients/INCRegFormPublic";
import ViewInactiveUsers from "pages/sppo-page/AddAnUser/ViewInactiveUsers";
import ViewActiveUsers from "pages/sppo-page/AddAnUser/ViewActiveUsers";
import SPPOUserProfile from "pages/sppo-page/AddAnUser/SPPOUserProfile";
import ViewINCClients from "pages/sppo-page/MyClients/ClientList";
import ViewINCClientsProfile from "pages/sppo-page/MyClients/ClientProfile";
import ViewInterpretingDetails from "pages/sppo-page/ManageInhouse/ViewBooking";
import ViewTransDetails from "pages/sppo-page/ManageInhouse/ViewTranslation";
import ViewTransDocumentList from "pages/sppo-page/ManageInhouse/ListINCTranslationDocument";
import ViewBookingDocumentList from "pages/sppo-page/ManageInhouse/ListINCInterpretingDocument";
import ViewAssignmentDocuments from "pages/sppo-page/ManageInhouse/ViewAssignmentDocuments";
import SavedDirectQuoteList from "pages/sppo-page/Quotation/SavedDirectQuoteList";
import SentDirectQuoteList from "pages/sppo-page/Quotation/SentDirectQuoteList";
import AcceptedDirectQuoteList from "pages/sppo-page/Quotation/AcceptedDirectQuoteList";
import ViewDirectQuote from "pages/sppo-page/Quotation/ViewDirectQuote";
import ProvideDirectInvoice from "pages/sppo-page/DirectInvoice/ProvideInvoice";
import DirectInvoiceList from "pages/sppo-page/DirectInvoice/DirectInvoiceList";
import ViewDirectInvoice from "pages/sppo-page/DirectInvoice/ViewDirectInvoice";

import CreateDirectQuoteProjectReport from "pages/sppo-page/Quotation/CreateDirectQuoteProjectReport";
import ViewDirectQuoteProjectReport from "pages/sppo-page/Quotation/ViewDirectQuoteProjectReport";
import CreateDirectQuoteInvoice from "pages/sppo-page/Quotation/CreateDirectQuoteInvoice";
import SPPOInvoiceList from "pages/sppo-page/Invoicing/Invoiced";
import SPPOReceivedInvoiceList from "pages/sppo-page/Invoicing/ReceivedInvoiced";

import SendCLARegistrationForm from "pages/sppo-page/MyLeads/SendCLARegistrationForm";
import SendClientRegistrationForm from "pages/sppo-page/MyLeads/SendClientRegistrationForm";
import ViewCLADetails from "pages/sppo-page/MyLeads/ViewCLADetails";

import CompanyContactDetails from "pages/sppo-page/CompanyContactDetails";
import ViewPassport from "components/Sppo/ProfileUpdate/ViewPassport";

import TranslationDeliveryDoc from "pages/Component/Translation_Delivery/SPPO/ViewDocument";

const SPPO = [
  {
    id: uuidv4(),
    text: "Dashboard",
    subText: "Service Providing Partner Dashboard",
    path: "sppo/dashboard",
    type: "dashboard",
    component: Dashboard,
    icon: Dicon,
  },
  {
    id: uuidv4(),
    text: "My Clients",
    path: "sppo/my-clients",
    component: MyClient,
    icon: MCSicon,
  },
  {
    id: uuidv4(),
    text: "My Leads",
    path: "sppo/my-leads",
    component: MyLeads,
    icon: LEicon,
  },
  {
    id: uuidv4(),
    text: " Manage In-house ⇧ Assignments",
    path: "sppo/manage-inhouse",
    component: InhouseBooking,
    icon: MIicon,
  },
  {
    id: uuidv4(),
    text: "Direct Quote & Invoice",
    path: "sppo/direct-qoute-invoice",
    component: DirectQuoteInvoice,
    icon: DQicon,
  },
  // {
  //   id: uuidv4(),
  //   text: "Projects",
  //   component: Projects,
  //   path: "sppo/projects",
  //   icon: PTicon,
  // },
  {
    id: uuidv4(),
    text: "Invoicing",
    component: CreateInvoice,
    path: "sppo/invoices",
    icon: Iicon,
  },
  {
    id: uuidv4(),
    text: "Find Linguist",
    component: FindLinguist,
    path: "sppo/find-linguist",
    icon: FLicon,
  },
  {
    id: uuidv4(),
    text: "Linguist Registration",
    component: LINRegistration,
    path: "sppo/linguist-registration",
    icon: RLIcon,
  },
  {
    id: uuidv4(),
    text: "Manage Team",
    path: "sppo/manage-team",
    component: UsersPage,
    icon: MTicon,
  },
  {
    id: uuidv4(),
    text: "Task, Note And Do List",
    path: "sppo/tasks",
    icon: NTMicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Notes And Do List - Colleagues",
        component: ColleagueNotes,
        path: "sppo/colleague-notes",
        icon: NCCRicon,
      },
      {
        id: uuidv4(),
        text: "My Notes And Do List",
        path: "sppo/my-notes",
        component: MyNote,
        icon: NMCRicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "Communication",
    path: "sppo/communication",
    component: Communication,
    icon: CMicon,
  },

  {
    id: uuidv4(),
    text: "Manage Public Profile",
    component: ManagePublicProfile,
    path: "sppo/manage-public-profile",
    icon: MPPicon,
  },
  {
    id: uuidv4(),
    text: "Price Calculator And Cashbook",
    component: CashBook,
    path: "sppo/price-calculator-cashbook",
    icon: PCCicon,
  },
  {
    id: uuidv4(),
    text: "Rate Card",
    component: RateCardPage,
    path: "sppo/view-rate-card",
    icon: RCicon,
  },
  {
    id: uuidv4(),
    text: "CARP Card",
    component: CarpCardPage,
    path: "sppo/view-carp-card",
    icon: VCCicon,
  },

  {
    id: uuidv4(),
    text: "Percentage Card",
    component: PercentageCardView,
    path: "sppo/view-percentage-card",
    icon: VPCicon,
  },
  {
    id: uuidv4(),
    text: "Activity Logs",
    component: ActivityLogs,
    path: "sppo/activity-logs",
    icon: ActivityLogIcon,
  },
  {
    id: uuidv4(),
    text: "Complete Registration",
    path: "sppo/full-registration",
    component: FullRegistration,
    icon: CRIcon,
  },

  {
    id: uuidv4(),
    path: "sppo/help",
    component: Help,
  },
  {
    id: uuidv4(),
    path: "sppo/reset-password",
    component: ResetPassword,
  },
  {
    id: uuidv4(),
    path: "sppo/auth/password/reset/:token",
    component: SetNewPassword,
  },

  {
    id: uuidv4(),
    path: "sppo/login",
    component: LoginPage,
    type: "login",
    subText: "Sppo Login",
  },
  {
    id: uuidv4(),
    path: "sppo/current-assignment",
    component: CurrentAssignmentPage,
  },
  {
    id: uuidv4(),
    path: "sppo/profile",
    component: ProfilePage,
  },

  {
    id: uuidv4(),
    path: "sppo/book-interpreter",
    component: BookInterpreter,
  },
  {
    id: uuidv4(),
    path: "sppo/order-translation",
    component: OrderTranslation,
  },

  {
    id: uuidv4(),
    path: "sppo/price-calculator",
    component: PriceCalculator,
  },
  {
    id: uuidv4(),
    path: "sppo/cashbook",
    component: CashBook,
  },

  //inhouse routes
  {
    id: uuidv4(),
    path: "sppo/add-inhouse-client/registration",
    component: InhouseClientReg,
  },
  {
    id: uuidv4(),
    path: "sppo/add-inhouse-client/send-form",
    component: SendInhouseFormPage,
  },
  {
    id: uuidv4(),
    path: "sppo/add-inhouse-client/removal-request",
    component: INCAccountRemoval,
  },
  {
    id: uuidv4(),
    path: "sppo/view-inhouse-client",
    component: ViewInhouseClient,
  },
  {
    id: uuidv4(),
    path: "sppo/send-booking-form",
    component: SendBookingForm,
  },
  {
    id: uuidv4(),
    path: "sppo/inhouse-booking/book-interpreter",
    component: INCInterpreterBooking,
  },

  {
    id: uuidv4(),
    path: "sppo/inhouse-booking/order-translation",
    component: OrderTranslation,
  },
  {
    id: uuidv4(),
    path: "sppo/inhouse-booking/book-interpreter/summary",
    component: INCInterpreterBookingSummary,
  },
  {
    id: uuidv4(),
    path: "sppo/inhouse-booking/order-translation/telephonic/summary",
    component: OrderTranslationSummary,
  },
  {
    id: uuidv4(),
    component: INCUnconfirmAssignment,
    path: "sppo/inc-unconfirm-assignment",
  },
  {
    id: uuidv4(),
    component: INCConfirmAssignment,
    path: "sppo/inc-confirm-assignment",
  },
  {
    id: uuidv4(),
    component: INCCancelAssignment,
    path: "sppo/inc-cancel-assignment",
  },
  {
    id: uuidv4(),
    component: INCUnconfirmAssignment,
    path: "sppo/inc-unconfirm-assignment/:data/:type",
  },
  {
    id: uuidv4(),
    component: INCConfirmAssignment,
    path: "sppo/inc-confirm-assignment/:data/:type",
  },
  {
    id: uuidv4(),
    component: INCCancelAssignment,
    path: "sppo/inc-cancel-assignment/:data/:type",
  },
  {
    id: uuidv4(),
    component: Notifications,
    path: "sppo/view-all-notifications",
  },
  // {
  //   id: uuidv4(),
  //   path: "sppo/colleague-notes",
  //   component: ColleagueNotes,
  // },

  {
    id: uuidv4(),
    path: "sppo/send-reg-form",
    component: SendRegForm,
  },

  {
    id: uuidv4(),
    path: "sppo/send-client-reg-form",
    component: SendClientRegForm,
  },

  {
    id: uuidv4(),
    path: "sppo/inhouse-client-form/:id",
    component: INCRegFormPublic,
  },

  {
    id: uuidv4(),
    path: "sppo/view-clients",
    component: ViewINCClients,
  },

  {
    id: uuidv4(),
    path: "sppo/view-client-profile/:id",
    component: ViewINCClientsProfile,
  },

  {
    id: uuidv4(),
    path: "sppo/view-inactive-users",
    component: ViewInactiveUsers,
  },
  {
    id: uuidv4(),
    path: "sppo/view-active-users",
    component: ViewActiveUsers,
  },
  //my client routes
  {
    id: uuidv4(),
    path: "sppo/my-clients/direct-quote",
    component: DirectQuote,
  },
  {
    id: uuidv4(),
    component: DirectInvoice,
    path: "sppo/my-clients/direct-invoice",
  },
  {
    id: uuidv4(),
    path: "sppo/provide-direct-quote",
    component: ProvideQuote,
  },

  {
    id: uuidv4(),
    path: "sppo/additional-user-profile/:id",
    component: SPPOUserProfile,
  },
  {
    id: uuidv4(),
    path: "sppo/unregistered-linguists-list",
    component: UnregisteredLinguistList,
  },
  {
    id: uuidv4(),
    path: "sppo/registered-linguists-list",
    component: RegisteredLinguistList,
  },
  {
    id: uuidv4(),
    path: "sppo/open-new-ticket",
    component: CreateTicket,
  },

  {
    id: uuidv4(),
    path: "sppo/view-tickets",
    component: OpenTicket,
  },
  {
    id: uuidv4(),
    path: "sppo/view-attachment-ticket-conversation/:id",
    component: ViewAttachConversation,
  },

  {
    id: uuidv4(),
    path: "sppo/view-ticket-attachment/:id",
    component: ViewAttachTicket,
  },

  {
    id: uuidv4(),
    path: "sppo/inc-view-interpreting-assignment/:id",
    component: ViewInterpretingDetails,
  },

  {
    id: uuidv4(),
    path: "sppo/inc-view-translation-assignment/:id",
    component: ViewTransDetails,
  },

  {
    id: uuidv4(),
    path: "sppo/inc-view-interpreting-document/:id",
    component: ViewBookingDocumentList,
  },
  {
    id: uuidv4(),
    path: "sppo/inc-view-translation-document/:id",
    component: ViewTransDocumentList,
  },
  {
    id: uuidv4(),
    path: "sppo/view-assignment-document/:id",
    component: ViewAssignmentDocuments,
  },

  {
    id: uuidv4(),
    path: "sppo/saved-direct-quote-list",
    component: SavedDirectQuoteList,
  },

  {
    id: uuidv4(),
    path: "sppo/sent-direct-quote-list",
    component: SentDirectQuoteList,
  },

  {
    id: uuidv4(),
    path: "sppo/accepted-direct-quote-list",
    component: AcceptedDirectQuoteList,
  },

  {
    id: uuidv4(),
    path: "sppo/view-direct-quote/:id",
    component: ViewDirectQuote,
  },

  {
    id: uuidv4(),
    path: "sppo/provide-direct-invoice",
    component: ProvideDirectInvoice,
  },

  {
    id: uuidv4(),
    path: "sppo/direct-invoice-list",
    component: DirectInvoiceList,
  },
  {
    id: uuidv4(),
    path: "sppo/view-direct-invoice/:id",
    component: ViewDirectInvoice,
  },
  {
    id: uuidv4(),
    path: "sppo/create-direct-quote-project-report/:id",
    component: CreateDirectQuoteProjectReport,
  },
  {
    id: uuidv4(),
    path: "sppo/direct-quote/view-project-report/:id",
    component: ViewDirectQuoteProjectReport,
  },

  {
    id: uuidv4(),
    path: "sppo/create-direct-quote-invoice/:id",
    component: CreateDirectQuoteInvoice,
  },

  {
    id: uuidv4(),
    path: "sppo/invoiced-list",
    component: SPPOInvoiceList,
  },

  {
    id: uuidv4(),
    path: "sppo/received-invoice-list",
    component: SPPOReceivedInvoiceList,
  },
  //partnership agreement
  {
    id: uuidv4(),
    component: PartnershipAgreement,
    path: "sppo/partnership-agreement-of-working-with-linguists-collective",
  },
  {
    id: uuidv4(),
    path: "sppo/send-cla-registration-form",
    component: SendCLARegistrationForm,
  },

  {
    id: uuidv4(),
    path: "sppo/send-client-registration-form",
    component: SendClientRegistrationForm,
  },
  {
    id: uuidv4(),
    path: "sppo/view-cla/:id",
    component: ViewCLADetails,
  },
  {
    id: uuidv4(),
    path: "sppo/view-welcome-message-attachment/:id/:message",
    component: ViewAttachMessage,
  },
  {
    id: uuidv4(),
    component: CompanyContactDetails,
    path: "sppo/company-contact-details",
  },
  {
    id: uuidv4(),
    component: ViewPassport,
    path: "sppo/sppo-view-passport/:id",
  },

  // view translation delivery document
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "sppo/view-translation-delivery-document1/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "sppo/view-translation-delivery-document2/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "sppo/view-translation-delivery-document3/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "sppo/view-translation-delivery-document4/:id",
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "sppo/view-translation-delivery-document5/:id",
  },
];

export default SPPO;
