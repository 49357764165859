import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Action from "components/Admin/BookingPanel/UnconfirmedAssignments/CAC/ListCACTransAssignments/Actions/Action";
import {
  Button,
  Dimmer,
  Header,
  Icon,
  Loader,
  Modal,
  Table,
} from "semantic-ui-react";
import CustomTextInput from "components/Common/CustomTextInput";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import Cookies from "js-cookie";
import CustomModal from "components/Common/CustomModal";
import swal from "sweetalert";
import SearchCACAssignments from "components/Admin/BookingPanel/UnconfirmedAssignments/CAC/Search/SearchTranslation";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ListCACTransAssignments({ translationData, text }) {
  const token = Cookies.get("token");
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const { addToast } = useToasts();
  const [show, setShow] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paidModal, setPaidModal] = React.useState(false);
  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  let sigPad = useRef({});
  const AdminID = Cookies.get("AdminID");
  const [pay, setPay] = useState({
    payment_date: "",
    payment_ref: "",
    estimate_paid_amount: "",
    note: "",
    signature: "",
    booking_id: "",
    bookingID: "",
    currency: "",
  });

  const handleClose = (e) => {
    setSignature(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    UnconfirmBookingList: null,
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });

  const {
    name,
    error,
    success,
    UnconfirmBookingList,
    removed,
    reload,
    loader,
  } = values;

  useEffect(() => {
    translationData && loadUnconfirmBookingList();
  }, [reload]);

  const loadUnconfirmBookingList = async (e) => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),

      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-unconfirmed-translation-cashclient`
      ),
    ]).then(
      Axios.spread((dashboard, unconfirmedJobs) => {
        setValues({
          ...values,
          UnconfirmBookingList: translationData
            ? unconfirmedJobs.data.filter((item) => item.id === translationData)
            : unconfirmedJobs.data,
          AdminID: dashboard.data.id,
        });
        translationData && setShow(true);
      })
    );
  };

  const searchFunc = (item) => {
    if (!text) {
      return true;
    }

    const searchText = text.toLowerCase();
    const isMatched =
      item.id.toLowerCase().includes(searchText) ||
      item.source_language.toLowerCase().includes(searchText) ||
      item.target_language.toLowerCase().includes(searchText) ||
      item.interpreting_service_required.toLowerCase().includes(searchText) ||
      item.remote_address.toLowerCase().includes(searchText) ||
      item.linguist_skill_level.toLowerCase().includes(searchText) ||
      item.gender.toLowerCase().includes(searchText) ||
      item.car_parking.toLowerCase().includes(searchText) ||
      item.security_clearance.toLowerCase().includes(searchText);

    return isMatched;
  };

  const handlePaid = async (_id, AdminID, id, estimate, currency) => {
    setPaidModal(true);
    setPay({
      ...pay,
      booking_id: _id,
      bookingID: id,
      currency,
      estimate: estimate,
    });
  };

  const handleConfirm = async (e) => {
    setValues({ ...values, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/update-payment-status-paid-cac/translationID/${pay.booking_id}`,
        {
          payment_date: pay.payment_date,
          payment_ref: pay.payment_ref,
          estimate_paid_amount: pay.estimate_paid_amount,
          note: pay.note,
          signature: sign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, loader: false });
      swal({
        icon: "success",
        text: "Estimated total paid",
        buttons: false,
      });
      loadUnconfirmBookingList();
      setPaidModal(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-unconfirm-translation-CAC/translationID/${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data) {
        setValues({ ...values, UnconfirmBookingList: [response?.data] });
        addToast("Assignment found", { appearance: "success" });
        setShow(true);
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <>
      {/* Payment modal */}
      <Modal
        open={paidModal}
        onClose={() => setPaidModal(false)}
        //onOpen={() => setOpen(true)}
        //trigger={<Button>Scrolling Content Modal</Button>}
      >
        <Modal.Header>
          Payment Details - {pay.bookingID}- {pay.currency} - {pay.estimate}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomTextInput
              name="payment_date"
              onChange={handleChange}
              dvalue={pay.payment_date}
              label="Payment date"
              dlabel="Payment date "
              date={true}
            />
            <CustomTextInput
              name="payment_ref"
              onChange={handleChange}
              value={pay.payment_ref}
              label="Payment reference "
              edit={true}
            />
            <CustomTextInput
              name="estimate_paid_amount"
              onChange={handleChange}
              placeholder={pay.estimate}
              value={pay.estimate_paid_amount}
              label="Total estimate paid"
              edit={true}
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
            <CustomTextInput label="Admin Id" value={values.AdminID} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              !pay.payment_ref ||
              !sign ||
              !pay.payment_date ||
              !pay.estimate_paid_amount ||
              values.loader
            }
            onClick={handleConfirm}
            color="purple"
          >
            Confirm <Icon name="chevron right" />
          </Button>
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>

      {!translationData && (
        <SearchCACAssignments
          show={show}
          setShow={setShow}
          setSearch={setSearch}
          search={search}
          handleSearch={handleSearch}
          load={loadUnconfirmBookingList}
        />
      )}
      <Paper className={classes.root}>
        {show && (
          <>
            <TableContainer className={classes.container}>
              <Table color="pink" stickyHeader aria-label="sticky table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                    <Table.HeaderCell>Client ID</Table.HeaderCell>
                    <Table.HeaderCell>Document type</Table.HeaderCell>
                    <Table.HeaderCell>Source language</Table.HeaderCell>
                    <Table.HeaderCell>Target language</Table.HeaderCell>
                    <Table.HeaderCell>
                      Target language skill level
                    </Table.HeaderCell>
                    <Table.HeaderCell>Deadline</Table.HeaderCell>
                    <Table.HeaderCell>Total estimated pay</Table.HeaderCell>
                    <Table.HeaderCell>Word count</Table.HeaderCell>
                    <Table.HeaderCell>Payment status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {UnconfirmBookingList ? (
                  <Table.Body>
                    {UnconfirmBookingList.filter(searchFunc)
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list, i) => (
                        <Table.Row role="checkbox" tabIndex={-1} key={i}>
                          <Table.Cell>
                            <Link
                              to={`/admin/view-cash-client-order-translation/${list._id}`}
                            >
                              {list.id}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>
                            {list.createdBy ? list?.createdBy?.id : "N/A"}
                          </Table.Cell>
                          <Table.Cell>{list.document_type}</Table.Cell>
                          <Table.Cell>{list.source_language}</Table.Cell>
                          <Table.Cell>{list.target_language}</Table.Cell>
                          <Table.Cell>
                            {list.target_expertise_level_required}
                          </Table.Cell>
                          <Table.Cell>
                            {moment(list.deadline).format(
                              "DD-MMM-YYYY : HH:mm"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {list?.currency_new?.substring(0, 3)}{" "}
                            {list.estimate}
                          </Table.Cell>
                          <Table.Cell>{list.wordCount}</Table.Cell>
                          {/* security level and duration is not included in schema */}
                          <Table.Cell>
                            <Action
                              load={loadUnconfirmBookingList}
                              handlePaid={handlePaid}
                              _id={list._id}
                              id={list.id}
                              clientID={
                                list?.createdBy?.id
                                  ? list?.createdBy?.id
                                  : "N/A"
                              }
                              name={
                                list?.createdBy?.FirstName +
                                " " +
                                list?.createdBy?.LastName
                              }
                              estimate={list.estimate}
                              currency={list?.currency_new?.substring(0, 3)}
                              AdminID={values.AdminID}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                ) : null}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={UnconfirmBookingList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </>
  );
}
