import React, { Fragment, useState, useEffect, useRef } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import TextTruncate from "react-text-truncate";
// Custom Components

import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ReplyIcon from "@material-ui/icons/Reply";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";
import { getBACQuestion } from "../../../../action/manager";
import history from "utils/history";
import Axios from "utils/axios";
import swal from "sweetalert";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const List = () => {
  const classes = useDashboardStyles();
  const [page, setPage] = React.useState(0);
  const [message, setMessage] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = async (id) => {
    swal = await swal({
      icon: "warning",
      title: "Are you Sure?",
      buttons: true,
    }).then((isConfirm) => {
      if (isConfirm) {
        Axios.delete(
          `/api/manager-admin/all-question/delete/quesID/${id}`
        )
          .then((result) => {})
          .then(
            swal({
              icon: "success",
              title: "success",
              text: " ",
              buttons: false,
            }),
            loadquestionList(),
            loadquestionList()
          );
      }
    });
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    questionList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, questionList, removed, reload } = values;

  useEffect(() => {
    loadquestionList();
  }, [reload]);

  const loadquestionList = () => {
    getBACQuestion().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setValues({ ...values, questionList: data });
      }
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table color="pink" stickyHeader aria-label="sticky table">
              <Table.Header>
                <Table.Row style={{ textAlign: "left" }}>
                  <Table.HeaderCell width="1">Last Name</Table.HeaderCell>
                  <Table.HeaderCell width="1">Client ID</Table.HeaderCell>
                  <Table.HeaderCell width="1">Question Date</Table.HeaderCell>
                  <Table.HeaderCell width="1">Question</Table.HeaderCell>
                  <Table.HeaderCell width="1">Reply Date</Table.HeaderCell>
                  <Table.HeaderCell width="1">Reply </Table.HeaderCell>
                  <Table.HeaderCell width="1">Replied By</Table.HeaderCell>
                  <Table.HeaderCell width="1">Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {questionList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((list) => (
                    <Table.Row
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={list._id}
                    >
                      <Table.Cell>{list?.postedByBAC?.LastName}</Table.Cell>
                      <Table.Cell>{list?.postedByBAC?.id}</Table.Cell>
                      <Table.Cell>
                        {Moment(list?.createdAt).format(
                          "DD MMM YYYY - kk:mm:ss"
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <TextTruncate
                          line={1}
                          element="span"
                          truncateText="…"
                          text={list?.question}
                          textTruncateChild={
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                history.push(
                                  `/admin/view-account-client-question/${list._id}`
                                )
                              }
                            >
                              Read More
                            </a>
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {Moment(list?.updatedAt).format(
                          "DD MMM YYYY - kk:mm:ss"
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <TextTruncate
                          line={1}
                          element="span"
                          truncateText="…"
                          text={list?.answer}
                          textTruncateChild={
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                history.push(
                                  `/admin/view-account-client-question/${list._id}`
                                )
                              }
                            >
                              Read More
                            </a>
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>{list?.answerByADM}</Table.Cell>
                      <Table.Cell>
                        <IconButton
                          onClick={() =>
                            history.push(
                              `/admin/view-account-client-question/${list._id}`
                            )
                          }
                          color="primary"
                        >
                          <ReplyIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(list._id)}
                          color="secondary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={questionList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </GridItem>
    </GridContainer>
  );
};
export default List;
