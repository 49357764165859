import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";

const ActionINC = ({ id, date, accepted, cacTimesheet, linSignature, dispute }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          minWidth: "10px",
          paddingLeft: "0px",
        }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => history.push(`/linguist/view-timesheet/${id}`)}
        >
          View Timesheet
        </MenuItem>
        {linSignature && !cacTimesheet && (
          <MenuItem
            onClick={() => history.push(`/linguist/amend-timesheet/${id}`)}
          >
            Amend Timesheet
          </MenuItem>
        )}

        {(accepted || date) && !dispute &&(
          <MenuItem
            onClick={() => history.push(`inhouse/interpreter/invoice/${id}`)}
          >
            Create Invoice
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default ActionINC;
