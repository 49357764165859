import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

//Material icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

// import Details from './Details'
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Segment,
  Statistic,
  Table,
} from "semantic-ui-react";
import { CURRENCY, DISCOUNT, TAXIFARE, TYPE_INTERPRETING } from "data";
import { IconButton, Input, TextField } from "@material-ui/core";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import swal from "sweetalert";
import {
  addOtherTransaction,
  addTransaction,
  deleteOtherTransaction,
  deleteTransaction,
} from "redux/actions/expenseAction";
import CustomSemanticDD from "components/Common/CustomSemanticDD";

const InvoiceINC = ({ id }) => {
  const classes = useEmployeeStyles();
  const [count] = useState([0]);
  const history = useHistory();
  const token = Cookies.get("LINtoken");
  const [invoice, setInvoice] = useState(null);
  const [linguist, setLinguist] = useState(null);
  const [text, setText] = useState("");
  const [amount, setAmount] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(null);
  const [rate, setRate] = useState(null);
  const [interpretingRate, setInterpretingRate] = useState(1);
  const [estimateRate, setEstimateRate] = useState(null);
  const [calculate, setCalculate] = useState({
    sendButton: false,
    loading: false,
    quoteId: "",
  });

  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
  });

  const totalFTF = invoice
    ? Number(invoice[0]) * Number(invoice?.duration) * interpretingRate
    : null;

  //   2nd subtotal
  const [otherAmount, setOtherAmount] = useState(null);
  const [otherText, setOtherText] = useState("");
  const [otherQuantity, setOtherQuantity] = useState(null);

  const { transactions } = useSelector((state) => state.expenseReducer);
  const amountsOne = transactions.map((transaction) => transaction.total);
  const subTotalOne = Number(
    amountsOne.reduce((acc, item) => (acc += item), 0)
  ).toFixed(2); //+ Number(totalFTF)

  const { otherTransactions } = useSelector((state) => state.expenseReducer);
  const amountsTwo = otherTransactions.map((transaction) => transaction.total);
  const subTotalTwo = amountsTwo
    .reduce((acc, item) => (acc += item), 0)
    .toFixed(2);

  const total = (Number(subTotalOne) + Number(subTotalTwo)).toFixed(2);

  const [discount, setDiscount] = useState(0);
  const [vat, setVAT] = useState(0);

  //Total after discount
  const totalDiscount = ((Number(total) * discount) / 100).toFixed(2);

  //Including platform and booking service charge
  const totalPBSC = ((Number(total) * companyCard?.pbsc) / 100).toFixed(2);
  const totalBASC = ((Number(total) * companyCard?.basc) / 100).toFixed(2);

  //Total VAT
  const totalVAT = invoice?.linQuoteID?.bacQuote
    ? (
        Number(
          Number(total) +
            Number(totalBASC) +
            Number(totalPBSC) -
            Number(totalDiscount)
        ) *
        (vat / 100)
      ).toFixed(2)
    : (
        Number(Number(total) + Number(totalPBSC) - Number(totalDiscount)) *
        (vat / 100)
      ).toFixed(2);

  //Total after including vat and discount
  const totalTwo = invoice?.linQuoteID?.bacQuote
    ? (
        Number(total) +
        Number(totalBASC) +
        Number(totalPBSC) -
        Number(totalDiscount) +
        Number(totalVAT)
      ).toFixed(2)
    : (
        Number(total) +
        Number(totalPBSC) -
        Number(totalDiscount) +
        Number(totalVAT)
      ).toFixed(2);

  const estimatePaidAdvance =
    invoice &&
    (invoice?.linQuoteID?.estimate_paid_amount
      ? invoice?.linQuoteID?.estimate_paid_amount
      : invoice?.linQuoteID?.grand_total);

  //Total after subtracting estimate paid
  const grandTotal = totalTwo - rate;

  //To be paid including vat

  const paidBy = moment().add(14, "days").format("DD/MMM/YYYY");

  const [lingRef, setLingRef] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleAdd = (e) => {
    e.preventDefault();

    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      text,
      amount: +amount,
      quantity,
      total: amount * quantity,
    };

    dispatch(addTransaction(newTransaction));
    setText("");
    setAmount("");
    setQuantity("");
  };

  const handleAddOther = (e) => {
    e.preventDefault();
    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      otherText,
      otherAmount: +otherAmount,
      otherQuantity,
      total: otherAmount * otherQuantity,
    };

    dispatch(addOtherTransaction(newTransaction));
    setOtherText("");
    setOtherAmount("");
    setOtherQuantity("");
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const loadInvoice = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-project-report/reportID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setInvoice(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          throw error;
        }
      }
    };

    const getLinguistProfile = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLinguist(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getCompanyCard = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/view/company-information-card`
        );

        setCompanyCard({
          ...companyCard,
          basc: response?.data[0].getCompanyCard,
          pbsc: response?.data[0].platform_booking_service_charge,
          basc: response?.data[0].billing_account_service_charge,
          cname: response?.data[0].company_name,
          caddress: response?.data[0].company_address,
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadInvoice();
    getLinguistProfile();
    getCompanyCard();

    return () => {
      source.cancel();
    };
  }, []);

  const handleCalculateCAC = async () => {
    setCalculate({ ...calculate, loading: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/create-quote-pr-invoice/cashclient/projectID/${id}`,
        {
          date_of_quote: invoice?.linQuoteID?.date_of_quote,
          date_of_invoice: moment().format("DD/MMM/YYYY"),
          service_completion_date: moment(invoice?.end_date).format(
            "DD/MMM/YYYY"
          ),
          linguist_ref: lingRef,
          currency: currency ? currency : "GBP (UK)",
          transactions: transactions.concat([
            {
              text: invoice?.linQuoteID?.interpreting_service_required,
              amount: invoice[0],
              quantity: invoice?.duration,
              total: totalFTF,
            },
          ]),
          otherTransactions: otherTransactions,
          subtotal1: subTotalOne,
          subtotal2: subTotalTwo,
          total: totalTwo,
          service_charge: companyCard?.pbsc,
          service_charge_amount: totalPBSC,
          discount_percentage: discount,
          discount_amount: totalDiscount,
          vat_percentage: vat,
          vat_amount: totalVAT,
          grand_total: grandTotal.toFixed(2),
          estimate_paid_amount: rate,
          // estimate_paid_amount: invoice?.linQuoteID?.estimate_paid_amount ? invoice?.linQuoteID?.estimate_paid_amount : invoice?.linQuoteID?.grand_total,
          linguist_note: "",
          admin_note: "",
          client_note: "",
          rate: invoice[0],
          company_name: companyCard?.cname,
          company_address: companyCard?.caddress,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Invoice successfully saved",
        icon: "success",
        button: "Ok",
      });
      setLoading(false);
      setCalculate({
        ...calculate,
        loading: false,
        sendButton: true,
        quoteId: response?.data._id,
      });
    } catch (error) {
      console.log(error);
      setCalculate({ ...calculate, loading: false, sendButton: false });
    }
  };

  const handleSendCAC = async () => {
    setLoading(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/send-quote-pr-invoice/cashclient/invoiceID/${calculate.quoteId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Invoice successfully send to client",
        icon: "success",
        button: "Ok",
      }).then(function () {
        window.location = "/linguist/dashboard";
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalculateBAC = async () => {
    setCalculate({ ...calculate, loading: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/create-quote-pr-invoice/accountclient/projectID/${id}`,
        {
          date_of_quote: invoice?.linQuoteID?.date_of_quote,
          date_of_invoice: moment().format("DD/MMM/YYYY"),
          service_completion_date: invoice?.end_date
            ? moment(invoice?.end_date).format("DD/MMM/YYYY")
            : "",
          linguist_ref: lingRef,
          currency: currency,
          transactions: transactions.concat([
            {
              text: invoice?.linQuoteID?.interpreting_service_required,
              amount: invoice[0],
              quantity: invoice?.duration,
              total: totalFTF,
            },
          ]),
          otherTransactions: otherTransactions,
          subtotal1: subTotalOne,
          subtotal2: subTotalTwo,
          total: totalTwo,
          service_charge: companyCard?.pbsc,
          bac_service_charge: companyCard?.basc,
          service_charge_amount: totalPBSC,
          bac_service_charge_amount: totalBASC,
          discount_percentage: discount,
          discount_amount: totalDiscount,
          vat_percentage: vat,
          vat_amount: totalVAT,
          grand_total: invoice?.linQuoteID?.monthlyBill
            ? totalTwo
            : grandTotal.toFixed(2),
          estimate_paid_amount: invoice?.linQuoteID?.monthlyBill
            ? "00.00"
            : rate,
          // estimate_paid_amount: invoice?.linQuoteID?.estimate_paid_amount ? invoice?.linQuoteID?.estimate_paid_amount : invoice?.linQuoteID?.grand_total,
          linguist_note: "",
          admin_note: "",
          client_note: "",
          rate: invoice[0],
          company_name: companyCard?.cname,
          company_address: companyCard?.caddress,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Invoice successfully saved",
        icon: "success",
        button: "Ok",
      });
      setLoading(false);
      setCalculate({
        ...calculate,
        loading: false,
        sendButton: true,
        quoteId: response?.data._id,
      });
    } catch (error) {
      console.log(error);
      setCalculate({ ...calculate, loading: false, sendButton: false });
    }
  };

  const handleSendBAC = async () => {
    setLoading(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/send-quote-pr-invoice/accountclient/invoiceID/${calculate.quoteId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Invoice successfully send to client",
        icon: "success",
        button: "Ok",
      }).then(function () {
        window.location = "/linguist/dashboard";
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCurrency = async (e) => {
    const { value } = e.target;
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
      );

      if (value === "GBP (UK)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.GBP) /
            response?.data?.rates[
              invoice?.linQuoteID?.currency?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "EUR (Euro countries)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.EUR) /
            response?.data?.rates[
              invoice?.linQuoteID?.currency?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "AUD (Australia)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.AUD) /
            response?.data?.rates[
              invoice?.linQuoteID?.currency?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "USD (USA, paying from USA only)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.USD) /
            response?.data?.rates[
              invoice?.linQuoteID?.currency?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "CAD (Canada)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.CAD) /
            response?.data?.rates[
              invoice?.linQuoteID?.currency?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "TRY (Turkish lira, payment from Turkey only)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.TRY) /
            response?.data?.rates[
              invoice?.linQuoteID?.currency?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "SGD (Singapore dollar)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.SGD) /
            response?.data?.rates[
              invoice?.linQuoteID?.currency?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "HUF (Hungarian forint)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.HUF) /
            response?.data?.rates[
              invoice?.linQuoteID?.currency?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (
        value === "NZD (New Zealand dollar, paying from New Zealand only)"
      ) {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.NZD) /
            response?.data?.rates[
              invoice?.linQuoteID?.currency?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      } else if (value === "RON (Romanian leu, paying from Romania only)") {
        setRate(
          (
            (estimatePaidAdvance * response?.data?.rates.RON) /
            response?.data?.rates[
              invoice?.linQuoteID?.currency?.substring(0, 3)
            ]
          ).toFixed(2)
        );
      }
      setCurrency(value);
    } catch (error) {
      console.log(error);
    }
  };

  return invoice && linguist ? (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem id="printArea" xs={12} sm={12} md={12} lg={8} xl={8}>
          <Card>
            <CardBody>
              {/* Avatar and company name */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "24px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <CustomAvatar style={{ margin: "0" }} />
                </div>
              </div>

              {/* First div  */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice to:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.linQuoteID?.quoteRequstByCashclient?.postedBy
                        ?.id
                        ? invoice?.linQuoteID?.quoteRequstByCashclient?.postedBy
                            ?.id
                        : invoice?.linQuoteID?.quoteRequstByAccountclient
                            ?.postedBy?.id
                        ? invoice?.linQuoteID?.quoteRequstByAccountclient
                            ?.postedBy?.id
                        : "N/A"}
                    </Header>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ID number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{linguist.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist Residence:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{linguist.Country}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      VAT/GST no:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{linguist.vat_no}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of quote:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;N/A
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of invoice:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{moment().format("DD/MMM/YYYY")}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice to be paid by:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{paidBy}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Job reference:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.linQuoteID?.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Service completion date:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.linQuoteID?.service_completion_date
                        ? moment(
                            invoice?.linQuoteID?.service_completion_date
                          ).format("DD/MMM/YYYY")
                        : "N/A"}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ref:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      <Input
                        onChange={(e) => setLingRef(e.target.value)}
                        value={lingRef}
                        type="number"
                      />
                    </Header>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Service:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.linQuoteID?.quoteRequstByCashclient
                      ?.quote_rqst_for
                      ? invoice?.linQuoteID?.quoteRequstByCashclient
                          ?.quote_rqst_for
                      : invoice?.linQuoteID?.quoteRequstByAccountclient
                          ?.quote_rqst_for
                      ? invoice?.linQuoteID?.quoteRequstByAccountclient
                          ?.quote_rqst_for
                      : "N/A"}
                  </Header>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Expertise required:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.linQuoteID?.quoteRequstByCashclient
                      ?.target_skills_required
                      ? invoice?.linQuoteID?.quoteRequstByCashclient
                          ?.target_skills_required
                      : invoice?.linQuoteID?.quoteRequstByAccountclient
                          ?.target_skills_required
                      ? invoice?.linQuoteID?.quoteRequstByAccountclient
                          ?.target_skills_required
                      : "N/A"}
                  </Header>
                </div>
              </div>

              <Segment size="small" textAlign="center" color="purple">
                <CustomSemanticDD
                  onChange={(e) => handleCurrency(e)}
                  value={currency}
                  label="Currency*"
                  data={CURRENCY}
                />
              </Segment>

              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="5">
                      Description of service
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Price {currency?.split("(")[0]} 00.00
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Quantity</Table.HeaderCell>
                    <Table.HeaderCell width="1">Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {transactions.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.text}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.amount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.quantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                        <IconButton
                          onClick={() => dispatch(deleteTransaction(tr.id))}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell>
                      <CustomAutoSelect
                        onChange={(e, value) => setText(value)}
                        value={text}
                        label="Choose"
                        data={TYPE_INTERPRETING}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                        placeholder={`${currency?.split("(")[0]}00.00`}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setQuantity(e.target.value)}
                        value={quantity}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <IconButton onClick={handleAdd}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header textAlign="right" as="h3">
                        Sub Total
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {Number(subTotalOne)}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Expenses and travel</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  {otherTransactions.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.otherText}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherAmount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherQuantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                        <IconButton
                          onClick={() =>
                            dispatch(deleteOtherTransaction(tr.id))
                          }
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell>
                      <CustomAutoSelect
                        onChange={(e, value) => setOtherText(value)}
                        value={otherText}
                        label="Taxi fare"
                        data={TAXIFARE}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setOtherAmount(e.target.value)}
                        value={otherAmount}
                        placeholder={`${currency?.split("(")[0]}00.00`}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        onChange={(e) => setOtherQuantity(e.target.value)}
                        value={otherQuantity}
                        style={{ margin: "24px auto 0" }}
                        type="number"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <IconButton onClick={handleAddOther}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h3">Sub Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {subTotalTwo}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {" "}
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {total}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Discount {invoice?.linQuoteID?.discount_percentage}%
                      </Header>
                      <CustomAutoSelect
                        data={DISCOUNT}
                        onChange={(e, value) => setDiscount(value)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalDiscount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Billing Account Service Charge {companyCard?.basc} %
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      {invoice?.linQuoteID?.quoteRequstByCashclient ? (
                        <Statistic size="mini">
                          <Statistic.Value>00.00</Statistic.Value>
                        </Statistic>
                      ) : (
                        <Statistic size="mini">
                          <Statistic.Value>
                            {currency?.split("(")[0]
                              ? currency?.split("(")[0]
                              : "GBP"}{" "}
                            {totalBASC}
                          </Statistic.Value>
                        </Statistic>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Platform and booking service charge {companyCard?.pbsc}{" "}
                        %
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalPBSC}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        VAT / GST ({invoice?.linQuoteID?.vat_percentage}%)
                      </Header>
                      <TextField
                        onChange={(e) => setVAT(e.target.value)}
                        type="number"
                        inputProps={{ min: "0", max: "10", step: "1" }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalVAT}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {currency?.split("(")[0]
                            ? currency?.split("(")[0]
                            : "GBP"}{" "}
                          {totalTwo}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Estimate paid in advance</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        {invoice?.linQuoteID?.monthlyBill ? (
                          <Statistic.Value>00.00</Statistic.Value>
                        ) : (
                          <Statistic.Value>
                            {invoice?.linQuoteID?.currency?.substring(0, 3) +
                              " " +
                              estimatePaidAdvance}
                          </Statistic.Value>
                        )}
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Estimate paid in advance (converted)
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic color="purple" size="mini">
                        {invoice?.linQuoteID?.monthlyBill ? (
                          <Statistic.Value>00.00</Statistic.Value>
                        ) : (
                          <Statistic.Value>
                            {currency?.split("(")[0]
                              ? currency?.split("(")[0]
                              : "GBP"}{" "}
                            {rate}
                          </Statistic.Value>
                        )}
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Total to be paid including VAT/GST
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        {invoice?.linQuoteID?.monthlyBill ? (
                          <Statistic.Value>
                            {currency?.split("(")[0]
                              ? currency?.split("(")[0]
                              : "GBP"}{" "}
                            {totalTwo}
                          </Statistic.Value>
                        ) : (
                          <Statistic.Value>
                            {currency?.split("(")[0]
                              ? currency?.split("(")[0]
                              : "GBP"}{" "}
                            {grandTotal.toFixed(2)}
                          </Statistic.Value>
                        )}
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              {invoice?.linQuoteID?.quoteRequstByCashclient ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Header style={{ marginTop: "16px" }} as="h4">
                    Thank You For Your Business
                  </Header>
                  <Header
                    color="grey"
                    style={{ marginBottom: "16px", marginTop: "0" }}
                    as="h4"
                  >
                    {companyCard?.cname}, {companyCard?.caddress}
                  </Header>

                  {!calculate.sendButton && (
                    <Button
                      onClick={handleCalculateCAC}
                      loading={calculate.loading}
                      color="purple"
                      disabled={!currency}
                    >
                      Calculate and Save
                    </Button>
                  )}
                  {calculate.sendButton && (
                    <Button
                      onClick={handleSendCAC}
                      loading={loading}
                      color="purple"
                    >
                      Send
                    </Button>
                  )}
                  {grandTotal.toFixed(2) <= 0 ? (
                    <p style={{ color: "red" }}>
                      Negative value!! Please make necessary arrangement for
                      refunding the amount shown (-) in 'Total to be paid
                      including VAT/GST'.
                    </p>
                  ) : null}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Header style={{ marginTop: "16px" }} as="h4">
                    Thank You For Your Business
                  </Header>
                  <Header
                    color="grey"
                    style={{ marginBottom: "16px", marginTop: "0" }}
                    as="h4"
                  >
                    Linguists Collective Ltd, 38 Mill Street, Bedford,
                    Bedfordshire, MK40 3HD
                  </Header>

                  {grandTotal.toFixed(2) <= 0 ? (
                    <p style={{ color: "red" }}>
                      Negative value!! Please make necessary arrangement for
                      refunding the amount shown (-) in 'Total to be paid
                      including VAT/GST'.
                    </p>
                  ) : null}

                  {!calculate.sendButton && (
                    <Button
                      onClick={handleCalculateBAC}
                      loading={calculate.loading}
                      color="purple"
                      disabled={!currency}
                    >
                      Calculate and Save
                    </Button>
                  )}
                  {calculate.sendButton && (
                    <Button
                      onClick={handleSendBAC}
                      loading={loading}
                      color="purple"
                    >
                      Send
                    </Button>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default InvoiceINC;
