import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import CashClientLayout from "layout/CashClientLayout";
// Custom Components

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LinguistProfile from "components/PublicProfileLIN/ProtectedPublicProfile/PPL";

const ManagePublicProfile = () => {
  const classes = useDashboardStyles();

  return (
    <CashClientLayout>
      <Cashclient>

        <LinguistProfile />

      </Cashclient>
    </CashClientLayout>
  );
};

export default ManagePublicProfile;
