import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
// Other
import { changePreRegInput } from "redux/actions/preregAction";
import Axios from "axios";
import swal from "sweetalert";
import CustomSelect from "components/Common/CustomSelect";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "50%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media (max-width:768px)"]: {
      width: "100%",
      padding: 0,
      display: "block",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const FormOne = ({ ticketID }) => {
  const classes = useFormStyles();
  const { data } = useSelector((state) => state.preregReducer);
  const dispatch = useDispatch();
  const [cacDetails, setCACDetails] = useState();
  const [bacDetails, setBACDetails] = useState();
  const [linDetails, setLINDetails] = useState();
  const [sppoDetails, setSPPODetails] = useState();
  const [empDetails, setEMPDetails] = useState();
  const [admDetails, setADMDetails] = useState();
  const { id } = useParams();

  const { invited_id, client } = data;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changePreRegInput(inputData));
  };

  const FindCACDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-CAC`,
        {
          invited_id: invited_id,
        }
      );

      setCACDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindBACDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-BAC`,
        {
          invited_id: invited_id,
        }
      );

      setBACDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindLINDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-LIN`,
        {
          invited_id: invited_id,
        }
      );

      setLINDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindSPPODetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-SPPO`,
        {
          invited_id: invited_id,
        }
      );

      setSPPODetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindEMPDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-EMP`,
        {
          invited_id: invited_id,
        }
      );

      setEMPDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindADMDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-ADM`,
        {
          invited_id: invited_id,
        }
      );

      setADMDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    FindCACDetails();
    FindBACDetails();
    FindLINDetails();
    FindSPPODetails();
    FindEMPDetails();
    FindADMDetails();
  }, []);

  const handleSubmit = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/send-invitation/ticketID/${ticketID}`,
        {
          invited_id: invited_id,
        }
      );

      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error.response?.data}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleSend = async (value) => {
    swal("Are you sure to send invitation?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          handleSubmit(value);

        default:
          break;
      }
    });
  };

  return (
    <form className={` form ${classes.form}`} noValidate autoComplete="off">
      <CustomSelect
        onChange={handleChange}
        label="Select Respondent Category"
        data={["CAC", "BAC", "LIN", "SPPO", "EMP", "MAN"]}
        width="50%"
        name="client"
        value={client}
        required
      />

      {client == "CAC" ? (
        <>
          <CustomInputSingle
            onChange={handleChange}
            value={invited_id}
            name="invited_id"
            label="Enter CAC Client Reference Number"
            width="50%"
            required
            placeholder="CAC..."
          />
          <div className={classes.buttonFlex}>
            <CustomButton
              onClick={FindCACDetails}
              disabled={!invited_id}
              text="Find CAC"
            />
          </div>
          <br />
          <div className={classes.buttonFlex}>
            {cacDetails ? (
              <>
                CAC ID: {cacDetails?.id}
                <br /> <br />
                Full Name: {cacDetails?.title} {cacDetails?.FirstName}{" "}
                {cacDetails?.LastName}
                <br />
                Mobile No: {cacDetails?.mobile_no}
                <br />
                Post / Zip Code: {cacDetails?.PostalCode}
              </>
            ) : (
              <p style={{ textAlign: "center", color: "red" }}>No Data Found</p>
            )}
          </div>
        </>
      ) : null}

      {client == "BAC" ? (
        <>
          <CustomInputSingle
            onChange={handleChange}
            value={invited_id}
            name="invited_id"
            label="Enter BAC Client Reference Number"
            width="50%"
            required
            placeholder="BAC..."
          />
          <div className={classes.buttonFlex}>
            <CustomButton
              onClick={FindBACDetails}
              disabled={!invited_id}
              text="Find BAC"
            />
          </div>
          <br />
          <div className={classes.buttonFlex}>
            {bacDetails ? (
              <>
                BAC ID: {bacDetails?.id}
                <br /> <br />
                Full Name: {bacDetails?.title} {bacDetails?.FirstName}{" "}
                {bacDetails?.LastName}
                <br />
                Mobile No: {bacDetails?.mobile_no}
                <br />
                Post / Zip Code: {bacDetails?.PostalCode}
              </>
            ) : (
              <p style={{ textAlign: "center", color: "red" }}>No Data Found</p>
            )}
          </div>
        </>
      ) : null}

      {client == "LIN" ? (
        <>
          <CustomInputSingle
            onChange={handleChange}
            value={invited_id}
            name="invited_id"
            label="Enter LIN Client Reference Number"
            width="50%"
            required
            placeholder="LIN/PRO..."
          />
          <div className={classes.buttonFlex}>
            <CustomButton
              onClick={FindLINDetails}
              disabled={!invited_id}
              text="Find Linguist"
            />
          </div>
          <br />
          <div className={classes.buttonFlex}>
            {linDetails ? (
              <>
                LIN ID: {linDetails?.id}
                <br /> <br />
                Full Name: {linDetails?.title} {linDetails?.FirstName}{" "}
                {linDetails?.LastName}
                <br />
                Mobile No: {linDetails?.mobile_no}
                <br />
                Post / Zip Code: {linDetails?.PostalCode}
                <br /> <br />
                Source Language(s): {linDetails?.source_language} |{" "}
                {linDetails?.source_language_1} |{" "}
                {linDetails?.source_language_2}
                <br />
                Target Language(s): {linDetails?.target_language} |{" "}
                {linDetails?.target_language_1} |{" "}
                {linDetails?.target_language_2}
              </>
            ) : (
              <p style={{ textAlign: "center", color: "red" }}>No Data Found</p>
            )}
          </div>
        </>
      ) : null}

      {client == "SPPO" ? (
        <>
          <CustomInputSingle
            onChange={handleChange}
            value={invited_id}
            name="invited_id"
            label="Enter SPPO Client Reference Number"
            width="50%"
            required
            placeholder="SPPO..."
          />
          <div className={classes.buttonFlex}>
            <CustomButton
              onClick={FindSPPODetails}
              disabled={!invited_id}
              text="Find SPPO"
            />
          </div>
          <br />
          <div className={classes.buttonFlex}>
            {sppoDetails ? (
              <>
                SPPO ID: {sppoDetails?.id}
                <br /> <br />
                Full Name: {sppoDetails?.title} {sppoDetails?.FirstName}{" "}
                {sppoDetails?.LastName}
                <br />
                Mobile No: {sppoDetails?.mobile_no}
                <br />
                Post / Zip Code: {sppoDetails?.PostalCode}
              </>
            ) : (
              <p style={{ textAlign: "center", color: "red" }}>No Data Found</p>
            )}
          </div>
        </>
      ) : null}

      {client == "MAN" ? (
        <>
          <CustomInputSingle
            onChange={handleChange}
            value={invited_id}
            name="invited_id"
            label="Enter MAN Client Reference Number"
            width="50%"
            required
            placeholder="ADM..."
          />
          <div className={classes.buttonFlex}>
            <CustomButton
              onClick={FindADMDetails}
              disabled={!invited_id}
              text="Find Admin"
            />
          </div>
          <br />
          <div className={classes.buttonFlex}>
            {admDetails ? (
              <>
                Admin ID: {admDetails?.id}
                <br /> <br />
                Full Name: {admDetails?.title} {admDetails?.FirstName}{" "}
                {admDetails?.LastName}
                <br />
                Mobile No: {admDetails?.mobile_no}
                <br />
                Post / Zip Code: {admDetails?.PostalCode}
              </>
            ) : (
              <p style={{ textAlign: "center", color: "red" }}>No Data Found</p>
            )}
          </div>
        </>
      ) : null}

      {client == "EMP" ? (
        <>
          <CustomInputSingle
            onChange={handleChange}
            value={invited_id}
            name="invited_id"
            label="Enter EMP Client Reference Number"
            width="50%"
            required
            placeholder="EMP..."
          />
          <div className={classes.buttonFlex}>
            <CustomButton
              onClick={FindEMPDetails}
              disabled={!invited_id}
              text="Find Employee"
            />
          </div>
          <br />
          <div className={classes.buttonFlex}>
            {empDetails ? (
              <>
                EMP ID: {empDetails?.id}
                <br /> <br />
                Full Name: {empDetails?.title} {empDetails?.FirstName}{" "}
                {empDetails?.LastName}
                <br />
                Mobile No: {empDetails?.mobile_no}
                <br />
                Post / Zip Code: {empDetails?.PostalCode}
              </>
            ) : (
              <p style={{ textAlign: "center", color: "red" }}>No Data Found</p>
            )}
          </div>
        </>
      ) : null}

      <div className={classes.buttonFlex}>
        <CustomButton onClick={handleSend} text="Confirm" />
      </div>
    </form>
  );
};

export default FormOne;
