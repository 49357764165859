import { useState } from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Invoices from "./Invoices/Invoices";
import TotalJobs from "./TotalJobs/TotalJobs";
import ReportFilter from "./ReportFilter";
import CACReportCards from "./CACReportCards";
import BACReportCards from "./BACReportCards";
import INCReportCards from "./INCReportCards";
import PRCReportCards from "./PRCReportCards";

const Report = () => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        {window.location.href.includes("cac") && <CACReportCards />}
        {window.location.href.includes("bac") && <BACReportCards />}
        {window.location.href.includes("inc") && <INCReportCards />}
        {window.location.href.includes("prc") && <PRCReportCards />}
      </GridItem>
    </GridContainer>
  );
};

export default Report;
