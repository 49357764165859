import React, { useState, useEffect } from "react";
import Employee from "components/RouteProtection/Employee/Employee";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import EmployeeLayout from "layout/EmployeeLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {
  Header,
  Icon,
  Button,
} from "semantic-ui-react";
import Box from "@material-ui/core/Box";
import axios from "utils/axios";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";



const Help = () => {
  const classes = useDashboardStyles();
  const matches = useMediaQuery("(max-width:768px)");
  const [values, setValues] = useState({
    toturialList: [],
  });
  const loadToturialsList = async () => {
    try {
      const response = await axios.get(
        "/api/ADM-EMP/get-tutorial"
      );
      setValues({ ...values, toturialList: response?.data });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadToturialsList();
  }, []);

  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Box pb={2}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}> Tutorials</p>
                </CardHeader>

                <CardBody>
                  <CardHeader
                    style={{ padding: 20, margin: 20 }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Employee Tutorials</p>
                  </CardHeader>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: matches ? "auto" : "30% 30% 30%",
                      justifyContent: matches ? null : "space-evenly",
                      alignItems: "end",
                    }}
                  >
                    {values.toturialList.map((list) =>
                      list.empTutorial === "yes" ? (
                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <Header as="h4" textAlign="center">
                            {list.tutorial_name}
                          </Header>
                          <Button
                            onClick={() => window.open(list.tutorial_link)}
                            fluid
                            color="youtube"
                          >
                            <Icon name="youtube" /> Watch on youtube
                          </Button>
                        </div>
                      ) : null
                    )}
                  </div>
                </CardBody>
                <CardBody>
                  <CardHeader
                    style={{ padding: 20, margin: 20 }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Linguist Tutorials</p>
                  </CardHeader>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: matches ? "auto" : "30% 30% 30%",
                      justifyContent: matches ? null : "space-evenly",
                      alignItems: "end",
                    }}
                  >
                    {values.toturialList.map((list) =>
                      list.linTutorial === "yes" ? (
                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <Header as="h4" textAlign="center">
                            {list.tutorial_name}
                          </Header>
                          <Button
                            onClick={() => window.open(list.tutorial_link)}
                            fluid
                            color="youtube"
                          >
                            <Icon name="youtube" /> Watch on youtube
                          </Button>
                        </div>
                      ) : null
                    )}
                  </div>
                </CardBody>
                <CardBody>
                  <CardHeader
                    style={{ padding: 20, margin: 20 }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Sppo Tutorials</p>
                  </CardHeader>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: matches ? "auto" : "30% 30% 30%",
                      justifyContent: matches ? null : "space-evenly",
                      alignItems: "end",
                    }}
                  >
                    {values.toturialList.map((list) =>
                      list.sppoTutorial === "yes" ? (
                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <Header as="h4" textAlign="center">
                            {list.tutorial_name}
                          </Header>
                          <Button
                            onClick={() => window.open(list.tutorial_link)}
                            fluid
                            color="youtube"
                          >
                            <Icon name="youtube" /> Watch on youtube
                          </Button>
                        </div>
                      ) : null
                    )}
                  </div>
                </CardBody>

                <CardBody>
                  <CardHeader
                    style={{ padding: 20, margin: 20 }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>CAC Tutorials</p>
                  </CardHeader>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: matches ? "auto" : "30% 30% 30%",
                      justifyContent: matches ? null : "space-evenly",
                      alignItems: "end",
                    }}
                  >
                    {values.toturialList.map((list) =>
                      list.cacTutorial === "yes" ? (
                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <Header as="h4" textAlign="center">
                            {list.tutorial_name}
                          </Header>
                          <Button
                            onClick={() => window.open(list.tutorial_link)}
                            fluid
                            color="youtube"
                          >
                            <Icon name="youtube" /> Watch on youtube
                          </Button>
                        </div>
                      ) : null
                    )}
                  </div>
                </CardBody>

                <CardBody>
                  <CardHeader
                    style={{ padding: 20, margin: 20 }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>BAC Tutorials</p>
                  </CardHeader>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: matches ? "auto" : "30% 30% 30%",
                      justifyContent: matches ? null : "space-evenly",
                      alignItems: "end",
                    }}
                  >
                    {values.toturialList.map((list) =>
                      list.bacTutorial === "yes" ? (
                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <Header as="h4" textAlign="center">
                            {list.tutorial_name}
                          </Header>
                          <Button
                            onClick={() => window.open(list.tutorial_link)}
                            fluid
                            color="youtube"
                          >
                            <Icon name="youtube" /> Watch on youtube
                          </Button>
                        </div>
                      ) : null
                    )}
                  </div>
                </CardBody>
              </Card>
            </Box>
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default Help;
