import React, { Fragment, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { orderBy } from "lodash";

// Custom Components
import CustomtableWIthStyle from "components/Common/CustomtableWIthStyle";
import RateInput from "./RateInput";

// Others
import { getRates } from "redux/actions/admin/controller";
import Details from "./Details";

const RateCard = ({ readOnly }) => {
  console.clear();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.adminControlData);
  const { rates, selectedCurrency, setCurrencyValue } = useSelector(
    (state) => state.getDataReducer
  );

  const convertedRates = rates ? rates[selectedCurrency] : 1;

  useEffect(() => {
    dispatch(getRates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rateField = (value, item, name) => (
    <Fragment>
      <RateInput
        value={value || undefined}
        item={item}
        name={name}
        readOnly={readOnly}
      />
    </Fragment>
  );

  const additionalField = (value, item, name) => (
    <Fragment>
      <RateInput
        value={value || undefined}
        item={item}
        name={name}
        readOnly={readOnly}
      />
    </Fragment>
  );

  const headers = [
    {
      id: uuidv4(),
      text: "Item /Linguists level",
      align: "left",
    },
    {
      id: uuidv4(),
      text: "Level 1",
    },
    {
      id: uuidv4(),
      text: "Level 2",
    },
    {
      id: uuidv4(),
      text: "Level 3",
    },
    {
      id: uuidv4(),
      text: "Level 4",
    },
    {
      id: uuidv4(),
      text: "Level 4R",
    },
    {
      id: uuidv4(),
      text: "Level 5",
    },
  ];

  const rowsKeys = [
    "column_1",
    "column_2",
    "column_3",
    "column_4",
    "column_5",
    "column_6",
    "column_7",
  ];

  let rows = data?.map((item) => {
    if ([1, 9, 15, 16].includes(+item.order)) {
      return {
        id: item._id,
        order: item.order,
        column_1: item.labelName,
        column_2: rateField(
          `${setCurrencyValue} ${(item.labelOne * convertedRates).toFixed(2)}${
            String(item.labelOne).length === 2 ? "" : ""
          }`,
          item,
          "labelOne"
        ),
        column_3: rateField(
          `${setCurrencyValue} ${(item.labelTwo * convertedRates).toFixed(2)}${
            String(item.labelTwo).length === 2 ? "" : ""
          }`,
          item,
          "labelTwo"
        ),
        column_4: rateField(
          `${setCurrencyValue} ${(item.labelThree * convertedRates).toFixed(2)}${
            String(item.labelThree).length === 2 ? "" : ""
          }`,
          item,
          "labelThree"
        ),
        column_5: rateField(
          `${setCurrencyValue} ${(item.labelFour * convertedRates).toFixed(2)}${
            String(item.labelFour).length === 2 ? "" : ""
          }`,
          item,
          "labelFour"
        ),
        column_6: rateField(
          `${setCurrencyValue} ${(item.labelFourR * convertedRates).toFixed(2)}${
            String(item.labelFourR).length === 2 ? "" : ""
          }`,
          item,
          "labelFourR"
        ),
        column_7: rateField(
          `${setCurrencyValue} ${(item.labelFive * convertedRates).toFixed(2)}${
            String(item.labelFive).length === 2 ? "" : ""
          }`,
          item,
          "labelFive"
        ),
      };
    } else if ([2, 3, 5, 6].includes(+item.order)) {
      return {
        id: item._id,
        order: item.order,
        column_1: item.labelName,
        column_2: rateField(
          `${setCurrencyValue} ${(item.labelOne * convertedRates).toFixed(2)}${
            String(item.labelOne).length === 2 ? "" : ""
          } /word`,
          item,
          "labelOne"
        ),
        column_3: rateField(
          `${setCurrencyValue} ${(item.labelTwo * convertedRates).toFixed(2)}${
            String(item.labelTwo).length === 2 ? "" : ""
          } /word`,
          item,
          "labelTwo"
        ),
        column_4: rateField(
          `${setCurrencyValue} ${(item.labelThree * convertedRates).toFixed(2)}${
            String(item.labelThree).length === 2 ? "" : ""
          } /word`,
          item,
          "labelThree"
        ),
        column_5: rateField(
          `${setCurrencyValue} ${(item.labelFour * convertedRates).toFixed(2)}${
            String(item.labelFour).length === 2 ? "" : ""
          } /word`,
          item,
          "labelFour"
        ),
        column_6: rateField(
          `${setCurrencyValue} ${(item.labelFourR * convertedRates).toFixed(2)}${
            String(item.labelFourR).length === 2 ? "" : ""
          } /word`,
          item,
          "labelFourR"
        ),
        column_7: rateField(
          `${setCurrencyValue} ${(item.labelFive * convertedRates).toFixed(2)}${
            String(item.labelFive).length === 2 ? "" : ""
          } /word`,
          item,
          "labelFive"
        ),
      };
    } else if (item.order === 30) {
      return {
        id: item._id,
        order: item.order,
        column_1: item.labelName,
        column_2: rateField(
          `${setCurrencyValue} ${(item.labelOne * convertedRates).toFixed(2)}${
            String(item.labelOne).length === 2 ? "" : ""
          } /word+`,
          item,
          "labelOne"
        ),
        column_3: rateField(
          `${setCurrencyValue} ${(item.labelTwo * convertedRates).toFixed(2)}${
            String(item.labelTwo).length === 2 ? "" : ""
          } /word+`,
          item,
          "labelTwo"
        ),
        column_4: rateField(
          `${setCurrencyValue} ${(item.labelThree * convertedRates).toFixed(2)}${
            String(item.labelThree).length === 2 ? "" : ""
          } /word+`,
          item,
          "labelThree"
        ),
        column_5: rateField(
          `${setCurrencyValue} ${(item.labelFour * convertedRates).toFixed(2)}${
            String(item.labelFour).length === 2 ? "" : ""
          } /word+`,
          item,
          "labelFour"
        ),
        column_6: rateField(
          `${setCurrencyValue} ${(item.labelFourR * convertedRates).toFixed(2)}${
            String(item.labelFourR).length === 2 ? "" : ""
          } /word+`,
          item,
          "labelFourR"
        ),
        column_7: rateField(
          `${setCurrencyValue} ${(item.labelFive * convertedRates).toFixed(2)}${
            String(item.labelFive).length === 2 ? "" : ""
          } /word+`,
          item,
          "labelFive"
        ),
      };
    } else if (item.order === 4) {
      return {
        id: item._id,
        order: item.order,
        column_1: item.labelName,
        column_2: rateField(
          `${setCurrencyValue} ${(item.labelOne * convertedRates).toFixed(2)}${
            String(item.labelOne).length === 2 ? "" : ""
          } /page`,
          item,
          "labelOne"
        ),
        column_3: rateField(
          `${setCurrencyValue} ${(item.labelTwo * convertedRates).toFixed(2)}${
            String(item.labelTwo).length === 2 ? "" : ""
          } /page`,
          item,
          "labelTwo"
        ),
        column_4: rateField(
          `${setCurrencyValue} ${(item.labelThree * convertedRates).toFixed(2)}${
            String(item.labelThree).length === 2 ? "" : ""
          } /page`,
          item,
          "labelThree"
        ),
        column_5: rateField(
          `${setCurrencyValue} ${(item.labelFour * convertedRates).toFixed(2)}${
            String(item.labelFour).length === 2 ? "" : ""
          } /page`,
          item,
          "labelFour"
        ),
        column_6: rateField(
          `${setCurrencyValue} ${(item.labelFourR * convertedRates).toFixed(2)}${
            String(item.labelFourR).length === 2 ? "" : ""
          } /page`,
          item,
          "labelFourR"
        ),
        column_7: rateField(
          `${setCurrencyValue} ${(item.labelFive * convertedRates).toFixed(2)}${
            String(item.labelFive).length === 2 ? "" : ""
          } /page`,
          item,
          "labelFive"
        ),
      };
    } else if (item.order === 7) {
      return {
        id: item._id,
        order: item.order,
        column_1: item.labelName,
        column_2: rateField(
          `${setCurrencyValue} ${(item.labelOne * convertedRates).toFixed(2)}${
            String(item.labelOne).length === 2 ? "" : ""
          } /hour`,
          item,
          "labelOne"
        ),
        column_3: rateField(
          `${setCurrencyValue} ${(item.labelTwo * convertedRates).toFixed(2)}${
            String(item.labelTwo).length === 2 ? "" : ""
          } /hour`,
          item,
          "labelTwo"
        ),
        column_4: rateField(
          `${setCurrencyValue} ${(item.labelThree * convertedRates).toFixed(2)}${
            String(item.labelThree).length === 2 ? "" : ""
          } /hour`,
          item,
          "labelThree"
        ),
        column_5: rateField(
          `${setCurrencyValue} ${(item.labelFour * convertedRates).toFixed(2)}${
            String(item.labelFour).length === 2 ? "" : ""
          } /hour`,
          item,
          "labelFour"
        ),
        column_6: rateField(
          `${setCurrencyValue} ${(item.labelFourR * convertedRates).toFixed(2)}${
            String(item.labelFourR).length === 2 ? "" : ""
          } /hour`,
          item,
          "labelFourR"
        ),
        column_7: rateField(
          `${setCurrencyValue} ${(item.labelFive * convertedRates).toFixed(2)}${
            String(item.labelFive).length === 2 ? "" : ""
          } /hour`,
          item,
          "labelFive"
        ),
      };
    } else if (item.order === 8) {
      return {
        id: item._id,
        order: item.order,
        column_1: item.labelName,
        column_2: rateField(
          `${setCurrencyValue} ${(item.labelOne * convertedRates).toFixed(2)}${
            String(item.labelOne).length === 2 ? "" : ""
          } /mile`,
          item,
          "labelOne"
        ),
        column_3: rateField(
          `${setCurrencyValue} ${(item.labelTwo * convertedRates).toFixed(2)}${
            String(item.labelTwo).length === 2 ? "" : ""
          } /mile`,
          item,
          "labelTwo"
        ),
        column_4: rateField(
          `${setCurrencyValue} ${(item.labelThree * convertedRates).toFixed(2)}${
            String(item.labelThree).length === 2 ? "" : ""
          } /mile`,
          item,
          "labelThree"
        ),
        column_5: rateField(
          `${setCurrencyValue} ${(item.labelFour * convertedRates).toFixed(2)}${
            String(item.labelFour).length === 2 ? "" : ""
          } /mile`,
          item,
          "labelFour"
        ),
        column_6: rateField(
          `${setCurrencyValue} ${(item.labelFourR * convertedRates).toFixed(2)}${
            String(item.labelFourR).length === 2 ? "" : ""
          } /mile`,
          item,
          "labelFourR"
        ),
        column_7: rateField(
          `${setCurrencyValue} ${(item.labelFive * convertedRates).toFixed(2)}${
            String(item.labelFive).length === 2 ? "" : ""
          } /mile`,
          item,
          "labelFive"
        ),
      };
    } else if ([10, 11].includes(+item.order)) {
      return {
        id: item._id,
        order: item.order,
        column_1: item.labelName,
        column_2: rateField(
          `${setCurrencyValue} ${(item.labelOne * convertedRates).toFixed(2)}${
            String(item.labelOne).length === 2 ? "" : ""
          } /night`,
          item,
          "labelOne"
        ),
        column_3: rateField(
          `${setCurrencyValue} ${(item.labelTwo * convertedRates).toFixed(2)}${
            String(item.labelTwo).length === 2 ? "" : ""
          } /night`,
          item,
          "labelTwo"
        ),
        column_4: rateField(
          `${setCurrencyValue} ${(item.labelThree * convertedRates).toFixed(2)}${
            String(item.labelThree).length === 2 ? "" : ""
          } /night`,
          item,
          "labelThree"
        ),
        column_5: rateField(
          `${setCurrencyValue} ${(item.labelFour * convertedRates).toFixed(2)}${
            String(item.labelFour).length === 2 ? "" : ""
          } /night`,
          item,
          "labelFour"
        ),
        column_6: rateField(
          `${setCurrencyValue} ${(item.labelFourR * convertedRates).toFixed(2)}${
            String(item.labelFourR).length === 2 ? "" : ""
          } /night`,
          item,
          "labelFourR"
        ),
        column_7: rateField(
          `${setCurrencyValue} ${(item.labelFive * convertedRates).toFixed(2)}${
            String(item.labelFive).length === 2 ? "" : ""
          } /night`,
          item,
          "labelFive"
        ),
      };
    } else if ([12].includes(+item.order)) {
      return {
        id: item._id,
        order: item.order,
        column_1: item.labelName,
        column_2: rateField(
          `${setCurrencyValue} ${(item.labelOne * convertedRates).toFixed(2)}${
            String(item.labelOne).length === 2 ? "" : ""
          } /day`,
          item,
          "labelOne"
        ),
        column_3: rateField(
          `${setCurrencyValue} ${(item.labelTwo * convertedRates).toFixed(2)}${
            String(item.labelTwo).length === 2 ? "" : ""
          } /day`,
          item,
          "labelTwo"
        ),
        column_4: rateField(
          `${setCurrencyValue} ${(item.labelThree * convertedRates).toFixed(2)}${
            String(item.labelThree).length === 2 ? "" : ""
          } /day`,
          item,
          "labelThree"
        ),
        column_5: rateField(
          `${setCurrencyValue} ${(item.labelFour * convertedRates).toFixed(2)}${
            String(item.labelFour).length === 2 ? "" : ""
          } /day`,
          item,
          "labelFour"
        ),
        column_6: rateField(
          `${setCurrencyValue} ${(item.labelFourR * convertedRates).toFixed(2)}${
            String(item.labelFourR).length === 2 ? "" : ""
          } /day`,
          item,
          "labelFourR"
        ),
        column_7: rateField(
          `${setCurrencyValue} ${(item.labelFive * convertedRates).toFixed(2)}${
            String(item.labelFive).length === 2 ? "" : ""
          } /day`,
          item,
          "labelFive"
        ),
      };
    } else {
      return {
        id: item._id,
        order: item.order,
        column_1: item.labelName,
        column_2: additionalField(
          `${item.labelOne}${
            String(item.labelOne).length === 2 ? "" : ""
          }% additional`,
          item,
          "labelOne"
        ),
        column_3: additionalField(
          `${item.labelTwo}${
            String(item.labelTwo).length === 2 ? "" : ""
          }% additional`,
          item,
          "labelTwo"
        ),
        column_4: additionalField(
          `${item.labelThree}${
            String(item.labelThree).length === 2 ? "" : ""
          }% additional`,
          item,
          "labelThree"
        ),
        column_5: additionalField(
          `${item.labelFour}${
            String(item.labelFour).length === 2 ? "" : ""
          }% additional`,
          item,
          "labelFour"
        ),
        column_6: additionalField(
          `${item.labelFourR}${
            String(item.labelFourR).length === 2 ? "" : ""
          }% additional`,
          item,
          "labelFourR"
        ),
        column_7: additionalField(
          `${item.labelFive}${
            String(item.labelFive).length === 2 ? "" : ""
          }% additional`,
          item,
          "labelFive"
        ),
      };
    }
  });

  rows = orderBy(rows, ["order"]);

  return (
    <Fragment>
      <CustomtableWIthStyle headers={headers} rows={rows} rowsKeys={rowsKeys} />
      <Details readOnly={readOnly} />
    </Fragment>
  );
};

export default RateCard;
