import React, { useState } from "react";
import { useParams } from "react-router";
// Material Core Components
import Typography from "@material-ui/core/Typography";

// Material Icons

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormTwo from "./FormTwo";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import LandingPageLayout from "layout/LandingPageLayout";

const PrivateInterpretingWeb = () => {
  const classes = useEmployeeStyles();
  const { frontPage } = useParams();
  const [id, setId] = useState("");
  return (
    <LandingPageLayout>
      {/* <div className={classes.root}> */}
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  Private Interpreting Assignment Booking
                </Typography>
                {frontPage && (
                  <Typography variant="subtitle2">
                    Linguist / Agent referrer ID: {id}
                  </Typography>
                )}
                <Typography variant="subtitle2">
                  <b></b>Recommended web browser: for best performance please
                  use
                  <a href="JavaScript:newPopup('https://www.google.co.uk/chrome/?brand=FHFK&gclsrc=aw.ds&gclid=EAIaIQobChMImueQy6yL8QIVCt1RCh0lIQ-7EAAYASAAEgJdS_D_BwE');">
                    {" "}
                    Google Chrome{" "}
                  </a>
                  or
                  <a href="JavaScript:newPopup('https://www.microsoft.com/en-us/edge');">
                    {" "}
                    Microsoft Edge.
                  </a>
                </Typography>
              </div>
              {frontPage && (
                <div className={classes.notes}>
                  <Typography variant="h6" gutterBottom>
                    Please note
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    this booking / order will be sent to the Agent Linguist as a
                    request from you. If Agent Linguist not available may refuse
                    to accept this booking / order. For further information and
                    confirmation please contact the Agent Linguist.
                  </Typography>
                </div>
              )}
              <FormTwo setId={setId} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* </div> */}
    </LandingPageLayout>
  );
};

export default PrivateInterpretingWeb;
