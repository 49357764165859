import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "react-loading-screen";
import CustomSelect from "components/Common/CustomSelect";
// import {loadStripe} from '@stripe/stripe-js';
// Material Core Components
import Cookies from "js-cookie";
import Typography from "@material-ui/core/Typography";
import { CURRENCY_x } from "data";
// Material Icons
import { changeBaInput } from "redux/actions/bookingAction";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import swal from "sweetalert";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import logo from "assets/images/logo.png";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import axios from "axios";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
} from "@material-ui/core";
import history from "utils/history";

import { Skeleton } from "@material-ui/lab";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";

import LandingPageLayout from "layout/LandingPageLayout";
import Axios from "axios";

const InhouseSummary = () => {
  const classes = useEmployeeStyles();
  const classesb = useLandingpageStyles();
  const [summary, setSummary] = useState("");
  const [rates, setRates] = useState("");
  const [payLaterAccess, setPayLaterAccess] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { data } = useSelector((state) => state.bookingReducer);
  const { currency_new, currency_new_card } = data;
  const IncID = Cookies.get("clientID");
  const [grandTotal, setGrandTotal] = useState("");
  // const stripePromise = loadStripe('pk_test_51I3OlCEdJeNZngVd9ROYTlsOSf8HVo8Q1MvlJeoy7Fryh5KfPtuO3LdLoggzv8QwTQgOvSrA0e4uNxbb0uDjbu1d00yj7pZaDs');
  useEffect(() => {
    data.currency = "GBP";
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/currency-exchange`)
      .then((res) => setRates(res?.data?.rates));

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-INC/${IncID}`
      )
      .then((res) => setPayLaterAccess(res?.data?.payLaterAccess));
  }, []);

  //calculation
  const new_currency = Cookies.get("currency");
  const bookingid = Cookies.get("bookingid");
  const inhouseClientID = Cookies.get("inhouseClientID");
  const total_Overnight_charges = Cookies.get("total_Overnight_charges");
  const total_PriceForInterpriting = Cookies.get("total_PriceForInterpriting");
  const total_TravelTotal_Wages = 0.0;
  const tatal_Food_Overnight = Cookies.get("tatal_Food_Overnight");

  let a = parseFloat(tatal_Food_Overnight) || 0;

  let b = parseFloat(total_Overnight_charges) || 0;

  let c = parseFloat(total_PriceForInterpriting) || 0;

  let d = parseFloat(total_TravelTotal_Wages) || 0;

  const estimatedTotal = a + b + c + d;

  //submission of payment
  const handleChange = (e) => {
    dispatch(
      changeBaInput({
        name: "currency_new",
        value: e.target.value,
      })
    );
  };

  const handleChangecard = (e) => {
    const { value } = e.target;

    dispatch(
      changeBaInput({
        name: "currency_new_card",
        value: e.target.value,
      })
    );

    if (value === "EUR (Euro countries)") {
      setGrandTotal(
        estimatedTotal * rates.EUR +
          (((estimatedTotal * 2.5) / 100) * rates.EUR).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.EUR +
          ((estimatedTotal * 2.5) / 100) * rates.EUR
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "EUR €");
    } else if (value === "AUD (Australia)") {
      setGrandTotal(
        estimatedTotal * rates.AUD +
          (((estimatedTotal * 2.5) / 100) * rates.AUD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.AUD +
          ((estimatedTotal * 2.5) / 100) * rates.AUD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "AUD $");
    } else if (value === "USD (USA, paying from USA only)") {
      setGrandTotal(
        estimatedTotal * rates.USD +
          (((estimatedTotal * 2.5) / 100) * rates.USD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.USD +
          ((estimatedTotal * 2.5) / 100) * rates.USD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "USD $");
    } else if (value === "CAD (Canada)") {
      setGrandTotal(
        estimatedTotal * rates.CAD +
          (((estimatedTotal * 2.5) / 100) * rates.CAD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.CAD +
          ((estimatedTotal * 2.5) / 100) * rates.CAD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "CAD $");
    } else if (value === "TRY (Turkish lira, payment from Turkey only)") {
      setGrandTotal(
        estimatedTotal * rates.TRY +
          (((estimatedTotal * 2.5) / 100) * rates.TRY).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.TRY +
          ((estimatedTotal * 2.5) / 100) * rates.TRY
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "TRY  ₺");
    } else if (value === "SGD (Singapore dollar)") {
      setGrandTotal(
        estimatedTotal * rates.SGD +
          (((estimatedTotal * 2.5) / 100) * rates.SGD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.SGD +
          ((estimatedTotal * 2.5) / 100) * rates.SGD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "SGD $");
    } else if (value === "HUF (Hungarian forint)") {
      setGrandTotal(
        estimatedTotal * rates.HUF +
          (((estimatedTotal * 2.5) / 100) * rates.HUF).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.HUF +
          ((estimatedTotal * 2.5) / 100) * rates.HUF
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "HUF Ft");
    } else if (
      value === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      setGrandTotal(
        estimatedTotal * rates.NZD +
          (((estimatedTotal * 2.5) / 100) * rates.NZD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.NZD +
          ((estimatedTotal * 2.5) / 100) * rates.NZD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "NZD $");
    } else if (value === "RON (Romanian leu, paying from Romania only)") {
      setGrandTotal(
        estimatedTotal * rates.RON +
          (((estimatedTotal * 2.5) / 100) * rates.RON).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.RON +
          ((estimatedTotal * 2.5) / 100) * rates.RON
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "RON lei");
    } else {
      setGrandTotal(estimatedTotal.toFixed(2));
      Cookies.set("grandTotal", estimatedTotal.toFixed(2));
      Cookies.set("stripeCurrency", "GBP £");
    }
  };

  const handlePayLaterNext = async (e) => {
    // alert("doing")
    setLoading(true);

    if (currency_new === "EUR (Euro countries)") {
      let x =
        estimatedTotal * rates.EUR + ((estimatedTotal * 2.5) / 100) * rates.EUR;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/payLater`,
        { currency_new, new_total, bookingid }
      );
    } else if (currency_new === "AUD (Australia)") {
      let x =
        estimatedTotal * rates.AUD + ((estimatedTotal * 2.5) / 100) * rates.AUD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/payLater`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else if (currency_new === "USD (USA, paying from USA only)") {
      let x =
        estimatedTotal * rates.USD + ((estimatedTotal * 2.5) / 100) * rates.USD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/payLater`,
        { currency_new, new_total, bookingid }
      );
    } else if (currency_new === "CAD (Canada)") {
      let x =
        estimatedTotal * rates.CAD + ((estimatedTotal * 2.5) / 100) * rates.CAD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/payLater`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else if (
      currency_new === "TRY (Turkish lira, payment from Turkey only)"
    ) {
      let x =
        estimatedTotal * rates.TRY + ((estimatedTotal * 2.5) / 100) * rates.TRY;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/payLater`,
        { currency_new, new_total, bookingid }
      );
    } else if (currency_new === "SGD (Singapore dollar)") {
      let x =
        estimatedTotal * rates.SGD + ((estimatedTotal * 2.5) / 100) * rates.SGD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/payLater`,
        { currency_new, new_total, bookingid }
      );
    } else if (currency_new === "HUF (Hungarian forint)") {
      let x =
        estimatedTotal * rates.HUF + ((estimatedTotal * 2.5) / 100) * rates.HUF;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/payLater`,
        { currency_new, new_total, bookingid }
      );
    } else if (
      currency_new === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      let x =
        estimatedTotal * rates.NZD + ((estimatedTotal * 2.5) / 100) * rates.NZD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/payLater`,
        { currency_new, new_total, bookingid }
      );
    } else if (
      currency_new === "RON (Romanian leu, paying from Romania only)"
    ) {
      let x =
        estimatedTotal * rates.RON + ((estimatedTotal * 2.5) / 100) * rates.RON;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/payLater`,
        { currency_new, new_total, bookingid }
      );
    } else {
      let new_total = estimatedTotal.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/payLater`,
        { currency_new, new_total, bookingid }
      );
    }

    try {
      let EUR = (
        estimatedTotal * rates.EUR +
        ((estimatedTotal * 2.5) / 100) * rates.EUR
      ).toFixed(2);
      let AUD = (
        estimatedTotal * rates.AUD +
        ((estimatedTotal * 2.5) / 100) * rates.AUD
      ).toFixed(2);
      let USD = (
        estimatedTotal * rates.USD +
        ((estimatedTotal * 2.5) / 100) * rates.USD
      ).toFixed(2);
      let CAD = (
        estimatedTotal * rates.CAD +
        ((estimatedTotal * 2.5) / 100) * rates.CAD
      ).toFixed(2);
      let TRY = (
        estimatedTotal * rates.TRY +
        ((estimatedTotal * 2.5) / 100) * rates.TRY
      ).toFixed(2);
      let SGD = (
        estimatedTotal * rates.SGD +
        ((estimatedTotal * 2.5) / 100) * rates.SGD
      ).toFixed(2);
      let HUF = (
        estimatedTotal * rates.HUF +
        ((estimatedTotal * 2.5) / 100) * rates.HUF
      ).toFixed(2);
      let NZD = (
        estimatedTotal * rates.NZD +
        ((estimatedTotal * 2.5) / 100) * rates.NZD
      ).toFixed(2);
      let RON = (
        estimatedTotal * rates.RON +
        ((estimatedTotal * 2.5) / 100) * rates.RON
      ).toFixed(2);

      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse-client/sendEmail-AUSDAccount`,
        {
          currency_new,
          estimatedTotal,
          inhouseClientID,
          rates,
          bookingid,
          EUR,
          AUD,
          USD,
          CAD,
          TRY,
          SGD,
          HUF,
          NZD,
          RON,
        }
      );
      setLoading(false);
      swal("Success!! Please check your registered email for more details..", {
        icon: "success",
        buttons: "OK!!",
      }).then((res) => {
        setLoading(false);
        Cookies.set("total_Overnight_charges", 0);
        Cookies.set("total_PriceForInterpriting", 0);
        Cookies.set("total_TravelTotal_Wages", 0);
        Cookies.set("tatal_Food_Overnight", 0);
        window.location = "/";
      });
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleNext = async (e) => {
    // alert("doing")
    setLoading(true);

    if (currency_new === "EUR (Euro countries)") {
      let x =
        estimatedTotal * rates.EUR + ((estimatedTotal * 2.5) / 100) * rates.EUR;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/sendMail`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else if (currency_new === "AUD (Australia)") {
      let x =
        estimatedTotal * rates.AUD + ((estimatedTotal * 2.5) / 100) * rates.AUD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/sendMail`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else if (currency_new === "USD (USA, paying from USA only)") {
      let x =
        estimatedTotal * rates.USD + ((estimatedTotal * 2.5) / 100) * rates.USD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/sendMail`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else if (currency_new === "CAD (Canada)") {
      let x =
        estimatedTotal * rates.CAD + ((estimatedTotal * 2.5) / 100) * rates.CAD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/sendMail`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else if (
      currency_new === "TRY (Turkish lira, payment from Turkey only)"
    ) {
      let x =
        estimatedTotal * rates.TRY + ((estimatedTotal * 2.5) / 100) * rates.TRY;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/sendMail`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else if (currency_new === "SGD (Singapore dollar)") {
      let x =
        estimatedTotal * rates.SGD + ((estimatedTotal * 2.5) / 100) * rates.SGD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/sendMail`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else if (currency_new === "HUF (Hungarian forint)") {
      let x =
        estimatedTotal * rates.HUF + ((estimatedTotal * 2.5) / 100) * rates.HUF;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/sendMail`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else if (
      currency_new === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      let x =
        estimatedTotal * rates.NZD + ((estimatedTotal * 2.5) / 100) * rates.NZD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/sendMail`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else if (
      currency_new === "RON (Romanian leu, paying from Romania only)"
    ) {
      let x =
        estimatedTotal * rates.RON + ((estimatedTotal * 2.5) / 100) * rates.RON;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/sendMail`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    } else {
      let new_total = estimatedTotal.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouseinterpreting/sendMail`,
        { currency_new, new_total, inhouseClientID, rates, bookingid }
      );
    }

    try {
      let EUR = (
        estimatedTotal * rates.EUR +
        ((estimatedTotal * 2.5) / 100) * rates.EUR
      ).toFixed(2);
      let AUD = (
        estimatedTotal * rates.AUD +
        ((estimatedTotal * 2.5) / 100) * rates.AUD
      ).toFixed(2);
      let USD = (
        estimatedTotal * rates.USD +
        ((estimatedTotal * 2.5) / 100) * rates.USD
      ).toFixed(2);
      let CAD = (
        estimatedTotal * rates.CAD +
        ((estimatedTotal * 2.5) / 100) * rates.CAD
      ).toFixed(2);
      let TRY = (
        estimatedTotal * rates.TRY +
        ((estimatedTotal * 2.5) / 100) * rates.TRY
      ).toFixed(2);
      let SGD = (
        estimatedTotal * rates.SGD +
        ((estimatedTotal * 2.5) / 100) * rates.SGD
      ).toFixed(2);
      let HUF = (
        estimatedTotal * rates.HUF +
        ((estimatedTotal * 2.5) / 100) * rates.HUF
      ).toFixed(2);
      let NZD = (
        estimatedTotal * rates.NZD +
        ((estimatedTotal * 2.5) / 100) * rates.NZD
      ).toFixed(2);
      let RON = (
        estimatedTotal * rates.RON +
        ((estimatedTotal * 2.5) / 100) * rates.RON
      ).toFixed(2);

      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse-client/sendEmail-AUSDAccount`,
        {
          currency_new,
          estimatedTotal,
          inhouseClientID,
          rates,
          bookingid,
          EUR,
          AUD,
          USD,
          CAD,
          TRY,
          SGD,
          HUF,
          NZD,
          RON,
        }
      );
      setLoading(false);
      swal("Success!! Please check your registered email for more details..", {
        icon: "success",
        buttons: "OK!!",
      }).then((res) => {
        setLoading(false);
        Cookies.set("total_Overnight_charges", 0);
        Cookies.set("total_PriceForInterpriting", 0);
        Cookies.set("total_TravelTotal_Wages", 0);
        Cookies.set("tatal_Food_Overnight", 0);
        window.location = "/";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const isAllow2 = !currency_new_card || 0;

  const isAllow = !currency_new || 0;
  return (
    <LandingPageLayout>
      <div className={classes.root}>
        {loading === false ? (
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={9} xl={8}>
              <Card>
                <CardBody>
                  <div className={`${classes.textCenter} ${classes.title}`}>
                    {/* <Avatar className={classes.avatar}> */}
                    <img
                      style={{ width: "70px", height: "65px" }}
                      src={logo}
                      alt=""
                    />
                    {/* </Avatar> */}
                    <Typography component="h1" variant="h5">
                      Summary
                    </Typography>
                  </div>

                  <div
                    style={{
                      padding: "4rem 12rem 4rem 12rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      {bookingid ? (
                        <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                          <p>Booking Order / Assignment ID</p>
                          <span style={{ marginLeft: "auto" }}>
                            {bookingid}
                          </span>
                        </div>
                      ) : null}
                      <Divider />

                      {total_PriceForInterpriting ? (
                        <div style={{ display: "flex", margin: "1em 0 0 0" }}>
                          <p>Total interpreting price:</p>

                          {new_currency.includes("GBP (UK)") && (
                            <span style={{ marginLeft: "auto" }}>
                              £ {c.toFixed(2)}
                            </span>
                          )}
                        </div>
                      ) : null}

                      {total_TravelTotal_Wages ? (
                        <div style={{ display: "flex" }}>
                          <p>Estimated Travelling allowances (TBC):</p>

                          {new_currency.includes("GBP (UK)") && (
                            <span style={{ marginLeft: "auto" }}>
                              £ {d.toFixed(2)}
                            </span>
                          )}
                        </div>
                      ) : null}

                      {tatal_Food_Overnight ? (
                        <div style={{ display: "flex" }}>
                          <p>Estimated food allowances (TBC):</p>

                          {new_currency.includes("GBP (UK)") && (
                            <span style={{ marginLeft: "auto" }}>
                              £ {a.toFixed(2)}
                            </span>
                          )}
                        </div>
                      ) : null}

                      {total_Overnight_charges ? (
                        <div style={{ display: "flex" }}>
                          <p>Estimated overnight charges (TBC):</p>

                          {new_currency.includes("GBP (UK)") && (
                            <span style={{ marginLeft: "auto" }}>
                              £ {b.toFixed(2)}
                            </span>
                          )}
                        </div>
                      ) : (
                        <Skeleton variant="text" animation="wave" />
                      )}

                      <Divider />
                      {estimatedTotal ? (
                        <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                          <p>Estimated total: </p>

                          {new_currency.includes("GBP (UK)") && (
                            <span style={{ marginLeft: "auto" }}>
                              £ {estimatedTotal.toFixed(2)}
                            </span>
                          )}
                        </div>
                      ) : (
                        <Skeleton variant="text" animation="wave" />
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                    <span style={{ marginLeft: "auto" }}>
                      * TBC- To be confirmed
                    </span>
                  </div>
                  <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                    <span style={{ marginLeft: "auto" }}>
                      * 2 Days + 70 miles = overnight
                    </span>
                  </div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Marketplace speedy payment
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomSelect
                        onChange={handleChangecard}
                        data={CURRENCY_x}
                        width="73.3%"
                        label="Preferred currency"
                        name="currency_new_card"
                        value={currency_new_card}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        {estimatedTotal ? (
                          <div
                            style={{ display: "flex", margin: "1em 0 1em 0" }}
                          >
                            <p>Estimated total</p>
                            {currency_new_card.includes("GBP (UK)") && (
                              <span style={{ marginLeft: "auto" }}>
                                £ {estimatedTotal.toFixed(2)}
                              </span>
                            )}
                            {currency_new_card.includes(
                              "EUR (Euro countries)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                €{" "}
                                {(
                                  estimatedTotal * rates.EUR +
                                  ((estimatedTotal * 2.5) / 100) * rates.EUR
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new_card.includes("AUD (Australia)") && (
                              <span style={{ marginLeft: "auto" }}>
                                AUD ${" "}
                                {(
                                  estimatedTotal * rates.AUD +
                                  ((estimatedTotal * 2.5) / 100) * rates.AUD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new_card.includes(
                              "USD (USA, paying from USA only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                USD ${" "}
                                {(
                                  estimatedTotal * rates.USD +
                                  ((estimatedTotal * 2.5) / 100) * rates.USD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new_card.includes("CAD (Canada)") && (
                              <span style={{ marginLeft: "auto" }}>
                                CAD ${" "}
                                {(
                                  estimatedTotal * rates.CAD +
                                  ((estimatedTotal * 2.5) / 100) * rates.CAD
                                ).toFixed(2)}
                              </span>
                            )}

                            {currency_new_card.includes(
                              "TRY (Turkish lira, payment from Turkey only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                TRY ₺{" "}
                                {(
                                  estimatedTotal * rates.TRY +
                                  ((estimatedTotal * 2.5) / 100) * rates.TRY
                                ).toFixed(2)}
                              </span>
                            )}

                            {currency_new_card.includes(
                              "SGD (Singapore dollar)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                SGD ${" "}
                                {(
                                  estimatedTotal * rates.SGD +
                                  ((estimatedTotal * 2.5) / 100) * rates.SGD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new_card.includes(
                              "HUF (Hungarian forint)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                HUF Ft{" "}
                                {(
                                  estimatedTotal * rates.HUF +
                                  ((estimatedTotal * 2.5) / 100) * rates.HUF
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new_card.includes(
                              "NZD (New Zealand dollar, paying from New Zealand only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                NZD ${" "}
                                {(
                                  estimatedTotal * rates.NZD +
                                  ((estimatedTotal * 2.5) / 100) * rates.NZD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new_card.includes(
                              "RON (Romanian leu, paying from Romania only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                RON lei{" "}
                                {(
                                  estimatedTotal * rates.RON +
                                  ((estimatedTotal * 2.5) / 100) * rates.RON
                                ).toFixed(2)}
                              </span>
                            )}

                            {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                          </div>
                        ) : null}
                      </div>
                    </AccordionDetails>
                    <AccordionDetails>
                      <Button
                        onClick={() =>
                          history.push(
                            `/inhouse-interpreter-booking/client/summary/estimate/payment`
                          )
                        }
                        disabled={isAllow2}
                        className={`${classesb.button}`}
                        style={{
                          width: "30rem",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Pay and confirm
                      </Button>

                      {/* //stripe */}
                    </AccordionDetails>
                  </Accordion>

                  {payLaterAccess && (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Pay Later
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CustomSelect
                          onChange={handleChange}
                          data={CURRENCY_x}
                          width="73.3%"
                          label="Preferred currency"
                          name="currency_new"
                          value={currency_new}
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          {estimatedTotal ? (
                            <div
                              style={{ display: "flex", margin: "1em 0 1em 0" }}
                            >
                              <p>Estimated total</p>
                              {currency_new.includes("GBP (UK)") && (
                                <span style={{ marginLeft: "auto" }}>
                                  £ {estimatedTotal.toFixed(2)}
                                </span>
                              )}
                              {currency_new.includes(
                                "EUR (Euro countries)"
                              ) && (
                                <span style={{ marginLeft: "auto" }}>
                                  €{" "}
                                  {(
                                    estimatedTotal * rates.EUR +
                                    ((estimatedTotal * 2.5) / 100) * rates.EUR
                                  ).toFixed(2)}
                                </span>
                              )}
                              {currency_new.includes("AUD (Australia)") && (
                                <span style={{ marginLeft: "auto" }}>
                                  AUD ${" "}
                                  {(
                                    estimatedTotal * rates.AUD +
                                    ((estimatedTotal * 2.5) / 100) * rates.AUD
                                  ).toFixed(2)}
                                </span>
                              )}
                              {currency_new.includes(
                                "USD (USA, paying from USA only)"
                              ) && (
                                <span style={{ marginLeft: "auto" }}>
                                  USD ${" "}
                                  {(
                                    estimatedTotal * rates.USD +
                                    ((estimatedTotal * 2.5) / 100) * rates.USD
                                  ).toFixed(2)}
                                </span>
                              )}
                              {currency_new.includes("CAD (Canada)") && (
                                <span style={{ marginLeft: "auto" }}>
                                  CAD ${" "}
                                  {(
                                    estimatedTotal * rates.CAD +
                                    ((estimatedTotal * 2.5) / 100) * rates.CAD
                                  ).toFixed(2)}
                                </span>
                              )}

                              {currency_new.includes(
                                "TRY (Turkish lira, payment from Turkey only)"
                              ) && (
                                <span style={{ marginLeft: "auto" }}>
                                  {" "}
                                  TRY ₺{" "}
                                  {(
                                    estimatedTotal * rates.TRY +
                                    ((estimatedTotal * 2.5) / 100) * rates.TRY
                                  ).toFixed(2)}
                                </span>
                              )}

                              {currency_new.includes(
                                "SGD (Singapore dollar)"
                              ) && (
                                <span style={{ marginLeft: "auto" }}>
                                  {" "}
                                  SGD ${" "}
                                  {(
                                    estimatedTotal * rates.SGD +
                                    ((estimatedTotal * 2.5) / 100) * rates.SGD
                                  ).toFixed(2)}
                                </span>
                              )}
                              {currency_new.includes(
                                "HUF (Hungarian forint)"
                              ) && (
                                <span style={{ marginLeft: "auto" }}>
                                  HUF Ft{" "}
                                  {(
                                    estimatedTotal * rates.HUF +
                                    ((estimatedTotal * 2.5) / 100) * rates.HUF
                                  ).toFixed(2)}
                                </span>
                              )}
                              {currency_new.includes(
                                "NZD (New Zealand dollar, paying from New Zealand only)"
                              ) && (
                                <span style={{ marginLeft: "auto" }}>
                                  {" "}
                                  NZD ${" "}
                                  {(
                                    estimatedTotal * rates.NZD +
                                    ((estimatedTotal * 2.5) / 100) * rates.NZD
                                  ).toFixed(2)}
                                </span>
                              )}
                              {currency_new.includes(
                                "RON (Romanian leu, paying from Romania only)"
                              ) && (
                                <span style={{ marginLeft: "auto" }}>
                                  {" "}
                                  RON lei{" "}
                                  {(
                                    estimatedTotal * rates.RON +
                                    ((estimatedTotal * 2.5) / 100) * rates.RON
                                  ).toFixed(2)}
                                </span>
                              )}

                              {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                            </div>
                          ) : null}
                        </div>
                      </AccordionDetails>
                      <AccordionDetails>
                        <Button
                          className={`${classesb.button}`}
                          disabled={isAllow}
                          style={{
                            width: "30rem",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          variant="contained"
                          color="primary"
                          onClick={handlePayLaterNext}
                        >
                          Pay later
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Marketplace bank transfer
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomSelect
                        onChange={handleChange}
                        data={CURRENCY_x}
                        width="73.3%"
                        label="Preferred currency"
                        name="currency_new"
                        value={currency_new}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        {estimatedTotal ? (
                          <div
                            style={{ display: "flex", margin: "1em 0 1em 0" }}
                          >
                            <p>Estimated total</p>
                            {currency_new.includes("GBP (UK)") && (
                              <span style={{ marginLeft: "auto" }}>
                                £ {estimatedTotal.toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes("EUR (Euro countries)") && (
                              <span style={{ marginLeft: "auto" }}>
                                €{" "}
                                {(
                                  estimatedTotal * rates.EUR +
                                  ((estimatedTotal * 2.5) / 100) * rates.EUR
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes("AUD (Australia)") && (
                              <span style={{ marginLeft: "auto" }}>
                                AUD ${" "}
                                {(
                                  estimatedTotal * rates.AUD +
                                  ((estimatedTotal * 2.5) / 100) * rates.AUD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes(
                              "USD (USA, paying from USA only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                USD ${" "}
                                {(
                                  estimatedTotal * rates.USD +
                                  ((estimatedTotal * 2.5) / 100) * rates.USD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes("CAD (Canada)") && (
                              <span style={{ marginLeft: "auto" }}>
                                CAD ${" "}
                                {(
                                  estimatedTotal * rates.CAD +
                                  ((estimatedTotal * 2.5) / 100) * rates.CAD
                                ).toFixed(2)}
                              </span>
                            )}

                            {currency_new.includes(
                              "TRY (Turkish lira, payment from Turkey only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                TRY ₺{" "}
                                {(
                                  estimatedTotal * rates.TRY +
                                  ((estimatedTotal * 2.5) / 100) * rates.TRY
                                ).toFixed(2)}
                              </span>
                            )}

                            {currency_new.includes(
                              "SGD (Singapore dollar)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                SGD ${" "}
                                {(
                                  estimatedTotal * rates.SGD +
                                  ((estimatedTotal * 2.5) / 100) * rates.SGD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes(
                              "HUF (Hungarian forint)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                HUF Ft{" "}
                                {(
                                  estimatedTotal * rates.HUF +
                                  ((estimatedTotal * 2.5) / 100) * rates.HUF
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes(
                              "NZD (New Zealand dollar, paying from New Zealand only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                NZD ${" "}
                                {(
                                  estimatedTotal * rates.NZD +
                                  ((estimatedTotal * 2.5) / 100) * rates.NZD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes(
                              "RON (Romanian leu, paying from Romania only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                RON lei{" "}
                                {(
                                  estimatedTotal * rates.RON +
                                  ((estimatedTotal * 2.5) / 100) * rates.RON
                                ).toFixed(2)}
                              </span>
                            )}

                            {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                          </div>
                        ) : null}
                      </div>
                    </AccordionDetails>
                    <AccordionDetails>
                      <Button
                        className={`${classesb.button}`}
                        disabled={isAllow}
                        style={{
                          width: "30rem",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Get bank details
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          <LoadingScreen
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc="https://linguistscollective.com/static/media/Interpreting-Translation-Linguists-Collective-Language-Services.0f1108ca.png"
            text="Please wait! The system is generating email."
          >
            <div>Loadable content</div>
          </LoadingScreen>
        )}
      </div>
    </LandingPageLayout>
  );
};

export default InhouseSummary;
