import CustomTextInput from "components/Common/CustomTextInput";
import React, { useRef, useState, useEffect } from "react";
import { Button, Dimmer, Loader, Rating, Table } from "semantic-ui-react";
import { Header } from "semantic-ui-react";
import { Checkbox } from "semantic-ui-react";
import SignatureCanvas from "react-signature-canvas";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import history from "utils/history";
import RichTextEditor from "react-rte";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import Signature from "./signature";

const Details = () => {
  let sigPad = useRef({});
  const [signature, setSignature] = useState(false);
  const [sign, setSign] = useState("");
  const [text, setText] = useState();
  const [projectReport, setProjectReport] = useState(null);
  const [linguist, setLinguist] = useState(null);
  const { id } = useParams();
  const token = Cookies.get("LINtoken");
  const [declaration, setDeclaration] = useState(false);
  const [details, setDetails] = useState({
    project_name: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    request_details: "",
    target_language_level: "",
    security_clearence: "",
    word_count: "",
    visual_formatting: "",
    certification: "",
    post_certification: "",
    project_report: RichTextEditor.createEmptyValue(),
    signature: "",
    supervisor_fullname: "",
    date: "",
    feedbackByLin: "",
    remarksByLin: "",
    loader: false,
  });
  const uploadRef = useRef();
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const todayDate = moment().format("DD/MM/YYYY");

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getProjectReport = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-project-report/reportID/${id}`
        );

        setProjectReport(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancel");
        } else {
          console.log(error.response);
        }
      }
    };

    getProjectReport();

    return () => {
      source.cancel();
    };
  }, []);

  const handleClose = (e) => {
    e.preventDefault();
    setSignature(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = () => {
    const temp = sigPad.current.toDataURL();
    setDetails({ ...details, signature: temp });
    setSignature(false);
  };

  const handleText = (value) => {
    setText(value.toString("markdown"));
    setDetails({ ...details, project_report: value });
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });
    const fd = new FormData();

    const file = new File([details.signature], "signature", {
      type: "image/png",
    });
    console.log(projectReport.count);

    fd.append("signatureByLin", file);
    fd.append("feedbackByLin", details.feedbackByLin);

    if (uploadTimesheet?.size) {
      fd.append("document", uploadTimesheet);
    }

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/accept-project-report/reportID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setDetails({ ...details, loader: false });
      swal({
        text: "Project Report Confirmed",
        buttons: "Ok",
        icon: "success",
      });
      history.push(`/linguist/accepted-quote`);
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  const handleRate = (e, { rating, maxRating }) => {
    setDetails({ ...details, feedbackByLin: rating });
  };

  return projectReport ? (
    <div>
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          // onEnd = {handleSave}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            basic
            color="purple"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="purple">
            Save
          </Button>
        </div>
      </CustomModal>
      <CustomTextInput
        label="Assignment reference number"
        value={
          projectReport?.linQuoteID?.quoteRequstByCashclient?.id
            ? projectReport?.linQuoteID?.quoteRequstByCashclient?.id
            : projectReport?.linQuoteID?.quoteRequstByAccountclient?.id
              ? projectReport?.linQuoteID?.quoteRequstByAccountclient?.id
              : "N/A"
        }
      />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center">Actual</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Requested</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Project start date: </Table.Cell>
            <Table.Cell textAlign="center">
              {moment(projectReport?.requested_start_date).format("DD/MMM/YYYY")}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {moment(projectReport?.start_date).format("DD/MMM/YYYY")}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project start time: </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.requested_start_time}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.start_time}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project end date: </Table.Cell>
            <Table.Cell textAlign="center">
              {moment(projectReport?.requested_end_date).format("DD/MMM/YYYY")}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {moment(projectReport?.end_date).format("DD/MM/YYYY")}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project end time: </Table.Cell>
            <Table.Cell textAlign="center">{projectReport?.requested_end_time}</Table.Cell>
            <Table.Cell textAlign="center">{projectReport?.end_time}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.linQuoteID?.quoteRequstByCashclient
                ?.target_skills_required
                ? projectReport?.linQuoteID?.quoteRequstByCashclient
                  ?.target_skills_required
                : projectReport?.linQuoteID?.quoteRequstByAccountclient
                  ?.target_skills_required
                  ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                    ?.target_skills_required
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.postedByLin?.target_language_level
                ? projectReport?.postedByLin?.target_language_level
                : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Security clearance/s</Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.linQuoteID?.quoteRequstByCashclient
                ?.security_clearance
                ? projectReport?.linQuoteID?.quoteRequstByCashclient
                  ?.security_clearance
                : projectReport?.linQuoteID?.quoteRequstByAccountclient
                  ?.security_clearance
                  ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                    ?.security_clearance
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.linQuoteID?.quoteRequstByCashclient
                ?.security_clearance
                ? projectReport?.linQuoteID?.quoteRequstByCashclient
                  ?.security_clearance
                : projectReport?.linQuoteID?.quoteRequstByAccountclient
                  ?.security_clearance
                  ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                    ?.security_clearance
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Wordcount</Table.Cell>
            <Table.Cell textAlign="center">{projectReport?.requested_wordCount ? projectReport?.requested_wordCount : "N/A"}</Table.Cell>
            <Table.Cell textAlign="center">{projectReport?.word_count ? projectReport?.word_count : "N/A" }</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Visual formatting</Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.linQuoteID?.quoteRequstByCashclient
                ?.require_visual_formating
                ? projectReport?.linQuoteID?.quoteRequstByCashclient
                  ?.require_visual_formating
                : projectReport?.linQuoteID?.quoteRequstByAccountclient
                  ?.require_visual_formating
                  ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                    ?.require_visual_formating
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.linQuoteID?.quoteRequstByCashclient
                ?.require_visual_formating
                ? projectReport?.linQuoteID?.quoteRequstByCashclient
                  ?.require_visual_formating
                : projectReport?.linQuoteID?.quoteRequstByAccountclient
                  ?.require_visual_formating
                  ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                    ?.require_visual_formating
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Certification</Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.linQuoteID?.quoteRequstByCashclient?.certificate
                ? projectReport?.linQuoteID?.quoteRequstByCashclient
                  ?.certificate
                : projectReport?.linQuoteID?.quoteRequstByAccountclient
                  ?.certificate
                  ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                    ?.certificate
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.linQuoteID?.quoteRequstByCashclient?.certificate
                ? projectReport?.linQuoteID?.quoteRequstByCashclient
                  ?.certificate
                : projectReport?.linQuoteID?.quoteRequstByAccountclient
                  ?.certificate
                  ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                    ?.certificate
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Post certification </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.linQuoteID?.quoteRequstByCashclient
                ?.certified_translation
                ? projectReport?.linQuoteID?.quoteRequstByCashclient
                  ?.certified_translation
                : projectReport?.linQuoteID?.quoteRequstByAccountclient
                  ?.certified_translation
                  ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                    ?.certified_translation
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.linQuoteID?.quoteRequstByCashclient
                ?.certified_translation
                ? projectReport?.linQuoteID?.quoteRequstByCashclient
                  ?.certified_translation
                : projectReport?.linQuoteID?.quoteRequstByAccountclient
                  ?.certified_translation
                  ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                    ?.certified_translation
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">Project Report</Header>
      <p>{projectReport?.project_report}</p>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
            <Table.HeaderCell>To be completed by admin</Table.HeaderCell>
            <Table.HeaderCell>To be completed by client</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            {projectReport?.signatureByLinTrue ? (
              <Table.Cell>
                <Checkbox
                  checked
                  label="I declare the information given by me is true/accurate and complete."
                  disabled
                />
              </Table.Cell>
            ) : (
              <Table.Cell>
                <Checkbox
                  checked={declaration}
                  onChange={(e, value) => setDeclaration(value.checked)}
                  label="I declare the information given by me is true/accurate and complete."
                />
              </Table.Cell>
            )}
            {projectReport?.signatureByadminTrue ? (
              <Table.Cell>
                <Checkbox
                  checked
                  label="I certify that the project was supervised / quality controlled by me"
                  disabled
                />
              </Table.Cell>
            ) : (
              <Table.Cell>
                <Checkbox
                  label="I certify that the project was supervised / quality controlled by me"
                  disabled
                />
              </Table.Cell>
            )}
            {projectReport?.signatureByClientTrue ? (
              <Table.Cell>
                <Checkbox
                  checked
                  label="I declare that I have checked the information on this form and certify that it is true and complete"
                  disabled
                />
              </Table.Cell>
            ) : (
              <Table.Cell>
                <Checkbox
                  label="I declare that I have checked the information on this form and certify that it is true and complete"
                  disabled
                />
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Signature{" "}
              <IconButton onClick={() => setSignature(true)}>
                <EditIcon />
              </IconButton>
              {details.signature && (
                <img
                  style={{ width: "100px", height: "50px" }}
                  src={details.signature}
                  alt=""
                />
              )}
            </Table.Cell>
            <Signature projectReport={projectReport} />
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p>
                {" "}
                Full name:
                <b>
                  {projectReport?.postedByLin?.FirstName
                    ? projectReport?.postedByLin?.FirstName
                    : " "}
                </b>
                <b>
                  {projectReport?.postedByLin?.LastName
                    ? projectReport?.postedByLin?.LastName
                    : " "}
                </b>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>
                {" "}
                Full name:
                <b>
                  {projectReport?.postedBySupervisor?.FirstName
                    ? projectReport?.postedBySupervisor?.FirstName
                    : projectReport?.postedBySupervisor?.FirstName
                      ? projectReport?.postedBySupervisor?.FirstName
                      : " "}
                </b>
                <b>
                  {projectReport?.postedBySupervisor?.LastName
                    ? projectReport?.postedBySupervisor?.LastName
                    : projectReport?.postedBySupervisor?.LastName
                      ? projectReport?.postedBySupervisor?.LastName
                      : " "}
                </b>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>
                {" "}
                Full name:
                <b>
                  {projectReport?.linQuoteID?.quoteRequstByCashclient?.postedBy
                    ?.FirstName
                    ? projectReport?.linQuoteID?.quoteRequstByCashclient
                      ?.postedBy?.FirstName
                    : projectReport?.linQuoteID?.quoteRequstByAccountclient
                      ?.postedBy?.FirstName
                      ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                        ?.postedBy?.FirstName
                      : " "}
                </b>
                <b>
                  {projectReport?.linQuoteID?.quoteRequstByCashclient?.postedBy
                    ?.LastName
                    ? projectReport?.linQuoteID?.quoteRequstByCashclient
                      ?.postedBy?.LastName
                    : projectReport?.linQuoteID?.quoteRequstByAccountclient
                      ?.postedBy?.LastName
                      ? projectReport?.linQuoteID?.quoteRequstByAccountclient
                        ?.postedBy?.LastName
                      : " "}
                </b>
              </p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {todayDate}</Table.Cell>
            <Table.Cell>Date: {todayDate}</Table.Cell>
            <Table.Cell>Date: {todayDate}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Feedback<b style={{ color: "red" }}>*</b>:
              <Rating
                maxRating={5}
                onRate={handleRate}
                icon="star"
                size="massive"
              />
            </Table.Cell>
            <Table.Cell>
              Remark
              <p>{projectReport.remarksBySupervisor}</p>
            </Table.Cell>
            <Table.Cell>
              Feedback:
              <Rating
                maxRating={5}
                rating={projectReport.feedbackByClient}
                icon="star"
                size="massive"
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      {/* <Signature projectReport={projectReport} /> */}

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button
        onClick={() => uploadRef.current.click()}
        basic
        style={{ backgroundColor: "purple", color: "white" }}
      >
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          loading={details.loader}
          onClick={handleSubmit}
          disabled={
            !declaration || !details.feedbackByLin || !details.signature
          }
          color="purple"
        >
          Submit
        </Button>
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="large">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
