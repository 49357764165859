import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
// Material Core Components\
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

// Material Icons
import ContactMailIcon from "@material-ui/icons/ContactMail";
// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";
import useDropdownStyles from "assets/jss/components/dropDownStyles";

import IconImg from "components/Sidebar/IconImg";
import CICicon from "assets/icons/company-info-card.png";

const Contact = ({ children, layoutType }) => {
  const history = useHistory();

  const state = useSelector((state) => state);

  const classes = useNavbarStyles();
  const classesTwo = useDropdownStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleChange = (e) => {
    switch (layoutType) {
      case "ACCOUNT_CLIENT":
        history.push("/account-client/booking-secratery");
        break;

      case "CASH_CLIENT":
        history.push("/cash-client/booking-secratery");
        break;

      case "EMPLOYEE_CLIENT":
        history.push("/employee/company-info-card");
        break;

      case "LINGUIST":
        history.push("/linguist/support-system");
        break;

      case "SPPO":
        history.push("/sppo/company-contact-details");
        break;

      default:
        history.push("/admin/view-contact-card");
        break;
    }
  };

  const handleChangeReport = (e) => {
    history.push("/linguist/report-issue");
  };

  // TODO set logic based on logged in user

  return (
    <Fragment>
      <IconButton aria-label="Person" color="primary" onClick={handleClick}>
        <div className={classes.imgDiv}>
          <IconImg className={classes.icons} icon={CICicon} />
        </div>
      </IconButton>

      <div>
        <Popper
          open={Boolean(open)}
          anchorEl={anchorEl}
          placement="bottom-end"
          className={classesTwo.toolTip}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {history.location.pathname.includes("account-client") ||
                    history.location.pathname.includes("cash-client") ? (
                      <MenuItem
                        onClick={handleChange}
                        className={classesTwo.dropdownItem}
                      >
                        Company Information
                      </MenuItem>
                    ) : (
                      <Fragment>
                        {history.location.pathname.includes("admin") ||
                        history.location.pathname.includes("employee") ? (
                          <MenuItem
                            onClick={handleChange}
                            className={classesTwo.dropdownItem}
                          >
                            Company Contact Info Card
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={handleChange}
                            className={classesTwo.dropdownItem}
                          >
                            Company Information
                          </MenuItem>
                        )}
                      </Fragment>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </Fragment>
  );
};

export default Contact;
