import React, { useState } from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

import SppoLayout from "layout/SppoLayout";
import Sppo from "components/RouteProtection/Sppo/Sppo";
import BookingOption from "components/Sppo/ManageInhouse/BookingOption";
import Options from "components/Sppo/ManageInhouse/Options";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import BookingFormLinguist from "components/Sppo/ManageInhouse/SendBookingForm/BookingForm";

const BookingInterpreter = () => {
  const classes = useDashboardStyles();
  const [type, setType] = useState("bookings");

  return (
    <SppoLayout>
      <Sppo>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Send Booking Form</p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BookingOption setType={setType} />
              </CardHeader>
            </Card>
            <Card>
              {type !== "" && (
                <CardHeader color="primary">
                  <Options type={type} />
                </CardHeader>
              )}
            </Card>
            <BookingFormLinguist />
          </GridItem>
        </GridContainer>
      </Sppo>
    </SppoLayout>
  );
};

export default BookingInterpreter;
