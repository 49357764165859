import { CHANGE_JOBADVERT_INPUT, UPDATE_ADVERT_STEP } from '../actions/actionTypes'

const INITIAL_STATE= {
    count: 1,
    data: {
        purchase_order_number: '',
        jobtitle: '',
        salary: '',
        contracttype: '',
        hoursperweek: '',
        duty_station: '',
        location: '',
        remote_details: '',
        address_line_one: '',
        address_line_two: '',
        city: '',
        postcode: '',
        region: '',
        country: '',
        nationality: '',
        other_nationalities: [],
        jobref: '',
        targetlanguage: '',
        sourcelanguage: '',
        additionallanguage: [],
        expertiselevel: '',
        about_company: '',
        jobdescription: '',
        howtoapply: '',
        applicationclosingdate: '',
        nhs_trust: '',
        registration_number: '',
        agree: false,
    }
}

const advertReducer = ( state= INITIAL_STATE, action) => {
    const { type, payload }= action

    switch(type) {
        case UPDATE_ADVERT_STEP:
            return {
                ...state,
                count: payload
            }
        case CHANGE_JOBADVERT_INPUT:
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.name]: payload.value
                }
            }
        default:
            return state
    }
}

export default advertReducer

