import React, { useState } from "react";
import { Header, TextArea } from "semantic-ui-react";
import swal from "sweetalert";
import Axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import CustomSnackBar from "components/Common/CustomSnackBar";
import { changePPLInput } from "redux/actions/updatePPLAction";
import { Checkbox } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";

const About = () => {
  const token = Cookies.get("SPPOtoken");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updatePPLReducer);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changePPLInput({
        name,
        value,
      })
    );
  };

  const [update, setUpdate] = useState(false);

  const updateAbout = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/allow-about-section/${
          data?.agent_sppo ? data?.agent_sppo : data?._id
        }`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/sppo/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const hideAbout = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/disable-about-section/${
          data?.agent_sppo ? data?.agent_sppo : data?._id
        }`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/sppo/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePublicProfile = (value) => {
    if (data.access) {
      if (!data.about_section) {
        swal(
          "About section of your profile will be public. Are you sure you want to make this section of your profile public?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              updateAbout(value);

            default:
              break;
          }
        });
      } else {
        swal(
          "Are you sure you want to revoke public view permission for this section?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              hideAbout(value);

            default:
              break;
          }
        });
      }
    }
  };

  return (
    <div>
      <CustomSnackBar
        message="Successfully updated! Send approval request to admin"
        handleClose={() => setUpdate(false)}
        openSnackBar={update}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2rem",
        }}
      >
        <Header color="purple" as="h2">
          About Us
        </Header>
        <Checkbox
          toggle
          checked={data?.about_section}
          onChange={() => handlePublicProfile("about_section")}
        />
      </div>
      <TextField
        label="Type here about you... (max 1296 ch.)"
        name="about"
        onChange={handleChange}
        value={data.about}
        multiline
        rows={5}
        inputProps={{ maxLength: 1296 }}
        style={{ width: "100%" }}
        placeholder="Type here...please provide brief and promotional introduction about you. Do no include your personal contact details or email address. Anything you mention must be true and have evidence to support your claim."
      />
    </div>
  );
};

export default About;
