import React, { useState } from "react";
// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import defaultAxios from "utils/axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
import { Button, Header } from "semantic-ui-react";
const FormOne = () => {
  const classes = useFormStyles();
  const [active, setActive] = useState("");
  const [data, setData] = useState({
    name: "",
    link: "",
    postedTo: "",
    embedLink: false,
  });

  const { name, link, postedTo, embedLink } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleLinkChange = (e) => {
    youtubeParser(e.target.value);
  };

  const youtubeParser = (url) => {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    setData({
      ...data,
      embedLink: match && match[7].length == 11 ? match[7] : false,
      link: url,
    });
  };

  const Active = (e, id) => {
    e.preventDefault();
    setActive(id);
    setData({ ...data, postedTo: id });
  };

  const isAllow = !data.name || !data.link || !data.postedTo;

  const handleSubmit = async (e) => {
    let sendData;
    const token = Cookies.get("token");
    if (postedTo === "emp") {
      sendData = {
        tutorial_name: data.name || "-",
        tutorial_link: data.link || "-",
        empTutorial: "yes" || "-",
      };
    } else if (postedTo === "lin") {
      sendData = {
        tutorial_name: data.name || "-",
        tutorial_link: data.link || "-",
        linTutorial: "yes" || "-",
      };
    } else if (postedTo === "sppo") {
      sendData = {
        tutorial_name: data.name || "-",
        tutorial_link: data.link || "-",
        sppoTutorial: "yes" || "-",
      };
    } else if (postedTo === "bac") {
      sendData = {
        tutorial_name: data.name || "-",
        tutorial_link: data.link || "-",
        bacTutorial: "yes" || "-",
      };
    } else if (postedTo === "cac") {
      sendData = {
        tutorial_name: data.name || "-",
        tutorial_link: data.link || "-",
        cacTutorial: "yes" || "-",
      };
    }

    try {
      const response = await defaultAxios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/post-tutorial`,
        sendData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `Posted to ${data.postedTo} successfully`,
        icon: "success",
        buttons: false,
      });
      setData({ ...data, name: "", link: "", postedTo: "", embedLink: "" });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error.response.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        label="Tutorial Name"
        name="name"
        value={data.name}
        width="100%"
        placeholder="enter tutorial name"
        required
      />

      <CustomInputSingle
        onChange={handleLinkChange}
        label="Tutorial link"
        name="link"
        value={data.link}
        width="100%"
        required
        placeholder="paste tutorial url (e.g: https://www.youtube.com/exampleUrl) "
      />
      <br />
      {data.embedLink && (
        <>
          <Header as="h5">video preview</Header>
          <iframe
            height="100%"
            width="100%"
            src={`https://www.youtube.com/embed/${data.embedLink}?rel=0`}
            frameBorder="0"
          ></iframe>
          <br />
        </>
      )}
      <br />
      <div className={classes.buttonFlex}>
        <Button.Group>
          <Button
            onClick={(e) => Active(e, "cac")}
            color={active === "cac" ? "purple" : "grey"}
          >
            CAC
          </Button>
          <Button.Or />
          <Button
            onClick={(e) => Active(e, "bac")}
            color={active === "bac" ? "purple" : "grey"}
          >
            BAC
          </Button>
          <Button.Or />
          <Button
            onClick={(e) => Active(e, "lin")}
            color={active === "lin" ? "purple" : "grey"}
          >
            LIN
          </Button>
          <Button.Or />
          <Button
            onClick={(e) => Active(e, "emp")}
            color={active === "emp" ? "purple" : "grey"}
          >
            EMP
          </Button>
          <Button.Or />
          <Button
            onClick={(e) => Active(e, "sppo")}
            color={active === "sppo" ? "purple" : "grey"}
          >
            SPPO
          </Button>
        </Button.Group>
      </div>
      <div className={classes.buttonFlex}>
        <CustomButton disabled={isAllow} text="Post" onClick={handleSubmit} />
      </div>
    </form>
  );
};

export default FormOne;
