import React, { useEffect, useState } from "react";
import Employee from "components/RouteProtection/Employee/Employee";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import CustomTextInput from "components/Common/CustomTextInput";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";

//Custom style
import EmployeeLayout from "layout/EmployeeLayout";
import Axios from "utils/axios";
import { useParams, Link } from "react-router-dom";
import Cookies from "js-cookie";
import CardHeader from "components/Card/CardHeader";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Button } from "@material-ui/core";

import Notes from "components/Admin/HrPanel/UnregisteredLinguist/Notes";
import Message from "components/Admin/HrPanel/UnregisteredLinguist/Message";

const ViewUnregisteredLinguists = () => {
  const { id } = useParams();
  const classes = useDashboardStyles();

  const [ULData, setULData] = useState(null);

  const getULinguistProfile = async (e) => {
    const token = Cookies.get("EMPtoken");
    try {
      const response = await Axios.get(
        `/api/employee/view/pregistration/preID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setULData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getULinguistProfile();
  }, []);

  return (
    <EmployeeLayout>
      <Employee>
        <div className={classes.root}>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className={classes.cardTitleWhite}>
                    Unregister Linguist - {ULData?.id}
                  </p>

                  <IconButton
                    color="inherit"
                    style={{ marginLeft: 5 }}
                    onClick={() => history.goBack()}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </CardHeader>
                <CardBody>
                  {ULData?.initial_reg ? (
                    <CustomTextInput value="Registered" label="Status" />
                  ) : (
                    <CustomTextInput value="Unregistered" label="Status" />
                  )}
                  <CustomTextInput value={ULData?.userID} label="Added by: " />
                  <CustomTextInput value={ULData?.title} label="Title:" />
                  <CustomTextInput
                    value={ULData?.first_name}
                    label="First Name:"
                  />
                  <CustomTextInput
                    value={ULData?.last_name}
                    label="Last Name:"
                  />
                  {/* <CustomTextInput value= {ULData?.first_name + ' ' +  ULData?.last_name}  label = 'Name: ' /> */}
                  <CustomTextInput
                    value={ULData?.mobile_no}
                    label="Mobile number:"
                  />
                  <CustomTextInput
                    value={ULData?.primary_email}
                    label="Email:"
                  />
                  <CustomTextInput
                    value={ULData?.addressline1}
                    label="House number: "
                  />
                  {ULData?.addressline2 !== "undefined" ? (
                    <CustomTextInput
                      value={ULData?.addressline2}
                      label="Address Line 2: "
                    />
                  ) : null}
                  <CustomTextInput value={ULData?.city} label="City: " />
                  <CustomTextInput value={ULData?.region} label="Region: " />
                  <CustomTextInput
                    value={ULData?.postal_code}
                    label="Postal Code: "
                  />
                  <CustomTextInput value={ULData?.country} label="Country " />
                  <CustomTextInput
                    value={ULData?.source_language}
                    label="Source Language: "
                  />
                  <CustomTextInput
                    value={ULData?.target_language}
                    label="Target Language: "
                  />
                  {ULData?.security_clearence !== "null" ? (
                    <CustomTextInput
                      value={ULData?.security_clearence}
                      label="Security Clearance: "
                    />
                  ) : null}
                  {/* <object data= {ULData?.upload_cv?.data.data} type="application/pdf" width="100%" height="1000px">
                </object> */}
                  <img src="" alt="" />
                  {/* <Button onClick= {`/admin/view-unregistered-linguist-cv/${ULData?._id}`} variant= 'contained' color= 'primary'>Preview CV</Button> */}
                  <Button
                    onClick={() =>
                      history.push(
                        `/employee/view-unregistered-linguist-cv/${ULData?._id}`
                      )
                    }
                    variant="contained"
                    color="primary"
                  >
                    {/* <Link
                      color="white"
                      to={`/admin/view-unregistered-linguist-cv/${ULData?._id}`}
                    > */}
                    Preview CV
                    {/* </Link> */}
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>

        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
            <Notes />
          </GridItem>
        </GridContainer>
        <br />
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
            <Message />
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default ViewUnregisteredLinguists;
