import React, { useState } from "react";
import { Link } from "react-router-dom";

// Material Core Components
import CssBaseline from "@material-ui/core/CssBaseline";

// Material Icons
import Sidebar from "components/Sidebar/Sidebar";
import Navbar from "components/Navbar/Navbar";
import { Typography } from "@material-ui/core";

// Custom Styles
import useStyles from "assets/jss/layouts/style";

function MasterLayout(props) {
  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary">
        {"© "}
        {/* <a className= {classes.footerLink} color="inherit" target= '_blank' href="https://home.linguistscollective.com/"> */}
        Linguists Collective Ltd<sup>®</sup> {new Date().getFullYear()} - All
        Rights Reserved - Version 02.450
      </Typography>
    );
  }
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    // <Manager>
    <div style={{ userSelect: "none" }} className={classes.root}>
      <CssBaseline />
      <Navbar
        handleDrawerToggle={handleDrawerToggle}
        layoutType={props.layoutType}
      />

      <Sidebar
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        layoutType={props.layoutType}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
        <footer
          className={classes.footer}
          style={{
            textAlign: "center",
          }}
        >
          {!["LINGUIST", "SPPO", "CASH_CLIENT", "ACCOUNT_CLIENT"].includes(
            props.layoutType
          ) ? (
            <p>
              <Copyright /> <a href="/admin/dashboard"> Dashboard</a> |
              <a href="/landing-page/terms-and-conditions" target="_blank">
                {" "}
                T&C
              </a>{" "}
              |
              <a href="/landing-page/privacy-policy" target="_blank">
                {" "}
                Privacy Policy
              </a>{" "}
              |
              <a href="/" target="_blank">
                {" "}
                Home
              </a>
            </p>
          ) : null}
          {props.layoutType === "CASH_CLIENT" ? (
            <p>
              <Copyright /> 
              <a
                href="/cash-client/service-agreement-of-cash-client"
              >
                {" "}
                Service Agreement{" "}
              </a>
              |
              <a href="/landing-page/terms-and-conditions" target="_blank">
                {" "}
                T&C{" "}
              </a>
              |<Link to="/cash-client/dashboard"> Dashboard </Link>|
              <a href="/" target="_blank">
                {" "}
                Home{" "}
              </a>
            </p>
          ) : null}

          {props.layoutType === "ACCOUNT_CLIENT" ? (
            <p>
              <Copyright /> 
              <a
                href="/account-client/service-agreement-of-account-client"
              >
                {" "}
                Service Agreement{" "}
              </a>
              |
              <a href="/landing-page/terms-and-conditions" target="_blank">
                {" "}
                T&C{" "}
              </a>
              |<Link to="/account-client/dashboard"> Dashboard </Link>|
              <a href="/" target="_blank">
                {" "}
                Home{" "}
              </a>
            </p>
          ) : null}

          {props.layoutType === "LINGUIST" ? (
            <p>
              <Copyright /> 
              <a
                href="/linguist/service-agreement-of-working-with-linguists-collective"
              >
                {" "}
                Service Agreement{" "}
              </a>
              |
              <a href="/landing-page/terms-and-conditions" target="_blank">
                {" "}
                T&C{" "}
              </a>
              |<Link to="/linguist/dashboard"> Dashboard </Link>|
              <a href="/" target="_blank">
                {" "}
                Home{" "}
              </a>
            </p>
          ) : null}
          {props.layoutType === "SPPO" ? (
            <p>
              <Copyright />
              <a
                href="/sppo/partnership-agreement-of-working-with-linguists-collective"
              >
                {" "}
                Partnership Agreement{" "}
              </a>
              |
              <a href="/landing-page/terms-and-conditions" target="_blank">
                {" "}
                T&C{" "}
              </a>
              |<Link to="/sppo/dashboard"> Dashboard </Link>|
              <a href="/" target="_blank">
                {" "}
                Home{" "}
              </a>
            </p>
          ) : null}
        </footer>
      </main>
    </div>
    // </Manager>
  );
}

export default MasterLayout;
