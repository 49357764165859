import { Fragment, useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { PASS_REGEX } from "data";

// Material Components
import Button from "@material-ui/core/Button";
import CustomPassword from "components/Common/CustomPassword";
import defaultAxios from "utils/axios";
import { ToastProvider, useToasts } from "react-toast-notifications";

const LinguistSetPassword = ({ token }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [notRegex, setNotRegex] = useState(false);

  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };
  const handleNewChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
    if (e.target.value !== "" && PASS_REGEX.test(e.target.value)) {
      setNotRegex(false);
    } else {
      setNotRegex(true);
    }
  };
  const handleSubmit = async () => {
    const { newPassword, confirmPassword } = passwords;

    const sendData = {
      resetPasswordLink: token,
      newPassword,
      confirmPassword,
    };

    setLoading(true);

    try {
      // Call api

      const response = await defaultAxios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/reset-password`,
        sendData
      );

      // After success, either redirect or show message
      addToast(
        "Your password has been reset. Please use new password to log in.",
        { appearance: "success" }
      );
      history.push("/linguists-login"); // Linguist
      setLoading(false);
    } catch (error) {
      // Show error message if any

      // error
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <CustomPassword
        label="New Password"
        name="newPassword"
        value={passwords.newPassword}
        onChange={handleNewChange}
        notRegex={notRegex}
      />
      <CustomPassword
        label="Confirm Password"
        name="confirmPassword"
        value={passwords.confirmPassword}
        onChange={handleChange}
        validation={passwords.newPassword !== passwords.confirmPassword}
      />
      <Button
        color="primary"
        variant="contained"
        style={{
          marginTop: "15px",
        }}
        onClick={handleSubmit}
        disabled={
          !passwords.newPassword ||
          !passwords.confirmPassword ||
          loading ||
          passwords.newPassword !== passwords.confirmPassword ||
          notRegex
        }
      >
        {loading ? "Submitting" : "Submit"}
      </Button>
    </Fragment>
  );
};

export default LinguistSetPassword;
