import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import {
  LANGUAGE_DATA,
  QUOTE_REQUEST_FOR_SEM_UI,
  TRANSLATION_QUALIFICATION,
  INTERPRETING_QUALIFICATION,
  OTHER_LANG_TEST,
  PROFESSIONAL_MEMBERSHIP_SEM_UI,
  MOTHER_LANG,
} from "data";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import Axios from "utils/axios";
import Cookies from "js-cookie";

const ProfessionalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updateProfileReducer);

  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("source_language", data.source_language);
    fd.append("source_language_1", data.source_language_1);
    fd.append("source_language_2", data.source_language_2);
    fd.append("target_language", data.target_language);
    fd.append("target_language_1", data.target_language_1);
    fd.append("target_language_2", data.target_language_2);
    fd.append("language_combinations", data.language_combinations);
    fd.append("language_combinations_1", data.language_combinations_1 || "");
    fd.append("language_combinations_2", data.language_combinations_2 || "");
    fd.append("language_combinations_3", data.language_combinations_3 || "");
    fd.append("language_combinations_4", data.language_combinations_4 || "");
    fd.append("ticking_relevant", data.ticking_relevant || "");
    fd.append(
      "interpreting_qualification",
      data.interpreting_qualification || ""
    );
    fd.append(
      "translation_qualification",
      data.translation_qualification || ""
    );
    fd.append("total_hours_of_proven", data.total_hours_of_proven || "");
    fd.append(
      "total_days_words_of_proven",
      data.total_days_words_of_proven || ""
    );
    fd.append(
      "professional_qualification",
      data.professional_qualification || ""
    );
    fd.append(
      "professional_membership_number_level",
      data.professional_membership_number_level || ""
    );
    fd.append("professional_membership", data.professional_membership || "");

    try {
      const response = await Axios.put(
        `/api/manager-admin/update-linguist/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          {edit.value ? (
            <CustomTextInput
              name="source_language"
              onChange={handleChange}
              label="Mother Tongue / Source / Native Language(1)"
              select={MOTHER_LANG}
              svalue={data.source_language}
            />
          ) : (
            <CustomTextInput
              name="source_language"
              label="Mother Tongue / Source / Native Language(1)"
              value={data.source_language}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              name="source_language_1"
              onChange={handleChange}
              label="Mother Tongue / Source / Native Language(2)"
              select={MOTHER_LANG}
              svalue={data.source_language_1}
            />
          ) : (
            <CustomTextInput
              name="source_language_1"
              label="Mother Tongue / Source / Native Language(2)"
              value={data.source_language_1}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              name="source_language_2"
              onChange={handleChange}
              label="Mother Tongue / Source / Native Language(3)"
              select={MOTHER_LANG}
              svalue={data.source_language_2}
            />
          ) : (
            <CustomTextInput
              name="source_language_2"
              label="Mother Tongue / Source / Native Language(3)"
              value={data.source_language_2}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              onChange={handleChange}
              name="target_language"
              label="Target Language(1)"
              select={MOTHER_LANG}
              svalue={data.target_language}
            />
          ) : (
            <CustomTextInput
              name="target_language"
              label="Target Language(1)"
              value={data.target_language}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              onChange={handleChange}
              name="target_language_1"
              label="Target Language(2)"
              select={MOTHER_LANG}
              svalue={data.target_language_1}
            />
          ) : (
            <CustomTextInput
              name="target_language_1"
              label="Target Language(2)"
              value={data.target_language_1}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              onChange={handleChange}
              name="target_language_2"
              label="Target Language(3)"
              select={MOTHER_LANG}
              svalue={data.target_language_2}
            />
          ) : (
            <CustomTextInput
              name="target_language_2"
              label="Target Language(3)"
              value={data.target_language_2}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              onChange={handleDropDown}
              name="language_combinations"
              label="List all other languages combinations (max 5)"
              data={LANGUAGE_DATA}
              dropdown={true}
            />
          ) : (
            <CustomTextInput
              label="List all other languages combinations (max 5)"
              value={data.language_combinations}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              onChange={handleDropDown}
              name="ticking_relevant"
              label="Please subscribe by ticking relevant boxes"
              data={QUOTE_REQUEST_FOR_SEM_UI}
              dropdown={true}
            />
          ) : (
            <CustomTextInput
              label="Please subscribe by ticking relevant boxes"
              value={data.ticking_relevant}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              name="interpreting_qualification"
              onChange={handleChange}
              svalue={data.interpreting_qualification}
              label="Interpreting Qualification (s)"
              select={INTERPRETING_QUALIFICATION}
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              label="Interpreting Qualification (s)"
              value={data.interpreting_qualification}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              name="translation_qualification"
              onChange={handleChange}
              svalue={data.translation_qualification}
              label="Translation Qualification (s)"
              select={TRANSLATION_QUALIFICATION}
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              label="Translation Qualification (s)"
              value={data.translation_qualification}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              name="other_language_test"
              onChange={handleChange}
              label="Other language test, qualification and or CPD (s)"
              select={OTHER_LANG_TEST}
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              label="Other language test, qualification and or CPD (s)"
              value={data.other_language_test}
            />
          )}

          <CustomTextInput
            name="total_days_words_of_proven"
            onChange={handleChange}
            label="Total hours of proven PSI experience"
            edit={edit.value}
            value={data.total_days_words_of_proven}
          />
          <CustomTextInput
            name="total_hours_of_proven"
            onChange={handleChange}
            label="Total Job days of proven PST work experience"
            edit={edit.value}
            value={data.total_hours_of_proven}
          />
          <CustomTextInput
            name="professional_qualification"
            onChange={handleChange}
            label="Please list any other professional qualifications and experiences"
            edit={edit.value}
            value={data.professional_qualification}
          />

          {edit.value ? (
            <CustomTextInput
              onChange={handleDropDown}
              name="professional_membership"
              label="Professional Membership"
              data={PROFESSIONAL_MEMBERSHIP_SEM_UI}
              dropdown={true}
            />
          ) : (
            <CustomTextInput
              label="Professional Membership"
              value={data.professional_membership}
            />
          )}

          <CustomTextInput
            name="professional_membership_number_level"
            onChange={handleChange}
            label="Professional membership and level"
            edit={edit.value}
            value={data.professional_membership_number_level}
          />

          <a href={`/employee/upload-linguist-documents/${data._id}`}>
            {" "}
            Upload Documents
          </a>
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default ProfessionalDetails;
