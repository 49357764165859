import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import {
  bank_swift_code_DATA,
  bank_swift_code_DATA_SEM_UI,
  GENDER_DATA,
} from "data";
import moment from "moment";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("EMPtoken");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("bank_account_name", data.bank_account_name || "");
    fd.append("bank_account_number", data.bank_account_number || "");
    fd.append("bank_branch_name", data.bank_branch_name || "");
    fd.append("aba", data.aba || "");
    fd.append("bank_sort_code", data.bank_sort_code || "");
    fd.append("branch_code", data.branch_code || "");
    fd.append("institution_number", data.institution_number || "");
    fd.append("bank_iban", data.bank_iban || "");
    fd.append("bank_swift_code", data.bank_swift_code || "");
    fd.append("ifsc_code", data.ifsc_code || "");
    fd.append("bank_name", data.bank_name || "");
    fd.append("bank_address", data.bank_address || "");
    fd.append("additional_info", data.additional_info || "");
    fd.append("prefered_currency", data.prefered_currency || "");

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/employee/profile/update`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="bank_account_name"
            onChange={handleChange}
            label="Bank account holder's full name"
            edit={edit.value}
            value={data.bank_account_name}
          />
          <CustomTextInput
            name="bank_account_number"
            onChange={handleChange}
            label="Account Number"
            edit={edit.value}
            value={data.bank_account_number}
          />
          <CustomTextInput
            name="bank_branch_name"
            onChange={handleChange}
            label="Branch name"
            edit={edit.value}
            value={data.bank_branch_name}
          />
          <CustomTextInput
            name="aba"
            onChange={handleChange}
            label="ABA"
            edit={edit.value}
            value={data.aba}
          />
          <CustomTextInput
            name="bank_sort_code"
            onChange={handleChange}
            label="Sort code"
            edit={edit.value}
            value={data.bank_sort_code}
          />
          <CustomTextInput
            name="institution_number"
            onChange={handleChange}
            label="Institution Number"
            edit={edit.value}
            value={data.institution_number}
          />
          <CustomTextInput
            name="branch_code"
            onChange={handleChange}
            label="Branch Code"
            edit={edit.value}
            value={data.branch_code}
          />
          <CustomTextInput
            name="bank_iban"
            onChange={handleChange}
            label="IBAN"
            edit={edit.value}
            value={data.bank_iban}
          />
          <CustomTextInput
            name="bank_swift_code"
            onChange={handleChange}
            label="Swift / BIC code"
            edit={edit.value}
            value={data.bank_swift_code}
          />

          <CustomTextInput
            name="ifsc_code"
            onChange={handleChange}
            label="Sort Code / IFSC Code"
            edit={edit.value}
            value={data.ifsc_code}
          />
          <CustomTextInput
            name="bank_name"
            onChange={handleChange}
            label="Name of the bank"
            edit={edit.value}
            value={data.bank_name}
          />
          <CustomTextInput
            name="bank_address"
            onChange={handleChange}
            label="Address of bank branch"
            edit={edit.value}
            value={data.bank_address}
          />

          <CustomTextInput
            name="additional_info"
            onChange={handleChange}
            label="Additional information"
            edit={edit.value}
            value={data.additional_info}
          />
          <CustomTextInput
            name="prefered_currency"
            onChange={handleChange}
            label="Preferred currency"
            edit={edit.value}
            value={data.prefered_currency}
          />
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
