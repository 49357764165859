import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCarpDetails } from "redux/actions/admin/controller";

import EditableText from "./EditableText";

import useCarpCardStyles from "./styles";
import SubDetails from "./Subdetails";

const CarpCard = ({ readOnly }) => {
  const classes = useCarpCardStyles();
  const dispatch = useDispatch();

  const { carpDetails } = useSelector((state) => state.adminControlData);

  useEffect(() => {
    dispatch(getCarpDetails());
  }, []);

  const {
    cancelChargeHours,
    clientCharge,
    cancelInterpretingHours,
    clientCancelHours,
    onsiteCancellation,
    directBookingHours,
    directBookingCharge,
    clientCancelCharge,
    onsiteCancellationCharge,
    percentOfTotalCancel,
    hoursOfTotalCancel,
    percentOfTotalEstimate,
  } = carpDetails;

  return (
    <Fragment>
      <div className={classes.root}>
        <h3>Client/s (for confirmed jobs only)</h3>
        <ul>
          <li>
            Client can cancel interpreting booking without charge{" "}
            <EditableText
              text={cancelChargeHours}
              readOnly={readOnly}
              name="cancelChargeHours"
            />{" "}
            hours before the booking starts for local job.
          </li>
          <li>
            Client will be charged{" "}
            <EditableText
              text={clientCharge}
              readOnly={readOnly}
              name="clientCharge"
            />
            % of the invoice for interpreting job cancelling with less than{" "}
            <EditableText
              text={cancelInterpretingHours}
              readOnly={readOnly}
              name="cancelInterpretingHours"
            />
            hours notice.
          </li>
          <li>
            Client will be charged{" "}
            <EditableText
              text={clientCancelCharge}
              readOnly={readOnly}
              name="clientCancelCharge"
            />
            % for cancelling interpreting job less than{" "}
            <EditableText
              text={clientCancelHours}
              readOnly={readOnly}
              name="clientCancelHours"
            />
            hours notice.
          </li>
          <li>
            Client will be charged{" "}
            <EditableText
              text={onsiteCancellationCharge}
              readOnly={readOnly}
              name="onsiteCancellationCharge"
            />{" "}
            % for onsite cancellation or{" "}
            <EditableText
              text={onsiteCancellation}
              readOnly={readOnly}
              name="onsiteCancellation"
            />
            hours before an interpreting assignment start time.
          </li>
          <li>
            In the event of any cost occurred from hotel or flight due to
            cancellation, client must pay.
          </li>
          <li>
            Client will be charged{" "}
            <EditableText
              text={percentOfTotalCancel}
              readOnly={readOnly}
              name="percentOfTotalCancel"
            />
            % of the total for cancellation of a translation assignment or
            project up to{" "}
            <EditableText
              text={hoursOfTotalCancel}
              readOnly={readOnly}
              name="hoursOfTotalCancel"
            />{" "}
            hours before the deadline (if any) as minimum, maximum cancellation
            charge can be up to{" "}
            <EditableText
              text={percentOfTotalEstimate}
              readOnly={readOnly}
              name="percentOfTotalEstimate"
            />
            % of the total estimate. Discount may be applied on linguist’s
            discretion. This depends on how much work linguist or the team may
            have already completed.{" "}
          </li>
          <li>
            For large translation project, LC may introduce separate agreement.{" "}
          </li>
        </ul>
      </div>
      <div className={classes.root}>
        <h3>Associates (Freelancer)</h3>
        <ul>
          <li>
            Direct booking FTF and Remote job accepted by Associate can give
            back before{" "}
            <EditableText
              text={directBookingHours}
              readOnly={readOnly}
              name="directBookingHours"
            />{" "}
            hours on the ground of illness and personal emergency.
          </li>
          <li>
            Direct booking job accepted by Associate can give back before
            delivering with penalty of{" "}
            <EditableText
              text={directBookingCharge}
              readOnly={readOnly}
              name="directBookingCharge"
            />{" "}
            % of the total value{" "}
          </li>
          <li>
            On the ground of poor health (off sick) maximum 6 jobs can be
            returned a year without paying any penalty. If freelancer accept an
            assignment, LC and client expect freelancer to deliver them with
            high standard, punctuality and according to code of conduct.
          </li>
          <li>
            Linguist not allowed to return or cancel translation assignment, any
            unexpected circumstances will be considered case by case basis. This
            might depend on client’s discretion. Linguist may be charged fees
            for returning a translation assignment, failing to complete
            assignment within the deadline and accepting an assignment without
            having necessary competence to deliver the service.{" "}
          </li>
          <li>
            For large translation project, LC may introduce separate agreement.{" "}
          </li>
        </ul>
        <h3>Linguists Collective (LC)</h3>
        <ul>
          {/* <li>
            Cancel by admin –option to do full or partial refund to client/s.
          </li> */}
          <li>
            <SubDetails readOnly={readOnly} />
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default CarpCard;
