import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Material Core Components\
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import IconImg from "components/Sidebar/IconImg";
import Sicon from "assets/icons/settings.png";
// Material Icons

// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";
import useDropdownStyles from "assets/jss/components/dropDownStyles";
import Axios from "utils/axios";
import Cookies from "js-cookie";

const NavPerson = ({ children, layoutType }) => {
  const history = useHistory();

  const state = useSelector((state) => state);

  const classes = useNavbarStyles();
  const classesTwo = useDropdownStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  // TODO set logic based on logged in user

  const handleChange = () => {
    switch (layoutType) {
      case "ACCOUNT_CLIENT":
        history.push("/account-client/reset-password");
        break;

      case "CASH_CLIENT":
        history.push("/cash-client/reset-password");
        break;

      case "EMPLOYEE_CLIENT":
        history.push("/employee/reset-password");
        break;

      case "LINGUIST":
        history.push("/linguist/reset-password");
        break;

      case "SPPO":
        history.push("/sppo/reset-password");
        break;

      default:
        history.push("/admin/reset-password");
        break;
    }
  };

  const handleLogOut = async (e) => {
    switch (layoutType) {
      case "ACCOUNT_CLIENT": {
        const response = await Axios.get("/api/accountclient/signout", {
          headers: {
            Authorization: `Bearer ${Cookies.get("BACtoken")}`,
          },
        });
        Cookies.remove("BACtoken");
        Cookies.remove("BACuser");
        localStorage.removeItem("BACuser");
        sessionStorage.clear();
        history.push("/account-client-login");
        break;
      }

      case "CASH_CLIENT": {
        const response = await Axios.get("/api/cashclient/signout", {
          headers: {
            Authorization: `Bearer ${Cookies.get("CACtoken")}`,
          },
        });
        Cookies.remove("CACtoken");
        Cookies.remove("CACuser");
        localStorage.removeItem("CACuser");
        sessionStorage.clear();
        Cookies.set("isAuth", false);
        history.push("/cash-client-login");
        break;
      }
      case "EMPLOYEE_CLIENT": {
        const response = await Axios.get("/api/employee/signout", {
          headers: {
            Authorization: `Bearer ${Cookies.get("EMPtoken")}`,
          },
        });
        Cookies.remove("EMPtoken");
        history.push("/employee-login");
        break;
      }
      case "LINGUIST": {
        const response = await Axios.get("/api/linguists/signout", {
          headers: {
            Authorization: `Bearer ${Cookies.get("LINtoken")}`,
          },
        });
        Cookies.remove("LINtoken");
        history.push("/linguists-login");
        break;
      }
      case "SPPO": {
        const response = await Axios.get("/api/sppo/signout", {
          headers: {
            Authorization: `Bearer ${Cookies.get("SPPOtoken")}`,
          },
        });
        Cookies.remove("SPPOtoken");
        history.push("/sppo/login");

        break;
      }
      default: {
        const response = await Axios.get("/api/manager-admin/signout", {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        });
        Cookies.remove("token");
        history.push("/admin/login");
        break;
      }
    }
  };

  return (
    <Fragment>
      <IconButton aria-label="Person" color="primary" onClick={handleClick}>
        <div className={classes.imgDiv}>
          <IconImg className={classes.icons} icon={Sicon} />
        </div>
      </IconButton>
      {/* <IconButton aria-label="Person" color="primary" onClick={handleClick}>
        <Settings className={classes.icons} />
      </IconButton> */}
      <div>
        <Popper
          open={Boolean(open)}
          anchorEl={anchorEl}
          placement="bottom-end"
          className={classesTwo.toolTip}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      className={classesTwo.dropdownItem}
                      onClick={handleChange}
                    >
                      Reset Password
                    </MenuItem>
                    <MenuItem
                      className={classesTwo.dropdownItem}
                      onClick={handleLogOut}
                    >
                      Log Out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </Fragment>
  );
};

export default NavPerson;
