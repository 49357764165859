import React, { Fragment } from "react";

// Custom Components
import ViewBIClients from "components/Auth/ViewBIClients/LinguistsCollectiveBooking";

const ViewBIClientsPage= () => {
  return (
    <Fragment>
      <ViewBIClients />
    </Fragment>
  );
};

export default ViewBIClientsPage
