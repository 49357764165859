import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader, Modal } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Button from "@material-ui/core/Button";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import TaskLists from "components/Admin/TaskLists/TaskLists";
import AddTask from "components/Admin/TaskLists/AddTask";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // ["@media (max-width:768px)"]: {
    //   display: "inline-block",
    //   padding: "0px",
    // },

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      // ["@media (max-width:768px)"]: {
      //   margin: "5px",
      //   width: "100%",
      // },
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

const useStyles = makeStyles(styles);
const AssignmentPage = () => {
  const button = useStyles();
  const classes = useDashboardStyles();
  const [open, setOpen] = useState(false);

  const [task_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminAccess(response?.data.task_panel);
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        {/* add task form */}
        <Modal
          size="large"
          closeIcon
          open={open}
          onClose={() => setOpen(false)}
        >
          <Header icon="tasks" content="Assign Task to Personnel(s)" />
          <Modal.Content>
            <AddTask />
          </Modal.Content>
        </Modal>
        {/* end of add task form */}

        <GridContainer>
          {task_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>Task List</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={button.root}>
                      <Button variant="outlined" onClick={() => setOpen(true)}>
                        + Add new task
                      </Button>
                    </div>
                    <IconButton
                      color="inherit"
                      onClick={() => history.goBack()}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                </CardHeader>
                <CardBody>
                  <TaskLists />
                </CardBody>
              </Card>
            </GridItem>
          ) : task_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
