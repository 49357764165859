import Axios from "axios";
import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
// Custom Components
import CommonCard from "components/Common/CommonCard";
import { Button } from "semantic-ui-react";
import CustomDateTime from "components/Common/CustomDateTime";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
const Bookings = () => {
  const classes = useDashboardStyles();

  const token = Cookies.get("SPPOtoken");

  const [deadlineCom, setDeadline] = useState(null);
  const [priorityCom, setPriority] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showNote, setShowNote] = React.useState(false);
  const [viewNote, setViewNote] = React.useState(false);

  const [totalnote, setTotalNote] = React.useState(false);
  const [values, setValues] = useState({ reload: false });

  const [pay, setPay] = React.useState({
    admin_note: "",
    mainPriority: "",
    deadline: "",
  });
  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };
  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const handleNote = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/write-colleague-note`,
        {
          admin_note: pay.admin_note,
          mainPriority: priorityCom,
          deadline: deadlineCom,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "Note successfully added",
      });
      setPay({ ...pay, admin_note: "", loader: false });
      setEdit({ ...edit, loader: false });
      getTotalNote();
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleVeiwNote = async () => {
    history.push(`/sppo/colleague-notes`);
  };

  const getTotalNote = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/count-colleague-note`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalNote();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowNote(false);
  };

  const handleShow = () => {
    setShowNote(!showNote);
  };

  return (
    <Card
      style={{
        textAlign: "start",
      }}
    >
      <CardHeader
        color="rose"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className={`${classes.cardCategory} ${classes.cardTitleWhite}`}>
          {" "}
          Our Notes and To Do List
        </p>
      </CardHeader>
      <CardBody>
        <TextField
          onChange={handleChange}
          value={pay.admin_note}
          id="standard-textarea"
          label="Type here... (max 432 ch.)"
          placeholder="Write note for your colleagues here..."
          multiline
          rows={4}
          inputProps={{ maxLength: 432 }}
          name="admin_note"
          required
          style={{ marginBottom: 15, width: 100 + "%" }}
        />

        <CustomAutoSelect
          required
          label="Priority"
          width="100%"
          data={["Note", "Low", "Medium", "High"]}
          onChange={(e, value) => setPriority(value)}
        />
        <CustomDateTime
          label="Deadline"
          width="100%"
          onChange={(e) => setDeadline(e.target.value)}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
            marginBottom: 20,
          }}
        >
          <Button color="pink" onClick={handleVeiwNote}>
            View Note ({totalnote})
          </Button>

          <Button
            disabled={!pay.admin_note || !priorityCom}
            onClick={handleNote}
            color="pink"
          >
            Save Note
            {edit.loader && <div class="ui active inline loader"></div>}
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default Bookings;
