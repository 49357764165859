import React from "react";

// Material Components
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

// Custom Components
import CustomFormControl from "./CustomFormControl";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomLabel from "./CustomLabel";
import { TextField } from "@material-ui/core";

const CustomTime = ({ label, width, required, moreInfo, ...rest }) => {
  const classes = useFormStyles();

  // const [selectedDate, setSelectedDate] = React.useState(value || null);

  // const handleDateChange = (date, value) => {
  //   setSelectedDate(date);
  //   onChange(value)
  // };
  return (
    <CustomFormControl width={width}>
      <TextField
        id="time"
        label= {<CustomLabel label={label} moreInfo={moreInfo} required= {required} />}
        type="time"
        {...rest}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />

    </CustomFormControl>
  );
};

export default CustomTime;
