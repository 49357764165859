import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
import history from "utils/history";
import queryString from "querystring";

// get cookie
export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};

export const isAuth = () => {
  if (process.browser) {
    const cookieChecked = getCookie("token");
    if (cookieChecked) {
      if (localStorage.getItem("user")) {
        return JSON.parse(localStorage.getItem("user"));
      } else {
        return false;
      }
    }
  }
};

// update user in local storage
export const updateUser = (user, next) => {
  if (process.browser) {
    if (localStorage.getItem("user")) {
      let auth = JSON.parse(localStorage.getItem("user"));
      auth = user;
      localStorage.setItem("user", JSON.stringify(auth));
      next();
    }
  }
};

export const getPositionList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-position`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removePosition = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove/position/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getManagerList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/managers`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAdminList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/admins`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeAdmin = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove/admin/adminID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeINV = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-invoice-by-LIN/invoiceID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const deleteLINDirectINV = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-linguist-invoice/invoiceID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const deleteClientSppoDirectINV = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-client-invoice/invoiceID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const deleteClientSppoDirectQuote = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-client-Quote/QuoteID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const deletePRCINV = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-prc-invoice-by-LIN/invoiceID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const deleteTimesheet = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-timesheet/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const deleteProjectReport = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-project-report/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const deletePRCQuote = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-private-client-Quote/quoteID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getCashclientList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/cash-clients`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAccountclientList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/account-clients`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getEmployeeList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/employees`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getInactiveEmployeeList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inactive-list/employees`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeEmployee = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-employee/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removecashclient = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-cash-client/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeLinguists = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-linguist/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeAccountclient = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-account-client/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getVerifiedLinguist = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/verified/Linguists`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getRegisteredLinguist = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/registered/Linguists`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getUnverifiedLinguist = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/unVerified/Linguists`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getSuspendLinguist = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/suspend/linguists`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getPregistrationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/pregistration`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getRefundList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/list-request-refund`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getProfessionalLinguist = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/professional-linguist-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getContractorLinguist = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/contractor-linguist-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getTrainerLinguist = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/trainer-linguist-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removePreregistration = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove/pregistration/preID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getUnverifiedSppo = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-unverifiedSppo`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


export const getInhouseClient = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-inhouse-client`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeInhouseclient = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-inhouse-client/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getPrivateClient = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-private-client`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchivePRC = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-private-client`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveINC = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-inhouse-client`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveBAC = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-account-clients`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveCAC = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-cash-clients`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveJobAdvert = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-archived-jobadvert`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveADM = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/archiveAdmin`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveINV = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-invoice-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchivePRCINV = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-prc-invoice-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveTimesheet = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-all-timesheet`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveProjectReport = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-all-project-report`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchivePRCQuote = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-archived-private-client-Quote`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveLINDirectINV = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-linguist-invoice`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveClientDirectINV = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-client-invoice`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveClientDirectQuote = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-client-quote`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveSPPODirectINV = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-sppo-invoice`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveEMP = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-employees`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveLIN = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-linguists`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getArchiveSPPO = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-Sppo`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removePrivateclient = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-private-client/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const archivePRC = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-private-client/clientID/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const archiveEMP = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-employee/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const accessEMP = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/access-employee-account/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const archiveINC = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-inhouse-client/clientID/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

// booking interpreting list
export const getCashtclientBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-interpreting-cashclient`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAccountclientBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-interpreting-accountclient`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// private client interpreting assignment list
//confirmed
export const getPrivateConfirmBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-confirm-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Invoice
export const getPrivateInvoiceBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-invoiced-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Completed
export const getPrivateCompletedBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-fullpaid-invoiced-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Closed
export const getPrivateClosedBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-closed-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Canceled
export const getPrivateCanceledBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-cancel-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// private client translation assignment list
//confirmed
export const getPrivateConfirmTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-confirmed-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Invoice
export const getPrivateInvoiceTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-invoiced-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Completed
export const getPrivateCompletedTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-fullpaid-invoiced-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Closed
export const getPrivateClosedTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-closed-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Canceled
export const getPrivateCanceledTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/private-cancel-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// confirmed inhouse assignment
export const getInhouseBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-confirm-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// canceled inhouse assignment
export const getInhouseCancelBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-canceled-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// assigned inhouse assignment
export const getAssignedInhouseBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-assigned-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// closed inhouse assignment
export const getClosedInhouseBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-closed-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// closed inhouse assignment
export const getInvoicedInhouseBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-invoiced-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// completed inhouse assignment
export const getCompletedInhouseBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-full-paid-invoiced-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// canceled inhouse assignment
export const getCanceledInhouseBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-canceled-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// Disputed inhouse assignment
export const getDisputedInhouseBookingList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-disputed-booking-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// confirmed translation inhouse assignment
export const getInhouseTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-confirmed-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// canceled translation inhouse assignment
export const getInhouseCancelTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-canceled-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// assigned translation inhouse assignment
export const getAssignedInhouseTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-assigned-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// closed translation inhouse assignment
export const getClosedInhouseTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-closed-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// closed translation inhouse assignment
export const getInvoicedInhouseTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-invoiced-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// completed translation inhouse assignment
export const getCompletedInhouseTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-full-paid-invoiced-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// canceled translation inhouse assignment
export const getCanceledInhouseTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-canceled-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// Disputed translation inhouse assignment
export const getDisputedInhouseTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-disputed-translation-list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getCashtclientTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-translation-cashclient`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAccountclientTranslationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-translation-accountclient`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getCashtclientQuotationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-quote-request-cashclient`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAccountclientQuotationList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/quote-request-list-BAC`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getRateCard = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/admin/rate_card`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getCacJobList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-jobadvert-cashclient`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getBacJobList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-jobadvert-accountclient`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getFrontJobList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-frontpage-jobadvert`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAcceptedFrontJobList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-accepted-frontpage-jobadvert`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getDeclinedFrontJobList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-declined-frontpage-jobadvert`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// contact request list and view
export const getContactRequest = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/contactRequest`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getLinQuestion = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/lin-question`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getSPPOQuestion = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/sppo-question`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getCACQuestion = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/cac-question`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getBACQuestion = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/bac-question`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeContact = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove/contactRequest/contactID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

// Subscription list and view
export const getSubscriber = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/subscriber`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeSubscriber = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/remove/subscriber/subscriberID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

// remove cookie
export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1,
    });
  }
};

// set localstorage
export const setLocalStorage = (key, value) => {
  if (process.browser) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// remove localstorage
export const removeLocalStorage = (key) => {
  if (process.browser) {
    localStorage.removeItem(key);
  }
};

export const signout = (next) => {
  removeCookie("token");
  removeLocalStorage("user");
  next();

  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/signout`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      console.log("Signout Success");
    })
    .catch((err) => console.log(err));
};

export const handleResponse = (response) => {
  if (response.status === 401) {
    signout(() => {
      history.push({
        pathname: "/admin/login",
        query: {
          message: "Your session is expired. Please signin again",
        },
      });
    });
  }
};

export const getProfile = (token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/dashboard`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const update = (token, user) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/profile/update`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: user,
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const AUSDAccount = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-AUSDAccount`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const CADAccount = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-CADAccount`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const EUROAccount = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-EUROAccount`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const HungarianForintAccount = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-HungarianForintAccount`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const NewRomanianAccount = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-NewRomanianAccount`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const NewzealandDollarAccount = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-NewzealandDollarAccount`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const SingaporeDollarAccount = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-SingaporeDollarAccount`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const TurkishLiraAccount = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-TurkishLiraAccount`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const UKGBPAccount = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-UKGBPAccount`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getUSDAccount = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-USDAccount`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const listRegisteredLinguistSearch = (params) => {
  let query = queryString.stringify(params);
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/search/registered-linguists?${query}`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const listUnregisteredLinguistSearch = (params) => {
  let query = queryString.stringify(params);
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/search/unregistered-linguists?${query}`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const archivePRCQuote = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-private-client-Quote/quoteID/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const accessManagerAccount = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/access-manager-account/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const accessSPPOAccount = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/access-Sppo/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const disableSPPOAccount = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/disable-Sppo/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const archiveManagerAccount = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-manager-account/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const archiveInvoiceByLIN = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-invoice-by-LIN/invoiceID/${_id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getInterpertingInvoiceList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-interpreting-invoice-by-LIN`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getTranslationInvoiceList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-translation-invoice-by-LIN`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getQuotationInvoiceList = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-quotation-invoice-by-LIN`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getCLA = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/CLA`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeLead = (_id, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete/claID/${_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};
