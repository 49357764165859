
import React from 'react'
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Notes from './List'
import Message from './MessageList'
import EmailMessage from './EmailMessageList'

const PersonalDetails = ({ id }) => {
  const classes = useDashboardStyles();
  return (

    <GridItem xs={12} sm={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <Notes id={id} />
        </CardBody>
        <CardBody>
          <Message id={id} />
        </CardBody>
        <CardBody>
          <EmailMessage id={id} />
        </CardBody>
      </Card>
    </GridItem>

  )
}

export default PersonalDetails
