import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import { Button } from "semantic-ui-react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Axios from "axios";
import Cookies from "js-cookie";
import ReactToExcel from "react-html-table-to-excel";
import moment from "moment";
import SearchView from "./SearchView/SearchView/SearchView";
import { useToasts } from "react-toast-notifications";
import Action from "./Action";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
  button: {
    background: "#e0e1e2 none",
    border: 0,
    color: "#ffff",
    padding: (20, 11),
    marginRight: 10,
    marginLeft: 10,
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
    borderRadius: "3pt",
    fontSize: 13,
    color: "rgba(0,0,0,.6)",
  },
});

export default function StickyHeadTable() {
  const token = Cookies.get("token");
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    List: [],
    removed: false,
    reload: false,
    AdminID: "",
  });

  const { name, error, success, List, removed, reload } = values;

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async (e) => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/list-request-refund`
      ),
    ]).then(
      Axios.spread((dashboard, unconfirmedJobs) => {
        setValues({
          ...values,
          List: unconfirmedJobs.data,
          AdminID: dashboard.data.id,
        });
      })
    );
  };

  const a = List?.map((uc) => uc);
  const exportPDF = (e) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    var totalPages = doc.internal.getNumberOfPages();
    var i;

    for (i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
      // doc.setTextColor(150);
      doc.text(
        50,
        doc.internal.pageSize.height - 30,
        "Strictly Company Confidential © linguistscollective.com."
      );
    }

    const title = "Refund List";
    const headers = [
      [
        "Id",
        "First\nName",
        "Last\nName",
        "Email",
        "Mobile\nNumber",
        "Registration\nDate",
        "Country",
        "City",
        "Source\nLanguage",
        "Target\nLanguage",
      ],
    ];

    const data = List.map((list) => [
      list.id,
      list.first_name,
      list.last_name,
      list.primary_email,
      list.mobile_no,
      moment(list.createdAt).format("DD/MM/YYYY") +
        "\n" +
        moment(list.createdAt).format("HH:mm"),
      list.country,
      list.city,
      list.source_language,
      list.target_language,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        valign: "middle",
        // halign: 'justify'
      },
      columnStyles: { 0: { fillColor: [138, 43, 226], textColor: "white" } },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    var string = doc.output("datauristring");
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    var x = window.open();
    x.document.open();
    x.document.write(embed);
    x.document.close();
  };

  const handleSearch = async () => {
    let entry = List.filter(
      (entry) =>
        entry.id == `${SearchText}` ||
        entry.client_ref == SearchText ||
        // || entry.client_ref == SearchText
        entry.client_email == SearchText ||
        // || entry.client_email == SearchText
        entry.client_mobile_no == SearchText ||
        // || entry.client_mobile_no == SearchText
        entry.lin_ref == SearchText ||
        // || entry.lin_ref == SearchText
        entry.assignment_ref == SearchText ||
        // || entry.assignment_ref == SearchText
        entry.payment_ref == SearchText ||
        // || entry.payment_ref == SearchText
        entry.payment_date == SearchText ||
        // || entry.payment_date == SearchText
        entry.paid_currency == SearchText ||
        // || entry.paid_currency == SearchText
        entry.paid_amount == SearchText ||
        // || entry.paid_amount == SearchText
        entry.refund_amount == SearchText ||
        // || entry.refund_amount == SearchText
        entry.refund_date == SearchText ||
        // || entry.refund_date == SearchText
        entry.refund_ref == SearchText
      // || entry.refund_ref == SearchText
    );
    try {
      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct keyword.", {
          appearance: "error",
        });
      } else if (entry.length != 0) {
        setValues({ ...values, List: entry, reload: false });
        addToast("Keyword found", { appearance: "success" });
        entry = [];
      } else {
        addToast("Keyword not found", { appearance: "error" });
      }
      if (values.List) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct Keyword.", {
          appearance: "error",
        });
        setShow(false);
      }
    }
  };

  return (
    <div>
      <SearchView
        load={loadList}
        SearchText={SearchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
        setShow={setShow}
      />

      {show ? (
        <div>
          {" "}
          <Paper className={classes.root}>
            <ReactToExcel
              // className = "btn"
              table="export"
              filename="excelFile"
              sheet="sheet 1"
              buttonText="Export CSV"
              className={classes.button}
            />

            <Button onClick={exportPDF}>Export PDF</Button>

            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" id="export">
                <TableHead>
                  <TableRow>
                    <TableCell>Client Ref</TableCell>
                    <TableCell>Client Mobile No</TableCell>
                    <TableCell>Assignment Ref</TableCell>
                    <TableCell>Linguist Ref</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell>Payment Ref</TableCell>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Paid Amount</TableCell>
                    <TableCell>Request Date</TableCell>
                    <TableCell>Refund Amount</TableCell>
                    <TableCell>Refund Date</TableCell>
                    <TableCell>Refund Ref</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {List.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((list) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={list._id}
                    >
                      <TableCell>{list.client_ref} </TableCell>
                      <TableCell>{list.client_mobile_no}</TableCell>
                      <TableCell>{list.assignment_ref}</TableCell>
                      <TableCell>{list.lin_ref}</TableCell>
                      <TableCell>{list.reason}</TableCell>
                      <TableCell>
                        Intent ID:{" "}
                        {list.payment_intent ? list.payment_intent : "N/A"}{" "}
                        <br />
                        Charge ID: {list.charge_id ? list.charge_id : "N/A"}
                      </TableCell>
                      <TableCell>{list.payment_date}</TableCell>
                      <TableCell>
                        {list.paid_currency?.substring(0, 3)} {list.paid_amount}
                      </TableCell>
                      <TableCell>
                        {Moment(list.createdAt)
                          .tz("Europe/London")
                          .format("DD MMM YYYY - kk:mm:ss")}
                      </TableCell>
                      <TableCell>{list.refund_amount}</TableCell>
                      <TableCell>{list.refund_date}</TableCell>
                      <TableCell>{list.refund_ref}</TableCell>
                      <TableCell>{list.status}</TableCell>
                      <TableCell>
                        <Action
                          load={loadList}
                          id={list?._id}
                          clientID={list?.client_ref}
                          clientEmail={list?.client_email}
                          assignment_ref={list?.assignment_ref}
                          assignmentID={list?.assignmentID}
                          currency={list?.paid_currency?.substring(0, 3)}
                          estimate={list?.paid_amount}
                          AdminID={values.AdminID}
                          charge_id={list?.charge_id}
                          paidby={list?.paidby}
                          refunded={list?.refunded}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={List.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      ) : null}
    </div>
  );
}
