import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Axios from "axios";
import swal from "sweetalert";
import CustomInput from "components/Common/CustomInput";
import CustomSelect from "components/Common/CustomSelect";
import { Button } from "semantic-ui-react";
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomRadio from "components/Common/CustomRadio";
import { Divider, Input } from "@material-ui/core";
import CustomPassword from "components/Common/CustomPassword";
import { MAIL_SERVER_TYPE, SIMPLE_QS, SSL_OPTIONS } from "data";

const Details = () => {
  const token = Cookies.get("token");

  const [details, setDetails] = useState({
    use_smtp_server: "",
    mail_server_type: "",
    incomming_server: "",
    outgoing_server: "",
    imap_port: "",
    pop3_port: "",
    smtp_port: "",
    username: "",
    password: "",
    ssl_option: "",
    loader: false,
  });

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/get-smtp-settings`
      );
      setDetails(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/post-smtp-settings`,
        {
          use_smtp_server: details.use_smtp_server || "",
          mail_server_type: details.mail_server_type || "",
          incomming_server: details.incomming_server || "",
          outgoing_server: details.outgoing_server || "",
          imap_port: details.imap_port || "",
          pop3_port: details.pop3_port || "",
          smtp_port: details.smtp_port || "",
          username: details.username || "",
          password: details.password || "",
          ssl_option: details.ssl_option || "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDetails({
        ...details,
        loader: false,
        mail_server_type: "",
        incomming_server: "",
        outgoing_server: "",
        imap_port: "",
        pop3_port: "",
        smtp_port: "",
        username: "",
        password: "",
      });
      swal({
        text: "SMTP Notification Settings Updated!",
        buttons: "Ok",
        icon: "success",
      }).then(function () {
        window.location = "/admin/smtp-notification-setting";
      });
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  return (
    <div>
      <CustomRadio
        onChange={handleChange}
        label="Use SMTP Server"
        data={SIMPLE_QS}
        width="100%"
        name="use_smtp_server"
        value={details.use_smtp_server}
      />

      <Divider style={{ marginTop: "24px", backgroundColor: "black" }} />

      <CustomSelect
        onChange={handleChange}
        label="Mail Server Type"
        name="mail_server_type"
        data={MAIL_SERVER_TYPE}
        width="100%"
        value={details.mail_server_type}
      />

      <CustomInput
        onChange={handleChange}
        label="Incoming Server e.g. mail.linguistscollective.com"
        width="100%"
        name="incomming_server"
        value={details.incomming_server}
        required
      />

      <div
        style={{ display: "flex", width: "100%", margin: "12px auto 0 auto" }}
      >
        <Input
          onChange={handleChange}
          value={details.imap_port}
          style={{ marginRight: "10px" }}
          placeholder="IMAP Port"
          name="imap_port"
        />

        <Input
          onChange={handleChange}
          value={details.pop3_port}
          placeholder="POP3 Port"
          name="pop3_port"
        />
      </div>

      <Divider style={{ marginTop: "24px", backgroundColor: "black" }} />

      <CustomInput
        onChange={handleChange}
        value={details.outgoing_server}
        label="Outgoing Server e.g. mail.linguistscollective.com"
        width="100%"
        name="outgoing_server"
      />
      <div
        style={{ display: "flex", width: "100%", margin: "12px auto 0 auto" }}
      >
        <Input
          onChange={handleChange}
          value={details.smtp_port}
          style={{ marginRight: "10px" }}
          name="smtp_port"
          placeholder="SMTP Port*"
          required
        />
      </div>

      <Divider style={{ marginTop: "24px", backgroundColor: "black" }} />

      <CustomInput
        onChange={handleChange}
        value={details.username}
        label="Username e.g. you@email.com"
        width="100%"
        name="username"
        required
      />

      <CustomPassword
        onChange={handleChange}
        value={details.password}
        label="Password"
        name="password"
        width="100%"
        required
      />

      <CustomRadio
        onChange={handleChange}
        label="SSL Options"
        name="ssl_option"
        data={SSL_OPTIONS}
        width="100%"
        value={details.ssl_option}
      />

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          loading={details.loader}
          onClick={handleSubmit}
          disabled={
            !details.incomming_server ||
            !details.smtp_port ||
            !details.username ||
            !details.password
          }
          color="purple"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Details;
