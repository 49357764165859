import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Custom Components
import EditableText from "./EditableText";

// Others
import { getRatesDetails } from "redux/actions/admin/controller";

// Custom Styles
import useRateCardStyles from "./styles";
import EditableTextArea from "./EditableTextArea";
import SubDetails from "./SubDetails";

const Details = ({ readOnly }) => {
  const dispatch = useDispatch();

  const { details } = useSelector((state) => state.adminControlData);

  useEffect(() => {
    dispatch(getRatesDetails());
  }, []);

  const classes = useRateCardStyles();

  return (
    <div className={classes.root}>
      <h4>Rate Card -Other items and relevant policies:</h4>
      <ul>
        <li>
          Minimum booking duration for FTF interpreting{" "}
          <EditableText
            text={details.minBookingFTF.hours}
            readOnly={readOnly}
            name="minBookingFTF.hours"
          />{" "}
          hours with{" "}
          <EditableText
            text={details.minBookingFTF.minutes}
            readOnly={readOnly}
            name="minBookingFTF.minutes"
          />{" "}
          minutes paid break
        </li>
        <li>
          Minimum booking duration for Remote interpreting{" "}
          <EditableText
            text={details.minBookingRemote.hours}
            readOnly={readOnly}
            name="minBookingRemote.hours"
          />{" "}
          hours with{" "}
          <EditableText
            name="minBookingRemote.minutes"
            text={details.minBookingRemote.minutes}
            readOnly={readOnly}
          />{" "}
          minutes paid break
        </li>
        <li>
        Minimum word counts for translation work 100+
        </li>
        <li>
          Maximum duration for one day booking 8 hours with{" "}
          <EditableText
            text={details.maxBookingDuration}
            name="maxBookingDuration"
            readOnly={readOnly}
          />{" "}
          hour paid break
        </li>
        <li>
          Billing Account Service Charge{" "}
          <EditableText
            text={details.serviceCharge}
            name="serviceCharge"
            readOnly={readOnly}
          />{" "}
          % on total amount{" "}
        </li>

        <li>
          Platform and booking service Charge{" "}
          <EditableText
            text={details.platformCharge}
            name="platformCharge"
            readOnly={readOnly}
          />{" "}
          % on total amount{" "}
        </li>


        <li>
          Linguists Collective VAT number{" "}
          <EditableText
            text={details.vatNumber}
            name="vatNumber"
            readOnly={readOnly}
            customWidth="10%"
          />{" "}
        </li>
        <li>
          More than 8 hours will be considered as overtime and or unsocial
          hours rate
        </li>
      </ul>
      <p>
        <span className="footer-highlight">* Overnight Allowance (OA):</span>{" "}
        <b>Overnight payment / allowances (for</b>{" "}
        <EditableText
          text={details.overnightPayment.days}
          readOnly={readOnly}
          name="overnightPayment.days"
        />{" "}
        <b>days or more bookings at same venue more than</b>{" "}
        <EditableText
          text={details.overnightPayment.milesOne}
          readOnly={readOnly}
          name="overnightPayment.milesOne"
        />{" "}
        <b>miles return journey for linguist).</b> Our system will try to source
        Face To Face (FTF) interpreting booking within{" "}
        <EditableText
          text={details.overnightPayment.milesFour}
          name="overnightPayment.milesFour"
          readOnly={readOnly}
        />{" "}
        miles of the from the job venue. However, this may not always be
        possible, in case if system cannot allocate a linguist within the{" "}
        <EditableText
          text={details.overnightPayment.milesTwo}
          name="overnightPayment.milesTwo"
          readOnly={readOnly}
        />{" "}
        miles, our system will try to source from outside the{" "}
        <EditableText
          text={details.overnightPayment.milesThree}
          name="overnightPayment.milesThree"
          readOnly={readOnly}
        />{" "}
        miles radiance in which case an Overnight Allowance (OA) charge will
        apply to the booking.
      </p>

      <SubDetails className={classes.details} readOnly={readOnly} />
    </div>
  );
};

export default Details;
