import React from "react";
import { useParams } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AdminSetPassword from "components/Admin/AdminSetPassword";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const SetNewPassword = () => {
  const classes = useDashboardStyles();
  let { token } = useParams();

  return (
    <GridContainer
      style={{
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <GridItem xs={12} sm={6} md={6} lg={4}>
        <Card>
          <CardHeader color="primary">
            <p className={classes.cardTitleWhite}>Set Your New Password</p>
          </CardHeader>
          <CardBody
            style={{
              textAlign: "center",
            }}
          >
            <AdminSetPassword token={token} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default SetNewPassword;
