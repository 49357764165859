import React, { useState } from "react";
import { listRegisteredLinguistSearch } from "../../../../../action/manager";
import Action from "./Action";
import GridItem from "components/Grid/GridItem";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import {
  PRIMARY_SOURCE_LANG,
  PRIMARY_TARGET_LANG,
  CURRENT_COUNTRY,
} from "data";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

const Search = () => {
  // const classes = useEmployeeStyles();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    search: undefined,
    results: [],
    searched: false,
    message: "",
    error: false,
    success: false,
    loading: false,
  });

  const { search, results, searched, message, error, success, loading } =
    values;

  const searchSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true });
    listRegisteredLinguistSearch({ search }).then((data) => {
      if (data) {
        setValues({
          ...values,
          results: data,
          searched: true,
          loading: false,
          message: `${data?.length} linguist found`,
        });
        // setValues({ ...values, loading: false });
      }
      // else{
      //   setValues({ ...values, results: data, loading: false, searched: false, message: `${data?.length} linguist found` });
      // }
    });
  };

  const handleID = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleName = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleSourceLanguage = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleTargetLanguage = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleTargetLevel = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleSourceLevel = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleCity = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handlePostcode = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleCountry = (e) => {
    setValues({
      ...values,
      search: e.target.value.trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };

  const showSuccess = () => (
    <div
      className="alert alert-success"
      style={{ display: success ? "" : "none" }}
    >
      Loaded
    </div>
  );

  const showLoading = () => (
    <div
      className="alert alert-info"
      style={{ display: loading ? "" : "none" }}
    >
      Loading...
    </div>
  );

  const searchedLinguists = (results = []) => {
    return (
      <div className="jumbotron bg-white">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {message && <b> {message} </b>}
          <br />
        </GridItem>

        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Linguist ID</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Source / Native language</TableCell>
                  <TableCell>Target language</TableCell>
                  <TableCell>Target language Level</TableCell>
                  <TableCell>Town / City</TableCell>
                  <TableCell>Post / Zip Code</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {results
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((list, i) => {
                    if (list.verified == true) {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={i}
                          style={{ background: "lightgreen" }}
                        >
                          <TableCell>
                            <a
                              href={`/employee/view-linguist-profile/${list._id}`}
                            >
                              {list.id}
                            </a>
                          </TableCell>

                          <TableCell>{list.FirstName}</TableCell>
                          <TableCell>{list.LastName}</TableCell>
                          <TableCell>{list.email}</TableCell>
                          <TableCell>{list.mobile_no}</TableCell>
                          <TableCell>{list.phone_no}</TableCell>
                          <TableCell>
                            {list.source_language
                              ? list.source_language
                              : "N/A"}{" "}
                            <br />
                            {list.source_language_1
                              ? list.source_language_1
                              : "N/A"}{" "}
                            <br />
                            {list.source_language_2
                              ? list.source_language_2
                              : "N/A"}
                          </TableCell>
                          <TableCell>
                            {list.target_language
                              ? list.target_language
                              : "N/A"}
                            <br />
                            {list.target_language_1
                              ? list.target_language_1
                              : "N/A"}
                            <br />
                            {list.target_language_2
                              ? list.target_language_2
                              : "N/A"}
                          </TableCell>
                          <TableCell>
                            {list?.target_language_level
                              ? list?.target_language_level
                              : "N/A"}
                            <br />
                            {list?.target_language_level_1
                              ? list?.target_language_level_1
                              : "N/A"}
                            <br />
                            {list?.target_language_level_2
                              ? list?.target_language_level_2
                              : "N/A"}
                          </TableCell>
                          <TableCell>{list.City}</TableCell>
                          <TableCell>{list.PostalCode}</TableCell>
                          <TableCell>{list.Country}</TableCell>
                          <TableCell>
                            <Action
                              name={list?.FirstName + " - " + list?.LastName}
                              id={list?._id}
                              ID={list?.id}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else if (list.verified == false) {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={i}
                          style={{ background: "#4FAEF4" }}
                        >
                          <TableCell>
                            <a
                              style={{ color: "white" }}
                              href={`/employee/view-linguist-profile/${list._id}`}
                            >
                              {list.id}
                            </a>
                          </TableCell>
                          <TableCell>{list.FirstName}</TableCell>
                          <TableCell>{list.LastName}</TableCell>
                          <TableCell>{list.email}</TableCell>
                          <TableCell>{list.mobile_no}</TableCell>
                          <TableCell>{list.phone_no}</TableCell>
                          <TableCell>{list.source_language}</TableCell>
                          <TableCell>{list.target_language}</TableCell>
                          <TableCell>{list?.target_language_level}</TableCell>
                          <TableCell>{list.City}</TableCell>
                          <TableCell>{list.PostalCode}</TableCell>
                          <TableCell>{list.Country}</TableCell>
                          <TableCell>
                            <Action
                              name={list?.FirstName + " - " + list?.LastName}
                              id={list?._id}
                              ID={list?.id}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else if (list.suspend == true) {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={i}
                          style={{ background: "#F7624A" }}
                        >
                          <TableCell>
                            <a
                              href={`/employee/view-linguist-profile/${list._id}`}
                            >
                              {list.id}
                            </a>
                          </TableCell>
                          <TableCell>{list.FirstName}</TableCell>
                          <TableCell>{list.LastName}</TableCell>
                          <TableCell>{list.email}</TableCell>
                          <TableCell>{list.mobile_no}</TableCell>
                          <TableCell>{list.phone_no}</TableCell>
                          <TableCell>{list.source_language}</TableCell>
                          <TableCell>{list.target_language}</TableCell>
                          <TableCell>{list?.target_language_level}</TableCell>
                          <TableCell>{list.City}</TableCell>
                          <TableCell>{list.PostalCode}</TableCell>
                          <TableCell>{list.Country}</TableCell>
                          <TableCell>
                            <Action
                              name={list?.FirstName + " - " + list?.LastName}
                              id={list?._id}
                              ID={list?.id}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
              </TableBody>
              {/* )} */}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={results?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  };

  const searchForm = () => (
    <form onSubmit={searchSubmit}>
      <div className="row">
        <CustomInputSingle
          onChange={handleCountry}
          type="search"
          required
          label="Enter Linguist ( ID or First Name or Last Name or Email or Postal Code or City or Country or Source Language or Target Language ) "
          placeholder="Search using ( ID or First Name or Last Name or Email or Postal Code or City or Country or Source Language or Target Language )"
        />
        <div className="col-md-4">
          <div className={classes.buttonFlex} style={{ marginRight: "45%" }}>
            <CustomButton text="Search" type="submit" />
          </div>
        </div>
      </div>
    </form>
  );

  return (
    <div className="container-fluid">
      <div className="pt-3 pb-5">{searchForm()}</div>
      <br />
      <br />
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <b style={{ color: "green" }}>* Verified Linguist,</b>{" "}
        <b style={{ color: "blue" }}>Unverified Linguist,</b>{" "}
        <b style={{ color: "red" }}> Suspend Linguist</b>
        <br />
        <br />
        {showSuccess()}
        {showLoading()}
      </GridItem>
      {searched && (
        <div style={{ marginTop: "40px", marginBottom: "100px" }}>
          {searchedLinguists(results)}
        </div>
      )}
    </div>
  );
};

export default Search;
