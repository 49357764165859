import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components

import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import Typography from "@material-ui/core/Typography";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import { ACCOUNT_TYPE, CURRENT_COUNTRY, EMAIL_REGEX, PASS_REGEX } from "data";

import { changeCAOInput, updateCAOStep } from "redux/actions/caoAction";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPassword from "components/Common/CustomPassword";
import CustomPhone from "components/Common/CustomPhone";

const PageOne = () => {
  const { data } = useSelector((state) => state.caoReducer);
  const dispatch = useDispatch();

  const [notValid, setNotValid] = useState(false);
  const [notRegex, setNotRegex] = useState(false);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const {
    account_type,
    other_details,
    business_name,
    house_street_no_name,
    address_line_two,
    City,
    PostalCode,
    Region,
    Country,
    business_website,
    primary_email,
    password,
    confirmation_password,
    business_phone_no,
    company_registration_number,
    charity_registration_number,
    passport,
    photo,
  } = data;

  const classes = useFormStyles();

  const handlePassportFiles = (e) => {
    const { name } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value: e.target.files[0],
      })
    );
  };

  const handleProfileFiles = (e) => {
    const { name } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value: e.target.files[0],
      })
    );
  };

  const handleNext = () => {
    dispatch(updateCAOStep(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value,
      })
    );
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value,
      })
    );
    if (value !== "" && PASS_REGEX.test(value)) {
      setNotRegex(false);
    } else {
      setNotRegex(true);
    }
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value,
      })
    );
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handlePassportClick = (e) => {
    e.preventDefault();
    fileInput2.current.click();
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeCAOInput({
        name: "Country",
        value,
      })
    );
  };

  const handleChangeAT = (e, value) => {
    dispatch(
      changeCAOInput({
        name: "account_type",
        value,
      })
    );
  };

  const handleBusinessPhone = (value) => {
    // inputData({ ...data, mobile_no: value });
    dispatch(
      changeCAOInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const isAllow =
    !account_type ||
    (account_type.includes("Other") && !other_details) ||
    !business_name ||
    !house_street_no_name ||
    !City ||
    !PostalCode ||
    !Region ||
    !Country ||
    !primary_email ||
    notValid ||
    password !== confirmation_password ||
    notRegex;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomAutoSelect
          onChange={handleChangeAT}
          label="Account type / sector"
          width="100%"
          name="account_type"
          value={account_type}
          data={ACCOUNT_TYPE}
          required
        />
        {account_type?.includes("Other") ? (
          <CustomInputSingle
            onChange={handleChange}
            width="100%"
            label="Other details"
            name="other_details"
            value={other_details}
            required
          />
        ) : null}
        <br />
        <Typography variant="subtitle2"> Business address:</Typography>

        <CustomInputSingle
          onChange={handleChange}
          label="Business or organization name"
          width="100%"
          name="business_name"
          value={business_name}
          required
          placeholder="Trading name of your business / organisation. Otherwise please write N/A"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="House number/name, Street name"
          moreInfo="Business address"
          width="100%"
          name="house_street_no_name"
          value={house_street_no_name}
          required
          placeholder="e.g. Flat/Building Name, 101 Example Street"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Address line 2"
          width="100%"
          name="address_line_two"
          value={address_line_two}
          placeholder="Additional Address Information"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Town / City"
          width="100%"
          name="City"
          value={City}
          required
          placeholder="Your Village, Town or City Name"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Post / Zip Code"
          width="100%"
          name="PostalCode"
          value={PostalCode}
          required
          placeholder="Your Post Code, Zip Code or Post Office Name"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="County / Region"
          width="100%"
          name="Region"
          value={Region}
          required
          placeholder="Your County, Division or State Name"
        />
        <CustomAutoSelect
          onChange={handleAutoCompleteCO}
          label="Country"
          moreInfo="Current Country of residence"
          width="100%"
          name="Country"
          value={Country}
          data={CURRENT_COUNTRY}
          required
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Business website"
          width="100%"
          name="business_website"
          value={business_website}
          placeholder="e.g. www.YourCompanyWebsite.com"
        />
        <CustomInputSingle
          onChange={handleEmailChange}
          label="Primary email"
          width="100%"
          name="primary_email"
          value={primary_email}
          placeholder="e.g. example@example.com"
          required
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <CustomPassword
          onChange={handlePasswordChange}
          label="Password"
          width="100%"
          name="password"
          value={password}
          notRegex={notRegex}
          required
          placeholder="e.g. Ex@mPle012345"
        />

        <CustomPassword
          onChange={handleChange}
          label="Confirm password"
          width="100%"
          name="confirmation_password"
          value={confirmation_password}
          validation={password !== confirmation_password}
          required
        />

        <br />
        <CustomPhone
          label="Business phone number"
          onChange={handleBusinessPhone}
          placeholder="Business phone number (e.g. +44 1234 567890)"
          value={business_phone_no}
          name="business_phone_no"
        />

        <CustomBigInputSingle
          onChange={handleChange}
          label="Company registration number"
          moreInfo="If registered"
          width="100%"
          name="company_registration_number"
          value={company_registration_number}
          placeholder="If registered provide company registration number (CRN) here  "
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="Charity registration number"
          moreInfo="If registered"
          width="100%"
          name="charity_registration_number"
          value={charity_registration_number}
          placeholder="If registered provide charity registration number here"
        />

        <div className={classes.buttonFlex}>
          <CustomButton disabled={isAllow} text="Next" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default PageOne;
