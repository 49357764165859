import React, {useState} from "react";
import { Link, useHistory } from "react-router-dom";
import Moment from 'moment'
// Custom Components

import InputBase from "@material-ui/core/InputBase";
import CardHeader from "components/Card/CardHeader";
import { Button } from "semantic-ui-react";
import { Table } from "semantic-ui-react";
import TableContainer from '@material-ui/core/TableContainer';
import CustomModal from "components/Common/CustomModal";
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import swal from 'sweetalert'
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { makeStyles } from '@material-ui/core/styles';
import { archivePRC } from "../../../../../action/manager";
import { ToastProvider, useToasts } from 'react-toast-notifications'
//Axios 
import Axios from 'utils/axios'

//Cookies
import Cookies from 'js-cookie'

import moment from 'moment'
// import AcceptedAction from "./AcceptedAction.jsx";


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 600,
  },
});

const AcceptedQuote = ({id}) => {
    const classes = useDashboardStyles();
    const [viewAll, setViewAll] = useState(null)
    const token = Cookies.get('token')
    const [search, setSearch] = useState('')
    const [searchData, setSearchData] = useState(null)
    const { addToast } = useToasts()
    const history = useHistory()

    const handleViewAll = async(e) => {
        try {
            const response = await Axios.get(`/api/manager-admin/list-linguist-prc/linID/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
                })
            
            setViewAll(response?.data)
          
        } catch(error) {
          if (error.response) {
            addToast("No data found!", { appearance: 'error' })
          }
           
        }
    }


    const handleSearch = async(e) => {
        try {
            const response = await Axios.get(`/api/manager-admin/find-linguist-prc/linID/${search}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
                })
            
            setSearchData(response?.data)
            setViewAll(null)
            if(response?.data){
              addToast('private client found', { appearance: 'success' })
            }else{
              addToast('private client not found', { appearance: 'error' })
            }
           
        } catch(error) {
          if (error.response) {
            addToast("Empty field, please write correct client id.", { appearance: 'error' })
          }
           
        }
    }










    
const ArchivePRC = _id => {
  archivePRC(_id, token).then(data => {
    if (data.error) {
        console.log(data.error);
        addToast(data.error, { appearance: 'error' })
    } else {
     addToast('Client moved to archive folder', { appearance: 'success' })
   
    }
});
}


const handleAccess = async(value) => {
  swal("Are you sure?", {
        dangerMode: true,
        buttons: {
            cancel: true,
            catch: {
                text: "Yes!",
                value: "catch",
            },
        },
        })
        .then((type) => {
            switch (type) {
           
              case "cancel":
                break;
           
              case "catch":
                ArchivePRC(value);
           
              default:
                break;
            }
        });
  
}




    return (
        <>
        <InputBase
          className={classes.input}
          placeholder="Enter client ID"
          onChange= {(e) => setSearch(e.target.value)}
          inputProps={{ "aria-label": "search google maps" }}
          value={search}
          style={{width:30+"%",padding:5,marginTop:20,boxShadow: '0px 0px 3px 2px rgba(0,0,0,0.25)',borderRadius:2+"pt"}}
        />
        <Button onClick= {handleSearch} style={{marginLeft:20}} color= 'purple'>Find a client</Button>

        <Button onClick= {handleViewAll} color= 'purple' style={{marginTop:20,marginBottom:20}}>View all clients</Button>
        
        <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table color= 'pink'>
           
            <Table.Header>
            <Table.Row style={{textAlign:'left'}}>
            <Table.Cell>Unique ID</Table.Cell>
            <Table.Cell>Title</Table.Cell>
            <Table.Cell>First name</Table.Cell>
            <Table.Cell>Last name</Table.Cell>
            <Table.Cell>Email address</Table.Cell>
            <Table.Cell>Mobile number</Table.Cell>
            <Table.Cell>Registration Date</Table.Cell>
            <Table.Cell>Action</Table.Cell>
            </Table.Row>
          </Table.Header>
  
        {searchData && !viewAll ?
            <Table.Body>
            <Table.Row>
            <Table.Cell>
              <Link to={`/admin/view-private/${searchData._id}`}>
                  {searchData.id}
                </Link>
              </Table.Cell>
              <Table.Cell>{searchData.title}</Table.Cell>
              <Table.Cell>{searchData.FirstName}</Table.Cell>
              <Table.Cell>{searchData.LastName}</Table.Cell>
              <Table.Cell>{searchData.email}</Table.Cell>
              <Table.Cell>{searchData.mobile_no}</Table.Cell>
              <Table.Cell>{Moment(searchData.createdAt).format('DD MMM YYYY - kk:mm:ss') }</Table.Cell>
              <Table.Cell><Button onClick={() => handleAccess(searchData._id)}>Archive</Button></Table.Cell>
              
            </Table.Row> 
            </Table.Body> 
            : 
            <Table.Body>
            {viewAll?.map((list,i) =>
            <Table.Row key= {i}>
             <Table.Cell>
              <Link to={`/admin/view-private/${list._id}`}>
                  {list.id}
                </Link>
              </Table.Cell>
              <Table.Cell>{list.title}</Table.Cell>
              <Table.Cell>{list.FirstName}</Table.Cell>
              <Table.Cell>{list.LastName}</Table.Cell>
              <Table.Cell>{list.email}</Table.Cell>
              <Table.Cell>{list.mobile_no}</Table.Cell>
              <Table.Cell>{Moment(list?.createdAt).format('DD MMM YYYY - kk:mm:ss') }</Table.Cell>
              <Table.Cell><Button onClick={() => handleAccess(list._id)}>Archive</Button></Table.Cell>
            </Table.Row> 
            )}
            </Table.Body>
        }
          </Table>
        </TableContainer>
  
      </Paper>
      </>
    )
}

export default AcceptedQuote
