import React, { useState } from "react";
import { Button } from "semantic-ui-react";
// Material Core Components
import Typography from "@material-ui/core/Typography";
import history from "utils/history";
// Material Icons

import { Progress } from "semantic-ui-react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageThree from "../Form/PageThree";
import Pagination from "./pagination";
// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomFormControl from "components/Common/CustomFormControl";
import CustomAvatar from "components/Common/CustomAvatar";

const LCJoining = () => {
  const classes = useEmployeeStyles();
  const [reload, setReload] = useState(0);

  return (
    <div className={classes.root}>
      <GridContainer
        justify="center"
        className={classes.container}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <CardHeader>
              <Pagination active={2} reload={reload} />
            </CardHeader>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  LC Full Registration Form
                </Typography>
                <Typography variant="subtitle2">Page 3 of 8</Typography>
                <Typography variant="subtitle2">Bank details</Typography>
              </div>
              <PageThree setReload={setReload} />
              <CustomFormControl width="73.33%">
                <Progress
                  color="purple"
                  percent={(3 / 8) * 100}
                  active
                  size="small"
                />
              </CustomFormControl>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default LCJoining;
