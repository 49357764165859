import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import Axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import CustomSnackBar from "components/Common/CustomSnackBar";

const About = () => {
  const token = Cookies.get("SPPOtoken");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updatePPLReducer);
  const [approvalLoader, setApprovalLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [calculate, setCalculate] = useState({
    sendButton: false,
    loading: false,
  });
  const [approval, setApproval] = useState(false);
  const [update, setUpdate] = useState(false);

  const updateAboutDetails = async (e) => {
    setCalculate({ ...calculate, loading: true });
    setSubmitLoader(true);
    try {
      const reqBody = {
        about: data.about,
      };
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/update-public-profile/${
          data?.agent_sppo ? data?.agent_sppo : data?._id
        }`,
        reqBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUpdate(true);
      setSubmitLoader(false);
      setCalculate({ ...calculate, loading: false, sendButton: true });
    } catch (error) {
      console.log(error);
    }
  };

  const sendApproval = async (e) => {
    setApprovalLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/send-public-profile-approval-request/${
          data?.agent_sppo ? data?.agent_sppo : data?._id
        }`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then(function () {
        window.location = "/sppo/manage-public-profile";
      });
      setApproval(true);
      setApprovalLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <CustomSnackBar
        message="Successfully updated! Send approval request to admin"
        handleClose={() => setUpdate(false)}
        openSnackBar={update}
      />
      <CustomSnackBar
        message="Public profile sent for approval"
        handleClose={() => setApproval(false)}
        openSnackBar={approval}
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        {!calculate.sendButton && (
          <Button
            style={{ display: "block", marginTop: "1rem" }}
            onClick={updateAboutDetails}
            loading={calculate.loading}
            color="purple"
            basic
          >
            Save and Update
          </Button>
        )}
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={sendApproval}
          disabled={!calculate.sendButton}
          loading={approvalLoader}
          color="purple"
        >
          Submit to admin for publication
        </Button>
      </div>
    </div>
  );
};

export default About;
