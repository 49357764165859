import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";

// Custom Components
import AccountClientLayout from "layout/AccountClientLayout";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import BISummary from "components/Auth/BookingForm/BACBooking/BISummary";

const BISummaryPage = () => {
  return (
    <AccountClientLayout>
      <Accountclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <BISummary />
          </GridItem>
        </GridContainer>
      </Accountclient>
    </AccountClientLayout>
  );
};

export default BISummaryPage;
