import useFormStyles from "assets/jss/components/auth/formStyle";
import React, { useEffect, useRef, useState } from "react";
import Items from "./TableBody";

//Style
import "./style.css";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Rating } from "@material-ui/lab";
import CustomTextInput from "components/Common/CustomTextInput";
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import TimesheetFooter from "./ItemsTwo";
import { useDispatch, useSelector } from "react-redux";
import CustomSnackBar from "components/Common/CustomSnackBar";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  changeTimesheetInput,
  updateTimesheet,
} from "redux/actions/timesheetAction";

const Details = () => {
  const { id } = useParams();
  const token = Cookies.get("LINtoken");
  const [details, setDetails] = useState(null);
  const { data } = useSelector((state) => state.timesheetReducer);
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [checked, setChecked] = useState(false);
  const [linguist, setLinguist] = useState([]);
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const [submit, setSubmit] = useState({
    toast: false,
    loader: false,
  });

  const {
    start_date,
    end_date,
    feedback,
    note,
    duration,
    start_time,
    signature,
    actual_duration,
  } = data;

  const getLinguistProfile = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLinguist(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLinguistProfile();
    let source = Axios.CancelToken.source();

    const getTimeSheet = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/accountclient-booking-view/bookingID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );
        setDetails(response?.data);
        dispatch(
          updateTimesheet({
            start_date: response?.data.data.startdateArray[0],
            end_date: response?.data.data.enddateArray[0],
            end_time: response?.data.data.endtimeArray[0],
            start_time: response?.data.data.starttimeArray[0],
            duration: response?.data.data.gethour,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    };

    getTimeSheet();

    return () => {
      source.cancel();
    };
  }, []);

  const handleSubmit = async () => {
    setSubmit({ ...submit, loader: true });
    const file = new File([signature], "signature", { type: "image/png" });
    const fd = new FormData();
    fd.append("signature", file);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("duration", duration || details?.gethour / details?.month_duration);
    fd.append("feedback", parseInt(feedback));
    fd.append("note", note);
    fd.append("start_time", start_time);
    fd.append("actual_duration", actual_duration);
    if (uploadTimesheet?.size) {
      fd.append("document", uploadTimesheet);
    }
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/make-bac-bulk-timesheet/bookingID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubmit({ ...submit, loader: false, toast: true });
      window.location = "/linguist/current-assignment";
    } catch (error) {
      setSubmit({ ...submit, loader: false });
      console.log(error);
    }
  };

  const handleViewPdf = () => {
    window.html2canvas = html2canvas;
    var doc = new jsPDF({
      orientation: "potrait",
      unit: "pt",
      size: "A4",
      // format: [4, 2]
    });
    var content = document.getElementById("printArea");

    doc.html(content, {
      callback: function (doc) {
        // doc.save('myDocument.pdf');
        window.open(doc.output("bloburl"));
      },
    });
  };

  return details ? (
    <div>
      <div id="printArea" style={{ width: "100%", marginTop: "24px" }}>
        <CustomSnackBar
          message="Timesheet submitted to client"
          handleClose={() => setSubmit({ ...submit, toast: false })}
          openSnackBar={submit.toast}
        />
        <CustomTextInput label="Job Reference Number" value={details.id} />

        <Items linguist={linguist} details={details} />

        <CustomTextInput
          label="Instructions for linguist professional"
          value=""
        />

        {/* Address details */}
        {details.remote_address && (
          <CustomTextInput
            label="Remote Address"
            value={details.remote_address}
          />
        )}
        {!details.remote_address ? (
          <>
            <CustomTextInput
              label="House Number / Street Name"
              value={details.house_number_street_name}
            />
            <CustomTextInput
              label="Address Line 2"
              value={details.address_line_two}
            />
            <CustomTextInput label="County / Region" value={details.region} />
            <CustomTextInput label="Post Code" value={details.post_code} />
            <CustomTextInput label="Country" value={details.country} />
          </>
        ) : null}
        <CustomTextInput
          label="Court case, police, NHS ref"
          value={details.security_clearance}
        />

        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
        >
          <Header style={{ margin: "0" }} as="h4">
            Feedback to client <b style={{ color: "red" }}>*</b>
          </Header>
          <div>
            <Rating
              name="simple-controlled"
              value={feedback}
              onChange={(event, newValue) => {
                dispatch(
                  changeTimesheetInput({
                    name: "feedback",
                    value: newValue,
                  })
                );
              }}
            />
          </div>
        </div>

        <CustomTextInput
          label="Customer / Client Name"
          value={details?.postedBy?.FirstName + " " + details?.postedBy?.LastName}
        />
        <CustomTextInput
          label="Customer / Client ref no."
          value={details?.clientRef}
        />
        <CustomTextInput
          label="Emergency Contact no."
          value={details?.postedBy?.mobile_no}
        />
        <CustomTextInput label="Name of limited speaker conference" value="" />
        {/* <div style= {{display: 'flex', alignItems: 'center', marginBottom: '1em'}}>
                <Header style= {{margin: '0'}} as= 'h4'>Feedback to linguist</Header>
                <div>
                    <Rating
                    name="simple-controlled"
                    value= '0'
                    />
                </div>
                </div> */}

        <TimesheetFooter
          checked={checked}
          setChecked={setChecked}
          linguist={linguist}
          details={details}
        />
      </div>

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button onClick={() => uploadRef.current.click()} basic color="purple">
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <div style={{ padding: "10px" }}>
          <Button
            onClick={handleViewPdf}
            style={{ backgroundColor: "purple", color: "white" }}
          >
            View PDF
          </Button>
        </div>
        <div style={{ padding: "10px" }}>
          <Button
            loading={submit.loader}
            disabled={submit.loader
              || !feedback
              || !signature
              || !checked
              || !duration
              || start_date === [""]
              || start_time === [""]}
            onClick={handleSubmit}
            style={{ backgroundColor: "purple", color: "white" }}
          >
            Submit
          </Button>
          {/* {submit.loader && 
                <Dimmer>
                <Loader active inline /> 
                </Dimmer>} */}
        </div>
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
