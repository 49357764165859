import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { sum } from "lodash";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/Common/CustomInput";
import CardHeader from 'components/Card/CardHeader'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem';
import CURRENCIES from 'data/currencies'

import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import BoxOne from "./BoxOne";
import BoxTwo from "./BoxTwo";
import BoxThree from "./BoxThree";

import { QuoteDiv } from "./style";
import { Box } from "@material-ui/core";

const AddData = () => {
  const [totalRevenue, setTotalRevenue] = useState();
  const [totalExp, setTotalExp] = useState();
  const classes = useDashboardStyles()
  const [date, setDate] = useState();
  const [reference,setReference] = useState('');
  const [currency,setCurrency] = useState(CURRENCIES[0].code)
  const [expenseDaas, setExpenseDaas] = useState([
    {
      id: uuidv4(),
      name: "",
      value: "",
    },
  ]);
  const [otherExpenseDt, setOtherExpenseDt] = useState([]);

  const [revDatas, setRevDatas] = useState([
    {
      id: uuidv4(),
      name: "",
      value: "",
    },
  ]);
  const [otherRevDatas, setOtherRevDatas] = useState([]);

  const handleAdd = () => {
    const moreDatas = [
      ...expenseDaas,
      {
        id: uuidv4(),
        name: "",
        value: "",
      },
    ];
    setExpenseDaas(moreDatas);
  };

  const handleOtherAdd = () => {
    const moreDatas = [
      ...otherExpenseDt,
      {
        id: uuidv4(),
        name: "",
        value: "",
      },
    ];
    setOtherExpenseDt(moreDatas);
  };
  const handleDateChange = (date) => {
    setDate(date);
  };
  const handleRemove = (id) => {
    const filteredDatas = expenseDaas.filter((dt) => dt.id !== id);
    setExpenseDaas(filteredDatas);
  };
  const handleOtherRemove = (id) => {
    const filteredDatas = otherExpenseDt.filter((dt) => dt.id !== id);
    setOtherExpenseDt(filteredDatas);
  };

  const handleDropdownChange = (id, value) => {
    const updatedData = expenseDaas.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          name: value,
        };
      }
      return item;
    });
    setExpenseDaas(updatedData);
    // For Other field
    if (value && value.toLowerCase().includes("other")) {
      if (expenseDaas.length > 1) {
        setExpenseDaas(expenseDaas.filter((item) => item.id !== id));
      }
      setOtherExpenseDt([
        ...otherExpenseDt,
        {
          id: uuidv4(),
          name: "",
          value: "",
        },
      ]);
    }
  };

  const handleRevAdd = () => {
    const moreDatas = [
      ...revDatas,
      {
        id: uuidv4(),
        name: "",
        value: "",
      },
    ];
    setRevDatas(moreDatas);
  };
  const handleOtherRevAdd = () => {
    const moreDatas = [
      ...otherRevDatas,
      {
        id: uuidv4(),
        name: "",
        value: "",
      },
    ];
    setOtherRevDatas(moreDatas);
  };
  const handleRevRemove = (id) => {
    const filteredDatas = revDatas.filter((dt) => dt.id !== id);
    setRevDatas(filteredDatas);
  };
  const handleOtherRevRemove = (id) => {
    const filteredDatas = otherRevDatas.filter((dt) => dt.id !== id);
    setOtherRevDatas(filteredDatas);
  };
  const handleRevDropdownChange = (id, value) => {
    const updatedData = revDatas.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          name: value,
        };
      }
      return item;
    });
    setRevDatas(updatedData);
    // For Other field
    if (value && value.toLowerCase().includes("other")) {
      if (revDatas.length > 1) {
        setRevDatas(revDatas.filter((item) => item.id !== id));
      }
      setOtherRevDatas([
        ...otherRevDatas,
        {
          id: uuidv4(),
          name: "",
          value: "",
        },
      ]);
    }
  };

  const handleExpInputChange = (id, value) => {
    const updatedData = expenseDaas.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          value,
        };
      }
      return item;
    });

    setExpenseDaas(updatedData);
  };
  const handleExpOtherNameInputChange = (id, value) => {
    const updatedData = otherExpenseDt.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          name: value,
        };
      }
      return item;
    });

    setOtherExpenseDt(updatedData);
  };
  const handleExpOtherValueInputChange = (id, value) => {
    const updatedData = otherExpenseDt.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          value,
        };
      }
      return item;
    });

    setOtherExpenseDt(updatedData);
  };

  const handleRevInputChange = (id, value) => {
    const updatedData = revDatas.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          value,
        };
      }
      return item;
    });

    setRevDatas(updatedData);
  };
  const handleRevOtherNameInputChange = (id, value) => {
    const updatedData = otherRevDatas.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          name: value,
        };
      }
      return item;
    });

    setOtherRevDatas(updatedData);
  };
  const handleRevOtherValueInputChange = (id, value) => {
    const updatedData = otherRevDatas.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          value,
        };
      }
      return item;
    });

    setOtherRevDatas(updatedData);
  };

  const handleTotalRevenue = () => {
    const revDataValues = revDatas
      .map((item) => +item.value)
      .filter((item) => item);

    const otherRevDataValues = otherRevDatas
      .map((item) => +item.value)
      .filter((item) => item);

    const total = sum(revDataValues) + sum(otherRevDataValues);
    setTotalRevenue(total);
  };

  const handleTotalExp = () => {
    const expDataValues = expenseDaas
      .map((item) => +item.value)
      .filter((item) => item);

    const otherExpDataValues = otherExpenseDt
      .map((item) => +item.value)
      .filter((item) => item);

    const total = sum(expDataValues) + sum(otherExpDataValues);
    setTotalExp(total);
  };

  const handleCalculate = () => {
    handleTotalRevenue();
    handleTotalExp();
  };
  return (
    <GridContainer>
      
        <Card>
          <CardBody> 
          <GridItem xs={12} sm={12} md={12} lg={12}>
             <Box p={1}>
        <Card >
          <CardBody>
            
            <QuoteDiv>
              <h4 >Quote / Your Reference No:</h4>
              <CustomInput onInput={(e)=>setReference(e.target.value)} width="30%" style={{marginBottom:20}} />
            </QuoteDiv>
            <br/>
            <QuoteDiv>
            <h4 >Currency:</h4>
            <TextField
                      select
                      // variant="outlined"
                      onChange={(e) => {
                        setCurrency(
                        e.target.value,
                        );
                      }}
                      width="100%"
                     
                      defaultValue={CURRENCIES[0].code}
                      name="currency"
                    >
                       {CURRENCIES.map((option) => (
            <MenuItem key={option.symbol} value={option.code} >
              {option.code +" "+ option.symbol}
            </MenuItem>
          ))}
          </TextField>
          </QuoteDiv>
            <BoxOne
            currency={currency}
              revDatas={revDatas}
              otherRevDatas={otherRevDatas}
              handleRevAdd={handleRevAdd}
              handleRevRemove={handleRevRemove}
              handleOtherRevRemove={handleOtherRevRemove}
              handleRevDropdownChange={handleRevDropdownChange}
              handleRevInputChange={handleRevInputChange}
              handleRevOtherNameInputChange={handleRevOtherNameInputChange}
              handleRevOtherValueInputChange={handleRevOtherValueInputChange}
              totalRevenue={totalRevenue}
              handleDateChange={handleDateChange}
              date={date}
              handleOtherRevAdd={handleOtherRevAdd}
            />

          </CardBody>
        </Card>
        </Box>
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={12}>
      <Box pb={1}>
        <Card
          
        >
             <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>
                Expenses / Outgoings
                </p>
              </CardHeader>
          <CardBody>
       
            {/* <h4>Expenses / Outgoings: </h4> */}
            
            <BoxTwo
              expenseDaas={expenseDaas}
              otherExpenseDt={otherExpenseDt}
              handleAdd={handleAdd}
              handleRemove={handleRemove}
              handleOtherRemove={handleOtherRemove}
              handleDropdownChange={handleDropdownChange}
              handleExpInputChange={handleExpInputChange}
              handleExpOtherNameInputChange={handleExpOtherNameInputChange}
              handleExpOtherValueInputChange={handleExpOtherValueInputChange}
              totalExp={totalExp}
              handleOtherAdd={handleOtherAdd}
              currency={currency}
            />
          
          </CardBody>
        </Card>
        </Box>
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={12}>
      <Box pb={1}>
        <Card
         
        >
                 <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>
                Profit & Loss
                </p>
              </CardHeader>
         
          <CardBody>
   
           
            <BoxThree
              handleCalculate={handleCalculate}
              totalRevenue={totalRevenue}
              totalExp={totalExp}
              date={date}
              reference={reference}
              currency={currency}
            />
           
          </CardBody>
        </Card>
        </Box>
        </GridItem>
        </CardBody>
        </Card>
    
  
    </GridContainer>
  );
};

export default AddData;
