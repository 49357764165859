import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import history from "utils/history";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import {
  changeInput,
  updateProfileLinguistPageFive,
} from "redux/actions/lcAction";
import CustomLabel from "components/Common/CustomLabel";
import { Dimmer, Loader } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";

import Axios from "axios";
import Cookies from "js-cookie";
import { Button, Header, Progress } from "semantic-ui-react";
import CustomDateTwo from "components/Common/CustomDateTwo";
import swal from "sweetalert";

const PageFive = ({ setReload }) => {
  const { data } = useSelector((state) => state.lcReducer);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const token = Cookies.get("LINtoken");
  const fileInput = useRef(null);
  const [loader, setLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [next, setNext] = useState(false);

  const getLinguistDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/get-details-page5`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        updateProfileLinguistPageFive({
          certificate_id: response?.data._id,
          institute_name: response?.data.institute_name,
          date_of_graduation: response?.data.date_of_graduation,
          name_of_degree: response?.data.name_of_degree,
          subject_studied: response?.data.subject_studied,
          relevant_certificate_photo: response?.data.relevant_certificate_photo,
        })
      );
      setLoader(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
      setLoader(false);
    }
  };

  const [viewRelevantCertificate, setViewRelevantCertificate] = useState(null);
  const RelevantCertificate = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-relevent-certificate-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewRelevantCertificate(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  useEffect(() => {
    getLinguistDetails();
    RelevantCertificate();
  }, []);

  const {
    certificate_id,
    institute_name,
    date_of_graduation,
    name_of_degree,
    subject_studied,
    relevant_certificate_photo,
  } = data;

  const classes = useFormStyles();

  const handleSave = async (e) => {
    e.preventDefault();
    setSaveLoader(true);
    let fd = new FormData();

    fd.append("institute_name", data.institute_name || "");
    fd.append("date_of_graduation", data.date_of_graduation || "dd/mm/yyyy");
    fd.append("name_of_degree", data.name_of_degree || "");
    fd.append("subject_studied", data.subject_studied || "");
    // if (data.relevant_certificate_photo?.name) {
    //   fd.append("relevant_certificate_photo", data.relevant_certificate_photo);
    // }

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-full-registration`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((val) => {
        setReload(Math.random());
        setNext(true);
      });
      setSaveLoader(false);
    } catch (error) {
      console.log(error.response);
      setSaveLoader(false);
    }
  };

  const handleBack = () => {
    history.push("/linguist/full-registration-p4");
  };
  const handleNext = () => {
    history.push("/linguist/full-registration-p6");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInput({
        name,
        value,
      })
    );
  };

  const handleDateChange = (e) => {
    dispatch(
      changeInput({
        name: "date_of_graduation",
        value: e.target.value,
      })
    );
  };

  const handleFiles = async (e) => {
    const { name } = e.target;
    dispatch(
      changeInput({
        name,
        value: e.target.files[0],
      })
    );
  };

  const handleCertificate = async (e) => {
    if (e.target?.files[0]?.size > 3000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 3mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      dispatch(
        changeInput({
          name: "relevant_certificate_photo",
          value: e.target.files[0],
        })
      );
      uploadCertificate(e.target.files[0]);
    }
  };

  const uploadCertificate = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("relevant_certificate_photo", file);
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-relevent-certificate-file`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      RelevantCertificate();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  if (loader) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomLabel
          label="General qualification"
          moreInfo="please add your highest degree / qualification"
        />

        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Name of the institute / school / college / university"
          placeholder="College, University or Training Provider"
          name="institute_name"
          value={institute_name}
        />
        <CustomDateTwo
          onChange={handleDateChange}
          label="Year / date of graduation or course completion"
          width="100%"
          name="date_of_graduation"
          value={date_of_graduation}
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Name of the degree / course / diploma / qualification / CPD obtained"
          name="name_of_degree"
          placeholder="please add your highest degree / qualification"
          value={name_of_degree}
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Subjects studied"
          name="subject_studied"
          placeholder="Subjects / Modules"
          value={subject_studied}
        />

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "24px",
            }}
          >
            <input
              accept=".pdf,.doc"
              required
              name="relevant_certificate_photo"
              ref={fileInput}
              onChange={handleCertificate}
              type="file"
              style={{ display: "none" }}
            />
            <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
              Upload relevant certificate and any reference letter (for your
              highest qualification) <b style={{ color: "red" }}>***</b>
            </Header>
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={handleClick}>Upload</Button>
            </div>
            {relevant_certificate_photo?.name && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <Header style={{ margin: "0" }} as="h4">
                    {relevant_certificate_photo?.name}
                  </Header>
                  <Header
                    style={{ margin: "0" }}
                    as="h5"
                  >{`${relevant_certificate_photo?.lastModifiedDate?.toDateString()}`}</Header>
                  <Header style={{ margin: "0" }} as="h5">{`${(
                    relevant_certificate_photo?.size / 1024
                  ).toFixed(2)}kb`}</Header>{" "}
                </div>
              </div>
            )}

            {viewRelevantCertificate?.relevant_certificate_photo_true && (
              <a
                href={`/linguist/linguist-view-relevent-certificate/${viewRelevantCertificate?._id}`}
              >
                View document
              </a>
            )}
          </div>
        </div>
        {progress > 0 && (
          <Progress percent={progress} indicating autoSuccess progress>
            {" "}
            Document Uploaded
          </Progress>
        )}
        <div className={classes.buttonFlex} style={{ marginTop: "24px" }}>
          <Button basic color="purple" onClick={handleBack}>
            Back
          </Button>
          {!next ? (
            <Button loading={saveLoader} color="purple" onClick={handleSave}>
              Save
            </Button>
          ) : (
            <Button color="green" onClick={handleNext}>
              Next
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default PageFive;
