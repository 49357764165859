import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import swal from "sweetalert";
//Axios
import Axios from "axios";

//Cookies
import Cookies from "js-cookie";

const ActionSent = ({ id, load, cancel }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("LINtoken");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resendQuote = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/send/private-client-quote/quoteID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `Quote successfully sent to the client.`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleResend = async (value) => {
    swal("Are you sure you want to resend this quote?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          resendQuote(value);

        default:
          break;
      }
    });
  };

  const cancelQuote = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/cancel-privateclient-directQuote/quoteID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `Quote cancelled!!!.`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCancel = async (value) => {
    swal("Are you sure you want to cancel this quote?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          cancelQuote(value);

        default:
          break;
      }
    });
  };

  
  const archiveQuote = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/archive-privateclient-directQuote/quoteID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleArchive = async (value) => {
    swal("Are you sure you want to archive this quote?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          archiveQuote(value);

        default:
          break;
      }
    });
  };

  return (
    <div>

      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          minWidth: "10px",
          paddingLeft: "0px",
        }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {cancel ? (
          <MenuItem onClick={handleArchive}>Archive</MenuItem>
        ) : (
          <>
            <MenuItem onClick={handleResend}>Resend</MenuItem>
            <MenuItem onClick={handleCancel}>Cancel</MenuItem>
          </>
        )}
      </Menu>

    </div>
  );
};

export default ActionSent;
