import { useHistory } from "react-router-dom";

// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const InvoiceOptions = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        onClick={() => history.push("/employee/cac-translation/invoiced-assignments")}
      >
       CAC Assignments
      </Button>
      <Button
        variant="outlined"
        onClick={() => history.push("/employee/bac-translation/invoiced-assignments")}
      >
      BAC Assignments
      </Button>
    </div>
  );
};

export default InvoiceOptions;
