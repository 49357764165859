import React, { useState } from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import AccountClientLayout from "layout/AccountClientLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import history from "utils/history";
import { Button } from "semantic-ui-react";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ListQuotes from "components/AccountClient/Assignments/ConfirmQuotations/ConfirmQuotations";

const ConfirmQuotationPage = () => {
  const classes = useDashboardStyles();
  return (
    <Accountclient>
      <AccountClientLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>Confirmed Quotations</p>
                <IconButton color="inherit" onClick={() => history.goBack()}>
                  <ArrowBackIcon />
                </IconButton>
              </CardHeader>

              <CardBody>
                <ListQuotes />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default ConfirmQuotationPage;
