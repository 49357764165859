import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";

import SearchIcon from "@material-ui/icons/Search";
import { Button } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "45%",
    ['@media(max-width:768px)']:{
      width:'100%',
      display:'block'
    },
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  button: {
    ["@media(max-width:768px)"]: {
      width: "100%",
      marginBottom: "5px !important",
    },
  }
}));
const SearhInput = ({setShow,show}) => {
  const classes = useStyles();
  return (
    <div>
      <Paper onClick={e=>e.preventDefault()} component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Enter client ID"
          inputProps={{ "aria-label": "search google maps" }}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Button className={classes.button} color='purple'>Find a request</Button>
        <Button className={classes.button} onClick={()=>setShow(!show)} color='purple'>View all requests</Button>
      </Paper>
    </div>
  );
};

export default SearhInput;
