import React from "react";

// Material Core Components
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CustomFormControl from "./CustomFormControl";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomLabel from "./CustomLabel";
import { ListIcon } from "semantic-ui-react";

//import moment from 'moment-timezone'
import { FormHelperText } from "@material-ui/core";

const CustomSelect = ({
  label,
  data,
  width,
  required,
  moreInfo,
  icon,
  helperText,
  placeholder,
  ...rest
}) => {
  const classes = useFormStyles();

  return (
    <CustomFormControl width={width}>
      <InputLabel id="custom-select-label">
        <CustomLabel label={label} moreInfo={moreInfo} required={required} />
      </InputLabel>
      <Select
        labelId="custom-select-label"
        id="custom-select"
        {...rest}
        className={classes.textField}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {data &&
          data.length &&
          data.map((item, index) => (
            <MenuItem key={index} value={icon ? item.title : item}>
              {icon ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "10px" }}>
                    <ListIcon>
                      <img
                        style={{ width: "25px", height: "25px" }}
                        src={item.img}
                        alt=""
                      />
                    </ListIcon>
                  </div>{" "}
                  <div>{item.title}</div>
                </div>
              ) : (
                item
              )}
            </MenuItem>
          ))}
      </Select>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </CustomFormControl>
  );
};

export default CustomSelect;
