import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import { Button } from "@material-ui/core";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import ITLimage from "../../assets/icons/billing acount form-02.jpg";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Avatar from "@material-ui/core/Avatar";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const BillingAccountOpeningForm = () => {
  const classes = useDashboardStyles();
  const avatar_classes = useEmployeeStyles();

  return (
    <CashClientLayout>
      <Cashclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{ display: "flex", justifyContent: "space-between" }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Billing Account Opening Application Form
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
              <CardHeader>
                <Avatar
                  onContextMenu={(e) => e.preventDefault()}
                  className={avatar_classes.square_avatar}
                  src={ITLimage}
                  alt="billing account openeing form Photo"
                  variant="square"
                />
              </CardHeader>
              <CardBody>
                <p
                  style={{
                    // textAlign: "center",
                    width: "100%",
                    color: "purple",
                  }}
                >
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    {" "}
                    Our customers can enjoy the following benefits by creating
                    an account today:
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    {" "}
                    We are committed to offering our customers an outstanding
                    service and our dedicated team are on hand to assist you
                    with any support you may require 24 hrs a day, 7 days a
                    week, 365 days a year.
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    {" "}
                    Priority in urgent booking (less than 24 hours) within the
                    UK & EU{" "}
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    A 14-day credit facility
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    Priority services
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    Additional users
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    Fully itemised invoices and bills to easily track your
                    spending and usage
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    No need to worry about paying during booking interpreting or
                    ordering translation services
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    Account security to stop unauthorised use
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    A dedicated accounts team
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    Booking through our easy online system
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "purple",
                    }}
                  >
                    {" "}
                    By creating an account, our customers can receive premier
                    services for an additional 10% administration charge.
                  </p>
                  <p
                    style={{
                      // textAlign: "center",
                      width: "100%",
                      color: "black",
                    }}
                  >
                    {" "}
                    <b style={{ color: "red" }}>*</b>Please be aware that all
                    applications will require additional information and a
                    credit check before approval can be given. Once your account
                    is approved and set up, we assure you that there are various
                    levels of security protection to stop unauthorised usage.
                    This will include a password for authorised users, etc. At
                    the end of each month, you will receive a fully itemised
                    bill of all bookings and orders taken, each individual cost,
                    who booked the assignment, details of the job, location and
                    any other additional time charges, if applicable. Please
                    follow the "Open A Billing Account (BAC)" link above and a
                    member of the team will get in contact with you.
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      width: "100%",
                      color: "purple",
                      fontSize: 17,
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    If you do not have login details, click below to create an
                    account
                  </p>
                  <div
                    style={{
                      marginBottom: "2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      href="https://linguistscollective.com/clientacc-opening-form"
                      rel="noreferrer"
                    >
                      <Button
                        className={`${classes.button}`}
                        style={{
                          backgroundColor: "purple",
                          color: "white",
                        }}
                        variant="contained"
                      >
                        Click here to apply for Billing Account
                      </Button>
                    </a>
                  </div>
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default BillingAccountOpeningForm;
