import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";

import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

// Material Icons
import helpIcon from "assets/icons/help.png";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Link to="/">
          <ListItem button key={1}>
            <Typography
              style={{
                color: "#aa43cc",
                fontWeight: "600",
              }}
              variant="button"
              noWrap
            >
              Home
            </Typography>
          </ListItem>
        </Link>

        <Link to="/landing-page/clients-portal">
          <ListItem button key={2}>
            <Typography
              style={{
                color: "#aa43cc",
                fontWeight: "600",
              }}
              variant="button"
              noWrap
            >
              Customer
            </Typography>
          </ListItem>
        </Link>

        <Link to="/interpreting-or-translation">
          <ListItem button key={4}>
            <Typography
              style={{
                color: "#aa43cc",
                fontWeight: "600",
              }}
              variant="button"
              noWrap
            >
              Book Interpreter / Order Translation
            </Typography>
          </ListItem>
        </Link>

        <Link to="/landing-page/about">
          <ListItem button key={3}>
            <Typography
              style={{
                color: "#aa43cc",
                fontWeight: "600",
              }}
              variant="button"
              noWrap
            >
              About
            </Typography>
          </ListItem>
        </Link>

        <Link to="/landing-page/faq">
          <ListItem button key={5}>
            <Typography
              style={{
                color: "#aa43cc",
                fontWeight: "600",
              }}
              variant="button"
              noWrap
            >
              Help
            </Typography>
            {/* <img
              style={{ width: "64px", height: "64px" }}
              src={helpIcon}
              alt="linguists_collective_help"
            /> */}
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            aria-label="Promotional"
            color="primary"
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon className={classes.icons} />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
