import { makeStyles } from "@material-ui/core/styles";

const cardBodyStyle = {
  cardBody: {
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",

    "@media screen and (max-width: 480px)": {
      padding: "0.9375rem 9px",
    },
  },
  cardBodyPlain: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  cardBodyProfile: {
    marginTop: "15px",
  },
  min100: {
    minHeight: "100px",
  },
};

const useCardBodyStyles = makeStyles(cardBodyStyle);

export default useCardBodyStyles;
