import SearchInput from "./SearchInput";
import { useState } from "react";

const SearchView = ({
  values,
  setFiltered,
  setFilter,
  setSearchText,
  setShow,
  SearchText,
  handleSearch,
  load,
  show,
}) => {
  return (
    <div>
      <SearchInput
        setFiltered={setFiltered}
        setFilter={setFilter}
        load={load}
        setSearchText={setSearchText}
        setShow={setShow}
        SearchText={SearchText}
        handleSearch={handleSearch}
        values={values}
        show={show}
      />
    </div>
  );
};

export default SearchView;
