import React, { useEffect, useState, useRef } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import swal from "sweetalert";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import { Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";

import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomModal from "components/Common/CustomModal";
import { Button, Header, Icon, Loader, Modal } from "semantic-ui-react";
import CustomTextInput from "components/Common/CustomTextInput";
import SignatureCanvas from "react-signature-canvas";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { EMAIL_REGEX } from "data";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

const Action = ({
  id,
  invID,
  load,
  status,
  bankpay,
  paystatus,
  name,
  ID,
  payment,
  estimate,
  currency,
  charge_id,
  total,
  cancel,
  refunded,
  markPaid,
  AdminID,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("token");

  const [notValid, setNotValid] = useState(false);

  const [showMarkPaid, setShowMarkPaid] = React.useState(false);
  const [showRefund, setShowRefund] = React.useState(false);

  const [showNote, setShowNote] = React.useState(false);
  const [viewNote, setViewNote] = React.useState(false);

  const [totalnote, setTotalNote] = React.useState(false);

  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  let sigPad = useRef({});

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const [pay, setPay] = React.useState({
    note: "",
    payment_ref: "",
    estimate_paid_amount: "",
    payment_date: "",
    signature: "",
    cancel_by_admin_client: "",
    refund_date: "",
    refund_reason: "",
    refund_amount: "",
    email: "",
  });

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  const handleEmailChange = (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleShow = () => {
    setShowNote(!showNote);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowNote(false);
  };

  const handleMarkPaid = (e) => {
    setAnchorEl(false);
    setShowMarkPaid(!showMarkPaid);
    setSignature(false);
  };

  const handleRefund = (e) => {
    setAnchorEl(false);
    setShowRefund(!showRefund);
    setSignature(false);
  };

  const handleBack = () => {
    setViewNote(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const handleReminder = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/send-reminder-to-direct-client-invoice/invID/${id}`,
        {
          note: pay.note,
          email: pay.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: "Reminder Sent!!!",
      });
      totalReminder();
      setPay({ ...pay, note: "", email: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const totalReminder = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/count-direct-client-invoice/invID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    totalReminder();
  }, []);

  const sendInvoice = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/send-client-invoice/invoiceID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Invoice sent!!!`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleSend = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          sendInvoice(value);

        default:
          break;
      }
    });
  };

  const sendArchive = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/archive-client-invoice/invoiceID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Moved to archive folder`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleArchive = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          sendArchive(value);

        default:
          break;
      }
    });
  };

  const cancelInv = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/cancel-client-invoice/invoiceID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Invoice cancelled!!!`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCancel = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          cancelInv(value);

        default:
          break;
      }
    });
  };

  const handleConfirm = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/mark-paid-direct-client-invoice/invID/${id}`,
        {
          payment_date: pay.payment_date,
          payment_ref: pay.payment_ref,
          estimate_paid_amount: parseInt(pay.estimate_paid_amount),
          note: pay.note,
          signature: sign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: response?.data.message,
        buttons: "Ok",
      });

      setPay({
        ...pay,
        estimate_paid_amount: "",
        payment_ref: "",
        payment_date: "",
      });
      setSign(null);
      load();
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: error.response?.data.raw.message,
        buttons: "Ok",
      });
    }
  };

  // refund function

  const handleRefundInvoice = async (e) => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/v1/refund/admin`,
        {
          charge_id: charge_id,
          reason: pay.refund_reason,
          amount: parseInt(pay.refund_amount * 100),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: response?.data.status,
        buttons: "Ok",
      });
      RefundInv();
    } catch (error) {
      swal({
        icon: "error",
        text: error.response?.data.raw.message,
        buttons: "Ok",
      });
      setEdit({ ...edit, loader: false });
    }
  };

  const RefundInv = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/refund-direct-client-invoice/invID/${id}`,
        {
          refund_date: pay.refund_date,
          refund_reason: pay.refund_reason,
          refund_amount: parseInt(pay.refund_amount * 100),
          note: pay.note,
          signature: sign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: response?.data.message,
        buttons: "Ok",
      });

      setPay({ ...pay, refund_amount: "", refund_date: "", refund_reason: "" });
      setSign(null);
      load();
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: error.response?.data.raw.message,
        buttons: "Ok",
      });
    }
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {status == "Saved" ? (
          <MenuItem onClick={handleSend}>Send</MenuItem>
        ) : null}

        {!payment && bankpay && !cancel ? (
          <MenuItem onClick={handleMarkPaid}>Mark as paid</MenuItem>
        ) : null}

        {!payment && !cancel ? (
          <MenuItem onClick={handleShow}>Send reminder ({totalnote})</MenuItem>
        ) : null}

        {payment && !refunded && !cancel ? (
          <MenuItem onClick={handleRefund}>Refund</MenuItem>
        ) : null}
        {!cancel && !payment ? (
          <MenuItem onClick={handleCancel}>Cancel</MenuItem>
        ) : null}

        <MenuItem onClick={handleArchive}>Archive (delete)</MenuItem>
      </Menu>

      {/* Mark as paid modal */}
      <Modal open={showMarkPaid} onClose={() => setShowMarkPaid(false)}>
        <Modal.Header>
          Payment Details - {invID}, {currency} Total - {total} Paid -{" "}
          {estimate}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomTextInput
              name="payment_date"
              onChange={handleChange}
              dvalue={pay.payment_date}
              label="Payment date"
              dlabel="Payment date "
              date={true}
            />
            <CustomTextInput
              name="payment_ref"
              onChange={handleChange}
              value={pay.payment_ref}
              label="Payment reference "
              edit={true}
            />
            <CustomTextInput
              number={true}
              name="estimate_paid_amount"
              onChange={handleChange}
              value={pay.estimate_paid_amount}
              label="Payment amount"
              placeholder={total}
              edit={true}
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
            <CustomTextInput label="Admin Id" value={AdminID} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {/* {paidby === "Paid by card" ? */}
          <Button
            disabled={
              !pay.payment_ref ||
              !sign ||
              !pay.payment_date ||
              !pay.estimate_paid_amount ||
              edit.loader
            }
            onClick={handleConfirm}
            color="purple"
          >
            Confirm <Icon name="chevron right" />
          </Button>
          {/* :
            <Button disabled={!pay.payment_ref || !sign || !pay.payment_date || !pay.estimate_paid_amount || edit.loader}
              onClick={RefundJob} color='purple'>
              Confirm <Icon name='chevron right' />
            </Button>} */}
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>

      {/* Refund Payment modal */}
      <Modal open={showRefund} onClose={() => setShowRefund(false)}>
        <Modal.Header>
          Refund Details - {invID}, {currency} {total}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomTextInput
              name="refund_date"
              onChange={handleChange}
              dvalue={pay.refund_date}
              label="Refund date"
              dlabel="Refund date "
              date={true}
            />
            <CustomTextInput
              name="refund_reason"
              onChange={handleChange}
              value={pay.refund_reason}
              label="Refund reference "
              edit={true}
            />
            <CustomTextInput
              number={true}
              name="refund_amount"
              onChange={handleChange}
              value={pay.refund_amount}
              label="Refund amount"
              placeholder={total}
              edit={true}
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
            <CustomTextInput label="Admin Id" value={AdminID} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {charge_id ? (
            <Button
              disabled={
                !pay.refund_reason ||
                !sign ||
                !pay.refund_date ||
                !pay.refund_amount ||
                edit.loader
              }
              onClick={handleRefundInvoice}
              color="purple"
            >
              Confirm <Icon name="chevron right" />
            </Button>
          ) : (
            <Button
              disabled={
                !pay.refund_reason ||
                !sign ||
                !pay.refund_date ||
                !pay.refund_amount ||
                edit.loader
              }
              onClick={RefundInv}
              color="purple"
            >
              Confirm <Icon name="chevron right" />
            </Button>
          )}
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>

      {/* send reminder */}
      {showNote ? (
        <Dialog open={showNote}>
          {!viewNote ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Send Reminder - {ID} - {name} - {currency} {total}
                </div>
              </DialogTitle>
              <DialogContent>
                {/* <TextField
                onChange={handleChange} value={pay.email}
                id="standard-textarea"
                label="CC Email"
                placeholder="Write email here..."
                multiline
                rows={1}
                inputProps={{ maxLength: 30 }}
                name="email"
                style={{ marginBottom: 15, width: 100 + "%" }}
              /> */}
                <CustomInputSingle
                  label="CC Email"
                  width="100%"
                  placeholder="CC Email"
                  name="email"
                  onChange={handleEmailChange}
                  value={pay.email}
                />
                {notValid && (
                  <small style={{ color: "red" }}>
                    enter valid email address
                  </small>
                )}
                <br />
                <TextField
                  onChange={handleChange}
                  value={pay.note}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="note"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.note}
                    onClick={handleReminder}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Send Reminder
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Saved Notes
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}
    </div>
  );
};

export default Action;
