import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import LinguistLayout from "layout/LinguistLayout";
// Custom Components

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LinguistProfile from "components/PublicProfileLIN/ProtectedPublicProfile/PPL";

const ManagePublicProfile = () => {
  const classes = useDashboardStyles();

  return (
    <LinguistLayout>
      <Linguist>

        <LinguistProfile />

      </Linguist>
    </LinguistLayout>
  );
};

export default ManagePublicProfile;
