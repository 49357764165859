import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "semantic-ui-react";

import {
  getSubscriber,
  removeSubscriber,
  getCookie,
} from "../../../../action/manager";
import Moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactToExcel from "react-html-table-to-excel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SearchView from "./Search/SearchView";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  button: {
    background: "#e0e1e2 none",
    border: 0,
    color: "rgba(0,0,0,.6)",
    padding: 0,
    margin: 0,
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [message, setMessage] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = getCookie("token");
  const [show, setShow] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const [SearchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload, filter]);

  const loadbookingList = () => {
    getSubscriber().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        if (filtered.length > 0) {
          setValues({ ...values, bookingList: filtered });
          setFiltered([]);
        } else {
          setValues({ ...values, bookingList: data });
        }
      }
    });
  };

  const deleteSubscriber = (_id) => {
    removeSubscriber(_id, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setMessage(data.message);
        loadbookingList();
      }
    });
  };

  const deleteConfirm = (_id) => {
    let answer = window.confirm(
      "Are you sure you want to delete this subscriber ?"
    );
    if (answer) {
      deleteSubscriber(_id);
    }
  };

  const a = bookingList?.map((uc) => uc);
  const exportPDF = (e) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const i = 0;
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(10);

    doc.setPage(i);
    //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
    // doc.setTextColor(150);
    doc.text(
      50,
      doc.internal.pageSize.height - 20,
      "Strictly Company Confidential © linguistscollective.com."
    );

    const title = "Subscriber List";
    const headers = [
      [
        "First name",
        "Last name",
        "Email",
        "Mobile number",
        "Subscription date",
      ],
    ];

    const data = bookingList.map((list) => [
      list.first_name,
      list.last_name,
      list.email,
      list.mobile_no,
      list.createdAt,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        valign: "middle",
        // halign: 'justify'
      },
      columnStyles: { 0: { fillColor: [138, 43, 226], textColor: "white" } },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    var string = doc.output("datauristring");
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    var x = window.open();
    x.document.open();
    x.document.write(embed);
    x.document.close();
  };

  return (
    <Paper className={classes.root}>
      <Button>
        <ReactToExcel
          // className = "btn"
          table="export"
          filename="excelFile"
          sheet="sheet 1"
          buttonText="Export CSV"
          className={classes.button}
        />
      </Button>
      <Button style={{ padding: "12px" }} onClick={exportPDF}>
        Export PDF
      </Button>
      <SearchView
        show={show}
        load={loadbookingList}
        setSearchText={setSearchText}
        SearchText={SearchText}
        setShow={setShow}
        setFiltered={setFiltered}
        setFilter={setFilter}
        values={bookingList}
      />
      {show && (
        <>
          {" "}
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" id="export">
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email Address</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookingList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((list) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={list._id}
                    >
                      <TableCell>{list.first_name}</TableCell>
                      <TableCell>{list.last_name}</TableCell>
                      <TableCell>{list.email}</TableCell>
                      <TableCell>{list.mobile_no}</TableCell>

                      <TableCell>
                        {Moment(list.createdAt).format(
                          "DD MMM YYYY - kk:mm:ss"
                        )}
                      </TableCell>
                      <TableCell>
                        <div>
                          <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            style={{
                              minWidth: "10px",
                              marginLeft: "3px",
                            }}
                          >
                            Action
                          </Button>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={() => deleteConfirm(list._id)}>
                              Delete
                            </MenuItem>
                          </Menu>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={bookingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
}
