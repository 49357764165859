import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import Details from "./Details";
import { Header } from "semantic-ui-react";

const AmendTimesheet = () => {
  const classes = useEmployeeStyles();

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem
          id="printArea"
          style={{ width: "210mm" }}
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={5}
        >
          <Card>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomAvatar style={{ margin: "0" }} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Header style={{ margin: 0 }} as="h4">
                    Linguists Collective Ltd
                  </Header>
                  <Header style={{ margin: 0 }} as="h4">
                    Timesheet (linguists / professionals)
                  </Header>
                </div>
              </div>

              <Details />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AmendTimesheet;
