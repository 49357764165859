import React, {  } from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import List from 'components/Employee/BookingPanel/HelpContact/LIN.jsx'
import { Box } from "@material-ui/core";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Options from "components/Employee/BookingPanel/HelpContact/Options"


const Archive = () => {
  const classes = useDashboardStyles();


  return (
    <EmployeeLayout>
      <Employee>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>Help Contact (Linguist)</p>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Options />
              </CardHeader>
              <CardBody>
                <Box pt={2}>
                  <Card>
                    <CardHeader color="primary">
                      <p className={classes.cardTitleWhite}>Answer a question</p>
                    </CardHeader>
                    <CardBody>
                      <List />
                    </CardBody>
                  </Card>
                </Box>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </Employee>
    </EmployeeLayout>
  );
};

export default Archive;
