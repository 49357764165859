import React, { useState } from "react";

// Material Components
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// Custom Styles
import useDropdownStyles from "assets/jss/components/dropDownStyles";

const CustomDropdown = ({ label, children }) => {
  const classes = useDropdownStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        color="primary"
        aria-controls={label}
        aria-haspopup="true"
        aria-label={label}
        component="span"
        onClick={handleClick}
      >
        <SettingsIcon fontSize="large" />
      </IconButton>

      <Popper
        open={Boolean(open)}
        anchorEl={anchorEl}
        placement="bottom-end"
        className={classes.toolTip}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseProfile}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default CustomDropdown;
