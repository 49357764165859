import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";
import { Button } from "semantic-ui-react";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "40%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media(max-width:768px)"]: {
      width: "100%",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearchInput = ({
  values,
  SearchText,
  setSearchText,
  setShow,
  load,
  setFiltered,
  setFilter,
  show
}) => {
  const classes = useStyles();
  const handleInput = (value) => {
    setSearchText(value);

    if (value === "" || value === undefined) {
      load();
    } else {
      setFiltered(
        values?.filter((item) => {
          if (item.id?.toLowerCase() === value?.toLowerCase()) {
            return item.id?.toLowerCase() === value?.toLowerCase();
          } else if (item.email?.toLowerCase() === value?.toLowerCase()) {
            return item.email?.toLowerCase() === value?.toLowerCase();
          } else if (item.last_name?.toLowerCase() === value?.toLowerCase()) {
            return item.last_name?.toLowerCase() === value?.toLowerCase();
          } else if (
            item.business_name?.toLowerCase() === value?.toLowerCase()
          ) {
            return item.business_name?.toLowerCase() === value?.toLowerCase();
          } else if (item.mobile_no === value) {
            return item.mobile_no === value;
          }
        })
      );
      setFilter(Math.random());
    }
  };
  return (
    <div>
      <Paper
        component="form"
        className={classes.root}
        onClick={(e) => e.preventDefault()}
      >
        <InputBase
          className={classes.input}
          placeholder="Filter contact..."
          onChange={(e) => {
            handleInput(e.target.value);
          }}
          inputProps={{ "aria-label": "search google maps" }}
          value={SearchText}
          // onChange={(e) => setText(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Button onClick={() => setShow(!show)} color="purple">
          View all
        </Button>
      </Paper>
    </div>
  );
};

export default SearchInput;
