import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styled from "@emotion/styled";
import axios from "axios";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


import { COUNTRY_DATA_SEM_UI } from "data";
import {
  Dropdown,
  Form,
  Select,
} from 'semantic-ui-react'

import Row from "../prebuilt/Row";
import CACRow from "../prebuilt/CACRow";
import BillingDetailsFields from "../prebuilt/BillingDetailsFields";
import CACBillingDetailsFields from "../prebuilt/CACBillingDetailsFields";
import SubmitButton from "../prebuilt/SubmitButton";
import CheckoutError from "../prebuilt/CheckoutError";
var iso3311a2 = require('iso-3166-1-alpha-2')

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

const CheckoutForm = ({ price, onSuccessfulCheckout, currency, cacClientID }) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [country, setCountry] = useState([])

  const stripe = useStripe();
  const elements = useElements();

  // TIP
  // use the cardElements onChange prop to add a handler
  // for setting any errors:

  const handleCardDetailsChange = ev => {
    console.log(ev?.error)
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();

    const billingDetails = {
      name: ev.target.name.value + " " + ev.target.last_name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        line2: ev.target.line2.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value,
        country: iso3311a2.getCode(country)
      },
      phone: ev.target.mobile_no.value
    };

    setProcessingTo(true);

    const cardElement = elements.getElement("card");

    try {
      const { data: clientSecret } = await axios.post(`${process.env.REACT_APP_BACKEND_API}/cashclient/v1/payment_intents`, {
        amount: Math.round((price * 100)),
        name: billingDetails.name,
        postal_code: billingDetails.address.postal_code,
        country: billingDetails.address.country,
        line1: billingDetails.address.line1,
        line2: billingDetails.address.line2,
        country: billingDetails.address.country,
        city: billingDetails.address.city,
        state: billingDetails.address.state,
        phone: billingDetails.phone,
        email: billingDetails.email,
        currency: currency.toLowerCase().split(' ')[0],
        // cacClientID: cacClientID,
      });

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret.client_secret, {
        payment_method: paymentMethodReq.paymentMethod.id
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }

      onSuccessfulCheckout(paymentIntent.id);
    } catch (err) {
      console.log(err)
      setCheckoutError(err.message);
      setProcessingTo(false);
    }
  };

  // the card element is housed within an iframe and:
  // > styles do not cascade from a parent window down into its iframes

  const iframeStyles = {
    base: {
      color: "#fff",
      fontSize: "16px",
      iconColor: "#fff",
      "::placeholder": {
        color: "#87bbfd"
      }
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE"
    },
    complete: {
      iconColor: "#cbf4c9"
    }
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true
  };

  const handleChange = async (e, data) => {
    setCountry(data.value)
  }



  // radio button
  const [value, setValue] = React.useState('female');

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <form style={{ paddingBottom: '40px' }} onSubmit={handleFormSubmit}>

      <CACRow>
        <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleRadioChange}>
            <FormControlLabel value="diff_address" control={<Radio />} label="Use Different Details" />
            <FormControlLabel value="same_address" control={<Radio />} label="Use Same Address" />
          </RadioGroup>
        </FormControl>
      </CACRow>

      { value == "diff_address" ?
        <Row>
          <BillingDetailsFields />
        </Row>
        :
        <Row>
          <CACBillingDetailsFields />
        </Row>
      }
      <Row>
        <Dropdown
          placeholder='Select Country'
          fluid
          search
          compact
          selection
          onChange={handleChange}
          options={COUNTRY_DATA_SEM_UI}
          name='country'
        />
      </Row>
      <Row>
        <CardElementContainer>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          />
        </CardElementContainer>
      </Row>
      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
      <Row>
        {/* TIP always disable your submit button while processing payments */}
        <SubmitButton disabled={isProcessing || !stripe}>
          {isProcessing ? "Processing..." : `Pay ${currency} ${price}`}
        </SubmitButton>
      </Row>
    </form>
  );
};

export default CheckoutForm;