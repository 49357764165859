import React, { } from 'react'
import { Table } from 'semantic-ui-react';
import GridContainer from "components/Grid/GridContainer";
import moment from "moment"

const Items = ({ values }) => {

    return (
        <GridContainer alignItems="center">
            <Table color={"pink"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Quotation Details</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    <Table.Row>
                        <Table.Cell>
                            Quote Reference ID
                        </Table.Cell>
                        <Table.Cell>
                            {values?.id == "undefined" ? "N/A" : values?.id ? values?.id : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Purchase order number</Table.Cell>
                        <Table.Cell>
                            {values?.purchase_order_number ? values?.purchase_order_number : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Quote request for</Table.Cell>
                        <Table.Cell>
                            {values?.quote_rqst_for ? values?.quote_rqst_for : "N/A"}
                        </Table.Cell>
                    </Table.Row>


                    <Table.Row >
                        <Table.Cell>Venue address: House no, street name</Table.Cell>
                        <Table.Cell>
                            {values?.house_street_no_name ? values?.house_street_no_name : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Address line 2</Table.Cell>
                        <Table.Cell>
                            {values?.address_line_two ? values?.address_line_two : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Town / City</Table.Cell>
                        <Table.Cell>
                            {values?.town_city ? values?.town_city : "N/A"}
                        </Table.Cell>
                    </Table.Row>



                    <Table.Row >
                        <Table.Cell>County / Region</Table.Cell>
                        <Table.Cell>
                            {values?.county_region ? values?.county_region : "N/A"}
                        </Table.Cell>
                    </Table.Row>


                    <Table.Row >
                        <Table.Cell>Post / Zip Code</Table.Cell>
                        <Table.Cell>
                            {values?.post_code ? values?.post_code : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Country</Table.Cell>
                        <Table.Cell>
                            {values?.country ? values?.country : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Other details</Table.Cell>
                        <Table.Cell>
                            {values?.other_details ? values?.other_details : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Quote request details</Table.Cell>
                        <Table.Cell>
                            {values?.request_details ? values?.request_details : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Project budget</Table.Cell>
                        <Table.Cell>
                            {values?.project_fix_budget ? values?.project_fix_budget : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Source language </Table.Cell>
                        <Table.Cell>
                            {values?.source_language ? values?.source_language : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Source language skill level</Table.Cell>
                        <Table.Cell>
                            {values?.source_skills_required ? values?.source_skills_required : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Target Language</Table.Cell>
                        <Table.Cell>
                            {values?.target_language ? values?.target_language : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Target Language skill level</Table.Cell>
                        <Table.Cell>
                            {values?.target_skills_required ? values?.target_skills_required : "N/A"}
                        </Table.Cell>
                    </Table.Row>



                    <Table.Row >
                        <Table.Cell>Preferred Linguist / Provider ID Number</Table.Cell>
                        <Table.Cell>
                            {values?.provider_id_number ? values?.provider_id_number : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Prefered gender</Table.Cell>
                        <Table.Cell>
                            {values?.gender ? values?.gender : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Security clearance</Table.Cell>
                        <Table.Cell>
                            {values?.security_clearance ? values?.security_clearance : "N/A"}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Timezone</Table.Cell>
                        <Table.Cell>
                            {values?.timezone}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell>Estimate / Quotation needed by</Table.Cell>
                        <Table.Cell>
                            {moment(values?.quote_by).format('DD-MMM-YYYY, HH:mm')}
                        </Table.Cell>
                    </Table.Row>



                    <Table.Row >
                        <Table.Cell>Payment type</Table.Cell>
                        <Table.Cell>
                            {values?.pay_type ? values?.pay_type : "N/A"}
                        </Table.Cell>
                    </Table.Row>


                </Table.Body>
            </Table>
        </GridContainer>
    )
}

export default Items