import { CHANGE_CONTACT_INPUT, UPDATE_CONTACT_DATA } from "redux/actions/actionTypes";

const INITIAL_STATE = {
    data: {
        company_name: '', 
        company_address: '', 
        company_phone_no: '', 
        company_general_email: '', 
        company_emergency_mobile_no: '',
        company_registration_no: '',
        vat_number: '', 
        vat_country_name: '', 
        company_legal_name: '',
        booking_secretary_no: '', 
        booking_secretary_email: '', 
        client_relation_team_member: '',
        client_support_email: '',
        sales_and_marketing_team_member: '', 
        sales_and_marketing_email: '', 
        hr_team_number: '', 
        linguist_support_email: '',
        recruitment_number: '',
        recruitment_email: '', 
        account_department_number: '', 
        account_and_invoice_support_email: '',
        complience_officer: '', 
        documentation_and_verification_officer: '', 
        data_controller: '', 
        amlro: '', 
        webmaster_report_system_issue: ''
    }
}

const contactCardReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case CHANGE_CONTACT_INPUT:
        return {
            ...state,
            data: {
              ...state.data,
              [payload.name]: payload.value
            }
        }
    
      case UPDATE_CONTACT_DATA: 
        return {
            ...state,
            data: payload
        }
  
      default:
        return state;
    }
  };
  
  export default contactCardReducer;