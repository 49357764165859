import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { Header } from 'semantic-ui-react'
import RichTextEditor from 'react-rte';
import Cookies from 'js-cookie'
import Axios from 'axios'
import swal from 'sweetalert'
import CustomInputSingle from "components/Common/CustomInputSingle";
import useFormStyles from "assets/jss/components/auth/formStyle";

const Details = () => {
  const classes = useFormStyles();
  const [text, setText] = useState()
  const token = Cookies.get('token')
  const [active, setActive] = useState("");
  const [details, setDetails] = useState({
    content: RichTextEditor.createEmptyValue(),
    title: "",
    loader: false
  })


  const Active = (e, id) => {
    e.preventDefault();
    setActive(id);
    setDetails({ ...details, postedTo: id });
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleText = value => {
    setText(value.toString('html'))
    setDetails({ ...details, content: value })
  }

  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true })

    try {
      const response = await Axios.post(`${process.env.REACT_APP_BACKEND_API}/manager-admin/write-privacy-policy`, {
        title: details.title,
        content: text
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setDetails({ ...details, loader: false, title: "" })
      swal({
        text: 'Privacy Policy successfully added!!!',
        buttons: 'Ok',
        icon: 'success'
      })
      // .then(function () {
      //   window.location = "/admin/cms";
      // });
    } catch (error) {
      console.log(error.response)
      setDetails({ ...details, loader: false })
    }
  }



  return (
    <div>

      <CustomInputSingle
        onChange={handleChange}
        label="Title"
        name="title"
        value={details.title}
        width="100%"
        placeholder="Enter Title..."
        required
      />

      <br />
      <Header as='h4'>Content</Header>
      <RichTextEditor
        value={details.content}
        onChange={handleText}
        required
      />

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button loading={details.loader} onClick={handleSubmit} disabled={!details.title || !details.content} color='purple'>Submit</Button>
      </div>

    </div>

  )
}

export default Details
