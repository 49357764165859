import { v4 as uuidv4 } from "uuid";
import { Label, Menu, Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Action from "../CloseTimesheet/Action";
import moment from "moment";

// Custom Components
import CustomtableWIthStyle from "components/Common/CustomtableWIthStyle";

const ViewAll = ({ data }) => {
  return (
    <div>
      <div style={{ width: "100%", height: "500px", overflowX: "scroll" }}>
        <Table color={"pink"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="1">Assignment ID</Table.HeaderCell>
              <Table.HeaderCell width="1">Start date & time</Table.HeaderCell>
              <Table.HeaderCell width="1">Duration</Table.HeaderCell>
              <Table.HeaderCell width="1">Source language</Table.HeaderCell>
              <Table.HeaderCell width="1">Target language</Table.HeaderCell>
              <Table.HeaderCell width="1">Service Required</Table.HeaderCell>
              <Table.HeaderCell width="1">Venue/Remote</Table.HeaderCell>
              <Table.HeaderCell width="1">Security Level</Table.HeaderCell>
              <Table.HeaderCell width="1">Status</Table.HeaderCell>
              <Table.HeaderCell width="1">Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.startdateArray.map((dt, i) => (
              <Table.Row key={data._id}>
                <Table.Cell>
                  {/* <Link
                    to={`/cash-client/view-cash-client-booking-interpreting/${data._id}`}
                  > */}
                  {data.id}
                  {/* </Link> */}
                </Table.Cell>
                <Table.Cell>
                  {moment(data.startdateArray[i]).format("DD-MM-YYYY")}
                  <br /> {data.starttimeArray[i]}
                </Table.Cell>
                <Table.Cell>{data.gethour}</Table.Cell>
                <Table.Cell>{data.source_language}</Table.Cell>
                <Table.Cell>{data.target_language}</Table.Cell>
                <Table.Cell>{data.target_linguist_skill_level}</Table.Cell>
                <Table.Cell>{data.typesof_interpreting_required}</Table.Cell>
                <Table.Cell>
                  {data.country ? data.country : data.remote_address}
                </Table.Cell>
                <Table.Cell>{data.status}</Table.Cell>
                <Table.Cell>
                  <Action id={data._id} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default ViewAll;
