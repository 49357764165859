import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Skeleton from "@material-ui/lab/Skeleton";
import Axios from "axios";
import swal from "sweetalert";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import ReverseMap from "components/Common/Map";
import { useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const Welcome = () => {
  const classes = useLandingpageStyles();
  const token = Cookies.get("LINtoken");
  const [state, setState] = useState({
    lat: "",
    lng: "",
  });

  const [user, setUser] = useState();
  const [address, setAddress] = useState(Cookies.get("visitor_address"));
  const matches = useMediaQuery("(max-width:1024px)");
  const message = matches
    ? `Please enable location from browser taskbar or settings \n(finding your location helps us to improve app security & privacy)\n
steps to turn on/off location on Chrome: 
1 - On your Android phone or tablet, open the Chrome app Chrome.
2 - To the right of the address bar, tap More More and then Settings.
3 - Tap Site settings and then Location.
4 - Turn Location on .

steps to turn on/off location on Safari:
1 - Open your iOS's Settings app.
2 - Tap [Privacy] > [Location Services]
3 - Make sure that Location Services is ON
4 - Tap [Privacy] > [Location Services] > [Safari Websites] 
5 - Tap [Allow Location Access] > Set to [While Using the App]`
    : `Please enable location from browser taskbar or settings \n(finding your location helps us to improve app security & privacy)\n
steps to turn on/off location on Chrome:            
1 - On your computer, open Chrome.
2 - At the top right, click More and then Settings.
3 - Under "Privacy and security," click Site settings.
4 - Click Location.
5 - Turn Ask before accessing on or off.

steps to turn on/off location on MacOS Safari:            
1 - Click on Safari written on the top left corner.
2 - Go to Preferences.
3 - Under the Privacy tab, select Website Tracking.
4 - Uncheck the options that say “Prevent Cross-site tracking” and “Ask websites not to track me”.

steps to turn on/off location on Microsoft Edge:   
1 - Open the Action Center on your Windows 10 computer.
2 - Select All Settings.
3 - Go to Privacy and select Location
4 - You’ll see a slider that says Location Service. Turn it on.

`;
  const getAddress = (address) => {
    setAddress(address);
  };
  useEffect(() => {
    const source = Axios.CancelToken.source();
    const loadDashboard = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );
        setUser(response?.data);
        if (Cookies.get("visitor_address")) {
          setAddress(Cookies.get("visitor_address"));
        } else if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              setState({
                ...state,
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            function (error) {
              if (error.code == error.PERMISSION_DENIED) {
                swal({
                  icon: "warning",
                  title: "Some features will stop working",
                  text: message,
                }).then(setAddress("permission denied"));
              }
            },
            {
              enableHighAccuracy: true,
            }
          );
        } else {
          swal({
            icon: "warning",
            title: "Some features will stop working",
            text: message,
          }).then(setAddress("permission denied"));
        }
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          throw error;
        }
      }
    };

    loadDashboard();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Card>
      <CardBody>
        {state.lng !== "" &&
          state.lat !== "" &&
          !Cookies.get("visitor_address") && (
            <ReverseMap
              reverse={true}
              lng={state.lng}
              lat={state.lat}
              setAddress={getAddress}
            />
          )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              flexWrap: "wrap",
            }}
          >
            {/* <h2>Welcome &nbsp;</h2> */}
            {!user ? (
              <Skeleton animation="wave" width="150px" component="h2" />
            ) : (
              <h2 style={{ margin: "0" }}>
                {user.FirstName + " " + user.LastName} | User ID: {user.id}
              </h2>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              flexWrap: "wrap",
            }}
          >
            <h4>You're logged in from or around: &nbsp;</h4>
            {address ? (
              <h4 style={{ margin: 0 }}>
                <b>{address}</b>{" "}
              </h4>
            ) : (
              <Skeleton animation="wave" width="150px" component="h2" />
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              flexWrap: "wrap",
            }}
          >
            <h4>Your IP Address: &nbsp;</h4>
            {user ? (
              <h4 style={{ margin: 0 }}>
                {user?.IP_Address?.filter((dt) => dt != "").map((st) => (
                  <b>{st}</b>
                ))}
              </h4>
            ) : (
              <Skeleton animation="wave" width="150px" component="h2" />
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              flexWrap: "wrap",
            }}
          >
            <Typography variant="subtitle2">
              <b></b>Recommended web browser: for best performance please use
              <a href="JavaScript:newPopup('https://www.google.co.uk/chrome/?brand=FHFK&gclsrc=aw.ds&gclid=EAIaIQobChMImueQy6yL8QIVCt1RCh0lIQ-7EAAYASAAEgJdS_D_BwE');">
                {" "}
                Google Chrome{" "}
              </a>
              or
              <a href="JavaScript:newPopup('https://www.microsoft.com/en-us/edge');">
                {" "}
                Microsoft Edge.
              </a>
            </Typography>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Welcome;
