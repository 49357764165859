import React from "react";

// Custom Components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const CommonCard = ({ title, children, ...rest }) => {
  const classes = useDashboardStyles();

  return (
    <Card {...rest}>
      {title ? (
        <CardHeader color="primary">
          <p className={`${classes.cardCategory} ${classes.cardTitleWhite}`}>
            {title}
          </p>
        </CardHeader>
      ) : null}
      <CardBody min100>{children}</CardBody>
    </Card>
  );
};

export default CommonCard;
