import React from "react";
import EmployeeLayout from "layout/EmployeeLayout";
import Employee from "components/RouteProtection/Employee/Employee";
import { useDispatch, useSelector } from "react-redux";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { Button } from "@material-ui/core";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import Axios from "axios";
import { changeCrInput } from "redux/actions/crAction";
import CustomTextInput from "components/Common/CustomTextInput";
import swal from "sweetalert";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { SECURITY_CLEARANCE_LEVEL } from "data";

const ViewContactCard = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useDashboardStyles();
  const btnClass = useLandingpageStyles();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.crReducer);

  const {
    proof_address_photo,
    passport_ni_photo,
    insurance_document,
    relevant_document_photo,
    interpreting_document,
    translation_document,
    psi_pst_document,
    other_document,
    relevant_certificate_photo,
    reference_letter,
    security_clearance_document_1,
    security_clearance_document_2,
    security_clearance_document_3,
    security_clearance_document_4,
    title_security_clearance_1,
    title_security_clearance_2,
    title_security_clearance_3,
    title_security_clearance_4,
    security_certificate_expiry_date_1,
    security_certificate_expiry_date_2,
    security_certificate_expiry_date_3,
    security_certificate_expiry_date_4,
    upload_cv,
  } = data;

  const handleFiles1 = (file) => {
    const inputData = {
      name: "proof_address_photo",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles2 = (file) => {
    const inputData = {
      name: "passport_ni_photo",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles3 = (file) => {
    const inputData = {
      name: "insurance_document",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles4 = (file) => {
    const inputData = {
      name: "relevant_document_photo",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles5 = (file) => {
    const inputData = {
      name: "interpreting_document",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles6 = (file) => {
    const inputData = {
      name: "translation_document",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles7 = (file) => {
    const inputData = {
      name: "psi_pst_document",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles8 = (file) => {
    const inputData = {
      name: "other_document",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles9 = (file) => {
    const inputData = {
      name: "relevant_certificate_photo",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles10 = (file) => {
    const inputData = {
      name: "reference_letter",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles11 = (file) => {
    const inputData = {
      name: "security_clearance_document_1",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles12 = (file) => {
    const inputData = {
      name: "security_clearance_document_2",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles13 = (file) => {
    const inputData = {
      name: "security_clearance_document_3",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles14 = (file) => {
    const inputData = {
      name: "security_clearance_document_4",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleFiles15 = (file) => {
    const inputData = {
      name: "upload_cv",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeCrInput({
        name,
        value,
      })
    );
  };

  const handleNext = async (e) => {
    let formData = new FormData();

    if (!proof_address_photo?.contentType) {
      formData.append("proof_address_photo", proof_address_photo[0] || "");
      formData.append("passport_ni_photo", passport_ni_photo[0] || "");
      formData.append("insurance_document", insurance_document[0] || "");
      formData.append(
        "relevant_document_photo",
        relevant_document_photo[0] || ""
      );
      formData.append("interpreting_document", interpreting_document[0] || "");
      formData.append("translation_document", translation_document[0] || "");
      formData.append("psi_pst_document", psi_pst_document[0] || "");
      formData.append("other_document", other_document[0] || "");
      formData.append(
        "relevant_certificate_photo",
        relevant_certificate_photo[0] || ""
      );
      formData.append("reference_letter", reference_letter[0] || "");
      formData.append("upload_cv", upload_cv[0] || "");
      formData.append(
        "security_clearance_document_1",
        security_clearance_document_1[0] || ""
      );
      formData.append(
        "security_clearance_document_2",
        security_clearance_document_2[0] || ""
      );
      formData.append(
        "security_clearance_document_3",
        security_clearance_document_3[0] || ""
      );
      formData.append(
        "security_clearance_document_4",
        security_clearance_document_4[0] || ""
      );
      formData.append(
        "title_security_clearance_1",
        data.title_security_clearance_1 || ""
      );
      formData.append(
        "title_security_clearance_2",
        data.title_security_clearance_2 || ""
      );
      formData.append(
        "title_security_clearance_3",
        data.title_security_clearance_3 || ""
      );
      formData.append(
        "title_security_clearance_4",
        data.title_security_clearance_4 || ""
      );
      formData.append(
        "security_certificate_expiry_date_1",
        data.security_certificate_expiry_date_1 || ""
      );
      formData.append(
        "security_certificate_expiry_date_2",
        data.security_certificate_expiry_date_2 || ""
      );
      formData.append(
        "security_certificate_expiry_date_3",
        data.security_certificate_expiry_date_3 || ""
      );
      formData.append(
        "security_certificate_expiry_date_4",
        data.security_certificate_expiry_date_4 || ""
      );
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/update-linguist-documents/linID/${id}`,
        formData
      );
      swal({
        title: "Success!",
        text: `${response?.data?.message}`,
        icon: "success",
        buttons: "Ok",
      }).then(() => {
        window.location = `/employee/upload-linguist-documents/${id}`;
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const isAllow =
    (title_security_clearance_1 && !security_certificate_expiry_date_1) ||
    (title_security_clearance_2 && !security_certificate_expiry_date_2) ||
    (title_security_clearance_3 && !security_certificate_expiry_date_3) ||
    (title_security_clearance_4 && !security_certificate_expiry_date_4) ||
    (security_certificate_expiry_date_1 && !title_security_clearance_1) ||
    (security_certificate_expiry_date_2 && !title_security_clearance_2) ||
    (security_certificate_expiry_date_3 && !title_security_clearance_3) ||
    (security_certificate_expiry_date_4 && !title_security_clearance_4);

  return (
    <EmployeeLayout>
      <Employee>
        <Card>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            color="primary"
          >
            <p className={classes.cardTitleWhite}>Upload Linguist Document </p>
            <IconButton color="inherit" onClick={() => history.goBack()}>
              <ArrowBackIcon />
            </IconButton>
          </CardHeader>
          <CardBody>
            <GridContainer
              justify="center"
              className={classes.container}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Passport or National ID Card or Residence Permit Card
                    </p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles2}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Proof of Address</p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles1}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer
              justify="center"
              className={classes.container}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Insurance Document</p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles3}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Any Other / Contract Documents
                    </p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles4}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer
              justify="center"
              className={classes.container}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Interpreting Qualification Document
                    </p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles5}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 10MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={10000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Translation Qualification Document
                    </p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles6}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 10MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={10000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer
              justify="center"
              className={classes.container}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      PSI / PST Experience Reference Letter
                    </p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles7}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Interpreting or Translation Experience Reference Letter
                    </p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles8}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer
              justify="center"
              className={classes.container}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      General Qualification Certificate or Relevant Certificate
                    </p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles9}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 10MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={10000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Work Reference Letter
                    </p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles10}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer
              justify="center"
              className={classes.container}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Security Clearence Documents(1)
                    </p>
                  </CardHeader>
                  <CardBody>
                    <CustomTextInput
                      onChange={handleChange}
                      name="title_security_clearance_1"
                      label="Security clearance"
                      select={SECURITY_CLEARANCE_LEVEL}
                      svalue={data.title_security_clearance_1}
                      style={{ width: "100%" }}
                    />
                    <CustomTextInput
                      name="security_certificate_expiry_date_1"
                      onChange={handleChange}
                      label=" Security clearance expiry date"
                      dlabel="Expiry date"
                      dvalue={data.security_certificate_expiry_date_1}
                      date={true}
                    />
                  </CardBody>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles11}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Security Clearence Documents(2)
                    </p>
                  </CardHeader>
                  <CardBody>
                    <CustomTextInput
                      onChange={handleChange}
                      name="title_security_clearance_2"
                      label="Security clearance"
                      select={SECURITY_CLEARANCE_LEVEL}
                      svalue={data.title_security_clearance_2}
                      style={{ width: "100%" }}
                    />
                    <CustomTextInput
                      name="security_certificate_expiry_date_2"
                      onChange={handleChange}
                      label=" Security clearance expiry date"
                      dlabel="Expiry date"
                      dvalue={data.security_certificate_expiry_date_2}
                      date={true}
                    />
                  </CardBody>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles12}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer
              justify="center"
              className={classes.container}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Security Clearence Documents(3)
                    </p>
                  </CardHeader>
                  <CardBody>
                    <CustomTextInput
                      onChange={handleChange}
                      name="title_security_clearance_3"
                      label="Security clearance"
                      select={SECURITY_CLEARANCE_LEVEL}
                      svalue={data.title_security_clearance_3}
                      style={{ width: "100%" }}
                    />
                    <CustomTextInput
                      name="security_certificate_expiry_date_3"
                      onChange={handleChange}
                      label=" Security clearance expiry date"
                      dlabel="Expiry date"
                      dvalue={data.security_certificate_expiry_date_3}
                      date={true}
                    />
                  </CardBody>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles13}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Security Clearence Documents(4)
                    </p>
                  </CardHeader>
                  <CardBody>
                    <CustomTextInput
                      onChange={handleChange}
                      name="title_security_clearance_4"
                      label="Security clearance"
                      select={SECURITY_CLEARANCE_LEVEL}
                      svalue={data.title_security_clearance_4}
                      style={{ width: "100%" }}
                    />
                    <CustomTextInput
                      name="security_certificate_expiry_date_4"
                      onChange={handleChange}
                      label=" Security clearance expiry date"
                      dlabel="Expiry date"
                      dvalue={data.security_certificate_expiry_date_4}
                      date={true}
                    />
                  </CardBody>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles14}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <GridContainer
              justify="center"
              className={classes.container}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Upload CV</p>
                  </CardHeader>
                  <CardBody>
                    <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                      onChange={handleFiles15}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "100%",
              }}
            >
              <Button
                className={`${btnClass.button}`}
                color="primary"
                variant="contained"
                onClick={handleNext}
                disabled={isAllow}
              >
                Submit
              </Button>
            </div>
          </CardBody>
        </Card>
      </Employee>
    </EmployeeLayout>
  );
};

export default ViewContactCard;
