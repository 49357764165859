import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import history from "utils/history"
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Options from "components/Admin/ControlPanel/Archive/Options"
import INVOptions from "components/Admin/ControlPanel/Archive/INV/INVOptions"

import LinInvoiceForClient from "components/Admin/ControlPanel/Archive/INV/LinInvoiceForClient"
import LinInvoiceForPRC from "components/Admin/ControlPanel/Archive/INV/PRCDirectInvoice"
import DirectINVForLIN from "components/Admin/ControlPanel/Archive/INV/LINDirectInvoiceByAdmin"
import DirectINVForClient from "components/Admin/ControlPanel/Archive/INV/ClientDirectInvoiceByAdmin"
import DirectINVForSPPO from "components/Admin/ControlPanel/Archive/INV/SPPODirectInvoiceByAdmin"
import DirectQTEForPRC from "components/Admin/ControlPanel/Archive/INV/PRCDirectQuote"
import DirectQTEForClient from "components/Admin/ControlPanel/Archive/INV/ClientDirectQuoteByAdmin"
import RequestedQuoteCAC from "components/Admin/ControlPanel/Archive/INV/RequestedQuoteCAC"
import RequestedQuoteBAC from "components/Admin/ControlPanel/Archive/INV/RequestedQuoteBAC"
import LINProvidedQuote from "components/Admin/ControlPanel/Archive/INV/LINProvidedQuote"



const Archive = () => {
  const classes = useDashboardStyles();
  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>
                  Invoice and Quote {" "}
                  {history.location.pathname.includes("admin/invoiced-by-linguist-for-all-client") ?
                    "(Invoiced by linguist for all client)" :
                    history.location.pathname.includes("admin/invoiced-by-linguist-for-PRC") ?
                      "(Direct Invoiced by linguist for PRC)" :
                      history.location.pathname.includes("admin/invoiced-by-admin-for-linguist") ?
                        "(Direct Invoiced by admin for Linguist)" :
                        history.location.pathname.includes("admin/invoiced-by-admin-for-all-client") ?
                          "(Direct Invoiced by admin for all client)" :
                          history.location.pathname.includes("admin/invoiced-by-admin-for-sppo") ?
                            "(Direct Invoiced by admin for SPPO)" :
                            history.location.pathname.includes("admin/quote-by-linguist-for-PRC") ?
                              "(Direct Quote by linguist for PRC)" :
                              history.location.pathname.includes("admin/quote-by-admin-for-client") ?
                                "(Direct Quote by admin for all client)" :
                                history.location.pathname.includes("admin/archived-requested-quote-by-cac") ?
                                  "(Requested Quote By CAC)" :
                                  history.location.pathname.includes("admin/archived-requested-quote-by-bac") ?
                                    "(Requested Quote By BAC)" :
                                    history.location.pathname.includes("admin/archived-provided-quote-by-linguist") ?
                                      "(Provided Quote By Linguist)" : null}
                </p>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Options />
              </CardHeader>
            </Card>
            <INVOptions />
            <Card>
              {history.location.pathname.includes("admin/invoiced-by-linguist-for-all-client") ?
                <LinInvoiceForClient /> :
                history.location.pathname.includes("admin/invoiced-by-linguist-for-PRC") ?
                  <LinInvoiceForPRC /> :
                  history.location.pathname.includes("admin/invoiced-by-admin-for-linguist") ?
                    <DirectINVForLIN /> :
                    history.location.pathname.includes("admin/invoiced-by-admin-for-all-client") ?
                      <DirectINVForClient /> :
                      history.location.pathname.includes("admin/invoiced-by-admin-for-sppo") ?
                        <DirectINVForSPPO /> :
                        history.location.pathname.includes("admin/quote-by-linguist-for-PRC") ?
                          <DirectQTEForPRC /> :
                          history.location.pathname.includes("admin/quote-by-admin-for-client") ?
                            <DirectQTEForClient /> :
                            history.location.pathname.includes("admin/archived-requested-quote-by-cac") ?
                              <RequestedQuoteCAC /> :
                              history.location.pathname.includes("admin/archived-requested-quote-by-bac") ?
                                <RequestedQuoteBAC /> :
                                history.location.pathname.includes("admin/archived-provided-quote-by-linguist") ?
                                  <LINProvidedQuote /> : null}
            </Card>
          </GridItem>
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default Archive;
