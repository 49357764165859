import GlobalStyles from "./prebuilt/GlobalStyles";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CustomAvatar from 'components/Common/CustomAvatar'
import { Header } from "semantic-ui-react";

// Learning
// To best leverage Stripe’s advanced fraud functionality,
// include this script on every page, not just the checkout page.
// This allows Stripe to detect anomalous behavior that may be indicative
// of fraud as customers browse your website.
// Note: This is why we are adding it to a StripeLayout component.

const stripePromise = loadStripe(process.env.REACT_APP_KEY);

// TIP
// call loadStripe outside of a component
// in that way there's no chance it will get
// called more times than it needs to

const StripeLayout = ({ children, title, avatar, clientId }) => {
  return (
    <>
    <Header style= {{margin: '0'}} as= 'h1' color= 'purple' textAlign= 'center'>Linguists Collective</Header>
    {avatar ? null : <CustomAvatar />}
      {avatar ? null : <GlobalStyles />}
      {clientId ? <Header style= {{margin: '0'}} as= 'h3' color= 'purple' textAlign= 'center'>Assignment ID: {clientId}</Header> : null}
      {/* <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head> */}
      <Elements stripe={stripePromise}>{children}</Elements>
    </>
  );
};

export default StripeLayout;