// Custom Components
import CustomSelect from "components/Common/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { changeMBCurrency } from "redux/actions/caoAction";

import { CURRENCY_STRIPE } from "data";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  noPrint: {
    "@media print": {
      display: "none !important",
    },
  },
});

const PriceDropdown = () => {
  const CURRRENCY_DATA = ["GBP", "CAD", "USD", "AUD", "EUR"];
  //const CURRRENCY_DATA = ["Pound £", "EUR €", "CA $", "US $", "AU $"];
  const dispatch = useDispatch();
  const { currency } = useSelector((state) => state.caoReducer);
  const printClasses = useStyles();

  const handleChange = (e) => {
    dispatch(changeMBCurrency(e.target.value));
  };

  return (
    <div
      className={printClasses.noPrint}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "1rem",
      }}
    >
      <CustomSelect
        onChange={handleChange}
        label="Currency"
        width="10%"
        name="Currency"
        data={CURRENCY_STRIPE}
        value={currency}
      />
    </div>
  );
};

export default PriceDropdown;
