import React from "react";
import { useHistory } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import Details from './Details'
import { Header } from "semantic-ui-react";

const TimesheetCAC = ({ title }) => {
  const classes = useEmployeeStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem  xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody id= 'printArea'>
              <div style= {{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              {/* <ProfileCard
                  image= 'https://image.freepik.com/free-photo/happy-man-taking-pictures-with-digital-camera_93675-22552.jpg'
                  header = 'Freelancer name'
                  meta = 'Freelancer Id No'
                  width= '200px'
              /> */}
              <CustomAvatar style= {{margin: '0'}} />
              <div style= {{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Header style= {{margin: 0}} as= 'h4'>Linguists Collective Ltd</Header>
                <Header style= {{margin: 0}} as= 'h4'>Timesheet</Header>
              </div>
              </div>
              {/* <div className={`${classes.textCenter} ${classes.title}`}>
                
              </div>   */}
              <Details />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default TimesheetCAC;
