import QuoteRqstPage from "pages/QuoteRequest";
const QUOTE_REQUEST = [
  {
    id: 150,
    type: "form",
    text: "Quote Request",
    subText: "Quote Request",
    path: "quoterequest-form",
    component: QuoteRqstPage,
  },
];

export default QUOTE_REQUEST;
