import Axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import { Button } from "semantic-ui-react";
// Custom Components
import CommonCard from "components/Common/CommonCard";
import CustomDateTime from "components/Common/CustomDateTime";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import history from "utils/history";

const MyNotes = () => {
  const [values, setValues] = useState({ reload: false });

  const token = Cookies.get("token");
  const [totalnote, setTotalNote] = React.useState(false);

  const [deadlineCom, setDeadline] = useState(null);
  const [priorityCom, setPriority] = useState(0);


  const [pay, setPay] = React.useState({
    admin_note: "",
    priority: "",
    deadline: "",
  });
  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };
  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const handleNote = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/write-my-note`,
        {
          admin_note: pay.admin_note,
          priority: priorityCom,
          deadline: deadlineCom,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: "Note successfully added",
      });
      setPay({ ...pay, admin_note: "", loader: false });
      setEdit({ ...edit, loader: false });
      getTotalNote();
      loadList();
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-my-note`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalNote = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-my-note`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
    getTotalNote();
  }, []);

  const handleVeiwNote = async () => {
    history.push(`/admin/my-notes`);
  };

  return (
    <CommonCard title="My Notes / To Do List">
      <>
        <TextField
          onChange={handleChange}
          value={pay.admin_note}
          id="standard-textarea"
          label="Type here... (max 432 ch.)"
          placeholder="Write here your own confidential note or to do list..."
          multiline
          rows={4}
          inputProps={{ maxLength: 432 }}
          name="admin_note"
          required
          style={{ marginBottom: 15, width: 100 + "%" }}
        />

        <CustomAutoSelect
          required
          label="Priority"
          width="100%"
          data={["Note", "Low", "Medium", "High"]}
          onChange={(e, value) => setPriority(value)}
        />
        {priorityCom !== "Note" && (
          <CustomDateTime
            label="Deadline"
            width="100%"
            onChange={(e) => setDeadline(e.target.value)}
          />
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
            marginBottom: 20,
          }}
        >
          <Button color="purple" onClick={handleVeiwNote}>
            View Note ({totalnote})
          </Button>
          <Button
            disabled={!pay.admin_note || !priorityCom}
            onClick={handleNote}
            color="purple"
            variant="outlined"
            startIcon={<SaveIcon />}
          >
            Save Note
            {edit.loader && <div class="ui active inline loader"></div>}
          </Button>
        </div>
      </>
    </CommonCard>
  );
};

export default MyNotes;
