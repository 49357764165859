import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// Custom Styles
import useCardIconStyles from "assets/jss/components/cardIconStyle";

const CardIcon = (props) => {
  const classes = useCardIconStyles();
  const { className, children, color, ...rest } = props;
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[color + "CardHeader"]]: color,
    [className]: className !== undefined,
  });
  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
};

CardIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
  ]),
  children: PropTypes.node,
};

export default CardIcon;
