import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { Document, Page } from 'react-pdf';

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

const ViewContactCard = () => {
  const { id } = useParams()
  const history = useHistory()
  const classes = useDashboardStyles();
  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}> Linguist Translation Qualifications Document</p>
                <i className="icon list arrow left"
                  onClick={() => {
                    history.goBack()
                  }}></i>
              </CardHeader>
              <CardBody >
                <GridContainer justify="center" className={classes.container}>
                  <GridItem xs={12} sm={12} md={4} lg={12} xl={12}>
                    <Card>
                      <object data={`${process.env.REACT_APP_BACKEND_API}/linguists/translation-document/${id}`} type="application/pdf" width="100%" height="1000px">
                      </object>
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default ViewContactCard;
