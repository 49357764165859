import React, { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import EditableText from "./EditableText";
import { useParams } from "react-router-dom";
import Axios from "utils/axios";
import swal from "sweetalert";

const Items = () => {
  const { id } = useParams()
  const [values, setValues] = useState({ reload: false });
  const [reload, setReload] = useState(0);

  const getEventDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/view-event-details/eventID/${id}`);
      setValues(response?.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getEventDetails();
  }, [reload]);

  const handleUpload = async (e) => {
    document.querySelector("#file").click();
  };

  const handleFiles = async (e) => {
    if (e.target.files.length) {
      let fd = new FormData();

      fd.append("banner", e.target.files[0]);

      try {
        const response = await Axios.put(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/edit-event/eventID/${id}`,
          fd,
        );
        swal({
          icon: "success",
          text: "Thank you for updating event banner.",
          buttons: false,
        }).then(function () {
          window.location = `/admin/edit-events-and-meetings/${id}`;
        });
      } catch (error) {
        console.log(error.response);
        if (error.response?.data) {
          swal({
            icon: "error",
            text: error?.response?.data?.error,
            buttons: false,
            dangerMode: true,
          });
        }
      }
    }
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
        <Card>
          <Table color={"pink"}>
            <Table.Body>

              <Table.Row>
                <Table.Cell>
                  Subject
                </Table.Cell>
                <Table.Cell>
                  {values?.subject == "undefined" ? (
                    " "
                  ) : values?.subject ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.subject}
                      readOnly={false}
                      id={values?._id}
                      variant="outlined"
                      name={"subject"}
                    />
                  ) : (
                    "N/A"
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Event details</Table.Cell>
                <Table.Cell>
                  {values?.details == "undefined" ? (
                    " "
                  ) : values?.details ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.details}
                      readOnly={false}
                      id={values?._id}
                      variant="outlined"
                      name={"details"}
                      details={true}
                    />
                  ) : (
                    "N/A"
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Venue Address</Table.Cell>
                <Table.Cell>
                  {values?.venue_address == "undefined" ? (
                    " "
                  ) : values?.venue_address ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.venue_address}
                      readOnly={false}
                      id={values?._id}
                      variant="outlined"
                      name={"venue_address"}
                    />
                  ) : (
                    "N/A"
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Remote Link</Table.Cell>
                <Table.Cell>
                  {values?.remote_link == "undefined" ? (
                    " "
                  ) : values?.remote_link ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.remote_link}
                      readOnly={false}
                      id={values?._id}
                      variant="outlined"
                      name={"remote_link"}
                    />
                  ) : (
                    "N/A"
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Joining Details</Table.Cell>
                <Table.Cell>
                  {values?.joining_details == "undefined" ? (
                    " "
                  ) : values?.joining_details ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.joining_details}
                      readOnly={false}
                      id={values?._id}
                      variant="outlined"
                      name={"joining_details"}
                    />
                  ) : (
                    "N/A"
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Timezone</Table.Cell>
                <Table.Cell>
                  {values?.timezone ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.timezone}
                      readOnly={false}
                      id={values?._id}
                      variant="outlined"
                      name={"timezone"}
                      timezone={true}
                    />
                  ) : (
                    "N/A"
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Registration Closing Date</Table.Cell>
                <Table.Cell>
                  {values?.events_date == "undefined" ? (
                    " "
                  ) : values?.events_date ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.events_date}
                      readOnly={false}
                      id={values?._id}
                      variant="outlined"
                      name={"events_date"}
                      date={true}
                    />
                  ) : (
                    "N/A"
                  )}
                </Table.Cell>
              </Table.Row>

            </Table.Body>
          </Table>

        </Card>
      </GridItem>

      <GridItem xs={6} sm={6} md={4} lg={3} xl={3}>
        <Card>
          <CardBody>
            <div
              style={{ cursor: "pointer" }}
              onClick={handleUpload}
              className="image"
            >
              <img
                src={`${process.env.REACT_APP_BACKEND_API}/get-events-banner/eventID/${id}`}
                style={{ maxHeight: "auto", maxWidth: "100%" }}
                alt="Event Banner"
              />
            </div>
            <input
              onChange={handleFiles}
              type="file"
              accept="image/png, image/jpeg"
              id="file"
              style={{ display: "none" }}
            />
          </CardBody>
        </Card>
        <h4 style={{ textAlign: "center" }}>
          ( height: 217px, width: 290px )
        </h4>
      </GridItem>

    </GridContainer>
  );
};

export default Items;
