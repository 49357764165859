import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

// Custom Styles
import ViewINCInvoice from "components/Linguist/ManageInhouse/Invoices/ViewINCInvoice";

const ViewINCInvoicePage = () => {

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <ViewINCInvoice />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewINCInvoicePage;
