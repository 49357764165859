import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LinguistProfile from "components/PublicProfileLIN/ProtectedPublicProfile/PPL";

const ManagePublicProfile = () => {

  return (
    <MasterLayout>
      <Manager>

        <LinguistProfile />

      </Manager>
    </MasterLayout>
  );
};

export default ManagePublicProfile;
