import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomSelect from "components/Common/CustomSelect";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import {
  MOTHER_LANG,
  INTERPRETING_QUALIFICATION,
  TRANSLATION_QUALIFICATION,
  OTHER_LANG_TEST,
  LANG_COMB,
} from "data";
import { updateErStep, autoSaveER, changeErInput } from "redux/actions/erAction";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import CustomInput from "components/Common/CustomInput";
import CustomAutoSelect from "components/Common/CustomAutoSelect";

const FormFour = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.erReducer);

  const {
    mother_tongue,
    interpreting_qualification,
    translation_qualification,
    other_language_test,
    language_combinations,
    other_qualifications,
  }= data

  const isAllow= 
    !language_combinations.length ||
    !interpreting_qualification ||
    !translation_qualification ||
    !other_language_test ||
    !mother_tongue

  useEffect(() => {
    const temp= JSON.parse(localStorage.getItem('userER'))
    dispatch(autoSaveER({
      temp
    }))
  }, [dispatch])

  useEffect(() => {
    localStorage.setItem('userER', JSON.stringify(data))
  }, [data])

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateErStep(5));
  };

  const handleBack = () => {
    dispatch(updateErStep(3));
  };

  const handleChange = e => {
    const { name, value }= e.target
    dispatch(changeErInput({
        name,
        value
    }))
  }

  const handleAutoCompleteLC = (e, value) => {
    dispatch(changeErInput({
      name: 'language_combinations',
      value
    }))
  }

  const handleChangeMT = (e, value) => {
    dispatch(changeErInput({
      name: 'mother_tongue',
      value
    }))
  }

  const handleChangeIQ = (e, value) => {
    dispatch(changeErInput({
      name: 'interpreting_qualification',
      value
    }))
  }

  const handleChangeTQ = (e, value) => {
    dispatch(changeErInput({
      name: 'translation_qualification',
      value
    }))
  }

  const handleChangeOLT = (e, value) => {
    dispatch(changeErInput({
      name: 'other_language_test',
      value
    }))
  }

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomAutoSelect
          onChange= {handleChangeMT}
          required
          label="Mother tongue / Native language"
          data={MOTHER_LANG}
          name= 'mother_tongue'
          value= {mother_tongue}
          width="100%"
        />
       
        <CustomAutoComplete
          onChange= {handleAutoCompleteLC}
          data={LANG_COMB}
          label="Language combinations"
          name= 'language_combinations'
          value= {language_combinations}
          required
        />
        <CustomAutoSelect
          required
          onChange= {handleChangeIQ}
          label="Interpreting qualification (s)"
          data={INTERPRETING_QUALIFICATION}
          name= 'interpreting_qualification'
          value= {interpreting_qualification}
          width="100%"
        />
        {interpreting_qualification?.includes('Other') ? (
        <CustomInput
          label= 'Other details'
          width= '100%'
        />)
        :
        null}
        <CustomAutoSelect
          required
          onChange= {handleChangeTQ}
          label="Translation qualification (s)"
          data={TRANSLATION_QUALIFICATION}
          name= 'translation_qualification'
          value= {translation_qualification}
          width="100%"
        />
        {translation_qualification?.includes('Other') ? (
        <CustomInput
          label= 'Other details'
          width= '100%'
        />)
        :
        null}
        <CustomAutoSelect
          required
          onChange= {handleChangeOLT}
          label="Other language test, qualification and or CPD"
          data={OTHER_LANG_TEST}
          name= 'other_language_test'
          value= {other_language_test}
          width="100%"
        />
        {other_language_test?.includes('Other') ? (
        <CustomInput
          label= 'Other details'
          width= '100%'
        />)
        :
        null}
       
        <CustomBigInput
          onChange= {handleChange}
          width="100%"
          label="Other qualification/s and experience/s related "
          moreInfo="Please list any other professional qualification/s and experience/s related to content writing / editing / teaching / training / general practice, doctor / lawyer / business consultancy below"
          name= 'other_qualifications'
          value= {other_qualifications}
        />
        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton text="Next" disabled= {isAllow} onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default FormFour;
