// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import AccountClientLayout from "layout/AccountClientLayout";
import { useParams } from "react-router-dom";

const ProjectReportINCPage = () => {
  const classes = useDashboardStyles();
  const { id } = useParams();
  return (
    <Accountclient>
      <AccountClientLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Timesheet document{" "}
                  {history.location.pathname.includes(
                    "account-client/view-timesheet-document-by-linguist"
                  )
                    ? "uploaded by linguist"
                    : history.location.pathname.includes(
                        "account-client/view-timesheet-document-by-client"
                      )
                    ? "uploaded by client"
                    : null}
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon onClick={() => history.goBack()} />
                </IconButton>
              </CardHeader>
              <CardBody>
                {history.location.pathname.includes(
                  "account-client/view-timesheet-document-by-linguist"
                ) ? (
                  <GridContainer justify="center" className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Card>
                        <object
                          data={`${process.env.REACT_APP_BACKEND_API}/view-timesheet-document-uploaded-by-Lin/timesheetID/${id}`}
                          type="application/pdf"
                          width="100%"
                          height="1000px"
                        ></object>
                      </Card>
                    </GridItem>
                  </GridContainer>
                ) : history.location.pathname.includes(
                    "account-client/view-timesheet-document-by-client"
                  ) ? (
                  <GridContainer justify="center" className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Card>
                        <object
                          data={`${process.env.REACT_APP_BACKEND_API}/view-timesheet-document-uploaded-by-Client/timesheetID/${id}`}
                          type="application/pdf"
                          width="100%"
                          height="1000px"
                        ></object>
                      </Card>
                    </GridItem>
                  </GridContainer>
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default ProjectReportINCPage;
