import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components

import BISummary from "components/Auth/IBT/EMPBISummary";

const BISummaryPage = () => {
  return (

    <EmployeeLayout>
      <Employee>
        <BISummary />
      </Employee>
    </EmployeeLayout>

  );
};

export default BISummaryPage;
