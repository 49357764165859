import React from 'react'
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import ViewCAC from 'components/Admin/CrmPanel/CAC/ViewCAC'
import { useParams } from 'react-router-dom'
import MasterLayout from 'layout/MasterLayout'
import { useHistory } from 'react-router-dom';

import useDashboardStyles from "assets/jss/pages/dashboardStyle";


const ViewCACDetails = () => {
  const { id } = useParams()
  const classes = useDashboardStyles();
  const history = useHistory()


  return (

    <MasterLayout>
      <Manager>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Cash Account Client Profile</p>
                <i className="icon list arrow left"
                  onClick={() => {
                    history.goBack()
                  }}></i>

              </CardHeader>
              <CardBody>
                <ViewCAC id={id} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Manager>
    </MasterLayout>
  )
}

export default ViewCACDetails
