// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TimeSheetList from "components/Linguist/LinguistDashboard/AssignmentView/Interpreting/BulkInterpreting/Timesheet/TimeSheetList";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";

//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import LinguistLayout from "layout/LinguistLayout";

const TimeSheetPage = () => {
  const classes = useDashboardStyles();
  return (
    <Linguist>
      <LinguistLayout>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
            <Card>
              <CardHeader
                style={{ display: "flex", justifyContent: "space-between" }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Account Client Timesheet
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
              <CardBody>
                <TimeSheetList />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LinguistLayout>
    </Linguist>
  );
};

export default TimeSheetPage;
