import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import { COUNTRY_DATA, COUNTRY_DATA_SEM_UI, GENDER_DATA } from "data";
import moment from "moment"

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("SPPOtoken");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };


  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "mobile_no",
        value,
      })
    );
  };


  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("title", data.title || "-");
    fd.append("first_name", data.first_name || "-");
    fd.append("last_name", data.last_name || "-");
    fd.append("address_line1", data.address_line1 || "-");
    fd.append("address_line2", data.address_line2 || "-");
    fd.append("postal_code", data.postal_code || "-");
    fd.append("city", data.city || "-");
    fd.append("region", data.region || "-");
    fd.append("country", data.country || "-");
    fd.append("birthday", data.birthday || "-");
    fd.append("sex", data.sex || "-");
    fd.append("primary_email", data.primary_email || "-");
    fd.append("mobile_no", data.mobile_no || "-");
    fd.append("nationality", data.nationality || "-");
    
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/profile/update`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="title"
            onChange={handleChange}
            label="Title"
            edit={edit.value}
            value={data.title}
          />
          <CustomTextInput
            name="first_name"
            onChange={handleChange}
            label="First Name"
            value={data.first_name}
          />
          <CustomTextInput
            name="last_name"
            onChange={handleChange}
            label="Last Name"
            edit={edit.value}
            value={data.last_name}
          />
          <CustomTextInput
            name="address_line1"
            onChange={handleChange}
            label="House number / name, Street name"
            edit={edit.value}
            value={data.address_line1}
          />
          <CustomTextInput
            name="address_line2"
            onChange={handleChange}
            label="Address Line 2"
            edit={edit.value}
            value={data.address_line2}
          />
          <CustomTextInput
            name="city"
            onChange={handleChange}
            label="Town / City"
            edit={edit.value}
            value={data.city}
          />
          <CustomTextInput
            name="postal_code"
            onChange={handleChange}
            label="Post / Zip code"
            edit={edit.value}
            value={data.postal_code}
          />
          <CustomTextInput
            name="Region"
            onChange={handleChange}
            label="County / Region"
            edit={edit.value}
            value={data.region}
          />
          {/* <CustomTextInput name= 'Country' onChange= {handleChange} label= 'Country' edit= {edit.value} value= {data.country} /> */}
          {edit.value ? (
            <CustomTextInput
              name="Country"
              onChange={handleChange}
              name="Country"
              select={COUNTRY_DATA}
              svalue={data.country}
              label="Country"
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              name="Country"
              onChange={handleChange}
              label="Country"
              value={data.country}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              name="Nationality"
              onChange={handleChange}
              name="Nationality"
              select={COUNTRY_DATA}
              svalue={data.nationality}
              label="Current Nationality"
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              name="Nationality"
              onChange={handleChange}
              label="Current Nationality"
              value={data.nationality}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              onChange={handleDropDown}
              name="other_nationality"
              label="Other Nationalities"
              data={COUNTRY_DATA_SEM_UI}
              dropdown={true}
            />
          ) : (
            <CustomTextInput
              name="other_nationality"
              onChange={handleChange}
              label="Other Nationalities"
              value={data.other_nationality}
            />
          )}
          <CustomTextInput label="Date of birth" value={data.birthday} />
          <CustomTextInput
            name="primary_email"
            onChange={handleChange}
            label="Direct / Personal Email"
            edit={edit.value}
            value={data.primary_email}
          />

          {edit.value ? (
            <CustomTextInput
              name="sex"
              onChange={handleChange}
              label="Gender"
              radio={true}
              rvalue={data.sex}
              data={GENDER_DATA}
            />
          ) : (
            <CustomTextInput
              name="sex"
              onChange={handleChange}
              label="Gender"
              value={data.sex}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              name="mobile_no"
              onChange={handleMobile}
              label="Mobile Number"
              pvalue={data.mobile_no}
              mobile={true}
            />
          ) : (
            <CustomTextInput
              name="mobile_no"
              onChange={handleChange}
              label="Mobile Number"
              value={data.mobile_no}
            />
          )}

        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
