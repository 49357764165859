import BIPaymentPage from "pages/cash-client/BIPayment";
import BISummaryPage from "pages/cash-client/BISummary";
import BISummaryPageOT from "pages/cash-client/BIsummary_fororderTranslation"
import BIPaymentPageOT from "pages/cash-client/BIpayment_forordertranslation"
import BIPaymentStatus from "components/Auth/LinguistsCollectiveBooking/JAPaymentStatus";
const BI_CASHCLIENT = [
  {
    id: '25a',
    text: "Summary",
    path: "cash-client/book-interpreter/summary",
    component: BISummaryPage,
  },

  {
      id: '25b',
      text: 'Payment',
      path: 'cash-client/book-interpreter/payment',
      component: BIPaymentPage
  }
  ,  {
    id: '25a',
    text: "Summary",
    path: "cash-client/order-translation/summary",
    component: BISummaryPageOT,
  },

  {
      id: '25b',
      text: 'Payment',
      path: 'cash-client/order-translation/payment',
      component: BIPaymentPageOT
  },

  {
    id: "25c",
    path: "cash-client/order-translation/payment/status",
    component: BIPaymentStatus,
  },
];

export default BI_CASHCLIENT;
