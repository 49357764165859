import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useParams } from "react-router-dom";
import Action from "./Actions";
import SearchView from "../Search/SearchView";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import { useToasts } from "react-toast-notifications";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ text }) {
  const { id } = useParams();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("BACtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    billList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, billList, removed, reload } = values;

  useEffect(() => {
    LoadBillList();
  }, [reload]);

  const LoadBillList = async () => {
    try {
      const response = await Axios.get(
        `/api/accountclient/viewall-Monthly-Bill`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, billList: response?.data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/find/account-client/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setValues({...values,billList:response?.data})
      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct client id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, billList: [response?.data], reload: false });
        // billList=[response?.data]
        addToast("client found", { appearance: "success" });
      } else {
        addToast("client not found", { appearance: "error" });
      }
      if (values.billList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct client id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <div>
      <SearchView
        load={LoadBillList}
        handleSearch={handleSearch}
        setSearchText={setSearchText}
        SearchText={SearchText}
        setShow={setShow}
        show={show}
      />
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Bill number</TableCell>
                    <TableCell>Month and Year</TableCell>
                    <TableCell>Total number of invoices</TableCell>
                    <TableCell>Total bill in GBP</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {billList.length > 0 ? (
                  <TableBody>
                    {billList

                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list, i) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                          <TableCell> {list?.bill_no} </TableCell>
                          <TableCell> {list?.billing_month} </TableCell>
                          <TableCell> {list?.total_invoice_no} </TableCell>
                          <TableCell>
                            {" "}
                            {list?.currency?.substring(0, 3)}{" "}
                            {list?.total_bill_amount}{" "}
                          </TableCell>
                          <TableCell> {list?.status} </TableCell>
                          <TableCell>
                            {" "}
                            <Action
                              billID={list?._id}
                              clientID={list?.bacClientID}
                            />{" "}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={billList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
