import React from "react";
import { useSelector } from "react-redux";

import CustomButton from "components/Common/CustomButton";

import useFormStyles from "assets/jss/components/auth/formStyle";

import { Button, Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";


const PageOne = () => {
  const { data } = useSelector(state => state.quoterqstReducer)

  const {

  }= data

  const classes = useFormStyles();

  const handleNext = () => {
    console.log(data)
  };


  return (
    <div>
        <Card raised style= {{marginTop: '24px'}} >
          <CardBody>
              <h4 style={{textAlign: "center"}}>Quote Request </h4>
          </CardBody>
        </Card>
        <Card raised style= {{marginTop: '24px', padding: '30px'}} >
          <CardBody>
            <h4 style={{textAlign: "center"}}>Client Information </h4>
            <p>Name: Nasrin Kona</p>
            <p>House number / Street number: 244/1, Baliapukur, Rajshahi</p>
            <p>Address: Baliapukur, Ghoramara, Boalia, Rajshahi</p>
            <p>Town / City: Rajshahi</p>
            <p>Zip code / Postal code: 6100</p>
            <p>Country / Region: Bangladesh</p>
            <p>Country: Bangladesh</p>
            
          </CardBody>
        </Card>
        <Card raised style= {{marginTop: '24px', padding: '30px'}} >
          <CardBody>
            <h4 style={{textAlign: "center"}}>Quote Details </h4>
            <p>Booking reference number or purchase order number: </p>
            <p>Quote request for: </p>
            <p>House number / Street number: </p>
            <p>Address: </p>
            <p>Town / City: </p>
            <p>Zip code / Postal code: </p>
            <p>Country / Region: </p>
            <p>Country: </p>
            <p>Other / Remote or Telephonic Interpreting Details: </p>
            <p>Details of the request: </p>
            <p>Do you have a fixed budget for this assignment or project? :</p>
            
          </CardBody>
        </Card>
        
        <Card raised style= {{marginTop: '24px', padding: '30px'}} >
          <CardBody>
            <p>Source Language: </p>
            <p>Target Language: </p>
            <p>Linguists skill level: </p>
            <p>Preferred Linguists / Service Provider ID Number : </p>
            <p>Preferred Gender: </p>
            <p>Timezone: </p>
            <p>Do you need this quote by: </p>
            <p>How do you intend to pay for this service: </p>
            
          </CardBody>
        </Card>
        <Card raised style= {{marginTop: '24px'}} >
          <CardBody>
            View Document
          </CardBody>
        </Card>
        

        <div className={classes.buttonFlex}>
          {/* //<CustomButton text="View Linguists" onClick={handleNext} /> */}
          <Button style= {{marginTop: '2rem', marginRight: '15px'}} variant= 'contained' color= 'primary'>View Linguists</Button>
          <CustomButton text="Send Note" onClick={handleNext} />
          <CustomButton text="Cancel" onClick={handleNext} />
        </div>
      {/* </form> */}
    </div>
  );
};

export default PageOne;
