import React, { } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Table } from 'semantic-ui-react';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from 'moment'

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const Items = ({ values }) => {
  const classes = useStyles();

  return (

    <>

      <GridContainer justify="center" className={classes.container}>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Table celled>
            <Table.Body>
              <Table.Header>
                <Table.Row >
                  <Table.HeaderCell width='5'>Date and time of requesting quote (London Time)</Table.HeaderCell>
                  <Table.HeaderCell width='5'>
                    {moment(values.createdAt).tz("Europe/London").format('DD-MMM-YYYY, HH:mm')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table.Body>
          </Table>
        </GridItem>
      </GridContainer>

      <br />


      <GridContainer justify="center" className={classes.container}>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>

          <Table celled>

            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width='5'>Linguist Provided Quote</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {values.acceptedID.filter(dt => dt != "").map(st =>
                <Table.Row>
                  <Table.Cell>{st.id}</Table.Cell>

                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </GridItem>

      </GridContainer>
      <br />
      <GridContainer justify="center" className={classes.container}>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width='5'>Linguist Declined </Table.HeaderCell>
                <Table.HeaderCell width='5'>Reasons</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.HeaderCell>
                {values.declinedLinID.filter(dt => dt != "").map(st =>
                  <Table.Row>
                    <Table.Cell>{st.id}</Table.Cell>

                  </Table.Row>
                )}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {values.declines.filter(dt => dt != "").map(st =>
                  <Table.Row>
                    <Table.Cell>{st}</Table.Cell>

                  </Table.Row>
                )}
              </Table.HeaderCell>
            </Table.Body>
          </Table>
        </GridItem>

      </GridContainer>
      <br />
    </>
  )
}

export default Items
