import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Material Core Components\
import IconButton from "@material-ui/core/IconButton";

// Material Icons
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import IconImg from "components/Sidebar/IconImg";

// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";
//Icons
import helpIcon from "assets/icons/help.png";

const Chat = ({ layoutType }) => {
  const state = useSelector((state) => state);

  const classes = useNavbarStyles();

  const history = useHistory();

  const handleClick = (event) => {
    switch (layoutType) {
      case "ACCOUNT_CLIENT":
        history.push("/account-client/help");
        break;

      case "CASH_CLIENT":
        history.push("/cash-client/help");
        break;

      case "EMPLOYEE_CLIENT":
        history.push("/employee/help");
        break;

      case "LINGUIST":
        history.push("/linguist/help");
        break;

      case "SPPO":
        history.push("/sppo/help");
        break;

      default:
        history.push("/admin/help");

        break;
    }
  };

  // TODO set logic based on logged in user

  return (
    <Fragment>
      <IconButton aria-label="Chat" color="primary" onClick={handleClick}>
        <div className={classes.imgDiv}>
          <IconImg className={classes.icons} icon={helpIcon} />
        </div>
      </IconButton>
      {/* <IconButton aria-label="Chat" color="primary" onClick={handleClick}>
        <QuestionAnswerIcon className={classes.icons} />
      </IconButton> */}
    </Fragment>
  );
};

export default Chat;
