import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import { Button, Header } from "semantic-ui-react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import Axios from "axios";
// Others
import { CURRENT_COUNTRY, EMAIL_REGEX } from "data";
import Cookies from "js-cookie";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import { changeSPPORInput, updateSPPORStep } from "redux/actions/spporAction";
import CustomAutoSelect from "components/Common/CustomAutoSelect";

const PageTwo = ({ setCompleted }) => {
  const { data } = useSelector((state) => state.spporReducer);
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const [details, setDetails] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const token = Cookies.get("SPPOtoken");

  const {
    primary_email,
    address_line1,
    address_line2,
    city,
    postal_code,
    region,
    country,
    nationality,
    photo,
    document,
  } = data;

  const getLinguistDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDetails(response?.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  const handleNext = () => {
    dispatch(updateSPPORStep(3));
    setCompleted(true);
  };

  const handleBack = () => {
    dispatch(updateSPPORStep(1));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeSPPORInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handlePassportClick = (e) => {
    e.preventDefault();
    fileInput2.current.click();
  };

  const handlePassport = (e) => {
    dispatch(
      changeSPPORInput({
        name: "document",
        value: e.target.files[0],
      })
    );
  };

  const handlePhoto = (e) => {
    dispatch(
      changeSPPORInput({
        name: "photo",
        value: e.target.files[0],
      })
    );
  };

  const handleChangeCO = (e, value) => {
    dispatch(
      changeSPPORInput({
        name: "country",
        value,
      })
    );
  };

  const handleChangeNA = (e, value) => {
    dispatch(
      changeSPPORInput({
        name: "nationality",
        value,
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeSPPORInput({
        name,
        value: value,
      })
    );
  };

  const isAllow =
    !primary_email ||
    !address_line1 ||
    !city ||
    !postal_code ||
    !country ||
    !document ||
    notValid;

  return (
    <div>
      <h3 style={{ textAlign: "center", paddingTop: "5px" }}>
        Company Director or Legal Representative's Details
      </h3>
      <form className={classes.form} noValidate autoComplete="off">
        <input
          accept="image/jpeg, image/png"
          ref={fileInput}
          onChange={(e) => handlePhoto(e)}
          type="file"
          style={{ display: "none" }}
          name="photo"
          required
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
            Upload Profile Photo (max 1mb)
          </Header>
          <div>
            <Button onClick={handleClick}>Upload</Button>
          </div>
          {photo && (
            <Header style={{ margin: "0", textAlign: "center" }} as="h5">
              <Header style={{ margin: "0" }} as="h1">
                <InsertPhotoIcon fontSize="large" />
              </Header>
              {photo.name}
              <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                photo.size / 1024
              ).toFixed(2)}kb`}</Header>
              <Header
                style={{ margin: "0" }}
                as="h5"
              >{`Last Modified: ${photo?.lastModifiedDate}`}</Header>
            </Header>
          )}
        </div>
        <CustomInputSingle
          width="100%"
          name="first_name"
          value={
            details.title + " " + details.first_name + " " + details.last_name
          }
          disabled
          placeholder="Your First Names"
        />
        <CustomInputSingle
          onChange={handleEmailChange}
          label="Direct / Personal Email"
          width="100%"
          name="primary_email"
          value={primary_email}
          required
          placeholder="e.g. your.name@yourcompany.com"
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}

        <CustomBigInputSingle
          onChange={handleChange}
          label="House Number / Name, Street Name"
          moreInfo="Current residence"
          width="100%"
          name="address_line1"
          value={address_line1}
          required
          placeholder="e.g. Flat/Building Name, 101 Example Street"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Address Line 2"
          width="100%"
          name="address_line2"
          value={address_line2}
          placeholder="Additional Address Information"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Town / City"
          width="100%"
          name="city"
          value={city}
          required
          placeholder="Your Village, Town or City Name"
        />

        <CustomInputSingle
          onChange={handleChange}
          label="County / Region"
          width="100%"
          name="region"
          value={region}
          placeholder="Your County, Division or State Name"
        />

        <CustomInputSingle
          onChange={handleChange}
          label="Post / Zip Code"
          width="100%"
          name="postal_code"
          value={postal_code}
          required
          placeholder="Your Post Code, Zip Code or Post Office Name"
        />
        <CustomAutoSelect
          onChange={handleChangeCO}
          label="Country"
          moreInfo="Current country of residence"
          width="100%"
          name="country"
          value={country}
          data={CURRENT_COUNTRY}
          required
        />
        <CustomAutoSelect
          onChange={handleChangeNA}
          label="Current Nationality"
          width="100%"
          name="nationality"
          value={nationality}
          data={CURRENT_COUNTRY}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "24px",
          }}
        >
          <input
            name="document"
            ref={fileInput2}
            onChange={handlePassport}
            type="file"
            style={{ display: "none" }}
            accept="image/jpeg, image/png"
            required
          />
          <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
            Upload personal identification (passport/DL/national ID - max 5mb)*
          </Header>
          <div>
            <Button onClick={handlePassportClick}>Upload</Button>
          </div>
          {document && (
            <Header style={{ margin: "0", textAlign: "center" }} as="h5">
              <Header style={{ margin: "0" }} as="h1">
                <InsertPhotoIcon fontSize="large" />
              </Header>
              {document.name}
              <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                document.size / 1024
              ).toFixed(2)}kb`}</Header>
              <Header
                style={{ margin: "0" }}
                as="h5"
              >{`Last Modified: ${document?.lastModifiedDate}`}</Header>
            </Header>
          )}
        </div>

        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton disabled={isAllow} text="Next" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default PageTwo;
