import Employee from "components/RouteProtection/Employee/Employee";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetch from 'isomorphic-fetch';


// Custom Components
import GridItem from "components/Grid/GridItem";
import EmployeeLayout from "layout/EmployeeLayout";
import CardHeader from "components/Card/CardHeader";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";

const ViewContactRequest = () => {

  const classes = useDashboardStyles();
  const { id } = useParams();

  const [values, setValues] = useState({
    // title: "mst",
    // FirstName: "kona", 
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_API}/manager-admin/view-inhouse-client/${id}`)
      .then(res => res.json())
      .then((result) => {
        setValues(result)
      })
  })

  return (
    <EmployeeLayout>
      <Employee>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>Inhouse Client - {values.id}</p>

          </CardHeader>
        </GridItem>

        <div raised style={{ marginLeft: '15%', marginRight: '15%' }}>

          <Card raised style={{ marginTop: '24px' }} >
            <CardBody>
              <h4 style={{ textAlign: "center" }}>Inhouse Client Details</h4>
            </CardBody>
          </Card>
          <Card raised style={{ marginTop: '24px', padding: '30px' }} >
            <CardBody>
              {/* <h4 style={{textAlign: "center"}}>Detail Information </h4> */}
              <p>Title: {values.title} </p>
              <p>First name: {values.FirstName} </p>
              <p>Last name: {values.LastName} </p>
              <p>Email: {values.email}  </p>

            </CardBody>
          </Card>

          <br /><br /><br />
        </div>
      </Employee>
    </EmployeeLayout>
  );
};

export default ViewContactRequest;
