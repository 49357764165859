import LinguistProfileView from "pages/admin/HRPanel/ManagePublicProfile";
import LinguistProfileViewEMP from "pages/employee/ManagePublicProfile";
import LINProtectedPPL from "pages/ProtectedPublicProfile/Linguist";
import CACProtectedPPL from "pages/ProtectedPublicProfile/CAC";
import BACProtectedPPL from "pages/ProtectedPublicProfile/BAC";
import EMPProtectedPPL from "pages/ProtectedPublicProfile/EMP";
import ADMProtectedPPL from "pages/ProtectedPublicProfile/Admin";
import PPL from "components/PublicProfileLIN/PPL/PPL";
import LINPROPPL from "components/PublicProfileLIN/PPLOnlyNumberID/PPL";
import PPS from "components/Sppo/PublicProfileSPPO/PPS/PPS";

const PROFILE_VIEW = [
  {
    id: "700dck",
    path: "admin/view-public-profile/:id", //preview public profile from admin dashboard
    component: LinguistProfileView,
  },
  {
    id: "700dcek",
    path: "employee/view-public-profile/:id", //preview public profile from admin dashboard
    component: LinguistProfileViewEMP,
  },

  // Linguist Public Profile Route
  {
    id: "100dck",
    path: "linguist/public/profile/:source/:target/:id", // Public Profile Linguist
    component: PPL,
  },
  {
    id: "100dck",
    path: "LINPRO/:id", // Public Profile Linguist
    component: LINPROPPL,
  },
  // SPPO Public Profile Route
  {
    id: "100dck",
    path: "partner/:id", // Public Profile sppo
    component: PPS,
  },

  // Linguist Protected Public Profile Route for all client, linguist and admin
  {
    id: "100dck",
    path: "linguist/protected/public/profile/:source/:target/:id", // Public Profile Linguist
    component: LINProtectedPPL,
  },

  {
    id: "100dck",
    path: "cash-client/protected/public/profile/:source/:target/:id", // Public Profile Linguist
    component: CACProtectedPPL,
  },

  {
    id: "100dck",
    path: "account-client/protected/public/profile/:source/:target/:id", // Public Profile Linguist
    component: BACProtectedPPL,
  },

  {
    id: "100dck",
    path: "employee/protected/public/profile/:source/:target/:id", // Public Profile Linguist
    component: EMPProtectedPPL,
  },

  {
    id: "100dck",
    path: "admin/protected/public/profile/:source/:target/:id", // Public Profile Linguist
    component: ADMProtectedPPL,
  },
];

export default PROFILE_VIEW;
