import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ResetPassword from "./ResetPassword";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const AdminResetPassword = () => {
  const classes = useDashboardStyles();

  return (
    <GridContainer
      style={{
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <GridItem xs={12} sm={6} md={6} lg={4}>
        <Card>
          <CardHeader color="primary">
            <p className={classes.cardTitleWhite}>Change Password</p>
          </CardHeader>
          <CardBody
            style={{
              textAlign: "center",
            }}
          >
            <ResetPassword />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AdminResetPassword;
