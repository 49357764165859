import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import { BASIC_QS, SIMPLE_QS, CURRENT_COUNTRY } from "data";
import { useDispatch, useSelector } from "react-redux";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import swal from "sweetalert";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";


const InsuranceDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("LINtoken");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updateProfileReducer);

  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };


  const handleAutoComplete = (e, value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "eu_all_other_countries",
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("work_in_the_uk", data.work_in_the_uk || "-");
    fd.append("ni_number", data.ni_number || "-");
    fd.append("eu_all_other_countries", data.eu_all_other_countries || "-");
    fd.append("utr_number", data.utr_number || "-");
    fd.append("vat_number", data.vat_number || "-");
    fd.append("liability_insurance", data.liability_insurance || "-");
    fd.append(
      "liability_insurance_expiry_date",
      data.liability_insurance_expiry_date
    );

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/profile/update`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      }).then(function () {
        window.location = "/linguist/profile/update";
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          {edit.value ? (
            <CustomTextInput
              onChange={handleChange}
              name="work_in_the_uk"
              select={BASIC_QS}
              label="Right to work in UK ?"
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              label="Right to work in UK ? "
              value={data.work_in_the_uk}
            />
          )}

          {edit.value ? (
            <CustomAutoComplete
              edit={edit.value}
              onChange={handleAutoComplete}
              width="100%"
              label="Please list below EU and all other countries that you have right to work"
              name="eu_all_other_countries"
              data={CURRENT_COUNTRY}
            />
          ) : (
            <CustomTextInput
              label="Please list below EU and all other countries that you have right to work"
              value={data?.eu_all_other_countries == "undefined" ? "" : data?.eu_all_other_countries}
            />
          )}

          <CustomTextInput
            name="ni_number"
            onChange={handleChange}
            label="National Insurance (NI) Number, Social Security Number"
            edit={edit.value}
            value={data.ni_number}
          />
          <CustomTextInput
            name="utr_number"
            onChange={handleChange}
            label="PTR / UTR number"
            edit={edit.value}
            value={data.utr_number}
          />
          <CustomTextInput
            name="vat_number"
            onChange={handleChange}
            label="VAT number"
            edit={edit.value}
            value={data.vat_number}
          />
          {edit.value ? (
            <CustomTextInput
              name="liability_insurance"
              onChange={handleChange}
              label="Do you have professional indemnity insurance and public liability insurance?"
              radio={true}
              rvalue={data.liability_insurance}
              data={SIMPLE_QS}
            />
          ) : (
            <CustomTextInput
              name="liability_insurance"
              onChange={handleChange}
              label="Do you have professional indemnity insurance and public liability insurance?"
              value={data.liability_insurance}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              name="liability_insurance_expiry_date"
              onChange={handleChange}
              label="Professional indemnity insurance and public liability insurance expiry date"
              dlabel="Expiry date"
              dvalue={data.liability_insurance_expiry_date}
              date={true}
            />
          ) :
            (
              <CustomTextInput
                name="liability_insurance_expiry_date"
                label="Professional indemnity insurance and public liability insurance expiry date"
                value={moment(data.liability_insurance_expiry_date).format(
                  "DD/MMM/YYYY"
                )}
              />
            )
          }

          <a href={`/linguist/update-linguist-documents`}>
            Upload Documents
          </a>

        </div>



        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>



      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default InsuranceDetails;
