import { useState } from "react";
import { listUnregisteredLinguistSearch } from "../../../../../action/manager";

import GridItem from "components/Grid/GridItem";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Action from "./Action";
import { Link } from "react-router-dom";
const Search = () => {
  const classes = useEmployeeStyles();
  const [values, setValues] = useState({
    search: undefined,
    results: [],
    searched: false,
    message: "",
    error: false,
    success: false,
    loading: false,
  });

  const { search, results, searched, message, error, success, loading } =
    values;

  const searchSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true });

    listUnregisteredLinguistSearch({ search }).then((data) => {
      if (data) {
        setValues({
          ...values,
          results: data,
          searched: true,
          message: `${data.length} linguist found`,
        });
        // setValues({ ...values, loading: false });
      }
      // else{
      //   setValues({ ...values, results: data, loading: false, searched: false, message: `${data?.length} linguist found` });
      // }
    });
  };

  const handleID = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleName = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleSourceLanguage = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleTargetLanguage = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleLevel = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handleCity = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  const handlePostcode = (e) => {
    setValues({
      ...values,
      search: e.target.value,
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };
  console.log(search);
  const handleCountry = (e) => {
    setValues({
      ...values,
      search: e.target.value.trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
      error: false,
      success: false,
      searched: false,
      results: [],
    });
  };

  const showSuccess = () => (
    <div
      className="alert alert-success"
      style={{ display: success ? "" : "none" }}
    >
      Loaded
    </div>
  );

  const showLoading = () => (
    <div
      className="alert alert-info"
      style={{ display: loading ? "" : "none" }}
    >
      Loading...
    </div>
  );

  const searchedLinguists = (results = []) => {
    return (
      <div className="jumbotron bg-white">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {message && <b> {message} </b>}
          <br />
        </GridItem>

        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Linguist ID</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Source / Native language</TableCell>
                  <TableCell>Target language</TableCell>
                  <TableCell>Town / City</TableCell>
                  <TableCell>Post / Zip Code</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((list, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell key={list._id} align={list.align}>
                        <Link
                          to={`/admin/view-unregistered-linguist-client/${list._id}`}
                        >
                          {list.id}
                        </Link>
                      </TableCell>

                      <TableCell>{list.first_name}</TableCell>
                      <TableCell>{list.last_name}</TableCell>
                      <TableCell>{list.primary_email}</TableCell>
                      <TableCell>{list.mobile_no}</TableCell>
                      <TableCell>{list.phone_no}</TableCell>
                      <TableCell>{list.source_language}</TableCell>
                      <TableCell>{list.target_language}</TableCell>
                      <TableCell>{list.city}</TableCell>
                      <TableCell>{list.postal_code}</TableCell>
                      <TableCell>{list.country}</TableCell>
                      <TableCell>
                        {" "}
                        <Action
                          id={list?._id}
                          ID={list?.id}
                          name={list?.first_name + " - " + list?.last_name}
                          initial_reg={list?.initial_reg}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  };

  const searchForm = () => (
    <form onSubmit={searchSubmit}>
      <div className="row">
        <CustomInputSingle
          onChange={handleCountry}
          type="search"
          required
          label="Enter Linguist ( ID or First Name or Last Name or Email or Postal Code or City or Country or Source Language or Target Language ) "
          placeholder="Search using ( ID or First Name Last Name or Email or Postal Code or City or Country or Source Language or Target Language )"
        />

        <div className="col-md-4">
          <div className={classes.buttonFlex} style={{ marginRight: "45%" }}>
            <CustomButton text="Search" type="submit" />
          </div>
        </div>
      </div>
    </form>
  );

  return (
    <div className="container-fluid">
      <div className="pt-3 pb-5">{searchForm()}</div>
      <br />
      <br />
      <GridItem xs={12} sm={12} md={12} lg={12}>
        {/* <b style={{color: "green"}}>* Verified Linguist,</b> <b style={{color: "blue"}}>Unverified Linguist,</b> <b style={{color: "red"}}> Suspend Linguist</b> */}
        {/* <br/><br/> */}
        {showSuccess()}
        {showLoading()}
      </GridItem>
      {searched && (
        <div style={{ marginTop: "40px", marginBottom: "100px" }}>
          {searchedLinguists(results)}
        </div>
      )}
    </div>
  );
};

export default Search;
