import swal from "sweetalert";
import Cookies from "js-cookie";

import {
  UPDATE_ER_STEP,
  CHANGE_ER_INPUT,
  ER_SUBMIT,
  SET_LOADING,
  ER_LOGIN,
  AUTO_SAVE_ER,
} from "./actionTypes";

import defaultAxios from "utils/axios";
import history from "utils/history";
import Axios from "axios";

export const updateErStep = (count) => ({
  type: UPDATE_ER_STEP,
  payload: count,
});

export const changeErInput = (data) => ({
  type: CHANGE_ER_INPUT,
  payload: data,
});

export const erSubmit = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    // Call API and store data
    await defaultAxios.post(`${process.env.REACT_APP_BACKEND_API}/employee/signin`, data);

    history.push("/employee/dashboard");

    // return action type
    dispatch({
      type: ER_SUBMIT,
    });
  } catch (error) {
    // else throw error message if any error

    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};
export const erLogin = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    // Call API and store data
    const response = await Axios.post(
      `${process.env.REACT_APP_BACKEND_API}/employee/signin`,
      data
    );

    Cookies.set("token", response?.data.token);

    history.push("https://linguistscollective.com/employee-dashboard");
    console.log(response?.data);
    // return action type
    dispatch({
      type: ER_LOGIN,
      payload: response?.data,
    });
  } catch (error) {
    // else throw error message if any error

    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const autoSaveER = (data) => ({
  type: AUTO_SAVE_ER,
  payload: data,
});
