import React from "react";

// Custom Component
import MasterLayout from "layout/MasterLayout";

const ClientLayout = ({ children }) => {
  return <MasterLayout layoutType="CASH_CLIENT">{children}</MasterLayout>;
};

export default ClientLayout;
