import React from "react";
import { Table } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import moment from "moment";
import EditableText from "./EditableText";
const Items = ({ values, setReload, type }) => {
  const readOnly = moment(values?.deadline) < moment();

  return (
    <GridContainer alignItems="center">
      <p>
        {values?.bank_pay
          ? "Paid by bank transfer"
          : values?.pay_later
          ? "Pay Later / UK Legal Aid Job"
          : values?.charge_id
          ? "Paid by stripe"
          : null}
      </p>
      <Table color={"pink"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Translation Details</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Assignment Reference ID</Table.Cell>
            <Table.Cell>
              {values?.id == "undefined"
                ? "N/A"
                : values?.id
                ? values?.id
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Order reference number or purchase order number
            </Table.Cell>
            <Table.Cell>
              {values?.purchase_order_number == "undefined" ? (
                " "
              ) : values?.purchase_order_number ? (
                <EditableText
                  setReload={setReload}
                  text={values?.purchase_order_number}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"purchase_order_number"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Document type</Table.Cell>
            <Table.Cell>
              {values?.document_type == "undefined" ? (
                " "
              ) : values?.document_type ? (
                <EditableText
                  setReload={setReload}
                  text={values?.document_type}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"document_type"}
                  type={type}
                  docType={true}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Other details</Table.Cell>
            <Table.Cell>
              {values?.other_details == "undefined" ? (
                " "
              ) : values?.other_details ? (
                <EditableText
                  setReload={setReload}
                  text={values?.other_details}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"other_details"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Source Language</Table.Cell>
            <Table.Cell>
              {values?.source_language ? (
                <EditableText
                  setReload={setReload}
                  text={values?.source_language}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"source_language"}
                  type={type}
                  lang={true}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Source language expertise level</Table.Cell>
            <Table.Cell>
              {values?.source_expertise_level_required ? (
                <EditableText
                  setReload={setReload}
                  text={values?.source_expertise_level_required}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"source_expertise_level_required"}
                  exp={true}
                  type={type}
                />
              ) : values?.source_language_skill_level ? (
                <EditableText
                  setReload={setReload}
                  text={values?.source_language_skill_level}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"source_language_skill_level"}
                  exp={true}
                  type={type}
                />
              ) : (
                <EditableText
                  setReload={setReload}
                  text={values?.source_linguist_skill_level}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"source_linguist_skill_level"}
                  exp={true}
                  type={type}
                />
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target Language</Table.Cell>
            <Table.Cell>
              {values?.target_language ? (
                <EditableText
                  setReload={setReload}
                  text={values?.target_language}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"target_language"}
                  type={type}
                  lang={true}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell>
              {values?.target_expertise_level_required ? (
                <EditableText
                  setReload={setReload}
                  text={values?.target_expertise_level_required}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"target_expertise_level_required"}
                  exp={true}
                  type={type}
                />
              ) : values?.target_language_skill_level ? (
                <EditableText
                  setReload={setReload}
                  text={values?.target_language_skill_level}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"target_language_skill_level"}
                  exp={true}
                  type={type}
                />
              ) : (
                <EditableText
                  setReload={setReload}
                  text={values?.target_linguist_skill_level}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"target_linguist_skill_level"}
                  exp={true}
                  type={type}
                />
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Require visual formating</Table.Cell>
            <Table.Cell>
              {values?.require_visual_formating == "undefined" ? (
                " "
              ) : values?.require_visual_formating ? (
                <EditableText
                  setReload={setReload}
                  text={values?.require_visual_formating}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"require_visual_formating"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Translation certification requirement</Table.Cell>
            <Table.Cell>
              {values?.certified_translation == "undefined" ? (
                " "
              ) : values?.certified_translation ? (
                <EditableText
                  setReload={setReload}
                  text={values?.certified_translation}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"certified_translation"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          {/* <Table.Row >
                        <Table.Cell>Certificate</Table.Cell>
                        <Table.Cell>
                            {values?.certificate == "undefined" ? " " : values?.certificate}
                        </Table.Cell>
                    </Table.Row> */}

          <Table.Row>
            <Table.Cell>Additional quality check</Table.Cell>
            <Table.Cell>
              {values?.additional_quality_check == "undefined" ? (
                " "
              ) : values?.additional_quality_check ? (
                <EditableText
                  setReload={setReload}
                  text={values?.additional_quality_check}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"additional_quality_check"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Word count</Table.Cell>
            <Table.Cell>
              {values?.wordCount ? (
                <EditableText
                  setReload={setReload}
                  text={values?.wordCount}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"wordCount"}
                  type={type}
                />
              ) : values?.count ? (
                <EditableText
                  setReload={setReload}
                  text={values?.count}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"count"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Preferred Linguist / Provider ID Number</Table.Cell>
            <Table.Cell>
              {values?.provider_id_number == "undefined" ? (
                " "
              ) : values?.provider_id_number ? (
                <EditableText
                  setReload={setReload}
                  text={values?.provider_id_number}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"provider_id_number"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Additional note</Table.Cell>
            <Table.Cell>
              {values?.additional_note == "undefined" ? (
                " "
              ) : values?.additional_note ? (
                <EditableText
                  setReload={setReload}
                  text={values?.additional_note}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"additional_note"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Security clearence</Table.Cell>
            <Table.Cell>
              {values?.security_clearance == "undefined" ? (
                " "
              ) : values?.security_clearance ? (
                <EditableText
                  setReload={setReload}
                  text={values?.security_clearance}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"security_clearance"}
                  security={true}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Timezone</Table.Cell>
            <Table.Cell>{values?.timezone}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Translation needed by</Table.Cell>
            <Table.Cell>
              <EditableText
                setReload={setReload}
                text={moment(values?.deadline).format("DD-MMM-YYYY, HH:mm")}
                readOnly={readOnly ? true : false}
                id={values?._id}
                variant="outlined"
                name={"deadline"}
                datetime={true}
                type={type}
              />{" "}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Estimated value / estimate paid</Table.Cell>
            <Table.Cell>
              {`${
                values?.estimate_paid_amount
                  ? values?.estimate_paid_amount
                  : values?.estimate
              } ${
                values?.currency_new
                  ? values?.currency_new?.substring(0, 3)
                  : values?.currency?.substring(0, 3)
              }`}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Date and Time of Placing Order (London Time)
            </Table.Cell>
            <Table.Cell>
              {moment(values.createdAt)
                .tz("Europe/London")
                .format("DD-MMM-YYYY, HH:mm")}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </GridContainer>
  );
};

export default Items;
