import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Options from 'components/Admin/Dashboard/JobLists/Options/BookingOptions'



const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader color="primary">
            <p className={classes.cardTitleWhite}>Interpreting Assignments</p>

          </CardHeader>
        </Card>
        <Card>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Options />
          </CardHeader>
        </Card>

      </GridItem>
    </GridContainer>
  );
};

export default UnconfirmedAssignmentsPage;
