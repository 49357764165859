import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import { useParams } from "react-router";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useSelector } from "react-redux";
import ProvidedQuotations from "./ProvidedQuotations";
import UnconfirmQuotations from "components/CashClient/Assignments/UnconfirmQuotations/UnconfirmQuotations";
import DirectQuoteByLC from "components/CashClient/Assignments/UnconfirmQuotations/DirectQuoteByLC";

const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  const { providedQuotes } = useSelector(
    (state) => state.unconfirmQuotesReducer
  );
  const { data, type } = useParams();
  return (
    <Cashclient>
      <CashClientLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{ display: "flex", justifyContent: "space-between" }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Unconfirmed Quotations{" "}
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardBody>
                <UnconfirmQuotations
                  quotationData={type === "quotation" && data}
                />
                {providedQuotes && <ProvidedQuotations id={providedQuotes} />}
              </CardBody>

              <CardBody>
                <DirectQuoteByLC
                  quotationData={type === "quotation" && data}
                />
              </CardBody>

            </Card>
          </GridItem>
        </GridContainer>
      </CashClientLayout>
    </Cashclient>
  );
};

export default UnconfirmedAssignmentsPage;
