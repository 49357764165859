import React, { useState, useEffect } from "react";
import { Header } from "semantic-ui-react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Options from "components/Linguist/Jobs/Options";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import TranslationJobsList from "components/Linguist/TranslationJobsLists";

import Cookies from "js-cookie";
import Axios from "axios";

const TranslationJobs = () => {
  const classes = useDashboardStyles();
  const [cacAndBacAccess, setcacAndBacAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("LINtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setcacAndBacAccess(response?.data.cacAndBacAccess);
  };
  useEffect(() => {
    getLinguistDetails();
  });

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          {cacAndBacAccess ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className={classes.cardTitleWhite}>Translation Offers</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Options currentPage="translation" />
                  </div>
                </CardHeader>

                <CardBody>
                  {/* <ClientOptions /> */}
                  <TranslationJobsList />
                </CardBody>
              </Card>
            </GridItem>
          ) : cacAndBacAccess === false ? (
            <Header as="h3">
              Access Denied! <br /> Complete Full Registration or Contact HR /
              Admin Team.
            </Header>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default TranslationJobs;
