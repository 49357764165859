// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import LandingPageLayout from "layout/LandingPageLayout";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from "react-router-dom";

const ProjectReportINCPage = () => {
  const classes = useDashboardStyles();
  const { id } = useParams();
  return (
    <LandingPageLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              color="primary"
            >
              <p className={classes.cardTitleWhite}>
                {history.location.pathname.includes(
                  "/view-timesheet-document-bylinguist"
                )
                  ? "Uploaded timesheet document by linguist"
                  : history.location.pathname.includes(
                    "/view-project-report-document-bylinguist"
                  )
                    ? "Uploaded project report document by linguist"
                    : null}
              </p>
              <IconButton color="inherit">
                <ArrowBackIcon onClick={() => history.goBack()} />
              </IconButton>
            </CardHeader>
            <CardBody>
              {history.location.pathname.includes(
                "/view-timesheet-document-bylinguist"
              ) ? (
                <GridContainer justify="center" className={classes.container}>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <object
                        data={`${process.env.REACT_APP_BACKEND_API}/view-timesheet-document-uploaded-by-Lin/timesheetID/${id}`}
                        type="application/pdf"
                        width="100%"
                        height="1000px"
                      ></object>
                    </Card>
                  </GridItem>
                </GridContainer>
              ) : history.location.pathname.includes(
                "/view-project-report-document-bylinguist"
              ) ? (
                <GridContainer justify="center" className={classes.container}>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <object
                        data={`${process.env.REACT_APP_BACKEND_API}/view-project-report-document-uploaded-by-Lin/reportID/${id}`}
                        type="application/pdf"
                        width="100%"
                        height="1000px"
                      ></object>
                    </Card>
                  </GridItem>
                </GridContainer>
              ) : null}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default ProjectReportINCPage;
