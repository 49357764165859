import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import {
  LANGUAGE_DATA,
  QUOTE_REQUEST_FOR_SEM_UI,
  TRANSLATION_QUALIFICATION,
  INTERPRETING_QUALIFICATION,
  OTHER_LANG_TEST,
  PROFESSIONAL_MEMBERSHIP_SEM_UI,
  MOTHER_LANG,
} from "data";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import Axios from "utils/axios";
import Cookies from "js-cookie";

const ProfessionalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updateProfileReducer);

  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("mother_tongue", data.mother_tongue || "");
    fd.append("language_combinations", data.language_combinations || "");
    fd.append(
      "interpreting_qualificaton",
      data.interpreting_qualificaton || ""
    );
    fd.append(
      "translation_qualification",
      data.translation_qualification || ""
    );
    fd.append("other_language_cpd", data.other_language_cpd || "");

    try {
      const response = await Axios.put(
        `/api/manager-admin/update-employee/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          {edit.value ? (
            <CustomTextInput
              name="mother_tongue"
              onChange={handleChange}
              label="Mother Tongue / Source / Native Language"
              select={MOTHER_LANG}
              svalue={data.mother_tongue}
            />
          ) : (
            <CustomTextInput
              name="mother_tongue"
              label="Mother Tongue / Source / Native Language"
              value={data.mother_tongue}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              onChange={handleDropDown}
              name="language_combinations"
              label="List all other languages combinations (max 5)"
              data={LANGUAGE_DATA}
              dropdown={true}
            />
          ) : (
            <CustomTextInput
              label="List all other languages combinations (max 5)"
              value={data.language_combinations}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              name="interpreting_qualificaton"
              onChange={handleChange}
              svalue={data.interpreting_qualificaton}
              label="Interpreting Qualification (s)"
              select={INTERPRETING_QUALIFICATION}
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              label="Interpreting Qualification (s)"
              value={data.interpreting_qualificaton}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              name="translation_qualification"
              onChange={handleChange}
              svalue={data.translation_qualification}
              label="Translation Qualification (s)"
              select={TRANSLATION_QUALIFICATION}
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              label="Translation Qualification (s)"
              value={data.translation_qualification}
            />
          )}

          {edit.value ? (
            <CustomTextInput
              name="other_language_cpd"
              onChange={handleChange}
              label="Other language test, qualification and or CPD (s)"
              select={OTHER_LANG_TEST}
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              label="Other language test, qualification and or CPD (s)"
              value={data.other_language_cpd}
            />
          )}
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default ProfessionalDetails;
