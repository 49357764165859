import React, { useState, useEffect } from "react";
import { Header } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { useMediaQuery } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import CommonCard from "components/Common/CommonCard";


function CoMemberShip() {
  const [values, setValues] = useState({ reload: false });
  const { id } = useParams();

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/public-cooperate-membership-list/${id}`
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <Header color="purple" as="h2">
          Corporate Membership
        </Header>
      </div>
      <br />

      {values.length > 0 ? (
        <GridContainer>
          {values?.map((list) => (
            <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
              <div style={{ margin: "5px" }}>
                <CommonCard
                  style={{ textAlign: "center" }}
                  title={list.association_name}
                >
                  <CardHeader color="primary">
                    <p style={{ textAlign: "center" }}>
                      My Membership / Reg. No. - {list.membership_no}{" "}
                    </p>
                    <p style={{ textAlign: "center" }}>
                      Registered until / Expiry: - {list.expiry_date}{" "}
                    </p>
                  </CardHeader>
                  <a href={
                    list?.website.includes('http')
                      ? list.website
                      : list?.website.includes('www')
                        ? 'http://' + list.website
                        : 'http://www.' + list?.website
                  } target="_blank">
                    <img
                      style={{
                        width: "100%",
                        padding: "10%",
                        objectFit: "fill",
                      }}
                      src={`${process.env.REACT_APP_BACKEND_API}/sppo/cooperate-membership-logo/membershipListID/${list?._id}`}
                      alt=""
                    />
                  </a>
                </CommonCard>
              </div>
            </GridItem>
          ))}
        </GridContainer>
      ) : null}
    </>
  );
}

export default CoMemberShip;
