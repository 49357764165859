import React, { useEffect, useState } from "react";
import "../PPS/sppoProfile.css";

// Custom Imports
import Header from "./Header/Header";
import Certifications from "../PPS/Certifications/Certifications";
import About from "../PPS/About/About";
import CoMemberShip from "../PPS/CoMemberShip/CoMemberShip";
import Footer from "../PPS/Footer/Footer";

import { updateLinguistProfile } from "redux/actions/updateProfileAction";
import Axios from "axios";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import { useLocation, useParams } from "react-router";
import useEmployeeStyles from "assets/jss/components/employeeStyle";

function PPL() {
  const classes = useEmployeeStyles();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/view-Sppo-details/${id}`
      );
      dispatch(updateLinguistProfile(response?.data));
      setData(response?.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <h3 style={{ textAlign: "center" }} color='purple'>SPPO Public Profile page for Approval</h3>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <Card>
            <div
              style={{
                background: "rgb(255,240,245)",
                padding: "20px",
                boxShadow:
                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
              }}
            >
              <Header />
            </div>

            <div
              style={{
                background: "rgb(255,240,245)",
                padding: "20px",
                boxShadow:
                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
              }}
            >
              <About />
            </div>

            <div
              style={{
                background: "rgb(255,240,245)",
                padding: "20px",
                boxShadow:
                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
              }}
            >
              <CoMemberShip id={data?._id} />
            </div>

            <div
              style={{
                background: "rgb(255,240,245)",
                padding: "20px",
                boxShadow:
                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
              }}
            >
              <Certifications id={data?._id} />
            </div>

          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default PPL;
