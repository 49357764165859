import React, { useState, Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";

// Material Core Components
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";

// Material Icons
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListAltIcon from "@material-ui/icons/ListAlt";

// Material Icons
import { Dashboard } from "@material-ui/icons";

// Custom Components
import SearchNav from "components/Users/Search/Admin";

// assets
// import logo from "assets/images/logo.png";
import ITLimage from "assets/icons/Interpreting-Translation-Linguists-Collective-Language-Services.png";

// Custom Styles
import useSidebarStyles from "assets/jss/components/sidebar";

// Routes
import ADMIN_ROUTES from "routes/admin";
import CASH_CLIENT from "routes/cashClient";
import EMPLOYEE from "routes/employee";
import LINGUIST from "routes/linguist";
import ACCOUNT_CLIENT from "routes/accountClient";
import SPPO from "routes/sppo";
import IconImg from "./IconImg";

// Images

// Others

const Sidebar = ({ handleDrawerToggle, mobileOpen, window, layoutType }) => {
  const [open, setOpen] = useState([]);

  const classes = useSidebarStyles();

  let routes;

  if (layoutType === "CASH_CLIENT") {
    routes = CASH_CLIENT;
  } else if (layoutType === "EMPLOYEE_CLIENT") {
    routes = EMPLOYEE;
  } else if (layoutType === "LINGUIST") {
    routes = LINGUIST;
  } else if (layoutType === "ACCOUNT_CLIENT") {
    routes = ACCOUNT_CLIENT;
  } else if (layoutType === "SPPO") {
    routes = SPPO;
  } else {
    routes = ADMIN_ROUTES;
  }

  const handleClick = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const brand = (
    <div className={classes.logo}>
      <Link to={`/${routes[0].path}`} className={classNames(classes.logoLink)}>
        <div className={classes.logoImage}>
          <img src={ITLimage} alt="logo" className={classes.img} />
        </div>
      </Link>
      <p className={classes.logoText}>
        Linguists Collective <br /> Interpreting & Translation
      </p>
    </div>
  );

  const drawer = (
    <div>
      {brand}
      <Hidden mdUp implementation="css" className={classes.pt15}>
        <SearchNav />
      </Hidden>
      <List className={classes.list}>
        {routes.map((item) => {
          return (
            item.text && (
              <Fragment key={item.id}>
                {item.subMenu ? (
                  <div
                    className={classes.item}
                    onClick={() => handleClick(item.id)}
                  >
                    <div className={classes.customDiv}>
                      <ListItem button className={classes.itemLink}>
                        <ListItemIcon className={classNames(classes.itemIcon)}>
                          {item.icon ? (
                            <IconImg width="55%" icon={item.icon} />
                          ) : (
                            <Dashboard />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.text}
                          className={classNames(classes.itemText)}
                          disableTypography={true}
                        />
                        {item.subMenu ? (
                          <Fragment>
                            {open.includes(item.id) ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </Fragment>
                        ) : null}
                      </ListItem>
                    </div>
                    {item.subMenu ? (
                      <Collapse
                        in={open.includes(item.id)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {item.subMenu.map((item) => (
                            <NavLink
                              to={`/${item.path}`}
                              className={classes.subItem}
                            >
                              <ListItem button className={classes.nested}>
                                <ListItemIcon
                                  className={classNames(classes.itemIcon)}
                                >
                                  {item.icon ? (
                                    <IconImg width="55%" icon={item.icon} />
                                  ) : (
                                    <ListAltIcon />
                                  )}
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                              </ListItem>
                            </NavLink>
                          ))}
                        </List>
                      </Collapse>
                    ) : null}
                  </div>
                ) : (
                  <NavLink
                    to={`/${item.path}`}
                    className={classes.item}
                    activeClassName="active"
                    onClick={() => handleClick(item.id)}
                  >
                    <div className={classes.customDiv}>
                      <ListItem button className={classes.itemLink}>
                        <ListItemIcon className={classNames(classes.itemIcon)}>
                          {item.icon ? (
                            <IconImg width="55%" icon={item.icon} />
                          ) : (
                            <Dashboard />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.text}
                          className={classNames(classes.itemText)}
                          disableTypography={true}
                        />
                      </ListItem>
                    </div>
                  </NavLink>
                )}
              </Fragment>
            )
          );
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default Sidebar;
