import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Moment from "moment";
// Custom Components
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import SearchView from "./Search/SearchView";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useToasts } from "react-toast-notifications";
//Axios
import Axios from "axios";
import Action from "components/Admin/BookingPanel/PrivateQuote/Action"
//Cookies
import Cookies from "js-cookie";


const AcceptedQuote = () => {
  const classes = useDashboardStyles();
  const [viewAll, setViewAll] = useState(null);
  const token = Cookies.get("EMPtoken");
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [SearchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleViewAll = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-saved-private-client-Quote`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewAll(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };


  const handleSearch = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-private-client-Quote/quoteID/${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewAll(...viewAll, response?.data);
      if (response?.data) {
        addToast("Quote found", { appearance: "success" });
      } else {
        addToast("Quote not found", { appearance: "error" });
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct Quote id.", {
          appearance: "error",
        });
      }
    }
  };

  useEffect(() => {
    handleViewAll();
  }, []);

  return (
    <>
      <SearchView
        load={handleViewAll}
        handleSearch={handleSearch}
        setSearchText={setSearchText}
        SearchText={SearchText}
        setShow={setShow}
        show={show}
      />

      <Paper className={classes.root}>
        {show &&
          <>
            <TableContainer className={classes.container}>
              <Table color="pink">
                <Table.Header>
                  <Table.Row style={{ textAlign: "left" }}>
                    <Table.Cell>Quote ID</Table.Cell>
                    <Table.Cell>Client ID</Table.Cell>
                    <Table.Cell>Linguist Ref ID</Table.Cell>
                    <Table.Cell>Estimate Amount</Table.Cell>
                    <Table.Cell>Quote Created Date</Table.Cell>
                    <Table.Cell>Expiry Date</Table.Cell>
                     <Table.Cell>Payment Status</Table.Cell>
                    <Table.Cell>Sending Status</Table.Cell>
                    <Table.Cell>Action</Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {viewAll?.map((list, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <Link to={`/employee/view-prc-quote-bylinguist/${list._id}`}>
                          {list.id}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        {list.privateClientID?.id
                          ? list.privateClientID.id
                          : "N/A"}
                      </Table.Cell>
                      <Table.Cell>
                        {list.postedBy?.id ? list.postedBy?.id : "N/A"}
                      </Table.Cell>
                      <Table.Cell>{list.grand_total}</Table.Cell>
                      <Table.Cell>
                        {Moment(list?.createdAt).format(
                          "DD MMM YYYY - kk:mm:ss"
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {Moment(list?.expiry_date).format(
                          "DD MMM YYYY - kk:mm:ss"
                        )}
                      </Table.Cell>
                      <Table.Cell>{list.status}</Table.Cell>
                      <Table.Cell>{list.sending_status}</Table.Cell>
                      <Action id={list?._id} load={handleViewAll} />
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={viewAll && viewAll.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        }
      </Paper>
    </>
  );
};

export default AcceptedQuote;
