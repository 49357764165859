import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomSelect from "components/Common/CustomSelect";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { CURRENT_COUNTRY, GENDER_DATA, EMAIL_REGEX } from "data";
import { updateCrStep, changeCrInput } from "redux/actions/crAction";
import CustomDate from "components/Common/CustomDate";
import CustomRadio from "components/Common/CustomRadio";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPhone from "components/Common/CustomPhone";
import { Button, Header } from "semantic-ui-react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";

const FormOne = () => {
  const { data } = useSelector((state) => state.crReducer);
  // const [photo, setPhoto] = useState(null);
  // const [passport, setPassport] = useState(null);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const [notValid, setNotValid] = useState(false);

  const FORM_TYPE = [
    "Quote Request",
    "Contact / Service Enquiry",
    "Call me back / leaving my details",
    "Client registration",
  ];

  const {
    AddressLine1,
    AddressLine2,
    Birthday,
    City,
    Country,
    FirstName,
    LastName,
    Nationality,
    PostalCode,
    Region,
    direct_line,
    business_email,
    mobile_no,
    sex,
    title,
    photo,
    passport,
    position,
    enquiry_reason,
    contact_address,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateCrStep(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleDateChange = (date) => {
    dispatch(
      changeCrInput({
        name: "Birthday",
        value: date,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeCrInput({
        name: "Country",
        value,
      })
    );
  };

  const handleChangeN = (e, value) => {
    dispatch(
      changeCrInput({
        name: "Nationality",
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeCrInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handlePhoto = async (e) => {
    dispatch(
      changeCrInput({
        name: "photo",
        value: e.target.files[0],
      })
    );
    // setPhoto(e.target.files[0]);
  };

  const handlePassport = async (e) => {
    dispatch(
      changeCrInput({
        name: "passport",
        value: e.target.files[0],
      })
    );
    // setPassport(e.target.files[0]);
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handlePassportClick = (e) => {
    e.preventDefault();
    fileInput2.current.click();
  };

  const isAllow =
    !FirstName ||
    !LastName ||
    !enquiry_reason ||
    (contact_address?.includes("Same") && !AddressLine1) ||
    (contact_address?.includes("Same") && !City) ||
    (contact_address?.includes("Same") && !Region) ||
    (contact_address?.includes("Same") && !PostalCode) ||
    (contact_address?.includes("Same") && !Country);

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <h4 style={{ textAlign: "center", paddingTop: "5px" }}>
        Personal or Company Legal Representative Details
      </h4>
      <CustomInputSingle
        onChange={handleChange}
        value={title}
        name="title"
        label="Title"
        width="100%"
        placeholder="e.g. Mr, Mrs, Miss, Ms etc"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={FirstName}
        name="FirstName"
        label="First Name(s)"
        placeholder="First Name(s) (Legal representative/director)"
        width="100%"
        required
      />
      <CustomInputSingle
        onChange={handleChange}
        value={LastName}
        name="LastName"
        label="Last Name"
        placeholder="Last Name (Legal representative/director)"
        width="100%"
        required
      />
      <CustomDate
        onChange={handleDateChange}
        value={Birthday}
        name="Birthday"
        label="Date of Birth"
        width="100%"
        maxDate={new Date("1-1-2000")}
      />
      <CustomRadio
        onChange={handleChange}
        value={sex}
        name="sex"
        data={GENDER_DATA}
        label="Gender"
        width="100%"
      />
      <CustomBigInputSingle
        onChange={handleEmailChange}
        value={business_email}
        name="business_email"
        label="Direct / personal email"
        moreInfo="If different"
        width="100%"
        placeholder="e.g. example@example.com"
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <br /> <br />
      <CustomPhone
        label="Mobile Number"
        onChange={handleMobile}
        placeholder="Mobile Number (Please select country then type number e.g. +44 7307 000000)"
        value={mobile_no}
        name="mobile_no"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={position}
        name="position"
        label="Your position"
        width="100%"
      />
      <CustomSelect
        onChange={handleChange}
        label="Enquiry Reason"
        data={FORM_TYPE}
        width="100%"
        name="enquiry_reason"
        value={enquiry_reason}
        required
      />
      <br />
      <input
        accept="image/jpeg, image/png"
        ref={fileInput}
        onChange={(e) => handlePhoto(e)}
        type="file"
        name="photo"
        style={{ display: "none" }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
          Upload profile photo or business logo
        </Header>
        <div>
          <Button onClick={handleClick}>Upload</Button>
        </div>
        {photo && (
          <Header style={{ margin: "0", textAlign: "center" }} as="h5">
            <Header style={{ margin: "0" }} as="h1">
              <InsertPhotoIcon fontSize="large" />
            </Header>
            {photo.name}
            <Header style={{ margin: "0" }} as="h5">{`Size: ${(
              photo.size / 1024
            ).toFixed(2)}kb`}</Header>
            <Header
              style={{ margin: "0" }}
              as="h5"
            >{`Last Modified: ${photo?.lastModifiedDate}`}</Header>
          </Header>
        )}
      </div>
      {contact_address?.includes("Same") ? (
        <>
          <CustomInputSingle
            onChange={handleChange}
            value={AddressLine1}
            name="AddressLine1"
            label="House number/name, Street name"
            width="100%"
            required
            placeholder="e.g. Flat/Building Name, 101 Example Street"
          />
          <CustomInputSingle
            onChange={handleChange}
            value={AddressLine2}
            name="AddressLine2"
            label="Address line 2"
            width="100%"
            placeholder="Additional Address Information"
          />
          <CustomInputSingle
            onChange={handleChange}
            value={City}
            name="City"
            label="Town / City"
            width="100%"
            required
            placeholder="Village, Town or City Name"
          />
          <CustomInputSingle
            onChange={handleChange}
            label="County / Region"
            width="100%"
            name="Region"
            value={Region}
            required
            placeholder="County, Division or State Name"
          />
          <CustomInputSingle
            onChange={handleChange}
            value={PostalCode}
            name="PostalCode"
            label="Post Code / Zip Code / Post office"
            width="100%"
            required
            placeholder="Post Code, Zip Code or Post Office Name"
          />
          <CustomAutoSelect
            onChange={handleAutoCompleteCO}
            label="Country"
            moreInfo="Current country of residence"
            width="100%"
            required
            value={Country}
            data={CURRENT_COUNTRY}
          />
        </>
      ) : (
        <>
          <CustomInputSingle
            onChange={handleChange}
            value={AddressLine1}
            name="AddressLine1"
            label="House number/name, Street name"
            width="100%"
            placeholder="e.g. Flat/Building Name, 101 Example Street"
          />
          <CustomInputSingle
            onChange={handleChange}
            value={AddressLine2}
            name="AddressLine2"
            label="Address line 2"
            width="100%"
            placeholder="Additional Address Information"
          />
          <CustomInputSingle
            onChange={handleChange}
            value={City}
            name="City"
            label="Town / City"
            width="100%"
            placeholder="Village, Town or City Name"
          />
          <CustomInputSingle
            onChange={handleChange}
            label="County / Region"
            width="100%"
            name="Region"
            value={Region}
            placeholder="County, Division or State Name"
          />
          <CustomInputSingle
            onChange={handleChange}
            value={PostalCode}
            name="PostalCode"
            label="Post Code / Zip Code / Post office"
            width="100%"
            placeholder="Post Code, Zip Code or Post Office Name"
          />
          <CustomAutoSelect
            onChange={handleAutoCompleteCO}
            label="Country"
            moreInfo="Current country of residence"
            width="100%"
            value={Country}
            data={CURRENT_COUNTRY}
          />{" "}
        </>
      )}
      <CustomAutoSelect
        onChange={handleChangeN}
        label="Current nationality"
        width="100%"
        name="Nationality"
        value={Nationality}
        data={CURRENT_COUNTRY}
      />
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "24px" }}
      >
        <input
          name="passport"
          ref={fileInput2}
          onChange={handlePassport}
          type="file"
          style={{ display: "none" }}
          accept="image/jpeg, image/png"
        />
        <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
          Upload personal identification (passport/DL/national ID)
        </Header>
        <div>
          <Button onClick={handlePassportClick}>Upload</Button>
        </div>
        {passport && (
          <Header style={{ margin: "0", textAlign: "center" }} as="h5">
            <Header style={{ margin: "0" }} as="h1">
              <InsertPhotoIcon fontSize="large" />
            </Header>
            {passport.name}
            <Header style={{ margin: "0" }} as="h5">{`Size: ${(
              passport.size / 1024
            ).toFixed(2)}kb`}</Header>
            <Header
              style={{ margin: "0" }}
              as="h5"
            >{`Last Modified: ${passport?.lastModifiedDate}`}</Header>
          </Header>
        )}
      </div>
      <CustomInputSingle
        onChange={handleChange}
        value={direct_line}
        name="direct_line"
        label="Direct line with extension no"
        width="100%"
        placeholder="Direct line no + any extension no (e.g. +44123456789 Ext. 123)"
      />
      <div className={classes.buttonFlex}>
        <CustomButton
          text="Next"
          onClick={handleNext}
          disabled={isAllow || notValid}
        />
      </div>
    </form>
  );
};

export default FormOne;
