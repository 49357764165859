import CustomAvatar from "components/Common/CustomAvatar";
import React, { useEffect } from "react";
import Details from "./Details";
import InputItemsAC from "./InputItemsAC";
import PriceDropdown from "./PriceDropdown";
import { useParams } from "react-router-dom";
import Axios from "utils/axios";

const MonthlyBill = ({ exportPdf }) => {
  const { billID } = useParams();
  const { clientID } = useParams();
  const [bill, setBill] = React.useState(null);

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getBillDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/manager-admin/view-Bill-details/billID/${billID}`
        );

        setBill(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          console.log(error.response?.data);
        }
      }
    };

    getBillDetails();

    return () => {
      source.cancel();
    };
  }, []);

  const handlePrevent = (e) => {
    e.preventDefault();
  };

  return (
    <div style={{ userSelect: "none" }} onContextMenu={(e) => handlePrevent(e)}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: "1",
          }}
        >
          Bill date: {bill?.billing_month}
        </div>
        <CustomAvatar style={{ margin: "8px 0 8px 0" }} />
      </div>
      <Details />
      <PriceDropdown />
      <InputItemsAC exportPdf={exportPdf} />
      {/* <Actions id = {id} /> */}
    </div>
  );
};

export default MonthlyBill;
