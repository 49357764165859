import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Button } from "semantic-ui-react";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import swal from "sweetalert";

function DeleteAccountRequest({ deleteRequest }) {
  const classes = useDashboardStyles();
  const token = Cookies.get("BACtoken");
  const matches = useMediaQuery("(max-width:768px)");
  const [data, setData] = useState({
    reason: "",
  });
  const { reason } = data;
  const [checked, setChecked] = useState(false);
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    try {
      const response = Axios.put(
        "/api/accountclient/delete-request",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        text: `Delete account request successfully send to the admin team.`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/account-client/profile";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response ? error?.response?.data?.error : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <>
      {matches ? (
        <Box>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Request Account Deletion</p>
            </CardHeader>
            <CardBody>
              {deleteRequest ? (
                <h3>
                  Request is under review, please make sure that you don't have
                  any active tasks, assignments, quotes, invoices, or jobs to be
                  completed
                </h3>
              ) : (
                <>
                  <TextField
                    multiline
                    rows={1}
                    rowsMax={5}
                    label="Reason for deleting your account*"
                    name="reason"
                    onChange={handleChange}
                    fullWidth
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={() => setChecked(!checked)}
                      />
                    }
                    label="Please note that, account deleting process cannot be performed if you have an active task, assignment, quote, invoice, or a job to be completed, upon completion of all active assignment or job and payment requirements satisfied LC will start the process of deleting by suspending or archiving the account for six months. After suspension period, if no dispute or request for re-activation received your account will be deleted permanently. If you have no active task this maybe take place right away.
          All personal data including business data will be lost, Are you sure you want to proceed with deleting this account?*"
                    style={{
                      width: 100 + "%",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  />
                  <Button
                    onClick={handleSubmit}
                    disabled={!reason || !checked}
                    color="red"
                  >
                    Request Delete
                  </Button>
                </>
              )}
            </CardBody>
          </Card>
        </Box>
      ) : (
        <Box ml={16} mr={16}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Request Account Deletion</p>
            </CardHeader>
            <CardBody>
              {deleteRequest ? (
                <h3>
                  Request is under review, please make sure that you don't have
                  any active tasks, assignments, quotes, invoices, or jobs to be
                  completed
                </h3>
              ) : (
                <>
                  <TextField
                    multiline
                    rows={1}
                    rowsMax={5}
                    label="Reason for deleting your account*"
                    name="reason"
                    onChange={handleChange}
                    fullWidth
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={() => setChecked(!checked)}
                      />
                    }
                    label="Please note that, account deleting process cannot be performed if you have an active task, assignment, quote, invoice, or a job to be completed, upon completion of all active assignment or job and payment requirements satisfied LC will start the process of deleting by suspending or archiving the account for six months. After suspension period, if no dispute or request for re-activation received your account will be deleted permanently. If you have no active task this maybe take place right away.
            All personal data including business data will be lost, Are you sure you want to proceed with deleting this account?*"
                    style={{
                      width: 100 + "%",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  />
                  <Button
                    onClick={handleSubmit}
                    disabled={!reason || !checked}
                    color="red"
                  >
                    Request Delete
                  </Button>
                </>
              )}
            </CardBody>
          </Card>
        </Box>
      )}
    </>
  );
}

export default DeleteAccountRequest;
