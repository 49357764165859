import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import LandingPageLayout from "layout/LandingPageLayout";
import { Card, Typography, Divider } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import ThreeCards from "./ThreeCards";
import ELSimage from "assets/images/expert-language-solutions.jpg";
import CCimage from "assets/icons/cultural-consultancy.png";
import CMimage from "assets/icons/cultural-mediation.png";
import TRAimage from "assets/icons/training.png";
import TRCimage from "assets/icons/transcription.png";
import VOAimage from "assets/icons/voice-over-artist.png";
import TRANimage from "assets/icons/transart.png";
import RTimage from "assets/icons/reverse-translation.png";
import PRimage from "assets/icons/proof-reading.png";
import CWimage from "assets/icons/content-writing.png";
import Eimage from "assets/icons/editing.png";
import RAimage from "assets/icons/research-assistance.png";
import LTimage from "assets/icons/language-tuition.png";
import STimage from "assets/icons/subtitling.png";

const Translation = () => {
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 4rem 0 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div>
            <img src={ELSimage} alt="" style={{ width: "100%" }} />
          </div>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={CCimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700" }}
                  paragraph
                  variant="h5"
                >
                  Cultural Consultancy
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Some companies have struggled to successfully expand
                  internationally due to their failure to understand the local
                  culture and adapt their communications to engage their target
                  audience. To succeed, we offer a range of cultural consultancy
                  services which we adapt to suit the needs of each client and
                  the preferences of local audiences. When we want to
                  communicate with people of a different culture than ours, we
                  should be aware of that cultural practices, as well as
                  language so that we can be understood. We must pay attention
                  to our non-verbal communication (e.g. gestures). Since every
                  client is unique and requires a different approach, our
                  cultural consultancy services often involve conducting
                  informal cultural education to ensure that they are
                  appropriate for adaptation in a local community.At Linguists
                  Collective, our team of experts have years of experience
                  in cultural and language consultancy and are here to help and
                  advice you on how to best communicate with your client. If you
                  require this service, please contact us for more information.
                  {/* They are understanding of history, beliefs and strengths which
                  continues throughout a land and they have lived with the
                  people that speak the language. Our Consultants have at least
                  7 years of industry experience as a linguist, interpreter or
                  translator. They have achieved at least alevel 6 or an
                  undergraduate level of relevant qualifications. If necessary,
                  they have attended further specialised training, working with
                  police, or essential workers, for example. This is to ensure
                  that our workers are given the respect they deserve about
                  their profession, and not taken advantage of. It is no secret
                  that linguist’s have been treated unfairly by agencies in the
                  past outsourcing their services, and making a profit whilst
                  linguist’s themselves received little. At Linguist’s
                  Collective, we do not align ourselves with such behaviour. We
                  wholeheartedly believe in Fair and Ethical treatment of our */}
                  staff and team. Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={CMimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Cultural Mediation
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  A cultural mediator facilitates mutual understanding between a
                  person or a group of people (e.g. the migrant/refugee
                  population) and a caregiver (e.g. a doctor) by providing
                  two-way verbal translation (interpreting) and helping those
                  overcome cultural barriers. Cultural mediation is the process
                  of building bridges between the cultural and social realms,
                  and the building of new relationships between the political,
                  cultural and public spheres. If two people are unable to
                  communicate with each other due to language differences, a
                  mediator can help to translate in the best possible way to
                  avoid any miscommunication or conflict. Our cultural mediator
                  has first-hand experience and training to provide such
                  services, prioritising customer needs. The mediator
                  (interpreter) can intervene to explain cultural differences
                  and ensure that neither party causes unintended offence.
                  Request a <Link to="/add-new-customer">Quote</Link> or
                  register to place your order, for short and long term contract
                  or permanent employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={TRAimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Training
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  We understand that there are many people who may not be
                  trained in linguists in their line of work. As such, if there
                  are areas in which an individual lacks knowledge, we can
                  facilitate training programmes available to them through
                  authorities or professionals who are responsible for providing
                  services to people from specific background. This includes
                  community and charity services which deal with community
                  matters, government regulations, migration, modern day slavery
                  and refugees. Please contact us for further details. Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={VOAimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Voiceover and Voiceover Artist
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  {" "}
                  If you are looking for a voiceover artist to give your brand a
                  voiceover in any style, language, or accent specific to
                  geographical location, try our site to find a professional
                  that do voiceovers for living or a linguist that has a passion
                  for it. Our professionals will take into consideration every
                  aspect you are looking for in a voiceover and aim to deliver
                  satisfactory results. We can make that difficult task of
                  finding someone to do what you need, much easier! You can
                  hire, by either posting a job using job post, which will
                  advertise your specific requirements, or by requesting a
                  quotation. Job post is the most commonly opted for, it will
                  increase your chances of finding a local voice artist as the
                  job advert will be circulated to all linguists within our
                  system. Request a <Link to="/add-new-customer">Quote</Link> or
                  register to place your order, for short and long term contract
                  or permanent employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={TRCimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Transcription
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  We have a list of qualified and experienced linguists and
                  audio typists from whom you can hire the best, most suited for
                  the job. Our linguists will follow instructions accurately,
                  putting the client's needs first. If there are any concerns,
                  our members can carry out our own Internet research for
                  unfamiliar terminology such as youth slung / street language,
                  and spelling. This will ensure a consistent and high standard
                  of work delivered, that our clients can appreciate and trust.
                  By requesting a quotation, you will have opportunity to
                  compare expertise and availability. Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={TRANimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Transart or Transcreation
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Transcreation is a concept used in the field of translation
                  studies to describe the process of adapting a message from one
                  language to another, while maintaining its intent, style,
                  tone, and context. A successfully transcreated message evokes
                  the same emotions and carries the same implications in the
                  target language as it does in the source language. It is
                  related to the concept of localisation, and this can involve
                  comprehensively adapting a translated text for a specific
                  demographic. Transcreation will emphasise the translator's
                  creative role. Unlike other forms of translation,
                  transcreation is not limited to just adapting words, but can
                  also focus on video and images. Transcreation is a linguistic
                  editorial art. If our clients should require this service,
                  they can request a quotation here. Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={RTimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Reverse Translation
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Reverse translation is mainly used for small messages and
                  marketing. It is a translation form involving a team, and is
                  therefore occasionally treated like a project. A group of
                  translators will translate from a source language to a target
                  language, whilst another group will translate that back into
                  the source language. They can achieve this without needing to
                  know what the original text was. This is done to test the
                  original message has remained unchanged and nothing has been
                  lost in translation. If a client requires this kind of
                  service, they can request a quotation here. Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={STimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Subtitling Translation
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Subtitling translation process includes: spotting,
                  translation, correction and simulation. It is important that
                  we allow our service users to ensure accuracy of their work in
                  regard to this. The ideal result is that the subtitles are
                  attune with the audio, a way which sounds natural and fluent.
                  This will be done so well, that the viewer will be undisturbed
                  by the subtitles and almost unaware that they are reading.
                  There won’t be a feeling of having missed any crucial details
                  due to text distraction. You can hire a subtitling translator
                  by requesting a quote from us. Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={PRimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Proofreading
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Proofreading is vital for any form of written language. It
                  ensures the text reads with correct grammar usage. Without
                  accurate proofreading, any piece of work can be misinterpreted
                  or be seen as nonsensical. Due to improper grammar, we aim to
                  make sure that if our customers are struggling to bring their
                  work up to scratch, a proof-reader is readily available. You
                  can request general proofreading with us by submitting a
                  quote, or if you are requesting a translation service, there
                  is an option to add proofreading. Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={Eimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Editing
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Not all organisations need an in-house editor, and not every
                  business requires all materials in a second language. However,
                  we do understand many clients may wish to seek out an editor
                  to help ensure all written material is of the best possible
                  quality. Our company can help you to find a reliable editor,
                  you can trust our linguists to get the job done! Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={CWimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Content Writing
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  If you are looking for a localised content writer for your
                  product, service, protection work, rescue or support work, get
                  in touch and we can provide you with one of our talented
                  linguists. We will make sure that any professional assigned to
                  the job, has the relevant and necessary experience to help you
                  write accurate, culturally appropriate and relevant content to
                  deliver your intended message without compromise. Content
                  editors typically require a degree in language related
                  disciplines such as mass communications, journalism or another
                  specific field. Rest assured; our system will allow you to see
                  the best professional for the job. Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={LTimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Language Tuition
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Are you engaged with providing a service to a community that
                  you can hardly understand due to language barriers? Are you
                  doing business with minority communities? Would you like to
                  know more about their language and culture? Then you’ve come
                  to the right place! Why not consider learning with one of our
                  professional linguists at Linguists Collective? Even if you
                  only need to know the basics or have a limited budget,
                  consider hiring one of our Level 1 (assistant) linguists, who
                  can help you learn at a very reasonable price. However, if you
                  are looking for help at an advanced level or intend to sit an
                  exam, we highly recommend hiring one of our professional
                  language teachers with a Level X qualification (associate
                  professional). Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={RAimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  Research Assistant
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  If you are involved in cross culture research that requires
                  knowledge in two or more languages and an understanding of
                  local culture, you can hire our professional linguists to help
                  you. Our staff are fully qualified, experienced and verified,
                  with various levels of security clearance for your peace of
                  mind. At Linguists Collective, we aim to deliver! Request a{" "}
                  <Link to="/add-new-customer">Quote</Link> or register to place
                  your order, for short and long term contract or permanent
                  employment please use{" "}
                  <Link to="/post-job-advert">Job Advert</Link>
                </Typography>
                <Typography variant="body1"></Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <ThreeCards />
      </div>
      {/* </div> */}
    </LandingPageLayout>
  );
};

export default Translation;
