// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PRINCPublic from "components/ConfirmedProjectReportINC/PRINCPublic"
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import LandingPageLayout from "layout/LandingPageLayout";

const ProjectReportPP = () => {
  const classes = useDashboardStyles();
  return (
    <LandingPageLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>In-house client project report</p>
            </CardHeader>
            <CardBody>
              <PRINCPublic />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default ProjectReportPP;
