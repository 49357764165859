import { makeStyles } from "@material-ui/core/styles";

import {
  primaryColor,
  whiteColor,
  primaryBoxShadow,
  defaultFont,
  grayColor,
} from "../variables";

const dropdownStyle = (theme) => ({
  dropdownItem: {
    ...defaultFont,
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    WebkitTransition: "all 150ms linear",
    MozTransition: "all 150ms linear",
    OTransition: "all 150ms linear",
    MsTransition: "all 150ms linear",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    lineHeight: "1.42857143",
    color: grayColor[8],
    whiteSpace: "nowrap",
    height: "unset",
    minHeight: "unset",
    "&:hover": {
      backgroundColor: primaryColor[0],
      color: whiteColor,
      ...primaryBoxShadow,
    },
  },
  linkText: {
    textDecoration: "none",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "1.42857143",
    color: grayColor[8],
    "&:hover": {
      backgroundColor: primaryColor[0],
      color: whiteColor,
      ...primaryBoxShadow,
    },
  },
  toolTip: {
    marginTop: "12px",
    minWidth: "200px",
    zIndex: 50000,
  },
  search: {
    marginTop: "12px",
    minWidth: "200px",
    zIndex: 60000,
  },
  myNote: {
    ...defaultFont,
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    marginBottom: "4px",
    marginTop: "5px",
    borderRadius: "2px",
    WebkitTransition: "all 150ms linear",
    MozTransition: "all 150ms linear",
    OTransition: "all 150ms linear",
    MsTransition: "all 150ms linear",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    lineHeight: "1.42857143",
    color: "white",
    whiteSpace: "nowrap",
    height: "unset",
    minHeight: "unset",
    backgroundColor: primaryColor[3],
    "&:hover": {
      backgroundColor: primaryColor[3],
      color: whiteColor,
      ...primaryBoxShadow,
    },
  },
  CoNote: {
    ...defaultFont,
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    WebkitTransition: "all 150ms linear",
    MozTransition: "all 150ms linear",
    OTransition: "all 150ms linear",
    MsTransition: "all 150ms linear",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    lineHeight: "1.42857143",
    color: "white",
    whiteSpace: "nowrap",
    height: "unset",
    minHeight: "unset",
    background: "linear-gradient(60deg, #ec407a, #d81b60)",
    "&:hover": {
      background: "linear-gradient(60deg, #ec407a, #d81b60)",
      color: whiteColor,
      ...primaryBoxShadow,
    },
  },
});

const useDropdownStyles = makeStyles(dropdownStyle);

export default useDropdownStyles;
