import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import "semantic-ui-css/semantic.min.css";
// import "./components/App.css"

// Custom Components
import App from "components/App";

// Redux
import store from "redux/store";

// Custom Styles
import Theme from "assets/jss/theme";

// Others
import * as serviceWorker from "./serviceWorker";
import history from "utils/history";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Theme>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </Theme>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
