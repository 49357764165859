import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "react-loading-screen";
import Button from "@material-ui/core/Button";
// Custom Components
import CustomSelect from "components/Common/CustomSelect";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";
import CustomUpload from "components/Common/CustomUpload";
import CustomRadio from "components/Common/CustomRadio";
import Time from "components/Auth/LinguistsCollectiveBooking/Time";
import moment from "moment-timezone";
import Cookies from "js-cookie";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import history from "utils/history";
// Others
import {
  LANG_COMB,
  ITP_SERVICE_REQUIRED,
  TYPE_INTERPRETING,
  SKILL_LEVEL_REQUIRED,
  SECURITY_CLEARANCE_LEVEL,
  CAR_PARKING,
  CURRENT_COUNTRY,
  GENDER_DATA,
  TIMEZONE,
  RARE_REGEX,
  LEVEL_REGEX
} from "data";

import { Checkbox, Icon } from "@material-ui/core";
import { changeIBTInput } from "redux/actions/ibtAction";
import CustomAutoSelect from "components/Common/CustomAutoSelect";

import swal from "sweetalert";
import Axios from "axios";

const FormTwo = ({ adminID, adminName }) => {
  const { data } = useSelector((state) => state.ibtReducer);
  const dispatch = useDispatch();
  // const { username } = JSON.parse(localStorage.getItem("user"));
  const { calender } = useSelector((state) => state.datetimeReducer);
  const [loading, setLoading] = useState(false);
  const {
    select_client,
    purchase_order_number,
    timezone,
    interpreting_service_required,
    house_number_street_name,
    address_line_two,
    county,
    post_code,
    country,
    remote_address,
    source_language,
    target_language,
    source_linguist_skill_level,
    target_linguist_skill_level,
    typesof_interpreting_required,
    job_description,
    preferred_interpreter,
    gender,
    security_clearance,
    car_parking,
    additional_info,
    upload_file,
    agree,
    town,
  } = data;
  const token = Cookies.get("token");
  const classes = useFormStyles();

  const handleNext = async (e) => {
    swal(
      "Have you completed all details? Please note that once this form submitted, you will not be able to amend any details here. Are you sure you want to submit this form now? ",
      {
        icon: "warning",
        buttons: ["Cancel", "Yes"],
      }
    ).then((willDelete) => {
      if (willDelete) {
        // history.push("book-interpreter/summary")
        setLoading(true);
        let totalCount = calender.total_days;
        //
        let startdateArray = [];
        let enddateArray = [];
        let starttimeArray = [];
        let endtimeArray = [];
        let durationArray = [];

        //  let totalCount = calender.total_days;

        //count 1
        if (totalCount.length === 1) {
          startdateArray.push(calender.startdateOne);
          enddateArray.push(calender.enddateOne);
          starttimeArray.push(calender.starttimeOne);
          endtimeArray.push(calender.endtimeOne);
          durationArray.push(calender.durationOne);
        }
        //count 1
        if (totalCount.length === 2) {
          startdateArray.push(calender.startdateOne, calender.startdateTwo);

          enddateArray.push(calender.enddateOne, calender.enddateTwo);

          starttimeArray.push(calender.starttimeOne, calender.starttimeTwo);

          endtimeArray.push(calender.endtimeOne, calender.endtimeTwo);
          durationArray.push(calender.durationOne, calender.durationTwo);
        }

        //count 1
        if (totalCount.length === 3) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree
          );
        }

        //count 1
        if (totalCount.length === 4) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour
          );
        }
        //count 1
        if (totalCount.length === 5) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive
          );
        }
        //count 1
        if (totalCount.length === 6) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix
          );
        }

        ///DONE TILL HERE ////////////////////////////////////////////////////////
        //count 1
        if (totalCount.length === 7) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven
          );
        }
        //count 1
        if (totalCount.length === 8) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight
          );
        }
        //count 1
        if (totalCount.length === 9) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine
          );
        }
        //count 1
        if (totalCount.length === 10) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen
          );
        }
        //count 1
        if (totalCount.length === 11) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven
          );
        }
        //count 1
        if (totalCount.length === 12) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve
          );
        }
        //count 1
        ////////////////////////////
        if (totalCount.length === 13) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen
          );
        }
        //count 1
        if (totalCount.length === 14) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen
          );
        }

        /////////////////////////////////////////////////////////////////////////////////////////////
        //count 1
        if (totalCount.length === 15) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen
          );
        }
        //count 1
        if (totalCount.length === 16) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen
          );
        }
        //count 1
        if (totalCount.length === 17) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen
          );
        }
        //count 1
        if (totalCount.length === 18) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen
          );
        }
        //count 1
        if (totalCount.length === 19) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen
          );
        }
        //count 1
        if (totalCount.length === 20) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty
          );
        }
        //count 1
        if (totalCount.length === 21) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne
          );
        }
        //count 1
        if (totalCount.length === 22) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne,
            calender.durationTwentyTwo
          );
        }
        //count 1
        if (totalCount.length === 23) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne,
            calender.durationTwentyTwo,
            calender.duration23
          );
        }
        //count 1
        if (totalCount.length === 24) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne,
            calender.durationTwentyTwo,
            calender.duration23,
            calender.duration24
          );
        }
        //count 1
        if (totalCount.length === 25) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne,
            calender.durationTwentyTwo,
            calender.duration23,
            calender.duration24,
            calender.duration25
          );
        }
        //count 1
        if (totalCount.length === 26) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne,
            calender.durationTwentyTwo,
            calender.duration23,
            calender.duration24,
            calender.duration25,
            calender.duration26
          );
        }
        //count 1
        if (totalCount.length === 27) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26,
            calender.startdate27
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26,
            calender.enddate27
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26,
            calender.starttime27
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26,
            calender.endtime27
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne,
            calender.durationTwentyTwo,
            calender.duration23,
            calender.duration24,
            calender.duration25,
            calender.duration26,
            calender.duration27
          );
        }

        //count 1
        if (totalCount.length === 28) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26,
            calender.startdate27,
            calender.startdate28
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26,
            calender.enddate27,
            calender.enddate28
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26,
            calender.starttime27,
            calender.starttime28
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26,
            calender.endtime27,
            calender.endtime28
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne,
            calender.durationTwentyTwo,
            calender.duration23,
            calender.duration24,
            calender.duration25,
            calender.duration26,
            calender.duration27,
            calender.duration28
          );
        }

        //count 1
        if (totalCount.length === 29) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26,
            calender.startdate27,
            calender.startdate28,
            calender.startdate29
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26,
            calender.enddate27,
            calender.enddate28,
            calender.enddate29
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26,
            calender.starttime27,
            calender.starttime28,
            calender.starttime29
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26,
            calender.endtime27,
            calender.endtime28,
            calender.endtime29
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne,
            calender.durationTwentyTwo,
            calender.duration23,
            calender.duration24,
            calender.duration25,
            calender.duration26,
            calender.duration27,
            calender.duration28,
            calender.duration29
          );
        }

        //count 1
        if (totalCount.length === 30) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26,
            calender.startdate27,
            calender.startdate28,
            calender.startdate29,
            calender.startdate30
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26,
            calender.enddate27,
            calender.enddate28,
            calender.enddate29,
            calender.enddate30
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26,
            calender.starttime27,
            calender.starttime28,
            calender.starttime29,
            calender.starttime30
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26,
            calender.endtime27,
            calender.endtime28,
            calender.endtime29,
            calender.endtime30
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne,
            calender.durationTwentyTwo,
            calender.duration23,
            calender.duration24,
            calender.duration25,
            calender.duration26,
            calender.duration27,
            calender.duration28,
            calender.duration29,
            calender.duration30
          );
        }

        //count 1
        if (totalCount.length === 31) {
          startdateArray.push(
            calender.startdateOne,
            calender.startdateTwo,
            calender.startdateThree,
            calender.startdateFour,
            calender.startdateFive,
            calender.startdateSix,
            calender.startdateSeven,
            calender.startdateEight,
            calender.startdateNine,
            calender.startdateTen,
            calender.startdateEleven,
            calender.startdateTwelve,
            calender.startdateThirteen,
            calender.startdateFourteen,
            calender.startdateFifteen,
            calender.startdateSixteen,
            calender.startdateSeventeen,
            calender.startdateEighteen,
            calender.startdateNineteen,
            calender.startdateTwenty,
            calender.startdateTwentyOne,
            calender.startdateTwentyTwo,
            calender.startdate23,
            calender.startdate24,
            calender.startdate25,
            calender.startdate26,
            calender.startdate27,
            calender.startdate28,
            calender.startdate29,
            calender.startdate30,
            calender.startdate31
          );

          enddateArray.push(
            calender.enddateOne,
            calender.enddateTwo,
            calender.enddateThree,
            calender.enddateFour,
            calender.enddateFive,
            calender.enddateSix,
            calender.enddateSeven,
            calender.enddateEight,
            calender.enddateNine,
            calender.enddateTen,
            calender.enddateEleven,
            calender.enddateTwelve,
            calender.enddateThirteen,
            calender.enddateFourteen,
            calender.enddateFifteen,
            calender.enddateSixteen,
            calender.enddateSeventeen,
            calender.enddateEighteen,
            calender.enddateNineteen,
            calender.enddateTwenty,
            calender.enddateTwentyOne,
            calender.enddateTwentyTwo,
            calender.enddate23,
            calender.enddate24,
            calender.enddate25,
            calender.enddate26,
            calender.enddate27,
            calender.enddate28,
            calender.enddate29,
            calender.enddate30,
            calender.enddate31
          );

          starttimeArray.push(
            calender.starttimeOne,
            calender.starttimeTwo,
            calender.starttimeThree,
            calender.starttimeFour,
            calender.starttimeFive,
            calender.starttimeSix,
            calender.starttimeSeven,
            calender.starttimeEight,
            calender.starttimeNine,
            calender.starttimeTen,
            calender.starttimeEleven,
            calender.starttimeTwelve,
            calender.starttimeThirteen,
            calender.starttimeFourteen,
            calender.starttimeFifteen,
            calender.starttimeSixteen,
            calender.starttimeSeventeen,
            calender.starttimeEighteen,
            calender.starttimeNineteen,
            calender.starttimeTwenty,
            calender.starttimeTwentyOne,
            calender.starttimeTwentyTwo,
            calender.starttime23,
            calender.starttime24,
            calender.starttime25,
            calender.starttime26,
            calender.starttime27,
            calender.starttime28,
            calender.starttime29,
            calender.starttime30,
            calender.starttime31
          );

          endtimeArray.push(
            calender.endtimeOne,
            calender.endtimeTwo,
            calender.endtimeThree,
            calender.endtimeFour,
            calender.endtimeFive,
            calender.endtimeSix,
            calender.endtimeSeven,
            calender.endtimeEight,
            calender.endtimeNine,
            calender.endtimeTen,
            calender.endtimeEleven,
            calender.endtimeTwelve,
            calender.endtimeThirteen,
            calender.endtimeFourteen,
            calender.endtimeFifteen,
            calender.endtimeSixteen,
            calender.endtimeSeventeen,
            calender.endtimeEighteen,
            calender.endtimeNineteen,
            calender.endtimeTwenty,
            calender.endtimeTwentyOne,
            calender.endtimeTwentyTwo,
            calender.endtime23,
            calender.endtime24,
            calender.endtime25,
            calender.endtime26,
            calender.endtime27,
            calender.endtime28,
            calender.endtime29,
            calender.endtime30,
            calender.endtime31
          );

          durationArray.push(
            calender.durationOne,
            calender.durationTwo,
            calender.durationThree,
            calender.durationFour,
            calender.durationFive,
            calender.durationSix,
            calender.durationSeven,
            calender.durationEight,
            calender.durationNine,
            calender.durationTen,
            calender.durationEleven,
            calender.durationTwelve,
            calender.durationThirteen,
            calender.durationFourteen,
            calender.durationFifteen,
            calender.durationSixteen,
            calender.durationSeventeen,
            calender.durationEighteen,
            calender.durationNineteen,
            calender.durationTwenty,
            calender.durationTwentyOne,
            calender.durationTwentyTwo,
            calender.duration23,
            calender.duration24,
            calender.duration25,
            calender.duration26,
            calender.duration27,
            calender.duration28,
            calender.duration29,
            calender.duration30,
            calender.duration31
          );
        }

        let total_Duration = calender.total_duration;
        Cookies.set("total_Duration", total_Duration);
        //noe append to the backend
        let fd = new FormData();
        fd.append("select_client", select_client);
        fd.append("purchase_order_number", purchase_order_number);
        fd.append("timezone", timezone);
        fd.append("upload_file", upload_file);
        fd.append("source_language", source_language);
        fd.append("target_language", target_language);
        fd.append("source_linguist_skill_level", source_linguist_skill_level);
        fd.append("target_linguist_skill_level", target_linguist_skill_level);
        fd.append(
          "interpreting_service_required",
          interpreting_service_required
        );
        fd.append("house_number_street_name", house_number_street_name);
        fd.append("town", town);
        fd.append("address_line_two", address_line_two);
        fd.append("county", county);
        fd.append("post_code", post_code);
        fd.append("country", country);
        fd.append("remote_address", remote_address);
        fd.append(
          "typesof_interpreting_required",
          typesof_interpreting_required
        );
        fd.append("job_description", job_description);
        fd.append("preferred_interpreter", preferred_interpreter);
        fd.append("gender", gender);
        fd.append("security_clearance", security_clearance);
        fd.append("car_parking", car_parking);
        fd.append("additional_info", additional_info);
        fd.append("agree", agree);
        //  fd.append('username', username)
        fd.append("startdateArray", startdateArray);
        fd.append("enddateArray", enddateArray);
        fd.append("starttimeArray", starttimeArray);
        fd.append("endtimeArray", endtimeArray);
        fd.append("durationArray", durationArray);
        fd.append("total_Duration", total_Duration);
        fd.append("adminID", adminID);
        fd.append("adminName", adminName);

        try {
          const response = Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/manager/interpreter-assignment-booking-telephonic-form`,
            fd
            // {
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //   },
            // }
          )
            .then((response) => {
              //for currency
              Cookies.set("currency", response?.data.message.currency_new);
              Cookies.set("x_date", response?.data.message.x_date);

              Cookies.set("BACusername", response?.data.message.BACusername);
              Cookies.set("senderemail", response?.data.message.send_email);
              // //id
              Cookies.set("id", response?.data.message.token);

              // //totalPriceForTranslation
              Cookies.set(
                "total_Overnight_charges",
                response?.data.message.total_Overnight_charges
              );

              // //totalPriceForCertificate
              Cookies.set(
                "total_PriceForInterpriting",
                response?.data.message.total_PriceForInterpriting
              );

              // //totalPriceForQualityCheck
              Cookies.set(
                "total_TravelTotal_Wages",
                response?.data.message.total_TravelTotal_Wages
              );
              Cookies.set(
                "tatal_Food_Overnight",
                response?.data.message.tatal_Food_Overnight
              );
            })
            .then(function () {
              history.push("book-interpreter/summary");
              //  window.location = "/admin/book-interpreter/summary";
            });
        } catch (error) {
          if (error.response) {
            swal({
              title: "Oops!",
              text: `${error?.response?.data?.error}`,
              icon: "error",
              buttons: false,
            });
          }
        }
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeIBTInput({
        name,
        value: value,
      })
    );
  };
  const handleChecked = (e) => {
    const { name, checked } = e.target;
    dispatch(
      changeIBTInput({
        name,
        value: checked,
      })
    );
  };
  const handleChangeT = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "target_language",
        value,
      })
    );
  };

  const handleExpertise = (value) => {
    if (target_language?.includes("R") || source_language?.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(
        LEVEL_REGEX
       );
    }
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "country",
        value,
      })
    );
  };

  const handleFiles = (e) => {
    const value = e.target.files[0];

    if (value.size >= 5000000) {
      swal({
        title: "Kindly select less than 5 mb file",
      });
    } else {
      dispatch(
        changeIBTInput({
          name: "upload_file",
          value,
        })
      );
    }
  };

  const handleSecurityChange = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "security_clearance",
        value,
      })
    );
  };
  const handleTypeChange = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "typesof_interpreting_required",
        value,
      })
    );
  };

  const handleChangeTZ = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "timezone",
        value,
      })
    );
  };
  const handleChangeS = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "source_language",
        value,
      })
    );
  };
  const isAllow =
    !select_client ||
    !interpreting_service_required ||
    (interpreting_service_required.includes("FTP") &&
      !house_number_street_name) ||
    (interpreting_service_required.includes("FTP") && !county) ||
    (interpreting_service_required.includes("FTP") && !post_code) ||
    (interpreting_service_required.includes("FTP") && !country) ||
    (interpreting_service_required.includes("Remote") && !remote_address) ||
    !source_language ||
    !target_language ||
    !target_linguist_skill_level ||
    !source_linguist_skill_level ||
    !job_description ||
    !agree;
  //fetch

  return (
    <div>
      {loading === false ? (
        <div>
          <form className={classes.form} noValidate autoComplete="off">
            {/* "Developer- add more option to book a multi day job. One Day job or Multi day job, allow easy pick without making mistake"  */}

            <CustomInputSingle
              onChange={handleChange}
              width="100%"
              label="Your booking ref/ Purchse order no"
              name="purchase_order_number"
              value={purchase_order_number}
              placeholder="Enter your reference number or purchase order number"
            />

            <CustomAutoSelect
              onChange={handleChangeTZ}
              label="Select time zone"
              width="100%"
              data={TIMEZONE}
              name="timezone"
              //defaultValue= {moment.tz.guess()}
              value={timezone}
              helperText={moment.tz.guess()}
            />

            <Time />

            <CustomSelect
              required
              onChange={handleChange}
              label="Interpreting service required"
              data={ITP_SERVICE_REQUIRED}
              width="100%"
              name="interpreting_service_required"
              value={interpreting_service_required}
            />

            {/* Developer - make this conditional if remote selected only be able to add remote platform details and phone number. If FTF selected show address field, if Remote address optional for invoicing purpose only. FTF onsite must provide venue details clearly including any department or building block */}

            {/* moreInfo="Developer - customer must select two, allow customer to book easily another combination which should become two jobs. System must be able to check match of languages 'not one both ie English <> Bengali '. Apply condition both selection cannot be same"  */}
            {interpreting_service_required !==
            "Remote Interpreting (RI) / Video Remote Interpreting (VRI)" ? (
              <Fragment>
                <CustomInputSingle
                  required
                  onChange={handleChange}
                  width="100%"
                  label="House Number / Name, Street Name"
                  name="house_number_street_name"
                  value={house_number_street_name}
                  placeholder="e.g. Flat/Building Name, 101 Example Street"
                />
                <CustomInputSingle
                  onChange={handleChange}
                  width="100%"
                  label="Address Line 2"
                  name="address_line_two"
                  value={address_line_two}
                  placeholder="Additional Address Information"
                />
                <CustomInputSingle
                  required
                  onChange={handleChange}
                  width="100%"
                  label="Town / City"
                  name="town"
                  value={town}
                  placeholder="Your Village, Town or City Name"
                />
                <CustomInputSingle
                  required
                  onChange={handleChange}
                  width="100%"
                  label="County / Region"
                  name="county"
                  value={county}
                  placeholder="Your County, Division or State Name"
                />
                <CustomInputSingle
                  required
                  onChange={handleChange}
                  width="100%"
                  label="Post / Zip code"
                  name="post_code"
                  value={post_code}
                  placeholder="Your Post Code, Zip Code or Post Office Name"
                />
                <CustomAutoSelect
                  onChange={handleAutoCompleteCO}
                  label="Country"
                  moreInfo="Current country of residence"
                  width="100%"
                  name="country"
                  value={country}
                  data={CURRENT_COUNTRY}
                  required
                />
              </Fragment>
            ) : (
              <CustomBigInputSingle
                required
                onChange={handleChange}
                width="100%"
                name="remote_address"
                value={remote_address}
                label="Remote Interpreting (RI/VRI) details"
                moreInfo="Please provide details Zoom link, Skype, phone number etc"
                placeholder="How linguist will join you? e.g. Zoom, Microsoft Team link, conference phone number etc."
              />
            )}
            <CustomAutoSelect
              required
              onChange={handleChangeS}
              label="Source/Native language"
              data={LANG_COMB}
              width="100%"
              name="source_language"
              value={source_language}
            />

            <CustomSelect
              required
              onChange={handleChange}
              label="Please indicate linguist's source language skills level required"
              moreInfo="Please indicate linguist's source language skills level required for this assignment (1 - 5)"
              data={SKILL_LEVEL_REQUIRED.filter((value) =>
                handleExpertise(value)
              )}
              width="100%"
              name="source_linguist_skill_level"
              value={source_linguist_skill_level}
            />

            <CustomAutoSelect
              required
              onChange={handleChangeT}
              label="Target language"
              data={LANG_COMB.filter((value) => value !== source_language)}
              width="100%"
              name="target_language"
              value={target_language}
            />
            <CustomSelect
              required
              onChange={handleChange}
              label="Please indicate linguist's target language skills level required"
              moreInfo="Please indicate linguist's target language skills level required for this assignment (1 - 5)"
              data={SKILL_LEVEL_REQUIRED.filter((value) =>
                handleExpertise(value)
              )}
              width="100%"
              name="target_linguist_skill_level"
              value={target_linguist_skill_level}
            />
            {/* (Developer* - this is a mandatory */}
            <CustomAutoSelect
              required
              onChange={handleTypeChange}
              label="Type/s of Interpreting required"
              moreInfo="Please pick the most relevant one/s)"
              data={TYPE_INTERPRETING}
              width="100%"
              name="typesof_interpreting_required"
              value={typesof_interpreting_required}
            />
            <CustomBigInput
              required
              onChange={handleChange}
              width="100%"
              label="Job description"
              moreInfo="Please provide brief information for the benefit of linguist's preparation"
              name="job_description"
              value={job_description}
              placeholder=" Provide details for interpreter e.g. solicitor conference, police interview, visiting GP etc."
            />
            <CustomBigInputSingle
              onChange={handleChange}
              width="100%"
              label="Preferred Linguist / Service provider ID number"
              moreInfo="LC will try its best, but cannot guarantee"
              name="preferred_interpreter"
              value={preferred_interpreter}
              placeholder="Provide details of the preferred linguist e.g. LIN/PRO12345 - Jane Miller"
            />
            <CustomRadio
              onChange={handleChange}
              label="Gender"
              moreInfo="Please note that we can not guarantee gender"
              data={GENDER_DATA}
              width="100%"
              value={gender}
            />
            <CustomAutoSelect
              onChange={handleSecurityChange}
              label="Please indicate security clearance level required*"
              moreInfo="Please indicate security clearance level required for this assignment"
              data={SECURITY_CLEARANCE_LEVEL}
              width="100%"
              name="security_clearance"
              value={security_clearance}
            />
            {interpreting_service_required.includes("Face") ? (
              <CustomSelect
                onChange={handleChange}
                label="Do you have car parking for linguist?"
                data={CAR_PARKING}
                width="100%"
                name="car_parking"
                value={car_parking}
              />
            ) : null}
            <CustomInput
              onChange={handleChange}
              label="Additional information and direction for linguist"
              width="100%"
              name="additional_info"
              value={additional_info}
              placeholder="Provide any other information including direction that you would like linguist to have"
            />

            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <CustomUpload
                color="primary"
                onChange={handleFiles}
                accept=".pdf,.docx,.JPEG"
                name="upload_file"
                width="100%"
                label="Upload any script or instruction here, pdf or photos/JPEG only)"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button color="secondary">
                {upload_file ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Icon name="file pdf" size="big" />
                    <p
                      style={{
                        margin: "0",
                        marginTop: "7px",
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                      }}
                    >
                      {upload_file.name}
                    </p>
                    <p>{upload_file?.lastModifiedDate?.toDateString()} </p>
                    <p style={{ margin: "0" }}>
                      {(upload_file.size / 1024).toFixed(2)}Kb{" "}
                    </p>
                  </div>
                ) : null}
              </Button>
            </div>

            <div
              className={classes.tc}
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <p>
                <Checkbox
                  onChange={handleChecked}
                  checked={agree}
                  name="agree"
                  color="primary"
                />
                I have confirmed the details with the client and informed client
                must agree with LC T&C and Privacy policy for completing this
                booking / order / quote request
              </p>
            </div>

            <div className={classes.buttonFlex}>
              <CustomButton
                text="Submit"
                disabled={isAllow}
                value={agree}
                onClick={handleNext}
              />
            </div>
          </form>
        </div>
      ) : (
        <LoadingScreen
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          logoSrc="https://linguistscollective.com/static/media/Interpreting-Translation-Linguists-Collective-Language-Services.0f1108ca.png"
          text="Please wait!  The system is calculating booking summary."
        >
          <div>Loadable content</div>
        </LoadingScreen>
      )}
    </div>
  );
};

export default FormTwo;
