import SearchInput from "./SearchInput";
// import ViewDeleteRequests from "./ViewDeleteRequests";

const SearchDeleteRequest = ({setShow,show}) => {
  return (
    <div>
      <SearchInput setShow={setShow} show={show}/>
      {/* <ViewDeleteRequests /> */}
    </div>
  );
};

export default SearchDeleteRequest;
