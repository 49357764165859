import Welcome from "components/Users/Linguist";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Options from "components/Linguist/Jobs/Options";
import LinguistDashboard from "components/Linguist/LinguistDashboard";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const Dashboard = () => {
  const classes = useDashboardStyles();

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12} style={{ width: "100%" }}>
            <Welcome />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <p className={classes.cardTitleWhite}>Linguist Dashboard</p>
                <Options />
              </CardHeader>
              <CardBody>
                {" "}
                <LinguistDashboard />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default Dashboard;
