import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import SPPOList from "components/Admin/AccountFinance/SPPO/SPPOList/ListSPPO";
import { Button } from "semantic-ui-react";
import history from "utils/history";
import { IconButton, useMediaQuery } from "@material-ui/core";

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const matchesSmall = useMediaQuery("(max-width:425px)");
  const [finance_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAdminAccess(response?.data.finance_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {finance_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                {matchesSmall ? (
                  <CardHeader style={{ display: "block" }} color="primary">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className={classes.cardTitleWhite}>
                        Direct Quotes And Invoices (SPPO)
                      </p>
                      <IconButton
                        color="inherit"
                        style={{ marginLeft: 5 }}
                        onClick={() => history.goBack()}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        onClick={() =>
                          history.push("/admin/invoice-to-sppo/create-invoice")
                        }
                        color="purple"
                      >
                        Raise invoice
                      </Button>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        onClick={() =>
                          history.push("/admin/admin-to-sppo-direct-invoices")
                        }
                        color="purple"
                      >
                        Direct invoice
                      </Button>
                    </div>
                  </CardHeader>
                ) : (
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Direct Quote And Invoice (SPPO)
                    </p>
                    <div>
                      <Button
                        onClick={() =>
                          history.push("/admin/invoice-to-sppo/create-invoice")
                        }
                        color="purple"
                      >
                        Raise invoice
                      </Button>
                      <Button
                        onClick={() =>
                          history.push("/admin/admin-to-sppo-direct-invoices")
                        }
                        color="purple"
                      >
                        Direct invoice
                      </Button>
                      <IconButton
                        color="inherit"
                        style={{ marginLeft: 5 }}
                        onClick={() => history.goBack()}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </div>
                  </CardHeader>
                )}

                <CardBody>
                  <SPPOList />
                </CardBody>
              </Card>
            </GridItem>
          ) : finance_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
