
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Axios from "utils/axios";
import { useParams } from 'react-router-dom'
import moment from "moment"
import Action from "../ActionDelivery"

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const { id } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { bookingList } = values;


  useEffect(() => {
    loadbookingList();
  }, []);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(`/api/for-linguists-admin/get_Translation_Delivery_Doc_list/assignmentID/${id}`)
      setValues({ ...values, bookingList: response?.data });
    } catch (error) {
      console.log(error)
    }
  };


  let sl = 1

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">

              <TableHead>
                <TableRow>
                  <TableCell>Document</TableCell>
                  <TableCell>Date and time (London)</TableCell>
                  <TableCell>Action</TableCell>

                </TableRow>
              </TableHead>

              {bookingList.length > 0 ?
                <TableBody>
                  {bookingList

                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((list, i) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                        <TableCell >{sl++}</TableCell>
                        <TableCell >{moment(list.createdAt).tz('Europe/London').format("DD MMM YYYY - HH:mm:ss")}</TableCell>
                        <TableCell >
                          <Action
                            id={list?._id}
                            load={loadbookingList}
                            doc1True={list?.doc1True}
                            doc2True={list?.doc2True}
                            doc3True={list?.doc3True}
                            doc4True={list?.doc4True}
                            doc5True={list?.doc5True}
                            bac={"bac"}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                :

                null}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={bookingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          /></div>

      </Paper>
    </div>
  );
}