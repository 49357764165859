import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import SearchView from "./Search/SearchAssignment";
import Cookies from "js-cookie";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

import Action from "./DirectAction";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("SPPOtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadInvoiceList();
  }, [reload]);

  const loadInvoiceList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/saved-list-client-Invoice`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, bookingList: response?.data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/find-saved-client-Invoice`,
        { id: SearchText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct invoice id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });

        addToast("Invoice found", { appearance: "success" });
      } else {
        addToast("Invoice not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct invoice id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <div>
      <SearchView
        load={loadInvoiceList}
        handleSearch={handleSearch}
        setSearchText={setSearchText}
        SearchText={SearchText}
        setShow={setShow}
        show={show}
      />
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Invoice no.</Table.HeaderCell>
                    <Table.HeaderCell>Date of invoice</Table.HeaderCell>
                    <Table.HeaderCell>Client ID</Table.HeaderCell>
                    <Table.HeaderCell>Invoice Total</Table.HeaderCell>
                    <Table.HeaderCell>VAT</Table.HeaderCell>
                    <Table.HeaderCell>Due Date</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {bookingList.length > 0 ? (
                  <Table.Body>
                    {bookingList
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list) => (
                        <Table.Row key={list._id}>
                          <Table.Cell key={list._id} align={list.align}>
                            {list.id}
                          </Table.Cell>
                          <Table.Cell>{list.date_of_invoice}</Table.Cell>
                          <Table.Cell>{list?.client_id}</Table.Cell>
                          <Table.Cell>{list.grand_total}</Table.Cell>
                          <Table.Cell>{list.vat_amount}</Table.Cell>
                          <Table.Cell>
                            {moment(list.invoice_tobe_paid_by).format(
                              "DD MMM YYYY HH:mm"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {list?.cancel
                              ? "Cancelled"
                              : list?.invoice_payment_status
                              ? "PAID"
                              : list.sending_status}
                          </Table.Cell>
                          {list.sending_status == "Saved" ? (
                            <Table.Cell>
                              <Action
                                id={list._id}
                                type="Saved"
                                load={loadInvoiceList}
                              />
                            </Table.Cell>
                          ) : (
                            <Table.Cell>
                              <Action
                                id={list._id}
                                cancel={list?.cancel}
                                load={loadInvoiceList}
                                pay={list?.invoice_payment_status}
                              />
                            </Table.Cell>
                          )}
                        </Table.Row>
                      ))}
                  </Table.Body>
                ) : (
                  "No data found"
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
