import React, { useEffect, useState } from "react";
import Items from "./TableBody";

//Style
import "./style.css";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { Rating } from "@material-ui/lab";
import CustomTextInput from "components/Common/CustomTextInput";
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import TimesheetFooter from "./ItemsTwo";
import { useSelector } from "react-redux";
import CustomSnackBar from "components/Common/CustomSnackBar";
import history from "utils/history";
import { useMediaQuery } from "@material-ui/core";

const Details = () => {
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const { data } = useSelector((state) => state.timesheetReducer);
  const matches = useMediaQuery("(max-width:768px)");
  const [submit, setSubmit] = useState({
    toast: false,
    loader: false,
  });

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getTimeSheet = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/view-details-timesheet/timesheetID/${id}`
        );
        setDetails(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    getTimeSheet();

    return () => {
      source.cancel();
    };
  }, []);

  return details ? (
    <div>
      <div style={{ width: "100%", marginTop: "24px" }}>
        <CustomSnackBar
          message="Timesheet submitted to client"
          handleClose={() => setSubmit({ ...submit, toast: false })}
          openSnackBar={submit.toast}
        />
        <CustomTextInput
          label="Job Reference Number"
          value={
            details.cacID?.id
              ? details.cacID?.id
              : details.bacID?.id
                ? details.bacID?.id
                : details.inhouseID?.id
                  ? details.inhouseID?.id
                  : "N/A"
          }
        />

        <Items details={details} />

        <CustomTextInput
          label="Instructions for linguist professional"
          value=""
        />

        {/* Address details */}
        {details.cacID?.remote_address
          ? details.cacID?.remote_address
          : details.bacID?.remote_address
            ? details.bacID?.remote_address
            : details.inhouseID?.remote_address && (
              <CustomTextInput
                label="Remote Address"
                value={
                  details.cacID?.remote_address
                    ? details.cacID?.remote_address
                    : details.bacID?.remote_address
                      ? details.bacID?.remote_address
                      : details.inhouseID?.remote_address
                }
              />
            )}

        {!details.cacID?.remote_address ? (
          !details.cacID?.remote_address
        ) : !details.bacID?.remote_address ? (
          !details.bacID?.remote_address
        ) : !details.inhouseID?.remote_address ? (
          <>
            <CustomTextInput
              label="House Number / Street Name"
              value={
                details.cacID?.house_number_street_name
                  ? details.cacID?.house_number_street_name
                  : details.bacID?.house_number_street_name
                    ? details.bacID?.house_number_street_name
                    : details.inhouseID?.house_number_street_name
                      ? details.inhouseID?.house_number_street_name
                      : "N/A"
              }
            />

            <CustomTextInput
              label="Address Line 2"
              value={
                details.cacID?.address_line_two
                  ? details.cacID?.address_line_two
                  : details.bacID?.address_line_two
                    ? details.bacID?.address_line_two
                    : details.inhouseID?.address_line_two
                      ? details.inhouseID?.address_line_two
                      : "N/A"
              }
            />

            <CustomTextInput
              label="County / Region"
              value={
                details.cacID?.region
                  ? details.cacID?.region
                  : details.bacID?.region
                    ? details.bacID?.region
                    : details.inhouseID?.region
                      ? details.inhouseID?.region
                      : "N/A"
              }
            />

            <CustomTextInput
              label="Post Code"
              value={
                details.cacID?.post_code
                  ? details.cacID?.post_code
                  : details.bacID?.post_code
                    ? details.bacID?.post_code
                    : details.inhouseID?.post_code
                      ? details.inhouseID?.post_code
                      : "N/A"
              }
            />

            <CustomTextInput
              label="Country"
              value={
                details.cacID?.country
                  ? details.cacID?.country
                  : details.bacID?.country
                    ? details.bacID?.country
                    : details.inhouseID?.country
                      ? details.inhouseID?.country
                      : "N/A"
              }
            />
          </>
        ) : null}
        <CustomTextInput
          label="Court case, police, NHS ref"
          value={
            details.cacID?.security_clearance
              ? details.cacID?.security_clearance
              : details.bacID?.security_clearance
                ? details.bacID?.security_clearance
                : details.inhouseID?.security_clearance
                  ? details.inhouseID?.security_clearance
                  : "N/A"
          }
        />

        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
        >
          <Header style={{ margin: "0" }} as="h4">
            Feedback to client
          </Header>
          <div>
            <Rating
              name="simple-controlled"
              value={details.feedback}
              readOnly
            />
          </div>
        </div>

        <p>
          Customer / Client Name:
          <b>
            {details.cacID?.postedBy?.FirstName
              ? details.cacID?.postedBy?.FirstName
              : details.bacID?.postedBy?.FirstName
                ? details.bacID?.postedBy?.FirstName
                : details.inhouseID?.ClientID?.FirstName
                  ? details.inhouseID?.ClientID?.FirstName
                  : "N/A"}
          </b>
          <b>
            {details.cacID?.postedBy?.LastName
              ? details.cacID?.postedBy?.LastName
              : details.bacID?.postedBy?.LastName
                ? details.bacID?.postedBy?.LastName
                : details.inhouseID?.ClientID?.LastName
                  ? details.inhouseID?.ClientID?.LastName
                  : "N/A"}
          </b>
        </p>

        <CustomTextInput
          label="Customer / Client ref no."
          value={
            details.cacID?.postedBy?.id
              ? details.cacID?.postedBy?.id
              : details.bacID?.postedBy?.id
                ? details.bacID?.postedBy?.id
                : details.inhouseID?.ClientID?.id
                  ? details.inhouseID?.ClientID?.id
                  : "N/A"
          }
        />

        <CustomTextInput
          label="Emergency Contact no."
          value={
            details.cacID?.postedBy?.mobile_no
              ? details.cacID?.postedBy?.mobile_no
              : details.bacID?.postedBy?.mobile_no
                ? details.bacID?.postedBy?.mobile_no
                : details.inhouseID?.ClientID?.mobile_no
                  ? details.inhouseID?.ClientID?.mobile_no
                  : "N/A"
          }
        />

        <CustomTextInput label="Name of limited speaker conference" value="" />
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
        >
          <Header style={{ margin: "0" }} as="h4">
            Feedback to linguist
          </Header>
          <div>
            <Rating
              name="simple-controlled"
              value={details.feedbackByClient}
              readOnly
            />
          </div>
        </div>

        <TimesheetFooter details={details} />
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {history.location.pathname.includes("/admin") ? (
          <div
            style={{
              display: matches ? "block" : "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              style={{
                marginBottom: matches ? "5px" : "0px",
                width: matches ? "100%" : "auto",
              }}
              onClick={() =>
                history.push(`/admin/view-timesheet-document-by-linguist/${id}`)
              }
              color="purple"
            >
              View Document (LIN){" "}
            </Button>
            <Button
              style={{
                marginBottom: matches ? "5px" : "0px",
                width: matches ? "100%" : "auto",
              }}
              onClick={() =>
                history.push(`/admin/view-timesheet-document-by-client/${id}`)
              }
              color="purple"
            >
              View Document (Client){" "}
            </Button>
          </div>
        ) : history.location.pathname.includes("/linguist") ? (
          <div
            style={{
              display: matches ? "block" : "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              style={{
                marginBottom: matches ? "5px" : "0px",
                width: matches ? "100%" : "auto",
              }}
              onClick={() =>
                history.push(
                  `/linguist/view-timesheet-document-by-linguist/${id}`
                )
              }
              color="purple"
            >
              View Document (LIN){" "}
            </Button>
            <Button
              style={{
                marginBottom: matches ? "5px" : "0px",
                width: matches ? "100%" : "auto",
              }}
              onClick={() =>
                history.push(
                  `/linguist/view-timesheet-document-by-client/${id}`
                )
              }
              color="purple"
            >
              View Document (Client){" "}
            </Button>
          </div>
        ) : history.location.pathname.includes("/cash-client") ? (
          <div
            style={{
              display: matches ? "block" : "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              style={{
                marginBottom: matches ? "5px" : "0px",
                width: matches ? "100%" : "auto",
              }}
              onClick={() =>
                history.push(
                  `/cash-client/view-timesheet-document-by-linguist/${id}`
                )
              }
              color="purple"
            >
              View Document (LIN){" "}
            </Button>
            <Button
              style={{
                marginBottom: matches ? "5px" : "0px",
                width: matches ? "100%" : "auto",
              }}
              onClick={() =>
                history.push(
                  `/cash-client/view-timesheet-document-by-client/${id}`
                )
              }
              color="purple"
            >
              View Document (Client){" "}
            </Button>
          </div>
        ) : history.location.pathname.includes("/account-client") ? (
          <div
            style={{
              display: matches ? "block" : "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              style={{
                marginBottom: matches ? "5px" : "0px",
                width: matches ? "100%" : "auto",
              }}
              onClick={() =>
                history.push(
                  `/account-client/view-timesheet-document-by-linguist/${id}`
                )
              }
              color="purple"
            >
              View Document (LIN){" "}
            </Button>
            <Button
              style={{
                marginBottom: matches ? "5px" : "0px",
                width: matches ? "100%" : "auto",
              }}
              onClick={() =>
                history.push(
                  `/account-client/view-timesheet-document-by-client/${id}`
                )
              }
              color="purple"
            >
              View Document (Client){" "}
            </Button>
          </div>
        ) : null}

        {submit.loader && (
          <div style={{ padding: "10px" }}>
            <Dimmer>
              <Loader active inline />
            </Dimmer>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
