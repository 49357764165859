// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from 'utils/history'
//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import MasterLayout from "layout/MasterLayout";
import PRCQuote from "components/Admin/TimesheetProjectReport/Invoice/ViewPRCQuote";

const ProjectReportINCPage = () => {
  const classes = useDashboardStyles();
  return (
    <MasterLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader style={{
              display: "flex",
              justifyContent: "space-between",
            }} color="primary">
              <p className={classes.cardTitleWhite}>Private Quote</p>
              <IconButton color='inherit'><ArrowBackIcon onClick={() => history.goBack()} /></IconButton>
            </CardHeader>
            <CardBody>
              <PRCQuote />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </MasterLayout>
  );
};

export default ProjectReportINCPage;
