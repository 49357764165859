
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Material Core Components
import Typography from "@material-ui/core/Typography";

// Material Icons

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import Axios from "axios";
// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import CustomFormControl from "components/Common/CustomFormControl";
import { Progress } from "semantic-ui-react";
import LandingPageLayout from "layout/LandingPageLayout";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie'

const InhouseClientFormWeb = () => {
  const { count } = useSelector((state) => state.icrReducer);

  const classes = useEmployeeStyles();

  const selectedForm = count;

  const { linID } = useParams()


  const [linguistsppo, LinSppoDetails] = useState([]);

  const LinguistSppoDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/only-view-LinID/SppoID/${linID}`
      );
      LinSppoDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };


  useEffect(() => {
    LinguistSppoDetails();
  }, []);

  Cookies.set('linID', linID)

  return (
    linguistsppo?.id ?
      <LandingPageLayout>
        <div className={classes.root}>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className={`${classes.textCenter} ${classes.title}`}>
                    <CustomAvatar />
                    <Typography component="h1" variant="h5">
                      In-house Client Registration
                    </Typography>
                    <Typography variant="subtitle2">Page {count} of 2</Typography>
                    <Typography variant="subtitle2">Reference ID: {linguistsppo?.id}</Typography>
                  </div>
                  {selectedForm === 1 && <FormOne />}
                  {selectedForm === 2 && <FormTwo />}
                  {/* {selectedForm === 3 && <FormThree />} */}
                  <CustomFormControl width="73.33%">
                    <Progress
                      color="purple"
                      percent={(selectedForm / 2) * 100}
                      active
                      size="small"
                    />
                  </CustomFormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </LandingPageLayout>
      : <Card>
        <div
          style={{
            backgroundImage:
              "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
            padding: "20px",
            boxShadow:
              "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            textAlign: "center"
          }}
        >
          This page is not available.
        </div>
      </Card>
  );
};

export default InhouseClientFormWeb;
