import AcepDec from "./AcepDec";

export const langRow = (text) => (
  <div
    style={{
      maxWidth: "120px",
      margin: "auto",
    }}
  >
    {text}
  </div>
);

export const dateRow = (text) => (
  <div
    style={{
      maxWidth: "130px",
      margin: "auto",
    }}
  >
    {text}
  </div>
);

export const addressRow = (text) => (
  <div
    style={{
      maxWidth: "200px",
      margin: "auto",
    }}
  >
    {text}
  </div>
);

export const acceptDecline = (
  type,
  fn,
  calcAcceptedRoute,
  handleDecline,
  id,
  email,
  declineOp,
  linID,
  target_level,
  target_language,
  startdateArray,
  streetName,
  postalCode,
  region,
  country,
  interpreting_service_required,
  distance
) => (
  <AcepDec
    type={type}
    fn={fn}
    calcAcceptedRoute={calcAcceptedRoute}
    handleDecline={handleDecline}
    id={id}
    email={email}
    declineOp={declineOp}
    lindID={linID}
    target_level={target_level}
    target_language={target_language}
    start_date={startdateArray}
    streetName={streetName}
    postalCode={postalCode}
    region={region}
    country={country}
    distance={distance}
    interpreting_service_required={interpreting_service_required}
  />
);
