import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

//Custom style
import useEmployeeStyles from "assets/jss/components/employeeStyle";

//Axios
import Axios from "utils/axios";

//Cookies
import Cookies from "js-cookie";

//Redux imports
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";

//Sweet alert
import swal from "sweetalert";

//hisory
import { useHistory } from "react-router-dom";
import { Checkbox, Dimmer, Loader, Table } from "semantic-ui-react";

//Level allocation
import TargetUpgradeAction from "components/Admin/Linguist/TargetUpgradeAction";
import TargetUpgradeAction1 from "components/Admin/Linguist/TargetUpgradeAction1";
import TargetUpgradeAction2 from "components/Admin/Linguist/TargetUpgradeAction2";

import SourceUpgradeAction from "components/Admin/Linguist/SourceUpgradeAction";
import SourceUpgradeAction1 from "components/Admin/Linguist/SourceUpgradeAction1";
import SourceUpgradeAction2 from "components/Admin/Linguist/SourceUpgradeAction2";

import OtherUpgradeAction from "components/Admin/Linguist/OtherUpgradeAction";

const AllocateAccess = ({ id, load }) => {
  const history = useHistory();
  const token = Cookies.get("token");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const handleVerifyAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/verified-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Verified",
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "verified",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleUnverifyAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/admin-disable-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Unverified",
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "verified",
          value: false,
        })
      );
      dispatch(
        changeLinguistProfileInput({
          name: "cacAndBacAccess",
          value: false,
        })
      );
      dispatch(
        changeLinguistProfileInput({
          name: "inhouseAccess",
          value: false,
        })
      );
      dispatch(
        changeLinguistProfileInput({
          name: "cashbookAndCalculatiorAccess",
          value: false,
        })
      );
      dispatch(
        changeLinguistProfileInput({
          name: "privateAccess",
          value: false,
        })
      );
      dispatch(
        changeLinguistProfileInput({
          name: "leadAccess",
          value: false,
        })
      );
      dispatch(
        changeLinguistProfileInput({
          name: "myclientAccess",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleToggle = async (value) => {
    if (!data.verified) {
      if (!data?.target_level_no > 0 || !data?.source_level_no > 0) {
        swal({
          icon: "warning",
          text: "You cannot mark as verified without allocating Linguist / Professional expertise level. Please allocate Level 0 or more than 0.",
          buttons: false,
          dangerMode: true,
        });
      } else {
        swal(
          "Are you sure you want to verify this Linguist / Professional. Have you checked all documents including Passport / ID, Qualifications, Security Certificate etc.?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              handleVerifyAccess(value);

            default:
              break;
          }
        });
      }
    } else {
      swal(
        "Are you sure you want to mark this linguist / professional as unverified?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleUnverifyAccess(value);

          default:
            break;
        }
      });
    }
  };

  const handleAssignmentAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/cacAndBac-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "cacAndBacAccess",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableAssignmentAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/cacAndBac-disable-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "cacAndBacAccess",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const EnableInhouseAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/inhouse-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "inhouseAccess",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableInhouseAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/inhouse-disable-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "inhouseAccess",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const EnablePrivateAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/private-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "privateAccess",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisablePrivateAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/private-disable-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "privateAccess",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const EnableCashbookAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/cashbook-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "cashbookAndCalculatiorAccess",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableCashbookAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/cashbook-disable-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "cashbookAndCalculatiorAccess",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const EnableLeadAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/lead-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "leadAccess",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableLeadAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/lead-disable-access-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "leadAccess",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const EnableLinguist = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "linguist",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const EnableProfessional = async (value) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/professional-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      dispatch(
        changeLinguistProfileInput({
          name: "professional",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableProfessional = async (value) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/professional-linguist-disable/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      dispatch(
        changeLinguistProfileInput({
          name: "professional",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const EnableContractor = async (value) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/contractor-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      dispatch(
        changeLinguistProfileInput({
          name: "contractor",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableContractor = async (value) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/contractor-linguist-disable/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      dispatch(
        changeLinguistProfileInput({
          name: "contractor",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const EnableTrainer = async (value) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/trainer-linguist/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      dispatch(
        changeLinguistProfileInput({
          name: "trainer",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableTrainer = async (value) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/trainer-linguist-disable/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      dispatch(
        changeLinguistProfileInput({
          name: "trainer",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAssignment = async (value) => {
    if (data.verified) {
      if (!data.cacAndBacAccess) {
        swal("Are you sure you want to do this ?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              handleAssignmentAccess(value);

            default:
              break;
          }
        });
      } else {
        swal(
          "Are you sure you want to disable CAC and BAC client assignment access?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              DisableAssignmentAccess(value);

            default:
              break;
          }
        });
      }
    }
  };

  const handleInhouseAccess = (value) => {
    if (data.verified) {
      if (!data.inhouseAccess) {
        swal("Are you sure you want to do this ?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              EnableInhouseAccess(value);

            default:
              break;
          }
        });
      } else {
        swal("Are you sure you want to disable Inhouse access?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              DisableInhouseAccess(value);

            default:
              break;
          }
        });
      }
    }
  };

  const handlePrivateAccess = (value) => {
    if (data.verified) {
      if (!data.privateAccess) {
        swal("Are you sure you want to do this ?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              EnablePrivateAccess(value);

            default:
              break;
          }
        });
      } else {
        swal("Are you sure you want to disable Private access?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              DisablePrivateAccess(value);

            default:
              break;
          }
        });
      }
    }
  };

  const handleCashbookAccess = async (value) => {
    if (data.verified) {
      if (!data.cashbookAndCalculatiorAccess) {
        swal("Are you sure you want to do this ?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              EnableCashbookAccess(value);

            default:
              break;
          }
        });
      } else {
        swal("Are you sure you want to disable Cashbook access?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              DisableCashbookAccess(value);

            default:
              break;
          }
        });
      }
    }
  };

  const handleLeadAccess = async (value) => {
    if (data.verified) {
      if (!data.leadAccess) {
        swal("Are you sure you want to do this ?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              EnableLeadAccess(value);

            default:
              break;
          }
        });
      } else {
        swal("Are you sure you want to disable Lead access?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              DisableLeadAccess(value);

            default:
              break;
          }
        });
      }
    }
  };

  const handleProfessional = async (value) => {
    if (!data.professional) {
      swal("Are you sure you want to do this ?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            EnableProfessional(value);

          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable Linguist position ?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            DisableProfessional(value);

          default:
            break;
        }
      });
    }
  };

  const handleLinguist = async (value) => {
    if (data.linguist) {
      swal(
        "Linguist position is default, if you change it then please select another position of linguist.",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Okay!",
              value: "catch",
            },
          },
        }
      );
    } else {
      swal("Are you sure you want to access Linguist position ?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            EnableLinguist(value);

          default:
            break;
        }
      });
    }
  };

  const handleContractor = async (value) => {
    if (!data.contractor) {
      swal("Are you sure you want to do this ?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            EnableContractor(value);

          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable Linguist position ?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            DisableContractor(value);

          default:
            break;
        }
      });
    }
  };

  const handleTrainer = async (value) => {
    if (!data.trainer) {
      swal("Are you sure you want to do this ?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            EnableTrainer(value);

          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable Linguist position ?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            DisableTrainer(value);

          default:
            break;
        }
      });
    }
  };

  const handlePublicProfile = (value) => {
    if (data.verified) {
      if (!data.adminAllowPublicProfile) {
        swal(
          "Have you visited this Linguist's Public Profile Preview page and checked all details? Please make sure Linguist information matching documents and contain NO personal contact details within the profile.",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              EnableadminAllowPublicProfile(value);

            default:
              break;
          }
        });
      } else {
        swal("Are you sure you want to disable this public profile?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              DisableadminAllowPublicProfile(value);

            default:
              break;
          }
        });
      }
    }
  };

  const EnableadminAllowPublicProfile = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/allow-public-profile/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Approved",
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "adminAllowPublicProfile",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableadminAllowPublicProfile = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/disable-public-profile/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled public profile",
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "adminAllowPublicProfile",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      {loader && (
        <Dimmer active inverted>
          <Loader size="large">Changing Access</Loader>
        </Dimmer>
      )}
      <Table structured celled color={"pink"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="1" textAlign="center">
              Label
            </Table.HeaderCell>
            <Table.HeaderCell width="1" textAlign="center">
              Language Name
            </Table.HeaderCell>
            <Table.HeaderCell width="1" textAlign="center">
              Level (0 - 5 & X)
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Source / Native Language(1)
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              {data?.source_language ? data.source_language : "N/A"}
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <SourceUpgradeAction
                level={data.source_language_level}
                lang={data?.source_language ? data.source_language[0] : "N/A"}
                id={id}
                verified={data.verified}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Source / Native Language(2)
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              {data?.source_language_1 ? data.source_language_1 : "N/A"}
            </Table.Cell>

            <Table.Cell width="1" textAlign="center">
              {data?.source_language_1 === "" ? null : (
                <SourceUpgradeAction1
                  level={data.source_language_level_1}
                  id={id}
                  verified={data.verified}
                  lang={
                    data?.source_language_1 ? data.source_language_1[0] : "N/A"
                  }
                />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Source / Native Language(3)
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              {data?.source_language_2 ? data.source_language_2 : "N/A"}
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              {data?.source_language_2 === "" ? null : (
                <SourceUpgradeAction2
                  level={data.source_language_level_2}
                  id={id}
                  verified={data.verified}
                  lang={
                    data?.source_language_2 ? data.source_language_2[0] : "N/A"
                  }
                />
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Target Language(1)
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              {data?.target_language ? data.target_language : "N/A"}
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <TargetUpgradeAction
                //state= {state}
                //setState= {setState}
                level={data.target_language_level}
                id={id}
                verified={data.verified}
                lang={data?.target_language ? data.target_language[0] : "N/A"}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Target Language(2)
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              {data?.target_language_1 ? data.target_language_1 : "N/A"}
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              {data?.target_language_1 === "" ? null : (
                <TargetUpgradeAction1
                  //state= {state}
                  //setState= {setState}
                  level={data.target_language_level_1}
                  id={id}
                  verified={data.verified}
                  lang={
                    data?.target_language_1 ? data.target_language_1[0] : "N/A"
                  }
                />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Target Language(3)
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              {data?.target_language_2 ? data.target_language_2 : "N/A"}
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              {data?.target_language_2 === "" ? null : (
                <TargetUpgradeAction2
                  //state= {state}
                  //setState= {setState}
                  level={data.target_language_level_2}
                  id={id}
                  verified={data.verified}
                  lang={
                    data?.target_language_2 ? data.target_language_2[0] : "N/A"
                  }
                />
              )}
            </Table.Cell>
          </Table.Row>

          {data.language_combinations
            ?.toString()
            .split(",")
            .map((lang, i) => (
              <Table.Row>
                <Table.Cell width="1" textAlign="center">
                  Other Language({i + 1})
                </Table.Cell>
                <Table.Cell width="1" textAlign="center">
                  {lang}
                </Table.Cell>
                <Table.Cell width="1" textAlign="center">
                  {lang ? (
                    <OtherUpgradeAction
                      // values= {values}
                      // setValues= {setValues}
                      level={data.other_language_level}
                      level1={data.other_language_level_1}
                      level2={data.other_language_level_2}
                      level3={data.other_language_level_3}
                      level4={data.other_language_level_4}
                      lang={lang ? lang : "N/A"}
                      id={data._id}
                      index={i}
                      load={load}
                    />
                  ) : null}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>

      <Table celled structured color={"pink"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="1" textAlign="center">
              Access
            </Table.HeaderCell>
            <Table.HeaderCell width="1" textAlign="center">
              Status
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Verified
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data.verified}
                onChange={() => handleToggle("verified")}
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              CAC and BAC Client assignment
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data.cacAndBacAccess}
                onChange={() => handleAssignment("cacAndBacAccess")}
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              In-house Client management
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data.inhouseAccess}
                onChange={() => handleInhouseAccess("inhouseAccess")}
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Private Client management
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data.privateAccess}
                onChange={() => handlePrivateAccess("privateAccess")}
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Cashbook and Price Calculator
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data.cashbookAndCalculatiorAccess}
                onChange={() =>
                  handleCashbookAccess("cashbookAndCalculatiorAccess")
                }
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Leads
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data.leadAccess}
                onChange={() => handleLeadAccess("leadAccess")}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table color={"pink"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="1" textAlign="center">
              Approve Public Profile Publication
            </Table.HeaderCell>
            <Table.HeaderCell width="1" textAlign="center">
              {data?.profile_update ? (
                <Checkbox
                  toggle
                  checked={data?.adminAllowPublicProfile}
                  onChange={() =>
                    handlePublicProfile("adminAllowPublicProfile")
                  }
                />
              ) : null}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>

      <Table color={"pink"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="1" textAlign="center">
              Set Position
            </Table.HeaderCell>
            <Table.HeaderCell width="1" textAlign="center">
              Title
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Linguist
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data.linguist}
                onChange={() => handleLinguist("linguist")}
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Professional
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data.professional}
                onChange={() => handleProfessional("professional")}
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Contractor
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data.contractor}
                onChange={() => handleContractor("contractor")}
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell width="1" textAlign="center">
              Trainer
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data.trainer}
                onChange={() => handleTrainer("trainer")}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default AllocateAccess;
