import React, { useState } from "react";
import { Button, Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomCaptcha from "components/Common/CustomCaptcha";
import TermsAndConditions from "components/Auth/EmployeeRegistration/TermsAndConditions";
import swal from "sweetalert";
import CustomAvatar from "components/Common/CustomAvatar";
import { useParams } from "react-router-dom";
import defaultAxios from "utils/axios";
import CustomPhone from "components/Common/CustomPhone";
import { EMAIL_REGEX } from "data";

const SubscribeHere = () => {
  const classes = useEmployeeStyles();
  const [captcha, setCaptcha] = useState();
  const [checked, setChecked] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const { id } = useParams();

  const [data, setData] = useState({
    title: "",
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
    organisation: "",
    address: "",
    comment: "",
  });

  const {
    title,
    first_name,
    last_name,
    email,
    mobile_no,
    organisation,
    address,
    comment,
  } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleEmailChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };
  const handleCheck = (value) => {
    setChecked(value);
  };

  const handleSubmit = async () => {
    const sendData = {
      first_name: data.first_name || "-",
      last_name: data.last_name || "-",
      email: data.email || "-",
      mobile_no: data.mobile_no || "-",
      title: data.title || "-",
      organisation: data.organisation || "-",
      address: data.address || "-",
      comment: data.comment || "-",
    };
    try {
      const response = await defaultAxios.post(
        `/api/event-member-registration/eventID/${id}`,
        sendData
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/events";
      });
      setData({
        ...data,
        title: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_no: "",
        organisation: "",
        address: "",
        comment: "",
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleMobile = (value) => {
    setData({ ...data, mobile_no: value });
  };

  return (
    <Card>
      <CardBody>
        <CustomInputSingle
          label="Title"
          width="100%"
          placeholder="( e.g. Mr. / Mrs / Miss etc. )"
          name="title"
          onChange={handleChange}
          value={title}
          required
        />
        <CustomInputSingle
          label="First Name(s)"
          width="100%"
          placeholder="First Name(s)"
          name="first_name"
          onChange={handleChange}
          value={first_name}
          required
        />
        <CustomInputSingle
          label="Last Name"
          width="100%"
          placeholder="Last Name"
          name="last_name"
          value={last_name}
          onChange={handleChange}
          required
        />
        <div style={{ marginTop: "24px" }}>
          <CustomPhone
            onChange={handleMobile}
            placeholder="Mobile number"
            value={mobile_no}
            name="mobile_no"
            required
          />
        </div>
        <CustomInputSingle
          label="Email"
          width="100%"
          placeholder="Email"
          name="email"
          onChange={handleEmailChange}
          value={email}
          required
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <br />
        <CustomInputSingle
          label="Organisation"
          width="100%"
          placeholder="Organisation"
          name="organisation"
          value={organisation}
          onChange={handleChange}
        />
        <CustomInputSingle
          label="Address"
          width="100%"
          placeholder="Address"
          name="address"
          value={address}
          onChange={handleChange}
        />
        <CustomInputSingle
          label="Comment"
          width="100%"
          placeholder="Comment"
          name="comment"
          value={comment}
          onChange={handleChange}
        />
        <TermsAndConditions
          handleCheck={handleCheck}
          text="I consent to having LC store my submitted information and contact me."
        />
        <CustomCaptcha onChange={handleCaptcha} />

        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <Button
            className={`${classes.button}`}
            color="primary"
            variant="contained"
            disabled={
              !checked ||
              !captcha ||
              !title ||
              !first_name ||
              !last_name ||
              !mobile_no ||
              !email ||
              notValid
            }
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default SubscribeHere;
