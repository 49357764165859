import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import LinguistLayout from "layout/LinguistLayout";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ViewLead from "components/Linguist/Leads/ViewCLA";
import history from "utils/history";
import { useParams } from 'react-router-dom'

const CashClient = () => {
  const { id } = useParams()
  const classes = useDashboardStyles();
  return (
    <Linguist>
      <LinguistLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>

                <p className={classes.cardTitleWhite}>View Lead Details</p>
                <i className="icon list arrow left"
                  onClick={() => {
                    history.goBack()
                  }}></i>
              </CardHeader>
              <CardBody>
                <ViewLead id={id} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </LinguistLayout>
    </Linguist>
  );
};

export default CashClient;
