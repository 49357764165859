import React from "react";
import { Table } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import EditableText from "./EditableText";
import moment from "moment";
const Items = ({ values, setReload, type }) => {
  const readOnly =
    moment(values?.startdateArray[0]) < moment() ||
    values?.job_status.includes("Closed");
  return (
    <GridContainer alignItems="center">
     <p>
        {values?.bank_pay
          ? "Paid by bank transfer"
          : values?.pay_later
          ? "Pay Later / UK Legal Aid Job"
          : values?.charge_id
          ? "Paid by stripe"
          : null}
      </p>
      <Table color={"pink"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Assignment Details</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Assignment Reference ID</Table.Cell>
            <Table.Cell>
              {values?.id == "undefined"
                ? "N/A"
                : values?.id
                ? values?.id
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Booking reference number or purchase order number
            </Table.Cell>
            <Table.Cell>
              {values?.purchase_order_number == "undefined" ? (
                "N/A"
              ) : values?.purchase_order_number ? (
                <EditableText
                  setReload={setReload}
                  text={values?.purchase_order_number}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"purchase_order_number"}
                  type={type}
                />
              ) : (
                <EditableText
                  setReload={setReload}
                  text={values?.purchase_order_number}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"purchase_order_number"}
                  type={type}
                />
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Interpreting Service</Table.Cell>
            <Table.Cell>
              {values?.interpreting_service_required == "undefined" ? (
                "N/A"
              ) : values?.interpreting_service_required ? (
                <EditableText
                  setReload={setReload}
                  text={values?.interpreting_service_required}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"interpreting_service_required"}
                  type={type}
                  service={true}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          {values?.interpreting_service_required ==
          "Remote Interpreting (RI) / Video Remote Interpreting (VRI)" ? (
            <Table.Row>
              <Table.Cell>Remote / Telephone Details</Table.Cell>
              <Table.Cell>
                {values?.remote_address == "undefined" ? (
                  "N/A"
                ) : values?.remote_address ? (
                  <EditableText
                    setReload={setReload}
                    text={values?.remote_address}
                    readOnly={readOnly ? true : false}
                    id={values?._id}
                    variant="outlined"
                    name={"remote_address"}
                    type={type}
                  />
                ) : (
                  <EditableText
                    setReload={setReload}
                    text={values?.remote_address}
                    readOnly={readOnly ? true : false}
                    id={values?._id}
                    variant="outlined"
                    name={"remote_address"}
                    type={type}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ) : (
            <>
              <Table.Row>
                <Table.Cell>Venue address: House no, Street name</Table.Cell>
                <Table.Cell>
                  {values?.house_number_street_name == "undefined" ? (
                    "N/A"
                  ) : values?.house_number_street_name ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.house_number_street_name}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"house_number_street_name"}
                      type={type}
                    />
                  ) : (
                    <EditableText
                      setReload={setReload}
                      text={values?.house_number_street_name}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"house_number_street_name"}
                      type={type}
                    />
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Address line 2</Table.Cell>
                <Table.Cell>
                  {values?.address_line_two == "undefined" ? (
                    "N/A"
                  ) : values?.address_line_two ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.address_line_two}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"address_line_two"}
                      type={type}
                    />
                  ) : (
                    <EditableText
                      setReload={setReload}
                      text={values?.address_line_two}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"address_line_two"}
                      type={type}
                    />
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Town / City</Table.Cell>
                <Table.Cell>
                  {values?.town == "undefined" ? (
                    "N/A"
                  ) : values?.town ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.town}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"town"}
                      type={type}
                    />
                  ) : (
                    <EditableText
                      setReload={setReload}
                      text={values?.town}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"town"}
                      type={type}
                    />
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>County / Region</Table.Cell>
                <Table.Cell>
                  {values?.region == "undefined" ? (
                    "N/A"
                  ) : values?.region ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.region}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"region"}
                      type={type}
                    />
                  ) : (
                    <EditableText
                      setReload={setReload}
                      text={values?.region}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"region"}
                      type={type}
                    />
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Post / Zip Code</Table.Cell>
                <Table.Cell>
                  {values?.post_code == "undefined" ? (
                    "N/A"
                  ) : values?.post_code ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.post_code}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"post_code"}
                      type={type}
                    />
                  ) : (
                    <EditableText
                      setReload={setReload}
                      text={values?.post_code}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"post_code"}
                      type={type}
                    />
                  )}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Country</Table.Cell>
                <Table.Cell>
                  {values?.country == "undefined" ? (
                    "N/A"
                  ) : values?.country ? (
                    <EditableText
                      setReload={setReload}
                      text={values?.country}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"country"}
                      type={type}
                      country={true}
                    />
                  ) : (
                    <EditableText
                      setReload={setReload}
                      text={values?.country}
                      readOnly={readOnly ? true : false}
                      id={values?._id}
                      variant="outlined"
                      name={"country"}
                      type={type}
                      country={true}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            </>
          )}

          <Table.Row>
            <Table.Cell>Source Language</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                setReload={setReload}
                text={values?.source_language}
                readOnly={readOnly ? true : false}
                id={values?._id}
                variant="outlined"
                name={"source_language"}
                type={type}
                lang={true}
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Source language skill level</Table.Cell>
            <Table.Cell>
              {values?.source_linguist_skill_level ? (
                <EditableText
                  setReload={setReload}
                  text={values?.source_linguist_skill_level}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"source_linguist_skill_level"}
                  exp={true}
                  type={type}
                />
              ) : values?.source_language_level ? (
                <EditableText
                  setReload={setReload}
                  text={values?.source_language_level}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"source_language_level"}
                  exp={true}
                  type={type}
                />
              ) : (
                <EditableText
                  setReload={setReload}
                  text={values?.source_expertise_level_required}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"source_expertise_level_required"}
                  exp={true}
                  type={type}
                />
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target Language</Table.Cell>
            <Table.Cell>
              {" "}
              <EditableText
                setReload={setReload}
                text={values?.target_language}
                readOnly={readOnly ? true : false}
                id={values?._id}
                variant="outlined"
                name={"target_language"}
                lang={true}
                type={type}
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target language skill level</Table.Cell>
            <Table.Cell>
              {values?.target_linguist_skill_level ? (
                <EditableText
                  setReload={setReload}
                  text={values?.target_linguist_skill_level}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"target_linguist_skill_level"}
                  exp={true}
                  type={type}
                />
              ) : values?.target_language_level ? (
                <EditableText
                  setReload={setReload}
                  text={values?.target_language_level}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"target_language_level"}
                  exp={true}
                  type={type}
                />
              ) : (
                <EditableText
                  setReload={setReload}
                  text={values?.target_expertise_level_required}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"target_expertise_level_required"}
                  exp={true}
                  type={type}
                />
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Types of Interpreting</Table.Cell>
            <Table.Cell>
              {values?.typesof_interpreting_required == "undefined" ? (
                "N/A"
              ) : values?.typesof_interpreting_required ? (
                <EditableText
                  setReload={setReload}
                  text={values?.typesof_interpreting_required}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"typesof_interpreting_required"}
                  interpretingType={true}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Job Description</Table.Cell>
            <Table.Cell>
              {values?.job_description == "undefined" ? (
                "N/A"
              ) : values?.job_description ? (
                <EditableText
                  setReload={setReload}
                  text={values?.job_description}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"job_description"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Preferred Linguist / Service Provider ID</Table.Cell>
            <Table.Cell>
              {values?.preferred_interpreter == "undefined" ? (
                "N/A"
              ) : values?.preferred_interpreter ? (
                <EditableText
                  setReload={setReload}
                  text={values?.preferred_interpreter}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"preferred_interpreter"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Preferred gender</Table.Cell>
            <Table.Cell>
              {values?.gender == "undefined" ? (
                "N/A"
              ) : values?.gender ? (
                <EditableText
                  setReload={setReload}
                  text={values?.gender}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"gender"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Security clearence level</Table.Cell>
            <Table.Cell>
              {values?.security_clearance == "undefined" ? (
                "N/A"
              ) : values?.security_clearance ? (
                <EditableText
                  setReload={setReload}
                  text={values?.security_clearance}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"security_clearance"}
                  security={true}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Car parking for linguist</Table.Cell>
            <Table.Cell>
              {values?.car_parking == "undefined" ? (
                "N/A"
              ) : values?.car_parking ? (
                <EditableText
                  setReload={setReload}
                  text={values?.car_parking}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"car_parking"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Additional Information or direction for linguist
            </Table.Cell>
            <Table.Cell>
              {values?.additional_info == "undefined" ? (
                "N/A"
              ) : values?.additional_info ? (
                <EditableText
                  setReload={setReload}
                  text={values?.additional_info}
                  readOnly={readOnly ? true : false}
                  id={values?._id}
                  variant="outlined"
                  name={"additional_info"}
                  type={type}
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Estimated value / estimate paid</Table.Cell>
            <Table.Cell>
              {values?.estimate_paid_amount
                ? values?.estimate_paid_amount
                : values?.estimate}{" "}
              {values?.currency_new
                ? values?.currency_new?.substring(0, 3)
                : values?.currency?.substring(0, 3)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </GridContainer>
  );
};

export default Items;
