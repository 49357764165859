import React, { useState, useEffect } from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import { Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CashbookPage from "components/CashBook/CashbookPage";
import CashBook from "components/Linguist/CashBook";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const AssignmentPage = () => {
  const classes = useDashboardStyles();

  const [cashbookAndCalculatiorAccess, setcashbookAndCalculatiorAccess] =
    useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("LINtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setcashbookAndCalculatiorAccess(response?.data.cashbookAndCalculatiorAccess);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          {cashbookAndCalculatiorAccess ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>Cashbook</p>
                  <IconButton color="inherit">
                    <ArrowBackIcon
                      style={{ marginLeft: 5 }}
                      onClick={() => history.goBack()}
                    ></ArrowBackIcon>
                  </IconButton>
                </CardHeader>
                <CardBody>
                  <CashBook />
                  <CashbookPage />
                </CardBody>
              </Card>
            </GridItem>
          ) : cashbookAndCalculatiorAccess === false ? (
            <Header as="h3">
              Access Denied! <br />
              Complete Full Registration or Contact HR / Admin Team.
            </Header>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default AssignmentPage;
