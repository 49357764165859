import React, {} from 'react'
import history from "utils/history";
import StripeLayout from 'layout/StripeLayout';
import CheckoutForm from 'layout/CheckoutFormLayout/CheckoutForm';
import Cookies from 'js-cookie'


const StripePayment = () => {
    const grandTotal = Cookies.get('grandTotal')
    const currency = Cookies.get('stripeCurrency')
    const id = Cookies.get('incClientID')

    return (
        <StripeLayout>
          <CheckoutForm
            price = {grandTotal}
            currency = {currency}
            onSuccessfulCheckout={(id) => history.push(`/inhouse-client/interpreter/success/estimate/payment/${id}`)}
            incClientID = {id}
          />
        </StripeLayout>
    )
}

export default StripePayment
