import React, { Fragment, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { orderBy } from "lodash";

// Custom Components
import CustomtableWIthStyle from "components/Common/CustomtableWIthStyle";
import PercentageInput from "./percentageinput";
import EditOption from "./EditOption";

// Others
import { getPercentRates } from "redux/actions/admin/controller";
import { NewPercentCardDiv } from "./style";

const NewPercentageCard = ({ readOnly }) => {
  const dispatch = useDispatch();
  const { percentData } = useSelector((state) => state.adminControlData);

  useEffect(() => {
    dispatch(getPercentRates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rateField = (value, item, name) => (
    <Fragment>
      <PercentageInput
        value={value || undefined}
        item={item}
        name={name}
        readOnly={readOnly}
      />
    </Fragment>
  );

  const editField = (item) => (
    <Fragment>
      <EditOption item={item} />
    </Fragment>
  );

  const headers = [
    {
      id: uuidv4(),
      text: "Label",
      align: "left",
    },
    {
      id: uuidv4(),
      text: "LC Platform fee %",
    },
    {
      id: uuidv4(),
      text: "Linguist %",
    },
   
    {
      id: uuidv4(),
      text: "Commission to Linguist %",
    },
    {
      id: uuidv4(),
      text: "Commission to SPPO %",
    },
    {
      id: uuidv4(),
      text: "",
    },
    {
      id: uuidv4(),
      text: "Total – Paid by Clients",
    },
  ];

  let rows = [
    {
      id: uuidv4(),
      column_1: "Booked by Cash Client",
      column_2: "015.00%",
      column_3: "085.00%",
      column_4: "N/A",
      column_5: "100.00%",
    },
    {
      id: uuidv4(),
      column_1: "Booked by Billing Account Client",
      column_2: "015.00%",
      column_3: "085.00%",
      column_4: "N/A",
      column_5: "100.00%",
    },
    {
      id: uuidv4(),
      column_1: "Inhouse Booking - Booked by Linguist / SPPO",
      column_2: "010.00%",
      column_3: "085.00%",
      column_4: "005.00%",
      column_5: "100.00%",
    },
    {
      id: uuidv4(),
      column_1:
        "Private booking - Booked by Linguist / Service Provider / SPPO",
      column_2: "005.00%",
      column_3: "095.00%",
      column_4: "N/A",
      column_5: "100.00%",
    },
  ];

  rows = percentData.map((item) => ({
    id: item._id,
    order: item.order,
    column_1: item.labelName,
    column_2: rateField(`${item.labelOne}%`, item, "labelOne"),
    column_3: rateField(`${item.labelTwo}%`, item, "labelTwo"),
    column_4: rateField(`${item.labelThree}%`, item, "labelThree"),
    column_5: rateField(`${item.labelFour}%`, item, "labelFour"),
    column_6: readOnly ? "" : editField(item),
    column_7: `${(item.labelOne + +item.labelTwo + +item.labelThree+ +item.labelFour).toFixed(
      2
    )}%`,
  }));

  rows = orderBy(rows);

  const rowsKeys = [
    "column_1",
    "column_2",
    "column_3",
    "column_4",
    "column_5",
    "column_6",
    "column_7",
  ];

  return (
    <NewPercentCardDiv>
      <CustomtableWIthStyle headers={headers} rows={rows} rowsKeys={rowsKeys} />
    </NewPercentCardDiv>
  );
};

export default NewPercentageCard;
