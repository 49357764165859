import React, { useState, useEffect } from "react";
import CommonCard from "components/Common/CommonCard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import OneCard from "./OneCard";
import Cookies from "js-cookie";
import Axios from "axios";
import { useMediaQuery } from "@material-ui/core";
//charts/PRC
//Active
import ActiveInterpretingJob from "./charts/PRC/activeInterpretingJob";
import ActiveITranslationJob from "./charts/PRC/activeTranslationJob";
import ActiveQuotationJob from "./charts/PRC/activeQuotationJob";
//Accepted
import AcceptedInterpretingJob from "./charts/PRC/acceptedInterpretingJob";
import AcceptedTranslationJob from "./charts/PRC/acceptedTranslationJob";
//Confirmed
import ConfirmedInterpretingJob from "./charts/PRC/confirmedInterpretingJob";
import ConfirmedITranslationJob from "./charts/PRC/confirmedTranslationJob";
import ConfirmedQuotationJob from "./charts/PRC/confirmedQuotationJob";
//cancelled
import CancelledInterpretingJob from "./charts/PRC/cancelledInterpretingJob";
import CancelledTranslationJob from "./charts/PRC/cancelledTranslationJob";
import CancelledQuotationJob from "./charts/PRC/cancelledQuotationJob";
//source lang
import SourcelangInterpreting from "./charts/PRC/SourcelangInterpreting";
import SourcelangTranslation from "./charts/PRC/SourcelangTranslation";
import SourcelangQuotation from "./charts/PRC/SourcelangQuotation";
//target lang
import TargetlangInterpreting from "./charts/PRC/TargetlangInterpreting";
import TargetlangTranslation from "./charts/PRC/TargetlangTranslation";
import TargetlangQuotation from "./charts/PRC/TargetlangQuotation";
//payments
import InterpretingPayments from "./charts/PRC/InterpretingPayments";
import TranslationPayments from "./charts/PRC/TranslationPayments";
import QuotationPayments from "./charts/PRC/QuotationPayments";
// Images
import JobIcon from "assets/icons/job-post.png";
import InvoiceIcon from "assets/icons/invoice.png";
import LanguageIcon from "assets/icons/lan.png";
import PaymentsIcon from "assets/icons/paym.png";
import { ReportCardsDiv } from "./style";

const ReportCards = () => {
  const [showAll, setShowAll] = useState(false);
  const [showAccepted, setShowAccepted] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);
  const [showSource, setShowSource] = useState(false);
  const [showTarget, setShowTarget] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [interpreting, setInterpreting] = useState(null);
  const [translation, setTranslation] = useState(null);
  const [acceptedInterpreting, setAcceptedInterpreting] = useState(null);
  const [acceptedTranslation, setAcceptedTranslation] = useState(null);
  const [cancelledInterpreting, setCancelledInterpreting] = useState(null);
  const [cancelledTranslation, setCancelledTranslation] = useState(null);
  const [confirmedInterpreting, setConfirmedInterpreting] = useState(null);
  const [confirmedTranslation, setConfirmedTranslation] = useState(null);
  const [confirmedQuotation, setConfirmedQuotation] = useState(null);
  const [quotation, setQuotation] = useState(null);
  const token = Cookies.get("token");
  const matches = useMediaQuery("(max-width:768px)");
  const handleShowAll = () => {
    setShowAll(!showAll);
    setShowAccepted(false);
    setShowConfirmed(false);
    setShowCancelled(false);
    setShowSource(false);
    setShowTarget(false);
    setShowPayment(false);
  };
  const handleShowAccepted = () => {
    setShowAccepted(!showAccepted);
    setShowAll(false);
    setShowConfirmed(false);
    setShowCancelled(false);
    setShowSource(false);
    setShowTarget(false);
    setShowPayment(false);
  };
  const handleShowConfirmed = () => {
    setShowAccepted(false);
    setShowAll(false);
    setShowConfirmed(!showConfirmed);
    setShowCancelled(false);
    setShowSource(false);
    setShowTarget(false);
    setShowPayment(false);
  };
  const handleShowCancelled = () => {
    setShowAccepted(false);
    setShowAll(false);
    setShowConfirmed(false);
    setShowCancelled(!showCancelled);
    setShowSource(false);
    setShowTarget(false);
    setShowPayment(false);
  };
  const handleShowSource = () => {
    setShowAccepted(false);
    setShowAll(false);
    setShowConfirmed(false);
    setShowCancelled(false);
    setShowSource(!showSource);
    setShowTarget(false);
    setShowPayment(false);
  };
  const handleShowTarget = () => {
    setShowAccepted(false);
    setShowAll(false);
    setShowConfirmed(false);
    setShowCancelled(false);
    setShowSource(false);
    setShowTarget(!showTarget);
    setShowPayment(false);
  };
  const handleShowPayment = () => {
    setShowAccepted(false);
    setShowAll(false);
    setShowConfirmed(false);
    setShowCancelled(false);
    setShowSource(false);
    setShowTarget(false);
    setShowPayment(!showPayment);
  };

  useEffect(() => {
    const getInterpretingDetails = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-total-interpretings/PRC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setInterpreting(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getAcceptedInterpretingDetails = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-total-Accepted-interpreting/PRC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAcceptedInterpreting(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getConfirmedInterpretingDetails = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-total-confirm-interpreting/PRC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setConfirmedInterpreting(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getCancelledInterpretingDetails = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-total-confirm-interpreting/PRC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setCancelledInterpreting(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getTranslationDetails = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-total-translation/PRC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setTranslation(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getAcceptedTranslationDetails = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-total-Accepted-translation/PRC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAcceptedTranslation(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getConfirmedTranslationDetails = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-total-confirm-translation/PRC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setConfirmedTranslation(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getCancelledTranslationDetails = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-total-Cancelled-translation/PRC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setCancelledTranslation(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getQuotationDetails = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-total-quotation/PRC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setQuotation(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getConfirmedQuotationDetails = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-total-confirm-quotation/PRC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setConfirmedQuotation(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    getInterpretingDetails();
    getTranslationDetails();
    getQuotationDetails();
    getConfirmedInterpretingDetails();
    getConfirmedTranslationDetails();
    getConfirmedQuotationDetails();
    getAcceptedInterpretingDetails();
    getAcceptedTranslationDetails();
    getCancelledInterpretingDetails();
    getCancelledTranslationDetails();
  }, []);

  return (
    <ReportCardsDiv>
      <GridContainer>
        {" "}
        {/* all jobs */}
        <GridItem
          style={{ transition: "all 0.4s ease-in-out" }}
          xs={12}
          sm={12}
          md={showAll ? 12 : 6}
          lg={showAll ? 12 : 6}
        >
          <CommonCard
            style={{
              PRCkground: `linear-gradient(60deg, #ab47bc, #8e24aa)`,
              cursor: "pointer",
            }}
            onClick={handleShowAll}
          >
            <OneCard
              icon={JobIcon}
              value={interpreting + translation + quotation}
              text="Total Jobs"
            />
          </CommonCard>
        </GridItem>
        {showAll && (
          <>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <CommonCard
                style={{
                  PRCkground: `linear-gradient(60deg, #ab47bc, #8e24aa)`,
                }}
                // onClick={handleInvoiceShow}
              >
                <OneCard
                  icon={JobIcon}
                  value={interpreting ? interpreting : 0}
                  text="Interpreting Jobs"
                />
              </CommonCard>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <CommonCard
                style={{
                  PRCkground: `linear-gradient(60deg, #ab47bc, #8e24aa)`,
                }}
                // onClick={handleJobShow}
              >
                <OneCard
                  icon={JobIcon}
                  value={translation ? translation : 0}
                  text="Translation Jobs"
                />
              </CommonCard>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <CommonCard
                style={{
                  PRCkground: `linear-gradient(60deg, #ab47bc, #8e24aa)`,
                }}
              >
                <OneCard
                  icon={JobIcon}
                  value={quotation ? quotation : 0}
                  text="Quotation Jobs"
                />
              </CommonCard>
            </GridItem>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: matches ? "auto" : "auto auto auto",
                width: "100%",
              }}
            >
              <ActiveInterpretingJob />
              <ActiveITranslationJob />
              <ActiveQuotationJob />
            </div>
          </>
        )}
        {/* accepted jobs */}
        <GridItem
          style={{ transition: "all 0.4s ease-in-out" }}
          xs={12}
          sm={12}
          md={showAccepted ? 12 : 6}
          lg={showAccepted ? 12 : 6}
        >
          <CommonCard
            style={{
              PRCkground: `linear-gradient(60deg, #ab47bc, #8e24aa)`,
              cursor: "pointer",
            }}
            onClick={handleShowAccepted}
          >
            <OneCard
              icon={JobIcon}
              value={acceptedInterpreting + acceptedTranslation}
              text="Accepted Jobs"
            />
          </CommonCard>
        </GridItem>
        {showAccepted && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: matches ? "auto" : "auto auto",
              width: "100%",
            }}
          >
            <AcceptedInterpretingJob />
            <AcceptedTranslationJob />
          </div>
        )}
        {/* confirmed jobs */}
        <GridItem
          style={{ transition: "all 0.4s ease-in-out" }}
          xs={12}
          sm={12}
          md={showConfirmed ? 12 : 6}
          lg={showConfirmed ? 12 : 6}
        >
          <CommonCard
            style={{
              PRCkground: `linear-gradient(60deg, #ab47bc, #8e24aa)`,
              cursor: "pointer",
            }}
            onClick={handleShowConfirmed}
          >
            <OneCard
              icon={JobIcon}
              value={
                confirmedInterpreting +
                confirmedTranslation +
                confirmedQuotation
              }
              text="Confirmed Jobs"
            />
          </CommonCard>
        </GridItem>
        {showConfirmed && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: matches ? "auto" : "auto auto auto",
              width: "100%",
            }}
          >
            <ConfirmedInterpretingJob />
            <ConfirmedITranslationJob />
            <ConfirmedQuotationJob />
          </div>
        )}
        {/* cancelled */}
        <GridItem
          style={{ transition: "all 0.4s ease-in-out" }}
          xs={12}
          sm={12}
          md={showCancelled ? 12 : 6}
          lg={showCancelled ? 12 : 6}
        >
          <CommonCard
            style={{
              PRCkground: `linear-gradient(60deg, #ab47bc, #8e24aa)`,
              cursor: "pointer",
            }}
            onClick={handleShowCancelled}
          >
            <OneCard
              icon={JobIcon}
              value={cancelledInterpreting + cancelledTranslation}
              text="Cancelled Jobs"
            />
          </CommonCard>
        </GridItem>
        {showCancelled && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: matches ? "auto" : "auto auto auto",
              width: "100%",
            }}
          >
            <CancelledInterpretingJob />
            <CancelledTranslationJob />
            <CancelledQuotationJob />
          </div>
        )}
        {/* source */}
        <GridItem
          style={{ transition: "all 0.4s ease-in-out" }}
          xs={12}
          sm={12}
          md={showSource ? 12 : 6}
          lg={showSource ? 12 : 6}
        >
          <CommonCard
            style={{
              PRCkground: `linear-gradient(60deg, #ab47bc, #8e24aa)`,
              cursor: "pointer",
            }}
            onClick={handleShowSource}
          >
            <OneCard icon={LanguageIcon} text="Source languages" />
          </CommonCard>
        </GridItem>
        {showSource && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: matches ? "auto" : "auto auto auto",
              width: "100%",
            }}
          >
            <SourcelangInterpreting />
            <SourcelangTranslation />
            <SourcelangQuotation />
          </div>
        )}
        {/* target */}
        <GridItem
          style={{ transition: "all 0.4s ease-in-out" }}
          xs={12}
          sm={12}
          md={showTarget ? 12 : 6}
          lg={showTarget ? 12 : 6}
        >
          <CommonCard
            style={{
              PRCkground: `linear-gradient(60deg, #ab47bc, #8e24aa)`,
              cursor: "pointer",
            }}
            onClick={handleShowTarget}
          >
            <OneCard icon={LanguageIcon} text="Target languages" />
          </CommonCard>
        </GridItem>
        {showTarget && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: matches ? "auto" : "auto auto auto",
              width: "100%",
            }}
          >
            <TargetlangInterpreting />
            <TargetlangTranslation />
            <TargetlangQuotation />
          </div>
        )}
        {/* payment */}
        <GridItem
          style={{ transition: "all 0.4s ease-in-out" }}
          xs={12}
          sm={12}
          md={showPayment ? 12 : 6}
          lg={showPayment ? 12 : 6}
        >
          <CommonCard
            style={{
              PRCkground: `linear-gradient(60deg, #ab47bc, #8e24aa)`,
              cursor: "pointer",
            }}
            onClick={handleShowPayment}
          >
            <OneCard icon={PaymentsIcon} text="Payment status" />
          </CommonCard>
        </GridItem>
        {showPayment && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: matches ? "auto" : "auto auto auto",
              width: "100%",
            }}
          >
            <InterpretingPayments />
            <TranslationPayments />
            <QuotationPayments />
          </div>
        )}
      </GridContainer>
    </ReportCardsDiv>
  );
};

export default ReportCards;
