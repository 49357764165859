const { CHANGE_PREREG_INPUT } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    data: {
        title: '',
        first_name: '',
        last_name: '',
        source_language: '',
        target_language: '',
        primary_email: '',
        other_email: '',
        mobile_no: '',
        phone_no: '',
        addressline1: '',
        addressline2: '',
        city: '',
        postal_code: '',
        region: '',
        country: '',
        security_clearence: '',
        other: '',
        upload_cv: '',
        gdpr: false,
    }
};


const preregReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case CHANGE_PREREG_INPUT:
            return {
                ...state,
                data: {
                  ...state.data,
                  [payload.name]: payload.value
                }
            }
        
        default:
            return state
    }
}

export default preregReducer
