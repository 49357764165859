import LCJoiningPage from "pages/LCJoining";
const LC_JOINING = [
  {
    id: 140,
    type: "form",
    text: "LC Joining",
    subText: "LC Joining",
    path: "lcjoining-form",
    component: LCJoiningPage,
  },
];

export default LC_JOINING;
