import React, { Fragment } from "react";

import TableBody from "./TableBody";

const InputItems = ({ state }) => {
  return (
    <Fragment>
      <TableBody state={state} />
    </Fragment>
  );
};

export default InputItems;
