import CustomTextInput from "components/Common/CustomTextInput";
import React, { useRef, useState, useEffect } from "react";
import { Button, Dimmer, Loader, Rating, Table } from "semantic-ui-react";
import { Header, Checkbox } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import { Modal } from "semantic-ui-react";
import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import RichTextEditor from "react-rte";
import { useParams } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import DisputeForm from "./DisputeForm"
import history from "utils/history";

const Details = () => {
  let sigPad = useRef({});
  const [signature, setSignature] = useState(false);
  const [projectReport, setProjectReport] = useState(null);
  const { id } = useParams();
  const [declaration, setDeclaration] = useState(false);

  const [linSignature, setLinSignature] = useState({
    linSignature: "",
  });

  const [noteOpen, setNoteOpen] = useState(false);

  const handleDispute = () => {
    setNoteOpen(true);
  };

  const [details, setDetails] = useState({
    project_name: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    request_details: "",
    target_language_level: "",
    security_clearence: "",
    word_count: "",
    visual_formatting: "",
    certification: "",
    post_certification: "",
    project_report: RichTextEditor.createEmptyValue(),
    signature: "",
    supervisor_fullname: "",
    date: "",
    feedbackByLin: "",
    remarksByLin: "",
    loader: false,
    feedbackByClient: "",
  });
  const uploadRef = useRef();
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const todayDate = moment().format("DD/MM/YYYY");

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getProjectReport = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/inhouse/view-translation-Report/translationID/${id}`
        );

        setProjectReport(response?.data[0]);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancel");
        } else {
          console.log(error.response);
        }
      }
    };

    const getLINSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-LIN-signature/reportID/${projectReport._id}`
        );
        setLinSignature({ ...linSignature, linSignature: response?.data });
      } catch (error) {
        console.log(error);
      }
    };

    getProjectReport();
    getLINSignature();

    return () => {
      source.cancel();
    };
  }, []);

  const handleClose = (e) => {
    e.preventDefault();
    setSignature(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = () => {
    const temp = sigPad.current.toDataURL();
    setDetails({ ...details, signature: temp });
    setSignature(false);
  };


  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });
    const fd = new FormData();

    const file = new File([details.signature], "signature", {
      type: "image/png",
    });

    fd.append("signatureByClient", file);
    fd.append("supervisor_fullname", "");
    fd.append("feedbackByClient", details.feedbackByClient);

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse/accept-translation-Report/ReportID/${projectReport._id}`,
        fd
      );

      setDetails({ ...details, loader: false });
      swal({
        icon: "success",
        text: "Project Report Confirmed",
        buttons: "Ok",
      }).then(() => {
        window.location = "/";
      });
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  const handleRate = (e, { rating, maxRating }) => {
    setDetails({ ...details, feedbackByClient: rating });
  };

  return projectReport ? (
    <div>
      {projectReport?.signatureByClientTrue ? "Already confirmed project report." :
        <>
          <CustomModal
            open={signature}
            handleClose={handleClose}
            style={{ height: "50%", width: "50%" }}
          >
            <SignatureCanvas
              penColor="black"
              // onEnd = {handleSave}
              ref={sigPad}
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={handleClear}
                style={{ marginRight: "5px" }}
                basic
                color="purple"
              >
                Clear
              </Button>
              <Button onClick={handleSave} variant="contained" color="purple">
                Save
              </Button>
            </div>
          </CustomModal>
          <CustomTextInput
            label="Assignment reference number"
            value={projectReport?.incTranslationID?.id}
          />
          {/* <CustomTextInput label= 'Translation / Training /Other Project name' value= 'abc' /> */}

          {/* Table */}
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell textAlign="center">Actual</Table.HeaderCell>
                <Table.HeaderCell>Requested</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {/* <Table.Row>
                    <Table.Cell>Project / assignment confirmation date: </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                </Table.Row> */}
              <Table.Row>
                <Table.Cell>Project start date: </Table.Cell>
                <Table.Cell>{projectReport?.start_date}</Table.Cell>
                <Table.Cell>{projectReport?.start_date}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Project completion date: </Table.Cell>
                <Table.Cell>{projectReport?.end_date}</Table.Cell>
                <Table.Cell> {projectReport?.end_date}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Project start time: </Table.Cell>
                <Table.Cell>{projectReport?.start_time}</Table.Cell>
                <Table.Cell>{projectReport?.start_time}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Project end time: </Table.Cell>
                <Table.Cell>{projectReport?.end_time}</Table.Cell>
                <Table.Cell>{projectReport?.end_time}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Target language expertise level</Table.Cell>
                <Table.Cell>
                  {projectReport?.incTranslationID?.target_language_skill_level}
                </Table.Cell>
                <Table.Cell>
                  {projectReport?.postedByLin?.target_language_level}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Security clearance/s</Table.Cell>
                <Table.Cell>
                  {projectReport?.security_clearance
                    ? projectReport?.security_clearance
                    : "N/A"}
                </Table.Cell>
                <Table.Cell>
                  {projectReport?.security_clearance
                    ? projectReport?.security_clearance
                    : "N/A"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Wordcount</Table.Cell>
                <Table.Cell>{projectReport?.incTranslationID?.count}</Table.Cell>
                <Table.Cell>{projectReport?.incTranslationID?.count}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Visual formatting</Table.Cell>
                <Table.Cell>
                  {projectReport?.incTranslationID?.require_visual_formating}
                </Table.Cell>
                <Table.Cell>
                  {projectReport?.incTranslationID?.require_visual_formating}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Certification</Table.Cell>
                <Table.Cell>
                  {projectReport?.incTranslationID?.certificate}
                </Table.Cell>
                <Table.Cell>
                  {projectReport?.incTranslationID?.certificate}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Post certification </Table.Cell>
                <Table.Cell>
                  {projectReport?.incTranslationID?.certified_translation}
                </Table.Cell>
                <Table.Cell>
                  {projectReport?.incTranslationID?.certified_translation}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header as="h4">Project Report</Header>
          <Header as="h5">{projectReport?.project_report}</Header>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  To be completed by LC Linguist / Professional(project lead)
                </Table.HeaderCell>
                <Table.HeaderCell>To be completed by client</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Checkbox
                    checked={true}
                    label="I declare the information given by me is true/accurate and complete."
                  />
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={declaration}
                    onChange={(e, value) => setDeclaration(value.checked)}
                    label="I declare that I have checked the information on this form and certify that it is true and complete"
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <div>Signature</div>
                  <img
                    style={{ width: "130px" }}
                    src={linSignature.linSignature}
                    alt=""
                  />
                </Table.Cell>
                <Table.Cell>
                  Signature{" "}
                  <IconButton onClick={() => setSignature(true)}>
                    <EditIcon />
                  </IconButton>
                  {details.signature && (
                    <img
                      style={{ width: "100px", height: "50px" }}
                      src={details.signature}
                      alt=""
                    />
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Full name:{" "}
                  {projectReport?.postedByLin?.FirstName +
                    " " +
                    projectReport?.postedByLin?.LastName}
                </Table.Cell>
                <Table.Cell>
                  Full name:{" "}
                  {projectReport?.incTranslationID?.client_ID?.FirstName +
                    " " +
                    projectReport?.incTranslationID?.client_ID?.LastName}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Date: {todayDate}</Table.Cell>
                <Table.Cell>Date: {todayDate}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Feedback:
                  <Rating
                    maxRating={5}
                    rating={projectReport?.feedbackByLin}
                    icon="star"
                    size="massive"
                  />
                </Table.Cell>
                <Table.Cell>
                  Feedback:
                  <Rating
                    onRate={handleRate}
                    maxRating={5}
                    icon="star"
                    size="massive"
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <input
            onChange={(e) => setUploadTimesheet(e.target.files[0])}
            ref={uploadRef}
            type="file"
            style={{ display: "none" }}
          />
          <Header as="h4">
            Upload timesheet or proof of service delivery, max1MB
          </Header>
          <Button
            onClick={() => uploadRef.current.click()}
            basic
            style={{ backgroundColor: "purple", color: "white" }}
          >
            Upload
          </Button>
          <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
            {uploadTimesheet?.name}
          </Header>
          <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
            {uploadTimesheet?.size}
          </Header>

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
          >
            <Button
              onClick={() => history.push(`/view-project-report-document-bylinguist/${projectReport?._id}`)}
              style={{ backgroundColor: "purple", color: "white" }}
            >
              View
            </Button>

            <Button
              loading={details.loader}
              onClick={handleSubmit}
              disabled={!declaration || !details.signature || !details.feedbackByClient}
              color="purple"
            >
              Confirm
            </Button>
            {projectReport?.dispute ?
              <Button
                color="purple"
                disabled
              >
                Disputed
              </Button>
              :
              <Modal
                open={noteOpen}
                onClose={() => setNoteOpen(false)}
                onOpen={() => setNoteOpen(true)}
                trigger={
                  <Button
                    color="purple"
                    onClick={handleDispute}
                  >
                    Dispute
                  </Button>
                }
              >
                <Modal.Content>
                  <Modal.Description>
                    <DisputeForm transID={id} reportID={projectReport._id} />
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            }
          </div>
        </>
      }
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="large">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
