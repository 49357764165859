import React, { useEffect, useState } from "react";
import "./SppoProfile.css";

// Custom Imports
import Header from "./Header/Header";
import CoMemberShip from "./CoMemberShip/CoMemberShip";
import Certifications from "./Certifications/Certifications";
import About from "./About/About";
import SaveAndUpdate from "./SaveAndUpdate";
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import Axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { updatePPLinguist } from "redux/actions/updatePPLAction";
import CustomSnackBar from "components/Common/CustomSnackBar";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";


function LinguistProfile() {
  const token = Cookies.get("SPPOtoken");
  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useDispatch();
  const [approval, setApproval] = useState(false);
  const [data, setData] = useState(false);
  const [checkData, setProfessional] = useState(false);
  const classes = useEmployeeStyles();


  const geSppoDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(updatePPLinguist(response?.data));
      setData(true);
      setProfessional(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    geSppoDetails();
  }, []);

  return data ? (
    <GridContainer justify="center" className={classes.container}>
      <GridItem xs={12} sm={12} md={8} lg={8}>
        <div>
          <CustomSnackBar
            message="Public profile sent for approval"
            handleClose={() => setApproval(false)}
            openSnackBar={approval}
          />
          <div
            style={{
              background: "rgb(255,240,245)",
              padding: "20px",
              boxShadow:
                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            }}
          >
            <Header />
          </div>
          <div
            style={{
              background: "rgb(255,240,245)",
              padding: "20px",
              boxShadow:
                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            }}
          >
            <About checkData={checkData} />
          </div>
          <div
            style={{
              background: "rgb(255,240,245)",
              padding: "20px",
              boxShadow:
                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            }}
          >
            <CoMemberShip checkData={checkData} />
          </div>

          <div
            style={{
              background: "rgb(255,240,245)",
              padding: "20px",
              boxShadow:
                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            }}
          >
            <Certifications checkData={checkData} />
          </div>
          <div
            style={{
              background: "rgb(255,240,245)",
              padding: "20px",
              boxShadow:
                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            }}
          >
            <SaveAndUpdate />
          </div>
        </div>
      </GridItem>
    </GridContainer>
  ) : (
    <p>Loading...</p>
  );
}

export default LinguistProfile;
