
import React, { useState } from 'react'
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from 'react-redux';
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
// import Notes from './NoteList'
// import Message from './MessageList'
// import Invoice from './InvoiceList'

const PersonalDetails = ({id}) => {
    const classes = useDashboardStyles();
    return (
     
          <GridItem xs={12} sm={12} md={12} lg={12}>
         
              <Card>

              {/* <CardBody>
                <Notes id = {id}/>
              </CardBody>

              <CardBody>
                <Message id = {id}/>
              </CardBody>

              <CardBody>
                <Invoice id = {id}/>
              </CardBody> */}
              
            </Card>
            
            
          </GridItem>
      
    )
}

export default PersonalDetails
