// Custom Components
import CommonCard from "components/Common/CommonCard";
import Tutorials from "assets/images/training-cpd.png";
const TrainingsAndCpd = () => {
  return (
    <div style={{ margin: "5px" }}>
      <CommonCard title="Trainings & CPDs">
        <a href="/linguist/help">
          <img style={{ width: "100%" }} src={Tutorials} alt="" />
        </a>
      </CommonCard>
    </div>
  );
};

export default TrainingsAndCpd;
