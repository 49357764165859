import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import Action from "components/Admin/BookingPanel/UnconfirmedAssignments/INC/ListINCAssignments/Actions/Action";
import { Button, Header, Icon, Loader, Modal, Table } from "semantic-ui-react";
import CustomTextInput from "components/Common/CustomTextInput";
import Axios from "axios";
import Cookies from "js-cookie";
import CustomModal from "components/Common/CustomModal";
import swal from "sweetalert";
import { useToasts } from "react-toast-notifications";
import SearchINCAssignments from "components/Admin/BookingPanel/UnconfirmedAssignments/INC/ListINCAssignments/Search/SearchINCAssignments";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function ListCACAssignments({ interpretingData, text }) {
  const token = Cookies.get("token");
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const { addToast } = useToasts();
  const [show, setShow] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paidModal, setPaidModal] = React.useState(false);
  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  let sigPad = useRef({});
  const AdminID = Cookies.get("AdminID");
  const [pay, setPay] = useState({
    payment_date: "",
    payment_ref: "",
    estimate_paid_amount: "",
    note: "",
    signature: "",
    booking_id: "",
    assignmentId: "",
    estimate: "",
    currency: "",
  });

  const handleClose = (e) => {
    setSignature(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    UnconfirmBookingList: null,
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });

  const {
    name,
    error,
    success,
    UnconfirmBookingList,
    removed,
    reload,
    loader,
  } = values;

  useEffect(() => {
    interpretingData && loadUnconfirmBookingList();
  }, [reload]);

  const loadUnconfirmBookingList = async (e) => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/inhouse-unconfirmed-booking-list`
      ),
    ]).then(
      Axios.spread((dashboard, unconfirmedJobs) => {
        setValues({
          ...values,
          UnconfirmBookingList: interpretingData
            ? unconfirmedJobs.data.filter(
                (item) => item.id === interpretingData
              )
            : unconfirmedJobs.data,
          AdminID: dashboard.data.id,
        });
        interpretingData && setShow(true);
      })
    );
  };

  const searchFunc = (item) => {
    if (!text) {
      return true;
    }

    const searchText = text.toLowerCase();
    const isMatched =
      item.id.toLowerCase().includes(searchText) ||
      item.source_language.toLowerCase().includes(searchText) ||
      item.target_language.toLowerCase().includes(searchText) ||
      item.interpreting_service_required.toLowerCase().includes(searchText) ||
      item.remote_address.toLowerCase().includes(searchText) ||
      item.linguist_skill_level.toLowerCase().includes(searchText) ||
      item.gender.toLowerCase().includes(searchText) ||
      item.car_parking.toLowerCase().includes(searchText) ||
      item.security_clearance.toLowerCase().includes(searchText);

    return isMatched;
  };

  const handlePaid = async (id, estimate, assignmentId, currency) => {
    setPaidModal(true);
    setPay({
      ...pay,
      booking_id: id,
      estimate: estimate,
      assignmentId: assignmentId,
      currency: currency,
    });
  };

  const handleConfirm = async (e) => {
    setValues({ ...values, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/update-payment-status-paid-inhouse/bookingID/${pay.booking_id}`,
        {
          payment_date: pay.payment_date,
          payment_ref: pay.payment_ref,
          estimate_paid_amount: pay.estimate_paid_amount,
          note: pay.note,
          signature: sign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, loader: false });
      swal({
        icon: "success",
        text: "Estimated total Paid",
        buttons: false,
      });
      setPaidModal(false);
      setPay({
        ...pay,
        estimate_paid_amount: "",
        payment_ref: "",
        payment_date: "",
      });
      setSign(null);
      loadUnconfirmBookingList();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  const handleClosePaid = (e) => {
    setPaidModal(false);
    setPay({
      ...pay,
      estimate_paid_amount: "",
      payment_ref: "",
      payment_date: "",
    });
    setSign(null);
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-unconfirm-booking-INC/bookingID/${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data) {
        setValues({ ...values, UnconfirmBookingList: [response?.data] });
        addToast("Assignment found", { appearance: "success" });
        setShow(true);
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <>
      {/* Payment modal */}
      <Modal
        open={paidModal}
        onClose={handleClosePaid}
        style={{ overflowY: "scroll", marginTop: 70 }}
      >
        <Modal.Header>
          Payment Details - {pay.assignmentId}, {pay.currency}, {pay.estimate}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomTextInput
              name="payment_date"
              onChange={handleChange}
              dvalue={pay.payment_date}
              label="Payment date"
              dlabel="Payment date "
              date={true}
            />
            <CustomTextInput
              name="payment_ref"
              onChange={handleChange}
              value={pay.payment_ref}
              label="Payment reference "
              edit={true}
            />
            <CustomTextInput
              number={true}
              name="estimate_paid_amount"
              onChange={handleChange}
              value={pay.estimate_paid_amount}
              label="Total estimate paid"
              placeholder={pay.estimate}
              edit={true}
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
            <CustomTextInput label="Admin ID" value={values.AdminID} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              !pay.payment_ref ||
              !sign ||
              !pay.payment_date ||
              !pay.estimate_paid_amount ||
              values.loader
            }
            onClick={handleConfirm}
            color="purple"
          >
            Confirm <Icon name="chevron right" />
          </Button>
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>
      {!interpretingData && (
        <SearchINCAssignments
          show={show}
          setShow={setShow}
          setSearch={setSearch}
          search={search}
          handleSearch={handleSearch}
          load={loadUnconfirmBookingList}
        />
      )}

      <Paper className={classes.root}>
        {show && (
          <>
            {" "}
            <TableContainer className={classes.container}>
              <Table color="pink" stickyHeader aria-label="sticky table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                    <Table.HeaderCell>Client ID</Table.HeaderCell>
                    <Table.HeaderCell>Ref Linguist</Table.HeaderCell>
                    <Table.HeaderCell>Timezone</Table.HeaderCell>
                    <Table.HeaderCell>Start Date & Time</Table.HeaderCell>
                    <Table.HeaderCell>
                      Start Date & Time in London Time
                    </Table.HeaderCell>
                    <Table.HeaderCell>Duration</Table.HeaderCell>
                    <Table.HeaderCell>Source language</Table.HeaderCell>
                    <Table.HeaderCell>Target language</Table.HeaderCell>
                    <Table.HeaderCell>
                      Target language skill level
                    </Table.HeaderCell>
                    <Table.HeaderCell>Total estimate</Table.HeaderCell>
                    <Table.HeaderCell>Payment status</Table.HeaderCell>
                    <Table.HeaderCell>Job status</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {UnconfirmBookingList ? (
                  <Table.Body>
                    {UnconfirmBookingList.filter(searchFunc)
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list, i) => (
                        <Table.Row
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={i}
                          style={
                            moment(list?.startdateArray[0]) == moment()
                              ? { backgroundColor: "yellow" }
                              : moment(list?.startdateArray[0]) < moment()
                              ? { backgroundColor: "red", color: "#ffffff" }
                              : null
                          }
                        >
                          <Table.Cell>
                            <Link
                              to={`/admin/view-inhouse-client-booking-interpreting/${list._id}`}
                            >
                              {list.id}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>
                            {list.ClientID?.id ? list.ClientID?.id : "N/A"}
                          </Table.Cell>
                          <Table.Cell>
                            {list.LinguistID?.id ? list.LinguistID?.id : "N/A"}
                          </Table.Cell>
                          <Table.Cell>{list.timezone}</Table.Cell>
                          <Table.Cell>
                            {moment(list.startdateArray[0]).format(
                              "DD-MMM-YYYY"
                            )}{" "}
                            - {list?.starttimeArray[0]}
                          </Table.Cell>
                          <Table.Cell>
                            {moment(list.startdateArray[0])
                              .tz("Europe/London")
                              .format("DD-MMM-YYYY")}{" "}
                            - {list?.starttimeArray[0]}
                          </Table.Cell>
                          <Table.Cell>{list.total_Duration}</Table.Cell>
                          <Table.Cell>{list.source_language}</Table.Cell>
                          <Table.Cell>{list.target_language}</Table.Cell>
                          <Table.Cell>{list.target_language_level}</Table.Cell>
                          <Table.Cell>
                            {list?.currency?.substring(0, 3)} {list.estimate}
                          </Table.Cell>
                          <Table.Cell>{list.status}</Table.Cell>
                          <Table.Cell>{list.job_status}</Table.Cell>
                          <Table.Cell>
                            <Action
                              handlePaid={() =>
                                handlePaid(
                                  list._id,
                                  list.estimate,
                                  list.id,
                                  list.currency
                                )
                              }
                              id={list._id}
                              load={loadUnconfirmBookingList}
                              AdminID={values.AdminID}
                              ID={list?.id}
                              clientID={
                                list?.ClientID?.id ? list?.ClientID?.id : "N/A"
                              }
                              name={
                                list?.ClientID?.FirstName +
                                " " +
                                list?.ClientID?.LastName
                              }
                              currency={list?.currency?.substring(0, 3)}
                              estimate={list?.estimate}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                ) : (
                  "no data found"
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={UnconfirmBookingList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </>
  );
}
