import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie'
import Axios from "utils/axios";

import MasterLayout from "layout/MasterLayout";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import LinguistsCollectiveTel from "components/Auth/OTT/LinguistsCollective";

const OTTPage = () => {

  const [data, setData] = useState(null)

  const getAdminDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setData(response?.data);
  };
  useEffect(() => {
    getAdminDetails();
  }, []);


  return (

    <MasterLayout>
      <Manager>
        <LinguistsCollectiveTel adminID={data?.id} adminName={data?.FirstName} />
      </Manager>
    </MasterLayout>

  );
};

export default OTTPage;
