import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const Options = ({ currentPage }) => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <div className={classes.root}>
      <Button variant="outlined">
        <Link to={`/admin/quotation/bac-requestedQuote`}>
          <b style={{ color: "white" }}>Requested Quote</b>
        </Link>
      </Button>
      <Button variant="outlined">
        <Link to={`/admin/quotation/bac-respondedQuotedProvided`}>
          <b style={{ color: "white" }}>Responded/Quote provided</b>
        </Link>
      </Button>

      <Button variant="outlined">
        <Link to={`/admin/quotation/bac-byClientQuote`}>
          <b style={{ color: "white" }}>Quote confirmed by client</b>
        </Link>
      </Button>


      <Button variant="outlined">
        <Link to={`/admin/quotation/bac-closed-jobs`}>
          <b style={{ color: "white" }}>Closed jobs</b>
        </Link>
      </Button>
      <Button variant="outlined">
        <Link to={`/admin/quotation/bac-invoiced-jobs`}>
          <b style={{ color: "white" }}>Invoiced jobs</b>
        </Link>
      </Button>
      <Button variant="outlined">
        <Link to={`/admin/quotation/bac-completed-jobs`}>
          <b style={{ color: "white" }}>Completed jobs (fully paid)</b>
        </Link>
      </Button>
      <Button variant="outlined">
        <Link to={`/admin/quotation/bac-canceled-jobs`}>
          <b style={{ color: "white" }}>Cancelled jobs</b>
        </Link>
      </Button>
     
    </div>
  );
};

export default Options;
