import React from "react";

// Material Components
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomDateTime from "components/Common/CustomDateTime";

// Custom Styles
import useMeetingsStyles from "./style";

const MeetingForm = () => {
  const classes = useMeetingsStyles();

  return (
    <div className={classes.root}>
      <p className={classes.heading}>
        <Icon>meeting_room</Icon> Meeting Created By{" "}
        <span className="span">John Doe</span>
      </p>
      {/* Form */}
      <form method="POST" className={classes.form}>
        <CustomInput label="Meeting Title" />
        <CustomDateTime label="Time Schedule" width="100%" />
        <CustomInput label="Join Link" />
        <div className={classes.buttonDiv}>
          <Button variant="contained" color="primary">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MeetingForm;
