const { UPDATE_UNCONFIRM_QUOTES, PROVIDED_QUOTES_LIST } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    unconfirmQuotes: [],
    providedQuotes: false
};


const unconfirmQuotesReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_UNCONFIRM_QUOTES:
            return {
                ...state,
                unconfirmQuotes: payload,
            };
        
        case PROVIDED_QUOTES_LIST: 
            return {
                ...state,
                providedQuotes: payload
            }
        
        default:
            return state
    }
}

export default unconfirmQuotesReducer
