import React, { Fragment } from "react";

// Custom Components
import ViewBILinguists from "components/Auth/ViewBILinguists/LinguistsCollectiveBooking";

const ViewBILinguistsPage= () => {
  return (
    <Fragment>
      <ViewBILinguists />
    </Fragment>
  );
};

export default ViewBILinguistsPage
