import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import LinguistLayout from "layout/LinguistLayout";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import InterpretingOption from "components/Linguist/InterpretingOption";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LinguistsCollectiveTel from "components/Linguist/ManageInhouse/Form/OTT/BISummary";

const OrderTranslation = () => {
  const classes = useDashboardStyles();
  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Summary</p>

              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >

                <InterpretingOption />
              </CardHeader>

            </Card>

            <LinguistsCollectiveTel />
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default OrderTranslation;
