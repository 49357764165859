import React, { useState, useEffect, useRef } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";
import { Button, Form, Segment, TextArea, Modal } from "semantic-ui-react";
import swal from "sweetalert";
import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "axios";
import Moment from "moment";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Action from "components/Admin/ManagementPanel/ActionMessage";
import { changePreRegInput } from "redux/actions/preregAction";
import { useDispatch, useSelector } from "react-redux";

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const token = Cookies.get("token");
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = useState({ reload: false });
  const [management_panel, setAdminAccess] = useState(null);
  const [attachment, setAttachment] = useState();
  const fileInput = useRef(null);
  const { data } = useSelector((state) => state.preregReducer);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [pay, setPay] = React.useState({
    message: "",
  });

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleCAC = async () => {
    setEdit({ ...edit, loader: true });

    let fd = new FormData();

    fd.append("attachment", attachment);
    fd.append("message", pay.message);
    fd.append("cacDashboard", "yes");

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/write-welcome-message`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: `${response?.data.message}`,
      });
      loadList();
      setPay({ ...pay, message: "", loader: false });
      setAttachment(null);
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: `${error?.response?.data?.error ? error?.response?.data?.error : "Check your internet connection please."}`,
      });
    }
  };
  const handleAttachment = (e) => {
    setAttachment(e.target.files[0]);
  };

  const handleBAC = async () => {
    setEdit({ ...edit, loader: true });

    let fd = new FormData();

    fd.append("attachment", attachment);
    fd.append("message", pay.message);
    fd.append("bacDashboard", "yes");

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/write-welcome-message`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: `${response?.data.message}`,
      });
      loadList();
      setPay({ ...pay, message: "", loader: false });
      setAttachment(null);
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: `${error?.response?.data?.error ? error?.response?.data?.error : "Check your internet connection please."}`,
      });
    }
  };

  const handleLIN = async () => {
    setEdit({ ...edit, loader: true });

    let fd = new FormData();

    fd.append("attachment", attachment);
    fd.append("message", pay.message);
    fd.append("linDashboard", "yes");

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/write-welcome-message`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: `${response?.data.message}`,
      });
      loadList();
      setPay({ ...pay, message: "", loader: false });
      setAttachment(null);
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: `${error?.response?.data?.error ? error?.response?.data?.error : "Check your internet connection please."}`,
      });
    }
  };

  const handleEMP = async () => {
    setEdit({ ...edit, loader: true });

    let fd = new FormData();

    fd.append("attachment", attachment);
    fd.append("message", pay.message);
    fd.append("empDashboard", "yes");

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/write-welcome-message`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: `${response?.data.message}`,
      });
      loadList();
      setPay({ ...pay, message: "", loader: false });
      setAttachment(null);
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: `${error?.response?.data?.error ? error?.response?.data?.error : "Check your internet connection please."}`,
      });
    }
  };

  const handleSPPO = async () => {
    setEdit({ ...edit, loader: true });

    let fd = new FormData();

    fd.append("attachment", attachment);
    fd.append("message", pay.message);
    fd.append("sppoDashboard", "yes");

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/write-welcome-message`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: `${response?.data.message}`,
      });
      loadList();
      setPay({ ...pay, message: "", loader: false });
      setAttachment(null);
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: `${error?.response?.data?.error ? error?.response?.data?.error : "Check your internet connection please."}`,
      });
    }
  };

  const postCAC = async (e, id) => {
    swal(`Are you sure you want to post this welcome message for CAC?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleCAC(value);

        default:
          break;
      }
    });
  };

  const postBAC = async (e, id) => {
    swal(`Are you sure you want to post this welcome message for BAC?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleBAC(value);

        default:
          break;
      }
    });
  };

  const postLIN = async (e, id) => {
    swal(`Are you sure you want to post this welcome message for LIN?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleLIN(value);

        default:
          break;
      }
    });
  };

  const postEMP = async (e, id) => {
    swal(`Are you sure you want to post this welcome message for EMP?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleEMP(value);

        default:
          break;
      }
    });
  };

  const postSPPO = async (e, id) => {
    swal(`Are you sure you want to post this welcome message for SPPO?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleSPPO(value);

        default:
          break;
      }
    });
  };

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/get-all-welcome-message`
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader color="primary">
            <p className={classes.cardTitleWhite}>Welcome Message</p>
          </CardHeader>
          <CardBody>
            <Segment color="purple">
              <Form color="purple">
                <Form.Group widths="equal">
                  <Form.Input
                    onChange={handleChange}
                    value={pay.message}
                    label="Subject"
                    name="message"
                    placeholder="Write subject here..."
                  />
                </Form.Group>
              </Form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "24px",
                }}
              >
                <input
                  onChange={handleAttachment}
                  type="file"
                  style={{ display: "none" }}
                  accept=".doc,.pdf,.jpg,.png,.gif,.txt,video/*,audio/*"
                  ref={fileInput}
                ></input>
                <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
                  Insert Attachment (max 1mb)*
                </Header>
                <div>
                  <Button onClick={handleClick}>Upload</Button>
                </div>
                {attachment && (
                  <Header style={{ margin: "0", textAlign: "center" }} as="h5">
                    {attachment.name}
                    <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                      attachment.size / 1024
                    ).toFixed(2)}kb`}</Header>
                    <Header
                      style={{ margin: "0" }}
                      as="h5"
                    >{`Last Modified: ${attachment?.lastModifiedDate?.toDateString()}`}</Header>
                  </Header>
                )}
              </div>
            </Segment>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button.Group>
                <Button
                  color="purple"
                  disabled={!pay.message || !attachment}
                  onClick={postCAC}
                >
                  CAC
                </Button>
                <Button.Or />
                <Button
                  color="purple"
                  disabled={!pay.message || !attachment}
                  onClick={postBAC}
                >
                  BAC
                </Button>
                <Button.Or />
                <Button
                  color="purple"
                  disabled={!pay.message || !attachment}
                  onClick={postLIN}
                >
                  LIN
                </Button>
                <Button.Or />
                <Button
                  color="purple"
                  disabled={!pay.message || !attachment}
                  onClick={postSPPO}
                >
                  SPPO
                </Button>
                <Button.Or />
                <Button
                  color="purple"
                  disabled={!pay.message || !attachment}
                  onClick={postEMP}
                >
                  EMP/
                  <br />
                  MAN
                </Button>
              </Button.Group>
            </div>
          </CardBody>
        </Card>

        <Card>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" id="export">
                <TableHead>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    <TableCell>Admin Name</TableCell>
                    <TableCell>Admin ID</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {values.length > 0 ? (
                  <TableBody>
                    {values
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={list._id}
                          style={
                            list?.cacDashboard
                              ? { backgroundColor: "#B048B5" }
                              : list?.bacDashboard
                              ? { backgroundColor: "#A74AC7" }
                              : list?.linDashboard
                              ? { backgroundColor: "#D462FF" }
                              : list?.sppoDashboard
                              ? { backgroundColor: "#FF00FF" }
                              : list?.empDashboard
                              ? { backgroundColor: "#EE82EE" }
                              : null
                          }
                        >
                          <TableCell style={{ textAlign: "justify" }}>
                            {list?.cacDashboard
                              ? "CAC - "
                              : list?.bacDashboard
                              ? "BAC - "
                              : list?.linDashboard
                              ? "LIN - "
                              : list?.sppoDashboard
                              ? "SPPO - "
                              : "EMP - "}
                            {list?.message}
                          </TableCell>
                          <TableCell>{list?.postedBy?.LastName}</TableCell>
                          <TableCell> {list?.postedBy?.id} </TableCell>

                          <TableCell>
                            {Moment(list.createdAt)
                              .tz("Europe/London")
                              .format("DD MMM YYYY - kk:mm:ss")}
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Action id={list?._id} load={loadList} />{" "}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  "No data found"
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={values.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AssignmentPage;
