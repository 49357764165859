import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import ListAltIcon from "@material-ui/icons/ListAlt";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import LINGroup from "@material-ui/icons/People";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { Fragment } from "react";
import { useMediaQuery } from "@material-ui/core";

const LinguistOptions = () => {
  const history = useHistory();
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <div>
      {!matches ? (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {" "}
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "22%" }}
            onClick={() => history.push("/admin/unverified-linguists")}
          >
            Unverified Linguist
          </Button>
          <Button
            startIcon={<VerifiedUserIcon />}
            color="primary"
            variant="contained"
            style={{ width: "22%" }}
            onClick={() => history.push("/admin/verified-linguists")}
          >
            Verified Linguist
          </Button>
          <Button
            startIcon={<NotInterestedIcon />}
            color="primary"
            variant="contained"
            style={{ width: "22%" }}
            onClick={() => history.push("/admin/suspend-linguists")}
          >
            Suspended Linguist
          </Button>
          <Button
            startIcon={<LINGroup />}
            color="primary"
            variant="contained"
            style={{ width: "22%" }}
            onClick={() => history.push("/admin/LIN-group")}
          >
            LIN Group
          </Button>
        </div>
      ) : (
        <Fragment>
          {" "}
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "10px" }}
            onClick={() => history.push("/admin/unverified-linguists")}
          >
            Unverified Linguist
          </Button>
          <Button
            startIcon={<VerifiedUserIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "10px" }}
            onClick={() => history.push("/admin/verified-linguists")}
          >
            Verified Linguist
          </Button>
          <Button
            startIcon={<NotInterestedIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "10px" }}
            onClick={() => history.push("/admin/suspend-linguists")}
          >
            Suspended Linguist
          </Button>
          <Button
            startIcon={<LINGroup />}
            color="primary"
            variant="contained"
            style={{ width: "100%" }}
            onClick={() => history.push("/admin/LIN-group")}
          >
            LIN Group
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default LinguistOptions;
