import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomDate from "components/Common/CustomDate";
import CustomRadio from "components/Common/CustomRadio";
import CustomUpload from "components/Common/CustomUpload";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";
import CustomPassword from "components/Common/CustomPassword";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import { CURRENT_COUNTRY, GENDER_DATA } from "data";
import {
  updateErStep,
  changeErInput,
  autoSaveER,
} from "redux/actions/erAction";
import CustomSelect from "components/Common/CustomSelect";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import CustomAutoSelect from "components/Common/CustomAutoSelect";

const FormOne = () => {
  const { data } = useSelector((state) => state.erReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    const temp = JSON.parse(localStorage.getItem("userER"));
    dispatch(
      autoSaveER({
        temp,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("userER", JSON.stringify(data));
  }, [data]);

  const {
    title,
    first_name,
    last_name,
    house_street_no_name,
    address_line_two,
    town_city,
    post_code,
    county_region,
    country,
    current_nationality,
    other_nationalities,
    dob,
    primary_email,
    password,
    confirm_password,
    sex,
    mobile_no,
    business_phone_no,
    online_calling_details,
    profile_photo,
    proof_address_photo,
    reference_one,
    reference_two,
  } = data;

  const isAllow =
    !title ||
    !first_name ||
    !last_name ||
    !house_street_no_name ||
    !town_city ||
    !post_code ||
    !county_region ||
    !country ||
    !current_nationality ||
    !dob ||
    !primary_email ||
    !password ||
    !confirm_password ||
    !sex ||
    !mobile_no;

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateErStep(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(changeErInput({ name, value }));
  };

  const handleDateChange = (date) => {
    dispatch(
      changeErInput({
        name: "dob",
        value: date,
      })
    );
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeErInput({
        name: "other_nationalities",
        value,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeErInput({
        name: "country",
        value,
      })
    );
  };

  const handleChangeNA = (e, value) => {
    dispatch(
      changeErInput({
        name: "current_nationality",
        value,
      })
    );
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomUpload
          required
          width="100%"
          label="Upload profile photo"
          name="profile_photo"
          value={profile_photo}
        />
        <CustomInput
          width="100%"
          label="Title"
          name="title"
          required
          onChange={handleChange}
          value={title}
        />
        <CustomInput
          width="100%"
          label="First Name(s)"
          name="first_name"
          onChange={handleChange}
          required
          value={first_name}
        />
        <CustomInput
          width="100%"
          label="Last Name"
          name="last_name"
          required
          onChange={handleChange}
          value={last_name}
        />
        <CustomInput
          onChange={handleChange}
          label="House number/name, Street name"
          required
          width="100%"
          name="house_street_no_name"
          value={house_street_no_name}
        />
        <CustomInput
          onChange={handleChange}
          label="Address line 2"
          width="100%"
          name="address_line_two"
          value={address_line_two}
        />
        <CustomInput
          onChange={handleChange}
          label="Town / City"
          required
          width="100%"
          name="town_city"
          value={town_city}
        />
        <CustomInput
          onChange={handleChange}
          label="Post / Zip Code"
          required
          width="100%"
          name="post_code"
          value={post_code}
        />
        <CustomInput
          onChange={handleChange}
          label="County / Region"
          required
          width="100%"
          name="county_region"
          value={county_region}
        />
        <CustomAutoSelect
          onChange={handleAutoCompleteCO}
          label="Country"
          moreInfo="Current country of residence"
          width="100%"
          name="country"
          value={country}
          data={CURRENT_COUNTRY}
          required
        />
        <CustomAutoSelect
          onChange={handleChangeNA}
          label="Current nationality"
          width="100%"
          name="current_nationality"
          value={current_nationality}
          data={CURRENT_COUNTRY}
          required
        />
        <CustomAutoComplete
          onChange={handleAutoComplete}
          label="Other nationalities"
          width="100%"
          data={CURRENT_COUNTRY}
          value={other_nationalities}
          placeholder="Choose"
        />
        <CustomDate
          width="100%"
          label="Date Of Birth"
          onChange={handleDateChange}
          name="dob"
          value={dob}
          required
        />
        <CustomRadio
          onChange={handleChange}
          label="Gender"
          width="100%"
          name="sex"
          value={sex}
          data={GENDER_DATA}
          required
        />
        <CustomBigInput
          onChange={handleChange}
          label="Primary Email"
          moreInfo="This email address will be shared with payment processor"
          width="100%"
          name="primary_email"
          value={primary_email}
          required
        />
        <CustomPassword
          onChange={handleChange}
          label="Password"
          width="100%"
          name="password"
          value={password}
          required
        />
        <CustomPassword
          onChange={handleChange}
          label="Confirm password"
          width="100%"
          name="confirm_password"
          value={confirm_password}
          validation={password !== confirm_password}
          required
        />
        <CustomInput
          width="100%"
          label="Phone number"
          name="business_phone_no"
          onChange={handleChange}
          value={business_phone_no}
        />
        <CustomInput
          width="100%"
          label="Mobile number"
          name="mobile_no"
          onChange={handleChange}
          required
          value={mobile_no}
        />
        <CustomInput
          onChange={handleChange}
          width="100%"
          label="Skype, Zoom or other calling details"
          name="online_calling_details"
          value={online_calling_details}
        />
        <CustomUpload
          width="100%"
          label="Upload proof of address(utility bill or driving licence)"
          name="proof_address_photo"
          value={proof_address_photo}
        />
        <CustomBigInput
          onChange={handleChange}
          width="100%"
          label="Reference 1 work"
          moreInfo="Add your professional referee details below (full name, email, full address, phone number). If you have no referee please provide details of a friend."
          name="reference_one"
          value={reference_one}
        />
        <CustomBigInput
          onChange={handleChange}
          width="100%"
          label="Reference 2 education"
          moreInfo="Add your academic referee details 2 below (full name, email, full address, phone number)"
          name="reference_two"
          value={reference_two}
        />
        <div className={classes.buttonFlex}>
          <CustomButton text="Next" disabled={isAllow} onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default FormOne;
