// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const BookingOptions = ({ setActive }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button variant="outlined" onClick={() => setActive("adm / emp")}>
        ADM / EMP
      </Button>
      <Button variant="outlined" onClick={() => setActive("cac")}>
        CAC
      </Button>
      <Button variant="outlined" onClick={() => setActive("bac")}>
        BAC
      </Button>
      <Button variant="outlined" onClick={() => setActive("lin")}>
        Linguist
      </Button>
      <Button variant="outlined" onClick={() => setActive("sppo")}>
        SPPO
      </Button>
    </div>
  );
};

export default BookingOptions;
