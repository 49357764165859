import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import EmployeeLayout from "layout/EmployeeLayout";

import EmployeeResetPassword from "components/Employee/EmployeeResetPassword";

// Custom Styles

const ResetPassword = () => {

  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <EmployeeResetPassword />
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default ResetPassword;
