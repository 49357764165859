import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("token");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("social_media_1", data.social_media_1);
    fd.append("social_media_2", data.social_media_2);
    fd.append("social_media_3", data.social_media_3);
    fd.append("social_media_4", data.social_media_4);
    fd.append("social_media_5", data.social_media_5);
    fd.append("social_media_6", data.social_media_6);
    fd.append("social_media_7", data.social_media_7);
    fd.append("social_media_8", data.social_media_8);
    fd.append("social_media_9", data.social_media_9);
    fd.append("social_media_10", data.social_media_10);

    fd.append("social_link_1", data.social_link_1);
    fd.append("social_link_2", data.social_media_2);
    fd.append("social_link_3", data.social_media_3);
    fd.append("social_link_4", data.social_link_4);
    fd.append("social_link_5", data.social_media_5);
    fd.append("social_link_6", data.social_media_6);
    fd.append("social_link_7", data.social_link_7);
    fd.append("social_link_8", data.social_media_8);
    fd.append("social_link_9", data.social_media_9);
    fd.append("social_link_10", data.social_media_10);

    try {
      const response = await Axios.put(
        `/api/manager-admin/update-CLA/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          {edit.value ? (
            <>
              <p>Social Media & link</p>
              <input
                name="social_media_1"
                onChange={handleChange}
                value={data?.social_media_1}
                placeholder="Social media name"
              />
              <input
                name="social_link_1"
                onChange={handleChange}
                value={data?.social_link_1}
                placeholder="Social media account link"
              />
              <hr />
            </>
          ) : data?.social_media_1 && data?.social_link_1 ? (
            <p>
              <Button
                onClick={() =>
                  window.open("https://" + `${data?.social_link_1}`, "_blank")
                }
                color="purple"
              >
                {data?.social_media_1}
              </Button>
              <b>
                <a target="_blank" href={"https://" + `${data?.social_link_1}`}>
                  {data?.social_link_1}
                </a>
              </b>
            </p>
          ) : null}

          {edit.value ? (
            <>
              <p>Social Media & link</p>
              <input
                name="social_media_2"
                onChange={handleChange}
                value={data?.social_media_2}
                placeholder="Social media name"
              />
              <input
                name="social_link_2"
                onChange={handleChange}
                value={data?.social_link_2}
                placeholder="Social media account link"
              />
              <hr />
            </>
          ) : data?.social_media_2 && data?.social_link_2 ? (
            <p>
              <Button
                onClick={() =>
                  window.open("https://" + `${data?.social_link_2}`, "_blank")
                }
                color="purple"
              >
                {data?.social_media_2}
              </Button>
              <b>
                <a target="_blank" href={"https://" + `${data?.social_link_2}`}>
                  {data?.social_link_2}
                </a>
              </b>
            </p>
          ) : null}

          {edit.value ? (
            <>
              <p>Social Media & link</p>
              <input
                name="social_media_3"
                onChange={handleChange}
                value={data?.social_media_3}
                placeholder="Social media name"
              />
              <input
                name="social_link_3"
                onChange={handleChange}
                value={data?.social_link_3}
                placeholder="Social media account link"
              />
              <hr />
            </>
          ) : data?.social_media_3 && data?.social_link_3 ? (
            <p>
              <Button
                onClick={() =>
                  window.open("https://" + `${data?.social_link_3}`, "_blank")
                }
                color="purple"
              >
                {data?.social_media_3}
              </Button>
              <b>
                <a target="_blank" href={"https://" + `${data?.social_link_3}`}>
                  {data?.social_link_3}
                </a>
              </b>
            </p>
          ) : null}

          {edit.value ? (
            <>
              <p>Social Media & link</p>
              <input
                name="social_media_4"
                onChange={handleChange}
                value={data?.social_media_4}
                placeholder="Social media name"
              />
              <input
                name="social_link_4"
                onChange={handleChange}
                value={data?.social_link_4}
                placeholder="Social media account link"
              />
              <hr />
            </>
          ) : data?.social_media_4 && data?.social_link_4 ? (
            <p>
              <Button
                onClick={() =>
                  window.open("https://" + `${data?.social_link_4}`, "_blank")
                }
                color="purple"
              >
                {data?.social_media_4}
              </Button>
              <b>
                <a target="_blank" href={"https://" + `${data?.social_link_4}`}>
                  {data?.social_link_4}
                </a>
              </b>
            </p>
          ) : null}

          {edit.value ? (
            <>
              <p>Social Media & link</p>
              <input
                name="social_media_5"
                onChange={handleChange}
                value={data?.social_media_5}
                placeholder="Social media name"
              />
              <input
                name="social_link_5"
                onChange={handleChange}
                value={data?.social_link_5}
                placeholder="Social media account link"
              />
              <hr />
            </>
          ) : data?.social_media_5 && data?.social_link_5 ? (
            <p>
              <Button
                onClick={() =>
                  window.open("https://" + `${data?.social_link_5}`, "_blank")
                }
                color="purple"
              >
                {data?.social_media_5}
              </Button>
              <b>
                <a target="_blank" href={"https://" + `${data?.social_link_5}`}>
                  {data?.social_link_5}
                </a>
              </b>
            </p>
          ) : null}

          {edit.value ? (
            <>
              <p>Social Media & link</p>
              <input
                name="social_media_6"
                onChange={handleChange}
                value={data?.social_media_6}
                placeholder="Social media name"
              />
              <input
                name="social_link_6"
                onChange={handleChange}
                value={data?.social_link_6}
                placeholder="Social media account link"
              />
              <hr />
            </>
          ) : data?.social_media_6 && data?.social_link_6 ? (
            <p>
              <Button
                onClick={() =>
                  window.open("https://" + `${data?.social_link_6}`, "_blank")
                }
                color="purple"
              >
                {data?.social_media_6}
              </Button>
              <b>
                <a target="_blank" href={"https://" + `${data?.social_link_6}`}>
                  {data?.social_link_6}
                </a>
              </b>
            </p>
          ) : null}

          {edit.value ? (
            <>
              <p>Social Media & link</p>
              <input
                name="social_media_7"
                onChange={handleChange}
                value={data?.social_media_7}
                placeholder="Social media name"
              />
              <input
                name="social_link_7"
                onChange={handleChange}
                value={data?.social_link_7}
                placeholder="Social media account link"
              />
              <hr />
            </>
          ) : data?.social_media_7 && data?.social_link_7 ? (
            <p>
              <Button
                onClick={() =>
                  window.open("https://" + `${data?.social_link_7}`, "_blank")
                }
                color="purple"
              >
                {data?.social_media_7}
              </Button>
              <b>
                <a target="_blank" href={"https://" + `${data?.social_link_7}`}>
                  {data?.social_link_7}
                </a>
              </b>
            </p>
          ) : null}

          {edit.value ? (
            <>
              <p>Social Media & link</p>
              <input
                name="social_media_8"
                onChange={handleChange}
                value={data?.social_media_8}
                placeholder="Social media name"
              />
              <input
                name="social_link_8"
                onChange={handleChange}
                value={data?.social_link_8}
                placeholder="Social media account link"
              />
              <hr />
            </>
          ) : data?.social_media_8 && data?.social_link_8 ? (
            <p>
              <Button
                onClick={() =>
                  window.open("https://" + `${data?.social_link_8}`, "_blank")
                }
                color="purple"
              >
                {data?.social_media_8}
              </Button>
              <b>
                <a target="_blank" href={"https://" + `${data?.social_link_8}`}>
                  {data?.social_link_8}
                </a>
              </b>
            </p>
          ) : null}

          {edit.value ? (
            <>
              <p>Social Media & link</p>
              <input
                name="social_media_9"
                onChange={handleChange}
                value={data?.social_media_9}
                placeholder="Social media name"
              />
              <input
                name="social_link_9"
                onChange={handleChange}
                value={data?.social_link_9}
                placeholder="Social media account link"
              />
              <hr />
            </>
          ) : data?.social_media_9 && data?.social_link_9 ? (
            <p>
              <Button
                onClick={() =>
                  window.open("https://" + `${data?.social_link_9}`, "_blank")
                }
                color="purple"
              >
                {data?.social_media_9}
              </Button>
              <b>
                <a target="_blank" href={"https://" + `${data?.social_link_9}`}>
                  {data?.social_link_9}
                </a>
              </b>
            </p>
          ) : null}

          {edit.value ? (
            <>
              <p>Social Media & link</p>
              <input
                name="social_media_10"
                onChange={handleChange}
                value={data?.social_media_10}
                placeholder="Social media name"
              />
              <input
                name="social_link_10"
                onChange={handleChange}
                value={data?.social_link_10}
                placeholder="Social media account link"
              />
              <hr />
            </>
          ) : data?.social_media_10 && data?.social_link_10 ? (
            <p>
              <Button
                onClick={() =>
                  window.open("https://" + `${data?.social_link_10}`, "_blank")
                }
                color="purple"
              >
                {data?.social_media_10}
              </Button>
              <b>
                <a target="_blank" href={"https://" + `${data?.social_link_10}`}>
                  {data?.social_link_10}
                </a>
              </b>
            </p>
          ) : null}
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
