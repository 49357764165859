import Welcome from "components/Users/Sppo";
import SPPO from "components/RouteProtection/Sppo/Sppo";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SppoLayout from "layout/SppoLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SppoDashboard from "components/Sppo/SppoDashboard";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";
import history from "utils/history";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
//Icons
import BITicon from "assets/icons/book-interpreter-telephonic.png";
import OTTicon from "assets/icons/order-translation-telephonic.png";
import RQTicon from "assets/icons/request-quote-telephonic.png";

const Dashboard = () => {
  const classes = useDashboardStyles();
  const [loggedInData, setLoggedInData] = useState([]);

  const getDetails = async (e) => {
    const token = Cookies.get("SPPOtoken");
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoggedInData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <SppoLayout>
      <SPPO>
        <GridContainer>
          <GridItem xs={24} sm={24} md={24} lg={24} style={{ width: "100%" }}>
            <Welcome loggedInData={loggedInData} />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                history.push("/sppo/inhouse-booking/book-interpreter")
              }
            >
              <CardHeader color="primary" stats icon>
                <CardIcon
                  color="primary"
                  style={{
                    textAlign: "center",
                    width: "90px",
                    padding: "10px",
                  }}
                >
                  <img src={BITicon} alt="" style={{ width: "100%" }} />{" "}
                </CardIcon>
                <h3 className={classes.cardCategory}>Book Interpreter</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                history.push("/sppo/inhouse-booking/order-translation")
              }
            >
              <CardHeader color="primary" stats icon>
                <CardIcon
                  color="primary"
                  style={{
                    textAlign: "center",
                    width: "90px",
                    padding: "10px",
                  }}
                >
                  <img src={OTTicon} alt="" style={{ width: "100%" }} />{" "}
                </CardIcon>
                <h3 className={classes.cardCategory}>Order Translation</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <div className={classes.cardSettingsDiv}>
              <Card
                style={{
                  cursor: "pointer",
                }}
                onClick={() => history.push("/sppo/provide-direct-quote")}
              >
                <CardHeader color="primary" stats icon>
                  <CardIcon
                    color="primary"
                    style={{
                      textAlign: "center",
                      width: "90px",
                      padding: "10px",
                    }}
                  >
                    <img src={RQTicon} alt="" style={{ width: "100%" }} />
                  </CardIcon>
                  <h3 className={classes.cardCategory}>Direct Quote</h3>
                </CardHeader>
                <CardFooter stats></CardFooter>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>
                  Service Providing Partner Organisation Dashboard ({loggedInData?.agent_sppo ? "User of Main SPPO" : "Main SPPO Admin"})
                </p>
               </CardHeader>
              <CardBody>
                {" "}
                <SppoDashboard />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </SPPO>
    </SppoLayout>
  );
};

export default Dashboard;
