import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ActivityLogs from "components/Employee/ActivityLogs/ActivityLogs";

const Archive = () => {
  const classes = useDashboardStyles();
  const history = useHistory();
  return (
    <Employee>
      <EmployeeLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Activity Logs</p>
                <IconButton color="inherit" onClick={() => history.goBack()}>
                  <ArrowBackIcon />
                </IconButton>
              </CardHeader>
            </Card>

            <Card>
              <ActivityLogs />
            </Card>
          </GridItem>
        </GridContainer>
      </EmployeeLayout>
    </Employee>
  );
};

export default Archive;
