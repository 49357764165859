import React, { Fragment } from 'react';
import Login from "components/Auth/Login/Login";

function CustomerLoginPage(props) {
  return (
    <Fragment>
      <Login />
    </Fragment>
  );
}

export default CustomerLoginPage;