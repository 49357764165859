import React, { useState } from "react";
import useDropdownStyles from "assets/jss/components/dropDownStyles";
import Axios from "utils/axios";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Cookies from "js-cookie";
const AssignmentPage = () => {
  const classesTwo = useDropdownStyles();
  const [searchData, setSearchData] = React.useState({
    assignmentID: "",
  });
  const [values, setValues] = useState({ reload: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleChange = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
    FindAssignment(e.target.value);
  };

  const FindAssignment = async (id) => {
    const token = Cookies.get("BACtoken");
    try {
      const response = await Axios.get(
        `/api/account-client/search-assignment/assignmentID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues(response?.data);
      handleClick();
    } catch (error) {}
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TextField
        label="Search jobs"
        onChange={handleChange}
        variant="outlined"
        name="assignmentID"
        style={{ margin: 5, borderRadius: "8pt" }}
        onClick={handleClick}
        placeholder="Job / Assignment ID.."
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                color="purple"
                disabled={!searchData.assignmentID}
                onClick={() => FindAssignment(searchData.assignmentID)}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popper
        open={Boolean(open)}
        anchorEl={anchorEl}
        placement="auto-start"
        className={classesTwo.search}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList role="menu">
                  <MenuItem
                    className={classesTwo.dropdownItem}
                    onClick={handleChange}
                  >
                    {searchData.assignmentID == "" ? (
                      "No results found"
                    ) : !values ? null : !values?.payment_status ? (
                      <>
                        {values?.id?.includes("BACIA") ||
                        values?.id?.includes("BACTIA") ? (
                          <a
                            href={`/account-client/unconfirmed-interpreters/${
                              values?.id
                            }/${"interpreting"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("BACTA") ? (
                          <a
                            href={`/account-client/unconfirmed-translations/${
                              values?.id
                            }/${"translation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("BACTTA") ? (
                          <a
                            href={`/account-client/unconfirmed-translations/${
                              values?.id
                            }/${"translation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("BACQR") ? (
                          <a
                            href={`/account-client/unconfirmed-quotations/${
                              values?.id
                            }/${"quotation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : (
                          "No results found"
                        )}{" "}
                      </>
                    ) : values?.payment_status &&
                      values?.active &&
                      !values?.accept ? (
                      <>
                        {values?.id?.includes("BACIA") ||
                        values?.id?.includes("BACTIA") ? (
                          <a
                            href={`/account-client/confirmed-interpreters/${
                              values?.id
                            }/${"interpreting"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("BACTA") ? (
                          <a
                            href={`/account-client/confirmed-translations/${
                              values?.id
                            }/${"translation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : values?.id?.includes("BACTTA") ? (
                          <a
                            href={`/account-client/confirmed-translations/${
                              values?.id
                            }/${"translation"}`}
                          >
                            {" "}
                            {values?.id}
                          </a>
                        ) : (
                          "No results found"
                        )}{" "}
                      </>
                    ) : (
                      "No results found"
                    )}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default AssignmentPage;
