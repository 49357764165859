import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import Axios from "axios";
import Cookies from "js-cookie";

const BankDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("LINtoken");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updateProfileReducer);

  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("bank_account_fullname", data.bank_account_fullname || "-");
    fd.append("bank_account_number", data.bank_account_number || "-");
    fd.append("bank_branch_name", data.bank_branch_name || "-");
    fd.append("bank_iban", data.bank_iban || "-");
    fd.append("bank_swift_code", data.bank_swift_code || "-");
    fd.append("bank_ifsc_code", data.bank_ifsc_code || "-");
    fd.append("bank_name", data.bank_name || "-");
    fd.append("bank_branch_address", data.bank_branch_address || "-");
    fd.append("currency", data.currency || "-");

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/profile/update`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
      setEdit({ ...edit, value: false, loader: false });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="bank_account_fullname"
            onChange={handleChange}
            label="Bank Account Holder's Name"
            edit={edit.value}
            value={data.bank_account_fullname}
          />
          <CustomTextInput
            name="bank_account_number"
            onChange={handleChange}
            label="Account Number"
            edit={edit.value}
            value={data.bank_account_number}
          />
          <CustomTextInput
            name="bank_branch_name"
            onChange={handleChange}
            label="Branch Name"
            edit={edit.value}
            value={data.bank_branch_name}
          />
          <CustomTextInput
            name="bank_iban"
            onChange={handleChange}
            label="IBAN"
            edit={edit.value}
            value={data.bank_iban}
          />
          <CustomTextInput
            name="bank_swift_code"
            onChange={handleChange}
            label="Swift / BIC code"
            edit={edit.value}
            value={data.bank_swift_code}
          />
          <CustomTextInput
            name="bank_ifsc_code"
            onChange={handleChange}
            label="Sort Code / IFSC Code"
            edit={edit.value}
            value={data.bank_ifsc_code}
          />
          <CustomTextInput
            name="bank_name"
            onChange={handleChange}
            label="Name of the bank"
            edit={edit.value}
            value={data.bank_name}
          />
          <CustomTextInput
            name="bank_branch_address"
            onChange={handleChange}
            label="Address of bank branch"
            edit={edit.value}
            value={data.bank_branch_address}
          />
          <CustomTextInput name="" label="Additional Information" edit={edit.value} />
          <CustomTextInput
            name="currency"
            onChange={handleChange}
            label="Preffered currency"
            edit={edit.value}
            value={data.currency}
          />

          <a href={`/linguist/update-linguist-documents`}>
            Upload Documents
          </a>

        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default BankDetails;
