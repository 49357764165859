import Axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// Custom Styles
import swal from "sweetalert";

const InhouseClientEmailActivation = () => {
  const sppoID = Cookies.get("sppoID");
  let { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/inhouse/signup/sppoID/${sppoID}`,
        {
          token: token,
        }
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });

      setTimeout(() => {
        history.push("/");
      }, 2000);
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return <div></div>;
};

export default InhouseClientEmailActivation;
