import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox, FormControlLabel } from "@material-ui/core";

// Custom Components
import CustomButton from "components/Common/CustomButton";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

const PageTwo = () => {
  const handleCaptcha = async (value) => {
    console.log(value);
  };
  const dispatch = useDispatch();
  const classes = useFormStyles();

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                // onChange={handleChange}
                //   checked={agree}
                name="agree"
                color="primary"
              />
            }
            label="I agree with the terms and conditions of LC and this job to be publicised on LC website and among linguists and professionals by email. I also agree with LC privacy policy and GDPR."
          />
        </div>

        {/* <CustomCaptcha onChange={handleCaptcha} /> */}

        <div className={classes.buttonFlex}>
          <CustomButton
            //   disabled={isAllow}
            text="Submit"
            //   onClick={handleNext}
          />
        </div>
      </form>
    </div>
  );
};

export default PageTwo;
