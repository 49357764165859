import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import swal from "sweetalert";
import Axios from "utils/axios";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

const Action = ({ id, load, contact_group_name }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showNote, setShowNote] = React.useState(false);
  const [viewNote, setViewNote] = React.useState(false);

  const [pay, setPay] = React.useState({
    contact_group_name: "",
  });
  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowNote(false);
  };

  const handleUpdate = () => {
    setShowNote(!showNote);
  };

  const handleBack = () => {
    setViewNote(false);
  };

  const handleConvert = async () => {
    history.push(`send-cla-registration-form`);
  };

  const DeleteClient = async () => {
    try {
      const response = await Axios.delete(
        `/api/delete/cla-contact-group/${id}`
      );
      swal({
        icon: "success",
        text: `CLA successfully deleted.`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure you want to delete this?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          DeleteClient(value);
          break;
        default:
          break;
      }
    });
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };
  const handleNote = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.put(
        `/api/update/cla-contact-group/${id}`,
        {
          contact_group_name: pay.contact_group_name,
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: "Successfully updated!!!",
      });
      setPay({ ...pay, contact_group_name: " ", loader: false });
      load();
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          minWidth: "10px",
          paddingLeft: "0px",
        }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <MenuItem onClick={handleUpdate}>Update</MenuItem>
      </Menu>

      {/* ADD NOTE */}
      {showNote ? (
        <Dialog open={showNote}>
          {!viewNote ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Contact Group - {contact_group_name}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.contact_group_name}
                  id="outlined-basic"
                  label="Contact Group"
                  variant="outlined"
                  name="contact_group_name"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    disabled={!pay.contact_group_name}
                    onClick={handleNote}
                    color="primary"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Update Contact Group
                  </Button>

                  <Button
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Update Contact Group
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="primary"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}
    </div>
  );
};

export default Action;
