import React, { useState, useEffect } from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Cookies from "js-cookie";
import Axios from "axios";
import { Header } from "semantic-ui-react";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ViewBill from "components/AccountClient/Bills/ViewBill/MonthlyBill";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import history from "utils/history";

const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  const [bill_access, setAssignmentAccess] = useState(null);
  const [bacUser, setbacUser] = useState(null);

  const getDetails = async () => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/accountclient/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAssignmentAccess(response?.data.bill_access);
    setbacUser(response?.data.bacUser);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Accountclient>
      <AccountClientLayout>
        <GridContainer>
          {bill_access ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>View Bills</p>
                  <IconButton color="inherit" onClick={() => history.goBack()}>
                    <ArrowBackIcon />
                  </IconButton>
                </CardHeader>
                <CardBody>
                  <ViewBill />
                </CardBody>
              </Card>
            </GridItem>
          ) : bacUser === false ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>View Bills</p>
                  <IconButton color="inherit" onClick={() => history.goBack()}>
                    <ArrowBackIcon />
                  </IconButton>
                </CardHeader>
                <CardBody>
                  <ViewBill />
                </CardBody>
              </Card>
            </GridItem>
          ) : bill_access === false ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default UnconfirmedAssignmentsPage;
