import { useState } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import { Button, Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomCaptcha from "components/Common/CustomCaptcha";
import TermsAndConditions from "components/Auth/EmployeeRegistration/TermsAndConditions";
import swal from "sweetalert";
import CustomAvatar from "components/Common/CustomAvatar";

import defaultAxios from "utils/axios";
import CustomPhone from "components/Common/CustomPhone";

const SubscribeHere = () => {
  const classes = useEmployeeStyles();
  const [captcha, setCaptcha] = useState();
  const [checked, setChecked] = useState(false);

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
  });

  const { first_name, last_name, email, mobile_no } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };
  const handleCheck = (value) => {
    setChecked(value);
  };

  const handleSubmit = async (e) => {
    const sendData = {
      first_name: data.first_name || "-",
      last_name: data.last_name || "-",
      email: data.email || "-",
      mobile_no: data.mobile_no || "-",
    };
    try {
      const response = await defaultAxios.post(
        "/api/frontpage/pre-subscribe",
        sendData
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleMobile = (value) => {
    setData({ ...data, mobile_no: value });
  };

  return (
    <LandingPageLayout>
      <GridContainer style={{ justifyContent: "center", margin: "2rem 0" }}>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  Subscribe with Linguists Collective
                </Typography>
              </div>
              <CustomInputSingle
                label="First Name(s)"
                width="100%"
                placeholder="First Name"
                name="first_name"
                onChange={handleChange}
                value={first_name}
                required
              />
              <CustomInputSingle
                label="Last Name"
                width="100%"
                placeholder="Last Name"
                name="last_name"
                value={last_name}
                onChange={handleChange}
                required
              />
              <div style={{ marginTop: "24px" }}>
                <CustomPhone
                  onChange={handleMobile}
                  placeholder="Mobile number"
                  value={mobile_no}
                  name="mobile_no"
                />
              </div>
              <CustomInputSingle
                label="Email"
                width="100%"
                placeholder="Email"
                name="email"
                onChange={handleChange}
                value={email}
                required
              />

              <TermsAndConditions
                handleCheck={handleCheck}
                text="By subscribing, I consent to having LC store my submitted information and contact me with news, updates and promotion."
              />
              <CustomCaptcha onChange={handleCaptcha} />

              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  className={`${classes.button}`}
                  color="primary"
                  variant="contained"
                  disabled={
                    !checked || !captcha || !first_name || !last_name || !email
                  }
                  onClick={handleSubmit}
                >
                  Subscribe
                </Button>
                <a style={{ marginLeft: "5px" }} href="/unsubscribe-here">
                  Unsubscribe
                </a>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default SubscribeHere;
