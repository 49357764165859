import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Cookie from "js-cookie";
// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInput from "components/Common/CustomInput";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import { changeCAOInput, updateCAOStep } from "redux/actions/caoAction";
import CustomRadio from "components/Common/CustomRadio";
import CustomBigInput from "components/Common/CustomBigInput";
import { CURRENCY, DEBIT_CARD, POSSIBLE_TO_PAY_IN_ACCOUNT, YES_NO } from "data";
import CustomCaptcha from "components/Common/CustomCaptcha";
import Axios from "axios";
import CustomSelect from "components/Common/CustomSelect";

const PageFour = () => {
  const { data } = useSelector((state) => state.caoReducer);
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    title,
    FirstName,
    LastName,
    Birthday,
    sex,
    email,
    password,
    mobile_no,
    direct_line,
    AddressLine1,
    AddressLine2,
    City,
    PostalCode,
    Country,
    Region,
    Nationality,
    other_nationalities,
    account_type,
    business_name,
    company_registration_number,
    charity_registration_number,
    business_website,
    position,
    business_phone_no,
    other_email,
    date_and_time,
    provide_additional_info,
    different_AddressLine1,
    different_AddressLine2,
    different_Country,
    different_CountryRegion,
    different_PostalCode,
    different_Town,
    languages,
    services,
    other_details,
    anticipated_spending_per_month,
    estimated_booking_volume_per_month,
    guarantee_payment,
    pay_in_account,
    existing_account_with_localprovider,
    company,
    bank_reference_letter,
    pay_in_direct_debit,
    company_bank_details,
    currency,
    photo,
    passport,
  } = data;

  const classes = useFormStyles();

  const handleCaptcha = async (value) => {
    setValid(true);
    const isHuman = await fetch(
      `https://www.google.com/recaptcha/api/siteverify`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
        mode: "no-cors",
        credentials: "omit",
        body: `secret=6LdPUwMaAAAAAK7JyeaYyRPz4NeNyC2XoQthvK8h&response=6LdPUwMaAAAAALqroyySr9kV-TXA7sxKx2qzvSnC`,
      }
    );
  };
  const token = Cookie.get("token");

  const handleSubmit = async (e) => {
    let formData = new FormData();
    formData.append("title", title || "-");
    formData.append("FirstName", FirstName || "-");
    formData.append("LastName", LastName || "-");
    formData.append("Birthday", Birthday || "-");
    formData.append("sex", sex || "-");
    formData.append("email", email || "-");
    formData.append("password", password || "-");
    formData.append("mobile_no", mobile_no || "-");
    formData.append("direct_line", direct_line || "-");
    formData.append("AddressLine1", AddressLine1 || "-");
    formData.append("AddressLine2", AddressLine2 || "-");
    formData.append("City", City || "-");
    formData.append("PostalCode", PostalCode || "-");
    formData.append("Region", Region || "-");
    formData.append("Country", Country || "-");
    formData.append("Nationality", Nationality || "-");
    formData.append("other_nationalities", other_nationalities || "-");
    formData.append("account_type", account_type || "-");
    formData.append("business_name", business_name || "-");
    formData.append(
      "company_registration_number",
      company_registration_number || "-"
    );
    formData.append(
      "charity_registration_number",
      charity_registration_number || "-"
    );
    formData.append("business_website", business_website || "-");
    formData.append("position", position || "-");
    formData.append("business_phone_no", business_phone_no || "-");
    formData.append("other_email", other_email || "-");
    formData.append("date_and_time", date_and_time || "-");
    formData.append("provide_additional_info", provide_additional_info || "-");
    formData.append("different_AddressLine1", different_AddressLine1 || "-");
    formData.append("different_AddressLine2", different_AddressLine2 || "-");
    formData.append("different_Town", different_Town || "-");
    formData.append("different_PostalCode", different_PostalCode || "-");
    formData.append("different_CountryRegion", different_CountryRegion || "-");
    formData.append("different_Country", different_Country || "-");
    formData.append("languages", languages || "-");
    formData.append("services", services || "-");
    formData.append("guarantee_payment", guarantee_payment || "-");
    formData.append("pay_in_account", pay_in_account || "-");
    formData.append(
      "existing_account_with_localprovider",
      existing_account_with_localprovider || "-"
    );
    formData.append("company", company || "-");
    formData.append("bank_reference_letter", bank_reference_letter || "-");
    formData.append("pay_in_direct_debit", pay_in_direct_debit || "-");
    formData.append("company_bank_details", company_bank_details || "-");
    formData.append("other_details", other_details || "-");
    formData.append("currency", currency || "-");
    formData.append(
      "anticipated_spending_per_month",
      anticipated_spending_per_month || "-"
    );
    formData.append(
      "estimated_booking_volume_per_month",
      estimated_booking_volume_per_month || "-"
    );

    if (!photo?.contentType) {
      formData.append("photo", photo);
    }
    if (!passport?.contentType) {
      formData.append("passport", passport);
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/add-new-accountclient`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `Client successfully added`,
        icon: "success",
        buttons: "Ok",
      }).then(() => {
        window.location = "/admin/account-client";
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        // text: "Email is associated with an existing account. Please use a different email for completing registration.",
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleBack = () => {
    dispatch(updateCAOStep(3));
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value: value || checked,
      })
    );
  };

  const isAllow = !valid;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInput
          onChange={handleChange}
          width="100%"
          label="Can you provide us any guarantee of payment?"
          name="guarantee_payment"
          value={guarantee_payment}
        />

        <CustomRadio
          onChange={handleChange}
          label="If you are a small or micro business, would it be possible to pay in account?"
          width="100%"
          name="pay_in_account"
          value={pay_in_account}
          data={POSSIBLE_TO_PAY_IN_ACCOUNT}
        />
        <CustomRadio
          onChange={handleChange}
          label="Do you or your company currently have an existing account with a local provider?"
          width="100%"
          name="existing_account_with_localprovider"
          value={existing_account_with_localprovider}
          data={YES_NO}
        />

        <CustomInput
          onChange={handleChange}
          label="If yes, which company do you hold an account with?"
          width="100%"
          name="company"
          value={company}
        />
        <CustomBigInput
          onChange={handleChange}
          label="Can you provide us with a bank reference letter?"
          moreInfo="If this business is a private company"
          width="100%"
          name="bank_reference_letter"
          value={bank_reference_letter}
        />
        <CustomRadio
          onChange={handleChange}
          label="Can you pay by Direct Debit?"
          width="100%"
          name="pay_in_direct_debit"
          value={pay_in_direct_debit}
          data={DEBIT_CARD}
        />
        <CustomBigInput
          onChange={handleChange}
          label="Company bank details"
          moreInfo="Bank Account Name, Sort Code (UK only), Account No, IBAN, BIC / Swift Code, IFSC Code, Reconfirm Account No, Bank name and branch, Bank address, Any other details"
          width="100%"
          name="company_bank_details"
          value={company_bank_details}
        />

        <CustomSelect
          onChange={handleChange}
          data={CURRENCY}
          width="100%"
          label="Preferred currency"
          name="currency"
          value={currency}
        />

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        ></div>

        <CustomCaptcha
          onClick={handleCaptcha}
          onChange={() => setValid(valid === true ? true : !valid)}
        />

        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
            disabled={loading}
          />
          <CustomButton
            disabled={isAllow || loading}
            text={loading ? "Submitting..." : "Submit"}
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default PageFour;
