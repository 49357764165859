import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import BACList from "components/Admin/CrmPanel/AccountRemoval/BACAccountRemoval/BACDeleteRequestList";
import Options from "components/Admin/CrmPanel/AccountRemoval/Options";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const AccountRemoval = () => {
  const classes = useDashboardStyles();
  const [crm_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAdminAccess(response?.data.crm_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  },[]);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {crm_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>
                    Account Removal Requests (BAC)
                  </p>
                  <div style={{ display: "flex" }}>
                    <IconButton color="inherit">
                      <ArrowBackIcon onClick={() => history.goBack()} />
                    </IconButton>
                  </div>
                </CardHeader>
              </Card>
              <Card>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                  color="primary"
                >
                  <Options />
                </CardHeader>

                <CardBody>
                  <BACList />
                </CardBody>
              </Card>
            </GridItem>
          ) : crm_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading...</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AccountRemoval;
