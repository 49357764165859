import React from "react";

import Typography from "@material-ui/core/Typography";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import LandingPageLayout from "layout/LandingPageLayout";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import image1 from "assets/images/linguistscollective-image1.jpg";
import TLimage from "assets/images/translating-languages.jpg";
import PPimage from "assets/images/promotion-photo.jpg";
import TSLimage from "assets/images/teaching-sign-language.jpg";
import Pimage from "assets/images/professional.jpg";
import ISOBanner from "assets/images/lcbanner.jpg";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { MainDiv, ImageDiv } from "./style";
import ThreeCards from "./ThreeCards";
const ClientPortal = () => {
  const classes = useLandingpageStyles();
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "2rem 0 2rem 0", textAlign: "justify" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.root}>
            <CardBody
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                backgroundColor: "#ffe7fa",
              }}
            >
              <MainDiv>
                <GridContainer alignItems="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="left">
                      <img src={image1} alt="Contemplative Reptile" />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="right">
                      <Typography gutterBottom variant="h5" component="h2">
                        About Us
                        <hr />
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        We are an online agency and service-based marketplace
                        solution developed to provide a space for collaboration
                        between interpreters, translators, multilingual
                        professionals (collectively, linguists) and institutions
                        which require their services. Designed with
                        transparency, fairness and integrity in mind, Linguists
                        Collective exists to provide the most relevant
                        opportunities to linguists; and to provide institutions
                        with the correct and best linguists for the services
                        required.
                      </Typography>
                      <br />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        At Linguists Collective, we understand how vital it is
                        for you to have access to all your linguistic needs. We
                        aim to provide our clients with the best possible
                        interpreting, translating and expert linguistic
                        services. Our trained and experienced professionals can
                        help you with any concern you may have, and the ability
                        to book one is right at your fingertips. Whatever
                        service you require, Linguists Collective can deliver.
                        Our company structure is a combination of agency and
                        marketplace, which helps to provide a complete service
                        to our customers. Whether you’re looking for a face to
                        face interpreter, medical or software translator, or an
                        investigative linguist, we have everything you could
                        possibly need under one ever-expanding umbrella. We even
                        have specialist services which are specifically designed
                        for investigation and analytical needs including
                        forensic and expert evidence.
                      </Typography>

                      <br />

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        If our customer requires special bespoke services, we
                        are prepared to go the extra mile for them and deliver.
                        Customer satisfaction is a key priority. However, if
                        under any circumstances we are unable to provide or find
                        a linguist or professional for the requested service, we
                        will issue a full refund of any deposit paid within 28
                        days. With our adaptable and fully equipped automated
                        systems, we are able to promote the highest standard of
                        services, based on our customer's needs. We pride
                        ourselves on Fair, Ethical, Transparent and Smart
                        behaviour, and put our client’s first.
                      </Typography>
                      <br />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Linguists have suffered an uphill climb for many years,
                        struggling to make ends meet by having to sacrifice
                        ethics for profit. But, this never needs be a concern at
                        Linguists Collective – we aim to challenge that age-old
                        norm within the industry, our systems providing quality
                        services for the best price possible. Customers are
                        given options and respect. We will only list the most
                        professional linguists as verified within our system, to
                        assure an exceptional service. Clients will have the
                        opportunity to give feedback, to help others decide on a
                        service, and also give our linguists a chance to address
                        any issues directly. All of this to ensure we provide
                        the best to our customers. We strive to promote real
                        living wages for linguists and are committed to operate
                        as a Fair, Ethical, Transparent and Smart LSP (Language
                        Service Providers) that works for everyone.
                      </Typography>
                      <br />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Our company is rooted in principles, and as such we
                        intend to reinvest any profit made, back into the
                        company to continuously improve the organisation. Our
                        linguists are taken into great consideration to be
                        treated fairly, we condemn and fight against modern day
                        slavery and worker exploitation. Thus, when possible,
                        the company will share profits amongst linguists as an
                        end of year bonus. Of course, our linguists must adhere
                        to our promise of providing remarkable services to
                        customers. If necessary, they will be given additional
                        training and courses to attend in order to improve their
                        practices.
                      </Typography>
                    </div>
                  </GridItem>
                </GridContainer>
              </MainDiv>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={12}>
          <ImageDiv>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} className={classes.imgCon}>
                <img
                  src={PPimage}
                  alt=""
                  style={{
                    width: "100%",
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} className={classes.imgCon}>
                <img
                  src={TSLimage}
                  alt=""
                  style={{
                    width: "100%",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} className={classes.imgCon}>
                <img
                  src={TLimage}
                  alt=""
                  style={{
                    width: "100%",
                  }}
                />
              </GridItem>
            </GridContainer>
          </ImageDiv>
        </GridItem>

        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.root}>
            <CardBody style={{ backgroundColor: "#ffe7fa" }}>
              <Typography gutterBottom variant="h5" component="h2">
                Quality Management of Linguists
                <hr />
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
                Voluntarily we will follow regulatory guidelines such as NRPSI
                and CIOL in order to maintain British standard and promote them
                among our linguists. Level 2 and above registered linguists at
                our firm have undergone strict and rigorous recruitment
                procedures, including reference and qualification checks. And to
                offer full transparency, our clients can see a linguist's
                profile. In joining our company, LC linguists must attend
                boarding training, specially designed to support those new in
                the industry. Our courses or CPDs are to assure standards of
                professionalism together with client protocols and expectations.
                This ensures that every linguist understands quality in
                profession and regulatory standard such as NRPSI. With an easy
                interface feedback and rating system, our clients are able to
                provide an opportunity for our linguists to improve, should they
                need to. The rating system is beneficial to customers in giving
                them the ability to choose the service best suited to them,
                ensuring consistency and excellence.
              </Typography>
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={image2}
                title="Contemplative Reptile"
              />
            </CardActionArea>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.root}>
            <CardBody style={{ padding: "30px", backgroundColor: "#ffe7fa" }}>
              <Typography gutterBottom variant="h5" component="h2">
                Quality Management System Policy
                {/* Linguists Collective is a registered company in England and
                Wales */}
                <hr />
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Linguists Collective (LC) will provide customers with products and services
                that conform to all applicable requirements.
                <br /><br />
                To help us achieve our Quality Policy we are committed to implementing a
                Quality Management System that supports the strategic direction of our organisation.
                <br /><br />
                We regularly review the performance of our Quality Management System and will set Quality Objectives that help us to continually improve.
                <br />
                {/* Aims and Objectives of the Company are:
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                a. To provide language services, relevant expert and
                language-based professional services at home and abroad.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                b. To provide freelance linguists an online platform to look for
                work on.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                c. To make a profit and create wealth for LC and then reinvest
                and pay dividends to the investors and shareholders.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                d. To seek to achieve excellence in the language service
                industry, prioritising customer satisfaction.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                e. To seek to make language services available and easily
                accessible to competent authorities, businesses and the wider
                public.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                f. To operate a system of quality assurance to ensure that any
                dissatisfaction with the service provided is investigated and
                resolved in a fair and swift manner.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                g. To promote better pay and conditions for linguists and
                language professionals.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                h. To strive to ensure that LC is a multicultural and diverse
                organisation, and that its board reflects these values.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                i. We use our best endeavours to compete fairly in the market in
                order to achieve our objectives.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                j. We will seek to be fair, ethical, co-operative, transparent
                and to operate a smart marketplace for ease of use. */}
              </Typography>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <img
            src={ISOBanner}
            alt="banner"
            style={{
              width: "100%",
            }}
          />
        </GridItem>
        <ThreeCards />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <img
            src={Pimage}
            alt="banner"
            style={{
              width: "100%",
            }}
          />
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default ClientPortal;
