import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Material Core Components
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";

import CustomAvatar from "components/Common/CustomAvatar";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import { Link } from "react-router-dom";

const LinguistsCollective = ({ title }) => {
  const { count } = useSelector((state) => state.transReducer);
  const classes = useEmployeeStyles();
  const history = useHistory();

  const selectedForm = count;

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <Avatar className={classes.avatar}>
                  <CustomAvatar />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {title ? (
                    <Fragment>{title}</Fragment>
                  ) : (
                    <Fragment>BAC Translation Assignment Order Form</Fragment>
                  )}
                </Typography>
                <Typography variant="subtitle2"></Typography>
              </div>
              <div className={classes.notes}>
                <Typography variant="h6" gutterBottom>
                  Note to the customer
                </Typography>
                <Typography variant="body2" gutterBottom>
                  You can order most types of translation via this order form
                  however there are some limitation for large volume of work and
                  if you are looking for bargain you can use our quote to
                  compare prices. If you have more than one document please
                  order them separately and indicate any requirement of
                  formatting and certification. For transcription and subtitling
                  work please use quotation form.
                </Typography>
              </div>
              {selectedForm === 1 && <FormTwo />}
              {selectedForm === 2 && <FormThree />}
              {/* {selectedForm === 3 && <FormThree />} */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default LinguistsCollective;
