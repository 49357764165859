import defaultAxios from "utils/axios";
import { GET_CASH_BOOK_DATA, LOADING, ADD_DATA } from "./actionTypes";
import Cookies from 'js-cookie'



export const getCashBookData = () => async (dispatch) => {
  const token = Cookies.get('LINtoken')
  try {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const response = await defaultAxios.get(`${process.env.REACT_APP_BACKEND_API}/linguists/cash-book`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    dispatch({
      type: GET_CASH_BOOK_DATA,
      payload: response?.data.data,
    });
  } catch (error) {
    console.log("getCashBookData --->", error);
    dispatch({
      type: LOADING,
      payload: false,
    });
  }
};

export const addCashbookData = (data) => ({
  type: ADD_DATA,
  payload: data,
});
