import { makeStyles } from "@material-ui/core/styles";

import { whiteColor, secondaryBoxShadow, blackColor } from "../variables";

const styles = (theme) => ({
  appBar: {
    ...secondaryBoxShadow,
    background: `${whiteColor} !important`,
    "@media print": {
      display: "none !important",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  title: {
    flexGrow: 1,
  },
  icons: {
    color: blackColor,
  },
  imgDiv: {
    height: "35px",
    width: "35px",

    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "4px",
      right: "7px",
    },
  },
  sideDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiButton-root": {
      minWidth: "50px",
      padding: "0px",
      marginLeft: "5px",
    },
    "@media (max-width: 380px)": {
      overflowX: "auto",
      justifyContent: "flex-start",
      paddingLeft: "10px",
      "&::::-webkit-scrollbar": {
        height: "2px",
      },
    },
  },
});

const useNavbarStyles = makeStyles(styles);

export default useNavbarStyles;
