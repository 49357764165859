import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Action from "./Action";
import moment from "moment";
import SearchView from "./Search/SearchAssignment";
import Cookies from "js-cookie";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";

import history from "utils/history";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ interpretingData }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("SPPOtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/list-confirm-booking`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({
        ...values,
        bookingList: interpretingData
          ? response?.data.filter((item) => item.id === interpretingData)
          : response?.data,
      });
      interpretingData && setShow(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/find-confirm-booking/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });

        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  const handleViewAll = async (e, list) => {
    e.preventDefault();
    setViewAll({ ...viewAll, open: true, list: list });
  };

  return (
    <div>
      {!interpretingData && (
        <SearchView
          load={loadbookingList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          SearchText={SearchText}
          setShow={setShow}
          show={show}
        />
      )}
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                    <Table.HeaderCell>User Ref ID</Table.HeaderCell>
                    <Table.HeaderCell>Client ID</Table.HeaderCell>
                    <Table.HeaderCell>Timezone</Table.HeaderCell>
                    <Table.HeaderCell>Start date & time</Table.HeaderCell>
                    <Table.HeaderCell>Duration</Table.HeaderCell>
                    <Table.HeaderCell>Source language</Table.HeaderCell>
                    <Table.HeaderCell>Target language</Table.HeaderCell>
                    <Table.HeaderCell>
                      Target language skill level
                    </Table.HeaderCell>
                    <Table.HeaderCell>Total estimate</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {bookingList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((list) => (
                      <Table.Row
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={list._id}
                      >
                        <Table.Cell key={list._id} align={list.align}>
                          <Link
                            onClick={() =>
                              history.push(
                                `/sppo/inc-view-interpreting-assignment/${list._id}`
                              )
                            }
                          >
                            {list.id}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>
                          {list?.userID ? list?.userID : "N/A"}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.ClientID?.id ? list?.ClientID?.id : "N/A"}
                        </Table.Cell>
                        <Table.Cell>{list.timezone}</Table.Cell>
                        <Table.Cell>
                          {moment(list.startdateArray[0]).format("DD-MMM-YYYY")}{" "}
                          - {list?.starttimeArray[0]}
                        </Table.Cell>
                        <Table.Cell>{list.total_Duration}</Table.Cell>
                        <Table.Cell>{list.source_language}</Table.Cell>
                        <Table.Cell>{list.target_language}</Table.Cell>
                        <Table.Cell>{list.target_language_level}</Table.Cell>
                        <Table.Cell>
                          {list?.currency.substring(0, 3)}{" "}
                          {list?.estimate_paid_amount
                            ? list?.estimate_paid_amount
                            : list?.estimate}
                        </Table.Cell>
                        <Table.Cell>
                          {list.accept
                            ? "Accepted by Linguist / Allocated to Linguist"
                            : "In Marketplace"}
                        </Table.Cell>
                        <Table.Cell>
                          <Action
                            load={loadbookingList}
                            accepted={list.accept}
                            id={list._id}
                            ID={list?.id}
                            timesheet={list?.timesheet}
                            clientID={list?.ClientID?.id}
                            name={
                              list?.ClientID?.FirstName
                                ? list?.ClientID?.FirstName
                                : "N/A" + " " + list?.ClientID?.LastName
                                ? list?.ClientID?.LastName
                                : "N/A"
                            }
                            currency={list?.currency.substring(0, 3)}
                            estimate={
                              list.estimate_paid_amount
                                ? list.estimate_paid_amount
                                : list.estimate
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
