
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetch from 'isomorphic-fetch';

import MasterLayout from "layout/LandingPageLayout";

import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";

const ViewContactRequest = () => {

  const { id } = useParams();

  const [values, setValues] = useState({
    // title: "mst",
    // FirstName: "kona", 
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_API}/frontpage/view-job/${id}`)
      .then(res => res.json())
      .then((result) => {
        setValues(result)
      })
  })

  return (
    <MasterLayout>

      <div raised style={{ marginLeft: '20%', marginRight: '20%' }}>

        <Card raised style={{ marginTop: '24px' }} >
          <CardBody>
            <h4 style={{ textAlign: "center" }}>Job Details</h4>
          </CardBody>
        </Card>
        <Card raised style={{ marginTop: '24px', padding: '30px' }} >
          <CardBody>
            {/* <h4 style={{textAlign: "center"}}>Detail Information </h4> */}
            <p>Job Title: {values.jobtitle} </p>
            <p>Salary: {values.salary} </p>
            <p>Contract type: {values.contracttype}  </p>
            <p>Hours per week: {values.hoursperweek}  </p>
            <p>Remote address: {values.remote_details}  </p>
            <p>House number or street number: {values.business_addressline_one}  </p>
            <p>Address: {values.business_addressline_two}  </p>
            <p>City: {values.business_city}  </p>
            <p>Post code: {values.business_postcode}  </p>
            <p>Country / Region: {values.business_region}  </p>
            <p>Country: {values.business_country}  </p>



          </CardBody>
        </Card>


        <Card raised style={{ marginTop: '24px', padding: '30px' }} >
          <CardBody>
            {/* <h4 style={{textAlign: "center"}}>Detail Information </h4> */}

            <p>Job Reference: {values.jobref}  </p>
            <p>Target Language: {values.targetlanguage}  </p>
            <p>Source Language: {values.sourcelanguage}  </p>
            <p>Additional Language: {values.additionallanguage}  </p>
            <p>Linguist Skill Level: {values.expertiselevel}  </p>
            <p>About Company: {values.about_company}  </p>
            <p>Job Description: {values.jobdescription}  </p>
            <p>Application Closing Date: {values.applicationclosingdate}  </p>
            <p>How to apply: {values.howtoapply}  </p>

            <p>Business name: {values.business_name}  </p>
            <p>About Company: {values.about_company}  </p>

          </CardBody>
        </Card>
        <br /><br /><br />
      </div>

    </MasterLayout>
  );
};

export default ViewContactRequest;
