import styled from "styled-components";

export const RootDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  @media (max-width: 920px) {
    /* justify-content: center; */
  }
  .logo-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 920px) {
      .img-div {
        width: 100px;
      }
    }

    h6 {
      text-align: center;
      line-height: 20px;
      @media (max-width: 920px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;

export const NavDiv = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 920px) {
    margin: auto;
    margin-top: 10px;
  }
`;

export const FooterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 7rem 1rem;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .left {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    a {
      width: 100%;
      button {
        width: 100%;
      }
    }

    .text {
      margin-left: auto;
      margin-right: auto;
    }
    div {
      margin-right: 30px;
    }
    @media (max-width: 1440px) {
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto auto;
      div {
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto auto;
      div {
        margin-right: 10px;
        margin-bottom: 20px;
      }
    }
    @media (width: 768px) {
      display: grid;
      grid-template-columns: auto auto auto auto;
      div {
        margin-right: 10px;
        margin-bottom: 20px;
      }
    }

    @media (max-width: 425px) {
      display: grid;
      grid-template-columns: auto;
      text-align: center;
      div {
        margin-right: 10px;
      }
    }
    @media (max-width: 886px) {
      width: 100%;
      justify-content: space-between;
      div {
        margin-bottom: 20px;
      }
    }

    @media (max-width: 633px) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .right {
    @media (max-width: 886px) {
      margin-top: 1rem;
      width: 100%;
    }
  }
`;

export const RouteSearchDiv = styled.div`
  .results {
    height: 200px;
    overflow-y: auto;
  }
`;
