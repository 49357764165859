import React, { Fragment, useState, useEffect } from "react";

// Material Core Components
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Brightness1 } from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Badge from "@material-ui/core/Badge";
import axios from "axios";
import Cookies from "js-cookie";
import { Button, Header } from "semantic-ui-react";
import Visibility from "@material-ui/icons/Visibility";
import { Beenhere } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
// Custom Components
import NotificationAction from "../NotificationAction";
import history from "utils/history";
// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";
import useDropdownStyles from "assets/jss/components/dropDownStyles";
import { NotificationActionDiv } from "./style";

// Icons
import IconImg from "components/Sidebar/IconImg";
import NDicon from "assets/icons/notifD.png";
import { Typography } from "@material-ui/core";

const NavNotification = () => {
  const token = Cookies.get("SPPOtoken");
  const classes = useNavbarStyles();
  const classesTwo = useDropdownStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [count, setCount] = useState(0);
  const [countUnread, setCountUnread] = useState(0);
  const [values, setValues] = useState();
  const [read, setRead] = useState([]);
  const [readloading, setreadLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    loadList();
    loadUnreadList();
    // const timer = setInterval(loadList, 600000);
    // return () => clearInterval(timer);
  }, [open, refresh]);

  const loadList = async () => {
    setFetching(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/get-all-notification`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues(response?.data);
      setCount(response?.data?.length);
      const user = Cookies.get("SPPOID");
      let tempArr = [];
      response?.data?.forEach((item) => {
        if (item?.isRead !== []) {
          item?.isRead.forEach((entry) => {
            entry === user && tempArr.push(item.id);
          });
        }
      });
      setRead(tempArr);
      setFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  const loadUnreadList = async () => {
    setFetching(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/get-unread-notification`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCountUnread(response?.data?.length);
      setFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRead = async (id, type) => {
    setreadLoading(true);
    await axios.put(
      `${process.env.REACT_APP_BACKEND_API}/sppo/read-mark-single/notificationID/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    setreadLoading(false);

    loadList();
  };
  const handleReadAll = async (id, type) => {
    setreadLoading(true);
    let i = 0;
    while (i !== count) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/read-mark/notificationID/${values[i]?._id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );
      i++;
      setreadLoading(false);
    }
    loadList();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton
        aria-label="show 0 new notifications"
        color="primary"
        onClick={handleClick}
      >
        <div className={classes.imgDiv}>
          {countUnread > 0 ? (
            <Badge badgeContent={countUnread} color="error">
              <IconImg className={classes.icons} icon={NDicon} />
            </Badge>
          ) : (
            <IconImg className={classes.icons} icon={NDicon} />
          )}
        </div>
      </IconButton>

      <div>
        <Popper
          open={Boolean(open)}
          anchorEl={anchorEl}
          placement="bottom-end"
          className={classesTwo.toolTip}
          transition
        >
          <Paper>
            <ClickAwayListener onClickAway={handleCloseProfile}>
              <div>
                <NotificationActionDiv>
                  <h3>Notifications</h3>
                  <NotificationAction
                    values={values}
                    handleReadAll={handleReadAll}
                    setRefresh={setRefresh}
                  />
                </NotificationActionDiv>

                <MenuList role="menu">
                  {values?.length > 0 ? (
                    values?.map(
                      (list, index) =>
                        index < 10 && (
                          <MenuItem
                            className={classesTwo.dropdownItem}
                            key={index}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{ display: "block" }}
                                onClick={() =>
                                  history.push("/sppo/view-all-notifications")
                                }
                              >
                                <div style={{ display: "flex" }}>
                                  {!list?.sppo_read &&
                                    moment().diff(
                                      moment(list?.createdAt),
                                      "days"
                                    ) <= 0 && (
                                      <Brightness1
                                        color="primary"
                                        fontSize="sm"
                                        style={{ marginRight: 5 }}
                                      />
                                    )}
                                  {list?.notification}
                                </div>
                                <Typography variant="subtitle2">
                                  {moment(list?.createdAt).fromNow()}
                                </Typography>
                              </div>
                              {!list?.sppo_read ? (
                                <MenuItem
                                  style={{
                                    backgroundColor: "#e0e1e2",
                                    color: "black",
                                    borderRadius: "50pt",
                                    marginLeft: 20,
                                  }}
                                  onClick={() => handleRead(list._id, list.id)}
                                >
                                  {" "}
                                  {readloading ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <Visibility />
                                  )}
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  style={{
                                    backgroundColor: "green",
                                    borderRadius: "50pt",
                                    marginLeft: 20,
                                  }}
                                >
                                  {" "}
                                  <Beenhere style={{ color: "white" }} />
                                </MenuItem>
                              )}
                            </div>
                          </MenuItem>
                        )
                    )
                  ) : (
                    <Header as="h3" textAlign="center">
                      nothing to show
                    </Header>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      color="purple"
                      loading={fetching}
                      disabled={fetching}
                      onClick={() =>
                        history.push("/sppo/view-all-notifications")
                      }
                      style={{ width: "90%", margin: 10 }}
                    >
                      View all notifications
                    </Button>
                  </div>
                </MenuList>
              </div>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
      {/* ) : null} */}
    </Fragment>
  );
};

export default NavNotification;
