import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";
import LinguistProfile from "components/PublicProfileLIN/ApprovalPublicProfile/LinguistProfile";

const ManagePublicProfile = () => {
 
  return (
    <MasterLayout>
      <Manager>
        <LinguistProfile />
      </Manager>
    </MasterLayout>
  );
};

export default ManagePublicProfile;
