import CustomTextInput from "components/Common/CustomTextInput";
import React, { useRef, useState, useEffect } from "react";
import { Button, Dimmer, Loader, Table } from "semantic-ui-react";
import { Card, Icon, Image, Header, Label, Checkbox } from "semantic-ui-react";
import { IconButton, Input, TextField } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import history from "utils/history";
import RichTextEditor from "react-rte";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "axios";
import moment from "moment";
import CustomTime from "components/Common/CustomTime";

const Details = () => {
  let sigPad = useRef({});
  const [signature, setSignature] = useState(false);
  const [sign, setSign] = useState("");
  const [text, setText] = useState();
  const [projectReport, setProjectReport] = useState(null);
  const [linguist, setLinguist] = useState(null);
  const { id } = useParams();
  const token = Cookies.get("LINtoken");
  const [declaration, setDeclaration] = useState(false);
  const [details, setDetails] = useState({
    project_name: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    request_details: "",
    target_language_level: "",
    security_clearence: "",
    word_count: "",
    visual_formatting: "",
    certification: "",
    post_certification: "",
    project_report: RichTextEditor.createEmptyValue(),
    signature: "",
    supervisor_fullname: "",
    date: "",
    feedbackByLin: "",
    remarksByLin: "",
    loader: false,
  });
  const uploadRef = useRef();
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const todayDate = moment().format("DD/MM/YYYY");

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getProjectReport = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/private-booking-view/bookingID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );

        setProjectReport(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancel");
        } else {
          console.log(error.response);
        }
      }
    };

    getProjectReport();

    return () => {
      source.cancel();
    };
  }, []);

  const handleClose = (e) => {
    e.preventDefault();
    setSignature(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = () => {
    const temp = sigPad.current.toDataURL();
    setDetails({ ...details, signature: temp });
    setSignature(false);
  };

  const handleText = (value) => {
    setText(value.toString("markdown"));
    setDetails({ ...details, project_report: value });
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });
    const fd = new FormData();

    const file = new File([details.signature], "signature", {
      type: "image/png",
    });

    fd.append("project_name", details.project_name);
    fd.append("start_date", details.start_date);
    fd.append("end_date", details.end_date);
    fd.append("start_time", details.start_time);
    fd.append("end_time", details.end_time);
    fd.append(
      "requested_start_date",
      moment(projectReport.startdateArray[0]).format("DD/MMM/YYYY")
    );
    fd.append(
      "requested_end_date",
      moment(projectReport.enddateArray[0]).format("DD/MMM/YYYY")
    );
    fd.append("requested_start_time", projectReport.starttimeArray[0]);
    fd.append("requested_end_time", projectReport.endtimeArray[0]);
    fd.append("request_details", details.request_details);
    fd.append(
      "target_language_level",
      projectReport.target_expertise_level_required
    );
    fd.append("security_clearence", projectReport.security_clearance);
    fd.append("project_report", text);
    fd.append("signatureByLin", file);
    fd.append("supervisor_fullname", "");
    fd.append("date", moment().format("YYYY/MM/DD"));
    fd.append("feedbackByLin", parseInt(details.feedbackByLin));
    fd.append("duration", projectReport.total_time_period);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/private-Report/bookingID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDetails({ ...details, loader: false });
      history.push(`/linguist/private-client/closed-assignments`);
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  return projectReport ? (
    <div>
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          // onEnd = {handleSave}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            basic
            color="purple"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="purple">
            Save
          </Button>
        </div>
      </CustomModal>
      <CustomTextInput
        label="Assignment reference number"
        value={projectReport.id}
      />
      {/* <CustomTextInput label= 'Translation / Training /Other Project name' value= 'abc' /> */}

      {/* Table */}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center">Requested</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actual</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              Project start date<b style={{ color: "red" }}>*</b>:{" "}
            </Table.Cell>
            <Table.Cell>
              {moment(projectReport.startdateArray[0]).format("DD/MMM/YYYY")}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <TextField
                type="date"
                value={details.start_date}
                onChange={(e) =>
                  setDetails({ ...details, start_date: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Project start time<b style={{ color: "red" }}>*</b>:{" "}
            </Table.Cell>
            <Table.Cell>{projectReport.starttimeArray[0]}</Table.Cell>
            <Table.Cell>
              <CustomTime
                value={details.start_time}
                onChange={(e) =>
                  setDetails({ ...details, start_time: e.target.value })
                }
                width="45%"
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Project completion date<b style={{ color: "red" }}>*</b>:{" "}
            </Table.Cell>
            <Table.Cell>
              {moment(projectReport.enddateArray[0]).format("DD/MMM/YYYY")}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <TextField
                type="date"
                value={details.end_date}
                onChange={(e) =>
                  setDetails({ ...details, end_date: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Project end time<b style={{ color: "red" }}>*</b>:{" "}
            </Table.Cell>
            <Table.Cell>{projectReport.endtimeArray[0]}</Table.Cell>
            <Table.Cell>
              <CustomTime
                value={details.end_time}
                onChange={(e) =>
                  setDetails({ ...details, end_time: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell>
              {projectReport.target_expertise_level_required}
            </Table.Cell>
            <Table.Cell>
              {projectReport.linguistID.target_language_level}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Security clearance/s</Table.Cell>
            <Table.Cell>
              {projectReport.security_clearance
                ? projectReport.security_clearance
                : "N/A"}
            </Table.Cell>
            <Table.Cell>
              {projectReport.security_clearance
                ? projectReport.security_clearance
                : "N/A"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">
        Project Report<b style={{ color: "red" }}>*</b>
      </Header>
      <RichTextEditor value={details.project_report} onChange={handleText} />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Checkbox
                checked={declaration}
                onChange={(e, value) => setDeclaration(value.checked)}
                label="I declare the information given by me is true/accurate and complete."
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Signature{" "}
              {history.location.pathname.includes("linguist") ? (
                <IconButton onClick={() => setSignature(true)}>
                  <EditIcon />
                </IconButton>
              ) : null}
              {details.signature && (
                <img
                  style={{ width: "100px", height: "50px" }}
                  src={details.signature}
                  alt=""
                />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Full name:{" "}
              {projectReport.linguistID.FirstName +
                " " +
                projectReport.linguistID.LastName}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {todayDate}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Feedback<b style={{ color: "red" }}>*</b>:
              <div>
                <Rating
                  name="simple-controlled"
                  value={details.feedbackByLin}
                  onChange={(e) =>
                    setDetails({ ...details, feedbackByLin: e.target.value })
                  }
                />
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button
        onClick={() => uploadRef.current.click()}
        basic
        style={{ backgroundColor: "purple", color: "white" }}
      >
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          loading={details.loader}
          onClick={handleSubmit}
          disabled={
            !declaration ||
            !details.project_report ||
            !details.start_date ||
            !details.start_time ||
            !details.end_date ||
            !details.end_time ||
            !details.signature ||
            !details.feedbackByLin
          }
          color="purple"
        >
          Submit
        </Button>
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="large">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
