import React, { useState } from "react";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import defaultAxios from "utils/axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
import { EMAIL_REGEX } from "data";

const FormOne = () => {
  const classes = useFormStyles();

  const [data, setData] = useState({
    labelName: "",
    labelOne: "",
    labelTwo: "",
    labelThree: "",
    labelFour: "",
    labelFourR: "",
    labelFive: "",
  });

  const {
    labelName,
    labelOne,
    labelTwo,
    labelThree,
    labelFour,
    labelFourR,
    labelFive,
  } = data;
  const [notValid, setNotValid] = useState(false);
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleEmailChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const isAllow =
    !data.labelName ||
    !data.labelOne ||
    !data.labelTwo ||
    !data.labelThree ||
    !data.labelFour ||
    !data.labelFourR ||
    !data.labelFive;

  const handleSubmit = async (e) => {
    // console.log(data);
    const token = Cookies.get("token");

    const sendData = {
      labelName: data.labelName || "-",
      labelOne: data.labelOne || "-",
      labelTwo: data.labelTwo || "-",
      labelThree: data.labelThree || "-",
      labelFour: data.labelFour || "-",
      labelFourR: data.labelFourR || "-",
      labelFive: data.labelFive || "-",
    };
    try {
      const response = await defaultAxios.post(
        `${process.env.REACT_APP_BACKEND_API}/admin/post/rate_card`,
        sendData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      swal({
        title: "Success",
        text: response?.data?.message,
        icon: "success",
        buttons: false,
      });
      setData({
        labelName: "",
        labelOne: "",
        labelTwo: "",
        labelThree: "",
        labelFour: "",
        labelFourR: "",
        labelFive: "",
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        label="Label Name"
        name="labelName"
        value={data.labelName}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Label One"
        name="labelOne"
        value={data.labelOne}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Label Two"
        name="labelTwo"
        value={data.labelTwo}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Label Three"
        name="labelThree"
        value={data.labelThree}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Label Four"
        name="labelFour"
        value={data.labelFour}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Label FourR"
        name="labelFourR"
        value={data.labelFourR}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Label Five"
        name="labelFive"
        value={data.labelFive}
        width="100%"
        required
      />

      <div className={classes.buttonFlex}>
        <CustomButton disabled={isAllow} text="Send" onClick={handleSubmit} />
      </div>
    </form>
  );
};

export default FormOne;
