import React, { useState } from "react";

// Material Components
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";

// Custom Components
import CustomLabel from "./CustomLabel";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

const CustomUpload = ({ label, moreInfo, required, width, ...rest }) => {
  const classes = useFormStyles({ width });


const [fileName, setFilename] = useState('Choose File');

  return (
    <div className={classes.customUpload}>
      <FormLabel component="legend" className={classes.formLabel}>
        <CustomLabel label={label} moreInfo={moreInfo} required= {required}/>
      </FormLabel>
      <input
        {...rest}
        //accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
      />
      <label htmlFor="contained-button-file">
        <Button variant="outlined" color="primary" component="span">
          {fileName}
        </Button>
      </label>
    </div>
  );
};

export default CustomUpload;
