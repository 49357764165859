import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ListInvoice from "components/CashClient/Invoices/UnpaidInvoices/InvoiceList";
import InvoiceOptions from 'components/CashClient/Invoices/InvoiceOptions'


const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  return (
    <Cashclient>
      <CashClientLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <p className={classes.cardTitleWhite}>Unpaid Invoices</p>
                <InvoiceOptions />
              </CardHeader>
            </Card>
            <Card>
              <CardBody>
                <ListInvoice />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </CashClientLayout>
    </Cashclient>
  );
};

export default UnconfirmedAssignmentsPage;
