import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";

import Cookies from "js-cookie";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import { COUNTRY_DATA, COUNTRY_DATA_SEM_UI, GENDER_DATA } from "data";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("LINtoken");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handlePhone = (value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("title", data.title || "-");
    fd.append("LastName", data.LastName || "-");
    fd.append("mobile_no", data.mobile_no || "-");
    fd.append("Nationality", data.Nationality || "-");
    fd.append("other_nationality", data.other_nationality || "-");
    fd.append("sex", data.sex);
    fd.append("other_email", data.other_email || "-");
    fd.append("reference_name", data.reference_name || "-");
    fd.append("reference_work", data.reference_work || "-");
    fd.append("online_calling_details", data.online_calling_details || "-");
    fd.append("business_phone_no", data.business_phone_no);

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/profile/update`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="title"
            onChange={handleChange}
            label="Title"
            edit={edit.value}
            value={data.title}
          />
          <CustomTextInput
            name="FirstName"
            label="First Name"
            value={data.FirstName}
          />
          <CustomTextInput
            name="LastName"
            onChange={handleChange}
            label="Last Name"
            edit={edit.value}
            value={data.LastName}
          />
          <CustomTextInput
            name="AddressLine1"
            label="House number / name, Street name"
            value={data.AddressLine1}
          />
          <CustomTextInput
            name="AddressLine2"
            label="Address Line 2"
            value={data.AddressLine2}
          />
          <CustomTextInput name="City" label="Town / City" value={data.City} />
          <CustomTextInput
            name="Region"
            label="County / Region"
            value={data.Region}
          />
          <CustomTextInput
            name="PostalCode"
            label="Post / Zip code"
            value={data.PostalCode}
          />
          <CustomTextInput
            name="Country"
            label="Country"
            value={data.Country}
          />
          {edit.value ? (
            <CustomTextInput
              name="Nationality"
              onChange={handleChange}
              name="Nationality"
              select={COUNTRY_DATA}
              svalue={data.Nationality}
              label="Current Nationality"
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              name="Nationality"
              onChange={handleChange}
              label="Current Nationality"
              value={data.Nationality}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              onChange={handleDropDown}
              name="other_nationality"
              label="Other Nationalities"
              data={COUNTRY_DATA_SEM_UI}
              dropdown={true}
            />
          ) : (
            <CustomTextInput
              name="other_nationality"
              onChange={handleChange}
              label="Other Nationalities"
              value={data.other_nationality}
            />
          )}
          <CustomTextInput label="Date of birth" value={data.Birthday} />
          <CustomTextInput label="Primary email" value={data.email} />

          {edit.value ? (
            <CustomTextInput
              name="sex"
              onChange={handleChange}
              label="Gender"
              radio={true}
              rvalue={data.sex}
              data={GENDER_DATA}
            />
          ) : (
            <CustomTextInput
              name="sex"
              onChange={handleChange}
              label="Gender"
              value={data.sex}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              name="mobile_no"
              onChange={handleMobile}
              label="Mobile Number"
              pvalue={data.mobile_no}
              mobile={true}
            />
          ) : (
            <CustomTextInput
              name="mobile_no"
              onChange={handleChange}
              label="Mobile Number"
              value={data.mobile_no}
            />
          )}
          <CustomTextInput
            name="other_email"
            onChange={handleChange}
            label="Other email address"
            edit={edit.value}
            value={data.other_email}
          />
          {edit.value ? (
            <CustomTextInput
              name="business_phone_no"
              onChange={handlePhone}
              label="Business phone number"
              pvalue={data.business_phone_no}
              mobile={true}
            />
          ) : (
            <CustomTextInput
              name="business_phone_no"
              onChange={handleChange}
              label="Business phone number"
              value={data.business_phone_no}
            />
          )}
          <CustomTextInput
            name="online_calling_details"
            onChange={handleChange}
            label="Skype, Zoom or other online calling details"
            edit={edit.value}
            value={data.online_calling_details}
          />
          <CustomTextInput
            name="reference_name"
            onChange={handleChange}
            label="Reference One (Work)"
            edit={edit.value}
            value={data.reference_name}
          />
          <CustomTextInput
            name="reference_work"
            onChange={handleChange}
            label="Reference Two (Education)"
            edit={edit.value}
            value={data.reference_work}
          />

          <a href={`/linguist/update-linguist-documents`}>
            Upload Documents
          </a>

        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
