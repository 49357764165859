import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import swal from "sweetalert";
//Axios
import Axios from "axios";

//Cookies
import Cookies from "js-cookie";
//history
import history from "utils/history";
import "./style.css";

const Action = ({ id, setReload }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("token");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteCashbook = async (value) => {
    if (history.location.pathname.includes("linguist")) {
      try {
        const response = await Axios.delete(
          `${process.env.REACT_APP_BACKEND_API}/linguists/cash-book/${id}`
        );
        swal({
          icon: "success",
          text: `${response?.data.message}`,
          buttons: false,
        }).then(function () {
          setReload(Math.random());
        });
      } catch (error) {
        console.log(error.response);
      }
    } else if (history.location.pathname.includes("sppo")) {
      try {
        const response = await Axios.delete(
          `${process.env.REACT_APP_BACKEND_API}/sppo/cash-book/${id}`
        );
        swal({
          icon: "success",
          text: `${response?.data.message}`,
          buttons: false,
        }).then(function () {
          setReload(Math.random());
        });
      } catch (error) {
        console.log(error.response);
      }
    } else if (history.location.pathname.includes("admin")) {
      try {
        const response = await Axios.delete(
          `${process.env.REACT_APP_BACKEND_API}/admin/cash-book/${id}`
        );
        swal({
          icon: "success",
          text: `${response?.data.message}`,
          buttons: false,
        }).then(function () {
          setReload(Math.random());
        });
      } catch (error) {
        console.log(error.response);
      }
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure you want to delete this?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          deleteCashbook(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          minWidth: "10px",
          paddingLeft: "0px",
        }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

export default Action;
