import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";
import { Button } from "semantic-ui-react";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "40%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearhInput = ({ text, setText, autoFill }) => {
  const classes = useStyles();
  return (
    <div>
      <Paper
        component="form"
        onSubmit={(e) => e.preventDefault()}
        className={classes.root}
      >
        <InputBase
          className={classes.input}
          placeholder="Find a linguist by (ID)"
          inputProps={{ "aria-label": "search google maps" }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Button color="purple" onClick={autoFill}>
          Auto Fill
        </Button>
      </Paper>
    </div>
  );
};

export default SearhInput;
