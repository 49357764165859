import React, { Fragment } from "react";

// Material Core Components
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Material Icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormTwo from "./FormTwo";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import history from "utils/history";
import SelectClient from "./selectClient";
const InhouseInterpreterTel = ({ title }) => {
  const classes = useEmployeeStyles();

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  In-house Interpreting Assignment Booking (Telephonic)
                </Typography>
              </div>
              <SelectClient />
              <FormTwo />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default InhouseInterpreterTel;
