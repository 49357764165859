import React from "react";

// Custom Components
import CommonCard from "components/Common/CommonCard";
import { PayPalButton } from "react-paypal-button-v2";
import paypalImage from "assets/icons/paypall-buttton.jpg";

const PromotionalMessage = () => {
  return (
    <div style={{ margin: "5px" }}>
      <CommonCard title="Be part of us!">
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=3MP6JGA34VSLQ"
          target="_blank"
        >
          <img style={{ width: "100%" }} src={paypalImage} alt="" />
        </a>
      </CommonCard>
    </div>
  );
};

export default PromotionalMessage;
