import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { findClientBill } from "redux/actions/billsAction";

const Action = ({ id, name, clientref }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleFind = e => {
    dispatch(findClientBill(true))
    setAnchorEl(false)
  }

  const handleAllInvoice = e => {
    history.push(`/admin/client-direct-quotes/${id}/${name}/${clientref}`)
  }


  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleAllInvoice}>See all quotes (of this client)</MenuItem>
      </Menu>
    </div>
  );
};

export default Action;
