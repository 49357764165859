import React from "react";

// Custom Component
import MasterLayout from "layout/MasterLayout";

const EmployeeLaout = ({ children }) => {
  return <MasterLayout layoutType="EMPLOYEE_CLIENT">{children}</MasterLayout>;
};

export default EmployeeLaout;
