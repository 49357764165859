import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//Custom styles
import useFormStyles from "assets/jss/components/auth/formStyle";

//Custom imports
import CustomSelect from "components/Common/CustomSelect";
import CustomInputSingle from "components/Common/CustomInputSingle";
import swal from "sweetalert";
import Axios from "axios";
import Cookies from "js-cookie";
import { Button, Icon } from "@material-ui/core";
import { useToasts } from "react-toast-notifications";

const FormOne = () => {
  const classes = useFormStyles();

  const { data } = useSelector((state) => state.ibtReducer);
  const { client_name } = data;
  const [inhouseClients, setInhouseClients] = useState([]);
  const { addToast } = useToasts();

  const getInhouseClients = async (e) => {
    const token = Cookies.get("token");
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/pregistration`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInhouseClients(response?.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const CLIENT_NAME = inhouseClients?.map(
    (client) => `${client.id} - ${client.first_name} ${client.last_name}`
  );

  useEffect(() => {
    getInhouseClients();
  }, []);

  const [state, setState] = useState({
    client_name: "",
    first_name: "",
    last_name: "",
    email: "",
  });

  const FETCH_EMAIL = inhouseClients.filter((client) =>
    state.client_name.includes(client.id)
  )[0]?.primary_email;
  const FETCH_FIRSTNAME = inhouseClients.filter((client) =>
    state.client_name.includes(client.id)
  )[0]?.first_name;
  const FETCH_LASTNAME = inhouseClients.filter((client) =>
    state.client_name.includes(client.id)
  )[0]?.last_name;
  const FETCH_ID = inhouseClients.filter((client) =>
    state.client_name.includes(client.id)
  )[0]?._id;

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    const token = Cookies.get("token");
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-form/pregistration`,
        {
          ...state,
          email: FETCH_EMAIL,
          first_name: FETCH_FIRSTNAME,
          last_name: FETCH_LASTNAME,
          clientID: FETCH_ID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `Email succesfully sent!`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/admin/send-form-registration-select";
      });
      setState({
        ...state,
        client_name: "",
        first_name: "",
        last_name: "",
        email: "",
        note: "",
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomSelect
          onChange={handleChange}
          label="Select Linguist"
          width="100%"
          data={CLIENT_NAME}
          value={state.client_name}
          name="client_name"
          required
        />

        <CustomInputSingle
          onChange={handleChange}
          label="First Name(s)"
          name="first_name"
          value={FETCH_FIRSTNAME}
          width="100%"
          shrink
        />

        <CustomInputSingle
          onChange={handleChange}
          label="Last Name"
          name="last_name"
          value={FETCH_LASTNAME}
          width="100%"
          shrink
        />

        <CustomInputSingle
          onChange={handleChange}
          label="Email"
          name="email"
          value={FETCH_EMAIL}
          width="100%"
          shrink
        />

        <CustomInputSingle
          onChange={handleChange}
          label="Note"
          name="note"
          value={data.note}
          width="100%"
        />

        <div className={classes.buttonFlex}>
          <Button
            disabled={!state.client_name}
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.button}
            endIcon={<Icon>send</Icon>}
          >
            Send
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormOne;
