import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import history from "utils/history";
import CustomDate from "components/Common/CustomDate";
import CustomPhone from "components/Common/CustomPhone";
import moment from "moment";
import React, { Fragment, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Header } from "semantic-ui-react";
import LandingPageLayout from "layout/LandingPageLayout";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import Typography from "@material-ui/core/Typography";
import swal from "sweetalert";
import Axios from "axios";

function INCDeleteAccountRequest() {
  const classes = useEmployeeStyles();
  const [sign, setSign] = useState("");
  const [dValid, setDValid] = useState(false);
  const [open, setOpen] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  let sigPad = useRef({});
  const matches = useMediaQuery("(max-width:768px)");
  const [clientID, setClientID] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [passNumber, setPassNumber] = useState("");
  const [Birthday, setBirthday] = useState("");
  const [mobile_no, setMobile_no] = useState(0);
  const [linID, setLinID] = useState("");
  const [linName, setLinName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    history.push("/");
  };
  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = () => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);
    setSignatureModal(false);
  };

  const handleSubmit = async () => {
    const values = {
      clientID,
      FirstName,
      LastName,
      passNumber,
      Birthday,
      mobile_no,
      linID,
      linName,
      email,
      reason,
    };
    try {
      const respnose = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse/delete-request`,
        values
      );
      setOpen(false);
      if (
        respnose &&
        respnose.data !==
          "The information you have provided doesn't match our record, please check and amend as appropriate."
      ) {
        swal({
          icon: "success",
          text: "Sent to Linguist for review.",
          buttons: false,
        }).then(function () {
          window.location = "/";
        });
      } else {
        swal({
          icon: "warning",
          text: respnose.data,
          buttons: false,
        });
      }
    } catch {
      swal({
        icon: "error",
        text: "User ID does not match with details.",
        buttons: false,
      });
    }
  };

  return (
    <LandingPageLayout>
      {!matches ? (
        <Box mt={8} mb={8} ml={32} mr={32}>
          <Card>
            <div
              className={`${classes.textCenter} ${classes.title}`}
              style={{ margin: 10 }}
            >
              <CustomAvatar />
              <Typography component="h1" variant="h5">
                Account Removal Request (INC)
              </Typography>
              <Typography variant="subtitle2"></Typography>
            </div>

            {/* Dialog */}
            <Dialog
              onClose={handleClose}
              aria-labelledby="simple-dialog-title"
              open={open}
            >
              <DialogContent>
                <Dialog open={signatureModal} handleClose={handleClose}>
                  <SignatureCanvas
                    penColor="black"
                    //onEnd = {handleSave}
                    ref={sigPad}
                    canvasProps={{
                      width: "max-content",
                      height: 200,
                      className: "sigCanvas",
                    }}
                  />
                  <DialogActions>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={handleClear}
                        style={{ marginRight: "5px" }}
                        color="purple"
                      >
                        Clear
                      </Button>
                      <Button onClick={handleSave} color="grey">
                        Save
                      </Button>
                    </div>
                  </DialogActions>
                </Dialog>
              </DialogContent>

              <DialogContent style={{ width: 400 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Signature:
                  </Header>
                  {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
                </div>
                <Button onClick={() => setSignatureModal(true)} color="purple">
                  Add Signature
                </Button>
                <Header varient="body1">
                  Date: {moment(Date.Now).format("DD/MM/YYYY")}
                </Header>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSubmit} disabled={!sign} color="blue">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>

            <CardBody>
              {" "}
              {!matches ? (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Client / User ID"
                      name="ID"
                      onChange={(e) => setClientID(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Pass number / memorable word"
                      name="Pass"
                      onChange={(e) => setPassNumber(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label="First Name (s)"
                      name="FirstName"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Last Name"
                      name="LastName"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <CustomDate
                      style={{ margin: 0, padding: 0, width: "100%" }}
                      label=" Date of Birth"
                      name="DoB"
                      onChange={(e) => setBirthday(e)}
                    ></CustomDate>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <CustomPhone
                      style={{ width: "100%", marginTop: 13 }}
                      required
                      label="Mobile No. *"
                      name="MobileNo"
                      onChange={(e) => setMobile_no(e)}
                    ></CustomPhone>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      type="email"
                      label="Primary Email"
                      name="PrimaryEmail"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label="ID of the Linguist managing your account?"
                      name="LinguistID"
                      onChange={(e) => setLinID(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Name of the Linguist managing your account?"
                      name="LinguistName"
                      onChange={(e) => setLinName(e.target.value)}
                    />
                  </div>

                  <TextField
                    required
                    multiline
                    rows={1}
                    rowsMax={2}
                    label="Reason for your request?"
                    name="DeleteReason"
                    onChange={(e) => setReason(e.target.value)}
                    fullWidth
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        required
                        onClick={() => setDValid(!dValid)}
                      />
                    }
                    label="Please note that, account deleting process cannot be performed if you have an active task, assignment, quote, invoice, or a job to be completed, upon completion of all active assignment or job and payment requirements satisfied LC will start the process of deleting by suspending or archiving the account for six months. After suspension period, if no dispute or request for re-activation received your account will be deleted permanently. If you have no active task this maybe take place right away. All personal data including business data will be lost, Are you sure you want to proceed with deleting this account? * "
                    style={{
                      width: 100 + "%",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{ width: "15%", marginRight: 10 }}
                      onClick={() => setOpen(true)}
                      disabled={
                        !dValid ||
                        clientID === "" ||
                        FirstName === "" ||
                        LastName === "" ||
                        passNumber === "" ||
                        !mobile_no ||
                        linID === "" ||
                        linName === "" ||
                        email === "" ||
                        reason === ""
                      }
                      color="red"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        width: "20%",
                        background: "#4caf50 none",
                        color: "white",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      required
                      label=" Client / User ID:"
                      name="ID"
                      onChange={(e) => setClientID(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Pass number / memorable word"
                      name="Pass"
                      onChange={(e) => setPassNumber(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label="First Name (s)"
                      name="FirstName"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Last Name"
                      name="LastName"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <CustomDate
                      style={{ margin: 0, padding: 0, width: "100%" }}
                      label=" Date of Birth"
                      name="DoB"
                      onChange={(e) => setBirthday(e)}
                    ></CustomDate>
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <CustomPhone
                      style={{ width: "100%", marginTop: 13 }}
                      required
                      label="Mobile No. *"
                      name="MobileNo"
                      onChange={(e) => setMobile_no(e)}
                    ></CustomPhone>
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      type="email"
                      label="Primary Email"
                      name="PrimaryEmail"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label="ID of the Linguist managing your account?"
                      name="LinguistID"
                      onChange={(e) => setLinID(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Name of the Linguist managing your account?"
                      name="LinguistName"
                      onChange={(e) => setLinName(e.target.value)}
                    />
                  </div>

                  <TextField
                    required
                    multiline
                    rows={1}
                    rowsMax={2}
                    label="Reason for your request?"
                    name="DeleteReason"
                    onChange={(e) => setReason(e.target.value)}
                    fullWidth
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        required
                        onClick={() => setDValid(!dValid)}
                      />
                    }
                    label="Please note that, account deleting process cannot be performed if you have an active task, assignment, quote, invoice, or a job to be completed, upon completion of all active assignment or job and payment requirements satisfied LC will start the process of deleting by suspending or archiving the account for six months. After suspension period, if no dispute or request for re-activation received your account will be deleted permanently. If you have no active task this maybe take place right away. All personal data including business data will be lost, Are you sure you want to proceed with deleting this account? * "
                    style={{
                      width: 100 + "%",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Button
                      style={{ width: "40%" }}
                      onClick={() => setOpen(true)}
                      disabled={
                        !dValid ||
                        clientID === "" ||
                        FirstName === "" ||
                        LastName === "" ||
                        passNumber === "" ||
                        !mobile_no ||
                        linID === "" ||
                        linName === "" ||
                        email === "" ||
                        reason === ""
                      }
                      color="red"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        width: "45%",
                        background: "#4caf50 none",
                        color: "white",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </Fragment>
              )}
            </CardBody>
          </Card>
        </Box>
      ) : (
        <Box m={2}>
          <Card>
            <div
              className={`${classes.textCenter} ${classes.title}`}
              style={{ margin: 10 }}
            >
              <CustomAvatar />
              <Typography component="h1" variant="h5">
                Account Removal Request (INC)
              </Typography>
              <Typography variant="subtitle2"></Typography>
            </div>

            {/* Dialog */}
            <Dialog
              onClose={handleClose}
              aria-labelledby="simple-dialog-title"
              open={open}
            >
              <DialogContent>
                <Dialog open={signatureModal} handleClose={handleClose}>
                  <SignatureCanvas
                    penColor="black"
                    //onEnd = {handleSave}
                    ref={sigPad}
                    canvasProps={{
                      width: "max-content",
                      height: 200,
                      className: "sigCanvas",
                    }}
                  />
                  <DialogActions>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={handleClear}
                        style={{ marginRight: "5px" }}
                        color="purple"
                      >
                        Clear
                      </Button>
                      <Button onClick={handleSave} color="grey">
                        Save
                      </Button>
                    </div>
                  </DialogActions>
                </Dialog>
              </DialogContent>

              <DialogContent style={{ width: 400 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Signature:
                  </Header>
                  {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
                </div>
                <Button onClick={() => setSignatureModal(true)} color="purple">
                  Add Signature
                </Button>
                <Header varient="body1">
                  Date: {moment(Date.Now).format("DD/MM/YYYY")}
                </Header>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSubmit} disabled={!sign} color="blue">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>

            <CardBody>
              {" "}
              {!matches ? (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Client / User ID"
                      name="ID"
                      onChange={(e) => setClientID(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Pass number / memorable word"
                      name="Pass"
                      onChange={(e) => setPassNumber(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label="First Name (s)"
                      name="FirstName"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Last Name"
                      name="LastName"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <CustomDate
                      style={{ margin: 0, padding: 0, width: "100%" }}
                      label=" Date of Birth"
                      name="DoB"
                      onChange={(e) => setBirthday(e)}
                    ></CustomDate>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <CustomPhone
                      style={{ width: "100%", marginTop: 13 }}
                      required
                      label="Mobile No. *"
                      name="MobileNo"
                      onChange={(e) => setMobile_no(e)}
                    ></CustomPhone>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      type="email"
                      label="Primary Email"
                      name="PrimaryEmail"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label="ID of the Linguist managing your account?"
                      name="LinguistID"
                      onChange={(e) => setLinID(e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      marginBottom: 5,
                    }}
                  >
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Name of the Linguist managing your account?"
                      name="LinguistName"
                      onChange={(e) => setLinName(e.target.value)}
                    />
                  </div>

                  <TextField
                    required
                    multiline
                    rows={1}
                    rowsMax={2}
                    label="Reason for your request?"
                    name="DeleteReason"
                    onChange={(e) => setReason(e.target.value)}
                    fullWidth
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        required
                        onClick={() => setDValid(!dValid)}
                      />
                    }
                    label="Please note that, account deleting process cannot be performed if you have an active task, assignment, quote, invoice, or a job to be completed, upon completion of all active assignment or job and payment requirements satisfied LC will start the process of deleting by suspending or archiving the account for six months. After suspension period, if no dispute or request for re-activation received your account will be deleted permanently. If you have no active task this maybe take place right away. All personal data including business data will be lost, Are you sure you want to proceed with deleting this account? * "
                    style={{
                      width: 100 + "%",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{ width: "15%", marginRight: 10 }}
                      onClick={() => setOpen(true)}
                      disabled={
                        !dValid ||
                        clientID === "" ||
                        FirstName === "" ||
                        LastName === "" ||
                        passNumber === "" ||
                        !mobile_no ||
                        linID === "" ||
                        linName === "" ||
                        email === "" ||
                        reason === ""
                      }
                      color="red"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        width: "20%",
                        background: "#4caf50 none",
                        color: "white",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      required
                      label=" Client / User ID:"
                      name="ID"
                      onChange={(e) => setClientID(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Pass number / memorable word"
                      name="Pass"
                      onChange={(e) => setPassNumber(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label="First Name (s)"
                      name="FirstName"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label=" Last Name"
                      name="LastName"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <CustomDate
                      style={{ margin: 0, padding: 0, width: "100%" }}
                      label=" Date of Birth"
                      name="DoB"
                      onChange={(e) => setBirthday(e)}
                    ></CustomDate>
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <CustomPhone
                      style={{ width: "100%", marginTop: 13 }}
                      required
                      label="Mobile No. *"
                      name="MobileNo"
                      onChange={(e) => setMobile_no(e)}
                    ></CustomPhone>
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      type="email"
                      label="Primary Email"
                      name="PrimaryEmail"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label="ID of the Agent Linguist or SPPO managing your account?"
                      name="LinguistID"
                      onChange={(e) => setLinID(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      label="Full Name of the Agent Linguist or SPPO managing your account?"
                      name="LinguistName"
                      onChange={(e) => setLinName(e.target.value)}
                    />
                  </div>

                  <TextField
                    required
                    multiline
                    rows={1}
                    rowsMax={2}
                    label="Reason for your request?"
                    name="DeleteReason"
                    onChange={(e) => setReason(e.target.value)}
                    fullWidth
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        required
                        onClick={() => setDValid(!dValid)}
                      />
                    }
                    label="Please note that, account deleting process cannot be performed if you have an active task, assignment, quote, invoice, or a job to be completed, upon completion of all active assignment or job and payment requirements satisfied LC will start the process of deleting by suspending or archiving the account for six months. After suspension period, if no dispute or request for re-activation received your account will be deleted permanently. If you have no active task this maybe take place right away. All personal data including business data will be lost, Are you sure you want to proceed with deleting this account? * "
                    style={{
                      width: 100 + "%",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Button
                      style={{ width: "40%" }}
                      onClick={() => setOpen(true)}
                      disabled={
                        !dValid ||
                        clientID === "" ||
                        FirstName === "" ||
                        LastName === "" ||
                        passNumber === "" ||
                        !mobile_no ||
                        linID === "" ||
                        linName === "" ||
                        email === "" ||
                        reason === ""
                      }
                      color="red"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        width: "45%",
                        background: "#4caf50 none",
                        color: "white",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </Fragment>
              )}
            </CardBody>
          </Card>
        </Box>
      )}
    </LandingPageLayout>
  );
}

export default INCDeleteAccountRequest;
