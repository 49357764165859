
import React from 'react'

function PageNotFound() {
    return (
        <div>
            <h1>404</h1>
            <h3>Page Not Found</h3>
        </div>
    )
}

export default PageNotFound;