import { Header, Rating } from "semantic-ui-react";
import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import Axios from "axios";
import Cookies from "js-cookie";
import "./Verified.css";
import { useSelector } from "react-redux";
import { Checkbox } from "semantic-ui-react";

function Verified() {
  const [timesheetRating, setTimesheetRating] = useState(0);
  const [reportRating, setReportRating] = useState(0);
  const { data } = useSelector((state) => state.updatePPLReducer);
  const token = Cookies.get("LINtoken");

  const getRating = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/timesheet-feedback-list/linID/${data._id}`
      );

      setTimesheetRating(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectReportRating = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/report-feedback-list/linID/${data._id}`
      );

      setReportRating(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRating();
    getProjectReportRating();
  }, []);

  let totalFeedbackByClient = 0,
    avgFeedbackByClient = 0;
  let totalTransFeedbackByClient = 0,
    avgTransFeedbackByClient = 0;

  // calculate interpreting feedback by client
  for (let i = 0; i <= timesheetRating?.length - 1; i++) {
    {
      totalFeedbackByClient += timesheetRating[i].feedbackByClient;
      avgFeedbackByClient = totalFeedbackByClient / timesheetRating?.length;
    }
  }

  // calculate translation feedback by client
  for (let i = 0; i <= reportRating?.length - 1; i++) {
    {
      totalTransFeedbackByClient += reportRating[i].feedbackByClient;
      avgTransFeedbackByClient =
        totalTransFeedbackByClient / reportRating?.length;
    }
  }

  const updateRatingSection = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/allow-public-profile-rating-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const hideRatingSection = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/hide-public-profile-rating-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePublicProfile = (value) => {
    if (data.verified) {
      if (!data.rating_section) {
        swal(
          "Rating section of your profile will be public. Are you sure you want to make this section of your profile public?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              updateRatingSection(value);

            default:
              break;
          }
        });
      } else {
        swal(
          "Are you sure you want to revoke public view permission for this section?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              hideRatingSection(value);

            default:
              break;
          }
        });
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header color="purple" as="h2">
          Client Ratings
        </Header>
        <Checkbox
          toggle
          checked={data?.rating_section}
          onChange={() => handlePublicProfile("rating_section")}
        />
      </div>

      {/* Feedback by client (timesheet) */}
      <Header as="h3">Interpreting</Header>
      <Rating
        maxRating={5}
        rating={avgFeedbackByClient}
        icon="star"
        size="massive"
      />

      {/* Feedback by client (project report) */}
      <Header as="h3">Translation and other services</Header>
      <Rating
        maxRating={5}
        rating={avgTransFeedbackByClient}
        icon="star"
        size="massive"
      />
    </div>
  );
}

export default Verified;
