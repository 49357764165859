import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import { Button, Header } from "semantic-ui-react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import { CURRENT_COUNTRY } from "data";
import Axios from "axios";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import { changeSPPORInput, updateSPPORStep } from "redux/actions/spporAction";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPhone from "components/Common/CustomPhone";
import Cookies from "js-cookie";

const PageOne = ({ setCompleted }) => {
  const { data } = useSelector((state) => state.spporReducer);
  const dispatch = useDispatch();
  const [details, setDetails] = useState(false);
  const fileInput = useRef(null);
  const token = Cookies.get("SPPOtoken");

  const {
    business_logo,
    business_name,
    legal_business_name,
    business_phone_no,
    business_address_line1,
    business_address_line2,
    business_city,
    business_postal_code,
    business_region,
    business_country,
    business_website,
    company_registration_no,
    charity_registration_no,
    vat_number,
    vat_country,
  } = data;

  const classes = useFormStyles();

  const getLinguistDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDetails(response?.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  const handleNext = () => {
    dispatch(updateSPPORStep(2));
    setCompleted(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeSPPORInput({
        name,
        value: value,
      })
    );
  };

  const handlePhone = (value) => {
    dispatch(
      changeSPPORInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const handleBusinessLogo = (e) => {
    dispatch(
      changeSPPORInput({
        name: "business_logo",
        value: e.target.files[0],
      })
    );
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeSPPORInput({
        name: "business_country",
        value,
      })
    );
  };

  const isAllow =
    !business_name ||
    !legal_business_name ||
    !business_phone_no ||
    !business_address_line1 ||
    !business_city ||
    !business_postal_code ||
    !business_country;

  return (
    <div>
      <h3 style={{ textAlign: "center", paddingTop: "5px" }}>
        Business / Organisation Details
      </h3>
      <form className={classes.form} noValidate autoComplete="off">
        <input
          accept="image/jpeg, image/png"
          ref={fileInput}
          onChange={(e) => handleBusinessLogo(e)}
          type="file"
          style={{ display: "none" }}
          name="business_logo"
          required
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
            Upload Business Logo (max 1mb)
          </Header>
          <div>
            <Button onClick={handleClick}>Upload</Button>
          </div>
          {business_logo && (
            <Header style={{ margin: "0", textAlign: "center" }} as="h5">
              <Header style={{ margin: "0" }} as="h1">
                <InsertPhotoIcon fontSize="large" />
              </Header>
              {business_logo.name}
              <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                business_logo.size / 1024
              ).toFixed(2)}kb`}</Header>
              <Header
                style={{ margin: "0" }}
                as="h5"
              >{`Last Modified: ${business_logo?.lastModifiedDate}`}</Header>
            </Header>
          )}
        </div>
        <CustomBigInputSingle
          onChange={handleChange}
          label="Business / Organisation Name"
          moreInfo="Trading as"
          name="business_name"
          value={business_name}
          required
          placeholder="Trading name of your business / organisation"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          width="100%"
          label="Legal Business Name"
          moreInfo="If different"
          name="legal_business_name"
          value={legal_business_name}
          required
          placeholder="Registered Name of The Business (if any)"
        />

        <CustomInputSingle
          // onChange={handleChange}
          // label="Primary / Business Email"
          width="100%"
          name="email"
          value={details.email}
          disabled
          placeholder="e.g. example@example.com"
        />
        <br />
        <CustomPhone
          onChange={handlePhone}
          label="Telephone Number"
          placeholder="Telephone Number* (Please select country then type number e.g. +44 7307 000000)"
          width="100%"
          name="business_phone_no"
          value={business_phone_no}
          required
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="House Number / Name, Street Name"
          moreInfo="Business address"
          width="100%"
          name="business_address_line1"
          value={business_address_line1}
          required
          placeholder="e.g. Flat/Building Name, 101 Example Street"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Address Line 2"
          width="100%"
          name="business_address_line2"
          value={business_address_line2}
          placeholder="Additional Address Information"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Town / City"
          width="100%"
          name="business_city"
          value={business_city}
          required
          placeholder="Your Village, Town or City Name"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="County / Region"
          width="100%"
          name="business_region"
          value={business_region}
          placeholder="Your County, Division or State Name"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Post / Zip Code"
          width="100%"
          name="business_postal_code"
          value={business_postal_code}
          required
          placeholder="Your Post Code, Zip Code or Post Office Name"
        />
        <CustomAutoSelect
          onChange={handleAutoCompleteCO}
          label="Country"
          moreInfo="Current country of residence"
          width="100%"
          name="business_country"
          value={business_country}
          data={CURRENT_COUNTRY}
          required
        />

        <CustomInputSingle
          onChange={handleChange}
          label="Business / Organisation Website"
          width="100%"
          name="business_website"
          value={business_website}
          placeholder="e.g. www.YourCompanyWebsite.com"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="Company Registration Number"
          moreInfo="If registered"
          width="100%"
          name="company_registration_no"
          value={company_registration_no}
          placeholder="if registered please provide CRN"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="Charity Registration Number"
          moreInfo="If registered"
          width="100%"
          name="charity_registration_no"
          value={charity_registration_no}
          placeholder="if registered please provide Charity Number and Registered Country"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="VAT / GST Number"
          moreInfo="If registered"
          width="100%"
          name="vat_number"
          value={vat_number}
          placeholder="if registered for VAT / GST Please provide number"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="VAT / GST Country Name"
          width="100%"
          name="vat_country"
          value={vat_country}
          placeholder="if registered for VAT / GST Please provide Country Details"
        />

        <div className={classes.buttonFlex}>
          <CustomButton disabled={isAllow} text="Next" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default PageOne;
