import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from "@material-ui/core";
import history from 'utils/history'

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import UnconfirmedOptions from "components/AccountClient/Assignments/UnconfirmAssignments/UnconfirmOptions";

const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  return (
    <Accountclient>
      <AccountClientLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }} color="primary">
                <p className={classes.cardTitleWhite}>Unconfirmed Assignments</p>
                <IconButton color='inherit' onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <UnconfirmedOptions />
              </CardHeader>
            </Card>

          </GridItem>
        </GridContainer>

      </AccountClientLayout>
    </Accountclient>
  );
};

export default UnconfirmedAssignmentsPage;
