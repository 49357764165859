import React, { useState, Fragment } from "react";

// Material imports
import { Avatar, IconButton } from "@material-ui/core";
import { Checkbox } from "semantic-ui-react";

// Material Icons
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// Custom imports
import { useStylesAvatar } from "assets/jss/layouts/styleP";
import ProfileModal from "assets/jss/Modals/ProfileModal/ProfileModal";

import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import CustomModal from "components/Common/CustomModal";
import Axios from "axios";
import history from "utils/history";
import publicProfileOne from "assets/images/publicProfileOne.jpg";
import publicProfileTwo from "assets/images/public profile-06.jpg";
import publicProfileThree from "assets/images/publicProfileThree.jpg";
import publicProfileFour from "assets/images/new sppo and pp photo-03.jpg";

import Slider from "../../Sliders/Slider";

//Redux imports
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import swal from "sweetalert";
import { Button, Header } from "semantic-ui-react";
// Share buttons

function ManageProfile() {
  const { id } = useParams();
  const [openProfileUpdate, setOpenProfileUpdate] = useState(false);
  const [upload, setUpload] = useState(false);
  const { data } = useSelector((state) => state.updateProfileReducer);
  const token = Cookies.get("token");
  const sliderImg = [
    publicProfileOne,
    publicProfileTwo,
    publicProfileThree,
    publicProfileFour,
  ];
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const classes_avatar = useStylesAvatar();

  const handleClose = () => {
    setUpload(false);
  };

  const EnableadminAllowPublicProfile = async () => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/allow-public-profile/linID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: "Approved",
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "adminAllowPublicProfile",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableadminAllowPublicProfile = async () => {
    setLoader(true);
    try {
      swal({
        icon: "success",
        text: "Disabled public profile",
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "adminAllowPublicProfile",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePublicProfile = (value) => {
    if (data.verified) {
      if (!data.adminAllowPublicProfile) {
        swal(
          "Have you visited this Linguist's Public Profile Preview page and checked all details? Please make sure Linguist information matching documents and contain NO personal contact details within the profile.",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              EnableadminAllowPublicProfile(value);

            default:
              break;
          }
        });
      } else {
        swal("Are you sure you want to disable this public profile?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              DisableadminAllowPublicProfile(value);

            default:
              break;
          }
        });
      }
    }
  };

  return (
    <Fragment>
      <ProfileModal
        openProfileUpdate={openProfileUpdate}
        setOpenProfileUpdate={setOpenProfileUpdate}
      />

      <div className="app__header__card">
        <CustomModal
          open={upload}
          handleClose={handleClose}
          style={{ height: "50%", width: "50%" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>Upload</p>
            <p style={{ marginTop: "10px" }}>Upload cover photo</p>
            <input type="file" name="logo" />
          </div>
        </CustomModal>
        <div
          style={{ position: "relative", marginBottom: "60px" }}
          className="relative mb-8"
        >
          <Slider data={sliderImg} />
          <div className="profile_avatar">
            <Avatar
              onContextMenu={(e) => e.preventDefault()}
              // onClick={() => setOpenProfileUpdate(true)}
              className={classes_avatar.root}
              src={`${process.env.REACT_APP_BACKEND_API}/linguists/public-profile-photo/${id}`}
              alt="P"
            />
          </div>
        </div>

        <br />
        <div style={{ padding: "32px", display: "flex", alignItems: "center" }}>
          <Button
            color="purple"
            onClick={() =>
              history.push(`/admin/view-linguist-profile/${data?._id}`)
            }
          >
            ⇦ View Profile
          </Button>

          <Button
            color="purple"
            onClick={() =>
              history.push(
                `/admin/protected/public/profile/${data?.source_language.toString().split(" ")[0]
                }/${data?.target_language.toString().split(" ")[0]}/${data?._id
                }`
              )
            }
          >
            Preview Public Profile
          </Button>
        </div>
        {data?.verified && data?.profile_update ? (
          <div
            style={{ padding: "32px", display: "flex", alignItems: "center" }}
          >
            <Checkbox
              toggle
              checked={data?.adminAllowPublicProfile}
              onChange={() => handlePublicProfile("adminAllowPublicProfile")}
            />
            <h3 style={{ margin: "0" }}>Approve Publication</h3>
          </div>
        ) : null}

        <div
          style={{
            padding: " 0 32px 32px 32px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p style={{ fontSize: "28px", margin: "0" }}>
              {data?.title} {data?.FirstName} {data?.LastName}{" "}
              {data?.verified ? (
                <IconButton>
                  <VerifiedUserIcon />
                </IconButton>
              ) : null}
            </p>
            <p
              style={{ fontSize: "24px", margin: "0" }}
              className="text-xl font-light"
            >
              {data?.public_profile_position == "Other"
                ? data?.Other_position
                : data?.public_profile_position}{" "}
            </p>
            <br />
            <p
              style={{ fontSize: "24px", margin: "0" }}
              className="text-xl font-light"
            >
              LC Linguist ID: {data?.id}
            </p>

            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <LocationOnIcon />
              <Header
                style={{ margin: "0", marginLeft: "5px" }}
                color="grey"
                size="medium"
              >
                {data.Country}
              </Header>
            </div>
            <br />

            <Header as="h3">Source and target languages</Header>
            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.target_language} {data?.target_language_level}
            </p>
            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.target_language_1} {data?.target_language_level_1}
            </p>
            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.target_language_2} {data?.target_language_level_2}
            </p>

            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.source_language} {data?.source_language_level}
            </p>
            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.source_language_1} {data?.source_language_level_1}
            </p>
            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.source_language_2} {data?.source_language_level_2}
            </p>

            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.language_combinations_1} {data?.other_language_level_1}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ManageProfile;
