import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import LandingPageLayout from "layout/LandingPageLayout";
// Custom Styles
import SendForm from "components/Admin/CrmPanel/Leads/RemovalForm/RemovalForm";

const CashClient = () => {
 
    return (
        <LandingPageLayout>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <SendForm />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </LandingPageLayout>
    );
};

export default CashClient;
