import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";

import { Box } from '@material-ui/core';
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from '@material-ui/icons/Close';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import TextField from "@material-ui/core/TextField";
import { Button } from "semantic-ui-react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";


const Action = ({ load, id, active }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const token = Cookies.get("EMPtoken");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setShowNote(false)
    };
    const handleBack = () => {
        setViewNote(false);
    };
    const handleShow = () => {
        setShowNote(!showNote)
    }

    const handleChange = async (e) => {
        setPay({ ...pay, [e.target.name]: e.target.value })
    }

    const [showNote, setShowNote] = React.useState(false);
    const [viewNote, setViewNote] = React.useState(false)

    const [pay, setPay] = React.useState({
        comment: ''
    })
    const [edit, setEdit] = React.useState({
        value: false,
        loader: false
    })


    const handleWriteComment = async () => {
        setEdit({ ...edit, loader: true })
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/employee/write-task-comment/${id}`, {
                comment: pay.comment
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                buttons: false,
                text: `${response?.data.message}`
            })
            load();
            setPay({ ...pay, comment: '', loader: false })
            setEdit({ ...edit, loader: false })
        } catch (error) {
            swal({
                icon: 'error',
                buttons: false,
                text: 'Failed '
            })
        }
    }




    const UpdateLowStatus = async () => {
        try {
            const response = await Axios.put(
                `${process.env.REACT_APP_BACKEND_API}/employee/task-priority-Low/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            swal({
                icon: "success",
                text: `${response?.data.message}`,
                buttons: false,
            })
            load();
        } catch (error) {
            console.log(error.response);
        }
    };

    const handleMarkLow = async (value) => {
        swal("Are you sure?", {
            dangerMode: true,
            buttons: {
                cancel: true,
                catch: {
                    text: "Yes!",
                    value: "catch",
                },
            },
        }).then((type) => {
            switch (type) {
                case "cancel":
                    break;

                case "catch":
                    UpdateLowStatus(value);

                default:
                    break;
            }
        });
    };





    const UpdateMediumStatus = async () => {
        try {
            const response = await Axios.put(
                `${process.env.REACT_APP_BACKEND_API}/employee/task-priority-Medium/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            swal({
                icon: "success",
                text: `${response?.data.message}`,
                buttons: false,
            })
            load();
        } catch (error) {
            console.log(error.response);
        }
    };

    const handleMarkMedium = async (value) => {
        swal("Are you sure?", {
            dangerMode: true,
            buttons: {
                cancel: true,
                catch: {
                    text: "Yes!",
                    value: "catch",
                },
            },
        }).then((type) => {
            switch (type) {
                case "cancel":
                    break;

                case "catch":
                    UpdateMediumStatus(value);

                default:
                    break;
            }
        });
    };





    const UpdateHighStatus = async () => {
        try {
            const response = await Axios.put(
                `${process.env.REACT_APP_BACKEND_API}/employee/task-priority-High/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            swal({
                icon: "success",
                text: `${response?.data.message}`,
                buttons: false,
            })
            load();
        } catch (error) {
            console.log(error.response);
        }
    };

    const handleMarkHigh = async (value) => {
        swal("Are you sure?", {
            dangerMode: true,
            buttons: {
                cancel: true,
                catch: {
                    text: "Yes!",
                    value: "catch",
                },
            },
        }).then((type) => {
            switch (type) {
                case "cancel":
                    break;

                case "catch":
                    UpdateHighStatus(value);

                default:
                    break;
            }
        });
    };





    const UpdateDoneStatus = async () => {
        try {
            const response = await Axios.put(
                `${process.env.REACT_APP_BACKEND_API}/employee/task-priority-Done/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            swal({
                icon: "success",
                text: `${response?.data.message}`,
                buttons: false,
            })
            load();
        } catch (error) {
            console.log(error.response);
        }
    };

    const handleMarkDone = async (value) => {
        swal("Are you sure?", {
            dangerMode: true,
            buttons: {
                cancel: true,
                catch: {
                    text: "Yes!",
                    value: "catch",
                },
            },
        }).then((type) => {
            switch (type) {
                case "cancel":
                    break;

                case "catch":
                    UpdateDoneStatus(value);

                default:
                    break;
            }
        });
    };




    const UpdateClose = async () => {
        try {
            const response = await Axios.put(
                `${process.env.REACT_APP_BACKEND_API}/task-closed/taskID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            swal({
                icon: "success",
                text: `${response?.data.message}`,
                buttons: false,
            })
            load();
        } catch (error) {
            console.log(error.response);
        }
    };

    const handleTaskClose = async (value) => {
        swal("Are you sure?", {
            dangerMode: true,
            buttons: {
                cancel: true,
                catch: {
                    text: "Yes!",
                    value: "catch",
                },
            },
        }).then((type) => {
            switch (type) {
                case "cancel":
                    break;

                case "catch":
                    UpdateClose(value);

                default:
                    break;
            }
        });
    };




    const DeleteTask = async () => {
        try {
            const response = await Axios.delete(
                `${process.env.REACT_APP_BACKEND_API}/task-delete/taskID/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            swal({
                icon: "success",
                text: `${response?.data.message}`,
                buttons: false,
            })
            load();
        } catch (error) {
            swal({
                icon: "error",
                text: `${error.response?.data}`,
                buttons: "Ok",
                dangerMode: true,
            });
        }
    };

    const handleDelete = async (value) => {
        swal("Are you sure?", {
            dangerMode: true,
            buttons: {
                cancel: true,
                catch: {
                    text: "Yes!",
                    value: "catch",
                },
            },
        }).then((type) => {
            switch (type) {
                case "cancel":
                    break;

                case "catch":
                    DeleteTask(value);

                default:
                    break;
            }
        });
    };




    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                Action
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {active ? <>
                    <MenuItem onClick={handleMarkLow}>Mark As Low Priority</MenuItem>
                    <MenuItem onClick={handleMarkMedium}>Mark As Medium Priority</MenuItem>
                    <MenuItem onClick={handleMarkHigh}>Mark As High Priority</MenuItem>
                    <MenuItem onClick={handleMarkDone}>Mark As Done</MenuItem>
                    <MenuItem onClick={handleShow}>Add Comment</MenuItem>
                    <MenuItem onClick={handleTaskClose}>Close Task</MenuItem>
                </> : null}
                {!active ?
                    <MenuItem onClick={handleDelete}>Delete Note</MenuItem>
                    : null}
            </Menu>





            {/* ADD NOTE */}
            {showNote ? (<Dialog open={showNote} >
                {!viewNote ?
                    <div style={{ width: 600 }}>
                        <DialogTitle style={{ borderBottom: 'rgb(0,0,0,.2) solid 1px' }}>
                            <div style={{ display: 'flex', alignItems: 'space-between', width: 100 + "%" }}>
                                Add Comment

                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                onChange={handleChange} value={pay.comment}
                                id="standard-textarea"
                                label="Type here... (max 144 ch.)"
                                placeholder="Write your comment here..."
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 144 }}
                                name="comment"
                                required
                                style={{ marginBottom: 15, width: 100 + "%" }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10, marginBottom: 20 }}>


                                <Button onClick={handleClose} color="purple" variant="outlined" startIcon={<CloseIcon />}>
                                    Close
                                </Button>

                                <Button disabled={!pay.comment} onClick={handleWriteComment} color="purple" variant="outlined" startIcon={<SaveIcon />}>

                                    Add Comment
                                    {edit.loader && <div class="ui active inline loader"></div>}
                                </Button>

                            </div>
                        </DialogContent>
                    </div>
                    :
                    <div style={{ width: 600 }}>
                        <DialogTitle style={{ borderBottom: 'rgb(0,0,0,.2) solid 1px' }}>
                            Add Comment
                        </DialogTitle>
                        <DialogContent style={{ overflowY: 'scroll' }}>
                            <Box pb={1}>
                                <Card>

                                    <CardBody>
                                        <h5 style={{ marginBottom: 15, width: 100 + "%" }} >some text</h5>
                                    </CardBody>
                                </Card>
                            </Box>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10, marginBottom: 20 }}>
                                <Button onClick={handleBack} color="purple" variant="outlined" startIcon={< ArrowLeftIcon />}>
                                    Back
                                </Button>
                                <Button onClick={handleClose} color="purple" variant="outlined" startIcon={<CloseIcon />}>
                                    Close
                                </Button>

                            </div>
                        </DialogContent>
                    </div>
                }

            </Dialog>) : null}


        </div>
    );
};

export default Action;
