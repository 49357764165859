import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment";
import { Rating } from "@material-ui/lab";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 3000,
  },
});

const Items = ({ values }) => {
  const classes = useStyles();

  return (
    <>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width="5">
                  Appointment date(s) / booking requested for
                </Table.HeaderCell>
                <Table.HeaderCell width="5">
                  Appointment / booking start time
                </Table.HeaderCell>
                <Table.HeaderCell width="5">
                  Appointment / booking duration
                </Table.HeaderCell>
                {/* if bulk_interpreting == true then need to show also monthly_duration and weekly_work_duration */}
                {values?.bulk_interpreting ? (
                  <>
                    <Table.HeaderCell width="5">
                      Monthly duration
                    </Table.HeaderCell>
                    <Table.HeaderCell width="5">
                      Weekly duration
                    </Table.HeaderCell>
                  </>
                ) : null}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell width="5">Timezone</Table.Cell>
                <Table.Cell width="5">{values.timezone}</Table.Cell>
              </Table.Row>

              <Table.HeaderCell>
                {values.startdateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(st).format("DD-MMM-YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {values.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {values?.durationArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.HeaderCell>

              {/* if bulk_interpreting == true then need to show here monthly_duration and weekly_work_duration */}
              {values?.bulk_interpreting ? (
                <>
                  <Table.HeaderCell>
                    {/* {values?.month_duration
                      .filter((dt) => dt != "")
                      .map((st) => ( */}
                    <Table.Row>
                      <Table.Cell>{values?.month_duration}</Table.Cell>
                    </Table.Row>
                    {/* ))} */}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {values?.weekly_work_duration
                      .filter((dt) => dt != "")
                      .map((st) => (
                        <Table.Row>
                          <Table.Cell>{st}</Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.HeaderCell>
                </>
              ) : null}

              <Table.Row>
                <Table.Cell width="5">Total Duration</Table.Cell>
                <Table.Cell width="5"></Table.Cell>
                <Table.Cell width="5">
                  {values.gethour
                    ? `${values.gethour} Hours`
                    : values.total_time_period
                    ? `${values.total_time_period} Hours`
                    : `${values.total_Duration} Hours`}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width="5">
                  Date and time of booking this assignment (London Time)
                </Table.Cell>
                <Table.Cell width="5">
                  {moment(values.createdAt)
                    .tz("Europe/London")
                    .format("DD-MMM-YYYY, HH:mm")}
                </Table.Cell>
                <Table.Cell width="5"></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </GridItem>
      </GridContainer>
      <br />
    </>
  );
};

export default Items;
