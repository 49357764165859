import React, { Fragment, useState } from "react";

// Custom Components
import CustomTable from "components/Common/CustomTable";
import CustomModal from "components/Common/CustomModal";
import NoteInput from "./NoteInput";
import ViewNote from "./ViewNote";

// Custom Styles
// import useCustomTableStyles from "assets/jss/customTable";

const TableTwo = () => {
  // const classes = useCustomTableStyles();

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("input");

  const handleOpen = () => {
    setOpen(true);
  };

  const columns = [
    { field: "assignment", headerName: "Assignment ID", width: 130 },
    { field: "type", headerName: "Document Type", width: 120 },
    {
      field: "language",
      headerName: "Languages",
      width: 120,
      renderCell: (params) => {
        const values = params.value.split(", ");
        return (
          <>
            {values.map((value, index) => (
              <span key={index}>{value}</span>
            ))}
          </>
        );
      },
    },
    { field: "deadline", headerName: "Deadline", width: 120 },
    { field: "words", headerName: "Expertise Level Required", width: 120 },
    { field: "value", headerName: "Visual Formatting", width: 120 },
    { field: "value", headerName: "Estimated Word Counts", width: 120 },
    { field: "value", headerName: "Certification", width: 120 },
    { field: "value", headerName: "Estimate", width: 120 },
  ];

  const rows = [
    {
      id: 1,
      assignment: "Translation",
      type: "Medical",
      language: "Bengali, Hindi",
      deadline: "11 June 20",
      words: "1700",
      value: "€30",
      action: "Urgent",
      status: "Not Allocated",
      note: "note",
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <CustomTable rows={rows} columns={columns} />
      <CustomModal open={open} handleClose={handleClose}>
        {modalType === "input" ? <NoteInput /> : null}
        {modalType === "view" ? <ViewNote /> : null}
      </CustomModal>
    </Fragment>
  );
};

export default TableTwo;
