import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import history from "utils/history";

const Action = ({
  id, reqQuote,
  LinQuoteID,
  payStatus, accepts,
  reportID, report,
  date, adminSig,
  invoice, invoiceID,
  load, handlePaid, handlePaidBankPay, cancel_request, cancel, bank_pay
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("token");
  const [showallocate, setShow] = React.useState(false);
  const [showConfirmation, setConfirmation] = React.useState(false);

  const [showNote, setShowNote] = React.useState(false);
  const [viewNote, setViewNote] = React.useState(false);

  const [showMessage, setShowMessage] = React.useState(false);
  const [viewMessage, setViewMessage] = React.useState(false);

  const [totalnote, setTotalNote] = React.useState(false);
  const [totalmessage, setTotalMessage] = React.useState(false);

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const [pay, setPay] = React.useState({
    admin_booking_notes: "",
  });

  const handleBack = () => {
    setViewNote(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShow(false);
    setConfirmation(false);
    setShowNote(false);
    setShowMessage(false);
  };

  const handleShow = () => {
    setShowNote(!showNote);
  };

  const handleShowMessage = () => {
    setShowMessage(!showMessage);
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  const handleNote = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/writeNote-quote-CAC/quoteID/${id}`,
        {
          admin_booking_notes: pay.admin_booking_notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "Note successfully added",
      });
      getTotalNote();
      setPay({ ...pay, admin_booking_notes: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleMessage = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/write-message-quote-cac/quoteID/${id}`,
        {
          admin_booking_notes: pay.admin_booking_notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "Message Sent!!!",
      });
      getTotalMessage();
      setPay({ ...pay, admin_booking_notes: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const getTotalNote = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-Note-quote-cac/quoteID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalMessage = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-message-quote-cac/quoteID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalMessage(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalNote();
    getTotalMessage();
  }, []);

  const archiveQuote = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-quote-request-cashclient/quoteID/${id}`
      );
      swal({
        icon: "success",
        text: `Quotation moved to archive folder!!!`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleArchiveQuote = async (e, id) => {
    swal(`Are you sure you want to archive this quote?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          archiveQuote(value);

        default:
          break;
      }
    });
  };

  const ProvidedQuoteArchive = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-lin-quote-Clients/linQuoteID/${LinQuoteID}`
      );
      swal({
        icon: "success",
        text: `${response?.data?.message}`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleProvidedQuoteArchive = async (e, id) => {
    swal(`Are you sure you want to archive this quote?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          ProvidedQuoteArchive(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {accepts &&
          !cancel_request &&
          !cancel &&
          payStatus &&
          !report &&
          !invoice ? (
          <MenuItem
            onClick={() =>
              history.push(`byClientQuote/create-project-report/${LinQuoteID}`)
            }
          >
            Close & Project Report
          </MenuItem>
        ) : null}

        {report && !adminSig && !cancel_request && !cancel ? (
          <MenuItem
            onClick={() =>
              history.push(`byClientQuote/remark-project-report/${reportID}`)
            }
          >
            Remark Project Report
          </MenuItem>
        ) : null}

        {report && !cancel_request && !cancel ? (
          <MenuItem
            onClick={() =>
              history.push(`/admin/view-project-report/${reportID}`)
            }
          >
            View Project Report
          </MenuItem>
        ) : null}

        {invoice && !cancel_request && !cancel ? (
          <MenuItem>
            <Link to={`/admin/view-invoice/${invoiceID}`}>View Invoice</Link>
          </MenuItem>
        ) : null}

        {accepts && !cancel_request && !cancel && !report && !bank_pay ? (
          <MenuItem onClick={handlePaid}>Cancel and Refund</MenuItem>
        ) : null}
        {accepts && cancel_request && !cancel && !report && !bank_pay ? (
          <MenuItem onClick={handlePaid}>Cancel and Refund</MenuItem>
        ) : null}

        {accepts && !cancel_request && !cancel && !report && bank_pay ? (
          <MenuItem onClick={handlePaidBankPay}>Cancel and Refund</MenuItem>
        ) : null}
        {accepts && cancel_request && !cancel && !report && bank_pay ? (
          <MenuItem onClick={handlePaidBankPay}>Cancel and Refund</MenuItem>
        ) : null}
        <MenuItem onClick={handleShow}>Add note ({totalnote})</MenuItem>
        <MenuItem onClick={handleShowMessage}>Send Message ({totalmessage}) </MenuItem>

        {reqQuote ?
          <MenuItem onClick={handleArchiveQuote} >Archive (Delete)</MenuItem> : null}
        {/* {ProvidedQuotedate ? */}
        {LinQuoteID ?
          <MenuItem onClick={handleProvidedQuoteArchive} >Archive (Delete)</MenuItem> : null}
        {/* {Cancelled ?
          <MenuItem onClick={handleProvidedQuoteArchive} >Delete</MenuItem> : null} */}
      </Menu>

      {/* ADD NOTE */}
      {showNote ? (
        <Dialog open={showNote}>
          {!viewNote ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Add Note
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.admin_booking_notes}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="admin_booking_notes"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.admin_booking_notes}
                    onClick={handleNote}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Save Note
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Saved Notes
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}

      {/* Send Message */}
      {showMessage ? (
        <Dialog open={showMessage}>
          {!viewMessage ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Send Message
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.admin_booking_notes}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="admin_booking_notes"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.admin_booking_notes}
                    onClick={handleMessage}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Send Message
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Send Message
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}
    </div>
  );
};

export default Action;
