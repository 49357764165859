import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: (props) => ({
    display: "block",
    margin: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    width: `${props.width || "30%"}`,
    marginTop: theme.spacing(2),
    marginBottom: "0px",
    "& .MuiFormControl-root": {
      marginTop: "0",
      width: "100%",
    },
    "& .MuiInputBase-adornedEnd": {
      width: "100%",
    },
  }),
  textField: (props) => ({
    width: `${props.width || "100%"}`,

    "& textarea": {
      "@media (max-width: 480px)": {
        height: `${props.height || "16px"} !important`,
      },
    },
  }),
  input: {
    display: "none",
  },
  customUpload: (props) => ({
    //marginRight: "auto",
    //marginLeft: "auto",
    //width: `${props.width || "100%"}`,
    marginTop: theme.spacing(3),
    // marginLeft: "6px",
    "& .MuiFormLabel-root": {
      paddingBottom: "0px",

      "& div": {
        paddingBottom: "0px",
      },
    },
  }),
  formLabel: {
    paddingBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: "1rem",
  },
  button: {
    marginTop: "1rem",
    marginRight: "1rem",
    width: "100px",
  },
  radioGroup: (props) => ({
    flexDirection: `${props.flexDir || "row !important"}`,
  }),
  buttonFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    "& .MuiFormControl-root": {
      margin: "0px",
      marginTop: "16px",
      "@media (max-width: 480px)": {
        width: "100%",
      },
    },

    "@media (max-width: 480px)": {
      flexDirection: "column",
    },

    "& .MuiButtonBase-root": {
      "@media (max-width: 480px)": {
        margin: "0px",
        marginBottom: "5px",
      },
    },
  },
  multiCheckboxRoot: {
    width: "100%",
  },
  pLabel: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.58)",
    fontWeight: 400,
    paddingLeft: "10px",
  },
  pickerClass: {
    width: "100%",
  },
  rLabel: {
    marginTop: theme.spacing(5),
  },
  tc: {
    marginTop: theme.spacing(3),
    paddingLeft: "8px",
    "& h6": {
      fontSize: "14px",
    },
  },
  flexDiv: {
    display: "flex",
    flexDirection: window.matchMedia("(max-width: 1000px)").matches
      ? "column"
      : "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 5rem",
  },
  timeDiv: {
    textAlign: "right",

    "& .i-btn": {
      //marginRight: "4.5rem",
      marginBottom: "-2rem",
    },
    "& .i-btn-two": {
      margin: "0",
      marginBottom: "-2rem",
    },
  },
});

const useFormStyles = makeStyles(styles);

export default useFormStyles;
