import React, { useEffect, useState } from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import { Accordion, Header, Icon } from "semantic-ui-react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Button } from "semantic-ui-react";
import Box from "@material-ui/core/Box";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { Fragment } from "react";

const Help = () => {
  const token = Cookies.get("LINtoken");
  const classes = useDashboardStyles();
  const [activeIndex, setActiveIndex] = useState("0");
  const matches = useMediaQuery("(max-width:768px)");
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  const [values, setValues] = useState([]);
  const [toturialList, setToturialList] = useState([]);
  const [data, setData] = useState({
    question: "",
  });
  const loadToturialsList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/LIN/get-tutorial`
      );
      setToturialList(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const { question } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    loadToturialsList();
    getAnswer();
  }, []);

  const getAnswer = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/lin-question`
      );
      setValues(response?.data);
    } catch (error) {
      // Copy from other catch's code
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    try {
      const response = Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/ask-question`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        // title: "Success",
        text: `Question sent to the Booking Secretary / Admin Team.`,
        icon: "success",
        buttons: false,
      });
      setData({ ...data, question: "" });
    } catch (error) {
      // Copy from other catch's code
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Box pb={2}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>Linguist Tutorials</p>
                </CardHeader>
                <CardBody>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: matches ? "auto" : "30% 30% 30%",
                      justifyContent: matches ? null : "space-evenly",
                      alignItems: "end",
                    }}
                  >
                    {toturialList.map((list) => (
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <Header as="h4" textAlign="center">
                          {list.tutorial_name}
                        </Header>
                        <Button
                          onClick={() => window.open(list.tutorial_link)}
                          fluid
                          color="youtube"
                        >
                          <Icon name="youtube" /> Watch on youtube
                        </Button>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Box>

            <Box pb={2}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>FAQs</p>
                </CardHeader>

                <Accordion fluid styled>
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    1. What is CAC?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 0}>
                    <p>
                      CAC refers to a Cash Client. We call them Cash Clients as
                      they pay the estimated sum for their service in advance in
                      order to book an interpreter, order translation online or
                      confirm a free quote. However, CAC can request for 'Pay
                      Later' option to be activated, particularly for UK Legal
                      Aid jobs.
                    </p>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    2. What is BAC?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 1}>
                    <p>
                      Billing Account Client is referred as BAC by LC. BAC are
                      mainly large organisations or SMEs that have a contract in
                      place between LC and BAC and pays monthly bill. BAC not
                      required to pay in advance for their booking interpreter,
                      ordering translation or confirming a quote.
                    </p>
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    3. What is a PRC?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 2}>
                    <p>
                      PRC stands for Private Clients of Linguists. Private
                      clients are our Linguists' own clients to whom they
                      provide services in their specialist language while being
                      qualified and registered to provide services for the
                      Linguists Collective (LC).
                    </p>
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 3}
                    index={3}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    4. What is INC?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 3}>
                    <p>
                      An in-house client is a client registered with a LC
                      Linguist who can place orders for services in translation
                      or interpreting via an Agent Linguist. As a Partner
                      Linguist, you’re able to act as an Agent Linguist which
                      allows you to take a booking in any language and an expert
                      linguist from our Collective will deliver the service for
                      you according to the requirements, with the revenue of the
                      job being shared between linguists. If you are adding an
                      INC job to the platform you are the Agent Linguist and job
                      is outgoing INC Assignment market with an up arrow ⇧, and
                      an INC incoming assignment marked with a down arrow ⇩
                      which you can accept to deliver the required service
                      (subject to matching your profile).
                    </p>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 4}
                    index={4}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    5. How to accept an assignment?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 4}>
                    <p>
                      You can accept assignments from your main dashboard. Click
                      on Interpreting Assignment, Translation Assignment or
                      Quote and Other and from those offers you can accept,
                      decline or provide a quote.
                    </p>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 5}
                    index={5}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    6. How do I close an assignment?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 5}>
                    <p>
                      After providing service, you will to go to current
                      assignment list and click on Action List to select 'Close
                      and Timesheet' or 'Close and Project Report', 5 days after
                      closing an assignment or it being accepted by a client,
                      you will then be able to issue an invoice.
                    </p>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 6}
                    index={6}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    7. How do I invoice a PRC?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 6}>
                    <p>
                      You can do from your job list, or you can invoice using a
                      direct invoice to PRCs
                    </p>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 7}
                    index={7}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    8. ID / Badge
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 7}>
                    <object
                      data="/LC-Linguist-Badge-Id.pdf"
                      type="application/pdf"
                      width="100%"
                      height="1000px"
                      aria-label="document"
                    ></object>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 8}
                    index={8}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    9. LC Dress Code
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 8}>
                    <object
                      data="/LC-Dress-Code.pdf"
                      type="application/pdf"
                      width="100%"
                      height="1000px"
                      aria-label="document"
                    ></object>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 9}
                    index={9}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    10. Modern Slavery
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 9}>
                    <object
                      data="/LC-Antislavery.pdf"
                      type="application/pdf"
                      width="100%"
                      height="1000px"
                      aria-label="document"
                    ></object>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 10}
                    index={10}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    11. Trust & Authority
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 10}>
                    <object
                      data="/LC-Trust-Authority.pdf"
                      type="application/pdf"
                      width="100%"
                      height="1000px"
                      aria-label="document"
                    ></object>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 11}
                    index={11}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    12. GDPR and Data Protection Laws
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 11}>
                    <object
                      data="/LC-GDPR and Data Protection Laws.pdf"
                      type="application/pdf"
                      width="100%"
                      height="1000px"
                      aria-label="document"
                    ></object>
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 12}
                    index={12}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    13. Who is who and what expertise do they have?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 12}>
                    <p>
                      Know your linguist’s title, qualification, experience and
                      their relevant skills.
                      <br />
                      You can select the best suitable linguist needed for your
                      assignment.
                    </p>
                    <object
                      data="/WhoIsWho.pdf"
                      type="application/pdf"
                      width="100%"
                      height="1000px"
                      aria-label="document"
                    ></object>
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 13}
                    index={13}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    14. Manual Timesheet
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 13}>
                    <object
                      data="/Linguists Collective time sheet.pdf"
                      type="application/pdf"
                      width="100%"
                      height="1000px"
                      aria-label="document"
                    ></object>
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 14}
                    index={14}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    15. Manual Project Report
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 14}>
                    <object
                      data="/Linguists Collective project report.pdf"
                      type="application/pdf"
                      width="100%"
                      height="1000px"
                      aria-label="document"
                    ></object>
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 15}
                    index={15}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    16. How claim expenses or upload manual timesheet for
                    special project?
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 15}>
                    you can upload from this link{" "}
                    <a
                      href="https://ec.linguistscollective.com"
                      target="_blank"
                    >
                      {" "}
                      here
                    </a>
                  </Accordion.Content>
                </Accordion>
              </Card>
            </Box>

            <Box pb={2}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>Have a question?</p>
                </CardHeader>
                <CardBody>
                  <div>
                    <TextareaAutosize
                      onChange={handleChange}
                      name="question"
                      style={{
                        width: 100 + "%",
                        margin: 10,
                        marginLeft: 0,
                        padding: 10,
                      }}
                      value={data.question}
                      rowsMin={5}
                      placeholder="Type your question here... Please note that your question and answer from Admin Team will be visible to all LC Linguists. Please do not share any confidential, personal, client or assignment details here."
                    />
                    <Button
                      color="purple"
                      disabled={!question}
                      onClick={handleSubmit}
                    >
                      Send
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Box>

            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>
                  Answers from Booking Secretary / Admin Team
                </p>
              </CardHeader>
              <CardBody>
                <Accordion>
                  {values.map((list) => (
                    <Fragment>
                      {list.question && list.answer ? (
                        <Fragment>
                          <Accordion.Title
                            active={activeIndex === list._id}
                            index={list._id}
                            onClick={handleClick}
                          >
                            <Icon name="dropdown" />
                            {list.question}
                          </Accordion.Title>
                          <Accordion.Content active={activeIndex === list._id}>
                            {list.answer}
                          </Accordion.Content>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ))}
                </Accordion>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default Help;
