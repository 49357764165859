import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomSelect from "components/Common/CustomSelect";
import CustomInput from "components/Common/CustomInput";
import CustomUpload from "components/Common/CustomUpload";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import { SIMPLE_QS } from "data";

import {
  autoSaveER,
  changeErInput,
  updateErStep,
} from "redux/actions/erAction";

const FormTwo = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.erReducer);

  const {
    work_in_the_uk,
    ni_number,
    utr_number,
    passport_ni_photo,
    relevant_document_photo,
  } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeErInput({
        name,
        value,
      })
    );
  };

  useEffect(() => {
    const temp = JSON.parse(localStorage.getItem("userER"));
    dispatch(
      autoSaveER({
        temp,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("userER", JSON.stringify(data));
  }, [data]);

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateErStep(3));
  };

  const handleBack = () => {
    dispatch(updateErStep(1));
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomSelect
          onChange={handleChange}
          width="100%"
          label="Do you have the right to work in UK?"
          moreInfo="or the country that this job will require you to be?"
          data={SIMPLE_QS}
          name="work_in_the_uk"
          value={work_in_the_uk}
        />

        <CustomInput
          onChange={handleChange}
          width="100%"
          label="National Insurance(NI) Number, Social Security Number or equivalent (if available)"
          name="ni_number"
          value={ni_number}
        />
        <CustomBigInput
          onChange={handleChange}
          width="100%"
          label="PTR/UTR"
          moreInfo="Personal Tax reference number / (UTR) unique tax reference number(if available)"
          name="utr_number"
          value={utr_number}
        />
        <CustomUpload
          required
          width="100%"
          label="Please upload copy of your passport, national ID, residence permit card here"
          name="passport_ni_photo"
          value={passport_ni_photo}
        />
        <CustomUpload
          width="100%"
          label="Add any other relevant document here"
          name="relevant_document_photo"
          value={relevant_document_photo}
        />
        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton text="Next" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default FormTwo;
