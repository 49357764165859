import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import AdminResetPassword from "components/Admin/AdminResetPassword";


const ResetPassword = () => {
  
  return (
    <Manager>
      <MasterLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <AdminResetPassword />
          </GridItem>
        </GridContainer>
      </MasterLayout>
    </Manager>
  );
};

export default ResetPassword;
