import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Container, Header } from "semantic-ui-react";
import history from "utils/history";
import Axios from "axios";
import Cookies from "js-cookie";

const BISuccessTransPage = () => {
  const { id } = useParams();
  const [session, setSession] = useState(null);
  const token = Cookies.get("CACtoken");
  const [sendMail, setSendMail] = useState(null);

  const changePaymentStatus = async (session) => {
    const id = Cookies.get("cacTransID");
    const { username } = JSON.parse(localStorage.getItem("CACuser"));
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/cashclientinterpreting/sendMailSTRIPE`,
        {
          currency_new: session.line_items.data[0].currency,
          new_total: session.line_items.data[0].amount_total / 100,
          username: username,
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSendMail(response?.data);
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    const retreiveSession = async (e) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API}/retreive/cash-client/checkout/session?id=` +
            id,
          {
            method: "GET",
            //   body: JSON.stringify(y),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((response) => response.json())
          .then((session) => {
            setSession(session);
            changePaymentStatus(session);
          });
      } catch (error) {
        console.log(error.response);
      }
    };

    retreiveSession();
  }, []);
  return session ? (
    <div style={{ backgroundColor: "purple", height: "100%", padding: "30px" }}>
      <Container
        style={{
          backgroundColor: "white",
          color: "purple",
          height: "100%",
          padding: "10px",
          borderRadius: "10px",
        }}
        textAlign="center"
      >
        {/* <Card> */}
        <Header as="h3">
          Your estimate amount is successfully paid. Thank you,{" "}
          {session.line_items.data[0].description}.
        </Header>
        <Header as="h4">
          We'll let you know once linguist has been assigned.
        </Header>
        <Header as="h3">Payment ID - {session.payment_intent}</Header>
        <Header as="h3">Order ID - {session.id}</Header>
        <Header as="h3">
          Total Estimate Amount Paid -{" "}
          {session.line_items.data[0].currency.toUpperCase() +
            " " +
            session.line_items.data[0].amount_total / 100}
        </Header>

        {!sendMail ? (
          <div>
            <img
              style={{ width: "300px", height: "200px", marginBottom: "10px" }}
              src="https://i.pinimg.com/originals/98/64/9a/98649add72e05e3cc1b8ae0e6f553c8e.gif"
              alt=""
            />
          </div>
        ) : (
          <Header as="h3">Check your mail!</Header>
        )}
        <Button
          onClick={() => history.push("/cash-client/dashboard")}
          basic
          color="purple"
        >
          Dashboard
        </Button>
        {/* </Card> */}
      </Container>
    </div>
  ) : null;
};

export default BISuccessTransPage;
