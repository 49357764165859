import React from "react";

// Material Core Components
import TextField from "@material-ui/core/TextField";
import CustomFormControl from "./CustomFormControl";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

const CustomInput = ({ width, height, shrink, ...rest }) => {
  const classes = useFormStyles({ height });

  return (
    <CustomFormControl width={`${width || "100%"}`}>
      <TextField
        multiline
        {...rest}
        className={classes.textField}
        InputLabelProps={{
          shrink: shrink,
        }}
      />
    </CustomFormControl>
  );
};

export default CustomInput;
