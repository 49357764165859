import IBTPageadmin from "pages/admin/IBT";
import IBTPageemp from "pages/employee/IBT";

const IBT = [
  {
    id: '700a',
    type: "form",
    text: "Interpreter assignment booking  (telephonic)",
    path: "admin/interpreter-assignment-booking-telephonic-form",
    component: IBTPageadmin,
  },

{
  id: '700b',
  type: "form",
  text: "Interpreter assignment booking  (telephonic)",
  path: "employee/interpreter-assignment-booking-telephonic-form",
  component: IBTPageemp,
},
];

export default IBT;
