import React, { Fragment } from "react";

// import Total from "./Total";
import TableBody from "./TableBody";

const InputItemsAC = () => {
  return (
    <Fragment>
      <TableBody />
    </Fragment>
  );
};

export default InputItemsAC;
