import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import { COUNTRY_DATA, COUNTRY_DATA_SEM_UI, GENDER_DATA } from "data";
import moment from "moment";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("token");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("title", data.title);
    fd.append("FirstName", data.FirstName);
    fd.append("LastName", data.LastName);
    fd.append("AddressLine1", data.AddressLine1);
    fd.append("AddressLine2", data.AddressLine2);
    fd.append("PostalCode", data.PostalCode);
    fd.append("City", data.City);
    fd.append("Region", data.Region);
    fd.append("Country", data.Country);
    fd.append("Birthday", data.Birthday);
    fd.append("sex", data.sex);
    fd.append("email", data.email);
    fd.append("mobile_no", data.mobile_no);
    fd.append("Nationality", data.Nationality);
    fd.append("other_nationality", data.other_nationality);
    fd.append("passNumber", data.passNumber);
    fd.append("direct_line", data.direct_line);

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/edit-inhouse-client/clientID/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="title"
            onChange={handleChange}
            label="Title"
            edit={edit.value}
            value={data.title}
          />
          <CustomTextInput
            name="FirstName"
            onChange={handleChange}
            label="First Name"
            edit={edit.value}
            value={data.FirstName}
          />
          <CustomTextInput
            name="LastName"
            onChange={handleChange}
            label="Last Name"
            edit={edit.value}
            value={data.LastName}
          />
          <CustomTextInput
            name="AddressLine1"
            onChange={handleChange}
            label="House number / name, Street name"
            edit={edit.value}
            value={data.AddressLine1}
          />
          <CustomTextInput
            name="AddressLine2"
            onChange={handleChange}
            label="Address Line 2"
            edit={edit.value}
            value={data.AddressLine2}
          />
          <CustomTextInput
            name="City"
            onChange={handleChange}
            label="Town / City"
            edit={edit.value}
            value={data.City}
          />
          <CustomTextInput
            name="PostalCode"
            onChange={handleChange}
            label="Post / Zip code"
            edit={edit.value}
            value={data.PostalCode}
          />
          <CustomTextInput
            name="Region"
            onChange={handleChange}
            label="County / Region"
            edit={edit.value}
            value={data.Region}
          />
          {edit.value ? (
            <CustomTextInput
              name="Country"
              onChange={handleChange}
              name="Country"
              select={COUNTRY_DATA}
              svalue={data.Country}
              label="Current Country"
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              name="Country"
              onChange={handleChange}
              label="Current Country"
              value={data.Country}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              name="Nationality"
              onChange={handleChange}
              name="Nationality"
              select={COUNTRY_DATA}
              svalue={data.Nationality}
              label="Current Nationality"
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              name="Nationality"
              onChange={handleChange}
              label="Current Nationality"
              value={data.Nationality}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              onChange={handleDropDown}
              name="other_nationality"
              label="Other Nationalities"
              data={COUNTRY_DATA_SEM_UI}
              dropdown={true}
            />
          ) : (
            <CustomTextInput
              name="other_nationality"
              onChange={handleChange}
              label="Other Nationalities"
              value={data.other_nationality}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              name="Birthday"
              onChange={handleChange}
              dlabel="Date of birth"
              label="Date of birth"
              dvalue={data.Birthday}
              date={true}
            />
          ) : (
            <CustomTextInput
              name="Birthday"
              onChange={handleChange}
              label="Date of birth"
              value={moment(data.Birthday).format("DD/MM/yyyy")}
            />
          )}
          <CustomTextInput
            name="email"
            onChange={handleChange}
            label="Primary email"
            edit={edit.value}
            value={data.email}
          />

          {edit.value ? (
            <CustomTextInput
              name="sex"
              onChange={handleChange}
              label="Gender"
              radio={true}
              rvalue={data.sex}
              data={GENDER_DATA}
            />
          ) : (
            <CustomTextInput
              name="sex"
              onChange={handleChange}
              label="Gender"
              value={data.sex}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              name="mobile_no"
              onChange={handleMobile}
              label="Mobile Number"
              pvalue={data.mobile_no}
              mobile={true}
            />
          ) : (
            <CustomTextInput
              name="mobile_no"
              onChange={handleChange}
              label="Mobile Number"
              value={data.mobile_no}
            />
          )}
          <CustomTextInput
            name="passNumber"
            onChange={handleChange}
            label="Pass number"
            edit={edit.value}
            value={data.passNumber}
          />
          <CustomTextInput
            name="direct_line"
            onChange={handleChange}
            label="Direct Line"
            edit={edit.value}
            value={data.direct_line}
          />
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          disabled={edit.loader}
          onClick={handleUpdate}
          style={{ backgroundColor: "#7C277D" }}
          primary
        >
          Update
        </Button>
        {edit.loader && <div class="ui active inline loader"></div>}
      </div>
    </>
  );
};

export default PersonalDetails;
