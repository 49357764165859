import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  root: (props) => ({
    "& .MuiTableHead-root": {
      background: "#75AAFA",

      "& .MuiTableCell-root": {
        color: "#fff",
        fontSize: props.hfz || "20px",
        minWidth: "130px",
        "@media (max-width: 480px)": {
          fontSize: "16px",
        },
      },
    },
  }),
});

const CustomTabaleWithStyles = ({ rows, headers, rowsKeys, hfz }) => {
  const classes = useStyles({ hfz });

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((cell) => (
              <TableCell key={cell.id} align={`${cell.align || "center"}`}>
                {cell.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {rowsKeys.map((key, index) =>
                index === 0 ? (
                  <TableCell key={index + row.id} component="th" scope="row">
                    {row[key]}
                  </TableCell>
                ) : (
                  <TableCell key={index + row.id} align="center">
                    {row[key]}
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTabaleWithStyles;
