import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
// Custom Components
import CustomSelect from "components/Common/CustomSelect";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";
import CustomInput from "components/Common/CustomInput";
import CustomUpload from "components/Common/CustomUpload";
import CustomRadio from "components/Common/CustomRadio";
import Time from "./Time";
import { changeTransInput } from "redux/actions/transAction";
import history from "utils/history";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import {
  LANG_COMB,
  ITP_SERVICE_REQUIRED,
  TYPE_INTERPRETING,
  SKILL_LEVEL_REQUIRED,
  SECURITY_CLEARANCE_LEVEL,
  CURRENT_COUNTRY,
  GENDER_DATA,
  TIMEZONE,
} from "data";
import Cookies from "js-cookie";

import { Checkbox, FormControlLabel, Icon } from "@material-ui/core";
// import CustomAutoComplete from "components/Common/CustomAutoComplete";
//import Axios from "axios";

import CustomAutoSelect from "components/Common/CustomAutoSelect";

import { getBookingDetails } from "action/newmanager";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import Axios from "axios";
import swal from "sweetalert";

const FormTwo = () => {
  const classesb = useLandingpageStyles();
  const { data } = useSelector((state) => state.transReducer);
  const classes = useFormStyles();
  const dispatch = useDispatch();
  ///////////////////////////////////////////////
  const [values, setValues] = useState({
    purchase_order_number: "",
    timezone: "",
    date: "",
    starttime: "",
    endtime: "",
    interpreting_service_required: "",
    house_number_street_name: "",
    address_line_two: "",
    region: "",
    post_code: "",
    country: "",
    current_nationality: "",
    other_nationalities: "",
    remote_address: "",
    source_language: "",
    target_language: "",
    source_linguist_skill_level: "",
    target_linguist_skill_level: "",
    typesof_interpreting_required: "",
    job_description: "",
    preferred_interpreter: "",
    gender: "",
    security_clearance: "",
    car_parking: "",
    additional_info: "",
    currency: "",
    error: false,
    success: false,
    loading: false,
    userData: process.browser && new FormData(),
  });

  const { username } = JSON.parse(localStorage.getItem("user"));

  const {
    purchase_order_number,
    timezone,
    date,
    starttime,
    endtime,
    interpreting_service_required,
    house_number_street_name,
    address_line_two,
    region,
    post_code,
    country,
    current_nationality,
    other_nationalities,
    remote_address,
    source_language,
    target_language,
    source_linguist_skill_level,
    target_linguist_skill_level,
    typesof_interpreting_required,
    job_description,
    preferred_interpreter,
    gender,
    security_clearance,
    car_parking,
    additional_info,
    currency,

    userData,
    agree,
    id,
  } = values;
  const { upload_file } = data;

  const init = () => {
    getBookingDetails().then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,
          purchase_order_number: data.purchase_order_number,
          timezone: data.timezone,
          date: data.date,
          starttime: data.starttime,
          endtime: data.endtime,
          interpreting_service_required: data.interpreting_service_required,
          house_number_street_name: data.house_number_street_name,
          address_line_two: data.address_line_two,
          region: data.region,
          post_code: data.post_code,
          country: data.country,
          current_nationality: data.current_nationality,
          other_nationalities: data.other_nationalities,
          remote_address: data.remote_address,
          source_language: data.source_language,
          target_language: data.target_language,
          source_linguist_skill_level: data.source_linguist_skill_level,
          target_linguist_skill_level: data.target_linguist_skill_level,
          typesof_interpreting_required: data.typesof_interpreting_required,
          job_description: data.job_description,
          preferred_interpreter: data.preferred_interpreter,
          gender: data.gender,
          security_clearance: data.security_clearance,
          car_parking: data.car_parking,
          additional_info: data.additional_info,
          id: data.id,
        });
      }
    });
  };

  useEffect(() => {
    init();
    setValues({ ...values, userData: new FormData() });
  }, []);

  //submitting the file
  const handleFiles = (e) => {
    const value = e.target.files[0];
    dispatch(
      changeTransInput({
        name: "upload_file",
        value,
      })
    );
  };

  const handleNext = async (e) => {
    e.preventDefault();
    const job_id = Cookies.get("job_id");

    let fd = new FormData();

    fd.append("purchase_order_number", purchase_order_number);
    fd.append("timezone", timezone);
    fd.append("date", date);
    fd.append("starttime", starttime);
    fd.append("endtime", endtime);
    fd.append("interpreting_service_required", interpreting_service_required);
    fd.append("house_number_street_name", house_number_street_name);
    fd.append("address_line_two", address_line_two);
    fd.append("region", region);
    fd.append("post_code", post_code);
    fd.append("country", country);
    fd.append("current_nationality", current_nationality);
    fd.append("remote_address", remote_address);
    fd.append("source_language", source_language);
    fd.append("target_language", target_language);
    fd.append("source_linguist_skill_level", source_linguist_skill_level);
    fd.append("target_linguist_skill_level", target_linguist_skill_level);
    fd.append("typesof_interpreting_required", typesof_interpreting_required);
    fd.append("job_description", job_description);
    fd.append("preferred_interpreter", preferred_interpreter);
    fd.append("gender", gender);
    fd.append("security_clearance", security_clearance);
    fd.append("car_parking", car_parking);
    fd.append("additional_info", additional_info);
    fd.append("id", id);
    fd.append("upload_file", upload_file);
    fd.append("username", username);

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/interpreting-cashclient/${job_id}`,
        fd
      );

      swal({
        title: "Please check the summary details",
        text: `and pay to confirm your order. `,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/admin/dashboard";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleChange = (name) => (e) => {
    const value = name === "photo" ? e.target.files[0] : e.target.value;

    userData.set(name, value);
    setValues({
      ...values,
      [name]: value,
      userData,
      error: false,
      success: false,
    });
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        {/* "Developer- add more option to book a multi day job. One Day job or Multi day job, allow easy pick without making mistake"  */}
        <CustomInput
          onChange={handleChange("purchase_order_number")}
          type="text"
          width="100%"
          label="Your booking ref/ Purchase order no"
          value={purchase_order_number}
        />

        <CustomSelect
          onChange={handleChange("timezone")}
          label="Select timezone"
          width="100%"
          data={TIMEZONE}
          //defaultValue= {moment.tz.guess()}
          value={timezone}
          //helperText= {moment.tz.guess()}
        />

        <Time />

        <CustomSelect
          required
          onChange={handleChange("interpreting_service_required")}
          label="Interpreting service required"
          data={ITP_SERVICE_REQUIRED}
          width="100%"
          value={interpreting_service_required}
        />

        {/* Developer - make this conditional if remote selected only be able to add remote platform details and phone number. If FTF selected show address field, if Remote address optional for invoicing purpose only. FTF onsite must provide venue details clearly including any department or building block */}

        {/* moreInfo="Developer - customer must select two, allow customer to book easily another combination which should become two jobs. System must be able to check match of languages 'not one both ie English <> Bengali '. Apply condition both selection cannot be same"  */}
        {interpreting_service_required !== "Remote / Telephone" ? (
          <Fragment>
            <CustomInput
              required
              onChange={handleChange("house_number_street_name")}
              width="100%"
              label="House Number / Name, Street Name"
              value={house_number_street_name}
            />
            <CustomInput
              onChange={handleChange("address_line_two")}
              width="100%"
              label="Address Line 2"
              value={address_line_two}
            />
            <CustomInput
              required
              onChange={handleChange("region")}
              width="100%"
              label="County / Region"
              value={region}
            />
            <CustomInput
              required
              onChange={handleChange("post_code")}
              width="100%"
              label="Post / Zip code"
              value={post_code}
            />
            <CustomAutoSelect
              onChange={handleChange("country")}
              label="Country"
              moreInfo="Venue country"
              width="100%"
              value={country}
              data={CURRENT_COUNTRY}
              required
            />
            {/* <CustomSelect
              onChange= {handleChange}
              label= 'Current nationality'
              width="100%"
              name= 'current_nationality'
              value= {current_nationality}
              data= {CURRENT_COUNTRY}
              required
            />
            <CustomAutoComplete
              onChange= {handleAutoComplete}
              label= 'Other nationalities'
              width="100%"
              data= {CURRENT_COUNTRY}
              value= {other_nationalities}
              placeholder= 'Choose'
            /> */}
          </Fragment>
        ) : (
          <CustomBigInput
            required
            onChange={handleChange("remote_address")}
            width="100%"
            value={remote_address}
            label="Remote / Telephone details"
            moreInfo="Please provide details Zoom link, Skype, phone number etc"
          />
        )}
        <CustomSelect
          required
          onChange={handleChange("source_language")}
          label="Source/Native language"
          data={LANG_COMB.filter((value) => value !== target_language)}
          width="100%"
          value={source_language}
        />

        <CustomSelect
          required
          onChange={handleChange("source_linguist_skill_level")}
          label="Please indicate linguist's source language skills level required"
          moreInfo="Please indicate linguist's source language skills level required for this assignment (1 - 5)"
          data={SKILL_LEVEL_REQUIRED}
          width="100%"
          value={source_linguist_skill_level}
        />

        <CustomSelect
          required
          onChange={handleChange("target_language")}
          label="Target language"
          data={LANG_COMB.filter((value) => value !== source_language)}
          width="100%"
          value={target_language}
        />
        <CustomSelect
          required
          onChange={handleChange("target_linguist_skill_level")}
          label="Please indicate linguist's target language skills level required"
          moreInfo="Please indicate linguist's target language skills level required for this assignment (1 - 5)"
          data={SKILL_LEVEL_REQUIRED}
          width="100%"
          value={target_linguist_skill_level}
        />
        {/* (Developer* - this is a mandatory */}
        <CustomAutoSelect
          required
          onChange={handleChange("typesof_interpreting_required")}
          label="Type/s of Interpreting required"
          moreInfo="Please pick the most relevant one/s)"
          data={TYPE_INTERPRETING}
          width="100%"
          value={typesof_interpreting_required}
        />
        <CustomBigInput
          required
          onChange={handleChange("job_description")}
          width="100%"
          label="Job description"
          moreInfo="Please provide brief information for the benefit of linguist's preparation"
          value={job_description}
        />
        <CustomBigInput
          onChange={handleChange("preferred_interpreter")}
          width="100%"
          label="Preferred Linguist / Service provider ID number"
          moreInfo="LC will try its best, but cannot guarantee"
          value={preferred_interpreter}
        />
        <CustomRadio
          onChange={handleChange("gender")}
          label="Gender"
          moreInfo="Please note that we can not guarantee gender"
          data={GENDER_DATA}
          width="100%"
          value={gender}
        />
        <CustomSelect
          onChange={handleChange("security_clearance")}
          label="Please indicate security clearance level required*"
          moreInfo="Please indicate security clearance level required for this assignment"
          data={SECURITY_CLEARANCE_LEVEL}
          width="100%"
          value={security_clearance}
        />
        <CustomInput
          onChange={handleChange("additional_info")}
          label="Additional information and direction for linguist"
          width="100%"
          value={additional_info}
        />

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <CustomUpload
            onChange={handleFiles}
            accept=".pdf,.docx"
            name="upload_file"
            width="100%"
            label="Upload any script or instruction here"
            moreInfo="e.g. for conference interpreting"
          />
          {upload_file ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Icon name="file pdf" size="big" />
              <p
                style={{
                  margin: "0",
                  marginTop: "7px",
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                }}
              >
                {upload_file.name}
              </p>
              <p>{upload_file?.lastModifiedDate?.toDateString()}</p>
            </div>
          ) : null}
        </div>

        {/* <CustomSelect
          onChange= {handleChange}
          data= {CURRENCY}
          width= '100%'
          label= 'Preferred currency'
          name= 'currency'
          value= {currency}
        /> */}

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                checked={agree}
                name="agree"
                color="primary"
              />
            }
            label="By submitting this form I agree with LC Privacy Policy, T&C"
          />
        </div>
        <div className={classes.buttonFlex}>
          <CustomButton
            text="Submit"
            value={agree}
            className={`${classesb.button}`}
            onClick={handleNext}
          />
        </div>
      </form>
    </div>
  );
};

export default FormTwo;
