import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import ProfileUpdate from "components/AccountClient/AccountClientDasboard/ProfileUpdate/ProfileUpdate"


const ProfilePage = () => {
  const classes = useDashboardStyles();

  return (
    <AccountClientLayout>
      <Accountclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Profile</p>
              </CardHeader>
              <CardBody>
                <ProfileUpdate />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Accountclient>
    </AccountClientLayout>
  );
};

export default ProfilePage;
