import { v4 as uuidv4 } from "uuid";
import EmailActivationAC from "pages/account-client/EmailActivationAC";
import ClientBookingPage from "pages/ClientBooking";
import PublicJobAdvertPage from "pages/PublicJobAdvert";
import JASummary from "components/Auth/PublicJobAdvert/JASummary";

const CA_OPENING = [
  {
    id: uuidv4(),
    type: "form",
    text: "LC Joining",
    subText: "Client Account Opening",
    path: "clientacc-opening-form",
    component: ClientBookingPage,
  },
  {
    id: uuidv4(),
    path: "accountclient/auth/account/activate/:token",
    component: EmailActivationAC,
  },
  {
    id: uuidv4(),
    type: "form",
    subText: "Job Advert(Public)",
    path: "interpreter-translator-linguists-job-advert",
    component: PublicJobAdvertPage,
  },
  {
    id: uuidv4(),
    type: "form",
    subText: "Job Advert(Public)",
    path: "interpreter-translator-linguists-job-advert/:id",
    component: PublicJobAdvertPage,
  },
  {
    id: uuidv4(),
    path: "interpreter-translator-linguists-job-advert-summary",
    component: JASummary,
  },

];

export default CA_OPENING;
