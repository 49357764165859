import Linguist from "components/RouteProtection/Linguist/Linguist";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import CardHeader from "components/Card/CardHeader";
import Notes from "./NotesINCTrans";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";

import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Items from "pages/Component/TransFooter";
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import fetch from "isomorphic-fetch";
import TranslationDetails from "pages/Component/TranslationDetailsLinClient";
import TranslationDelivery from "pages/Component/Translation_Delivery/LIN/Translation_Delivery"

const ViewContactRequest = () => {
  const history = useHistory();
  const classes = useDashboardStyles();
  const { id } = useParams();
  const [values, setValues] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-Translation-view/TranslationID/${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setValues(result);
      });
  }, []);

  return values ? (
    <Linguist>
      <LinguistLayout>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>
              Translation Assignment - {values.id}
            </p>
            <IconButton color="inherit">
              <ArrowBackIcon onClick={() => history.goBack()}></ArrowBackIcon>
            </IconButton>
          </CardHeader>
        </GridItem>
        <br />
        <React.Fragment>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
              <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
                <CardHeader
                  color="primary"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 10,
                    top: 35,
                  }}
                >
                  <p className={classes.cardTitleWhite}>
                    Translation Assignment
                  </p>
                </CardHeader>
              </GridItem>
              <Card
                raised
                style={{ marginTop: "24px", padding: "30px", zIndex: 0 }}
              >
                <CardBody>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <CardBody>
                        <TranslationDetails values={values} />
                      </CardBody>
                    </Card>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ zIndex: 1 }}
                    >
                      <Button
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          marginTop: 10,
                          top: 35,
                          background:
                            "linear-gradient( 60deg , #ab47bc, #8e24aa)",
                          width: 100 + "%",
                          padding: (10, 15),
                        }}
                        onClick={() =>
                          history.push(
                            `/linguist/inc-view-translation-document/${values._id}/${values.id}`
                          )
                        }
                      >
                        <p className={classes.cardTitleWhite}>View Document</p>
                      </Button>
                    </GridItem>
                  </GridItem>
                </CardBody>
                <br />
                <br />
                <CardBody>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ zIndex: 1 }}
                  >
                    <Card>
                      <Items values={values} />
                    </Card>
                    <br />
                    <br />
                    <Card>
                      <Notes />
                    </Card>
                  </GridItem>
                </CardBody>
              </Card>
            </GridItem>
            {values?.accept ?
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ zIndex: 1 }}
              >
                <br />
                <Card>
                  <TranslationDelivery values={values} />
                </Card>
              </GridItem> : null}
          </GridContainer>
        </React.Fragment>
      </LinguistLayout>
    </Linguist>
  ) : (
    "Loading..."
  );
};

export default ViewContactRequest;
