import moment from 'moment-timezone'
const { CHANGE_OTT_INPUT } = require("redux/actions/actionTypes");


const INITIAL_STATE = {
    data: {
        client_found: false,
        select_client: '',
        timezone: moment.tz.guess(),
        purchase_order_number: '',
        document_type: '',
        source_language: '',
        source_language_skill_level: '',
        target_language: '',
        target_language_skill_level: '',
        expertise_level_required: '',
        require_visual_formating: '',
        certified_translation: '',
        certificate: '',
        additional_quality_check: '',
        document: '',
        provider_id_number: '',
        additional_note: '',
        upload_cv: '',
        upload_file: '',
        currency_new: '',
        word_count: '',
        count:'',
        target_expertise_level_required: '',
        source_expertise_level_required: '',
        agree: false
    }
};

const ottReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case CHANGE_OTT_INPUT:
            return {
                ...state,
                data: {
                  ...state.data,
                  [payload.name]: payload.value
                }
            }
        
        default:
            return state
    }
}

export default ottReducer