import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextArea } from "semantic-ui-react";

import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import { SubDetailsDiv } from "./styles";
import { updateRateDetails } from "redux/actions/admin/controller";

const SubDetails = ({ readOnly, className }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { details } = useSelector((state) => state.adminControlData);

  const [value, setValue] = useState(
    `LC oppose all forms of slavery which consists of but is not limited to: human trafficking, sex trafficking, and unpaid labour. LC is committed to fight modern day slavery. If Lignusit is required to be logged into Client's system, or present, LC expects the Linguist to be paid. If the Linguist is not paid for the full duration of their log-in or presence at the venue (including any travel time to and from the venue from the Linguist's home base), LC may consider that as a form of modern-day slavery. LC advises that Linguists do not accept work that pays less than relevant national minimum wage per hour.`
  );

  useEffect(() => {
    if (details?.subDetails) {
      setValue(details?.subDetails);
    }
  }, [details]);

  const handleChange = async () => {
    const coopiedDetails = { ...details };
    coopiedDetails["subDetails"] = value;
    setLoading(true);

    await dispatch(updateRateDetails(coopiedDetails));
    setLoading(false);
    setIsClicked(false);
  };

  return (
    <SubDetailsDiv className={className}>
      {isClicked ? (
        <TextArea
          placeholder="Tell us more"
          value={value}
          rows={4}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <p>{value}</p>
      )}
      {!readOnly ? (
        <div className="icon-div">
          {isClicked ? (
            <Fragment>
              {loading ? (
                <CircularProgress
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              ) : (
                <IconButton
                  aria-label="action"
                  color="primary"
                  onClick={handleChange}
                >
                  <CheckIcon />
                </IconButton>
              )}
            </Fragment>
          ) : (
            <IconButton
              aria-label="action"
              color="primary"
              onClick={() => setIsClicked(true)}
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
      ) : null}
    </SubDetailsDiv>
  );
};

export default SubDetails;
