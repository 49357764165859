import fetch from "isomorphic-fetch";

export const getJob = () => {
    return fetch(
      `${process.env.REACT_APP_BACKEND_API}/frontpage/job-list`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
  };