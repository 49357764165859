import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { acceptDecline } from "../functions";
import Axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
import { Table } from "semantic-ui-react";
import moment from "moment";
import Action from "./Action";
import SearchView from "./SearchView/SearchView";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function CacQuoteRequest() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload, filter]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/list/quote-request-cashclient`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (filtered.length > 0) {
        setValues({ ...values, bookingList: filtered });
        setFiltered([]);
      } else {
        setValues({ ...values, bookingList: response?.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccept = async (id, email) => {
    // console.log(id)
    // try {
    //   const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/manager-admin/accept-frontpage-jobadvert/${id}`, {email, email}, {
    //     // headers: {
    //     //   Authorization: `Bearer ${token}`
    //     // }
    //   })
    //   swal ({
    //     icon: 'success',
    //     text: `${response?.data.message}`,
    //     buttons: false
    //   }).then(function() {
    //     window.location = "/admin/job-advert";
    // });
    //   setValues({...values, reload: true})
    //   console.log(response)
    // } catch(error) {
    //   console.log(error.response)
    // }
  };

  const decline = async (decline, id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/reject/quote-request-cashclient/quoteID/${id}`,
        { email: email, declines: decline },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      loadbookingList();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDecline = async (e, id, email) => {
    const declines = e.target.value;
    swal(`Are you sure you want to decline this job?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      // .then(function() {
      //   window.location = "/admin/unverified-linguists";
      // })
      .then((value) => {
        switch (value) {
          case "cancel":
            break;

          case "catch":
            decline(declines, id, email);

          default:
            break;
        }
      });
  };

  return (
    <Paper className={classes.root}>
      <SearchView
        values={bookingList}
        load={loadbookingList}
        setSearchText={setSearchText}
        setShow={setShow}
        SearchText={searchText}
        setFiltered={setFiltered}
        setFilter={setFilter}
        show={show}
      />
      {show && (
        <>
          {" "}
          <TableContainer className={classes.container}>
            <Table color="pink">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                  {/* <Table.HeaderCell>Quote Request For</Table.HeaderCell> */}
                  <Table.HeaderCell>Remote / Venue</Table.HeaderCell>
                  <Table.HeaderCell>Request Details</Table.HeaderCell>
                  <Table.HeaderCell>Budget</Table.HeaderCell>
                  <Table.HeaderCell>Source language</Table.HeaderCell>
                  <Table.HeaderCell>Target language</Table.HeaderCell>

                  <Table.HeaderCell>
                    Target language skill level
                  </Table.HeaderCell>
                  <Table.HeaderCell>Timezone</Table.HeaderCell>
                  <Table.HeaderCell>Quote Needed By</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {bookingList?.length > 0 ? (
                <Table.Body>
                  {bookingList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((list) => (
                      <>
                        {moment(list.quote_by) > moment() ||
                        moment(list.quote_by) === moment() ? (
                          <Table.Row key={list._id}>
                            <Table.Cell key={list._id} align={list.align}>
                              <Link to={`/linguist/cac-view-quote/${list._id}`}>
                                {list.id}
                              </Link>
                            </Table.Cell>

                            <Table.Cell>{list.country}</Table.Cell>
                            {/* <Table.Cell>{list.salary}</Table.Cell> */}
                            <Table.Cell>{list.request_details}</Table.Cell>
                            <Table.Cell>{list.project_fix_budget}</Table.Cell>
                            <Table.Cell>{list.source_language}</Table.Cell>
                            <Table.Cell>{list.target_language}</Table.Cell>
                            <Table.Cell>
                              {list.target_skills_required}
                            </Table.Cell>
                            <Table.Cell>{list.timezone}</Table.Cell>
                            <Table.Cell>
                              {moment(list.quote_by).format(
                                "DD-MMM-YYYY : HH:mm"
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <Action
                                id={list._id}
                                email={list.email}
                                handleDecline={handleDecline}
                                declineOp
                              />
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row key={list._id}>
                            <Table.Cell key={list._id} align={list.align}>
                              <Link to={`/linguist/cac-view-quote/${list._id}`}>
                                {list.id}
                              </Link>
                            </Table.Cell>

                            <Table.Cell>{list.country}</Table.Cell>
                            {/* <Table.Cell>{list.salary}</Table.Cell> */}
                            <Table.Cell>{list.request_details}</Table.Cell>
                            <Table.Cell>{list.project_fix_budget}</Table.Cell>
                            <Table.Cell>{list.source_language}</Table.Cell>
                            <Table.Cell>{list.target_language}</Table.Cell>
                            <Table.Cell>
                              {list.target_skills_required}
                            </Table.Cell>
                            <Table.Cell>{list.timezone}</Table.Cell>
                            <Table.Cell>
                              {moment(list.quote_by).format(
                                "DD-MMM-YYYY : HH:mm"
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              Assignment Acceptance Time Expired.You may contact
                              Booking Team for an update.
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </>
                    ))}
                </Table.Body>
              ) : (
                "Sorry, no CAC Quote request found matching your profile"
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={bookingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
}
