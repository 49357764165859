import React, { useState, useEffect } from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SppoLayout from "layout/SppoLayout";
import Sppo from "components/RouteProtection/Sppo/Sppo";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Cookies from "js-cookie";
import Axios from "axios";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Header } from "semantic-ui-react";
import TicketOptions from "components/Admin/Communication/SPPO/SPPOTicketOptions";

const Communication = () => {
  const classes = useDashboardStyles();

  const [sppoID, setSPPOID] = useState(null);

  const getDetails = async (e) => {
    const token = Cookies.get("SPPOtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setSPPOID(response?.data);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <SppoLayout>
      <Sppo>
        <GridContainer>
          {sppoID?.agent_sppo && sppoID?.communication_access ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>
                    Communication - Support Tickets
                  </p>
                </CardHeader>
                <CardBody>
                  <TicketOptions />
                </CardBody>
              </Card>
            </GridItem>
            : sppoID?.profile_update ?
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader color="primary">
                    <p className={classes.cardTitleWhite}>
                      Communication - Support Tickets
                    </p>
                  </CardHeader>
                  <CardBody>
                    <TicketOptions />
                  </CardBody>
                </Card>
              </GridItem>
              : sppoID?.agent_sppo && sppoID?.profile_update === false ?
                <Header as='h3'>Access denied.</Header>
                :
                sppoID?.profile_update === false ?
                  <Header as='h3'>Access denied. <a href={`/sppo/full-registration`}>Please complete full registration.</a></Header>
                  :
                  <Header as='h3'>Loading...</Header>
          }
        </GridContainer>
      </Sppo>
    </SppoLayout>
  );
};

export default Communication;
