import Axios from "utils/axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Actions from "./Actions";
import { TableDiv } from "./style";

const Bill = () => {
  const { billID } = useParams();
  const { clientID } = useParams();
  const [rates, setRates] = useState("");
  const { currency } = useSelector((state) => state.caoReducer);
  const [invoice, setInvoice] = useState(null);
  const [content, setContent] = useState("");
  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
  });

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const InvoiceDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/manager-admin/view-Monthly-Bill-details/billID/${billID}`
        );

        setInvoice(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          console.log(error.response?.data);
        }
      }
    };

    const getCurrency = async () => {
      if (currency) {
        Axios.get(
          `/api/currency-exchange`
        ).then((res) => setRates(res?.data?.rates));
      }
    };

    const getCompanyInfo = async () => {
      if (currency) {
        Axios.get(
          `/api/manager-admin/view/company-information-card`
        ).then((res) =>
          setCompanyCard({
            ...companyCard,
            basc: res.data[0].billing_account_service_charge,
            pbsc: res.data[0].platform_booking_service_charge,
          })
        );
      }
    };

    const handlePdfContent = () => {
      setContent(document.getElementById("pdf").innerHTML);
    };

    InvoiceDetails();
    getCurrency();
    getCompanyInfo();
    handlePdfContent();
    return () => {
      source.cancel();
    };
  }, [currency]);

  let sl = 1,
    sumTotal = 0,
    sumDue = 0,
    sumDiscount = 0,
    sumVatTotal = 0,
    sumBAServiceCharge = 0,
    sumServiceCharge = 0,
    sumDeposit = 0,
    sumPaid = 0;

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumTotal += invoice[i].total * rates.GBP)
        : currency?.substring(0, 3) == "EUR"
        ? (sumTotal += invoice[i].total * rates.EUR)
        : currency?.substring(0, 3) == "AUD"
        ? (sumTotal += invoice[i].total * rates.AUD)
        : currency?.substring(0, 3) == "USD"
        ? (sumTotal += invoice[i].total * rates.USD)
        : currency?.substring(0, 3) == "CAD"
        ? (sumTotal += invoice[i].total * rates.CAD)
        : currency?.substring(0, 3) == "TRY"
        ? (sumTotal += invoice[i].total * rates.TRY)
        : currency?.substring(0, 3) == "SGD"
        ? (sumTotal += invoice[i].total * rates.SGD)
        : currency?.substring(0, 3) == "HUF"
        ? (sumTotal += invoice[i].total * rates.HUF)
        : currency?.substring(0, 3) == "NZD"
        ? (sumTotal += invoice[i].total * rates.NZD)
        : currency?.substring(0, 3) == "RON"
        ? (sumTotal += invoice[i].total * rates.RON)
        : (sumTotal += invoice[i].total * rates.GBP);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.GBP
            : invoice[i].due * rates.GBP)
        : currency?.substring(0, 3) == "EUR"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.EUR
            : invoice[i].due * rates.EUR)
        : currency?.substring(0, 3) == "AUD"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.AUD
            : invoice[i].due * rates.AUD)
        : currency?.substring(0, 3) == "USD"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.USD
            : invoice[i].due * rates.USD)
        : currency?.substring(0, 3) == "CAD"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.CAD
            : invoice[i].due * rates.CAD)
        : currency?.substring(0, 3) == "TRY"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.TRY
            : invoice[i].due * rates.TRY)
        : currency?.substring(0, 3) == "SGD"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.SGD
            : invoice[i].due * rates.SGD)
        : currency?.substring(0, 3) == "HUF"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.HUF
            : invoice[i].due * rates.HUF)
        : currency?.substring(0, 3) == "NZD"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.NZD
            : invoice[i].due * rates.NZD)
        : (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.RON
            : invoice[i].due * rates.RON);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumDiscount += invoice[i].discount * rates.GBP)
        : currency?.substring(0, 3) == "EUR"
        ? (sumDiscount += invoice[i].discount * rates.EUR)
        : currency?.substring(0, 3) == "AUD"
        ? (sumDiscount += invoice[i].discount * rates.AUD)
        : currency?.substring(0, 3) == "USD"
        ? (sumDiscount += invoice[i].discount * rates.USD)
        : currency?.substring(0, 3) == "CAD"
        ? (sumDiscount += invoice[i].discount * rates.CAD)
        : currency?.substring(0, 3) == "TRY"
        ? (sumDiscount += invoice[i].discount * rates.TRY)
        : currency?.substring(0, 3) == "SGD"
        ? (sumDiscount += invoice[i].discount * rates.SGD)
        : currency?.substring(0, 3) == "HUF"
        ? (sumDiscount += invoice[i].discount * rates.HUF)
        : currency?.substring(0, 3) == "NZD"
        ? (sumDiscount += invoice[i].discount * rates.NZD)
        : (sumDiscount += invoice[i].discount * rates.RON);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumVatTotal += invoice[i].vat * rates.GBP)
        : currency?.substring(0, 3) == "EUR"
        ? (sumVatTotal += invoice[i].vat * rates.EUR)
        : currency?.substring(0, 3) == "AUD"
        ? (sumVatTotal += invoice[i].vat * rates.AUD)
        : currency?.substring(0, 3) == "USD"
        ? (sumVatTotal += invoice[i].vat * rates.USD)
        : currency?.substring(0, 3) == "CAD"
        ? (sumVatTotal += invoice[i].vat * rates.CAD)
        : currency?.substring(0, 3) == "TRY"
        ? (sumVatTotal += invoice[i].vat * rates.TRY)
        : currency?.substring(0, 3) == "SGD"
        ? (sumVatTotal += invoice[i].vat * rates.SGD)
        : currency?.substring(0, 3) == "HUF"
        ? (sumVatTotal += invoice[i].vat * rates.HUF)
        : currency?.substring(0, 3) == "NZD"
        ? (sumVatTotal += invoice[i].vat * rates.NZD)
        : (sumVatTotal += invoice[i].vat * rates.RON);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumBAServiceCharge += invoice[i]?.basc * rates.GBP)
        : currency?.substring(0, 3) == "EUR"
        ? (sumBAServiceCharge += invoice[i]?.basc * rates.EUR)
        : currency?.substring(0, 3) == "AUD"
        ? (sumBAServiceCharge += invoice[i]?.basc * rates.AUD)
        : currency?.substring(0, 3) == "USD"
        ? (sumBAServiceCharge += invoice[i]?.basc * rates.USD)
        : currency?.substring(0, 3) == "CAD"
        ? (sumBAServiceCharge += invoice[i]?.basc * rates.CAD)
        : currency?.substring(0, 3) == "TRY"
        ? (sumBAServiceCharge += invoice[i]?.basc * rates.TRY)
        : currency?.substring(0, 3) == "SGD"
        ? (sumBAServiceCharge += invoice[i]?.basc * rates.SGD)
        : currency?.substring(0, 3) == "HUF"
        ? (sumBAServiceCharge += invoice[i]?.basc * rates.HUF)
        : currency?.substring(0, 3) == "NZD"
        ? (sumBAServiceCharge += invoice[i]?.basc * rates.NZD)
        : (sumBAServiceCharge += invoice[i]?.basc * rates.RON);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumServiceCharge += invoice[i]?.pbsc * rates.GBP)
        : currency?.substring(0, 3) == "EUR"
        ? (sumServiceCharge += invoice[i]?.pbsc * rates.EUR)
        : currency?.substring(0, 3) == "AUD"
        ? (sumServiceCharge += invoice[i]?.pbsc * rates.AUD)
        : currency?.substring(0, 3) == "USD"
        ? (sumServiceCharge += invoice[i]?.pbsc * rates.USD)
        : currency?.substring(0, 3) == "CAD"
        ? (sumServiceCharge += invoice[i]?.pbsc * rates.CAD)
        : currency?.substring(0, 3) == "TRY"
        ? (sumServiceCharge += invoice[i]?.pbsc * rates.TRY)
        : currency?.substring(0, 3) == "SGD"
        ? (sumServiceCharge += invoice[i]?.pbsc * rates.SGD)
        : currency?.substring(0, 3) == "HUF"
        ? (sumServiceCharge += invoice[i]?.pbsc * rates.HUF)
        : currency?.substring(0, 3) == "NZD"
        ? (sumServiceCharge += invoice[i]?.pbsc * rates.NZD)
        : (sumServiceCharge += invoice[i]?.pbsc * rates.RON);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumDeposit += invoice[i]?.estimate * rates.GBP)
        : currency?.substring(0, 3) == "EUR"
        ? (sumDeposit += invoice[i]?.estimate * rates.EUR)
        : currency?.substring(0, 3) == "AUD"
        ? (sumDeposit += invoice[i]?.estimate * rates.AUD)
        : currency?.substring(0, 3) == "USD"
        ? (sumDeposit += invoice[i]?.estimate * rates.USD)
        : currency?.substring(0, 3) == "CAD"
        ? (sumDeposit += invoice[i]?.estimate * rates.CAD)
        : currency?.substring(0, 3) == "TRY"
        ? (sumDeposit += invoice[i]?.estimate * rates.TRY)
        : currency?.substring(0, 3) == "SGD"
        ? (sumDeposit += invoice[i]?.estimate * rates.SGD)
        : currency?.substring(0, 3) == "HUF"
        ? (sumDeposit += invoice[i]?.estimate * rates.HUF)
        : currency?.substring(0, 3) == "NZD"
        ? (sumDeposit += invoice[i]?.estimate * rates.NZD)
        : (sumDeposit += invoice[i]?.estimate * rates.RON);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? invoice[i].paid * rates.GBP
            : 0 * rates.GBP)
        : currency?.substring(0, 3) == "EUR"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? invoice[i].paid * rates.EUR
            : 0 * rates.EUR)
        : currency?.substring(0, 3) == "AUD"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? invoice[i].paid * rates.AUD
            : 0 * rates.AUD)
        : currency?.substring(0, 3) == "USD"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? invoice[i].paid * rates.USD
            : 0 * rates.USD)
        : currency?.substring(0, 3) == "CAD"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? invoice[i].paid * rates.CAD
            : 0 * rates.CAD)
        : currency?.substring(0, 3) == "TRY"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? invoice[i].paid * rates.TRY
            : 0 * rates.TRY)
        : currency?.substring(0, 3) == "SGD"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? invoice[i].paid * rates.SGD
            : 0 * rates.SGD)
        : currency?.substring(0, 3) == "HUF"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? invoice[i].paid * rates.HUF
            : 0 * rates.HUF)
        : currency?.substring(0, 3) == "NZD"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? invoice[i].paid * rates.NZD
            : 0 * rates.NZD)
        : (sumPaid += invoice[i].invoice_payment_status
            ? invoice[i].paid * rates.RON
            : 0 * rates.RON);
    }
  }

  return (
    <div id="pdf">
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>Item no.</th>
              <th>Assignment ID</th>
              <th>Invoice No.</th>
              <th>Invoice Date</th>
              <th>SP/Linguist ID</th>
              <th>VAT No.</th>
              <th>Country</th>
              <th>Currency</th>
              <th>Total</th>
              <th>Discount</th>
              <th>VAT Total</th>
              <th>BAC Service Charge</th>
              <th>Platform Service Charge</th>
              <th>Deposit</th>
              <th>Paid</th>
              <th>Due</th>
              <th>Due in {currency?.substring(0, 3)}</th>
            </tr>
          </thead>
          <tbody>
            {invoice?.map((data) => (
              <tr>
                <td>{sl++}</td>
                <td>{data?.assignmentID ? data?.assignmentID : "N/A"}</td>
                <td>{data?.invoiceID ? data?.invoiceID : "N/A"}</td>
                <td>{data?.invoiceDate ? data?.invoiceDate : "N/A"}</td>
                <td>{data?.linID ? data?.linID : "N/A"}</td>
                <td>{data?.linVat ? data?.linVat : "N/A"}</td>
                <td>{data?.linCountry ? data?.linCountry : "N/A"}</td>
                <td>{data?.currency?.substring(0, 3)}</td>
                <td>{data?.total ? data?.total : "00.00"}</td>
                <td>{data?.discount ? data?.discount : "00.00"}</td>
                <td>{data?.vat ? data?.vat : "00.00"}</td>
                <td>{data?.basc ? data?.basc : "00.00"}</td>
                <td>{data?.pbsc ? data?.pbsc : "00.00"}</td>
                {/* deposit amount */}
                <td>{data?.estimate ? data?.estimate : "00.00"}</td>
                {/* Paid amount */}
                <td>
                  {/* {data?.invoice_payment_status ? data?.grand_total : '00'} */}
                  {data?.paid ? data?.paid : "00.00"}
                </td>
                {/* due amount */}
                <td>
                  {/* {data?.invoice_payment_status ? '00' : data?.grand_total} */}
                  {data?.due ? data?.due : "00.00"}
                </td>
                {/* due amount change with currency rate */}
                <td>
                  {currency?.substring(0, 3) == "GBP"
                    ? data?.invoice_payment_status
                      ? "00"
                      : data?.due * rates.GBP
                    : currency?.substring(0, 3) == "EUR"
                    ? data?.invoice_payment_status
                      ? "00"
                      : data?.due * rates.EUR
                    : currency?.substring(0, 3) == "AUD"
                    ? data?.invoice_payment_status
                      ? "00"
                      : data?.due * rates.AUD
                    : currency?.substring(0, 3) == "USD"
                    ? data?.invoice_payment_status
                      ? "00"
                      : data?.due * rates.USD
                    : currency?.substring(0, 3) == "CAD"
                    ? data?.invoice_payment_status
                      ? "00"
                      : data?.due * rates.CAD
                    : currency?.substring(0, 3) == "TRY"
                    ? data?.invoice_payment_status
                      ? "00"
                      : data?.due * rates.TRY
                    : currency?.substring(0, 3) == "SGD"
                    ? data?.invoice_payment_status
                      ? "00"
                      : data?.due * rates.SGD
                    : currency?.substring(0, 3) == "HUF"
                    ? data?.invoice_payment_status
                      ? "00"
                      : data?.due * rates.HUF
                    : currency?.substring(0, 3) == "NZD"
                    ? data?.invoice_payment_status
                      ? "00"
                      : data?.due * rates.NZD
                    : data?.invoice_payment_status
                    ? "00"
                    : data?.due * rates.RON}
                </td>
              </tr>
            ))}

            <tr
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                backgroundColor: "lightgray",
              }}
            >
              <td>Total</td>

              <td>{invoice?.length}</td>

              <td></td>

              <td></td>

              <td></td>

              <td></td>

              <td></td>

              <td>Total in currency ({currency})</td>

              <td>
                {currency?.substring(0, 3) !== "GBP"
                  ? (sumTotal * (105 / 100)).toFixed(2)
                  : sumTotal.toFixed(2)}
              </td>

              {/* total discount amount */}
              <td>{sumDiscount.toFixed(2)}</td>
              {/* total vat amount */}
              <td>{sumVatTotal.toFixed(2)}</td>
              {/* Billing account service charge coming from company info card */}
              <td>{sumBAServiceCharge.toFixed(2)}</td>
              {/* platform booking service charge comming from company info card */}
              <td>{sumServiceCharge.toFixed(2)}</td>
              {/* total deposit or estimate amount */}
              <td>{sumDeposit.toFixed(2)}</td>
              {/* total paid amount */}
              <td>{sumPaid.toFixed(2)}</td>
              {/* total due amount */}
              <td>{sumDue.toFixed(2)}</td>
              {/* total due amount */}
              <td>
                {currency?.substring(0, 3) !== "GBP"
                  ? (sumDue * (105 / 100)).toFixed(2)
                  : sumDue.toFixed(2)}
              </td>

              <td style={{ display: "none" }}></td>
            </tr>
          </tbody>
        </table>
      </TableDiv>

      <Actions
        listContent={content}
        billID={billID}
        clientID={clientID}
        total={
          currency?.substring(0, 3) !== "GBP"
            ? (sumTotal * (105 / 100)).toFixed(2)
            : sumTotal.toFixed(2)
        }
      />
    </div>
  );
};

export default Bill;
