import React from "react";

// Material Components
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// Custom Components
import CustomLabel from "components/Common/CustomLabel";
import CustomFormControl from "components/Common/CustomFormControl";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

const CustomDate = ({
  label,
  width,
  moreInfo,
  onChange,
  value,
  required,
  ...rest
}) => {
  const classes = useFormStyles();

  const handleDateChange = (date, value) => {
    onChange(date);
  };

  return (
    <CustomFormControl width={width} {...rest}>
      <MuiPickersUtilsProvider
        className={classes.pickerClass}
        utils={DateFnsUtils}
      >
        {value ? (
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label={
              <CustomLabel
                label={label}
                moreInfo={"DD/MM/YYYY"}
                required={required}
              />
            }
            format="dd/MM/yyyy"
            value={value}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        ) : (
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label={
              <CustomLabel
                label={label}
                moreInfo={"DD/MM/YYYY"}
                required={required}
              />
            }
            format="dd/MM/yyyy"
            onChange={handleDateChange}
            value={null}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        )}
      </MuiPickersUtilsProvider>
    </CustomFormControl>
  );
};

export default CustomDate;
