import Axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// Custom Styles
import swal from "sweetalert";

const InhouseClientEmailActivation = () => {
  const bacID = Cookies.get("bacID");
  let { token } = useParams();
  const history = useHistory();
  // const { data } = useSelector((state) => state.icrReducer);

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/accountclient/user/signup/bacID/${bacID}`,
        {
          token: token,
        }
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });

      setTimeout(() => {
        history.push("/account-client-login");
      }, 2000);
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return <div></div>;
};

export default InhouseClientEmailActivation;
