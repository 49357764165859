import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import CheckIcon from "@material-ui/icons/Check";
import { useParams } from "react-router-dom";
// Other
import { changePreRegInput } from "redux/actions/preregAction";
import Axios from "axios";
import swal from "sweetalert";
import { Button, IconButton } from "@material-ui/core";
import CustomAvatar from "components/Common/CustomAvatar";
import LandingPageLayout from "layout/LandingPageLayout";
import { Header, Table } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import useEmployeeStyles from "assets/jss/components/employeeStyle";

const FormOne = () => {
  const { id } = useParams();
  const classes = useEmployeeStyles();
  const { data } = useSelector((state) => state.preregReducer);
  const dispatch = useDispatch();
  const fileInput = useRef(null);

  const { upload_file_1 } = data;

  const [edit, setEdit] = React.useState({
    loader: false,
  });

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleFiles = (e) => {
    if (e.target.files.length > 2) {
      swal({
        icon: "error",
        title: "Kindly select two documents only",
      });
    } else {
      const value = Array.from(e.target.files);
      let i = 0;
      while (value.length !== i) {
        if (value[i].size >= 30000000) {
          swal({
            title: "Kindly select less than 30 mb document",
            icon: "error",
          });
        } else {
          if (upload_file_1) {
            dispatch(
              changePreRegInput({
                name: "upload_file_1",
                value: upload_file_1.concat(value),
              })
            );
          } else {
            dispatch(
              changePreRegInput({
                name: "upload_file_1",
                value,
              })
            );
          }
        }
        i++;
      }
    }
  };

  const handleRemove = (index) => {
    const copiedTC = upload_file_1;
    copiedTC.splice(index, 1);
    dispatch(
      changePreRegInput({
        name: "upload_file_1",
        value: copiedTC,
      })
    );
  };

  const handleSubmit = async (e) => {
    setEdit({ ...edit, loader: true });

    let fd = new FormData();
    fd.append("upload_file_1", upload_file_1[0] || "");
    // fd.append("upload_file_1", upload_file_1[1] || "");

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/PRC/upload-interpreting-document/assignmentID/${id}`,
        fd
      );
      swal({
        title: "Success",
        text: response?.data.message,
        icon: "success",
        buttons: false,
      });
      // .then(function () {
      //   window.location = "/";
      // });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleSend = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleSubmit(value);
          break;
        default:
          break;
      }
    });
  };

  return (
    <LandingPageLayout>
      <form className={classes.form} noValidate autoComplete="off">
        <div className={classes.root}>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CustomAvatar />
                  </div>
                  <div className={`${classes.textCenter} ${classes.title}`}>
                    <Header style={{ margin: "0" }} as="h3">
                      Linguists Collective (LC)
                    </Header>
                    <Header style={{ margin: "0" }} as="h3">
                      Upload Interpreting Assignment Documents
                    </Header>
                  </div>
                  <input
                    onChange={handleFiles}
                    accept=".pdf,.docx,image/jpeg,image/jpg"
                    name="upload_file_1"
                    width="100%"
                    required
                    ref={fileInput}
                    type="file"
                    style={{ display: "none" }}
                    multiple
                  />
                  <br />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Header
                      style={{ margin: "0", marginBottom: "5px" }}
                      as="h5"
                    >
                      Upload interpreting documents * (documents with less than
                      30mb size each)
                    </Header>
                    <div>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleClick}
                        disabled={upload_file_1 && upload_file_1.length === 1}
                      >
                        Upload
                      </Button>
                    </div>

                    {upload_file_1 && (
                      <>
                        <Table color="purple">
                          <Table.Header>
                            <Table.Row textAlign="center">
                              <Table.HeaderCell>Name</Table.HeaderCell>
                              <Table.HeaderCell>Size</Table.HeaderCell>
                              <Table.HeaderCell>
                                Last Modified Date
                              </Table.HeaderCell>
                              {upload_file_1.length > 0 && (
                                <Table.HeaderCell>Remove File</Table.HeaderCell>
                              )}
                              {upload_file_1.length > 0 && (
                                <Table.HeaderCell>Upload File</Table.HeaderCell>
                              )}
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            {upload_file_1 &&
                              upload_file_1?.map((file, i) => (
                                <Table.Row textAlign="center" key={i}>
                                  <Table.Cell>{file.name}</Table.Cell>
                                  <Table.Cell>
                                    {(file.size / 1024).toFixed(2)}Kb
                                  </Table.Cell>
                                  <Table.Cell>
                                    {file?.lastModifiedDate?.toDateString()}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {upload_file_1.length > 0 ? (
                                      <IconButton
                                        color="secondary"
                                        className="i-btn-two"
                                        component="button"
                                        onClick={() => handleRemove(i)}
                                      >
                                        <RemoveCircleOutlineIcon />
                                      </IconButton>
                                    ) : null}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {upload_file_1.length > 0 ? (
                                      <CheckIcon
                                        color="secondary"
                                        onClick={handleSend}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "1.3rem",
                                          verticalAlign: "middle",
                                        }}
                                      />
                                    ) : null}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                          </Table.Body>
                        </Table>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </form>
    </LandingPageLayout>
  );
};

export default FormOne;
