import { CHANGE_TIMESHEET_INPUT, UPDATE_TIMESHEET } from "./actionTypes";

export const changeTimesheetInput = data => ({
    type: CHANGE_TIMESHEET_INPUT,
    payload: data
})

export const updateTimesheet = data => ({
    type: UPDATE_TIMESHEET,
    payload: data
})