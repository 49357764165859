import { IconButton, Input, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table } from 'semantic-ui-react';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CustomSemanticDD from 'components/Common/CustomSemanticDD';
import { DURATION } from 'data';
import { useDispatch, useSelector } from 'react-redux';
import { changeTimesheetInput } from 'redux/actions/timesheetAction';
import CustomTime from 'components/Common/CustomTime';

const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    container: {
      maxHeight: 440,
    },
  });

const Items = ({details}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [edit, setEdit] = useState(false)
    const {data} = useSelector(state => state.timesheetReducer)
    const dispatch = useDispatch()
    // const [state, setState] = useState({
    //     level: '',
    //     gender: '',
    //     start_date: '',
    //     end_date: '',
    //     start_time: '',
    //     start_time: '',
    //     end_time: ''
    // })

    const {
        start_date,
        end_date,
        start_time,
        end_time,
        duration,
    } = data

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    const handleChange = e => {
        // setState({...state, [e.target.name] : e.target.value})
        dispatch(changeTimesheetInput({
          name: e.target.name,
          value: e.target.value
        })) 
    }

    const handleEdit = e => {
      setEdit(true) 
    }

    const handleDone = e => {
      setEdit(false)
    }
    return (
          <Table celled>
          <Table.Header>
          <Table.Row>
            <Table.HeaderCell width= '5'>Job Details</Table.HeaderCell>
            <Table.HeaderCell width= '3'>As Requested</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
          <Table.Body>
           
            {/* {unverifiedLinguistList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list) => ( */}
            <Table.Row hover= 'true' role="checkbox" tabIndex={-1} >   
              <Table.Cell>Level of expertise required source / native language ({details.quoteRequstByCashclient.source_language})</Table.Cell>
              <Table.Cell>{details.quoteRequstByCashclient.source_skills_required}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Level of expertise required target language ({details.quoteRequstByCashclient.target_language})</Table.Cell>
              <Table.Cell>{details.quoteRequstByCashclient.target_skills_required}</Table.Cell>
            </Table.Row>
            
            <Table.Row>
              <Table.Cell>Timezone</Table.Cell>
              <Table.Cell>{details.quoteRequstByCashclient.timezone}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Appointment start date</Table.Cell>
              <Table.Cell>
                <TextField
                  id="date"
                  label="Start date"
                  value= {start_date}
                  name= 'start_date'
                  onChange= {handleChange}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Appointment end date</Table.Cell>
              <Table.Cell>
                <TextField
                  id="date"
                  label="End date"
                  value= {end_date}
                  name= 'end_date'
                  onChange= {handleChange}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Start time</Table.Cell>
              <Table.Cell><CustomTime name= 'start_time' value= {start_time} onChange= {handleChange} label="Start time" /></Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>End time</Table.Cell>
              <Table.Cell><CustomTime name= 'end_time' value= {end_time} onChange= {handleChange} label="End time"/></Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Duration</Table.Cell>
              <Table.Cell></Table.Cell> 
            </Table.Row>

              {/* ))} */}
          </Table.Body>
        </Table>
    )
}

export default Items
