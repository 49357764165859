import React, {  } from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Options from "components/Employee/BookingPanel/PrivateQuote/Options"
import SavedPRC from "components/Employee/BookingPanel/PrivateQuote/PrivateSavedQuote";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";




const AssignmentPage = () => {
  const classes = useDashboardStyles();


  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>Private Client Quote (Saved)</p>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <Options />
                  <IconButton color='inherit' style={{ marginLeft: 5 }} onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
                </div>

              </CardHeader>
              <CardBody>
                <SavedPRC />
              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default AssignmentPage;
