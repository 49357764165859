import SearchInput from "./SearchInput";

const SearchView = ({
  setSearchText,
  setShow,
  SearchText,
  handleSearch,
  load,
  show,
}) => {
  return (
    <div>
      <SearchInput
        load={load}
        setSearchText={setSearchText}
        setShow={setShow}
        SearchText={SearchText}
        handleSearch={handleSearch}
        show={show}
      />
    </div>
  );
};

export default SearchView;
