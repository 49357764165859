import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import CustomDate from "components/Common/CustomDate";
import CustomUpload from "components/Common/CustomUpload";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { autoSaveER, changeErInput, updateErStep } from "redux/actions/erAction";
import CustomLabel from "components/Common/CustomLabel";

const FormFive = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.erReducer);

  const {
    institute_name,
    date_of_graduation,
    name_of_degree,
    subject_studied,
    relevant_certificate_photo,
  }= data

  const handleChange = e => {
    const { name, value }= e.target
    dispatch(changeErInput({
        name,
        value
    }))
  }

  const handleDateChange = date => {
    dispatch(changeErInput({
        name: 'date_of_graduation',
        value: date
    }))
  }

  useEffect(() => {
    const temp= JSON.parse(localStorage.getItem('userER'))
    dispatch(autoSaveER({
      temp
    }))
  }, [dispatch])

  useEffect(() => {
    localStorage.setItem('userER', JSON.stringify(data))
  }, [data])

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateErStep(6));
  };

  const handleBack = () => {
    dispatch(updateErStep(4));
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomLabel label= 'General qualification' moreInfo= 'Please add only your highest degree/qualification' />
        <CustomInput
          onChange= {handleChange}
          width="100%"
          label="Name of the institute / school / college / university"
          name= 'institute_name'
          value= {institute_name}
        />
        <CustomDate
        onChange= {handleDateChange}
        label= 'Year / date of graduation or course completion'
        width= '100%'
        name= 'date_of_graduation'
        value= {date_of_graduation}
        />
        <CustomInput
          onChange= {handleChange}
          width="100%"
          label="Name of the degree / course / diploma / qualification / CPD obtained"
          name= 'name_of_degree'
          value= {name_of_degree}
        />
        <CustomInput
          onChange= {handleChange}
          width="100%"
          label="Subjects studied"
          name= 'subject_studied'
          value= {subject_studied}
        />
        <CustomUpload
          onChange= {handleChange}
          width= '100%'
          label= 'Upload relevant certificate and any reference letter (for your highest qualification)'
          name= 'relevant_certificate_photo'
          value= {relevant_certificate_photo}
        />
        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton text="Next" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default FormFive;
