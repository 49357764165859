import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Header } from "semantic-ui-react";
import Cookies from 'js-cookie'
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import SPPOProfile from 'components/Sppo/PublicProfileSPPO/ApprovalPublicProfile/PPS'




const AssignmentPage = () => {
    const classes = useDashboardStyles();
    const [crm_panel, setAdminAccess] = useState(null)

    const getLinguistDetails = async (e) => {
        const token = Cookies.get("token");
        const response = await Axios.get(
            "/api/manager-admin/dashboard",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        setAdminAccess(response?.data.crm_panel);
    };
    useEffect(() => {
        getLinguistDetails();
    }, []);


    return (
        <MasterLayout>
            <Manager>
                <GridContainer>
                    {crm_panel ?
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                                    <p className={classes.cardTitleWhite}>Approval Public Profile</p>
                                    <IconButton color='inherit' style={{ marginLeft: 5 }} onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
                                </CardHeader>
                                <CardBody>
                                    <SPPOProfile />
                                </CardBody>
                            </Card>
                        </GridItem>

                        :
                        crm_panel === 0 ?
                            <Header as='h3'>Access denied</Header>
                            :
                            <Header as='h3'>Loading...</Header>
                    }

                </GridContainer>
            </Manager>
        </MasterLayout>
    );
};

export default AssignmentPage;
