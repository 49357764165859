import React, { useState } from 'react'

//Custom style

//Axios
import Axios from 'axios'

//Cookies
import Cookies from 'js-cookie'

//Redux imports
import { useDispatch, useSelector } from 'react-redux'
import { changeLinguistProfileInput } from 'redux/actions/updateProfileAction';

//Sweet alert
import swal from 'sweetalert'

//hisory
import { useHistory } from 'react-router-dom';
import { Checkbox, Dimmer, Loader, Table } from "semantic-ui-react";

//Level allocation



const AllocateAccess = ({ id }) => {
    const history = useHistory()
    const token = Cookies.get('SPPOtoken')
    const { data } = useSelector(state => state.updateProfileReducer)
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)


    const handleVerifyAccess = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/login-access/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Login Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const handleUnverifyAccess = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-login-access/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Login Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableMyClient = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-my-client/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'My Client Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'access_my_client',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableMyClient = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-my-client/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'My Client Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'access_my_client',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableMyLead = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-my-lead/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'My Lead Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'my_lead_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableMyLead = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-my-lead/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'My Lead Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'my_lead_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableInhouse = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-manage-inhouse/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Manage In-house Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'manage_inhouse_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableInhouse = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-manage-inhouse/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Manage In-house Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'manage_inhouse_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableQuotation = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-direct-quote-invoice/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Direct Quote And Invoice Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'direct_quote_invoice_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableQuotation = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-direct-quote-invoice/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Direct Quote And Invoice Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'direct_quote_invoice_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }

    const enableProject = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-project/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Project Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'project_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableProject = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-project/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Project Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'project_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableCommunication = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-communication/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Communication Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'communication_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableCommunication = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-communication/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Communication Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'communication_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }



    const enableInvoicing = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-invoicing/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Invoicing Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'invoicing_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableInvoicing = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-invoicing/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Invoicing Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'invoicing_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }




    const enableFindLin = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-find-linguist/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Find Linguist Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'find_linguist_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableFindLin = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-find-linguist/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Find Linguist Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'find_linguist_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableManageTeam = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-manage-team/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Manage Team Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'manage_team_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableManageTeam = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-manage-team/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Manage Team Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'manage_team_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }



    const enableNotes = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-colleague-note/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Colleague Note Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'colleague_note_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableNotes = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-colleague-note/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Colleague Note Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'colleague_note_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableManagePublicProfile = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/access-manage-public-profile/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Manage Public Profile Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'manage_public_profile_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableManagePublicProfile = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/sppo/disable-manage-public-profile/sppoID/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Manage Public Profile Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'manage_public_profile_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }













    const handleToggle = async (value) => {
        
            if (!data.access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                handleVerifyAccess(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                handleUnverifyAccess(value);

                            default:
                                break;
                        }
                    });
            }
        
    }



    
    const handleMyClient = async (value) => {
        if (data.access) {
            if (!data.access_my_client) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableMyClient(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableMyClient(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }


    const handleMyLead = async (value) => {
        if (data.access) {
            if (!data.my_lead_access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableMyLead(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableMyLead(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }



    
    const handleInhouse = async (value) => {
        if (data.access) {
            if (!data.manage_inhouse_access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableInhouse(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableInhouse(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }


    
    const handleQuotation = async (value) => {
        if (data.access) {
            if (!data.direct_quote_invoice_access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableQuotation(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableQuotation(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }


    
    const handleProject = async (value) => {
        if (data.access) {
            if (!data.project_access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableProject(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableProject(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }

    
    const handleCommunication = async (value) => {
        if (data.access) {
            if (!data.communication_access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableCommunication(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableCommunication(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }


    const handleInvoicing = async (value) => {
        if (data.access) {
            if (!data.invoicing_access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableInvoicing(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableInvoicing(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }

    
    const handleFindLin = async (value) => {
        if (data.access) {
            if (!data.find_linguist_access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableFindLin(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableFindLin(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }


    
    const handleManageTeam = async (value) => {
        if (data.access) {
            if (!data.manage_team_access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableManageTeam(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableManageTeam(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }



    const handleNotes = async (value) => {
        if (data.access) {
            if (!data.colleague_note_access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableNotes(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableNotes(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }


    
    const handlePublicProfile = async (value) => {
        if (data.access) {
            if (!data.manage_public_profile_access) {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableManagePublicProfile(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableManagePublicProfile(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }


  

    return (
        <>
            {loader && (
                <Dimmer active inverted>
                    <Loader size='large'>Changing Access</Loader>
                </Dimmer>
            )}

            <Table celled structured color={"pink"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width='1' textAlign='center'>Access</Table.HeaderCell>
                        <Table.HeaderCell width='1' textAlign='center'>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Login / Access Permission</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.access} onChange={() => handleToggle('access')} />
                        </Table.Cell>
                    </Table.Row>

                   
                     <Table.Row >
                        <Table.Cell width='1' textAlign='center'>My Clients</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.access_my_client} onChange={() => handleMyClient('access_my_client')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>My Leads</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.my_lead_access} onChange={() => handleMyLead('my_lead_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Manage In-house Clients</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.manage_inhouse_access} onChange={() => handleInhouse('manage_inhouse_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Direct Quote and Invoice</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.direct_quote_invoice_access} onChange={() => handleQuotation('direct_quote_invoice_access')} />
                        </Table.Cell>
                    </Table.Row>

                   

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Communication</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.communication_access} onChange={() => handleCommunication('communication_access')} />
                        </Table.Cell>
                    </Table.Row>

                   
                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Find Linguist</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.find_linguist_access} onChange={() => handleFindLin('find_linguist_access')} />
                        </Table.Cell>
                    </Table.Row>
                    

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Invoicing</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.invoicing_access} onChange={() => handleInvoicing('invoicing_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Project</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.project_access} onChange={() => handleProject('project_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Manage Team</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.manage_team_access} onChange={() => handleManageTeam('manage_team_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Colleague Note</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.colleague_note_access} onChange={() => handleNotes('colleague_note_access')} />
                        </Table.Cell>
                    </Table.Row>

                    {/* <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Manage Public Profile</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.manage_public_profile_access} onChange={() => handlePublicProfile('manage_public_profile_access')} />
                        </Table.Cell>
                    </Table.Row> */}


                </Table.Body>
            </Table>
        </>
    )
}

export default AllocateAccess
