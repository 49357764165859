import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import { useParams } from "react-router";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import UnconfirmedOptions from "components/CashClient/Assignments/ConfirmAssignments/ConfirmOptions";
import ListTranslations from "components/CashClient/Assignments/ConfirmAssignments/ListTranslations/ListTranslations";

const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  const { data, type } = useParams();
  return (
    <Cashclient>
      <CashClientLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{ display: "flex", justifyContent: "space-between" }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Confirmed Translation Assignments
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <UnconfirmedOptions />
              </CardHeader>

              <CardBody>
                <ListTranslations
                  translationData={type === "translation" && data}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </CashClientLayout>
    </Cashclient>
  );
};

export default UnconfirmedAssignmentsPage;
