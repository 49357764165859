import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import LinguistLayout from "layout/LinguistLayout";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import BookingOptions from "components/Linguist/ManagePrivateClient/BookingOption";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import SendBookingFormPrivate from "components/Linguist/ManagePrivateClient/Form/SendBookingForm/SendBookingFormPrivate";

const SendBookingFormPrivatePage = () => {
  const classes = useDashboardStyles();
  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Send Booking Form</p>
                <IconButton color='inherit'><ArrowBackIcon

                  onClick={() => history.goBack()}
                ></ArrowBackIcon></IconButton>
              </CardHeader>

            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BookingOptions />
              </CardHeader>

            </Card>
            <SendBookingFormPrivate />
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default SendBookingFormPrivatePage;
