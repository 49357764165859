import { IconButton, Input } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table } from "semantic-ui-react";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
import { DURATION } from "data";
import { useDispatch, useSelector } from "react-redux";
import { changeTimesheetInput } from "redux/actions/timesheetAction";
import CustomInputSingle from "components/Common/CustomInputSingle";

import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const Items = ({ details, linguist }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [edit, setEdit] = useState(false);
  const { data } = useSelector((state) => state.timesheetReducer);
  const [duration, setDuration] = useState();
  const [timeEdit, setTimeEdit] = useState(false);
  const [time, setTime] = useState();
  const [timeChanged, setTimeChanged] = useState(false);
  let overDays = 0;
  const [changed, setChanged] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    level: "",
    gender: "",
    start_date: "",
    end_date: "",
    start_time: "",
    start_time: "",
    end_time: "",
  });

  const {
    start_date,
    end_date,
    start_time,
    end_time,
    // duration,
  } = data;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (e) => {
    // setState({...state, [e.target.name] : e.target.value})
    dispatch(
      changeTimesheetInput({
        name: e.target.name,
        value: e.target.value,
      })
    );
  };
  const handleTimeDone = (e) => {
    setTimeEdit(false);
    dispatch(
      changeTimesheetInput({
        name: "start_time",
        value: time,
      })
    );
    setTimeChanged(true);
  };
  const handleTimeEdit = (e) => {
    setTimeEdit(true);
    // setChanged(false)
  };

  const handleEdit = (e) => {
    setEdit(true);
    // setChanged(false)
  };

  const handleDone = (e) => {
    setEdit(false);
    dispatch(
      changeTimesheetInput({
        name: "duration",
        value: duration,
      })
    );
    setChanged(true);
  };

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="5">Job Details</Table.HeaderCell>
          <Table.HeaderCell width="3">Requested</Table.HeaderCell>
          <Table.HeaderCell width="3">Actual</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {/* {unverifiedLinguistList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list) => ( */}
        <Table.Row hover="true" role="checkbox" tabIndex={-1}>
          <Table.Cell>
            Level of expertise required source / native language (
            {details?.linID?.source_language})
          </Table.Cell>
          <Table.Cell>{details?.source_linguist_skill_level}</Table.Cell>
          <Table.Cell>{details?.linID?.source_language_level}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            Level of expertise required target language (
            {details?.linID?.target_language})
          </Table.Cell>
          <Table.Cell>{details?.target_linguist_skill_level}</Table.Cell>
          <Table.Cell>{details?.linID?.target_language_level}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Timezone</Table.Cell>
          <Table.Cell>{details?.timezone}</Table.Cell>
          <Table.Cell>{details?.timezone}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Appointment start date</Table.Cell>
          <Table.Cell>
            {details?.startdateArray.map((date, i) =>
              i !== details?.startdateArray.length - 1 ? date + ", " : date
            )}
          </Table.Cell>
          <Table.Cell>
            {details?.startdateArray.map((date, i) =>
              i !== details?.startdateArray.length - 1 ? date + ", " : date
            )}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Start time</Table.Cell>
          <Table.Cell>
            {details?.starttimeArray.map((date, i) =>
              i !== details?.starttimeArray.length - 1 ? date + ", " : date
            )}
          </Table.Cell>
          <Table.Cell>
            {" "}
            <>
              {timeEdit ? (
                <div style={{ display: "flex" }}>
                  <CustomInputSingle
                    label="Add duration"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                  <IconButton onClick={handleTimeDone}>
                    <DoneIcon />
                  </IconButton>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {timeChanged
                    ? time
                    : details?.starttimeArray.map((date, i) =>
                        i !== details?.starttimeArray.length - 1
                          ? date + ", "
                          : date
                      )}
                  <IconButton onClick={handleTimeEdit}>
                    <EditIcon />
                  </IconButton>
                </div>
              )}
            </>
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Duration</Table.Cell>
          <Table.Cell>{details?.gethour}</Table.Cell>
          <Table.Cell>
            <>
              {edit ? (
                <div style={{ display: "flex" }}>
                  <CustomSemanticDD
                    label="Add duration"
                    data={DURATION}
                    onChange={(e) => setDuration(e.target.value)}
                  />
                  <IconButton onClick={handleDone}>
                    <DoneIcon />
                  </IconButton>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {changed ? duration : details?.gethour}
                  <IconButton onClick={handleEdit}>
                    <EditIcon />
                  </IconButton>
                </div>
              )}
            </>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default Items;
