import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-number-input/style.css";
// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomPhone from "components/Common/CustomPhone";
import CustomCaptcha from "components/Common/CustomCaptcha";

// Other
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { CURRENT_COUNTRY, EMAIL_REGEX } from "data";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import {
  changeInhouseClientInput,
  updateInhouseClientStep,
} from "redux/actions/icrAction";
import Axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";

const FormTwo = () => {
  const { data } = useSelector((state) => state.icrReducer);
  const [notValid, setNotValid] = useState(false);
  const [captcha, setCaptcha] = useState("");

  const {
    profile_photo,
    passport_ni_photo,
    title,
    FirstName,
    LastName,
    Birthday,
    sex,
    email,
    mobile_no,
    direct_line,
    AddressLine1,
    AddressLine2,
    City,
    PostalCode,
    Region,
    Country,
    Nationality,
    other_nationality,
    passNumber,
    confirm_passNumber,
    business_name,
    position,
    business_email,
    business_phone_no,
    business_address,
    business_address_2,
    business_town,
    business_zipcode,
    business_region,
    business_country,
    charity_registration_no,
    company_registration_no,
    business_website,
    note,
    gdpr,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: value || checked,
    };
    dispatch(changeInhouseClientInput(inputData));
  };

  const handleChangeCO = (e, value) => {
    dispatch(
      changeInhouseClientInput({
        name: "business_country",
        value,
      })
    );
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeInhouseClientInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleMobile = (value) => {
    dispatch(
      changeInhouseClientInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    dispatch(
      changeInhouseClientInput({
        name,
        value: !gdpr,
      })
    );
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
    const isHuman = await fetch(
      `https://www.google.com/recaptcha/api/siteverify`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
        mode: "no-cors",
        credentials: "omit",
        body: `secret=6LdPUwMaAAAAAK7JyeaYyRPz4NeNyC2XoQthvK8h&response=6LdPUwMaAAAAALqroyySr9kV-TXA7sxKx2qzvSnC`,
      }
    );
  };

  const handleNext = async (e) => {
    const token = Cookies.get("LINtoken");
    let formData = new FormData();
    formData.append("profile_photo", profile_photo);
    formData.append("passport_ni_photo", passport_ni_photo);
    formData.append("title", title);
    formData.append("FirstName", FirstName);
    formData.append("LastName", LastName);
    formData.append("Birthday", Birthday);
    formData.append("sex", sex);
    formData.append("email", email);
    formData.append("mobile_no", mobile_no);
    formData.append("direct_line", direct_line);
    formData.append("AddressLine1", AddressLine1);
    formData.append("AddressLine2", AddressLine2);
    formData.append("City", City);
    formData.append("PostalCode", PostalCode);
    formData.append("Region", Region);
    formData.append("Country", Country);
    formData.append("Nationality", Nationality);
    formData.append("other_nationality", other_nationality);
    formData.append("passNumber", passNumber);
    formData.append("confirm_passNumber", confirm_passNumber);
    formData.append("business_name", business_name);
    formData.append("position", position);
    formData.append("business_email", business_email);
    formData.append("business_phone_no", business_phone_no);
    formData.append("business_address", business_address);
    formData.append("business_address_2", business_address_2);
    formData.append("business_town", business_town);
    formData.append("business_zipcode", business_zipcode);
    formData.append("business_region", business_region);
    formData.append("business_country", business_country);
    formData.append("charity_registration_no", charity_registration_no);
    formData.append("company_registration_no", company_registration_no);
    formData.append("business_website", business_website);
    formData.append("note", note);
    formData.append("gdpr", gdpr);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/private/pre-signup`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        title: "Success!",
        text: `Email sent to client`,
        icon: "success",
        buttons: false,
      }).then(() => {
        window.location = "/";
      });
    } catch (error) {
      console.log(error.response);
      swal({
        title: "Oops!",
        text: `${error.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleBack = (e) => {
    dispatch(updateInhouseClientStep(1));
  };
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInput
        onChange={handleChange}
        value={business_name}
        name="business_name"
        label="Business or organisation name"
        width="100%"
        placeholder="Trading name of your business / organisation. Otherwise please write N/A"
      />
      <CustomInput
        onChange={handleChange}
        value={position}
        name="position"
        label="Your position"
        width="100%"
        placeholder="e.g. Manager, Director etc"
      />
      <CustomBigInputSingle
        onChange={handleEmailChange}
        value={business_email}
        name="business_email"
        label="Business email address"
        moreInfo="If different"
        width="100%"
        placeholder="e.g. example@example.com"
      />
      {business_email && notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomPhone
        onChange={handleMobile}
        value={business_phone_no}
        name="business_phone_no"
        label="Business phone number"
        width="100%"
        placeholder="Business Phone Number (Please select country then type number e.g. +44 7307 000000)"
      />
      {/* *Developer allow select same as before or add new with 5 fields including post code and country */}
      <CustomBigInput
        onChange={handleChange}
        label="House number/name, Street name"
        moreInfo="main contact address (if different)"
        width="100%"
        name="business_address"
        value={business_address}
        placeholder="e.g. Flat/Building Name/No, 101 Example Street"
      />
      <CustomInput
        onChange={handleChange}
        label="Address line 2"
        width="100%"
        name="business_address_2"
        value={business_address_2}
        placeholder="Additional Address Information"
      />
      <CustomInput
        onChange={handleChange}
        label="Town / City"
        width="100%"
        name="business_town"
        value={business_town}
        placeholder="Your Village, Town or City Name"
      />

      <CustomInput
        onChange={handleChange}
        label="County / Region"
        width="100%"
        name="business_region"
        value={business_region}
        placeholder="Your County, Division or State Name"
      />
      <CustomInput
        onChange={handleChange}
        label="Post / Zip Code"
        width="100%"
        name="business_zipcode"
        value={business_zipcode}
        placeholder="Your Post Code, Zip Code or Post Office Name"
      />
      <CustomAutoSelect
        onChange={handleChangeCO}
        label="Country"
        width="100%"
        name="business_country"
        value={business_country}
        data={CURRENT_COUNTRY}
      />
      <CustomBigInput
        onChange={handleChange}
        label="Company registration number"
        moreInfo="If registered"
        width="100%"
        name="company_registration_no"
        value={company_registration_no}
        placeholder="if registered please provide CRN"
      />
      <CustomBigInput
        onChange={handleChange}
        label="Charity registration number"
        moreInfo="If registered"
        width="100%"
        name="charity_registration_no"
        value={charity_registration_no}
        placeholder="if registered please provide Charity Number and Registered Country"
      />
      <CustomInput
        onChange={handleChange}
        value={business_website}
        name="business_website"
        label="Business website"
        width="100%"
        placeholder="e.g. www.YourCompanyWebsite.com"
      />
      <CustomInput
        onChange={handleChange}
        value={note}
        name="note"
        label="Additional information or note"
        width="100%"
        placeholder="Enquery Details..."
      />

      <div
        className={classes.tc}
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <p>
          <Checkbox
            onChange={handleCheckbox}
            checked={gdpr}
            name="gdpr"
            color="primary"
          />
          By submitting this form I agree with Linguists Collective
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/privacy-policy"
          >
            Privacy Policy{" "}
          </a>
          and{" "}
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/terms-and-conditions"
          >
            {" "}
            T&C
          </a>
        </p>

        <CustomCaptcha onChange={handleCaptcha} />
      </div>

      <div className={classes.buttonFlex}>
        <CustomButton
          text="Back"
          variant="outlined"
          color="primary"
          onClick={handleBack}
        />
        <CustomButton
          disabled={!gdpr || !captcha}
          text="Submit"
          onClick={handleNext}
        />
      </div>
    </form>
  );
};

export default FormTwo;
