import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";

import SearchIcon from "@material-ui/icons/Search";
import { Button } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "45%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
      ['@media (max-width:768px)']:{
        width:'100%'
      },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearchInput = ({ load,setSearchText,searchText, handleSearch }) => {
  const classes = useStyles();
  const handleInput = (value)=>{
    setSearchText(value)
    if(value==''||value==undefined)
    load()
  }
  return (
    <div>
      <Paper onClick={e=>e.preventDefault()}  component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Enter private client ID"
          onChange={(e) => {
            handleInput(e.target.value);
          }}
          inputProps={{ "aria-label": "search google maps" }}
          value={searchText}
          // onChange={(e) => setText(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Button onClick={handleSearch} color= 'purple'>Find a private client</Button>
      </Paper>
    </div>
  );
};

export default SearchInput;
