import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Action from "../Action";
import Moment from "moment";
import { Dimmer, Loader, Table } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";
import Axios from "axios";
import SearchView from "./SearchView/SearchView";
import Cookies from "js-cookie";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("token");
  const [paidModal, setPaidModal] = React.useState(false);
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const { addToast } = useToasts();
  const [pay, setPay] = useState({
    note: "",
    linID: "",
    genID: "",
    name: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    verifiedLinguistList: null,
    removed: false,
    reload: false,
    loader: false,
  });

  const { name, error, success, verifiedLinguistList, removed, reload } =
    values;

  let source = Axios.CancelToken.source();
  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/verified/linguists`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      );
      if (filtered.length > 0) {
        setValues({
          ...values,
          verifiedLinguistList: filtered,
        });
        setFiltered([]);
      } else {
        setValues({ ...values, verifiedLinguistList: response?.data });
      }
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("Request cancelled");
      } else {
        console.log(error.response);
      }
    }
  };

  useEffect(() => {
    loadList();

    return () => {
      source.cancel();
    };
  }, [reload, filter]);

  if (!verifiedLinguistList) {
    return (
      <Dimmer active inverted>
        <Loader size="medium">Loading..</Loader>
      </Dimmer>
    );
  }

  const handleSearch = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-verified-linguist/linID`,
        { id: SearchText }
      );
      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct linguits id.", {
          appearance: "error",
        });
      } else if (response?.data?.length > 0) {
        setValues({
          ...values,
          verifiedLinguistList: response?.data,
          reload: false,
        });
        addToast("Linguits found", { appearance: "success" });
      } else {
        addToast("Linguits not found", { appearance: "error" });
        setShow(false);
      }
      if (values.verifiedLinguistList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct linguits id.", {
          appearance: "error",
        });
        setShow(false);
      }
    }
  };

  return (
    <>
      <div>
        <SearchView
          load={loadList}
          SearchText={SearchText}
          setSearchText={setSearchText}
          handleSearch={handleSearch}
          setShow={setShow}
          setFiltered={setFiltered}
          setFilter={setFilter}
          values={verifiedLinguistList}
          show={show}
        />
        <Paper className={classes.root}>
          {show ? (
            <div>
              <TableContainer className={classes.container}>
                <Table singleLine color="pink">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Unique ID</Table.HeaderCell>
                      <Table.HeaderCell>Registration Date</Table.HeaderCell>
                      <Table.HeaderCell>First Name</Table.HeaderCell>
                      <Table.HeaderCell>Last Name</Table.HeaderCell>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.HeaderCell>Location</Table.HeaderCell>
                      <Table.HeaderCell>
                        Source / Native language
                      </Table.HeaderCell>
                      <Table.HeaderCell>Target language</Table.HeaderCell>
                      <Table.HeaderCell>Target language Level</Table.HeaderCell>
                      <Table.HeaderCell>Position</Table.HeaderCell>
                      <Table.HeaderCell>LIN Group</Table.HeaderCell>
                      <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  {verifiedLinguistList.length > 0 ? (
                    <Table.Body>
                      {verifiedLinguistList
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((list) => (
                          <Table.Row
                            role="checkbox"
                            tabIndex={-1}
                            key={list._id}
                            style={
                              list?.position == "Linguist"
                                ? { backgroundColor: "#c5a6cf" }
                                : list?.position == "Professional"
                                ? { backgroundColor: "#c5e1c8" }
                                : list?.position == "Contractor"
                                ? { backgroundColor: "#b7e1f9" }
                                : { backgroundColor: "#f9cfe3" }
                            }
                          >
                            <Table.Cell key={list._id} align={list.align}>
                              <Link
                                to={`/admin/view-linguist-profile/${list._id}`}
                              >
                                {list.id}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>
                              <br />
                              {Moment(list?.createdAt).format(
                                "DD MMM YYYY - kk:mm:ss"
                              )}
                            </Table.Cell>
                            <Table.Cell>{list.FirstName}</Table.Cell>
                            <Table.Cell>{list.LastName}</Table.Cell>
                            <Table.Cell>{list.email}</Table.Cell>
                            <Table.Cell>{`${list.City} - ${list.Country}`}</Table.Cell>

                            <Table.Cell>
                              {list.source_language}
                              <br />
                              {list.source_language_1}
                              <br />
                              {list.source_language_2}
                            </Table.Cell>

                            <Table.Cell>
                              {list.target_language}
                              <br />
                              {list.target_language_1}
                              <br />
                              {list.target_language_2}
                            </Table.Cell>
                            <Table.Cell>
                              {list.target_language_level}
                              <br />
                              {list.target_language_level_1}
                              <br />
                              {list.target_language_level_2}
                            </Table.Cell>
                            <Table.Cell>{list.position}</Table.Cell>
                            <Table.Cell>{list.contact_group}</Table.Cell>
                            <Table.Cell>
                              <Action
                                load={loadList}
                                id={list._id}
                                ID={list.id}
                                name={list.FirstName + " " + list.LastName}
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  ) : null}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={verifiedLinguistList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          ) : null}
        </Paper>
      </div>
    </>
  );
}
