import React, { useEffect, useState } from "react";
import "../PPL/LinguistProfile.css";

// Custom Imports
import Header from "../PPL/Header/Header";
import SecurityClearance from "../PPL/SecurityClearance/SecurityClearance";
import Verified from "../PPL/Verified/Verified";
import About from "../PPL/About/About";
import WorkExperience from "../PPL/WorkExperience/WorkExperience";
import Qualification from "../PPL/Qualification/Qualification";
import ProfMemberShip from "../PPL/ProfMemberShip/ProfMemberShip";
import Footer from "../PPL/Footer/Footer";

import { updateLinguistProfile } from "redux/actions/updateProfileAction";
import Axios from "axios";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import { useLocation, useParams } from "react-router";
import useEmployeeStyles from "assets/jss/components/employeeStyle";

function PPL() {
  const classes = useEmployeeStyles();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/public/profile-using-only-number-id/${id}`
      );
      dispatch(updateLinguistProfile(response?.data));
      setData(response?.data);
      if (response?.data.adminAllowPublicProfile === false) {
        window.location = "/";
      }
    } catch (error) {
      console.log(error.response);

    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <div className={classes.root}>
      {data?._id ?
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={8} lg={8}>
            {data?.full_public_profile_section ? (
              <Card>
                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                    padding: "20px",
                    boxShadow:
                      "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                  }}
                >
                  <Header id={data._id} />
                </div>

                {data.rating_section ? (
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                      padding: "20px",
                      boxShadow:
                        "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                    }}
                  >
                    <Verified id={data._id} />
                  </div>
                ) : null}

                {data.professional_membership_section ? (
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                      padding: "20px",
                      boxShadow:
                        "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                    }}
                  >
                    <ProfMemberShip id={data._id} />
                  </div>
                ) : null}

                {data.security_clearance_section ? (
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                      padding: "20px",
                      boxShadow:
                        "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                    }}
                  >
                    <SecurityClearance id={data._id} />
                  </div>
                ) : null}

                {data.about_section ? (
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                      padding: "20px",
                      boxShadow:
                        "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                    }}
                  >
                    <About id={data._id} />
                  </div>
                ) : null}

                {data.qualification_section ? (
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                      padding: "20px",
                      boxShadow:
                        "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                    }}
                  >
                    <Qualification checkdata={data?.professional_qualification} id={data._id} />
                  </div>
                ) : null}

                {data.work_experience_section ? (
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                      padding: "20px",
                      boxShadow:
                        "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                    }}
                  >
                    <WorkExperience id={data._id} />
                  </div>
                ) : null}
                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",

                    boxShadow:
                      "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                  }}
                >
                  <Footer id={data._id} refID={data?.id} incAccess={data?.inhouseAccess} />
                </div>
              </Card>
            ) : (
              <Card>
                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                    padding: "20px",
                    boxShadow:
                      "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                  }}
                >
                  Linguist's Public Profile view restricted by the Linguist.
                </div>
              </Card>
            )}
          </GridItem>
        </GridContainer>
        :
        <Card>
          <div
            style={{
              backgroundImage:
                "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
              padding: "20px",
              boxShadow:
                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            }}
          >
            This page is not available
          </div>
        </Card>
      }
    </div>
  );
}

export default PPL;
