const { STORE_VALUES } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
  currency: "Pound £",
  serviceCompletionDate: "",
  spQuoteRef: "",
  invoiceService: "",
};

const invoiceReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_VALUES:
      return {
        ...state,
        [payload.name]: payload.value,
      };
    default:
      return state;
  }
};

export default invoiceReducer;
