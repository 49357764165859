import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetch from 'isomorphic-fetch';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Moment from 'moment';

const ViewContactRequest = () => {
  const history = useHistory()
  const classes = useDashboardStyles();
  const { id } = useParams();

  const [values, setValues] = useState({
    // title: "mst",
    // FirstName: "kona", 
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_API}/manager-admin/view/contactRequest/contactID/${id}`)
      .then(res => res.json())
      .then((result) => {
        setValues(result)
      })
  }, []);

  return (
    <EmployeeLayout>
      <Employee>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>Contact Request from - {values.first_name}</p>
            <IconButton color="inherit" onClick={() => history.goBack()}>
              <ArrowBackIcon />
            </IconButton>
          </CardHeader>
        </GridItem>

        <div raised style={{ marginLeft: '15%', marginRight: '15%' }}>

          <Card raised style={{ marginTop: '24px' }} >
            <CardBody>
              <h3 style={{ textAlign: "center" }}>Contact Request</h3>
            </CardBody>
          </Card>
          <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
            <CardHeader
              color="primary"
              style={{
                textAlign: 'center', fontWeight: 'bold', marginTop: 10, top: 35
              }}
            >
              <p className={classes.cardTitleWhite}>Detail Information </p>
            </CardHeader>
          </GridItem>
          <Card raised style={{ marginTop: '24px', padding: '30px', zIndex: 0 }} >
            <CardBody>
              {/* <h4 style={{textAlign: "center"}}></h4> */}
              <h2>Date: {Moment(values.createdAt).format('DD MMM YYYY - kk:mm:ss')}</h2>
              <p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 15, width: 100 + "%" }}>Name:<br /> {values.first_name} {values.last_name}</p>
              <p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 15, width: 100 + "%" }}>Email:<br /> {values.email} </p>
              <p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 15, width: 100 + "%" }}>Phone Number:<br /> {values.phone_no} </p>
              <p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 15, width: 100 + "%" }}>Mobile Number:<br /> {values.mobile_no}  </p>
              <p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 15, width: 100 + "%" }}>Message:<br /> {values.message}  </p>

            </CardBody>
          </Card>
          <br /><br /><br />
        </div>

      </Employee>
    </EmployeeLayout>
  );
};

export default ViewContactRequest;
