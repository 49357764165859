import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";

const Action = ({id}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch= useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
     >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => window.open(`/direct-quote-from-lc-accept/${id}`)}>View and Accept</MenuItem>
      </Menu>
    </div>
  );
};

export default Action;
