import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useSnackbar } from "notistack";

import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

// Material Icons
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";

// Others
import {
  setSelectedPercent,
  updatePercentRates,
} from "redux/actions/admin/controller";

const EditOption = ({ item }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { selectedPercent, loading } = useSelector(
    (state) => state.adminControlData
  );

  const handleEdit = () => {
    dispatch(setSelectedPercent(item));
  };

  const handleDone = async () => {
    //   check if total sum isn't 100
    const { labelOne, labelTwo, labelThree, labelFour, labelFive } = selectedPercent;

    let sum = 0;

    if (labelOne) sum += labelOne;
    if (labelTwo) sum += labelTwo;
    if (labelThree) sum += labelThree;
    if (labelFour) sum += labelFour;
    if (labelFive) sum += labelFive;

    if (sum !== 100 || sum !== 100.0) {
      //   alert("Total percent must be 100%");
      enqueueSnackbar("Total percent must be 100%", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      return;
    }

    await dispatch(updatePercentRates(selectedPercent));
  };

  return (
    <div>
      {selectedPercent && selectedPercent._id === item._id ? (
        <IconButton color="primary" aria-label="edit" onClick={handleDone}>
          {loading ? <CircularProgress /> : <DoneIcon />}
        </IconButton>
      ) : (
        <IconButton color="primary" aria-label="edit" onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      )}
    </div>
  );
};

export default EditOption;
