import React, { useState, useEffect } from "react";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Link } from "react-router-dom";
//Semantic UI imorts
import { Table } from "semantic-ui-react";

import Axios from "axios";
import moment from "moment";
//Sweet alert
import swal from "sweetalert";

//Cookies import
import Cookies from "js-cookie";

//Action
import Action from "../Action";
//search
import SearchView from "../TranslationSearch/SearchView";
import { useToasts } from "react-toast-notifications";
import TablePagination from "@material-ui/core/TablePagination";

const TransTableOne = ({ translationData }) => {
  const classes = useDashboardStyles();
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    translationList: null,
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const {
    name,
    error,
    success,
    translationList,
    cacAndBacAccess,
    removed,
    reload,
  } = values;
  let source = Axios.CancelToken.source();

  const loadtranslationList = () => {
    const linID = Cookies.get("linID");

    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/accountclient-current-Translation-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/cashclient-current-Translation-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      ),
      // Axios.get(`${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-booking-list/linID/${linID}`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`
      //   }
      // }),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      ),
    ]).then(
      Axios.spread((bacBooking, cacBooking, dashboard) => {
        const user1_2 = cacBooking.data;
        setValues({
          ...values,
          translationList: translationData
            ? user1_2.filter((item) => item.id === translationData)
            : user1_2,
          cacAndBacAccess: dashboard.data.cacAndBacAccess,
          reload: false,
        });
      })
    );
    translationData && setShow(true);
  };

  useEffect(() => {
    loadtranslationList();

    return () => {
      source.cancel();
    };
  }, [reload]);

  const handleAccept = async (id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/cashclient-Translation-accept/TranslationID/${id}`,
        { email: email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setValues({ ...values, reload: true });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-cashclient-current-Translation/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({
          ...values,
          translationList: [response?.data],
          reload: false,
        });

        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.translationList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };
  const decline = async (decline, id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/cashclient-Translation-reject/TranslationID/${id}`,
        { email: email, declines: decline },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setValues({ ...values, reload: true });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDecline = async (e, id, email) => {
    const declines = e.target.value;
    swal(`Are you sure you want to decline this job?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      // .then(function() {
      //   window.location = "/admin/unverified-linguists";
      // })
      .then((value) => {
        switch (value) {
          case "cancel":
            break;

          case "catch":
            decline(declines, id, email);

          default:
            break;
        }
      });
  };

  return (
    <div>
      {!translationData && (
        <SearchView
          show={show}
          load={loadtranslationList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          SearchText={SearchText}
          setShow={setShow}
        />
      )}
      <div>
        {show ? (
          <div style={{ overflowX: "scroll" }}>
            {" "}
            <Table color={"pink"}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                  <Table.HeaderCell>Document type</Table.HeaderCell>
                  <Table.HeaderCell>Timezone</Table.HeaderCell>
                  <Table.HeaderCell>Deadline</Table.HeaderCell>
                  <Table.HeaderCell>Source language</Table.HeaderCell>
                  <Table.HeaderCell>Target language</Table.HeaderCell>
                  <Table.HeaderCell>
                    Target language skill level
                  </Table.HeaderCell>
                  <Table.HeaderCell>Estimated word count</Table.HeaderCell>
                  <Table.HeaderCell>Estimate paid</Table.HeaderCell>
                  <Table.HeaderCell>Payment Status</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {translationList ? (
                <Table.Body>
                  {translationList.map((list) => (
                    <Table.Row>
                      <Table.Cell>
                        <Link
                          to={`/linguist/cac-view-translation-assignment/${list._id}`}
                        >
                          {list?.id}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{list.document_type}</Table.Cell>
                      <Table.Cell>{list.timezone}</Table.Cell>
                      <Table.Cell>
                        {moment(list?.deadline).format(
                          "DD-MMM-YYYY : HH:mm:ss"
                        )}
                      </Table.Cell>
                      <Table.Cell>{list.source_language}</Table.Cell>
                      <Table.Cell>{list.target_language}</Table.Cell>
                      <Table.Cell>
                        {list.target_expertise_level_required}
                      </Table.Cell>
                      <Table.Cell>{list.wordCount}</Table.Cell>
                      <Table.Cell>
                        {list?.currency_new?.substring(0, 3)} {list.estimate}
                      </Table.Cell>
                      <Table.Cell>{list.status}</Table.Cell>
                      <Table.Cell>
                        <Action client={list.id} id={list._id} trans={true} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              ) : null}
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={translationList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TransTableOne;
