import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { DropzoneArea } from "material-ui-dropzone";
import Typography from "@material-ui/core/Typography";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomCaptcha from "components/Common/CustomCaptcha";
// Other
import { updateCrStep, changeCrInput } from "redux/actions/crAction";
import { Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import CustomRadio from "components/Common/CustomRadio";

import { CURRENT_COUNTRY, PERSONAL_ADDR, EMAIL_REGEX } from "data";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPhone from "components/Common/CustomPhone";
import Axios from "utils/axios";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const FormTwo = ({ mainobjID, userID }) => {
  const { data } = useSelector((state) => state.crReducer);
  const [timeCount, setTimeCount] = useState([1]);
  const [notValid, setNotValid] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const { sppocontact } = useParams();

  const handleAdd = () => {
    setTimeCount([...timeCount, timeCount[0] + 1]);
  };
  const handleRemove = () => {
    const copiedTC = [...timeCount];
    copiedTC.pop();
    setTimeCount(copiedTC);
    const inputData = {
      name: `social_media_${timeCount.length}`,
      value: "",
    };
    const inputLinkData = {
      name: `social_media_link_${timeCount.length}`,
      value: "",
    };
    dispatch(changeCrInput(inputData));
    dispatch(changeCrInput(inputLinkData));
  };

  const {
    AddressLine1,
    AddressLine2,
    Birthday,
    City,
    Country,
    FirstName,
    LastName,
    Nationality,
    PostalCode,
    Region,
    business_address,
    business_address_2,
    business_country,
    business_email,
    business_name,
    business_phone_no,
    business_region,
    business_town,
    business_website,
    business_zipcode,
    company_registration_no,
    charity_registration_no,
    direct_line,
    contact_group,
    email,
    mobile_no,
    note,
    about_us,
    social_media_1,
    social_media_2,
    social_media_3,
    social_media_4,
    social_media_5,
    social_media_6,
    social_media_7,
    social_media_8,
    social_media_9,
    social_media_10,
    social_media_link_1,
    social_media_link_2,
    social_media_link_3,
    social_media_link_4,
    social_media_link_5,
    social_media_link_6,
    social_media_link_7,
    social_media_link_8,
    social_media_link_9,
    social_media_link_10,
    position,
    enquiry_reason,
    sex,
    title,
    photo,
    passport,
    quote_enquiry_file,
    gdpr,
    other_contacts,
    contact_address,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();
  const [addContact, setAddContact] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeCrInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleSocialChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeCrInput(inputData));
  };

  const handlegdprChange = (e, target) => {
    const inputData = {
      name: "gdpr",
      value: target,
    };
    dispatch(changeCrInput(inputData));
  };

  const handlePhone = (value) => {
    // inputData({ ...data, mobile_no: value });
    dispatch(
      changeCrInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const handleContactGroup = (e) => {
    const inputData = {
      name: "contact_group",
      value: e.target.innerText,
    };
    dispatch(changeCrInput(inputData));
    if (inputData.value === "Other") setAddContact(true);
  };

  const handleChangeCO = (e, value) => {
    dispatch(
      changeCrInput({
        name: "business_country",
        value,
      })
    );
  };

  const handleContact = () => {
    const inputData = {
      name: "other_contacts",
      value: other_contacts,
    };
    dispatch(changeCrInput(inputData));
    setAddContact(false);
  };

  const handleFiles = (file) => {
    const inputData = {
      name: "quote_enquiry_file",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
    const isHuman = await fetch(
      `https://www.google.com/recaptcha/api/siteverify`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
        mode: "no-cors",
        credentials: "omit",
        body: `secret=6LdPUwMaAAAAAK7JyeaYyRPz4NeNyC2XoQthvK8h&response=6LdPUwMaAAAAALqroyySr9kV-TXA7sxKx2qzvSnC`,
      }
    );
  };

  const handleNext = async (e) => {
    let formData = new FormData();

    formData.append("AddressLine1", AddressLine1 || "");
    formData.append("AddressLine2", AddressLine2 || "");
    formData.append("Birthday", Birthday || "");
    formData.append("City", City || "");
    formData.append("Country", Country || "");
    formData.append("FirstName", FirstName || "");
    formData.append("LastName", LastName || "");
    formData.append("Nationality", Nationality || "");
    formData.append("PostalCode", PostalCode || "");
    formData.append("Region", Region || "");
    formData.append("enquiry_reason", enquiry_reason || "");
    formData.append(
      "business_address",
      contact_address.includes("Same")
        ? AddressLine1
        : business_address
        ? business_address
        : ""
    );
    formData.append(
      "business_address_2",
      contact_address.includes("Same")
        ? AddressLine2
        : business_address_2
        ? business_address_2
        : ""
    );
    formData.append(
      "business_country",
      contact_address.includes("Same")
        ? Country
        : business_country
        ? business_country
        : ""
    );
    formData.append("business_email", business_email);
    formData.append("business_name", business_name || "");
    formData.append("business_phone_no", business_phone_no || "");
    formData.append(
      "business_region",
      contact_address.includes("Same")
        ? Region
        : business_region
        ? business_region
        : ""
    );
    formData.append(
      "business_town",
      contact_address.includes("Same")
        ? City
        : business_town
        ? business_town
        : ""
    );
    formData.append("business_website", business_website || "");
    formData.append(
      "business_zipcode",
      contact_address.includes("Same")
        ? PostalCode
        : business_zipcode
        ? business_zipcode
        : ""
    );
    formData.append("company_registration_no", company_registration_no || "");
    formData.append("about_us", about_us || "");
    formData.append("contact_group", contact_group || "");
    formData.append("direct_line", direct_line || "");
    formData.append("email", email || "");
    formData.append("gdpr", gdpr || "");
    formData.append("mobile_no", mobile_no || "");
    formData.append("note", note || "");
    formData.append("position", position || "");
    formData.append("sex", sex || "");
    formData.append("title", title || "");
    formData.append("social_media_1", social_media_1 || "");
    formData.append("social_media_2", social_media_2 || "");
    formData.append("social_media_3", social_media_3 || "");
    formData.append("social_media_4", social_media_4 || "");
    formData.append("social_media_5", social_media_5 || "");
    formData.append("social_media_6", social_media_6 || "");
    formData.append("social_media_7", social_media_7 || "");
    formData.append("social_media_8", social_media_8 || "");
    formData.append("social_media_9", social_media_9 || "");
    formData.append("social_media_10", social_media_10 || "");

    formData.append("social_link_1", social_media_link_1 || "");
    formData.append("social_link_2", social_media_link_2 || "");
    formData.append("social_link_3", social_media_link_3 || "");
    formData.append("social_link_4", social_media_link_4 || "");
    formData.append("social_link_5", social_media_link_5 || "");
    formData.append("social_link_6", social_media_link_6 || "");
    formData.append("social_link_7", social_media_link_7 || "");
    formData.append("social_link_8", social_media_link_8 || "");
    formData.append("social_link_9", social_media_link_9 || "");
    formData.append("social_link_10", social_media_link_10 || "");

    formData.append("charity_registration_no", charity_registration_no || "");

    formData.append("refID", mainobjID || "");
    formData.append("userID", userID || "");
    formData.append("selfReg", sppocontact || "");

    if (!photo?.contentType) {
      formData.append("photo", photo);
    }
    if (!passport?.contentType) {
      formData.append("passport", passport);
    }
    if (!quote_enquiry_file?.contentType) {
      formData.append("quote_enquiry_file_1", quote_enquiry_file[0] || "");
      formData.append("quote_enquiry_file_2", quote_enquiry_file[1] || "");
      formData.append("quote_enquiry_file_3", quote_enquiry_file[2] || "");
    }

    try {
      const response = await Axios.post("/api/create/CLA", formData);
      swal({
        title: "Success!",
        text: `Lead successfully added`,
        icon: "success",
        buttons: "Ok",
      }).then(() => {
        window.location = "/";
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        // text: "Email is associated with an existing account. Please use a different email for completing registration.",
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleBack = () => {
    dispatch(updateCrStep(1));
  };

  const handleSubmit = async (e) => {
    try {
      const response = Axios.post("/api/create/cla-contact-group", {
        contact_group_name: other_contacts,
      });
      swal({
        text: `Success`,
        icon: "success",
        buttons: false,
      });
      loadList();
      loadList();
      loadList();
      setAddContact({ other_contacts: " " });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const [values, setValues] = useState({
    list: [],
    reload: false,
  });

  const { list, reload } = values;
  const [contact, setContact] = useState([]);

  useEffect(() => {
    loadList();
  }, [reload]);

  const loadList = async () => {
    try {
      const response = await Axios.get(`/api/list/cla-contact-group`);
      setContact(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const CONTACT_GROUP = contact?.map(
    (CONTACT) => `${CONTACT.contact_group_name}`
  );

  return (
    <div>
      <h4 style={{ textAlign: "center", paddingTop: "5px" }}>
        Organisation, Quote Request or Enquiry Details
      </h4>

      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          value={business_name}
          name="business_name"
          label="Business or organisation name"
          width="100%"
          required
          placeholder="Trading name of your business / organisation. Otherwise please write N/A"
        />
        <CustomInputSingle
          onChange={handleEmailChange}
          value={email}
          name="email"
          label="Primary / Business email"
          width="100%"
          required
          placeholder="e.g. example@example.com"
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <br />
        <CustomPhone
          label="Business phone number"
          onChange={handlePhone}
          value={business_phone_no}
          name="business_phone_no"
          required
          placeholder="Business Phone Number* (Please select country then type number e.g. +44 7307 000000)"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="Company registration number"
          moreInfo="If registered"
          width="100%"
          name="company_registration_no"
          value={company_registration_no}
          placeholder="if registered please provide CRN"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="Charity registration number"
          moreInfo="If registered"
          width="100%"
          name="charity_registration_no"
          value={charity_registration_no}
          placeholder="if registered please provide Charity Number and Registered Country"
        />
        {/* *Developer allow select same as before or add new with 5 fields including post code and country */}
        <CustomRadio
          onChange={handleChange}
          label="Company / organisation address"
          moreInfo="If different from personal address"
          width="100%"
          data={PERSONAL_ADDR}
          name="contact_address"
          value={contact_address}
          required
        />
        {/* {contact_address?.includes("Same") ? */}
        {(contact_address?.includes("Same") && !AddressLine1) ||
        (contact_address?.includes("Same") && !City) ||
        (contact_address?.includes("Same") && !Region) ||
        (contact_address?.includes("Same") && !PostalCode) ||
        (contact_address?.includes("Same") && !Country) ? (
          <p style={{ color: "red" }}>
            Please complete 'Legal representative / personal address' on{" "}
            <a onClick={handleBack}>page 1</a>
          </p>
        ) : null}
        {contact_address?.includes("Different") && (
          <>
            <CustomBigInputSingle
              onChange={handleChange}
              label="House number/name, Street name"
              moreInfo="main contact address (if different)"
              width="100%"
              required
              name="business_address"
              value={business_address}
              placeholder="e.g. Flat/Building Name, 101 Example Street"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Address line 2"
              width="100%"
              name="business_address_2"
              value={business_address_2}
              placeholder="Additional Address Information"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Town / City"
              width="100%"
              required
              name="business_town"
              value={business_town}
              placeholder="Your Village, Town or City Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="County / Region"
              width="100%"
              required
              name="business_region"
              value={business_region}
              placeholder="Your County, Division or State Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Post / Zip Code"
              width="100%"
              required
              name="business_zipcode"
              value={business_zipcode}
              placeholder="Your Post Code, Zip Code or Post Office Name"
            />
            <CustomAutoSelect
              onChange={handleChangeCO}
              label="Country"
              width="100%"
              required
              name="business_country"
              value={business_country}
              data={CURRENT_COUNTRY}
            />
          </>
        )}
        <br />
        <Typography variant="subtitle2">
          {" "}
          Enquiry / Quote request details:
        </Typography>
        <CustomInput
          onChange={handleChange}
          value={note}
          name="note"
          label="Enquiry Details / Note / Quote request details"
          width="100%"
          placeholder="Your enquiry / quotation request details here..."
        />
        <br />
        <DropzoneArea
          acceptedFiles={[
            ".jpg, .jpeg, .png, .doc, .docx, .pdf, .mp4, .bmp, .gif, .xls, .xlsx, .docx, .odt, .ppt, .pptx, .video, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ]}
          onChange={handleFiles}
          showFileNames
          dropzoneText="Drag & Drop file here or click (Max 3 files, 30MB, contact us for help)"
          showAlerts={true}
          clearOnUnmount
          maxFileSize={30000000}
          showPreviewsInDropzone
          showFileNamesInPreview
          filesLimit={3}
        />
        <br />
        <Typography variant="subtitle2"> Additional:</Typography>
        <CustomInputSingle
          onChange={handleChange}
          value={business_website}
          name="business_website"
          label="Business website"
          width="100%"
          placeholder="e.g. www.YourCompanyWebsite.com"
        />
        <CustomAutoSelect
          onChange={(e) => handleContactGroup(e)}
          value={contact_group}
          name="contact_group"
          label="Contact group"
          width="100%"
          data={CONTACT_GROUP}
        />{" "}
        <CustomAutoSelect
          onChange={handleChange}
          label="How did you hear about us?"
          width="100%"
          name="about_us"
          value={about_us}
          data={[
            "Google",
            "Referral",
            "Radio Advert",
            "Newspaper Advert",
            "TV Advert",
            "Flyer",
            "Facebook",
            "Linkedin",
            "Other",
          ]}
        />
        {timeCount.map((tc, i) => (
          <div style={{ display: "flex" }}>
            <CustomInputSingle
              onChange={handleSocialChange}
              label="Social media name"
              width="30%"
              placeholder="e.g. twitter, facebook, linkedIn"
              name={`social_media_${i + 1}`}
            />
            <CustomBigInputSingle
              onChange={handleChange}
              name={`social_media_link_${i + 1}`}
              label="Enter social media account link"
              width="65%"
              placeholder="e.g. https://www.facebook.com/LinguistsCollective"
              moreInfo="maximum 10 accounts"
              required
            />
          </div>
        ))}
        <div className={classes.timeDiv}>
          {timeCount.length > 1 ? (
            <IconButton
              color="secondary"
              className="i-btn-two"
              component="button"
              onClick={handleRemove}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          ) : null}
          {timeCount.length !== 10 ? (
            <IconButton
              color="primary"
              className="i-btn"
              component="button"
              onClick={handleAdd}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          ) : null}
        </div>
        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handlegdprChange}
                checked={gdpr}
                name="gdpr"
                color="primary"
              />
            }
            // label="By submitting this form I agree with LC Privacy Policy, T&C, and GDPR"
          />
          I understand that my data will be saved by the Agent Linguist or SPPO
          and Linguists Collective. By submitting this form I agree with LC
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/privacy-policy"
          >
            {" "}
            Privacy Policy{" "}
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/terms-and-conditions"
          >
            {" "}
            T&C
          </a>
          <CustomCaptcha onChange={handleCaptcha} />
        </div>
        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton
            disabled={
              !gdpr ||
              !captcha ||
              !business_name ||
              !business_phone_no ||
              (contact_address.includes("Different") && !business_address) ||
              (contact_address.includes("Different") && !business_town) ||
              (contact_address.includes("Different") && !business_region) ||
              (contact_address.includes("Different") && !business_zipcode) ||
              (contact_address.includes("Different") && !business_country) ||
              (social_media_1 && !social_media_link_1) ||
              (social_media_2 && !social_media_link_2) ||
              (social_media_3 && !social_media_link_3) ||
              (social_media_4 && !social_media_link_4) ||
              (social_media_5 && !social_media_link_5) ||
              (social_media_6 && !social_media_link_6) ||
              (social_media_7 && !social_media_link_7) ||
              (social_media_8 && !social_media_link_8) ||
              (social_media_9 && !social_media_link_9) ||
              (social_media_10 && !social_media_link_10) ||
              (business_email && notValid) ||
              !contact_address
            }
            text="Submit"
            onClick={handleNext}
          />
        </div>
      </form>
    </div>
  );
};

export default FormTwo;
