import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import AccountClientLayout from "layout/AccountClientLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CardHeader from "components/Card/CardHeader";
import ProjectReport from "components/Admin/TimesheetProjectReport/AmendProjectReport/AmendProjectReport";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";

const ViewUnpaidInvoicePage = () => {
  const classes = useDashboardStyles();

  return (
    <Accountclient>
      <AccountClientLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{ display: "flex", justifyContent: "space-between" }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>Amend Project Report</p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    style={{ marginLeft: 5 }}
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
              <CardBody>
                <ProjectReport />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default ViewUnpaidInvoicePage;
