import React, { useState, useEffect } from "react";
import { Card, Header } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { useSelector } from "react-redux";



function Certifications() {
  const [values, setValues] = useState({ reload: false });
  const { id } = useParams();

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/public-certification-list/${id}`
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <div
      raised
      style={{
        width: "100%",
        padding: "1rem",
        background: "rgb(255,240,245)",
      }}
    >
      <Card.Content>
        <Header color="purple" as="h2">
          Certification/s
        </Header>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            marginBottom: "20px",
          }}
        >
          {values.length > 0 ? (
            <>
              {values?.map((list) => (
                <div style={{ marginRight: "10px" }}>
                  <Header as="h5">
                    {list.certificate_title} <br /> {list?.org_name} <br /> {list.expiry_date}
                  </Header>{" "}
                  <br />
                </div>
              ))}{" "}
            </>
          ) : null}
        </div>
      </Card.Content>
    </div>
  );
}

export default Certifications;
