// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import RateCard from "components/RateCard/RateCard";
import CurrencyDropdown from "components/Common/CurrencyDropdown";
import LandingPageLayout from "layout/LandingPageLayout";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";

const RateCardPage = () => {
  const classes = useLandingpageStyles();

  return (
    <LandingPageLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>
                Rate Card: Interpreting and translation price guide for UK,
                Europe, Canada, Australia, New Zealand and USA.
              </p>
            </CardHeader>
            <CardBody>
              <CurrencyDropdown />
              <RateCard readOnly />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default RateCardPage;
