import React, { useState, useEffect } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import Cookies from "js-cookie";
const InterpretingAcceptedJobChart = () => {
  const token = Cookies.get("token");
  const renderInterpretingAcceptedChart = async () => {
    try {
      const sdk = new ChartsEmbedSDK({
        baseUrl: "https://charts.mongodb.com/charts-lc-project-rdlgu", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
        height: 500,
        getUserToken: function () {
          return token;
        },
      });
      const chart = sdk.createChart({
        chartId: "c70a1ffd-a584-4b19-8be6-dea5c137a595",
      }); // ~REPLACE~ with the Chart ID from your Embed Chart dialog

      chart.render(document.getElementById("chart3"));
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    renderInterpretingAcceptedChart();
  });
  return <div id="chart3"></div>;
};

export default InterpretingAcceptedJobChart;
