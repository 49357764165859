import React, { useEffect, useState } from "react";

import Axios from "axios";
import Cookies from "js-cookie";
import { Table } from "semantic-ui-react";
import Action from "./Action";

//Moment js
import moment from "moment";

const ProvidedQuotations = ({ id }) => {
  const [invoice, setInvoice] = useState(null);
  const token = Cookies.get("BACtoken");


  useEffect(() => {
    const loadInvoice = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/accountclient/unconfirm-linQuote-list/quoteID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setInvoice(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    loadInvoice();

  }, [id]);

  return invoice ? (
    <Table singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Linguist ref number</Table.HeaderCell>
          <Table.HeaderCell>Quotation Date</Table.HeaderCell>
          <Table.HeaderCell>Expiry Date</Table.HeaderCell>
          <Table.HeaderCell>Currency</Table.HeaderCell>
          <Table.HeaderCell>Grand total</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {invoice.map((inv) => (
          <Table.Row>
            <Table.Cell>{inv?.postedBy?.id}</Table.Cell>
            <Table.Cell>
              {moment(inv?.createdAt).format("DD-MMM-YYYY")}
            </Table.Cell>
            <Table.Cell>
              {moment(inv?.expiry_date) < moment() ||
              moment(inv?.expiry_date) === moment() ? (
                <p style={{ color: "red" }}>
                  {moment(inv?.expiry_date).format("DD-MMM-YYYY")}
                </p>
              ) : (
                moment(inv?.expiry_date).format("DD-MMM-YYYY")
              )}
            </Table.Cell>
            <Table.Cell>{inv?.currency}</Table.Cell>
            <Table.Cell>{inv?.grand_total}</Table.Cell>
            <Table.Cell>
              <Action id={inv?._id} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : null;
};

export default ProvidedQuotations;
