import React from "react";
import SppoLayout from "layout/SppoLayout";
import Sppo from "components/RouteProtection/Sppo/Sppo";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CardHeader from "components/Card/CardHeader";
import InvoiceOptions from 'components/Sppo/Invoices/InvoiceOptions'
import InvoiceList from 'components/Sppo/Invoices/ReceivedInvoice/Invoice'

const InvoicePage = () => {
    const classes = useDashboardStyles();

    return (
        <SppoLayout>
            <Sppo>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}
                            >
                                <p className={classes.cardTitleWhite}>Received Invoices</p>
                                <InvoiceOptions />
                            </CardHeader>
                            <CardBody>
                                <InvoiceList />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Sppo>
        </SppoLayout>
    );
};

export default InvoicePage;
