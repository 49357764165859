import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// Other
import CustomSelect from "components/Common/CustomSelect";
import Axios from "axios";
import swal from "sweetalert";
import { Button } from "@material-ui/core";
import CustomAvatar from "components/Common/CustomAvatar";
import { Header } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Cookies from "js-cookie";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { DropzoneArea } from "material-ui-dropzone";
import { changeCrInput } from "redux/actions/crAction";

const FormOne = () => {
  const { id } = useParams();
  const classes = useEmployeeStyles();
  const { data } = useSelector((state) => state.crReducer);
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const { document } = data
  const [values, setValues] = useState({ reload: false });

  const [sdata, setData] = useState({
    document_type: "",
  });

  const FORM_TYPE = [
    "Passport Document",
    "National ID Card",
    "Other Legal Document",
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (e) => {
    setData({ ...sdata, [e.target.name]: e.target.value });
  };

  const [edit, setEdit] = React.useState({
    loader: false,
  });


  const handlePhotoFiles = (file) => {
    const inputData = {
      name: "document",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEdit({ ...edit, loader: true });

    let fd = new FormData();
    fd.append("document", document[0] || "");
    fd.append("document_type", sdata.document_type);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/upload-client-document/clientID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: response?.data.message,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = `/admin/view-account-client/${id}`;
      });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const loadList = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/client-document-list/clientID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  let sl = 1;

  const deleteDoc = async (id) => {
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/delete-document/${id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      loadList();
    } catch (error) {
      console.log(error.response);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure you want to delete this document?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          deleteDoc(value);

        default:
          break;
      }
    });
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
            <Card>
              <CardBody>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomAvatar />
                </div>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <Header style={{ margin: "0" }} as="h3">
                    Linguists Collective (LC)
                  </Header>
                  <Header style={{ margin: "0" }} as="h3">
                    Upload Documents
                  </Header>
                </div>

                <CustomSelect
                  onChange={handleChange}
                  label="Select Document Type"
                  data={FORM_TYPE}
                  width="100%"
                  name="document_type"
                  value={sdata.document_type}
                  required
                />
                <br />
                <DropzoneArea
                  acceptedFiles={[
                    ".jpg, .jpeg, .png, .doc, .docx, .pdf",
                  ]}
                  onChange={handlePhotoFiles}
                  showFileNames
                  dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                  showAlerts={true}
                  clearOnUnmount
                  maxFileSize={5000000}
                  showPreviewsInDropzone
                  showFileNamesInPreview
                  filesLimit={1}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleSubmit}
                    color="purple"
                    disabled={!sdata.document_type || !document[0] || edit.loader}
                  >
                    Submit
                  </Button>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Paper className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" id="export">
                      <TableHead>
                        <TableRow>
                          <TableCell>Serial Number</TableCell>
                          <TableCell>Document Type</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>

                      {values.length > 0 ? (
                        <TableBody>
                          {values
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((list) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={list._id}
                              >
                                <TableCell>{sl++}</TableCell>
                                <TableCell>
                                  <a
                                    href={`/admin/view-client-document/${list._id}`}
                                  >
                                    {list?.document_type}
                                  </a>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() => handleDelete(list._id)}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      ) : (
                        "No data found"
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={values.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </form>
  );
};

export default FormOne;
