import React from "react";

// Material core
import { createMuiTheme } from "@material-ui/core/styles";

// Material Styles
import { ThemeProvider } from "@material-ui/styles";

// Custom Styles
import { primaryColor } from "./variables";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        minWidth: "100px",
        textTransform: "none",
      },
    },
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: primaryColor[1],
    },
  },
  tooltip: {
    fontSize: "18px",
  },
});

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
