import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ListAltIcon from '@material-ui/icons/ListAlt';
import SendIcon from '@material-ui/icons/Send';

const ULOptions = () => {
  const history = useHistory();

  return (
    <div>
      <Button
        startIcon={<PersonAddIcon />}
        color="primary"
        variant="contained"
        style={{ width: "30%", marginLeft: "15px", marginRight: "15px" }}
        onClick={() => history.push("/admin/add-position")}
      >
        Add Manager Position
      </Button>
      <Button
        startIcon={<ListAltIcon />}
        color="primary"
        variant="contained"
        style={{ width: "30%", marginRight: "15px" }}
        onClick={() => history.push("/admin/manage-privilege")}
      >
        Manage Manager Privileges
      </Button>

      <Button
        startIcon={<ListAltIcon />}
        color="primary"
        variant="contained"
        style={{ width: "30%", marginRight: "15px" }}
        // onClick={() => history.push("/admin/employee-privilege")}
      >
        Manage Employee Privileges
      </Button>
     
    </div>
  );
};

export default ULOptions;
