import React, { useState, useEffect } from "react";
import SppoLayout from "layout/SppoLayout";
import Sppo from "components/RouteProtection/Sppo/Sppo";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import BookingOption from "components/Sppo/ManageInhouse/BookingOption";
import Options from "components/Sppo/ManageInhouse/Options";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Cookies from 'js-cookie'
import Axios from 'axios'
import { IconButton } from "@material-ui/core";
import { Header } from "semantic-ui-react";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import InhouseInterpreterTel from "components/Sppo/ManageInhouse/Form/INCInterpreterTel/INCInterpreterTel";

const INCInterpreterBooking = () => {
  const classes = useDashboardStyles();
  const [type, setType] = useState("bookings");

  const [sppoID, setSPPOID] = useState(null)

  const getDetails = async (e) => {
    const token = Cookies.get("SPPOtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setSPPOID(response?.data);
  };
  useEffect(() => {
    getDetails();
  }, []);


  return (
    <SppoLayout>
      <Sppo>
        <GridContainer>
          {sppoID?.agent_sppo && sppoID?.manage_inhouse_access ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className={classes.cardTitleWhite}>In-house Booking</p>
                  <IconButton color="inherit">
                    <ArrowBackIcon
                      onClick={() => history.goBack()}
                    ></ArrowBackIcon>
                  </IconButton>
                </CardHeader>
              </Card>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <BookingOption setType={setType} />
                </CardHeader>
              </Card>
              <Card>
                <CardHeader color="primary">
                  <Options type={type} />
                </CardHeader>
              </Card>
              <InhouseInterpreterTel />
            </GridItem>
            : sppoID?.profile_update ?
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    color="primary"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className={classes.cardTitleWhite}>In-house Booking</p>
                    <IconButton color="inherit">
                      <ArrowBackIcon
                        onClick={() => history.goBack()}
                      ></ArrowBackIcon>
                    </IconButton>
                  </CardHeader>
                </Card>
                <Card>
                  <CardHeader
                    color="primary"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <BookingOption setType={setType} />
                  </CardHeader>
                </Card>
                <Card>
                  <CardHeader color="primary">
                    <Options type={type} />
                  </CardHeader>
                </Card>
                <InhouseInterpreterTel />
              </GridItem>
              :
              sppoID?.agent_sppo && sppoID?.profile_update === false ?
                <Header as='h3'>Access denied.</Header>
                :
                sppoID?.profile_update === false ?
                  <Header as='h3'>Access denied. <a href={`/sppo/full-registration`}>Please complete full registration.</a></Header>
                  :
                  <Header as='h3'>Loading...</Header>
          }
        </GridContainer>
      </Sppo>
    </SppoLayout>
  );
};

export default INCInterpreterBooking;
