import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    padding: "2rem 0",
  },
  heading: {
    display: "flex",
    margin: "0",
    alignItems: "center",

    "& .MuiIcon-root": {
      marginRight: "10px",
    },

    "& .span": {
      fontWeight: "bold",
      marginLeft: "10px",
    },
  },
  form: {
    width: "50%",
    marginLeft: "15rem",
    marginRight: "15rem",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      marginLeft: "5rem",
      marginRight: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0rem",
      marginRight: "0rem",
    },
  },
  buttonDiv: {
    textAlign: "right",
    marginTop: "1rem",
  },
});

const useMeetingsStyles = makeStyles(styles);

export default useMeetingsStyles;
