import FrontPage from "pages/landing-page/FrontPage";
import About from "pages/landing-page/About";
import Interpreter from "pages/landing-page/Interpreter";
import Translation from "pages/landing-page/Translation";
import BF from "pages/landing-page/BF";
import ViewJobAdvert from "pages/landing-page/jobadvert/ViewJob";

import PostJobAdvert from "pages/landing-page/PostJobAdvert";
import ClientPortal from "pages/landing-page/ClientPortal";
import FEE from "pages/landing-page/FEE";
import ELS from "pages/landing-page/ELS";
import LinguistsProfessionals from "pages/landing-page/LinguistsProfessionals";
import ServiceProviders from "pages/landing-page/ServiceProviders";
import EmployeesPortal from "pages/landing-page/EmployeesPortal";
import Contact from "pages/landing-page/Contact";
import PrivacyPolicy from "pages/landing-page/PrivacyPolicy";
import FAQ from "pages/landing-page/FAQ";
import UploadCVPage from "pages/landing-page/UploadCV";
import TermsAndCondition from "pages/landing-page/TermsAndCondition";
import SubscribeHere from "pages/landing-page/subscriber/SubscribeHere";
import Unsubscribe from "pages/landing-page/subscriber/Unsubscribe";
import ViewRateCard from "pages/landing-page/ViewRateCard";
import INCDeleteAccountRequest from "pages/landing-page/INCRequestDeletion";
import INCDeleteAccountRequestSppo from "pages/landing-page/INCRequestDeletionSppo";
import PRCDeleteAccountRequest from "pages/landing-page/PRCRequestDeletion";
import BACUser from "pages/landing-page/BACUser/BacUserForm";
import eventRegistration from "pages/admin/ManagementPanel/EventRegistraion";
import PublicEvents from "pages/admin/ManagementPanel/PublicEvents";

import FindMyDetails from "pages/landing-page/FindLINClient/FindMyDetails";
import FindMyDetailsTrans from "pages/landing-page/FindLINClient/FindMyDetailsTrans";

import LINFindMyDetails from "pages/landing-page/FindLINClient/LINFindMyDetailsBooking";
import LINFindMyDetailsTrans from "pages/landing-page/FindLINClient/LINFindMyDetailsTrans";
import VerifyLinguist from "pages/landing-page/FindLINClient/VerifyLinguist";

import UploadINCDocs from "pages/landing-page/UploadDocument/UploadINCDocs";
import UploadPRCDocs from "pages/landing-page/UploadDocument/UploadPRCDocs";
import InterpretingOrTranslationForm from "pages/landing-page/InterpretingOrTranslation/InterpretingOrTranslation";
import FormSelection from "pages/landing-page/InterpretingOrTranslation/FormSelection";
import PRCFormSelection from "pages/landing-page/InterpretingOrTranslation/PRCFormSelection";
import INCFormSelection from "pages/landing-page/InterpretingOrTranslation/INCFormSelection";
import InterpretingForm from "pages/landing-page/InterpretingOrTranslation/Forms/Interpreting/LinguistsCollectiveBooking";
import TranslationForm from "pages/landing-page/InterpretingOrTranslation/Forms/Translation/LinguistsCollective";
import QuotationForm from "pages/landing-page/InterpretingOrTranslation/Forms/Quotation/QuoteRqst";

// payment routes
import JobAdvertStripePayment from "components/Auth/PublicJobAdvert/StripePayment";
import SuccessJobAdvertStripe from "components/Auth/PublicJobAdvert/SuccessJobAdvertStripe.jsx";

import ViewTimesheetDocument from "pages/landing-page/UploadDocument/ViewTimesheetDocument";

const LANDING_PAGE = [
  {
    id: "200a",
    type: "form",
    text: "front-page",
    subText: "Landing Page",
    path: "/",
    component: FrontPage,
  },
  {
    id: "200b",
    path: "landing-page/view-rate-card",
    component: ViewRateCard,
  },
  {
    id: "200b",
    text: "about",
    path: "landing-page/about",
    component: About,
  },
  {
    id: "200b",
    text: "about",
    path: "landing-page/terms-and-conditions",
    component: TermsAndCondition,
  },
  {
    id: "200c",
    text: "interpreter",
    path: "landing-page/interpreter",
    component: Interpreter,
  },

  {
    id: "200d",
    text: "translation",
    path: "landing-page/translation",
    component: Translation,
  },
  {
    id: "200e",
    text: "bilingual",
    path: "landing-page/bilingual-professional",
    component: BF,
  },

  {
    id: "200f",
    text: "jobadvert",
    path: "post-job-advert",
    component: PostJobAdvert,
  },

  {
    id: "200g",
    path: "landing-page/clients-portal",
    component: ClientPortal,
  },
  {
    id: "200h",
    path: "job-advert-details/:id",
    component: ViewJobAdvert,
  },
  {
    id: "200h",
    path: "landing-page/forensic-expert-evidence",
    component: FEE,
  },

  {
    id: "200i",
    path: "landing-page/expert-language-solutions",
    component: ELS,
  },

  {
    id: "200j",
    path: "landing-page/linguists-professionals",
    component: LinguistsProfessionals,
  },
  {
    id: "200sp",
    path: "landing-page/partner-organisation",
    component: ServiceProviders,
  },
  {
    id: "200k",
    path: "landing-page/employees-portal",
    component: EmployeesPortal,
  },

  {
    id: "200l",
    path: "landing-page/contact",
    component: Contact,
  },
  {
    id: "200n",
    path: "landing-page/subscribe-here",
    component: SubscribeHere,
  },
  {
    id: "200n",
    path: "unsubscribe-here",
    component: Unsubscribe,
  },
  {
    id: "200m",
    path: "landing-page/faq",
    component: FAQ,
  },

  {
    id: "200n",
    path: "landing-page/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    id: "200n",
    path: "landing-page/linguist-professionals/upload-cv",
    component: UploadCVPage,
  },
  {
    id: "200n",
    path: "delete-my-inc-account-from-linguist",
    component: INCDeleteAccountRequest,
  },
  {
    id: "2010n",
    path: "delete-my-inc-account-from-sppo",
    component: INCDeleteAccountRequestSppo,
  },
  {
    id: "200n",
    path: "delete-my-prc-account-from-linguist",
    component: PRCDeleteAccountRequest,
  },

  {
    id: "200n",
    path: "billing-account-client-additional-user/:id",
    component: BACUser,
  },
  {
    id: "200n",
    path: "interpreting-or-translation",
    component: InterpretingOrTranslationForm,
  },
  {
    id: "200n",
    path: "interpreting-or-translation/select-form",
    component: FormSelection,
  },
  {
    id: "200n",
    path: "interpreting-or-translation/prc-select-form/:linID/:clientID/:clientpin/:clientLname",
    component: PRCFormSelection,
  },
  {
    id: "200n",
    path: "interpreting-or-translation/inc-select-form/:linID/:clientID/:clientPC/:clientLN/:clientobj",
    component: INCFormSelection,
  },
  {
    id: "200n",
    path: "interpreting-or-translation/inc-select-form/:sppoobj/:sppoID/:clientID/:clientPC/:clientLN/:clientobj",
    component: INCFormSelection,
  },
  {
    id: "200n",
    path: "interpreting-or-translation/interpreting-form",
    component: InterpretingForm,
  },
  {
    id: "200n",
    path: "interpreting-or-translation/translation-form",
    component: TranslationForm,
  },
  {
    id: "200n",
    path: "interpreting-or-translation/quotation-form",
    component: QuotationForm,
  },

  {
    id: "201n",
    path: "events",
    component: PublicEvents,
  },

  {
    id: "202n",
    path: "event-member-registration/:id",
    component: eventRegistration,
  },

  {
    id: "203n",
    path: "inc_booking/find_my_details/:id/:sppoID",
    component: FindMyDetails,
  },

  {
    id: "203n",
    path: "inc_order/find_my_details/:id/:sppoID",
    component: FindMyDetailsTrans,
  },

  {
    id: "203n",
    path: "inc_booking/find_my_details/:id",
    component: LINFindMyDetails,
  },

  {
    id: "203n",
    path: "inc_order/find_my_details/:id",
    component: LINFindMyDetailsTrans,
  },

  {
    id: "203n",
    path: "verify-linguist",
    component: VerifyLinguist,
  },

  {
    id: "203n",
    path: "upload-inc-client-documents/:id",
    component: UploadINCDocs,
  },

  {
    id: "203n",
    path: "upload-prc-client-documents/:id",
    component: UploadPRCDocs,
  },

  // payment routes
  {
    id: "203n",
    path: "job-advert/summary/estimate/payment", //Job advert estimate payment
    component: JobAdvertStripePayment,
  },

  {
    id: "203n",
    path: "job-advert/success/estimate/payment/:id", // Job advert estimate payment
    component: SuccessJobAdvertStripe,
  },

  {
    id: "203n",
    path: "view-timesheet-document-bylinguist/:id", 
    component: ViewTimesheetDocument,
  },

  {
    id: "203n",
    path: "view-project-report-document-bylinguist/:id", 
    component: ViewTimesheetDocument,
  },

];

export default LANDING_PAGE;
