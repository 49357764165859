
export const Email_Type = [
    "Cash Account Client",
    "Billing Account Client",
    "Linguist Account",
    "SPPO Account",
    "Employee Account",
    "Manager / Admin Account",
    "Interpreting",
    "Translation",
    "Quotation",
    "Close and Timesheet",
    "Project Report",
    "Allocate Job, Take Job Back and Cancel Job",
    "Job Advert",
    "Additional"
]

export const CAC_Email_List = [
  "1 - CAC Pre-Signup = Email to Client",
  "2 - CAC Pre-Signup = Email to Admin",
  "3 - CAC Signup = Email to Client",
  "4 - CAC Signup = Email to Admin",
  "5 - CAC Signin = Email to Client",
  "6 - CAC Forgot Password = Email to Client",
];

export const BAC_Email_List = [
  "7 - BAC Pre-Signup = Email to Client",
  "8 - BAC Pre-Signup = Email to Admin",
  "9 - BAC Signup = Email to Client",
  "10 - BAC Signup = Email to Admin",
  "11 - BAC Signin = Email to Client",
  "12 - BAC Forgot Password = Email to Client",
  "13 - BAC Send User Registration Form = Email to User",
];

export const LIN_Email_List = [
  "14 - LIN Pre-Signup = Email to Linguist",
  "15 - LIN Pre-Signup = Email to Admin",
  "16 - LIN Signup = Email to Linguist",
  "17 - LIN Signup = Email to Admin",
  "18 - LIN Signin = Email to Linguist",
  "19 - LIN Forgot Password = Email to Linguist",
];

export const SPPO_Email_List = [
  "20 - SPPO Pre-Signup = Email to SPPO",
  "21 - SPPO Pre-Signup = Email to Admin",
  "22 - SPPO Signup = Email to SPPO",
  "23 - SPPO Signup = Email to Admin",
  "24 - SPPO Signin = Email to SPPO",
  "25 - SPPO Forgot Password = Email to SPPO",
  "26 - SPPO Send User Registration Form = Email to User",
];

export const EMP_Email_List = [
  "27 - EMP Pre-Signup = Email to Employee",
  "28 - EMP Pre-Signup = Email to Admin",
  "29 - EMP Signup = Email to Employee",
  "30 - EMP Signup = Email to Admin",
  "31 - EMP Signin = Email to Employee",
  "32 - EMP Forgot Password = Email to Employee",
];

export const ADM_Email_List = [
  "33 - MAN Pre-Signup = Email to the new Manager",
  "34 - MAN Pre-Signup = Email to the current Manager",
  "35 - MAN Signup = Email to the new Manager",
  "36 - MAN Signup = Email to the current Manager",
  "37 - MAN Signin = Email to the new Manager",
  "38 - MAN Forgot Password = Email to the new Manager",
];

export const Interpreting_Email_List = [
  "39 - CAC, BAC INC and PRC - Create Interpreting Assignment - Send Bank Email = Email to Client",
  "40 - CAC, BAC INC and PRC - Create Interpreting Assignment - Send Bank Email = Email to Admin",

  "41 - CAC, BAC INC and PRC - Create Interpreting Assignment - Send Stripe Email = Email to Client",
  "42 - CAC, BAC INC and PRC - Create Interpreting Assignment - Send Stripe Email = Email to Admin",
  "43 - CAC, BAC INC and PRC - Create Interpreting Assignment - Send Stripe Email = Email to Linguist",

  "44 - BAC - Create Interpreting Assignment - Added To Monthly Bill Email = Email to Client",
  "45 - BAC - Create Interpreting Assignment - Added To Monthly Bill Email = Email to Admin",
  "46 - BAC - Create Interpreting Assignment - Added To Monthly Bill Email = Email to Linguist",

  "47 - CAC and PRC - Create Interpreting Assignment - Pay Later Email = Email to Client",
  "48 - CAC and PRC - Create Interpreting Assignment - Pay Later Email = Email to Admin",
  "49 - CAC and PRC - Create Interpreting Assignment - Pay Later Email = Email to Linguist",

  "50 - CAC, BAC and INC - Accept Interpreting Assignment = Email to Client",
  "51 - CAC, BAC and INC - Accept Interpreting Assignment = Email to Admin",
  "52 - CAC, BAC and INC - Accept Interpreting Assignment = Email to Linguist",
];

export const Translation_Email_List = [
  "53 - CAC, BAC INC and PRC - Create Translation Assignment - Send Bank Email = Email to Client",
  "54 - CAC, BAC INC and PRC - Create Translation Assignment - Send Bank Email = Email to Admin",

  "55 - CAC, BAC INC and PRC - Create Translation Assignment - Send Stripe Email = Email to Client",
  "56 - CAC, BAC INC and PRC - Create Translation Assignment - Send Stripe Email = Email to Admin",
  "57 - CAC, BAC INC and PRC - Create Translation Assignment - Send Stripe Email = Email to Linguist",

  "58 - BAC - Create Translation Assignment - Added To Monthly Bill Email = Email to Client",
  "59 - BAC - Create Translation Assignment - Added To Monthly Bill Email = Email to Admin",
  "60 - BAC - Create Translation Assignment - Added To Monthly Bill Email = Email to Linguist",

  "61 - CAC and PRC - Create Translation Assignment - Pay Later Email = Email to Client",
  "62 - CAC and PRC - Create Translation Assignment - Pay Later Email = Email to Admin",
  "63 - CAC and PRC - Create Translation Assignment - Pay Later Email = Email to Linguist",

  "64 - CAC, BAC and INC - Accept Translation Assignment = Email to Client",
  "65 - CAC, BAC and INC - Accept Translation Assignment = Email to Admin",
  "66 - CAC, BAC and INC - Accept Translation Assignment = Email to Linguist",
];

export const Quote_Request_Email_List = [
  "67 - CAC and BAC - Quote Request = Email to Client",
  "68 - CAC and BAC - Quote Request = Email to Linguist",
];

export const Timesheet_Email_List = [
  "69 - CAC and BAC - Make Timesheet = Email to Client",
  "70 - CAC and BAC - Make Timesheet = Email to Admin",
  "71 - CAC and BAC - Make Timesheet = Email to Linguist",
  "72 - CAC and BAC - Confirm Timesheet = Email to Admin",
];

export const Project_Report_Email_List = [
  "73 - CAC and BAC - Make Project Report = Email to Client",
  "74 - CAC and BAC - Make Project Report = Email to Admin",
  "75 - CAC and BAC - Make Project Report = Email to Linguist",
  "76 - CAC and BAC - Confirm Project Report = Email to Admin",
];

export const Allocate_TakeJob_Cancel_Email_List = [
  "77 - Allocate Interpreting Job = Email to Admin/Client",
  "78 - Allocate Interpreting Job = Email to Linguist",
  "79 - Allocate Translation Job = Email to Admin/Client",
  "80 - Allocate Translation Job = Email to Linguist",
  "81 - Take Job - Interpreting or Translation = Email to Admin/Linguist/Client",
  "82 - Cancel Job - Interpreting or Translation = Email to Admin/Linguist/Client",
];

export const JobAdvert_Email_List = [
  "83 - Job Advert - Send Bank Email - Email to Admin and Client",
  "84 - Job Advert - Send Stripe Email - Email to Admin and Client",
];

export const Additional_Email_List = [
  "85 - Ask Question For All = Email to Admin",
];





// variables

export const Pre_Signup_User_Variable = [
  "{title}",
  "{first_name}",
  "{last_name}",
  "{activation_link} *",
  "{site_url}",
  "{app_name}"
];

export const Pre_Signup_Lin_Variable = [
  "{title}",
  "{first_name}",
  "{last_name}",
  "{activation_link} *",
  "{login_url}",
  "{portal_url}",
  "{site_url}",
  "{app_name}"
];

export const Pre_Signup_Sppo_Variable = [
  "{title}",
  "{first_name}",
  "{last_name}",
  "{activation_link} *",
  "{portal_url}",
  "{site_url}",
  "{app_name}"
];

export const Pre_Signup_Admin_Variable = [
  "{title}",
  "{first_name}",
  "{last_name}",
  "{email}",
  "{site_url}",
  "{app_name}"
];

export const Signup_Admin_Variable = [
  "{id}",
  "{title}",
  "{first_name}",
  "{last_name}",
  "{email}",
  "{site_url}",
  "{app_name}"
];

export const Signup_User_Variable = [
  "{title}",
  "{first_name}",
  "{last_name}",
  "{login_url}",
  "{site_url}",
  "{app_name}"
];

export const Forgot_Password_User_Variable = [
  "{title}",
  "{first_name}",
  "{last_name}",
  "{reset_link}",
  "{site_url}",
  "{app_name}"
];

export const Signin_User_Variable = [
  "{id}",
  "{title}",
  "{first_name}",
  "{last_name}",
  "{email}",
  "{location}",
  "{remote_ip}",
  "{date_time}",
  "{site_url}",
  "{app_name}"
];

// send BAC user registration form varibale
export const BAC_User_Variable = [
  "{main_bac_id}",
  "{main_bac_title}",
  "{main_bac_first_name}",
  "{main_bac_last_name}",
  "{main_bac_business_name}",
  "{title}",
  "{first_name}",
  "{last_name}",
  "{email}",
  "{note}",
  "{form_url}",
  "{site_url}",
  "{app_name}"
];

// send SPPO user registration form varibale
export const SPPO_User_Variable = [
  "{main_sppo_id}",
  "{main_sppo_title}",
  "{main_sppo_first_name}",
  "{main_sppo_last_name}",
  "{main_sppo_business_name}",
  "{title}",
  "{first_name}",
  "{last_name}",
  "{email}",
  "{note}",
  "{form_url}",
  "{site_url}",
  "{app_name}"
];

// interpreting variable for bank email for admin and client
export const Interpreting_BankEmail_Variable = [
  "{booking_ref_id}",
  "{client_title}",
  "{client_first_name}",
  "{client_last_name}",
  "{purchase_order_number}",
  "{source_language}",
  "{target_language}",
  "{target_skill_level}",
  "{timezone}",
  "{start_date}",
  "{start_time}",
  "{service}",
  "{currency}",
  "{estimate}",
  "{rate_card}",
  "{site_url}",
  "{app_name}"
];

export const Interpreting_StripeEmail_Variable = [
  "{booking_ref_id}",
  "{client_title}",
  "{client_first_name}",
  "{client_last_name}",
  "{lin_title}",
  "{lin_first_name}",
  "{lin_last_name}",
  "{purchase_order_number}",
  "{source_language}",
  "{target_language}",
  "{target_skill_level}",
  "{timezone}",
  "{start_date}",
  "{start_time}",
  "{service}",
  "{currency}",
  "{estimate}",
  "{rate_card}",
  "{site_url}",
  "{app_name}"
];

export const Translation_BankEmail_Variable = [
  "{order_ref_id}",
  "{client_title}",
  "{client_first_name}",
  "{client_last_name}",
  "{purchase_order_number}",
  "{source_language}",
  "{target_language}",
  "{target_skill_level}",
  "{timezone}",
  "{deadline}",
  "{word_count}",
  "{service}",
  "{currency}",
  "{estimate}",
  "{rate_card}",
  "{site_url}",
  "{app_name}"
];

export const Translation_StripeEmail_Variable = [
  "{order_ref_id}",
  "{client_title}",
  "{client_first_name}",
  "{client_last_name}",
  "{lin_title}",
  "{lin_first_name}",
  "{lin_last_name}",
  "{purchase_order_number}",
  "{source_language}",
  "{target_language}",
  "{target_skill_level}",
  "{timezone}",
  "{deadline}",
  "{word_count}",
  "{service}",
  "{currency}",
  "{estimate}",
  "{rate_card}",
  "{site_url}",
  "{app_name}"
];

export const Accept_Interpreting_Variable = [
  "{booking_ref_id}",
  "{client_id}",
  "{client_title}",
  "{client_first_name}",
  "{client_last_name}",
  "{lin_id}",
  "{lin_title}",
  "{lin_first_name}",
  "{lin_last_name}",
  "{lin_target_skill_level}",
  "{lin_public_profile}",
  "{purchase_order_number}",
  "{source_language}",
  "{target_language}",
  "{target_skill_level}",
  "{timezone}",
  "{start_date}",
  "{start_time}",
  "{service}",
  "{currency}",
  "{estimate}",
  "{rate_card}",
  "{site_url}",
  "{app_name}"
];

export const Accept_Translation_Variable = [
  "{order_ref_id}",
  "{client_id}",
  "{client_title}",
  "{client_first_name}",
  "{client_last_name}",
  "{lin_id}",
  "{lin_title}",
  "{lin_first_name}",
  "{lin_last_name}",
  "{lin_target_skill_level}",
  "{lin_public_profile}",
  "{purchase_order_number}",
  "{source_language}",
  "{target_language}",
  "{target_skill_level}",
  "{timezone}",
  "{deadline}",
  "{word_count}",
  "{service}",
  "{currency}",
  "{estimate}",
  "{rate_card}",
  "{site_url}",
  "{app_name}"
];

export const Quote_Request_Variable = [
  "{quote_ref_id}",
  "{id}",
  "{title}",
  "{first_name}",
  "{last_name}",
  "{business_name}",
  "{request_details}",
  "{source_language}",
  "{target_language}",
  "{target_skill_level}",
  "{timezone}",
  "{deadline}",
  "{rate_card}",
  "{site_url}",
  "{app_name}"
];

export const Make_Timesheet_Variable = [
  "{booking_ref_id}",
  "{booking_created_date}",
  "{client_id}",
  "{client_title}",
  "{client_first_name}",
  "{client_last_name}",
  "{lin_id}",
  "{lin_title}",
  "{lin_first_name}",
  "{lin_last_name}",
  "{site_url}",
  "{app_name}"
];

export const Confirm_Timesheet_Variable = [
  "{timesheet_ref_id}",
  "{timesheet_created_date}",
  "{lin_or_client_id}",
  "{lin_or_client_title}",
  "{lin_or_client_first_name}",
  "{lin_or_client_last_name}",
  "{site_url}",
  "{app_name}"
];

export const Make_ProjectReport_Variable = [
  "{order_ref_id}",
  "{order_created_date}",
  "{client_id}",
  "{client_title}",
  "{client_first_name}",
  "{client_last_name}",
  "{lin_id}",
  "{lin_title}",
  "{lin_first_name}",
  "{lin_last_name}",
  "{site_url}",
  "{app_name}"
];

export const Confirm_ProjectReport_Variable = [
  "{report_ref_id}",
  "{report_created_date}",
  "{lin_or_client_id}",
  "{lin_or_client_title}",
  "{lin_or_client_first_name}",
  "{lin_or_client_last_name}",
  "{site_url}",
  "{app_name}"
];

export const Allocate_Interpreting_Variable = [
  "{booking_ref_id}",
  "{client_id}",
  "{client_title}",
  "{client_first_name}",
  "{client_last_name}",
  "{lin_id}",
  "{lin_title}",
  "{lin_first_name}",
  "{lin_last_name}",
  "{source_language}",
  "{target_language}",
  "{target_skill_level}",
  "{timezone}",
  "{start_date}",
  "{start_time}",
  "{duration}",
  "{service}",
  "{site_url}",
  "{app_name}"
];

export const Allocate_Translation_Variable = [
  "{order_ref_id}",
  "{client_id}",
  "{client_title}",
  "{client_first_name}",
  "{client_last_name}",
  "{lin_id}",
  "{lin_title}",
  "{lin_first_name}",
  "{lin_last_name}",
  "{source_language}",
  "{target_language}",
  "{target_skill_level}",
  "{timezone}",
  "{deadline}",
  "{word_count}",
  "{service}",
  "{site_url}",
  "{app_name}"
];

export const Take_Job_Variable = [
  "{assignment_ref_id}",
  "{client_id}",
  "{lin_id}",
  "{site_url}",
  "{app_name}"
];

export const Cancel_Job_Variable = [
  "{assignment_ref_id}",
  "{client_id}",
  "{site_url}",
  "{app_name}"
];

export const Job_Advert_Variable = [
  "{job_ref_id}",
  "{title}",
  "{first_name}",
  "{last_name}",
  "{email}",
  "{job_title}",
  "{salary}",
  "{contract_type}",
  "{hours_per_week}",
  "{job_ref}",
  "{target_language}",
  "{source_language}",
  "{additional_language}",
  "{expertise_level}",
  "{about_company}",
  "{job_description}",
  "{application_closing_date}",
  "{nhs_trust}",
  "{registration_number}",
  "{site_url}",
  "{app_name}"
];

export const Ask_Ques_Variable = [
  "{user_id}",
  "{user_title}",
  "{user_first_name}",
  "{user_last_name}",
  "{site_url}",
  "{app_name}"
];

