import React, { } from "react";
import LinguistLayout from "layout/LinguistLayout";
import Linguist from "components/RouteProtection/Linguist/Linguist";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton, Link, Button, useMediaQuery } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PrivateList from "components/Linguist/ManagePrivateClient/DeleteRequest"
import Options from "components/Linguist/MyClient/PRCOptions";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";


const PRCAccountRemoval = () => {
  const classes = useDashboardStyles();
  const matches = useMediaQuery('(max-width:768px)')
  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>

          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                <p className={classes.cardTitleWhite}>Add / Remove Private Client (Account Removal Requests)</p>
                <IconButton color='inherit'><ArrowBackIcon onClick={() => history.goBack()} /></IconButton>

              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >

                <Options />
              </CardHeader>
              <CardBody>
                {!matches ? <div> <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/registration')} variant='contained' color='primary'>Filling up the form</Button>
                  <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/send-client-registration-form')} variant='contained' color='primary'>Sending form</Button>
                  <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/removal-request')} variant='contained' color='primary'>Removal Requests</Button>
                  <Link href='/delete-my-prc-account-from-linguist' target='_blank'>  <Button color='primary' variant='contained'> Removal Form</Button></Link>
                </div> :
                  <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
                    <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/registration')} variant='contained' color='primary'>Filling up the form</Button>
                    <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/send-client-registration-form')} variant='contained' color='primary'>Sending form</Button>
                    <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/add-private-client/removal-request')} variant='contained' color='primary'>Removal Requests</Button>
                    <Link href='/delete-my-prc-account-from-linguist' target='_blank'>  <Button color='primary' variant='contained'> Removal Form</Button></Link>
                  </div>
                }

              </CardBody>
              <CardBody>
                <PrivateList />
              </CardBody>
            </Card>
          </GridItem>


        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default PRCAccountRemoval;
