import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Moment from "moment";
// Custom Components

import InputBase from "@material-ui/core/InputBase";
import CardHeader from "components/Card/CardHeader";
import { Button } from "semantic-ui-react";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import CustomModal from "components/Common/CustomModal";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import swal from "sweetalert";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { makeStyles } from "@material-ui/core/styles";
import { archivePRC } from "../../../../../action/manager";
import { ToastProvider, useToasts } from "react-toast-notifications";
//Axios
import Axios from "utils/axios";
import Action from "components/Admin/CrmPanel/Leads/Action";
//Cookies
import Cookies from "js-cookie";

// import AcceptedAction from "./AcceptedAction.jsx";

const AcceptedQuote = ({ id }) => {
  const classes = useDashboardStyles();
  const [viewAll, setViewAll] = useState(null);
  const token = Cookies.get("token");
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState(null);
  const { addToast } = useToasts();

  const handleViewAll = async (e) => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/list-linguist-lead/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewAll(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("No data found!", { appearance: "error" });
      }
    }
  };

  const handleSearch = async (e) => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/find-linguist-lead/linID/${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSearchData(response?.data);
      setViewAll(null);
      if (response?.data) {
        addToast("Lead found", { appearance: "success" });
      } else {
        addToast("Lead not found", { appearance: "error" });
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct lead id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <>
      <InputBase
        className={classes.input}
        placeholder="Enter Lead ID"
        onChange={(e) => setSearch(e.target.value)}
        inputProps={{ "aria-label": "search google maps" }}
        value={search}
        style={{
          width: 30 + "%",
          padding: 5,
          marginTop: 20,
          boxShadow: "0px 0px 3px 2px rgba(0,0,0,0.25)",
          borderRadius: 2 + "pt",
        }}
      />
      <Button onClick={handleSearch} style={{ marginLeft: 20 }} color="purple">
        Find a lead
      </Button>

      <Button
        onClick={handleViewAll}
        color="purple"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        View all leads
      </Button>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table color="pink">
            <Table.Header>
              <Table.Row style={{ textAlign: "left" }}>
                <Table.Cell>Unique ID</Table.Cell>
                <Table.Cell>Title</Table.Cell>
                <Table.Cell>First name</Table.Cell>
                <Table.Cell>Last name</Table.Cell>
                <Table.Cell>Email address</Table.Cell>
                <Table.Cell>Mobile number</Table.Cell>
                <Table.Cell>Registration Date</Table.Cell>
                <Table.Cell>Action</Table.Cell>
              </Table.Row>
            </Table.Header>

            {searchData && !viewAll ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Link to={`/admin/view-cla/${searchData._id}`}>
                      {searchData.id}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{searchData.title}</Table.Cell>
                  <Table.Cell>{searchData.FirstName}</Table.Cell>
                  <Table.Cell>{searchData.LastName}</Table.Cell>
                  <Table.Cell>{searchData.email}</Table.Cell>
                  <Table.Cell>{searchData.mobile_no}</Table.Cell>
                  <Table.Cell>
                    {Moment(searchData.createdAt).format(
                      "DD MMM YYYY - kk:mm:ss"
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Action
                      load={handleViewAll}
                      id={searchData._id}
                      ID={searchData?.id}
                      name={searchData?.FirstName + " " + searchData?.LastName}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body>
                {viewAll?.map((list, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <Link to={`/admin/view-cla/${list?._id}`}>
                        {list?.id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{list?.title}</Table.Cell>
                    <Table.Cell>{list?.FirstName}</Table.Cell>
                    <Table.Cell>{list?.LastName}</Table.Cell>
                    <Table.Cell>{list?.email}</Table.Cell>
                    <Table.Cell>{list?.mobile_no}</Table.Cell>
                    <Table.Cell>
                      {Moment(list?.createdAt).format("DD MMM YYYY - kk:mm:ss")}
                    </Table.Cell>
                    <Table.Cell>
                      <Action
                        load={handleViewAll}
                        id={list?._id}
                        ID={list?.id}
                        name={list?.FirstName + " " + list?.LastName}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default AcceptedQuote;
