import React from "react";

// Material Core Components
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";

// Custom Components
import CustomFormControl from "./CustomFormControl";

const CustomPassword = ({
  label,
  width,
  onChange,
  value,
  required,
  validation,
  notRegex,
  ...rest
}) => {
  const [values, setValues] = React.useState({
    password: value || "",
    showPassword: false,
    notRegex: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
      if (onChange) {
      onChange(event);
    }
  };



  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <CustomFormControl width={`${width || "100%"}`}>
      <InputLabel htmlFor="standard-adornment-password" required={required}>
        {label}
      </InputLabel>
      <Input
        id="standard-adornment-password"
        type={values.showPassword ? "text" : "password"}
        value={values.password}
        required={required}
        onChange={handleChange("password")}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        {...rest}
      />
      {validation && (
        <FormHelperText error>Password does not match</FormHelperText>
      )}
      {notRegex && (
        <FormHelperText error>
          {" "}
          Password must be minimum ten characters, at least one uppercase
          letter, one lowercase letter, one number and one special character
        </FormHelperText>
      )}
      
    </CustomFormControl>
  );
};

export default CustomPassword;
