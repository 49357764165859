import Cookies from "js-cookie";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import SearchView from "../SearchView/SearchView";
import moment from "moment";
// import Action from "./Action";

import CustomModal from "components/Common/CustomModal";
import ViewAll from "./ViewAll";
// Others
import { acceptDecline } from "./functions";
import Axios from "axios";
import swal from "sweetalert";
import CustomSnackBar from "components/Common/CustomSnackBar";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const INC = ({ details }) => {
  const [open, setOpen] = useState(false);
  const token = Cookies.get("LINtoken");
  const linID = Cookies.get("linID");
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const handleClose = () => {
    setOpen(!open);
  };

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [submit, setSubmit] = useState({
    toast: false,
    loader: false,
    message: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const {
    name,
    error,
    success,
    bookingList,
    cacAndBacAccess,
    removed,
    reload,
  } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload, filter]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-confirm-Translation-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (filtered.length > 0) {
        setValues({ ...values, bookingList: filtered });
        setFiltered([]);
      } else {
        setValues({ ...values, bookingList: response?.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccept = async (
    id,
    email,
    linID,
    target_level,
    target_language,
    start_date
  ) => {
    // console.log('Job target language', target_level)

    // //Extracting number from target lang to compare
    // let level_no;

    // //Concating target language of linguist
    // const targetLinguist = details.target_language.concat(details.target_language_1).concat(details.target_language_2)
    // const sourceLinguist = details.source_language.concat(details.source_language_1).concat(details.source_language_2)

    // // let indexTargetLang = sourceLinguist.indexOf(target_language) || targetLinguist.indexOf(target_language)
    // // console.log(indexTargetLang)

    // //Concating target skill level and source skill level
    // const targetSkill = details.target_language_level.concat(details.target_language_level_1).concat(details.target_language_level_2)
    // const sourceSkill = details.source_language_level.concat(details.source_language_level_1).concat(details.source_language_level_2)

    // let indexTargetLang, target_level_linguist;
    // console.log(sourceLinguist)
    // console.log(target_language)
    // if (sourceLinguist.indexOf(target_language) !== -1) {
    //   indexTargetLang = sourceLinguist.indexOf(target_language)
    //   target_level_linguist = sourceSkill[indexTargetLang]
    //   console.log(sourceSkill)
    // } else {
    //   indexTargetLang = targetLinguist.indexOf(target_language)
    //   target_level_linguist = targetSkill[indexTargetLang]
    //   console.log(target_level_linguist)
    // }

    // let level_no_linguist;
    // if (target_level_linguist.toString().includes('4R')) {
    //   level_no_linguist = '4.5'
    //   console.log('4R')
    // } else if (target_level_linguist.includes('X')) {
    //   level_no_linguist = '0'
    // }
    // else {
    //   const temp = target_level_linguist.match(/(\d+)/)
    //   level_no_linguist = temp[0]
    // }

    // if (target_level.toString().includes('4R')) {
    //   level_no = '4.5'
    // } else {
    //   const temp = target_level.match(/(\d+)/)
    //   level_no = temp[0]
    // }

    // if ((Number(level_no) <= Number(level_no_linguist))) {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-Translation-accept/TranslationID/${id}`,
        { email: email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      loadbookingList();
      loadbookingList();
    } catch (error) {
      console.log(error.response);
      swal({
        icon: "error",
        text: `${error.response?.data}`,
        buttons: "Ok",
        dangerMode: true,
      });
    }
    // } else {
    //   setSubmit({ ...submit, toast: true, message: `You cannot accept this assignment, your target language ${target_level_linguist} is below required target language ${target_level}` })
    // }
  };

  const decline = async (decline, id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-Translation-reject/TranslationID/${id}`,
        { email: email, declines: decline },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      loadbookingList();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDecline = async (e, id, email) => {
    const declines = e.target.value;
    swal(`Are you sure you want to decline this job?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      // .then(function() {
      //   window.location = "/admin/unverified-linguists";
      // })
      .then((value) => {
        switch (value) {
          case "cancel":
            break;

          case "catch":
            decline(declines, id, email);

          default:
            break;
        }
      });
  };

  return (
    <Paper className={classes.root}>
      <CustomSnackBar
        message={submit.message}
        handleClose={() => setSubmit({ ...submit, toast: false })}
        openSnackBar={submit.toast}
        severity="error"
      />
      <SearchView
        values={bookingList}
        load={loadbookingList}
        setSearchText={setSearchText}
        setShow={setShow}
        SearchText={searchText}
        setFiltered={setFiltered}
        setFilter={setFilter}
        show={show}
        inc={true}
      />
      {show && (
        <>
          {" "}
          <TableContainer className={classes.container}>
            <Table aria-label="sticky table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                  <Table.HeaderCell>Document type</Table.HeaderCell>
                  <Table.HeaderCell>Deadline</Table.HeaderCell>
                  <Table.HeaderCell>Source language</Table.HeaderCell>
                  <Table.HeaderCell>Target language</Table.HeaderCell>
                  <Table.HeaderCell>
                    Target language skill level
                  </Table.HeaderCell>
                  <Table.HeaderCell>Estimated word count</Table.HeaderCell>
                  <Table.HeaderCell>Estimate paid</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Accept / Decline
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {bookingList.length > 0 ? (
                <Table.Body>
                  {bookingList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .filter((list) => list.linguistID !== linID)
                    .map((list) => (
                      <>
                        {moment(list.deadline) > moment() ||
                        moment(list.deadline) === moment() ? (
                          <Table.Row
                            role="checkbox"
                            tabIndex={-1}
                            key={list._id}
                          >
                            <Table.Cell key={list._id} align={list.align}>
                              <Link
                                to={`/linguist/inc-view-translation-assignment/${list._id}`}
                              >
                                {list?.id}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>{list.document_type}</Table.Cell>
                            <Table.Cell>
                              {moment(list?.deadline)
                                .tz("Europe/London")
                                .format("DD-MMM-YYYY : HH:mm:ss")}
                            </Table.Cell>
                            <Table.Cell>{list.source_language}</Table.Cell>
                            <Table.Cell>{list.target_language}</Table.Cell>
                            <Table.Cell>
                              {list.target_language_skill_level}
                            </Table.Cell>

                            <Table.Cell>{list.count}</Table.Cell>
                            <Table.Cell>
                              {list?.currency_new?.substring(0, 3)}{" "}
                              {list.estimate}
                            </Table.Cell>
                            <Table.Cell>
                              {acceptDecline(
                                "bl",
                                "",
                                handleAccept,
                                handleDecline,
                                list._id,
                                list.email,
                                "declineOp",
                                list.LinguistID,
                                list.target_language_skill_level,
                                list.target_language,
                                list.deadline
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row
                            role="checkbox"
                            tabIndex={-1}
                            key={list._id}
                          >
                            <Table.Cell key={list._id} align={list.align}>
                              <Link
                                to={`/linguist/inc-view-translation-assignment/${list._id}`}
                              >
                                {list?.id}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>{list.document_type}</Table.Cell>
                            <Table.Cell>
                              {moment(list?.deadline)
                                .tz("Europe/London")
                                .format("DD-MMM-YYYY : HH:mm:ss")}
                            </Table.Cell>
                            <Table.Cell>{list.source_language}</Table.Cell>
                            <Table.Cell>{list.target_language}</Table.Cell>
                            <Table.Cell>
                              {list.target_language_skill_level}
                            </Table.Cell>

                            <Table.Cell>{list.count}</Table.Cell>
                            <Table.Cell>
                              {list?.currency_new?.substring(0, 3)}{" "}
                              {list.estimate}
                            </Table.Cell>
                            <Table.Cell>
                              Assignment Acceptance Time Expired.You may contact
                              Booking Team for an update.
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </>
                    ))}
                </Table.Body>
              ) : (
                "Sorry, no INC translation assignment available matching your profile."
              )}
            </Table>
            <CustomModal handleClose={handleClose} open={open}>
              <ViewAll />
            </CustomModal>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={bookingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
};

export default INC;
