const { makeStyles } = require("@material-ui/core");

const useLandingpageStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  footer: {
    marginTop: "auto",
    backgroundColor: "lightgray",
    display: "flex",
    alignItems: "center",
  },

  signature: {
    marginLeft: "auto",
  },

  footerLink: {
    textDecoration: "none",
  },

  frontImage: {
    boxShadow: "0 2px 5px  rgba(0, 0, 0, 0.1)",
    width: "100%",
  },

  cardTitle: {
    cursor: "default",
    background: "#B794F4",
    transition: "transform 100ms ease-in",
  },

  clientPortal: {
    //minHeight: "1000px",
    backgroundImage: "linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)    ",
    padding: window.matchMedia("(max-width: 1000px)").matches
      ? "2rem !important"
      : "2rem 5rem 2rem 5rem",
    color: "purple",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    marginBottom: window.matchMedia("(max-width: 1000px)").matches
      ? "3rem"
      : "0",
  },
  button: {
    backgroundColor: "purple",
    color: "white",
    "&:hover": {
      backgroundColor: "#E86BE3",
    },
  },

  services: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid black",
    paddingBottom: "1em",
  },
  hoverCard: {
    transition: "transform 200ms ease-in",
    "&:hover": {
      transform: "scale(1.07)",
    },
  },
  paddingMobile: {
    padding: window.matchMedia("(max-width: 1000px)").matches ? "1rem" : "5rem",
  },

  imagePortal: {
    minHeight: "300px",
    background: "#B794F4",
    color: "#fff",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  },

  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "lightgray",
  },

  title: {
    cursor: "pointer",
    transition: "transform 100ms ease-in",
    "&:hover": {
      transform: "scale(1.07)",
    },
    "&:.MuiListItemText-dense": {
      fontWeight: "600 !important",
    },
  },
  scroll: {
    scrollBehavior: "smooth"
  },
  root: {
    maxWidth: "100%",
    display: "flex",
  },
  media: {
    width: "200px",
    height: "200px",
  },
  imgCon: {
    padding: "0px !important",
  },
}));

export default useLandingpageStyles;
