import {
  UPDATE_CR_STEP,
  CHANGE_INPUT,
  CR_SUBMIT,
  SET_LOADING,
  CR_LOGIN,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  count: 1,
  loading: false,
  data: {
    profile_photo: "",
    title: "",
    first_name: "",
    last_name: "",
    dob: null,
    gender: '',
    house_street_no_name: '',
    address_line_two: '',
    town_city: '',
    post_code: '',
    county_region: '',
    country: null,
    current_nationality: '',
    other_nationalities: [],
    email: "",
    password: "",
    confirm_password: '',
    mobile_no: "",
    identification_doc: "",
    direct_line: "",
    business_name: "",
    position: "",
    business_email: "",
    business_phone_no: "",
    // business_address: "",
    contact_house_street_no_name: '',
    contact_address_line_two: '',
    contact_town_city: '',
    contact_post_code: '',
    contact_county_region: '',
    contact_country: '',
    company_registration_no: "",
    business_website: "",
    currency: '',
    note: "",
    gdpr: false,
  },
  loggedInData: null,
};

const crReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value,
        },
      };
    case UPDATE_CR_STEP:
      return {
        ...state,
        count: payload,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case CR_LOGIN:
      return {
        ...state,
        loading: false,
        loggedInData: payload,
      };

    case CR_SUBMIT:
      return {
        ...state,
        loading: false,
        data: {
          title: "",
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          mobile_no: "",
          direct_line: "",
          house_no: "",
          street_name: "",
          town: "",
          zip_code: "",
          business_name: "",
          position: "",
          business_email: "",
          business_phone_no: "",
          business_address: "",
          company_registration_no: "",
          business_website: "",
          note: "",
          gdpr: "agree",
        },
      };
    default:
      return state;
  }
};

export default crReducer;
