// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TimesheetBAC from "components/TimesheetBAC/TimesheetBAC"
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import { IconButton } from "@material-ui/core";

//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import LinguistLayout from "layout/LinguistLayout";

const TimeSheetPageBAC = () => {
  const classes = useDashboardStyles();
  return (
    <Linguist>
      <LinguistLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                <p className={classes.cardTitleWhite}>
                  Account Client Timesheet
                </p>
                <IconButton color='inherit'><ArrowBackIcon
                  onClick={() => history.goBack()}
                ></ArrowBackIcon></IconButton>
              </CardHeader>
              <CardBody>
                <TimesheetBAC />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LinguistLayout>
    </Linguist>
  );
};

export default TimeSheetPageBAC;
