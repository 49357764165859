import Manager from "components/RouteProtection/ManagerAdmin/Manager";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
// Custom Components
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Moment from "moment";
import Axios from "utils/axios";

import Notes from "components/Admin/HrPanel/InvitedLinguist/Notes";
import Messages from "components/Admin/HrPanel/InvitedLinguist/Message";

const ViewBAcQuestion = () => {
  const classes = useDashboardStyles();
  const { id } = useParams();
  const [values, setValues] = useState([]);

  useEffect(() => {
    Axios.get(
      `/api/manager-admin/view-invited-linguist-details/inviteID/${id}`
    ).then((result) => {
      setValues(result.data);
    });
  }, []);

  return (
    <Manager>
      <MasterLayout>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>
              Invited Linguist - {values?.id}
            </p>
            <IconButton color="inherit">
              <ArrowBackIcon onClick={() => history.goBack()} />
            </IconButton>
          </CardHeader>
        </GridItem>

        <div raised style={{ marginLeft: "15%", marginRight: "15%" }}>
          <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
            <CardHeader
              color="primary"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginTop: 10,
                top: 35,
              }}
            >
              <p className={classes.cardTitleWhite}>Invited Linguist</p>
            </CardHeader>
          </GridItem>
          <Card
            raised
            style={{ marginTop: "24px", padding: "30px", zIndex: 0 }}
          >
            <CardBody>
              <div>
                <h2>
                  Date:{" "}
                  {Moment(values?.createdAt).format("DD MMM YYYY - kk:mm:ss")}
                </h2>
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    fontSize: 15,
                    width: 100 + "%",
                  }}
                >
                  Name: {values?.first_name + " " + values?.last_name}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    fontSize: 15,
                    width: 100 + "%",
                  }}
                >
                  Email: {values?.email}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    fontSize: 15,
                    width: 100 + "%",
                  }}
                >
                  Mobile Number: {values?.mobile_no}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    fontSize: 15,
                    width: 100 + "%",
                  }}
                >
                  Target Language: {values?.target_language}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    fontSize: 15,
                    width: 100 + "%",
                  }}
                >
                  Source Language: {values?.source_language}
                </p>
              </div>
            </CardBody>
          </Card>
          <br />
          <Card>
            <CardHeader
              color="primary"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: 20,
                top: 35,
              }}
            >
              <p className={classes.cardTitleWhite}>Notes and Messages</p>
            </CardHeader>
            <CardBody>
              <Notes />
              <Messages />
            </CardBody>
          </Card>
          <br />
          {values?.removalRequest ? (
            <Card>
              <CardHeader
                color="primary"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: 20,
                  top: 35,
                }}
              >
                <p className={classes.cardTitleWhite}>
                  Removal Request From Invited Linguist
                </p>
              </CardHeader>
              <CardBody>
                <h2 style={{ color: "red" }}>
                  <br />
                  Date: {values?.del_request_date}
                </h2>
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    fontSize: 15,
                    width: 100 + "%",
                    color: "red",
                  }}
                >
                  Name: {values?.first_name + " " + values?.last_name}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    fontSize: 15,
                    width: 100 + "%",
                    color: "red",
                  }}
                >
                  Email: {values?.email}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    fontSize: 15,
                    width: 100 + "%",
                    color: "red",
                  }}
                >
                  Mobile Number: {values?.mobile_no}
                </p>
              </CardBody>
            </Card>
          ) : null}
        </div>
      </MasterLayout>
    </Manager>
  );
};

export default ViewBAcQuestion;
