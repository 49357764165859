//cashclient
//signin
export const CASH_CLIENT_SIGNIN_REQUEST = 'CASH_CLIENT_SIGNIN_REQUEST';
export const CASH_CLIENT_SIGNIN_SUCCESS = 'CASH_CLIENT_SIGNIN_SUCCESS'
export const CASH_CLIENT_SIGNIN_FAIL = 'CASH_CLIENT_SIGNIN_FAIL'
export const CASH_CLIENT_SIGNOUT = 'CASH_CLIENT_SIGNOUT'


export const CASH_CLIENT_REGISTER_REQUEST = 'CASH_CLIENT_REGISTER_REQUEST';
export const CASH_CLIENT_REGISTER_SUCCESS = 'CASH_CLIENT_REGISTER_SUCCESS'
export const CASH_CLIENT_REGISTER_FAIL = 'CASH_CLIENT_REGISTER_FAIL'

export const CASH_CLIENT_INTERPRETING_REQUEST = 'CASH_CLIENT_INTERPRETING_REQUEST';
export const CASH_CLIENT_INTERPRETING_SUCCESS = 'CASH_CLIENT_INTERPRETING_SUCCESS'
export const CASH_CLIENT_INTERPRETING_FAIL = 'CASH_CLIENT_INTERPRETING_FAIL'

//quots
export const CASH_CLIENT_QUOTS_REQUEST = 'CASH_CLIENT_QUOTS_REQUEST';
export const CASH_CLIENT_QUOTS_SUCCESS = 'CASH_CLIENT_QUOTS_SUCCESS'
export const CASH_CLIENT_QUOTS_FAIL = 'CASH_CLIENT_QUOTS_FAIL'

export const CASH_CLIENT_TRANSLATION_REQUEST = 'CASH_CLIENT_TRANSLATION_REQUEST';
export const CASH_CLIENT_TRANSLATION_SUCCESS = 'CASH_CLIENT_TRANSLATION_SUCCESS'
export const CASH_CLIENT_TRANSLATION_FAIL = 'CASH_CLIENT_TRANSLATION_FAIL'

//quotes request
export const CASH_CLIENT_QUOTES_REQUEST = 'CASH_CLIENT_QUOTES_REQUEST';
export const CASH_CLIENT_QUOTES_SUCCESS = 'CASH_CLIENT_QUOTES_SUCCESS'
export const CASH_CLIENT_QUOTES_FAIL = 'CASH_CLIENT_QUOTES_FAIL'


/* TELIPHONIC BOOKING  */
export const TELIPHONIC_BOOKING_REQUEST = 'TELIPHONIC_BOOKING_REQUEST';
export const TELIPHONIC_BOOKING_SUCCESS = 'TELIPHONIC_BOOKING_SUCCESS'
export const TELIPHONIC_BOOKING_FAIL = 'TELIPHONIC_BOOKING_FAIL'

//EMPLOYEE

//signin
export const EMPLOYEE_SIGNIN_REQUEST = 'EMPLOYEE_SIGNIN_REQUEST';
export const EMPLOYEE_SIGNIN_SUCCESS = 'EMPLOYEE_SIGNIN_SUCCESS'
export const EMPLOYEE_SIGNIN_FAIL = 'EMPLOYEE_SIGNIN_FAIL'
export const EMPLOYEE_SIGNOUT = 'EMPLOYEE_SIGNOUT'


//ACCOUNT__CLIENT

//book interperter
export const ACCOUNT_CLIENT_INTERPRETING_REQUEST = 'ACCOUNT_CLIENT_INTERPRETING_REQUEST';
export const ACCOUNT_CLIENT_INTERPRETING_SUCCESS = 'ACCOUNT_CLIENT_INTERPRETING_SUCCESS'
export const ACCOUNT_CLIENT_INTERPRETING_FAIL = 'ACCOUNT_CLIENT_INTERPRETING_FAIL'


//ORDER_TRANSLATION
export const ORDERTRANSLATION_BOOKING_FAIL = 'ORDERTRANSLATION_BOOKING_FAIL';
export const ORDERTRANSLATION_BOOKING_REQUEST = 'ORDERTRANSLATION_BOOKING_REQUEST'
export const ORDERTRANSLATION_BOOKING_SUCCESS = 'ORDERTRANSLATION_BOOKING_SUCCESS'


//JOB_ADVART
export const CASH_CLIENT_JOBADVART_REQUEST = 'CASH_CLIENT_JOBADVART_REQUEST' 
export const CASH_CLIENT_JOBADVART_SUCCESS = 'CASH_CLIENT_JOBADVART_SUCCESS'
export const CASH_CLIENT_JOBADVART_FAIL = 'CASH_CLIENT_JOBADVART_FAIL'


export const ACCOUNT_CLIENT_SIGNIN_REQUEST = "ACCOUNT_CLIENT_SIGNIN_REQUEST"
export const ACCOUNT_CLIENT_SIGNIN_SUCCESS = "ACCOUNT_CLIENT_SIGNIN_SUCCESS"
export const ACCOUNT_CLIENT_SIGNIN_FAIL = "ACCOUNT_CLIENT_SIGNIN_FAIL"