import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { Checkbox } from "semantic-ui-react";
import Axios from "axios";

import "./sigCanvas.css";
import moment from "moment";

const TimesheetFooter = ({ details }) => {
  const [signature, setSignature] = useState({
    linSignature: "",
  });

  const [cacSignature, setcACSignature] = useState({
    cacSignature: "",
  });

  const [bacSignature, setBACSignature] = useState({
    bacSignature: "",
  });

  const [incSignature, setINCSignature] = useState({
    incSignature: "",
  });

  var arrayBufferView = new Uint8Array(details.signature?.data?.data);
  var blob = new Blob([arrayBufferView], { type: "image/jpeg" });

  useEffect(() => {
    const getCACSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/cashclient/view-signature/timesheetID/${details._id}`
        );
        setcACSignature({ ...cacSignature, cacSignature: response?.data });
      } catch (error) {
        console.log(error);
      }
    };

    const getLINSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/view-lin-signature/timesheetID/${details._id}`
        );
        setSignature({ ...signature, linSignature: response?.data });
      } catch (error) {
        console.log(error);
      }
    };

    const getBACSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/accountclient/view-signature/timesheetID/${details._id}`
        );
        setBACSignature({ ...bacSignature, bacSignature: response?.data });
      } catch (error) {
        console.log(error);
      }
    };

    const getINCSignature = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/inhouse/view-signature/timesheetID/${details._id}`
        );
        setINCSignature({ ...incSignature, incSignature: response?.data });
      } catch (error) {
        console.log(error);
      }
    };

    getCACSignature();
    getLINSignature();
    getBACSignature();
    getINCSignature();
  }, []);

  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="2">
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
            <Table.HeaderCell width="2">
              To be completed by client
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            {details.signatureByLINTrue ? (
              <Table.Cell>
                <Checkbox
                  label="I declare that I have checked the information on this form and certify that it is true and complete"
                  checked={true}
                  disabled
                />
              </Table.Cell>
            ) : (
              <Table.Cell>
                <Checkbox
                  label="I declare that I have checked the information on this form and certify that it is true and complete"
                  disabled
                />
              </Table.Cell>
            )}

            {details.signatureByCACTrue
              || details.signatureByBACTrue
              || details.signatureByINCTrue
              || details.signatureByPRCTrue ? (
              <Table.Cell>
                <Checkbox
                  label="I declare the information given by me is true/accurate and complete."
                  checked={true}
                  disabled
                />
              </Table.Cell>
            ) : (
              <Table.Cell>
                <Checkbox
                  label="I declare the information given by me is true/accurate and complete."
                  disabled
                />
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ display: "flex", flexDirection: "column" }}>
              <div>Signature</div>
              <img
                style={{ width: "130px" }}
                src={signature?.linSignature}
                alt=""
              />
            </Table.Cell>
            <Table.Cell>
              <div>
                Signature
                {cacSignature?.cacSignature ? (
                  <img
                    style={{ width: "130px" }}
                    src={cacSignature?.cacSignature}
                    alt=""
                  />
                ) : null}
                {bacSignature?.bacSignature ? (
                  <img
                    style={{ width: "130px" }}
                    src={bacSignature?.bacSignature}
                    alt=""
                  />
                ) : null}
                {incSignature?.incSignature ? (
                  <img
                    style={{ width: "130px" }}
                    src={incSignature?.incSignature}
                    alt=""
                  />
                ) : null}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <p>
                Full Name:
                <b>
                  {details.lin_fullname
                    ? details.lin_fullname
                    : " "}
                </b>
              </p>
            </Table.Cell>

            <Table.Cell>
              <p>
                Full Name:
                <b>
                  {details.client_fullname
                    ? details.client_fullname
                    : "N/A"}
                </b>
              </p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {moment().format("DD-MMM-YYYY")}</Table.Cell>
            <Table.Cell>Date: {moment().format("DD-MMM-YYYY")}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default TimesheetFooter;
