import React, { useState, useEffect } from "react";

import MasterLayout from "layout/MasterLayout";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

import Axios from "utils/axios";
import swal from "sweetalert";
import { AccordionSummary } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import CustomAvatar from "components/Common/CustomAvatar";
import moment from "moment";
import { Header } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Cookies from "js-cookie";
import { Table } from "semantic-ui-react";
import history from "utils/history";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";

const FormOne = () => {
  const token = Cookies.get("token");
  const classesb = useLandingpageStyles();
  const classes = useEmployeeStyles();
  const [values, setValues] = useState("");
  const [edit, setEdit] = React.useState({
    loader: false,
  });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/BAC/get-quotation-details`
    )
      .then((res) => res.json())
      .then((result) => {
        setValues(result);
      });
  }, []);

  const sendLink = async (e) => {
    setEdit({ ...edit, loader: true });
    try {
      await Axios.post(
        `/api/BAC/sendlink-quotation-document/assignmentID/${values?._id}`,
        " ",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `Send Link For Document/s Upload`,
        icon: "success",
        buttons: false,
      });
      // .then(function () {
      //     window.location = "/admin/dashboard";
      // });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `Failed`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleSend = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          sendLink(value);
          break;
        default:
          break;
      }
    });
  };

  return values ? (
    <MasterLayout>
      <Manager>
        <form className={classes.form} noValidate autoComplete="off">
          <div className={classes.root}>
            <GridContainer justify="center" className={classes.container}>
              <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
                <Card>
                  <CardBody>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CustomAvatar />
                    </div>
                    <div className={`${classes.textCenter} ${classes.title}`}>
                      <Header style={{ margin: "0" }} as="h3">
                        Linguists Collective (LC)
                      </Header>
                      <Header style={{ margin: "0" }} as="h3">
                        Send Link For Document/s Upload Of Assignment{" "}
                        {values?.id}
                      </Header>
                    </div>

                    <br />

                    <GridItem xs={12} sm={12} md={4} lg={12} xl={12}>
                      <Card>
                        <CardBody>
                          <GridContainer alignItems="center">
                            <Table color={"pink"}>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell>
                                    Date and time of quote creation
                                  </Table.Cell>
                                  <Table.Cell>
                                    {moment(values.createdAt).format(
                                      "YYYY-MM-DD, HH:mm"
                                    )}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Quote request for</Table.Cell>
                                  <Table.Cell>
                                    {values.quote_rqst_for}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Purchase order number</Table.Cell>
                                  <Table.Cell>
                                    {values.purchase_order_number}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Timezone</Table.Cell>
                                  <Table.Cell>{values.timezone}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>
                                    House number or street name
                                  </Table.Cell>
                                  <Table.Cell>
                                    {values.house_street_no_name}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Address</Table.Cell>
                                  <Table.Cell>
                                    {values.address_line_two}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Town / city</Table.Cell>
                                  <Table.Cell>{values.town_city}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Post code / zip code</Table.Cell>
                                  <Table.Cell>{values.post_code}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Country / region</Table.Cell>
                                  <Table.Cell>
                                    {values.county_region}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Country</Table.Cell>
                                  <Table.Cell>{values.country}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Other details</Table.Cell>
                                  <Table.Cell>
                                    {values.other_details}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Quote request details</Table.Cell>
                                  <Table.Cell>
                                    {values.request_details}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Project budget</Table.Cell>
                                  <Table.Cell>
                                    {values.project_fix_budget}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Source language </Table.Cell>
                                  <Table.Cell>
                                    {values.source_language}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>
                                    Source language skill level
                                  </Table.Cell>
                                  <Table.Cell>
                                    {values.source_skills_required}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Target Language</Table.Cell>
                                  <Table.Cell>
                                    {values.target_language}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>
                                    Target Language skill level
                                  </Table.Cell>
                                  <Table.Cell>
                                    {values.target_skills_required}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Provider ID number</Table.Cell>
                                  <Table.Cell>
                                    {values.provider_id_number}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Prefered gender</Table.Cell>
                                  <Table.Cell>{values.gender}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Security clearence</Table.Cell>
                                  <Table.Cell>
                                    {values.security_clearance}
                                  </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Quote by</Table.Cell>
                                  <Table.Cell>{values.quote_by}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                  <Table.Cell>Payment type</Table.Cell>
                                  <Table.Cell>{values.pay_type}</Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                    {values.upload_cv ? (
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <AppBar
                          position="static"
                          className={`${classesb.button}`}
                          style={{ width: "100%", height: "100%" }}
                        >
                          <Toolbar
                            onClick={(e) => history.push("/admin/dashboard")}
                          >
                            <h3
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Complete
                            </h3>
                          </Toolbar>
                        </AppBar>
                      </AccordionSummary>
                    ) : (
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        {edit.loader ? (
                          <h3
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {edit.loader && (
                              <div class="ui active inline loader"></div>
                            )}
                          </h3>
                        ) : (
                          <AppBar
                            position="static"
                            className={`${classes.button}`}
                            style={{ width: "100%", height: "100%" }}
                          >
                            <Toolbar onClick={handleSend}>
                              <h3
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Send Link For Document/s Upload
                              </h3>
                            </Toolbar>
                          </AppBar>
                        )}
                      </AccordionSummary>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </form>
      </Manager>
    </MasterLayout>
  ) : null;
};

export default FormOne;
