import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from 'js-cookie'
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Options from "components/Admin/BookingPanel/PrivateQuote/Options"
import AcceptedPRC from "components/Admin/BookingPanel/PrivateQuote/PrivateAcceptedQuote";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useHistory } from 'react-router-dom';




const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const history = useHistory()
  const [booking_panel, setAdminAccess] = useState(null)

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(response);
    setAdminAccess(response?.data.booking_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);


  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {booking_panel ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <p className={classes.cardTitleWhite}>Private Client Quote (Accepted)</p>
                  <div style={{ display: 'flex' }}>
                    <Options />
                    <IconButton color='inherit' style={{ marginLeft: 5 }} onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
                  </div>

                </CardHeader>
                <CardBody>
                  <AcceptedPRC />
                </CardBody>
              </Card>
            </GridItem>

            :
            booking_panel === 0 ?
              <Header as='h3'>Access denied</Header>
              :
              <Dimmer active inverted>
                <Loader size='large'>Loading</Loader>
              </Dimmer>
          }

        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
