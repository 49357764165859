import React, { Fragment } from "react";

// Custom Components
import ViewOTLinguists from "components/Auth/ViewOTLinguists/LinguistsCollective";

const ViewOTLinguistsPage= () => {
  return (
    <Fragment>
      <ViewOTLinguists />
    </Fragment>
  );
};

export default ViewOTLinguistsPage
