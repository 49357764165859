import React, { useState, useEffect } from "react";
import history from "utils/history";
import StripeLayout from "layout/StripeLayout";
import CheckoutForm from "layout/CheckoutFormLayout/CheckoutForm";
import Cookies from "js-cookie";
import { Dimmer, Loader } from "semantic-ui-react";
import Axios from "axios";
import { useParams } from "react-router";

const PayTelINCBI = () => {
  const grandTotal = Cookies.get("grandTotal");
  const currency = Cookies.get("stripeCurrency");
  const [details, setDetails] = useState(null);
  const [loader, setLoader] = useState(true);
  const id = Cookies.get("incClientID");
  const { token } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getDetails = async (req, res) => {
      try {
        const response = await Axios.put(
          `${process.env.REACT_APP_BACKEND_API}/get/pay/inc/telephonic/interpreter/details`,
          {
            token: token,
          }
        );
        Cookies.set("bookingid", response?.data.details.id);
        setData(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getDetails();
  }, []);

  return data ? (
    <StripeLayout clientId={data.details.id}>
      <CheckoutForm
        price={data.total}
        currency={data.currency}
        onSuccessfulCheckout={(id) =>
          history.push(
            `/inhouse-client/interpreter/success/estimate/payment/${id}`
          )
        }
        incClientID={data.details.ClientID.id}
      />
    </StripeLayout>
  ) : null;
};

export default PayTelINCBI;
