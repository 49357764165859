import { useState, useEffect } from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

const ViewOneJob = ({ id }) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/frontpage/view-job/${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setValues(result);
      });
  }, [id]);

  return (
    <div>
      <Card raised style={{}}>
        <CardBody>
          <h4 style={{ textAlign: "center" }}>Job Details</h4>
        </CardBody>
      </Card>
      <Card raised style={{ marginTop: "24px", padding: "30px" }}>
        <CardBody>
          {/* <h4 style={{textAlign: "center"}}>Detail Information </h4> */}
          <p>Job Title: {values.jobtitle} </p>
          <p>Salary: {values.salary} </p>
          <p>Contract type: {values.contracttype} </p>
          <p>Hours per week: {values.hoursperweek} </p>
          <p>Remote address: {values.remote_details} </p>
          <p>
            House number or street number: {values.business_addressline_one}{" "}
          </p>
          <p>Address: {values.business_addressline_two} </p>
          <p>City: {values.business_city} </p>
          <p>Post code: {values.business_postcode} </p>
          <p>Country / Region: {values.business_region} </p>
          <p>Country: {values.business_country} </p>
        </CardBody>
      </Card>

      <Card raised style={{ padding: "30px" }}>
        <CardBody>
          {/* <h4 style={{textAlign: "center"}}>Detail Information </h4> */}

          <p>Job Reference: {values.jobref} </p>
          <p>Target Language: {values.targetlanguage} </p>
          <p>Source Language: {values.sourcelanguage} </p>
          <p>Additional Language: {values.additionallanguage} </p>
          <p>Linguist Skill Level: {values.expertiselevel} </p>
          <p>About Company: {values.about_company} </p>
          <p>Job Description: {values.jobdescription} </p>
          <p>Application Closing Date: {values.applicationclosingdate} </p>
          <p>How to apply: {values.howtoapply} </p>

          <p>Business name: {values.business_name} </p>
          <p>About Company: {values.about_company} </p>
        </CardBody>
      </Card>
      <br />
      <br />
      <br />
    </div>
  );
};

export default ViewOneJob;
