import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import ListAltIcon from '@material-ui/icons/ListAlt';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Fragment } from "react";
import { useMediaQuery } from "@material-ui/core";

const LinguistOptions = () => {
  const history = useHistory();
  const matches = useMediaQuery('(max-width:768px)')
  return (
    <div style={{display:'flex',justifyContent:'center'}}>
     <Button
        startIcon={<ListAltIcon />}
        color="primary"
        variant="contained"
        style={{
           width: "45%",
           marginRight: "15px" }}
        onClick={() => history.push("/admin/inactive-employees")}
      >
         Inactive Employees
      </Button>
      <Button
        startIcon={<VerifiedUserIcon />}
        color="primary"
        variant="contained"
        style={{ 
          width: "45%",
           marginRight: "15px" }}
        onClick={() => history.push("/admin/active-employees")}
      >
       Active Employees
      </Button>
    </div>
  );
};

export default LinguistOptions;
