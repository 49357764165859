import React from "react";

// Custom Components
// Custom Styles
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Button, Header, Icon, Loader, Modal, Table } from "semantic-ui-react";
import Moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Action from "./Action";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "70vh",
    overflowY: "auto",
  },
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 400,
  },
});

const CommunicationMenu = ({
  values,
  TicketDetails,
  load,
  setFilter,
  setFiltered,
}) => {
  // const classes = useDashboardStyles();
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { providedQuotes } = useSelector(
    (state) => state.unconfirmQuotesReducer
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (value) => {
    if (value === "" || value === undefined) {
      load();
    } else {
      setFiltered(
        values?.filter((item) => {
          if (item.ticket_no == value) {
            return item.ticket_no == value;
          } else if (
            Moment(item.createdAt)
              .tz("Europe/London")
              .format("DD MMM YYYY - HH:mm:ss")
              ?.toLowerCase() === value?.toLowerCase()
          ) {
            return (
              Moment(item.createdAt)
                .tz("Europe/London")
                .format("DD MMM YYYY - HH:mm:ss")
                ?.toLowerCase() === value?.toLowerCase()
            );
          } else if (item.subject?.toLowerCase() === value?.toLowerCase()) {
            return item.subject?.toLowerCase() === value?.toLowerCase();
          }
        })
      );
      setFilter(Math.random());
    }
  };

  return (
    <>
      <Divider />
      <Grid item xs={12} style={{ padding: "10px" }}>
        <TextField
          onChange={(e) => handleSearch(e.target.value)}
          id="outlined-basic-email"
          label="Search"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Divider />
      <List>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table color="pink" stickyHeader aria-label="sticky table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Ticket no.</Table.HeaderCell>
                  <Table.HeaderCell>Created By</Table.HeaderCell>
                  <Table.HeaderCell>Created At</Table.HeaderCell>
                  <Table.HeaderCell>Subject</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {values.length > 0 ? (
                <Table.Body>
                  {values
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((list, i) => (
                      <Table.Row
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={i}
                        style={
                          list?.urgency == "Low"
                            ? { backgroundColor: "white" }
                            : list?.urgency == "Medium"
                            ? { backgroundColor: "#DFFF00h" }
                            : list?.urgency == "High"
                            ? { backgroundColor: "#FFBF00" }
                            : list?.urgency == "Urgent"
                            ? { backgroundColor: "#FF7F7F" }
                            : null
                        }
                      >
                        <Table.Cell>{list.ticket_no}</Table.Cell>
                        <Table.Cell>{list.postedByIDName}</Table.Cell>
                        <Table.Cell>
                          {Moment(list.createdAt)
                            .tz("Europe/London")
                            .format("DD MMM YYYY - HH:mm:ss")}
                        </Table.Cell>
                        <Table.Cell> {list?.subject} </Table.Cell>
                        <Table.Cell>
                          {" "}
                          {list?.status ? "Open" : "Closed"}{" "}
                        </Table.Cell>
                        <Table.Cell>
                          <Action
                            id={list._id}
                            adminID={list?.postedByIDName?.includes("ADM")}
                            ticketDetails={TicketDetails}
                          ></Action>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              ) : null}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={values?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </List>
    </>
  );
};

export default CommunicationMenu;
