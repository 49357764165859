import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import MasterLayout from "layout/MasterLayout";
import Meetings from "components/ManagementPanel/Meetings";

const MeetingsPage = () => {
  return (
    <MasterLayout>
      <Manager>
        <Meetings />
      </Manager>
    </MasterLayout>
  );
};

export default MeetingsPage;
