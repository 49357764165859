import { ADD_TRANSACTION, DELETE_TRANSACTION, ADD_OTHER_TRANSACTION, DELETE_OTHER_TRANSACTION } from "redux/actions/actionTypes";

const INITIAL_STATE = {
    transactions: [],
    otherTransactions: []
}

export const expenseReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case ADD_TRANSACTION:
            return {
                ...state,
                transactions: [payload, ...state.transactions]
            };

        case DELETE_TRANSACTION:
            return {
                ...state,
                transactions: state.transactions.filter(transaction => transaction.id !== action.payload)
            }
        
        case ADD_OTHER_TRANSACTION:
            return {
                ...state,
                otherTransactions: [payload, ...state.otherTransactions]
            }
        
        case DELETE_OTHER_TRANSACTION:
            return {
                ...state,
                otherTransactions: state.otherTransactions.filter(transaction => transaction.id !== action.payload)
            }
        
        default:
            return state
    }
}

export default expenseReducer