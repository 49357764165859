import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LinList from "components/Admin/AccountFinance/InvoiceToLinguist/InvoiceToLinguist";
import InvoiceList from "components/Admin/AccountFinance/InvoiceToLinguist/FindBill/FindBill";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const [finance_panel, setAdminAccess] = useState(null);
  const buttonclasses = useStyles();

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminAccess(response?.data.finance_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {finance_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>Invoice to Linguist</p>
                  <div style={{ display: "flex" }}>
                    <div className={buttonclasses.root}>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          history.push("invoice-to-linguist/create-invoice")
                        }
                      >
                        Raise invoice
                      </Button>
                    </div>
                    <IconButton
                      color="inherit"
                      style={{ marginLeft: 5 }}
                      onClick={() => history.goBack()}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                </CardHeader>

                <CardBody>
                  <Header as="h3">Linguist List</Header>
                  <LinList />
                  <Header as="h3">Invoice List</Header>
                  <InvoiceList />
                </CardBody>
              </Card>
            </GridItem>
          ) : finance_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
