// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TimesheetINCPublic from "components/TimesheetINCPublic/TimesheetINCPublic"
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import LandingPageLayout from "layout/LandingPageLayout";

const TimeSheetINCPublicPage = () => {
  const classes = useDashboardStyles();
  return (
    <LandingPageLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>In-house client timesheet</p>
            </CardHeader>
            <CardBody>
              {/* <TimeSheet /> */}
              <TimesheetINCPublic />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default TimeSheetINCPublicPage;
