import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Button } from "semantic-ui-react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import AllocateActions from "components/Admin/Dashboard/JobLists/AllocateActions";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import history from "utils/history";

const Action = ({
  list,
  id,
  allocated,
  cancel,
  cancel_request,
  report,
  reportID,
  invoice,
  invoiceID,
  load,
  handlePaid,
  handlePaidBankPay,
  handlePaidMonthlyPay,
  bank_pay,
  monthly_pay,
  ID,
  clientID,
  name,
  currency,
  estimate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("EMPtoken");
  const [showallocate, setShow] = React.useState(false);
  const [showConfirmation, setConfirmation] = React.useState(false);
  const [text, setText] = React.useState("");
  const [data, setData] = React.useState([]);

  const [showNote, setShowNote] = React.useState(false);
  const [viewNote, setViewNote] = React.useState(false);

  const [showMessage, setShowMessage] = React.useState(false);
  const [viewMessage, setViewMessage] = React.useState(false);

  const [totalnote, setTotalNote] = React.useState(false);
  const [totalmessage, setTotalMessage] = React.useState(false);

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const [Linguist, setLinguist] = React.useState({
    id: "",
    data: "",
  });

  const [pay, setPay] = React.useState({
    admin_booking_notes: "",
  });

  const handleBack = () => {
    setViewNote(false);
  };
  const handleConfirmation = () => {
    setConfirmation(!showConfirmation);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShow(false);
    setConfirmation(false);
    setShowNote(false);
    setShowMessage(false);
    setLinguist({ ...Linguist, data: "" });
  };

  const handleShow = () => {
    setShowNote(!showNote);
  };

  const handleShowMessage = () => {
    setShowMessage(!showMessage);
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  const handleNote = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/employee/writeNote-translation-accountclient/translationID/${id}`,
        {
          admin_booking_notes: pay.admin_booking_notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "Note successfully added",
      });
      getTotalNote();
      setPay({ ...pay, admin_booking_notes: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleMessage = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/employee/write-message-translation-bac/translationID/${id}`,
        {
          admin_booking_notes: pay.admin_booking_notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "Message Sent!!!",
      });
      getTotalMessage();
      setPay({ ...pay, admin_booking_notes: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const getTotalNote = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-Note-translation-bac/translationID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalMessage = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/count-message-translation-bac/translationID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalMessage(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalNote();
    getTotalMessage();
  }, []);

  const handleYes = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/employee/allocate-Translation-BAC/TranslationID/${id}`,
        {
          linID: text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // setShow(!showallocate)
      // setValues({...values,reload:!reload})
      // setText('')
      // setLinguist({...Linguist, data: ''})
      swal({
        icon: "success",
        buttons: false,
        text: response?.data,
      });

      // setAnchorEl(null);
      // handleClose()
      // load()
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed",
      });
    }
  };

  const handleAllocate = async () => {
    setShow(!showallocate);
  };

  const handleAutoFill = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-lin/linID`,
        {
          id: text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLinguist({ ...Linguist, data: response?.data });
      if (!response?.data) {
        swal({
          text: "Wrong linguist Id",
          buttons: "Ok",
          dangerMode: true,
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
      swal({
        text: "Wrong linguist Id",
        buttons: false,
        dangerMode: true,
        icon: "error",
      });
    }
  };

  const handleJobBack = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/employee/takejob-Translation-BAC/TranslationID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //
      swal({
        icon: "success",
        buttons: false,
        text: response?.data.message,
      });
      load();
      setAnchorEl(null);
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlereport = async () => {
    history.push(`/employee/report/reportID/${reportID}`);
  };

  const handleInvoice = async () => {
    history.push(`/employee/view-invoice/${invoiceID}`);
  };

  const Archive = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/archive-inactive-assignments/${id}`
      );
      swal({
        icon: "success",
        text: `Assignment moved to the archive folder!!!`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleArchive = async () => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          Archive(value);

        default:
          break;
      }
    });
  };



  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!allocated && !cancel && !cancel_request && !report && !invoice ? (
          <MenuItem onClick={(e) => handleAllocate(e)}>
            Allocate Manually
          </MenuItem>
        ) : null}
        {allocated && !report && !invoice ? (
          <MenuItem onClick={(e) => handleJobBack(e)}>Take Job Back</MenuItem>
        ) : null}
        {reportID && !invoice && !cancel ? (
          <MenuItem onClick={(e) => handlereport(e)}>
            View Project Report
          </MenuItem>
        ) : null}
        {invoiceID ? (
          <MenuItem onClick={(e) => handleInvoice(e)}>View Invoice</MenuItem>
        ) : null}

        {!cancel_request &&
        !cancel &&
        !report &&
        !invoice &&
        !bank_pay &&
        !monthly_pay ? (
          <MenuItem onClick={handlePaid}>Cancel and Refund</MenuItem>
        ) : null}
        {cancel_request &&
        !cancel &&
        !report &&
        !invoice &&
        !bank_pay &&
        !monthly_pay ? (
          <MenuItem onClick={handlePaid}>Cancel and Refund</MenuItem>
        ) : null}

        {!cancel_request &&
        !cancel &&
        !report &&
        !invoice &&
        bank_pay &&
        !monthly_pay ? (
          <MenuItem onClick={handlePaidBankPay}>Cancel and Refund</MenuItem>
        ) : null}
        {cancel_request &&
        !cancel &&
        !report &&
        !invoice &&
        bank_pay &&
        !monthly_pay ? (
          <MenuItem onClick={handlePaidBankPay}>Cancel and Refund</MenuItem>
        ) : null}

        {!cancel_request &&
        !cancel &&
        !report &&
        !invoice &&
        !bank_pay &&
        monthly_pay ? (
          <MenuItem onClick={handlePaidMonthlyPay}>Cancel and Refund</MenuItem>
        ) : null}
        {cancel_request &&
        !cancel &&
        !report &&
        !invoice &&
        !bank_pay &&
        monthly_pay ? (
          <MenuItem onClick={handlePaidMonthlyPay}>Cancel and Refund</MenuItem>
        ) : null}

        <MenuItem onClick={handleShow}>Add note ({totalnote})</MenuItem>
        <MenuItem onClick={handleShowMessage}>
          Send Message ({totalmessage}){" "}
        </MenuItem>
        { id ?
              <MenuItem onClick={handleArchive}>Archive (Delete)</MenuItem> : null}
      </Menu>

      {/* allocate manually */}
      {showallocate ? (
        <div>
          <Dialog open={showConfirmation} onClose={handleClose}>
            <DialogTitle>
              I confirm that languages and expertise match, YES, I want to
              allocate this to linguist -{" "}
              {Linguist.data ? Linguist.data.id : ""}
            </DialogTitle>
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={handleClose}
                style={{ background: "#d9534f none", color: "white" }}
                autoFocus
              >
                No, Cancel
              </Button>
              <Button
                onClick={handleYes}
                style={{ background: "#5cb85c none", color: "white" }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={showallocate} onClose={handleClose}>
            <DialogTitle style={{ borderBottom: "1px rgb(0,0,0,.2) solid" }}>
              Assigning Assignment Manually
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`Assignment reference: ${list.id || ""} `}
              </DialogContentText>
              <DialogContentText>
                {`Source language: ${list.source_language || ""} `}
              </DialogContentText>
              <DialogContentText>
                {`Target language: ${list.target_language || ""} `}
              </DialogContentText>
              <DialogContentText>
                {`Target Language Expertise Level: ${
                  list.target_linguist_skill_level || ""
                } `}
              </DialogContentText>
            </DialogContent>
            <DialogContent style={{ width: "600px" }}>
              <AllocateActions
                text={text}
                setText={setText}
                autoFill={handleAutoFill}
              />
            </DialogContent>
            <DialogContent style={{ borderBottom: "1px rgb(0,0,0,.2) solid" }}>
              <DialogContentText>
                {`First name(s): ${
                  Linguist.data ? Linguist.data.FirstName : ""
                } `}
              </DialogContentText>
              <DialogContentText>
                {`Last name: ${Linguist.data ? Linguist.data.LastName : ""}`}
              </DialogContentText>
              <DialogContentText>
                {`Phone number: ${
                  Linguist.data ? Linguist.data.mobile_no : ""
                }`}
              </DialogContentText>
              <DialogContentText>
                {`Email: ${Linguist.data ? Linguist.data.email : ""}`}
              </DialogContentText>
              <DialogContentText>
                {`Source language(s): ${
                  (Linguist.data ? Linguist.data.source_language : "") +
                  ", " +
                  (Linguist.data ? Linguist.data.source_language_1 : "") +
                  ", " +
                  (Linguist.data ? Linguist.data.source_language_2 : "")
                }`}
              </DialogContentText>
              <DialogContentText>
                {`Target language(s): ${
                  (Linguist.data ? Linguist.data.target_language : "") +
                  ", " +
                  (Linguist.data ? Linguist.data.target_language_1 : "") +
                  ", " +
                  (Linguist.data ? Linguist.data.target_language_2 : "")
                }`}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={handleConfirmation}
                color="primary"
                variant="outlined"
                disabled={Linguist.data === "" || Linguist.data === null}
                autoFocus
              >
                Confirm Manual Assignment
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}

      {/* ADD NOTE */}
      {showNote ? (
        <Dialog open={showNote}>
          {!viewNote ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Add Note - {ID} {name} {clientID ? clientID : "N/A"}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.admin_booking_notes}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="admin_booking_notes"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.admin_booking_notes}
                    onClick={handleNote}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Save Note
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Saved Notes
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}

      {/* Send payment reminder */}
      {showMessage ? (
        <Dialog open={showMessage}>
          {!viewMessage ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Send Message {ID} {currency} {estimate} {name}{" "}
                  {clientID ? clientID : "N/A"}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.admin_booking_notes}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="admin_booking_notes"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                  {clientID ? (
                    <Button
                      disabled={!pay.admin_booking_notes}
                      onClick={handleMessage}
                      color="purple"
                      variant="outlined"
                      startIcon={<SaveIcon />}
                    >
                      Send Message
                      {edit.loader && (
                        <div class="ui active inline loader"></div>
                      )}
                    </Button>
                  ) : null}
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Send message
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}
    </div>
  );
};

export default Action;
