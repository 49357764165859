import React, { useEffect, useState } from "react";
import "../PPL/LinguistProfile.css";

// Custom Imports
import Header from "./Header/HeaderEMP";
import SecurityClearance from "../PPL/SecurityClearance/SecurityClearance";
import Verified from "../PPL/Verified/Verified";
import About from "../PPL/About/About";
import WorkExperience from "../PPL/WorkExperience/WorkExperience";
import Qualification from "../PPL/Qualification/Qualification";
import ProfMemberShip from "../PPL/ProfMemberShip/ProfMemberShip";
import Footer from "../PPL/Footer/Footer";

import { updateLinguistProfile } from "redux/actions/updateProfileAction";
import Axios from "axios";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useParams } from "react-router";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";

function LinguistProfileView() {
  const { id } = useParams();
  const token = Cookies.get("EMPtoken");
  const dispatch = useDispatch();
  const [data, setData] = useState(false);

  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/employee/view-linguist/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(updateLinguistProfile(response?.data));
      setData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={8} lg={8}>
        <h3 style={{ textAlign: "center" }} color="purple">
          Linguist Public Profile page for Approval
        </h3>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} lg={8}>
        <Card>
          <div
            style={{
              backgroundImage:
                "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
              padding: "20px",
              boxShadow:
                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            }}
          >
            <Header id={data._id} />
          </div>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default LinguistProfileView;
