import { useHistory } from "react-router-dom";

// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { useEffect, useState } from "react";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const QuoteOptions = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        onClick={() => history.push("/sppo/my-clients/direct-quote")}
      >
        Direct Quote
      </Button>
      <Button
        variant="outlined"
        onClick={() => history.push("/sppo/my-clients/direct-invoice")}
      >
        Direct Invoice
      </Button>
    </div>
  );
};

export default QuoteOptions;
