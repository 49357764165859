import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import LinguistLayout from "layout/LinguistLayout";
// Custom Components
// Custom Styles
import ViewAttach from "components/Admin/ManagementPanel/ViewAttachMessage";

const ViewContactCard = () => {
  return (
    <LinguistLayout>
      <Linguist>
        <ViewAttach />
      </Linguist>
    </LinguistLayout>
  );
};

export default ViewContactCard;
