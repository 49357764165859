import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SppoLayout from "layout/SppoLayout";
import SppoResetPassword from "components/Sppo/SppoResetPassword";


const ResetPassword = () => {

  return (
    <SppoLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <SppoResetPassword />
        </GridItem>
      </GridContainer>
    </SppoLayout>
  );
};

export default ResetPassword;
