import React, { useEffect, useState } from "react";
import "./sppoProfile.css";

// Custom Imports
import Header from "./Header/Header";
import Certifications from "./Certifications/Certifications";
import About from "./About/About";
import CoMemberShip from "./CoMemberShip/CoMemberShip";
import Footer from "./Footer/Footer";
import { updateLinguistProfile } from "redux/actions/updateProfileAction";
import Axios from "axios";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import { useLocation, useParams } from "react-router";
import useEmployeeStyles from "assets/jss/components/employeeStyle";

function PPL() {
  const classes = useEmployeeStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/public/profile/${id}`
      );
      dispatch(updateLinguistProfile(response?.data));
      setData(response?.data);
      if (response?.data.adminAllowPublicProfile === false) {
        window.location = "/";
      }
    } catch (error) {
      console.log(error.response);
      // if (error?.response?.data?.error === "Public profile not activated") {
      //   window.location = "/";
      // }
    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <div className={classes.root}>
      {data?._id ?
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={8} lg={8}>
            {data?.agent_sppo ?
              <Card>
                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                    padding: "20px",
                    boxShadow:
                      "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                    textAlign: "center"
                  }}
                >
                  This page is not available.
                </div>
              </Card>
              :
              <Card>
                <div
                  style={{
                    background: "rgb(255,240,245)",
                    padding: "20px",
                    boxShadow:
                      "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                  }}
                >
                  <Header />
                </div>

                {data.about_section ?
                  <div
                    style={{
                      background: "rgb(255,240,245)",
                      padding: "20px",
                      boxShadow:
                        "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                    }}
                  >
                    <About />
                  </div>
                  : null}

                {data?.cooperate_membership_section ?
                  <div
                    style={{
                      background: "rgb(255,240,245)",
                      padding: "20px",
                      boxShadow:
                        "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                    }}
                  >
                    <CoMemberShip id={data?._id} />
                  </div>
                  : null}

                {data?.certification_section ?
                  <div
                    style={{
                      background: "rgb(255,240,245)",
                      padding: "20px",
                      boxShadow:
                        "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                    }}
                  >
                    <Certifications id={data?._id} />
                  </div>
                  : null}

                <div
                  style={{
                    background: "rgb(255,240,245)",

                    boxShadow:
                      "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                  }}
                >
                  <Footer id={data?._id} name={data?.business_name} />
                </div>
              </Card>

            }
          </GridItem>
        </GridContainer>
        :
        <Card>
          <div
            style={{
              backgroundImage:
                "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
              padding: "20px",
              boxShadow:
                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            }}
          >
            This page is not available
          </div>
        </Card>
      }
    </div>
  );
}

export default PPL;
