import SearchInput from "./SearchInput";
import { useState } from "react";
import { Button } from "semantic-ui-react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const SearchClient = ({
  load,
  setSearchText,
  setShow,
  handleSearch,
  searchText,
  show,
}) => {
  // const matches = useMediaQuery("(max-width:768px)");
  // const showClients = (e) => {
  //   setShow(true);
  // };
  return (
    <div>
      <SearchInput
        load={load}
        setSearchText={setSearchText}
        searchText={searchText}
        handleSearch={handleSearch}
        show={show}
        setShow={setShow}
      />
      {/* {matches ? (
        <Button style={{ width: "100%" }} color="purple" onClick={showClients}>
          View all inhouse clients
        </Button>
      ) : (
        <Button
          style={{ marginTop: "24px", marginBottom: 10 }}
          color="purple"
          onClick={showClients}
        >
          View all inhouse clients
        </Button>
      )} */}
    </div>
  );
};

export default SearchClient;
