import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Paper from "@material-ui/core/Paper";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import { useToasts } from "react-toast-notifications";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import Action from "./Action";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import moment from "moment";
import SecurityClearenceOption from "./SecurityClearenceOption.jsx"

const ViewContactCard = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useDashboardStyles();
  const [viewAll, setViewAll] = useState(null);
  const { addToast } = useToasts();

  const documentList = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/get-all-security-clearence-document3-list/linID/${id}`
      );

      setViewAll(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  useEffect(() => {
    documentList();
  }, []);

  let sl = 1;

  return (
    <Manager>
      <MasterLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Linguist Security Clearence Documents(3)
                </p>
                <IconButton color="inherit" onClick={() => history.goBack()}>
                  <ArrowBackIcon />
                </IconButton>
              </CardHeader>
              <SecurityClearenceOption />
              <CardBody>
                <Paper className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table color="pink">
                      <Table.Header>
                        <Table.Row style={{ textAlign: "left" }}>
                          <Table.Cell>Serial Number</Table.Cell>
                          <Table.Cell>Date and Time</Table.Cell>
                          <Table.Cell>Action</Table.Cell>
                        </Table.Row>
                      </Table.Header>

                      {viewAll?.length > 0 ? (
                        <Table.Body>
                          {viewAll?.map((list, i) => (
                            <>
                              <Table.Row>
                                <Table.Cell>
                                  <a
                                    href={`/admin/linguist-view-security-clearence-document3/${list?._id}`}
                                  >
                                    Document {sl++}
                                  </a>
                                </Table.Cell>
                                <Table.Cell>
                                  {moment(list?.createdAt).format(
                                    "DD-MMM-YYYY : HH:mm"
                                  )}
                                </Table.Cell>
                                <Table.Cell>
                                  {i == 0 ? null :
                                    <Action
                                      id={list?._id}
                                      document={"Document 1"}
                                      load={documentList}
                                    />}
                                </Table.Cell>
                              </Table.Row>
                            </>
                          ))}
                        </Table.Body>
                      ) : (
                        "No another document"
                      )}
                    </Table>
                  </TableContainer>
                </Paper>
              </CardBody>

              <CardBody>
                <GridContainer justify="center" className={classes.container}>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <object
                        data={`${process.env.REACT_APP_BACKEND_API}/for-linguists-manager/view-latest-security-clearence-document3/linID/${id}`}
                        type="application/pdf"
                        width="100%"
                        height="1000px"
                      ></object>
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardBody>

            </Card>
          </GridItem>
        </GridContainer>
      </MasterLayout>
    </Manager>
  );
};

export default ViewContactCard;
