import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import Typography from "@material-ui/core/Typography";

import CustomSelect from "components/Common/CustomSelect";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { changeBaInput } from "redux/actions/bookingAction";
import logo from "assets/images/logo.png";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Axios from "axios";
import { Button } from "semantic-ui-react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@material-ui/core";
import history from "utils/history";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { CURRENCY_x } from "data";
import swal from "sweetalert";
import { loadStripe } from "@stripe/stripe-js";

const BIsummary = ({ payLaterAccess }) => {
  const classes = useEmployeeStyles();
  const classesb = useLandingpageStyles();
  const [grandTotal, setGrandTotal] = useState("");
  const { data } = useSelector((state) => state.bookingReducer);
  const { currency_new, currency_new_card } = data;
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const [submitLoader, setSubmitLoader] = useState(false);
  const stripePromise = loadStripe(
    "pk_test_51I3OlCEdJeNZngVd9ROYTlsOSf8HVo8Q1MvlJeoy7Fryh5KfPtuO3LdLoggzv8QwTQgOvSrA0e4uNxbb0uDjbu1d00yj7pZaDs"
  );

  const [rates, setRates] = useState("");

  const dispatch = useDispatch();

  const { username } = JSON.parse(localStorage.getItem("CACuser"));

  const CACstripeI_D = Cookies.get("CACstripeI_D");

  const New_currency = Cookies.get("currency");

  //id
  const summary = Cookies.get("cacTransID");

  //wordcount
  const Word_count = Cookies.get("wordCount");

  //totalPriceForTranslation
  const TotalPriceForTranslation = Cookies.get("totalPriceForTranslation");

  //totalPriceForCertificate
  const TotalPriceForCertificate = Cookies.get("totalPriceForCertificate");

  const token = Cookies.get("CACtoken");
  //totalPriceForQualityCheck
  const TotalPriceForQualityCheck = Cookies.get("totalPriceForQualityCheck");

  //translationVisualFormatingPrice
  const TranslationVisualFormatingPrice = Cookies.get(
    "translationVisualFormatingPrice"
  );

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
    ).then((res) => setRates(res?.data?.rates));
  }, []);

  let a = parseFloat(TranslationVisualFormatingPrice) || 0;

  let b = parseFloat(TotalPriceForCertificate) || 0;

  let c = parseFloat(TotalPriceForTranslation) || 0;
  let d = parseFloat(TotalPriceForQualityCheck) || 0;

  const estimatedTotal = a + b + c + d;

  const handleChange = (e) => {
    dispatch(
      changeBaInput({
        name: "currency_new",
        value: e.target.value,
      })
    );
  };
  const handleChangecard = (e) => {
    const { value } = e.target;

    dispatch(
      changeBaInput({
        name: "currency_new_card",
        value: e.target.value,
      })
    );

    if (value === "EUR (Euro countries)") {
      setGrandTotal(
        estimatedTotal * rates.EUR +
          (((estimatedTotal * 2.5) / 100) * rates.EUR).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.EUR +
          ((estimatedTotal * 2.5) / 100) * rates.EUR
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "EUR €");
    } else if (value === "AUD (Australia)") {
      setGrandTotal(
        estimatedTotal * rates.AUD +
          (((estimatedTotal * 2.5) / 100) * rates.AUD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.AUD +
          ((estimatedTotal * 2.5) / 100) * rates.AUD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "AUD $");
    } else if (value === "USD (USA, paying from USA only)") {
      setGrandTotal(
        estimatedTotal * rates.USD +
          (((estimatedTotal * 2.5) / 100) * rates.USD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.USD +
          ((estimatedTotal * 2.5) / 100) * rates.USD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "USD $");
    } else if (value === "CAD (Canada)") {
      setGrandTotal(
        estimatedTotal * rates.CAD +
          (((estimatedTotal * 2.5) / 100) * rates.CAD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.CAD +
          ((estimatedTotal * 2.5) / 100) * rates.CAD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "CAD $");
    } else if (value === "TRY (Turkish lira, payment from Turkey only)") {
      setGrandTotal(
        estimatedTotal * rates.TRY +
          (((estimatedTotal * 2.5) / 100) * rates.TRY).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.TRY +
          ((estimatedTotal * 2.5) / 100) * rates.TRY
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "TRY  ₺");
    } else if (value === "SGD (Singapore dollar)") {
      setGrandTotal(
        estimatedTotal * rates.SGD +
          (((estimatedTotal * 2.5) / 100) * rates.SGD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.SGD +
          ((estimatedTotal * 2.5) / 100) * rates.SGD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "SGD $");
    } else if (value === "HUF (Hungarian forint)") {
      setGrandTotal(
        estimatedTotal * rates.HUF +
          (((estimatedTotal * 2.5) / 100) * rates.HUF).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.HUF +
          ((estimatedTotal * 2.5) / 100) * rates.HUF
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "HUF Ft");
    } else if (
      value === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      setGrandTotal(
        estimatedTotal * rates.NZD +
          (((estimatedTotal * 2.5) / 100) * rates.NZD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.NZD +
          ((estimatedTotal * 2.5) / 100) * rates.NZD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "NZD $");
    } else if (value === "RON (Romanian leu, paying from Romania only)") {
      setGrandTotal(
        estimatedTotal * rates.RON +
          (((estimatedTotal * 2.5) / 100) * rates.RON).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.RON +
          ((estimatedTotal * 2.5) / 100) * rates.RON
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "RON lei");
    } else {
      setGrandTotal(estimatedTotal.toFixed(2));
      Cookies.set("grandTotal", estimatedTotal.toFixed(2));
      Cookies.set("stripeCurrency", "GBP £");
    }
  };

  const handlePayByCard = () => {
    sessionStorage.clear();
    history.push(`/cac-translation-order/client/summary/estimate/payment`);
  };

  const handleNext = async (e) => {
    setSubmitLoader(true);
    let id = summary;
    // alert("doing")
    if (currency_new === "EUR (EU countries)") {
      let x =
        estimatedTotal * rates.EUR + ((estimatedTotal * 2.5) / 100) * rates.EUR;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient_translation/send_Confirm_Mail`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (currency_new === "AUD (Australia)") {
      let x =
        estimatedTotal * rates.AUD + ((estimatedTotal * 2.5) / 100) * rates.AUD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient_translation/send_Confirm_Mail`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (currency_new === "USD (USA, paying from USA only)") {
      let x =
        estimatedTotal * rates.USD + ((estimatedTotal * 2.5) / 100) * rates.USD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient_translation/send_Confirm_Mail`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (currency_new === "CAD (Canada)") {
      let x =
        estimatedTotal * rates.CAD + ((estimatedTotal * 2.5) / 100) * rates.CAD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient_translation/send_Confirm_Mail`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (
      currency_new === "TRY (Turkish lira, payment from Turkey only)"
    ) {
      let x =
        estimatedTotal * rates.TRY + ((estimatedTotal * 2.5) / 100) * rates.TRY;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient_translation/send_Confirm_Mail`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (currency_new === "SGD (Singapore dollar)") {
      let x =
        estimatedTotal * rates.SGD + ((estimatedTotal * 2.5) / 100) * rates.SGD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient_translation/send_Confirm_Mail`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (currency_new === "HUF (Hungarian forint)") {
      let x =
        estimatedTotal * rates.HUF + ((estimatedTotal * 2.5) / 100) * rates.HUF;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient_translation/send_Confirm_Mail`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (
      currency_new === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      let x =
        estimatedTotal * rates.NZD + ((estimatedTotal * 2.5) / 100) * rates.NZD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient_translation/send_Confirm_Mail`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (
      currency_new === "RON (Romanian leu, paying from Romania only)"
    ) {
      let x =
        estimatedTotal * rates.RON + ((estimatedTotal * 2.5) / 100) * rates.RON;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient_translation/send_Confirm_Mail`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      let new_total = estimatedTotal.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient_translation/send_Confirm_Mail`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    try {
      let EUR = (
        estimatedTotal * rates.EUR +
        ((estimatedTotal * 2.5) / 100) * rates.EUR
      ).toFixed(2);
      let AUD = (
        estimatedTotal * rates.AUD +
        ((estimatedTotal * 2.5) / 100) * rates.AUD
      ).toFixed(2);
      let USD = (
        estimatedTotal * rates.USD +
        ((estimatedTotal * 2.5) / 100) * rates.USD
      ).toFixed(2);
      let CAD = (
        estimatedTotal * rates.CAD +
        ((estimatedTotal * 2.5) / 100) * rates.CAD
      ).toFixed(2);
      let TRY = (
        estimatedTotal * rates.TRY +
        ((estimatedTotal * 2.5) / 100) * rates.TRY
      ).toFixed(2);
      let SGD = (
        estimatedTotal * rates.SGD +
        ((estimatedTotal * 2.5) / 100) * rates.SGD
      ).toFixed(2);
      let HUF = (
        estimatedTotal * rates.HUF +
        ((estimatedTotal * 2.5) / 100) * rates.HUF
      ).toFixed(2);
      let NZD = (
        estimatedTotal * rates.NZD +
        ((estimatedTotal * 2.5) / 100) * rates.NZD
      ).toFixed(2);
      let RON = (
        estimatedTotal * rates.RON +
        ((estimatedTotal * 2.5) / 100) * rates.RON
      ).toFixed(2);

      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/CAC/sendEmail-AUSDAccount`,
        {
          currency_new,
          estimatedTotal,
          username,
          rates,
          id,
          EUR,
          AUD,
          USD,
          CAD,
          TRY,
          SGD,
          HUF,
          NZD,
          RON,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `and pay to confirm your booking. You recive selected bank details via email kindly check it `,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });

      // const newresponst = Axios.post('')
      //  alert(response)
      //code
      console.clear();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePayLater = async (e) => {
    setSubmitLoader(true);
    let id = summary;
    if (currency_new === "EUR (EU countries)") {
      let x =
        estimatedTotal * rates.EUR + ((estimatedTotal * 2.5) / 100) * rates.EUR;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/translation/payLater`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });
    } else if (currency_new === "AUD (Australia)") {
      let x =
        estimatedTotal * rates.AUD + ((estimatedTotal * 2.5) / 100) * rates.AUD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/translation/payLater`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });
    } else if (currency_new === "USD (USA, paying from USA only)") {
      let x =
        estimatedTotal * rates.USD + ((estimatedTotal * 2.5) / 100) * rates.USD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/translation/payLater`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });
    } else if (currency_new === "CAD (Canada)") {
      let x =
        estimatedTotal * rates.CAD + ((estimatedTotal * 2.5) / 100) * rates.CAD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/translation/payLater`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });
    } else if (
      currency_new === "TRY (Turkish lira, payment from Turkey only)"
    ) {
      let x =
        estimatedTotal * rates.TRY + ((estimatedTotal * 2.5) / 100) * rates.TRY;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/translation/payLater`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });
    } else if (currency_new === "SGD (Singapore dollar)") {
      let x =
        estimatedTotal * rates.SGD + ((estimatedTotal * 2.5) / 100) * rates.SGD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/translation/payLater`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });
    } else if (currency_new === "HUF (Hungarian forint)") {
      let x =
        estimatedTotal * rates.HUF + ((estimatedTotal * 2.5) / 100) * rates.HUF;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/translation/payLater`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });
    } else if (
      currency_new === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      let x =
        estimatedTotal * rates.NZD + ((estimatedTotal * 2.5) / 100) * rates.NZD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/translation/payLater`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });
    } else if (
      currency_new === "RON (Romanian leu, paying from Romania only)"
    ) {
      let x =
        estimatedTotal * rates.RON + ((estimatedTotal * 2.5) / 100) * rates.RON;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/translation/payLater`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });
    } else {
      let new_total = estimatedTotal.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/translation/payLater`,
        { currency_new, new_total, username, rates, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(
          () =>
            //one more axios request

            swal({
              title: "Please check the summary details",
              text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
              icon: "success",
              buttons: false,
            }),
          setSubmitLoader(false)
        )
        .then(
          Cookies.set("cacTransID", null),
          Cookies.set("translationVisualFormatingPrice", 0),
          Cookies.set("totalPriceForTranslation", 0),
          Cookies.set("totalPriceForQualityCheck", 0),
          Cookies.set("totalPriceForCertificate", 0)
        )
        .then(function () {
          window.location = "/cash-client/dashboard";
        });
    }

    // try {
    //   let EUR = (
    //     estimatedTotal * rates.EUR +
    //     ((estimatedTotal * 2.5) / 100) * rates.EUR
    //   ).toFixed(2);
    //   let AUD = (
    //     estimatedTotal * rates.AUD +
    //     ((estimatedTotal * 2.5) / 100) * rates.AUD
    //   ).toFixed(2);
    //   let USD = (
    //     estimatedTotal * rates.USD +
    //     ((estimatedTotal * 2.5) / 100) * rates.USD
    //   ).toFixed(2);
    //   let CAD = (
    //     estimatedTotal * rates.CAD +
    //     ((estimatedTotal * 2.5) / 100) * rates.CAD
    //   ).toFixed(2);
    //   let TRY = (
    //     estimatedTotal * rates.TRY +
    //     ((estimatedTotal * 2.5) / 100) * rates.TRY
    //   ).toFixed(2);
    //   let SGD = (
    //     estimatedTotal * rates.SGD +
    //     ((estimatedTotal * 2.5) / 100) * rates.SGD
    //   ).toFixed(2);
    //   let HUF = (
    //     estimatedTotal * rates.HUF +
    //     ((estimatedTotal * 2.5) / 100) * rates.HUF
    //   ).toFixed(2);
    //   let NZD = (
    //     estimatedTotal * rates.NZD +
    //     ((estimatedTotal * 2.5) / 100) * rates.NZD
    //   ).toFixed(2);
    //   let RON = (
    //     estimatedTotal * rates.RON +
    //     ((estimatedTotal * 2.5) / 100) * rates.RON
    //   ).toFixed(2);

    //   await Axios.post(
    //     `${process.env.REACT_APP_BACKEND_API}/CAC/sendEmail-AUSDAccount`,
    //     {
    //       currency_new,
    //       estimatedTotal,
    //       username,
    //       rates,
    //       id,
    //       EUR,
    //       AUD,
    //       USD,
    //       CAD,
    //       TRY,
    //       SGD,
    //       HUF,
    //       NZD,
    //       RON,
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )
    //     .then(
    //       () =>
    //         //one more axios request

    //         swal({
    //           title: "Please check the summary details",
    //           text: `You have selected to pay later. You will receive an invoice. Please check your email for acceptance detail of your request and confirmation of assigned Linguist.`,
    //           icon: "success",
    //           buttons: false,
    //         }),
    //       setSubmitLoader(false)
    //     )
    //     .then(
    //       Cookies.set("cacTransID", null),
    //       Cookies.set("translationVisualFormatingPrice", 0),
    //       Cookies.set("totalPriceForTranslation", 0),
    //       Cookies.set("totalPriceForQualityCheck", 0),
    //       Cookies.set("totalPriceForCertificate", 0)
    //     )
    //     .then(function () {
    //       window.location = "/cash-client/dashboard";
    //     });

    //   // const newresponst = Axios.post('')
    //   //  alert(response)
    //   //code
    //   console.clear();
    // } catch (error) {
    //   console.log(error.response);
    // }
  };

  const isAllow2 = !CACstripeI_D || !currency_new_card || 0;

  const isAllow = !currency_new || 0;

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={9} xl={6}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                {/* <Avatar className={classes.avatar}> */}
                <img
                  style={{ width: "70px", height: "65px" }}
                  src={logo}
                  alt=""
                />
                {/* </Avatar> */}
                <Typography component="h1" variant="h5">
                  Summary
                </Typography>
              </div>
              <div
                style={{
                  padding: "4rem 12rem 4rem 12rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  {summary ? (
                    <div style={{ display: "flex" }}>
                      <p>Translation Order / Assignment ID</p>
                      <span style={{ marginLeft: "auto" }}>{summary}</span>
                    </div>
                  ) : null}

                  {Word_count ? (
                    <div style={{ display: "flex" }}>
                      <p>Estimated word count</p>
                      <span style={{ marginLeft: "auto" }}>{Word_count}</span>
                    </div>
                  ) : null}
                  <Divider />
                  <div style={{ display: "flex", margin: "1em 0 1em 0" }}></div>

                  {c ? (
                    <div style={{ display: "flex" }}>
                      <p>Price for translation (TBC**)</p>

                      {New_currency.includes("GBP") && (
                        <span style={{ marginLeft: "auto" }}>£ {c}</span>
                      )}
                      {New_currency.includes("EUR") && (
                        <span style={{ marginLeft: "auto" }}>
                          €{" "}
                          {(
                            c * rates.EUR +
                            ((c * 2.5) / 100) * rates.EUR
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("AUD") && (
                        <span style={{ marginLeft: "auto" }}>
                          AUD ${" "}
                          {(
                            c * rates.AUD +
                            ((c * 2.5) / 100) * rates.AUD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("USD") && (
                        <span style={{ marginLeft: "auto" }}>
                          USD ${" "}
                          {(
                            c * rates.USD +
                            ((c * 2.5) / 100) * rates.USD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("CAD") && (
                        <span style={{ marginLeft: "auto" }}>
                          CAD ${" "}
                          {(
                            c * rates.CAD +
                            ((c * 2.5) / 100) * rates.CAD
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("TRY") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          TRY ₺{" "}
                          {(
                            c * rates.TRY +
                            ((c * 2.5) / 100) * rates.TRY
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("SGD") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          SGD S${" "}
                          {(
                            c * rates.SGD +
                            ((c * 2.5) / 100) * rates.SGD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("HUF") && (
                        <span style={{ marginLeft: "auto" }}>
                          HUF Ft{" "}
                          {(
                            c * rates.HUF +
                            ((c * 2.5) / 100) * rates.HUF
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("NZD") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          NZD ${" "}
                          {(
                            c * rates.NZD +
                            ((c * 2.5) / 100) * rates.NZD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("RON") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          RON lei{" "}
                          {(
                            c * rates.RON +
                            ((c * 2.5) / 100) * rates.RON
                          ).toFixed(2)}
                        </span>
                      )}

                      {/* <span style= {{ marginLeft: 'auto'}}> {summary.new_currency.includes('euro (eu)') ? '€': '£'} {summary.new_currency.includes('euro (eu)') ? (c * 1.11).toFixed(2) : c}</span> */}
                    </div>
                  ) : null}

                  {b ? (
                    <div style={{ display: "flex" }}>
                      <p>Price for certification (TBC**)</p>

                      {New_currency.includes("GBP") && (
                        <span style={{ marginLeft: "auto" }}>£ {b}</span>
                      )}
                      {New_currency.includes("EUR") && (
                        <span style={{ marginLeft: "auto" }}>
                          €{" "}
                          {(
                            b * rates.EUR +
                            ((b * 2.5) / 100) * rates.EUR
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("AUD") && (
                        <span style={{ marginLeft: "auto" }}>
                          AUD ${" "}
                          {(
                            b * rates.AUD +
                            ((b * 2.5) / 100) * rates.AUD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("USD") && (
                        <span style={{ marginLeft: "auto" }}>
                          USD ${" "}
                          {(
                            b * rates.USD +
                            ((b * 2.5) / 100) * rates.USD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("CAD") && (
                        <span style={{ marginLeft: "auto" }}>
                          CAD ${" "}
                          {(
                            b * rates.CAD +
                            ((b * 2.5) / 100) * rates.CAD
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("TRY") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          TRY ₺{" "}
                          {(
                            b * rates.TRY +
                            ((b * 2.5) / 100) * rates.TRY
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("SGD") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          SGD S${" "}
                          {(
                            b * rates.SGD +
                            ((b * 2.5) / 100) * rates.SGD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("HUF") && (
                        <span style={{ marginLeft: "auto" }}>
                          HUF Ft{" "}
                          {(
                            b * rates.HUF +
                            ((b * 2.5) / 100) * rates.HUF
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("NZD") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          NZD ${" "}
                          {(
                            b * rates.NZD +
                            ((b * 2.5) / 100) * rates.NZD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("RON") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          RON lei{" "}
                          {(
                            b * rates.RON +
                            ((b * 2.5) / 100) * rates.RON
                          ).toFixed(2)}
                        </span>
                      )}
                    </div>
                  ) : null}

                  {d ? (
                    <div style={{ display: "flex" }}>
                      <p>Price for quality check (TBC**)</p>

                      {New_currency.includes("GBP") && (
                        <span style={{ marginLeft: "auto" }}>£ {d}</span>
                      )}
                      {New_currency.includes("EUR") && (
                        <span style={{ marginLeft: "auto" }}>
                          €{" "}
                          {(
                            d * rates.EUR +
                            ((d * 2.5) / 100) * rates.EUR
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("AUD") && (
                        <span style={{ marginLeft: "auto" }}>
                          AUD ${" "}
                          {(
                            d * rates.AUD +
                            ((d * 2.5) / 100) * rates.AUD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("USD") && (
                        <span style={{ marginLeft: "auto" }}>
                          USD ${" "}
                          {(
                            d * rates.USD +
                            ((d * 2.5) / 100) * rates.USD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("CAD") && (
                        <span style={{ marginLeft: "auto" }}>
                          CAD ${" "}
                          {(
                            d * rates.CAD +
                            ((d * 2.5) / 100) * rates.CAD
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("TRY") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          TRY ₺{" "}
                          {(
                            d * rates.TRY +
                            ((d * 2.5) / 100) * rates.TRY
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("SGD") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          SGD S${" "}
                          {(
                            d * rates.SGD +
                            ((d * 2.5) / 100) * rates.SGD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("HUF") && (
                        <span style={{ marginLeft: "auto" }}>
                          HUF Ft{" "}
                          {(
                            d * rates.HUF +
                            ((d * 2.5) / 100) * rates.HUF
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("NZD") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          NZD ${" "}
                          {(
                            d * rates.NZD +
                            ((d * 2.5) / 100) * rates.NZD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("RON") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          RON lei{" "}
                          {(
                            d * rates.RON +
                            ((d * 2.5) / 100) * rates.RON
                          ).toFixed(2)}
                        </span>
                      )}
                    </div>
                  ) : null}

                  {a ? (
                    <div style={{ display: "flex" }}>
                      <p>Translation visual formatting (TBC**)</p>

                      {New_currency.includes("GBP") && (
                        <span style={{ marginLeft: "auto" }}>£ {a}</span>
                      )}
                      {New_currency.includes("EUR") && (
                        <span style={{ marginLeft: "auto" }}>
                          €{" "}
                          {(
                            a * rates.EUR +
                            ((c * 2.5) / 100) * rates.EUR
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("AUD") && (
                        <span style={{ marginLeft: "auto" }}>
                          AUD ${" "}
                          {(
                            a * rates.AUD +
                            ((a * 2.5) / 100) * rates.AUD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("USD") && (
                        <span style={{ marginLeft: "auto" }}>
                          USD ${" "}
                          {(
                            a * rates.USD +
                            ((a * 2.5) / 100) * rates.USD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("CAD") && (
                        <span style={{ marginLeft: "auto" }}>
                          CAD ${" "}
                          {(
                            a * rates.CAD +
                            ((a * 2.5) / 100) * rates.CAD
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("TRY") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          TRY ₺{" "}
                          {(
                            a * rates.TRY +
                            ((a * 2.5) / 100) * rates.TRY
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("SGD") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          SGD S${" "}
                          {(
                            a * rates.SGD +
                            ((a * 2.5) / 100) * rates.SGD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("HUF") && (
                        <span style={{ marginLeft: "auto" }}>
                          HUF Ft{" "}
                          {(
                            a * rates.HUF +
                            ((a * 2.5) / 100) * rates.HUF
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("NZD") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          NZD ${" "}
                          {(
                            a * rates.NZD +
                            ((a * 2.5) / 100) * rates.NZD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("RON") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          RON lei{" "}
                          {(
                            a * rates.RON +
                            ((a * 2.5) / 100) * rates.RON
                          ).toFixed(2)}
                        </span>
                      )}
                    </div>
                  ) : null}

                  <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                    <p>Price for security clearances level (TBC**)</p>
                    {New_currency.includes("GBP") && (
                      <span style={{ marginLeft: "auto" }}>£ 00.00</span>
                    )}
                  </div>

                  {estimatedTotal ? (
                    <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                      <p>Estimated total</p>

                      {New_currency.includes("GBP") && (
                        <span style={{ marginLeft: "auto" }}>
                          £ {estimatedTotal.toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("EUR") && (
                        <span style={{ marginLeft: "auto" }}>
                          €{" "}
                          {(
                            estimatedTotal * rates.EUR +
                            ((estimatedTotal * 2.5) / 100) * rates.EUR
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("AUD") && (
                        <span style={{ marginLeft: "auto" }}>
                          AUD ${" "}
                          {(
                            estimatedTotal * rates.AUD +
                            ((estimatedTotal * 2.5) / 100) * rates.AUD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("USD") && (
                        <span style={{ marginLeft: "auto" }}>
                          USD ${" "}
                          {(
                            estimatedTotal * rates.USD +
                            ((estimatedTotal * 2.5) / 100) * rates.USD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("CAD") && (
                        <span style={{ marginLeft: "auto" }}>
                          CAD ${" "}
                          {(
                            estimatedTotal * rates.CAD +
                            ((estimatedTotal * 2.5) / 100) * rates.CAD
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("TRY") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          TRY ₺{" "}
                          {(
                            estimatedTotal * rates.TRY +
                            ((estimatedTotal * 2.5) / 100) * rates.TRY
                          ).toFixed(2)}
                        </span>
                      )}

                      {New_currency.includes("SGD") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          SGD S${" "}
                          {(
                            estimatedTotal * rates.SGD +
                            ((estimatedTotal * 2.5) / 100) * rates.SGD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("HUF") && (
                        <span style={{ marginLeft: "auto" }}>
                          HUF Ft{" "}
                          {(
                            estimatedTotal * rates.HUF +
                            ((estimatedTotal * 2.5) / 100) * rates.HUF
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("NZD") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          NZD ${" "}
                          {(
                            estimatedTotal * rates.NZD +
                            ((estimatedTotal * 2.5) / 100) * rates.NZD
                          ).toFixed(2)}
                        </span>
                      )}
                      {New_currency.includes("RON") && (
                        <span style={{ marginLeft: "auto" }}>
                          {" "}
                          RON lei{" "}
                          {(
                            estimatedTotal * rates.RON +
                            ((estimatedTotal * 2.5) / 100) * rates.RON
                          ).toFixed(2)}
                        </span>
                      )}

                      {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                    </div>
                  ) : null}
                </div>
              </div>
              <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                <span style={{ marginLeft: "auto" }}>** To be confirmed</span>
              </div>

              {payLaterAccess ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Pay Later / UK Legal Aid Job
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomSelect
                      onChange={handleChange}
                      data={CURRENCY_x}
                      width="73.3%"
                      label="Preferred currency"
                      name="currency_new"
                      value={currency_new}
                    />
                  </AccordionDetails>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      {estimatedTotal ? (
                        <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                          <p>Estimated total</p>
                          {currency_new.includes("GBP") && (
                            <span style={{ marginLeft: "auto" }}>
                              £ {estimatedTotal.toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("EUR (Euro countries)") && (
                            <span style={{ marginLeft: "auto" }}>
                              €{" "}
                              {(
                                estimatedTotal * rates.EUR +
                                ((estimatedTotal * 2.5) / 100) * rates.EUR
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("AUD (Australia)") && (
                            <span style={{ marginLeft: "auto" }}>
                              AUD ${" "}
                              {(
                                estimatedTotal * rates.AUD +
                                ((estimatedTotal * 2.5) / 100) * rates.AUD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "USD (USA, paying from USA only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              USD ${" "}
                              {(
                                estimatedTotal * rates.USD +
                                ((estimatedTotal * 2.5) / 100) * rates.USD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("CAD (Canada)") && (
                            <span style={{ marginLeft: "auto" }}>
                              CAD ${" "}
                              {(
                                estimatedTotal * rates.CAD +
                                ((estimatedTotal * 2.5) / 100) * rates.CAD
                              ).toFixed(2)}
                            </span>
                          )}

                          {currency_new.includes(
                            "TRY (Turkish lira, payment from Turkey only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              TRY ₺{" "}
                              {(
                                estimatedTotal * rates.TRY +
                                ((estimatedTotal * 2.5) / 100) * rates.TRY
                              ).toFixed(2)}
                            </span>
                          )}

                          {currency_new.includes("SGD (Singapore dollar)") && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              SGD S${" "}
                              {(
                                estimatedTotal * rates.SGD +
                                ((estimatedTotal * 2.5) / 100) * rates.SGD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("HUF (Hungarian forint)") && (
                            <span style={{ marginLeft: "auto" }}>
                              HUF Ft{" "}
                              {(
                                estimatedTotal * rates.HUF +
                                ((estimatedTotal * 2.5) / 100) * rates.HUF
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "NZD (New Zealand dollar, paying from New Zealand only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              NZD ${" "}
                              {(
                                estimatedTotal * rates.NZD +
                                ((estimatedTotal * 2.5) / 100) * rates.NZD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "RON (Romanian leu, paying from Romania only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              RON lei{" "}
                              {(
                                estimatedTotal * rates.RON +
                                ((estimatedTotal * 2.5) / 100) * rates.RON
                              ).toFixed(2)}
                            </span>
                          )}

                          {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                        </div>
                      ) : null}
                    </div>
                  </AccordionDetails>

                  <AccordionDetails>
                    <Button
                      loading={submitLoader}
                      className={`${classesb.button}`}
                      onClick={handlePayLater}
                      disabled={isAllow}
                      style={{
                        width: "30rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      variant="contained"
                      color="purple"
                    >
                      Pay Later
                    </Button>
                  </AccordionDetails>
                </Accordion>
              ) : null}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Marketplace speedy payment
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomSelect
                    onChange={handleChangecard}
                    data={CURRENCY_x}
                    width="73.3%"
                    label="Preferred currency"
                    name="currency_new_card"
                    value={currency_new_card}
                  />
                </AccordionDetails>
                <AccordionDetails>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {estimatedTotal ? (
                      <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                        <p>Estimated total</p>
                        {currency_new_card.includes("GBP") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {estimatedTotal.toFixed(2)}
                          </span>
                        )}
                        {currency_new_card.includes("EUR (Euro countries)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              estimatedTotal * rates.EUR +
                              ((estimatedTotal * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new_card.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              estimatedTotal * rates.AUD +
                              ((estimatedTotal * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new_card.includes(
                          "USD (USA, paying from USA only)"
                        ) && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              estimatedTotal * rates.USD +
                              ((estimatedTotal * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new_card.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              estimatedTotal * rates.CAD +
                              ((estimatedTotal * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_new_card.includes(
                          "TRY (Turkish lira, payment from Turkey only)"
                        ) && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              estimatedTotal * rates.TRY +
                              ((estimatedTotal * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_new_card.includes(
                          "SGD (Singapore dollar)"
                        ) && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              estimatedTotal * rates.SGD +
                              ((estimatedTotal * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new_card.includes(
                          "HUF (Hungarian forint)"
                        ) && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              estimatedTotal * rates.HUF +
                              ((estimatedTotal * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new_card.includes(
                          "NZD (New Zealand dollar, paying from New Zealand only)"
                        ) && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              estimatedTotal * rates.NZD +
                              ((estimatedTotal * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new_card.includes(
                          "RON (Romanian leu, paying from Romania only)"
                        ) && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              estimatedTotal * rates.RON +
                              ((estimatedTotal * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}

                        {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                      </div>
                    ) : null}
                  </div>
                </AccordionDetails>
                <AccordionDetails>
                  {/* <Button onClick={handleClickSTRIPE} disabled= {isAllow2}  className={`${classesb.button}`} style= {{width: '30rem', marginLeft: 'auto', marginRight: 'auto'}} variant= 'contained' color= 'primary'>Pay and confirm</Button> */}
                  <Button
                    onClick={() => handlePayByCard()}
                    disabled={isAllow2}
                    className={`${classesb.button}`}
                    style={{
                      width: "30rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Pay and confirm
                  </Button>

                  {/* //stripe */}
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Marketplace bank transfer
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomSelect
                    onChange={handleChange}
                    data={CURRENCY_x}
                    width="73.3%"
                    label="Preferred currency"
                    name="currency_new"
                    value={currency_new}
                  />
                </AccordionDetails>
                <AccordionDetails>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {estimatedTotal ? (
                      <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                        <p>Estimated total</p>
                        {currency_new.includes("GBP") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {estimatedTotal.toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes("EUR (Euro countries)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              estimatedTotal * rates.EUR +
                              ((estimatedTotal * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              estimatedTotal * rates.AUD +
                              ((estimatedTotal * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes(
                          "USD (USA, paying from USA only)"
                        ) && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              estimatedTotal * rates.USD +
                              ((estimatedTotal * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              estimatedTotal * rates.CAD +
                              ((estimatedTotal * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_new.includes(
                          "TRY (Turkish lira, payment from Turkey only)"
                        ) && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              estimatedTotal * rates.TRY +
                              ((estimatedTotal * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_new.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              estimatedTotal * rates.SGD +
                              ((estimatedTotal * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              estimatedTotal * rates.HUF +
                              ((estimatedTotal * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes(
                          "NZD (New Zealand dollar, paying from New Zealand only)"
                        ) && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              estimatedTotal * rates.NZD +
                              ((estimatedTotal * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_new.includes(
                          "RON (Romanian leu, paying from Romania only)"
                        ) && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              estimatedTotal * rates.RON +
                              ((estimatedTotal * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}

                        {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                      </div>
                    ) : null}
                  </div>
                </AccordionDetails>

                <AccordionDetails>
                  <Button
                    loading={submitLoader}
                    className={`${classesb.button}`}
                    onClick={handleNext}
                    disabled={isAllow}
                    style={{
                      width: "30rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    variant="contained"
                    color="purple"
                  >
                    Get bank details
                  </Button>
                </AccordionDetails>
              </Accordion>

              {/* {selectedForm === 2 && <FormThree />} */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default BIsummary;
