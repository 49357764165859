import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";

// Material Core Components
import Typography from "@material-ui/core/Typography";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Axios from "utils/axios";
//Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";

import PageOne from "./PageOne";
import PageTwo from "./PageTwo";

const PublicJobAdvert = ({id}) => {
  const { count } = useSelector((state) => state.advertReducer);

  const classes = useEmployeeStyles();
  const selectedForm = count;

  const [user, userDetails] = useState([]);

  const UserDetailsID = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/only-view-LinID/SppoID/AdminID/CacID/BacID/${id}`
      );
      userDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };


  useEffect(() => {
    UserDetailsID();
  }, []);

  return (
    <div className={classes.root}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  Post A Job Advert
                </Typography>
                <Typography variant="subtitle2">Reference ID: {user?.id ? user?.id : "LC Web"}</Typography>
              </div>
            
              {selectedForm === 1 && <PageOne userID={user?.id}/>}
              {selectedForm === 2 && <PageTwo userID={user?.id} />}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default PublicJobAdvert;
