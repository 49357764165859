import React, { Fragment, useState } from "react";

// Material Core Components\
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconImg from "components/Sidebar/IconImg";

// Material Icons
import EventIcon from "@material-ui/icons/Event";
import Cicon from "assets/icons/calendar.png";

// Custom Components
import CustomCalendar from "components/Common/CustomCalendar";

// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";
import useDropdownStyles from "assets/jss/components/dropDownStyles";

const NavCalendar = () => {
  const classes = useNavbarStyles();
  const classesTwo = useDropdownStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <Fragment>
      {/* <IconButton aria-label="Person" color="primary" onClick={handleClick}>
        <EventIcon className={classes.icons} />
      </IconButton> */}

      <IconButton aria-label="Person" color="primary" onClick={handleClick}>
        <div className={classes.imgDiv}>
          <IconImg className={classes.icons} icon={Cicon} />
        </div>
      </IconButton>
      <div>
        <Popper
          open={Boolean(open)}
          anchorEl={anchorEl}
          placement="bottom-end"
          className={classesTwo.toolTip}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <CustomCalendar />
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </Fragment>
  );
};

export default NavCalendar;
