import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "axios";
import swal from "sweetalert";
import CustomInputSingle from "components/Common/CustomInputSingle";
import { useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

const Details = () => {
  const { id } = useParams();

  const token = Cookies.get("token");

  const [details, setDetails] = useState({
    content: "",
    subject: "",
    loader: false,
  });

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/view-email-settings/${id}`
      );
      setDetails({
        ...details,
        types_of_email: response?.data.types_of_email,
        subject: response?.data.subject,
        content: response?.data.content,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/update-email-settings/${id}`,
        {
          subject: details.subject || "-",
          content: details.content || "-",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: `${response?.data?.message}`,
        buttons: "Ok",
        icon: "success",
      });
      setDetails({ ...details, loader: false });
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  return (
    <div>
      <br />
      <b>{details.types_of_email}</b>
      <br />
      <CustomInputSingle
        onChange={handleChange}
        label="Subject"
        name="subject"
        value={details.subject}
        width="100%"
        placeholder="Enter Title..."
        required
      />

      <br />
      <Header as="h4">Content</Header>
      <TextField
        label="Type here..."
        name="content"
        onChange={handleChange}
        value={details.content}
        multiline
        rows={50}
        style={{ width: "100%" }}
      />

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          loading={details.loader}
          onClick={handleSubmit}
          disabled={!details.subject || !details.content}
          color="purple"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Details;
