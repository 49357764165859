import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Custom styles
import useFormStyles from "assets/jss/components/auth/formStyle";

//Custom imports
import CustomSelect from "components/Common/CustomSelect";
import CustomInputSingle from "components/Common/CustomInputSingle";

import { COUNTRY_DATA, EMAIL_REGEX } from "data";
import Axios from "axios";
import Cookies from "js-cookie";
import { Button, Icon } from "@material-ui/core";
import swal from "sweetalert";

const FormOne = () => {
  const classes = useFormStyles();
  const FORM_TYPE = [
    "Inhouse interpreter booking",
    "Inhouse translation order",
    "Both",
  ];
  const { data } = useSelector((state) => state.ibtReducer);
  const { client_name } = data;
  const [inhouseClients, setInhouseClients] = useState([]);
  const [notValid, setNotValid] = useState(false);
  const getInhouseClients = async (e) => {
    const token = Cookies.get("SPPOtoken");
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/list-own-inhouse-client`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInhouseClients(response?.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const CLIENT_NAME = inhouseClients?.map(
    (client) => `${client.id} - ${client.FirstName} ${client.LastName}`
  );

  useEffect(() => {
    getInhouseClients();
  }, []);

  const [state, setState] = useState({
    client_name: "",
    secondary_email: "",
    form_name: "",
  });
  const isAllow =
    !state.client_name ||
    !state.form_name ||
    (state.secondary_email && notValid);

  const FETCH_EMAIL = inhouseClients.filter((client) =>
    state.client_name.includes(client.id)
  )[0]?.email;
  const FETCH_ID = inhouseClients.filter((client) =>
    state.client_name.includes(client.id)
  )[0]?._id;

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleEmailChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleSubmit = async (e) => {
    const token = Cookies.get("SPPOtoken");
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/send-booking-form`,
        { ...state, email: FETCH_EMAIL, clientID: FETCH_ID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `Check your mail!`,
        icon: "success",
        buttons: false,
      });
      setState({ client_name: "", secondary_email: "", form_name: "" });
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomSelect
          onChange={handleChange}
          label="Select Client"
          width="100%"
          data={CLIENT_NAME}
          value={state.client_name}
          name="client_name"
        />

        <CustomInputSingle
          onChange={handleChange}
          label="Email"
          width="100%"
          value={FETCH_EMAIL}
          name="email"
          shrink
        />

        <CustomInputSingle
          onChange={handleEmailChange}
          label="Secondary email"
          width="100%"
          value={state.secondary_email}
          name="secondary_email"
        />
        {state.secondary_email && notValid && (
          <>
            <small style={{ color: "red" }}>enter a valid email address</small>
          </>
        )}
        <CustomSelect
          onChange={handleChange}
          label="Select Form"
          data={FORM_TYPE}
          width="100%"
          name="form_name"
          value={state.form_name}
        />

        <div className={classes.buttonFlex}>
          <Button
            disabled={isAllow}
            style={{ marginTop: 10 }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.button}
            endIcon={<Icon>send</Icon>}
          >
            Send
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormOne;
