import React, { useState, useEffect } from "react";
import { Header } from "semantic-ui-react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import BookingOptions from "components/Linguist/ManagePrivateClient/BookingOption";

import Cookies from "js-cookie";
import Axios from "axios";

const PrivateClient = () => {
  const classes = useDashboardStyles();
  const [privateAccess, setPrivateAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("LINtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setPrivateAccess(response?.data.privateAccess);
  };

  useEffect(() => {
    getLinguistDetails();
  });

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <p className={classes.cardTitleWhite}>Manage Private Clients</p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
            </Card>
            {privateAccess ? (
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <BookingOptions />
                </CardHeader>
              </Card>
            ) : privateAccess === false ? (
              <Header as="h3">
                Access Denied! <br /> Complete Full Registration or Contact HR /
                Admin Team.
              </Header>
            ) : (
              <Header as="h3">Loading...</Header>
            )}
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default PrivateClient;
