import React, {  } from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

import { Header } from "semantic-ui-react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useParams } from "react-router-dom";
// Custom Styles

import JobList from "components/Employee/BookingPanel/JobAdvert/RejectedJobAdd";
import Options from "components/Employee/BookingPanel/JobAdvert/JobOptions";

const AssignmentPage = () => {
  const { data, type } = useParams();
  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <Options />
            </Card>
            <Card>
              <CardBody>
                <Header as="h3">LC Front(Web) rejected job adverts</Header>
                <JobList jobadvert={type === "jobadvert" && data}/>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default AssignmentPage;
