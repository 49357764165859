import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

// Material Core Components
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Material Icons

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomPassword from "components/Common/CustomPassword";
import ForgotPassword from "./ForgotPassword";
import CustomEmail from "components/Common/CustomEmail";
// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import LandinPageLayout from "layout/LandingPageLayout";

// Others
import CustomAvatar from "components/Common/CustomAvatar";
import Axios from "axios";
import swal from "sweetalert";
import history from "utils/history";
import CustomCaptcha from "components/Common/CustomCaptcha";
import { EMAIL_REGEX } from "data";

const Login = () => {
  const dispatch = useDispatch();
  const [notValid, setNotValid] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [value, setValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useEmployeeStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/signin`,
        { ...state }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
      setLoading(false);

      const { user } = response?.data;
      localStorage.setItem("linguistLoggedIn", JSON.stringify(user));
      // localStorage.setItem("user", JSON.stringify(user));
      Cookies.set("LINtoken", response?.data.token);
      // Cookies.set("token", response?.data.token);
      Cookies.set("linID", response?.data.user._id);
      const token = Cookies.get("LINtoken");

      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/post-activity-log`,
        {
          Device: Cookies.get("visitor_device"),
          Location: Cookies.get("visitor_address") || "permission denied",
          Browser: Cookies.get("visitor_browser"),
          IP: Cookies.get("visitor_ip"),
          Time: new Date(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      history.push("linguist/dashboard");
      //localStorage.setItem("user", JSON.stringify(response?.data.linguists));
      // swal({
      //   title: "Welcome",
      //   text: `${response?.data.linguists.FirstName} ${response?.data.linguists.LastName}`,
      //   icon: "success",
      //   buttons: false,
      // });
    } catch (error) {
      if (error?.response?.data?.error) {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      } else {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `Please check your internet connection.`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleCaptcha = async (value) => {
    setValue(value);
  };

  return (
    <LandinPageLayout>
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    Linguist login
                  </Typography>
                </div>
                <form method="post">
                  <CustomEmail
                    onChange={handleEmailChange}
                    label="Email"
                    width="100%"
                    name="email"
                    value={state.email}
                    type="email"
                    placeholder="Your registered primary email "
                  />
                  {notValid && (
                    <small style={{ color: "red" }}>
                      enter valid email address
                    </small>
                  )}
                  <CustomPassword
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    value={state.password}
                    placeholder="Enter your password"
                  />

                  <CustomCaptcha onChange={handleCaptcha} />

                  <div className={classes.buttonDiv}>
                    <Button
                      color="primary"
                      onClick={handleSubmit}
                      disabled={
                        !state.email || !state.password || !value || loading
                      }
                      variant="contained"
                    >
                      {loading ? "Logging..." : "Login"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => history.push("/linguist/registration")}
                    >
                      Register
                    </Button>
                  </div>
                </form>
                <div className={classes.forgotDiv}>
                  <span />
                  <ForgotPassword />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </LandinPageLayout>
  );
};

export default Login;
