import { useHistory } from "react-router-dom";

// Materials Core
// import Button from "@material-ui/core/Button";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "15px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const AssignmentOptions = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        // icon='fork'
        // label={{
        //   basic: true,
        //   color: 'purple',
        //   pointing: 'left',
        //   content: '0'
        // }}
        onClick={() =>
          history.push("/employee/unconfirmed-assignments/cash-client")
        }
      >
        Cash Client
      </Button>
      <Button
        variant="outlined"
        icon="fork"
        label={{
          as: "a",
          basic: true,
          color: "purple",
          pointing: "left",
          content: "0",
        }}
        onClick={() =>
          history.push(
            "/employee/unconfirmed-assignments/billing-account-client"
          )
        }
      >
        Account Client
      </Button>
      <Button
        variant="outlined"
        // icon='fork'
        // label={{
        //   as: 'a',
        //   basic: true,
        //   color: 'purple',
        //   pointing: 'left',
        //   content: '0',
        // }}
        onClick={() =>
          history.push("/employee/unconfirmed-assignments/inhouse-client")
        }
      >
        In-house Client
      </Button>
      <Button
        variant="outlined"
        // icon='fork'
        // label={{
        //   as: 'a',
        //   basic: true,
        //   color: 'purple',
        //   pointing: 'left',
        //   content: '0',
        // }}
        onClick={() =>
          history.push("/employee/unconfirmed-assignments/private-client")
        }
      >
        Private Client
      </Button>
      <Button
        variant="outlined"
        // icon='fork'
        // label={{
        //   as: 'a',
        //   basic: true,
        //   color: 'purple',
        //   pointing: 'left',
        //   content: '0',
        // }}
        // onClick={() => history.push("/employee/all-inactive-assignments")}
      >
        Incomplete Assignments
      </Button>
    </div>
  );
};

export default AssignmentOptions;
