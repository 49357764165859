import React from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import { Button, Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomAvatar from "components/Common/CustomAvatar";
import Axios from "utils/axios";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import history from "utils/history";
import swal from "sweetalert";

const SubscribeHere = () => {
  const classes = useLandingpageStyles();
  const [incDetails, setINCDetails] = React.useState();
  const [data, setData] = React.useState({
    username: "",
    linID: "",
  });

  const { username, linID } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const FindLinguist = async () => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/find-verified-linguist-for-idcard/username`,
        {
          username: username,
          linID: linID,
        }
      );
      setINCDetails(response?.data);
      setData({ ...data, username: "", linID: "" });
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error.response?.data}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  return (
    <LandingPageLayout>
      <GridContainer style={{ justifyContent: "center", margin: "2rem 0" }}>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ textAlign: "center" }}
                >
                  Verify LC Linguist ID Card
                </Typography>
              </div>
              {incDetails ? null : (
                <>
                  <CustomInputSingle
                    label="LC Lingusit's Profile ID"
                    width="100%"
                    placeholder="Enter Linguist Profile ID Here (e.g. LIN/PRO100...)"
                    name="linID"
                    onChange={handleChange}
                    value={data.linID}
                    required
                  />

                  <CustomInputSingle
                    label="LC Lingusit's ID Card Security Code"
                    width="100%"
                    placeholder="Enter ID Card Security Code (e.g. EKBLXUJe_)"
                    name="username"
                    onChange={handleChange}
                    value={data.username}
                    required
                  />
                </>
              )}

              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                {!incDetails ? (
                  <Button
                    className={`${classes.button}`}
                    color="primary"
                    variant="contained"
                    onClick={FindLinguist}
                    disabled={!data.username || !data.linID}
                  >
                    Verify Linguist
                  </Button>
                ) : (
                  <>
                    <p>Profile ID: {incDetails?.id}</p>
                    <p>Name: {incDetails?.FirstName}</p>

                    <Button
                      className={`${classes.button}`}
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        history.push(
                          `/linguist/public/profile/${
                            incDetails?.source_language
                              ?.toString()
                              .split(" ")[0]
                          }/${
                            incDetails?.target_language
                              ?.toString()
                              .split(" ")[0]
                          }/${incDetails?._id}`
                        )
                      }
                    >
                      View Full Profile
                    </Button>
                    <br />
                    <br />
                    <p>
                      If you cannot access the full profile of the Linguist
                      please <a href={`landing-page/contact`}>contact</a> LC
                      Admin Team for verification*
                    </p>
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

// EBBlxUJe_
export default SubscribeHere;
