import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    ["@media(max-width:1024px)"]: {
      width: "-webkit-fill-available",
    },

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "purple",
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

export default function SimpleAccordion() {
  const classes = useStyles();
  const { id } = useParams();
  const matches = useMediaQuery("(max-width:1024px)");

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Cash Client</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/cashclient-service-agreement/post-agreement`}
              >
                Test Link
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Account Client</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/sent-bac-user-requests`}
              >
                Sent BAC User Requests
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Linguist</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/view-linguist-all-passport-document`}
              >
                Passport Document
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/view-linguist-all-public-professional-membership`}
              >
                Professional Membership
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/view-linguist-all-public-security-clearance`}
              >
                Security Clearance
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/view-linguist-all-public-educational-qualification`}
              >
                Educational Qualification
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>SPPO</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/view-sppo-all-public-corporate-membership`}
              >
                Corporate Membership
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/view-sppo-all-public-certifications`}
              >
                Certification/s
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/sent-sppo-user-requests`}
              >
                Sent SPPO User Requests
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Employee</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a style={{ textAlign: "center" }} href={`/`}>
                Test Link
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Manager</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/registration-requests-CAC`}
              >
                CAC Registration Requests
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/registration-requests-BAC`}
              >
                BAC Registration Request
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/registration-requests-ADM`}
              >
                Admin Registration Request
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/registration-requests-EMP`}
              >
                Employee Registration Request
              </a>
            </GridItem>
          </GridContainer>

          <GridContainer
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/registration-requests-Lead`}
              >
                Lead Registration Request
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/assignment-document-requests`}
              >
                Assignment Document Requests
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/all-documents-of-assignments`}
              >
                All Documents Of Assignments
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/all-inactive-jobadvert`}
              >
                All Inactive Job Adverts
              </a>
            </GridItem>
          </GridContainer>
        
          <GridContainer
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/list-all-close-and-timesheet`}
              >
                Close and Timesheet
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/list-all-project-report`}
              >
                Project Report
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
