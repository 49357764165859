import React, { useState, useEffect } from "react";
// Material Core Components
import Typography from "@material-ui/core/Typography";
import defaultAxios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormOne from "./FormOne";
import {
  Header,
} from "semantic-ui-react";
// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";

const RegistrationForm = () => {
  const classes = useEmployeeStyles();


  const [jobPrice, setJobPrice] = useState(null);

  const getJobPrice = async (e) => {
    const response = await defaultAxios.get(
      "/api/manager-admin/view-job-advert-price"
    );

    setJobPrice(response?.data);
  };
  useEffect(() => {
    getJobPrice();
  }, []);

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container} xs={12} sm={12} md={12} lg={12} xl={12}>
        <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">Percentage Card (Post Percentage)</Typography>
              </div>
              <FormOne />
            </CardBody>
          </Card>
        </GridItem>
       </GridContainer>
    </div>
  );
};

export default RegistrationForm;
