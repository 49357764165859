import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "components/Common/CustomSelect";
// Material Core Components
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import LoadingScreen from "react-loading-screen";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import logo from "assets/images/logo.png";
import { CURRENCY_x } from "data";
// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Axios from "axios";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
} from "@material-ui/core";

import Cookies from "js-cookie";

import { changeBaInput } from "redux/actions/bookingAction";
import swal from "sweetalert";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";

import { loadStripe } from "@stripe/stripe-js";
import history from "utils/history";

const OTinhousesummary = () => {
  //STRIPE
  const stripePromise = loadStripe(
    "pk_test_51I3OlCEdJeNZngVd9ROYTlsOSf8HVo8Q1MvlJeoy7Fryh5KfPtuO3LdLoggzv8QwTQgOvSrA0e4uNxbb0uDjbu1d00yj7pZaDs"
  );
  const classes = useEmployeeStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classesb = useLandingpageStyles();
  const [rates, setRates] = useState("");
  const [payLaterAccess, setPayLaterAccess] = useState(false);
  const IncID = Cookies.get("clientID");

  const [grandTotal, setGrandTotal] = useState("");

  const currency_u = Cookies.get("currency");
  //id
  const id = Cookies.get("id");
  const wordCount = Cookies.get("wordCount");
  const inhouseClientID = Cookies.get("client_ID");
  //tatal_Food_Overnight
  const totalPriceForTranslation = Cookies.get("totalPriceForTranslation") || 0;

  //total_Overnight_charges
  const totalPriceForCertificate = Cookies.get("totalPriceForCertificate") || 0;

  //total_PriceForInterpriting
  const totalPriceForQualityCheck =
    Cookies.get("totalPriceForQualityCheck") || 0;

  //total_TravelTotal_Wages
  const translationVisualFormatingPrice =
    Cookies.get("translationVisualFormatingPrice") || 0;

  const { data } = useSelector((state) => state.bookingReducer);
  const { currency_new, currency_new_card } = data;

  // alert(currency_new.props.value)

  const handleChange = (e) => {
    dispatch(
      changeBaInput({
        name: "currency_new",
        value: e.target.value,
      })
    );
  };
  const handleChangecard = (e) => {
    const { value } = e.target;
    dispatch(
      changeBaInput({
        name: "currency_new_card",
        value: e.target.value,
      })
    );
    if (value === "EUR (Euro countries)") {
      setGrandTotal(
        estimatedTotal * rates.EUR +
          (((estimatedTotal * 2.5) / 100) * rates.EUR).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.EUR +
          ((estimatedTotal * 2.5) / 100) * rates.EUR
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "EUR €");
    } else if (value === "AUD (Australia)") {
      setGrandTotal(
        estimatedTotal * rates.AUD +
          (((estimatedTotal * 2.5) / 100) * rates.AUD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.AUD +
          ((estimatedTotal * 2.5) / 100) * rates.AUD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "AUD $");
    } else if (value === "USD (USA, paying from USA only)") {
      setGrandTotal(
        estimatedTotal * rates.USD +
          (((estimatedTotal * 2.5) / 100) * rates.USD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.USD +
          ((estimatedTotal * 2.5) / 100) * rates.USD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "USD $");
    } else if (value === "CAD (Canada)") {
      setGrandTotal(
        estimatedTotal * rates.CAD +
          (((estimatedTotal * 2.5) / 100) * rates.CAD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.CAD +
          ((estimatedTotal * 2.5) / 100) * rates.CAD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "CAD $");
    } else if (value === "TRY (Turkish lira, payment from Turkey only)") {
      setGrandTotal(
        estimatedTotal * rates.TRY +
          (((estimatedTotal * 2.5) / 100) * rates.TRY).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.TRY +
          ((estimatedTotal * 2.5) / 100) * rates.TRY
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "TRY  ₺");
    } else if (value === "SGD (Singapore dollar)") {
      setGrandTotal(
        estimatedTotal * rates.SGD +
          (((estimatedTotal * 2.5) / 100) * rates.SGD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.SGD +
          ((estimatedTotal * 2.5) / 100) * rates.SGD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "SGD $");
    } else if (value === "HUF (Hungarian forint)") {
      setGrandTotal(
        estimatedTotal * rates.HUF +
          (((estimatedTotal * 2.5) / 100) * rates.HUF).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.HUF +
          ((estimatedTotal * 2.5) / 100) * rates.HUF
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "HUF Ft");
    } else if (
      value === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      setGrandTotal(
        estimatedTotal * rates.NZD +
          (((estimatedTotal * 2.5) / 100) * rates.NZD).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.NZD +
          ((estimatedTotal * 2.5) / 100) * rates.NZD
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "NZD $");
    } else if (value === "RON (Romanian leu, paying from Romania only)") {
      setGrandTotal(
        estimatedTotal * rates.RON +
          (((estimatedTotal * 2.5) / 100) * rates.RON).toFixed(2)
      );
      Cookies.set(
        "grandTotal",
        (
          estimatedTotal * rates.RON +
          ((estimatedTotal * 2.5) / 100) * rates.RON
        ).toFixed(2)
      );
      Cookies.set("stripeCurrency", "RON lei");
    } else {
      setGrandTotal(estimatedTotal.toFixed(2));
      Cookies.set("grandTotal", estimatedTotal.toFixed(2));
      Cookies.set("stripeCurrency", "GBP £");
    }
  };
  // let new_date = currency_new.props.value || 0
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
    ).then((res) => setRates(res?.data?.rates));

    Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/find-INC/${IncID}`
    ).then((res) => setPayLaterAccess(res?.data?.payLaterAccess));
  }, []);

  let c = parseFloat(totalPriceForTranslation) || 0;

  let b = parseFloat(totalPriceForCertificate) || 0;

  let a = parseFloat(totalPriceForQualityCheck) || 0;

  let d = parseFloat(translationVisualFormatingPrice) || 0;

  const estimatedTotal = a + b + c + d;

  const handleNext = async (e) => {
    // alert("doing")
    // setLoading(true)
    if (currency_new === "EUR (Euro countries)") {
      let x =
        estimatedTotal * rates.EUR + ((estimatedTotal * 2.5) / 100) * rates.EUR;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/sendmail`,
        { currency_new, new_total, rates, id }
      );
    } else if (currency_new === "AUD (Australia)") {
      let x =
        estimatedTotal * rates.AUD + ((estimatedTotal * 2.5) / 100) * rates.AUD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/sendmail`,
        { currency_new, new_total, rates, id }
      );
    } else if (currency_new === "USD (USA, paying from USA only)") {
      let x =
        estimatedTotal * rates.USD + ((estimatedTotal * 2.5) / 100) * rates.USD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/sendmail`,
        { currency_new, new_total, rates, id }
      );
    } else if (currency_new === "CAD (Canada)") {
      let x =
        estimatedTotal * rates.CAD + ((estimatedTotal * 2.5) / 100) * rates.CAD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/sendmail`,
        { currency_new, new_total, rates, id }
      );
    } else if (
      currency_new === "TRY (Turkish lira, payment from Turkey only)"
    ) {
      let x =
        estimatedTotal * rates.TRY + ((estimatedTotal * 2.5) / 100) * rates.TRY;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/sendmail`,
        { currency_new, new_total, rates, id }
      );
    } else if (currency_new === "SGD (Singapore dollar)") {
      let x =
        estimatedTotal * rates.SGD + ((estimatedTotal * 2.5) / 100) * rates.SGD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/sendmail`,
        { currency_new, new_total, rates, id }
      );
    } else if (currency_new === "HUF (Hungarian forint)") {
      let x =
        estimatedTotal * rates.HUF + ((estimatedTotal * 2.5) / 100) * rates.HUF;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/sendmail`,
        { currency_new, new_total, rates, id }
      );
    } else if (
      currency_new === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      let x =
        estimatedTotal * rates.NZD + ((estimatedTotal * 2.5) / 100) * rates.NZD;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/sendmail`,
        { currency_new, new_total, rates, id }
      );
    } else if (
      currency_new === "RON (Romanian leu, paying from Romania only)"
    ) {
      let x =
        estimatedTotal * rates.RON + ((estimatedTotal * 2.5) / 100) * rates.RON;
      let new_total = x.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/sendmail`,
        { currency_new, new_total, rates, id }
      );
    } else {
      let new_total = estimatedTotal.toFixed(2);
      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/sendmail`,
        { currency_new, new_total, rates, id }
      );
    }

    try {
      let bookingid = id;
      let EUR = (
        estimatedTotal * rates.EUR +
        ((estimatedTotal * 2.5) / 100) * rates.EUR
      ).toFixed(2);
      let AUD = (
        estimatedTotal * rates.AUD +
        ((estimatedTotal * 2.5) / 100) * rates.AUD
      ).toFixed(2);
      let USD = (
        estimatedTotal * rates.USD +
        ((estimatedTotal * 2.5) / 100) * rates.USD
      ).toFixed(2);
      let CAD = (
        estimatedTotal * rates.CAD +
        ((estimatedTotal * 2.5) / 100) * rates.CAD
      ).toFixed(2);
      let TRY = (
        estimatedTotal * rates.TRY +
        ((estimatedTotal * 2.5) / 100) * rates.TRY
      ).toFixed(2);
      let SGD = (
        estimatedTotal * rates.SGD +
        ((estimatedTotal * 2.5) / 100) * rates.SGD
      ).toFixed(2);
      let HUF = (
        estimatedTotal * rates.HUF +
        ((estimatedTotal * 2.5) / 100) * rates.HUF
      ).toFixed(2);
      let NZD = (
        estimatedTotal * rates.NZD +
        ((estimatedTotal * 2.5) / 100) * rates.NZD
      ).toFixed(2);
      let RON = (
        estimatedTotal * rates.RON +
        ((estimatedTotal * 2.5) / 100) * rates.RON
      ).toFixed(2);

      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse-client/sendEmail-AUSDAccount`,
        {
          currency_new,
          estimatedTotal,
          rates,
          bookingid,
          inhouseClientID,
          EUR,
          AUD,
          USD,
          CAD,
          TRY,
          SGD,
          HUF,
          NZD,
          RON,
        }
      );
      //   setLoading(false)
      swal("Success!! Please check your registered email for more details..", {
        icon: "success",
        buttons: "OK!!",
      }).then((res) => {
        Cookies.set("total_Overnight_charges", 0);
        Cookies.set("total_PriceForInterpriting", 0);
        Cookies.set("total_TravelTotal_Wages", 0);
        Cookies.set("tatal_Food_Overnight", 0);
        window.location = "/";
      });
    } catch (error) {
      console.log(error.response);
    }
  };
  const handlePayLaterNext = async (e) => {
    // alert("doing")
    // setLoading(true)
    if (currency_new === "EUR (Euro countries)") {
      let x =
        estimatedTotal * rates.EUR + ((estimatedTotal * 2.5) / 100) * rates.EUR;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/payLater`,
        { currency_new, new_total, id }
      );
    } else if (currency_new === "AUD (Australia)") {
      let x =
        estimatedTotal * rates.AUD + ((estimatedTotal * 2.5) / 100) * rates.AUD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/payLater`,
        { currency_new, new_total, id }
      );
    } else if (currency_new === "USD (USA, paying from USA only)") {
      let x =
        estimatedTotal * rates.USD + ((estimatedTotal * 2.5) / 100) * rates.USD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/payLater`,
        { currency_new, new_total, id }
      );
    } else if (currency_new === "CAD (Canada)") {
      let x =
        estimatedTotal * rates.CAD + ((estimatedTotal * 2.5) / 100) * rates.CAD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/payLater`,
        { currency_new, new_total, id }
      );
    } else if (
      currency_new === "TRY (Turkish lira, payment from Turkey only)"
    ) {
      let x =
        estimatedTotal * rates.TRY + ((estimatedTotal * 2.5) / 100) * rates.TRY;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/payLater`,
        { currency_new, new_total, id }
      );
    } else if (currency_new === "SGD (Singapore dollar)") {
      let x =
        estimatedTotal * rates.SGD + ((estimatedTotal * 2.5) / 100) * rates.SGD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/payLater`,
        { currency_new, new_total, id }
      );
    } else if (currency_new === "HUF (Hungarian forint)") {
      let x =
        estimatedTotal * rates.HUF + ((estimatedTotal * 2.5) / 100) * rates.HUF;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/payLater`,
        { currency_new, new_total, id }
      );
    } else if (
      currency_new === "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      let x =
        estimatedTotal * rates.NZD + ((estimatedTotal * 2.5) / 100) * rates.NZD;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/payLater`,
        { currency_new, new_total, id }
      );
    } else if (
      currency_new === "RON (Romanian leu, paying from Romania only)"
    ) {
      let x =
        estimatedTotal * rates.RON + ((estimatedTotal * 2.5) / 100) * rates.RON;
      let new_total = x.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/payLater`,
        { currency_new, new_total, id }
      );
    } else {
      let new_total = estimatedTotal.toFixed(2);
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/inhouse_translation/order_translation/payLater`,
        { currency_new, new_total, id }
      );
    }

    try {
      let bookingid = id;
      let EUR = (
        estimatedTotal * rates.EUR +
        ((estimatedTotal * 2.5) / 100) * rates.EUR
      ).toFixed(2);
      let AUD = (
        estimatedTotal * rates.AUD +
        ((estimatedTotal * 2.5) / 100) * rates.AUD
      ).toFixed(2);
      let USD = (
        estimatedTotal * rates.USD +
        ((estimatedTotal * 2.5) / 100) * rates.USD
      ).toFixed(2);
      let CAD = (
        estimatedTotal * rates.CAD +
        ((estimatedTotal * 2.5) / 100) * rates.CAD
      ).toFixed(2);
      let TRY = (
        estimatedTotal * rates.TRY +
        ((estimatedTotal * 2.5) / 100) * rates.TRY
      ).toFixed(2);
      let SGD = (
        estimatedTotal * rates.SGD +
        ((estimatedTotal * 2.5) / 100) * rates.SGD
      ).toFixed(2);
      let HUF = (
        estimatedTotal * rates.HUF +
        ((estimatedTotal * 2.5) / 100) * rates.HUF
      ).toFixed(2);
      let NZD = (
        estimatedTotal * rates.NZD +
        ((estimatedTotal * 2.5) / 100) * rates.NZD
      ).toFixed(2);
      let RON = (
        estimatedTotal * rates.RON +
        ((estimatedTotal * 2.5) / 100) * rates.RON
      ).toFixed(2);

      await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/inhouse-client/sendEmail-AUSDAccount`,
        {
          currency_new,
          estimatedTotal,
          rates,
          bookingid,
          inhouseClientID,
          EUR,
          AUD,
          USD,
          CAD,
          TRY,
          SGD,
          HUF,
          NZD,
          RON,
        }
      );
      //   setLoading(false)
      swal("Success!! Please check your registered email for more details..", {
        icon: "success",
        buttons: "OK!!",
      }).then((res) => {
        Cookies.set("total_Overnight_charges", 0);
        Cookies.set("total_PriceForInterpriting", 0);
        Cookies.set("total_TravelTotal_Wages", 0);
        Cookies.set("tatal_Food_Overnight", 0);
        window.location = "/";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleClickSTRIPE = async (event) => {
    // Get Stripe.js instance
    // alert("ok")
    Cookies.set("currency_new_card", currency_new_card);
    const stripe = await stripePromise;
    if (currency_new_card === "EUR (Euro countries)") {
      let x =
        estimatedTotal * rates.EUR + ((estimatedTotal * 2.5) / 100) * rates.EUR;
      let new_total = x.toFixed(2);

      Cookies.set("new_total", new_total);
      let y = {
        getCurrency: currency_new_card,
        getTotal: new_total,
        getname: inhouseClientID,
        getStripeID: id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/in-house/getPaymentOrderT/v1/payment_intents`,
        {
          method: "POST",
          body: JSON.stringify(y),
          headers: { "Content-Type": "application/json" },
        }
      );
      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (result.error) {
        console.log(result.error);
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else if (currency_new_card === "AUD (Australia)") {
      let x =
        estimatedTotal * rates.AUD + ((estimatedTotal * 2.5) / 100) * rates.AUD;
      let new_total = x.toFixed(2);
      Cookies.set("new_total", new_total);
      let y = {
        getCurrency: currency_new_card,
        getTotal: new_total,
        getname: inhouseClientID,
        getStripeID: id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/in-house/getPaymentOrderT/v1/payment_intents`,
        {
          method: "POST",
          body: JSON.stringify(y),
          headers: { "Content-Type": "application/json" },
        }
      );
      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else if (currency_new_card === "USD (USA, paying from USA only)") {
      let x =
        estimatedTotal * rates.USD + ((estimatedTotal * 2.5) / 100) * rates.USD;
      let new_total = x.toFixed(2);
      Cookies.set("new_total", new_total);
      let y = {
        getCurrency: currency_new_card,
        getTotal: new_total,
        getname: inhouseClientID,
        getStripeID: id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/in-house/getPaymentOrderT/v1/payment_intents`,
        {
          method: "POST",
          body: JSON.stringify(y),
          headers: { "Content-Type": "application/json" },
        }
      );
      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else if (currency_new_card === "CAD (Canada)") {
      let x =
        estimatedTotal * rates.CAD + ((estimatedTotal * 2.5) / 100) * rates.CAD;
      let new_total = x.toFixed(2);
      Cookies.set("new_total", new_total);
      let y = {
        getCurrency: currency_new_card,
        getTotal: new_total,
        getname: inhouseClientID,
        getStripeID: id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/in-house/getPaymentOrderT/v1/payment_intents`,
        {
          method: "POST",
          body: JSON.stringify(y),
          headers: { "Content-Type": "application/json" },
        }
      );
      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else if (
      currency_new_card === "TRY (Turkish lira, payment from Turkey only)"
    ) {
      let x =
        estimatedTotal * rates.TRY + ((estimatedTotal * 2.5) / 100) * rates.TRY;
      let new_total = x.toFixed(2);
      Cookies.set("new_total", new_total);
      let y = {
        getCurrency: currency_new_card,
        getTotal: new_total,
        getname: inhouseClientID,
        getStripeID: id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/in-house/getPaymentOrderT/v1/payment_intents`,
        {
          method: "POST",
          body: JSON.stringify(y),
          headers: { "Content-Type": "application/json" },
        }
      );
      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else if (currency_new_card === "SGD (Singapore dollar)") {
      let x =
        estimatedTotal * rates.SGD + ((estimatedTotal * 2.5) / 100) * rates.SGD;
      let new_total = x.toFixed(2);
      Cookies.set("new_total", new_total);
      let y = {
        getCurrency: currency_new_card,
        getTotal: new_total,
        getname: inhouseClientID,
        getStripeID: id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/in-house/getPaymentOrderT/v1/payment_intents`,
        {
          method: "POST",
          body: JSON.stringify(y),
          headers: { "Content-Type": "application/json" },
        }
      );
      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else if (currency_new_card === "HUF (Hungarian forint)") {
      let x =
        estimatedTotal * rates.HUF + ((estimatedTotal * 2.5) / 100) * rates.HUF;
      let new_total = x.toFixed(2);
      Cookies.set("new_total", new_total);
      let y = {
        getCurrency: currency_new_card,
        getTotal: new_total,
        getname: inhouseClientID,
        getStripeID: id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/in-house/getPaymentOrderT/v1/payment_intents`,
        {
          method: "POST",
          body: JSON.stringify(y),
          headers: { "Content-Type": "application/json" },
        }
      );
      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else if (
      currency_new_card ===
      "NZD (New Zealand dollar, paying from New Zealand only)"
    ) {
      let x =
        estimatedTotal * rates.NZD + ((estimatedTotal * 2.5) / 100) * rates.NZD;
      let new_total = x.toFixed(2);
      Cookies.set("new_total", new_total);
      let y = {
        getCurrency: currency_new_card,
        getTotal: new_total,
        getname: inhouseClientID,
        getStripeID: id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/in-house/getPaymentOrderT/v1/payment_intents`,
        {
          method: "POST",
          body: JSON.stringify(y),
          headers: { "Content-Type": "application/json" },
        }
      );
      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else if (
      currency_new_card === "RON (Romanian leu, paying from Romania only)"
    ) {
      let x =
        estimatedTotal * rates.RON + ((estimatedTotal * 2.5) / 100) * rates.RON;
      let new_total = x.toFixed(2);
      Cookies.set("new_total", new_total);
      let y = {
        getCurrency: currency_new_card,
        getTotal: new_total,
        getname: inhouseClientID,
        getStripeID: id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/in-house/getPaymentOrderT/v1/payment_intents`,
        {
          method: "POST",
          body: JSON.stringify(y),
          headers: { "Content-Type": "application/json" },
        }
      );
      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else {
      let new_total = estimatedTotal.toFixed(2);
      Cookies.set("new_total", new_total);
      let x = {
        getCurrency: currency_new_card,
        getTotal: new_total,
        getname: inhouseClientID,
        getStripeID: id,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/in-house/getPaymentOrderT/v1/payment_intents`,
        {
          method: "POST",
          body: JSON.stringify(x),
          headers: { "Content-Type": "application/json" },
        }
      );

      const session = await response.json();
      const result = await stripe
        .redirectToCheckout({
          sessionId: session.id,
        })
        .then(function (result) {
          alert(result);
          if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            alert(result.error.message);
          }
        });
    }
  };

  const isAllow = !estimatedTotal || !currency_new || 0;

  // Cookies.set('estimatedTotal', estimatedTotal)
  return (
    <div className={classes.root}>
      {loading === false ? (
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={9} xl={8}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  {/* <Avatar className={classes.avatar}> */}
                  <img
                    style={{ width: "70px", height: "65px" }}
                    src={logo}
                    alt=""
                  />
                  {/* </Avatar> */}
                  <Typography component="h1" variant="h5">
                    Summary
                  </Typography>
                </div>
                <div
                  style={{
                    padding: "4rem 12rem 4rem 12rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {id ? (
                      <div style={{ display: "flex" }}>
                        <p>Booking Order / Assignment ID</p>
                        <span style={{ marginLeft: "auto" }}>{id}</span>
                      </div>
                    ) : null}

                    <Divider />
                    <div
                      style={{ display: "flex", margin: "1em 0 1em 0" }}
                    ></div>
                    {totalPriceForTranslation ? (
                      <div style={{ display: "flex" }}>
                        <p>{wordCount} words translation price:</p>
                        {/* <span style= {{ marginLeft: 'auto'}}> {currency_u.includes('euro (eu)') ? '€': '£'} {currency_u.includes('euro (eu)') ? (total_PriceForInterpriting * rates.EUR).toFixed(2) : total_PriceForInterpriting}</span> */}
                        {currency_u.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {c.toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("EUR (EU)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              c * rates.EUR +
                              ((c * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              c * rates.AUD +
                              ((c * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("USD (USA)") && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              c * rates.USD +
                              ((c * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              c * rates.CAD +
                              ((c * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_u.includes("TRY (Turkish lira)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              c * rates.TRY +
                              ((c * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_u.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              c * rates.SGD +
                              ((c * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              c * rates.HUF +
                              ((c * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("NZD (New Zealand dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              c * rates.NZD +
                              ((c * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("RON (Romanian leu)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              c * rates.RON +
                              ((c * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}
                      </div>
                    ) : null}

                    {translationVisualFormatingPrice ? (
                      <div style={{ display: "flex" }}>
                        <p>Estimated price for visual formatting (TBC)</p>
                        {currency_u.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {d.toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("EUR (EU)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              d * rates.EUR +
                              ((d * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              d * rates.AUD +
                              ((d * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("USD (USA)") && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              d * rates.USD +
                              ((d * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              d * rates.CAD +
                              ((d * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_u.includes("TRY (Turkish lira)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              d * rates.TRY +
                              ((d * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_u.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              d * rates.SGD +
                              ((d * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              d * rates.HUF +
                              ((d * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("NZD (New Zealand dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              d * rates.NZD +
                              ((d * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("RON (Romanian leu)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              d * rates.RON +
                              ((d * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}
                        {/* <span style= {{ marginLeft: 'auto'}}>{total_TravelTotal_Wages}</span> */}
                      </div>
                    ) : null}

                    {totalPriceForQualityCheck ? (
                      <div style={{ display: "flex" }}>
                        <p>Estimated price for quality check (TBC)</p>
                        {/* <span style= {{ marginLeft: 'auto'}}> {currency_u.includes('euro (eu)') ? '€': '£'} {currency_u.includes('euro (eu)') ? (tatal_Food_Overnight * rates.EUR).toFixed(2) : tatal_Food_Overnight}</span> */}

                        {currency_u.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {a.toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("EUR (EU)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              a * rates.EUR +
                              ((a * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              a * rates.AUD +
                              ((a * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("USD (USA)") && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              a * rates.USD +
                              ((a * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              a * rates.CAD +
                              ((a * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_u.includes("TRY (Turkish lira)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              a * rates.TRY +
                              ((a * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_u.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              a * rates.SGD +
                              ((a * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              a * rates.HUF +
                              ((a * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("NZD (New Zealand dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              a * rates.NZD +
                              ((a * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("RON (Romanian leu)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              a * rates.RON +
                              ((a * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}

                        {/* <span style= {{ marginLeft: 'auto'}}>{tatal_Food_Overnight}</span> */}
                      </div>
                    ) : null}

                    {totalPriceForCertificate ? (
                      <div style={{ display: "flex" }}>
                        <p>Estimated price for certification (TBC):</p>

                        {currency_u.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {b.toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("EUR (EU)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              b * rates.EUR +
                              ((b * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              b * rates.AUD +
                              ((b * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("USD (USA)") && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              b * rates.USD +
                              ((b * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              b * rates.CAD +
                              ((b * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_u.includes("TRY (Turkish lira)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              b * rates.TRY +
                              ((b * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_u.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              b * rates.SGD +
                              ((b * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              b * rates.HUF +
                              ((b * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("NZD (New Zealand dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              b * rates.NZD +
                              ((b * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("RON (Romanian leu)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              b * rates.RON +
                              ((b * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}

                        {/* <span style= {{ marginLeft: 'auto'}}>{total_Overnight_charges}</span> */}
                      </div>
                    ) : null}

                    <Divider />

                    {estimatedTotal ? (
                      <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                        <p>Estimated total</p>

                        {currency_u.includes("GBP (UK)") && (
                          <span style={{ marginLeft: "auto" }}>
                            £ {estimatedTotal.toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("EUR (EU)") && (
                          <span style={{ marginLeft: "auto" }}>
                            €{" "}
                            {(
                              estimatedTotal * rates.EUR +
                              ((estimatedTotal * 2.5) / 100) * rates.EUR
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("AUD (Australia)") && (
                          <span style={{ marginLeft: "auto" }}>
                            AUD ${" "}
                            {(
                              estimatedTotal * rates.AUD +
                              ((estimatedTotal * 2.5) / 100) * rates.AUD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("USD (USA)") && (
                          <span style={{ marginLeft: "auto" }}>
                            USD ${" "}
                            {(
                              estimatedTotal * rates.USD +
                              ((estimatedTotal * 2.5) / 100) * rates.USD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("CAD (Canada)") && (
                          <span style={{ marginLeft: "auto" }}>
                            CAD ${" "}
                            {(
                              estimatedTotal * rates.CAD +
                              ((estimatedTotal * 2.5) / 100) * rates.CAD
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_u.includes("TRY (Turkish lira)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            TRY ₺{" "}
                            {(
                              estimatedTotal * rates.TRY +
                              ((estimatedTotal * 2.5) / 100) * rates.TRY
                            ).toFixed(2)}
                          </span>
                        )}

                        {currency_u.includes("SGD (Singapore dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            SGD S${" "}
                            {(
                              estimatedTotal * rates.SGD +
                              ((estimatedTotal * 2.5) / 100) * rates.SGD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("HUF (Hungarian forint)") && (
                          <span style={{ marginLeft: "auto" }}>
                            HUF Ft{" "}
                            {(
                              estimatedTotal * rates.HUF +
                              ((estimatedTotal * 2.5) / 100) * rates.HUF
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("NZD (New Zealand dollar)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            NZD ${" "}
                            {(
                              estimatedTotal * rates.NZD +
                              ((estimatedTotal * 2.5) / 100) * rates.NZD
                            ).toFixed(2)}
                          </span>
                        )}
                        {currency_u.includes("RON (Romanian leu)") && (
                          <span style={{ marginLeft: "auto" }}>
                            {" "}
                            RON lei{" "}
                            {(
                              estimatedTotal * rates.RON +
                              ((estimatedTotal * 2.5) / 100) * rates.RON
                            ).toFixed(2)}
                          </span>
                        )}

                        {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                  <span style={{ marginLeft: "auto" }}>* To be confirmed</span>
                </div>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Marketplace speedy payment
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomSelect
                      onChange={handleChangecard}
                      data={CURRENCY_x}
                      width="73.3%"
                      label="Preferred currency"
                      name="currency_new_card"
                      value={currency_new_card}
                    />
                  </AccordionDetails>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      {estimatedTotal ? (
                        <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                          <p>Estimated total</p>
                          {currency_new_card.includes("GBP (UK)") && (
                            <span style={{ marginLeft: "auto" }}>
                              £ {estimatedTotal.toFixed(2)}
                            </span>
                          )}
                          {currency_new_card.includes(
                            "EUR (Euro countries)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              €{" "}
                              {(
                                estimatedTotal * rates.EUR +
                                ((estimatedTotal * 2.5) / 100) * rates.EUR
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new_card.includes("AUD (Australia)") && (
                            <span style={{ marginLeft: "auto" }}>
                              AUD ${" "}
                              {(
                                estimatedTotal * rates.AUD +
                                ((estimatedTotal * 2.5) / 100) * rates.AUD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new_card.includes(
                            "USD (USA, paying from USA only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              USD ${" "}
                              {(
                                estimatedTotal * rates.USD +
                                ((estimatedTotal * 2.5) / 100) * rates.USD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new_card.includes("CAD (Canada)") && (
                            <span style={{ marginLeft: "auto" }}>
                              CAD ${" "}
                              {(
                                estimatedTotal * rates.CAD +
                                ((estimatedTotal * 2.5) / 100) * rates.CAD
                              ).toFixed(2)}
                            </span>
                          )}

                          {currency_new_card.includes(
                            "TRY (Turkish lira, payment from Turkey only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              TRY ₺{" "}
                              {(
                                estimatedTotal * rates.TRY +
                                ((estimatedTotal * 2.5) / 100) * rates.TRY
                              ).toFixed(2)}
                            </span>
                          )}

                          {currency_new_card.includes(
                            "SGD (Singapore dollar)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              SGD S${" "}
                              {(
                                estimatedTotal * rates.SGD +
                                ((estimatedTotal * 2.5) / 100) * rates.SGD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new_card.includes(
                            "HUF (Hungarian forint)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              HUF Ft{" "}
                              {(
                                estimatedTotal * rates.HUF +
                                ((estimatedTotal * 2.5) / 100) * rates.HUF
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new_card.includes(
                            "NZD (New Zealand dollar, paying from New Zealand only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              NZD ${" "}
                              {(
                                estimatedTotal * rates.NZD +
                                ((estimatedTotal * 2.5) / 100) * rates.NZD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new_card.includes(
                            "RON (Romanian leu, paying from Romania only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              RON lei{" "}
                              {(
                                estimatedTotal * rates.RON +
                                ((estimatedTotal * 2.5) / 100) * rates.RON
                              ).toFixed(2)}
                            </span>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </AccordionDetails>
                  <AccordionDetails>
                    <Button
                      className={`${classesb.button}`}
                      disabled={!estimatedTotal || !currency_new_card}
                      onClick={() =>
                        history.push(
                          `/inhouse-translation-order/client/summary/estimate/payment`
                        )
                      }
                      style={{
                        width: "30rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Pay and confirm
                    </Button>
                  </AccordionDetails>
                </Accordion>
                {/* pay later */}
                {payLaterAccess && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Pay Later
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomSelect
                        onChange={handleChange}
                        data={CURRENCY_x}
                        s
                        width="73.3%"
                        label="Preferred currency"
                        name="currency_new"
                        value={currency_new}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        {estimatedTotal ? (
                          <div
                            style={{ display: "flex", margin: "1em 0 1em 0" }}
                          >
                            <p>Estimated total</p>
                            {currency_new.includes("GBP (UK)") && (
                              <span style={{ marginLeft: "auto" }}>
                                £ {estimatedTotal.toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes("EUR (Euro countries)") && (
                              <span style={{ marginLeft: "auto" }}>
                                €{" "}
                                {(
                                  estimatedTotal * rates.EUR +
                                  ((estimatedTotal * 2.5) / 100) * rates.EUR
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes("AUD (Australia)") && (
                              <span style={{ marginLeft: "auto" }}>
                                AUD ${" "}
                                {(
                                  estimatedTotal * rates.AUD +
                                  ((estimatedTotal * 2.5) / 100) * rates.AUD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes(
                              "USD (USA, paying from USA only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                USD ${" "}
                                {(
                                  estimatedTotal * rates.USD +
                                  ((estimatedTotal * 2.5) / 100) * rates.USD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes("CAD (Canada)") && (
                              <span style={{ marginLeft: "auto" }}>
                                CAD ${" "}
                                {(
                                  estimatedTotal * rates.CAD +
                                  ((estimatedTotal * 2.5) / 100) * rates.CAD
                                ).toFixed(2)}
                              </span>
                            )}

                            {currency_new.includes(
                              "TRY (Turkish lira, payment from Turkey only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                TRY ₺{" "}
                                {(
                                  estimatedTotal * rates.TRY +
                                  ((estimatedTotal * 2.5) / 100) * rates.TRY
                                ).toFixed(2)}
                              </span>
                            )}

                            {currency_new.includes(
                              "SGD (Singapore dollar)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                SGD S${" "}
                                {(
                                  estimatedTotal * rates.SGD +
                                  ((estimatedTotal * 2.5) / 100) * rates.SGD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes(
                              "HUF (Hungarian forint)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                HUF Ft{" "}
                                {(
                                  estimatedTotal * rates.HUF +
                                  ((estimatedTotal * 2.5) / 100) * rates.HUF
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes(
                              "NZD (New Zealand dollar, paying from New Zealand only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                NZD ${" "}
                                {(
                                  estimatedTotal * rates.NZD +
                                  ((estimatedTotal * 2.5) / 100) * rates.NZD
                                ).toFixed(2)}
                              </span>
                            )}
                            {currency_new.includes(
                              "RON (Romanian leu, paying from Romania only)"
                            ) && (
                              <span style={{ marginLeft: "auto" }}>
                                {" "}
                                RON lei{" "}
                                {(
                                  estimatedTotal * rates.RON +
                                  ((estimatedTotal * 2.5) / 100) * rates.RON
                                ).toFixed(2)}
                              </span>
                            )}

                            {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                          </div>
                        ) : null}
                      </div>
                    </AccordionDetails>
                    <AccordionDetails>
                      <Button
                        className={`${classesb.button}`}
                        disabled={isAllow}
                        style={{
                          width: "30rem",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handlePayLaterNext}
                      >
                        Pay later{" "}
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                )}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Marketplace bank transfer
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomSelect
                      onChange={handleChange}
                      data={CURRENCY_x}
                      width="73.3%"
                      label="Preferred currency"
                      name="currency_new"
                      value={currency_new}
                    />
                  </AccordionDetails>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      {estimatedTotal ? (
                        <div style={{ display: "flex", margin: "1em 0 1em 0" }}>
                          <p>Estimated total</p>
                          {currency_new.includes("GBP (UK)") && (
                            <span style={{ marginLeft: "auto" }}>
                              £ {estimatedTotal.toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("EUR (Euro countries)") && (
                            <span style={{ marginLeft: "auto" }}>
                              €{" "}
                              {(
                                estimatedTotal * rates.EUR +
                                ((estimatedTotal * 2.5) / 100) * rates.EUR
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("AUD (Australia)") && (
                            <span style={{ marginLeft: "auto" }}>
                              AUD ${" "}
                              {(
                                estimatedTotal * rates.AUD +
                                ((estimatedTotal * 2.5) / 100) * rates.AUD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "USD (USA, paying from USA only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              USD ${" "}
                              {(
                                estimatedTotal * rates.USD +
                                ((estimatedTotal * 2.5) / 100) * rates.USD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("CAD (Canada)") && (
                            <span style={{ marginLeft: "auto" }}>
                              CAD ${" "}
                              {(
                                estimatedTotal * rates.CAD +
                                ((estimatedTotal * 2.5) / 100) * rates.CAD
                              ).toFixed(2)}
                            </span>
                          )}

                          {currency_new.includes(
                            "TRY (Turkish lira, payment from Turkey only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              TRY ₺{" "}
                              {(
                                estimatedTotal * rates.TRY +
                                ((estimatedTotal * 2.5) / 100) * rates.TRY
                              ).toFixed(2)}
                            </span>
                          )}

                          {currency_new.includes("SGD (Singapore dollar)") && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              SGD S${" "}
                              {(
                                estimatedTotal * rates.SGD +
                                ((estimatedTotal * 2.5) / 100) * rates.SGD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes("HUF (Hungarian forint)") && (
                            <span style={{ marginLeft: "auto" }}>
                              HUF Ft{" "}
                              {(
                                estimatedTotal * rates.HUF +
                                ((estimatedTotal * 2.5) / 100) * rates.HUF
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "NZD (New Zealand dollar, paying from New Zealand only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              NZD ${" "}
                              {(
                                estimatedTotal * rates.NZD +
                                ((estimatedTotal * 2.5) / 100) * rates.NZD
                              ).toFixed(2)}
                            </span>
                          )}
                          {currency_new.includes(
                            "RON (Romanian leu, paying from Romania only)"
                          ) && (
                            <span style={{ marginLeft: "auto" }}>
                              {" "}
                              RON lei{" "}
                              {(
                                estimatedTotal * rates.RON +
                                ((estimatedTotal * 2.5) / 100) * rates.RON
                              ).toFixed(2)}
                            </span>
                          )}

                          {/* <span style= {{ marginLeft: 'auto'}}>{estimatedTotal}</span> */}
                        </div>
                      ) : null}
                    </div>
                  </AccordionDetails>
                  <AccordionDetails>
                    <Button
                      className={`${classesb.button}`}
                      disabled={isAllow}
                      style={{
                        width: "30rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Get bank details
                    </Button>
                  </AccordionDetails>
                </Accordion>

                {/* {selectedForm === 2 && <FormThree />} */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <LoadingScreen
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          logoSrc="https://linguistscollective.com/static/media/Interpreting-Translation-Linguists-Collective-Language-Services.0f1108ca.png"
          text="Please wait! The system is generating email and sending it to the client."
        >
          <div>Loadable content</div>
        </LoadingScreen>
      )}
    </div>
  );
};

export default OTinhousesummary;
