import React, { useState } from "react";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Typography from "@material-ui/core/Typography";
import CustomInputSingle from "components/Common/CustomInputSingle";
import RichTextEditor from "react-rte";
import { Button, Header } from "semantic-ui-react";
import CustomSelect from "components/Common/CustomSelect";
import CustomRadio from "components/Common/CustomRadio";
import Axios from "axios";
import swal from "sweetalert";
import {
  Email_Type,
  CAC_Email_List,
  BAC_Email_List,
  LIN_Email_List,
  SPPO_Email_List,
  EMP_Email_List,
  ADM_Email_List,
  Interpreting_Email_List,
  Translation_Email_List,
  Quote_Request_Email_List,
  Timesheet_Email_List,
  Project_Report_Email_List,
  Allocate_TakeJob_Cancel_Email_List,
  JobAdvert_Email_List,
  Additional_Email_List,
  Pre_Signup_User_Variable,
  Pre_Signup_Lin_Variable,
  Pre_Signup_Sppo_Variable,
  Pre_Signup_Admin_Variable,
  Signup_User_Variable,
  Signup_Admin_Variable,
  Signin_User_Variable,
  Forgot_Password_User_Variable,
  BAC_User_Variable,
  SPPO_User_Variable,
  Interpreting_BankEmail_Variable,
  Interpreting_StripeEmail_Variable,
  Translation_BankEmail_Variable,
  Translation_StripeEmail_Variable,
  Accept_Interpreting_Variable,
  Accept_Translation_Variable,
  Quote_Request_Variable,
  Make_Timesheet_Variable,
  Confirm_Timesheet_Variable,
  Make_ProjectReport_Variable,
  Confirm_ProjectReport_Variable,
  Allocate_Interpreting_Variable,
  Allocate_Translation_Variable,
  Take_Job_Variable,
  Cancel_Job_Variable,
  Job_Advert_Variable,
  Ask_Ques_Variable,
} from "data/Email_Settings";

const EmailSettings = () => {
  const classes = useEmployeeStyles();
  const [details, setDetails] = useState({
    content: RichTextEditor.createEmptyValue(),
    types_of_email: "",
    subject: "",
  });
  const [text, setText] = useState();

  const handleText = (value) => {
    setText(value.toString("html"));
    setDetails({ ...details, content: value });
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const [emailType, setEmailType] = useState("");

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setEmailType(value);
  };

  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/post-email-settings`,
        {
          types_of_email: details.types_of_email || "",
          subject: details.subject || "",
          content: text || "",
        }
      );
      setDetails({
        ...details,
        loader: false,
        types_of_email: "",
        subject: "",
      });
      swal({
        text: `${response?.data?.message}`,
        buttons: "Ok",
        icon: "success",
      });
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  return (
    <GridContainer
      style={{
        justifyContent: "center",
      }}
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
      <GridItem xs={12} sm={12} md={3} lg={3}>
        <Card>
          <div className={`${classes.textCenter} ${classes.title}`}>
            <CustomAvatar />
            <Typography component="h1" variant="h5">
              LC Email Settings Amendment Form
            </Typography>
          </div>
          <CardBody>
            <CustomRadio
              onChange={handleRadioChange}
              label="Select Types Of Email"
              width="100%"
              data={Email_Type}
              value={emailType}
              required
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={9} lg={9}>
        <Card>
          <div className={`${classes.textCenter} ${classes.title}`}>
            <CustomAvatar />
            <Typography component="h1" variant="h5">
              LC Email Settings Amendment Form
            </Typography>
          </div>
          <CardBody>
            {emailType.includes("Cash Account Client") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={CAC_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Billing Account Client") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={BAC_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Linguist Account") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={LIN_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("SPPO Account") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={SPPO_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Employee Account") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={EMP_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Manager / Admin Account") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={ADM_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Interpreting") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={Interpreting_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Translation") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={Translation_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Quotation") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={Quote_Request_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Close and Timesheet") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={Timesheet_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Project Report") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={Project_Report_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes(
                "Allocate Job, Take Job Back and Cancel Job"
              ) ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={Allocate_TakeJob_Cancel_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Job Advert") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={JobAdvert_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : emailType.includes("Additional") ? (
              <CustomSelect
                required
                onChange={handleChange}
                label="Type/s of Email"
                moreInfo="Please pick the most relevant one/s)"
                data={Additional_Email_List}
                width="100%"
                name="types_of_email"
                value={details.types_of_email}
              />
            ) : null}

            <CustomInputSingle
              onChange={handleChange}
              label="Subject"
              width="100%"
              name="subject"
              value={details.subject}
              placeholder="type email subject"
              required
            />
            <Header as="h4">Body *</Header>
            <RichTextEditor
              value={details.content}
              onChange={handleText}
              required
            />
            <div
              style={{ margin: 10, display: "flex", justifyContent: "center" }}
            >
              <Button
                loading={details.loader}
                onClick={handleSubmit}
                disabled={
                  !emailType ||
                  !details.types_of_email ||
                  !details.subject ||
                  !text
                }
                color="purple"
              >
                Submit
              </Button>
            </div>
          </CardBody>
          <CardBody>
            {/* For CAC */}
            {details.types_of_email?.includes(
              "1 - CAC Pre-Signup = Email to Client"
            )
              ? Pre_Signup_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "2 - CAC Pre-Signup = Email to Admin"
                )
              ? Pre_Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "3 - CAC Signup = Email to Client"
                )
              ? Signup_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "4 - CAC Signup = Email to Admin"
                )
              ? Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "5 - CAC Signin = Email to Client"
                )
              ? Signin_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "6 - CAC Forgot Password = Email to Client"
                )
              ? Forgot_Password_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End CAC */}

            {/* For BAC */}
            {details.types_of_email?.includes(
              "7 - BAC Pre-Signup = Email to Client"
            )
              ? Pre_Signup_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "8 - BAC Pre-Signup = Email to Admin"
                )
              ? Pre_Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "9 - BAC Signup = Email to Client"
                )
              ? Signup_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "10 - BAC Signup = Email to Admin"
                )
              ? Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "11 - BAC Signin = Email to Client"
                )
              ? Signin_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "12 - BAC Forgot Password = Email to Client"
                )
              ? Forgot_Password_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "13 - BAC Send User Registration Form = Email to User"
                )
              ? BAC_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End BAC */}

            {/* For LIN */}
            {details.types_of_email?.includes(
              "14 - LIN Pre-Signup = Email to Linguist"
            )
              ? Pre_Signup_Lin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "15 - LIN Pre-Signup = Email to Admin"
                )
              ? Pre_Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "16 - LIN Signup = Email to Linguist"
                )
              ? Signup_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "17 - LIN Signup = Email to Admin"
                )
              ? Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "18 - LIN Signin = Email to Linguist"
                )
              ? Signin_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "19 - LIN Forgot Password = Email to Linguist"
                )
              ? Forgot_Password_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End LIN */}

            {/* For SPPO */}
            {details.types_of_email?.includes(
              "20 - SPPO Pre-Signup = Email to SPPO"
            )
              ? Pre_Signup_Sppo_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "21 - SPPO Pre-Signup = Email to Admin"
                )
              ? Pre_Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "22 - SPPO Signup = Email to SPPO"
                )
              ? Signup_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "23 - SPPO Signup = Email to Admin"
                )
              ? Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "24 - SPPO Signin = Email to SPPO"
                )
              ? Signin_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "25 - SPPO Forgot Password = Email to SPPO"
                )
              ? Forgot_Password_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "26 - SPPO Send User Registration Form = Email to User"
                )
              ? SPPO_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End SPPO */}

            {/* For EMP */}
            {details.types_of_email?.includes(
              "27 - EMP Pre-Signup = Email to Employee"
            )
              ? Pre_Signup_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "28 - EMP Pre-Signup = Email to Admin"
                )
              ? Pre_Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "29 - EMP Signup = Email to Employee"
                )
              ? Signup_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "30 - EMP Signup = Email to Admin"
                )
              ? Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "31 - EMP Signin = Email to Employee"
                )
              ? Signin_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "32 - EMP Forgot Password = Email to Employee"
                )
              ? Forgot_Password_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End EMP */}

            {/* For MAN */}
            {details.types_of_email?.includes(
              "33 - MAN Pre-Signup = Email to the new Manager"
            )
              ? Pre_Signup_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "34 - MAN Pre-Signup = Email to the current Manager"
                )
              ? Pre_Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "35 - MAN Signup = Email to the new Manager"
                )
              ? Signup_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "36 - MAN Signup = Email to the current Manager"
                )
              ? Signup_Admin_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "37 - MAN Signin = Email to the new Manager"
                )
              ? Signin_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "38 - MAN Forgot Password = Email to the new Manager"
                )
              ? Forgot_Password_User_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End MAN */}

            {/* For Interpreting */}
            {details.types_of_email?.includes(
              "39 - CAC, BAC INC and PRC - Create Interpreting Assignment - Send Bank Email = Email to Client"
            )
              ? Interpreting_BankEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "40 - CAC, BAC INC and PRC - Create Interpreting Assignment - Send Bank Email = Email to Admin"
                )
              ? Interpreting_BankEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "41 - CAC, BAC INC and PRC - Create Interpreting Assignment - Send Stripe Email = Email to Client"
                )
              ? Interpreting_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "42 - CAC, BAC INC and PRC - Create Interpreting Assignment - Send Stripe Email = Email to Admin"
                )
              ? Interpreting_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "43 - CAC, BAC INC and PRC - Create Interpreting Assignment - Send Stripe Email = Email to Linguist"
                )
              ? Interpreting_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "44 - BAC - Create Interpreting Assignment - Added To Monthly Bill Email = Email to Client"
                )
              ? Interpreting_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "45 - BAC - Create Interpreting Assignment - Added To Monthly Bill Email = Email to Admin"
                )
              ? Interpreting_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "46 - BAC - Create Interpreting Assignment - Added To Monthly Bill Email = Email to Linguist"
                )
              ? Interpreting_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "47 - CAC and PRC - Create Interpreting Assignment - Pay Later Email = Email to Client"
                )
              ? Interpreting_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "48 - CAC and PRC - Create Interpreting Assignment - Pay Later Email = Email to Admin"
                )
              ? Interpreting_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "49 - CAC and PRC - Create Interpreting Assignment - Pay Later Email = Email to Linguist"
                )
              ? Interpreting_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "50 - CAC, BAC and INC - Accept Interpreting Assignment = Email to Client"
                )
              ? Accept_Interpreting_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "51 - CAC, BAC and INC - Accept Interpreting Assignment = Email to Admin"
                )
              ? Accept_Interpreting_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "52 - CAC, BAC and INC - Accept Interpreting Assignment = Email to Linguist"
                )
              ? Accept_Interpreting_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End Interpreting */}

            {/* For Translation */}
            {details.types_of_email?.includes(
              "53 - CAC, BAC INC and PRC - Create Translation Assignment - Send Bank Email = Email to Client"
            )
              ? Translation_BankEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "54 - CAC, BAC INC and PRC - Create Translation Assignment - Send Bank Email = Email to Admin"
                )
              ? Translation_BankEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "55 - CAC, BAC INC and PRC - Create Translation Assignment - Send Stripe Email = Email to Client"
                )
              ? Translation_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "56 - CAC, BAC INC and PRC - Create Translation Assignment - Send Stripe Email = Email to Admin"
                )
              ? Translation_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "57 - CAC, BAC INC and PRC - Create Translation Assignment - Send Stripe Email = Email to Linguist"
                )
              ? Translation_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "58 - BAC - Create Translation Assignment - Added To Monthly Bill Email = Email to Client"
                )
              ? Translation_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "59 - BAC - Create Translation Assignment - Added To Monthly Bill Email = Email to Admin"
                )
              ? Translation_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "60 - BAC - Create Translation Assignment - Added To Monthly Bill Email = Email to Linguist"
                )
              ? Translation_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "61 - CAC and PRC - Create Translation Assignment - Pay Later Email = Email to Client"
                )
              ? Translation_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "62 - CAC and PRC - Create Translation Assignment - Pay Later Email = Email to Admin"
                )
              ? Translation_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "63 - CAC and PRC - Create Translation Assignment - Pay Later Email = Email to Linguist"
                )
              ? Translation_StripeEmail_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "64 - CAC, BAC and INC - Accept Translation Assignment = Email to Client"
                )
              ? Accept_Translation_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "65 - CAC, BAC and INC - Accept Translation Assignment = Email to Admin"
                )
              ? Accept_Translation_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "66 - CAC, BAC and INC - Accept Translation Assignment = Email to Linguist"
                )
              ? Accept_Translation_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End Translation */}

            {/* For Quote Request */}
            {details.types_of_email?.includes(
              "67 - CAC and BAC - Quote Request = Email to Client"
            )
              ? Quote_Request_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "68 - CAC and BAC - Quote Request = Email to Linguist"
                )
              ? Quote_Request_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End Quote Request */}

            {/* For Timesheet */}
            {details.types_of_email?.includes(
              "69 - CAC and BAC - Make Timesheet = Email to Client"
            )
              ? Make_Timesheet_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "70 - CAC and BAC - Make Timesheet = Email to Admin"
                )
              ? Make_Timesheet_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "71 - CAC and BAC - Make Timesheet = Email to Linguist"
                )
              ? Make_Timesheet_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "72 - CAC and BAC - Confirm Timesheet = Email to Admin"
                )
              ? Confirm_Timesheet_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End Timesheet */}

            {/* For Project Report */}
            {details.types_of_email?.includes(
              "73 - CAC and BAC - Make Project Report = Email to Client"
            )
              ? Make_ProjectReport_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "74 - CAC and BAC - Make Project Report = Email to Admin"
                )
              ? Make_ProjectReport_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "75 - CAC and BAC - Make Project Report = Email to Linguist"
                )
              ? Make_ProjectReport_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "76 - CAC and BAC - Confirm Project Report = Email to Admin"
                )
              ? Confirm_ProjectReport_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End Project Report */}

            {/* For Allocate job / Take job back / cancel job */}
            {details.types_of_email?.includes(
              "77 - Allocate Interpreting Job = Email to Admin/Client"
            )
              ? Allocate_Interpreting_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "78 - Allocate Interpreting Job = Email to Linguist"
                )
              ? Allocate_Interpreting_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "79 - Allocate Translation Job = Email to Admin/Client"
                )
              ? Allocate_Translation_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "80 - Allocate Translation Job = Email to Linguist"
                )
              ? Allocate_Translation_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "81 - Take Job - Interpreting or Translation = Email to Admin/Linguist/Client"
                )
              ? Take_Job_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "82 - Cancel Job - Interpreting or Translation = Email to Admin/Linguist/Client"
                )
              ? Cancel_Job_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* For Allocate job / Take job back / cancel job */}

            {/* For Job Advert */}
            {details.types_of_email?.includes(
              "83 - Job Advert - Send Bank Email - Email to Admin and Client"
            )
              ? Job_Advert_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : details.types_of_email?.includes(
                  "84 - Job Advert - Send Stripe Email - Email to Admin and Client"
                )
              ? Job_Advert_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End Job Advert */}

            {/* For Ask Question */}
            {details.types_of_email?.includes(
              "85 - Ask Question For All = Email to Admin"
            )
              ? Ask_Ques_Variable.map((st) => (
                  <Button color="purple">{st}</Button>
                ))
              : null}
            {/* End Ask Question */}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EmailSettings;
