import React, { Fragment } from "react";

// Custom Components
import EmployeeRegistration from "components/Auth/EmployeeRegistration/EmployeeRegistration";

const EmployeeRegistrationPage = () => {
  return (
    <Fragment>
      <EmployeeRegistration />
    </Fragment>
  );
};

export default EmployeeRegistrationPage;
