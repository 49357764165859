import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import CustomTextInput from "components/Common/CustomTextInput";
import { Button, Dimmer, Header, Loader, Table } from "semantic-ui-react";
import "./sigCanvas.css";
import moment from "moment";
import Signature from "./signature";
import history from "utils/history";
import { useMediaQuery } from "@material-ui/core";
const Details = () => {
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const matches = useMediaQuery("(max-width:768px)");
  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getTimeSheet = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/view-project-report/reportID/${id}`
        );
        setDetails(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    getTimeSheet();

    return () => {
      source.cancel();
    };
  }, []);

  return details ? (
    <div>
      <CustomTextInput
        label="Assignment reference number"
        value={
          details?.cacTranslationID?.id
            ? details?.cacTranslationID?.id
            : details?.bacTranslationID?.id
              ? details?.bacTranslationID?.id
              : details?.incTranslationID?.id
                ? details?.incTranslationID?.id
                : details?.prcTranslationID?.id
                  ? details?.prcTranslationID?.id
                  : details?.prcBookingID?.id
                    ? details?.prcBookingID?.id
                    : details?.prcDirectQuoteID?.id
                      ? details?.prcDirectQuoteID?.id
                      : details.linQuoteID?.quoteRequstByCashclient?.id
                        ? details.linQuoteID?.quoteRequstByCashclient?.id
                        : details.linQuoteID?.quoteRequstByAccountclient?.id
                          ? details.linQuoteID?.quoteRequstByAccountclient?.id
                          : details?.QuoteID
                            ? details?.QuoteID
                            : "N/A"
        }
      />

      {/* Table */}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center">Requested</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actual</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Project start date: </Table.Cell>

            <Table.Cell textAlign="center">
              {details.requested_start_date}
            </Table.Cell>

            <Table.Cell textAlign="center">
              {details.start_date &&
                moment(details.start_date).format("DD/MMM/YYYY")}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project start time: </Table.Cell>
            <Table.Cell textAlign="center">
              {details.requested_start_time}
            </Table.Cell>
            <Table.Cell textAlign="center">{details.start_time}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project completion date: </Table.Cell>
            <Table.Cell textAlign="center">
              {details.requested_end_date}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details.end_date &&
                moment(details.end_date).format("DD/MMM/YYYY")}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project end time: </Table.Cell>
            <Table.Cell textAlign="center">
              {details.requested_end_time}
            </Table.Cell>
            <Table.Cell textAlign="center">{details.end_time}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell textAlign="center">
              {details?.cacTranslationID?.target_expertise_level_required
                ? details?.cacTranslationID?.target_expertise_level_required
                : details?.bacTranslationID?.target_expertise_level_required
                  ? details?.bacTranslationID?.target_expertise_level_required
                  : details?.incTranslationID?.target_language_skill_level
                    ? details?.incTranslationID?.target_language_skill_level
                    : details?.prcTranslationID?.target_linguist_skill_level
                      ? details?.prcTranslationID?.target_linguist_skill_level
                      : details?.prcBookingID?.target_expertise_level_required
                        ? details?.prcBookingID?.target_expertise_level_required
                        : details.linQuoteID?.quoteRequstByCashclient
                          ?.target_skills_required
                          ? details.linQuoteID?.quoteRequstByCashclient
                            ?.target_skills_required
                          : details.linQuoteID?.quoteRequstByAccountclient
                            ?.target_skills_required
                            ? details.linQuoteID?.quoteRequstByAccountclient
                              ?.target_skills_required
                            : details.directQuoteID?.skill_level
                              ? details.directQuoteID?.skill_level
                              : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.postedByLin?.target_language_level
                ? details?.postedByLin?.target_language_level
                : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Security clearance/s</Table.Cell>
            <Table.Cell textAlign="center">
              {details?.linQuoteID?.quoteRequstByCashclient?.security_clearance
                ? details?.linQuoteID?.quoteRequstByCashclient
                  ?.security_clearance
                : details?.linQuoteID?.quoteRequstByAccountclient
                  ?.security_clearance
                  ? details?.linQuoteID?.quoteRequstByAccountclient
                    ?.security_clearance
                  : details?.cacTranslationID?.security_clearance
                    ? details?.cacTranslationID?.security_clearance
                    : details?.bacTranslationID?.security_clearance
                      ? details?.bacTranslationID?.security_clearance
                      : details?.incTranslationID?.security_clearance
                        ? details?.incTranslationID?.security_clearance
                        : details?.prcTranslationID?.security_clearance
                          ? details?.prcTranslationID?.security_clearance
                          : details?.prcBookingID?.security_clearance
                            ? details?.prcBookingID?.security_clearance
                            : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.security_clearence
                ? details?.security_clearence
                : details?.postedByLin?.title_security_clearance_1
                  ? details?.postedByLin?.title_security_clearance_1
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Wordcount</Table.Cell>
            <Table.Cell textAlign="center">
              {details?.requested_wordCount == "undefined"
                ? "N/A"
                : details?.requested_wordCount
                  ? details?.requested_wordCount
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.word_count == "undefined"
                ? "N/A"
                : details?.word_count
                  ? details?.word_count
                  : details?.cacTranslationID?.wordCount
                    ? details?.cacTranslationID?.wordCount
                    : details?.bacTranslationID?.wordCount
                      ? details?.bacTranslationID?.wordCount
                      : details?.incTranslationID?.count
                        ? details?.incTranslationID?.count
                        : details?.prcTranslationID?.count
                          ? details?.prcTranslationID?.count
                          : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Visual formatting</Table.Cell>
            <Table.Cell textAlign="center">
              {details?.visual_formatting == "undefined"
                ? "N/A"
                : details?.visual_formatting
                  ? details?.visual_formatting
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.visual_formatting == "undefined"
                ? "N/A"
                : details?.visual_formatting
                  ? details?.visual_formatting
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Certification</Table.Cell>
            <Table.Cell textAlign="center">
              {details?.certification == "undefined"
                ? "N/A"
                : details?.certification
                  ? details?.certification
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.certification == "undefined"
                ? "N/A"
                : details?.certification
                  ? details?.certification
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Post certification </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.post_certification == "undefined"
                ? "N/A"
                : details?.post_certification
                  ? details?.post_certification
                  : "N/A"}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details?.post_certification == "undefined"
                ? "N/A"
                : details?.post_certification
                  ? details?.post_certification
                  : "N/A"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">Project Report</Header>

      <p>
        {details.project_report == "undefined" ? "N/A" : details.project_report}
      </p>

      <Signature details={details} />

      {history.location.pathname.includes("/admin") ? (
        <div
          style={{
            display: matches ? "block" : "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/admin/view-project-report-document-by-linguist/${id}`
              )
            }
            color="purple"
          >
            View Document (LIN){" "}
          </Button>
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/admin/view-project-report-document-by-client/${id}`
              )
            }
            color="purple"
          >
            View Document (Client){" "}
          </Button>
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(`/admin/view-project-report-document-by-admin/${id}`)
            }
            color="purple"
          >
            View Document (Admin){" "}
          </Button>
        </div>
      ) : history.location.pathname.includes("/linguist") ? (
        <div
          style={{
            display: matches ? "block" : "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/linguist/view-project-report-document-by-linguist/${id}`
              )
            }
            color="purple"
          >
            View Document (LIN){" "}
          </Button>
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/linguist/view-project-report-document-by-client/${id}`
              )
            }
            color="purple"
          >
            View Document (Client){" "}
          </Button>
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/linguist/view-project-report-document-by-admin/${id}`
              )
            }
            color="purple"
          >
            View Document (Admin){" "}
          </Button>
        </div>
      ) : history.location.pathname.includes("/cash-client") ? (
        <div
          style={{
            display: matches ? "block" : "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/cash-client/view-project-report-document-by-linguist/${id}`
              )
            }
            color="purple"
          >
            View Document (LIN){" "}
          </Button>
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/cash-client/view-project-report-document-by-client/${id}`
              )
            }
            color="purple"
          >
            View Document (Client){" "}
          </Button>
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/cash-client/view-project-report-document-by-admin/${id}`
              )
            }
            color="purple"
          >
            View Document (Admin){" "}
          </Button>
        </div>
      ) : history.location.pathname.includes("/account-client") ? (
        <div
          style={{
            display: matches ? "block" : "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/account-client/view-project-report-document-by-linguist/${id}`
              )
            }
            color="purple"
          >
            View Document (LIN){" "}
          </Button>
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/account-client/view-project-report-document-by-client/${id}`
              )
            }
            color="purple"
          >
            View Document (Client){" "}
          </Button>
          <Button
            style={{
              marginBottom: matches ? "5px" : "0px",
              width: matches ? "100%" : "auto",
            }}
            onClick={() =>
              history.push(
                `/account-client/view-project-report-document-by-admin/${id}`
              )
            }
            color="purple"
          >
            View Document (Admin){" "}
          </Button>
        </div>
      ) : null}
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="large">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
