import React, { useState, useRef, useEffect } from "react";

// Material Core Components
import Typography from "@material-ui/core/Typography";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Header, Button } from "semantic-ui-react";
import axios from "axios";
import swal from "sweetalert";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";

const PostLinguistSliders = () => {
  const classes = useEmployeeStyles();

  const fileInput = useRef(null);
  const [load, setLoad] = useState(false);
  const [photo, setPhoto] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!photo) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(photo);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [photo]);

  const handlePhotoFiles = (e) => {
    setPhoto(e.target.files[0]);
  };
  const handlePhotoClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const postSlider = async (e) => {
    let fd = new FormData();
    setLoad(true);
    fd.append("slider", photo);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/post-public-profile-slider/SPPO`,
        fd
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      setPhoto(null);
      setPreview(null);
      setLoad(false);
    } catch (error) {
      swal({
        icon: "error",
        text: `${error.response?.data}`,
        buttons: false,
      });
      setLoad(false);
    }
  };

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  Post Sppo Slider
                </Typography>
              </div>
              <br />
              <Header style={{ margin: "0" }} as="h5">
                {" "}
                Upload Photo *
              </Header>
              <input
                onChange={handlePhotoFiles}
                accept="image/*"
                name="photo"
                width="100%"
                required
                ref={fileInput}
                type="file"
                style={{ display: "none" }}
                multiple
              />
              <br />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Button onClick={handlePhotoClick}>Upload</Button>
                </div>
                {photo && (
                  <Header style={{ margin: "0", textAlign: "center" }} as="h5">
                    <Header style={{ margin: "0" }} as="h1">
                      {" "}
                      <img src={preview} alt="slider thumbnail" />
                    </Header>
                    {photo.name}
                    <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                      photo.size / 1024
                    ).toFixed(2)}kb`}</Header>
                    <Header
                      style={{ margin: "0" }}
                      as="h5"
                    >{`Last Modified: ${photo?.lastModifiedDate?.toDateString()}`}</Header>
                  </Header>
                )}
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  loading={load}
                  color="purple"
                  disabled={!photo}
                  onClick={postSlider}
                >
                  Submit
                </Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default PostLinguistSliders;
