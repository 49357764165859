import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Components
import Booking from "components/Admin/ControlPanel/CompanyCard/CIC/Booking";
import HR from "components/Admin/ControlPanel/CompanyCard/CIC/HR";
import Report from "components/Admin/ControlPanel/CompanyCard/CIC/Report";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const Chat = () => {
  const classes = useDashboardStyles();
  return (
    <LinguistLayout>
      <Linguist>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto' }}>


          <div style={{ display: "flex", justifyContent: 'center' }}>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>Booking Secretary / Admin</p>
                </CardHeader>
                <CardBody>
                  <Booking />
                </CardBody>
              </Card>
            </GridItem>
          </div>

          <div style={{ display: "flex", justifyContent: 'center' }}>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>HR Team</p>
                </CardHeader>
                <CardBody>
                  <HR />
                </CardBody>
              </Card>
            </GridItem>
          </div>




          <div style={{ display: "flex", justifyContent: 'center' }}>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>Technical Support</p>
                </CardHeader>
                <CardBody>
                  <Report />
                </CardBody>
              </Card>
            </GridItem>
          </div>
        </div>
      </Linguist>
    </LinguistLayout>
  );
};

export default Chat;
