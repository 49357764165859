import React, { Fragment } from "react";

// Custom Components
import ViewQRLinguists from "components/Auth/ViewQRLinguists/QuoteRqst";

const ViewQRLinguistsPage= () => {
  return (
    <Fragment>
      <ViewQRLinguists />
    </Fragment>
  );
};

export default ViewQRLinguistsPage
