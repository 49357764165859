import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import CustomAvatar from 'components/Common/CustomAvatar';
import React, {useEffect, useState} from 'react'
import useEmployeeStyles from 'assets/jss/components/employeeStyle';
import { useSelector } from 'react-redux';
import defaultAxios from "utils/axios";
import history from 'utils/history';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import swal from "sweetalert";
import Cookies from 'js-cookie'

import UKGBPAccount from './UKGBPAccount'
import EUROAccount from './EUROAccount'
import CADAccount from './CADAccount'
import AUSDAccount from './AUSDAccount'
import TuriskaLira from './TuriskaLira'
import SDAccount from './SDAccount'
import USDAccount from './USDAccount'
import Hungarian from './Hungarian'
import Newzealand from './Newzealand'
import Romanian from './Romanian'

const JASummary = () => {

    const classes = useEmployeeStyles();

    return (
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
            <Card>
                <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                    <CustomAvatar />
                    <Typography component="h1" variant="h5">
                        View Bank Details
                    </Typography>
                </div>
               
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>UK GBP Account</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <UKGBPAccount/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Euro Account </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <EUROAccount/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Canadian Dollar Account  </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CADAccount/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Australian Dollar Account  </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AUSDAccount/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Turkish Lira  </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TuriskaLira/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Singapore Dollar</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SDAccount/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>US Dollar</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <USDAccount/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Hungarian Forint</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Hungarian/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>New Zealand Dollar</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Newzealand/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>New Romanian leu</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Romanian/>
                  </AccordionDetails>
                </Accordion>
               
                </CardBody>
            </Card>
            </GridItem>
        </GridContainer>
      </div>
    )
}

export default JASummary
