import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from "@material-ui/core";
import history from 'utils/history'

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import Form from "components/Admin/CrmPanel/CAC/CACRegistration/CACRegistration";



const AddCashClient = () => {
  const classes = useDashboardStyles();
  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }} color="primary">
                <p className={classes.cardTitleWhite}>Add New Cash Client</p>
                <IconButton color='inherit' onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
              </CardHeader>
              <CardBody>
                <Form />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default AddCashClient;
