import React, { Fragment } from "react";

// Custom Components
import InitialRegistration from "components/Auth/LCJoining/Registration/InitialRegistration";

const InitialRegistrationPage = () => {
  return (
    <Fragment>
      <InitialRegistration />
    </Fragment>
  );
};

export default InitialRegistrationPage;
