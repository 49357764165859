import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import Axios from "utils/axios";
import swal from "sweetalert";
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomCaptcha from "components/Common/CustomCaptcha";
import { Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import { updateCrStep, changeCrInput } from "redux/actions/crAction";
import CustomPhone from "components/Common/CustomPhone";
import { EMAIL_REGEX } from "data";

const FormOne = ({ mainobjID, userID }) => {
  const { data } = useSelector((state) => state.crReducer);
  const [notValid, setNotValid] = useState(false);
  const [notBValid, setNotBValid] = useState(false);
  const [captcha, setCaptcha] = useState("");

  const {
    FirstName,
    LastName,
    email,
    business_email,
    mobile_no,
    business_phone_no,
    business_name,
    title,
    gdpr,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
  };

  const handlegdprChange = (e, target) => {
    const inputData = {
      name: "gdpr",
      value: target,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleBusinessEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotBValid(false);
    } else {
      setNotBValid(true);
    }
  };

  const handleMobile = (value) => {
    dispatch(
      changeCrInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handlePhone = (value) => {
    dispatch(
      changeCrInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
    const isHuman = await fetch(
      `https://www.google.com/recaptcha/api/siteverify`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
        mode: "no-cors",
        credentials: "omit",
        body: `secret=6LdPUwMaAAAAAK7JyeaYyRPz4NeNyC2XoQthvK8h&response=6LdPUwMaAAAAALqroyySr9kV-TXA7sxKx2qzvSnC`,
      }
    );
  };

  const handleNext = async (e) => {
    let formData = new FormData();

    formData.append("title", title || "");
    formData.append("FirstName", FirstName || "");
    formData.append("LastName", LastName || "");
    formData.append("email", email || "");
    formData.append("business_email", business_email || "");
    formData.append("mobile_no", mobile_no || "");
    formData.append("business_phone_no", business_phone_no || "");
    formData.append("business_name", business_name || "");
    formData.append("refID", mainobjID || "");
    formData.append("userID", userID || "");

    try {
      const response = await Axios.post("/api/create/CLA", formData);
      swal({
        title: "Success!",
        text: `Lead successfully added`,
        icon: "success",
        buttons: "Ok",
      }).then(() => {
        window.location = "/";
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const isAllow =
    !FirstName ||
    !LastName ||
    !email ||
    !business_phone_no ||
    !business_name ||
    !captcha ||
    !gdpr;

  return (
    <div>
      <h4 style={{ textAlign: "center", paddingTop: "5px" }}>
        Personal or Company Legal Representative Details
      </h4>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          value={title}
          name="title"
          label="Title"
          width="100%"
          placeholder="e.g. Mr, Mrs, Miss, Ms etc"
        />
        <CustomInputSingle
          onChange={handleChange}
          value={FirstName}
          name="FirstName"
          label="First Name(s)"
          width="100%"
          required
          placeholder="First Name(s) (Legal representative/director)"
        />
        <CustomInputSingle
          onChange={handleChange}
          value={LastName}
          name="LastName"
          label="Last Name"
          width="100%"
          required
          placeholder="Last Name (Legal representative/director)"
        />
        <CustomBigInputSingle
          onChange={handleEmailChange}
          value={business_email}
          name="business_email"
          label="Direct / Personal Email"
          moreInfo="If different"
          width="100%"
          placeholder="e.g. example@example.com"
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <br />
        <br />
        <CustomPhone
          onChange={handleMobile}
          value={mobile_no}
          name="mobile_no"
          placeholder="Mobile Number (Please select country then type number e.g. +44 7307 000000)"
        />

        <CustomInputSingle
          onChange={handleChange}
          value={business_name}
          name="business_name"
          label="Business or organisation name"
          width="100%"
          required
          placeholder="Trading name of your business / organisation. Otherwise please write N/A"
        />

        <CustomBigInputSingle
          onChange={handleBusinessEmailChange}
          value={email}
          name="email"
          label="Primary / Business email"
          moreInfo="If different"
          width="100%"
          required
          placeholder="e.g. example@example.com"
        />
        {notBValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <br />

        <br />
        <CustomPhone
          onChange={handlePhone}
          value={business_phone_no}
          name="business_phone_no"
          required
          placeholder="Business Phone Number* (Please select country then type number e.g. +44 7307 000000)"
        />

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handlegdprChange}
                checked={gdpr}
                name="gdpr"
                color="primary"
              />
            }
          />
          I understand that my data will be saved by the Agent Linguist or SPPO
          and Linguists Collective. By submitting this form I agree with LC
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/privacy-policy"
          >
            {" "}
            Privacy Policy{" "}
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/terms-and-conditions"
          >
            {" "}
            T&C
          </a>
          <CustomCaptcha onChange={handleCaptcha} />
        </div>

        <div className={classes.buttonFlex}>
          <CustomButton
            text="Submit"
            onClick={handleNext}
            disabled={isAllow || notValid}
          />
        </div>
      </form>
    </div>
  );
};

export default FormOne;
