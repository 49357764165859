import { useState, Fragment, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Axios from "utils/axios";
import CustomSelect from "components/Common/CustomSelect";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import {
  LANG_COMB,
  SKILL_LEVEL_REQUIRED,
  COUNTRY_DATA,
  ITP_SERVICE_REQUIRED,
  SECURITY_CLEARANCE_LEVEL,
  TYPE_INTERPRETING,
  DOCUMENT_TYPE,
} from "data";
import CustomDate from "components/Common/CustomDate";
import CustomDateTime from "components/Common/CustomDateTime";
import CustomPhone from "components/Common/CustomPhone";

// Material
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";

const EditableText = ({
  text,
  readOnly,
  name,
  id,
  lang,
  exp,
  setReload,
  date,
  phone,
  country,
  type,
  interpretingType,
  security,
  service,
  docType,
  datetime,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [value, setValue] = useState(text);
  const [inputName, setInputName] = useState();
  const [loading, setLoading] = useState(false);
  const base = "manager-admin";
  useEffect(() => {
    setValue(text);
  }, [text]);
  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const handleCheck = async () => {
    try {
      const fd = new FormData();
      fd.append(inputName, value);
      if (!inputName) {
        setIsClicked(!isClicked);
        return false;
      }
      setLoading(true);
      if (type === "cacassignment") {
        await Axios.put(
          `/api/${base}/amend-interpreting-CAC/bookingID/${id}`,
          fd
        );
      } else if (type === "bacassignment") {
        await Axios.put(
          `/api/${base}/amend-interpreting-BAC/bookingID/${id}`,
          fd
        );
      } else if (type === "cactrans") {
        await Axios.put(
          `/api/${base}/amend-translation-CAC/translationID/${id}`,
          fd
        );
      } else if (type === "bactrans") {
        await Axios.put(
          `/api/${base}/amend-translation-BAC/translationID/${id}`,
          fd
        );
      } else if (type === "incassignment") {
        await Axios.put(
          `/api/${base}/amend-interpreting-INC/bookingID/${id}`,
          fd
        );
      } else if (type === "inctrans") {
        await Axios.put(
          `/api/${base}/amend-translation-INC/translationID/${id}`,
          fd
        );
      } else if (type === "prcassignment") {
        await Axios.put(
          `/api/${base}/amend-interpreting-PRC/bookingID/${id}`,
          fd
        );
      } else if (type === "prctrans") {
        await Axios.put(
          `/api/${base}/amend-translation-PRC/translationID/${id}`,
          fd
        );
      } else {
        await Axios.put(`/api/manager-admin/edit-jobadvert/jobID/${id}`, fd);
      }
      setIsClicked(!isClicked);
      setReload(Math.random());
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setInputName(e.target.name);
  };
  const handleSecurityChange = (e, value) => {
    setValue(value);
    setInputName("security_clearance");
  };
  const handleDocTypeChange = (e, value) => {
    setValue(value);
    setInputName("document_type");
  };
  const handleDateChange = (date) => {
    console.log(date);
    setValue(date);
    setInputName(name);
  };
  const handleDateTimeChange = (e) => {
    setValue(e.target.value);
    setInputName(name);
  };
  const handleMobile = (value) => {
    setValue(value);
    setInputName(name);
  };

  return (
    <span
      style={{
        display: isClicked && "flex",
        justifyContent: isClicked && "flex-start",
        alignItems: isClicked && "center",
        flexWrap: isClicked && "wrap",
      }}
    >
      {isClicked ? (
        lang ? (
          <CustomSelect
            onChange={handleChange}
            data={LANG_COMB}
            value={value}
            name={name}
            width="80%"
          />
        ) : exp ? (
          <CustomSelect
            onChange={handleChange}
            data={SKILL_LEVEL_REQUIRED}
            value={value}
            name={name}
            width="80%"
          />
        ) : country ? (
          <CustomSelect
            onChange={handleChange}
            data={COUNTRY_DATA}
            value={value}
            name={name}
            width="80%"
          />
        ) : date ? (
          <CustomDate
            onChange={handleDateChange}
            value={value}
            name={name}
            width="80%"
            format="yyyy-MM-dd"
          />
        ) : datetime ? (
          <CustomDateTime
            onChange={handleDateTimeChange}
            value={value}
            name={name}
            width="80%"
          />
        ) : phone ? (
          <CustomPhone
            onChange={handleMobile}
            value={value}
            name={name}
            width="80%"
          />
        ) : interpretingType ? (
          <CustomSelect
            value={value}
            onChange={handleChange}
            name={name}
            id="outlined-basic"
            placeholder={text}
            variant="outlined"
            data={TYPE_INTERPRETING}
            width="80%"
          />
        ) : service ? (
          <CustomSelect
            value={value}
            onChange={handleChange}
            name={name}
            id="outlined-basic"
            placeholder={text}
            variant="outlined"
            data={ITP_SERVICE_REQUIRED}
            width="80%"
          />
        ) : security ? (
          <CustomAutoSelect
            onChange={handleSecurityChange}
            data={SECURITY_CLEARANCE_LEVEL}
            value={value}
            name={name}
            id="outlined-basic"
            placeholder={text}
            variant="outlined"
            width="80%"
          />
        ) : docType ? (
          <CustomAutoSelect
            onChange={handleDocTypeChange}
            data={DOCUMENT_TYPE}
            value={value}
            name={name}
            id="outlined-basic"
            placeholder={text}
            variant="outlined"
            width="80%"
          />
        ) : (
          <TextField
            value={value}
            onChange={handleChange}
            name={name}
            id="outlined-basic"
            placeholder={text}
            variant="outlined"
          />
        )
      ) : (
        <span>{text}</span>
      )}{" "}
      {!readOnly ? (
        <Fragment>
          {!isClicked ? (
            <EditIcon
              color="primary"
              onClick={handleClick}
              style={{
                cursor: "pointer",
                fontSize: "1.2rem",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <Fragment>
              {loading ? (
                <CircularProgress
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              ) : (
                <CheckIcon
                  color="secondary"
                  onClick={handleCheck}
                  style={{
                    cursor: "pointer",
                    fontSize: "2rem",
                    verticalAlign: "middle",
                    color: "green",
                  }}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      ) : null}{" "}
    </span>
  );
};

export default EditableText;
