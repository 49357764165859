import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
// Custom Styles
import ViewAttach from "components/Admin/ManagementPanel/ViewAttachMessage";

const ViewContactCard = () => {
  return (
    <EmployeeLayout>
      <Employee>
        <ViewAttach />
      </Employee>
    </EmployeeLayout>
  );
};

export default ViewContactCard;
