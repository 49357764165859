import React from 'react'
import { Table } from 'semantic-ui-react'
import Action from './Actions/Action'

const SavedBill = () => {
    return (
      <Table selectable>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell>Invoice no.</Table.HeaderCell>
            <Table.HeaderCell>Date of invoice</Table.HeaderCell>
            <Table.HeaderCell>Total number of invoices</Table.HeaderCell>
            <Table.HeaderCell>Total bill in GBP</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
        <Table.Row>
            <Table.Cell>BAC123</Table.Cell>
            <Table.Cell>September 14, 2013</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>100</Table.Cell>
            <Table.Cell>No</Table.Cell>
            <Table.Cell><Action /></Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>BAC124</Table.Cell>
            <Table.Cell>September 16, 2013</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>100</Table.Cell>
            <Table.Cell>No</Table.Cell>
            <Table.Cell><Action /></Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>BAC125</Table.Cell>
            <Table.Cell>September 17, 2013</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>160</Table.Cell>
            <Table.Cell>No</Table.Cell>
            <Table.Cell><Action /></Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>BAC126</Table.Cell>
            <Table.Cell>September 18, 2013</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>500</Table.Cell>
            <Table.Cell>No</Table.Cell>
            <Table.Cell><Action /></Table.Cell>
        </Table.Row>
        </Table.Body>
      </Table>
    )
}

export default SavedBill
