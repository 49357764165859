import React, { Fragment } from "react";

import AdminLogin from "components/Admin/Login/AdminLogin";

const ADMLoginPage = () => {
  return (
    <Fragment>
      <AdminLogin />
    </Fragment>
  );
};

export default ADMLoginPage;
