import React, { useEffect, useState } from "react";

//Material imports
import { Button, Divider, FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import swal from "sweetalert";

//Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomPhone from "components/Common/CustomPhone";
//Custom imports
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomTextInput from "components/Common/CustomTextInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// //Material Icon
// import EditIcon from '@material-ui/icons/Edit';

import { withStyles } from "@material-ui/styles";
import Axios from "axios";
import CustomSnackBar from "components/Common/CustomSnackBar";
import { useDispatch, useSelector } from "react-redux";
import {
  changeContactCardInput,
  updateContactData,
} from "redux/actions/contactCardAction";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "purple",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "lightgray",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CompanyCard = () => {
  const classes = useEmployeeStyles();
  const [edit, setEdit] = useState(false);
  const [contactData, setContactData] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const { data } = useSelector((data) => data.contactCardReducer);
  const dispatch = useDispatch();

  const getCompanyCard = async (e) => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/view/company-information-card`
    );
    setContactData(response?.data[0]);
    dispatch(updateContactData(response?.data[0]));
  };

  useEffect(() => {
    getCompanyCard();
  }, []);

  const handleEdit = (e) => {
    setEdit(!edit);
  };

  const handleChange = (e) => {
    // setState({...data, [e.target.name] : e.target.value})
    const { name, value } = e.target;
    dispatch(
      changeContactCardInput({
        name,
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeContactCardInput({
        name: "company_phone_no",
        value: value,
      })
    );
  };

  const handleSubmit = async (e) => {
    if (!data._id) {
      try {
        const response = Axios.post(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/company-information-card`,
          data
        );
        swal({
          //title: "Success",
          text: `Successfully Updated`,
          icon: "success",
          buttons: false,
        }).then(function () {
          window.location = "/admin/contact-form";
        });
      } catch (error) {
        // Copy from other catch's code
        if (error.response) {
          swal({
            title: "Oops!",
            text: `${
              error.response
                ? error?.response?.data?.error
                : "Something Went Wrong"
            }`,
            icon: "error",
            buttons: false,
          });
        }
      }
    } else {
      try {
        const response = Axios.put(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/edit/company-information-card/${data._id}`,
          data
        );
        swal({
          //title: "Success",
          text: `Successfully Updated`,
          icon: "success",
          buttons: false,
        }).then(function () {
          window.location = "/admin/contact-form";
        });
      } catch (error) {
        // Copy from other catch's code
        if (error.response) {
          swal({
            title: "Oops!",
            text: `${
              error.response
                ? error?.response?.data?.error
                : "Something Went Wrong"
            }`,
            icon: "error",
            buttons: false,
          });
        }
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <>
      <CustomSnackBar
        message={message}
        openSnackBar={openSnackBar}
        handleClose={handleClose}
      />
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
            <Card>
              <CardBody>
                <div className={`${classes.title}`}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#b19cd9",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "1.5rem",
                        flex: "1",
                        margin: "0",
                      }}
                    >
                      Company Contact Info Card
                    </p>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={edit}
                          onChange={handleEdit}
                          name="checkedB"
                        />
                      }
                      label="Edit"
                    />
                  </div>

                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="company_name"
                    value={data?.company_name ? data.company_name : "N/A"}
                    label="Company name: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    multiline
                    name="company_address"
                    value={data?.company_address}
                    label="Company address:"
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    number={true}
                    name="company_phone_no"
                    value={data?.company_phone_no}
                    label="Phone number:"
                  />
                  {/* <CustomPhone
                    edit={edit}
                    label="Phone number"
                    onChange={handleMobile}
                    placeholder='Phone number'
                    value={data?.company_phone_no}
                    name='company_phone_no'
                  /> */}
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="company_general_email"
                    value={data?.company_general_email}
                    label="General email:"
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    number={true}
                    name="company_registration_no"
                    value={data?.company_registration_no}
                    label="Company registration number: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="company_legal_name"
                    value={data?.company_legal_name}
                    label="Company legal name: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="vat_number"
                    value={data?.vat_number}
                    label="VAT / GST number: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="vat_country_name"
                    value={data?.vat_country_name}
                    label="VAT country name: "
                  />

                  <Divider />

                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    number={true}
                    name="company_emergency_mobile_no"
                    value={data?.company_emergency_mobile_no}
                    label="Emergency mobile number:"
                  />

                  <Divider />

                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "1.25rem",
                    }}
                  >
                    Booking Secretary and Admin Team
                  </p>
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="booking_secretary_name"
                    value={data?.booking_secretary_name}
                    label="Manager / personnel name: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="booking_secretary_directdial"
                    value={data?.booking_secretary_directdial}
                    label="Direct dial: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    number={true}
                    name="booking_secretary_no"
                    value={data?.booking_secretary_no}
                    label="Booking secretary number:"
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="booking_secretary_email"
                    value={data?.booking_secretary_email}
                    label="Booking secretary email: "
                  />

                  <Divider />

                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "1.25rem",
                    }}
                  >
                    CRM / Admin /Customer Service Team
                  </p>
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="client_support_name"
                    value={data?.client_support_name}
                    label="Manager / personnel name: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="client_support_directdial"
                    value={data?.client_support_directdial}
                    label="Direct dial: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    number={true}
                    name="client_relation_team_member"
                    value={data?.client_relation_team_member}
                    label="Client relation team number: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="client_support_email"
                    value={data?.client_support_email}
                    label="Client support email: "
                  />

                  <Divider />

                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "1.25rem",
                    }}
                  >
                    Sales and Marketing Team
                  </p>
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="sales_and_marketing_name"
                    value={data?.sales_and_marketing_name}
                    label="Manager / personnel name: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="sales_and_marketing_directdial"
                    value={data?.sales_and_marketing_directdial}
                    label="Direct dial: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    number={true}
                    name="sales_and_marketing_team_member"
                    value={data?.sales_and_marketing_team_member}
                    label="Sales and marketing team number: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="sales_and_marketing_email"
                    value={data?.sales_and_marketing_email}
                    label="Sales and marketing email: "
                  />

                  <Divider />

                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "1.25rem",
                    }}
                  >
                    HR Team
                  </p>
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="recruitment_name"
                    value={data?.recruitment_name}
                    label="Manager / personnel name: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="recruitment_directdial"
                    value={data?.recruitment_directdial}
                    label="Direct dial: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="hr_team_member"
                    value={data?.hr_team_member}
                    label="HR team number: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="linguist_support_email"
                    value={data?.linguist_support_email}
                    label="Linguist support email: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    number={true}
                    name="recruitment_number"
                    value={data?.recruitment_number}
                    label="Recruitment number: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="recruitment_email"
                    value={data?.recruitment_email}
                    label="Recruitment email: "
                  />

                  <Divider />

                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "1.25rem",
                    }}
                  >
                    Account, Finance and Invoicing Team
                  </p>
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="account_department_name"
                    value={data?.account_department_name}
                    label="Manager / personnel name: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="account_department_directdial"
                    value={data?.account_department_directdial}
                    label="Direct dial: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    number={true}
                    name="account_department_number"
                    value={data?.account_department_number}
                    label="Account department number: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="account_and_invoice_support_email"
                    value={data?.account_and_invoice_support_email}
                    label="Account and invoice support email: "
                  />

                  <Divider />

                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "1.25rem",
                    }}
                  >
                    Compliance Team
                  </p>
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="complience_name"
                    value={data?.complience_name}
                    label="Manager / personnel name: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="complience_directdial"
                    value={data?.complience_directdial}
                    label="Direct dial: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="complience_officer"
                    value={data?.complience_officer}
                    label="Compliance Officer: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="documentation_and_verification_officer"
                    value={data?.documentation_and_verification_officer}
                    label="Documentation and Verification Officer: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="data_controller"
                    value={data?.data_controller}
                    label="Data controller: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="amlro"
                    value={data?.amlro}
                    label="Anti-Money Laundering Reporting Officer (AMLRO): "
                  />

                  <Divider />

                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="webmaster_report_system_issue"
                    value={data?.webmaster_report_system_issue}
                    label="Webmaster / Report system issue: "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="webmaster_directdial"
                    value={data?.webmaster_directdial}
                    label="Urgent Enquiry: "
                  />

                  <Divider />

                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "1.25rem",
                    }}
                  >
                    Service Charge
                  </p>
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="platform_booking_service_charge"
                    value={data?.platform_booking_service_charge}
                    label="Platform and Booking Service Charge(%): "
                  />
                  <CustomTextInput
                    onChange={handleChange}
                    edit={edit}
                    name="billing_account_service_charge"
                    value={data?.billing_account_service_charge}
                    label="Billing Account Service Charge(%): "
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "24px",
                      marginBottom: "24px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Ok!
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
};

export default CompanyCard;
