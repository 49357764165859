import styled from "styled-components";

export const CommonDiv = styled.div`
  height: 400px;
  overflow-y: scroll;
  .item {
    font-size: 18px;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 5px 10px !important;
  }
  .item.active {
    background: rgba(0, 0, 0, 0.1);
  }
`;
