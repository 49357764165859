import React from "react";
import { Card, Icon, Image } from "semantic-ui-react";
import GridItem from "components/Grid/GridItem";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import moment from "moment";

const CardExampleCard = ({ values }) => {
  const classes = useEmployeeStyles();

  return (
    <>
      {values.length > 0 ? (
        <>
          {values?.map((list) => (
            <>
              {moment(list?.events_date) > moment() ||
                moment(list?.events_date) == moment() ? (
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <Card>
                    {/* image size 290 x 217 => width="290" height="217" */}
                    {/* {list?.banner ? */}
                    <Image
                      src={`${process.env.REACT_APP_BACKEND_API}/get-events-banner/eventID/${list?._id}`}
                      size="medium"
                      centered
                    />
                    {/* : null} */}
                    <Card.Content>
                      <Card.Header>{list?.subject}</Card.Header>

                      <Card.Description>
                        <span className="date">
                          {list?.CAC_check == "yes" ? "CAC " : null}
                          {list?.BAC_check == "yes" ? "BAC " : null}
                          {list?.LIN_check == "yes" ? "LIN " : null}
                          {list?.EMP_check == "yes" ? "EMP " : null}
                          {list?.SPPO_check == "yes" ? "SPPO " : null}
                          {list?.INDIVIDUAL_check == "yes"
                            ? "INDIVIDUAL "
                            : null}
                          {list?.PUBLIC_check == "yes" ? "PUBLIC" : null}
                        </span>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <Icon name="calendar alternate outline" />{" "}
                      {moment(list?.events_date).format("DD-MMM-YYYY")}{" "}
                      <Icon name="time" />{" "}
                      {moment(list?.events_date).format("HH:mm")}
                    </Card.Content>
                    <Card.Content extra>
                      <Icon name="map marker alternate" />
                      Time Zone {list?.timezone}
                    </Card.Content>

                    <Card.Content extra>
                      <Icon name="signup" />
                      <a
                        target="_self"
                        href={`/event-member-registration/${list?._id}`}
                      >
                        {" "}
                        Registration/RSVP Respond please
                      </a>
                    </Card.Content>
                    <Card.Content extra>
                      <Icon name="eye" />
                      <a
                        target="_self"
                        href={`/event-member-registration/${list?._id}`}
                      >
                        {" "}
                        View Details
                      </a>
                    </Card.Content>
                    <Card.Content extra>
                      <Icon name="location arrow" />{" "}
                      <span className="date">{list?.venue_address}</span>
                    </Card.Content>
                    <Card.Content extra>
                      <Icon name="video" />{" "}
                      <a target="_self" href={list?.remote_link}>
                        Remote Joining Link (if available){" "}
                      </a>
                    </Card.Content>
                  </Card>

                  <br />
                </GridItem>
              ) : null}{" "}
            </>
          ))}{" "}
        </>
      ) : (
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card>
            <Card.Content>
              <Card.Header>International Mother Language Day Celebration and LC Virtual Conference
              </Card.Header>

              <Card.Description>
                <span className="date">
                  PUBLIC
                </span>
              </Card.Description>
            </Card.Content>

            <Card.Content extra>
              <Icon name="calendar alternate outline" />
              21 February 2023
              <Icon name="time" />
              18:30 Hrs London Time

            </Card.Content>

            <Card.Content extra>
              <Icon name="map marker alternate" />
              Time Zone London Time
            </Card.Content>

            <Card.Content extra>
              <Icon name="signup" />
              To register please email admin@linguistscollective.com

            </Card.Content>

            <Card.Content extra>
              <Icon name="video" />
              Zoom Meeting ID: 833 8862 2313
              Passcode: LCcon2022
            </Card.Content>
          </Card>
        </GridItem>
      )}
    </>
  );
};

export default CardExampleCard;
