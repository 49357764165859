import React, { useState } from "react";
import Axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import swal from "sweetalert";
import { Checkbox, Dimmer, Loader, Table } from "semantic-ui-react";


const AllocateAccess = ({ id }) => {
  const token = Cookies.get("token");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);


  const handleFindUnregLin = (value) => {
    if (data.access) {
      if (!data.unregLinFindAccess) {
        swal(
          "Are you sure you want to enable this find unregistered linguist section?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              EnableunregLinFindAccess(value);

            default:
              break;
          }
        });
      } else {
        swal("Are you sure you want to disable this find unregistered linguist section?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              DisableunregLinFindAccess(value);

            default:
              break;
          }
        });
      }
    }
  };

  const EnableunregLinFindAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-unregistered-linguist-access/sppoID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Approved",
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "unregLinFindAccess",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableunregLinFindAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-unregistered-linguist-disable/sppoID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled",
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "unregLinFindAccess",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };


  const handleFindRegLin = (value) => {
    if (data.access) {
      if (!data.regLinFindAccess) {
        swal(
          "Are you sure you want to enable this find registered linguist section?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              EnableregLinFindAccess(value);

            default:
              break;
          }
        });
      } else {
        swal("Are you sure you want to disable this find registered linguist section?", {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              DisableregLinFindAccess(value);

            default:
              break;
          }
        });
      }
    }
  };

  const EnableregLinFindAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-registered-linguist-access/sppoID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Approved",
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "regLinFindAccess",
          value: true,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const DisableregLinFindAccess = async (value) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-registered-linguist-disable/sppoID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled",
        buttons: false,
      });
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "regLinFindAccess",
          value: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      {loader && (
        <Dimmer active inverted>
          <Loader size="large">Changing Access</Loader>
        </Dimmer>
      )}

      <Table color={"pink"}>
        <Table.Header>

          <Table.Row>
            <Table.HeaderCell width="1" textAlign="center">
              Approve Find Registered Linguist
            </Table.HeaderCell>
            <Table.HeaderCell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data?.regLinFindAccess}
                onChange={() =>
                  handleFindRegLin("regLinFindAccess")
                }
              />
            </Table.HeaderCell>
          </Table.Row>

          <Table.Row>
            <Table.HeaderCell width="1" textAlign="center">
              Approve Find Unregistered Linguist
            </Table.HeaderCell>
            <Table.HeaderCell width="1" textAlign="center">
              <Checkbox
                toggle
                checked={data?.unregLinFindAccess}
                onChange={() =>
                  handleFindUnregLin("unregLinFindAccess")
                }
              />
            </Table.HeaderCell>
          </Table.Row>



        </Table.Header>
      </Table>

    </>
  );
};

export default AllocateAccess;
