import React, { Fragment, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//Custom Components
import CustomSelect from "components/Common/CustomSelect";
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";
import CustomDate from "components/Common/CustomDate";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import { Checkbox, Divider } from "@material-ui/core";
import CustomRadio from "components/Common/CustomRadio";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPhone from "components/Common/CustomPhone";
import CustomCaptcha from "components/Common/CustomCaptcha";
import { Icon, Header, Button } from "semantic-ui-react";
import Axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import {
  COUNTRY_DATA,
  CURRENT_COUNTRY,
  JOB_ADVERT_ADDRESS,
  LANG_COMB,
  NHS_TRUST,
  PRIMARY_SOURCE_LANG,
  PRIMARY_TARGET_LANG,
  SKILL_LEVEL_REQUIRED_X,
  EMAIL_REGEX,
} from "data";

import { changeJaInput } from "redux/actions/advertAction";

const PageOne = ({ userID }) => {
  const { data } = useSelector((state) => state.advertReducer);
  const dispatch = useDispatch();
  const [notValid, setNotValid] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [document, setDocument] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const fileInput = useRef(null);
  const token = Cookies.get("token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeJaInput({
        name,
        value: value,
      })
    );
  };

  const handleChecked = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeJaInput({
        name,
        value: value || checked,
      })
    );
  };

  const handlePhoneChange = (value) => {
    dispatch(
      changeJaInput({
        name: "phone_no",
        value,
      })
    );
  };
  const handleMobileChange = (value) => {
    dispatch(
      changeJaInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeJaInput({
        name,
        value,
      })
    );
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleFiles = (e) => {
    setDocument(e.target.files[0]);
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeJaInput({
        name: "additionallanguage",
        value,
      })
    );
  };

  const handleDateChange = (date) => {
    dispatch(
      changeJaInput({
        name: "applicationclosingdate",
        value: date,
      })
    );
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleChangeS = (e, value) => {
    dispatch(
      changeJaInput({
        name: "sourcelanguage",
        value,
      })
    );
  };

  const handleChangeT = (e, value) => {
    dispatch(
      changeJaInput({
        name: "targetlanguage",
        value,
      })
    );
  };

  const handleChangeCO = (e, value) => {
    dispatch(
      changeJaInput({
        name: "country",
        value,
      })
    );
  };

  const handleChangeCOB = (e, value) => {
    dispatch(
      changeJaInput({
        name: "business_country",
        value,
      })
    );
  };

  const {
    purchase_order_no,
    jobtitle,
    salary,
    contracttype,
    hoursperweek,
    location,
    remote_details,
    jobref,
    targetlanguage,
    sourcelanguage,
    additionallanguage,
    expertiselevel,
    about_company,
    jobdescription,
    applicationclosingdate,
    howtoapply,
    business_name,
    company_number,
    company_website,
    vat_gst_number,
    phone_no,
    nhs_trust,
    registration_number,
    business_addressline_one,
    business_addressline_two,
    business_city,
    business_postcode,
    business_region,
    business_country,
    title,
    first_name,
    last_name,
    address_line_one,
    address_line_two,
    city,
    region,
    country,
    postcode,
    mobile_no,
    email,
    position,
    agree,
  } = data;

  const classes = useFormStyles();

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };

  const isAllow =
    !jobtitle ||
    !salary ||
    !contracttype ||
    !hoursperweek ||
    !address_line_one ||
    !city ||
    !mobile_no ||
    !first_name ||
    !last_name ||
    !title ||
    !postcode ||
    !region ||
    !country ||
    !email ||
    !howtoapply ||
    !sourcelanguage ||
    !expertiselevel ||
    !about_company ||
    !jobdescription ||
    !applicationclosingdate ||
    !nhs_trust ||
    (nhs_trust === "Yes" && !registration_number) ||
    !agree ||
    !captcha ||
    notValid ||
    (location === "This position is Remote" && !remote_details) ||
    (location === "This position is Remote" && !sourcelanguage) ||
    (location === "This position is office based" &&
      !business_addressline_one) ||
    (location === "This position is office based" && !business_city) ||
    (location === "This position is office based" && !business_postcode) ||
    (location === "This position is office based" && !business_region) ||
    (location === "This position is office based" && !business_country) ||
    (nhs_trust.includes("Yes") && !registration_number);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    let fd = new FormData();

    fd.append("purchase_order_no", purchase_order_no || "");
    fd.append("jobtitle", jobtitle || "");
    fd.append("salary", salary || "");
    fd.append("contracttype", contracttype || "");
    fd.append("hoursperweek", hoursperweek || "");
    fd.append("location", location || "");
    fd.append("remote_details", remote_details || "");

    fd.append("jobref", jobref || "");
    fd.append("targetlanguage", targetlanguage || "");
    fd.append("sourcelanguage", sourcelanguage || "");
    fd.append("additionallanguage", additionallanguage || "");
    fd.append("expertiselevel", expertiselevel || "");
    fd.append("about_company", about_company || "");
    fd.append("jobdescription", jobdescription || "");

    fd.append("applicationclosingdate", applicationclosingdate || "");
    fd.append("howtoapply", howtoapply || "");
    fd.append("business_name", business_name || "");
    fd.append("company_number", company_number || "");
    fd.append("company_website", company_website || "");
    fd.append("vat_gst_number", vat_gst_number || "");
    fd.append("phone_no", phone_no || "");

    fd.append("nhs_trust", nhs_trust || "");
    fd.append("registration_number", registration_number || "");
    fd.append("business_addressline_one", business_addressline_one || "");
    fd.append("business_addressline_two", business_addressline_two || "");
    fd.append("business_city", business_city || "");
    fd.append("business_postcode", business_postcode || "");
    fd.append("business_region", business_region || "");

    fd.append("business_country", business_country || "");
    fd.append("title", title || "");
    fd.append("first_name", first_name || "");
    fd.append("last_name", last_name || "");
    fd.append("address_line_one", address_line_one || "");
    fd.append("address_line_two", address_line_two || "");

    fd.append("city", city || "");
    fd.append("region", region || "");
    fd.append("country", country || "");
    fd.append("postcode", postcode || "");
    fd.append("mobile_no", mobile_no || "");

    fd.append("email", email || "");
    fd.append("position", position || "");
    fd.append("document", document || "");
    fd.append("userID", userID || "LC Web");

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/jobadvert`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubmitLoader(false);

      //id
      Cookies.set("id", response?.data.id);

      //charge
      Cookies.set("charge", response?.data.charge);

      swal({
        title: "Success!",
        text: `Success`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location =
          "/interpreter-translator-linguists-job-advert-summary";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          label="Your ref / Purchase order no."
          width="100%"
          name="purchase_order_no"
          value={purchase_order_no}
          placeholder="Company reference or purchase order no."
        />
        <br />
        <p style={{ color: "purple" }}>Job advert details:</p>

        <CustomInputSingle
          onChange={handleChange}
          label="Position / Job Title"
          width="100%"
          name="jobtitle"
          value={jobtitle}
          required
          placeholder="Advertising for the position?"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Salary"
          width="100%"
          name="salary"
          value={salary}
          required
          placeholder="Salary for this position"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Contract type"
          width="100%"
          name="contracttype"
          value={contracttype}
          required
          placeholder="e.g. fulltime, part time, fixed term"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Hours per week"
          width="100%"
          name="hoursperweek"
          value={hoursperweek}
          type={"number"}
          required
          placeholder="e.g. total numbers of hours/week"
        />

        <CustomRadio
          onChange={handleChange}
          width="100%"
          label="Position for / location?"
          name="location"
          value={location}
          data={JOB_ADVERT_ADDRESS}
        />
        {location === "This position is office based" ? (
          <Fragment>
            <CustomBigInputSingle
              onChange={handleChange}
              label="House Number/Name, Street Name"
              moreInfo="Duty station / work place address / remote"
              width="100%"
              name="business_addressline_one"
              value={business_addressline_one}
              required
              placeholder="Building Number/Name, Road Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Address line 2"
              width="100%"
              name="business_addressline_two"
              value={business_addressline_two}
              placeholder="Additional Information"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Town / City"
              width="100%"
              name="business_city"
              value={business_city}
              required
              placeholder="Village / Town / City"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="County / Region"
              width="100%"
              name="business_region"
              value={business_region}
              required
              placeholder="County / Region / Division / State"
            />

            <CustomInputSingle
              onChange={handleChange}
              label="Post / Zip Code"
              width="100%"
              name="business_postcode"
              value={business_postcode}
              required
              placeholder="Post / Zip Code / Post Office Name"
            />
            <CustomAutoSelect
              onChange={handleChangeCOB}
              label="Country"
              moreInfo="Current country of residence"
              width="100%"
              name="business_country"
              value={business_country}
              data={CURRENT_COUNTRY}
              required
            />
          </Fragment>
        ) : (
          <CustomInputSingle
            onChange={handleChange}
            label="Remote details"
            width="100%"
            name="remote_details"
            value={remote_details}
            required
            placeholder="Provide details for working remotely"
          />
        )}
        <CustomInputSingle
          onChange={handleChange}
          label="Job ref. if any"
          width="100%"
          name="jobref"
          value={jobref}
          placeholder="Your job reference number, if any"
        />
        <CustomAutoSelect
          onChange={handleChangeS}
          label="Job related source language"
          width="100%"
          name="sourcelanguage"
          value={sourcelanguage}
          data={PRIMARY_SOURCE_LANG.filter((value) => value !== targetlanguage)}
          required
        />
        <CustomAutoSelect
          onChange={handleChangeT}
          width="100%"
          label="Job related target language"
          name="targetlanguage"
          value={targetlanguage}
          data={PRIMARY_TARGET_LANG.filter((value) => value !== sourcelanguage)}
        />
        <CustomAutoComplete
          onChange={handleAutoComplete}
          width="100%"
          label="Additional language(s) required for this job"
          name="additionallanguage"
          data={LANG_COMB}
          value={additionallanguage}
          placeholder="Choose"
        />
        <CustomSelect
          onChange={handleChange}
          width="100%"
          label="Please indicate linguist's skills required"
          name="expertiselevel"
          value={expertiselevel}
          data={SKILL_LEVEL_REQUIRED_X}
          required
        />
        <CustomInput
          onChange={handleChange}
          label="Job description"
          width="100%"
          name="jobdescription"
          value={jobdescription}
          required
          placeholder="Please provide job description"
        />
        <CustomDate
          onChange={handleDateChange}
          width="100%"
          label="Application closing date"
          name="applicationclosingdate"
          value={applicationclosingdate}
          minDate={new Date()}
          required
        />
        <CustomInput
          onChange={handleChange}
          width="100%"
          label="How to apply for this position?"
          name="howtoapply"
          value={howtoapply}
          required
          placeholder="Provide information - how to applying for this job?"
        />
        <br />

        <p style={{ color: "purple" }}>
          Employer / business / organisation details:{" "}
        </p>
        <CustomInputSingle
          onChange={handleChange}
          label="Business/ organisation name"
          width="100%"
          name="business_name"
          value={business_name}
          placeholder="Name of your business organisation"
        />
        <CustomInput
          onChange={handleChange}
          label="About you/ your organisation/ company"
          width="100%"
          name="about_company"
          value={about_company}
          required
          placeholder="About your organisation"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Business/ organisation company number"
          width="100%"
          name="company_number"
          value={company_number}
          placeholder="Company registration no. CRN (if registered)"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Your / organisation / company website"
          width="100%"
          name="company_website"
          value={company_website}
          placeholder="e.g. https://xyz.com"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="VAT / GST Number"
          width="100%"
          name="vat_gst_number"
          value={vat_gst_number}
          placeholder="If registered, enter VAT / GST no."
        />
        <br />
        <CustomPhone
          onChange={handlePhoneChange}
          placeholder="Phone number"
          width="100%"
          name="phone_no"
          value={phone_no}
          placeholder="Phone Number (Please select country then type number e.g. +44 1234 000000)"
        />
        <CustomRadio
          onChange={handleChange}
          width="100%"
          label="Is the employer a registered charity or NHS trust?"
          name="nhs_trust"
          value={nhs_trust}
          data={NHS_TRUST}
          required
        />
        {nhs_trust.includes("Yes") ? (
          <>
            <CustomInputSingle
              onChange={handleChange}
              width="100%"
              label="Registration number"
              name="registration_number"
              value={registration_number}
              type={"number"}
              required
              placeholder="Enter charity registration no."
            />
            <br />
          </>
        ) : null}
        <Divider />
        <br />
        <p style={{ color: "purple" }}>
          Your / company representative details:
        </p>
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Title"
          name="title"
          value={title}
          required
          placeholder="( e.g. Mr. / Mrs / Miss etc. )"
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="First Name(s)"
          name="first_name"
          value={first_name}
          required
          placeholder="First Name(s)/Forename/Given Names"
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Last Name"
          name="last_name"
          value={last_name}
          required
          placeholder="Last Name/Surname/Family Name"
        />
        <CustomInputSingle
          onChange={handleEmailChange}
          width="100%"
          label="Primary email address"
          name="email"
          value={email}
          required
          placeholder="e.g. example@example.com"
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <br />
        <CustomPhone
          onChange={handleMobileChange}
          width="100%"
          placeholder="Mobile number / direct line"
          name="mobile_no"
          value={mobile_no}
          placeholder="Mobile Number* (Please select country then type number e.g. +44 7307 000000)"
          required
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="House number/name, Street name"
          name="address_line_one"
          value={address_line_one}
          required
          placeholder="e.g. Flat/Building Name, 101 Example Street"
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Address line 2"
          name="address_line_two"
          value={address_line_two}
          placeholder="Additional Address Information"
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Town / City"
          name="city"
          value={city}
          required
          placeholder="Your Village, Town or City Name"
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="County / Region"
          name="region"
          value={region}
          required
          placeholder="Your County, Division or State Name"
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Post Code / Zip Code / Post office"
          name="postcode"
          value={postcode}
          required
          placeholder="Your Post Code, Zip Code or Post Office Name"
        />
        <CustomAutoSelect
          onChange={handleChangeCO}
          width="100%"
          label="Country"
          name="country"
          value={country}
          data={COUNTRY_DATA}
          required
        />

        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Your position"
          name="position"
          value={position}
          placeholder="Your position / relationship"
        />
        <br />
        <div>
          <input
            accept="image/png, image/jpeg"
            ref={fileInput}
            onChange={handleFiles}
            type="file"
            name="document"
            width="100%"
            style={{ display: "none" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
              Please upload your identity document (passport / national ID)
            </Header>
            <div>
              <Button onClick={handleClick}>Upload</Button>
            </div>
            {document && (
              <div style={{ textAlign: "center" }}>
                <Header style={{ margin: "0" }} as="h1">
                  <Icon name="file pdf" size="large" />
                </Header>{" "}
                <Header style={{ margin: "0" }} as="h5">
                  {document.name}{" "}
                </Header>
                <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                  document.size / 1024
                ).toFixed(2)}kb`}</Header>
                <Header
                  style={{ margin: "0" }}
                  as="h5"
                >{`Last Modified: ${document?.lastModifiedDate}`}</Header>
              </div>
            )}
          </div>
        </div>
        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <p>
            <Checkbox
              onChange={handleChecked}
              checked={agree}
              name="agree"
              color="primary"
            />
            I confirm that the information I provided within the form are
            accurate and lawful. I agree for this job advert to be published
            within LC website and among linguists and professionals by email. I
            also agree with LC
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/privacy-policy"
            >
              {" "}
              Privacy Policy{" "}
            </a>
            and{" "}
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/terms-and-conditions"
            >
              {" "}
              T&C
            </a>
            .
          </p>
          <p>*Required</p>
        </div>
        <CustomCaptcha onChange={handleCaptcha} />
        <div className={classes.buttonFlex}>
          <CustomButton
            disabled={isAllow}
            text="Submit"
            onClick={handleSubmit}
            loading={submitLoader}
          />
        </div>
      </form>
    </div>
  );
};

export default PageOne;
