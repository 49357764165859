import Linguist from "components/RouteProtection/Linguist/Linguist";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import CardHeader from "components/Card/CardHeader";
import BookingDetails from "pages/Component/BookingDetailsLinClient";
import Items from "pages/Component/ClientBookingTable";
import GoogleMap from "components/Common/Map";

import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Footer from "pages/Component/BookingFooter";
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import fetch from "isomorphic-fetch";

const ViewContactRequest = () => {
  const history = useHistory();
  const classes = useDashboardStyles();
  const { id, linAddress, isMapped } = useParams();
  const [values, setValues] = useState("");
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/linguists/cashclient-booking-view/bookingID/${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setValues(result);
      });
  }, []);

  return values ? (
    <Linguist>
      <LinguistLayout>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>
              Interpreting Assignment - {values.id}
            </p>
            <IconButton color="inherit">
              <ArrowBackIcon onClick={() => history.goBack()}></ArrowBackIcon>
            </IconButton>
          </CardHeader>
        </GridItem>
        <br />
        <React.Fragment>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
              <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
                <CardHeader
                  color="primary"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 10,
                    top: 35,
                  }}
                >
                  <p className={classes.cardTitleWhite}>
                    Interpreting Assignment
                  </p>
                </CardHeader>
              </GridItem>
              <Card
                raised
                style={{ marginTop: "24px", padding: "30px", zIndex: 0 }}
              >
                <CardBody>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <CardBody>
                        <BookingDetails values={values} />
                      </CardBody>
                      <Items values={values} />
                      {isMapped &&
                        values.interpreting_service_required !==
                          "Remote Interpreting (RI) / Video Remote Interpreting (VRI)" && (
                          <CardBody>
                            <Table>
                              <Table.Body>
                                <Table.Row style={{ textAlign: "center" }}>
                                  <Table.Cell>
                                    Distance from LIN Address to Venue
                                  </Table.Cell>
                                  <Table.Cell>{distance} (One way)</Table.Cell>
                                </Table.Row>
                                <Table.Row style={{ textAlign: "center" }}>
                                  <Table.Cell>
                                    Estimated Time of Arrival ETA (or travel
                                    time to the venue)
                                  </Table.Cell>
                                  <Table.Cell>{duration}</Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                            <GoogleMap
                              origin={linAddress}
                              destination={`${values.house_number_street_name} ${values.post_code}, ${values.region}, ${values.country}`}
                              setDistance={setDistance}
                              setDuration={setDuration}
                            />
                          </CardBody>
                        )}
                    </Card>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ zIndex: 1 }}
                    >
                      <Button
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          marginTop: 10,
                          top: 35,
                          background:
                            "linear-gradient( 60deg , #ab47bc, #8e24aa)",
                          width: 100 + "%",
                          padding: (10, 15),
                        }}
                        onClick={() =>
                          history.push(
                            `/linguist/cac-view-interpreting-document/${values._id}/${values.id}`
                          )
                        }
                      >
                        <p className={classes.cardTitleWhite}>View Document</p>
                      </Button>
                    </GridItem>
                  </GridItem>
                </CardBody>
                <br />
                <br />
                <CardBody>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ zIndex: 1 }}
                  >
                    <Card>
                      <Footer values={values} />
                    </Card>
                  </GridItem>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      </LinguistLayout>
    </Linguist>
  ) : (
    "Loading..."
  );
};

export default ViewContactRequest;
