import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Axios from "utils/axios";
import CommonCard from "components/Common/CommonCard";
import EventsCard from "./EventsCardComponent";

const CardExampleCard = () => {
  const classes = useEmployeeStyles();
  const [values, setValues] = useState({ reload: false });
  const dispatch = useDispatch();

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `/api/EMP/list-events`
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <CommonCard title="Events and Meetings">
      <GridContainer xs={12} sm={12} md={12} lg={12} justify="center">
        <EventsCard values={values} />
      </GridContainer>
    </CommonCard>
  );
};

export default CardExampleCard;
