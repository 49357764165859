import AdminRegistrationPage from "pages/AdminRegistrationPage";

const ADMIN_REG = [
  {
    id: '500a',
    type: "form",
    text: "Admin Registration Form",
    path: "admin-reg-form",
    component: AdminRegistrationPage,
  },
];

export default ADMIN_REG;
