import {
    CASHCLIENT_UPDATE_CR_STEP,
    CASHCLIENT_CHANGE_INPUT,
    CASHCLIENT_CR_SUBMIT,
    CASHCLIENT_SET_LOADING,
    CASHCLIENT_CR_LOGIN,
  } from "../actions/actionTypes";
  
  
  const CASH_INITIAL_STATE = {
    count: 1,
    loading: false,
    data: {
      title: "",
      first_name: "",
      last_name: "",
      dob: null,
      gender: '',
      house_street_no_name: '',
      address_line_two: '',
      town_city: '',
      post_code: '',
      county_region: '',
      country: null,
      current_nationality: '',
      other_nationalities: [],
      email: "",
      mobile_no: "",
      direct_line: "",
      business_name: "",
      position: "",
      business_email: "",
      business_phone_no: "",
      // business_address: "",
      contact_house_street_no_name: '',
      contact_address_line_two: '',
      contact_town_city: '',
      contact_post_code: '',
      contact_county_region: '',
      contact_country: '',
      company_registration_no: "",
      business_website: "",
      note: "",
      gdpr: false,
    },
    loggedInData: null,
  };
  
  const profileReducer = (state = CASH_INITIAL_STATE, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case CASHCLIENT_CHANGE_INPUT:
        return {
          ...state,
          data: {
            ...state.data,
            [payload.name]: payload.value,
          },
        };
      case CASHCLIENT_UPDATE_CR_STEP:
        return {
          ...state,
          count: payload,
        };
  
      case CASHCLIENT_SET_LOADING:
        return {
          ...state,
          loading: payload,
        };
  
      case CASHCLIENT_CR_LOGIN:
        return {
          ...state,
          loading: false,
          loggedInData: payload,
        };
  
      case CASHCLIENT_CR_SUBMIT:
        return {
          ...state,
          loading: false,
          data: {
            title: "",
            first_name: "",
            last_name: "",
            email: "",
            mobile_no: "",
            direct_line: "",
            house_no: "",
            street_name: "",
            town: "",
            zip_code: "",
            business_name: "",
            position: "",
            business_email: "",
            business_phone_no: "",
            business_address: "",
            company_registration_no: "",
            business_website: "",
            note: "",
            gdpr: "agree",
          },
        };
      default:
        return state;
    }
  };
  
  export default profileReducer;
  