import React, { Fragment } from "react";

import AdminRegistration from "components/Auth/AdminRegistration/AdminRegistration";
import LandinPageLayout from "layout/LandingPageLayout";

const AdminRegistrationPage = () => {
  return (
    <LandinPageLayout>
      <AdminRegistration />
    </LandinPageLayout>
  );
};

export default AdminRegistrationPage;
