// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ProvidedQuote from "components/Admin/TimesheetProjectReport/Invoice/ViewLinProvidedQuote";

import history from 'utils/history'
//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import LinguistLayout from "layout/LinguistLayout";

const ProjectReportPage = () => {
  const classes = useDashboardStyles();
  return (
    <LinguistLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <p className={classes.cardTitleWhite}>Provided Quote</p>
              <i className="icon list arrow left"
                onClick={() => {
                  history.goBack()
                }}></i>
            </CardHeader>
            <CardBody>
              <ProvidedQuote />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LinguistLayout>
  );
};

export default ProjectReportPage;
