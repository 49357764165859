import React, { useState, useEffect } from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import LinguistLayout from "layout/LinguistLayout";

import { Button, Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LeadsList from "components/Linguist/Leads/Leads";

const useStyles = makeStyles({
  Button: {
    width: "100%",
  },
});
const Leads = () => {
  const classes = useDashboardStyles();
  const buttonClass = useStyles();
  const matches = useMediaQuery("(max-width:768px)");

  const [leadAccess, setLeadAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("LINtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setLeadAccess(response?.data);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          {leadAccess?.leadAccess ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                {!matches ? (
                  <CardHeader
                    style={{ display: "flex", justifyContent: "space-between" }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Leads</p>
                    <div>
                      <Button
                        onClick={() =>
                          history.push(`/linguist/manage-my-in-house-clients`)
                        }
                        color="purple"
                      >
                        My In-house Clients
                      </Button>
                      <Button
                        onClick={() =>
                          history.push(`/linguist/manage-my-private-clients`)
                        }
                        color="purple"
                      >
                        My Private Clients
                      </Button>
                      <Button
                        onClick={() =>
                          window.open(`/add-new-customer/${leadAccess?._id}`)
                        }
                        color="purple"
                      >
                        + Add Lead
                      </Button>
                      <Button
                        onClick={() =>
                          history.push(`/linguist/send-cla-registration-form`)
                        }
                        color="purple"
                      >
                        Send Registration Form{" "}
                      </Button>

                      <Button
                        onClick={() =>
                          window.open("/contact-removal-request", "_blank")
                        }
                        color="purple"
                      >
                        Removal Form
                      </Button>

                      <IconButton
                        onClick={() => history.goBack()}
                        color="inherit"
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </div>
                  </CardHeader>
                ) : (
                  <>
                    {" "}
                    <CardHeader
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      color="primary"
                    >
                      <p className={classes.cardTitleWhite}>
                        Leads (Potential Clients - Contacts)
                      </p>
                      <IconButton
                        onClick={() => history.goBack()}
                        color="inherit"
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </CardHeader>{" "}
                    <CardHeader color="primary">
                      {" "}
                      <div style={{ marginBottom: "15px" }}>
                        <Button
                          className={buttonClass.Button}
                          onClick={() =>
                            history.push(`/linguist/manage-my-in-house-clients`)
                          }
                          color="purple"
                        >
                          My In-house Clients
                        </Button>
                      </div>
                      <div style={{ marginBottom: "15px" }}>
                        <Button
                          className={buttonClass.Button}
                          onClick={() =>
                            history.push(`/linguist/manage-my-private-clients`)
                          }
                          color="purple"
                        >
                          My Private Clients
                        </Button>
                      </div>
                      <div style={{ marginBottom: "15px" }}>
                        <Button
                          className={buttonClass.Button}
                          onClick={() =>
                            window.open(`/add-new-customer/${leadAccess?._id}`)
                          }
                          color="purple"
                        >
                          + Add Lead
                        </Button>
                      </div>
                      <div style={{ marginBottom: "15px" }}>
                        <Button
                          className={buttonClass.Button}
                          onClick={() =>
                            history.push(`/linguist/send-cla-registration-form`)
                          }
                          color="purple"
                        >
                          Send Registration Form{" "}
                        </Button>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Button
                          className={buttonClass.Button}
                          onClick={() =>
                            window.open("/contact-removal-request", "_blank")
                          }
                          color="purple"
                        >
                          Removal Form
                        </Button>
                      </div>
                    </CardHeader>
                  </>
                )}

                <CardBody>
                  <LeadsList />
                </CardBody>
              </Card>
            </GridItem>
          ) : leadAccess?.leadAccess === false ? (
            <Header as="h3">
              {" "}
              Access Denied!. <br /> Complete Full Registration or Contact HR /
              Admin Team.
            </Header>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default Leads;
