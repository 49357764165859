import { Table } from "semantic-ui-react";
import Button from "@material-ui/core/Button";
import ReactToExcel from "react-html-table-to-excel";
import { makeStyles } from "@material-ui/core/styles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const useStyles = makeStyles({
  button: {
    background: "#3f51b5  none",
    width: "40%",
    border: 0,
    color: "#ffff",
    padding: 16,
    marginLeft: 25,
    cursor: "pointer",
    fontFamily: ` "Roboto", "Helvetica", "Arial", sans-serif;`,
    fontWeight: "light",
    borderRadius: "3pt",
    ["@media (max-width:1024px)"]: {
      marginLeft: 0,
      width: "100%",
      margin: 0,
      marginTop: 10,
    },
  },
  pdfButton: {
    width: "40%",
    padding: 12,
    marginLeft: 50,
    ["@media (max-width:1024px)"]: {
      width: "100%",
      margin: 0,
    },
  },
  profitButton: {
    width: "45%",
    padding: 12,
    ["@media (max-width:1024px)"]: {
      width: "100%",
      marginTop: 10,
      margin: 0,
    },
  },
});

const BoxThree = ({
  handleCalculate,
  totalRevenue,
  totalExp,
  date,
  reference,
  currency,
}) => {
  const classes = useStyles();

  const exportPDF = (e) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 10;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);
    var totalPages = doc.internal.getNumberOfPages();
    var i;

    for (i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
      // doc.setTextColor(150);
      doc.text(
        50,
        doc.internal.pageSize.height - 30,
        `Strictly Company Confidential © linguistscollective.com - ${moment(
          date
        )
          .tz("Europe/London")
          .format("DD MMM, YYYY - HH:mm:ss ")} - UK local time`
      );
    }

    const title = "Price Calculator";
    const headers = [
      [
        "Quote/\nYour Reference No",
        "Date",
        "Currency",
        "Total Revenue",
        "Total Expenses",
        "Gross Profit/\nLoss",
      ],
    ];

    const data = [
      [
        reference,
        moment(date).format("DD MMM, YYYY"),
        currency,
        totalRevenue,
        totalExp,
        grossProfit,
      ],
    ];
    // const One = "Column One: Job / Your Reference";
    // const Two = "Column Two: Payment Received / Contract Value";

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        valign: "middle",
        // halign: 'justify'
      },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    var string = doc.output("datauristring", {
      filename: "price-calculator-result-pdf",
    });
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    var x = window.open();
    // doc.save('price-calculator-result-table-pdf')
    x.document.open();
    x.document.write(embed);
    x.document.close();
  };

  const grossProfit = totalRevenue - totalExp;
  return (
    <div>
      <Table id="price-calculator">
        <Table.Header
          style={{
            display: "none",
          }}
        >
          <Table.Row>
            <Table.HeaderCell> </Table.HeaderCell>
            <Table.HeaderCell> </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell
              style={{
                width: "100%",
              }}
            >
              Total Revenue:
            </Table.Cell>
            <Table.Cell>
              <span
                style={{
                  color: "green",
                }}
              >
                {totalRevenue ? totalRevenue.toFixed(2) + " " + currency : ""}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                width: "300px",
              }}
            >
              Total Expenses:
            </Table.Cell>
            <Table.Cell>
              <span
                style={{
                  color: "red",
                }}
              >
                {totalExp ? totalExp.toFixed(2) + " " + currency : ""}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                width: "350px",
              }}
            >
              <span style={{ width: "100%", display: "flex" }}>
                Gross Profit / Loss (Total Revenue - Total Expenses)
                <br />
              </span>
              <span
                style={{
                  paddingLeft: "16px",
                  fontSize: "2rem",
                  color: `${Math.sign(+grossProfit) === -1 ? "red" : "green"}`,
                }}
              >
                {isNaN(grossProfit)
                  ? ""
                  : grossProfit.toFixed(2) + " " + currency}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ display: "flex", justifyContent: "center" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleCalculate}
                className={classes.profitButton}
              >
                Profit / Loss
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <div style={{ dispaly: "grid", gridTemplateColumns: "auto auto" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={exportPDF}
          className={classes.pdfButton}
        >
          Export in pdf
        </Button>

        <ReactToExcel
          className={classes.button}
          table="price-calculator"
          filename="price-caclculator-result-table-xls"
          sheet="sheet 1"
          buttonText="EXPORT IN EXCEL"
        />
      </div>
    </div>
  );
};

export default BoxThree;
