// Custom Components
import CommonCard from "components/Common/CommonCard";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import MessageFromManagement from "assets/icons/message from managmemnt-05.png";
const WelcomeMessage = () => {
  const [values, setValues] = useState({ reload: false });

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/LIN/get-welcome-message`
      );
      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <>
      <div style={{ margin: "5px" }}>
        <CommonCard title="Message from management">
          {values?._id ?
            <a
              href={`view-welcome-message-attachment/${values?._id}/${values?.message}`}
            >
              <img style={{ width: "100%" }} src={MessageFromManagement} alt="" />
            </a>
            : <img style={{ width: "100%" }} src={MessageFromManagement} alt="" />}
        </CommonCard>
      </div>
    </>
  );
};

export default WelcomeMessage;
