import React, { useState } from 'react'
import Axios from "utils/axios";
import Cookies from 'js-cookie'
//Redux imports
import { useDispatch, useSelector } from 'react-redux'
import { changeLinguistProfileInput } from 'redux/actions/updateProfileAction';
import swal from 'sweetalert'
import { useHistory } from 'react-router-dom';
import { Checkbox, Dimmer, Loader, Table } from "semantic-ui-react";



const AllocateAccess = ({ id }) => {
    const history = useHistory()
    const token = Cookies.get('BACtoken')
    const { data } = useSelector(state => state.updateProfileReducer)
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)


    const handleVerifyAccess = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Login Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const handleUnverifyAccess = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Login Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableInterpreting = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-Interpreting-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Interpreting Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'interpreting_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableInterpreting = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-Interpreting-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Interpreting Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'interpreting_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableTranslation = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-Translation-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Translation Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'translation_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableTranslation = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-Translation-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Translation Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'translation_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableQuotation = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-Quotation-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Quotation Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'quotation_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableQuotation = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-Quotation-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Quotation Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'quotation_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }

    const enableJobadvert = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-Jobadvert-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Job Advert Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'jobadvert_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableJobadvert = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-Jobadvert-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Job Advert Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'jobadvert_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableCommunication = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-Communication-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Communication Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'communication_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableCommunication = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-Communication-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Communication Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'communication_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }



    const enableFeedback = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-Feedback-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Feedback Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'feedback_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableFeedback = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-Feedback-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Feedback Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'feedback_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }




    const enableHistory = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-History-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Assignment History Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'history_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableHistory = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-History-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Assignment History Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'history_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }


    const enableInvoice = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-Invoice-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Invoice Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'invoice_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableInvoice = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-Invoice-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Invoice Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'invoice_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }



    const enableBiils = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-Bill-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Billing Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'bill_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableBills = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-Bill-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Billing Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'bill_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }





    const enableReports = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-Report-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Report Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'report_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableReports = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-Report-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Report Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'report_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }




    const enableAdduser = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/access-Adduser-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Add An User Access Granted',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'adduser_access',
                value: true
            }))
        } catch (error) {
            console.log(error.response)
        }
    }

    const disableAdduser = async (value) => {
        setLoader(true)
        try {
            const response = await Axios.put(`/api/accountclient/disable-Adduser-own-bacuser/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            swal({
                icon: 'success',
                text: 'Add An User Access Revoked',
                buttons: false
            })
            setLoader(false)
            dispatch(changeLinguistProfileInput({
                name: 'adduser_access',
                value: false
            }))

        } catch (error) {
            console.log(error.response)
        }
    }












    const handleToggle = async (value) => {

        if (!data.access) {
            swal("Are you sure you want to do this ?", {
                dangerMode: true,
                buttons: {
                    cancel: true,
                    catch: {
                        text: "Yes!",
                        value: "catch",
                    },
                },
            })
                .then((type) => {
                    switch (type) {

                        case "cancel":
                            break;

                        case "catch":
                            handleVerifyAccess(value);

                        default:
                            break;
                    }
                });
        } else {
            swal("Are you sure?", {
                dangerMode: true,
                buttons: {
                    cancel: true,
                    catch: {
                        text: "Yes!",
                        value: "catch",
                    },
                },
            })
                .then((type) => {
                    switch (type) {

                        case "cancel":
                            break;

                        case "catch":
                            handleUnverifyAccess(value);

                        default:
                            break;
                    }
                });
        }

    }



    const handleInterpreting = async (value) => {
        if (data.access) {
            if (!data.interpreting_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableInterpreting(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableInterpreting(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }




    const handleTranslation = async (value) => {
        if (data.access) {
            if (!data.translation_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableTranslation(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableTranslation(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }



    const handleQuotation = async (value) => {
        if (data.access) {
            if (!data.quotation_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableQuotation(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableQuotation(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }



    const handleJobadvert = async (value) => {
        if (data.access) {
            if (!data.jobadvert_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableJobadvert(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableJobadvert(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }


    const handleCommunication = async (value) => {
        if (data.access) {
            if (!data.communication_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableCommunication(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableCommunication(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }


    const handleFeedback = async (value) => {
        if (data.access) {
            if (!data.feedback_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableFeedback(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableFeedback(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }


    const handleHistory = async (value) => {
        if (data.access) {
            if (!data.history_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableHistory(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableHistory(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }



    const handleInvoice = async (value) => {
        if (data.access) {
            if (!data.invoice_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableInvoice(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableInvoice(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }



    const handleBills = async (value) => {
        if (data.access) {
            if (!data.bill_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableBiils(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableBills(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }




    const handleReports = async (value) => {
        if (data.access) {
            if (!data.report_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableReports(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableReports(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }



    const handleAdduser = async (value) => {
        if (data.access) {
            if (!data.adduser_access) {
                swal("Are you sure you want to do this ?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                enableAdduser(value);

                            default:
                                break;
                        }
                    });
            } else {
                swal("Are you sure you want to do this?", {
                    dangerMode: true,
                    buttons: {
                        cancel: true,
                        catch: {
                            text: "Yes!",
                            value: "catch",
                        },
                    },
                })
                    .then((type) => {
                        switch (type) {

                            case "cancel":
                                break;

                            case "catch":
                                disableAdduser(value);

                            default:
                                break;
                        }
                    });
            }
        }
    }



   






    return (
        <>
            {loader && (
                <Dimmer active inverted>
                    <Loader size='large'>Changing Access</Loader>
                </Dimmer>
            )}

            <Table celled structured color={"pink"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width='1' textAlign='center'>Access</Table.HeaderCell>
                        <Table.HeaderCell width='1' textAlign='center'>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Login / Access Permission</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.access} onChange={() => handleToggle('access')} />
                        </Table.Cell>
                    </Table.Row>

                   
                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Interpreting Assignment</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.interpreting_access} onChange={() => handleInterpreting('interpreting_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Translation Assignment</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.translation_access} onChange={() => handleTranslation('translation_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Quotation</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.quotation_access} onChange={() => handleQuotation('quotation_access')} />
                        </Table.Cell>
                    </Table.Row>



                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Communication</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.communication_access} onChange={() => handleCommunication('communication_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Add An User</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.adduser_access} onChange={() => handleAdduser('adduser_access')} />
                        </Table.Cell>
                    </Table.Row>


                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Assignment History</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.history_access} onChange={() => handleHistory('history_access')} />
                        </Table.Cell>
                    </Table.Row>


                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Feedback</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.feedback_access} onChange={() => handleFeedback('feedback_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Job Advert</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.jobadvert_access} onChange={() => handleJobadvert('jobadvert_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Invoices</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.invoice_access} onChange={() => handleInvoice('invoice_access')} />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Billing</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.bill_access} onChange={() => handleBills('bill_access')} />
                        </Table.Cell>
                    </Table.Row>

                   

                    <Table.Row >
                        <Table.Cell width='1' textAlign='center'>Reports</Table.Cell>
                        <Table.Cell width='1' textAlign='center'>
                            <Checkbox toggle checked={data.report_access} onChange={() => handleReports('report_access')} />
                        </Table.Cell>
                    </Table.Row>

                </Table.Body>
            </Table>
        </>
    )
}

export default AllocateAccess
