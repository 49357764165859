import React, { useState, useEffect } from "react";

// Material Core Components
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormOne from "./FormOne";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";

const AdminRegistration = () => {
  const classes = useEmployeeStyles();
  const [supreme, setSupreme] = useState([]);


  const SupremeDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/supreme/manager/getsuper`
      );
      setSupreme(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };


  useEffect(() => {
    SupremeDetails();
  }, []);

  return (
    <div className={classes.root}>
      {supreme?.supreme_role == 1 ?
        <Card>
          <div
            style={{
              backgroundImage:
                "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
              padding: "20px",
              textAlign: "center",
              boxShadow:
                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
            }}
          >
            This page is not available.
          </div>
        </Card> :
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    Supreme Manager Registration
                  </Typography>
                  <Typography variant="subtitle2"></Typography>
                </div>
                <FormOne />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      }
    </div>
  );
};

export default AdminRegistration;
