import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { Header } from 'semantic-ui-react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import { IconButton } from "@material-ui/core";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import UnconfirmedOptions from "components/Employee/BookingPanel/UnconfirmedAssignments/UnconfirmedOptions";
import ListBACAssignments from "components/Employee/BookingPanel/UnconfirmedAssignments/BAC/ListBACAssignments/ListBACAssignments";
import ListBACTransAssignments from "components/Employee/BookingPanel/UnconfirmedAssignments/BAC/ListBACTransAssignments/ListBACTransAssignments";
import ListBACQuotations from "components/Employee/BookingPanel/UnconfirmedAssignments/BAC/ListBACLinQuote/ListBACAssignments";
import { useParams } from "react-router-dom";

const BACUnconfirmedAssignments = () => {
  const classes = useDashboardStyles();
  const { data, type } = useParams();

  return (
    <Employee>
      <EmployeeLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{
                display: "flex",
                justifyContent: "space-between",
              }} color="primary">
                <p className={classes.cardTitleWhite}>Unconfirmed Assignments (Billing Account Client)</p>
                <IconButton color='inherit'><ArrowBackIcon onClick={() => history.goBack()} /></IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <UnconfirmedOptions />
              </CardHeader>
            </Card>
            <Card>

              <CardBody>
                <Header as='h4'>Interpreting Assignments</Header>
                <ListBACAssignments interpretingData={type === "interpreting" && data}/>
                <Header as='h4'>Translation Assignments</Header>
                <ListBACTransAssignments translationData={type === "translation" && data}/>
                <Header as='h4'>Quotation</Header>
                <ListBACQuotations quotationData={type === "quotation" && data}/>
              </CardBody>
            </Card>

          </GridItem>
        </GridContainer>

      </EmployeeLayout>
    </Employee>
  );
};

export default BACUnconfirmedAssignments;

