import React, { useEffect, useState } from "react";

// Material Core Components
import Typography from "@material-ui/core/Typography";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import moment from "moment";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CustomAvatar from "components/Common/CustomAvatar";

const RegistrationForm = () => {
  const classes = useDashboardStyles();
  const [logs, setLogs] = useState();
  const tz = moment().tz(moment.tz.guess()).format("z");

  useEffect(() => {
    const activityLogs = async () => {
      const token = Cookies.get("EMPtoken");
      const response = await Axios.get(`/api/employee/view-activity-log`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLogs(response?.data);
    };

    activityLogs();
  }, []);
  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  Activity Logs
                </Typography>
              </div>
              {logs?.map((log) => (
                <Card>
                  <CardHeader
                    color="primary"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className={classes.cardTitleWhite}>
                      Log date: {moment(log.LoggedTime).format("DD/MMM/YYYY")} (
                      {tz})
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Typography component="h5" variant="h6">
                      Login date:{" "}
                      {moment(log.LoggedTime).format("DD/MMM/YYYY , hh:mm A")} (
                      {tz})
                    </Typography>{" "}
                    {log.LogoutTime && (
                      <Typography component="h5" variant="h6">
                        Logout date:{" "}
                        {moment(log.LogoutTime).format("DD/MMM/YYYY , hh:mm A")}{" "}
                        ({tz})
                      </Typography>
                    )}
                    <Typography component="h5" variant="h6">
                      Location from or around: {log.Location}
                    </Typography>
                    <Typography component="h5" variant="h6">
                      IP: {log?.ADMINID?.IP_Address}
                    </Typography>
                    <Typography component="h5" variant="h6">
                      Device details: {log.Device}, {log.Browser}
                    </Typography>
                  </CardBody>
                </Card>
              ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default RegistrationForm;
