import React from "react";
import { Button, Icon } from "semantic-ui-react";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { Container, Typography } from "@material-ui/core";
import TwoCards from "./TwoCards";
import history from "utils/history";

function Footer({ id, name }) {
  const classes = useLandingpageStyles();

  const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary">
        {"© "}
        Linguists Collective Ltd<sup>®</sup> {new Date().getFullYear()}
      </Typography>
    );
  };

  return (
    <>
      <div
        style={{
          marginBottom: "10px",
          width: "100%",
          padding: "30px",
        }}
      >
        <>
          <TwoCards objID={id} name={name} />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50% 50%",
              alignItems: "baseline",
            }}
          >
            <Button
              style={{ marginBottom: 10 }}
              color="purple"
              onClick={() => history.push(`/sppo/inhouse-client-form/${id}`)}
            >
              Register with us (INC)
            </Button>
            <Button
              color="purple"
              onClick={() =>
                window.open(
                  "https://linguistscollective.com/post-job-advert",
                  "_blank"
                )
              }
            >
              Post a job advert
            </Button>
          </div>

          <div
            style={{
              marginTop: 10,
              width: "100%",
            }}
          >
            <Button
              style={{ marginBottom: 10, width: "100%" }}
              color="purple"
              onClick={() =>
                history.push(`/add-new-customer/${id}/sppocontact`)
              }
            >
              Leave details to be contacted by our Sales and Marketing Team
            </Button>
          </div>

          <div
            style={{
              marginTop: 10,
              display: "grid",
              gridTemplateColumns: "33.4% 33.5% 33.4% ",
              width: "100%",
            }}
          >
            <Button
              onClick={() =>
                window.open(
                  "https://www.facebook.com/LinguistsCollective/",
                  "_blank"
                )
              }
              color="facebook"
            >
              <Icon name="facebook" /> Facebook
            </Button>

            <Button
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/linguistscollective",
                  "_blank"
                )
              }
              color="linkedin"
            >
              <Icon name="linkedin" /> LinkedIn
            </Button>

            <Button
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCy_sIqjGtj4wRFv8f45Qyig",
                  "_blank"
                )
              }
              color="youtube"
            >
              <Icon name="youtube" /> YouTube
            </Button>
          </div>
        </>
      </div>

      <footer className={classes.footer}>
        <div
          style={{
            width: "100%",
            margin: "auto",
            padding: "1rem 7rem 1rem",
            display: "grid",
            gridTemplateColumns: "50% 50%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Container style={{ padding: "0px", margin: "0px" }} maxWidth="sm">
            <Copyright />
          </Container>
          <div className={classes.signature}>
            <p>
              Builder:
              {/* Signature by */}{" "}
              <a href="https://cambridgeitconsultancy.co.uk/">{"{CITC} "}</a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
