import cookie from 'js-cookie';


import {
  EMPLOYEE_SIGNIN_FAIL,
  EMPLOYEE_SIGNIN_REQUEST,
  EMPLOYEE_SIGNIN_SUCCESS,
} from "constants/cashClient_constant";
import defaultAxios from "utils/axios";

export const employeeSignin = (email, password) => async (dispatch) => {
  dispatch({ type: EMPLOYEE_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await defaultAxios.post("/api/employee/signin", {
      email,
      password,
    });
    dispatch({ type: EMPLOYEE_SIGNIN_SUCCESS, payload: data });
    // console.log(data);
    localStorage.setItem("EMPuser", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: EMPLOYEE_SIGNIN_FAIL,
      payload:
        error.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message,
    });
  }
};



// get cookie
export const getCookie = (key) => {
  if(process.browser){
      return cookie.get(key)
  }
};

export const isAuth = () => {
  if(process.browser){
      const cookieChecked = getCookie('EMPtoken');
      if(cookieChecked){
          if(localStorage.getItem('EMPuser')){
              return JSON.parse(localStorage.getItem('EMPuser'));
          }
          else{
              return false;
          }
      }
  }
}
