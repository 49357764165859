import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import CashClientLayout from "layout/CashClientLayout";

// Custom Components
// Custom Styles
import ViewAttach from "components/Admin/ManagementPanel/ViewAttachMessage";

const ViewContactCard = () => {
  return (
    <CashClientLayout>
      <Cashclient>
        <ViewAttach />
      </Cashclient>
    </CashClientLayout>
  );
};

export default ViewContactCard;
