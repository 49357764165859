import React, { useState, useEffect } from "react";
import history from "utils/history";
import StripeLayout from "layout/StripeLayout";
import CheckoutForm from "layout/CheckoutFormLayout/CheckoutFormPRC";
import Cookies from "js-cookie";

import Axios from "axios";
import { useParams } from "react-router";

const PayTelINCBI = () => {

  const { token } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getDetails = async (req, res) => {
      try {
        const response = await Axios.put(
          `${process.env.REACT_APP_BACKEND_API}/get/pay/inc/telephonic/interpreter/details`,
          {
            token: token,
          }
        );

        Cookies.set("prcBookingID", response?.data.assignment_id);
        setData(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getDetails();
  }, []);

  return data ? (
    <StripeLayout clientId={data.assignment_id}>
      <CheckoutForm
        price={data.total}
        currency={data.currency}
        onSuccessfulCheckout={(id) =>
          history.push(
            `/private-client/interpreter/success/estimate/payment/${id}`
          )
        }
        prcClientId={data.clientID}
      />
    </StripeLayout>
  ) : null;
};

export default PayTelINCBI;
