import React, { useState, useEffect } from "react";
import SPPO from "components/RouteProtection/Sppo/Sppo";
import SppoLayout from "layout/SppoLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Profile from "components/Sppo/MyClients/Profile/ViewProfile";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Cookies from 'js-cookie'
import Axios from 'axios'
import { Header } from "semantic-ui-react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import { useParams } from "react-router";


const AddAnUserPage = () => {
    const classes = useDashboardStyles();
    const [sppoID, setSPPOID] = useState(null)
    const { id } = useParams();
    const getDetails = async (e) => {
        const token = Cookies.get("SPPOtoken");
        const response = await Axios.get(
            `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        setSPPOID(response?.data);
    };
    useEffect(() => {
        getDetails();
    }, []);


    return (
        <SppoLayout>
            <SPPO>
                <GridContainer>
                    {sppoID?.agent_sppo && sppoID?.my_client_access ?
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color="primary"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                    <p className={classes.cardTitleWhite}>View Client Profile</p>
                                    <IconButton color="inherit">
                                        <ArrowBackIcon
                                            onClick={() => history.goBack()}
                                        ></ArrowBackIcon>
                                    </IconButton>
                                </CardHeader>
                                <CardBody>
                                    <Profile id={id} />
                                </CardBody>
                            </Card>
                        </GridItem>
                        :
                        sppoID?.profile_update ?
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardHeader color="primary"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                        <p className={classes.cardTitleWhite}>View Client Profile</p>
                                        <IconButton color="inherit">
                                            <ArrowBackIcon
                                                onClick={() => history.goBack()}
                                            ></ArrowBackIcon>
                                        </IconButton>
                                    </CardHeader>
                                    <CardBody>
                                        <Profile id={id} />
                                    </CardBody>
                                </Card>
                            </GridItem>
                            :
                            sppoID?.agent_sppo && sppoID?.profile_update === false ?
                                <Header as='h3'>Access denied.</Header>
                                :
                                sppoID?.profile_update === false ?
                                    <Header as='h3'>Access denied. <a href={`/sppo/full-registration`}>Please complete full registration.</a></Header>
                                    :
                                    <Header as='h3'>Loading...</Header>
                    }
                </GridContainer>

            </SPPO>

        </SppoLayout>
    );
};

export default AddAnUserPage;
