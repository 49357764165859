import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material Core Components
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";

// Others
import { setSelectedPercent } from "redux/actions/admin/controller";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "150px",
    margin: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
  valueDiv: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
  inputDiv: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    alignItems: "center",
    "& .MuiIconButton-root": {
      padding: "0",
      height: "24px",
    },
  },
}));

const PercentageInput = ({ type, value, item, name }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [rate, setRate] = useState("");
  const [open, setOpen] = useState(false);

  const { selectedPercent } = useSelector((state) => state.adminControlData);

  useEffect(() => {
    let vl = value.replace("%", "");
    setRate(+vl);
  }, [value]);

  const handleChange = (event) => {
    if (isNaN(Number(event.target.value))) {
      return;
    }

    if (event.target.value > 100) {
      setOpen(true);
      return;
    }

    const inputValue = event.target.value;

    const newItem = { ...selectedPercent };
    newItem[name] = Number(inputValue);
    dispatch(setSelectedPercent(newItem));

    setRate(event.target.value);
  };

  const isOpen = selectedPercent && item._id === selectedPercent._id;

  return (
    <div className={classes.root}>
      {value && !isOpen ? (
        <div className={classes.valueDiv}>
          <p>{value}</p>
        </div>
      ) : (
        <div className={classes.inputDiv}>
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-rate">Percent</InputLabel>
            <OutlinedInput
              id="outlined-adornment-rate"
              value={rate}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  {type === "percent" ? "%" : "%"}
                </InputAdornment>
              }
              labelWidth={60}
            />
          </FormControl>
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        message="Value can not be more than 100%"
        key={"top" + "center"}
      />
    </div>
  );
};
export default PercentageInput;
