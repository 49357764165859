import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomSelect from "components/Common/CustomSelect";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { CURRENT_COUNTRY, EMAIL_REGEX } from "data";
import { updateCrStep, changeCrInput } from "redux/actions/crAction";
import CustomDate from "components/Common/CustomDate";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPhone from "components/Common/CustomPhone";
import { Button, Header } from "semantic-ui-react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import Typography from "@material-ui/core/Typography";

const FormOne = () => {
  const { data } = useSelector((state) => state.crReducer);
  // const [photo, setPhoto] = useState(null);
  // const [passport, setPassport] = useState(null);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const [notValid, setNotValid] = useState(false);

  const FORM_TYPE = [
    "Quote Request (page 2)",
    "Contact / Service Enquiry",
    "Call me back / leaving my details",
    "Client registration",
  ];

  const {
    AddressLine1,
    AddressLine2,
    contact_address,
    City,
    Country,
    FirstName,
    LastName,
    enquiry_reason,
    PostalCode,
    Region,
    direct_line,
    business_email,
    mobile_no,
    position,
    title,
    photo,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateCrStep(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeCrInput({
        name: "Country",
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeCrInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handlePhoto = async (e) => {
    dispatch(
      changeCrInput({
        name: "photo",
        value: e.target.files[0],
      })
    );
    // setPhoto(e.target.files[0]);
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const isAllow =
    !FirstName ||
    !LastName ||
    !enquiry_reason ||
    (contact_address?.includes("Same") && !AddressLine1) ||
    (contact_address?.includes("Same") && !City) ||
    (contact_address?.includes("Same") && !Region) ||
    (contact_address?.includes("Same") && !PostalCode) ||
    (contact_address?.includes("Same") && !Country);

  return (
    <div>
      <h4 style={{ textAlign: "center", paddingTop: "5px" }}>
        Personal or Company Legal Representative Details
      </h4>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          value={title}
          name="title"
          label="Title"
          width="100%"
          placeholder="e.g. Mr, Mrs, Miss, Ms etc"
        />
        <CustomInputSingle
          onChange={handleChange}
          value={FirstName}
          name="FirstName"
          label="First Name(s)"
          width="100%"
          required
          placeholder="First Name(s) (Legal representative/director)"
        />
        <CustomInputSingle
          onChange={handleChange}
          value={LastName}
          name="LastName"
          label="Last Name"
          width="100%"
          required
          placeholder="Last Name (Legal representative/director)"
        />
        <CustomBigInputSingle
          onChange={handleEmailChange}
          value={business_email}
          name="business_email"
          label="Direct / personal email"
          moreInfo="If different"
          width="100%"
          placeholder="e.g. example@example.com"
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <br />
        <CustomPhone
          label="Mobile Number"
          onChange={handleMobile}
          value={mobile_no}
          name="mobile_no"
          placeholder="Mobile Number (Please select country then type number e.g. +44 7307 000000)"
        />
        <CustomInputSingle
          onChange={handleChange}
          value={position}
          name="position"
          label="Your position"
          width="100%"
          placeholder="e.g. Manager, Director etc"
        />
        <CustomSelect
          onChange={handleChange}
          label="Enquiry Reason"
          data={FORM_TYPE}
          width="100%"
          name="enquiry_reason"
          value={enquiry_reason}
          required
        />
        <br />
        <input
          accept="image/jpeg, image/png"
          ref={fileInput}
          onChange={(e) => handlePhoto(e)}
          type="file"
          name="photo"
          style={{ display: "none" }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
            Upload profile photo or business logo
          </Header>
          <div>
            <Button onClick={handleClick}>Upload</Button>
          </div>
          {photo && (
            <Header style={{ margin: "0", textAlign: "center" }} as="h5">
              <Header style={{ margin: "0" }} as="h1">
                <InsertPhotoIcon fontSize="large" />
              </Header>
              {photo.name}
              <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                photo.size / 1024
              ).toFixed(2)}kb`}</Header>
              <Header
                style={{ margin: "0" }}
                as="h5"
              >{`Last Modified: ${photo?.lastModifiedDate}`}</Header>
            </Header>
          )}
        </div>
        <br /> <br />
        <Typography variant="subtitle2">
          {" "}
          Legal representative / personal address:
        </Typography>
        {contact_address?.includes("Same") ? (
          <>
            <CustomInputSingle
              onChange={handleChange}
              value={AddressLine1}
              name="AddressLine1"
              label="House number/name, Street name"
              width="100%"
              required
              placeholder="e.g. Flat/Building Name, 101 Example Street"
            />
            <CustomInputSingle
              onChange={handleChange}
              value={AddressLine2}
              name="AddressLine2"
              label="Address line 2"
              width="100%"
              placeholder="Additional Address Information"
            />
            <CustomInputSingle
              onChange={handleChange}
              value={City}
              name="City"
              label="Town / City"
              width="100%"
              required
              placeholder="Your Village, Town or City Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="County / Region"
              width="100%"
              name="Region"
              value={Region}
              required
              placeholder="Your County, Division or State Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              value={PostalCode}
              name="PostalCode"
              label="Post Code / Zip Code / Post office"
              width="100%"
              required
              placeholder="Your Post Code, Zip Code or Post Office Name"
            />
            <CustomAutoSelect
              onChange={handleAutoCompleteCO}
              label="Country"
              moreInfo="Current country of residence"
              width="100%"
              value={Country}
              required
              data={CURRENT_COUNTRY}
            />
          </>
        ) : (
          <>
            <CustomInputSingle
              onChange={handleChange}
              value={AddressLine1}
              name="AddressLine1"
              label="House number/name, Street name"
              width="100%"
              placeholder="e.g. Flat/Building Name, 101 Example Street"
            />
            <CustomInputSingle
              onChange={handleChange}
              value={AddressLine2}
              name="AddressLine2"
              label="Address line 2"
              width="100%"
              placeholder="Additional Address Information"
            />
            <CustomInputSingle
              onChange={handleChange}
              value={City}
              name="City"
              label="Town / City"
              width="100%"
              placeholder="Your Village, Town or City Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="County / Region"
              width="100%"
              name="Region"
              value={Region}
              placeholder="Your County, Division or State Name"
            />
            <CustomInputSingle
              onChange={handleChange}
              value={PostalCode}
              name="PostalCode"
              label="Post Code / Zip Code / Post office"
              width="100%"
              placeholder="Your Post Code, Zip Code or Post Office Name"
            />
            <CustomAutoSelect
              onChange={handleAutoCompleteCO}
              label="Country"
              moreInfo="Current country of residence"
              width="100%"
              value={Country}
              data={CURRENT_COUNTRY}
            />
          </>
        )}
        <CustomInputSingle
          onChange={handleChange}
          value={direct_line}
          name="direct_line"
          label="Direct line with extension no"
          width="100%"
          placeholder="Your direct line no + any extension no (e.g. +44123456789 Ext. 123)"
        />
        <div className={classes.buttonFlex}>
          <CustomButton
            text="Next"
            onClick={handleNext}
            disabled={isAllow || notValid}
          />
        </div>
      </form>
    </div>
  );
};

export default FormOne;
