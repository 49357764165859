import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// Material Core Components
import Typography from "@material-ui/core/Typography";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormTwo from "./FormTwo";
import CustomAvatar from "components/Common/CustomAvatar";
// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

const LinguistsCollectiveBooing = ({ title }) => {
  const { count } = useSelector((state) => state.bookingReducer);
  const classes = useEmployeeStyles();
  const history = useHistory();

  const selectedForm = count;

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={8} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  {title ? (
                    <Fragment>{title}</Fragment>
                  ) : (
                    <Fragment>
                      BAC Interpreting Assignment Booking Form
                    </Fragment>
                  )}
                </Typography>
                {/* <Typography style={{ marginTop: "5px" }} variant="subtitle1">
                  Looking for monthly booking?{" "}
                  <Link
                    target="_blank"
                    color="primary"
                    to="/interpreting-or-translation/interpreting-form"
                  >
                    Bulk Booking Form{" "}
                  </Link>
                </Typography> */}
              </div>
              <div className={classes.notes}>
                <Typography variant="h6" gutterBottom>
                  Note to the customer
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Minimum Duration: RI/VRI - 1.0h, FTF - 2.0h, Court and
                  Conference - 4.0h.
                </Typography>
              </div>
              {selectedForm === 1 && <FormTwo />}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default LinguistsCollectiveBooing;
