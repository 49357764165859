import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
// Custom Components
import Action from "./BAC_Bulk_Action";
import Cookies from "js-cookie";
import Axios from "axios";
import history from "utils/history";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Button, Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import CustomModal from "components/Common/CustomModal";
import ViewAll from "../bacViewAll";
import { useToasts } from "react-toast-notifications";
import SearchView from "../InterpretingSearch/SearchView";

const BAC = ({ details, interpretingData }) => {
  const classes = useDashboardStyles();
  const [SearchText, setSearchText] = useState("");
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const { addToast } = useToasts();
  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const linAddress =
    details &&
    `${details?.Region} ${details?.PostalCode}, ${details?.City}, ${details?.Country}`;
  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-accountclient-current-booking/${SearchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });

        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const { bookingList, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/accountclient-current-bulk-booking-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({
        ...values,
        bookingList: interpretingData
          ? response?.data.filter((item) => item.id === interpretingData)
          : response?.data,
        reload: false,
      });
      interpretingData && setShow(true);
    } catch (error) {
      console.log(error?.response);
    }
  };

  const handleViewAll = async (e, list) => {
    e.preventDefault();
    setViewAll({ ...viewAll, open: true, list: list });
  };

  return (
    <div>
      {!interpretingData && (
        <SearchView
          show={show}
          load={loadbookingList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          SearchText={SearchText}
          setShow={setShow}
        />
      )}
      <Paper className={classes.root}>
        <CustomModal
          open={viewAll.open}
          handleClose={() => setViewAll({ ...viewAll, open: false })}
        >
          <ViewAll data={viewAll.list} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              basic
              color="purple"
              onClick={() => setViewAll({ ...viewAll, open: false })}
            >
              Close
            </Button>
            <Button
              color="purple"
              onClick={() =>
                history.push(
                  `current-assignment/bac/timesheet/${viewAll.list._id}`
                )
              }
            >
              Close and timesheet
            </Button>
          </div>
        </CustomModal>

        {show && (
          <div>
            <TableContainer className={classes.container}>
              <Table color="pink" stickyHeader aria-label="sticky table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="1">Assignment ID</Table.HeaderCell>
                    <Table.HeaderCell width="1">Timezone</Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Start date & time
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Duration</Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Source language
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Target language
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Service Required
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Security Level
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Estimate Amount
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Payment Status
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {bookingList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((list) => (
                      <Table.Row tabIndex={-1} key={list._id}>
                        {list.interpreting_service_required ===
                        "Remote Interpreting (RI) / Video Remote Interpreting (VRI)" ? (
                          <Table.Cell key={list._id} align={list.align}>
                            <Link
                              to={`/linguist/bac-view-interpreting-assignment/${
                                list._id
                              }/${false}`}
                            >
                              {list?.id}
                            </Link>
                          </Table.Cell>
                        ) : (
                          <Table.Cell key={list._id} align={list.align}>
                            <Link
                              to={`/linguist/bac-view-interpreting-assignment/${
                                list._id
                              }/${linAddress}/${true}`}
                            >
                              {list?.id}
                            </Link>
                          </Table.Cell>
                        )}

                        <Table.Cell>{list.timezone}</Table.Cell>
                        <Table.Cell>
                          {moment(list.startdateArray[0]).format("DD-MMM-YYYY")}{" "}
                          - {list?.starttimeArray[0]}
                        </Table.Cell>
                        <Table.Cell>
                          {list.gethour ? list.gethour : list.total_Duration}
                        </Table.Cell>
                        <Table.Cell>{list.source_language}</Table.Cell>
                        <Table.Cell>{list.target_language}</Table.Cell>
                        <Table.Cell>
                          {list.target_linguist_skill_level
                            ? list.target_linguist_skill_level
                            : list.target_language_level}
                        </Table.Cell>
                        <Table.Cell>
                          {list.typesof_interpreting_required}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.currency_new?.substring(0, 3)} {list.estimate}
                        </Table.Cell>
                        <Table.Cell>{list.status}</Table.Cell>
                        <Table.Cell>
                          {list.startdateArray?.length > 1 ? (
                            <Button
                              color="purple"
                              onClick={(e) => handleViewAll(e, list)}
                            >
                              View All
                            </Button>
                          ) : (
                            <Action client={list.id} id={list._id} month_duration={list?.month_duration} />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default BAC;
