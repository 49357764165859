const {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SET_LOADING,
} = require("redux/actions/actionTypes");

const INITIAL_STATE = { data: [], loading: false };

const authReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FORGOT_PASSWORD:
      return {
        ...state,
        data: payload,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        data: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
