import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import LandingPageLayout from "layout/LandingPageLayout";
import { Card, Typography, Divider } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import ThreeCards from "./ThreeCards";
import Timage from "assets/images/Translate.jpg";
import WTimage from "assets/icons/world-translation.png";
import TRimage from "assets/icons/translator.png";

const Translation = () => {
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 4rem 0 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div>
            <img src={Timage} alt="" style={{ width: "100%" }} />
          </div>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
              }}
            >
              <div>
                <img src={WTimage} alt="" style={{ width: "15%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700" }}
                  paragraph
                  variant="h5"
                >
                  Request a Translation
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  A translation order can be placed using the form in dashboard
                  for various certificates, marksheets, court orders, police
                  statements, public notices, transcripts etc. Up to 10,000
                  words or 20 A4 pages of an order can be placed online. If your
                  project is more than 10,000 words or 20 A4 pages, please use
                  the quotation form to send us details for the project and one
                  of our linguists, or a member of our Admin team will contact
                  you to discuss further, if necessary. They will provide you
                  with best price according to your request.
                </Typography>
                <br />
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  For large volumes of work, we may need to appoint a project
                  co-ordinator and multiple linguists. If such is the case, we
                  will be in touch to organise a meeting. This will be a free
                  consultation for our clients. Order human translation
                  <Link to="/cash-client-login"> online</Link>.
                </Typography>
                <br />
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
              }}
            >
              <div>
                <img src={TRimage} alt="" style={{ width: "10%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  What Is Translation?
                </Typography>
                <br />
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Translation is rendering text from a source language into a
                  target language| while preserving meaning. Translation is
                  written.
                </Typography>
                <br />
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  While linguistic and cultural skills are critical, the most
                  important quality a good translator must have is the ability
                  to write well in the target language. Skilled translators must
                  have the ability to understand the source language and the
                  culture of the country where the text originated, then using a
                  good library of dictionaries and reference materials, to
                  render that material clearly and accurately into the target
                  language.
                </Typography>
                <br />
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Even bilingual individuals can rarely express themselves in a
                  given subject equally well in both languages, and many
                  excellent translators are not fully bilingual to begin with.
                  Knowing this limitation, a good translator will only translate
                  documents into his or her native language.
                </Typography>
                <br />
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  When choosing a translator it is important to insist that the
                  translator only translate into their native language and into
                  an area of their subject matter expertise.
                </Typography>
                <br />
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <ThreeCards />
      </div>
      {/* </div> */}
    </LandingPageLayout>
  );
};

export default Translation;
