import CustomTextInput from "components/Common/CustomTextInput";
import React, { useRef, useState, useEffect } from "react";
import { Button, Dimmer, Loader, Table } from "semantic-ui-react";
import { Header, Checkbox } from "semantic-ui-react";
import { IconButton, TextField } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import history from "utils/history";
import RichTextEditor from "react-rte";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import moment from "moment";
import CustomTime from "components/Common/CustomTime";

const Details = () => {
  let sigPad = useRef({});
  const [signature, setSignature] = useState(false);
  const [text, setText] = useState();
  const [projectReport, setProjectReport] = useState(null);
  const [linguist, setLinguist] = useState(null);
  const { id } = useParams();
  const token = Cookies.get("BACtoken");
  const [declaration, setDeclaration] = useState(false);
  const [details, setDetails] = useState({
    project_name: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    request_details: "",
    target_language_level: "",
    security_clearence: "",
    word_count: "",
    visual_formatting: "",
    certification: "",
    post_certification: "",
    project_report: RichTextEditor.createEmptyValue(),
    signature: "",
    supervisor_fullname: "",
    date: "",
    feedbackByClient: "",
    remarksByLin: "",
    loader: false,
  });
  const uploadRef = useRef();
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const todayDate = moment().format("DD/MM/YYYY");

  useEffect(() => {
    const getProjectReport = async (e) => {
      try {
        const response = await Axios.get(
          `/api/accountclient/translation-view/translationID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProjectReport(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    const getLinguist = async (e) => {
      try {
        const response = await Axios.get("/api/accountclient/dashboard", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response);
        setLinguist(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getProjectReport();
    getLinguist();
  }, []);

  const handleClose = (e) => {
    e.preventDefault();
    setSignature(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = () => {
    const temp = sigPad.current.toDataURL();
    setDetails({ ...details, signature: temp });
    setSignature(false);
  };

  const handleText = (value) => {
    setText(value.toString("markdown"));
    setDetails({ ...details, project_report: value });
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });
    const fd = new FormData();

    const file = new File([details.signature], "signature", {
      type: "image/png",
    });

    fd.append("project_name", details.project_name || "-");
    fd.append("start_date", details.start_date || moment(projectReport.createdAt).format("DD/MMM/YYYY"));
    fd.append("end_date", details.end_date || moment(projectReport.deadline).format("DD/MMM/YYYY"));
    fd.append("start_time", details.start_time || moment(projectReport.createdAt).format("HH:mm"));
    fd.append("end_time", details.end_time || moment(projectReport.deadline).format("HH:mm"));
    fd.append("requested_start_date", moment(projectReport.createdAt).format("DD/MMM/YYYY") || "-");
    fd.append("requested_end_date", moment(projectReport.deadline).format("DD/MMM/YYYY") || "-");
    fd.append("requested_start_time", moment(projectReport.createdAt).format("HH:mm") || "-");
    fd.append("requested_end_time", moment(projectReport.deadline).format("HH:mm") || "-");
    fd.append("requested_wordCount", projectReport?.wordCount || "-");
    fd.append("word_count", details.word_count || projectReport?.wordCount);
    fd.append("request_details", details.request_details || "-");
    fd.append(
      "target_language_level",
      projectReport.target_expertise_level_required || "-"
    );
    fd.append("security_clearence", projectReport.security_clearance || "-");
    fd.append(
      "visual_formatting",
      projectReport.require_visual_formating || "-"
    );
    fd.append("certification", projectReport.certificate || "-");
    fd.append("post_certification", projectReport.certified_translation || "-");
    fd.append("project_report", text || "-");
    fd.append("signatureByClient", file);
    fd.append("supervisor_fullname", "");
    fd.append("date", moment().format("YYYY/MM/DD"));
    fd.append("feedbackByClient", parseInt(details.feedbackByClient));

    if (uploadTimesheet?.size) {
      fd.append("documentByClient", uploadTimesheet);
    }

    try {
      const response = await Axios.post(
        `/api/accountclient/Make-Project-Report/translationID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDetails({ ...details, loader: false });
      history.push(`/account-client/confirmed-translations`);
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  return projectReport && linguist ? (
    <div>
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          // onEnd = {handleSave}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            basic
            color="purple"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="purple">
            Save
          </Button>
        </div>
      </CustomModal>
      <CustomTextInput
        label="Assignment reference number"
        value={projectReport.id}
      />
      {/* <CustomTextInput label= 'Translation / Training /Other Project name' value= 'abc' /> */}

      {/* Table */}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center">Requested</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actual</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Project start date<b style={{ color: "red" }}>*</b>: </Table.Cell>
            <Table.Cell textAlign="center">
              {moment(projectReport.createdAt).format("DD/MMM/YYYY")}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <TextField
                type="date"
                value={details.start_date}
                onChange={(e) =>
                  setDetails({ ...details, start_date: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project start time<b style={{ color: "red" }}>*</b>: </Table.Cell>
            <Table.Cell textAlign="center">
              {moment(projectReport.createdAt).format("HH:mm")}
            </Table.Cell>
            <Table.Cell>
              <CustomTime
                value={details.start_time}
                onChange={(e) =>
                  setDetails({ ...details, start_time: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project end date<b style={{ color: "red" }}>*</b>: </Table.Cell>

            <Table.Cell textAlign="center">
              {projectReport?.deadline &&
                moment(projectReport.deadline).format("DD/MMM/YYYY")}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <TextField
                type="date"
                value={details.end_date}
                onChange={(e) =>
                  setDetails({ ...details, end_date: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project end time<b style={{ color: "red" }}>*</b>: </Table.Cell>
            <Table.Cell textAlign="center">
              {projectReport?.deadline &&
                moment(projectReport.deadline).format("HH:mm")}
            </Table.Cell>
            <Table.Cell>
              <CustomTime
                value={details.end_time}
                onChange={(e) =>
                  setDetails({ ...details, end_time: e.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell>
              {projectReport.target_expertise_level_required}
            </Table.Cell>
            <Table.Cell>{linguist.target_language_level}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Security clearance/s</Table.Cell>
            <Table.Cell>
              {projectReport.security_clearance
                ? projectReport.security_clearance
                : "N/A"}
            </Table.Cell>
            <Table.Cell>
              {projectReport.security_clearance
                ? projectReport.security_clearance
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Wordcount<b style={{ color: "red" }}>*</b></Table.Cell>
            <Table.Cell>{projectReport.wordCount}</Table.Cell>
            <Table.Cell>
              <CustomTextInput
                number={true}
                name="word_count"
                onChange={(e) =>
                  setDetails({ ...details, word_count: e.target.value })
                }
                value={details.word_count}
                edit={true}
                placeholder="100"
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Visual formatting</Table.Cell>
            <Table.Cell>{projectReport.require_visual_formating}</Table.Cell>
            <Table.Cell>{projectReport.require_visual_formating}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Certification</Table.Cell>
            <Table.Cell>{projectReport.certificate}</Table.Cell>
            <Table.Cell>{projectReport.certificate}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Post certification </Table.Cell>
            <Table.Cell>{projectReport.certified_translation}</Table.Cell>
            <Table.Cell>{projectReport.certified_translation}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">Project Report<b style={{ color: "red" }}>*</b></Header>
      <RichTextEditor value={details.project_report} onChange={handleText} />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
            {/* <Table.HeaderCell>
              To be verified by project supervisor / quality control (if
              applicable){" "}
            </Table.HeaderCell> */}
            <Table.HeaderCell>To be completed by client</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Checkbox
                label="I declare the information given by me is true/accurate and complete."
                disabled
              />
            </Table.Cell>
            {/* <Table.Cell>
              <Checkbox
                label="I certify that the project was supervised / quality control by me"
                disabled
              />
            </Table.Cell> */}
            <Table.Cell>
              <Checkbox
                checked={declaration}
                onChange={(e, value) => setDeclaration(value.checked)}
                label="I declare that I have checked the information on this form and certify that it is true and complete"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Signature</Table.Cell>
            {/* <Table.Cell>Signature</Table.Cell> */}
            <Table.Cell>
              <div>
                Signature{" "}
                <IconButton onClick={() => setSignature(true)}>
                  <EditIcon />
                </IconButton>
              </div>
              {details.signature && (
                <img
                  style={{ width: "100px", height: "50px" }}
                  src={details.signature}
                  alt=""
                />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Full name:{" "}
              {projectReport.linID.FirstName +
                " " +
                projectReport.linID.LastName}
            </Table.Cell>
            {/* <Table.Cell>Full name: </Table.Cell> */}
            <Table.Cell>
              Full name:{" "}
              {projectReport.createdBy.FirstName +
                " " +
                projectReport.createdBy.LastName}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {todayDate}</Table.Cell>
            {/* <Table.Cell>Date: {todayDate}</Table.Cell> */}
            <Table.Cell>Date: {todayDate}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Feedback</Table.Cell>
            {/* <Table.Cell>Remarks: </Table.Cell> */}
            <Table.Cell>
              Feedback
              <div>
                <Rating
                  name="simple-controlled"
                  value={details.feedbackByClient}
                  onChange={(e) =>
                    setDetails({ ...details, feedbackByClient: e.target.value })
                  }
                />
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button
        onClick={() => uploadRef.current.click()}
        basic
        style={{ backgroundColor: "purple", color: "white" }}
      >
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          loading={details.loader}
          onClick={handleSubmit}
          disabled={
            !declaration ||
            !details.project_report ||
            !details.start_date ||
            !details.start_time ||
            !details.end_date ||
            !details.end_time ||
            !details.word_count ||
            !details.feedbackByClient ||
            !details.signature
          }
          color="purple"
        >
          Submit
        </Button>
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="large">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
