// Custom Components
import CommonCard from "components/Common/CommonCard";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid } from "semantic-ui-react";

const NewsAndNotice = () => {
  const [values, setValues] = useState({ reload: false });

  
  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/employee/view-emp-news-notice`
      );
      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <CommonCard title="News and Notice">
      {values.length > 0 ? (
        <Grid>
          {values?.map((list) => (
            <Grid.Row style={{ textAlign: "center" }}>
              <marquee scrollamount="2" direction="left">
                <h5 style={{ textAlign: "center" }}>{list.news_notice} </h5>
              </marquee>
              <p style={{ textAlign: "center" }}>
                {moment(list.createdAt).format("DD-MMM-YYYY : HH:mm")}
              </p>
            </Grid.Row>
          ))}
        </Grid>
      ) : null}
    </CommonCard>
  );
};

export default NewsAndNotice;
