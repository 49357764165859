import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components

import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import { Button } from "semantic-ui-react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import { ACCOUNT_TYPE, CURRENT_COUNTRY } from "data";

import { changeCAOInput, updateCAOStep } from "redux/actions/caoAction";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPassword from "components/Common/CustomPassword";
import CustomUpload from "components/Common/CustomUpload";
import CustomPhone from "components/Common/CustomPhone";
import { Header } from "semantic-ui-react";
import { EMAIL_REGEX, PASS_REGEX } from "data";

const PageOne = () => {
  const { data } = useSelector((state) => state.caoReducer);
  const dispatch = useDispatch();
  // const [photo, setPhoto] = useState();
  // const [passport, setPassport] = useState();
  const [notValid, setNotValid] = useState(false);
  const [notRegex, setNotRegex] = useState(false);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const {
    account_type,
    other_details,
    business_name,
    AddressLine1,
    AddressLine2,
    City,
    PostalCode,
    Region,
    Country,
    business_website,
    email,
    password,
    confirmation_password,
    business_phone_no,
    company_registration_number,
    charity_registration_number,
    passport,
    photo,
  } = data;

  const classes = useFormStyles();

  const handlePassportFiles = (e) => {
    const { name } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value: e.target.files[0],
      })
    );
  };

  const handleProfileFiles = (e) => {
    const { name } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value: e.target.files[0],
      })
    );
  };

  const handleNext = () => {
    dispatch(updateCAOStep(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value,
      })
    );
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value,
      })
    );
    if (value !== "" && PASS_REGEX.test(value)) {
      setNotRegex(false);
    } else {
      setNotRegex(true);
    }
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value,
      })
    );
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handlePassportClick = (e) => {
    e.preventDefault();
    fileInput2.current.click();
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeCAOInput({
        name: "Country",
        value,
      })
    );
  };

  const handleChangeAT = (e, value) => {
    dispatch(
      changeCAOInput({
        name: "account_type",
        value,
      })
    );
  };

  const handleBusinessPhone = (value) => {
    // inputData({ ...data, mobile_no: value });
    dispatch(
      changeCAOInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const isAllow =
    !account_type ||
    (account_type.includes("Other") && !other_details) ||
    !business_name ||
    !AddressLine1 ||
    !City ||
    !PostalCode ||
    !Region ||
    !Country ||
    !email ||
    notValid ||
    password !== confirmation_password ||
    notRegex;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <div>
          <input
            accept="image/png, image/jpeg"
            ref={fileInput}
            onChange={handleProfileFiles}
            type="file"
            width="100%"
            name="photo"
            style={{ display: "none" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
              Profile photo upload / take
            </Header>
            <div>
              <Button onClick={handleClick}>Upload</Button>
            </div>
            {photo && (
              <div style={{ textAlign: "center" }}>
                <Header style={{ margin: "0" }} as="h1">
                  <InsertPhotoIcon fontSize="large" />
                </Header>{" "}
                <Header style={{ margin: "0" }} as="h5">
                  {photo.name}{" "}
                </Header>
                <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                  photo.size / 1024
                ).toFixed(2)}kb`}</Header>
                <Header
                  style={{ margin: "0" }}
                  as="h5"
                >{`Last Modified: ${photo?.lastModifiedDate}`}</Header>
              </div>
            )}
          </div>
        </div>
        <CustomAutoSelect
          onChange={handleChangeAT}
          label="Account type / sector"
          width="100%"
          name="account_type"
          value={account_type}
          data={ACCOUNT_TYPE}
          required
        />
        {account_type?.includes("Other") ? (
          <CustomInputSingle
            onChange={handleChange}
            width="100%"
            label="Other details"
            name="other_details"
            value={other_details}
            required
          />
        ) : null}
        <CustomInputSingle
          onChange={handleChange}
          label="Business or organization name"
          width="100%"
          name="business_name"
          value={business_name}
          required
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="House number/name, Street name"
          moreInfo="Business address"
          width="100%"
          name="AddressLine1"
          value={AddressLine1}
          required
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Address line 2"
          width="100%"
          name="AddressLine2"
          value={AddressLine2}
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Town / City"
          width="100%"
          name="City"
          value={City}
          required
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Post / Zip Code"
          width="100%"
          name="PostalCode"
          value={PostalCode}
          required
        />
        <CustomInputSingle
          onChange={handleChange}
          label="County / Region"
          width="100%"
          name="Region"
          value={Region}
          required
        />
        <CustomAutoSelect
          onChange={handleAutoCompleteCO}
          label="Country"
          moreInfo="Current Country of residence"
          width="100%"
          name="Country"
          value={Country}
          data={CURRENT_COUNTRY}
          required
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Business website"
          width="100%"
          name="business_website"
          value={business_website}
        />
        <CustomInputSingle
          onChange={handleEmailChange}
          label="Primary email"
          width="100%"
          name="email"
          value={email}
          required
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <CustomPassword
          onChange={handlePasswordChange}
          label="Password"
          width="100%"
          name="password"
          value={password}
          notRegex={notRegex}
          required
        />

        <CustomPassword
          onChange={handleChange}
          label="Confirmation password"
          width="100%"
          name="confirmation_password"
          value={confirmation_password}
          validation={password !== confirmation_password}
          required
        />

        <br />
        <CustomPhone
          label="Business phone number"
          onChange={handleBusinessPhone}
          placeholder="Business phone number"
          value={business_phone_no}
          name="business_phone_no"
        />
        <div>
          <br />
          <input
            ref={fileInput2}
            onChange={handlePassportFiles}
            type="file"
            width="100%"
            name="passport"
            style={{ display: "none" }}
            accept="image/png, image/jpeg"
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
              Upload personal identification (passport/DL/national ID)
            </Header>
            <div>
              <Button onClick={handlePassportClick}>Upload</Button>
            </div>
            {passport && (
              <div style={{ textAlign: "center" }}>
                {" "}
                <Header style={{ margin: "0" }} as="h1">
                  <InsertPhotoIcon fontSize="large" />
                </Header>
                <Header style={{ margin: "0" }} as="h5">
                  {passport.name}{" "}
                </Header>
                <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                  passport.size / 1024
                ).toFixed(2)}kb`}</Header>
                <Header
                  style={{ margin: "0" }}
                  as="h5"
                >{`Last Modified: ${passport?.lastModifiedDate}`}</Header>
              </div>
            )}
          </div>
        </div>
        <CustomBigInputSingle
          onChange={handleChange}
          label="Company registration number"
          moreInfo="If registered"
          width="100%"
          name="company_registration_number"
          value={company_registration_number}
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="Charity registration number"
          moreInfo="If registered"
          width="100%"
          name="charity_registration_number"
          value={charity_registration_number}
        />

        <div className={classes.buttonFlex}>
          <CustomButton disabled={isAllow} text="Next" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default PageOne;
