import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import LinguistLayout from "layout/LinguistLayout";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import Link from "@material-ui/core/Link";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Button } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Options from "components/Linguist/MyClient/INCOptions";



const BookingInterpreter = () => {
  const classes = useDashboardStyles();
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>
                  Add / Remove In-house Client
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>

              </CardHeader>

            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >

                <Options />
              </CardHeader>
              <CardBody>
                {matches ? (
                  <>
                    <Button
                      style={{ marginBottom: "5px", width: "100%" }}
                      onClick={() =>
                        history.push(
                          "/linguist/add-inhouse-client/registration"
                        )
                      }
                      variant="contained"
                      color="primary"
                    >
                      Filling up the form
                    </Button>
                    <Button
                      style={{ marginBottom: "5px", width: "100%" }}
                      onClick={() =>
                        history.push("/linguist/add-inhouse-client/send-form")
                      }
                      variant="contained"
                      color="primary"
                    >
                      Sending form
                    </Button>
                    <Button
                      style={{ marginBottom: "5px", width: "100%" }}
                      onClick={() =>
                        history.push(
                          "/linguist/add-inhouse-client/removal-request"
                        )
                      }
                      variant="contained"
                      color="primary"
                    >
                      Removal Requests
                    </Button>
                    <Link
                      href="/delete-my-inc-account-from-linguist"
                      target="_blank"
                    >
                      {" "}
                      <Button
                        color="primary"
                        variant="contained"
                        style={{
                          width: "100%",
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        {" "}
                        Removal Form
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() =>
                        history.push(
                          "/linguist/add-inhouse-client/registration"
                        )
                      }
                      variant="contained"
                      color="primary"
                    >
                      Filling up the form
                    </Button>
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() =>
                        history.push("/linguist/add-inhouse-client/send-form")
                      }
                      variant="contained"
                      color="primary"
                    >
                      Sending form
                    </Button>
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() =>
                        history.push(
                          "/linguist/add-inhouse-client/removal-request"
                        )
                      }
                      variant="contained"
                      color="primary"
                    >
                      Removal Requests
                    </Button>
                    <Link
                      href="/delete-my-inc-account-from-linguist"
                      target="_blank"
                    >
                      {" "}
                      <Button color="primary" variant="contained">
                        {" "}
                        Removal Form
                      </Button>
                    </Link>
                  </>
                )}
              </CardBody>
            </Card>

            {/* <LinguistsCollectiveBooking /> */}
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default BookingInterpreter;
