import React from 'react'

//ReCaptcha import
import ReCAPTCHA from "react-google-recaptcha";

const CustomCaptcha = ({...rest}) => {
    const recaptchaRef = React.useRef();
    return (
        <>
          <ReCAPTCHA
            style={{ marginTop: "24px" }}
            theme="light"
            ref={recaptchaRef}
            sitekey='6LdfagMaAAAAAOO55BwuYq_ebRqhHGvcSlMXv5K8'
            {...rest}
            //asyncScriptOnLoad={asyncScriptOnLoad}
          />
        </>
    )
}

export default CustomCaptcha
