import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Button, Header } from "semantic-ui-react";
import Visibility from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { Beenhere } from "@material-ui/icons";
import { Brightness1 } from "@material-ui/icons";
import Axios from "utils/axios";
import Cookies from "js-cookie";

export default function StickyHeadTable() {
  const token = Cookies.get("SPPOtoken");
  const [values, setValues] = useState({ reload: false });
  const [readloading, setreadLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [read, setRead] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    setFetching(true);
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/get-all-notification`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues(response?.data);
      setCount(response?.data?.length);
      const user = Cookies.get("ADMID");
      let tempArr = [];
      response?.data?.forEach((item) => {
        if (item?.isRead.length > 0) {
          item?.isRead.forEach((entry) => {
            entry === user && tempArr.push(item.id);
          });
        }
      });
      setRead(tempArr);
      setFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRead = (id, type) => {
    setreadLoading(true);
    Axios.put(
      `${process.env.REACT_APP_BACKEND_API}/sppo/read-mark-single/notificationID/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    setreadLoading(false);

    loadList();
  };
  const handleReadAll = (id, type) => {
    setreadLoading(true);
    let i = 0;
    while (i !== count) {
      Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/read-mark/notificationID/${values[i]?._id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );
      i++;
      setreadLoading(false);
    }
    loadList();
  };
  const handleDelete = async (id, type) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/delete-notification/notificationID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );

      loadList();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDeleteAll = (id, type) => {
    let i = 0;
    while (i !== count) {
      Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/delete-notification/notificationID/${values[i]?._id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );
      i++;
    }
    loadList();
  };

  return (
    <GridContainer style={{ justifyContent: "center" }}>
      <GridItem lg={6} md={6} sm={12}>
        <Card>
          <CardBody>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => handleReadAll()}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Visibility />
                Mark all as read
              </Button>
              <Button
                color="red"
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => handleDeleteAll()}
              >
                <DeleteIcon />
                Delete all
              </Button>
            </div>
          </CardBody>
        </Card>
        {values?.length > 0 ? (
          values?.map((list, i) => (
            <Card>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "block" }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      {!list?.sppo_read &&
                        moment().diff(moment(list?.createdAt), "days") <= 0 && (
                          <Brightness1
                            color="primary"
                            fontSize="sm"
                            style={{ marginRight: 5 }}
                          />
                        )}
                      <Header as="h3">{list?.notification}</Header>
                    </div>
                    <Header as="h5">
                      {" "}
                      {moment(list?.createdAt).fromNow()}
                    </Header>
                  </div>
                  <div style={{ display: "flex" }}>
                    {!list?.sppo_read ? (
                      <>
                        <Button
                          onClick={() => handleRead(list._id, list.id)}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Visibility />
                        </Button>
                        <Button
                          color="red"
                          onClick={() => handleDelete(list._id, list.id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          color="green"
                          disabled
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Beenhere style={{ color: "white" }} />
                        </Button>
                        <Button
                          color="red"
                          onClick={() => handleDelete(list._id, list.id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          ))
        ) : (
          <Header as="h3" textAlign="center">
            nothing to show
          </Header>
        )}
      </GridItem>
    </GridContainer>
  );
}
