import React, { useState, Fragment } from "react";

// Material imports
import { Avatar, IconButton } from "@material-ui/core";
import { Button, Header } from "semantic-ui-react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// Material Icons
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

// Custom imports
import { useStylesAvatar } from "assets/jss/layouts/styleP";
import ProfileModal from "assets/jss/Modals/ProfileModal/ProfileModal";

import Slider from "../../Sliders/Slider";
import publicProfileOne from "assets/images/publicProfileOne.jpg";
import publicProfileTwo from "assets/images/public profile-06.jpg";
import publicProfileThree from "assets/images/publicProfileThree.jpg";
import publicProfileFour from "assets/images/new sppo and pp photo-03.jpg";

//Redux imports
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
// Share buttons
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";

function HeaderPage() {
  const matches = useMediaQuery("(max-width:425px)");
  const [details, setDetails] = useState("Interpreter");
  const [openProfileUpdate, setOpenProfileUpdate] = useState(false);
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const sliderImg = [
    publicProfileOne,
    publicProfileTwo,
    publicProfileThree,
    publicProfileFour,
  ];
  const [combination, setcombination] = useState(data?.language_combinations);
  const classes_avatar = useStylesAvatar();

  return (
    <Fragment>
      <Header as="h2" textAlign="center">
        Linguist Profile
      </Header>
      <ProfileModal
        openProfileUpdate={openProfileUpdate}
        setOpenProfileUpdate={setOpenProfileUpdate}
      />

      <div className="app__header__card">
        <div style={{ position: "relative", marginBottom: "60px" }}>
          <Slider data={sliderImg} />

          <div className="profile_avatar">
            <Avatar
              onContextMenu={(e) => e.preventDefault()}
              className={classes_avatar.root}
              src={`${process.env.REACT_APP_BACKEND_API}/linguists/public-profile-photo/${data._id}`}
              alt="Profile Photo"
            />
          </div>
        </div>

        <div
          style={{
            padding: " 0 32px 32px 32px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p style={{ fontSize: "28px", margin: "0" }}>
              {data?.title} {data?.FirstName} {data?.LastName}{" "}
              {data.verified ? (
                <IconButton>
                  <VerifiedUserIcon />
                </IconButton>
              ) : null}
            </p>
            <p
              style={{ fontSize: "24px", margin: "0" }}
              className="text-xl font-light"
            >
              {data?.public_profile_position == "Other"
                ? data?.Other_position
                : data?.public_profile_position}
            </p>
            <br />
            <p
              style={{ fontSize: "24px", margin: "0" }}
              className="text-xl font-light"
            >
              LC Linguist ID: {data?.id}
            </p>

            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <LocationOnIcon />
              <Header
                style={{ margin: "0", marginLeft: "5px" }}
                color="grey"
                size="medium"
              >
                {data.Country}
              </Header>
            </div>
            <br />
            <Header as="h3">Source and Target Languages</Header>
            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.target_language} {data?.target_language_level}
            </p>
            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.target_language_1} {data?.target_language_level_1}
            </p>
            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.target_language_2} {data?.target_language_level_2}
            </p>

            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.source_language} {data?.source_language_level}
            </p>
            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.source_language_1} {data?.source_language_level_1}
            </p>
            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.source_language_2} {data?.source_language_level_2}
            </p>

            <p style={{ fontSize: "18px" }} className="text-xl font-light">
              {data?.language_combinations_1} {data?.other_language_level_1}
            </p>
          </div>
          {matches ? (
            <div>
              <div>
                <FacebookShareButton
                  quote="Interpreting and Translation Services"
                  hashtag="Interpreting"
                  url={window.location.href}
                >
                  <Button circular color="facebook" icon="facebook" />
                </FacebookShareButton>
                <TwitterShareButton
                  quote="Interpreting and Translation Services"
                  hashtag="Interpreting"
                  url={window.location.href}
                >
                  <Button circular color="twitter" icon="twitter" />
                </TwitterShareButton>
                <LinkedinShareButton
                  quote="Interpreting and Translation Services"
                  hashtag="Interpreting"
                  url={window.location.href}
                >
                  <Button circular color="linkedin" icon="linkedin" />
                </LinkedinShareButton>
                <EmailShareButton
                  quote="Interpreting and Translation Services"
                  hashtag="Interpreting"
                  url={window.location.href}
                >
                  <Button circular color="google plus" icon="google plus" />
                </EmailShareButton>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <Button
                  style={{ marginBottom: 10 }}
                  color="purple"
                  onClick={() =>
                    window.open("https://www.linguistscollective.com")
                  }
                >
                  Linguists Collective (LC)
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <FacebookShareButton url={window.location.href}>
                  <Button circular color="facebook" icon="facebook" />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <Button circular color="twitter" icon="twitter" />
                </TwitterShareButton>
                <LinkedinShareButton url={window.location.href}>
                  <Button circular color="linkedin" icon="linkedin" />
                </LinkedinShareButton>
                <EmailShareButton url={window.location.href}>
                  <Button circular color="google plus" icon="google plus" />
                </EmailShareButton>
              </div>
              <br />
              <div style={{ display: "flex" }}>
                <Button
                  color="purple"
                  color="purple"
                  onClick={() =>
                    window.open("https://www.linguistscollective.com")
                  }
                >
                  Linguists Collective (LC)
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default HeaderPage;
