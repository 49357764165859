import React from "react";
import { useHistory } from "react-router-dom";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import InvoicingOptions from "components/Linguist/Invoicing/InvoicingOptions";
import CardHeader from "components/Card/CardHeader";

//custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { IconButton } from "@material-ui/core";


const CreateQuotation = () => {

  const history = useHistory();
  const classe = useDashboardStyles();
  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">

                <p className={classe.cardTitleWhite}>Invoicing</p>
                <IconButton color='inherit'><ArrowBackIcon
                  style={{ marginLeft: 5 }}
                  onClick={() => history.goBack()}
                ></ArrowBackIcon></IconButton>

              </CardHeader>
              <CardBody>
                <InvoicingOptions />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default CreateQuotation;
