import React from "react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from 'react-router-dom'

const ViewContactCard = () => {
  const { id } = useParams()
  const classes = useDashboardStyles();
  return (

    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader
            color="primary"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <p className={classes.cardTitleWhite}>Attachment</p>
            <IconButton color="inherit">
              <ArrowBackIcon
                onClick={() => history.goBack()}
              ></ArrowBackIcon>
            </IconButton>
          </CardHeader>
          <CardBody >
            <GridContainer justify="center" className={classes.container}>
              <GridItem xs={12} sm={12} md={4} lg={12} xl={12}>
                <Card>
                  <object data={`${process.env.REACT_APP_BACKEND_API}/ticket-system/view-conversation-attachment/conversationID/${id}`} type="application/pdf" width="100%" height="1000px">
                  </object>
                </Card>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

  );
};

export default ViewContactCard;
