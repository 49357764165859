import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

import useEmployeeStyles from "assets/jss/components/employeeStyle";

import { Dimmer, Header, Loader, Statistic, Table } from "semantic-ui-react";
import { CURRENCY, PAY_OPTIONS } from "data";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import moment from "moment";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
//Stripe
import StripeLayout from "layout/StripeLayout";
import CheckoutPRCQuote from "layout/CheckoutLayout/CheckoutPRCQuote";
import history from "utils/history";

const ViewINCInvoice = () => {
  const classes = useEmployeeStyles();
  const { id } = useParams();
  const token = Cookies.get("LINtoken");
  const [invoice, setInvoice] = useState(null);
  const [payOptions, setPayOptions] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [bankDetails, setBankDetails] = useState({
    account_holder_name: "",
    bsb: "",
    account_number: "",
    bic: "",
    iban: "",
    sort_code: "",
    routing_number: "",
    institution_number: "",
    transit_number: "",
    bank_code: "",
  });
  const [convertedCurrency, setConvertedCurrency] = useState(null);

  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
  });

  useEffect(() => {

    const loadInvoice = async () => {
      try {
        const response = await Axios.get(
          `/api/linguists/private-quote-view/quoteID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setInvoice(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    const getCompanyCard = async () => {
      try {
        const response = await Axios.get(
          "/api/manager-admin/view/company-information-card"
        );
        setCompanyCard({
          ...companyCard,
          pbsc: response?.data[0].platform_booking_service_charge,
          basc: response?.data[0].billing_account_service_charge,
          cname: response?.data[0].company_name,
          caddress: response?.data[0].company_address,
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadInvoice();
    getCompanyCard();

  }, []);

  // Handling payments

  const handlePayOptions = async (e) => {
    setPayOptions(e.target.value);
    if (e.target.value === "Marketplace speedy payment") {
      setBankDetails({
        ...bankDetails,
        bank_code: "",
        account_number: "",
        account_holder_name: "",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      setCurrency(null);
    }
  };

  const handleCurrency = async (e) => {
    setCurrency(e.target.value);
    const value = e.target.value;
    if (value === "AUD (Australia)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        bsb: "802-985",
        account_number: "918630266",
        bic: "",
        sort_code: "",
        iban: "",
        routing_number: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `AUD $ ${(
            (response?.data?.rates?.AUD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "EUR (EU)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        bic: "TRWIBEB1XXX",
        iban: "BE13 9671 5755 4739",
        bsb: "",
        account_number: "",
        sort_code: "",
        routing_number: "",
        bank_code: "",
        institution_number: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `EUR € ${(
            (response?.data?.rates?.EUR * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "GBP (UK)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        sort_code: "23-14-70",
        iban: "GB51 TRWI 2314 7058 5979 53",
        account_number: "",
        bsb: "",
        bic: "",
        routing_number: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `GBP £ ${(
            (response?.data?.rates?.GBP * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "TRY (Turkish lira)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        iban: "TR69 0010 3000 0000 0033 8634 34",
        sort_code: "",
        bsb: "",
        account_number: "",
        bic: "",
        routing_number: "",
        bank_code: "",
        institution_number: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `TRY ₺ ${(
            (response?.data?.rates?.TRY * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "USD (USA)") {
      setBankDetails({
        ...bankDetails,
        account_number: "9600000000264428",
        account_holder_name: "Linguists Collective Ltd",
        routing_number: "084009519",
        sort_code: "",
        bsb: "",
        bic: "",
        iban: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `USD $ ${(
            (response?.data?.rates?.USD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "CAD (Canada)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        institution_number: "621",
        account_number: "200110001176",
        transit_number: "16001",
        routing_number: "",
        bic: "",
        bsb: "",
        sort_code: "",
        iban: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `CAD $ ${(
            (response?.data?.rates?.CAD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "HUF (Hungarian forint)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        account_number: "12600016-17943734-02621164",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `HUF $ ${(
            (response?.data?.rates?.HUF * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "NZD (New Zealand dollar)") {
      setBankDetails({
        ...bankDetails,
        account_number: "02-1290-0578525-000",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `NZD $ ${(
            (response?.data?.rates?.NZD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "RON (Romanian leu)") {
      setBankDetails({
        ...bankDetails,
        bank_code: "BRELROBUXXX",
        account_number: "RO73 BREL 0005 4000 8848 0100",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `lei ${(
            (response?.data?.rates?.RON * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "SGD (Singapore dollar)") {
      setBankDetails({
        ...bankDetails,
        bank_code: "7171",
        account_number: "885-074-585-39",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `SGD $ ${(
            (response?.data?.rates?.SGD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  return invoice ? (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem id="printArea" xs={12} sm={12} md={12} lg={8} xl={8}>
          <Card>
            <CardBody>
              {/* Avatar and company name */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "24px",
                }}
              >
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  {invoice.status === "PAID" ? (
                    <Statistic color="green">
                      <Statistic.Value text>
                        {invoice.status}
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : (
                    <Statistic color="red">
                      <Statistic.Value text>
                        {invoice.status}
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  )}
                  <CustomAvatar style={{ margin: "0", marginLeft: "10px" }} />
                </div>
              </div>

              {/* First div  */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.privateClientID?.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.privateClientID.FirstName}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.privateClientID.AddressLine1}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.privateClientID.AddressLine2}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.privateClientID.City}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.privateClientID.Region}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.privateClientID.PostalCode}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.privateClientID.Country}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.privateClientID.email}
                    </Header>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Quotation number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ID number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.postedBy?.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist Residence:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.postedBy.Country}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      VAT/GST no:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.postedBy.vat_number}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of quote:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{moment(invoice.createdAt).format("DD/MMM/YYYY")}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of invoice:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;N/A
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Quotation to be paid by:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {moment(invoice.expiry_date).format("DD/MMM/YYYY HH:mm")}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Client reference:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_ref}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Service completion date:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {moment(invoice.service_completion_date).format(
                        "DD/MMM/YYYY"
                      )}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ref:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.linguist_ref}
                    </Header>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Service:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;{invoice.service}
                  </Header>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Linguist expertise level:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;{invoice.postedBy.target_language_level}
                  </Header>
                </div>
              </div>

              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="5">
                      Description of service
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Price</Table.HeaderCell>
                    <Table.HeaderCell width="1">Quantity</Table.HeaderCell>
                    <Table.HeaderCell width="1">Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {invoice.productOne.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.text}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.amount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.quantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ))}

                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header textAlign="right" as="h3">
                        Sub Total
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.subtotal1}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Expenses and travel</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  {invoice.productTwo.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.otherText}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherAmount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherQuantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ))}

                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h3">Sub Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.subtotal2}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice?.subtotal1 + invoice?.subtotal2}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Discount ({invoice.discount_percentage})%
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.discount_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Billing Account Service Charge</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>00.00</Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Platform and booking service charge{" "}
                        {invoice.service_charge}%
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice?.service_charge_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">VAT ({invoice.vat_percentage})%</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.vat_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.grand_total}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Estimate paid in advance</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>00.00</Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Total to be paid including VAT/GST
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {Number(invoice.grand_total).toFixed(2)}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Header style={{ marginTop: "16px" }} as="h4">
                  Thank You For Your Business
                </Header>
                <Header
                  color="grey"
                  style={{ marginBottom: "16px", marginTop: "0" }}
                  as="h4"
                >
                  {invoice?.company_name}, {invoice?.company_address}
                </Header>

                {Number(invoice?.grand_total) > 0 ?
                  <>
                    {invoice?.cancel ? (
                      <p style={{ color: "red" }}>Quote has been calcelled</p>
                    ) : (
                      <>
                        {invoice?.payment_status ? (
                          <p style={{ color: "green" }}>Quote has been paid</p>
                        ) : (
                          <>
                            <Header style={{ marginTop: "16px" }} as="h4">
                              Expiry Date and time:{" "}
                              {moment(invoice?.expiry_date).format(
                                "DD-MMM-YYYY : HH:mm:ss"
                              )}{" "}
                              - {invoice.timezone}
                            </Header>
                            {moment(invoice?.expiry_date) > moment() ||
                              moment(invoice?.expiry_date) === moment() ? (
                              <div>
                                <CustomSemanticDD
                                  onChange={(e) => handlePayOptions(e)}
                                  label="Accept and Pay"
                                  data={PAY_OPTIONS}
                                />
                                {payOptions === "Marketplace bank transfer" && (
                                  <CustomSemanticDD
                                    onChange={(e) => handleCurrency(e)}
                                    label="Currency"
                                    data={CURRENCY}
                                    value={currency}
                                  />
                                )}
                                {/* {payOptions === 'Marketplace bank transfer' && <Button onClick={handleBankPay} style={{ marginBottom: '10px' }} color='purple'>Accept and Pay</Button>} */}
                                {payOptions === "Marketplace bank transfer" &&
                                  convertedCurrency && (
                                    <Header as="h4">
                                      Total to be paid including VAT/GST:{" "}
                                      {convertedCurrency}
                                    </Header>
                                  )}
                                <Header as="h3">
                                  {bankDetails.account_holder_name}
                                </Header>

                                {bankDetails.account_number && (
                                  <Statistic color="purple" size="mini">
                                    <Statistic.Label>
                                      Account number
                                    </Statistic.Label>
                                    <Statistic.Value>
                                      {bankDetails.account_number}
                                    </Statistic.Value>
                                  </Statistic>
                                )}

                                {bankDetails.sort_code && (
                                  <Statistic color="purple" size="mini">
                                    <Statistic.Label>Sort code</Statistic.Label>
                                    <Statistic.Value>
                                      {bankDetails.sort_code}
                                    </Statistic.Value>
                                  </Statistic>
                                )}

                                {bankDetails.bic && (
                                  <Statistic color="purple" size="mini">
                                    <Statistic.Label>BIC</Statistic.Label>
                                    <Statistic.Value>
                                      {bankDetails.bic}
                                    </Statistic.Value>
                                  </Statistic>
                                )}

                                {bankDetails.iban && (
                                  <Statistic color="purple" size="mini">
                                    <Statistic.Label>IBAN</Statistic.Label>
                                    <Statistic.Value>
                                      {bankDetails.iban}
                                    </Statistic.Value>
                                  </Statistic>
                                )}

                                {bankDetails.bsb && (
                                  <Statistic color="purple" size="mini">
                                    <Statistic.Label>BSB</Statistic.Label>
                                    <Statistic.Value>
                                      {bankDetails.bsb}
                                    </Statistic.Value>
                                  </Statistic>
                                )}

                                {bankDetails.institution_number && (
                                  <Statistic color="purple" size="mini">
                                    <Statistic.Label>
                                      Institution number
                                    </Statistic.Label>
                                    <Statistic.Value>
                                      {bankDetails.institution_number}
                                    </Statistic.Value>
                                  </Statistic>
                                )}

                                {bankDetails.bank_code && (
                                  <Statistic color="purple" size="mini">
                                    <Statistic.Label>Bank code</Statistic.Label>
                                    <Statistic.Value>
                                      {bankDetails.bank_code}
                                    </Statistic.Value>
                                  </Statistic>
                                )}

                                {bankDetails.routing_number && (
                                  <Statistic color="purple" size="mini">
                                    <Statistic.Label>
                                      Routing number
                                    </Statistic.Label>
                                    <Statistic.Value>
                                      {bankDetails.routing_number}
                                    </Statistic.Value>
                                  </Statistic>
                                )}

                                {bankDetails.transit_number && (
                                  <Statistic color="purple" size="mini">
                                    <Statistic.Label>
                                      Transit number
                                    </Statistic.Label>
                                    <Statistic.Value>
                                      {bankDetails.transit_number}
                                    </Statistic.Value>
                                  </Statistic>
                                )}

                                {currency && (
                                  <Header as="h5">
                                    Please inform our Admin Team after processing
                                    the payment. Bank transfer payment can take a
                                    few days to complete. Please use either your
                                    client reference number{" "}
                                    {invoice?.privateClientID?.id}, invoice or
                                    billing number {invoice.id} - as the payment
                                    reference.
                                  </Header>
                                )}
                              </div>
                            ) : (
                              <p style={{ color: "red" }}>
                                This quote has been expired. Please send quote
                                request again.
                              </p>
                            )}{" "}
                          </>
                        )}
                      </>
                    )}
                  </>
                  : <p style={{ color: "red" }}>
                    Negative value!! Please contact your agent linguist.
                  </p>
                }


                {/* {payOptions === 'Marketplace speedy payment' && <Button loading= {loading} onClick= {() => setCardPay(true)} color= 'purple'>Pay</Button>} */}
                {payOptions === "Marketplace speedy payment" && (
                  <StripeLayout avatar={true}>
                    <CheckoutPRCQuote
                      price={Number(invoice.grand_total)}
                      currency={invoice.currency}
                      quoteID={invoice._id}
                      onSuccessfulCheckout={(id, invoiceId) =>
                        history.push(
                          `/private-client/quote/estimate/success/payment/${invoiceId}/${id}`
                        )
                      }
                      prcClientID={invoice.privateClientID.id}
                      clientDetails={invoice.privateClientID}
                      invoice={invoice}
                    />
                  </StripeLayout>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default ViewINCInvoice;
