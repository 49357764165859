import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";
import history from "utils/history";

import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useToasts } from "react-toast-notifications";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import {
  changeInput,
  updateProfileLinguistPageSix,
} from "redux/actions/lcAction";
import CustomLabel from "components/Common/CustomLabel";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";

import Axios from "axios";
import Cookies from "js-cookie";
import { Button, Dimmer, Loader, Header, Progress } from "semantic-ui-react";
import CustomDateTwo from "components/Common/CustomDateTwo";

const PageSix = ({ setReload }) => {
  const { data } = useSelector((state) => state.lcReducer);
  const dispatch = useDispatch();
  const token = Cookies.get("LINtoken");
  const [timeCount, setTimeCount] = useState([1]);
  const [loader, setLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [reference_letter, setReferenceLetter] = useState(false);
  const [linDetails, setLinDetails] = useState(false);
  const [progress, setProgress] = useState(0);
  const [next, setNext] = useState(false);
  const { addToast } = useToasts();

  const uploadReferenceLetter = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("reference_letter", file);
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-reference-file`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      WorkExperience();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleDoc1 = async (e) => {
    if (e.target?.files[0]?.size > 2000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 2mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      setReferenceLetter(e.target.files[0]);
      uploadReferenceLetter(e.target.files[0]);
    }
  };

  const getLinguistDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/get-details-page6`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        updateProfileLinguistPageSix({
          certificate_id: response?.data._id,
          organization_name: response?.data.organization_name,
          organization_address: response?.data.organization_address,
          start_date: response?.data.start_date,
          leaving_date: response?.data.leaving_date,
          experience_gained: response?.data.experience_gained,
          reason_for_leaving: response?.data.reason_for_leaving,
          reference_name: response?.data.reference_name,
        })
      );
      setLinDetails(response?.data);
      setLoader(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
      setLoader(false);
    }
  };

  const [viewWorkExp, setViewWorkExp] = useState(null);
  const WorkExperience = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-work-reference-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewWorkExp(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  useEffect(() => {
    getLinguistDetails();
    WorkExperience();
  }, []);

  const {
    organization_name,
    organization_address,
    start_date,
    leaving_date,
    experience_gained,
    reason_for_leaving,
    reference_name,
  } = data;

  const classes = useFormStyles();

  const handleSave = async (e) => {
    e.preventDefault();
    setSaveLoader(true);
    let fd = new FormData();

    fd.append("organization_name", data.organization_name || "");
    fd.append("organization_address", data.organization_address || "");
    fd.append("start_date", data.start_date || "dd/mm/yyyy");
    fd.append("leaving_date", data.leaving_date || "dd/mm/yyyy");
    fd.append("experience_gained", data.experience_gained || "");
    fd.append("reason_for_leaving", data.reason_for_leaving || "");
    fd.append("reference_name", data.reference_name || "");

    // if (reference_letter?.size) {
    //   fd.append("reference_letter", reference_letter);
    // }

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-full-registration`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((val) => {
        setReload(Math.random());
      });
      setSaveLoader(false);
      setNext(true);
    } catch (error) {
      console.log(error.response);
      setSaveLoader(false);
    }
  };

  const handleBack = () => {
    history.push("/linguist/full-registration-p5");
  };
  const handleNext = () => {
    history.push("/linguist/full-registration-p7");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInput({
        name,
        value,
      })
    );
  };

  const handleDateChange = (e) => {
    dispatch(
      changeInput({
        name: "start_date",
        value: e.target.value,
      })
    );
  };

  const handleDateChangeL = (e) => {
    dispatch(
      changeInput({
        name: "leaving_date",
        value: e.target.value,
      })
    );
  };

  const handleAdd = () => {
    setTimeCount([...timeCount, timeCount[0] + 1]);
  };
  const handleRemove = () => {
    const copiedTC = [...timeCount];
    copiedTC.pop();
    setTimeCount(copiedTC);
  };

  if (loader) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomLabel
          label="Work experience"
          moreInfo="List maximum three previous employments"
        />

        {timeCount.map((tc) => (
          <Fragment>
            <CustomInputSingle
              onChange={handleChange}
              width="100%"
              label="Name of the organisation / event"
              name="organization_name"
              value={organization_name}
            />
            <CustomInputSingle
              onChange={handleChange}
              width="100%"
              label="Address / contact details"
              name="organization_address"
              value={organization_address}
            />
            <CustomDateTwo
              onChange={handleDateChange}
              label="Start date"
              width="100%"
              name="start_date"
              value={start_date}
            />
            <CustomDateTwo
              onChange={handleDateChangeL}
              label="Leaving date"
              width="100%"
              name="leaving_date"
              value={leaving_date}
            />
            <CustomInputSingle
              onChange={handleChange}
              width="100%"
              label="List of duties / experience gained"
              name="experience_gained"
              value={experience_gained}
            />
            <CustomInput
              onChange={handleChange}
              label="Reasons for leaving"
              width="100%"
              name="reason_for_leaving"
              value={reason_for_leaving}
            />
            <CustomBigInputSingle
              onChange={handleChange}
              width="100%"
              label="Reference Name"
              moreInfo="Provide name of your supervisor and contact details below"
              name="reference_name"
              value={reference_name}
            />
          </Fragment>
        ))}

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "24px",
            }}
          >
            <Header as="h4">Upload reference letter (max 2mb)</Header>
            <Header as="h4">
              <input
                type="file"
                name="reference_letter"
                onChange={handleDoc1}
                accept="application/pdf"
              />
            </Header>
            {reference_letter?.name && (
              <div>
                <Header
                  style={{ margin: "0" }}
                  as="h5"
                >{`${reference_letter?.lastModifiedDate?.toDateString()}`}</Header>
                <Header style={{ margin: "0" }} as="h5">{`${(
                  reference_letter?.size / 1024
                ).toFixed(2)}kb`}</Header>
              </div>
            )}

            <Header as="h4">
              {viewWorkExp?.reference_letter_true && (
                <a
                  href={`/linguist/linguist-view-reference-letter/${viewWorkExp?._id}`}
                >
                  View document
                </a>
              )}
            </Header>
          </div>
        </div>
        {progress > 0 && (
          <Progress percent={progress} indicating autoSuccess progress>
            {" "}
            Document Uploaded
          </Progress>
        )}
        <div className={classes.timeDiv}>
          {timeCount.length > 1 ? (
            <IconButton
              color="secondary"
              className="i-btn-two"
              component="button"
              onClick={handleRemove}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          ) : null}
          {timeCount.length != 3 ? (
            <IconButton
              color="primary"
              className="i-btn"
              component="button"
              onClick={handleAdd}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          ) : null}
        </div>

        <div className={classes.buttonFlex} style={{ marginTop: "24px" }}>
          <Button basic color="purple" onClick={handleBack}>
            Back
          </Button>
          {!next ? (
            <Button loading={saveLoader} color="purple" onClick={handleSave}>
              Save
            </Button>
          ) : (
            <Button color="green" onClick={handleNext}>
              Next
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default PageSix;
