import { useHistory } from "react-router-dom";

// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CustomSnackBar from "components/Common/CustomSnackBar";
import Axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";


const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const BookingOptions = () => {
  const classes = useStyles();
  const history = useHistory();
  const [privateAccess, setPrivateAccess] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("LINtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );


    setPrivateAccess(response?.data.privateAccess);
  };
  useEffect(() => {
    getLinguistDetails();
  },[]);

  const handleClick = (e) => {
    setOpenSnackBar(true);
  };

  const handleClose = (e) => {
    setOpenSnackBar(false);
  };

  return (
    <div className={classes.root}>
      <CustomSnackBar
        message={"Private Access Disabled"}
        openSnackBar={openSnackBar}
        handleClose={handleClose}
      />
      <Button
        variant="outlined"
        onClick={
          // !privateAccess
            // ? handleClick
             () => history.push("/linguist/add-private-client")
        }
      >
        Add / Remove Private Client
      </Button>
      <Button
        variant="outlined"
        onClick={
          // !privateAccess
          //   ? handleClick
             () => history.push("/linguist/view-private-client")}
      >
        Search / View private client
      </Button>
      <Button
        variant="outlined"
        onClick={
          // !privateAccess
          //   ? handleClick
             () => history.push("/linguist/send-booking-form-private")
        }
      >
        Send Booking Form
      </Button>
      <Button
        variant="outlined"
        onClick={
          // !privateAccess
          //   ? handleClick
             () => history.push("/linguist/private-client/book-interpreter")
        }
      >
        Private Interpreting Booking
      </Button>
      <Button
        variant="outlined"
        onClick={
          // !privateAccess
          //   ? handleClick
             () => history.push("/linguist/private-client/order-translation")
        }
      >
        Private Translation Order
      </Button>
      <Button
        variant="outlined"
        onClick={
          // !privateAccess
          //   ? handleClick
             () => history.push("/linguist/prc-unconfirm-assignment")}
      >
       Unconfirm Assignments
      </Button>
      <Button
        variant="outlined"
        onClick={
          // !privateAccess
          //   ? handleClick
             () => history.push("/linguist/prc-confirm-assignment")}
      >
        Confirmed Assignments
      </Button>
      <Button
        variant="outlined"
        onClick={
          // !privateAccess
          //   ? handleClick
             () => history.push("/linguist/private-client/closed-assignments")}
      >
        Private Closed Assignments
      </Button>
      <Button
        variant="outlined"
        onClick={
          // !privateAccess
          //   ? handleClick
             () => history.push("/linguist/private-client/quote")}
      >
        Private Direct Quote
      </Button>
      <Button
        variant="outlined"
        onClick={
          // !privateAccess
          //   ? handleClick
             () => history.push("/linguist/direct-prc-invoice")}
      >
        Private Direct Invoice
      </Button>
     
    </div>
  );
};

export default BookingOptions;
