import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import { GENDER_DATA } from "data";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("BACtoken");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handlePhone = (value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "phone_no",
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("title", data.title);
    fd.append("FirstName", data.FirstName);
    fd.append("LastName", data.LastName);
    fd.append("Birthday", data.Birthday);
    fd.append("sex", data.sex);
    fd.append("email", data.email);
    fd.append("mobile_no", data.mobile_no);
    fd.append("other_email", data.other_email);
    fd.append("phone_no", data.phone_no);

    try {
      const response = await Axios.put(
        `/api/accountclient/update-own-bacuser/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="title"
            onChange={handleChange}
            label="Title"
            edit={edit.value}
            value={data.title}
          />
          <CustomTextInput
            name="FirstName"
            onChange={handleChange}
            label="First Name"
            value={data.FirstName}
          />
          <CustomTextInput
            name="LastName"
            onChange={handleChange}
            label="Last Name"
            edit={edit.value}
            value={data.LastName}
          />

          <CustomTextInput
            name="Birthday"
            onChange={handleChange}
            label="Date of birth"
            value={data.Birthday}
          />

          {edit.value ? (
            <CustomTextInput
              name="sex"
              onChange={handleChange}
              label="Gender"
              radio={true}
              rvalue={data.sex}
              data={GENDER_DATA}
            />
          ) : (
            <CustomTextInput
              name="sex"
              onChange={handleChange}
              label="Gender"
              value={data.sex}
            />
          )}
          <CustomTextInput
            name="email"
            onChange={handleChange}
            label="Primary email"
            value={data.email}
          />
          <CustomTextInput
            name="other_email"
            onChange={handleChange}
            label="Another email"
            edit={edit.value}
            value={data.other_email}
          />

          {edit.value ? (
            <CustomTextInput
              name="mobile_no"
              onChange={handleMobile}
              label="Mobile Number"
              pvalue={data.mobile_no}
              mobile={true}
            />
          ) : (
            <CustomTextInput
              name="mobile_no"
              onChange={handleChange}
              label="Mobile Number"
              value={data.mobile_no}
            />
          )}
          {edit.value ? (
            <CustomTextInput
              name="phone_no"
              onChange={handlePhone}
              label="Phone Number"
              pvalue={data.phone_no}
              mobile={true}
            />
          ) : (
            <CustomTextInput
              name="phone_no"
              onChange={handleChange}
              label="Phone Number"
              value={data.phone_no}
            />
          )}
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
