import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import FirstSegment from "components/ManagementPanel/Meetings/FirstSegment";
import SecondSegment from "components/ManagementPanel/Meetings/SecondSegment";

const Meetings = () => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4} lg={4}>
        <FirstSegment />
      </GridItem>
      <GridItem xs={12} sm={12} md={8} lg={8}>
        <SecondSegment />
      </GridItem>
    </GridContainer>
  );
};

export default Meetings;
