import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { autoSaveER, changeErInput, updateErStep } from "redux/actions/erAction";

const FormThree = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.erReducer);

  const {
    bank_account_fullname,
    bank_sort_code,
    bank_account_number,
    bank_branch_name,
    bank_iban,
    bank_swift_code,
    bank_name,
    bank_branch_address,
    currency,
    bank_additional_info,
    country,
    aba,
    branch_code,
    institution_number,
    ifsc_code
  }= data

  const handleChange = e => {
    const { name, value }= e.target
    dispatch(changeErInput({
        name,
        value
    }))
  }
  
  useEffect(() => {
    const temp= JSON.parse(localStorage.getItem('userER'))
    dispatch(autoSaveER({
      temp
    }))
  }, [dispatch])

  useEffect(() => {
    localStorage.setItem('userER', JSON.stringify(data))
  }, [data])

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateErStep(4));
  };

  const handleBack = () => {
    dispatch(updateErStep(2));
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
      <CustomBigInput
          onChange= {handleChange}
          width="73.3%"
          label="Bank account holder's full name"
          moreInfo= 'Personal and bank details will be shared with payment processor'
          name= 'bank_account_fullname'
          value= {bank_account_fullname}
        />
        <CustomBigInput
          onChange= {handleChange}
          label= "Account Number"
          moreInfo= 'Please provide relevant details as used in your country of residence and for international payment number'
          width="73.3%"
          name= 'bank_account_number'
          value= {bank_account_number}
        />
        <CustomInput
          onChange= {handleChange}
          label="Branch name"
          width="73.3%"
          name= 'bank_branch_name'
          value= {bank_branch_name}
        />
        {country.includes('United States') ? (
          <CustomInput
            onChange= {handleChange}
            label="ABA"
            width="73.3%"
            name= 'aba'
            value= {aba}
          />
        ): null}
        {country.includes('United Kingdom') ? (
        <CustomInput
          onChange= {handleChange}
          label="Sort code"
          width="73.33%"
          name= 'bank_sort_code'
          value= {bank_sort_code}
        />
        ): null}
        {country.includes('Canada') ? (
          <React.Fragment>
            <CustomInput
              onChange= {handleChange}
              label="Branch Code"
              width="73.3%"
              name= 'branch_code'
              value= {branch_code}
            />
          <CustomInput
            onChange= {handleChange}
            label="Institution Number"
            width="73.3%"
            name= 'institution_number'
            value= {institution_number}
          />
          </React.Fragment>
        ): null}
        <CustomInput
          onChange= {handleChange}
          label= 'IBAN'
          width="73.3%"
          name= 'bank_iban'
          value= {bank_iban}
        />
        <CustomInput
          onChange= {handleChange}
          width="73.3%"
          label="Swift / BIC code"
          name= 'bank_swift_code'
          value= {bank_swift_code}
        />
        {(country!=='United States' && country!=='United Kingdom' && country!=='Canada') ? (
          <CustomInput
            onChange= {handleChange}
            label="Sort Code / IFSC Code"
            width="73.3%"
            name= 'ifsc_code'
            value= {ifsc_code}
          />
        ): null}
        <CustomInput
          onChange= {handleChange}
          width="73.3%"
          label="Name of the bank"
          name= 'bank_name'
          value= {bank_name}
        />
        <CustomInput
          onChange= {handleChange}
          width="73.3%"
          label="Address of bank branch"
          name= 'bank_branch_address'
          value= {bank_branch_address}
        />
        <CustomInput
          onChange= {handleChange}
          width="73.3%"
          label="Additional information"
          name= 'bank_additional_info'
          value= {bank_additional_info}
        />
        <CustomBigInput
          onChange= {handleChange}
          width="73.3%"
          label="Preferred currency"
          moreInfo= 'GBP, EURO, Chinese Yuan / Renminbi'
          name= 'currency'
          value= {currency}
        />
        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton text="Next" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default FormThree;
