import { useHistory } from "react-router-dom";
import React from "react";
// Materials Core
import { Button, Modal } from 'semantic-ui-react'
import { makeStyles } from "@material-ui/core/styles";
import MyNotesForm from "components/CashClient/Dashboard/MyNote/Form"



const styles = {
  root: {
    display: "flex",
    alignItems: "center",

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const InvoiceOptions = () => {

  const [open, setOpen] = React.useState(false)
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Button color="purple"
        variant="outlined"
        onClick={() => history.push("/cash-client/invoices-list/unpaid-invoices")}
      >
        Unpaid Invoices
      </Button>
      <Button color="purple"
        variant="outlined"
        onClick={() => history.push("/cash-client/invoices-list/paid-invoices")}
      >
        Paid Invoices
      </Button>
      <>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          trigger={<Button color="purple" icon='add' content='My Note / Do List' />}

        >
          <Modal.Content scrolling>
            <Modal.Description>
              <MyNotesForm />
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </>
    </div>
  );
};

export default InvoiceOptions;
