import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomUpload from "components/Common/CustomUpload";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import { changeInput } from "redux/actions/lcAction";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CustomBigInput from "components/Common/CustomBigInput";
import { useToasts } from "react-toast-notifications";

//Sweet Alert imports
import swal from "sweetalert";

import Axios from "axios";
import history from "utils/history";
import Cookies from "js-cookie";
import {
  Button,
  Dimmer,
  Icon,
  Loader,
  Modal,
  Progress,
} from "semantic-ui-react";

const PageEight = () => {
  const { data } = useSelector((state) => state.lcReducer);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [view, setView] = React.useState(false);
  const token = Cookies.get("LINtoken");
  const [status, setStatus] = useState({
    flag: false,
    loader: false,
  });
  const [next, setNext] = useState(false);
  const [progress, setProgress] = useState(0);

  const { note, upload_cv, special_needs, gdpr } = data;

  const classes = useFormStyles();

  const handleClick = (e) => {
    e.preventDefault();
    setStatus({ ...status, flag: true });
  };

  const sendForApproval = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/send-approval`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStatus({ ...status, flag: false, loader: false });
      swal({
        icon: "success",
        text: "Welcome to Linguists Collective. Thank you for completing the full registration. Verification process can take a few days, please do check your email.",
        buttons: false,
      }).then(function () {
        window.location = "/linguist/dashboard";
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        setStatus({ ...status, flag: false, loader: false });
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  const handleNext = async (e) => {
    setStatus({ ...status, loader: true });
    const token = Cookies.get("LINtoken");
    let fd = new FormData();

    fd.append("note", data.note);
    fd.append("special_needs", data.special_needs);
    fd.append("profile_update", true);
    // if (data.upload_cv?.name) {
    //   fd.append("upload_cv", data.upload_cv);
    // }
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-full-registration`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      sendForApproval();
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        setStatus({ ...status, flag: false, loader: false });
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  const handleBack = () => {
    history.push("/linguist/full-registration-p7");
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeInput({
        name,
        value: value || checked,
      })
    );
  };

  const handleFiles = (e) => {
    const name = e.target.name;
    const value = e.target.files[0];
    if (e.target?.files[0]?.size > 3000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 3mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      dispatch(
        changeInput({
          name,
          value,
        })
      );
      uploadCV(e.target?.files[0]);
    }
  };

  const [profileUpdate, setProfileUpdate] = useState("");

  const [page1, setPage1] = useState("");
  const [page2, setPage2] = useState("");
  const [page3, setPage3] = useState("");
  const [page4, setPage4] = useState("");
  const [page5, setPage5] = useState("");
  const [page6, setPage6] = useState("");
  const [page7, setPage7] = useState("");
  const [page8, setPage8] = useState("");

  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/get-mandatory-details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProfileUpdate(response?.data.profile_update);
      setPage1(response?.data.photo);
      setPage2(response?.data.passport_true);
      setPage3(response?.data.bank_account_fullname);
      setPage4(response?.data.target_language);
      setPage5(response?.data.institute_name);
      setPage6(response?.data.organization_name);
      setPage7(response?.data.criminal_record);
      setPage8(response?.data.upload_cv);
      if (response?.data.profile_update) {
        history.push("/linguist/profile/update");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadCV = async (file) => {
    let fd = new FormData();
    if (file) {
      fd.append("upload_cv", file);
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-cv-file`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      getCV();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };
  const [viewCV, setViewCV] = useState(null);
  const getCV = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-cv-upload-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewCV(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  useEffect(() => {
    getLinguistDetails();
    getCV();
  }, []);

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInput
          onChange={handleChange}
          width="100%"
          label="Would you like to add any other information or note?"
          name="note"
          value={note}
        />
        <br />
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <p>
            Please upload
            <a target="_blank" href="https://europa.eu/europass/en">
              {" "}
              Europass CV{" "}
            </a>{" "}
            in English.
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCy_sIqjGtj4wRFv8f45Qyig/videos"
            >
              {" "}
              YouTube{" "}
            </a>
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <CustomUpload
            onChange={(e) => handleFiles(e, "upload_cv")}
            width="100%"
            label="Upload copy of your CV here for LC and sharing with clients. You should update this twice a year."
            name="upload_cv"
          />

          {upload_cv ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Icon name="file pdf" size="big" />
              <p
                style={{
                  margin: "0",
                  marginTop: "7px",
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                }}
              >
                {upload_cv?.name}
              </p>
              <p>{upload_cv?.lastModifiedDate?.toDateString()} </p>
              <p>{`${(upload_cv?.size / 1024).toFixed(2)}kb`}</p>
            </div>
          ) : null}
        </div>
        {progress > 0 && (
          <Progress percent={progress} indicating autoSuccess progress>
            {" "}
            Document Uploaded
          </Progress>
        )}
        <CustomBigInput
          onChange={handleChange}
          width="100%"
          label="Do you have any disability or special needs?"
          moreInfo="If yes do you need any adjustment to be made for you?"
          name="special_needs"
          value={special_needs}
        />
        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <p>
            <Checkbox
              onChange={handleChange}
              checked={gdpr}
              name="gdpr"
              color="primary"
            />
            By submitting this form I agree with LC
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/privacy-policy"
            >
              {" "}
              Privacy Policy{" "}
            </a>
            ,{" "}
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/terms-and-conditions"
            >
              {" "}
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a href="https://linguistscollective.com/linguist/service-agreement-of-working-with-linguists-collective">
              {" "}
              Service Agreement
            </a>
          </p>
        </div>

        <Modal
          size="small"
          open={status.flag}
          onClose={() => setStatus({ ...status, flag: false })}
          dimmer="inverted"
        >
          <Modal.Header>Complete Registration</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to submit your profile for approval?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => setStatus({ ...status, flag: false })}
              negative
            >
              Cancel
            </Button>
            <Button
              disabled={status.loader}
              onClick={handleNext}
              color="purple"
            >
              Yes
            </Button>
          </Modal.Actions>
          {status.loader && (
            <Dimmer active inverted>
              <Loader active inverted size="small"></Loader>
            </Dimmer>
          )}
        </Modal>

        <div style={{ marginTop: "24px" }} className={classes.buttonFlex}>
          <Button onClick={handleBack} basic color="purple" content="Back" />
          {page1 && page2 && page4 && page7 ? (
            <Button
              style={{ marginLeft: 30 }}
              disabled={!gdpr}
              onClick={handleClick}
              color="purple"
            >
              Send for approval
            </Button>
          ) : (
            <p style={{ color: "red" }}>
              Please complete mandatory elements on all pages before submitting
              this form.
              <br />
              Submit button will appear here.
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default PageEight;
