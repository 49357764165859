import React, { } from "react";
import Axios from "utils/axios";
import swal from "sweetalert";
import DoneIcon from "@material-ui/icons/Done";
import { IconButton } from '@material-ui/core'



const Action = ({ only_number_id, id }) => {


  const handleDone = async (e) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/update-linguist-duplicate-ID/linID/${id}`,
        only_number_id
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      })
        .then(function () {
          window.location = "/admin/scan-duplicate-linguist";
        });
    } catch (error) {
      console.log(error.response);
      swal({
        icon: "error",
        text: `${error?.response?.data?.error}`,
        buttons: false,
      });
    }
  };


  return (
    <IconButton onClick={handleDone}>
      <DoneIcon />
    </IconButton>
  );
};

export default Action;
