const {
  STORE_VALUES,
  DESCRIPTION_QUOTATION,
  ADD_DESCRIPTION,
  REMOVE_DESCRIPTION,
  EXPENSES_QUOTATION,
  ADD_EXPENSES,
  REMOVE_EXPENSES,
  PERCENT_CHANGE,
} = require("../actionTypes");

export const storeValues = (data) => ({
  type: STORE_VALUES,
  payload: data,
});

export const descriptionQutation = (data) => ({
  type: DESCRIPTION_QUOTATION,
  payload: data,
});

export const addDescription = () => ({
  type: ADD_DESCRIPTION,
});

export const removeDescription = (data) => ({
  type: REMOVE_DESCRIPTION,
  payload: data,
});

export const expensesQutation = (data) => ({
  type: EXPENSES_QUOTATION,
  payload: data,
});
export const addExpenses = () => ({
  type: ADD_EXPENSES,
});

export const removeExpenses = (data) => ({
  type: REMOVE_EXPENSES,
  payload: data,
});

export const percentChange = (data) => ({
  type: PERCENT_CHANGE,
  payload: data,
});
