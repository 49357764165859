import {
  UPDATE_LC_STEP,
  LC_LOGIN,
  CHANGE_LC_INPUT,
  AUTO_SAVE,
  UPDATE_PROFILE_LINGUIST,
  UPDATE_PROFILE_LINGUIST_TWO,
  UPDATE_PROFILE_LINGUIST_THREE,
  UPDATE_PROFILE_LINGUIST_FOUR,
  UPDATE_PROFILE_LINGUIST_FIVE,
  UPDATE_PROFILE_LINGUIST_SIX,
  UPDATE_PROFILE_LINGUIST_SEVEN
} from "../actions/actionTypes";


const INITIAL_STATE = {
  loggedInData: "",
  count: 1,
  data: {
    photo_preview: '',
    profile_photo: null,
    title: "",
    FirstName: "",
    LastName: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    PostalCode: "",
    Region: "",
    Country: "",
    Nationality: "",
    other_nationality: [],
    Birthday: "",
    email: "",
    sex: "",
    mobile_no: "",
    other_email: "",
    business_phone_no: "",
    online_calling_details: "",
    proof_address_photo: null,
    reference_work: "",
    reference_education: "",
    work_in_the_uk: "",
    eu_all_other_countries: [],
    ni_number: "",
    utr_number: "",
    vat_number: "",
    liability_insurance: "",
    liability_insurance_expiry_date: null,
    passport_ni_photo: null,
    relevant_document_photo: "",
    bank_account_fullname: "",
    bank_sort_code: "",
    bank_account_number: "",
    bank_branch_name: "",
    bank_iban: "",
    bank_swift_code: "",
    bank_name: "",
    bank_branch_address: "",
    bank_additional_info: "",
    bic_no: "",
    bank_ifsc_code: "",
    institution_number: "",
    branch_code: "",
    aba: "",
    currency: "",
    mother_tongue: "",
    source_language: "",
    source_language_1: "",
    source_language_2: "",
    target_language: "",
    target_language_1: "",
    target_language_2: "",
    language_combinations: [],
    ticking_relevant: [],
    interpreting_qualification: "",
    translation_qualification: "",
    other_language_test: "",
    total_hours_of_proven: "",
    total_days_words_of_proven: "",
    professional_qualification: "",
    professional_membership: [],
    professional_membership_number_level: "",
    institute_name: "",
    date_of_graduation: "",
    name_of_degree: "",
    subject_studied: "",
    relevant_certificate_photo: null,
    organization_name: "",
    organization_address: "",
    start_date: "",
    leaving_date: "",
    experience_gained: "",
    reason_for_leaving: "",
    reference_name: "",
    criminal_record: "",
    criminal_record_details: "",
    security_clearance: "",
    title_security_clearance: [],
    title_security_clearance_1: '',
    title_security_clearance_2: '',
    title_security_clearance_3: '',
    title_security_clearance_4: '',
    security_certificate_expiry_date: '',
    security_certificate_expiry_date_1: '',
    security_certificate_expiry_date_2: '',
    security_certificate_expiry_date_3: '',
    security_certificate_expiry_date_4: '',
    security_clearance_document: "",
    note: "",
    upload_cv: "",
    relevant_documents: "",
    special_needs: "",
    gdpr: false,
    id: '',
    certificate_id: '',
    security_clearance_details: []
  },
};

const lcReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_LC_STEP:
      return {
        ...state,
        count: payload,
      };

    case LC_LOGIN:
      return {
        ...state,
        loggedInData: payload,
      };

    case CHANGE_LC_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value,
        },
      };
    
    case UPDATE_PROFILE_LINGUIST:
      return {
        ...state,
        data: {
          ...state.data,
          address_id: payload.address_id,
          title: payload.title,
          AddressLine1: payload.AddressLine1,
          AddressLine2: payload.AddressLine2,
          PostalCode: payload.PostalCode,
          Country: payload.Country,
          Nationality: payload.Nationality,
          Birthday: payload.Birthday,
          FirstName: payload.FirstName,
          LastName: payload.LastName,
          City: payload.City,
          Region: payload.Region,
          email: payload.email,
          other_nationality: payload.other_nationality,
          sex: payload.sex,
          mobile_no: payload.mobile_no,
          other_email: payload.other_email,
          business_phone_no: payload.business_phone_no,
          online_calling_details: payload.online_calling_details,
          reference_work: payload.reference_work,
          reference_education: payload.reference_education,
          proof_address_photo: payload.proof_address_photo,
          photo: payload.photo,
          photo_preview: payload.photo_preview
        }
      }

      case UPDATE_PROFILE_LINGUIST_TWO:
        return {
          ...state,
          data: {
            ...state.data,
            id: payload.id,
            work_in_the_uk: payload.work_in_the_uk,
            eu_all_other_countries: payload.eu_all_other_countries,
            ni_number: payload.ni_number,
            utr_number: payload.utr_number,
            vat_number: payload.vat_number,
            liability_insurance: payload.liability_insurance,
            liability_insurance_expiry_date: payload.liability_insurance_expiry_date,
            passport_ni_photo: payload.passport_ni_photo,
            relevant_document_photo: payload.relevant_document_photo,
          }
        }

        case UPDATE_PROFILE_LINGUIST_THREE:
          return {
            ...state,
            data: {
              ...state.data,
              bank_account_fullname: payload.bank_account_fullname,
              bank_sort_code: payload.bank_sort_code,
              bank_account_number: payload.bank_account_number,
              bank_branch_name: payload.bank_branch_name,
              bank_iban: payload.bank_iban,
              bank_swift_code: payload.bank_swift_code,
              bank_name: payload.bank_name,
              bank_branch_address: payload.bank_branch_address,
              bank_additional_info: payload.bank_additional_info,
              bic_no: payload.bic_no,
              bank_ifsc_code: payload.bank_ifsc_code,
              institution_number: payload.institution_number,
              branch_code: payload.branch_code,
              aba: payload.aba,
              currency: payload.currency,
            }
          }
        
          case UPDATE_PROFILE_LINGUIST_FOUR:
            return {
              ...state,
              data: {
                ...state.data,
                mother_tongue: payload.mother_tongue,
                source_language: payload.source_language,
                source_language_1: payload.source_language_1,
                source_language_2: payload.source_language_2,
                target_language: payload.target_language,
                target_language_1: payload.target_language_1,
                target_language_2: payload.target_language_2,
                language_combinations: payload.language_combinations,
                ticking_relevant: payload.ticking_relevant,
                interpreting_qualification: payload.interpreting_qualification,
                translation_qualification: payload.translation_qualification,
                other_language_test: payload.other_language_test,
                total_hours_of_proven: payload.total_hours_of_proven,
                total_days_words_of_proven: payload.total_days_words_of_proven,
                professional_qualification: payload.professional_qualification,
                professional_membership: payload.professional_membership,
                professional_membership_number_level: payload.professional_membership_number_level,
              }
            }

            case UPDATE_PROFILE_LINGUIST_FIVE:
              return {
                ...state,
                data: {
                  ...state.data,
                  certificate_id: payload.certificate_id,
                  institute_name: payload.institute_name,
                  date_of_graduation: payload.date_of_graduation,
                  name_of_degree: payload.name_of_degree,
                  subject_studied: payload.subject_studied,
                  relevant_certificate_photo: payload.relevant_certificate_photo,
                }
              }

            case UPDATE_PROFILE_LINGUIST_SIX:
              return {
                ...state,
                data: {
                  ...state.data,
                  certificate_id: payload._id,
                  organization_name: payload.organization_name,
                  organization_address: payload.organization_address,
                  start_date: payload.start_date,
                  leaving_date: payload.leaving_date,
                  experience_gained: payload.experience_gained,
                  reason_for_leaving: payload.reason_for_leaving,
                  reference_name: payload.reference_name,
                }
              }
            
              case UPDATE_PROFILE_LINGUIST_SEVEN:
                return {
                  ...state,
                  data: {
                    ...state.data,
                    security_id: payload._id,
                    criminal_record: payload.criminal_record,
                    criminal_record_details: payload.criminal_record_details,
                    security_clearance: payload.security_clearance,
                    security_clearance_details: payload.security_clearance_details,
                    title_security_clearance_1: payload.title_security_clearance_1,
                    title_security_clearance_2: payload.title_security_clearance_2,
                    title_security_clearance_3: payload.title_security_clearance_3,
                    title_security_clearance_4: payload.title_security_clearance_4,
                    security_certificate_expiry_date_1: payload.security_certificate_expiry_date_1,
                    security_certificate_expiry_date_2: payload.security_certificate_expiry_date_2,
                    security_certificate_expiry_date_3: payload.security_certificate_expiry_date_3,
                    security_certificate_expiry_date_4: payload.security_certificate_expiry_date_4
                  }
                }

    case AUTO_SAVE:
      return {
        ...state,
        data: payload.temp,
      };

    default:
      return state;
  }
};

export default lcReducer;
