import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";

// Custom Components
import AccountClientLayout from "layout/AccountClientLayout";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import BIPayment from "components/Auth/accountClientInterpretingbooking/BIPayment";

const BIPaymentPage = () => {
  return (
    <AccountClientLayout>
      <Accountclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <BIPayment />
          </GridItem>
        </GridContainer>
      </Accountclient>
    </AccountClientLayout>
  );
};

export default BIPaymentPage;
