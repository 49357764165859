import React, { useEffect, useState } from "react";
import "../PPL/LinguistProfile.css";

// Custom Imports
import Header from "../PPL/Header/Header";
import SecurityClearance from "../PPL/SecurityClearance/SecurityClearance";
import Verified from "../PPL/Verified/Verified";
import About from "../PPL/About/About";
import WorkExperience from "../PPL/WorkExperience/WorkExperience";
import Qualification from "../PPL/Qualification/Qualification";
import ProfMemberShip from "../PPL/ProfMemberShip/ProfMemberShip";

import { updateLinguistProfile } from "redux/actions/updateProfileAction";
import Axios from "axios";
import { useDispatch } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import { useLocation, useParams } from "react-router";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";


function PPL() {

  const classes = useEmployeeStyles();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { id } = useParams()
  const [data, setData] = useState([]);
  const dispatch = useDispatch();


  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/protected/public/profile/${id}`
      );
      dispatch(updateLinguistProfile(response?.data));
      setData(response?.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <h3 style={{ textAlign: "center" }} color='purple'>
            <IconButton color="inherit" onClick={history.goBack}>
              <ArrowBackIcon />
            </IconButton>
            | Linguist Public Profile Preview Page (restricted)
          </h3>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <Card>
            <div
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                padding: "20px",
                boxShadow:
                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
              }}
            >
              <Header id={id} />
            </div>

            <div
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                padding: "20px",
                boxShadow:
                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
              }}
            >
              <Verified id={id} />
            </div>



            <div
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                padding: "20px",
                boxShadow:
                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
              }}
            >
              <ProfMemberShip id={id} />
            </div>

            <div
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                padding: "20px",
                boxShadow:
                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
              }}
            >
              <SecurityClearance id={id} />
            </div>
            
            {data.about ?
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                  padding: "20px",
                  boxShadow:
                    "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                }}
              >
                <About id={id} />
              </div>
              : null}

            <div
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                padding: "20px",
                boxShadow:
                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
              }}
            >
              <Qualification checkdata={data?.professional_qualification} id={id} />
            </div>

            <div
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                padding: "20px",
                boxShadow:
                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
              }}
            >
              <WorkExperience id={id} />
            </div>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default PPL;
