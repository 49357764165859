import React from "react";
import { Table } from "semantic-ui-react";
import moment from "moment";

const Items = ({ details }) => {
  const startTime = details.start_time[0].split(",");
  const startDate = details.start_date[0].split(",");
  const actualDuration = details?.actual_duration[0]?.split(",");

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="5">Job Details</Table.HeaderCell>
            <Table.HeaderCell width="3">Requested</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {/* {unverifiedLinguistList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list) => ( */}
          <Table.Row hover="true" role="checkbox" tabIndex={-1}>
            <Table.Cell>
              Level of expertise required source / native language (
              {details?.cacID?.source_language
                ? details?.cacID?.source_language
                : details?.bacID?.source_language}
              )
            </Table.Cell>

            <Table.Cell>
              {details?.cacID?.source_linguist_skill_level
                ? details?.cacID?.source_linguist_skill_level
                : details?.bacID?.source_linguist_skill_level}
            </Table.Cell>

            <Table.Cell>{details?.postedBy?.source_language_level}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Level of expertise required target language (
              {details?.cacID?.target_language
                ? details?.cacID?.target_language
                : details?.bacID?.target_language}
              )
            </Table.Cell>

            <Table.Cell>
              {details?.cacID?.target_linguist_skill_level
                ? details?.cacID?.target_linguist_skill_level
                : details?.bacID?.target_linguist_skill_level}
            </Table.Cell>

            <Table.Cell>{details?.postedBy?.target_language_level}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Timezone</Table.Cell>
            <Table.Cell>
              {details?.cacID?.timezone
                ? details?.cacID?.timezone
                : details?.bacID?.timezone}
            </Table.Cell>

            <Table.Cell>
              {details?.cacID?.timezone
                ? details?.cacID?.timezone
                : details?.bacID?.timezone}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="3">
              Appointment start date
            </Table.HeaderCell>
            <Table.HeaderCell width="3">
              Actual appointment start date
            </Table.HeaderCell>
            <Table.HeaderCell width="3">Start Time</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual start Time</Table.HeaderCell>
            <Table.HeaderCell width="3">Appointment Duration</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual Duration</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.HeaderCell width="3">
            {details?.cacID?.startdateArray
              ? details?.cacID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(st).tz("Europe/London").format("DD-MMM-YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.bacID?.startdateArray
              ? details?.bacID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(st).tz("Europe/London").format("DD-MMM-YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.start_date[0] !== ""
              ? startDate
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(st).format("DD-MMM-YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))
              : details?.cacID?.startdateArray
              ? details?.cacID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : details?.bacID?.startdateArray
              ? details?.bacID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.cacID?.starttimeArray
              ? details?.cacID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.bacID?.starttimeArray
              ? details?.bacID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.start_time[0] !== ""
              ? startTime
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : details?.cacID?.starttimeArray
              ? details?.cacID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : details?.bacID?.starttimeArray
              ? details?.bacID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.cacID?.durationArray
              ? details?.cacID?.durationArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.bacID?.durationArray
              ? details?.bacID?.durationArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.actual_duration[0] !== ""
              ? actualDuration
                  ?.filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>

          <Table.Row>
            <Table.Cell>Total Duration</Table.Cell>

            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              {details?.cacID?.gethour
                ? details?.cacID?.gethour
                : details?.bacID?.bulk_interpreting
                ? details?.bacID?.gethour / details?.bacID?.month_duration
                : details?.bacID?.gethour}
            </Table.Cell>
            <Table.Cell>{details?.duration}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default Items;
