import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "semantic-ui-react";
import SearchIcon from "@material-ui/icons/Search";
import CustomDate from "components/Common/CustomDate";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "40%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media(max-width:768px)"]: {
      width: "100%",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearchInput = ({
  values,
  from,
  setFrom,
  to,
  setTo,
  load,
  setFiltered,
  setFilter,
}) => {
  const classes = useStyles();
  const handleFromDateChange = (date) => {
    setFrom(date);
  };
  const handleToDateChange = (date) => {
    setTo(date);
  };
  const handleSearch = (e) => {
    e.preventDefault();

    setFiltered(
      values?.filter((item) => {
        let x = moment(item?.createdAt);
        return moment(x).isBetween(from, to);
      })
    );
    setFilter(Math.random());
  };

  return (
    <div>
      <Paper
        component="form"
        className={classes.root}
        onClick={(e) => e.preventDefault()}
      >
        <CustomDate
          onChange={handleFromDateChange}
          name="from"
          label="From date"
          value={from}
          format="yyyy-MM-dd"
        />
        <CustomDate
          onChange={handleToDateChange}
          name="to"
          label="To Date"
          value={to}
          format="yyyy-MM-dd"
        />
        <Button onClick={handleSearch} color="purple">
          Search
        </Button>
      </Paper>
    </div>
  );
};

export default SearchInput;
