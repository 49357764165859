import { useHistory } from "react-router-dom";

// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    width:'100%',
    ['@media (max-width:768px)']:{
      display:'inline-block',
      padding :'0px'
    },

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      ['@media (max-width:768px)']:{
       margin: "5px",
       width:'100%'
      },
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const InterpretingOption = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        onClick={() => history.push("/linguist/add-inhouse-client")}
      >
        Add / Remove Inhouse Client
      </Button>
      <Button
        variant="outlined"
        onClick={() => history.push("/linguist/view-inhouse-client")}
      >
        Search / View inhouse client
      </Button>
      <Button
        variant="outlined"
        onClick={() => history.push("/linguist/send-booking-form")}
      >
        Send Booking Form
      </Button>
      <Button
        variant="outlined"
        onClick={() => history.push("/linguist/inhouse-booking/book-interpreter")}
      >
        In-house Interpreting Booking
      </Button>
      <Button
        variant="outlined"
        onClick={() => history.push("/linguist/inhouse-booking/order-translation")}
      >
        In-house Translation Order
      </Button>
      <Button
        variant="outlined"
        onClick={() => history.push("/linguist/inc-unconfirm-assignment")}
      >
        Unconfirm Outgoing Assignments
      </Button>
      <Button
        variant="outlined"
        onClick={() => history.push("/linguist/inc-confirm-assignment")}
      >
        Confirmed Outgoing Assignments
      </Button>
      <Button
        variant="outlined"
        onClick={() => history.push("/linguist/inc-cancel-assignment")}
      >
        Cancelled Outgoing Assignments
      </Button>
      
    </div>
  );
};

export default InterpretingOption;
