import React from 'react'
import history from 'utils/history'
// Custom Components
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import CardHeader from "components/Card/CardHeader";

//Custom styles
import useDashboardStyles from 'assets/jss/pages/dashboardStyle';
import CardBody from 'components/Card/CardBody';
import ViewUnconfirmQuotation from 'components/CashClient/Assignments/UnconfirmQuotations/ViewUnconfirmQuotation';
import { useParams } from 'react-router-dom';

const ViewUnconfirmQuotationPage = () => {
  const classes = useDashboardStyles();
  const { id } = useParams()
  return (
    <CashClientLayout>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <CardHeader
          color="primary"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className={classes.cardTitleWhite}> Quotation view</p>
          <i className="icon list arrow left"
            onClick={() => {
              history.goBack()
            }}></i>
        </CardHeader>
        <CardBody>
          <ViewUnconfirmQuotation id={id} />
        </CardBody>
      </GridItem>
    </CashClientLayout>

  )
}

export default ViewUnconfirmQuotationPage
