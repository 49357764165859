import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import LinguistLayout from "layout/LinguistLayout";

import ViewAttach from "components/Admin/Communication/ViewAttachTicket";



const ViewContactCard = () => {

  return (
    <LinguistLayout>
      <Linguist>

        <ViewAttach />

      </Linguist>
    </LinguistLayout>
  );
};

export default ViewContactCard;
