import swal from "sweetalert";
import Cookies from "js-cookie";
import {
  UPDATE_CR_STEP,
  CHANGE_INPUT,
  CR_SUBMIT,
  SET_LOADING,
  CR_LOGIN,
} from "./actionTypes";

import history from "utils/history";
import Axios from "axios";

// data -> {name: "", value: ""}
export const changeCrInput = (data) => ({
  type: CHANGE_INPUT,
  payload: data,
});

export const updateCrStep = (count) => ({
  type: UPDATE_CR_STEP,
  payload: count,
});

export const crSubmit = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });

    // return action type
    dispatch({
      type: CR_SUBMIT,
    });
  } catch (error) {
    // else throw error message if any error

    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const crLogin = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    // Call API and store data
    const response = await Axios.post(`${process.env.REACT_APP_BACKEND_API}/cashclient/signin`, data);

    Cookies.set("token", response?.data.token);

    history.push("https://linguistscollective.com/cash-client/dashboard");

    // return action type
    dispatch({
      type: CR_LOGIN,
      payload: response?.data,
    });
  } catch (error) {
    // else throw error message if any error

    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};
////new