import React, { Fragment } from "react";
import { useSelector } from "react-redux";

// Material Core Components
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Material Icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";
import LandingPageLayout from "layout/LandingPageLayout";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import CustomFormControl from "components/Common/CustomFormControl";
import { Progress } from "semantic-ui-react";
import history from "utils/history";

const ContactRegistration = () => {
  const { count } = useSelector((state) => state.crReducer);

  const classes = useEmployeeStyles();

  const selectedForm = count;

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">

                  <Fragment>
                    Customer Enquiry and Quotation Request Form
                  </Fragment>
                </Typography>
                <Typography variant="subtitle2">Page {count} of 2</Typography>
              </div>
              {selectedForm === 1 && <FormOne />}
              {selectedForm === 2 && <FormTwo />}
              {selectedForm === 3 && <FormThree />}
              <CustomFormControl width="73.33%">
                <Progress
                  color="purple"
                  percent={(selectedForm / 2) * 100}
                  active
                  size="small"
                />
              </CustomFormControl>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>

  );
};

export default ContactRegistration;
