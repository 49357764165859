import Axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CardHeader from "components/Card/CardHeader";

const EmailActivationAC = () => {
  const [status, setStatus] = useState("");
  const classes = useDashboardStyles();

  const { token } = useParams();

  const preSignup = async (e) => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/accountclient/signup`,
        {
          token: token,
        }
      );
      setStatus(response?.data.message);
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    preSignup();
  }, []);
  return (
    <div>
      <GridContainer
        style={{
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Email Activation</p>
            </CardHeader>
            <CardBody
              style={{
                textAlign: "center",
              }}
            >
              <h3>Thank you for registering as a Billing Account Client</h3>
              <p>
                Please look out for further communication from LC Admin Team.
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default EmailActivationAC;
