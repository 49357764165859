import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { updateCrStep, changeCrInput } from "redux/actions/crAction";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CustomSelect from "components/Common/CustomSelect";
import { CURRENCY, CURRENT_COUNTRY, PERSONAL_ADDR, EMAIL_REGEX } from "data";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPhone from "components/Common/CustomPhone";
import CustomCaptcha from "components/Common/CustomCaptcha";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import CustomRadio from "components/Common/CustomRadio";
import history from "utils/history";

const FormTwo = () => {
  const { data } = useSelector((state) => state.crReducer);
  const token = Cookies.get("CACtoken");
  const [captcha, setCaptcha] = useState("");
  const [notValid, setNotValid] = useState(false);
  const {
    business_name,
    position,
    business_email,
    business_phone_no,
    business_address,
    business_address_2,
    business_town,
    business_zipcode,
    business_region,
    business_country,
    charity_registration_no,
    company_registration_no,
    business_website,
    currency,
    note,
    gdpr,
    contact_address,
    house_no,
    street_name,
    town,
    zip_code,
    county_region,
    country,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeCrInput(inputData));
  };
  const handleChecked = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: checked,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };
  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };
  const handlePhone = (value) => {
    // inputData({ ...data, mobile_no: value });
    dispatch(
      changeCrInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  const handleChangeCO = (e, value) => {
    dispatch(
      changeCrInput({
        name: "business_country",
        value,
      })
    );
  };

  const handleNext = async (e) => {
    let title = data?.title,
      FirstName = data?.first_name,
      LastName = data?.last_name,
      Birthday = data?.dob,
      sex = data?.gender,
      email = data?.email,
      password = data?.password,
      mobile_no = data?.mobile_no,
      direct_line = data?.direct_line,
      AddressLine2 = data?.street_name ? data?.street_name : "",
      AddressLine1 = data?.house_no,
      City = data?.town,
      PostalCode = data?.zip_code,
      Region = data?.county_region,
      Country = data?.country,
      businessAddressLine1 = data?.business_address,
      businessAddressLine2 = data?.business_address_2,
      businessCity = data?.business_town,
      businessPostalCode = data?.business_zipcode,
      businessRegion = data?.business_region,
      businessCountry = data?.business_country,
      Nationality = data?.current_nationality,
      other_nationality = data?.other_nationalities;

    if (contact_address?.includes("Same")) {
      businessAddressLine1 = data?.house_no;

      businessAddressLine2 = data?.street_name;

      businessCity = data?.town;

      businessPostalCode = data?.zip_code;

      businessRegion = data?.county_region;

      businessCountry = data?.country;
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/pre-signup`,
        {
          title,
          FirstName,
          LastName,
          email,
          password,
          mobile_no,
          direct_line,
          AddressLine1,
          AddressLine2,
          Birthday,
          sex,
          City,
          PostalCode,
          Region,
          Country,
          Nationality,
          other_nationality,
          business_name,
          position,
          business_email,
          business_phone_no,
          businessAddressLine1,
          businessAddressLine2,
          businessRegion,
          businessCountry,
          businessCity,
          businessPostalCode,
          charity_registration_no,
          company_registration_no,
          business_website,
          note,
          gdpr,
          currency,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(history.push("/"));
    } catch (error) {
      swal({
        title: "Oops",
        text: `${
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Please check your internet connection or server error, contact with admin team."
        }`,
        icon: "error",
        buttons: false,
      });
      console.log(error.response);
    }
  };

  const handleBack = () => {
    dispatch(updateCrStep(1));
  };
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        value={business_name}
        name="business_name"
        label="Business or organisation name"
        width="100%"
        placeholder="Trading name of your business / organisation. Otherwise please write N/A"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={position}
        name="position"
        label="Your position"
        width="100%"
        placeholder="Your position at your Corporation/Company. (if any)"
      />
      <CustomInputSingle
        onChange={handleEmailChange}
        value={business_email}
        name="business_email"
        label="Business email address"
        moreInfo="If different"
        width="100%"
        placeholder="e.g. example@example.com"
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <br />
      <CustomPhone
        label="Business phone number"
        onChange={handlePhone}
        placeholder="Business phone number (Please select country then type number e.g. +44 7307 000000)"
        value={business_phone_no}
        name="business_phone_no"
        required
      />

      <CustomRadio
        onChange={handleChange}
        label="Business address"
        moreInfo="If different from personal address"
        width="100%"
        data={PERSONAL_ADDR}
        name="contact_address"
        value={contact_address}
        required
      />

      {/* *Developer allow select same as before or add new with 5 fields including post code and country */}
      {contact_address?.includes("Different") && (
        <>
          {" "}
          <CustomBigInputSingle
            onChange={handleChange}
            label="House number/name, Street name"
            moreInfo="main contact address (if different)"
            width="100%"
            name="business_address"
            value={business_address}
            required
            placeholder="e.g. Flat/Building Name, 101 Example Street"
          />
          <CustomInputSingle
            onChange={handleChange}
            label="Address line 2"
            width="100%"
            name="business_address_2"
            value={business_address_2}
            placeholder="Additional Address Information"
          />
          <CustomInputSingle
            onChange={handleChange}
            label="Town / City"
            width="100%"
            name="business_town"
            value={business_town}
            required
            placeholder="Your Village, Town or City Name"
          />
          <CustomInputSingle
            onChange={handleChange}
            label="Post / Zip Code"
            width="100%"
            name="business_zipcode"
            value={business_zipcode}
            required
            placeholder="Your Post Code, Zip Code or Post Office Name"
          />
          <CustomInputSingle
            onChange={handleChange}
            label="County / Region"
            width="100%"
            name="business_region"
            value={business_region}
            required
            placeholder="Your County, Division or State Name"
          />
          <CustomAutoSelect
            onChange={handleChangeCO}
            label="Country"
            width="100%"
            name="business_country"
            required
            value={business_country}
            data={CURRENT_COUNTRY}
          />
        </>
      )}

      <CustomBigInputSingle
        onChange={handleChange}
        label="Company registration number"
        moreInfo="If registered"
        width="100%"
        name="company_registration_no"
        value={company_registration_no}
        placeholder="If registered provide company registration number (CRN) here"
      />
      <CustomBigInputSingle
        onChange={handleChange}
        label="Charity registration number"
        moreInfo="If registered"
        width="100%"
        name="charity_registration_no"
        value={charity_registration_no}
        placeholder="If registered provide charity registration number here "
      />
      <CustomInputSingle
        onChange={handleChange}
        value={business_website}
        name="business_website"
        label="Business website"
        width="100%"
        placeholder="e.g. www.YourCompanyWebsite.com"
      />
      <CustomSelect
        onChange={handleChange}
        data={CURRENCY}
        width="100%"
        label="Preferred currency"
        name="currency"
        value={currency}
        placeholder="GBP"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={note}
        name="note"
        label="Additional information or note"
        width="100%"
      />

      <div
        className={classes.tc}
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChecked}
              checked={gdpr}
              name="gdpr"
              color="primary"
            />
          }
          label="By submitting this form I agree with LC Service Agreement, Privacy Policy and T&C."
        />
      </div>
      <CustomCaptcha onChange={handleCaptcha} />

      <div className={classes.buttonFlex}>
        <CustomButton
          text="Back"
          variant="outlined"
          color="primary"
          onClick={handleBack}
        />
        <CustomButton
          disabled={
            !gdpr ||
            (business_email && notValid) ||
            !captcha ||
            !contact_address ||
            (contact_address?.includes("Different") && !business_address) ||
            (contact_address?.includes("Different") && !business_town) ||
            (contact_address?.includes("Different") && !business_region) ||
            (contact_address?.includes("Different") && !business_zipcode) ||
            (contact_address?.includes("Different") && !business_country)
          }
          text="Submit"
          onClick={handleNext}
        />
      </div>
    </form>
  );
};

export default FormTwo;
