import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "./CustomPhone.css"

const CustomPhone = ({label, ...rest}) => {
    return (
        <PhoneInput
            international
            // style= {{width: '100%', height: '100%'}}
            placeholder= {label}
            {...rest}
        />
    )
}

export default CustomPhone
