import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import AccountClientLayout from "layout/AccountClientLayout";
import Items from "pages/Component/ClientQuoteTable";
import QuotationDetails from "pages/Component/QuotationDetails";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

// Others
import { Card } from "@material-ui/core";
//import CustomLabel from "components/Common/CustomLabel";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import fetch from "isomorphic-fetch";
import TranslationDelivery from "pages/Component/Translation_Delivery/BAC/Translation_Delivery"

const ViewContactRequest = () => {
  const [state, setState] = React.useState({
    reload: false,
  });

  const history = useHistory();
  const classes = useDashboardStyles();
  const { id } = useParams();
  const [values, setValues] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/accountclient/details/quotation/quoteID/${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setValues(result);
      });
  }, [state]);

  return values ? (
    <Accountclient>
      <AccountClientLayout>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>Quotation - {values.id}</p>
            <i
              className="icon list arrow left"
              onClick={() => {
                history.goBack();
              }}
            ></i>
          </CardHeader>
        </GridItem>
        <br />
        <React.Fragment>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={8} xl={8}>
              <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
                <CardHeader
                  color="primary"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 10,
                    top: 35,
                  }}
                >
                  <p className={classes.cardTitleWhite}>Quotation</p>
                </CardHeader>
              </GridItem>
              <Card
                raised
                style={{ marginTop: "24px", padding: "30px", zIndex: 0 }}
              >
                <CardBody>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <CardBody>
                        <QuotationDetails values={values} />
                      </CardBody>

                      <Items values={values} />
                    </Card>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ zIndex: 1 }}
                    >
                      <Button
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          marginTop: 10,
                          top: 35,
                          background:
                            "linear-gradient( 60deg , #ab47bc, #8e24aa)",
                          width: 100 + "%",
                          padding: (10, 15),
                        }}
                        onClick={() =>
                          history.push(
                            `/account-client/view-quotation-document/${values._id}`
                          )
                        }
                      >
                        <p className={classes.cardTitleWhite}>View Document</p>
                      </Button>
                    </GridItem>
                    <br />
                    <br />
                  </GridItem>
                </CardBody>

                <CardBody>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ zIndex: 1 }}
                  >
                    <Card>
                      <TranslationDelivery />
                    </Card>
                  </GridItem>
                </CardBody>

              </Card>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      </AccountClientLayout>
    </Accountclient>
  ) : (
    "Loading..."
  );
};

export default ViewContactRequest;
