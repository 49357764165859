import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Action from "./Action";
import { Table } from "semantic-ui-react";
import Axios from "axios";
import Cookies from "js-cookie";
import "jspdf-autotable";
import moment from "moment";
import { Link } from "react-router-dom";
import SearchView from "./Search/SearchView";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ListCACAssignments({ text }) {
  const token = Cookies.get("CACtoken");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const [show, setShow] = useState(false);
  const [paidModal, setPaidModal] = React.useState(false);
  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  let sigPad = useRef({});

  const handleClose = (e) => {
    setSignature(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    UnconfirmBookingList: null,
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });

  const {
    name,
    error,
    success,
    UnconfirmBookingList,
    removed,
    reload,
    loader,
  } = values;

  useEffect(() => {
    loadUnconfirmBookingList();
  }, [reload, filter]);

  // const loadUnconfirmBookingList = async (e) => {
  //   try {
  //     const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/cashclient/confirm-linQuote-list`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       }
  //     })
  //     setValues({ ...values, UnconfirmBookingList: response?.data })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };

  const loadUnconfirmBookingList = async () => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/confirm-linQuote-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/unconfirm-linQuote-list-bankpay`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((confirmQuote, unconfirmQuote) => {
        const history = confirmQuote.data.concat(unconfirmQuote.data);

        filtered.length > 0
          ? setValues({ ...values, UnconfirmBookingList: filtered })
          : setValues({ ...values, UnconfirmBookingList: history });
        setFiltered([]);
      })
    );
  };

  return (
    <>
      <SearchView
        values={UnconfirmBookingList}
        SearchText={searchText}
        setSearchText={setSearchText}
        setFiltered={setFiltered}
        load={loadUnconfirmBookingList}
        setShow={setShow}
        setFilter={setFilter}
        show={show}
      />
      {show && (
        <>
          {" "}
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Quote ID</Table.HeaderCell>
                    <Table.HeaderCell>Linguist ref number</Table.HeaderCell>
                    <Table.HeaderCell>Timezone</Table.HeaderCell>
                    <Table.HeaderCell>Quotation Date</Table.HeaderCell>
                    <Table.HeaderCell>Currency</Table.HeaderCell>
                    <Table.HeaderCell>Grand total</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {UnconfirmBookingList ? (
                  <Table.Body>
                    {UnconfirmBookingList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).map((list, i) => (
                      <Table.Row
                        style={
                          list?.bank_pay && !list?.payment_status
                            ? { backgroundColor: "#E98BE2" }
                            : list?.reportID?.invoice
                            ? { backgroundColor: "red", color: "white" }
                            : list.project_report
                            ? { backgroundColor: "lightgreen" }
                            : { backgroundColor: "yellow" }
                        }
                        key={list._id}
                      >
                        {list?.quoteRequstByCashclient?.id ? (
                          <Table.Cell>
                            <Link
                              to={`/cash-client/view-cash-client-quotation/${list?.quoteRequstByCashclient?._id}`}
                            >
                              {list?.quoteRequstByCashclient?.id}
                            </Link>
                          </Table.Cell>
                        ) : (
                          <Table.Cell>
                            {list?.quoteRequstByCashclient?.id}
                          </Table.Cell>
                        )}
                        <Table.Cell>{list?.postedBy?.id}</Table.Cell>
                        <Table.Cell>{list?.timezone}</Table.Cell>
                        <Table.Cell>
                          {moment(list.createdAt).format("DD-MMM-YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.currency?.substring(0, 3)}
                        </Table.Cell>
                        <Table.Cell>{list.grand_total}</Table.Cell>
                        <Table.Cell>
                          <>
                            {list?.bank_pay && !list?.payment_status
                              ? "Await payment confirmation"
                              : null}
                            {list?.reportID?.signatureByLinTrue &&
                            list?.reportID?.signatureByClientTrue
                              ? "Project report accepted by both"
                              : list?.reportID?.signatureByadminTrue &&
                                list?.reportID?.signatureByClientTrue
                              ? "Project report accepted by client"
                              : list?.reportID?.signatureByLinTrue
                              ? "Project report created by linguist"
                              : list?.reportID?.signatureByClientTrue
                              ? "Project report created by client"
                              : list?.reportID?.signatureByadminTrue
                              ? "Project report created by admin"
                              : "Project report not created"}
                            <br />
                            {list?.reportID?.invoice
                              ? "-Invoiced by linguist-"
                              : null}{" "}
                          </>
                        </Table.Cell>
                        <Table.Cell>
                          <Action
                            load={loadUnconfirmBookingList}
                            id={list._id}
                            cancel={list.cancel}
                            pay={list?.payment_status}
                            report={list?.project_report}
                            reportID={list?.reportID?._id}
                            invoice={list?.reportID?.invoice}
                            invoiceID={list?.reportID?.invoiceID?._id}
                            clientReport={list?.reportID?.signatureByClientTrue}
                            linReport={list?.reportID?.signatureByLinTrue}
                            adminReport={list?.reportID?.signatureByadminTrue}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                ) : null}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={UnconfirmBookingList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}
    </>
  );
}
