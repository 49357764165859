import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
// Custom Components
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import defaultAxios from "utils/axios";
import swal from "sweetalert";
import Cookies from 'js-cookie'
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import {
  Header,
} from "semantic-ui-react";

const FormOne = () => {
  const classes = useLandingpageStyles();

  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({
    actual_price: "",
    discount_percentage: "",
    charities_discount_percentage: "",
    total: "",
    total_for_charities: ""
  })

  const handleEdit = (e) => {
    setEdit(true);
  };

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const total_discount = ((Number(data.actual_price) - Number(data.actual_price) * data.discount_percentage / 100)).toFixed(2);
  const total_charities_discount = ((Number(total_discount) - Number(total_discount) * data.charities_discount_percentage / 100)).toFixed(2);

  const isAllow =
    !data.actual_price ||
    !data.charities_discount_percentage ||
    !data.discount_percentage

  const handleSubmit = async (e) => {
    // console.log(data);
    const token = Cookies.get('token')

    const sendData = {
      actual_price: data.actual_price || "-",
      discount_percentage: data.discount_percentage || "-",
      charities_discount_percentage: data.charities_discount_percentage || "-",
      total: total_discount || "-",
      total_for_charities: total_charities_discount || "-",
    };
    try {
      const response = await defaultAxios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/post-job-advert-price`,
        sendData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      );
      // console.log(response);
      swal({
        title: "Success",
        text: response?.data.message,
        icon: "success",
        buttons: false,
      })
      setData({ ...data, actual_price: "", discount_percentage: "", charities_discount_percentage: "", total: "", total_for_charities: "" })
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };




  return (
    <>
      <br />
      <GridContainer justify="center">
        <TextField
          type="number"
          name="actual_price"
          onChange={handleChange}
          value={data.actual_price}
          width="100%"
          label="Actual Price"
          placeholder="£000.00"
          variant="outlined"
          required
        />
       
      </GridContainer>
      <br />
      <GridContainer justify="center">
        <TextField
          type="number"
          name="discount_percentage"
          onChange={handleChange}
          value={data.discount_percentage}
          width="100%"
          label="Discount Percentage"
          placeholder="000.00%"
          variant="outlined"
          required
        />
       
      </GridContainer>
      <br />
      <GridContainer justify="center">
        <Header style={{ margin: "0" }} as="h4">Discount Price : {total_discount}</Header>
      </GridContainer>

      <br />
      <GridContainer justify="center">
        <TextField
          type="number"
          name="charities_discount_percentage"
          onChange={handleChange}
          value={data.charities_discount_percentage}
          width="100%"
          label="Discount for NHS / Charities"
          placeholder="000.00%"
          variant="outlined"
          required
        />
      
      </GridContainer>

      <br />
      <GridContainer justify="center">
        <Header style={{ margin: "0" }} as="h4">Charities Discount Price : {total_charities_discount}</Header>
      </GridContainer>

      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <Button
          className={`${classes.button}`}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isAllow}
        >
          Submit
        </Button>
      </div>


    </>
  )
}

export default FormOne
