import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Action from "./Action";
import ActionDirect from "./ActionDirectInv";
import { Button } from "semantic-ui-react";
import ReactToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Moment from "moment";
import SearchView from "./Search/SearchInvoice";
import Cookies from "js-cookie";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
  button: {
    background: "#3f51b5 none",
    border: 0,
    color: "#ffff",
    padding: 10,
    margin: 0,
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
    borderRadius: "3pt",
    fontSize: "14px",
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("CACtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const [directInv, setDirectInv] = useState({
    name: "",
    error: false,
    success: false,
    directInvlist: [],
    removed: false,
    reload: false,
  });

  const { bookingList, reload } = values;
  const { directInvlist } = directInv;

  useEffect(() => {
    loadbookingList();
    loadDirectInv();
  }, [reload]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/list-paid-invoice`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, bookingList: response?.data });
    } catch (error) {
      console.log(error);
    }
  };

  const loadDirectInv = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/list-direct-paid-invoice-by-admin`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDirectInv({ ...directInv, directInvlist: response?.data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/find-paid-invoice`,
        { id: SearchText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setValues({...values,bookingList:response?.data})
      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct invoice id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });
        // bookingList=[response?.data]
        addToast("Invoice found", { appearance: "success" });
      } else {
        addToast("Invoice not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct invoice id.", {
          appearance: "error",
        });
      }
    }
  };

  //export in pdf
  const exportPDF = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 10;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    var totalPages = doc.internal.getNumberOfPages();
    var i;

    for (i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
      // doc.setTextColor(150);
      doc.text(
        50,
        doc.internal.pageSize.height - 30,
        "Strictly Company Confidential © linguistscollective.com"
      );
    }

    const title = "Paid invoices";
    const headers = [
      [
        "Invoice ID",
        "Date",
        "Currency",
        "Total",
        "VAT",
        "Total including VAT",
        "Due Date",
      ],
    ];

    const data = bookingList.map((list) => [
      list.id,
      list.date_of_invoice,
      list.currency.substring(0, 3),
      list.grand_total.toFixed(2),
      list.vat_percentage,
      list.vat_amount,
      list.due,
    ]);
    // const One = "Column One: Job / Your Reference";
    // const Two = "Column Two: Payment Received / Contract Value";

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        valign: "middle",
        // halign: 'justify'
      },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    var string = doc.output("datauristring");
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    var x = window.open();
    // doc.save('un-paid-invoice-table-pdf')
    x.document.open();
    x.document.write(embed);
    x.document.close();
  };

  return (
    <div>
      <SearchView
        load={loadbookingList}
        handleSearch={handleSearch}
        setSearchText={setSearchText}
        SearchText={SearchText}
        setShow={setShow}
        show={show}
      />
      {show ? (
        <div>
          <Paper className={classes.root}>
            <div>
              <br />
              <h3>Invoice from linguist</h3>
              <TableContainer className={classes.container}>
                <Table
                  id="cashbook-excel"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width="1">Invoice no</TableCell>
                      <TableCell width="1">Assignment ID</TableCell>
                      <TableCell width="1">Invoice Date</TableCell>
                      <TableCell width="1">Total</TableCell>
                      <TableCell width="1">VAT(%)</TableCell>
                      <TableCell width="1">Total including VAT</TableCell>
                      <TableCell width="1">Due date</TableCell>
                      <TableCell width="1">Action</TableCell>
                    </TableRow>
                  </TableHead>

                  {bookingList.length > 0 ? (
                    <TableBody>
                      {bookingList

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((list, i) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            <TableCell>{list.id}</TableCell>

                            {list.cacInterpretingID?._id ? (
                              <TableCell>
                                <Link
                                  to={`/cash-client/view-cash-client-booking-interpreting/${list.cacInterpretingID?._id}`}
                                >
                                  {list.assignment_id}
                                </Link>
                              </TableCell>
                            ) : list.cacTranslationID?._id ? (
                              <TableCell>
                                <Link
                                  to={`/cash-client/view-cash-client-order-translation/${list.cacTranslationID?._id}`}
                                >
                                  {list.assignment_id}
                                </Link>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Link
                                  to={`/cash-client/view-cash-client-quotation/${list.projectID?.linQuoteID?.quoteRequstByCashclient?._id}`}
                                >
                                  {list.assignment_id}
                                </Link>
                              </TableCell>
                            )}

                            <TableCell>
                              {Moment(list.date_of_invoice).format(
                                "DD-MMM-YYYY"
                              )}
                            </TableCell>
                            <TableCell>
                              {list?.currency?.substring(0, 3)}{" "}
                              {list.grand_total}
                            </TableCell>
                            <TableCell>{list.vat_percentage}</TableCell>
                            <TableCell>
                              {list?.currency?.substring(0, 3)}{" "}
                              {list.vat_amount}
                            </TableCell>
                            <TableCell>
                              {Moment(list.invoice_tobe_paid_by).format(
                                "DD-MMM-YYYY"
                              )}
                            </TableCell>
                            <TableCell>
                              <Action
                                projectID={list.projectID?.id}
                                id={list._id}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={bookingList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </Paper>

          <Paper className={classes.root}>
            <div>
              <h3>Direct invoice from admin</h3>
              <TableContainer className={classes.container}>
                <Table
                  id="cashbook-excel"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width="1">Invoice no</TableCell>
                      <TableCell width="1">Admin ID</TableCell>
                      <TableCell width="1">Admin Name</TableCell>
                      <TableCell width="1">Invoice Date</TableCell>
                      <TableCell width="1">Currency</TableCell>
                      <TableCell width="1">Total</TableCell>
                      <TableCell width="1">VAT</TableCell>
                      <TableCell width="1">Total including VAT</TableCell>
                      <TableCell width="1">Due date</TableCell>
                      <TableCell width="1">Action</TableCell>
                    </TableRow>
                  </TableHead>

                  {directInvlist.length > 0 ? (
                    <TableBody>
                      {directInvlist

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((list, i) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            <TableCell>{list?.id} </TableCell>
                            <TableCell>{list?.postedBy?.id} </TableCell>
                            <TableCell>{list?.postedBy?.FirstName} </TableCell>
                            <TableCell>
                              {Moment(list.date_of_invoice).format(
                                "DD-MMM-YYYY"
                              )}
                            </TableCell>
                            <TableCell>{list.currency}</TableCell>
                            <TableCell>{list.grand_total}</TableCell>
                            <TableCell>{list.vat_percentage}</TableCell>
                            <TableCell>{list.vat_amount}</TableCell>
                            <TableCell>
                              {Moment(list.invoice_tobe_paid_by).format(
                                "DD-MMM-YYYY"
                              )}
                            </TableCell>
                            <TableCell>
                              <ActionDirect id={list._id} />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={directInvlist.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </Paper>

          <div style={{ marginTop: "5px" }}>
            {/* Export in PDF */}
            <Button
              style={{ background: "#3f51b5 none", color: "white" }}
              onClick={exportPDF}
            >
              Export in Pdf
            </Button>
            {/* Export in XLS */}
            <ReactToExcel
              className={classes.button}
              table="cashbook-excel"
              filename="paid-invoices"
              sheet="sheet 1"
              buttonText="Export in Excel"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
