import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import history from 'utils/history'
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import ULOptions from 'components/Admin/HrPanel/ULOptions'
import UnregLinguist from "components/Admin/HrPanel/UnregisteredLinguist/ViewULinguists";

const ListUnregisteredLinguist = () => {
  const classes = useDashboardStyles();
  return (
    <Manager>
      <MasterLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                <p className={classes.cardTitleWhite}>Unregistered Linguists</p>
                <IconButton color='inherit' onClick={() => history.goBack()}><ArrowBackIcon></ArrowBackIcon></IconButton>
              </CardHeader>
              <CardBody>
                <ULOptions />
              </CardBody>
              <CardBody>
                <UnregLinguist />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </MasterLayout>
    </Manager>
  );
};

export default ListUnregisteredLinguist;
