import React, { useState } from "react";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import {
  Header,
} from "semantic-ui-react";
import useFormStyles from "assets/jss/components/auth/formStyle";

import defaultAxios from "utils/axios";
import swal from "sweetalert";
import Cookies from "js-cookie";

const FormOne = () => {
  const classes = useFormStyles();



  const [data, setData] = useState({
    labelName: "",
    labelOne: "",
    labelTwo: "",
    labelThree: "",
    labelFour: "",
  });

  const total = ((Number(data.labelOne) +
    Number(data.labelTwo) +
    Number(data.labelThree) +
    Number(data.labelFour))).toFixed(2);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const isAllow =
    !data.labelName ||
    !data.labelOne ||
    !data.labelTwo ||
    !data.labelThree ||
    !data.labelFour 

  const handleSubmit = async (e) => {
    // console.log(data);
    const token = Cookies.get("token");

    const sendData = {
      labelName: data.labelName || "-",
      labelOne: data.labelOne || 0,
      labelTwo: data.labelTwo || 0,
      labelThree: data.labelThree || 0,
      labelFour: data.labelFour || 0,
    };
    try {
      const response = await defaultAxios.post(
        `${process.env.REACT_APP_BACKEND_API}/admin/percent_card`,
        sendData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      swal({
        title: "Success",
        text: response?.data?.message,
        icon: "success",
        buttons: false,
      });
      setData({
        labelName: "",
        labelOne: "",
        labelTwo: "",
        labelThree: "",
        labelFour: "",
       });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        label="Label Name"
        name="labelName"
        value={data.labelName}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="LC Platform Fee %"
        name="labelOne"
        value={data.labelOne}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Linguist %"
        name="labelTwo"
        value={data.labelTwo}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Commission To Linguist %"
        name="labelThree"
        value={data.labelThree}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Commission To SPPO %"
        name="labelFour"
        value={data.labelFour}
        width="100%"
        required
      />
      <br />
      <Header style={{ margin: "0" }} as="h4">Total : {total}</Header>
      {total == 100 ?
        <div className={classes.buttonFlex}>
          <CustomButton disabled={isAllow} text="Send" onClick={handleSubmit} />
        </div> : <p style={{color: "red"}}>Total is not equal 100%</p>}
    </form>
  );
};

export default FormOne;
