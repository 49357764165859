import React from 'react'

import { Input } from '@material-ui/core'

// Custom imports
import CustomFormControl from './CustomFormControl'

const CustomNumberInput = ({ width, label, required, ...rest }) => {
    return (
        <CustomFormControl style= {{margin: '32px auto 0'}} width={`${"100%"}`}>
            <Input
                // onChange={handleChange}
                // value={mobile_no}
                {...rest}
                //name="mobile_no"
                placeholder= {`${label} ${required ? '*' : ''}`}
                style= {{width: width}}
                type= 'number'
                required
            />
        </CustomFormControl>
    )
}

export default CustomNumberInput
