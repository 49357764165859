import React, { useState } from "react";

// Material Components
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import history from "utils/history";
// Custom Components
import CustomInput from "components/Common/CustomInput";
import ForgotPassword from "components/Auth/Password/CashForgotPassword";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import { whiteColor } from "assets/jss/variables";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    minWidth: "300px",
    padding: theme.spacing(2, 4, 2),
    textAlign: "center",

    "& .MuiButton-root": {
      marginTop: "15px",
    },
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "18px",
    paddingTop: "0px",
    "& small": {
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const ForgotPasswordLogin = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
        }}
      >
        <Button color="primary" onClick={handleOpen}>
          Forgot Password?{" "}
        </Button>
        <Button
          color="primary"
          onClick={() => history.push("/account-client-login")}
        >
          Billing Account Client (BAC) Login{" "}
        </Button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Forgot Password</p>
              </CardHeader>
              <CardBody>
                <ForgotPassword />
              </CardBody>
            </Card>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ForgotPasswordLogin;
