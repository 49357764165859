import React, { useState } from "react";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomPassword from "components/Common/CustomPassword";
import swal from "sweetalert";
import Axios from "axios";
import Cookies from "js-cookie";
import CustomCaptcha from "components/Common/CustomCaptcha";
import Button from "@material-ui/core/Button";
import ForgotPassword from "./CACForgotPassword";
import { Header } from 'semantic-ui-react'
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import {Divider} from '@material-ui/core'
const VerifyCAC = ({ setVerified }) => {
  const classes = useEmployeeStyles();
  const [clientPassword, setClientPassword] = useState();
  const [clientEmail, setClientEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setClientPassword(value);
  };
  const handleClientEmailChange = (e) => {
    const { value } = e.target;
    setClientEmail(value);
  };

  const handleCACLogin = async (e) => {
    e.preventDefault();
    const sendData = { email: clientEmail, password: clientPassword };
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/signin`,
        sendData
      );

      const { token, user } = response?.data;
      Cookies.set("CACtoken", token);
      setVerified(true);
      sessionStorage.setItem("frontUser", JSON.stringify(user));
      localStorage.setItem("CACuser", JSON.stringify(user));
      Cookies.set("isAuth", true);
      setLoading(false);
    } catch (error) {
      // Copy from other catch's code
      if (error?.response?.data?.error) {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      } else {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `Please check your internet connection.`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const isAllow = !clientEmail || !clientPassword;
  return (
    <Card>
      <CardBody>
       <Header style={{ margin: "0" }} as="h3" textAlign="center">
                   
                     Cash Account Client (CAC) Login
                   
        </Header>
        <Divider orientation="horizontal" style={{margin:10}}/>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleClientEmailChange}
          value={clientEmail}
          name="email"
          label="Email"
          width="100%"
          type="email"
          placeholder="Your registered primary email "
          required
        />
        <CustomPassword
          onChange={handlePasswordChange}
          value={clientPassword}
          name="clientPassword"
          label="Password"
          width="100%"
          placeholder="Enter your password"
          required
        />
        <CustomCaptcha onChange={(value) => setValid(value)} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 10,
          }}
        >
          <div className={classes.buttonDiv}>
            <Button
              color="primary"
              onClick={handleCACLogin}
              disabled={isAllow || loading || !valid}
              variant="contained"
            >
              {loading ? "Logging..." : "Login"}
            </Button>
           
          </div>
          {/*         
        <Button
          loading={loading}
          disabled={isAllow || loading || !valid}
          color="purple"
          onClick={handleCACLogin}
        >
          Verify Account
        </Button> */}
        </div>
      </form>
      <div className={classes.forgotDiv}>
        <span />
        <ForgotPassword />
      </div>
      </CardBody>
    </Card>
  );
};
export default VerifyCAC;
