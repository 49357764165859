import swal from "sweetalert";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import fetch from "isomorphic-fetch";

import {
  ACCOUNT_CLIENT_INTERPRETING_REQUEST,
  ACCOUNT_CLIENT_INTERPRETING_SUCCESS,
  ACCOUNT_CLIENT_INTERPRETING_FAIL,
} from "constants/cashClient_constant";

import history from "utils/history";
import {
  ACCOUNT_CLIENT_JOBADVART_REQUEST,
  ACCOUNT_CLIENT_JOBADVART_SUCCESS,
  ACCOUNT_CLIENT_JOBADVART_FAIL,
  ACCOUNT_CLIENT_QUOTS_FAIL,
  ACCOUNT_CLIENT_QUOTS_REQUEST,
  ACCOUNT_CLIENT_QUOTS_SUCCESS,
  ACCOUNT_CLIENT_TRANSLATION_REQUEST,
  ACCOUNT_CLIENT_TRANSLATION_SUCCESS,
  ACCOUNT_CLIENT_TRANSLATION_FAIL,
} from "constants/accountclientConstants";
const token = Cookies.get("BACtoken");
//Cash client boooking interpreter
export const accountClientbooking = (
  purchase_order_number,
  timezone,
  date,
  starttime,
  endtime,
  interpreting_service_required,
  house_number_street_name,
  address_line_two,
  region,
  post_code,
  country,
  current_nationality,
  other_nationalities,
  remote_address,
  source_language,
  target_language,
  linguist_skill_level,
  typesof_interpreting_required,
  job_description,
  preferred_interpreter,
  gender,
  security_clearance,
  car_parking,
  additional_info,
  currency,
  scripts,
  agree,
  username
) => async (dispatch) => {
  dispatch({
    type: ACCOUNT_CLIENT_INTERPRETING_REQUEST,
    payload: {
      purchase_order_number,
      timezone,
      date,
      starttime,
      endtime,
      interpreting_service_required,
      house_number_street_name,
      address_line_two,
      region,
      post_code,
      country,
      current_nationality,
      other_nationalities,
      remote_address,
      source_language,
      target_language,
      linguist_skill_level,
      typesof_interpreting_required,
      job_description,
      preferred_interpreter,
      gender,
      security_clearance,
      car_parking,
      additional_info,
      currency,
      scripts,
      agree,
      username,
    },
  });
  
  try {
    const { data } = await Axios.post(
      "/api/accountclient/accountClientinterpreting",
      {
        purchase_order_number,
        timezone,
        date,
        starttime,
        endtime,
        interpreting_service_required,
        house_number_street_name,
        address_line_two,
        region,
        post_code,
        country,
        current_nationality,
        other_nationalities,
        remote_address,
        source_language,
        target_language,
        linguist_skill_level,
        typesof_interpreting_required,
        job_description,
        preferred_interpreter,
        gender,
        security_clearance,
        car_parking,
        additional_info,
        currency,
        scripts,
        agree,
        username,
      }
    );

    dispatch({ type: ACCOUNT_CLIENT_INTERPRETING_SUCCESS, payload: data });

    swal({
      title: "Please check the summary details",
      text: `and pay to confirm your order. `,
      icon: "success",
      buttons: false,
    });
    history.push("book-interpreter/summary");
  } catch (error) {
    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: ACCOUNT_CLIENT_INTERPRETING_FAIL,
      payload:
        error.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message,
    });
  }
};

//Account client quotes request
export const ACQuotesRequest = (
  purchase_order_number,
  quote_rqst_for,
  house_street_no_name,
  address_line_two,
  town_city,
  post_code,
  county_region,
  country,
  other_details,
  request_details,
  project_fix_budget,
  documents,
  source_language,
  target_language,
  skills_required,
  provider_id_number,
  gender,
  timezone,
  quote_by,
  pay_type,
  gdpr,
  username
) => async (dispatch) => {
  dispatch({
    type: ACCOUNT_CLIENT_QUOTS_REQUEST,
    payload: {
      purchase_order_number,
      quote_rqst_for,
      house_street_no_name,
      address_line_two,
      town_city,
      post_code,
      county_region,
      country,
      other_details,
      request_details,
      project_fix_budget,
      documents,
      source_language,
      target_language,
      skills_required,
      provider_id_number,
      gender,
      timezone,
      quote_by,
      pay_type,
      gdpr,
      username,
    },
  });

  // console.log("data");
  try {
    const { data } = await Axios.post(
      "/api/accountclient/request/quote",
      {
        purchase_order_number,
        quote_rqst_for,
        house_street_no_name,
        address_line_two,
        town_city,
        post_code,
        county_region,
        country,
        other_details,
        request_details,
        project_fix_budget,
        documents,
        source_language,
        target_language,
        skills_required,
        provider_id_number,
        gender,
        timezone,
        quote_by,
        pay_type,
        gdpr,
        username,
      }
    );
    dispatch({ type: ACCOUNT_CLIENT_QUOTS_SUCCESS, payload: data });
    swal({
      title: "Success!",
      text: `Submited`,
      icon: "success",
      buttons: false,
    });

    history.push(`/account-client/dashboard`);
  } catch (error) {
    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: ACCOUNT_CLIENT_QUOTS_FAIL,
      payload:
        error.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message,
    });
  }
};
// translationrequestAC

//order translation
export const translationrequestAC = (
  wordCount,
  purchase_order_number,
  document_type,
  other_details,
  source_language,
  target_language,
  expertise_level_required,
  require_visual_formating,
  certified_translation,
  certificate,
  additional_quality_check,
  provider_id_number,
  additional_note,
  timezone,
  deadline,
  agree,
  username
) => async (dispatch) => {
  dispatch({
    type: ACCOUNT_CLIENT_TRANSLATION_REQUEST,
    payload: {
      wordCount,
      purchase_order_number,
      document_type,
      other_details,
      source_language,
      target_language,
      expertise_level_required,
      require_visual_formating,
      certified_translation,
      certificate,
      additional_quality_check,
      provider_id_number,
      additional_note,
      timezone,
      deadline,
      agree,
      username,
    },
  });

  try {
    const { data } = await Axios.post(
      "/api/accountclient_translation",
      {
        wordCount,
        purchase_order_number,
        document_type,
        other_details,
        source_language,
        target_language,
        expertise_level_required,
        require_visual_formating,
        certified_translation,
        certificate,
        additional_quality_check,
        provider_id_number,
        additional_note,
        timezone,
        deadline,
        agree,
        username,
      }
    );
    // console.log(data);
    swal({
      title: "success!",
      text: `${data.message}`,
      icon: "success",
      buttons: false,
    });

    dispatch({ type: ACCOUNT_CLIENT_TRANSLATION_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: ACCOUNT_CLIENT_TRANSLATION_FAIL,
      payload:
        error.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message,
    });
  }
};

// //account client quotes request
export const job_advart_ac = (
  purchase_order_number,
  remote_address,
  job_title,
  salary,
  contract_type,
  hours_per_week,
  house_street_no_name,
  address_line_two,
  town_city,
  post_code,
  county_region,
  country,
  current_nationality,
  other_nationalities,
  job_ref,
  source_language,
  target_language,
  additional_language,
  linguist_skills_level,
  about_company,
  job_description,
  application_closing_date,
  nhs_trust,
  registration_number,
  agree,
  username
) => async (dispatch) => {
  dispatch({
    type: ACCOUNT_CLIENT_JOBADVART_REQUEST,
    payload: {
      purchase_order_number,
      remote_address,
      job_title,
      salary,
      contract_type,
      hours_per_week,
      house_street_no_name,
      address_line_two,
      town_city,
      post_code,
      county_region,
      country,
      current_nationality,
      other_nationalities,
      job_ref,
      source_language,
      target_language,
      additional_language,
      linguist_skills_level,
      about_company,
      job_description,
      application_closing_date,
      nhs_trust,
      registration_number,
      agree,
      username,
    },
  });
  try {
    const { data } = await Axios.post(
      `/api/accountclient_jobadvert`,
      {
        purchase_order_number,
        remote_address,
        job_title,
        salary,
        contract_type,
        hours_per_week,
        house_street_no_name,
        address_line_two,
        town_city,
        post_code,
        county_region,
        country,
        current_nationality,
        other_nationalities,
        job_ref,
        source_language,
        target_language,
        additional_language,
        linguist_skills_level,
        about_company,
        job_description,
        application_closing_date,
        nhs_trust,
        registration_number,
        agree,
        username,
      }
    );
    dispatch({ type: ACCOUNT_CLIENT_JOBADVART_SUCCESS, payload: data });
    // console.log(data);
    swal({
      title: "Welcome!",
      text: `${data}`,
      icon: "success",
      buttons: false,
    });

    history.push("/form");
  } catch (error) {
    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: ACCOUNT_CLIENT_JOBADVART_FAIL,
      payload:
        error.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message,
    });
  }
};

// get cookie
export const getCookie = (key) => {
  if (process.browser) {
    return Cookies.get(key);
  }
};

export const isAuth = () => {
  if (process.browser) {
    const cookieChecked = getCookie("BACtoken");
    if (cookieChecked) {
      if (localStorage.getItem("BACuser")) {
        return JSON.parse(localStorage.getItem("BACuser"));
      } else {
        return false;
      }
    }
  }
};

// booking interpreting list
export const getConfirmBookingList = () => {
  const token = Cookies.get("BACtoken");
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/accountclient/booking-list`,

    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// booking interpreting list
export const getConfirmTranslationList = () => {
  const token = Cookies.get("BACtoken");
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/accountclient/translation-list`,

    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


// Remote interpreting video / telephone
// Remote video / telephone
// Remote Interpreting( VRI Video/Remote Interpreting Telephon)
// Remote Interpreting (RI) / Video Remote Interpreting (VRI) details

// remove cookie
export const removeCookie = (key) => {
  if(process.browser){
    Cookies.remove(key, {
          expires: 1
      })
  }
};

// set localstorage
export const setLocalStorage = (key, value) => {
  if(process.browser){
      localStorage.setItem(key, JSON.stringify(value));
  }
};

// remove localstorage
export const removeLocalStorage = (key) => {
  if(process.browser){
      localStorage.removeItem(key);
  }
};


export const signout = next => {
  removeCookie('BACtoken');
  removeLocalStorage('BACuser');
  next();

  return fetch(`${process.env.REACT_APP_BACKEND_API}/accountclient/signout`, {
      method: 'GET'
  })
      .then(response => {
          console.log('Signout Success');
      })
      .catch(err => console.log(err));
};


export const handleResponse = response => {
  if (response.status === 401) {
      signout(() => {
          history.push({
            pathname: '/account-client-login',
            query: {
                message: 'Your session is expired. Please signin again'
            }
        });
      });
  }
};

export const getProfile = token => {
  return fetch(`${process.env.REACT_APP_BACKEND_API}/accountclient/dashboard`, {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
      }
  })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
};

export const update = (token, user) => {
  return fetch(`${process.env.REACT_APP_BACKEND_API}/accountclient/profile/update`, {
      method: 'PUT',
      headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
      },
      body: user

  })
      .then(response => {
          handleResponse(response);
          return response.json();
      })
      .catch(err => console.log(err));
};

// update user in local storage
export const updateUser = (user, next) => {
  if (process.browser) {
      if (localStorage.getItem('user')) {
          let auth = JSON.parse(localStorage.getItem('user'));
          auth = user;
          localStorage.setItem('user', JSON.stringify(auth));
          next();
      }
  }
};



// quotation list
export const getSendQuotationList = () => {
  const token = Cookies.get('BACtoken')
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/accountclient/list/quotations`,

    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
