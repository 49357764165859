import React, { useState } from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LandingPageLayout from "layout/LandingPageLayout";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
// Custom Styles
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import ThreeCards from "./ThreeCards";
import interpreting_front from "assets/icons/interpreting_front.png";
import TNimage from "assets/icons/translation-new.png";
import FEEimage from "assets/icons/forensic_expert.png";

import job from "assets/icons/job.png";
import multilingual_expert from "assets/icons/multilingual_expert.png";
import expert_language_solution from "assets/icons/expert_language_solution.png";

const FrontPage = () => {
  const classes = useLandingpageStyles();
  const [changeLink, setChangeLink] = useState(false);
  return (
    <LandingPageLayout>
      {/* <ThreeCards /> */}
      <div className={`${classes.paddingMobile}`}>
        <Typography display="block" align="center" variant="h3">
          Welcome to Linguists Collective
        </Typography>

        <Typography
          paragraph
          display="block"
          align="center"
          variant="body1"
          style={{ paddingLeft: "70px", paddingRight: "70px" }}
        >
          We are an agency and service-based marketplace for language services.
          <br />
          Please register to access your dedicated dashboard.
        </Typography>
        <br />
        {/* </div>
      <div className={`${classes.paddingMobile}`}> */}
        <Typography display="block" align="center" variant="h3">
          Services
        </Typography>
        <GridContainer style={{ textAlign: "justify" }}>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card className={`${classes.hoverCard}`}>
              <CardBody>
                <Link
                  style={{ textDecoration: "none" }}
                  to={
                    changeLink
                      ? "/interpreting-or-translation"
                      : "/landing-page/interpreter"
                  }
                >
                  <div className={`${classes.services}`}>
                    <img
                      style={{
                        width: "90px",
                        height: "90px",
                      }}
                      src={interpreting_front}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: "purple",
                        marginTop: "1em",
                        marginBottom: "0",
                      }}
                    >
                      Interpreting
                    </p>
                    <Typography style={{ color: "purple" }} variant="body1">
                      It’s instant and facilitates conversation between two or
                      more languages which makes it ideal for meetings that have
                      a sense of urgency to them. This could be anything from a
                      doctor explaining test results to a patient to a
                      conversation between a police officer and someone in
                      custody.{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        onMouseOver={() => setChangeLink(true)}
                        onMouseLeave={() => setChangeLink(false)}
                      >
                        Book an interpreter
                      </a>
                    </Typography>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card className={`${classes.hoverCard}`}>
              <CardBody>
                <Link
                  style={{ textDecoration: "none" }}
                  to={
                    changeLink
                      ? "/interpreting-or-translation"
                      : "/landing-page/translation"
                  }
                >
                  <div className={`${classes.services}`}>
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                      src={TNimage}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: "purple",
                        marginTop: "1em",
                        marginBottom: "0",
                      }}
                    >
                      Translation
                    </p>
                    <Typography style={{ color: "purple" }} variant="body1">
                      You may order a translation service of up to 10,000 words
                      or 20 A4 pages directly. However, if your translation
                      project is larger than 10,000 words or more than 20 A4
                      pages, please contact us by using the quotation form.{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        onMouseOver={() => setChangeLink(true)}
                        onMouseLeave={() => setChangeLink(false)}
                      >
                        Order translation online
                      </a>
                      <br /> <br />
                    </Typography>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card className={`${classes.hoverCard}`}>
              <CardBody>
                <Link
                  style={{ textDecoration: "none" }}
                  to={
                    changeLink
                      ? "/add-new-customer"
                      : "/landing-page/bilingual-professional"
                  }
                >
                  <div className={`${classes.services}`}>
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                      src={multilingual_expert}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: "purple",
                        marginTop: "1em",
                        marginBottom: "0",
                      }}
                    >
                      Multilingual Professional
                    </p>
                    <Typography style={{ color: "purple" }} variant="body1">
                      Multilingual Professionals can form good relationships
                      with people across the globe. Here our speakers use their
                      language talents on jobs such as expanded vocabularies,
                      problem solving skills, listening skills and interpersonal
                      skills.{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        onMouseOver={() => setChangeLink(true)}
                        onMouseLeave={() => setChangeLink(false)}
                      >
                        Request quote
                      </a>
                      <br />
                    </Typography>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card className={`${classes.hoverCard}`}>
              <CardBody>
                <Link
                  style={{ textDecoration: "none" }}
                  to={
                    changeLink
                      ? "/add-new-customer"
                      : "/landing-page/forensic-expert-evidence"
                  }
                >
                  <div className={`${classes.services}`}>
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                      src={FEEimage}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: "purple",
                        marginTop: "1em",
                        marginBottom: "0",
                      }}
                    >
                      Forensic & Expert Evidence
                    </p>
                    <Typography style={{ color: "purple" }} variant="body1">
                      This unique, one of a kind service is here to help
                      investigation and offer a careful systematic analysis of
                      language. It will allow the use of an interpreter or
                      translator to help and aid the court with information and
                      research to communicate with greater accuracy.{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        onMouseOver={() => setChangeLink(true)}
                        onMouseLeave={() => setChangeLink(false)}
                      >
                        Contact us for more details or quotation
                      </a>
                    </Typography>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card className={`${classes.hoverCard}`}>
              <CardBody>
                <Link
                  style={{ textDecoration: "none" }}
                  to={
                    changeLink
                      ? "/add-new-customer"
                      : "/landing-page/expert-language-solutions"
                  }
                >
                  <div className={`${classes.services}`}>
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                      src={expert_language_solution}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: "purple",
                        marginTop: "1em",
                        marginBottom: "0",
                      }}
                    >
                      Expert Language Solutions
                    </p>
                    <Typography style={{ color: "purple" }} variant="body1">
                      Our expert linguists can provide services for cultural
                      consultancy, editing, proofreading, voiceover and
                      voiceover artist, to transcription, translation of several
                      types and many more.{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        onMouseOver={() => setChangeLink(true)}
                        onMouseLeave={() => setChangeLink(false)}
                      >
                        Request quote
                      </a>
                    </Typography>{" "}
                    <br />
                    <br />
                  </div>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card className={`${classes.hoverCard}`}>
              <CardBody>
                <Link style={{ textDecoration: "none" }} to="/post-job-advert">
                  <div className={`${classes.services}`}>
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                      src={job}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: "purple",
                        marginTop: "1em",
                        marginBottom: "0",
                      }}
                    >
                      Job Advert and Search
                    </p>
                    <Typography style={{ color: "purple" }} variant="body1">
                      Recruitment advertising and great opportunities available
                      here. Find a language industry related talent or job.
                      Whether you would like to post a job advert or search for
                      a job, click here to find out more.
                    </Typography>
                  </div>
                  <br />
                </Link>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>

      <br />
      <ThreeCards />
      <br />
    </LandingPageLayout>
  );
};

export default FrontPage;
