import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Action from "components/CashClient/Assignments/UnconfirmAssignments/ListTranslations/Actions/Action";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function ListCACAssignments({ translationData, text }) {
  const token = Cookies.get("CACtoken");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paidModal, setPaidModal] = React.useState(false);
  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  let sigPad = useRef({});

  const handleClose = (e) => {
    setSignature(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    UnconfirmBookingList: [],
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });

  const {
    name,
    error,
    success,
    UnconfirmBookingList,
    removed,
    reload,
    loader,
  } = values;

  useEffect(() => {
    loadUnconfirmBookingList();
  }, [reload]);

  const loadUnconfirmBookingList = async (e) => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/unconfirmed-translation-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((unconfirmedJobs) => {
        setValues({
          ...values,
          UnconfirmBookingList: translationData
            ? unconfirmedJobs.data.filter((item) => item.id === translationData)
            : unconfirmedJobs.data,
        });
      })
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Assignment ID</TableCell>
                <TableCell>Document</TableCell>
                <TableCell>Timezone</TableCell>
                <TableCell>Deadline</TableCell>
                <TableCell>Source language</TableCell>
                <TableCell>Target language</TableCell>
                <TableCell>Expertise Level</TableCell>
                <TableCell>Word Count</TableCell>
                <TableCell>Estimate Paid</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {UnconfirmBookingList.length > 0 ? (
              <TableBody>
                {UnconfirmBookingList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((list, i) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    <TableCell>
                      <Link
                        to={`/cash-client/view-cash-client-order-translation/${list._id}`}
                      >
                        {list.id}
                      </Link>
                    </TableCell>
                    <TableCell>{list?.document_type}</TableCell>
                    <TableCell>{list?.timezone}</TableCell>
                    <TableCell>
                      {moment(list?.deadline).format("DD-MMM-YYYY : HH:mm:ss")}
                    </TableCell>
                    <TableCell>{list?.source_language}</TableCell>
                    <TableCell>{list?.target_language}</TableCell>
                    <TableCell>
                      {list?.target_expertise_level_required}
                    </TableCell>
                    <TableCell>{list?.wordCount}</TableCell>
                    <TableCell>
                      {list?.currency_new?.substring(0, 3) +
                        " " +
                        list?.estimate}
                    </TableCell>
                    <TableCell>{list?.status}</TableCell>
                    <TableCell>
                      <Action load={loadUnconfirmBookingList} id={list._id} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={UnconfirmBookingList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
