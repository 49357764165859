import Axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import { Button } from "semantic-ui-react";
// Custom Components
import CommonCard from "components/Common/CommonCard";
import CustomDateTime from "components/Common/CustomDateTime";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import { useDispatch } from "react-redux";
import history from "utils/history";

const MyNotes = () => {
  const [deadlineCom, setDeadline] = useState(null);
  const [priorityCom, setPriority] = useState(0);
  const token = Cookies.get("LINtoken");
  const [totalnote, setTotalNote] = React.useState(false);
  const dispatch = useDispatch();
  const options = [
    { key: 1, text: "Note", value: 1 },
    { key: 2, text: "Low", value: 2 },
    { key: 3, text: "Medium", value: 3 },
    { key: 4, text: "High", value: 4 },
    { key: 5, text: "Done", value: 5 },
  ];

  const [pay, setPay] = React.useState({
    my_note: "",
    priority: "",
    deadline: "",
  });
  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };
  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const handleNote = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/write-my-note`,
        {
          my_note: pay.my_note,
          priority: priorityCom,
          deadline: deadlineCom,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: "Note successfully added",
      });
      setPay({ ...pay, my_note: "", priority: "", loader: false });
      setEdit({ ...edit, loader: false });
      getTotalNote();
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const getTotalNote = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/count-my-note`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalNote();
  }, []);

  return (
    <div style={{ margin: "5px" }}>
      <CommonCard title="My Note / To Do List">
        <>
          <TextField
            onChange={handleChange}
            value={pay.my_note}
            id="standard-textarea"
            label="Type here... (max 432 ch.)"
            placeholder="Write here your own confidential note or to do list..."
            multiline
            rows={4}
            inputProps={{ maxLength: 432 }}
            name="my_note"
            required
            style={{ marginBottom: 15, width: 100 + "%" }}
          />

          {/* <Dropdown width="100%" name="priority" clearable options={options} selection /> */}
          <CustomAutoSelect
            required
            label="Priority"
            width="100%"
            data={["Note", "Low", "Medium", "High"]}
            onChange={(e, value) => setPriority(value)}
          />
          <CustomDateTime
            label="Deadline"
            width="100%"
            onChange={(e) => setDeadline(e.target.value)}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
              marginBottom: 20,
            }}
          >
            <Button
              onClick={() => history.push("/linguist/my-note-or-to-do-list")}
              color="purple"
            >
              View Note ({totalnote})
            </Button>

            <Button
              disabled={!pay.my_note || !priorityCom}
              onClick={handleNote}
              color="purple"
              variant="outlined"
              startIcon={<SaveIcon />}
            >
              Save Note
              {edit.loader && <div class="ui active inline loader"></div>}
            </Button>
          </div>
        </>
      </CommonCard>
    </div>
  );
};

export default MyNotes;
