const { CHANGE_QRT_INPUT } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    data: {
        select_client: '',
        purchase_order_number: '',
        quote_rqst_for: [],
        house_street_no_name: '',
        address_line_two: '',
        town_city: '',
        post_code: '',
        county_region: '',
        country: '',
        other_details: '',
        request_details: '',
        project_fix_budget: '',
        documents: '',
        source_language: '',
        target_language: '',
        source_skills_required: '',
        target_skills_required: '',
        provider_id_number: '',
        gender: '',
        quote_by: '',
        pay_type: '',
        agree: ''
    }
};

const qrtReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case CHANGE_QRT_INPUT:
            return {
                ...state,
                data: {
                  ...state.data,
                  [payload.name]: payload.value
                }
            }
        
        default:
            return state
    }
}

export default qrtReducer