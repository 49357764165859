import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";
import { Button } from "semantic-ui-react";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "50%",

    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media(max-width:1900px)"]: {
      width: "100%",
      display: "block",
    },
  },
  button: {
    ["@media(max-width:1024px)"]: {
      width: "100%",
      marginBottom: "5px !important",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearhInput = ({
  search,
  handleSearch,
  load,
  setSearch,
  setShow,
  show,
}) => {
  const classes = useStyles();
  const handleChange = (value) => {
    setSearch(value);
    if (value == "" || value == undefined) {
      load();
    }
  };
  const handleShow = () => {
    load();
    setShow(!show);
  };
  return (
    <div>
      <Paper
        onClick={(e) => e.preventDefault()}
        component="form"
        className={classes.root}
      >
        <InputBase
          className={classes.input}
          placeholder="Enter Assignment ID"
          inputProps={{ "aria-label": "search google maps" }}
          onChange={(e) => handleChange(e.target.value)}
          value={search}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>

        <Button
          className={classes.button}
          onClick={handleSearch}
          color="purple"
        >
          find an assignment{" "}
        </Button>
        <Button className={classes.button} onClick={handleShow} color="purple">
          View all interpreting assignments
        </Button>
      </Paper>
    </div>
  );
};

export default SearhInput;
