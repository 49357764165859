// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ProjectReport from "components/Admin/TimesheetProjectReport/AmendProjectReport/AmendProjectReport";

import history from "utils/history";
//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import LinguistLayout from "layout/LinguistLayout";

const AmendProjectReportPage = () => {
  const classes = useDashboardStyles();
  return (
    <Linguist>
      <LinguistLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Amend Project report</p>
                <i
                  className="icon list arrow left"
                  onClick={() => {
                    history.goBack();
                  }}
                ></i>
              </CardHeader>
              <CardBody>
                <ProjectReport />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LinguistLayout>
    </Linguist>
  );
};

export default AmendProjectReportPage;
