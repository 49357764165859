import React, {useState} from 'react'
import { Button, Modal } from "@material-ui/core"

// Style imports
import {useStylesProfileImage, getModalStyle} from '../../layouts/styleP'
import './ProfileModal.css'

import Axios from 'utils/axios'
import swal from 'sweetalert'
import Cookies from 'js-cookie'
import { Dimmer, Loader } from 'semantic-ui-react'

const ProfileModal = ({ openProfileUpdate, setOpenProfileUpdate }) => {
    const classes_profileImage= useStylesProfileImage()
    const [modalStyle]= useState(getModalStyle)
    const token = Cookies.get('LINtoken')
    const [loader, setLoader] = useState(false)

    const handleProfileUpload = e => {
        document.querySelector('#file').click()
    }

    const handleChange = async(e) => {
        setLoader(true)
        if (e.target.files.length) {

            let fd = new FormData()
      
            fd.append('public_profile_photo', e.target.files[0])
      
            try {
              const response = await Axios.put('/api/linguists/profile/update', fd, {
                headers: {
                  Authorization: `Bearer ${token}`,
                }
              })
              swal({
                icon: 'success',
                text: 'Thank you for updating profile',
                buttons: false
              }).then(function () {
                window.location = "/linguist/manage-public-profile";
              });
              setLoader(false)
            } catch (error) {
              console.log(error.response)
              if (error.response?.data) {
                swal({
                  icon: 'error',
                  text: error?.response?.data?.error,
                  buttons: false,
                  dangerMode: true
                })
              }
              setLoader(false)
            }
          }
        
    }

    if (loader) {
        <Dimmer active inverted>
          <Loader size='large'>Loading</Loader>
        </Dimmer>
    }

    return (
        <Modal
            open={openProfileUpdate}
            onClose= {() => setOpenProfileUpdate(false)}
        >
            <div style={modalStyle} className={classes_profileImage.paper}>
                <div className= 'setting_modal'>
                    <div className= 'profileUpdate_heading'>
                        <h3 className= 'profileUpdate_heading_'>Change Profile Photo</h3>
                    </div>

                    <div className= 'profileUpdate_buttons'>
                        <input onChange= {(e) => handleChange(e)} type="file" id="file" style={{display: "none"}} accept=".jpeg, .png"/>
                        <Button type= 'file' style= {{fontSize: 'medium',textTransform: 'capitalize',fontWeight: '500', color: '#0095f6', letterSpacing: 'normal', borderTop: '1px solid #dbdbdb'}}onClick= {(e) => handleProfileUpload(e)}>Upload Photo</Button>
                        <Button style= {{fontSize: 'medium',textTransform: 'capitalize',fontWeight: '400', letterSpacing: 'normal', borderTop: '1px solid #dbdbdb'}} onClick= {() => setOpenProfileUpdate(false)}>Cancel</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
        

export default ProfileModal
