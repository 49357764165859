import React, { useState, useEffect } from "react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "axios";

import CAC from "components/Linguist/Translation/CAC/confirmAssinment";
import BAC from "components/Linguist/Translation/BAC/confirmAssignment";
import INC from "components/Linguist/Translation/INC/confirmAssignment";

const PrivateClient = () => {
  const classes = useDashboardStyles();
  const [cacAndBacAccess, setCacAndBacAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("LINtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCacAndBacAccess(response?.data);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <GridContainer>
      {cacAndBacAccess?.cacAndBacAccess ? (
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader>
              <p>Cash Account Client Translation Assignment Offers</p>
            </CardHeader>
            <CAC />
          </Card>
          <Card>
            <CardHeader>
              <p>Billing Account Client Translation Assignment Offers</p>
            </CardHeader>
            <BAC />
          </Card>
          <Card>
            <CardHeader>
              <p>In-house ⇩ Client Translation Assignment Offers </p>
            </CardHeader>
            <INC details={cacAndBacAccess} />
          </Card>
        </GridItem>
      ) : cacAndBacAccess?.cacAndBacAccess === false ? (
        <Header as="h3">Access denied</Header>
      ) : (
        <Dimmer active inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
      )}
    </GridContainer>
  );
};

export default PrivateClient;
