import React, { useState } from "react";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import GridContainer from "components/Grid/GridContainer";

const PersonalDetails = ({ id }) => {
  const classes = useDashboardStyles();
  return (
    <GridItem xs={12} sm={12} md={12} lg={12}>
      <Card>
        <CardHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          color="primary"
        >
          <p className={classes.cardTitleWhite}>Passport Document</p>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <object
                  data={`${process.env.REACT_APP_BACKEND_API}/manager-admin/view-Sppo-passport/sppoID/${id}`}
                  type="application/pdf"
                  width="100%"
                  height="1000px"
                ></object>
              </Card>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default PersonalDetails;
