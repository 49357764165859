import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import List from "components/Admin/BookingPanel/HelpContact/LIN.jsx";
import { Box } from "@material-ui/core";
import { Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Options from "components/Admin/BookingPanel/HelpContact/Options";

const Archive = () => {
  const classes = useDashboardStyles();
  const [booking_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAdminAccess(response?.data.booking_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <Manager>
      <MasterLayout>
        <GridContainer>
          {booking_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className={classes.cardTitleWhite}>
                    Help Contact (Linguist)
                  </p>
                </CardHeader>
              </Card>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Options />
                </CardHeader>
                <CardBody>
                  <Box pt={2}>
                    <Card>
                      <CardHeader color="primary">
                        <p className={classes.cardTitleWhite}>
                          Answer a question
                        </p>
                      </CardHeader>
                      <CardBody>
                        <List />
                      </CardBody>
                    </Card>
                  </Box>
                </CardBody>
              </Card>
            </GridItem>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </MasterLayout>
    </Manager>
  );
};

export default Archive;
