import CustomTextInput from "components/Common/CustomTextInput";
import React, { useRef, useState } from "react";
import { Button, Table } from "semantic-ui-react";
import { Card, Icon, Image, Header, Label, Checkbox } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";

import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import history from "utils/history";

const Details = () => {
  let sigPad = useRef({});
  const [signature, setSignature] = useState(false);
  const [sign, setSign] = useState("");

  const handleClose = (e) => {
    e.preventDefault();
    setSignature(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(sigPad.current.fromDataURL(temp));

    setSignature(false);
  };

  return (
    <div>
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>
      <CustomTextInput label="Assignment reference number" value="1234" />
      <CustomTextInput
        label="Translation / Training /Other Project name"
        value="abc"
      />

      {/* Table */}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Actual</Table.HeaderCell>
            <Table.HeaderCell>Requested</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Project / assignment confirmation date: </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project start date: </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project completion date: </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project background and request details</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Security clearance/s</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Wordcount</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Visual formatting</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Certification</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Post certification </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Card.Group itemsPerRow={3}>
        <Card color="red">
          <Image src="https://image.freepik.com/free-photo/elegance-style-masculinity-concept-attractive-trendy-looking-young-brunette-man-with-bristle-happy-smile-expressing-confidence-posing-isolated-yellow-wall-turning-head-round_343059-4597.jpg" />
          <Card.Content>
            <Card.Header>Project Lead</Card.Header>
          </Card.Content>
        </Card>
        <Card color="orange">
          <Image src="https://image.freepik.com/free-photo/elegance-style-masculinity-concept-attractive-trendy-looking-young-brunette-man-with-bristle-happy-smile-expressing-confidence-posing-isolated-yellow-wall-turning-head-round_343059-4597.jpg" />
          <Card.Content>
            <Card.Header>Project supervisor/ Coordinator</Card.Header>
          </Card.Content>
        </Card>
        <Card color="yellow">
          <Image src="https://image.freepik.com/free-photo/elegance-style-masculinity-concept-attractive-trendy-looking-young-brunette-man-with-bristle-happy-smile-expressing-confidence-posing-isolated-yellow-wall-turning-head-round_343059-4597.jpg" />
          <Card.Content>
            <Card.Header>Proofreader</Card.Header>
          </Card.Content>
        </Card>
        <Card color="violet">
          <Image src="https://image.freepik.com/free-photo/cheerful-businessman-conference-centre_107420-67801.jpg" />
          <Card.Content>
            <Card.Header>Team members</Card.Header>
            <Card.Description>Position - 1</Card.Description>
          </Card.Content>
        </Card>
        <Card color="purple">
          <Image src="https://img.freepik.com/free-photo/portrait-laughing-young-asian-businessman-city-crossed-arms-looking-away_34048-1296.jpg?size=626&ext=jpg&ga=GA1.2.849485224.1610954406" />
          <Card.Content>
            <Card.Header>Team members</Card.Header>
            <Card.Description>Position - 5</Card.Description>
          </Card.Content>
        </Card>
        <Card color="pink">
          <Image src="https://image.freepik.com/free-photo/handsome-business-man-working-computer-office_1303-22672.jpg" />
          <Card.Content>
            <Card.Header>Team members</Card.Header>
            <Card.Description>Position - 6</Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>

      <Header as="h4">Project Report</Header>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
            <Table.HeaderCell>
              To be verified by project supervisor / quality control (if
              applicable){" "}
            </Table.HeaderCell>
            <Table.HeaderCell>To be completed by client</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Checkbox label="I declare the information given by me is true/accurate and complete." />
            </Table.Cell>
            <Table.Cell>
              <Checkbox label="I certify that the project was supervised / quality control by me" />
            </Table.Cell>
            <Table.Cell>
              <Checkbox label="I declare that I have checked the information on this form and certify that it is true and complete" />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Signature{" "}
              {history.location.pathname.includes("linguist") ? (
                <IconButton onClick={() => setSignature(true)}>
                  <EditIcon />
                </IconButton>
              ) : null}
            </Table.Cell>
            <Table.Cell>
              Signature{" "}
              {history.location.pathname.includes("admin") ? (
                <IconButton onClick={() => setSignature(true)}>
                  <EditIcon />
                </IconButton>
              ) : null}
            </Table.Cell>
            <Table.Cell>
              Signature{" "}
              {history.location.pathname.includes("cash-client") ? (
                <IconButton onClick={() => setSignature(true)}>
                  <EditIcon />
                </IconButton>
              ) : null}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Full name: </Table.Cell>
            <Table.Cell>Full name: </Table.Cell>
            <Table.Cell>Full name: </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: </Table.Cell>
            <Table.Cell>Date: </Table.Cell>
            <Table.Cell>Date: </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Feedback</Table.Cell>
            <Table.Cell>Remarks: </Table.Cell>
            <Table.Cell>Feedback</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button style={{ backgroundColor: "purple", color: "white" }}>
        Upload
      </Button>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button style={{ backgroundColor: "purple", color: "white" }}>
          View PDF
        </Button>
        <Button style={{ backgroundColor: "purple", color: "white" }}>
          Download PDF
        </Button>
        <Button style={{ backgroundColor: "purple", color: "white" }}>
          Print PDF
        </Button>
      </div>
    </div>
  );
};

export default Details;
