import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import CashClientLayout from "layout/CashClientLayout";
import ViewAttach from "components/Admin/Communication/ViewAttachConversation";


const ViewContactCard = () => {
  return (
    <Cashclient>
      <CashClientLayout>

        <ViewAttach />

      </CashClientLayout>
    </Cashclient>
  );
};

export default ViewContactCard;
