import styled from "styled-components";

export const NotificationActionDiv = styled.div`
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0;
    padding-left: 15px;
  }
`;

export const NotificationDiv = styled.div`
  position: relative;
  .MuiList-root {
    padding: 0;
    position: absolute;
    right: 0;
    top: 30px;
  }
`;
