import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material Core Components
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

// Material Icons
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";

// Others
import { updateRate } from "redux/actions/admin/controller";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "150px",
    margin: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
  valueDiv: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
  inputDiv: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    alignItems: "center",
    "& .MuiIconButton-root": {
      padding: "0",
      height: "24px",
    },
  },
}));

const RateInput = ({ type, value, item, name, readOnly }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [rate, setRate] = useState("");
  const [isEditClicked, setIsEditClicked] = useState(false);

  const { loading } = useSelector((state) => state.adminControlData);

  const handleChange = (event) => {
    if (isNaN(Number(event.target.value))) {
      return;
    }
    setRate(event.target.value);
  };

  const handleEdit = () => {
    setIsEditClicked(!isEditClicked);
  };

  const handleDone = async () => {
    // setIsEditClicked(!isEditClicked);
    if (!rate) {
      setIsEditClicked(!isEditClicked);

      return;
    }
    const newItem = { ...item };
    newItem[name] = Number(rate);

    await dispatch(updateRate(newItem));
    setIsEditClicked(!isEditClicked);
  };

  return (
    <div className={classes.root}>
      {value && !isEditClicked ? (
        <div className={classes.valueDiv}>
          <p>{value}</p>
          {!readOnly ? (
            <IconButton color="primary" aria-label="edit" onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          ) : null}
        </div>
      ) : (
        <div className={classes.inputDiv}>
          {value && !readOnly ? (
            <IconButton color="primary" aria-label="edit" onClick={handleDone}>
              {loading ? <CircularProgress /> : <DoneIcon />}
            </IconButton>
          ) : null}
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-rate">Rate</InputLabel>
            <OutlinedInput
              id="outlined-adornment-rate"
              value={rate}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  {type === "percent" ? "%" : "£"}
                </InputAdornment>
              }
              labelWidth={60}
            />
          </FormControl>
        </div>
      )}
    </div>
  );
};
export default RateInput;
