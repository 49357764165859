import React from "react";

// Material Core Components
import Typography from "@material-ui/core/Typography";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormOne from "./FormOne";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";

const RegistrationForm = () => {
  const classes = useEmployeeStyles();

  return (
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  {/* <Typography component="h1" variant="h5">
                    Pre Registration Form
                  </Typography> */}
                  <Typography component="h1" variant="h5">Send Account Client Registration Form</Typography>
                </div>
                <FormOne />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
  );
};

export default RegistrationForm;
