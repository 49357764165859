import CustomInput from "components/Common/CustomInput";
import { useSelector, useDispatch } from "react-redux";

import { percentChange } from "redux/actions/admin/invoice";

const Total = ({ state }) => {
  const dispatch = useDispatch();

  const { expenses, description, percents } = useSelector(
    (state) => state.invoiceReducers
  );
  const { rates, selectedCurrency, setCurrencyValue } = useSelector(
    (state) => state.getDataReducer
  );

  const convertedRates = rates ? rates[selectedCurrency] : 1;

  const price = setCurrencyValue;
  const priceText = selectedCurrency;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (isNaN(Number(value))) {
      return;
    }
    dispatch(
      percentChange({
        name,
        value,
      })
    );
  };

  const expenSubTotal = expenses.reduce(
    (acc, item) => acc + Number(item.quantity) * Number(item.rate),
    0
  );

  const descSubTotal = description.reduce(
    (acc, item) => acc + Number(item.quantity) * Number(item.rate),
    0
  );

  const serviceValue = Number(percents.service) / 100;
  const discountValue = Number(percents.discount) / 100;
  const vatValue = Number(percents.vat) / 100;

  const total = expenSubTotal + descSubTotal;

  const serviceCharge = isNaN(total)
    ? "0.00"
    : (total * serviceValue).toFixed(2);

  const vat = isNaN(total) ? "0.00" : (total * vatValue).toFixed(2);

  const discnt = isNaN(total) ? "0.00" : (total * discountValue).toFixed(2);

  const fullTotal =
    Number(total) + Number(serviceCharge) + Number(vat) - Number(discnt);

  const depositedPaid = 350;

  const toBePaid = fullTotal - depositedPaid;

  return (
    <>
      <tr>
        <td colSpan="2"></td>
        <td>
          <p>
            <strong>Billing Account Service Charge%</strong>
          </p>
        </td>
        <td>
          <p>10%</p>
        </td>
        <td>
          <p className="fw-bold">
            <span>
              {price}
              {(+serviceCharge * convertedRates).toFixed(2)}
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td colSpan="2"></td>
        <td>
          <p>
            <strong>Discount%</strong>
          </p>
        </td>
        <td>
          <CustomInput
            width="100%"
            placeholder="0"
            value={percents.discount}
            name="discount"
            onChange={handleChange}
          />
        </td>
        <td>
          <p className="fw-bold">
            <span>
              {price}
              {(+discnt * convertedRates).toFixed(2)}
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td colSpan="2"></td>
        <td>
          <p>
            <strong>VAT %</strong>
          </p>
        </td>
        <td>
          <CustomInput
            width="100%"
            placeholder="20"
            value={percents.vat}
            name="vat"
            onChange={handleChange}
          />
        </td>
        <td>
          <p className="fw-bold">
            <span>
              {price}
              {(+vat * convertedRates).toFixed(2)}
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td colSpan="2"></td>
        <td></td>
        <td>
          <p>
            <strong>Total ({priceText}) </strong>
          </p>
        </td>
        <td>
          <p className="fw-bold">
            <span className="pr-10"></span>{" "}
            <span className="">
              {price}{" "}
              {isNaN(fullTotal)
                ? "0.00"
                : (fullTotal * convertedRates).toFixed(2)}{" "}
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td colSpan="2"></td>
        <td></td>
        <td>
          <p>
            <strong>Deposite paid</strong>
          </p>
        </td>
        <td>
          <p className="fw-bold">
            <span className="pr-10"></span>{" "}
            <span className="">
              {price}
              {(depositedPaid * convertedRates).toFixed(2)}
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td colSpan="2"></td>
        <td></td>
        <td>
          <p>
            <strong>Total to be paid including VAT</strong>
          </p>
        </td>
        <td>
          <p className="fw-bold">
            <span className="pr-10"></span>{" "}
            <span className="">
              {price}
              {+toBePaid ? (toBePaid * convertedRates).toFixed(2) : "0.00"}
            </span>
          </p>
        </td>
      </tr>
    </>
  );
};

export default Total;
