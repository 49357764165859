import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import ViewEmployeeList from "components/Admin/Employee/ViewEmployeeList";
// import SearchEmployee from "components/Admin/Employee/SearchEmployee";
import EmpOptions from "components/Admin/HrPanel/EmpOptions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};
const useStyles = makeStyles(styles);
const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const headerclasses = useStyles();
  const [hr_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminAccess(response?.data.hr_panel);
  };
  
  useEffect(() => {
    getLinguistDetails();
  },[]);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {hr_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>Active Employees</p>
                  <div className={headerclasses.root}>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        window.open("/employee/initial-registration")
                      }
                    >
                      Registration Form
                    </Button>
                    <IconButton color="inherit" onClick={history.goBack}>
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                </CardHeader>

                <CardBody>
                  <EmpOptions />
                </CardBody>
                <CardBody>
                  <ViewEmployeeList />
                </CardBody>
              </Card>
            </GridItem>
          ) : hr_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading...</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
