import { useCallback, useState } from "react";
import { Search, Label } from "semantic-ui-react";
import _ from "lodash";
import { Link } from "react-router-dom";

import ROUTES from "data/publicRoutes";

import { RouteSearchDiv } from "./style";

const resultRenderer = ({ text, path }) => {
  return (
    <div>
      <Link to={`/${path}`}>{text}</Link>
    </div>
  );
};

const RouteSearch = () => {
  const [results, setResults] = useState([]);

  const handleSearchChange = useCallback((e, data) => {
    const re = new RegExp(_.escapeRegExp(data.value), "i");
    const isMatch = (result) => {
      return re.test(result.subText);
    };

    let matchedData = _.filter(ROUTES, isMatch);

    matchedData = matchedData.map((dt) => {
      if (dt.subText) {
        const foundIndex = dt.subText.toLowerCase().indexOf(data.value);
        let matchedSubStr = dt.subText.substr(foundIndex, foundIndex + 5);
        dt.text = `${matchedSubStr}...`;
      }
      return dt;
    });

    setResults(matchedData);
  }, []);

  return (
    <RouteSearchDiv>
      {" "}
      <Search
        input={{ icon: "search", iconPosition: "right" }}
        size="large"
        placeholder="Search here.."
        results={results}
        resultRenderer={resultRenderer}
        onSearchChange={handleSearchChange}
      />
    </RouteSearchDiv>
  );
};

export default RouteSearch;
