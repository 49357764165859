import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import TextField from "@material-ui/core/TextField";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import {
  CURRENT_COUNTRY,
  PRIMARY_SOURCE_LANG,
  PRIMARY_TARGET_LANG,
  EMAIL_REGEX,
  PASS_REGEX,
} from "data";
import CustomDate from "components/Common/CustomDate";
import CustomPassword from "components/Common/CustomPassword";
import { changeInitRegInput } from "redux/actions/irAction";
import { Checkbox } from "@material-ui/core";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import axios from "axios";
import swal from "sweetalert";
import CustomPhone from "components/Common/CustomPhone";
import CustomSelect from "components/Common/CustomSelect";
import CustomCaptcha from "components/Common/CustomCaptcha";

import history from "utils/history";
import CustomEmail from "components/Common/CustomEmail";

const PageOne = () => {
  const { data } = useSelector((state) => state.irReducer);
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState("");
  const [notValid, setNotValid] = useState(false);
  const [notRegex, setNotRegex] = useState(false);
  const [loading, setLoading] = useState(false);

  const FORM_TYPE = [
    "Professional Linguist",
    "Multilingual / Bilingual Professional",
    "Trainer / Lecturer",
    "Expert Contractor",
    "Other (details below)",
  ];

  const {
    title,
    FirstName,
    LastName,
    mobile_no,
    AddressLine1,
    AddressLine2,
    City,
    PostalCode,
    Region,
    Country,
    Nationality,
    service,
    other_service,
    Birthday,
    email,
    password,
    confirm_password,
    target_language,
    source_language,
    gdpr,
  } = data;

  const classes = useFormStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInitRegInput({
        name,
        value: value,
      })
    );
  };

  const handleChecked = (e) => {
    const { name, checked } = e.target;
    dispatch(
      changeInitRegInput({
        name,
        value: checked,
      })
    );
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInitRegInput({
        name,
        value: value,
      })
    );
    if (value !== "" && PASS_REGEX.test(value)) {
      setNotRegex(false);
    } else {
      setNotRegex(true);
    }
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInitRegInput({
        name,
        value: value,
      })
    );
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleDateChange = (date) => {
    let dateConst = 0;
    dispatch(
      changeInitRegInput({
        name: "Birthday",
        value: date,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "Country",
        value,
      })
    );
  };

  const handleChangeSO = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "source_language",
        value,
      })
    );
  };

  const handleChangeTA = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "target_language",
        value,
      })
    );
  };
  const handleChangeNA = (e, value) => {
    dispatch(
      changeInitRegInput({
        name: "Nationality",
        value: value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeInitRegInput({
        name: "mobile_no",
        value: value,
      })
    );
  };

  const handleCaptcha = async (value) => {
    //const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/verify-captcha`, {captcha: value})

    setCaptcha(value);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/pre-signup`,
        data
      );

      swal({
        title: "Success",
        text: `Email has been sent to activate your account`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/";
      });
      setLoading(false);
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
      setLoading(false);
    }
  };

  const isAllow =
    !title ||
    !FirstName ||
    !LastName ||
    !mobile_no ||
    !AddressLine1 ||
    !City ||
    !PostalCode ||
    !Region ||
    !Country ||
    !Birthday ||
    !email ||
    !password ||
    !confirm_password ||
    !gdpr ||
    !Nationality ||
    !target_language ||
    !source_language ||
    !service ||
    (service.includes("Other") && !other_service) ||
    !captcha ||
    notRegex ||
    notValid ||
    password !== confirm_password;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Title"
          name="title"
          value={title}
          required
          placeholder="( e.g. Mr. / Mrs / Miss etc. )"
        />

        <CustomInputSingle
          onChange={handleChange}
          label="First Name (s)"
          width="100%"
          name="FirstName"
          value={FirstName}
          required
          placeholder="First Name(s)/Forename/Given Names as shown on passport or birth certificate"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Last Name"
          width="100%"
          name="LastName"
          value={LastName}
          placeholder="Last Name/Surname/Family Name as shown on passport or marriage certificate"
          required
        />
        <br />
        <CustomPhone
          label="Mobile Number *"
          onChange={handleMobile}
          placeholder="Mobile Number* (Please select country then type number e.g. +44 7307 000000)"
          value={mobile_no}
          name="mobile_no"
          required
        />
        <CustomInputSingle
          onChange={handleChange}
          label="House Number/Name, Street Name"
          width="100%"
          name="AddressLine1"
          value={AddressLine1}
          placeholder="e.g. Flat/Building Name, 101 Example Street"
          required
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Address line 2"
          width="100%"
          name="AddressLine2"
          value={AddressLine2}
          placeholder="Additional Address Information"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Town / City"
          width="100%"
          name="City"
          value={City}
          placeholder="Your Village, Town or City Name"
          required
        />

        <CustomInputSingle
          onChange={handleChange}
          label="County / Region"
          width="100%"
          name="Region"
          value={Region}
          placeholder="Your County, Division or State Name"
          required
        />

        <CustomInputSingle
          onChange={handleChange}
          label="Post / Zip Code"
          width="100%"
          name="PostalCode"
          value={PostalCode}
          placeholder="Your Post Code, Zip Code or Post Office Name"
          required
        />

        <CustomAutoSelect
          onChange={handleAutoCompleteCO}
          label="Country"
          moreInfo="Current country of residence"
          width="100%"
          name="Country"
          value={Country}
          data={CURRENT_COUNTRY}
          required
        />
        <CustomAutoSelect
          onChange={handleChangeNA}
          label="Current nationality"
          width="100%"
          name="Nationality"
          value={Nationality}
          data={CURRENT_COUNTRY}
          required
        />
        <CustomDate
          onChange={handleDateChange}
          width="100%"
          label="Date of birth"
          name="Birthday"
          value={Birthday}
          maxDate={new Date("1-1-2000")}
          required
        />
        <CustomEmail
          onChange={handleEmailChange}
          label="Primary Email"
          moreInfo="This email address will be shared with payment processor"
          width="100%"
          name="email"
          value={email}
          type="email"
          placeholder="e.g. example@example.com"
          required
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <CustomPassword
          onChange={handlePasswordChange}
          label="Password"
          width="100%"
          name="password"
          value={password}
          placeholder="e.g. Ex@mPle012345"
          notRegex={notRegex}
          required
        />
        <CustomPassword
          onChange={handleChange}
          label="Confirm password"
          width="100%"
          name="confirm_password"
          value={confirm_password}
          validation={password !== confirm_password}
          required
        />

        <CustomAutoSelect
          onChange={handleChangeSO}
          value={source_language}
          name="source_language"
          label="Source Language / Mother Tongue"
          data={PRIMARY_SOURCE_LANG.filter(
            (value) => value !== target_language
          )}
          width="100%"
          required
        />
        <CustomAutoSelect
          onChange={handleChangeTA}
          value={target_language}
          name="target_language"
          label="Target Language"
          data={PRIMARY_TARGET_LANG.filter(
            (value) => value !== source_language
          )}
          width="100%"
          required
        />

        <CustomSelect
          onChange={handleChange}
          label="I would like to register as"
          data={FORM_TYPE}
          width="100%"
          name="service"
          value={service}
          required
        />
        <br />
        {service === "Other (details below)" ? (
          <TextField
            onChange={handleChange}
            value={other_service}
            id="standard-textarea"
            label="Other details...(max 160 ch.)"
            placeholder="Type here...(e.g. Interpreter, Bilingual Childminder, Multilingual Solicitor, English Teacher etc.-max 160 ch.)"
            multiline
            rows={2}
            inputProps={{ maxLength: 160 }}
            name="other_service"
            // required
            style={{ marginBottom: 15, width: 100 + "%" }}
          />
        ) : null}

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <p>
            <Checkbox
              onChange={handleChecked}
              checked={gdpr}
              name="gdpr"
              color="primary"
            />
            By submitting this form I agree with LC Service Agreement,
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/privacy-policy"
            >
              {" "}
              Privacy Policy{" "}
            </a>
            and{" "}
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/terms-and-conditions"
            >
              {" "}
              Terms and Conditions
            </a>{" "}
          </p>
        </div>

        <CustomCaptcha onChange={handleCaptcha} />

        <div className={classes.buttonFlex}>
          <CustomButton
            onClick={handleSubmit}
            disabled={isAllow || loading}
            text="Submit"
          />
        </div>
      </form>
    </div>
  );
};

export default PageOne;
