import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import { DropzoneArea } from "material-ui-dropzone";
import CustomRadio from "components/Common/CustomRadio";
import CustomBigInput from "components/Common/CustomBigInput";
import { CURRENT_COUNTRY, GENDER_DATA, EMAIL_REGEX } from "data";
import { changeInput, updateProfileLinguist } from "redux/actions/lcAction";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import { changeCrInput } from "redux/actions/crAction";
import Cookies from "js-cookie";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import swal from "sweetalert";
import { Button, Header, Progress } from "semantic-ui-react";
import Webcam from "react-webcam";
import CustomPhone from "components/Common/CustomPhone";
import CustomEmail from "components/Common/CustomEmail";
import history from "utils/history";

const PageOne = ({ setReload }) => {
  const { data } = useSelector((state) => state.lcReducer);
  // const { data } = useSelector((state) => state.crReducer);
  const dispatch = useDispatch();
  const token = Cookies.get("LINtoken");
  const [take, setTake] = useState("");
  const [notValid, setNotValid] = useState(false);
  const [next, setNext] = useState(false);
  const fileInput = useRef(null);
  const [progress, setProgress] = useState(0);
  const [proofProgress, setProofProgress] = useState(0);

  const fileInput2 = useRef(null);
  const [profilePhoto, setProfilePhoto] = useState({
    preview: "",
    raw: "",
  });
  const [saveLoader, setSaveLoader] = useState(false);
  const webcamRef = React.useRef(null);
  const { addToast } = useToasts();
  const videoConstraints = {
    width: 270,
    height: 400,
    facingMode: "user",
  };

  const getLinguistDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/get-details-page1`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        updateProfileLinguist({
          address_id: response?.data._id,
          title: response?.data.title,
          AddressLine1: response?.data.AddressLine1,
          AddressLine2: response?.data.AddressLine2,
          PostalCode: response?.data.PostalCode,
          Country: response?.data.Country,
          Nationality: response?.data.Nationality,
          Birthday: response?.data.Birthday,
          FirstName: response?.data.FirstName,
          LastName: response?.data.LastName,
          City: response?.data.City,
          Region: response?.data.Region,
          email: response?.data.email,
          other_nationality: response?.data.other_nationality,
          sex: response?.data.sex,
          mobile_no: response?.data.mobile_no,
          other_email: response?.data.other_email,
          business_phone_no: response?.data.business_phone_no,
          online_calling_details: response?.data.online_calling_details,
          reference_work: response?.data.reference_work,
          reference_education: response?.data.reference_education,
          proof_address_photo: response?.data.proof_address_photo,
          photo: response?.data.photo,
          photo_preview: response?.data.photo_preview,
        })
      );
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  const [viewAll, setViewAll] = useState(null);
  const documentList = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-proof-of-address-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewAll(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  useEffect(() => {
    getLinguistDetails();
    documentList();
  }, []);

  const {
    photo_preview,
    photo,
    address_id,
    title,
    FirstName,
    LastName,
    AddressLine1,
    AddressLine2,
    City,
    PostalCode,
    Region,
    Country,
    Nationality,
    other_nationality,
    Birthday,
    email,
    sex,
    mobile_no,
    other_email,
    business_phone_no,
    online_calling_details,
    profile_photo,
    proof_address_photo,
    reference_work,
    reference_education,
  } = data;

  const classes = useFormStyles();

  const handleSave = async (e) => {
    e.preventDefault();
    setSaveLoader(true);
    let fd = new FormData();

    // if (!photo?.contentType) {
    //   fd.append("photo", data.photo);
    // }
    fd.append("title", data.title || "");
    fd.append("email", data.email || "");
    fd.append("sex", data.sex || "");
    fd.append("mobile_no", data.mobile_no || "");
    fd.append("other_email", data.other_email || "");
    fd.append("other_nationality", data.other_nationality || "");
    fd.append("online_calling_details", data.online_calling_details || "");
    fd.append("business_phone_no", data.business_phone_no || "");
    fd.append("reference_work", data.reference_work || "");
    fd.append("reference_education", data.reference_education || "");
    // if (data.proof_address_photo?.name) {
    //   fd.append("proof_address_photo", proof_address_photo);
    // }
    // if (!proof_address_photo?.contentType) {
    //   formData.append("proof_address_photo", proof_address_photo[0] || "");
    // }

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-full-registration`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((val) => {
        setReload(Math.random());
      });
      setSaveLoader(false);
      setNext(true);
    } catch (error) {
      console.log(error.response);
      setSaveLoader(false);
    }
  };

  const handleNext = () => {
    history.push("/linguist/full-registration-p2");
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleProofClick = (e) => {
    e.preventDefault();
    fileInput2.current.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInput({
        name,
        value,
      })
    );
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInput({
        name,
        value,
      })
    );
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleChangeN = (e, value) => {
    dispatch(
      changeInput({
        name: "Nationality",
        value,
      })
    );
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeInput({
        name: "other_nationality",
        value,
      })
    );
  };

  const handleFiles = async (e) => {
    if (e.target?.files[0]?.size > 1000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 1mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      dispatch(
        changeInput({
          name: "photo",
          value: e.target.files[0],
        })
      );
      dispatch(
        changeInput({
          name: "photo_preview",
          value: URL.createObjectURL(e.target.files[0]),
        })
      );
      uploadPhoto(e.target?.files[0]);
    }
  };

  const uploadPhoto = async (file) => {
    let fd = new FormData();

    if (!file?.contentType) {
      fd.append("photo", file);
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-profile-photo`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Photo could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const uploadProof = async (file) => {
    let fd = new FormData();
    if (!file?.contentType) {
      fd.append("proof_address_photo", file || "");
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-proof-photo`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProofProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      documentList();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleProofFiles = async (e) => {
    if (e.target?.files[0]?.size > 3000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 3mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      dispatch(
        changeInput({
          name: e.target.name,
          value: e.target.files[0],
        })
      );
      uploadProof(e.target.files[0]);
    }
  };

  const handleFiles1 = (file) => {
    const inputData = {
      name: "proof_address_photo",
      value: file,
    };
    dispatch(changeCrInput(inputData));
  };

  const isAllow =
    !title ||
    !LastName ||
    !Nationality ||
    !email ||
    !mobile_no ||
    (!photo_preview && !photo) ||
    (notValid && other_email);

  const handleUpload = async (e) => {
    e.preventDefault();
    setTake(true);
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setTake(false);
    setProfilePhoto({ ...profilePhoto, preview: imageSrc });
    dispatch(
      changeInput({
        name: "profile_photo",
        value: imageSrc,
      })
    );
  }, [webcamRef]);

  const handlePhone = (value) => {
    dispatch(
      changeInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handleBusinessPhone = (value) => {
    dispatch(
      changeInput({
        name: "business_phone_no",
        value,
      })
    );
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <div className="ui centered card">
          <div className="image">
            {photo?.contentType ? (
              <img
                style={{ height: "100%" }}
                src={`${process.env.REACT_APP_BACKEND_API}/linguists/profile-photo/${address_id}`}
              />
            ) : (
              <img style={{ height: "100%" }} src={photo_preview} />
            )}
            {take ? (
              <>
                <Webcam
                  audio={false}
                  height={"100%"}
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  width={"100%"}
                  videoConstraints={videoConstraints}
                />
                <Button
                  onClick={capture}
                  style={{
                    backgroundColor: "purple",
                    color: "white",
                    width: "100%",
                  }}
                >
                  Capture photo
                </Button>
              </>
            ) : null}
            {progress > 0 && (
              <Progress percent={progress} indicating autoSuccess progress>
                {" "}
                Photo uploaded
              </Progress>
            )}
          </div>
          <div className="content">
            <a className="header">
              {FirstName} {LastName}
            </a>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "24px",
          }}
        >
          <input
            required
            accept="image/*"
            name="profile_photo"
            ref={fileInput}
            onChange={handleFiles}
            type="file"
            style={{ display: "none" }}
          />
          <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
            Please upload Your photo for Profile and ID Card, photo should be H:
            70mm x W: 50mm, Max 1MB *
          </Header>
          <div>
            <Button onClick={handleClick}>Upload</Button>
          </div>
          {photo?.name && (
            <div>
              <Header style={{ margin: "0" }} as="h5">
                {photo.name}
              </Header>
              <Header style={{ margin: "0" }} as="h5">{`${(
                photo.size / 1024
              ).toFixed(2)}kb`}</Header>
            </div>
          )}
        </div>

        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Title"
          name="title"
          value={title}
          required
        />

        <CustomInputSingle
          label="First Name (s)"
          width="100%"
          value={FirstName}
          className="form-control"
          required
          disabled
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Last Name"
          width="100%"
          name="LastName"
          value={LastName}
          required
        />
        <CustomInputSingle
          label="House number/name, Street name"
          width="100%"
          name="AddressLine1"
          value={AddressLine1}
          required
          disabled
        />
        <CustomInputSingle
          label="Address line 2"
          width="100%"
          name="AddressLine2"
          value={AddressLine2}
          disabled
        />
        <CustomInputSingle
          label="Town / City"
          width="100%"
          name="City"
          value={City}
          required
          disabled
        />

        <CustomInputSingle
          label="County / Region"
          width="100%"
          name="Region"
          value={Region}
          required
          disabled
        />
        <CustomInputSingle
          label="Post / Zip Code"
          width="100%"
          name="PostalCode"
          value={PostalCode}
          required
          disabled
        />
        <CustomBigInput
          label="Country"
          moreInfo="Current Country of residence"
          width="100%"
          name="Country"
          value={Country}
          required
          disabled
        />

        <CustomAutoSelect
          onChange={handleChangeN}
          label="Current nationality"
          width="100%"
          name="Nationality"
          value={Nationality}
          data={CURRENT_COUNTRY}
          required
        />
        <CustomAutoComplete
          onChange={handleAutoComplete}
          label="Other nationalities"
          width="100%"
          name="other_nationality"
          data={CURRENT_COUNTRY}
          value={other_nationality}
          placeholder="Choose"
        />
        <CustomBigInput
          label="Date of birth"
          moreInfo="DD/MM/YYYY"
          width="100%"
          name="Birthday"
          value={Birthday}
          required
          disabled
        />
        <CustomEmail
          onChange={handleChange}
          label="Primary email"
          moreInfo="This email address will be shared with payment processor"
          width="100%"
          name="email"
          type="email"
          value={email}
          disabled
          required
        />
        <CustomRadio
          onChange={handleChange}
          label="Gender"
          width="100%"
          name="sex"
          value={sex}
          data={GENDER_DATA}
        />

        <CustomPhone
          label="Mobile number *"
          onChange={handlePhone}
          placeholder="Mobile number *"
          value={mobile_no}
          name="mobile_no"
          required
        />
        <CustomEmail
          onChange={handleEmailChange}
          label="Other email address"
          width="100%"
          name="other_email"
          value={other_email}
        />
        {other_email && notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <br />
        <CustomPhone
          label="Business phone number"
          onChange={handleBusinessPhone}
          placeholder="Business phone number"
          value={business_phone_no}
          name="business_phone_no"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Skype, Zoom or other online calling details"
          width="100%"
          name="online_calling_details"
          value={online_calling_details}
          height="50px"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "24px",
          }}
        >
          <input
            accept=".pdf,.doc"
            name="proof_address_photo"
            ref={fileInput2}
            onChange={handleProofFiles}
            type="file"
            style={{ display: "none" }}
          />
          <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
            Upload your proof of address here (utility bill, driving licence
            etc) <b style={{ color: "red" }}>***</b>
          </Header>

          {/* <DropzoneArea
                      acceptedFiles={[
                        ".jpg, .jpeg, .pdf",
                      ]}
                      onChange={handleFiles1}
                      showFileNames
                      dropzoneText="Drag & Drop file here or click (Max 1 files, 5MB, contact us for help)"
                      showAlerts={true}
                      clearOnUnmount
                      maxFileSize={5000000}
                      showPreviewsInDropzone
                      showFileNamesInPreview
                      filesLimit={1}
                    /> */}

          <div style={{ marginBottom: "10px" }}>
            <Button onClick={handleProofClick}>Upload</Button>
          </div>
          {proof_address_photo?.name && (
            <div>
              <Header style={{ margin: "0" }} as="h4">
                {proof_address_photo.name}
              </Header>
              <Header
                style={{ margin: "0" }}
                as="h5"
              >{`${proof_address_photo?.lastModifiedDate?.toDateString()}`}</Header>
              <Header style={{ margin: "0" }} as="h5">{`${(
                proof_address_photo.size / 1024
              ).toFixed(2)}kb`}</Header>
            </div>
          )}

          {viewAll?.proof_address_photo_true && (
            <a href={`/linguist/linguist-view-address/${viewAll?._id}`}>
              View document
            </a>
          )}
          {proofProgress > 0 && (
            <Progress percent={proofProgress} indicating autoSuccess progress>
              {" "}
              Document uploaded
            </Progress>
          )}
        </div>

        <div style={{ display: "flex", alignItems: "flex-end" }}></div>
        <CustomBigInput
          onChange={handleChange}
          label="Reference 1 work"
          moreInfo="Add your professional referee details below (full name, email, full address, phone number). If you have no referee please provide details of a friend"
          width="100%"
          name="reference_work"
          value={reference_work}
        />
        <CustomBigInput
          onChange={handleChange}
          label="Reference 2 education"
          moreInfo="Add your academic referee details 2 below (full name, email, full address, phone number)"
          width="100%"
          name="reference_education"
          value={reference_education}
        />

        <div className={classes.buttonFlex} style={{ marginTop: "24px" }}>
          {!next ? (
            <Button
              loading={saveLoader}
              color="purple"
              disabled={isAllow}
              onClick={handleSave}
            >
              Save
            </Button>
          ) : (
            <Button color="green" onClick={handleNext}>
              Next
            </Button>
          )}
          {/* <Button basic color="purple" onClick={handleSkip}>
            Skip
          </Button> */}
        </div>
      </form>
    </div>
  );
};

export default PageOne;
