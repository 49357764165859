import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import Cookies from 'js-cookie'
import { Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { showClientBills } from "redux/actions/billsAction";

const Action = ({id, type}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get('BACtoken')
  const dispatch = useDispatch()
  const {findBill} = useSelector(state => state.billsReducer)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = e => {
    history.push(`/account-client/view-invoice/${id}`)
  }

  const handleFind = e => {
    // dispatch(findClientBill(true))
    setAnchorEl(false)
  }

  const handleAllBills = e => {
    dispatch(showClientBills(true))
    setAnchorEl(false)
  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
       
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleView}>View Invoice</MenuItem>
       
      </Menu>
    </div>
  );
};

export default Action;
