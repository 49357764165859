import { v4 as uuidv4 } from "uuid";
import { Label, Menu, Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

// Custom Components
import CustomtableWIthStyle from "components/Common/CustomtableWIthStyle";

// Others
import { langRow, dateRow, addressRow } from "./functions";
import Actions from "./Actions";
const ViewAll = () => {
  const bookingList = [
    {
      _id: 1,
      id: 1,
      starttime: 1012,
      endtime: dateRow("24 September 2020 10:30 UTC +01:00"),
      total_hour: "Remote/Telephone",
      source_language: addressRow(
        "Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016"
      ),
      target_language: langRow("Bengali / Bangala (Sylheti)"),
      interpreting_service_required: "Junior Level 2",
      remote_address: "Male",
      linguist_skill_level: "NPPV L2(UK)",
      gender: "no",
      car_parking: "6 hours",
      security_clearance: "£650",
    },
    {
      _id: 2,
      id: 2,
      starttime: 1013,
      endtime: dateRow("18 August 2020 12:30 UTC +05:30"),
      total_hour: "FTF",
      source_language: addressRow(
        "Shop No 32, Wing C, Shyam Kamal, Agrawal Rd, Vile Parle(e),  Mumbai,  Maharashtra, 400057"
      ),
      target_language: langRow("Hindi / Marathi"),
      interpreting_service_required: "Co-operator Level 3",
      remote_address: "Male",
      linguist_skill_level: "DBS(UK)",
      gender: "yes",
      car_parking: "2 hours 30 mins",
      security_clearance: "£512",
    },
    {
      _id: 3,
      id: 3,
      starttime: 1014,
      endtime: dateRow("18 August 2020 12:30 UTC +05:30"),
      total_hour: "Remote/Telephone",
      source_language: addressRow(
        "Shop No 32, Wing C, Shyam Kamal, Agrawal Rd, Vile Parle(e),  Mumbai,  Maharashtra, 400057"
      ),
      target_language: langRow("Hindi / Marathi"),
      interpreting_service_required: "Associate Level 4",
      remote_address: "Female",
      linguist_skill_level: "CTC(UK)",
      gender: "no",
      car_parking: "2 hours 30 mins",
      security_clearance: "£512",
    },
  ];

  return (
    <div>
      <div style={{ width: "100%", height: "500px", overflowX: "scroll" }}>
        <Table color={"pink"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Assignment ID</Table.HeaderCell>
              <Table.HeaderCell>Start date & time</Table.HeaderCell>
              <Table.HeaderCell>Duration</Table.HeaderCell>
              <Table.HeaderCell>Source language</Table.HeaderCell>
              <Table.HeaderCell>Target language</Table.HeaderCell>
              <Table.HeaderCell>Service Required</Table.HeaderCell>
              <Table.HeaderCell>Venue/Remote</Table.HeaderCell>
              <Table.HeaderCell>Expertise Level</Table.HeaderCell>
              <Table.HeaderCell>Gender</Table.HeaderCell>
              <Table.HeaderCell>Parking</Table.HeaderCell>
              <Table.HeaderCell>Security Level</Table.HeaderCell>

              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {bookingList.map((list) => (
              <Table.Row key={list._id}>
                <Table.Cell>
                  <Link
                    to={`/cash-client/view-cash-client-booking-interpreting/${list._id}`}
                  >
                    {list.id}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {list.starttime}
                  <br /> {list.endtime}
                </Table.Cell>
                <Table.Cell>{list.total_hour}</Table.Cell>
                <Table.Cell>{list.source_language}</Table.Cell>
                <Table.Cell>{list.target_language}</Table.Cell>
                <Table.Cell>{list.interpreting_service_required}</Table.Cell>
                <Table.Cell>{list.remote_address}</Table.Cell>
                <Table.Cell>{list.linguist_skill_level}</Table.Cell>
                <Table.Cell>{list.gender}</Table.Cell>
                <Table.Cell>{list.car_parking}</Table.Cell>
                <Table.Cell>{list.security_clearance}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <Actions />
    </div>
  );
};

export default ViewAll;
