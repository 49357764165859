import React, { useEffect, useState } from "react";

// Custom Components
import Cookies from "js-cookie";
import Axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import { useToasts } from "react-toast-notifications";
import Action from "./Action"


const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const AssignmentHistory = () => {
  const classes = useDashboardStyles();
  const token = Cookies.get("token");
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { addToast } = useToasts();
  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });
  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const {
    name,
    error,
    success,
    bookingList,
    cacAndBacAccess,
    removed,
    reload,
  } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = async () => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/list-inactive-interpreting-CAC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/list-inactive-interpreting-BAC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/list-inactive-interpreting-INC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/list-inactive-interpreting-PRC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),

      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/list-inactive-translation-CAC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/list-inactive-translation-BAC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/list-inactive-translation-INC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/list-inactive-translation-PRC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),

    ]).then(
      Axios.spread((cacBooking, bacbooking, incbooking, prcbooking, cactrans, bactrans, inctrans, prctrans) => {
        const history = cacBooking.data
          .concat(bacbooking.data)
          .concat(incbooking.data)
          .concat(prcbooking.data)
          .concat(cactrans.data)
          .concat(bactrans.data)
          .concat(inctrans.data)
          .concat(prctrans.data)
        setValues({ ...values, bookingList: history });
      })
    );
  };

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          {" "}
          <TableContainer className={classes.container}>
            <Table id="cashbook-table" color="pink" stickyHeader aria-label="sticky table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="1">Client Ref</Table.HeaderCell>
                  <Table.HeaderCell width="1">Assignment Ref</Table.HeaderCell>
                  <Table.HeaderCell width="1"> Source language </Table.HeaderCell>
                  <Table.HeaderCell width="1"> Target language </Table.HeaderCell>
                  <Table.HeaderCell width='1'>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {bookingList
                  ?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  .map((list) => (
                    <Table.Row
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={list._id}

                    >
                      <Table.Cell>
                        {list.postedBy?.id
                          ? list.postedBy?.id
                          : list.ClientID?.id
                            ? list.ClientID?.id
                            : list.clientID?.id
                              ? list.clientID?.id
                              : list.createdBy?.id
                                ? list.createdBy?.id
                                : list.client_ID?.id
                                  ? list.client_ID?.id
                                  : list.PrivateClientID?.id

                        }
                      </Table.Cell>
                      <Table.Cell>{list.id}</Table.Cell>
                      <Table.Cell>{list.source_language}</Table.Cell>
                      <Table.Cell>{list.target_language}</Table.Cell>
                      <Table.Cell>
                        <Action id={list?._id} load={loadbookingList} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={bookingList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>

      </Paper>
    </div>
  );
};

export default AssignmentHistory;
