import React, { useState, useRef, useEffect } from "react";
import { Card, Header } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { Tooltip } from "@material-ui/core";

function ProfMemberShip({ id }) {
  const [values, setValues] = useState({ reload: false });

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/security_clearance-list/linID/${id}`
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <div
      raised
      style={{
        width: "100%",
        padding: "1rem",
        backgroundImage:
          "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
      }}
    >
      <Card.Content>
        <Header color="purple" as="h2">
          Security Clearance/s
        </Header>
        {values.length > 0 ? (
          <>
            {values?.map((list) => (
              <div style={{ marginRight: "10px" }}>
                <Header as="h5">
                  {list.security_clearance} <br /> {list.expiry_date}
                </Header>{" "}
                <br />
              </div>
            ))}{" "}
          </>
        ) : null}
      </Card.Content>
    </div>
  );
}

export default ProfMemberShip;
