import styled from "styled-components";
import { Button } from "@material-ui/core";

export const CustomButton = styled(Button)`
  .btn {
    color: #ab47bc;
    border: none;
    background: transparent;
    padding: 4px 15px;
  }
`;

export const DateFilterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  h3 {
    margin: 0;
    padding-right: 15px;
  }
`;

export const CashbookDiv = styled.div`
  margin-top: 2rem;
`;

export const TableDataDiv = styled.div`
  padding-top: 0;
  overflow-x: scroll;
  height: 500px;
  table {
    thead {
      tr {
        th {
          min-width: 160px;
        }
      }
    }

    .danger {
      background: #ff00004f;
    }
    .success {
      background: green;
      /* TODO:- add green if needed */
    }
  }
`;

export const AddDataDiv = styled.div`
  padding-top: 2rem;
  overflow-x: scroll;
  table {
    thead {
      tr {
        th {
          min-width: 160px;
        }
      }
    }
    tbody {
      td {
        .d-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .MuiFormControl-root {
            margin-right: 0px;
          }
        }
      }
    }
  }
`;
