import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Container, Header } from 'semantic-ui-react'
import Axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

const SuccessPRCOTWeb = () => {
  const { id } = useParams()
  const [session, setSession] = useState(null)
  const [sendMail, setSendMail] = useState(null)
  const bookingid = Cookies.get('prcTransID')

  const changePaymentStatus = async (session) => {

    try {
      const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/private_translation/order_translation/confirmmail/stripe`, {
        currency_new: session.currency,
        new_total: session.amount_received / 100,
        bookingid: bookingid,
        payment_intent: session.id,
        charge_id: session.charges.data[0].id,
        // payment_date: moment.unix(session.created).format("DD/MM/YYYY HH:mm")
      })
      setSendMail(response?.data)
    } catch (error) {
      console.log(error.response)
    }
  }
  useEffect(() => {
    const retreiveSession = async (e) => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/retrieve/payment/intent/${id}`)
        setSession(response?.data)
        changePaymentStatus(response?.data)

      } catch (error) {
        console.log(error)
      }
    }

    retreiveSession();

  }, [])
  return (
    session ?
      <div style={{ backgroundColor: 'purple', height: '100%', padding: '30px' }}>
        <Container style={{ backgroundColor: 'white', color: 'purple', height: '100%', padding: '10px', borderRadius: '10px' }} textAlign='center'>
          {/* <Card> */}
          <Header as='h3'>Estimated sum paid successfully. Thank you, {session.charges.data[0].billing_details.name}.</Header>
          <Header as='h4'>Please check your email.</Header>
          <Header as='h3'>Order ID - {bookingid}</Header>
          <Header as='h3'>Charge / Transaction ID - {session.charges.data[0].id}</Header>
          <Header as='h3'>Total Estimated Sum Paid - {session.currency.toUpperCase() + " " + session.amount_received / 100}</Header>
          <Header as='h3'>{moment.unix(session.created).format("DD/MMM/YYYY HH:mm")}</Header>
          {!sendMail ?
            <div>
              <img
                style={{ width: '300px', height: '200px', marginBottom: '10px' }}
                src='https://i.pinimg.com/originals/98/64/9a/98649add72e05e3cc1b8ae0e6f553c8e.gif'
                alt=''
              />
              <Header as='h3'>Email sending...</Header>
              <Header as='h3'>Please wait</Header>
            </div>
            :
            <>
              <Header as='h3'>Email sent!</Header>

              <Button onClick={() => window.location = '/'} basic color='purple'>Home Page</Button>
              <Button onClick={() => window.print()} color='purple'>Print</Button>
            </>
          }
        </Container>
      </div>
      : null
  )
}

export default SuccessPRCOTWeb
