import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import moment from "moment";

const Qualification = () => {
  const [edit, setEdit] = useState(false);
  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updateProfileReducer);

  const handleEdit = (e) => {
    setEdit(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    let fd = new FormData();

    fd.append("institute_name", data.institute_name || "");
    fd.append("date_of_graduation", data.date_of_graduation || "");
    fd.append("name_of_degree", data.name_of_degree || "");
    fd.append("subject_studied", data.subject_studied || "");

    try {
      const response = await Axios.put(
        `/api/manager-admin/update-linguist/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit(false);
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="institute_name"
            onChange={handleChange}
            label="Name of institute / school / college / university"
            edit={edit}
            value={data.institute_name}
          />

          {edit ? (
            <CustomTextInput
              name="date_of_graduation"
              onChange={handleChange}
              label=" Year / date of graduation of course completion"
              dlabel="Date of graduation"
              dvalue={data.date_of_graduation}
              date={true}
            />
          ) : (
            <CustomTextInput
              name="date_of_graduation"
              label=" Year / date of graduation of course completion"
              value={moment(data.date_of_graduation).format("DD/MM/yyyy")}
            />
          )}

          <CustomTextInput
            name="name_of_degree"
            onChange={handleChange}
            label="Name of degree / course / diploma / qualification / CPD obtained"
            edit={edit}
            value={data.name_of_degree}
          />
          <CustomTextInput
            name="subject_studied"
            onChange={handleChange}
            label="Subjects studied"
            edit={edit}
            value={data.subject_studied}
          />
          <CustomTextInput
            label="Upload relevant certificate photo and any reference letter"
            value=""
          />

          <a href={`/admin/upload-linguist-documents/${data._id}`}>
            {" "}
            Upload Documents
          </a>

        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default Qualification;
