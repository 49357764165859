import { v4 as uuidv4 } from "uuid";
import InitialRegistraion from "pages/InitialRegistration";
const INITIAL_REGISTRATION = [
  {
    id: uuidv4(),
    type: "form",
    text: "Initial Registration",
    subText: "Initial Registration",
    path: "initial-registration-form",
    component: InitialRegistraion,
  },
];

export default INITIAL_REGISTRATION;
