import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import LinguistLayout from "layout/LinguistLayout";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Button, useMediaQuery } from "@material-ui/core";
import BookingOptions from "components/Linguist/ManagePrivateClient/BookingOption";
import CardBody from "components/Card/CardBody";

const AddPrivateClient = () => {
  const classes = useDashboardStyles();
  const matches = useMediaQuery('(max-width:768px)')
  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Private Direct Quote</p>
                <IconButton color='inherit'><ArrowBackIcon
                  onClick={() => history.goBack()}
                ></ArrowBackIcon></IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BookingOptions />
              </CardHeader>
              <CardBody>
                {!matches ? <div>
                  <Button onClick={() => history.push('/linguist/private-client/provide-quote')} style={{ marginRight: '10px' }} variant='contained' color='primary'>Provide PRC Quote </Button>
                  <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/private-client/saved-quote-list')} variant='contained' color='primary'> Saved Quotes</Button>
                  <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/private-client/quote-list')} variant='contained' color='primary'> Sent Quotes</Button>
                  <Button onClick={() => history.push('/linguist/private-client/accepted-quote-list')} variant='contained' color='primary'>Accepted Quote </Button>
                </div> : <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto' }}>
                  <Button onClick={() => history.push('/linguist/private-client/provide-quote')} style={{ marginRight: '10px' }} variant='contained' color='primary'>Provide PRC Quote </Button>
                  <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/private-client/saved-quote-list')} variant='contained' color='primary'> Saved Quotes</Button>
                  <Button style={{ marginRight: '10px' }} onClick={() => history.push('/linguist/private-client/quote-list')} variant='contained' color='primary'> Sent Quotes</Button>
                  <Button onClick={() => history.push('/linguist/private-client/accepted-quote-list')} variant='contained' color='primary'>Accepted Quote </Button>
                </div>}
              </CardBody>
            </Card>  
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default AddPrivateClient;
