import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import axios from "axios";
import Action from "../Action";
import { Link } from "react-router-dom";


const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  button: {
    background: "#e0e1e2 none",
    border: 0,
    color: "rgba(0,0,0,.6)",
    padding: 12,
    marginRight: 10,
    borderRadius: "3pt",
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
    fontSize: 13,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    details: [],
    removed: false,
    reload: false,
  });

  const { details, reload } = values;

  useEffect(() => {
    loadList();
  }, [reload]);

  const loadList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-list-quote-request-accountclient`
      );
      setValues({ ...values, details: response?.data });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="export">
          <TableHead>
            <TableRow>
              <TableCell>Assignment ID</TableCell>
              <TableCell>Quote Request For</TableCell>
              <TableCell>Source Language</TableCell>
              <TableCell>Target Language</TableCell>
              <TableCell>Remote / Venue</TableCell>
              <TableCell>Request Details</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>Skills Level</TableCell>
              <TableCell>Quote Needed By</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {details
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((list, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}
                  style={
                    moment(list?.quote_by) < moment()
                      ? { backgroundColor: "red", color: "white" }
                      : null
                  }
                >
                  <TableCell key={list._id} align={list.align}>
                    <Link
                      to={`/admin/view-account-client-quotation/${list._id}`}
                    >
                      {list.id}
                    </Link>
                  </TableCell>
                  <TableCell>{list.quote_rqst_for}</TableCell>
                  <TableCell>{list.source_language}</TableCell>
                  <TableCell>{list.target_language}</TableCell>
                  <TableCell>{list.country}</TableCell>
                  <TableCell>{list.request_details}</TableCell>
                  <TableCell>{list.project_fix_budget}</TableCell>
                  <TableCell>{list.target_skills_required}</TableCell>
                  <TableCell>
                    {moment(list.quote_by).format("DD-MMM-YYYY")}
                  </TableCell>

                  <TableCell>
                    <Action
                      load={loadList}
                      cancel={list.cancel}
                      list={list}
                      id={list._id}
                      date={moment(list?.quote_by) < moment()}
                      reqQuoteBAC={"reqQuoteBAC"}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={details.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
