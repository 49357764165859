import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isAuth } from "../../../action/cashClientaction";

const Private = ({ children }) => {
  const history = useHistory();
 
  useEffect(() => {
    if (!isAuth()) {
      history.push("/cash-client-login");
    } else if (isAuth().role !== 1) {
      history.push("/cash-client-login");
    }
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default Private;
