import React from "react";
import Axios from "axios";
import swal from "sweetalert";
// import Button from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const Action = ({ id, load }) => {
  const DeleteMembership = async (e) => {
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/sppo/delete-cooperate-certification/${id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleRemove = async (value) => {
    swal("Are you sure?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          DeleteMembership(value);

        default:
          break;
      }
    });
  };

  return (
    <IconButton onClick={handleRemove}>
      <DeleteIcon />
    </IconButton>
  );
};

export default Action;
