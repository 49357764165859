import { makeStyles } from "@material-ui/core/styles";

import { grayColor } from "../variables";

const style = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    backgroundAttachment: "fixed",
    background: "#FAFAFA",
  },
  container: {
    background: "#FAFAFA",
    backgroundAttachment: "fixed",
  },
  avatar: {
    width: "100px",
    height: "100px",
    margin: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#FAFAFA",
  },
  large_avatar: {
    width: "250px",
    height: "250px",
    margin: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#FAFAFA",
  },
  square_avatar: {
    width: "100%",
    height: "max-content",
    margin: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#FAFAFA",
  },
  textCenter: {
    textAlign: "center",
  },
  title: {
    borderBottom: `1px solid ${grayColor[11]}`,
    paddingBottom: "5px",

    "& h1": {
      "@media (max-width: 480px)": {
        fontSize: "1rem",
      },
    },
  },
  menu: {
    "& div.ui.secondary.pointing.menu": {
      overflow: "auto",
      overflowY: "hidden",
      "@media (max-width: 1024px)": {
        display: "grid",
        gridTemplateColumns: "auto auto",
        overflow: "hidden",
      },
    },
  },
  header: {
    marginBottom: "5rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  profile_photo_card: {
    width: "fit-content",
  },
  about: {
    marginLeft: "4rem",
    textAlign: "start",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      marginLeft: "2rem",
    },
    "@media (max-width: 425px)": {
      flexDirection: "column",
      marginLeft: "1rem",
    },
    "@media (max-width: 375px)": {
      flexDirection: "column",
      marginLeft: "1rem",
      fontSize: "xx-small",
    },
  },
  notes: {
    padding: "12px",
    borderBottom: `1px solid ${grayColor[11]}`,
  },
  forgotDiv: {
    textAlign: "center",
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-root": {
      padding: "0px",
      color: "blue",
    },
    fontFamily: "Roboto",
    fontWeight: "500",
    lineHeight: "1.75",
    "& a": {
      textDecoration: "none",
      color: "blue",
      fontWeight: "500",
    },
  },
  buttonDiv: {
    marginTop: "20px",
    textAlign: "center",
    "& .MuiButton-root": {
      width: "120px",
      margin: "0 5px",
    },
  },
  toggleAccess: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

const useEmployeeStyles = makeStyles(style);

export default useEmployeeStyles;
