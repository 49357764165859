import React from "react";
import { Checkbox, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CustomFormControl from "./CustomFormControl";
import CustomLabel from "./CustomLabel";

const CustomAutoComplete = ({
  width,
  label,
  data,
  required,
  placeholder,
  ...rest
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <CustomFormControl width={`${width || "100%"}`}>
      {data.length > 0 ? (
        <Autocomplete
          multiple
          id="nationalities"
          name="Pratik"
          options={data}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          {...rest}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </React.Fragment>
          )}
          style={{ width: { width } }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={<CustomLabel label={label} required={required} />}
              placeholder={placeholder}
            />
          )}
        />
      ) : (
        <TextField
            variant="outlined"
            disabled
            value="LIN contact group is empty"
        />
      )}
    </CustomFormControl>
  );
};

export default CustomAutoComplete;
