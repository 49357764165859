import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table, Button } from "semantic-ui-react";
import ReactToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import SearchView from "./InvoicingSearch/SearchInvoice";
import Cookies from "js-cookie";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import Action from "./Action";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  button: {
    background: "#3f51b5 none",
    border: 0,
    color: "#ffff",
    padding: 10,
    margin: 0,
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
    borderRadius: "3pt",
    fontSize: "14px",
  },
});

export default function InvoicingList() {
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paidModal, setPaidModal] = React.useState(false);
  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  let sigPad = useRef({});

  const [pay, setPay] = useState({
    payment_date: "",
    payment_ref: "",
    estimate_paid_amount: "",
    note: "",
    signature: "",
    booking_id: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    ReceivedInvoice: [],
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });

  const { ReceivedInvoice, reload } = values;

  useEffect(() => {
    loadUnconfirmBookingList();
  }, [reload]);

  const loadUnconfirmBookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/from-admin-invoice-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, ReceivedInvoice: response?.data });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/from-admin-invoice-find`,
        { id: SearchText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct invoice id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({
          ...values,
          ReceivedInvoice: [response?.data],
          reload: false,
        });

        addToast("Invoice found", { appearance: "success" });
      } else {
        addToast("Invoice not found", { appearance: "error" });
      }
      if (values.ReceivedInvoice) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct invoice id.", {
          appearance: "error",
        });
      }
    }
  };

  //export in pdf
  const exportPDF = (e) => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 10;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    var totalPages = doc.internal.getNumberOfPages();
    var i;

    for (i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
      // doc.setTextColor(150);
      doc.text(
        50,
        doc.internal.pageSize.height - 30,
        "Strictly Company Confidential © linguistscollective.com"
      );
    }

    const title = "received invoices";
    const headers = [["Invoice ID", "Date", "Invoice Total", "Status", "Note"]];

    const data = ReceivedInvoice.map((list) => [
      list.id,
      list.date_of_invoice,
      list.currency.substring(0, 3) + " " + list.grand_total.toFixed(2),
      list.status,
      list.admin_note,
    ]);
    // const One = "Column One: Job / Your Reference";
    // const Two = "Column Two: Payment Received / Contract Value";

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        valign: "middle",
        // halign: 'justify'
      },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    var string = doc.output("datauristring");
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    var x = window.open();
    // doc.save('un-paid-invoice-table-pdf')
    x.document.open();
    x.document.write(embed);
    x.document.close();
  };

  return (
    <div>
      <SearchView
        show={show}
        load={loadUnconfirmBookingList}
        handleSearch={handleSearch}
        setSearchText={setSearchText}
        SearchText={SearchText}
        setShow={setShow}
      />
      {show ? (
        <div>
          <Paper className={classes.root}>
            <div>
              <TableContainer className={classes.container}>
                <Table
                  id="cashbook-excel"
                  color="pink"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Invoice ID</Table.HeaderCell>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      <Table.HeaderCell>Invoice Total</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Note</Table.HeaderCell>
                      <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  {ReceivedInvoice.length > 0 ? (
                    <Table.Body>
                      {ReceivedInvoice.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).map((invoice) => (
                        <Table.Row
                          role="checkbox"
                          tabIndex={-1}
                          key={invoice._id}
                          style={{
                            background: `${
                              invoice.invoice_payment_status
                                ? "#0080006b"
                                : "#ff00004d"
                            }`,
                          }}
                        >
                          <Table.Cell key={invoice._id} align={invoice.align}>
                            <Link
                              to={`/linguist/view-received-invoice/${invoice._id}`}
                            >
                              {invoice.id}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>
                            {moment(invoice.date_of_invoice).format(
                              "DD-MMM-YYYY"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {invoice.currency?.substring(0, 3)}{" "}
                            {invoice.grand_total}
                          </Table.Cell>
                          <Table.Cell>{invoice.status}</Table.Cell>
                          <Table.Cell>{invoice.admin_note}</Table.Cell>
                          <Table.Cell>
                            <Action
                              id={invoice?._id}
                              bank_pay={invoice?.bank_pay}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  ) : null}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={ReceivedInvoice.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </Paper>
          <div style={{ marginTop: "5px" }}>
            {/* Export in PDF */}
            <Button
              style={{ background: "#3f51b5 none", color: "white" }}
              onClick={exportPDF}
            >
              Export in Pdf
            </Button>
            {/* Export in XLS */}
            <ReactToExcel
              className={classes.button}
              table="cashbook-excel"
              filename="received-invoices"
              sheet="sheet 1"
              buttonText="Export in Excel"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
