import SearchInput from "./SearchInput";
import SearchInputINC from "./SearchInputINC";

const SearchView = ({
  setSearchText,
  setShow,
  SearchText,
  load,
  values,
  setFiltered,
  setFilter,
  show,
  inc,
}) => {
  return (
    <div>
      {!inc ? (
        <SearchInput
          values={values}
          load={load}
          setSearchText={setSearchText}
          setShow={setShow}
          SearchText={SearchText}
          setFiltered={setFiltered}
          setFilter={setFilter}
          show={show}
        />
      ) : (
        <SearchInputINC
          values={values}
          load={load}
          setSearchText={setSearchText}
          setShow={setShow}
          SearchText={SearchText}
          setFiltered={setFiltered}
          setFilter={setFilter}
          show={show}
        />
      )}
    </div>
  );
};

export default SearchView;
