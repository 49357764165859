import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SppoLayout from "layout/SppoLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CashBook from "components/Sppo/CashBook";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CashbookPage from "components/CashBook/CashbookPage";

const Cashbook = () => {
  const classes = useDashboardStyles();

  return (
    <SppoLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>
                Cashbook
              </p>
            </CardHeader>
            <CardBody>
              <CashBook />
              <CashbookPage />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </SppoLayout>
  );
};

export default Cashbook;
