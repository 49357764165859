import { useHistory } from "react-router-dom";

// Materials Core
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { useEffect, useState } from "react";
import { Button, useMediaQuery } from "@material-ui/core";



const styles = {
    root: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        "& .MuiButton-root": {
            marginRight: "10px",
            marginBottom: "5px",
            color: "#fff",
            borderColor: "#fff",
            "&:last-child": {
                marginRight: "0px",
            },
            "@media (max-width: 480px)": {
                marginRight: "0px",
                width: "100%",
                marginBottom: "10px",
            },
        },
    },
};

const useStyles = makeStyles(styles);

const QuoteOptions = () => {
    const classes = useStyles();
    const history = useHistory();
    const matches = useMediaQuery("(max-width:768px)");
    return (
        <div className={classes.root}>
            {!matches ? (
                <div>
                    <Button
                        onClick={() => history.push("/sppo/provide-direct-invoice")}
                        style={{ marginRight: "10px" }}
                        variant="contained"
                        color="primary"
                    >
                        Raise Invoice{" "}
                    </Button>
                    <Button
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                            history.push("/sppo/direct-invoice-list")
                        }
                        variant="contained"
                        color="primary"
                    >
                        {" "}
                        Direct invoice to client
                    </Button>
                </div>
            ) : (
                <div>
                    <Button
                        onClick={() => history.push("/sppo/provide-direct-invoice")}
                        style={{ marginRight: "10px" }}
                        variant="contained"
                        color="primary"
                    >
                        Raise Invoice{" "}
                    </Button>
                    <Button
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                            history.push("/sppo/direct-invoice-list")
                        }
                        variant="contained"
                        color="primary"
                    >
                        {" "}
                        Direct invoice to client
                    </Button>
                </div>
            )}
        </div>
    );
};

export default QuoteOptions;
