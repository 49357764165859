import CustomTextInput from 'components/Common/CustomTextInput'
import React, { useRef, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { Checkbox } from 'semantic-ui-react'
import {IconButton} from '@material-ui/core'

import SignatureCanvas from 'react-signature-canvas'
import EditIcon from '@material-ui/icons/Edit';
import './sigCanvas.css'
import CustomModal from 'components/Common/CustomModal';
import RichTextEditor from 'react-rte';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { changeTimesheetInput } from 'redux/actions/timesheetAction'

const TimesheetFooter = ({details}) => {
    let sigPad = useRef({})
    const [signatureModal, setSignatureModal] = useState(false)
    const {data} = useSelector(state => state.timesheetReducer)
    const dispatch = useDispatch()
    const [text, setText] = useState(RichTextEditor.createEmptyValue())

    const {
        signature
    } = data

    const handleClose = e => {
        e.preventDefault()
        setSignatureModal(false)
    }

    const handleClear = e => {
        e.preventDefault()
        sigPad.current.clear()
    }

    const handleSave = e => {
        const temp = (sigPad.current.toDataURL())
        dispatch(changeTimesheetInput({
            name: 'signature',
            value: temp
        }))
        setSignatureModal(false)
    }

    const handleText = value => {
        setText(value)
    }

    return (
        <div>
            <CustomModal
                open= {signatureModal}
                handleClose= {handleClose}
                style= {{height: '50%', width: '50%'}}
            >
                <SignatureCanvas penColor='black'
                    //onEnd = {handleSave}
                    ref = {sigPad}
                    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} 
                />
                <div style= {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button onClick= {handleClear} style= {{marginRight: '5px'}} color= 'purple'>Clear</Button>
                    <Button onClick= {handleSave} color = 'grey'>Save</Button>
                </div>
            </CustomModal>

            {/* Table */}

            <Checkbox label='I declare that I have checked the information on this form and certify that it is true and complete' />

            <div>
                Signature <IconButton onClick = {() => setSignatureModal(true)}><EditIcon /></IconButton>
            </div>
            {signature && <img style= {{width: '130px'}} src= {signature} alt= '' />}

            <CustomTextInput label= 'Full name' value= {details.quoteRequstByCashclient.postedBy.FirstName + " " + details.quoteRequstByCashclient.postedBy.LastName} />

            Date: {moment().format('DD-MM-YYYY')}
        </div>
    )
}

export default TimesheetFooter
