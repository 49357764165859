import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import DirectAction from "./Actions/DirectQuoteAction";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ListCACAssignments({ quotationData, text }) {
  const token = Cookies.get("BACtoken");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    UnconfirmBookingList: [],
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });

  const [quoteValue, setQuotesValue] = useState({
    UnconfirmQuoteList: [],
  });

  const {
    reload,
  } = values;
  
  const { UnconfirmQuoteList } = quoteValue;

  useEffect(() => {
    loadDirectQuoteByAdmin();
  }, [reload]);


  const loadDirectQuoteByAdmin = async (e) => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/BAC/list-direct-unpaid-quote-by-admin`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((unconfirmedJobs) => {
        setQuotesValue({
          ...quoteValue,
          UnconfirmQuoteList: quotationData
            ? unconfirmedJobs.data.filter((item) => item.id === quotationData)
            : unconfirmedJobs.data,
        });
      })
    );
  };

  return (
    <>
     
      <h3>Direct Quote By LC</h3>

      <br />

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Quote ID</TableCell>
                <TableCell>Quotation Date</TableCell>
                <TableCell>Expiry Date</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Grand total</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {UnconfirmQuoteList.length > 0 ? (
              <TableBody>
                {UnconfirmQuoteList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((list, i) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    <TableCell>{list.id}</TableCell>
                    <TableCell>
                      {moment(list.createdAt).format("DD-MMM-YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(list.expiry_date) < moment() ||
                        moment(list.expiry_date) === moment() ? (
                        <p style={{ color: "red" }}>
                          {moment(list.expiry_date).format("DD-MMM-YYYY")}
                        </p>
                      ) : (
                        moment(list.expiry_date).format("DD-MMM-YYYY")
                      )}
                    </TableCell>
                    <TableCell>{list.currency}</TableCell>
                    <TableCell>{list.grand_total}</TableCell>
                    <TableCell>
                      <DirectAction id={list._id} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={UnconfirmQuoteList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
