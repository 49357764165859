import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DescriptionIcon from "@material-ui/icons/Description";
import BusinessIcon from "@material-ui/icons/Business";
import Axios from "axios";
import Cookies from 'js-cookie'


const ULOptions = ({id}) => {
  const history = useHistory();
  const token = Cookies.get('LINtoken')
  const [totalSecurityDoc1, setTotalSecurityDoc1] = React.useState(false);
  const [totalSecurityDoc2, setTotalSecurityDoc2] = React.useState(false);
  const [totalSecurityDoc3, setTotalSecurityDoc3] = React.useState(false);
  const [totalSecurityDoc4, setTotalSecurityDoc4] = React.useState(false);

  const getTotalSecurityDoc1 = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/count-security-clearence-document1`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalSecurityDoc1(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalSecurityDoc2 = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/count-security-clearence-document2`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalSecurityDoc2(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalSecurityDoc3 = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/count-security-clearence-document3`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalSecurityDoc3(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalSecurityDoc4 = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/count-security-clearence-document4`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalSecurityDoc4(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalSecurityDoc1();
    getTotalSecurityDoc2()
    getTotalSecurityDoc3()
    getTotalSecurityDoc4()
  }, []);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "auto auto " }}>
      <Button
        startIcon={<PermIdentityIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/linguist/profile/update`)
        }
      >
        Back to Profile
      </Button>
      <Button
        startIcon={<PermIdentityIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/linguist/linguist-view-security-clearence-1`)
        }
      >
        1-Security Clearence Document ({totalSecurityDoc1})
      </Button>

      {/* proof of address */}
      <Button
        startIcon={<BusinessIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-security-clearence-2`)}
      >
        2-Security Clearence Document ({totalSecurityDoc2})
      </Button>

      <Button
        startIcon={<CreditCardIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-security-clearence-3`)}
      >
        3-Security Clearence Document ({totalSecurityDoc3})
      </Button>
      <Button
        startIcon={<DescriptionIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-security-clearence-4`)}
      >
        4-Security Clearence Document ({totalSecurityDoc4})
      </Button>


    </div>
  );
};

export default ULOptions;
