import { CHANGE_QUOTERQST_INPUT, UPDATE_QUOTERQST_STEP }  from "../actions/actionTypes";
import moment from 'moment-timezone'

const INITIAL_STATE = {
  count: 1,
  data: {
    purchase_order_number: '',
    quote_rqst_for: [],
    house_street_no_name: '',
    address_line_two: '',
    town_city: '',
    post_code: '',
    county_region: '',
    country: '',
    other_details: '',
    request_details: '',
    project_fix_budget: '',
    documents: '',
    source_language: '',
    target_language: '',
    skills_required: '',
    provider_id_number: '',
    gender: '',
    timezone: moment.tz.guess(),
    quote_by: '',
    pay_type: '',
    upload_cv: '',
    security_clearance: '',
    gdpr: ''
  }
};

const quoterqstReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_QUOTERQST_STEP:
      return {
        ...state,
        count: payload,
      };
    
    case CHANGE_QUOTERQST_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value
        }
      }

    default:
      return state;
  }
};

export default quoterqstReducer;