import SearchInput from "./DateInput";

const SearchView = ({
  from,
  setFrom,
  to,
  setTo,
  load,
  values,
  setFiltered,
  setFilter,
}) => {
  return (
    <div>
      <SearchInput
        values={values}
        load={load}
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
        setFiltered={setFiltered}
        setFilter={setFilter}
      />
    </div>
  );
};

export default SearchView;
