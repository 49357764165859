import React, { Fragment } from "react";

// Custom Components
import ViewOTClients from "components/Auth/ViewOTClients/LinguistsCollective";

const ViewOTClientsPage= () => {
  return (
    <Fragment>
      <ViewOTClients />
    </Fragment>
  );
};

export default ViewOTClientsPage
