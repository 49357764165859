import React from "react";
import Typography from "@material-ui/core/Typography";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormTwo from "./FormTwo";
import SelectClient from "./searchclient";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";


const PrivateInterpretingTel = ({ title }) => {
  const classes = useEmployeeStyles();

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.textCenter} ${classes.title}`}>
                <CustomAvatar />
                <Typography component="h1" variant="h5">
                  Private Interpreting Assignment Booking (Telephonic)
                </Typography>
              </div>

              <SelectClient />
              <FormTwo />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default PrivateInterpretingTel;
