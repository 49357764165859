const { CHANGE_ADMINREG_INPUT } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    data: {
        profile_photo: '',
        title: '',
        first_name: '',
        last_name: '',
        dob: '',
        gender: '',
        primary_email: '',
        personal_email: '',
        tel_no: '',
        mobile_no: '',
        house_street_number_name: '',
        address_line_two: '',
        town_city: '',
        post_zip_code: '',
        county_region: '',
        country: '',
        current_nationality: '',
        other_nationalities: [],
        password: '',
        confirm_password: '',
        additional_info: '',
        agree: '',
    }
};


const adminregReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case CHANGE_ADMINREG_INPUT:
            return {
                ...state,
                data: {
                  ...state.data,
                  [payload.name]: payload.value
                }
            }
        
        default:
            return state
    }
}

export default adminregReducer
