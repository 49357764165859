// Custom Components
import CommonCard from "components/Common/CommonCard";
import Glossary from "assets/images/glossary.png";
const Notifications = () => {
  return (
    <div style={{ margin: "5px" }}>
      <CommonCard title="Glossary and Terminology">
        <a href="https://linguistglossary.net" target="_blank">
          <img style={{ width: "100%" }} src={Glossary} alt="" />
        </a>
      </CommonCard>
    </div>
  );
};

export default Notifications;
