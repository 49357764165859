import ServiceTable from "./ServiceTable";
import Table from "./Table";
import OtherExpenseTable from "./OtherExpenseTable";
import Total from "./Total";

const TableBody = ({ state }) => {
  const priceOne = state.split(" ")[1];

  return (
    <Table>
      <ServiceTable price={priceOne} />
      <OtherExpenseTable price={priceOne} />
      <Total state={state} />
    </Table>
  );
};

export default TableBody;
