import fetch from "isomorphic-fetch";
import cookie from "js-cookie";

const token = cookie.get("token");
const newid = cookie.get("job_id");

export const getAccountClient = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/maneger/interpreter-assignment-booking-telephonic-form`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//get private client
export const getPrivateClient = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/private/client/list`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getCashClientJobAdvart = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/maneger/interpreter-assignment-booking-telephonic-form`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//signin
export const sign_in = (user) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/cashclient/signin`,
    {
      method: "POST",

      headers: {
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(user),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//cookie
export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 1,
    });
  }
};

//get cookie
export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};

// set localstorage
export const setLocalStorage = (key, value) => {
  if (process.browser) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// authenticate user by pass data to cookie and local storage
export const authenticate = (data, next) => {
  setCookie("token", data.token);
  setLocalStorage("user", data.user);
  next();
};

export const isAuth = () => {
  if (process.browser) {
    const cookieChecked = getCookie("token");
    if (cookieChecked) {
      if (localStorage.getItem("user")) {
        return JSON.parse(localStorage.getItem("user"));
      } else {
        return false;
      }
    }
  }
};

// update user in local storage
export const updateUser = (user, next) => {
  if (process.browser) {
    if (localStorage.getItem("user")) {
      let auth = JSON.parse(localStorage.getItem("user"));
      auth = user;
      localStorage.setItem("user", JSON.stringify(auth));
      next();
    }
  }
};

export const cash_client_profileupdate = () => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/cashclient/profile`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//get booking details with id
export const getBookingDetails = () => {
  // alert(newid)
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/maneger/interpreter-assignment/${newid}`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
