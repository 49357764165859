import React, { useEffect, useState } from "react";
import { Dimmer, Header, Loader, Tab } from "semantic-ui-react";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import Documents from "./Documents/Documents";
import DocumentsNew from "./DocumentsNew/Documents";
import BankDetails from "./BankDetails";
import PersonalDetails from "./PersonalDetails";
import InsuranceDetails from "./InsuranceDetails";
import ProfessionalDetails from "./ProfessionalDetails";
import Qualification from "./Qualification";
import WorkExperience from "./WorkExperience";
import SecurityClearance from "./SecurityClearance";
import OtherDetails from "./OtherDetails";
import StripeDetails from "./StripeDetails";

import DeleteAccountRequest from "./DeleteAccountRequest.jsx";
import Axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { updateLinguistProfile } from "redux/actions/updateProfileAction";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import swal from "sweetalert";
import history from "utils/history";

const ProfileUpdateLinguist = () => {
  const token = Cookies.get("LINtoken");
  const dispatch = useDispatch();
  const classes = useEmployeeStyles();
  const { data } = useSelector((state) => state.updateProfileReducer);
  const [upload, setUpload] = useState("");

  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data.profile_update === false) {
        history.push("/linguist/full-registration-p1");
      }
      dispatch(updateLinguistProfile(response?.data));
      //setData(response?.data)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getLinguistDetails();
  }, []);

  const panes = [
    {
      menuItem: "Personal Details",
      render: () => (
        <Tab.Pane attached={false}>
          <PersonalDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Bank Details",
      render: () => (
        <Tab.Pane attached={false}>
          <BankDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Payment Processor Details",
      render: () => (
        <Tab.Pane attached={false}>
          <StripeDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Due diligence",
      render: () => (
        <Tab.Pane attached={false}>
          <InsuranceDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Professional Details",
      render: () => (
        <Tab.Pane attached={false}>
          <ProfessionalDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "General Qualification",
      render: () => (
        <Tab.Pane attached={false}>
          <Qualification />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Work Experience",
      render: () => (
        <Tab.Pane attached={false}>
          <WorkExperience />
        </Tab.Pane>
      ),
    },

    {
      menuItem: "Security Clearance",
      render: () => (
        <Tab.Pane attached={false}>
          <SecurityClearance />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Old Documents",
      render: () => (
        <Tab.Pane attached={false}>
          <Documents id={data._id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Documents",
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentsNew id={data._id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Other details",
      render: () => (
        <Tab.Pane attached={false}>
          <OtherDetails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Delete Account Request",
      render: () => (
        <Tab.Pane attached={false}>
          <DeleteAccountRequest deleteRequest={data?.deleteAccountRequest} />
        </Tab.Pane>
      ),
    },
  ];

  const handleUpload = async (e) => {
    document.querySelector("#file").click();
  };

  const handleFiles = async (e) => {
    if (e.target.files.length) {
      let fd = new FormData();

      fd.append("photo", e.target.files[0]);

      try {
        const response = await Axios.put(
          `${process.env.REACT_APP_BACKEND_API}/linguists/profile/update`,
          fd,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        swal({
          icon: "success",
          text: "Thank you for updating profile",
          buttons: false,
        }).then(function () {
          window.location = "/linguist/profile/update";
        });
      } catch (error) {
        console.log(error.response);
        if (error.response?.data) {
          swal({
            icon: "error",
            text: error?.response?.data?.error,
            buttons: false,
            dangerMode: true,
          });
        }
      }
    }
  };

  return data.profile_update === true ? (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
    >
      <div className={classes.header}>
        <GridItem xs={6} sm={6} md={6} lg={2} xl={2}>
          <Card className={classes.profile_photo_card}>
            <CardBody>
              <div
                style={{ cursor: "pointer" }}
                onClick={handleUpload}
                className="image"
              >
                <img
                  src={`${process.env.REACT_APP_BACKEND_API}/linguists/profile-photo/${data._id}`}
                  style={{ maxHeight: "auto", maxWidth: "100%" }}
                  alt="profile photo"
                />
              </div>
              <input
                onChange={handleFiles}
                type="file"
                accept="image/png, image/jpeg"
                id="file"
                style={{ display: "none" }}
              />
            </CardBody>
          </Card>
        </GridItem>

        <div className={classes.about}>
          <br />
          <Header size="large">Profile ID: {data.id}</Header>
          <Header size="large">
            {data.title} {data.FirstName} {data.LastName}
          </Header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Position:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.position}
            </Header>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Registered as:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data?.other_service ? data?.other_service : data?.service}
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Email:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.email}
            </Header>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Mobile number:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.mobile_no}
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Source Language(s):{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.source_language} - {data.source_language_level}
              <br />
              {data.source_language_1} - {data.source_language_level_1}
              <br />
              {data.source_language_2} - {data.source_language_level_2}
            </Header>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Target language(s):{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.target_language} - {data.target_language_level} <br />
              {data.target_language_1} - {data.target_language_level_1} <br />
              {data.target_language_2} - {data.target_language_level_2}
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              <a
                target="_blank"
                href={`/linguist/public/profile/${
                  data?.source_language?.toString().split(" ")[0]
                }/${data?.target_language?.toString().split(" ")[0]}/${
                  data._id
                }`}
              >
                View Public Profile
              </a>{" "}
              |
              <a
                href={`/linguist/protected/public/profile/${
                  data?.source_language?.toString().split(" ")[0]
                }/${data?.target_language?.toString().split(" ")[0]}/${
                  data._id
                }`}
              >
                {" "}
                Preview (Restricted)
              </a>{" "}
              |<a href={`/linguist/manage-public-profile`}> Manage</a>
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0", color: "#949f8c" }} size="medium">
              Old Profile ID:{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="#949f8c"
              size="medium"
            >
              <strike style={{ color: "#949f8c" }}>{data?.old_id}</strike>
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <LocationOnIcon />
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.Country}
            </Header>
          </div>
        </div>
      </div>

      <div>
        <Tab
          className={classes.menu}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
        />
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader active inverted size="small"></Loader>
    </Dimmer>
  );
};

export default ProfileUpdateLinguist;
