import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, TextArea } from "semantic-ui-react";
// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";

import CustomButton from "components/Common/CustomButton";
import CustomPhone from "components/Common/CustomPhone";
import CustomRadio from "components/Common/CustomRadio";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Other
import { Checkbox } from "@material-ui/core";
import { changePreRegInput } from "redux/actions/preregAction";
import Axios from "axios";
import swal from "sweetalert";
import { Button, Header } from "semantic-ui-react";
import CustomSelect from "components/Common/CustomSelect";
import { changeJaInput } from "redux/actions/advertAction";
import { makeStyles } from "@material-ui/core/styles";
import { EMAIL_REGEX } from "data";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "50%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media (max-width:768px)"]: {
      width: "100%",
      padding: 0,
      display: "block",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const FormOne = ({ token }) => {
  const classes = useFormStyles();
  const selectClasses = useFormStyles();
  const { data } = useSelector((state) => state.preregReducer);
  const dispatch = useDispatch();
  const [notValid, setNotValid] = useState(false);
  const fileInput = useRef(null);
  const [attachment, setAttachment] = useState();

  const {
    service,
    urgency,
    find_id,
    additional_ccEmail,
    additional_contact_number,
    subject,
    details,
    like_tobe_done,
    additional_info,
    send_copy,
    other,
  } = data;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changePreRegInput(inputData));
  };

  const handleAttachment = (e) => {
    setAttachment(e.target.files[0]);
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleChecked = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: checked,
    };
    dispatch(changePreRegInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changePreRegInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleMobile = (value) => {
    dispatch(
      changePreRegInput({
        name: "additional_contact_number",
        value,
      })
    );
  };

  const isAllow =
    !additional_info ||
    !service ||
    !urgency ||
    !like_tobe_done ||
    !subject ||
    !details ||
    !send_copy ||
    (service.includes("Timesheet") && !find_id) ||
    (service.includes("Invoice") && !find_id) ||
    (service.includes("Payment") && !find_id) ||
    (service.includes("Billing") && !find_id) ||
    (service.includes("Contact") && !find_id) ||
    (service.includes("Project") && !find_id) ||
    (service.includes("Complaint") && !other) ||
    (service.includes("Assignment") && !find_id) ||
    (service.includes("System") && !other) ||
    (service.includes("Other") && !other);

  const handleSubmit = async (e) => {
    let fd = new FormData();

    fd.append("find_id", find_id);
    fd.append("additional_ccEmail", additional_ccEmail || "-");
    fd.append("additional_contact_number", additional_contact_number || "-");
    fd.append("subject", subject);
    fd.append("details", details);
    fd.append("like_tobe_done", like_tobe_done);
    fd.append("service", service);
    fd.append("urgency", urgency);
    fd.append("additional_info", additional_info || "-");
    fd.append("attachment", attachment);
    fd.append("other", other || "-");
    fd.append("send_copy", send_copy);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguist/create-new-ticket-LIN`,
        fd,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `Ticket successfully created!!!`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/linguist/open-new-ticket";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `Something wrong!`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <form className={` form ${classes.form}`} noValidate autoComplete="off">
      <CustomSelect
        onChange={handleChange}
        label="After service client care"
        data={[
          "Timesheet / Project Report",
          "Invoice",
          "Payment",
          "Refund",
          "Contact Client",
          "Billing",
          "Contact Linguist",
          "Project",
          "Complaint or Compliment",
          "Assignment",
          "Technical Support",
          "Other",
        ]}
        width="100%"
        name="service"
        value={service}
        required
      />

      {service == "Timesheet / Project Report" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={find_id}
          name="find_id"
          label="Enter Assignment Reference Number"
          width="100%"
          required
        />
      ) : null}

      {service == "Invoice" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={find_id}
          name="find_id"
          label="Enter Invoice Number"
          width="100%"
          required
        />
      ) : null}

      {service == "Payment" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={find_id}
          name="find_id"
          label="Job, Quote or Invoice Reference Number"
          width="100%"
          required
        />
      ) : null}

      {service == "Refund" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={find_id}
          name="find_id"
          label="Job, Quote or Invoice Reference Number"
          width="100%"
          required
        />
      ) : null}

      {service == "Project" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={find_id}
          name="find_id"
          label="Job Or Project Reference Number"
          width="100%"
          required
        />
      ) : null}

      {service == "Contact Client" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={find_id}
          name="find_id"
          label="Client Reference Number"
          width="100%"
          required
          placeholder="CAC1000..."
        />
      ) : null}

      {service == "Contact Linguist" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={find_id}
          name="find_id"
          label="Linguist Reference Number"
          width="100%"
          required
          placeholder="LIN/PRO..."
        />
      ) : null}

      {service == "Billing" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={find_id}
          name="find_id"
          label="Bill or BAC ref number"
          width="100%"
          required
        />
      ) : null}

      {service == "Assignment" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={find_id}
          name="find_id"
          label="Enter Assignment Reference Number"
          width="100%"
          required
        />
      ) : null}

      {service == "Complaint or Compliment" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={other}
          name="other"
          label="Your Reference"
          width="100%"
          required
        />
      ) : null}

      {service == "Technical Support" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={other}
          name="other"
          label="Your Reference"
          width="100%"
          required
        />
      ) : null}

      {service == "Other" ? (
        <CustomInputSingle
          onChange={handleChange}
          value={other}
          name="other"
          label="Other details..."
          width="100%"
          required
        />
      ) : null}

      <CustomInputSingle
        onChange={handleEmailChange}
        value={additional_ccEmail}
        name="additional_ccEmail"
        label="Additional Contact Email"
        width="100%"
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}

      <br />

      <CustomPhone
        onChange={handleMobile}
        value={additional_contact_number}
        width="100%"
        label="Additional Contact Number"
        name="additional_contact_number"
      />

      <CustomInputSingle
        onChange={handleChange}
        value={subject}
        name="subject"
        label="Subject"
        width="100%"
        required
      />
      <br />
      <Form>
        <TextArea
          onChange={handleChange}
          value={details}
          name="details"
          placeholder="Please provide details and any evidence here*"
          width="100%"
          style={{ minHeight: 100 }}
          required
        />
      </Form>
      <CustomInputSingle
        onChange={handleChange}
        value={like_tobe_done}
        name="like_tobe_done"
        label="*What would you like to be done to resolve this issue?"
        width="100%"
        required
      />

      <CustomRadio
        onChange={handleChange}
        value={additional_info}
        name="additional_info"
        data={[
          "Relevant Linguist",
          "Relevant Client",
          "Client Care Admin / Manager Only",
          "Linguist and LC Admin / Manager",
        ]}
        label="Should this matter be referred to:"
        width="100%"
        required
      />

      <CustomSelect
        onChange={handleChange}
        label="Urgency"
        data={["Low", "Medium", "High", "Urgent"]}
        width="100%"
        name="urgency"
        value={urgency}
        required
      />

      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "24px" }}
      >
        <input
          name="identification_doc"
          onChange={handleAttachment}
          type="file"
          style={{ display: "none" }}
          accept=".doc,.pdf,.jpg,.png,.gif,.zip,.rar,.txt,.log,.xml"
          ref={fileInput}
        />
        <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
          Attach a document to this ticket
        </Header>
        <div>
          <Button onClick={handleClick}>Upload</Button>
          <p>
            Allowed File Extensions: doc, docx, xml, png, gif, jpg, jpeg, zip,
            rar, pdf, txt, log
          </p>
        </div>
        {attachment && (
          <Header style={{ margin: "0", textAlign: "center" }} as="h5">
            {attachment.name}
            <Header style={{ margin: "0" }} as="h5">{`Size: ${(
              attachment.size / 1024
            ).toFixed(2)}kb`}</Header>
            <Header
              style={{ margin: "0" }}
              as="h5"
            >{`Last Modified: ${attachment?.lastModifiedDate?.toDateString()}`}</Header>
          </Header>
        )}
        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChecked}
                checked={send_copy}
                name="send_copy"
                value="yes"
                color="primary"
              />
            }
            label="Send a copy to me*"
          />
        </div>

        <div className={classes.buttonFlex}>
          <CustomButton
            onClick={handleSubmit}
            disabled={isAllow}
            text="Submit"
          />
        </div>
      </div>
    </form>
  );
};

export default FormOne;
