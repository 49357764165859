import React, { useEffect, useState, useRef } from "react";
import Items from "./TableBody";

//Style
import "./style.css";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Rating } from "@material-ui/lab";
import CustomTextInput from "components/Common/CustomTextInput";
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import TimesheetFooter from "./ItemsTwo";
import CustomSnackBar from "components/Common/CustomSnackBar";
import { useSelector } from "react-redux";

const Details = () => {
  const { id } = useParams();
  const { data } = useSelector((state) => state.timesheetReducer);
  const [details, setDetails] = useState(null);
  const uploadRef = useRef();
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const token = window.location.pathname.includes("linguist")
    ? Cookies.get("LINtoken")
    : window.location.pathname.includes("cash-client")
    ? Cookies.get("CACtoken")
    : window.location.pathname.includes("account-client")
    ? Cookies.get("BACtoken")
    : window.location.pathname.includes("admin") && Cookies.get("token");

  const [submit, setSubmit] = useState({
    toast: false,
    loader: false,
  });

  const { start_date, end_date, duration, start_time, end_time } = data;
  const handleSubmit = async () => {
    setSubmit({ ...submit, loader: true });
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("end_time", end_time);
    fd.append("start_time", start_time);
    fd.append("actual_duration", duration);
    if (uploadTimesheet?.size) {
      fd.append("document", uploadTimesheet);
    }
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/edit-timesheet/timesheetID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubmit({ ...submit, loader: false, toast: true });
      window.location = "/linguist/current-assignment";
    } catch (error) {
      setSubmit({ ...submit, loader: false });
      console.log(error);
    }
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getTimeSheet = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/view-details-timesheet/timesheetID/${id}`
        );
        setDetails(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    getTimeSheet();

    return () => {
      source.cancel();
    };
  }, []);

  return details ? (
    <div>
      <div style={{ width: "100%", marginTop: "24px" }}>
        <CustomSnackBar
          message="Timesheet updated"
          handleClose={() => setSubmit({ ...submit, toast: false })}
          openSnackBar={submit.toast}
        />
        <CustomTextInput
          label="Job Reference Number"
          value={
            details.cacID?.id
              ? details.cacID?.id
              : details.bacID?.id
              ? details.bacID?.id
              : details.inhouseID?.id
              ? details.inhouseID?.id
              : "N/A"
          }
        />

        <Items details={details} />

        <CustomTextInput
          label="Instructions for linguist professional"
          value=""
        />

        {/* Address details */}
        {details.cacID?.remote_address
          ? details.cacID?.remote_address
          : details.bacID?.remote_address
          ? details.bacID?.remote_address
          : details.inhouseID?.remote_address && (
              <CustomTextInput
                label="Remote Address"
                value={
                  details.cacID?.remote_address
                    ? details.cacID?.remote_address
                    : details.bacID?.remote_address
                    ? details.bacID?.remote_address
                    : details.inhouseID?.remote_address
                }
              />
            )}

        {!details.cacID?.remote_address ? (
          !details.cacID?.remote_address
        ) : !details.bacID?.remote_address ? (
          !details.bacID?.remote_address
        ) : !details.inhouseID?.remote_address ? (
          <>
            <CustomTextInput
              label="House Number / Street Name"
              value={
                details.cacID?.house_number_street_name
                  ? details.cacID?.house_number_street_name
                  : details.bacID?.house_number_street_name
                  ? details.bacID?.house_number_street_name
                  : details.inhouseID?.house_number_street_name
                  ? details.inhouseID?.house_number_street_name
                  : "N/A"
              }
            />

            <CustomTextInput
              label="Address Line 2"
              value={
                details.cacID?.address_line_two
                  ? details.cacID?.address_line_two
                  : details.bacID?.address_line_two
                  ? details.bacID?.address_line_two
                  : details.inhouseID?.address_line_two
                  ? details.inhouseID?.address_line_two
                  : "N/A"
              }
            />

            <CustomTextInput
              label="County / Region"
              value={
                details.cacID?.region
                  ? details.cacID?.region
                  : details.bacID?.region
                  ? details.bacID?.region
                  : details.inhouseID?.region
                  ? details.inhouseID?.region
                  : "N/A"
              }
            />

            <CustomTextInput
              label="Post Code"
              value={
                details.cacID?.post_code
                  ? details.cacID?.post_code
                  : details.bacID?.post_code
                  ? details.bacID?.post_code
                  : details.inhouseID?.post_code
                  ? details.inhouseID?.post_code
                  : "N/A"
              }
            />

            <CustomTextInput
              label="Country"
              value={
                details.cacID?.country
                  ? details.cacID?.country
                  : details.bacID?.country
                  ? details.bacID?.country
                  : details.inhouseID?.country
                  ? details.inhouseID?.country
                  : "N/A"
              }
            />
          </>
        ) : null}
        <CustomTextInput
          label="Court case, police, NHS ref"
          value={
            details.cacID?.security_clearance
              ? details.cacID?.security_clearance
              : details.bacID?.security_clearance
              ? details.bacID?.security_clearance
              : details.inhouseID?.security_clearance
              ? details.inhouseID?.security_clearance
              : "N/A"
          }
        />

        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
        >
          <Header style={{ margin: "0" }} as="h4">
            Feedback to client
          </Header>
          <div>
            <Rating
              name="simple-controlled"
              value={details.feedback}
              readOnly
            />
          </div>
        </div>

        <p>
          Customer / Client Name:
          <b>
            {details.cacID?.postedBy?.FirstName
              ? details.cacID?.postedBy?.FirstName
              : details.bacID?.postedBy?.FirstName
              ? details.bacID?.postedBy?.FirstName
              : details.inhouseID?.ClientID?.FirstName
              ? details.inhouseID?.ClientID?.FirstName
              : "N/A"}
          </b>
          <b>
            {details.cacID?.postedBy?.LastName
              ? details.cacID?.postedBy?.LastName
              : details.bacID?.postedBy?.LastName
              ? details.bacID?.postedBy?.LastName
              : details.inhouseID?.ClientID?.LastName
              ? details.inhouseID?.ClientID?.LastName
              : "N/A"}
          </b>
        </p>

        <CustomTextInput
          label="Customer / Client ref no."
          value={
            details.cacID?.postedBy?.id
              ? details.cacID?.postedBy?.id
              : details.bacID?.postedBy?.id
              ? details.bacID?.postedBy?.id
              : details.inhouseID?.ClientID?.id
              ? details.inhouseID?.ClientID?.id
              : "N/A"
          }
        />

        <CustomTextInput
          label="Emergency Contact no."
          value={
            details.cacID?.postedBy?.mobile_no
              ? details.cacID?.postedBy?.mobile_no
              : details.bacID?.postedBy?.mobile_no
              ? details.bacID?.postedBy?.mobile_no
              : details.inhouseID?.ClientID?.mobile_no
              ? details.inhouseID?.ClientID?.mobile_no
              : "N/A"
          }
        />

        <CustomTextInput label="Name of limited speaker conference" value="" />
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
        >
          <Header style={{ margin: "0" }} as="h4">
            Feedback to linguist
          </Header>
          <div>
            <Rating
              name="simple-controlled"
              value={details.feedbackByClient}
              readOnly
            />
          </div>
        </div>

        <TimesheetFooter details={details} />
      </div>

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button onClick={() => uploadRef.current.click()} basic color="purple">
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <div style={{ padding: "10px" }}>
          <Button
            loading={submit.loader}
            disabled={
              submit.loader ||
              !duration ||
              start_date === [""] ||
              start_time === [""]
            }
            onClick={handleSubmit}
            style={{ backgroundColor: "purple", color: "white" }}
          >
            Amend Timesheet
          </Button>
        </div>
        {submit.loader && (
          <div style={{ padding: "10px" }}>
            <Dimmer>
              <Loader active inline />
            </Dimmer>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
