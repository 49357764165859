import React from "react";

// Material Core Components
import FormControl from "@material-ui/core/FormControl";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

const CustomFormControl = ({ width, children, ...rest }) => {
  const classes = useFormStyles({
    width,
  });
  return (
    <FormControl {...rest} className={classes.formControl}>
      {children}
    </FormControl>
  );
};

export default CustomFormControl;
