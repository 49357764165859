import { Fragment, useState } from "react";
import swal from "sweetalert";
import defaultAxios from "utils/axios";
import axios from "axios";
import Cookie from "js-cookie";
import { PASS_REGEX } from "data";

// Material Components
import Button from "@material-ui/core/Button";
import CustomPassword from "components/Common/CustomPassword";

const ResetPassword = () => {
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
    oldPassword: "",
  });
  const [notRegex, setNotRegex] = useState(false);

  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };
  const handleNewChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
    if (e.target.value !== "" && PASS_REGEX.test(e.target.value)) {
      setNotRegex(false);
    } else {
      setNotRegex(true);
    }
  };
  const handleSubmit = async () => {
    const { password, confirmPassword, oldPassword } = passwords;
    if (password !== confirmPassword) {
      swal({
        title: "Oops!",
        text: `Password and Confirm Password must be same`,
        icon: "error",
        buttons: false,
      });
      return;
    }

    const token = Cookie.get("CACtoken");

    const { username } = JSON.parse(localStorage.getItem("CACuser"));

    // const sendData = { password, oldPassword, newPassword: confirmPassword };
    const sendData = new FormData();
    sendData.append("password", password);
    sendData.append("oldPassword", oldPassword);
    sendData.append("newPassword", confirmPassword);

    setLoading(true);

    try {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      };
      // Call api
      const response = await defaultAxios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/passwordUpdate/${username}`,
        sendData,
        config
      );

      // either message or redirect
      swal({
        title: "",
        text: `Password updated successfully`,
        icon: "success",
        buttons: false,
      }).then(function () {
        Cookie.remove("CACtoken");
        window.location = "/cash-client-login";
      });

      setPasswords({
        password: "",
        confirmPassword: "",
        oldPassword: "",
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);

      // Copy from other catch's code
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <Fragment>
      <CustomPassword
        label="Old Password"
        name="oldPassword"
        value={passwords.oldPassword}
        onChange={handleChange}
        placeholder="Your old password e.g. Ex@mPle012345 "
      />
      <CustomPassword
        label="New Password"
        name="password"
        value={passwords.password}
        onChange={handleNewChange}
        notRegex={notRegex}
        placeholder="e.g. Ex@mPle012345"
      />
      <CustomPassword
        label="Confirm New Password"
        name="confirmPassword"
        value={passwords.confirmPassword}
        onChange={handleChange}
        validation={passwords.password !== passwords.confirmPassword}
        placeholder="e.g. Ex@mPle012345"
      />
      <Button
        color="primary"
        variant="contained"
        style={{
          marginTop: "15px",
        }}
        onClick={handleSubmit}
        disabled={
          !passwords.password ||
          !passwords.confirmPassword ||
          !passwords.oldPassword ||
          loading ||
          notRegex ||
          passwords.password !== passwords.confirmPassword
        }
      >
        {loading ? "Submitting..." : "Submit"}
      </Button>
    </Fragment>
  );
};

export default ResetPassword;
