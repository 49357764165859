import SearchInput from "./SearchInput";


const SearchView = ({ setSearchText, setShow, SearchText, handleSearch, load }) => {

  return (
    <div>
      <SearchInput load={load} setSearchText={setSearchText} setShow={setShow} SearchText={SearchText} handleSearch={handleSearch} />
     
    </div>
  );
};

export default SearchView;
