import React, {  } from "react";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Cookies from "js-cookie";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import { CustomButton } from "components/AccountClient/Report/style";
import Axios from "axios";

const BIPayment = () => {
  const classes = useEmployeeStyles();
  const { userData } = useSelector((state) => state.mangopayReducer);
  const { data } = useSelector((state) => state.bookingReducer);
  const { username } = JSON.parse(localStorage.getItem("CACuser"));

  const currency_new = Cookies.get("currency_new_card");

  //id
  const id = Cookies.get("cacBookingID");
  const new_total = Cookies.get("new_total");

  const token = Cookies.get("CACtoken");

  Axios.post(
    `${process.env.REACT_APP_BACKEND_API}/cashclient/cashclientinterpreting/sendMailSTRIPE`,
    { currency_new, new_total, username, id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <div className={`${classes.title}`}>
                <Typography component="h1" variant="h5">
                  Order {id} Successful!
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "20px" }}
                  src="https://www.cntraveller.in/wp-content/themes/cntraveller/images/check-circle.gif"
                  alt=""
                />
                <Typography component="h1" variant="h5">
                  Your order is confirmed
                </Typography>
              </div>
              <div>
                <Typography variant="h5">
                  Check your dashboard and email for more details
                </Typography>
              </div>
              <CustomButton
                text="Dashboard"
                // onClick= {handleChange}
                width="100%"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default BIPayment;
