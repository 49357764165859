import MeetingsPage from "pages/management/MeetingsPage";
import NoticePage from "pages/management/NoticePage";

const MANAGEMENT_PANEL_ROUTES = [
  {
    id: 21,
    text: "Notice Page",
    path: "notice",
    component: NoticePage,
  },
  {
    id: 22,
    text: "Meetings Page",
    path: "meetings",
    component: MeetingsPage,
  },
];

export default MANAGEMENT_PANEL_ROUTES;
