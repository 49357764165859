import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// Secong Segment
// import ChatComponent from "components/Admin/Dashboard/SecondSegment/ChatComponent";

// Third Segment
// import TaskLists from "components/Admin/Dashboard/ThirdSegment/TaskLists";

import BookingList from "components/Admin/Dashboard/JobLists/Booking";
import TranslationList from "components/Admin/Dashboard/JobLists/Translation";
import QuotationList from "components/Admin/Dashboard/JobLists/Quotation";

// Fourth Segment
import Alerts from "components/Admin/Dashboard/FourthSegment/Alerts";
import MyNotes from "components/Admin/Dashboard/FourthSegment/MyNotes";
import Bookings from "./Bookings/Bookings";

const Dashboard = () => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <BookingList />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <TranslationList />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <QuotationList />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <Bookings />
          </GridItem>
          <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <Alerts />
          </GridItem>
          <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <MyNotes />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default Dashboard;
