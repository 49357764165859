import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import List from "components/Admin/AccountFinance/SPPO/DirectInvoice/ListInvoice";

const ConfirmAssignment = () => {
  const classes = useDashboardStyles();
 
  return (
    <Manager>
      <MasterLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  SPPO Direct Invoices
                </p>
                <IconButton
                  color="inherit"
                  style={{ marginLeft: 5 }}
                  onClick={() => history.goBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardBody>
                <List />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </MasterLayout>
    </Manager>
  );
};

export default ConfirmAssignment;
