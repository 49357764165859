import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CustomModal from "components/Common/CustomModal";
import CustomTextInput from "components/Common/CustomTextInput";
import Cookies from "js-cookie";
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Header, Icon, Loader, Modal } from "semantic-ui-react";
import swal from "sweetalert";
import Axios from "axios";

const Action = ({ id, type, cancel, load }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("LINtoken");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const deleteInvoice = async (value) => {
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/linguists/private-invoice-remove/invoiceID/${id}`
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      })
      load()
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure you want to delete this?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          deleteInvoice(value);

        default:
          break;
      }
    });
  };

  const archiveInvoice = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/private-invoice-archive/invoiceID/${id}`
      );

      swal({
        icon: "success",
        text: `Invoice moved to the archive folder.`,
        buttons: false,
      })
      load()
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleArchive = async (value) => {
    swal("Are you sure you want to move to archive folder?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          archiveInvoice(value);

        default:
          break;
      }
    });
  };

  const cancelInvoice = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/private-invoice-cancel/invoiceID/${id}`
      );

      swal({
        icon: "success",
        text: `Invoice cancelled.`,
        buttons: false,
      })
      load()
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCancel = async (value) => {
    swal("Are you sure you want to cancel this invoice?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          cancelInvoice(value);

        default:
          break;
      }
    });
  };

  const resendInvoice = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/send/private-client-invoice/invoiceID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: `Invoice successfully sent to the client.`,
        buttons: false,
      })
      load()
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleResend = async (value) => {
    swal("Are you sure you want to resend this invoice?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          resendInvoice(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* delete only for the saved prc direct invoice */}

        {type ? (
          <>
            <MenuItem onClick={handleResend}>Send</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </>
        ) : (
          <>
            {cancel ? (
              <MenuItem onClick={handleArchive}>Archive</MenuItem>
            ) : (
              <>
                <MenuItem onClick={handleCancel}>Cancel</MenuItem>
                <MenuItem onClick={handleResend}>Resend</MenuItem>
              </>
            )}
          </>
        )}
      </Menu>
    </div>
  );
};

export default Action;
