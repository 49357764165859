import React, { Fragment } from "react";

// Custom Components
import LCJoining from "components/Auth/LCJoining/JoinPageWithForm/LCJoining1";

const LCJoiningPage = () => {
  return (
    <Fragment>
      <LCJoining />
    </Fragment>
  );
};

export default LCJoiningPage;
