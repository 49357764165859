import EmailActivationSPPO from "pages/sppo-page/EmailActivationSPPO";
import SPPORegistrationPage from "pages/SPPORegistration";

const SPPOR = [
  {
    id: 600,
    type: "form",
    text: "SPPORegistration",
    path: "sppor-form",
    component: SPPORegistrationPage,
  },
  {
    id: 601,
    type: "form",
    text: "SPPORegistration",
    path: "sppo/additional-user-registration/:id",
    component: SPPORegistrationPage,
  },
  {
    id: 600,
    type: "form",
    text: "SPPORegistration",
    path: "sppo/auth/account/activate/:token",
    component: EmailActivationSPPO,
  },
];

export default SPPOR;
