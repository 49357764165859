import React, { useState, useEffect } from "react";
import EmployeeLayout from "layout/EmployeeLayout";
import Employee from "components/RouteProtection/Employee/Employee";

import Cookies from 'js-cookie'
import Axios from 'utils/axios'

// Custom Components
import LinguistsCollectiveBookingTel from "components/Auth/IBT/LinguistsCollectiveBooking";

const IBTPage = () => {

  const [data, setData] = useState(null)

  const getAdminDetails = async (e) => {
    const token = Cookies.get("EMPtoken");
    const response = await Axios.get(
      "/api/employee/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setData(response?.data);
  };
  useEffect(() => {
    getAdminDetails();
  }, []);

  return (

    <EmployeeLayout>
      <Employee>
        <LinguistsCollectiveBookingTel adminID={data?.id} adminName={data?.FirstName} />
      </Employee>
    </EmployeeLayout>

  );
};

export default IBTPage;
