import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SppoLayout from "layout/SppoLayout";
import Sppo from "components/RouteProtection/Sppo/Sppo";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import SendForm from "components/Sppo/Leads/SendClaForm/SendClaForm";
import history from "utils/history";

const SendCLARegistrationForm = () => {
  const classes = useDashboardStyles();
  return (
    <SppoLayout>
      <Sppo>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>
                  Send Lead registration form
                </p>
                <i
                  className="icon list arrow left"
                  onClick={() => {
                    history.goBack();
                  }}
                ></i>
              </CardHeader>
              <CardBody>
                <SendForm />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Sppo>
    </SppoLayout>
  );
};

export default SendCLARegistrationForm;
