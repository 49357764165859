import React, { useState, useEffect } from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Cookies from "js-cookie";
import Axios from "axios";
import { Header } from "semantic-ui-react";
import ACLinguistsCollective from "components/Auth/BookingForm/BACTranslation/LinguistsCollective";

const UnconfirmedAssignmentsPage = () => {
  const [translation_access, setAssignmentAccess] = useState(null);
  const [bacUser, setbacUser] = useState(null);

  const getDetails = async (e) => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/accountclient/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAssignmentAccess(response?.data.translation_access);
    setbacUser(response?.data.bacUser);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Accountclient>
      <AccountClientLayout>
        <GridContainer>
          {translation_access ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <ACLinguistsCollective />
            </GridItem>
          ) : bacUser === false ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <ACLinguistsCollective />
            </GridItem>
          ) : translation_access === false ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default UnconfirmedAssignmentsPage;
