// import { de } from "date-fns/locale";

export const GET_CURRENCY_RATES = "GET_CURRENCY_RATES";
export const SELECTED_CURRENCY = "SELECTED_CURRENCY";
export const SET_CURRENCY = "SET_CURRENCY";

//Auth types
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const GET_USER_DETAILS = "GET_USER_DETAILS";

// Employee Registration
export const UPDATE_ER_STEP = "UPDATE_ER_STEP";
export const CHANGE_ER_INPUT = "CHANGE_ER_INPUT";
export const ER_SUBMIT = "ER_SUBMIT";
export const ER_LOGIN = "ER_LOGIN";
export const AUTO_SAVE_ER = "AUTO_SAVE_ER";

// Custom Registration
export const UPDATE_CR_STEP = "UPDATE_CR_STEP";
export const CHANGE_INPUT = "CHANGE_INPUT";
export const CR_SUBMIT = "CR_SUBMIT";
export const SET_LOADING = "SET_LOADING";
export const CR_LOGIN = "CR_LOGIN";

// Translation
export const UPDATE_TRANSLATION_STEP = "UPDATE_TRANSLATION_STEP";
export const CHANGE_TRANSLATION_INPUT = "CHANGE_TRANSLATION_INPUT";

// AMMEND
export const AMEND_TRANSLATION_STEP = "AMEND_TRANSLATION_STEP";
export const AMEND_TRANSLATION_INPUT = "AMEND_TRANSLATION_INPUT";
//Account client translation
// Translation
export const AC_UPDATE_TRANSLATION_STEP = "AC_UPDATE_TRANSLATION_STEP";
export const AC_CHANGE_TRANSLATION_INPUT = "AC_CHANGE_TRANSLATION_INPUT";

// Booking interpreter
export const UPDATE_BOOKING_STEP = "UPDATE_BOOKING_STEP";
export const CHANGE_BA_INPUT = "CHANGE_BA_INPUT";

// ACCOUNT CLIENT Booking interpreter
export const ACCOUNT_UPDATE_BOOKING_STEP = "ACCOUNT_UPDATE_BOOKING_STEP";
export const ACCOUNT_CHANGE_BA_INPUT = "ACCOUNT_CHANGE_BA_INPUT";

// Linguist Collective Joining
export const LC_LOGIN = "LC_LOGIN";
export const UPDATE_LC_STEP = "UPDATE_LC_STEP";
export const CHANGE_LC_INPUT = "CHANGE_LC_INPUT";
export const AUTO_SAVE = "AUTO_SAVE";
export const UPDATE_PROFILE_LINGUIST = 'UPDATE_PROFILE_LINGUIST'
export const UPDATE_PROFILE_LINGUIST_TWO = 'UPDATE_PROFILE_LINGUIST_TWO'
export const UPDATE_PROFILE_LINGUIST_THREE = 'UPDATE_PROFILE_LINGUIST_THREE'
export const UPDATE_PROFILE_LINGUIST_FOUR = 'UPDATE_PROFILE_LINGUIST_FOUR'
export const UPDATE_PROFILE_LINGUIST_FIVE = 'UPDATE_PROFILE_LINGUIST_FIVE'
export const UPDATE_PROFILE_LINGUIST_SIX = 'UPDATE_PROFILE_LINGUIST_SIX'
export const UPDATE_PROFILE_LINGUIST_SEVEN = 'UPDATE_PROFILE_LINGUIST_SEVEN'

// Job Advert
export const CHANGE_JOBADVERT_INPUT = "CHANGE_JOBADVERT_INPUT";
export const UPDATE_ADVERT_STEP = "UPDATE_ADVERT_STEP";

//Quote Request
export const UPDATE_QUOTERQST_STEP = "UPDATE_QUOTERQST_STEP";
export const CHANGE_QUOTERQST_INPUT = "CHANGE_QUOTERQST_INPUT";

//AC quote request
export const AC_UPDATE_QUOTERQST_STEP = "UPDATE_QUOTERQST_STEP";
export const AC_CHANGE_QUOTERQST_INPUT = "CHANGE_QUOTERQST_INPUT";

// Client account opening
export const UPDATE_CLIENTACCOPENING_STEP = "UPDATE_CLIENTACCOUNTOPENING_STEP";
export const CHANGE_CLIENTACCOPENING_INPUT = "CHANGE_CLIENTACCOPENING_INPUT";
export const CHANGE_MB_CURRENCY = "CHANGE_MB_CURRENCY";

// Initial Registration
export const CHANGE_INITIALREG_INPUT = "CHANGE_INITIALREG_INPUT";

// Service Providing Partner Organisation registration
export const UPDATE_SPPOR_STEP = "UPDATE_SPPOR_STEP";
export const CHANGE_SPPOR_INPUT = "CHANGE_SPPOR_INPUT";

// Interpreting Assignment Booking / Telephonic
export const CHANGE_IBT_INPUT = "CHANGE_IBT_INPUT";

// Order translation / Telephonic
export const CHANGE_OTT_INPUT = "CHANGE_OTT_INPUT";

// Quote requuest / Telephonic
export const CHANGE_QRT_INPUT = "CHANGE_QRT_INPUT";

// Payment
export const USER_DATA = "USER_DATA";

// Rate Card API
export const GET_RATES = "GET_RATES";
export const UPDATE_RATE = "UPDATE_RATE";
export const EDIT_CLICK = "EDIT_CLICK";

export const GET_RATE_DETAILS = "GET_RATE_DETAILS";
export const UPDATE_RATE_DETAILS = "UPDATE_RATE_DETAILS";

export const GET_CARP_DETAILS = "GET_CARP_DETAILS";
export const UPDATE_CARP_DETAILS = "UPDATE_CARP_DETAILS";

export const GET_CURRENCY = "GET_CURRENCY";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const POST_CURRENCY = "POST_CURRENCY";
export const GET_LEAD_DETAILS = "GET_LEAD_DETAILS";
export const UPDATE_LEAD_CONTACT_GROUP = "UPDATE_LEAD_CONTACT_GROUP";


// Invoices
export const DESCRIPTION_QUOTATION = "DESCRIPTION_QUOTATION";
export const ADD_DESCRIPTION = "ADD_DESCRIPTION";
export const REMOVE_DESCRIPTION = "REMOVE_DESCRIPTION";

export const EXPENSES_QUOTATION = "EXPENSES_QUOTATION";
export const ADD_EXPENSES = "ADD_EXPENSES";
export const REMOVE_EXPENSES = "REMOVE_EXPENSES";

export const PERCENT_CHANGE = "PERCENT_CHANGE";

// Create invoices types
export const STORE_VALUES = "STORE_VALUES";

//Admin Registration Form
export const CHANGE_ADMINREG_INPUT = "CHANGE_ADMINREG_INPUT";

// Rate Card API
export const GET_PERCENTAGES = "GET_PERCENTAGES";
export const UPDATE_PERCENTAGE = "UPDATE_PERCENTAGE";
export const EDIT_CLICK_PERCENTAGE = "EDIT_CLICK_PERCENTAGE";

//Pre Registration
export const CHANGE_PREREG_INPUT = "CHANGE_PREREG_INPUT";

//percentage card
export const GET_PERCENTAGE_RATES = "GET_PERCENTAGE_RATES";
export const UPDATE_PERCENTAGE_RATE = "UPDATE_PERCENTAGE_RATE";
export const SET_PERCENT = "SET_PERCENT";

//cashclient profile up[date

// Custom Registration
export const CASHCLIENT_UPDATE_CR_STEP = "CASHCLIENT_UPDATE_CR_STEP";
export const CASHCLIENT_CHANGE_INPUT = "CASHCLIENT_CHANGE_INPUT";
export const CASHCLIENT_CR_SUBMIT = "CR_SUBMIT";
export const CASHCLIENT_SET_LOADING = "CASHCLIENT_SET_LOADING";
export const CASHCLIENT_CR_LOGIN = "CASHCLIENT_CR_LOGIN";
// Company Card
export const CHANGE_CONTACT_INPUT = 'CHANGE_CONTACT_INPUT'
export const UPDATE_CONTACT_DATA = 'UPDATE_CONTACT_DATA'

// Inhouse Client Registration
export const CHANGE_INHOUSE_CLIENT_INPUT = 'CHANGE_INHOUSE_CLIENT_INPUT'
export const UPDATE_INHOUSE_CLIENT_STEP = 'UPDATE_INHOUSE_CLIENT_STEP'

export const ACCOUNT_CLIENT_CHANGE_TRANSLATION_INPUT = "ACCOUNT_CLIENT_CHANGE_TRANSLATION_INPUT"
export const ACCOUNT_CLIENT_UPDATE_TRANSLATION_STEP = "ACCOUNT_CLIENT_UPDATE_TRANSLATION_STEP"

//Manager Dashboard (View Unverified Linguist)
export const CHANGE_VERIFIED_ACCESS = 'CHANGE_VERIFIED_ACCESS'
export const CHANGE_PREREG_INPUT_ADMIN = "CHANGE_PREREG_INPUT_ADMIN";

// Multiday booking
export const CHANGE_DATETIME_INPUT = 'CHANGE_DATETIME_INPUT'

// Update linguist profile
export const CHANGE_LINGUIST_PROFILE = 'CHANGE_LINGUIST_PROFILE'
export const UPDATE_LINGUIST_PROFILE = 'UPDATE_LINGUIST_PROFILE'

//Timesheet details
export const CHANGE_TIMESHEET_INPUT = 'CHANGE_TIMESHEET_INPUT'
export const UPDATE_TIMESHEET = 'UPDATE_TIMESHEET'

//Admin dashboard
export const FIND_CLIENT_BILL = 'FIND_CLIENT_BILL'
export const SHOW_CLIENT_BILLS = 'SHOW_CLIENT_BILLS'

//telephonic assignments
export const FIND_TELEPHONIC_BOOKING = 'FIND_TELEPHONIC_BOOKING'
export const VIEW_TELEPHONIC_BOOKING = 'VIEW_TELEPHONIC_BOOKING'

//Expense Reducer
export const ADD_TRANSACTION = 'ADD_TRANSACTION'
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION'
export const ADD_OTHER_TRANSACTION = 'ADD_OTHER_TRANSACTION'
export const DELETE_OTHER_TRANSACTION = 'DELETE_OTHER_TRANSACTION'

//Unconfirm quotations 
export const UPDATE_UNCONFIRM_QUOTES = 'UPDATE_UNCONFIRM_QUOTES'
export const PROVIDED_QUOTES_LIST = 'PROVIDED_QUOTES_LIST'

// linguist clients
export const INHOUSE_CLIENT = 'INHOUSE_CLIENT'
export const PRIVATE_CLIENT = 'PRIVATE_CLIENT'

// Public profile
export const UPDATE_PUBLIC_PROFILE_LINGUIST = 'UPDATE_PUBLIC_PROFILE_LINGUIST'
export const CHANGE_LINGUIST_PUBLIC_PROFILE = 'CHANGE_LINGUIST_PUBLIC_PROFILE'
