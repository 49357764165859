import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";
import swal from "sweetalert";
//Material icons

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import { Dimmer, Header, Loader, Statistic, Table } from "semantic-ui-react";
import { CURRENCY, PAY_OPTIONS } from "data";
import Axios from "utils/axios";
import moment from "moment";
import CustomSemanticDD from "components/Common/CustomSemanticDD";

//Stripe
import StripeLayout from "layout/StripeLayout";
import CheckoutCACInvoice from "layout/CheckoutLayout/CheckoutCACInvoice";
import history from "utils/history";

import { Button } from "semantic-ui-react";

const ViewINCInvoice = () => {
  const classes = useEmployeeStyles();
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);

  const [payOptions, setPayOptions] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [bankDetails, setBankDetails] = useState({
    account_holder_name: "",
    bsb: "",
    account_number: "",
    bic: "",
    iban: "",
    sort_code: "",
    routing_number: "",
    institution_number: "",
    transit_number: "",
    bank_code: "",
  });
  const [convertedCurrency, setConvertedCurrency] = useState(null);

  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
  });

  useEffect(() => {

    const loadInvoice = async () => {
      try {
        const response = await Axios.get(
          `/api/manager-admin/view-client-invoice/invoiceID/${id}`
        );

        setInvoice(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    const getCompanyCard = async () => {
      try {
        const response = await Axios.get(
          "/api/manager-admin/view/company-information-card"
        );

        setCompanyCard({
          ...companyCard,
          pbsc: response?.data[0].platform_booking_service_charge,
          basc: response?.data[0].billing_account_service_charge,
          cname: response?.data[0].company_name,
          caddress: response?.data[0].company_address,
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadInvoice();
    getCompanyCard();

   
  }, []);

  // Handling payments

  const handlePayOptions = async (e) => {
    setPayOptions(e.target.value);
    if (e.target.value === "Marketplace speedy payment") {
      setBankDetails({
        ...bankDetails,
        bank_code: "",
        account_number: "",
        account_holder_name: "",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      setCurrency(null);
    }
  };

  const handleCurrency = async (e) => {
    setCurrency(e.target.value);
    const value = e.target.value;
    if (value === "AUD (Australia)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        bsb: "802-985",
        account_number: "918630266",
        bic: "",
        sort_code: "",
        iban: "",
        routing_number: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `AUD $ ${(
            (response?.data?.rates?.AUD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "EUR (EU)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        bic: "TRWIBEB1XXX",
        iban: "BE13 9671 5755 4739",
        bsb: "",
        account_number: "",
        sort_code: "",
        routing_number: "",
        bank_code: "",
        institution_number: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `EUR € ${(
            (response?.data?.rates?.EUR * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "GBP (UK)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        sort_code: "23-14-70",
        iban: "GB51 TRWI 2314 7058 5979 53",
        account_number: "",
        bsb: "",
        bic: "",
        routing_number: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `GBP £ ${(
            (response?.data?.rates?.GBP * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "TRY (Turkish lira)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        iban: "TR69 0010 3000 0000 0033 8634 34",
        sort_code: "",
        bsb: "",
        account_number: "",
        bic: "",
        routing_number: "",
        bank_code: "",
        institution_number: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `TRY ₺ ${(
            (response?.data?.rates?.TRY * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "USD (USA)") {
      setBankDetails({
        ...bankDetails,
        account_number: "9600000000264428",
        account_holder_name: "Linguists Collective Ltd",
        routing_number: "084009519",
        sort_code: "",
        bsb: "",
        bic: "",
        iban: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `USD $ ${(
            (response?.data?.rates?.USD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "CAD (Canada)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        institution_number: "621",
        account_number: "200110001176",
        transit_number: "16001",
        routing_number: "",
        bic: "",
        bsb: "",
        sort_code: "",
        iban: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `CAD $ ${(
            (response?.data?.rates?.CAD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "HUF (Hungarian forint)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        account_number: "12600016-17943734-02621164",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `HUF $ ${(
            (response?.data?.rates?.HUF * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "NZD (New Zealand dollar)") {
      setBankDetails({
        ...bankDetails,
        account_number: "02-1290-0578525-000",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
        bank_code: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `NZD $ ${(
            (response?.data?.rates?.NZD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "RON (Romanian leu)") {
      setBankDetails({
        ...bankDetails,
        bank_code: "BRELROBUXXX",
        account_number: "RO73 BREL 0005 4000 8848 0100",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `lei ${(
            (response?.data?.rates?.RON * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    } else if (value === "SGD (Singapore dollar)") {
      setBankDetails({
        ...bankDetails,
        bank_code: "7171",
        account_number: "885-074-585-39",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      try {
        const response = await Axios.get(
          `/api/currency-exchange`
        );

        setConvertedCurrency(
          `SGD $ ${(
            (response?.data?.rates?.SGD * invoice?.grand_total) /
            response?.data?.rates[invoice?.currency?.substring(0, 3)]
          ).toFixed(2)}`
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const BankPayment = async (e) => {
    try {
      const response = await Axios.put(
        `/api/client-or-sppo/bank-pay-direct-invoice/invID/${id}`
      );
      swal({
        icon: "success",
        text: response?.data.message,
        buttons: "Ok",
      }).then(function () {
        window.location = "/";
      });
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: error.response?.data.raw.message,
        buttons: "Ok",
      });
    }
  };

  const handleBankPay = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          BankPayment(value);
          break;
        default:
          break;
      }
    });
  };

  return invoice ? (
    <div className={classes.root}>
      <GridContainer justify="center" className={classes.container}>
        <GridItem id="printArea" xs={12} sm={12} md={12} lg={8} xl={8}>
          <Card>
            <CardBody>
              {/* Avatar and company name */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "24px",
                }}
              >
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  {Number(invoice?.grand_total) === 0 ? (
                    <Statistic color="green">
                      <Statistic.Value text>
                        PAID
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : Number(invoice?.grand_total) < 0 ? (
                    <Statistic color="purple">
                      <Statistic.Value text>
                        OVERPAID
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : invoice.status === "PAID" ? (
                    <Statistic color="green">
                      <Statistic.Value text>
                        {invoice.status}
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : invoice.status === "Paid in full" ? (
                    <Statistic color="green">
                      <Statistic.Value text>
                        {invoice.status}
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  ) : (
                    <Statistic color="red">
                      <Statistic.Value text>
                        {invoice.status}
                        <br />
                      </Statistic.Value>
                    </Statistic>
                  )}
                  <CustomAvatar style={{ margin: "0", marginLeft: "10px" }} />
                </div>
              </div>

              {/* First div  */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice.client_firstname + " " + invoice.client_lastname}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_addressline_one}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_addressline_two}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_city}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_region}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_postalcode}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_country}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.client_email}
                    </Header>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice number:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.id}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      User ID:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.postedByID
                        ? invoice?.postedByID
                        : invoice?.userID}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Company Residence:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.company_address}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      VAT/GST no:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice?.vat_lc}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of quote:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {invoice?.date_of_quote ? invoice?.date_of_quote : "N/A"}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Date of invoice:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.date_of_invoice}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Invoice to be paid by:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;
                      {moment(invoice.invoice_tobe_paid_by).format(
                        "DD/MMM/YYYY HH:mm"
                      )}
                    </Header>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Service completion date:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.service_completion_date}
                    </Header>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Linguist/SP ref:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.linguist_ref}
                    </Header>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Service:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;{invoice.service}
                  </Header>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Source language:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.directQuoteID?.source_language
                      ? invoice?.directQuoteID?.source_language
                      : invoice?.source_language}
                  </Header>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Target language:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.directQuoteID?.target_language
                      ? invoice?.directQuoteID?.target_language
                      : invoice.target_language}
                  </Header>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header style={{ margin: "0" }} as="h4">
                    Expertise required:
                  </Header>
                  <Header color="grey" style={{ margin: "0" }} as="h4">
                    &nbsp;
                    {invoice?.directQuoteID?.skill_level
                      ? invoice?.directQuoteID?.skill_level
                      : invoice.skill_level}
                  </Header>
                </div>
              </div>

              <Table color="pink">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="5">
                      Description of service
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">Price</Table.HeaderCell>
                    <Table.HeaderCell width="1">Quantity</Table.HeaderCell>
                    <Table.HeaderCell width="1">Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {invoice.productOne.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.text}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.amount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.quantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ))}

                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header textAlign="right" as="h3">
                        Sub Total
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.subtotal1}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Expenses and travel</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>

                  {invoice.productTwo.map((tr) => (
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h4">{tr.otherText}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherAmount}</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4">{tr.otherQuantity}</Header>
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Header style={{ margin: "0" }} as="h4">
                          {tr.total}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ))}

                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h3">Sub Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.subtotal2}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {Number(invoice.subtotal1) +
                            Number(invoice.subtotal2)}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Discount ({invoice.discount_percentage})%
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.discount_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Billing Account Service Charge{" "}
                        {invoice.bac_service_charge}%
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.bac_service_charge_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Platform and booking service charge{" "}
                        {invoice.service_charge} %
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.service_charge_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">VAT ({invoice.vat_percentage})%</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.vat_amount}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Total</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.grand_total}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">Estimate paid in advance</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}
                          {invoice?.estimate_paid_in_advance
                            ? invoice?.estimate_paid_in_advance
                            : "00.00"}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign>
                      <Header as="h4">
                        Total to be paid including VAT/GST
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Statistic size="mini">
                        <Statistic.Value>
                          {invoice.currency?.substring(0, 3)}{" "}
                          {invoice.grand_total}
                        </Statistic.Value>
                      </Statistic>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Header style={{ marginTop: "16px" }} as="h4">
                  Thank You For Your Business
                </Header>
                <Header
                  color="grey"
                  style={{ marginBottom: "16px", marginTop: "0" }}
                  as="h4"
                >
                  {invoice?.company_name + ", "+ invoice?.company_address}
                </Header>

                {Number(invoice?.grand_total) < 0 ? (
                  <>
                    <p style={{ color: "red" }}>
                      Negative value!! Please request refund of the amount shown
                      (-) in 'Total to be paid including VAT/GST', unless you
                      have been already issued with the refund.
                    </p>
                    {/* <Button onClick={handleShowNote} style={{ marginBottom: '10px' }} color='purple'>Refund request</Button> */}
                  </>
                ) : Number(invoice?.grand_total) === 0 ? (
                  <h3 style={{ color: "green" }}>
                    {" "}
                    This invoice has been paid.
                  </h3>
                ) : (
                  <>
                    {invoice?.cancel ? (
                      <p style={{ color: "red" }}>Invoice is cancelled</p>
                    ) : !invoice?.invoice_payment_status ? (
                      <div>
                        <CustomSemanticDD
                          onChange={(e) => handlePayOptions(e)}
                          label="Pay"
                          data={PAY_OPTIONS}
                        />
                        <br />
                        {payOptions === "Marketplace bank transfer" && (
                          <CustomSemanticDD
                            onChange={(e) => handleCurrency(e)}
                            label="Currency"
                            data={CURRENCY}
                            value={currency}
                          />
                        )}
                        <br />
                        {payOptions === "Marketplace bank transfer" &&
                          currency && (
                            <Button
                              onClick={handleBankPay}
                              style={{ marginBottom: "10px" }}
                              color="purple"
                            >
                              Accept and Pay
                            </Button>
                          )}
                        <br />
                        {payOptions === "Marketplace bank transfer" &&
                          convertedCurrency && (
                            <Header as="h4">
                              Total to be paid including VAT/GST:{" "}
                              {convertedCurrency}
                            </Header>
                          )}
                        <Header as="h3">
                          {bankDetails.account_holder_name}
                        </Header>

                        {bankDetails.account_number && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>Account number</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails.account_number}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails.sort_code && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>Sort code</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails.sort_code}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails.bic && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>BIC</Statistic.Label>
                            <Statistic.Value>{bankDetails.bic}</Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails.iban && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>IBAN</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails.iban}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails.bsb && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>BSB</Statistic.Label>
                            <Statistic.Value>{bankDetails.bsb}</Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails.institution_number && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>
                              Institution number
                            </Statistic.Label>
                            <Statistic.Value>
                              {bankDetails.institution_number}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails.bank_code && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>Bank code</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails.bank_code}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails.routing_number && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>Routing number</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails.routing_number}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {bankDetails.transit_number && (
                          <Statistic color="purple" size="mini">
                            <Statistic.Label>Transit number</Statistic.Label>
                            <Statistic.Value>
                              {bankDetails.transit_number}
                            </Statistic.Value>
                          </Statistic>
                        )}

                        {currency && (
                          <Header as="h5">
                            Please inform our Admin Team after processing the
                            payment. Bank transfer payment can take a few days
                            to complete. Please use either your client reference
                            number {invoice.client_id}, invoice or billing
                            number {invoice.id} - as the payment reference.
                          </Header>
                        )}
                      </div>
                    ) : (
                      <p style={{ color: "green" }}>Invoice has been paid.</p>
                    )}{" "}
                  </>
                )}
                {payOptions === "Marketplace speedy payment" && (
                  <StripeLayout avatar={true}>
                    <CheckoutCACInvoice
                      price={Number(invoice.grand_total)}
                      currency={invoice.currency}
                      onSuccessfulCheckout={(id, invoiceId) =>
                        history.push(
                          `/pay-direct-invoice/success/invoice/payment/${invoiceId}/${id}`
                        )
                      }
                      cacClientID={id}
                      clientDetails={invoice}
                      invoice={invoice}
                    />
                  </StripeLayout>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default ViewINCInvoice;
