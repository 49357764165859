import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Icon, Modal } from "semantic-ui-react";
import CustomInput from "components/Common/CustomInput";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import Button from "@material-ui/core/Button";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SendIcon from "@material-ui/icons/Send";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SecurityIcon from "@material-ui/icons/Security";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DescriptionIcon from "@material-ui/icons/Description";
import BusinessIcon from "@material-ui/icons/Business";

const ULOptions = ({ id, linRefID }) => {
  const history = useHistory();
  const [paidModal, setPaidModal] = React.useState(false);
  const [pay, setPay] = useState({
    note: "",
    linID: "",
  });

  const handlePaid = async (id) => {
    setPaidModal(true);
    setPay({ ...pay, linID: id });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("token");

  const handlePaidClick = (e) => {
    setAnchorEl(false);
    handlePaid(id);
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };
  const [values, setValues] = useState({
    loader: false,
  });

  const handleConfirm = async (e) => {
    setValues({ ...values, loader: true });
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-document-request/linID/${pay.linID}`,
        {
          note: pay.note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, loader: false });
      swal({
        icon: "success",
        text: "Document request successfully send",
        buttons: false,
      });
      setPaidModal(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  const [totalAddress, setTotalAddress] = React.useState(false);
  const [totalPassport, setTotalPassport] = React.useState(false);
  const [totalRelevantDoc, setTotalRelevantDoc] = React.useState(false);
  const [totalInsuranceDoc, setTotalInsuranceDoc] = React.useState(false);
  const [totalInterpretingDoc, setTotalInterpretingDoc] = React.useState(false);
  const [totalTranslationDoc, setTotalTranslationDoc] = React.useState(false);
  const [totalPsiDoc, setTotalPsiDoc] = React.useState(false);
  const [totalOtherDoc, setTotalOtherDoc] = React.useState(false);
  const [totalRelevantCertificate, setTotalRelevantCertificate] =
    React.useState(false);
  const [totalWorkExpDoc, setTotalWorkExpDoc] = React.useState(false);
  const [totalSecurityDoc, setTotalSecurityDoc] = React.useState(false);
  const [totalCv, setTotalCv] = React.useState(false);

  const getTotalAddress = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-proof-of-address/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalAddress(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalPassport = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-passport/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalPassport(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalRelevantDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-relevent-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalRelevantDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalInsuranceDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-insurance-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalInsuranceDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalInterpretingDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-interpreting-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalInterpretingDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalTranslationDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-translation-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalTranslationDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalPsiDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-psipst-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalPsiDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalOtherDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-other-experience-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalOtherDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalRelevantCertificate = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-relevent-certificate-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalRelevantCertificate(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalWorkExpDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-work-reference-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalWorkExpDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalSecurityDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-total-security-clearence-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalSecurityDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalCv = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-cv-upload-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalCv(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalAddress();
    getTotalPassport();
    getTotalRelevantDoc();
    getTotalInsuranceDoc();
    getTotalInterpretingDoc();
    getTotalTranslationDoc();
    getTotalPsiDoc();
    getTotalOtherDoc();
    getTotalRelevantCertificate();
    getTotalWorkExpDoc();
    getTotalSecurityDoc();
    getTotalCv();
  }, []);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "auto auto " }}>
      <Modal open={paidModal} onClose={() => setPaidModal(false)}>
        <Modal.Header>Request Document - {linRefID}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <CustomInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={handleConfirm}
            style={{ backgroundColor: "purple", color: "white" }}
            disabled={!pay.note || values.loader}
          >
            Send Request <Icon name="chevron right" />
          </Button>

          {values.loader && <div class="ui active inline loader"></div>}
        </Modal.Actions>
      </Modal>

      <Button
        startIcon={<PermIdentityIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/linguist-profile-photo-preview/${id}`)
        }
      >
        Preview Profile Photo
      </Button>

      {/* proof of address */}
      <Button
        startIcon={<BusinessIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/employee/linguist-address-preview/${id}`)}
      >
        Preview Address Document ({totalAddress})
      </Button>

      <Button
        startIcon={<CreditCardIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/employee/linguist-passport/${id}`)}
      >
        Passport or National ID ({totalPassport})
      </Button>
      <Button
        startIcon={<DescriptionIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/linguist-relevent-document/${id}`)
        }
      >
        Any Other / Contract Documents ({totalRelevantDoc})
      </Button>

      <Button
        startIcon={<VerifiedUserIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/linguist-relevent-certificate/${id}`)
        }
      >
        Relevent Certificate ({totalRelevantCertificate})
      </Button>

      <Button
        startIcon={<SecurityIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/linguist-security-clearence-1/${id}`)
        }
      >
        Security Clearence Document ({totalSecurityDoc})
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/employee/linguist-cv/${id}`)}
      >
        CV ({totalCv})
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/linguist-insurance-document/${id}`)
        }
      >
        Insurance Document ({totalInsuranceDoc})
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/linguist-reference-letter/${id}`)
        }
      >
        Previous work reference ({totalWorkExpDoc})
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/linguist-interpreting-document/${id}`)
        }
      >
        Interpreting Qualifications Document ({totalInterpretingDoc})
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/linguist-translation-document/${id}`)
        }
      >
        Translation Qualifications Document ({totalTranslationDoc})
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/linguist-psi-pst-document/${id}`)
        }
      >
        PSI / PST experience reference letter ({totalPsiDoc})
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/linguist-any-other-document/${id}`)
        }
      >
        Interpreting or translation reference ({totalOtherDoc})
      </Button>

      <Button
        startIcon={<SendIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={handlePaidClick}
      >
        Request Document
      </Button>
      <Button
        startIcon={<SendIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/employee/upload-linguist-documents/${id}`)
        }
      >
        Upload Documents
      </Button>
    </div>
  );
};

export default ULOptions;
