import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

import { Button, Modal } from 'semantic-ui-react'
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ColleagueNotes from "components/Employee/Dashboard/Notes/ColleagueNoteList"

import ColleagueNotesForm from "components/Employee/Dashboard/Notes/ColleagueNoteForm"




const Archive = () => {
  const classes = useDashboardStyles();
  const [open, setOpen] = React.useState(false)

  return (
    <Employee>
      <EmployeeLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>Note / To Do List For Colleagues</p>
              </CardHeader>
            </Card>

            {/* start colleague note form modal */}
              <Card>
                <Modal
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  trigger={<Button color="purple" icon='add' content='Note / Do List' />}

                >
                  <Modal.Content scrolling>
                    <Modal.Description>
                      <ColleagueNotesForm />
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </Card>
           
            <Card>
              <ColleagueNotes />
            </Card>
          </GridItem>
        </GridContainer>

      </EmployeeLayout>
    </Employee>
  );
};

export default Archive;
