import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import AccountClientLayout from "layout/AccountClientLayout";
// Custom Components
// Custom Styles
import ViewAttach from "components/Admin/ManagementPanel/ViewAttachMessage";

const ViewContactCard = () => {
  return (
    <AccountClientLayout>
      <Accountclient>
        <ViewAttach />
      </Accountclient>
    </AccountClientLayout>
  );
};

export default ViewContactCard;
