import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import { Button } from "semantic-ui-react";
import Axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";

const Action = ({
  id,
  load,
  report,
  reportID,
  linReport,
  cacReport,
  invoice,
  invoiceID,
  accept,
  cancel,
  source,
  target,
  linID,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("CACtoken");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cancelRequest = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/cancel-translation/translationID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Cancel request sent to admin!!!`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCancel = async (e, id) => {
    swal(
      `This will send cancellation request to the Admin Team. You will hear from them if this job has been cancelled. Are you sure you want to send cancellation request for this assignment?`,
      {
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }
    ).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          cancelRequest(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {accept ? (
          <MenuItem
            onClick={() =>
              history.push(
                `/cash-client/protected/public/profile/${source}/${target}/${linID}`
              )
            }
          >
            Request Linguist Details
          </MenuItem>
        ) : null}
        {accept && !report ? (
          <MenuItem
            onClick={() =>
              history.push(`/cash-client/create-project-report/${id}`)
            }
          >
            Create Project Report
          </MenuItem>
        ) : null}
        {linReport && !cacReport ? (
          <MenuItem
            onClick={() =>
              history.push(`/cash-client/accept-project-report/${reportID}`)
            }
          >
            Confirm Project Report
          </MenuItem>
        ) : null}

        {report ? (
          <MenuItem
            onClick={() =>
              history.push(`/cash-client/view-project-report/${reportID}`)
            }
          >
            View Project Report
          </MenuItem>
        ) : null}

        {invoice ? (
          <MenuItem
            onClick={() =>
              history.push(
                `/cashclient/confirmed-translation/view-invoice/${invoiceID}`
              )
            }
          >
            View Invoice
          </MenuItem>
        ) : null}

        {!linReport && cacReport ? (
          <MenuItem
            onClick={() =>
              history.push(`/cash-client/amend-project-report/${reportID}`)
            }
          >
            Amend Project Report
          </MenuItem>
        ) : null}

        {!report && !cancel ? (
          <MenuItem onClick={handleCancel}>Cancel</MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default Action;
