import React, { useEffect, useState } from "react";
// Custom Components
import Typography from "@material-ui/core/Typography";
import { Icon, Step } from "semantic-ui-react";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import history from "utils/history";
import Axios from "axios";
import Cookies from "js-cookie";
import { useMediaQuery } from "@material-ui/core";
const CompleteRegistration = ({ active, reload }) => {
  const classes = useDashboardStyles();
  const token = Cookies.get("LINtoken");
  const matches = useMediaQuery("(max-width:1024px)");
  const [profileUpdate, setProfileUpdate] = useState("");
  const [page1, setPage1] = useState("");
  const [page2, setPage2] = useState("");
  const [page3, setPage3] = useState("");
  const [page4, setPage4] = useState("");
  const [page5, setPage5] = useState("");
  const [page6, setPage6] = useState("");
  const [page7, setPage7] = useState("");
  const [page8, setPage8] = useState("");
  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/get-mandatory-details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProfileUpdate(response?.data.profile_update);
      setPage1(response?.data.photo);
      setPage2(response?.data.passport_true);
      setPage3(response?.data.bank_account_fullname);
      setPage4(response?.data.target_language);
      setPage5(response?.data.institute_name);
      setPage6(response?.data.organization_name);
      setPage7(response?.data.criminal_record);
      setPage8(response?.data.upload_cv);
      console.log("passport", response?.data.passport_true);
      if (response?.data.profile_update) {
        history.push("/linguist/profile/update");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLinguistDetails();
  }, [reload]);

  return (
    <div>
      <Step.Group vertical={matches} widths={matches ? 0 : 8} fluid stackable>
        {page1 && page1 !== "-" ? (
          <>
            <Step
              active={active === 0}
              onClick={() => history.push("/linguist/full-registration-p1")}
              completed
            >
              <Icon name="file alternate" />
              <Step.Content>
                <Step.Title>*Personal Details</Step.Title>
              </Step.Content>
            </Step>
          </>
        ) : (
          <>
            {!profileUpdate && !page1 ? (
              <Step
                active={active === 0}
                onClick={() => history.push("/linguist/full-registration-p1")}
              >
                <Icon name="id card" />
                <Step.Content>
                  <Step.Title>*Personal Details</Step.Title>
                </Step.Content>
              </Step>
            ) : (
              <Step
                active={active === 0}
                onClick={() => history.push("/linguist/full-registration-p1")}
              >
                <Icon color="red" name="x" />
                <Step.Content>
                  <Step.Title>*Personal Details</Step.Title>
                </Step.Content>
              </Step>
            )}
          </>
        )}
        {page2 && page2 !== "-" ? (
          <>
            <Step
              active={active === 1}
              onClick={() => history.push("/linguist/full-registration-p2")}
              completed
            >
              <Icon name="file alternate" />
              <Step.Content>
                <Step.Title>*Due Diligence</Step.Title>
              </Step.Content>
            </Step>
          </>
        ) : (
          <>
            {!profileUpdate && !page2 ? (
              <Step
                active={active === 1}
                onClick={() => history.push("/linguist/full-registration-p2")}
              >
                <Icon name="file alternate" />
                <Step.Content>
                  <Step.Title>*Due Diligence</Step.Title>
                </Step.Content>
              </Step>
            ) : (
              <Step
                active={active === 1}
                onClick={() => history.push("/linguist/full-registration-p2")}
              >
                <Icon color="red" name="x" />
                <Step.Content>
                  <Step.Title>*Due Diligence</Step.Title>
                </Step.Content>
              </Step>
            )}
          </>
        )}
        {page3 && page3 !== "-" ? (
          <>
            <Step
              active={active === 2}
              onClick={() => history.push("/linguist/full-registration-p3")}
              completed
            >
              <Icon name="file alternate" />
              <Step.Content>
                <Step.Title>Bank Details</Step.Title>
              </Step.Content>
            </Step>
          </>
        ) : (
          <>
            {!profileUpdate && !page3 ? (
              <Step
                active={active === 2}
                onClick={() => history.push("/linguist/full-registration-p3")}
              >
                <Icon name="money bill alternate" />

                <Step.Content>
                  <Step.Title>Bank Details</Step.Title>
                </Step.Content>
              </Step>
            ) : (
              <Step
                active={active === 2}
                onClick={() => history.push("/linguist/full-registration-p3")}
              >
                <Icon color="yellow" name="exclamation triangle" />

                <Step.Content>
                  <Step.Title>Bank Details</Step.Title>
                </Step.Content>
              </Step>
            )}
          </>
        )}

        {page4 && page4 !== "-" ? (
          <>
            <Step
              active={active === 3}
              onClick={() => history.push("/linguist/full-registration-p4")}
              completed
            >
              <Icon name="file alternate" />
              <Step.Content>
                <Step.Title>*Language Combinations</Step.Title>
              </Step.Content>
            </Step>
          </>
        ) : (
          <>
            {!profileUpdate && !page4 ? (
              <Step
                active={active === 3}
                onClick={() => history.push("/linguist/full-registration-p4")}
              >
                <Icon name="language" />
                <Step.Content>
                  <Step.Title>*Language Combinations</Step.Title>
                </Step.Content>
              </Step>
            ) : (
              <Step
                active={active === 3}
                onClick={() => history.push("/linguist/full-registration-p4")}
              >
                <Icon color="red" name="x" />
                <Step.Content>
                  <Step.Title>*Language Combinations</Step.Title>
                </Step.Content>
              </Step>
            )}
          </>
        )}
        {page5 && page5 !== "-" ? (
          <>
            <Step
              active={active === 4}
              onClick={() => history.push("/linguist/full-registration-p5")}
              completed
            >
              <Icon name="file alternate" />
              <Step.Content>
                <Step.Title>General Qualification</Step.Title>
              </Step.Content>
            </Step>
          </>
        ) : (
          <>
            {!profileUpdate && !page5 ? (
              <Step
                active={active === 4}
                onClick={() => history.push("/linguist/full-registration-p5")}
              >
                <Icon name="university" />
                <Step.Content>
                  <Step.Title>General Qualification</Step.Title>
                </Step.Content>
              </Step>
            ) : (
              <Step
                active={active === 4}
                onClick={() => history.push("/linguist/full-registration-p5")}
              >
                <Icon color="yellow" name="exclamation triangle" />
                <Step.Content>
                  <Step.Title>General Qualification</Step.Title>
                </Step.Content>
              </Step>
            )}
          </>
        )}
        {page6 && page6 !== "-" ? (
          <>
            <Step
              active={active === 5}
              onClick={() => history.push("/linguist/full-registration-p6")}
              completed
            >
              <Icon name="file alternate" />
              <Step.Content>
                <Step.Title>Work Experience</Step.Title>
              </Step.Content>
            </Step>
          </>
        ) : (
          <>
            {!profileUpdate && !page6 ? (
              <Step
                active={active === 5}
                onClick={() => history.push("/linguist/full-registration-p6")}
              >
                <Icon name="briefcase" />
                <Step.Content>
                  <Step.Title>Work Experience</Step.Title>
                </Step.Content>
              </Step>
            ) : (
              <Step
                active={active === 5}
                onClick={() => history.push("/linguist/full-registration-p6")}
              >
                <Icon color="yellow" name="exclamation triangle" />
                <Step.Content>
                  <Step.Title>Work Experience</Step.Title>
                </Step.Content>
              </Step>
            )}
          </>
        )}
        {page7 && page7 !== "-" ? (
          <>
            <Step
              active={active === 6}
              onClick={() => history.push("/linguist/full-registration-p7")}
              completed
            >
              <Icon name="file alternate" />
              <Step.Content>
                <Step.Title>*Security Clearence</Step.Title>
              </Step.Content>
            </Step>
          </>
        ) : (
          <>
            {!profileUpdate && !page7 ? (
              <Step
                active={active === 6}
                onClick={() => history.push("/linguist/full-registration-p7")}
              >
                <Icon name="shield" />
                <Step.Content>
                  <Step.Title>*Security Clearence</Step.Title>
                </Step.Content>
              </Step>
            ) : (
              <Step
                active={active === 6}
                onClick={() => history.push("/linguist/full-registration-p7")}
              >
                <Icon color="red" name="x" />
                <Step.Content>
                  <Step.Title>*Security Clearence</Step.Title>
                </Step.Content>
              </Step>
            )}
          </>
        )}
        <>
          {!profileUpdate && (
            <Step
              active={active === 7}
              onClick={() => history.push("/linguist/full-registration-p8")}
            >
              <Icon name="info circle" />
              <Step.Content>
                <Step.Title>Submit & Other Details</Step.Title>
              </Step.Content>
            </Step>
          )}
        </>
      </Step.Group>
      <Typography variant="subtitle2">*Required </Typography>
      <Typography variant="subtitle2">
        <Icon color="green" name="check" /> icon means page completed
      </Typography>
      {/* <Typography variant="subtitle2">
        <Icon color="red" name="x" /> icon means page mandatory but not
        completed
      </Typography>
      <Typography variant="subtitle2">
        <Icon color="yellow" name="exclamation triangle" />
        icon means page not mandatory and not completed
      </Typography> */}

      <Typography variant="subtitle2">
        <b style={{ color: "red" }}>**</b>Recommended browser
        <a href="JavaScript:newPopup('https://www.google.co.uk/chrome/?brand=FHFK&gclsrc=aw.ds&gclid=EAIaIQobChMImueQy6yL8QIVCt1RCh0lIQ-7EAAYASAAEgJdS_D_BwE');">
          {" "}
          Google Chrome{" "}
        </a>
        and
        <a href="JavaScript:newPopup('https://www.microsoft.com/en-us/edge');">
          {" "}
          Microsoft Edge
        </a>
      </Typography>

      <Typography variant="subtitle2">
        <b style={{ color: "red" }}>***</b>We strongly recommend file/s to be
        uploaded are in
        <a href="JavaScript:newPopup('https://acrobat.adobe.com/uk/en/acrobat/about-adobe-pdf.html');">
          {" "}
          Portable Document Format (PDF){" "}
        </a>
        (web use, small size, max file size - 3 MB).
      </Typography>
    </div>
  );
};

export default CompleteRegistration;
