import React, { useState, useEffect } from "react";
import Sppo from "components/RouteProtection/Sppo/Sppo";
import SppoLayout from "layout/SppoLayout";

import { Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LinguistOptions from "components/Sppo/FindLinguist/LinguistsOptions";
import UnregisteredLingusit from 'components/Admin/BookingPanel/FindLinguist/Unregistered/UnregisteredLinguists'

const AssignmentPage = () => {
  const classes = useDashboardStyles();

  const [sppoID, setSPPOID] = useState(null);

  const getDetails = async (e) => {
    const token = Cookies.get("SPPOtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setSPPOID(response?.data);
  };
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <SppoLayout>
      <Sppo>
        <GridContainer>
          {sppoID?.agent_sppo && sppoID?.find_linguist_access ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>Unregistered Linguists</p>
                  <IconButton color="inherit" onClick={() => history.goBack()}>
                    <ArrowBackIcon></ArrowBackIcon>
                  </IconButton>
                </CardHeader>
                <CardBody>
                  <LinguistOptions />
                </CardBody>
                <CardBody>
                  {sppoID?.unregLinFindAccess ?
                    <UnregisteredLingusit />
                    : "Access Denied"}
                </CardBody>
              </Card>
            </GridItem>
            : sppoID?.profile_update ?
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    style={{ display: "flex", justifyContent: "space-between" }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>Unregistered Linguists</p>
                    <IconButton color="inherit" onClick={() => history.goBack()}>
                      <ArrowBackIcon></ArrowBackIcon>
                    </IconButton>
                  </CardHeader>
                  <CardBody>
                    <LinguistOptions />
                  </CardBody>
                  <CardBody>
                    {sppoID?.unregLinFindAccess ?
                      <UnregisteredLingusit />
                      : "Access Denied"}
                  </CardBody>
                </Card>
              </GridItem>
              : sppoID?.agent_sppo && sppoID?.profile_update === false ?
                <Header as='h3'>Access denied.</Header>
                :
                sppoID?.profile_update === false ?
                  <Header as='h3'>Access denied. <a href={`/sppo/full-registration`}>Please complete full registration.</a></Header>
                  :
                  <Header as='h3'>Loading...</Header>
          }
        </GridContainer>
      </Sppo>
    </SppoLayout>
  );
};

export default AssignmentPage;
