import React, {  } from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import LinguistLayout from "layout/LinguistLayout";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";



const Leads = () => {
    const classes = useDashboardStyles();

    return (
        <LinguistLayout>
            <Linguist>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>

                            <CardHeader
                                style={{ display: "flex", justifyContent: "space-between" }}
                                color="primary"
                            >
                                <p className={classes.cardTitleWhite}>Manage Clients</p>

                                <IconButton
                                    onClick={() => history.goBack()}
                                    color="inherit"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            </CardHeader>
                        </Card>
                       
                    </GridItem>

                </GridContainer>
            </Linguist>
        </LinguistLayout>
    );
};

export default Leads;
