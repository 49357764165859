import React, { Fragment } from "react";

// Custom Components
import ViewQRManager from "components/Auth/ViewQRManager/QuoteRqst";

const ViewQRManagerPage= () => {
  return (
    <Fragment>
      <ViewQRManager />
    </Fragment>
  );
};

export default ViewQRManagerPage
