import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isAuth } from "../../../action/linguistAction";

const Private = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    if (!isAuth()) {
      history.push("/linguists-login");
    } else if (isAuth().role !== 1) {
      history.push("/linguists-login");
    }
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default Private;
