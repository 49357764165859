import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomSelect from "components/Common/CustomSelect";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";
import CustomInput from "components/Common/CustomInput";
//import CustomUpload from "components/Common/CustomUpload";
import CustomRadio from "components/Common/CustomRadio";
import Time from "./Time";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import moment from "moment-timezone";

// Others
import {
  LANG_COMB,
  ITP_SERVICE_REQUIRED,
  TYPE_INTERPRETING,
  SKILL_LEVEL_REQUIRED,
  SECURITY_CLEARANCE_LEVEL,
  CAR_PARKING,
  CURRENT_COUNTRY,
  GENDER_DATA,
  TIMEZONE,
  RARE_REGEX,
  LEVEL_REGEX
} from "data";

import { Button, Card, Checkbox, FormControlLabel } from "@material-ui/core";
//import CustomAutoComplete from "components/Common/CustomAutoComplete";
import { changeIBTInput } from "redux/actions/ibtAction";
import CardBody from "components/Card/CardBody";

const FormTwo = () => {
  const { data } = useSelector((state) => state.ibtReducer);
  const dispatch = useDispatch();

  const {
    selected_client,
    purchase_order_number,
    timezone,
    assignment_date,
    start_time,
    end_time,
    interpreting_service_required,
    house_number_street_name,
    address_line_two,
    county,
    post_code,
    country,
    current_nationality,
    //other_nationalities,
    remote_address,
    source_language,
    target_language,
    linguist_skill_level,
    typesof_interpreting_required,
    job_description,
    preferred_interpreter,
    gender,
    security_clearance,
    car_parking,
    additional_info,
    scripts,
    agree,
  } = data;

  const classes = useFormStyles();

  const handleNext = () => {
    console.log(data);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeIBTInput({
        name,
        value: value || checked,
      })
    );
  };

  const handleExpertise = (value) => {
    if (target_language.includes("R") || source_language.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(
        LEVEL_REGEX
       );
    }
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeIBTInput({
        name: "other_nationalities",
        value,
      })
    );
  };

  const isAllow =
    !assignment_date ||
    !start_time ||
    !end_time ||
    !interpreting_service_required ||
    (interpreting_service_required.includes("FTP") &&
      !house_number_street_name) ||
    (interpreting_service_required.includes("FTP") && !county) ||
    (interpreting_service_required.includes("FTP") && !post_code) ||
    (interpreting_service_required.includes("FTP") && !country) ||
    (interpreting_service_required.includes("FTP") && !current_nationality) ||
    (interpreting_service_required.includes("Remote") && !remote_address) ||
    !source_language ||
    !target_language ||
    !linguist_skill_level ||
    !interpreting_service_required ||
    !typesof_interpreting_required ||
    !job_description ||
    !agree;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        {/* "Developer- add more option to book a multi day job. One Day job or Multi day job, allow easy pick without making mistake"  */}
        <CustomSelect
          onChange={handleChange}
          label="Select client"
          width="100%"
          data={ITP_SERVICE_REQUIRED}
          value={selected_client}
        />
        <CustomInput
          onChange={handleChange}
          width="100%"
          label="Your booking ref/ Purchase order no"
          name="purchase_order_number"
          value={purchase_order_number}
        />

        <CustomSelect
          onChange={handleChange}
          label="Select timezone"
          width="100%"
          data={TIMEZONE}
          name="timezone"
          //defaultValue= {moment.tz.guess()}
          value={timezone}
          helperText={moment.tz.guess()}
        />

        <Time />

        <CustomSelect
          required
          onChange={handleChange}
          label="Interpreting service required"
          data={ITP_SERVICE_REQUIRED}
          width="100%"
          name="interpreting_service_required"
          value={interpreting_service_required}
        />

        {/* Developer - make this conditional if remote selected only be able to add remote platform details and phone number. If FTF selected show address field, if Remote address optional for invoicing purpose only. FTF onsite must provide venue details clearly including any department or building block */}

        {/* moreInfo="Developer - customer must select two, allow customer to book easily another combination which should become two jobs. System must be able to check match of languages 'not one both ie English <> Bengali '. Apply condition both selection cannot be same"  */}
        <CustomBigInput
          required
          onChange={handleChange}
          width="100%"
          name="remote_address"
          value={remote_address}
          label="Remote / Telephone details"
          moreInfo="Please provide details Zoom link, Skype, phone number etc"
        />
        <CustomInput
          required
          onChange={handleChange}
          width="100%"
          label="House Number / Name, Street Name"
          name="house_number_street_name"
          value={house_number_street_name}
        />
        <CustomInput
          onChange={handleChange}
          width="100%"
          label="Address Line 2"
          name="address_line_two"
          value={address_line_two}
        />
        <CustomInput
          required
          onChange={handleChange}
          width="100%"
          label="County / Region"
          name="county"
          value={county}
        />
        <CustomInput
          required
          onChange={handleChange}
          width="100%"
          label="Post / Zip code"
          name="post_code"
          value={post_code}
        />
        <CustomSelect
          onChange={handleChange}
          label="Country"
          moreInfo="Current country of residence"
          width="100%"
          name="country"
          value={country}
          data={CURRENT_COUNTRY}
          required
        />
        {/* <CustomSelect
              onChange= {handleChange}
              label= 'Current nationality'
              width="100%"
              name= 'current_nationality'
              value= {current_nationality}
              data= {CURRENT_COUNTRY}
              required
            />
            <CustomAutoComplete
              onChange= {handleAutoComplete}
              label= 'Other nationalities'
              width="100%"
              data= {CURRENT_COUNTRY}
              value= {other_nationalities}
              placeholder= 'Choose'
            /> */}

        <CustomSelect
          required
          onChange={handleChange}
          label="Source language"
          data={LANG_COMB}
          width="100%"
          name="source_language"
          value={source_language}
        />
        <CustomSelect
          required
          onChange={handleChange}
          label="Target language"
          data={LANG_COMB.filter((value) => value !== source_language)}
          width="100%"
          name="target_language"
          value={target_language}
        />
        <CustomSelect
          required
          onChange={handleChange}
          label="Please indicate linguist's skills level required"
          moreInfo="Please indicate linguist's skills level required for this assignment (1 - 5)"
          data={SKILL_LEVEL_REQUIRED.filter((value) => handleExpertise(value))}
          width="100%"
          name="linguist_skill_level"
          value={linguist_skill_level}
        />
        {/* (Developer* - this is a mandatory */}
        <CustomSelect
          required
          onChange={handleChange}
          label="Type/s of Interpreting required"
          moreInfo="Please pick the most relevant one/s)"
          data={TYPE_INTERPRETING}
          width="100%"
          name="typesof_interpreting_required"
          value={typesof_interpreting_required}
        />
        <CustomBigInput
          required
          onChange={handleChange}
          width="100%"
          label="Job description"
          moreInfo="Please provide brief information for the benefit of linguist's preparation"
          name="job_description"
          value={job_description}
        />
        <CustomBigInput
          onChange={handleChange}
          width="100%"
          label="Preferred Linguist / Service provider ID number"
          moreInfo="LC will try its best, but cannot guarantee"
          name="preferred_interpreter"
          value={preferred_interpreter}
        />
        <CustomRadio
          onChange={handleChange}
          label="Gender"
          moreInfo="Please note that we can not guarantee gender"
          data={GENDER_DATA}
          width="100%"
          value={gender}
        />
        <CustomSelect
          onChange={handleChange}
          label="Please indicate security clearance level required*"
          moreInfo="Please indicate security clearance level required for this assignment"
          data={SECURITY_CLEARANCE_LEVEL}
          width="100%"
          name="security_clearance"
          value={security_clearance}
        />
        <CustomSelect
          onChange={handleChange}
          label="Do you have car parking for linguist?"
          data={CAR_PARKING}
          width="100%"
          name="car_parking"
          value={car_parking}
        />
        <CustomInput
          onChange={handleChange}
          label="Additional information and direction for linguist"
          width="100%"
          name="additional_info"
          value={additional_info}
        />
        {/* <CustomUpload
          onChange= {handleChange}
          width="100%"
          label="Upload any script or instruction here"
          moreInfo="e.g. for conference interpreting"
          name= 'scripts'
          value= {scripts}
        /> */}
        <p style={{ marginTop: "24px" }}>Scripts</p>
        <Card raised style={{ marginTop: "16px" }}>
          <CardBody>View Documents</CardBody>
        </Card>

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                checked={agree}
                name="agree"
                color="primary"
              />
            }
            label="By submitting this form I agree with LC Privacy Policy, T&C"
          />
        </div>

        <div className={classes.buttonFlex}>
          <Button
            style={{ marginTop: "2rem", marginRight: "15px" }}
            variant="contained"
            color="primary"
          >
            Provide Quote
          </Button>
          <CustomButton text="Decline" onClick={handleNext} />
          <Button
            style={{ marginTop: "2rem", marginRight: "15px" }}
            variant="contained"
            color="primary"
          >
            View Note
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormTwo;
