import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Action from "components/CashClient/Assignments/UnconfirmAssignments/ListInterpreters/Actions/Action";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ListCACAssignments({ interpretingData, text }) {
  const token = Cookies.get("CACtoken");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [] = React.useState(false);
  const [, setSign] = useState("");
  const [, setSignature] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    UnconfirmBookingList: [],
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });

  const { UnconfirmBookingList, reload } = values;

  useEffect(() => {
    loadUnconfirmBookingList();
  }, [reload]);

  const loadUnconfirmBookingList = async () => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/unconfirmed-booking-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((unconfirmedJobs) => {
        setValues({
          ...values,
          UnconfirmBookingList: interpretingData
            ? unconfirmedJobs.data.filter(
                (item) => item.id === interpretingData
              )
            : unconfirmedJobs.data,
        });
      })
    );
  };

  const searchFunc = (item) => {
    if (!text) {
      return true;
    }

    const searchText = text.toLowerCase();
    const isMatched =
      item.id.toLowerCase().includes(searchText) ||
      item.source_language.toLowerCase().includes(searchText) ||
      item.target_language.toLowerCase().includes(searchText) ||
      item.interpreting_service_required.toLowerCase().includes(searchText) ||
      item.remote_address.toLowerCase().includes(searchText) ||
      item.linguist_skill_level.toLowerCase().includes(searchText) ||
      item.gender.toLowerCase().includes(searchText) ||
      item.car_parking.toLowerCase().includes(searchText) ||
      item.security_clearance.toLowerCase().includes(searchText);

    return isMatched;
  };

  const a = UnconfirmBookingList?.map((uc) => uc);

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell width="1">Assignment ID</TableCell>
                <TableCell width="1">Timezone</TableCell>
                <TableCell width="1">Start Date & Time</TableCell>
                <TableCell width="1">Duration</TableCell>
                <TableCell width="1">Source language</TableCell>
                <TableCell width="1">Target language</TableCell>
                <TableCell width="1">Service Required</TableCell>
                <TableCell width="1">Security Level</TableCell>
                <TableCell width="1">Estimate Amount</TableCell>
                <TableCell width="1">Payment Status</TableCell>
                <TableCell width="1">Action</TableCell>
              </TableRow>
            </TableHead>

            {UnconfirmBookingList.length > 0 ? (
              <TableBody>
                {UnconfirmBookingList.filter(searchFunc)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((list, i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell key={list._id} align={list.align}>
                        <Link
                          to={`/cash-client/view-cash-client-booking-interpreting/${list._id}`}
                        >
                          {list.id}
                        </Link>
                      </TableCell>
                      <TableCell>{list.timezone}</TableCell>
                      <TableCell>
                        {moment(list.startdateArray[0]).format("DD-MMM-YYYY")} -{" "}
                        {list?.starttimeArray[0]}
                      </TableCell>
                      <TableCell>{list?.gethour}</TableCell>
                      <TableCell>{list?.source_language}</TableCell>
                      <TableCell>{list?.target_language}</TableCell>
                      <TableCell>{list?.target_linguist_skill_level}</TableCell>
                      <TableCell>
                        {list?.typesof_interpreting_required}
                      </TableCell>
                      <TableCell>
                        {list?.currency_new?.substring(0, 3)} {list?.estimate}
                      </TableCell>
                      {/* .substring(0,3) */}
                      <TableCell>{list.status}</TableCell>
                      <TableCell>
                        <Action
                          load={loadUnconfirmBookingList}
                          id={list?._id}
                          linID={list?.postedBy}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={UnconfirmBookingList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
