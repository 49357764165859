import Cookies from "js-cookie";

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import SearchView from "../SearchView/SearchView";
// import Action from "./Action";

import CustomModal from "components/Common/CustomModal";
import ViewAll from "./ViewAll";
// Others
import { acceptDecline } from "./functions";
import Axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import CustomSnackBar from "components/Common/CustomSnackBar";
import { Link } from "@material-ui/core";
import history from "utils/history";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const InterpretingJobsLists = ({ details }) => {
  const [open, setOpen] = useState(false);
  const token = Cookies.get("LINtoken");
  const [submit, setSubmit] = useState({
    toast: false,
    loader: false,
    message: "",
  });
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const linID = Cookies.get("linID");
  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState();
  const [map, setMap] = useState();

  const linAddress =
    details &&
    `${details?.Region} ${details?.PostalCode}, ${details?.City}, ${details?.Country}`;

  const handleClose = () => {
    setOpen(!open);
  };
  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const { bookingList, reload } = values;

  useEffect(() => {
    loadbookingList();
    mapLoad();
  }, [reload, filter]);

  const onScriptLoad = () => {
    const map = new window.google.maps.Map(document.getElementById("map"), {});

    setMap(map);
  };

  const mapLoad = () => {
    const API_KEY = process.env.REACT_APP_MAPS_API_KEY;
    if (!window.google) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        `https://maps.googleapis.com/maps/api/js?key=` +
        API_KEY +
        `&libraries=places,geometry`;
      script.id = "googleMaps";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.addEventListener("load", (e) => {
        onScriptLoad();
      });
    } else {
      onScriptLoad();
    }
  };

  const calcPushRoute = async (
    region,
    post_code,
    city,
    country,
    id,
    interpreting_service_required
  ) => {
    if (
      interpreting_service_required ===
      "Remote Interpreting (RI) / Video Remote Interpreting (VRI)"
    ) {
      history.push(`/linguist/bac-view-interpreting-assignment/${id}/${false}`);
    } else {
      //create a DirectionsService object to use the route method and get a result for our request
      const directionsService = new window.google.maps.DirectionsService();

      //create a DirectionsRenderer object which we will use to display the route
      const directionsDisplay = new window.google.maps.DirectionsRenderer();
      //bind the DirectionsRenderer to the map
      directionsDisplay.setMap(map);
      //create request
      let request = {
        origin: linAddress,
        destination: `${region} ${post_code}, ${city}, ${country}`,
        travelMode: window.google.maps.TravelMode.DRIVING, //WALKING, BYCYCLING, TRANSIT
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      };

      //pass the request to the route method
      directionsService.route(request, function (result, status) {
        if (status == window.google.maps.DirectionsStatus.OK) {
          history.push(
            `/linguist/bac-view-interpreting-assignment/${id}/${linAddress}/${true}`
          );
          //Get distance and time

          setDuration(result.routes[0].legs[0].duration.text);

          // await function is unexpected error
          setDistance([
            result.routes[0].legs[0].distance.text,
            result.routes[0].legs[0].distance.value,
          ]);
          //display route
          directionsDisplay.setDirections(result);
        } else {
          swal({
            icon: "error",
            text: "The assignment venue is located out of country, please contact Admin / Booking Team. Error Code: Foreign 007",
            buttons: false,
            dangerMode: true,
          }); //delete route from map
          directionsDisplay.setDirections({ routes: [] });
        }
      });
    }
  };

  const calcAcceptedRoute = async (
    id,
    email,
    linID,
    target_level,
    target_language,
    _start_date,
    streetName,
    post_code,
    city,
    country,
    interpreting_service
  ) => {
    if (
      interpreting_service !==
      "Remote Interpreting (RI) / Video Remote Interpreting (VRI)"
    ) {
      //create a DirectionsService object to use the route method and get a result for our request
      const directionsService = new window.google.maps.DirectionsService();

      //create a DirectionsRenderer object which we will use to display the route
      const directionsDisplay = new window.google.maps.DirectionsRenderer();
      //bind the DirectionsRenderer to the map
      directionsDisplay.setMap(map);
      //create request
      let request = {
        origin: linAddress,
        destination: `${streetName} ${post_code}, ${city}, ${country}`,
        travelMode: window.google.maps.TravelMode.DRIVING, //WALKING, BYCYCLING, TRANSIT
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      };

      //pass the request to the route method
      directionsService.route(request, function (result, status) {
        if (status == window.google.maps.DirectionsStatus.OK) {
          //Get distance and time

          setDistance([
            result.routes[0].legs[0].distance.text,
            result.routes[0].legs[0].distance.value,
          ]);

          handleAccept(
            id,
            email,
            linID,
            target_level,
            target_language,
            _start_date,
            streetName,
            post_code,
            city,
            country,
            interpreting_service,
            result.routes[0].legs[0].distance.value
          );

          setDuration(result.routes[0].legs[0].duration.text);

          //display route
          directionsDisplay.setDirections(result);
        } else {
          //delete route from map
          directionsDisplay.setDirections({ routes: [] });
          swal({
            icon: "error",
            text: "The assignment venue is located out of country, please contact Admin / Booking Team. Error Code: Foreign 007",
            buttons: false,
          });
        }
      });
    } else {
      handleAccept(
        id,
        email,
        linID,
        target_level,
        target_language,
        _start_date,
        streetName,
        post_code,
        city,
        country,
        interpreting_service
      );
    }
  };

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/accountclient-booking-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (filtered.length > 0) {
        setValues({ ...values, bookingList: filtered });
        setFiltered([]);
      } else {
        setValues({ ...values, bookingList: response?.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //You cannot accept this assignment, check start date and time with Booking Secretary / Admin Team.

  const handleAccept = async (
    id,
    email,
    linID,
    target_level,
    target_language,
    _start_date,
    streetName,
    postalCode,
    region,
    country,
    interpreting_service,
    distance
  ) => {
    setAcceptLoader(true);

    if (interpreting_service === "Face to Face FTF - On Site") {
      if (distance <= 112654) {
        try {
          const response = await Axios.put(
            `${process.env.REACT_APP_BACKEND_API}/linguists/accountclient-booking-accept/bookingID/${id}`,
            { email: email },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          swal({
            icon: "success",
            text: `${response?.data.message}`,
            buttons: false,
          });
          loadbookingList();
          setAcceptLoader(false);
          setViewAll({ ...viewAll, open: false });
        } catch (error) {
          swal({
            icon: "error",
            text: `${error.response?.data}`,
            buttons: "Ok",
            dangerMode: true,
          });
          setAcceptLoader(false);
        }
      } else {
        swal({
          icon: "error",
          text: "Please contact admin to allocate this job (Distance greater than 70 miles) Error ref code: Distance70 001",
          buttons: false,
        });
        setAcceptLoader(false);
      }
    } else if (
      interpreting_service ===
      "Remote Interpreting (RI) / Video Remote Interpreting (VRI)"
    ) {
      try {
        const response = await Axios.put(
          `${process.env.REACT_APP_BACKEND_API}/linguists/accountclient-booking-accept/bookingID/${id}`,
          { email: email },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        swal({
          icon: "success",
          text: `${response?.data.message}`,
          buttons: false,
        });
        loadbookingList();
        setAcceptLoader(false);
        setViewAll({ ...viewAll, open: false });
      } catch (error) {
        swal({
          icon: "error",
          text: `${error.response?.data}`,
          buttons: "Ok",
          dangerMode: true,
        });
        setAcceptLoader(false);
      }
    }
  };

  const pushView = (
    id,
    streetName,
    postalCode,
    region,
    country,
    interpreting_service_required
  ) => {
    calcPushRoute(
      streetName,
      postalCode,
      region,
      country,
      id,
      interpreting_service_required
    );
  };

  const decline = async (decline, id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/accountclient-booking-reject/bookingID/${id}`,
        { email: email, declines: decline },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      loadbookingList();
      setViewAll({ ...viewAll, open: false });
    } catch (error) {
      swal({
        icon: "error",
        text: `${error.response?.data}`,
        buttons: "Ok",
        dangerMode: true,
      });
    }
  };

  const handleDecline = async (e, id, email) => {
    const declines = e.target.value;
    swal(`Are you sure you want to decline this job?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          decline(declines, id, email);

        default:
          break;
      }
    });
  };

  const handleViewAll = async (e, list) => {
    e.preventDefault();
    setViewAll({ ...viewAll, open: true, list: list });
  };

  return (
    <Paper className={classes.root}>
      <CustomSnackBar
        message={submit.message}
        handleClose={() => setSubmit({ ...submit, toast: false })}
        openSnackBar={submit.toast}
        severity="error"
      />
      <CustomModal
        open={viewAll.open}
        handleClose={() => setViewAll({ ...viewAll, open: false })}
      >
        <ViewAll data={viewAll.list} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Button
            basic
            color="purple"
            onClick={() => setViewAll({ ...viewAll, open: false })}
          >
            Close
          </Button>
          <select
            onChange={(e) =>
              handleDecline(e, viewAll.list._id, viewAll.list.email)
            }
            className="ui dropdown"
          >
            <option>Decline</option>
            <option value="Not profitable">Not profitable</option>
            <option value="Venue is too far">Venue is too far</option>
            <option value="Unavailable / booked">Unavailable / booked</option>
            <option value="On holiday">On holiday</option>
            <option value="Not interested in job from this client">
              Not interested in job from this client
            </option>
            <option value="Don't feel confident">Don't feel confident</option>
            <option value="Need more information">Need more information</option>
            <option value="Other">Other</option>
          </select>
          <Button
            loading={acceptLoader}
            color="purple"
            onClick={() =>
              calcAcceptedRoute(
                viewAll.list._id,
                viewAll.list.email,
                viewAll.list.LinguistID,
                viewAll.list.target_language_level,
                viewAll.list.target_language,
                viewAll.list.startdateArray,
                viewAll.list.house_number_street_name,
                viewAll.list.post_code,
                viewAll.list.region,
                viewAll.list.country,
                viewAll.list.interpreting_service_required
              )
            }
          >
            Accept
          </Button>
        </div>
      </CustomModal>
      <SearchView
        values={values.bookingList}
        SearchText={searchText}
        setSearchText={setSearchText}
        setFiltered={setFiltered}
        load={loadbookingList}
        setShow={setShow}
        setFilter={setFilter}
        show={show}
      />
      {show && (
        <>
          {" "}
          <TableContainer className={classes.container}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                  <Table.HeaderCell>Timezone</Table.HeaderCell>
                  <Table.HeaderCell>Start date & time</Table.HeaderCell>
                  <Table.HeaderCell>Duration</Table.HeaderCell>
                  <Table.HeaderCell>Source language</Table.HeaderCell>
                  <Table.HeaderCell>Target language</Table.HeaderCell>
                  <Table.HeaderCell>Service Required</Table.HeaderCell>
                  <Table.HeaderCell>Security Level</Table.HeaderCell>
                  <Table.HeaderCell>Estimate Paid</Table.HeaderCell>
                  <Table.HeaderCell>Payment Status</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {bookingList?.length > 0 ? (
                <Table.Body>
                  {bookingList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .filter((list) => list.LinguistID !== linID)
                    .map((list) => (
                      <>
                        {moment(list.startdateArray[0]) > moment() ||
                        moment(list.startdateArray[0]) === moment() ? (
                          <Table.Row
                            role="checkbox"
                            tabIndex={-1}
                            key={list._id}
                          >
                            <Table.Cell key={list._id} align={list.align}>
                              <Link
                                onClick={() =>
                                  pushView(
                                    list._id,
                                    list.house_number_street_name,
                                    list.post_code,
                                    list.region,
                                    list.country,
                                    list?.interpreting_service_required
                                  )
                                }
                              >
                                {list.id}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>{list.timezone}</Table.Cell>
                            <Table.Cell>
                              {moment(list.startdateArray[0]).format(
                                "DD-MMM-YYYY"
                              )}{" "}
                              - {list?.starttimeArray[0]}
                            </Table.Cell>
                            <Table.Cell>
                              {list.gethour
                                ? list.gethour
                                : list.total_Duration}
                            </Table.Cell>
                            <Table.Cell>{list.source_language}</Table.Cell>
                            <Table.Cell>{list.target_language}</Table.Cell>
                            <Table.Cell>
                              {list.interpreting_service_required}
                            </Table.Cell>
                            <Table.Cell>
                              {list.security_clearance
                                ? list.security_clearance
                                : "N/A"}
                            </Table.Cell>
                            <Table.Cell>
                              {list?.currency_new?.substring(0, 3) +
                                " " +
                                Number(list.estimate).toFixed(2)}
                            </Table.Cell>
                            <Table.Cell>{list.status}</Table.Cell>
                            <Table.Cell>
                              {list.startdateArray?.length > 1 ? (
                                <Button
                                  color="purple"
                                  onClick={(e) => handleViewAll(e, list)}
                                >
                                  View All
                                </Button>
                              ) : (
                                acceptDecline(
                                  "bl",
                                  "",
                                  calcAcceptedRoute,
                                  handleDecline,
                                  list._id,
                                  list.email,
                                  "declineOp",
                                  list.LinguistID,
                                  list.target_language_level,
                                  list.target_language,
                                  list.startdateArray,
                                  list.house_number_street_name,
                                  list.post_code,
                                  list.region,
                                  list.country,
                                  list.interpreting_service_required,
                                  distance && distance[1]
                                )
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row
                            role="checkbox"
                            tabIndex={-1}
                            key={list._id}
                          >
                            <Table.Cell key={list._id} align={list.align}>
                              <Link
                                onClick={() =>
                                  pushView(
                                    list._id,
                                    list.house_number_street_name,
                                    list.post_code,
                                    list.region,
                                    list.country,
                                    list?.interpreting_service_required
                                  )
                                }
                              >
                                {list.id}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>{list.timezone}</Table.Cell>
                            <Table.Cell>
                              {moment(list.startdateArray[0]).format(
                                "DD-MMM-YYYY"
                              )}{" "}
                              - {list?.starttimeArray[0]}
                            </Table.Cell>
                            <Table.Cell>
                              {list.gethour
                                ? list.gethour
                                : list.total_Duration}
                            </Table.Cell>
                            <Table.Cell>{list.source_language}</Table.Cell>
                            <Table.Cell>{list.target_language}</Table.Cell>
                            <Table.Cell>
                              {list.interpreting_service_required}
                            </Table.Cell>
                            <Table.Cell>
                              {list.security_clearance
                                ? list.security_clearance
                                : "N/A"}
                            </Table.Cell>
                            <Table.Cell>
                              {list?.currency_new?.substring(0, 3) +
                                " " +
                                Number(list.estimate).toFixed(2)}
                            </Table.Cell>
                            <Table.Cell>{list.status}</Table.Cell>
                            <Table.Cell>
                              Assignment Acceptance Time Expired.You may contact
                              Booking Team for an update.
                            </Table.Cell>
                          </Table.Row>
                        )}{" "}
                      </>
                    ))}
                </Table.Body>
              ) : (
                "Sorry, no BAC interpreting assignment available matching your profile."
              )}
            </Table>
            <CustomModal handleClose={handleClose} open={open}>
              <ViewAll />
            </CustomModal>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={bookingList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
};

export default InterpretingJobsLists;
