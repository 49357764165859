import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomAvatar from "components/Common/CustomAvatar";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import {
  Button,
  Dimmer,
  Header,
  Loader,
  Statistic,
  Segment,
  Table,
} from "semantic-ui-react";
import {
  CURRENCY,
  CURRENCY_STRIPE,
  DISCOUNT,
  LANG_COMB,
  SKILL_LEVEL_REQUIRED,
  TAXIFARE,
  TIMEZONE,
  QUOTE_REQUEST_FOR,
} from "data";
import { IconButton, Input, TextField } from "@material-ui/core";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import swal from "sweetalert";
import {
  addOtherTransaction,
  addTransaction,
  deleteOtherTransaction,
  deleteTransaction,
} from "redux/actions/expenseAction";
import CustomDateTime from "components/Common/CustomDateTime";

//Material icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import momentTimezone from "moment-timezone";

const Quotation = ({ title, id }) => {
  const classes = useEmployeeStyles();
  const history = useHistory();
  const token = Cookies.get("token");
  const [admin, setAdmin] = useState(null);
  const [lingRef, setLingRef] = useState(null);
  const [timezone, setTimezone] = useState(momentTimezone.tz.guess());
  const [expiryDate, setExpiryDate] = useState(null);
  const [adminNote, setAdminNote] = useState(null);
  const [dateQuote, setDateQuote] = useState(null);
  const [currency, setCurrency] = useState(CURRENCY_STRIPE[0]);
  const [client, setPrivateClient] = useState({
    id: "",
    data: "",
    estimate_paid_in_advance: "",
    email: "",
    FirstName: "",
    Region: "",
    Country: "",
    PostalCode: "",
    City: "",
    AddressLine1: "",
    AddressLine2: "",
  });
  const [serviceDate, setServiceDate] = useState(null);
  const [clientRef, setClientRef] = useState("");
  const [service, setService] = useState("");
  const [sourcelanguage, setSourceLanguage] = useState("");
  const [targetlanguage, setTargetLanguage] = useState("");
  const [skilllevel, setSkillLevel] = useState("");
  const [completionDate, setCompletionDate] = useState(null);
  const [calculate, setCalculate] = useState({
    sendButton: false,
    loading: false,
    quoteId: "",
  });

  //   1st subtotal
  const [text, setText] = useState("");
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);

  //   2nd subtotal
  const [otherAmount, setOtherAmount] = useState(0);
  const [otherText, setOtherText] = useState("");
  const [otherQuantity, setOtherQuantity] = useState(0);

  const dispatch = useDispatch();

  const { transactions } = useSelector((state) => state.expenseReducer);
  const amountsOne = transactions.map((transaction) => transaction.total);
  const subTotalOne = amountsOne
    .reduce((acc, item) => (acc += item), 0)
    .toFixed(2);

  const { otherTransactions } = useSelector((state) => state.expenseReducer);
  const amountsTwo = otherTransactions.map((transaction) => transaction.total);
  const subTotalTwo = amountsTwo
    .reduce((acc, item) => (acc += item), 0)
    .toFixed(2);
  const [bascCharge, setBASC] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [vat, setVAT] = useState(0);

  const [totalFTF, setTotalFTF] = useState({
    price: 0,
    quantity: 0,
  });

  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
    cvat_no: "",
  });
  //  const totalIntr = totalFTF.price * totalFTF.quantity

  const total = (Number(subTotalOne) + Number(subTotalTwo)).toFixed(2);

  //Total after discount
  const totalDiscount = ((Number(total) * discount) / 100).toFixed(2);

  //Including platform and booking service charge
  const totalPBSC = ((Number(total) * companyCard?.pbsc) / 100).toFixed(2);
  //Including billing account service charge
  const totalBASC = ((Number(total) * bascCharge) / 100).toFixed(2);

  //Total VAT
  const totalVAT = (
    Number(
      Number(total) +
        Number(totalPBSC) +
        Number(totalBASC) -
        Number(totalDiscount)
    ) *
    (vat / 100)
  ).toFixed(2);

  //Total after including vat and discount
  const totalTwo = (
    Number(total) +
    Number(totalPBSC) +
    Number(totalBASC) -
    Number(totalDiscount)
  ).toFixed(2);

  const grandTotal = Number(Number(totalTwo) + Number(totalVAT)).toFixed(2);

  const handleAdd = (e) => {
    e.preventDefault();

    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      text,
      amount: +amount,
      quantity,
      total: amount * quantity,
    };

    dispatch(addTransaction(newTransaction));
    setText("");
    setAmount("");
    setQuantity("");
  };

  const handleAddOther = (e) => {
    e.preventDefault();
    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      otherText,
      otherAmount: +otherAmount,
      otherQuantity,
      total: otherAmount * otherQuantity,
    };

    dispatch(addOtherTransaction(newTransaction));
    setOtherText("");
    setOtherAmount("");
    setOtherQuantity("");
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getAdminProfile = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAdmin(response?.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    const getCompanyCard = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/view/company-information-card`
        );

        setCompanyCard({
          ...companyCard,
          basc: response?.data[0].getCompanyCard,
          pbsc: response?.data[0].platform_booking_service_charge,
          basc: response?.data[0].billing_account_service_charge,
          cname: response?.data[0].company_name,
          caddress: response?.data[0].company_address,
          cvat_no: response?.data[0].vat_number,
        });
      } catch (error) {
        console.log(error);
      }
    };

    getAdminProfile();
    getCompanyCard();

    return () => {
      source.cancel();
    };
  }, []);

  const handleSend = async (e) => {
    setCalculate({ ...calculate, loading: true });
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-client-Quote/QuoteID/${calculate.quoteId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Quotation successfully send to client",
        icon: "success",
        button: "Ok",
      }).then(function () {
        window.location = "/admin/dashboard";
      });
      setCalculate({ ...calculate, loading: false });
      // history.push('/admin/dashboard')
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutoFill = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-client/clientID/${client.id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPrivateClient({ ...client, data: response?.data });
      if (response?.data === "Sorry! client not found.") {
        swal({
          text: "Sorry! client not found.",
          buttons: "Ok",
          dangerMode: true,
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
      swal({
        text: "Wrong client Id",
        buttons: false,
        dangerMode: true,
        icon: "error",
      });
    }
  };

  const handleCalculate = async (e) => {
    setCalculate({ ...calculate, loading: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/create-client-Quote/clientID`,
        {
          _id: client.data._id,
          date_of_quote: moment().format("DD/MMM/YYYY : HH:mm"),
          date_of_invoice: "",
          service_completion_date: serviceDate,
          linguist_ref: lingRef,
          currency: currency ? currency : "GBP (UK)",
          service: service,
          source_language: sourcelanguage,
          target_language: targetlanguage,
          skill_level: skilllevel,
          client_ref: clientRef,
          account_service_charge: companyCard.basc,
          transactions: transactions,
          otherTransactions: otherTransactions,
          subtotal1: subTotalOne,
          subtotal2: subTotalTwo,
          total: totalTwo,
          service_charge: companyCard.pbsc,
          service_charge_amount: totalPBSC,
          bac_service_charge: bascCharge,
          bac_service_charge_amount: totalBASC,
          discount_percentage: discount,
          discount_amount: totalDiscount,
          vat_percentage: vat,
          vat_amount: totalVAT,
          grand_total: grandTotal,
          deposit: "",
          due: "",
          expiry_date: expiryDate,
          timezone: timezone,
          company_name: companyCard.cname,
          company_address: companyCard.caddress,
          linguist_note: "",
          admin_note: adminNote,

          client_note: "",
          client_title: client.data?.title,
          client_firstname: client.data?.FirstName,
          client_lastname: client.data?.LastName,
          client_email: client.data?.email,
          client_mobile_no: client.data?.mobile_no,
          client_city: client.data?.City,
          client_postalcode: client.data?.PostalCode,
          client_region: client.data?.Region,
          client_country: client.data?.Country,
          client_addressline_one: client.data?.AddressLine1,
          client_addressline_two: client.data?.AddressLine2,
          client_id: client.data?.id,
          postedByID: admin?.id,
          postedByName: admin?.FirstName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Quotation saved not send to client yet. Please send the quotation when you ready.",
        icon: "success",
        button: "Noted",
        dangerMode: true,
      });
      setCalculate({
        ...calculate,
        loading: false,
        sendButton: true,
        quoteId: response?.data._id,
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCurrency = async (e, value) => {
    setCurrency(value);
  };

  return companyCard && admin ? (
    <div className={classes.root}>
      <div id="pdf"></div>
      <GridContainer justify="center" className={classes.container}>
        <GridItem id="printArea" xs={12} sm={12} md={12} lg={12} xl={12}>
          {/* Avatar and company name */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "24px",
            }}
          >
            <div style={{ display: "flex" }}>
              <CustomAvatar style={{ margin: "0" }} />
            </div>
          </div>

          {/* First div  */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
              overflowX: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0 5px 0 0" }} as="h4">
                  Quotation to:
                </Header>
                <Input
                  style={{ marginRight: "5px" }}
                  onChange={(e) =>
                    setPrivateClient({ ...client, id: [e.target.value] })
                  }
                  placeholder="Type Client Id.."
                />
                <Button inverted color="purple" onClick={handleAutoFill}>
                  Auto fill
                </Button>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Name of the company:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input />
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Name of the person:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input
                    onChange={(e) =>
                      setPrivateClient({
                        ...client,
                        data: { ...client.data, FirstName: e.target.value },
                      })
                    }
                    value={client.data?.FirstName}
                  />
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  House number/ name, Street name:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input
                    onChange={(e) =>
                      setPrivateClient({
                        ...client,
                        data: { ...client.data, AddressLine1: e.target.value },
                      })
                    }
                    value={client.data?.AddressLine1}
                  />
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Address Line Two:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input
                    onChange={(e) =>
                      setPrivateClient({
                        ...client,
                        data: { ...client.data, AddressLine2: e.target.value },
                      })
                    }
                    value={client.data?.AddressLine2}
                  />
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Region:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input
                    onChange={(e) =>
                      setPrivateClient({
                        ...client,
                        data: { ...client.data, Region: e.target.value },
                      })
                    }
                    value={client.data?.Region}
                  />
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  City:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input
                    onChange={(e) =>
                      setPrivateClient({
                        ...client,
                        data: { ...client.data, City: e.target.value },
                      })
                    }
                    value={client.data?.City}
                  />
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Postal Code:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input
                    onChange={(e) =>
                      setPrivateClient({
                        ...client,
                        data: { ...client.data, PostalCode: e.target.value },
                      })
                    }
                    value={client.data?.PostalCode}
                  />
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Country:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input
                    onChange={(e) =>
                      setPrivateClient({
                        ...client,
                        data: { ...client.data, Country: e.target.value },
                      })
                    }
                    value={client.data?.Country}
                  />
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Email:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input
                    onChange={(e) =>
                      setPrivateClient({
                        ...client,
                        data: { ...client.data, email: e.target.value },
                      })
                    }
                    value={client.data?.email}
                  />
                </Header>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Quotation number:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  User Id:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;{admin.id}
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Company Residency:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;{companyCard.caddress}
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  LC VAT/GST no:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;{companyCard.cvat_no}
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Date of quote:
                </Header>
                &nbsp;
                {/* <TextField
                                        id="date"
                                        onChange={(e) => setDateQuote(e.target.value)}
                                        value={dateQuote}
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    /> */}
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  {moment().format("DD/MMM/YYYY : HH:mm")}
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Date of invoice:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;N/A
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Quotation estimate to be paid by:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  {expiryDate
                    ? moment(expiryDate).format("DD/MMM/YYYY : HH:mm")
                    : ""}
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Client Ref:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input
                    onChange={(e) => setClientRef(e.target.value)}
                    value={clientRef}
                  />
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Service completion date*:&nbsp;
                </Header>
                <TextField
                  id="date"
                  // label="Date"
                  onChange={(e) => setServiceDate(e.target.value)}
                  value={serviceDate}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Header style={{ margin: "0" }} as="h4">
                  Linguist/SP ref:
                </Header>
                <Header color="grey" style={{ margin: "0" }} as="h4">
                  &nbsp;
                  <Input
                    onChange={(e) => setLingRef(e.target.value)}
                    value={lingRef}
                    type="number"
                  />
                </Header>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomAutoSelect
                label="Service"
                width="40%"
                onChange={(e, value) => setService(value)}
                data={QUOTE_REQUEST_FOR}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Header style= {{margin: '0'}} as= 'h4'>Source language:</Header><Header color= 'grey' style= {{margin: '0'}} as= 'h4'>&nbsp;</Header> */}
              <CustomAutoSelect
                label="Source Language"
                width="40%"
                onChange={(e, value) => setSourceLanguage(value)}
                data={LANG_COMB}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Header style= {{margin: '0'}} as= 'h4'>Target language:</Header><Header color= 'grey' style= {{margin: '0'}} as= 'h4'>&nbsp;</Header> */}
              <CustomAutoSelect
                label="Target language"
                width="40%"
                onChange={(e, value) => setTargetLanguage(value)}
                data={LANG_COMB}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Header style= {{margin: '0'}} as= 'h4'>Linguist's expertise level:</Header><Header color= 'grey' style= {{margin: '0'}} as= 'h4'>&nbsp;</Header> */}
              <CustomAutoSelect
                label="Linguist skill level"
                width="40%"
                onChange={(e, value) => setSkillLevel(value)}
                data={SKILL_LEVEL_REQUIRED}
              />
            </div>
          </div>

          <Segment color="purple" textAlign="center">
            <CustomAutoSelect
              width="30%"
              data={CURRENCY_STRIPE}
              onChange={(e, value) => handleCurrency(e, value)}
              value={currency}
            />
          </Segment>

          <Table color="pink">
            <Table.Header>
              {/* <Table.Row>
                                    <Table.HeaderCell width='5'></Table.HeaderCell>
                                    <Table.HeaderCell width='1'></Table.HeaderCell>
                                    <Table.HeaderCell width='1'></Table.HeaderCell>
                                    <Table.HeaderCell width='1'><CustomAutoSelect label='Currency' data={CURRENCY} value='GBP (UK)' /></Table.HeaderCell>
                                </Table.Row> */}
              <Table.Row>
                <Table.HeaderCell width="5">
                  Description of service
                </Table.HeaderCell>
                <Table.HeaderCell width="1">Price £00.00</Table.HeaderCell>
                <Table.HeaderCell width="1">Quantity 00.00</Table.HeaderCell>
                <Table.HeaderCell width="1">Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {transactions.map((tr) => (
                <Table.Row>
                  <Table.Cell>
                    <Header as="h4">{tr.text}</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Header as="h4">{tr.amount}</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Header as="h4">{tr.quantity}</Header>
                  </Table.Cell>
                  <Table.Cell>
                    {tr.total}
                    <IconButton
                      onClick={() => dispatch(deleteTransaction(tr.id))}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row>
                <Table.Cell>
                  <CustomAutoSelect
                    onChange={(e, value) => setText(value)}
                    value={text}
                    label="Choose"
                    data={QUOTE_REQUEST_FOR}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    placeholder="£ 00.00"
                    style={{ margin: "24px auto 0" }}
                    type="number"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    onChange={(e) => setQuantity(e.target.value)}
                    value={quantity}
                    style={{ margin: "24px auto 0" }}
                    type="number"
                  />
                </Table.Cell>
                <Table.Cell>
                  <IconButton
                    disabled={
                      !amount ||
                      !text ||
                      !quantity ||
                      quantity <= 0 ||
                      amount <= 0
                    }
                    onClick={handleAdd}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign>
                  <Header textAlign="right" as="h3">
                    Sub Total
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Statistic size="mini">
                    <Statistic.Value>
                      {currency?.substring(0, 3)} {Number(subTotalOne)}
                    </Statistic.Value>
                  </Statistic>
                </Table.Cell>
              </Table.Row>
              <Table.Row style={{ backgroundColor: "#F9FAFB" }}>
                <Table.Cell>Expenses and travel</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              {otherTransactions.map((tr) => (
                <Table.Row>
                  <Table.Cell>
                    <Header as="h4">{tr.otherText}</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Header as="h4">{tr.otherAmount}</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Header as="h4">{tr.otherQuantity}</Header>
                  </Table.Cell>
                  <Table.Cell>
                    {tr.total}
                    <IconButton
                      onClick={() => dispatch(deleteOtherTransaction(tr.id))}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row>
                <Table.Cell>
                  <CustomAutoSelect
                    onChange={(e, value) => setOtherText(value)}
                    value={otherText}
                    label="Taxi fare"
                    data={TAXIFARE}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    onChange={(e) => setOtherAmount(e.target.value)}
                    value={otherAmount}
                    placeholder="£ 00.00"
                    style={{ margin: "24px auto 0" }}
                    type="number"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    onChange={(e) => setOtherQuantity(e.target.value)}
                    value={otherQuantity}
                    style={{ margin: "24px auto 0" }}
                    type="number"
                  />
                </Table.Cell>
                <Table.Cell>
                  <IconButton
                    disabled={
                      !otherQuantity ||
                      !otherAmount ||
                      !otherText ||
                      otherQuantity <= 0 ||
                      otherAmount <= 0
                    }
                    onClick={handleAddOther}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign>
                  <Header as="h3">Sub Total</Header>
                </Table.Cell>
                <Table.Cell>
                  <Statistic size="mini">
                    <Statistic.Value>
                      {currency?.substring(0, 3)} {subTotalTwo}
                    </Statistic.Value>
                  </Statistic>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign>
                  <Header as="h4">Total</Header>
                </Table.Cell>
                <Table.Cell>
                  <Statistic size="mini">
                    <Statistic.Value>
                      {currency?.substring(0, 3)} {total}
                    </Statistic.Value>
                  </Statistic>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign>
                  <Header as="h4">Discount %</Header>
                  <CustomAutoSelect
                    data={DISCOUNT}
                    onChange={(e, value) => setDiscount(value)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Statistic size="mini">
                    <Statistic.Value>
                      {currency?.substring(0, 3)} {totalDiscount}
                    </Statistic.Value>
                  </Statistic>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign>
                  <Header as="h4">
                    Billing Account Service Charge {companyCard?.basc} %
                  </Header>
                  <CustomAutoSelect
                    data={["0", "10"]}
                    onChange={(e, value) => setBASC(value)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Statistic size="mini">
                    <Statistic.Value>
                      {currency?.substring(0, 3)} {totalBASC}
                    </Statistic.Value>
                  </Statistic>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign>
                  <Header as="h4">
                    Platform and booking service charge {companyCard?.pbsc} %
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Statistic size="mini">
                    <Statistic.Value>
                      {currency?.substring(0, 3)} {totalPBSC}
                    </Statistic.Value>
                  </Statistic>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign>
                  <Header as="h4">Total</Header>
                </Table.Cell>
                <Table.Cell>
                  <Statistic size="mini">
                    <Statistic.Value>
                      {currency?.substring(0, 3)} {totalTwo}
                    </Statistic.Value>
                  </Statistic>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign>
                  <Header as="h4">VAT %</Header>
                  <TextField
                    onChange={(e) => setVAT(e.target.value)}
                    type="number"
                    inputProps={{ min: "0", max: "10", step: "1" }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Statistic size="mini">
                    <Statistic.Value>
                      {currency?.substring(0, 3)} {totalVAT}
                    </Statistic.Value>
                  </Statistic>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign>
                  <Header as="h4">Estimate paid in advance</Header>
                </Table.Cell>
                <Table.Cell>
                  <Statistic size="mini">
                    <Statistic.Value>00.00</Statistic.Value>
                  </Statistic>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign>
                  <Header as="h4">
                    Estimated total to be paid including VAT/GST
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Statistic size="mini">
                    <Statistic.Value>
                      {currency?.substring(0, 3)} {grandTotal}
                    </Statistic.Value>
                  </Statistic>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <div style={{ marginBottom: "24px" }}>
            <CustomAutoSelect
              onChange={(e, value) => setTimezone(value)}
              label="Timezone"
              width="30%"
              data={TIMEZONE}
              value={timezone}
            />
            <CustomDateTime
              onChange={(e) => setExpiryDate(e.target.value)}
              label="Expiry date"
            />
            <div style={{ width: "30%", margin: "24px auto 0 auto" }}>
              <TextField
                onChange={(e) => setAdminNote(e.target.value)}
                fullWidth
                placeholder="Note"
                name="Note"
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Header style={{ marginTop: "16px" }} as="h4">
              Thank You For Your Business
            </Header>
            <Header
              color="grey"
              style={{ marginBottom: "16px", marginTop: "0" }}
              as="h4"
            >
              {companyCard.cname + ", " + companyCard.caddress}
            </Header>
            {!calculate.sendButton && (
              <Button
                disabled={!expiryDate || !client.data?.email || !serviceDate}
                onClick={handleCalculate}
                loading={calculate.loading}
                color="purple"
              >
                Calculate and Save
              </Button>
            )}
            {calculate.sendButton && (
              <Button
                onClick={handleSend}
                loading={calculate.loading}
                color="purple"
              >
                Send
              </Button>
            )}
          </div>
        </GridItem>
      </GridContainer>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default Quotation;
