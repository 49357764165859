// import BIPaymentPage from "pages/account-client/BIPayment";
import BISummaryPageADMIN from "pages/admin/BISummary";
import BISummaryPageEMP from "pages/employee/BISummary";

import BISummaryPageOTAC from "pages/admin/BIsummary_ordertranslation"
import BISummaryPageOTACEMP from "pages/employee/BIsummary_ordertranslation"

import QuoteSummaryBAC from "pages/landing-page/UploadDocument/BACQuotationSummary"
import QuoteSummaryBACEMP from "pages/landing-page/UploadDocument/EMPBACQuotationSummary"

import TranslationDocumentBAC from "pages/landing-page/UploadDocument/BACTranslation"
import InterpretingDocumentBAC from "pages/landing-page/UploadDocument/BACInterpreting"
import quotationDocumentBAC from "pages/landing-page/UploadDocument/BACQuotation"

import TranslationDocumentCAC from "pages/landing-page/UploadDocument/CACTranslation"
import InterpretingDocumentCAC from "pages/landing-page/UploadDocument/CACInterpreting"
import quotationDocumentCAC from "pages/landing-page/UploadDocument/CACQuotation"


import TranslationDocumentINC from "pages/landing-page/UploadDocument/INCTranslation"
import InterpretingDocumentINC from "pages/landing-page/UploadDocument/INCInterpreting"

import TranslationDocumentPRC from "pages/landing-page/UploadDocument/PRCTranslation"
import InterpretingDocumentPRC from "pages/landing-page/UploadDocument/PRCInterpreting"



const BI_ADMIN = [
  {
    id: '25a',
    text: "Summary",
    path: "admin/book-interpreter/summary",
    component: BISummaryPageADMIN,
  },

    {
    id: '30a',
    text: "Summary",
    path: "employee/book-interpreter/summary",
    component: BISummaryPageEMP,
  },

  {
    id: '26a',
    text: "Summary",
    path: "admin/order-translation/summary",
    component: BISummaryPageOTAC,
  },

    {
    id: '31a',
    text: "Summary",
    path: "employee/order-translation/summary",
    component: BISummaryPageOTACEMP,
  },


  {
    id: '28a',
    path: "admin/telephonic-quote-summary",
    component: QuoteSummaryBAC,
  },

   {
    id: '32a',
    path: "employee/telephonic-quote-summary",
    component: QuoteSummaryBACEMP,
  },

  
  // upload document from public page for cac, bac, inc and prc (interpreting, translation and quotation)
  {
    id: '29a',
    path: "CAC/upload-interpreting-document/:id",
    component: InterpretingDocumentCAC,
  },
  {
    id: '27a',
    path: "CAC/upload-translation-document/:id",
    component: TranslationDocumentCAC,
  },
  {
    id: '29a',
    path: "CAC/upload-quotation-document/:id",
    component: quotationDocumentCAC,
  },





  {
    id: '29a',
    path: "BAC/upload-interpreting-document/:id",
    component: InterpretingDocumentBAC,
  },
  {
    id: '27a',
    path: "BAC/upload-translation-document/:id",
    component: TranslationDocumentBAC,
  },
  {
    id: '29a',
    path: "BAC/upload-quotation-document/:id",
    component: quotationDocumentBAC,
  },



  {
    id: '29a',
    path: "INC/upload-interpreting-document/:id",
    component: InterpretingDocumentINC,
  },
  {
    id: '27a',
    path: "INC/upload-translation-document/:id",
    component: TranslationDocumentINC,
  },



  {
    id: '29a',
    path: "PRC/upload-interpreting-document/:id",
    component: InterpretingDocumentPRC,
  },
  {
    id: '27a',
    path: "PRC/upload-translation-document/:id",
    component: TranslationDocumentPRC,
  },

];

export default BI_ADMIN;
