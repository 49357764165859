import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("token");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };


  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("business_name", data.business_name);
    fd.append("position", data.position);
    fd.append("business_email", data.business_email);
    fd.append("business_phone_no", data.business_phone_no);
    fd.append("business_address", data.business_address);
    fd.append("business_address_2", data.business_address_2);
    fd.append("business_town", data.business_town);
    fd.append("business_zipcode", data.business_zipcode);
    fd.append("Country", data.Country);
    fd.append("company_registration_no", data.company_registration_no);
    fd.append("charity_registration_no", data.charity_registration_no);
    fd.append("business_website", data.business_website);
    fd.append("note", data.note);

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/update-cash-client/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="business_name"
            onChange={handleChange}
            label="Business or organization name"
            edit={edit.value}
            value={data.business_name}
          />
          <CustomTextInput
            name="position"
            onChange={handleChange}
            label="Position"
            edit={edit.value}
            value={data.position}
          />
          <CustomTextInput
            name="business_email"
            onChange={handleChange}
            label="Business email address"
            edit={edit.value}
            value={data.business_email}
          />
          <CustomTextInput
            name="business_phone_no"
            onChange={handleChange}
            label="Business phone number"
            edit={edit.value}
            value={data.business_phone_no}
          />
          <CustomTextInput
            name="business_address"
            onChange={handleChange}
            label="House number / Street number"
            edit={edit.value}
            value={data.business_address}
          />
          <CustomTextInput
            name="business_address_2"
            onChange={handleChange}
            label="Address"
            edit={edit.value}
            value={data.business_address_2}
          />
          <CustomTextInput
            name="business_town"
            onChange={handleChange}
            label="Town / City"
            edit={edit.value}
            value={data.business_town}
          />
          <CustomTextInput
            name="business_zipcode"
            onChange={handleChange}
            label="Zip code / Postal code"
            edit={edit.value}
            value={data.business_zipcode}
          />
          <CustomTextInput
            name="Country"
            onChange={handleChange}
            label="Country"
            edit={edit.value}
            value={data.Country}
          />

          <CustomTextInput
            name="company_registration_no"
            onChange={handleChange}
            label="Company registration no"
            edit={edit.value}
            value={data.company_registration_no}
          />

          <CustomTextInput
            name="charity_registration_no"
            onChange={handleChange}
            label="Charity registration no"
            edit={edit.value}
            value={data.charity_registration_no}
          />

          <CustomTextInput
            name="business_website"
            onChange={handleChange}
            label="Business Website"
            edit={edit.value}
            value={data.business_website}
          />
          <CustomTextInput
            name="note"
            onChange={handleChange}
            label="Additional Note"
            edit={edit.value}
            value={data.note}
          />
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
