import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router";
import ContactRegistration from "components/Auth/ContactRegistration/ContactRegistration";

const ContactRegistrationPage = () => {
  const { id } = useParams();

  return (
    <Fragment>
      <ContactRegistration id={id} />
    </Fragment>
  );
};

export default ContactRegistrationPage;
