import { useHistory } from "react-router-dom";

// Materials Core
import { Button } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
    ["@media (max-width:768px)"]: {
      display: "inline-block",
      padding: "0px",
    },

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      ["@media (max-width:768px)"]: {
        margin: "5px",
        width: "100%",
      },
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const Options = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Button
        style={{ marginRight: "10px" }}
        attached="left"
        color="purple"
        icon="add"
        content="Open New Ticket"
        onClick={() => history.push("/cash-client/open-new-ticket")}
      />
      <Button
        attached="left"
        color="purple"
        icon="list"
        content="View Tickets"
        onClick={() => history.push("/cash-client/view-tickets")}
      />
    </div>
  );
};

export default Options;
