import SearchInput from "./SearchInput";
import FindBill from "../SaveBill";
import { useState } from "react";
import { Button } from "semantic-ui-react";
import { useMediaQuery } from "@material-ui/core";

const SearchSavedBill = () => {
  const [searchChat, setSearchChat] = useState("");
  const [findBill, setFindBill] = useState(false);
  const matches = useMediaQuery("(max-width:768px)");
  const handleSearch = (e) => {
    setSearchChat(e.target.value);
  };

  const showClients = (e) => {
    setFindBill(true);
  };
  return (
    <div style={{ marginTop: "24px" }}>
      <SearchInput searchChat={searchChat} handleSearch={handleSearch} />
      {matches ? (
        <Button style={{ width: "100%" }} color="purple" onClick={showClients}>
          View all saved invoices
        </Button>
      ) : (
        <Button
          style={{ marginTop: "24px" }}
          color="purple"
          onClick={showClients}
        >
          View all saved invoices
        </Button>
      )}
      {findBill && <FindBill searchChat={searchChat} />}
      {findBill && (
        <Button style={{ marginTop: "24px" }} color="purple">
          Export PDF
        </Button>
      )}
      {findBill && (
        <Button style={{ marginTop: "24px" }} color="purple">
          Export Excel
        </Button>
      )}
    </div>
  );
};

export default SearchSavedBill;
