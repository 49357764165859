import { makeStyles } from "@material-ui/core/styles";

import { successColor, whiteColor, grayColor, hexToRgb } from "../variables";

const dashboardStyle = {
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "18px",
    paddingTop: "0px",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardSettingsDiv: {
    display: "flex",
    alignItems: "center",
  },

  listDiv: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "240px",
    justifyContent: "space-between",
  },
  listsDiv: {},
  listItemIcon: {
    minWidth: "30px",
  },
  taskDiv: {
    textAlign: "right",
  },
  textCenter: {
    textAlign: "center",
  },
  boxDiv: {
    minHeight: "200px",
    display: "flex",
    alignItems: "flex-end",
    "& .MuiButton-root": {
      minWidth: "30px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
  flexP: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    marginLeft: "1rem",
  },
  attachDiv: {
    "& .MuiIconButton-label": {
      transform: "rotate(-45deg)",
    },
    "& .MuiIconButton-root": {
      minWidth: "10px",
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingRight: "0px",
      paddingLeft: "0px",
    },
  },
};

const useDashboardStyles = makeStyles(dashboardStyle);

export default useDashboardStyles;
