const { CHANGE_VERIFIED_ACCESS } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    verified: false,
};


const unverifiedLinguistReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case CHANGE_VERIFIED_ACCESS:
            return {
                ...state,
                verified: payload,
            };
        
        default:
            return state
    }
}

export default unverifiedLinguistReducer
