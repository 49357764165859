import React from "react";

// Custom Components
import CommonCard from "components/Common/CommonCard";
import { PayPalButton } from "react-paypal-button-v2";
import jobImage from "assets/icons/jobview-buttton.png";

const PromotionalMessage = () => {
  return (
    <div style={{ margin: "5px" }}>
      <CommonCard title="View Job Adverts!">
        <a
          href="https://linguistscollective.com/post-job-advert"
          target="_blank"
        >
          <img style={{ width: "100%" }} src={jobImage} alt="" />
        </a>
      </CommonCard>
    </div>
  );
};

export default PromotionalMessage;
