import { Fragment, useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

// Material Components
import Button from "@material-ui/core/Button";
import CustomPassword from "components/Common/CustomPassword";
import defaultAxios from "utils/axios";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { PASS_REGEX } from "data";

const CashClientSetPassword = ({ CACtoken }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const [notRegex, setNotRegex] = useState(false);

  const [data, setData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const { newPassword, confirmPassword } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleNewChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    if (e.target.value !== "" && PASS_REGEX.test(e.target.value)) {
      setNotRegex(false);
    } else {
      setNotRegex(true);
    }
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    const sendData = {
      resetPasswordLink: token,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };
    try {
      const response = await defaultAxios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/reset-password`,
        sendData
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/cash-client-login";
      });
      setLoading(false);
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <Fragment>
      <CustomPassword
        label="New Password"
        name="newPassword"
        value={newPassword}
        onChange={handleNewChange}
        notRegex={notRegex}
      />
      <CustomPassword
        label="Confirm Password"
        name="confirmPassword"
        value={confirmPassword}
        onChange={handleChange}
        validation={newPassword !== confirmPassword}
      />
      <Button
        color="primary"
        variant="contained"
        style={{
          marginTop: "15px",
        }}
        onClick={handleSubmit}
        disabled={
          !newPassword ||
          !confirmPassword ||
          loading ||
          newPassword !== confirmPassword ||
          notRegex
        }
      >
        {loading ? "Submitting" : "Submit"}
      </Button>
    </Fragment>
  );
};

export default CashClientSetPassword;
