import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import swal from "sweetalert";
import { Button } from "semantic-ui-react";
import Cookies from "js-cookie";


const Action = ({
  id, LinQuoteID, load, reqQuoteCAC, reqQuoteBAC,
  cacInterpreting, bacInterpreting, incInterpreting, prcInterpreting,
  cacTranslation, bacTranslation, incTranslation, prcTranslation
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get('token')


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const deleteCACQuoteReq = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-quote-request-cashclient/quoteID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleCACQuoteDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this quote?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deleteCACQuoteReq(value);

          default:
            break;
        }
      });
  }


  const deleteBACQuoteReq = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-quote-request-accountclient/quoteID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleBACQuoteDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this quote?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deleteBACQuoteReq(value);

          default:
            break;
        }
      });
  }


  const deleteLINQuote = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-lin-quote/linQuoteID/${LinQuoteID}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleLINQuoteDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this quote?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deleteLINQuote(value);

          default:
            break;
        }
      });
  }


  const deleteCACInterpreting = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-interpreting-cashclient/interpretingID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleCACInterpretingDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this assignment?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deleteCACInterpreting(value);

          default:
            break;
        }
      });
  }

  const deleteBACInterpreting = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-interpreting-accountclient/interpretingID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleBACInterpretingDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this assignment?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deleteBACInterpreting(value);

          default:
            break;
        }
      });
  }

  const deleteINCInterpreting = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-inhouse-interpreting/interpretingID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleINCInterpretingDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this assignment?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deleteINCInterpreting(value);

          default:
            break;
        }
      });
  }

  const deletePRCInterpreting = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-private-interpreting/interpretingID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handlePRCInterpretingDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this assignment?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deletePRCInterpreting(value);

          default:
            break;
        }
      });
  }

  const deleteCACTranslation = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-translation-cashclient/translationID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleCACTranslationDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this assignment?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deleteCACTranslation(value);

          default:
            break;
        }
      });
  }

  const deleteBACTranslation = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-translation-accountclient/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleBACTranslationDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this assignment?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deleteBACTranslation(value);

          default:
            break;
        }
      });
  }

  const deleteINCTranslation = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/remove-inhouse-translation/translationID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleINCTranslationDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this assignment?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deleteINCTranslation(value);

          default:
            break;
        }
      });
  }

  
  const deletePRCTranslation = async (e) => {
    try {
      const response = await Axios.delete(`${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-private-translation/translationID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      swal({
        icon: 'success',
        text: `${response?.data.message}`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handlePRCTranslationDelete = async (e, id) => {
    swal(`Are you sure you want to permanently delete this assignment?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            deletePRCTranslation(value);

          default:
            break;
        }
      });
  }





  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}

      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {reqQuoteCAC ?
          <MenuItem onClick={handleCACQuoteDelete}>Delete</MenuItem> : null}
        {reqQuoteBAC ?
          <MenuItem onClick={handleBACQuoteDelete}>Delete</MenuItem> : null}
        {LinQuoteID ?
          <MenuItem onClick={handleLINQuoteDelete}>Delete</MenuItem> : null}
        {cacInterpreting ?
          <MenuItem onClick={handleCACInterpretingDelete}>Delete</MenuItem> : null}
        {bacInterpreting ?
          <MenuItem onClick={handleBACInterpretingDelete}>Delete</MenuItem> : null}
        {incInterpreting ?
          <MenuItem onClick={handleINCInterpretingDelete}>Delete</MenuItem> : null}
        {prcInterpreting ?
          <MenuItem onClick={handlePRCInterpretingDelete}>Delete</MenuItem> : null}
        {cacTranslation ?
          <MenuItem onClick={handleCACTranslationDelete}>Delete</MenuItem> : null}
        {bacTranslation ?
          <MenuItem onClick={handleBACTranslationDelete}>Delete</MenuItem> : null}
        {incTranslation ?
          <MenuItem onClick={handleINCTranslationDelete}>Delete</MenuItem> : null}
        {prcTranslation ?
          <MenuItem onClick={handlePRCTranslationDelete}>Delete</MenuItem> : null}
      </Menu>

    </div>
  );
};

export default Action;
