import React, { useState, useEffect } from "react";
import CommonCard from "components/Common/CommonCard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import OneCard from "./OneCard";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Images
import InvoiceIcon from "assets/icons/invoice.png";
import JobIcon from "assets/icons/job-post.png";
import VATIcon from "assets/icons/VAT ICON-02.png";

import { ReportCardsDiv } from "./style";

const ReportCards = () => {
  // const [showBills, setShowBills] = useState(false)
  const [showAccepted, setShowAccepted] = useState(false);
  // const [showInvoice, setShowInvoice] = useState(false)
  const [invoice, setInvoice] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [vat, setVat] = useState(null);
  const [interpreting, setInterpreting] = useState(null);
  const [translation, setTranslation] = useState(null);
  const [acceptedInterpreting, setAcceptedInterpreting] = useState(null);
  const [acceptedTranslation, setAcceptedTranslation] = useState(null);
  const [quotation, setQuotation] = useState(null);
  const [bill, setBill] = useState();
  const token = Cookies.get("BACtoken");

  // const handleBillShow = () => {
  //   setShowJobs(false)
  //   setShowInvoice(false)
  //   setShowBills(!showBills)
  // }
  const handleJobShowAccepted = () => {
    setShowAccepted(!showAccepted);
    // setShowInvoice(false)
    // setShowBills(false)
  };
  // const handleInvoiceShow = () => {
  //   setShowJobs(false)
  //   setShowInvoice(!showInvoice)
  //   setShowBills(false)
  // }

  // useEffect(() => {
  //   const getInvoiceDetails = async () => {
  //     try {
  //       const response = await Axios.get(
  //         `/api/accountclient/count-total-invoice`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       setInvoice(response?.data);
  //     } catch (error) {
  //       console.log(error.response?.data);
  //     }
  //   };

  //   const getBillDetails = async () => {
  //     try {
  //       const response = await Axios.get(
  //         `/api/accountclient/count-total-Bill`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       setBill(response?.data);
  //     } catch (error) {
  //       console.log(error.response?.data);
  //     }
  //   };

  //   const getjobsDetails = async () => {
  //     try {
  //       const response = await Axios.get(
  //         `/api/accountclient/count-total-jobs`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       setJobs(response?.data);
  //     } catch (error) {
  //       console.log(error.response?.data);
  //     }
  //   };

  //   const getVatDetails = async () => {
  //     try {
  //       const response = await Axios.get(
  //         `/api/accountclient/count-total-vats`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       setVat(response?.data);
  //     } catch (error) {
  //       console.log(error.response?.data);
  //     }
  //   };

  //   getjobsDetails();
  //   getBillDetails();
  //   getInvoiceDetails();
  //   getVatDetails();
  // }, []);

  useEffect(() => {
    const getBillDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/count-total-Bill`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setBill(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getInvoiceDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/count-total-invoice`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setInvoice(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    const getjobsDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/count-total-jobs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setJobs(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    const getVatDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/count-total-vats`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setVat(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getInterpretingDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/count-total-interpretings`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setInterpreting(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getAcceptedInterpretingDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/count-total-Accepted-interpretings`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAcceptedInterpreting(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getTranslationDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/count-total-translation`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setTranslation(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getAcceptedTranslationDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/count-total-Accepted-translation`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAcceptedTranslation(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getQuotationDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/count-total-quotation`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setQuotation(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    getBillDetails();
    getjobsDetails();
    getInvoiceDetails();
    getVatDetails();
    getInterpretingDetails();
    getTranslationDetails();
    getQuotationDetails();
    getAcceptedInterpretingDetails();
    getAcceptedTranslationDetails();
  }, []);

  // const renderInterpretingAcceptedChart = async () => {
  //   const sdk = new ChartsEmbedSDK({
  //     baseUrl: "https://charts.mongodb.com/charts-lc-project-rdlgu", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
  //     chartId: "e4252c15-46d5-44f2-b6aa-279cb5125b71",
  //     getUserToken: async function () {
  //       return { cacUser: token };
  //     },
  //   });
  // };
  // const chart = sdk.createChart({
  //   chartId: "d98f67cf-374b-4823-a2a8-f86e9d480065",
  // }); // ~REPLACE~ with the Chart ID from your Embed Chart dialog
  // const renderInterpretingActiveChart = async () => {
  //   const sdk = new ChartsEmbedSDK({
  //     baseUrl: "https://charts.mongodb.com/charts-lc-project-rdlgu", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
  //     getUserToken: async function () {
  //       return await login(getUser(), getPass());
  //     },
  //   });
  // };
  // const renderInterpretingCancelledChart = async () => {
  //   const sdk = new ChartsEmbedSDK({
  //     baseUrl: "https://charts.mongodb.com/charts-lc-project-rdlgu", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
  //     getUserToken: async function () {
  //       return await login(getUser(), getPass());
  //     },
  //   });
  // };
  // const renderInterpretingSLChart = async () => {
  //   const sdk = new ChartsEmbedSDK({
  //     baseUrl: "https://charts.mongodb.com/charts-lc-project-rdlgu", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
  //     getUserToken: async function () {
  //       return await login(getUser(), getPass());
  //     },
  //   });
  // };

  // const renderInterpretingTLChart = async () => {
  //   const sdk = new ChartsEmbedSDK({
  //     baseUrl: "https://charts.mongodb.com/charts-lc-project-rdlgu", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
  //     getUserToken: async function () {
  //       return await login(getUser(), getPass());
  //     },
  //   });
  // };
  // const renderInterpretingPayedChart = async () => {
  //   const sdk = new ChartsEmbedSDK({
  //     baseUrl: "https://charts.mongodb.com/charts-lc-project-rdlgu", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
  //     getUserToken: async function () {
  //       return await login(getUser(), getPass());
  //     },
  //   });
  // };

  let totlVat = 0;
  for (let i = 0; i <= vat?.length - 1; i++) {
    {
      totlVat += vat[i].vat_percentage;
    }
  }

  return (
    <ReportCardsDiv>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CommonCard
            style={{ background: `linear-gradient(60deg, #ab47bc, #8e24aa)` }}
            // onClick={handleInvoiceShow}
          >
            <OneCard
              icon={InvoiceIcon}
              value={invoice ? invoice : 0}
              text="Invoices"
            />
          </CommonCard>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CommonCard
            style={{ background: `linear-gradient(60deg, #ab47bc, #8e24aa)` }}
            // onClick={handleInvoiceShow}
          >
            <OneCard icon={InvoiceIcon} value={bill ? bill : 0} text="Bills" />
          </CommonCard>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CommonCard
            style={{ background: `linear-gradient(60deg, #ab47bc, #8e24aa)` }}
          >
            <OneCard
              icon={VATIcon}
              value={totlVat}
              text="Total VAT / GST
            "
            />
          </CommonCard>
        </GridItem>

        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CommonCard
            style={{ background: `linear-gradient(60deg, #ab47bc, #8e24aa)` }}
            // onClick={handleJobShow}
          >
            <OneCard icon={JobIcon} value={jobs ? jobs : 0} text="Total Jobs" />
          </CommonCard>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CommonCard
            style={{ background: `linear-gradient(60deg, #ab47bc, #8e24aa)` }}
            onClick={handleJobShowAccepted}
          >
            <OneCard
              icon={JobIcon}
              value={acceptedInterpreting + acceptedTranslation}
              text="Accepted Jobs"
            />
          </CommonCard>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CommonCard
            style={{ background: `linear-gradient(60deg, #ab47bc, #8e24aa)` }}
            // onClick={handleInvoiceShow}
          >
            <OneCard
              icon={JobIcon}
              value={interpreting ? interpreting : 0}
              text="Interpreting Jobs"
            />
          </CommonCard>
        </GridItem>

        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CommonCard
            style={{ background: `linear-gradient(60deg, #ab47bc, #8e24aa)` }}
            // onClick={handleJobShow}
          >
            <OneCard
              icon={JobIcon}
              value={translation ? translation : 0}
              text="Translation Jobs"
            />
          </CommonCard>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CommonCard
            style={{ background: `linear-gradient(60deg, #ab47bc, #8e24aa)` }}
          >
            <OneCard
              icon={JobIcon}
              value={quotation ? quotation : 0}
              text="Quotation Jobs"
            />
          </CommonCard>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={12} lg={12}>
          {showJobs && <TotalJobsTableData />}
          {showInvoice && <InvoicesTableData />}
        </GridItem> */}
      </GridContainer>
    </ReportCardsDiv>
  );
};

export default ReportCards;
