import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import CustomInput from "components/Common/CustomInput";
import CustomSelect from "components/Common/CustomSelect";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";

import {
  PRIMARY_TARGET_LANG,
  TRANSLATION_CERTIFICATE,
  DOCUMENT_TYPE,
  PROOFREADING,
  SOURCE_LANG_EXPERTISE_LEVEL,
  TARGET_LANG_EXPERTISE_LEVEL,
  RARE_REGEX,
  LEVEL_REGEX,
} from "data";
// import CURRENCIES from "data/currencies";
import { QuoteDiv } from "./style";

const data = [
  "GBP (UK)",
  "EUR (EU)",
  "AUD (Australia)",
  "USD (USA)",
  "CAD (Canada)",
  "TRY (Turkish lira)",
  "SGD (Singapore dollar)",
  "HUF (Hungarian forint)",
  "NZD (New Zealand dollar)",
  "RON (Romanian leu)",
];
const langLevel = {
  "Partner / Expert Level 5: Qualified, experienced and expert linguist":
    "labelFive",
  "Associate Level 4: Qualified and experienced linguist": "labelFour",
  "Associate Level 4R: Experienced Rare linguist": "labelFourR",
  "Co-operator Level 3: Rare language linguist": "labelThree",
  "Junior Level 2: Apprentice or qualified by experienced linguist": "labelTwo",
  "Assistant Level 1:  Trainee, student or qualified by experienced linguist":
    "labelOne",
};

const QuickQuote = () => {
  const { rates, selectedCurrency, setCurrencyValue } = useSelector(
    (state) => state.getDataReducer
  );

  const classes = useLandingpageStyles();
  const [rate, setRates] = useState();
  // const [result, setResult] = useState();
  const [value, setValue] = useState({
    sourceLang: "",
    sourceLangExp: "",
    targetLang: "",
    targetLangExp: "",
    documentType: "",
    transCertificate: "",
    proofCertificate: "",
    securityClearance: "",
    wordCounts: "",
    currency: "GBP (UK)",
    result: 0,
  });
  const { result } = value;
  useEffect(() => {
    getRates();
  }, []);

  const getRates = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}/admin/rate_card`
    );
    const rates = response?.data?.filter((dt) => [2, 3].includes(dt.order));
    console.clear();
    setRates(rates);
  };

  const handleEstimate = () => {
    const fLevel = langLevel[value.targetLangExp];
    const wordCounts = +value.wordCounts;
    const ratesValue = rate.map((rate) => rate[fLevel]);
    let results = wordCounts * ratesValue[0] + wordCounts * ratesValue[1];

    if (results) {
      results = results * rates[value.currency.split(" ")[0]];
      setValue({ ...value, result: results });
    }
  };

  return (
    <QuoteDiv>
      <div className="row">
        <div className="left">Source / Native language*</div>
        <div className="right">
          <CustomAutoSelect
            width="100%"
            value={value.sourceLang}
            onChange={(e, val) =>
              setValue({
                ...value,
                sourceLang: val,
              })
            }
            name="source_language"
            data={PRIMARY_TARGET_LANG.filter((val) => val !== value.targetLang)}
          />
        </div>
      </div>
      <div className="row">
        <div className="left">Source / Native language expertise level*</div>
        <div className="right">
          <CustomAutoSelect
            width="100%"
            data={
              value?.sourcelang?.includes("(R)")
                ? SOURCE_LANG_EXPERTISE_LEVEL.filter((item) =>
                    item.match(RARE_REGEX)
                  )
                : SOURCE_LANG_EXPERTISE_LEVEL.filter((item) =>
                    item.match(LEVEL_REGEX)
                  )
            }
            value={value.sourceLangExp}
            onChange={(e, val) =>
              setValue({
                ...value,
                sourceLangExp: val,
              })
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="left">Target language*</div>
        <div className="right">
          <CustomAutoSelect
            width="100%"
            value={value.targetLang}
            onChange={(e, val) =>
              setValue({
                ...value,
                targetLang: val,
              })
            }
            data={PRIMARY_TARGET_LANG.filter((val) => val !== value.sourceLang)}
            name="target_language"
          />
        </div>
      </div>
      <div className="row">
        <div className="left">Target language expertise level*</div>
        <div className="right">
          <CustomAutoSelect
            width="100%"
            data={
              value?.targetlang?.includes("(R)")
                ? TARGET_LANG_EXPERTISE_LEVEL.filter((item) =>
                    item.match(RARE_REGEX)
                  )
                : TARGET_LANG_EXPERTISE_LEVEL.filter((item) =>
                    item.match(LEVEL_REGEX)
                  )
            }
            value={value.targetLangExp}
            onChange={(e, val) =>
              setValue({
                ...value,
                targetLangExp: val,
              })
            }
            // data={
            //   value?.targetlang?.includes("(R)")
            //     ? TARGET_LANG_EXPERTISE_LEVEL.filter((item) =>
            //         item.match(RARE_REGEX)
            //       )
            //     : TARGET_LANG_EXPERTISE_LEVEL.filter((item) =>
            //         item.match(LEVEL_REGEX)
            //       )
            // }
            // value={value.targetLangExp}
            // onChange={(e, val) =>
            //   setValue({
            //     ...value,
            //     targetLangExp: value?.targetlang?.includes("(R)")
            //       ? val.match(RARE_REGEX)
            //       : val.match(LEVEL_REGEX),
            //   })
            // }
          />
        </div>
      </div>
      <div className="row">
        <div className="left">Document type*</div>
        <div className="right">
          <CustomAutoSelect
            width="100%"
            data={DOCUMENT_TYPE}
            value={value.documentType}
            onChange={(e, val) =>
              setValue({
                ...value,
                documentType: val,
              })
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="left">
          Do you require this translation to be certified by a qualified
          translator?
        </div>
        <div className="right">
          <CustomSelect
            width="100%"
            data={TRANSLATION_CERTIFICATE}
            value={value.transCertificate}
            onChange={(e) =>
              setValue({
                ...value,
                transCertificate: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="left">
          Would you require additional quality check / proofreading?
        </div>
        <div className="right">
          <CustomSelect
            width="100%"
            data={PROOFREADING}
            value={value.proofCertificate}
            onChange={(e) =>
              setValue({
                ...value,
                proofCertificate: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="row">
        <div
          className="left"
          style={{
            marginTop: "10px",
          }}
        >
          Approximate word counts* (maximum 10,000 words)
        </div>
        <div className="right">
          <CustomInput
            width="100%"
            value={value.wordCounts}
            placeholder="Number of words e.g. 1000"
            onChange={(e) => {
              if (Number(e.target.value) && Number(e.target.value) > 10000) {
                return false;
              }
              if (isNaN(Number(e.target.value))) {
                return false;
              }
              setValue({
                ...value,
                wordCounts: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="left">Currency</div>
        <div className="right">
          <CustomSelect
            label="Currency"
            width="100%"
            name="Currency"
            data={data}
            value={value.currency}
            onChange={(e) =>
              setValue({
                ...value,
                currency: e.target.value,
                result: 0,
              })
            }
          />
        </div>
      </div>
      <div className="row">
        <div
          className="left"
          style={{
            marginTop: "10px",
          }}
        >
          <Button
            className={`${classes.button}`}
            color="primary"
            variant="contained"
            onClick={handleEstimate}
            disabled={
              !rate ||
              !value.sourceLangExp ||
              !value.targetLang ||
              !value.targetLangExp ||
              !value.wordCounts
            }
            style={{ marginTop: 10, marginRight: 10 }}
          >
            Provide me an instant estimate
          </Button>
        </div>
        <div className="right">
          Approximately - {result ? value.currency.split(" ")[0] : ""}{" "}
          {result ? result.toFixed(2) : null}
        </div>
      </div>
    </QuoteDiv>
  );
};

export default QuickQuote;
