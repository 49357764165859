import React, { useState, useEffect } from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

import Axios from "utils/axios";
import Cookie from "js-cookie";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import TicketOptions from "components/Admin/Communication/EMP/EMPTicketOptions";
import TicketList from "components/Admin/Communication/Admin/TicketList";
import ViewTicket from "components/Admin/Communication/EMP/ViewTicket";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    minWidth: "fit-content",
  },
  chatSection: {
    width: "100%",
    height: "max-content",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "max-content",
    overflowY: "auto",
  },
});

const CommunicationMenu = () => {
  const classes = useDashboardStyles();
  const classess = useStyles();
  const [values, setValues] = useState({ reload: false });
  const token = Cookie.get("EMPtoken");
  const { providedQuotes } = useSelector(
    (state) => state.unconfirmQuotesReducer
  );
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const [tickets, setTickets] = useState([]);

  const TicketDetails = async (id) => {
    try {
      const response = await Axios.get(
        `/api/ticket-system/view-ticket-details/ticketID/${id}`
      );

      setTickets(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/ticket-list-ADM`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      filtered.length > 0 ? setValues(filtered) : setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
    setFiltered([]);
  }, [filter]);

  return (
    <Employee>
      <EmployeeLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <p className={classes.cardTitleWhite}>
                  Communication - Support Tickets - Viewing Support Tickets
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
              <CardBody>
                <TicketOptions />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardBody>
                <Grid
                  container
                  component={Paper}
                  className={classess.chatSection}
                >
                  <Grid item xs={5} className={classess.borderRight500}>
                    <TicketList
                      values={values}
                      TicketDetails={TicketDetails}
                      load={loadList}
                      setValues={setValues}
                      setFilter={setFilter}
                      setFiltered={setFiltered}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    {providedQuotes && (
                      <ViewTicket
                        id={providedQuotes}
                        token={token}
                        tickets={tickets}
                      />
                    )}
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </EmployeeLayout>
    </Employee>
  );
};

export default CommunicationMenu;
