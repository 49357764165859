import { Card } from "@material-ui/core";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import OTicon from "assets/icons/book-translation-06.png";
import BIicon from "assets/icons/order-translation.png";
import QRicon from "assets/icons/quote-request.png";
import Cookies from "js-cookie";
const ThreeCards = () => {
  const classes = useLandingpageStyles();
  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card
            raised
            style={{
              marginBottom: "1rem",
              marginTop: "3rem",
            }}
          >
            <CardBody>
              <Link
                style={{ textDecoration: "none" }}
                to={
                  sessionStorage.getItem("frontUser") ||
                  (Cookies.get("BACtoken") &&
                    localStorage.getItem("BACuser")) ||
                  (Cookies.get("CACtoken") && localStorage.getItem("CACuser"))
                    ? "/interpreting-or-translation/interpreting-form"
                    : "/interpreting-or-translation"
                }
              >
                <div className={classes.card}>
                  <img
                    style={{
                      width: "50px",
                      height: "45px",
                      marginRight: "5px",
                    }}
                    src={BIicon}
                    alt=""
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#9c27b0",
                    }}
                  >
                    Book An Interpreter
                  </p>
                </div>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card
            raised
            style={{
              marginBottom: "1rem",
              marginTop: "3rem",
            }}
          >
            <CardBody>
              <Link
                style={{ textDecoration: "none" }}
                to={
                  Cookies.get("CACtoken") ||
                  (Cookies.get("BACtoken") &&
                    sessionStorage.getItem("frontUser")) ||
                  (Cookies.get("BACtoken") &&
                    localStorage.getItem("BACuser")) ||
                  (Cookies.get("CACtoken") && localStorage.getItem("CACuser"))
                    ? "/interpreting-or-translation/translation-form"
                    : "/interpreting-or-translation"
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "5px",
                    }}
                    // src="http://linguistscollective.uk/book.png"
                    src={OTicon}
                    alt=""
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#9c27b0",
                    }}
                  >
                    Order Translation
                  </p>
                </div>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card
            raised
            style={{
              marginBottom: "1rem",
              marginTop: "3rem",
            }}
          >
            <CardBody>
              <Link
                style={{ textDecoration: "none" }}
                to={
                  Cookies.get("CACtoken") ||
                  (Cookies.get("BACtoken") &&
                    sessionStorage.getItem("frontUser")) ||
                  (Cookies.get("BACtoken") &&
                    localStorage.getItem("BACuser")) ||
                  (Cookies.get("CACtoken") && localStorage.getItem("CACuser"))
                    ? "/interpreting-or-translation/quotation-form"
                    : "/interpreting-or-translation"
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "5px",
                    }}
                    src={QRicon}
                    // src="http://linguistscollective.uk/quote.png"
                    alt=""
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#9c27b0",
                    }}
                  >
                    Request A Quote
                  </p>
                </div>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Fragment>
  );
};

export default ThreeCards;
