import { GET_USER_DETAILS } from "../actions/actionTypes";

const INITAL_STATE = {
  user: null,
};

const userReducer = (state = INITAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_DETAILS:
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
