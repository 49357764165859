import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import Cookies from 'js-cookie'
import { useParams } from 'react-router-dom';



const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {

  const { id } = useParams()
  const { addToast } = useToasts();
  const classes = useStyles();
  const token = Cookies.get('token')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [paidModal, setPaidModal] = React.useState(false)
  const [notepaidModal, setNotePaidModal] = React.useState(false)
  const [viewnotepaidModal, setViewNotePaidModal] = React.useState(false)
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const [pay, setPay] = useState({
    message: '',
    claID: '',
    admin_note: ''
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({ reload: false });
  const [notevalues, setNoteValues] = useState({ reload: false });
  const {
    notemessageList,
  } = values;

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-invoice-message/prcID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-invoice-note/prcID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),

    ]).then(
      Axios.spread((noteList, messageList) => {
        const history = noteList.data
          .concat(messageList.data)
        setValues({ ...values, notemessageList: history });
      })
    );
  };


  return (

    <>
      <h3>Invoice note and messages</h3>
      <Paper className={classes.root}>

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" id="export">
            <TableHead>
              <TableRow>

                <TableCell>Note</TableCell>
                <TableCell>Admin Name</TableCell>
                <TableCell>Admin ID</TableCell>
                <TableCell>Created Date</TableCell>

              </TableRow>
            </TableHead>

            {notemessageList?.length > 0 ?
              <TableBody>
                {notemessageList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((list) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={list?._id}
                    >
                      <TableCell>{list?.note}</TableCell>
                      <TableCell>{list?.postedBy?.LastName}</TableCell>
                      <TableCell> {list?.postedBy?.id} </TableCell>

                      <TableCell>
                        {Moment(list?.createdAt).tz('Europe/London').format(
                          "DD MMM YYYY - kk:mm:ss"
                        )}</TableCell>

                    </TableRow>
                  ))}
              </TableBody>
              : "No data found"}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={notemessageList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

      </Paper>
    </>
  );
}
