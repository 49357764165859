import FormField from "./CACFormField";
import Cookies from "js-cookie";
import Axios from "axios";
import React, { useState, useEffect } from "react";

const BillingDetailsFields = () => {
  const [values, setValues] = useState(null);

  const getCACDetails = async (e) => {
    const token = Cookies.get("CACtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/cashclient/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setValues(response?.data);
  };
  useEffect(() => {
    getCACDetails();
  }, []);

  return values ? (
    <>
      <FormField
        name="name"
        label="First Name(s)"
        type="text"
        placeholder=""
        value={values.FirstName}
      />
      <FormField
        name="last_name"
        label="Last Name"
        type="text"
        placeholder=""
        value={values.LastName}
      />
      <FormField
        name="email"
        label="Email"
        type="email"
        placeholder=""
        value={values.email}
      />
      <FormField
        name="address"
        label="House No/Name, Street Name"
        type="text"
        placeholder=""
        value={values.AddressLine1}
      />
      <FormField
        name="line2"
        label="Address line 2"
        type="text"
        value={values.AddressLine2}
        placeholder=""
      />
      <FormField
        name="city"
        label="Town/City"
        type="text"
        placeholder=""
        value={values.City}
      />
      <FormField
        name="state"
        label="State/Region/County"
        type="text"
        placeholder=""
        value={values.Region}
      />
      <FormField
        name="zip"
        label="Post/Zip"
        type="text"
        placeholder=""
        value={values.PostalCode}
      />
      {/* <FormField
        name="country"
        label="Country"
        type="text"
        placeholder=""
        
      /> */}
      <FormField
        name="mobile_no"
        label="Mobile number"
        type="tel"
        placeholder=""
        value={values.mobile_no}
      />
      <FormField
        // name="dob"
        // label="Date of birth"
        // type="date"
        // value={values.Birthday}
        // placeholder=""
        name="dob"
        label="Date of birth"
        type="text"
        placeholder=""
        value={values.Birthday}
      />
    </>
  ) : null;
};

export default BillingDetailsFields;
