import { useDispatch, useSelector } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import CustomInput from "components/Common/CustomInput";
import DescriptionDropdown from "./DescriptionDropdown";
import {
  addDescription,
  removeDescription,
  descriptionQutation,
} from "redux/actions/admin/invoice";

const ServiceTable = () => {
  const dispatch = useDispatch();

  const { description } = useSelector((state) => state.invoiceReducers);
  const { rates, selectedCurrency, setCurrencyValue } = useSelector(
    (state) => state.getDataReducer
  );

  const convertedRates = rates ? rates[selectedCurrency] : 1;

  console.clear();

  const numLength = description.length;

  const handleChange = (value, type, data) => {
    data[type] = value;

    dispatch(descriptionQutation(data));
  };

  console.clear();

  const subTotal = description.reduce(
    (acc, item) => acc + Number(item.quantity) * Number(item.rate),
    0
  );

  return (
    <>
      {description
        .filter((data) => data.isReadOnly)
        .map((item) => (
          <tr key={item.id}>
            <td colSpan="2">
              <p>{item.service}</p>
            </td>
            <td className="pt-25">
              <CustomInput
                placeholder="Quantity"
                value={item.quantity}
                width="100%"
                onChange={(e) => handleChange(e.target.value, "quantity", item)}
              />
            </td>
            <td className="pt-25">
              <CustomInput
                placeholder="Rate"
                width="100%"
                value={item.rate}
                onChange={(e) => handleChange(e.target.value, "rate", item)}
              />
            </td>
            <td className="pt-25">
              <p>
                {setCurrencyValue}
                {isNaN(Number(item.quantity) * Number(item.rate))
                  ? "0.00"
                  : (
                      Number(item.quantity) *
                      Number(item.rate) *
                      convertedRates
                    ).toFixed(2)}
              </p>
            </td>
          </tr>
        ))}
      {description
        .filter((data) => !data.isReadOnly)
        .map((data) => (
          <>
            <tr key={data.id}>
              <td colSpan="2">
                <DescriptionDropdown
                  onChange={(e) =>
                    handleChange(e.target.value, "service", data)
                  }
                  value={data.service}
                />
              </td>
              <td className="pt-25">
                <CustomInput
                  placeholder="Quantity"
                  width="100%"
                  value={data.quantity}
                  onChange={(e) =>
                    handleChange(e.target.value, "quantity", data)
                  }
                />
              </td>
              <td className="pt-25">
                <CustomInput
                  placeholder="Rate"
                  width="100%"
                  value={data.rate}
                  onChange={(e) => handleChange(e.target.value, "rate", data)}
                />
              </td>
              <td className="pt-25">
                <p>
                  {setCurrencyValue}
                  {isNaN(Number(data.quantity) * Number(data.rate))
                    ? "0.00"
                    : (Number(data.quantity) * Number(data.rate)).toFixed(2)}
                </p>
                <IconButton
                  aria-label="add"
                  color="primary"
                  onClick={() => dispatch(removeDescription(data))}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </td>
            </tr>
            {data.service.toLowerCase().includes("other") ? (
              <tr>
                <td colSpan="2" className="other-input">
                  <CustomInput
                    placeholder="Write here..."
                    width="100%"
                    onChange={(e) =>
                      handleChange(e.target.value, "service", data)
                    }
                    value={data.service}
                  />
                </td>
              </tr>
            ) : null}
          </>
        ))}

      {/* Sub Total */}
      <tr>
        <td colSpan="2"></td>
        <td className=""></td>
        <td className=""></td>
        <td
          className=""
          style={{
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            aria-label="add"
            color="primary"
            onClick={() => dispatch(addDescription())}
            style={{
              marginRight: "2rem",
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </td>
      </tr>
      <tr>
        <td colSpan="2"></td>
        <td className="pt-25"></td>
        <td className="pt-25">
          <p>
            <strong>Sub Total</strong>
          </p>
        </td>
        <td className="pt-25">
          <p>
            <strong>
              {setCurrencyValue}
              {isNaN(subTotal)
                ? "0.00"
                : (subTotal * convertedRates).toFixed(2)}
            </strong>
          </p>
        </td>
      </tr>
    </>
  );
};

export default ServiceTable;
