import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CardHeader from "components/Card/CardHeader";
import Invoice from "components/AccountClient/Invoices/PaidInvoices/InvoiceList";
import { useSelector } from "react-redux";
import InvoiceOptions from 'components/AccountClient/Invoices/InvoiceOptions'

const PaidInvoice = () => {
  const classes = useDashboardStyles();

  return (
    <Accountclient>
      <AccountClientLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <p className={classes.cardTitleWhite}>Paid Invoices</p>
                <InvoiceOptions />
              </CardHeader>
              <CardBody>

                <Invoice />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default PaidInvoice;
