import {useState} from 'react'
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomDate from "./CustomDate";
import RevenueItems from "./RevenueItems";
import { FlexDiv } from "./style";
import CardHeader from 'components/Card/CardHeader'
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const BoxOne = ({
  revDatas,
  otherRevDatas,
  handleRevAdd,
  handleRevRemove,
  handleOtherRevRemove,
  handleRevDropdownChange,
  handleRevInputChange,
  handleRevOtherNameInputChange,
  handleRevOtherValueInputChange,
  totalRevenue,
  handleDateChange,
  handleOtherRevAdd,
  date,
  currency
}) => {
  const classes = useDashboardStyles()
  return (
    <div>
      <FlexDiv>
        <h3 className="a-p">Date:</h3>
        <CustomDate width="30%" onChange={handleDateChange} value={date} style={{marginBottom:40}} />
      </FlexDiv>
      
                    
      <FlexDiv>
        <Card>
        <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>
                Revenue Items
                </p>
              </CardHeader>
          <CardBody><RevenueItems
        currency={currency}
        revDatas={revDatas}
        otherRevDatas={otherRevDatas}
        handleRevAdd={handleRevAdd}
        handleRevRemove={handleRevRemove}
        handleOtherRevRemove={handleOtherRevRemove}
        handleRevDropdownChange={handleRevDropdownChange}
        handleRevInputChange={handleRevInputChange}
        handleRevOtherNameInputChange={handleRevOtherNameInputChange}
        handleRevOtherValueInputChange={handleRevOtherValueInputChange}
        totalRevenue={totalRevenue}
        handleOtherRevAdd={handleOtherRevAdd}></RevenueItems></CardBody>
        </Card>
        {/* <p
          className="p"
          style={{
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          Revenue Items:
        </p> */}
        <p></p>
      </FlexDiv>
      
    
    </div>
  );
};

export default BoxOne;
