import { useState, Fragment } from "react";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import CustomModal from "components/Common/CustomModal";

import { ActionsDiv } from "./style";

const Actions = ({ currency }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const { invoiceReducer, qutationReducer } = useSelector((state) => state);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePreview = () => {
    console.log({ invoiceReducer, qutationReducer });
  };

  return (
    <Fragment>
      <ActionsDiv>
        <Button
          color="primary"
          variant="outlined"
          style={{ marginRight: "15px" }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: "15px" }}
          onClick={handlePreview}
          disabled={isLoading === "render"}
        >
          View PDF
        </Button>

        <Button color="primary" variant="outlined">
          Send
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          style={{ marginLeft: "15px" }}
        >
          Discard
        </Button>
      </ActionsDiv>
      <CustomModal open={open} handleClose={handleClose}>
        {isLoading === "render" ? (
          <CircularProgress />
        ) : (
          <div
            id="pdf"
            style={{
              height: "100vh",
            }}
          ></div>
        )}
      </CustomModal>
    </Fragment>
  );
};

export default Actions;
