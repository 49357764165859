import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import { IconButton } from "@material-ui/core";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import UnconfirmedOptions from "components/Admin/BookingPanel/UnconfirmedAssignments/UnconfirmedOptions";
import ListAssignments from "components/Admin/BookingPanel/UnconfirmedAssignments/InactiveAssignments/InactiveAssignments";

const BACUnconfirmedAssignments = () => {
  const classes = useDashboardStyles();
  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{
                display: "flex",
                justifyContent: "space-between",
              }} color="primary">
                <p className={classes.cardTitleWhite}>Unconfirm Assignments (All Incomplete Assignments)</p>
                <IconButton color='inherit'><ArrowBackIcon onClick={() => history.goBack()} /></IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <UnconfirmedOptions />
              </CardHeader>
            </Card>
            <Card>

              <CardBody>
                <ListAssignments />
              </CardBody>
            </Card>

          </GridItem>
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default BACUnconfirmedAssignments;

