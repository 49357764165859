import SearchInput from "./SearchInput";
import SearchInputINVSPPO from "./SearchInputINVtoSPPO";
import SearchInputINVLIN from "./SearchInputINVtoLIN";
import SearchInputINVLINtoPRC from "./SearchInputINVLINtoPRC";
import { useState } from "react";

const SearchView = ({
  values,
  setFiltered,
  setFilter,
  setSearchText,
  SearchText,
  load,
  id,
}) => {
  return (
    <div>
      {id === "invtosppo" ? (
        <SearchInputINVSPPO
          setFiltered={setFiltered}
          setFilter={setFilter}
          load={load}
          setSearchText={setSearchText}
          SearchText={SearchText}
          values={values}
        />
      ) : id === "invtolin" ? (
        <SearchInputINVLIN
          setFiltered={setFiltered}
          setFilter={setFilter}
          load={load}
          setSearchText={setSearchText}
          SearchText={SearchText}
          values={values}
        />
      ) : id === "invlintoprc" ? (
        <SearchInputINVLINtoPRC
          setFiltered={setFiltered}
          setFilter={setFilter}
          load={load}
          setSearchText={setSearchText}
          SearchText={SearchText}
          values={values}
        />
      ) : (
        <SearchInput
          setFiltered={setFiltered}
          setFilter={setFilter}
          load={load}
          setSearchText={setSearchText}
          SearchText={SearchText}
          values={values}
        />
      )}
    </div>
  );
};

export default SearchView;
