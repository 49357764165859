import React, { useState, useEffect } from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import CashClientLayout from "layout/CashClientLayout";

import Cookies from "js-cookie";
import Axios from "utils/axios";
import ReactHtmlParser from "react-html-parser";

import { Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";

const ServiceAgreement = () => {
  const classes = useLandingpageStyles();

  const [agreement, setAgreement] = useState(null);

  const getServiceAgreement = async (e) => {
    const token = Cookies.get("CACtoken");
    const response = await Axios.get(
      "/api/CAC/get-service-agreement",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAgreement(response?.data);
  };
  useEffect(() => {
    getServiceAgreement();
  }, []);

  return (
    <CashClientLayout>
      <Cashclient>
        <GridContainer style={{ margin: "4rem 0 4rem 0" }}>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card style={{ padding: "5rem" }} className={classes.clientPortal}>
              {agreement ? (
                <CardBody>
                  <Typography
                    style={{ fontWeight: "700", textAlign: "center" }}
                    paragraph
                    variant="h5"
                  >
                    {agreement?.title}
                  </Typography>
                  <div style={{ paddingTop: "10px", paddingLeft: "15px" }}>
                    <Typography
                      style={{ fontWeight: "700", textAlign: "justify" }}
                      paragraph
                      variant="h6"
                    >
                      {ReactHtmlParser(agreement?.content)}
                    </Typography>
                  </div>
                </CardBody>
              ) : (
                <CardBody>
                  <Typography
                    style={{ fontWeight: "700", textAlign: "center" }}
                    paragraph
                    variant="h5"
                  >
                    Service Agreement temporarily unavailable, please contact
                    Admin Office.
                  </Typography>
                </CardBody>
              )}
            </Card>
          </GridItem>
        </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default ServiceAgreement;
