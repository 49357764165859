import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import Axios from "axios";
import Cookies from "js-cookie";

const OtherDetails = () => {
  const [edit, setEdit] = useState(false);
  const token = Cookies.get("LINtoken");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updateProfileReducer);
  const handleEdit = (e) => {
    setEdit(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };


  const handleUpdate = async (e) => {
    let fd = new FormData();

    fd.append("special_needs", data.special_needs);
    fd.append("note", data.note);

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/profile/update`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit(false);
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            name="note"
            onChange={handleChange}
            label="Would you like to add any other information or note"
            edit={edit}
            value={data.note}
          />
          <CustomTextInput
            name="special_needs"
            onChange={handleChange}
            label="Do you have any disability or speacial needs"
            edit={edit}
            value={data.special_needs}
          />

          <a href={`/linguist/update-linguist-documents`}>
            Upload Documents
          </a>

        </div>

        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default OtherDetails;
