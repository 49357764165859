import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Dimmer, Loader, Table } from "semantic-ui-react";
import { Icon, Modal } from "semantic-ui-react";
import CustomInput from "components/Common/CustomInput";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import Button from "@material-ui/core/Button";
import AttachmentIcon from "@material-ui/icons/Attachment";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SendIcon from "@material-ui/icons/Send";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SecurityIcon from "@material-ui/icons/Security";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DescriptionIcon from "@material-ui/icons/Description";
import BusinessIcon from "@material-ui/icons/Business";

const ULOptions = ({ id, linRefID }) => {
  const history = useHistory();
  const [paidModal, setPaidModal] = React.useState(false);
  const [pay, setPay] = useState({
    note: "",
    linID: "",
  });

  const handlePaid = async (id) => {
    setPaidModal(true);
    setPay({ ...pay, linID: id });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("token");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePaidClick = (e) => {
    setAnchorEl(false);
    handlePaid(id);
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };
  const [values, setValues] = useState({
    loader: false,
  });

  const handleConfirm = async (e) => {
    setValues({ ...values, loader: true });
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-document-request/linID/${pay.linID}`,
        {
          note: pay.note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, loader: false });
      swal({
        icon: "success",
        text: "Document request successfully send",
        buttons: false,
      });
      setPaidModal(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "auto auto " }}>
      <Modal open={paidModal} onClose={() => setPaidModal(false)}>
        <Modal.Header>Request Document - {linRefID}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <CustomInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={handleConfirm}
            style={{ backgroundColor: "purple", color: "white" }}
            disabled={!pay.note || values.loader}
          >
            Send Request <Icon name="chevron right" />
          </Button>

          {values.loader && <div class="ui active inline loader"></div>}
        </Modal.Actions>
      </Modal>

      <Button
        startIcon={<PermIdentityIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/admin/linguist-profile-photo-preview/${id}`)
        }
      >
        Preview Profile Photo
      </Button>
      <Button
        startIcon={<BusinessIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/admin/linguist-old-address-preview/${id}`)}
      >
        Preview Address Document
      </Button>
      <Button
        startIcon={<CreditCardIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/admin/linguist-old-passport/${id}`)}
      >
        Passport or National ID
      </Button>
      <Button
        startIcon={<DescriptionIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/admin/linguist-old-relevent-document/${id}`)}
      >
        Relevent Document
      </Button>

      <Button
        startIcon={<VerifiedUserIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/admin/linguist-old-relevent-certificate/${id}`)
        }
      >
        Relevent Certificate
      </Button>

      <Button
        startIcon={<SecurityIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/admin/linguist-old-security-clearence/${id}`)}
      >
        Security Clearence Document
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/admin/linguist-old-cv/${id}`)}
      >
        CV
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/admin/linguist-old-insurance-document/${id}`)}
      >
        Insurance Document
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/admin/linguist-old-reference-letter/${id}`)}
      >
        Previous work reference
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/admin/linguist-old-interpreting-document/${id}`)
        }
      >
        Interpreting Qualifications Document
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() =>
          history.push(`/admin/linguist-oldtranslation-document/${id}`)
        }
      >
        Translation Qualifications Document
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/admin/linguist-old-psi-pst-document/${id}`)}
      >
        PSI / PST experience reference letter
      </Button>

      <Button
        startIcon={<AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/admin/linguist-old-any-other-document/${id}`)}
      >
        Interpreting or translation reference
      </Button>

      <Button
        startIcon={<SendIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={handlePaidClick}
      >
        Request Document
      </Button>
    </div>
  );
};

export default ULOptions;
