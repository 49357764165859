import React, { Fragment, useState } from "react";

// materil core components
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import IconButton from "@material-ui/core/IconButton";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
// Custom Components
import CustomTable from "components/Common/CustomTable";
import CustomModal from "components/Common/CustomModal";
import NoteInput from "./NoteInput";
import Action from "./Action";
import ViewNote from "./ViewNote";

const TableOne = () => {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("input");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: "assignment", headerName: "Assignment ID", width: 130 },
    { field: "type", headerName: "Start date & time", width: 120 },
    {
      field: "language",
      headerName: "Service Required",
      width: 120,
      renderCell: (params) => {
        const values = params.value.split(", ");
        return (
          <>
            {values.map((value, index) => (
              <span key={index}>{value}</span>
            ))}
          </>
        );
      },
    },
    { field: "date", headerName: "Venue/Remote", width: 120 },
    { field: "time", headerName: "Expertise Level Required", width: 120 },
    { field: "time", headerName: "Gender", width: 120 },
    { field: "time", headerName: "Security Level", width: 120 },
    { field: "time", headerName: "Parking", width: 120 },
    { field: "value", headerName: "Duration", width: 120 },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return <Action />;
      },
    },
    {
      field: "note",
      headerName: "Note",
      width: 120,
      renderCell: (params) => (
        <Fragment>
          <IconButton
            aria-label="Person"
            color="primary"
            onClick={() => {
              handleOpen();
              setModalType("input");
            }}
          >
            <ControlPointIcon />
          </IconButton>
          <IconButton
            aria-label="Person"
            color="primary"
            onClick={() => {
              handleOpen();
              setModalType("view");
            }}
          >
            <ViewComfyIcon />
          </IconButton>
        </Fragment>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      assignment: "Interpreting",
      type: "FTF",
      language: "English, Bengali",
      date: "21 June 20",
      time: "2000",
      value: "€800",
      action: "Urgent",
      status: "Allocated",
      note: "note",
    },
  ];
  return (
    <Fragment>
      <CustomTable rows={rows} columns={columns} />
      <CustomModal open={open} handleClose={handleClose}>
        {modalType === "input" ? <NoteInput /> : null}
        {modalType === "view" ? <ViewNote /> : null}
      </CustomModal>
    </Fragment>
  );
};

export default TableOne;
