import React from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import { useParams } from "react-router";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import LinguistLayout from "layout/LinguistLayout";
import BookingOptions from "components/Linguist/ManagePrivateClient/BookingOption";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import ConfirmAssignment from "components/Linguist/ManagePrivateClient/ConfirmAssignment/List";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ConfirmTransList from "components/Linguist/ManagePrivateClient/ConfirmTransList/ConfirmTransList";
import { Header } from "semantic-ui-react";

const ViewInhouseClient = () => {
  const classes = useDashboardStyles();
  const { data, type } = useParams();
  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>
                  Private Confirm Assignments
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BookingOptions />
              </CardHeader>
            </Card>
            <Card>
              <CardBody>
                <Header as="h3">Confirmed Interpreting Assignments</Header>
                <ConfirmAssignment
                  interpretingData={type === "interpreting" && data}
                />

                <Header as="h3">Confirmed Translation Assignments</Header>
                <ConfirmTransList
                  translationData={type === "translation" && data}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default ViewInhouseClient;
