import React, { useEffect, useState } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PrivateInterpretingSummary from "components/Linguist/ManagePrivateClient/Form/PrivateInterpretingWeb/summary";
import Cookies from "js-cookie";
import Axios from "axios";

const BISummaryPage = () => {
  const privateclientID = Cookies.get("privateclientID");
  const [values, setValues] = useState({ reload: false });

  const clientDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-PRC/${privateclientID}`
      );
      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    clientDetails();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <PrivateInterpretingSummary payLaterAccess={values?.payLaterAccess} />
      </GridItem>
    </GridContainer>
  );
};

export default BISummaryPage;
