import React, { useState } from "react";
import Linguist from "components/RouteProtection/Linguist/Linguist";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import NoteForm from "components/Linguist/MyNote/MyNote";
import NoteList from "components/Linguist/MyNote/NoteList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",

      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};
const useStyles = makeStyles(styles);

const InterpretingJobs = () => {
  const classes = useDashboardStyles();
  const ButtonClass = useStyles();
  const [show, setShow] = useState(false);

  return (
    <LinguistLayout>
      <Linguist>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Saved Notes</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={ButtonClass.root}>
                    <Button variant="outlined" onClick={() => setShow(!show)}>
                      {show ? "Saved Notes" : "+ Add Note"}
                    </Button>
                  </div>
                  <IconButton color="inherit">
                    <ArrowBackIcon
                      onClick={() => history.goBack()}
                    ></ArrowBackIcon>
                  </IconButton>
                </div>
              </CardHeader>
              <CardBody>{show ? <NoteForm /> : <NoteList />}</CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Linguist>
    </LinguistLayout>
  );
};

export default InterpretingJobs;
