import React, { Fragment, useState } from "react";

// Material Core Components
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

// Material Icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

// Custom Components
import CustomTime from "components/Common/CustomTime";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
import { DURATION } from "data";
import { TextField, Typography } from "@material-ui/core";
import CustomFormControl from "components/Common/CustomFormControl";
import { changedatetime } from "redux/actions/datetimeAction";
import { Button } from "semantic-ui-react";

const Time = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { calender } = useSelector((state) => state.datetimeReducer);

  const {
    startdate,
    starttime,
    total_duration,
    enddate,
    endtime,
    total_days,

    startdateOne,
    enddateOne,
    starttimeOne,
    endtimeOne,
    durationOne,
    startdateTwo,
    enddateTwo,
    starttimeTwo,
    endtimeTwo,
    durationTwo,

    startdateThree,
    enddateThree,
    starttimeThree,
    endtimeThree,
    durationThree,
    startdateFour,
    enddateFour,
    starttimeFour,
    endtimeFour,
    durationFour,

    startdateFive,
    enddateFive,
    starttimeFive,
    endtimeFive,
    durationFive,
    startdateSix,
    enddateSix,
    starttimeSix,
    endtimeSix,
    durationSix,

    startdateSeven,
    enddateSeven,
    starttimeSeven,
    endtimeSeven,
    durationSeven,
    startdateEight,
    enddateEight,
    starttimeEight,
    endtimeEight,
    durationEight,

    startdateNine,
    enddateNine,
    starttimeNine,
    endtimeNine,
    durationNine,
    startdateTen,
    enddateTen,
    starttimeTen,
    endtimeTen,
    durationTen,

    startdateEleven,
    enddateEleven,
    starttimeEleven,
    endtimeEleven,
    durationEleven,
    startdateTwelve,
    enddateTwelve,
    starttimeTwelve,
    endtimeTwelve,
    durationTwelve,

    startdateThirteen,
    enddateThirteen,
    starttimeThirteen,
    endtimeThirteen,
    durationThirteen,
    startdateFourteen,
    enddateFourteen,
    starttimeFourteen,
    endtimeFourteen,
    durationFourteen,

    startdateFifteen,
    enddateFifteen,
    starttimeFifteen,
    endtimeFifteen,
    durationFifteen,
    startdateSixteen,
    enddateSixteen,
    starttimeSixteen,
    endtimeSixteen,
    durationSixteen,

    startdateSeventeen,
    enddateSeventeen,
    starttimeSeventeen,
    endtimeSeventeen,
    durationSeventeen,
    startdateEighteen,
    enddateEighteen,
    starttimeEighteen,
    endtimeEighteen,
    durationEighteen,

    startdateNineteen,
    enddateNineteen,
    starttimeNineteen,
    endtimeNineteen,
    durationNineteen,
    startdateTwenty,
    enddateTwenty,
    starttimeTwenty,
    endtimeTwenty,
    durationTwenty,

    startdateTwentyOne,
    enddateTwentyOne,
    starttimeTwentyOne,
    endtimeTwentyOne,
    durationTwentyOne,
    startdateTwentyTwo,
    enddateTwentyTwo,
    starttimeTwentyTwo,
    endtimeTwentyTwo,
    durationTwentyTwo,

    startdate23,
    enddate23,
    starttime23,
    endtime23,
    duration23,
    startdate24,
    enddate24,
    starttime24,
    endtime24,
    duration24,

    startdate25,
    enddate25,
    starttime25,
    endtime25,
    duration25,
    startdate26,
    enddate26,
    starttime26,
    endtime26,
    duration26,

    startdate27,
    enddate27,
    starttime27,
    endtime27,
    duration27,
    startdate28,
    enddate28,
    starttime28,
    endtime28,
    duration28,

    startdate29,
    enddate29,
    starttime29,
    endtime29,
    duration29,
    startdate30,
    enddate30,
    starttime30,
    endtime30,
    duration30,

    startdate31,
    enddate31,
    starttime31,
    endtime31,
    duration31,
    flag,
  } = calender;

  const [timeCount, setTimeCount] = useState([1]);

  const handleClick = (e, titleProps) => {
    e.preventDefault();

    // let dt;
    // dt = ((moment(durationOne, 'HHmm').add(durationTwo.split(":")[0], 'h').add(durationTwo.split(":")[1], 'm')
    // .add(durationThree.split(":")[0], 'h').add(durationThree.split(":")[1], 'm')
    // .add(durationFour.split(":")[0], 'h').add(durationFour.split(":")[1], 'm')
    // .add(durationFive.split(":")[0], 'h').add(durationFive.split(":")[1], 'm')
    // .add(durationSix.split(":")[0], 'h').add(durationSix.split(":")[1], 'm')
    // .add(durationSeven.split(":")[0], 'h').add(durationSeven.split(":")[1], 'm')
    // .add(durationEight.split(":")[0], 'h').add(durationEight.split(":")[1], 'm')
    // .add(durationNine.split(":")[0], 'h').add(durationNine.split(":")[1], 'm')
    // .add(durationTen.split(":")[0], 'h').add(durationTen.split(":")[1], 'm')
    // .add(durationEleven.split(":")[0], 'h').add(durationEleven.split(":")[1], 'm')
    // .add(durationTwelve.split(":")[0], 'h').add(durationTwelve.split(":")[1], 'm')
    // .add(durationThirteen.split(":")[0], 'h').add(durationThirteen.split(":")[1], 'm')
    // .add(durationFourteen.split(":")[0], 'h').add(durationFourteen.split(":")[1], 'm')
    // .add(durationFifteen.split(":")[0], 'h').add(durationFifteen.split(":")[1], 'm')
    // .add(durationSixteen.split(":")[0], 'h').add(durationSixteen.split(":")[1], 'm')
    // .add(durationSeventeen.split(":")[0], 'h').add(durationSeventeen.split(":")[1], 'm')
    // .add(durationEighteen.split(":")[0], 'h').add(durationEighteen.split(":")[1], 'm')
    // .add(durationNineteen.split(":")[0], 'h').add(durationNineteen.split(":")[1], 'm')
    // .add(durationTwenty.split(":")[0], 'h').add(durationTwenty.split(":")[1], 'm')
    // .add(durationTwentyOne.split(":")[0], 'h').add(durationTwentyOne.split(":")[1], 'm')
    // .add(durationTwentyTwo.split(":")[0], 'h').add(durationTwentyTwo.split(":")[1], 'm')
    // .add(duration23.split(":")[0], 'h').add(duration23.split(":")[1], 'm')
    // .add(duration24.split(":")[0], 'h').add(duration24.split(":")[1], 'm')
    // .add(duration25.split(":")[0], 'h').add(duration25.split(":")[1], 'm')
    // .add(duration26.split(":")[0], 'h').add(duration26.split(":")[1], 'm')
    // .add(duration27.split(":")[0], 'h').add(duration27.split(":")[1], 'm')
    // .add(duration28.split(":")[0], 'h').add(duration28.split(":")[1], 'm')
    // .add(duration29.split(":")[0], 'h').add(duration29.split(":")[1], 'm')
    // .add(duration30.split(":")[0], 'h').add(duration30.split(":")[1], 'm')
    // .add(duration31.split(":")[0], 'h').add(duration31.split(":")[1], 'm'))
    // .format('HH:mm'))

    // const dthr= durationOne.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationThree.split("0")[0] + durationFour.split("0")[0] + durationFive.split("0")[0] + durationSix.split("0")[0]
    // + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0]
    // + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0]
    // + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0]
    // + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0]
    // + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0] + durationTwo.split("0")[0]

    const dt =
      moment.duration(moment(durationOne, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(durationTwo, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(durationThree, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(durationFour, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(durationFive, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(durationSix, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(durationSeven, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(durationEight, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(durationNine, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(durationTen, "HHmm").format("HH:mm")).asHours() +
      moment
        .duration(moment(durationEleven, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationTwelve, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationThirteen, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationFourteen, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationFifteen, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationSixteen, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationSeventeen, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationEighteen, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationNineteen, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationTwenty, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationTwentyOne, "HHmm").format("HH:mm"))
        .asHours() +
      moment
        .duration(moment(durationTwentyTwo, "HHmm").format("HH:mm"))
        .asHours() +
      moment.duration(moment(duration23, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(duration24, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(duration25, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(duration26, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(duration27, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(duration28, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(duration29, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(duration30, "HHmm").format("HH:mm")).asHours() +
      moment.duration(moment(duration31, "HHmm").format("HH:mm")).asHours();

    dispatch(
      changedatetime({
        name: "total_duration",
        value: dt,
      })
    );

    dispatch(
      changedatetime({
        name: "flag",
        value: false,
      })
    );
    dispatch(
      changedatetime({
        name: "total_days",
        value: timeCount,
      })
    );
  };

  const handleAdd = () => {
    setTimeCount([...timeCount, timeCount[0] + 1]);
  };
  const handleRemove = () => {
    const copiedTC = [...timeCount];
    copiedTC.pop();
    let duration;
    if (timeCount.length === 2) {
      const dhr = durationTwo.split(":")[0];
      const dhm = durationTwo.split(":")[1];
      const lastVal = moment.duration(durationTwo, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationTwo",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateTwo",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeTwo",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateTwo",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeTwo",
          value: "",
        })
      );
    } else if (timeCount.length === 3) {
      const dhr = durationThree.split(":")[0];
      const dhm = durationThree.split(":")[1];
      const lastVal = moment.duration(durationThree, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationThree",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateThree",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeThree",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateThree",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeThree",
          value: "",
        })
      );
    } else if (timeCount.length === 4) {
      const dhr = durationFour.split(":")[0];
      const dhm = durationFour.split(":")[1];
      const lastVal = moment.duration(durationFour, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationFour",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateFour",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeFour",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateFour",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeFour",
          value: "",
        })
      );
    } else if (timeCount.length === 5) {
      const dhr = durationFive.split(":")[0];
      const dhm = durationFive.split(":")[1];
      const lastVal = moment.duration(durationFive, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationFive",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateFive",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeFive",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateFive",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeFive",
          value: "",
        })
      );
    } else if (timeCount.length === 6) {
      const dhr = durationSix.split(":")[0];
      const dhm = durationSix.split(":")[1];
      const lastVal = moment.duration(durationSix, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationSix",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateSix",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeSix",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateSix",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeSix",
          value: "",
        })
      );
    } else if (timeCount.length === 7) {
      const dhr = durationSeven.split(":")[0];
      const dhm = durationSeven.split(":")[1];
      const lastVal = moment.duration(durationSeven, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationSeven",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateSeven",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeSeven",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateSeven",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeSeven",
          value: "",
        })
      );
    } else if (timeCount.length === 8) {
      const dhr = durationEight.split(":")[0];
      const dhm = durationEight.split(":")[1];
      const lastVal = moment.duration(durationEight, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationEight",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateEight",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeEight",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateEight",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeEight",
          value: "",
        })
      );
    } else if (timeCount.length === 9) {
      const dhr = durationNine.split(":")[0];
      const dhm = durationNine.split(":")[1];
      const lastVal = moment.duration(durationNine, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationNine",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateNine",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeNine",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateNine",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeNine",
          value: "",
        })
      );
    } else if (timeCount.length === 10) {
      const dhr = durationTen.split(":")[0];
      const dhm = durationTen.split(":")[1];
      const lastVal = moment.duration(durationTen, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationTen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateTen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeTen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateTen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeTen",
          value: "",
        })
      );
    } else if (timeCount.length === 11) {
      const dhr = durationEleven.split(":")[0];
      const dhm = durationEleven.split(":")[1];
      const lastVal = moment.duration(durationEleven, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationEleven",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateEleven",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeEleven",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateEleven",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeEleven",
          value: "",
        })
      );
    } else if (timeCount.length === 12) {
      const dhr = durationTwelve.split(":")[0];
      const dhm = durationTwelve.split(":")[1];
      const lastVal = moment.duration(durationTwelve, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationTwelve",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateTwelve",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeTwelve",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateTwelve",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeTwelve",
          value: "",
        })
      );
    } else if (timeCount.length === 13) {
      const dhr = durationThirteen.split(":")[0];
      const dhm = durationThirteen.split(":")[1];
      const lastVal = moment.duration(durationThirteen, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationThirteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateThirteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeThirteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateThirteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeThirteen",
          value: "",
        })
      );
    } else if (timeCount.length === 14) {
      const dhr = durationFourteen.split(":")[0];
      const dhm = durationFourteen.split(":")[1];
      const lastVal = moment.duration(durationFourteen, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationFourteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateFourteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeFourteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateFourteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeFourteen",
          value: "",
        })
      );
    } else if (timeCount.length === 15) {
      const dhr = durationFifteen.split(":")[0];
      const dhm = durationFifteen.split(":")[1];
      const lastVal = moment.duration(durationFifteen, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationFifteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateFifteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeFifteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateFifteenr",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeFifteen",
          value: "",
        })
      );
    } else if (timeCount.length === 16) {
      const dhr = durationSixteen.split(":")[0];
      const dhm = durationSixteen.split(":")[1];
      const lastVal = moment.duration(durationSixteen, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationSixteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateSixteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeSixteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateSixteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeSixteen",
          value: "",
        })
      );
    } else if (timeCount.length === 17) {
      const dhr = durationSeventeen.split(":")[0];
      const dhm = durationSeventeen.split(":")[1];
      const lastVal = moment.duration(durationSeventeen, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationSeventeen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateSeventeen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeSeventeen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateSeventeen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeSeventeen",
          value: "",
        })
      );
    } else if (timeCount.length === 18) {
      const dhr = durationEighteen.split(":")[0];
      const dhm = durationEighteen.split(":")[1];
      const lastVal = moment.duration(durationEighteen, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationEighteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateEighteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeEighteeen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateEighteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeEighteen",
          value: "",
        })
      );
    } else if (timeCount.length === 19) {
      const dhr = durationNineteen.split(":")[0];
      const dhm = durationNineteen.split(":")[1];
      const lastVal = moment.duration(durationNineteen, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationNineteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateNineteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeNineteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateNineteen",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeNineteen",
          value: "",
        })
      );
    } else if (timeCount.length === 20) {
      const dhr = durationTwenty.split(":")[0];
      const dhm = durationTwenty.split(":")[1];
      const lastVal = moment.duration(durationTwenty, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationTwenty",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateTwenty",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeTwenty",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateTwenty",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeTwenty",
          value: "",
        })
      );
    } else if (timeCount.length === 21) {
      const dhr = durationTwentyOne.split(":")[0];
      const dhm = durationTwentyOne.split(":")[1];
      const lastVal = moment.duration(durationTwentyOne, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationTwentyOne",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateTwentyOne",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeTwentyOne",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateTwentyOne",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeTwentyOne",
          value: "",
        })
      );
    } else if (timeCount.length === 22) {
      const dhr = durationTwentyTwo.split(":")[0];
      const dhm = durationTwentyTwo.split(":")[1];
      const lastVal = moment.duration(durationTwentyTwo, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "durationTwentyTwo",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdateTwentyTwo",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttimeTwentyTwo",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddateTwentyTwo",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtimeTwentyTwo",
          value: "",
        })
      );
    } else if (timeCount.length === 23) {
      const dhr = duration23.split(":")[0];
      const dhm = duration23.split(":")[1];
      const lastVal = moment.duration(duration23, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "duration23",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdate23",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttime23",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddate23",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtime23",
          value: "",
        })
      );
    } else if (timeCount.length === 24) {
      const dhr = duration24.split(":")[0];
      const dhm = duration24.split(":")[1];
      const lastVal = moment.duration(duration24, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "duration24",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdate24",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttime24",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddate24",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtime24",
          value: "",
        })
      );
    } else if (timeCount.length === 25) {
      const dhr = duration25.split(":")[0];
      const dhm = duration25.split(":")[1];
      const lastVal = moment.duration(duration25, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "duration25",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdate25",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttime25",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddate25",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtime25",
          value: "",
        })
      );
    } else if (timeCount.length === 26) {
      const dhr = duration26.split(":")[0];
      const dhm = duration26.split(":")[1];
      const lastVal = moment.duration(duration26, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "duration26",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdate26",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttime26",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddate26",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtime26",
          value: "",
        })
      );
    } else if (timeCount.length === 27) {
      const dhr = duration27.split(":")[0];
      const dhm = duration27.split(":")[1];
      const lastVal = moment.duration(duration27, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "duration27",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdate27",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttime27",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddate27",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtime27",
          value: "",
        })
      );
    } else if (timeCount.length === 28) {
      const dhr = duration28.split(":")[0];
      const dhm = duration28.split(":")[1];
      const lastVal = moment.duration(duration28, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "duration28",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdate28",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttime28",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddate28",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtime28",
          value: "",
        })
      );
    } else if (timeCount.length === 29) {
      const dhr = duration29.split(":")[0];
      const dhm = durationTwentyOne.split(":")[1];
      const lastVal = moment.duration(duration29, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "duration29",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdate29",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttime29",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddate29",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtime29",
          value: "",
        })
      );
    } else if (timeCount.length === 30) {
      const dhr = duration30.split(":")[0];
      const dhm = duration30.split(":")[1];
      const lastVal = moment.duration(duration30, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "duration30",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdate30",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttime30",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddate30",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtime30",
          value: "",
        })
      );
    } else if (timeCount.length === 31) {
      const dhr = duration31.split(":")[0];
      const dhm = duration31.split(":")[1];
      const lastVal = moment.duration(duration31, "HHmm").asHours();
      duration = total_duration - lastVal;
      dispatch(
        changedatetime({
          name: "total_duration",
          value: duration,
        })
      );
      dispatch(
        changedatetime({
          name: "duration31",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "startdate31",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "starttime31",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "enddate31",
          value: "",
        })
      );
      dispatch(
        changedatetime({
          name: "endtime31",
          value: "",
        })
      );
    }
    setTimeCount(copiedTC);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    // console.log(date)
    // const dtt = dt.push(date)
    dispatch(
      changedatetime({
        name,
        value,
      })
    );

    //setDateOne({...dateOne, [name]: value})
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    dispatch(
      changedatetime({
        name,
        value,
      })
    );
  };

  // const handleTimeChangeE = (date) => {
  //   const ett = et.push(date)
  //   dispatch(changeBaInput({
  //     name: 'endtime',
  //     value: et
  //   }))
  // }

  const handleDuration = (e) => {
    const { name, value } = e.target;

    dispatch(
      changedatetime({
        name,
        value,
      })
    );

    //setDateOne({...dateOne, [name] : value})
    const hr = value.split(":")[0].toString();
    const mm = value.split(":")[1].toString();

    let starttime, endtime, startdate, enddate;
    if (name.includes("TwentyOne")) {
      starttime = starttimeTwentyOne;
      endtime = "endtimeTwentyOne";
      startdate = startdateTwentyOne;
      enddate = "enddateTwentyOne";
    } else if (name.includes("TwentyTwo")) {
      starttime = starttimeTwentyTwo;
      endtime = "endtimeTwentyTwo";
      startdate = startdateTwentyTwo;
      enddate = "enddateTwentyTwo";
    } else if (name.includes("One")) {
      starttime = starttimeOne;
      endtime = "endtimeOne";
      startdate = startdateOne;
      enddate = "enddateOne";
    } else if (name.includes("Sixteen")) {
      starttime = starttimeSixteen;
      endtime = "endtimeSixteen";
      startdate = startdateSixteen;
      enddate = "enddateSixteen";
    } else if (name.includes("Seventeen")) {
      starttime = starttimeSeventeen;
      endtime = "endtimeSeventeen";
      startdate = startdateSeventeen;
      enddate = "enddateSeventeen";
    } else if (name.includes("Eighteen")) {
      starttime = starttimeEighteen;
      endtime = "endtimeEighteen";
      startdate = startdateEighteen;
      enddate = "enddateEighteen";
    } else if (name.includes("Nineteen")) {
      starttime = starttimeNineteen;
      endtime = "endtimeNineteen";
      startdate = startdateNineteen;
      enddate = "enddateNineteen";
    } else if (name.includes("Twenty")) {
      starttime = starttimeTwenty;
      endtime = "endtimeTwenty";
      startdate = startdateTwenty;
      enddate = "enddateTwenty";
    } else if (name.includes("Two")) {
      starttime = starttimeTwo;
      endtime = "endtimeTwo";
      startdate = startdateTwo;
      enddate = "enddateTwo";
    } else if (name.includes("Three")) {
      starttime = starttimeThree;
      endtime = "endtimeThree";
      startdate = startdateThree;
      enddate = "enddateThree";
    } else if (name.includes("Fourteen")) {
      starttime = starttimeFourteen;
      endtime = "endtimeFourteen";
      startdate = startdateFourteen;
      enddate = "enddateFourteen";
    } else if (name.includes("Four")) {
      starttime = starttimeFour;
      endtime = "endtimeFour";
      startdate = startdateFour;
      enddate = "enddateFour";
    } else if (name.includes("Five")) {
      starttime = starttimeFive;
      endtime = "endtimeFive";
      startdate = startdateFive;
      enddate = "enddateFive";
    } else if (name.includes("Six")) {
      starttime = starttimeSix;
      endtime = "endtimeSix";
      startdate = startdateSix;
      enddate = "enddateSix";
    } else if (name.includes("Seven")) {
      starttime = starttimeSeven;
      endtime = "endtimeSeven";
      startdate = startdateSeven;
      enddate = "enddateSeven";
    } else if (name.includes("Eight")) {
      starttime = starttimeEight;
      endtime = "endtimeEight";
      startdate = startdateEight;
      enddate = "enddateEight";
    } else if (name.includes("Nine")) {
      starttime = starttimeNine;
      endtime = "endtimeNine";
      startdate = startdateNine;
      enddate = "enddateNine";
    } else if (name.includes("Ten")) {
      starttime = starttimeTen;
      endtime = "endtimeTen";
      startdate = startdateTen;
      enddate = "enddateTen";
    } else if (name.includes("Eleven")) {
      starttime = starttimeEleven;
      endtime = "endtimeEleven";
      startdate = startdateEleven;
      enddate = "enddateEleven";
    } else if (name.includes("Twelve")) {
      starttime = starttimeTwelve;
      endtime = "endtimeTwelve";
      startdate = startdateTwelve;
      enddate = "enddateTwelve";
    } else if (name.includes("Thirteen")) {
      starttime = starttimeThirteen;
      endtime = "endtimeThirteen";
      startdate = startdateThirteen;
      enddate = "enddateThirteen";
    } else if (name.includes("Fifteen")) {
      starttime = starttimeFifteen;
      endtime = "endtimeFifteen";
      startdate = startdateFifteen;
      enddate = "enddateFifteen";
    } else if (name.includes("23")) {
      starttime = starttime23;
      endtime = "endtime23";
      startdate = startdate23;
      enddate = "enddate23";
    } else if (name.includes("24")) {
      starttime = starttime24;
      endtime = "endtime24";
      startdate = startdate24;
      enddate = "enddate24";
    } else if (name.includes("25")) {
      starttime = starttime25;
      endtime = "endtime25";
      startdate = startdate25;
      enddate = "enddate25";
    } else if (name.includes("26")) {
      starttime = starttime26;
      endtime = "endtime26";
      startdate = startdate26;
      enddate = "enddate26";
    } else if (name.includes("27")) {
      starttime = starttime27;
      endtime = "endtime27";
      startdate = startdate27;
      enddate = "enddate27";
    } else if (name.includes("28")) {
      starttime = starttime28;
      endtime = "endtime28";
      startdate = startdate28;
      enddate = "enddate28";
    } else if (name.includes("29")) {
      starttime = starttime29;
      endtime = "endtime29";
      startdate = startdate29;
      enddate = "enddate29";
    } else if (name.includes("30")) {
      starttime = starttime30;
      endtime = "endtime30";
      startdate = startdate30;
      enddate = "enddate30";
    } else if (name.includes("31")) {
      starttime = starttime31;
      endtime = "endtime31";
      startdate = startdate31;
      enddate = "enddate31";
    }

    const sthr = starttime.split(":")[0].toString();
    const stmm = starttime.split(":")[1].toString();

    const end_time = moment(starttime, "HHmm")
      .add(hr, "h")
      .add(mm, "m")
      .format("HH:mm");
    dispatch(
      changedatetime({
        name: endtime,
        value: end_time,
      })
    );
    //setDateOne({...dateOne, endtimeOne: endtime})

    const ethr = end_time.split(":")[0].toString();
    const etmm = end_time.split(":")[1].toString();
    let nextDay = parseInt(ethr + etmm) - parseInt(sthr + stmm);
    let end_date;
    if (nextDay > 0) {
      end_date = startdate;
    } else {
      end_date = moment(startdate, "YYYYmmDD")
        .add(1, "day")
        .format("yyyy-mm-DD");
    }

    dispatch(
      changedatetime({
        name: enddate,
        value: end_date,
      })
    );

    dispatch(
      changedatetime({
        name: "flag",
        value: true,
      })
    );
  };

  const a = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            name="startdateOne"
            value={startdateOne}
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            inputProps={{
              min: moment().format("YYYY-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          value={starttimeOne}
          name="starttimeOne"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!starttimeOne || !startdateOne}
            name="durationOne"
            value={durationOne}
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            name="enddateOne"
            value={enddateOne}
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          name="endtimeOne"
          value={endtimeOne}
          disabled
          label="End time"
        />
      </div>
    </Fragment>
  );

  const b = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateTwo}
            name="startdateTwo"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateOne || !starttimeOne || !durationOne}
            type="date"
            inputProps={{
              min: moment(startdateOne, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateOne || !starttimeOne || !durationOne}
          value={starttimeTwo}
          name="starttimeTwo"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!starttimeTwo || !startdateTwo}
            value={durationTwo}
            name="durationTwo"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateTwo}
            name="enddateTwo"
            //defaultValue= {moment(startdateTwo, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          value={endtimeTwo}
          disabled
          name="endtimeTwo"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const c = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateThree}
            name="startdateThree"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateTwo || !starttimeTwo || !durationTwo}
            type="date"
            inputProps={{
              min: moment(startdateTwo, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateTwo || !starttimeTwo || !durationTwo}
          value={starttimeThree}
          name="starttimeThree"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateThree || !starttimeThree}
            name="durationThree"
            value={durationThree}
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateThree}
            name="enddateThree"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeThree}
          name="endtimeThree"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const d = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateFour}
            name="startdateFour"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateThree || !starttimeThree || !durationThree}
            type="date"
            inputProps={{
              min: moment(startdateThree, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateThree || !starttimeThree || !durationThree}
          value={starttimeFour}
          name="starttimeFour"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateFour || !starttimeFour}
            value={durationFour}
            name="durationFour"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateFour}
            name="enddateFour"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeFour}
          name="endtimeFour"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const e = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateFive}
            name="startdateFive"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateFour || !starttimeFour || !durationFour}
            type="date"
            inputProps={{
              min: moment(startdateFour, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateFour || !starttimeFour || !durationFour}
          value={starttimeFive}
          name="starttimeFive"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateFive || !starttimeFive}
            value={durationFive}
            name="durationFive"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateFive}
            name="enddateFive"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeFive}
          name="endtimeFive"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const f = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateSix}
            name="startdateSix"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateFive || !starttimeFive || !durationFive}
            type="date"
            inputProps={{
              min: moment(startdateFive, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateFive || !starttimeFive || !durationFive}
          value={starttimeSix}
          name="starttimeSix"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateSix || !starttimeSix}
            value={durationSix}
            name="durationSix"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateSix}
            name="enddateSix"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeSix}
          name="endtimeSix"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const g = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateSeven}
            name="startdateSeven"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateSix || !starttimeSix || !durationSix}
            type="date"
            inputProps={{
              min: moment(startdateSix, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateSix || !starttimeSix || !durationSix}
          value={starttimeSeven}
          name="starttimeSeven"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateSeven || !starttimeSeven}
            value={durationSeven}
            name="durationSeven"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateSeven}
            name="enddateSeven"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeSeven}
          name="endtimeSeven"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const h = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateEight}
            name="startdateEight"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateSeven || !starttimeSeven || !durationSeven}
            type="date"
            inputProps={{
              min: moment(startdateSeven, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateSeven || !starttimeSeven || !durationSeven}
          value={starttimeEight}
          name="starttimeEight"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateEight || !starttimeEight}
            value={durationEight}
            name="durationEight"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateEight}
            name="enddateEight"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeEight}
          name="endtimeEight"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const i = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateNine}
            name="startdateNine"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateEight || !starttimeEight || !durationEight}
            type="date"
            inputProps={{
              min: moment(startdateEight, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateEight || !starttimeEight || !durationEight}
          value={starttimeNine}
          name="starttimeNine"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateNine || !starttimeNine}
            value={durationNine}
            name="durationNine"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateNine}
            name="enddateNine"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeNine}
          name="endtimeNine"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const j = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateTen}
            name="startdateTen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateNine || !starttimeNine || !durationNine}
            type="date"
            inputProps={{
              min: moment(startdateNine, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateNine || !starttimeNine || !durationNine}
          value={starttimeTen}
          name="starttimeTen"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateTen || !starttimeTen}
            value={durationTen}
            name="durationTen"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateTen}
            name="enddateTen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeTen}
          name="endtimeTen"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const k = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateEleven}
            name="startdateEleven"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateTen || !starttimeTen || !durationTen}
            type="date"
            inputProps={{
              min: moment(startdateTen, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateTen || !starttimeTen || !durationTen}
          value={starttimeEleven}
          name="starttimeEleven"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateEleven || !startdateEleven}
            value={durationEleven}
            name="durationEleven"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateEleven}
            name="enddateEleven"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeEleven}
          name="endtimeEleven"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const l = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateTwelve}
            name="startdateTwelve"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateEleven || !starttimeEleven || !durationEleven}
            type="date"
            inputProps={{
              min: moment(startdateEleven, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateEleven || !starttimeEleven || !durationEleven}
          value={starttimeTwelve}
          name="starttimeTwelve"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateTwelve || !starttimeTwelve}
            value={durationTwelve}
            name="durationTwelve"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateTwelve}
            name="enddateTwelve"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeTwelve}
          name="endtimeTwelve"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const m = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateThirteen}
            name="startdateThirteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateTwelve || !starttimeTwelve || !durationTwelve}
            type="date"
            inputProps={{
              min: moment(startdateTwelve, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateTwelve || !starttimeTwelve || !durationTwelve}
          value={starttimeThirteen}
          name="starttimeThirteen"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateThirteen || !starttimeThirteen}
            value={durationThirteen}
            name="durationThirteen"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateThirteen}
            name="enddateThirteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeThirteen}
          name="endtimeThirteen"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const n = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateFourteen}
            name="startdateFourteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={
              !startdateThirteen || !starttimeThirteen || !durationThirteen
            }
            type="date"
            inputProps={{
              min: moment(startdateThirteen, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={
            !startdateThirteen || !starttimeThirteen || !durationThirteen
          }
          value={starttimeFourteen}
          name="starttimeFourteen"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateFourteen || !starttimeFourteen}
            value={durationFourteen}
            name="durationFourteen"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateFourteen}
            name="enddateFourteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeFourteen}
          name="endtimeFourteen"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const o = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateFifteen}
            name="startdateFifteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={
              !startdateFourteen || !starttimeFourteen || !durationFourteen
            }
            type="date"
            inputProps={{
              min: moment(startdateFourteen, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={
            !startdateFourteen || !starttimeFourteen || !durationFourteen
          }
          value={starttimeFifteen}
          name="starttimeFifteen"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateFifteen || !starttimeFifteen}
            value={durationFifteen}
            name="durationFifteen"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateFifteen}
            name="enddateFifteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeFifteen}
          name="endtimeFifteen"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const p = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateSixteen}
            name="startdateSixteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={
              !startdateFifteen || !starttimeFifteen || !durationFifteen
            }
            type="date"
            inputProps={{
              min: moment(startdateFifteen, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateFifteen || !starttimeFifteen || !durationFifteen}
          value={starttimeSixteen}
          name="starttimeSixteen"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateSixteen || !starttimeSixteen}
            value={durationSixteen}
            name="durationSixteen"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateSixteen}
            name="enddateSixteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeSixteen}
          name="endtimeSixteen"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const q = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateSeventeen}
            name="startdateSeventeen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={
              !startdateSixteen || !starttimeSixteen || !durationSixteen
            }
            type="date"
            inputProps={{
              min: moment(startdateSixteen, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateSixteen || !starttimeSixteen || !durationSixteen}
          value={starttimeSeventeen}
          name="starttimeSeventeen"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateSeventeen || !starttimeSeventeen}
            value={durationSeventeen}
            name="durationSeventeen"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateSeventeen}
            name="enddateSeventeen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeSeventeen}
          name="endtimeSeventeen"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const r = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateEighteen}
            name="startdateEighteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={
              !startdateSeventeen || !starttimeSeventeen || !durationSeventeen
            }
            type="date"
            inputProps={{
              min: moment(startdateSeventeen, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={
            !startdateSeventeen || !starttimeSeventeen || !durationSeventeen
          }
          value={starttimeEighteen}
          name="starttimeEighteen"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateEighteen || !starttimeEighteen}
            value={durationEighteen}
            name="durationEighteen"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateEighteen}
            name="enddateEighteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeEighteen}
          name="endtimeEighteen"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const s = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateNineteen}
            name="startdateNineteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={
              !startdateEighteen || !starttimeEighteen || !durationEighteen
            }
            type="date"
            inputProps={{
              min: moment(startdateEighteen, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={
            !startdateEighteen || !starttimeEighteen || !durationEighteen
          }
          value={starttimeNineteen}
          name="starttimeNineteen"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateNineteen || !starttimeNineteen}
            value={durationNineteen}
            name="durationNineteen"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateNineteen}
            name="enddateNineteen"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeNineteen}
          name="endtimeNineteen"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const t = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateTwenty}
            name="startdateTwenty"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={
              !startdateNineteen || !starttimeNineteen || !durationNineteen
            }
            type="date"
            inputProps={{
              min: moment(startdateNineteen, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={
            !startdateNineteen || !starttimeNineteen || !durationNineteen
          }
          value={starttimeTwenty}
          name="starttimeTwenty"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateTwenty || !starttimeTwenty}
            value={durationTwenty}
            name="durationTwenty"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateTwenty}
            name="enddateTwenty"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeTwenty}
          name="endtimeTwenty"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const u = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateTwentyOne}
            name="startdateTwentyOne"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdateTwenty || !starttimeTwenty || !durationTwenty}
            type="date"
            inputProps={{
              min: moment(startdateTwenty, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdateTwenty || !starttimeTwenty || !durationTwenty}
          value={starttimeTwentyOne}
          name="starttimeTwentyOne"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateTwentyOne || !starttimeTwentyOne}
            value={durationTwentyOne}
            name="durationTwentyOne"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateTwentyOne}
            name="enddateTwentyOne"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeTwentyOne}
          name="endtimeTwentyOne"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const v = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdateTwentyTwo}
            name="startdateTwentyTwo"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={
              !startdateTwentyOne || !starttimeTwentyOne || !durationTwentyOne
            }
            type="date"
            inputProps={{
              min: moment(startdateTwentyOne, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={
            !startdateTwentyOne || !starttimeTwentyOne || !durationTwentyOne
          }
          value={starttimeTwentyTwo}
          name="starttimeTwentyTwo"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdateTwentyTwo || !starttimeTwentyTwo}
            value={durationTwentyTwo}
            name="durationTwentyTwo"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddateTwentyTwo}
            name="enddateTwentyTwo"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtimeTwentyTwo}
          name="endtimeTwentyTwo"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const w = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdate23}
            name="startdate23"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={
              !startdateTwentyTwo || !starttimeTwentyTwo || !durationTwentyTwo
            }
            type="date"
            inputProps={{
              min: moment(startdateTwentyTwo, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={
            !startdateTwentyTwo || !starttimeTwentyTwo || !durationTwentyTwo
          }
          value={starttime23}
          name="starttime23"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdate23 || !starttime23}
            value={duration23}
            name="duration23"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddate23}
            name="enddate23"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtime23}
          name="endtime23"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const x = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdate24}
            name="startdate24"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdate23 || !starttime23 || !duration23}
            type="date"
            inputProps={{
              min: moment(startdate23, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdate23 || !starttime23 || !duration23}
          value={starttime24}
          name="starttime24"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdate24 || !starttime24}
            value={duration24}
            name="duration24"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddate24}
            name="enddate24"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtime24}
          name="endtime24"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const y = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdate25}
            name="startdate25"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdate24 || !starttime24 || !duration24}
            type="date"
            inputProps={{
              min: moment(startdate24, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdate24 || !starttime24 || !duration24}
          value={starttime25}
          name="starttime25"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdate25 || !starttime25}
            value={duration25}
            name="duration25"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddate25}
            name="enddate25"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtime25}
          name="endtime25"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const z = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdate26}
            name="startdate26"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdate25 || !starttime25 || !duration25}
            type="date"
            inputProps={{
              min: moment(startdate25, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdate25 || !starttime25 || !duration25}
          value={starttime26}
          name="starttime26"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdate26 || !starttime26}
            value={duration26}
            name="duration26"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddate26}
            name="enddate26"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtime26}
          name="endtime26"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const za = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdate27}
            name="startdate27"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdate26 || !starttime26 || !duration26}
            type="date"
            inputProps={{
              min: moment(startdate26, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdate26 || !starttime26 || !duration26}
          value={starttime27}
          name="starttime27"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdate27 || !starttime27}
            value={duration27}
            name="duration27"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddate27}
            name="enddate27"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtime27}
          name="endtime27"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const zb = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdate28}
            name="startdate28"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdate27 || !starttime27 || !duration27}
            type="date"
            inputProps={{
              min: moment(startdate27, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdate27 || !starttime27 || !duration27}
          value={starttime28}
          name="starttime28"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdate28 || !starttime28}
            value={duration28}
            name="duration28"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddate28}
            name="enddate28"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtime28}
          name="endtime28"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const zc = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdate29}
            name="startdate29"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdate28 || !starttime28 || !duration28}
            type="date"
            inputProps={{
              min: moment(startdate28, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdate28 || !starttime28 || !duration28}
          value={starttime29}
          name="starttime29"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdate29 || !starttime29}
            value={duration29}
            name="duration29"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddate29}
            name="enddate29"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtime29}
          name="endtime29"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const zd = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdate30}
            name="startdate30"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdate29 || !starttime29 || !duration29}
            type="date"
            inputProps={{
              min: moment(startdate29, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdate29 || !starttime29 || !duration29}
          value={starttime30}
          name="starttime30"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdate30 || !starttime30}
            value={duration30}
            name="duration30"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddate30}
            name="enddate30"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtime30}
          name="endtime30"
          label="End time"
        />
      </div>
    </Fragment>
  );

  const ze = (
    <Fragment>
      <div
        style={{ padding: "0", alignItems: "flex-end" }}
        className={classes.flexDiv}
      >
        <CustomFormControl style={{ marginRight: "5px" }}>
          <TextField
            id="date"
            label="Start date"
            onChange={handleDateChange}
            value={startdate31}
            name="startdate31"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            disabled={!startdate30 || !starttime30 || !duration30}
            type="date"
            inputProps={{
              min: moment(startdate30, "yyyy-MM-DD")
                .add(1, "d")
                .format("yyyy-MM-DD"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled={!startdate30 || !starttime30 || !duration30}
          value={starttime31}
          name="starttime31"
          onChange={handleTimeChange}
          required
          label="Start time"
        />
        <div style={{ margin: "0 5px 0 5px" }}>
          <CustomSemanticDD
            disabled={!startdate31 || !starttime31}
            value={duration31}
            name="duration31"
            onChange={handleDuration}
            label="Duration"
            data={DURATION}
          />
        </div>
        <CustomFormControl style={{ marginLeft: "5px" }}>
          <TextField
            id="date"
            label="End date"
            disabled
            value={enddate31}
            name="enddate31"
            //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </CustomFormControl>
        <CustomTime
          disabled
          value={endtime31}
          name="endtime31"
          label="End time"
        />
      </div>
    </Fragment>
  );

  return (
    <>
      <div className={classes.timeDiv}>
        <p style={{ margin: "0", marginTop: "16px" }}>
          Please select start date, time and duration for your booking, you can
          book maximum 31 days in one slot. Total duration must be calculated
          using the 'Calculate total duration' before submitting this form.
        </p>
        {timeCount.length === 1 && a}
        {timeCount.length === 2 && (
          <>
            {a}
            {b}
          </>
        )}
        {timeCount.length === 3 && (
          <>
            {a}
            {b}
            {c}
          </>
        )}
        {timeCount.length === 4 && (
          <>
            {a}
            {b}
            {c}
            {d}
          </>
        )}
        {timeCount.length === 5 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
          </>
        )}
        {timeCount.length === 6 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
          </>
        )}
        {timeCount.length === 7 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
          </>
        )}
        {timeCount.length === 8 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
          </>
        )}
        {timeCount.length === 9 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
          </>
        )}
        {timeCount.length === 10 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
          </>
        )}
        {timeCount.length === 11 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
          </>
        )}
        {timeCount.length === 12 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
          </>
        )}
        {timeCount.length === 13 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
          </>
        )}
        {timeCount.length === 14 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
          </>
        )}
        {timeCount.length === 15 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
          </>
        )}
        {timeCount.length === 16 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
          </>
        )}
        {timeCount.length === 17 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
          </>
        )}
        {timeCount.length === 18 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
          </>
        )}
        {timeCount.length === 19 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
          </>
        )}
        {timeCount.length === 20 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
          </>
        )}
        {timeCount.length === 21 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
          </>
        )}
        {timeCount.length === 22 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
            {v}
          </>
        )}
        {timeCount.length === 23 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
            {v}
            {w}
          </>
        )}
        {timeCount.length === 24 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
            {v}
            {w}
            {x}
          </>
        )}

        {timeCount.length === 25 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
            {v}
            {w}
            {x}
            {y}
          </>
        )}

        {timeCount.length === 26 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
            {v}
            {w}
            {x}
            {y}
            {z}
          </>
        )}

        {timeCount.length === 27 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
            {v}
            {w}
            {x}
            {y}
            {z}
            {za}
          </>
        )}
        {timeCount.length === 28 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
            {v}
            {w}
            {x}
            {y}
            {z}
            {za}
            {zb}
          </>
        )}
        {timeCount.length === 29 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
            {v}
            {w}
            {x}
            {y}
            {z}
            {za}
            {zb}
            {zc}
          </>
        )}
        {timeCount.length === 30 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
            {v}
            {w}
            {x}
            {y}
            {z}
            {za}
            {zb}
            {zc}
            {zd}
          </>
        )}
        {timeCount.length === 31 && (
          <>
            {a}
            {b}
            {c}
            {d}
            {e}
            {f}
            {g}
            {h}
            {i}
            {j}
            {k}
            {l}
            {m}
            {n}
            {o}
            {p}
            {q}
            {r}
            {s}
            {t}
            {u}
            {v}
            {w}
            {x}
            {y}
            {z}
            {za}
            {zb}
            {zc}
            {zd}
            {ze}
          </>
        )}
        {timeCount.length > 1 ? (
          <IconButton
            color="secondary"
            className="i-btn-two"
            component="button"
            onClick={handleRemove}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        ) : null}
        {timeCount.length != 31 ? (
          <IconButton
            color="primary"
            className="i-btn"
            component="button"
            onClick={handleAdd}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        ) : null}
      </div>

      {/* Total Calculation */}
      <Typography variant="h6" paragraph>
        Total days: {timeCount.length}
      </Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          style={{ backgroundColor: "#7C277D" }}
          disabled={!flag}
          onClick={handleClick}
          primary
        >
          Calculate total duration (hrs)
        </Button>
        <Typography variant="h4">&nbsp;{total_duration}</Typography>
      </div>
      {/* {durationOne && <Typography>Total duration: {moment.duration(total_duration).asHours()}</Typography> */}
    </>
  );
};

export default Time;
