import React from "react";
import TableOne from "./Interpreting/TableOne";
import TableTwo from "./Interpreting/TableTwo";
import TableThree from "./Interpreting/TableThree";
import TransTableOne from "./Translation/TableOne";
import TransTableTwo from "./Translation/TableTwo";
import TransTableThree from "./Translation/TableThree";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import { Header } from "semantic-ui-react";

const CloseTimesheet = ({ data, type }) => {
  const classes = useDashboardStyles();

  return (
    <div className={classes.listDiv}>
      <Header as="h4">CAC Interpreting Assignments</Header>
      <TableOne
        interpretingData={
          type === "interpreting" && data.includes("CAC") && data
        }
      />
      <Header as="h4">BAC Interpreting Assignments</Header>
      <TableTwo
        interpretingData={
          type === "interpreting" && data.includes("BAC") && data
        }
      />
      <Header as="h4">INC Interpreting Assignments</Header>
      <TableThree
        interpretingData={
          type === "interpreting" &&
          (data.includes("IT") || data.includes("TI")) &&
          data
        }
      />
      <Header as="h4">CAC Translation Assignments</Header>
      <TransTableOne
        translationData={type === "translation" && data.includes("CAC") && data}
      />
      <Header as="h4">BAC Translation Assignments</Header>
      <TransTableTwo
        translationData={type === "translation" && data.includes("BAC") && data}
      />
      <Header as="h4">INC Translation Assignments</Header>
      <TransTableThree
        translationData={
          type === "translation" &&
          (data.includes("IT") || data.includes("TI")) &&
          data
        }
      />
    </div>
  );
};

export default CloseTimesheet;
