import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Cookies from "js-cookie";
import React from "react";
import { Button } from "semantic-ui-react";
import swal from "sweetalert";
import Axios from "axios";
import history from "utils/history";

const Action = ({ id, type, cancel, load, pay }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("SPPOtoken");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const deleteInvoice = async (value) => {
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-client-invoice/invoiceID/${id}`
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure you want to delete this?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          deleteInvoice(value);

        default:
          break;
      }
    });
  };

  const archiveInvoice = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/archive-client-invoice/invoiceID/${id}`
      );
      swal({
        icon: "success",
        text: `Invoice moved to the archive folder.`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleArchive = async (value) => {
    swal("Are you sure?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          archiveInvoice(value);

        default:
          break;
      }
    });
  };

  const cancelInvoice = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/cancel-client-invoice/invoiceID/${id}`
      );
      swal({
        icon: "success",
        text: `Invoice cancelled.`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCancel = async (value) => {
    swal("Are you sure you want to cancel this invoice?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          cancelInvoice(value);

        default:
          break;
      }
    });
  };

  const resendInvoice = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/send-client-invoice/invoiceID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Invoice successfully sent to the client.`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleResend = async (value) => {
    swal("Are you sure you want to resend this invoice?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          resendInvoice(value);

        default:
          break;
      }
    });
  };

  const handleSee = (e) => {
    window.open(`/pay-direct-invoice/${id}`);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSee}>View And Pay</MenuItem>
      </Menu>
    </div>
  );
};

export default Action;
