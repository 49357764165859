import React from "react";
import { useSelector } from "react-redux";

// Material Core Components
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Material Icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";
import FormFour from "./FormFour";
import FormFive from "./FormFive";
import FormSix from "./FormSix";
import FormSeven from "./FormSeven";
import FormEight from "./FormEight";
import MasterLayout from "layout/MasterLayout";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import { Progress } from "semantic-ui-react";
import CustomFormControl from "components/Common/CustomFormControl";
import CustomAvatar from "components/Common/CustomAvatar";

const EmployeeRegistration = () => {
  const { count } = useSelector((state) => state.erReducer);

  const classes = useEmployeeStyles();

  const selectedForm = count;

  return (
    // <MasterLayout>
      // <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                {/* <div className={`${classes.textCenter} ${classes.title}`}> */}
                  {/* <CustomAvatar /> */}
                  {/* <Typography component="h1" variant="h5">
                    Employee Registration
                  </Typography>
                  <Typography variant="subtitle2">Page {count} of 8</Typography> */}
                {/* </div> */}
                {selectedForm === 1 && <FormOne />}
                {selectedForm === 2 && <FormTwo />}
                {selectedForm === 3 && <FormThree />}
                {selectedForm === 4 && <FormFour />}
                {selectedForm === 5 && <FormFive />}
                {selectedForm === 6 && <FormSix />}
                {selectedForm === 7 && <FormSeven />}
                {selectedForm === 8 && <FormEight />}
                <CustomFormControl width="73.33%">
                  <Progress
                    color="purple"
                    percent={(selectedForm / 8) * 100}
                    active
                    size="small"
                  />
                </CustomFormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      // </div>
    // </MasterLayout>
  );
};

export default EmployeeRegistration;
