import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomPhone from "components/Common/CustomPhone";
import CustomSelect from "components/Common/CustomSelect";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import {
  CURRENT_COUNTRY,
  GENDER_DATA,
  PRIMARY_SOURCE_LANG,
  PRIMARY_TARGET_LANG,
  SECURITY_CLEARANCE_LEVEL,
  EMAIL_REGEX,
} from "data";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { changePreRegInput } from "redux/actions/preregAction";
import Axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
import { Button, Header } from "semantic-ui-react";
import { LANG_COMB } from "data";

import { changeJaInput } from "redux/actions/advertAction";
import CustomAutoComplete from "components/Common/CustomAutoComplete";

const FormOne = () => {
  const classes = useFormStyles();
  const { data } = useSelector((state) => state.preregReducer);
  const dispatch = useDispatch();
  const [notValid, setNotValid] = useState(false);
  const [otherNotValid, setOtherNotValid] = useState(false);
  const fileInput = useRef(null);
  const [upload_cv, setCV] = useState();
  const {
    title,
    first_name,
    last_name,
    source_language,
    target_language,
    primary_email,
    other_email,
    mobile_no,
    phone_no,
    addressline1,
    addressline2,
    city,
    additional_language,
    postal_code,
    region,
    country,
    security_clearence,
    other,
    gdpr,
    note,
  } = data;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changePreRegInput(inputData));
  };

  const handleCV = (e) => {
    setCV(e.target.files[0]);
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleChecked = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: checked,
    };
    dispatch(changePreRegInput(inputData));
  };
  const handleDateChange = (date) => {
    dispatch(
      changePreRegInput({
        name: "dob",
        value: date,
      })
    );
  };

  const handleAutoCompleteON = (e, value) => {
    dispatch(
      changePreRegInput({
        name: "other_nationalities",
        value,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changePreRegInput({
        name: "country",
        value,
      })
    );
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changePreRegInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };
  const handleOtherEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changePreRegInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setOtherNotValid(false);
    } else {
      setOtherNotValid(true);
    }
  };

  const handleMobile = (value) => {
    dispatch(
      changePreRegInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handlePhone = (value) => {
    dispatch(
      changePreRegInput({
        name: "phone_no",
        value,
      })
    );
  };

  const isAllow =
    !title ||
    !first_name ||
    !last_name ||
    !primary_email ||
    !mobile_no ||
    !city ||
    !country ||
    !source_language ||
    !target_language ||
    !gdpr ||
    notValid ||
    (other_email && otherNotValid);

  const handleSubmit = async (e) => {
    let fd = new FormData();

    fd.append("title", title);
    fd.append("first_name", first_name);
    fd.append("last_name", last_name);
    fd.append("source_language", source_language);
    fd.append("target_language", target_language);
    fd.append("primary_email", primary_email);
    fd.append("other_email", other_email);
    fd.append("mobile_no", mobile_no);
    fd.append("phone_no", phone_no);
    fd.append("addressline1", addressline1);
    fd.append("addressline2", addressline2);
    fd.append("city", city);
    fd.append("postal_code", postal_code);
    fd.append("region", region);
    fd.append("country", country);
    fd.append("security_clearence", security_clearence);
    fd.append("upload_cv", upload_cv);
    fd.append("gdpr", gdpr);
    fd.append("note", note);

    const token = Cookies.get("SPPOtoken");

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/create/pregistration`,
        fd,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `Linguist successfully added to the unregistered list.`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/sppo/linguist-registration";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `Something wrong!`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleChangeSO = (e, value) => {
    dispatch(
      changePreRegInput({
        name: "source_language",
        value,
      })
    );
  };

  const handleChangeTA = (e, value) => {
    dispatch(
      changePreRegInput({
        name: "target_language",
        value,
      })
    );
  };

  const handleChangeSE = (e, value) => {
    dispatch(
      changePreRegInput({
        name: "security_clearence",
        value,
      })
    );
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeJaInput({
        name: "additional_language",
        value,
      })
    );
  };

  return (
    <form className={` form ${classes.form}`} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        value={title}
        name="title"
        label="Title"
        width="100%"
        required
      />
      <CustomInputSingle
        onChange={handleChange}
        value={first_name}
        name="first_name"
        label="First Name(s)"
        width="100%"
        required
      />
      <CustomInputSingle
        onChange={handleChange}
        value={last_name}
        name="last_name"
        label="Last Name"
        width="100%"
        required
      />
      <CustomAutoSelect
        onChange={handleChangeSO}
        value={source_language}
        name="source_language"
        label="Source Language"
        data={PRIMARY_SOURCE_LANG.filter((value) => value !== target_language)}
        width="100%"
        required
      />
      <CustomAutoSelect
        onChange={handleChangeTA}
        value={target_language}
        name="target_language"
        label="Target Language"
        data={PRIMARY_TARGET_LANG.filter((value) => value !== source_language)}
        width="100%"
        required
      />

      <CustomAutoComplete
        onChange={handleAutoComplete}
        width="100%"
        label="Other Languages"
        name="additionallanguage"
        data={LANG_COMB}
        value={additional_language}
        placeholder="Choose"
      />

      <CustomInputSingle
        onChange={handleEmailChange}
        value={primary_email}
        name="primary_email"
        label="Primary email"
        width="100%"
        required
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomInputSingle
        onChange={handleOtherEmailChange}
        value={other_email}
        name="other_email"
        label="Other email"
        width="100%"
      />
      {other_email && otherNotValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <br />
      <CustomPhone
        onChange={handleMobile}
        value={mobile_no}
        width="100%"
        label="Mobile number"
        name="mobile_no"
        required
      />
      <br />
      <CustomPhone
        onChange={handlePhone}
        value={phone_no}
        width="100%"
        label="Phone number"
        name="phone_no"
      />

      <CustomInputSingle
        onChange={handleChange}
        value={addressline1}
        name="addressline1"
        label="House number/name, Street name"
        width="100%"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={addressline2}
        name="addressline2"
        label="Address line 2"
        width="100%"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={city}
        name="city"
        label="Town / City"
        width="100%"
        required
      />
      <CustomInputSingle
        onChange={handleChange}
        label="County / Region"
        width="100%"
        name="region"
        value={region}
      />
      <CustomInputSingle
        onChange={handleChange}
        value={postal_code}
        name="postal_code"
        label="Post Code / Zip Code / Post office"
        width="100%"
      />
      <CustomAutoSelect
        onChange={handleAutoCompleteCO}
        label="Country"
        moreInfo="Current country of residence"
        width="100%"
        value={country}
        data={CURRENT_COUNTRY}
        required
      />

      <CustomSelect
        onChange={handleChange}
        width="100%"
        label="Security Clearance"
        name="security_clearence"
        data={SECURITY_CLEARANCE_LEVEL}
        value={security_clearence}
        placeholder="Choose"
      />

      {/* {security_clearence?.includes('Other') ? (
        <CustomInputSingle
          onChange={handleChange}
          value={other}
          name="other"
          label="Other details"
          width="100%"
        />
      ) : null} */}

      <CustomInputSingle
        onChange={handleChange}
        value={note}
        name="note"
        label="Note"
        width="100%"
        required
      />

      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "24px" }}
      >
        <input
          name="identification_doc"
          onChange={handleCV}
          type="file"
          style={{ display: "none" }}
          accept=".doc,.pdf"
          ref={fileInput}
        />
        <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
          Upload your CV here
        </Header>
        <div>
          <Button onClick={handleClick}>Upload</Button>
        </div>
        {upload_cv && (
          <Header style={{ margin: "0", textAlign: "center" }} as="h5">
            {upload_cv.name}
            <Header style={{ margin: "0" }} as="h5">{`Size: ${(
              upload_cv.size / 1024
            ).toFixed(2)}kb`}</Header>
            <Header
              style={{ margin: "0" }}
              as="h5"
            >{`Last Modified: ${upload_cv?.lastModifiedDate?.toDateString()}`}</Header>
          </Header>
        )}
        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChecked}
                checked={gdpr}
                name="gdpr"
                color="primary"
              />
            }
            label="I have taken the details from public source/s or collected from the individual provided to me.
                "
          />
        </div>

        <div className={classes.buttonFlex}>
          <CustomButton
            onClick={handleSubmit}
            disabled={isAllow}
            text="Submit"
          />
        </div>
      </div>
    </form>
  );
};

export default FormOne;
