import { Card } from "@material-ui/core";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment"
import PostJobShortList from "./jobadvert/PostJobShortList";

const ThreeCards = ({ text, contactList }) => {
  const classes = useLandingpageStyles();

  const searchFunc = (item) => {
    if (!text) {
      return true;
    }

    const searchText = text.toLowerCase();

    let {
      city,
      country,
      region,
      nationality,
      postcode,
      targetlanguage,
      sourcelanguage,
      jobtitle,
      salary,
    } = item;

    city = city || "-";
    country = country || "-";
    region = region || "-";
    nationality = nationality || "-";
    postcode = postcode || "-";
    targetlanguage = targetlanguage || "-";
    sourcelanguage = sourcelanguage || "-";
    jobtitle = jobtitle || "-";
    salary = salary || "-";

    const isMatched =
      city.toLowerCase().includes(searchText) ||
      country.toLowerCase().includes(searchText) ||
      region.toLowerCase().includes(searchText) ||
      nationality.toLowerCase().includes(searchText) ||
      targetlanguage.toLowerCase().includes(searchText) ||
      sourcelanguage.toLowerCase().includes(searchText) ||
      jobtitle.toLowerCase().includes(searchText) ||
      salary.toLowerCase().includes(searchText) ||
      postcode.toLowerCase().includes(searchText);

    return isMatched;
  };

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <PostJobShortList data={contactList} />
        </GridItem>
      </GridContainer>
      <GridContainer>
        {contactList.filter(searchFunc).map((list) => (
          <GridItem xs={12} sm={12} md={3} lg={3}>
            {moment(list?.applicationclosingdate) < moment() ? null :
              <Card
                raised
                style={{
                  marginBottom: "1rem",
                  marginTop: "3rem",
                }}
              >
                <CardBody>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/job-advert-details/${list._id}`}
                  >
                    <div className={classes.card}>
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#9c27b0",
                        }}
                      >
                        {list.jobtitle}
                      </p>
                      <p>Salary: {list.salary}</p>
                      <p>Target Language: {list.targetlanguage}</p>
                      <p>Source Language: {list.sourcelanguage}</p>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            }
          </GridItem>
        ))}
      </GridContainer>
    </Fragment>
  );
};

export default ThreeCards;
