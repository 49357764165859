import React, { useState, useEffect } from "react";
import { DetailsDiv } from "./style";
import { useParams } from "react-router-dom";
import Axios from "utils/axios";
import moment from "moment";

const Details = () => {
  const { billID } = useParams();
  const { clientID } = useParams();
  const [values, setValues] = React.useState(null);
  const [bill, setBill] = React.useState(null);
  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
    vat: "",
  });

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const ClientDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/manager-admin/view-account-client/${clientID}`
        );

        setValues(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          console.log(error.response?.data);
        }
      }
    };

    const getBillDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/manager-admin/view-Bill-details/billID/${billID}`
        );

        setBill(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          console.log(error.response?.data);
        }
      }
    };

    const getCompanyCard = async () => {
      try {
        const response = await Axios.get(
          `/api/manager-admin/view/company-information-card`
        );

        setCompanyCard({
          ...companyCard,
          basc: response?.data[0].getCompanyCard,
          pbsc: response?.data[0].platform_booking_service_charge,
          basc: response?.data[0].billing_account_service_charge,
          cname: response?.data[0].company_name,
          caddress: response?.data[0].company_address,
          vat: response?.data[0].vat_number,
        });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          console.log(error.response?.data);
        }
      }
    };

    ClientDetails();
    getCompanyCard();
    getBillDetails();

    return () => {
      source.cancel();
    };
  }, []);

  let sl = 1;

  return (
    <DetailsDiv>
      <div className="left">
        <ul>
          <li>
            Bill to: <span>{values?.FirstName}</span>
          </li>
          <li>
            Name of the company: <span>{values?.company}</span>
          </li>
          <li className="address">Address: {values?.AddressLine1}</li>
          <li>Email: {values?.email}</li>
          <li>Client ID: {values?.id}</li>
        </ul>
      </div>
      <div style={{ flex: "1" }}>
        <ul>
          <li>
            Billing Department <span>{values?.AddressLine1}</span>
          </li>
          <li>
            Name: <span>{values?.FirstName}</span>
          </li>
          <li>
            Position: <span>{values?.position ? values?.position : "N/A"}</span>
          </li>
          <li className="address">Address: {values?.AddressLine1}</li>
          <li>Contact: {values?.mobile_no}</li>
          <li>Email: {values?.email}</li>
        </ul>
      </div>
      <div className="right">
        <ul>
          <li>{companyCard?.cname}</li>
          <li className="address">Address: {companyCard?.caddress}</li>

          <li>Bill number: {bill?.bill_no}</li>
          <li>LC VAT Number: {companyCard?.vat}</li>
          <li>
            Bill from: {moment().subtract(1, "month").format(`02-MM-YYYY`)}
          </li>
          <li>Billing date: {bill?.billing_month}</li>
          <li>Bill to be paid by: {moment().format("15-MM-YYYY")}</li>
        </ul>
      </div>
    </DetailsDiv>
  );
};

export default Details;
