import React from 'react'

const CustomSemanticDD = ({label, data, ...rest}) => {
    return (
        <select name= {label} {...rest} className="ui dropdown" required>
            <option value="GBP">{label}</option>
            {data.map(dt => (
                <option value= {dt}>{dt}</option>
            ))}
        </select>
    )
}

export default CustomSemanticDD
