import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomSelect from "components/Common/CustomSelect";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import { DropzoneArea } from "material-ui-dropzone";
// Other
import {
  CURRENT_COUNTRY,
  GENDER_DATA,
  PRIMARY_SOURCE_LANG,
  PRIMARY_TARGET_LANG,
  SECURITY_CLEARANCE_LEVEL,
  EMAIL_REGEX,
} from "data";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomUpload from "components/Common/CustomUpload";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { changePreRegInput } from "redux/actions/preregAction";
import CustomCaptcha from "components/Common/CustomCaptcha";
import Axios from "axios";
import { LocationCityOutlined } from "@material-ui/icons";
import swal from "sweetalert";
import CustomNumberInput from "components/Common/CustomNumberInput";

import { LANG_COMB } from "data";

import { changeJaInput } from "redux/actions/advertAction";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import history from "utils/history";
import { Icon, Header, Button } from "semantic-ui-react";
import CustomPhone from "components/Common/CustomPhone";

const FormOne = () => {
  const classes = useFormStyles();
  const btnClasses = useLandingpageStyles();
  const { data } = useSelector((state) => state.preregReducer);
  const [captcha, setCaptcha] = useState("");
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const [notValid, setNotValid] = useState(false);
  const {
    title,
    first_name,
    last_name,
    source_language,
    target_language,
    primary_email,
    other_email,
    mobile_no,
    phone_no,
    addressline1,
    addressline2,
    city,
    additional_language,
    postal_code,
    region,
    country,
    security_clearence,
    other,
    upload_cv,
    gdpr,
  } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changePreRegInput(inputData));
  };
  const handleChecked = (e) => {
    const { name, checked } = e.target;
    const inputData = {
      name,
      value: checked,
    };
    dispatch(changePreRegInput(inputData));
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changePreRegInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleDateChange = (date) => {
    dispatch(
      changePreRegInput({
        name: "dob",
        value: date,
      })
    );
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeJaInput({
        name: "additional_language",
        value,
      })
    );
  };

  const handleAutoCompleteON = (e, value) => {
    dispatch(
      changePreRegInput({
        name: "other_nationalities",
        value,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changePreRegInput({
        name: "country",
        value,
      })
    );
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };

  // const handleFiles = (e) => {
  //   const value = e.target.files[0];
  //   dispatch(
  //     changePreRegInput({
  //       name: "upload_cv",
  //       value,
  //     })
  //   );
  // };

  const handleFiles = (file) => {
    dispatch(
      changePreRegInput({
        name: "upload_cv",
        value: file[0],
      })
    );
  };

  const handleSubmit = async (e) => {
    let fd = new FormData();

    fd.append("title", title);
    fd.append("first_name", first_name);
    fd.append("last_name", last_name);
    fd.append("source_language", source_language);
    fd.append("target_language", target_language);
    fd.append("primary_email", primary_email);
    fd.append("other_email", other_email);
    fd.append("mobile_no", mobile_no);
    fd.append("phone_no", phone_no);
    fd.append("addressline1", addressline1);
    fd.append("addressline2", addressline2);
    fd.append("city", city);
    fd.append("postal_code", postal_code);
    fd.append("region", region);
    fd.append("country", country);
    fd.append("security_clearence", security_clearence);
    fd.append("upload_cv", upload_cv);
    fd.append("gdpr", gdpr);
    fd.append("upload_cv", upload_cv);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/preregistration`,
        fd
      );
      swal({
        title: "Success",
        text: `Successfully send your CV`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/landing-page/linguist-professionals/upload-cv";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleChangeSO = (e, value) => {
    dispatch(
      changePreRegInput({
        name: "source_language",
        value,
      })
    );
  };

  const handleChangeTA = (e, value) => {
    dispatch(
      changePreRegInput({
        name: "target_language",
        value,
      })
    );
  };

  const handleChangeSE = (e, value) => {
    dispatch(
      changePreRegInput({
        name: "security_clearence",
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changePreRegInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handlePhone = (value) => {
    dispatch(
      changePreRegInput({
        name: "phone_no",
        value,
      })
    );
  };

  const isAllow =
    !title ||
    !first_name ||
    !last_name ||
    !primary_email ||
    !source_language ||
    !target_language ||
    !mobile_no ||
    !addressline1 ||
    !postal_code ||
    !region ||
    !city ||
    !country ||
    !upload_cv ||
    !captcha ||
    !gdpr ||
    (notValid && other_email !== "") ||
    (notValid && primary_email !== "");

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        value={title}
        name="title"
        label="Title"
        width="100%"
        required
        placeholder="( e.g. Mr. / Mrs / Miss etc. )"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={first_name}
        name="first_name"
        label="First Name(s)"
        width="100%"
        required
        placeholder="First Name(s)/Forename/Given Names as shown on passport or birth certificate"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={last_name}
        name="last_name"
        label="Last Name"
        width="100%"
        placeholder="Last Name/Surname/Family Name as shown on passport or marriage certificate"
        required
      />
      <CustomAutoSelect
        onChange={handleChangeSO}
        value={source_language}
        name="source_language"
        label="Source / Native Language"
        data={PRIMARY_TARGET_LANG.filter((value) => value !== target_language)}
        width="100%"
        required
      />
      <CustomAutoSelect
        onChange={handleChangeTA}
        value={target_language}
        name="target_language"
        label="Target Language"
        data={PRIMARY_TARGET_LANG.filter((value) => value !== source_language)}
        width="100%"
        required
      />

      <CustomAutoComplete
        onChange={handleAutoComplete}
        width="100%"
        label="Other Languages"
        name="additionallanguage"
        data={LANG_COMB}
        value={additional_language}
        placeholder="Choose"
      />

      <CustomInputSingle
        onChange={handleEmailChange}
        value={primary_email}
        name="primary_email"
        label="Primary email"
        width="100%"
        required
        placeholder="e.g. example@example.com"
      />
      {notValid && primary_email !== "" && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomInputSingle
        onChange={handleEmailChange}
        value={other_email}
        name="other_email"
        label="Other email"
        width="100%"
        placeholder="e.g. example@example.com"
      />
      {notValid && other_email !== "" && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <div style={{ marginTop: "24px" }}>
        <CustomPhone
          onChange={handleMobile}
          placeholder="Mobile number *"
          value={mobile_no}
          name="mobile_no"
          required
          placeholder="Mobile Number* (Please select country then type number e.g. +44 7307 000000)"
        />
      </div>
      <div style={{ marginTop: "24px" }}>
        <CustomPhone
          label="Phone Number"
          onChange={handlePhone}
          placeholder="Phone number"
          value={phone_no}
          name="phone_no"
          placeholder="Phone Number (Please select country then type number e.g. +44 1234 000000)"
        />
      </div>

      <CustomInputSingle
        onChange={handleChange}
        value={addressline1}
        name="addressline1"
        label="House number/name, Street name"
        width="100%"
        required
        placeholder="e.g. Flat/Building Name, 101 Example Street"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={addressline2}
        name="addressline2"
        label="Address line 2"
        width="100%"
        placeholder="Additional Address Information"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={city}
        name="city"
        label="Town / City"
        width="100%"
        required
        placeholder="Your Village, Town or City Name"
      />
      <CustomInputSingle
        onChange={handleChange}
        label="County / Region"
        required
        width="100%"
        name="region"
        value={region}
        placeholder="Your County, Division or State Name"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={postal_code}
        name="postal_code"
        label="Post Code / Zip Code / Post office"
        width="100%"
        required
        placeholder="Your Post Code, Zip Code or Post Office Name"
      />
      <CustomAutoSelect
        onChange={handleAutoCompleteCO}
        label="Country"
        moreInfo="Current country of residence"
        width="100%"
        value={country}
        data={CURRENT_COUNTRY}
        required
      />

      <CustomAutoComplete
        onChange={handleAutoComplete}
        width="100%"
        label="Security Clearance"
        name="security_clearence"
        data={SECURITY_CLEARANCE_LEVEL}
        value={additional_language}
        placeholder="Choose"
      />
      {security_clearence?.includes("Other") ? (
        <CustomInputSingle
          onChange={handleChange}
          value={other}
          name="other"
          label="Other details"
          width="100%"
          required
        />
      ) : null}
      <br />
      <p>Please upload
        <a
          target="_blank"
          href="https://europa.eu/europass/en"
        >
          {" "}
          Europass CV{" "}
        </a>  in English.
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCy_sIqjGtj4wRFv8f45Qyig/videos"
        >
          {" "}
          YouTube{" "}
        </a>
      </p>
      <br />

      <DropzoneArea
        acceptedFiles={[".jpg, .jpeg, .png, .pdf"]}
        onChange={handleFiles}
        showFileNames
        dropzoneText="Drag and Drop here or Click to upload your CV in English (preferably Europass). PDF format, Max 1 file and 5MB.        Max 1 file 5MB."
        showAlerts={true}
        clearOnUnmount
        maxFileSize={5000000}
        showPreviewsInDropzone
        showFileNamesInPreview
        filesLimit={1}
      />

      <div
        className={classes.tc}
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <p>
          <Checkbox
            onChange={handleChecked}
            checked={gdpr}
            name="gdpr"
            color="primary"
          />
          By submitting this form I agree with LC
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/privacy-policy"
          >
            {" "}
            Privacy Policy{" "}
          </a>
          and{" "}
          <a
            target="_blank"
            href="https://linguistscollective.com/landing-page/terms-and-conditions"
          >
            {" "}
            T&C
          </a>
        </p>
      </div>



      <CustomCaptcha onChange={handleCaptcha} />

      <div className={classes.buttonFlex}>
        <CustomButton onClick={handleSubmit} disabled={isAllow} text="Submit" />
      </div>
    </form>
  );
};

export default FormOne;
