import { Fragment, useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

// Material Components
import Button from "@material-ui/core/Button";
import CustomPassword from "components/Common/CustomPassword";
import defaultAxios from "utils/axios";
import { PASS_REGEX } from "data";
const AccountClientSetPassword = ({ token }) => {
  const history = useHistory();
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [notRegex, setNotRegex] = useState(false);

  const handleChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };
  const handleNewChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
    if (e.target.value !== "" && PASS_REGEX.test(e.target.value)) {
      setNotRegex(false);
    } else {
      setNotRegex(true);
    }
  };
  const handleSubmit = async () => {
    const { newPassword, confirmPassword } = passwords;

    const sendData = {
      resetPasswordLink: token,
      newPassword,
      confirmPassword,
    };

    setLoading(true);

    try {
      // Call api

      const response = await defaultAxios.put(
        "/api/accountclient/reset-password ",
        sendData
      );

      // console.log(response?.data);

      // After success, either redirect or show message
      // Account Client
      swal({
        title: "Welcome!",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });
      history.push("/account-client-login");
      setLoading(false);
    } catch (error) {
      // Show error message if any
      // console.log("error", error);
      // error
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <CustomPassword
        label="New Password"
        name="newPassword"
        value={passwords.newPassword}
        onChange={handleNewChange}
        notRegex={notRegex}
      />
      <CustomPassword
        label="Confirm Password"
        name="confirmPassword"
        value={passwords.confirmPassword}
        onChange={handleChange}
        validation={passwords.newPassword !== passwords.confirmPassword}
      />
      <Button
        color="primary"
        variant="contained"
        style={{
          marginTop: "15px",
        }}
        onClick={handleSubmit}
        disabled={
          !passwords.newPassword ||
          !passwords.confirmPassword ||
          loading ||
          passwords.newPassword !== passwords.confirmPassword ||
          notRegex
        }
      >
        {loading ? "Submitting" : "Submit"}
      </Button>
    </Fragment>
  );
};

export default AccountClientSetPassword;
