import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Moment from "moment";

import IconButton from "@material-ui/core/IconButton";
import { Button } from "semantic-ui-react";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "40%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
    ["@media(max-width:1900px)"]: {
      width: "100%",
      display: "block",
    },
  },
  button: {
    ["@media(max-width:1024px)"]: {
      width: "100%",
      marginBottom: "5px !important",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const SearchInput = ({
  setFiltered,
  setFilter,
  values,
  SearchText,
  setSearchText,
  handleSearch,
  setShow,
  load,
  show,
}) => {
  const classes = useStyles();
  const handleInput = (value) => {
    setSearchText(value);
    if (value == "" || value == undefined) {
      load();
    }
    // else {
    //   setFiltered(
    //     values?.filter((item) => {
    //       if (
    //         Moment(item?.createdAt)
    //           .tz("Europe/London")
    //           .format("DD MMM YYYY")
    //           .toLowerCase() === value?.toLowerCase()
    //       ) {
    //         return (
    //           Moment(item?.createdAt)
    //             .tz("Europe/London")
    //             .format("DD MMM YYYY")
    //             .toLowerCase() === value?.toLowerCase()
    //         );
    //       } else if (item.FirstName?.toLowerCase() === value?.toLowerCase()) {
    //         return item.FirstName?.toLowerCase() === value?.toLowerCase();
    //       } else if (item.LastName?.toLowerCase() === value?.toLowerCase()) {
    //         return item.LastName?.toLowerCase() === value?.toLowerCase();
    //       } else if (item.email?.toLowerCase() === value?.toLowerCase()) {
    //         return item.email?.toLowerCase() === value?.toLowerCase();
    //       } else if (item.City?.toLowerCase() === value?.toLowerCase()) {
    //         return item.City?.toLowerCase() === value?.toLowerCase();
    //       } else if (item.Country?.toLowerCase() === value?.toLowerCase()) {
    //         return item.Country?.toLowerCase() === value?.toLowerCase();
    //       } else if (
    //         item.contact_group?.toLowerCase() === value?.toLowerCase()
    //       ) {
    //         return item.contact_group?.toLowerCase() === value?.toLowerCase();
    //       } else if (
    //         item.City?.toLowerCase() + " - " + item.Country?.toLowerCase() ===
    //         value?.toLowerCase()
    //       ) {
    //         return (
    //           item.City?.toLowerCase() + " - " + item.Country?.toLowerCase() ===
    //           value?.toLowerCase()
    //         );
    //       } else if (
    //         item.source_language[0]?.toLowerCase() === value?.toLowerCase()
    //       ) {
    //         return (
    //           item.source_language[0]?.toLowerCase() === value?.toLowerCase()
    //         );
    //       } else if (
    //         item.source_language_1[0]?.toLowerCase() === value?.toLowerCase()
    //       ) {
    //         return (
    //           item.source_language_1[0]?.toLowerCase() === value?.toLowerCase()
    //         );
    //       } else if (
    //         item.source_language_2[0]?.toLowerCase() === value?.toLowerCase()
    //       ) {
    //         return (
    //           item.source_language_2[0]?.toLowerCase() === value?.toLowerCase()
    //         );
    //       } else if (
    //         item.target_language[0]?.toLowerCase() === value?.toLowerCase()
    //       ) {
    //         return (
    //           item.target_language[0]?.toLowerCase() === value?.toLowerCase()
    //         );
    //       } else if (
    //         item.target_language_1[0]?.toLowerCase() === value?.toLowerCase()
    //       ) {
    //         return (
    //           item.target_language_1[0]?.toLowerCase() === value?.toLowerCase()
    //         );
    //       } else if (
    //         item.target_language_2[0]?.toLowerCase() === value?.toLowerCase()
    //       ) {
    //         return (
    //           item.target_language_2[0]?.toLowerCase() === value?.toLowerCase()
    //         );
    //       } else if (
    //         item.target_language_level[0]?.toLowerCase() ===
    //         value?.toLowerCase()
    //       ) {
    //         return (
    //           item.target_language_level[0]?.toLowerCase() ===
    //           value?.toLowerCase()
    //         );
    //       } else if (
    //         item.target_language_level_1[0]?.toLowerCase() ===
    //         value?.toLowerCase()
    //       ) {
    //         return (
    //           item.target_language_level_1[0]?.toLowerCase() ===
    //           value?.toLowerCase()
    //         );
    //       } else if (
    //         item.target_language_level_2[0]?.toLowerCase() ===
    //         value?.toLowerCase()
    //       ) {
    //         return (
    //           item.target_language_level_2[0]?.toLowerCase() ===
    //           value?.toLowerCase()
    //         );
    //       } else if (item.position?.toLowerCase() === value?.toLowerCase()) {
    //         return item.position?.toLowerCase() === value?.toLowerCase();
    //       }
    //     })
    //   );
    //   setFilter(Math.random());
    // }
  };
  return (
    <div>
      <Paper
        component="form"
        className={classes.root}
        onClick={(e) => e.preventDefault()}
      >
        <InputBase
          className={classes.input}
          placeholder="Find Linguist with (firstname,lastname,email,etc).."
          onChange={(e) => {
            handleInput(e.target.value);
          }}
          inputProps={{ "aria-label": "search google maps" }}
          value={SearchText}
          // onChange={(e) => setText(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Button
          className={classes.button}
          color="purple"
          onClick={handleSearch}
        >
          Find a linguist
        </Button>
        <Button
          className={classes.button}
          onClick={() => setShow(!show)}
          color="purple"
        >
          View all linguists
        </Button>
      </Paper>
    </div>
  );
};

export default SearchInput;
