import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Cookies from "js-cookie";
import Axios from "utils/axios";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ text }) {
  const classes = useStyles();
  const { id } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("token");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();
  const [isBAC, setIsBAC] = useState(false);

  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    List: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, List, removed, reload } = values;

  useEffect(() => {
    loadList();
  }, [reload]);

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/invitation-list/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, List: response?.data });
      setIsBAC(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile No.</TableCell>
                  <TableCell>Target Language</TableCell>
                  <TableCell>Source Language</TableCell>
                  <TableCell>Note</TableCell>
                  <TableCell>Date and time (London)</TableCell>
                </TableRow>
              </TableHead>

              {List.length > 0 ? (
                <TableBody>
                  {List.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((list, i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell>
                        {list.first_name} - {list.last_name}
                      </TableCell>
                      <TableCell>{list.email}</TableCell>
                      <TableCell>{list.mobile_no}</TableCell>
                      <TableCell>{list.target_language}</TableCell>
                      <TableCell>{list.source_language}</TableCell>
                      <TableCell>
                        <p style={{ textAlign: "justify" }}>{list.note}</p>
                      </TableCell>
                      <TableCell>
                        {moment(list.createdAt)
                          .tz("Europe/London")
                          .format("DD-MMM-YYYY")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={List.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
}
