import React from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AddData from "./AddData";

import { PricecalculatorDiv } from "./style";

const PricecalculatorPage = () => {
  return (
    <PricecalculatorDiv>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={8}
          lg={8}
          style={{
            margin: "auto",
          }}
        >
          <AddData />
        </GridItem>
      </GridContainer>
    </PricecalculatorDiv>
  );
};

export default PricecalculatorPage;
