import React, { useEffect, useState } from "react";

// Custom Components
import Cookies from "js-cookie";
import Axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import SearchView from "./InterpretingSearch/SearchView";
import { useToasts } from "react-toast-notifications";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const TableOne = () => {
  const classes = useDashboardStyles();
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { addToast } = useToasts();
  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });
  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-cashclient-closed-booking/${searchText}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (searchText == undefined || searchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });
        // bookingList=[response?.data]
        addToast("Assignment found", { appearance: "success" });
      } else {
        addToast("Assignment not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const {
    name,
    error,
    success,
    bookingList,
    cacAndBacAccess,
    removed,
    reload,
  } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = async () => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/cashclient-history-booking-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/accountclient-history-booking-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/inhouse-history-booking-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((cacBooking, bacBooking, incBooking) => {
        const history = cacBooking.data
          .concat(bacBooking.data)
          .concat(incBooking.data);
        setValues({ ...values, bookingList: history });
      })
    );
  };

  return (
    <div>
      <SearchView
        show={show}
        load={loadbookingList}
        handleSearch={handleSearch}
        setSearchText={setSearchText}
        searchText={searchText}
        setShow={setShow}
      />
      <Paper className={classes.root}>
        {show ? (
          <div>
            {" "}
            <TableContainer className={classes.container}>
              <Table color="pink" stickyHeader aria-label="sticky table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="1">Assignment ID</Table.HeaderCell>
                    <Table.HeaderCell width="1">Duration</Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Source language
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Target language
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Target language skill
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1">
                      Service Required
                    </Table.HeaderCell>
                    {/* <Table.HeaderCell width= '1'>Action</Table.HeaderCell> */}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {bookingList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((list) => (
                      <Table.Row
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={list._id}
                      >
                        <Table.Cell key={list._id} align={list.align}>
                          {list.id}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.gethour ? list?.gethour : list?.total_Duration}
                        </Table.Cell>
                        <Table.Cell>{list.source_language}</Table.Cell>
                        <Table.Cell>{list.target_language}</Table.Cell>
                        <Table.Cell>
                          {list?.target_linguist_skill_level
                            ? list?.target_linguist_skill_level
                            : list?.target_language_level}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.typesof_interpreting_required
                            ? list?.typesof_interpreting_required
                            : list?.interpreting_service_required}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
};

export default TableOne;
