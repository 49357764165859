import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import Axios from "axios";
import Cookies from 'js-cookie'
import swal from "sweetalert";
import { Button } from "semantic-ui-react";

const Action = ({id, type}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get('token')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = e => {
      history.push(`view-public-profile/${id}`)
  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        // style={{
        //   minWidth: "10px",
        //   paddingLeft: "0px",
        // }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>List View</MenuItem>
        <MenuItem onClick={handleClose}>Amend</MenuItem>
        <MenuItem onClick={handleClose}>Change Status</MenuItem>
        <MenuItem onClick={handleClose}>Archive</MenuItem>
        <MenuItem onClick={handleClose}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

export default Action;
