import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import MasterLayout from "layout/MasterLayout";
import Notice from "components/ManagementPanel/Noitce";

const NoticePage = () => {
  return (
    <MasterLayout>
      <Manager>
        <Notice />
      </Manager>
    </MasterLayout>
  );
};

export default NoticePage;
