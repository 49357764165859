import { AC_CHANGE_TRANSLATION_INPUT, AC_UPDATE_TRANSLATION_STEP } from "../actions/actionTypes";
import moment from 'moment-timezone'
const INITIAL_STATE = {
  count: 1,
  data: {
    purchase_order_number: '',
    document_type: '',
    source_language: '',
    target_language: '',
    expertise_level_required: '',
    require_visual_formating: '',
    certified_translation: '',
    certificate: '',
    additional_quality_check: '',
    document: '',
    provider_id_number: '',
    additional_note: '',
    timezone: moment.tz.guess(),
    deadline: null,
    agree: false,
    count: ''
  }
};

const transReducerAC = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AC_UPDATE_TRANSLATION_STEP:
      return {
        ...state,
        count: payload,
      };

    case AC_CHANGE_TRANSLATION_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value
        }
      }

    default:
      return state;
  }
};

export default transReducerAC;
