import Axios from "utils/axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import { Button, Header } from "semantic-ui-react";
// Custom Components
import CommonCard from "components/Common/CommonCard";
import CustomDateTime from "components/Common/CustomDateTime";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import history from "utils/history";
import moment from "moment";
const MyNotes = () => {
  const [values, setValues] = useState({ reload: false });

  const token = Cookies.get("BACtoken");
  const [totalnote, setTotalNote] = React.useState(false);

  const [deadlineDateCom, setDeadlineDate] = useState(null);
  const [deadlineTimeCom, setDeadlineTime] = useState(null);
  const [priorityCom, setPriority] = useState(0);

  const [pay, setPay] = React.useState({
    admin_note: "",
    priority: "",
    deadline: "",
  });
  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };
  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const handleNote = async () => {
    setEdit({ ...edit, loader: true });
    const deadline = `${deadlineDateCom}T${deadlineTimeCom}`;

    try {
      const response = await Axios.post(
        `/api/accountclient/write-my-note`,
        {
          admin_note: pay.admin_note,
          priority: priorityCom,
          deadline: deadline,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: "Note successfully added",
      });
      setPay({ ...pay, admin_note: "", loader: false });
      setEdit({ ...edit, loader: false });
      getTotalNote();
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const getTotalNote = async () => {
    try {
      const response = await Axios.get(`/api/accountclient/count-my-note`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTotalNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalNote();
  }, []);

  const handleVeiwNote = async () => {
    history.push(`/account-client/my-notes`);
  };

  return (
    <CommonCard title="My Notes / To Do List">
      <>
        <TextField
          onChange={handleChange}
          value={pay.admin_note}
          id="standard-textarea"
          label="Type here... (max 432 ch.)"
          placeholder="Write here your own confidential note or to do list..."
          multiline
          rows={4}
          inputProps={{ maxLength: 432 }}
          name="admin_note"
          required
          style={{ marginBottom: 15, width: 100 + "%" }}
        />

        <CustomAutoSelect
          required
          label="Priority"
          width="100%"
          data={["Note", "Low", "Medium", "High"]}
          onChange={(e, value) => setPriority(value)}
        />
        {priorityCom !== "Note" && (
          // <CustomDateTime
          //   label="Deadline"
          //   width="100%"
          //   onChange={(e) => setDeadline(e.target.value)}
          // />
          <div style={{ margin: 10 }}>
            <Header as="h5">Deadline </Header>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                style={{
                  width: "90%",
                  height: "2.8rem",
                  fontSize: "1rem",
                }}
                min={moment().format("YYYY-MM-DD")}
                type="date"
                onChange={(e) => setDeadlineDate(e.target.value)}
              />

              <input
                style={{
                  width: "90%",
                  height: "2.8rem",
                  fontSize: "1rem",
                }}
                type="time"
                onChange={(e) => setDeadlineTime(e.target.value)}
              />
            </div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
            marginBottom: 20,
          }}
        >
          <Button color="purple" onClick={handleVeiwNote}>
            View Note ({totalnote})
          </Button>
          <Button
            disabled={!pay.admin_note || !priorityCom}
            onClick={handleNote}
            color="purple"
            variant="outlined"
            startIcon={<SaveIcon />}
          >
            Save Note
            {edit.loader && <div class="ui active inline loader"></div>}
          </Button>
        </div>
      </>
    </CommonCard>
  );
};

export default MyNotes;
