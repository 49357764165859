import { useHistory } from "react-router-dom";

// Materials Core
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { useEffect, useState } from "react";
import { Button, useMediaQuery } from "@material-ui/core";



const styles = {
    root: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        "& .MuiButton-root": {
            marginRight: "10px",
            marginBottom: "5px",
            color: "#fff",
            borderColor: "#fff",
            "&:last-child": {
                marginRight: "0px",
            },
            "@media (max-width: 480px)": {
                marginRight: "0px",
                width: "100%",
                marginBottom: "10px",
            },
        },
    },
};

const useStyles = makeStyles(styles);

const QuoteOptions = () => {
    const classes = useStyles();
    const history = useHistory();
    const matches = useMediaQuery("(max-width:768px)");
    return (
        <div className={classes.root}>
            {!matches ? (
                <div>
                    <Button
                        style={{ marginRight: "10px" }}
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            history.push("/sppo/provide-direct-quote")
                        }
                    >
                        Provide Direct Quote{" "}
                    </Button>
                    <Button
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                            history.push("/sppo/saved-direct-quote-list")
                        }
                        variant="contained"
                        color="primary"
                    >
                        {" "}
                        Saved Quotes
                    </Button>
                    <Button
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                            history.push("/sppo/sent-direct-quote-list")
                        }
                        variant="contained"
                        color="primary"
                    >
                        {" "}
                        Sent Quote
                    </Button>
                    <Button
                        onClick={() =>
                            history.push("/sppo/accepted-direct-quote-list")
                        }
                        variant="contained"
                        color="primary"
                    >
                        Accepted Quote{" "}
                    </Button>
                </div>
            ) : (
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto auto",
                        marginBottom: 15,
                    }}
                >
                    <Button
                        style={{ marginRight: "10px" }}
                        variant="contained"
                        color="primary"
                    >
                        Provide Direct Quote{" "}
                    </Button>
                    <Button
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                            history.push("/sppo/saved-direct-quote-list")
                        }
                        variant="contained"
                        color="primary"
                    >
                        {" "}
                        Saved Quotes
                    </Button>
                    <Button
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                            history.push("/sppo/sent-direct-quote-list")
                        }
                        variant="contained"
                        color="primary"
                    >
                        {" "}
                        Sent Quote
                    </Button>
                    <Button
                        onClick={() =>
                            history.push("/sppo/accepted-direct-quote-list")
                        }
                        variant="contained"
                        color="primary"
                    >
                        Accepted Quote{" "}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default QuoteOptions;
