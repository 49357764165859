import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetch from "isomorphic-fetch";

import Switch from "@material-ui/core/Switch";
import swal from "sweetalert";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import { Table } from "semantic-ui-react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import CardHeader from "components/Card/CardHeader";
import Moment from "moment";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

// Others
import { Card } from "@material-ui/core";
//import CustomLabel from "components/Common/CustomLabel";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";

const ViewContactRequest = () => {
  const token = Cookies.get("token");
  const [UVData, setUVData] = useState(null);
  const [state, setState] = React.useState({
    reload: false,
  });

  const history = useHistory();
  const classes = useDashboardStyles();
  const { id } = useParams();

  const [values, setValues] = useState({
    // title: "mst",
    // FirstName: "kona",
  });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/view/admins/${id}`
    )
      .then((res) => res.json())
      .then((result) => {
        setValues(result);
        setUVData(result);
      });
  }, [state]);

  const handleAccessManager = async (value) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/accessManager/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: "Executive Manager added successfully ",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableManagerAccess = async (value) => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/disableManager/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        text: "Disabled Access For Executive Manager",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessHrManager = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessHrAdmin/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "HR Manager added successfully ",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableHrManagerAccess = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableHrAdmin/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled Access For Executive Manager",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessSalesManager = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessSalesAndMarketing/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Sales and marketing manager added successfully ",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableSalesManagerAccess = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableSalesAndMarketing/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled Access For Sales and marketing manager",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessFinanceManager = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessFinanceManager/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Finance manager added successfully ",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableFinanceManager = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableFinanceManager/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled Access For Finance manager",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessCrmAdmin = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessCrmAdmin/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Client service manager and booking secretary added successfully ",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableCrmAdmin = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableCrmAdmin/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled Access For CRM service manager and booking secretary",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessHeadOfficeManager = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessHeadOfficeManager/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Head of admin and office manager added successfully ",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableHeadOfficeManager = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableHeadOfficeManager/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled Access For Head of admin and office manager",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessAssistantManager = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessAssistantManager/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Assistant manager added successfully ",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableAssistantManager = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableAssistantManager/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled Access For Assistant manager",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessEmployeeSupervisor = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessEmployeeSupervisor/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Employee supervisor added successfully ",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableEmployeeSupervisor = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableEmployeeSupervisor/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled Access For Employee supervisor",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessOfficeAssistant = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessOfficeAssistant/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Office assistant added successfully ",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableOfficeAssistant = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableOfficeAssistant/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled Access For Office assistant",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessTrainee = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessTrainee/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Trainee added successfully ",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableTrainee = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableTrainee/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disabled Access For Trainee",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessBookingPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessBookingPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Access booking secretary panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableBookingPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableBookingPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disable booking secretary panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessTaskPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessTaskPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Access task panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableTaskPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableTaskPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disable Task panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessHrPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessHrPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Access HR panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableHrPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableHrPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disable HR panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessCrmPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessCrmPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Access CRM panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableCrmPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableCrmPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disable CRM panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessFinancePanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessFinancePanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Access Finance panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableFinancePanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableFinancePanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disable Finance panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessControlPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessControlPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Access Control panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableControlPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableControlPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disable Control panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessManagementPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessManagementPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Access Management panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableManagementPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableManagementPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disable Management panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleAccessContactPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/accessContactPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Access Contact panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDisableContactPanel = async (value) => {
    try {
      await Axios.put(
        `/api/manager-admin/disableContactPanel/adminID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: "Disable Contact panel",
        buttons: false,
      });
      setState({ ...state, reload: !state.reload });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleToggle = async (value) => {
    if (!UVData.manager_role) {
      swal(
        "Are you sure you want to access this admin for executive manager ?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessManager(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal(
        "Are you sure you want to disable access for this executive manager?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableManagerAccess(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleHrRole = async (value) => {
    if (!UVData.hr_role) {
      swal("Are you sure you want to access this admin for HR Manager ?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessHrManager(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable access for this HR Manager?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableHrManagerAccess(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleSalesAndMarketingRole = async (value) => {
    if (!UVData.sales_and_marketing_role) {
      swal(
        "Are you sure you want to access this admin for sales and marketing Manager ?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessSalesManager(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal(
        "Are you sure you want to disable access for this sales and marketing Manager?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableSalesManagerAccess(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleFinanceRole = async (value) => {
    if (!UVData.finance_role) {
      swal("Are you sure you want to access this admin for Finance Manager ?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessFinanceManager(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal(
        "Are you sure you want to disable access for this Finance Manager?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableFinanceManager(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleCrmAdmin = async (value) => {
    if (!UVData.crm_role) {
      swal(
        "Are you sure you want to access this admin for CRM service manager and booking secretary ?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessCrmAdmin(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal(
        "Are you sure you want to disable access for this CRM service manager and booking secretary?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableCrmAdmin(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleHeadAdmin = async (value) => {
    if (!UVData.head_and_office_role) {
      swal(
        "Are you sure you want to access this admin for Head of office admin ?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessHeadOfficeManager(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal(
        "Are you sure you want to disable access for this Head of office admin?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableHeadOfficeManager(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleAssistantManager = async (value) => {
    if (!UVData.assistant_manager_role) {
      swal(
        "Are you sure you want to access this admin for Assistant manager?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessAssistantManager(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal(
        "Are you sure you want to disable access for this Assistant manager?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableAssistantManager(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleEmployeeSupervisor = async (value) => {
    if (!UVData.employee_supervisor_role) {
      swal(
        "Are you sure you want to access this admin for Employee supervisor?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessEmployeeSupervisor(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal(
        "Are you sure you want to disable access for this Employee supervisor?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableEmployeeSupervisor(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleOfficeAssistant = async (value) => {
    if (!UVData.office_assistant_role) {
      swal("Are you sure you want to access this admin for Office assistant?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessOfficeAssistant(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal(
        "Are you sure you want to disable access for this Office assistant?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableOfficeAssistant(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleTrainee = async (value) => {
    if (!UVData.trainee_role) {
      swal("Are you sure you want to access this admin for Trainee?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessTrainee(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable access for this Trainee?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableTrainee(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleBookingPanel = async (value) => {
    if (!UVData.booking_panel) {
      swal(
        "Are you sure you want to access this admin for booking secretary?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessBookingPanel(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable access for booking secretary?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableBookingPanel(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleTaskPanel = async (value) => {
    if (!UVData.task_panel) {
      swal("Are you sure you want to access this admin for task panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessTaskPanel(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable access for task panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableTaskPanel(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleHrPanel = async (value) => {
    const token = Cookies.get("token");
    if (!UVData.hr_panel) {
      swal("Are you sure you want to access this admin for hr panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessHrPanel(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable access for hr panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableHrPanel(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleCrmPanel = async (value) => {
    if (!UVData.crm_panel) {
      swal("Are you sure you want to access this admin for crm panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessCrmPanel(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable access for crm panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableCrmPanel(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleFinancePanel = async (value) => {
    if (!UVData.finance_panel) {
      swal(
        "Are you sure you want to access this admin for account and finance panel?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessFinancePanel(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal(
        "Are you sure you want to disable access for account and finance panel?",
        {
          dangerMode: true,
          buttons: {
            cancel: true,
            catch: {
              text: "Yes!",
              value: "catch",
            },
          },
        }
      ).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableFinancePanel(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleControlPanel = async (value) => {
    if (!UVData.control_panel) {
      swal("Are you sure you want to access this admin for control panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessControlPanel(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable access for control panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableControlPanel(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleManagementPanel = async (value) => {
    if (!UVData.management_panel) {
      swal("Are you sure you want to access this admin for management panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessManagementPanel(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable access for management panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableManagementPanel(value);
            break;
          default:
            break;
        }
      });
    }
  };

  const handleContactPanel = async (value) => {
    if (!UVData.contact_panel) {
      swal("Are you sure you want to access this admin for contact panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleAccessContactPanel(value);
            break;
          default:
            break;
        }
      });
    } else {
      swal("Are you sure you want to disable access for contact panel?", {
        dangerMode: true,
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }).then((type) => {
        switch (type) {
          case "cancel":
            break;

          case "catch":
            handleDisableContactPanel(value);
            break;
          default:
            break;
        }
      });
    }
  };

  return (
    <Manager>
      <MasterLayout>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>
              Admins - {values.id} - {values.FirstName}
            </p>
            <i
              className="icon list arrow left"
              onClick={() => {
                history.goBack();
              }}
            ></i>
          </CardHeader>
        </GridItem>
        <br />
        <React.Fragment>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody alignItems="center">
                    <h4 style={{ textAlign: "center" }}>
                      User ID: {values.id} - {values.title} {values.FirstName}{" "}
                      {values.LastName}
                    </h4>
                    <h4 style={{ textAlign: "center" }}>
                      Access permitted:
                      {values.admin_role}
                    </h4>
                  </CardBody>
                </Card>
                <br />
                <Card>
                  <CardBody>
                    <GridContainer alignItems="center">
                      <GridItem xs={6} sm={6} md={6}>
                        <div className="left">
                          <img
                            src={`${process.env.REACT_APP_BACKEND_API}/manager-admin/photo-admins/${id}`}
                            className="img img-fluid img-thumbnail mb-3"
                            style={{ maxHeight: "auto", maxWidth: "50%" }}
                            alt="photo"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={6}>
                        <div className="right">
                          <h4>
                            Name: {values.title} {values.FirstName}{" "}
                            {values.LastName}
                          </h4>
                          <h4>Work Email: {values.email}</h4>
                          <h4>Mobile Number: {values.mobile_no}</h4>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
                <br />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card raised style={{ marginTop: "24px" }}>
                  <CardBody>
                    <h4>
                      Joining Date:{" "}
                      {Moment(values.createdAt).format(
                        "DD MMM YYYY - kk:mm:ss"
                      )}
                    </h4>

                    <p>Personal Email: {values.personal_email} </p>
                    <p>Date Of Birth: {values.Birthday} </p>
                    <p>Gender: {values.Sex} </p>
                    <p>Telephone Number: {values.telephone_no} </p>
                    <p>House number / Stree name: {values.AddressLine1} </p>
                    <p>Address: {values.AddressLine2} </p>
                    <p>City: {values.City} </p>
                    <p>Postal Code: {values.PostalCode} </p>
                    <p>Country: {values.Country} </p>
                    <p>Region: {values.Region} </p>
                    <p>Nationality: {values.Nationality} </p>
                    <p>Other Nationality: {values.other_nationality} </p>
                    <p>About: {values.about} </p>
                    <p>Additional Information: {values.additional_info} </p>
                  </CardBody>
                </Card>
                <br />
              </GridItem>
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody>
                    <GridContainer alignItems="center">
                      <Table color={"pink"}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Set Position</Table.HeaderCell>
                            <Table.HeaderCell>Title</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              Executive Director / Manager
                            </Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() => handleToggle("manager_role")}
                                checked={UVData ? UVData.manager_role : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>HR Manager</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() => handleHrRole("hr_role")}
                                checked={UVData ? UVData.hr_role : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Sales & Marketing Manager</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleSalesAndMarketingRole(
                                    "sales_and_marketing_role"
                                  )
                                }
                                checked={
                                  UVData ? UVData.sales_and_marketing_role : 0
                                }
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Finance Manager</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleFinanceRole("finance_role")
                                }
                                checked={UVData ? UVData.finance_role : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>
                              Client Service Manger & Booking Secretary
                              Supervisor (CRM)
                            </Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() => handleCrmAdmin("crm_role")}
                                checked={UVData ? UVData.crm_role : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Assistant Manager</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleAssistantManager(
                                    "assistant_manager_role"
                                  )
                                }
                                checked={
                                  UVData ? UVData.assistant_manager_role : 0
                                }
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Head of Admin and Office</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleHeadAdmin("head_and_office_role")
                                }
                                checked={
                                  UVData ? UVData.head_and_office_role : 0
                                }
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Employee Supervisor</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleEmployeeSupervisor(
                                    "employee_supervisor_role"
                                  )
                                }
                                checked={
                                  UVData ? UVData.employee_supervisor_role : 0
                                }
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Office Assistant</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleOfficeAssistant("office_assistant_role")
                                }
                                checked={
                                  UVData ? UVData.office_assistant_role : 0
                                }
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Trainee / Apprentice</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() => handleTrainee("trainee_role")}
                                checked={UVData ? UVData.trainee_role : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </GridContainer>
                  </CardBody>
                </Card>
                <br />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody>
                    <GridContainer alignItems="center">
                      <Table color={"pink"}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Admin Access</Table.HeaderCell>
                            <Table.HeaderCell>Title</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>Booking Secretary Panel</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleBookingPanel("booking_panel")
                                }
                                checked={UVData ? UVData.booking_panel : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Task List</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() => handleTaskPanel("task_panel")}
                                checked={UVData ? UVData.task_panel : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>HR Panel</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() => handleHrPanel("hr_panel")}
                                checked={UVData ? UVData.hr_panel : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>CRM Panel</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() => handleCrmPanel("crm_panel")}
                                checked={UVData ? UVData.crm_panel : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Account and Finance Panel</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleFinancePanel("finance_panel")
                                }
                                checked={UVData ? UVData.finance_panel : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Control Panle</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleControlPanel("control_panel")
                                }
                                checked={UVData ? UVData.control_panel : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Management Panel</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleManagementPanel("management_panel")
                                }
                                checked={UVData ? UVData.management_panel : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Contact Panel</Table.Cell>
                            <Table.Cell>
                              <Switch
                                edge="start"
                                onChange={() =>
                                  handleContactPanel("contact_panel")
                                }
                                checked={UVData ? UVData.contact_panel : 0}
                                //inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                              />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </GridContainer>
                  </CardBody>
                </Card>
                <br />
                <br />
              </GridItem>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      </MasterLayout>
    </Manager>
  );
};

export default ViewContactRequest;
