import React, { useState, useEffect } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import Cookies from "js-cookie";
const TranslationAcceptedJobChart = ({ id }) => {
  const token = Cookies.get("token");
  const renderInterpretingAcceptedChart = async () => {
    try {
      const sdk = new ChartsEmbedSDK({
        baseUrl: "https://charts.mongodb.com/charts-lc-project-rdlgu", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
        height: 500,
        getUserToken: function () {
          return token;
        },
      });
      const chart = sdk.createChart({
        //need to be changed ALI
        chartId: "278e8d54-711c-4812-b3ac-9475266ed05a",
      }); // ~REPLACE~ with the Chart ID from your Embed Chart dialog

      chart.render(document.getElementById("chart6"));
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    renderInterpretingAcceptedChart();
  });
  return <div id="chart6"></div>;
};

export default TranslationAcceptedJobChart;
