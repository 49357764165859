import FormField from "./FormField";

const BillingDetailsFields = () => {
  return (
    <>
      <FormField
        name="name"
        label="First Name(s)"
        type="text"
        placeholder=""
        required
      />
      <FormField
        name="last_name"
        label="Last Name"
        type="text"
        placeholder=""
        required
      />
      <FormField
        name="email"
        label="Email"
        type="email"
        placeholder=""
        required
      />
      <FormField
        name="address"
        label="House No/Name, Street Name"
        type="text"
        placeholder=""
        required
      />
      <FormField
        name="line2"
        label="Address line 2"
        type="text"
        placeholder=""
        
      />
      <FormField
        name="city"
        label="Town/City"
        type="text"
        placeholder=""
        required
      />
      <FormField
        name="state"
        label="State/Region/County"
        type="text"
        placeholder=""
        required
      />
      <FormField
        name="zip"
        label="Post/Zip"
        type="text"
        placeholder=""
        required
      />
      {/* <FormField
        name="country"
        label="Country"
        type="text"
        placeholder=""
        required
      /> */}
      <FormField
        name="mobile_no"
        label="Mobile number"
        type="tel"
        placeholder=""
        required
      />
      <FormField
        name="dob"
        label="Date of birth"
        type="date"
        placeholder=""
        
      />
    </>
  );
};

export default BillingDetailsFields;