import { makeStyles } from "@material-ui/core/styles";

import { drawerWidth, whiteColor, secondaryBoxShadow } from "../variables";

const styles = (theme) => ({
  root: {
    display: "flex",
    padding: "15px",
    flexGrow: 1,
    flexDirection: "column",
  },

  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    ...secondaryBoxShadow,
    background: `${whiteColor} !important`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  footer:{
    "@media print": {
      display: "none !important",
    },
  }
});

const useStyles = makeStyles(styles);

export default useStyles;
