import React, { useState } from "react";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import { useDispatch, useSelector } from "react-redux";
import { changeSPPORInput, updateSPPORStep } from "redux/actions/spporAction";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import Axios from "axios";
import swal from "sweetalert";
import history from "utils/history";
import Cookies from "js-cookie";

const PageThree = () => {
  const classes = useFormStyles();
  const { data } = useSelector((state) => state.spporReducer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const token = Cookies.get("SPPOtoken");
  const {
    business_logo,
    business_name,
    legal_business_name,
    business_phone_no,
    business_address_line1,
    business_address_line2,
    business_city,
    business_postal_code,
    business_region,
    business_country,
    business_website,
    company_registration_no,
    charity_registration_no,
    vat_number,
    vat_country,

    primary_email,
    address_line1,
    address_line2,
    city,
    postal_code,
    region,
    country,
    nationality,
    photo,
    document,

    bank_account_fullname,
    bank_sort_code,
    bank_account_number,
    bank_branch_name,
    bank_iban,
    bank_swift_code,
    bank_name,
    bank_branch_address,
    currency,
    bank_additional_info,
    aba,
    branch_code,
    institution_number,
    ifsc_code,
    gdpr,
  } = data;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeSPPORInput({
        name,
        value: value,
      })
    );
  };


  const handleBack = () => {
    dispatch(updateSPPORStep(2));
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    let formData = new FormData();
    formData.append("business_name", business_name || "-");
    formData.append("legal_business_name", legal_business_name || "-");
    formData.append("business_phone_no", business_phone_no || "-");
    formData.append("business_address_line1", business_address_line1 || "-");
    formData.append("business_address_line2", business_address_line2 || "-");
    formData.append("business_city", business_city || "-");
    formData.append("business_postal_code", business_postal_code || "-");
    formData.append("business_region", business_region || "-");
    formData.append("business_country", business_country || "-");
    formData.append("business_website", business_website || "-");
    formData.append("company_registration_no", company_registration_no || "-");
    formData.append("charity_registration_no", charity_registration_no || "-");
    formData.append("vat_number", vat_number || "-");
    formData.append("vat_country", vat_country || "-");
    formData.append("primary_email", primary_email || "-");
    formData.append("address_line1", address_line1 || "-");
    formData.append("address_line2", address_line2 || "-");
    formData.append("city", city || "-");
    formData.append("postal_code", postal_code || "-");
    formData.append("region", region || "-");
    formData.append("country", country || "-");
    formData.append("nationality", nationality || "-");

    formData.append("bank_account_fullname", bank_account_fullname || "-");
    formData.append("bank_sort_code", bank_sort_code || "-");
    formData.append("bank_account_number", bank_account_number || "-");
    formData.append("bank_branch_name", bank_branch_name || "-");
    formData.append("bank_iban", bank_iban || "-");
    formData.append("bank_swift_code", bank_swift_code || " ");
    formData.append("bank_name", bank_name || " ");
    formData.append("bank_branch_address", bank_branch_address || " ");
    formData.append("currency", currency || " ");
    formData.append("bank_additional_info", bank_additional_info || " ");
    formData.append("aba", aba || " ");
    formData.append("branch_code", branch_code || " ");
    formData.append("institution_number", institution_number || " ");
    formData.append("ifsc_code", ifsc_code || " ");

    if (photo?.size) {
      formData.append("photo", photo);
    }

    if (business_logo?.size) {
      formData.append("business_logo", business_logo);
    }

    if (document?.size) {
      formData.append("document", document);
    }

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/profile/update`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });
      history.push("/sppo/profile");
      setLoading(false);
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error ? error?.response?.data?.error : "Check your internet connection please."}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const isAllow =
    !business_name ||
    !legal_business_name ||
    !business_phone_no ||
    !business_address_line1 ||
    !business_city ||
    !business_postal_code ||
    !business_country ||
    !primary_email ||
    !address_line1 ||
    !city ||
    !postal_code ||
    !country;

  return (
    <div>
      <h3 style={{ textAlign: "center", paddingTop: "5px" }}>
        Bank / Payment Processing Details
      </h3>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomBigInputSingle
          onChange={handleChange}
          width="100%"
          label="Bank Account Holder's Full Name"
          moreInfo="Bank details will be shared with payment processor"
          name="bank_account_fullname"
          value={bank_account_fullname}
          placeholde="Bank Account Holder's Full Name"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="Account Number"
          moreInfo="Please provide relevant details as used in your country of residence and for international payment number"
          width="100%"
          name="bank_account_number"
          value={bank_account_number}
          placeholde="Bank Account Number"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Branch Name"
          width="100%"
          name="bank_branch_name"
          value={bank_branch_name}
          placeholde="Branch Name"
        />
        {country?.includes("United States") ? (
          <CustomInputSingle
            onChange={handleChange}
            label="ABA"
            width="100%"
            name="aba"
            value={aba}
            placeholde="ABA"
          />
        ) : null}
        {country?.includes("United Kingdom") ? (
          <CustomInputSingle
            onChange={handleChange}
            label="Sort Code"
            width="1003%"
            name="bank_sort_code"
            value={bank_sort_code}
            placeholde="Bank Sort Code"
          />
        ) : null}
        {country?.includes("Canada") ? (
          <React.Fragment>
            <CustomInputSingle
              onChange={handleChange}
              label="Branch Code"
              width="100%"
              name="branch_code"
              value={branch_code}
              placeholder="Branch Code"
            />
            <CustomInputSingle
              onChange={handleChange}
              label="Institution Number"
              width="100%"
              name="institution_number"
              value={institution_number}
              placeholde="Institution Number"
            />
          </React.Fragment>
        ) : null}
        <CustomInputSingle
          onChange={handleChange}
          label="IBAN"
          width="100%"
          name="bank_iban"
          value={bank_iban}
          placeholde="International Bank Account Number"
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Swift / BIC Code"
          name="bank_swift_code"
          value={bank_swift_code}
          placeholde="Swift / BIC code"
        />
        {country !== "United States" &&
          country !== "United Kingdom" &&
          country !== "Canada" ? (
          <CustomInputSingle
            onChange={handleChange}
            label="IFSC Code"
            width="100%"
            name="ifsc_code"
            value={ifsc_code}
            placeholder="IFSC Code"
          />
        ) : null}
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Name Of The Bank"
          name="bank_name"
          value={bank_name}
          placeholder="Name Of The Bank"
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Address Of Bank Branch"
          name="bank_branch_address"
          value={bank_branch_address}
          placeholder="Address Of Bank Branch"
        />
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Additional Information"
          name="bank_additional_info"
          value={bank_additional_info}
          placeholder="Additional Information"
        />
        <CustomBigInputSingle
          onChange={handleChange}
          width="100%"
          label="Preferred currency"
          moreInfo="GBP, EURO, Chinese Yuan / Renminbi"
          name="currency"
          value={currency}
          placeholder="GBP, EURO, Chinese Yuan / Renminbi"
        />

        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton
            disabled={isAllow || loading}
            text="Submit"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default PageThree;
