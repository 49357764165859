import React from "react";

// Material Components
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

// Custom Components
import CustomLabel from "./CustomLabel";
import CustomFormControl from "./CustomFormControl";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

const CustomRadio = ({
  label,
  moreInfo,
  width,
  data,
  flexDir,
  required,
  ...rest
}) => {
  const classes = useFormStyles({
    flexDir,
  });

  return (
    <CustomFormControl
      component="fieldset"
      width={width}
      className={classes.rLabel}
      style={{
        marginTop: "2rem",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <FormLabel component="legend">
        <CustomLabel label={label} moreInfo={moreInfo} required={required} />
      </FormLabel>
      <RadioGroup
        aria-label={label.toLowerCase()}
        name={label.toLowerCase()}
        {...rest}
        className={classes.radioGroup}
      >
        {data.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item}
            control={<Radio />}
            label={item}
            color="primary"
          />
        ))}
      </RadioGroup>
    </CustomFormControl>
  );
};

export default CustomRadio;
