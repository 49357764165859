import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import { Dimmer, Header, Loader } from "semantic-ui-react";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Invoice from "components/InvoiceLinguist/Invoice";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history"
import CardHeader from "components/Card/CardHeader";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Cookies from 'js-cookie'
import Axios from "utils/axios";

const CreateInvoiceLinguist = () => {

  const classes = useDashboardStyles();
  const [finance_panel, setAdminAccess] = useState(null)

  const getAdminDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminAccess(response?.data.finance_panel);
  };
  useEffect(() => {
    getAdminDetails();
  }, []);

  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          {finance_panel ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                  <p className={classes.cardTitleWhite}>Invoice to Linguist</p>
                  <IconButton color='inherit' style={{ marginLeft: 5 }} onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
                </CardHeader>
                <CardBody>
                  <Invoice />
                </CardBody>
              </Card>
            </GridItem>
            :
            finance_panel === 0 ?
              <Header as='h3'>Access denied</Header>
              :
              <Dimmer active inverted>
                <Loader size='large'>Loading</Loader>
              </Dimmer>
          }
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default CreateInvoiceLinguist;
