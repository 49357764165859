import React, { useState } from 'react'
import TableOne from './Assignments/TableOne'
import TableTwo from './Assignments/TableTwo'
import TableThree from './Assignments/TableThree'
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Header } from 'semantic-ui-react';

const AssignmentHistory = () => {
  const classes = useDashboardStyles();
  const [privateAccess, setPrivateAccess] = useState(null)

  return (

    <div className={classes.listDiv}>
      <Header as='h4'>CAC, BAC & INC interpreting history</Header>
      <TableOne />
      <Header as='h4'>CAC, BAC & INC translation history</Header>
      <TableTwo />
      <Header as='h4'>CAC & BAC quote history</Header>
      <TableThree />
    </div>

  )
}

export default AssignmentHistory
