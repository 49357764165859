import EmployeeRegistrationPage from "pages/EmployeeRegistration";
import CustomerRegistrationPage from "pages/CustomerRegistration";
//add new customer
import ContactRegistrationPage from "pages/landing-page/Lead/ContactRegistration";
import LeadRegistration from "pages/landing-page/Lead/LeadRegistration";
// import RegistrationPage from "pages/account-client/Registration";
import InitialRegistration from "pages/linguist/InitialRegistration";
import LoginPage from "pages/admin/LoginPage";
import EmailActivationCAC from "pages/cash-client/EmailActivationCAC";
import BACClientEmailActivation from "pages/account-client/BACClientEmailActivation";


import SupremeRegistration from "pages/Supreme/Registration/Registration";
import SupremeLogin from "pages/Supreme/Login/Login"


const REGISTRATION = [
  {
    id: 48,
    path: "mamun/executive/director/manager/supreme/first/time/lcproject2@2@/registration",
    type: "form",
    component: SupremeRegistration,
  },
  {
    id: 49,
    path: "mamun/executive/director/manager/supreme/first/time/lcproject2@2@/login",
    type: "form",
    component: SupremeLogin,
  },
  {
    id: 10,
    text: "Employee Registration",
    path: "employee-registration",
    type: "form",
    component: EmployeeRegistrationPage,
  },
  {
    id: 30,
    path: "linguist/registration",
    component: InitialRegistration,
  },
  {
    id: 20,
    text: "Cash Client Registration",
    path: "customer-registration",
    type: "form",
    component: CustomerRegistrationPage,
  },
  {
    id: 21,
    text: "Customer Registration",
    path: "add-new-customer",
    type: "form",
    component: ContactRegistrationPage
  },
  {
    id: 211,
    text: "Customer Registration",
    path: "add-new-customer/:id",
    type: "form",
    component: ContactRegistrationPage
  },
  {
    id: 211,
    text: "Customer Registration",
    path: "add-new-lead/:id",
    type: "form",
    component: LeadRegistration
  },
  {
    id: 211,
    text: "Customer Registration",
    path: "add-new-lead",
    type: "form",
    component: LeadRegistration
  },
  {
    id: 212,
    text: "Customer Registration",
    path: "add-new-customer/:id/:sppocontact",
    type: "form",
    component: ContactRegistrationPage
  },
  {
    id: 68,
    path: "cashclient/auth/account/activate/:token",
    component: EmailActivationCAC,
  },

  
  {
    id: 70,
    path: "bacuser/auth/account/activate/:token",
    component: BACClientEmailActivation,
  },

  {
    id:40,
    path: "admin/login",
    type: "login",
    subText: "Admin Login",
    component: LoginPage,
  },
];

export default REGISTRATION;
