import React, { Fragment } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import {
  Card,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import ThreeCards from "./ThreeCards";
import BFimage from "assets/images/6.jpg";
import TOCimage from "assets/icons/service-providing-partnership.png";
import FOSPimage from "assets/icons/service-providing.png";

const SP = [
  "Teachers",
  "Childminders",
  "Doctor and Nurses",
  "Tour and Travel guides",
  "Personal assistants",
  "Lawyers or Solicitors",
  "Accountant and Tax Advisors",
  "Chefs",
  "Drivers",
  "And many more.",
];

const Customers = [
  "Care workers",
  "Childminders",
  "Doctors and Nurses",
  "Tour guides",
  "Personal assistants",
  "Teachers",
  "Lawyers",
  "Paralegals",
  "Accountants",
  "Editors, proof-readers and Content Writers",
  "And many more.",
];

const BF = () => {
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 4rem 0 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div>
            <img src={BFimage} alt="" style={{ width: "100%" }} />
          </div>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={FOSPimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  For our Service Providers
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  If you are a multilingual, plurilingual or bilingual
                  professional and are passionate about speaking your mother
                  tongue, or speak a highly demandable language in a local area,
                  join us today to find customers that are looking for a
                  professional native speaker. You can help provide services
                  that customers are usually looking for in a native speaker.
                  These include, but are not limited to:
                </Typography>
                <List dense>
                  {SP.map((value) => (
                    <ListItem>
                      <ListItemText primary={value} />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  <Link to="/linguist/registration">Register</Link> with us to
                  provide your services.
                </Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={TOCimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  paragraph
                  variant="h5"
                >
                  To our Customers
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  You can hire a qualified, experienced, and expert multilingual
                  or bilingual professional using our webapp quotation system.
                  Bilinguals and professionals that you find here cover various
                  sectors and many professions, including, but not limited to:
                </Typography>
                <List dense>
                  {Customers.map((value) => (
                    <ListItem>
                      <ListItemText primary={value} />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Request a <Link to="/add-new-customer">Quote</Link> or{" "}
                  <Link to="/customer-registration">Register</Link> to book an
                  interpreter and order translation work.
                </Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <ThreeCards />
      </div>
      {/* </div> */}
    </LandingPageLayout>
  );
};

export default BF;
