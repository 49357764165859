import React, { useState, useEffect, useRef } from "react";

// Custom Components
// Custom Styles
import { Button, Header } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { changePreRegInput } from "redux/actions/preregAction";
import history from "utils/history";
import { Form, Segment, Modal } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import CustomSelect from "components/Common/CustomSelect";
// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import useFormStyles from "assets/jss/components/auth/formStyle";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "70vh",
    overflowY: "auto",
  },
});

const CommunicationMenu = ({ id, token, tickets }) => {
  const classes = useStyles();
  const classess = useFormStyles();

  const [conversationDetails, setConversation] = useState({ reload: false });
  const fileInput = useRef(null);
  const [attachment, setAttachment] = useState();
  const { data } = useSelector((state) => state.preregReducer);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const [cacDetails, setCACDetails] = useState();
  const [bacDetails, setBACDetails] = useState();
  const [linDetails, setLINDetails] = useState();
  const [sppoDetails, setSPPODetails] = useState();
  const [empDetails, setEMPDetails] = useState();
  const [admDetails, setADMDetails] = useState();

  const [converse, setConverse] = useState({
    conversation: "",
    invited_id: "",
    client: "",
  });
  // const { conversation, invited_id, client } = data;
  const { conversation, invited_id, client } = converse;

  const handleChange = (e) => {
    // const { name, value, checked } = e.target;
    setConverse({ ...converse, [e.target.name]: e.target.value });
    const inputData = {
      name: e.target.name,
      value: e.target.value,
    };
    dispatch(changePreRegInput(inputData));
  };

  const ConversationDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/conversation-list/ticketID/${id}`
      );

      setConversation(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindCACDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-CAC`,
        {
          invited_id: invited_id,
        }
      );

      setCACDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindBACDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-BAC`,
        {
          invited_id: invited_id,
        }
      );

      setBACDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindLINDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-LIN`,
        {
          invited_id: invited_id,
        }
      );

      setLINDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindSPPODetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-SPPO`,
        {
          invited_id: invited_id,
        }
      );

      setSPPODetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindEMPDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-EMP`,
        {
          invited_id: invited_id,
        }
      );

      setEMPDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const FindADMDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/find-ADM`,
        {
          invited_id: invited_id,
        }
      );

      setADMDetails(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    ConversationDetails();
    FindCACDetails();
    FindBACDetails();
    FindLINDetails();
    FindSPPODetails();
    FindEMPDetails();
    FindADMDetails();
  }, []);

  const handleAttachment = (e) => {
    setAttachment(e.target.files[0]);
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleSubmit = async (e) => {
    let fd = new FormData();

    fd.append("attachment", attachment);
    fd.append("conversation", conversation);

    if (attachment) {
      fd.append("attachTrue", true);
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/start-conversation-ADM/ticketID/${id}`,
        fd,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        title: "Success",
        text: `Sent!!!`,
        icon: "success",
        buttons: false,
      });
      ConversationDetails();
      setAttachment();
      setConverse({ ...converse, conversation: "" });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `Something wrong!`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const sendInvitation = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/send-invitation/ticketID/${id}`,
        {
          invited_id: invited_id,
        }
      );

      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/admin/view-tickets";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error.response?.data}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleSend = async (value) => {
    swal("Are you sure to send invitation?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          sendInvitation(value);

        default:
          break;
      }
    });
  };

  const closeTicket = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/ticket-system/close-ticket/ticketID/${id}`
      );

      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/admin/view-tickets";
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error.response?.data}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleClose = async (value) => {
    swal("Are you sure to close this ticket?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          closeTicket(value);

        default:
          break;
      }
    });
  };

  return (
    <>
      <List className={classes.messageArea}>
        <ListItem key="3">
          <Grid container>
            {tickets?.status ? (
              <Grid item xs={12}>
                <Button
                  color="purple"
                  icon="close"
                  floated="right"
                  content="Close this support ticket"
                  onClick={handleClose}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <ListItemText
                align="right"
                primary={tickets?.ticket_no}
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                primary={
                  tickets?.additional_ccEmail == "undefined"
                    ? ""
                    : tickets?.additional_ccEmail == "-"
                    ? ""
                    : tickets?.additional_ccEmail
                }
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                primary={
                  tickets?.additional_contact_number == "undefined"
                    ? ""
                    : tickets?.additional_contact_number == "-"
                    ? ""
                    : tickets?.additional_contact_number
                }
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                primary={tickets?.subject}
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                primary={tickets?.details}
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align="right"
                secondary={moment(tickets?.createdAt).format(
                  "DD/MMM/YYYY : HH:mm"
                )}
              ></ListItemText>
            </Grid>
            <Grid item xs={12}>
              <ListItemText style={{ color: "purple" }} align="right">
                <a href={`/admin/view-ticket-attachment/${tickets?._id}`}>
                  View Attach
                </a>
              </ListItemText>
            </Grid>
            {tickets?.status ? (
              <Grid item xs={12}>
                <Modal
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  trigger={
                    <Button
                      color="purple"
                      icon="add"
                      floated="right"
                      content="Add a respondent to the ticket"
                    />
                  }
                >
                  <Modal.Content scrolling>
                    <Modal.Description>
                      <form
                        className={` form ${classess.form}`}
                        noValidate
                        autoComplete="off"
                      >
                        <h4 style={{ textAlign: "center" }}>
                          Add a respondent to the ticket
                        </h4>
                        <CustomSelect
                          onChange={handleChange}
                          label="Select Respondent Category"
                          data={["CAC", "BAC", "LIN", "SPPO", "EMP", "MAN"]}
                          width="50%"
                          name="client"
                          value={client}
                          required
                        />

                        {client == "CAC" ? (
                          <>
                            <CustomInputSingle
                              onChange={handleChange}
                              value={invited_id}
                              name="invited_id"
                              label="Enter CAC Client Reference Number"
                              width="50%"
                              required
                              placeholder="CAC..."
                            />
                            <div className={classess.buttonFlex}>
                              <CustomButton
                                onClick={FindCACDetails}
                                disabled={!invited_id}
                                text="Find CAC"
                              />
                            </div>
                            <br />
                            <div className={classess.buttonFlex}>
                              {cacDetails ? (
                                <>
                                  CAC ID: {cacDetails?.id}
                                  <br /> <br />
                                  Full Name: {cacDetails?.title}{" "}
                                  {cacDetails?.FirstName} {cacDetails?.LastName}
                                  <br />
                                  Mobile No: {cacDetails?.mobile_no}
                                  <br />
                                  Post / Zip Code: {cacDetails?.PostalCode}
                                </>
                              ) : (
                                <p
                                  style={{ textAlign: "center", color: "red" }}
                                >
                                  No Data Found
                                </p>
                              )}
                            </div>
                          </>
                        ) : null}

                        {client == "BAC" ? (
                          <>
                            <CustomInputSingle
                              onChange={handleChange}
                              value={invited_id}
                              name="invited_id"
                              label="Enter BAC Client Reference Number"
                              width="50%"
                              required
                              placeholder="BAC..."
                            />
                            <div className={classess.buttonFlex}>
                              <CustomButton
                                onClick={FindBACDetails}
                                disabled={!invited_id}
                                text="Find BAC"
                              />
                            </div>
                            <br />
                            <div className={classess.buttonFlex}>
                              {bacDetails ? (
                                <>
                                  BAC ID: {bacDetails?.id}
                                  <br /> <br />
                                  Full Name: {bacDetails?.title}{" "}
                                  {bacDetails?.FirstName} {bacDetails?.LastName}
                                  <br />
                                  Mobile No: {bacDetails?.mobile_no}
                                  <br />
                                  Post / Zip Code: {bacDetails?.PostalCode}
                                </>
                              ) : (
                                <p
                                  style={{ textAlign: "center", color: "red" }}
                                >
                                  No Data Found
                                </p>
                              )}
                            </div>
                          </>
                        ) : null}

                        {client == "LIN" ? (
                          <>
                            <CustomInputSingle
                              onChange={handleChange}
                              value={invited_id}
                              name="invited_id"
                              label="Enter LIN Client Reference Number"
                              width="50%"
                              required
                              placeholder="LIN/PRO..."
                            />
                            <div className={classess.buttonFlex}>
                              <CustomButton
                                onClick={FindLINDetails}
                                disabled={!invited_id}
                                text="Find Linguist"
                              />
                            </div>
                            <br />
                            <div className={classess.buttonFlex}>
                              {linDetails ? (
                                <>
                                  LIN ID: {linDetails?.id}
                                  <br /> <br />
                                  Full Name: {linDetails?.title}{" "}
                                  {linDetails?.FirstName} {linDetails?.LastName}
                                  <br />
                                  Mobile No: {linDetails?.mobile_no}
                                  <br />
                                  Post / Zip Code: {linDetails?.PostalCode}
                                  <br /> <br />
                                  Source Language(s):{" "}
                                  {linDetails?.source_language} |{" "}
                                  {linDetails?.source_language_1} |{" "}
                                  {linDetails?.source_language_2}
                                  <br />
                                  Target Language(s):{" "}
                                  {linDetails?.target_language} |{" "}
                                  {linDetails?.target_language_1} |{" "}
                                  {linDetails?.target_language_2}
                                </>
                              ) : (
                                <p
                                  style={{ textAlign: "center", color: "red" }}
                                >
                                  No Data Found
                                </p>
                              )}
                            </div>
                          </>
                        ) : null}

                        {client == "SPPO" ? (
                          <>
                            <CustomInputSingle
                              onChange={handleChange}
                              value={invited_id}
                              name="invited_id"
                              label="Enter SPPO Client Reference Number"
                              width="50%"
                              required
                              placeholder="SPPO..."
                            />
                            <div className={classess.buttonFlex}>
                              <CustomButton
                                onClick={FindSPPODetails}
                                disabled={!invited_id}
                                text="Find SPPO"
                              />
                            </div>
                            <br />
                            <div className={classess.buttonFlex}>
                              {sppoDetails ? (
                                <>
                                  SPPO ID: {sppoDetails?.id}
                                  <br /> <br />
                                  Full Name: {sppoDetails?.title}{" "}
                                  {sppoDetails?.FirstName}{" "}
                                  {sppoDetails?.LastName}
                                  <br />
                                  Mobile No: {sppoDetails?.mobile_no}
                                  <br />
                                  Post / Zip Code: {sppoDetails?.PostalCode}
                                </>
                              ) : (
                                <p
                                  style={{ textAlign: "center", color: "red" }}
                                >
                                  No Data Found
                                </p>
                              )}
                            </div>
                          </>
                        ) : null}

                        {client == "MAN" ? (
                          <>
                            <CustomInputSingle
                              onChange={handleChange}
                              value={invited_id}
                              name="invited_id"
                              label="Enter MAN Client Reference Number"
                              width="50%"
                              required
                              placeholder="ADM..."
                            />
                            <div className={classess.buttonFlex}>
                              <CustomButton
                                onClick={FindADMDetails}
                                disabled={!invited_id}
                                text="Find Admin"
                              />
                            </div>
                            <br />
                            <div className={classess.buttonFlex}>
                              {admDetails ? (
                                <>
                                  Admin ID: {admDetails?.id}
                                  <br /> <br />
                                  Full Name: {admDetails?.title}{" "}
                                  {admDetails?.FirstName} {admDetails?.LastName}
                                  <br />
                                  Mobile No: {admDetails?.mobile_no}
                                  <br />
                                  Post / Zip Code: {admDetails?.PostalCode}
                                </>
                              ) : (
                                <p
                                  style={{ textAlign: "center", color: "red" }}
                                >
                                  No Data Found
                                </p>
                              )}
                            </div>
                          </>
                        ) : null}

                        {client == "EMP" ? (
                          <>
                            <CustomInputSingle
                              onChange={handleChange}
                              value={invited_id}
                              name="invited_id"
                              label="Enter EMP Client Reference Number"
                              width="50%"
                              required
                              placeholder="EMP..."
                            />
                            <div className={classess.buttonFlex}>
                              <CustomButton
                                onClick={FindEMPDetails}
                                disabled={!invited_id}
                                text="Find Employee"
                              />
                            </div>
                            <br />
                            <div className={classess.buttonFlex}>
                              {empDetails ? (
                                <>
                                  EMP ID: {empDetails?.id}
                                  <br /> <br />
                                  Full Name: {empDetails?.title}{" "}
                                  {empDetails?.FirstName} {empDetails?.LastName}
                                  <br />
                                  Mobile No: {empDetails?.mobile_no}
                                  <br />
                                  Post / Zip Code: {empDetails?.PostalCode}
                                </>
                              ) : (
                                <p
                                  style={{ textAlign: "center", color: "red" }}
                                >
                                  No Data Found
                                </p>
                              )}
                            </div>
                          </>
                        ) : null}

                        <div className={classess.buttonFlex}>
                          <CustomButton
                            onClick={handleSend}
                            disabled={!invited_id}
                            text="Confirm"
                          />
                        </div>
                      </form>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              {tickets?.invited_name_id
                ?.filter((dt) => dt != "")
                .map((st) => (
                  <ListItemText align="right" primary={st}></ListItemText>
                ))}
            </Grid>
          </Grid>
        </ListItem>
        <Divider />

        {conversationDetails.length > 0 ? (
          <>
            {conversationDetails?.map((list, i) => (
              <>
                <ListItem key="3">
                  <Grid container>
                    <Grid item xs={12}>
                      {list?.postedByIDName.includes("ADM") ? (
                        <>
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText
                              style={{ color: "purple" }}
                              align="right"
                            >
                              <a
                                href={`/admin/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}

                      {list?.postedByIDName.includes("EMP") ? (
                        <>
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText
                              style={{ color: "purple" }}
                              align="right"
                            >
                              <a
                                href={`/admin/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            align="right"
                            style={{ color: "purple" }}
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}

                      {list?.postedByIDName.includes("SPPO") ? (
                        <>
                          <ListItemText
                            align="center"
                            style={{ color: "green" }}
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            align="center"
                            style={{ color: "green" }}
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText
                              style={{ color: "green" }}
                              align="center"
                            >
                              <a
                                href={`/admin/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            align="center"
                            style={{ color: "green" }}
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}

                      {list?.postedByIDName.includes("CAC") ? (
                        <>
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText style={{ color: "red" }} align="left">
                              <a
                                href={`/admin/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}

                      {list?.postedByIDName.includes("BAC") ? (
                        <>
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText style={{ color: "red" }} align="left">
                              <a
                                href={`/admin/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            align="left"
                            style={{ color: "red" }}
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}

                      {list?.postedByIDName.includes("LIN") ? (
                        <>
                          <ListItemText
                            style={{ color: "blue" }}
                            align="center"
                            primary={list?.postedByIDName}
                          ></ListItemText>
                          <ListItemText
                            style={{ color: "blue" }}
                            align="center"
                            primary={list?.conversation}
                          ></ListItemText>
                          {list?.attachTrue ? (
                            <ListItemText
                              style={{ color: "blue" }}
                              align="center"
                            >
                              <a
                                href={`/admin/view-attachment-ticket-conversation/${list?._id}`}
                              >
                                View Attach
                              </a>
                            </ListItemText>
                          ) : null}
                          <ListItemText
                            style={{ color: "blue" }}
                            align="center"
                            primary={moment(list?.createdAt).format(
                              "DD/MMM/YYYY"
                            )}
                          ></ListItemText>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </>
            ))}
          </>
        ) : null}
      </List>
      <Divider />

      {tickets?.status ? (
        <Grid container style={{ padding: "20px" }}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                display: "flex",
              }}
            >
              <TextField
                onChange={handleChange}
                name="conversation"
                value={converse.conversation}
                id="outlined-basic-email"
                label="Reply to this ticket"
                style={{ marginRight: "5px", width: "85%" }}
              />

              <input
                name="identification_doc"
                onChange={handleAttachment}
                type="file"
                style={{ display: "none" }}
                accept=".doc,.pdf,.jpg,.png,.gif,.zip,.rar,.txt,.log,.xml"
                ref={fileInput}
              ></input>
              <div>
                <Fab
                  style={{ marginRight: "10px" }}
                  color="secondary"
                  onClick={handleClick}
                >
                  {attachment ? (
                    <p style={{ fontSize: "7pt" }}>Uploaded</p>
                  ) : (
                    <NoteAddIcon />
                  )}{" "}
                </Fab>{" "}
              </div>
              <Fab
                color="primary"
                aria-label="add"
                onClick={handleSubmit}
                disabled={!converse.conversation}
              >
                <SendIcon />
              </Fab>
            </div>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default CommunicationMenu;
