import ViewBILinguistsPage from "pages/ViewBI";
import ViewBIClientsPage from "pages/ViewBIClients";
import ViewBIManagerPage from "pages/ViewBIManager";
import ViewOTClientsPage from "pages/ViewOTClients";
import ViewOTLinguistsPage from "pages/ViewOTLinguists";
import ViewOTManagerPage from "pages/ViewOTManager";

const VIEW_BI = [
  {
    id: '100b',
    type: "form",
    text: 'View Booking Interpreter (Linguists)',
    path: "view-bi-linguists",
    component: ViewBILinguistsPage,
  },
  {
    id: '100c',
    type: "form",
    text: 'View Booking Interpreter (Manager)',
    path: "view-bi-manager",
    component: ViewBIManagerPage,
  },
  {
    id: '90d',
    type: "form",
    text: 'View Booking Interpreter (Clients)',
    path: "view-bi-clients",
    component: ViewBIClientsPage,
  },
  {
    id: '100e',
    type: "form",
    text: 'View Order Translation (Clients)',
    path: "view-ot-clients",
    component: ViewOTClientsPage,
  },
  {
    id: '100f',
    type: "form",
    text: 'View Order Translation (Linguists)',
    path: "view-ot-linguists",
    component: ViewOTLinguistsPage,
  },
  {
    id: '100g',
    type: "form",
    text: 'View Order Translation (Manager)',
    path: "view-ot-manager",
    component: ViewOTManagerPage,
  },
];

export default VIEW_BI;
