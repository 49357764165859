import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import Moment from "moment";
import Action from "./ActionNotes";
import Axios from "axios";
import Cookies from "js-cookie";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const PersonalDetails = ({ id }) => {
  const token = Cookies.get("token");
  const classes = useDashboardStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({ reload: false });

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-my-note`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  return (
    <GridItem xs={12} sm={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" id="export">
                <TableHead>
                  <TableRow>
                    <TableCell>Created At (London Time)</TableCell>
                    <TableCell>My Note / To Do List</TableCell>
                    <TableCell>Deadline (As selected)</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {values.length > 0 ? (
                  <TableBody>
                    {values
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={list._id}
                          style={
                            list?.priority == "Note"
                              ? { backgroundColor: "white" }
                              : list?.priority == "Low"
                              ? { backgroundColor: "lightyellow" }
                              : list?.priority == "Medium"
                              ? { backgroundColor: "#FFBF00" }
                              : list?.priority == "High"
                              ? { backgroundColor: "red" }
                              : { backgroundColor: "lightgreen" }
                          }
                        >
                          <TableCell>
                            {Moment(list.createdAt)
                              .tz("Europe/London")
                              .format("DD MMM YYYY - HH:mm:ss")}
                          </TableCell>
                          <TableCell>
                            {list?.admin_note}
                            <br />
                            <TableCell>
                              {list?.comment
                                ?.filter((dt) => dt != "")
                                .map((st) => (
                                  <TableRow>
                                    {" "}
                                    {st} <br />
                                    <br />
                                  </TableRow>
                                ))}
                            </TableCell>
                          </TableCell>
                          <TableCell>
                            {" "}
                            {list.deadline
                              ? Moment(list.deadline).format(
                                  "DD MMM YYYY - HH:mm:ss"
                                )
                              : "N/A"}
                          </TableCell>
                          <TableCell> {list?.priority} </TableCell>
                          <TableCell>
                            {" "}
                            <Action id={list?._id} load={loadList} priority={list?.priority} />{" "}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  "No data found"
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={values.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default PersonalDetails;
