import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";
import { useParams } from "react-router";
import { Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton, useMediaQuery } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import ViewCompletedAssignments from "components/Admin/BookingPanel/Interpreting/Completed/BAC/List";
import InterpretingtOptions from "components/Admin/BookingPanel/Interpreting/BACInterpretingOptions";
import Options from "components/Admin/BookingPanel/Interpreting/Completed/Options";

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const matches = useMediaQuery("(max-width:425px)");
  const { data, type } = useParams();
  const [booking_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get("/api/manager-admin/dashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setAdminAccess(response?.data.booking_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {booking_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                {matches ? (
                  <CardHeader
                    style={{ display: "grid", gridTemplateColumns: "auto" }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Completed Assignments <br />
                      (Fully Paid) (BAC)
                    </p>
                    <div style={{ display: "flex" }}>
                      <Options />
                      <IconButton
                        color="inherit"
                        style={{ marginLeft: 5 }}
                        onClick={() => history.goBack()}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </div>
                  </CardHeader>
                ) : (
                  <CardHeader
                    style={{ display: "flex", justifyContent: "space-between" }}
                    color="primary"
                  >
                    <p className={classes.cardTitleWhite}>
                      Completed Assignments (Fully Paid) (BAC)
                    </p>
                    <div style={{ display: "flex" }}>
                      <Options />
                      <IconButton
                        color="inherit"
                        style={{ marginLeft: 5 }}
                        onClick={() => history.goBack()}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </div>
                  </CardHeader>
                )}
              </Card>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <InterpretingtOptions />
                </CardHeader>
              </Card>
              <Card>
                <CardBody>
                  <ViewCompletedAssignments
                    interpretingData={type === "interpreting" && data}
                  />
                </CardBody>
              </Card>
            </GridItem>
          ) : booking_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            // <Dimmer active inverted>
            //   <Loader size='large'>Loading</Loader>
            // </Dimmer>
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
