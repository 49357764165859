import React, { useState, useEffect } from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Options from "components/AccountClient/AddAnUser/Options";
import BAC from "components/AccountClient/AddAnUser/InactiveClient/List";
import Cookies from "js-cookie";
import Axios from "axios";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const ViewCashClient = () => {
  const classes = useDashboardStyles();
  const [bacID, setBacID] = useState(null);

  const getDetails = async () => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/accountclient/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBacID(response?.data._id);
  };
  useEffect(() => {
    getDetails();
  });

  return (
    <AccountClientLayout>
      <Accountclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>Inactive Users</p>
              </CardHeader>
              <CardBody>
                <Options bacID={bacID} />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <BAC />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Accountclient>
    </AccountClientLayout>
  );
};

export default ViewCashClient;
