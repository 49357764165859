import BIPaymentPage from "pages/account-client/BIPayment";
import BISummaryPage from "pages/account-client/BISummary";
import BISummaryPageOTAC from "pages/account-client/BIpayment_forordertranslation"
const BI_ACCPUNTCLIENT = [
  {
    id: '25a',
    text: "Summary",
    path: "account-client/book-interpreter/summary",
    component: BISummaryPage,
  },

  {
      id: '25b',
      text: 'Payment',
      path: 'account-client/book-interpreter/payment/:id',
      component: BIPaymentPage
  },
  {
    id: '25a',
    text: "Summary",
    path: "account-client/order-translation/summary",
    component: BISummaryPageOTAC,
  },

];

export default BI_ACCPUNTCLIENT;
