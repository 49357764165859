import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

// Material Core Components
import IconButton from "@material-ui/core/IconButton";

// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";

import IconImg from "components/Sidebar/IconImg";
import DashboardIcon from "assets/icons/dashboardN.png";

const Dashboard = ({ layoutType }) => {
  const classes = useNavbarStyles();
  const history = useHistory();

  const handleClick = (event) => {
    // TODO: Redirect

    switch (layoutType) {
      case "ACCOUNT_CLIENT":
        history.push("/account-client/dashboard");
        break;

      case "CASH_CLIENT":
        history.push("/cash-client/dashboard");
        break;

      case "EMPLOYEE_CLIENT":
        history.push("/employee/dashboard");
        break;

      case "LINGUIST":
        history.push("/linguist/dashboard");
        break;

      case "SPPO":
        history.push("/sppo/dashboard");
        break;

      default:
        history.push("/admin/dashboard");
        break;
    }
  };
  return (
    <Fragment>
      <IconButton aria-label="dashboard" color="primary" onClick={handleClick}>
        <div className={classes.imgDiv}>
          <IconImg className={classes.icons} icon={DashboardIcon} />
        </div>
      </IconButton>
    </Fragment>
  );
};

export default Dashboard;
