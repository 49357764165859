import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";
import { useParams } from "react-router";
import { Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import InhouseAssignment from "components/Admin/BookingPanel/Inhouse/Translation/Closed/List";
import InterpretingtOptions from "components/Admin/BookingPanel/Inhouse/Translation/TranslationOptions";

const AssignmentPage = () => {
  const { data, type } = useParams();
  const classes = useDashboardStyles();
  const [booking_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get("/api/manager-admin/dashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setAdminAccess(response?.data.booking_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {booking_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>
                    Closed Assignments (In-house)
                  </p>
                  <IconButton color="inherit">
                    <ArrowBackIcon onClick={() => history.goBack()} />
                  </IconButton>
                </CardHeader>
              </Card>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <InterpretingtOptions />
                </CardHeader>
              </Card>
              <Card>
                <CardBody>
                  <InhouseAssignment
                    translationData={type === "translation" && data}
                  />
                </CardBody>
              </Card>
            </GridItem>
          ) : booking_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            // <Dimmer active inverted>
            //   <Loader size='large'>Loading</Loader>
            // </Dimmer>
            <Header as="h3">Loading...</Header>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
