import { CHANGE_SPPOR_INPUT, UPDATE_SPPOR_STEP } from "./actionTypes";

export const changeSPPORInput = data => ({
    type: CHANGE_SPPOR_INPUT,
    payload: data
})

export const updateSPPORStep = count => ({
    type: UPDATE_SPPOR_STEP,
    payload: count
})