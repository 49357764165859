import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
import { DURATION } from "data";
import { useDispatch } from "react-redux";
import { changeTimesheetInput } from "redux/actions/timesheetAction";
import moment from "moment";
import { TextField, IconButton } from "@material-ui/core";
import CustomTime from "components/Common/CustomTime";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
const Items = ({ details, linguist }) => {
  const [duration, setDuration] = useState();

  let diffArr = details.startdateArray;
  let index = 0;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    level: "",
    gender: "",
    start_date: [],
    end_date: "",
    start_time: [],
    end_time: "",
    singleDurations: [],
    dump: [],
    oneDayDuration: (
      details.durationArray[0] /
      (details.month_duration * 1)
    ).toFixed(2),
  });
  const handleAdd = (iteriator) => {
    if (iteriator !== moment().daysInMonth()) {
      diffArr.push(index);
      setState({
        ...state,
        dump: diffArr,
        oneDayDuration: (
          details.durationArray[0] /
          (details.month_duration * (iteriator + 2))
        ).toFixed(2),
      });
      index++;
    }
  };
  const handleDateArray = (newVal, index) => {
    let temp = state.start_date;
    temp.splice(index, 1, newVal);
    setState({ ...state, start_date: temp });
    dispatch(
      changeTimesheetInput({
        name: "start_date",
        value: temp,
      })
    );
  };
  const handleTimeArray = (newVal, index) => {
    let temp = state.start_time;
    temp.splice(index, 1, newVal);
    setState({ ...state, start_time: temp });
    dispatch(
      changeTimesheetInput({
        name: "start_time",
        value: temp,
      })
    );
  };
  const handleDurationArray = (newVal, index) => {
    let temp = state.singleDurations;
    temp.splice(index, 1, newVal);
    setState({ ...state, singleDurations: temp });
    let dur = 0;
    state.singleDurations.map((val) => {
      dur += moment.duration(moment(val, "HHmm").format("HH:mm")).asHours();
    });
    setDuration(dur);
    dispatch(
      changeTimesheetInput({
        name: "duration",
        value: dur,
      })
    );
    let actual = [];
    state.singleDurations.map((val) => {
      actual.push(
        moment.duration(moment(val, "HHmm").format("HH:mm")).asHours()
      );
    });
    dispatch(
      changeTimesheetInput({
        name: "actual_duration",
        value: actual,
      })
    );
  };

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="5">Job Details</Table.HeaderCell>
          <Table.HeaderCell width="3">Requested</Table.HeaderCell>
          <Table.HeaderCell width="3">Actual</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {/* {unverifiedLinguistList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list) => ( */}
        <Table.Row hover="true" role="checkbox" tabIndex={-1}>
          <Table.Cell>
            Level of expertise required source / native language (
            {linguist.source_language})
          </Table.Cell>
          <Table.Cell>{details.source_linguist_skill_level}</Table.Cell>
          <Table.Cell>{linguist.source_language_level}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            Level of expertise required target language (
            {linguist.target_language})
          </Table.Cell>
          <Table.Cell>{details.target_linguist_skill_level}</Table.Cell>
          <Table.Cell>{linguist.target_language_level}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Timezone</Table.Cell>
          <Table.Cell>{details.timezone}</Table.Cell>
          <Table.Cell>{details.timezone}</Table.Cell>
        </Table.Row>

        {diffArr.map((st, i) => (
          <Table.Row
            style={{
              background: i === diffArr.length - 1 && i !== 0 && "#f1f1f1",
            }}
          >
            <Table.Cell>
              Appointment start date, time & duration - Day {i + 1}
            </Table.Cell>
            <Table.Cell>
              {i !== 0
                ? moment(details.startdateArray[0])
                    .add(i, "day")
                    .format("DD-MMM-YYYY")
                : moment(details.startdateArray[0]).format("DD-MMM-YYYY")}{" "}
              : {details.starttimeArray[0]} : {state.oneDayDuration}
            </Table.Cell>
            <Table.Cell>
              <div>
                <Table.Row>
                  <Table.Cell>
                    <TextField
                      type="date"
                      inputProps={{
                        min: (i !== 0
                          ? moment(state.start_date[i - 1], "yyyy-MM-DD")
                          : moment()
                        )
                          .add(1, "d")
                          .format("yyyy-MM-DD"),
                      }}
                      onChange={(e) => handleDateArray(e.target.value, i)}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <CustomTime
                      // value={state.start_time}
                      onChange={(e) => handleTimeArray(e.target.value, i)}
                      width="100%"
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <CustomSemanticDD
                      label="Add duration"
                      data={DURATION}
                      onChange={(e) => handleDurationArray(e.target.value, i)}
                    />
                  </Table.Cell>
                </Table.Row>
                {(i === diffArr.length - 1 && i !== (moment().daysInMonth() )) && (
                  <div stle={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton onClick={() => handleAdd(i)} color="primary">
                      <AddCircleOutlineIcon fontSize="large" />
                    </IconButton>
                  </div>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
        ))}

        <Table.Row>
          <Table.Cell>Total Duration / month</Table.Cell>
          <Table.Cell>
            {(details.gethour / details.month_duration).toFixed(2)}
          </Table.Cell>
          <Table.Cell>
            {duration
              ? duration
              : (details.gethour / details.month_duration).toFixed(2)}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default Items;
