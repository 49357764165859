import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import CashClientLayout from "layout/CashClientLayout";
// Custom Components

// Custom Styles

import ViewAttach from "components/Admin/Communication/ViewAttachTicket";


const ViewContactCard = () => {

  return (
    <Cashclient>
      <CashClientLayout>

        <ViewAttach />

      </CashClientLayout>
    </Cashclient>
  );
};

export default ViewContactCard;
