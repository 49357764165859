import React, { useEffect, useState } from "react";

// Custom Components
import Cookies from "js-cookie";
import Axios from "axios";

import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import { Link } from "react-router-dom";



const AssignmentHistory = () => {
  const classes = useDashboardStyles();
  const token = Cookies.get("BACtoken");

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const {
    bookingList,
    reload,
  } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = async () => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/accountclient/booking-history-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/accountclient/translation-history-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/accountclient/history-list/quotations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((bacBooking, bacTranslation, bacQuote) => {
        const history = bacBooking.data
          .concat(bacTranslation.data)
          .concat(bacQuote.data);
        setValues({ ...values, bookingList: history });
      })
    );
  };

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          {" "}
          <TableContainer className={classes.container}>
            <Table
              id="cashbook-table"
              color="pink"
              stickyHeader
              aria-label="sticky table"
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="1">Assignment ID</Table.HeaderCell>
                  <Table.HeaderCell width="1">Source language</Table.HeaderCell>
                  <Table.HeaderCell width="1">Target language</Table.HeaderCell>
                  <Table.HeaderCell width="1">
                    Target language skill
                  </Table.HeaderCell>
                  <Table.HeaderCell width="1">
                    Service Required
                  </Table.HeaderCell>
                  {/* <Table.HeaderCell width='1'>Status</Table.HeaderCell> */}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {bookingList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((list) => (
                    <Table.Row
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={list._id}
                      style={
                        list?.cancel_request
                          ? { backgroundColor: "yellow" }
                          : null
                      }
                    >
                      {list?.target_linguist_skill_level ? (
                        <Table.Cell>
                          <Link
                            to={`/account-client/view-account-client-booking-interpreting/${list?._id}`}
                          >
                            {list?.id}
                          </Link>
                        </Table.Cell>
                      ) : list?.target_expertise_level_required ? (
                        <Table.Cell>
                          <Link
                            to={`/account-client/view-account-client-order-translation/${list?._id}`}
                          >
                            {list?.id}
                          </Link>
                        </Table.Cell>
                      ) : (
                        <Table.Cell>
                          <Link
                            to={`/account-client/view-account-client-quotation/${list?._id}`}
                          >
                            {list?.id}
                          </Link>
                        </Table.Cell>
                      )}

                      <Table.Cell>{list.source_language}</Table.Cell>
                      <Table.Cell>{list.target_language}</Table.Cell>
                      <Table.Cell>
                        {list?.target_linguist_skill_level
                          ? list?.target_linguist_skill_level
                          : list?.target_expertise_level_required
                          ? list?.target_expertise_level_required
                          : list?.target_skills_required}
                      </Table.Cell>
                      <Table.Cell>
                        {list?.typesof_interpreting_required
                          ? list?.typesof_interpreting_required
                          : list?.document_type
                          ? list?.document_type
                          : list?.quote_rqst_for}
                      </Table.Cell>
                      {/* <Table.Cell>{list.job_status}</Table.Cell> */}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={bookingList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default AssignmentHistory;
