import React from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import { Button, Card, List, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { Link } from "react-router-dom";
import history from "utils/history";

const LinguistProfessionals = () => {
  const classes = useLandingpageStyles();
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 0 4rem 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card style={{ padding: "5rem" }} className={classes.clientPortal}>
            <CardBody>
              <Typography
                style={{ fontWeight: "700", borderBottom: "1px solid black" }}
                paragraph
                variant="h5"
              >
                Linguists and Professionals
              </Typography>
              <Typography variant="body1">
                Please login to access your dedicated platform
              </Typography>
              <List dense>
                {/* {ES.map(value => <ListItem>
                                    <ListItemText
                                        primary= {value}
                                    />
                                </ListItem>)} */}
              </List>
              <div style={{ marginBottom: "2rem" }}>
                <Link style={{ textDecoration: "none" }} to="/linguists-login">
                  <Button className={`${classes.button}`} variant="contained">
                    Linguist Login
                  </Button>
                </Link>
              </div>
              <Typography style={{ fontWeight: "600" }} paragraph variant="h6">
                Why Join Linguists Collective?
              </Typography>
              <Typography
                style={{ textAlign: "justify" }}
                paragraph
                variant="body1"
              >
                We are a diverse organisation, a linguist community coming
                together to provide the best services, promoting real living
                wage for linguists. We will take you in with open arms and give
                you the best opportunity to maximise your profit. Our mission is
                to work together to uphold the highest industry standards and
                maximise profit for us and you. We are committed to{" "}
                <b>fairness</b> and <b>ethical</b> operation. We establish{" "}
                <b>co-operative</b> culture and remain <b>transparent</b> as
                well as operating <b>smart</b>. At Linguists Collective you will
                never need to worry about being let down or ripped off, we value
                and respect our staff.
              </Typography>
              <Typography
                style={{ textAlign: "justify" }}
                paragraph
                variant="body1"
              >
                By joining us as a co-operator or associate, you will enjoy our
                advanced platform. It’s not just a portal, it’s a complete
                working solution for freelance professional linguists.
              </Typography>
              <Typography style={{ fontWeight: "600" }} paragraph variant="h6">
                Why do we stand out?
              </Typography>
              <Typography
                style={{ textAlign: "justify" }}
                paragraph
                variant="body1"
              >
                {" "}
                Well, we believe this because of our independence and in valuing
                not only our clients, but our staff too. Our caring concern for
                quality services, and our particular experience in campaigning
                for better pay and condition for interpreters and linguists, can
                assure you that we will always treat our linguists with the
                respect and reward that they deserve. Come along and join our
                family of linguists!
              </Typography>
              <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/linguist/registration"
                >
                  <Button className={`${classes.button}`} variant="contained">
                    Linguist Registration
                  </Button>
                </Link>
              </div>
              <Typography
                style={{ fontWeight: "600" }}
                paragraph
                variant="body1"
              >
                Looking for work?
              </Typography>
              <Button
                onClick={() =>
                  history.push("/landing-page/linguist-professionals/upload-cv")
                }
                variant="contained"
                className={`${classes.button}`}
              >
                Drop us your CV
              </Button>
              <br /><br />
              <div>
                *Recommended web browser:
                <a href="JavaScript:newPopup('https://www.google.co.uk/chrome/?brand=FHFK&gclsrc=aw.ds&gclid=EAIaIQobChMImueQy6yL8QIVCt1RCh0lIQ-7EAAYASAAEgJdS_D_BwE');"> Chrome </a>
                or <a href="JavaScript:newPopup('https://www.microsoft.com/en-us/edge');"> Edge </a>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default LinguistProfessionals;
