import React, { Fragment } from "react";

import SupremeRegistration from "components/Auth/Supreme/Registration/Registration";

const SupremeRegistrationPage = () => {
  return (
    <Fragment>
      <SupremeRegistration />
    </Fragment>
  );
};

export default SupremeRegistrationPage;
