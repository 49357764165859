import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// Fourth Segment
import NewsAndNotice from "./NewsAndNotice";
import WelcomeMessage from "./WelcomeMessage";

import BookingList from "components/CashClient/Dashboard/JobLists/Interpreting/TableOne";
import TranslationList from "components/CashClient/Dashboard/JobLists/Translation/TableTwo";
import QuotationList from "components/CashClient/Dashboard/JobLists/Quotation/TableThree";
import EventsCard from "components/Admin/ManagementPanel/EventsForm/CACEventsCard";
import MyNoteForm from "components/CashClient/Dashboard/MyNote/Form";
import { Header } from "semantic-ui-react";
import { useMediaQuery } from "@material-ui/core";


const CashClientDashboard = () => {
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <GridContainer justify="center" xs={12} sm={12} md={12} lg={12} xl={12}>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <Header as="h3">Interpreting Assignments</Header>
        <BookingList />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <br />
        <Header as="h3">Translation Assignments</Header>
        <TranslationList />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
        <br />
        <Header as="h3">Quotations</Header>
        <QuotationList />
        <br />
        <br />
        <br />
      </GridItem>
      {matches ? (
        <>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <EventsCard />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <MyNoteForm />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <NewsAndNotice />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <WelcomeMessage />
          </GridItem>
        </>
      ) : (
        <>

          <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
            <EventsCard />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
            <MyNoteForm />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
            <NewsAndNotice />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
            <WelcomeMessage />
          </GridItem>
        </>
      )}
    </GridContainer>
  );
};

export default CashClientDashboard;
