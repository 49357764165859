import React, { Fragment, useState } from "react";

// Material Core Components
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

// Material Icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

// Custom Components
import CustomTime from "components/Common/CustomTime";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomDate from "components/Common/CustomDate";
import { changeBaInputAC } from "redux/actions/bookingAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const Time = () => {
  const classes = useFormStyles();
  const dispatch= useDispatch()
  const { data } = useSelector(state => state.accountbookingReducer)
  const [dt, setDt] = useState([])
  const [st, setSt] = useState([])
  const [et, setEt] = useState([])

  const {
    date,
    starttime,
    endtime
  }= data

  const [timeCount, setTimeCount] = useState([1]);

  const handleAdd = () => {
    setTimeCount([...timeCount, timeCount[0] + 1]);
  };
  const handleRemove = () => {
    const copiedTC = [...timeCount];
    copiedTC.pop();
    setTimeCount(copiedTC);
  };

  const handleDateChange = (date) => {
    const dtt = dt.push(date)
    dispatch(changeBaInputAC({
      name: 'date',
      value: dt
    }))
  }

  const handleTimeChange = (date) => {
    const stt = st.push(date)
    dispatch(changeBaInputAC({
      name: 'starttime',
      value: st
    }))
  }

  const handleTimeChangeE = (date) => {
    const ett = et.push(date)
    dispatch(changeBaInputAC({
      name: 'endtime',
      value: et
    }))
  }

  return (
    <div className={classes.timeDiv}>
      
      {timeCount.map((tc) => (
        <Fragment>
          <div  style= {{padding: '0'}} className={classes.flexDiv} key={tc}>
            <CustomDate onChange= {handleDateChange} required label= 'Date'/>
            <CustomTime onChange= {handleTimeChange} required label="Start Time" />
            <CustomTime onChange= {handleTimeChangeE} required label="End Time" />
          </div>
        </Fragment>
      ))}
      {timeCount.length > 1 ? (
        <IconButton
          color="secondary"
          className="i-btn-two"
          component="button"
          onClick={handleRemove}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      ) : null}
      <IconButton
        color="primary"
        className="i-btn"
        component="button"
        onClick={handleAdd}
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </div>
  );
};

export default Time;
