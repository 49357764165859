import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import AccountClientLayout from "layout/AccountClientLayout";
import { useParams } from "react-router";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import history from "utils/history";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import UnconfirmedOptions from "components/AccountClient/Assignments/UnconfirmAssignments/UnconfirmOptions";
import ListTranslations from "components/AccountClient/Assignments/UnconfirmAssignments/ListTranslations/ListTranslations";

const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  const { data, type } = useParams();
  return (
    <Accountclient>
      <AccountClientLayout>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>
                  Unconfirmed Translation Orders
                </p>
                <IconButton color="inherit" onClick={() => history.goBack()}>
                  <ArrowBackIcon />
                </IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <UnconfirmedOptions />
              </CardHeader>

              <CardBody>
                <ListTranslations
                  translationData={type === "translation" && data}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default UnconfirmedAssignmentsPage;
