import React from "react";
import EmployeeLayout from "layout/EmployeeLayout";
import Employee from "components/RouteProtection/Employee/Employee";
import LinguistProfile from "components/PublicProfileLIN/ApprovalPublicProfile/LinguistProfileEMP";

const ManagePublicProfile = () => {
  return (
    <EmployeeLayout>
      <Employee>
        <LinguistProfile />
      </Employee>
    </EmployeeLayout>
  );
};

export default ManagePublicProfile;
