import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { Header } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "axios";
import swal from "sweetalert";
import CustomInputSingle from "components/Common/CustomInputSingle";
import useFormStyles from "assets/jss/components/auth/formStyle";
import { useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

const Details = ({ title, content }) => {
  const classes = useFormStyles();
  const { id } = useParams();

  const [text, setText] = useState();
  const token = Cookies.get("token");

  const [details, setDetails] = useState({
    content: "",
    title: "",
    loader: false,
  });

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/view-privacy-policy/${id}`
      );
      setDetails({
        ...details,
        title: response?.data.title,
        content: response?.data.content,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/update-privacy-policy/${id}`,
        {
          title: details.title || "-",
          content: details.content || "-",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDetails({ ...details, loader: false, title: "", content: "" });
      swal({
        text: "Terms and Conditions Updated.",
        buttons: "Ok",
        icon: "success",
      });
      // .then(function () {
      //   window.location = "/admin/cms";
      // });
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  return (
    <div>
      <CustomInputSingle
        onChange={handleChange}
        label="Title"
        name="title"
        value={details.title}
        width="100%"
        placeholder="Enter Title..."
        required
      />

      <br />
      <Header as="h4">Content</Header>
      <TextField
        label="Type here..."
        name="content"
        onChange={handleChange}
        value={details.content}
        multiline
        rows={100}
        style={{ width: "100%" }}
      />

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          loading={details.loader}
          onClick={handleSubmit}
          disabled={!details.title || !details.content}
          color="purple"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Details;
