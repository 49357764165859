// Material Icons
import Cookies from "js-cookie";
import Axios from "utils/axios";
import CommonCard from "components/Common/CommonCard";
import React, { useState, useEffect } from "react";
import { CompanyDetailsDiv } from "../style";

const CompanyDetails = () => {
  const token = Cookies.get("BACtoken");
  const [values, setValues] = useState(null);

  const getProfile = async (e) => {
    try {
      const response = await Axios.get(
        `/api/accountclient/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues(response?.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return values ? (
    <CommonCard>
      <CompanyDetailsDiv>
        <h3>Client / Customer ref no. {values.id}</h3>
        <div className="details">
          <p className="text">Company Profile</p> <br />
          <p className="text">Business name: {values.business_name}</p>
          <p className="text">Position: {values.position}</p>
          <p className="text">Business email: {values.email}</p>
          <p className="text">Phone: {values.business_phone_no}</p>
          <p className="text">
            Business Address: {values.different_AddressLine1},{" "}
            {values.different_AddressLine2}
          </p>
          <p className="text">Town/City: {values.different_Town}</p>
          <p className="text">Region: {values.different_CountryRegion}</p>
          <p className="text">Zipcode/Postalcode: {values.different_PostalCode}</p>
          <p className="text">Country: {values.different_Country}</p>
          <p className="text">
            Company registration no: {values.company_registration_number}
          </p>
          <p className="text">
            Charity registration no: {values.charity_registration_number}
          </p>
          <p className="text">Business website: {values.business_website}</p>
          <p className="text">Additional note: {values.note}</p>
        </div>
      </CompanyDetailsDiv>
    </CommonCard>
  ) : null;
};

export default CompanyDetails;
