import {
  UPDATE_ER_STEP,
  CHANGE_ER_INPUT,
  SET_LOADING,
  ER_LOGIN,
  ER_SUBMIT,
  AUTO_SAVE_ER,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  count: 1,
  loading: false,
  data: {
    title: "",
    first_name: "",
    last_name: "",
    house_street_no_name: '',
    address_line_two: '',
    town_city: '',
    post_code: '',
    county_region: '',
    country: '',
    current_nationality: '',
    other_nationalities: [],
    dob: "",
    sex: "",
    primary_email: "",
    password: "",
    confirm_password: '',
    mobile_no: "",
    online_calling_details: "",
    proof_address_photo: "",
    reference_one: "",
    reference_two: "",
    work_in_the_uk: "",
    ni_number: "",
    utr_number: "",
    passport_ni_photo: "",
    relevant_document_photo: "",
    bank_account_fullname: "",
    bank_sort_code: "",
    bank_account_number: "",
    bank_branch_name: "",
    bank_iban: "",
    bank_swift_code: "",
    bank_name: "",
    bank_branch_address: "",
    bank_additional_info: "",
    bic_no: '',
    ifsc_code: '',
    institution_number: '',
    branch_code: '',
    aba: '',
    currency: "",
    mother_tongue: '',
    language_combinations: [],
    interpreting_qualification: "",
    translation_qualification: "",
    other_language_test: "",
    other_qualifications: '',
    institute_name: "",
    date_of_graduation: "",
    name_of_degree: "",
    subject_studied: "",
    relevant_certificate_photo: "",
    organization_name: "",
    organization_address: "",
    start_date: "",
    leaving_date: "",
    experience_gained: "",
    reason_for_leaving: '',
    supervisor_contact_details: "",
    criminal_record: "",
    criminal_record_details: "",
    security_clearance: "",
    title_security_clearance: "",
    security_clearance_document: "",
    security_certificate_expiry_date: "",
    note: "",
    upload_cv: "",
    relevant_documents: "",
    special_needs: "",
    gdpr: false,
    
    business_name: "",
    position: "",
    business_email: "",
    business_phone_no: "",
    business_address: "",
    business_website: "",
  },
  loggedInData: null,
};

const erReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_ER_STEP:
      return {
        ...state,
        count: payload,
      };

    case CHANGE_ER_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value,
        },
      };

    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case ER_LOGIN:
      return {
        ...state,
        loading: false,
        loggedInData: payload,
      };
    case ER_SUBMIT:
      return {
        ...state,
        loading: false,
        data: {
          title: "",
          first_name: "",
          last_name: "",
          dob: "",
          sex: "",
          email: "",
          password: "",
          mobile_no: "",
          house_no: "",
          street_name: "",
          town: "",
          zip_code: "",
          country: "",
          business_name: "",
          position: "",
          business_email: "",
          business_phone_no: "",
          business_address: "",
          business_website: "",
          note: "",
        },
      };
    
    case AUTO_SAVE_ER:
      return {
        ...state,
        data: payload.temp
      }

    default:
      return state;
  }
};

export default erReducer;
