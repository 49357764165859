import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import ListAltIcon from "@material-ui/icons/ListAlt";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { useMediaQuery } from "@material-ui/core";

const LinguistOptions = () => {
  const history = useHistory();
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <div>
      {matches ? (
        <>
          {" "}
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/admin/unverified-sppo")}
          >
            Unverified SPPO
          </Button>
          <Button
            startIcon={<VerifiedUserIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/admin/verified-sppo")}
          >
            Verified SPPO
          </Button>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {" "}
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "45%", marginRight: "15px" }}
            onClick={() => history.push("/admin/unverified-sppo")}
          >
            Unverified SPPO
          </Button>
          <Button
            startIcon={<VerifiedUserIcon />}
            color="primary"
            variant="contained"
            style={{ width: "45%", marginRight: "15px" }}
            onClick={() => history.push("/admin/verified-sppo")}
          >
            Verified SPPO
          </Button>
        </div>
      )}
    </div>
  );
};

export default LinguistOptions;
