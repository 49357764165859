import React, { useEffect } from "react";
import Axios from "axios";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import history from "utils/history";
import swal from "sweetalert";
import Cookies from "js-cookie";

const BIPaymentStatus = () => {
  const location = useLocation();

  const sendData = async (e) => {
    const data = Cookies.get("user_token");
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/cashclientinterpreting/sendMail/${data}`
      );
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  const viewPayIn = async (e) => {
    const Id = queryString.parse(location.search);
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/view/payin/card/direct/${Id.transactionId}`
      );

      if (response?.data.Status === "SUCCEEDED") {
        sendData();
        swal({
          icon: "success",
          text: "Payment Success",
          buttons: false,
        });
        history.push("/cash-client/dashboard");
      } else {
        swal({
          icon: "error",
          text: "Payment Failed",
          buttons: false,
          dangerMode: true,
        });
        history.push("/");
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    viewPayIn();
  }, []);
  return <div></div>;
};

export default BIPaymentStatus;
