import CustomTextInput from "components/Common/CustomTextInput";
import React, { useRef, useState, useEffect } from "react";
import { Button, Dimmer, Loader, Rating, Table } from "semantic-ui-react";
import { Header } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { Checkbox } from "semantic-ui-react";
import SignatureCanvas from "react-signature-canvas";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import history from "utils/history";
import RichTextEditor from "react-rte";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "axios";
import moment from "moment";
import CustomSelect from "components/Common/CustomSelect";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
import { DURATION, SECURITY_CLEARANCE_LEVEL } from "data";
import swal from "sweetalert";
import { IconButton } from "@material-ui/core";
import RemarksField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import { changeTimesheetInput } from "redux/actions/timesheetAction";

const Details = () => {
  let sigPad = useRef({});
  const [signature, setSignature] = useState(false);
  const [sign, setSign] = useState("");
  const [text, setText] = useState();
  const [projectReport, setProjectReport] = useState(null);
  const [admin, setAdminDetails] = useState(null);
  const { id } = useParams();
  const token = Cookies.get("token");
  const [declaration, setDeclaration] = useState(false);
  const [changed, setChanged] = useState(false);
  const [duration, setDuration] = useState();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    project_name: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    request_details: "",
    source_language: "",
    target_language: "",
    target_language_level: "",
    security_clearence: "",
    word_count: "",
    duration: "",
    visual_formatting: "",
    certification: "",
    post_certification: "",
    project_report: RichTextEditor.createEmptyValue(),
    signature: "",
    supervisor_fullname: "",
    date: "",
    remarksBySupervisor: "",
    remarksByLin: "",
    loader: false,
  });
  const uploadRef = useRef();
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const todayDate = moment().format("DD/MMM/YYYY");

  const handleEdit = (e) => {
    setEdit(true);
    // setChanged(false)
  };

  const handleDone = (e) => {
    setEdit(false);
    dispatch(
      changeTimesheetInput({
        name: "duration",
        value: duration,
      })
    );
    setChanged(true);
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getProjectReport = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/view-client-Quote/QuoteID/${id}`
        );
        setProjectReport(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancel");
        } else {
          console.log(error.response);
        }
      }
    };

    const getAdminDetails = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAdminDetails(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancel");
        } else {
          console.log(error.response);
        }
      }
    };

    getProjectReport();
    getAdminDetails();

    return () => {
      source.cancel();
    };
  }, []);

  const handleClose = (e) => {
    e.preventDefault();
    setSignature(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = () => {
    const temp = sigPad.current.toDataURL();
    setDetails({ ...details, signature: temp });
    setSignature(false);
  };

  const handleText = (value) => {
    setText(value.toString("markdown"));
    setDetails({ ...details, project_report: value });
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });
    const fd = new FormData();

    const file = new File([details.signature], "signature", {
      type: "image/png",
    });

    fd.append("project_name", details.project_name || "");
    fd.append("start_date", details.start_date || "");
    fd.append("end_date", details.end_date || "");
    fd.append("request_details", details.request_details || "");
    fd.append("source_language", projectReport.source_language || "");
    fd.append("target_language", projectReport.target_language || "");
    fd.append("target_language_level", projectReport.skill_level || "");
    fd.append("security_clearence", details.security_clearence || "");
    fd.append("word_count", details.word_count || "");
    fd.append("duration", duration || "");
    fd.append("project_report", text || "");
    fd.append("signatureBySupervisor", file);
    fd.append("supervisor_fullname", "");
    fd.append("date", moment().format("YYYY/MMM/DD"));
    fd.append("remarksBySupervisor", details.remarksBySupervisor);

    if (uploadTimesheet?.size) {
      fd.append("documentByAdmin", uploadTimesheet);
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/create-project-report-directQuote/quoteID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDetails({ ...details, loader: false });
      swal({
        text: "Project Report Created",
        buttons: "Ok",
        icon: "success",
      });
      history.push(`/admin/find-quote`);
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  const handleRate = (e, { rating, maxRating }) => {
    setDetails({ ...details, remarksBySupervisor: rating });
  };

  return projectReport ? (
    <div>
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          // onEnd = {handleSave}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            basic
            color="purple"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="purple">
            Save
          </Button>
        </div>
      </CustomModal>
      <CustomTextInput
        label="Quotation reference number"
        value={projectReport?.id}
      />

      {/* Table */}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center">Actual</Table.HeaderCell>
            <Table.HeaderCell>Requested</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Project start date: </Table.Cell>
            <Table.Cell textAlign="center">
              <TextField
                type="date"
                value={details.start_date}
                onChange={(e) =>
                  setDetails({ ...details, start_date: e.target.value })
                }
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details.start_date &&
                moment(details.start_date).format("DD/MM/YYYY")}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project end date: </Table.Cell>
            <Table.Cell textAlign="center">
              <TextField
                type="date"
                value={details.end_date}
                onChange={(e) =>
                  setDetails({ ...details, end_date: e.target.value })
                }
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              {details.end_date &&
                moment(details.end_date).format("DD/MM/YYYY")}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Service</Table.Cell>
            <Table.Cell>{projectReport?.service}</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Source language</Table.Cell>
            <Table.Cell>{projectReport?.source_language}</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Target language</Table.Cell>
            <Table.Cell>
              <Table.Cell>{projectReport?.target_language}</Table.Cell>
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell>
              <Table.Cell>{projectReport?.skill_level}</Table.Cell>
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Security clearence/s</Table.Cell>
            <Table.Cell>
              <CustomSelect
                onChange={(e) =>
                  setDetails({ ...details, security_clearence: e.target.value })
                }
                data={SECURITY_CLEARANCE_LEVEL}
                width="100%"
                name="security_clearence"
                value={details.security_clearence}
              />
            </Table.Cell>
            <Table.Cell>{/* {details.security_clearence} */}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Interpreting duration </Table.Cell>
            <Table.Cell>
              <>
                {edit ? (
                  <div style={{ display: "flex" }}>
                    <CustomSemanticDD
                      label="Add duration"
                      data={DURATION}
                      onChange={(e) => setDuration(e.target.value)}
                    />
                    <IconButton onClick={handleDone}>
                      <DoneIcon />
                    </IconButton>
                  </div>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {changed ? duration : ""}
                    <IconButton onClick={handleEdit}>
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
              </>
            </Table.Cell>
            <Table.Cell textAlign="center">{duration}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Wordcount</Table.Cell>
            <Table.Cell>
              <CustomTextInput
                number={true}
                name="word_count"
                onChange={(e) =>
                  setDetails({ ...details, word_count: e.target.value })
                }
                value={details.word_count}
                edit={true}
                placeholder="Wordcount number..."
              />
            </Table.Cell>
            <Table.Cell textAlign="center">{details.word_count}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">Project Report</Header>
      <RichTextEditor value={details.project_report} onChange={handleText} />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>To be completed by admin</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Checkbox
                checked={declaration}
                onChange={(e, value) => setDeclaration(value.checked)}
                label="I declare that I have checked the information on this form and certify that it is true and complete"
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Signature{" "}
              <IconButton onClick={() => setSignature(true)}>
                <EditIcon />
              </IconButton>
              {details.signature && (
                <img
                  style={{ width: "100px", height: "50px" }}
                  src={details.signature}
                  alt=""
                />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Full name: {admin?.FirstName + " " + admin?.LastName}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {todayDate}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Remark
              <RemarksField
                onChange={(e) =>
                  setDetails({
                    ...details,
                    remarksBySupervisor: e.target.value,
                  })
                }
                id="standard-textarea"
                label="Type here... (max 120 ch.)"
                placeholder="Write your remarks here..."
                multiline
                rows={2}
                inputProps={{ maxLength: 120 }}
                name="remarksBySupervisor"
                style={{ marginBottom: 15, width: 100 + "%" }}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      {/* Future work : Only for cac and bac */}

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button
        onClick={() => uploadRef.current.click()}
        basic
        style={{ backgroundColor: "purple", color: "white" }}
      >
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          loading={details.loader}
          onClick={handleSubmit}
          disabled={!declaration || !details.signature}
          color="purple"
        >
          Submit
        </Button>
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="large">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
