import { useHistory } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CustomInput from "components/Common/CustomInput";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Icon, Modal } from 'semantic-ui-react'
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SecurityIcon from '@material-ui/icons/Security';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DescriptionIcon from '@material-ui/icons/Description';
import BusinessIcon from '@material-ui/icons/Business';
import Axios from "axios";
import Cookies from 'js-cookie'
import swal from 'sweetalert'



const ULOptions = ({ id }) => {
  const history = useHistory();
  const [paidModal, setPaidModal] = React.useState(false)
  const [pay, setPay] = useState({
    note: '',
  })

  const handlePaid = async (id) => {
    setPaidModal(true)
    setPay({ ...pay })
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get('LINtoken')

  const handlePaidClick = e => {
    setAnchorEl(false)
    handlePaid(id)
  }

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value })
  }
  const [values, setValues] = useState({
    loader: false
  });

  const handleConfirm = async (e) => {
    setValues({ ...values, loader: true })
    try {
      const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/linguists/send-reference-letter`, {
        note: pay.note,

      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setValues({ ...values, loader: false })
      swal({
        icon: 'success',
        text: 'Reference letter or proof of address successfully send',
        buttons: false
      })
      setPaidModal(false)
    } catch (error) {
      console.log(error.response)
    }
  }





  const [totalAddress, setTotalAddress] = React.useState(false);
  const [totalPassport, setTotalPassport] = React.useState(false);
  const [totalRelevantDoc, setTotalRelevantDoc] = React.useState(false);
  const [totalInsuranceDoc, setTotalInsuranceDoc] = React.useState(false);
  const [totalInterpretingDoc, setTotalInterpretingDoc] = React.useState(false);
  const [totalTranslationDoc, setTotalTranslationDoc] = React.useState(false);
  const [totalPsiDoc, setTotalPsiDoc] = React.useState(false);
  const [totalOtherDoc, setTotalOtherDoc] = React.useState(false);
  const [totalRelevantCertificate, setTotalRelevantCertificate] = React.useState(false);
  const [totalWorkExpDoc, setTotalWorkExpDoc] = React.useState(false);
  const [totalSecurityDoc, setTotalSecurityDoc] = React.useState(false);
  const [totalCv, setTotalCv] = React.useState(false);

  const getTotalAddress = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-proof-of-address/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalAddress(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalPassport = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-passport/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalPassport(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalRelevantDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-relevent-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalRelevantDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalInsuranceDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-insurance-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalInsuranceDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalInterpretingDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-interpreting-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalInterpretingDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalTranslationDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-translation-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalTranslationDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalPsiDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-psipst-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalPsiDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalOtherDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-other-experience-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalOtherDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalRelevantCertificate = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-relevent-certificate-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalRelevantCertificate(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalWorkExpDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-work-reference-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalWorkExpDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalSecurityDoc = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-total-security-clearence-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalSecurityDoc(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalCv = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/for-linguist-manager/count-cv-upload-document/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalCv(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalAddress();
    getTotalPassport()
    getTotalRelevantDoc()
    getTotalInsuranceDoc()
    getTotalInterpretingDoc();
    getTotalTranslationDoc()
    getTotalPsiDoc()
    getTotalOtherDoc()
    getTotalRelevantCertificate();
    getTotalWorkExpDoc()
    getTotalSecurityDoc()
    getTotalCv()
  }, []);






  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'auto auto ' }}>

      <Modal
        open={paidModal}
        onClose={() => setPaidModal(false)}
      >
        <Modal.Header>Reference Letter</Modal.Header>
        <Modal.Content>
          <Modal.Description>

            <p>- Why do you need this reference / proof hour letter?</p>
            <p>- What details should be included?</p>
            <CustomInput name='note' onChange={handleChange} value={pay.note} label='Answere' edit={true} />

          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleConfirm} color='purple' disabled={values.loader}>
            Send <Icon name='chevron right' />
          </Button>

          {values.loader && <div class="ui active inline loader" style={{ backgroundColor: "purple" }}></div>}
        </Modal.Actions>
      </Modal>

      <Button
        startIcon={<PermIdentityIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-profile-photo-preview/${id}`)}
      >
        Preview Profile Photo
      </Button>
      <Button
        startIcon={<  BusinessIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-address`)}
      >
        Preview Address Document ({totalAddress})
      </Button>
      <Button
        startIcon={<CreditCardIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-passport`)}
      >
        Passport or National ID ({totalPassport})
      </Button>
      <Button
        startIcon={< DescriptionIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-relevent-document`)}
      >
        Any Other / Contract Documents ({totalRelevantDoc})
      </Button>

      <Button
        startIcon={< VerifiedUserIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-relevent-certificate`)}
      >
        Relevent Certificate ({totalRelevantCertificate})
      </Button>

      <Button
        startIcon={<SecurityIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-security-clearence-1`)}
      >
        Security Clearence Document ({totalSecurityDoc})
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-cv`)}
      >
        CV ({totalCv})
      </Button>



      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-insurance-document`)}
      >
        Insurance Document ({totalInsuranceDoc})
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-reference-letter`)}
      >
        Previous work reference ({totalWorkExpDoc})
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-interpreting-document`)}
      >
        Interpreting Qualifications Document ({totalInterpretingDoc})
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-translation-document`)}
      >
        Translation Qualifications Document ({totalTranslationDoc})
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-psi-pst-document`)}
      >
        PSI / PST experience reference letter ({totalPsiDoc})
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-view-any-other-document`)}
      >
        Interpreting or translation reference ({totalOtherDoc})
      </Button>

      <Button
        startIcon={<AssignmentIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={handlePaidClick}

      >
        Request reference / proof of hours

      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/update-linguist-documents`)}
      >
        Upload Documents
      </Button>

    </div>
  );
};

export default ULOptions;
