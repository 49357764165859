import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";

// Custom Components
import CashClientLayout from "layout/CashClientLayout";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import BIPayment from "components/Auth/LinguistsCollective/BIpayment";

const BIPaymentPage = () => {
  return (
    <CashClientLayout>
      <Cashclient>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <BIPayment />
        </GridItem>
      </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default BIPaymentPage;
