import React, { useState } from "react";
import { Accordion, Header, Icon, Segment } from "semantic-ui-react";
import LandingPageLayout from "layout/LandingPageLayout";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ThreeCards from "./ThreeCards";
import SearchView from "./SearchView/SearchView";
const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState("0");
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  const values = [
    "Why is it important to have an interpreter?",
    "Why can't I use a family member?",
    "What sets Linguists Collective platform apart from other interpreting organisations?",
    "How do you know the translators and interpreters are good?",
    "How do I determine what language/dialect to use for a certain/specific interpreting?",
    "What type of computer systems and software does LC support?",
    "What payments methods are available?",
    "How do I book an interpreter/order translation online?",
    "Is legal interpreting provided within LC?",
    "What happens if I need to cancel a booking?",
    "What is a PRC?",
    "What is a INC?",
    "What is a CAC?",
    "What is a BAC?",
    "What is Manual TimeSheet?",
    "What is Manual Project Report?",
  ];
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 0 4rem 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Segment inverted color="purple">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {" "}
              <Header as="h2" style={{ color: "white" }} textAlign="right">
                <Icon name="question circle" />
                <Header.Content>Frequently Asked Questions</Header.Content>
              </Header>
              <SearchView
                setFiltered={setFiltered}
                setSearchText={setSearchText}
                searchText={searchText}
                values={values}
              />
            </div>
          </Segment>
          <Accordion fluid styled>
            {filtered.length > 1 ? (
              <>
                {filtered?.map((item, i) => (
                  <div key={i}>
                    <Accordion.Title
                      active={activeIndex === i}
                      index={i}
                      onClick={handleClick}
                    >
                      <Icon name="dropdown" />
                      {item}
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === i}>
                      {item === values[0] ? (
                        <>
                          {" "}
                          <p>
                            Interpreters are used where two or more people need
                            assistance understanding something in another
                            language. A professional interpreter is important to
                            have when a professional interview is conducted,
                            such as doctor’s appointment, job interviews, police
                            interviews, immigration interviews, school and
                            further education institutes or any interrogations
                            to avoid any serious consequences due to language
                            barriers, miscommunication or cultural incompetency.
                          </p>
                          <p>
                            For any legal reasons: it is your right to have an
                            interpreter in the UK and EU as this plays an
                            integral part of the right to a fair trial. It is a
                            principle of English common law that the Defendant
                            must be able to understand the charges made against
                            them and be able to properly defend themselves. The
                            right is also enshrined in the European Convention
                            on Human Rights. Not only do the interpreters play
                            an important role in global business, they enhance
                            the communication by conveying information
                            accurately and frequently form one language to
                            another in different countries across the world.{" "}
                          </p>
                        </>
                      ) : item === values[1] ? (
                        <p>
                          Interpreting is a profession and it is unwise to use
                          someone who is not qualified as an interpreter in any
                          environment where the consequences of incorrect
                          translation are negative. Due to the sensitivity of
                          information, it being shared is not ideal as it may
                          cause conflict of interest and compromise
                          confidentiality amongst families.
                        </p>
                      ) : item === values[2] ? (
                        <>
                          {" "}
                          <p>
                            We are the first to introduce a combined agency and
                            a marketplace platform for interpreting and
                            translation services.
                          </p>
                          <p>a. Open and transparent   </p>
                          <p>b. Smart and secure booking system </p>
                          <p>c. Secure payment</p>
                          <p>d. Modern marketplace</p>
                        </>
                      ) : item === values[3] ? (
                        <p>
                          You have the flexibility to check our professionals
                          profile and book accordingly to fit your needs. This
                          will allow you to pick the most suitable translator or
                          interpreter to accurately convey the message, whether
                          it is written or spoken. Therefore, it is important
                          that the client defines their objectives and provide
                          all the necessary details to the language service
                          provider.
                        </p>
                      ) : item === values[4] ? (
                        <p>
                          You will need to speak to your non-native speaker and
                          identify which languages are needed using our list of
                          languages with the relevant country name and origin.
                          Here we have produced a list of languages and origin
                          or the country they are found, you can show this to
                          the non-native speaker for identifying correct mother
                          tongue
                          {/* <a href="/list-of-languages-web.pdf" download>
                  {""} pdf list
                </a> */}
                          <object
                            data="/list-of-languages-web.pdf"
                            type="application/pdf"
                            width="100%"
                            height="1000px"
                            aria-label="document"
                          ></object>
                        </p>
                      ) : item === values[5] ? (
                        <p>
                          All, but we recommend using larger than 8” screen for
                          the best quality of experience of using LC platform
                        </p>
                      ) : item === values[6] ? (
                        <p>
                          Card (with refund option, if needed) for all, bank
                          transfer and direct debit for Billing Account Clients
                          only (no refund available).
                        </p>
                      ) : item === values[7] ? (
                        <>
                          {" "}
                          <p>
                            On our website, at the top we have an option to
                            click ‘Book, online and order’. That will take you
                            to the relevant page to help you book an interpreter
                            or order a translation online.
                          </p>
                          <object
                            data="/Book-interpreter-or-order-translation.pdf"
                            type="application/pdf"
                            width="100%"
                            height="700px"
                            aria-label="document"
                          ></object>
                        </>
                      ) : item === values[8] ? (
                        <p>
                          Yes, we offer interpreting and translating services
                          for matters involving the British court, security
                          personnel, police investigation and many more.
                        </p>
                      ) : item === values[9] ? (
                        <p>
                          You can cancel your booking from clicking the option
                          to cancel within the job list on your dashboard or by
                          contacting our customer service team. However, if you
                          are cancelling with less than 48 hours notice, you
                          will be charged. Please refer to the CARP card for
                          appropriate cancellation fees.
                        </p>
                      ) : item === values[10] ? (
                        <p>
                          PRC stands for Private Clients of Linguists. Private
                          clients are our Linguists' own clients to whom they
                          provide services in their specialist language while
                          being qualified and registered to provide services for
                          the Linguists Collective.
                        </p>
                      ) : item === values[11] ? (
                        <p>
                          An in-house client is a client registered with a LC
                          Linguist or SPPO who can place orders for services in
                          translation or interpreting via that Agent Linguist or
                          SPPO public profile.
                        </p>
                      ) : item === values[12] ? (
                        <p>
                          CAC refers to a Cash Account Client. We call them Cash
                          Clients as they pay the estimated sum for their
                          service in advance in order to book an interpreter,
                          order translation online or confirm a free quote.
                        </p>
                      ) : item === values[13] ? (
                        <p>
                          Billing Account Client is referred as BAC by LC. BAC
                          are mainly large organisations or SMEs that have a
                          contract in place between LC and BAC and pays monthly
                          bill. BAC not required to pay in advance for their
                          booking interpreter, ordering translation or
                          confirming a quote.
                        </p>
                      ) : item === values[14] ? (
                        <object
                          data="/LC-Trainer-Profile.pdf"
                          type="application/pdf"
                          width="100%"
                          height="700px"
                          aria-label="document"
                        ></object>
                      ) : item === values[15] ? (
                        <object
                          data="/Linguists Collective time sheet.pdf"
                          type="application/pdf"
                          width="100%"
                          height="700px"
                          aria-label="document"
                        ></object>
                      ) : (
                        item === values[16] && (
                          <object
                            data="/Linguists Collective project report.pdf"
                            type="application/pdf"
                            width="100%"
                            height="700px"
                            aria-label="document"
                          ></object>
                        )
                      )}
                    </Accordion.Content>{" "}
                  </div>
                ))}
              </>
            ) : (
              <>
                {" "}
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  Why is it important to have an interpreter?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <p>
                    Interpreters are used where two or more people need
                    assistance understanding something in another language. A
                    professional interpreter is important to have when a
                    professional interview is conducted, such as doctor’s
                    appointment, job interviews, police interviews, immigration
                    interviews, school and further education institutes or any
                    interrogations to avoid any serious consequences due to
                    language barriers, miscommunication or cultural
                    incompetency.
                  </p>
                  <p>
                    For any legal reasons: it is your right to have an
                    interpreter in the UK and EU as this plays an integral part
                    of the right to a fair trial. It is a principle of English
                    common law that the Defendant must be able to understand the
                    charges made against them and be able to properly defend
                    themselves. The right is also enshrined in the European
                    Convention on Human Rights. Not only do the interpreters
                    play an important role in global business, they enhance the
                    communication by conveying information accurately and
                    frequently form one language to another in different
                    countries across the world.{" "}
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  Why can’t I use a family member?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <p>
                    Interpreting is a profession and it is unwise to use someone
                    who is not qualified as an interpreter in any environment
                    where the consequences of incorrect translation are
                    negative. Due to the sensitivity of information, it being
                    shared is not ideal as it may cause conflict of interest and
                    compromise confidentiality amongst families.
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  What sets Linguists Collective platform apart from other
                  interpreting organisations?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <p>
                    We are the first to introduce a combined agency and a
                    marketplace platform for interpreting and translation
                    services.
                  </p>
                  <p>a. Open and transparent   </p>
                  <p>b. Smart and secure booking system </p>
                  <p>c. Secure payment</p>
                  <p>d. Modern marketplace</p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 3}
                  index={3}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  How do you know the translators and interpreters are good?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <p>
                    You have the flexibility to check our professionals profile
                    and book accordingly to fit your needs. This will allow you
                    to pick the most suitable translator or interpreter to
                    accurately convey the message, whether it is written or
                    spoken. Therefore, it is important that the client defines
                    their objectives and provide all the necessary details to
                    the language service provider.
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  How do I determine what language/dialect to use for a
                  certain/specific interpreting?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <p>
                    You will need to speak to your non-native speaker and
                    identify which languages are needed using our list of
                    languages with the relevant country name and origin. Here we
                    have produced a list of languages and origin or the country
                    they are found, you can show this to the non-native speaker
                    for identifying correct mother tongue
                    {/* <a href="/list-of-languages-web.pdf" download>
                  {""} pdf list
                </a> */}
                    <object
                      data="/list-of-languages-web.pdf"
                      type="application/pdf"
                      width="100%"
                      height="1000px"
                      aria-label="document"
                    ></object>
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 5}
                  index={5}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  What type of computer systems and software does LC support?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  <p>
                    All, but we recommend using larger than 8” screen for the
                    best quality of experience of using LC platform
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 6}
                  index={6}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  What payments methods are available?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                  <p>
                    Card (with refund option, if needed) for all, bank transfer
                    and direct debit for Billing Account Clients only (no refund
                    available).
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 7}
                  index={7}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  How do I book an interpreter/order translation online?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 7}>
                  <p>
                    On our website, at the top we have an option to click ‘Book,
                    online and order’. That will take you to the relevant page
                    to help you book an interpreter or order a translation
                    online.
                  </p>
                  <object
                    data="/Book-interpreter-or-order-translation.pdf"
                    type="application/pdf"
                    width="100%"
                    height="700px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 8}
                  index={8}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  Is legal interpreting provided within LC?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 8}>
                  <p>
                    Yes, we offer interpreting and translating services for
                    matters involving the British court, security personnel,
                    police investigation and many more.
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 9}
                  index={9}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  What happens if I need to cancel a booking?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 9}>
                  <p>
                    You can cancel your booking from clicking the option to
                    cancel within the job list on your dashboard or by
                    contacting our customer service team. However, if you are
                    cancelling with less than 48 hours notice, you will be
                    charged. Please refer to the CARP card for appropriate
                    cancellation fees.
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 10}
                  index={10}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  What is a PRC?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 10}>
                  <p>
                    PRC stands for Private Clients of Linguists. Private clients
                    are our Linguists' own clients to whom they provide services
                    in their specialist language while being qualified and
                    registered to provide services for the Linguists Collective.
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 11}
                  index={11}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  What is a INC?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 11}>
                  <p>
                    An in-house client is a client registered with a LC Linguist
                    or SPPO who can place orders for services in translation or
                    interpreting via that Agent Linguist or SPPO public profile.
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 12}
                  index={12}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  What is a CAC?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 12}>
                  <p>
                    CAC refers to a Cash Account Client. We call them Cash
                    Clients as they pay the estimated sum for their service in
                    advance in order to book an interpreter, order translation
                    online or confirm a free quote. However, CAC can request for
                    'Pay Later' option to be activated, particularly for UK
                    Legal Aid jobs.
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 13}
                  index={13}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  What is a BAC?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 13}>
                  <p>
                    Billing Account Client is referred as BAC by LC. BAC are
                    mainly large organisations or SMEs that have a contract in
                    place between LC and BAC and pays monthly bill. BAC not
                    required to pay in advance for their booking interpreter,
                    ordering translation or confirming a quote.
                  </p>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 14}
                  index={14}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  LC Linguist Trainer's Profile, International Mother Language
                  Day and LC Virtual Conference 21 Feb 2022
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 14}>
                  <object
                    data="/LC-Trainer-Profile.pdf"
                    type="application/pdf"
                    width="100%"
                    height="700px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 15}
                  index={15}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  What is Manual TimeSheet?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 15}>
                  <object
                    data="/Linguists Collective time sheet.pdf"
                    type="application/pdf"
                    width="100%"
                    height="700px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 16}
                  index={16}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  What is Manual Project Report?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 16}>
                  <object
                    data="/Linguists Collective project report.pdf"
                    type="application/pdf"
                    width="100%"
                    height="700px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>
              </>
            )}
          </Accordion>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <iframe
              width="1000"
              height="500"
              src="https://www.youtube.com/embed/n4J4deOJAI0?rel=0"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              title="LC - linguistscollective"
              loading="lazy"
            ></iframe>
          </div>
          <br />
          <ThreeCards />
        </GridItem>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default FAQ;
