import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import { COUNTRY_DATA, ACCOUNT_TYPE, GENDER_DATA } from "data";
import moment from "moment";

const PersonalDetails = () => {
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const token = Cookies.get("token");
  const { data } = useSelector((state) => state.updateProfileReducer);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeLinguistProfileInput({
        name: "different_AddressLine2",
        value,
      })
    );
  };

  const handleUpdate = async (e) => {
    setEdit({ ...edit, loader: true });
    let fd = new FormData();

    fd.append("account_type", data.account_type);
    fd.append("business_name", data.business_name);
    fd.append("AddressLine1", data.AddressLine1);
    fd.append("AddressLine2", data.AddressLine2);
    fd.append("City", data.City);
    fd.append("PostalCode", data.PostalCode);
    fd.append("Country", data.Country);
    fd.append("Region", data.Region);
    fd.append("business_website", data.business_website);
    fd.append("company_registration_number", data.company_registration_number);
    fd.append("charity_registration_number", data.charity_registration_number);
    fd.append("business_phone_no", data.business_phone_no);

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/update-account-client/${data._id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit({ ...edit, value: false, loader: false });
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
        setEdit({ ...edit, value: false, loader: false });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          {edit.value ? (
            <CustomTextInput
              onChange={handleChange}
              name="account_type"
              label="Account type"
              select={ACCOUNT_TYPE}
              svalue={data.account_type}
            />
          ) : (
            <CustomTextInput label="Account type" value={data.account_type} />
          )}

          <CustomTextInput
            name="business_name"
            onChange={handleChange}
            label="Business Name"
            edit={edit.value}
            value={data.business_name}
          />
          <CustomTextInput
            name="AddressLine1"
            onChange={handleChange}
            label="House number / name, Street name"
            edit={edit.value}
            value={data.AddressLine1}
          />
          <CustomTextInput
            name="AddressLine2"
            onChange={handleChange}
            label="Address Line 2"
            edit={edit.value}
            value={data.AddressLine2}
          />
          <CustomTextInput
            name="city"
            onChange={handleChange}
            label="City / Town"
            edit={edit.value}
            value={data.city}
          />
          <CustomTextInput
            name="PostalCode"
            onChange={handleChange}
            label="Postal code"
            edit={edit.value}
            value={data.PostalCode}
          />
          {edit.value ? (
            <CustomTextInput
              name="Country"
              onChange={handleChange}
              name="Country"
              select={COUNTRY_DATA}
              svalue={data.Country}
              label="Country"
              selectLabel="Choose"
            />
          ) : (
            <CustomTextInput
              name="Country"
              onChange={handleChange}
              label="Country"
              value={data.Country}
            />
          )}
          <CustomTextInput
            name="Region"
            onChange={handleChange}
            label="Region"
            edit={edit.value}
            value={data.Region}
          />
          <CustomTextInput
            name="business_website"
            onChange={handleChange}
            label="Business Website"
            edit={edit.value}
            value={data.business_website}
          />

          <CustomTextInput
            name="charity_registration_number"
            onChange={handleChange}
            label="Charity registration number"
            edit={edit.value}
            value={data.charity_registration_number}
          />
          <CustomTextInput
            name="company_registration_number"
            onChange={handleChange}
            label="Company registration number"
            edit={edit.value}
            value={data.company_registration_number}
          />
          <CustomTextInput
            name="business_phone_no"
            onChange={handleChange}
            label="Business phone number"
            edit={edit.value}
            value={data.business_phone_no}
          />
        </div>
        <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {edit.value ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={edit.loader}
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
          {edit.loader && <div class="ui active inline loader"></div>}
        </div>
      ) : null}
    </>
  );
};

export default PersonalDetails;
