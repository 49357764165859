import CustomAvatar from "components/Common/CustomAvatar";
import React from "react";
import Details from "./Details";
import InputItemsAC from "./InputItemsAC";
import PriceDropdown from "./PriceDropdown";
import moment from 'moment'
import { useParams } from "react-router-dom";


const MonthlyBill = () => {

  const { id } = useParams()

  const handlePrevent = e => {
    e.preventDefault()
  }


  return (
    <div style={{ userSelect: 'none' }} onContextMenu={(e) => handlePrevent(e)}>
      <div style={{ display: 'flex' }}>
        <div style={{ fontWeight: 'bold', fontSize: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '1' }}>
          Bill date: {moment().format('27-MM-YYYY')}
        </div>
        <CustomAvatar style={{ margin: '8px 0 8px 0' }} />
      </div>
      <Details />
      <PriceDropdown />
      <InputItemsAC />
      {/* <Actions id = {id} /> */}

    </div>
  );
};

export default MonthlyBill;
