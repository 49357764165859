import swal from "sweetalert";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import fetch from "isomorphic-fetch";

import {
  CASH_CLIENT_INTERPRETING_FAIL,
  CASH_CLIENT_QUOTS_FAIL,
  CASH_CLIENT_JOBADVART_REQUEST,
  CASH_CLIENT_QUOTS_REQUEST,
  CASH_CLIENT_JOBADVART_SUCCESS,
  CASH_CLIENT_QUOTS_SUCCESS,
  CASH_CLIENT_JOBADVART_FAIL,
  CASH_CLIENT_INTERPRETING_REQUEST,
  CASH_CLIENT_INTERPRETING_SUCCESS,
  CASH_CLIENT_SIGNIN_FAIL,
  CASH_CLIENT_SIGNIN_REQUEST,
  CASH_CLIENT_SIGNIN_SUCCESS,
  CASH_CLIENT_TRANSLATION_FAIL,
  CASH_CLIENT_TRANSLATION_REQUEST,
  CASH_CLIENT_TRANSLATION_SUCCESS,
} from "constants/cashClient_constant";

import history from "utils/history";

import Cookie from "js-cookie";
const token = Cookie.get("CACtoken");
//cashclient signin

export const signin = (email, password) => async (dispatch) => {
  dispatch({ type: CASH_CLIENT_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await Axios.post(
      "/api/cashclient/signin",
      { email, password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: CASH_CLIENT_SIGNIN_SUCCESS, payload: data });
    // console.log(data);

    swal({
      title: "Welcome!",
      text: `${data.user.FirstName} ${data.user.LastName}`,
      icon: "success",
      buttons: false,
    });

    history.push(`/cash-client/dashboard`);
  } catch (error) {
    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: CASH_CLIENT_SIGNIN_FAIL,
      payload:
        error.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message,
    });
  }
};

//cashclient pre signup
export const preSignup =
  (
    title,
    FirstName,
    LastName,
    email,
    password,
    mobile_no,
    direct_line,
    AddressLine1,
    AddressLine2,
    Birthday,
    sex,
    City,
    PostalCode,
    Region,
    Country,
    Nationality,
    other_nationality,
    business_name,
    position,
    business_email,
    business_phone_no,
    business_address,
    business_address_2,
    business_town,
    business_region,
    business_country,
    charity_registration_no,
    company_registration_no,
    business_website,
    note,
    gdpr,
    currency
  ) =>
  async (dispatch) => {
    dispatch({
      type: CASH_CLIENT_SIGNIN_REQUEST,
      payload: {
        title,
        FirstName,
        LastName,
        email,
        password,
        mobile_no,
        direct_line,
        AddressLine1,
        AddressLine2,
        Birthday,
        sex,
        City,
        PostalCode,
        Region,
        Country,
        Nationality,
        other_nationality,
        business_name,
        position,
        business_email,
        business_phone_no,
        business_address,
        business_address_2,
        business_town,
        business_region,
        business_country,
        charity_registration_no,
        company_registration_no,
        business_website,
        note,
        gdpr,
        currency,
      },
    });
    try {
      // console.log(currency)
      const { data } = await Axios.post(
        "/api/cashclient/pre-signup",
        {
          title,
          FirstName,
          LastName,
          email,
          password,
          mobile_no,
          direct_line,
          AddressLine1,
          AddressLine2,
          Birthday,
          sex,
          City,
          PostalCode,
          Region,
          Country,
          Nationality,
          other_nationality,
          business_name,
          position,
          business_email,
          business_phone_no,
          business_address,
          business_address_2,
          business_town,
          business_region,
          business_country,
          charity_registration_no,
          company_registration_no,
          business_website,
          note,
          gdpr,
          currency,
        }
      );

      dispatch({ type: CASH_CLIENT_SIGNIN_SUCCESS, payload: data });
      if (
        data.message ===
        "Kindly check your email. Confirmation email sent to test3@pratik.mamun.org.uk."
      ) {
        swal({
          title: "Success",
          text: `Kindly check your registered email to activate your account.`,
          icon: "success",
          buttons: false,
        });
        window.location = "/";
      }

      Axios.post(
        "/api/v1/account_links",
        { Country, email }
      )
        .then((res) => {
          if (res.data === "ban") {
            window.location = "/";
          } else {
            let new_url = res.data.url;
            // window.location.href = new_url
            window.location = "/";
          }
        })
        .catch((err) => console.log(err.response));

      // swal({
      //   title: "Success",
      //   text: `${data.message}`,
      //   icon: "success",
      //   buttons: false,
      // });
      // window.location = "/";
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
      dispatch({
        type: CASH_CLIENT_SIGNIN_FAIL,
        payload:
          error.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message,
      });
    }
  };

//Cash client boooking interpreter
export const booking =
  (
    purchase_order_number,
    timezone,
    date,
    starttime,
    endtime,
    interpreting_service_required,
    house_number_street_name,
    address_line_two,
    region,
    post_code,
    country,
    current_nationality,
    other_nationalities,
    remote_address,
    source_language,
    target_language,
    linguist_skill_level,
    typesof_interpreting_required,
    job_description,
    preferred_interpreter,
    gender,
    security_clearance,
    car_parking,
    additional_info,
    currency,
    scripts,
    agree,
    username
  ) =>
  async (dispatch) => {
    dispatch({
      type: CASH_CLIENT_INTERPRETING_REQUEST,
      payload: {
        purchase_order_number,
        timezone,
        date,
        starttime,
        endtime,
        interpreting_service_required,
        house_number_street_name,
        address_line_two,
        region,
        post_code,
        country,
        current_nationality,
        other_nationalities,
        remote_address,
        source_language,
        target_language,
        linguist_skill_level,
        typesof_interpreting_required,
        job_description,
        preferred_interpreter,
        gender,
        security_clearance,
        car_parking,
        additional_info,
        currency,
        scripts,
        agree,
        username,
      },
    });

    try {
      const { data } = await Axios.post(
        "/api/cashclient/cashclientinterpreting",
        {
          purchase_order_number,
          timezone,
          date,
          starttime,
          endtime,
          interpreting_service_required,
          house_number_street_name,
          address_line_two,
          region,
          post_code,
          country,
          current_nationality,
          other_nationalities,
          remote_address,
          source_language,
          target_language,
          linguist_skill_level,
          typesof_interpreting_required,
          job_description,
          preferred_interpreter,
          gender,
          security_clearance,
          car_parking,
          additional_info,
          currency,
          scripts,
          agree,
          username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((res) => {
        console.log(res.data.message);
      });

      dispatch({ type: CASH_CLIENT_INTERPRETING_SUCCESS, payload: data });
      swal({
        title: "Please check the summary details",
        text: `and pay to confirm your order. `,
        icon: "success",
        buttons: false,
      });
      history.push("book-interpreter/summary");
    } catch (error) {
      dispatch({
        type: CASH_CLIENT_INTERPRETING_FAIL,
        payload:
          error.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message,
      });
    }
  };

//order translation
export const translationrequest =
  (
    wordCount,
    purchase_order_number,
    document_type,
    other_details,
    source_language,
    target_language,
    expertise_level_required,
    require_visual_formating,
    certified_translation,
    certificate,
    additional_quality_check,
    provider_id_number,
    additional_note,
    timezone,
    deadline,
    agree,
    username
  ) =>
  async (dispatch) => {
    dispatch({
      type: CASH_CLIENT_TRANSLATION_REQUEST,
      payload: {
        wordCount,
        purchase_order_number,
        document_type,
        other_details,
        source_language,
        target_language,
        expertise_level_required,
        require_visual_formating,
        certified_translation,
        certificate,
        additional_quality_check,
        provider_id_number,
        additional_note,
        timezone,
        deadline,
        agree,
        username,
      },
    });

    try {
      const { data } = await Axios.post(
        "/api/cashclient_translation",
        {
          wordCount,
          purchase_order_number,
          document_type,
          other_details,
          source_language,
          target_language,
          expertise_level_required,
          require_visual_formating,
          certified_translation,
          certificate,
          additional_quality_check,
          provider_id_number,
          additional_note,
          timezone,
          deadline,
          agree,
          username,
        }
      );
      // console.log(data);
      swal({
        title: "success!",
        text: `${data.message}`,
        icon: "success",
        buttons: false,
      });

      dispatch({ type: CASH_CLIENT_TRANSLATION_SUCCESS, payload: data });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
      dispatch({
        type: CASH_CLIENT_TRANSLATION_FAIL,
        payload:
          error.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message,
      });
    }
  };

// //cash client quotes request
export const job_advart =
  (
    purchase_order_number,
    remote_address,
    job_title,
    salary,
    contract_type,
    hours_per_week,
    house_street_no_name,
    address_line_two,
    town_city,
    post_code,
    county_region,
    country,
    current_nationality,
    other_nationalities,
    job_ref,
    source_language,
    target_language,
    additional_language,
    linguist_skills_level,
    about_company,
    job_description,
    application_closing_date,
    nhs_trust,
    registration_number,
    agree,
    username
  ) =>
  async (dispatch) => {
    dispatch({
      type: CASH_CLIENT_JOBADVART_REQUEST,
      payload: {
        purchase_order_number,
        remote_address,
        job_title,
        salary,
        contract_type,
        hours_per_week,
        house_street_no_name,
        address_line_two,
        town_city,
        post_code,
        county_region,
        country,
        current_nationality,
        other_nationalities,
        job_ref,
        source_language,
        target_language,
        additional_language,
        linguist_skills_level,
        about_company,
        job_description,
        application_closing_date,
        nhs_trust,
        registration_number,
        agree,
        username,
      },
    });
    try {
      const { data } = await Axios.post(
        `/api/cashclient/jobadvert`,
        {
          purchase_order_number,
          remote_address,
          job_title,
          salary,
          contract_type,
          hours_per_week,
          house_street_no_name,
          address_line_two,
          town_city,
          post_code,
          county_region,
          country,
          current_nationality,
          other_nationalities,
          job_ref,
          source_language,
          target_language,
          additional_language,
          linguist_skills_level,
          about_company,
          job_description,
          application_closing_date,
          nhs_trust,
          registration_number,
          agree,
          username,
        }
      );
      dispatch({ type: CASH_CLIENT_JOBADVART_SUCCESS, payload: data });
      // console.log(data);
      swal({
        title: "Welcome!",
        text: `${data}`,
        icon: "success",
        buttons: false,
      });

      history.push("/form");
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
      dispatch({
        type: CASH_CLIENT_JOBADVART_FAIL,
        payload:
          error.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message,
      });
    }
  };

//cashclient quotes request
export const QuotesRequest =
  (
    purchase_order_number,
    quote_rqst_for,
    house_street_no_name,
    address_line_two,
    town_city,
    post_code,
    county_region,
    country,
    other_details,
    request_details,
    project_fix_budget,
    documents,
    source_language,
    target_language,
    skills_required,
    provider_id_number,
    gender,
    timezone,
    quote_by,
    pay_type,
    gdpr,
    username
  ) =>
  async (dispatch) => {
    dispatch({
      type: CASH_CLIENT_QUOTS_REQUEST,
      payload: {
        purchase_order_number,
        quote_rqst_for,
        house_street_no_name,
        address_line_two,
        town_city,
        post_code,
        county_region,
        country,
        other_details,
        request_details,
        project_fix_budget,
        documents,
        source_language,
        target_language,
        skills_required,
        provider_id_number,
        gender,
        timezone,
        quote_by,
        pay_type,
        gdpr,
        username,
      },
    });

    // console.log("data");
    try {
      const { data } = await Axios.post(
        "/api/cashclient/request/quote",
        {
          purchase_order_number,
          quote_rqst_for,
          house_street_no_name,
          address_line_two,
          town_city,
          post_code,
          county_region,
          country,
          other_details,
          request_details,
          project_fix_budget,
          documents,
          source_language,
          target_language,
          skills_required,
          provider_id_number,
          gender,
          timezone,
          quote_by,
          pay_type,
          gdpr,
          username,
        }
      );
      dispatch({ type: CASH_CLIENT_QUOTS_SUCCESS, payload: data });
      swal({
        title: "Success!",
        text: `Submited`,
        icon: "success",
        buttons: false,
      });

      history.push(`/cash-client/dashboard`);
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
      dispatch({
        type: CASH_CLIENT_QUOTS_FAIL,
        payload:
          error.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message,
      });
    }
  };

// get cookie
export const getCookie = (key) => {
  if (process.browser) {
    return Cookies.get(key);
  }
};

export const isAuth = () => {
  if (process.browser) {
    const cookieChecked = getCookie("CACtoken");
    if (cookieChecked) {
      if (localStorage.getItem("CACuser")) {
        return JSON.parse(localStorage.getItem("CACuser"));
      } else {
        return false;
      }
    }
  }
};

// booking interpreting list
export const getConfirmBookingList = () => {
  const token = Cookies.get("CACtoken");
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/cashclient/booking-list`,

    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// order translation list
export const getConfirmTranslationList = () => {
  const token = Cookies.get("CACtoken");
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/cashclient/translation-list`,

    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// quotation list
export const getSendQuotationList = () => {
  const token = Cookies.get("CACtoken");
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/cashclient/list/quotations`,

    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// remove cookie
export const removeCookie = (key) => {
  if (process.browser) {
    Cookie.remove(key, {
      expires: 1,
    });
  }
};

// set localstorage
export const setLocalStorage = (key, value) => {
  if (process.browser) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// remove localstorage
export const removeLocalStorage = (key) => {
  if (process.browser) {
    localStorage.removeItem(key);
  }
};

export const signout = (next) => {
  removeCookie("CACtoken");
  removeLocalStorage("CACuser");
  next();

  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/cashclient/signout`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      console.log("Signout Success");
    })
    .catch((err) => console.log(err));
};

export const handleResponse = (response) => {
  if (response.status === 401) {
    signout(() => {
      history.push({
        pathname: "/cash-client-login",
        query: {
          message: "Your session is expired. Please signin again",
        },
      });
    });
  }
};

export const getProfile = (token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/cashclient/dashboard`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const update = (token, user) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/cashclient/profile/update`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: user,
    }
  )
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

// update user in local storage
export const updateUser = (user, next) => {
  if (process.browser) {
    if (localStorage.getItem("CACuser")) {
      let auth = JSON.parse(localStorage.getItem("CACuser"));
      auth = user;
      localStorage.setItem("CACuser", JSON.stringify(auth));
      next();
    }
  }
};
