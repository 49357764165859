import React, { useEffect, useState } from "react";

import Axios from "utils/axios";
import { Table } from "semantic-ui-react";
import Action from "./ActionEvent";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";

//Moment js
import moment from "moment";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});
const ProvidedQuotations = ({ id }) => {
  const [events, setEvents] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const loadEventMemberList = async (e) => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/get-events-member-list/eventID/${id}`
      );
      console.log(response);
      setEvents(response?.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    loadEventMemberList();
  }, [id]);

  return events ? (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Firstname</Table.HeaderCell>
                <Table.HeaderCell>Lastname</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Mobile no</Table.HeaderCell>
                <Table.HeaderCell>Organisation</Table.HeaderCell>
                <Table.HeaderCell>Address</Table.HeaderCell>
                <Table.HeaderCell>Comment</Table.HeaderCell>
                <Table.HeaderCell>Registration Date</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {events
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((inv) => (
                  <Table.Row>
                    <Table.Cell>{inv.title}</Table.Cell>
                    <Table.Cell>{inv.first_name}</Table.Cell>
                    <Table.Cell>{inv.last_name}</Table.Cell>
                    <Table.Cell>{inv.email}</Table.Cell>
                    <Table.Cell>{inv.mobile_no}</Table.Cell>
                    <Table.Cell>{inv.organisation}</Table.Cell>
                    <Table.Cell>{inv.address}</Table.Cell>
                    <Table.Cell>{inv.comment}</Table.Cell>
                    <Table.Cell>
                      {moment(inv.createdAt).format("DD-MMM-YYYY : HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      <Action
                        id={inv._id}
                        load={loadEventMemberList}
                        reminder={inv?.reminder.length}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={events.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  ) : (
    "No data found"
  );
};

export default ProvidedQuotations;
