import React from "react";

// Material Core Components
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

// Material Icons
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";

// Custom Components
import AdminDashboardSearch from "components/Users/Search/Admin";
import CACDashboardSearch from "components/Users/Search/CAC";
import BACDashboardSearch from "components/Users/Search/BAC";
import EmployeeDashboardSearch from "components/Users/Search/Employee";
import LINDashboardSearch from "components/Users/Search/LIN";
import SPPODashboardSearch from "components/Users/Search/SPPO";

import history from "utils/history";
import NavPerson from "./NavPerson";
import LINNavNotification from "./Notifications/LINNavNotification";
import CACNavNotification from "./Notifications/CACNavNotification";
import BACNavNotification from "./Notifications/BACNavNotification";
import SPPONavNotification from "./Notifications/SPPONavNotification";
import AdminNavNotifications from "./Notifications/AdminNavNotifications";
import EMPNavNotifications from "./Notifications/EMPNavNotifications";
import NavSettings from "./NavSettings";
import Help from "./Help";
import Contact from "./Contact";
import ToggleButton from "./ToggleButton";
import NavCalendar from "./NavCalendar";

// Custom Styles
import useNavbarStyles from "assets/jss/components/navbarStyle";
import { Fragment } from "react";
import Dashboard from "./Dashboard";
// import LinguistShop from "./LinguistShop";
// import PromotionalMessage from "./PromotionalMessage";

const Navbar = ({ handleDrawerToggle, layoutType }) => {
  const classes = useNavbarStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title} noWrap></Typography>
        <div className={classes.sideDiv}>
          <ToggleButton />
          <Hidden smDown implementation="css">
            {history.location.pathname.includes("admin") ? (
              <AdminDashboardSearch />
            ) : history.location.pathname.includes("employee") ? (
              <EmployeeDashboardSearch />
            ) : history.location.pathname.includes("cashclient") ||
              history.location.pathname.includes("cash-client") ? (
              <CACDashboardSearch />
            ) : history.location.pathname.includes("account-client") ? (
              <BACDashboardSearch />
            ) : history.location.pathname.includes("linguist") ? (
              <LINDashboardSearch />
            ) : (
              history.location.pathname.includes("sppo") && (
                <SPPODashboardSearch />
              )
            )}
          </Hidden>
          <Dashboard layoutType={layoutType} />
          {layoutType === "LINGUIST" ? (
            <LINNavNotification />
          ) : layoutType === "CASH_CLIENT" ? (
            <CACNavNotification />
          ) : layoutType === "SPPO" ? (
            <SPPONavNotification />
          ) : layoutType === "ACCOUNT_CLIENT" ? (
            <BACNavNotification />
          ) : layoutType === "EMPLOYEE_CLIENT" ? (
            <EMPNavNotifications />
          ) : (
            <AdminNavNotifications />
          )}

          <NavCalendar />
          <Fragment>
            {/* {!["ACCOUNT_CLIENT", "CASH_CLIENT"].includes(layoutType) ? (
              <Chat />
            ) : null}
            ; */}
          </Fragment>
          <NavPerson layoutType={layoutType} />
          {/* {layoutType === "LINGUIST" ? <PromotionalMessage /> : null} */}

          <NavSettings layoutType={layoutType} />
          <Contact layoutType={layoutType} />
          <Help layoutType={layoutType} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
