import Axios from "utils/axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import Cookies from "js-cookie";
import history from "utils/history";

const UnconfirmQuotationPay = () => {
  const { id } = useParams();
  const token = Cookies.get("CACtoken");

  useEffect(() => {
    const payStatus = async (e) => {
      try {
        const response = await Axios.put(
          `/api/cashclient/acceptQuote/linQuoteID/${id}`,
          "",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        swal({
          text: "Payment successfully done",
          icon: "success",
          buttons: "Ok",
        });
        setTimeout(() => {
          history.push(
            "https://linguistscollective.com/cashclient/unconfirmed-quotations"
          );
        }, 2000);
      } catch (error) {
        console.log(error.response);
      }
    };

    payStatus();
  }, []);
  return <div></div>;
};

export default UnconfirmQuotationPay;
