import EditIcon from "@material-ui/icons/Edit";
import CustomTextInput from "components/Common/CustomTextInput";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import {
  BASIC_QS,
  SECURITY_CLEARANCE_LEVEL_SEM_UI,
  SIMPLE_QS,
  SECURITY_CLEARANCE_LEVEL,
} from "data";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import CustomUpload from "components/Common/CustomUpload";
import { Icon } from "semantic-ui-react";

import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";

const SecurityClearance = () => {
  const [edit, setEdit] = useState(false);
  const token = Cookies.get("LINtoken");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updateProfileReducer);
  const handleEdit = (e) => {
    setEdit(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleDropDown = (e, data) => {
    const { name, value } = data;
    dispatch(
      changeLinguistProfileInput({
        name,
        value,
      })
    );
  };

  const handleFiles = async (e) => {
    dispatch(
      changeLinguistProfileInput({
        name: e.target.name,
        value: e.target.files[0],
      })
    );
  };

  const handleUpdate = async (e) => {
    let fd = new FormData();

    fd.append("criminal_record", data.criminal_record);

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/profile/update`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEdit(false);
      swal({
        icon: "success",
        text: "Thank you for updating profile",
        buttons: false,
      });
    } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
        swal({
          icon: "error",
          text: error?.response?.data?.error,
          buttons: false,
          dangerMode: true,
        });
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1" }}>
          <CustomTextInput
            label="Criminal record"
            value={data.criminal_record}
          />
          <CustomTextInput
            label="Criminal record details"
            value={data?.criminal_record_details == "undefined" ? "" : data?.criminal_record_details}
          />

          <hr />
          {edit ? (
            <CustomTextInput
              name="security_clearance"
              onChange={handleChange}
              label="Do you have security / police clearance"
              radio={true}
              rvalue={data.security_clearance}
              data={SIMPLE_QS}
            />
          ) : (
            <CustomTextInput
              label="Do you have security / police clearance"
              value={data.security_clearance}
            />
          )}

          {edit ? (
            <CustomTextInput
              onChange={handleChange}
              name="title_security_clearance_1"
              label="Security clearance"
              select={SECURITY_CLEARANCE_LEVEL}
              svalue={data.title_security_clearance_1}
            />
          ) : (
            <CustomTextInput
              label="Security clearance"
              value={data.title_security_clearance_1}
            />
          )}
          {edit ? (
            <CustomTextInput
              name="security_certificate_expiry_date_1"
              onChange={handleChange}
              label=" Security clearance expiry date"
              dlabel="Expiry date"
              dvalue={data.security_certificate_expiry_date_1}
              date={true}
            />
          ) : (
            <CustomTextInput
              label="Security clearance expiry date"
              value={data.security_certificate_expiry_date_1 ? moment(data.security_certificate_expiry_date_1).format(
                "DD/MM/yyyy"
              ) : ""}
            />
          )}
          {edit ? (
            <input
              type="file"
              name="security_clearance_document_1"
              onChange={handleFiles}
              accept="application/pdf"
            />
          ) : null}
          <a href={`/linguist/linguist-view-security-clearence-1`}>
            View Security Clearence Document
          </a>

          <hr />

          {edit ? (
            <CustomTextInput
              onChange={handleChange}
              name="title_security_clearance_2"
              label="Security clearance"
              select={SECURITY_CLEARANCE_LEVEL}
              svalue={data.title_security_clearance_2}
            />
          ) : (
            <CustomTextInput
              label="Security clearance"
              value={data.title_security_clearance_2}
            />
          )}
          {edit ? (
            <CustomTextInput
              name="security_certificate_expiry_date_2"
              onChange={handleChange}
              label=" Security clearance expiry date"
              dlabel="Expiry date"
              dvalue={data.security_certificate_expiry_date_2}
              date={true}
            />
          ) : (
            <CustomTextInput
              label="Security clearance expiry date"
              value={data.security_certificate_expiry_date_2 ? moment(data.security_certificate_expiry_date_2).format(
                "DD/MM/yyyy"
              ) : ""}
            />
          )}

          {edit ? (
            <input
              type="file"
              name="security_clearance_document_2"
              onChange={handleFiles}
              accept="application/pdf"
            />
          ) : null}
          <a href={`/linguist/linguist-view-security-clearence-2`}>
            View Security Clearence Document
          </a>

          <hr />

          {edit ? (
            <CustomTextInput
              onChange={handleChange}
              name="title_security_clearance_3"
              label="Security clearance"
              select={SECURITY_CLEARANCE_LEVEL}
              svalue={data.title_security_clearance_3}
            />
          ) : (
            <CustomTextInput
              label="Security clearance"
              value={data.title_security_clearance_3}
            />
          )}
          {edit ? (
            <CustomTextInput
              name="security_certificate_expiry_date_3"
              onChange={handleChange}
              label=" Security clearance expiry date"
              dlabel="Expiry date"
              dvalue={data.security_certificate_expiry_date_3}
              date={true}
            />
          ) : (
            <CustomTextInput
              label="Security clearance expiry date"
              value={data.security_certificate_expiry_date_3 ? moment(data.security_certificate_expiry_date_3).format(
                "DD/MM/yyyy"
              ) : ""}
            />
          )}
          {edit ? (
            <input
              type="file"
              name="security_clearance_document_3"
              onChange={handleFiles}
              accept="application/pdf"
            />
          ) : null}
          <a href={`/linguist/linguist-view-security-clearence-3`}>
            {" "}
            View Security Clearence Document
          </a>

          <hr />

          {edit ? (
            <CustomTextInput
              onChange={handleChange}
              name="title_security_clearance_4"
              label="Security clearance"
              select={SECURITY_CLEARANCE_LEVEL}
              svalue={data.title_security_clearance_4}
            />
          ) : (
            <CustomTextInput
              label="Security clearance"
              value={data.title_security_clearance_4}
            />
          )}
          {edit ? (
            <CustomTextInput
              name="security_certificate_expiry_date_4"
              onChange={handleChange}
              label=" Security clearance expiry date"
              dlabel="Expiry date"
              dvalue={data.security_certificate_expiry_date_4}
              date={true}
            />
          ) : (
            <CustomTextInput
              label="Security clearance expiry date"
              value={data.security_certificate_expiry_date_4 ? moment(data.security_certificate_expiry_date_4).format(
                "DD/MM/yyyy"
              ) : ""}
            />
          )}
          {edit ? (
            <input
              type="file"
              name="security_clearance_document_4"
              onChange={handleFiles}
              accept="application/pdf"
            />
          ) : null}
          <a href={`/linguist/linguist-view-security-clearence-4`}>
            View Security Clearence Document
          </a>

          <hr />

          <a href={`/linguist/update-linguist-documents`}>
            Upload Documents
          </a>

        </div>
        {/* <div style={{ flex: "0" }}>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div> */}
      </div>

      {edit ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleUpdate}
            style={{ backgroundColor: "#7C277D" }}
            primary
          >
            Update
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default SecurityClearance;
