import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
// Custom Components
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomInput from "components/Common/CustomInput";
import CustomUpload from "components/Common/CustomUpload";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import Cookie from "js-cookie";
import { DropzoneArea } from "material-ui-dropzone";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import history from "utils/history";
// Others
import {
  CURRENT_COUNTRY,
  GENDER_DATA,
  PAYMENT_QUOTEREQUEST,
  PRIMARY_SOURCE_LANG,
  PRIMARY_TARGET_LANG,
  SECURITY_CLEARANCE_LEVEL,
  QUOTE_REQUEST_FOR,
  SKILL_LEVEL_REQUIRED,
  TIMEZONE,
  RARE_REGEX,
  LEVEL_REGEX
} from "data";

import { changeQuoteInput } from "redux/actions/quoterqstAction";
import CustomSelect from "components/Common/CustomSelect";
import {
  Checkbox,
  FormControlLabel,
  Icon,
  useMediaQuery,
} from "@material-ui/core";

import CustomRadio from "components/Common/CustomRadio";
import CustomDateTime from "components/Common/CustomDateTime";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import moment from "moment";
import { Header } from "semantic-ui-react";
import Axios from "axios";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import swal from "sweetalert";

const PageOne = () => {
  const { data } = useSelector((state) => state.ACquoterqstReducer);
  const dispatch = useDispatch();
  const [deadlineDate, setDeadlineDate] = useState();
  const [deadlineTime, setDeadlineTime] = useState();
  const [loading, setLoading] = useState(false);
  const classesb = useLandingpageStyles();
  const {
    purchase_order_number,
    quote_rqst_for,
    house_street_no_name,
    address_line_two,
    town_city,
    post_code,
    county_region,
    country,
    other_details,
    request_details,
    project_fix_budget,
    security_clearance,
    documents,
    source_language,
    target_language,
    source_skills_required,
    target_skills_required,
    provider_id_number,
    gender,
    timezone,
    quote_by,
    pay_type,
    upload_cv,
    gdpr,
  } = data;
  const matches = useMediaQuery("(max-width:768px)");
  const token = Cookie.get("BACtoken");
  const classes = useFormStyles();
  const { username } = JSON.parse(localStorage.getItem("BACuser"));

  const handleNext = async (e) => {
    swal(
      `
      Please note that you will not be able to return to this page, after submission you will be directed to dashboard. Are you ready to submit?`,
      {
        icon: "warning",
        buttons: ["Cancel", "Yes"],
      }
    ).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        e.preventDefault();
        let fd = new FormData();

        fd.append("purchase_order_number", purchase_order_number);
        fd.append("quote_rqst_for", quote_rqst_for);
        fd.append("house_street_no_name", house_street_no_name);
        fd.append("address_line_two", address_line_two);
        fd.append("town_city", town_city);
        fd.append("post_code", post_code);
        fd.append("county_region", county_region);
        fd.append("country", country);
        fd.append("other_details", other_details);
        fd.append("request_details", request_details);
        fd.append("project_fix_budget", project_fix_budget);
        fd.append("documents", documents);
        fd.append("source_language", source_language);
        fd.append("target_language", target_language);
        fd.append("source_skills_required", source_skills_required);
        fd.append("target_skills_required", target_skills_required);
        fd.append("provider_id_number", provider_id_number);
        fd.append("gender", gender);
        fd.append("timezone", timezone);
        fd.append("quote_by", quote_by);
        fd.append("pay_type", pay_type);
        fd.append("upload_cv", upload_cv);
        fd.append("security_clearance", security_clearance);
        fd.append("gdpr", gdpr);
        fd.append("username", username);

        try {
          const response = Axios.post(
            `${process.env.REACT_APP_BACKEND_API}/accountclient/request/quote`,
            fd,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          //for currency
          setLoading(false);
          swal("Thank you for your quote request!! ", {
            icon: "success",
            buttons: "OK!!",
          }).then(function () {
            window.location = "/account-client/dashboard";
          });
        } catch (error) {
          if (error.response) {
            swal({
              title: "Oops!",
              text: `${error?.response?.data?.error}`,
              icon: "error",
              buttons: false,
            });
          }
        }
      }
    });
  };
  //file upload

  // file upload
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeQuoteInput({
        name,
        value: value,
      })
    );
  };
  const handleChecked = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeQuoteInput({
        name,
        value: checked,
      })
    );
  };

  const handleFiles = (file) => {
    dispatch(
      changeQuoteInput({
        name: "upload_cv",
        value: file[0],
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "country",
        value,
      })
    );
  };

  const handleExpertise = (value) => {
    if (target_language?.includes("R") || source_language?.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(
        LEVEL_REGEX
       );
    }
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "quote_rqst_for",
        value,
      })
    );
  };

  const handleDateChange = (date, time) => {
    setDeadlineDate(date);
    setDeadlineTime(time);
    dispatch(
      changeQuoteInput({
        name: "quote_by",
        value: `${date}T${time}`,
      })
    );
  };

  const handleChangeS = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "source_language",
        value: value,
      })
    );
  };

  const handleChangeT = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "target_language",
        value: value,
      })
    );
  };
  const handleSecurityChange = (e, value) => {
    dispatch(
      changeQuoteInput({
        name: "security_clearance",
        value: value,
      })
    );
  };

  const isAllow =
    !quote_rqst_for.length ||
    !request_details ||
    !project_fix_budget ||
    !source_language ||
    !target_language ||
    !target_skills_required ||
    !source_skills_required ||
    !deadlineDate ||
    !deadlineTime ||
    !gdpr;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          label="Your booking ref/ Purchase order no"
          name="purchase_order_number"
          value={purchase_order_number}
          placeholder="Enter your reference number or purchase order number"
        />
        <CustomAutoComplete
          onChange={handleAutoComplete}
          label="Quote Request for"
          name="quote_rqst_for"
          data={QUOTE_REQUEST_FOR}
          value={quote_rqst_for}
          placeholder="Choose"
          required
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="House Number/Name, Street Name"
          moreInfo="Venue address"
          width="100%"
          name="house_street_no_name"
          value={house_street_no_name}
          placeholder="e.g. Flat/Building Name, 101 Example Street"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Address line 2"
          width="100%"
          name="address_line_two"
          value={address_line_two}
          placeholder="Additional Address Information"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Town / City"
          width="100%"
          name="town_city"
          value={town_city}
          placeholder="Your Village, Town or City Name"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="Post / Zip Code"
          width="100%"
          name="post_code"
          value={post_code}
          placeholder="Your Post Code, Zip Code or Post Office Name"
        />
        <CustomInputSingle
          onChange={handleChange}
          label="County / Region"
          width="100%"
          name="county_region"
          value={county_region}
          placeholder="Your County, Division or State Name"
        />
        <CustomAutoSelect
          onChange={handleAutoCompleteCO}
          label="Country"
          moreInfo="Venue country"
          width="100%"
          name="country"
          value={country}
          data={CURRENT_COUNTRY}
        />
        {!matches ? (
          <CustomInputSingle
            onChange={handleChange}
            label="Other / Remote Interpreting (RI) / Video Remote Interpreting (VRI)"
            name="other_details"
            value={other_details}
            placeholder="How linguist will join you? e.g. Zoom, Microsoft Team link, conference phone number etc."
          />
        ) : (
          <CustomInputSingle
            onChange={handleChange}
            label="Other / Remote Interpreting (RI) / Video Remote Interpreting (VRI)"
            name="other_details"
            value={other_details}
            style={{ paddingTop: 20 }}
            placeholder="How linguist will join you? e.g. Zoom, Microsoft Team link, conference phone number etc."
          />
        )}

        <CustomInput
          onChange={handleChange}
          label="Details of your request"
          name="request_details"
          value={request_details}
          required
          placeholder="Provide details for interpreter e.g. solicitor conference, police interview, visiting GP etc."
        />
        {!matches ? (
          <CustomBigInputSingle
            onChange={handleChange}
            label="Do you have a fixed budget for this assignment ?"
            moreInfo="If so, please provide details here including currency"
            name="project_fix_budget"
            value={project_fix_budget}
            required
            placeholder="Provide details here including currency"
          />
        ) : (
          <CustomBigInputSingle
            onChange={handleChange}
            label="Do you have a fixed budget for this assignment ?"
            moreInfo="If so, please provide details here including currency"
            name="project_fix_budget"
            value={project_fix_budget}
            required
            style={{ paddingTop: 20 }}
            placeholder="Provide details here including currency"
          />
        )}
        <br />

        <DropzoneArea
          acceptedFiles={[".jpg, .jpeg, .png, .pdf"]}
          onChange={handleFiles}
          showFileNames
          dropzoneText="Drag & Drop here or Click to add any script or instruction to this form - (PDF or photo / JPEG file only, Max 1 file, 5MB, contact us for help "
          showAlerts={true}
          clearOnUnmount
          maxFileSize={5000000}
          showPreviewsInDropzone
          showFileNamesInPreview
          filesLimit={1}
        />
        {/* <div style={{ display: "flex", alignItems: "flex-end" }}>
          <CustomUpload
            onChange={handleFiles}
            accept=".pdf,.docx,.jpeg"
            width="100%"
            label="Upload any script or instruction here, pdf or photos/JPEG only)"
            name="documents"
            value={documents}
          />{" "}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button color="secondary">
            {upload_cv ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Icon name="file pdf" size="big" />
                <p
                  style={{
                    margin: "1",
                    marginTop: "10px",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  {upload_cv.name}
                </p>
                <p>{upload_cv?.lastModifiedDate?.toDateString()} </p>
                <p style={{ margin: "0" }}>
                  {(upload_cv.size / 1024).toFixed(2)}Kb{" "}
                </p>
              </div>
            ) : null}
          </Button>
        </div> */}

        <CustomAutoSelect
          onChange={handleChangeS}
          label="Source/Native language"
          data={PRIMARY_SOURCE_LANG.filter(
            (value) => value !== target_language
          )}
          width="100%"
          name="source_language"
          value={source_language}
          required
        />
        {!matches ? (
          <CustomSelect
            onChange={handleChange}
            label="Please indicate linguist's source language skills level required"
            moreInfo="Please indicate linguist's source language skills level required for this assignment (1 - 5)"
            data={SKILL_LEVEL_REQUIRED.filter((value) =>
              handleExpertise(value)
            )}
            required
            width="100%"
            name="source_skills_required"
            value={source_skills_required}
          />
        ) : (
          <CustomSelect
            onChange={handleChange}
            label="Please indicate linguist's source language skills level required"
            moreInfo="Please indicate linguist's source language skills level required for this assignment (1 - 5)"
            data={SKILL_LEVEL_REQUIRED.filter((value) =>
              handleExpertise(value)
            )}
            required
            width="100%"
            name="source_skills_required"
            value={source_skills_required}
            style={{ marginTop: 50 }}
          />
        )}

        <CustomAutoSelect
          onChange={handleChangeT}
          label="Target language"
          data={PRIMARY_TARGET_LANG.filter(
            (value) => value !== source_language
          )}
          width="100%"
          name="target_language"
          value={target_language}
          required
        />
        {!matches ? (
          <CustomSelect
            required
            onChange={handleChange}
            label="Please indicate linguist's target language skills level required"
            moreInfo="Please indicate linguist's target language skills level required for this assignment (1 - 5). Target language skills level is an element for price calculation."
            data={SKILL_LEVEL_REQUIRED.filter((value) =>
              handleExpertise(value)
            )}
            width="100%"
            name="target_skills_required"
            value={target_skills_required}
          />
        ) : (
          <CustomSelect
            required
            onChange={handleChange}
            label="Please indicate linguist's target language skills level required"
            moreInfo="Please indicate linguist's target language skills level required for this assignment (1 - 5). Target language skills level is an element for price calculation."
            data={SKILL_LEVEL_REQUIRED.filter((value) =>
              handleExpertise(value)
            )}
            width="100%"
            name="target_skills_required"
            value={target_skills_required}
            style={{ marginTop: 50 }}
          />
        )}
        {!matches ? (
          <CustomBigInputSingle
            onChange={handleChange}
            label="Preferred Linguist / Service provider ID number"
            width="100%"
            name="provider_id_number"
            moreInfo="LC will try its best, but cannot guarantee"
            value={provider_id_number}
            placeholder="Provide details of the preferred linguist e.g. LIN/PRO12345 - Jane Miller"
          />
        ) : (
          <CustomBigInputSingle
            onChange={handleChange}
            label="Preferred Linguist / Service provider ID number"
            width="100%"
            name="provider_id_number"
            moreInfo="LC will try its best, but cannot guarantee"
            value={provider_id_number}
            style={{ paddingTop: 20 }}
            placeholder="Provide details of the preferred linguist e.g. LIN/PRO12345 - Jane Miller"
          />
        )}
        {!matches ? (
          <CustomAutoSelect
            onChange={handleSecurityChange}
            label="Please indicate security clearance level required*"
            moreInfo="Please indicate security clearance level required for this assignment"
            data={SECURITY_CLEARANCE_LEVEL}
            width="100%"
            name="security_clearance"
            value={security_clearance}
          />
        ) : (
          <CustomAutoSelect
            onChange={handleSecurityChange}
            label="Please indicate security clearance level required*"
            moreInfo="Please indicate security clearance level required for this assignment"
            data={SECURITY_CLEARANCE_LEVEL}
            width="100%"
            name="security_clearance"
            value={security_clearance}
            style={{ marginTop: 50 }}
          />
        )}

        <CustomRadio
          onChange={handleChange}
          label="Preferred gender"
          moreInfo="LC will try, but cannot guarantee"
          data={GENDER_DATA}
          width="100%"
          name="gender"
          value={gender}
        />
        <CustomSelect
          onChange={handleChange}
          label="Select timezone"
          width="100%"
          data={TIMEZONE}
          name="timezone"
          value={timezone}
          //helperText= {moment.tz.guess()}
        />
        <div style={{ margin: 10 }}>
          <Header as="h5">
            When do you need this quote by (date and time)? *
          </Header>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <input
              style={{
                width: "90%",
                height: "2.8rem",
                fontSize: "1rem",
              }}
              min={moment().format("YYYY-MM-DD")}
              type="date"
              onChange={(e) => handleDateChange(e.target.value, deadlineTime)}
            />

            <input
              style={{
                width: "90%",
                height: "2.8rem",
                fontSize: "1rem",
              }}
              type="time"
              min={moment().format("YYYY-MM-DD")}
              onChange={(e) => handleDateChange(deadlineDate, e.target.value)}
            />
          </div>
        </div>
        {/* <CustomDateTime
          onChange={handleDateChange}
          width="100%"
          label="When do you need this quote by (date and time)?*"
          name="quote_by"
          value={quote_by}
          required
        /> */}
        {!matches ? (
          <CustomSelect
            onChange={handleChange}
            width="100%"
            label="How do you intend to pay for this service?"
            name="pay_type"
            value={pay_type}
            data={PAYMENT_QUOTEREQUEST}
          />
        ) : (
          <CustomSelect
            onChange={handleChange}
            width="100%"
            label="How do you intend to pay for this service?"
            name="pay_type"
            value={pay_type}
            data={PAYMENT_QUOTEREQUEST}
            style={{ marginTop: 50 }}
          />
        )}
        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <p>
            <Checkbox
              onChange={handleChecked}
              checked={gdpr}
              name="gdpr"
              color="primary"
            />
            By submitting this form I agree with Linguists Collective{" "}
            <a href="https://linguistscollective.com/account-client/service-agreement-of-account-client">
              {" "}
              Service Agreement{" "}
            </a>
            ,
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/privacy-policy"
            >
              Privacy Policy{" "}
            </a>
            and{" "}
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/terms-and-conditions"
            >
              {" "}
              T&C
            </a>
          </p>
        </div>
        {/* <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChecked}
                checked={gdpr}
                name="gdpr"
                color="primary"
              />
            }
            label="By submitting this form I gdpr with LC Privacy Policy and T&C"
          />
        </div> */}

        <div className={classes.buttonFlex}>
          <CustomButton
            disabled={isAllow}
            className={`${classesb.button}`}
            text="Submit"
            onClick={handleNext}
          />
        </div>
      </form>
    </div>
  );
};

export default PageOne;
