import React from "react";
import AccountClientLayout from "layout/AccountClientLayout";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
// Custom Components

// Custom Styles
import ViewAttach from "components/Admin/Communication/ViewAttachTicket";


const ViewContactCard = () => {

  return (
    <Accountclient>
      <AccountClientLayout>

        <ViewAttach />

      </AccountClientLayout>
    </Accountclient>
  );
};

export default ViewContactCard;
