import React, { Fragment } from "react";

// Custom Components
import ClientAcc from "components/Auth/ClientBooking/ClientAcc";

const ClientBookingPage = () => {
  return (
    <Fragment>
      <ClientAcc />
    </Fragment>
  );
};

export default ClientBookingPage;
