import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { useMediaQuery } from "@material-ui/core";
import TwoCards from "./TwoCards";
import { Container, Typography } from "@material-ui/core";
import history from "utils/history";


function Footer({ id, refID, incAccess }) {

  const classes = useLandingpageStyles();
  const matches = useMediaQuery("(max-width:425px)");

  const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary">
        {"© "}
        Linguists Collective Ltd<sup>®</sup> {new Date().getFullYear()}
      </Typography>
    );
  };

  return (
    <>
      <div
        style={{
          marginBottom: "10px",
          width: "100%",
          padding: "30px",
        }}
      >
        {incAccess ?
          <TwoCards objID={id} refID={refID} /> : null}
        {matches ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              width: "100%",
            }}
          >
            {incAccess ?
              <Button
                style={{ marginBottom: 10, marginRight: 5 }}
                onClick={() => history.push(`/inhouse-client-form/${id}`)}
                color="purple"
              >
                Register with me (INC)
              </Button> : null}
            <Button
              color="purple"
              style={{ marginBottom: 10 }}
              onClick={() =>
                window.open(
                  `https://linguistscollective.com/add-new-customer/${id}`,
                  "_blank"
                )
              }
            >
              {" "}
              Leave your contact details
            </Button>

            <Button
              style={{ marginBottom: 10, marginRight: 5 }}
              color="purple"
              onClick={() =>
                window.open(
                  "https://linguistscollective.com/post-job-advert",
                  "_blank"
                )
              }
            >
              Post a job advert
            </Button>
            <Button
              style={{ marginBottom: 10 }}
              onClick={() =>
                window.open(
                  "https://www.facebook.com/LinguistsCollective/",
                  "_blank"
                )
              }
              color="facebook"
            >
              <Icon name="facebook" /> Facebook
            </Button>

            <Button
              style={{ marginBottom: 10, marginRight: 5 }}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/linguistscollective",
                  "_blank"
                )
              }
              color="linkedin"
            >
              <Icon name="linkedin" /> LinkedIn
            </Button>

            <Button
              style={{ marginBottom: 10 }}
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCy_sIqjGtj4wRFv8f45Qyig",
                  "_blank"
                )
              }
              color="youtube"
            >
              <Icon name="youtube" /> YouTube
            </Button>
          </div>
        ) : (
          <>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "33% 33% 33%",
                width: "100%",
              }}
            >
              {incAccess ?
                <Button
                  onClick={() => history.push(`/inhouse-client-form/${id}`)}
                  color="purple"
                >
                  Register with me (INC)
                </Button> : null}
              <Button
                color="purple"
                onClick={() =>
                  window.open(
                    `https://linguistscollective.com/add-new-customer/${id}`,
                    "_blank"
                  )
                }
              >
                {" "}
                Leave your contact details
              </Button>

              <Button
                color="purple"
                onClick={() =>
                  window.open(
                    "https://linguistscollective.com/post-job-advert",
                    "_blank"
                  )
                }
              >
                Post a job advert
              </Button>
            </div>

            <div
              style={{
                marginTop: 10,
                display: "grid",
                gridTemplateColumns: "33% 33% 33% ",
                width: "100%",
              }}
            >
              <Button
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/LinguistsCollective/",
                    "_blank"
                  )
                }
                color="facebook"
              >
                <Icon name="facebook" /> Facebook
              </Button>

              <Button
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/linguistscollective",
                    "_blank"
                  )
                }
                color="linkedin"
              >
                <Icon name="linkedin" /> LinkedIn
              </Button>

              <Button
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCy_sIqjGtj4wRFv8f45Qyig",
                    "_blank"
                  )
                }
                color="youtube"
              >
                <Icon name="youtube" /> YouTube
              </Button>
            </div>
          </>
        )}
      </div>

      <footer className={classes.footer}>
        <div
          style={{
            width: "100%",
            margin: "auto",
            padding: "1rem 7rem 1rem",
            display: "grid",
            gridTemplateColumns: "50% 50%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Container style={{ padding: "0px", margin: "0px" }} maxWidth="sm">
            <Copyright />
          </Container>
          <div className={classes.signature}>
            <p>
              Builder:
              {/* Signature by */}{" "}
              <a href="https://cambridgeitconsultancy.co.uk/">{"{CITC} "}</a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
