import { useHistory } from "react-router-dom";
import React from "react";
// Materials Core
import { Button } from 'semantic-ui-react'
import { makeStyles } from "@material-ui/core/styles";



const styles = {
  root: {
    display: "flex",
    // alignItems: "center",

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const InvoiceOptions = ({ sppoID }) => {

  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Button color="purple"
        variant="outlined"
        onClick={() =>
          history.push(`/sppo/my-clients`)
        }
      >
        Our INC Clients
      </Button>
      <Button color="purple"
        variant="outlined"
        onClick={() =>
          window.open(`/add-new-customer/${sppoID}`)
        }
      >
        + Add Lead
      </Button>
      <Button color="purple"
        variant="outlined"
        onClick={() => history.push("/sppo/send-cla-registration-form")}
      >
        Send Registration Form
      </Button>
      <Button color="purple"
        variant="outlined"
        onClick={() =>
          window.open("/contact-removal-request", "_blank")
        }
      >
        Removal Form
      </Button>

    </div>
  );
};

export default InvoiceOptions;
