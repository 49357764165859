import React from "react";
import { Button } from "semantic-ui-react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
//Axios

//Cookies




const Action = ({ id, report, reportID, invoice, invoiceID, clientReport, linReport, adminReport }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };




  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      // style={{
      //   minWidth: "10px",
      //   paddingLeft: "0px",
      // }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!report ?
          <MenuItem onClick={() => history.push(`accepted-quote/quotation/create-project-report/${id}`)}>Close & Project Report</MenuItem>
          : null}

        {report && !linReport ?
          <MenuItem onClick={() => history.push(`accepted-quote/quotation/confirm-project-report/${reportID}`)}>Confirm Project Report</MenuItem>
          : null}

        {report ?
          <MenuItem onClick={() => history.push(`accepted-quote/quotation/view-project-report/${reportID}`)}>View Project Report</MenuItem>
          : null}

        {report && !invoice ?
          <MenuItem onClick={() => history.push(`accepted-quote/quotation/create-invoice/${reportID}`)}>Create Invoice</MenuItem>
          : null}

        {invoice ?
          <MenuItem onClick={() => history.push(`accepted-quote/quotation/view-invoice/${invoiceID}`)}>View Invoice</MenuItem>
          : null}

      </Menu>
    </div>
  );
};

export default Action;
