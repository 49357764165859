import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import { useHistory, useParams } from 'react-router-dom';

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Profile from "components/AccountClient/AddAnUser/ProfileUpdate/ProfileUpdate"
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from "@material-ui/core";



const ViewCashClient = () => {
  const history = useHistory()
  const {id} = useParams()
  const classes = useDashboardStyles();

  return (

    <AccountClientLayout>
      <Accountclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
            <CardHeader style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }} color="primary">
                <p className={classes.cardTitleWhite}>User Profile</p>
                <IconButton color='inherit' onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
              </CardHeader>
            
            </Card>
            <Card>
              <CardBody>
                  <Profile id={id} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Accountclient>
    </AccountClientLayout>

  );
};

export default ViewCashClient;
