import { CHANGE_INHOUSE_CLIENT_INPUT, UPDATE_INHOUSE_CLIENT_STEP } from "./actionTypes";

export const changeInhouseClientInput = data => ({
    type: CHANGE_INHOUSE_CLIENT_INPUT,
    payload: data
})

export const updateInhouseClientStep = data => ({
    type: UPDATE_INHOUSE_CLIENT_STEP,
    payload: data
})