import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomButton from "components/Common/CustomButton";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomUpload from "components/Common/CustomUpload";
import TermsAndConditions from "./TermsAndConditions";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import {
  autoSaveER,
  changeErInput,
  updateErStep,
} from "redux/actions/erAction";
import CustomCaptcha from "components/Common/CustomCaptcha";

const FormEight = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.erReducer);

  const { note, upload_cv, relevant_documents, special_needs, gdpr } = data;

  useEffect(() => {
    const temp = JSON.parse(localStorage.getItem("userER"));
    dispatch(
      autoSaveER({
        temp,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("userER", JSON.stringify(data));
  }, [data]);

  const classes = useFormStyles();

  const handleBack = () => {
    dispatch(updateErStep(7));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(changeErInput({ name, value }));
  };

  const handleCaptcha = async (value) => {
    // const isHuman = await fetch(`https://www.google.com/recaptcha/api/siteverify`, {
    //   method: "post",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
    //   },
    //   mode: 'no-cors',
    //   credentials: 'omit',
    //   body: `secret=6LdPUwMaAAAAAK7JyeaYyRPz4NeNyC2XoQthvK8h&response=6LdPUwMaAAAAALqroyySr9kV-TXA7sxKx2qzvSnC`
    // })
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInput
          onChange={handleChange}
          value={note}
          name="note"
          label="Would you like to add any other information or note?"
        />
        <CustomUpload
          onChange={handleChange}
          value={upload_cv}
          name="upload_cv"
          label="Upload your CV"
        />
        {/* (developer- allow more option here*) */}
        <CustomUpload
          onChange={handleChange}
          value={relevant_documents}
          name="relevant_documents"
          label="Upload any other documents"
        />
        {/* (Developer -make option here for Associate to share with company only, customer, everyone or none) */}
        <CustomBigInput
          onChange={handleChange}
          name="special_needs"
          value={special_needs}
          label="Do you have any disability or special needs"
          moreInfo="if yes do you need any adjustment to be made for you? you can share this information with customer."
        />
        <TermsAndConditions />

        <CustomCaptcha onChange={handleCaptcha} />

        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton disabled={!gdpr} text="Submit" />
        </div>
      </form>
    </div>
  );
};

export default FormEight;
