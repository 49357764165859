import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

//Material icons

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

// import Details from './Details'
import { Dimmer, Header, Loader, Statistic, Table } from "semantic-ui-react";
import Axios from "axios";
import moment from 'moment'
//Stripe


const ViewINCInvoice = () => {
  const classes = useEmployeeStyles();
  const { id } = useParams()
  const [invoice, setInvoice] = useState(null)

  const [companyCard, setCompanyCard] = useState({
    basc: '',
    pbsc: '',
    cname: '',
    caddress: ''
  })


  useEffect(() => {
    let source = Axios.CancelToken.source()

    const loadInvoice = async () => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/linguists/private-invoice-view/invoiceID/${id}`)

        setInvoice(response?.data)
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log('Request cancelled')
        } else {
          console.log(error.response)
        }
      }
    }

    const getCompanyCard = async () => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/manager-admin/view/company-information-card`)

        setCompanyCard({ ...companyCard, pbsc: response?.data[0].platform_booking_service_charge, basc: response?.data[0].billing_account_service_charge, cname: response?.data[0].company_name, caddress: response?.data[0].company_address })
      } catch (error) {
        console.log(error)
      }
    }

    loadInvoice();
    getCompanyCard();

    return () => {
      source.cancel();
    }
  }, [])



  return (
    invoice ?
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem id='printArea' xs={12} sm={12} md={12} lg={8} xl={8}>
            <Card >
              <CardBody>
                {/* Avatar and company name */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '24px' }}>

                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {Number(invoice?.grand_total).toFixed(2) == 0 ? (
                      <Statistic color="green">
                        <Statistic.Value text>
                          PAID
                          <br />
                        </Statistic.Value>
                      </Statistic>
                    ) : Number(invoice?.grand_total).toFixed(2) < 0 ? (
                      <Statistic color="purple">
                        <Statistic.Value text>
                          OVERPAID
                          <br />
                        </Statistic.Value>
                      </Statistic>
                    ) : invoice.status == "PAID" ? (
                      <Statistic color="green">
                        <Statistic.Value text>
                          {invoice.status}
                          <br />
                        </Statistic.Value>
                      </Statistic>
                    ) : invoice.status == "Paid" ? (
                      <Statistic color="green">
                        <Statistic.Value text>
                          {invoice.status}
                          <br />
                        </Statistic.Value>
                      </Statistic>
                    ) : (
                      <Statistic color="red">
                        <Statistic.Value text>
                          {invoice.status}
                          <br />
                        </Statistic.Value>
                      </Statistic>
                    )}
                    <CustomAvatar style={{ margin: '0', marginLeft: '10px' }} />
                  </div>
                </div>

                {/* First div  */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.privateClientID.id}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.privateClientID?.FirstName}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.privateClientID?.AddressLine1}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.privateClientID?.AddressLine2}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.privateClientID?.City}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.privateClientID?.Region}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.privateClientID?.PostalCode}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.privateClientID?.Country}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.privateClientID?.email}</Header>
                    </div>

                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header style={{ margin: '0' }} as='h4'>Invoice number:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice?.id}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header style={{ margin: '0' }} as='h4'>Linguist/SP ID number:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice?.postedBy?.id}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header style={{ margin: '0' }} as='h4'>Linguist Residence:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice?.postedBy?.Country}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header style={{ margin: '0' }} as='h4'>VAT/GST no:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice?.postedBy?.vat_number}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header style={{ margin: '0' }} as='h4'>Date of invoice:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{moment(invoice.createdAt).format("DD/MMM/YYYY")}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header style={{ margin: '0' }} as='h4'>Invoice to be paid by:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{moment(invoice.invoice_tobe_paid_by).format("DD/MMM/YYYY")}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header style={{ margin: '0' }} as='h4'>Client reference:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.client_ref}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header style={{ margin: '0' }} as='h4'>Service completion date:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{moment(invoice.service_completion_date).format("DD/MMM/YYYY")}</Header>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                      <Header style={{ margin: '0' }} as='h4'>Linguist/SP ref:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice.linguist_ref}</Header>
                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Header style={{ margin: '0' }} as='h4'>Service:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice?.projectID?.prcDirectQuoteID?.service}</Header>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Header style={{ margin: '0' }} as='h4'>Linguist source language and expertise level:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice?.source_language} {invoice?.target_language_level}</Header>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Header style={{ margin: '0' }} as='h4'>Linguist target language and expertise level:</Header><Header color='grey' style={{ margin: '0' }} as='h4'>&nbsp;{invoice?.target_language} {invoice?.target_language_level}</Header>
                  </div>

                </div>

                <Table color='pink'>
                  <Table.Header>

                    <Table.Row>
                      <Table.HeaderCell width='5'>Description of service</Table.HeaderCell>
                      <Table.HeaderCell width='1'>Price</Table.HeaderCell>
                      <Table.HeaderCell width='1'>Quantity</Table.HeaderCell>
                      <Table.HeaderCell width='1'>Total</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>

                    {invoice?.productOne?.map(tr => (
                      <Table.Row>
                        <Table.Cell><Header as='h4'>{tr.text}</Header></Table.Cell>
                        <Table.Cell><Header as='h4'>{tr.amount}</Header></Table.Cell>
                        <Table.Cell><Header as='h4'>{tr.quantity}</Header></Table.Cell>
                        <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                          <Header style={{ margin: '0' }} as='h4'>{tr.total}</Header>

                        </Table.Cell>
                      </Table.Row>
                    ))}

                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign><Header textAlign='right' as='h3'>Sub Total</Header></Table.Cell>
                      <Table.Cell>
                        <Statistic size='mini'>
                          <Statistic.Value>{invoice.currency?.substring(0, 3)} {invoice.subtotal1}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Expenses and travel</Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                    {invoice?.productTwo?.map(tr => (
                      <Table.Row>
                        <Table.Cell><Header as='h4'>{tr.otherText}</Header></Table.Cell>
                        <Table.Cell><Header as='h4'>{tr.otherAmount}</Header></Table.Cell>
                        <Table.Cell><Header as='h4'>{tr.otherQuantity}</Header></Table.Cell>
                        <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                          <Header style={{ margin: '0' }} as='h4'>{tr.total}</Header>

                        </Table.Cell>
                      </Table.Row>
                    ))}

                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign><Header as='h3'>Sub Total</Header></Table.Cell>
                      <Table.Cell>
                        <Statistic size='mini'>
                          <Statistic.Value>{invoice.currency?.substring(0, 3)} {invoice.subtotal2}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign><Header as='h4'>Total</Header></Table.Cell>
                      <Table.Cell>
                        <Statistic size='mini'>
                          <Statistic.Value>{invoice.currency?.substring(0, 3)} {(invoice?.subtotal1 + invoice?.subtotal2)}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign><Header as='h4'>Discount ({invoice.discount_percentage})%</Header></Table.Cell>
                      <Table.Cell>
                        <Statistic size='mini'>
                          <Statistic.Value>{invoice.currency?.substring(0, 3)} {invoice.discount_amount}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign><Header as='h4'>Billing Account Service Charge</Header></Table.Cell>
                      <Table.Cell>
                        <Statistic size='mini'>
                          <Statistic.Value>00.00</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header as='h4'>Platform and booking service charge {invoice.service_charge}%</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size='mini'>
                          <Statistic.Value>{invoice.currency?.substring(0, 3)} {invoice?.service_charge_amount}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign><Header as='h4'>VAT ({invoice.vat_percentage})%</Header></Table.Cell>
                      <Table.Cell>
                        <Statistic size='mini'>
                          <Statistic.Value>{invoice.currency?.substring(0, 3)} {invoice.vat_amount}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign><Header as='h4'>Total</Header></Table.Cell>
                      <Table.Cell>
                        <Statistic size='mini'>
                          <Statistic.Value>{invoice.currency?.substring(0, 3)} {invoice.grand_total}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign><Header as='h4'>Estimate paid in advance</Header></Table.Cell>
                      <Table.Cell>
                        <Statistic size='mini'>
                          <Statistic.Value>00.00</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign><Header as='h4'>Total to be paid including VAT/GST</Header></Table.Cell>
                      <Table.Cell>
                        <Statistic size='mini'>
                          <Statistic.Value>{invoice.currency?.substring(0, 3)} {(Number(invoice.grand_total)).toFixed(2)}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Header style={{ marginTop: '16px' }} as='h4'>Thank You For Your Business</Header>
                  <Header color='grey' style={{ marginBottom: '16px', marginTop: '0' }} as='h4'>Linguists Collective LC, 38 Mill Street, Bedford, Bedfordshire, MK40 3HD</Header>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      :
      <Dimmer active inverted>
        <Loader size='medium'>Loading</Loader>
      </Dimmer>
  );
};

export default ViewINCInvoice;
