const {
  GET_RATES,
  SET_LOADING,
  UPDATE_RATE,
  GET_RATE_DETAILS,
  UPDATE_RATE_DETAILS,
  GET_CARP_DETAILS,
  UPDATE_CARP_DETAILS,
  GET_PERCENTAGE_RATES,
  UPDATE_PERCENTAGE_RATE,
  SET_PERCENT,
  GET_CURRENCY,
  UPDATE_CURRENCY,
  POST_CURRENCY,
  GET_LEAD_DETAILS,
  UPDATE_LEAD_CONTACT_GROUP
} = require("redux/actions/actionTypes");

const INITIAL_STATE = {
  loading: false,
  data: [],
  details: {
    minBookingFTF: {
      hours: "3",
      minutes: "35",
    },
    minBookingRemote: {
      hours: "2",
      minutes: "40",
    },
    overnightPayment: {
      days: "1",
      milesOne: "7",
      milesTwo: "140",
      milesThree: "80",
      milesFour: "70",
    },
    maxBookingDuration: "2",
    serviceCharge: "18.12",
    vatNumber: "10.12",
  },

  currencyDetails: {
    country: {
      GBP: "United kingdom"
    },
    currency: {
      GBP: "GBP"
    },
    code: {
      GBP: "GBP"
    },
    rates: {
      GBP: "1"
    },

  },
  leadDetails: {
    contact_group_name: ""
  },

  carpDetails: {
    cancelChargeHours: "48:00",
    clientCharge: "3",
    clientCancelHours: "20:02",
    onsiteCancellation: "0.44",
    directBookingHours: "08:07",
    directBookingCharge: "1.12",
    clientCancelCharge: "0.98",
    onsiteCancellationCharge: "18.98",
    percentOfTotalCancel: "10.98",
    hoursOfTotalCancel: "2:00",
    percentOfTotalEstimate: "23.56",
    cancelInterpretingHours: "2:00",
    subDetails: "",
  },
  percentData: [],
  selectedPercent: null,
};

const controllerReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PERCENT:
      return {
        ...state,
        selectedPercent: payload,
      };
    case GET_PERCENTAGE_RATES:
      return {
        ...state,
        loading: false,
        percentData: payload,
      };
    case UPDATE_PERCENTAGE_RATE:
      return {
        ...state,
        loading: false,
        percentData: payload,
        selectedPercent: null,
      };
    case GET_RATES:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case GET_RATE_DETAILS:
      return {
        ...state,
        loading: false,
        details: payload,
      };
    case UPDATE_RATE_DETAILS:
      return {
        ...state,
        loading: false,
        details: payload,
      };
    case GET_CURRENCY:
      return {
        ...state,
        loading: false,
        currencyDetails: payload,
      };
    case UPDATE_CURRENCY:
      return {
        ...state,
        loading: false,
        currencyDetails: payload,
      };
    case POST_CURRENCY:
      return {
        ...state,
        loading: false,
        currencyDetails: payload,
      };
      case GET_LEAD_DETAILS:
        return {
          ...state,
          loading: false,
          data: payload,
        };
    case UPDATE_LEAD_CONTACT_GROUP:
      return {
        ...state,
        loading: false,
        leadDetails: payload,
      };
    case GET_CARP_DETAILS:
      return {
        ...state,
        loading: false,
        carpDetails: payload,
      };
    case UPDATE_CARP_DETAILS:
      return {
        ...state,
        loading: false,
        carpDetails: payload,
      };
    case UPDATE_RATE:
      return {
        ...state,
        loading: false,
        data: payload,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};

export default controllerReducer;
