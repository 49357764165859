import React, { Fragment } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import { Card, Divider, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import ThreeCards from "./ThreeCards";

import FTFimage from "assets/icons/face-to-face.png";
import Rimage from "assets/icons/remote.png";
import LCBimage from "assets/images/lc-banner-3.jpg";
import WIimage from "assets/icons/what-interpreter.png";

const Interpreter = () => {
  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "4rem 4rem 0 0" }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div>
            <img src={LCBimage} alt="" style={{ width: "100%" }} />
          </div>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={FTFimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700" }}
                  paragraph
                  variant="h5"
                >
                  Face-to-Face (FTF)
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Do you need a face to face interpreter? Our qualified
                  interpreters come to your organisation for onsite interpreting
                  and for a wide range of face-to-face assignments. Whatever you
                  need, we can provide! We have separated them into various
                  categories to match the linguist qualification and experience
                  to what you need. You can find this in the booking form drop
                  down menu. During the booking process, a client should select
                  the most relevant description that can define language with a
                  specific dialect, types of Interpreting, experience level and
                  field of expertise required for the assignment. If you are not
                  sure of anything, please feel free to contact us for support.
                  We will answer any questions or worries you may have. Register
                  as a <Link to="/customer-registration">Cash Client</Link> or
                  Apply for a{" "}
                  <Link to="/clientacc-opening-form">Billing Account</Link> to
                  buy our services.
                </Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={Rimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700" }}
                  paragraph
                  variant="h5"
                >
                  Remote Interpreting / Video Remote Interpreting (RI/VRI)
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Remote interpreting provides flexibility to our clients and
                  increases the opportunity in finding linguists who are experts
                  in a particularly rare language. During booking, our clients
                  would need to specify how an interpreter should contact you,
                  and providea specific date and time. Linguists Collective
                  avoids providing a system which restricts our clients in any
                  way. We aim to provide full transparency and allow flexibility
                  to use our system to organise meetings or appointments in
                  whatever way suits you. Some of our most regular clients, use
                  Microsoft Team, Google Meet, Zoom, Skype and Cisco Webex for
                  video interpreting, whilst others prefer to use a traditional
                  telephone conference to call linguists and add a conference
                  call. Please be aware, that if a client requests a linguist
                  call from their own phone to a specific number, our linguist
                  may add an additional charge for the telephone call out.
                  Whichever suits you, please indicate your specifications
                  during the booking process and how you will provide any
                  details in joining your appointment or meeting. Remote
                  interpreting booked and requested via the same form as FTF.
                  Book <Link to="/cash-client-login">RI/VRI</Link>.
                </Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <Card>
            <CardBody
              style={{
                padding: "20px",
                backgroundColor: "#B794F4",
                textAlign: "center",
                paddingTop: "0",
              }}
            >
              <div style={{ width: "80px", height: "80px", margin: "auto" }}>
                <img src={WIimage} alt="" style={{ width: "100%" }} />
              </div>
              <Divider />
              <Fragment>
                <Typography
                  style={{ fontWeight: "700" }}
                  paragraph
                  variant="h5"
                >
                  What is interpreting?
                </Typography>
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Interpreting is an oral form of translation, enabling
                  real-time cross-linguistic communication either face-to-face,
                  in a conference setting or over the phone. It can also be
                  called oral translation, real-time translation or personal
                  translation. This is the process where a person repeats what
                  is said out loud in a different language.
                </Typography>
                <br />
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  Interpreting, just like translation, is basically
                  paraphrasing—the interpreter listens to a speaker in one
                  language, grasps the content of what is being said, and then
                  paraphrases his or her understanding of the meaning using the
                  tools of the target language. An interpreter changes words
                  into meaning, and then changes meaning back into words—of a
                  different language.
                </Typography>
                <br />
                <Typography variant="body1" style={{ textAlign: "justify" }}>
                  An interpreter must be able to translate in both directions on
                  the spot, without using dictionaries or other reference
                  materials. Just as you cannot explain to someone a thought if
                  you did not fully understand it, neither can you translate or
                  interpret something without mastery of the subject matter
                  being relayed. To be effective, the interpreter decides how to
                  best convey both the meaning of the words and the context in
                  which those words were used. This requires a high level of
                  expertise in a subject area, current knowledge of different
                  cultures, and a great deal of experience.
                </Typography>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <ThreeCards />
      </div>
    </LandingPageLayout>
  );
};

export default Interpreter;
