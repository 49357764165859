import { Table } from "semantic-ui-react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import { orderBy } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { TableDataDiv } from "./style";
import Action from "./Action";
import ReactToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "semantic-ui-react";
import Axios from "axios";
import Cookies from "js-cookie";
import history from "utils/history";

let totalEx = 0;
let grossEx = 0;

const totalExpenses = (item) => {
  const total =
    +item.cashPaid +
    +item.fuelCost +
    +item.parking +
    +item.toolCc +
    +item.busFare +
    +item.trainFare +
    +item.taxiFare +
    +item.airFare +
    +item.airportTransfer +
    +item.hotelAcm +
    +item.chargeFee +
    +item.food +
    +item.otherExpenses;
  totalEx = total.toFixed(2);
  return isNaN(total) ? "" : total.toFixed(2);
};

const grossProfile = (item) => {
  const total = totalExpenses(item);
  const grossProfile = +item.paymentValue - +total;
  grossEx = grossProfile.toFixed(2);
  return isNaN(grossProfile) ? "" : grossProfile.toFixed(2);
};

// "GBP £", "EUR €", "CAD $", "USD $", "AUD $", "CHF";

const currencyObj = {
  GBP: "£",
  EUR: "€",
  CAD: "$",
  USD: "$",
  AUD: "$",
};

const TableData = ({ fromDate, toDate, cRef, reload, setReload }) => {
  // const { data, loading } = useSelector((state) => state.cashBookReducer);
  const [data, setData] = useState();
  const LoadCashbook = async () => {
    if (history.location.pathname.includes("admin")) {
      try {
        const token = Cookies.get("token");
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/admin/cash-book`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.log("error");
      }
    } else if (history.location.pathname.includes("sppo")) {
      try {
        const token = Cookies.get("SPPOtoken");
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/sppo/cash-book`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.log("error");
      }
    } else if (history.location.pathname.includes("linguist")) {
      try {
        const token = Cookies.get("LINtoken");
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/cash-book`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setData(response.data.data);
      } catch (error) {
        console.log("error");
      }
    }
  };
  useEffect(() => {
    LoadCashbook();
  }, [reload]);
  const useStyles = makeStyles({
    button: {
      background: "#3f51b5 none",
      border: 0,
      color: "#ffff",
      padding: 10,
      margin: 0,
      cursor: "pointer",
      fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
      fontWeight: "bold",
      borderRadius: "3pt",
      fontSize: "14px",
    },
  });
  const classes = useStyles();
  console.clear();
  // console.log("data -->", data);
  const searchFunc = (item) => {
    if (!fromDate || !toDate) {
      return true;
    }
    let { date } = item;
    date = moment(date).add(4, "h").add(30, "m");
    // console.log("date -->", date);
    // console.log("fromDate -->", fromDate);
    // console.log("toDate -->", toDate);

    const fDateCheck =
      moment(date.startOf("day")).isSame(moment(fromDate).startOf("day")) ||
      moment(date.startOf("day")).isAfter(moment(fromDate).startOf("day"));
    const tDateCheck =
      moment(date.startOf("day")).isSame(moment(toDate).startOf("day")) ||
      moment(date.startOf("day")).isBefore(moment(toDate).startOf("day"));

    // console.log("fDateCheck -->", fDateCheck);
    // console.log("tDateCheck -->", tDateCheck);

    // console.log("--------------------------------------------");
    return fDateCheck && tDateCheck;
  };

  const filteredData = data;
  const a = filteredData?.map((uc) => uc);

  const exportPDF = (e) => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 10;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    var totalPages = doc.internal.getNumberOfPages();
    var i;

    for (i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
      // doc.setTextColor(150);
      doc.text(
        50,
        doc.internal.pageSize.height - 30,
        "Strictly Company Confidential © linguistscollective.com"
      );
    }

    const title = "Cashbook";
    const headers = [
      [
        "Date",
        "Job/\nYour Reference",
        "Payment Received/\nContract Value",
        "Fuel Cost",
        "Parking",
        "Toll/\nCC",
        "Bus Fare/\nTrain Fare",
        "Taxi Fare",
        "Air Fare",
        "Airport Transfer",
        "Hotel/\nAccomodation",
        "Charge/\nFee",
        "Food",
        "Cash Paid",
        "Other Expenses",
        "Total Expenses",
        "Gross Profit/\nLoss",
        "Currency",
      ],
    ];

    const data = filteredData.map((list) => [
      moment(list.date).format("DD MMM, YYYY"),
      list.jobReference,
      list.paymentValue,
      list.fuelCost,
      list.parking,
      list.toolCc,
      list.busFare,
      list.taxiFare,
      list.airFare,
      list.airportTransfer,
      list.hotelAcm,
      list.chargeFee,
      list.food,
      list.cashPaid,
      list.otherExpenses,
      totalEx,
      grossEx,
      list.currency,
    ]);
    // const One = "Column One: Job / Your Reference";
    // const Two = "Column Two: Payment Received / Contract Value";

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        valign: "middle",
        // halign: 'justify'
      },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    var string = doc.output("datauristring");
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    var x = window.open();
    doc.save("cashbook-table-pdf");
    x.document.open();
    x.document.write(embed);
    x.document.close();
  };

  return (
    <TableDataDiv>
      <Button
        style={{ background: "#3f51b5 none", color: "white" }}
        onClick={exportPDF}
      >
        Export in Pdf
      </Button>
      <ReactToExcel
        className={classes.button}
        table="cashbook-excel"
        filename="cashbook"
        sheet="sheet 1"
        buttonText="Export in Excel"
      />
      <Table color={"pink"} id="cashbook-excel" ref={cRef}>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell>Item No.</Table.HeaderCell> */}
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Job / Your Reference</Table.HeaderCell>
            <Table.HeaderCell>
              Payment Received / Contract Value
            </Table.HeaderCell>
            <Table.HeaderCell>Fuel Cost</Table.HeaderCell>
            <Table.HeaderCell>Parking</Table.HeaderCell>
            <Table.HeaderCell>Toll / CC</Table.HeaderCell>
            <Table.HeaderCell>Bus Fare / Train Fare</Table.HeaderCell>
            <Table.HeaderCell> Taxi Fare</Table.HeaderCell>
            <Table.HeaderCell>Air Fare</Table.HeaderCell>
            <Table.HeaderCell>Airport Transfer</Table.HeaderCell>
            <Table.HeaderCell>Hotel / Accomodation</Table.HeaderCell>
            <Table.HeaderCell>Charge / Fee</Table.HeaderCell>
            <Table.HeaderCell>Food</Table.HeaderCell>
            <Table.HeaderCell>Cash Paid</Table.HeaderCell>
            <Table.HeaderCell>Other Expenses</Table.HeaderCell>
            <Table.HeaderCell>Total Expenses</Table.HeaderCell>
            <Table.HeaderCell>
              Gross profit (Total Revenue / Total Income - Total Expenses)
            </Table.HeaderCell>
            <Table.HeaderCell>Currency</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {!data ? (
          <Segment>
            <Dimmer active inverted>
              <Loader size="small">Loading</Loader>
            </Dimmer>

            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
          </Segment>
        ) : (
          <Table.Body>
            <Fragment>
              {filteredData?.map((item, index) => (
                <Table.Row key={index}>
                  {/* <Table.Cell>{index + 1}</Table.Cell> */}
                  <Table.Cell>
                    {moment(item.date).format("DD MMM, YYYY")}
                  </Table.Cell>
                  <Table.Cell>{item.jobReference}</Table.Cell>
                  <Table.Cell>
                    {currencyObj[item.currency]}
                    {item.paymentValue}
                  </Table.Cell>
                  <Table.Cell>{item.fuelCost}</Table.Cell>
                  <Table.Cell>{item.parking}</Table.Cell>
                  <Table.Cell>{item.toolCc}</Table.Cell>
                  <Table.Cell>{item.busFare}</Table.Cell>
                  {/* <Table.Cell></Table.Cell> */}
                  <Table.Cell>{item.taxiFare}</Table.Cell>
                  <Table.Cell>{item.airFare}</Table.Cell>
                  <Table.Cell>{item.airportTransfer}</Table.Cell>
                  <Table.Cell>{item.hotelAcm}</Table.Cell>
                  <Table.Cell>{item.chargeFee}</Table.Cell>
                  <Table.Cell>{item.food}</Table.Cell>
                  <Table.Cell>{item.cashPaid}</Table.Cell>
                  <Table.Cell>{item.otherExpenses}</Table.Cell>
                  <Table.Cell>
                    {currencyObj[item.currency]}
                    {totalExpenses(item)}
                  </Table.Cell>
                  <Table.Cell
                    className={`${
                      Math.sign(+grossProfile(item)) === -1
                        ? "danger"
                        : "success"
                    }`}
                  >
                    {currencyObj[item.currency]}
                    {grossProfile(item)}
                  </Table.Cell>
                  <Table.Cell>{item.currency}</Table.Cell>
                  <Table.Cell>
                    <Action id={item._id} setReload={setReload} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Fragment>
          </Table.Body>
        )}
      </Table>
    </TableDataDiv>
  );
};

export default TableData;
