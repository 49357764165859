import { useDispatch, useSelector } from "react-redux";

import { storeValues } from "redux/actions/admin/invoice";

import Actions from "./Actions";

import Details from "./Details";
import SubDetails from "./SubDetails";
import PriceDropdown from "./PriceDropdown";
import InputItems from "./InputItems";
import BottomDetails from "./BottomDetails";

const Invoice = () => {
  const dispatch = useDispatch();
  const { currency } = useSelector((state) => state.invoiceReducer);

  const { setCurrencyValue } = useSelector((state) => state.getDataReducer);

  const handleChange = (value) => {
    dispatch(
      storeValues({
        name: "currency",
        value,
      })
    );
  };
  console.clear();
  return (
    <div>
      <Details />
      <SubDetails />
      <PriceDropdown handleChange={handleChange} state={currency} />
      <InputItems state={currency} />
      <BottomDetails currency={currency} />
      <Actions currency={setCurrencyValue} />
    </div>
  );
};

export default Invoice;
