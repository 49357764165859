import QRTPageadmin from "pages/admin/QRT";
import QRTPageemp from "pages/employee/QRT";

const QRT = [
  {
    id: '700e',
    type: "form",
    text: "Quote Request (telephonic)",
    path: "admin/quote-request-telephonic-form",
    component: QRTPageadmin,
  },

  {
    id: '700f',
    type: "form",
    text: "Quote Request (telephonic)",
    path: "employee/quote-request-telephonic-form",
    component: QRTPageemp,
  },
];

export default QRT;
