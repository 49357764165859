import { useState, Fragment, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Axios from "utils/axios";
import CustomDate from "components/Common/CustomDate";
import CustomDateTime from "components/Common/CustomDateTime";
import { TIMEZONE } from "data";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
// Material
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";

const EditableText = ({
  text,
  readOnly,
  name,
  id,
  setReload,
  details,
  date,
  datetime,
  timezone
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [value, setValue] = useState(text);
  const [inputName, setInputName] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const handleCheck = async () => {
    try {
      const fd = new FormData();
      fd.append(inputName, value);
      if (!inputName) {
        setIsClicked(!isClicked);
        return false;
      }
      setLoading(true);

      await Axios.put(`/api/manager-admin/edit-event/eventID/${id}`, fd);

      setIsClicked(!isClicked);
      setReload(Math.random());
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setInputName(e.target.name);
  };

  const handleDateChange = (date) => {
    console.log(date);
    setValue(date);
    setInputName(name);
  };

  const handleDateTimeChange = (e) => {
    setValue(e.target.value);
    setInputName(name);
  };


  return (
    <span
      style={{
        display: isClicked && "flex",
        justifyContent: isClicked && "flex-start",
        alignItems: isClicked && "center",
        flexWrap: isClicked && "wrap",
      }}
    >
      {isClicked ? (
        date ? (
          <CustomDate
            onChange={handleDateChange}
            value={value}
            name={name}
            width="80%"
            format="yyyy-MM-dd"
          />
        ) : datetime ? (
          <CustomDateTime
            onChange={handleDateTimeChange}
            value={value}
            name={name}
            width="80%"
          />
        ) : timezone ? (
          <CustomAutoSelect
            onChange={handleChange}
            label="Select timezone"
            width="80%"
            data={TIMEZONE}
            name={name}
            value={value}
          />
        ) : details ? (
          <TextField
            onChange={handleChange}
            value={value}
            id="standard-textarea"
            placeholder="Write details here..."
            multiline
            rows={6}
            name={name}
            width="80%"
            style={{ marginBottom: 15, width: 100 + "%" }}
          />
        ) : (
          <TextField
            value={value}
            onChange={handleChange}
            name={name}
            id="outlined-basic"
            placeholder={text}
            variant="outlined"
            fullWidth
          />
        )
      ) : (
        <span>{text}</span>
      )}{" "}
      {!readOnly ? (
        <Fragment>
          {!isClicked ? (
            <EditIcon
              color="primary"
              onClick={handleClick}
              style={{
                cursor: "pointer",
                fontSize: "1.2rem",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <Fragment>
              {loading ? (
                <CircularProgress
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              ) : (
                <CheckIcon
                  color="secondary"
                  onClick={handleCheck}
                  style={{
                    cursor: "pointer",
                    fontSize: "2rem",
                    verticalAlign: "middle",
                    color: "green",
                  }}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      ) : null}{" "}
    </span>
  );
};

export default EditableText;
