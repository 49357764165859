import { UPDATE_LC_STEP, CHANGE_LC_INPUT, LC_LOGIN, AUTO_SAVE, UPDATE_PROFILE_LINGUIST, UPDATE_PROFILE_LINGUIST_TWO, UPDATE_PROFILE_LINGUIST_THREE,
    UPDATE_PROFILE_LINGUIST_FOUR, UPDATE_PROFILE_LINGUIST_FIVE, UPDATE_PROFILE_LINGUIST_SIX, UPDATE_PROFILE_LINGUIST_SEVEN } from './actionTypes'

export const updateLCStep= (count) => ({
    type: UPDATE_LC_STEP,
    payload: count
})

export const lcLogin= (loggedInData) => ({
    type: LC_LOGIN,
    payload: loggedInData
})

export const changeInput= data => ({
    type: CHANGE_LC_INPUT,
    payload: data
})

export const autoSaveLC = data => ({
    type: AUTO_SAVE,
    payload: data
})

export const updateProfileLinguist = data => ({
    type: UPDATE_PROFILE_LINGUIST,
    payload: data
})

export const updateProfileLinguistPageTwo = data => ({
    type: UPDATE_PROFILE_LINGUIST_TWO,
    payload: data
})

export const updateProfileLinguistPageThree = data => ({
    type: UPDATE_PROFILE_LINGUIST_THREE,
    payload: data
})

export const updateProfileLinguistPageFour = data => ({
    type: UPDATE_PROFILE_LINGUIST_FOUR,
    payload: data
})

export const updateProfileLinguistPageFive = data => ({
    type: UPDATE_PROFILE_LINGUIST_FIVE,
    payload: data
})

export const updateProfileLinguistPageSix = data => ({
    type: UPDATE_PROFILE_LINGUIST_SIX,
    payload: data
})

export const updateProfileLinguistPageSeven = data => ({
    type: UPDATE_PROFILE_LINGUIST_SEVEN,
    payload: data
})