// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";

//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import CashClientLayout from "layout/CashClientLayout";
import TimesheetCAC from "components/CashClient/Assignments/ConfirmAssignments/TimesheetCAC/TimeSheetCAC";

const CloseTimesheetPage = () => {
  const classes = useDashboardStyles();
  return (
    <CashClientLayout>
      <Cashclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Timesheet</p>
              </CardHeader>
              <CardBody>
                <TimesheetCAC />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default CloseTimesheetPage;
