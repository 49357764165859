import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CompanyDetails from "./RIghtSegment/CompanyDetails";

const BusinessProfile = () => {
  return (
    <GridContainer
      style={{
        justifyContent: "center",
      }}
    >
      <GridItem xs={12} sm={12} md={8} lg={8} xl={8}>
        <CompanyDetails />
      </GridItem>
    </GridContainer>
  );
};

export default BusinessProfile;
