import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomSelect from "components/Common/CustomSelect";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";
import CustomInput from "components/Common/CustomInput";
//import CustomUpload from "components/Common/CustomUpload";
import CustomRadio from "components/Common/CustomRadio";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import {
  PRIMARY_TARGET_LANG,
  PRIMARY_SOURCE_LANG,
  VIRTUAL_FORMATTING,
  DOCUMENT_TYPE,
  TRANSLATION_CERTIFICATE,
  TRANSLATION_PRINTED,
  PRINT_QUALITY,
  SKILL_LEVEL_REQUIRED,
  RARE_REGEX,
  LEVEL_REGEX
} from "data";
import { changeTransInput, updateTransStep } from "redux/actions/transAction";
import { Button, Card, Checkbox, FormControlLabel } from "@material-ui/core";
import CardBody from "components/Card/CardBody";

const FormTwo = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.transReducer);
  const [count, setCount] = useState("");

  const {
    purchase_order_number,
    document_type,
    other_details,
    source_language,
    target_language,
    expertise_level_required,
    require_visual_formating,
    certified_translation,
    certificate,
    additional_quality_check,
    provider_id_number,
    additional_note,
    agree,
  } = data;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeTransInput({
        name,
        value: value || checked,
      })
    );
  };

  const handleExpertise = (value) => {
    if (target_language.includes("R") || source_language.includes("R")) {
      return value.match(RARE_REGEX);
    } else {
      return value.match(
        LEVEL_REGEX
       );
    }
  };

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateTransStep(1));
  };

  const isAllow =
    !document_type ||
    (document_type.includes("Other") && !other_details) ||
    !source_language ||
    !target_language ||
    !expertise_level_required ||
    !require_visual_formating ||
    !certified_translation ||
    !certificate ||
    !additional_quality_check ||
    !agree;

  const handleUpload = (e) => {
    const files = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(files);

    reader.onload = (e) => {
      const text = e.target.result;
      const textCount = text.split(" ");
      setCount(textCount.length);
    };
  };

  return (
    <div>
      {/*'Developer allow "add more" option at the end of first order'*/}
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInput
          onChange={handleChange}
          width="100%"
          label="Your booking ref/ Purchase order no"
          name="purchase_order_number"
          value={purchase_order_number}
        />
        <CustomSelect
          required
          onChange={handleChange}
          name="document_type"
          value={document_type}
          label="Document type"
          data={DOCUMENT_TYPE}
          width="100%"
        />
        {document_type?.includes("Other") ? (
          <CustomInput
            onChange={handleChange}
            label="Other details"
            name="other_details"
            value={other_details}
            width="100%"
            required
          />
        ) : null}
        <CustomSelect
          onChange={handleChange}
          label="Source language"
          data={PRIMARY_SOURCE_LANG}
          width="100%"
          name="source_language"
          value={source_language}
          required
        />
        <CustomSelect
          onChange={handleChange}
          label="Target language"
          data={PRIMARY_TARGET_LANG.filter(
            (value) => value !== source_language
          )}
          width="100%"
          name="target_language"
          value={target_language}
          required
        />
        <CustomSelect
          onChange={handleChange}
          label="Expertise level required"
          moreInfo="for this assignment / project"
          data={SKILL_LEVEL_REQUIRED.filter((value) => handleExpertise(value))}
          width="100%"
          name="expertise_level_required"
          value={expertise_level_required}
          required
        />
        {/*  (*Developer make sure this charge can be changed on settings with other rates */}
        <CustomSelect
          onChange={handleChange}
          label="Do this job require visual formatting?"
          moreInfo="document to look alike same as source document formatting boxes, graphs etc, set charge of £x* each A4 page"
          data={VIRTUAL_FORMATTING}
          width="100%"
          name="require_visual_formating"
          value={require_visual_formating}
          required
        />
        {/* (*Developer make sure charge can be modified easily on settings) */}
        <CustomSelect
          onChange={handleChange}
          label="Do you require this translation to be certified"
          moreInfo="By a qualified translator? If yes, please indicate which page(s) / document(s)? "
          data={TRANSLATION_CERTIFICATE}
          width="100%"
          name="certified_translation"
          value={certified_translation}
          required
        />
        {/* (*Developer make sure charge can be modified easily on settings) */}
        <CustomRadio
          onChange={handleChange}
          label="Would you like certificate to be posted or electronically?"
          moreInfo="If you have requested your translation to be certified"
          data={TRANSLATION_PRINTED}
          flexDir="column"
          width="100%"
          name="certificate"
          value={certificate}
          required
        />
        {/* (*Developer make sure charge can be modified easily on settings)  */}
        <CustomSelect
          onChange={handleChange}
          label="Would you require additional quality check?"
          moreInfo="Would you require additional quality assurance QA for this assignment / project? (a qualified second linguist will check the work of main translator and assure quality of translation, recommended for large volume of translation, not necessary for certified translation)"
          data={PRINT_QUALITY}
          width="100%"
          name="additional_quality_check"
          value={additional_quality_check}
          required
        />
        {/* <CustomUpload
          onChange= {handleUpload}
          required
          width="100%"
          label="Please upload your document(s) here (clear and readable scan, pdf or photos/JPEG only)"
        /> */}
        <Card raised style={{ marginTop: "24px" }}>
          <CardBody>View Documents</CardBody>
        </Card>
        {/* (*Developer try to make this automated) */}
        <CustomBigInput
          width="100%"
          label="Estimated number words / word counts"
          moreInfo="(10k words or 20 A4 pages only. More than 10k or 20 pages please use quote)"
          required
          value={count}
        />
        <CustomBigInput
          onChange={handleChange}
          label="Preferred Linguist / Service provider ID number"
          width="100%"
          name="provider_id_number"
          moreInfo="LC will try its best, but cannot guarantee"
          value={provider_id_number}
        />
        <CustomInput
          onChange={handleChange}
          name="additional_note"
          value={additional_note}
          label="Additional note for translator"
          width="100%"
        />

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                name="agree"
                checked={agree}
                color="primary"
              />
            }
            label="By submitting this form I agree with Linguists Collective - T&C, Privacy"
          />
        </div>

        <div className={classes.buttonFlex}>
          {/* <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          /> */}
          <CustomButton text="Amend" onClick={handleNext} />
          <CustomButton text="Cancel" onClick={handleNext} />
          <Button
            style={{ marginTop: "2rem", marginRight: "15px" }}
            variant="contained"
            color="primary"
          >
            Provide Quote
          </Button>
          <Button
            style={{ marginTop: "2rem", marginRight: "15px" }}
            variant="contained"
            color="primary"
          >
            View Note
          </Button>
          <CustomButton text="Archive" onClick={handleNext} />
        </div>
      </form>
    </div>
  );
};

export default FormTwo;
