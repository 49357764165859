import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import swal from "sweetalert";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import { changeLinguistProfileInput } from "redux/actions/updateProfileAction";
import { useDispatch } from "react-redux";
import { Button, Dimmer, Loader } from "semantic-ui-react";

const LevelAction = ({ id, level, verified, lang }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLevel = async (levelSelected) => {
    setLoader(true);
    try {
      const response = await Axios.put(
        `/api/manager-admin/upgrade-target-language-level/linID/${id}`,
        {
          target_language_level: levelSelected,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then(function () {
        window.location = `/admin/view-linguist-profile/${id}`;
      });
      // console.log(response)
      setAnchorEl(null);
      setLoader(false);
      dispatch(
        changeLinguistProfileInput({
          name: "target_language_level",
          value: levelSelected,
        })
      );
    } catch (error) {
      if (error) {
        console.log(error.response);
      }
    }
  };

  const handleChange = async (levelSelected) => {
    swal(`Are you sure you want to assign ${levelSelected} ?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          handleLevel(levelSelected);

        default:
          break;
      }
    });
  };

  if (loader) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Assigning level</Loader>
      </Dimmer>
    );
  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        // style={{
        //   minWidth: "10px",
        //   paddingLeft: "0px",
        // }}
      >
        {/* {state.level} */}
        {level || "level 0"}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!verified ? (
          <MenuItem onClick={() => handleChange("Level 0")}>Level 0</MenuItem>
        ) : null}
        {lang?.includes("(R)") ? (
          <>
            <MenuItem onClick={() => handleChange("N/A")}>N/A</MenuItem>
            <MenuItem onClick={() => handleChange("Level 3")}>Level 3</MenuItem>
            <MenuItem onClick={() => handleChange("Level 4R")}>
              Level 4r
            </MenuItem>
            <MenuItem onClick={() => handleChange("Level 5")}>Level 5</MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={() => handleChange("N/A")}>N/A</MenuItem>
            <MenuItem onClick={() => handleChange("Level 1")}>Level 1</MenuItem>
            <MenuItem onClick={() => handleChange("Level 2")}>Level 2</MenuItem>
            <MenuItem onClick={() => handleChange("Level 3")}>Level 3</MenuItem>
            <MenuItem onClick={() => handleChange("Level 4")}>Level 4</MenuItem>
            <MenuItem onClick={() => handleChange("Level 5")}>Level 5</MenuItem>

            <MenuItem onClick={() => handleChange("Level X")}>Level X</MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};

export default LevelAction;
