import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import Action from "./Action";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import Searchlinguist from "components/Admin/CrmPanel/Leads/SearchView/SearchView";
import Cookies from "js-cookie";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const { addToast } = useToasts();
  const classes = useStyles();
  const token = Cookies.get("EMPtoken");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const [pay, setPay] = useState({
    message: "",
    claID: "",
    admin_note: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({ reload: false });
  const [notevalues, setNoteValues] = useState({ reload: false });

  useEffect(() => {
    loadList();
    setFiltered([]);
  }, [filter]);

  const loadList = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/CLA`
    )
      .then((res) =>
        filtered.length > 0 ? setValues(filtered) : setValues(res.data)
      )
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Paper className={classes.root}>
        <Searchlinguist
          values={values}
          SearchText={searchText}
          setSearchText={setSearchText}
          setFiltered={setFiltered}
          load={loadList}
          setShow={setShow}
          setFilter={setFilter}
          show={show}
        />
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" id="export">
                <TableHead>
                  <TableRow>
                    <TableCell>LEAD Contact ID</TableCell>
                    <TableCell>Ref / User ID</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile no</TableCell>
                    <TableCell>Business Phone no</TableCell>
                    <TableCell>Town & Country</TableCell>
                    <TableCell>Contact Group</TableCell>
                    <TableCell>Registration Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {values.length > 0 ? (
                  <TableBody>
                    {values
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={list._id}
                          style={
                            list?.BACReg && list?.CACReg
                              ? { backgroundColor: "lightpink" }
                              : list?.BACReg
                                ? { backgroundColor: "lightcyan" }
                                : list?.CACReg
                                  ? { backgroundColor: "lightgreen" }
                                  : list?.INCReg
                                    ? { backgroundColor: "lightsalmon" }
                                    : list?.PRCReg
                                      ? { backgroundColor: "lemonchiffon" }
                                      : null
                          }
                        >
                          <TableCell key={list._id} align={list.align}>
                            <Link to={`/employee/view-cla/${list._id}`}>
                              {list.id}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {list?.postedBy?.id
                              ? list?.postedBy?.id
                              : list?.userID
                                ? list?.userID
                                : list?.postedByClient
                                  ? list?.postedByClient
                                  : "LC Web"}
                            {/* <br />
                            Main User: {list?.userID ? list?.userID : "N/A"} */}
                            <br />
                            {list?.selfReg ? "SPPO Public Profile" : ""}
                          </TableCell>
                          <TableCell>{list.LastName}</TableCell>
                          <TableCell>{list.business_name}</TableCell>
                          <TableCell>
                            {list.removalRequest ? (
                              <p style={{ color: "red" }}>{list.email}</p>
                            ) : (
                              list.email
                            )}
                          </TableCell>
                          <TableCell>{list.mobile_no}</TableCell>
                          <TableCell>{list.business_phone_no}</TableCell>
                          <TableCell>{list.City}</TableCell>
                          <TableCell>{list.contact_group}</TableCell>
                          <TableCell>
                            {Moment(list.createdAt).format(
                              "DD MMM YYYY - kk:mm:ss"
                            )}
                          </TableCell>
                          <TableCell>{
                            list?.CACReg && list?.BACReg
                              ? <>Associated with CAC and BAC, Email ({list?.countEmail ? list?.countEmail : 0}) entry</>
                              : list?.CACReg
                                ? <>Associated with CAC, Email ({list?.countEmail ? list?.countEmail : 0}) entry</>
                                : list?.BACReg
                                  ? <>Associated with BAC, Email ({list?.countEmail ? list?.countEmail : 0}) entry</>
                                  : list?.INCReg
                                    ? <>Associated with INC, Email ({list?.countEmail ? list?.countEmail : 0}) entry</>
                                    : list?.PRCReg
                                      ? <>Associated with PRC, Email ({list?.countEmail ? list?.countEmail : 0}) entry</>
                                      : null}
                          </TableCell>
                          <TableCell>
                            <Action
                              load={loadList}
                              id={list._id}
                              ID={list?.id}
                              name={list?.FirstName + " " + list?.LastName}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  "No data found"
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={values.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </>
  );
}
