const { CHANGE_INHOUSE_CLIENT_INPUT, UPDATE_INHOUSE_CLIENT_STEP } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    count: 1,
    loading: false,
    data: {
    title: '',
    FirstName: '',
    LastName: '',
    Birthday: '',
    sex: '',
    email: '',
    mobile_no: '',
    direct_line: '',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    PostalCode: '',
    Region: '',
    Country: '',
    Nationality: '',
    other_nationality: [],
    passNumber: '',
    confirm_passNumber: '',
    business_name: "",
    position: "",
    business_email: "",
    business_phone_no: "",
    // business_address: "",
    business_address: '',
    business_address_2: '',
    business_town: '',
    business_zipcode: '',
    business_region: '',
    business_country: '',
    company_registration_no: "",
    business_website: "",
    note: "",
    gdpr: false,
    },
    loggedInData: null,
  };

const icrReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case CHANGE_INHOUSE_CLIENT_INPUT:
        return {
          ...state,
          data: {
            ...state.data,
            [payload.name]: payload.value,
          },
        };
     
      case UPDATE_INHOUSE_CLIENT_STEP:
          return {
            ...state,
            count: payload,
          };

      default:
        return state;
    }
}

export default icrReducer;