import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

const ViewContactCard = () => {
    const { id } = useParams()
    const history = useHistory()
    const classes = useDashboardStyles();

    return (
        <Employee>
            <EmployeeLayout>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                <p className={classes.cardTitleWhite}> View Document </p>
                                <i className="icon list arrow left"
                                    onClick={() => {
                                        history.goBack()
                                    }}></i>
                            </CardHeader>
                            <CardBody >
                                <GridContainer justify="center" className={classes.container}>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <object data={`${process.env.REACT_APP_BACKEND_API}/manager-admin/view-quote-enquiry-file_1/claID/${id}`} type="application/pdf" width="100%" height="1000px">
                                        </object>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardBody >
                                <GridContainer justify="center" className={classes.container}>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <object data={`${process.env.REACT_APP_BACKEND_API}/manager-admin/view-quote-enquiry-file_2/claID/${id}`} type="application/pdf" width="100%" height="1000px">
                                        </object>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardBody >
                                <GridContainer justify="center" className={classes.container}>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <object data={`${process.env.REACT_APP_BACKEND_API}/manager-admin/view-quote-enquiry-file_3/claID/${id}`} type="application/pdf" width="100%" height="1000px">
                                        </object>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </EmployeeLayout>
        </Employee>
    );
};

export default ViewContactCard;
