import React from "react";

// Material Core Components
import TextField from "@material-ui/core/TextField";
import CustomFormControl from "./CustomFormControl";

// Material icons
import InfoIcon from '@material-ui/icons/Info'

// Custom Components
import CustomLabel from "./CustomLabel";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import { InputAdornment, Tooltip } from "@material-ui/core";

const CustomEmail = ({ label, moreInfo, width, required, shrink, type, ...rest }) => {
  const classes = useFormStyles();

  return (
    <CustomFormControl width={`${width || "100%"}`}>
      <TextField
        label={<CustomLabel label={label} required= {required}/>}
        {...rest}
        InputLabelProps={{
          shrink: shrink,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              {moreInfo ? (<Tooltip title= {moreInfo} placement="top"><InfoIcon style= {{cursor: 'pointer'}} fontSize= 'small'/></Tooltip>): null}
            </InputAdornment>
          ),
        }}
        className={classes.textField}
      />
    </CustomFormControl>
  );
};

export default CustomEmail;
