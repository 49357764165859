import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import CashClientLayout from "layout/CashClientLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import SendForm from "components/CashClient/RecommendLC/RecommendLC"


const Archive = () => {
  const classes = useDashboardStyles();
 
  return (
    <Cashclient>
      <CashClientLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>Recommend LC</p>
              </CardHeader>
            </Card>

            <Card>
              <SendForm />
            </Card>

          </GridItem>
        </GridContainer>

      </CashClientLayout>
    </Cashclient>
  );
};

export default Archive;
