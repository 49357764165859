import React, { useEffect, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import Axios from "utils/axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { Button } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

const Action = ({ load, handlePaid, ID, id, type, name, reminder }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("token");

  const [showNote, setShowNote] = React.useState(false);
  const [viewNote, setViewNote] = React.useState(false);

  const [showDocNote, setShowDocNote] = React.useState(false);
  const [viewDocNote, setViewDocNote] = React.useState(false);

  const [showProfileNote, setShowProfileNote] = React.useState(false);
  const [viewProfileNote, setViewProfileNote] = React.useState(false);

  const [showMessage, setMessage] = React.useState(false);
  const [viewMessage, setViewMessage] = React.useState(false);

  const [profilenote, setProfileNote] = React.useState(null);
  const [docnote, setDocNote] = React.useState(null);
  const [remindernote, setReminderNote] = React.useState(null);
  const [totalMessage, setTotalMessage] = React.useState(null);

  const [pay, setPay] = React.useState({
    note: "",
  });

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const handleShow = () => {
    setShowNote(!showNote);
  };

  const handleShowProfileNote = () => {
    setShowProfileNote(!showProfileNote);
  };

  const handleShowMessage = () => {
    setMessage(!showMessage);
  };

  const handleShowDocNote = () => {
    setShowDocNote(!showDocNote);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowNote(false);
    setShowDocNote(false);
    setShowProfileNote(false);
    setMessage(false);
  };

  const handleBack = () => {
    setViewNote(false);
    setViewDocNote(false);
    setViewProfileNote(false);
    setMessage(false);
  };

  const handlePaidClick = (e) => {
    setAnchorEl(false);
    handlePaid(id, ID, name);
  };

  const handleView = (e) => {
    history.push(`view-public-profile/${id}`);
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  // const handleSuspend = async (e) => {
  //   try {
  //     const response = await Axios.put(
  //       `/api/manager-admin/suspend-linguist/linID/${id}`,
  //       "",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     swal({
  //       icon: "success",
  //       text: `${response?.data.message}`,
  //       buttons: false,
  //     });
  //     load();
  //   } catch (error) {
  //     console.log(error.response);
  //   }
  // };

  // const handleUnsuspend = async (e) => {
  //   try {
  //     const response = await Axios.put(
  //       `/api/manager-admin/unsuspend-linguist/linID/${id}`,
  //       "",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     swal({
  //       icon: "success",
  //       text: `${response?.data.message}`,
  //       buttons: false,
  //     });
  //     load();
  //   } catch (error) {
  //     console.log(error.response);
  //   }
  // };

  // const ArchiveLIN = async (e) => {
  //   try {
  //     const response = await Axios.put(
  //       `/api/manager-admin/archive-linguist/${id}`,
  //       "",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     swal({
  //       icon: "success",
  //       text: `${response?.data.message}`,
  //       buttons: false,
  //     });
  //     load();
  //   } catch (error) {
  //     console.log(error.response);
  //   }
  // };

  // const handleArchive = async (value) => {
  //   swal("Are you sure you want move to archive this linguist?", {
  //     dangerMode: true,
  //     buttons: {
  //       cancel: true,
  //       catch: {
  //         text: "Yes!",
  //         value: "catch",
  //       },
  //     },
  //   }).then((type) => {
  //     switch (type) {
  //       case "cancel":
  //         break;

  //       case "catch":
  //         ArchiveLIN(value);

  //       default:
  //         break;
  //     }
  //   });
  // };

  const handleReminderFullReg = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.put(
        `/api/manager-admin/send-full-registration-reminder/linID/${id}`,
        {
          note: pay.note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: "Reminder sent",
      });
      getReminderNote();
      setPay({ ...pay, note: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleMessage = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.put(
        `/api/manager-admin/send-message/linID/${id}`,
        {
          note: pay.note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: "Message sent",
      });
      getTotalMessage();
      setPay({ ...pay, note: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleDocumentRequest = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.put(
        `/api/manager-admin/send-document-request/linID/${id}`,
        {
          note: pay.note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: "Document Request sent",
      });
      getDocNote();
      setPay({ ...pay, note: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleProfileNote = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `/api/manager-admin/write-profile-note/linID/${id}`,
        {
          note: pay.note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        buttons: false,
        text: "Note Added!!!",
      });
      getProfileNote();
      setPay({ ...pay, note: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const getProfileNote = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/count-profile-note/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setProfileNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getDocNote = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/count-doc-reminder-note/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDocNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getReminderNote = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/count-reg-reminder-note/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setReminderNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalMessage = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/count-message/linID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalMessage(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getProfileNote();
    getDocNote();
    getReminderNote();
    getTotalMessage();
  }, []);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleView}>View Public Profile</MenuItem>
        {/* {type ? (
          <MenuItem onClick={handleUnsuspend}>Unsuspend</MenuItem>
        ) : (
          <MenuItem onClick={handleSuspend}>Suspend</MenuItem>
        )}
        {type ? <MenuItem onClick={handleArchive}>Archive</MenuItem> : null} */}

        <MenuItem onClick={handleShowDocNote}>
          Request documents ({docnote})
        </MenuItem>
        {reminder ? (
          <MenuItem onClick={handleShow}>
            Send full registration reminder ({remindernote})
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleShowProfileNote}>
          Add note ({profilenote})
        </MenuItem>
        <MenuItem onClick={handleShowMessage}>
          Send Message ({totalMessage})
        </MenuItem>
      </Menu>

      {/* ADD Reminder NOTE */}
      {showNote ? (
        <Dialog open={showNote}>
          {!viewNote ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Send full registration Reminder - {name} - {ID}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.note}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="note"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.note}
                    onClick={handleReminderFullReg}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Send Reminder
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Send Reminder
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}

      {/* ADD Doc Reminder NOTE */}
      {showDocNote ? (
        <Dialog open={showDocNote}>
          {!viewDocNote ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Request documents - {name} - {ID}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.note}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="note"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.note}
                    onClick={handleDocumentRequest}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Send Request
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Send Request
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}

      {/* ADD NOTE */}
      {showProfileNote ? (
        <Dialog open={showProfileNote}>
          {!viewProfileNote ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Add Note - {name} - {ID}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.note}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="note"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.note}
                    onClick={handleProfileNote}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Save Note
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Save Note
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}

      {/* Send message */}
      {showMessage ? (
        <Dialog open={showMessage}>
          {!viewMessage ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Send Message - {name} - {ID}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.note}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your message here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="note"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.note}
                    onClick={handleMessage}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Send Message
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Send Message
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}
    </div>
  );
};

export default Action;
