import React from "react";
import { useParams } from "react-router";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { IconButton } from "@material-ui/core";
import history from "utils/history";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ViewList from "components/Employee/BookingPanel/Quotation/Closed/CAC/List";
import QuotationOptions from "components/Employee/BookingPanel/Quotation/QuotationOptions";
import Options from "components/Employee/BookingPanel/Quotation/Closed/Options";

const ClosedJobs = () => {
  const classes = useDashboardStyles();
  const { data, type } = useParams();

  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                color="primary"
              >
                <p className={classes.cardTitleWhite}>Closed Jobs (CAC)</p>
                <div style={{ display: "flex" }}>
                  <Options />
                  <IconButton color="inherit">
                    <ArrowBackIcon onClick={() => history.goBack()} />
                  </IconButton>
                </div>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <QuotationOptions />
              </CardHeader>
            </Card>

            <Card>
              <CardBody>
                <ViewList quotationData={type === "quotation" && data} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default ClosedJobs;
