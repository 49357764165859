import React, { useState, useEffect } from "react";

import { Button } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "axios";
// Custom Components
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import CardHeader from "components/Card/CardHeader";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const useStyles = makeStyles({
  Button: {
    width: "100%",
  },
});
const Leads = () => {
  const classes = useDashboardStyles();
  const buttonClass = useStyles();
  const matches = useMediaQuery("(max-width:768px)");


  return (
    <>
      {!matches ? (
        <CardHeader
          style={{ display: "flex", justifyContent: "space-between" }}
          color="primary"
        >
          <p className={classes.cardTitleWhite}>Job Advert</p>
          <div>
            <Button
              onClick={() => history.push("/admin/paid-job-advert")}
              color="purple"
            >
              Paid Add Review
            </Button>
            <Button
              onClick={() =>
                history.push(`/admin/unpaid-job-advert`)
              }
              color="purple"
            >
              Unpaid Add
            </Button>

            <Button
              onClick={() =>
                history.push(`/admin/accepted-jobadvert`)
              }
              color="purple"
            >
              Accepted Add
            </Button>

            <Button
              onClick={() =>
                history.push(`/admin/declined-jobadvert`)
              }
              color="purple"
            >
              Rejected Add
            </Button>

            <IconButton
              onClick={() => history.goBack()}
              color="inherit"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
        </CardHeader>
      ) : (
        <>
          {" "}
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            color="primary"
          >
            <p className={classes.cardTitleWhite}>Job Advert</p>
            <IconButton
              onClick={() => history.goBack()}
              color="inherit"
            >
              <ArrowBackIcon />
            </IconButton>
          </CardHeader>{" "}
          <CardHeader color="primary">
            {" "}
            <div style={{ marginBottom: "15px" }}>
              <Button
                className={buttonClass.Button}
                onClick={() => window.open("/admin/paid-job-advert")}
                color="purple"
              >
                Paid Add Review
              </Button>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <Button
                className={buttonClass.Button}
                onClick={() =>
                  history.push(`/admin/unpaid-job-advert`)
                }
                color="purple"
              >
                Unaid Add 
              </Button>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <Button
                className={buttonClass.Button}
                onClick={() =>
                  history.push(`/admin/accepted-jobadvert`)
                }
                color="purple"
              >
                Accepted Add 
              </Button>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <Button
                className={buttonClass.Button}
                onClick={() =>
                  history.push(`/admin/declined-jobadvert`)
                }
                color="purple"
              >
                Rejected Add 
              </Button>
            </div>
          </CardHeader>
        </>
      )}
    </>

  );
};

export default Leads;
