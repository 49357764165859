import { ACCOUNT_CLIENT_CHANGE_TRANSLATION_INPUT, CHANGE_TRANSLATION_INPUT, ACCOUNT_CLIENT_UPDATE_TRANSLATION_STEP, UPDATE_TRANSLATION_STEP } from "./actionTypes";

export const updateTransStep = (count) => ({
  type: UPDATE_TRANSLATION_STEP,
  payload: count,
});

export const changeTransInput = data => ({
  type: CHANGE_TRANSLATION_INPUT,
  payload: data
})

export const changeTransInputAC = data => ({
  type: ACCOUNT_CLIENT_CHANGE_TRANSLATION_INPUT,
  payload: data
})

export const updateTransStepAC = (count) => ({
  type: ACCOUNT_CLIENT_UPDATE_TRANSLATION_STEP,
  payload: count,
});