import Axios from "axios";
import React, { } from "react";
import swal from "sweetalert";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import { Button } from "semantic-ui-react";
// Custom Components
import CommonCard from "components/Common/CommonCard";
import { useParams } from "react-router";

const MyNotes = ({ transID, reportID }) => {
    const [pay, setPay] = React.useState({
        dispute_note: "",
    });
    const handleChange = async (e) => {
        setPay({ ...pay, [e.target.name]: e.target.value });
    };
    const [edit, setEdit] = React.useState({
        value: false,
        loader: false,
    });

    const handleDispute = async () => {
        setEdit({ ...edit, loader: true });
        try {
            const response = await Axios.put(
                `${process.env.REACT_APP_BACKEND_API}/dispute-project-report/ReportID/${reportID}`,
                {
                    dispute_note: pay.dispute_note,
                },
            );
            swal({
                icon: "success",
                buttons: false,
                text: `${response.data.message}`,
            }).then(function () {
                window.location = `/accept-inhouse-translation-close-and-report/${transID}`;
            });
            setPay({ ...pay, dispute_note: "", loader: false });
            setEdit({ ...edit, loader: false });
        } catch (error) {
            swal({
                icon: "error",
                buttons: false,
                text: "Failed ",
            });
        }
    };



    return (
        <CommonCard title="Reason For Dispute">
            <>
                <TextField
                    onChange={handleChange}
                    value={pay.dispute_note}
                    id="standard-textarea"
                    label="Type here... (max 432 ch.)"
                    placeholder="Write here your own confidential note for dispute..."
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 432 }}
                    name="dispute_note"
                    required
                    style={{ marginBottom: 15, width: 100 + "%" }}
                />

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 10,
                        marginBottom: 20,
                    }}
                >
                    <Button
                        disabled={!pay.dispute_note}
                        onClick={handleDispute}
                        color="purple"
                        variant="outlined"
                        startIcon={<SaveIcon />}
                    >
                        Confirm Dispute
                        {edit.loader && <div class="ui active inline loader"></div>}
                    </Button>
                </div>
            </>
        </CommonCard>
    );
};

export default MyNotes;
