import React from "react";
import { useSelector } from "react-redux";

// Material Core Components
import Typography from "@material-ui/core/Typography";

// Material Icons
import Cookies from 'js-cookie'
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import CustomFormControl from "components/Common/CustomFormControl";
import { Progress } from "semantic-ui-react";

const InhouseClientFormWeb = ({id, userID}) => {
  const { count } = useSelector((state) => state.icrReducer);

  const classes = useEmployeeStyles();

  const selectedForm = count;
  Cookies.set('sppoID', id)
  return (
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    In-house Client Registration
                  </Typography>
                  <Typography variant="subtitle2">Page {count} of 2</Typography>
                  <Typography variant="subtitle2">{userID? userID : "LC Web"}</Typography>
                </div>
                {selectedForm === 1 && <FormOne />}
                {selectedForm === 2 && <FormTwo id={id} userID={userID} />}
                <CustomFormControl width="73.33%">
                  <Progress
                    color="purple"
                    percent={(selectedForm / 2) * 100}
                    active
                    size="small"
                  />
                </CustomFormControl>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
  );
};

export default InhouseClientFormWeb;
