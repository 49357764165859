import React, { useState } from "react";
import { useParams } from "react-router";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import Sppo from "components/RouteProtection/Sppo/Sppo";
import CardBody from "components/Card/CardBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import SppoLayout from "layout/SppoLayout";
import BookingOption from "components/Sppo/ManageInhouse/BookingOption";
import Options from "components/Sppo/ManageInhouse/Options";
import { IconButton } from "@material-ui/core";
import ConfirmAssignment from "components/Sppo/ManageInhouse/ConfirmAssignments/ConfirmList";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import { Header } from "semantic-ui-react";

import TransConfirmAssign from "components/Sppo/ManageInhouse/TransConfirmAssign/TransConfirmAssign";

const ViewInhouseClient = () => {
  const classes = useDashboardStyles();
  const [atype, setType] = useState("assignments");
  const { data, type } = useParams();
  return (
    <SppoLayout>
      <Sppo>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className={classes.cardTitleWhite}>
                  In-house Confirm Assignments
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BookingOption setType={setType} />
              </CardHeader>
            </Card>
            <Card>
              {type !== "" && (
                <CardHeader color="primary">
                  <Options type={atype} />
                </CardHeader>
              )}
              <CardBody>
                <Header as="h3">Confirm Interpreting Assignments</Header>
                <ConfirmAssignment
                  interpretingData={type === "interpreting" && data}
                />

                <Header as="h3">Confirm Translation Assignments</Header>
                <TransConfirmAssign
                  translationData={type === "translation" && data}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Sppo>
    </SppoLayout>
  );
};

export default ViewInhouseClient;
