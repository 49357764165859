import { Fragment } from "react";
import { useHistory } from "react-router-dom";
// Material Core Components
import Button from "@material-ui/core/Button";

const AcepDec = ({
  type,
  fn,
  calcAcceptedRoute,
  handleDecline,
  id,
  email,
  declineOp,
  linID,
  target_level,
  target_language,
  start_date,
  streetName,
  postalCode,
  region,
  country,
  interpreting_service_required,
}) => {
  const history = useHistory();
  const handleNext = () => {
    history.push("/linguist/create-quotation");
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {type === "all" ? (
        <Button color="primary" variant="contained" onClick={fn}>
          View All
        </Button>
      ) : (
        <Fragment>
          {history.location.pathname.includes("quote") ? (
            <Button
              variant="contained"
              color="primary"
              style={{
                marginBottom: "10px",
              }}
              onClick={handleNext}
            >
              Provide Quote
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                calcAcceptedRoute(
                  id,
                  email,
                  linID,
                  target_level,
                  target_language,
                  start_date,
                  streetName,
                  postalCode,
                  region,
                  country,
                  interpreting_service_required
                )
              }
              style={{
                marginBottom: "10px",
              }}
            >
              Accept
            </Button>
          )}

          {declineOp ? (
            <select
              onChange={(e) => handleDecline(e, id, email)}
              className="ui dropdown"
            >
              <option>Decline</option>
              <option value="Not profitable">Not profitable</option>
              <option value="Venue is too far">Venue is too far</option>
              <option value="Unavailable / booked">Unavailable / booked</option>
              <option value="On holiday">On holiday</option>
              <option value="Not interested in job from this client">
                Not interested in job from this client
              </option>
              <option value="Don't feel confident">
                Don't feel confident
              </option>
              <option value="Need more information">
                Need more information
              </option>
              <option value="Other">Other</option>
            </select>
          ) : (
            <Button
              onClick={() => handleDecline(id, email)}
              color="secondary"
              variant="outlined"
            >
              Decline
            </Button>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default AcepDec;
