import SearchInput from "./SearchInput";
import { Button } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//   div:{
//     marginTop: '24px'
//   , width: '50%',
//   ['@media (max-width:768px)']:{
//     width:'100%',

//   }
//   },
//   root: {
//       ['@media (max-width:768px)']:{
//         width:'100%',
//         display:'flex',
//         justifyContent:'center'
//       }
//     },
//     button:{
//       ['@media (max-width:768px)']:{
//         width:'96%',marginTop:5
//       }
//     }
// }));
const SearchClient = ({
  setShow,
  setSearchText,
  SearchText,
  handleSearch,
  load,
  show,
}) => {
  // // const [searchChat, setSearchChat] = useState('')
  // const classes = useStyles();
  // // const handleSearch = e => {
  // //   console.log(e.target.value)
  // //   setSearchChat(e.target.value)
  // // }

  // const showClients = e => {
  //  setShow(!show)
  // }

  return (
    <div>
      <SearchInput
        show={show}
        setShow={setShow}
        load={load}
        setSearchText={setSearchText}
        SearchText={SearchText}
        handleSearch={handleSearch}
      />
    </div>
  );
};

export default SearchClient;
