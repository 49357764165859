import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import CustomEmail from "components/Common/CustomEmail";
// Material Core Components
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomPassword from "components/Common/CustomPassword";
import SppoForgot from "./SppoForgot";
import CustomAvatar from "components/Common/CustomAvatar";
import { EMAIL_REGEX } from "data";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import LandingPageLayout from "layout/LandingPageLayout";

// Others
import Axios from "axios";
import swal from "sweetalert";
import CustomCaptcha from "components/Common/CustomCaptcha";

const SppoLogin = () => {
  const history = useHistory();
  const [notValid, setNotValid] = useState(false);
  const dispatch = useDispatch();
  // const { loading } = useSelector((state) => state.erReducer);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [captcha, setCaptcha] = useState();
  const [loading, setLoading] = useState(false);
  const classes = useEmployeeStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/signin`,
        state
      );

      Cookies.set("SPPOtoken", response?.data?.token);
      localStorage.setItem("SPPOuser", JSON.stringify(response?.data?.sppo));

      history.push("dashboard");

      // swal({
      //   title: "Welcome",
      //   text: `${response?.data.sppo.first_name} ${response?.data.sppo.last_name}`,
      //   icon: "success",
      //   buttons: false,
      // });
      const token = Cookies.get("SPPOtoken");

      Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/post-activity-log`,
        {
          Device: Cookies.get("visitor_device"),
          Location: Cookies.get("visitor_address") || "permission denied",
          Browser: Cookies.get("visitor_browser"),
          IP: Cookies.get("visitor_ip"),
          Time: new Date(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      if (error?.response?.data?.error) {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      } else {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `Please check your internet connection.`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const { email, password } = state;

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };

  return (
    <LandingPageLayout>
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    SPPO Login
                  </Typography>
                </div>
                <form method="POST">
                  {/* <CustomInput
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    value={email}
                  /> */}
                  <CustomEmail
                    onChange={handleEmailChange}
                    label="Email"
                    width="100%"
                    name="email"
                    value={email}
                    type="email"
                    placeholder="Your registered primary email "
                  />
                  {notValid && (
                    <small style={{ color: "red" }}>
                      enter valid email address
                    </small>
                  )}
                  <CustomPassword
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    value={password}
                    placeholder="Enter your password"
                  />

                  <CustomCaptcha onChange={handleCaptcha} />

                  <div className={classes.buttonDiv}>
                    <Button
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!email || !password || loading || !captcha}
                      variant="contained"
                    >
                      {loading ? "Logging..." : "Login"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => history.push("/sppor-form")}
                    >
                      Register
                    </Button>
                  </div>
                </form>
                <div className={classes.forgotDiv}>
                  <span />
                  <SppoForgot />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </LandingPageLayout>
  );
};

export default SppoLogin;
