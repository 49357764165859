import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import swal from "sweetalert";

// Material Components
import Button from "@material-ui/core/Button";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomDate from "components/Common/CustomDate";
import CustomCaptcha from "components/Common/CustomCaptcha";
import { EMAIL_REGEX } from "data";

const ForgotPassword = ({ type }) => {
  const [email, setEmail] = useState();
  const [dob, setDob] = useState();
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [notValid, setNotValid] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleDateChange = (date) => {
    setDob(date);
  };

  const handleSubmit = async () => {
    const sendData = {
      email,
      birthday: dob,
    };

    setLoading(true);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/forgot-password`,
        sendData
      );

      swal({
        title: "Success!",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      // error
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
      setLoading(false);
    }
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };

  return (
    <Fragment>
      <CustomDate
        label="Enter your birth date..."
        width="100%"
        onChange={handleDateChange}
        value={dob}
      />
      <CustomInput
        onChange={handleChange}
        value={email}
        label="Enter your associated email..."
      />
      {notValid && (
        <>
          <small style={{ color: "red", float: "left" }}>
            enter a valid email address
          </small>
        </>
      )}
      <CustomCaptcha onChange={handleCaptcha} />

      <Button
        onClick={handleSubmit}
        color="primary"
        variant="contained"
        disabled={!email || !dob || loading || !captcha || notValid}
        style={{
          marginTop: "15px",
        }}
      >
        {loading ? "Sending..." : "Send Reset Link"}
      </Button>
    </Fragment>
  );
};

export default ForgotPassword;
