import TicBox from "@material-ui/core/Checkbox";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Material imports
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import { Checkbox } from "semantic-ui-react";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
// Icons
import { changeCrInput } from "redux/actions/crAction";
import { Header, Button, Modal, Form } from "semantic-ui-react";
import Action from "./Action";

function ProfMemberShip({ checkData }) {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.crReducer);
  const [values, setValues] = useState({ reload: false });
  const token = Cookies.get("SPPOtoken");
  const fileInput = useRef(null);
  const { certificate_title, org_name, expiry_date, certificate_document } =
    data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeCrInput(inputData));
  };

  useEffect(() => {
    loadList();
  }, []);

  const handlePhoto = async (e) => {
    dispatch(
      changeCrInput({
        name: "certificate_document",
        value: e.target.files[0],
      })
    );
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleSave = async (e) => {
    let formData = new FormData();
    formData.append("certificate_title", certificate_title || "-");
    formData.append("org_name", org_name || "-");
    formData.append("expiry_date", expiry_date || "-");

    if (!certificate_document?.contentType) {
      formData.append("certificate_document", certificate_document);
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/update-public-profile-certification`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        title: "Success!",
        text: `Successfully added`,
        icon: "success",
        buttons: "Ok",
      }).then(function () {
        window.location = "manage-public-profile";
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/certification-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const [state, setState] = useState({
    checkedA: false,
    checkedB: false,
    checkedF: false,
    checkedG: false,
    checkedH: false,
    other: false,
    added: false,
  });

  const [otherModal, setOtherModal] = useState(false);

  const handleOther = (e) => {
    setOtherModal(true);
  };

  const handleClose = (e) => {
    setOtherModal(false);
  };

  const allowCertificationSection = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/allow-Certification-section/${
          checkData?.agent_sppo ? checkData?.agent_sppo : checkData?._id
        }`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/sppo/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const hideCertificationSection = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/disable-Certification-section/${
          checkData?.agent_sppo ? checkData?.agent_sppo : checkData?._id
        }`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/sppo/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePublicProfile = (value) => {
    if (checkData.access) {
      if (!checkData.certification_section) {
        swal(
          "Certification section of your profile will be public. Are you sure you want to make this section of your profile public?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              allowCertificationSection(value);

            default:
              break;
          }
        });
      } else {
        swal(
          "Are you sure you want to revoke public view permission for this section?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              hideCertificationSection(value);

            default:
              break;
          }
        });
      }
    }
  };

  return (
    <div>
      <Modal size="mini" onClose={handleClose} open={otherModal}>
        <Modal.Header>Adding Certification Details</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Field>
                <label>Name Of The Certificte</label>
                <input
                  name="certificate_title"
                  onChange={handleChange}
                  value={certificate_title}
                  placeholder="Trade / Professional Certificate Name"
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Name Of The Certifier</label>
                <input
                  name="org_name"
                  onChange={handleChange}
                  value={org_name}
                  placeholder="Certification authority or organisation name"
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Expiry Date</label>
                <input
                  name="expiry_date"
                  onChange={handleChange}
                  value={expiry_date}
                  placeholder="e.g. 01/Jan/2021"
                  required
                />
              </Form.Field>

              <Header as="h4">Upload Certificate Document</Header>

              <input
                accept="image/jpeg, image/png, .pdf, .doc"
                ref={fileInput}
                onChange={(e) => handlePhoto(e)}
                type="file"
                style={{ display: "none" }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Button onClick={handleClick}>Upload</Button>
                </div>
                {certificate_document && (
                  <Header style={{ margin: "0", textAlign: "center" }} as="h5">
                    <Header style={{ margin: "0" }} as="h1">
                      <InsertPhotoIcon fontSize="large" />
                    </Header>
                    {certificate_document?.name}
                    <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                      certificate_document?.size / 1024
                    ).toFixed(2)}kb`}</Header>
                    <Header
                      style={{ margin: "0" }}
                      as="h5"
                    >{`Last Modified: ${certificate_document?.lastModifiedDate}`}</Header>
                  </Header>
                )}
              </div>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            content="Add"
            labelPosition="right"
            icon="checkmark"
            onClick={handleSave}
            disabled={!certificate_title || !expiry_date}
            positive
          />
        </Modal.Actions>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header color="purple" as="h2">
          Certification/s
        </Header>

        {/* <Button.Group>
          <Button toggle active={true}>
            LC
          </Button>
          <Button toggle>
            <Icon name="user" />
          </Button>
          {checkData?.certification_section ? (
            <Button color="green" toggle onClick={hideSection}>
              <Icon name="globe" />
            </Button>
          ) : (
            <Button color="red" toggle onClick={allowSection}>
              <Icon name="globe" />
            </Button>
          )}
        </Button.Group> */}

        <Checkbox
          toggle
          checked={checkData?.certification_section}
          onChange={() => handlePublicProfile("certification_section")}
        />
      </div>{" "}
      <br />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          marginBottom: "20px",
        }}
      >
        {values.length > 0 ? (
          <>
            {values?.map((list) => (
              <>
                {" "}
                {list.certificate_title ? (
                  <div
                    style={{
                      display: "block",
                      margin: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Header as="h5">
                      {list.certificate_title} <br /> {list?.org_name} <br />{" "}
                      {list.expiry_date}
                    </Header>
                    <div>
                      <Action id={list?._id} load={loadList} />
                    </div>
                  </div>
                ) : null}
              </>
            ))}{" "}
          </>
        ) : null}
      </div>
      <FormControlLabel
        control={
          <TicBox
            onChange={handleOther}
            name="other"
            checked={state.other}
            color="primary"
          />
        }
        label="Add Certificte/s"
      />
    </div>
  );
}

export default ProfMemberShip;
