import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
// Other
import Axios from "axios";
import swal from "sweetalert";
import { Button } from "semantic-ui-react";
import CustomAvatar from "components/Common/CustomAvatar";
import LandingPageLayout from "layout/LandingPageLayout";
import { Header } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import useEmployeeStyles from "assets/jss/components/employeeStyle";

const FormOne = () => {
  const { id } = useParams();
  const classes = useEmployeeStyles();
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);

  const [photo, setPhoto] = useState();
  const [passport, setPassport] = useState();

  const [edit, setEdit] = React.useState({
    loader: false,
  });

  const handlePhotoClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };
  const handlePassportClick = (e) => {
    e.preventDefault();
    fileInput2.current.click();
  };

  const handlePhotoFiles = (e) => {
    setPhoto(e.target.files[0]);
  };
  const handlePassportFiles = (e) => {
    setPassport(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEdit({ ...edit, loader: true });

    let fd = new FormData();
    fd.append("photo", photo || "");
    fd.append("passport", passport || "");

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/edit-inhouse-client/clientID/${id}`,
        fd
      );
      swal({
        title: "Success",
        text: "INC profile updated!",
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/";
      });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <LandingPageLayout>
      <form className={classes.form} noValidate autoComplete="off">
        <div className={classes.root}>
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CustomAvatar />
                  </div>
                  <div className={`${classes.textCenter} ${classes.title}`}>
                    <Header style={{ margin: "0" }} as="h3">
                      Linguists Collective (LC)
                    </Header>
                    <Header style={{ margin: "0" }} as="h3">
                      Upload In-house Client Documents
                    </Header>
                  </div>
                  <Header style={{ margin: "0" }} as="h5">
                    {" "}
                    Upload profile photo
                  </Header>
                  <input
                    onChange={handlePhotoFiles}
                    accept=".pdf,.docx,image/jpeg,image/jpg"
                    name="photo"
                    width="100%"
                    required
                    ref={fileInput}
                    type="file"
                    style={{ display: "none" }}
                    multiple
                  />
                  <br />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <Button
                        color="blue"
                        onClick={handlePhotoClick}
                        disabled={photo && photo.length === 2}
                      >
                        Upload
                      </Button>
                    </div>
                    {photo && (
                      <Header
                        style={{ margin: "0", textAlign: "center" }}
                        as="h5"
                      >
                        {photo.name}
                        <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                          photo.size / 1024
                        ).toFixed(2)}kb`}</Header>
                        <Header
                          style={{ margin: "0" }}
                          as="h5"
                        >{`Last Modified: ${photo?.lastModifiedDate?.toDateString()}`}</Header>
                      </Header>
                    )}
                    <Header style={{ marginTop: "10px" }} as="h5">
                      Upload personal identification (passport/DL/national ID)
                    </Header>
                  </div>
                  <input
                    onChange={handlePassportFiles}
                    accept=".pdf,.docx,image/jpeg,image/jpg"
                    name="photo"
                    width="100%"
                    required
                    ref={fileInput2}
                    type="file"
                    style={{ display: "none" }}
                    multiple
                  />
                  <br />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <Button
                        color="blue"
                        onClick={handlePassportClick}
                        disabled={photo && photo.length === 2}
                      >
                        Upload
                      </Button>
                    </div>
                    {passport && (
                      <Header
                        style={{ margin: "0", textAlign: "center" }}
                        as="h5"
                      >
                        {passport.name}
                        <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                          passport.size / 1024
                        ).toFixed(2)}kb`}</Header>
                        <Header
                          style={{ margin: "0" }}
                          as="h5"
                        >{`Last Modified: ${passport?.lastModifiedDate?.toDateString()}`}</Header>
                      </Header>
                    )}
                  </div>
                  <br />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={handleSubmit} color="purple">
                      Submit
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </form>
    </LandingPageLayout>
  );
};

export default FormOne;
