import React, { useEffect, useState } from 'react';
import './LinguistProfile.css'

// Custom Imports
import Header from './Header/Header'
import ProfMemberShip from './ProfMemberShip/ProfMemberShip'
import SecurityClearance from './SecurityClearance/SecurityClearance'
import Verified from './Verified/Verified'
import About from './About/About'
import WorkExperience from './WorkExperience/WorkExperience'
import Qualification from './Qualification/Qualification'
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Axios from 'axios'
import history from 'utils/history'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux';
import { updatePPLinguist } from 'redux/actions/updatePPLAction';
import CustomSnackBar from 'components/Common/CustomSnackBar';
import useEmployeeStyles from "assets/jss/components/employeeStyle";


function LinguistProfile() {
  const classes = useEmployeeStyles();
  const token = Cookies.get('LINtoken')
  const [submitLoader, setSubmitLoader] = useState(false)
  const dispatch = useDispatch()
  const [approval, setApproval] = useState(false)
  const [data, setData] = useState(false)
  const [checkData, setProfessional] = useState(false)

  const getLinguistDetails = async (e) => {
    try {
      const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (response?.data.profile_update === false) {
        history.push('/linguist/full-registration-p1')
      }
      dispatch(updatePPLinguist(response?.data))
      setData(true)
      setProfessional(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getLinguistDetails();
  }, [])

  const sendApproval = async (e) => {
    setSubmitLoader(true)
    try {
      const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/linguists/send-public-profile-approval-request`, '', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setApproval(true)
      setSubmitLoader(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    data ?
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <div>
            <CustomSnackBar message='Public profile sent for approval' handleClose={() => setApproval(false)} openSnackBar={approval} />
            <div style={{ backgroundImage: 'linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)', padding: '20px', boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)' }}>
              <Header />
            </div>

            <div style={{ backgroundImage: 'linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)', padding: '20px', boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)' }}>
              <Verified />
            </div>

            <div style={{ backgroundImage: 'linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)', padding: '20px', boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)' }}>
              <ProfMemberShip checkData={checkData} />
            </div>

            <div style={{ backgroundImage: 'linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)', padding: '20px', boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)' }}>
              <SecurityClearance checkData={checkData} />
            </div>

            <div style={{ backgroundImage: 'linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)', padding: '20px', boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)' }}>
              <About checkData={checkData} />
            </div>

            <div style={{ backgroundImage: 'linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)', padding: '20px', boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)' }}>
              <Qualification checkData={checkData} />
            </div>

            <div style={{ backgroundImage: 'linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)', padding: '20px', boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)' }}>
              <WorkExperience />
            </div>

          </div>
        </GridItem>
      </GridContainer>
      :
      <p>Loading...</p>
  );
}

export default LinguistProfile;
