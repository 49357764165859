
import React, { useState } from 'react'
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from 'react-redux';
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Search from './Search/SearchClient'
import Client from './List'

const PersonalDetails = ({ id }) => {
  const classes = useDashboardStyles();
  const { linguistPRC } = useSelector(state => state.linguistclient)

  return (

    <GridItem xs={12} sm={12} md={12} lg={12}>
      <Card>
        <CardHeader
          color="primary"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className={classes.cardTitleWhite}>Private Clients</p>

        </CardHeader>
      </Card>
      <Card>
        <CardBody>
          {/* <Search/> */}
          <Client id={id} />
          {/* {linguistPRC.length > 0 && <Client linguistPRC= {linguistPRC} id = {id} />}	 */}

        </CardBody>

      </Card>


    </GridItem>

  )
}

export default PersonalDetails
