import { v4 as uuidv4 } from "uuid";

import ViewCashclientDetails from "pages/employee/Cashclient/ViewCashclientDetails";
import ViewAccountclientDetails from "pages/employee/Accountclient/ViewAccountclientDetails";

import Communication from "pages/employee/Communication/Communication";
import EmployeeDashboard from "pages/employee/Dashboard";
import InterpretingList from "pages/employee/BookingSecretary/Interpreting/InterpretingList";
import TranslationList from "pages/employee/BookingSecretary/Translation/TranslationList";
import TaskLists from "pages/employee/TaskLists";
import MyNotes from "pages/employee/Task/MyNotes";
import ColleagueNotes from "pages/employee/Task/ColleagueNotes";

import CreateQuotation from "pages/employee/Quotation/CreateQuotation";
import FindQuote from "pages/employee/Quotation/FindQuote";
import InvoicePage from "pages/employee/CreateInvoice";
import CIC from "pages/employee/Cic";
import QuotationFromClients from "pages/employee/BookingSecretary/Quotation/QuotationFromClients";

import InhouseInterpretingAssignments from "pages/employee/BookingSecretary/Inhouse/Interpreting/ConfirmAssignments";
import InhouseTranslationAssignments from "pages/employee/BookingSecretary/Inhouse/Translation/TranslationAssignment";

import PrivateInterpretingAssignments from "pages/employee/BookingSecretary/Private/PrivateInterpreting/ConfirmAssignments";
import PrivateTranslationAssignments from "pages/employee/BookingSecretary/Private/PrivateTranslation/TranslationAssignment";

// inhouse and private
import ViewInhouseDetails from "pages/employee/Crmpanel/Inhouse/ViewInhouseDetails";
import ViewPrivateDetails from "pages/employee/Crmpanel/Private/ViewPrivateDetails";

import ViewSppoDetails from "pages/employee/Sppo/ViewSppoDetails";

import ProfilePage from "pages/employee/ProfilePage";
import ResetPassword from "pages/employee/ResetPassword";
import SetNewPassword from "pages/employee/SetNewPassword";

// booking secretary cac&bac interpreting panel
//Confirm
import ConfirmCACAssignments from "pages/employee/BookingSecretary/Interpreting/CAC/ConfirmCACAssignments";
import ConfirmBACAssignments from "pages/employee/BookingSecretary/Interpreting/BAC/ConfirmBACAssignments";
//Assigned
import AssignedCACAssignments from "pages/employee/BookingSecretary/Interpreting/CAC/AssignedCACAssignments";
import AssignedBACAssignments from "pages/employee/BookingSecretary/Interpreting/BAC/AssignedBACAssignments";
//Closed
import ClosedCACAssignments from "pages/employee/BookingSecretary/Interpreting/CAC/ClosedCACAssignments";
import ClosedBACAssignments from "pages/employee/BookingSecretary/Interpreting/BAC/ClosedBACAssignments";
//invoiced (CAC & BAC)
import InvoicedCACAssignments from "pages/employee/BookingSecretary/Interpreting/CAC/InvoicedAssignments";
import InvoicedBACAssignments from "pages/employee/BookingSecretary/Interpreting/BAC/InvoicedAssignments";
// Completed(CAC & BAC)
import CompletedCACAssignments from "pages/employee/BookingSecretary/Interpreting/CAC/CompletedAssignments";
import CompletedBACAssignments from "pages/employee/BookingSecretary/Interpreting/BAC/CompletedAssignments";

//Canceled
import CanceledCACAssignments from "pages/employee/BookingSecretary/Interpreting/CAC/CanceledCACAssignments";
import CanceledBACAssignments from "pages/employee/BookingSecretary/Interpreting/BAC/CanceledBACAssignments";

// booking secretary cac&bac translation panel
//Confirm CAC & BAC
import ConfirmCACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/CAC/ConfirmCACAssignments";
import ConfirmBACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/BAC/ConfirmBACAssignments";
//Assigned CAC & BAC
import AssignedCACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/CAC/AssignedCACAssignments";
import AssignedBACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/BAC/AssignedBACAssignments";
//Closed CAC & BAC
import ClosedCACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/CAC/ClosedCACAssignments";
import ClosedBACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/BAC/ClosedBACAssignments";
//Invoiced CAC & BAC
import InvoicedCACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/CAC/InvoicedAssignments";
import InvoicedBACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/BAC/InvoicedAssignments";
//Completed CAC & BAC
import CompletedCACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/CAC/CompletedAssignments";
import CompletedBACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/BAC/CompletedAssignments";
// Canceled CAC & BAC
import CanceledCACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/CAC/CanceledCACAssignments";
import CanceledBACAssignmentsTranslation from "pages/employee/BookingSecretary/Translation/BAC/CanceledBACAssignments";

// booking secretary cac&bac quotation from clients routes
//requested quote cac + bac
import RequestedCACQuote from "pages/employee/BookingSecretary/Quotation/CAC/RequestedQuote";
import RequestedBACQuote from "pages/employee/BookingSecretary/Quotation/BAC/RequestedQuote";

//provided quote cac + bac
import RespondedCACQuoteProvided from "pages/employee/BookingSecretary/Quotation/CAC/RespondedQuoteProvided";
import RespondedBACQuoteProvided from "pages/employee/BookingSecretary/Quotation/BAC/RespondedQuoteProvided";

//confirmed quote cac + bac
import ConfirmedByCACCliendQuote from "pages/employee/BookingSecretary/Quotation/CAC/ConfirmedByCliendQuote";
import ConfirmedByBACCliendQuote from "pages/employee/BookingSecretary/Quotation/BAC/ConfirmedByCliendQuote";

//closed quote cac + bac
import ClosedCACJobs from "pages/employee/BookingSecretary/Quotation/CAC/ClosedJobs";
import ClosedBACJobs from "pages/employee/BookingSecretary/Quotation/BAC/ClosedJobs";

//invoiced quote cac + bac
import InvoicedCACJobs from "pages/employee/BookingSecretary/Quotation/CAC/InvoicedJobs";
import InvoicedBACJobs from "pages/employee/BookingSecretary/Quotation/BAC/InvoicedJobs";

//completed quote cac + bac
import CompletedCACJobs from "pages/employee/BookingSecretary/Quotation/CAC/CompletedJobs";
import CompletedBACJobs from "pages/employee/BookingSecretary/Quotation/BAC/CompletedJobs";

//canceled quote cac + bac
import CanceledCACJobs from "pages/employee/BookingSecretary/Quotation/CAC/CanceledJobs";
import CanceledBACJobs from "pages/employee/BookingSecretary/Quotation/BAC/CanceledJobs";

import PaidJobAdvert from "pages/employee/BookingSecretary/jobadvert/PaidJobAdvert";
import UnpaidJobAdvert from "pages/employee/BookingSecretary/jobadvert/UnpaidJobAdvert";
import ViewJObAdvert from "pages/employee/BookingSecretary/jobadvert/ViewJObAdvert";
import ViewJObAdvertDocument from "pages/employee/BookingSecretary/jobadvert/ViewJObAdvertDocument";
import ViewAcceptedjob from "pages/employee/BookingSecretary/jobadvert/ViewAcceptedJob";
import ViewDeclinedJob from "pages/employee/BookingSecretary/jobadvert/ViewDeclinedJob";

import InitialRegistrationPage from "pages/employee/InitialRegistrationPage";
import EmployeeAccountActivate from "pages/employee/EmployeeAccountActivate";

import ContactRequest from "pages/employee/Contact/ContactRequest";
import ViewContactRequest from "pages/employee/Contact/ViewContactRequest";

//linguist profile & documntation
import UploadLINDoc from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/UploadLINDoc";
import LINProfilePhoto from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ProfilePhoto";
import LINAddress from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ProofAddressPhoto";
import LINPassport from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/Passport";
import LINReleventDocument from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ReleventDocument";
import LINIsuranceDocument from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/InsuranceDocument";
import LINReferenceLetter from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ReferenceLetter";
import LINInterpretingDocument from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/InterpretingDocument";
import LINTranslationDocument from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/TranslationDocument";
import LINPSIPSTDocument from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/PSIPSTDocument";
import LINAnyOtherDocument from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/AnyOtherDocument";
import LINCV from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/CV";
import SearchAllLin from "pages/employee/BookingSecretary/FindLinguist/SearchAllLin";
import SearchAllMultiLin from "pages/employee/BookingSecretary/FindLinguist/SearchAllMultiLin";

import LINPassportDocument from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/PassportDocument";
import LINAddressDocument from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/AddressDocument";
import LINReleventCertificate from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ReleventCertificate";
import LINSecurityClearence1 from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/SecurityClearence1";
import LINSecurityClearence2 from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/SecurityClearence2";
import LINSecurityClearence3 from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/SecurityClearence3";
import LINSecurityClearence4 from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/SecurityClearence4";
import ViewOtherExperienceDoc from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/OtherExperienceDocument";
import ViewCVDoc from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/CV";
import ViewInsuranceDoc from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/InsuranceDocument";
import ViewInterpretingDoc from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/InterpretingDocument";
import ViewTranslationDoc from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/TranslationDocument";
import ViewPSIPSTDoc from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/PSIPSTDocument";
import ViewWorkReferenceLetter from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/WorkReferenceDocument";
import ViewReleventCertificate from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/ReleventCertificate";
import ViewReleventDoc from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/ReleventDocument";
import ViewSecurityDoc1 from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/SecurityClearence1";
import ViewSecurityDoc2 from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/SecurityClearence2";
import ViewSecurityDoc3 from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/SecurityClearence3";
import ViewSecurityDoc4 from "components/Employee/BookingPanel/ViewLinguist/DocumentsNew/ViewDocuments/SecurityClearence4";

import LINOldPassport from "components/Employee/BookingPanel/ViewLinguist/Documents/Passport";
import LINOldAddress from "components/Employee/BookingPanel/ViewLinguist/Documents/ProofAddressPhoto";
import LINOldReleventDocument from "components/Employee/BookingPanel/ViewLinguist/Documents/ReleventDocument";
import LINOldReleventCertificate from "components/Employee/BookingPanel/ViewLinguist/Documents/ReleventCertificate";
import LINOldSecurityClearence from "components/Employee/BookingPanel/ViewLinguist/Documents/SecurityClearence";
import LINOldCV from "components/Employee/BookingPanel/ViewLinguist/Documents/CV";
import LINOldIsuranceDocument from "components/Employee/BookingPanel/ViewLinguist/Documents/InsuranceDocument";
import LINOldReferenceLetter from "components/Employee/BookingPanel/ViewLinguist/Documents/ReferenceLetter";
import LINOldInterpretingDocument from "components/Employee/BookingPanel/ViewLinguist/Documents/InterpretingDocument";
import LINOldTranslationDocument from "components/Employee/BookingPanel/ViewLinguist/Documents/TranslationDocument";
import LINOldPSIPSTDocument from "components/Employee/BookingPanel/ViewLinguist/Documents/PSIPSTDocument";
import LINOldAnyOtherDocument from "components/Employee/BookingPanel/ViewLinguist/Documents/AnyOtherDocument";
import ViewUnregisteredLinguistsCV from "pages/employee/ViewUnregisteredLinguistsCV";

// Icons
import Dicon from "assets/icons/dashboardAC.png";
import ActivityLogIcon from "assets/icons/activitylog.png";
import Ticon from "assets/icons/tasklist.png";
import NTMicon from "assets/icons/my noteto do list.png";
// import CARPicon from "assets/icons/carp-card.png";
import BSicon from "assets/icons/booking-secretary.png";
import FLicon from "assets/icons/find-linguist.png";
import PTicon from "assets/icons/project-tasks.png";
import PCicon from "assets/icons/price-calculator.png";
import VBDicon from "assets/icons/view bank details-01.png";
import IAicon from "assets/icons/interpreting-assignments.png";
import IIAicon from "assets/icons/inhouse-interpreting-assignment.png";
import TAicon from "assets/icons/translation-assignments.png";
import ITAicon from "assets/icons/inhouse-translation-assignments.png";
import QFCicon from "assets/icons/quotation-from-clients.png";

import JAicon from "assets/icons/job-advert.png";
import CRicon from "assets/icons/contact-request.png";
import CMicon from "assets/icons/communication-menuAC.png";
import VCicon from "assets/icons/view-carp-card.png";
import VPicon from "assets/icons/view-percentage-card.png";
import VRicon from "assets/icons/view-rate-card.png";
import NCCRicon from "assets/icons/notes-02.png";
import NMCRicon from "assets/icons/my notes-02.png";
import LEicon from "assets/icons/leads-01.png";
import DQicon from "assets/icons/direct qoutes-03.png";
import PIJicon from "assets/icons/private interpreting-01.png";
import PTJicon from "assets/icons/private translation-01.png";
import PQJicon from "assets/icons/private quotes-01.png";
import UCAicon from "assets/icons/unconfirmed assignments-01.png";
import Iicon from "assets/icons/invoice.png";
import HPicon from "assets/icons/help contact-01.png";

import Help from "pages/employee/Help";
import OpenNewTicket from "pages/employee/Communication/CreateTicket";
import ViewCommunication from "pages/employee/Communication/TicketList";
import ViewAttachConversation from "pages/employee/Communication/ViewAttachConversation";
import ViewAttachTicket from "pages/employee/Communication/ViewAttachTicket";

import PriceCalculator from "pages/employee/PriceCalculator";
import PercentageCardView from "pages/employee/PercentageCardView";
import RateCardViewBookingSecretary from "pages/employee/RateCardPage";
import CarpCardViewBookingSecretary from "pages/employee/CarpCardPage";
import ViewBankDetailsBookingSecretary from "pages/employee/BankDetailsPage";
import HelpContact from "pages/employee/BookingSecretary/HelpContact/Others";

import LeadsBooking from "pages/employee/BookingSecretary/Leads/Leads";
import SendCLARegistrationForm from "pages/employee/BookingSecretary/Leads/SendCLARegistrationForm";
import SendCACBACRegistrationForm from "pages/employee/BookingSecretary/Leads/SendCACBACForm";
import ViewCLADetails from "pages/employee/BookingSecretary/Leads/ViewCLADetails";

import Projects from "pages/employee/Projects";
import FindLinguist from "pages/employee/BookingSecretary/FindLinguist/FindLinguist";
import UnregisteredLinguistList from "pages/employee/BookingSecretary/FindLinguist/UnregisteredLinguists";
import ViewUnregisteredLinguists from "pages/employee/ViewUnregisteredLinguists";
import ViewLinguistProfile from "pages/employee/ViewLinguistProfile";
import RegisteredLinguistList from "pages/employee/BookingSecretary/FindLinguist/RegisteredLinguists";

//help contact
import HelpCAC from "pages/employee/BookingSecretary/HelpContact/CAC";
import HelpBAC from "pages/employee/BookingSecretary/HelpContact/BAC";
import HelpLIN from "pages/employee/BookingSecretary/HelpContact/LIN";
import HelpSPPO from "pages/employee/BookingSecretary/HelpContact/SPPO";

import ViewLinQuestion from "pages/employee/BookingSecretary/HelpContact/ViewLinQuestion";
import ViewCACQuestion from "pages/employee/BookingSecretary/HelpContact/ViewCACQuestion";
import ViewBACQuestion from "pages/employee/BookingSecretary/HelpContact/ViewBACQuestion";
import ViewSPPOQuestion from "pages/employee/BookingSecretary/HelpContact/ViewSPPOQuestion";

// start Assignment routes
import cacconfirmbooking from "pages/employee/JobList/CAC/Booking";
import bacconfirmbooking from "pages/employee/JobList/BAC/Booking";
import inhouseconfirmbooking from "pages/employee/JobList/Inhouse/Booking";

import cacconfirmTranslation from "pages/employee/JobList/CAC/Translation";
import bacconfirmTranslation from "pages/employee/JobList/BAC/Translation";
import inhouseconfirmTranslation from "pages/employee/JobList/Inhouse/Translation";

import bacconfirmQuotations from "pages/employee/JobList/BAC/Quotations";
import cacconfirmQuotations from "pages/employee/JobList/CAC/Quotations";

import ViewCashClientBooking from "pages/employee/Cashclient/ViewCashClientBooking";
import ViewAccountClientBooking from "pages/employee/Accountclient/ViewAccountClientBooking";
import ViewInhouseClientBooking from "pages/employee/Inhouse/ViewInhouseClientBooking";
import ViewPrivateClientBooking from "pages/employee/Private/ViewPrivateClientBooking";

import ViewInhouseClientTranslation from "pages/employee/Inhouse/ViewInhouseTranslation";
import ViewPrivateClientTranslation from "pages/employee/Private/ViewPrivateTranslation";
import ViewCashClientTranslation from "pages/employee/Cashclient/ViewCashClientTranslation";
import ViewAccountClientTranslation from "pages/employee/Accountclient/ViewAccountclientTranslation";

import ViewCashClientQuotation from "pages/employee/Cashclient/ViewCashClientQuotation";
import ViewAccountClientQuotation from "pages/employee/Accountclient/ViewAccountClientQuotation";

import ViewInvoice from "pages/employee/TimesheetProjectReport/Invoice/ViewInvoice";
import ViewTimesheet from "pages/employee/TimesheetProjectReport/Timesheet/ViewTimesheet";
import ViewProjectReport from "pages/employee/TimesheetProjectReport/ProjectReport/ViewProjectReport";

// booking secretary inc interpreting panel
import ConfirmAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Interpreting/ConfirmAssignments";
import AssignedAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Interpreting/AssignedAssignments";
import ClosedAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Interpreting/ClosedAssignments";
import InvoicedAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Interpreting/InvoicedAssignments";
import CompletedAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Interpreting/CompletedAssignments";
import CanceledAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Interpreting/CanceledAssignments";

// booking secretary prc interpreting panel
import ConfirmAssignmentsPRC from "pages/employee/BookingSecretary/Private/PrivateInterpreting/ConfirmAssignments";
import ClosedAssignmentsPRC from "pages/employee/BookingSecretary/Private/PrivateInterpreting/ClosedAssignments";
import InvoicedAssignmentsPRC from "pages/employee/BookingSecretary/Private/PrivateInterpreting/InvoicedAssignments";
import CompletedAssignmentsPRC from "pages/employee/BookingSecretary/Private/PrivateInterpreting/CompletedAssignments";
import CanceledAssignmentsPRC from "pages/employee/BookingSecretary/Private/PrivateInterpreting/CanceledAssignments";

// booking secretary inc translation panel
import ConfirmTAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Translation/ConfirmAssignments";
import AssignedTAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Translation/AssignedAssignments";
import ClosedTAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Translation/ClosedAssignments";
import InvoicedTAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Translation/InvoicedAssignments";
import CompletedTAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Translation/CompletedAssignments";
import CanceledTAssignmentsINC from "pages/employee/BookingSecretary/Inhouse/Translation/CanceledAssignments";

// booking secretary prc translation panel
import ConfirmTAssignmentsPRC from "pages/employee/BookingSecretary/Private/PrivateTranslation/ConfirmAssignments";
import ClosedTAssignmentsPRC from "pages/employee/BookingSecretary/Private/PrivateTranslation/ClosedAssignments";
import InvoicedTAssignmentsPRC from "pages/employee/BookingSecretary/Private/PrivateTranslation/InvoicedAssignments";
import CompletedTAssignmentsPRC from "pages/employee/BookingSecretary/Private/PrivateTranslation/CompletedAssignments";
import CanceledTAssignmentsPRC from "pages/employee/BookingSecretary/Private/PrivateTranslation/CanceledAssignments";

import AssignmentDocument from "pages/Component/ViewEMPAssignmentDocuments";
// view documents
import ViewCACInterpretingDocument from "pages/employee/Cashclient/ViewCACInterpretingDocument";
import ViewCACTranslationDocument from "pages/employee/Cashclient/ViewCACTranslationDocument";
import ViewBACInterpretingDocument from "pages/employee/Accountclient/ViewBACInterpretingDocument";
import ViewBACTranslationDocument from "pages/employee/Accountclient/ViewBACTranslationDocument";
import viewINCInterpretingDocument from "pages/employee/Inhouse/ViewINCInterpretingDocument";
import viewINCTranslationDocument from "pages/employee/Inhouse/ViewINCTranslationDocument";
import viewPRCInterpretingDocument from "pages/employee/Private/ViewPRCInterpretingDocument";
import viewPRCTranslationDocument from "pages/employee/Private/ViewPRCTranslationDocument";
import viewCACQuoteDocument from "pages/employee/Cashclient/ViewCACQuoteDocument";
import viewBACQuoteDocument from "pages/employee/Accountclient/ViewBACQuoteDocument";

// unconfirm assignments
import UnconfirmedAssignmentsPage from "pages/employee/BookingSecretary/UnconfirmedAssignmentsPage/UnconfirmedAssignmentsPage";
import CACUnconfirmedAssignments from "pages/employee/BookingSecretary/UnconfirmedAssignmentsPage/CACUnconfirmedAssignments";
import BACUnconfirmedAssignments from "pages/employee/BookingSecretary/UnconfirmedAssignmentsPage/BACUnconfirmedAssignmentsPage";
import INCUnconfirmedAssignments from "pages/employee/BookingSecretary/UnconfirmedAssignmentsPage/INCUnconfirmedAssignments";
import PRCUnconfirmedAssignments from "pages/employee/BookingSecretary/UnconfirmedAssignmentsPage/PRCUnconfirmedAssignments";
import AllInactiveAssignments from "pages/employee/BookingSecretary/UnconfirmedAssignmentsPage/AllInactiveAssignments";

// direct quote routes
import ClientDirectQuote from "pages/employee/Quotation/quote";
import ViewDirectQuote from "pages/employee/Quotation/ViewDirectQuote";
import CreateDirectQuoteProjectReport from "pages/employee/Quotation/CreateDirectQuoteProjectReport";
import ViewDirectQuoteProjectReport from "pages/employee/Quotation/ViewDirectQuoteProjectReport";
import CreateDirectQuoteInvoice from "pages/employee/Quotation/CreateDirectQuoteInvoice";

// provided quote by linguist
import ViewCACLINProvidedQuote from "pages/employee/Quotation/ViewLINProvidedQuote";

// private client direct quote by linguist
import PrivateQuote from "pages/employee/BookingSecretary/PrivateQuote/PrivateQuote";
import PrivateSentQuote from "pages/employee/BookingSecretary/PrivateQuote/PrivateSentQuote";
import PrivateSavedQuote from "pages/employee/BookingSecretary/PrivateQuote/PrivateSavedQuote";
import ViewPRCQuote from "pages/employee/BookingSecretary/PrivateQuote/ViewPRCQuote";

import CreateQuoteProjectReport from "pages/employee/TimesheetProjectReport/ProjectReport/CreateQuoteProjectReport";
import RemarkQuoteProjectReport from "pages/employee/TimesheetProjectReport/ProjectReport/RemarkQuoteProjectReport";
import ViewQuoteProjectReport from "pages/employee/TimesheetProjectReport/ProjectReport/ViewProjectReport";

//Activity logs
import ActivityLogs from "pages/employee/ActivityLogs/ActivityLogs";

import ViewLeadPassport from "components/Employee/Leads/Documents/ViewPassport";
import ViewLeadQuoteDocument from "components/Employee/Leads/Documents/ViewEnquiryFile";

import TranslationDeliveryDoc from "pages/Component/Translation_Delivery/EMP/ViewDocument";
import Notifications from "pages/employee/Notifications/Notifications";
import ViewAttachMessage from "pages/employee/WelcomeMessage/ViewAttachMessage";

const EMPLOYEE = [
  {
    id: uuidv4(),
    text: "Dashboard",
    subText: "Employee Dashboard",
    path: "employee/dashboard",
    type: "dashboard",
    component: EmployeeDashboard,
    icon: Dicon,
  },
  {
    id: uuidv4(),
    text: "Booking Secretary",
    path: "booking-secretary",
    icon: BSicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "CAC & BAC Interpreting assignments",
        component: InterpretingList,
        path: "employee/interpreting-assignments",
        icon: IAicon,
      },
      {
        id: uuidv4(),
        text: "CAC & BAC Translation assignments",
        component: TranslationList,
        path: "employee/translation-assignments",
        icon: TAicon,
      },
      {
        id: uuidv4(),
        text: "CAC & BAC Quotation",
        component: QuotationFromClients,
        path: "employee/quotation-from-clients",
        icon: QFCicon,
      },

      {
        id: uuidv4(),
        text: "Inhouse Interpreting Assignments",
        component: InhouseInterpretingAssignments,
        path: "employee/inhouse-interpreting-assignments",
        icon: IIAicon,
      },
      {
        id: uuidv4(),
        text: "Inhouse Translation Assignments",
        component: InhouseTranslationAssignments,
        path: "employee/inhouse-translation-assignments",
        icon: ITAicon,
      },

      {
        id: uuidv4(),
        text: "Private Interpreting Assignments",
        component: PrivateInterpretingAssignments,
        path: "employee/private-interpreting-assignments",
        icon: PIJicon,
      },
      {
        id: uuidv4(),
        text: "Private Translation Assignments",
        component: PrivateTranslationAssignments,
        path: "employee/private-translation-assignments",
        icon: PTJicon,
      },
      {
        id: uuidv4(),
        text: "Private Quotes",
        component: PrivateQuote,
        path: "employee/private-quote",
        icon: PQJicon,
      },

      {
        id: uuidv4(),
        text: "Unconfirmed Assignments",
        path: "employee/unconfirmed-assignments",
        component: UnconfirmedAssignmentsPage,
        icon: UCAicon,
      },

      {
        id: uuidv4(),
        text: "Job Advert",
        component: PaidJobAdvert,
        path: "employee/paid-job-advert",
        icon: JAicon,
      },

      {
        id: uuidv4(),
        text: "Direct invoice",
        component: InvoicePage,
        path: "employee/direct-invoice",
        icon: Iicon,
      },
      // {
      //   id: uuidv4(),
      //   text: "Projects",
      //   component: Projects,
      //   path: "employee/projects",
      //   icon: PTicon,
      // },
      {
        id: uuidv4(),
        text: "Find Linguist",
        component: FindLinguist,
        path: "employee/find-linguist",
        icon: FLicon,
      },
      {
        id: uuidv4(),
        text: "Direct Quote By LC",
        component: FindQuote,
        path: "employee/find-quote",
        icon: DQicon,
      },
      {
        id: uuidv4(),
        text: "Leads",
        component: LeadsBooking,
        path: "employee/bookingsecretary/lead",
        icon: LEicon,
      },
      {
        id: uuidv4(),
        text: "Price Calculator",
        component: PriceCalculator,
        path: "employee/price-calculator",
        icon: PCicon,
      },
      {
        id: uuidv4(),
        text: "Rate Card View",
        component: RateCardViewBookingSecretary,
        path: "employee/view-rate-card-BookingSecretary",
        icon: VRicon,
      },
      {
        id: uuidv4(),
        text: "CARP Card View",
        component: CarpCardViewBookingSecretary,
        path: "employee/view-carp-card-BookingSecretary",
        icon: VCicon,
      },
      {
        id: uuidv4(),
        text: "Percentage Card View",
        component: PercentageCardView,
        path: "employee/view-percentage-card",
        icon: VPicon,
      },
      {
        id: uuidv4(),
        text: "Bank Details",
        path: "employee/view-bank-details-booking-secretary",
        component: ViewBankDetailsBookingSecretary,
        icon: VBDicon,
      },
      {
        id: uuidv4(),
        text: "Help Contacts",
        component: HelpContact,
        path: "employee/help-contact",
        icon: HPicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "Task, Note And Do List",
    path: "employee/task-lists",
    component: TaskLists,
    icon: NTMicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Task List",
        path: "employee/task-lists",
        component: TaskLists,
        icon: Ticon,
      },
      {
        id: uuidv4(),
        text: "Notes And Do List - Colleagues",
        path: "employee/colleague-notes",
        component: ColleagueNotes,
        icon: NCCRicon,
      },
      {
        id: uuidv4(),
        text: "My Notes And Do List",
        path: "employee/my-notes",
        component: MyNotes,
        icon: NMCRicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "Communication",
    component: Communication,
    path: "employee/communication",
    icon: CMicon,
  },

  {
    id: uuidv4(),
    text: "Contact Request",
    path: "employee/contact-request",
    component: ContactRequest,
    icon: CRicon,
  },
  {
    id: uuidv4(),
    text: "Activity Logs",
    path: "employee/activity-logs",
    component: ActivityLogs,
    icon: ActivityLogIcon,
  },

  {
    id: uuidv4(),
    path: "employee/help",
    component: Help,
  },
  {
    id: uuidv4(),
    path: "employee/auth/password/reset/:token",
    component: SetNewPassword,
    type: "form",
    subText: "Employee Set Password",
  },
  {
    id: uuidv4(),
    path: "employee/auth/account/activate/:token",
    component: EmployeeAccountActivate,
  },
  {
    id: uuidv4(),
    path: "employee/reset-password",
    component: ResetPassword,
  },
  {
    id: uuidv4(),
    path: "employee/initial-registration",
    component: InitialRegistrationPage,
    type: "form",
    subText: "Employee Initial Registration",
  },
  // booking secretary interpreting route

  // booking secretary cac&bac interpreting route
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/confirm-assignments",
    component: ConfirmCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/confirm-assignments/:data/:type",
    component: ConfirmCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/confirm-assignments",
    component: ConfirmBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/confirm-assignments/:data/:type",
    component: ConfirmBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/assigned-assignments",
    component: AssignedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/assigned-assignments/:data/:type",
    component: AssignedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/assigned-assignments",
    component: AssignedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/assigned-assignments/:data/:type",
    component: AssignedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/closed-assignments",
    component: ClosedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/closed-assignments/:data/:type",
    component: ClosedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/closed-assignments",
    component: ClosedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/closed-assignments/:data/:type",
    component: ClosedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/invoiced-assignments",
    component: InvoicedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/invoiced-assignments/:data/:type",
    component: InvoicedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/invoiced-assignments",
    component: InvoicedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/invoiced-assignments/:data/:type",
    component: InvoicedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/completed-assignments",
    component: CompletedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/completed-assignments/:data/:type",
    component: CompletedCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/completed-assignments",
    component: CompletedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/completed-assignments/:data/:type",
    component: CompletedBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/canceled-assignments",
    component: CanceledCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/cac-interpreting/canceled-assignments/:data/:type",
    component: CanceledCACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/canceled-assignments",
    component: CanceledBACAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/bac-interpreting/canceled-assignments/:data/:type",
    component: CanceledBACAssignments,
  },

  // booking secretary cac&bac translation route
  {
    id: uuidv4(),
    path: "employee/cac-translation/confirm-assignments",
    component: ConfirmCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/confirm-assignments/:data/:type",
    component: ConfirmCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/confirm-assignments",
    component: ConfirmBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/confirm-assignments/:data/:type",
    component: ConfirmBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/assigned-assignments",
    component: AssignedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/assigned-assignments/:data/:type",
    component: AssignedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/assigned-assignments",
    component: AssignedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/assigned-assignments/:data/:type",
    component: AssignedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/closed-assignments",
    component: ClosedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/closed-assignments/:data/:type",
    component: ClosedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/closed-assignments",
    component: ClosedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/closed-assignments/:data/:type",
    component: ClosedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/invoiced-assignments",
    component: InvoicedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/invoiced-assignments/:data/:type",
    component: InvoicedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/invoiced-assignments",
    component: InvoicedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/invoiced-assignments/:data/:type",
    component: InvoicedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/completed-assignments",
    component: CompletedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/completed-assignments/:data/:type",
    component: CompletedCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/completed-assignments",
    component: CompletedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/completed-assignments/:data/:type",
    component: CompletedBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/canceled-assignments",
    component: CanceledCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/cac-translation/canceled-assignments/:data/:type",
    component: CanceledCACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/canceled-assignments",
    component: CanceledBACAssignmentsTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-translation/canceled-assignments/:data/:type",
    component: CanceledBACAssignmentsTranslation,
  },

  // inhouse interpreting routes

  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/confirm-assignments",
    component: ConfirmAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/confirm-assignments/:data/:type",
    component: ConfirmAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/assigned-assignments",
    component: AssignedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/assigned-assignments/:data/:type",
    component: AssignedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/closed-assignments",
    component: ClosedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/closed-assignments/:data/:type",
    component: ClosedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/invoiced-assignments",
    component: InvoicedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/invoiced-assignments/:data/:type",
    component: InvoicedAssignmentsINC,
  },

  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/completed-assignments",
    component: CompletedAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/completed-assignments/:data/:type",
    component: CompletedAssignmentsINC,
  },

  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/canceled-assignments",
    component: CanceledAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-interpreting/canceled-assignments/:data/:type",
    component: CanceledAssignmentsINC,
  },

  // booking secretary prc interpreting route
  {
    id: uuidv4(),
    path: "employee/private-interpreting/confirm-assignments",
    component: ConfirmAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-interpreting/confirm-assignments/:data/:type",
    component: ConfirmAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "employee/private-interpreting/closed-assignments",
    component: ClosedAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-interpreting/closed-assignments/:data/:type",
    component: ClosedAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-interpreting/invoiced-assignments",
    component: InvoicedAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-interpreting/invoiced-assignments/:data/:type",
    component: InvoicedAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "employee/private-interpreting/completed-assignments",
    component: CompletedAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-interpreting/completed-assignments/:data/:type",
    component: CompletedAssignmentsPRC,
  },

  {
    id: uuidv4(),
    path: "employee/private-interpreting/canceled-assignments",
    component: CanceledAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-interpreting/canceled-assignments/:data/:type",
    component: CanceledAssignmentsPRC,
  },

  // booking secretary inc translation route
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/confirm-assignments",
    component: ConfirmTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/confirm-assignments/:data/:type",
    component: ConfirmTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/assigned-assignments",
    component: AssignedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/assigned-assignments/:data/:type",
    component: AssignedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/closed-assignments",
    component: ClosedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/closed-assignments/:data/:type",
    component: ClosedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/invoiced-assignments",
    component: InvoicedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/invoiced-assignments/:data/:type",
    component: InvoicedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/completed-assignments",
    component: CompletedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/completed-assignments/:data/:type",
    component: CompletedTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/canceled-assignments",
    component: CanceledTAssignmentsINC,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-translation/canceled-assignments/:data/:type",
    component: CanceledTAssignmentsINC,
  },
  // booking secretary prc translation route
  {
    id: uuidv4(),
    path: "employee/private-translation/confirm-assignments",
    component: ConfirmTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-translation/confirm-assignments/:data/:type",
    component: ConfirmTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-translation/closed-assignments",
    component: ClosedTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-translation/closed-assignments/:data/:type",
    component: ClosedTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-translation/invoiced-assignments",
    component: InvoicedTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-translation/invoiced-assignments/:data/:type",
    component: InvoicedTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-translation/completed-assignments",
    component: CompletedTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-translation/completed-assignments/:data/:type",
    component: CompletedTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-translation/canceled-assignments",
    component: CanceledTAssignmentsPRC,
  },
  {
    id: uuidv4(),
    path: "employee/private-translation/canceled-assignments/:data/:type",
    component: CanceledTAssignmentsPRC,
  },
  // booking secretary panel cac&bac quotation from clients routes
  {
    id: uuidv4(),
    path: "employee/quotation/cac-requestedQuote",
    component: RequestedCACQuote,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-requestedQuote/:data/:type",
    component: RequestedCACQuote,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-requestedQuote",
    component: RequestedBACQuote,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-requestedQuote/:data/:type",
    component: RequestedBACQuote,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-respondedQuotedProvided",
    component: RespondedCACQuoteProvided,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-respondedQuotedProvided/:data/:type",
    component: RespondedCACQuoteProvided,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-respondedQuotedProvided",
    component: RespondedBACQuoteProvided,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-respondedQuotedProvided/:data/:type",
    component: RespondedBACQuoteProvided,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-byClientQuote",
    component: ConfirmedByCACCliendQuote,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-byClientQuote/:data/:type",
    component: ConfirmedByCACCliendQuote,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-byClientQuote",
    component: ConfirmedByBACCliendQuote,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-byClientQuote/:data/:type",
    component: ConfirmedByBACCliendQuote,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-closed-jobs",
    component: ClosedCACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-closed-jobs/:data/:type",
    component: ClosedCACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-closed-jobs",
    component: ClosedBACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-closed-jobs/:data/:type",
    component: ClosedBACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-invoiced-jobs",
    component: InvoicedCACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-invoiced-jobs/:data/:type",
    component: InvoicedCACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-invoiced-jobs",
    component: InvoicedBACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-invoiced-jobs/:data/:type",
    component: InvoicedBACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-completed-jobs",
    component: CompletedCACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-completed-jobs/:data/:type",
    component: CompletedCACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-completed-jobs",
    component: CompletedBACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-completed-jobs/:data/:type",
    component: CompletedBACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-canceled-jobs",
    component: CanceledCACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/cac-canceled-jobs/:data/:type",
    component: CanceledCACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-canceled-jobs",
    component: CanceledBACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/bac-canceled-jobs/:data/:type",
    component: CanceledBACJobs,
  },
  {
    id: uuidv4(),
    path: "employee/accepted-jobadvert",
    component: ViewAcceptedjob,
  },
  {
    id: uuidv4(),
    path: "employee/accepted-jobadvert/:data/:type",
    component: ViewAcceptedjob,
  },
  {
    id: uuidv4(),
    path: "employee/declined-jobadvert",
    component: ViewDeclinedJob,
  },
  {
    id: uuidv4(),
    path: "employee/declined-jobadvert/:data/:type",
    component: ViewDeclinedJob,
  },
  {
    id: uuidv4(),
    path: "employee/view-jobadvert/:id",
    component: ViewJObAdvert,
  },
  {
    id: uuidv4(),
    path: "employee/view-inhouse/:id",
    component: ViewInhouseDetails,
  },
  {
    id: uuidv4(),
    path: "employee/view-private/:id",
    component: ViewPrivateDetails,
  },
  {
    id: uuidv4(),
    path: "employee/view-sppo/:id",
    component: ViewSppoDetails,
  },
  {
    id: uuidv4(),
    path: "employee/view-cashclient/:id",
    component: ViewCashclientDetails,
  },
  {
    id: uuidv4(),
    path: "employee/view-account-client/:id",
    component: ViewAccountclientDetails,
  },
  {
    id: uuidv4(),
    path: "employee/view-contact-Request/:id",
    component: ViewContactRequest,
  },
  {
    id: uuidv4(),
    path: "employee/profile",
    component: ProfilePage,
  },

  {
    id: uuidv4(),
    path: "employee/company-info-card",
    component: CIC,
  },

  // ticket routes
  {
    id: uuidv4(),
    path: "employee/open-new-ticket",
    component: OpenNewTicket,
  },
  {
    id: uuidv4(),
    path: "employee/view-tickets",
    component: ViewCommunication,
  },
  {
    id: uuidv4(),
    path: "employee/view-attachment-ticket-conversation/:id",
    component: ViewAttachConversation,
  },
  {
    id: uuidv4(),
    path: "employee/view-ticket-attachment/:id",
    component: ViewAttachTicket,
  },
  {
    id: uuidv4(),
    path: "employee/send-cla-registration-form",
    component: SendCLARegistrationForm,
  },
  {
    id: uuidv4(),
    path: "employee/send-CAC-BAC-registration-form",
    component: SendCACBACRegistrationForm,
  },
  {
    id: uuidv4(),
    path: "employee/view-cla/:id",
    component: ViewCLADetails,
  },
  {
    id: uuidv4(),
    path: "employee/find-unregistered-linguists",
    component: UnregisteredLinguistList,
  },
  {
    id: uuidv4(),
    path: "employee/find-registered-linguists",
    component: RegisteredLinguistList,
  },
  {
    id: uuidv4(),
    path: "employee/help-cac",
    component: HelpCAC,
  },
  {
    id: uuidv4(),
    path: "employee/help-bac",
    component: HelpBAC,
  },
  {
    id: uuidv4(),
    path: "employee/help-lin",
    component: HelpLIN,
  },
  {
    id: uuidv4(),
    path: "employee/help-sppo",
    component: HelpSPPO,
  },
  {
    id: uuidv4(),
    path: "employee/view-linguist-question/:id",
    component: ViewLinQuestion,
  },
  {
    id: uuidv4(),
    path: "employee/view-cash-client-question/:id",
    component: ViewCACQuestion,
  },
  {
    id: uuidv4(),
    path: "employee/view-account-client-question/:id",
    component: ViewBACQuestion,
  },
  {
    id: uuidv4(),
    path: "employee/view-sppo-question/:id",
    component: ViewSPPOQuestion,
  },

  // start assignment routes
  {
    id: uuidv4(),
    path: "employee/cac-confirmed-interpreters",
    component: cacconfirmbooking,
  },
  {
    id: uuidv4(),
    path: "employee/bac-confirmed-interpreters",
    component: bacconfirmbooking,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-confirmed-interpreters",
    component: inhouseconfirmbooking,
  },

  {
    id: uuidv4(),
    path: "employee/cac-confirmed-translations",
    component: cacconfirmTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/bac-confirmed-translations",
    component: bacconfirmTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/inhouse-confirmed-translations",
    component: inhouseconfirmTranslation,
  },

  {
    id: uuidv4(),
    path: "employee/cac-confirmed-quotations",
    component: cacconfirmQuotations,
  },
  {
    id: uuidv4(),
    path: "employee/bac-confirmed-quotations",
    component: bacconfirmQuotations,
  },

  // view assignment(interpreting, translation and quotation for cac, bac, inc and prc) details page route
  {
    id: uuidv4(),
    path: "employee/view-cash-client-booking-interpreting/:id",
    component: ViewCashClientBooking,
  },
  {
    id: uuidv4(),
    path: "employee/view-account-client-booking-interpreting/:id",
    component: ViewAccountClientBooking,
  },
  {
    id: uuidv4(),
    path: "employee/view-inhouse-client-booking-interpreting/:id",
    component: ViewInhouseClientBooking,
  },
  {
    id: uuidv4(),
    path: "employee/view-private-client-booking-interpreting/:id",
    component: ViewPrivateClientBooking,
  },

  // translation view details
  {
    id: uuidv4(),
    path: "employee/view-inhouse-client-translation/:id",
    component: ViewInhouseClientTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/view-private-client-translation/:id",
    component: ViewPrivateClientTranslation,
  },
  {
    id: uuidv4(),
    path: "employee/view-cash-client-order-translation/:id",
    component: ViewCashClientTranslation,
  },

  {
    id: uuidv4(),
    path: "employee/view-account-client-order-translation/:id",
    component: ViewAccountClientTranslation,
  },

  // quotation routes for cac and bac
  {
    id: uuidv4(),
    path: "employee/view-cash-client-quotation/:id",
    component: ViewCashClientQuotation,
  },
  {
    id: uuidv4(),
    path: "employee/view-account-client-quotation/:id",
    component: ViewAccountClientQuotation,
  },

  // invoice, timesheet and project report routes
  {
    id: uuidv4(),
    path: "employee/view-invoice/:id",
    component: ViewInvoice,
  },

  {
    id: uuidv4(),
    path: "employee/view-timesheet/:id",
    component: ViewTimesheet,
  },

  {
    id: uuidv4(),
    path: "employee/report/reportID/:id",
    component: ViewProjectReport,
  },

  // interpreting and translation document
  {
    id: uuidv4(),
    path: "employee/view-cac-interpreting-document/:id",
    component: ViewCACInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "employee/view-cac-translation-document/:id",
    component: ViewCACTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "employee/view-bac-interpreting-document/:id",
    component: ViewBACInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "employee/view-bac-translation-document/:id",
    component: ViewBACTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "employee/inc-view-interpreting-document/:id",
    component: viewINCInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "employee/inc-view-translation-document/:id",
    component: viewINCTranslationDocument,
  },
  {
    id: uuidv4(),
    path: "employee/prc-view-interpreting-document/:id",
    component: viewPRCInterpretingDocument,
  },
  {
    id: uuidv4(),
    path: "employee/prc-view-translation-document/:id",
    component: viewPRCTranslationDocument,
  },

  // view quote document
  {
    id: uuidv4(),
    path: "employee/cac-view-quotation-document/:id",
    component: viewCACQuoteDocument,
  },
  {
    id: uuidv4(),
    path: "employee/bac-view-quotation-document/:id",
    component: viewBACQuoteDocument,
  },
  {
    id: uuidv4(),
    path: "employee/view-assignment-document/:id",
    component: AssignmentDocument,
  },

  // end

  //Booking secretary unconfirmed assignment routes
  {
    id: uuidv4(),
    path: "employee/unconfirmed-assignments/cash-client",
    component: CACUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/unconfirmed-assignments/cash-client/:data/:type",
    component: CACUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/unconfirmed-assignments/billing-account-client",
    component: BACUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/unconfirmed-assignments/billing-account-client/:data/:type",
    component: BACUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/unconfirmed-assignments/inhouse-client",
    component: INCUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/unconfirmed-assignments/inhouse-client/:data/:type",
    component: INCUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/unconfirmed-assignments/private-client",
    component: PRCUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/unconfirmed-assignments/private-client/:data/:type",
    component: PRCUnconfirmedAssignments,
  },
  {
    id: uuidv4(),
    path: "employee/all-inactive-assignments",
    component: AllInactiveAssignments,
  },

  {
    id: uuidv4(),
    component: CreateQuotation,
    path: "employee/create-quotation",
  },
  {
    id: uuidv4(),
    path: "employee/client-direct-quotes/:id/:name/:clientref",
    component: ClientDirectQuote,
  },
  {
    id: uuidv4(),
    path: "employee/view-direct-quote/:id",
    component: ViewDirectQuote,
  },
  {
    id: uuidv4(),
    path: "employee/view-provided-quote/:id",
    component: ViewCACLINProvidedQuote,
  },
  {
    id: uuidv4(),
    path: "employee/private-quote/sent-quote",
    component: PrivateSentQuote,
  },
  {
    id: uuidv4(),
    path: "employee/private-quote/saved-quote",
    component: PrivateSavedQuote,
  },
  {
    id: uuidv4(),
    path: "employee/view-prc-quote-bylinguist/:id",
    component: ViewPRCQuote,
  },

  // direct quote routes
  {
    id: uuidv4(),
    path: "employee/create-direct-quote-project-report/:id",
    component: CreateDirectQuoteProjectReport,
  },

  {
    id: uuidv4(),
    path: "employee/direct-quote/view-project-report/:id",
    component: ViewDirectQuoteProjectReport,
  },

  {
    id: uuidv4(),
    path: "employee/create-direct-quote-invoice/:id",
    component: CreateDirectQuoteInvoice,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/byClientQuote/create-project-report/:id",
    component: CreateQuoteProjectReport,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/byClientQuote/remark-project-report/:id",
    component: RemarkQuoteProjectReport,
  },
  {
    id: uuidv4(),
    path: "employee/quotation/byClientQuote/view-project-report/:id",
    component: ViewQuoteProjectReport,
  },
  {
    id: uuidv4(),
    component: UnpaidJobAdvert,
    path: "employee/unpaid-job-advert",
  },

  {
    id: uuidv4(),
    component: UnpaidJobAdvert,
    path: "employee/unpaid-job-advert/:data/:type",
  },
  {
    id: uuidv4(),
    component: PaidJobAdvert,
    path: "employee/paid-job-advert/:data/:type",
  },

  {
    id: uuidv4(),
    component: ViewJObAdvert,
    path: "employee/view-job-advert/:id",
    icon: JAicon,
  },

  {
    id: uuidv4(),
    component: ViewJObAdvertDocument,
    path: "employee/view-job-advert-document/:id",
    icon: JAicon,
  },

  // lead documents
  {
    id: uuidv4(),
    component: ViewLeadPassport,
    path: "employee/lead-passport-document-view/:id",
    icon: JAicon,
  },

  {
    id: uuidv4(),
    component: ViewLeadQuoteDocument,
    path: "employee/lead-quote-enquiry-documents/:id",
    icon: JAicon,
  },

  // view translation delivery document
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "employee/view-translation-delivery-document1/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "employee/view-translation-delivery-document2/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "employee/view-translation-delivery-document3/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "employee/view-translation-delivery-document4/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    component: TranslationDeliveryDoc,
    path: "employee/view-translation-delivery-document5/:id",
    icon: JAicon,
  },
  {
    id: uuidv4(),
    path: "employee/view-all-notifications",
    component: Notifications,
  },
  {
    id: uuidv4(),
    path: "employee/view-welcome-message-attachment/:id/:message",
    component: ViewAttachMessage,
  },
  {
    id: uuidv4(),
    path: "employee/view-unregistered-linguist-client/:id",
    component: ViewUnregisteredLinguists,
  },
  {
    id: uuidv4(),
    path: "employee/view-linguist-profile/:id",
    component: ViewLinguistProfile,
  },
  // {
  //   id: uuidv4(),
  //   path: "employee/upload-linguist-documents/:id",
  //   component: UploadLINDoc,
  // },
  // linguist documents
  {
    id: uuidv4(),
    path: "employee/linguist-profile-photo-preview/:id",
    component: LINProfilePhoto,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-address-preview/:id",
    component: LINAddress,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-old-address-preview/:id",
    component: LINOldAddress,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-relevent-document/:id",
    component: LINReleventDocument,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-old-relevent-document/:id",
    component: LINOldReleventDocument,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-insurance-document/:id",
    component: LINIsuranceDocument,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-old-insurance-document/:id",
    component: LINOldIsuranceDocument,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-reference-letter/:id",
    component: LINReferenceLetter,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-old-reference-letter/:id",
    component: LINOldReferenceLetter,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-relevent-certificate/:id",
    component: LINReleventCertificate,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-old-relevent-certificate/:id",
    component: LINOldReleventCertificate,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-old-security-clearence/:id",
    component: LINOldSecurityClearence,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-security-clearence-1/:id",
    component: LINSecurityClearence1,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-security-clearence-2/:id",
    component: LINSecurityClearence2,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-security-clearence-3/:id",
    component: LINSecurityClearence3,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-security-clearence-4/:id",
    component: LINSecurityClearence4,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-other-experience-document/:id",
    component: ViewOtherExperienceDoc,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-cv-document/:id",
    component: ViewCVDoc,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-insurance-document/:id",
    component: ViewInsuranceDoc,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-interpreting-document/:id",
    component: ViewInterpretingDoc,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-translation-document/:id",
    component: ViewTranslationDoc,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-psi-pst-document/:id",
    component: ViewPSIPSTDoc,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-work-reference-letter/:id",
    component: ViewWorkReferenceLetter,
  },

  {
    id: uuidv4(),
    path: "employee/linguist-view-relevent-certificate-document/:id",
    component: ViewReleventCertificate,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-relevent-document/:id",
    component: ViewReleventDoc,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-security-clearence-document1/:id",
    component: ViewSecurityDoc1,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-security-clearence-document2/:id",
    component: ViewSecurityDoc2,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-security-clearence-document3/:id",
    component: ViewSecurityDoc3,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-security-clearence-document4/:id",
    component: ViewSecurityDoc4,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-passport/:id",
    component: LINPassport,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-old-passport/:id",
    component: LINOldPassport,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-passport-document/:id",
    component: LINPassportDocument,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-view-address-document/:id",
    component: LINAddressDocument,
  },
  {
    id: uuidv4(),
    path: "employee/upload-linguist-documents/:id",
    component: UploadLINDoc,
  },
  {
    id: uuidv4(),
    path: "employee/view-unregistered-linguist-cv/:id",
    component: ViewUnregisteredLinguistsCV,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-cv/:id",
    component: LINCV,
  },
  {
    id: uuidv4(),
    path: "employee/linguist-old-cv/:id",
    component: LINOldCV,
  },
  {
    id: uuidv4(),
    path: "employee/registered-unregistered-linguists-list",
    component: SearchAllLin,
  },
  {
    id: uuidv4(),
    path: "employee/registered-unregistered-multi-linguists-list",
    component: SearchAllMultiLin,
  },
];

export default EMPLOYEE;
