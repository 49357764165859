import React from "react";

import { useHistory } from "react-router-dom";

//Materials Core
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const InvoicingOptions = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          history.push("/linguist/invoice-list");
        }}
      >
        Invoiced
      </Button>
      <Button
        color="primary"
        variant="contained"
        color="primary"
        variant="contained"
        onClick={() => {
          history.push("/linguist/paid-invoices-list");
        }}
      >
        Paid Invoices
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          history.push("/linguist/received-invoices-list");
        }}
      >
        Received Invoices
      </Button>
    </div>
  );
};

export default InvoicingOptions;
