import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getSupportSystem } from "../../../../../action/linguistAction";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 500,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload]);

  const loadbookingList = () => {
    getSupportSystem().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setValues({ ...values, bookingList: data });
      }
    });
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>{list.company_name}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Company Address</TableCell>
                <TableCell>{list.company_address}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Phone Number</TableCell>
                <TableCell>{list.company_phone_no}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>General Email</TableCell>
                <TableCell>{list.company_general_email}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Urgent Enquiry Mobile Number</TableCell>
                <TableCell>{list.company_emergency_mobile_no}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Company Legal Name</TableCell>
                <TableCell>{list.company_legal_name}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>Company Registration Number</TableCell>
                <TableCell>{list.company_registration_no}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>VAT / GST Number</TableCell>
                <TableCell>{list.vat_number}</TableCell>
              </TableRow>
            ))}
            {bookingList.map((list) => (
              <TableRow>
                <TableCell>VAT / GST Country Name</TableCell>
                <TableCell>{list.vat_country_name}</TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </TableContainer>
    </Paper>
  );
}
