import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie'
import Axios from "utils/axios";

// Custom Components
import QuoteRqstTel from "components/Auth/QRT/QuoteRqst";
import MasterLayout from "layout/MasterLayout";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

const QRTPage = () => {


  const [data, setData] = useState(null)

  const getAdminDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setData(response?.data);
  };
  useEffect(() => {
    getAdminDetails();
  }, []);


  return (

    <MasterLayout>
      <Manager>
        <QuoteRqstTel adminID={data?.id} adminName={data?.FirstName} />
      </Manager>
    </MasterLayout>

  );
};

export default QRTPage;
