import { Card } from "@material-ui/core";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import OTicon from "assets/icons/book-translation-06.png";
import BIicon from "assets/icons/order-translation.png";
import { Button, Icon, Modal } from "semantic-ui-react";
import history from "utils/history";
import CustomAvatar from "components/Common/CustomAvatar";
import Axios from "axios";
import { useParams } from "react-router";

const ThreeCards = ({ refID, objID }) => {
  const classes = useLandingpageStyles();
  const { id } = useParams();
  const [firstOpen, setFirstOpen] = React.useState(false);
  const [secondOpen, setSecondOpen] = React.useState(false);
  const [incDetails, setINCDetails] = React.useState();
  const [data, setData] = React.useState({
    clientID: "",
    mobile_no: "",
    City: "",
  });


  const { clientID, mobile_no, City } = data;


  const FindINCClient = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/find-inhouse-client`,
        {
          clientID: clientID,
          mobile_no: mobile_no,
          City: City,
        }
      );
      setINCDetails(response?.data);
      setData({ ...data, clientID: "", mobile_no: "", City: "" });
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    FindINCClient();
  }, []);

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card
            raised
            style={{
              marginBottom: "1rem",
              marginTop: "3rem",
            }}
          >
            <CardBody>
              <Link onClick={() => setFirstOpen(true)}>
                <div className={classes.card}>
                  <img
                    style={{
                      width: "50px",
                      height: "45px",
                      marginRight: "5px",
                    }}
                    src={BIicon}
                    alt=""
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#9c27b0",
                    }}
                  >
                    Book An Interpreter
                  </p>
                </div>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card
            raised
            style={{
              marginBottom: "1rem",
              marginTop: "3rem",
            }}
          >
            <CardBody>
              <Link onClick={() => setSecondOpen(true)}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "5px",
                    }}
                    src={OTicon}
                    alt=""
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#9c27b0",
                    }}
                  >
                    Order Translation
                  </p>
                </div>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <Modal
        onClose={() => setFirstOpen(false)}
        onOpen={() => setFirstOpen(true)}
        open={firstOpen}
      >
        <Modal.Header>Book An Interpreter</Modal.Header>
        <Modal.Content image>
          <div className="image">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "24px",
              }}
            >
              <div style={{ display: "flex" }}>
                <CustomAvatar style={{ margin: "0" }} />
              </div>
            </div>
          </div>
          <Modal.Description>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "24px",
              }}
            >
              <div style={{ display: "flex" }}>
                <h2>
                  Are you registered with me ({refID}) as an In-house client?
                </h2>
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => history.push(`/inhouse-client-form/${objID}`)}
            primary
          >
            <Icon name="signup" /> Register
          </Button>
          <Button
            onClick={() =>
              history.push(`/inc_booking/find_my_details/${objID}`)
            }
            primary
          >
            Yes <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => setSecondOpen(false)}
        onOpen={() => setSecondOpen(true)}
        open={secondOpen}
      >
        <Modal.Header>Order A Translation</Modal.Header>
        <Modal.Content image>
          <div className="image">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "24px",
              }}
            >
              <div style={{ display: "flex" }}>
                <CustomAvatar style={{ margin: "0" }} />
              </div>
            </div>
          </div>
          <Modal.Description>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "24px",
              }}
            >
              <div style={{ display: "flex" }}>
                <h2>
                  Are you registered with me ({refID}) as an In-house client?
                </h2>
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => history.push(`/inhouse-client-form/${objID}`)}
            primary
          >
            <Icon name="signup" /> Register
          </Button>
          <Button
            onClick={() => history.push(`/inc_order/find_my_details/${objID}`)}
            primary
          >
            Yes <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default ThreeCards;
