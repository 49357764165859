import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import Cookies from "js-cookie";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Action from "components/Admin/AccountFinance/InvoiceToClient/FindBill/Actions/Action";
import SearchView from "./Search/SearchView";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const { id } = useParams();
  const token = Cookies.get("token");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  let sigPad = useRef({});
  const [pay, setPay] = useState({
    payment_date: "",
    payment_ref: "",
    estimate_paid_amount: "",
    note: "",
    signature: "",
    booking_id: "",
    total_bill_amount: "",
    modal: false,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = (e) => {
    setSignature(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    AdminID: "",
  });

  const { name, error, success, bookingList, removed, reload } = values;

  useEffect(() => {
    loadbookingList();
    setFiltered([]);
  }, [filter]);

  const loadbookingList = async () => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-sppo-invoice/clientID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((cacclient) => {
        const history = cacclient.data;
        filtered.length > 0
          ? setValues({ ...values, bookingList: filtered })
          : setValues({ ...values, bookingList: history });
      })
    );
  };

  return (
    <>
      <SearchView
        values={bookingList}
        SearchText={searchText}
        setSearchText={setSearchText}
        setFiltered={setFiltered}
        load={loadbookingList}
        setShow={setShow}
        setFilter={setFilter}
        show={show}
      />
      {show && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Invoice no.</TableCell>
                  <TableCell>Date of invoice</TableCell>
                  <TableCell>Total amount</TableCell>
                  <TableCell>Expiry date and time</TableCell>
                  <TableCell>Sending status</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Client ref</TableCell>
                  <TableCell>Client email</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              {bookingList.length > 0 ? (
                <TableBody>
                  {bookingList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((list) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={list._id}
                        style={
                          list?.sending_status == "Saved"
                            ? { backgroundColor: "yellow" }
                            : { backgroundColor: "lightgreen" }
                        }
                      >
                        <TableCell>
                          <Link to={`/admin/view-direct-invoice/${list?._id}`}>
                            {list?.id}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {Moment(list?.createdAt)
                            .tz("Europe/London")
                            .format("DD-MMM-YYYY")}
                        </TableCell>
                        <TableCell>
                          Total amount: {list?.currency?.substring(0, 3)}{" "}
                          {list?.grand_total} <br />
                          Bank payment: {list?.currency?.substring(0, 3)}{" "}
                          {list?.estimate_paid_amount
                            ? list?.estimate_paid_amount
                            : "00.00"}
                        </TableCell>
                        <TableCell>
                          {Moment(list?.expiry_date)
                            .tz("Europe/London")
                            .format("DD-MMM-YYYY : hh:mm:ss")}
                        </TableCell>
                        <TableCell>{list?.sending_status}</TableCell>
                        <TableCell>{list?.status}</TableCell>
                        <TableCell>{list?.client_id}</TableCell>
                        <TableCell>{list?.client_email}</TableCell>
                        <TableCell>
                          <Action
                            load={loadbookingList}
                            status={list?.sending_status}
                            id={list?._id}
                            invID={list?.id}
                            estimate={
                              list?.estimate_paid_amount
                                ? list?.estimate_paid_amount
                                : "00.00"
                            }
                            AdminID={list?.postedBy?.id}
                            name={
                              list?.client_firstname
                                ? list?.client_firstname
                                : "N/A"
                            }
                            ID={list?.client_id ? list?.client_id : "N/A"}
                            currency={list?.currency?.substring(0, 3)}
                            total={list?.grand_total}
                            charge_id={list?.charge_id}
                            markPaid={list?.markPaid}
                            refunded={list?.refunded}
                            cancel={list?.cancel}
                            payment={list?.invoice_payment_status}
                            paystatus={list?.status}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                "No data found"
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={bookingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
}
