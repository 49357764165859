// Custom Components
import CustomSelect from "components/Common/CustomSelect";

const ServiceDropdown = ({ value, onChange }) => {
  const data = [
    "Interpereting",
    "Translation",
    "Expert Linguist / forensic / training / investigative",
    "Associate professional / bilingual professional",
    "Other",
  ];

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <CustomSelect
      width="100%"
      name="description"
      data={data}
      value={value}
      onChange={handleChange}
    />
  );
};

export default ServiceDropdown;
