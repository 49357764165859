import { ACCOUNT_CHANGE_BA_INPUT, ACCOUNT_UPDATE_BOOKING_STEP } from "../actions/actionTypes";

const INITIAL_STATE = {
  count: 1,
  data: {
    purchase_order_number: '',
    timezone: '',
    date: '',
    starttime: null,
    endtime: '',
    interpreting_service_required: '',
    house_number_street_name: '',
    address_line_two: '',
    region: '',
    current_nationality: '',
    other_nationalities: [],
    post_code: '',
    country: '',
    remote_address: '',
    source_language: '',
    target_language: '',
    linguist_skill_level: '',
    typesof_interpreting_required: '',
    job_description: '',
    preferred_interpreter: '',
    gender: '',
    security_clearance: '',
    car_parking: '',
    additional_info: '',
    scripts: '',
    currency: '',
    tc: '',
    upload_file:''
  }
};

const accountbookingReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACCOUNT_UPDATE_BOOKING_STEP:
      return {
        ...state,
        count: payload,
      };
    
    case ACCOUNT_CHANGE_BA_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value
        }
      }

    default:
      return state;
  }
};

export default accountbookingReducer;
