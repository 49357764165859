import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomSelect from "components/Common/CustomSelect";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import defaultAxios from "utils/axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
import { EMAIL_REGEX } from "data";

const FormOne = () => {
  const FORM_TYPE = ["INC Registration", "PRC Registration"];

  const classes = useFormStyles();
  const [notValid, setNotValid] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    note: "",
    form_name: "",
  });

  const { first_name, last_name, email, note } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleEmailChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const isAllow =
    !data.first_name ||
    !data.email ||
    !data.last_name ||
    !data.form_name ||
    notValid;

  const handleSubmit = async (e) => {
    const token = Cookies.get("LINtoken");

    const sendData = {
      first_name: data.first_name || "-",
      last_name: data.last_name || "-",
      email: data.email || "-",
      note: data.note || "-",
      form_name: data.form_name || "-",
    };
    try {
      const response = await defaultAxios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/send-client-registration-form`,
        sendData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      swal({
        title: "Success",
        text: "Registration form successfully send",
        icon: "success",
        buttons: false,
      });
      setData({
        ...data,
        first_name: "",
        last_name: "",
        email: "",
        note: "",
        form_name: "",
      });
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        label="First Name(s)"
        name="first_name"
        value={data.first_name}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Last Name"
        name="last_name"
        value={data.last_name}
        width="100%"
        required
      />

      <CustomInputSingle
        onChange={handleEmailChange}
        label="Email"
        name="email"
        value={data.email}
        width="100%"
        required
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomInputSingle
        onChange={handleChange}
        label="Note"
        name="note"
        value={data.note}
        width="100%"
      />

      <CustomSelect
        onChange={handleChange}
        label="Select Form"
        data={FORM_TYPE}
        width="100%"
        name="form_name"
        value={data.form_name}
        required
      />

      <div className={classes.buttonFlex}>
        <CustomButton disabled={isAllow} text="Send" onClick={handleSubmit} />
      </div>
    </form>
  );
};

export default FormOne;
