import Tikbox from "@material-ui/core/Checkbox";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Material imports
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import CommonCard from "components/Common/CommonCard";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
// Icons
import { changeCrInput } from "redux/actions/crAction";
import { Checkbox } from "semantic-ui-react";

import { Header, Button, Modal, Form } from "semantic-ui-react";

import Image5 from "assets/images/ciol.png";
import Image1 from "assets/images/NRPSI.png";
import Image2 from "assets/images/ITI.png";
import Image3 from "assets/images/ITIA_AM_badge.png";
import Image4 from "assets/images/ATA.jpg";

import Action from "./Action";

function ProfMemberShip({ checkData }) {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.crReducer);
  const [values, setValues] = useState({ reload: false });
  const token = Cookies.get("LINtoken");
  const fileInput = useRef(null);
  const { reg_no, org_name, website, expiry_date, logo } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeCrInput(inputData));
  };

  const handlePhoto = async (e) => {
    dispatch(
      changeCrInput({
        name: "logo",
        value: e.target.files[0],
      })
    );
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleSave = async (e) => {
    let formData = new FormData();
    formData.append("reg_no", reg_no || "-");
    formData.append("org_name", org_name || "-");
    formData.append("website", website || "-");
    formData.append("expiry_date", expiry_date || "-");

    if (!logo?.contentType) {
      formData.append("logo", logo);
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-public-profile-professional-membership`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `Successfully added`,
        icon: "success",
        buttons: "Ok",
      }).then(function () {
        window.location = "manage-public-profile";
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/professional-membership-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  const [state, setState] = useState({
    checkedA: false,
    checkedB: false,
    checkedF: false,
    checkedG: false,
    checkedH: false,
    other: false,
    added: false,
  });

  const [otherModal, setOtherModal] = useState(false);

  const handleOther = (e) => {
    setOtherModal(true);
  };

  const handleClose = (e) => {
    setOtherModal(false);
  };

  const updateProMembershipSection = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/allow-public-profile-membership-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const hideProMembershipSection = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/hide-public-profile-membership-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePublicProfile = (value) => {
    if (checkData.verified) {
      if (!checkData.professional_membership_section) {
        swal(
          "Professional membership section of your profile will be public. Are you sure you want to make this section of your profile public?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              updateProMembershipSection(value);

            default:
              break;
          }
        });
      } else {
        swal(
          "Are you sure you want to revoke public view permission for this section?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              hideProMembershipSection(value);

            default:
              break;
          }
        });
      }
    }
  };

  return (
    <div>
      <Modal size="mini" onClose={handleClose} open={otherModal}>
        <Modal.Header>Adding Professional Membership Details</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Field>
                <label>Name of association</label>
                <input
                  name="org_name"
                  onChange={handleChange}
                  value={org_name}
                  placeholder="Professional Body e.g. CIOL, NRPSI, ATA etc"
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Professtional membership number</label>
                <input
                  name="reg_no"
                  onChange={handleChange}
                  value={reg_no}
                  placeholder="Professtional membership number"
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Expiry Date</label>
                <input
                  name="expiry_date"
                  onChange={handleChange}
                  value={expiry_date}
                  placeholder="e.g. 01/Jan/2021"
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Organisation / association website</label>
                <input
                  name="website"
                  onChange={handleChange}
                  value={website}
                  placeholder="e.g. https://examplesite.org"
                />
              </Form.Field>

              <Header as="h4">Upload organisation logo</Header>

              <input
                accept="image/jpeg, image/png"
                ref={fileInput}
                onChange={(e) => handlePhoto(e)}
                type="file"
                style={{ display: "none" }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Button onClick={handleClick}>Upload</Button>
                  <br />
                  (Recommended size - 70 x 70 px)
                </div>
                {logo && (
                  <Header style={{ margin: "0", textAlign: "center" }} as="h5">
                    <Header style={{ margin: "0" }} as="h1">
                      <InsertPhotoIcon fontSize="large" />
                    </Header>
                    {logo.name}
                    <Header style={{ margin: "0" }} as="h5">{`Size: ${(
                      logo.size / 1024
                    ).toFixed(2)}kb`}</Header>
                    <Header
                      style={{ margin: "0" }}
                      as="h5"
                    >{`Last Modified: ${logo?.lastModifiedDate}`}</Header>
                  </Header>
                )}
              </div>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            content="Add"
            labelPosition="right"
            icon="checkmark"
            onClick={handleSave}
            disabled={!reg_no || !org_name || !website || !expiry_date || !logo}
            positive
          />
        </Modal.Actions>
      </Modal>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header color="purple" as="h2">
          Professional membership
        </Header>
        <Checkbox
          toggle
          checked={checkData?.professional_membership_section}
          onChange={() =>
            handlePublicProfile("professional_membership_section")
          }
        />
      </div>

      {values?.length > 0 ? (
        <GridContainer>
          {values?.map((list) => (
            <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
              <div style={{ margin: "5px" }}>
                <CommonCard
                  style={{ textAlign: "center" }}
                  title={list.org_name}
                >
                  <CardHeader color="primary">
                    <p style={{ textAlign: "center" }}>
                      My Membership / Reg. No. - {list.reg_no}{" "}
                    </p>
                    <p style={{ textAlign: "center" }}>
                      Registered until / Expiry: - {list.expiry_date}{" "}
                    </p>
                  </CardHeader>
                  <a
                    href={
                      list?.website.includes("http")
                        ? list.website
                        : list?.website.includes("www")
                        ? "http://" + list.website
                        : "http://www." + list?.website
                    }
                    target="_blank"
                  >
                    <img
                      style={{ width: "100%", padding: "10%" }}
                      src={`${process.env.REACT_APP_BACKEND_API}/linguists/professional-membership-logo/membershipListID/${list?._id}`}
                      alt=""
                    />
                  </a>
                </CommonCard>
              </div>

              <div>
                <Action id={list?._id} load={loadList} />
              </div>
            </GridItem>
          ))}
        </GridContainer>
      ) : null}

      <FormControlLabel
        control={
          <Tikbox
            onChange={handleOther}
            name="other"
            checked={state.other}
            color="primary"
          />
        }
        label="Add Details"
      />

      <Header color="purple">
        *Please note that you cannot hide your profile from LC clients, but you
        can choose not to make your profile public.
        <br />
        **Logo Examples. Please consult your own organisation before using their
        logo. You will be liable for any misuse of logo. Usually you are allowed
        to use logo of an organisation, if you are a full member of that
        Professional Organisation / Regulatory Body.
        <br />
        ***Please note that privacy settings are also set on each section. Use
        Toggle to set your preferences for each section.
        <br />
        ****You must have evidence of all information and education that you
        list here for publication.
      </Header>

      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={2} xl={2}>
          <div style={{ margin: "5px" }}>
            <CommonCard style={{ textAlign: "center" }}>
              <img
                style={{ width: "100%", padding: "10%" }}
                src={Image1}
                alt=""
              />
            </CommonCard>
          </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={2} xl={2}>
          <div style={{ margin: "5px" }}>
            <CommonCard style={{ textAlign: "center" }}>
              <img
                style={{ width: "100%", padding: "10%" }}
                src={Image2}
                alt=""
              />
            </CommonCard>
          </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={2} xl={2}>
          <div style={{ margin: "5px" }}>
            <CommonCard style={{ textAlign: "center" }}>
              <img
                style={{ width: "100%", padding: "10%" }}
                src={Image3}
                alt=""
              />
            </CommonCard>
          </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={2} xl={2}>
          <div style={{ margin: "5px" }}>
            <CommonCard style={{ textAlign: "center" }}>
              <img
                style={{ width: "100%", padding: "10%" }}
                src={Image4}
                alt=""
              />
            </CommonCard>
          </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={2} xl={2}>
          <div style={{ margin: "5px" }}>
            <CommonCard style={{ textAlign: "center" }}>
              <img
                style={{ width: "100%", padding: "10%" }}
                src={Image5}
                alt=""
              />
            </CommonCard>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ProfMemberShip;
