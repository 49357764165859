import React, { useState, useEffect } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import {
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import ThreeCards from "./ThreeCards";
import JobList from "./JobList";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { Link } from "react-router-dom";
import { Divider, Header, Search, Menu } from "semantic-ui-react";
import CardFooter from "components/Card/CardFooter";
import Slider from "components/JobAdvert/Slider";
import JobSearchImg from "assets/images/job-search.jpg";
import JobAdvertSearchImg from "assets/images/job-advert-search.jpg";
import JobAdvertImg from "assets/images/job-advert.jpg";
import JPimage from "assets/icons/job-post.png";
import JSimage from "assets/icons/search-job.png";
import { SearchDiv } from "./style";
import { getJob } from "../../action/frontpage";
import Axios from "axios";


const ES = [
  "The advert must be about a language or industry related job.",
  "The advert should be from the employer directly.",
  "Advert must be employment related not a freelancing opportunity.",
  "The advert should be posted in writing and paid for, using the appropriate form here.",
  "LC will charge £̶1̶0̶0̶  (90% discount) per advert, but will allow a 50% discount for NHS and registered charities with a certified charity number.",
  "The job advert will be sent by email to all linguists registered with us and show a notification on their profile when they login.",
];

const resultRenderer = ({ jobtitle, _id }) => {
  return (
    <div>
      <Link to={`/job-advert-details/${_id}`}>{jobtitle}</Link>
    </div>
  );
};

const PostJobAdvert = () => {
  const classes = useLandingpageStyles();
  const [job, setJob] = useState("Post a Job Advert");
  const [activeItem, setActiveItem] = useState("Post a Job Advert");
  const [text, setText] = useState();
  const [results, setResults] = useState([]);

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    contactList: [],
    removed: false,
    reload: false,
  });
  const { contactList, reload } = values;

  useEffect(() => {
    loadContactList();
  }, [reload]);

  const loadContactList = () => {
    getJob().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setValues({ ...values, contactList: data });
      }
    });
  };

  const [jobPrice, setJobPrice] = useState(null);

  const getJobPrice = async (e) => {
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/manager-admin/view-job-advert-price`
    );

    setJobPrice(response?.data);
  };
  useEffect(() => {
    getJobPrice();
  }, []);


  const searchFunc = (item, text) => {
    if (!text) {
      return true;
    }

    const searchText = text.toLowerCase();

    let {
      city,
      country,
      region,
      nationality,
      postcode,
      targetlanguage,
      sourcelanguage,
      jobtitle,
      salary,
    } = item;

    city = city || "-";
    country = country || "-";
    region = region || "-";
    nationality = nationality || "-";
    postcode = postcode || "-";
    targetlanguage = targetlanguage || "-";
    sourcelanguage = sourcelanguage || "-";
    jobtitle = jobtitle || "-";
    salary = salary || "-";

    const isMatched =
      city.toLowerCase().includes(searchText) ||
      country.toLowerCase().includes(searchText) ||
      region.toLowerCase().includes(searchText) ||
      nationality.toLowerCase().includes(searchText) ||
      targetlanguage.toLowerCase().includes(searchText) ||
      sourcelanguage.toLowerCase().includes(searchText) ||
      jobtitle.toLowerCase().includes(searchText) ||
      salary.toLowerCase().includes(searchText) ||
      postcode.toLowerCase().includes(searchText);

    return isMatched;
  };

  const handleSearchChange = (e) => {
    setText(e.target.value);

    const filteredResults = contactList
      .filter((item) => searchFunc(item, e.target.value))
      .map((item) => ({
        ...item,
        jobtitle: e.target.value,
      }));

    setResults(filteredResults);
  };

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    setJob(name);
  };
  const sliderImg = [JobSearchImg, JobAdvertSearchImg, JobAdvertImg];

  return (
    <LandingPageLayout>
      <GridContainer style={{ margin: "0 0 0 0" }}>
        <Card className={classes.frontImage}>
          <CardBody style={{ paddingTop: "0" }}>
            <Slider data={sliderImg} />
          </CardBody>
          <CardFooter>
            <Menu color="purple" inverted tabular widths="two">
              <Menu.Item
                name="Post a Job Advert"
                active={activeItem === "Post a Job Advert"}
                onClick={handleItemClick}
              >
                {/* <Icon name="adversal" /> */}
                {/* <div> */}
                <img src={JPimage} alt="" style={{ width: "10%" }} />
                {/* </div> */}
                <span style={{ marginLeft: "10px" }}>Post a Job Advert</span>
              </Menu.Item>

              <Menu.Item
                name="Search Job"
                active={activeItem === "Search Job"}
                onClick={handleItemClick}
              >
                {/* <div> */}
                <img src={JSimage} alt="" style={{ width: "10%" }} />
                {/* </div> */}
                {/* <Icon name="search" /> */}
                <span style={{ marginLeft: "10px" }}>Search Job</span>
              </Menu.Item>
            </Menu>
          </CardFooter>
          {job === "Post a Job Advert" ? (
            <GridContainer
              style={{
                marginTop: "4rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card className={classes.clientPortal}>
                  <CardBody>
                    <Header as="h2">
                      <img
                        src="https://am3pap002files.storage.live.com/y4mter1Y-iS9vY8eVuaiOHNvbQwmfXIPdUPvnTTqFZ0q6l4bDt6TYKvydIRX3zjMP8ja3HbyuVFIZH2njRbgNQIi_rmByO8frUuZ5SY0cnNLRSo4uVDgNHy_e4baQXeI7NSerYDW-FQOL6QKvnMp_2OIjG6sHV_V4wKrDAjV2dux7JmJa6AAMWzdZlZ2u3AFpefeVqQQ8DvKjrmK_FDEfxt3zDm8unrNhiVUtIPQuvvct6baDe9C1rp-e74-R7jJP5eILi7tf0QDFseq5hZVuhpeQ/promotional%20message.png?psid=1&width=696&height=696&cropMode=center"
                        alt=""
                      />

                    </Header>
                    <h2 style={{ textAlign: "center" }}>Post a Job Advert</h2>
                    <p style={{ textAlign: "center" }}>(Recruitment advert only)</p>
                    <Divider />
                    <Typography variant="body1">
                      Both CAC and BAC are welcome to post industry related or
                      language related job adverts with us, using the form here.
                      You must meet the following conditions:
                    </Typography>
                    <List dense style={{ marginLeft: "20px" }}>
                      <p style={{ textAlign: "justify" }}>
                        The advert must be about a language or industry related job.,
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        The advert should be from the employer directly.,
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        Advert must be employment related not a freelancing opportunity.,
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        The advert should be posted in writing and paid for, using the appropriate form here.,
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        LC will charge £̶<strike>{jobPrice?.actual_price ? jobPrice?.actual_price : "100"}</strike> ({jobPrice?.discount_percentage ? jobPrice?.discount_percentage : "Contact admin (0)"}% discount) per advert, but will allow a {jobPrice?.charities_discount_percentage ? jobPrice?.charities_discount_percentage : "50"}% discount for NHS and registered charities with a certified charity number.,
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        The job advert will be sent by email to all linguists registered with us and show a notification on their profile when they login.,
                      </p>
                    </List>
                    <div style={{ textAlign: "center" }}>
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/interpreter-translator-linguists-job-advert"
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginBottom: "10px" }}
                        >
                          Post
                        </Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          ) : null}
          {job === "Search Job" ? (
            <GridContainer
              style={{
                marginTop: "0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card
                  className={classes.clientPortal}
                  style={{
                    paddingTop: "0px",
                  }}
                >
                  <CardBody
                    style={{
                      height: "200px",
                    }}
                  >
                    <Header
                      as="h2"
                      style={{
                        marginBottom: "0px",
                      }}
                    >
                      <img
                        src="https://am3pap002files.storage.live.com/y4mNWdZZhUvLLKKhwGiJGM8QEfxMgYYD8m9f0gBxhPJ_BBRhzgN17DZO1mC1yLSJK_p38DWcS0vUgXeMBVltujmUyV67ZLqOMeaWXMLD0uMCYzcTmDJSsMfdaKGQFATj_mfn2khP7YBgehM8T-ghmafk3xqFmRoEVLwoAloGqTpYxfpRzUCfNlXy8SZPbWx9UgTUDYKGR25lnK0pZwMg8nEbM_cTp7YUV9HWILpJFvOTF8_yhx51lo6PgQvdicAfUcjPKf3n_r8Nc6Ib9N8I6ExVw/job%20search.jpg?psid=1&width=285&height=233"
                        alt=""
                      />
                      <Header.Content>Search Job</Header.Content>
                    </Header>
                    <Divider
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    />
                    <SearchDiv>
                      <Search
                        input={{ icon: "search", iconPosition: "right" }}
                        size="large"
                        results={results}
                        resultRenderer={resultRenderer}
                        onSearchChange={handleSearchChange}
                        placeholder="Search here.."
                      />
                    </SearchDiv>
                  </CardBody>
                </Card>
              </GridItem>
              <JobList text={text} contactList={contactList} />
            </GridContainer>
          ) : null}

          <ThreeCards />
        </Card>
      </GridContainer>
    </LandingPageLayout>
  );
};

export default PostJobAdvert;
