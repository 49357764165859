import React from "react";
import Welcome from "components/Users/Admin";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

import { useHistory } from "react-router-dom";

// Custom Components
import MasterLayout from "layout/MasterLayout";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";

import Dashboard from "components/Admin/Dashboard";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

//Icons
import BITicon from "assets/icons/book-interpreter-telephonic.png";
import OTTicon from "assets/icons/order-translation-telephonic.png";
import RQTicon from "assets/icons/request-quote-telephonic.png";

const AdminDashboard = () => {
  const history = useHistory();

  const classes = useDashboardStyles();

  return (
    <Manager>
      <MasterLayout>
        <GridContainer>
          <GridItem xs={24} sm={24} md={24} lg={24} style={{ width: "100%" }}>
            <Welcome />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(
                  "/admin/interpreter-assignment-booking-telephonic-form"
                )
              }
            >
              <CardHeader color="primary" stats icon>
                <CardIcon
                  color="primary"
                  style={{
                    textAlign: "center",
                    width: "90px",
                    padding: "10px",
                  }}
                >
                  <img src={BITicon} alt="" style={{ width: "100%" }} />{" "}
                </CardIcon>
                <h3 className={classes.cardCategory}>Book Interpreter</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                history.push("/admin/order-translation-telephonic-form")
              }
            >
              <CardHeader color="primary" stats icon>
                <CardIcon
                  color="primary"
                  style={{
                    textAlign: "center",
                    width: "90px",
                    padding: "10px",
                  }}
                >
                  <img src={OTTicon} alt="" style={{ width: "100%" }} />{" "}
                </CardIcon>
                <h3 className={classes.cardCategory}>Order Translation</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <div className={classes.cardSettingsDiv}>
              <Card
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  history.push("/admin/quote-request-telephonic-form")
                }
              >
                <CardHeader color="primary" stats icon>
                  <CardIcon
                    color="primary"
                    style={{
                      textAlign: "center",
                      width: "90px",
                      padding: "10px",
                    }}
                  >
                    <img src={RQTicon} alt="" style={{ width: "100%" }} />
                  </CardIcon>
                  <h3 className={classes.cardCategory}>Request Quote</h3>
                </CardHeader>
                <CardFooter stats></CardFooter>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Manager Dashboard</p>
              </CardHeader>
              <CardBody>
                {" "}
                <Dashboard />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </MasterLayout>
    </Manager>
  );
};

export default AdminDashboard;
