import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextArea } from "semantic-ui-react";

import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import { SubDetailsDiv } from "./styles";
import {
  updateCarpDetails,
  getCarpDetails,
} from "redux/actions/admin/controller";

const SubDetails = ({ readOnly, className }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { carpDetails } = useSelector((state) => state.adminControlData);

  const [value, setValue] = useState(
    `Cancel by admin –option to do full or partial refund to client /s `
  );

  useEffect(() => {
    if (carpDetails?.subDetails) {
      setValue(carpDetails?.subDetails);
    }
  }, [carpDetails?.subDetails]);

  const handleChange = async () => {
    const coopiedDetails = { ...carpDetails };
    coopiedDetails["subDetails"] = value;
    setLoading(true);

    await dispatch(updateCarpDetails(coopiedDetails));
    setLoading(false);
    setIsClicked(false);
  };

  return (
    <SubDetailsDiv className={className}>
      {isClicked ? (
        <TextArea
          placeholder="Tell us more"
          value={value}
          rows={2}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <p>{value}</p>
      )}
      {!readOnly ? (
        <div className="icon-div">
          {isClicked ? (
            <Fragment>
              {loading ? (
                <CircularProgress
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              ) : (
                <IconButton
                  aria-label="action"
                  color="primary"
                  onClick={handleChange}
                >
                  <CheckIcon />
                </IconButton>
              )}
            </Fragment>
          ) : (
            <IconButton
              aria-label="action"
              color="primary"
              onClick={() => setIsClicked(true)}
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
      ) : null}
    </SubDetailsDiv>
  );
};

export default SubDetails;
