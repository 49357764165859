import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";
// Custom Styles
import ViewAttach from "components/Admin/ManagementPanel/ViewAttachMessage";



const ViewContactCard = () => {

  return (
    <MasterLayout>
      <Manager>
        <ViewAttach />
      </Manager>
    </MasterLayout>
  );
};

export default ViewContactCard;
