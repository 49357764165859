import React, { useEffect } from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";
import Welcome from "components/Users/Cashclient";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CashClientDashboard from "components/CashClient/Dashboard";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

//Icons
import BIicon from "assets/icons/book-interpreterD.png";
import OTicon from "assets/icons/order-translationD.png";
import RQicon from "assets/icons/request-quoteD.png";

import Axios from "utils/axios";
import Cookies from "js-cookie";
import history from "utils/history";
import CardIcon from "components/Card/CardIcon";
import CardFooter from "components/Card/CardFooter";

const Dashboard = () => {
  const classes = useDashboardStyles();

  useEffect(() => {
    const token = Cookies.get("CACtoken");
    console.log(token);
    Axios.get(
      "/api/cashclient/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => console.log(err.response));
  }, []);

  return (
    <CashClientLayout>
      <Cashclient>
        <GridContainer>
          <GridItem xs={24} sm={24} md={24} lg={24} style={{ width: "100%" }}>
            <Welcome />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card
              style={{
                cursor: "pointer",
              }}
              onClick={() => history.push("/cash-client/book-interpreter")}
            >
              <CardHeader color="primary" stats icon>
                <CardIcon
                  color="primary"
                  style={{
                    textAlign: "center",
                    width: "90px",
                    padding: "10px",
                  }}
                >
                  <img src={BIicon} alt="" style={{ width: "100%" }} />
                </CardIcon>

                <h3 className={classes.cardCategory}>Book Interpreter</h3>
                {/* <h3 className={classes.cardTitle}>49/50</h3> */}
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card
              style={{
                cursor: "pointer",
              }}
              onClick={() => history.push("/cash-client/order-translation")}
            >
              <CardHeader color="primary" stats icon>
                <CardIcon
                  color="primary"
                  style={{
                    textAlign: "center",
                    width: "90px",
                    padding: "10px",
                  }}
                >
                  <img src={OTicon} alt="" style={{ width: "100%" }} />
                </CardIcon>
                <h3 className={classes.cardCategory}>Order Translation</h3>
                {/* <h3 className={classes.cardTitle}>49/50</h3> */}
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <div className={classes.cardSettingsDiv}>
              <Card
                style={{
                  cursor: "pointer",
                }}
                onClick={() => history.push("/cash-client/quote-request")}
              >
                <CardHeader color="primary" stats icon>
                  <CardIcon
                    color="primary"
                    style={{
                      textAlign: "center",
                      width: "90px",
                      padding: "10px",
                    }}
                  >
                    <img src={RQicon} alt="" style={{ width: "100%" }} />
                  </CardIcon>

                  <h3 className={classes.cardCategory}>Request Quote</h3>
                  {/* <h3 className={classes.cardTitle}>49/50</h3> */}
                </CardHeader>
                <CardFooter stats></CardFooter>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Cash Client Dashboard</p>
              </CardHeader>
              <CardBody>
                {" "}
                <CashClientDashboard />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default Dashboard;
