import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import { IconButton } from "@material-ui/core";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import BookingInterpreting from "components/CashClient/Assignments/AmendForm/BookingInterpreting"

const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  return (
    <Cashclient>
      <CashClientLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                <p className={classes.cardTitleWhite}>Amend Interpreting Assignments</p>
                <IconButton color='inherit'><ArrowBackIcon

                  onClick={() => history.goBack()}
                ></ArrowBackIcon></IconButton>
              </CardHeader>
            </Card>
            <BookingInterpreting />

          </GridItem>
        </GridContainer>

      </CashClientLayout>
    </Cashclient>
  );
};

export default UnconfirmedAssignmentsPage;
