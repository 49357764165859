import React, { useState, useEffect } from "react";
import { Button, Card, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomAvatar from "components/Common/CustomAvatar";
import Axios from "utils/axios";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import Action from "./Action";

const SubscribeHere = () => {
  const classes = useLandingpageStyles();
  const [values, setValues] = useState({ reload: false });
  const [lastID, setLastID] = useState({ reload: false });

  const [data, setData] = React.useState({
    linID: "",
  });

  const [edit, setEdit] = useState(false);

  const { linID } = data;

  const [details, setDetails] = useState({ only_number_id: "" });
  const [lindetails, setlinDetails] = useState({ only_number_id: "" });

  const handleEdit = (e) => {
    setEdit(true);
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const FindLinguist = async () => {
    try {
      const response = await Axios.put(
        `/api/manager-admin/find-duplicate-linguist/linID`,
        {
          linID: linID,
        }
      );
      setValues(response?.data);
      setData({ ...data, linID: "" });
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error.response?.data}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const getLastLinID = async () => {
    try {
      const response = await Axios.get(
        `/api/manager-admin/get-linguist-last-updated-ID`
      );
      setLastID(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getLastLinID();
  }, []);

  return (
    <GridContainer
      justify="center"
      className={classes.container}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
        <Card>
          <CardBody>
            <div className={`${classes.textCenter} ${classes.title}`}>
              <CustomAvatar />
              <Typography
                component="h1"
                variant="h5"
                style={{ textAlign: "center" }}
              >
                Scan Duplicate Linguist ID
              </Typography>
            </div>

            <CustomInputSingle
              label="LC Lingusit's Profile ID"
              width="100%"
              placeholder="Enter Linguist Profile ID Here (e.g. LIN/PRO100...)"
              name="linID"
              onChange={handleChange}
              value={data.linID}
              required
            />

            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                className={`${classes.button}`}
                color="primary"
                variant="contained"
                onClick={FindLinguist}
                disabled={!data.linID}
              >
                Scan Linguist
              </Button>
            </div>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
        {values.length > 0 ? (
          <>
            {values?.map((list) => (
              <Card>
                <CardBody>
                  <>
                    {edit ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h4>LIN/PRO</h4>
                        <TextField
                          type="number"
                          name="only_number_id"
                          onChange={(e) =>
                            setDetails({
                              ...details,
                              only_number_id: e.target.value,
                            })
                          }
                          value={details.only_number_id}
                          edit={true}
                          width="100%"
                          placeholder="e.g. 100002"
                          variant="outlined"
                          required
                        />
                        {details.only_number_id ? (
                          <Action
                            only_number_id={details}
                            id={list?._id}
                            old_id={list?.id}
                          />
                        ) : null}{" "}
                        Last updated LIN/PRO ID:{" "}
                        {lastID?.last_update_lin_ID_number}
                        <br /> New ID must be between 10000 - 150000 and not
                        already in use.
                      </div>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {list?.id}
                        <IconButton onClick={handleEdit}>
                          <EditIcon />
                        </IconButton>
                      </div>
                    )}
                  </>
                  <h4 style={{ textAlign: "left" }}>Old ID: {list?.old_id}</h4>
                  <h4 style={{ textAlign: "left" }}>
                    Profile ID Card Security Code: {list?.username}
                  </h4>
                  <h4 style={{ textAlign: "left" }}>
                    Firstname: {list?.FirstName}
                  </h4>
                  <h4 style={{ textAlign: "left" }}>
                    Lastname: {list?.LastName}
                  </h4>
                  <h4 style={{ textAlign: "left" }}>
                    Mobile Number: {list?.mobile_no}
                  </h4>
                  <h4 style={{ textAlign: "left" }}>Email: {list?.email}</h4>
                </CardBody>
              </Card>
            ))}
          </>
        ) : (
          "No Linguist Found"
        )}
      </GridItem>
    </GridContainer>
  );
};

// EBBlxUJe_
export default SubscribeHere;
