import { makeStyles } from "@material-ui/core/styles";

const style = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "0px",

    "& .svg-icon": {
      marginBottom: "15px",
    },
  },
  label: {
    margin: "0",
    marginRight: "5px",
    paddingBottom: "15px",
  },
  sIcon: {
    paddingBottom: "15px",
  },
  toolTip: {
    fontSize: "14px",
  },
});

const useCustomLabelStyles = makeStyles(style);

export default useCustomLabelStyles;
