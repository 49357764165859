import React, { } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import DocumentList from "./AgentLin_Document_list"
import DriveLinkList from "../DriveLink_list_client_sppo"

const Items = () => {
    const classes = useDashboardStyles();

    return (

        <>
            <GridContainer justify="center" className={classes.container} xs={12} sm={12} md={12} lg={12} xl={12}>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                        <CardHeader
                            color="primary"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <p className={classes.cardTitleWhite}>
                                Translation or Transcript Document Delivery
                            </p>
                        </CardHeader>
                        <CardBody>
                            <DocumentList />
                        </CardBody>
                        <CardBody>
                            <DriveLinkList />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}

export default Items
