import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import SearchView from "./Search/SearchView";
import Cookies from "js-cookie";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import Action from "components/Employee/Dashboard/JobLists/quotation/BAC/BACActions";
import moment from "moment";

import SignatureCanvas from "react-signature-canvas";
import CustomModal from "components/Common/CustomModal";
import { Button, Header, Loader, Modal, Icon } from "semantic-ui-react";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
import { CANCEL_DETAILS } from "data";
import CustomTextInput from "components/Common/CustomTextInput";
import swal from "sweetalert";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ quotationData, text }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const token = Cookies.get("EMPtoken");
  const [show, setShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const { addToast } = useToasts();
  const [isBAC, setIsBAC] = useState(false);

  const [viewAll, setViewAll] = useState({
    open: false,
    list: [],
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, bookingList, removed, reload } = values;

  const [paidModal, setPaidModal] = React.useState(false);
  const [paidModalBankPay, setPaidModalBankPay] = React.useState(false);
  const [paidModalMonthlyPay, setPaidModalMonthlyPay] = React.useState(false);
  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  let sigPad = useRef({});
  const [adminID, setAdminID] = useState("");
  const [cancel, setCancel] = useState([]);
  const [pay, setPay] = useState({
    payment_date: "",
    payment_ref: "",
    estimate_paid_amount: "",
    cancel_by_admin_client: "",
    note: "",
    signature: "",
    booking_id: "",
    assignmentId: "",
    grand_total: "",
    currency: "",
    charge_id: "",
  });

  const handlePaid = async (
    id,
    grand_total,
    assignmentId,
    currency,
    charge_id
  ) => {
    setPaidModal(true);
    setPay({
      ...pay,
      booking_id: id,
      grand_total: grand_total,
      assignmentId: assignmentId,
      currency: currency,
      charge_id: charge_id,
    });
  };

  const handlePaidBankPay = async (
    id,
    grand_total,
    assignmentId,
    currency,
    charge_id
  ) => {
    setPaidModalBankPay(true);
    setPay({
      ...pay,
      booking_id: id,
      grand_total: grand_total,
      assignmentId: assignmentId,
      currency: currency,
      charge_id: charge_id,
    });
  };

  const handlePaidMonthlyPay = async (
    id,
    grand_total,
    assignmentId,
    currency,
    charge_id
  ) => {
    setPaidModalMonthlyPay(true);
    setPay({
      ...pay,
      booking_id: id,
      grand_total: grand_total,
      assignmentId: assignmentId,
      currency: currency,
      charge_id: charge_id,
    });
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  const handleClosePaid = (e) => {
    setPaidModal(false);
    setPaidModalBankPay(false);
    setPaidModalMonthlyPay(false);
    setPay({
      ...pay,
      estimate_paid_amount: "",
      payment_ref: "",
      payment_date: "",
      cancel_by_admin_client: "",
    });
    setSign(null);
  };

  const handleClose = (e) => {
    setSignature(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const getAdminDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/employee/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAdminID(response?.data.id);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelJob = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/employee/cancel&refund-linQuote/linQuoteID/${pay.booking_id}`,
        {
          cancel_by_admin_client: pay.cancel_by_admin_client,
          refund_date: pay.payment_date,
          refund_reason: pay.payment_ref,
          refund_amount: parseInt(pay.estimate_paid_amount * 100),
          note: pay.note,
          signature: sign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: response?.data.message,
        buttons: "Ok",
      });
      setPaidModal(false);
      setPaidModalBankPay(false);
      setPaidModalMonthlyPay(false);
      setPay({
        ...pay,
        estimate_paid_amount: "",
        payment_ref: "",
        payment_date: "",
        cancel_by_admin_client: "",
      });
      setSign(null);
      loadbookingList();
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: error.response?.data.raw.message,
        buttons: "Ok",
      });
    }
  };

  const handleConfirm = async (e) => {
    setValues({ ...values, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/v1/refund/admin`,
        {
          charge_id: pay.charge_id,
          reason: pay.payment_ref,
          amount: parseInt(pay.estimate_paid_amount * 100),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: response?.data.status,
        buttons: "Ok",
      });
      cancelJob();
    } catch (error) {
      swal({
        icon: "error",
        text: error.response?.data.raw.message,
        buttons: "Ok",
      });
      setValues({ ...values, loader: false });
    }
  };

  const handleConfirmBankPay = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          cancelJob(value);

        default:
          break;
      }
    });
  };

  useEffect(() => {
    loadbookingList();
    getAdminDetails();
  }, [reload]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/confirmed-lin-quote-list-BAC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({
        ...values,
        bookingList: quotationData
          ? response?.data.filter((item) => item.id === quotationData)
          : response?.data,
      });
      quotationData && setShow(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-confirmed-lin-quote-BAC/quoteID`,
        { id: SearchText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (SearchText == undefined || SearchText == "") {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      } else if (response?.data) {
        setValues({ ...values, bookingList: [response?.data], reload: false });

        addToast("assignment found", { appearance: "success" });
      } else {
        addToast("assignment not found", { appearance: "error" });
      }
      if (values.bookingList) {
        setShow(true);
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct assignment id.", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <div>
      {/* Payment modal */}
      <Modal
        open={paidModal}
        onClose={handleClosePaid}
        style={{ overflowY: "scroll", marginTop: 70 }}
      >
        <Modal.Header>
          Cancel and Refund (Stripe Payment) - {pay.assignmentId},{" "}
          {pay.currency}, {pay.grand_total}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomSemanticDD
              value={pay.cancel_by_admin_client}
              onChange={handleChange}
              label="Cancel"
              required
              name="cancel_by_admin_client"
              data={CANCEL_DETAILS}
            />
            <CustomTextInput
              name="payment_date"
              onChange={handleChange}
              dvalue={pay.payment_date}
              label="Refund date"
              dlabel="Refund date "
              date={true}
            />
            <CustomTextInput
              name="payment_ref"
              onChange={handleChange}
              value={pay.payment_ref}
              label="Reason for refund "
              edit={true}
            />
            <CustomTextInput
              number={true}
              name="estimate_paid_amount"
              onChange={handleChange}
              value={pay.estimate_paid_amount}
              placeholder="00.00"
              label="Total amount to refund"
              edit={true}
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
            <CustomTextInput label="Admin ID" value={adminID} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              !pay.payment_ref ||
              !sign ||
              !pay.cancel_by_admin_client ||
              !pay.payment_date ||
              !pay.estimate_paid_amount ||
              values.loader
            }
            onClick={handleConfirm}
            color="purple"
          >
            Confirm <Icon name="chevron right" />
          </Button>
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>

      {/* Bankpay Payment modal */}
      <Modal
        open={paidModalBankPay}
        onClose={handleClosePaid}
        style={{ overflowY: "scroll", marginTop: 70 }}
      >
        <Modal.Header>
          Cancel and Refund (Bank Payment) - {pay.assignmentId}, {pay.currency},{" "}
          {pay.grand_total}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomSemanticDD
              value={pay.cancel_by_admin_client}
              onChange={handleChange}
              label="Cancel"
              required
              name="cancel_by_admin_client"
              data={CANCEL_DETAILS}
            />
            <CustomTextInput
              name="payment_date"
              onChange={handleChange}
              dvalue={pay.payment_date}
              label="Refund date"
              dlabel="Refund date "
              date={true}
            />
            <CustomTextInput
              name="payment_ref"
              onChange={handleChange}
              value={pay.payment_ref}
              label="Reason for refund "
              edit={true}
            />
            <CustomTextInput
              number={true}
              name="estimate_paid_amount"
              onChange={handleChange}
              value={pay.estimate_paid_amount}
              placeholder="00.00"
              label="Total amount to refund"
              edit={true}
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
            <CustomTextInput label="Admin ID" value={adminID} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              !pay.payment_ref ||
              !sign ||
              !pay.cancel_by_admin_client ||
              !pay.payment_date ||
              !pay.estimate_paid_amount ||
              values.loader
            }
            onClick={handleConfirmBankPay}
            color="purple"
          >
            Confirm <Icon name="chevron right" />
          </Button>
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>

      {/* Monthly Payment modal */}
      <Modal
        open={paidModalMonthlyPay}
        onClose={handleClosePaid}
        style={{ overflowY: "scroll", marginTop: 70 }}
      >
        <Modal.Header>
          Cancel and Refund (Added to monthly bill) - {pay.assignmentId},{" "}
          {pay.currency}, {pay.grand_total}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomSemanticDD
              value={pay.cancel_by_admin_client}
              onChange={handleChange}
              label="Cancel"
              required
              name="cancel_by_admin_client"
              data={CANCEL_DETAILS}
            />
            <CustomTextInput
              name="payment_date"
              onChange={handleChange}
              dvalue={pay.payment_date}
              label="Refund date"
              dlabel="Refund date "
              date={true}
            />
            <CustomTextInput
              name="payment_ref"
              onChange={handleChange}
              value={pay.payment_ref}
              label="Reason for refund "
              edit={true}
            />
            <CustomTextInput
              number={true}
              name="estimate_paid_amount"
              onChange={handleChange}
              value={pay.estimate_paid_amount}
              placeholder="00.00"
              label="Total amount to refund"
              edit={true}
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
            <CustomTextInput label="Admin ID" value={adminID} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              !pay.payment_ref ||
              !sign ||
              !pay.cancel_by_admin_client ||
              !pay.payment_date ||
              !pay.estimate_paid_amount ||
              values.loader
            }
            onClick={handleConfirmBankPay}
            color="purple"
          >
            Confirm <Icon name="chevron right" />
          </Button>
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>

      {!quotationData && (
        <SearchView
          show={show}
          load={loadbookingList}
          handleSearch={handleSearch}
          setSearchText={setSearchText}
          SearchText={SearchText}
          setShow={setShow}
        />
      )}
      <Paper className={classes.root}>
        {show ? (
          <div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Assignment ID</TableCell>
                    <TableCell>Provided Quote ID</TableCell>
                    <TableCell>Quote Request For</TableCell>
                    <TableCell>Source Language</TableCell>
                    <TableCell>Target Language</TableCell>
                    <TableCell>Remote / Venue</TableCell>
                    <TableCell>Request Details</TableCell>
                    <TableCell>Budget</TableCell>
                    <TableCell>Skills Level</TableCell>
                    <TableCell>Quote Needed By</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {bookingList.length > 0 ? (
                  <TableBody>
                    {bookingList

                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list, i) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                          <TableCell key={list._id} align={list.align}>
                            <Link
                              to={`/employee/view-account-client-quotation/${list?.quoteRequstByAccountclient?._id}`}
                            >
                              {list?.quoteRequstByAccountclient?.id}
                            </Link>
                          </TableCell>
                          <TableCell key={list._id} align={list.align}>
                            <Link
                              to={`/employee/view-provided-quote/${list?._id}`}
                            >
                              {list?.id}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {list?.quoteRequstByAccountclient?.quote_rqst_for}
                          </TableCell>
                          <TableCell>
                            {list?.quoteRequstByAccountclient?.source_language}
                          </TableCell>
                          <TableCell>
                            {list?.quoteRequstByAccountclient?.target_language}
                          </TableCell>
                          <TableCell>
                            {list?.quoteRequstByAccountclient?.country}
                          </TableCell>
                          <TableCell>
                            {list?.quoteRequstByAccountclient?.request_details}
                          </TableCell>
                          <TableCell>
                            {
                              list?.quoteRequstByAccountclient
                                ?.project_fix_budget
                            }
                          </TableCell>
                          <TableCell>
                            {
                              list?.quoteRequstByAccountclient
                                ?.target_skills_required
                            }
                          </TableCell>
                          <TableCell>
                            {moment(
                              list?.quoteRequstByAccountclient?.quote_by
                            ).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell>{list.status}</TableCell>
                          <TableCell>
                            <Action
                              load={loadbookingList}
                              cancel={list.cancel}
                              cancel_request={list.cancel_request}
                              list={list}
                              bank_pay={list.bank_pay}
                              monthly_pay={list.monthlyBill}
                              id={list?.quoteRequstByAccountclient?._id}
                              quoteID={list?._id}
                              report={list.project_report}
                              accepts={list.accepts}
                              payStatus={list.payment_status}
                              handlePaid={() =>
                                handlePaid(
                                  list._id,
                                  list.grand_total,
                                  list.id,
                                  list?.currency?.substring(0, 3),
                                  list.charge_id
                                )
                              }
                              handlePaidBankPay={() =>
                                handlePaidBankPay(
                                  list._id,
                                  list.grand_total,
                                  list.id,
                                  list?.currency?.substring(0, 3),
                                  list.charge_id
                                )
                              }
                              handlePaidMonthlyPay={() =>
                                handlePaidMonthlyPay(
                                  list._id,
                                  list.grand_total,
                                  list.id,
                                  list?.currency?.substring(0, 3),
                                  list.charge_id
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
