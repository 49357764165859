import React from "react";

// Custom Styles
import useCustomLabelStyles from "assets/jss/components/auth/customLabelStyle";

const CustomTooltipTitle = ({ text }) => {
  const classes = useCustomLabelStyles();
  return <p className={classes.toolTip}>{text}</p>;
};

export default CustomTooltipTitle;
