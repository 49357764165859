import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
// Custom Components
import INCRegistration from "components/Sppo/INCRegistrationForm/InhouseClientFormWeb";
import LandinPageLayout from "layout/LandingPageLayout";
import Axios from "axios";
import Card from "components/Card/Card";


const SPPORegistrationPage = () => {
    const { id } = useParams();

    const [sppoID, setSPPOID] = useState(null)

    const getDetails = async (e) => {
        const response = await Axios.get(
            `${process.env.REACT_APP_BACKEND_API}/sppo/only-read-ID/${id}`);

        setSPPOID(response?.data);
    };
    useEffect(() => {
        getDetails();
    }, []);

    return (
        sppoID?.id ?
            <LandinPageLayout>

                <INCRegistration id={sppoID?.agent_sppo ? sppoID?.agent_sppo : id} userID={sppoID?.id} />

            </LandinPageLayout>
            : <Card>
                <div
                    style={{
                        backgroundImage:
                            "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
                        padding: "20px",
                        boxShadow:
                            "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                        textAlign: "center"
                    }}
                >
                    This page is not available.
                </div>
            </Card>
    );
};

export default SPPORegistrationPage;
