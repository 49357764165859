import Cookies from "js-cookie";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Table } from "semantic-ui-react";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import SearchView from "../SearchView/SearchView";
// import Action from "./Action";

import CustomModal from "components/Common/CustomModal";
import ViewAll from "./ViewAll";
// Others
import { acceptDecline } from "./functions";
import Axios from "axios";
import swal from "sweetalert";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const InterpretingJobsLists = () => {
  const [open, setOpen] = useState(false);
  const token = Cookies.get("LINtoken");
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });

  const handleClose = () => {
    setOpen(!open);
  };

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    cacAndBacAccess: null,
  });

  const {
    name,
    error,
    success,
    bookingList,
    cacAndBacAccess,
    removed,
    reload,
  } = values;

  useEffect(() => {
    loadbookingList();
  }, [reload, filter]);

  const loadbookingList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/cashclient-Translation-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (filtered.length > 0) {
        setValues({ ...values, bookingList: filtered });
        setFiltered([]);
      } else {
        setValues({ ...values, bookingList: response?.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccept = async (id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/cashclient-Translation-accept/TranslationID/${id}`,
        { email: email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      loadbookingList();
    } catch (error) {
      swal({
        icon: "error",
        text: `${error.response?.data}`,
        buttons: "Ok",
        dangerMode: true,
      });
    }
  };

  const decline = async (decline, id, email) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/cashclient-Translation-reject/TranslationID/${id}`,
        { email: email, declines: decline },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      loadbookingList();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDecline = async (e, id, email) => {
    const declines = e.target.value;
    swal(`Are you sure you want to decline this job?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          decline(declines, id, email);

        default:
          break;
      }
    });
  };

  // const handleAccept = async (id, email) => {
  //     swal(`Are you sure you want to accept this job?`, {
  //     buttons: {
  //       cancel: true,
  //       catch: {
  //         text: "Yes!",
  //         value: "catch",
  //       },
  //     },
  //   })
  //     .then((value) => {
  //       switch (value) {

  //         case "cancel":
  //           break;

  //         case "catch":
  //           accept(id, email);

  //         default:
  //           break;
  //       }
  //     });
  // }

  return (
    <Paper className={classes.root}>
      <SearchView
        values={bookingList}
        load={loadbookingList}
        setSearchText={setSearchText}
        setShow={setShow}
        SearchText={searchText}
        setFiltered={setFiltered}
        setFilter={setFilter}
        show={show}
      />
      {show && (
        <>
          {" "}
          <TableContainer className={classes.container}>
            <Table aria-label="sticky table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                  <Table.HeaderCell>Document type</Table.HeaderCell>
                  <Table.HeaderCell>Timezone</Table.HeaderCell>
                  <Table.HeaderCell>Deadline</Table.HeaderCell>
                  <Table.HeaderCell>Source language</Table.HeaderCell>
                  <Table.HeaderCell>Target language</Table.HeaderCell>
                  <Table.HeaderCell>
                    Target language skill level
                  </Table.HeaderCell>
                  <Table.HeaderCell>Estimated word count</Table.HeaderCell>
                  <Table.HeaderCell>Estimate paid</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Accept / Decline
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {bookingList.length > 0 ? (
                <Table.Body>
                  {bookingList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((list) => (
                      <>
                        {moment(list.deadline) > moment() ||
                        moment(list.deadline) === moment() ? (
                          <Table.Row
                            role="checkbox"
                            tabIndex={-1}
                            key={list._id}
                          >
                            <Table.Cell key={list._id} align={list.align}>
                              <Link
                                to={`/linguist/cac-view-translation-assignment/${list._id}`}
                              >
                                {list?.id}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>{list.document_type}</Table.Cell>
                            <Table.Cell>{list.timezone}</Table.Cell>
                            <Table.Cell>
                              {moment(list?.deadline).format(
                                "DD-MMM-YYYY : HH:mm:ss"
                              )}
                            </Table.Cell>
                            <Table.Cell>{list.source_language}</Table.Cell>
                            <Table.Cell>{list.target_language}</Table.Cell>
                            <Table.Cell>
                              {list.target_expertise_level_required}
                            </Table.Cell>
                            <Table.Cell>{list.wordCount}</Table.Cell>
                            <Table.Cell>
                              {list?.currency_new?.substring(0, 3)}{" "}
                              {list.estimate}
                            </Table.Cell>
                            <Table.Cell>
                              {acceptDecline(
                                "bl",
                                "",
                                handleAccept,
                                handleDecline,
                                list._id,
                                list.email,
                                "declineOp"
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row
                            role="checkbox"
                            tabIndex={-1}
                            key={list._id}
                          >
                            <Table.Cell key={list._id} align={list.align}>
                              <Link
                                to={`/linguist/cac-view-translation-assignment/${list._id}`}
                              >
                                {list?.id}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>{list.document_type}</Table.Cell>
                            <Table.Cell>{list.timezone}</Table.Cell>
                            <Table.Cell>
                              {moment(list?.deadline).format(
                                "DD-MMM-YYYY : HH:mm:ss"
                              )}
                            </Table.Cell>
                            <Table.Cell>{list.source_language}</Table.Cell>
                            <Table.Cell>{list.target_language}</Table.Cell>
                            <Table.Cell>
                              {list.target_expertise_level_required}
                            </Table.Cell>
                            <Table.Cell>{list.wordCount}</Table.Cell>
                            <Table.Cell>
                              {list?.currency_new?.substring(0, 3)}{" "}
                              {list.estimate}
                            </Table.Cell>
                            <Table.Cell>
                              Assignment Acceptance Time Expired.You may contact
                              Booking Team for an update.
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </>
                    ))}
                </Table.Body>
              ) : (
                "Sorry, no CAC translation assignment available matching your profile."
              )}
            </Table>
            <CustomModal handleClose={handleClose} open={open}>
              <ViewAll />
            </CustomModal>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={bookingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
};

export default InterpretingJobsLists;
