import Axios from "utils/axios";
import swal from "sweetalert";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import Actions from "./Actions";
import { TableDiv } from "./style";
import { Header, Statistic } from "semantic-ui-react";
import { CURRENCY, PAY_OPTIONS } from "data";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
//Stripe
import StripeLayout from "layout/StripeLayout";
import CheckoutBACInvoice from "layout/CheckoutLayout/CheckoutBACInvoice";
import history from "utils/history";
import { Button } from "semantic-ui-react";

const Bill = () => {
  const { billID } = useParams();
  const { clientID } = useParams();
  const [rates, setRates] = useState("");
  const { currency } = useSelector((state) => state.caoReducer);
  const [invoice, setInvoice] = React.useState(null);
  const [bill, setBill] = React.useState(null);
  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
  });
  const [payOptions, setPayOptions] = useState(null);
  const [bankCurrency, setCurrency] = useState(null);
  const [bankDetails, setBankDetails] = useState({
    account_holder_name: "",
    bsb: "",
    account_number: "",
    bic: "",
    iban: "",
    sort_code: "",
    routing_number: "",
    institution_number: "",
    transit_number: "",
    bank_code: "",
  });
  const [convertedCurrency, setConvertedCurrency] = useState(null);

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const InvoiceDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/view-Bill-details/billID/${billID}`
        );

        setInvoice(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          console.log(error.response?.data);
        }
      }
    };

    const getBillDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/manager-admin/view-Bill-details/billID/${billID}`
        );

        setBill(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          console.log(error.response?.data);
        }
      }
    };

    const getCurrency = async () => {
      if (currency) {
        Axios.get(
          `/api/currency-exchange`
        ).then((res) => setRates(res?.data?.rates));
      }
    };

    const getCompanyInfo = async () => {
      if (currency) {
        Axios.get(
          `/api/manager-admin/view/company-information-card`
        ).then((res) =>
          setCompanyCard({
            ...companyCard,
            basc: res.data[0].billing_account_service_charge,
            pbsc: res.data[0].platform_booking_service_charge,
            cname: res.data[0].company_name,
            caddress: res.data[0].company_address,
          })
        );
      }
    };

    InvoiceDetails();
    getCurrency();
    getCompanyInfo();
    getBillDetails();

    return () => {
      source.cancel();
    };
  }, [currency]);

  // Handling payments

  const handlePayOptions = async (e) => {
    setPayOptions(e.target.value);
    if (e.target.value === "Marketplace speedy payment") {
      setBankDetails({
        ...bankDetails,
        bank_code: "",
        account_number: "",
        account_holder_name: "",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      setCurrency(null);
    }
  };

  const handleCurrency = async (e) => {
    setCurrency(e.target.value);
    const value = e.target.value;
    if (value === "AUD (Australia)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        bsb: "802-985",
        account_number: "918630266",
        bic: "",
        sort_code: "",
        iban: "",
        routing_number: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      setConvertedCurrency(
        (
          (rates?.AUD * bill?.total_bill_amount) /
          rates[bill?.currency?.substring(0, 3)]
        ).toFixed(2)
      );
    } else if (value === "EUR (EU)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        bic: "TRWIBEB1XXX",
        iban: "BE13 9671 5755 4739",
        bsb: "",
        account_number: "",
        sort_code: "",
        routing_number: "",
        bank_code: "",
        institution_number: "",
        transit_number: "",
      });
      setConvertedCurrency(
        (
          (rates?.EUR * bill?.total_bill_amount) /
          rates[bill?.currency?.substring(0, 3)]
        ).toFixed(2)
      );
    } else if (value === "GBP (UK)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        sort_code: "23-14-70",
        iban: "GB51 TRWI 2314 7058 5979 53",
        account_number: "",
        bsb: "",
        bic: "",
        routing_number: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      setConvertedCurrency(
        (
          (rates?.GBP * bill?.total_bill_amount) /
          rates[bill?.currency?.substring(0, 3)]
        ).toFixed(2)
      );
    } else if (value === "TRY (Turkish lira)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        iban: "TR69 0010 3000 0000 0033 8634 34",
        sort_code: "",
        bsb: "",
        account_number: "",
        bic: "",
        routing_number: "",
        bank_code: "",
        institution_number: "",
        transit_number: "",
      });
      setConvertedCurrency(
        (
          (rates?.TRY * bill?.total_bill_amount) /
          rates[bill?.currency?.substring(0, 3)]
        ).toFixed(2)
      );
    } else if (value === "USD (USA)") {
      setBankDetails({
        ...bankDetails,
        account_number: "9600000000264428",
        account_holder_name: "Linguists Collective Ltd",
        routing_number: "084009519",
        sort_code: "",
        bsb: "",
        bic: "",
        iban: "",
        transit_number: "",
        institution_number: "",
        bank_code: "",
      });
      setConvertedCurrency(
        (
          (rates?.USD * bill?.total_bill_amount) /
          rates[bill?.currency?.substring(0, 3)]
        ).toFixed(2)
      );
    } else if (value === "CAD (Canada)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        institution_number: "621",
        account_number: "200110001176",
        transit_number: "16001",
        routing_number: "",
        bic: "",
        bsb: "",
        sort_code: "",
        iban: "",
        bank_code: "",
      });
      setConvertedCurrency(
        (
          (rates?.CAD * bill?.total_bill_amount) /
          rates[bill?.currency?.substring(0, 3)]
        ).toFixed(2)
      );
    } else if (value === "HUF (Hungarian forint)") {
      setBankDetails({
        ...bankDetails,
        account_holder_name: "Linguists Collective Ltd",
        account_number: "12600016-17943734-02621164",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
        bank_code: "",
      });
      setConvertedCurrency(
        (
          (rates?.HUF * bill?.total_bill_amount) /
          rates[bill?.currency?.substring(0, 3)]
        ).toFixed(2)
      );
    } else if (value === "NZD (New Zealand dollar)") {
      setBankDetails({
        ...bankDetails,
        account_number: "02-1290-0578525-000",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
        bank_code: "",
      });
      setConvertedCurrency(
        (
          (rates?.NZD * bill?.total_bill_amount) /
          rates[bill?.currency?.substring(0, 3)]
        ).toFixed(2)
      );
    } else if (value === "RON (Romanian leu)") {
      setBankDetails({
        ...bankDetails,
        bank_code: "BRELROBUXXX",
        account_number: "RO73 BREL 0005 4000 8848 0100",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      setConvertedCurrency(
        (
          (rates?.RON * bill?.total_bill_amount) /
          rates[bill?.currency?.substring(0, 3)]
        ).toFixed(2)
      );
    } else if (value === "SGD (Singapore dollar)") {
      setBankDetails({
        ...bankDetails,
        bank_code: "7171",
        account_number: "885-074-585-39",
        account_holder_name: "Linguists Collective Ltd",
        bic: "",
        institution_number: "",
        routing_number: "",
        sort_code: "",
        iban: "",
        bsb: "",
        transit_number: "",
      });
      setConvertedCurrency(
        (
          (rates?.SGD * bill?.total_bill_amount) /
          rates[bill?.currency?.substring(0, 3)]
        ).toFixed(2)
      );
    }
  };

  let sl = 1,
    sumTotal = 0,
    sumDue = 0,
    sumDiscount = 0,
    sumVatTotal = 0,
    sumBAServiceCharge = 0,
    sumServiceCharge = 0,
    sumDeposit = 0,
    sumPaid = 0;

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumTotal +=
            (invoice[i].total * rates.GBP) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "EUR"
        ? (sumTotal +=
            (invoice[i].total * rates.EUR) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "AUD"
        ? (sumTotal +=
            (invoice[i].total * rates.AUD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "USD"
        ? (sumTotal +=
            (invoice[i].total * rates.USD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "CAD"
        ? (sumTotal +=
            (invoice[i].total * rates.CAD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "TRY"
        ? (sumTotal +=
            (invoice[i].total * rates.TRY) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "SGD"
        ? (sumTotal +=
            (invoice[i].total * rates.SGD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "HUF"
        ? (sumTotal +=
            (invoice[i].total * rates.HUF) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "NZD"
        ? (sumTotal +=
            (invoice[i].total * rates.NZD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "RON"
        ? (sumTotal +=
            (invoice[i].total * rates.RON) /
            rates[invoice[i].currency?.substring(0, 3)])
        : (sumTotal +=
            (invoice[i].total * rates.GBP) /
            rates[invoice[i].currency?.substring(0, 3)]);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.GBP
            : (invoice[i].due * rates.GBP) /
              rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "EUR"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.EUR
            : (invoice[i].due * rates.EUR) /
              rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "AUD"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.AUD
            : (invoice[i].due * rates.AUD) /
              rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "USD"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.USD
            : (invoice[i].due * rates.USD) /
              rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "CAD"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.CAD
            : (invoice[i].due * rates.CAD) /
              rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "TRY"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.TRY
            : (invoice[i].due * rates.TRY) /
              rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "SGD"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.SGD
            : (invoice[i].due * rates.SGD) /
              rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "HUF"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.HUF
            : (invoice[i].due * rates.HUF) /
              rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "NZD"
        ? (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.NZD
            : (invoice[i].due * rates.NZD) /
              rates[invoice[i].currency?.substring(0, 3)])
        : (sumDue += invoice[i].invoice_payment_status
            ? 0 * rates.RON
            : (invoice[i].due * rates.RON) /
              rates[invoice[i].currency?.substring(0, 3)]);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumDiscount +=
            (invoice[i].discount * rates.GBP) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "EUR"
        ? (sumDiscount +=
            (invoice[i].discount * rates.EUR) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "AUD"
        ? (sumDiscount +=
            (invoice[i].discount * rates.AUD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "USD"
        ? (sumDiscount +=
            (invoice[i].discount * rates.USD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "CAD"
        ? (sumDiscount +=
            (invoice[i].discount * rates.CAD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "TRY"
        ? (sumDiscount +=
            (invoice[i].discount * rates.TRY) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "SGD"
        ? (sumDiscount +=
            (invoice[i].discount * rates.SGD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "HUF"
        ? (sumDiscount +=
            (invoice[i].discount * rates.HUF) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "NZD"
        ? (sumDiscount +=
            (invoice[i].discount * rates.NZD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : (sumDiscount +=
            (invoice[i].discount * rates.RON) /
            rates[invoice[i].currency?.substring(0, 3)]);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumVatTotal +=
            (invoice[i].vat * rates.GBP) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "EUR"
        ? (sumVatTotal +=
            (invoice[i].vat * rates.EUR) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "AUD"
        ? (sumVatTotal +=
            (invoice[i].vat * rates.AUD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "USD"
        ? (sumVatTotal +=
            (invoice[i].vat * rates.USD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "CAD"
        ? (sumVatTotal +=
            (invoice[i].vat * rates.CAD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "TRY"
        ? (sumVatTotal +=
            (invoice[i].vat * rates.TRY) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "SGD"
        ? (sumVatTotal +=
            (invoice[i].vat * rates.SGD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "HUF"
        ? (sumVatTotal +=
            (invoice[i].vat * rates.HUF) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "NZD"
        ? (sumVatTotal +=
            (invoice[i].vat * rates.NZD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : (sumVatTotal +=
            (invoice[i].vat * rates.RON) /
            rates[invoice[i].currency?.substring(0, 3)]);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumBAServiceCharge +=
            (invoice[i]?.basc * rates.GBP) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "EUR"
        ? (sumBAServiceCharge +=
            (invoice[i]?.basc * rates.EUR) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "AUD"
        ? (sumBAServiceCharge +=
            (invoice[i]?.basc * rates.AUD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "USD"
        ? (sumBAServiceCharge +=
            (invoice[i]?.basc * rates.USD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "CAD"
        ? (sumBAServiceCharge +=
            (invoice[i]?.basc * rates.CAD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "TRY"
        ? (sumBAServiceCharge +=
            (invoice[i]?.basc * rates.TRY) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "SGD"
        ? (sumBAServiceCharge +=
            (invoice[i]?.basc * rates.SGD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "HUF"
        ? (sumBAServiceCharge +=
            (invoice[i]?.basc * rates.HUF) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "NZD"
        ? (sumBAServiceCharge +=
            (invoice[i]?.basc * rates.NZD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : (sumBAServiceCharge +=
            (invoice[i]?.basc * rates.RON) /
            rates[invoice[i].currency?.substring(0, 3)]);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumServiceCharge +=
            (invoice[i]?.pbsc * rates.GBP) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "EUR"
        ? (sumServiceCharge +=
            (invoice[i]?.pbsc * rates.EUR) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "AUD"
        ? (sumServiceCharge +=
            (invoice[i]?.pbsc * rates.AUD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "USD"
        ? (sumServiceCharge +=
            (invoice[i]?.pbsc * rates.USD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "CAD"
        ? (sumServiceCharge +=
            (invoice[i]?.pbsc * rates.CAD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "TRY"
        ? (sumServiceCharge +=
            (invoice[i]?.pbsc * rates.TRY) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "SGD"
        ? (sumServiceCharge +=
            (invoice[i]?.pbsc * rates.SGD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "HUF"
        ? (sumServiceCharge +=
            (invoice[i]?.pbsc * rates.HUF) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "NZD"
        ? (sumServiceCharge +=
            (invoice[i]?.pbsc * rates.NZD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : (sumServiceCharge +=
            (invoice[i]?.pbsc * rates.RON) /
            rates[invoice[i].currency?.substring(0, 3)]);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumDeposit +=
            (invoice[i]?.estimate * rates.GBP) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "EUR"
        ? (sumDeposit +=
            (invoice[i]?.estimate * rates.EUR) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "AUD"
        ? (sumDeposit +=
            (invoice[i]?.estimate * rates.AUD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "USD"
        ? (sumDeposit +=
            (invoice[i]?.estimate * rates.USD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "CAD"
        ? (sumDeposit +=
            (invoice[i]?.estimate * rates.CAD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "TRY"
        ? (sumDeposit +=
            (invoice[i]?.estimate * rates.TRY) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "SGD"
        ? (sumDeposit +=
            (invoice[i]?.estimate * rates.SGD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "HUF"
        ? (sumDeposit +=
            (invoice[i]?.estimate * rates.HUF) /
            rates[invoice[i].currency?.substring(0, 3)])
        : currency?.substring(0, 3) == "NZD"
        ? (sumDeposit +=
            (invoice[i]?.estimate * rates.NZD) /
            rates[invoice[i].currency?.substring(0, 3)])
        : (sumDeposit +=
            (invoice[i]?.estimate * rates.RON) /
            rates[invoice[i].currency?.substring(0, 3)]);
    }
  }

  for (let i = 0; i <= invoice?.length - 1; i++) {
    {
      currency?.substring(0, 3) == "GBP"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? (invoice[i].paid * rates.GBP) /
              rates[invoice[i].currency?.substring(0, 3)]
            : 0 * rates.GBP)
        : currency?.substring(0, 3) == "EUR"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? (invoice[i].paid * rates.EUR) /
              rates[invoice[i].currency?.substring(0, 3)]
            : 0 * rates.EUR)
        : currency?.substring(0, 3) == "AUD"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? (invoice[i].paid * rates.AUD) /
              rates[invoice[i].currency?.substring(0, 3)]
            : 0 * rates.AUD)
        : currency?.substring(0, 3) == "USD"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? (invoice[i].paid * rates.USD) /
              rates[invoice[i].currency?.substring(0, 3)]
            : 0 * rates.USD)
        : currency?.substring(0, 3) == "CAD"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? (invoice[i].paid * rates.CAD) /
              rates[invoice[i].currency?.substring(0, 3)]
            : 0 * rates.CAD)
        : currency?.substring(0, 3) == "TRY"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? (invoice[i].paid * rates.TRY) /
              rates[invoice[i].currency?.substring(0, 3)]
            : 0 * rates.TRY)
        : currency?.substring(0, 3) == "SGD"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? (invoice[i].paid * rates.SGD) /
              rates[invoice[i].currency?.substring(0, 3)]
            : 0 * rates.SGD)
        : currency?.substring(0, 3) == "HUF"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? (invoice[i].paid * rates.HUF) /
              rates[invoice[i].currency?.substring(0, 3)]
            : 0 * rates.HUF)
        : currency?.substring(0, 3) == "NZD"
        ? (sumPaid += invoice[i].invoice_payment_status
            ? (invoice[i].paid * rates.NZD) /
              rates[invoice[i].currency?.substring(0, 3)]
            : 0 * rates.NZD)
        : (sumPaid += invoice[i].invoice_payment_status
            ? (invoice[i].paid * rates.RON) /
              rates[invoice[i].currency?.substring(0, 3)]
            : 0 * rates.RON);
    }
  }

  const BankPayment = async (e) => {
    try {
      const response = await Axios.put(
        `/api/accountclient/bank-pay-bill/billID/${billID}`
      );
      swal({
        icon: "success",
        text: "Success",
        buttons: "Ok",
      }).then(function () {
        window.location = "/account-client/dashboard";
      });
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text: error.response?.data,
        buttons: "Ok",
      });
    }
  };

  const handleBankPay = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          BankPayment(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>Item no.</th>
              <th>Assignment ID</th>
              <th>Invoice No.</th>
              <th>Invoice Date</th>
              <th>SP/Linguist ID</th>
              <th>VAT No.</th>
              <th>Country</th>
              <th>Currency</th>
              <th>Total</th>
              <th>Discount</th>
              <th>VAT Total</th>
              <th>BAC Service Charge</th>
              <th>Platform Service Charge</th>
              <th>Deposit</th>
              <th>Paid</th>
              <th>Due</th>
              <th>Due in {currency?.substring(0, 3)}</th>
            </tr>
          </thead>
          <tbody>
            {invoice?.map((data) => (
              <tr>
                <td>{sl++}</td>
                <td>{data?.assignmentID ? data?.assignmentID : "N/A"}</td>
                <td>{data?.invoiceID ? data?.invoiceID : "N/A"}</td>
                <td>{data?.invoiceDate ? data?.invoiceDate : "N/A"}</td>
                <td>{data?.linID ? data?.linID : "N/A"}</td>
                <td>{data?.linVat ? data?.linVat : "N/A"}</td>
                <td>{data?.linCountry ? data?.linCountry : "N/A"}</td>
                <td>{data?.currency?.substring(0, 3)}</td>
                <td>{data?.total ? data?.total : "00.00"}</td>
                <td>{data?.discount ? data?.discount : "00.00"}</td>
                <td>{data?.vat ? data?.vat : "00.00"}</td>
                <td>{data?.basc ? data?.basc : "00.00"}</td>
                <td>{data?.pbsc ? data?.pbsc : "00.00"}</td>
                {/* deposit amount */}
                <td>{data?.estimate ? data?.estimate : "00.00"}</td>
                {/* Paid amount */}
                <td>
                  {/* {data?.invoice_payment_status ? data?.sumDue : '00'} */}
                  {data?.paid ? data?.paid : "00.00"}
                </td>
                {/* due amount */}
                <td>
                  {/* {data?.invoice_payment_status ? '00' : data?.sumDue} */}
                  {data?.due ? data?.due : "00.00"}
                </td>
                {/* due amount change with currency rate */}
                <td>
                  {currency?.substring(0, 3) == "GBP"
                    ? data?.invoice_payment_status
                      ? "00"
                      : (
                          (data?.due * rates.GBP) /
                          rates[data.currency?.substring(0, 3)]
                        ).toFixed(2)
                    : currency?.substring(0, 3) == "EUR"
                    ? data?.invoice_payment_status
                      ? "00"
                      : (
                          (data?.due * rates.EUR) /
                          rates[data.currency?.substring(0, 3)]
                        ).toFixed(2)
                    : currency?.substring(0, 3) == "AUD"
                    ? data?.invoice_payment_status
                      ? "00"
                      : (
                          (data?.due * rates.AUD) /
                          rates[data.currency?.substring(0, 3)]
                        ).toFixed(2)
                    : currency?.substring(0, 3) == "USD"
                    ? data?.invoice_payment_status
                      ? "00"
                      : (
                          (data?.due * rates.USD) /
                          rates[data.currency?.substring(0, 3)]
                        ).toFixed(2)
                    : currency?.substring(0, 3) == "CAD"
                    ? data?.invoice_payment_status
                      ? "00"
                      : (
                          (data?.due * rates.CAD) /
                          rates[data.currency?.substring(0, 3)]
                        ).toFixed(2)
                    : currency?.substring(0, 3) == "TRY"
                    ? data?.invoice_payment_status
                      ? "00"
                      : (
                          (data?.due * rates.TRY) /
                          rates[data.currency?.substring(0, 3)]
                        ).toFixed(2)
                    : currency?.substring(0, 3) == "SGD"
                    ? data?.invoice_payment_status
                      ? "00"
                      : (
                          (data?.due * rates.SGD) /
                          rates[data.currency?.substring(0, 3)]
                        ).toFixed(2)
                    : currency?.substring(0, 3) == "HUF"
                    ? data?.invoice_payment_status
                      ? "00"
                      : (
                          (data?.due * rates.HUF) /
                          rates[data.currency?.substring(0, 3)]
                        ).toFixed(2)
                    : currency?.substring(0, 3) == "NZD"
                    ? data?.invoice_payment_status
                      ? "00"
                      : (
                          (data?.due * rates.NZD) /
                          rates[data.currency?.substring(0, 3)]
                        ).toFixed(2)
                    : data?.invoice_payment_status
                    ? "00"
                    : (
                        (data?.due * rates.RON) /
                        rates[data.currency?.substring(0, 3)]
                      ).toFixed(2)}
                </td>
              </tr>
            ))}

            <tr
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                backgroundColor: "lightgray",
              }}
            >
              <td>Total</td>

              <td>{invoice?.length}</td>

              <td></td>

              <td></td>

              <td></td>

              <td></td>

              <td></td>

              <td>
                Total in currency (
                {currency?.substring(0, 3) ? currency?.substring(0, 3) : "GBP"})
              </td>

              <td>
                {currency?.substring(0, 3) !== "GBP"
                  ? (sumTotal * (105 / 100)).toFixed(2)
                  : sumTotal.toFixed(2)}
              </td>

              {/* total discount amount */}
              <td>{sumDiscount.toFixed(2)}</td>
              {/* total vat amount */}
              <td>{sumVatTotal.toFixed(2)}</td>
              {/* Billing account service charge coming from company info card */}
              <td>{sumBAServiceCharge.toFixed(2)}</td>
              {/* platform booking service charge comming from company info card */}
              <td>{sumServiceCharge.toFixed(2)}</td>
              {/* total deposit or estimate amount */}
              <td>{sumDeposit.toFixed(2)}</td>
              {/* total paid amount */}
              <td>{sumPaid.toFixed(2)}</td>
              {/* total due amount */}
              <td>{sumDue.toFixed(2)}</td>
              {/* total due amount */}
              <td>
                {currency?.substring(0, 3) !== "GBP"
                  ? (sumDue * (105 / 100)).toFixed(2)
                  : sumDue.toFixed(2)}
              </td>

              <td style={{ display: "none" }}></td>
            </tr>
          </tbody>
        </table>
      </TableDiv>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Header style={{ marginTop: "16px" }} as="h4">
          Thank You For Your Business
        </Header>
        <Header
          color="grey"
          style={{ marginBottom: "16px", marginTop: "0" }}
          as="h4"
        >
          {companyCard?.cname} {companyCard?.caddress}
        </Header>

        {!bill?.invoice_payment_status ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomSemanticDD
              onChange={(e) => handlePayOptions(e)}
              label="Pay"
              data={PAY_OPTIONS}
            />
            <br />
            {payOptions === "Marketplace bank transfer" && (
              <CustomSemanticDD
                onChange={(e) => handleCurrency(e)}
                label="Currency"
                data={CURRENCY}
                value={bankCurrency}
              />
            )}
            <br />
            {payOptions === "Marketplace bank transfer" && bankCurrency && (
              <Button
                onClick={handleBankPay}
                style={{ marginBottom: "10px" }}
                color="purple"
              >
                Accept and Pay
              </Button>
            )}

            {payOptions === "Marketplace bank transfer" && currency && (
              <Header as="h4">
                Total to be paid including VAT/GST:
                {bankCurrency?.substring(0, 3)} {convertedCurrency}
              </Header>
            )}

            {payOptions === "Marketplace bank transfer" && (
              <Header as="h3">{bankDetails.account_holder_name}</Header>
            )}

            {payOptions === "Marketplace bank transfer" &&
              bankDetails.account_number && (
                <Statistic color="purple" size="mini">
                  <Statistic.Label>Account number</Statistic.Label>
                  <Statistic.Value>
                    {bankDetails.account_number}
                  </Statistic.Value>
                </Statistic>
              )}

            {payOptions === "Marketplace bank transfer" &&
              bankDetails.sort_code && (
                <Statistic color="purple" size="mini">
                  <Statistic.Label>Sort code</Statistic.Label>
                  <Statistic.Value>{bankDetails.sort_code}</Statistic.Value>
                </Statistic>
              )}

            {payOptions === "Marketplace bank transfer" && bankDetails.bic && (
              <Statistic color="purple" size="mini">
                <Statistic.Label>BIC</Statistic.Label>
                <Statistic.Value>{bankDetails.bic}</Statistic.Value>
              </Statistic>
            )}

            {payOptions === "Marketplace bank transfer" && bankDetails.iban && (
              <Statistic color="purple" size="mini">
                <Statistic.Label>IBAN</Statistic.Label>
                <Statistic.Value>{bankDetails.iban}</Statistic.Value>
              </Statistic>
            )}

            {payOptions === "Marketplace bank transfer" && bankDetails.bsb && (
              <Statistic color="purple" size="mini">
                <Statistic.Label>BSB</Statistic.Label>
                <Statistic.Value>{bankDetails.bsb}</Statistic.Value>
              </Statistic>
            )}

            {payOptions === "Marketplace bank transfer" &&
              bankDetails.institution_number && (
                <Statistic color="purple" size="mini">
                  <Statistic.Label>Institution number</Statistic.Label>
                  <Statistic.Value>
                    {bankDetails.institution_number}
                  </Statistic.Value>
                </Statistic>
              )}

            {payOptions === "Marketplace bank transfer" &&
              bankDetails.bank_code && (
                <Statistic color="purple" size="mini">
                  <Statistic.Label>Bank code</Statistic.Label>
                  <Statistic.Value>{bankDetails.bank_code}</Statistic.Value>
                </Statistic>
              )}

            {payOptions === "Marketplace bank transfer" &&
              bankDetails.routing_number && (
                <Statistic color="purple" size="mini">
                  <Statistic.Label>Routing number</Statistic.Label>
                  <Statistic.Value>
                    {bankDetails.routing_number}
                  </Statistic.Value>
                </Statistic>
              )}

            {payOptions === "Marketplace bank transfer" &&
              bankDetails.transit_number && (
                <Statistic color="purple" size="mini">
                  <Statistic.Label>Transit number</Statistic.Label>
                  <Statistic.Value>
                    {bankDetails.transit_number}
                  </Statistic.Value>
                </Statistic>
              )}

            {payOptions === "Marketplace bank transfer" && (
              <Header as="h5">
                Please inform our Admin Team after processing the payment. Bank
                transfer payment can take a few days to complete.
                {/* Please use either your client reference number
                            {invoice.accountclientID.id}, 
                            invoice or billing number
                            {invoice.timesheetID.id} 
                            - as the payment reference. */}
              </Header>
            )}
          </div>
        ) : (
          <p style={{ color: "green" }}>This bill has been paid.</p>
        )}
        {payOptions === "Marketplace speedy payment" && (
          <StripeLayout avatar={true}>
            <CheckoutBACInvoice
              // price="10"
              price={
                currency?.substring(0, 3) !== "GBP"
                  ? (sumDue * (105 / 100)).toFixed(2)
                  : sumDue.toFixed(2)
              }
              currency={currency?.substring(0, 3)}
              onSuccessfulCheckout={(id) =>
                history.push(
                  `/account-client/monthlybill/success/bill/payment/${billID}/${id}`
                )
              }
              bacClientID={clientID}
              clientDetails={clientID}
              invoice={invoice}
            />
          </StripeLayout>
        )}
      </div>
    </div>
  );
};

export default Bill;
