import { useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
import swal from "sweetalert";
import Axios from 'axios'
import Cookies from 'js-cookie'


const EmailActivationPrivate = () => {
  const linID = Cookies.get('linID')
  let { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    try {
      const response = await Axios.post(`${process.env.REACT_APP_BACKEND_API}/private/signup/linID/${linID}`, {
        token: token,
      });
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });

      setTimeout(() => {
        history.push("/");
      }, 2000);
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return <div></div>;
};

export default EmailActivationPrivate;

