import { useState, Fragment, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Axios from "utils/axios";

// Material
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";

const EditableText = ({ text, readOnly, name, id, setReload, load }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [value, setValue] = useState(text);
  const [inputName, setInputName] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const handleCheck = async () => {
    try {
      const fd = new FormData();
      fd.append(inputName, value);
      if (!inputName) {
        setIsClicked(!isClicked);
        return false;
      }
      setLoading(true);

      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/paid-to-sppo-direct-invoice/invID/${id}`,
        fd
      );
      load();
      setIsClicked(!isClicked);
      setReload(Math.random());
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setInputName(e.target.name);
  };

  return (
    <span
      style={{
        display: isClicked && "flex",
        justifyContent: isClicked && "flex-start",
        alignItems: isClicked && "center",
        flexWrap: isClicked && "wrap",
      }}
    >
      {isClicked ? (
        <TextField
          value={value}
          onChange={handleChange}
          name={name}
          id="outlined-basic"
          placeholder={text}
          variant="outlined"
        />
      ) : (
        <span>{text}</span>
      )}{" "}
      {!readOnly ? (
        <Fragment>
          {!isClicked ? (
            <EditIcon
              color="primary"
              onClick={handleClick}
              style={{
                cursor: "pointer",
                fontSize: "1.2rem",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <Fragment>
              {loading ? (
                <CircularProgress
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              ) : (
                <CheckIcon
                  color="secondary"
                  onClick={handleCheck}
                  style={{
                    cursor: "pointer",
                    fontSize: "2rem",
                    verticalAlign: "middle",
                    color: "green",
                  }}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      ) : null}{" "}
    </span>
  );
};

export default EditableText;
