import React from "react";
import { Table } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";

const Items = ({ values }) => {
  return (
    <GridContainer alignItems="center">
     <p>
        {values?.bank_pay
          ? "Paid by bank transfer"
          : values?.pay_later
          ? "Pay Later / UK Legal Aid Job"
          : values?.charge_id
          ? "Paid by stripe"
          : null}
      </p>
      <Table color={"pink"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Assignment Details</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Assignment Reference ID</Table.Cell>
            <Table.Cell>
              {values?.id == "undefined"
                ? "N/A"
                : values?.id
                ? values?.id
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Booking reference number or purchase order number
            </Table.Cell>
            <Table.Cell>
              {values?.purchase_order_number == "undefined"
                ? "N/A"
                : values?.purchase_order_number
                ? values?.purchase_order_number
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Interpreting Service</Table.Cell>
            <Table.Cell>
              {values?.interpreting_service_required == "undefined"
                ? "N/A"
                : values?.interpreting_service_required
                ? values?.interpreting_service_required
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          {values?.interpreting_service_required ==
          "Remote Interpreting (RI) / Video Remote Interpreting (VRI)" ? (
            <Table.Row>
              <Table.Cell>Remote / Telephone Details</Table.Cell>
              <Table.Cell>
                {values?.remote_address == "undefined"
                  ? "N/A"
                  : values?.remote_address
                  ? values?.remote_address
                  : "Please contact admin / client for details."}
              </Table.Cell>
            </Table.Row>
          ) : (
            <>
              <Table.Row>
                <Table.Cell>Venue address: House no, Street name</Table.Cell>
                <Table.Cell>
                  {values?.house_number_street_name == "undefined"
                    ? "N/A"
                    : values?.house_number_street_name
                    ? values?.house_number_street_name
                    : "N/A"}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Address line 2</Table.Cell>
                <Table.Cell>
                  {values?.address_line_two == "undefined"
                    ? "N/A"
                    : values?.address_line_two
                    ? values?.address_line_two
                    : "N/A"}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Town / City</Table.Cell>
                <Table.Cell>
                  {values?.town == "undefined"
                    ? "N/A"
                    : values?.town
                    ? values?.town
                    : "N/A"}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>County / Region</Table.Cell>
                <Table.Cell>
                  {values?.region == "undefined"
                    ? "N/A"
                    : values?.region
                    ? values?.region
                    : "N/A"}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Post / Zip Code</Table.Cell>
                <Table.Cell>
                  {values?.post_code == "undefined"
                    ? "N/A"
                    : values?.post_code
                    ? values?.post_code
                    : "N/A"}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Country</Table.Cell>
                <Table.Cell>
                  {values?.country == "undefined"
                    ? "N/A"
                    : values?.country
                    ? values?.country
                    : "N/A"}
                </Table.Cell>
              </Table.Row>
            </>
          )}

          <Table.Row>
            <Table.Cell>Source Language</Table.Cell>
            <Table.Cell>{values?.source_language}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Source language skill level</Table.Cell>
            <Table.Cell>
              {values?.source_linguist_skill_level
                ? values?.source_linguist_skill_level
                : values?.source_language_level
                ? values?.source_language_level
                : values?.source_expertise_level_required}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target Language</Table.Cell>
            <Table.Cell>{values?.target_language}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target language skill level</Table.Cell>
            <Table.Cell>
              {values?.target_linguist_skill_level
                ? values?.target_linguist_skill_level
                : values?.target_language_level
                ? values?.target_language_level
                : values?.target_expertise_level_required}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Types of Interpreting</Table.Cell>
            <Table.Cell>
              {values?.typesof_interpreting_required == "undefined"
                ? "N/A"
                : values?.typesof_interpreting_required
                ? values?.typesof_interpreting_required
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Job Description</Table.Cell>
            <Table.Cell>
              {values?.job_description == "undefined"
                ? "N/A"
                : values?.job_description
                ? values?.job_description
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Preferred Linguist / Service Provider ID</Table.Cell>
            <Table.Cell>
              {values?.preferred_interpreter == "undefined"
                ? "N/A"
                : values?.preferred_interpreter
                ? values?.preferred_interpreter
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Preferred gender</Table.Cell>
            <Table.Cell>
              {values?.gender == "undefined"
                ? "N/A"
                : values?.gender
                ? values?.gender
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Security clearence level</Table.Cell>
            <Table.Cell>
              {values?.security_clearance == "undefined"
                ? "N/A"
                : values?.security_clearance
                ? values?.security_clearance
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Car parking for linguist</Table.Cell>
            <Table.Cell>
              {values?.car_parking == "undefined"
                ? "N/A"
                : values?.car_parking
                ? values?.car_parking
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Additional Information or direction for linguist
            </Table.Cell>
            <Table.Cell>
              {values?.additional_info == "undefined"
                ? "N/A"
                : values?.additional_info
                ? values?.additional_info
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Estimated value / estimate paid</Table.Cell>
            <Table.Cell>
              {values?.estimate_paid_amount
                ? values?.estimate_paid_amount
                : values?.estimate}{" "}
              {values?.currency_new
                ? values?.currency_new?.substring(0, 3)
                : values?.currency?.substring(0, 3)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </GridContainer>
  );
};

export default Items;
