import React from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TicketForm from "components/Admin/Communication/CAC/CreateTicketForm";
import TicketOptions from "components/Admin/Communication/CAC/CACTicketOptions";
import CustomAvatar from "components/Common/CustomAvatar";
import Cookies from "js-cookie";

const CommunicationMenu = () => {
  const classes = useDashboardStyles();
  const token = Cookies.get("CACtoken");

  return (
    <CashClientLayout>
      <Cashclient>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                color="primary"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <p className={classes.cardTitleWhite}>
                  Communication - Support Tickets - Opening a new Support Ticket
                </p>
                <IconButton color="inherit">
                  <ArrowBackIcon
                    onClick={() => history.goBack()}
                  ></ArrowBackIcon>
                </IconButton>
              </CardHeader>
              <CardBody>
                <TicketOptions />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    Ticket Form
                  </Typography>
                  <Typography variant="subtitle2"></Typography>
                </div>
                <TicketForm token={token} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default CommunicationMenu;
