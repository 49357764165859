import { v4 as uuidv4 } from "uuid";
import BookingHistory from "pages/account-client/BookingHistory";
import Dashboard from "pages/account-client/Dashboard";
import BillingAccount from "pages/account-client/BilllingAccount";
import CommunicationMenu from "pages/account-client/Communication/Communication";
import CreateTicket from "pages/account-client/Communication/CreateTicket";
import OpenTicket from "pages/account-client/Communication/TicketList";
import RateCardPage from "pages/account-client/RateCardPage";
import BookInterpreterPageAC from "pages/account-client/AccountClientBookInterpreting";
import ACOrderTranslationPage from "pages/account-client/OrderTranslationPage";
import RequestQuotePage from "pages/account-client/RequestQuotePage";
import FeedbackPage from "pages/account-client/FeedbackPage";
import ProfilePage from "pages/account-client/ProfilePage";
import MonthlyBills from "pages/account-client/Bills/MonthlyBill";
import ViewBills from "pages/account-client/Bills/ViewBills";
import BusinessProfilePage from "pages/account-client/BusinessProfilePage";

import AddAnUser from "pages/account-client/AddNewUser/AddUser";
import ActiveUser from "pages/account-client/AddNewUser/ActiveUser";
import InactiveUser from "pages/account-client/AddNewUser/InactiveUser";
import UserProfileUpdate from "pages/account-client/AddNewUser/UserProfileUpdate";

import Report from "pages/account-client/Report";
import ResetPasswordPage from "pages/account-client/ResetPasswordPage";
import PercentageCardView from "pages/account-client/PercentageCardView";
import InvoicePage from "pages/account-client/Invoices";
import ACJobAdvertPage from "pages/account-client/ACjobadvert";
import SetNewPassword from "pages/account-client/SetNewPassword";
import ServiceAgreement from "pages/account-client/ServiceAgreement";
import Help from "pages/account-client/Help";
import BookingSecratery from "pages/account-client/BACBookingSecretary";
import ActivityLogs from "pages/account-client/ActivityLogs/ActivityLogs";

//Icons
import BPicon from "assets/icons/business-profile.png";
import CMicon from "assets/icons/communication-menuAC.png";
import Dicon from "assets/icons/dashboardAC.png";
import JAicon from "assets/icons/job-advert.png";
import BHicon from "assets/icons/booking-history.png";
import Bicon from "assets/icons/monthly-billAC.png";
import Ficon from "assets/icons/feedback.png";
import Ricon from "assets/icons/report.png";
import AUicon from "assets/icons/add-user.png";
import VRicon from "assets/icons/view-rate-card.png";
import VPCicon from "assets/icons/view-percentage-card.png";
import Iicon from "assets/icons/invoice.png";
import ViewAccountClientInterpreting from "pages/account-client/Assignment/ViewInterpretingDetails";
import ViewAccountClientTranslation from "pages/account-client/Assignment/ViewTranslationDetails";
import ViewAccountClientQuotation from "pages/account-client/Assignment/ViewQuotationDetails";
import MBicon from "assets/icons/bills-01.png";
import UAicon from "assets/icons/unconfirmed assignments-01.png";
import UQicon from "assets/icons/unconfirmed-quote.png";
import UCAicon from "assets/icons/confirmed asssignments.png";
import UCQicon from "assets/icons/confirmed quotation.png";
import NCCRicon from "assets/icons/notes-02.png";
import NMCRicon from "assets/icons/my notes-02.png";
import NTMicon from "assets/icons/my noteto do list.png";
import ActivityLogIcon from "assets/icons/activitylog.png";
import SendRegForm from "pages/account-client/AddNewUser/SendRegForm";

import ConfirmAssignments from "pages/account-client/Assignment/confirmAssignments/ConfirmAssignments";
import ConfirmedInterpreters from "pages/account-client/Assignment/confirmAssignments/ConfirmedInterpreters";
import ConfirmedTranslations from "pages/account-client/Assignment/confirmAssignments/ConfirmedTranslations";
import ConfirmedQuotations from "pages/account-client/Assignment/confirmAssignments/ConfirmedQuotations";

import UnconfirmedAssignments from "pages/account-client/Assignment/unconfirmAssignments/unconfirmAssignments";
import UnconfirmedInterpreters from "pages/account-client/Assignment/unconfirmAssignments/UnconfirmedInterpreters";
import UnconfirmedTranslations from "pages/account-client/Assignment/unconfirmAssignments/UnconfirmedTranslations";
import UnconfirmedQuotations from "pages/account-client/Assignment/unconfirmAssignments/UnconfirmedQuotations";

import CloseTimesheetPage from "pages/account-client/Assignment/ClosedAssignments/CloseTimesheet.jsx";
import UnpaidInvoice from "pages/account-client/UnpaidInvoice.jsx";
import PaidInvoice from "pages/account-client/PaidInvoice.jsx";
import ViewUnpaidInvoicePage from "pages/account-client/Invoices/PaymentINVpageBooking.jsx";
import ViewTransInvoicePage from "pages/account-client/Invoices/PaymentINVpageTrans.jsx";

import ViewBACBookingDocument from "pages/account-client/Assignment/ViewBACBookingDocument";
import ViewBACTransDocument from "pages/account-client/Assignment/ViewBACTransDocument";
import ViewBACQuoteDocument from "pages/account-client/Assignment/ViewBACQuoteDocument";
import ViewUnconfirmQuotationPage from "pages/account-client/Assignment/ViewUnconfirmQuotation";

import CreateTimeSheetPage from "pages/account-client/Invoices/CreateTimeSheetPage";
import ViewBACBookingTimesheet from "pages/account-client/Invoices/ViewBACBookingTimesheet";
import AmendTimesheet from "pages/account-client/Invoices/AmendTimesheet";
import ViewInvoice from "pages/account-client/Invoices/ViewInvoice";

import ProjectReportCreate from "pages/account-client/Invoices/CreateTransProjectReport";
import ProjectReportQuoteCreate from "pages/account-client/Invoices/CreateQuoteProjectReport";
import ProjectReportView from "pages/account-client/Invoices/ViewProjectReport";
import AmendProjectReport from "pages/account-client/Invoices/AmendProjectReport";
import ProjectReportConfirm from "pages/account-client/Invoices/ConfirmProjectReport";

// payment routes for booking estimate and invoice
import BACStripePayment from "components/Auth/BookingForm/BACBooking/StripePayment";
import SuccessBACStripe from "components/Auth/BookingForm/BACBooking/SuccessBACStripe";
import SuccessBACBookingINV from "components/AccountClient/Invoices/ViewUnpaidInvoice/SucessBACBookingINV";
import BACPayTrans from "components/Auth/BookingForm/BACTranslation/StripePayment";
import SuccessTransBACStripe from "components/Auth/BookingForm/BACTranslation/SuccessBACStripe";
import SuccessBACTransINV from "components/AccountClient/Invoices/ViewUnpaidInvoice/SucessBACTransINV";
import SuccessBACQuote from "components/AccountClient/Invoices/ViewUnpaidInvoice/SuccessBACQuote";
import SuccessBACBill from "components/AccountClient/Invoices/ViewUnpaidInvoice/SuccessBACBill";

import ViewBACDirectINV from "pages/account-client/Invoices/ViewDirectInvoice";

import ColleagueNote from "pages/account-client/MyNote/ColleagueNote";
import MyNote from "pages/account-client/MyNote/MyNote";

import ViewAttachConversation from "pages/account-client/Communication/ViewAttachConversation";
import ViewAttachTicket from "pages/account-client/Communication/ViewAttachTicket";

import ViewDocument from "components/AccountClient/AccountClientDasboard/ProfileUpdate/Documents/ViewDocument";

import RecommendLC from "pages/account-client/RecommendLC/RecommendLCForm";

import ViewProjectReportDocument from "pages/account-client/Invoices/ViewProjectReportDocument";
import ViewTimesheetDocument from "pages/account-client/Invoices/ViewTimesheetDocument";

import TranslationDeliveryDoc from "pages/Component/Translation_Delivery/BAC/ViewDocument";
import BulkTimeSheetListPage from "pages/account-client/Timesheet/BulkTimeSheetListPage";
import CreateBulkTimeSheet from "pages/account-client/Timesheet/CreateBulkTimeSheet";
import ConfirmBulkTimeSheet from "pages/account-client/Timesheet/ConfirmBulkTimeSheet";
import Notifications from "pages/account-client/Notifications/Notifications";
import ViewAttachMessage from "pages/account-client/WelcomeMessage/ViewAttachMessage";



const ACCOUNT_CLIENT = [
  {
    id: uuidv4(),
    text: "Dashboard",
    subText: "Account Client Dashboard",
    path: "account-client/dashboard",
    type: "dashboard",
    component: Dashboard,
    icon: Dicon,
  },

  {
    id: uuidv4(),
    text: "Assignments",
    path: "account-client/assignments",
    icon: Bicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Confirmed Assignments",
        component: ConfirmAssignments,
        path: "account-client/confirm-assignments",
        icon: UCAicon,
      },
      {
        id: uuidv4(),
        text: "Confirmed Quotations",
        path: "account-client/confirmed-quotations",
        component: ConfirmedQuotations,
        icon: UCQicon,
      },
      {
        id: uuidv4(),
        text: "Unconfirmed Assignments",
        path: "account-client/unconfirm-assignments",
        component: UnconfirmedAssignments,
        icon: UAicon,
      },
      {
        id: uuidv4(),
        text: "Unconfirmed Quotations",
        path: "account-client/unconfirmed-quotations",
        component: UnconfirmedQuotations,
        icon: UQicon,
      },
    ],
  },
  {
    id: uuidv4(),
    path: "account-client/unconfirmed-quotations/:data/:type",
    component: UnconfirmedQuotations,
  },
  {
    id: uuidv4(),
    path: "account-client/confirm-close-and-timesheet/:id",
    component: CloseTimesheetPage,
  },
  {
    id: uuidv4(),
    path: "account-client/bulk-booking-confirm-close-and-timesheet/:id",
    component: ConfirmBulkTimeSheet,
  },
  {
    id: uuidv4(),
    component: PaidInvoice,
    path: "account-client/invoices-list/paid-invoices",
  },
  {
    id: uuidv4(),
    component: UnpaidInvoice,
    path: "account-client/invoices-list/unpaid-invoices",
  },
  {
    id: uuidv4(),
    component: ViewUnpaidInvoicePage,
    path: "account-client/invoices-list/unpaid-invoices/view-invoice/:id",
  },

  {
    id: uuidv4(),
    component: ViewTransInvoicePage,
    path: "account-client/invoices-list/unpaid-invoices/view-translation-invoice/:id",
  },

  {
    id: uuidv4(),
    path: "account-client/help",
    component: Help,
  },
  {
    id: uuidv4(),
    path: "account-client/reset-password",
    component: ResetPasswordPage,
  },
  {
    id: uuidv4(),
    path: "account-client/view-account-client-booking-interpreting/:id",
    component: ViewAccountClientInterpreting,
  },
  {
    id: uuidv4(),
    path: "account-client/view-account-client-order-translation/:id",
    component: ViewAccountClientTranslation,
  },
  {
    id: uuidv4(),
    path: "account-client/view-account-client-quotation/:id",
    component: ViewAccountClientQuotation,
  },
  {
    id: uuidv4(),
    text: "Task, Note And Do List",
    path: "account-client/tasks",
    icon: NTMicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Notes And Do List - Colleagues",
        component: ColleagueNote,
        path: "account-client/colleague-notes",
        icon: NCCRicon,
      },
      {
        id: uuidv4(),
        text: "My Notes And Do List",
        path: "account-client/my-notes",
        component: MyNote,
        icon: NMCRicon,
      },
    ],
  },

  {
    id: uuidv4(),
    text: "Communication",
    path: "account-client/communication",
    component: CommunicationMenu,
    icon: CMicon,
  },
  {
    id: uuidv4(),
    text: "Business Profile",
    path: "account-client/business-profile",
    component: BusinessProfilePage,
    icon: BPicon,
  },
  {
    id: uuidv4(),
    text: "Add An User",
    component: AddAnUser,
    path: "account-client/add-an-user",
    icon: AUicon,
  },

  {
    id: uuidv4(),
    component: ActiveUser,
    path: "account-client/active-user",
  },
  {
    id: uuidv4(),
    component: InactiveUser,
    path: "account-client/inactive-user",
  },
  {
    id: uuidv4(),
    component: UserProfileUpdate,
    path: "account-client/additional-user-profile/:id",
  },
  {
    id: uuidv4(),
    text: "Booking history",
    path: "account-client/booking-history",
    component: BookingHistory,
    icon: BHicon,
  },
  {
    id: uuidv4(),
    text: "Feedback",
    path: "account-client/feedback",
    component: FeedbackPage,
    icon: Ficon,
  },
  {
    id: uuidv4(),
    path: "account-client/book-interpreter",
    component: BookInterpreterPageAC,
  },
  {
    id: uuidv4(),
    path: "account-client/service-agreement-of-account-client",
    component: ServiceAgreement,
  },
  {
    id: uuidv4(),
    path: "accountclient/auth/password/reset/:token",
    component: SetNewPassword,
  },
  {
    id: uuidv4(),
    path: "account-client/order-translation",
    component: ACOrderTranslationPage,
  },
  {
    id: uuidv4(),
    path: "account-client/request-quote",
    component: RequestQuotePage,
  },
  {
    id: uuidv4(),
    component: ViewBills,
    path: "account-client/monthly-bill/:billID/:clientID",
  },
  {
    id: uuidv4(),
    component: BookingSecratery,
    path: "account-client/booking-secratery",
  },
  {
    id: uuidv4(),
    type: "form",
    text: "Job Advert",
    subText: "Job Advert",
    path: `account-client/jobAdvert-form`,
    component: ACJobAdvertPage,
    icon: JAicon,
  },

  {
    id: uuidv4(),
    text: "Billings",
    path: "account-client/billing-account",
    component: BillingAccount,
    icon: Bicon,
    subMenu: [
      {
        id: uuidv4(),
        text: "Invoices",
        component: InvoicePage,
        path: "account-client/invoices-list",
        icon: Iicon,
      },

      {
        id: uuidv4(),
        text: "Monthly bills",
        component: MonthlyBills,
        path: "account-client/monthly-bill",
        icon: MBicon,
      },

      {
        id: uuidv4(),
        text: "View Rate Card",
        path: "account-client/view-rate-card",
        component: RateCardPage,
        icon: VRicon,
      },
      {
        id: uuidv4(),
        text: "View Percentage Card",
        path: "account-client/view-percentage-card",
        component: PercentageCardView,
        icon: VPCicon,
      },

      {
        id: uuidv4(),
        text: "Management Report",
        component: Report,
        path: "account-client/report",
        icon: Ricon,
      },
    ],
  },
  {
    id: uuidv4(),
    text: "Recommend LC",
    path: "account-client/recommend-lc-bac",
    component: RecommendLC,
    icon: NTMicon,
  },
  {
    id: uuidv4(),
    text: "Activity Logs",
    path: "account-client/activity-logs",
    component: ActivityLogs,
    icon: ActivityLogIcon,
  },
  {
    id: uuidv4(),
    path: "account-client/profile",
    component: ProfilePage,
  },

  {
    id: uuidv4(),
    path: "account-client/send-reg-form",
    component: SendRegForm,
  },

  {
    id: uuidv4(),
    path: "account-client/confirmed-interpreters",
    component: ConfirmedInterpreters,
  },
  {
    id: uuidv4(),
    path: "account-client/confirmed-interpreters/:data/:type",
    component: ConfirmedInterpreters,
  },

  {
    id: uuidv4(),
    path: "account-client/confirmed-translations",
    component: ConfirmedTranslations,
  },
  {
    id: uuidv4(),
    path: "account-client/confirmed-translations/:data/:type",
    component: ConfirmedTranslations,
  },

  {
    id: uuidv4(),
    path: "account-client/unconfirmed-interpreters",
    component: UnconfirmedInterpreters,
  },
  {
    id: uuidv4(),
    path: "account-client/unconfirmed-interpreters/:data/:type",
    component: UnconfirmedInterpreters,
  },

  {
    id: uuidv4(),
    path: "account-client/unconfirmed-translations",
    component: UnconfirmedTranslations,
  },
  {
    id: uuidv4(),
    path: "account-client/unconfirmed-translations/:data/:type",
    component: UnconfirmedTranslations,
  },

  {
    id: uuidv4(),
    path: "account-client/view-interpreting-document/:id",
    component: ViewBACBookingDocument,
  },

  {
    id: uuidv4(),
    path: "account-client/view-translation-document/:id",
    component: ViewBACTransDocument,
  },

  {
    id: uuidv4(),
    path: "account-client/view-quotation-document/:id",
    component: ViewBACQuoteDocument,
  },

  {
    id: uuidv4(),
    path: "account-client/view-and-pay-unconfirmed-quotations/quotation/:id",
    component: ViewUnconfirmQuotationPage,
  },

  // timesheet and invoice routes
  {
    id: uuidv4(),
    component: CreateTimeSheetPage,
    path: "account-client/create-close-and-timesheet/:id",
  },
  {
    id: uuidv4(),
    path: "account-client/view-timesheet/:id",
    component: ViewBACBookingTimesheet,
  },
  {
    id: uuidv4(),
    path: "account-client/amend-timesheet/:id",
    component: AmendTimesheet,
  },

  {
    id: uuidv4(),
    path: "account-client/view-invoice/:id",
    component: ViewInvoice,
  },

  // project report
  {
    id: uuidv4(),
    component: ProjectReportCreate,
    path: "account-client/create-translation-project-report/:id",
  },

  {
    id: uuidv4(),
    component: ProjectReportQuoteCreate,
    path: "account-client/create-quote-project-report/:id",
  },

  {
    id: uuidv4(),
    component: ProjectReportView,
    path: "account-client/view-project-report/:id",
  },
  {
    id: uuidv4(),
    component: AmendProjectReport,
    path: "account-client/amend-project-report/:id",
  },

  {
    id: uuidv4(),
    component: ProjectReportConfirm,
    path: "account-client/confirm-project-report/:id",
  },

  // payment routes
  {
    id: uuidv4(),
    path: "bac-interpreter-booking/client/summary/estimate/payment", //Account client estimate booking interpreter
    component: BACStripePayment,
  },
  {
    id: uuidv4(),
    path: "account-client/interpreter/success/estimate/payment/:id", //BAC Booking Interpreter Send Mail
    component: SuccessBACStripe,
  },
  {
    id: uuidv4(),
    path: "account-client/interpreter/success/invoice/payment/:invoiceId/:id", //BAC interpreting Invoice Pay Success Page
    component: SuccessBACBookingINV,
  },

  {
    id: uuidv4(),
    path: "bac-translation-order/client/summary/estimate/payment", //Account estimate order translation
    component: BACPayTrans,
  },
  {
    id: uuidv4(),
    path: "account-client/translation/success/estimate/payment/:id", //BAC Booking Interpreter Send Mail
    component: SuccessTransBACStripe,
  },
  {
    id: uuidv4(),
    path: "account-client/translation/success/invoice/payment/:invoiceId/:id", //BAC interpreting Invoice Pay Success Page
    component: SuccessBACTransINV,
  },

  {
    id: uuidv4(),
    path: "account-client/quotation/success/quote/payment/:invoiceId/:id", //BAC interpreting Invoice Pay Success Page
    component: SuccessBACQuote,
  },

  {
    id: uuidv4(),
    path: "account-client/monthlybill/success/bill/payment/:billID/:id", //BAC monthly bill Pay Success Page
    component: SuccessBACBill,
  },

  {
    id: uuidv4(),
    path: "account-client/view-direct-invoice/:id",
    component: ViewBACDirectINV,
  },

  {
    id: uuidv4(),
    path: "account-client/open-new-ticket",
    component: CreateTicket,
  },

  {
    id: uuidv4(),
    path: "account-client/view-tickets",
    component: OpenTicket,
  },

  {
    id: uuidv4(),
    path: "account-client/view-attachment-ticket-conversation/:id",
    component: ViewAttachConversation,
  },

  {
    id: uuidv4(),
    path: "account-client/view-ticket-attachment/:id",
    component: ViewAttachTicket,
  },

  {
    id: uuidv4(),
    path: "account-client/view-document/:id",
    component: ViewDocument,
  },

  // timesheet and project report documents view routes
  {
    id: uuidv4(),
    path: "account-client/view-timesheet-document-by-linguist/:id",
    component: ViewTimesheetDocument,
  },
  {
    id: uuidv4(),
    path: "account-client/view-timesheet-document-by-client/:id",
    component: ViewTimesheetDocument,
  },
  {
    id: uuidv4(),
    path: "account-client/view-project-report-document-by-linguist/:id",
    component: ViewProjectReportDocument,
  },
  {
    id: uuidv4(),
    path: "account-client/view-project-report-document-by-client/:id",
    component: ViewProjectReportDocument,
  },
  {
    id: uuidv4(),
    path: "account-client/view-project-report-document-by-admin/:id",
    component: ViewProjectReportDocument,
  },

    // view translation delivery document
    {
      id: uuidv4(),
      component: TranslationDeliveryDoc,
      path: "account-client/view-translation-delivery-document1/:id",
    },
    {
      id: uuidv4(),
      component: TranslationDeliveryDoc,
      path: "account-client/view-translation-delivery-document2/:id",
    },
    {
      id: uuidv4(),
      component: TranslationDeliveryDoc,
      path: "account-client/view-translation-delivery-document3/:id",
    },
    {
      id: uuidv4(),
      component: TranslationDeliveryDoc,
      path: "account-client/view-translation-delivery-document4/:id",
    },
    {
      id: uuidv4(),
      component: TranslationDeliveryDoc,
      path: "account-client/view-translation-delivery-document5/:id",
    },
    {
      id: uuidv4(),
      component: BulkTimeSheetListPage,
      path: "account-client/listview-bulk-interpreting-timesheet/:id",
    },
    {
      id: uuidv4(),
      component: CreateBulkTimeSheet,
      path: "account-client/create-bac-bulk-interpreting-timesheet/:id",
    },

    {
      id: uuidv4(),
      path: "account-client/view-all-notifications",
      component: Notifications,
    },
    {
      id: uuidv4(),
      path: "account-client/view-welcome-message-attachment/:id/:message",
      component: ViewAttachMessage,
    },
];

export default ACCOUNT_CLIENT;
