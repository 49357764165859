import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    ["@media(max-width:1024px)"]: {
      width: "-webkit-fill-available",
    },

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "purple",
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};

export default function SimpleAccordion() {
  const classes = useStyles();
  const { id } = useParams();
  const matches = useMediaQuery("(max-width:1024px)");

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Public / Home</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/terms-and-conditions/post-T&C`}
              >
                Terms and Conditions
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/privacy-policy/post-privacy-policy`}
              >
                Privacy Policy
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/job-advert-sliders/post-slider`}
              >
                Job Advert Sliders
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/job-advert-price/post-price`}
              >
                Job Advert Price
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Cash Client</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/cashclient-service-agreement/post-agreement`}
              >
                Service Agreement
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Account Client</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/accountclient-service-agreement/post-agreement`}
              >
                Service Agreement
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Linguist</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/linguist-service-agreement/post-agreement`}
              >
                Service Agreement
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/linguist-public-profile-sliders/post-slider`}
              >
                Public Profile Sliders
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>SPPO</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/sppo-service-agreement/post-agreement`}
              >
                Partnership Agreement
              </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a
                style={{ textAlign: "center" }}
                href={`/admin/sppo-public-profile-sliders/post-slider`}
              >
                Public Profile Sliders
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Employee</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a style={{ textAlign: "center" }} href={`/`}>
                Test Link
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Manager</Typography>
        </AccordionSummary>
        <hr />
        <AccordionDetails>
          <GridContainer
            // justify="center"
            className={classes.container}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <a style={{ textAlign: "center" }} href={`/`}>
                Test Link
              </a>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
