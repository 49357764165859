import { CHANGE_LINGUIST_PUBLIC_PROFILE, UPDATE_PUBLIC_PROFILE_LINGUIST } from "./actionTypes";

export const updatePPLinguist = data => ({
    type: UPDATE_PUBLIC_PROFILE_LINGUIST,
    payload: data,
})

export const changePPLInput = data => ({
    type: CHANGE_LINGUIST_PUBLIC_PROFILE,
    payload: data
})