import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import CustomFormControl from './CustomFormControl'
import CustomLabel from './CustomLabel'

const CustomAutoSelect = ({label, data, width, required, moreInfo, ...rest}) => {

    const defaultProps = {
        options: data,
        getOptionLabel: (option) => option,
    };

    return (
        <CustomFormControl width={`${width || "100%"}`}>
            <Autocomplete
                {...defaultProps}
                {...rest}
                id="auto-complete"
                autoSelect
                includeInputInList
                renderInput={(params) => <TextField {...params} label= {<CustomLabel label={label} moreInfo={moreInfo} required= {required} />} margin="normal" />}
            />
        </CustomFormControl>
    )
}

export default CustomAutoSelect
