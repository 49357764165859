import React from "react";

// Custom Component
import MasterLayout from "layout/MasterLayout";

const AccountClientLayout = ({ children }) => {
  return <MasterLayout layoutType="ACCOUNT_CLIENT">{children}</MasterLayout>;
};

export default AccountClientLayout;
