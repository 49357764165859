const {
  GET_CASH_BOOK_DATA,
  LOADING,
  ADD_DATA,
} = require("redux/actions/linguists/actionTypes");

const INITIAL_STATE = {
  data: [],
  loading: false,
};

const cashBookReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CASH_BOOK_DATA:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case ADD_DATA:
      return {
        ...state,
        data: [payload, ...state.data],
      };
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};

export default cashBookReducer;
