import React from "react";
import { useSelector } from "react-redux";

import { Card, Header } from "semantic-ui-react";

const About = () => {
  const { data } = useSelector((state) => state.updateProfileReducer);

  return (
    <div
      raised
      style={{
        width: "100%",
        padding: "1rem",
        backgroundImage:
          "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
      }}
    >
      <Card.Content>
        <Header as="h2" color="purple">
          Work Experience
        </Header>
        {/* Glitch, need to swap public_psi and public_pst */}
        <Card.Description>
          Total hours of public service interpreting (PSI) experience: {data.public_psi}
        </Card.Description>
        <Card.Description>
          Number words translated from source language(s): {data.public_pst}
        </Card.Description>
      </Card.Content>
    </div>
  );
};

export default About;
