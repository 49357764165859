import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomPassword from "components/Common/CustomPassword";
import CustomPhone from "components/Common/CustomPhone";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { CURRENT_COUNTRY, EMAIL_REGEX } from "data";
import CustomDate from "components/Common/CustomDate";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import {
  changeInhouseClientInput,
  updateInhouseClientStep,
} from "redux/actions/icrAction";

const FormOne = () => {
  const { data } = useSelector((state) => state.icrReducer);
  const [notValid, setNotValid] = useState(false);
  const {
    title,
    FirstName,
    LastName,
    Birthday,
    email,
    mobile_no,
    direct_line,
    AddressLine1,
    AddressLine2,
    City,
    PostalCode,
    Region,
    Country,
    Nationality,
    other_nationality,
    passNumber,
    confirm_passcode,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateInhouseClientStep(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeInhouseClientInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeInhouseClientInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeInhouseClientInput(inputData));
  };

  const handleMobile = (value) => {
    const inputData = {
      name: "mobile_no",
      value: value,
    };
    dispatch(changeInhouseClientInput(inputData));
  };

  const handleAutoCompleteON = (e, value) => {
    dispatch(
      changeInhouseClientInput({
        name: "other_nationality",
        value,
      })
    );
  };

  const handleDateChange = (date) => {
    dispatch(
      changeInhouseClientInput({
        name: "Birthday",
        value: date,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeInhouseClientInput({
        name: "Country",
        value,
      })
    );
  };

  const handleChangeN = (e, value) => {
    dispatch(
      changeInhouseClientInput({
        name: "Nationality",
        value,
      })
    );
  };

  const isAllow =
    !title ||
    !FirstName ||
    !LastName ||
    !email ||
    !mobile_no ||
    !AddressLine1 ||
    !City ||
    !PostalCode ||
    !Country ||
    !passNumber ||
    !confirm_passcode ||
    notValid ||
    passNumber !== confirm_passcode;

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        value={title}
        name="title"
        label="Title"
        width="100%"
        required
      />
      <CustomInputSingle
        onChange={handleChange}
        value={FirstName}
        name="FirstName"
        label="First Name(s)"
        width="100%"
        required
      />
      <CustomInputSingle
        onChange={handleChange}
        value={LastName}
        name="LastName"
        label="Last Name"
        width="100%"
        required
      />
      <CustomDate
        onChange={handleDateChange}
        value={Birthday}
        name="Birthday"
        label="Date of Birth"
        width="100%"
        maxDate={new Date("1-1-2000")}
      />
      <CustomInputSingle
        onChange={handleChange}
        value={AddressLine1}
        name="AddressLine1"
        label="House number/name, Street name"
        width="100%"
        required
      />
      <CustomInputSingle
        onChange={handleChange}
        value={AddressLine2}
        name="AddressLine2"
        label="Address line 2"
        width="100%"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={City}
        name="City"
        label="Town / City"
        width="100%"
        required
      />
      <CustomInputSingle
        onChange={handleChange}
        label="County / Region"
        // required
        width="100%"
        name="Region"
        value={Region}
      />
      <CustomInputSingle
        onChange={handleChange}
        value={PostalCode}
        name="PostalCode"
        label="Post Code / Zip Code / Post office"
        width="100%"
        required
      />
      <CustomAutoSelect
        onChange={handleAutoCompleteCO}
        label="Country"
        moreInfo="Current Country of residence"
        width="100%"
        value={Country}
        data={CURRENT_COUNTRY}
        required
      />
      <CustomAutoSelect
        onChange={handleChangeN}
        label="Current nationality"
        width="100%"
        name="Nationality"
        value={Nationality}
        data={CURRENT_COUNTRY}
      />

      <CustomInputSingle
        onChange={handleEmailChange}
        value={email}
        name="email"
        label="Primary email address"
        width="100%"
        required
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomPassword
        onChange={handlePasswordChange}
        label="Passcode / Memorable word"
        width="100%"
        name="passNumber"
        value={passNumber}
        required
      />
      <CustomPassword
        onChange={handleChange}
        label="Confirm Passcode / Memorable word"
        width="100%"
        name="confirm_passcode"
        value={confirm_passcode}
        validation={passNumber !== confirm_passcode}
        required
      />

      <CustomPhone
        onChange={(e) => handleMobile(e)}
        value={mobile_no}
        name="mobile_no"
        label="Mobile number *"
        width="100%"
        style={{ marginTop: "2rem" }}
        required
      />
      <CustomInputSingle
        onChange={handleChange}
        value={direct_line}
        name="direct_line"
        label="Direct line with extension no"
        width="100%"
      />

      <div className={classes.buttonFlex}>
        <CustomButton text="Next" onClick={handleNext} disabled={isAllow} />
      </div>
    </form>
  );
};

export default FormOne;
