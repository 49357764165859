import { makeStyles, useTheme } from '@material-ui/core/styles'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStylesSelect = makeStyles((theme) => ({
    formControl: {
        margin: '16px auto 0px !important',
        display: 'block !important',
        width: window.matchMedia("(max-width: 1000px)").matches? '90%': '55%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

const useStylesInput = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '45ch',
    },
  },
}));

const useStylesBtnNxt= makeStyles(theme => ({
  root: {
    backgroundColor: '#ab47bc !Important',
    color: 'white !important',
    marginTop: '16px !important',
    width: '6.25rem !important',
    textTransform: 'none !important',
    outline: 'none !important'
  }
}))

const useStylesBtnPrev= makeStyles(theme => ({
  root: {
    marginTop: '16px !important',
    marginRight: '10px',
    width: '6.25rem !important',
    textTransform: 'none !important',
    outline: 'none !important',
  }
}))

const useStylesRadio = makeStyles((theme) => ({
  formControl: {
      margin: '16px auto 0px !important',
      display: 'block !important',
      width: '83% !important',
  },
}));

const useStylesDate = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

const useStylesUploadBtn= makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

const useStylesProfileImage = makeStyles((theme) => ({
  paper: {
      position: 'absolute',
      width: 350,
      backgroundColor: 'white',
      borderRadius: '12px',
      outline: 'none',
      lineHeight: '1.5',
  },
}));

const useStylesEdit= makeStyles((theme) => ({
  paper: {
      position: 'absolute',
      width: window.matchMedia("(max-width: 700px)").matches? 340: 600,
      backgroundColor: 'white',
      borderRadius: '12px',
      outline: 'none',
      lineHeight: '1.5',
      padding: '20px',
      fontFamily: 'Roboto'
  },
}));

export const getModalStyle = () => {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = {
  root: {
      position: 'absolute !important',
      bottom: '-50px',
      left: '30px',
      cursor: 'pointer',
      width: window.matchMedia("(max-width: 700px)").matches? '100 !important': '200px !important',
      height: window.matchMedia("(max-width: 700px)").matches? '100 !important': '200px !important',
      margin: 'auto'
  }
}

const useStylesAvatar = makeStyles(styles)

export { useStylesSelect, MenuProps, getStyles, useTheme, useStylesInput, useStylesBtnNxt, useStylesBtnPrev, useStylesRadio, useStylesDate, useStylesUploadBtn, useStylesProfileImage, useStylesEdit, useStylesAvatar }