import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// Custom Components
import GridItem from "components/Grid/GridItem";
import LinguistLayout from "layout/LinguistLayout";
import CardHeader from "components/Card/CardHeader";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// Others
import { Button, Card, IconButton } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import Axios from "axios";
import Cookies from "js-cookie";
import history from "utils/history";

const ViewCACTranslationJob = () => {
  const classes = useDashboardStyles();
  const { id } = useParams();
  const token = Cookies.get("LINtoken");
  const [values, setValues] = useState(null);

  useEffect(() => {
    const loadTransJob = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/cashclient-Translation-view/TranslationID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setValues(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    loadTransJob();
  }, []);

  return values ? (
    <LinguistLayout>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <CardHeader
          color="primary"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className={classes.cardTitleWhite}>
            View Cash Client Order Translation Details -- {values.id}
          </p>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIcon color="white" />
          </IconButton>
        </CardHeader>
      </GridItem>

      <div raised style={{ marginLeft: "15%", marginRight: "15%" }}>
        <Card raised style={{ marginTop: "24px" }}>
          <CardBody>
            <h3 style={{ textAlign: "center" }}>Order Translation</h3>
          </CardBody>
        </Card>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
          <CardHeader
            color="primary"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginTop: 10,
              top: 35,
            }}
          >
            <p className={classes.cardTitleWhite}>Client Information</p>
          </CardHeader>
        </GridItem>
        <Card raised style={{ marginTop: "24px", padding: "30px", zIndex: 0 }}>
          <CardBody>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 10,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Assignment Reference Number: {values.id}
            </p>
          </CardBody>
        </Card>

        <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
          <CardHeader
            color="primary"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginTop: 10,
              top: 35,
            }}
          >
            <p className={classes.cardTitleWhite}>Translation Details</p>
          </CardHeader>
        </GridItem>
        <Card raised style={{ marginTop: "24px", padding: "30px", zIndex: 0 }}>
          <CardBody>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 10,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Booking reference number or purchase order number:{" "}
              {values.purchase_order_no}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Document type: {values.document_type}{" "}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Source Language: {values.source_language}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Source Language Level: {values.source_expertise_level_required}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Target Language: {values.target_language}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Target Language Level: {values.target_expertise_level_required}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Do you require visual formatting?:{" "}
              {values.require_visual_formating}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Do you require this translation to be certified:{" "}
              {values.certified_translation}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Would you certified to be posted or electronically? :{" "}
              {values.certificate}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Would you require additional quality check? :{" "}
              {values.additional_quality_check}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Word Count: {values.wordCount}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Preffered Linguist / Service provider ID number:{" "}
              {values.provider_id_number}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Additional note for translator: {values.additional_note}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Security clearance:{" "}
              {values.security_clearance !== "undefined"
                ? values.security_clearance
                : "N/A"}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Timezone: {values.timezone}
            </p>
            <p
              style={{
                fontWeight: "bold",
                marginTop: 30,
                fontSize: 15,
                width: 100 + "%",
              }}
            >
              Translation needed by:{" "}
              {moment(values.deadline).format("DD/MM/YYYY HH:mm")}
            </p>
          </CardBody>
        </Card>

        <GridItem xs={12} sm={12} md={12} lg={12} style={{ zIndex: 1 }}>
          <Button
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginTop: 10,
              top: 35,
              background: "linear-gradient( 60deg , #ab47bc, #8e24aa)",
              width: 100 + "%",
              padding: (10, 15),
            }}
            onClick={() =>
              history.push(`/linguist/view-translation-document/${values._id}`)
            }
          >
            View Translation Document
          </Button>
        </GridItem>
      </div>
    </LinguistLayout>
  ) : null;
};

export default ViewCACTranslationJob;
