import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import history from "utils/history";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Report from "components/Admin/ManagementPanel/Report/Report";
import Options from "components/Admin/ManagementPanel/Report/options";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const [management_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAdminAccess(response?.data.management_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {management_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  color="primary"
                >
                  <p className={classes.cardTitleWhite}>Reports (PRC)</p>
                  <div style={{ display: "flex" }}>
                    <Options />
                    <IconButton
                      color="inherit"
                      onClick={() => history.goBack()}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                </CardHeader>

                <CardBody>
                  <Report />
                </CardBody>
              </Card>
            </GridItem>
          ) : management_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
