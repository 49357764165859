import moment from "moment";

// Custom Components

import { Table } from "semantic-ui-react";

// Others

const ViewAll = ({ data }) => {
  return (
    <div>
      <div style={{ width: "100%", height: "500px", overflowX: "scroll" }}>
        <Table color={"pink"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="1">Assignment ID</Table.HeaderCell>
              <Table.HeaderCell width="1">Timezone</Table.HeaderCell>
              <Table.HeaderCell width="1">Start date & time</Table.HeaderCell>
              <Table.HeaderCell width="1">Source language</Table.HeaderCell>
              <Table.HeaderCell width="1">Target language</Table.HeaderCell>
              <Table.HeaderCell width="1">Service Required</Table.HeaderCell>
              <Table.HeaderCell width="1">Security Clearance</Table.HeaderCell>
              <Table.HeaderCell width="1">Venue/Remote</Table.HeaderCell>
              <Table.HeaderCell width="1">Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data?.startdateArray
              ?.filter((dt) => dt != "")
              .map((dt, i) => (
                <Table.Row key={data._id}>
                  <Table.Cell>
                    {/* <Link
                    to={`/cash-client/view-cash-client-booking-interpreting/${data._id}`}
                  > */}
                    {data.id}
                    {/* </Link> */}
                  </Table.Cell>
                  <Table.Cell>{data.timezone}</Table.Cell>
                  <Table.Cell>
                    {moment(data.startdateArray[i]).format("DD-MMM-YYYY")}
                    <br /> {data.starttimeArray[i]}
                  </Table.Cell>
                  <Table.Cell>{data.source_language}</Table.Cell>
                  <Table.Cell>{data.target_language}</Table.Cell>
                  <Table.Cell>{data.interpreting_service_required}</Table.Cell>
                  <Table.Cell>
                    {data.security_clearance ? data.security_clearance : "N/A"}
                  </Table.Cell>
                  <Table.Cell>
                    {data.country ? data.country : data.remote_address}
                  </Table.Cell>
                  <Table.Cell>{data.status}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default ViewAll;
