import axios from "axios";

const {
  GET_CURRENCY_RATES,
  SELECTED_CURRENCY,
  SET_CURRENCY,
} = require("./actionTypes");

export const currencyValue = () => async (dispatch) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_API}/currency-exchange`
  );
  const updatedRates = {};
  for (const rate in response?.data?.rates) {
    if (rate !== "GBP") {
      const convertedRate =
        response?.data?.rates[rate] * 0.025 + response?.data?.rates[rate];
      updatedRates[rate] = convertedRate;
    } else {
      updatedRates[rate] = response?.data?.rates[rate];
    }
  }

  dispatch({
    type: GET_CURRENCY_RATES,
    payload: updatedRates,
  });
};

export const setSelectedCurrency = (type) => ({
  type: SELECTED_CURRENCY,
  payload: type,
});

export const setCurrency = (type) => ({
  type: SET_CURRENCY,
  payload: type,
});
