import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Options from 'components/Employee/Dashboard/JobLists/Options/QuotationOptions'
import TableThree from 'components/Employee/Dashboard/JobLists/quotation/CAC/List'


const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>Cash Client Quotations</p>
                <IconButton color="inherit" onClick={() => history.goBack()}>
                  <ArrowBackIcon></ArrowBackIcon>
                </IconButton>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Options />
              </CardHeader>
              <CardBody>
                <TableThree />
              </CardBody>
            </Card>

          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default UnconfirmedAssignmentsPage;
