import React, { useEffect, useState } from "react";
// Custom Components
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Axios from "axios";
import Cookies from 'js-cookie'
// Fourth Segment
import SppoShop from "components/Sppo/SppoDashboard/SppoShop";
import PromotionalMessage from "components/Sppo/SppoDashboard/PromotionalMessage";
import NewsAndNotice from "components/Sppo/SppoDashboard/NewsAndNotice";
import TrainingsAndCpd from "components/Sppo/SppoDashboard/Trainings&Cpd";
import Notifications from "components/Sppo/SppoDashboard/Notifications";
import WelcomeMessage from "components/Sppo/SppoDashboard/WelcomeMessage";
import ViewJobAdverts from "components/Sppo/SppoDashboard/ViewJobAdverts";
import MyNote from "components/Sppo/MyNote/MyNoteForm";
import EventsCard from "components/Admin/ManagementPanel/EventsForm/SPPOEventsCard";
import NoteFromcolleagues from "components/Sppo/MyNote/ColleagueForm"
import { Button } from "@material-ui/core";
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";



const SppoDashboard = () => {
  const matches = useMediaQuery("(max-width:425px)");
  const classes = useLandingpageStyles();
  const token = Cookies.get('SPPOtoken')
  const [user, setUser] = useState('')

  useEffect(() => {
    const source = Axios.CancelToken.source()
    const loadDashboard = async (e) => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/sppo/dashboard`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          cancelToken: source.token
        })
        setUser(response?.data)
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log('Request cancelled')
        } else {
          throw error
        }
      }
    }

    loadDashboard();

    return () => {
      source.cancel();
    }
  }, [])



  return (
    <>
      {matches ? (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto",
            }}
          >
            <Notifications />
            <WelcomeMessage />
            <TrainingsAndCpd />
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto",
            }}
          >
            <NoteFromcolleagues />
            <NewsAndNotice />
            <EventsCard />
            <MyNote />
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto",
            }}
          >
            <SppoShop />
            <PromotionalMessage />
            <ViewJobAdverts />
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto",
            }}>
            <Button
              className={`${classes.button}`}
              color="primary"
              variant="contained"
              onClick={() => window.open(`/partner/${user?.id}`)}
            >
              SPPO Public Profile ( Sharing Link: linguistscollective.com/partner/{user?.id} )
            </Button>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "33% 33% 33%",
            }}
          >
            <Notifications />
            <WelcomeMessage />
            <TrainingsAndCpd />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "100%",
            }}
          >
            <NewsAndNotice />

          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "33% 33% 33%",
            }}
          >
            <NoteFromcolleagues />
            <EventsCard />
            <MyNote />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "33% 33% 33%",
            }}
          >
            <SppoShop />
            <PromotionalMessage />
            <ViewJobAdverts />
          </div>
          {user?.only_number_id ?
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "100%",
              }}>
              <Button
                className={`${classes.button}`}
                color="primary"
                variant="contained"
                onClick={() => window.open(`/partner/${user?.id}`)}
              >
                SPPO Public Profile ( Sharing Link: linguistscollective.com/partner/{user?.id} )
              </Button>
            </div>
            :
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "100%",
              }}>
              <Button
                className={`${classes.button}`}
                color="primary"
                variant="contained"
              >
                Loading...
              </Button>
            </div>
          }
        </>
      )}
    </>
  );
};

export default SppoDashboard;
