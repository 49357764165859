import CustomTextInput from "components/Common/CustomTextInput";
import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "semantic-ui-react";
import { Checkbox } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";

import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import RichTextEditor from "react-rte";
import moment from "moment";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeTimesheetInput } from "redux/actions/timesheetAction";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const TimesheetFooter = ({ details }) => {
  let sigPad = useRef({});
  const token = Cookies.get("BACtoken");
  const { id } = useParams();
  const [signatureModal, setSignatureModal] = useState(false);
  const { data } = useSelector((state) => state.timesheetReducer);
  const dispatch = useDispatch();
  const [text, setText] = useState(RichTextEditor.createEmptyValue());
  const [linSignature, setLinSignature] = useState("");
  const { FirstName, LastName } = JSON.parse(localStorage.getItem("BACuser"));

  const { signature } = data;

  const getLinguistSignature = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/view-lin-signature/timesheetID/${details[0]._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLinSignature(response?.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  useEffect(() => {
    getLinguistSignature();
  });

  const handleClose = (e) => {
    e.preventDefault();
    setSignatureModal(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = () => {
    const temp = sigPad.current.toDataURL();
    dispatch(
      changeTimesheetInput({
        name: "signature",
        value: temp,
      })
    );
    setSignatureModal(false);
  };

  const handleDeclaration = (e, value) => {
    dispatch(
      changeTimesheetInput({
        name: "declaration",
        value: value.checked,
      })
    );
  };

  return (
    <div>
      <CustomModal
        open={signatureModal}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          //onEnd = {handleSave}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            color="purple"
          >
            Clear
          </Button>
          <Button onClick={handleSave} color="grey">
            Save
          </Button>
        </div>
      </CustomModal>

      {/* Table */}

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="2">
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
            <Table.HeaderCell width="2">
              To be completed by client
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Checkbox
                label="I declare that I have checked the information on this form and certify that it is true and complete"
                checked={true}
              />
            </Table.Cell>
            <Table.Cell>
              <Checkbox
                label="I declare the information given by me is true/accurate and comp"
                checked={data.declaration}
                onChange={handleDeclaration}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ display: "flex", flexDirection: "column" }}>
              <div>Signature</div>
              <img style={{ width: "130px" }} src={linSignature} alt="" />
            </Table.Cell>
            <Table.Cell>
              <div>
                Signature{" "}
                <IconButton onClick={() => setSignatureModal(true)}>
                  <EditIcon />
                </IconButton>
              </div>
              {signature && (
                <img style={{ width: "130px" }} src={signature} alt="" />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <CustomTextInput
                label="Full name"
                value={
                  details[0].postedBy?.FirstName
                    ? details[0].postedBy?.FirstName
                    : " " + " " + details[0].postedBy?.LastName
                    ? details[0].postedBy?.LastName
                    : " "
                }
              />
            </Table.Cell>
            <Table.Cell>
              <CustomTextInput
                label="Full name"
                value={FirstName + " " + LastName}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {moment().format("DD-MM-YYYY")}</Table.Cell>
            <Table.Cell>Date: {moment().format("DD-MM-YYYY")}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default TimesheetFooter;
