import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomAvatar from "components/Common/CustomAvatar";

//Material icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import LinguistLayout from "layout/LinguistLayout";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

// import Details from './Details'
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Statistic,
  Table,
} from "semantic-ui-react";
import { DISCOUNT, TAXIFARE, TYPE_INTERPRETING } from "data";
import { IconButton, Input, TextField } from "@material-ui/core";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import swal from "sweetalert";
import {
  addOtherTransaction,
  addTransaction,
  deleteOtherTransaction,
  deleteTransaction,
} from "redux/actions/expenseAction";

const ViewSavedPRCQuote = () => {
  const classes = useEmployeeStyles();
  const token = Cookies.get("LINtoken");
  const [invoice, setInvoice] = useState(null);
  const [linguist, setLinguist] = useState(null);
  const [text, setText] = useState("");
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [calculate, setCalculate] = useState({
    sendButton: false,
    loading: false,
    quoteId: "",
  });

  const [companyCard, setCompanyCard] = useState({
    basc: "",
    pbsc: "",
    cname: "",
    caddress: "",
  });

  //   const totalFTF = invoice ? Number(invoice) * Number(invoice?.duration) : null

  //   2nd subtotal
  const [otherAmount, setOtherAmount] = useState(0);
  const [otherText, setOtherText] = useState("");
  const [otherQuantity, setOtherQuantity] = useState(0);

  const { transactions } = useSelector((state) => state.expenseReducer);
  const amountsOne = transactions.map((transaction) => transaction.total);
  const subTotalOne =
    invoice &&
    (
      Number(amountsOne.reduce((acc, item) => (acc += item), 0)) +
      invoice.subtotal1
    ).toFixed(2);

  const { otherTransactions } = useSelector((state) => state.expenseReducer);
  const amountsTwo = otherTransactions.map((transaction) => transaction.total);
  const subTotalTwo =
    invoice &&
    (
      Number(amountsTwo.reduce((acc, item) => (acc += item), 0)) +
      invoice.subtotal2
    ).toFixed(2);

  const total = (Number(subTotalOne) + Number(subTotalTwo)).toFixed(2);

  const [discount, setDiscount] = useState(0);
  const [vat, setVAT] = useState(0);

  //Total after discount
  const totalDiscount = ((Number(total) * discount) / 100).toFixed(2);

  //Including platform and booking service charge
  const totalPBSC = ((Number(total) * companyCard?.pbsc) / 100).toFixed(2);

  //Total VAT
  const totalVAT = (
    Number(Number(total) + Number(totalPBSC) - Number(totalDiscount)) *
    (vat / 100)
  ).toFixed(2);

  //Total after including vat and discount
  const totalTwo = (
    Number(total) +
    Number(totalPBSC) -
    Number(totalDiscount) +
    Number(totalVAT)
  ).toFixed(2);

  //Total after subtracting estimate paid
  const grandTotal =
    invoice && (Number(totalTwo) - invoice.grand_total).toFixed(2);
  //To be paid including vat

  const paidBy = moment().add(14, "days").format("DD/MMM/YYYY");

  const [lingRef, setLingRef] = useState(null);

  const handleAdd = (e) => {
    e.preventDefault();

    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      text,
      amount: +amount,
      quantity,
      total: amount * quantity,
    };

    dispatch(addTransaction(newTransaction));
    setText("");
    setAmount("");
    setQuantity("");
  };

  const handleAddOther = (e) => {
    e.preventDefault();
    const newTransaction = {
      id: Math.floor(Math.random() * 100000000),
      otherText,
      otherAmount: +otherAmount,
      otherQuantity,
      total: otherAmount * otherQuantity,
    };

    dispatch(addOtherTransaction(newTransaction));
    setOtherText("");
    setOtherAmount("");
    setOtherQuantity("");
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const loadInvoice = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/private-quote-view/quoteID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setInvoice(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancelled");
        } else {
          throw error;
        }
      }
    };

    const getLinguistProfile = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLinguist(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getCompanyCard = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/manager-admin/view/company-information-card`
        );
        setCompanyCard({
          ...companyCard,
          pbsc: response?.data[0].platform_booking_service_charge,
          basc: response?.data[0].billing_account_service_charge,
          cname: response?.data[0].company_name,
          caddress: response?.data[0].company_address,
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadInvoice();
    getLinguistProfile();
    getCompanyCard();

    return () => {
      source.cancel();
    };
  }, []);

  const handleSend = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/send/private-client-quote/quoteID/${calculate.quoteId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Quote successfully send to client",
        icon: "success",
        button: "Ok",
      }).then(function () {
        window.location = "/linguist/dashboard";
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalculate = async (e) => {
    setCalculate({ ...calculate, loading: true });
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/amend/private-client-quote/quoteID/${id}`,
        {
          date_of_quote: "",
          date_of_invoice: moment().format("DD/MM/YYYY"),
          service_completion_date: moment(invoice.end_date).format(
            "DD/MM/YYYY"
          ),
          linguist_ref: lingRef,
          currency: "GBP",
          transactions: transactions.concat(invoice.productOne),
          otherTransactions: otherTransactions.concat(invoice.productTwo),
          subtotal1: subTotalOne,
          subtotal2: subTotalTwo,
          service_charge: companyCard?.pbsc,
          service_amount: totalPBSC,
          total: totalTwo,
          discount_percentage: discount,
          discount_amount: totalDiscount,
          vat_percentage: vat,
          vat_amount: totalVAT,
          grand_total: grandTotal,
          estimate_paid_amount: invoice.grand_total,
          due: "",
          linguist_note: "",
          admin_note: "",
          client_note: "",
          rate: invoice,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        text: "Quote successfully saved",
        icon: "success",
        button: "Ok",
      });
      setCalculate({
        ...calculate,
        loading: false,
        sendButton: true,
        quoteId: response?.data._id,
      });
      console.log(response);
    } catch (error) {
      console.log(error.response);
      setCalculate({ ...calculate, loading: false, sendButton: false });
    }
  };

  return invoice && linguist ? (
    <LinguistLayout>
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem id="printArea" xs={12} sm={12} md={12} lg={8} xl={8}>
            <Card>
              <CardBody>
                {/* Avatar and company name */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "24px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <CustomAvatar style={{ margin: "0" }} />
                  </div>
                </div>

                {/* First div  */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "24px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Quote to:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{invoice.privateClientID.id}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Name of the company:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;Linguist Collective
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Name of the person:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;Pratik
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        House number/ name, Street name:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{invoice.privateClientID.AddressLine1}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Address Line Two:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{invoice.privateClientID.AddressLine2}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Region:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{invoice.privateClientID.Region}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        City:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{invoice.privateClientID.City}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Postal Code:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{invoice.privateClientID.PostalCode}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Country:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{invoice.privateClientID.Country}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Email:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{invoice.privateClientID.email}
                      </Header>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        ViewSavedPRCQuote number:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{invoice.id}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Linguist/SP ID number:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{linguist.id}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Linguist Residence:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;Navi Mumbai
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        VAT/GST no:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;1234
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Date of quote:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;N/A
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Date of invoice:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{moment().format("DD/MM/YYYY")}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Invoice to be paid by:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{paidBy}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Job reference:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{invoice.id}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Service completion date:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;{moment(invoice.end_date).format("DD/MM/YYYY")}
                      </Header>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Header style={{ margin: "0" }} as="h4">
                        Linguist/SP ref:
                      </Header>
                      <Header color="grey" style={{ margin: "0" }} as="h4">
                        &nbsp;
                        <Input
                          onChange={(e) => setLingRef(e.target.value)}
                          value={lingRef}
                          type="number"
                        />
                      </Header>
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Header style={{ margin: "0" }} as="h4">
                      Service:
                    </Header>
                    <Header color="grey" style={{ margin: "0" }} as="h4">
                      &nbsp;{invoice.service}
                    </Header>
                  </div>
                </div>

                <Table color="pink">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width="5">
                        Description of service
                      </Table.HeaderCell>
                      <Table.HeaderCell width="1">Price</Table.HeaderCell>
                      <Table.HeaderCell width="1">Quantity</Table.HeaderCell>
                      <Table.HeaderCell width="1">Total</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {invoice.productOne.map((tr) => (
                      <Table.Row>
                        <Table.Cell>
                          <Header as="h4">{tr.text}</Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h4">{tr.amount}</Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h4">{tr.quantity}</Header>
                        </Table.Cell>
                        <Table.Cell
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Header style={{ margin: "0" }} as="h4">
                            {tr.total}
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                    {transactions.map((tr) => (
                      <Table.Row>
                        <Table.Cell>
                          <Header as="h4">{tr.text}</Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h4">{tr.amount}</Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h4">{tr.quantity}</Header>
                        </Table.Cell>
                        <Table.Cell
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Header style={{ margin: "0" }} as="h4">
                            {tr.total}
                          </Header>
                          <IconButton
                            onClick={() => dispatch(deleteTransaction(tr.id))}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                    <Table.Row>
                      <Table.Cell>
                        <CustomAutoSelect
                          onChange={(e, value) => setText(value)}
                          value={text}
                          label="Choose"
                          data={TYPE_INTERPRETING}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          onChange={(e) => setAmount(e.target.value)}
                          value={amount}
                          placeholder="£ 00.00"
                          style={{ margin: "24px auto 0" }}
                          type="number"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          onChange={(e) => setQuantity(e.target.value)}
                          value={quantity}
                          style={{ margin: "24px auto 0" }}
                          type="number"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <IconButton onClick={handleAdd}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header textAlign="right" as="h3">
                          Sub Total
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size="mini">
                          <Statistic.Value>
                            £ {Number(subTotalOne)}
                          </Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Expenses and travel</Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                    {invoice.productTwo.map((tr) => (
                      <Table.Row>
                        <Table.Cell>
                          <Header as="h4">{tr.otherText}</Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h4">{tr.otherAmount}</Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h4">{tr.otherQuantity}</Header>
                        </Table.Cell>
                        <Table.Cell
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Header style={{ margin: "0" }} as="h4">
                            {tr.total}
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                    {otherTransactions.map((tr) => (
                      <Table.Row>
                        <Table.Cell>
                          <Header as="h4">{tr.otherText}</Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h4">{tr.otherAmount}</Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h4">{tr.otherQuantity}</Header>
                        </Table.Cell>
                        <Table.Cell
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Header style={{ margin: "0" }} as="h4">
                            {tr.total}
                          </Header>
                          <IconButton
                            onClick={() =>
                              dispatch(deleteOtherTransaction(tr.id))
                            }
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                    <Table.Row>
                      <Table.Cell>
                        <CustomAutoSelect
                          onChange={(e, value) => setOtherText(value)}
                          value={otherText}
                          label="Taxi fare"
                          data={TAXIFARE}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          onChange={(e) => setOtherAmount(e.target.value)}
                          value={otherAmount}
                          placeholder="£ 00.00"
                          style={{ margin: "24px auto 0" }}
                          type="number"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          onChange={(e) => setOtherQuantity(e.target.value)}
                          value={otherQuantity}
                          style={{ margin: "24px auto 0" }}
                          type="number"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <IconButton onClick={handleAddOther}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header as="h3">Sub Total</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size="mini">
                          <Statistic.Value>£ {subTotalTwo}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header as="h4">Total</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size="mini">
                          <Statistic.Value>£ {total}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header as="h4">Discount %</Header>
                        <CustomAutoSelect
                          data={DISCOUNT}
                          onChange={(e, value) => setDiscount(value)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size="mini">
                          <Statistic.Value>{totalDiscount}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header as="h4">
                          Billing Account Service Charge {companyCard?.basc} %
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size="mini">
                          <Statistic.Value>00.00</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header as="h4">
                          Platform and booking service charge{" "}
                          {companyCard?.pbsc} %
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size="mini">
                          <Statistic.Value>{totalPBSC}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header as="h4">VAT %</Header>
                        <TextField
                          onChange={(e) => setVAT(e.target.value)}
                          type="number"
                          inputProps={{ min: "0", max: "10", step: "1" }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size="mini">
                          <Statistic.Value>{totalVAT}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header as="h4">Total</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size="mini">
                          <Statistic.Value>{totalTwo}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header as="h4">Estimate paid in advance</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size="mini">
                          <Statistic.Value>
                            {invoice.grand_total}
                          </Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell textAlign>
                        <Header as="h4">
                          Total to be paid including VAT/GST
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Statistic size="mini">
                          <Statistic.Value>£ {grandTotal}</Statistic.Value>
                        </Statistic>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Header style={{ marginTop: "16px" }} as="h4">
                    Thank You For Your Business
                  </Header>
                  <Header
                    color="grey"
                    style={{ marginBottom: "16px", marginTop: "0" }}
                    as="h4"
                  >
                    {companyCard.cname + ", " + companyCard.caddress}
                  </Header>
                  {!calculate.sendButton && (
                    <Button
                      onClick={handleCalculate}
                      loading={calculate.loading}
                      color="purple"
                    >
                      Calculate and Save
                    </Button>
                  )}
                  {calculate.sendButton && (
                    <Button onClick={handleSend} color="purple">
                      Send
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </LinguistLayout>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default ViewSavedPRCQuote;
