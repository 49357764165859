import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SendIcon from "@material-ui/icons/Send";
import { useMediaQuery } from "@material-ui/core";
import { Fragment } from "react";

const ULOptions = () => {
  const history = useHistory();
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <div>
      {!matches ? (
        <Fragment>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{ width: "23%", marginLeft: "15px", marginRight: "15px" }}
            onClick={() => history.push("/admin/add-preregistration-linguists")}
          >
            Add Unregistered Linguist
          </Button>
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "23%", marginRight: "15px" }}
            onClick={() => history.push("/admin/list-unregistered-linguists")}
          >
            List of Unregistered Linguist
          </Button>
          <Button
            startIcon={<SendIcon />}
            color="primary"
            variant="contained"
            style={{ width: "23%", marginRight: "15px" }}
            onClick={() => history.push("/admin/send-form-registration")}
          >
            Send reg form (Autofill / Manual)
          </Button>
          <Button
            startIcon={<SendIcon />}
            color="primary"
            variant="contained"
            style={{ width: "23%" }}
            onClick={() => history.push("/admin/send-form-registration-select")}
          >
            Send reg form (Dropdown)
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/admin/add-preregistration-linguists")}
          >
            Add Unregistered Linguist
          </Button>
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/admin/list-unregistered-linguists")}
          >
            List of Unregistered Linguist
          </Button>
          <Button
            startIcon={<SendIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/admin/send-form-registration")}
          >
            Send form for reg (Auto field)
          </Button>
          <Button
            startIcon={<SendIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/admin/send-form-registration-select")}
          >
            Send form for reg (Dropdown)
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default ULOptions;
