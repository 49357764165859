import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Action from "components/Admin/BookingPanel/UnconfirmedAssignments/BAC/ListBACLinQuote/Actions/Action";
import { Button, Header, Icon, Loader, Modal, Table } from "semantic-ui-react";
import CustomTextInput from "components/Common/CustomTextInput";
import Axios from "axios";
import Cookies from "js-cookie";
import CustomModal from "components/Common/CustomModal";
import swal from "sweetalert";
import Moment from "moment";
import SearchCACAssignments from "components/Admin/BookingPanel/UnconfirmedAssignments/BAC/SearchQuote/SearchQuote";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function ListCACAssignments({ quotationData, text }) {
  const token = Cookies.get("token");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paidModal, setPaidModal] = React.useState(false);
  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  const [search, setSearch] = useState("");
  const { addToast } = useToasts();
  const [show, setShow] = useState(false);
  let sigPad = useRef({});
  const AdminID = Cookies.get("AdminID");
  const [pay, setPay] = useState({
    payment_date: "",
    payment_ref: "",
    estimate_paid_amount: "",
    note: "",
    signature: "",
    booking_id: "",
    assignmentId: "",
    grand_total: "",
    currency: "",
  });

  const handleClose = (e) => {
    setSignature(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    UnconfirmBookingList: null,
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });

  const {
    name,
    error,
    success,
    UnconfirmBookingList,
    removed,
    reload,
    loader,
  } = values;

  useEffect(() => {
    quotationData && loadUnconfirmBookingList();
  }, [reload]);

  const loadUnconfirmBookingList = async (e) => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/unconfirmed-lin-quote-list-BAC`
      ),
    ]).then(
      Axios.spread((dashboard, unconfirmedJobs) => {
        setValues({
          ...values,
          UnconfirmBookingList: quotationData
            ? unconfirmedJobs.data.filter(
                (item) => item?.id === quotationData
              )
            : unconfirmedJobs.data,
          AdminID: dashboard.data.id,
        });
        quotationData && setShow(true);
      })
    );
  };

  const handleSearch = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/find-unconfirmed-lin-quote-BAC/quoteID`,
        { id: search },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data) {
        setValues({ ...values, UnconfirmBookingList: [response?.data] });
        addToast("quote found", { appearance: "success" });
        setShow(true);
      } else {
        addToast("quote not found", { appearance: "error" });
      }
    } catch (error) {
      if (error.response) {
        addToast("Empty field, please write correct quote id.", {
          appearance: "error",
        });
      }
    }
  };

  const handlePaid = async (id, grand_total, assignmentId, currency) => {
    setPaidModal(true);
    setPay({
      ...pay,
      booking_id: id,
      grand_total: grand_total,
      assignmentId: assignmentId,
      currency: currency,
    });
  };

  const handleConfirm = async (e) => {
    setValues({ ...values, loader: true });
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/update-payment-status-paid/quoteID/${pay.booking_id}`,
        {
          payment_date: pay.date,
          payment_ref: pay.ref,
          estimate_paid_amount: pay.estimate_paid_amount,
          note: pay.note,
          signature: sign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValues({ ...values, loader: false });
      swal({
        icon: "success",
        text: "Estimated total Paid",
        buttons: false,
      });
      loadUnconfirmBookingList();
      setPaidModal(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
  };

  return (
    <>
      {/* Payment modal */}
      <Modal
        open={paidModal}
        onClose={() => setPaidModal(false)}
        //onOpen={() => setOpen(true)}
        //trigger={<Button>Scrolling Content Modal</Button>}
      >
        <Modal.Header>
          Payment Details - {pay.assignmentId}, {pay.currency},{" "}
          {pay.grand_total}
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <CustomTextInput
              name="payment_date"
              onChange={handleChange}
              dvalue={pay.payment_date}
              label="Payment date"
              dlabel="Payment date "
              date={true}
            />
            <CustomTextInput
              name="payment_ref"
              onChange={handleChange}
              value={pay.payment_ref}
              label="Payment reference "
              edit={true}
            />
            <CustomTextInput
              name="estimate_paid_amount"
              onChange={handleChange}
              value={pay.estimate_paid_amount}
              label="Total estimate paid"
              placeholder={pay.grand_total}
              edit={true}
            />
            <CustomTextInput
              name="note"
              onChange={handleChange}
              value={pay.note}
              label="Note"
              edit={true}
            />
            <CustomTextInput label="Admin Id" value={values.AdminID} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Header style={{ margin: "0" }} as="h4">
                Signature:
              </Header>
              {sign && <img style={{ width: "300px" }} src={sign} alt="" />}
            </div>
            <Button onClick={() => setSignature(true)} color="purple">
              Add Signature
            </Button>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              !pay.payment_ref ||
              !sign ||
              !pay.payment_date ||
              !pay.estimate_paid_amount ||
              values.loader
            }
            onClick={handleConfirm}
            color="purple"
          >
            Confirm <Icon name="chevron right" />
          </Button>
          <Loader active inline />
        </Modal.Actions>
      </Modal>

      {/* Signature modal */}
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          onEnd={{ handleSave }}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </CustomModal>
      {!quotationData && (
        <SearchCACAssignments
          show={show}
          setShow={setShow}
          setSearch={setSearch}
          search={search}
          handleSearch={handleSearch}
          load={loadUnconfirmBookingList}
        />
      )}

      <Paper className={classes.root}>
        {show ? (
          <div>
            {" "}
            <TableContainer className={classes.container}>
              <Table color="pink" stickyHeader aria-label="sticky table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Quote ID</Table.HeaderCell>
                    <Table.HeaderCell>LIN Quote ID</Table.HeaderCell>
                    <Table.HeaderCell>Linguist ref number</Table.HeaderCell>
                    <Table.HeaderCell>Quotation Date</Table.HeaderCell>
                    <Table.HeaderCell>Quote Needed By</Table.HeaderCell>
                    <Table.HeaderCell>Grand total</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {UnconfirmBookingList ? (
                  <Table.Body>
                    {UnconfirmBookingList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).map((list, i) => (
                      <Table.Row hover role="checkbox" tabIndex={-1} key={i}>
                        {list?.quoteRequstByAccountclient?.id ? (
                          <Table.Cell>
                            <Link
                              to={`/admin/view-account-client-quotation/${list?.quoteRequstByAccountclient?._id}`}
                            >
                              {list?.quoteRequstByAccountclient?.id
                                ? list?.quoteRequstByAccountclient?.id
                                : "N/A"}
                            </Link>
                          </Table.Cell>
                        ) : (
                          <Table.Cell>
                            {list?.quoteRequstByAccountclient?.id
                              ? list?.quoteRequstByAccountclient?.id
                              : "N/A"}
                          </Table.Cell>
                        )}
                        <Table.Cell>
                          {" "}
                          <Link to={`/admin/view-provided-quote/${list?._id}`}>
                            {list?.id}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{list?.postedBy?.id}</Table.Cell>
                        <Table.Cell>
                          {Moment(list.createdAt).format("DD-MMM-YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                          {Moment(
                            list?.quoteRequstByAccountclient?.quote_by
                          ).format("DD-MMM-YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.currency?.substring(0, 3)} {list?.grand_total}
                        </Table.Cell>
                        <Table.Cell>
                          {list?.bank_pay && !list?.payment_status
                            ? "Await payment confirmation"
                            : null}
                        </Table.Cell>
                        <Table.Cell>
                          <Action
                            load={loadUnconfirmBookingList}
                            handlePaid={() =>
                              handlePaid(
                                list._id,
                                list.grand_total,
                                list.id,
                                list?.currency?.substring(0, 3)
                              )
                            }
                            id={list._id}
                            quoteID={list?.quoteRequstByAccountclient?._id}
                            ID={list?.id}
                            clientID={
                              list?.quoteRequstByAccountclient?.postedBy?.id
                                ? list?.quoteRequstByAccountclient?.postedBy?.id
                                : "N/A"
                            }
                            name={
                              list?.quoteRequstByAccountclient?.postedBy
                                ?.FirstName +
                              " " +
                              list?.quoteRequstByAccountclient?.postedBy
                                ?.LastName
                            }
                            currency={list?.currency?.substring(0, 3)}
                            estimate={list?.grand_total}
                            AdminID={values.AdminID}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                ) : null}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={UnconfirmBookingList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Paper>
    </>
  );
}
