import React from "react";

// Matetial Components
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import { useDispatch, useSelector } from "react-redux";
import { changeErInput } from "redux/actions/erAction";

const TermsAndConditions = ({ text, handleCheck }) => {
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { data } = useSelector((state) => state.erReducer);

  const { gdpr } = data;

  const handleChange = (e) => {
    const { name, checked } = e.target;
    dispatch(changeErInput({ name, value: !gdpr }));
    handleCheck(checked);
  };

  return (
    <div
      className={classes.tc}
      style={{
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            checked={gdpr}
            name="gdpr"
            color="primary"
          />
        }
        label={
          text ||
          "By submitting this form I agree with LC Privacy Policy, T&C, and GDPR"
        }
      />
    </div>
  );
};

export default TermsAndConditions;
