import React from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import AccountClientLayout from "layout/AccountClientLayout";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import LinguistProfile from "components/PublicProfileLIN/ProtectedPublicProfile/PPL";

const ManagePublicProfile = () => {
  const classes = useDashboardStyles();

  return (
    <AccountClientLayout>
      <Accountclient>

        <LinguistProfile />

      </Accountclient>
    </AccountClientLayout>
  );
};

export default ManagePublicProfile;
