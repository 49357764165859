import React, { useState, useEffect } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import Cookies from "js-cookie";
const InterpretingAcceptedJobChart = () => {
  const token = Cookies.get("token");
  const renderInterpretingAcceptedChart = async () => {
    try {
      const sdk = new ChartsEmbedSDK({
        baseUrl: "https://charts.mongodb.com/charts-lc-project-rdlgu", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
        height: 500,
        getUserToken: function () {
          return token;
        },
      });
      const chart = sdk.createChart({
        chartId: "7f8ea6bb-54f0-43b4-936e-e84fb28f7901",
      }); // ~REPLACE~ with the Chart ID from your Embed Chart dialog

      chart.render(document.getElementById("chart17"));
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    renderInterpretingAcceptedChart();
  });
  return <div id="chart17"></div>;
};

export default InterpretingAcceptedJobChart;
