import Employee from "components/RouteProtection/Employee/Employee";
import React, { useState, useEffect } from "react";
import { Dimmer, Header, Loader, Modal } from "semantic-ui-react";
import Button from "@material-ui/core/Button";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import EmployeeLayout from "layout/EmployeeLayout";
import TaskLists from "components/Employee/TaskLists/TaskLists";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import AddTask from "components/Employee/TaskLists/AddTask";
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    ["@media (max-width:768px)"]: {
      display: "inline-block",
      padding: "0px",
    },

    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      ["@media (max-width:768px)"]: {
        margin: "5px",
        width: "100%",
      },
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
};


const useStyles = makeStyles(styles);


const TaskListPage = () => {
  const button = useStyles();
  const classes = useDashboardStyles();
  const [open, setOpen] = useState(false);

  return (
    <EmployeeLayout>
      <Employee>
        {/* add task form */}
        <Modal
          size="large"
          closeIcon
          open={open}
          onClose={() => setOpen(false)}
        >
          <Header icon="tasks" content="Assign Task to Personnel(s)" />
          <Modal.Content>
            <AddTask />
          </Modal.Content>
        </Modal>
        {/* end of add task form */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
            <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  color="primary"
                >
                <p className={classes.cardTitleWhite}>Task List</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className={button.root}>
                    <Button variant="outlined" onClick={() => setOpen(true)}>
                      + Add new task
                    </Button>
                  </div>
                  <IconButton
                    color="inherit"
                    onClick={() => history.goBack()}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </div>
              </CardHeader>
              <CardBody>
                <TaskLists />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default TaskListPage;
