import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { getArchiveINV, removeINV } from "../../../../../action/manager";
import Moment from "moment";
import Cookies from "js-cookie";
import { Button } from "semantic-ui-react";
import swal from "sweetalert";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const token = Cookies.get("token");
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    List: [],
    removed: false,
    reload: false,
  });

  const { name, error, success, List, removed, reload } = values;

  useEffect(() => {
    loadList();
  }, [reload]);

  const loadList = () => {
    getArchiveINV().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setValues({ ...values, List: data });
      }
    });
  };

  const deleteAdmin = (_id) => {
    removeINV(_id, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        swal({
          icon: "success",
          text: `${data.message}`,
          buttons: false,
        });
        loadList();
      }
    });
  };

  const handleDelete = async (value) => {
    swal("Are you sure?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          deleteAdmin(value);

        default:
          break;
      }
    });
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="export">
          <TableHead>
            <TableRow>
              <TableCell>Invoice ID</TableCell>
              <TableCell>Linguist Ref ID</TableCell>
              <TableCell>Total to be paid</TableCell>
              <TableCell>Invoice Created Date</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Payment Date</TableCell>
              <TableCell>Transaction No</TableCell>
              <TableCell>Paid By</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {List.length > 0 ? (
            <TableBody>
              {List.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((list) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={list._id}>
                  <TableCell>
                    <Link to={`/admin/view-invoice/${list?._id}`}>
                      {list?.id}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {list.postedBy?.id ? list.postedBy?.id : "N/A"}
                  </TableCell>
                  <TableCell>{list?.currency?.substring(0,3)} {list?.grand_total}</TableCell>
                  <TableCell>
                    {Moment(list?.createdAt).format("DD MMM YYYY - hh:mm:ss")}
                  </TableCell>
                  <TableCell>{list?.invoice_tobe_paid_by}</TableCell>
                  <TableCell>
                    {list.payment_date
                      ? Moment(list?.payment_date).format("DD MMM YYYY")
                      : "N/A"}
                  </TableCell>
                  <TableCell>{list?.payment_ref ? list?.payment_ref : "N/A"}</TableCell>
                  <TableCell>{list?.paidBy ? list?.paidBy : "N/A"}</TableCell>
                  <TableCell>{list?.status}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleDelete(list._id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={List.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
