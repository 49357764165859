import SearchInput from "./SearchInput";
import { useState } from "react";
import { Button } from "semantic-ui-react";
// import { makeStyles } from "@material-ui/core/styles";
// const useStyles = makeStyles((theme) => ({
//   div: {
//     marginTop: "24px",
//     width: "50%",
//     ["@media (max-width:768px)"]: {
//       width: "100%",
//     },
//   },
//   root: {
//     ["@media (max-width:768px)"]: {
//       width: "100%",
//       display: "flex",
//       justifyContent: "center",
//     },
//   },
//   button: {
//     ["@media (max-width:768px)"]: {
//       width: "96%",
//       marginTop: 5,
//     },
//   },
// }));
const SearchClient = ({
  setShow,
  setSearchText,
  SearchText,
  handleSearch,
  load,
  show,
}) => {
  return (
    <div>
      <SearchInput
        load={load}
        setSearchText={setSearchText}
        SearchText={SearchText}
        handleSearch={handleSearch}
        show={show}
        setShow={setShow}
      />
    </div>
  );
};

export default SearchClient;
