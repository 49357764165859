import React, { useState, useEffect } from "react";
import Cashclient from "components/RouteProtection/CashClient/Cashclient";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CashClientLayout from "layout/CashClientLayout";
import Cookies from "js-cookie";
import Axios from "utils/axios";

import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Rating } from "semantic-ui-react";

const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  const [feedback, setFeedback] = useState(null);
  const [transFeedback, setTransFeedback] = useState(null);

  const getInterpretingFeedback = async () => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      "/api/cashclient/interpreting-feedback",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setFeedback(response?.data);
  };

  const getTranslationFeedback = async () => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      "/api/cashclient/translation-feedback",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setTransFeedback(response?.data);
  };

  useEffect(() => {
    getInterpretingFeedback();
    getTranslationFeedback();
  }, []);

  let totalFeedbackbyLIN = 0,
    avgFeedbackByLIN = 0,
    totalFeedbackByClient = 0,
    avgFeedbackByClient = 0;
  let totalTransFeedbackbyLIN = 0,
    avgTransFeedbackByLIN = 0,
    totalTransFeedbackByClient = 0,
    avgTransFeedbackByClient = 0;

  // calculate interpreting feedback by linguist
  for (let i = 0; i <= feedback?.length - 1; i++) {
    totalFeedbackbyLIN += feedback[i].feedback;
    avgFeedbackByLIN = totalFeedbackbyLIN / feedback?.length;
  }

  // calculate interpreting feedback by client
  for (let i = 0; i <= feedback?.length - 1; i++) {
    totalFeedbackByClient += feedback[i].feedbackByClient;
    avgFeedbackByClient = totalFeedbackByClient / feedback?.length;
  }

  // calculate translation feedback by linguist
  for (let i = 0; i <= transFeedback?.length - 1; i++) {
    totalTransFeedbackbyLIN += transFeedback[i].feedbackByLin;
    avgTransFeedbackByLIN = totalTransFeedbackbyLIN / transFeedback?.length;
  }

  // calculate translation feedback by client
  for (let i = 0; i <= transFeedback?.length - 1; i++) {
    totalTransFeedbackByClient += transFeedback[i].feedbackByClient;
    avgTransFeedbackByClient =
      totalTransFeedbackByClient / transFeedback?.length;
  }

  return (
    <CashClientLayout>
      <Cashclient>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Feedback</p>
              </CardHeader>
              <CardBody>
                <h1>Feedbacks from Linguists</h1>

                <h3
                  style={{
                    textAlign: "center",
                    width: "100%",
                    color: "purple",
                  }}
                >
                  Interpreting Assignment Feedback ({avgFeedbackByLIN})
                  <Rating
                    maxRating={5}
                    rating={avgFeedbackByLIN}
                    icon="star"
                    size="massive"
                  />
                </h3>

                <h3
                  style={{
                    textAlign: "center",
                    width: "100%",
                    color: "purple",
                  }}
                >
                  Translation Assignment Feedback ({avgTransFeedbackByLIN})
                  <Rating
                    maxRating={5}
                    rating={avgTransFeedbackByLIN}
                    icon="star"
                    size="massive"
                  />
                </h3>

                <hr />

                <h1>My feedbacks to Linguists</h1>

                <h3
                  style={{
                    textAlign: "center",
                    width: "100%",
                    color: "purple",
                  }}
                >
                  Interpreting Assignment Feedback ({avgFeedbackByClient})
                  <Rating
                    maxRating={5}
                    rating={avgFeedbackByClient}
                    icon="star"
                    size="massive"
                  />
                </h3>

                <h3
                  style={{
                    textAlign: "center",
                    width: "100%",
                    color: "purple",
                  }}
                >
                  Translation Assignment Feedback ({avgTransFeedbackByClient})
                  <Rating
                    maxRating={5}
                    rating={avgTransFeedbackByClient}
                    icon="star"
                    size="massive"
                  />
                </h3>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Cashclient>
    </CashClientLayout>
  );
};

export default UnconfirmedAssignmentsPage;
