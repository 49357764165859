import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomPhone from "components/Common/CustomPhone";
// Other
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { CURRENT_COUNTRY, EMAIL_REGEX } from "data";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import {
  changeInhouseClientInput,
  updateInhouseClientStep,
} from "redux/actions/icrAction";
import Axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";

const FormTwo = () => {
  const [notValid, setNotValid] = useState(false);
  const { data } = useSelector((state) => state.icrReducer);
  const {
    AddressLine1,
    AddressLine2,
    Birthday,
    City,
    Country,
    FirstName,
    LastName,
    Nationality,
    PostalCode,
    Region,
    business_address,
    business_address_2,
    business_country,
    business_email,
    business_name,
    business_phone_no,
    business_region,
    business_town,
    business_website,
    business_zipcode,
    company_registration_no,
    confirm_passNumber,
    confirm_passcode,
    direct_line,
    email,
    gdpr,
    mobile_no,
    note,
    other_nationality,
    passNumber,
    passcode,
    position,
    sex,
    title,
    charity_registration_no,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: value || checked,
    };
    dispatch(changeInhouseClientInput(inputData));
  };

  const handleChecked = (e) => {
    const { name, value, checked } = e.target;
    const inputData = {
      name,
      value: checked,
    };
    dispatch(changeInhouseClientInput(inputData));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeInhouseClientInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleChangeCO = (e, value) => {
    dispatch(
      changeInhouseClientInput({
        name: "business_country",
        value,
      })
    );
  };

  const handleBusinessPhone = (value) => {
    const inputData = {
      name: "business_phone_no",
      value: value,
    };
    dispatch(changeInhouseClientInput(inputData));
  };

  const handleNext = async (e) => {
    const token = Cookies.get("LINtoken");
    let formData = new FormData();
    formData.append("AddressLine1", AddressLine1);
    formData.append("AddressLine2", AddressLine2);
    formData.append("Birthday", Birthday);
    formData.append("City", City);
    formData.append("Country", Country);
    formData.append("FirstName", FirstName);
    formData.append("LastName", LastName);
    formData.append("Nationality", Nationality);
    formData.append("PostalCode", PostalCode);
    formData.append("Region", Region);
    formData.append("business_address", business_address);
    formData.append("business_address_2", business_address_2);
    formData.append("business_country", business_country);
    formData.append("business_email", business_email);
    formData.append("business_name", business_name);
    formData.append("business_phone_no", business_phone_no);
    formData.append("business_region", business_region);
    formData.append("business_town", business_town);
    formData.append("business_website", business_website);
    formData.append("business_zipcode", business_zipcode);
    formData.append("company_registration_no", company_registration_no);
    formData.append("confirm_passNumber", confirm_passNumber);
    formData.append("confirm_passcode", confirm_passcode);
    formData.append("direct_line", direct_line);
    formData.append("email", email);
    formData.append("gdpr", gdpr);
    formData.append("mobile_no", mobile_no);
    formData.append("note", note);
    formData.append("other_nationality", other_nationality);
    formData.append("passNumber", passNumber);
    formData.append("position", position);
    formData.append("sex", sex);
    formData.append("title", title);
    formData.append("charity_registration_no", charity_registration_no);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/add-inhouse-client`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success!",
        text: `Registration Form Submitted. you can now book or order assignment for this client.`,
        icon: "success",
        buttons: "Ok",
      }).then(() => {
        window.location = "/linguist/add-inhouse-client";
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `Email is associated with an existing account. Please use a different email for completing registration.`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleBack = (e) => {
    dispatch(updateInhouseClientStep(1));
  };
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <CustomInputSingle
        onChange={handleChange}
        value={business_name}
        name="business_name"
        label="Business or organisation name"
        width="100%"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={position}
        name="position"
        label="Your position"
        width="100%"
      />
      <CustomBigInputSingle
        onChange={handleEmailChange}
        value={business_email}
        name="business_email"
        label="Business email address"
        moreInfo="If different"
        width="100%"
      />
      {business_email && notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomPhone
        onChange={(e) => handleBusinessPhone(e)}
        value={business_phone_no}
        name="business_phone_no"
        label="Business phone number"
        width="100%"
      />
      {/* *Developer allow select same as before or add new with 5 fields including post code and country */}
      <CustomBigInputSingle
        onChange={handleChange}
        label="House number/name, Street name"
        moreInfo="main contact address (if different)"
        width="100%"
        name="business_address"
        value={business_address}
      />
      <CustomInputSingle
        onChange={handleChange}
        label="Address line 2"
        width="100%"
        name="business_address_2"
        value={business_address_2}
      />
      <CustomInputSingle
        onChange={handleChange}
        label="Town / City"
        width="100%"
        name="business_town"
        value={business_town}
      />
      <CustomInputSingle
        onChange={handleChange}
        label="Post / Zip Code"
        width="100%"
        name="business_zipcode"
        value={business_zipcode}
      />
      <CustomInputSingle
        onChange={handleChange}
        label="County / Region"
        width="100%"
        name="business_region"
        value={business_region}
      />
      <CustomAutoSelect
        onChange={handleChangeCO}
        label="Country"
        width="100%"
        name="business_country"
        value={business_country}
        data={CURRENT_COUNTRY}
      />
      <CustomBigInputSingle
        onChange={handleChange}
        label="Company registration number"
        moreInfo="If registered"
        width="100%"
        name="company_registration_no"
        value={company_registration_no}
      />
      <CustomBigInputSingle
        onChange={handleChange}
        label="Charity registration number"
        moreInfo="If registered"
        width="100%"
        name="charity_registration_no"
        value={charity_registration_no}
      />
      <CustomInputSingle
        onChange={handleChange}
        value={business_website}
        name="business_website"
        label="Business website"
        width="100%"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={note}
        name="note"
        label="Additional information or note"
        width="100%"
      />

      <div
        className={classes.tc}
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChecked}
              checked={gdpr}
              name="gdpr"
              color="primary"
            />
          }
          label="I have made the client aware of the LC T&C  and Privacy Policy and client agree with them, I am aware of my responsibility for UK and EU Data Protection laws and GDPR"
        />
      </div>

      <div className={classes.buttonFlex}>
        <CustomButton
          text="Back"
          variant="outlined"
          color="primary"
          onClick={handleBack}
        />
        <CustomButton
          style={{ marginLeft: 30 }}
          disabled={!gdpr || (notValid && business_email)}
          text="Submit"
          onClick={handleNext}
        />
      </div>
    </form>
  );
};

export default FormTwo;
