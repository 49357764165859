// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import history from "utils/history";
const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const BookingOptions = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button variant="outlined" onClick={() => history.push("/admin/cms")}>
        CMS
      </Button>

      {/* post sliders options */}
      {history.location.pathname.includes("admin/linguist") ? (
        <Button
          variant="outlined"
          onClick={() =>
            history.push("/admin/linguist-public-profile-sliders/post-slider")
          }
        >
          Post Slider
        </Button>
      ) : history.location.pathname.includes("admin/sppo") ? (
        <Button
          variant="outlined"
          onClick={() =>
            history.push("/admin/sppo-public-profile-sliders/post-slider")
          }
        >
          Post Slider
        </Button>
      ) : history.location.pathname.includes("admin/job-advert-sliders") ? (
        <Button
          variant="outlined"
          onClick={() =>
            history.push("/admin/job-advert-sliders/post-slider")
          }
        >
          Post Slider
        </Button>
      ) : null}


      {/* view sliders options */}
      {history.location.pathname.includes("admin/linguist") ? (
        <Button
          variant="outlined"
          onClick={() =>
            history.push("/admin/linguist-public-profile-sliders/view-sliders")
          }
        >
          View Sliders
        </Button>
      ) : history.location.pathname.includes("admin/sppo") ? (
        <Button
          variant="outlined"
          onClick={() =>
            history.push("/admin/sppo-public-profile-sliders/view-sliders")
          }
        >
          View Sliders
        </Button>
      ) : history.location.pathname.includes("admin/job-advert-sliders") ? (
        <Button
          variant="outlined"
          onClick={() =>
            history.push("/admin/job-advert-sliders/view-sliders")
          }
        >
          View Sliders
        </Button>
      ) : null}


    </div>
  );
};

export default BookingOptions;
