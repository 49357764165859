import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
// Material Components
import Button from "@material-ui/core/Button";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import { forgotPassword } from "redux/actions/auth";
import CustomDate from "components/Common/CustomDate";

const ForgotPassword = ({ type }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [userId, setUserId] = useState();
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleUserId = (e) => {
    setUserId(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(forgotPassword({ email, userId }));
  };
  return (
    <Fragment>
      <CustomDate label="Enter your birth date" width="100%" />
      {/* {type === "dob" ? (
      ) : (
        <CustomInput
          onChange={handleUserId}
          value={userId}
          label="Enter your user ID.."
        />
      )} */}
      <CustomInput
        onChange={handleChange}
        value={email}
        label="Enter your associated email"
      />
      <Button
        onClick={handleSubmit}
        color="primary"
        variant="contained"
        disabled={!email}
        style={{
          marginTop: "15px",
        }}
      >
        Send Reset Link
      </Button>
    </Fragment>
  );
};

export default ForgotPassword;
