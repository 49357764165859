import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Header } from "semantic-ui-react";
import LinguistLayout from "layout/LinguistLayout";
import Linguist from "components/RouteProtection/Linguist/Linguist";
import InvoicingOptions from "components/Linguist/Invoicing/InvoicingOptions";
import ReceivedInvoices from "components/Linguist/Invoicing/PaidInvoice/InvoicingList";

const ReceivedInvoicesList = () => {
    const classes = useDashboardStyles();
    
    return (
        <Linguist>
            <LinguistLayout>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                                <p className={classes.cardTitleWhite}>Paid invoices</p>
                                <IconButton color='inherit'><ArrowBackIcon
                                    style={{ marginLeft: 5 }}
                                    onClick={() => history.goBack()}
                                ></ArrowBackIcon></IconButton>
                            </CardHeader>
                        </Card>
                        <Card>
                            <CardHeader
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <InvoicingOptions />
                            </CardHeader>
                            <CardBody>
                                <Header as="h4"></Header>
                                <ReceivedInvoices />

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </LinguistLayout>
        </Linguist>
    );
};

export default ReceivedInvoicesList;
