import React, { Fragment } from "react";

// Custom Components
import Freelancers from "../Filters/Freelancers";
import Clients from "../Filters/Clients";
import Customers from "../Filters/Customers";
import Employees from "../Filters/Employees";

const FirstSegment = () => {
  return (
    <Fragment>
      <Freelancers />
      <Clients />
      <Customers />
      <Employees />
    </Fragment>
  );
};

export default FirstSegment;
