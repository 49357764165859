import React, { useState, useEffect } from 'react';
import { getProfile, getCookie } from '../../../../action/cashClientaction';
// Material Icons

import CommonCard from "components/Common/CommonCard";

import { CompanyDetailsDiv } from "../style";

const CompanyDetails = () => {
  const token = getCookie('CACtoken');
  const [values, setValues] = useState({})

  const init = () => {
    getProfile(token).then(data => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({
          ...values,

          id: data.id,
          FirstName: data.FirstName,
          LastName: data.LastName,
          email: data.email,
          direct_line: data.direct_line,
          mobile_no: data.mobile_no,
          AddressLine1: data.AddressLine1,
          AddressLine2: data.AddressLine2,
          City: data.City,
          PostalCode: data.PostalCode,
          Country: data.Country,
          Region: data.Region,
          Nationality: data.Nationality,
          business_name: data.business_name,
          position: data.position,
          business_email: data.business_email,
          business_phone_no: data.business_phone_no,
          business_address: data.business_address,
          business_address_2: data.business_address_2,
          business_town: data.business_town,
          business_zipcode: data.business_zipcode,
          business_region: data.business_region,
          business_country: data.business_country,
          business_website: data.business_website,
          company_registration_no: data.company_registration_no,
          charity_registration_no: data.charity_registration_no,
        });
      }
    });
  };

  useEffect(() => {
    init();
  }, []);


  return (
    <CommonCard>
      <CompanyDetailsDiv>
        <h3>Client / Customer ref no. {values.id}</h3>
           <div className="details">
          <p className="text">Company Profile</p> <br/>
          <p className="text">Business name: {values.business_name}</p>
          <p className="text">Position: {values.position}</p>
          <p className="text">Business email: {values.business_email}</p>
          <p className="text">Phone: {values.business_phone_no}</p>
          <p className="text">Business Address: {values.business_address}, {values.business_address_2}</p>
          <p className="text">Town/City: {values.business_town}</p>
       
          <p className="text">Region: {values.business_region}</p>
          <p className="text">Zipcode/Postalcode: {values.business_zipcode}</p>
          <p className="text">Country: {values.business_country}</p>

       
          <p className="text">Company registration no: {values.company_registration_no}</p>
          <p className="text">Charity registration no: {values.charity_registration_no}</p>
          <p className="text">Business website: {values.business_website}</p>
          <p className="text">Additional note: {values.note}</p>
        </div>

      </CompanyDetailsDiv>

    </CommonCard>
  );
};

export default CompanyDetails;
