import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import Action from "./Action"
import Cookies from "js-cookie";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const styles = {
    root: {
        display: "flex",
        alignItems: "center",
        ["@media(max-width:1024px)"]: {
            width: "-webkit-fill-available",
        },

        "& .MuiButton-root": {
            marginRight: "10px",
            color: "#fff",
            borderColor: "purple",
            "&:last-child": {
                marginRight: "0px",
            },
        },
    },
};


export default function SimpleAccordion() {
    const classes = useStyles();
    const { id } = useParams();
    const matches = useMediaQuery("(max-width:1024px)");
    const [taskDetails, setTaskDetails] = useState();

    const getTaskDetails = async (e) => {
        const token = Cookies.get("EMPtoken");
        const response = await Axios.get(
            `${process.env.REACT_APP_BACKEND_API}/employee/my-task-list`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        setTaskDetails(response?.data);
    };

    useEffect(() => {
        getTaskDetails();
    }, []);

    return (
        <div className={classes.root}>
            {taskDetails?.map((list) => (
                <>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={
                                list?.active == false
                                    ? { backgroundColor: "green" }
                                    : list?.priority == "Low"
                                        ? { backgroundColor: "lightyellow" }
                                        : list?.priority == "Medium"
                                            ? { backgroundColor: "#FFBF00" }
                                            : list?.priority == "High"
                                                ? { backgroundColor: "red" }
                                                : list?.priority == "Done"
                                                    ? { backgroundColor: "lightgreen" }
                                                    : null

                            }
                        >
                            <Typography className={classes.heading}>
                                {list?.id} -{" "}
                                {list?.postedByName} -{" "}
                                {list?.postedByID} -{" "}
                                {list?.subject} -{" "}
                                {list?.deadline} -{" "}
                                {list?.priority} {">>"} {" "}
                                {list?.priorityMarkAs?.filter((dt) => dt != "")
                                    .map((st) => (
                                        <>{st}{st ? " > " : " "} </>
                                    ))}
                                {list?.active ? " | Active Task" : " | Task Closed"}
                            </Typography>
                        </AccordionSummary>
                        <hr />
                        <AccordionDetails>
                            Task Details:

                            <GridContainer
                                className={classes.container}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <GridItem xs={12} sm={12} md={11} lg={11} xl={11}>
                                    <Typography style={{ textAlign: "justify" }} className={classes.heading}> {list?.task}</Typography>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
                                    <Action id={list?._id} load={getTaskDetails} active={list?.active} />
                                </GridItem>
                            </GridContainer>
                        </AccordionDetails>
                        <hr />
                        <AccordionDetails>
                            Task Note:

                            <GridContainer
                                className={classes.container}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography style={{ textAlign: "justify" }} className={classes.heading}>{list?.note}</Typography>
                                </GridItem>

                            </GridContainer>
                        </AccordionDetails>
                        <hr />
                        <AccordionDetails>
                            Status:

                            <GridContainer
                                className={classes.container}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                    {list?.updatedBy?.filter(dt => dt != "").map(st =>
                                        <Typography className={classes.heading}>{st}</Typography>
                                    )}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                    {list?.priorityMarkAs?.filter((dt) => dt != "")
                                        .map((st) => (
                                            <Typography className={classes.heading}
                                            >
                                                <b style={st == "Low"
                                                    ? { backgroundColor: "lightyellow" }
                                                    : st == "Medium"
                                                        ? { backgroundColor: "#FFBF00" }
                                                        : st == "High"
                                                            ? { backgroundColor: "red" }
                                                            : { backgroundColor: "lightgreen" }}> {st}</b> <br />
                                            </Typography>
                                        ))}
                                </GridItem>
                            </GridContainer>
                        </AccordionDetails>
                        <hr />
                        <AccordionDetails>
                            Comment / Note:

                            <GridContainer
                                className={classes.container}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {list?.comment?.filter(dt => dt != "").map(st =>
                                        <Typography className={classes.heading}> {st}</Typography>
                                    )}
                                </GridItem>
                            </GridContainer>
                        </AccordionDetails>
                        <hr />
                        <AccordionDetails>
                            Assigned to:
                            <GridContainer
                                className={classes.container}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                {list?.personnelID?.filter(dt => dt != "").map(st =>
                                    <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <Typography className={classes.heading}>{st}</Typography>
                                    </GridItem>
                                )}

                            </GridContainer>
                        </AccordionDetails>
                    </Accordion>
                    <br />
                </>
            ))}
        </div>
    );
}
