import React, { useEffect, useState } from "react";

import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";

import LocationOnIcon from "@material-ui/icons/LocationOn";

//Custom style
import Axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { updateLinguistProfile } from "redux/actions/updateProfileAction";
import useFormStyles from "assets/jss/components/auth/formStyle";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import Moment from "moment";

import { Dimmer, Header, Loader, Tab } from "semantic-ui-react";

import PersonalDetails from "./PersonalDetails";

const ViewLinguist = () => {
  const token = Cookies.get("token");
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { data } = useSelector((state) => state.updateProfileReducer);
  const [upload, setUpload] = useState("");
  const [state, setState] = React.useState({
    reload: false,
  });

  const panes = [
    {
      menuItem: "Personal Details",
      render: () => (
        <Tab.Pane attached={false}>
          <PersonalDetails />
        </Tab.Pane>
      ),
    },
  ];

  const getAdminDetails = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/view/admins/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(updateLinguistProfile(response?.data));
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getAdminDetails();
  }, [state]);

  const handleUpload = async (e) => {
    document.querySelector("#file").click();
  };

  const handleFiles = async (e) => {
    if (e.target.files.length) {
      let fd = new FormData();

      fd.append("photo", e.target.files[0]);

      try {
        const response = await Axios.put(
          `${process.env.REACT_APP_BACKEND_API}//manager-admin/update-admin-account/${data._id}`,
          fd,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        swal({
          icon: "success",
          text: "Thank you for updating profile",
          buttons: false,
        }).then(function () {
          window.location = `/admin/view-admin-details/${id}`;
        });
      } catch (error) {
        console.log(error.response);
        if (error.response?.data) {
          swal({
            icon: "error",
            text: error?.response?.data?.error,
            buttons: false,
            dangerMode: true,
          });
        }
      }
    }
  };

  if (!data.FirstName) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
    >
      <div
        style={{ marginBottom: "5rem", display: "flex", flexDirection: "row" }}
      >
        <GridItem xs={6} sm={6} md={4} lg={2} xl={2}>
          <Card>
            <CardBody>
              <div
                style={{ cursor: "pointer" }}
                onClick={handleUpload}
                className="image"
              >
                <img
                  src={`${process.env.REACT_APP_BACKEND_API}/manager-admin/photo-admins/${data._id}`}
                  style={{ maxHeight: "auto", maxWidth: "100%" }}
                  alt="profile photo"
                />
              </div>
              <input
                onChange={handleFiles}
                type="file"
                accept="image/png, image/jpeg"
                id="file"
                style={{ display: "none" }}
              />
            </CardBody>
          </Card>
        </GridItem>

        <div style={{ marginLeft: "4rem" }}>
          <br />
          <Header size="large">
            {data.title} {data.FirstName} {data.LastName}
          </Header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              ID-{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.id}
            </Header>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Joining Date -{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {Moment(data.createdAt).format("DD MMM YYYY - kk:mm:ss")}
            </Header>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Email-{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.email}
            </Header>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Header style={{ margin: "0" }} size="medium">
              Mobile number-{" "}
            </Header>
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.mobile_no}
            </Header>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <LocationOnIcon />
            <Header
              style={{ margin: "0", marginLeft: "5px" }}
              color="grey"
              size="medium"
            >
              {data.Country}
            </Header>
          </div>
        </div>
      </div>

      <div>
        <div>
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
      </div>
    </div>
  );
};

export default ViewLinguist;
