import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import CustomPassword from "components/Common/CustomPassword";
import Typography from "@material-ui/core/Typography";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Other
import { CURRENT_COUNTRY, GENDER_DATA, EMAIL_REGEX, PASS_REGEX } from "data";
import { updateCrStep, changeCrInput } from "redux/actions/crAction";
import CustomDate from "components/Common/CustomDate";
import CustomRadio from "components/Common/CustomRadio";
import CustomAutoSelect from "components/Common/CustomAutoSelect";
import CustomPhone from "components/Common/CustomPhone";
import { Button, Header } from "semantic-ui-react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";

const FormOne = () => {
  const { data } = useSelector((state) => state.crReducer);
  const [photo, setPhoto] = useState(null);
  const [passport, setPassport] = useState(null);
  const [notRegex, setNotRegex] = useState(false);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const [notValid, setNotValid] = useState(false);
  const {
    title,
    first_name,
    last_name,
    dob,
    gender,
    email,
    password,
    confirm_password,
    mobile_no,
    direct_line,
    house_no,
    street_name,
    town,
    zip_code,
    county_region,
    country,
    current_nationality,
  } = data;

  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleNext = () => {
    dispatch(updateCrStep(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
    if (value !== "" && PASS_REGEX.test(value)) {
      setNotRegex(false);
    } else {
      setNotRegex(true);
    }
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value,
    };
    dispatch(changeCrInput(inputData));
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleDateChange = (date) => {
    dispatch(
      changeCrInput({
        name: "dob",
        value: date,
      })
    );
  };

  const handleAutoCompleteCO = (e, value) => {
    dispatch(
      changeCrInput({
        name: "country",
        value,
      })
    );
  };

  const handleChangeN = (e, value) => {
    dispatch(
      changeCrInput({
        name: "current_nationality",
        value,
      })
    );
  };

  const handleMobile = (value) => {
    dispatch(
      changeCrInput({
        name: "mobile_no",
        value,
      })
    );
  };

  const handlePassport = (e) => {
    setPassport(e.target.files[0]);
  };

  const handlePhoto = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handlePassportClick = (e) => {
    e.preventDefault();
    fileInput2.current.click();
  };

  const isAllow =
    !title ||
    !first_name ||
    !last_name ||
    !dob ||
    !email ||
    !password ||
    !confirm_password ||
    !mobile_no ||
    !house_no ||
    !town ||
    !zip_code ||
    !country ||
    notValid ||
    password !== confirm_password ||
    notRegex;

  return (
    <form className={classes.form} noValidate autoComplete="off">
      {/* <input
        accept="image/jpeg, image/png"
        ref={fileInput}
        onChange={(e) => handlePhoto(e)}
        type="file"
        style={{ display: "none" }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
          Upload profile photo
        </Header>
        <div>
          <Button onClick={handleClick}>Upload</Button>
        </div>
        {photo && (
          <Header style={{ margin: "0", textAlign: "center" }} as="h5">
            <Header style={{ margin: "0" }} as="h1">
              <InsertPhotoIcon fontSize="large" />
            </Header>
            {photo.name}
            <Header style={{ margin: "0" }} as="h5">{`Size: ${(
              photo.size / 1024
            ).toFixed(2)}kb`}</Header>
            <Header
              style={{ margin: "0" }}
              as="h5"
            >{`Last Modified: ${photo?.lastModifiedDate}`}</Header>
          </Header>
        )}
      </div> */}
      <CustomInputSingle
        onChange={handleChange}
        value={title}
        name="title"
        label="Title"
        width="100%"
        required
        placeholder="e.g. Mr, Mrs, Miss, Ms etc"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={first_name}
        name="first_name"
        label="First Name(s)"
        width="100%"
        required
        placeholder="First Name(s)"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={last_name}
        name="last_name"
        label="Last Name"
        width="100%"
        required
        placeholder="Last Name"
      />
      <CustomDate
        onChange={handleDateChange}
        value={dob}
        name="dob"
        label="Date of Birth"
        width="100%"
        maxDate={new Date("1-1-2000")}
        required
      />
      <br />
      <Typography variant="subtitle2">
        Personal or Company Legal Representative Address:
      </Typography>
      <CustomInputSingle
        style={{ margin: "0px" }}
        onChange={handleChange}
        value={house_no}
        name="house_no"
        label="House number/name, Street name"
        width="100%"
        required
        placeholder="e.g. Flat/Building Name, 101 Example Street"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={street_name}
        name="street_name"
        label="Address line 2"
        width="100%"
        placeholder="Additional Address Information"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={town}
        name="town"
        label="Town / City"
        width="100%"
        required
        placeholder="Your Village, Town or City Name"
      />
      <CustomInputSingle
        onChange={handleChange}
        label="County / Region"
        width="100%"
        name="county_region"
        value={county_region}
        placeholder="Your County, Division or State Name"
      />
      <CustomInputSingle
        onChange={handleChange}
        value={zip_code}
        name="zip_code"
        label="Post Code / Zip Code / Post office"
        width="100%"
        required
        placeholder="Your Post Code, Zip Code or Post Office Name"
      />
      <CustomAutoSelect
        onChange={handleAutoCompleteCO}
        label="Country"
        moreInfo="Current country of residence"
        width="100%"
        value={country}
        data={CURRENT_COUNTRY}
        required
      />
      {/* <CustomAutoSelect
        onChange={handleChangeN}
        label="Current nationality"
        width="100%"
        name="current_nationality"
        value={current_nationality}
        data={CURRENT_COUNTRY}
        
      /> */}
      <CustomInputSingle
        onChange={handleEmailChange}
        value={email}
        name="email"
        label="Primary email address"
        width="100%"
        required
        placeholder="e.g. example@example.com"
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomPassword
        onChange={handlePasswordChange}
        label="Password"
        width="100%"
        name="password"
        value={password}
        notRegex={notRegex}
        required
        placeholder="e.g. Ex@mPle012345"
      />
      <CustomPassword
        onChange={handleChange}
        label="Confirm password"
        width="100%"
        name="confirm_password"
        value={confirm_password}
        validation={password !== confirm_password}
        required
      />

      <br />
      <CustomPhone
        label="Mobile Number"
        onChange={handleMobile}
        placeholder="Mobile number (e.g. +44 1234 567890) *"
        value={mobile_no}
        name="mobile_no"
        required
      />
      {/* <div
        style={{ display: "flex", flexDirection: "column", marginTop: "24px" }}
      >
        <input
          name="identification_doc"
          ref={fileInput2}
          onChange={handlePassport}
          type="file"
          style={{ display: "none" }}
          accept="image/jpeg, image/png"
        />
        <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
          Upload personal identification (passport/DL/national ID)
        </Header>
        <div>
          <Button onClick={handlePassportClick}>Upload</Button>
        </div>
        {passport && (
          <Header style={{ margin: "0", textAlign: "center" }} as="h5">
            <Header style={{ margin: "0" }} as="h1">
              <InsertPhotoIcon fontSize="large" />
            </Header>
            {passport.name}
            <Header style={{ margin: "0" }} as="h5">{`Size: ${(
              passport.size / 1024
            ).toFixed(2)}kb`}</Header>
            <Header
              style={{ margin: "0" }}
              as="h5"
            >{`Last Modified: ${passport?.lastModifiedDate}`}</Header>
          </Header>
        )}
      </div> */}

      <CustomInputSingle
        onChange={handleChange}
        value={direct_line}
        name="direct_line"
        label="Direct line with extension no"
        width="100%"
        placeholder="Your direct line no + any extension no (e.g. +44123456789 Ext. 123)"
      />

      <div className={classes.buttonFlex}>
        <CustomButton text="Next" onClick={handleNext} disabled={isAllow} />
      </div>
    </form>
  );
};

export default FormOne;
