import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

const CommunicationMenu = () => {
  const classes = useStyles();
  const token = Cookies.get("token");
  const [queuing, setQueuing] = useState({ reload: false });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const QueuingDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/queue-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setQueuing(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    QueuingDetails();
  }, []);

  return (
    <Paper className={classes.root}>
      <div>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Created At</TableCell>
                <TableCell>Processing</TableCell>
                <TableCell>Completed</TableCell>
              </TableRow>
            </TableHead>

            {queuing.length > 0 ? (
              <TableBody>
                {queuing

                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((list, i) => (
                    <TableRow tabIndex={-1} key={i}>
                      <TableCell>
                        {moment(list?.createdAt).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell>{list?.processing}</TableCell>
                      <TableCell>{list?.completed}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            ) : (
              "no data available"
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={queuing.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </Paper>
  );
};

export default CommunicationMenu;
