import CustomDate from "components/Common/CustomDate";
import CustomInput from "components/Common/CustomInput";

import { DetailsDiv } from "../CreateQuotation/style";

const Details = () => {
  return (
    <DetailsDiv>
      <div className="left">
        <ul>
          <li>
            Invoice to: <span></span>
          </li>
          <li>
            Name of the company: <span></span>
          </li>
          <li className="address">Name of the person's address:</li>
          <li>Email:</li>
        </ul>
      </div>
      <div className="right">
        <ul>
          <li>
            Invoice number: <span></span>
          </li>
          <li>
            Linguist/SP ID number: <span></span>
          </li>
          <li>Name of the professional: </li>
          <li>Linguist Residence:</li>
          <li>VAT No:</li>
          <li>
            Date of Quote: <span></span>
          </li>
          <li>
            Date of Invoice: <span></span>
          </li>
          <li>Invoice to be paid by:</li>
          <li>Job reference ID:</li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Service completion date:</span>
            <CustomDate name="service_completion_date" width="40%" />
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Linguist/SP invoice ref:</span>
            <CustomInput width="35%" name="spQuoteRef" />
          </li>
        </ul>
      </div>
    </DetailsDiv>
  );
};

export default Details;
