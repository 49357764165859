import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PeopleIcon from "@material-ui/icons/People";
import { useMediaQuery } from "@material-ui/core";
import { Fragment } from "react";

const Actions = () => {
  const history = useHistory();
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <div>
      {!matches ? (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{ width: "30%", marginLeft: "5px", marginRight: "15px" }}
            onClick={() => history.push("/admin/add-account-client")}
          >
            Add Account Client
          </Button>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{ width: "30%", marginLeft: "5px", marginRight: "15px" }}
            onClick={() => history.push("/admin/send-bac-reg-form")}
          >
            Send Reg Form
          </Button>
          <Button
            startIcon={<PeopleIcon />}
            color="primary"
            variant="contained"
            style={{ width: "30%" }}
            onClick={() => history.push("/admin/view-account-client")}
          >
            View Account Clients
          </Button>
        </div>
      ) : (
        <Fragment>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "10px" }}
            onClick={() => history.push("/admin/add-account-client")}
          >
            Add Account Client
          </Button>
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "10px" }}
            onClick={() => history.push("/admin/send-bac-reg-form")}
          >
            Send Reg Form
          </Button>
          <Button
            startIcon={<PeopleIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%" }}
            onClick={() => history.push("/admin/view-account-client")}
          >
            View Account Clients
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default Actions;
