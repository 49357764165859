import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import Cookies from "js-cookie";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Action from "./Actions/Action";
import SearchView from "./Search/SearchView";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable() {
  const { id } = useParams();
  const token = Cookies.get("token");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  let sigPad = useRef({});
  const [pay, setPay] = useState({
    payment_date: "",
    payment_ref: "",
    estimate_paid_amount: "",
    note: "",
    signature: "",
    booking_id: "",
    total_bill_amount: "",
    modal: false,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };




  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    bookingList: [],
    removed: false,
    reload: false,
    AdminID: "",
  });

  const { bookingList } = values;

  useEffect(() => {
    loadbookingList();
    setFiltered([]);
  }, [filter]);

  const loadbookingList = async () => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/cash-clients`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list/account-clients`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-inhouse-client`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/list-private-client`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((cacclient, bacclient, incclient, prcclient) => {
        const history = cacclient.data
          .concat(bacclient.data)
          .concat(incclient.data)
          .concat(prcclient.data);
        filtered.length > 0
          ? setValues({ ...values, bookingList: filtered })
          : setValues({ ...values, bookingList: history });
      })
    );
  };

  return (
    <>
      <SearchView
        values={bookingList}
        SearchText={searchText}
        setSearchText={setSearchText}
        setFiltered={setFiltered}
        load={loadbookingList}
        setShow={setShow}
        setFilter={setFilter}
        show={show}
      />
      {show && (
        <Paper className={classes.root}>
          {" "}
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Client Ref</TableCell>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Date Joined</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Phone number</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              {bookingList.length > 0 ? (
                <TableBody>
                  {bookingList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((list) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={list._id}
                        style={
                          list?.cancel_request
                            ? { backgroundColor: "yellow" }
                            : null
                        }
                      >
                        {list?.id?.includes("CAC") ? (
                          <TableCell>
                            <Link to={`/admin/view-cash-client/${list?._id}`}>
                              {list?.id}
                            </Link>
                          </TableCell>
                        ) : list?.id?.includes("BAC") ? (
                          <TableCell>
                            <Link
                              to={`/admin/view-account-client/${list?._id}`}
                            >
                              {list?.id}
                            </Link>
                          </TableCell>
                        ) : list?.id?.includes("INC") ? (
                          <TableCell>
                            <Link to={`/admin/view-inhouse/${list?._id}`}>
                              {list?.id}
                            </Link>
                          </TableCell>
                        ) : (
                          <TableCell>
                            <Link to={`/admin/view-private/${list?._id}`}>
                              {list?.id}
                            </Link>
                          </TableCell>
                        )}

                        <TableCell>
                          {list?.business_name ? list?.business_name : "N/A"}
                        </TableCell>
                        <TableCell>{list?.FirstName}</TableCell>
                        <TableCell>
                          {Moment(list?.createdAt)
                            .tz("Europe/London")
                            .format("DD-MMM-YYYY")}
                        </TableCell>
                        <TableCell>{list?.email}</TableCell>
                        <TableCell>{list?.mobile_no}</TableCell>
                        <TableCell>
                          <Action
                            load={loadbookingList}
                            id={list._id}
                            name={list?.FirstName}
                            clientref={list?.id}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                "No data found"
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={bookingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
}
