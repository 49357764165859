import React, { useState, useEffect } from "react";

// Custom Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomButton from "components/Common/CustomButton";
import InputBase from "@material-ui/core/InputBase";

import IconButton from "@material-ui/core/IconButton";
import { getPregistrationList } from "../../../action/manager";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "semantic-ui-react";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import { makeStyles } from "@material-ui/core/styles";
import defaultAxios from "utils/axios";
import swal from "sweetalert";
import Cookies from "js-cookie";
import { useToasts } from "react-toast-notifications";
import { EMAIL_REGEX } from "data";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "70%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)",

    ["@media(max-width:1900px)"]: {
      width: "100%",
      display: "block",
    },
  },
  button: {
    ["@media(max-width:1024px)"]: {
      width: "100%",
      marginBottom: "5px !important",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));
const FormOne = () => {
  const classes = useStyles();
  const classe = useFormStyles();
  const { addToast } = useToasts();
  const [notValid, setNotValid] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    note: "",
  });
  const [id, setId] = useState();
  const { first_name, last_name, email, note } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleEmailChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const isAllow =
    !data.first_name || !data.email || !data.last_name || notValid;

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async (e) => {
    getPregistrationList().then((data) => {
      if (data && data.error) {
        console.log(data.error);
      } else {
        setFetchedData(data);
      }
    });
  };

  const handleFind = () => {
    if (id != "") {
      let i = 0;
      while (i != fetchedData.length) {
        if (fetchedData[i].id == id) {
          setData({
            first_name: fetchedData[i].first_name,
            last_name: fetchedData[i].last_name,
            email: fetchedData[i].primary_email,
            note: "",
          });
          addToast("Data filled succesfully", { appearance: "success" });
        }
        // else if(i==fetchedData.length-1&&fetchedData[i].id!=id){
        //   swal({
        //     title: "Oops!",
        //     text: 'invalid id',
        //     icon: "error",
        //     buttons: false,
        //   });
        // }
        i++;
      }
    } else {
      setData({ ...data, first_name: "", last_name: "", email: "", note: "" });
      addToast("Empty field, please write correct linguist id", {
        appearance: "error",
      });
    }
  };

  const handleSubmit = async (e) => {
    const token = Cookies.get("token");

    const sendData = {
      first_name: data.first_name || "-",
      last_name: data.last_name || "-",
      email: data.email || "-",
      note: data.note || "-",
    };
    try {
      const response = await defaultAxios.post(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/send-form/pregistration`,
        sendData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `Email succesfully sent`,
        icon: "success",
        buttons: false,
      });
      setData({ ...data, first_name: "", last_name: "", email: "", note: "" });
      // addToast("Email succesfully sent", { appearance: 'success' })
    } catch (error) {
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className={classes.form}
      noValidate
      autoComplete="off"
    >
      <div className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Enter linguist id"
          onChange={(e) => setId(e.target.value)}
          // onChange={(e) => setText(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Button
          className={classes.button}
          color="purple"
          onClick={() => handleFind()}
        >
          Autofill
        </Button>
      </div>
      <CustomInputSingle
        onChange={handleChange}
        label="First Name(s)"
        name="first_name"
        value={data.first_name}
        width="100%"
      />

      <CustomInputSingle
        onChange={handleChange}
        label="Last Name"
        name="last_name"
        value={data.last_name}
        width="100%"
      />

      <CustomInputSingle
        onChange={handleEmailChange}
        label="Email"
        name="email"
        value={data.email}
        width="100%"
      />
      {notValid && (
        <small style={{ color: "red" }}>enter valid email address</small>
      )}
      <CustomInputSingle
        onChange={handleChange}
        label="Note"
        name="note"
        value={data.note}
        width="100%"
      />

      <div className={classe.buttonFlex}>
        <CustomButton disabled={isAllow} text="Send" onClick={handleSubmit} />
      </div>
    </form>
  );
};

export default FormOne;
