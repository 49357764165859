import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import { Button } from "semantic-ui-react";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";

const Action = ({
  id,
  load,
  accept,
  cancel,
  timesheet,
  timesheetID,
  invoice,
  invoiceID,
  cacTimesheet,
  linTimesheet,
  source,
  target,
  linID,
}) => {
  const token = Cookies.get("CACtoken");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cancelRequest = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/cancel-booking/bookingID/${id}`, "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Cancel request sent to admin!!!`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCancel = async (e, id) => {
    swal(
      `This will send cancellation request to the Admin Team. You will hear from them if this job has been cancelled. Are you sure you want to send cancellation request for this assignment?`,
      {
        buttons: {
          cancel: true,
          catch: {
            text: "Yes!",
            value: "catch",
          },
        },
      }
    ).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          cancelRequest(value);

        default:
          break;
      }
    });
  };

  //amend job
  const handleAmendJob = async (e, id) => {
    history.push(`/cash-client/amend-job/${id}`);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {accept ? (
          <MenuItem
            onClick={() =>
              history.push(
                `/cash-client/protected/public/profile/${source}/${target}/${linID}`
              )
            }
          >
            Request Linguist Details
          </MenuItem>
        ) : null}
        {accept && !timesheet ? (
          <MenuItem
            onClick={() =>
              history.push(`/cash-client/create-close-and-timesheet/${id}`)
            }
          >
            Closing and Timesheet
          </MenuItem>
        ) : null}

        {linTimesheet && !cacTimesheet ? (
          <MenuItem
            onClick={() =>
              history.push(`/cash-client/confirm-close-and-timesheet/${id}`)
            }
          >
            Confirm Timesheet
          </MenuItem>
        ) : null}
        {!linTimesheet && cacTimesheet && (
          <MenuItem
            onClick={() => history.push(`/cash-client/amend-timesheet/${timesheetID}`)}
          >
            Amend Timesheet
          </MenuItem>
        )}
        {timesheet ? (
          <MenuItem
            onClick={() =>
              history.push(`/cash-client/view-timesheet/${timesheetID}`)
            }
          >
            View Timesheet
          </MenuItem>
        ) : null}

        {invoice ? (
          <MenuItem
            onClick={() =>
              history.push(`/cash-client/view-invoice/${invoiceID}`)
            }
          >
            View Invoice
          </MenuItem>
        ) : null}

        {!timesheet && !cancel ? (
          <MenuItem onClick={handleCancel}>Cancel</MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default Action;
