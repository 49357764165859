import React, {  } from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from "react-router-dom";

import ViewBACCanceledAssignments from "components/Employee/BookingPanel/TranslationN/Canceled/BAC/List";
import TranslationtOptions from "components/Employee/BookingPanel/TranslationN/BACTranslationOptions";
import Options from "components/Employee/BookingPanel/TranslationN/Canceled/Options";

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const { data, type } = useParams();


  return (
    <EmployeeLayout>
      <Employee>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
                <p className={classes.cardTitleWhite}>Canceled Assignments (BAC)</p>
                <div style={{ display: 'flex' }}>
                  <Options />
                  <IconButton color='inherit' style={{ marginLeft: 5 }} onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
                </div>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TranslationtOptions />
              </CardHeader>
            </Card>

            <Card>
              <CardBody>

                <ViewBACCanceledAssignments translationData={type === "translation" && data}/>

              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>
      </Employee>
    </EmployeeLayout>
  );
};

export default AssignmentPage;
