import React, { useState } from "react";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import { Button } from "@material-ui/core";
import CustomInputSingle from "components/Common/CustomInputSingle";
import CustomPassword from "components/Common/CustomPassword";
import Axios from "axios";
import swal from "sweetalert";
import history from "utils/history";
import CustomPhone from "components/Common/CustomPhone";
import CustomCaptcha from "components/Common/CustomCaptcha";
import { Header } from "semantic-ui-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Divider } from "@material-ui/core";
const VerifyBAC = () => {
  const classes = useEmployeeStyles();
  const [clientPasscode, setClientPasscode] = useState();
  const [clientID, setClientID] = useState();
  
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);

  const handlePassCodeChange = (e) => {
    const { value } = e.target;
    setClientPasscode(value);
  };
  const handleClientIDChange = (e) => {
    const { value } = e.target;
    setClientID(value);
  };
 
  const handlePRCLogin = async (e) => {
    e.preventDefault();
    const sendData = {
      client_id: clientID,
      passNumber: clientPasscode,
    };
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/find-PRC-details`,
        sendData
      );
      const { PostalCode, id, postedBy, LastName } = response?.data;
      history.push(
        `/interpreting-or-translation/prc-select-form/${postedBy?._id}/${id}/${PostalCode}/${LastName}`
      );
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.error) {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      } else {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `Please check your internet connection.`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const isAllow = !clientID || !clientPasscode;
  return (
    <Card>
      <CardBody>
        <Header style={{ margin: "0" }} as="h3" textAlign="center">
          Private Client (PRC) Verification
        </Header>
        <Divider orientation="horizontal" style={{ margin: 10 }} />
        <form className={classes.form} noValidate autoComplete="off">
          <CustomInputSingle
            onChange={handleClientIDChange}
            value={clientID}
            name="clientID"
            label="Your Private Client registered Client ID"
            width="100%"
            required
            placeholder="e.g. PRC1000..."
          />

          <CustomPassword
            onChange={handlePassCodeChange}
            value={clientPasscode}
            name="clientPasscode"
            label="Your passcode or passnumber"
            width="100%"
            required
            placeholder="Please enter your pass number or code here"
          />
    
          <CustomCaptcha onChange={(value) => setValid(value)} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: 10,
            }}
          >
            <div className={classes.buttonDiv}>
              <Button
                color="primary"
                onClick={handlePRCLogin}
                disabled={isAllow || loading || !valid}
                variant="contained"
              >
                {loading ? "Verifying..." : "Verify"}
              </Button>
            </div>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};
export default VerifyBAC;
