import React from "react";
import { useDispatch } from "react-redux";

// Material Components
import Typography from "@material-ui/core/Typography";

// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomSelect from "components/Common/CustomSelect";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

// Others
import { PAYMENT_TYPE } from "data";
import { updateTransStep } from "redux/actions/transAction";

const FormThree = () => {
  const dispatch = useDispatch();

  const classes = useFormStyles();

  const handleBack = () => {
    dispatch(updateTransStep(1));
  };

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomSelect label="Payment" data={PAYMENT_TYPE} width="98%" />

        <div className={classes.tc}>
          <Typography variant="h6" gutterBottom>
            T&C agree
          </Typography>
          <Typography variant="body2" gutterBottom>
            Thank you for placing your translation order with us. We are on
            process of assigning a linguist your assignment, once linguist
            allocated we will send you an estimated date of completion..... any
            additional amount will have to be paid before receiving the work...
            f you have ordered certified copy ... we will ask you to pay for
            post... We have received your order ....
          </Typography>

        </div>
        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
          />
          <CustomButton text="Submit" />
        </div>
      </form>
    </div>
  );
};

export default FormThree;
