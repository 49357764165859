import React, { useState } from "react";

import { Button, Header, Icon, Input } from "semantic-ui-react";

import swal from "sweetalert";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { changePPLInput } from "redux/actions/updatePPLAction";
import CustomSnackBar from "components/Common/CustomSnackBar";
import { Checkbox } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";

const About = () => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [approvalLoader, setApprovalLoader] = useState(false);
  const [edit, setEdit] = useState({
    value: false,
    loader: false,
  });
  const [calculate, setCalculate] = useState({
    sendButton: false,
    loading: false,
  });
  const [approval, setApproval] = useState(false);

  const token = Cookies.get("LINtoken");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.updatePPLReducer);
  const [update, setUpdate] = useState(false);
  const handleEdit = (e) => {
    setEdit({ ...edit, value: true });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changePPLInput({
        name,
        value,
      })
    );
  };

  const [formats, setFormats] = React.useState(() => ["phone"]);

  const updateAbout = async (e) => {
    setCalculate({ ...calculate, loading: true });
    setSubmitLoader(true);
    try {
      const reqBody = {
        about: data.about,
        public_professional_membership: data.public_professional_membership,
        professional_qualification: data.professional_qualification,
        public_pst: data.public_pst,
        public_psi: data.public_psi,
        public_profile_position: data.public_profile_position,
        Other_position: data.Other_position,
      };
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-public-profile`,
        reqBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUpdate(true);
      setSubmitLoader(false);
      setCalculate({ ...calculate, loading: false, sendButton: true });
    } catch (error) {
      console.log(error);
    }
  };

  const sendApproval = async (e) => {
    setApprovalLoader(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/send-public-profile-approval-request`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
      setApproval(true);
      setApprovalLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateWorkexperience = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/allow-public-profile-work-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const hideWorkexperience = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/hide-public-profile-work-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePublicProfile = (value) => {
    if (data.verified) {
      if (!data.work_experience_section) {
        swal(
          "Work experience section of your profile will be public. Are you sure you want to make this section of your profile public?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              updateWorkexperience(value);

            default:
              break;
          }
        });
      } else {
        swal(
          "Are you sure you want to revoke public view permission for this section?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              hideWorkexperience(value);

            default:
              break;
          }
        });
      }
    }
  };

  return (
    <div>
      <CustomSnackBar
        message="Successfully updated! Send approval request to admin"
        handleClose={() => setUpdate(false)}
        openSnackBar={update}
      />
      <CustomSnackBar
        message="Public profile sent for approval"
        handleClose={() => setApproval(false)}
        openSnackBar={approval}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header color="purple" as="h2">
          Work experience
        </Header>
        <Checkbox
          toggle
          checked={data?.work_experience_section}
          onChange={() => handlePublicProfile("work_experience_section")}
        />
      </div>
      <Header as="h4">
        Total hours of public service interpreting (PSI) experience:
      </Header>
      <TextField
        label="Only number of hours"
        name="public_psi"
        value={data.public_psi}
        onChange={handleChange}
        type={"number"}
        focus
        style={{ width: "40%" }}
        variant="outlined"
        placeholder="Only number of hours"
      />

      <Header as="h4">Number words translated from source language(s):</Header>
      <TextField
        label="Only number of words"
        name="public_pst"
        value={data.public_pst}
        onChange={handleChange}
        type={"number"}
        focus
        style={{ width: "40%" }}
        variant="outlined"
        placeholder="Only number of words"
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        {!calculate.sendButton && (
          <Button
            style={{ display: "block", marginTop: "1rem" }}
            onClick={updateAbout}
            loading={calculate.loading}
            color="purple"
            basic
          >
            Save and Update
          </Button>
        )}
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={sendApproval}
          disabled={!calculate.sendButton}
          loading={approvalLoader}
          color="purple"
        >
          Submit to admin for publication
        </Button>
      </div>
    </div>
  );
};

export default About;
