import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SendIcon from "@material-ui/icons/Send";
import { useMediaQuery } from "@material-ui/core";
import { Fragment } from "react";

const ULOptions = () => {
  const history = useHistory();
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <div>
      {matches ? (
        <>
          {" "}
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/admin/interpreting-invoice")}
          >
            Interpreting Assignment Invoices
          </Button>
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/admin/translation-invoice")}
          >
            Translation Assignment Invoices
          </Button>
          <Button
            startIcon={<SendIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/admin/quote-invoice")}
          >
            Quote Invoices
          </Button>
          <Button
            startIcon={<SendIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() => history.push("/admin/prc-direct-invoices")}
          >
            PRC Direct Invoices
          </Button>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {" "}
          <Button
            startIcon={<PersonAddIcon />}
            color="primary"
            variant="contained"
            style={{ width: "30%", marginLeft: "15px", marginRight: "15px" }}
            onClick={() => history.push("/admin/interpreting-invoice")}
          >
            Interpreting Assignment Invoices
          </Button>
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "30%", marginRight: "15px" }}
            onClick={() => history.push("/admin/translation-invoice")}
          >
            Translation Assignment Invoices
          </Button>
          <Button
            startIcon={<SendIcon />}
            color="primary"
            variant="contained"
            style={{ width: "30%", marginRight: "15px" }}
            onClick={() => history.push("/admin/quote-invoice")}
          >
            Quote Invoices
          </Button>
          <Button
            startIcon={<SendIcon />}
            color="primary"
            variant="contained"
            style={{ width: "30%", marginRight: "15px" }}
            onClick={() => history.push("/admin/prc-direct-invoices")}
          >
            PRC Direct Invoices
          </Button>
        </div>
      )}
    </div>
  );
};

export default ULOptions;
