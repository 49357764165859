const { FIND_CLIENT_BILL, SHOW_CLIENT_BILLS } = require("redux/actions/actionTypes");

const INITIAL_STATE = {
    findBill : false,
    showBills : false
};


const billsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case FIND_CLIENT_BILL:
            return {
                findBill: payload,
            };

        case SHOW_CLIENT_BILLS:
            return {
                showBills: payload,
            };
        
        default:
            return state
    }
}

export default billsReducer
