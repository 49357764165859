import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// Custom Styles
import useCardBodyStyles from "assets/jss/components/cardBodyStyle";

const CardBody = (props) => {
  const classes = useCardBodyStyles();
  const { className, children, plain, profile, min100, ...rest } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.min100]: min100,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    [className]: className !== undefined,
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
};

CardBody.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  children: PropTypes.node,
};

export default CardBody;
