import TikBox from "@material-ui/core/Checkbox";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Material imports
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "semantic-ui-react";

import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
// Icons
import { changeCrInput } from "redux/actions/crAction";

import { Header, Button, Modal, Form } from "semantic-ui-react";
import { SECURITY_CLEARANCE_LEVEL } from "data";
import CustomSelect from "components/Common/CustomSelect";
import Action from "./Action";

function ProfMemberShip({ checkData }) {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.crReducer);
  const [values, setValues] = useState({ reload: false });
  const token = Cookies.get("LINtoken");
  const fileInput = useRef(null);
  const { security_clearance, expiry_date } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputData = {
      name,
      value: value,
    };
    dispatch(changeCrInput(inputData));
  };

  const handleSave = async () => {
    let formData = new FormData();
    formData.append("security_clearance", security_clearance || "-");
    formData.append("expiry_date", expiry_date || "-");

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-public-profile-security_clearance`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        title: "Success!",
        text: `Successfully added`,
        icon: "success",
        buttons: "Ok",
      }).then(function () {
        window.location = "manage-public-profile";
      });
    } catch (error) {
      console.log(error.response?.data);
      swal({
        title: "Oops!",
        text: `${error?.response?.data?.error}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const loadList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/security_clearance-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setValues(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    loadList();
  }, []);

  const [state, setState] = useState({
    checkedA: false,
    checkedB: false,
    checkedF: false,
    checkedG: false,
    checkedH: false,
    other: false,
    added: false,
  });

  const [otherModal, setOtherModal] = useState(false);

  const handleOther = () => {
    setOtherModal(true);
  };

  const handleClose = () => {
    setOtherModal(false);
  };

  const updateSecuritySection = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/allow-public-profile-security-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const hideSecuritySection = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/hide-public-profile-security-section`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      }).then(function () {
        window.location = "/linguist/manage-public-profile";
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handlePublicProfile = (value) => {
    if (checkData.verified) {
      if (!checkData.security_clearance_section) {
        swal(
          "Security clearance section of your profile will be public. Are you sure you want to make this section of your profile public?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              updateSecuritySection(value);

            default:
              break;
          }
        });
      } else {
        swal(
          "Are you sure you want to revoke public view permission for this section?",
          {
            dangerMode: true,
            buttons: {
              cancel: true,
              catch: {
                text: "Yes!",
                value: "catch",
              },
            },
          }
        ).then((type) => {
          switch (type) {
            case "cancel":
              break;

            case "catch":
              hideSecuritySection(value);

            default:
              break;
          }
        });
      }
    }
  };

  return (
    <div>
      <Modal size="mini" onClose={handleClose} open={otherModal}>
        <Modal.Header>Adding Security Clearance Details</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Field>
                <label>Security Clearance</label>
                <CustomSelect
                  onChange={handleChange}
                  width="100%"
                  label="security clearance(s)"
                  name="security_clearance"
                  data={SECURITY_CLEARANCE_LEVEL}
                  value={security_clearance}
                  placeholder="security clearance(s)"
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Expiry Date</label>
                <input
                  name="expiry_date"
                  onChange={handleChange}
                  value={expiry_date}
                  placeholder="e.g. 01/Jan/2021"
                  required
                />
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            content="Add"
            labelPosition="right"
            icon="checkmark"
            onClick={handleSave}
            disabled={!security_clearance || !expiry_date}
            positive
          />
        </Modal.Actions>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header color="purple" as="h2">
          Security Clearance/s
        </Header>
        <Checkbox
          toggle
          checked={checkData?.security_clearance_section}
          onChange={() => handlePublicProfile("security_clearance_section")}
        />
      </div>{" "}
      <br />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          marginBottom: "20px",
        }}
      >
        {values?.length > 0 ? (
          <>
            {values?.map((list) => (
              <>
                {" "}
                {list.security_clearance ? (
                  <div
                    style={{
                      display: "block",
                      margin: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Header as="h5">
                      {list.security_clearance} <br /> {list.expiry_date}
                    </Header>
                    <div>
                      <Action id={list?._id} load={loadList} />
                    </div>
                  </div>
                ) : null}
              </>
            ))}{" "}
          </>
        ) : null}
      </div>
      <FormControlLabel
        control={
          <TikBox
            onChange={handleOther}
            name="other"
            checked={state.other}
            color="primary"
          />
        }
        label="Add Security Clearance/s"
      />
    </div>
  );
}

export default ProfMemberShip;
