import React from "react";

// Mateial Components
import Tooltip from "@material-ui/core/Tooltip";

// Material Icons
import InfoIcon from "@material-ui/icons/Info";

// Custom Components
import CustomTooltipTitle from "./CustomTooltipTitle";

// Custom Styles
import useCustomLabelStyles from "assets/jss/components/auth/customLabelStyle";

const CustomLabel = ({ label, moreInfo, required }) => {
  const classes = useCustomLabelStyles();

  return (
    <div className={classes.root}>
      <p className={classes.label}>
        {label} {required ? " *" : null}
      </p>
      {moreInfo ? (
        <Tooltip
          title={<CustomTooltipTitle text={moreInfo} />}
          placement="top"
          className={classes.toolTip}
        >
          <InfoIcon className="svg-icon" />
        </Tooltip>
      ) : null}
    </div>
  );
};

export default CustomLabel;
