import React from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import { useHistory } from 'react-router-dom';

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CashClient from "components/Admin/CrmPanel/CAC/List/List";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

const ViewCashClient = () => {
  const history = useHistory()
  const classes = useDashboardStyles();

  return (
    <Manager>
      <MasterLayout>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <p className={classes.cardTitleWhite}>View Cash Clients</p>
                <i className="icon list arrow left"
                  onClick={() => {
                    history.goBack()
                  }}></i>
              </CardHeader>
              <CardBody>
                <CashClient />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </MasterLayout>
    </Manager>
  );
};

export default ViewCashClient;
