import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomInput from "components/Common/CustomInput";
import CustomRadio from "components/Common/CustomRadio";
import CustomSelect from "components/Common/CustomSelect";
import history from "utils/history";
import { useToasts } from "react-toast-notifications";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import {
  changeInput,
  updateProfileLinguistPageSeven,
} from "redux/actions/lcAction";
import { CR_RECORD, SECURITY_CLEARANCE_LEVEL, SIMPLE_QS } from "data";
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Table,
  Progress,
} from "semantic-ui-react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import swal from "sweetalert";

//Material icons
import Cookies from "js-cookie";
import Axios from "axios";

const useStyles = makeStyles({
  date: {
    marginTop: "15px",
  },
});

const PageSeven = ({ setReload }) => {
  const dateClass = useStyles();
  const { data } = useSelector((state) => state.lcReducer);
  const dispatch = useDispatch();
  const token = Cookies.get("LINtoken");
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const uploadRef1 = useRef();
  const uploadRef2 = useRef();
  const uploadRef3 = useRef();
  const uploadRef4 = useRef();
  const { addToast } = useToasts();
  const [loader, setLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [pageData, setPageData] = useState(false);
  const [linID, setLinID] = useState(false);
  const [state, setState] = useState({
    transactions: [],
  });
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [progress4, setProgress4] = useState(0);
  const [next, setNext] = useState(false);

  const getLinguistDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/get-details-page7`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        updateProfileLinguistPageSeven({
          id: response?.data._id,
          criminal_record: response?.data.criminal_record,
          criminal_record_details: response?.data.criminal_record_details,
          title_security_clearance_1: response?.data.title_security_clearance_1,
          title_security_clearance_2: response?.data.title_security_clearance_2,
          title_security_clearance_3: response?.data.title_security_clearance_3,
          title_security_clearance_4: response?.data.title_security_clearance_4,
          security_clearance: response?.data.security_clearance,
          security_certificate_expiry_date_1:
            response?.data.security_certificate_expiry_date_1,
          security_certificate_expiry_date_2:
            response?.data.security_certificate_expiry_date_2,
          security_certificate_expiry_date_3:
            response?.data.security_certificate_expiry_date_3,
          security_certificate_expiry_date_4:
            response?.data.security_certificate_expiry_date_4,
        })
      );
      setPageData(response);
      setLinID(response?.data._id);
      setLoader(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
      setLoader(false);
    }
  };

  const [viewSecurity1, setViewSecurity1] = useState(null);
  const SecurityClearance1 = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-security-clearence-document1`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewSecurity1(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  const [viewSecurity2, setViewSecurity2] = useState(null);
  const SecurityClearance2 = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-security-clearence-document2`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewSecurity2(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  const [viewSecurity3, setViewSecurity3] = useState(null);
  const SecurityClearance3 = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-security-clearence-document3`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewSecurity3(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  const [viewSecurity4, setViewSecurity4] = useState(null);
  const SecurityClearance4 = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-security-clearence-document4`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewSecurity4(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  useEffect(() => {
    getLinguistDetails();
    SecurityClearance1();
    SecurityClearance2();
    SecurityClearance3();
    SecurityClearance4();
  }, []);

  const [title_security_clearance_1, setTitleSecurityClearance_1] =
    useState("");
  const [title_security_clearance_2, setTitleSecurityClearance_2] =
    useState("");
  const [title_security_clearance_3, setTitleSecurityClearance_3] =
    useState("");
  const [title_security_clearance_4, setTitleSecurityClearance_4] =
    useState("");

  const [security_clearance_document_1, setSecurityClearanceDocument_1] =
    useState(null);
  const [security_clearance_document_2, setSecurityClearanceDocument_2] =
    useState(null);
  const [security_clearance_document_3, setSecurityClearanceDocument_3] =
    useState(null);
  const [security_clearance_document_4, setSecurityClearanceDocument_4] =
    useState(null);

  const [security_certificate_expiry_date_1, setExpiryDate_1] = useState(null);
  const [security_certificate_expiry_date_2, setExpiryDate_2] = useState(null);
  const [security_certificate_expiry_date_3, setExpiryDate_3] = useState(null);
  const [security_certificate_expiry_date_4, setExpiryDate_4] = useState(null);

  const { criminal_record, criminal_record_details, security_clearance } = data;

  const classes = useFormStyles();

  const handleSave = async (e) => {
    e.preventDefault();
    setSaveLoader(true);
    let fd = new FormData();

    fd.append("criminal_record", data.criminal_record || "");
    fd.append("security_clearance", data.security_clearance || "");
    fd.append("criminal_record_details", data.criminal_record_details || "");
    // //Documents
    // if (security_clearance_document_1?.size) {
    //   fd.append("security_clearance_document_1", security_clearance_document_1);
    // }
    // if (security_clearance_document_2?.size) {
    //   fd.append("security_clearance_document_2", security_clearance_document_2);
    // }
    // if (security_clearance_document_3?.size) {
    //   fd.append("security_clearance_document_3", security_clearance_document_3);
    // }
    // if (security_clearance_document_4?.size) {
    //   fd.append("security_clearance_document_4", security_clearance_document_4);
    // }

    //Title
    fd.append(
      "title_security_clearance_1",
      title_security_clearance_1 || data?.title_security_clearance_1 || ""
    );
    fd.append(
      "title_security_clearance_2",
      title_security_clearance_2 || data?.title_security_clearance_2 || ""
    );
    fd.append(
      "title_security_clearance_3",
      title_security_clearance_3 || data?.title_security_clearance_3 || ""
    );
    fd.append(
      "title_security_clearance_4",
      title_security_clearance_4 || data?.title_security_clearance_4 || ""
    );

    //Expiry date
    fd.append(
      "security_certificate_expiry_date_1",
      security_certificate_expiry_date_1 ||
        data?.security_certificate_expiry_date_1 ||
        ""
    );
    fd.append(
      "security_certificate_expiry_date_2",
      security_certificate_expiry_date_2 ||
        data?.security_certificate_expiry_date_2 ||
        ""
    );
    fd.append(
      "security_certificate_expiry_date_3",
      security_certificate_expiry_date_3 ||
        data?.security_certificate_expiry_date_3 ||
        ""
    );
    fd.append(
      "security_certificate_expiry_date_4",
      security_certificate_expiry_date_4 ||
        data?.security_certificate_expiry_date_4 ||
        ""
    );

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-full-registration`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((val) => {
        setReload(Math.random());
      });
      setSaveLoader(false);
      setNext(true);
    } catch (error) {
      console.log(error.response);
      setSaveLoader(false);
    }
  };

  const handleBack = () => {
    history.push("/linguist/full-registration-p6");
  };

  const handleNext = () => {
    history.push("/linguist/full-registration-p8");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInput({
        name,
        value,
      })
    );
  };

  const handleDoc1 = async (e) => {
    if (e.target?.files[0]?.size > 3000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 3mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      setSecurityClearanceDocument_1(e.target.files[0]);
      uploadDoc1(e.target.files[0]);
    }
  };

  const uploadDoc1 = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("security_clearance_document_1", file);
      fd.append(
        "title_security_clearance_1",
        title_security_clearance_1 || data?.title_security_clearance_1 || ""
      );
      //Expiry date
      fd.append(
        "security_certificate_expiry_date_1",
        security_certificate_expiry_date_1 ||
          data?.security_certificate_expiry_date_1 ||
          ""
      );
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-security-clearance-document-1`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress1(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      SecurityClearance1();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleDoc2 = async (e) => {
    if (e.target?.files[0]?.size > 3000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 3mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      setSecurityClearanceDocument_2(e.target.files[0]);
      uploadDoc2(e.target.files[0]);
    }
  };
  const uploadDoc2 = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("security_clearance_document_2", file);

      fd.append(
        "title_security_clearance_2",
        title_security_clearance_2 || data?.title_security_clearance_2 || ""
      );

      fd.append(
        "security_certificate_expiry_date_2",
        security_certificate_expiry_date_2 ||
          data?.security_certificate_expiry_date_2 ||
          ""
      );
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-security-clearance-document-2`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress2(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      SecurityClearance2();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };
  const handleDoc3 = async (e) => {
    if (e.target?.files[0]?.size > 3000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 3mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      setSecurityClearanceDocument_3(e.target.files[0]);
      uploadDoc3(e.target.files[0]);
    }
  };

  const uploadDoc3 = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("security_clearance_document_3", file);

      fd.append(
        "title_security_clearance_3",
        title_security_clearance_3 || data?.title_security_clearance_3 || ""
      );

      fd.append(
        "security_certificate_expiry_date_3",
        security_certificate_expiry_date_3 ||
          data?.security_certificate_expiry_date_3 ||
          ""
      );
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-security-clearance-document-3`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress3(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      SecurityClearance3();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleDoc4 = async (e) => {
    if (e.target?.files[0]?.size > 3000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 3mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      setSecurityClearanceDocument_4(e.target.files[0]);
      uploadDoc4(e.target.files[0]);
    }
  };

  const uploadDoc4 = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("security_clearance_document_4", file);

      fd.append(
        "title_security_clearance_4",
        title_security_clearance_4 || data?.title_security_clearance_4 || ""
      );

      fd.append(
        "security_certificate_expiry_date_4",
        security_certificate_expiry_date_4 ||
          data?.security_certificate_expiry_date_4 ||
          ""
      );
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-security-clearance-document-4`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress4(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      SecurityClearance4();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const isAllow =
    !criminal_record ||
    (criminal_record.includes("Yes") && !criminal_record_details) ||
    !security_clearance;
  // (security_clearance.includes("Yes") &&
  //   !security_certificate_expiry_date_1 &&
  //   !title_security_clearance_1);

  if (loader) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <Header as="h4">
          Do you have any criminal record and or pending police case against you
          in any country?{" "}
        </Header>
        <CustomSelect
          onChange={handleChange}
          width="100%"
          label="Do you have any criminal record?"
          name="criminal_record"
          value={criminal_record}
          data={CR_RECORD}
          required
        />
        {criminal_record?.includes("Yes") ? (
          <CustomInput
            onChange={handleChange}
            label="Other details"
            name="criminal_record_details"
            value={criminal_record_details}
            width="100%"
            required
          />
        ) : null}
        <Header as="h4">Do you have security / police clearance?*</Header>
        <CustomRadio
          onChange={handleChange}
          width="100%"
          label=" "
          name="security_clearance"
          value={security_clearance}
          data={SIMPLE_QS}
          // required
        />

        {security_clearance === "Yes" && (
          <Table>
            <Table.Header>
              <Table.Row style={{ textAlign: "center" }}>
                <Table.HeaderCell width="1">
                  List of security clearances (max 4)
                </Table.HeaderCell>

                <Table.HeaderCell width="1">
                  Security clearance expiry date
                </Table.HeaderCell>
                <Table.HeaderCell width="1">
                  Security clearance document{" "}
                  <b style={{ color: "red" }}>***</b>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {/* start first row and View Uploaded Document */}
            <Table.Row style={{ textAlign: "center" }}>
              <Table.Cell>
                <Header as="h4">
                  <CustomSelect
                    onChange={(e) =>
                      setTitleSecurityClearance_1(e.target.value)
                    }
                    width="100%"
                    displayEmpty={true}
                    name="title_security_clearance_1"
                    data={SECURITY_CLEARANCE_LEVEL}
                    variant="outlined"
                    value={title_security_clearance_1}
                    placeholder="List of your security clearance(s) *"
                  />
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  <TextField
                    type="date"
                    fullWidth
                    variant="outlined"
                    className={dateClass.date}
                    value={security_certificate_expiry_date_1}
                    onChange={(e) => setExpiryDate_1(e.target.value)}
                  />
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  <input
                    type="file"
                    name="security_clearance_document_1"
                    onChange={handleDoc1}
                    accept="application/pdf"
                  />
                </Header>
                {security_clearance_document_1?.name && (
                  <div>
                    <Header
                      style={{ margin: "0" }}
                      as="h5"
                    >{`${security_clearance_document_1?.lastModifiedDate?.toDateString()}`}</Header>
                    <Header style={{ margin: "0" }} as="h5">{`${(
                      security_clearance_document_1?.size / 1024
                    ).toFixed(2)}kb`}</Header>
                  </div>
                )}
                {progress1 > 0 && (
                  <Progress percent={progress1} indicating autoSuccess progress>
                    {" "}
                    Document Uploaded
                  </Progress>
                )}
              </Table.Cell>
            </Table.Row>
            {security_clearance === "Yes" &&
            data.title_security_clearance_1 &&
            data.security_certificate_expiry_date_1 ? (
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">{data.title_security_clearance_1}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4">
                    {moment(data.security_certificate_expiry_date_1).format(
                      "DD-MMM-YYYY"
                    )}
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4">
                    {viewSecurity1?.security_clearance_document_1_true && (
                      <a
                        href={`/linguist/linguist-view-security-clearence-1/${viewSecurity1?._id}`}
                      >
                        View document
                      </a>
                    )}
                  </Header>
                </Table.Cell>
              </Table.Row>
            ) : null}
            {/* end first row and View Uploaded Document */}

            {/* start second row and View Uploaded Document */}
            <Table.Row style={{ textAlign: "center" }}>
              <Table.Cell>
                <Header as="h4">
                  <CustomSelect
                    onChange={(e) =>
                      setTitleSecurityClearance_2(e.target.value)
                    }
                    width="100%"
                    displayEmpty={true}
                    name="title_security_clearance_2"
                    data={SECURITY_CLEARANCE_LEVEL}
                    variant="outlined"
                    value={title_security_clearance_2}
                    placeholder="List of your security clearance(s)"
                  />
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  <TextField
                    type="date"
                    fullWidth
                    variant="outlined"
                    className={dateClass.date}
                    value={security_certificate_expiry_date_2}
                    onChange={(e) => setExpiryDate_2(e.target.value)}
                  />
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  <input
                    type="file"
                    name="security_clearance_document_2"
                    onChange={handleDoc2}
                    accept="application/pdf"
                  />
                </Header>
                {security_clearance_document_2?.name && (
                  <div>
                    <Header
                      style={{ margin: "0" }}
                      as="h5"
                    >{`${security_clearance_document_2?.lastModifiedDate?.toDateString()}`}</Header>
                    <Header style={{ margin: "0" }} as="h5">{`${(
                      security_clearance_document_2?.size / 1024
                    ).toFixed(2)}kb`}</Header>
                  </div>
                )}
                {progress2 > 0 && (
                  <Progress percent={progress2} indicating autoSuccess progress>
                    {" "}
                    Document Uploaded
                  </Progress>
                )}
              </Table.Cell>
            </Table.Row>
            {security_clearance === "Yes" &&
            data.title_security_clearance_2 &&
            data.security_certificate_expiry_date_2 ? (
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">{data.title_security_clearance_2}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4">
                    {moment(data.security_certificate_expiry_date_2).format(
                      "DD-MMM-YYYY"
                    )}
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4">
                    {viewSecurity2?.security_clearance_document_2_true && (
                      <a
                        href={`/linguist/linguist-view-security-clearence-2/${viewSecurity2?._id}`}
                      >
                        View document
                      </a>
                    )}
                  </Header>
                </Table.Cell>
              </Table.Row>
            ) : null}
            {/* end second row and View Uploaded Document */}

            {/* start third row and View Uploaded Document */}
            <Table.Row style={{ textAlign: "center" }}>
              <Table.Cell>
                <Header as="h4">
                  <CustomSelect
                    onChange={(e) =>
                      setTitleSecurityClearance_3(e.target.value)
                    }
                    width="100%"
                    displayEmpty={true}
                    name="title_security_clearance_3"
                    data={SECURITY_CLEARANCE_LEVEL}
                    variant="outlined"
                    value={title_security_clearance_3}
                    placeholder="List of your security clearance(s)"
                  />
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  <TextField
                    type="date"
                    fullWidth
                    variant="outlined"
                    className={dateClass.date}
                    value={security_certificate_expiry_date_3}
                    onChange={(e) => setExpiryDate_3(e.target.value)}
                  />
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  <input
                    type="file"
                    name="security_clearance_document_3"
                    onChange={handleDoc3}
                    accept="application/pdf"
                  />
                </Header>
                {security_clearance_document_3?.name && (
                  <div>
                    <Header
                      style={{ margin: "0" }}
                      as="h5"
                    >{`${security_clearance_document_3?.lastModifiedDate?.toDateString()}`}</Header>
                    <Header style={{ margin: "0" }} as="h5">{`${(
                      security_clearance_document_3?.size / 1024
                    ).toFixed(2)}kb`}</Header>
                  </div>
                )}
                {progress3 > 0 && (
                  <Progress percent={progress3} indicating autoSuccess progress>
                    {" "}
                    Document Uploaded
                  </Progress>
                )}
              </Table.Cell>
            </Table.Row>
            {security_clearance === "Yes" &&
            data.title_security_clearance_3 &&
            data.security_certificate_expiry_date_3 ? (
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">{data.title_security_clearance_3}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4">
                    {moment(data.security_certificate_expiry_date_3).format(
                      "DD-MMM-YYYY"
                    )}
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4">
                    {viewSecurity3?.security_clearance_document_3_true && (
                      <a
                        href={`/linguist/linguist-view-security-clearence-3/${viewSecurity3?._id}`}
                      >
                        View document
                      </a>
                    )}
                  </Header>
                </Table.Cell>
              </Table.Row>
            ) : null}
            {/* end third row and View Uploaded Document */}

            {/* start fourth row and View Uploaded Document */}
            <Table.Row style={{ textAlign: "center" }}>
              <Table.Cell>
                <Header as="h4">
                  <CustomSelect
                    onChange={(e) =>
                      setTitleSecurityClearance_4(e.target.value)
                    }
                    width="100%"
                    displayEmpty={true}
                    name="title_security_clearance_4"
                    data={SECURITY_CLEARANCE_LEVEL}
                    variant="outlined"
                    value={title_security_clearance_4}
                    placeholder="List of your security clearance(s)"
                  />
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  <TextField
                    type="date"
                    fullWidth
                    variant="outlined"
                    className={dateClass.date}
                    value={security_certificate_expiry_date_4}
                    onChange={(e) => setExpiryDate_4(e.target.value)}
                  />
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  <input
                    type="file"
                    name="security_clearance_document_4"
                    onChange={handleDoc4}
                    accept="application/pdf"
                  />
                </Header>
                {security_clearance_document_4?.name && (
                  <div>
                    <Header
                      style={{ margin: "0" }}
                      as="h5"
                    >{`${security_clearance_document_4?.lastModifiedDate?.toDateString()}`}</Header>
                    <Header style={{ margin: "0" }} as="h5">{`${(
                      security_clearance_document_4?.size / 1024
                    ).toFixed(2)}kb`}</Header>
                  </div>
                )}
                {progress4 > 0 && (
                  <Progress percent={progress4} indicating autoSuccess progress>
                    {" "}
                    Document Uploaded
                  </Progress>
                )}
              </Table.Cell>
            </Table.Row>
            {security_clearance === "Yes" &&
            data.title_security_clearance_4 &&
            data.security_certificate_expiry_date_4 ? (
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">{data.title_security_clearance_4}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4">
                    {moment(data.security_certificate_expiry_date_4).format(
                      "DD-MMM-YYYY"
                    )}
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4">
                    {viewSecurity4?.security_clearance_document_4_true && (
                      <a
                        href={`/linguist/linguist-view-security-clearence-4/${viewSecurity4?._id}`}
                      >
                        View document
                      </a>
                    )}
                  </Header>
                </Table.Cell>
              </Table.Row>
            ) : null}
            {/* end fourth row and View Uploaded Document */}
          </Table>
        )}

        <div className={classes.buttonFlex} style={{ marginTop: "24px" }}>
          <Button basic color="purple" onClick={handleBack}>
            Back
          </Button>
          {!next ? (
            <Button
              disabled={isAllow}
              loading={saveLoader}
              color="purple"
              onClick={handleSave}
            >
              Save
            </Button>
          ) : (
            <Button color="green" onClick={handleNext}>
              Next
            </Button>
          )}
        </div>
        {/* } */}
      </form>
    </div>
  );
};

export default PageSeven;
