import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import CustomSelect from "components/Common/CustomSelect";
import CustomBigInput from "components/Common/CustomBigInput";
import CustomButton from "components/Common/CustomButton";
import CustomInput from "components/Common/CustomInput";
import CustomUpload from "components/Common/CustomUpload";
import CustomRadio from "components/Common/CustomRadio";
import Time from "./Time";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import moment from 'moment-timezone'

// Others
import {
  LANG_COMB,
  ITP_SERVICE_REQUIRED,
  TYPE_INTERPRETING,
  SKILL_LEVEL_REQUIRED,
  SECURITY_CLEARANCE_LEVEL,
  CAR_PARKING,
  CURRENT_COUNTRY,
  GENDER_DATA,
  TIMEZONE,
} from "data";

import { Button, Card, Checkbox, FormControlLabel } from "@material-ui/core";
//import CustomAutoComplete from "components/Common/CustomAutoComplete";
import { changeIBTInput } from "redux/actions/ibtAction";
//import CustomLabel from "components/Common/CustomLabel";
import CardBody from "components/Card/CardBody";
import { CenterDiv } from "components/AccountClient/BusinessProfile/style";

const FormTwo = () => {
  const { data } = useSelector(state => state.ibtReducer)
  const dispatch = useDispatch();

  const {
    selected_client,
    purchase_order_number,
    timezone,
    assignment_date,
    start_time,
    end_time,
    interpreting_service_required,
    house_number_street_name,
    address_line_two,
    county,
    post_code,
    country,
    current_nationality,
    other_nationalities,
    remote_address,
    source_language,
    target_language,
    linguist_skill_level,
    typesof_interpreting_required,
    job_description,
    preferred_interpreter,
    gender,
    security_clearance,
    car_parking,
    additional_info,
    scripts,
    agree
  }= data

  const classes = useFormStyles();

  const handleNext = () => {
    console.log(data)
  };

  const handleChange = e => {
    const { name, value, checked }= e.target
    dispatch(changeIBTInput({
        name,
        value: value || checked
    }))
  }

  const handleExpertise = value => {
    if (target_language.includes('R') || source_language.includes('R')) {
        return value.match(/(rare|Rare|Level 5|Level X |Level 1|Level 2)/g)
    } else {
        return value.match(/(Level 1|Level 2|Level 5|Level X |Level 3|Level 4:)/g)
    }
  }

  const handleAutoComplete = (e, value) => {
    dispatch(changeIBTInput({
      name: 'other_nationalities',
      value
    }))
  }

  const isAllow= 
    !assignment_date ||
    !start_time ||
    !end_time ||
    !interpreting_service_required ||
    (interpreting_service_required.includes('FTP') && !house_number_street_name) ||
    (interpreting_service_required.includes('FTP') && !county) ||
    (interpreting_service_required.includes('FTP') && !post_code) ||
    (interpreting_service_required.includes('FTP') && !country) ||
    (interpreting_service_required.includes('FTP') && !current_nationality) ||
    (interpreting_service_required.includes('Remote') && !remote_address) ||
    !source_language ||
    !target_language ||
    !linguist_skill_level ||
    !interpreting_service_required ||
    !typesof_interpreting_required ||
    !job_description ||
    !agree

  return (
    <div>

        <Card raised style= {{marginTop: '24px'}} >
          <CardBody>
              <h4 style={{textAlign: "center"}}>Booking Interpreting </h4>
          </CardBody>
        </Card>
        <Card raised style= {{marginTop: '24px', padding: '30px'}} >
          <CardBody>
            <h4 style={{textAlign: "center"}}>Client Information </h4>
            <p>Name: </p>
            <p>House number / Street number: </p>
            <p>Address: </p>
            <p>Town / City: </p>
            <p>Zip code / Postal code: </p>
            <p>Country / Region: </p>
            <p>Country: </p>
            
          </CardBody>
        </Card>
        <Card raised style= {{marginTop: '24px', padding: '30px'}} >
          <CardBody>
            <h4 style={{textAlign: "center"}}>Booking Details</h4>
            <p>Booking reference number or purchase order number: </p>
            <p>Timezone: </p>
            <p>Date & Time: </p>
            <p>Interpreting Service: </p>
            <p>Remote / Telephone Details: </p>
            <p>House number / Street number: </p>
            <p>Address: </p>
            <p>Town / City: </p>
            <p>Zip code / Postal code: </p>
            <p>Country / Region: </p>
            <p>Country: </p>
            
          </CardBody>
        </Card>
        <Card raised style= {{marginTop: '24px', padding: '30px'}} >
          <CardBody>
            <p>Source Language: </p>
            <p>Target Language: </p>
            <p>Linguists skill level: </p>
            <p>Interpreting Service: </p>
            <p>Types of Interpreting: </p>
            <p>Job Description: </p>
            <p>Preferred Linguist / Service Provider ID: </p>
            <p>Gender: </p>
            <p>Security clearence level: </p>
            <p>Car parking for linguist: </p>
            <p>Additional Information or direction for linguist: </p>
            
          </CardBody>
        </Card>
        <Card raised style= {{marginTop: '24px'}} >
          <CardBody>
            View Document
          </CardBody>
        </Card>
      

        <div className={classes.buttonFlex}>
          <Button style= {{marginTop: '2rem', marginRight: '15px'}} variant= 'contained' color= 'primary'>View Linguists</Button>
          <CustomButton text="Send Note" onClick={handleNext} />
          <CustomButton text="Cancel" onClick={handleNext} />
        </div>
  
    </div>
  );
};

export default FormTwo;
