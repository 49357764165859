import React, { useEffect, useState } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import moment from "moment";
import Axios from "axios";

const ViewContactCard = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useDashboardStyles();
  const [dateTime, setDateTime] = useState(null);

  const LoadDateTime = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/view-document-datetime-linguist/docID/${id}`
      );

      setDateTime(response?.data);
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    LoadDateTime();
  }, []);

  return (
    <Manager>
      <MasterLayout>
        <Card>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            color="primary"
          >
            <p className={classes.cardTitleWhite}>
              Security Clearence Document -{" "}
              {moment(dateTime?.createdAt).format("DD-MMM-YYYY : HH:mm")}
            </p>
            <IconButton color="inherit" onClick={() => history.goBack()}>
              <ArrowBackIcon />
            </IconButton>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center" className={classes.container}>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                  <object
                    data={`${process.env.REACT_APP_BACKEND_API}/for-linguists-manager/view-security-clearence-document1/docID/${id}`}
                    type="application/pdf"
                    width="100%"
                    height="1000px"
                  ></object>
                </Card>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </MasterLayout>
    </Manager>
  );
};

export default ViewContactCard;
