import React, { useEffect, useRef, useState } from "react";
import Items from "./TableBody";

//Style
import "./style.css";
import Axios from "utils/axios";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Rating } from "@material-ui/lab";
import CustomTextInput from "components/Common/CustomTextInput";
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import TimesheetFooter from "./ItemsTwo";
import { useDispatch, useSelector } from "react-redux";
import CustomSnackBar from "components/Common/CustomSnackBar";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  changeTimesheetInput,
  updateTimesheet,
} from "redux/actions/timesheetAction";

const Details = () => {
  const { id } = useParams();
  const token = Cookies.get("BACtoken");
  const [details, setDetails] = useState(null);
  const { data } = useSelector((state) => state.timesheetReducer);
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [checked, setChecked] = useState(false);
  const [linguist, setLinguist] = useState([]);
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const [submit, setSubmit] = useState({
    toast: false,
    loader: false,
  });

  const {
    start_date,
    end_date,
    feedbackByClient,
    note,
    duration,
    start_time,
    signatureByBAC,
  } = data;

  const getClientDetails = async () => {
    try {
      const response = await Axios.get(
        "/api/accountclient/dashboard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLinguist(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getClientDetails();
   
    const getAssignmentDetails = async () => {
      try {
        const response = await Axios.get(
          `/api/accountclient/booking-view/bookingID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDetails(response?.data);
        dispatch(
          updateTimesheet({
            start_date: response?.data.data.startdateArray,
            end_date: response?.data.data.enddateArray,
            end_time: response?.data.data.endtimeArray,
            start_time: response?.data.data.starttimeArray,
            duration: response?.data.data.gethour,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    };

    getAssignmentDetails();

  }, []);

  const handleSubmit = async () => {
    setSubmit({ ...submit, loader: true });
    const file = new File([signatureByBAC], "signatureByBAC", {
      type: "image/png",
    });
    const fd = new FormData();
    fd.append("signatureByBAC", file);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("duration", duration || details?.gethour);
    fd.append("feedbackByClient", feedbackByClient);
    fd.append("note", note);
    fd.append("start_time", start_time);

    if (uploadTimesheet?.size) {
      fd.append("documentByClient", uploadTimesheet);
    }

    try {
      const response = await Axios.post(
        `/api/accountclient/make-close-timesheet/bookingID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubmit({ ...submit, loader: false, toast: true });
      window.location = "/account-client/confirmed-interpreters";
    } catch (error) {
      setSubmit({ ...submit, loader: false });
      console.log(error);
    }
  };

  const handleViewPdf = () => {
    window.html2canvas = html2canvas;
    var doc = new jsPDF({
      orientation: "potrait",
      unit: "pt",
      size: "A4",
    });
    var content = document.getElementById("printArea");

    doc.html(content, {
      callback: function (doc) {
        // doc.save('myDocument.pdf');
        window.open(doc.output("bloburl"));
      },
    });
  };

  return details ? (
    <div>
      <div id="printArea" style={{ width: "100%", marginTop: "24px" }}>
        <CustomSnackBar
          message="Timesheet submitted to linguist"
          handleClose={() => setSubmit({ ...submit, toast: false })}
          openSnackBar={submit.toast}
        />
        <CustomTextInput label="Job Reference Number" value={details?.id} />

        <Items details={details} />

        <CustomTextInput
          label="Instructions for linguist professional"
          value=""
        />

        {/* Address details */}
        {details?.remote_address && (
          <CustomTextInput
            label="Remote Address"
            value={details?.remote_address}
          />
        )}
        {!details?.remote_address ? (
          <>
            <CustomTextInput
              label="House Number / Street Name"
              value={details?.house_number_street_name}
            />
            <CustomTextInput
              label="Address Line 2"
              value={details?.address_line_two}
            />
            <CustomTextInput label="County / Region" value={details?.region} />
            <CustomTextInput label="Post Code" value={details?.post_code} />
            <CustomTextInput label="Country" value={details?.country} />
          </>
        ) : null}
        <CustomTextInput
          label="Court case, police, NHS ref"
          value={details?.security_clearance}
        />

        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "1em" }}
        >
          <Header style={{ margin: "0" }} as="h4">
            Feedback to linguist
          </Header>
          <div>
            <Rating
              name="simple-controlled"
              value={feedbackByClient}
              onChange={(event, newValue) => {
                dispatch(
                  changeTimesheetInput({
                    name: "feedbackByClient",
                    value: newValue,
                  })
                );
              }}
            />
          </div>
        </div>

        <CustomTextInput
          label="Customer / Client Name"
          value={details?.postedBy?.FirstName + " " + details?.postedBy?.LastName}
        />
        <CustomTextInput
          label="Customer / Client ref no."
          value={details?.clientRef}
        />
        <CustomTextInput
          label="Emergency Contact no."
          value={details?.postedBy?.mobile_no}
        />
        <CustomTextInput label="Name of limited speaker conference" value="" />

        <TimesheetFooter
          checked={checked}
          setChecked={setChecked}
          linguist={linguist}
          details={details}
        />
      </div>

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button onClick={() => uploadRef.current.click()} basic color="purple">
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <div style={{ padding: "10px" }}>
          <Button
            onClick={handleViewPdf}
            style={{ backgroundColor: "purple", color: "white" }}
          >
            View PDF
          </Button>
        </div>
        <div style={{ padding: "10px" }}>
          <Button
            loading={submit.loader}
            disabled={
              submit.loader || !signatureByBAC || !feedbackByClient || !checked
            }
            onClick={handleSubmit}
            style={{ backgroundColor: "purple", color: "white" }}
          >
            Submit
          </Button>
          {/* {submit.loader && 
                <Dimmer>
                <Loader active inline /> 
                </Dimmer>} */}
        </div>
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
