import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetch from 'isomorphic-fetch';


// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterLayout from "layout/MasterLayout";
import CardHeader from "components/Card/CardHeader";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";

import { Button, Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import { useHistory } from 'react-router-dom';
import Moment from 'moment'

const ViewContactRequest = () => {


  const history = useHistory()
  const classes = useDashboardStyles();
  const { id } = useParams();

  const [values, setValues] = useState({
    // title: "mst",
    // FirstName: "kona", 
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_API}/manager-admin/view-account-client/${id}`)
      .then(res => res.json())
      .then((result) => {
        setValues(result)
      })
  }, [])


  return (
    <Manager>
      <MasterLayout>

        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader
            color="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={classes.cardTitleWhite}>Billing Account Client - {values.id}</p>
            <i className="icon list arrow left"
              onClick={() => {
                history.goBack()
              }}></i>
          </CardHeader>
        </GridItem>
        <br />
        <React.Fragment>

          <GridContainer justify="center" className={classes.container}>


            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <GridItem xs={12} sm={12} md={4} lg={12} xl={12}>
                <Card>
                  <CardBody alignItems="center">
                    <h4 style={{ textAlign: "center" }}>Client ID: {values.id}</h4>
                    <h4 style={{ textAlign: "center" }}>Joining Date: {Moment(values.createdAt).format('DD MMM YYYY - kk:mm:ss')}</h4>

                  </CardBody>
                </Card>
                <br />
                <Card>
                  <CardBody>
                    <GridContainer alignItems="center">
                      <GridItem xs={12} sm={12} md={6}>
                        <div className="left">
                          <img
                            src={`${process.env.REACT_APP_BACKEND_API}/manager-admin/photo-account-client/${id}`}
                            className="img img-fluid img-thumbnail mb-3"
                            style={{ maxHeight: 'auto', maxWidth: '50%' }}
                            alt="profile photo"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <div className="right">
                          <h4>Name: {values.title} {values.FirstName} {values.LastName}</h4>
                          <h4>Work Email: {values.email}</h4>
                          <h4>Mobile Number: {values.mobile_no}</h4>

                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>

                </Card>
                <br />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={12} xl={12}>
                <Card raised style={{ marginTop: '24px' }} >
                  <CardBody>
                    <h4 style={{ textAlign: "center" }}> Personal Information</h4>

                    <GridContainer alignItems="center">
                      <GridItem xs={12} sm={12} md={6}>
                        <div className="left">

                          <p>Title:</p>
                          <p>First Name: </p>
                          <p>Last Name: </p>
                          <p>Date Of Birth: </p>
                          <p>Gender: </p>
                          <p>House number / Street number: </p>
                          <p>Address: </p>
                          <p>Town / City: </p>
                          <p>Zip code / Postal code: </p>
                          <p>Country / Region: </p>
                          <p>Country: </p>
                          <p>Current Nationality: </p>
                          <p>Other Nationality: </p>
                          <p>Primary Email Address: </p>
                          <p>Mobile Number: </p>
                          <p>Direct line with extension no: </p>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <div className="right">
                          <p><input value={values.title}></input></p>
                          <p><input value={values.FirstName}></input></p>
                          <p>{values.LastName}</p>
                          <p><input value={values.Birthday}></input></p>
                          <p><input value={values.sex}></input></p>
                          <p><input value={values.AddressLine1}></input></p>
                          <p><input value={values.AddressLine2}></input></p>
                          <p><input value={values.City}></input></p>
                          <p><input value={values.PostalCode}></input></p>
                          <p><input value={values.Region}></input></p>
                          <p><input value={values.Country}></input></p>
                          <p><input value={values.Nationality}></input></p>
                          <p><input value={values.other_nationality}></input></p>
                          <p><input value={values.email}></input></p>
                          <p><input value={values.mobile_no}></input></p>
                          <p><input value={values.direct_line}></input></p>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
                <br />
              </GridItem>
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <Card>
                <CardBody alignItems="center">
                  <h4 style={{ textAlign: "center" }}> Business Information</h4>

                </CardBody>

                <CardBody>

                  <p>Business or organization name: <input value={values.business_name}></input></p>
                  <p>Position: <input value={values.position}></input></p>
                  <p>Business email address: <input value={values.business_email}></input></p>
                  <p>Business phone number: <input value={values.business_phone_no}></input></p>
                  <p>House number / Street number: <input value={values.business_address}></input></p>
                  <p>Address: <input value={values.business_address_2}></input></p>
                  <p>Town / City: <input value={values.business_town}></input></p>
                  <p>Zip code / Postal code: <input value={values.business_zipcode}></input></p>
                  <p>Country / Region: <input value={values.business_region}></input></p>
                  <p>Country: <input value={values.business_region}></input></p>
                  <p>Company registration number: <input value={values.company_registration_no}></input></p>
                  <p>Charity registration number: <input value={values.charity_registration_no}></input></p>
                  <p>Business website: <input value={values.business_website}></input></p>
                  <p>Additional Information or nore: <input value={values.note}></input></p>

                  <Button color="primary">Update</Button>

                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </React.Fragment>

      </MasterLayout>
    </Manager>
  );
};


export default ViewContactRequest;
