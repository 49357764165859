import React from "react";

// Material Core Components
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Material Icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import PageOne from "./PageOne";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import LandingPageLayout from "layout/LandingPageLayout";
import CustomAvatar from "components/Common/CustomAvatar";

const QuoteRqst = () => {
  const classes = useEmployeeStyles();

  return (
    <LandingPageLayout>
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    Initial Registration
                  </Typography>
                  <Typography variant="subtitle2"></Typography>
                </div>
                <PageOne />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </LandingPageLayout>
  );
};

export default QuoteRqst;
