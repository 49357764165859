// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import history from "utils/history";
const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const BookingOptions = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
     
      <Button
        variant="outlined"
        onClick={() => history.push("/admin/email-settings")}
      >
        Post Email Settings
      </Button>

      <Button
        variant="outlined"
        onClick={() =>
          history.push("/admin/email-settings/list-of-email-template")
        }
      >
        View Email Settings
      </Button>
    </div>
  );
};

export default BookingOptions;
