import React, { useState, useEffect } from "react";
import Manager from "components/RouteProtection/ManagerAdmin/Manager";
import MasterLayout from "layout/MasterLayout";

import { Dimmer, Header, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import Axios from "utils/axios";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import RefundList from "components/Admin/AccountFinance/Refund/RefundList";

const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const [finance_panel, setAdminAccess] = useState(null);

  const getLinguistDetails = async (e) => {
    const token = Cookies.get("token");
    const response = await Axios.get(
      "/api/manager-admin/dashboard",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAdminAccess(response?.data.finance_panel);
  };
  useEffect(() => {
    getLinguistDetails();
  }, []);

  return (
    <MasterLayout>
      <Manager>
        <GridContainer>
          {finance_panel ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader color="primary">
                  <p className={classes.cardTitleWhite}>
                    Overpaid Refund Requests (Estimate)
                  </p>
                </CardHeader>
                <CardBody>
                  <RefundList />
                </CardBody>
              </Card>
            </GridItem>
          ) : finance_panel === 0 ? (
            <Header as="h3">Access denied</Header>
          ) : (
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          )}
        </GridContainer>
      </Manager>
    </MasterLayout>
  );
};

export default AssignmentPage;
