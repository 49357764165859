import { useHistory } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CustomInput from "components/Common/CustomInput";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Icon, Modal } from 'semantic-ui-react'
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SecurityIcon from '@material-ui/icons/Security';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DescriptionIcon from '@material-ui/icons/Description';
import BusinessIcon from '@material-ui/icons/Business';
import Axios from "axios";
import Cookies from 'js-cookie'
import swal from 'sweetalert'



const ULOptions = ({ id }) => {
  const history = useHistory();
  const [paidModal, setPaidModal] = React.useState(false)
  const [pay, setPay] = useState({
    note: '',
  })

  const handlePaid = async (id) => {
    setPaidModal(true)
    setPay({ ...pay })
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get('LINtoken')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePaidClick = e => {
    setAnchorEl(false)
    handlePaid(id)
  }

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value })
  }
  const [values, setValues] = useState({
    loader: false
  });

  const handleConfirm = async (e) => {
    setValues({ ...values, loader: true })
    try {
      const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/linguists/send-reference-letter`, {
        note: pay.note,

      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setValues({ ...values, loader: false })
      swal({
        icon: 'success',
        text: 'Reference letter or proof of address successfully send',
        buttons: false
      })
      setPaidModal(false)
    } catch (error) {
      console.log(error.response)
    }
  }






  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'auto auto ' }}>

      <Modal
        open={paidModal}
        onClose={() => setPaidModal(false)}
      >
        <Modal.Header>Reference Letter</Modal.Header>
        <Modal.Content>
          <Modal.Description>

            <p>- Why do you need this reference / proof hour letter?</p>
            <p>- What details should be included?</p>
            <CustomInput name='note' onChange={handleChange} value={pay.note} label='Answere' edit={true} />

          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleConfirm} color='purple' disabled={values.loader}>
            Send <Icon name='chevron right' />
          </Button>

          {values.loader && <div class="ui active inline loader" style={{ backgroundColor: "purple" }}></div>}
        </Modal.Actions>
      </Modal>

      <Button
        startIcon={<PermIdentityIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-profile-photo-preview/${id}`)}
      >
        Preview Profile Photo
      </Button>
      <Button
        startIcon={<  BusinessIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-address-preview/${id}`)}
      >
        Preview Address Document
      </Button>
      <Button
        startIcon={<CreditCardIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-passport/${id}`)}
      >
        Passport or National ID
      </Button>
      <Button
        startIcon={< DescriptionIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-relevent-document/${id}`)}
      >
        Relevent Document
      </Button>

      <Button
        startIcon={< VerifiedUserIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-relevent-certificate/${id}`)}
      >
        Relevent Certificate
      </Button>

      <Button
        startIcon={<SecurityIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-security-clearence/${id}`)}
      >
        Security Clearence Document
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-cv/${id}`)}
      >
        CV
      </Button>



      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-insurance-document/${id}`)}
      >
        Insurance Document
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-reference-letter/${id}`)}
      >
        Previous work reference
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-interpreting-document/${id}`)}
      >
        Interpreting Qualifications Document
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-translation-document/${id}`)}
      >
        Translation Qualifications Document
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-psi-pst-document/${id}`)}
      >
        PSI / PST experience reference letter
      </Button>

      <Button
        startIcon={< AssignmentIndIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => history.push(`/linguist/linguist-any-other-document/${id}`)}
      >
        Interpreting or translation reference
      </Button>

      <Button
        startIcon={<AssignmentIcon />}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
        onClick={handlePaidClick}

      >
        Request reference / proof of hours

      </Button>

    </div>
  );
};

export default ULOptions;
