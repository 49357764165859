import React, { useState, Fragment, useEffect } from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SppoLayout from "layout/SppoLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Button } from "semantic-ui-react";
import Box from "@material-ui/core/Box";
import { Accordion, Header, Icon } from "semantic-ui-react";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";

const Help = () => {
  const classes = useDashboardStyles();
  const [activeIndex, setActiveIndex] = useState("0");
  const matches = useMediaQuery("(max-width:768px)");
  const [values, setValues] = useState([]);
  const [data, setData] = useState({
    question: "",
  });
  const [toturialList, setToturialList] = useState([]);
  const loadToturialsList = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/SPPO/get-tutorial`
      );
      setToturialList(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const token = Cookies.get("SPPOtoken");
  const { question } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    loadToturialsList();
    getAnswer();
  }, []);

  const getAnswer = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/sppo-question`
      );

      setValues(response?.data);
    } catch (error) {
      // Copy from other catch's code
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    try {
      const response = Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/ask-question`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        // title: "Success",
        text: `Question sent to the Booking Secretary / Admin Team.`,
        icon: "success",
        buttons: false,
      });
      setData({ ...data, question: "" });
    } catch (error) {
      // Copy from other catch's code
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <SppoLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Box pb={2}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>SPPO Tutorials</p>
              </CardHeader>
              <CardBody>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: matches ? "auto" : "30% 30% 30%",
                    justifyContent: matches ? null : "space-evenly",
                    alignItems: "end",
                  }}
                >
                  {toturialList.map((list) => (
                    <div
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <Header as="h4" textAlign="center">
                        {list.tutorial_name}
                      </Header>
                      <Button
                        onClick={() => window.open(list.tutorial_link)}
                        fluid
                        color="youtube"
                      >
                        <Icon name="youtube" /> Watch on youtube
                      </Button>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Box>

          <Box pb={2}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>FAQs</p>
              </CardHeader>

              <Accordion fluid styled>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  1. How to accept an assignment?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <p>
                    You can accept assignments from your main dashboard. Click
                    on Interpreting Assignment, Translation Assignment or Quote
                    and Other and from those offers you can accept, decline or
                    provide a quote.
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  2. How do I close an assignment?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <p>
                    After providing service, you will to go to current
                    assignment list and click on Action List to select 'Close
                    and Timesheet' or 'Close and Project Report', 5 days after
                    closing an assignment or it being accepted by a client, you
                    will then be able to issue an invoice.
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  3. How do I invoice a PRC?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <p>
                    You can do from your job list, or you can invoice using a
                    direct invoice to PRCs
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 3}
                  index={3}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  4. ID / Badge
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <object
                    data="/LC-Linguist-Badge-Id.pdf"
                    type="application/pdf"
                    width="100%"
                    height="1000px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  5. LC Dress Code
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <object
                    data="/LC-Dress-Code.pdf"
                    type="application/pdf"
                    width="100%"
                    height="1000px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 5}
                  index={5}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  6. Modern Slavery
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  <object
                    data="/LC-Antislavery.pdf"
                    type="application/pdf"
                    width="100%"
                    height="1000px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 6}
                  index={6}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  7. Trust & Authority
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                  <object
                    data="/LC-Trust-Authority.pdf"
                    type="application/pdf"
                    width="100%"
                    height="1000px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 7}
                  index={7}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  8. GDPR and Data Protection Laws
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 7}>
                  <object
                    data="/LC-GDPR and Data Protection Laws.pdf"
                    type="application/pdf"
                    width="100%"
                    height="1000px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 8}
                  index={8}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  9. Who is who and what expertise do they have?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 8}>
                  <p>
                    Know your linguist’s title, qualification, experience and
                    their relevant skills.
                    <br />
                    You can select the best suitable linguist needed for your
                    assignment.
                  </p>
                  <object
                    data="/WhoIsWho.pdf"
                    type="application/pdf"
                    width="100%"
                    height="1000px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 9}
                  index={9}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  10. Manual Timesheet
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 9}>
                  <object
                    data="/Linguists Collective time sheet.pdf"
                    type="application/pdf"
                    width="100%"
                    height="1000px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 10}
                  index={10}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  11. Manual Project Report
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 10}>
                  <object
                    data="/Linguists Collective project report.pdf"
                    type="application/pdf"
                    width="100%"
                    height="1000px"
                    aria-label="document"
                  ></object>
                </Accordion.Content>
              </Accordion>
            </Card>
          </Box>

          <Box pb={2}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Have a question?</p>
              </CardHeader>
              <CardBody>
                <div>
                  <TextareaAutosize
                    onChange={handleChange}
                    name="question"
                    style={{
                      width: 100 + "%",
                      margin: 10,
                      marginLeft: 0,
                      padding: 10,
                    }}
                    value={data.question}
                    rowsMin={5}
                    placeholder="Type your question here... Please note that your question and answer from Admin Team will be visible to all LC Linguists. Please do not share any confidential, personal, client or assignment details here."
                  />
                  <Button
                    color="purple"
                    disabled={!question}
                    onClick={handleSubmit}
                  >
                    Send
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Box>

          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>
                Answers from Booking Secretary / Admin Team
              </p>
            </CardHeader>
            <CardBody>
              <Accordion>
                {values.map((list) => (
                  <Fragment>
                    {list.question && list.answer ? (
                      <Fragment>
                        <Accordion.Title
                          active={activeIndex === list._id}
                          index={list._id}
                          onClick={handleClick}
                        >
                          <Icon name="dropdown" />
                          {list.question}
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === list._id}>
                          {list.answer}
                        </Accordion.Content>
                      </Fragment>
                    ) : null}
                  </Fragment>
                ))}
              </Accordion>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </SppoLayout>
  );
};

export default Help;
