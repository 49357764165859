import React, { useState, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { Button, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInputSingle from "components/Common/CustomInputSingle";

const Action = ({
  id,
  load,
  trans,
  ID,
  clientID,
  name,
  currency,
  estimate,
  accepted,
  project_report
}) => {
  const token = Cookies.get("SPPOtoken");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [showNote, setShowNote] = React.useState(false);
  const [viewNote, setViewNote] = React.useState(false);

  const [showMessage, setShowMessage] = React.useState(false);
  const [viewMessage, setViewMessage] = React.useState(false);

  const [totalnote, setTotalNote] = React.useState(false);
  const [totalmessage, setTotalMessage] = React.useState(false);

  const [showAllocate, setShowAllocate] = React.useState(false);
  const classes = useLandingpageStyles();
  const [linDetails, setLinDetails] = React.useState();
  const [data, setData] = React.useState({
    linID: "",
  });

  const { linID } = data;

  const FindLinDetails = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/find-linguist`,
        {
          linID: linID,
        }
      );
      setLinDetails(response?.data);
      setData({ ...data, linID: "" });
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error.response?.data}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const JobAllocate = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/allocate-translation-inhouse/translationID/${id}`,
        {
          linID: linDetails?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        title: "Success",
        text: `${response?.data.message}`,
        icon: "success",
        buttons: false,
      });
      load();
      handleClose();
    } catch (error) {
      swal({
        title: "Oops!",
        text: `${error.response?.data}`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleYes = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          JobAllocate(value);

        default:
          break;
      }
    });
  };

  const [pay, setPay] = useState({
    admin_booking_notes: "",
    bookingID: "",
  });

  const [edit, setEdit] = React.useState({
    value: false,
    loader: false,
  });
  const handleBack = () => {
    setViewNote(false);
    setViewMessage(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowNote(false);
    setShowMessage(false);
    setShowAllocate(false);
    setLinDetails("");
  };

  const handleShowNote = () => {
    setShowNote(!showNote);
  };

  const handleShowMessage = () => {
    setShowMessage(!showMessage);
  };

  const handleAllocate = () => {
    setShowAllocate(!showAllocate);
  };

  const handleChange = async (e) => {
    setPay({ ...pay, [e.target.name]: e.target.value });
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const [values, setValues] = useState({
    loader: false,
  });

  const handleNote = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/write-note-INC/bookingID/${id}`,
        {
          admin_booking_notes: pay.admin_booking_notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: response?.data.message,
      });
      getTotalNote();
      setPay({ ...pay, admin_booking_notes: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const handleMessage = async () => {
    setEdit({ ...edit, loader: true });
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/send-message-INC/bookingID/${id}`,
        {
          admin_booking_notes: pay.admin_booking_notes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        icon: "success",
        buttons: false,
        text: response?.data.message,
      });
      getTotalMessage();
      setPay({ ...pay, admin_booking_notes: "", loader: false });
      setEdit({ ...edit, loader: false });
    } catch (error) {
      swal({
        icon: "error",
        buttons: false,
        text: "Failed ",
      });
    }
  };

  const getTotalNote = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/count-note-interpreting-INC/bookingID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalNote(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getTotalMessage = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/sppo/count-message-interpreting-INC/bookingID/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalMessage(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getTotalNote();
    getTotalMessage();
  }, []);

  const cancel = async (e) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/inhouse-Translation-cancel/TranslationID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      load();
      setValues({ ...values, reload: true });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCancel = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((value) => {
      switch (value) {
        case "cancel":
          break;

        case "catch":
          cancel(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!accepted ? (
          <MenuItem onClick={handleAllocate}>Allocate Manually</MenuItem>
        ) : null}
        {!project_report ?
          <MenuItem onClick={handleCancel}>Cancel</MenuItem>
          : null}
        <MenuItem onClick={handleShowNote}>Add note ({totalnote})</MenuItem>
        <MenuItem onClick={handleShowMessage}>
          Send Message ({totalmessage})
        </MenuItem>
      </Menu>

      {/* ADD NOTE */}
      {showNote ? (
        <Dialog open={showNote}>
          {!viewNote ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Add Note - {ID} {name} {clientID}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.admin_booking_notes}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="admin_booking_notes"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  <Button
                    disabled={!pay.admin_booking_notes}
                    onClick={handleNote}
                    color="purple"
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Save Note
                    {edit.loader && <div class="ui active inline loader"></div>}
                  </Button>
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Save Note
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}

      {/* Send Message */}
      {showMessage ? (
        <Dialog open={showMessage}>
          {!viewMessage ? (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "space-between",
                    width: 100 + "%",
                  }}
                >
                  Send Message - {ID} {name} {clientID ? clientID : "N/A"}
                </div>
              </DialogTitle>
              <DialogContent>
                <TextField
                  onChange={handleChange}
                  value={pay.admin_booking_notes}
                  id="standard-textarea"
                  label="Type here... (max 432 ch.)"
                  placeholder="Write your note here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 432 }}
                  name="admin_booking_notes"
                  required
                  style={{ marginBottom: 15, width: 100 + "%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>

                  {clientID ? (
                    <Button
                      disabled={!pay.admin_booking_notes}
                      onClick={handleMessage}
                      color="purple"
                      variant="outlined"
                      startIcon={<SaveIcon />}
                    >
                      Send Message
                      {edit.loader && (
                        <div class="ui active inline loader"></div>
                      )}
                    </Button>
                  ) : null}
                </div>
              </DialogContent>
            </div>
          ) : (
            <div style={{ width: 600 }}>
              <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
                Send Message
              </DialogTitle>
              <DialogContent style={{ overflowY: "scroll" }}>
                <Box pb={1}>
                  <Card>
                    <CardBody>
                      <h5 style={{ marginBottom: 15, width: 100 + "%" }}>
                        some text
                      </h5>
                    </CardBody>
                  </Card>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={handleBack}
                    color="purple"
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="purple"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </div>
          )}
        </Dialog>
      ) : null}

      {/* manually allocation ui */}
      {showAllocate ? (
        <Dialog open={showAllocate}>
          <DialogTitle style={{ borderBottom: "rgb(0,0,0,.2) solid 1px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "space-between",
                width: 100 + "%",
              }}
            >
              Allocate Manually - {ID} {currency} {estimate}
            </div>
          </DialogTitle>
          <DialogContent>
            <GridContainer
              style={{ justifyContent: "center", margin: "2rem 0" }}
            >
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardBody>
                    <CustomInputSingle
                      label="Linguist Ref ID"
                      width="100%"
                      placeholder="e.g. LIN/PRO100..."
                      name="linID"
                      onChange={handleChange}
                      value={data.linID}
                      required
                    />

                    <div style={{ textAlign: "center", marginTop: "1rem" }}>
                      <Button
                        className={`${classes.button}`}
                        color="primary"
                        variant="contained"
                        onClick={FindLinDetails}
                        disabled={!data.linID}
                      >
                        Find Linguist
                      </Button>
                    </div>
                  </CardBody>

                  <CardBody>
                    <div style={{ textAlign: "center", marginTop: "1rem" }}>
                      {linDetails ? (
                        <>
                          <p>Linguist ID: {linDetails?.id}</p>
                          <p>Name: {linDetails?.LastName}</p>

                          <p>
                            Required Source: {linDetails?.source_language}{" "}
                            {linDetails?.source_language_level
                              ? linDetails?.source_language_level
                              : "N/A"}{" "}
                          </p>
                          <p>
                            Required Target: {linDetails?.target_language}{" "}
                            {linDetails?.target_language_level
                              ? linDetails?.target_language_level
                              : "N/A"}
                          </p>

                          <p>
                            Linguist Source: {linDetails?.source_language_1}{" "}
                            {linDetails?.source_language_level_1
                              ? linDetails?.source_language_level_1
                              : "N/A"}
                          </p>
                          <p>
                            Linguist Target: {linDetails?.target_language_1}{" "}
                            {linDetails?.target_language_level_1
                              ? linDetails?.target_language_level_1
                              : "N/A"}
                          </p>

                          <p>
                            Linguist Source:{linDetails?.source_language_2}{" "}
                            {linDetails?.source_language_level_2
                              ? linDetails?.source_language_level_2
                              : "N/A"}
                          </p>
                          <p>
                            Linguist Target: {linDetails?.target_language_2}{" "}
                            {linDetails?.target_language_level_2
                              ? linDetails?.target_language_level_2
                              : "N/A"}
                          </p>
                          <br />
                        </>
                      ) : null}
                      <Button
                        className={`${classes.button}`}
                        color="primary"
                        variant="contained"
                        onClick={handleClose}
                        style={{ marginRight: "5px" }}
                        startIcon={<CloseIcon />}
                      >
                        Cancel
                      </Button>
                      {linDetails ? (
                        <Button
                          className={`${classes.button}`}
                          color="primary"
                          variant="contained"
                          onClick={handleYes}
                        >
                          Confirm Manual Assignment
                        </Button>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
};

export default Action;
