import React, { Fragment, useState } from "react";

// Material Core Components
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

// Material Icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

// Custom Components
import CustomTime from "components/Common/CustomTime";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import CustomDate from "components/Common/CustomDate";
import { useDispatch, useSelector } from "react-redux";
import { changeIBTInput } from "redux/actions/ibtAction";

const Time = () => {
  const classes = useFormStyles();
  const dispatch= useDispatch()
  const { data } = useSelector(state => state.ibtReducer)

  const {
    assignment_date,
    start_time,
    end_time
  }= data

  const [timeCount, setTimeCount] = useState([1]);

  const handleAdd = () => {
    setTimeCount([...timeCount, timeCount[0] + 1]);
  };
  const handleRemove = () => {
    const copiedTC = [...timeCount];
    copiedTC.pop();
    setTimeCount(copiedTC);
  };

  const handleDateChange = (date) => {
    dispatch(changeIBTInput({
      name: 'assignment_date',
      value: date
    }))
  }

  const handleTimeChange = (date) => {
    dispatch(changeIBTInput({
      name: 'start_time',
      value: date
    }))
  }

  const handleTimeChangeE = (date) => {
    dispatch(changeIBTInput({
      name: 'end_time',
      value: date
    }))
  }

  return (
    <div className={classes.timeDiv}>
      
      {timeCount.map((tc) => (
        <Fragment>
          <div className={classes.flexDiv} style= {{padding: '0'}} key={tc}>
            <CustomDate onChange= {handleDateChange} name= 'assignment_date' value= {assignment_date} required label= 'Date'/>
            <CustomTime onChange= {handleTimeChange} name= 'start_time' value= {start_time} required label="Start Time" />
            <CustomTime onChange= {handleTimeChangeE} name= 'end_time' value= {end_time} required label="End Time" />
          </div>
        </Fragment>
      ))}
      {timeCount.length > 1 ? (
        <IconButton
          color="secondary"
          className="i-btn-two"
          component="button"
          onClick={handleRemove}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      ) : null}
      <IconButton
        style= {{marginRight: '0'}}
        color="primary"
        className="i-btn"
        component="button"
        onClick={handleAdd}
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </div>
  );
};

export default Time;
