import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
// Materials Core

import { Button } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import Cookies from "js-cookie";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const AssignmentOptions = () => {
  const classes = useStyles();
  const history = useHistory();

  const token = Cookies.get("LINtoken");
  const [totalCurrent, setTotalCurrent] = React.useState(0);
  const [totalClosed, setTotalClosed] = React.useState(0);
  const [totalHistory, setTotalHistory] = React.useState(0);

  const getCurrenctAssignment = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguist/count-total-current-assignment`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalCurrent(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getClosedAssignment = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguist/count-total-closed-assignment`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalClosed(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  const getAssignmentHistory = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguist/count-total-assignment-history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTotalHistory(response?.data);
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  useEffect(() => {
    getCurrenctAssignment();
    getClosedAssignment();
    getAssignmentHistory();
  }, []);

  return (
    <div className={classes.root}>
      <Button
        color="purple"
        content="Current Assignments"
        icon="calendar outline"
        label={{
          basic: true,
          color: "purple",
          pointing: "left",
          content: `${totalCurrent}`,
        }}
        onClick={() => history.push("/linguist/current-assignment")}
      />
      <Button
        color="purple"
        content="Closed Assignments"
        icon="calendar check outline"
        label={{
          as: "a",
          basic: true,
          color: "purple",
          pointing: "left",
          content: `${totalClosed}`,
        }}
        onClick={() =>
          history.push("/linguist/current-assignment/close-and-timesheet")
        }
      />
      <Button
        color="purple"
        content="Assignment History"
        icon="history"
        label={{
          as: "a",
          basic: true,
          color: "purple",
          pointing: "left",
          content: `${totalHistory}`,
        }}
        onClick={() =>
          history.push("/linguist/current-assignment/assignment-history")
        }
      />
    </div>
  );
};

export default AssignmentOptions;
