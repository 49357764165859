import React, { useState, useEffect } from "react";
import SPPO from "components/RouteProtection/Sppo/Sppo";
import SppoLayout from "layout/SppoLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Options from "components/Sppo/MyClients/Options";
import ClientOptions from "components/Sppo/ManageInhouse/Options";
import ClientList from "components/Sppo/MyClients/Clients/ClientList";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import Cookies from 'js-cookie'
import Axios from 'axios'
import { Header } from "semantic-ui-react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "utils/history";
import { IconButton } from "@material-ui/core";

const AddAnUserPage = () => {
    const classes = useDashboardStyles();
    const [sppoID, setSPPOID] = useState(null)
    const [type, setType] = useState("");
    const getDetails = async (e) => {
        const token = Cookies.get("SPPOtoken");
        const response = await Axios.get(
            `${process.env.REACT_APP_BACKEND_API}/sppo/access-control`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        setSPPOID(response?.data);
    };
    useEffect(() => {
        getDetails();
    }, []);


    return (
        <SppoLayout>
            <SPPO>
                <GridContainer>
                    {sppoID?.agent_sppo && sppoID?.my_client_access ?
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color="primary"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                    <p className={classes.cardTitleWhite}>View Clients</p>
                                    <div style={{ display: "flex" }}>
                                        {/* <BookingOption setType={setType} /> */}
                                        <IconButton color="inherit">
                                            <ArrowBackIcon
                                                onClick={() => history.goBack()}
                                            ></ArrowBackIcon>
                                        </IconButton>
                                    </div>
                                </CardHeader>
                                {type !== "" && (
                                    <Card>
                                        <CardHeader color="primary">
                                            <ClientOptions type={type} />
                                        </CardHeader>
                                    </Card>
                                )}
                                <CardBody>
                                    <Options sppoID={sppoID?._id} />
                                </CardBody>
                                <CardBody>
                                    <ClientList />
                                </CardBody>
                            </Card>
                        </GridItem>
                        :
                        sppoID?.profile_update ?
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardHeader color="primary"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                        <p className={classes.cardTitleWhite}>View Clients</p>
                                        <div style={{ display: "flex" }}>
                                            {/* <BookingOption setType={setType} /> */}
                                            <IconButton color="inherit">
                                                <ArrowBackIcon
                                                    onClick={() => history.goBack()}
                                                ></ArrowBackIcon>
                                            </IconButton>
                                        </div>
                                    </CardHeader>
                                    {type !== "" && (
                                        <Card>
                                            <CardHeader color="primary">
                                                <ClientOptions type={type} />
                                            </CardHeader>
                                        </Card>
                                    )}
                                    <CardBody>
                                        <Options sppoID={sppoID?._id} />
                                    </CardBody>
                                    <CardBody>
                                        <ClientList />
                                    </CardBody>
                                </Card>
                            </GridItem>
                            :
                            sppoID?.agent_sppo && sppoID?.profile_update === false ?
                                <Header as='h3'>Access denied.</Header>
                                :
                                sppoID?.profile_update === false ?
                                    <Header as='h3'>Access denied. <a href={`/sppo/full-registration`}>Please complete full registration.</a></Header>
                                    :
                                    <Header as='h3'>Loading...</Header>
                    }
                </GridContainer>

            </SPPO>

        </SppoLayout>
    );
};

export default AddAnUserPage;
