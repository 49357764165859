import React, { useState, useEffect } from "react";
import Accountclient from "components/RouteProtection/AccountClient/Accountclient";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountClientLayout from "layout/AccountClientLayout";
import Cookies from "js-cookie";
import Axios from "axios";
import { Header } from "semantic-ui-react";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { Rating } from "semantic-ui-react";
import { Typography, Divider } from "@material-ui/core";

const UnconfirmedAssignmentsPage = () => {
  const classes = useDashboardStyles();
  const [feedback_access, setAssignmentAccess] = useState(null);
  const [bacUser, setbacUser] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [transFeedback, setTransFeedback] = useState(null);

  const getDetails = async (e) => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/accountclient/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAssignmentAccess(response?.data.feedback_access);
    setbacUser(response?.data.bacUser);
  };

  const getInterpretingFeedback = async (e) => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/accountclient/interpreting-feedback`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setFeedback(response?.data);
  };

  const getTranslationFeedback = async (e) => {
    const token = Cookies.get("BACtoken");
    const response = await Axios.get(
      `${process.env.REACT_APP_BACKEND_API}/accountclient/translation-feedback`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setTransFeedback(response?.data);
  };

  useEffect(() => {
    getDetails();
    getInterpretingFeedback();
    getTranslationFeedback();
  }, []);

  let totalFeedbackbyLIN = 0,
    avgFeedbackByLIN = 0,
    totalFeedbackByClient = 0,
    avgFeedbackByClient = 0;
  let totalTransFeedbackbyLIN = 0,
    avgTransFeedbackByLIN = 0,
    totalTransFeedbackByClient = 0,
    avgTransFeedbackByClient = 0;

  // calculate interpreting feedback by linguist
  for (let i = 0; i <= feedback?.length - 1; i++) {
    {
      totalFeedbackbyLIN += feedback[i].feedback;
      avgFeedbackByLIN = Math.round(totalFeedbackbyLIN / feedback?.length);
    }
  }

  // calculate interpreting feedback by client
  for (let i = 0; i <= feedback?.length - 1; i++) {
    {
      totalFeedbackByClient += feedback[i].feedbackByClient;
      avgFeedbackByClient = Math.round(
        totalFeedbackByClient / feedback?.length
      );
    }
  }

  // calculate translation feedback by linguist
  for (let i = 0; i <= transFeedback?.length - 1; i++) {
    {
      totalTransFeedbackbyLIN += transFeedback[i].feedbackByLin;
      avgTransFeedbackByLIN = Math.round(
        totalTransFeedbackbyLIN / transFeedback?.length
      );
    }
  }

  // calculate translation feedback by client
  for (let i = 0; i <= transFeedback?.length - 1; i++) {
    {
      totalTransFeedbackByClient += transFeedback[i].feedbackByClient;
      avgTransFeedbackByClient = Math.round(
        totalTransFeedbackByClient / transFeedback?.length
      );
    }
  }

  return (
    <Accountclient>
      <AccountClientLayout>
        <Card>
          <CardHeader color="primary">
            <p className={classes.cardTitleWhite}>Feedback</p>
          </CardHeader>
          <GridContainer style={{ justifyContent: "center" }}>
            {feedback_access ? (
              <>
                <GridItem xs={12} sm={12} md={12} lg={4}>
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "center",
                        padding: 20,
                        lineHeight: 7,
                      }}
                    >
                      <Typography variant="h3">
                        Feedbacks from Linguists
                      </Typography>
                      <Divider
                        orientation="horizontal"
                        style={{ marginBottom: 15, marginTop: 15 }}
                      />
                      <Typography
                        variant="h5"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          color: "purple",
                        }}
                      >
                        Interpreting Assignment Feedback - ({avgFeedbackByLIN})
                      </Typography>
                      <Rating
                        maxRating={5}
                        rating={avgFeedbackByLIN}
                        icon="star"
                        size="massive"
                      />

                      <Typography
                        variant="h5"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          color: "purple",
                        }}
                      >
                        Translation Assignment Feedback - (
                        {avgTransFeedbackByLIN})
                      </Typography>
                      <Rating
                        maxRating={5}
                        rating={avgTransFeedbackByLIN}
                        icon="star"
                        size="massive"
                      />
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={4}>
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "center",
                        padding: 20,
                        lineHeight: 7,
                      }}
                    >
                      <Typography variant="h3">
                        My feedbacks to Linguists
                      </Typography>
                      <Divider
                        orientation="horizontal"
                        style={{ marginBottom: 15, marginTop: 15 }}
                      />

                      <Typography
                        variant="h5"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          color: "purple",
                        }}
                      >
                        Interpreting Assignment Feedback - (
                        {avgFeedbackByClient})
                      </Typography>
                      <Rating
                        maxRating={5}
                        rating={avgFeedbackByClient}
                        icon="star"
                        size="massive"
                      />
                      <Typography
                        variant="h5"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          color: "purple",
                        }}
                      >
                        Translation Assignment Feedback - (
                        {avgTransFeedbackByClient})
                      </Typography>
                      <Rating
                        maxRating={5}
                        rating={avgTransFeedbackByClient}
                        icon="star"
                        size="massive"
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </>
            ) : bacUser === false ? (
              <>
                <GridItem xs={12} sm={12} md={12} lg={4}>
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "center",
                        padding: 20,
                        lineHeight: 7,
                      }}
                    >
                      <Typography variant="h3">
                        Feedbacks from Linguists
                      </Typography>
                      <Divider
                        orientation="horizontal"
                        style={{ marginBottom: 15, marginTop: 15 }}
                      />
                      <Typography
                        variant="h5"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          color: "purple",
                        }}
                      >
                        Interpreting Assignment Feedback - ({avgFeedbackByLIN})
                      </Typography>
                      <Rating
                        maxRating={5}
                        rating={avgFeedbackByLIN}
                        icon="star"
                        size="massive"
                      />

                      <Typography
                        variant="h5"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          color: "purple",
                        }}
                      >
                        Translation Assignment Feedback - (
                        {avgTransFeedbackByLIN})
                      </Typography>
                      <Rating
                        maxRating={5}
                        rating={avgTransFeedbackByLIN}
                        icon="star"
                        size="massive"
                      />
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={4}>
                  <Card>
                    <CardBody
                      style={{
                        textAlign: "center",
                        padding: 20,
                        lineHeight: 7,
                      }}
                    >
                      <Typography variant="h3">
                        My feedbacks to Linguists
                      </Typography>
                      <Divider
                        orientation="horizontal"
                        style={{ marginBottom: 15, marginTop: 15 }}
                      />

                      <Typography
                        variant="h5"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          color: "purple",
                        }}
                      >
                        Interpreting Assignment Feedback - (
                        {avgFeedbackByClient})
                      </Typography>
                      <Rating
                        maxRating={5}
                        rating={avgFeedbackByClient}
                        icon="star"
                        size="massive"
                      />
                      <Typography
                        variant="h5"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          color: "purple",
                        }}
                      >
                        Translation Assignment Feedback - (
                        {avgTransFeedbackByClient})
                      </Typography>
                      <Rating
                        maxRating={5}
                        rating={avgTransFeedbackByClient}
                        icon="star"
                        size="massive"
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </>
            ) : feedback_access === false ? (
              <Header as="h3">Access denied</Header>
            ) : (
              <Header as="h3">Loading...</Header>
            )}
          </GridContainer>
        </Card>
      </AccountClientLayout>
    </Accountclient>
  );
};

export default UnconfirmedAssignmentsPage;
