import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";
import swal from "sweetalert";
//Axios
import Axios from "axios";

//Cookies
import Cookies from "js-cookie";

const Action = ({
  id,
  load,
  saved,
  sent,
  accepted,
  cancel,
  report,
  reportID,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = Cookies.get("SPPOtoken");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteQuote = async (value) => {
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/delete-client-Quote/QuoteID/${id}`
      );

      swal({
        icon: "success",
        text: `${response?.data.message}`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleDelete = async (value) => {
    swal("Are you sure you want to delete this?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          deleteQuote(value);

        default:
          break;
      }
    });
  };

  const ResendQuote = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/send-client-Quote/QuoteID/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Quote successfully sent to the client.`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleResend = async (value) => {
    swal("Are you sure you want to resend this quote?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          ResendQuote(value);

        default:
          break;
      }
    });
  };

  const handleSee = (e) => {
    history.push(`/sppo/view-direct-quote/${id}`);
  };

  const handleProjectReport = (e) => {
    history.push(`/sppo/create-direct-quote-project-report/${id}`);
  };

  const handleViewProject = (e) => {
    history.push(`/sppo/direct-quote/view-project-report/${reportID}`);
  };

  const handleInvoice = (e) => {
    history.push(`/sppo/create-direct-quote-invoice/${id}`);
  };

  const cancelQuote = async (value) => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/sppo/cancel-direct-client-Quote/${id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      swal({
        icon: "success",
        text: `Quote cancelled!!!.`,
        buttons: false,
      });
      load();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleCancel = async (value) => {
    swal("Are you sure you want to cancel this quote?", {
      dangerMode: true,
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    }).then((type) => {
      switch (type) {
        case "cancel":
          break;

        case "catch":
          cancelQuote(value);

        default:
          break;
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          minWidth: "10px",
          paddingLeft: "0px",
        }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSee}>Open</MenuItem>
        {saved ? (
          <>
            <MenuItem onClick={handleResend}>Send</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </>
        ) : null}
        {sent && !cancel ? (
          <>
            <MenuItem onClick={handleResend}>Resend</MenuItem>
            <MenuItem onClick={handleCancel}>Cancel</MenuItem>
          </>
        ) : null}
        {accepted && !report ? (
          <MenuItem onClick={handleProjectReport}>
            Create Project Report
          </MenuItem>
        ) : null}
        {accepted && report ? (
          <>
            <MenuItem onClick={handleViewProject}>View project report</MenuItem>
            <MenuItem onClick={handleInvoice}>Create Invoice</MenuItem>{" "}
          </>
        ) : null}
        {cancel ? <MenuItem onClick={handleDelete}>Delete</MenuItem> : null}
      </Menu>
    </div>
  );
};

export default Action;
