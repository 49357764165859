import { TextArea } from "semantic-ui-react";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import { Typography } from "@material-ui/core";

import CustomInputSingle from "components/Common/CustomInputSingle";
import TermsAndConditions from "components/Auth/EmployeeRegistration/TermsAndConditions";
import CustomCaptcha from "components/Common/CustomCaptcha";
import useFormStyles from "assets/jss/components/auth/formStyle";
import { EMAIL_REGEX } from "data";

import Axios from "axios";
import swal from "sweetalert";

import CustomPhone from "components/Common/CustomPhone";

const ContactForm = () => {
  const classes = useFormStyles();
  const [checked, setChecked] = useState(false);

  const [captcha, setCaptcha] = useState();
  const [notValid, setNotValid] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    mobile_no: "",
    phone_no: "",
    email: "",
    message: "",
  });

  const { first_name, last_name, email, phone_no, mobile_no, message } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleEmailChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.value !== "" && EMAIL_REGEX.test(e.target.value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handlePhone = (value) => {
    setData({ ...data, phone_no: value });
  };
  const handleMobile = (value) => {
    setData({ ...data, mobile_no: value });
  };

  const handleCheck = (value) => {
    setChecked(value);
  };

  const handleCaptcha = async (value) => {
    setCaptcha(value);
  };

  const handleSubmit = async () => {
    try {
      const response = Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/frontpage/contactRequest`,
        data
      );
      swal({
        //title: "Success",
        text: `Contact Request send`,
        icon: "success",
        buttons: false,
      }).then(function () {
        window.location = "/landing-page/contact";
      });
    } catch (error) {
      // Copy from other catch's code
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response
              ? error?.response?.data?.error
              : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div>
        <CustomInputSingle
          label="First Name"
          width="100%"
          placeholder="First Name"
          name="first_name"
          onChange={handleChange}
          required
        />
        <CustomInputSingle
          label="Last Name"
          width="100%"
          placeholder="Last Name"
          name="last_name"
          onChange={handleChange}
          required
        />
        <br />
        <CustomPhone
          label="Phone Number"
          onChange={handlePhone}
          placeholder="Phone number (e.g. +44 1234 567890)"
          value={phone_no}
          name="phone_no"
        />
        <br />
        <CustomPhone
          label="Mobile Number"
          onChange={handleMobile}
          placeholder="Mobile number (e.g. +44 1234 567890)"
          value={mobile_no}
          name="mobile_no"
        />
        {/* <CustomInputSingle
          label="Email"
          width="100%"
          placeholder="Email"
          name="email"
          onChange={handleChange}
          required
        /> */}
        <CustomInputSingle
          label="Email"
          width="100%"
          placeholder="Email"
          name="email"
          onChange={handleEmailChange}
          value={email}
          required
        />
        {notValid && (
          <small style={{ color: "red" }}>enter valid email address</small>
        )}
        <br />
        <TextArea
          placeholder="Tell us more.."
          style={{ minHeight: 200 }}
          name="message"
          onChange={handleChange}
        />
        <p
          className="statement"
          style={{
            marginTop: "1rem",
            fontSize: "1rem",
          }}
        >
          Please be aware that in order for us to adhere to GDPR guidelines, our
          systems will store your data.{" "}
        </p>
        <TermsAndConditions
          handleCheck={handleCheck}
          text="I consent to having this website store my submitted information so they can respond to my inquiry."
        />
        <CustomCaptcha onChange={handleCaptcha} />
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          style={{
            marginTop: "1rem",
          }}
          disabled={
            !checked ||
            !captcha ||
            !first_name ||
            !last_name ||
            !email ||
            !message
          }
        >
          Submit
        </Button>
      </div>
      <div className="top">
        <Typography
          style={{
            fontWeight: "700",
            marginTop: "10px",
          }}
          paragraph
          variant="h6"
        >
          Reach out to us by post
        </Typography>
        <Typography paragraph variant="body1">
          Linguists Collective Ltd, 38 Mill St, Bedford, MK40 3HD, United
          Kingdom
        </Typography>
      </div>
      <br />
      <Typography paragraph variant="body1">
        W: linguistscollective.com E: info@linguistscollective.com P: +44 730
        7377 430
      </Typography>
      <Typography paragraph variant="body1">
        Please contact Admin Team for EU (Cyprus), Middle East (Dubai) and Asia
        (Sylhet) office contact details.
      </Typography>
      {/* {/* <div className="top">
        <Typography
          style={{
            fontWeight: "700",
            marginTop: "10px",
          }}
          paragraph
          variant="h6"
        >
          Website
        </Typography>
        <Typography paragraph variant="body1">
          linguistscollective.com
        </Typography>
      </div> */}
      {/* <div className="top">
        <Typography
          style={{
            fontWeight: "700",
            marginTop: "10px",
          }}
          paragraph
          variant="h6"
        >
          Email
        </Typography>
        <Typography paragraph variant="body1">
          info@linguistscollective.com
        </Typography>
      </div>
      <div className="top">
        <Typography
          style={{
            fontWeight: "700",
            marginTop: "10px",
          }}
          paragraph
          variant="h6"
        >
          Phone number
        </Typography>
        <Typography paragraph variant="body1">
          +44 333 240 0139
        </Typography>
      </div>
      <div className="top">
        <Typography paragraph variant="body1">
          Please contact Admin Team for European (Cyprus), Dubai and Bangladesh
          office contact details.
        </Typography>
      </div>  */}
    </form>
  );
};

export default ContactForm;
