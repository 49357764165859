import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "utils/history";

const Action = ({
  id,
  linSignature,
  bacSignature,
  invoice,
  invoiceID,
  bookingID
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          minWidth: "10px",
          paddingLeft: "0px",
        }}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => history.push(`/account-client/view-timesheet/${id}`)}
        >
          View Timesheet
        </MenuItem>

        {linSignature && !bacSignature ? (
          <MenuItem
            onClick={() =>
              history.push(`/account-client/bulk-booking-confirm-close-and-timesheet/${id}`)
            }
          >
            Confirm Timesheet
          </MenuItem>
        ) : null}

        {invoice ? (
          <MenuItem
            onClick={() =>
              history.push(`/account-client/view-invoice/${invoiceID}`)
            }
          >
            View Invoice
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default Action;
