import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Material Core Components
import CustomInputSingle from "components/Common/CustomInputSingle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// Custom Styles
import swal from "sweetalert";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import { changeOTTInput } from "redux/actions/ottAction";
import Cookies from "js-cookie";

import Axios from "axios";

import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { Button } from "@material-ui/core";

const SelectClient = () => {
  const classes = useEmployeeStyles();
  const { data } = useSelector((state) => state.ottReducer);
  const dispatch = useDispatch();
  const token = Cookies.get("SPPOtoken");
  const classesb = useLandingpageStyles();
  const [clientID, setClientID] = useState("");
  const { select_client } = data;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeOTTInput({
        name,
        value: value,
      })
    );
  };

  const handleNext = async () => {
    let fd = new FormData();
    fd.append("select_client", select_client);

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/sppo/select-own-inhouse-client`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(
        changeOTTInput({
          name: "client_found",
          value: true,
        })
      );
      let nclientID =
        response?.data.id +
        " - " +
        response?.data.LastName +
        " - " +
        response?.data.PostalCode +
        " - " +
        response?.data.mobile_no +
        " - " +
        response?.data.passNumber;
      setClientID(nclientID);
      Cookies.set("payLaterAccess", response?.data?.payLaterAccess);
    } catch (error) {
      swal({
        title: "OOPS !!!!",
        text: `Kindly provide correct ID number`,
        icon: "error",
        buttons: false,
      });

      dispatch(
        changeOTTInput({
          name: "client_found",
          value: false,
        })
      );
    }
  };

  const isAllow = !select_client;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInputSingle
          onChange={handleChange}
          width="100%"
          label="Enter client ID"
          name="select_client"
          value={select_client}
          placeholder="Enter INC ID (e.g. - INC0000)"
        />
        <div
          className={classes.buttonFlex}
          style={{
            display: "flex",
            marginTop: "24px",
            justifyContent: "center",
          }}
        >
          <Button
            className={`${classesb.button}`}
            disabled={isAllow}
            style={{ width: "30rem" }}
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            Find client
          </Button>
        </div>
      </form>
      {clientID.length > 0 ? (
        <div
          style={{
            margin: "0",
            marginTop: "15px",
            fontSize: "0.9rem",
            fontWeight: "bold",
          }}
        >
          <AppBar position="static">
            <Toolbar>
              <h3>{clientID}</h3>
            </Toolbar>
          </AppBar>
        </div>
      ) : (
        <div
          style={{
            margin: "0",
            marginTop: "15px",
            fontSize: "0.9rem",
            fontWeight: "bold",
          }}
        >
          <AppBar position="static">
            <Toolbar>
              <h3>
                Find client with ID and ask your INC client to confirm
                surname, post/zip code, phone number and passcode for security.
              </h3>
            </Toolbar>
          </AppBar>
        </div>
      )}
    </div>
  );
};

export default SelectClient;
