import { CHANGE_DATETIME_INPUT } from "redux/actions/actionTypes";

const INITIAL_STATE = {
  calender: {
    total_days: 1,
    weekly_work_duration: "",
    total_duration: "",
    flag: false,

    startdateOne: "",
    enddateOne: "",
    starttimeOne: "",
    endtimeOne: "",
    durationOne: "",
    startdateTwo: "",
    enddateTwo: "",
    starttimeTwo: "",
    endtimeTwo: "",
    durationTwo: "",

    startdateThree: "",
    enddateThree: "",
    starttimeThree: "",
    endtimeThree: "",
    durationThree: "",
    startdateFour: "",
    enddateFour: "",
    starttimeFour: "",
    endtimeFour: "",
    durationFour: "",

    startdateFive: "",
    enddateFive: "",
    starttimeFive: "",
    endtimeFive: "",
    durationFive: "",
    startdateSix: "",
    enddateSix: "",
    starttimeSix: "",
    endtimeSix: "",
    durationSix: "",

    startdateSeven: "",
    enddateSeven: "",
    starttimeSeven: "",
    endtimeSeven: "",
    durationSeven: "",
    startdateEight: "",
    enddateEight: "",
    starttimeEight: "",
    endtimeEight: "",
    durationEight: "",

    startdateNine: "",
    enddateNine: "",
    starttimeNine: "",
    endtimeNine: "",
    durationNine: "",
    startdateTen: "",
    enddateTen: "",
    starttimeTen: "",
    endtimeTen: "",
    durationTen: "",

    startdateEleven: "",
    enddateEleven: "",
    starttimeEleven: "",
    endtimeEleven: "",
    durationEleven: "",
    startdateTwelve: "",
    enddateTwelve: "",
    starttimeTwelve: "",
    endtimeTwelve: "",
    durationTwelve: "",

    startdateThirteen: "",
    enddateThirteen: "",
    starttimeThirteen: "",
    endtimeThirteen: "",
    durationThirteen: "",
    startdateFourteen: "",
    enddateFourteen: "",
    starttimeFourteen: "",
    endtimeFourteen: "",
    durationFourteen: "",

    startdateFifteen: "",
    enddateFifteen: "",
    starttimeFifteen: "",
    endtimeFifteen: "",
    durationFifteen: "",
    startdateSixteen: "",
    enddateSixteen: "",
    starttimeSixteen: "",
    endtimeSixteen: "",
    durationSixteen: "",

    startdateSeventeen: "",
    enddateSeventeen: "",
    starttimeSeventeen: "",
    endtimeSeventeen: "",
    durationSeventeen: "",
    startdateEighteen: "",
    enddateEighteen: "",
    starttimeEighteen: "",
    endtimeEighteen: "",
    durationEighteen: "",

    startdateNineteen: "",
    enddateNineteen: "",
    starttimeNineteen: "",
    endtimeNineteen: "",
    durationNineteen: "",
    startdateTwenty: "",
    enddateTwenty: "",
    starttimeTwenty: "",
    endtimeTwenty: "",
    durationTwenty: "",

    startdateTwentyOne: "",
    enddateTwentyOne: "",
    starttimeTwentyOne: "",
    endtimeTwentyOne: "",
    durationTwentyOne: "",
    startdateTwentyTwo: "",
    enddateTwentyTwo: "",
    starttimeTwentyTwo: "",
    endtimeTwentyTwo: "",
    durationTwentyTwo: "",

    startdate23: "",
    enddate23: "",
    starttime23: "",
    endtime23: "",
    duration23: "",
    startdate24: "",
    enddate24: "",
    starttime24: "",
    endtime24: "",
    duration24: "",

    startdate25: "",
    enddate25: "",
    starttime25: "",
    endtime25: "",
    duration25: "",
    startdate26: "",
    enddate26: "",
    starttime26: "",
    endtime26: "",
    duration26: "",

    startdate27: "",
    enddate27: "",
    starttime27: "",
    endtime27: "",
    duration27: "",
    startdate28: "",
    enddate28: "",
    starttime28: "",
    endtime28: "",
    duration28: "",

    startdate29: "",
    enddate29: "",
    starttime29: "",
    endtime29: "",
    duration29: "",
    startdate30: "",
    enddate30: "",
    starttime30: "",
    endtime30: "",
    duration30: "",

    startdate31: "",
    enddate31: "",
    starttime31: "",
    endtime31: "",
    duration31: "",
  },
};

const datetimeReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_DATETIME_INPUT:
      return {
        ...state,
        calender: {
          ...state.calender,
          [payload.name]: payload.value,
        },
      };

    default:
      return state;
  }
};

export default datetimeReducer;
