import CustomTextInput from "components/Common/CustomTextInput";
import React, { useRef, useState, useEffect } from "react";
import { Button, Dimmer, Loader, Rating, Table } from "semantic-ui-react";
import { Card, Icon, Image, Header, Label, Checkbox } from "semantic-ui-react";
import { IconButton, Input, TextField } from "@material-ui/core";

import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import "./sigCanvas.css";
import CustomModal from "components/Common/CustomModal";
import history from "utils/history";
import RichTextEditor from "react-rte";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "axios";
import moment from "moment";
import CustomTime from "components/Common/CustomTime";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
import { DURATION } from "data";
import { changedatetime } from "redux/actions/datetimeAction";
import { useDispatch, useSelector } from "react-redux";

const Details = () => {
  let sigPad = useRef({});
  const dispatch = useDispatch();
  const [signature, setSignature] = useState(false);
  const [sign, setSign] = useState("");
  const [text, setText] = useState();
  const [projectReport, setProjectReport] = useState(null);
  const { id } = useParams();
  const token = Cookies.get("LINtoken");
  const [declaration, setDeclaration] = useState(false);
  const { calender } = useSelector((state) => state.datetimeReducer);
  const { starttimeOne, startdateOne, enddateOne, endtimeOne, durationOne } =
    calender;
  const [details, setDetails] = useState({
    project_name: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    request_details: "",
    target_language_level: "",
    security_clearence: "",
    word_count: "",
    visual_formatting: "",
    certification: "",
    post_certification: "",
    project_report: RichTextEditor.createEmptyValue(),
    signature: "",
    supervisor_fullname: "",
    date: "",
    feedbackByLin: "",
    remarksByLin: "",
    loader: false,
    duration: "",
  });

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changedatetime({
        name,
        value,
      })
    );
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    dispatch(
      changedatetime({
        name,
        value,
      })
    );
  };

  const handleDuration = async (e) => {
    const { value, name } = e.target;
    dispatch(
      changedatetime({
        name,
        value,
      })
    );

    const hr = value.split(":")[0].toString();
    const mm = value.split(":")[1].toString();

    let starttime = starttimeOne;
    let startdate = startdateOne;
    let endtime = endtimeOne;
    let enddate = enddateOne;

    const sthr = starttime.split(":")[0].toString();
    const stmm = starttime.split(":")[1].toString();

    const end_time = moment(starttime, "HHmm")
      .add(hr, "h")
      .add(mm, "m")
      .format("HH:mm");

    dispatch(
      changedatetime({
        name: "endtimeOne",
        value: end_time,
      })
    );

    const ethr = end_time.split(":")[0].toString();
    const etmm = end_time.split(":")[1].toString();
    let nextDay = parseInt(ethr + etmm) - parseInt(sthr + stmm);
    let end_date;
    if (nextDay > 0) {
      end_date = startdate;
    } else {
      end_date = moment(startdate, "YYYYmmDD")
        .add(1, "day")
        .format("yyyy-mm-DD");
    }

    dispatch(
      changedatetime({
        name: "enddateOne",
        value: end_date,
      })
    );
  };

  const uploadRef = useRef();
  const [uploadTimesheet, setUploadTimesheet] = useState(null);
  const todayDate = moment().format("DD/MM/YYYY");

  useEffect(() => {
    let source = Axios.CancelToken.source();

    const getProjectReport = async (e) => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BACKEND_API}/linguists/private-quote-view/quoteID/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );

        setProjectReport(response?.data);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request cancel");
        } else {
          console.log(error.response);
        }
      }
    };

    getProjectReport();

    return () => {
      source.cancel();
    };
  }, []);

  const handleClose = (e) => {
    e.preventDefault();
    setSignature(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
  };

  const handleSave = () => {
    const temp = sigPad.current.toDataURL();
    setDetails({ ...details, signature: temp });
    setSignature(false);
  };

  const handleText = (value) => {
    setText(value.toString("markdown"));
    setDetails({ ...details, project_report: value });
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const [rating, setRating] = useState(null);

  const handleRate = (e, { rating, maxRating }) => setRating(rating);

  const handleSubmit = async (e) => {
    setDetails({ ...details, loader: true });
    const fd = new FormData();

    const file = new File([details.signature], "signature", {
      type: "image/png",
    });

    fd.append("project_name", details.project_name);
    fd.append("start_date", startdateOne);
    fd.append("end_date", enddateOne);
    fd.append("start_time", starttimeOne);
    fd.append("end_time", endtimeOne);
    fd.append("duration", durationOne);
    fd.append("request_details", details.request_details);
    fd.append(
      "target_language_level",
      projectReport.postedBy.target_language_level
    );
    fd.append("security_clearence", projectReport.security_clearance);
    fd.append("word_count", details.word_count);
    fd.append("visual_formatting", details.visual_formating);
    fd.append("certification", details.certification);
    fd.append("post_certification", details.post_certification);
    fd.append("project_report", text);
    fd.append("signatureByLin", file);
    fd.append("supervisor_fullname", "");
    fd.append("date", moment().format("YYYY/MM/DD"));
    // fd.append('feedbackByLin', rating)

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/linguists/make-private-Report/directQuoteID/${id}`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      history.push(
        `/linguist/private-client/direct-quote/invoice/${response?.data._id}`
      );
      setDetails({ ...details, loader: false });
    } catch (error) {
      console.log(error.response);
      setDetails({ ...details, loader: false });
    }
  };

  return projectReport ? (
    <div>
      <CustomModal
        open={signature}
        handleClose={handleClose}
        style={{ height: "50%", width: "50%" }}
      >
        <SignatureCanvas
          penColor="black"
          // onEnd = {handleSave}
          ref={sigPad}
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleClear}
            style={{ marginRight: "5px" }}
            basic
            color="purple"
          >
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" color="purple">
            Save
          </Button>
        </div>
      </CustomModal>
      <CustomTextInput
        label="Assignment reference number"
        value={projectReport.id}
      />
      {/* <CustomTextInput label= 'Translation / Training /Other Project name' value= 'abc' /> */}

      {/* Table */}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Actual</Table.HeaderCell>
            <Table.HeaderCell>Requested</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {/* <Table.Row>
                    <Table.Cell>Project / assignment confirmation date: </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                </Table.Row> */}
          <Table.Row>
            <Table.Cell>Project start date: </Table.Cell>
            <Table.Cell>
              <TextField
                id="date"
                label="Start date"
                onChange={handleDateChange}
                name="startdateOne"
                value={startdateOne}
                //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
                type="date"
                inputProps={{
                  min: moment().format("YYYY-MM-DD"),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Table.Cell>
            <Table.Cell>
              {details.start_date &&
                moment(details.start_date).format("DD/MMM/YYYY")}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project start time: </Table.Cell>
            <Table.Cell>
              <TextField
                id="time"
                label="Start time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={starttimeOne}
                name="starttimeOne"
                onChange={handleTimeChange}
              />
            </Table.Cell>
            <Table.Cell>{details.start_time && details.start_time}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Duration: </Table.Cell>
            <Table.Cell>
              <CustomSemanticDD
                disabled={!starttimeOne || !startdateOne}
                name="durationOne"
                value={durationOne}
                onChange={handleDuration}
                label="Duration"
                data={DURATION}
              />
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project end date: </Table.Cell>
            <Table.Cell>
              <TextField
                id="date"
                label="End date"
                disabled
                name="enddateOne"
                value={enddateOne}
                //defaultValue= {moment(startdate, 'dd/MM,yyyy').format('yyyy-MM-DD')}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Table.Cell>
            <Table.Cell>
              {details.end_date &&
                moment(details.end_date).format("DD/MMM/YYYY")}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Project end time: </Table.Cell>
            <Table.Cell>
              <TextField
                id="time"
                label="End time"
                type="time"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                name="endtimeOne"
                value={endtimeOne}
              />
            </Table.Cell>
            <Table.Cell>{details.end_time && details.end_time}</Table.Cell>
          </Table.Row>
          {/* <Table.Row>
                    <Table.Cell>Project background and request details</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                </Table.Row> */}
          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell>
              {projectReport.postedBy.target_language_level}
            </Table.Cell>
            <Table.Cell>
              {projectReport.target_expertise_level_required}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Security clearance/s</Table.Cell>
            <Table.Cell>
              {projectReport.postedBy.security_clearance
                ? projectReport.security_clearance
                : "N/A"}
            </Table.Cell>
            <Table.Cell>
              {projectReport.security_clearance
                ? projectReport.security_clearance
                : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Wordcount</Table.Cell>
            <Table.Cell>
              <TextField
                id="outlined-basic"
                label="Word count"
                inputProps={{ type: "number" }}
                variant="outlined"
                value={details.word_count}
                onChange={(e) =>
                  setDetails({ ...details, word_count: e.target.value })
                }
              />
            </Table.Cell>
            <Table.Cell>{details.word_count}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Visual formatting</Table.Cell>
            <Table.Cell>
              <TextField
                id="outlined-basic"
                label="Visual formatting"
                inputProps={{ type: "text" }}
                variant="outlined"
                value={details.visual_formatting}
                onChange={(e) =>
                  setDetails({ ...details, visual_formatting: e.target.value })
                }
              />
            </Table.Cell>
            <Table.Cell>{details.visual_formatting}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Certification</Table.Cell>
            <Table.Cell>
              <TextField
                id="outlined-basic"
                label="Certification"
                inputProps={{ type: "text" }}
                variant="outlined"
                value={details.certification}
                onChange={(e) =>
                  setDetails({ ...details, certification: e.target.value })
                }
              />
            </Table.Cell>
            <Table.Cell>{details.certification}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Post certification </Table.Cell>
            <Table.Cell>
              <TextField
                id="outlined-basic"
                label="Post certification"
                inputProps={{ type: "text" }}
                variant="outlined"
                value={details.post_certification}
                onChange={(e) =>
                  setDetails({ ...details, post_certification: e.target.value })
                }
              />
            </Table.Cell>
            <Table.Cell>{details.post_certification}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h4">Project Report</Header>
      <RichTextEditor value={details.project_report} onChange={handleText} />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              To be completed by LC Linguist / Professional(project lead)
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Checkbox
                checked={declaration}
                onChange={(e, value) => setDeclaration(value.checked)}
                label="I declare the information given by me is true/accurate and complete."
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Signature{" "}
              {history.location.pathname.includes("linguist") ? (
                <IconButton onClick={() => setSignature(true)}>
                  <EditIcon />
                </IconButton>
              ) : null}
              {details.signature && (
                <img
                  style={{ width: "100px", height: "50px" }}
                  src={details.signature}
                  alt=""
                />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Full name:{" "}
              {projectReport.postedBy.FirstName +
                " " +
                projectReport.postedBy.LastName}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date: {todayDate}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Feedback:
              <Rating maxRating={5} onRate={handleRate} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <input
        onChange={(e) => setUploadTimesheet(e.target.files[0])}
        ref={uploadRef}
        type="file"
        style={{ display: "none" }}
      />
      <Header as="h4">
        Upload timesheet or proof of service delivery, max1MB
      </Header>
      <Button
        onClick={() => uploadRef.current.click()}
        basic
        style={{ backgroundColor: "purple", color: "white" }}
      >
        Upload
      </Button>
      <Header style={{ margin: "0", marginTop: "10px" }} as="h5">
        {uploadTimesheet?.name}
      </Header>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button basic onClick={handleSubmit} color="purple">
          Skip
        </Button>
        <Button
          loading={details.loader}
          onClick={handleSubmit}
          disabled={!declaration || !details.signature}
          color="purple"
        >
          Submit
        </Button>
      </div>
    </div>
  ) : (
    <Dimmer active inverted>
      <Loader size="large">Loading</Loader>
    </Dimmer>
  );
};

export default Details;
