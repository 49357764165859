import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Cookie from "js-cookie";
// Custom Components
import CustomButton from "components/Common/CustomButton";
import CustomInput from "components/Common/CustomInput";
import history from "utils/history";
// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";

import { changeCAOInput, updateCAOStep } from "redux/actions/caoAction";
import CustomRadio from "components/Common/CustomRadio";
import CustomBigInput from "components/Common/CustomBigInput";
import { CURRENCY, DEBIT_CARD, POSSIBLE_TO_PAY_IN_ACCOUNT, YES_NO } from "data";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CustomCaptcha from "components/Common/CustomCaptcha";
import Axios from "axios";
import CustomSelect from "components/Common/CustomSelect";

const PageFour = () => {
  const { data } = useSelector((state) => state.caoReducer);
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    guarantee_payment,
    pay_in_account,
    existing_account_with_localprovider,
    company,
    bank_reference_letter,
    pay_in_direct_debit,
    company_bank_details,
    currency,
    gdpr,
  } = data;

  const classes = useFormStyles();

  const handleCaptcha = async (value) => {
    setValid(value);
  };
  const token = Cookie.get("token");
  const handleSubmit = async () => {
    const sendData = {
      title: data.title || "-",
      FirstName: data.FirstName || "-",
      LastName: data.LastName || "-",
      Birthday: data.Birthday || "-",
      Sex: data.Sex || "-",
      AddressLine1: data.house_street_no_name || "-",
      AddressLine2: data.address_line_two || "-",
      email: data.primary_email || "-",
      password: data.password,
      City: data.City || "-",
      PostalCode: data.PostalCode || "-",
      Region: data.Region || "-",
      Country: data.Country || "-",
      Nationality: data.Nationality || "-",
      other_nationalities: data.other_nationalities || "-",
      account_type: data.account_type || "-",
      business_name: data.business_name || "-",
      company_registration_number: data.company_registration_number || "-",
      charity_registration_number: data.charity_registration_number || "-",
      business_website: data.business_website || "-",
      position: data.position || "-",
      mobile_no: data.mobile_no || "-",
      business_phone_no: data.business_phone_no || "-",
      other_email: data.other_email || "-",
      date_and_time: data.date_and_time || "-",
      provide_additional_info: data.additional_info || "-",
      different_AddressLine1: data.different_AddressLine1 || "-",
      different_AddressLine2: data.contact_house_street_no_name || "-",
      different_Town: data.different_Town || "-",
      different_PostalCode: data.different_PostalCode || "-",
      different_CountryRegion: data.different_CountryRegion || "-",
      different_Country: data.different_Country || "-",
      languages: data.anticipated_languages_required || "-",
      services: data.anticipated_services_required,
      guarantee_payment,
      pay_in_account,
      existing_account_with_localprovider,
      company,
      bank_reference_letter,
      pay_in_direct_debit,
      company_bank_details,
      currency,
      gdpr,
      other_details: data.other_details || "-",
      anticipated_spending_per_month:
        data.anticipated_spending_per_month || "-",
      estimated_booking_volume_per_month:
        data.estimated_booking_volume_per_month || "-",
      profile_photo: data.photo || "-",
      passport: data.passport || "-",
    };

    setLoading(true);
    // fetch api
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/accountclient/pre-signup`,
        sendData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      swal({
        title: "Thank you for submitting your application",
        text: `One of our Admin Team member will be in touch with you by email as soon as possible. Kindly check your email to active your account.`,
        icon: "success",
        buttons: false,
      });
      history.push("/");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // error
      if (error.response) {
        swal({
          title: "Oops!",
          text: `${
            error.response ? error?.response?.data?.error : "Something Went Wrong"
          }`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleBack = () => {
    dispatch(updateCAOStep(3));
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value: value,
      })
    );
  };
  const handleChecked = (e) => {
    const { name, value, checked } = e.target;
    dispatch(
      changeCAOInput({
        name,
        value: checked,
      })
    );
  };

  const isAllow = !gdpr || !valid;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomInput
          onChange={handleChange}
          width="100%"
          label="Can you provide us any guarantee of payment?"
          name="guarantee_payment"
          value={guarantee_payment}
        />

        <CustomRadio
          onChange={handleChange}
          label="If you are a small or micro business, would it be possible to pay in account?"
          width="100%"
          name="pay_in_account"
          value={pay_in_account}
          data={POSSIBLE_TO_PAY_IN_ACCOUNT}
        />
        <CustomRadio
          onChange={handleChange}
          label="Do you or your company currently have an existing account with a local provider?"
          width="100%"
          name="existing_account_with_localprovider"
          value={existing_account_with_localprovider}
          data={YES_NO}
        />

        <CustomInput
          onChange={handleChange}
          label="If yes, which company do you hold an account with?"
          width="100%"
          name="company"
          value={company}
        />
        <CustomBigInput
          onChange={handleChange}
          label="Can you provide us with a bank reference letter?"
          moreInfo="If this business is a private company"
          width="100%"
          name="bank_reference_letter"
          value={bank_reference_letter}
        />
        <CustomRadio
          onChange={handleChange}
          label="Can you pay by Direct Debit?"
          width="100%"
          name="pay_in_direct_debit"
          value={pay_in_direct_debit}
          data={DEBIT_CARD}
        />
        <CustomBigInput
          onChange={handleChange}
          label="Company bank details"
          moreInfo="Bank Account Name, Sort Code (UK only), Account No, IBAN, BIC / Swift Code, IFSC Code, Reconfirm Account No, Bank name and branch, Bank address, Any other details"
          width="100%"
          name="company_bank_details"
          value={company_bank_details}
        />

        <CustomSelect
          onChange={handleChange}
          data={CURRENCY}
          width="100%"
          label="Preferred currency"
          name="currency"
          value={currency}
        />

        <div
          className={classes.tc}
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                checked={gdpr}
                name="gdpr"
                color="primary"
              />
            }
            label=", Privacy Policy and T&C"
          /> */}
          <p>
            <Checkbox
              onChange={handleChecked}
              checked={gdpr}
              name="gdpr"
              color="primary"
            />
            By submitting this form I confirm that I am authorised to register
            with LC and on behalf of the company (organisation name provided in
            this form) I agree with the LC Service Agreement,
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/privacy-policy"
            >
              {" "}
              Privacy Policy{" "}
            </a>
            and{" "}
            <a
              target="_blank"
              href="https://linguistscollective.com/landing-page/terms-and-conditions"
            >
              {" "}
              T&C
            </a>{" "}
          </p>
        </div>

        <CustomCaptcha
          onClick={handleCaptcha}
          onChange={(value) => setValid(value)}
        />

        <div className={classes.buttonFlex}>
          <CustomButton
            text="Back"
            variant="outlined"
            color="primary"
            onClick={handleBack}
            disabled={loading}
          />
          <CustomButton
            disabled={isAllow || loading}
            text={loading ? "Submitting..." : "Submit"}
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default PageFour;
