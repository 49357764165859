import { useParams } from "react-router-dom";

// Materials Core
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import history from "utils/history";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiButton-root": {
      marginRight: "10px",
      marginBottom: "5px",
      color: "#fff",
      borderColor: "#fff",
      "&:last-child": {
        marginRight: "0px",
      },
      "@media (max-width: 480px)": {
        marginRight: "0px",
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
};

const useStyles = makeStyles(styles);

const UnconfirmedOptions = ({ currentPage }) => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <div className={classes.root}>
      <Button onClick={() => history.push('/employee/cac-confirmed-quotations')} variant="outlined">
        <b style={{ color: "white" }}>Cash Client</b>
      </Button>

      <Button onClick={() => history.push('/employee/bac-confirmed-quotations')} variant="outlined">
        <b style={{ color: "white" }}>Account Client</b>
      </Button>
    </div>
  );
};

export default UnconfirmedOptions;
