import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Card, Container, Header } from 'semantic-ui-react'
import history from 'utils/history'
import Axios from 'axios'
import Cookies from 'js-cookie'

const SuccessDirectQInv = () => {
    const {invoiceID} = useParams()
    const {paymentIntent} = useParams()
    const [session, setSession] = useState(null)
    const [sendMail, setSendMail] = useState(null)
    const bookingid = Cookies.get('quoteID')

    const changePaymentStatus = async(session) => {
        
        try {
            const response = await Axios.put(`${process.env.REACT_APP_BACKEND_API}/private-client/payment-direct-invoice/invoiceID/${invoiceID}`, {
                currency: session.currency,
                estimate: session.amount_received / 100,
                payment_intent: session.id,
                charge_id: session.charges.data[0].id
            })
            setSendMail(response?.data)
        } catch(error) {
            console.log(error.response)
        }
    }
    useEffect(() => {
        const retreiveSession = async(e) => {
        try {
            const response = await Axios.get(`${process.env.REACT_APP_BACKEND_API}/retrieve/payment/intent/${paymentIntent}`)
            setSession(response?.data)
            changePaymentStatus(response?.data)
            
          } catch(error) {
              console.log(error)
          }
        }

        retreiveSession();

    }, [])
    return (
        session ?
        <div style= {{backgroundColor: 'purple', height: '100%', padding: '30px'}}>
          <Container style= {{backgroundColor: 'white', color: 'purple', height: '100%', padding: '10px', borderRadius: '10px'}} textAlign='center'>
            {/* <Card> */}
              <Header as= 'h3'>Your estimate amount is successfully paid. Thank you, {session.charges.data[0].billing_details.name}.</Header>
              <Header as= 'h4'>We'll let you know once linguist has been assigned. Check your mail.</Header>
              {/* <Header as= 'h3'>Order ID - {bookingid}</Header> */}
              <Header as= 'h3'>Charge / Transaction ID - {session.charges.data[0].id}</Header>
              {/* <Header as= 'h3'>Payment ID - {session.id}</Header>
              <Header as= 'h3'>Balance Transaction - {session.charges.data[0].balance_transaction}</Header> */}
              <Header as= 'h3'>Total Estimate Amount Paid - {session.currency.toUpperCase() + " " + session.amount_received / 100}</Header>

            {!sendMail ?
            <div>
            <img
              style= {{width: '300px', height: '200px', marginBottom: '10px'}}
              src= 'https://i.pinimg.com/originals/98/64/9a/98649add72e05e3cc1b8ae0e6f553c8e.gif'
              alt= ''
            />
            </div>
            :
            <Header as= 'h3'>Email sent!</Header>
            }
            <Button onClick= {() => window.location= '/'} basic color= 'purple'>Home Page</Button>
            {/* </Card> */}
          </Container>
        </div>
        : null
    )
}

export default SuccessDirectQInv
