import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

const CashBook = () => {
  const history = useHistory();
  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        style={{ width: "48%", marginLeft: "5px", marginRight: "15px" }}
        onClick={() => history.push("/sppo/price-calculator")}
      >
        Price Calculator
      </Button>
      <Button
        color="primary"
        variant="contained"
        style={{ width: "50%" }}
        onClick={() => history.push("/sppo/cashbook")}
      >
        Cashbook
      </Button>
    </div>
  );
};

export default CashBook;
