import React, { useState, useEffect } from "react";
import LandingPageLayout from "layout/LandingPageLayout";
import Typography from "@material-ui/core/Typography";
// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import CustomAvatar from "components/Common/CustomAvatar";
import EventsForm from "components/Admin/ManagementPanel/EventsForm/EventMemberForm"
import Axios from "utils/axios";
import { useParams } from 'react-router-dom';
import moment from 'moment'


const AssignmentPage = () => {
  const classes = useDashboardStyles();
  const { id } = useParams()

  const [values, setValues] = useState({ reload: false });
  const viewEvent = async () => {
    try {
      const response = await Axios.get(`/api/view-event-details/eventID/${id}`)

      setValues(response?.data)
    } catch (error) {
      console.log(error.response?.data)
    }
  };

  useEffect(() => {
    viewEvent()
  }, [])


  return (
    <LandingPageLayout>

      <Card>
        <GridContainer justify="center" className={classes.container} xs={12} sm={12} md={12} lg={12} xl={12}>

          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="primary">
                <p className={classes.cardTitleWhite}>Event Registration Form</p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={6} >
            <Card>
              <CardBody>
                {/* {values?.banner ?
                  <Image src={`${process.env.REACT_APP_BACKEND_API}/get-events-banner/eventID/${id}`}
                    size='medium' centered
                  /> : null} */}
                <h4 style={{ textAlign: "center" }}>Event name: {values?.subject}</h4>
                <h4 style={{ textAlign: "center" }}>Event date and time: {moment(values?.events_date).format("DD-MMM-YYYY")} - {moment(values?.events_date).format("HH:mm")}</h4>
                <h4 style={{ textAlign: "center" }}>Time zone: {values?.timezone}</h4>
                <h4 style={{ textAlign: "center" }}>Location: {values?.venue_address}</h4>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 style={{ textAlign: "center" }}>Details</h4>
                <h4 style={{ justifyContent: "center" }}>{values?.details}</h4>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    Event Registration Form
                  </Typography>
                </div>
                <EventsForm />
              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>
      </Card>
    </LandingPageLayout>
  );
};

export default AssignmentPage;
