// Custom Components
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

//Custom styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "utils/history";
import LinguistLayout from "layout/LinguistLayout";
import InvoicePRC from "components/Linguist/LinguistDashboard/Invoice/InvoiceTransPRC";
import { useParams } from "react-router-dom";

const InvoicePrivateClientTransPage = () => {
  const classes = useDashboardStyles();
  const { id } = useParams()
  return (
    <LinguistLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }} color="primary">
              <p className={classes.cardTitleWhite}>Private Client Invoice</p>
              <IconButton color='inherit'><ArrowBackIcon
                style={{ marginLeft: 5 }}
                onClick={() => history.goBack()}
              ></ArrowBackIcon></IconButton>
            </CardHeader>
            <CardBody>
              <InvoicePRC id={id} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </LinguistLayout>
  );
};

export default InvoicePrivateClientTransPage;
