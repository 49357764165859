import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "utils/axios";
import swal from "sweetalert";
import { Button } from "semantic-ui-react";


const Action = ({ id, load }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };




    const archive = async (e) => {
        try {
            const response = await Axios.put(`/api/manager-admin/archive-private-client-Quote/quoteID/${id}`)
            swal({
                icon: 'success',
                text: `${response?.data.message}`,
                buttons: false
            })
            load()

            console.log(response)
        } catch (error) {
            console.log(error.response)
        }
    }


    const handlearchive = async (e, id) => {
        swal(`Are you sure?`, {
            buttons: {
                cancel: true,
                catch: {
                    text: "Yes!",
                    value: "catch",
                },
            },
        })
            .then((value) => {
                switch (value) {

                    case "cancel":
                        break;

                    case "catch":
                        archive(value);

                    default:
                        break;
                }
            });
    }




    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}

            >
                Action
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handlearchive} >Archive</MenuItem>
            </Menu>

        </div>
    );
};

export default Action;
