import React from "react";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SppoLayout from "layout/SppoLayout";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// Custom Styles
import useDashboardStyles from "assets/jss/pages/dashboardStyle";
import ProfileUpdate from "components/Sppo/ProfileUpdate/ProfileUpdate"

const ProfilePage = () => {
  const classes = useDashboardStyles();

  return (
    <SppoLayout>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Profile</p>
            </CardHeader>
            <CardBody>
              <ProfileUpdate />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </SppoLayout>
  );
};

export default ProfilePage;
