import React, { useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import { SliderDiv } from "./style";
import axios from "axios";


const CustomSlider = ({ data }) => {

  const [values, setValues] = useState("");

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/manager-admin/all-slider-list/LIN`
      );
      setValues(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    values.length > 0 ?

      <SliderDiv>
        <Carousel navButtonsAlwaysVisible>
          {values?.map((img, index) => (
            <div key={img?._id}>
              <img
                src={`${process.env.REACT_APP_BACKEND_API}/manager-admin/view-slider/sliderID/${img._id}`}
                alt="logo"
                style={{
                  width: "100%",
                }}
              />
            </div>
          ))}

        </Carousel>
      </SliderDiv>
      : null 
  );
};

export default CustomSlider;
