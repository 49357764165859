import { useHistory } from "react-router-dom";
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import Button from "@material-ui/core/Button";

import SearchOutlined from "@material-ui/icons/SearchOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import GridContainer from "components/Grid/GridContainer";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { useMediaQuery } from "@material-ui/core";

const LinguistOptions = () => {
  const history = useHistory();
  const classes = useEmployeeStyles();
  const matches = useMediaQuery("(max-width:425px)");

  return (
    <GridContainer justify="center" className={classes.container}>
      {matches ? (
        <>
          {" "}
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginBottom: "5px" }}
            onClick={() =>
              history.push("/employee/find-unregistered-linguists")
            }
          >
            Unregistered Linguist
          </Button>
          <Button
            startIcon={<VerifiedUserIcon />}
            color="primary"
            variant="contained"
            style={{ width: "100%" }}
            onClick={() => history.push("/employee/find-registered-linguists")}
          >
            Registered Linguist
          </Button>
          <Button
            startIcon={<SearchOutlined />}
            color="primary"
            variant="contained"
            style={{ width: "100%" }}
            onClick={() =>
              history.push("/employee/registered-unregistered-linguists-list")
            }
          >
            Search Linguist
          </Button>
          <Button
            startIcon={<SearchOutlined />}
            color="primary"
            variant="contained"
            style={{ width: "100%" }}
            onClick={() =>
              history.push(
                "/employee/registered-unregistered-multi-linguists-list"
              )
            }
          >
            Search Multilingual Linguist
          </Button>
        </>
      ) : (
        <>
          {" "}
          <Button
            startIcon={<ListAltIcon />}
            color="primary"
            variant="contained"
            style={{ width: "23%", marginLeft: "15px", marginRight: "15px" }}
            onClick={() =>
              history.push("/employee/find-unregistered-linguists")
            }
          >
            Unregistered Linguist
          </Button>
          <Button
            startIcon={<VerifiedUserIcon />}
            color="primary"
            variant="contained"
            style={{ width: "23%", marginRight: "15px" }}
            onClick={() => history.push("/employee/find-registered-linguists")}
          >
            Registered Linguist
          </Button>
          <Button
            startIcon={<SearchOutlined />}
            color="primary"
            variant="contained"
            style={{ width: "23%", marginRight: "15px" }}
            onClick={() =>
              history.push("/employee/registered-unregistered-linguists-list")
            }
          >
            Search Linguist
          </Button>
          <Button
            startIcon={<SearchOutlined />}
            color="primary"
            variant="contained"
            style={{ width: "23%", marginRight: "15px" }}
            onClick={() =>
              history.push(
                "/employee/registered-unregistered-multi-linguists-list"
              )
            }
          >
            Search Multilingual Linguist
          </Button>
        </>
      )}
    </GridContainer>
  );
};

export default LinguistOptions;
