import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useDispatch } from "react-redux";

import Cookies from "js-cookie";
// Material Core Components
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/Common/CustomInput";
import CustomPassword from "components/Common/CustomPassword";
import ForgotPassword from "./AccountForgot";

// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";

import LandinPageLayout from "layout/LandingPageLayout";
import CustomEmail from "components/Common/CustomEmail";
// Others
import CustomAvatar from "components/Common/CustomAvatar";
import CustomCaptcha from "components/Common/CustomCaptcha";
import Axios from "axios";
import { EMAIL_REGEX } from "data";

const BACLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [notValid, setNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    captcha: "",
  });

  const classes = useEmployeeStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    if (value !== "" && EMAIL_REGEX.test(value)) {
      setNotValid(false);
    } else {
      setNotValid(true);
    }
  };

  const handleSubmit = async () => {
    const sendData = { email: state.email, password: state.password };
    setLoading(true);
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/accountclient/signin`,
        sendData
      );

      const { token, stripeI_D, accountclient } = response?.data;

      // 1st Step -> Set token on browser
      Cookies.set("BACtoken", token);


      Cookies.set("BACstripeI_D", stripeI_D);
      Cookies.set("BACuser", accountclient);
      // 2nd step --> set user data
      localStorage.setItem("BACuser", JSON.stringify(accountclient));
      Cookies.set("isAuth", true);
      history.push("/account-client/dashboard");

      // swal({
      //   title: "Welcome",
      //   text: `${accountclient.FirstName} ${accountclient.LastName}`,
      //   icon: "success",
      //   buttons: false,
      // });
      setLoading(false);
      Axios.post(
        `${process.env.REACT_APP_BACKEND_API}/accountclient/post-activity-log`,
        {
          Device: Cookies.get("visitor_device"),
          Location: Cookies.get("visitor_address") || "permission denied",
          Browser: Cookies.get("visitor_browser"),
          IP: Cookies.get("visitor_ip"),
          Time: new Date(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      if (error?.response?.data?.error) {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `${error?.response?.data?.error}`,
          icon: "error",
          buttons: false,
        });
      } else {
        setLoading(false);
        swal({
          title: "Oops!",
          text: `Please check your internet connection.`,
          icon: "error",
          buttons: false,
        });
      }
    }
  };

  const handleCaptcha = (value) => {
    setState({
      ...state,
      captcha: value,
    });
  };

  const { email, password, captcha } = state;

  return (
    <LandinPageLayout>
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    Billing Account Client (BAC) Login
                  </Typography>
                </div>
                <form method="POST">
                  {/* <CustomInput
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    value={email}
                  /> */}

                  <CustomEmail
                    onChange={handleEmailChange}
                    label="Email"
                    width="100%"
                    name="email"
                    value={email}
                    type="email"
                    placeholder="Your registered primary email "
                  />
                  {notValid && (
                    <small style={{ color: "red" }}>
                      enter valid email address
                    </small>
                  )}

                  <CustomPassword
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    value={password}
                    placeholder="Enter your password"
                  />

                  <CustomCaptcha onChange={handleCaptcha} />

                  <div className={classes.buttonDiv}>
                    <Button
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!email || !password || !captcha || loading}
                      variant="contained"
                    >
                      {loading ? "Logging..." : "Login"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => history.push("/clientacc-opening-form")}
                    >
                      Register
                    </Button>
                  </div>
                </form>
                <div className={classes.forgotDiv}>
                  <span />
                  <ForgotPassword />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </LandinPageLayout>
  );
};

export default BACLogin;
