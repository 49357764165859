import React from "react";
import { Table } from "semantic-ui-react";
import GridContainer from "components/Grid/GridContainer";
import moment from "moment";

const Items = ({ values }) => {
  return (
    <GridContainer alignItems="center">
       <p>
        {values?.bank_pay
          ? "Paid by bank transfer"
          : values?.pay_later
          ? "Pay Later / UK Legal Aid Job"
          : values?.charge_id
          ? "Paid by stripe"
          : null}
      </p>
      <Table color={"pink"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Translation Details</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>

          <Table.Row>
            <Table.Cell>
              Assignment Reference ID
            </Table.Cell>
            <Table.Cell>
              {values?.id == "undefined" ? "N/A" : values?.id ? values?.id : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              Order reference number or purchase order number
            </Table.Cell>
            <Table.Cell>
              {values?.purchase_order_number == "undefined"
                ? " "
                : values?.purchase_order_number
                  ? values?.purchase_order_number
                  : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Document type</Table.Cell>
            <Table.Cell>
              {values?.document_type == "undefined"
                ? " "
                : values?.document_type
                  ? values?.document_type
                  : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Other details</Table.Cell>
            <Table.Cell>
              {values?.other_details == "undefined"
                ? " "
                : values?.other_details
                  ? values?.other_details
                  : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Source Language</Table.Cell>
            <Table.Cell>
              {values?.source_language ? values?.source_language : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Source language expertise level</Table.Cell>
            <Table.Cell>
              {values?.source_expertise_level_required
                ? values?.source_expertise_level_required
                : values?.source_language_skill_level
                  ? values?.source_language_skill_level
                  : values?.source_linguist_skill_level}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target Language</Table.Cell>
            <Table.Cell>
              {values?.target_language ? values?.target_language : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Target language expertise level</Table.Cell>
            <Table.Cell>
              {values?.target_expertise_level_required
                ? values?.target_expertise_level_required
                : values?.target_language_skill_level
                  ? values?.target_language_skill_level
                  : values?.target_linguist_skill_level}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Require visual formating</Table.Cell>
            <Table.Cell>
              {values?.require_visual_formating == "undefined"
                ? " "
                : values?.require_visual_formating
                  ? values?.require_visual_formating
                  : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Translation certification requirement</Table.Cell>
            <Table.Cell>
              {values?.certified_translation == "undefined"
                ? " "
                : values?.certified_translation
                  ? values?.certified_translation
                  : "N/A"}
            </Table.Cell>
          </Table.Row>

          {/* <Table.Row >
                        <Table.Cell>Certificate</Table.Cell>
                        <Table.Cell>
                            {values?.certificate == "undefined" ? " " : values?.certificate}
                        </Table.Cell>
                    </Table.Row> */}

          <Table.Row>
            <Table.Cell>Additional quality check</Table.Cell>
            <Table.Cell>
              {values?.additional_quality_check == "undefined"
                ? " "
                : values?.additional_quality_check
                  ? values?.additional_quality_check
                  : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Word count</Table.Cell>
            <Table.Cell>
              {values?.wordCount
                ? values?.wordCount
                : values?.count
                  ? values?.count
                  : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Preferred Linguist / Provider ID Number</Table.Cell>
            <Table.Cell>
              {values?.provider_id_number == "undefined"
                ? " "
                : values?.provider_id_number
                  ? values?.provider_id_number
                  : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Additional note</Table.Cell>
            <Table.Cell>
              {values?.additional_note == "undefined"
                ? " "
                : values?.additional_note
                  ? values?.additional_note
                  : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Security clearence</Table.Cell>
            <Table.Cell>
              {values?.security_clearance == "undefined"
                ? " "
                : values?.security_clearance
                  ? values?.security_clearance
                  : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Timezone</Table.Cell>
            <Table.Cell>{values?.timezone}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Translation needed by</Table.Cell>
            <Table.Cell>
              {moment(values?.deadline).format("DD-MMM-YYYY, HH:mm")}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Estimated value / estimate paid</Table.Cell>
            <Table.Cell>
              {`${values?.estimate_paid_amount
                ? values?.estimate_paid_amount
                : values?.estimate
                } ${values?.currency_new
                  ? values?.currency_new?.substring(0, 3)
                  : values?.currency?.substring(0, 3)
                }`}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Date and Time of Placing Order (London Time)</Table.Cell>
            <Table.Cell>
              {moment(values.createdAt).tz("Europe/London").format('DD-MMM-YYYY, HH:mm')}
            </Table.Cell>
          </Table.Row>

        </Table.Body>
      </Table>
    </GridContainer>
  );
};

export default Items;