import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Styles
import useFormStyles from "assets/jss/components/auth/formStyle";
import history from "utils/history";
import { useToasts } from "react-toast-notifications";

import {
  changeInput,
  updateProfileLinguistPageTwo,
} from "redux/actions/lcAction";
import CustomBigInputSingle from "components/Common/CustomBigInputSingle";
import {
  CURRENT_COUNTRY,
  PROFESSIONAL_LIABILITY_INSURANCE,
  SIMPLE_QS,
} from "data";
import CustomSelect from "components/Common/CustomSelect";
import { Dimmer, Loader } from "semantic-ui-react";
import CustomAutoComplete from "components/Common/CustomAutoComplete";
import { Button, Header, Progress } from "semantic-ui-react";
import Axios from "axios";
import Cookies from "js-cookie";
import CustomDateTwo from "components/Common/CustomDateTwo";
import swal from "sweetalert";

const PageTwo = ({ setReload }) => {
  const { data } = useSelector((state) => state.lcReducer);
  const dispatch = useDispatch();
  const [saveLoader, setSaveLoader] = useState(false);
  const token = Cookies.get("LINtoken");
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const fileInput3 = useRef(null);
  const [loader, setLoader] = useState(true);
  const [passProgress, setPassProgress] = useState(0);
  const [relevantProgress, setRelevantProgress] = useState(0);
  const [insuranceProgress, setInsuranceProgress] = useState(0);
  const [next, setNext] = useState(false);

  const { addToast } = useToasts();
  const getLinguistDetails = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/get-details-page2`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        updateProfileLinguistPageTwo({
          id: response?.data._id,
          work_in_the_uk: response?.data.work_in_the_uk,
          eu_all_other_countries: response?.data.eu_all_other_countries,
          ni_number: response?.data.ni_number,
          utr_number: response?.data.utr_number,
          vat_number: response?.data.vat_number,
          liability_insurance: response?.data.liability_insurance,
          liability_insurance_expiry_date:
            response?.data.liability_insurance_expiry_date,
          passport_ni_photo: response?.data.passport_true,
          relevant_document_photo: response?.data.relevant_document_photo,
          insurance_document: response?.data.insurance_document,
        })
      );
      setLoader(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
      setLoader(false);
    }
  };

  const [viewPassport, setViewPassport] = useState(null);
  const PassportDoc = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-passport-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewPassport(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  const [viewRelevant, setViewRelevantDoc] = useState(null);
  const RelevantDoc = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-relevent-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewRelevantDoc(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  const [viewInsurance, setViewInsuranceDoc] = useState(null);
  const InsuranceDoc = async (e) => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/linguists/latest-insurance-document`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response?.data);
      setViewInsuranceDoc(response?.data);
    } catch (error) {
      if (error.response) {
        addToast("Something went wrong!", { appearance: "error" });
      }
    }
  };

  useEffect(() => {
    getLinguistDetails();
    PassportDoc();
    RelevantDoc();
    InsuranceDoc();
  }, []);

  const {
    id,
    work_in_the_uk,
    eu_all_other_countries,
    ni_number,
    utr_number,
    vat_number,
    liability_insurance,
    liability_insurance_expiry_date,
    passport_ni_photo,
    relevant_document_photo,
    insurance_document,
  } = data;

  const classes = useFormStyles();

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleRelevantClick = (e) => {
    e.preventDefault();
    fileInput2.current.click();
  };

  const handleInsuranceClick = (e) => {
    e.preventDefault();
    fileInput3.current.click();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaveLoader(true);
    let fd = new FormData();

    fd.append("work_in_the_uk", data.work_in_the_uk || "");
    fd.append("eu_all_other_countries", data.eu_all_other_countries);
    fd.append("ni_number", data.ni_number || "");
    fd.append("utr_number", data.utr_number || "");
    fd.append("vat_number", data.vat_number || "");
    fd.append("liability_insurance", data.liability_insurance || "");
    fd.append(
      "liability_insurance_expiry_date",
      data.liability_insurance_expiry_date || ""
    );
    // if (data.passport_ni_photo?.name) {
    //   fd.append("passport_ni_photo", data.passport_ni_photo);
    //   fd.append("passport_true", true || false);
    // }
    // if (data.relevant_document_photo?.name) {
    //   fd.append("relevant_document_photo", data.relevant_document_photo);
    // }
    // if (data.insurance_document?.name) {
    //   fd.append("insurance_document", data.insurance_document);
    // }

    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/update-full-registration`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((val) => {
        setReload(Math.random());
      });
      setSaveLoader(false);
      setNext(true);
    } catch (error) {
      console.log(error.response);
      setSaveLoader(false);
    }
  };

  const handleNext = () => {
    history.push("/linguist/full-registration-p3");
  };
  const handleBack = () => {
    history.push("/linguist/full-registration-p1");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeInput({
        name,
        value,
      })
    );
  };

  const handleAutoComplete = (e, value) => {
    dispatch(
      changeInput({
        name: "eu_all_other_countries",
        value,
      })
    );
  };

  const handleDateChange = (e) => {
    dispatch(
      changeInput({
        name: "liability_insurance_expiry_date",
        value: e.target.value,
      })
    );
  };

  const uploadRelevant = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("relevant_document_photo", file);
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-relevant-file`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setRelevantProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      RelevantDoc();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };
  const uploadPassport = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("passport_ni_photo", file);
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-passport-file`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setPassProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      PassportDoc();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };
  const uploadInsurance = async (file) => {
    let fd = new FormData();

    if (file) {
      fd.append("insurance_document", file);
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_BACKEND_API}/linguists/upload-insurance-file`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setInsuranceProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );

      InsuranceDoc();
    } catch (error) {
      console.log(error);
      swal({
        title: "Oops!",
        text: `Document could not be upload, please try again`,
        icon: "error",
        buttons: false,
      });
    }
  };

  const handleRelevantFiles = async (e) => {
    if (e.target?.files[0]?.size > 3000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 3mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      dispatch(
        changeInput({
          name: "relevant_document_photo",
          value: e.target.files[0],
        })
      );
      uploadRelevant(e.target?.files[0]);
    }
  };

  const handleInsuranceFiles = async (e) => {
    if (e.target?.files[0]?.size > 3000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 3mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      dispatch(
        changeInput({
          name: "insurance_document",
          value: e.target.files[0],
        })
      );
      uploadInsurance(e.target?.files[0]);
    }
  };

  const handlePassportFiles = async (e) => {
    if (e.target?.files[0]?.size > 3000000) {
      swal({
        title: "Oops!",
        text: `File size must be less than 3mb`,
        icon: "error",
        buttons: false,
      });
    } else {
      dispatch(
        changeInput({
          name: "passport_ni_photo",
          value: e.target.files[0],
        })
      );
      uploadPassport(e.target?.files[0]);
    }
  };

  if (loader) {
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  const isAllow = !passport_ni_photo;

  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <CustomSelect
          onChange={handleChange}
          width="100%"
          label="Do you have right to work in the UK?"
          moreInfo="For applicant in the UK"
          name="work_in_the_uk"
          value={work_in_the_uk}
          data={SIMPLE_QS}
        />

        <CustomAutoComplete
          onChange={handleAutoComplete}
          width="100%"
          label="Please list below EU and all other countries that you have right to work"
          name="eu_all_other_countries"
          data={CURRENT_COUNTRY}
        />

        <CustomBigInputSingle
          onChange={handleChange}
          label="National Insurance (NI) Number, Social Security Number"
          moreInfo="Or equivalent"
          width="100%"
          name="ni_number"
          value={ni_number}
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="PTR / UTR number"
          moreInfo="Personal tax reference number / Unique tax reference"
          width="100%"
          name="utr_number"
          value={utr_number}
        />
        <CustomBigInputSingle
          onChange={handleChange}
          label="VAT / GST number"
          moreInfo="If registered"
          width="100%"
          name="vat_number"
          value={vat_number}
        />
        <CustomSelect
          onChange={handleChange}
          width="100%"
          label="Do you have professional indemnity insurance and public liability insurance?"
          moreInfo="Please provide expiry date (for liability insurance)"
          name="liability_insurance"
          value={liability_insurance}
          data={PROFESSIONAL_LIABILITY_INSURANCE}
        />
        <CustomDateTwo
          onChange={handleDateChange}
          label="Professional indemnity insurance and public liability insurance expiry date"
          width="100%"
          name="liability_insurance_expiry_date"
          value={liability_insurance_expiry_date}
        />
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "24px",
            }}
          >
            <input
              accept=".pdf,.doc,.jpeg"
              required
              name="passport_ni_photo"
              ref={fileInput}
              onChange={handlePassportFiles}
              type="file"
              style={{ display: "none" }}
            />
            <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
              Please upload copy of your passport, national ID, residence permit
              card here* <b style={{ color: "red" }}>***</b>
            </Header>
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={handleClick}>Upload</Button>
            </div>
            {passport_ni_photo?.name && (
              <div>
                <Header style={{ margin: "0" }} as="h4">
                  {passport_ni_photo?.name}
                </Header>
                <Header
                  style={{ margin: "0" }}
                  as="h5"
                >{`${passport_ni_photo?.lastModifiedDate?.toDateString()}`}</Header>
                <Header style={{ margin: "0" }} as="h5">{`${(
                  passport_ni_photo?.size / 1024
                ).toFixed(2)}kb`}</Header>
              </div>
            )}

            {viewPassport?.passport_ni_photo_true && (
              <a href={`/linguist/linguist-view-passport/${viewPassport?._id}`}>
                View document
              </a>
            )}
          </div>
        </div>
        {passProgress > 0 && (
          <Progress percent={passProgress} indicating autoSuccess progress>
            {" "}
            Document uploaded
          </Progress>
        )}
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "24px",
            }}
          >
            <input
              accept=".pdf,.doc,.jpeg"
              name="relevant_document_photo"
              ref={fileInput2}
              onChange={handleRelevantFiles}
              type="file"
              style={{ display: "none" }}
            />
            <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
              Add any other relevant document here
            </Header>
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={handleRelevantClick}>Upload</Button>
            </div>
            {relevant_document_photo?.name && (
              <div>
                <Header style={{ margin: "0" }} as="h4">
                  {relevant_document_photo?.name}
                </Header>
                <Header
                  style={{ margin: "0" }}
                  as="h5"
                >{`${relevant_document_photo?.lastModifiedDate?.toDateString()}`}</Header>
                <Header style={{ margin: "0" }} as="h5">{`${(
                  relevant_document_photo?.size / 1024
                ).toFixed(2)}kb`}</Header>
              </div>
            )}

            {viewRelevant?.relevant_document_photo_true && (
              <a
                href={`/linguist/linguist-view-relevent-document/${viewRelevant?._id}`}
              >
                View document
              </a>
            )}
          </div>
        </div>
        {relevantProgress > 0 && (
          <Progress percent={relevantProgress} indicating autoSuccess progress>
            {" "}
            Document uploaded
          </Progress>
        )}
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "24px",
            }}
          >
            <input
              accept=".pdf,.doc,.jpeg"
              name="insurance_document"
              ref={fileInput3}
              onChange={handleInsuranceFiles}
              type="file"
              style={{ display: "none" }}
            />
            <Header style={{ margin: "0", marginBottom: "5px" }} as="h5">
              Add insurance document here
            </Header>
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={handleInsuranceClick}>Upload</Button>
            </div>
            {insurance_document?.name && (
              <div>
                <Header style={{ margin: "0" }} as="h4">
                  {insurance_document?.name}
                </Header>
                <Header
                  style={{ margin: "0" }}
                  as="h5"
                >{`${insurance_document?.lastModifiedDate?.toDateString()}`}</Header>
                <Header style={{ margin: "0" }} as="h5">{`${(
                  insurance_document?.size / 1024
                ).toFixed(2)}kb`}</Header>
              </div>
            )}

            {viewInsurance?.insurance_document_true && (
              <a
                href={`/linguist/linguist-view-insurance-document/${viewInsurance?._id}`}
              >
                View document
              </a>
            )}
            <br />
          </div>
        </div>
        {insuranceProgress > 0 && (
          <Progress percent={insuranceProgress} indicating autoSuccess progress>
            {" "}
            Document uploaded
          </Progress>
        )}
        <p>
          * If you face issues uploading document or need to upload more
          documentation, you can do that later after completing your full
          registration by updating your profile.
        </p>

        <div className={classes.buttonFlex} style={{ marginTop: "24px" }}>
          <Button basic color="purple" onClick={handleBack}>
            Back
          </Button>
          {!next ? (
            <Button
              loading={saveLoader}
              color="purple"
              disabled={isAllow}
              onClick={handleSave}
            >
              Save
            </Button>
          ) : (
            <Button color="green" onClick={handleNext}>
              Next
            </Button>
          )}
          {/* <Button basic color="purple" onClick={handleSkip}>
            Skip
          </Button> */}
        </div>
      </form>
    </div>
  );
};

export default PageTwo;
