import React, { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { changeTimesheetInput } from "redux/actions/timesheetAction";
import moment from "moment";
import CustomSemanticDD from "components/Common/CustomSemanticDD";
import { DURATION } from "data";
import CustomTime from "components/Common/CustomTime";
import { TextField } from "@material-ui/core";

const Items = ({ details }) => {
  const actualDuration = details.actual_duration[0].split(",");
  const dispatch = useDispatch();
  const [duration, setDuration] = useState();
  const [state, setState] = useState({
    level: "",
    gender: "",
    start_date: [],
    end_date: "",
    start_time: [],
    end_time: "",
    singleDurations: [],
  });

  useEffect(() => {
    if (details?.cacID?.enddateArray) {
      dispatch(
        changeTimesheetInput({
          name: "end_date",
          value: details?.cacID.enddateArray,
        })
      );
      dispatch(
        changeTimesheetInput({
          name: "end_time",
          value: details?.cacID.endtimeArray,
        })
      );
    } else if (details?.bacID?.enddateArray) {
      dispatch(
        changeTimesheetInput({
          name: "end_date",
          value: details?.bacID.enddateArray,
        })
      );
      dispatch(
        changeTimesheetInput({
          name: "end_time",
          value: details?.bacID.endtimeArray,
        })
      );
    } else if (details?.inhouseID?.enddateArray) {
      dispatch(
        changeTimesheetInput({
          name: "end_date",
          value: details?.inhouseID.enddateArray,
        })
      );
      dispatch(
        changeTimesheetInput({
          name: "end_time",
          value: details?.inhouseID.endtimeArray,
        })
      );
    }
  }, []);

  const handleDateArray = (newVal, index) => {
    let temp = state.start_date;
    temp.splice(index, 1, newVal);
    setState({ ...state, start_date: temp });
    dispatch(
      changeTimesheetInput({
        name: "start_date",
        value: temp,
      })
    );
  };
  const handleTimeArray = (newVal, index) => {
    let temp = state.start_time;
    temp.splice(index, 1, newVal);
    setState({ ...state, start_time: temp });
    dispatch(
      changeTimesheetInput({
        name: "start_time",
        value: temp,
      })
    );
  };
  const handleDurationArray = (newVal, index) => {
    let temp = state.singleDurations;
    temp.splice(index, 1, newVal);
    setState({ ...state, singleDurations: temp });
    let dur = 0;
    state.singleDurations.map((val) => {
      dur += moment.duration(moment(val, "HHmm").format("HH:mm")).asHours();
    });
    setDuration(dur);
    dispatch(
      changeTimesheetInput({
        name: "duration",
        value: dur,
      })
    );
    let actual = [];
    state.singleDurations.map((val) => {
      actual.push(
        moment.duration(moment(val, "HHmm").format("HH:mm")).asHours()
      );
    });
    dispatch(
      changeTimesheetInput({
        name: "actual_duration",
        value: actual,
      })
    );
  };
  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="5">Job Details</Table.HeaderCell>
            <Table.HeaderCell width="3">Requested</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {/* {unverifiedLinguistList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list) => ( */}
          <Table.Row hover="true" role="checkbox" tabIndex={-1}>
            <Table.Cell>
              Level of expertise required source / native language (
              {details.cacID?.source_language
                ? details.cacID?.source_language
                : details.bacID?.source_language
                ? details.bacID?.source_language
                : details.inhouseID?.source_language}
              )
            </Table.Cell>

            <Table.Cell>
              {details.cacID?.source_linguist_skill_level
                ? details.cacID?.source_linguist_skill_level
                : details.bacID?.source_linguist_skill_level
                ? details.bacID?.source_linguist_skill_level
                : details.inhouseID?.source_language_level}
            </Table.Cell>

            <Table.Cell>
              {details.postedBy?.source_language_level
                ? details.postedBy?.source_language_level
                : "N/A"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Level of expertise required target language (
              {details.cacID?.target_language
                ? details.cacID?.target_language
                : details.bacID?.target_language
                ? details.bacID?.target_language
                : details.inhouseID?.target_language}
              )
            </Table.Cell>

            <Table.Cell>
              {details.cacID?.target_linguist_skill_level
                ? details.cacID?.target_linguist_skill_level
                : details.bacID?.target_linguist_skill_level
                ? details.bacID?.target_linguist_skill_level
                : details.inhouseID?.target_language_level}
            </Table.Cell>

            <Table.Cell>
              {details.postedBy?.target_language_level
                ? details.postedBy?.target_language_level
                : "N/A"}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Timezone</Table.Cell>
            <Table.Cell>
              {details.cacID?.timezone
                ? details.cacID?.timezone
                : details.bacID?.timezone
                ? details.bacID?.timezone
                : details.inhouseID?.timezone}
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table celled>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell width="3">
              Appointment start date
            </Table.HeaderCell> */}
            <Table.HeaderCell width="3">
              Actual appointment start date
            </Table.HeaderCell>
            {/* <Table.HeaderCell width="3">Start Time</Table.HeaderCell> */}
            <Table.HeaderCell width="3">Actual start Time</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual duration </Table.HeaderCell>
            <Table.HeaderCell width="3">Appointment end date</Table.HeaderCell>
            <Table.HeaderCell width="3">End Time</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {/* <Table.HeaderCell width="3">
            {details?.cacID?.startdateArray
              ? details?.cacID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(st).tz("Europe/London").format("DD-MMM-YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.bacID?.startdateArray
              ? details?.bacID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(st).tz("Europe/London").format("DD-MMM-YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.inhouseID?.startdateArray
              ? details?.inhouseID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(st).tz("Europe/London").format("DD-MMM-YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell> */}
          <Table.HeaderCell width="3">
            {details?.cacID?.startdateArray
              ? details?.cacID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st, i) => (
                    <Table.Row>
                      <Table.Cell>
                        <TextField
                          type="date"
                          inputProps={{
                            min: (i !== 0
                              ? moment(state.start_date[i - 1], "yyyy-MM-DD")
                              : moment()
                            )
                              .add(1, "d")
                              .format("yyyy-MM-DD"),
                          }}
                          onChange={(e) => handleDateArray(e.target.value, i)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}
            {details?.bacID?.startdateArray
              ? details?.bacID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st, i) => (
                    <Table.Row>
                      <Table.Cell>
                        <TextField
                          type="date"
                          inputProps={{
                            min: (i !== 0
                              ? moment(state.start_date[i - 1], "yyyy-MM-DD")
                              : moment()
                            )
                              .add(1, "d")
                              .format("yyyy-MM-DD"),
                          }}
                          onChange={(e) => handleDateArray(e.target.value, i)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}
            {details?.inhouseID?.startdateArray
              ? details?.inhouseID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st, i) => (
                    <Table.Row>
                      <Table.Cell>
                        <TextField
                          type="date"
                          inputProps={{
                            min: (i !== 0
                              ? moment(state.start_date[i - 1], "yyyy-MM-DD")
                              : moment()
                            )
                              .add(1, "d")
                              .format("yyyy-MM-DD"),
                          }}
                          onChange={(e) => handleDateArray(e.target.value, i)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>
          {/* <Table.HeaderCell width="3">
            {details?.cacID?.starttimeArray
              ? details?.cacID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.bacID?.starttimeArray
              ? details?.bacID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.inhouseID?.starttimeArray
              ? details?.inhouseID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell> */}

          <Table.HeaderCell width="3">
            {details?.cacID?.starttimeArray
              ? details?.cacID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st, i) => (
                    <Table.Row>
                      <Table.Cell>
                        <CustomTime
                          onChange={(e) => handleTimeArray(e.target.value, i)}
                          width="100%"
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}
            {details?.bacID?.starttimeArray
              ? details?.bacID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st, i) => (
                    <Table.Row>
                      <Table.Cell>
                        <CustomTime
                          onChange={(e) => handleTimeArray(e.target.value, i)}
                          width="100%"
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}
            {details?.inhouseID?.starttimeArray
              ? details?.inhouseID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st, i) => (
                    <Table.Row>
                      <Table.Cell>
                        <CustomTime
                          onChange={(e) => handleTimeArray(e.target.value, i)}
                          width="100%"
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>
          <Table.HeaderCell width="3">
            {actualDuration
              ? actualDuration
                  .filter((dt) => dt != "")
                  .map((st, i) => (
                    <Table.Row>
                      <Table.Cell>
                        <CustomSemanticDD
                          label="Add duration"
                          data={DURATION}
                          onChange={(e) =>
                            handleDurationArray(e.target.value, i)
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.cacID?.enddateArray
              ? details?.cacID?.enddateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(st).tz("Europe/London").format("DD-MMM-YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.bacID?.enddateArray
              ? details?.bacID?.enddateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(st).tz("Europe/London").format("DD-MMM-YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.inhouseID?.enddateArray
              ? details?.inhouseID?.enddateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(st).tz("Europe/London").format("DD-MMM-YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.cacID?.endtimeArray
              ? details?.cacID?.endtimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.bacID?.endtimeArray
              ? details?.bacID?.endtimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}

            {details?.inhouseID?.endtimeArray
              ? details?.inhouseID?.endtimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
              : null}
          </Table.HeaderCell>

          <Table.Row>
            <Table.Cell>Total Duration</Table.Cell>
            <Table.Cell>
              {details.cacID?.gethour
                ? details.cacID?.gethour
                : details.bacID?.gethour
                ? details.bacID?.gethour
                : details.inhouseID?.total_Duration}
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Actual Total Duration</Table.Cell>
            <Table.Cell>
              {duration
                ? duration
                : details.cacID?.gethour
                ? details.cacID?.gethour
                : details.bacID?.gethour
                ? details.bacID?.gethour
                : details.inhouseID?.total_Duration}
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default Items;
