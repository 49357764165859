import SearchInput from "./SearchInput";

const SearchBACAssignments = ({show,handleSearch,search,load,setSearch,setShow}) => {
  return (
    <div>
      <SearchInput show={show} setShow={setShow} setSearch={setSearch} load={load} handleSearch={handleSearch} search={search}/>

    </div>
  );
};

export default SearchBACAssignments;
