import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Action from "components/CashClient/Assignments/ConfirmAssignments/ListInterpreters/Actions/Action";
import { Table } from "semantic-ui-react";
import Axios from "axios";
import Cookies from "js-cookie";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import SearchView from "./Search/SearchView";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ListCACAssignments({ interpretingData, text }) {
  const token = Cookies.get("CACtoken");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(0);
  const [show, setShow] = useState(false);
  const [paidModal, setPaidModal] = React.useState(false);
  const [sign, setSign] = useState("");
  const [signature, setSignature] = useState(false);
  let sigPad = useRef({});

  const handleClose = (e) => {
    setSignature(false);
  };

  const handleClear = (e) => {
    sigPad.current.clear();
  };

  const handleSave = (e) => {
    const temp = sigPad.current.toDataURL();
    setSign(temp);

    setSignature(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    UnconfirmBookingList: null,
    removed: false,
    reload: false,
    loader: false,
    AdminID: "",
  });

  const {
    name,
    error,
    success,
    UnconfirmBookingList,
    removed,
    reload,
    loader,
  } = values;

  useEffect(() => {
    loadUnconfirmBookingList();
  }, [reload, filter]);

  const loadUnconfirmBookingList = async (e) => {
    Axios.all([
      Axios.get(
        `${process.env.REACT_APP_BACKEND_API}/cashclient/booking-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]).then(
      Axios.spread((unconfirmedJobs) => {
        filtered.length > 0
          ? setValues({ ...values, UnconfirmBookingList: filtered })
          : setValues({
              ...values,
              UnconfirmBookingList: interpretingData
                ? unconfirmedJobs.data.filter(
                    (item) => item.id === interpretingData
                  )
                : unconfirmedJobs.data,
            });
        interpretingData && setShow(true);
        setFiltered([]);
      })
    );
  };

  const a = UnconfirmBookingList?.map((uc) => uc);

  const exportPDF = (e) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Interpreting Assignments";
    const headers = [
      [
        "Assignment ID",
        "Timezone",
        "Start date & time",
        "Duration",
        "Source language",
        "Target language",
        "Service Required",
        "Venue/Remote",
        "Status",
        "Estimate",
      ],
    ];

    const data = UnconfirmBookingList.map((list) => [
      list.id,
      list.timezone,
      list.startdateArray,
      list.gethour,
      list.source_language,
      list.target_language,
      list.interpreting_service_required,
      list.country,
      !list.accepted
        ? "Linguist allocated"
        : "Awaiting new linguist allocation",
      list.estimate,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    var string = doc.output("datauristring");
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
    var x = window.open();
    doc.save("confirmed-interpreting-list-pdf");
    x.document.open();
    x.document.write(embed);
    x.document.close();
  };

  return (
    <>
      {!interpretingData && (
        <SearchView
          values={UnconfirmBookingList}
          SearchText={searchText}
          setSearchText={setSearchText}
          setFiltered={setFiltered}
          load={loadUnconfirmBookingList}
          setShow={setShow}
          setFilter={setFilter}
          show={show}
        />
      )}
      {show && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                  <Table.HeaderCell>Timezone</Table.HeaderCell>
                  <Table.HeaderCell>Start Date & Time</Table.HeaderCell>
                  <Table.HeaderCell>Duration</Table.HeaderCell>
                  <Table.HeaderCell>Source language</Table.HeaderCell>
                  <Table.HeaderCell>Target language</Table.HeaderCell>
                  <Table.HeaderCell>Service Required</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Estimate paid</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {UnconfirmBookingList ? (
                <Table.Body>
                  {UnconfirmBookingList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((list, i) => (
                    <Table.Row
                      style={
                        list?.timesheetID?.invoice
                          ? { backgroundColor: "red", color: "white" }
                          : list.timesheet
                          ? { backgroundColor: "lightgreen" }
                          : { backgroundColor: "yellow" }
                      }
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                    >
                      <Table.Cell>
                        <Link
                          to={`/cash-client/view-cash-client-booking-interpreting/${list._id}`}
                        >
                          {list.id}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{list.timezone}</Table.Cell>
                      <Table.Cell>
                        {moment(list.startdateArray[0]).format("DD-MMM-YYYY")} -{" "}
                        {list?.starttimeArray[0]}
                      </Table.Cell>
                      <Table.Cell>{list.gethour}</Table.Cell>
                      <Table.Cell>{list.source_language}</Table.Cell>
                      <Table.Cell>{list.target_language}</Table.Cell>
                      <Table.Cell>
                        {list.typesof_interpreting_required}
                      </Table.Cell>
                      <Table.Cell>
                        {list?.timesheetID?.invoice
                          ? "Invoiced by linguist"
                          : list?.timesheetID?.signatureByLINTrue &&
                            list?.timesheetID?.signatureByCACTrue
                          ? "Timesheet accepted by both"
                          : list.accept && list.timesheet
                          ? list.job_status
                          : list.accept
                          ? "Linguist accepted"
                          : "Awaiting new linguist allocation"}{" "}
                        <br />
                        {list?.cancel ? "Cancel request sent to admin" : null}
                      </Table.Cell>
                      <Table.Cell>
                        {list?.currency_new?.substring(0, 3)}
                        {list.estimate}
                      </Table.Cell>
                      <Table.Cell>
                        <Action
                          load={loadUnconfirmBookingList}
                          id={list._id}
                          source={
                            list?.linID?.source_language
                              ?.toString()
                              .split(" ")[0]
                          }
                          target={
                            list?.linID?.target_language
                              ?.toString()
                              .split(" ")[0]
                          }
                          linID={list.linID?._id}
                          accept={list.accept}
                          cancel={list.cancel}
                          timesheet={list.timesheet}
                          timesheetID={list.timesheetID?._id}
                          invoice={list?.timesheetID?.invoice}
                          invoiceID={list?.timesheetID?.invoiceID?._id}
                          cacTimesheet={list.timesheetID?.signatureByCACTrue}
                          linTimesheet={list.timesheetID?.signatureByLINTrue}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              ) : null}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={UnconfirmBookingList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
}
