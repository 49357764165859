import React, { Fragment } from "react";

import UploadCV from "components/Auth/UploadCV/UploadCV";

const UploadCVPage = () => {
  return (
    <Fragment>
        <UploadCV />
    </Fragment>
  );
};

export default UploadCVPage;
