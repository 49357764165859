import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { changeTimesheetInput } from "redux/actions/timesheetAction";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const Items = ({ details }) => {

  const startTime = details?.start_time[0].split(",");
  const startDate = details?.start_date[0].split(",");
  const actualDuration = details?.actual_duration[0].split(",");


  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="5">Job Details</Table.HeaderCell>
            <Table.HeaderCell width="3">Requested</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {/* {unverifiedLinguistList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list) => ( */}
          <Table.Row hover="true" role="checkbox" tabIndex={-1}>
            <Table.Cell>
              Level of expertise required source / native language (
              {details?.postedBy?.source_language})
            </Table.Cell>
            <Table.Cell>{details?.inhouseID?.source_language_level}</Table.Cell>
            <Table.Cell>{details?.postedBy?.source_language_level}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Level of expertise required target language (
              {details?.postedBy?.target_language})
            </Table.Cell>
            <Table.Cell>{details?.inhouseID?.target_language_level}</Table.Cell>
            <Table.Cell>{details?.postedBy?.target_language_level}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Timezone</Table.Cell>
            <Table.Cell>{details?.inhouseID?.timezone}</Table.Cell>
            <Table.Cell>{details?.inhouseID?.timezone}</Table.Cell>
          </Table.Row>    
        </Table.Body>
      </Table>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="3">
              Appointment start date
            </Table.HeaderCell>
            <Table.HeaderCell width="3">
              Actual appointment start date
            </Table.HeaderCell>
            <Table.HeaderCell width="3">Start Time</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual start Time</Table.HeaderCell>
            <Table.HeaderCell width="3">Appointment Duration</Table.HeaderCell>
            <Table.HeaderCell width="3">Actual Duration</Table.HeaderCell>

          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.HeaderCell width="3">
            {details?.inhouseID?.startdateArray
              ? details?.inhouseID?.startdateArray
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>
                      {moment(st).tz("Europe/London").format("DD-MMM-YYYY")}
                    </Table.Cell>
                  </Table.Row>
                ))
              : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.start_date[0] !== ""
              ? startDate
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>
                      {moment(st).format("DD-MMM-YYYY")}
                    </Table.Cell>
                  </Table.Row>
                ))
              : details?.inhouseID?.startdateArray
                ? details?.inhouseID?.startdateArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
                : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.inhouseID?.starttimeArray
              ? details?.inhouseID?.starttimeArray
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>{st}</Table.Cell>
                  </Table.Row>
                ))
              : null}

          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.start_time[0] !== ""
              ? startTime
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>{st}</Table.Cell>
                  </Table.Row>
                ))
              : details?.inhouseID?.starttimeArray
                ? details?.inhouseID?.starttimeArray
                  .filter((dt) => dt != "")
                  .map((st) => (
                    <Table.Row>
                      <Table.Cell>{st}</Table.Cell>
                    </Table.Row>
                  ))
                : null}
          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.inhouseID?.durationArray
              ? details?.inhouseID?.durationArray
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>
                      {st}
                    </Table.Cell>
                  </Table.Row>
                ))
              : null}

          </Table.HeaderCell>

          <Table.HeaderCell width="3">
            {details?.actual_duration[0] !== ""
              ? actualDuration
                .filter((dt) => dt != "")
                .map((st) => (
                  <Table.Row>
                    <Table.Cell>{st}</Table.Cell>
                  </Table.Row>
                ))
              : null}

          </Table.HeaderCell>

          <Table.Row>
            <Table.Cell>Total Duration</Table.Cell>

            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              {details?.inhouseID?.total_Duration}
            </Table.Cell>
            <Table.Cell>{details?.duration}</Table.Cell>
          </Table.Row>

        </Table.Body>
      </Table>
    </>
  );
};

export default Items;
