import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "semantic-ui-react";
import Axios from "utils/axios";
import swal from 'sweetalert'


const Action = ({ id, load, date }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const cancel = async (e) => {
    try {
      const response = await Axios.delete(`/api/accountclient/delete/quotations/${id}`)
      swal({
        icon: 'success',
        text: `Quotation permanently deleted!!!`,
        buttons: false
      })
      load()
    } catch (error) {
      console.log(error.response)
    }
  }



  const handleCancel = async (e, id) => {
    swal(`Are you sure?`, {
      buttons: {
        cancel: true,
        catch: {
          text: "Yes!",
          value: "catch",
        },
      },
    })
      .then((value) => {
        switch (value) {

          case "cancel":
            break;

          case "catch":
            cancel(value);

          default:
            break;
        }
      });
  }

  return (
    <div>
      {date ? <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          Action
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >

          <MenuItem onClick={handleCancel} >Delete</MenuItem>
        </Menu> </>
        : null}
    </div>
  );
};

export default Action;
