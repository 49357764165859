import React from "react";
import Employee from "components/RouteProtection/Employee/Employee";
import EmployeeLayout from "layout/EmployeeLayout";

import BISummaryadmin from "components/Auth/OTT/EMPBISummary";

const BIPaymentPage = () => {
  return (


    <EmployeeLayout>
      <Employee>
        <BISummaryadmin />
      </Employee>
    </EmployeeLayout>
  );
};

export default BIPaymentPage;
