import { CHANGE_QUOTERQST_INPUT, UPDATE_QUOTERQST_STEP } from './actionTypes'

export const updateQuoteRqstStep= (count) => ({
    type: UPDATE_QUOTERQST_STEP,
    payload: count
})

export const changeQuoteInput = data => ({
    type: CHANGE_QUOTERQST_INPUT,
    payload: data
})