import swal from "sweetalert";

import axios from "axios";
import history from "utils/history";

const {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SET_LOADING,
} = require("./actionTypes");

export const forgotPassword = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });

    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_API}/cashclient/forgot-password`,
      {
        email: data.email,
        id: data.userId,
      }
    );

    swal({
      title: "Success",
      text: `${response?.data.message}`,
      icon: "success",
      buttons: false,
    });
    setTimeout(() => {
      history.push("/cash-client-login");
    }, 100);
    dispatch({
      type: FORGOT_PASSWORD,
      payload: [],
    });
  } catch (error) {
    swal({
      title: "Oops!",
      text: `${error?.response?.data?.error}`,
      icon: "error",
      buttons: false,
    });
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const resetPassword = (data) => async (dispatch) => {
  //   TODO:- API Call
  dispatch({ type: RESET_PASSWORD, payload: [] });
};
