import React, { Fragment } from "react";

// Material Core Components
import Typography from "@material-ui/core/Typography";
import LandingPageLayout from "layout/LandingPageLayout";

// Custom Components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FormTwo from "./FormTwo";
import Cookies from "js-cookie";
import Axios from "axios";
import { Button } from "semantic-ui-react";
import history from "utils/history";
// Custom Styles
import useEmployeeStyles from "assets/jss/components/employeeStyle";
import CustomAvatar from "components/Common/CustomAvatar";

const LinguistsCollectiveTel = () => {
  const classes = useEmployeeStyles();
  const ref =
    JSON.parse(sessionStorage.getItem("frontUser")) ||
    (Cookies.get("CACtoken") && JSON.parse(localStorage.getItem("CACuser"))) ||
    (Cookies.get("BACtoken") && JSON.parse(localStorage.getItem("BACuser")));
  const id = ref?._id;
  const clientDetails =
    ref?.id +
    " - " +
    ref?.LastName +
    " - " +
    ref?.PostalCode +
    " - " +
    ref?.mobile_no;
  const handleSignout = async () => {
    if (Cookies.get("CACtoken")) {
      await Axios.get("/api/cashclient/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("CACtoken")}`,
        },
      });
      Cookies.remove("CACtoken");
      Cookies.remove("CACuser");
      localStorage.removeItem("CACuser");
      sessionStorage.clear();
      Cookies.set("isAuth", false);
      history.push("/cash-client-login");
    } else if (Cookies.get("BACtoken")) {
      await Axios.get("/api/accountclient/signout", {
        headers: {
          Authorization: `Bearer ${Cookies.get("BACtoken")}`,
        },
      });
      Cookies.remove("BACtoken");
      Cookies.remove("BACuser");
      localStorage.removeItem("BACuser");
      sessionStorage.clear();
      history.push("/account-client-login");
    }
  };
  return (
    <LandingPageLayout>
      <div className={classes.root}>
        <GridContainer justify="center" className={classes.container}>
          <GridItem xs={12} sm={12} md={12} lg={6} xl={5}>
            <Card>
              <CardBody>
                <div className={`${classes.textCenter} ${classes.title}`}>
                  <CustomAvatar />
                  <Typography component="h1" variant="h5">
                    <Fragment>
                      Order Translation (Direct -{" "}
                      {ref?.id.substring(0, 3).toUpperCase()})
                    </Fragment>
                    <Typography variant="subtitle2" gutterBottom>
                    Client Reference ID:{ref?.id}
                    </Typography>
                  </Typography>
                  {/* {(Cookies.get("CACtoken") || Cookies.get("BACtoken")) && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 10,
                      }}
                    >
                      <Button
                        color="green"
                        onClick={() =>
                          history.push(
                            Cookies.get("CACtoken")
                              ? "/cash-client/dashboard"
                              : "/account-client/dashboard"
                          )
                        }
                      >
                        My Dashboard
                      </Button>
                      <Button color="red" onClick={handleSignout}>
                        Logout
                      </Button>
                    </div>
                  )}{" "} */}
                </div>
                <div className={classes.notes}>
                  <Typography variant="h6" gutterBottom>
                    Note to the customer
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    You can order most types of translation via this order form
                    however there are some limitation for large volume of work
                    and if you are looking for bargain you can use our quote to
                    compare prices. If you have more than one document please
                    order them separately and indicate any requirement of
                    formatting and certification. For transcription and
                    subtitling work please use quotation form.
                  </Typography>
                </div>
                <FormTwo
                  clientDetails={clientDetails}
                  id={id}
                  refID={ref?.id}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </LandingPageLayout>
  );
};

export default LinguistsCollectiveTel;
