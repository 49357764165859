import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import axios from "utils/axios";
import Action from "./Action";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  button: {
    background: "#e0e1e2 none",
    border: 0,
    color: "rgba(0,0,0,.6)",
    padding: 12,
    marginRight: 10,
    borderRadius: "3pt",
    cursor: "pointer",
    fontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
    fontWeight: "bold",
    fontSize: 13,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [values, setValues] = useState({
    name: "",
    error: false,
    success: false,
    toturialList: [],
    removed: false,
    reload: false,
  });

  const { toturialList, reload } = values;

  useEffect(() => {
    loadToturialsList();
  }, [reload]);

  const loadToturialsList = async () => {
    try {
      const response = await axios.get(
        "/api/ADM-EMP/get-tutorial"
      );
      setValues({ ...values, toturialList: response?.data });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="export">
          <TableHead>
            <TableRow>
              <TableCell>Posted To</TableCell>
              <TableCell>Posted At</TableCell>
              <TableCell>Tutorial Name</TableCell>
              <TableCell>Tutorial Link</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toturialList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((list) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={list._id}>
                  <TableCell>
                    {list.empTutorial === "yes"
                      ? "Employee"
                      : list.sppoTutorial === "yes"
                      ? "Sppo"
                      : list.linTutorial === "yes"
                      ? "Linguist"
                      : list.bacTutorial === "yes"
                      ? "BAC"
                      : list.cacTutorial === "yes"
                      ? "CAC"
                      : null}
                  </TableCell>
                  <TableCell>
                    {moment(list.createdAt).format("DD/MMM/YYYY")}
                  </TableCell>
                  <TableCell>{list.tutorial_name}</TableCell>
                  <TableCell>
                    <a target="_blank" href={`${list.tutorial_link}`}>
                      Tutorial Link
                    </a>
                  </TableCell>
                  <TableCell>
                    <Action id={list?._id} load={loadToturialsList} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={toturialList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
